import type { HStepsProps } from 'candidate/components';
import type { ProfileProps } from 'candidate/features/profile/Profile.types';
import type { FormikState } from 'formik';
import type { ChangeEvent, ComponentProps, FocusEvent } from 'react';
import {
  Button,
  FormControl,
  VStack,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
} from '@terminal/design-system';
import { StepPageLayout } from '../../components/StepPageLayout';

export function MoreInfoStepBase({
  description,
  errors,
  shouldShowSkipButton,
  isFinalizeDisabled,
  initialRef,
  onFinalizeClick,
  onSignOutClick,
  onSkipClick,
  onSocialProfileChange,
  onBlur,
  progressProps,
  userFullName,
  values,
  touched,
  title,
}: {
  description: string;
  shouldShowSkipButton: boolean;
  isFinalizeDisabled: boolean;
  initialRef?: React.MutableRefObject<null>;
  onFinalizeClick: () => void;
  onSignOutClick: () => Promise<void>;
  onSkipClick: () => void;
  onSocialProfileChange: (e: string | ChangeEvent<any>) => void;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  progressProps: HStepsProps;
  userFullName: string;
  title: string;
} & Pick<
  FormikState<ProfileProps['candidate']['socialProfile']['formValues']>,
  'values' | 'errors' | 'touched'
>) {
  return (
    <StepPageLayout
      {...{
        progressProps,
        title,
        description,
        userFullName,
        onSignOutClick,
      }}
      footer={
        <Flex flexDir={['column-reverse', 'row']} w="full" justifyContent="center">
          {shouldShowSkipButton && (
            <Button onClick={onSkipClick} variant="ghost" w={['full', 64]}>
              Skip
            </Button>
          )}
          <Button
            onClick={onFinalizeClick}
            w={['full', 64]}
            disabled={isFinalizeDisabled}
            isLoading={isFinalizeDisabled}
            ml={[0, 6]}
          >
            Finalize
          </Button>
        </Flex>
      }
    >
      <VStack spacing={[4, 4, 6]} w="full">
        <FormControl isInvalid={touched.linkedin_url && !!errors.linkedin_url}>
          <FormLabel htmlFor="linkedin_url">LinkedIn</FormLabel>
          <Input
            id="linkedin_url"
            name="linkedin_url"
            placeholder="www.linkedin.com/in/"
            autoCapitalize="none"
            ref={initialRef}
            value={values.linkedin_url || ''}
            spellCheck={false}
            onChange={onSocialProfileChange}
            onBlur={onBlur}
          />
          <FormErrorMessage>{errors.linkedin_url}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={touched.github_url && !!errors.github_url}>
          <FormLabel htmlFor="github_url">GitHub (optional)</FormLabel>
          <Input
            id="github_url"
            name="github_url"
            placeholder="www.github.com"
            autoCapitalize="none"
            spellCheck={false}
            value={values.github_url || ''}
            onChange={onSocialProfileChange}
            onBlur={onBlur}
          />
          <FormErrorMessage>{errors.github_url}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={touched.other_url && !!errors.other_url}>
          <FormLabel htmlFor="other_url">Portfolio (optional)</FormLabel>
          <Input
            id="other_url"
            name="other_url"
            placeholder="www.website.com"
            autoCapitalize="none"
            spellCheck={false}
            value={values.other_url || ''}
            onChange={onSocialProfileChange}
            onBlur={onBlur}
          />
          <FormErrorMessage>{errors.other_url}</FormErrorMessage>
        </FormControl>
      </VStack>
    </StepPageLayout>
  );
}

export type MoreInfoStepVariationProps = Omit<
  ComponentProps<typeof MoreInfoStepBase>,
  'title' | 'description' | 'shouldShowSkipButton'
>;

export function MoreStepInfoNotSkippable(props: MoreInfoStepVariationProps) {
  return (
    <MoreInfoStepBase
      {...props}
      title="Add Additional Information"
      description="The more information we have, the better your matches will be."
      shouldShowSkipButton={false}
    />
  );
}

export function MoreStepInfoSkippable(props: MoreInfoStepVariationProps) {
  return (
    <MoreInfoStepBase
      {...props}
      title="Add Additional Information"
      description="The more information we have, the better your matches will be."
      shouldShowSkipButton
    />
  );
}

export function MoreStepInfoReview(props: MoreInfoStepVariationProps) {
  return (
    <MoreInfoStepBase
      {...props}
      title="Add Your Profiles"
      description="The more information we have, the better your matches will be."
      shouldShowSkipButton
    />
  );
}

export const MoreInfoStep = {
  Base: MoreInfoStepBase,
  Variation: {
    Skippable: MoreStepInfoSkippable,
    NotSkippable: MoreStepInfoNotSkippable,
    Review: MoreStepInfoReview,
  },
};
