import somethingWentWrongImageSrc from 'candidate/assets/images/404-rebrand.svg?url';
import { ExceptionPage } from '../ExceptionPage';

export function ErrorFallback({
  onActionClick,
  actionTitle,
}: {
  onActionClick: () => void;
  actionTitle: string;
}) {
  return (
    <ExceptionPage
      title="Oops!"
      subTitle=" Something went wrong."
      description="We are sorry, the page you requested could not be found."
      imageSrc={somethingWentWrongImageSrc}
      onActionClick={onActionClick}
      actionTitle={actionTitle}
    />
  );
}
