import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import * as events from 'global/events';
import mixpanel from 'global/events/mixpanel';

import { BlankScreenLoading } from 'global/components';
import { useRedirection } from 'global/utils';

import UpdateCandidateTestCompleted from './UpdateCandidateTestCompleted.graphql';
import { CompletionLanding } from './CompletionLanding';
import type {
  UpdateCandidateTestCompletedMutation,
  UpdateCandidateTestCompletedMutationVariables,
} from './types';

export function CompletionLandingController({
  testID,
  userID,
}: {
  testID: string;
  userID?: number;
}) {
  const redirectTo = useRedirection();

  const [updateCompleted, { loading, error }] = useMutation<
    UpdateCandidateTestCompletedMutation,
    UpdateCandidateTestCompletedMutationVariables
  >(UpdateCandidateTestCompleted, {
    context: {
      role: 'candidate',
    },
    onCompleted: (data) => {
      // Only send this event to Mixpanel. It's tracked in Segment via the backend
      mixpanel.track(events.name.assessment.completed, {
        'Assessment Name': data.update_hr_test_candidate?.returning[0]?.hr_test.name,
      });
    },
  });

  useEffect(() => {
    updateCompleted({
      variables: {
        completed: true,
        test_id: testID,
        user_id: Number(userID),
        now: new Date().toISOString(),
      },
    });
  }, [testID, updateCompleted, userID]);

  if (loading) {
    return <BlankScreenLoading />;
  }

  if (error) {
    throw error;
  }

  return <CompletionLanding onClose={() => redirectTo('/assessments')} />;
}
