import { Candidate_Source_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import type { SelectCandidateInsiderInfoQuery } from './types';

// TODO: [CAND-538] write tests
export function serializeSelectCandidateInsiderInfo(data: SelectCandidateInsiderInfoQuery | null): {
  shouldForceRedirect: {
    toOnboarding: boolean;
    outOfOnboarding: boolean;
  };
  userRole: 'candidate' | 'member' | null;
} {
  if (!data) {
    return {
      shouldForceRedirect: {
        toOnboarding: false,
        outOfOnboarding: false,
      },
      userRole: null,
    };
  }

  if (data.candidate.length === 0) {
    throw new Error('Candidate not found');
  }

  const candidate = data.candidate[0] as SelectCandidateInsiderInfoQuery['candidate'][number];
  const isMember = (candidate.dayforce_employee?.dayforce_assignments || []).length > 0;
  const hasAppliedToAJob =
    !!candidate.applicant_jobs?.[0]?.icims_profile_job.id ||
    !!candidate.candidate_jobs?.[0]?.icims_profile_job.id;
  const isOnboardingCompleted = candidate.profile_flow_completed_at != null;
  const isOutboundCandidate = [
    Candidate_Source_Choices_Enum.Gem,
    Candidate_Source_Choices_Enum.Icims,
  ].includes(candidate.source);

  return {
    shouldForceRedirect: {
      toOnboarding:
        /** If is not a Member and hasn't applied to a Job, Check the onboarding status */
        !(isMember || hasAppliedToAJob || isOnboardingCompleted || isOutboundCandidate),
      outOfOnboarding: isMember || hasAppliedToAJob || isOnboardingCompleted || isOutboundCandidate,
    },
    userRole: isMember ? 'member' : 'candidate',
  };
}
