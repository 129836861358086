/* eslint-disable react/no-array-index-key */
import { Box, HStack } from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';
import { StepsProgressBar } from './StepsProgressBar';
import { Step } from './Step';

export function ProgressBar({ progress }: { progress: number }) {
  // Chakra UI has a value for 0.5 so we have to manually convert it to 50%
  // This should get fixed at some point in Chakra UI library at which point we should revert this.
  const safeProgress =
    {
      0.5: '50%',
      1: 'full',
    }[progress] || progress;

  return (
    <Box w="full" position="fixed" zIndex="docked" aria-label="progress" role="progressbar">
      <Box h={3} w={safeProgress} bg="accent.main" />
    </Box>
  );
}

const determineState = (currentStepNumber: number, currentStep: number) => {
  if (currentStepNumber > currentStep) return 'complete';
  if (currentStepNumber === currentStep) return 'active';
  return 'incomplete';
};

export type HStepsProps = {
  currentStepNumber: number;
  totalSteps: number;
  onStepClick?: (stepNumber: number) => void;
};

export function HSteps({ currentStepNumber, onStepClick, totalSteps }: HStepsProps) {
  const factor = totalSteps - 1;
  const value = (100 / factor) * (currentStepNumber - 1);
  const isDesktop = useIsViewPortDesktop();

  if (!isDesktop) {
    return <ProgressBar progress={currentStepNumber / totalSteps || 1} />;
  }

  return (
    <Box as="nav" aria-label="Steps" position="relative">
      <HStack
        spacing={[4, 6, 12]}
        justify="space-between"
        align="center"
        as="ol"
        listStyleType="none"
        zIndex="base"
        data-steps={totalSteps}
        role="progressbar"
        aria-label="steps"
      >
        {[...Array(totalSteps)].map((_, index) => (
          <Step
            labelForSightedUser={currentStepNumber.toString()}
            key={`step-${currentStepNumber}-${index}`}
            onClick={onStepClick ? () => onStepClick(index + 1) : undefined}
            state={determineState(currentStepNumber, index + 1)}
          >
            {index + 1}
          </Step>
        ))}
      </HStack>
      <StepsProgressBar value={value} />
    </Box>
  );
}
