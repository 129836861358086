import { Icon } from '@terminal/design-system';

export function ColombiaFlagIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
        <path d="m1 3.5 9-2.5 6.5 1.5 7 3 9-2 1 14-10.5 1-8-3.5h-7l-7 1v-12.5z" fill="#ECB23F" />
        <path
          d="m1 16.5 9.5-2 5.5 1.5 7.5 2.5 9-2 0.4846 5.5-10.485 1.5-7.2846-3.1h-7.1077l-7.1077 0.4571v-4.3571z"
          fill="#4B71F9"
        />
        <path
          d="M1 22L10.5 20L16 21.5L23.5 24L32.5 22L32.9846 27.5L22.5 29L15.2154 25.9H8.10769L1 26.3571V22Z"
          fill="#F6623A"
        />
        <path
          d="M1 33V3"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m1 27.057 4.4857-1.2933c3.7591-1.085 7.8345-0.5992 11.184 1.3333 3.2685 1.8852 7.2314 2.3961 10.921 1.408l4.2482-1.1373c0.6867-0.1844 1.1602-0.773 1.1605-1.4427v-21.076c-5e-4 -0.47053-0.2362-0.91364-0.6369-1.197-0.4006-0.28341-0.9198-0.37433-1.4026-0.24562l-3.3692 0.90267c-3.6899 0.98807-7.6528 0.47715-10.921-1.408-3.3498-1.9325-7.4252-2.4183-11.184-1.3333l-4.4857 1.292"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m18.111 16.001c-3.5262-2.0342-7.8088-2.5443-11.759-1.4067l-5.0433 1.3312c-0.4005 0.1057-0.63948 0.5161-0.53376 0.9166 0.038946 0.1475 0.11924 0.2732 0.22516 0.3677v4.7903c0 0.2761 0.22386 0.5 0.5 0.5 0.1478 0 0.28063-0.0641 0.37216-0.1661 0.12735 0.0342 0.26534 0.0352 0.40128-4e-3l4.4857-1.292c3.5659-1.0292 7.4311-0.567 10.602 1.2623 3.443 1.9859 7.6108 2.5216 11.49 1.4828l3.0032-0.8046c0.0461 0.0141 0.095 0.0216 0.1457 0.0216 0.1584 0 0.2996-0.0737 0.3912-0.1886 0.2937-0.1632 0.4505-0.5104 0.3596-0.8496-0.0424-0.1584-0.1325-0.2914-0.2508-0.3875v-3.8366c0.2219-0.1809 0.3297-0.4814 0.2508-0.7759-0.1072-0.4001-0.5184-0.6375-0.9185-0.5304l-3.3692 0.9027c-3.5005 0.9373-7.2586 0.4513-10.352-1.3332zm13.389 2.0725-2.6488 0.7096c-3.8792 1.0388-8.047 0.5031-11.49-1.4828-3.171-1.8292-7.0358-2.2916-10.602-1.2624l-0.01658 0.0046-4.743 1.2519v3.5534l4.3436-1.2511c3.9523-1.1407 8.2384-0.6313 11.767 1.4043 3.0938 1.7845 6.8519 2.2706 10.352 1.3332l3.0369-0.8136v-3.4471z"
          clipRule="evenodd"
          fill="#25383F"
          fillRule="evenodd"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 33V3"
      />
      <path
        stroke="currentColor"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 27.057l4.486-1.294c3.759-1.085 7.834-.599 11.184 1.334 3.268 1.885 7.231 2.396 10.921 1.408l4.248-1.138c.687-.184 1.16-.773 1.161-1.442V4.849c0-.47-.236-.914-.637-1.197-.4-.284-.92-.375-1.402-.246l-3.37.903c-3.69.988-7.652.477-10.921-1.408C13.32.968 9.245.482 5.486 1.567L1 2.86"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M32.026 17.156l-3.369.903c-3.69.988-7.653.477-10.921-1.408-3.35-1.933-7.425-2.419-11.184-1.334L2.066 16.61M32.026 22.156l-3.369.903c-3.69.988-7.653.477-10.921-1.408-3.35-1.933-7.425-2.419-11.184-1.334L2.066 21.61"
      />
    </Icon>
  );
}
