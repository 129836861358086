import { compareByDateAsc } from 'global/utils';
import moment from 'moment';
import { Candidate_Source_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { toDatesTitle } from '../../../Profile.utils';
import type { SelectProfileQuery } from '../../../graphql/types';
import type { ProfileProps } from '../../../Profile.types';
import type { UpsertCandidateWorkHistoryMutationVariables } from './graphql';

export function serializerWorkExperiences(
  workExperiences: SelectProfileQuery['candidates'][number]['candidate_work_experiences'],
): ProfileProps['candidate']['workExperiences'] {
  return workExperiences
    .slice()
    .sort((a, b) => {
      const earliestDate = '1800-01-01';

      const compareCurrentlyWorking = Number(b.currently_working) - Number(a.currently_working);
      if (compareCurrentlyWorking !== 0) {
        return compareCurrentlyWorking;
      }

      const compareEndDate = compareByDateAsc(
        a.end_date || earliestDate,
        b.end_date || earliestDate,
      );
      if (compareEndDate !== 0) {
        return compareEndDate;
      }

      return compareByDateAsc(a.start_date || earliestDate, b.start_date || earliestDate);
    })
    .map(
      ({
        id,
        company_name,
        currently_working,
        start_date,
        end_date,
        job_title,
        location,
        description,
      }) => {
        const datesTitle = toDatesTitle({
          startDate: start_date,
          endDate: end_date,
          isCurrent: !!currently_working,
          format: 'MMMM yyyy',
        });
        const hasMissingData = !company_name || !location || !datesTitle || !job_title;

        return {
          id,
          title: job_title,
          subTitle: [company_name, location].filter(Boolean).join(' - ') || null,
          datesTitle,
          isCurrent: !!currently_working,
          formValues: {
            job_title: job_title || '',
            company_name: company_name || '',
            location: location || '',
            currently_working: !!currently_working,
            startDateYear: start_date && moment(start_date).year(),
            startDateMonth: start_date && moment(start_date).month(),
            endDateYear: end_date && moment(end_date).year(),
            endDateMonth: end_date && moment(end_date).month(),
            description: description || '',
          },
          hasMissingData,
          missingDataCTA: hasMissingData
            ? [job_title, company_name].filter(Boolean).join(', ') || null
            : null,
          description,
        };
      },
    );
}

export function serializerForWorkExperienceUpsert({
  values,
  workExperienceID,
  candidateID,
}: {
  values: ProfileProps['candidate']['workExperiences'][number]['formValues'];
  workExperienceID: UpsertCandidateWorkHistoryMutationVariables['object']['id'];
  candidateID: number;
}): UpsertCandidateWorkHistoryMutationVariables {
  return {
    object: {
      candidate_id: candidateID,
      job_title: values.job_title?.length ? values.job_title : null,
      company_name: values.company_name.length ? values.company_name : null,
      location: values.location.length ? values.location : null,
      currently_working: values.currently_working,
      source: Candidate_Source_Choices_Enum.CandidatePortal,
      start_date: values.startDateYear
        ? new Date(Date.UTC(values.startDateYear, values.startDateMonth || 0))
        : null,
      end_date:
        values.endDateYear && !values.currently_working
          ? new Date(Date.UTC(values.endDateYear, values.endDateMonth || 0))
          : null,
      description: values.description,
      ...(workExperienceID && { id: workExperienceID }),
    },
  };
}
