import { SwitchWithPageNotFound } from 'candidate/components';
import { CustomRoute } from 'global/components/router';
import { Route, Redirect, useRouteMatch, useLocation } from 'react-router-dom';
import { useCandidateAuth } from 'candidate/utils';

import { ExperiencesManualEntryController } from './ExperiencesManualEntry.controller';
import { ExperiencesWhatDoYouHaveController } from './ExperiencesWhatDoYouHave.controller';

export function ExperiencesStepRouter({
  candidateID,
  onStepCompleteSuccess,
  parentNestedPageTitles,
  progressProps,
  workExperiences,
  degreeChoices,
  educations,
}: {
  parentNestedPageTitles: string[];
} & Omit<React.ComponentProps<typeof ExperiencesManualEntryController>, 'variation'>) {
  const auth = useCandidateAuth();
  const { path, url } = useRouteMatch();
  const location = useLocation();

  return (
    <SwitchWithPageNotFound>
      <CustomRoute
        path={`${path}/combined/choose-path`}
        exact
        title={[...parentNestedPageTitles, 'Choose Path']}
      >
        <ExperiencesWhatDoYouHaveController
          candidateID={candidateID as number}
          onStepCompleteSuccess={onStepCompleteSuccess}
          progressProps={progressProps}
        />
      </CustomRoute>
      <CustomRoute
        path={`${path}/combined/manual-entry`}
        exact
        title={[...parentNestedPageTitles, 'Manual Entry']}
      >
        <ExperiencesManualEntryController
          candidateID={candidateID as number}
          onStepCompleteSuccess={onStepCompleteSuccess}
          progressProps={progressProps}
          mode="add"
          userID={auth.user?.id}
          workExperiences={workExperiences}
          degreeChoices={degreeChoices}
          educations={educations}
          variation="Combined"
        />
      </CustomRoute>
      <Route
        path={path}
        exact
        render={() => (
          <Redirect
            to={{
              pathname: `${url}/combined/choose-path`,
              search: location.search,
            }}
          />
        )}
      />
    </SwitchWithPageNotFound>
  );
}
