import { useEffect } from 'react';
import { useCandidateAuth } from 'candidate/utils';
import { firebaseAuth } from 'global/firebaseApp';
import { useSignUp, googleSignIn } from 'global/auth';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { CustomRoute, SwitchWithPageNotFound } from 'candidate/components';
import { JobApplicationSignUp } from './JobApplicationSignUp';

export function JobApplicationSignUpController({
  companyName,
  currentEmail,
  jobID,
  position,
  firstName,
  lastName,
  continueURL,
  parentURL,
}: {
  companyName: string;
  currentEmail: string;
  jobID: number | null;
  position: string;
  firstName: string;
  lastName: string;
  continueURL: string;
  parentURL: string;
}) {
  const auth = useCandidateAuth();
  const location = useLocation<string>();
  const { path } = useRouteMatch();
  const { handleSignUpSubmit, isLoading, isError, shouldHideGoogleSSO } = useSignUp({
    continueURL,
  });

  useEffect(() => {
    if (isError) {
      localStorage.removeItem('from-direct-application-job-id');
      localStorage.removeItem('from-direct-application-job-title');
      localStorage.removeItem('from-direct-application-job-company');
    }
  }, [isError]);

  const includeValuesToJobMatches = () => {
    localStorage.setItem('from-direct-application-job-id', `${jobID}`);
    localStorage.setItem('from-direct-application-job-title', position);
    localStorage.setItem('from-direct-application-job-company', companyName);
  };

  return (
    <SwitchWithPageNotFound>
      <CustomRoute
        path={path}
        conditionalRedirects={[
          {
            condition: auth.isAuthorized && !!jobID,
            redirectURL: `/job/matches/detail/${jobID}`,
            search: location.search
              ? `${location.search}&from=claim-account`
              : `?from=claim-account`,
          },
          {
            condition: !auth.isAuthorized && (!firstName || !currentEmail),
            redirectURL: parentURL,
          },
        ]}
      >
        <JobApplicationSignUp
          onEmailSignSubmitted={(email, password) => {
            // We need to set this here because after SignUp the user is redirected to email-verification
            includeValuesToJobMatches();

            return handleSignUpSubmit({ email, password, firstName, lastName });
          }}
          onGoogleLoginClicked={() => {
            includeValuesToJobMatches();
            googleSignIn(
              { auth: firebaseAuth, dispatch: auth.dispatch },
              { hintEmail: currentEmail },
            );
          }}
          email={currentEmail}
          firstName={firstName}
          isLoading={isLoading}
          shouldHideGoogleSSO={shouldHideGoogleSSO}
        />
      </CustomRoute>
    </SwitchWithPageNotFound>
  );
}
