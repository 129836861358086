import type { FormikHelpers } from 'formik';

export * from './graphql/types';

// Section was created as an enum so we can, in the future, if one more sections is added, just navigate
// through them with the indexes and not depend on their names or order
export enum Section {
  'personalInformation',
  'experience',
  'accountCreation',
}

export type FormikSubmitHandler<V> = (values: V, formikHelpers: FormikHelpers<V>) => void;
