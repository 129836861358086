import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import { useMutation } from '@apollo/client';
import type { LocationTypeAheadProps } from 'global/components';
import { useImportGooglePlacesScript, useQuery } from 'global/utils';
import type { SelectCountryListQuery, SelectCountryListQueryVariables } from 'candidate/utils/data';
import { SelectCountryList } from 'candidate/utils/data';
import type {
  ProfileProps,
  UpdateCandidatePersonalInfoMutation,
  UpdateCandidatePersonalInfoMutationVariables,
} from '../../../../Profile.types';
import { UpdateCandidatePersonalInfo } from '../../../../graphql';
import type { LocationFormProps } from './Location.types';

export function useHandleLocationTypeahead({
  candidateID,
  initialFormValues,
  onUpdateComplete,
}: {
  candidateID: number;
  onUpdateComplete: () => void;
  initialFormValues?: {
    country?: ProfileProps['candidate']['personalInfo']['formValues']['country'];
    city?: ProfileProps['candidate']['personalInfo']['formValues']['city'];
    state?: ProfileProps['candidate']['personalInfo']['formValues']['state'];
    latitude?: ProfileProps['candidate']['personalInfo']['formValues']['latitude'];
    longitude?: ProfileProps['candidate']['personalInfo']['formValues']['longitude'];
    country_id?: ProfileProps['candidate']['personalInfo']['formValues']['country_id'];
  };
}) {
  const normalizedCountries = useQuery<SelectCountryListQuery, SelectCountryListQueryVariables>(
    SelectCountryList,
  );
  const { placeIDToCountryIDMap, countryNameToIDMap } =
    normalizedCountries.data?.country_choices?.reduce<{
      placeIDToCountryIDMap: Record<string, number>;
      countryNameToIDMap: Record<string, number>;
    }>(
      (map, obj: { place_id: string | null; name: string; id: number }) => {
        if (!obj.place_id) return map;
        return {
          placeIDToCountryIDMap: {
            ...map.placeIDToCountryIDMap,
            [obj.place_id]: obj.id,
          },
          countryNameToIDMap: {
            ...map.countryNameToIDMap,
            [obj.name.toLowerCase()]: obj.id,
          },
        };
      },
      { placeIDToCountryIDMap: {}, countryNameToIDMap: {} },
    ) || { placeIDToCountryIDMap: {}, countryNameToIDMap: {} };

  const isGooglePlaceAPIReady = useImportGooglePlacesScript();

  const validationSchema = Yup.object().shape({
    country: Yup.string().required('Country not found.'),
    city: Yup.string().notRequired().nullable(),
    state: Yup.string().notRequired().nullable(),
    latitude: Yup.string().notRequired().nullable(),
    longitude: Yup.string().notRequired().nullable(),
    country_id: Yup.number().notRequired().nullable(),
  });

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const [updatePersonalInfo, { loading: isLoading_updatePersonalInfo }] = useMutation<
    UpdateCandidatePersonalInfoMutation,
    UpdateCandidatePersonalInfoMutationVariables
  >(UpdateCandidatePersonalInfo, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to update your location. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
    onCompleted: () => {
      onUpdateComplete();
    },
  });

  const formik = useFormik<LocationFormProps>({
    initialValues: {
      country: initialFormValues?.country || '',
      latitude: initialFormValues?.latitude || '',
      longitude: initialFormValues?.longitude || '',
      city: initialFormValues?.city || '',
      state: initialFormValues?.state || '',
      country_id: initialFormValues?.country_id || null,
    },
    validationSchema,
    onSubmit: ({ country, latitude, longitude, city, state, country_id }) => {
      updatePersonalInfo({
        variables: {
          candidate_id: candidateID,
          candidate_set_input: {
            country_id,
            country,
            ...(latitude !== '' && { latitude }),
            ...(longitude !== '' && { longitude }),
            ...(city !== '' && { city }),
            ...(state !== '' && { state }),
          },
        },
      });
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleLocationChange = ({
    latitude,
    longitude,
    city,
    state,
    country,
    country_id,
  }: Parameters<LocationTypeAheadProps['onChange']>[number]) => {
    formik.setFieldValue('country', country);
    formik.setFieldValue('country_id', country_id);
    formik.setFieldValue('latitude', latitude);
    formik.setFieldValue('longitude', longitude);
    formik.setFieldValue('city', city);
    formik.setFieldValue('state', state);
  };

  return {
    isGooglePlaceAPIReady,
    isLoading_updatePersonalInfo,
    handleLocationChange,
    formik,
    isLoading_normalizedCountries: normalizedCountries.loading,
    placeIDToCountryIDMap,
    countryNameToIDMap,
  };
}
