import { Role_Choices_Enum } from 'global/types/hasura-tables.generated.types';

export const Skill = {
  Go: {
    name: 'Go',
    id: 98,
    roles: [{ role: Role_Choices_Enum.Bed }],
  },
  PHP: {
    name: 'PHP',
    id: 99,
    roles: [{ role: Role_Choices_Enum.Bed }],
  },
  React: {
    name: 'React',
    id: 101,
    roles: [{ role: Role_Choices_Enum.Fed }],
  },
  GraphQL: {
    name: 'GraphQL',
    id: 102,
    roles: [{ role: Role_Choices_Enum.Fed }, { role: Role_Choices_Enum.Bed }],
  },
  Javascript: {
    name: 'Javascript',
    id: 103,
    roles: [{ role: Role_Choices_Enum.Fed }],
  },
  SQL: {
    name: 'SQL',
    id: 104,
    roles: [{ role: Role_Choices_Enum.Bed }],
  },
};

export const mainSkillsList = [
  {
    id: 43,
    name: '.NET',
  },
  {
    id: 20,
    name: 'Angular',
  },
  {
    id: 6,
    name: 'AWS',
  },
  {
    id: 24,
    name: 'C/C++',
  },
  {
    id: 72,
    name: 'CSS',
  },
  {
    id: 40,
    name: 'Django',
  },
  {
    id: 16,
    name: 'Docker',
  },
  {
    id: 49,
    name: 'Git',
  },
  {
    id: 71,
    name: 'HTML',
  },
  {
    id: 14,
    name: 'Java',
  },
  {
    id: 46,
    name: 'JQuery',
  },
  {
    id: 17,
    name: 'MySQL',
  },
  {
    id: 8,
    name: 'Node',
  },
  {
    id: 18,
    name: 'NoSQL',
  },
  {
    id: 1,
    name: 'Python',
  },
  {
    id: 2,
    name: 'React',
  },
  {
    id: 9,
    name: 'Ruby',
  },
  {
    id: 7,
    name: 'SQL',
  },
  {
    id: 12,
    name: 'Typescript',
  },
];

export const moreSkillsList = [
  {
    id: 52,
    name: 'Android',
  },
  {
    id: 36,
    name: 'Azure',
  },
  {
    id: 38,
    name: 'Bootstrap',
  },
  {
    id: 42,
    name: 'Drupal',
  },
  {
    id: 41,
    name: 'Flask',
  },
  {
    id: 21,
    name: 'GCP',
  },
  {
    id: 58,
    name: 'GraphQL',
  },
  {
    id: 35,
    name: 'Jenkins',
  },
  {
    id: 22,
    name: 'Kubernetes',
  },
  {
    id: 39,
    name: 'Laravel',
  },
  {
    id: 50,
    name: 'Linux',
  },
  {
    id: 54,
    name: 'MongoDB',
  },
  {
    id: 63,
    name: 'Objective-C',
  },
  {
    id: 4,
    name: 'PHP',
  },
  {
    id: 5,
    name: 'Postgres',
  },
  {
    id: 13,
    name: 'R',
  },
  {
    id: 70,
    name: 'React Native',
  },
  {
    id: 32,
    name: 'Redux',
  },
  {
    id: 48,
    name: 'REST API',
  },
  {
    id: 26,
    name: 'Selenium',
  },
  {
    id: 53,
    name: 'Spring',
  },
  {
    id: 37,
    name: 'Swift',
  },
  {
    id: 51,
    name: 'Unix',
  },
  {
    id: 11,
    name: 'Vue.js',
  },
];
