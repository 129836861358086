import type { ReactNode } from 'react';
import type { SpaceProps } from '@terminal/design-system';
import { AddIcon, Button } from '@terminal/design-system';

interface ProfileSectionButtonProps {
  accented?: boolean;
  children?: ReactNode;
  onAddClick: () => void;
}

export function ProfileSectionButton({
  accented,
  children,
  onAddClick,
  ...spacingProps
}: ProfileSectionButtonProps & SpaceProps) {
  return (
    <Button
      colorScheme={accented ? 'accent' : 'default'}
      iconSpacing={3}
      leftIcon={<AddIcon fontSize={accented ? '2xl' : 'md'} />}
      onClick={onAddClick}
      size={accented ? 'lg' : 'sm'}
      variant="ghost"
      {...spacingProps}
    >
      {children}
    </Button>
  );
}
