import type { ApolloQueryResult } from '@apollo/client';

import { useRouteMatch } from 'react-router-dom';
import { CustomRoute } from 'global/components/router';
import { useRedirection } from 'global/utils';
import { SwitchWithPageNotFound } from 'candidate/components';
import { useCandidateAuth } from 'candidate/utils';
import type { Experiment } from 'candidate/utils';

import type { SelectProfileQuery } from '../../../../graphql/types';

import { AddLocationStepController } from '../location';
import { ResumeParsingController } from './ResumeParsing.Controller';

export function ResumeParsingStepRouter({
  candidateID,
  candidateCountry,
  country_id,
  onSignOutClick,
  onStepCompleteSuccess,
  formattedAddress,
  parentNestedPageTitles,
  progressProps,
  publicID,
  userFullName,
  refetch,
  locationVariation,
}: {
  parentNestedPageTitles: string[];
  refetch: (variables?: { user_id?: number }) => Promise<ApolloQueryResult<SelectProfileQuery>>;
  locationVariation: Experiment['onboardingLocation'];
} & React.ComponentProps<typeof ResumeParsingController> &
  Omit<
    React.ComponentProps<typeof AddLocationStepController>,
    'onStepCompleteSuccess' | 'isConfirmStep'
  >) {
  const { path, url } = useRouteMatch();
  const redirectTo = useRedirection();
  const auth = useCandidateAuth();

  const handleResumeParseCompleted = async (hasParsedResume: boolean) => {
    // if user chose manual entry
    if (!hasParsedResume) {
      onStepCompleteSuccess(false);
      return;
    }

    const parsedData = await refetch({
      user_id: auth.user?.id,
    });

    if (parsedData.data.candidates[0]?.country_choice?.name?.length) {
      onStepCompleteSuccess(true);
      return;
    }

    redirectTo(`${url}/confirm-account`);
  };

  return (
    <SwitchWithPageNotFound>
      <CustomRoute path={`${path}/`} exact title={[...parentNestedPageTitles]}>
        <ResumeParsingController
          {...{
            candidateID,
            onSignOutClick,
            progressProps,
            publicID,
            userFullName,
          }}
          onStepCompleteSuccess={handleResumeParseCompleted}
        />
      </CustomRoute>
      <CustomRoute
        path={`${path}/confirm-account`}
        title={[...parentNestedPageTitles, 'Confirm Location']}
        conditionalRedirects={[]}
      >
        <AddLocationStepController
          {...{
            candidateID,
            candidateCountry,
            country_id,
            formattedAddress,
            onSignOutClick,
            progressProps,
            userFullName,
          }}
          onStepCompleteSuccess={() => onStepCompleteSuccess(true)}
          isConfirmStep
          experienceVariation={locationVariation}
        />
      </CustomRoute>
    </SwitchWithPageNotFound>
  );
}
