/* eslint-disable func-names */
import { useYearsController } from 'candidate/shared/modules/years';
import { useToast } from '@terminal/design-system';
import type { ProfileProps } from '../../Profile.types';
import { YearsOfExperienceForm } from './YearsOfExperienceForm';

export function YearsOfExperienceController({
  userID,
  candidateID,
  candidateYearsOfExperienceRange,
  yearsOfExperienceRangeChoices,
}: {
  candidateID: number;
  userID?: number;
  candidateYearsOfExperienceRange: ProfileProps['candidate']['yearsOfExperienceRange'];
  yearsOfExperienceRangeChoices: ProfileProps['yearsOfExperienceRangeChoices'];
}) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const { formik, handleOnCheckboxClick, isLoading_upsert } = useYearsController({
    candidateID,
    userID,
    candidateYearsOfExperienceRange,
    yearsOfExperienceRangeChoices,
    onSaveSuccess: () => {
      toast({
        title: 'Saved Successfully!',
        description: 'Your changes were saved successfully.',
        status: 'success',
      });
    },
  });

  return (
    <YearsOfExperienceForm
      yearsOfExperienceChoices={yearsOfExperienceRangeChoices}
      values={formik.values}
      onCheckboxClick={handleOnCheckboxClick}
      showSaveButton={JSON.stringify(formik.initialValues) !== JSON.stringify(formik.values)}
      showLoadingForSaveButton={isLoading_upsert}
      onSaveClick={formik.handleSubmit}
    />
  );
}
