import { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import { validators } from 'global/utils';
import type { HStepsProps } from 'candidate/components';
import type {
  UpdateCandidateResumeAndSocialProfileMutation,
  UpdateCandidateResumeAndSocialProfileMutationVariables,
} from 'candidate/shared/modules/onboarding/data';
import { UpdateCandidateResumeAndSocialProfile } from 'candidate/shared/modules/onboarding/data';
import type { ProfileProps } from '../../../../Profile.types';
import { MoreInfoStep } from './MoreInfoStep';
import type { MoreInfoStepVariationProps } from './MoreInfoStep';

export const create_resumeAndProfiles_validationSchema = ({
  linkedInRequiredMessage,
}: {
  linkedInRequiredMessage?: string;
} = {}) =>
  Yup.object().shape({
    linkedin_url: Yup.string()
      .required(
        linkedInRequiredMessage && linkedInRequiredMessage !== ''
          ? linkedInRequiredMessage
          : validators.linkedIn.required.message,
      )
      .matches(validators.linkedIn.validURL.regex, validators.linkedIn.validURL.message)
      .nullable(),
    github_url: Yup.string().matches(validators.github.regex, validators.github.message).nullable(),
    other_url: Yup.string().matches(validators.url.regex, validators.url.message).nullable(),
  });

export function MoreInfoStepController({
  candidateResume,
  candidateSocialProfile,
  candidateWorkExperiences,
  candidateID,
  onStepCompleteSuccess,
  onSignOutClick,
  userFullName,
  progressProps,
  mode,
}: {
  candidateResume: ProfileProps['candidate']['resume'];
  candidateSocialProfile: ProfileProps['candidate']['socialProfile'];
  candidateWorkExperiences: ProfileProps['candidate']['workExperiences'];
  candidateID: number;
  onStepCompleteSuccess: () => void;
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  progressProps: HStepsProps;
  mode: 'add' | 'review';
}) {
  const initialRef = useRef(null);

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const [updateResumeAndSocialProfile, { loading: isLoading_updateResumeAndSocialProfile }] =
    useMutation<
      UpdateCandidateResumeAndSocialProfileMutation,
      UpdateCandidateResumeAndSocialProfileMutationVariables
    >(UpdateCandidateResumeAndSocialProfile, {
      onError: (error) => {
        toast({
          description: 'Something went wrong trying to update your resume. Please try again!',
          status: 'error',
        });
        Sentry.captureException(error);
      },
      onCompleted: () => {
        onStepCompleteSuccess();
      },
    });

  const formik = useFormik<ProfileProps['candidate']['socialProfile']['formValues']>({
    initialValues: { ...candidateSocialProfile.formValues, ...candidateResume.formValues },
    validationSchema: create_resumeAndProfiles_validationSchema({
      linkedInRequiredMessage:
        candidateWorkExperiences.length > 0
          ? 'Please input your LinkedIn URL or select “skip” below.'
          : 'Please input your LinkedIn URL or go back and add experience',
    }),
    onSubmit: async ({ linkedin_url, github_url, other_url }) => {
      try {
        updateResumeAndSocialProfile({
          variables: {
            candidate_id: candidateID,
            linkedin_url,
            github_url,
            other_url,
          },
        });
      } catch (error: unknown) {
        toast({
          status: 'error',
          description: 'Saving your resume failed. Please try again!',
        });
      }
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const props: MoreInfoStepVariationProps = {
    errors: formik.errors,
    isFinalizeDisabled: isLoading_updateResumeAndSocialProfile,
    onFinalizeClick: formik.handleSubmit,
    onSignOutClick,
    onSkipClick: onStepCompleteSuccess,
    onSocialProfileChange: formik.handleChange,
    progressProps,
    userFullName,
    initialRef,
    values: formik.values,
    touched: formik.touched,
    onBlur: formik.handleBlur,
  };

  if (mode === 'review') {
    return <MoreInfoStep.Variation.Review {...props} />;
  }

  if (candidateWorkExperiences.length > 0) {
    return <MoreInfoStep.Variation.Skippable {...props} />;
  }

  return <MoreInfoStep.Variation.NotSkippable {...props} />;
}
