import type { ComponentProps } from 'react';
import {
  Button,
  CheckboxToggle,
  Wrap,
  DropdownIcon,
  Divider,
  Collapse,
  Flex,
} from '@terminal/design-system';
import type { SkillsProps } from 'candidate/shared/modules';
import { StepPageLayout } from '../../components';

export function SkillsStepTags({
  candidateSelectedSkills,
  isNextDisabled,
  mainSkillOptions,
  moreSkillOptions,
  onNextClick,
  onSkillClick,
  shouldShowFullList,
  stepPageLayoutProps,
  onShowMoreLessClick,
  title = 'What Are Your Top Skills?',
  description = 'To get better job matches, select at least 3 of your top skills.',
}: {
  candidateSelectedSkills: SkillsProps['skillGroups'][number]['skills'][number]['formValues'][];
  isNextDisabled: boolean;
  mainSkillOptions: Omit<SkillsProps['skillChoices'][number], 'roles'>[];
  moreSkillOptions: Omit<SkillsProps['skillChoices'][number], 'roles'>[];
  onNextClick: () => void;
  onSkillClick: (skillID: number) => void;
  shouldShowFullList: boolean;
  stepPageLayoutProps: Pick<
    ComponentProps<typeof StepPageLayout>,
    'progressProps' | 'userFullName' | 'onSignOutClick'
  >;
  onShowMoreLessClick: () => void;
  title?: string;
  description?: string;
}) {
  return (
    <StepPageLayout
      {...stepPageLayoutProps}
      title={title}
      description={description}
      footer={
        <Button onClick={onNextClick} w={['full', 64]} isDisabled={isNextDisabled}>
          Next
        </Button>
      }
    >
      <Flex flexDir="column">
        <Wrap justify="center" shouldWrapChildren spacing={[3, 3, 4]}>
          {mainSkillOptions.map(({ id, name }) => {
            return (
              <CheckboxToggle
                defaultChecked={candidateSelectedSkills.some(({ skillID }) => skillID === id)}
                onChange={() => onSkillClick(id)}
                className="notranslate"
              >
                {name}
              </CheckboxToggle>
            );
          })}
        </Wrap>
        <Collapse in={shouldShowFullList} animateOpacity>
          <Divider my={5} />
          <Wrap justify="center" shouldWrapChildren spacing={[3, 3, 4]}>
            {moreSkillOptions.map(({ id, name }) => {
              return (
                <CheckboxToggle
                  defaultChecked={candidateSelectedSkills.some(({ skillID }) => skillID === id)}
                  onChange={() => onSkillClick(id)}
                  className="notranslate"
                >
                  {name}
                </CheckboxToggle>
              );
            })}
          </Wrap>
        </Collapse>
        <Button
          alignSelf="center"
          mt={[9, 9, 7]}
          rightIcon={
            <DropdownIcon
              fontSize="2xl"
              transitionDuration="250ms"
              transform={`rotate(${shouldShowFullList ? '180deg' : 0})`}
            />
          }
          variant="ghost"
          w="min"
          onClick={onShowMoreLessClick}
        >
          Show {shouldShowFullList ? 'Less' : 'More'}
        </Button>
      </Flex>
    </StepPageLayout>
  );
}
