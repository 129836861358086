/* eslint-disable react/no-unescaped-entities */
import { PublicPageLayout } from 'candidate/components';
import { LocationTypeAhead } from 'global/components';
import type { LocationTypeAheadProps } from 'global/components';
import {
  Box,
  Flex,
  Container,
  Text,
  Heading,
  Link,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Button,
  Image,
  Grid,
} from '@terminal/design-system';
import type { FormikState } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import type { CountryNotSupportedForm } from './CountryNotSupported.types';
import GlobeSVG from './globe.svg?url';

export function CountryNotSupported({
  onJoinWaitListClick,
  onBlur,
  onLocationChange,
  values,
  errors,
  isSubmissionLoading,
  formattedLocation = '',
  touched,
  placeIDToCountryIDMap,
}: {
  onJoinWaitListClick: () => void;
  onBlur: (event: React.FocusEvent<any>) => void;
  onChange: (event: string | React.ChangeEvent<any>) => void;
  onLocationChange: LocationTypeAheadProps['onChange'];
  isSubmissionLoading: boolean;
  formattedLocation?: string;
  placeIDToCountryIDMap: Record<string, number>;
} & Pick<FormikState<CountryNotSupportedForm>, 'errors' | 'values' | 'touched'>) {
  return (
    <PublicPageLayout
      headerRightPlaceholderDesktop={
        <Flex alignItems="center">
          <Text>Don’t Have An Account?</Text>
          <Link as={RouterLink} to="/onboarding" ml={2} fontWeight="bold">
            Sign Up
          </Link>
        </Flex>
      }
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link
            as={RouterLink}
            to={`/auth/login${window.location.search}`}
            ml={2}
            fontWeight="bold"
          >
            Login
          </Link>
        </Flex>
      }
    >
      <Container flex="1" px={[3, 3, 0]} py={0}>
        <Grid gridTemplateColumns={['1fr', '1fr', '1fr 1fr']} h={['auto', 'auto', '100%']}>
          <Flex
            pos="relative"
            bg={['transparent', 'transparent', 'bg.tertiary']}
            alignItems="center"
            justifyContent="center"
            flex="1"
            pr={[0, 0, 12]}
          >
            <Image
              src={GlobeSVG}
              alt="coming soon at your country"
              role="presentation"
              mt={[18, 18, 0]}
            />
            <Box
              display={['none', 'none', 'block']}
              bg="bg.tertiary"
              w="50vw"
              h="full"
              position="absolute"
              top={0}
              right="100%"
            />
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            direction="column"
            flex="1"
            ml={[0, 0, '18%']}
            mt={[6, 6, 0]}
          >
            <Heading variant="heading-2" textAlign={['center', 'center', 'left']}>
              <Heading as="span" variant="heading-2" color="text.success">
                We’re Sorry,
              </Heading>{' '}
              We Aren’t Hiring In Your Country Yet
            </Heading>
            <Text mt={2} textAlign={['center', 'center', 'left']}>
              Let us know where you are located and join our waitlist to be the first to hear about
              new opportunities in your country.
            </Text>

            <FormControl isInvalid={touched.country && !!errors.country}>
              <LocationTypeAhead
                defaultValue={formattedLocation}
                placeIDToCountryIDMap={placeIDToCountryIDMap}
                noOptionsMatchedCopy="Address not found"
                placeholder="Type Location"
                name="country"
                onBlur={onBlur}
                onChange={onLocationChange}
                containerProps={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                }}
                renderBefore={({ getLabelProps }) => (
                  <FormLabel mt={6} {...getLabelProps()}>
                    My Location
                  </FormLabel>
                )}
              />
              <FormErrorMessage>{errors.country}</FormErrorMessage>
            </FormControl>
            <Button
              mt={6}
              maxW="ml"
              w="full"
              onClick={onJoinWaitListClick}
              isLoading={isSubmissionLoading}
              disabled={!!errors.country && !values.country}
            >
              Join Our Waitlist
            </Button>
          </Flex>
        </Grid>
      </Container>
    </PublicPageLayout>
  );
}
