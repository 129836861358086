import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { SelectProfile } from 'candidate/features/profile/graphql';
import { useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import { trackProfile } from 'candidate/features/profile/Profile.utils';
import type { ProfileProps, SelectProfileQuery } from 'candidate/features/profile/Profile.types';
import {
  Role_Choices_Enum,
  Candidate_Source_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import * as Yup from 'yup';
import { UpsertDeleteCandidateRoles } from './data';
import type {
  UpsertDeleteCandidateRolesMutation,
  UpsertDeleteCandidateRolesMutationVariables,
} from './data';

export const RolesValidationSchema = Yup.object().shape({
  candidateRoles: Yup.array()
    .of(
      Yup.object().shape({
        role: Yup.mixed().oneOf(Object.values(Role_Choices_Enum)),
        id: Yup.string().nullable(),
      }),
    )
    .required()
    .min(1, 'At least one selected role is required'),
});

export function useRolesController({
  candidateRoles,
  userID,
  candidateID,
  onModalClose,
  onModalOpen,
  onSaveSuccess,
}: {
  candidateRoles: ProfileProps['candidate']['roles'];
  candidateID: number;
  userID?: number;
  onModalClose?: () => void;
  onModalOpen?: () => void;
  onSaveSuccess?: () => void;
}) {
  const [toDeleteIDs, setToDeleteIDs] = useState<number[]>([]);
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const [upsert, { loading: isLoading_upsert }] = useMutation<
    UpsertDeleteCandidateRolesMutation,
    UpsertDeleteCandidateRolesMutationVariables
  >(UpsertDeleteCandidateRoles, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to update your roles. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
    onCompleted: () => {
      if (onSaveSuccess) {
        onSaveSuccess();
      }
    },
    update(cache, { data: upsertDeleteRoles }) {
      try {
        const readData = cache.readQuery<SelectProfileQuery>({
          query: SelectProfile,
          variables: {
            user_id: userID,
          },
        });

        const newCandidateRoles = (
          upsertDeleteRoles?.insert_candidate_role?.returning || []
        ).filter((role) =>
          upsertDeleteRoles?.delete_candidate_role?.returning.every(({ id }) => role.id !== id),
        );

        const updatedCandidate = {
          ...readData?.candidates[0],
          candidate_roles: newCandidateRoles,
        };

        cache.writeQuery({
          query: SelectProfile,
          broadcast: true,
          variables: {
            user_id: userID as number,
          },
          data: { ...readData, candidates: [updatedCandidate] },
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  const formik = useFormik<{
    candidateRoles: ProfileProps['candidate']['roles'][number]['formValues'][];
  }>({
    initialValues: { candidateRoles: candidateRoles.map(({ formValues }) => formValues) },
    validationSchema: RolesValidationSchema,
    onSubmit: (newRolesValues) => {
      upsert({
        variables: {
          // @ts-ignore
          upsert_objects: newRolesValues.candidateRoles.map((oneRole) => ({
            candidate_id: candidateID,
            role: oneRole.role,
            source: Candidate_Source_Choices_Enum.CandidatePortal,
            years_of_exp: oneRole.years_of_exp || null,
            ...(oneRole.id && { id: Number(oneRole.id) }),
          })),
          delete_candidate_role_ids: toDeleteIDs,
        },
      });
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const hasFormValuesChanged = () =>
    JSON.stringify(formik.initialValues) !== JSON.stringify(formik.values);

  const handleOnClose = () => {
    formik.resetForm();
    if (onModalClose) {
      onModalClose();
    }
    formik.resetForm();
  };

  const handleOnSaveClick = () => {
    if (hasFormValuesChanged()) {
      formik.handleSubmit();
    } else {
      if (onModalClose) {
        onModalClose();
      }
      formik.resetForm();
    }
  };

  const handleOnCheckboxClick = (selectedRole: string) => {
    const selectedRoleID = candidateRoles.find(({ role }) => role.name === selectedRole)?.id;

    if (formik.values.candidateRoles.some((roleInForm) => roleInForm.role === selectedRole)) {
      if (selectedRoleID) {
        setToDeleteIDs([...toDeleteIDs, selectedRoleID]);
      }

      formik.setFieldValue(
        'candidateRoles',
        formik.values.candidateRoles.filter(({ role }) => role !== selectedRole),
      );
    } else {
      setToDeleteIDs(toDeleteIDs.filter((roleID) => roleID !== selectedRoleID));

      formik.setFieldValue('candidateRoles', [
        ...formik.values.candidateRoles,
        { role: selectedRole, id: selectedRoleID },
      ]);
    }
  };

  const handleAddClick = () => {
    formik.resetForm();
    if (onModalOpen) {
      onModalOpen();
    }
    if (!candidateRoles.length) {
      trackProfile('clicked-add-entry', { section: 'Roles', context: 'Card' });
    }
    trackProfile('edit-modal-opened', { section: 'Roles' });
  };

  return {
    formik,
    handleAddClick,
    handleOnCheckboxClick,
    handleOnClose,
    handleOnSaveClick,
    hasFormValuesChanged,
    isLoading_upsert,
  };
}
