import type { FormikState } from 'formik';
import { FormControl, FormLabel, Input, FormErrorMessage, Select } from '@terminal/design-system';
import { Candidate_Curation_Desired_Salary_Currency_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import React from 'react';
import type { DesiredSalaryInformation } from '../../Profile.types';

export function DesiredSalaryForm({
  errors,
  onChange,
  onBlur,
  values,
  touched,
}: {
  onChange: (event: string | React.ChangeEvent<any>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLElement>) => void;
} & Pick<FormikState<DesiredSalaryInformation>, 'errors' | 'values' | 'touched'>) {
  return (
    <>
      <FormControl isInvalid={touched.desiredSalaryCurrency && !!errors.desiredSalaryCurrency}>
        <FormLabel htmlFor="desiredSalaryCurrency">Currency</FormLabel>
        <Select
          flex={1}
          placeholder="Currency"
          name="desiredSalaryCurrency"
          defaultValue={values.desiredSalaryCurrency || undefined}
          onChange={onChange}
          onBlur={onBlur}
          sx={{
            color: values.desiredSalaryCurrency ? 'text.primary' : 'text.secondary',
          }}
        >
          {Object.values(Candidate_Curation_Desired_Salary_Currency_Choices_Enum).map(
            (currency) => (
              <option value={currency} key={`desiredSalaryCurrency-${currency}`}>
                {currency}
              </option>
            ),
          )}
        </Select>
        <FormErrorMessage>{errors.desiredSalaryCurrency}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={touched.desiredSalaryAmount && !!errors.desiredSalaryAmount}>
        <FormLabel htmlFor="desiredSalaryAmount">Annual Salary</FormLabel>
        <Input
          id="desiredSalaryAmount"
          name="desiredSalaryAmount"
          onChange={onChange}
          placeholder="Enter the amount"
          value={values.desiredSalaryAmount || ''}
          type="number"
          inputMode="decimal"
          onBlur={onBlur}
        />
        <FormErrorMessage>{errors.desiredSalaryAmount}</FormErrorMessage>
      </FormControl>
    </>
  );
}
