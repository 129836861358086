import { useEffect, useReducer } from 'react';
import type { Reducer } from 'react';

import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth/authMethods';
import { useToast } from '@terminal/design-system';
import authContext from 'global/authContext';
import { asyncReducer, createAsyncInitialState, toAsyncProps } from 'global/utils';
import { useHandleAuthBackendError } from '../auth.utils';
import { EmailNotVerifiedWarningPage } from './EmailNotVerifiedWarningPage';
import { EmailNotVerifiedResendSuccessPage } from './EmailNotVerifiedResendSuccessPage';
import type { AuthenticationRouterOverwrite } from '../types';

export function EmailNotVerifiedController({
  overwrite,
}: {
  overwrite?: AuthenticationRouterOverwrite;
}) {
  const [state, dispatch] = useReducer<Reducer<AsyncState<null>, AsyncAction<null>>>(
    asyncReducer,
    createAsyncInitialState(null),
  );

  const { isLoading, isError, isResolved } = toAsyncProps(state);

  useHandleAuthBackendError({
    isError,
    error: state.error,
    throwIfNoFriendlyError: true,
    errorsWithFriendlyMessage: {
      'no-code': 'There is not code in your email validation link',
      'auth/expired-action-code': 'The validation link has expired. Please request a new one.',
      'auth/invalid-action-code': 'The validation link is not valid. Please request a new one.',
    },
  });

  const toast = useToast({
    position: 'top',
    duration: 6000,
  });

  const handleSendVerifyEmail = async () => {
    try {
      dispatch({ type: 'pending' });
      const url = `${import.meta.env.REACT_APP_AUTH_URL}/user/verify-email`;
      const { headers } = await authContext(firebaseAuth, 'web', 'verify');
      const result = await fetch(url, {
        method: 'POST',
        headers: { ...headers, 'Content-Type': 'application/json' },
        body: JSON.stringify({
          url: `${window.location.origin}${
            overwrite?.attrs?.['email-not-verified']?.generateContinueURL?.() ||
            '/auth/?utm_source=email-verification&utm_medium=email'
          }`,
        }),
      });

      if (result.ok) {
        dispatch({ type: 'resolved', data: null });
      } else {
        const { error } = await result.json();
        const err = new Error(error) as Error & { code?: string };
        err.code = error?.includes?.('TOO_MANY_ATTEMPTS_TRY_LATER')
          ? 'auth/too-many-requests'
          : error?.code;

        throw err;
      }
    } catch (e) {
      dispatch({ type: 'rejected', error: e });
    }
  };

  useEffect(() => {
    const errorsWithFriendlyMessage: { [key: string]: string } = {
      'auth/too-many-requests':
        'Validation email has been already been sent. Please check your email!',
    };

    if (isError) {
      const friendlyError = errorsWithFriendlyMessage[state.error?.code || ''];

      if (friendlyError) {
        toast({
          status: 'error',
          description: friendlyError,
        });
      } else {
        // throw error so that Error boundary can catch it and log it in sentry
        throw state.error;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.error, isError]);

  if (isResolved) {
    return (
      <EmailNotVerifiedResendSuccessPage
        pageTemplateOverwrite={
          overwrite?.pageTemplate?.['email-not-verified-success'] ||
          overwrite?.pageTemplate?.default
        }
      />
    );
  }

  return (
    <EmailNotVerifiedWarningPage
      isLoading={isLoading}
      onLogoutClick={() => {
        signOut({ auth: firebaseAuth });
      }}
      onResendVerificationEmailClick={handleSendVerifyEmail}
      pageTemplateOverwrite={
        overwrite?.pageTemplate?.['email-not-verified'] || overwrite?.pageTemplate?.default
      }
    />
  );
}
