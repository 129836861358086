import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import { cleanFilename, createUserTrackingInfo } from 'global/utils';
import Sentry from 'global/sentry';
import * as events from 'global/events';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createStorageRef, uploadBytes } from 'global/firebaseApp';
import { useToast } from '@terminal/design-system';
import type { HStepsProps } from 'candidate/components';
import * as Yup from 'yup';
import { UpdateCandidateResumeAndSocialProfile } from 'candidate/shared/modules/onboarding/data';
import { createResumeFileYupValidation } from 'candidate/shared/modules/resume';
import type {
  UpdateCandidateResumeAndSocialProfileMutation,
  UpdateCandidateResumeAndSocialProfileMutationVariables,
} from 'candidate/shared/modules/onboarding/data';
import { useGoogleDrivePicker } from 'global/hooks';
import { ResumeParsingFailed } from './ResumeParsingFailed';
import { ResumeParsingLoading } from './ResumeParsingLoading';
import { UploadResumeForm } from './UploadResumeForm';
import { UploadResumeShowAttachedFile } from './UploadResumeShowAttachedFile';
import { ParseCandidateResume, InsertParsedResume } from '../../../../modules/resume';
import type {
  ParseCandidateResumeMutation,
  ParseCandidateResumeMutationVariables,
  InsertParsedResumeMutation,
  InsertParsedResumeMutationVariables,
} from '../../../../modules/resume';

export function ResumeParsingController({
  candidateID,
  onStepCompleteSuccess,
  onSignOutClick,
  userFullName,
  progressProps,
  publicID,
}: {
  candidateID: number;
  onStepCompleteSuccess: (hasParsedResume: boolean) => void;
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  progressProps: HStepsProps;
  publicID: string | null;
}) {
  const initialRef = useRef(null);
  const [resumeParsingError, setResumeParsingError] = useState<ApolloError | null>(null);
  const [isResumeSaving, setIsResumeSaving] = useState<boolean>(false);

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const [is_fake_parse_resume_loading, set_is_fake_parse_resume_loading] = useState<boolean>(false);

  /**
   * This fake loading succeeds after 5 seconds of celery worker returning the worked ID.
   */
  const init_fake_resume_parsing_loading = useCallback(() => {
    const timer = setTimeout(() => {
      set_is_fake_parse_resume_loading(false);
      onStepCompleteSuccess(true);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [onStepCompleteSuccess, set_is_fake_parse_resume_loading]);

  const [parseCandidateResume] = useMutation<
    ParseCandidateResumeMutation,
    ParseCandidateResumeMutationVariables
  >(ParseCandidateResume, {
    context: {
      role: 'candidate',
      service: 'service',
    },
    onError: (error) => {
      Sentry.captureException(error);
      setResumeParsingError(error);
    },
    onCompleted: () => {
      events.track(events.name.resume.parse.success, {
        ...createUserTrackingInfo(),
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        file_extension: formik.values.file?.name.split('.').pop(),
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        file_size: formik.values.file?.size,
      });

      init_fake_resume_parsing_loading();
    },
  });

  const [insert_parsed_resume] = useMutation<
    InsertParsedResumeMutation,
    InsertParsedResumeMutationVariables
  >(InsertParsedResume, {
    context: {
      role: 'candidate',
    },
  });

  const [updateResumeAndSocialProfile] = useMutation<
    UpdateCandidateResumeAndSocialProfileMutation,
    UpdateCandidateResumeAndSocialProfileMutationVariables
  >(UpdateCandidateResumeAndSocialProfile, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to update your resume. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);

      events.track(events.name.errorDisplayed, {
        ...createUserTrackingInfo(),
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        file_extension: formik.values.file?.name.split('.').pop(),
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        file_size: formik.values.file?.size,
        type: 'resume-parse-error',
        error,
      });
    },
    onCompleted: () => {
      setIsResumeSaving(false);
      set_is_fake_parse_resume_loading(true);
      parseCandidateResume({ variables: { candidateID } });

      try {
        // This only ensures that the user will see the resume parsing loading bar when they land into home page even if the celery task
        // hasn't yet started. As this block is only important for UX, its places inside a try-catch to not block the user if it fails
        insert_parsed_resume({
          variables: {
            object: {
              candidate_id: candidateID,
            },
          },
        });
      } catch (error: unknown) {
        Sentry.captureException(error);
      }
    },
  });

  const handleFileSubmission = async ({ file }: { file: File }) => {
    try {
      events.track(events.name.resume.parse.start, {
        ...createUserTrackingInfo(),
        file_extension: file?.name.split('.').pop(),
        file_size: file?.size,
      });

      const newFilename = file ? cleanFilename(file.name) : null;

      if (file) {
        setIsResumeSaving(true);

        const resumeRef = createStorageRef(`/candidate/resume/${publicID}/${newFilename}`);
        await uploadBytes(resumeRef, file);

        updateResumeAndSocialProfile({
          variables: {
            candidate_id: candidateID,
            resume_filename: newFilename,
          },
        });
      }
    } catch (error: unknown) {
      toast({
        status: 'error',
        description: 'Saving your resume failed. Please try again!',
      });

      Sentry.captureException(error);

      events.track(events.name.errorDisplayed, {
        ...createUserTrackingInfo(),
        file_extension: file?.name.split('.').pop(),
        file_size: file?.size,
        type: 'resume-parse-error',
        error,
      });
    }
  };

  const formik = useFormik<{ file: File | null }>({
    initialValues: { file: null },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      file: createResumeFileYupValidation(),
    }),
    onSubmit: ({ file }) => {
      if (file) handleFileSubmission({ file });
    },
  });
  const {
    isLoading: isLoading_googlePicker,
    isReady: isReady_googlePicker,
    onClick: onGoogleDrivePickerClick,
  } = useGoogleDrivePicker({
    onFilePicked: (file) => {
      events.track(events.name.resume.attach.start, {
        ...createUserTrackingInfo(),
        file_extension: file.name.split('.').pop(),
        file_size: file?.size,
        resume_source: 'Google Drive',
      });

      formik.setFieldValue('file', file);
      handleFileSubmission({ file });
    },
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to get your file. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    if (formik.values.file) {
      events.track(events.name.resume.attach.start, {
        ...createUserTrackingInfo(),
        file_extension: formik.values.file.name.split('.').pop(),
        file_size: formik.values.file?.size,
        resume_source: 'Device',
      });
    }
  }, [formik.values.file]);

  useEffect(() => {
    if (formik.errors.file) {
      events.track(events.name.errorDisplayed, {
        ...createUserTrackingInfo(),
        file_extension: formik.values.file?.name.split('.').pop(),
        file_size: formik.values.file?.size,
        type: 'resume-invalid-entry',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors.file]);

  const handleOnFileChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.files && target.files.length > 0) {
      formik.setFieldValue('file', target.files[0]);
    }
  };

  const handleOnDeleteClick = () => {
    formik.setFieldValue('file', null);
  };

  const handleOnUploadClick = () => {
    // @ts-ignore
    initialRef.current.click();
  };

  const handleTryAgain = () => {
    formik.setFieldValue('file', null);
    setResumeParsingError(null);
    set_is_fake_parse_resume_loading(false);
  };

  if (resumeParsingError) {
    return (
      <ResumeParsingFailed
        progressProps={progressProps}
        onSignOutClick={onSignOutClick}
        userFullName={userFullName}
        onEnterInfoManuallyClicked={() => onStepCompleteSuccess(false)}
        onTryAgainClick={handleTryAgain}
      />
    );
  }

  if (is_fake_parse_resume_loading) {
    return (
      <ResumeParsingLoading
        progressProps={progressProps}
        onSignOutClick={onSignOutClick}
        userFullName={userFullName}
      />
    );
  }

  if (formik.values.file && !formik.errors.file) {
    return (
      <UploadResumeShowAttachedFile
        fileName={formik.values.file?.name}
        initialRef={initialRef}
        isNextLoading={isResumeSaving}
        onDeleteClick={handleOnDeleteClick}
        onNextClick={formik.handleSubmit}
        onSkipClick={() => onStepCompleteSuccess(false)}
        progressProps={progressProps}
        onSignOutClick={onSignOutClick}
        userFullName={userFullName}
      />
    );
  }

  return (
    <UploadResumeForm
      progressProps={progressProps}
      initialRef={initialRef}
      onFileChange={handleOnFileChange}
      onUploadClick={handleOnUploadClick}
      isInvalid={!!formik.errors.file}
      inputErrorText={formik.errors.file}
      onEnterInfoManuallyClicked={() => onStepCompleteSuccess(false)}
      onSignOutClick={onSignOutClick}
      userFullName={userFullName}
      onGoogleDrivePickerClick={onGoogleDrivePickerClick}
      isGoogleDriveLoading={isLoading_googlePicker}
      isGoogleDriveDisabled={!isReady_googlePicker}
    />
  );
}
