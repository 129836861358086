import { Candidate_Source_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import type { SelectCandidateInsiderInfoQuery } from './types';

function createFixture_SelectCandidateInsiderInfo_applicantJob(
  overwrite: Partial<
    SelectCandidateInsiderInfoQuery['candidate'][number]['applicant_jobs'][number]
  > = {},
): SelectCandidateInsiderInfoQuery['candidate'][number]['applicant_jobs'][number] {
  return { icims_profile_job: { id: 1 }, ...overwrite };
}

function createFixture_SelectCandidateInsiderInfo_candidateJob(
  overwrite: Partial<
    SelectCandidateInsiderInfoQuery['candidate'][number]['candidate_jobs'][number]
  > = {},
): SelectCandidateInsiderInfoQuery['candidate'][number]['candidate_jobs'][number] {
  return { icims_profile_job: { id: 1 }, ...overwrite };
}

function createFixture_SelectCandidateInsiderInfo_candidate(
  overwrite: Partial<SelectCandidateInsiderInfoQuery['candidate'][number]> = {},
): SelectCandidateInsiderInfoQuery['candidate'][number] {
  return {
    profile_flow_completed_at: null,
    profile_flow_started_at: null,
    applicant_jobs: [],
    candidate_jobs: [],
    dayforce_employee: null,
    source: Candidate_Source_Choices_Enum.CandidatePortal,
    ...overwrite,
  };
}

/**
 * Create Fixture for SelectCandidateInsiderInfo query
 * Use buildFixture_SelectCandidateInsiderInfo_candidate to create candidate items
 */
function createFixture_SelectCandidateInsiderInfo_result(
  overwrite: Partial<SelectCandidateInsiderInfoQuery> = {},
): SelectCandidateInsiderInfoQuery {
  return {
    candidate: [createFixture_SelectCandidateInsiderInfo_candidate()],
    ...overwrite,
  };
}

export const SelectCandidateInsiderInfoFixture = {
  createResult: createFixture_SelectCandidateInsiderInfo_result,
  createCandidate: createFixture_SelectCandidateInsiderInfo_candidate,
  createApplicantJob: createFixture_SelectCandidateInsiderInfo_applicantJob,
  createCandidateJob: createFixture_SelectCandidateInsiderInfo_candidateJob,
  results: {
    /** When candidates have not started on boarding
     * @returns
     * ```
     * [{
     *    candidate: {
     *      profile_flow_completed_at: null,
     *      profile_flow_started_at: null
     *    }
     * }]
     * ```
     */
    'not-started': createFixture_SelectCandidateInsiderInfo_result(),

    /** When candidates have started on boarding, but have not completed it yet
     *  ```
     * [{
     *    candidate: {
     *      profile_flow_started_at: '2021-02-22'
     *      profile_flow_completed_at: null,
     *    }
     * }]
     * ```
     */
    'started-not-completed': createFixture_SelectCandidateInsiderInfo_result({
      candidate: [
        createFixture_SelectCandidateInsiderInfo_candidate({
          profile_flow_started_at: '2021-02-22',
          profile_flow_completed_at: null,
        }),
      ],
    }),

    /** When candidates have started on boarding and completed it
     *  ```
     * [{
     *    candidate: {
     *      profile_flow_started_at: '2021-02-22'
     *      profile_flow_completed_at: '2021-02-23',
     *    }
     * }]
     * ```
     */
    'started-completed': createFixture_SelectCandidateInsiderInfo_result({
      candidate: [
        createFixture_SelectCandidateInsiderInfo_candidate({
          profile_flow_started_at: '2021-02-22',
          profile_flow_completed_at: '2021-02-23',
        }),
      ],
    }),
  },
};
