import { useRef, useState } from 'react';
import type { ComponentProps } from 'react';
import { useFormik } from 'formik';
import { validators } from 'global/utils';
import { createResumeFileYupValidation } from 'candidate/shared/modules/resume';
import * as Yup from 'yup';
import type { SkillsProps } from 'candidate/shared/modules';
import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { ResumeAndExperience } from './ResumeAndExperience';
import type { FormikSubmitHandler } from '../../DirectApplication.types';
import { Section } from '../../DirectApplication.types';
import { serializerForResumeAndExperience } from './ResumeAndExperience.serializer';

export type ResumeAndExperienceForm = {
  resume: File | null;
  linkedin: string;
  yoe?: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum;
  candidateSkills: SkillsProps['skillGroups'][number]['skills'][number]['formValues'][];
};

export const validationSchema = Yup.object().shape(
  {
    resume: createResumeFileYupValidation().when('linkedin', {
      is: (linkedin: string) => !linkedin || linkedin.length === 0,
      then: Yup.mixed().required('At least one of the resume and LinkedIn must be provided.'),
      otherwise: Yup.mixed(),
    }),
    linkedin: Yup.string()
      .matches(validators.linkedIn.validURL.regex, validators.linkedIn.validURL.message)
      .when('resume', {
        is: (resume: string) => !resume,
        then: Yup.string().required('At least one of the resume and LinkedIn must be provided.'),
        otherwise: Yup.string(),
      }),
    yoe: Yup.string().required('Year of Experience is required'),
    candidateSkills: Yup.array().min(3, 'Please, select at least three skills').required(),
  },
  [['resume', 'linkedin']],
);

export function ResumeAndExperienceController({
  isOpened,
  onSectionBoxEditClick,
  sectionSubmitButtonIsLoading,
  skillChoices,
  isCompleted,
  onComplete,
  yearsOfExperienceRangeChoices,
}: {
  sectionSubmitButtonIsLoading: boolean;
  skillChoices: Omit<SkillsProps['skillChoices'][number], 'roles'>[];
  onComplete: FormikSubmitHandler<ResumeAndExperienceForm>;
} & Pick<
  ComponentProps<typeof ResumeAndExperience>,
  'isOpened' | 'onSectionBoxEditClick' | 'isCompleted' | 'yearsOfExperienceRangeChoices'
>) {
  const resumeFileBrowseRef = useRef(null);

  const { mainSkillOptions, moreSkillOptions } = serializerForResumeAndExperience({ skillChoices });

  const formik = useFormik<ResumeAndExperienceForm>({
    initialValues: {
      resume: null,
      linkedin: '',
      yoe: undefined,
      candidateSkills: [],
    },
    validationSchema,
    onSubmit: onComplete,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOnFileChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.files && target.files.length > 0) {
      formik.setFieldValue('resume', target.files[0]);
    }
  };

  const handleOnUploadClick = () => {
    // @ts-ignore
    resumeFileBrowseRef.current.click();
  };

  const handleOnDeleteClick = () => {
    formik.setFieldValue('resume', null);
  };

  const [wasShowMoreClicked, setWasShowMoreClicked] = useState(false);

  const handle_onShowMoreOrLessSkillsClick = () => {
    setWasShowMoreClicked(!wasShowMoreClicked);
  };

  const addSkillToValues = ({
    id,
    name,
  }: {
    id: SkillsProps['skillGroups'][number]['skills'][number]['formValues']['skillID'];
    name: SkillsProps['skillGroups'][number]['skills'][number]['formValues']['name'];
  }) => {
    formik.setValues({
      ...formik.values,
      candidateSkills: [
        ...formik.values.candidateSkills,
        {
          candidateSkillID: '0',
          competency: null,
          is_favorite: false,
          name,
          skillID: id,
          years_of_exp: null,
        },
      ],
    });
  };

  const removeSkillFromValues = (
    id: SkillsProps['skillGroups'][number]['skills'][number]['formValues']['skillID'],
  ) => {
    const newCandidateSkills: SkillsProps['skillGroups'][number]['skills'][number]['formValues'][] =
      formik.values.candidateSkills.reduce(
        (
          valuesToSet: SkillsProps['skillGroups'][number]['skills'][number]['formValues'][],
          currentSkill,
        ) => {
          return currentSkill.skillID !== id ? [...valuesToSet, currentSkill] : valuesToSet;
        },
        [],
      );

    formik.setValues({ ...formik.values, candidateSkills: newCandidateSkills });
  };

  const hanldeOnSkillClick = (selectedSkillId: number) => () => {
    const isSkillInValues = formik.values.candidateSkills.find(
      ({ skillID }) => skillID === selectedSkillId,
    );
    const selectedSkill = skillChoices.find(({ id }) => id === selectedSkillId);

    if (isSkillInValues && selectedSkill) {
      removeSkillFromValues(selectedSkill.id);
    } else if (selectedSkill) {
      addSkillToValues(selectedSkill);
    }
  };

  return (
    <ResumeAndExperience
      yearsOfExperienceRangeChoices={yearsOfExperienceRangeChoices}
      section={Section.experience}
      onSectionBoxEditClick={onSectionBoxEditClick}
      isOpened={isOpened}
      resumeFileBrowseRef={resumeFileBrowseRef}
      onNextClick={formik.handleSubmit}
      errors={formik.errors}
      values={formik.values}
      touched={formik.touched}
      onBlur={formik.handleBlur}
      sectionSubmitButtonIsLoading={sectionSubmitButtonIsLoading}
      onChange={formik.handleChange}
      onResumeFileChange={handleOnFileChange}
      onResumeUploadClick={handleOnUploadClick}
      onResumeDeleteClick={handleOnDeleteClick}
      skillsOptions={
        wasShowMoreClicked ? [...mainSkillOptions, ...moreSkillOptions] : mainSkillOptions
      }
      onShowMoreOrLessSkillsClick={handle_onShowMoreOrLessSkillsClick}
      shouldDisplayShowMore={!wasShowMoreClicked}
      isCompleted={isCompleted}
      hanldeOnSkillClick={hanldeOnSkillClick}
      sectionSubmitButtonIsDisabled={!formik.isValid}
    />
  );
}
