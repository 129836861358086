export const mainSkills = [
  '.NET',
  'Angular',
  'AWS',
  'C/C++',
  'CSS',
  'Django',
  'Docker',
  'Git',
  'HTML',
  'Java',
  'Javascript',
  'JQuery',
  'MySQL',
  'Node',
  'NoSQL',
  'Python',
  'React',
  'Ruby',
  'SQL',
  'Typescript',
];
export const moreSkills = [
  'Android',
  'Azure',
  'Bootstrap',
  'C#',
  'Drupal',
  'Flask',
  'GCP',
  'GraphQL',
  'iOS',
  'Jenkins',
  'Kubernetes',
  'Laravel',
  'Linux',
  'Microservices',
  'MongoDB',
  'Objective-C',
  'Oracle',
  'PHP',
  'Postgres',
  'R',
  'React Native',
  'Redux',
  'REST API',
  'Ruby on Rails',
  'Selenium',
  'Spring',
  'Swift',
  'Terraform',
  'Unix',
  'Vue.js',
  'Golang',
];
