import { EditModal } from '../../components';
import type { ProfileProps } from '../../Profile.types';
import {
  EducationForm,
  EducationsSection,
  useEducationsController,
} from '../../modules/educations';

export function EducationsController({
  educations,
  userID,
  candidateID,
  degreeChoices,
  isModalOpen,
  onModalClose,
  onModalOpen,
}: {
  educations: ProfileProps['candidate']['educations'];
  candidateID: number;
  userID?: number;
  degreeChoices: ProfileProps['degreeChoices'];
  isModalOpen: boolean;
  onModalClose: () => void;
  onModalOpen: () => void;
}) {
  const {
    deleteEducation,
    editID,
    formik,
    handleAddClick,
    handleEditClick,
    handleOnClose,
    handleOnSaveClick,
    hasFormValuesChanged,
    initialRef,
    isLoading_delete,
    isLoading_upsert,
  } = useEducationsController({
    onModalOpen,
    onModalClose,
    educations,
    userID,
    candidateID,
  });

  return (
    <>
      <EducationsSection
        educations={educations}
        onAddClick={handleAddClick}
        onEditClick={handleEditClick}
      />
      <EditModal
        isOpen={isModalOpen}
        onClose={handleOnClose}
        initialRef={initialRef}
        shouldConfirmClose={hasFormValuesChanged()}
        showLoadingForSaveButton={isLoading_upsert}
        showLoadingForDeleteButton={isLoading_delete}
        onSaveClick={handleOnSaveClick}
        OnDeleteClick={() => {
          deleteEducation({
            variables: { id: editID || 0 },
          });
        }}
        showDeleteButton={editID != null}
        title={editID != null ? 'Edit Education' : 'Add Education'}
      >
        <EducationForm
          degreeOptions={degreeChoices}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          initialRef={initialRef}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </EditModal>
    </>
  );
}
