import { Box, Button } from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';

export function AccountCreatedSuccess({ onClick }: { onClick: () => void }) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <Box width={isDesktop ? 'ml' : 'full'} px={3}>
      <Button marginTop="14" onClick={onClick} width="full">
        Explore My Terminal Homepage
      </Button>
    </Box>
  );
}
