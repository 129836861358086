import type { FormikState } from 'formik';
import type { ChangeEvent, FocusEvent } from 'react';
import type { ProfileProps } from '../../../../../Profile.types';
import { EducationForm, EducationsSection } from '../../../../../modules/educations';
import { EditModal } from '../../../../../components';

export function Educations({
  degreeChoices,
  editID,
  educations,
  errors,
  initialRef,
  isModalOpen,
  onAddClick,
  onBlur,
  onChange,
  onCloseModalClick,
  onDeleteClick,
  onEditClick,
  onSaveClick,
  shouldAskConfirmationOnModalClose,
  showLoadingForDeleteButton,
  showLoadingForSaveButton,
  values,
  touched,
}: {
  degreeChoices: ProfileProps['degreeChoices'];
  educations: ProfileProps['candidate']['educations'];
  initialRef?: React.MutableRefObject<null>;
  onAddClick: () => void;
  onBlur: (event: FocusEvent<any>) => void;
  onChange: (event: string | ChangeEvent<any>) => void;
  editID: number | null;
  isModalOpen: boolean;
  onCloseModalClick: () => void;
  onDeleteClick: (deleteID: number) => void;
  onEditClick: (
    values: ProfileProps['candidate']['educations'][number]['formValues'],
    editID: number,
  ) => void;
  onSaveClick: () => void;
  shouldAskConfirmationOnModalClose: boolean;
  showLoadingForDeleteButton: boolean;
  showLoadingForSaveButton: boolean;
} & Pick<
  FormikState<ProfileProps['candidate']['educations'][number]['formValues']>,
  'values' | 'errors' | 'touched'
>) {
  return (
    <>
      <EducationsSection
        educations={educations}
        onAddClick={onAddClick}
        onEditClick={onEditClick}
      />
      <EditModal
        isOpen={isModalOpen}
        onClose={onCloseModalClick}
        initialRef={initialRef}
        shouldConfirmClose={shouldAskConfirmationOnModalClose}
        showLoadingForSaveButton={showLoadingForSaveButton}
        showLoadingForDeleteButton={showLoadingForDeleteButton}
        onSaveClick={onSaveClick}
        OnDeleteClick={() => {
          onDeleteClick(editID || 0);
        }}
        showDeleteButton={editID != null}
        title={editID != null ? 'Edit Education' : 'Add Education'}
      >
        <EducationForm
          degreeOptions={degreeChoices}
          values={values}
          errors={errors}
          touched={touched}
          initialRef={initialRef}
          onChange={onChange}
          onBlur={onBlur}
        />
      </EditModal>
    </>
  );
}
