import { Icon } from '@terminal/design-system';
import type { FlagIconProp } from './utils';

export function HungaryFlagIcon({ isColored, boxSize = 8, ...rest }: FlagIconProp) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 34 34" fill="none" boxSize={boxSize} {...rest}>
        <path
          d="M1 2.5L10 1L17 3.5L25.5 5L32.5 3L33 11.5L23 12.5L16.5 9.5L9 8.5L1 11.5V2.5Z"
          fill="#F6623A"
        />
        <path
          d="M1 11.5L10 8.5L17.5 11L25.5 13L32.5 11.5V20.5L23 21.5L16.5 18.5L9 17.5L1 19.5V11.5Z"
          fill="white"
        />
        <path
          d="M1.5 19.5L10 18L16.5 19.5L25.5 22L32.5 20V27L23 29.5L16.5 26.5L10 25L1.5 26.5V19.5Z"
          fill="#7AA550"
        />
        <path
          d="M1 33V3"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 27.0566L5.48569 25.7633C9.24478 24.6783 13.3202 25.1641 16.67 27.0966C19.9385 28.9818 23.9014 29.4927 27.5913 28.5046L31.8395 27.3673C32.5262 27.1829 32.9997 26.5943 33 25.9246V4.84861C32.9995 4.37808 32.7638 3.93497 32.3631 3.65156C31.9625 3.36815 31.4433 3.27723 30.9605 3.40594L27.5913 4.30861C23.9014 5.29668 19.9385 4.78576 16.67 2.90061C13.3202 0.968147 9.24478 0.482295 5.48569 1.56727L1 2.85927"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.11283 17.5956C10.1291 16.457 14.4847 16.9652 18.072 18.9981C21.2259 20.7851 25.0585 21.2725 28.6287 20.3333L32.0583 19.4306C32.4589 19.3252 32.8691 19.5645 32.9745 19.9651C33.0799 20.3656 32.8407 20.7758 32.4401 20.8812L29.0104 21.7839L29.0103 21.7839C25.0683 22.8209 20.8327 22.2864 17.3324 20.3031L17.3324 20.3031C14.0998 18.4712 10.1584 18.0077 6.52161 19.0388L6.52122 19.0389L1.955 20.3309C1.55643 20.4437 1.14191 20.212 1.02913 19.8135C0.916361 19.4149 1.14804 19.0004 1.54661 18.8876L6.11243 17.5957C6.11249 17.5957 6.11256 17.5957 6.11262 17.5957C6.11269 17.5956 6.11276 17.5956 6.11283 17.5956Z"
          fill="#25383F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.12565 8.59562C10.1419 7.45697 14.4975 7.96518 18.0848 9.99811C21.2387 11.7851 25.0713 12.2725 28.6415 11.3333L32.0711 10.4306C32.4717 10.3252 32.8819 10.5645 32.9873 10.9651C33.0928 11.3656 32.8535 11.7758 32.4529 11.8812L29.0232 12.7839L29.0231 12.7839C25.0811 13.8209 20.8455 13.2864 17.3453 11.3031L17.3452 11.3031C14.1127 9.4712 10.1712 9.00767 6.53443 10.0388L6.53403 10.039L1.96781 11.331C1.56925 11.4437 1.15473 11.212 1.04195 10.8135C0.929179 10.4149 1.16086 10.0004 1.55943 9.88761L6.12525 8.59573C6.12531 8.59571 6.12537 8.59569 6.12543 8.59568C6.1255 8.59566 6.12558 8.59564 6.12565 8.59562Z"
          fill="#25383F"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 34 34" fill="none" boxSize={boxSize} {...rest}>
      <path
        d="M1 33V3"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 27.0566L5.48569 25.7633C9.24478 24.6783 13.3202 25.1641 16.67 27.0966C19.9385 28.9818 23.9014 29.4927 27.5913 28.5046L31.8395 27.3673C32.5262 27.1829 32.9997 26.5943 33 25.9246V4.84861C32.9995 4.37808 32.7638 3.93497 32.3631 3.65156C31.9625 3.36815 31.4433 3.27723 30.9605 3.40594L27.5913 4.30861C23.9014 5.29668 19.9385 4.78576 16.67 2.90061C13.3202 0.968147 9.24478 0.482295 5.48569 1.56727L1 2.85927"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.11283 17.5956C10.1291 16.457 14.4847 16.9652 18.072 18.9981C21.2259 20.7851 25.0585 21.2725 28.6287 20.3333L32.0583 19.4306C32.4589 19.3252 32.8691 19.5645 32.9745 19.9651C33.0799 20.3656 32.8407 20.7758 32.4401 20.8812L29.0104 21.7839L29.0103 21.7839C25.0683 22.8209 20.8327 22.2864 17.3324 20.3031L17.3324 20.3031C14.0998 18.4712 10.1584 18.0077 6.52161 19.0388L6.52122 19.0389L1.955 20.3309C1.55643 20.4437 1.14191 20.212 1.02913 19.8135C0.916361 19.4149 1.14804 19.0004 1.54661 18.8876L6.11243 17.5957C6.11249 17.5957 6.11256 17.5957 6.11262 17.5957C6.11269 17.5956 6.11276 17.5956 6.11283 17.5956Z"
        fill="#25383F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12565 8.59562C10.1419 7.45697 14.4975 7.96518 18.0848 9.99811C21.2387 11.7851 25.0713 12.2725 28.6415 11.3333L32.0711 10.4306C32.4717 10.3252 32.8819 10.5645 32.9873 10.9651C33.0928 11.3656 32.8535 11.7758 32.4529 11.8812L29.0232 12.7839L29.0231 12.7839C25.0811 13.8209 20.8455 13.2864 17.3453 11.3031L17.3452 11.3031C14.1127 9.4712 10.1712 9.00767 6.53443 10.0388L6.53403 10.039L1.96781 11.331C1.56925 11.4437 1.15473 11.212 1.04195 10.8135C0.929179 10.4149 1.16086 10.0004 1.55943 9.88761L6.12525 8.59573C6.12531 8.59571 6.12537 8.59569 6.12543 8.59568C6.1255 8.59566 6.12558 8.59564 6.12565 8.59562Z"
        fill="#25383F"
      />
    </Icon>
  );
}
