import { Icon } from '@terminal/design-system';

export function DesignerIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <path
          d="m15.394 30.896h-5.8314c-3.2206 0-5.8313-2.6653-5.8313-5.9533v-11.907h17.494v11.907c0 3.288-2.6108 5.9533-5.8313 5.9533z"
          clipRule="evenodd"
          fill="#EFD58B"
          fillRule="evenodd"
        />
        <path
          d="m2.759 1.1299c3.3692 3.0838 2.5593 1.8389 4.3074 2.9886 0.44624 0.30236 0.79833 0.72884 1.0146 1.229 0.4326 0.97176 0.32243 2.1056-0.28876 2.9716-0.61118 0.86605-1.6298 1.3317-2.6696 1.2204-1.0398-0.1113-1.9417-0.7825-2.3637-1.7591-1.012-2.0965-1.012-4.5541 0-6.6505z"
          clipRule="evenodd"
          fill="#A0CFDA"
          fillRule="evenodd"
        />
        <path
          d="m15.077 6.5v-1.0453l4.4149-4.3512 0.7607 6.2153-0.7607 1.1813"
          clipRule="evenodd"
          fill="#8FA25B"
          fillRule="evenodd"
        />
        <path
          d="m2.759 1.1299c3.3692 3.0838 2.5593 1.8389 4.3074 2.9886 0.44624 0.30236 0.79833 0.72884 1.0146 1.229 0.4326 0.97176 0.32243 2.1056-0.28876 2.9716-0.61118 0.86605-1.6298 1.3317-2.6696 1.2204-1.0398-0.1113-1.9417-0.7825-2.3637-1.7591-1.012-2.0965-1.012-4.5541 0-6.6505z"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m5.4375 9.54 1.2064 3.4966"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m15.394 30.896h-5.8313c-3.2206 0-5.8313-2.6653-5.8313-5.9533v-11.907h17.494v11.907c0 3.288-2.6108 5.9533-5.8314 5.9533v0z"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m23.169 24.943h-1.9438v-11.907h1.9438c3.2205 0 5.8313 2.6654 5.8313 5.9533v0c0 3.2879-2.6108 5.9533-5.8313 5.9533v0z"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m8.0448 7.8612 3.1463 5.1754"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m12.456 13.037 2.6215-7.5819 4.4149-4.3512 0.7607 6.2153-1.9762 5.7179"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m19.948 8.1945c-1.9396 0.62226-4.0486-0.13787-5.1757-1.8654"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </Icon>
    );
  }
  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.759 1.13c3.37 3.084 2.56 1.839 4.307 2.988.447.303.799.73 1.015 1.23.433.971.322 2.105-.289 2.971-.61.866-1.63 1.332-2.67 1.22-1.04-.11-1.941-.782-2.363-1.759-1.012-2.096-1.012-4.554 0-6.65z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.438 9.54l1.206 3.497"
      />
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.394 30.897H9.562c-3.22 0-5.831-2.666-5.831-5.954V13.037h17.494v11.906c0 3.288-2.61 5.954-5.831 5.954v0zM23.169 24.943h-1.944V13.037h1.944c3.22 0 5.831 2.665 5.831 5.953v0c0 3.288-2.61 5.953-5.831 5.953v0z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.045 7.861l3.146 5.176M12.456 13.037l2.621-7.582 4.415-4.352.761 6.216-1.976 5.718M19.948 8.195c-1.94.622-4.048-.138-5.175-1.866"
      />
    </Icon>
  );
}
