import { Icon } from '@terminal/design-system';

export function AIMLIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <g
          clipPath="url(#ai-ml-clip1)"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M10.037 30.574v-3.583H8.25a3.584 3.584 0 0 1-3.583-3.585v-3.583H2.392a.904.904 0 0 1-.836-1.218C4.597 10.72 6.44 3.697 14.966 3.697" />
          <path d="M12.4 7.46h6.79a1.509 1.509 0 0 0 1.509-1.51v-.753a1.508 1.508 0 0 1 1.508-1.508h3.771M15.419 11.985h7.543" />
          <path
            d="M25.978 3.69a2.263 2.263 0 1 0 4.527 0 2.263 2.263 0 0 0-4.527 0Z"
            fill="#A0CFDA"
          />
          <path d="M12.4 16.517h6.79a1.507 1.507 0 0 1 1.509 1.507 1.508 1.508 0 0 0 1.508 1.509h3.771" />
          <path
            d="M25.978 19.527a2.263 2.263 0 1 0 4.527 0 2.263 2.263 0 0 0-4.527 0Z"
            fill="#8FA25B"
          />
          <path
            d="M22.96 11.985a2.262 2.262 0 1 0 4.525 0 2.262 2.262 0 0 0-4.524 0Z"
            fill="#ECB23F"
          />
          <path d="M22.58 30.574v-6.29" />
        </g>
        <defs>
          <clipPath id="ai-ml-clip1">
            <path fill="#fff" d="M0 0h32v32H0z" />
          </clipPath>
        </defs>
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <g
        clipPath="url(#ai-ml-clip0)"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.037 30.574v-3.583H8.25a3.585 3.585 0 0 1-3.583-3.585v-3.583H2.392a.904.904 0 0 1-.836-1.218C4.597 10.72 6.441 3.697 14.966 3.697" />
        <path d="M12.4 7.46h6.79a1.509 1.509 0 0 0 1.509-1.51v-.753a1.509 1.509 0 0 1 1.508-1.508h3.771M15.419 11.985h7.543M25.978 3.69a2.263 2.263 0 1 0 4.526 0 2.263 2.263 0 0 0-4.526 0ZM12.4 16.517h6.79a1.506 1.506 0 0 1 1.509 1.507 1.509 1.509 0 0 0 1.508 1.509h3.771" />
        <path d="M25.978 19.527a2.263 2.263 0 1 0 4.526 0 2.263 2.263 0 0 0-4.526 0ZM22.96 11.985a2.263 2.263 0 1 0 4.527 0 2.263 2.263 0 0 0-4.526 0ZM22.58 30.574v-6.29" />
      </g>
      <defs>
        <clipPath id="ai-ml-clip0">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
}
