import { useFormik } from 'formik';
import { createPasswordYupValidation } from 'global/auth';
import type { ComponentProps } from 'react';
import * as Yup from 'yup';
import type { FormikSubmitHandler } from '../../DirectApplication.types';
import { GetMatchedToMoreRoles } from './GetMatchedToMoreRoles';

export type GetMatchedToMoreRolesForm = {
  getMatchedToMoreRoles: boolean;
  password: string;
};

const passwordResetValidationSchema = Yup.object().shape({
  getMatchedToMoreRoles: Yup.boolean(),
  password: Yup.string().when('getMatchedToMoreRoles', {
    is: true,
    then: createPasswordYupValidation(),
  }),
});

export function GetMatchedToMoreRolesController({
  isOpened,
  onSectionBoxEditClick,
  sectionSubmitButtonIsLoading,
  isCompleted,
  onComplete,
}: {
  sectionSubmitButtonIsLoading: boolean;
  onComplete: FormikSubmitHandler<GetMatchedToMoreRolesForm>;
} & Pick<
  ComponentProps<typeof GetMatchedToMoreRoles>,
  'isOpened' | 'onSectionBoxEditClick' | 'isCompleted'
>) {
  const formik = useFormik({
    initialValues: { getMatchedToMoreRoles: true, password: '' },
    validationSchema: passwordResetValidationSchema,
    onSubmit: onComplete,
  });
  const handleSwitchChange = () => {
    formik.setValues({
      ...formik.values,
      getMatchedToMoreRoles: !formik.values.getMatchedToMoreRoles,
      password: formik.values.getMatchedToMoreRoles ? '' : formik.values.password,
    });
  };

  const hasValidationError =
    formik.values.getMatchedToMoreRoles && (!formik.values.password || !!formik.errors.password);

  return (
    <GetMatchedToMoreRoles
      handleSubmit={formik.handleSubmit}
      sectionSubmitButtonIsDisabled={hasValidationError}
      sectionSubmitButtonIsLoading={sectionSubmitButtonIsLoading}
      isCompleted={isCompleted}
      isOpened={isOpened}
      onSectionBoxEditClick={onSectionBoxEditClick}
      isSwitchChecked={formik.values.getMatchedToMoreRoles}
      handleSwitchChange={handleSwitchChange}
      errors={formik.errors}
      handleChange={formik.handleChange}
      handleBlur={formik.handleBlur}
      touched={formik.touched}
      values={formik.values}
    />
  );
}
