import { Icon } from '@terminal/design-system';

export function FullstackIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
        <path
          d="m7.1854 20.736-5.7138 2.9515c-0.29073 0.1506-0.47327 0.4507-0.47327 0.7781s0.18254 0.6275 0.47327 0.778l14.521 7.5118c0.6318 0.3262 1.3824 0.3262 2.0142 0l14.521-7.5032c0.2908-0.1506 0.4733-0.4507 0.4733-0.7781s-0.1825-0.6275-0.4733-0.778l-5.7138-2.9601"
          clipRule="evenodd"
          fill="#ECB23F"
          fillRule="evenodd"
        />
        <path
          d="m7.1854 20.736-5.7138 2.9515c-0.29073 0.1506-0.47327 0.4507-0.47327 0.7781s0.18254 0.6275 0.47327 0.778l14.521 7.5118c0.6318 0.3262 1.3824 0.3262 2.0142 0l14.521-7.5032c0.2908-0.1506 0.4733-0.4507 0.4733-0.7781s-0.1825-0.6275-0.4733-0.778l-5.7138-2.9601"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m7.1854 13.268-5.7138 2.9557c-0.29073 0.1506-0.47327 0.4507-0.47327 0.7781s0.18254 0.6275 0.47327 0.778l14.521 7.5118c0.6318 0.3262 1.3824 0.3262 2.0142 0l14.521-7.5118c0.2908-0.1505 0.4733-0.4506 0.4733-0.778s-0.1825-0.6275-0.4733-0.7781l-5.7138-2.9557"
          clipRule="evenodd"
          fill="#EFD58B"
          fillRule="evenodd"
        />
        <path
          d="m7.1854 13.268-5.7138 2.9557c-0.29073 0.1506-0.47327 0.4507-0.47327 0.7781s0.18254 0.6275 0.47327 0.778l14.521 7.5118c0.6318 0.3262 1.3824 0.3262 2.0142 0l14.521-7.5118c0.2908-0.1505 0.4733-0.4506 0.4733-0.778s-0.1825-0.6275-0.4733-0.7781l-5.7138-2.9557"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m32.528 8.7522-14.521-7.5075c-0.6318-0.32624-1.3824-0.32624-2.0142 0l-14.521 7.5075c-0.29073 0.15056-0.47327 0.45065-0.47327 0.77806 0 0.3274 0.18254 0.62748 0.47327 0.77808l14.521 7.5117c0.6318 0.3263 1.3824 0.3263 2.0142 0l14.521-7.5032c0.2908-0.1506 0.4733-0.45064 0.4733-0.77805 0-0.3274-0.1825-0.62749-0.4733-0.77806v-0.00853z"
          clipRule="evenodd"
          fill="#A0CFDA"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M30.556 8.267L16.944 1.229c-.593-.305-1.296-.305-1.888 0L1.444 8.267c-.273.14-.444.422-.444.729s.171.588.444.73l13.612 7.04c.592.307 1.295.307 1.888 0l13.612-7.033c.272-.14.443-.422.443-.729s-.17-.588-.443-.73v-.007z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.8 12.5l-5.356 2.77C1.17 15.413 1 15.694 1 16s.171.588.444.73l13.612 7.04c.592.306 1.295.306 1.888 0l13.612-7.04c.272-.142.443-.423.443-.73s-.17-.588-.443-.73L25.2 12.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.8 19.5l-5.356 2.767c-.273.14-.444.422-.444.729s.171.588.444.73l13.612 7.04c.592.307 1.295.307 1.888 0l13.612-7.033c.272-.14.443-.422.443-.729s-.17-.588-.443-.73L25.2 19.5"
      />
      <mask id="qkka2wkrla" width="32" height="32" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="currentColor" d="M0 0H32V32H0z" />
      </mask>
    </Icon>
  );
}
