import { PublicPageLayout } from 'candidate/components';
import { Flex, Text, Link, Image, Heading } from '@terminal/design-system';
import type { AuthPageTemplateProps } from 'global/auth';
import { AuthPageHeader } from 'global/auth';
import { Link as RouterLink } from 'react-router-dom';
import { useIsViewPortDesktop } from 'global/utils';
import pageNotFound404ImageSrc from 'candidate/assets/images/404-rebrand.svg?url';
import { TwoColumnAuthPageLayout } from './TwoColumnAuthPageLayout';

export function CandidateAuthDefaultPageTemplate({
  leftImage,
  leftTitle,
  headerTitle,
  mobileTitle,
  mobileSubtitle,
  children,
}: AuthPageTemplateProps & {
  leftImage?: React.ReactNode;
  leftTitle?: string;
  mobileTitle?: string;
  mobileSubtitle?: string;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <PublicPageLayout
      headerRightPlaceholderDesktop={
        <Flex alignItems="center">
          <Text variant="caption">Don’t Have an Account?</Text>
          <Link
            as={RouterLink}
            to={`/onboarding${window.location.search}`}
            ml={2}
            variant="caption"
          >
            Sign Up
          </Link>
        </Flex>
      }
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link as={RouterLink} to={`/onboarding${window.location.search}`}>
            Sign Up
          </Link>
        </Flex>
      }
    >
      <TwoColumnAuthPageLayout
        leftColumn={
          isDesktop && (
            <Flex w="full" flexDir="column" alignItems="center">
              {leftImage || <Image src={pageNotFound404ImageSrc} w="350px" />}
              {leftTitle && <Heading mt={[0, 0, 10]}>{leftTitle}</Heading>}
            </Flex>
          )
        }
        rightColumn={
          <Flex
            flexDir="column"
            flex={1}
            alignItems={['center', 'center', 'flex-start']}
            justifyContent={['flex-start', 'flex-start', 'center']}
            h="full"
          >
            {isDesktop ? (
              headerTitle && <AuthPageHeader title={headerTitle} />
            ) : (
              <>
                {mobileTitle && <AuthPageHeader title={mobileTitle} mt={6} />}
                {mobileSubtitle && (
                  <Heading variant="heading-4" mt={2}>
                    {mobileSubtitle}
                  </Heading>
                )}
              </>
            )}
            <Flex zIndex="docked" mt={[9, 9, 6]} w="full" justifyContent="center" flexDir="column">
              {children}
            </Flex>
          </Flex>
        }
      />
    </PublicPageLayout>
  );
}
