import { AbsoluteCenter, Box } from '@chakra-ui/react';
import type { AbsoluteCenterProps } from '@chakra-ui/react';

export function StepsProgressBar(
  props: {
    value: number;
  } & AbsoluteCenterProps,
) {
  const { value, ...rest } = props;
  return (
    <AbsoluteCenter
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={value}
      aria-valuetext={`Progress: ${value}%`}
      position="absolute"
      height="1px"
      axis="vertical"
      bg="ui.disabled"
      width="full"
      {...rest}
    >
      <Box bg="accent.main" height="inherit" width={`${value}%`} />
    </AbsoluteCenter>
  );
}
