import { useRef, useState } from 'react';
import { createStorageRef, uploadBytes, getDownloadURL } from 'global/firebaseApp';
import * as events from 'global/events';
import type { ChangeEvent } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import { useMutation } from '@apollo/client';
import { cleanFilename, useQueryString, createUserTrackingInfo } from 'global/utils';
import { useGoogleDrivePicker } from 'global/hooks';
import { createResumeFileYupValidation } from 'candidate/shared/modules/resume';
import { EditModal } from '../../components';
import type {
  ProfileProps,
  UpdateCandidateResumeMutationVariables,
  UpdateCandidateResumeMutation,
} from '../../Profile.types';
import { UpdateCandidateResume } from '../../graphql';
import { ResumeForm } from './ResumeForm';
import { ResumeSection } from './ResumeSection';
import { trackProfile } from '../../Profile.utils';

export const ResumeFormValidationSchema = Yup.object().shape({
  // @ts-ignore
  file: createResumeFileYupValidation({
    fileSizeError: 'Invalid file size!',
    fileTypeError: 'Unsupported file type.',
  }),
});

export function ResumeController({
  candidateID,
  resume,
  isModalOpen,
  onModalClose,
  onModalOpen,
}: {
  candidateID: number;
  resume: ProfileProps['candidate']['resume'];
  isModalOpen: boolean;
  onModalClose: () => void;
  onModalOpen: () => void;
}) {
  const initialRef = useRef(null);
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [isDownloadingResume, setIsDownloadingResume] = useState<boolean>(false);

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const isEdit = !!resume.fileName;
  const trackingContext = useQueryString().get('tc');

  const [updateCandidateResume] = useMutation<
    UpdateCandidateResumeMutation,
    UpdateCandidateResumeMutationVariables
  >(UpdateCandidateResume, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to update your resume. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
    onCompleted: (data) => {
      if (data.update_candidate?.returning[0]?.resume_filename == null) {
        trackProfile('entry-deleted', { section: 'Resume' });
      } else if (isEdit) {
        trackProfile('entry-edited', { section: 'Resume' });
      } else {
        trackProfile('entry-added', {
          section: 'Resume',
          ...(trackingContext === 'hpcta'
            ? { context: 'Personalized Complete Profile Action' }
            : {}),
        });
      }
    },
  });

  const handleUpdateResume = async (values: ProfileProps['candidate']['resume']['formValues']) => {
    try {
      const newFilename = values.file ? cleanFilename(values.file.name) : null;
      if (values.file) {
        setIsUploadingFile(true);
        const resumeRef = createStorageRef(`/candidate/resume/${resume.publicID}/${newFilename}`);
        await uploadBytes(resumeRef, values.file);
      }
      updateCandidateResume({
        variables: {
          candidate_id: candidateID,
          resume_filename: newFilename,
        },
      });
    } catch (error) {
      toast({
        description: 'Something went wrong trying to update your resume. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    } finally {
      setIsUploadingFile(false);
      onModalClose();
    }
  };

  const formik = useFormik<ProfileProps['candidate']['resume']['formValues']>({
    initialValues: { file: null },
    validationSchema: ResumeFormValidationSchema,
    onSubmit: (values) => handleUpdateResume(values),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });
  const {
    isLoading: isLoading_googlePicker,
    isOpen: isOpen_googlePicker,
    isReady: isReady_googlePicker,
    onClick: onGooglePickerClick,
  } = useGoogleDrivePicker({
    onFilePicked: (file) => {
      events.track(events.name.resume.attach.start, {
        ...createUserTrackingInfo(),
        file_extension: file.name.split('.').pop(),
        file_size: file?.size,
        resume_source: 'Google Drive',
      });

      formik.setFieldValue('file', file);
      handleUpdateResume({ file });
    },
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to get your file. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
  });

  const handleOnSaveClick = async () => {
    formik.handleSubmit();
  };

  const handleOnFileChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files && target.files.length > 0 && target.files[0]) {
      events.track(events.name.resume.attach.start, {
        ...createUserTrackingInfo(),
        file_extension: target.files[0].name.split('.').pop(),
        file_size: target.files[0].size,
        resume_source: 'Device',
      });

      formik.setFieldValue('file', target.files[0]);
      formik.handleSubmit();
    }
  };

  const handleOnUploadClick = () => {
    // @ts-ignore
    initialRef.current.click();
  };

  const handleOnEditClick = () => {
    formik.resetForm();
    onModalOpen();
    trackProfile('edit-modal-opened', { section: 'Resume' });
    if (!isEdit) {
      trackProfile('clicked-add-entry', { section: 'Resume', context: 'Card' });
    }
  };

  const handleOnDownloadClick = async () => {
    if (!isDownloadingResume) {
      try {
        setIsDownloadingResume(true);
        const resumeRef = createStorageRef(
          `/candidate/resume/${resume.publicID}/${resume.fileName}`,
        );
        const downloadURL = await getDownloadURL(resumeRef);
        const fileResponse = await fetch(downloadURL, {
          headers: new Headers({
            Origin: window.location.origin,
          }),
          mode: 'cors',
        });
        const fileBlob = await fileResponse.blob();
        const blobURL = window.URL.createObjectURL(fileBlob);
        const a = document.createElement('a');
        if (resume.fileName) {
          a.setAttribute('download', resume.fileName);
        }
        a.setAttribute('href', blobURL);
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        toast({
          description: 'Something went wrong trying to download your resume. Please try again!',
          status: 'error',
        });

        Sentry.captureException(error);
      } finally {
        setIsDownloadingResume(false);
      }
    }
  };

  const handleOnClose = () => {
    onModalClose();
  };

  return (
    <>
      <ResumeSection
        isEdit={isEdit}
        onEditClick={handleOnEditClick}
        resume={resume}
        onDownloadClick={handleOnDownloadClick}
      />
      <EditModal
        isOpen={isModalOpen}
        onClose={handleOnClose}
        initialRef={initialRef}
        shouldConfirmClose={false}
        onSaveClick={handleOnSaveClick}
        title="Add Resume"
        saveText="Delete File"
        saveBtnBgColorScheme="error"
        shouldConfirmSave
        confirmSaveTitle="Delete Resume?"
        confirmSaveSubtitle="Are you sure you want to delete your resume?"
        confirmSaveText="Confirm Delete"
        shouldHideOverlay={isOpen_googlePicker}
        hideFooter={isUploadingFile || isLoading_googlePicker || !isEdit}
      >
        <ResumeForm
          errors={formik.errors}
          values={formik.values}
          initialRef={initialRef}
          onFileChange={handleOnFileChange}
          onUploadClick={handleOnUploadClick}
          onGoogleDrivePickerClick={isReady_googlePicker ? onGooglePickerClick : undefined}
          uploadedFileName={resume.fileName || null}
          isFileLoading={isUploadingFile || isLoading_googlePicker}
        />
      </EditModal>
    </>
  );
}
