import {
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Button,
  Image,
  Grid,
  GridItem,
} from '@terminal/design-system';
import type { useRedirection } from 'global/utils';
import { useIsViewPortDesktop } from 'global/utils';
import type { AssessmentProps } from 'candidate/shared/data-layer/SelectAssessments';
import { AssessmentsList, AssessmentDetails, EmptyAssessments } from './components';
import { AssessmentCard } from './components/AssessmentCard';
import HeaderIllustration from './assets/live_assessment.svg?url';

export function Assessments({
  assessments,
  personalizedHeader,
  isCreateTestLoading,
  onLearnMoreClick,
  redirectTo,
  selectedAssessment,
  startTest,
}: {
  assessments: AssessmentProps[];
  personalizedHeader: {
    shouldBeDisplayed: boolean;
    headerTitle: string;
    headerBody: string;
    headerButtonCTA?: string;
    assessment: AssessmentProps;
    card?: {
      cardTitle: string;
      cardBody: string;
      assessmentDuration: string;
    };
  };
  isCreateTestLoading: boolean;
  onLearnMoreClick: (assessment: AssessmentProps) => void;
  redirectTo: ReturnType<typeof useRedirection>;
  selectedAssessment?: AssessmentProps;
  startTest: (testId: AssessmentProps) => void;
}) {
  const completedAssessments = assessments.filter((a) => a.status !== 'Challenge Available');
  const isDesktop = useIsViewPortDesktop();

  return (
    <>
      <Heading variant="heading-2" color="text.primary">
        Test Your Skills
      </Heading>

      <Tabs mt={[4, 4, 3]}>
        <TabList>
          <Tab>All</Tab>
          <Tab>My Assessments</Tab>
        </TabList>

        <TabPanels>
          <TabPanel pt={0}>
            <Grid
              alignItems="center"
              bgColor="bg.tertiary"
              gap={6}
              px={[3, 3, 20]}
              py={6}
              templateColumns={['1fr', '1fr', '1fr 1fr']}
              // Applying `mb` in parent to not apply a `mt` in all the below conditions
              mb={[4, 4, 6]}
            >
              <GridItem>
                <Heading color="brand.main" variant="heading-2">
                  {personalizedHeader.headerTitle}
                </Heading>
                <Text variant="body" color="text.primary" mt={4} whiteSpace="pre-line">
                  {personalizedHeader.headerBody}
                </Text>
                {personalizedHeader?.headerButtonCTA && (
                  <Button mt={4} onClick={() => startTest(personalizedHeader.assessment)}>
                    {personalizedHeader.headerButtonCTA}
                  </Button>
                )}
              </GridItem>
              {isDesktop && (
                <GridItem position="relative" px={14}>
                  {personalizedHeader.card && (
                    <AssessmentCard
                      name={personalizedHeader.card.cardTitle}
                      about={personalizedHeader.card.cardBody}
                      status="Challenge Available"
                      onLearnMoreClick={() => onLearnMoreClick(personalizedHeader.assessment)}
                      footerNote={personalizedHeader.card.assessmentDuration}
                    />
                  )}
                  <Image
                    src={HeaderIllustration}
                    position="absolute"
                    top={0}
                    right={0}
                    transform={
                      personalizedHeader.card ? 'translate(0%, -33%)' : 'translate(0%, -65%)'
                    }
                    width={!personalizedHeader.card ? '275px' : {}}
                  />
                </GridItem>
              )}
            </Grid>

            {assessments.length > 0 ? (
              <AssessmentsList onLearnMoreClick={onLearnMoreClick} assessmentsList={assessments} />
            ) : (
              <EmptyAssessments
                title="No Assessments Available"
                message="There are not any assessments available to you at this time."
              />
            )}
          </TabPanel>
          <TabPanel>
            {completedAssessments.length > 0 ? (
              <AssessmentsList
                onLearnMoreClick={onLearnMoreClick}
                assessmentsList={completedAssessments}
              />
            ) : (
              <EmptyAssessments
                title={
                  isDesktop ? 'You Haven’t Completed Any Assessments.' : 'No Assessments Available'
                }
                message={
                  isDesktop
                    ? 'Please take a test to able to see them here.'
                    : 'There are not any assessments available to you at this time.'
                }
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <AssessmentDetails
        name={selectedAssessment?.name}
        status={selectedAssessment?.status}
        about={selectedAssessment?.about}
        isStartLoading={isCreateTestLoading}
        {...selectedAssessment?.detailProps}
        onClose={() => redirectTo('/assessments', { shouldScrollToTopOnChange: false })}
        isOpen={!!selectedAssessment}
        onStartClick={() => startTest(selectedAssessment as AssessmentProps)}
      />
    </>
  );
}
