import { Icon } from '@terminal/design-system';

export function FrontendIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <path
          d="M3 9C1.89543 9 1 8.79336 1 8.53846V3.49692C1.00366 3.22283 1.9656 3.00084 3.15333 3H28.8573C30.0392 3.00084 30.9963 3.22173 31 3.49446V8.50308C30.9963 8.77717 30.0344 8.99916 28.8467 9H3Z"
          clipRule="evenodd"
          fill="#EFD58B"
          fillRule="evenodd"
        />
        <path
          d="M3 29C1.89543 29 1 28.3112 1 27.4615V10.6564C1.00366 9.74277 1.9656 9.00282 3.15333 9H28.8573C30.0392 9.00282 30.9963 9.7391 31 10.6482V27.3436C30.9963 28.2572 30.0344 28.9972 28.8467 29H3Z"
          clipRule="evenodd"
          fill="#A0CFDA"
          fillRule="evenodd"
        />
        <path
          d="M3 29C1.89543 29 1 28.1046 1 27V5.15333C1.00366 3.9656 1.9656 3.00366 3.15333 3H28.8573C30.0392 3.00366 30.9963 3.96082 31 5.14267V26.8467C30.9963 28.0344 30.0344 28.9963 28.8467 29H3Z"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M31 9H1"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m17 19h6"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m9 15 4 4-4 4"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3 29c-1.105 0-2-.895-2-2V5.153c.004-1.187.966-2.15 2.153-2.153h25.704c1.182.004 2.14.96 2.143 2.143v21.704c-.004 1.187-.966 2.15-2.153 2.153H3z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M31 9H1M17 19h6M9 15l4 4-4 4"
      />
    </Icon>
  );
}
