import {
  Box,
  Flex,
  Input,
  InputGroup,
  Image,
  Text,
  InputRightElement,
  Link,
  Accordion,
  LinkoutIcon,
  Container,
  ChakraCheckCircleIcon as CheckCircleIcon,
  Heading,
} from '@terminal/design-system';
import { PageLayout } from 'candidate/components';
import ReferIcon from 'candidate/assets/images/refer.svg?url';
import { supportedCountriesReadableString } from 'candidate/shared';
import increasedSalary from './assets/IncreasedSalary.svg?url';
import EditIcon from './assets/Edit.svg?url';
import ShareIcon from './assets/Share.svg?url';
import CopyIcon from './assets/Copy.svg?url';
import { candidateReferralAmount, memberReferralAmount } from './Referral.serializer';

export type ReferralBaseProps = {
  hasCopied: boolean;
  isDesktop: boolean | undefined;
  onCopyClick: () => void;
  onSignOutClick: () => Promise<void>;
  terminalIOCandidateReferralURL: string;
  userFullName: string;
};

function VariationWithNonMemberInfo({
  candidateAvailability,
  hasCopied,
  isDesktop,
  onCopyClick,
  onSignOutClick,
  terminalIOCandidateReferralURL,
  userFullName,
}: ReferralBaseProps & {
  candidateAvailability: string | null;
}) {
  return (
    <PageLayout.Variation.WithAllSections
      onSignOutClick={onSignOutClick}
      userFullName={userFullName}
      candidateAvailability={candidateAvailability}
      shouldNotWrapWithContainer
    >
      <Container flex="1" pt={[4, 4, 12]}>
        <Flex flexDir="column" alignItems="center">
          <Box flex={1}>
            <Image width={[32, 'full', 'full']} src={increasedSalary} />
          </Box>

          <Heading variant={isDesktop ? 'heading-1' : 'heading-2'} textAlign="center">
            Refer Someone, Get {candidateReferralAmount}
          </Heading>

          <Box flex={1} width={['full', 'sm', 'md']} textAlign="center">
            <Text variant="body" paddingTop={isDesktop ? 6 : 2}>
              Know someone in the industry? Share your personalized code below and you’ll both get a{' '}
              {candidateReferralAmount} USD gift card once they accept an offer.
            </Text>

            <Box paddingTop={6}>
              {!hasCopied ? (
                <InputGroup size="sm" p="relative">
                  <Input isReadOnly value={terminalIOCandidateReferralURL} h={12} />

                  <InputRightElement cursor="pointer" height="full" onClick={onCopyClick}>
                    <Image src={CopyIcon} />
                  </InputRightElement>
                </InputGroup>
              ) : (
                <Flex
                  backgroundColor="ui.disabled"
                  h={12}
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Text color="text.disabled" variant="body">
                    <CheckCircleIcon marginRight={1} />
                    Copied!
                  </Text>
                </Flex>
              )}
            </Box>
          </Box>

          <Heading
            variant={isDesktop ? 'heading-2' : 'heading-3'}
            paddingTop={20}
            textAlign="center"
          >
            It’s Easy - Here’s How It Works
          </Heading>

          <Flex
            flexDir={['column', 'column', 'row']}
            alignItems={['center', 'center', 'flex-start']}
            width={['full', '3xl', '4xl']}
            justifyContent="space-around"
          >
            {[
              {
                icon: ShareIcon,
                title: 'You Invite',
                description:
                  'Send your invite link to someone you think would be a great fit for the Terminal community.',
              },
              {
                icon: EditIcon,
                title: 'They Join',
                description:
                  'Have them sign up with Terminal using your link and wait for the job matching magic to happen!',
              },
              {
                icon: ReferIcon,
                title: 'You Both Earn',
                description: `When they land a job, we will send both of you a ${candidateReferralAmount} USD gift card. It’s a win-win for everyone!`,
              },
            ].map(({ icon, title, description }) => (
              <Flex
                flexDir="column"
                alignItems="center"
                width={64}
                key={`how-it-works-item-${title}`}
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width={16}
                  height={16}
                  borderRadius={64}
                  backgroundColor="ui.primary"
                  marginTop={16}
                >
                  <Image w={6} h={6} src={icon} />
                </Flex>

                <Heading variant="heading-4" marginTop={6}>
                  {title}
                </Heading>

                <Text variant="body" marginTop={3} textAlign="center">
                  {description}
                </Text>
              </Flex>
            ))}
          </Flex>
          <Box mt={[12, 12, 18]} bgColor="bg.secondary" w="100vw" boxSizing="border-box">
            <Flex maxWidth="880px" flexDirection="column" mx="auto" py={[10, 10, 20]}>
              <Heading variant="heading-2" textAlign="center">
                FAQ
              </Heading>
              <Container w="full" mt={8}>
                <Accordion
                  accordions={[
                    {
                      title:
                        'Do I have to be hired through Terminal in order to receive compensation for this referral?',
                      description:
                        'Good news! You will receive your $1,000 referral bonus when the person you referred is hired through Terminal, regardless of whether you have found a job yourself. Even better news, if you are hired through Terminal, your $1,000 bonus will increase to $5,000 if the person you referred lands a job through Terminal!',
                    },
                    {
                      title: 'When will I be paid if someone I referred lands a job? ',
                      description:
                        'Your payment will be sent out once the person you referred reaches the 90 day mark at their new company',
                    },
                    {
                      title:
                        'Is there a limit to how many friends I can refer or the rewards I can earn?',
                      description:
                        'No! Please feel free to refer as many of your qualified friends as you wish and collect your rewards accordingly. ',
                    },
                    {
                      title: 'Who is eligible to be a referral?',
                      description: `Our current program is eligible for candidates in ${supportedCountriesReadableString} only.`,
                    },
                  ]}
                  arrowColor="accent.darkest"
                />
              </Container>
              <Text variant="body" mt={8} textAlign="center">
                For more FAQs on our referral program, visit our{' '}
                <Link
                  display="inline"
                  fontSize="inherit"
                  href="https://help.terminal.io/en/articles/6482863-referral-program-faq"
                  isExternal
                >
                  help center <LinkoutIcon ml={2} mt="-0.125rem" />
                </Link>
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </PageLayout.Variation.WithAllSections>
  );
}

function VariationWithMemberInfo({
  hasCopied,
  isDesktop,
  onCopyClick,
  onSignOutClick,
  terminalIOCandidateReferralURL,
  userFullName,
}: ReferralBaseProps) {
  return (
    <PageLayout.Variation.WithReferralOnly
      onSignOutClick={onSignOutClick}
      userFullName={userFullName}
      shouldNotWrapWithContainer
    >
      <Container flex="1" pt={[4, 4, 12]}>
        <Flex flexDir="column" alignItems="center">
          <Box flex={1}>
            <Image width={[32, 'full', 'full']} src={increasedSalary} />
          </Box>

          <Heading variant={isDesktop ? 'heading-1' : 'heading-2'} textAlign="center">
            Refer Someone, Get {memberReferralAmount}
          </Heading>

          <Box flex={1} width={['full', 'sm', 'md']} textAlign="center">
            <Text variant="body" paddingTop={isDesktop ? 6 : 2}>
              Know someone in the industry? Share your personalized code below and get a{' '}
              {memberReferralAmount} USD gift card once they accept an offer.
            </Text>

            <Box paddingTop={6}>
              {!hasCopied ? (
                <InputGroup size="sm" p="relative">
                  <Input isReadOnly value={terminalIOCandidateReferralURL} h={12} />

                  <InputRightElement cursor="pointer" height="full" onClick={onCopyClick}>
                    <Image src={CopyIcon} />
                  </InputRightElement>
                </InputGroup>
              ) : (
                <Flex
                  backgroundColor="ui.disabled"
                  h={12}
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Text color="text.disabled" variant="body">
                    <CheckCircleIcon marginRight={1} />
                    Copied!
                  </Text>
                </Flex>
              )}
            </Box>
          </Box>

          <Heading
            variant={isDesktop ? 'heading-2' : 'heading-3'}
            paddingTop={20}
            textAlign="center"
          >
            It’s Easy - Here’s How It Works
          </Heading>

          <Flex
            flexDir={['column', 'column', 'row']}
            alignItems={['center', 'center', 'flex-start']}
            width={['full', '3xl', '4xl']}
            justifyContent="space-around"
          >
            {[
              {
                icon: ShareIcon,
                title: 'You Invite',
                description:
                  'Send your invite link to someone you think would be a great fit for the Terminal community.',
              },
              {
                icon: EditIcon,
                title: 'They Join',
                description:
                  'Have them sign up with Terminal using your link and wait for the job matching magic to happen!',
              },
              {
                icon: ReferIcon,
                title: 'You Earn',
                description: `When they land a job, you get ${memberReferralAmount} USD!`,
              },
            ].map(({ icon, title, description }) => (
              <Flex
                flexDir="column"
                alignItems="center"
                width={64}
                key={`how-it-works-item-${title}`}
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width={16}
                  height={16}
                  borderRadius={64}
                  backgroundColor="ui.primary"
                  marginTop={16}
                >
                  <Image w={6} h={6} src={icon} />
                </Flex>

                <Heading variant="heading-4" marginTop={6}>
                  {title}
                </Heading>

                <Text variant="body" marginTop={3} textAlign="center">
                  {description}
                </Text>
              </Flex>
            ))}
          </Flex>
          <Box mt={[12, 12, 18]} bgColor="bg.secondary" w="100vw" boxSizing="border-box">
            <Flex maxWidth="880px" flexDirection="column" mx="auto" py={[10, 10, 20]}>
              <Heading variant="heading-2" textAlign="center">
                FAQ
              </Heading>
              <Container w="full" mt={8}>
                <Accordion
                  accordions={[
                    {
                      title:
                        'Do I have to be hired through Terminal in order to receive compensation for this referral?',
                      description:
                        'Good news! You will receive your $1,000 referral bonus when the person you referred is hired through Terminal, regardless of whether you have found a job yourself. Even better news, if you are hired through Terminal, your $1,000 bonus will increase to $5,000 if the person you referred lands a job through Terminal!',
                    },
                    {
                      title: 'When will I be paid if someone I referred lands a job? ',
                      description:
                        'Your payment will be sent out once the person you referred reaches the 90 day mark at their new company',
                    },
                    {
                      title:
                        'Is there a limit to how many friends I can refer or the rewards I can earn?',
                      description:
                        'No! Please feel free to refer as many of your qualified friends as you wish and collect your rewards accordingly. ',
                    },
                    {
                      title: 'Who is eligible to be a referral?',
                      description: `Our current program is eligible for candidates in ${supportedCountriesReadableString} only.`,
                    },
                  ]}
                  arrowColor="accent.darkest"
                />
              </Container>
              <Text variant="body" mt={8} textAlign="center">
                For more FAQs on our referral program, visit our{' '}
                <Link
                  display="inline"
                  fontSize="inherit"
                  href="https://help.terminal.io/en/articles/6482863-referral-program-faq"
                  isExternal
                >
                  help center <LinkoutIcon ml={2} mt="-0.125rem" />
                </Link>
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </PageLayout.Variation.WithReferralOnly>
  );
}

export const Referral = {
  Variation: {
    WithMemberInfo: VariationWithMemberInfo,
    WithNonMemberInfo: VariationWithNonMemberInfo,
  },
};
