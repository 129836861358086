import type { HStepsProps } from 'candidate/components';
import { useDesiredSalaryController } from '../../../../modules/desired-salary';
import type { DesiredSalaryInformation } from '../../../../Profile.types';
import { SalaryStep } from './SalaryStep';

export function SalaryController({
  candidateID,
  onStepCompleteSuccess,
  onSignOutClick,
  userFullName,
  progressProps,
  desiredSalaryData,
  userID,
}: {
  candidateID: number;
  onStepCompleteSuccess: () => void;
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  progressProps: HStepsProps;
  desiredSalaryData: DesiredSalaryInformation;
  userID?: number;
}) {
  const { isLoading_upsert, formik } = useDesiredSalaryController({
    desiredSalaryData,
    userID,
    candidateID,
    onSaveClick: onStepCompleteSuccess,
    fieldsAreRequired: true,
  });

  return (
    <SalaryStep
      isNextDisabled={isLoading_upsert}
      onNextClick={formik.handleSubmit}
      onSkipClick={onStepCompleteSuccess}
      onSignOutClick={onSignOutClick}
      userFullName={userFullName}
      progressProps={progressProps}
      errors={formik.errors}
      values={formik.values}
      touched={formik.touched}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
    />
  );
}
