import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import {
  Job_Status_Choices_Enum,
  Candidate_Job_Workflow_Status_Choices_Enum,
  Candidate_Curation_Workflow_Status_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
/* eslint-disable no-param-reassign */
import {
  convertToEmploymentTypeCopy,
  normalizedTechStackWithSkills,
  serializeJobDetails,
} from 'candidate/features/job/utils';
import type { NoUndefinedField } from 'global/utils';
import { compareByObjectsDateDsc, compareByPropDateAsc } from 'global/utils';
import moment from 'moment';
import type { JobDetailDataProps } from 'candidate/shared/modules/job/JobDetail/JobDetail.types';
import type { SelectMyJobsQuery } from 'candidate/shared/modules/job/MyJobs/graphql';
import type { SelectCandidateApplicableJobsForBrowsingQuery } from '../data/types/SelectCandidateApplicableJobsForBrowsing.query.generated';
import { serializeHandpickedJobsByTP } from '../HandpickedJobsByTP';
import { jobDetailSerializer } from '../JobDetail/JobDetail.job.serializer';
import { ActivityStatus } from './myJobs.types';

export type MyJob = {
  jobID: number | null;
  summary: {
    companyName: string | null;
    skills: string[];
    logo: string | null;
    logo_sq: string | null;
    jobTitle: string | null;
    updatedAt: string | null;
    salary: JobDetailDataProps['salary'];
    badge?: {
      label: ActivityStatus | Candidate_Job_Workflow_Status_Choices_Enum;
    };
  };
  jobDescription: JobDetailDataProps;
  applicationStatus:
    | {
        date: string;
        status: string;
        title: string;
        description: string;
      }[]
    | null;
  isTPRecommendation?: boolean;
};

export function selectBadgeColor(
  status: ActivityStatus | Candidate_Job_Workflow_Status_Choices_Enum,
): string {
  const badgeColors = {
    [ActivityStatus.Applied]: 'disabled',
    [ActivityStatus.Interview]: 'info',
    [ActivityStatus.Offer]: 'success',
    [ActivityStatus.InReview]: 'warning',
    [ActivityStatus.Declined]: 'disabled',
    [ActivityStatus.Closed]: 'disabled',
    [Candidate_Job_Workflow_Status_Choices_Enum.CandidateApplied]: 'disabled',
    [Candidate_Job_Workflow_Status_Choices_Enum.CandidateRejected]: 'disabled',
  };
  // @ts-ignore -> We don't need to check if the status is in the enum
  return badgeColors[status] || 'disabled';
}

function toActivityStatus_fromICIMSorJobStatus(ICIMSStatus?: string | null): ActivityStatus | null {
  switch (ICIMSStatus?.trim()) {
    case 'CANDIDATE_APPROVED':
      return ActivityStatus.Applied;

    case 'Submitted to Client - Client Portal Advance':
    case 'Submitted to Client - Submitted':
    case 'Submitted to Review - Submitted to Review':
    case 'Terminal Screen - Terminal Review':
    case 'Terminal Screen - Terminal Screen Completed':
    case 'Terminal Screen - Terminal Screen Scheduled':
    case 'Terminal Screen - Terminal Screen to be Scheduled':
      return ActivityStatus.InReview;

    case 'Client Review - Awaiting Client Feedback':
    case 'Client Review - Final Interview Completed':
    case 'Client Review - Interview #1':
    case 'Client Review - Interview #1 to be Scheduled':
    case 'Client Review - Interview #2':
    case 'Client Review - Interview #2 to be Scheduled':
    case 'Client Review - Interview #3':
    case 'Client Review - Interview #3 to be Scheduled':
    case 'Client Review - Interview #4':
      return ActivityStatus.Interview;

    case 'Rejected - Terminal Rejected - Offer Rescinded':
    case 'Rejected - Terminal Rejected - Other (Explain)':
    case 'Rejected - Terminal Rejected - Role / Level':
    case 'Rejected - Terminal Rejected - Role Filled':
    case 'Rejected - Terminal Rejected - Role No Longer Needed':
    case 'Rejected - Terminal Rejected - Timing':
    case 'Rejected - Terminal Rejected - Took Another Opportunity':
    case 'Rejected - Terminal Rejected - Type of Companies':
    case 'Candidate Withdrew - Self-Withdrew (Portal)':
    case 'Rejected - Client Rejected - Candidate Lacks Experience':
    case 'Rejected - Client Rejected - Candidate Lacks Technical Proficiency':
    case 'Rejected - Client Rejected - Candidate Lacks Work Authorization':
    case 'Rejected - Client Rejected - Candidate Unresponsive':
    case 'Rejected - Client Rejected - Candidate Withdrew':
    case 'Rejected - Client Rejected - Candidate not a Culture Fit':
    case 'Rejected - Client Rejected - Client Portal Rejected':
    case 'Rejected - Client Rejected - Compensation':
    case 'Rejected - Client Rejected - Contractor Background':
    case 'Rejected - Client Rejected - English Proficiency':
    case 'Rejected - Client Rejected - Job Stability':
    case 'Rejected - Client Rejected - Offer Rescinded':
    case 'Rejected - Client Rejected - Other (Explain)':
    case 'Rejected - Client Rejected - Requirements Changed':
    case 'Rejected - Client Rejected - Role Filled':
    case 'Rejected - Client Rejected - Role No Longer Needed':
    case 'Rejected - Client Rejected - Took Another Opportunity':
    case 'Rejected - Offer Declined - Offer Declined - Compensation':
    case 'Rejected - Terminal Rejected - Candidate Lacks Experience':
    case 'Rejected - Terminal Rejected - Candidate Lacks Technical Proficiency':
    case 'Rejected - Terminal Rejected - Candidate Lacks Work Authorization':
    case 'Rejected - Terminal Rejected - Candidate Unresponsive':
    case 'Rejected - Terminal Rejected - Candidate Withdrew':
    case 'Rejected - Terminal Rejected - Candidate not a Culture Fit':
    case 'Rejected - Terminal Rejected - Job Stability':
    case 'Rejected - Terminal Rejected - Location':
    case 'Rejected - Terminal Rejected - Looking for Relocation':
    case 'Rejected - Terminal Rejected - Compensation':
    case 'Rejected - Terminal Rejected - Contractor Background':
    case 'Rejected - Terminal Rejected - English Proficiency':
      return ActivityStatus.Declined;

    case 'Offer - Background Check Completed':
    case 'Offer - Background Check Initiated':
    case 'Offer - New Offer':
    case 'Offer - Offer Accepted':
    case 'Offer - Offer Extended':
      return ActivityStatus.Offer;

    case 'Closed (Filled)':
    case 'Closed (Not Filled)':
    case 'CURATOR_REJECTED':
    case 'TP_MATCH_REJECTED':
      return ActivityStatus.Closed;

    case 'CANDIDATE_REJECTED':
      return ActivityStatus.NotInterested;

    default:
      return null;
  }
}

function toHistoryTitleAndDescription_fromStatus(
  status: ActivityStatus | Candidate_Job_Workflow_Status_Choices_Enum,
): {
  title: string;
  description: string;
} {
  // TODO: add more
  switch (status) {
    case ActivityStatus.Applied:
    case Candidate_Job_Workflow_Status_Choices_Enum.CandidateApproved:
      return {
        title: 'Application Sent',
        description: 'You applied to this position.',
      };

    case ActivityStatus.InReview:
      return {
        title: 'Application In Review',
        description: 'Our team is accessing your fit to this position.',
      };

    case ActivityStatus.Interview:
      return {
        title: 'Interview placeholder title',
        description: 'Interview placeholder description',
      };

    case ActivityStatus.Offer:
      return {
        title: 'Offer placeholder title',
        description: 'Offer placeholder description',
      };

    case ActivityStatus.Declined:
      return {
        title: 'Declined placeholder title',
        description: 'Declined placeholder description',
      };

    case ActivityStatus.Closed:
    case Candidate_Job_Workflow_Status_Choices_Enum.CuratorRejected:
    case Candidate_Job_Workflow_Status_Choices_Enum.TpMatchRejected:
      return {
        title: 'Job Opportunity Closed',
        description: 'This job is currently closed.',
      };

    default:
      return {
        title: '',
        description: '',
      };
  }
}

function toActivityStatus_fromCandidate_Curation_Workflow_Status(
  curationWorkflowStatus?: Candidate_Curation_Workflow_Status_Choices_Enum,
): ActivityStatus | null {
  switch (curationWorkflowStatus?.trim()) {
    case 'REJECTED':
      return ActivityStatus.Declined;

    default:
      return null;
  }
}

// Takes data from ICIMS and returns an array of all job applications with activity statuses (applicationStatus)
function transformICIMSJobData_toListOfJobApplications_withActivityStatuses({
  jobApplications,
  ICIMsJobUpdates,
  candidateInfo,
  conversionTable,
  candidateJobUpdates,
}: {
  jobApplications:
    | SelectMyJobsQuery['candidate'][number]['applicant_jobs']
    | SelectMyJobsQuery['candidate'][number]['candidate_jobs'];
  ICIMsJobUpdates: NoUndefinedField<SelectMyJobsQuery>['candidate'][number]['icims_profile_person']['icims_people'][number]['icims_applicant_workflows'];
  candidateInfo?: {
    country: Exclude<SelectMyJobsQuery['candidate'][number]['country_choice'], null>['name'] | null;
    talentPartnerEmail: string | null | undefined;
    candidateSkills: SelectMyJobsQuery['candidate'][number]['candidate_skills'][number]['skill']['name'];
    yearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null | undefined;
    furthestCandidateCurationWorkflow:
      | NonNullable<
          SelectMyJobsQuery['candidate'][number]['furthest_candidate_curation_workflow']
        >['status']
      | null;
  } | null;
  dateCountry: string;
  conversionTable: SelectMyJobsQuery['currency_exchange_rate'];
  candidateJobUpdates: SelectMyJobsQuery['candidate'][number]['candidate_job_workflows'];
}): {
  [key: number]: Omit<MyJob, 'summary'> & {
    summary: Omit<MyJob['summary'], 'updatedAt'>;
  };
} {
  type MyJobWithoutSummary = Omit<MyJob, 'summary'> & {
    summary: Omit<MyJob['summary'], 'updatedAt'>;
  };

  const jobsApplicationStatusDataMap: {
    [key: number]: MyJobWithoutSummary;
  } = {};

  function createICIMSJobApplicationStatus({
    icims_job,
    status,
    created_at,
  }: {
    created_at: any;
    status: ActivityStatus;
    icims_job: Parameters<
      typeof transformICIMSJobData_toListOfJobApplications_withActivityStatuses
    >[0]['jobApplications'][number]['icims_profile_job']['icims_jobs'][number];
  }) {
    const { profile_id, jobtitle, icims_company } = icims_job;

    const { jobDetail } = serializeJobDetails({
      job: icims_job,
      applicant_jobs: [],
      candidate_jobs: [],
      candidateInfo: {
        country: candidateInfo?.country || null,
        talentPartnerEmail: candidateInfo?.talentPartnerEmail,
        candidateSkills: candidateInfo?.candidateSkills,
        yearsOfExperience: candidateInfo?.yearsOfExperience,
        furthestCandidateCurationWorkflow:
          candidateInfo?.furthestCandidateCurationWorkflow as Candidate_Curation_Workflow_Status_Choices_Enum,
      },
      conversionTable,
    });

    jobsApplicationStatusDataMap[profile_id] = {
      jobID: profile_id,
      summary: {
        companyName: icims_company?.organizations[0]?.name || null,
        skills: normalizedTechStackWithSkills({
          requiredSkills: {
            skill1: icims_job.skillByRequiredSkill1,
            skill2: icims_job.skillByRequiredSkill2,
            skill3: icims_job.skillByRequiredSkill3,
          },
          techStack: icims_job.tech_stack,
        }),
        logo: icims_company?.organizations[0]?.logo || null,
        logo_sq: icims_company?.organizations[0]?.logo_sq || null,
        jobTitle: jobtitle,
        salary: jobDetail?.salary || null,
      },
      jobDescription: jobDetail as JobDetailDataProps,
      applicationStatus: [
        ...(jobsApplicationStatusDataMap[profile_id]?.applicationStatus || []),
        {
          status,
          date: created_at,
          title: toHistoryTitleAndDescription_fromStatus(status).title,
          description: toHistoryTitleAndDescription_fromStatus(status).description,
        },
      ],
    };
  }

  // For `job` table
  function createJobApplicationStatus({
    job,
    status,
    created_at,
  }: {
    created_at: any;
    status: Candidate_Job_Workflow_Status_Choices_Enum;
    job: SelectMyJobsQuery['candidate'][number]['candidate_job_workflows'][number]['job'];
  }) {
    const jobDetail = jobDetailSerializer({
      job,
      createdAt: created_at,
      candidateInfo: {
        country: candidateInfo?.country || null,
        candidateSkills: candidateInfo?.candidateSkills,
        yearsOfExperience: candidateInfo?.yearsOfExperience,
        furthestCandidateCurationWorkflow:
          candidateInfo?.furthestCandidateCurationWorkflow as Candidate_Curation_Workflow_Status_Choices_Enum,
      },
      conversionTable,
    });

    jobsApplicationStatusDataMap[jobDetail.id as number] = {
      jobID: jobDetail.id,
      summary: {
        companyName: jobDetail.companyName,
        skills: jobDetail.techStacks.map(({ stack }) => stack),
        logo: jobDetail.logo?.src || null,
        logo_sq: jobDetail.logo_sq?.src || null,
        jobTitle: jobDetail.title,
        salary: jobDetail?.salary || null,
      },
      jobDescription: {
        ...jobDetail,
        talentPartnerEmail: candidateInfo?.talentPartnerEmail,
        employmentType: convertToEmploymentTypeCopy(job.employment_type),
      },
      applicationStatus: [
        ...(jobsApplicationStatusDataMap[jobDetail.id as number]?.applicationStatus || []),
        {
          status: toActivityStatus_fromICIMSorJobStatus(status) || '',
          date: created_at,
          title: toHistoryTitleAndDescription_fromStatus(status).title,
          description: toHistoryTitleAndDescription_fromStatus(status).description,
        },
      ],
    };
  }

  jobApplications.forEach(({ created_at, icims_profile_job: { icims_jobs } }) => {
    if (!icims_jobs[0]) {
      return;
    }

    const { candidate_curation_workflows } = icims_jobs[0];

    createICIMSJobApplicationStatus({
      icims_job: icims_jobs[0],
      status: ActivityStatus.Applied,
      created_at,
    });

    // Right now, a job application can have curation workflow only when its rejected. On other words,
    // when Jobs that are not submitted to client get rejected, that status comes from this `candidate_curation_workflows`.
    if (candidate_curation_workflows.length > 0) {
      candidate_curation_workflows.forEach((curation_workflow) => {
        const status = toActivityStatus_fromCandidate_Curation_Workflow_Status(
          curation_workflow.status,
        ) as ActivityStatus;

        createICIMSJobApplicationStatus({
          icims_job: icims_jobs[0]!,
          status,
          created_at: curation_workflow.created_at,
        });
      });
    }

    // Closed job (doesn't include created_at field)
    if (
      ['Closed (Not Filled)', 'Closed (Filled)'].includes(
        icims_jobs[0].icims_folder?.value as string,
      )
    ) {
      createICIMSJobApplicationStatus({
        icims_job: icims_jobs[0],
        status: toActivityStatus_fromICIMSorJobStatus(
          icims_jobs[0].icims_folder?.value,
        ) as ActivityStatus,
        created_at: null,
      });
    }
  });

  // Adds the rest of the statuses to the normalized array
  ICIMsJobUpdates?.forEach(({ icims_job, icims_applicant_workflow_statuses }) => {
    if (!icims_job?.profile_id) {
      return;
    }

    // Closed job (doesn't include created_at field)
    if (['Closed (Not Filled)', 'Closed (Filled)'].includes(icims_job.icims_folder?.value)) {
      createICIMSJobApplicationStatus({
        icims_job,
        status: toActivityStatus_fromICIMSorJobStatus(
          icims_job.icims_folder.value,
        ) as ActivityStatus,
        created_at: null,
      });
    }

    // Right now, a job application can have curation workflow only when its rejected. On other words,
    // when Jobs that are not submitted to client get rejected, that status comes from this `candidate_curation_workflows`.
    if (icims_job.candidate_curation_workflows.length > 0) {
      icims_job.candidate_curation_workflows.forEach((curation_workflow) => {
        const status = toActivityStatus_fromCandidate_Curation_Workflow_Status(
          curation_workflow.status,
        ) as ActivityStatus;

        createICIMSJobApplicationStatus({
          icims_job,
          status,
          created_at: curation_workflow?.created_at,
        });
      });
    }

    icims_applicant_workflow_statuses.forEach(({ created_at, icims_status }) => {
      const status = toActivityStatus_fromICIMSorJobStatus(icims_status?.value);

      if (!status) {
        return;
      }

      createICIMSJobApplicationStatus({
        icims_job,
        status: status as ActivityStatus,
        created_at,
      });
    });
  });

  candidateJobUpdates?.forEach((job) => {
    createJobApplicationStatus({
      job: job.job,
      status: job.status,
      created_at: job.created_at,
    });
  });

  return jobsApplicationStatusDataMap;
}

export function myJobsSerializer({
  data,
}: {
  data: SelectMyJobsQuery | SelectCandidateApplicableJobsForBrowsingQuery;
}) {
  const candidate = data?.candidate[0] as SelectMyJobsQuery['candidate'][number];

  const activityFeedCards = transformICIMSJobData_toListOfJobApplications_withActivityStatuses({
    candidateInfo: {
      country: candidate?.country_choice?.name || null,
      talentPartnerEmail: candidate?.owner_person?.icims_people[0]?.email,
      candidateSkills: candidate?.candidate_skills?.map(({ skill }) => skill.name),
      yearsOfExperience: candidate?.candidate_curation_detail?.years_of_exp_range,
      furthestCandidateCurationWorkflow:
        candidate?.furthest_candidate_curation_workflow?.status || null,
    },
    // @ts-ignore
    jobApplications: [...(candidate?.applicant_jobs || []), ...(candidate?.candidate_jobs || [])],
    // @ts-ignore
    ICIMsJobUpdates: candidate?.icims_profile_person?.icims_people.flatMap(
      ({ icims_applicant_workflows }) => icims_applicant_workflows,
    ),
    // Updates made by candidate, either accepting or rejecting a job suggestion from Talent Partner
    candidateJobUpdates: candidate?.candidate_job_workflows.filter((job) =>
      [
        Candidate_Job_Workflow_Status_Choices_Enum.CandidateApproved,
        Candidate_Job_Workflow_Status_Choices_Enum.CandidateRejected,
        Candidate_Job_Workflow_Status_Choices_Enum.CuratorRejected,
        Candidate_Job_Workflow_Status_Choices_Enum.TpMatchRejected,
      ].includes(job.status),
    ),
    conversionTable: data?.currency_exchange_rate,
  });

  const activityFeed_withSortedApplicationStatus = Object.values(activityFeedCards).map(
    (jobData) => {
      return {
        ...jobData,
        applicationStatus: jobData.applicationStatus
          // Sorts each history inside activity card in descending order
          ?.sort(compareByObjectsDateDsc)
          ?.filter(
            (currentActivity, index, allActivities) =>
              index ===
              allActivities.findIndex((activity) => activity.status === currentActivity.status),
          )
          .reverse()
          .map((history) => {
            history.date =
              history.status === ActivityStatus.Closed
                ? ''
                : moment(history.date).format('MMM D, YYYY');
            return history;
          }),
      };
    },
  );

  // Add updatedAt title after sort it
  const myJobs = activityFeed_withSortedApplicationStatus
    .map((activityFeedCard) => {
      if (!activityFeedCard.applicationStatus) return activityFeedCard;

      // if the status is anything other than declined or offer and the application is already closed,
      // I should see the job closed card in my activity feed
      const shouldShowApplication_asClosed =
        !activityFeedCard.applicationStatus?.some(({ status }) =>
          [ActivityStatus.Declined, ActivityStatus.Offer].includes(status as ActivityStatus),
        ) &&
        activityFeedCard.applicationStatus?.some(({ status }) => status === ActivityStatus.Closed);

      if (!shouldShowApplication_asClosed) {
        // Deletes the `Closed` status from the history array
        activityFeedCard.applicationStatus = activityFeedCard.applicationStatus.splice(
          activityFeedCard.applicationStatus.findIndex(
            ({ status }) => status !== ActivityStatus.Closed,
          ),
        );
      }

      return {
        ...activityFeedCard,
        // Easier to sort it having an `updatedAt` outside `summary` obj
        updatedAt: shouldShowApplication_asClosed
          ? null
          : `Updated on ${activityFeedCard.applicationStatus[0]?.date}`,
        summary: {
          ...activityFeedCard.summary,
          updatedAt: shouldShowApplication_asClosed
            ? null
            : `Updated on ${activityFeedCard.applicationStatus[0]?.date}`,
          badge: {
            label: activityFeedCard.applicationStatus[0]?.status as ActivityStatus,
          },
        },
        applicationStatus:
          /* 
            If an application has a `Declined` status, the UI shouldn't show the `Application Status` tab
          */
          activityFeedCard.applicationStatus[0]?.status === ActivityStatus.Declined ||
          activityFeedCard.applicationStatus[0]?.status === ActivityStatus.NotInterested
            ? []
            : // Removes first element from array (is the same than the current status, so is duplicated)
              activityFeedCard.applicationStatus,
      };
    })
    // Sorts each Activity Feed card in ascending order
    .sort(compareByPropDateAsc('updatedAt')) as MyJob[];

  const { handpickedJobsByTp } = serializeHandpickedJobsByTP({
    jobs: candidate?.candidate_job_workflows.filter(
      ({ job }) =>
        job.status === Job_Status_Choices_Enum.Approved ||
        job.status === Job_Status_Choices_Enum.OnHold ||
        job.status === Job_Status_Choices_Enum.Paused ||
        job.status === Job_Status_Choices_Enum.PendingClose ||
        job.status === Job_Status_Choices_Enum.Posted,
    ),

    candidateInfo: {
      country: candidate?.country_choice?.name || null,
      candidateSkills: candidate?.candidate_skills?.map(({ skill }) => skill.name),
      yearsOfExperience: candidate?.candidate_curation_detail?.years_of_exp_range,
      furthestCandidateCurationWorkflow:
        candidate?.furthest_candidate_curation_workflow?.status || null,
    },
    conversionTable: data?.currency_exchange_rate,
  });

  const talentPartnerSelectedJobs_toReview =
    (candidate?.furthest_candidate_curation_workflow?.status &&
      [
        // Statuses that candidate should meet if is applicable to have TP job recommendations
        Candidate_Curation_Workflow_Status_Choices_Enum.ClosedCurrentlyScreeningElsewhere,
        Candidate_Curation_Workflow_Status_Choices_Enum.ClosedAlreadyScreened,
        Candidate_Curation_Workflow_Status_Choices_Enum.PhoneScreenNoShow,
        Candidate_Curation_Workflow_Status_Choices_Enum.PhoneScreenScheduling,
        Candidate_Curation_Workflow_Status_Choices_Enum.PhoneScreenScheduled,
        Candidate_Curation_Workflow_Status_Choices_Enum.PhoneScreenPassed,
        Candidate_Curation_Workflow_Status_Choices_Enum.Accepted,
        Candidate_Curation_Workflow_Status_Choices_Enum.PhoneScreenCompleted,
      ].includes(candidate?.furthest_candidate_curation_workflow?.status) &&
      handpickedJobsByTp?.map((job) => {
        return {
          jobID: job.id,
          workflowID: job.workFlowID,
          summary: {
            companyName: job.companyName,
            skills: job.techStacks.map((stack) => stack.stack),
            logo: job.logo?.src || null,
            logo_sq: job.logo_sq?.src || null,
            jobTitle: job.title,
            updatedAt: null,
            salary: job.salary,
          },
          jobDescription: {
            companyName: job.companyName,
            descriptiveInfos: job.descriptiveInfos,
            industry: job.industry,
            logo: job.logo || null,
            tagInfos: job.tagInfos,
            techStacks: job.techStacks,
            title: job.title,
            organizationID: job.organizationID,
            salary: job.salary,
            talentPartnerEmail: candidate.owner_person?.icims_people[0]?.email,
            topApplicantInfo: job.topApplicantInfo,
            interviewDetails: job.interviewDetails,
            employmentType: job.employmentType,
          },
          applicationStatus: null,
          isTPRecommendation: true,
        };
      })) ||
    [];

  return {
    // Remove duplicate `Closed` myJobs
    appliedJobs: myJobs.filter(
      (job, index) =>
        myJobs.indexOf(job) === index &&
        ![ActivityStatus.Closed, ActivityStatus.Declined, ActivityStatus.NotInterested].includes(
          job.summary.badge?.label as ActivityStatus,
        ),
    ),
    archivedJobs: myJobs.filter(
      (job, index) =>
        myJobs.indexOf(job) === index &&
        [ActivityStatus.Closed, ActivityStatus.Declined, ActivityStatus.NotInterested].includes(
          job.summary.badge?.label as ActivityStatus,
        ),
    ),
    isTalentPartnerAssigned: !!candidate?.owner_person?.icims_people[0]?.email,
    talentPartnerEmail: candidate?.owner_person?.icims_people[0]?.email,
    talentPartnerSelectedJobs_toReview,
    shouldShowHandpickedJobsBanner: talentPartnerSelectedJobs_toReview.length > 0,
  };
}
