import { PublicPageLayout } from 'candidate/components';
import { SignInForm } from 'global/auth/SignIn';
import * as Yup from 'yup';
import { Heading, Center, Text, Box, Flex, Link, Image } from '@terminal/design-system';
import { Link as RouterLink } from 'react-router-dom';
import { TwoColumnAuthPageLayout } from 'candidate/app/components';
import { useIsViewPortDesktop } from 'global/utils';
import {
  PasswordFieldWithPopoverValidator,
  createPasswordYupValidation,
} from 'global/auth/components';
import WeHaveSeenYouBeforeSVG from '../AccountExistsLogIn/assets/we-have-seen-you-before.svg?url';

const signUpValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please make sure your email address is valid')
    .required('Please include an email address.'),
  password: createPasswordYupValidation(),
});

export function JobApplicationSignUp({
  onGoogleLoginClicked,
  onEmailSignSubmitted,
  isLoading,
  firstName,
  email,
  shouldHideGoogleSSO,
}: {
  onGoogleLoginClicked: () => void;
  onEmailSignSubmitted: (email: string, password: string) => Promise<void>;
  isLoading: boolean;
  firstName: string;
  email: string;
  shouldHideGoogleSSO: boolean;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <PublicPageLayout
      headerRightPlaceholderDesktop={
        <Flex alignItems="center">
          <Text>Don’t Have An Account?</Text>
          <Link as={RouterLink} to="/onboarding" ml={2} fontWeight="bold">
            Sign Up
          </Link>
        </Flex>
      }
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link
            as={RouterLink}
            to={`/auth/login${window.location.search}`}
            ml={2}
            fontWeight="bold"
          >
            Login
          </Link>
        </Flex>
      }
    >
      <TwoColumnAuthPageLayout
        leftColumn={
          isDesktop && (
            <Flex w="full" flexDir="column" alignItems="center">
              <Image src={WeHaveSeenYouBeforeSVG} />
            </Flex>
          )
        }
        rightColumn={
          <Flex
            zIndex="docked"
            alignItems="stretch"
            w="full"
            h="full"
            mt={[6, 6, 0]}
            justifyContent={['flex-start', 'flex-start', 'center']}
            flexDir="column"
          >
            <Box textAlign={['center', 'center', 'left']}>
              <Heading variant="heading-2">
                <Heading
                  display={['block', 'block', 'inline']}
                  variant="heading-2"
                  color="brand.main"
                >
                  {firstName && `${firstName}, `}
                </Heading>
                We’ve Seen You Before 👀
              </Heading>
              <Text mt={2}>
                To apply to this second job with us and save your information for future
                applications, complete your account now.
              </Text>
            </Box>
            <Box mt={6}>
              <SignInForm
                onGoogleLoginClicked={onGoogleLoginClicked}
                onEmailSignSubmitted={onEmailSignSubmitted}
                showLoadingForEmailSignIn={isLoading}
                disableGoogleSignInButton={isLoading}
                email={email}
                isEmailInputDisabled={!!email}
                shouldHideGoogleSSO={shouldHideGoogleSSO}
                hideForgotPassword
                logInWithEmailLabel="Complete Account Creation"
                logInWithGoogleLabel="Complete Account with Google"
                validationSchema={signUpValidationSchema}
                renderPasswordField={(formik) => (
                  <PasswordFieldWithPopoverValidator
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.errors.password && !!formik.touched.password}
                    placeholder="Your Password"
                    title="Your Password"
                    touched={!!formik.touched.password}
                    value={formik.values.password}
                    submissionCount={formik.submitCount}
                    mt={[4, 4, 6]}
                  />
                )}
              />
            </Box>

            <Box textAlign="center" mt={4}>
              <Text variant="hint" color="text.disabled">
                {`Please make sure to use the same email: ${email}`}
              </Text>
            </Box>

            <Center>
              <Text variant="hint" mt={4} textAlign="center" maxW="17.375rem">
                By submitting your application, you agree to the{' '}
                <Link
                  variant="hint"
                  href="https://terminal.io/terms-of-use"
                  isExternal
                  fontSize="xs"
                  color="accent.main"
                  display="inline-block"
                >
                  Terms of Service
                </Link>{' '}
                &{' '}
                <Link
                  variant="hint"
                  fontSize="xs"
                  color="accent.main"
                  display="inline-block"
                  href="https://terminal.io/job-applicant-privacy"
                >
                  Job Application Notice.
                </Link>
              </Text>
            </Center>
          </Flex>
        }
      />
    </PublicPageLayout>
  );
}
