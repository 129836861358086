import type { ProfileProps, SelectProfileQuery } from 'candidate/features/profile/Profile.types';
import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types/hasura-tables.generated.types';

export const displayYearsOfExperienceRangeNames: { [key: string]: string } = {
  ZERO_TWO: '0 to 2 years',
  TWO_FIVE: '2 to 5 years',
  FIVE_TEN: '5 to 10 years',
  TEN_PLUS: 'More than 10 years',
};

export function serializerYearsOfExperienceRange(
  candidate_curation_years_of_exp_range_choices: SelectProfileQuery['candidate_curation_years_of_exp_range_choices'],
): ProfileProps['yearsOfExperienceRangeChoices'] {
  return candidate_curation_years_of_exp_range_choices
    .filter(({ value }) => !!displayYearsOfExperienceRangeNames[value])
    .map(({ value }) => ({
      name: value as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
      displayName: displayYearsOfExperienceRangeNames[value] || '',
    }));
}
