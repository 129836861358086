import type { FormikState } from 'formik';
import type { ChangeEvent, FocusEvent } from 'react';
import type { ProfileProps } from '../../../../../Profile.types';
import {
  WorkExperiencesForm,
  WorkExperiencesSection,
} from '../../../../../modules/work-experiences';
import { EditModal } from '../../../../../components';

export function WorkExperiences({
  editID,
  errors,
  initialRef,
  isModalOpen,
  onAddClick,
  onBlur,
  onChange,
  onCloseModalClick,
  onDeleteClick,
  onEditClick,
  onSaveClick,
  shouldAskConfirmationOnModalClose,
  showLoadingForDeleteButton,
  showLoadingForSaveButton,
  values,
  touched,
  workExperiences,
}: {
  initialRef?: React.MutableRefObject<null>;
  editID: number | null;
  isModalOpen: boolean;
  onAddClick: () => void;
  onCloseModalClick: () => void;
  onDeleteClick: (deleteID: number) => void;
  onEditClick: (
    values: ProfileProps['candidate']['workExperiences'][number]['formValues'],
    editID: number,
  ) => void;
  onBlur: (event: FocusEvent<any>) => void;
  onChange: (event: string | ChangeEvent<any>) => void;
  onSaveClick: () => void;
  shouldAskConfirmationOnModalClose: boolean;
  showLoadingForDeleteButton: boolean;
  showLoadingForSaveButton: boolean;
  workExperiences: ProfileProps['candidate']['workExperiences'];
} & Pick<
  FormikState<{
    company_name: string;
    currently_working: boolean;
    description: string;
    endDateMonth: number | null;
    endDateYear: number | null;
    job_title: string;
    location: string;
    startDateMonth: number | null;
    startDateYear: number | null;
  }>,
  'values' | 'errors' | 'touched'
>) {
  return (
    <>
      <WorkExperiencesSection
        workExperiences={workExperiences}
        onAddClick={onAddClick}
        onEditClick={onEditClick}
      />
      <EditModal
        isOpen={isModalOpen}
        onClose={onCloseModalClick}
        initialRef={initialRef}
        shouldConfirmClose={shouldAskConfirmationOnModalClose}
        showLoadingForSaveButton={showLoadingForSaveButton}
        showLoadingForDeleteButton={showLoadingForDeleteButton}
        onSaveClick={onSaveClick}
        OnDeleteClick={() => {
          onDeleteClick(editID || 0);
        }}
        showDeleteButton={editID != null}
        title={editID != null ? 'Edit Experience' : 'Add Experience'}
      >
        <WorkExperiencesForm
          values={values}
          errors={errors}
          touched={touched}
          initialRef={initialRef}
          onChange={onChange}
          onBlur={onBlur}
        />
      </EditModal>
    </>
  );
}
