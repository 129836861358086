import { useCandidateAuth } from 'candidate/utils';
import { BlankScreenLoading } from 'global/components';
import { Route, Switch } from 'react-router-dom';
import { PublicPageLayout } from '../PageLayout';
import { PageNotFound, PrivatePageNotFound } from './PageNotFound';

// TODO: [CA-173] make the PageNotFound coming down as an optional prop
export function SwitchWithPageNotFound({ children }: { children: React.ReactNode }) {
  const auth = useCandidateAuth();
  if (!auth.isResolved && !auth.isError) {
    return <BlankScreenLoading />;
  }

  return (
    <Switch>
      {children}
      <Route>
        {auth.isAuthorized ? (
          <PrivatePageNotFound />
        ) : (
          <PublicPageLayout>
            <PageNotFound />
          </PublicPageLayout>
        )}
      </Route>
    </Switch>
  );
}
