// @ts-nocheck
// NOTE: When testing locally you must use a trusted domain.
//       https://www.intercom.com/help/en/articles/4418-list-trusted-domains-you-use-with-intercom
import { isSmallScreen } from 'global/utils/general';

let hasBooted = false;
let userData = {};
const HIDDEN_ON_PAGE_REGEX = /^\/(auth|job-apply|onboarding|sign-up)/;
const appId = import.meta.env.REACT_APP_INTERCOM_APP_ID;

const hideDefaultLauncher = () => {
  // The default launcher is the icon in the corner. It's meant to show and hide itself based on
  // settings in the admin panel but only seems to check on full page loads and not after the path
  // changes.
  // There is no function to hide it, instead we have to set hide_default_launcher on update and boot.
  return {
    hide_default_launcher:
      isSmallScreen() || !!document.location.pathname.match(HIDDEN_ON_PAGE_REGEX),
  };
};

const boot = () => {
  // We're calling identify and setUserProperties with async functions but we need to make sure
  // that we have all user properties set before we call boot. Missing hash and id will result in an
  // error since a valid hash is required. Missing name and email don't seem to update properly on
  // the user side when set later. I think it's pulling from local storage and doesn't update.
  if (!hasBooted && userData.user_id && userData.user_hash && userData.name && userData.email) {
    window.Intercom('boot', {
      ...userData,
      ...hideDefaultLauncher(),
    });
    hasBooted = true;
  }
};

window.intercomSettings = {
  ...window.intercomSettings,
  hide_default_launcher: true,
  app_id: appId,
};

let hasInitialized = false;

const initialize = async () => {
  hasInitialized = true;
  /* eslint-disable */
  /* prettier-ignore */
  try {
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==='function'){ic('reattach_activator');ic('update',w.intercomSettings)}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src=`https://widget.intercom.io/widget/${ appId }`;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x)}})();
  } catch (e) {
    throw new Error(`On inserting Intercom: ${e.message || e}`);
  }
};

const isInitialized = () => {
  return hasInitialized;
};

const identify = async (id, options) => {
  userData = {
    ...userData,
    user_id: id,
    user_hash: options.intercomUserHash,
  };
  boot();
};

const setUserProperties = async (name, email) => {
  userData = {
    ...userData,
    name,
    email,
  };
  if (hasBooted) {
    window.Intercom('update', {
      ...userData,
      last_request_at: Math.floor(new Date().getTime() / 1000),
      ...hideDefaultLauncher(),
    });
    return;
  }
  boot();
};

const trackPage = async (/* category, name, properties */) => {
  window.Intercom('update', {
    last_request_at: Math.floor(new Date().getTime() / 1000),
    ...hideDefaultLauncher(),
  });
};

/* eslint-disable no-unused-vars */
const notImplemented = (name) => {
  return async () => {
    // console.log(`${name} not implemented`);
  };
};

export default {
  name: 'intercom',
  identify,
  isInitialized,
  trackPage,
  setUserProperties,
  initialize,
  track: notImplemented('track'),
  reset: notImplemented('reset'),
};
