import React from 'react';
import { PublicPageLayout } from 'candidate/components';
import { SignUp as AuthSignUp } from 'global/auth';
import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils/hooks';
import type { SpaceProps, TypographyProps } from '@terminal/design-system';
import { Link as RouterLink } from 'react-router-dom';
import { TwoColumnAuthPageLayout } from 'candidate/app/components';
import handshakeSVG from '../assets/handshake.svg?url';
import earninSVG from '../assets/earnin.svg?url';
import himshersSVG from '../assets/himshers.svg?url';
import armorySVG from '../assets/armory.svg?url';
import benefitsSVG from '../assets/benefits.svg?url';
import clickSVG from '../assets/click.svg?url';
import rolesSVG from '../assets/roles.svg?url';
import lockImage from '../assets/lock.png';
import jobsStackImage from '../assets/job-stack-blurred.png';

type SignUpStepProps = {
  onGoogleLoginClicked: () => void;
  onEmailSignSubmitted: (values: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
  }) => Promise<void>;
  isLoading: boolean;
  shouldHideGoogleSSO: boolean;
  headerProps: {
    title: string | React.ReactNode;
    subtitle: string;
  };
  stepInfo: React.ReactNode;
};

function SignUpStepPerksList(spaceProps: SpaceProps) {
  return (
    <List spacing={6} fontFamily="body" {...spaceProps}>
      <ListItem alignItems="center" display="flex">
        <Image src={rolesSVG} mr={6} />
        Full time roles with top U.S. tech companies.
      </ListItem>
      <ListItem alignItems="center" display="flex">
        <Image src={benefitsSVG} mr={6} />
        Top health benefits and home office perks.
      </ListItem>
      <ListItem alignItems="center" display="flex">
        <Image src={clickSVG} mr={6} />
        Apply in one click to roles that match your background.
      </ListItem>
    </List>
  );
}

function SignUpStepPageHeading({
  title,
  subtitle,
  ...stylingProps
}: { title: string | React.ReactNode; subtitle: string } & SpaceProps & TypographyProps) {
  return (
    <Box {...stylingProps}>
      <Heading as="h1" fontSize={['3xl', '3xl', '4xl']} color="ui.primary" wordBreak="break-word">
        {title}
      </Heading>
      <Heading variant="heading-4" mt={[2, 2, 3]}>
        {subtitle}
      </Heading>
    </Box>
  );
}

function SignUpStepCompaniesList(spaceProps: SpaceProps) {
  return (
    <Box {...spaceProps}>
      <Heading variant="heading-3">Our Clients</Heading>
      <Flex
        mt={3}
        direction={['column', 'column', 'row']}
        alignItems={['center', 'center', 'flex-start']}
      >
        <Image src={earninSVG} w="227px" mt={[3, 3, 0]} alignSelf="center" />
        <Image src={handshakeSVG} ml={[0, 0, 12]} mt={[6, 6, 0]} maxW="227px" />
      </Flex>
      <Flex direction={['column', 'column', 'row']} alignItems={['center', 'center', 'flex-start']}>
        <Image src={himshersSVG} w="227px" alignSelf="center" />
        <Image src={armorySVG} ml={[0, 0, 12]} mt={[6, 6, 0]} w="227px" alignSelf="center" />
      </Flex>
    </Box>
  );
}

function SignUpStepTermsCopy(spaceProps: SpaceProps) {
  return (
    <Flex {...spaceProps} flexDir={['column', 'column', 'row']}>
      <Text variant="hint" mr={[0, 0, 1]} whiteSpace="nowrap">
        By signing up you agree to the
      </Text>
      <Box whiteSpace="nowrap">
        <Link
          fontSize="inherit"
          href="https://terminal.io/terms-of-use"
          isExternal
          display="inline"
        >
          Terms Of Use
        </Link>
        {' & '}
        <Link
          fontSize="inherit"
          href="https://terminal.io/job-applicant-privacy"
          isExternal
          display="inline"
        >
          Job Applicant Notice
        </Link>
      </Box>
    </Flex>
  );
}

function SignUpStepWithoutTabs({
  onGoogleLoginClicked,
  onEmailSignSubmitted,
  isLoading,
  shouldHideGoogleSSO,
  headerProps,
  stepInfo,
}: SignUpStepProps) {
  const isDesktop = useIsViewPortDesktop();
  return (
    <TwoColumnAuthPageLayout
      leftColumn={stepInfo}
      rightColumn={
        <Flex flex={1} zIndex={1} flexDir="column">
          {!isDesktop && (
            <SignUpStepPageHeading
              title={headerProps.title}
              subtitle={headerProps.subtitle}
              textAlign="center"
            />
          )}
          <Flex maxW={['full', 'full', 'ml']} mt={[6, 6, 0]} mb={[9, 9, 0]} flexDir="column">
            <Heading variant="heading-2" mb={6}>
              Sign Up
            </Heading>
            <AuthSignUp.ManualWithSSOSignUp
              onGoogleLoginClicked={onGoogleLoginClicked}
              onEmailSignSubmitted={onEmailSignSubmitted}
              showLoadingForEmailSignUp={isLoading}
              shouldHideGoogleSSO={shouldHideGoogleSSO}
              termsCopy={<SignUpStepTermsCopy />}
            />
          </Flex>
        </Flex>
      }
    />
  );
}

function SignUpStepWithTabs({
  onGoogleLoginClicked,
  onEmailSignSubmitted,
  isLoading,
  headerProps,
  stepInfo,
}: Omit<SignUpStepProps, 'shouldHideGoogleSSO'>) {
  return (
    <Container flexDir="column" flex="1" p={0} justifyContent="center" display="flex">
      <Flex flex={1} zIndex={1} py={6} px={3} flexDir="column">
        <SignUpStepPageHeading
          title={headerProps.title}
          subtitle={headerProps.subtitle}
          textAlign="center"
        />
        <Tabs align="center" isFitted mt={6}>
          <TabList>
            <Tab>Email</Tab>
            <Tab>Social</Tab>
          </TabList>
          <TabPanels>
            <TabPanel pt={3}>
              <Flex p={[3, 3, 8]} flexDir="column">
                <AuthSignUp.ManualSignUpForm
                  onEmailSignSubmitted={onEmailSignSubmitted}
                  showLoadingForEmailSignUp={isLoading}
                />
                <AuthSignUp.SignUpTerms mt={6}>
                  <SignUpStepTermsCopy />
                </AuthSignUp.SignUpTerms>
              </Flex>
            </TabPanel>
            <TabPanel pt={3}>
              <Flex p={[3, 3, 8]} flexDir="column">
                <AuthSignUp.GoogleSignUpSSO mt={1} onGoogleLoginClicked={onGoogleLoginClicked} />
                <AuthSignUp.SignUpTerms mt={6}>
                  <SignUpStepTermsCopy />
                </AuthSignUp.SignUpTerms>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      {stepInfo}
    </Container>
  );
}

export function SignUpStepBase({ children }: { children: React.ReactNode }) {
  return (
    <PublicPageLayout
      headerRightPlaceholderDesktop={
        <Flex alignItems="center">
          <Text variant="caption">Already Have an Account?</Text>
          <Link fontSize="sm" as={RouterLink} to={`/auth${window.location.search}`} ml={2}>
            Login
          </Link>
        </Flex>
      }
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link fontSize="sm" as={RouterLink} to={`/auth/login${window.location.search}`}>
            Login
          </Link>
        </Flex>
      }
    >
      {children}
    </PublicPageLayout>
  );
}

function VariationWithSignUpAtBeginning({
  onGoogleLoginClicked,
  onEmailSignSubmitted,
  isLoading,
  shouldHideGoogleSSO,
}: Omit<SignUpStepProps, 'headerProps' | 'stepInfo'>) {
  const isDesktop = useIsViewPortDesktop();
  const headerProps = {
    title: 'Create Your Profile and Get Matched to Jobs Today',
    subtitle: 'It’s free and easy to join.',
  };

  return (
    <SignUpStepBase>
      {isDesktop || shouldHideGoogleSSO ? (
        <SignUpStepWithoutTabs
          stepInfo={
            <Flex flex={1} zIndex={1} flexDir="column">
              {isDesktop && (
                <SignUpStepPageHeading
                  title={headerProps.title}
                  subtitle={headerProps.subtitle}
                  textAlign="left"
                  mt={-10}
                />
              )}
              <SignUpStepPerksList mt={8} />
              <SignUpStepCompaniesList mt={10} />
            </Flex>
          }
          headerProps={headerProps}
          {...{ onGoogleLoginClicked, onEmailSignSubmitted, isLoading, shouldHideGoogleSSO }}
        />
      ) : (
        <SignUpStepWithTabs
          stepInfo={
            <Flex
              py={10}
              px={3}
              flex={1}
              flexDir="column"
              justifyContent="flex-start"
              bg="bg.tertiary"
            >
              <SignUpStepPerksList />
              <SignUpStepCompaniesList mt={10} />
            </Flex>
          }
          headerProps={headerProps}
          {...{ onGoogleLoginClicked, onEmailSignSubmitted, isLoading }}
        />
      )}
    </SignUpStepBase>
  );
}
function VariationWithPerkList({
  onGoogleLoginClicked,
  onEmailSignSubmitted,
  isLoading,
  shouldHideGoogleSSO,
}: Omit<SignUpStepProps, 'headerProps' | 'stepInfo'>) {
  const isDesktop = useIsViewPortDesktop();
  const headerProps = {
    title: 'Sign up now to unlock your matches!',
    subtitle: `It's free to join.`,
  };

  return (
    <SignUpStepBase>
      {isDesktop || shouldHideGoogleSSO ? (
        <SignUpStepWithoutTabs
          stepInfo={
            <Flex flex={1} zIndex={1} flexDir="column">
              {isDesktop && (
                <SignUpStepPageHeading
                  title={headerProps.title}
                  subtitle={headerProps.subtitle}
                  textAlign="left"
                  mt={-10}
                />
              )}
              <SignUpStepPerksList mt={8} />
              <SignUpStepCompaniesList mt={10} />
            </Flex>
          }
          headerProps={headerProps}
          {...{ onGoogleLoginClicked, onEmailSignSubmitted, isLoading, shouldHideGoogleSSO }}
        />
      ) : (
        <SignUpStepWithTabs
          stepInfo={
            <Flex
              py={10}
              px={3}
              flex={1}
              flexDir="column"
              justifyContent="flex-start"
              bg="bg.tertiary"
            >
              <SignUpStepPerksList />
              <SignUpStepCompaniesList mt={10} />
            </Flex>
          }
          headerProps={headerProps}
          {...{ onGoogleLoginClicked, onEmailSignSubmitted, isLoading }}
        />
      )}
    </SignUpStepBase>
  );
}

function VariationWithBlurredInfo({
  onGoogleLoginClicked,
  onEmailSignSubmitted,
  isLoading,
  shouldHideGoogleSSO,
}: Omit<SignUpStepProps, 'headerProps' | 'stepInfo'>) {
  const isDesktop = useIsViewPortDesktop();
  const headerProps = {
    title: (
      <>
        One Last Step To{' '}
        <Heading as="span" color="text.success">
          Unlock
        </Heading>{' '}
        Your Job Matches
      </>
    ),
    subtitle: 'Join today to start applying to fully remote roles with top U.S. tech companies.',
  };

  return (
    <SignUpStepBase>
      {isDesktop || shouldHideGoogleSSO ? (
        <SignUpStepWithoutTabs
          stepInfo={
            <Flex flex={1} zIndex={1} flexDir="column">
              {isDesktop && (
                <SignUpStepPageHeading
                  title={headerProps.title}
                  subtitle={headerProps.subtitle}
                  textAlign="left"
                  mt={-10}
                />
              )}
              <Flex mt={10} alignItems="center" justifyContent="center" position="relative">
                <Image src={jobsStackImage} />
                <Box
                  bgImage={lockImage}
                  position="absolute"
                  w="full"
                  h="full"
                  top={0}
                  bgRepeat="no-repeat"
                  bgPos="center"
                />
              </Flex>
            </Flex>
          }
          headerProps={headerProps}
          {...{ onGoogleLoginClicked, onEmailSignSubmitted, isLoading, shouldHideGoogleSSO }}
        />
      ) : (
        <SignUpStepWithTabs
          stepInfo={
            <Flex
              py={10}
              px={3}
              pb={16}
              flex={1}
              flexDir="column"
              justifyContent="flex-start"
              bg="bg.tertiary"
            >
              <Flex justifyContent="center" position="relative">
                <Image src={jobsStackImage} />
                <Box
                  bgImage={lockImage}
                  position="absolute"
                  w="full"
                  h="full"
                  top={0}
                  bgRepeat="no-repeat"
                  bgPos="center"
                />
              </Flex>
            </Flex>
          }
          headerProps={headerProps}
          {...{ onGoogleLoginClicked, onEmailSignSubmitted, isLoading }}
        />
      )}
    </SignUpStepBase>
  );
}

export const SignUpStep = {
  // TODO: remove signup at beginning variations
  Variation: {
    WithSignUpAtBeginning: VariationWithSignUpAtBeginning,
    WithPerkList: VariationWithPerkList,
    WithBlurredInfo: VariationWithBlurredInfo,
  },
};
