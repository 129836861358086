import { ConsentManager } from '@segment/consent-manager';
import inEU from '@segment/in-eu';
import { Text, Link, VStack, Button, Box, Container, useToken } from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';

export async function requiresConsent() {
  return (await inEU()) || document.location.search.includes('requireConsent');
}

function otherWriteKeys() {
  if (import.meta.env.REACT_APP_SEGMENT_OTHER_WRITE_KEYS?.startsWith('__')) {
    return [];
  }
  return import.meta.env.REACT_APP_SEGMENT_OTHER_WRITE_KEYS?.replace(' ', '').split(',') || [];
}

export function SegmentConsent() {
  const [bgPrimary, textInverse] = useToken('colors', ['bg.primary', 'text.inverse']);
  // Built in ConsentManager UI uses same desktop design for tablets, so we need to avoid using useIsViewPortDesktop to be consistent
  const isDesktop = useIsViewPortDesktop();

  return (
    <Box w="full" pos="fixed" bottom={0} zIndex="tooltip">
      <Box bgColor="bg.primary" shadow="reverse.md">
        <Container
          sx={{
            /* ConsentManager resets */
            p: { margin: 0 },
            'p:first-child': { margin: 0 },
            '& > div > div': { padding: 0 },
            /* "bannerSubContent" is not optional and adds unnecessary space for our case  */
            'p + p': { display: 'none' },
          }}
        >
          <ConsentManager
            writeKey={import.meta.env.REACT_APP_SEGMENT_CANDIDATE_KEY || ''}
            otherWriteKeys={otherWriteKeys()}
            shouldRequireConsent={requiresConsent}
            cdnHost={
              !import.meta.env.REACT_APP_SEGMENT_CDN_DOMAIN?.startsWith('__')
                ? import.meta.env.REACT_APP_SEGMENT_CDN_DOMAIN
                : 'cdn.segment.com'
            }
            defaultDestinationBehavior="imply"
            // TODO: [TP-1473] Fix this
            // eslint-disable-next-line react/no-unstable-nested-components
            bannerActionsBlock={({ acceptAll, changePreferences }) => (
              <VStack spacing={4} pt={[0, 6, 6]} pb={[3, 6, 6]} ml={[0, 10, 10]}>
                <Button
                  variant="primary"
                  onClick={acceptAll}
                  h={8}
                  w="full"
                  minW={['auto', '200px', '200px']}
                >
                  <Text variant="caption" color="text.inverse" fontWeight="bold">
                    Accept All
                  </Text>
                </Button>
                <Link as="button" onClick={changePreferences}>
                  <Text variant="caption" textDecor="underline">
                    Manage Preferences
                  </Text>
                </Link>
              </VStack>
            )}
            bannerHideCloseButton
            bannerBackgroundColor={bgPrimary}
            bannerTextColor={textInverse}
            bannerContent={
              <Box pt={[3, 6, 6]} pb={[0, 6, 6]} mb={[4, 0]}>
                <Text variant={!isDesktop ? 'caption' : 'body'}>
                  We use various cookies that analyze your interaction with this website. Learn more
                  about our{' '}
                  <Link href="https://terminal.io/privacy-policy" target="_blank">
                    Privacy Policy.
                  </Link>
                </Text>
              </Box>
            }
            bannerSubContent=""
            preferencesDialogTitle="Website Data Collection Preferences"
            preferencesDialogContent="We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site."
            cancelDialogTitle="Are you sure you want to cancel?"
            cancelDialogContent="Your preferences have not been saved. By continuing to use our website, you are agreeing to our Website Data Collection Policy."
          />
        </Container>
      </Box>
    </Box>
  );
}
