import {
  Box,
  Grid,
  Flex,
  Text,
  Button,
  EditIcon,
  Tag,
  UnorderedList,
  ListItem,
  ListIcon,
  ArrowRightIcon,
  Link,
  WarningIcon,
} from '@terminal/design-system';
import { ProfileSectionButton } from '../../../components';
import type { ProfileProps } from '../../../Profile.types';

export function WorkExperiencesSection({
  workExperiences,
  onAddClick,
  onEditClick,
}: {
  workExperiences: ProfileProps['candidate']['workExperiences'];
  onAddClick: () => void;
  onEditClick: (
    values: ProfileProps['candidate']['workExperiences'][number]['formValues'],
    editID: number,
  ) => void;
}) {
  return (
    <>
      <Grid templateColumns="1fr" gap={6} w="full">
        {workExperiences.some(({ hasMissingData }) => hasMissingData) && (
          <Flex bg="ui.lighter.warning" py={4} p={[3, 3, 8]} alignItems="center">
            <WarningIcon fontSize="2xl" color="ui.warning" />
            <Flex flexDir="column" ml={6}>
              <Text>
                There are missing information in your{' '}
                <Text as="span" variant="label">
                  Work Experience:
                </Text>
              </Text>
              <UnorderedList spacing={1} mt={1} pl={3}>
                {workExperiences
                  .filter(({ hasMissingData }) => hasMissingData)
                  .map(({ missingDataCTA, id, formValues }) => (
                    <Link
                      onClick={() => {
                        onEditClick(formValues, id);
                      }}
                      key={`missing-data-item-${id}`}
                    >
                      <ListItem>
                        {missingDataCTA}
                        <ListIcon as={ArrowRightIcon} ml={2} />
                      </ListItem>
                    </Link>
                  ))
                  .flat()}
              </UnorderedList>
            </Flex>
          </Flex>
        )}

        {workExperiences.map(
          ({ id, title, subTitle, datesTitle, isCurrent, formValues, description }) => (
            <Box key={`work-experience-${id}`} p={[3, 3, 8]} bg="bg.secondary">
              <Box>
                {isCurrent && (
                  <Tag size="sm" colorScheme="accent-lightest" variant="subtle">
                    Current Company
                  </Tag>
                )}
                <Box>
                  <Flex justifyContent="space-between">
                    <Box>
                      {!!title && (
                        <Text variant="label" mt={3} textTransform="capitalize">
                          {title}
                        </Text>
                      )}
                      {!!subTitle && (
                        <Text textTransform="capitalize" variant="caption" mt={1}>
                          {subTitle}
                        </Text>
                      )}
                      {!!datesTitle && (
                        <Text mt={1} textTransform="capitalize" variant="caption">
                          {datesTitle}
                        </Text>
                      )}
                    </Box>
                    <Flex alignItems="center">
                      <Button
                        size="sm"
                        onClick={() => {
                          onEditClick(formValues, id);
                        }}
                        variant="ghost"
                      >
                        <EditIcon fontSize="md" />
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </Box>
              {description && (
                <Text variant="caption" mt={2} whiteSpace="pre-line">
                  {description}
                </Text>
              )}
            </Box>
          ),
        )}
      </Grid>
      <ProfileSectionButton mt={workExperiences.length ? 6 : 0} onAddClick={onAddClick}>
        Add Work Experience
      </ProfileSectionButton>
    </>
  );
}
