import { ProfileSectionButton } from 'candidate/features/profile/components';
import type { SkillsProps } from 'candidate/shared/modules';
import { Tag, Text, Wrap, WrapItem, Button, EditIcon, Flex, Box } from '@terminal/design-system';

export function SkillsSection({
  skillGroups,
  isAccented,
  onEditClick,
  onAddClick,
}: {
  skillGroups: SkillsProps['skillGroups'];
  isAccented: boolean;
  onEditClick: () => void;
  onAddClick: () => void;
}) {
  return (
    <Box>
      <Box>
        {skillGroups.length > 0 && (
          <Wrap spacing={2} className="notranslate">
            {skillGroups.map((group) =>
              group.skills.map(({ title }) => {
                return (
                  <WrapItem key={`skill-${title}-${skillGroups}`}>
                    <Tag colorScheme="accent-lightest" variant="subtle">
                      <Text whiteSpace="nowrap">{title}</Text>
                    </Tag>
                  </WrapItem>
                );
              }),
            )}
          </Wrap>
        )}
        {isAccented && (
          <ProfileSectionButton onAddClick={onAddClick} accented>
            Add Skill
          </ProfileSectionButton>
        )}
      </Box>
      <Button size="sm" onClick={onEditClick} variant="ghost" mt={6}>
        <Flex alignItems="center">
          <EditIcon fontSize="md" mr={3} /> Edit Skills
        </Flex>
      </Button>
    </Box>
  );
}
