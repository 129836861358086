import { useCandidateAuth } from 'candidate/utils';
import { Redirect, Route } from 'react-router-dom';
import { DocTitle } from './DocTitle';

interface PrivateRouteProps extends React.ComponentProps<typeof Route> {
  authPath: string;
  onBeforeRedirect?: () => void;
  conditionalRedirects?: { condition: boolean; redirectURL: string; search?: string }[];
  title?: string | string[];
}

export function PrivateRoute({
  authPath,
  title,
  onBeforeRedirect,
  conditionalRedirects = [],
  children,
  ...props
}: PrivateRouteProps) {
  const auth = useCandidateAuth();

  const conditionalRedirect = [
    ...conditionalRedirects,
    { condition: !auth.isAuthorized, redirectURL: authPath },
  ].find((x) => x.condition);

  if (onBeforeRedirect && conditionalRedirect?.condition) {
    onBeforeRedirect();
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        conditionalRedirect?.condition ? (
          <Redirect
            to={{
              pathname: conditionalRedirect.redirectURL,
              search: conditionalRedirect?.search,
              state: { from: location },
            }}
          />
        ) : (
          <>
            {/* using the DocTitle makes sure the effects of the Route children run in order
            to prevent a document.title update of parent CustomRoute to override the ones from its child
          */}
            <DocTitle title={title} />
            {children}
          </>
        )
      }
    />
  );
}
