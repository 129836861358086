import { useMutation } from '@apollo/client';
import { SelectProfile } from 'candidate/features/profile/graphql';
import type { ProfileProps, SelectProfileQuery } from 'candidate/features/profile/Profile.types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { UpsertCandidateYearsOfExperienceRange } from './data';
import type {
  UpsertCandidateYearsOfExperienceRangeMutation,
  UpsertCandidateYearsOfExperienceRangeMutationVariables,
} from './data/types/UpsertCandidateYearsOfExperienceRange.query.generated';

export const YearsValidationSchema = Yup.object().shape({
  yearsOfExperienceRange: Yup.mixed().oneOf(
    Object.values(Candidate_Curation_Years_Of_Exp_Range_Choices_Enum),
  ),
});

export function useYearsController({
  candidateYearsOfExperienceRange,
  yearsOfExperienceRangeChoices,
  userID,
  candidateID,
  onSaveSuccess,
}: {
  yearsOfExperienceRangeChoices: ProfileProps['yearsOfExperienceRangeChoices'];
  candidateYearsOfExperienceRange: ProfileProps['candidate']['yearsOfExperienceRange'];
  candidateID: number;
  userID?: number;
  onSaveSuccess: () => void;
}) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const [updateCandidateYearsOfExperience, { loading: isLoading_upsert }] = useMutation<
    UpsertCandidateYearsOfExperienceRangeMutation,
    UpsertCandidateYearsOfExperienceRangeMutationVariables
  >(UpsertCandidateYearsOfExperienceRange, {
    context: {
      role: 'candidate',
    },
    onError: (error) => {
      toast({
        description:
          'Something went wrong trying to update your years of experience. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
    onCompleted: () => {
      onSaveSuccess();
    },
    update(cache, { data: upsertCandidate_yearsOfExperienceRange_data }) {
      try {
        const readData = cache.readQuery<SelectProfileQuery>({
          query: SelectProfile,
          variables: {
            user_id: userID,
          },
        });

        const updatedCandidate = {
          ...readData?.candidates[0],
          candidate_curation_detail: {
            ...readData?.candidates[0]?.candidate_curation_detail,
            ...upsertCandidate_yearsOfExperienceRange_data?.insert_candidate_curation_detail
              ?.returning[0],
          },
        };

        cache.writeQuery({
          query: SelectProfile,
          broadcast: true,
          variables: {
            user_id: userID as number,
          },
          data: { ...readData, candidates: [updatedCandidate] },
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  const formik = useFormik<{
    yearsOfExperienceRange: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum;
  }>({
    initialValues: {
      yearsOfExperienceRange: (
        yearsOfExperienceRangeChoices.find(
          (choice) => choice.name === candidateYearsOfExperienceRange,
        ) || { name: '' }
      ).name as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
    },
    validationSchema: YearsValidationSchema,
    onSubmit: ({ yearsOfExperienceRange }) => {
      updateCandidateYearsOfExperience({
        variables: {
          candidate_id: candidateID,
          years_of_exp_range: yearsOfExperienceRange,
        },
      });
    },
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const handleOnCheckboxClick = (
    years_of_exp_range: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  ) => {
    formik.setFieldValue('yearsOfExperienceRange', years_of_exp_range);
  };

  return {
    formik,
    handleOnCheckboxClick,
    isLoading_upsert,
  };
}
