import type { ChangeEvent, ChangeEventHandler, ComponentProps, FocusEventHandler } from 'react';
import { useRef } from 'react';
import type { FormikState } from 'formik';
import {
  Box,
  Button,
  Center,
  DeleteIcon,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
  Divider,
  CheckboxToggle,
  Select,
} from '@terminal/design-system';
import type { serializerYearsOfExperienceRange } from 'candidate/shared/modules/years';
import ArrowDown from '../../assets/arrow-down.svg?react';
import ArrowUp from '../../assets/arrow-up.svg?react';
import type { ResumeAndExperienceForm } from './ResumeAndExperience.controller';
import { ApplicationSectionBox } from '../../components';
import { Section } from '../../DirectApplication.types';

export function ResumeAndExperience({
  errors,
  onBlur,
  onChange,
  onNextClick,
  onResumeDeleteClick,
  onResumeFileChange,
  onResumeUploadClick,
  isOpened,
  onSectionBoxEditClick,
  resumeFileBrowseRef,
  values,
  touched,
  onShowMoreOrLessSkillsClick,
  skillsOptions,
  shouldDisplayShowMore,
  isCompleted,
  hanldeOnSkillClick,
  sectionSubmitButtonIsLoading,
  yearsOfExperienceRangeChoices,
  sectionSubmitButtonIsDisabled,
}: {
  hanldeOnSkillClick: (skillID: number) => (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLSelectElement>;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  sectionSubmitButtonIsLoading: boolean;
  onNextClick: () => void;
  onResumeDeleteClick: () => void;
  onResumeFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onResumeUploadClick: () => void;
  resumeFileBrowseRef?: React.MutableRefObject<null>;
  skillsOptions: Array<{ id: number; name: string }>;
  onShowMoreOrLessSkillsClick: () => void;
  shouldDisplayShowMore: boolean;
  sectionSubmitButtonIsDisabled: boolean;
  yearsOfExperienceRangeChoices: ReturnType<typeof serializerYearsOfExperienceRange>;
} & Pick<FormikState<ResumeAndExperienceForm>, 'errors' | 'values' | 'touched'> &
  Omit<ComponentProps<typeof ApplicationSectionBox>, 'title' | 'children'>) {
  const initialRef = useRef(null);

  return (
    <ApplicationSectionBox
      section={Section.experience}
      onSectionBoxEditClick={onSectionBoxEditClick}
      isCompleted={isCompleted}
      isOpened={isOpened}
      title="Resume & Experience"
      spaceAboveDivider={8}
    >
      <>
        <Text variant="label" mt="8">
          Resume
        </Text>
        <Text variant="caption" mt="2" color="text.secondary" fontWeight="thin">
          Please include one of the following:
        </Text>
        <Box w="full" backgroundColor="bg.secondary" mt="4" borderRadius="base" p={6}>
          <VStack spacing={6}>
            <FormControl isInvalid={touched.resume && !!errors.resume} justifyContent="center">
              <Center>
                {!values.resume ? (
                  <>
                    <input
                      type="file"
                      ref={resumeFileBrowseRef}
                      onChange={onResumeFileChange}
                      style={{ height: 0, overflow: 'hidden', position: 'absolute' }}
                      // hiding the input this way lets the user open the file dialog by pressing enter
                    />
                    <Button
                      ref={initialRef}
                      variant="primary"
                      backgroundColor="accent.darkest"
                      w="full"
                      onClick={onResumeUploadClick}
                    >
                      Upload Resume/CV
                    </Button>
                  </>
                ) : (
                  <Center>
                    <Text variant="label">{values.resume?.name}</Text>
                    <Button variant="ghost" ml={4} size="sm" onClick={onResumeDeleteClick}>
                      <DeleteIcon color="ui.error" />
                    </Button>
                  </Center>
                )}
              </Center>
              <FormErrorMessage justifyContent="center" mt={3}>
                {errors.resume}
              </FormErrorMessage>
              <Text variant="caption" color="text.secondary" mt={3}>
                For best results use: DOC, DOCX, TXT. Max. file size 5mb.
              </Text>
            </FormControl>

            <Flex width="full" flexDir="row" alignItems="center" mt="6" justifyContent="center">
              <Divider orientation="horizontal" flex="1" />

              <Text variant="hint" color="text.disabled" px="2" fontWeight="bold">
                OR
              </Text>

              <Divider orientation="horizontal" flex="1" />
            </Flex>

            <FormControl isInvalid={touched.linkedin && !!errors.linkedin}>
              <FormLabel htmlFor="linkedin">LinkedIn</FormLabel>
              <Input
                id="linkedin"
                name="linkedin"
                onBlur={onBlur}
                onChange={onChange}
                placeholder="www.linkedin.com/in"
                value={values.linkedin}
                autoCapitalize="none"
                backgroundColor="bg.primary"
                spellCheck={false}
              />
              <FormErrorMessage>{errors.linkedin}</FormErrorMessage>
            </FormControl>
          </VStack>
        </Box>

        <Divider orientation="horizontal" flex="1" mt="6" />

        <FormControl mt="14" id="yoe" isInvalid={!!errors.yoe && !!touched.yoe}>
          <FormLabel htmlFor="yoe">Years of Experience</FormLabel>

          <Text variant="caption" color="text.secondary" mt="2">
            Please provide your total years of relevant experience for this role.
          </Text>

          <Select
            mt={4}
            id="yoe"
            name="yoe"
            onBlur={onBlur}
            onChange={onChange}
            placeholder="Select years of experience"
          >
            {yearsOfExperienceRangeChoices.map(({ name, displayName }) => (
              <option value={String(name)} key={`location-${name}`}>
                {displayName}
              </option>
            ))}
          </Select>

          <FormErrorMessage role="alert">{errors.yoe}</FormErrorMessage>
        </FormControl>

        <Divider orientation="horizontal" flex="1" mt="14" />

        <Text variant="label" mt="14">
          Top Skills
        </Text>

        <Text variant="caption" color="text.secondary" mt="2">
          Select at least 3 of your top skills
        </Text>

        <FormControl
          id="candidateSkills"
          isInvalid={!!errors.candidateSkills && !!touched.candidateSkills}
        >
          <FormErrorMessage mt={3}>
            {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
            <>{errors.candidateSkills}</>
          </FormErrorMessage>
        </FormControl>

        <Box mt="4">
          {skillsOptions.map(({ id, name }) => {
            return (
              <CheckboxToggle
                key={id}
                display="inline-block"
                marginRight="4"
                marginTop="4"
                onChange={hanldeOnSkillClick(id)}
                className="notranslate"
              >
                {name}
              </CheckboxToggle>
            );
          })}

          <Button
            _hover={{ backgroundColor: 'initial' }}
            _focus={{ boxShadow: 'none' }}
            onClick={onShowMoreOrLessSkillsClick}
            variant="ghost"
            rightIcon={shouldDisplayShowMore ? <ArrowDown /> : <ArrowUp />}
            w="full"
            textAlign="center"
            mt="4"
          >
            Show {shouldDisplayShowMore ? 'More' : 'Less'}
          </Button>
        </Box>

        <Button
          isLoading={sectionSubmitButtonIsLoading}
          isDisabled={sectionSubmitButtonIsDisabled}
          w="full"
          onClick={onNextClick}
          mt={14}
        >
          Continue
        </Button>
      </>
    </ApplicationSectionBox>
  );
}
