import { Icon } from '@terminal/design-system';

export function MexicoFlagIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
        <path d="M1 3L8 1.5V25L1 27.5V3Z" fill="#7AA550" />
        <path d="M33 3.5L25.5 5V28.5L33 27V3.5Z" fill="#F6623A" />
        <path d="M25.5 5L8 1V25L25 29L25.5 5Z" fill="#fff" />
        <path
          d="M1 33V3"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m1 27.057 4.4857-1.2933c3.7591-1.085 7.8345-0.5992 11.184 1.3333 3.2685 1.8852 7.2314 2.3961 10.921 1.408l4.2482-1.1373c0.6867-0.1844 1.1602-0.773 1.1605-1.4427v-21.076c-5e-4 -0.47053-0.2362-0.91364-0.6369-1.197-0.4006-0.28341-0.9198-0.37433-1.4026-0.24562l-3.3692 0.90267c-3.6899 0.98807-7.6528 0.47715-10.921-1.408-3.3498-1.9325-7.4252-2.4183-11.184-1.3333l-4.4857 1.292"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line x1="8.15" x2="8.15" y1="1" y2="26" stroke="#25383F" strokeWidth="1.5" />
        <line x1="25.217" x2="25.217" y1="4" y2="29" stroke="#25383F" strokeWidth="1.5" />
        <path
          d="m20.784 15.25c0 2.3023-2.0057 4.25-4.5833 4.25-2.5777 0-4.5834-1.9477-4.5834-4.25s2.0057-4.25 4.5834-4.25c2.5776 0 4.5833 1.9477 4.5833 4.25z"
          fill="#25383F"
          stroke="#25383F"
          strokeWidth="1.5"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 33V3"
      />
      <path
        stroke="currentColor"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 27.057l4.486-1.294c3.759-1.085 7.834-.599 11.184 1.334 3.268 1.885 7.231 2.396 10.921 1.408l4.248-1.138c.687-.184 1.16-.773 1.161-1.442V4.849c0-.47-.236-.914-.637-1.197-.4-.284-.92-.375-1.402-.246l-3.37.903c-3.69.988-7.652.477-10.921-1.408C13.32.968 9.245.482 5.486 1.567L1 2.86"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        d="M8.15 1L8.15 26M25.217 4L25.217 29M20.783 15.25c0 2.302-2.005 4.25-4.583 4.25-2.578 0-4.583-1.948-4.583-4.25S13.622 11 16.2 11c2.578 0 4.583 1.948 4.583 4.25z"
      />
    </Icon>
  );
}
