import { CheckboxCard, Button } from '@terminal/design-system';
import type { HStepsProps } from 'candidate/components';
import type { ProfileProps } from '../../../../Profile.types';
import { StepPageLayout } from '../../components/StepPageLayout';
import { CheckboxCardGroup } from '../../components';

export function AvailabilityStep({
  availabilityOptions,
  selectedAvailability,
  isNextDisabled,
  onCheckboxClick,
  onNextClick,
  onSignOutClick,
  userFullName,
  progressProps,
}: {
  availabilityOptions: ProfileProps['availabilityOptions'];
  selectedAvailability: string;
  isNextDisabled: boolean;
  onCheckboxClick: (selectedAvailability: string) => void;
  onNextClick: () => void;
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  progressProps: HStepsProps;
}) {
  return (
    <StepPageLayout
      progressProps={progressProps}
      title="When Are You Available?"
      description="We'll match you to the right roles based on your availability."
      footer={
        <Button
          onClick={onNextClick}
          w={['full', 64]}
          disabled={isNextDisabled}
          disableIOSHoverBugHandler
        >
          Next
        </Button>
      }
      {...{ userFullName, onSignOutClick }}
    >
      <CheckboxCardGroup>
        {Object.entries(availabilityOptions).map(([value, label]) => (
          <CheckboxCard
            aria-checked={value === selectedAvailability}
            onChange={() => onCheckboxClick(value)}
            isChecked={value === selectedAvailability}
            key={`insider-role-${value}`}
            value={value}
            name="candidateAvailability"
            role="checkbox"
          >
            {label}
          </CheckboxCard>
        ))}
      </CheckboxCardGroup>
    </StepPageLayout>
  );
}
