import type { SelectCurrencyExchangeRatesQuery } from 'candidate/shared/modules';
import type { JobSuggestionProps } from 'candidate/shared/modules/job/data/JobSuggestions.types';
import type { SelectCandidateApplicableJobsForBrowsingQuery } from '../../../../shared/modules/job/data/types/SelectCandidateApplicableJobsForBrowsing.query.generated';
import type { SelectCandidateQuery } from '../../../../shared/modules/job/data/types/SelectCandidate.query.generated';
import { serializeJobDetails } from '../../utils';

export function serializerForAllJobs({
  allJobsData,
  candidateInfo = {
    country: null,
    talentPartnerEmail: null,
    furthestCandidateCurationWorkflow: null,
  },
  jobSuggestions,
  conversionTable,
}: {
  allJobsData: SelectCandidateApplicableJobsForBrowsingQuery;
  candidateInfo: {
    country:
      | Exclude<SelectCandidateQuery['candidate'][number]['country_choice'], null>['name']
      | null;
    talentPartnerEmail: string | null | undefined;
    furthestCandidateCurationWorkflow:
      | NonNullable<
          SelectCandidateQuery['candidate'][number]['furthest_candidate_curation_workflow']
        >['status']
      | null;
  };
  jobSuggestions?: JobSuggestionProps[];
  conversionTable: SelectCurrencyExchangeRatesQuery['exchange_rates'] | undefined;
}): {
  /**
   * All Jobs that are not on the suggested list
   */
  otherJobs: ReturnType<typeof serializeJobDetails>[];
} {
  const allJobs = allJobsData?.icims_job?.map((job) =>
    serializeJobDetails({
      job,
      candidateInfo: {
        // candidateSkills and yearsOfExperience are needed to get Top Applicant Jobs
        // but AllJobs can't be a TA, so no information is needed
        candidateSkills: null,
        yearsOfExperience: null,
        ...candidateInfo,
      },
      // TODO [CAND-1288]: define a better way to pass this only with SelectCandidateApplicableJobsForBrowsingQuery (not when public)
      applicant_jobs:
        (allJobsData as SelectCandidateApplicableJobsForBrowsingQuery)?.applicant_job || [],
      candidate_jobs:
        (allJobsData as SelectCandidateApplicableJobsForBrowsingQuery)?.candidate_job || [],
      conversionTable,
    }),
  );

  if (!jobSuggestions) {
    return {
      otherJobs: allJobsData ? allJobs : [],
    };
  }

  // Other Jobs section should not include jobs that are already on `suggested` section
  const allJobsWithoutDuplicates = allJobs?.filter((job) => {
    return !jobSuggestions.find((suggestedJob) => {
      return suggestedJob.id === job.id;
    });
  });

  return {
    otherJobs: allJobsData ? allJobsWithoutDuplicates : [],
  };
}
