import { Badge, Box, Flex, Grid, HStack, Image, Tag, Text } from '@chakra-ui/react';
import { JobDrawerDetails } from 'candidate/shared/modules';
import { Button, Heading } from '@terminal/design-system';
import type { MyJob } from '../../shared/modules/job/MyJobs/myJobs.serializer';
import { selectBadgeColor } from '../../shared/modules/job/MyJobs/myJobs.serializer';

function EmptyState({
  image,
  title,
  description,
  ctaText,
  onClickCTA,
}: {
  image: string;
  title: string;
  description: string;
  ctaText?: string;
  onClickCTA?: () => void;
}) {
  return (
    <Flex alignItems="center" flexDir="column" justifyContent="center" textAlign="center" mt={14}>
      <Box>
        <Image src={image} />
      </Box>
      <Heading variant="heading-2" mt={6}>
        {title}
      </Heading>
      <Text mt={2} variant="body">
        {description}
      </Text>
      {ctaText && (
        <Button mt={6} variant="primary" onClick={onClickCTA}>
          {ctaText}
        </Button>
      )}
    </Flex>
  );
}

function JobCard({ job, onClick }: { job: MyJob['summary']; onClick: () => void }) {
  const skills_maxLength = 2;

  return (
    <Box
      backgroundColor="bg.primary"
      border="1px solid"
      borderColor="ui.secondary"
      borderRadius={4}
      p={6}
      position="relative"
      onClick={onClick}
      cursor="pointer"
      _hover={{
        backgroundColor: 'accent.lightest',
      }}
    >
      {job.badge && (
        <Badge
          colorScheme={selectBadgeColor(job.badge.label)}
          position="absolute"
          top={6}
          right={6}
        >
          <Text variant="caption" color="text.primary" fontWeight="bold">
            {job.badge.label}
          </Text>
        </Badge>
      )}
      <Flex h={14} maxW={40} alignItems="left">
        <Image src={job.logo_sq || job.logo || undefined} alt={`${job.companyName} logo`} />
      </Flex>

      <Text mt={3} variant="caption">
        {job.companyName}
      </Text>
      <Text variant="label">{job.jobTitle}</Text>
      {job.salary && (
        <Badge colorScheme="disabled" mt={3}>
          <Text variant="caption" color="text.primary">
            {job.salary}
          </Text>
        </Badge>
      )}
      <HStack mt={3} spacing={2}>
        {job.skills.slice(0, skills_maxLength).map((skill) => (
          <Tag
            className="notranslate"
            key={`job-suggestion-skill-${skill}`}
            display="block"
            colorScheme="accent-lightest"
            isTruncated
            title={skill}
            pos="relative"
            flexShrink={1}
            size="sm"
            variant="subtle"
          >
            {skill}
          </Tag>
        ))}
        {job.skills.length > skills_maxLength! && (
          <Box flexShrink={0}>
            <Text variant="caption">{`+${job.skills.length - skills_maxLength!} More`}</Text>
          </Box>
        )}
      </HStack>
      <Text mt={3} variant="caption" color="text.secondary">
        {job.updatedAt}
      </Text>
    </Box>
  );
}

export function MyJobsPage({
  emptyStateCopies,
  jobs,
  onJobDetailClose,
  selectedJob,
  handleApplyButtonClick,
  onCardClick,
  isApplyButtonLoading,
  handleDeclinedButtonClick,
  isDeclinedButtonLoading,
}: {
  emptyStateCopies: {
    image: string;
    title: string;
    description: string;
    ctaText?: string;
    onClickCTA?: () => void;
  } | null;
  jobs: MyJob[];
  selectedJob: MyJob | null;
  onCardClick: (jobID: number | null) => void;
  onJobDetailClose: () => void;
  handleApplyButtonClick?: () => void;
  isApplyButtonLoading?: boolean;
  handleDeclinedButtonClick?: () => void;
  isDeclinedButtonLoading?: boolean;
}) {
  return (
    <>
      {jobs.length ? (
        <Grid templateColumns={['1fr', '1fr', 'repeat(3, 1fr)']} gap={6} mt={6}>
          {jobs.map((job) => (
            <JobCard key={job.jobID} job={job.summary} onClick={() => onCardClick(job.jobID)} />
          ))}
        </Grid>
      ) : (
        emptyStateCopies && <EmptyState {...emptyStateCopies} />
      )}

      <JobDrawerDetails
        jobDetails={selectedJob}
        isOpen={!!selectedJob}
        onClose={onJobDetailClose}
        handleApplyButtonClick={handleApplyButtonClick}
        isApplyButtonLoading={isApplyButtonLoading}
        handleDeclinedButtonClick={handleDeclinedButtonClick}
        isDeclinedButtonLoading={isDeclinedButtonLoading}
      />
    </>
  );
}
