import type { SkillsProps } from 'candidate/shared/modules';
import { serializerYearsOfExperienceRange } from 'candidate/shared/modules/years';
import type { SelectDirectApplicationInfoQuery } from './DirectApplication.types';

export function serializerForDirectApplication(
  data: SelectDirectApplicationInfoQuery | undefined,
): {
  jobApplicationInfo: {
    id: number | null;
    companyName: string | null;
    position: string | null;
    location: string | null;
    youAppliedForCopy: string;
  };
  skillOptionsData: {
    skillsCompetencyOptions: SkillsProps['skillCompetencyChoices'];
    skillsOptions: Omit<SkillsProps['skillChoices'][number], 'roles'>[];
  };
  applicationSectionsPageTitle: string;
  yearsOfExperienceRangeChoices: ReturnType<typeof serializerYearsOfExperienceRange>;
} {
  const companyName = data?.icims_job[0]?.icims_company?.name || null;
  const position = data?.icims_job[0]?.jobtitle || null;

  const applicationSectionsPageTitle = position ? `Apply for ${position}` : 'Apply';

  const yearsOfExperienceRangeChoices = serializerYearsOfExperienceRange(
    data ? data.candidate_curation_years_of_exp_range_choices : [],
  );

  return {
    applicationSectionsPageTitle,
    jobApplicationInfo: {
      id: data?.icims_job[0]?.profile_id ?? null,
      companyName,
      position,
      location:
        data?.icims_job[0]?.icims_job_locations
          .map((jobLocation) => {
            return jobLocation?.icims_location?.value || null;
          })
          .filter(Boolean)
          .join('; ') || null,
      youAppliedForCopy: `You applied for ${position} at ${companyName}.`,
    },
    skillOptionsData: {
      skillsCompetencyOptions: data?.candidate_skill_comp_choices.map(({ value }) => value) || [],
      skillsOptions: data?.skill_options.slice().sort((a, b) => a.name.localeCompare(b.name)) || [],
    },
    yearsOfExperienceRangeChoices,
  };
}
