/* eslint-disable func-names */
import { useRolesController } from 'candidate/shared/modules/roles/useRolesController';
import { useToast } from '@terminal/design-system';
import type { ProfileProps } from '../../Profile.types';
import { RolesForm } from './RolesForm';

export function RolesController({
  candidateRoles,
  userID,
  candidateID,
  roleChoices,
  onModalClose,
  onModalOpen,
}: {
  candidateRoles: ProfileProps['candidate']['roles'];
  candidateID: number;
  userID?: number;
  roleChoices: ProfileProps['roleChoices'];
  onModalClose: () => void;
  onModalOpen: () => void;
}) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const onSaveSuccess = () => {
    toast({
      title: 'Saved Successfully!',
      description: 'Your changes were saved successfully.',
      status: 'success',
    });
    onModalClose();
  };

  const {
    formik,
    handleOnCheckboxClick,
    handleOnSaveClick,
    hasFormValuesChanged,
    isLoading_upsert,
  } = useRolesController({
    candidateRoles,
    userID,
    candidateID,
    onModalClose,
    onModalOpen,
    onSaveSuccess,
  });

  return (
    <RolesForm
      rolesOptions={roleChoices}
      values={formik.values}
      errors={formik.errors}
      onCheckboxClick={handleOnCheckboxClick}
      showSaveButton={hasFormValuesChanged()}
      showLoadingForSaveButton={isLoading_upsert}
      onSaveClick={handleOnSaveClick}
    />
  );
}
