import { useMutation } from '@apollo/client';
import Sentry from 'global/sentry';
import { useToast } from '@terminal/design-system';
import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { SelectProfile } from '../../graphql';
import type { SelectProfileQuery } from '../../graphql/types';
import type { ProfileProps } from '../../Profile.types';
import { trackProfile } from '../../Profile.utils';
import {
  DeleteCandidateEducation,
  UpsertCandidateEducation,
  serializerForEducationsUpsert,
} from './data';
import type {
  DeleteCandidateEducationMutation,
  DeleteCandidateEducationMutationVariables,
  UpsertCandidateEducationMutation,
  UpsertCandidateEducationMutationVariables,
} from './data';
import { educationsForm_validationSchema } from './EducationsForm.validation';

export function useEducationsController({
  educations,
  userID,
  candidateID,
  onModalClose,
  onModalOpen,
}: {
  educations: ProfileProps['candidate']['educations'];
  candidateID: number;
  userID?: number;
  onModalClose: () => void;
  onModalOpen: () => void;
}) {
  const initialRef = useRef(null);
  const [editID, setEditID] = useState<number | null>(null);

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const addInitialValues: ProfileProps['candidate']['educations'][number]['formValues'] = {
    concentration: '',
    degree: null,
    school_name: '',
    start_year: null,
    graduation_year: null,
  };

  const [initialValues, setInitialValues] =
    useState<ProfileProps['candidate']['educations'][number]['formValues']>(addInitialValues);

  const resetEditForm = () => {
    setInitialValues(addInitialValues);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.resetForm();
    setEditID(null);
  };

  const [upsert, { loading: isLoading_upsert }] = useMutation<
    UpsertCandidateEducationMutation,
    UpsertCandidateEducationMutationVariables
  >(UpsertCandidateEducation, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to update your educations. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
    onCompleted: () => {
      onModalClose();
      resetEditForm();
      if (educations.length) {
        trackProfile('entry-edited', { section: 'Education' });
      } else {
        trackProfile('entry-added', { section: 'Education' });
      }
    },
    update(cache, { data: upsertEducationData }) {
      try {
        const readData = cache.readQuery<SelectProfileQuery>({
          query: SelectProfile,
          variables: {
            user_id: userID,
          },
        });

        const candidateCurrentEducations = readData?.candidates[0]?.candidate_educations || [];

        if (
          candidateCurrentEducations?.some(
            (education) =>
              education.id === upsertEducationData?.insert_candidate_education?.returning[0]?.id,
          )
        ) {
          // when item already exist in cache, we can allow apollo to automatically update its cache
          return;
        }

        const updatedCandidate = {
          ...readData?.candidates[0],
          candidate_educations: [
            ...candidateCurrentEducations,
            upsertEducationData?.insert_candidate_education?.returning[0],
          ],
        };

        cache.writeQuery({
          query: SelectProfile,
          broadcast: true,
          variables: {
            user_id: userID as number,
          },
          data: { ...readData, candidates: [updatedCandidate] },
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  const [deleteEducation, { loading: isLoading_delete }] = useMutation<
    DeleteCandidateEducationMutation,
    DeleteCandidateEducationMutationVariables
  >(DeleteCandidateEducation, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to delete your education. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
    onCompleted: () => {
      resetEditForm();
      onModalClose();
      trackProfile('entry-deleted', { section: 'Education' });
    },
    update(cache, { data: deleteEducationData }) {
      try {
        const readData = cache.readQuery<SelectProfileQuery>({
          query: SelectProfile,
          variables: {
            user_id: userID as number,
          },
        });

        const candidateCurrentEducations = readData?.candidates[0]?.candidate_educations || [];

        const newCandidate = {
          ...readData?.candidates[0],
          candidate_educations: candidateCurrentEducations.filter(
            (education) =>
              education.id !== deleteEducationData?.delete_candidate_education_by_pk?.id,
          ),
        };

        cache.writeQuery({
          query: SelectProfile,
          broadcast: true,
          variables: {
            user_id: userID as number,
          },
          data: { ...readData, candidates: [newCandidate] },
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  const formik = useFormik<ProfileProps['candidate']['educations'][number]['formValues']>({
    initialValues,
    validationSchema: educationsForm_validationSchema,
    onSubmit: (values) => {
      upsert({
        variables: serializerForEducationsUpsert({ values, candidateID, educationID: editID }),
      });
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const hasFormValuesChanged = () =>
    JSON.stringify(formik.initialValues) !== JSON.stringify(formik.values);

  const handleOnClose = () => {
    formik.resetForm();
    onModalClose();
    resetEditForm();
  };

  const handleOnSaveClick = () => {
    if (hasFormValuesChanged()) {
      formik.handleSubmit();
    } else {
      onModalClose();
      resetEditForm();
    }
  };

  const handleAddClick = () => {
    resetEditForm();
    onModalOpen();
    if (!educations.length) {
      trackProfile('clicked-add-entry', { section: 'Education', context: 'Card' });
    }
    trackProfile('edit-modal-opened', { section: 'Education' });
  };

  const handleEditClick = (
    editInitialValues: ProfileProps['candidate']['educations'][number]['formValues'],
    id: number,
  ) => {
    setEditID(id);
    setInitialValues(editInitialValues);
    onModalOpen();
    trackProfile('edit-modal-opened', { section: 'Education' });
  };

  return {
    deleteEducation,
    editID,
    formik,
    handleAddClick,
    handleEditClick,
    handleOnClose,
    handleOnSaveClick,
    hasFormValuesChanged,
    initialRef,
    isLoading_delete,
    isLoading_upsert,
  };
}
