import { makeVar } from '@apollo/client';
import type { ReactiveVar } from '@apollo/client';

export type TrackingParams = { [keyof: string]: string };

function createUpdateTrackingParams(trackingParamsVar: ReactiveVar<TrackingParams>) {
  return ({
    queryString,
    matchingQueryParams = [],
    matchQueryParamsRegex,
  }: {
    queryString: URLSearchParams;
    matchingQueryParams?: string[];
    matchQueryParamsRegex?: RegExp;
  }) => {
    if (!matchingQueryParams.length && !matchQueryParamsRegex) {
      throw new Error('You need to specify at least of one of the matching params');
    }

    trackingParamsVar({
      // Merging the two so that the preview tracking params overwrite the new one

      // Filtering the querystring based on the matching params
      ...Object.fromEntries(
        Array.from(queryString).filter(([key]) => {
          if (matchingQueryParams.includes(key)) return true;
          if (matchQueryParamsRegex && key.match(matchQueryParamsRegex)) return true;
          return false;
        }),
      ),

      // Spreading the previous tracking params
      ...trackingParamsVar(),
    });
  };
}

const initialValues: TrackingParams = {};
// Caching the initial value into Apollo reactive var
const trackingParamsVar: ReactiveVar<TrackingParams> = makeVar<TrackingParams>(initialValues);

export const trackingParamsState = {
  /**
   * Inserts the new matching query params into the state. It does not overwrite the previous values.
   */
  insert: createUpdateTrackingParams(trackingParamsVar),
  stateVar: trackingParamsVar,
  reset: () => {
    trackingParamsVar(initialValues);
  },
};
