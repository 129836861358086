import { useFormik } from 'formik';
import { useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import { useMutation } from '@apollo/client';
import type { Candidate_Availability_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import type { HStepsProps } from 'candidate/components';
import type {
  ProfileProps,
  UpdateCandidatePersonalInfoMutation,
  UpdateCandidatePersonalInfoMutationVariables,
} from '../../../../Profile.types';
import { UpdateCandidatePersonalInfo } from '../../../../graphql';
import { AvailabilityStep } from './AvailabilityStep';

export const filteredAvailabilityOptions = {
  Interested_And_Available_Now: 'Right Now!',
  Interested_And_Available_Soon_1_3_Months: 'In 1-3 months',
  Interested_And_Available_Later_3_Plus_Months: 'More than 3 months',
};

export function AvailabilityController({
  availabilityOptions,
  candidateAvailability,
  candidateID,
  onStepCompleteSuccess,
  onSignOutClick,
  userFullName,
  progressProps,
}: {
  availabilityOptions: ProfileProps['availabilityOptions'];
  candidateAvailability: string;
  candidateID: number;
  onStepCompleteSuccess: () => void;
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  progressProps: HStepsProps;
}) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const [updatePersonalInfo, { loading: isLoading_upsert }] = useMutation<
    UpdateCandidatePersonalInfoMutation,
    UpdateCandidatePersonalInfoMutationVariables
  >(UpdateCandidatePersonalInfo, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to update your availability. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
    onCompleted: () => {
      onStepCompleteSuccess();
    },
  });

  const formik = useFormik<{
    candidateAvailability: Candidate_Availability_Choices_Enum;
  }>({
    initialValues: {
      candidateAvailability: (Object.entries(availabilityOptions).filter(
        ([, description]: [string, string]) => description === candidateAvailability,
      )[0] || [''])[0] as Candidate_Availability_Choices_Enum,
    },
    onSubmit: (values) =>
      updatePersonalInfo({
        variables: {
          candidate_id: candidateID,
          candidate_set_input: {
            availability: values.candidateAvailability,
          },
        },
      }),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const handleOnCheckboxClick = (selectedAvailability: string) => {
    formik.setFieldValue('candidateAvailability', selectedAvailability);
  };

  return (
    <AvailabilityStep
      availabilityOptions={filteredAvailabilityOptions}
      selectedAvailability={formik.values.candidateAvailability ?? ''}
      isNextDisabled={isLoading_upsert || !formik.values.candidateAvailability}
      onCheckboxClick={handleOnCheckboxClick}
      onNextClick={formik.handleSubmit}
      onSignOutClick={onSignOutClick}
      userFullName={userFullName}
      progressProps={progressProps}
    />
  );
}
