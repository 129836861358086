import React, { useEffect, useState } from 'react';
import type { Preferences } from '@segment/consent-manager/types/types';

import {
  loadPreferences,
  onPreferencesSaved as onSegmentPreferencesSaved,
} from '@segment/consent-manager';

import * as events from 'global/events';
import mixpanel from 'global/events/mixpanel';
import segment from 'global/events/segment';
import intercom from 'global/events/intercom';
import { BlankScreenLoading } from 'global/components';
import { requiresConsent, SegmentConsent } from './components';

const segmentEnabled = !import.meta.env.REACT_APP_SEGMENT_CANDIDATE_KEY?.startsWith('__');
const segmentCdn = !import.meta.env.REACT_APP_SEGMENT_CDN_DOMAIN?.startsWith('__');

/**
 *  If we initialize for non-EU users, all trackers are enabled by default.
 *
 *  If we initialize for EU users, only Segment is enabled for the SegmentConsent plugin
 *    and other trackers can be initialized when the user consents
 */
async function initializeTracking(
  prefs: Preferences,
  isConsentRequired: boolean,
  trackerMetadata: any,
) {
  // Always try to initialize Segment once
  if (!segment.isInitialized() && segmentEnabled) {
    try {
      // Single stage initialization
      await segment.initialize(trackerMetadata);
      events.enableTracker(segment);
    } catch (e) {
      // console.error('Failed to initialize Segment', e);
    }
  }

  if (!mixpanel.isInitialized() && (!isConsentRequired || prefs.destinationPreferences?.Mixpanel)) {
    try {
      await mixpanel.initialize(trackerMetadata);
      events.enableTracker(mixpanel);
    } catch (e) {
      // console.error('Failed to initialize Mixpanel', e);
    }
  }

  if (!intercom.isInitialized() && (!isConsentRequired || prefs.customPreferences?.functional)) {
    try {
      await intercom.initialize();
      events.enableTracker(intercom);
    } catch (e) {
      // console.error('Failed to initialize Intercom', e);
    }
  }
}

/**
 * Notes
 * - If you are outside of the EU, you can override requiresConsent by adding a parameter of
 *   "requireConsent" to the URL. Example: http://app.localhost:3000/?requireConsent
 * - Consent is saved in a cookie on the top domain (IE, .terminal.io)
 * - To re-trigger the consent form, you can delete the "tracking-preferences" cookie
 * - Segment will call identify on the user with their new tracking preferences. You can see this
 *   in the Segment debugger.
 * - Intercom will not appear locally unless you use app.localhost (trusted domain)
 */
export function AppTracking({ children }: { children: React.ReactNode }) {
  const [isTrackingReady, setIsTrackingReady] = useState(false);

  useEffect(() => {
    let cleanUpSegmentPreferencesSavedListener: () => void;
    async function internal() {
      const isConsentRequired = segmentEnabled && (await requiresConsent());

      const trackerMetadata = {
        mixpanelToken: import.meta.env.REACT_APP_MIXPANEL_CANDIDATE_TOKEN,
        segmentWriteKey: import.meta.env.REACT_APP_SEGMENT_CANDIDATE_KEY,
        segmentSkipLoad: isConsentRequired,
        segmentCdn: segmentCdn ? import.meta.env.REACT_APP_SEGMENT_CDN_DOMAIN : null,
        appName: 'Candidate Portal',
      };

      cleanUpSegmentPreferencesSavedListener = onSegmentPreferencesSaved((preferences) => {
        initializeTracking(preferences, isConsentRequired, trackerMetadata);
      });

      await initializeTracking(loadPreferences(), isConsentRequired, trackerMetadata);
      setIsTrackingReady(true);
    }
    internal();

    return () => {
      cleanUpSegmentPreferencesSavedListener?.();
    };
  }, []);

  if (!isTrackingReady) {
    return <BlankScreenLoading />;
  }

  return (
    <>
      {children}
      {segmentEnabled ? (
        <SegmentConsent />
      ) : (
        <div style={{ display: 'none' }}>Segment Disabled</div>
      )}
    </>
  );
}
