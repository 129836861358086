import { ErrorBoundary } from 'global/sentry';
import { useRedirection } from 'global/utils';

import { ErrorFallback, PageLayout } from 'candidate/components';
import { useAppInitData } from 'candidate/utils/general';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import { useCandidateAuth } from 'candidate/utils';

import { JobMatchesController } from './JobMatches.controller';

export function JobMatchesRouter() {
  const auth = useCandidateAuth();
  const redirectTo = useRedirection();
  const { candidateAvailability } = useAppInitData({ userID: auth.user?.id });

  return (
    <PageLayout.Variation.WithAllSections
      candidateAvailability={candidateAvailability}
      shouldNotWrapWithContainer
      onSignOutClick={() => signOut({ auth: firebaseAuth })}
      userFullName={auth.userFullName}
    >
      <ErrorBoundary
        fallback={
          <ErrorFallback
            onActionClick={() => {
              redirectTo('/job/matches');
              window.location.reload();
            }}
            actionTitle="Go back to Job Matches"
          />
        }
      >
        <JobMatchesController />
      </ErrorBoundary>
    </PageLayout.Variation.WithAllSections>
  );
}
