import moment from 'moment';
import type {
  AssessmentProps,
  SelectAssessmentsQuery,
  CandidateAttempt,
  CandidateResult,
} from 'candidate/shared/data-layer/SelectAssessments';

function toResultCopy(result?: CandidateResult) {
  return `Result: ${result?.score}/${result?.max_score}`;
}

enum CandidateStatus { // CandidateAssessmentStatus
  NoCompletion,
  Completed_AwaitingResult,
  Passed_AvailableToTake_InFuture, // have a Passed the test and the test is available to be taken in the future
  Passed_AvailableToTake_Now, // have a Passed the test and the test is available to be taken now
  Failed_AvailableToTake_InFuture, // have a Failed the test and the test is available to be taken in the future
  Failed_AvailableToTake_Now, // have a Failed the test and the test is available to be taken now
  Plagiarism_AvailableToTake_InFuture,
  Plagiarism_AvailableToTake_Now,
}

// TODO: [CAND-201] use this in the other function
function determineAssessmentStatus(
  candidateAttempt: CandidateAttempt,
  minimumPassingPercentage: number,
) {
  if (!candidateAttempt.results.length) {
    return candidateAttempt?.completed
      ? CandidateStatus.Completed_AwaitingResult
      : CandidateStatus.NoCompletion; // This scenario will likely never happen. Has been added so that the function returns for every possible scenario
  }

  if (candidateAttempt.results[0]?.plagiarism_status) {
    return new Date().getTime() < new Date(candidateAttempt.invite_valid_to).getTime()
      ? CandidateStatus.Plagiarism_AvailableToTake_InFuture
      : CandidateStatus.Plagiarism_AvailableToTake_Now;
  }

  const hasPassed = candidateAttempt.results[0]?.percentage_score >= minimumPassingPercentage;

  if (new Date().getTime() < new Date(candidateAttempt.invite_valid_to).getTime()) {
    return hasPassed
      ? CandidateStatus.Passed_AvailableToTake_InFuture
      : CandidateStatus.Failed_AvailableToTake_InFuture;
  }

  return hasPassed
    ? CandidateStatus.Passed_AvailableToTake_Now
    : CandidateStatus.Failed_AvailableToTake_Now;
}

function determineCandidateCurrentAssessmentStatus(
  candidateAttempts: CandidateAttempt[],
  minimumPassingPercentage: number,
): CandidateStatus {
  if (!candidateAttempts.length) {
    return CandidateStatus.NoCompletion;
  }

  // by this point, candidate has at least one attempt

  if (candidateAttempts.every(({ results }) => !results.length)) {
    return candidateAttempts[0]?.completed
      ? CandidateStatus.Completed_AwaitingResult
      : CandidateStatus.NoCompletion;
  }

  if (!candidateAttempts[0]?.results.length && candidateAttempts[0]?.completed) {
    return CandidateStatus.Completed_AwaitingResult;
  }

  // by this point, candidate has at least one attempt and one result

  const allCompletedAttempts = candidateAttempts.filter((attempt) => attempt.results.length);

  if (allCompletedAttempts[0]?.results[0]?.plagiarism_status) {
    return new Date().getTime() < new Date(allCompletedAttempts[0]?.invite_valid_to).getTime()
      ? CandidateStatus.Plagiarism_AvailableToTake_InFuture
      : CandidateStatus.Plagiarism_AvailableToTake_Now;
  }

  const hasPassed =
    allCompletedAttempts[0]?.results[0]?.percentage_score >= minimumPassingPercentage;

  if (new Date().getTime() < new Date(allCompletedAttempts[0]?.invite_valid_to).getTime()) {
    return hasPassed
      ? CandidateStatus.Passed_AvailableToTake_InFuture
      : CandidateStatus.Failed_AvailableToTake_InFuture;
  }

  return hasPassed
    ? CandidateStatus.Passed_AvailableToTake_Now
    : CandidateStatus.Failed_AvailableToTake_Now;
}

// Assumption is that the query returns the attempts and results sorted in asc order
export function serializeForAssessments(data: SelectAssessmentsQuery): {
  assessments: AssessmentProps[];
} {
  const assessmentsData = data.assessments.map(
    ({
      id,
      candidateAttempts,
      name,
      about,
      duration,
      instructions,
      minimum_passing_percentage,
      format,
      slug,
    }): AssessmentProps => {
      const candidateLatestAttempt = candidateAttempts[0];
      const candidateLatestResult = candidateLatestAttempt?.results[0];

      const candidateStatus: CandidateStatus = determineCandidateCurrentAssessmentStatus(
        candidateAttempts,
        minimum_passing_percentage,
      );

      const durationCopy = `${duration} minutes`;

      const canTakeTest = ![
        CandidateStatus.Completed_AwaitingResult,
        CandidateStatus.Passed_AvailableToTake_InFuture,
        CandidateStatus.Failed_AvailableToTake_InFuture,
        CandidateStatus.Plagiarism_AvailableToTake_InFuture,
      ].includes(candidateStatus);

      // will have link only when last test has a valid invite and its not completed, otherwise its null
      const link =
        (!candidateLatestAttempt?.results.length &&
          !candidateLatestAttempt?.completed && // last attempt is not completed
          new Date().getTime() < new Date(candidateLatestAttempt?.invite_valid_to).getTime() && // attempt invite is still valid
          candidateLatestAttempt?.test_link) ||
        null;
      const StatusCopy = {
        [CandidateStatus.NoCompletion]: 'Challenge Available',
        [CandidateStatus.Completed_AwaitingResult]: 'Completed',
        [CandidateStatus.Passed_AvailableToTake_InFuture]: 'Passed',
        [CandidateStatus.Passed_AvailableToTake_Now]: 'Passed',
        [CandidateStatus.Failed_AvailableToTake_InFuture]: 'Did Not Pass',
        [CandidateStatus.Failed_AvailableToTake_Now]: 'Did Not Pass',
        [CandidateStatus.Plagiarism_AvailableToTake_InFuture]: 'Failed',
        [CandidateStatus.Plagiarism_AvailableToTake_Now]: 'Failed',
      };

      const CardFooterCopy = {
        [CandidateStatus.NoCompletion]: durationCopy,
        [CandidateStatus.Completed_AwaitingResult]: 'Saving Results',
        [CandidateStatus.Passed_AvailableToTake_InFuture]: toResultCopy(candidateLatestResult),
        [CandidateStatus.Passed_AvailableToTake_Now]: 'Available for Retake',
        [CandidateStatus.Failed_AvailableToTake_InFuture]: toResultCopy(candidateLatestResult),
        [CandidateStatus.Failed_AvailableToTake_Now]: 'Available for Retake',
        [CandidateStatus.Plagiarism_AvailableToTake_InFuture]: 'Plagiarism Detected',
        [CandidateStatus.Plagiarism_AvailableToTake_Now]: 'Plagiarism Detected',
      };

      const HistoryDescription = {
        [CandidateStatus.NoCompletion]:
          'You have not previously taken this assessment. You are eligible to take this assessment at any time.',
        [CandidateStatus.Completed_AwaitingResult]:
          'You completed this assessment and we are saving the results.',
        [CandidateStatus.Passed_AvailableToTake_Now]:
          'You are eligible to take this assessment at any time.',
        [CandidateStatus.Passed_AvailableToTake_InFuture]: `Available for retake on ${moment(
          candidateLatestAttempt?.invite_valid_to,
        )
          .add(1, 'day')
          .format('MMMM DD, yyyy')}`,
        [CandidateStatus.Failed_AvailableToTake_Now]:
          'You are eligible to take this assessment at any time.',
        [CandidateStatus.Failed_AvailableToTake_InFuture]: `Available for retake on ${moment(
          candidateLatestAttempt?.invite_valid_to,
        )
          .add(1, 'day')
          .format('MMMM DD, yyyy')}`,
        [CandidateStatus.Plagiarism_AvailableToTake_Now]:
          'You are eligible to take this assessment at any time.',
        [CandidateStatus.Plagiarism_AvailableToTake_InFuture]: `Available for retake on ${moment(
          candidateLatestAttempt?.invite_valid_to,
        )
          .add(1, 'day')
          .format('MMMM DD, yyyy')}`,
      };

      const HistoryTitleActionWord = {
        [CandidateStatus.NoCompletion]: 'taken',
        [CandidateStatus.Completed_AwaitingResult]: 'taken',
        [CandidateStatus.Passed_AvailableToTake_InFuture]: 'passed',
        [CandidateStatus.Passed_AvailableToTake_Now]: 'passed',
        [CandidateStatus.Failed_AvailableToTake_InFuture]: 'failed',
        [CandidateStatus.Failed_AvailableToTake_Now]: 'failed',
        [CandidateStatus.Plagiarism_AvailableToTake_InFuture]: 'taken',
        [CandidateStatus.Plagiarism_AvailableToTake_Now]: 'taken',
      };

      const histories = candidateAttempts
        .flatMap((attempt) => {
          return attempt.results.map((result) => ({
            result,
            attemptStatus: determineAssessmentStatus(attempt, minimum_passing_percentage),
          }));
        })
        .sort((objA, objB): number => {
          return (
            new Date(objB.result.attempt_starttime).getTime() -
            new Date(objA.result.attempt_starttime).getTime()
          );
        })
        .map(({ result, attemptStatus }) => {
          const isPlagiarismDetected = [
            CandidateStatus.Plagiarism_AvailableToTake_InFuture,
            CandidateStatus.Plagiarism_AvailableToTake_Now,
          ].includes(attemptStatus);

          return {
            title: `Test ${HistoryTitleActionWord[attemptStatus]} on ${moment(
              result?.attempt_starttime,
            ).format('MMM DD, yyyy')}.`,
            result: isPlagiarismDetected ? 'Plagiarism Detected' : toResultCopy(result),
            attemptStartTime: result?.attempt_starttime,
          };
        });

      return {
        id,
        name,
        status: StatusCopy[candidateStatus] as AssessmentProps['status'],
        about,
        slug,
        cardProps: {
          footerNote: CardFooterCopy[candidateStatus],
        },
        detailProps: {
          instructions,
          duration: durationCopy,
          format,
          canTakeTest,
          link,
          history: {
            description: HistoryDescription[candidateStatus],
            histories,
          },
        },
      };
    },
  );

  return {
    assessments: assessmentsData,
  };
}
