import type { ComponentProps } from 'react';
import { Text } from '@terminal/design-system';
import { SkillsForm, SkillsSection } from 'candidate/shared/modules';
import { EditModal } from '../../components';

export function Skills({
  skillsSectionProps,
  editModalProps,
  skillsFormProps,
}: {
  skillsSectionProps: ComponentProps<typeof SkillsSection>;
  editModalProps: ComponentProps<typeof EditModal>;
  skillsFormProps: ComponentProps<typeof SkillsForm>;
}) {
  return (
    <>
      <SkillsSection {...skillsSectionProps} />
      <EditModal {...editModalProps}>
        <Text variant="caption" color="text.secondary" mb={6}>
          Showcase your top skills to make your profile stand out.
        </Text>
        <SkillsForm {...skillsFormProps} />
      </EditModal>
    </>
  );
}
