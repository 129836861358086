import { useEffect, useState } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import Sentry from 'global/sentry';
import { useToast } from '@terminal/design-system';
import { useRedirection } from 'global/utils';
import { useCandidateAuth } from 'candidate/utils';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import * as events from 'global/events';

import type { AssessmentProps } from 'candidate/shared/data-layer/SelectAssessments';
import { BlankScreenLoading } from 'global/components';

import { Assessments } from './Assessments';
import CreateInvitation from './CreateInvitation.graphql-service.graphql';
import type { CreateInvitationMutation, CreateInvitationMutationVariables } from './types';
import SelectAssessmentsPage from './SelectAssessmentsPage.graphql';

import type {
  SelectAssessmentsPageQuery,
  SelectAssessmentsPageQueryVariables,
} from './types/SelectAssessmentsPage.query.generated';
import { serializerForAssessmentsPage } from './AssessmentsPage.serializer';
import { CompletionLandingController } from './completion-landing';

export function AssessmentsController() {
  const auth = useCandidateAuth();
  const redirectTo = useRedirection();
  const location = useLocation();

  const { slug, testID } = useParams<{ slug: string; testID: string }>();
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });
  const [isCreateTestLoading, setIsCreateTestLoading] = useState(false);
  const [selectAssessmentsResult, setSelectAssessmentsResult] = useState<{
    data: SelectAssessmentsPageQuery | null;
    isLoading: boolean;
    error: unknown;
  }>({
    data: null,
    isLoading: false,
    error: null,
  });

  const client = useApolloClient();

  const isAssessmentCompleted = matchPath(location.pathname, {
    path: `/assessments/complete/${testID}`,
    exact: true,
    strict: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      // Because of how apollo client fetches the query after route changes, this was the only way
      // to control to fetch one once. UseQuery "Skip" didn't work. And useLazyQuery didn't work.
      const { data, error, loading } = await client.query<
        SelectAssessmentsPageQuery,
        SelectAssessmentsPageQueryVariables
      >({
        query: SelectAssessmentsPage,
        context: {
          role: 'candidate',
        },
        variables: {
          user_id: auth.user?.id as number,
        },
      });

      setSelectAssessmentsResult({
        data,
        isLoading: loading,
        error,
      });
    };

    fetchData();
    // I'm suspecting we don't need to put the giant client variable in the dependency array.
    // But if we found some bugs around the assessment data loading, we should consider adding it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user?.id]);

  const [createInvitation] = useMutation<
    CreateInvitationMutation,
    CreateInvitationMutationVariables
  >(CreateInvitation, {
    context: {
      role: 'candidate',
      service: 'service',
    },
  });

  const handleStartTest = async (assessment: AssessmentProps) => {
    events.track(events.name.assessment.clicked, {
      assessment_name: assessment?.name,
    });

    try {
      let link: string | undefined;

      if (assessment.detailProps.link) {
        link = assessment.detailProps.link;
      } else {
        setIsCreateTestLoading(true);
        const mutationResult = await createInvitation({
          variables: { test_id: assessment.id, email: auth.user?.email },
        });

        events.track(events.name.assessment.invitationCreated, {
          assessment_name: assessment.name,
        });

        link = mutationResult.data?.createHrInvitation?.testLink;
      }

      if (link) {
        events.track(events.name.assessment.redirected, {
          assessment_name: assessment.name,
        });

        window.location.href = link;
      } else {
        throw new Error(
          `Assessment successfully created, but has no test link (name: ${assessment.name})`,
        );
      }
    } catch (error: unknown) {
      setIsCreateTestLoading(false);
      toast({
        status: 'error',
        description: 'Something went wrong trying to create the test invite. Please try again!',
      });
      Sentry.captureException(error);
    }
  };

  const handleLearnMoreClick = (assessment: AssessmentProps) => {
    events.track(events.name.assessment.viewed, {
      assessment_name: assessment.name,
      last_taken_date: assessment.detailProps.history.histories[0]?.attemptStartTime,
    });

    redirectTo(`/assessments/${assessment.slug}`, { shouldScrollToTopOnChange: false });
  };

  if (selectAssessmentsResult.error) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw selectAssessmentsResult.error;
  }

  if (!selectAssessmentsResult.data || selectAssessmentsResult.isLoading) {
    return <BlankScreenLoading aria-label="loading" />;
  }

  const { assessments, personalizedHeader } = serializerForAssessmentsPage(
    selectAssessmentsResult.data as SelectAssessmentsPageQuery,
  );
  const selectedAssessment = assessments.find((a) => a.slug === slug);

  return (
    <>
      {isAssessmentCompleted && (
        <CompletionLandingController testID={testID} userID={auth.user?.id} />
      )}
      <Assessments
        assessments={assessments}
        personalizedHeader={personalizedHeader}
        isCreateTestLoading={isCreateTestLoading}
        onLearnMoreClick={handleLearnMoreClick}
        redirectTo={redirectTo}
        selectedAssessment={selectedAssessment}
        startTest={handleStartTest}
      />
    </>
  );
}
