import { Icon } from '@terminal/design-system';

export function OtherFlagIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
        <path
          d="m1 27.057 4.4857-1.2933c3.7591-1.085 7.8345-0.5992 11.184 1.3333 3.2685 1.8852 7.2314 2.3961 10.921 1.408l4.2482-1.1373c0.6867-0.1844 1.1602-0.773 1.1605-1.4427v-21.076c-5e-4 -0.47053-0.2362-0.91364-0.6369-1.197-0.4006-0.28341-0.9198-0.37433-1.4026-0.24562l-3.3692 0.90267c-3.6899 0.98807-7.6528 0.47715-10.921-1.408-3.3498-1.9325-7.4252-2.4183-11.184-1.3333l-4.4857 1.292"
          clipRule="evenodd"
          fill="#A0CFDA"
          fillRule="evenodd"
        />
        <path
          d="M1 33V3"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m1 27.057 4.4857-1.2933c3.7591-1.085 7.8345-0.5992 11.184 1.3333 3.2685 1.8852 7.2314 2.3961 10.921 1.408l4.2482-1.1373c0.6867-0.1844 1.1602-0.773 1.1605-1.4427v-21.076c-5e-4 -0.47053-0.2362-0.91364-0.6369-1.197-0.4006-0.28341-0.9198-0.37433-1.4026-0.24562l-3.3692 0.90267c-3.6899 0.98807-7.6528 0.47715-10.921-1.408-3.3498-1.9325-7.4252-2.4183-11.184-1.3333l-4.4857 1.292"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m14.2 10.963c0-1.5418 1.2536-2.7916 2.8-2.7916s2.8 1.2499 2.8 2.7916c0 1.5418-1.2536 2.7917-2.8 2.7917v2.2333"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m17 19.408c-0.3092 0-0.56 0.25-0.56 0.5584 0 0.3083 0.2508 0.5583 0.56 0.5583 0.3093 0 0.56-0.25 0.56-0.5583 0-0.3084-0.2507-0.5584-0.56-0.5584"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 33V3"
      />
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 27.057l4.486-1.294c3.759-1.085 7.834-.599 11.184 1.334 3.268 1.885 7.231 2.396 10.921 1.408l4.248-1.138c.687-.184 1.16-.773 1.161-1.442V4.849c0-.47-.236-.914-.637-1.197-.4-.284-.92-.375-1.402-.246l-3.37.903c-3.69.988-7.652.477-10.921-1.408C13.32.968 9.245.482 5.486 1.567L1 2.86"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.2 10.963c0-1.542 1.254-2.792 2.8-2.792 1.547 0 2.8 1.25 2.8 2.792 0 1.542-1.253 2.791-2.8 2.791v2.234M17 19.407c-.309 0-.56.25-.56.559 0 .308.251.558.56.558.31 0 .56-.25.56-.558 0-.309-.25-.559-.56-.559"
      />
    </Icon>
  );
}
