import React, { useCallback, useEffect, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Sentry, { ErrorBoundary } from 'global/sentry';
import { useQuery, useQueryString, useRedirection } from 'global/utils';
import { useSetUtmCookies } from 'global/events/utmCookies';
import { AssessmentRouter } from 'candidate/features/assessments';
import { ProfileRouter } from 'candidate/features/profile';
import { DirectApplicationRouter } from 'candidate/features/direct-application';
import { appState, useCandidateAuth, useCandidateFlags } from 'candidate/utils';
import { firebaseAuth } from 'global/firebaseApp';
import { AuthenticationRouter } from 'global/auth';
import { Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';
import * as events from 'global/events';
import {
  SwitchWithPageNotFound,
  CustomRoute,
  PrivateRoute,
  ErrorFallback,
  ScrollToTopOnPathChange,
} from 'candidate/components';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import type {
  SelectCandidateInsiderInfoQuery,
  SelectCandidateInsiderInfoQueryVariables,
} from 'candidate/shared/data-layer/SelectCandidateInsiderInfo';
import {
  SelectCandidateInsiderInfo,
  serializeSelectCandidateInsiderInfo,
} from 'candidate/shared/data-layer/SelectCandidateInsiderInfo';
import { HomePageRouter } from 'candidate/features/home-page';
import { JobRouter } from 'candidate/features/job';
import { OnboardingController } from 'candidate/features/profile/features/insider';
import { SetRouter } from 'candidate/features/set';
import { ActivityIndicator, SentryTest } from 'global/components';
import { selectUserTempBlob } from 'candidate/shared/data-layer/userTempBlob';
import { ReferralRouter } from 'candidate/features/referral';
import { CompanyRouter } from 'candidate/features/company/Company.router';
import { useReducedMotion } from 'framer-motion';
import { MyJobsController } from 'candidate/features/my-jobs/MyJobs.controller';
import { candidateAuthPageTemplateOverwrite } from './components/CandidateAuthPages';

function MemberRouter() {
  return (
    <SwitchWithPageNotFound>
      <PrivateRoute path="/referral" authPath="/auth" title="Referrals">
        <ReferralRouter />
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/"
        authPath="/auth"
        conditionalRedirects={[
          {
            condition: true,
            redirectURL: '/referral',
          },
        ]}
      />
    </SwitchWithPageNotFound>
  );
}

function CandidateAppRouter() {
  const ldClient = useLDClient();
  const shouldReduceMotion = useReducedMotion();
  const onboardingRouteMatch = useRouteMatch({
    path: ['/onboarding', '/set/onboarding'],
    strict: false,
    sensitive: true,
  });
  const auth = useCandidateAuth();
  const redirectTo = useRedirection();
  const location = useLocation();

  const appFlow = useReactiveVar(appState.flow.stateVar);

  const [
    shouldForceRedirect_fromOnboarding_toAuth_forEmailVerification,
    setShouldForceRedirect_fromOnboarding_toAuth_forEmailVerification,
  ] = useState<boolean | null>(null);
  // This is used as a flag. Needs to be set on page load to avoid loading the condition after the user signs up
  // So we can show the custom verify your email screen
  useEffect(() => {
    if (auth.isResolved) {
      setShouldForceRedirect_fromOnboarding_toAuth_forEmailVerification(
        !firebaseAuth?.currentUser?.isAnonymous && !auth.isAuthorized && auth.isAuthenticated,
      );
    }
    // Because we only want the useEffect function to run on page load after auth is resolved.
    // And not when its state changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isResolved]);

  useEffect(() => {
    if (!ldClient || !auth.user?.id) return;
    ldClient.identify({
      key: `${auth.user?.id}`,
      name: `${auth.userFullName}`,
      custom: {
        app: 'candidate',
        organization: 'terminal-candidate',
        role: 'candidate',
      },
    });
  }, [ldClient, auth.user?.id, auth.userFullName]);

  const { loading: isLoading_selectCandidateInsiderInfo, data: selectCandidateInsiderInfoData } =
    useQuery<SelectCandidateInsiderInfoQuery, SelectCandidateInsiderInfoQueryVariables>(
      SelectCandidateInsiderInfo,
      {
        context: {
          role: 'candidate',
        },
        variables: {
          candidate_id: auth.candidateID as number,
        },
        // We want to avoid the query from executing if appFlow['is-signing-up-in-direct-application'] so the AppIndicator does not get displayed and the routes component don't get unmounted
        skip: !auth.isAuthenticated || appFlow['is-signing-up-in-direct-application'],
      },
    );

  if (
    (!auth.isResolved && !auth.isError) ||
    // Only for authorized users we need to fetch this query and check if they are in
    // the onboarding flow.
    (auth.isAuthorized && isLoading_selectCandidateInsiderInfo) ||
    // because unless this value is set we can't decide if user needs get redirected out of onboarding
    shouldForceRedirect_fromOnboarding_toAuth_forEmailVerification === null
  ) {
    /**
     * TODO: We are using shouldReduceMotion to avoid rendering the framer-motion components in Jest tests
     * We need to update framer-motion and chakra-ui to get a better integration
     */
    return (
      <ActivityIndicator
        type={
          !shouldReduceMotion && !!onboardingRouteMatch && !auth.user?.email
            ? 'endless-loading-bar-with-icon'
            : 'simple-spinner'
        }
        description="Initializing your profile..."
      />
    );
  }

  if (auth.error?.code === 'auth/operation-not-supported-in-this-environment') {
    redirectTo('/auth', {
      shouldPassQuerystringAlong: true,
    });
    return null;
  }

  if ((auth.isEmailVerified || auth.user?.skip_email_verify) && !auth.roles.includes('candidate')) {
    Sentry.setUser({
      id: auth.user?.id,
      email: auth.user?.email,
      firebase_uid: auth.user?.firebase_uid,
      roles: auth.roles.join(','),
    });
    Sentry.captureException(new Error('User email was verified but it did no have candidate role'));
  }
  const { shouldForceRedirect, userRole } = serializeSelectCandidateInsiderInfo(
    selectCandidateInsiderInfoData as SelectCandidateInsiderInfoQuery,
  );

  if (userRole === 'member') {
    return <MemberRouter />;
  }

  // TODO: extract the codes related to Candidate routes into a separate component (CandidateRouter)
  return (
    <SwitchWithPageNotFound>
      <CustomRoute
        path="/job-apply"
        conditionalRedirects={[
          {
            condition:
              auth.isEmailVerified === false &&
              !(
                auth.user?.candidate_skip_email_verify === true ||
                auth.user?.skip_email_verify === true
              ),
            redirectURL: `/auth`,
            search: location.search,
          },
        ]}
      >
        <React.StrictMode>
          <DirectApplicationRouter />
        </React.StrictMode>
      </CustomRoute>
      <CustomRoute
        path="/onboarding"
        title="Onboarding"
        conditionalRedirects={[
          {
            condition: shouldForceRedirect_fromOnboarding_toAuth_forEmailVerification === true,
            redirectURL: '/auth',
            search: location.search,
          },
          {
            condition: shouldForceRedirect.outOfOnboarding,
            redirectURL: '/',
            search: location.search,
          },
        ]}
      >
        <ScrollToTopOnPathChange />
        {/* TODO: (web-split) add StrictMode to onbaording feature. Currently with
         StrictMode enabled, the onboarding feature auth fails. */}
        <OnboardingController rootTitle="Onboarding" />
      </CustomRoute>
      <CustomRoute path="/set" title="Initiating Setup">
        <React.StrictMode>
          <SetRouter />
        </React.StrictMode>
      </CustomRoute>
      <CustomRoute path="/sentry-test">
        <SentryTest />
      </CustomRoute>
      <PrivateRoute
        path="/assessments"
        authPath="/auth"
        title="Assessments"
        conditionalRedirects={[
          {
            condition: shouldForceRedirect.toOnboarding,
            redirectURL: '/onboarding',
          },
        ]}
      >
        <React.StrictMode>
          <AssessmentRouter />
        </React.StrictMode>
      </PrivateRoute>
      <PrivateRoute
        path="/profile"
        authPath="/auth"
        title="Profile"
        conditionalRedirects={[
          {
            condition: shouldForceRedirect.toOnboarding,
            redirectURL: '/onboarding',
          },
        ]}
      >
        <React.StrictMode>
          <ProfileRouter />
        </React.StrictMode>
      </PrivateRoute>

      <PrivateRoute
        path="/referral"
        authPath="/auth"
        title="Referrals"
        conditionalRedirects={[
          {
            condition: shouldForceRedirect.toOnboarding,
            redirectURL: '/onboarding',
          },
        ]}
      >
        <React.StrictMode>
          <ReferralRouter />
        </React.StrictMode>
      </PrivateRoute>

      <PrivateRoute
        path="/job"
        authPath="/auth"
        title="Job"
        conditionalRedirects={[
          {
            condition: shouldForceRedirect.toOnboarding,
            redirectURL: '/onboarding',
          },
        ]}
      >
        <React.StrictMode>
          <JobRouter />
        </React.StrictMode>
      </PrivateRoute>

      <PrivateRoute
        path="/my-jobs"
        authPath="/auth"
        title="My Jobs"
        conditionalRedirects={[
          {
            condition: shouldForceRedirect.toOnboarding,
            redirectURL: '/onboarding',
          },
        ]}
      >
        <React.StrictMode>
          <MyJobsController />
        </React.StrictMode>
      </PrivateRoute>

      <PrivateRoute
        path="/company/:id"
        authPath="/auth"
        title="Companies"
        conditionalRedirects={[
          {
            condition: shouldForceRedirect.toOnboarding,
            redirectURL: '/onboarding',
          },
        ]}
      >
        <React.StrictMode>
          <CompanyRouter />
        </React.StrictMode>
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/"
        authPath="/auth"
        conditionalRedirects={[
          {
            condition: shouldForceRedirect.toOnboarding,
            redirectURL: '/onboarding',
            search: location.search,
          },
        ]}
      >
        <React.StrictMode>
          <ScrollToTopOnPathChange />
          <HomePageRouter />
        </React.StrictMode>
      </PrivateRoute>
    </SwitchWithPageNotFound>
  );
}

// This component was made so that it could be used in the test renderer.
export function AppRouterInit({ children }: { children: React.ReactNode }) {
  const queryString = useQueryString();

  appState.trackingParams.insert({
    queryString,
    matchingQueryParams: ['cid'],
    matchQueryParamsRegex: /^utm/,
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export function AppRouter() {
  const client = useApolloClient();
  const location = useLocation();
  const auth = useCandidateAuth();
  const featureFlags = useCandidateFlags();

  const queryString = useQueryString();
  const tracking_id = queryString.get('tracking_id');

  useEffect(() => {
    // Track page view
    events.trackPage();
  }, [location]);

  // ! This implementation comes from terminal.io / web-customer
  /*  We have people coming from Iterable, but they're appearing as anonymous users. We want to identify them so that we can see Email Link Clicked and
      Loaded a Page event on a single profile in Mixpanel. We're using email address as the identifier */
  useEffect(() => {
    if ((window as any).analytics) {
      if (!tracking_id) return;

      // Segment's identity resolution will only work if we identify a user with a matching trait.
      const email = Array.isArray(tracking_id) ? '' : decodeURIComponent(tracking_id);
      if (email.match(/.+@.+/)) {
        (window as any).analytics.identify({ email });
      }

      // Mixpanel will not automatically merge anonymous users when we identify them by email, so
      // we need to call alias() to merge the front-end and backend profiles.
      (window as any).analytics.alias(
        Array.isArray(tracking_id) ? tracking_id : decodeURIComponent(tracking_id),
      );
    }
  }, [tracking_id]);

  useSetUtmCookies();

  /** For returning users who are going to have their email verified, we need to check if in their
   * temp blob it is declared that they are from onboarding flow. If yes we want to redirect them
   * to /onboarding?s=1 so that their candidate.profile_flow_started_at gets set properly. */
  const handleEmailVerificationComplete = useCallback(async (firebaseUID: string) => {
    try {
      const blobValues = await selectUserTempBlob({ firebaseUID, client });
      if (blobValues.flagHasStartedInsiderFlow) {
        return '/onboarding?s=1';
      }

      return null;
    } catch (error: unknown) {
      Sentry.captureException(error);
      return null;
    }
    // I'm suspecting we don't need to put the giant client variable in the dependency array.
    // But if we found some bugs around the assessment data loading, we should consider adding it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!featureFlags.demo) return;
    // eslint-disable-next-line no-console
    console.log('Demo FF is enabled', featureFlags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags.demo]);

  return (
    <ErrorBoundary
      fallback={
        <ErrorFallback
          onActionClick={() => {
            window.location.reload();
          }}
          actionTitle="Try Again"
        />
      }
    >
      <AppRouterInit>
        <Switch>
          <Route path="/auth">
            <React.StrictMode>
              <AuthenticationRouter
                {...auth}
                overwrite={{
                  route: {
                    signup: '/onboarding',
                  },
                  pageTemplate: candidateAuthPageTemplateOverwrite,
                  attrs: {
                    // Adding custom continueURL to match candidate APP direct application rules.
                    'email-not-verified': {
                      generateContinueURL() {
                        // This is part of the logic of Direct Application - claim account flow.
                        const fromDirectApplication_jobID = localStorage.getItem(
                          'from-direct-application-job-id',
                        );

                        return fromDirectApplication_jobID
                          ? `/job/matches/detail/${fromDirectApplication_jobID}?from=claim-account`
                          : '/auth/?utm_source=email-verification&utm_medium=email';
                      },
                    },
                  },
                }}
                onEmailVerificationComplete={handleEmailVerificationComplete}
                skipEmailVerify={
                  auth.user?.skip_email_verify === true ||
                  auth.user?.candidate_skip_email_verify === true
                }
              />
            </React.StrictMode>
          </Route>
          <CandidateAppRouter />
        </Switch>
      </AppRouterInit>
    </ErrorBoundary>
  );
}
