import { Center, Spinner } from '@terminal/design-system';
import { PageLayout } from './PageLayout';

export function PrivatePageLoading({
  userFullName,
  onSignOutClick,
  candidateAvailability,
}: {
  userFullName: string;
  onSignOutClick: () => Promise<void>;
  candidateAvailability: string | null;
}) {
  return (
    <PageLayout.Variation.WithAllSections
      {...{ onSignOutClick, userFullName, candidateAvailability }}
    >
      <Center w="100%" minH="90vh" bg="bg.secondary">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="ui.secondary"
          color="brand.main"
          size="xl"
          aria-label="Loading"
          role="status"
        />
      </Center>
    </PageLayout.Variation.WithAllSections>
  );
}
