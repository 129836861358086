import { EmailVerificationIcon, Text, Link, Container, Flex } from '@terminal/design-system';
import { AuthFormCard, AuthMessage } from 'global/auth';
import { PublicPageLayout } from 'candidate/components';

export function VerifyEmailInstruction() {
  return (
    <PublicPageLayout>
      <Container
        flexDir={['column-reverse', 'column-reverse', 'row']}
        flex="1"
        py={[6, 10]}
        justifyContent="center"
        display="flex"
      >
        <Flex flex={1} flexDir="column" justifyContent="flex-start" pt={16}>
          <Flex
            flexDir="column"
            flex={1}
            alignItems="center"
            justifyContent={['flex-start', 'flex-start', 'center']}
          >
            <AuthFormCard>
              <AuthMessage icon={<EmailVerificationIcon />} title="Please Verify Your Email">
                <Text>
                  A verification email has been sent to your email account. Please check your inbox
                  to verify. If you have any problems, please{' '}
                  <Link href="https://help.terminal.io/" isExternal display="inline">
                    contact us.
                  </Link>
                </Text>
              </AuthMessage>
            </AuthFormCard>
          </Flex>
        </Flex>
      </Container>
    </PublicPageLayout>
  );
}
