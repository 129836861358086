import { useState, useEffect } from 'react';
import { createStorageRef, getMetadata } from 'global/firebaseApp';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { BlankScreenLoading } from 'global/components';
import { useDisclosure, useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import { useImportGooglePlacesScript, useQueryString } from 'global/utils';
import { useCandidateAuth } from 'candidate/utils';
import { serializeForProfile } from './data';
import type {
  SelectProfileQuery,
  SelectProfileQueryVariables,
  ProfileProps,
  ProfileModalName,
} from './Profile.types';
import { SelectProfile, UpdateCandidateSocialProfile } from './graphql';
import { Profile } from './Profile';
import { trackProfile, isValidSectionName } from './Profile.utils';

export function ProfileController() {
  const auth = useCandidateAuth();
  const [resumeUpdatedDate, setResumeUpdatedDate] = useState<string>();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const [selectedModal, setSelectedModal] = useState<ProfileModalName>('social-profile');
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const isGooglePlaceAPIReady = useImportGooglePlacesScript();
  const sectionAnchor = useQueryString().get('s');
  const trackingContext = useQueryString().get('tc');
  const history = useHistory();
  const location = useLocation();

  const handleMutationError = (error: unknown, tryingTo: string) => {
    toast({
      status: 'error',
      description: `Something went wrong trying to ${tryingTo}. Please try again!`,
    });
    Sentry.captureException(error);
  };

  const { loading: isLoading_selectProfile, data } = useQuery<
    SelectProfileQuery,
    SelectProfileQueryVariables
  >(SelectProfile, {
    context: {
      role: 'candidate',
    },
    variables: {
      user_id: auth.user?.id as number,
    },
  });

  const resumeFileName = data?.candidates[0]?.resume_filename;

  const handleOpenModal = (modal: ProfileModalName) => {
    setSelectedModal(modal);
    onModalOpen();
  };

  useEffect(() => {
    // We validate that the passed section is an available modal to avoid
    // expanding the progress list with non-valid values
    if (sectionAnchor && isValidSectionName(sectionAnchor)) {
      handleOpenModal(sectionAnchor as ProfileModalName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionAnchor]);

  useEffect(() => {
    const getUpdatedDate = async (candidate: any) => {
      const { public_id, resume_filename } = candidate;
      const fileRef = createStorageRef(`/candidate/resume/${public_id}/${resume_filename}`);
      const { updated } = await getMetadata(fileRef);
      setResumeUpdatedDate(updated);
    };

    if (data && data.candidates[0]?.resume_filename) {
      getUpdatedDate(data.candidates[0]);
    }
    // resumeFileName is the only part of the data that we care
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeFileName]);

  const [updateSocialProfiles, { loading: isLoading_updateSocialProfiles }] = useMutation(
    UpdateCandidateSocialProfile,
    {
      context: {
        role: 'candidate',
      },
      onError: (error) => {
        handleMutationError(error, 'update your social profile');
      },
      onCompleted: ({ update_candidate_by_pk: { github_url, linkedin_url, other_url } }) => {
        toast({
          title: 'Saved Successfully!',
          description: 'Your changes were saved successfully.',
          status: 'success',
        });
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (sectionsCompletionStatus.socialProfile) {
          trackProfile('entry-edited', { section: 'Social Profiles' });
        } else {
          trackProfile('entry-added', {
            section: 'Social Profiles',
            ...(trackingContext === 'hpcta'
              ? { context: 'Personalized Complete Profile Action' }
              : {}),
          });
        }
        if (!github_url && !linkedin_url && !other_url) {
          trackProfile('entry-deleted', { section: 'Social Profiles' });
        }
      },
    },
  );

  if (isLoading_selectProfile || isGooglePlaceAPIReady !== 'ready') {
    return <BlankScreenLoading aria-label="Loading" />;
  }

  const {
    availabilityOptions,
    sectionsCompletionStatus,
    completionPercentage,
    candidate,
    degreeChoices,
    roleChoices,
    skillChoices,
    yearsOfExperienceRangeChoices,
  } = serializeForProfile(data as SelectProfileQuery, resumeUpdatedDate);

  const handleUpdateSocialProfile = (
    values: ProfileProps['candidate']['socialProfile']['formValues'],
  ): void => {
    updateSocialProfiles({
      variables: { candidate_id: candidate.id, ...values },
    });
  };

  // This is only called by social profiles until it is refactored to a separate section in the section folder
  const handleCloseModal = () => {
    history.push(location.pathname);
    onModalClose();
  };

  return (
    <Profile
      availabilityOptions={availabilityOptions}
      candidate={candidate}
      sectionsCompletionStatus={sectionsCompletionStatus}
      completionPercentage={completionPercentage}
      degreeChoices={degreeChoices}
      roleChoices={roleChoices}
      skillChoices={skillChoices}
      yearsOfExperienceChoices={yearsOfExperienceRangeChoices}
      isSavingForm={isLoading_updateSocialProfiles}
      isModalOpen={isModalOpen}
      onModalClose={handleCloseModal}
      onModalOpen={(modal) => history.push(`${location.pathname}?s=${modal}`)}
      onSocialProfileChangeSubmit={handleUpdateSocialProfile}
      userID={auth.user?.id}
      selectedModal={selectedModal}
    />
  );
}
