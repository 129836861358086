import {
  Candidate_Education_Degree_Choices_Enum,
  Candidate_Source_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { toDatesTitle } from 'global/utils/date';
import type { SelectProfileQuery } from '../../../graphql/types';
import type { ProfileProps } from '../../../Profile.types';
import type { UpsertCandidateEducationMutationVariables } from './types';

export const degreeDictionary = {
  [Candidate_Education_Degree_Choices_Enum.Bootcamp]: 'Bootcamp',
  [Candidate_Education_Degree_Choices_Enum.Associate]: 'Associate',
  [Candidate_Education_Degree_Choices_Enum.Bachelors]: "Bachelor's",
  [Candidate_Education_Degree_Choices_Enum.Masters]: "Master's",
  [Candidate_Education_Degree_Choices_Enum.Doctoral]: 'Doctoral',
  [Candidate_Education_Degree_Choices_Enum.NoDegree]: 'No Degree',
  [Candidate_Education_Degree_Choices_Enum.Other]: 'Other',
};

export function serializerEducations(
  educations: SelectProfileQuery['candidates'][number]['candidate_educations'],
) {
  return educations
    .slice()
    .sort((a, b) => {
      const earliestYear = 1800;

      const compareStartYear = (b.start_year || earliestYear) - (a.start_year || earliestYear);
      if (compareStartYear !== 0) {
        return compareStartYear;
      }

      return (b.graduation_year || earliestYear) - (a.graduation_year || earliestYear);
    })
    .map(
      ({ id, concentration, degree, school_name, still_studying, start_year, graduation_year }) => {
        const degreeTitle = degree ? degreeDictionary[degree] : false;
        const hasMissingData =
          !school_name || !concentration || !degreeTitle || !graduation_year || !start_year;

        return {
          id,
          isCurrent: still_studying,
          title: school_name,
          subTitle: [concentration, degreeTitle].filter(Boolean).join(' - ') || 'Unknown',
          datesTitle: toDatesTitle({
            startDate: start_year?.toString(),
            endDate: graduation_year?.toString(),
            isCurrent: still_studying,
            format: 'yyyy',
          }),
          formValues: {
            concentration: concentration || '',
            degree: degree || null,
            school_name: school_name || '',
            start_year,
            graduation_year,
            source: Candidate_Source_Choices_Enum.CandidatePortal,
          },
          hasMissingData,
          missingDataCTA: hasMissingData
            ? [school_name, concentration].filter(Boolean).join(', ') || null
            : null,
        };
      },
    );
}

export function serializerForEducationsUpsert({
  values,
  educationID,
  candidateID,
}: {
  values: ProfileProps['candidate']['educations'][number]['formValues'];
  educationID: number | null;
  candidateID: number;
}): UpsertCandidateEducationMutationVariables {
  return {
    objects: {
      school_name: values.school_name,
      concentration: values.concentration.length ? values.concentration : null,
      degree: values.degree ? (values.degree as Candidate_Education_Degree_Choices_Enum) : null,
      start_year: values.start_year,
      graduation_year: values.graduation_year,
      candidate_id: candidateID,
      source: Candidate_Source_Choices_Enum.CandidatePortal,
      still_studying: !!values.start_year && !values.graduation_year,
      ...(educationID && { id: educationID }),
    },
  };
}
