import { useRouteMatch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Sentry from 'global/sentry';
import { useIsViewPortDesktop, useRedirection, useQueryString } from 'global/utils';
import type { ApolloError } from '@apollo/client';
import { useCandidateAuth, appState, triggerGoal } from 'candidate/utils';
import { supportedCountries } from 'candidate/shared';
import * as events from 'global/events';
import {
  PrivatePageLoading,
  SwitchWithPageNotFound,
  CommunicationModal,
  CommunicationModalBody,
  CommunicationModalFooterCTAs,
} from 'candidate/components';
import { JobDetail } from 'candidate/shared/modules/job/JobDetail';
import { serializeProfileCompletion } from 'candidate/shared/modules/profile/Profile.serializer';
import {
  useJobSuggestionsController,
  serializerForJobSuggestions,
  myJobsSerializer,
} from 'candidate/shared/modules';
import type { SelectJobSuggestionsQuery } from 'candidate/shared/modules';
import {
  useDisclosure,
  useToast,
  Flex,
  Image,
  Heading,
  Text,
  Button,
} from '@terminal/design-system';
import type { Applicant_Job_Insert_Input } from 'global/types/hasura-tables.generated.types';
import type {
  SelectCandidateQuery,
  SelectCandidateQueryVariables,
  SelectCurrencyExchangeRatesQuery,
  SelectCurrencyExchangeRatesQueryVariables,
} from 'candidate/shared/modules/job/data';
import {
  SelectCandidateApplicableJobsForBrowsing,
  SelectCandidate,
  SelectCurrencyExchangeRates,
  SelectJobSuggestions,
} from 'candidate/shared/modules/job/data';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import { JobMatches } from 'candidate/shared/modules/job/JobMatches';
import noMatchImageSource from 'candidate/shared/modules/job/assets/no-job-matches.svg?url';
import thankyouImageSource from 'candidate/shared/modules/job/JobDetail/assets/job-thank-you.svg?url';
import hiringMarketsSrc from 'candidate/assets/images/hiring-markets.svg?url';
import moment from 'moment';
import type {
  SelectCandidateApplicableJobsForBrowsingQuery,
  SelectCandidateApplicableJobsForBrowsingQueryVariables,
} from 'candidate/shared/modules/job/data/types/SelectCandidateApplicableJobsForBrowsing.query.generated';
import type {
  InsertCandidateApplicantJobMutation,
  InsertCandidateApplicantJobMutationVariables,
} from '../../Job.types';
import { InsertCandidateJobApplication } from '../../graphql';
import { serializerForAllJobs } from '../allJobs/AllJobs.serializer';

const modalVariations_default = { isNewAccount: false, comesFromExternalPage: false };

export function JobMatchesController() {
  const history = useHistory();
  const toast = useToast();
  const isDesktop = useIsViewPortDesktop();
  const redirectTo = useRedirection();
  const { path, url } = useRouteMatch();
  const { pathname, search } = useLocation();
  const { params = {} } = useRouteMatch<{ jobID: string }>(`${path}/detail/:jobID`) || {};
  const { jobID: jobIDParam }: { jobID?: string } = params;
  const prevViewEvent = useRef<string>('');
  const auth = useCandidateAuth();
  const [modalVariations, setModalVariations] = useState<{
    isNewAccount: boolean;
    comesFromExternalPage: boolean;
  }>(modalVariations_default);
  const queryString = useQueryString();
  const from = queryString.get('from');
  // We are fetching the feed from the last 3 months
  const [feedInitialDateState] = useState(moment().subtract(3, 'month'));

  const { loading: isLoading_SelectCandidateInfo, data: selectCandidateInfoData } = useQuery<
    SelectCandidateQuery,
    SelectCandidateQueryVariables
  >(SelectCandidate, {
    context: {
      role: 'candidate',
    },
    variables: {
      user_id: auth.user?.id as number,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (!data?.candidate[0]?.country_choice?.name) {
        toast({
          description:
            'Your profile is missing important information. Please go to the profile page to enter your country.',
          status: 'error',
        });
      }
    },
  });

  const { loading: isLoading_selectCurrencyExchangeRatesQuery, data: currencyExchangeRates } =
    useQuery<SelectCurrencyExchangeRatesQuery, SelectCurrencyExchangeRatesQueryVariables>(
      SelectCurrencyExchangeRates,
      {
        fetchPolicy: 'cache-and-network',
      },
    );

  if (selectCandidateInfoData && !selectCandidateInfoData?.candidate[0]) {
    throw new Error('SelectCandidate query does not return any candidate');
  }

  const candidateInfo = selectCandidateInfoData?.candidate[0];

  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const {
    data: jobSuggestionsData,
    handleOnMatchAlertsChange,
    isLoading_selectJobSuggestions,
  } = useJobSuggestionsController({
    candidateID: auth.user?.candidate?.id as number,
    userID: auth.user?.id as number,
  });

  const { loading: isLoading_allJobs, data: allJobsData } = useQuery<
    SelectCandidateApplicableJobsForBrowsingQuery,
    SelectCandidateApplicableJobsForBrowsingQueryVariables
  >(SelectCandidateApplicableJobsForBrowsing, {
    skip: !candidateInfo || !auth.user?.candidate?.id || !candidateInfo?.country_choice?.name,
    context: {
      role: 'candidate',
    },
    variables: {
      candidate_id: auth.user?.candidate?.id as number,
      // it is safe to cast since we are skipping if candidateInfo is falsy
      candidate_country: candidateInfo?.country_choice?.name as string,
      feed_initial_date: feedInitialDateState,
    },
    fetchPolicy: 'cache-and-network',
    onError: (error: ApolloError) => {
      Sentry.captureException(error);
    },
  });

  const [insertCandidateJobApplication, { loading: isLoading_insertCandidateJobApplication }] =
    useMutation<InsertCandidateApplicantJobMutation, InsertCandidateApplicantJobMutationVariables>(
      InsertCandidateJobApplication,
      {
        onCompleted: onModalOpen,
        onError: (error: ApolloError) => {
          if (
            error.message ===
            `Uniqueness violation. duplicate key value violates unique constraint "applicant_job_pkey"`
          ) {
            toast({
              description: 'You have already applied for this job.',
              status: 'error',
            });
          } else {
            toast({
              description:
                'Something went wrong trying to submit your application. Please try again!',
              status: 'error',
            });

            Sentry.captureException(error);
          }
        },
        update(cache, _, { variables }) {
          try {
            const readData = cache.readQuery<SelectJobSuggestionsQuery>({
              query: SelectJobSuggestions,
              variables: {
                candidate_id: auth.user?.candidate?.id as number,
              },
            });

            const updatedJobDetails = {
              ...readData,
              applicant_job: [
                ...(readData?.applicant_job || []),
                { job: Number((variables?.objects as Applicant_Job_Insert_Input)?.job) },
              ],
            };

            cache.writeQuery({
              query: SelectJobSuggestions,
              broadcast: true,
              variables: {
                candidate_id: auth.user?.candidate?.id as number,
              },
              data: updatedJobDetails,
            });
          } catch (error) {
            cache.evict({
              fieldName: 'candidate',
              broadcast: true,
            });
            Sentry.captureException(error);
          }
        },
      },
    );

  const handleNoJobMatchesClick = () => {
    redirectTo(`${url}${!isDesktop ? '/no-matches' : ''}`);
  };

  const handleOnApplyClick = ({ jobID }: { jobID: number }) => {
    events.track(events.name.ctaClicked, {
      job_id: jobID,
      name: 'applied',
    });
    // Trigger goal (Campaign: Hide.show salaries) -> Click on apply button
    triggerGoal({ goalID: 205, campaignID: 71 });

    insertCandidateJobApplication({
      variables: {
        objects: {
          candidate_id: auth.user?.candidate?.id,
          job: Number(jobID),
        },
        candidate_id: auth.user?.candidate?.id as number,
      },
    });
  };

  const onCompleteProfileClick = () => {
    events.track(events.name.ctaClicked, {
      name: 'complete-profile',
    });

    redirectTo('/profile');
  };

  useEffect(() => {
    if (!from) return;

    if (from === 'claim-account') {
      appState.flow.update('landing-from-direct-application', 'claim-account');
      setModalVariations({ isNewAccount: true, comesFromExternalPage: true });
    }

    if (from === 'direct-application') {
      setModalVariations({ isNewAccount: false, comesFromExternalPage: true });
    }

    queryString.delete('from');
    const whiteListedParams = queryString.toString();
    history.replace(
      `${window.location.pathname}${whiteListedParams ? `?${whiteListedParams}` : ''}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  useEffect(() => {
    if (!auth.isAuthorized || !candidateInfo) return;

    const fromDirectApplication_jobID = localStorage.getItem('from-direct-application-job-id');
    localStorage.removeItem('from-direct-application-job-id');

    // Verifying that we have at least the minimum required data from a user to let him use DirectApplication from the Marketing site.
    if (
      fromDirectApplication_jobID &&
      (!candidateInfo.email ||
        !candidateInfo.first_name ||
        !(candidateInfo.resume_filename || candidateInfo.linkedin_url))
    ) {
      toast({
        description: 'Please complete your profile to be able to apply',
        status: 'error',
      });
      return;
    }

    if (fromDirectApplication_jobID) {
      insertCandidateJobApplication({
        variables: {
          objects: {
            candidate_id: auth.user?.candidate?.id,
            job: Number(fromDirectApplication_jobID),
          },
          candidate_id: auth.user?.candidate?.id as number,
        },
      });
    }
    // This needs to happen only in the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthorized, candidateInfo]);

  const { jobSuggestions, isSubscribedToAlerts }: ReturnType<typeof serializerForJobSuggestions> =
    jobSuggestionsData
      ? serializerForJobSuggestions({
          ...jobSuggestionsData,
          candidateInfo: {
            country: candidateInfo?.country_choice?.name || null,
            talentPartnerEmail: candidateInfo?.owner_person?.icims_people[0]?.email,
            candidateSkills: candidateInfo?.candidate_skills?.map(({ skill }) => skill.name),
            yearsOfExperience: candidateInfo?.candidate_curation_detail?.years_of_exp_range,
            furthestCandidateCurationWorkflow:
              candidateInfo?.furthest_candidate_curation_workflow?.status || null,
          },
          conversionTable: currencyExchangeRates?.exchange_rates,
        })
      : { jobSuggestions: [], isSubscribedToAlerts: false };
  const { completionPercentage } = serializeProfileCompletion({
    fullName: candidateInfo?.first_name || candidateInfo?.last_name || '',
    availability: candidateInfo?.availability,
    location: candidateInfo?.country_choice?.name || candidateInfo?.city,
    email: candidateInfo?.email,
    roles: candidateInfo?.candidate_roles,
    socialProfileLinks: {
      linkedin_url: candidateInfo?.linkedin_url,
      github_url: candidateInfo?.github_url,
      other_url: candidateInfo?.other_url,
    },
    workExperiences: candidateInfo?.candidate_work_experiences,
    educations: candidateInfo?.candidate_educations,
    skillGroups: candidateInfo?.candidate_skills,
    resumeFilename: candidateInfo?.resume_filename,
    desiredSalaryAmount: candidateInfo?.candidate_curation_detail?.desired_salary_amount,
  });

  useEffect(() => {
    if (
      !isLoading_selectJobSuggestions &&
      !isLoading_allJobs &&
      jobSuggestionsData &&
      allJobsData
    ) {
      events.track(events.name.homePage.jobMatches.viewed, {
        num_matches: jobSuggestionsData?.candidate_suggested_job?.length ?? 0,
        num_top_applicants: jobSuggestions.filter(
          (job) => job.jobDetail?.topApplicantInfo.isTopApplicant,
        ).length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading_selectJobSuggestions,
    isLoading_allJobs,
    jobSuggestionsData?.candidate_suggested_job?.length,
    auth.isLoading,
    allJobsData?.icims_job.length,
  ]);

  /**
   * Used to handle Job Details View event.
   */
  useEffect(() => {
    if (isLoading_selectJobSuggestions && isLoading_allJobs && !jobSuggestions.length) return;

    /**
     * If Desktop and it is not in a nested path the behavior is to show the first Job suggestion Detail, then track.
     */
    if (isDesktop && !jobIDParam && prevViewEvent.current !== `${jobSuggestions?.[0]?.id}`) {
      prevViewEvent.current = `${jobSuggestions?.[0]?.id}`;
      events.track(events.name.job.detailsViewed, {
        job_id: jobSuggestions?.[0]?.id,
        is_top_applicant: jobSuggestions.filter((job) => job.id === jobSuggestions?.[0]?.id)[0]
          ?.jobDetail?.topApplicantInfo.isTopApplicant,
      });
    }

    /**
     * If nested path changes.
     */
    if (jobIDParam && prevViewEvent.current !== jobIDParam) {
      prevViewEvent.current = jobIDParam;
      events.track(events.name.job.detailsViewed, {
        job_id: jobIDParam,
        is_top_applicant: jobSuggestions.filter((job) => job.id === Number(jobIDParam))[0]
          ?.jobDetail?.topApplicantInfo.isTopApplicant,
      });
    }
  }, [
    jobIDParam,
    jobSuggestions,
    isLoading_selectJobSuggestions,
    isDesktop,
    prevViewEvent,
    isLoading_allJobs,
  ]);

  if (
    isLoading_selectJobSuggestions ||
    isLoading_allJobs ||
    isLoading_SelectCandidateInfo ||
    isLoading_selectCurrencyExchangeRatesQuery
  ) {
    return (
      <PrivatePageLoading
        onSignOutClick={() => signOut({ auth: firebaseAuth })}
        userFullName={auth.userFullName}
        candidateAvailability={null}
      />
    );
  }

  const { otherJobs } = serializerForAllJobs({
    allJobsData: allJobsData as SelectCandidateApplicableJobsForBrowsingQuery,
    jobSuggestions,
    conversionTable: currencyExchangeRates?.exchange_rates,
    candidateInfo: {
      country: candidateInfo?.country_choice?.name || null,
      talentPartnerEmail: candidateInfo?.owner_person?.icims_people[0]?.email,
      furthestCandidateCurationWorkflow:
        candidateInfo?.furthest_candidate_curation_workflow?.status || null,
    },
  });

  const { talentPartnerSelectedJobs_toReview, archivedJobs, shouldShowHandpickedJobsBanner } =
    myJobsSerializer({
      data: allJobsData as SelectCandidateApplicableJobsForBrowsingQuery,
    });

  const selectedJob = [...jobSuggestions, ...otherJobs].find((job) => `${job.id}` === jobIDParam);
  const isCountrySupported = supportedCountries.includes(candidateInfo?.country_choice?.name ?? '');

  return (
    <>
      <JobMatches
        header={
          <Heading variant="heading-1" color="text.primary">
            Browse Jobs
          </Heading>
        }
        notJobMatchesButtonBgColor={
          pathname === '/job/matches' && isDesktop ? 'ui.inverse.secondary' : 'ui.inverse.primary'
        }
        handleNoJobMatchesClick={handleNoJobMatchesClick}
        onMatchAlertsChange={() => handleOnMatchAlertsChange(isSubscribedToAlerts)}
        showIsSubscribedOn={isSubscribedToAlerts}
        // Logic should be in serializer [CAND-1476]
        jobSuggestions={[...jobSuggestions, ...otherJobs].filter(
          (jobSuggestion) =>
            // TP suggestions jobs that are `In Review` or `Archived` tab should be hidden in the Browse Jobs list
            ![...talentPartnerSelectedJobs_toReview, ...archivedJobs].some(
              (talentPartnerJobSuggestion) => jobSuggestion.id === talentPartnerJobSuggestion.jobID,
            ),
        )}
        activeSuggestion={Number(jobIDParam || jobSuggestions?.[0]?.id)}
        shouldShowHandpickedJobsBanner={shouldShowHandpickedJobsBanner}
        jobSectionDetail={
          <SwitchWithPageNotFound>
            <Route
              exact
              path={[`${path}/detail/:jobID`, `${path}/no-matches`]}
              render={() => {
                if (!selectedJob && isDesktop) {
                  return (
                    <Redirect
                      to={{
                        pathname: '/job/matches',
                        search,
                      }}
                    />
                  );
                }

                return (
                  <JobDetail
                    isDrawerOpen={!isDesktop}
                    onDrawerClose={() => {
                      redirectTo('/job/matches');
                    }}
                    onApplyClick={() => {
                      handleOnApplyClick({ jobID: Number(selectedJob?.id) });
                    }}
                    isCountrySupported={isCountrySupported}
                    isApplyButtonLoading={isLoading_insertCandidateJobApplication}
                    hasAlreadyApplied={!!selectedJob?.applied}
                    onCompleteProfileClick={onCompleteProfileClick}
                    // @ts-ignore -> ignoring since we are redirecting when selectedJob is undefined
                    jobDetailData={selectedJob?.jobDetail || jobSuggestions[0]?.jobDetail}
                    onAboutCompanyClick={(organizationID) =>
                      redirectTo(`/company/${organizationID}`)
                    }
                  />
                );
              }}
            />
            <Route exact path={`${path}`}>
              {!isCountrySupported && !jobSuggestions.length && isDesktop && (
                <Flex
                  flexDir="column"
                  alignItems="center"
                  h="full"
                  w="full"
                  minH="sm"
                  justifyContent="center"
                  bgColor={['transparent', 'transparent', 'ui.inverse.secondary']}
                >
                  <Image
                    h="auto"
                    w="full"
                    maxWidth="15.402rem"
                    objectFit="cover"
                    src={hiringMarketsSrc}
                    alt="No job suggestion match"
                  />
                  <Heading mt={6} variant="heading-2" color="text.primary">
                    You Have No Matches Yet!
                  </Heading>
                  <Text mt={2} color="text.primary" textAlign="center" px={20}>
                    We do not have any jobs available for your location. We will let you know if we
                    expand to your area
                  </Text>
                </Flex>
              )}
              {(!jobSuggestions?.length || !jobSuggestions?.[0]?.jobDetail) &&
                isDesktop &&
                isCountrySupported &&
                completionPercentage < 100 && (
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    h="full"
                    w="full"
                    minH="sm"
                    justifyContent="center"
                    bgColor={['transparent', 'transparent', 'ui.inverse.secondary']}
                  >
                    <Image src={noMatchImageSource} alt="No job suggestion match" maxW="280px" />
                    <Heading mt={6} variant="heading-2" color="text.primary">
                      You Have No Matches Yet!
                    </Heading>
                    <Text mt={2} color="text.primary">
                      Fill your profile to get matching jobs!
                    </Text>
                    <Button onClick={onCompleteProfileClick} mt={6} w={64}>
                      Complete Profile
                    </Button>
                  </Flex>
                )}
              {(!jobSuggestions?.length || !jobSuggestions?.[0]?.jobDetail) &&
                isDesktop &&
                isCountrySupported &&
                completionPercentage === 100 && (
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    h="full"
                    w="full"
                    minH="sm"
                    justifyContent="center"
                    bgColor={['transparent', 'transparent', 'ui.inverse.secondary']}
                  >
                    <Image src={noMatchImageSource} alt="No job suggestion match" maxW="280px" />
                    <Heading mt={6} variant="heading-2" color="text.primary">
                      You Have No Matches Yet!
                    </Heading>
                    <Text mt={2} color="text.primary">
                      Subscribe to alerts to find out when you have new matches
                    </Text>
                  </Flex>
                )}
              {(jobSuggestions?.length || !isDesktop) && (
                <JobDetail
                  onApplyClick={() => {
                    handleOnApplyClick({ jobID: Number(jobSuggestions?.[0]?.id) });
                  }}
                  isApplyButtonLoading={isLoading_insertCandidateJobApplication}
                  hasAlreadyApplied={!!jobSuggestions?.[0]?.applied}
                  onCompleteProfileClick={onCompleteProfileClick}
                  // @ts-ignore -> ignoring since we are displaying an empty state
                  jobDetailData={selectedJob?.jobDetail || jobSuggestions[0]?.jobDetail}
                  onAboutCompanyClick={(organizationID) => redirectTo(`/company/${organizationID}`)}
                  isCountrySupported={isCountrySupported}
                />
              )}
            </Route>
            <Route path={`${path}/:any`}>
              <Redirect
                to={{
                  pathname: '/job/matches',
                  search,
                }}
              />
            </Route>
          </SwitchWithPageNotFound>
        }
      />
      <CommunicationModal
        isOpen={isModalOpen}
        image={{ src: thankyouImageSource, alt: 'you have applied image' }}
        onClose={() => {
          onModalClose();
          setModalVariations(modalVariations_default);
        }}
      >
        <CommunicationModalBody>
          <Heading variant="heading-2" color="brand.main" textAlign="center">
            You Applied!
          </Heading>
          <Text mt={2} textAlign="center">
            You applied to{' '}
            <span>
              {selectedJob?.jobDetail?.title ||
                localStorage.getItem('from-direct-application-job-title') ||
                'this'}
            </span>{' '}
            opportunity
            {(selectedJob?.jobDetail?.companyName ||
              localStorage.getItem('from-direct-application-job-company')) &&
              ` at ${
                selectedJob?.jobDetail?.companyName ||
                localStorage.getItem('from-direct-application-job-company')
              }`}
            . Good luck!
          </Text>
        </CommunicationModalBody>
        <CommunicationModalFooterCTAs
          primaryCTA={{
            title: modalVariations.comesFromExternalPage ? 'View More Jobs' : 'Go Back',
            onClick: () => {
              onModalClose();
              setModalVariations(modalVariations_default);
            },
          }}
          {...(modalVariations.isNewAccount
            ? {
                secondaryCTA: {
                  title: 'Explore My Homepage',
                  onClick: () => {
                    redirectTo('/');
                  },
                },
              }
            : {})}
        />
      </CommunicationModal>
    </>
  );
}
