import type { ComponentProps } from 'react';
import { Flex, Heading, Text, Button, Image, Link } from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';
import { Link as RouterLink } from 'react-router-dom';
import pageNotFound404ImageSrc from 'candidate/assets/images/404-rebrand.svg?url';
import { PublicPageLayout } from 'candidate/components';
import type { CandidateAuthDefaultPageTemplate } from '../CandidateAuthDefaultTemplate';
import { TwoColumnAuthPageLayout } from '../TwoColumnAuthPageLayout';

export function CandidateNoAccessPage(
  props: ComponentProps<typeof CandidateAuthDefaultPageTemplate>,
) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <PublicPageLayout
      headerRightPlaceholderDesktop={
        <Flex alignItems="center">
          <Text variant="caption">Don’t Have an Account?</Text>
          <Link
            as={RouterLink}
            to={`/onboarding${window.location.search}`}
            ml={2}
            variant="caption"
          >
            Sign Up
          </Link>
        </Flex>
      }
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link as={RouterLink} to={`/onboarding${window.location.search}`}>
            Sign Up
          </Link>
        </Flex>
      }
    >
      <TwoColumnAuthPageLayout
        leftColumn={
          isDesktop && (
            <Flex w="full" flexDir="column" alignItems="center">
              <Image src={pageNotFound404ImageSrc} w="350px" />
            </Flex>
          )
        }
        rightColumn={
          <Flex
            zIndex="docked"
            alignItems={['center', 'center', 'flex-start']}
            w="full"
            h="full"
            justifyContent="center"
            flexDir="column"
          >
            {!isDesktop && <Image src={pageNotFound404ImageSrc} maxW="175px" />}
            <Heading
              mt={[6, 6, 0]}
              variant="heading-2"
              color="brand.main"
              textAlign={['center', 'center', 'left']}
            >
              You Don’t Have Access
            </Heading>
            <Text textAlign={['center', 'center', 'left']} mt={2}>
              You need additional access to view this page. Email us at{' '}
              <Link href="mailto:support@terminal.io" isExternal display="inline">
                support@terminal.io
              </Link>{' '}
              to request permission.
            </Text>
            <Button onClick={props.footerLinkOnClick} mt={6} w="full">
              Logout
            </Button>
          </Flex>
        }
      />
    </PublicPageLayout>
  );
}
