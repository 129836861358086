import { Icon } from '@terminal/design-system';

export function DataEngineerIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 14V21.8C1 24.6721 7.00789 27 14.4193 27C21.8308 27 27.8387 24.6721 27.8387 21.8V14"
          fill="#ECB23F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 6.20004V14C1 16.8722 7.00789 19.2 14.4193 19.2C21.8308 19.2 27.8387 16.8722 27.8387 14V6.20004"
          fill="#EFD58B"
        />
        <ellipse cx="14.4193" cy="6.2" rx="13.4193" ry="5.2" fill="#A0CFDA" />
        <ellipse
          cx="14.4193"
          cy="6.2"
          rx="13.4193"
          ry="5.2"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 6.20004V14C1 16.8722 7.00789 19.2 14.4193 19.2C21.8308 19.2 27.8387 16.8722 27.8387 14V6.20004"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 14V21.8C1 24.6721 7.00789 27 14.4193 27C21.8308 27 27.8387 24.6721 27.8387 21.8V14"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3416 13.3522L24.9703 15.4175C25.0738 15.7607 25.3039 16.0518 25.614 16.2317C25.9241 16.4115 26.2909 16.4668 26.6402 16.3862L28.734 15.9011C29.1311 15.8113 29.5466 15.851 29.9195 16.0146C30.2923 16.1781 30.603 16.4568 30.8058 16.8099C31.0087 17.1629 31.093 17.5717 31.0466 17.9762C31.0001 18.3807 30.8252 18.7596 30.5476 19.0574L29.0811 20.6349C28.8363 20.8981 28.7003 21.2441 28.7003 21.6035C28.7003 21.9629 28.8363 22.309 29.0811 22.5722L30.5476 24.1525C30.8246 24.4504 30.9989 24.8292 31.0451 25.2334C31.0912 25.6375 31.0067 26.0459 30.804 26.3986C30.6012 26.7512 30.2909 27.0297 29.9184 27.1933C29.5459 27.3568 29.1309 27.3968 28.734 27.3074L26.6402 26.8223C26.2909 26.7417 25.9241 26.797 25.614 26.9768C25.3039 27.1567 25.0738 27.4478 24.9703 27.791L24.3416 29.852C24.2248 30.2418 23.9854 30.5836 23.659 30.8265C23.3325 31.0694 22.9364 31.2007 22.5295 31.2007C22.1226 31.2007 21.7265 31.0694 21.4001 30.8265C21.0736 30.5836 20.8342 30.2418 20.7174 29.852L20.0887 27.7867C19.9852 27.4435 19.7551 27.1524 19.445 26.9726C19.135 26.7927 18.7681 26.7375 18.4188 26.8181L16.325 27.3031C15.9282 27.3925 15.5131 27.3525 15.1406 27.189C14.7681 27.0255 14.4578 26.747 14.2551 26.3943C14.0523 26.0416 13.9678 25.6333 14.014 25.2291C14.0601 24.8249 14.2344 24.4461 14.5114 24.1482L15.9779 22.5679C16.2227 22.3047 16.3587 21.9587 16.3587 21.5993C16.3587 21.2399 16.2227 20.8938 15.9779 20.6306L14.5114 19.0503C14.2338 18.7525 14.0589 18.3735 14.0125 17.969C13.966 17.5645 14.0503 17.1558 14.2532 16.8028C14.456 16.4497 14.7667 16.171 15.1396 16.0074C15.5124 15.8439 15.9279 15.8041 16.325 15.894L18.4188 16.3791C18.7681 16.4597 19.135 16.4044 19.445 16.2245C19.7551 16.0447 19.9852 15.7536 20.0887 15.4104L20.7174 13.3451C20.835 12.9555 21.0751 12.6143 21.402 12.3719C21.7289 12.1296 22.1252 11.9992 22.5322 12C22.9391 12.0008 23.3349 12.1328 23.6609 12.3764C23.9868 12.62 24.2256 12.9622 24.3416 13.3522Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.396 21.6035C20.396 22.1694 20.6208 22.7121 21.0209 23.1122C21.421 23.5123 21.9637 23.7371 22.5296 23.7371C23.0955 23.7371 23.6382 23.5123 24.0383 23.1122C24.4384 22.7121 24.6632 22.1694 24.6632 21.6035C24.6632 21.0376 24.4384 20.495 24.0383 20.0948C23.6382 19.6947 23.0955 19.4699 22.5296 19.4699C21.9637 19.4699 21.421 19.6947 21.0209 20.0948C20.6208 20.495 20.396 21.0376 20.396 21.6035Z"
          fill="#ECB23F"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <ellipse
        cx="14.4193"
        cy="6.2"
        rx="13.4193"
        ry="5.2"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.20004V14C1 16.8722 7.00789 19.2 14.4193 19.2C21.8308 19.2 27.8387 16.8722 27.8387 14V6.20004"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 14V21.8C1 24.6721 7.00789 27 14.4193 27C21.8308 27 27.8387 24.6721 27.8387 21.8V14"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3416 13.3522L24.9703 15.4175C25.0738 15.7607 25.3039 16.0518 25.614 16.2317C25.9241 16.4115 26.2909 16.4668 26.6402 16.3862L28.734 15.9011C29.1311 15.8113 29.5466 15.851 29.9195 16.0146C30.2923 16.1781 30.603 16.4568 30.8058 16.8099C31.0087 17.1629 31.093 17.5717 31.0466 17.9762C31.0001 18.3807 30.8252 18.7596 30.5476 19.0574L29.0811 20.6349C28.8363 20.8981 28.7003 21.2441 28.7003 21.6035C28.7003 21.9629 28.8363 22.309 29.0811 22.5722L30.5476 24.1525C30.8246 24.4504 30.9989 24.8292 31.0451 25.2334C31.0912 25.6375 31.0067 26.0459 30.804 26.3986C30.6012 26.7512 30.2909 27.0297 29.9184 27.1933C29.5459 27.3568 29.1309 27.3968 28.734 27.3074L26.6402 26.8223C26.2909 26.7417 25.9241 26.797 25.614 26.9768C25.3039 27.1567 25.0738 27.4478 24.9703 27.791L24.3416 29.852C24.2248 30.2418 23.9854 30.5836 23.659 30.8265C23.3325 31.0694 22.9364 31.2007 22.5295 31.2007C22.1226 31.2007 21.7265 31.0694 21.4001 30.8265C21.0736 30.5836 20.8342 30.2418 20.7174 29.852L20.0887 27.7867C19.9852 27.4435 19.7551 27.1524 19.445 26.9726C19.135 26.7927 18.7681 26.7375 18.4188 26.8181L16.325 27.3031C15.9282 27.3925 15.5131 27.3525 15.1406 27.189C14.7681 27.0255 14.4578 26.747 14.2551 26.3943C14.0523 26.0416 13.9678 25.6333 14.014 25.2291C14.0601 24.8249 14.2344 24.4461 14.5114 24.1482L15.9779 22.5679C16.2227 22.3047 16.3587 21.9587 16.3587 21.5993C16.3587 21.2399 16.2227 20.8938 15.9779 20.6306L14.5114 19.0503C14.2338 18.7525 14.0589 18.3735 14.0125 17.969C13.966 17.5645 14.0503 17.1558 14.2532 16.8028C14.456 16.4497 14.7667 16.171 15.1396 16.0074C15.5124 15.8439 15.9279 15.8041 16.325 15.894L18.4188 16.3791C18.7681 16.4597 19.135 16.4044 19.445 16.2245C19.7551 16.0447 19.9852 15.7536 20.0887 15.4104L20.7174 13.3451C20.835 12.9555 21.0751 12.6143 21.402 12.3719C21.7289 12.1296 22.1252 11.9992 22.5322 12C22.9391 12.0008 23.3349 12.1328 23.6609 12.3764C23.9868 12.62 24.2256 12.9622 24.3416 13.3522Z"
        fill="white"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.396 21.6035C20.396 22.1694 20.6208 22.7121 21.0209 23.1122C21.421 23.5123 21.9637 23.7371 22.5296 23.7371C23.0955 23.7371 23.6382 23.5123 24.0383 23.1122C24.4384 22.7121 24.6632 22.1694 24.6632 21.6035C24.6632 21.0376 24.4384 20.495 24.0383 20.0948C23.6382 19.6947 23.0955 19.4699 22.5296 19.4699C21.9637 19.4699 21.421 19.6947 21.0209 20.0948C20.6208 20.495 20.396 21.0376 20.396 21.6035Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
