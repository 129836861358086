import type { HStepsProps } from 'candidate/components';
import {
  CheckboxCard,
  Button,
  GridItem,
  Text,
  Image,
  HStack,
  VStack,
} from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';
import { roleIcons } from '../../../../../../shared/modules/roles';
import type { ProfileProps } from '../../../../Profile.types';
import { StepPageLayout, CheckboxCardGroup } from '../../components';
import startInterviewingIcon from './assets/start_interviewing.svg?url';
import talentTeamSvg from './assets/talent-team.svg?url';

export function RolesSelectionBase({
  isNextDisabled,
  onCheckboxClick,
  onNextClick,
  onSignOutClick,
  progressProps,
  roleChoices,
  selectedRoles,
  userFullName,
  shouldDisplayBanner,
  bannerContent,
}: {
  isNextDisabled: boolean;
  onCheckboxClick: (role: string) => void;
  onNextClick: () => void;
  onSignOutClick: () => Promise<void>;
  progressProps: HStepsProps;
  roleChoices: ProfileProps['roleChoices'];
  selectedRoles: ProfileProps['candidate']['roles'][number]['formValues'][];
  userFullName: string;
  shouldDisplayBanner?: boolean;
  bannerContent?: React.ReactNode;
}) {
  return (
    // TODO: [CAND-333] refactor to pass up header and footer
    <StepPageLayout
      title="Start By Telling Us What You Are Looking For"
      description="Select all roles that apply:"
      {...{ progressProps, userFullName, onSignOutClick }}
      footer={
        <Button onClick={onNextClick} w={['full', 64]} disabled={isNextDisabled}>
          Next
        </Button>
      }
      bannerContent={shouldDisplayBanner ? bannerContent : undefined}
      contentContainerProps={{ w: ['full', 'ml', '35.75rem'] }}
    >
      <CheckboxCardGroup>
        {roleChoices.map(({ name: roleChoice, displayName }) => {
          const isSelected = selectedRoles.some((selectedRole) => selectedRole.role === roleChoice);

          const icon = roleIcons({ role: roleChoice, isColored: isSelected });

          if (!icon) return null;

          return (
            <GridItem key={`insider-role-${roleChoice}`}>
              <CheckboxCard
                role="checkbox"
                aria-checked={isSelected}
                aria-labelledby="candidate-role"
                onChange={() => onCheckboxClick(roleChoice)}
                isChecked={isSelected}
                checkboxIcon={icon}
                className="notranslate"
                sx={{
                  '.chakra-button': {
                    h: 20,
                  },
                  '.checkbox-card__icon-container': {
                    pl: 3,
                    pr: 0,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                  },
                  '.checkbox-card__icon-wrapper': {
                    mb: 0,
                    mr: 2,
                  },
                  '& > .chakra-icon': {
                    bottom: [2, 2, 2],
                    right: [2, 2, 2],
                  },
                }}
              >
                {displayName}
              </CheckboxCard>
            </GridItem>
          );
        })}
      </CheckboxCardGroup>
    </StepPageLayout>
  );
}

export function RolesSelectionStatistic(props: React.ComponentProps<typeof RolesSelectionBase>) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <RolesSelectionBase
      shouldDisplayBanner
      bannerContent={
        <HStack>
          {isDesktop && <Image src={startInterviewingIcon} />}
          <VStack>
            {isDesktop && (
              <Text color="accent.darker" variant="caption" fontWeight="bold">
                Start Interviewing Faster 🚀
              </Text>
            )}
            <Text
              variant={isDesktop ? 'label' : 'caption'}
              fontWeight={isDesktop ? 'bold' : 'bold'}
              textAlign="center"
            >
              On average, qualified candidates receive 2-3 interviews within two weeks
            </Text>
          </VStack>
          {!isDesktop && <Text>🚀</Text>}
        </HStack>
      }
      {...props}
    />
  );
}

export function RolesSelectionPartnerPromise(
  props: React.ComponentProps<typeof RolesSelectionBase>,
) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <RolesSelectionBase
      shouldDisplayBanner
      bannerContent={
        <HStack>
          {isDesktop && <Image src={talentTeamSvg} />}
          <VStack>
            <Text
              variant={isDesktop ? 'label' : 'caption'}
              fontWeight={isDesktop ? 'bold' : 'bold'}
              textAlign="center"
            >
              Complete your profile and you’ll hear from our Talent team in the next 24-48 hours!
              {isDesktop && (
                <Text as="span" ml={1} pos="relative" top="-0.125rem">
                  🔥
                </Text>
              )}
            </Text>
          </VStack>
          {!isDesktop && <Text>🔥</Text>}
        </HStack>
      }
      {...props}
    />
  );
}

export const RolesSelection = {
  Variation: {
    Statistic: RolesSelectionStatistic,
    PartnerPromise: RolesSelectionPartnerPromise,
  },
};
