import { Flex } from '@terminal/design-system';
import type {
  As,
  LayoutProps,
  SpaceProps,
  FlexboxProps,
  PositionProps,
  BackgroundProps,
  EffectProps,
} from '@chakra-ui/react';

type CardProps = {
  className?: string;
  children: React.ReactNode;
  as?: As;
} & FlexboxProps &
  SpaceProps &
  LayoutProps &
  PositionProps &
  BackgroundProps &
  EffectProps;

export function CardHeader({ children, ...stylingProps }: CardProps) {
  return (
    <Flex
      borderBottom="1px solid"
      borderBottomColor="ui.inverse.secondary"
      px={8}
      py={4}
      {...stylingProps}
    >
      {children}
    </Flex>
  );
}

export function CardBody({ children, ...stylingProps }: CardProps) {
  return (
    <Flex flexDir="column" flex={1} p={8} {...stylingProps}>
      {children}
    </Flex>
  );
}

export function Card({ children, ...stylingProps }: CardProps) {
  return (
    <Flex
      bg="bg.primary"
      borderRadius="base"
      flexDir="column"
      shadow="md"
      w="full"
      h="fit-content"
      {...stylingProps}
    >
      {children}
    </Flex>
  );
}
