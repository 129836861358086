import type { ApolloClient } from '@apollo/client';
import { UpsertUserTempBlob, SelectUserTempBlob } from './data/graphql';
import type {
  UpsertUserTempBlobMutationVariables,
  UpsertUserTempBlobMutation,
  SelectUserTempBlobQuery,
  SelectUserTempBlobQueryVariables,
} from './data/graphql/types';

export async function selectUserTempBlob({
  firebaseUID,
  client,
}: {
  firebaseUID: string;
  client: ApolloClient<object>;
}): Promise<{ [key: string]: any }> {
  const { data: data_query, error: error_query } = await client.query<
    SelectUserTempBlobQuery,
    SelectUserTempBlobQueryVariables
  >({
    query: SelectUserTempBlob,
    context: {
      role: 'authenticated',
    },
    variables: {
      firebase_uid: firebaseUID,
    },
  });

  if (error_query) throw error_query;

  return data_query;
}

export async function updateUserTempBlob({
  firebaseUID,
  client,
  newValues,
  shouldOverwriteProps,
}: {
  firebaseUID: string;
  client: ApolloClient<object>;
  newValues: { [key: string]: any };
  shouldOverwriteProps?: boolean;
}) {
  const data_query = await selectUserTempBlob({ firebaseUID, client });
  const blob = shouldOverwriteProps
    ? { ...data_query.user_temp_blob[0]?.blob, ...newValues }
    : { ...newValues, ...data_query.user_temp_blob[0]?.blob };

  const { data: data_mutation } = await client.mutate<
    UpsertUserTempBlobMutation,
    UpsertUserTempBlobMutationVariables
  >({
    mutation: UpsertUserTempBlob,
    context: {
      role: 'authenticated',
    },
    variables: {
      objects: {
        firebase_uid: firebaseUID,
        blob,
      },
    },
  });

  return data_mutation;
}
