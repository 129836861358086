import { Icon } from '@terminal/design-system';

export function SpainFlagIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
        <path d="m1 2 9-1.5 7 2.5 8.5 1.5 7.5-1v7.5l-10 1-6.5-3-7.5-1-8 3v-9z" fill="#F6623A" />
        <path
          d="m1 21 9-2.5 6.5 1.5 7 3 9.5-1.5-0.5 6-10.5 1.5-5.5-2-7.5-2-8 2v-6z"
          fill="#F6623A"
        />
        <path
          d="m1 10 9-2.5 6.5 1.5 7 3 9.5-1.5v11l-11.5 1-6-2.5-7.5-1-7 2.5v-11.5z"
          fill="#ECB23F"
        />
        <path
          d="M1 33V3"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m1 27.057 4.4857-1.2933c3.7591-1.085 7.8345-0.5992 11.184 1.3333 3.2685 1.8852 7.2314 2.3961 10.921 1.408l4.2482-1.1373c0.6867-0.1844 1.1602-0.773 1.1605-1.4427v-21.076c-5e-4 -0.47053-0.2362-0.91364-0.6369-1.197-0.4006-0.28341-0.9198-0.37433-1.4026-0.24562l-3.3692 0.90267c-3.6899 0.98807-7.6528 0.47715-10.921-1.408-3.3498-1.9325-7.4252-2.4183-11.184-1.3333l-4.4857 1.292"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m31.96 10.406-3.3692 0.9027c-3.6899 0.9881-7.6528 0.4772-10.921-1.408-3.3498-1.9325-7.4252-2.4183-11.184-1.3333l-4.4857 1.292"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m32.026 21.156-3.3693 0.9027c-3.6899 0.988-7.6528 0.4771-10.921-1.408-3.3498-1.9325-7.4253-2.4183-11.184-1.3334l-4.4857 1.292"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m6.5625 11.981v1.9035c1e-5 0.6017 0.18233 1.1892 0.52294 1.6852s0.82352 0.8771 1.3851 1.0931l0.26025 0.1c0.17331 0.0667 0.36519 0.0667 0.5385 0l0.26025-0.1c0.56151-0.216 1.0444-0.5971 1.3851-1.0931 0.3406-0.496 0.5229-1.0835 0.5229-1.6852v-1.9035c5e-4 -0.0716-0.0198-0.1418-0.0582-0.2022-0.0385-0.0604-0.0937-0.1084-0.1588-0.1383-0.7-0.3055-1.4567-0.4598-2.2205-0.4527-0.76378-0.0071-1.5205 0.1472-2.2205 0.4527-0.06513 0.0299-0.12026 0.0779-0.15875 0.1383-0.0385 0.0604-0.05873 0.1306-0.05825 0.2022z"
          fill="#fff"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
      <path
        d="M1 33V3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="m1 27.057 4.486-1.294c3.759-1.085 7.834-.599 11.184 1.334 3.268 1.885 7.231 2.396 10.921 1.408l4.248-1.138c.687-.184 1.16-.773 1.161-1.442V4.849c0-.47-.236-.914-.637-1.197-.4-.284-.92-.375-1.402-.246l-3.37.903c-3.69.988-7.652.477-10.921-1.408C13.32.968 9.245.482 5.486 1.567L1 2.86"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m31.96 10.406-3.369.903c-3.69.988-7.652.477-10.921-1.408-3.35-1.933-7.425-2.419-11.184-1.334L2 9.86M32.026 21.156l-3.369.903c-3.69.988-7.653.477-10.921-1.408-3.35-1.933-7.425-2.419-11.185-1.334L2.066 20.61"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.563 11.981v1.903a2.977 2.977 0 0 0 1.908 2.779l.26.1a.75.75 0 0 0 .538 0l.26-.1a2.976 2.976 0 0 0 1.909-2.779v-1.903a.372.372 0 0 0-.218-.34A5.424 5.424 0 0 0 9 11.188a5.424 5.424 0 0 0-2.22.452.372.372 0 0 0-.217.341v0z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
