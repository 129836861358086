import * as Yup from 'yup';

export const skillsForm_validationSchema = Yup.object().shape({
  candidateSkills: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Please choose skill.'),
      years_of_exp: Yup.number()
        .min(0, 'Please choose a number between 0 and 40.')
        .max(40, 'Please choose a number between 0 and 40.')
        .integer('Please choose a round number.')
        .typeError('Please choose a round number.')
        .required('Please input years of experience.'),
    }),
  ),
});

export const skillsForm_withoutYoE_validationSchema = Yup.object().shape({
  candidateSkills: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Please choose skill.'),
    }),
  ),
});
