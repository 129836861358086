import type { ChangeEvent, FocusEvent } from 'react';
import type { FormikState } from 'formik';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
  Grid,
  GridItem,
  Text,
  Box,
} from '@terminal/design-system';
import type { ProfileProps } from '../../Profile.types';

export function EducationForm({
  errors,
  onChange,
  onBlur,
  initialRef,
  values,
  touched,
  degreeOptions,
}: {
  onChange: (event: string | ChangeEvent<any>) => void;
  onBlur: (event: FocusEvent<any>) => void;
  initialRef?: React.MutableRefObject<null>;
  degreeOptions: ProfileProps['degreeChoices'];
} & Pick<
  FormikState<ProfileProps['candidate']['educations'][number]['formValues']>,
  'values' | 'errors' | 'touched'
>) {
  const currentYear = new Date().getUTCFullYear();
  const startYearsOptions = Array(currentYear - 1960)
    .fill('')
    .map((_, idx) => (currentYear - idx).toString());

  const maxYear = new Date().getUTCFullYear() + 10;
  const endYearsOptions = Array(maxYear - 1960)
    .fill('')
    .map((_, idx) => (maxYear - idx).toString());

  return (
    <VStack spacing={[4, 4, 6]}>
      <FormControl id="school_name" isInvalid={touched.school_name && !!errors.school_name}>
        <FormLabel htmlFor="school_name">School Name</FormLabel>
        <Input
          ref={initialRef}
          placeholder="Type school name"
          id="school_name"
          name="school_name"
          onChange={onChange}
          onBlur={onBlur}
          value={values.school_name}
        />
        <FormErrorMessage>{errors.school_name}</FormErrorMessage>
      </FormControl>

      <FormControl id="concentration" isInvalid={touched.concentration && !!errors.concentration}>
        <FormLabel htmlFor="concentration">Field of Study</FormLabel>
        <Input
          placeholder="Type field of study"
          id="concentration"
          name="concentration"
          onChange={onChange}
          onBlur={onBlur}
          value={values.concentration}
        />
        <FormErrorMessage>{errors.concentration}</FormErrorMessage>
      </FormControl>

      <FormControl id="degree" isInvalid={touched.degree && !!errors.degree}>
        <FormLabel htmlFor="degree">Degree</FormLabel>
        <Select
          flex={1}
          placeholder="Select Degree"
          id="degree"
          name="degree"
          defaultValue={values.degree || undefined}
          onChange={onChange}
          onBlur={onBlur}
        >
          {Object.entries(degreeOptions).map(([value, description]) => (
            <option value={value} key={`degree-${value}`}>
              {description}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors.degree}</FormErrorMessage>
      </FormControl>

      <Box w="full">
        <Grid templateColumns="repeat(2, 1fr)" gap={3} w="full">
          <GridItem>
            <FormControl
              isInvalid={touched.start_year && !!errors.start_year}
              display="flex"
              justifyContent="space-between"
              flexDir="column"
              minH="full"
            >
              <FormLabel>Start Year</FormLabel>
              <Select
                placeholder="Start Year"
                name="start_year"
                defaultValue={values.start_year || undefined}
                onChange={onChange}
                onBlur={onBlur}
              >
                {startYearsOptions.map((value) => (
                  <option value={value} key={`startYear-${value}`}>
                    {value}
                  </option>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              isInvalid={touched.graduation_year && !!errors.graduation_year}
              display="flex"
              justifyContent="space-between"
              flexDir="column"
              minH="full"
            >
              <FormLabel>End Year (or Expected)</FormLabel>
              <Select
                placeholder="End Year"
                name="graduation_year"
                defaultValue={values.graduation_year || undefined}
                onChange={onChange}
                onBlur={onBlur}
              >
                {endYearsOptions.map((value) => (
                  <option value={value} key={`endYear-${value}`}>
                    {value}
                  </option>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </Grid>
        {((touched.start_year && errors.start_year) ||
          (touched.graduation_year && errors.graduation_year)) && (
          <Grid templateColumns="repeat(2, 1fr)" gap={3} w="full" mt={2}>
            <GridItem>
              <Text variant="caption" color="text.error">
                {errors.start_year}
              </Text>
            </GridItem>
            <GridItem>
              <Text variant="caption" color="text.error">
                {errors.graduation_year}
              </Text>
            </GridItem>
          </Grid>
        )}
      </Box>
    </VStack>
  );
}
