import { useFlags } from 'launchdarkly-react-client-sdk';
import { switchUndefinedWithDefault } from 'global/utils';

/**
 * Default values:
 * - https://docs.launchdarkly.com/sdk/client-side/react/react-web#configuring-the-react-sdk
 * - https://docs.launchdarkly.com/sdk/features/evaluating#javascript
 *
 *
 * Avoid using underscores in variable names, despite the fact that LaunchDarkly's documentation suggests support for it since it does not work well.
 *
 */
export type FeatureFlag = {
  demo: boolean;
};

export const defaultLDFlag: FeatureFlag = {
  demo: false,
};

export function useCandidateFlags(): FeatureFlag {
  const featureFlags = useFlags<FeatureFlag>();
  return switchUndefinedWithDefault(featureFlags, defaultLDFlag);
}
