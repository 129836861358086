import { Icon } from '@terminal/design-system';

export function RomaniaFlagIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
        <path d="M11 1L1 2.5625V26L11 24.4375V1Z" fill="#4B71F9" />
        <path d="M32 4H23V28L32 26.5V4Z" fill="#F6623A" />
        <path d="M22 5L12 2V24.5L22 28V5Z" fill="#ECB23F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.768585 2.2021C1.1828 2.2021 1.51859 2.53789 1.51859 2.9521V32.2336C1.51859 32.6479 1.1828 32.9836 0.768585 32.9836C0.354372 32.9836 0.0185852 32.6479 0.0185852 32.2336V2.9521C0.0185852 2.53789 0.354372 2.2021 0.768585 2.2021Z"
          fill="#25383F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.768585 26.4326L5.14654 25.1702C8.81534 24.1112 12.7929 24.5854 16.0623 26.4716C19.2522 28.3116 23.1199 28.8103 26.7212 27.8459L30.8673 26.7358C31.5376 26.5558 31.9997 25.9814 32 25.3277V4.75644C31.9995 4.29718 31.7695 3.86468 31.3784 3.58806C30.9874 3.31144 30.4807 3.2227 30.0095 3.34832L26.7212 4.22937C23.1199 5.19378 19.2522 4.6951 16.0623 2.85509C12.7929 0.96891 8.81534 0.494693 5.14654 1.55369L0.768585 2.81475"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line x1="11.75" y1="1" x2="11.75" y2="25.4013" stroke="#25383F" strokeWidth="1.5" />
        <line x1="22.75" y1="4" x2="22.75" y2="28.4013" stroke="#25383F" strokeWidth="1.5" />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.768585 2.2021C1.1828 2.2021 1.51859 2.53789 1.51859 2.9521V32.2336C1.51859 32.6479 1.1828 32.9836 0.768585 32.9836C0.354372 32.9836 0.0185852 32.6479 0.0185852 32.2336V2.9521C0.0185852 2.53789 0.354372 2.2021 0.768585 2.2021Z"
        fill="#25383F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.768585 26.4326L5.14654 25.1702C8.81534 24.1112 12.7929 24.5854 16.0623 26.4716C19.2522 28.3116 23.1199 28.8103 26.7212 27.8459L30.8673 26.7358C31.5376 26.5558 31.9997 25.9814 32 25.3277V4.75644C31.9995 4.29718 31.7695 3.86468 31.3784 3.58806C30.9874 3.31144 30.4807 3.2227 30.0095 3.34832L26.7212 4.22937C23.1199 5.19378 19.2522 4.6951 16.0623 2.85509C12.7929 0.96891 8.81534 0.494693 5.14654 1.55369L0.768585 2.81475"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line x1="11.75" y1="1" x2="11.75" y2="25.4013" stroke="#25383F" strokeWidth="1.5" />
      <line x1="22.75" y1="4" x2="22.75" y2="28.4013" stroke="#25383F" strokeWidth="1.5" />
    </Icon>
  );
}
