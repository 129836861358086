import { useRouteMatch, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'global/sentry';
import {
  ErrorFallback,
  SwitchWithPageNotFound,
  ScrollToTopOnPathChange,
} from 'candidate/components';
import { CustomRoute } from 'global/components/router';
import { useCandidateAuth } from 'candidate/utils';
import { SetOnboardingController } from './subfeatures/SetOnboarding';

export function SetRouter() {
  const auth = useCandidateAuth();
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <ErrorBoundary
      fallback={
        <ErrorFallback onActionClick={() => window.location.reload()} actionTitle="Go back" />
      }
    >
      <ScrollToTopOnPathChange />
      <SwitchWithPageNotFound>
        <CustomRoute
          path={`${path}/onboarding`}
          conditionalRedirects={[
            {
              condition: auth.isAuthorized,
              redirectURL: '/',
            },
            {
              condition: !location.search,
              redirectURL: '/onboarding',
            },
          ]}
        >
          <SetOnboardingController />
        </CustomRoute>
      </SwitchWithPageNotFound>
    </ErrorBoundary>
  );
}
