import type { ComponentProps, ComponentType } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from 'global/sentry';
import { ActivityProvider } from 'global/components';
import { AuthProvider } from 'global/auth';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, Fonts } from '@terminal/design-system';
import { graphqlClient } from 'global/graphqlClient';
import { ExperimentProvider } from 'candidate/utils/experiment';

import { defaultLDFlag } from 'candidate/utils';
import { AppRouter } from './AppRouter';
import { AppTracking } from './AppTracking';

import { theme } from './theme';

const determineUserRole = (userRoles: string[]) => {
  if (userRoles.includes('candidate')) {
    return 'candidate';
  }

  return 'authenticated';
};

export const apolloClient = graphqlClient('candidate:portal', determineUserRole);

Sentry.init({ dsn: import.meta.env.REACT_APP_SENTRY_DSN_CANDIDATE });

/**
 * App component that wraps AppFixedWrappers with React Router (BrowserRouter) and
 * [LaunchDarkly](https://github.com/terminalinc/product/pull/2301#issue-1436164908)
 *
 * @returns {JSX.Element} App component
 */
const AppWithOptionalLD = (children: ComponentType<{}>) => {
  const launchDarklyClientID = import.meta.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID?.trim();
  if (launchDarklyClientID) {
    return withLDProvider({
      clientSideID: launchDarklyClientID,
      user: { key: 'anonymous-client', anonymous: true, custom: { app: 'candidate' } },
      deferInitialization: true,
      // * Launch Darkly default values - https://docs.launchdarkly.com/sdk/client-side/react/react-web#configuring-the-react-sdk - https://docs.launchdarkly.com/sdk/features/evaluating#javascript
      flags: defaultLDFlag,
    })(children);
  }
  return children;
};

export function AppFixedWrappers({
  children,
  authTesting,
}: {
  children: React.ReactNode;
  authTesting?: ComponentProps<typeof AuthProvider>['testing'];
}) {
  return (
    <ExperimentProvider>
      <AuthProvider appName="candidate" testing={authTesting}>
        <ApolloProvider client={apolloClient}>
          <ChakraProvider theme={theme} resetCSS>
            <ActivityProvider>{children}</ActivityProvider>
          </ChakraProvider>
        </ApolloProvider>
      </AuthProvider>
    </ExperimentProvider>
  );
}

export const App = AppWithOptionalLD(() => (
  <AppFixedWrappers>
    <BrowserRouter>
      <AppTracking>
        <Fonts />
        <AppRouter />
      </AppTracking>
    </BrowserRouter>
  </AppFixedWrappers>
));
