import { useFormik } from 'formik';
import { useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import * as Yup from 'yup';
import { useRouteMatch } from 'react-router-dom';
import { validators } from 'global/utils';
import { useMutation } from '@apollo/client';
import { useCandidateAuth } from 'candidate/utils';
import type { HStepsProps } from 'candidate/components';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import { UpdateCandidateLinkedin } from 'candidate/shared/modules/onboarding/data/graphql';
import type {
  UpdateCandidateLinkedinMutationVariables,
  UpdateCandidateLinkedinMutation,
} from 'candidate/shared/modules/onboarding/data/graphql/types';
import { ExperiencesWhatDoYouHave } from './ExperiencesWhatDoYouHave';

export const validationSchema = Yup.object().shape({
  linkedin: Yup.string()
    .required()
    .matches(validators.linkedIn.validURL.regex, validators.linkedIn.validURL.message),
});

type Mode = 'add' | 'review' | 'question';

const titleMode: Record<Mode, string> = {
  add: 'Add Experience',
  review: 'Review Your Experience',
  question: 'What Experience Do You Have?',
};

export function ExperiencesWhatDoYouHaveController({
  candidateID,
  mode,
  onStepCompleteSuccess,
  progressProps,
}: {
  candidateID: number;
  mode?: Mode;
  onStepCompleteSuccess: () => void;
  progressProps: HStepsProps;
}) {
  const { url } = useRouteMatch();
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });
  const auth = useCandidateAuth();
  const [updateCandidateLinkedin] = useMutation<
    UpdateCandidateLinkedinMutation,
    UpdateCandidateLinkedinMutationVariables
  >(UpdateCandidateLinkedin, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to save your personal info. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
    onCompleted: () => {
      onStepCompleteSuccess();
    },
  });
  const experiencesForm = useFormik<{ linkedin: string }>({
    initialValues: {
      linkedin: '',
    },
    validationSchema,
    onSubmit: async (values) =>
      updateCandidateLinkedin({
        variables: { candidate_id: candidateID, linkedin_url: values.linkedin },
      }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const hasValidationError = !!Object.keys(experiencesForm.errors).length;
  const urlPath = url.split('/');
  // We are removing the last path of the URL to get the variation URL
  const variationURL = urlPath.slice(0, urlPath.length - 1).join('/');

  return (
    <ExperiencesWhatDoYouHave
      isNextDisabled={!experiencesForm.dirty || hasValidationError}
      onNextClick={experiencesForm.handleSubmit}
      onSignOutClick={() => signOut({ auth: firebaseAuth })}
      progressProps={progressProps}
      title={titleMode[mode as Mode] || titleMode.question}
      userFullName={auth.userFullName}
      errors={experiencesForm.errors}
      values={experiencesForm.values}
      touched={experiencesForm.touched}
      onBlur={experiencesForm.handleBlur}
      onChange={experiencesForm.handleChange}
      addExperienceHref={`${variationURL}/manual-entry`}
    />
  );
}
