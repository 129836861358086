import { Skill } from 'candidate/shared/modules';
import type { UpsertDeleteCandidateRolesMutation } from 'candidate/shared/modules/roles/data';
import { HasuraTypes } from 'global/types';
import {
  Candidate_Availability_Choices_Enum,
  Candidate_Curation_Desired_Salary_Currency_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Candidate_Education_Degree_Choices_Enum,
  Role_Choices_Enum,
  Candidate_Skill_Comp_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import type { SelectProfileQuery, UpdateCandidatePersonalInfoMutation } from '../graphql/types';

export function createCandidate(
  overwrite?: Partial<SelectProfileQuery['candidates'][number]>,
): SelectProfileQuery['candidates'][number] {
  return {
    id: 1,
    created_at: '2020-01-28T19:12:17.26338900:00',
    updated_at: '2020-01-29T20:37:25.58680500:00',
    email: 'sara.lang@gmail.com',
    first_name: 'sarah',
    github_url: null,
    last_name: 'lang',
    linkedin_url: null,
    other_url: null,
    phone: '18679602592',
    availability: HasuraTypes.Candidate_Availability_Choices_Enum.InterestedAndAvailableNow,
    resume_filename: null,
    public_id: null,
    country_choice: {
      name: 'Canada',
      id: 1,
    },
    city: 'Toronto',
    profile_flow_variations: {},
    state: 'ON',
    latitude: null,
    longitude: null,
    applicant_consents: [
      {
        id: 1,
      },
    ],
    candidate_educations: [
      {
        id: 2,
        degree: Candidate_Education_Degree_Choices_Enum.Bootcamp,
        concentration: null,
        graduation_year: 2006,
        school_name: 'EMU',
        start_year: 2002,
        still_studying: true,
      },
      {
        id: 1,
        degree: Candidate_Education_Degree_Choices_Enum.Masters,
        concentration: 'history',
        graduation_year: 2006,
        school_name: 'EMU',
        start_year: 2002,
        still_studying: true,
      },
    ],
    candidate_roles: [
      {
        id: 1,
        role: HasuraTypes.Role_Choices_Enum.Fed,
        years_of_exp: 1,
      },
      {
        id: 2,
        role: HasuraTypes.Role_Choices_Enum.Mqa,
        years_of_exp: 1,
      },
      {
        id: 7,
        role: HasuraTypes.Role_Choices_Enum.Dev,
        years_of_exp: 1,
      },
    ],
    candidate_skills: [
      {
        id: 2,
        skill: Skill.PHP,
        competency: Candidate_Skill_Comp_Choices_Enum.Expert,
        is_favorite: false,
        years_of_exp: 1,
      },
      {
        id: 3,
        skill: Skill.React,
        competency: Candidate_Skill_Comp_Choices_Enum.Beginner,
        is_favorite: false,
        years_of_exp: 3,
      },
      {
        id: 4,
        skill: Skill.GraphQL,
        competency: Candidate_Skill_Comp_Choices_Enum.Expert,
        is_favorite: false,
        years_of_exp: 1,
      },
    ],
    candidate_work_experiences: [
      {
        id: 6,
        company_name: 'Telus',
        currently_working: true,
        start_date: '2014-10-09',
        end_date: null,
        location: 'Toronto',
        job_title: 'Coach',
        description: `• Maximized applications’ efficiency, data quality, scope, operability, and flexibility.
        • Used various ideas from distributed computing, large-scale design, real-time data processing, data storage, ML, and AI to solve challenging dataset problems.
        • Managed, optimized, and updated PHP databases as necessary.
        • Developed app integration with REST / SOAP and other APIs for Google Maps, social media logins, payment processors, and other services.`,
      },
      {
        id: 2,
        company_name: 'Benchsci',
        currently_working: false,
        start_date: '2011-10-09',
        end_date: '2014-11-09',
        location: 'Toronto',
        job_title: 'Staff Manager',
        description: `• Interviewing and hiring employees and ensuring they're properly trained.
        • Offering constant feedback to keep everyone in sync.
        • Conducting periodic performance reviews.
        • Keeping production and morale high and taking steps to improve retention.`,
      },
      {
        id: 16,
        company_name: 'Facebook inc',
        currently_working: false,
        start_date: '2006-10-09',
        end_date: '2011-10-09',
        location: 'BC',
        job_title: 'Engineer',
        description: '',
      },
    ],
    candidate_curation_detail: {
      years_of_exp_range: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus,
      desired_salary_amount: 40000,
      desired_salary_currency: Candidate_Curation_Desired_Salary_Currency_Choices_Enum.Usd,
    },
    parsed_resume: null,
    profile_flow_furthest_step: null,
    profile_flow_started_at: '2020-01-30T20:37:25.58680500:00',
    ...overwrite,
  };
}

export function createProfileData(
  overwriteCandidate?: Partial<SelectProfileQuery['candidates'][number]>,
  overwriteRoleChoices?: SelectProfileQuery['role_choices'],
): SelectProfileQuery {
  return {
    assessments: [],
    candidates: [createCandidate(overwriteCandidate)],
    candidate_education_degree_choices: [
      {
        value: 'Bootcamp',
      },
      {
        value: 'Associate',
      },
      {
        value: 'Bachelors',
      },
      {
        value: 'Doctoral',
      },
      {
        value: 'Other',
      },
      {
        value: 'Masters',
      },
    ],
    role_choices: overwriteRoleChoices || [
      {
        value: 'FED',
      },
      {
        value: 'MQA',
      },
      {
        value: 'DEV',
      },
      {
        value: 'FSD',
      },
      {
        value: 'MOD',
      },
      {
        value: 'BED',
      },
    ],
    candidate_curation_years_of_exp_range_choices: [
      { value: 'ZERO_TWO' },
      { value: 'TWO_FIVE' },
      { value: 'FIVE_TEN' },
      { value: 'TEN_PLUS' },
    ],
    skills: Object.values(Skill),
    candidate_skill_comp_choices: [],
  };
}

export const SelectProfileFixture = {
  createData: createProfileData,
  createCandidate,
  results: {
    'fresh-candidate': createProfileData({
      id: 1,
      created_at: '2020-01-28T19:12:17.26338900:00',
      updated_at: '2020-01-29T20:37:25.58680500:00',
      email: 'sara.lang@gmail.com',
      first_name: 'sarah',
      github_url: null,
      last_name: 'lang',
      linkedin_url: null,
      other_url: null,
      phone: null,
      availability: null,
      resume_filename: null,
      public_id: null,
      city: null,
      country_choice: null,
      state: null,
      latitude: null,
      longitude: null,
      applicant_consents: [],
      candidate_educations: [],
      candidate_roles: [],
      candidate_work_experiences: [],
      candidate_curation_detail: {
        years_of_exp_range: null,
        desired_salary_amount: null,
        desired_salary_currency: null,
      },
      parsed_resume: null,
      profile_flow_furthest_step: null,
      profile_flow_started_at: null,
    }),
  },
};

export const UpdateCandidatePersonalInfoFixture = {
  createData: (
    overwrite: Partial<UpdateCandidatePersonalInfoMutation> = {} as Partial<UpdateCandidatePersonalInfoMutation>,
  ): UpdateCandidatePersonalInfoMutation => ({
    update_candidate_by_pk: {
      id: 4919,
      first_name: 'Miguel',
      last_name: 'Gonzalez',
      email: 'miguel+asdssj@terminal.io',
      phone: null,
      availability: Candidate_Availability_Choices_Enum.InterestedAndAvailableLater_3PlusMonths,
      city: null,
      country: 'Chile',
      country_id: 1,
      state: null,
      latitude: null,
      longitude: null,
      country_choice: {
        id: 1,
        name: 'Chile',
      },
      __typename: 'candidate',
    },
    ...overwrite,
  }),
};

export const UpsertDeleteCandidateRolesFixture = {
  createData: (
    overwrite: Partial<UpsertDeleteCandidateRolesMutation> = {} as Partial<UpsertDeleteCandidateRolesMutation>,
  ): UpsertDeleteCandidateRolesMutation => ({
    delete_candidate_role: {
      affected_rows: 0,
      returning: [],
      // __typename: 'candidate_role_mutation_response',
    },
    insert_candidate_role: {
      affected_rows: 2,
      returning: [
        {
          id: 107,
          role: Role_Choices_Enum.Fed,
          years_of_exp: null,
          // __typename: 'candidate_role'
        },
        {
          id: 106,
          role: Role_Choices_Enum.Mod,
          years_of_exp: null,
          // __typename: 'candidate_role'
        },
      ],
      // __typename: 'candidate_role_mutation_response',
    },
    ...overwrite,
  }),
};
