import * as Yup from 'yup';

export const createResumeFileYupValidation = ({
  fileSizeError = 'The file is too large.',
  fileTypeError = 'Unsupported file type.',
}: {
  fileSizeError?: string;
  fileTypeError?: string;
} = {}) =>
  Yup.mixed()
    .test('fileSize', fileSizeError, (value) => !value || value.size <= 5000000)
    .test(
      'fileType',
      fileTypeError,
      (value) =>
        !value ||
        [
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'text/plain',
        ].includes(value.type),
    );
