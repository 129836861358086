import {
  ArrowRightIcon,
  Box,
  Button,
  CheckCircleSolidIcon,
  ErrorCircleIcon,
  Flex,
  Heading,
  ProgrammingIcon,
  Text,
} from '@terminal/design-system';
import type { AssessmentProps } from 'candidate/shared/data-layer/SelectAssessments';

export interface AssessmentCardProps {
  about: string;
  footerNote: string;
  name: string;
  onLearnMoreClick: () => void;
  status: AssessmentProps['status'];
}

export function AssessmentCard({
  about,
  footerNote,
  name,
  onLearnMoreClick,
  status,
}: AssessmentCardProps) {
  const titleColor = {
    Passed: 'text.success',
    Failed: 'text.error',
    'Did Not Pass': 'text.error',
    Completed: 'text.primary',
    'Challenge Available': 'text.primary',
  }[status] as 'text.success' | 'text.error' | 'text.primary';

  const Icon =
    {
      Passed: CheckCircleSolidIcon,
      Failed: ErrorCircleIcon,
      'Did Not Pass': ErrorCircleIcon,
      Completed: ProgrammingIcon,
      'Challenge Available': ProgrammingIcon,
    }[status] || ProgrammingIcon;

  return (
    <Box
      bg="bg.primary"
      p={6}
      borderRadius="base"
      border="1px solid"
      borderColor="ui.secondary"
      className="assessment-card"
      cursor="pointer"
      onClick={onLearnMoreClick}
      _hover={{ bgColor: 'bg.tertiary' }}
    >
      <Flex alignItems="center">
        <Icon color={titleColor} fontSize="xl" />
        <Text ml={2} color={titleColor} variant="caption">
          {status}
        </Text>
      </Flex>
      <Heading variant="heading-3" my={2} whiteSpace="normal" isTruncated>
        {name}
      </Heading>
      <Text isTruncated noOfLines={2} whiteSpace="normal" h={12}>
        {about}
      </Text>
      <Flex alignItems="center" justifyContent="space-between" w="full" mt={6}>
        <Text variant="caption" color="text.secondary">
          {footerNote}
        </Text>
        <Box>
          <Button
            variant="ghost"
            h="auto"
            p={2}
            sx={{
              '.assessment-card:hover &': {
                bg: 'transparent',
              },
            }}
          >
            <Flex alignItems="center">
              <Text
                variant="caption"
                fontWeight="bold"
                mr={3}
                sx={{
                  color: ['accent.main', 'accent.main', 'transparent'],
                  '.assessment-card:hover &': {
                    color: 'accent.main',
                  },
                }}
              >
                Learn More
              </Text>
              <ArrowRightIcon fontWeight="bold" boxSize={4} color="accent.main" />
            </Flex>
          </Button>
        </Box>
      </Flex>
    </Box>
  );
}
