import { useQuery } from 'global/utils';
import { useEffect, useState } from 'react';
import { availabilityCTAsDictionary } from 'candidate/shared';
import { SelectCandidateInitData } from './data';
import type { SelectCandidateInitDataQuery, SelectCandidateInitDataQueryVariables } from './data';

/**
 * +33% markup
 */
export const markupPercentage = 1.33;

export function useImplicitRouting<S>(
  defaultValue: S | (() => S),
  {
    shouldScrollToTopOnChange,
    onViewChange,
    scrollAnimationBehavior = 'smooth',
  }: {
    shouldScrollToTopOnChange?: boolean;
    onViewChange?: () => void;
    scrollAnimationBehavior?: ScrollBehavior;
  } = {},
) {
  // Did not deconstruct the return value to benefit from getting the useState<S> return type in this function
  // since ReturnType<typeof useState<S>> did not work.
  const useViewReturnValues = useState<S>(defaultValue);

  const currentView = useViewReturnValues[0];

  useEffect(() => {
    if (shouldScrollToTopOnChange) {
      window.scrollTo({ top: 0, left: 0, behavior: scrollAnimationBehavior });
    }
    if (onViewChange) {
      onViewChange();
    }
  }, [currentView, shouldScrollToTopOnChange, onViewChange, scrollAnimationBehavior]);

  return useViewReturnValues;
}

/**
 * Returns data that needs to be globally available on the initial app state
 * at the top level.
 */
export function useAppInitData({ userID }: { userID?: number }) {
  const { loading: isLoading_selectCandidateInitData, data: candidateInitData } = useQuery<
    SelectCandidateInitDataQuery,
    SelectCandidateInitDataQueryVariables
  >(SelectCandidateInitData, {
    context: {
      role: 'candidate',
    },
    variables: {
      user_id: userID as number,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const candidateAvailability = candidateInitData?.candidate[0]?.availability
    ? availabilityCTAsDictionary[candidateInitData?.candidate[0]?.availability].short
    : null;
  const isMember =
    (candidateInitData?.candidate[0]?.dayforce_employee?.dayforce_assignments || []).length > 0;

  return {
    candidateAvailability,
    isMember,
    isLoading: isLoading_selectCandidateInitData,
  };
}
