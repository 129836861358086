import type { HStepsProps } from 'candidate/components';
import { useExperiment } from 'candidate/utils';
import React from 'react';
import { useRolesController } from '../../../../../../shared/modules/roles/useRolesController';
import type { ProfileProps } from '../../../../Profile.types';
import { RolesSelection, RolesSelectionBase } from './RolesSelection';

type RolesSelectionVariationComponent = React.ComponentType<
  React.ComponentProps<typeof RolesSelectionBase>
>;

const ExperimentVariationMap: Record<string, RolesSelectionVariationComponent> = {
  default: RolesSelectionBase,
  'role-selection-with-banner': RolesSelection.Variation.Statistic, // duplicated to avoid messing an experiment that is still running
  'role-selection-with-banner-statistic': RolesSelection.Variation.Statistic,
  'role-selection-with-banner-partner-promise': RolesSelection.Variation.PartnerPromise,
};

export function RolesSelectionController({
  candidateID,
  candidateRoles,
  onSignOutClick,
  onStepCompleteSuccess,
  progressProps,
  roleChoices,
  userFullName,
  userID,
}: {
  candidateID: number;
  candidateRoles: ProfileProps['candidate']['roles'];
  onSignOutClick: () => Promise<void>;
  onStepCompleteSuccess: () => void;
  progressProps: HStepsProps;
  roleChoices: ProfileProps['roleChoices'];
  userFullName: string;
  userID?: number;
}) {
  const { formik, handleOnCheckboxClick, isLoading_upsert } = useRolesController({
    candidateRoles,
    userID,
    candidateID,
    onSaveSuccess: onStepCompleteSuccess,
  });

  const experiment = useExperiment();
  const roleVariation = experiment.role;

  const RolesSelectionVariation = (ExperimentVariationMap[roleVariation] ||
    ExperimentVariationMap.default) as RolesSelectionVariationComponent;

  return (
    <RolesSelectionVariation
      onSignOutClick={onSignOutClick}
      selectedRoles={formik.values.candidateRoles}
      roleChoices={roleChoices}
      onCheckboxClick={handleOnCheckboxClick}
      onNextClick={formik.handleSubmit}
      isNextDisabled={isLoading_upsert || formik.values.candidateRoles.length === 0}
      userFullName={userFullName}
      progressProps={progressProps}
    />
  );
}
