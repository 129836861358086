import { Icon } from '@terminal/design-system';

export function ScrumMasterIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <path
          d="m27.178 22.135 3.775 3.776-3.775 3.775M1.75 25.91h14.472"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.593 25.91c5.56 0 10.068-4.507 10.068-10.067 0-5.56-4.508-10.068-10.068-10.068"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M11.188 6.787a10.068 10.068 0 0 0-5.663 9.056c0 5.56 4.508 10.068 10.068 10.068h14.472"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <mask id="a" maskUnits="userSpaceOnUse" x="0" y="4.025" width="28" height="24" fill="#000">
          <path fill="#fff" d="M0 4.025h28v24H0z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.593 5.025a.75.75 0 0 0 0 1.5 9.318 9.318 0 0 1 .018 18.636H1.75a.75.75 0 0 0 0 1.5h14.472c.077 0 .15-.012.22-.033 5.578-.433 9.969-5.096 9.969-10.785 0-5.974-4.844-10.818-10.818-10.818Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.593 5.025a.75.75 0 0 0 0 1.5 9.318 9.318 0 0 1 .018 18.636H1.75a.75.75 0 0 0 0 1.5h14.472c.077 0 .15-.012.22-.033 5.578-.433 9.969-5.096 9.969-10.785 0-5.974-4.844-10.818-10.818-10.818Z"
          fill="#A0CFDA"
        />
        <path
          d="m15.61 25.16-.001-1 .002 1Zm-.017 0v-1 1Zm-13.843 0v1-1Zm0 1.5v1-1Zm13.838 0 .007-1h-.007v1Zm.024 0v-1h-.002l.002 1Zm.61 0v-1 1Zm.22-.032-.077-.997-.11.008-.106.033.293.956Zm-.599-20.853a.25.25 0 0 1-.25.25v-2a1.75 1.75 0 0 0-1.75 1.75h2Zm-.25-.25a.25.25 0 0 1 .25.25h-2c0 .967.784 1.75 1.75 1.75v-2Zm10.318 10.318c0-5.698-4.62-10.318-10.318-10.318v2a8.318 8.318 0 0 1 8.318 8.318h2ZM15.612 26.161c5.69-.01 10.299-4.626 10.299-10.318h-2a8.318 8.318 0 0 1-8.302 8.318l.003 2Zm-.019 0h.02l-.004-2h-.016v2Zm-13.843 0h13.843v-2H1.75v2Zm.25-.25a.25.25 0 0 1-.25.25v-2A1.75 1.75 0 0 0 0 25.91h2Zm-.25-.25a.25.25 0 0 1 .25.25H0c0 .966.784 1.75 1.75 1.75v-2Zm13.838 0H1.75v2h13.838v-2Zm.005 0h.002l-.014 2h.012v-2Zm.017 0h-.017v2h.02l-.003-2Zm.612 0h-.61v2h.61v-2Zm-.073.01a.249.249 0 0 1 .073-.01v2c.177 0 .35-.027.514-.077l-.587-1.912Zm9.262-9.828c0 5.162-3.985 9.395-9.046 9.788l.155 1.994c6.094-.474 10.89-5.567 10.89-11.782h-2Zm-9.818-9.818c5.422 0 9.818 4.396 9.818 9.818h2c0-6.527-5.291-11.818-11.818-11.818v2Z"
          fill="#fff"
          mask="url(#a)"
        />
        <path
          d="m18.11 9.55-3.775-3.775L18.11 2"
          stroke="#A0CFDA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <path
        d="m27.178 22.135 3.775 3.776-3.775 3.775M1.75 25.91h14.472"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.593 25.91c5.56 0 10.068-4.507 10.068-10.067 0-5.56-4.508-10.068-10.068-10.068"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.188 6.787a10.068 10.068 0 0 0-5.663 9.056c0 5.56 4.508 10.068 10.068 10.068h14.472"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="0" y="4.025" width="28" height="24" fill="#000">
        <path fill="#fff" d="M0 4.025h28v24H0z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.593 5.025a.75.75 0 0 0 0 1.5 9.318 9.318 0 0 1 .018 18.636H1.75a.75.75 0 0 0 0 1.5h14.472c.077 0 .15-.012.22-.033 5.578-.433 9.969-5.096 9.969-10.785 0-5.974-4.844-10.818-10.818-10.818Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.593 5.025a.75.75 0 0 0 0 1.5 9.318 9.318 0 0 1 .018 18.636H1.75a.75.75 0 0 0 0 1.5h14.472c.077 0 .15-.012.22-.033 5.578-.433 9.969-5.096 9.969-10.785 0-5.974-4.844-10.818-10.818-10.818Z"
        fill="#25383F"
      />
      <path
        d="m15.61 25.16-.001-1 .002 1Zm-.017 0v-1 1Zm-13.843 0v1-1Zm0 1.5v1-1Zm13.838 0 .007-1h-.007v1Zm.024 0v-1h-.002l.002 1Zm.61 0v-1 1Zm.22-.032-.077-.997-.11.008-.106.033.293.956Zm-.599-20.853a.25.25 0 0 1-.25.25v-2a1.75 1.75 0 0 0-1.75 1.75h2Zm-.25-.25a.25.25 0 0 1 .25.25h-2c0 .967.784 1.75 1.75 1.75v-2Zm10.318 10.318c0-5.698-4.62-10.318-10.318-10.318v2a8.318 8.318 0 0 1 8.318 8.318h2ZM15.612 26.161c5.69-.01 10.299-4.626 10.299-10.318h-2a8.318 8.318 0 0 1-8.302 8.318l.003 2Zm-.019 0h.02l-.004-2h-.016v2Zm-13.843 0h13.843v-2H1.75v2Zm.25-.25a.25.25 0 0 1-.25.25v-2A1.75 1.75 0 0 0 0 25.91h2Zm-.25-.25a.25.25 0 0 1 .25.25H0c0 .966.784 1.75 1.75 1.75v-2Zm13.838 0H1.75v2h13.838v-2Zm.005 0h.002l-.014 2h.012v-2Zm.017 0h-.017v2h.02l-.003-2Zm.612 0h-.61v2h.61v-2Zm-.073.01a.249.249 0 0 1 .073-.01v2c.177 0 .35-.027.514-.077l-.587-1.912Zm9.262-9.828c0 5.162-3.985 9.395-9.046 9.788l.155 1.994c6.094-.474 10.89-5.567 10.89-11.782h-2Zm-9.818-9.818c5.422 0 9.818 4.396 9.818 9.818h2c0-6.527-5.291-11.818-11.818-11.818v2Z"
        fill="#fff"
        mask="url(#a)"
      />
      <path
        d="m18.11 9.55-3.775-3.775L18.11 2"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
