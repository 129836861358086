import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { Candidate_Job_Workflow_Status_Choices_Enum } from '../../../../../global/types/hasura-tables.generated.types';
import { jobDetailSerializer } from '../JobDetail/JobDetail.job.serializer';
import type { SelectCandidateQuery } from '../data/types';
import type { SelectMyJobsQuery } from '../MyJobs/graphql';

export const serializeHandpickedJobsByTP = ({
  jobs,
  candidateInfo,
  conversionTable,
}: {
  jobs: SelectMyJobsQuery['candidate'][number]['candidate_job_workflows'] | undefined;
  candidateInfo: {
    country:
      | Exclude<SelectCandidateQuery['candidate'][number]['country_choice'], null>['name']
      | null;
    candidateSkills: SelectCandidateQuery['candidate'][number]['candidate_skills'][number]['skill']['name'];
    yearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null | undefined;
    furthestCandidateCurationWorkflow:
      | NonNullable<
          SelectCandidateQuery['candidate'][number]['furthest_candidate_curation_workflow']
        >['status']
      | null;
  } | null;
  conversionTable: SelectMyJobsQuery['currency_exchange_rate'];
}) => {
  return {
    handpickedJobsByTp: jobs
      ?.filter((job) => job.status === Candidate_Job_Workflow_Status_Choices_Enum.TpMatchPending)
      ?.map(({ job, created_at, id }) => {
        return {
          ...jobDetailSerializer({
            job,
            createdAt: created_at,
            candidateInfo,
            conversionTable,
          }),
          workFlowID: id,
        };
      })
      // Sort by date job was handpicked by TP
      .sort((jobA, jobB) => new Date(jobB.createdAt).valueOf() - new Date(jobA.createdAt).valueOf())
      // `Top applicants` at the top job list
      .sort(
        (jobA, jobB) =>
          Number(jobB.topApplicantInfo.numberOfSkillsMatched) -
          Number(jobA.topApplicantInfo.numberOfSkillsMatched),
      ),
  };
};
