import type { SkillsProps } from 'candidate/shared/modules';
import { mainSkills, moreSkills } from './SkillsStepTags.constants';

export function serializerForResumeAndExperience({
  skillChoices,
}: {
  skillChoices: Omit<SkillsProps['skillChoices'][number], 'roles'>[];
}): {
  mainSkillOptions: {
    id: number;
    name: string;
  }[];
  moreSkillOptions: {
    id: number;
    name: string;
  }[];
} {
  const mainSkillOptions = skillChoices.filter((skill) =>
    mainSkills.map((mainSkill) => mainSkill.toLowerCase()).includes(skill.name.toLowerCase()),
  );
  const moreSkillOptions = skillChoices.filter((skill) =>
    moreSkills.map((mainSkill) => mainSkill.toLowerCase()).includes(skill.name.toLowerCase()),
  );

  return { mainSkillOptions, moreSkillOptions };
}
