import { makeVar } from '@apollo/client';
import type { ReactiveVar } from '@apollo/client';

type FlowState = {
  'landing-first-time-from-completed-onboarding-flow': 'manual' | 'resume-parsing' | null;
  'is-completed-onboarding-session': boolean;
  'is-signing-up-in-direct-application': boolean;
  'landing-from-direct-application': 'claim-account' | 'create-account' | null;
};

function createUpdateFlow(flowVar: ReactiveVar<FlowState>) {
  return <T extends keyof FlowState>(key: T, value: FlowState[T]) => {
    flowVar({
      ...flowVar(),
      [key]: value,
    });
  };
}

const initialValues: FlowState = {
  'is-completed-onboarding-session': false,
  'landing-first-time-from-completed-onboarding-flow': null,
  'is-signing-up-in-direct-application': false,
  'landing-from-direct-application': null,
};

const flowVar: ReactiveVar<FlowState> = makeVar<FlowState>(initialValues);

export const flowState = {
  update: createUpdateFlow(flowVar),
  stateVar: flowVar,
  reset: () => {
    flowVar(initialValues);
  },
};
