import type { ChangeEvent, FocusEvent } from 'react';
import type { FormikState } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  AsyncTypeahead,
  Grid,
  GridItem,
  Button,
  InputGroup,
  InputRightElement,
  EditIcon,
} from '@terminal/design-system';
import type { ProfileProps } from '../../Profile.types';

export function PersonalInfoForm({
  errors,
  values,
  touched,
  initialRef,
  onBlur,
  onChange,
  onLocationSuggestionSelect,
  showLocationInput,
  showLocationSuggestionLoading,
  showLocationSuggestionNoResult,
  locationInputValue,
  onLocationInputValueChange,
  locationSuggestions,
  displaySaveBtn = false,
  showLoadingForSaveButton = false,
  onSaveClick = () => {},
}: {
  initialRef?: React.MutableRefObject<null>;
  onBlur: (event: FocusEvent<any>) => void;
  onChange: (event: string | ChangeEvent<any>) => void;
  onLocationSuggestionSelect: (address: string) => void;
  showLocationInput: boolean;
  locationInputValue: string;
  onLocationInputValueChange: (location: string, shouldFetchData: boolean) => void;
  locationSuggestions: string[];
  showLocationSuggestionLoading: boolean;
  showLocationSuggestionNoResult: boolean;
  displaySaveBtn?: boolean;
  showLoadingForSaveButton?: boolean;
  onSaveClick?: () => void;
} & Pick<
  FormikState<ProfileProps['candidate']['personalInfo']['formValues']>,
  'errors' | 'values' | 'touched'
>) {
  return (
    <>
      <Grid gridTemplateColumns={['1fr', '1fr', '1fr 1fr']} rowGap={6} columnGap={6}>
        <GridItem order={[1, 1, 'unset']}>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input value={values.email} disabled />
          </FormControl>
        </GridItem>

        <GridItem order={[4, 4, 'unset']}>
          <FormControl isInvalid={touched.phone && !!errors.phone}>
            <FormLabel htmlFor="phone">Phone Number (optional)</FormLabel>
            <InputGroup>
              <Input
                id="phone"
                name="phone"
                onBlur={onBlur}
                onChange={onChange}
                placeholder="+1-555-555-5555"
                value={values.phone}
              />
              <InputRightElement pointerEvents="none">
                <EditIcon fontSize="md" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.phone}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem order={[2, 2, 'unset']}>
          <FormControl isInvalid={touched.firstName && !!errors.firstName}>
            <FormLabel htmlFor="firstName">First Name</FormLabel>
            <InputGroup>
              <Input
                id="firstName"
                name="firstName"
                onBlur={onBlur}
                onChange={onChange}
                placeholder="First Name"
                ref={initialRef}
                value={values.firstName}
                spellCheck={false}
              />
              <InputRightElement pointerEvents="none">
                <EditIcon fontSize="md" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem order={[5, 5, 'unset']} pos="relative">
          <FormControl>
            {showLocationInput && (
              <>
                <AsyncTypeahead
                  name="location"
                  onSelectionChange={(_, address) => onLocationSuggestionSelect(address)}
                  onBlur={onBlur}
                  placeholder="Search Location"
                  options={locationSuggestions}
                  inputValue={locationInputValue}
                  fullScreen={{
                    title: 'Search Location',
                    renderBefore: ({ getLabelProps }) => (
                      <FormLabel {...getLabelProps()}>Location</FormLabel>
                    ),
                  }}
                  onInputValueChange={(change: string) => onLocationInputValueChange(change, true)}
                  onInputValueChangeAfterSelection={(change: string) =>
                    onLocationInputValueChange(change, false)
                  }
                  isLoading={showLocationSuggestionLoading}
                  showNoResult={showLocationSuggestionNoResult}
                  noOptionsMatchedCopy="Address not found"
                  containerProps={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  renderBefore={({ getLabelProps }) => (
                    <FormLabel {...getLabelProps()}>Location</FormLabel>
                  )}
                />
                <EditIcon pointerEvents="none" fontSize="md" pos="absolute" bottom={4} right={4} />
              </>
            )}
          </FormControl>
        </GridItem>

        <GridItem order={[3, 3, 'unset']}>
          <FormControl isInvalid={touched.lastName && !!errors.lastName}>
            <FormLabel htmlFor="lastName">Last Name</FormLabel>
            <InputGroup>
              <Input
                id="lastName"
                name="lastName"
                onBlur={onBlur}
                onChange={onChange}
                placeholder="Last Name"
                value={values.lastName}
                spellCheck={false}
              />
              <InputRightElement pointerEvents="none">
                <EditIcon fontSize="md" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
      {displaySaveBtn && (
        <Button
          flex={1}
          id="edit-profile-form"
          variant="primary"
          type="submit"
          onClick={onSaveClick}
          isLoading={showLoadingForSaveButton}
          mt={6}
        >
          Save
        </Button>
      )}

      <Input type="hidden" value={values.city} name="city" />
      <Input type="hidden" value={values.state} name="state" />
      <Input type="hidden" value={values.country} name="country" />
      <Input type="hidden" value={values.latitude} name="latitude" />
      <Input type="hidden" value={values.longitude} name="longitude" />
    </>
  );
}
