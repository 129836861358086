import { Box, Heading, Link, Center, Text } from '@terminal/design-system';
import * as Yup from 'yup';
import { useIsViewPortDesktop } from 'global/utils';
import { SignInForm } from 'global/auth';
import {
  PasswordFieldWithPopoverValidator,
  createPasswordYupValidation,
} from 'global/auth/components';

const signUpValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please make sure your email address is valid')
    .required('Please include an email address.'),
  password: createPasswordYupValidation(),
});

export function NoAccountCreatedSuccess({
  email,
  googleSignIn,
  handleSignUpSubmit,
  isLoading,
  shouldHideGoogleSSO,
}: {
  email: string;
  googleSignIn: () => void;
  handleSignUpSubmit: (email: string, password: string) => void;
  isLoading: boolean;
  shouldHideGoogleSSO: boolean;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <Box width={isDesktop ? 'ml' : 'auto'} marginTop={[8, 8, 14]} px={[3, 3, 8]} textAlign="center">
      <Heading variant={isDesktop ? 'heading-2' : 'heading-3'}>
        Get Matched To More Jobs With A Free Terminal Account
      </Heading>

      <Box
        mt="6"
        border="1px solid"
        borderRadius="base"
        width="full"
        py="4"
        px={[3, 3, 8]}
        borderColor="ui.secondary"
        textAlign="left"
      >
        <SignInForm
          onGoogleLoginClicked={googleSignIn}
          onEmailSignSubmitted={handleSignUpSubmit}
          showLoadingForEmailSignIn={isLoading}
          disableGoogleSignInButton={isLoading}
          email={email}
          isEmailInputDisabled={!!email}
          shouldHideGoogleSSO={shouldHideGoogleSSO}
          hideForgotPassword
          logInWithEmailLabel="Create Account"
          logInWithGoogleLabel="Create Account with Google"
          validationSchema={signUpValidationSchema}
          renderPasswordField={(formik) => (
            <PasswordFieldWithPopoverValidator
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.password && !!formik.touched.password}
              placeholder="Your Password"
              title="Your Password"
              touched={!!formik.touched.password}
              value={formik.values.password}
              submissionCount={formik.submitCount}
              mt={[4, 4, 6]}
            />
          )}
        />
        <Center>
          <Text variant="hint" mt={4} textAlign="center" maxW="17.375rem">
            By submitting your application, you agree to the{' '}
            <Link
              variant="hint"
              href="https://terminal.io/terms-of-use"
              isExternal
              fontSize="xs"
              color="accent.main"
              display="inline-block"
            >
              Terms of Service
            </Link>
          </Text>
        </Center>
      </Box>
    </Box>
  );
}
