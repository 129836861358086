import type { FormikState } from 'formik';
import { PasswordFieldWithPopoverValidator } from 'global/auth/components';
import { Button, Text, Link, Switch } from '@terminal/design-system';
import type { MouseEventHandler } from 'react';
import { ApplicationSectionBox } from '../../components';
import { Section } from '../../DirectApplication.types';
import type { GetMatchedToMoreRolesForm } from './GetMatchedToMoreRoles.controller';

export function GetMatchedToMoreRoles({
  isOpened,
  isCompleted,
  isSwitchChecked,
  sectionSubmitButtonIsLoading,
  onSectionBoxEditClick,
  handleSwitchChange,
  sectionSubmitButtonIsDisabled,
  errors,
  touched,
  values,
  handleBlur,
  handleChange,
  handleSubmit,
}: {
  isOpened: boolean;
  isSwitchChecked: boolean;
  handleBlur: (e: string | React.ChangeEvent<unknown>) => void;
  handleChange: (event: string | React.ChangeEvent<unknown>) => void;
  isCompleted: boolean;
  sectionSubmitButtonIsLoading: boolean;
  sectionSubmitButtonIsDisabled: boolean;
  onSectionBoxEditClick: (section: number) => MouseEventHandler<HTMLButtonElement>;
  handleSubmit: () => void;
  handleSwitchChange: () => void;
} & Pick<FormikState<GetMatchedToMoreRolesForm>, 'errors' | 'values' | 'touched'>) {
  return (
    <ApplicationSectionBox
      section={Section.accountCreation}
      onSectionBoxEditClick={onSectionBoxEditClick}
      isCompleted={isCompleted}
      isOpened={isOpened}
      title="Get Matched To More Roles"
      headingRightComponent={
        isOpened ? (
          <Switch
            id="getMatchedToMoreRoles"
            name="getMatchedToMoreRoles"
            isChecked={isSwitchChecked}
            onChange={handleSwitchChange}
          />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )
      }
    >
      <>
        <Text variant="body" color="text.secondary" mt={6}>
          Create a password to create an account and be considered for all matching roles within our
          exclusive network of companies, keep track of your applications, and more. An account will
          be created only when you submit your application.
        </Text>
        {isSwitchChecked && (
          <PasswordFieldWithPopoverValidator
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.password && !!touched.password}
            placeholder="Create your password"
            title="Your Password"
            touched={!!touched.password}
            value={values.password}
            submissionCount={3}
            mt={6}
          />
        )}
        <Button
          onClick={handleSubmit}
          isDisabled={sectionSubmitButtonIsDisabled}
          isLoading={sectionSubmitButtonIsLoading}
          variant="solid"
          colorScheme="primary"
          w="full"
          mt={6}
          type="submit"
        >
          Submit Your Application
        </Button>
        <Text variant="hint" color="text.secondary" mt={4} textAlign="center">
          By submitting your application, you agree to the
          <Link
            variant="hint"
            href="https://terminal.io/terms-of-use"
            isExternal
            fontSize="xs"
            color="accent.main"
            display="inline-block"
          >
            Terms of Service
          </Link>{' '}
          &
          <Link
            variant="hint"
            fontSize="xs"
            color="accent.main"
            display="inline-block"
            href="https://terminal.io/job-applicant-privacy"
          >
            Job Application Notice.
          </Link>
        </Text>
      </>
    </ApplicationSectionBox>
  );
}
