import { Icon } from '@terminal/design-system';

export function PolandFlagIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
        <path d="M1 16L12.5 14L24 18L33 16V27L22 29L11.5 25.5L1 27V16Z" fill="#F6623A" />
        <path
          d="m1 3.4194 7-2.4194 7.5 1 7 3 10.5-1.5806v12.581l-7 2-10-3-8-0.9355-7 1.9355v-12.581z"
          fill="#fff"
        />
        <path
          d="M1 33V3"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m1 27.057 4.4857-1.2933c3.7591-1.085 7.8345-0.5992 11.184 1.3333 3.2685 1.8852 7.2314 2.3961 10.921 1.408l4.2482-1.1373c0.6867-0.1844 1.1602-0.773 1.1605-1.4427v-21.076c-5e-4 -0.47053-0.2362-0.91364-0.6369-1.197-0.4006-0.28341-0.9198-0.37433-1.4026-0.24562l-3.3692 0.90267c-3.6899 0.98807-7.6528 0.47715-10.921-1.408-3.3498-1.9325-7.4252-2.4183-11.184-1.3333l-4.4857 1.292"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m6.345 13.847c3.9522-1.1406 8.2381-0.6312 11.767 1.4043 3.0938 1.7845 6.8519 2.2706 10.352 1.3332h1e-4l3.3691-0.9026c0.4001-0.1072 0.8114 0.1302 0.9186 0.5303s-0.1303 0.8114-0.5304 0.9186l-3.3692 0.9026-1e-4 1e-4c-3.8792 1.0387-8.047 0.503-11.49-1.4828l-1e-4 -1e-4c-3.1709-1.8292-7.0357-2.2916-10.602-1.2624l-4e-4 2e-4 -4.4857 1.292c-0.39804 0.1146-0.81364-0.1151-0.92829-0.5132-0.11464-0.398 0.11509-0.8136 0.51312-0.9282l4.4853-1.2919c1e-4 -1e-4 1.9e-4 -1e-4 2.9e-4 -1e-4h1.1e-4z"
          clipRule="evenodd"
          fill="#25383F"
          fillRule="evenodd"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
      <path
        d="M1 33V3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="m1 27.057 4.486-1.294c3.759-1.085 7.834-.599 11.184 1.334 3.268 1.885 7.231 2.396 10.921 1.408l4.248-1.138c.687-.184 1.16-.773 1.161-1.442V4.849c0-.47-.236-.914-.637-1.197-.4-.284-.92-.375-1.402-.246l-3.37.903c-3.69.988-7.652.477-10.921-1.408C13.32.968 9.245.482 5.486 1.567L1 2.86"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.345 13.847c3.952-1.141 8.238-.632 11.767 1.404 3.094 1.784 6.852 2.27 10.352 1.333l3.37-.902a.75.75 0 1 1 .387 1.448l-3.369.903c-3.88 1.039-8.047.503-11.49-1.483-3.17-1.829-7.036-2.291-10.601-1.262L2.273 16.58a.75.75 0 0 1-.415-1.441l4.486-1.292z"
        fill="currentColor"
      />
    </Icon>
  );
}
