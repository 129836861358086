import { Container, Flex, Box } from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';
import React from 'react';

export function TwoColumnAuthPageLayout({
  leftColumn,
  rightColumn,
}: {
  leftColumn?: React.ReactNode;
  rightColumn: React.ReactNode;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <Container
      flexDir={['column-reverse', 'column-reverse', 'row']}
      flex="1"
      justifyContent="center"
      display="flex"
      px={0}
    >
      {leftColumn && (
        <Flex flex="1" bg="bg.tertiary" position="relative">
          {
            // This empty box is used to display the blue left space in auth page layouts
            isDesktop && <Box bg="bg.tertiary" w="100%" h="full" position="absolute" right="100%" />
          }
          <Flex
            w={['full', 'full', '80%']}
            mr={[0, 0, '20%']}
            py={[0, 0, '20%']}
            alignItems="center"
          >
            {leftColumn}
          </Flex>
        </Flex>
      )}
      <Flex flex="1">
        <Flex
          px={[3, 3, 0]}
          w={['full', 'full', '80%']}
          ml={[0, 0, '20%']}
          py={[0, 0, '20%']}
          alignItems="center"
        >
          {rightColumn}
        </Flex>
      </Flex>
    </Container>
  );
}
