import type { DesiredSalaryInformation } from 'candidate/features/profile/Profile.types';
import type { FormikState } from 'formik';
import { Button, Flex, Grid } from '@terminal/design-system';
import type { HStepsProps } from 'candidate/components';
import { DesiredSalaryForm } from 'candidate/features/profile/modules/desired-salary';
import { StepPageLayout } from '../../components/StepPageLayout';

export function SalaryStep({
  isNextDisabled,
  onNextClick,
  onSignOutClick,
  userFullName,
  progressProps,
  onChange,
  onBlur,
  errors,
  values,
  touched,
  onSkipClick,
}: {
  isNextDisabled: boolean;
  onNextClick: () => void;
  onSkipClick: () => void;
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  progressProps: HStepsProps;
  onChange: (event: string | React.ChangeEvent<any>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLElement>) => void;
} & Pick<FormikState<DesiredSalaryInformation>, 'errors' | 'values' | 'touched'>) {
  return (
    <StepPageLayout
      title="What Is Your Desired Salary?"
      description="Your target salary helps us match you to jobs. We will not share this with potential employers."
      {...{ progressProps, userFullName, onSignOutClick }}
      footer={
        <Flex flexDir="column" w="full" alignItems="center">
          <Button onClick={onNextClick} w={['full', 64]} disabled={isNextDisabled}>
            Next
          </Button>
          <Button
            mt={6}
            onClick={onSkipClick}
            w={['full', 14]}
            variant="ghost"
            disableIOSHoverBugHandler
          >
            Skip
          </Button>
        </Flex>
      }
    >
      <Grid gap={[3, 3, 4]} templateColumns="10rem 1fr">
        <DesiredSalaryForm
          errors={errors}
          onChange={onChange}
          values={values}
          touched={touched}
          onBlur={onBlur}
        />
      </Grid>
    </StepPageLayout>
  );
}
