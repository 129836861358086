import { useState } from 'react';
import type { ReactNode, RefObject, MutableRefObject } from 'react';
import type { FocusableElement } from '@chakra-ui/utils';
import {
  Box,
  Button,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
} from '@terminal/design-system';

export interface EditModalProps {
  /**
   * Modal header title.
   */
  title: string;
  /**
   * If `true`, the modal will show the a confirmation footer with Discard and Keep Editing actions.
   */
  shouldConfirmClose: boolean;
  /**
   * Callback for when the save button is clicked.
   */
  onSaveClick: () => void;
  /**
   * Callback for when the delete button is clicked.
   */
  OnDeleteClick?: () => void;
  /**
   * If `true`, a reverse shadow is displayed on the modal footer.
   */
  liftFooter?: boolean;

  /**
   * The `ref` of element to receive focus when the modal closes.
   */
  finalRef?: RefObject<FocusableElement>;

  scrollBehavior?: 'outside' | 'inside';

  showDeleteButton?: boolean;
  showLoadingForDeleteButton?: boolean;
  showLoadingForSaveButton?: boolean;

  initialRef?: MutableRefObject<null>;
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  shouldHideOverlay?: boolean;

  /**
   * If set it will override the default 'Save' text.
   */
  saveText?: string;
  /**
   * If `true`, the modal will show a confirmation footer with Discard and Continue actions.
   */
  shouldConfirmSave?: boolean;
  /**
   * If set it will override the default 'Save Changes?' text.
   */
  confirmSaveTitle?: string;
  /**
   * If set it will override the default 'Are you sure you want to save your changes?' text.
   */
  confirmSaveSubtitle?: string;
  /**
   * If set it will override the default 'Save' text.
   */
  confirmSaveText?: string;
  hideFooter?: boolean;
  saveBtnBgColorScheme?: React.ComponentProps<typeof Button>['colorScheme'];
}

// TODO: rename it to UserInfoModal because of design naming
export function EditModal({
  title,
  showDeleteButton = false,
  showLoadingForDeleteButton = false,
  showLoadingForSaveButton = false,
  shouldConfirmClose,
  onSaveClick,
  OnDeleteClick,
  finalRef,
  scrollBehavior = 'inside',
  liftFooter = false,
  isOpen,
  initialRef,
  onClose,
  children,
  saveText = 'Save',
  shouldConfirmSave,
  confirmSaveTitle = 'Save Changes?',
  confirmSaveSubtitle = 'Are you sure you want to save your changes?',
  confirmSaveText = 'Save',
  shouldHideOverlay = false,
  hideFooter = false,
  saveBtnBgColorScheme,
}: EditModalProps) {
  const [footerView, setFooterView] = useState<'edit' | 'confirm-close' | 'confirm-save'>('edit');

  const handleCloseModal = () => {
    if (shouldConfirmClose) {
      setFooterView('confirm-close');
    } else {
      onClose();
    }
  };

  const handleOnSaveClick = () => {
    if (shouldConfirmSave) {
      setFooterView('confirm-save');
    } else {
      onSaveClick();
    }
  };

  return (
    <Modal
      isCentered
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleCloseModal}
      scrollBehavior={scrollBehavior}
      closeOnOverlayClick={!shouldHideOverlay}
    >
      {!shouldHideOverlay && <ModalOverlay />}
      <ModalContent
        w={['100%', 'ml', 'ml']}
        maxW={['100%', 'ml', 'ml']}
        height={['100%', 'auto', 'auto']}
        maxH={['100%', 'calc(100vh - 4rem)', 'calc(100vh - 4rem)']}
        containerProps={{ ...(shouldHideOverlay ? { visibility: 'hidden', zIndex: -1 } : {}) }}
      >
        {['confirm-close', 'confirm-save'].includes(footerView) && (
          <Box
            bg="ui.primary"
            opacity={0.25}
            w="full"
            h="full"
            position="absolute"
            zIndex={90}
            cursor="not-allowed"
          />
        )}

        <ModalHeader>
          <Text fontWeight="bold" color="accent.darkest">
            {title}
          </Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>{children}</ModalBody>

        {!hideFooter && (
          <ModalFooter bg="bg.primary" boxShadow={liftFooter ? 'reverse.md' : 'none'}>
            {footerView === 'edit' && (
              <Flex w="full">
                {!showDeleteButton ? (
                  <Button
                    flex={1}
                    variant="secondary"
                    mr={6}
                    minW="auto"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    flex={1}
                    variant="secondary"
                    colorScheme="error"
                    isLoading={showLoadingForDeleteButton}
                    mr={6}
                    minW="auto"
                    onClick={OnDeleteClick}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  flex={1}
                  id="edit-profile-form"
                  variant="primary"
                  colorScheme={saveBtnBgColorScheme}
                  type="submit"
                  onClick={handleOnSaveClick}
                  isLoading={showLoadingForSaveButton}
                >
                  {saveText}
                </Button>
              </Flex>
            )}

            {footerView === 'confirm-save' && (
              <Box w="full">
                <Text fontWeight="bold" color="accent.darkest">
                  {confirmSaveTitle}
                </Text>
                <Text mt={4}>{confirmSaveSubtitle}</Text>
                <Flex mt={8}>
                  <Button
                    flex={1}
                    variant="secondary"
                    mr={6}
                    onClick={() => {
                      onClose();
                      setFooterView('edit');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    flex={1}
                    variant="primary"
                    type="reset"
                    colorScheme="error"
                    onClick={() => {
                      onSaveClick();
                      setFooterView('edit');
                    }}
                  >
                    {confirmSaveText}
                  </Button>
                </Flex>
              </Box>
            )}

            {footerView === 'confirm-close' && (
              <Box w="full">
                <Text fontWeight="bold" color="accent.darkest">
                  Discard Changes?
                </Text>
                <Text mt={4}>Are you sure you want to discard your changes?</Text>
                <Flex mt={8}>
                  <Button
                    variant="secondary"
                    mr={6}
                    onClick={() => {
                      setFooterView('edit');
                    }}
                    flex={1}
                  >
                    Keep Editing
                  </Button>
                  <Button
                    variant="primary"
                    type="reset"
                    colorScheme="error"
                    onClick={() => {
                      onClose();
                      setFooterView(
                        'edit',
                      ); /* so that the next time the same modal opens, its on the edit view */
                    }}
                    flex={1}
                  >
                    Discard
                  </Button>
                </Flex>
              </Box>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
