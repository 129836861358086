import { Candidate_Availability_Choices_Enum } from 'global/types/hasura-tables.generated.types';

export const supportedCountries = [
  'Canada',
  'Chile',
  'Colombia',
  'Costa Rica',
  'Hungary',
  'Mexico',
  'Poland',
  'Romania',
  'Spain',
];

// e.g "Canada, Chile, Colombia, Costa Rica, Hungary, Mexico, Poland, Romania and Spain"
export const supportedCountriesReadableString = `${supportedCountries
  .slice(0, -1)
  .join(', ')} and ${supportedCountries[supportedCountries.length - 1]}`;

export const availabilityCTAsDictionary = {
  [Candidate_Availability_Choices_Enum.InterestedAndAvailableNow]: {
    short: 'Available Now',
    long: 'Interested and Available Now',
  },
  [Candidate_Availability_Choices_Enum.InterestedAndAvailableSoon_1_3Months]: {
    short: 'Available 1-3 Months',
    long: 'Interested and Available Soon (1-3 Months)',
  },
  [Candidate_Availability_Choices_Enum.InterestedAndAvailableLater_3PlusMonths]: {
    short: 'Available 3+ Months',
    long: 'Interested and Available Later (3+ Months)',
  },
  [Candidate_Availability_Choices_Enum.NotInterestedInANewRole]: {
    short: 'Not Interested',
    long: 'Not Interested In a New Role',
  },
};
