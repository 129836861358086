import { PublicPageLayout } from 'candidate/components';
import { CenteredContainer } from 'candidate/components/CenteredContainer';
import { Button, Image, Flex, Heading, Link, Text, Wrap, WrapItem } from '@terminal/design-system';
import { Link as RouterLink } from 'react-router-dom';
import illustration1 from './illustration-1.svg?url';
import illustration2 from './illustration-2.svg?url';
import illustration3 from './illustration-3.svg?url';
import illustration4 from './illustration-4.svg?url';

function HowItWorksBase({
  perks,
  footer,
}: {
  perks: {
    message: string;
    imageSRC: string;
  }[];
  footer: React.ReactNode;
}) {
  return (
    <PublicPageLayout
      headerRightPlaceholderDesktop={
        <Flex alignItems="center">
          <Text color="text.inverse">Do You Have a Terminal Account?</Text>
          <Link
            as={RouterLink}
            to={`/auth${window.location.search}`}
            color="text.inverse"
            ml={2}
            fontWeight="bold"
          >
            Login
          </Link>
        </Flex>
      }
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link
            as={RouterLink}
            to={`/auth${window.location.search}`}
            color="text.inverse"
            ml={2}
            fontWeight="bold"
          >
            Login
          </Link>
        </Flex>
      }
    >
      <CenteredContainer py={6} px={[4, 4, 0]}>
        <Heading
          variant="heading-1"
          as="h1"
          fontSize={['3xl', '3xl', '4xl']}
          color="accent.darkest"
          textAlign="center"
        >
          Unlock Access to Top Remote Tech Jobs
        </Heading>
        <Heading
          variant="heading-2"
          as="h2"
          fontSize={['md', 'md', '2xl']}
          textAlign="center"
          mt={[2, 2, 1]}
        >
          The process is simple and kicks off immediately.
        </Heading>
        <Wrap
          flexDir={['column', 'column', 'row']}
          spacing={8}
          mt={10}
          align="center"
          justify="center"
          data-chromatic="ignore"
        >
          {perks.map(({ message, imageSRC }) => (
            <WrapItem
              flexDir={['row', 'row', 'column']}
              w={['fill-content', 'fill-content', 64]}
              key={imageSRC}
              alignItems="center"
            >
              <Image h={[22, 22, 40]} src={imageSRC} mr={[3, 3, 0]} />
              <Text textAlign={['left', 'left', 'center']} mt={1}>
                {message}
              </Text>
            </WrapItem>
          ))}
        </Wrap>
        {footer}
      </CenteredContainer>
    </PublicPageLayout>
  );
}

function VariationWithSignUpAtBeginning() {
  return (
    <HowItWorksBase
      perks={[
        {
          message: 'Sign up for Terminal and answer a few brief questions.',
          imageSRC: illustration1,
        },
        { message: 'Review your job matches and apply with one click.', imageSRC: illustration2 },
        {
          message: 'Connect with our talent team to find the perfect fit.',
          imageSRC: illustration3,
        },
        { message: 'Land your dream job and take on new challenges.', imageSRC: illustration4 },
      ]}
      footer={
        <Button
          w={['full', 'full', 'sm']}
          mt={10}
          size="lg"
          as={RouterLink}
          to={`/onboarding/sign-up/form${window.location.search}`}
        >
          Let&apos;s Go
        </Button>
      }
    />
  );
}

function VariationWithSignUpAtTheEnd({
  onStepCompleteSuccess,
}: {
  onStepCompleteSuccess: () => void;
}) {
  return (
    <HowItWorksBase
      perks={[
        {
          message: 'Answer a few brief questions to create your profile.',
          imageSRC: illustration1,
        },
        { message: 'Review your job matches and apply with one click.', imageSRC: illustration2 },
        {
          message: 'Connect with our talent team to find the perfect fit.',
          imageSRC: illustration3,
        },
        { message: 'Land your dream job and take on new challenges.', imageSRC: illustration4 },
      ]}
      footer={
        <Button w={['full', 'full', 'sm']} mt={10} size="lg" onClick={onStepCompleteSuccess}>
          Let&apos;s Go
        </Button>
      }
    />
  );
}

export const HowItWorks = {
  Variation: {
    WithSignUpAtBeginning: VariationWithSignUpAtBeginning,
    WithSignUpAtTheEnd: VariationWithSignUpAtTheEnd,
  },
};
