import type { ComponentProps } from 'react';
import { Flex, Heading, Text, Button, Image, Center } from '@terminal/design-system';
import emailVerificationImageSrc from 'candidate/assets/images/email-verification.svg?url';
import { CandidateAuthDefaultPageTemplate } from '../CandidateAuthDefaultTemplate';

export function CandidateEmailNotVerifiedPage(
  props: ComponentProps<typeof CandidateAuthDefaultPageTemplate>,
) {
  return (
    <CandidateAuthDefaultPageTemplate
      {...props}
      leftImage={<Image src={emailVerificationImageSrc} maxW="18rem" />}
    >
      <Flex flexDir="column" alignItems={['center', 'center', 'flex-start']}>
        <Center display={['flex', 'flex', 'none']}>
          <Image src={emailVerificationImageSrc} maxW="9.8rem" />
        </Center>
        <Heading
          mt={[6, 6, 0]}
          variant="heading-2"
          color="brand.main"
          textAlign={['center', 'center', 'left']}
        >
          Please Verify Your Email Address
        </Heading>
        <Text textAlign={['center', 'center', 'left']} mt={2}>
          You will need to verify your email address before you can proceed. Please check your inbox
          for a verification email from Terminal.
        </Text>
        <Text textAlign={['center', 'center', 'left']} mt={7}>
          If you do not see an email, you can request a new link below.
        </Text>
        <Button onClick={props.footerLinkOnClick} mt={6} w="full" isLoading={props.isLoading}>
          Resend Verification Email
        </Button>
        <Button onClick={props.footerSecondaryClick} mt={6} w="full" variant="secondary">
          Logout
        </Button>
      </Flex>
    </CandidateAuthDefaultPageTemplate>
  );
}
