import { useAuth } from 'global/auth';

export function useCandidateAuth() {
  const useAuthProp = useAuth();

  const { roles } = useAuthProp;

  /** User friendly full name is taken from the data in this order
  /* 1. candidate.[first_name last_name]
  /* 2. user_info.name
  /* 3. user_info.email
  */
  const candidateFullName = [
    useAuthProp.user?.candidate?.first_name,
    useAuthProp.user?.candidate?.last_name,
  ]
    .filter(Boolean)
    .join(' ');

  const userFullName = candidateFullName || useAuthProp.user?.name || useAuthProp.user?.email;

  return {
    ...useAuthProp,
    userFullName,
    isAuthorized: !!(
      roles.includes('candidate') &&
      // User who are anonymously signed in will not have an email.
      useAuthProp.user?.email &&
      (useAuthProp.user?.candidate_skip_email_verify === true ||
        useAuthProp.user?.skip_email_verify === true ||
        useAuthProp.isEmailVerified === true)
    ),
    isCandidate: roles.includes('candidate'),
    candidateID: useAuthProp.user?.candidate?.id,
  };
}
