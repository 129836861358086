import {
  Box,
  Button,
  CheckCircleSolidIcon,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  ProgrammingIcon,
  Flex,
  VStack,
  Heading,
  Text,
  ErrorCircleIcon,
} from '@terminal/design-system';
import { stripAllHTMLAttributes } from 'global/utils';
import type {
  AssessmentProps,
  AssessmentHistory,
} from 'candidate/shared/data-layer/SelectAssessments';

export interface AssessmentDetailsProps {
  about?: string;
  duration?: string;
  format?: string;
  history?: AssessmentHistory;
  instructions?: string;
  isOpen: boolean;
  isStartLoading: boolean;
  canTakeTest?: boolean;
  name?: string;
  onClose: () => void;
  onStartClick: () => void;
  status?: AssessmentProps['status'];
}

export function AssessmentDetails({
  about,
  duration,
  format,
  history,
  instructions,
  isOpen,
  isStartLoading,
  canTakeTest,
  name,
  onClose,
  onStartClick,
  status,
}: AssessmentDetailsProps) {
  const titleColor = {
    Passed: 'text.success',
    Failed: 'text.error',
    'Did Not Pass': 'text.error',
    Completed: 'text.primary',
    'Challenge Available': 'text.primary',
  }[status || 'Challenge Available'] as 'text.success' | 'text.error' | 'text.primary';

  const Icon = {
    Passed: CheckCircleSolidIcon,
    Failed: ErrorCircleIcon,
    'Did Not Pass': ErrorCircleIcon,
    Completed: ProgrammingIcon,
    'Challenge Available': ProgrammingIcon,
  }[status || 'Challenge Available'];

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay>
        <DrawerContent pt={[16, 16, 20]} pb={[4, 4, 8]} h="full" justifyContent="space-between">
          <DrawerCloseButton fontSize="md" right="initial" left={5} top={5} />
          <DrawerBody px={[6, 6, 20]} mb={4}>
            <Flex alignItems="center" mb={6}>
              <Icon color={titleColor} fontSize="xl" />
              <Text ml={2} color={titleColor} variant="caption">
                {status}
              </Text>
            </Flex>
            <Heading variant="heading-1">{name}</Heading>
            <VStack spacing={8} my={6} align="stretch">
              <Box>
                <Text variant="label" mb={1}>
                  About This Test
                </Text>
                <Text>{about}</Text>
              </Box>
              <Box>
                <Text variant="label" mb={1}>
                  Instructions
                </Text>
                <Text
                  sx={{
                    'ul, ol': {
                      pl: 4,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: stripAllHTMLAttributes(instructions || ''),
                  }}
                />
              </Box>
              <Flex>
                <Box mr={10}>
                  <Text variant="label" mb={1}>
                    Duration
                  </Text>
                  <Text>{duration}</Text>
                </Box>
                <Box>
                  <Text variant="label" mb={1}>
                    Format
                  </Text>
                  <Text>{format}</Text>
                </Box>
              </Flex>
              <Box>
                <Box>
                  <Text variant="label" mb={1}>
                    History
                  </Text>
                  <VStack spacing={2} alignItems="flex-start" className="chromatic-ignore">
                    {history?.histories &&
                      history?.histories.map(({ title, result }) => (
                        <Box key={title} className="chromatic-ignore">
                          <Text mb={1} className="chromatic-ignore">
                            {title}
                          </Text>
                          <Text>{result}</Text>
                        </Box>
                      ))}
                  </VStack>
                </Box>
                <Text className="chromatic-ignore" mt={history?.histories.length ? 8 : 0}>
                  {history?.description}
                </Text>
              </Box>
            </VStack>
          </DrawerBody>
          <DrawerFooter justifyContent="flex-end">
            <Button
              mx={[4, 4, 8]}
              mt={[4, 4, 8]}
              onClick={onStartClick}
              w={[40, 40, 64]}
              disabled={!canTakeTest}
              isLoading={isStartLoading}
            >
              Start
            </Button>
          </DrawerFooter>
          {/* <DrawerFooter p={0} boxShadow="reverse.md" bg="bg.secondary"></DrawerFooter> */}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
