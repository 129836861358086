import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { BlankScreenLoading } from 'global/components';
import { useAppInitData, useCandidateAuth } from 'candidate/utils';
import { Box, useClipboard, useToast } from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import * as events from 'global/events';
import { SelectReferralInfo } from './graphql';
import { Referral } from './Referral';
import { serializerForReferral } from './Referral.serializer';
import type { SelectReferralInfoQuery, SelectReferralInfoQueryVariables } from './Referral.types';

export function ReferralController() {
  const auth = useCandidateAuth();
  const isDesktop = useIsViewPortDesktop();

  const { candidateAvailability, isLoading: isLoading_candidateAvailability } = useAppInitData({
    userID: auth.user?.id,
  });

  const toast = useToast({
    position: isDesktop ? 'top' : 'bottom',
  });

  const { loading: isLoading_selectReferralInfo, data } = useQuery<
    SelectReferralInfoQuery,
    SelectReferralInfoQueryVariables
  >(SelectReferralInfo, {
    context: {
      role: 'candidate',
    },
    variables: {
      user_id: auth.user?.id as number,
    },
    fetchPolicy: 'cache-and-network',
  });

  // After coping, Chakra UI has copies stays true for a few seconds,
  // then it changes back to false. Therefore we are maintaining that state
  // ourselves.
  const [hasCopied, setHasCopied] = useState<boolean>(false);
  const { onCopy, value, setValue } = useClipboard('');

  if (isLoading_selectReferralInfo || isLoading_candidateAvailability) {
    return <BlankScreenLoading />;
  }

  const { terminalIOCandidateReferralURL, isMember, referralAmount, messageToCopy } =
    serializerForReferral(data as SelectReferralInfoQuery);

  if (value !== messageToCopy) {
    setValue(messageToCopy);
  }

  const handleOnCopyClick = async () => {
    try {
      events.track(events.name.referralPage.referralLinkCopied, {
        page_version: isMember ? 'member' : 'candidate',
        referral_link: terminalIOCandidateReferralURL,
        referral_amount: referralAmount,
      });

      onCopy();
      setHasCopied(true);

      toast({
        title: 'Link copied!',
        description: 'Paste the link to share via SMS or social media to start referring.',
        status: 'success',
      });
    } catch (error) {
      setHasCopied(false);
      toast({
        title: 'Failed to copy',
        description: 'Please try again',
        status: 'error',
      });
    }
  };

  return (
    <Box w="full" overflowX="hidden">
      {isMember ? (
        <Referral.Variation.WithMemberInfo
          hasCopied={hasCopied}
          isDesktop={isDesktop}
          onCopyClick={handleOnCopyClick}
          onSignOutClick={() => signOut({ auth: firebaseAuth })}
          terminalIOCandidateReferralURL={terminalIOCandidateReferralURL}
          userFullName={auth.userFullName}
        />
      ) : (
        <Referral.Variation.WithNonMemberInfo
          candidateAvailability={candidateAvailability}
          hasCopied={hasCopied}
          isDesktop={isDesktop}
          onCopyClick={handleOnCopyClick}
          onSignOutClick={() => signOut({ auth: firebaseAuth })}
          terminalIOCandidateReferralURL={terminalIOCandidateReferralURL}
          userFullName={auth.userFullName}
        />
      )}
    </Box>
  );
}
