import { useEffect, useState, useRef } from 'react';
import type { HStepsProps } from 'candidate/components';
import { SelectAllPublicJobs } from 'candidate/features/job/graphql';
import { serializerForAllJobs } from 'candidate/features/job/subfeatures/allJobs/AllJobs.serializer';
import { JobDetail } from 'candidate/shared/modules/job/JobDetail';
import { useIsViewPortDesktop } from 'global/utils';
import type { JobSuggestionProps } from 'candidate/shared/modules/job/data/JobSuggestions.types';
import { BlankScreenLoading } from 'global/components';
import type { serializeJobDetails } from 'candidate/features/job/utils';
import type {
  SelectAllPublicJobsQuery,
  SelectAllPublicJobsQueryVariables,
} from 'candidate/features/job/graphql/types';
import { useQuery } from '@apollo/client';
import { useRolesController } from 'candidate/shared/modules/roles/useRolesController';
import type { ProfileProps } from 'candidate/features/profile/Profile.types';
import { RolesAndJobs } from './RolesAndJobs';

export function RolesAndJobsController({
  candidateID,
  candidateRoles,
  onStepCompleteSuccess,
  progressProps,
  roleChoices,
  userID,
  shouldDisplayBanner = false,
}: {
  candidateID: number;
  candidateRoles: ProfileProps['candidate']['roles'];
  onStepCompleteSuccess: () => void;
  progressProps: HStepsProps;
  roleChoices: ProfileProps['roleChoices'];
  userID?: number;
  shouldDisplayBanner?: boolean;
}) {
  const scrollRef = useRef<number>(0);
  const isDesktop = useIsViewPortDesktop();
  const [selectedJob, setSelectedJob] = useState<
    ReturnType<typeof serializeJobDetails> | Record<string, any>
  >({});
  const { loading: isLoading_allJobs, data: allJobsData } = useQuery<
    SelectAllPublicJobsQuery,
    SelectAllPublicJobsQueryVariables
  >(SelectAllPublicJobs, {
    context: {
      role: 'candidate',
    },
    fetchPolicy: 'cache-and-network',
  });
  const { otherJobs } = serializerForAllJobs({
    // @ts-ignore -> SelectAllPublicJobs does not include salaries for safety reasons
    allJobsData,
  });
  const firstJob = otherJobs?.[0];

  const { formik, handleOnCheckboxClick, isLoading_upsert } = useRolesController({
    candidateRoles,
    userID,
    candidateID,
    onSaveSuccess: onStepCompleteSuccess,
  });

  useEffect(() => {
    if (isDesktop === undefined || isDesktop || isLoading_allJobs) return undefined;

    const bottomGap = 5;
    const onScroll = () => {
      const scrollBottom = window.scrollY + window.innerHeight;
      const isScrollDown = scrollRef.current < window.scrollY;
      const hasVerticalScroll = document.body.scrollHeight > window.innerHeight;

      // when the scroll reaches the bottom, open the modal
      if (
        !hasVerticalScroll ||
        (isScrollDown && scrollBottom >= document.body.scrollHeight - bottomGap)
      ) {
        setSelectedJob({ id: 'empty' });
      }

      scrollRef.current = window.scrollY;
    };

    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, isLoading_allJobs]);

  if (isLoading_allJobs) {
    return <BlankScreenLoading />;
  }

  return (
    <RolesAndJobs
      selectedRoles={formik.values.candidateRoles}
      roleChoices={roleChoices}
      onCheckboxClick={handleOnCheckboxClick}
      onNextClick={formik.handleSubmit}
      isNextDisabled={isLoading_upsert || formik.values.candidateRoles.length === 0}
      progressProps={progressProps}
      allJobs={otherJobs}
      activeSuggestion={(selectedJob?.id || firstJob?.id) as number}
      onJobDetailClick={(job) => {
        setSelectedJob(job);
      }}
      isModalOpen={!!selectedJob?.id}
      onModalClose={() => setSelectedJob({})}
      shouldDisplayBanner={shouldDisplayBanner}
      jobSectionDetail={
        <JobDetail
          isModalOpen={false}
          isDrawerOpen={false}
          isApplyButtonLoading={false}
          hasAlreadyApplied={false}
          onApplyClick={() => {
            setSelectedJob((firstJob || { id: 'empty' }) as JobSuggestionProps);
          }}
          {...(selectedJob?.jobDetail || firstJob?.jobDetail || {})}
        />
      }
    />
  );
}
