import { convertToEmploymentTypeCopy, createContractorRateCTA } from 'candidate/features/job/utils';
import {
  Employment_Type_Choices_Enum,
  Candidate_Curation_Workflow_Status_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Interview_Type_Choices_Enum,
  Candidate_Curation_Desired_Salary_Currency_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { convertFromMinutesToHuman } from 'global/utils';
import { uniqWith } from 'ramda';
import type { SelectCandidateQuery } from '../data';
import type { SelectMyJobsQuery } from '../MyJobs/graphql';
import type { JobDetailDataProps } from './JobDetail.types';

const formatCurrency = ({
  currencySymbol,
  isoCode,
  isSuffix,
  moneyAmount,
}: {
  currencySymbol: string;
  isoCode?: string;
  isSuffix?: boolean;
  moneyAmount: number;
}) => {
  // If number is > 999, adds the letter `K or M` (as thousand and millions)
  const formatter = Intl.NumberFormat('en', { notation: 'compact' }).format;

  // The `replace` add a space between the letters (e.g 1.8M -> 1.8 M)
  return `${isoCode || ''} ${!isSuffix ? currencySymbol : ''}${formatter(
    Math.floor(moneyAmount),
  ).replace(/[A-Z]/g, ' $&')}${isSuffix ? ` ${currencySymbol}` : ''}`;
};

const createSalaryRangeCTA = ({
  salaryRange,
  candidateCountry,
  conversionTable,
}: {
  salaryRange: {
    minSalary: number;
    maxSalary: number;
    currencySymbol: string;
    isSuffix: boolean;
  };
  candidateCountry: string;
  conversionTable: SelectMyJobsQuery['currency_exchange_rate'];
}): string | null => {
  const currency = conversionTable?.find(
    ({ country_choice }) =>
      country_choice?.name.toLowerCase().trim() === candidateCountry?.toLowerCase().trim(),
  );

  if (!currency) return null;

  // Return null if multiplication is NaN
  if (
    [salaryRange?.maxSalary, salaryRange?.maxSalary, currency.finance_exchange_rate].some(
      (value) => !Number.isFinite(value) || value === 0,
    )
  ) {
    return null;
  }

  return `${formatCurrency({
    // Due business decisions, We display CR (Costa Rica's) salaries in `USD`, not `CRC`
    isoCode:
      currency.iso_code === 'CRC'
        ? Candidate_Curation_Desired_Salary_Currency_Choices_Enum.Usd
        : currency.iso_code,
    currencySymbol: salaryRange.currencySymbol,
    isSuffix: salaryRange.isSuffix,
    // TODO: [TP-1473] Fix this
    // eslint-disable-next-line no-unsafe-optional-chaining
    moneyAmount: salaryRange?.minSalary * currency.finance_exchange_rate,
  })} -${formatCurrency({
    currencySymbol: salaryRange.currencySymbol,
    isSuffix: salaryRange.isSuffix,
    // TODO: [TP-1473] Fix this
    // eslint-disable-next-line no-unsafe-optional-chaining
    moneyAmount: salaryRange?.maxSalary * currency.finance_exchange_rate,
  })}/year`;
};

// TODO: Duplicated from https://github.com/terminalinc/product/blob/master/web-customer/src/candidate/features/job/utils.ts
const findSalaryRangeByLocation = ({
  candidateCountry,
  jobSalaries,
}: {
  candidateCountry?: string;
  jobSalaries:
    | NonNullable<
        SelectMyJobsQuery['candidate'][number]['candidate_job_workflows'][number]['job']['job_meta_infos'][number]
      >
    | undefined;
}) => {
  switch (candidateCountry?.toLowerCase().trim()) {
    case 'mexico':
    case 'chile':
    case 'colombia':
    case 'costa rica':
      return {
        minSalary: jobSalaries?.latam_min_salary,
        maxSalary: jobSalaries?.latam_max_salary,
        currencySymbol: '\u0024', // '$',
        isSuffix: false,
      };

    case 'spain':
      return {
        minSalary: jobSalaries?.europe_min_salary,
        maxSalary: jobSalaries?.europe_max_salary,
        currencySymbol: '\u20AC', // '€'
        isSuffix: false,
      };

    case 'poland':
      return {
        minSalary: jobSalaries?.europe_min_salary,
        maxSalary: jobSalaries?.europe_max_salary,
        currencySymbol: 'zł', // Unicode does not exist
        isSuffix: true,
      };

    case 'romania':
      return {
        minSalary: jobSalaries?.europe_min_salary,
        maxSalary: jobSalaries?.europe_max_salary,
        currencySymbol: 'lei', // Leu is the singular and Lei is the plural. 1 leu, 2 Lei
        isSuffix: true,
      };

    case 'hungary':
      return {
        minSalary: jobSalaries?.europe_min_salary,
        maxSalary: jobSalaries?.europe_max_salary,
        currencySymbol: 'Ft', // Unicode does not exist
        isSuffix: true,
      };

    case 'canada':
      return {
        minSalary: jobSalaries?.canada_min_salary,
        maxSalary: jobSalaries?.canada_max_salary,
        currencySymbol: '\u0024', // '$',
        isSuffix: false,
      };

    default:
      return null;
  }
};

export const duplicatedTechStackMappings = [
  // mapTo should the same value as in the `skill` table
  {
    stacks: ['node', 'nodejs', 'node.js', 'node js'],
    mapTo: 'Node',
  },
  {
    stacks: ['js', 'javascript'],
    mapTo: 'JavaScript',
  },
  {
    stacks: ['postgres', 'postgresql'],
    mapTo: 'Postgres',
  },
  {
    stacks: ['graphql', 'graph ql'],
    mapTo: 'GraphQL',
  },
  {
    stacks: ['react', 'react js', 'preact', 'reactjs'],
    mapTo: 'React',
  },
  {
    stacks: ['express', 'express js'],
    mapTo: 'Express.js',
  },
  {
    stacks: ['cicd', 'ci/cd'],
    mapTo: 'CI/CD',
  },
];

// Tech stack + required skills
function mergeTechStackWithSkills_andRemoveDuplicates({
  requiredSkills,
  techStack,
}: {
  requiredSkills: SelectMyJobsQuery['candidate'][number]['candidate_job_workflows'][number]['job']['job_required_skills'];
  techStack: string[] | null;
}) {
  return uniqWith(
    (a, b) => a?.toLowerCase() === b?.toLowerCase(),
    [
      ...[
        requiredSkills.map(({ skill: { name } }) => name),
        // Empty skill returns 'N/A'
      ].filter((skill, idx) => skill[idx] !== 'N/A' && skill),
      techStack,
    ]
      .flat(Infinity)
      // Normalize data (e.g ['node.js', 'node'] => ['nodeJS'])
      //   @ts-ignore
      .map((current: string) => {
        const skillDuplicateMap = duplicatedTechStackMappings.find(({ stacks }) => {
          return stacks.includes(current?.toLowerCase().trim());
        });
        return skillDuplicateMap ? skillDuplicateMap.mapTo : current?.trim();
      }),
  ).filter(Boolean);
}

const yearsOfExperienceOptions = [
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus,
];

const hasCandidateEnoughExperience = ({
  candidateYearsOfExperience,
  jobsYearsOfExperience,
}: {
  candidateYearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null | undefined;
  jobsYearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null;
}) => {
  const yearsOfExperienceIndex = yearsOfExperienceOptions.findIndex(
    (option) => option === candidateYearsOfExperience,
  );
  // Adding previous, current and next years of experience to candidate's years
  // e.g a 2-5YoE dev meets a [0-2, 2-5, 5-10]YoE job requirement
  const candidateYearsOfExperienceArray = yearsOfExperienceOptions
    .slice()
    .splice(
      yearsOfExperienceIndex === 0 ? 0 : yearsOfExperienceIndex - 1,
      yearsOfExperienceIndex + 2,
    );

  return jobsYearsOfExperience && candidateYearsOfExperienceArray.includes(jobsYearsOfExperience);
};

const createYearsOfExperienceCopy = ({
  candidateYearsOfExperience,
  jobsYearsOfExperience,
}: {
  candidateYearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null | undefined;
  jobsYearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null;
}) => {
  if (!hasCandidateEnoughExperience({ candidateYearsOfExperience, jobsYearsOfExperience }))
    return null;

  switch (true) {
    case candidateYearsOfExperience === Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo:
      return '0-2 years experience';

    case jobsYearsOfExperience?.includes(
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive,
    ) || candidateYearsOfExperience === Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive:
      return '2+ years experience';

    case jobsYearsOfExperience?.includes(
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen,
    ) || candidateYearsOfExperience === Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen:
      return '5+ years experience';

    case jobsYearsOfExperience?.includes(
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus,
    ) || candidateYearsOfExperience === Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus:
      return '10+ years experience';

    default:
      return null;
  }
};

const convertToHumanInterviewType = (type: Interview_Type_Choices_Enum) => {
  return (
    {
      [Interview_Type_Choices_Enum.HiringManagerInterview as Interview_Type_Choices_Enum]:
        'Hiring manager interview',
      [Interview_Type_Choices_Enum.TechnicalAssessmentHome]: 'Technical assessment - take home',
      [Interview_Type_Choices_Enum.TechnicalInterview]: 'Technical interview',
      [Interview_Type_Choices_Enum.VirtualOnsite]: 'Virtual onsite',
      [Interview_Type_Choices_Enum.FinalInterview]: 'Final Interview',
    }[type] || null
  );
};

/**
 * Serializer for jobDetail that comes from new `job` table
 */
export const jobDetailSerializer = ({
  job,
  createdAt,
  candidateInfo,
  conversionTable,
}: {
  job: SelectMyJobsQuery['candidate'][number]['candidate_job_workflows'][number]['job'];
  createdAt: SelectMyJobsQuery['candidate'][number]['candidate_job_workflows'][number]['created_at'];
  candidateInfo: {
    country:
      | Exclude<SelectCandidateQuery['candidate'][number]['country_choice'], null>['name']
      | null;
    candidateSkills: SelectCandidateQuery['candidate'][number]['candidate_skills'][number]['skill']['name'];
    yearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null | undefined;
    furthestCandidateCurationWorkflow:
      | NonNullable<
          SelectCandidateQuery['candidate'][number]['furthest_candidate_curation_workflow']
        >['status']
      | null;
  } | null;
  conversionTable: SelectMyJobsQuery['currency_exchange_rate'];
}) => {
  const jobOrganization = job.organization;

  const jobSalary =
    candidateInfo?.country && job?.employment_type !== Employment_Type_Choices_Enum.Contract
      ? createSalaryRangeCTA({
          salaryRange: findSalaryRangeByLocation({
            candidateCountry: candidateInfo.country,
            jobSalaries: job.job_meta_infos[0],
          })!,
          candidateCountry: candidateInfo.country,
          conversionTable,
        })
      : createContractorRateCTA({
          salaryRange: {
            minSalary: job?.min_contract_rate,
            maxSalary: job?.max_contract_rate,
            currencySymbol: '\u0024', // '$',
            isSuffix: false,
          },
        });

  const techStack = mergeTechStackWithSkills_andRemoveDuplicates({
    requiredSkills: job.job_required_skills,
    techStack: job.job_tech_stack.map((stack) => stack.skill.name),
  });

  const isTopApplicant =
    !!job.job_required_skills.filter(Boolean).length &&
    // Check if candidate matches ALL job required skills
    mergeTechStackWithSkills_andRemoveDuplicates({
      requiredSkills: job.job_required_skills,
      techStack: null,
    })
      .filter(Boolean)
      .every((requiredSkill) =>
        candidateInfo?.candidateSkills?.filter(Boolean).includes(requiredSkill),
      ) &&
    // Check if candidate has enough years of experience
    hasCandidateEnoughExperience({
      candidateYearsOfExperience: candidateInfo?.yearsOfExperience,
      jobsYearsOfExperience: job.min_years_of_experience,
    });

  return {
    id: job.icims_job_profile_id,
    createdAt,
    title: job.title || null,
    logo: jobOrganization?.logo
      ? {
          src: jobOrganization?.logo,
          alt: job.title || '',
        }
      : null,
    logo_sq: jobOrganization?.logo_sq
      ? {
          src: jobOrganization?.logo_sq,
          alt: job.title || '',
        }
      : null,
    // Includes job's tech_stack and 3 required skills
    techStacks: techStack.map((stack) => {
      return {
        stack,
        shouldHighlight: isTopApplicant
          ? !!candidateInfo?.candidateSkills?.find(
              (candidateSkill: string) =>
                stack.toLowerCase().trim() === candidateSkill.toLowerCase().trim(),
            )
          : false,
      };
    }),
    descriptiveInfos: [
      {
        title: 'About The Role',
        description: job?.about,
      },
      {
        title: 'What You’ll Do',
        description: job?.what_youll_do,
      },
      {
        title: 'What You’ll Bring',
        description: job?.what_youll_bring,
      },
    ].filter((info) => info.description) as JobDetailDataProps['descriptiveInfos'],
    employmentType: convertToEmploymentTypeCopy(
      job.employment_type,
    ) as Employment_Type_Choices_Enum,
    tagInfos: [
      {
        title: 'Company Size',
        description: job.organization.icims_company?.numofemps?.toString(),
      },
    ].filter((info) => info.description) as JobDetailDataProps['tagInfos'],
    companyName: job.organization.name,
    organizationID: job.organization.id,
    industry: jobOrganization?.salesforce_customer?.industry || null,
    salary: jobSalary,
    topApplicantInfo: {
      isTopApplicant: isTopApplicant || false,
      numberOfSkillsMatched: isTopApplicant
        ? techStack.filter((stack) => candidateInfo?.candidateSkills?.indexOf(stack) !== -1).length
        : null,
      yearsOfExperience: isTopApplicant
        ? createYearsOfExperienceCopy({
            candidateYearsOfExperience: candidateInfo?.yearsOfExperience,
            jobsYearsOfExperience: job.min_years_of_experience,
          })
        : null,
    },
    interviewDetails:
      candidateInfo?.furthestCandidateCurationWorkflow ===
      Candidate_Curation_Workflow_Status_Choices_Enum.Accepted
        ? job.interview_details
            ?.map((details) => {
              return {
                title: convertToHumanInterviewType(details.type),
                description: details.description,
                duration: convertFromMinutesToHuman(details.length),
                order: details.order,
              };
            })
            .sort((interviewA, interviewB) => interviewA.order - interviewB.order) || []
        : [],
  };
};
