/* eslint-disable func-names */
import { useSkillsController } from 'candidate/shared/modules';
import type { SkillsProps } from 'candidate/shared/modules';
import { Skills } from './Skills';

export function SkillsController({
  skillGroups,
  userID,
  candidateID,
  skillChoices,
  isModalOpen,
  onModalClose,
  onModalOpen,
}: {
  skillGroups: SkillsProps['skillGroups'];
  candidateID: number;
  userID?: number;
  skillChoices: SkillsProps['skillChoices'];
  isModalOpen: boolean;
  onModalClose: () => void;
  onModalOpen: () => void;
}) {
  const {
    formik,
    handleAddClick,
    handleAddSkillClick,
    handleSelectSkill,
    handleDeleteSkill,
    handleOnClose,
    handleOnSaveClick,
    hasFormValuesChanged,
    isLoading_upsert,
    shouldShowDeleteColumn,
    skillAvailableOption,
  } = useSkillsController({
    skillWithYearsOfExperience: false,
    onModalOpen,
    onModalClose,
    skillChoices,
    skillGroups,
    userID,
    candidateID,
  });

  return (
    <Skills
      skillsSectionProps={{
        skillGroups,
        isAccented: !skillGroups.length,
        onEditClick: handleAddClick,
        onAddClick: handleAddClick,
      }}
      editModalProps={{
        isOpen: isModalOpen,
        onClose: handleOnClose,
        shouldConfirmClose: hasFormValuesChanged(),
        showLoadingForSaveButton: isLoading_upsert,
        onSaveClick: handleOnSaveClick,
        title: 'Add Skills',
      }}
      skillsFormProps={{
        skillsOptions: skillAvailableOption,
        values: formik.values,
        errors: formik.errors,
        touched: formik.touched,
        onChange: formik.handleChange,
        onBlur: formik.handleBlur,
        onSelectSkill: handleSelectSkill,
        onDeleteSkillClick: handleDeleteSkill,
        onAddClick: handleAddSkillClick,
        shouldShowDeleteColumn,
        shouldShowYearsOfExperience: false,
      }}
    />
  );
}
