import {
  Heading,
  Box,
  Text,
  Image,
  Button,
  Flex,
  Tag,
  HStack,
  CheckCircleSolidIcon,
  Badge,
  ChevronIcon,
} from '@terminal/design-system';
import type { JobSuggestionProps } from '../data/JobSuggestions.types';

export function JobCard({
  jobSection = 'other-jobs',
  onJobDetailClick,
  isDesktop,
  jobSuggestionData,
  maxSkillsToDisplay,
  shouldHighlight,
}: {
  jobSection?: 'suggested-jobs' | 'other-jobs' | 'tp-suggestion';
  onJobDetailClick: (job: JobSuggestionProps) => void;
  isDesktop?: boolean;
  jobSuggestionData: JobSuggestionProps;
  maxSkillsToDisplay?: number;
  shouldHighlight?: boolean;
}) {
  // TODO [CAND-1289]: Handle empty values better
  const {
    applied,
    id,
    image = { src: undefined, alt: undefined },
    skills = [],
    subtitle,
    title,
    jobDetail,
    isTopApplicant,
  } = jobSuggestionData;
  // TODO: Handle this based on the width of the container
  const skills_maxLength = maxSkillsToDisplay;

  return (
    <Button
      key={`job-card-${id}`}
      aria-label={`${jobSection}-job-card`}
      variant="unstyled"
      h="auto"
      w="full"
      pos="relative"
      p={3}
      justifyContent="space-between"
      onClick={() => onJobDetailClick(jobSuggestionData)}
      borderRight="1px solid"
      borderLeft="1px solid"
      borderBottom="1px solid"
      borderColor="ui.inverse.secondary"
      backgroundColor={isDesktop && shouldHighlight ? 'ui.inverse.secondary' : 'transparent'}
      _first={{
        borderTop: ['1px solid', '1px solid', 'none'],
        borderTopColor: ['ui.inverse.secondary', 'ui.inverse.secondary', 'transparent'],
      }}
      _selected={{
        boxShadow: 'none',
      }}
      _focus={{
        boxShadow: 'none',
      }}
      _hover={{
        backgroundColor: 'ui.secondary',
        borderColor: 'ui.secondary',
      }}
    >
      <HStack alignItems="flex-start" spacing={[3, 3, 6]} w="full">
        <Flex alignItems="center" justify="center" alignSelf="stretch">
          <Image
            w="3.75rem"
            h="3.75rem"
            src={image.src || undefined}
            alt={image.alt || undefined}
            objectFit="contain"
            objectPosition="center"
          />
        </Flex>
        <Box flex={1} overflow="hidden" w="full">
          <Box textAlign="left">
            <Text variant="hint" isTruncated className="notranslate">
              {title}
            </Text>
            <Heading as="p" variant="heading-4" isTruncated className="notranslate">
              {subtitle}
            </Heading>
            {jobSuggestionData?.salary && (
              <Badge colorScheme="disabled" my={3}>
                <Text variant="caption" color="text.primary">
                  {jobSuggestionData.salary}
                </Text>
              </Badge>
            )}
          </Box>
          <HStack mt={2} spacing={2}>
            {skills.slice(0, skills_maxLength).map((skill) => (
              <Tag
                className="notranslate"
                key={`job-suggestion-skill-${skill}`}
                display="block"
                colorScheme="accent-lightest"
                isTruncated
                title={skill}
                pos="relative"
                flexShrink={1}
                size="sm"
                variant="subtle"
              >
                {skill}
              </Tag>
            ))}
            {skills.length > skills_maxLength! && (
              <Box flexShrink={0}>
                <Text variant="caption">{`+${skills.length - skills_maxLength!} More`}</Text>
              </Box>
            )}
          </HStack>
          {(jobDetail?.topApplicantInfo.isTopApplicant || isTopApplicant) && (
            <Flex alignItems="center" mt={3}>
              <ChevronIcon color="ui.success" mr={2} />
              <Text color="text.primary" variant="caption" textAlign="left">
                You are a Top Applicant
              </Text>
            </Flex>
          )}
        </Box>
        {applied && (
          <CheckCircleSolidIcon
            pos="absolute"
            top="50%"
            transform={{ translateY: '-50%' }}
            right={3}
            color="brand.main"
          />
        )}
      </HStack>
    </Button>
  );
}
