import { Center, Spinner } from '@terminal/design-system';
import { PublicPageLayout } from './PageLayout';

export function PublicPageLoading() {
  return (
    <PublicPageLayout>
      <Center w="100%" minH="90vh" bg="bg.secondary">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="ui.secondary"
          color="brand.main"
          size="xl"
          aria-label="Loading"
          role="status"
        />
      </Center>
    </PublicPageLayout>
  );
}
