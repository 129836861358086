/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react';
import { useAppInitData, useCandidateAuth, appState } from 'candidate/utils';
import { useIsViewPortDesktop, useQuery, useRedirection } from 'global/utils';
import * as events from 'global/events';
import { useDisclosure } from '@terminal/design-system';
import { PrivatePageLoading } from 'candidate/components';
import { useReactiveVar } from '@apollo/client';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import moment from 'moment';
import { JobDrawerDetails } from 'candidate/shared/modules';
import { useHandpickedJobsByTPController } from 'candidate/shared/modules/job/useHandpickedJobsByTPController';
import { Candidate_Job_Workflow_Status_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { HomePage, TalentPartnerJobRecommendations } from './HomePage';
import { SelectHomePageInfo } from './graphql';
import type { SelectHomePageInfoQuery, SelectHomePageInfoQueryVariables } from './HomePage.types';
import { serializerForHomePage } from './HomePage.serializer';
import { JobSuggestionsController } from './sections';

export function HomePageController({
  /**
   * We are fetching the feed from the last 3 months. This prop is mainly used by storybook
   * to workaround a limitation of mock data feedInitialDate being different than that of the controller
   * by a few milliseconds which causes the mock fixture not resolving.
   */
  feedInitialDate,
  is_resume_parsing_failed = false,
}: {
  feedInitialDate?: string;
  is_resume_parsing_failed: boolean;
}) {
  const { user, userFullName } = useCandidateAuth();
  const redirectTo = useRedirection();
  const isDesktop = useIsViewPortDesktop();
  const {
    isOpen: isPersonalizedHeaderModalOpen,
    onOpen: onPersonalizedHeaderModalOpen,
    onClose: onPersonalizedHeaderModalClose,
  } = useDisclosure();

  const appFlow = useReactiveVar(appState.flow.stateVar);
  // We are fetching the feed from the last 3 months
  const [feedInitialDateState] = useState(feedInitialDate || moment().subtract(3, 'month'));
  const [selectedJobIDByTP, setSelectedJobIDByTP] = useState<number | null>(null);
  const { candidateAvailability, isLoading: isLoading_candidateAvailability } = useAppInitData({
    userID: user?.id,
  });
  const { isOpen: isResumeModalOpen, onClose: onResumeModalClose } = useDisclosure({
    defaultIsOpen:
      appFlow['landing-first-time-from-completed-onboarding-flow'] === 'resume-parsing',
  });

  const { isOpen: isResumeFailureModalOpen, onClose: onResumeFailureModalClose } = useDisclosure({
    defaultIsOpen: is_resume_parsing_failed,
  });

  const {
    isOpen: isOnboardingModalOpen,
    onClose: onOnboardingModalClose,
    onOpen: onOnboardingModalOpen,
  } = useDisclosure();

  const {
    isOpen: isJobDetailsOpen,
    onClose: onJobDetailsClose,
    onOpen: onJobDetailsOpen,
  } = useDisclosure();

  const {
    isOpen: isTerminalProcessModalOpen,
    onClose: onTerminalProcessModalClose,
    onOpen: onTerminalProcessModalOpen,
  } = useDisclosure();

  useEffect(() => {
    if (appFlow['landing-first-time-from-completed-onboarding-flow'] === 'manual') {
      onOnboardingModalOpen();
      events.track(events.name.homePage.onboarding.modalViewed);
    }

    if (
      ['claim-account', 'create-account'].includes(appFlow['landing-from-direct-application'] || '')
    ) {
      onOnboardingModalOpen();
      events.track(events.name.homePage.onboarding.modalViewed);
    }
  }, [isDesktop, appFlow, onOnboardingModalOpen]);

  useEffect(() => {
    if (isOnboardingModalOpen || isResumeModalOpen) {
      appState.flow.update('landing-first-time-from-completed-onboarding-flow', null);
      appState.flow.update('landing-from-direct-application', null);
    }
  }, [isOnboardingModalOpen, isResumeModalOpen]);

  const {
    loading: isLoading_selectHomePageInfo,
    data,
    refetch: refetch_selectHomePageInfo,
  } = useQuery<SelectHomePageInfoQuery, SelectHomePageInfoQueryVariables>(SelectHomePageInfo, {
    context: {
      role: 'candidate',
    },
    variables: {
      user_id: user?.id as number,
      feed_initial_date: feedInitialDateState,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { applyToJob, isLoading_applyToJob, declineJob, isLoading_declineJob } =
    useHandpickedJobsByTPController({
      userID: user?.id as number,
      feedInitialDate: feedInitialDateState,
      onComplete: () => {
        onJobDetailsClose();
        setSelectedJobIDByTP(null);
      },
    });

  if (isLoading_selectHomePageInfo || isLoading_candidateAvailability) {
    return (
      <PrivatePageLoading
        onSignOutClick={() => signOut({ auth: firebaseAuth })}
        {...{ userFullName, candidateAvailability }}
      />
    );
  }

  const {
    profileCompletion,
    candidateID,
    onboardingSlides,
    personalizedHeaderProps,
    talentPartnerInfo,
    talentPartnerJobRecommendations,
    appliedJobsSummary,
  } = serializerForHomePage({
    data: data as SelectHomePageInfoQuery,
    isDesktop,
    candidateEmail: user?.email,
  });

  const handleApplyButtonClick = (jobID: number | null) => {
    const workflowID = talentPartnerJobRecommendations?.find(
      (job) => job.jobID === jobID,
    )?.workflowID;

    if (!workflowID)
      throw new Error(`The selected job with ID:${jobID} that was applied did not have workflowID`);

    applyToJob({
      variables: {
        workflowId: workflowID,
        status: Candidate_Job_Workflow_Status_Choices_Enum.CandidateApproved,
      },
    });
  };

  const handleDeclinedButtonClick = (jobID: number | null) => {
    const workflowID = talentPartnerJobRecommendations?.find(
      (job) => job.jobID === jobID,
    )?.workflowID;

    if (!workflowID)
      throw new Error(`The selected job with ID:${jobID} that was applied did not have workflowID`);

    declineJob({
      variables: {
        workflowId: workflowID,
        status: Candidate_Job_Workflow_Status_Choices_Enum.CandidateRejected,
      },
    });
  };

  return (
    <HomePage
      onSignOutClick={() => signOut({ auth: firebaseAuth })}
      appliedJobsSummary={appliedJobsSummary}
      leftSections={
        <>
          {!!talentPartnerJobRecommendations?.length && (
            <>
              <TalentPartnerJobRecommendations
                jobRecommendations={talentPartnerJobRecommendations}
                onJobDetailClick={(id) => {
                  setSelectedJobIDByTP(id);
                  onJobDetailsOpen();
                  events.track(events.name.ctaClicked, {
                    name: 'talent-partner-suggestion',
                    job_id: id,
                  });
                }}
              />
              <JobDrawerDetails
                jobDetails={
                  talentPartnerJobRecommendations.find((job) => job.jobID === selectedJobIDByTP) ||
                  null
                }
                isOpen={isJobDetailsOpen}
                onClose={onJobDetailsClose}
                handleApplyButtonClick={() => handleApplyButtonClick(selectedJobIDByTP)}
                handleDeclinedButtonClick={() => handleDeclinedButtonClick(selectedJobIDByTP)}
                isApplyButtonLoading={isLoading_applyToJob}
                isDeclinedButtonLoading={isLoading_declineJob}
              />
            </>
          )}
          <JobSuggestionsController
            candidateID={candidateID}
            userID={user?.id as number}
            candidateInfo={{
              country: data?.candidate[0]?.country_choice?.name || null,
              talentPartnerEmail: talentPartnerInfo?.email,
              candidateSkills: data?.candidate[0]?.candidate_skills?.map(({ skill }) => skill.name),
              yearsOfExperience: data?.candidate[0]?.candidate_curation_detail?.years_of_exp_range,
              furthestCandidateCurationWorkflow:
                data?.candidate[0]?.furthest_candidate_curation_workflow?.status || null,
            }}
            profileCompletion={profileCompletion}
          />
        </>
      }
      personalizedHeaderProps={{
        ...personalizedHeaderProps,
        ...(personalizedHeaderProps.buttonCTA
          ? {
              onRender: () =>
                events.track(events.name.homePage.personalizedHeader.actionViewed, {
                  cta: `Button: ${personalizedHeaderProps.buttonCTA}`,
                }),
              calendlyModal: {
                isOpen: isPersonalizedHeaderModalOpen,
                onClose: () => {
                  refetch_selectHomePageInfo();
                  onPersonalizedHeaderModalClose();
                },
              },
              onCTAButtonClick: () => {
                onPersonalizedHeaderModalOpen();
              },
            }
          : {}),
      }}
      onboardingSlides={{
        slides: onboardingSlides,
        card: {
          onSlideChange: (toSlideIndex) => {
            events.track(events.name.homePage.onboarding.slidesViewed, {
              slide_number: toSlideIndex + 1,
              slide_title: onboardingSlides[toSlideIndex]?.title,
              display_type: 'side-bar-card',
            });
          },
          onRender: (slideIndex) => {
            events.track(events.name.homePage.onboarding.slidesViewed, {
              slide_number: slideIndex + 1,
              slide_title: onboardingSlides[slideIndex]?.title,
              display_type: 'side-bar-card',
            });
          },
        },
        modal: {
          isOpen: isOnboardingModalOpen,
          onClose: () => {
            events.track(events.name.homePage.onboarding.modalClosed);
            onOnboardingModalClose();
          },
          onSlideChange: (toSlideIndex) => {
            events.track(events.name.homePage.onboarding.slidesViewed, {
              slide_number: toSlideIndex + 1,
              slide_title: onboardingSlides[toSlideIndex]?.title,
              display_type: 'modal',
            });
          },
          onRender: (slideIndex) => {
            events.track(events.name.homePage.onboarding.slidesViewed, {
              slide_number: slideIndex + 1,
              slide_title: onboardingSlides[slideIndex]?.title,
              display_type: 'modal',
            });
          },
          onOpen: () => {
            onOnboardingModalOpen();
          },
        },
      }}
      parseResumeCompletionModal={{
        isOpen: isResumeModalOpen,
        onClose: onResumeModalClose,
        onGoToProfileClick: () => {
          events.track(events.name.ctaClicked, { name: 'go-to-profile' });
          redirectTo('/profile');
        },
      }}
      parseResumeFailureModal={{
        isOpen: isResumeFailureModalOpen,
        onClose: onResumeFailureModalClose,
        onGoToProfileClick: () => {
          events.track(events.name.ctaClicked, { name: 'go-to-profile' });
          redirectTo('/profile');
        },
      }}
      talentPartnerInfo={talentPartnerInfo}
      terminalProcessModal={{
        isOpen: isTerminalProcessModalOpen,
        onClose: onTerminalProcessModalClose,
        onOpen: onTerminalProcessModalOpen,
      }}
      {...{ candidateAvailability, userFullName }}
    />
  );
}
