import type { ReactNode } from 'react';
import { Role_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import {
  BackendIcon,
  FrontendIcon,
  DevOpsIcon,
  FullstackIcon,
  MobileIcon,
  DataScienceIcon,
  LeaderIcon,
  DesignerIcon,
  ManualQAIcon,
  AutomationQAIcon,
  DataEngineerIcon,
  DataAnalystIcon,
  AIMLIcon,
  ScrumMasterIcon,
  ProductManagerIcon,
} from './assets/icons';

export function roleIcons({ role, isColored }: { role: string; isColored: boolean }) {
  const rolesMap: Record<Role_Choices_Enum | string, ReactNode> = {
    [Role_Choices_Enum.Fed]: <FrontendIcon isColored={isColored} />,
    [Role_Choices_Enum.Bed]: <BackendIcon isColored={isColored} />,
    [Role_Choices_Enum.Fsd]: <FullstackIcon isColored={isColored} />,
    [Role_Choices_Enum.Dev]: <DevOpsIcon isColored={isColored} />,
    [Role_Choices_Enum.Mqa]: <ManualQAIcon isColored={isColored} />,
    [Role_Choices_Enum.Aqa]: <AutomationQAIcon isColored={isColored} />,
    [Role_Choices_Enum.Mod]: <MobileIcon isColored={isColored} />,
    [Role_Choices_Enum.Dsc]: <DataScienceIcon isColored={isColored} />,
    [Role_Choices_Enum.Den]: <DataEngineerIcon isColored={isColored} />,
    [Role_Choices_Enum.Dan]: <DataAnalystIcon isColored={isColored} />,
    [Role_Choices_Enum.Enl]: <LeaderIcon isColored={isColored} />,
    [Role_Choices_Enum.Des]: <DesignerIcon isColored={isColored} />,
    [Role_Choices_Enum.Aml]: <AIMLIcon isColored={isColored} />,
    [Role_Choices_Enum.Scm]: <ScrumMasterIcon isColored={isColored} />,
    [Role_Choices_Enum.Prm]: <ProductManagerIcon isColored={isColored} />,
  };

  return rolesMap[role];
}
