import { Flex, Text, FileIcon, Box, EditIcon, Button } from '@terminal/design-system';
import { ProfileSectionButton } from '../../components';
import type { ProfileProps } from '../../Profile.types';

export function ResumeSection({
  isEdit,
  onEditClick,
  resume,
  onDownloadClick,
}: {
  isEdit: boolean;
  resume: ProfileProps['candidate']['resume'];
  onEditClick: () => void;
  onDownloadClick: () => void;
}) {
  return (
    <Box>
      {isEdit ? (
        <Flex pl={6} pr={8} py={10} bgColor="bg.secondary">
          <Flex onClick={onDownloadClick} cursor="pointer">
            <FileIcon fontSize="2xl" mr={3} />
            <Text variant="label" isTruncated maxW="lg">
              {resume.fileName}
            </Text>
          </Flex>
          <Button size="sm" onClick={onEditClick} variant="ghost" ml="auto">
            <EditIcon fontSize="md" />
          </Button>
        </Flex>
      ) : (
        <ProfileSectionButton onAddClick={onEditClick}>Add Resume</ProfileSectionButton>
      )}
    </Box>
  );
}
