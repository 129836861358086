import type { HStepsProps } from 'candidate/components';
import {
  designTokens,
  CheckboxCard,
  Button,
  GridItem,
  Grid,
  CheckboxGroup,
  Center,
} from '@terminal/design-system';
import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { roleIcons } from '../../../../../../shared/modules/roles';
import type { ProfileProps } from '../../../../Profile.types';
import { StepPageLayout } from '../../components';

export function YearsStep({
  isNextDisabled,
  onCheckboxClick,
  onNextClick,
  onSignOutClick,
  progressProps,
  yearsOfExperienceRangeChoices,
  selectedYearsOfExperienceRange,
  userFullName,
}: {
  isNextDisabled: boolean;
  onCheckboxClick: (years_of_exp_range: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum) => void;
  onNextClick: () => void;
  onSignOutClick: () => Promise<void>;
  progressProps: HStepsProps;
  yearsOfExperienceRangeChoices: ProfileProps['yearsOfExperienceRangeChoices'];
  selectedYearsOfExperienceRange: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum;
  userFullName: string;
}) {
  return (
    <StepPageLayout
      title="How Many Years Of Experience Do You Have?"
      description="We'll match you to the right roles based on your years of relevant experience. "
      progressProps={progressProps}
      userFullName={userFullName}
      onSignOutClick={onSignOutClick}
      footer={
        <Button onClick={onNextClick} w={['full', 64]} disabled={isNextDisabled}>
          Next
        </Button>
      }
    >
      <CheckboxGroup>
        <Center>
          <Grid
            templateColumns={`${designTokens.space[40]} ${designTokens.space[40]}`}
            gap={4}
            justifyContent="center"
          >
            {yearsOfExperienceRangeChoices.map(({ name: yearChoice, displayName }) => {
              const isSelected = selectedYearsOfExperienceRange === yearChoice;

              return (
                <GridItem key={`insider-role-${yearChoice}`}>
                  <CheckboxCard
                    role="checkbox"
                    aria-checked={isSelected}
                    aria-labelledby="candidate-role"
                    onChange={() => onCheckboxClick(yearChoice)}
                    isChecked={isSelected}
                    checkboxIcon={roleIcons({ role: yearChoice, isColored: isSelected })}
                  >
                    {displayName}
                  </CheckboxCard>
                </GridItem>
              );
            })}
          </Grid>
        </Center>
      </CheckboxGroup>
    </StepPageLayout>
  );
}
