import { openConsentManager } from '@segment/consent-manager';
import {
  AssessmentsIcon,
  Avatar,
  Box,
  BriefcaseIcon,
  BrowseIcon,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HomeIcon,
  HStack,
  Icon,
  Image,
  Link,
  LogoutIcon,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuIcon,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  UserIcon,
} from '@terminal/design-system';
import { useLocation } from 'react-router-dom';
import { Logo } from 'global/components/Logo';
import ReferIconDark from 'candidate/assets/images/refer-dark.svg?url';
import { useIsViewPortDesktop, useRedirection } from 'global/utils';
import type { IconProps, ComponentWithAs } from '@terminal/design-system';
import * as events from 'global/events';
import { HeaderMenuItem } from './HeaderMenuItem';
import { UserMenuDesktop } from './UserMenu/UserMenuDesktop';
import ReferIcon from './assets/Refer.svg?url';

function MobileNavMenuItem({
  handleLinkClick,
  isActive,
  label,
  leftIcon,
  sectionRoute,
}: {
  handleLinkClick: (path: string) => void;
  isActive: boolean;
  label: string;
  leftIcon: ComponentWithAs<'svg', IconProps> | string;
  sectionRoute: string;
}) {
  return (
    <MenuItem
      icon={
        typeof leftIcon === 'string' ? (
          <Image src={leftIcon} height={4} />
        ) : (
          <Icon as={leftIcon} fontSize="md" />
        )
      }
      onClick={() => handleLinkClick(sectionRoute)}
      isActive={isActive}
    >
      <Text variant="caption" color={isActive ? 'text.inverse' : 'text.primary'}>
        {label}
      </Text>
    </MenuItem>
  );
}

export function PublicPageHeader({
  rightSidePlaceholderDesktop,
  rightSidePlaceholderMobile,
}: {
  /**
   * renders the element on the right side of the header
   */
  rightSidePlaceholderDesktop?: React.ReactNode;
  rightSidePlaceholderMobile?: React.ReactNode;
}) {
  const isDesktop = useIsViewPortDesktop();
  const redirectTo = useRedirection();
  const handleLinkClick = (path: string): void => {
    redirectTo(path);
  };

  if (!isDesktop)
    return (
      <Flex
        bg="bg.primary"
        h={14}
        alignItems="center"
        justifyContent="space-between"
        pr={4}
        as="nav"
        role="navigation"
        borderBottom="1px solid"
        borderBottomColor="ui.secondary"
      >
        <Center>
          <Link
            p={3}
            onClick={() => {
              handleLinkClick('/');
            }}
          >
            <Logo width="100px" height={7} />
          </Link>
        </Center>
        {rightSidePlaceholderMobile}
      </Flex>
    );

  return (
    <Flex
      bg="bg.primary"
      justifyContent="space-between"
      px={12}
      h={20}
      w="100%"
      as="nav"
      role="navigation"
      borderBottom="1px solid"
      borderBottomColor="ui.secondary"
    >
      <Center ml={-3}>
        <Link
          p={3}
          onClick={() => {
            handleLinkClick('/');
          }}
        >
          <Logo width={32} height={9} />
        </Link>
      </Center>
      {rightSidePlaceholderDesktop}
    </Flex>
  );
}

function PageHeaderBase({
  candidateAvailability,
  desktopMenuItems,
  mobileMenuItems,
  onSignOutClick,
  userFullName,
  isOpen,
  onOpen,
  onClose,
  handleLinkClick,
}: {
  onSignOutClick: () => Promise<void>;
  candidateAvailability: string | null;
  desktopMenuItems: React.ReactNode | null;
  mobileMenuItems: React.ReactNode | null;
  userFullName: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleLinkClick: (path: string) => void;
}) {
  const isDesktop = useIsViewPortDesktop();

  if (!isDesktop)
    return (
      <>
        <Flex
          bg="bg.primary"
          h={14}
          alignItems="center"
          justifyContent="space-between"
          as="nav"
          role="navigation"
          px={3}
          py={1}
          borderBottom="1px solid"
          borderBottomColor="ui.secondary"
        >
          <Link
            onClick={() => {
              handleLinkClick('/');
            }}
          >
            <Logo width="100px" />
          </Link>
          <Button
            _hover={{ bg: 'inherit', opacity: 0.8 }}
            variant="ghost"
            onClick={onOpen}
            w={12}
            h={12}
          >
            <MenuIcon color="brand.main" fontSize="2xl" />
          </Button>
        </Flex>
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton fontSize="md" />
              <DrawerHeader mt={14}>
                <Avatar
                  className="notranslate"
                  name={userFullName}
                  color="text.inverse"
                  bg="accent.darkest"
                  mt={3}
                />
                <Flex alignItems="center" mt={4}>
                  <Box flex={5}>
                    <Text variant="label" textTransform="capitalize">
                      {userFullName}
                    </Text>
                    {candidateAvailability && <Text variant="hint">{candidateAvailability}</Text>}
                  </Box>
                </Flex>
              </DrawerHeader>

              <DrawerBody p="0">
                <Box
                  position="relative"
                  h="full"
                  // This gives the <Menu /> component a full height
                  // We do it this way because the component does not accept style props
                  sx={{
                    'div:has(> div[role=menu])': {
                      height: 'full',
                    },
                  }}
                >
                  <Menu isOpen autoSelect={false}>
                    <MenuList minWidth="xs" shadow="none" h="full">
                      <Flex flexDir="column" justifyContent="space-between" h="full">
                        <Box>
                          {mobileMenuItems}
                          <MenuGroup>
                            <MenuItem
                              onClick={() => {
                                window.open('https://help.terminal.io', '_blank');
                              }}
                            >
                              <Text variant="caption">Help</Text>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                window.open('https://terminal.io/terms-of-use', '_blank');
                              }}
                            >
                              <Text variant="caption">Terms of Use</Text>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                openConsentManager();
                              }}
                            >
                              <Text variant="caption">Manage Cookies</Text>
                            </MenuItem>
                          </MenuGroup>
                          <MenuDivider />
                        </Box>
                        <Box>
                          <MenuGroup>
                            <MenuItem icon={<LogoutIcon fontSize="md" />} onClick={onSignOutClick}>
                              <Text variant="caption">Logout</Text>
                            </MenuItem>
                          </MenuGroup>
                        </Box>
                      </Flex>
                    </MenuList>
                  </Menu>
                </Box>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </>
    );

  return (
    <Flex
      bg="bg.primary"
      justifyContent="space-between"
      px={12}
      h={20}
      w="100%"
      as="nav"
      role="navigation"
      borderBottom="1px solid"
      borderBottomColor="ui.secondary"
    >
      <Center ml={-3}>
        <Link
          p={3}
          onClick={() => {
            handleLinkClick('/');
          }}
        >
          <Logo width={32} />
        </Link>
      </Center>
      <Flex flexDir="row" justifyContent="flex-end" alignItems="center">
        <HStack spacing={6}>
          {desktopMenuItems}
          <Box w="min" h={10}>
            <Divider orientation="vertical" />
          </Box>

          <UserMenuDesktop
            fullName={userFullName}
            candidateAvailability={candidateAvailability}
            onHelpClick={() => {
              window.open('https://help.terminal.io', '_blank');
            }}
            onTermsClick={() => {
              window.open('https://terminal.io/terms-of-use', '_blank');
            }}
            onManageCookiesClick={() => {
              openConsentManager();
            }}
            signOut={onSignOutClick}
          />
        </HStack>
      </Flex>
    </Flex>
  );
}

function VariationWithAllSections({
  candidateAvailability,
  onSignOutClick,
  userFullName,
}: {
  onSignOutClick: () => Promise<void>;
  candidateAvailability: string | null;
  userFullName: string;
}) {
  const redirectTo = useRedirection();
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const splitLocation = pathname.split('/');
  const rootRoute = (splitLocation.length > 1 ? splitLocation[1] : '') || '';

  const handleLinkClick = (path: string): void => {
    if (path === '/referral') {
      events.track(events.name.ctaClicked, {
        name: 'referral-mobile-header',
      });
    }
    redirectTo(path);
    onClose();
  };

  return (
    <PageHeaderBase
      {...{
        candidateAvailability,
        onSignOutClick,
        userFullName,
        isOpen,
        onOpen,
        onClose,
        handleLinkClick,
      }}
      mobileMenuItems={
        <>
          <MenuGroup>
            <MobileNavMenuItem
              handleLinkClick={handleLinkClick}
              label="Home"
              leftIcon={HomeIcon}
              sectionRoute="/"
              isActive={rootRoute === ''}
            />
            <MobileNavMenuItem
              handleLinkClick={handleLinkClick}
              label="Browse Jobs"
              leftIcon={BrowseIcon}
              sectionRoute="/job/matches"
              isActive={rootRoute === 'job'}
            />
            <MobileNavMenuItem
              handleLinkClick={handleLinkClick}
              label="My Jobs"
              leftIcon={BriefcaseIcon}
              sectionRoute="/my-jobs"
              isActive={rootRoute === 'my-jobs'}
            />
            <MobileNavMenuItem
              handleLinkClick={handleLinkClick}
              label="Profile"
              leftIcon={UserIcon}
              sectionRoute="/profile"
              isActive={rootRoute === 'profile'}
            />
            <MobileNavMenuItem
              handleLinkClick={handleLinkClick}
              label="Assessments"
              leftIcon={AssessmentsIcon}
              sectionRoute="/assessments"
              isActive={rootRoute === 'assessments'}
            />
            <MobileNavMenuItem
              handleLinkClick={handleLinkClick}
              label="Refer & Earn"
              leftIcon={rootRoute === 'referral' ? ReferIcon : ReferIconDark}
              sectionRoute="/referral"
              isActive={rootRoute === 'referral'}
            />
          </MenuGroup>
          <MenuDivider my={3} />
        </>
      }
      desktopMenuItems={
        <>
          <HeaderMenuItem
            onClick={() => {
              handleLinkClick('/');
            }}
            title="Home"
            isActive={rootRoute === ''}
          />
          <HeaderMenuItem
            onClick={() => {
              handleLinkClick('/job/matches');
            }}
            title="Browse Jobs"
            isActive={rootRoute === 'job'}
          />
          <HeaderMenuItem
            onClick={() => {
              handleLinkClick('/my-jobs');
            }}
            title="My Jobs"
            isActive={rootRoute === 'my-jobs'}
          />
          <HeaderMenuItem
            onClick={() => {
              handleLinkClick('/assessments');
            }}
            title="Assessments"
            isActive={rootRoute === 'assessments'}
          />
          <HeaderMenuItem
            onClick={() => {
              handleLinkClick('/profile');
            }}
            title="Profile"
            isActive={rootRoute === 'profile'}
          />
          <HeaderMenuItem
            onClick={() => {
              events.track(events.name.ctaClicked, { name: 'referral-header' });

              handleLinkClick('/referral');
            }}
            title="Refer & Earn"
            isActive={rootRoute === 'referral'}
            rightIcon={ReferIcon}
          />
        </>
      }
    />
  );
}

function VariationWithReferralOnly({
  candidateAvailability,
  onSignOutClick,
  userFullName,
}: {
  onSignOutClick: () => Promise<void>;
  candidateAvailability: string | null;
  userFullName: string;
}) {
  const redirectTo = useRedirection();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLinkClick = (path: string): void => {
    events.track(events.name.ctaClicked, {
      name: 'referral-mobile-header',
    });
    redirectTo(path);
    onClose();
  };

  return (
    <PageHeaderBase
      {...{
        candidateAvailability,
        onSignOutClick,
        userFullName,
        isOpen,
        onOpen,
        onClose,
        handleLinkClick,
      }}
      mobileMenuItems={
        <>
          <MenuGroup>
            <MobileNavMenuItem
              handleLinkClick={handleLinkClick}
              label="Refer & Earn"
              leftIcon={ReferIcon}
              sectionRoute="/referral"
              isActive={false}
            />
          </MenuGroup>
          <MenuDivider my={3} />
        </>
      }
      desktopMenuItems={
        <HeaderMenuItem
          onClick={() => {
            events.track(events.name.ctaClicked, { name: 'referral-header' });

            handleLinkClick('/referral');
          }}
          title="Refer & Earn"
          isActive={false}
          rightIcon={ReferIcon}
        />
      }
    />
  );
}

function VariationWithNoSections({
  candidateAvailability,
  onSignOutClick,
  userFullName,
}: {
  onSignOutClick: () => Promise<void>;
  candidateAvailability: string | null;
  userFullName: string;
}) {
  const redirectTo = useRedirection();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleLinkClick = (path: string): void => {
    redirectTo(path);
    onClose();
  };

  return (
    <PageHeaderBase
      {...{
        candidateAvailability,
        onSignOutClick,
        userFullName,
        isOpen,
        onOpen,
        onClose,
        handleLinkClick,
      }}
      mobileMenuItems={null}
      desktopMenuItems={null}
    />
  );
}

export const PageHeader = {
  Variation: {
    WithAllSections: VariationWithAllSections,
    WithNoSections: VariationWithNoSections,
    WithReferralOnly: VariationWithReferralOnly,
  },
};
