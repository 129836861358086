import { firebaseAuth } from 'global/firebaseApp';
import { Heading, Text, Box } from '@terminal/design-system';
import { useCandidateAuth } from 'candidate/utils';
import { useSignIn, googleSignIn } from 'global/auth';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { CustomRoute, SwitchWithPageNotFound } from 'candidate/components';
import { useRedirection } from 'global/utils';
import { JobApplicationLogIn } from './JobApplicationLogIn';

export function AccountExistsLogInFlowController({
  companyName,
  currentEmail,
  jobID,
  position,
  firstName,
}: {
  companyName: string;
  currentEmail?: string;
  jobID: number | null;
  position: string;
  firstName?: string;
}) {
  const auth = useCandidateAuth();
  const redirectTo = useRedirection();
  const { path } = useRouteMatch();
  const { handleSignInSubmit, isLoading, shouldHideGoogleSSO } = useSignIn();
  const location = useLocation();

  return (
    <SwitchWithPageNotFound>
      <CustomRoute
        path={path}
        onBeforeRedirect={() => {
          localStorage.setItem('from-direct-application-job-id', `${jobID}`);
          localStorage.setItem('from-direct-application-job-title', position);
          localStorage.setItem('from-direct-application-job-company', companyName);
        }}
        conditionalRedirects={[
          {
            condition: auth.isAuthorized && !!jobID,
            redirectURL: `/job/matches/detail/${jobID}`,
            search: location.search
              ? `${location.search}&from=direct-application`
              : `?from=direct-application`,
          },
        ]}
      >
        <JobApplicationLogIn
          onEmailSignSubmitted={handleSignInSubmit}
          onGoogleLoginClicked={() =>
            googleSignIn(
              { dispatch: auth.dispatch, auth: firebaseAuth },
              { forceWithoutLinking: true },
            )
          }
          email={currentEmail}
          isLoading={isLoading}
          onForgotPasswordClick={() => {
            redirectTo('/auth/forgot-password');
          }}
          shouldHideGoogleSSO={shouldHideGoogleSSO}
          header={
            <Box textAlign={['center', 'center', 'left']}>
              <Heading variant="heading-2">
                <Heading display="inline" variant="heading-2" color="brand.main">
                  {firstName && `${firstName}, `}
                </Heading>
                Welcome Back!
              </Heading>
            </Box>
          }
          footer={
            !!currentEmail && (
              <Box textAlign="center" mt={4}>
                <Text variant="hint" color="text.disabled">
                  {`Please make sure to use the same email: ${currentEmail}`}
                </Text>
              </Box>
            )
          }
        />
      </CustomRoute>
    </SwitchWithPageNotFound>
  );
}
