import { useRef } from 'react';
import * as Yup from 'yup';
import {
  ParseError,
  parsePhoneNumberWithError,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import { useFormik } from 'formik';
import { useToast, Box } from '@terminal/design-system';
import usePlacesAutocomplete, { getLatLng, getGeocode } from 'use-places-autocomplete';
import * as events from 'global/events';
import Sentry from 'global/sentry';
import { useMutation } from '@apollo/client';
import { useQuery, validators } from 'global/utils';
import type { SelectCountryListQuery, SelectCountryListQueryVariables } from 'candidate/utils/data';
import { SelectCountryList } from 'candidate/utils/data';
import type {
  ProfileProps,
  UpdateCandidatePersonalInfoMutation,
  UpdateCandidatePersonalInfoMutationVariables,
} from '../../Profile.types';
import { UpdateCandidatePersonalInfo } from '../../graphql';
import { PersonalInfoForm } from './PersonalInfoForm';
import { objectsEmptyValuesToNull, trackProfile } from '../../Profile.utils';

/* eslint-disable func-names */
Yup.addMethod(Yup.string, 'phone', function (message) {
  // @ts-ignore
  return this.test('valid-format', message, function (value) {
    if (!value || value.length === 0) return true;

    // @ts-ignore
    const { path, createError } = this;
    try {
      // First try to parse the phone number, if it fails, it will throw an error
      parsePhoneNumberWithError(value);

      // Then validate the phone number length to catch those error the parsing did not catch
      const phoneNumberValidationError = validatePhoneNumberLength(value);

      if (phoneNumberValidationError) {
        const error = new ParseError();

        error.message = phoneNumberValidationError;

        // We're disabling the next line because we need to throw a ParseError here
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw error;
      }

      return true;
    } catch (error) {
      if (error instanceof ParseError) {
        // Not a phone number, non-existent country, etc.

        switch (error.message) {
          case 'TOO_LONG':
            return createError({ path, message: 'Phone number is too long.' });
          case 'INVALID_COUNTRY':
            return createError({ path, message: 'Invalid country code.' });
          case 'TOO_SHORT':
            return createError({ path, message: 'Phone number is too short.' });
          default:
            return createError({ path, message });
        }
      }
      throw error;
    }
  });
});

export const PersonalInfoValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(validators.firstName.regex, validators.firstName.message)
    .required('First name is a required field.'),
  lastName: Yup.string()
    .matches(validators.lastName.regex, validators.lastName.message)
    .required('Last name is a required field.'),
  availability: Yup.string().required('Availability is a required field.').nullable(),
  email: Yup.string().required(),
  city: Yup.string().notRequired().nullable(),
  country: Yup.string().notRequired().nullable(),
  country_id: Yup.number().notRequired().nullable(),
  state: Yup.string().notRequired().nullable(),
  latitude: Yup.string().notRequired().nullable(),
  longitude: Yup.string().notRequired().nullable(),
  // @ts-ignore
  phone: Yup.string().notRequired().phone('Invalid phone number.'),
});

export function usePersonalInfoController({
  isEdit,
  personalInfo,
  candidateID,
}: {
  isEdit: boolean;
  personalInfo: ProfileProps['candidate']['personalInfo'];
  candidateID: number;
}) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const [updatePersonalInfo, { loading: isLoading }] = useMutation<
    UpdateCandidatePersonalInfoMutation,
    UpdateCandidatePersonalInfoMutationVariables
  >(UpdateCandidatePersonalInfo, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to update your personal info. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
    onCompleted: ({ update_candidate_by_pk }) => {
      toast({
        title: 'Saved Successfully!',
        description: 'Your changes were saved successfully.',
        status: 'success',
      });

      if (isEdit) {
        trackProfile('entry-edited', { section: 'Personal Info' });
      } else {
        trackProfile('entry-added', { section: 'Personal Info' });
      }
      if (!update_candidate_by_pk?.first_name && !update_candidate_by_pk?.last_name) {
        trackProfile('entry-deleted', { section: 'Personal Info' });
      }

      events.setUserProperties(
        `${update_candidate_by_pk?.first_name} ${update_candidate_by_pk?.last_name}`,
        update_candidate_by_pk?.email,
      );
    },
  });

  const handleUpdatePersonalInfo = (
    values: ProfileProps['candidate']['personalInfo']['formValues'],
  ): void => {
    const { firstName, lastName, formattedAddress, ...mutationValues } = values;
    updatePersonalInfo({
      variables: {
        candidate_id: candidateID,
        candidate_set_input: {
          ...objectsEmptyValuesToNull(mutationValues),
          first_name: firstName.length ? firstName : null,
          last_name: firstName.length ? lastName : null,
        },
      },
    });
  };

  const formik = useFormik<ProfileProps['candidate']['personalInfo']['formValues']>({
    initialValues: personalInfo.formValues,
    validationSchema: PersonalInfoValidationSchema,
    onSubmit: (values) => handleUpdatePersonalInfo(values),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const hasFormValuesChanged = () =>
    JSON.stringify(formik.initialValues) !== JSON.stringify(formik.values);

  return { formik, updatePersonalInfo, hasFormValuesChanged, isLoading };
}

export function PersonalInfoController({
  candidateID,
  personalInfo,
}: {
  candidateID: number;
  personalInfo: ProfileProps['candidate']['personalInfo'];
}) {
  const initialRef = useRef(null);

  const normalizedCountries = useQuery<SelectCountryListQuery, SelectCountryListQueryVariables>(
    SelectCountryList,
  );
  const placeIDToCountryIDMap: Record<string, number> =
    normalizedCountries.data?.country_choices?.reduce(
      (map: Record<string, number>, obj: { place_id: string | null; id: number }) => {
        if (!obj.place_id) return map;
        return {
          ...map,
          [obj.place_id]: obj.id,
        };
      },
      {},
    ) || {};

  const {
    ready: isGooglePlaceAPIReady,
    value: locationInputValue,
    suggestions: locationSuggestions,
    setValue: setLocationInputValue,
  } = usePlacesAutocomplete({
    defaultValue: personalInfo.formValues.formattedAddress,
    debounce: 400,
    requestOptions: {
      types: ['(regions)'],
      language: 'en-US',
    },
  });

  // temporarily added options.length to not show a empty line (UL border) when user starts typing
  // as usePlacesAutocomplete has a limitation where it only gives us loading state after some result
  // have already been shown
  const isLoadingSuggestions =
    locationSuggestions.loading ||
    (personalInfo.formValues.formattedAddress !== locationInputValue &&
      locationInputValue.length > 0 &&
      locationSuggestions.status !== 'ZERO_RESULTS' &&
      locationSuggestions.data.length === 0);

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const isEdit = !!personalInfo.fullName;

  const { formik, hasFormValuesChanged, isLoading } = usePersonalInfoController({
    isEdit,
    personalInfo,
    candidateID,
  });

  const handleLocationSuggestionSelect = async (selectAddress: string) => {
    try {
      const results = await getGeocode({ address: selectAddress, language: 'en-US' });

      const selectedResult: (typeof results)[number] =
        results.find(
          (result) =>
            result.types.includes('country') ||
            result.types.includes('locality') ||
            result.types.includes('administrative_area_level_1'),
        ) || results[0];

      if (!results.length) return;

      const { city, country, state } = selectedResult.address_components.reduce(
        (acc: any, next: any) => {
          if (next.types.includes('locality')) {
            return {
              ...acc,
              city: next.long_name,
            };
          }

          if (next.types.includes('country')) {
            return {
              ...acc,
              country: next.long_name,
            };
          }

          if (next.types.includes('administrative_area_level_1')) {
            return {
              ...acc,
              state: next.long_name,
            };
          }

          return acc;
        },
        {
          city: '',
          state: '',
          country: '',
          formattedAddress: selectedResult.formatted_address,
        },
      );

      const countryResults: (typeof results)[number] =
        results.find((result: (typeof results)[number]) => result.types.includes('country')) ||
        (await getGeocode({ address: country, language: 'en-US' }).then(
          (res) => res.find((result) => result.types.includes('country')) || res[0],
        ));

      const { lat, lng } = await getLatLng(selectedResult);
      formik.setFieldValue('latitude', lat);
      formik.setFieldValue('longitude', lng);
      formik.setFieldValue('city', city);
      formik.setFieldValue('state', state);
      formik.setFieldValue('country', country, true);
      formik.setFieldValue('country_id', placeIDToCountryIDMap[countryResults.place_id] || null);
    } catch (error: unknown) {
      toast({
        description: 'Something went wrong trying to get location suggestions!',
        status: 'error',
      });

      Sentry.captureException(error);
    }
  };

  const handleOnSaveClick = () => {
    if (hasFormValuesChanged()) {
      formik.handleSubmit();
    }
  };

  return (
    <Box w="full">
      <PersonalInfoForm
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        initialRef={initialRef}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        onLocationSuggestionSelect={handleLocationSuggestionSelect}
        showLocationInput={isGooglePlaceAPIReady}
        locationInputValue={locationInputValue}
        onLocationInputValueChange={setLocationInputValue}
        locationSuggestions={locationSuggestions.data.map((suggestion) => suggestion.description)}
        showLocationSuggestionLoading={isLoadingSuggestions}
        showLocationSuggestionNoResult={locationSuggestions.status === 'ZERO_RESULTS'}
        displaySaveBtn={hasFormValuesChanged()}
        showLoadingForSaveButton={isLoading}
        onSaveClick={handleOnSaveClick}
      />
    </Box>
  );
}
