import { PublicPageLayout } from 'candidate/components';
import { Flex, Heading, Text, Box, Image, VStack, Accordion, Link } from '@terminal/design-system';
import React from 'react';
import { useIsViewPortDesktop } from 'global/utils';
import { Link as RouterLink } from 'react-router-dom';
import ReviewIcon from '../assets/review.svg?url';
import MatchIcon from '../assets/match.svg?url';
import ProfileUpdatedIcon from '../assets/profile_updated.svg?url';

export function Success({
  companyName,
  position,
  applicantFirstName,
  header,
  secondaryCTA,
}: {
  applicantFirstName?: string | null;
  companyName: string;
  position: string;
  header: React.ReactNode;
  secondaryCTA?: React.ReactNode;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <PublicPageLayout
      headerRightPlaceholderDesktop={
        <Flex alignItems="center">
          <Text>Don’t Have An Account?</Text>
          <Link as={RouterLink} to="/onboarding" ml={2} fontWeight="bold">
            Sign Up
          </Link>
        </Flex>
      }
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link
            as={RouterLink}
            to={`/auth/login${window.location.search}`}
            ml={2}
            fontWeight="bold"
          >
            Login
          </Link>
        </Flex>
      }
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        flex="1"
        justifyItems="center"
        pt={[4, 4, 12]}
        pb="12"
      >
        <Heading variant={isDesktop ? 'heading-1' : 'heading-2'}>
          {applicantFirstName ? `${applicantFirstName}, ` : ''}Thanks For Applying 🎉
        </Heading>
        <Heading
          textAlign={['center', 'center', 'initial']}
          variant={isDesktop ? 'heading-3' : 'heading-4'}
          color="text.secondary"
        >
          You’ve succesfully applied for{' '}
          <Heading
            variant={isDesktop ? 'heading-3' : 'heading-4'}
            display="inline-block"
            color="accent.main"
          >
            {position}
          </Heading>{' '}
          at{' '}
          <Heading
            variant={isDesktop ? 'heading-3' : 'heading-4'}
            display="inline-block"
            color="accent.main"
          >
            {companyName}
          </Heading>
        </Heading>

        <Flex flexDirection="row" marginTop={[8, 8, 14]}>
          <Box
            minHeight="2"
            height="2"
            marginRight="2"
            minWidth="2"
            width="2"
            backgroundColor="ui.darker.info"
          />
          <Box
            minHeight="2"
            height="2"
            marginRight="2"
            minWidth="2"
            width="2"
            backgroundColor="ui.warning"
          />
          <Box
            minHeight="2"
            height="2"
            marginRight="2"
            minWidth="2"
            width="2"
            backgroundColor="ui.success"
          />
        </Flex>

        {header}

        <Box width={isDesktop ? 'ml' : 'auto'} px={[3, 3, 8]}>
          <Heading
            marginTop="12"
            variant={isDesktop ? 'heading-2' : 'heading-3'}
            textAlign="center"
          >
            What To Expect With Terminal
          </Heading>
          <Flex direction={['column', 'column', 'row']} alignItems="center" mt="8" gap={[4, 4, 6]}>
            <Image width="16" src={ReviewIcon} />

            <VStack alignItems="flex-start" spacing="1">
              <Heading
                variant="heading-3"
                textAlign={isDesktop ? 'left' : 'center'}
                w={isDesktop ? 'auto' : 'full'}
              >
                We Review Your Application
              </Heading>
              <Text variant="body" color="text.secondary" textAlign={isDesktop ? 'left' : 'center'}>
                Our Talent team will review your application. If you&apos;re a fit we&apos;ll reach
                out within 3 days to start the interview process.
              </Text>
            </VStack>
          </Flex>
          <Flex
            direction={isDesktop ? 'row' : 'column'}
            alignItems="center"
            mt="10"
            gap={[4, 4, 6]}
          >
            <Image width="16" src={MatchIcon} />

            <VStack alignItems="flex-start" spacing="1">
              <Heading
                variant="heading-3"
                textAlign={isDesktop ? 'left' : 'center'}
                w={isDesktop ? 'auto' : 'full'}
              >
                We Match You To Even More Jobs
              </Heading>
              <Text variant="body" color="text.secondary" textAlign={isDesktop ? 'left' : 'center'}>
                We want you to land your DREAM job. We’ll continue looking for roles of interest for
                you, there is no limit to the number of companies you can interview with!
              </Text>
            </VStack>
          </Flex>
          <Flex
            direction={isDesktop ? 'row' : 'column'}
            alignItems="center"
            mt="10"
            gap={[4, 4, 6]}
          >
            <Image width="16" src={ProfileUpdatedIcon} />

            <VStack alignItems="flex-start" spacing="1">
              <Heading
                variant="heading-3"
                textAlign={isDesktop ? 'left' : 'center'}
                w={isDesktop ? 'auto' : 'full'}
              >
                Keep Your Profile Updated
              </Heading>
              <Text variant="body" color="text.secondary" textAlign={isDesktop ? 'left' : 'center'}>
                Your profile is key to unlocking access to great jobs. Keep it as up to date to get
                matched to the right opportunities.
              </Text>
            </VStack>
          </Flex>
          {secondaryCTA && (
            <Flex direction="row" marginTop="14" justifyContent="center" width="full">
              {secondaryCTA}
            </Flex>
          )}
        </Box>
      </Flex>

      <Flex
        backgroundColor="bg.secondary"
        flexDirection="column"
        alignItems="center"
        flex="1"
        justifyItems="center"
        pt={[4, 4, 12]}
        px={[3, 3, 8]}
        pb="12"
      >
        <Box width={isDesktop ? 'ml' : 'auto'}>
          <Heading variant="heading-2" textAlign="center">
            FAQ
          </Heading>

          <Accordion
            accordions={[
              {
                title: 'Is Terminal a recruiting agency?',
                description:
                  'No. Terminal supports its partners in recruiting engineers, but we also remain your legal employer in your home country and provide support for as long as you’re employed. This allows US companies to hire developers outside of the US, while also offering developers the flexibility of 100% remote work in either full-time or contract roles.',
              },
              {
                title: 'Will I be working as a contractor?',
                description:
                  'You decide whether you are open to taking contract roles, full-time roles, or both. Contract roles are fully supported by Terminal and frequently offer contract-to-hire paths. Full-time roles receive the benefits that come along with that status in your home country, such as country-specific holidays and severance requirements. These laws and benefits vary from country to country.',
              },
              {
                title: 'Who is my full-time employer?',
                description:
                  "For full-time roles, you will be employed full-time by Terminal. For contract roles, you will be contracted by Terminal. Your work will be fully remote in your country of residence, and 100% dedicated to the partner company who offered you the role. While Terminal handles all of your contracts and benefits, you will be embedded in the partner's engineering team. We often hear that our hires feel like they are truly a part of the team, no different from other employees at their company.",
              },
            ]}
            mt={8}
          />
        </Box>
      </Flex>
    </PublicPageLayout>
  );
}
