import type { CompanyPage } from 'candidate/shared/modules/company';
import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { Employment_Type_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import type {
  SelectCandidateQuery,
  SelectCurrencyExchangeRatesQuery,
} from 'candidate/shared/modules';
import type { SelectCompanyInfoQuery } from './data';
import {
  createSalaryRangeCTA,
  hasCandidateEnoughExperience,
  findSalaryRangeByLocation,
  findYearsOfExperienceByID,
  normalizedTechStackWithSkills,
  createContractorRateCTA,
} from '../job/utils';
import type { JobYearsOfExperienceIDEnum } from '../job/Job.types';

function AddHttpPrefix(link: string | null | undefined) {
  if (!link) return link;
  return link.startsWith('http') ? link : `//${link}`;
}

export function serializeForCompany({
  data,
  applicantJobs = [],
  candidateJobs = [],
  candidateInfo,
  conversionTable,
}: {
  data: SelectCompanyInfoQuery;
  applicantJobs?: SelectCandidateQuery['candidate'][number]['applicant_jobs'];
  candidateJobs?: SelectCandidateQuery['candidate'][number]['candidate_jobs'];
  conversionTable: SelectCurrencyExchangeRatesQuery | undefined;
  candidateInfo?: {
    candidateCountry:
      | Exclude<SelectCandidateQuery['candidate'][number]['country_choice'], null>['name']
      | null;
    candidateSkills: SelectCandidateQuery['candidate'][number]['candidate_skills'][number]['skill']['name'];
    yearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null | undefined;
  } | null;
}): React.ComponentProps<typeof CompanyPage>['companyInfo'] {
  if (!data.organization_by_pk) {
    throw new Error('Company not found');
  }

  const { organization_by_pk: organizationInfo } = data;

  const companyTestimonials = organizationInfo.organization_videos
    .map(({ url, id }) => {
      // Video URL should include `embed` keyword so video render correctly in `iframe` tag
      const youtubeID: RegExpMatchArray | null = url.match(
        /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/,
      );

      return youtubeID && youtubeID[1]
        ? { id, url: `https://www.youtube.com/embed/${youtubeID[1]}?modestbranding=1` }
        : null;
    })
    .filter(Boolean);

  return {
    name: organizationInfo?.name,
    about: organizationInfo?.about as string,
    cultureAndValues: organizationInfo?.culture_values as string,
    linkedinURL: AddHttpPrefix(organizationInfo?.linkedin_url) as string,
    websiteURL: AddHttpPrefix(organizationInfo?.website_url) as string,
    headquarters: [
      organizationInfo?.hq_city,
      organizationInfo?.hq_state,
      organizationInfo?.hq_country,
    ]
      .filter(Boolean)
      .join(', '),
    industry: organizationInfo?.salesforce_customer?.industry as string,
    leadership: (organizationInfo?.organization_leaderships || []).map(
      ({ organization_employee: { name, title, linkedin_url } }) => ({
        name,
        title,
        linkedinURL: AddHttpPrefix(linkedin_url),
      }),
    ),
    jobs: (organizationInfo?.icims_company?.icims_jobs || [])
      .map(
        ({
          profile_id,
          jobtitle,
          tech_stack,
          job,
          skillByRequiredSkill1,
          skillByRequiredSkill2,
          skillByRequiredSkill3,
          years_experience,
        }) => ({
          id: profile_id,
          title: jobtitle,
          skills:
            typeof tech_stack === 'string'
              ? normalizedTechStackWithSkills({
                  requiredSkills: {
                    skill1: skillByRequiredSkill1,
                    skill2: skillByRequiredSkill2,
                    skill3: skillByRequiredSkill3,
                  },
                  techStack: tech_stack,
                })
              : [],
          salary:
            candidateInfo?.candidateCountry &&
            job?.employment_type !== Employment_Type_Choices_Enum.Contract
              ? createSalaryRangeCTA({
                  salaryRange: findSalaryRangeByLocation({
                    candidateCountry: candidateInfo.candidateCountry,
                    jobSalaries: job?.job_meta_infos[0],
                  })!,
                  candidateCountry: candidateInfo.candidateCountry,
                  conversionTable: conversionTable?.exchange_rates,
                })
              : createContractorRateCTA({
                  salaryRange: {
                    minSalary: job?.min_contract_rate,
                    maxSalary: job?.max_contract_rate,
                    currencySymbol: '\u0024', // '$',
                    isSuffix: false,
                  },
                }),
          applied: [...applicantJobs, ...candidateJobs].some(
            ({ icims_profile_job }) => icims_profile_job.id === profile_id,
          ),
          // Check if candidate matches ALL 3 job required skills
          isTopApplicant:
            !![
              skillByRequiredSkill1?.name,
              skillByRequiredSkill2?.name,
              skillByRequiredSkill3?.name,
            ].filter(Boolean).length &&
            [skillByRequiredSkill1?.name, skillByRequiredSkill2?.name, skillByRequiredSkill3?.name]
              .filter(Boolean)
              .every((requiredSkill) =>
                candidateInfo?.candidateSkills?.filter(Boolean).includes(requiredSkill),
              ) &&
            // Check if candidate has enough years of experience
            hasCandidateEnoughExperience({
              candidateYearsOfExperience: candidateInfo?.yearsOfExperience,
              jobsYearsOfExperience: years_experience?.map(
                ({ id }: { id: JobYearsOfExperienceIDEnum }) => findYearsOfExperienceByID({ id }),
              ),
            }),
        }),
      )
      // Sort top applicants to the top list
      .sort((a, b) => Number(b.isTopApplicant) - Number(a.isTopApplicant)),
    logoURL: organizationInfo?.logo as string,
    squareLogoURL: (organizationInfo?.logo_sq || organizationInfo?.logo) as string,
    // @ts-ignore ignoring as the .filter(Boolean) is not detected by type guard
    videoTestimonials: companyTestimonials,
  };
}
