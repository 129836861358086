import { Icon } from '@terminal/design-system';

export function CanadaFlagIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
        <path d="m5 1h5.5l6 2 6 1.5 4-0.5 0.5 24-6 1-4.5-2.5-6-2-5.5 0.5v-24z" fill="#fff" />
        <path d="M32 4H26V28L32 26.5V4Z" fill="#F6623A" />
        <path d="M6 2L0.5 3.5V26L6 24.5V2Z" fill="#F6623A" />
        <path
          d="m0.76858 2.2021c0.41422 0 0.75 0.33579 0.75 0.75v29.282c0 0.4143-0.33579 0.75-0.75 0.75-0.41421 0-0.75-0.3357-0.75-0.75v-29.282c0-0.41421 0.33579-0.75 0.75-0.75z"
          clipRule="evenodd"
          fill="#25383F"
          fillRule="evenodd"
        />
        <path
          d="m0.76858 26.433 4.378-1.2624c3.6688-1.059 7.6464-0.5848 10.916 1.3014 3.1899 1.84 7.0576 2.3387 10.659 1.3743l4.1461-1.1101c0.6703-0.18 1.1324-0.7544 1.1327-1.4081v-20.571c-5e-4 -0.45926-0.2305-0.89176-0.6216-1.1684-0.391-0.27662-0.8977-0.36536-1.3689-0.23974l-3.2883 0.88105c-3.6013 0.96441-7.469 0.46573-10.659-1.3743-3.2694-1.8862-7.247-2.3604-10.916-1.3014l-4.378 1.2611"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m13.276 10.137 0.596 2.9687-1.3233-1.3905c-0.1733-0.182-0.4917-0.1132-0.5632 0.1217l-0.2157 0.7086-1.7449-0.3447c-0.24189-0.0477-0.45167 0.1624-0.38595 0.3865l0.47175 1.6092 0.0684 0.2418-0.63242 0.2774c-0.22064 0.0973-0.25703 0.3782-0.06768 0.5225l3.092 2.3563c0.0251 0.0184 0.0365 0.0495 0.0307 0.0688l-0.2686 0.8268c-0.0695 0.214 0.1171 0.4248 0.3533 0.3993 0.4199-0.0454 0.7526-0.0894 1.4501-0.1876l0.099-0.014c0.6467-0.091 1.0169-0.1395 1.4137-0.181l-0.1368 2.9563c-0.0082 0.1766 0.1418 0.324 0.3297 0.324h0.5177c0.1855 0 0.3346-0.1438 0.3299-0.3183l-0.0792-2.9607c0.392 0.0413 0.7612 0.0897 1.4009 0.1797l0.099 0.014c0.6975 0.0982 1.0302 0.1422 1.45 0.1876 0.2363 0.0255 0.4229-0.1853 0.3534-0.3993l-0.2706-0.8332c-0.0038-0.0129 0.0076-0.044 0.027-0.0582l3.0977-2.3605c0.1893-0.1443 0.1529-0.4252-0.0677-0.5225l-0.632-0.2787 0.0688-0.2435 0.4709-1.6062c0.0657-0.2241-0.144-0.4342-0.3859-0.3865l-1.7452 0.3443-0.2154-0.7082c-0.0715-0.2349-0.39-0.3037-0.5632-0.1217l-1.3233 1.3898 0.596-2.968c0.051-0.2543-0.2314-0.45234-0.4758-0.33362l-0.8139 0.39542c-0.1275 0.0664-0.1356 0.0659-0.1454 0.0493l-1.1219-1.9622c-0.1246-0.21794-0.4569-0.21791-0.5814 5e-5l-1.1243 1.9672c-0.0069 0.0116-0.015 0.0121-0.1337-0.0499l-0.8227-0.39982c-0.2444-0.11872-0.5268 0.07932-0.4758 0.33362z"
          fill="#F6623A"
          stroke="#25383F"
          strokeWidth="1.2"
        />
        <line x1="5.6828" x2="5.6828" y1="1" y2="25.401" stroke="#25383F" strokeWidth="1.5" />
        <line x1="26.504" x2="26.504" y1="3.9282" y2="28.329" stroke="#25383F" strokeWidth="1.5" />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.769 2.202c.414 0 .75.336.75.75v29.282c0 .414-.336.75-.75.75-.415 0-.75-.336-.75-.75V2.952c0-.414.335-.75.75-.75z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M.769 26.433l4.378-1.263c3.668-1.059 7.646-.585 10.915 1.302 3.19 1.84 7.058 2.338 10.66 1.374l4.145-1.11c.67-.18 1.133-.755 1.133-1.408V4.756c0-.459-.23-.891-.622-1.168-.39-.277-.897-.365-1.369-.24l-3.288.881c-3.601.965-7.469.466-10.659-1.374C12.792.97 8.815.495 5.147 1.554L.769 2.814"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".4"
        d="M13.873 13.105l-.596-2.968c-.051-.255.231-.453.475-.334l.823.4c.119.062.127.061.134.05l1.124-1.967c.125-.218.457-.218.581 0l1.122 1.962c.01.016.018.017.146-.05l.814-.395c.244-.119.527.08.475.334l-.596 2.968 1.324-1.39c.173-.182.491-.113.563.122l.215.708 1.745-.345c.242-.047.452.163.386.387l-.47 1.606-.07.244.633.278c.22.098.257.379.067.523l-3.097 2.36c-.02.015-.031.046-.027.059l.27.833c.07.214-.117.425-.353.4-.42-.046-.753-.09-1.45-.189l-.1-.014c-.639-.09-1.008-.138-1.4-.18l.08 2.961c.004.175-.145.319-.33.319h-.518c-.188 0-.338-.148-.33-.324l.137-2.957c-.397.042-.767.09-1.414.181l-.099.014c-.697.099-1.03.143-1.45.188-.236.026-.423-.185-.353-.4l.268-.826c.006-.02-.005-.05-.03-.069L9.48 15.238c-.19-.144-.153-.425.067-.523l.633-.277-.069-.242-.472-1.609c-.065-.224.145-.434.386-.386l1.745.344.216-.708c.071-.235.39-.304.563-.122l1.324 1.39zm1.414-2.553c-.223.374-.634.402-1.024.198l-.212-.103.48 2.388c.088.384-.013.7-.326.81-.262.092-.557-.022-.727-.224l-1.022-1.073-.063.207c-.072.27-.36.457-.707.408l-1.266-.25.329 1.12c.004.016.055.191.067.238.026.098.038.173.036.25-.007.239-.159.4-.384.474l-.163.072 2.676 2.04c.23.168.336.459.255.731l-.122.375c.244-.03.523-.07.925-.126l.1-.014c.718-.1 1.102-.15 1.559-.196.154-.016.312.056.43.169.113-.112.266-.185.422-.17.457.046.84.096 1.56.197l.099.014c.402.057.681.095.925.126l-.124-.381c-.08-.266.027-.557.251-.72l2.683-2.045-.134-.06c-.255-.086-.407-.247-.414-.485-.002-.078.01-.153.036-.251.012-.047.063-.222.068-.24l.328-1.118-1.285.253c-.328.046-.616-.141-.685-.4l-.066-.218-1.01 1.06c-.182.215-.477.329-.739.237-.313-.11-.414-.426-.327-.802l.481-2.396-.204.099c-.398.208-.809.18-1.035-.199l-.834-1.459-.837 1.464z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        d="M5.683 1L5.683 25.401M26.504 3.928L26.504 28.329"
      />
    </Icon>
  );
}
