import {
  Button,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@terminal/design-system';
import React from 'react';

export function CommunicationModalBody({ children }: { children: React.ReactNode }) {
  return (
    <Flex flexDir="column" flex={1} justifyContent="flex-end" mt={2}>
      {children}
    </Flex>
  );
}

export function CommunicationModalFooterCTAs({
  primaryCTA,
  secondaryCTA,
}: {
  primaryCTA: {
    title: string;
    onClick: () => void;
  };
  secondaryCTA?: {
    title: string;
    onClick: () => void;
  };
}) {
  return (
    <>
      <Button onClick={primaryCTA.onClick} variant="primary" mt={8} size="lg" w="full">
        {primaryCTA.title}
      </Button>
      {secondaryCTA && (
        <Button onClick={secondaryCTA.onClick} variant="secondary" mt={3} size="lg" w="full">
          {secondaryCTA.title}
        </Button>
      )}
    </>
  );
}

export function CommunicationModal({
  onClose,
  children,
  image,
  isOpen,
}: {
  onClose: () => void;
  children: React.ReactNode;
  image: { src: string; alt: string };
  isOpen: boolean;
}) {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      returnFocusOnClose={false}
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent maxW={['21.938rem', '21.938rem', 'lg']}>
        <ModalCloseButton />
        <Flex bg="bg.tertiary" alignItems="center" justifyContent="center" h="min" py={6}>
          <Image minW={40} src={image.src} alt={image.alt} />
        </Flex>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}
