import type { SpaceProps, FlexboxProps, LayoutProps } from '@terminal/design-system';
import { Flex, VStack } from '@terminal/design-system';

export function WideLeftNarrowRightLayout({
  left,
  right,
  rightVerticalSpacing = [4, 6],
  leftVerticalSpacing = 8,
  rightContainerProps,
  leftContainerProps,
  flexProps,
  ...containerSpaceProps
}: {
  left: React.ReactNode;
  right: React.ReactNode;
  rightVerticalSpacing?: SpaceProps['m'];
  leftVerticalSpacing?: SpaceProps['m'];
  rightContainerProps?: SpaceProps & LayoutProps;
  leftContainerProps?: SpaceProps & LayoutProps;
  flexProps?: FlexboxProps;
} & SpaceProps) {
  return (
    <Flex flexDir={['column', 'column', 'row']} {...containerSpaceProps} {...flexProps}>
      <VStack
        minW={['full', 'full', 'lg']}
        maxW={['full', 'full', '2xl']}
        w="full"
        spacing={leftVerticalSpacing}
        zIndex="docked"
        mt={0}
        role="status"
        {...leftContainerProps}
      >
        {left}
      </VStack>
      <VStack
        ml={[0, 6]}
        mt={[10, 10, 0]}
        minW={['full', 'full', 'xs']}
        w="fit"
        zIndex="docked"
        flex={1}
        spacing={rightVerticalSpacing}
        {...rightContainerProps}
      >
        {right}
      </VStack>
    </Flex>
  );
}
