import {
  Badge,
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Grid,
  HStack,
  Image,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  ArrowRightIcon,
  BriefcaseIcon,
  BuildingIcon,
  Button,
  CalendarIcon,
  CheckCircleSolidIcon,
  Heading,
  InfoIcon,
  WalletIcon,
  VerticalSteps,
} from '@terminal/design-system';
import { useIsViewPortDesktop, useRedirection } from 'global/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { MyJob } from '../MyJobs';
import { selectBadgeColor } from '../MyJobs';
import { JobDescription, JobDetailInterviewDetails } from './JobDetail';

export function JobDrawerDetails({
  jobDetails,
  isOpen,
  onClose,
  handleApplyButtonClick,
  isApplyButtonLoading,
  handleDeclinedButtonClick,
  isDeclinedButtonLoading,
}: {
  jobDetails: MyJob | null;
  isOpen: boolean;
  onClose: () => void;
  handleApplyButtonClick?: () => void;
  isApplyButtonLoading?: boolean;
  handleDeclinedButtonClick?: () => void;
  isDeclinedButtonLoading?: boolean;
}) {
  const tabTitles = useMemo(() => {
    return [
      !!jobDetails?.applicationStatus?.length && 'Application Status',
      !!jobDetails?.jobDescription && 'Job Description',
      !!jobDetails?.jobDescription.interviewDetails && 'Interview Details',
    ].filter(Boolean);
  }, [jobDetails]);

  const redirectTo = useRedirection();
  const salaryBanner = useRef<HTMLDivElement>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const isDesktop = useIsViewPortDesktop();

  useEffect(() => {
    setTabIndex(tabTitles.indexOf('Job Description'));
  }, [tabTitles]);

  // This counter is just acting as dependency of an useEffect array to trigger an action
  const [counterInfoIconClick, setCounterInfoIconClick] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (salaryBanner.current) {
      salaryBanner.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [counterInfoIconClick]);

  return (
    <Drawer
      isOpen={isOpen}
      placement={isDesktop === false ? 'bottom' : 'right'}
      onClose={onClose}
      size="lg"
      isFullHeight
      autoFocus={false}
      returnFocusOnClose={false}
    >
      <DrawerOverlay>
        <DrawerContent pt={[16, 16, 20]} h="full" justifyContent="space-between">
          <DrawerCloseButton fontSize="md" right="initial" left={5} top={5} />
          <DrawerBody px={[3, 3, 20]} mb={4} overflow="overlay">
            <>
              <Box>
                <Flex justifyContent="space-between">
                  {jobDetails?.summary.badge && isDesktop && (
                    <Badge colorScheme={selectBadgeColor(jobDetails?.summary.badge.label)}>
                      <Text variant="caption" color="text.primary" fontWeight="bold">
                        {jobDetails?.summary.badge.label}
                      </Text>
                    </Badge>
                  )}
                  {isDesktop && <Text variant="hint">{jobDetails?.summary.updatedAt}</Text>}
                </Flex>
                {jobDetails?.summary.logo && (
                  <Box w="full" my={6}>
                    <Image
                      w="full"
                      maxW="xs"
                      h={10}
                      src={jobDetails?.summary.logo}
                      alt={`${jobDetails?.summary.companyName} logo`}
                      objectFit="contain"
                      objectPosition="left"
                    />
                  </Box>
                )}
                <HStack justifyContent="space-between" w="full" m={0}>
                  <Box>
                    {jobDetails?.summary.companyName &&
                      jobDetails?.jobDescription.organizationID && (
                        <Link
                          color="accent.main"
                          display="inline-block"
                          onClick={() =>
                            redirectTo(
                              `/company/${jobDetails?.jobDescription.organizationID as number}`,
                            )
                          }
                        >
                          {jobDetails?.summary.companyName}
                        </Link>
                      )}
                    {jobDetails?.summary.jobTitle && (
                      <Heading color="text.primary" variant="heading-2">
                        {jobDetails?.summary.jobTitle}
                      </Heading>
                    )}
                  </Box>
                </HStack>
                <Divider borderColor="ui.secondary" my={[4, 4, 6]} opacity={1} />
                <Flex
                  justifyContent="space-between"
                  flexDirection={['column', 'row', 'row']}
                  alignItems="flex-start"
                >
                  <Flex gridColumnGap={6} gridRowGap={3} flexWrap={['nowrap', 'nowrap', 'wrap']}>
                    {jobDetails?.jobDescription.industry && (
                      <Flex alignItems="center">
                        <BuildingIcon fontSize="2xl" color="ui.darker.disabled" />
                        <Flex flexDir="column" ml={3}>
                          <Text variant="hint" fontWeight="bold">
                            Industry
                          </Text>
                          <Text variant="caption">{jobDetails?.jobDescription.industry}</Text>
                        </Flex>
                      </Flex>
                    )}

                    {jobDetails?.jobDescription.employmentType && (
                      <Flex alignItems="center">
                        <BriefcaseIcon fontSize="2xl" color="ui.darker.disabled" />
                        <Flex flexDir="column" ml={3}>
                          <Text variant="hint" fontWeight="bold">
                            Job Type
                          </Text>
                          <Text variant="caption">{jobDetails?.jobDescription.employmentType}</Text>
                        </Flex>
                      </Flex>
                    )}

                    {jobDetails?.jobDescription.salary && (
                      <Flex alignItems="center">
                        <WalletIcon fontSize="2xl" color="ui.darker.disabled" />
                        <Flex flexDir="column" ml={3}>
                          <Text variant="hint" fontWeight="bold">
                            Salary
                          </Text>
                          <Text variant="caption">{jobDetails?.jobDescription.salary}</Text>
                        </Flex>
                        <InfoIcon
                          color="ui.darker.disabled"
                          ml={3}
                          cursor="pointer"
                          onClick={() => {
                            setTabIndex(tabTitles.findIndex((tab) => tab === 'Job Description'));
                            setCounterInfoIconClick(counterInfoIconClick + 1);
                          }}
                        />
                      </Flex>
                    )}
                  </Flex>
                  {jobDetails?.jobDescription.organizationID && (
                    <Button
                      fontWeight="bold"
                      variant="ghost"
                      color="accent.main"
                      rightIcon={<ArrowRightIcon fontSize="xs" />}
                      onClick={() =>
                        redirectTo(
                          `/company/${jobDetails?.jobDescription.organizationID as number}`,
                        )
                      }
                    >
                      About {jobDetails?.jobDescription.companyName}
                    </Button>
                  )}
                </Flex>

                <Divider borderColor="ui.secondary" my={[4, 4, 6]} opacity={1} />

                {jobDetails?.jobDescription?.techStacks &&
                  jobDetails?.jobDescription?.techStacks.length > 0 && (
                    <>
                      <Wrap mt={3} data-testid="tech-stack-wrapper">
                        {jobDetails?.jobDescription?.techStacks.map(
                          ({ stack, shouldHighlight }) => (
                            <WrapItem key={`techStack-${stack}`}>
                              <Tag
                                colorScheme={shouldHighlight ? 'success' : 'accent-lightest'}
                                size="sm"
                                variant="subtle"
                              >
                                {stack}
                              </Tag>
                            </WrapItem>
                          ),
                        )}
                      </Wrap>

                      {jobDetails?.jobDescription.topApplicantInfo.isTopApplicant && (
                        <Grid
                          alignItems="center"
                          gridTemplateColumns={['1fr', '1fr', '44% 53%']}
                          backgroundColor="accent.lightest"
                          mt={6}
                          p={4}
                          gridGap={[0, 0, 6]}
                          justifyContent="space-between"
                        >
                          <Box>
                            <Heading variant="heading-4">You Are a Top Applicant</Heading>
                            <Text variant="caption" mt={1}>
                              Your experience matches the minimum requirements for this role.
                            </Text>
                          </Box>
                          <Flex gridGap={4} mt={3}>
                            <Flex alignItems="center">
                              <CheckCircleSolidIcon color="ui.success" mr={1} />
                              <Text variant="caption">
                                {jobDetails?.jobDescription.topApplicantInfo.numberOfSkillsMatched}{' '}
                                Skills Match
                              </Text>
                            </Flex>

                            <Flex alignItems="center">
                              <CheckCircleSolidIcon color="ui.success" mr={1} />
                              <Text variant="caption">
                                {jobDetails?.jobDescription.topApplicantInfo.yearsOfExperience}
                              </Text>
                            </Flex>
                          </Flex>
                        </Grid>
                      )}
                    </>
                  )}
              </Box>

              <Tabs mt={[4, 4, 3]} index={tabIndex >= 0 ? tabIndex : 0} onChange={handleTabsChange}>
                <TabList>
                  {!!jobDetails?.applicationStatus?.length && (
                    <Tab
                      border="none"
                      _focus={{
                        backgroundColor: 'unset',
                      }}
                      _selected={{
                        color: 'accent.main',
                        borderBottom: '2px solid',
                        borderColor: 'accent.main',
                      }}
                    >
                      Application Status
                    </Tab>
                  )}
                  {!!jobDetails?.jobDescription && (
                    <Tab
                      border="none"
                      _focus={{
                        backgroundColor: 'unset',
                      }}
                      _selected={{
                        color: 'accent.main',
                        borderBottom: '2px solid',
                        borderColor: 'accent.main',
                      }}
                    >
                      Job Description
                    </Tab>
                  )}
                  {jobDetails?.jobDescription &&
                    jobDetails?.jobDescription.interviewDetails.length > 0 && (
                      <Tab
                        border="none"
                        _focus={{
                          backgroundColor: 'unset',
                        }}
                        _selected={{
                          color: 'accent.main',
                          borderBottom: '2px solid',
                          borderColor: 'accent.main',
                        }}
                      >
                        Interview Details
                      </Tab>
                    )}
                </TabList>

                <TabPanels>
                  {!!jobDetails?.applicationStatus?.length && (
                    <TabPanel pt={[3, 3, 6]}>
                      <VerticalSteps
                        ml={[8, 8, 6]}
                        mt={2}
                        mb={[21, 21, 0]}
                        timelineColor="accent.lighter"
                        steps={jobDetails?.applicationStatus?.map((history) => (
                          <>
                            {history.date && (
                              <Flex alignItems="center" gridGap={1}>
                                <CalendarIcon color="accent.main" />
                                <Text variant="caption" fontWeight="bold" color="accent.main">
                                  {history.date}
                                </Text>
                              </Flex>
                            )}
                            <Text variant="caption" fontWeight="bold" mt={1}>
                              {history.title}
                            </Text>
                            <Text variant="caption" mt={1}>
                              {history.description}
                            </Text>
                          </>
                        ))}
                      />
                    </TabPanel>
                  )}
                  {!!jobDetails?.jobDescription && (
                    <TabPanel pt={[3, 3, 6]}>
                      <JobDescription
                        jobDetailData={jobDetails?.jobDescription || null}
                        salaryBanner={salaryBanner}
                      />
                    </TabPanel>
                  )}
                  {jobDetails?.jobDescription &&
                    jobDetails?.jobDescription.interviewDetails.length > 0 && (
                      <TabPanel pt={[3, 3, 6]}>
                        <JobDetailInterviewDetails
                          jobDetailData={jobDetails?.jobDescription || null}
                        />
                      </TabPanel>
                    )}
                </TabPanels>
              </Tabs>
            </>
          </DrawerBody>
          {jobDetails?.isTPRecommendation && (
            <DrawerFooter
              px={[4, 4, 20]}
              py={[4, 4, 6]}
              boxShadow="0px -4px 6px rgba(0, 0, 0, 0.1)"
              justifyContent="center"
              w="full"
            >
              <Flex
                gridGap={4}
                flexDirection={['column-reverse', 'column-reverse', 'row']}
                px={[4, 4, 0]}
                py={[4, 4, 0]}
                w="full"
              >
                <Button
                  w={['full', 'full', '277px']}
                  variant="outline"
                  colorScheme="accent"
                  isLoading={isDeclinedButtonLoading}
                  onClick={handleDeclinedButtonClick}
                >
                  Not Interested
                </Button>
                <Button
                  w={['full', 'full', '277px']}
                  onClick={handleApplyButtonClick}
                  isLoading={isApplyButtonLoading}
                >
                  Confirm & Apply
                </Button>
              </Flex>
            </DrawerFooter>
          )}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
