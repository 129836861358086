import { EditModal } from '../../components';
import type { ProfileProps } from '../../Profile.types';
import {
  useWorkExperiencesController,
  WorkExperiencesForm,
  WorkExperiencesSection,
} from '../../modules/work-experiences';

export function WorkExperiencesController({
  workExperiences,
  userID,
  candidateID,
  isModalOpen,
  onModalClose,
  onModalOpen,
}: {
  workExperiences: ProfileProps['candidate']['workExperiences'];
  candidateID: number;
  userID?: number;
  isModalOpen: boolean;
  onModalClose: () => void;
  onModalOpen: () => void;
}) {
  const {
    deleteWorkExperience,
    editID,
    formik,
    handleAddClick,
    handleEditClick,
    handleOnClose,
    handleOnSaveClick,
    hasFormValuesChanged,
    initialRef,
    isLoading_delete,
    isLoading_upsert,
  } = useWorkExperiencesController({
    onModalOpen,
    onModalClose,
    workExperiences,
    userID,
    candidateID,
  });

  return (
    <>
      <WorkExperiencesSection
        workExperiences={workExperiences}
        onAddClick={handleAddClick}
        onEditClick={handleEditClick}
      />
      <EditModal
        isOpen={isModalOpen}
        onClose={handleOnClose}
        initialRef={initialRef}
        shouldConfirmClose={hasFormValuesChanged()}
        showLoadingForSaveButton={isLoading_upsert}
        showLoadingForDeleteButton={isLoading_delete}
        onSaveClick={handleOnSaveClick}
        OnDeleteClick={() => {
          deleteWorkExperience({
            variables: {
              id: editID || 0,
            },
          });
        }}
        showDeleteButton={editID != null}
        title={editID != null ? 'Edit Experience' : 'Add Experience'}
      >
        <WorkExperiencesForm
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          initialRef={initialRef}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </EditModal>
    </>
  );
}
