import { Icon } from '@terminal/design-system';

export function LeaderIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <circle cx="10" cy="10.5" r="5.5" fill="#A0CFDA" />
        <circle cx="23.636" cy="13.5" r="4.5" fill="#8FA25B" />
        <circle
          cx="10"
          cy="10.5"
          r="5.5"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m1 27c0-4.9705 4.0294-9 9-9 4.9706 0 9 4.0295 9 9"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <circle
          cx="23.636"
          cy="13.5"
          r="4.5"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m21.084 20.091c2.2599-0.8329 4.7842-0.5087 6.7602 0.8682 1.9761 1.3769 3.1545 3.6327 3.1558 6.0411"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <circle
        cx="10"
        cy="10.5"
        r="5.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 27c0-4.97 4.03-9 9-9s9 4.03 9 9"
      />
      <circle
        cx="23.636"
        cy="13.5"
        r="4.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21.084 20.09c2.26-.832 4.784-.508 6.76.869S31 24.592 31 27"
      />
      <mask id="c1x4ezyoza" width="32" height="32" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="currentColor" d="M0 0H32V32H0z" />
      </mask>
    </Icon>
  );
}
