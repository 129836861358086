import type { NoUndefinedField } from 'global/utils';
import { Employment_Type_Choices_Enum } from 'global/types';
import type { NonNullable_ICIMsJob, SelectCompanyInfoQuery } from './graphql';

function createSelectCompanyInfo_organizationEmployee(
  overwrite: Partial<
    NoUndefinedField<SelectCompanyInfoQuery>['organization_by_pk']['organization_leaderships'][number]['organization_employee']
  > = {} as Partial<
    NoUndefinedField<SelectCompanyInfoQuery>['organization_by_pk']['organization_leaderships'][number]['organization_employee']
  >,
): NoUndefinedField<SelectCompanyInfoQuery>['organization_by_pk']['organization_leaderships'][number]['organization_employee'] {
  return {
    __typename: 'organization_employee',
    name: 'Leeroy Jenkins',
    title: 'COO & Co-founder',
    linkedin_url: 'https://www.linkedin.com/in/miguelglzv/',
    ...overwrite,
  };
}

function createSelectCompanyInfo_ICIMSJob_Job(
  overwrite: Partial<NonNullable_ICIMsJob[number]['job']> = {},
): NonNullable_ICIMsJob[number]['job'] {
  return {
    employment_type: Employment_Type_Choices_Enum.FullTime,
    max_contract_rate: null,
    min_contract_rate: null,
    job_meta_infos: [
      {
        latam_min_salary: 123,
        latam_max_salary: 223424,
        canada_max_salary: 425322,
        canada_min_salary: 323542,
        europe_max_salary: 652323,
        europe_min_salary: 52532,
      },
    ],
    ...overwrite,
  };
}

function createSelectCompanyInfo_ICIMSJob(
  overwrite: Partial<NonNullable_ICIMsJob[number]> = {},
): NonNullable_ICIMsJob[number] {
  return {
    __typename: 'icims_job',
    profile_id: 3911,
    jobtitle: 'Platform Infrastructure Support Associate',
    tech_stack: 'Java, Python, MySQL, Javascript',
    skillByRequiredSkill1: {
      name: 'JavaScript',
    } as any,
    skillByRequiredSkill2: {
      name: 'TypeScript',
    } as any,
    skillByRequiredSkill3: {
      name: 'NodeJS',
    } as any,
    years_experience: [
      {
        id: 'C28236',
        value: 'Sr 5-10',
      },
    ] as any,
    job: createSelectCompanyInfo_ICIMSJob_Job(),
    ...overwrite,
  };
}

function createSelectCompanyInfo_organizationVideo(
  overwrite: Partial<
    NoUndefinedField<SelectCompanyInfoQuery>['organization_by_pk']['organization_videos'][number]
  > = {} as Partial<
    NoUndefinedField<SelectCompanyInfoQuery>['organization_by_pk']['organization_videos'][number]
  >,
): NoUndefinedField<SelectCompanyInfoQuery>['organization_by_pk']['organization_videos'][number] {
  return {
    __typename: 'organization_video',
    id: 1,
    url: 'https://www.youtube.com/embed/15F-AIuE-xU',
    ...overwrite,
  };
}

function createSelectCompanyInfo_organization(
  overwrite: Partial<SelectCompanyInfoQuery['organization_by_pk']> = {} as Partial<
    SelectCompanyInfoQuery['organization_by_pk']
  >,
): SelectCompanyInfoQuery['organization_by_pk'] {
  return {
    __typename: 'organization',
    name: 'Darwin Homes',
    logo: 'https://static.prod.terminal.io/logos/earnin.svg',
    logo_sq: 'https://static.prod.terminal.io/logos/earnin.svg',
    hq_country: 'United States',
    hq_state: 'Texas',
    hq_city: 'Austin',
    culture_values:
      '<ul><li>Professionalism: Ushering in a new level of quality service to the rental industry which is often non-transparent. Protecting tenants and property owners with protections through technology</li><li>Passion</li><li>Independance </li><li>Grit</li><li>Heart & Empathy for Customers</li></ul>',
    website_url: 'https://www.darwinhomes.com/',
    about:
      '<p>Darwin Homes is a real estate technology company focused on using technology to manage residential properties at scale. Our full-stack, tech-enabled property management platform allows us to operate a diverse set of housing types, ranging from large multi-family developments to single-family homes.</p><p>Property management sits at a strategic point in the real estate ecosystem, giving us the ability to leverage our position to positively impact the lives of both our property owners and residents.</p>',
    linkedin_url: 'https://www.linkedin.com/company/darwin-homes-inc/about/',
    organization_leaderships: [
      {
        __typename: 'organization_leadership',
        organization_employee: createSelectCompanyInfo_organizationEmployee(),
      },
    ],
    salesforce_customer: { __typename: 'salesforce_customer', industry: 'Rental' },
    icims_company: {
      __typename: 'icims_company',
      icims_jobs: [createSelectCompanyInfo_ICIMSJob()],
    },
    organization_videos: [createSelectCompanyInfo_organizationVideo()],
    ...overwrite,
  };
}

function createSelectCompanyInfoFixture(
  overwrite: Partial<SelectCompanyInfoQuery> = {} as Partial<SelectCompanyInfoQuery>,
): SelectCompanyInfoQuery {
  return {
    organization_by_pk: createSelectCompanyInfo_organization(),
    ...overwrite,
  };
}

const organizationData = {
  createOrganization: createSelectCompanyInfo_organization,
  createVideo: createSelectCompanyInfo_organizationVideo,
  createICIMSJob: createSelectCompanyInfo_ICIMSJob,
  createJob: createSelectCompanyInfo_ICIMSJob_Job,
  createEmployee: createSelectCompanyInfo_organizationEmployee,
};

export const SelectCompanyInfoFixture = {
  createData: createSelectCompanyInfoFixture,
  organizationData,
  results: createSelectCompanyInfoFixture(),
  createSelectCompanyInfo_ICIMSJob,
  createSelectCompanyInfo_ICIMSJob_Job,
};
