import { useFormik } from 'formik';
import type { ComponentProps } from 'react';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useQuery, validators } from 'global/utils';
import type { SelectCountryListQuery, SelectCountryListQueryVariables } from 'candidate/utils/data';
import { SelectCountryList } from 'candidate/utils/data';
import { useToast } from '@terminal/design-system';
import type { FormikSubmitHandler } from '../../DirectApplication.types';
import { PersonalInfo } from './PersonalInfo';
import { jobLocationOptions } from './PersonalInfo.serializer';

export type PersonalInfoForm = {
  firstName: string;
  lastName: string;
  email: string;
  location: string;
  country_id: number | null;
};

export const PersonalInfoValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Please make sure your email address is valid.')
    .required('Email is a required field.'),
  firstName: Yup.string()
    .trim()
    .matches(validators.firstName.regex, validators.firstName.message)
    .required('First name is a required field.'),
  lastName: Yup.string()
    .trim()
    .matches(validators.lastName.regex, validators.lastName.message)
    .required('Last name is a required field.'),
  location: Yup.string().required('Location is a required field.').nullable(),
  country_id: Yup.number().notRequired().nullable(),
});

export function PersonalInfoController({
  onSectionBoxEditClick,
  isOpened,
  onComplete,
  sectionSubmitButtonIsLoading,
  isCompleted,
}: {
  onComplete: FormikSubmitHandler<PersonalInfoForm>;
  sectionSubmitButtonIsLoading: boolean;
} & Pick<
  ComponentProps<typeof PersonalInfo>,
  'isOpened' | 'onSectionBoxEditClick' | 'isCompleted'
>) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const normalizedCountries = useQuery<SelectCountryListQuery, SelectCountryListQueryVariables>(
    SelectCountryList,
  );
  const countryNameToIDMap: Record<string, number> =
    normalizedCountries.data?.country_choices?.reduce(
      (map: Record<string, number>, obj: { name: string; id: number }) => {
        if (!obj.name) return map;
        return {
          ...map,
          [obj.name]: obj.id,
        };
      },
      {},
    ) || {};

  const formik = useFormik<PersonalInfoForm>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      location: '',
      country_id: null,
    },
    validationSchema: PersonalInfoValidationSchema,
    onSubmit: (values, helpers) => {
      const countryID = values.location ? countryNameToIDMap[values.location] : null;
      onComplete({ ...values, country_id: countryID || null }, helpers);
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const hasValidationError = !!Object.keys(formik.errors).length;

  useEffect(() => {
    if (formik.isSubmitting && hasValidationError) {
      toast({
        status: 'error',
        description: 'Please review your entry and try again.',
      });
    }

    // Ignoring toast as it is a fixed design system utility
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasValidationError, formik.isSubmitting]);

  return (
    <PersonalInfo
      errors={formik.errors}
      values={formik.values}
      touched={formik.touched}
      sectionSubmitButtonIsDisabled={!formik.isValid}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      jobLocationOptions={jobLocationOptions}
      onNextClick={formik.handleSubmit}
      onSectionBoxEditClick={onSectionBoxEditClick}
      isCompleted={isCompleted}
      isOpened={isOpened}
      sectionSubmitButtonIsLoading={sectionSubmitButtonIsLoading}
    />
  );
}
