import { toFriendlyYearsOfExperience } from 'global/utils';
import { Candidate_Skill_Comp_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import type { SelectProfileQuery } from 'candidate/features/profile/Profile.types';
import type { SkillsProps } from 'candidate/shared/modules';

/**
 * groups skills by competencies. If there are no competency, it will group them under "others" group.
 */
export function serializeSkills(
  candidateSkills: SelectProfileQuery['candidates'][number]['candidate_skills'],
): SkillsProps['skillGroups'] {
  const skillGroupTexts: {
    [key: string]: {
      title: string;
      subtitle: string;
      emptySkills: string | null;
      isFavorite: boolean;
    };
  } = {
    preferred: {
      title: 'Preferred Skills',
      subtitle: 'Skills and technologies you would prefer to work with in your next role.',
      emptySkills:
        'You have not indicated any preferred skills. To add a preferred skills click the star icon on any selected skills.',
      isFavorite: true,
    },
    other: {
      title: 'More Skills',
      subtitle: 'Additional skills that you want to showcase.',
      emptySkills: null,
      isFavorite: false,
    },
  };

  const skillGroupDictionary = candidateSkills.reduce(
    (acc, current) => {
      const groupName = current.is_favorite ? 'preferred' : 'other';

      if (acc[groupName]) {
        const newAcc = {
          ...acc,
          [groupName]: [...(acc[groupName] || []), current],
        };
        return newAcc;
      }

      return {
        [groupName]: [current],
        ...acc,
      };
    },
    (candidateSkills.length > 0 ? { preferred: [] } : {}) as {
      [key: string]: SelectProfileQuery['candidates'][number]['candidate_skills'];
    },
  );

  const skillsCompetencyGroupingSortOrder: { [key: string]: number } = {
    [Candidate_Skill_Comp_Choices_Enum.Expert]: 5,
    [Candidate_Skill_Comp_Choices_Enum.Advanced]: 4,
    [Candidate_Skill_Comp_Choices_Enum.Intermediate]: 3,
    [Candidate_Skill_Comp_Choices_Enum.Beginner]: 2,
    others: 1,
  };

  return Object.entries(skillGroupDictionary)
    .map(([competencyGroup, competencyGroupSkills]) => {
      return {
        title: skillGroupTexts[competencyGroup]
          ?.title as SkillsProps['skillGroups'][number]['title'],
        subtitle: skillGroupTexts[competencyGroup]
          ?.subtitle as SkillsProps['skillGroups'][number]['subtitle'],
        skills: competencyGroupSkills
          // sorting the skills by proficiency then by years of exp and then alphabetically
          .sort((a, b) => {
            const proficiencyAscOrder =
              (skillsCompetencyGroupingSortOrder[b.competency?.toLowerCase() || 'others'] || 1) -
              (skillsCompetencyGroupingSortOrder[a.competency?.toLowerCase() || 'others'] || 1);

            if (proficiencyAscOrder !== 0) {
              return proficiencyAscOrder;
            }

            const yearsOfExperienceAscOrder = (b.years_of_exp || -1) - (a.years_of_exp || -1);

            if (yearsOfExperienceAscOrder !== 0) {
              return yearsOfExperienceAscOrder;
            }

            return b.skill.name.localeCompare(a.skill.name);
          })
          .map(({ id, skill, years_of_exp, competency, is_favorite }) => ({
            title: skill.name,
            subTitle:
              years_of_exp || competency
                ? [...(years_of_exp ? [toFriendlyYearsOfExperience(years_of_exp)] : []), competency]
                    .filter(Boolean)
                    .join(' - ')
                : null,
            formValues: {
              candidateSkillID: id.toString(),
              skillID: skill.id,
              name: skill.name,
              years_of_exp: years_of_exp ? years_of_exp?.toString() : null,
              competency: competency || null,
              is_favorite: !!is_favorite,
            },
          })),
        isFavorite: skillGroupTexts[competencyGroup]?.isFavorite ?? false,
        emptySkillsText: skillGroupTexts[competencyGroup]?.emptySkills ?? null,
      };
    })
    .sort((a, b) => {
      // orders favorites group first
      return Number(b.isFavorite) - Number(a.isFavorite);
    });
}

/**
 * [Archived]
 * groups skills by competencies. If there are no competency, it will group them under "others" group.
 * Keeping this function in case in the future we wanted to try grouping skills by competency instead of favorites
 */
export function serializeSkillsWithCompetencyGrouping(
  candidateSkills: SelectProfileQuery['candidates'][number]['candidate_skills'],
) {
  const skillGroupDictionary = candidateSkills.reduce((acc, current) => {
    const competencyGroup = current.competency || 'others';

    if (acc[competencyGroup]) {
      const newAcc = {
        ...acc,
        [competencyGroup]: [...(acc[competencyGroup] || []), current],
      };
      return newAcc;
    }

    return {
      [competencyGroup]: [current],
      ...acc,
    };
  }, {} as { [key: string]: SelectProfileQuery['candidates'][number]['candidate_skills'] });

  const skillsCompetencyGroupingSortOrder: { [key: string]: number } = {
    expert: 4,
    intermediate: 3,
    novice: 2,
    others: 1,
  };

  return Object.entries(skillGroupDictionary)
    .map(([competencyGroup, competencyGroupSkills]) => {
      return {
        title: competencyGroup,
        skills: competencyGroupSkills
          .sort((a, b) => (b.years_of_exp || -1) - (a.years_of_exp || -1)) // sorting the skills under each group by years of experience
          .map(({ id, skill, years_of_exp, competency, is_favorite }) => ({
            id,
            name: skill.name,
            yearsOfExperience: years_of_exp ? toFriendlyYearsOfExperience(years_of_exp) : null,
            competency,
            formValues: {
              candidateSkillID: id.toString(),
              skillID: skill.id,
              name: skill.name,
              years_of_exp: years_of_exp ? years_of_exp?.toString() : '',
              competency: competency || null,
              is_favorite: !!is_favorite,
            },
          })),
      };
    })
    .sort((a, b) => {
      // sorts the competencies group by experts, intermediate, novice and others in order
      return (
        (skillsCompetencyGroupingSortOrder[b.title.toLowerCase()] || 1) -
        (skillsCompetencyGroupingSortOrder[a.title.toLowerCase()] || 1)
      );
    });
}
