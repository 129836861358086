import { Center, Heading, Text, Image } from '@terminal/design-system';
import EmptyAssessmentsImage from 'candidate/assets/images/no-assessments.svg?url';

export function EmptyAssessments({ title, message }: { title: string; message: string }) {
  return (
    <Center flexDirection="column" h={['55vh', '60vh']} minH="sm" px={[4, 4, 10]}>
      <Image src={EmptyAssessmentsImage} alt="No Assessments Available" />
      <Heading variant="heading-2" mt={6} align="center">
        {title}
      </Heading>
      <Text variant="body" mt={2} align="center">
        {message}
      </Text>
    </Center>
  );
}
