import * as Yup from 'yup';
import { validators } from 'global/utils';
import type { ProfileProps } from 'candidate/features/profile/Profile.types';
import { useCandidateAuth } from 'candidate/utils';
import type { HStepsProps } from 'candidate/components';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import { useRouteMatch } from 'react-router-dom';
import { ExperiencesManualEntry } from './ExperiencesManualEntry';

export const validationSchema = Yup.object().shape({
  linkedin: Yup.string().matches(
    validators.linkedIn.validURL.regex,
    validators.linkedIn.validURL.message,
  ),
});

type Mode = 'add' | 'review' | 'question';

const titleMode: Record<Mode, string> = {
  add: 'Add Experience',
  review: 'Review Your Experience',
  question: 'What Experience Do You Have?',
};

export function ExperiencesManualEntryController({
  candidateID,
  mode,
  onStepCompleteSuccess,
  progressProps,
  userID,
  workExperiences,
  educations,
  degreeChoices,
  variation,
}: {
  candidateID: number;
  mode: Mode;
  onStepCompleteSuccess: () => void;
  progressProps: HStepsProps;
  userID?: number;
  workExperiences: ProfileProps['candidate']['workExperiences'];
  educations: ProfileProps['candidate']['educations'];
  degreeChoices: ProfileProps['degreeChoices'];
  variation: 'Combined' | 'Separated';
}) {
  const { url } = useRouteMatch();
  const auth = useCandidateAuth();
  const ExperiencesManualEntryVariation = ExperiencesManualEntry.Variation[variation];
  const urlPath = url.split('/');
  // We are removing the last path of the URL to get the variation URL
  const variationURL = urlPath.slice(0, urlPath.length - 1).join('/');

  return (
    <ExperiencesManualEntryVariation
      isNextDisabled={educations.length === 0 && workExperiences.length === 0}
      onNextClick={onStepCompleteSuccess}
      onSignOutClick={() => signOut({ auth: firebaseAuth })}
      progressProps={progressProps}
      title={titleMode[mode] || titleMode.review}
      userFullName={auth.userFullName}
      workExperiences={workExperiences}
      candidateID={candidateID}
      userID={userID}
      educations={educations}
      degreeChoices={degreeChoices}
      goBackUrl={`${variationURL}/choose-path`}
    />
  );
}
