import { Icon } from '@terminal/design-system';

export function CostaRicaFlagIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
        <path
          d="M1 2.82163L10.5 0.5L16 2.82163L24.5 5L31.5 2.82163L33 4.074V6L25.5 8L18.5 7L11.5 4.074L1 6V4.074V2.82163Z"
          fill="#4B71F9"
        />
        <path
          d="m1 23.822 9.5-2.3216 5.5 2.3216 8.5 2.1784 7-2.1784 1.5 1.2524v1.926l-7.5 2-7-1-7-2.926-10.5 1.926v-3.1784z"
          fill="#4B71F9"
        />
        <path
          d="m1 18 9-2 6 1.574 7.5 1.926 8.5-1 1-0.926v6.426l-8.5 1.8125-6.5-1.8125-7-3-5.5 1-4.5 1.5v-5.926 0.426z"
          fill="#fff"
        />
        <path
          d="m1 11.5 9.5-3 5.5 1.5 7.5 4 8-1.926h1.5v7.926l-8.5 2-6.5-2-7-2-5 1-5 1.3125v-8.8125z"
          fill="#F6623A"
        />
        <path
          d="M1 6L10 4L16 5.574L23.5 7.5L32 6.5L33 5.574V12L24.5 13.8125L18 12L11 9L5.5 10L1 11.5V5.574V6Z"
          fill="#fff"
        />
        <path
          d="M1 33V3"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m32 6.5315-3.3737 0.94976c-3.6947 1.0396-8.8536 0.45857-12.126-1.525-3.3542-2.0333-6.2444-2.501-10.008-1.3594l-4.4916 1.3594"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m32 12.164-3.3737 1.1873c-3.6947 1.2995-7.6629 0.6275-10.936-1.8519-3.3542-2.5416-7.4351-3.1806-11.199-1.7536l-4.4916 1.6993"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m31.96 24.406-3.3692 0.9027c-3.6899 0.9881-7.6528 0.4772-10.921-1.408-3.3498-1.9325-7.4252-2.4183-11.184-1.3333l-4.4857 1.292"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m31.96 20.406-3.3692 0.9027c-3.6899 0.9881-7.6528 0.4772-10.921-1.408-3.3498-1.9325-7.4252-2.4183-11.184-1.3333l-4.4857 1.292"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m1 27.057 4.4857-1.2933c3.7591-1.085 7.8345-0.5992 11.184 1.3333 3.2685 1.8852 7.2314 2.3961 10.921 1.408l4.2482-1.1373c0.6867-0.1844 1.1602-0.773 1.1605-1.4427v-21.076c-5e-4 -0.47053-0.2362-0.91364-0.6369-1.197-0.4006-0.28341-0.9198-0.37433-1.4026-0.24562l-3.3692 0.90267c-3.6899 0.98807-7.6528 0.47715-10.921-1.408-3.3498-1.9325-7.4252-2.4183-11.184-1.3333l-4.4857 1.292"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
      <path
        d="M1 33V3M32 6.532l-3.374.95c-3.694 1.04-8.853.458-12.126-1.525-3.354-2.034-6.244-2.501-10.008-1.36L2 5.957M32 12.164l-3.374 1.188c-3.694 1.3-7.663.627-10.935-1.852-3.354-2.542-7.435-3.18-11.2-1.754L2 11.446M31.96 24.406l-3.369.903c-3.69.988-7.652.477-10.921-1.408-3.35-1.933-7.425-2.419-11.184-1.334L2 23.86M31.96 20.406l-3.369.903c-3.69.988-7.652.477-10.921-1.408-3.35-1.933-7.425-2.419-11.184-1.334L2 19.86"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="m1 27.057 4.486-1.294c3.759-1.085 7.834-.599 11.184 1.334 3.268 1.885 7.231 2.396 10.921 1.408l4.248-1.138c.687-.184 1.16-.773 1.161-1.442V4.849c0-.47-.236-.914-.637-1.197-.4-.284-.92-.375-1.402-.246l-3.37.903c-3.69.988-7.652.477-10.921-1.408C13.32.968 9.245.482 5.486 1.567L1 2.86"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
