import * as Yup from 'yup';

export const createDesiredSalaryYupValidation = (withRequiredFields: boolean) => {
  const desiredSalaryAmount = Yup.number()
    .integer('Amount must be an integer number.')
    .positive('Amount must be greater than 0.')
    .max(1000000000000000, 'Amount must be less than 1 quadrillion.')
    .nullable();

  const desiredSalaryCurrency = Yup.string().required('Currency is required.').nullable();

  return withRequiredFields
    ? Yup.object()
        .shape({ desiredSalaryAmount: Yup.number().required('Amount is required.').nullable() })
        .concat(Yup.object().shape({ desiredSalaryAmount, desiredSalaryCurrency }))
    : Yup.object().shape(
        {
          desiredSalaryAmount: desiredSalaryAmount.when('desiredSalaryCurrency', {
            is: (currency: string) => !!currency,
            then: Yup.number().required('Amount is required').nullable(),
            otherwise: Yup.number().nullable(),
          }),
          desiredSalaryCurrency: Yup.string().when('desiredSalaryAmount', {
            is: (amount: number) => amount > 0,
            then: Yup.string().required('Currency is required').nullable(),
            otherwise: Yup.string().nullable(),
          }),
        },
        [['desiredSalaryAmount', 'desiredSalaryCurrency']],
      );
};
