export * from './graphql/types';

export enum ActivityStatus {
  Applied = 'Applied',
  InReview = 'In Review',
  Interview = 'Interview',
  Declined = 'Declined',
  Offer = 'Offer',
  Closed = 'Closed',
  NotInterested = 'Not interested',
}
