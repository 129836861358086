import { serializeForAssessments } from 'candidate/shared/data-layer/SelectAssessments';
import type { AssessmentProps } from '../../shared/data-layer/SelectAssessments/types/index';
import type { Assessments } from './Assessments';
import type { SelectAssessmentsPageQuery } from './types/SelectAssessmentsPage.query.generated';

export function serializerForAssessmentsPage(data: SelectAssessmentsPageQuery): {
  assessments: AssessmentProps[];
  personalizedHeader: React.ComponentProps<typeof Assessments>['personalizedHeader'];
} {
  const { assessments } = serializeForAssessments(data);

  const candidateSkills = data.candidate[0]?.candidate_skills.map(({ skill }) => skill.name);

  // Returns assessments that match a candidate's skill and are not completed
  const assessmentsWithMatchedSkills_NotCompleted = assessments.filter(
    (assessment) =>
      !['Passed', 'Completed'].includes(assessment.status) &&
      candidateSkills?.includes(assessment.name),
  );

  const findAssessmentBySkillName = ({
    skill,
    overwrite,
  }: {
    skill: string;
    overwrite?: Partial<React.ComponentProps<typeof Assessments>['personalizedHeader']>;
  }) => {
    return {
      shouldBeDisplayed: assessmentsWithMatchedSkills_NotCompleted.some(
        (assessment) => assessment.name.toLowerCase() === skill.toLowerCase(),
      ),
      assessment: assessmentsWithMatchedSkills_NotCompleted.find(
        (assessment) => assessment.name.toLowerCase() === skill.toLowerCase(),
      ),
      headerTitle: 'Suggested Test For You',
      headerBody:
        'Reusable across job applications. \n Benchmark your skills against others in your field.',
      headerButtonCTA: `Test ${skill}`,
      card: {
        cardTitle: skill,
        assessmentDuration: assessmentsWithMatchedSkills_NotCompleted.find(
          (assessment) => assessment.name.toLowerCase() === skill.toLowerCase(),
        )?.detailProps.duration as string,
      },
      ...overwrite,
    };
  };

  const personalizeAssessmentsHeaderCopies = [
    {
      ...findAssessmentBySkillName({ skill: 'Ruby' }),
      card: {
        cardTitle: findAssessmentBySkillName({ skill: 'Ruby' }).card.cardTitle,
        cardBody: 'Short coding challenge covering REST APIs and data manipulation.',
        assessmentDuration: findAssessmentBySkillName({ skill: 'Ruby' }).card.assessmentDuration,
      },
    },
    {
      ...findAssessmentBySkillName({ skill: 'Python' }),
      card: {
        cardTitle: findAssessmentBySkillName({ skill: 'Python' }).card.cardTitle,
        cardBody:
          'Multiple-choice Python basics, plus a short coding challenge for UI manipulation.',
        assessmentDuration: findAssessmentBySkillName({ skill: 'Python' }).card.assessmentDuration,
      },
    },
    {
      ...findAssessmentBySkillName({ skill: 'JavaScript' }),
      card: {
        cardTitle: findAssessmentBySkillName({ skill: 'JavaScript' }).card.cardTitle,
        cardBody: 'Multiple-choice covering JavaScript basics, and a Rest API coding challenge.',
        assessmentDuration: findAssessmentBySkillName({ skill: 'JavaScript' }).card
          .assessmentDuration,
      },
    },
    {
      ...findAssessmentBySkillName({ skill: 'React' }),
      card: {
        cardTitle: findAssessmentBySkillName({ skill: 'React' }).card.cardTitle,
        cardBody: 'Short coding challenge for UI setup and data manipulation.',
        assessmentDuration: findAssessmentBySkillName({ skill: 'React' }).card.assessmentDuration,
      },
    },
    // Fallback
    {
      shouldBeDisplayed: true,
      headerTitle: 'Strengthen Your Profile, Take a Test!',
      headerBody:
        'Reusable across job applications. \n Benchmark your skills against others in your field.',
    },
  ].find(({ shouldBeDisplayed }) => shouldBeDisplayed) as React.ComponentProps<
    typeof Assessments
  >['personalizedHeader'];

  return {
    assessments,
    personalizedHeader: personalizeAssessmentsHeaderCopies,
  };
}
