import { Icon } from '@terminal/design-system';

export function ManualQAIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <path
          d="M1 23C1.00127 21.6036 1.32716 20.2267 1.95194 18.9778C2.57671 17.729 3.48326 16.6425 4.59999 15.8042C5.71673 14.9659 7.01307 14.3987 8.38665 14.1473C9.76023 13.896 11.1734 13.9674 12.5147 14.356"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 6.5C4.5 7.95869 5.07946 9.35764 6.11091 10.3891C7.14236 11.4205 8.54131 12 10 12C11.4587 12 12.8576 11.4205 13.8891 10.3891C14.9205 9.35764 15.5 7.95869 15.5 6.5C15.5 5.04131 14.9205 3.64236 13.8891 2.61091C12.8576 1.57946 11.4587 1 10 1C8.54131 1 7.14236 1.57946 6.11091 2.61091C5.07946 3.64236 4.5 5.04131 4.5 6.5Z"
          fill="#A0CFDA"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 21C14 22.8565 14.7375 24.637 16.0503 25.9497C17.363 27.2625 19.1435 28 21 28C22.8565 28 24.637 27.2625 25.9497 25.9497C27.2625 24.637 28 22.8565 28 21C28 19.1435 27.2625 17.363 25.9497 16.0503C24.637 14.7375 22.8565 14 21 14C19.1435 14 17.363 14.7375 16.0503 16.0503C14.7375 17.363 14 19.1435 14 21Z"
          fill="#EFD58B"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.9999 31L25.9492 25.9493"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <path
        d="M1 23C1.00127 21.6036 1.32716 20.2267 1.95194 18.9778C2.57671 17.729 3.48326 16.6425 4.59999 15.8042C5.71673 14.9659 7.01307 14.3987 8.38665 14.1473C9.76023 13.896 11.1734 13.9674 12.5147 14.356"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 6.5C4.5 7.95869 5.07946 9.35764 6.11091 10.3891C7.14236 11.4205 8.54131 12 10 12C11.4587 12 12.8576 11.4205 13.8891 10.3891C14.9205 9.35764 15.5 7.95869 15.5 6.5C15.5 5.04131 14.9205 3.64236 13.8891 2.61091C12.8576 1.57946 11.4587 1 10 1C8.54131 1 7.14236 1.57946 6.11091 2.61091C5.07946 3.64236 4.5 5.04131 4.5 6.5Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 21C14 22.8565 14.7375 24.637 16.0503 25.9497C17.363 27.2625 19.1435 28 21 28C22.8565 28 24.637 27.2625 25.9497 25.9497C27.2625 24.637 28 22.8565 28 21C28 19.1435 27.2625 17.363 25.9497 16.0503C24.637 14.7375 22.8565 14 21 14C19.1435 14 17.363 14.7375 16.0503 16.0503C14.7375 17.363 14 19.1435 14 21Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9999 31L25.9492 25.9493"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
