import { useEffect } from 'react';
import { useCandidateAuth, useExperiment, triggerExperiments } from 'candidate/utils';
import { firebaseAuth } from 'global/firebaseApp';
import { useSignUp } from 'global/auth';
import { BlankScreenLoading } from 'global/components';

import { SignUpStep } from './SignUpStep';
import { VerifyEmailInstruction } from './VerifyEmailInstruction';

export function SignUpController({
  onGoogleLoginClicked,
  continueURL,
  onStepCompleteSuccess,
}: {
  onGoogleLoginClicked: () => void;
  continueURL: string;
  onStepCompleteSuccess: () => void;
}) {
  const auth = useCandidateAuth();
  const experiment = useExperiment();
  const signUpVariation = experiment.signUp;

  useEffect(() => {
    triggerExperiments([51]);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (auth.isAuthorized) {
      if (
        // To work around a race issue where user gets authorized before the
        // createAuth..putUser function is resolved. Since non-anonymous user
        // will always have first_name, we can wait until first_name is in auth user
        auth.user?.first_name
      ) {
        onStepCompleteSuccess();
      } else {
        // Handling the edge where user main now have the first_name. we don't want the user
        // get stock on seeing the loading bar if for one reason or other they don't have first_name
        timer = setTimeout(() => {
          onStepCompleteSuccess();
        }, 4000);
      }
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthorized, auth?.user?.first_name]);

  const {
    handleSignUpSubmit,
    isLoading,
    isResolved: signUpIsResolved,
    shouldHideGoogleSSO,
  } = useSignUp({
    continueURL,

    // Since onboarding pre signs in users with firebase anonymous signin, they will have
    // candidate role and they wont have anonymous role
    contextRole: 'candidate',
  });

  if (!firebaseAuth.currentUser?.isAnonymous && auth.isAuthenticated && !auth.isAuthorized) {
    return <VerifyEmailInstruction />;
  }

  if (signUpIsResolved || auth.isAuthorized) {
    return <BlankScreenLoading aria-label="Loading" />;
  }

  if (['beginning-solo', 'beginning-pre-sign-up'].includes(signUpVariation)) {
    return (
      <SignUpStep.Variation.WithSignUpAtBeginning
        onEmailSignSubmitted={handleSignUpSubmit}
        onGoogleLoginClicked={onGoogleLoginClicked}
        isLoading={isLoading}
        shouldHideGoogleSSO={shouldHideGoogleSSO}
      />
    );
  }

  if (['end-blurred-info'].includes(signUpVariation)) {
    return (
      <SignUpStep.Variation.WithBlurredInfo
        onEmailSignSubmitted={handleSignUpSubmit}
        onGoogleLoginClicked={onGoogleLoginClicked}
        isLoading={isLoading}
        shouldHideGoogleSSO={shouldHideGoogleSSO}
      />
    );
  }

  return (
    <SignUpStep.Variation.WithPerkList
      onEmailSignSubmitted={handleSignUpSubmit}
      onGoogleLoginClicked={onGoogleLoginClicked}
      isLoading={isLoading}
      shouldHideGoogleSSO={shouldHideGoogleSSO}
    />
  );
}
