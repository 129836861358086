import { useState } from 'react';
import type { HStepsProps } from 'candidate/components';
import type { Experiment } from 'candidate/utils';
import { supportedCountries } from 'candidate/shared';
import type { ProfileProps } from '../../../../Profile.types';
import { AddLocationStep } from './AddLocationStep';
import { useHandleLocationTypeahead } from './Location.hooks';

const addLocationVariationMap: Record<
  Experiment['onboardingLocation'],
  | typeof AddLocationStep.Variation.CheckboxCountrySelection
  | typeof AddLocationStep.Variation.InputCitySelection
> = {
  'checkbox-country-selection': AddLocationStep.Variation.CheckboxCountrySelection,
  'input-city-selection': AddLocationStep.Variation.InputCitySelection,
};

const titleAndDescriptionVariationMap: Record<
  Experiment['onboardingLocation'],
  (props: { isConfirmStep: any }) => {
    title: string;
    description: string;
  }
> = {
  'checkbox-country-selection': ({ isConfirmStep }) => ({
    title: isConfirmStep ? 'One last thing. Where are you located?' : 'Where Are You Located?',
    description: isConfirmStep
      ? 'It looks like we weren’t able to find your location on your resume. Please choose your country below so we can find the best roles for you'
      : `We are currently hiring in the countries below. Let us know if you live elsewhere, we are always looking to expand!`,
  }),
  'input-city-selection': ({ isConfirmStep }) => ({
    title: isConfirmStep
      ? 'One last thing. What city are you located in?'
      : 'What City Are You Located In?',
    description: isConfirmStep
      ? 'It looks like we weren’t able to find your location on your resume. Entering your specific location below helps us match you to relevant jobs within your country.'
      : 'Your specific location helps us match you to relevant jobs within your country.',
  }),
};

export function AddLocationStepController({
  candidateCountry,
  country_id,
  candidateID,
  formattedAddress,
  onSignOutClick,
  onStepCompleteSuccess,
  progressProps,
  userFullName,
  /**
   * Indicates when the location was not parsed from the resuming.
   */
  isConfirmStep,
  experienceVariation,
}: {
  candidateCountry: ProfileProps['candidate']['personalInfo']['location'];
  country_id: ProfileProps['candidate']['personalInfo']['country_id'];
  candidateID: number;
  formattedAddress: string;
  onSignOutClick: () => Promise<void>;
  onStepCompleteSuccess: () => void;
  progressProps: HStepsProps;
  userFullName: string;
  isConfirmStep: boolean;
  experienceVariation?: Experiment['onboardingLocation'];
}) {
  const initialSelectedCountry =
    candidateCountry && supportedCountries.includes(candidateCountry) ? candidateCountry : 'Other';

  const [selectedCountry, setSelectedCountry] = useState<string | null>(
    candidateCountry ? initialSelectedCountry : null,
  );
  const [selectedCountryID, setSelectedCountryID] = useState<number | null>(country_id || null);

  const {
    formik,
    handleLocationChange,
    isGooglePlaceAPIReady,
    isLoading_updatePersonalInfo,
    isLoading_normalizedCountries,
    countryNameToIDMap,
    placeIDToCountryIDMap,
  } = useHandleLocationTypeahead({
    candidateID,
    initialFormValues: {
      country: selectedCountry && selectedCountry !== 'Other' ? selectedCountry : '',
      country_id: selectedCountryID,
    },
    onUpdateComplete: onStepCompleteSuccess,
  });

  const handleOnCheckboxClick = (selectedLocation: string) => {
    const countryID = countryNameToIDMap[selectedLocation.toLowerCase()] || null;
    setSelectedCountryID(countryID);
    setSelectedCountry(selectedLocation);
    if (selectedLocation === 'Other') {
      formik.resetForm();
    } else {
      formik.setFieldValue('country_id', countryID);
      formik.setFieldValue('country', selectedLocation);
    }
  };

  const AddLocationStepVariation =
    addLocationVariationMap[experienceVariation as Experiment['onboardingLocation']] ||
    addLocationVariationMap['checkbox-country-selection'];

  return (
    <AddLocationStepVariation
      isTypeaheadReady={isGooglePlaceAPIReady === 'ready' && !isLoading_normalizedCountries}
      progressProps={progressProps}
      selectedLocation={selectedCountry}
      locationOptions={supportedCountries}
      // TODO: [CAND-335] use Yup and formik.isValid to determine if next is disabled or not
      isNextDisabled={isLoading_updatePersonalInfo || !formik.values.country}
      onCheckboxClick={handleOnCheckboxClick}
      onNextClick={formik.handleSubmit}
      onSignOutClick={onSignOutClick}
      userFullName={userFullName}
      shouldShowTypeahead={selectedCountry === 'Other'}
      onLocationBlur={formik.handleBlur}
      onLocationChange={handleLocationChange}
      typeaheadDefault={initialSelectedCountry === 'Other' ? formattedAddress : ''}
      placeIDToCountryIDMap={placeIDToCountryIDMap}
      errors={formik.errors}
      touched={formik.touched}
      title={
        titleAndDescriptionVariationMap[experienceVariation as Experiment['onboardingLocation']]?.({
          isConfirmStep,
        })?.title || ''
      }
      description={
        titleAndDescriptionVariationMap[experienceVariation as Experiment['onboardingLocation']]?.({
          isConfirmStep,
        })?.description || ''
      }
    />
  );
}
