import type { FormikErrors } from 'formik';
import {
  Box,
  CheckboxCard,
  Text,
  CheckboxGroup,
  Grid,
  Button,
  FormControl,
  FormErrorMessage,
} from '@terminal/design-system';
import { roleIcons } from '../../../../shared/modules/roles';
import type { ProfileProps } from '../../Profile.types';

export function RolesForm({
  values,
  rolesOptions,
  errors,
  onCheckboxClick,
  onSaveClick,
  showSaveButton,
  showLoadingForSaveButton,
}: {
  values: { candidateRoles: ProfileProps['candidate']['roles'][number]['formValues'][] };
  rolesOptions: ProfileProps['roleChoices'];
  onCheckboxClick: (role: string) => void;
  onSaveClick: () => void;
  showSaveButton: boolean;
  showLoadingForSaveButton: boolean;
  errors: FormikErrors<{
    candidateRoles: ProfileProps['candidate']['roles'][number]['formValues'][];
  }>;
}) {
  return (
    <FormControl isInvalid={!!errors}>
      <Box w="full" className="notranslate">
        <CheckboxGroup>
          <Grid templateColumns="repeat(auto-fill, minmax(183px, 1fr))" gap={4}>
            {rolesOptions.map(({ name: roleChoice, displayName }) => {
              const isSelected = values.candidateRoles.some(
                (selectedRole) => selectedRole.role === roleChoice,
              );
              const icon = roleIcons({ role: roleChoice, isColored: isSelected });

              if (!icon) return null;

              return (
                <CheckboxCard
                  role="checkbox"
                  aria-checked={isSelected}
                  aria-labelledby="candidate-role"
                  onChange={() => onCheckboxClick(roleChoice)}
                  isChecked={isSelected}
                  key={`insider-role-${roleChoice}`}
                  checkboxIcon={icon}
                >
                  <Text>{displayName}</Text>
                </CheckboxCard>
              );
            })}
          </Grid>
        </CheckboxGroup>
        <FormErrorMessage>
          {/* TODO (web-split) Fix this error validation. Make sure it displays correctly */}
          {Array.isArray(errors?.candidateRoles) ? errors?.candidateRoles.join(',') : undefined}
        </FormErrorMessage>
        {showSaveButton && (
          <Button
            flex={1}
            variant="primary"
            type="submit"
            onClick={onSaveClick}
            isLoading={showLoadingForSaveButton}
            mt={6}
          >
            Save
          </Button>
        )}
      </Box>
    </FormControl>
  );
}
