import { Route, useRouteMatch } from 'react-router-dom';
import { ErrorBoundary } from 'global/sentry';
import {
  ErrorFallback,
  PublicPageLayout,
  SwitchWithPageNotFound,
  ExceptionPage,
  ScrollToTopOnPathChange,
} from 'candidate/components';
import friendlyErrorImageSrc from './assets/friendly-error.svg?url';
import { DirectApplicationController } from './DirectApplication.controller';

export function DirectApplicationRouter() {
  const { path } = useRouteMatch();

  return (
    <ErrorBoundary
      fallback={
        <ErrorFallback
          onActionClick={() => window.location.reload()}
          actionTitle="Go back to Edit Job Application"
        />
      }
    >
      <ScrollToTopOnPathChange />
      <SwitchWithPageNotFound>
        <Route path={`${path}/not-found`}>
          <PublicPageLayout>
            <ExceptionPage
              title="Error!"
              subTitle="Job Not Found!"
              imageSrc={friendlyErrorImageSrc}
              description="We are sorry, but the job you requested is no longer active. Please return to the job board to view all of our current job openings."
            />
          </PublicPageLayout>
        </Route>
        <Route path={`${path}/:jobID`}>
          <DirectApplicationController />
        </Route>
      </SwitchWithPageNotFound>
    </ErrorBoundary>
  );
}
