import { useEducationsController } from 'candidate/features/profile/modules/educations';
import type { ProfileProps } from 'candidate/features/profile/Profile.types';
import { useDisclosure } from '@terminal/design-system';
import { Educations } from './Educations';

export function EducationsController({
  candidateID,
  degreeChoices,
  educations,
  userID,
}: {
  candidateID: number;
  degreeChoices: ProfileProps['degreeChoices'];
  educations: ProfileProps['candidate']['educations'];
  userID?: number;
}) {
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  const {
    deleteEducation,
    editID,
    formik,
    handleAddClick,
    handleEditClick,
    handleOnClose,
    handleOnSaveClick,
    hasFormValuesChanged,
    initialRef,
    isLoading_delete,
    isLoading_upsert,
  } = useEducationsController({
    onModalOpen,
    onModalClose,
    educations,
    userID,
    candidateID,
  });

  return (
    <Educations
      degreeChoices={degreeChoices}
      editID={editID}
      errors={formik.errors}
      touched={formik.touched}
      initialRef={initialRef}
      isModalOpen={isModalOpen}
      onAddClick={handleAddClick}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      onCloseModalClick={handleOnClose}
      onDeleteClick={(deleteID: number) =>
        deleteEducation({
          variables: {
            id: deleteID,
          },
        })
      }
      educations={educations}
      onEditClick={handleEditClick}
      onSaveClick={handleOnSaveClick}
      shouldAskConfirmationOnModalClose={hasFormValuesChanged()}
      showLoadingForDeleteButton={isLoading_delete}
      showLoadingForSaveButton={isLoading_upsert}
      values={formik.values}
    />
  );
}
