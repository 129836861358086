import { Grid, Box, Button, useToast } from '@terminal/design-system';
import type { DesiredSalaryInformation } from '../../Profile.types';
import { useDesiredSalaryController, DesiredSalaryForm } from '../../modules/desired-salary';

export function DesiredSalaryController({
  userID,
  candidateID,
  desiredSalaryData,
}: {
  candidateID: number;
  userID?: number;
  desiredSalaryData: DesiredSalaryInformation;
}) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });
  const { hasFormValuesChanged, isLoading_upsert, formik } = useDesiredSalaryController({
    desiredSalaryData,
    userID,
    candidateID,
    onSaveClick: () => {
      toast({
        title: 'Saved Successfully!',
        description: 'Your changes were saved successfully.',
        status: 'success',
      });
    },
    fieldsAreRequired: false,
  });

  return (
    <Box>
      <Grid gap={[3, 3, 4]} templateColumns={['7rem 1fr', '7rem 1fr', '10.688rem 12.188rem']}>
        <DesiredSalaryForm
          errors={formik.errors}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          values={formik.values}
          touched={formik.touched}
        />
      </Grid>
      {hasFormValuesChanged() && (
        <Button
          flex={1}
          variant="primary"
          type="submit"
          onClick={() => formik.handleSubmit()}
          isLoading={isLoading_upsert}
          mt={6}
        >
          Save
        </Button>
      )}
    </Box>
  );
}
