import { Button, VStack, Flex, Box, Divider, Text } from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';
import type { ReactNode, ComponentProps } from 'react';
import type { HStepsProps } from 'candidate/components';
import { Link as RouterLink } from 'react-router-dom';
import type { ProfileProps } from '../../../../Profile.types';
import { StepPageLayout } from '../../components/StepPageLayout';
import { WorkExperiencesController } from './work-experiences/WorkExperiences.controller';
import { EducationsController } from './educations/Educations.controller';

export function ExperiencesManualEntryBase({
  isNextDisabled,
  onNextClick,
  onSignOutClick,
  progressProps,
  title,
  userFullName,
  workExperiences,
  candidateID,
  userID,
  educations,
  degreeChoices,
  footer,
}: {
  isNextDisabled: boolean;
  onNextClick: () => void;
  onSignOutClick: () => Promise<void>;
  progressProps: HStepsProps;
  title: string;
  userFullName: string;
  workExperiences: ProfileProps['candidate']['workExperiences'];
  candidateID: number;
  userID?: number;
  educations: ProfileProps['candidate']['educations'];
  degreeChoices: ProfileProps['degreeChoices'];
  footer?: ReactNode;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <StepPageLayout
      progressProps={progressProps}
      title={title}
      description="Please include at least one previous role. The more complete your background, the better your job matches will be!"
      userFullName={userFullName}
      onSignOutClick={onSignOutClick}
      footer={
        footer || (
          <Flex flexDir={['column', 'row-reverse']} w="full">
            <Button
              onClick={onNextClick}
              w={['full', 64]}
              mb={[3, 0]}
              display={!isDesktop || !isNextDisabled ? 'default' : 'none'}
              isDisabled={isNextDisabled}
            >
              Next
            </Button>
            <Button
              onClick={onNextClick}
              variant="ghost"
              margin="auto"
              display={isNextDisabled ? 'default' : 'none'}
            >
              I Don’t Have Experience
            </Button>
          </Flex>
        )
      }
    >
      <VStack spacing={[6, 6, 10]} alignItems="flex-start" justifyContent="stretch">
        <Box w="full">
          <Box mt={[6, 6, 10]}>
            <Text variant="label" casing="uppercase" color="text.disabled">
              Work Experience
            </Text>
          </Box>
          <Box mt={[4, 4, 6]}>
            <WorkExperiencesController
              candidateID={candidateID}
              userID={userID}
              workExperiences={workExperiences}
            />
          </Box>
        </Box>
        <Box w="full">
          <Divider />
          <Box mt={[6, 6, 10]}>
            <Text variant="label" casing="uppercase" color="text.disabled">
              Education
            </Text>
          </Box>
          <Box mt={[4, 4, 6]}>
            <EducationsController
              candidateID={candidateID}
              userID={userID}
              educations={educations}
              degreeChoices={degreeChoices}
            />
          </Box>
        </Box>
      </VStack>
    </StepPageLayout>
  );
}

export function ExperiencesManualEntrySeparated(
  props: ComponentProps<typeof ExperiencesManualEntryBase>,
) {
  return <ExperiencesManualEntryBase {...props} />;
}

export function ExperiencesManualEntryCombined({
  educations,
  workExperiences,
  onNextClick,
  isNextDisabled,
  goBackUrl,
  ...props
}: { goBackUrl: string } & ComponentProps<typeof ExperiencesManualEntryBase>) {
  return (
    <ExperiencesManualEntryBase
      {...props}
      educations={educations}
      onNextClick={onNextClick}
      workExperiences={workExperiences}
      isNextDisabled={isNextDisabled}
      footer={
        <Flex
          flexDir={['column-reverse', 'column-reverse', 'row']}
          justify={['flex-start', 'flex-start', 'flex-end']}
          w="full"
        >
          <Button
            as={RouterLink}
            w={['full', 'full', 22]}
            variant="ghost"
            mr={[0, 0, 4]}
            to={goBackUrl}
          >
            Go Back
          </Button>
          <Button
            onClick={onNextClick}
            w={['full', 'full', 14]}
            mb={[3, 3, 0]}
            isDisabled={isNextDisabled}
          >
            Finalize
          </Button>
        </Flex>
      }
    />
  );
}

export const ExperiencesManualEntry = {
  Variation: {
    Separated: ExperiencesManualEntrySeparated,
    Combined: ExperiencesManualEntryCombined,
  },
};
