import {
  Candidate_Curation_Desired_Salary_Currency_Choices_Enum,
  Candidate_Curation_Workflow_Status_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Interview_Type_Choices_Enum,
  Employment_Type_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { uniqWith } from 'ramda';
import { convertFromMinutesToHuman } from 'global/utils';
import type {
  JobSuggestionProps,
  JobDetailDataProps,
  SelectCurrencyExchangeRatesQuery,
  SelectCandidateQuery,
} from 'candidate/shared/modules';
import { duplicatedTechStackMappings } from 'candidate/shared/modules/job/JobDetail/JobDetail.job.serializer';
import { markupPercentage } from 'candidate/utils';
import type { SelectCandidateApplicableJobsForBrowsingQuery } from '../../shared/modules/job/data/types/SelectCandidateApplicableJobsForBrowsing.query.generated';
import { JobYearsOfExperienceIDEnum } from './Job.types';

const formatCurrency = ({
  currencySymbol,
  isoCode,
  isSuffix,
  moneyAmount,
}: {
  currencySymbol: string;
  isoCode?: string;
  isSuffix?: boolean;
  moneyAmount: number;
}) => {
  // If number is > 999, adds the letter `K or M` (as thousand and millions)
  const formatter = Intl.NumberFormat('en', { notation: 'compact' }).format;

  // The `replace` add a space between the letters (e.g 1.8M -> 1.8 M)
  return `${isoCode || ''} ${!isSuffix ? currencySymbol : ''}${formatter(
    Math.floor(moneyAmount),
  ).replace(/[A-Z]/g, ' $&')}${isSuffix ? ` ${currencySymbol}` : ''}`;
};

export const createSalaryRangeCTA = ({
  salaryRange,
  candidateCountry,
  conversionTable,
}: {
  salaryRange: {
    minSalary: number;
    maxSalary: number;
    currencySymbol: string;
    isSuffix: boolean;
  };
  candidateCountry: string;
  conversionTable: SelectCurrencyExchangeRatesQuery['exchange_rates'] | undefined;
}): string | null => {
  const currency = conversionTable?.find(
    ({ country_choice }) =>
      country_choice?.name?.toLowerCase().trim() === candidateCountry?.toLowerCase().trim(),
  );
  if (!currency) return null;

  // Return null if multiplication is NaN
  if (
    [salaryRange?.maxSalary, salaryRange?.maxSalary, currency.finance_exchange_rate].some(
      (value) => !Number.isFinite(value) || value === 0,
    )
  ) {
    return null;
  }

  return `${formatCurrency({
    // Due business decisions, We display CR (Costa Rica's) salaries in `USD`, not `CRC`
    isoCode:
      currency.iso_code === 'CRC'
        ? Candidate_Curation_Desired_Salary_Currency_Choices_Enum.Usd
        : currency.iso_code,
    currencySymbol: salaryRange.currencySymbol,
    isSuffix: salaryRange.isSuffix,
    // TODO: [TP-1473] Fix this
    // eslint-disable-next-line no-unsafe-optional-chaining
    moneyAmount: salaryRange?.minSalary * currency.finance_exchange_rate,
  })} -${formatCurrency({
    currencySymbol: salaryRange.currencySymbol,
    isSuffix: salaryRange.isSuffix,
    // TODO: [TP-1473] Fix this
    // eslint-disable-next-line no-unsafe-optional-chaining
    moneyAmount: salaryRange?.maxSalary * currency.finance_exchange_rate,
  })}/year`;
};

export const createContractorRateCTA = ({
  salaryRange,
}: {
  salaryRange: {
    minSalary: number;
    maxSalary: number;
    currencySymbol: string;
    isSuffix: boolean;
  };
}): string | null => {
  if (
    [salaryRange?.maxSalary, salaryRange?.maxSalary].some(
      (value) => !Number.isFinite(value) || value === 0,
    )
  ) {
    return null;
  }

  return `${formatCurrency({
    isoCode: Candidate_Curation_Desired_Salary_Currency_Choices_Enum.Usd,
    currencySymbol: salaryRange.currencySymbol,
    isSuffix: salaryRange.isSuffix,
    // eslint-disable-next-line no-unsafe-optional-chaining -- we're already checking is not 0 at upper validation
    moneyAmount: salaryRange?.minSalary / markupPercentage,
  })} -${formatCurrency({
    currencySymbol: salaryRange.currencySymbol,
    isSuffix: salaryRange.isSuffix,
    // eslint-disable-next-line no-unsafe-optional-chaining -- we're already checking is not 0 at upper validation
    moneyAmount: salaryRange?.maxSalary / markupPercentage,
  })}/month`;
};

export const findSalaryRangeByLocation = ({
  candidateCountry,
  jobSalaries,
}: {
  candidateCountry?: string;
  jobSalaries:
    | NonNullable<
        SelectCandidateApplicableJobsForBrowsingQuery['icims_job'][number]['job']
      >['job_meta_infos'][number]
    | undefined;
}) => {
  switch (candidateCountry?.toLowerCase().trim()) {
    case 'mexico':
    case 'chile':
    case 'colombia':
    case 'costa rica':
      return {
        minSalary: jobSalaries?.latam_min_salary,
        maxSalary: jobSalaries?.latam_max_salary,
        currencySymbol: '\u0024', // '$',
        isSuffix: false,
      };

    case 'spain':
      return {
        minSalary: jobSalaries?.europe_min_salary,
        maxSalary: jobSalaries?.europe_max_salary,
        currencySymbol: '\u20AC', // '€'
        isSuffix: false,
      };

    case 'poland':
      return {
        minSalary: jobSalaries?.europe_min_salary,
        maxSalary: jobSalaries?.europe_max_salary,
        currencySymbol: 'zł', // Unicode does not exist
        isSuffix: true,
      };

    case 'romania':
      return {
        minSalary: jobSalaries?.europe_min_salary,
        maxSalary: jobSalaries?.europe_max_salary,
        currencySymbol: 'lei', // Leu is the singular and Lei is the plural. 1 leu, 2 Lei
        isSuffix: true,
      };

    case 'hungary':
      return {
        minSalary: jobSalaries?.europe_min_salary,
        maxSalary: jobSalaries?.europe_max_salary,
        currencySymbol: 'Ft', // Unicode does not exist
        isSuffix: true,
      };

    case 'canada':
      return {
        minSalary: jobSalaries?.canada_min_salary,
        maxSalary: jobSalaries?.canada_max_salary,
        currencySymbol: '\u0024', // '$',
        isSuffix: false,
      };

    default:
      return null;
  }
};

// Tech stack + required skills
export const normalizedTechStackWithSkills = ({
  requiredSkills,
  techStack,
}: {
  requiredSkills: {
    skill1: SelectCandidateApplicableJobsForBrowsingQuery['icims_job'][0]['skillByRequiredSkill1'];
    skill2: SelectCandidateApplicableJobsForBrowsingQuery['icims_job'][0]['skillByRequiredSkill2'];
    skill3: SelectCandidateApplicableJobsForBrowsingQuery['icims_job'][0]['skillByRequiredSkill3'];
  };
  techStack: string | null;
}) =>
  uniqWith(
    (a, b) => a.toLowerCase() === b.toLowerCase(),
    [
      ...[
        requiredSkills.skill1?.name,
        requiredSkills.skill2?.name,
        requiredSkills.skill3?.name,
        ...(techStack?.split(',') || []),
        // Empty skill returns 'N/A'
      ].filter((skill) => skill !== 'N/A' && skill),
    ]
      // Normalize data (e.g ['node.js', 'node'] => ['nodeJS'])
      .map((current: string) => {
        const skillDuplicateMap = duplicatedTechStackMappings.find(({ stacks }) => {
          return stacks.includes(current?.toLowerCase().trim());
        });
        return skillDuplicateMap ? skillDuplicateMap.mapTo : current?.trim();
      }),
  ).filter(Boolean);

export const findYearsOfExperienceByID = ({ id }: { id: JobYearsOfExperienceIDEnum }) => {
  switch (id) {
    case JobYearsOfExperienceIDEnum.ZERO_TWO:
      return Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo;

    case JobYearsOfExperienceIDEnum.TWO_FIVE:
      return Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive;

    case JobYearsOfExperienceIDEnum.FIVE_TEN:
      return Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen;

    case JobYearsOfExperienceIDEnum.TEN_PLUS:
      return Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus;
    default:
      return null;
  }
};

const yearsOfExperienceOptions = [
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus,
];

export const hasCandidateEnoughExperience = ({
  candidateYearsOfExperience,
  jobsYearsOfExperience,
}: {
  candidateYearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null | undefined;
  jobsYearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum[];
}) => {
  const yearsOfExperienceIndex = yearsOfExperienceOptions.findIndex(
    (option) => option === candidateYearsOfExperience,
  );
  // Adding previous, current and next years of experience to candidate's years
  // e.g a 2-5YoE dev meets a [0-2, 2-5, 5-10]YoE job requirement
  const candidateYearsOfExperienceArray = yearsOfExperienceOptions
    .slice()
    .splice(
      yearsOfExperienceIndex === 0 ? 0 : yearsOfExperienceIndex - 1,
      yearsOfExperienceIndex + 2,
    );

  return jobsYearsOfExperience?.some((element) =>
    candidateYearsOfExperienceArray.includes(element),
  );
};

export const createYearsOfExperienceCopy = ({
  candidateYearsOfExperience,
  jobsYearsOfExperience,
}: {
  candidateYearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null | undefined;
  jobsYearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum[];
}) => {
  const jobsYearsOfExperienceSorted = jobsYearsOfExperience.sort(
    (a, b) => yearsOfExperienceOptions.indexOf(a) - yearsOfExperienceOptions.indexOf(b),
  );

  if (!hasCandidateEnoughExperience({ candidateYearsOfExperience, jobsYearsOfExperience }))
    return null;

  switch (true) {
    case candidateYearsOfExperience === Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo:
      return '0-2 years experience';

    case jobsYearsOfExperienceSorted.includes(
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive,
    ) || candidateYearsOfExperience === Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive:
      return '2+ years experience';

    case jobsYearsOfExperienceSorted.includes(
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen,
    ) || candidateYearsOfExperience === Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen:
      return '5+ years experience';

    case jobsYearsOfExperienceSorted.includes(
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus,
    ) || candidateYearsOfExperience === Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus:
      return '10+ years experience';

    default:
      return null;
  }
};

export const convertToEmploymentTypeCopy = (type?: Employment_Type_Choices_Enum | null) => {
  return (
    {
      [Employment_Type_Choices_Enum.Contract]: 'Contractor',
      [Employment_Type_Choices_Enum.FullTime]: 'Full-time',
      // This enum value is for Candidate preferences only, but in case we get this for a Job the fallback is FullTime
      [Employment_Type_Choices_Enum.FullTimeAndContract]: 'Full-time',
    }[type as Employment_Type_Choices_Enum] || null
  );
};

export const convertToHumanInterviewType = (type: Interview_Type_Choices_Enum) => {
  return (
    {
      [Interview_Type_Choices_Enum.HiringManagerInterview as Interview_Type_Choices_Enum]:
        'Hiring manager interview',
      [Interview_Type_Choices_Enum.TechnicalAssessmentHome]: 'Technical assessment - take home',
      [Interview_Type_Choices_Enum.TechnicalInterview]: 'Technical interview',
      [Interview_Type_Choices_Enum.VirtualOnsite]: 'Virtual onsite',
      [Interview_Type_Choices_Enum.FinalInterview]: 'Final Interview',
    }[type] || null
  );
};

export function serializeJobDetails({
  job,
  applicant_jobs,
  candidate_jobs,
  candidateInfo,
  conversionTable,
}: {
  job: SelectCandidateApplicableJobsForBrowsingQuery['icims_job'][0];
  applicant_jobs: SelectCandidateApplicableJobsForBrowsingQuery['applicant_job'];
  candidate_jobs: SelectCandidateApplicableJobsForBrowsingQuery['candidate_job'];
  candidateInfo?: {
    country:
      | Exclude<SelectCandidateQuery['candidate'][number]['country_choice'], null>['name']
      | null;
    talentPartnerEmail: string | null | undefined;
    candidateSkills: SelectCandidateQuery['candidate'][number]['candidate_skills'][number]['skill']['name'];
    yearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null | undefined;
    furthestCandidateCurationWorkflow:
      | NonNullable<
          SelectCandidateQuery['candidate'][number]['furthest_candidate_curation_workflow']
        >['status']
      | null;
  } | null;
  conversionTable: SelectCurrencyExchangeRatesQuery['exchange_rates'] | undefined;
}): JobSuggestionProps {
  const jobOrganization = job.icims_company?.organizations[0];

  const jobSalary =
    candidateInfo?.country && job?.job?.employment_type !== Employment_Type_Choices_Enum.Contract
      ? createSalaryRangeCTA({
          salaryRange: findSalaryRangeByLocation({
            candidateCountry: candidateInfo?.country,
            jobSalaries: job.job?.job_meta_infos[0],
          })!,
          candidateCountry: candidateInfo?.country,
          conversionTable,
        })
      : createContractorRateCTA({
          salaryRange: {
            minSalary: job.job?.min_contract_rate,
            maxSalary: job.job?.max_contract_rate,
            currencySymbol: '\u0024', // '$',
            isSuffix: false,
          },
        });

  const jobRequiredSkills = [
    job.skillByRequiredSkill1?.name,
    job.skillByRequiredSkill2?.name,
    job.skillByRequiredSkill3?.name,
  ];

  const techStack = normalizedTechStackWithSkills({
    requiredSkills: {
      skill1: job.skillByRequiredSkill1,
      skill2: job.skillByRequiredSkill2,
      skill3: job.skillByRequiredSkill3,
    },
    techStack: job.tech_stack,
  });

  const isTopApplicant =
    !!jobRequiredSkills.filter(Boolean).length &&
    // Check if candidate matches ALL 3 job required skills
    jobRequiredSkills
      .filter(Boolean)
      .every((requiredSkill) =>
        candidateInfo?.candidateSkills?.filter(Boolean).includes(requiredSkill),
      ) &&
    // Check if candidate has enough years of experience
    hasCandidateEnoughExperience({
      candidateYearsOfExperience: candidateInfo?.yearsOfExperience,
      jobsYearsOfExperience: job.years_experience?.map(
        ({ id }: { id: JobYearsOfExperienceIDEnum }) => findYearsOfExperienceByID({ id }),
      ),
    });

  return {
    id: job.profile_id,
    title: job.icims_company?.organizations[0]?.name || null,
    image: {
      src: job.icims_company?.organizations[0]?.logo || null,
      alt: job.icims_company?.organizations[0]?.name || null,
    },
    subtitle: job.jobtitle || null,
    skills: normalizedTechStackWithSkills({
      requiredSkills: {
        skill1: job.skillByRequiredSkill1,
        skill2: job.skillByRequiredSkill2,
        skill3: job.skillByRequiredSkill3,
      },
      techStack: job.tech_stack,
    }),
    applied:
      applicant_jobs.some(({ job: jobID }) => jobID === job.profile_id) ||
      candidate_jobs?.some(({ job: jobID }) => jobID === job.profile_id) ||
      false,
    salary: jobSalary,
    jobDetail: {
      title: job.jobtitle || null,
      logo: jobOrganization?.logo
        ? {
            src: jobOrganization?.logo,
            alt: job.jobtitle || '',
          }
        : null,
      // Includes job's tech_stack and 3 required skills
      techStacks: techStack.map((stack) => {
        return {
          stack,
          shouldHighlight:
            isTopApplicant &&
            !!candidateInfo?.candidateSkills?.find(
              (candidateSkill: string) =>
                stack.toLowerCase().trim() === candidateSkill.toLowerCase().trim(),
            ),
        };
      }),
      // @ts-ignore -> ignoring since type ward is not detecting the filter(Boolean) function
      location: job.icims_job_locations
        .map((location) => location.icims_location?.value || null)
        .filter(Boolean),
      descriptiveInfos: [
        {
          title: 'About The Role',
          description: job?.about,
        },
        {
          title: 'What You’ll Do',
          description: job?.what_youll_do,
        },
        {
          title: 'What You’ll Bring',
          description: job?.what_you_bring,
        },
      ].filter((info) => info.description) as JobDetailDataProps['descriptiveInfos'],
      tagInfos: [
        {
          title: 'Company Size',
          description: job.icims_company?.numofemps?.toString(),
        },
      ].filter((info) => info.description) as JobDetailDataProps['tagInfos'],
      companyName: job.icims_company?.organizations[0]?.name,
      organizationID: job.icims_company?.organizations[0]?.id,
      industry: jobOrganization?.salesforce_customer?.industry || null,
      salary: jobSalary,
      employmentType: convertToEmploymentTypeCopy(job?.job?.employment_type),
      talentPartnerEmail: candidateInfo?.talentPartnerEmail,
      topApplicantInfo: {
        isTopApplicant,
        numberOfSkillsMatched: isTopApplicant
          ? techStack.filter((stack) => candidateInfo?.candidateSkills?.indexOf(stack) !== -1)
              .length
          : null,
        yearsOfExperience: isTopApplicant
          ? createYearsOfExperienceCopy({
              candidateYearsOfExperience: candidateInfo?.yearsOfExperience,
              jobsYearsOfExperience: job.years_experience?.map(
                ({ id }: { id: JobYearsOfExperienceIDEnum }) => findYearsOfExperienceByID({ id }),
              ),
            })
          : null,
      },
      interviewDetails:
        candidateInfo?.furthestCandidateCurationWorkflow ===
        Candidate_Curation_Workflow_Status_Choices_Enum.Accepted
          ? job.job?.interview_details
              ?.map((details) => {
                return {
                  title: convertToHumanInterviewType(details.type),
                  description: details.description,
                  duration: convertFromMinutesToHuman(details.length),
                  order: details.order,
                };
              })
              .sort((interviewA, interviewB) => interviewA.order - interviewB.order) || []
          : [],
    },
  };
}
