import { cloneElement } from 'react';
import { Box, Circle, Text, createIcon } from '@terminal/design-system';

function CircleWrapper({
  isClickable,
  children,
  onClick,
}: {
  isClickable: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}) {
  return isClickable ? (
    <button onClick={onClick} type="button">
      {children}
    </button>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
}

const CheckBoldIcon = createIcon({
  displayName: 'CheckBoldIcon',
  viewBox: '0 0 10 10',
  path: cloneElement(
    <path
      d="M8.75 1.25 3.72 8.436a.733.733 0 0 1-1.19.02L1.25 6.75"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    {
      key: 'CheckBoldIcon',
      stroke: 'currentColor',
      fill: 'none',
    },
  ),
});

export function Step({
  labelForSightedUser,
  children,
  state,
  onClick,
}: {
  state: 'active' | 'complete' | 'incomplete';
  labelForSightedUser?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}) {
  const isCompleted = state === 'complete';
  const isIncomplete = state === 'incomplete';

  return (
    <Box as="li" display="inline-flex">
      <CircleWrapper isClickable={onClick !== undefined && state === 'complete'} onClick={onClick}>
        <>
          <Circle
            aria-hidden
            zIndex={1}
            position="relative"
            size="6"
            bg={!isIncomplete ? 'accent.main' : 'ui.disabled'}
          >
            {!isCompleted ? (
              <Text fontSize="sm" color={isIncomplete ? 'text.secondary' : 'text.inverse'}>
                {children}
              </Text>
            ) : (
              <CheckBoldIcon color="text.inverse" fontSize="x-small" />
            )}
          </Circle>
          <Box srOnly>
            {isCompleted ? `${labelForSightedUser} - Completed` : labelForSightedUser}
          </Box>
        </>
      </CircleWrapper>
    </Box>
  );
}
