import type { SelectReferralInfoQuery } from './Referral.types';

export const candidateReferralAmount = '$1,000';
export const memberReferralAmount = '$5,000';

export function serializerForReferral(data: SelectReferralInfoQuery): {
  terminalIOCandidateReferralURL: string;
  messageToCopy: string;
  isMember: boolean;
  referralAmount: string;
} {
  if (data.candidate.length === 0) {
    throw new Error('Candidate not found');
  }

  const candidate = data.candidate[0] as SelectReferralInfoQuery['candidate'][number];

  // Determine if a user is member
  const isMember =
    !!candidate.dayforce_employee?.dayforce_assignments &&
    candidate.dayforce_employee?.dayforce_assignments?.length > 0;
  const referralAmount = isMember ? memberReferralAmount : candidateReferralAmount;

  const terminalIOCandidateReferralURL = encodeURI(
    `https://terminal.io/referral?utm_source=referral&utm_campaign=${candidate.id}&name=${candidate.first_name} ${candidate.last_name}`,
  );

  return {
    terminalIOCandidateReferralURL,
    messageToCopy: `Come join me at Terminal! Sign up today and receive ${referralAmount} when you land a job 🚀 ${terminalIOCandidateReferralURL}`,
    isMember,
    referralAmount,
  };
}
