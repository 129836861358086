export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  bytea: any;
  citext: any;
  date: any;
  float8: any;
  geography: any;
  geometry: any;
  json: any;
  jsonb: any;
  numeric: any;
  oid: any;
  smallint: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type ExploreCandidatesArgs = {
  badges?: InputMaybe<Scalars['jsonb']>;
  candidate_role?: InputMaybe<Scalars['String']>;
  employment_type?: InputMaybe<Scalars['String']>;
  min_years_of_experience?: InputMaybe<Scalars['String']>;
  must_have_skill_ids?: InputMaybe<Scalars['jsonb']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  regions?: InputMaybe<Scalars['jsonb']>;
  should_have_skill_ids?: InputMaybe<Scalars['jsonb']>;
  sort_option?: InputMaybe<Scalars['String']>;
  top_candidates_criteria?: InputMaybe<Scalars['String']>;
};

export type ExploreCandidatesOutput = {
  __typename?: 'ExploreCandidatesOutput';
  candidate: Maybe<Candidate>;
  candidate_id: Scalars['Int'];
  candidate_profile_updated_at: Maybe<Scalars['timestamptz']>;
  has_in_demand: Scalars['Boolean'];
  has_mutual_interest: Scalars['Boolean'];
  has_top_company_exp: Scalars['Boolean'];
  has_years_tech_lead_exp: Scalars['Boolean'];
  score: Scalars['numeric'];
  total_count: Scalars['Int'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['Int']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['Int']>>;
  _eq?: InputMaybe<Array<Scalars['Int']>>;
  _gt?: InputMaybe<Array<Scalars['Int']>>;
  _gte?: InputMaybe<Array<Scalars['Int']>>;
  _in?: InputMaybe<Array<Array<Scalars['Int']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['Int']>>;
  _lte?: InputMaybe<Array<Scalars['Int']>>;
  _neq?: InputMaybe<Array<Scalars['Int']>>;
  _nin?: InputMaybe<Array<Array<Scalars['Int']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Stores information about the activity of customers viewing candidate profile */
export type Activity_Customer_Profile_View = {
  __typename?: 'activity_customer_profile_view';
  /** An object relationship */
  candidate: Maybe<Candidate>;
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  user_id: Scalars['Int'];
  /** An object relationship */
  user_info: User_Info;
};

/** aggregated selection of "activity_customer_profile_view" */
export type Activity_Customer_Profile_View_Aggregate = {
  __typename?: 'activity_customer_profile_view_aggregate';
  aggregate: Maybe<Activity_Customer_Profile_View_Aggregate_Fields>;
  nodes: Array<Activity_Customer_Profile_View>;
};

/** aggregate fields of "activity_customer_profile_view" */
export type Activity_Customer_Profile_View_Aggregate_Fields = {
  __typename?: 'activity_customer_profile_view_aggregate_fields';
  avg: Maybe<Activity_Customer_Profile_View_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Activity_Customer_Profile_View_Max_Fields>;
  min: Maybe<Activity_Customer_Profile_View_Min_Fields>;
  stddev: Maybe<Activity_Customer_Profile_View_Stddev_Fields>;
  stddev_pop: Maybe<Activity_Customer_Profile_View_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Activity_Customer_Profile_View_Stddev_Samp_Fields>;
  sum: Maybe<Activity_Customer_Profile_View_Sum_Fields>;
  var_pop: Maybe<Activity_Customer_Profile_View_Var_Pop_Fields>;
  var_samp: Maybe<Activity_Customer_Profile_View_Var_Samp_Fields>;
  variance: Maybe<Activity_Customer_Profile_View_Variance_Fields>;
};

/** aggregate fields of "activity_customer_profile_view" */
export type Activity_Customer_Profile_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Activity_Customer_Profile_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Activity_Customer_Profile_View_Avg_Fields = {
  __typename?: 'activity_customer_profile_view_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "activity_customer_profile_view". All fields are combined with a logical 'AND'. */
export type Activity_Customer_Profile_View_Bool_Exp = {
  _and?: InputMaybe<Array<Activity_Customer_Profile_View_Bool_Exp>>;
  _not?: InputMaybe<Activity_Customer_Profile_View_Bool_Exp>;
  _or?: InputMaybe<Array<Activity_Customer_Profile_View_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "activity_customer_profile_view" */
export enum Activity_Customer_Profile_View_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActivityCustomerProfileViewPkey = 'activity_customer_profile_view_pkey',
}

/** input type for incrementing numeric columns in table "activity_customer_profile_view" */
export type Activity_Customer_Profile_View_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "activity_customer_profile_view" */
export type Activity_Customer_Profile_View_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Activity_Customer_Profile_View_Max_Fields = {
  __typename?: 'activity_customer_profile_view_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  user_id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Activity_Customer_Profile_View_Min_Fields = {
  __typename?: 'activity_customer_profile_view_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  user_id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "activity_customer_profile_view" */
export type Activity_Customer_Profile_View_Mutation_Response = {
  __typename?: 'activity_customer_profile_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Activity_Customer_Profile_View>;
};

/** on_conflict condition type for table "activity_customer_profile_view" */
export type Activity_Customer_Profile_View_On_Conflict = {
  constraint: Activity_Customer_Profile_View_Constraint;
  update_columns?: Array<Activity_Customer_Profile_View_Update_Column>;
  where?: InputMaybe<Activity_Customer_Profile_View_Bool_Exp>;
};

/** Ordering options when selecting data from "activity_customer_profile_view". */
export type Activity_Customer_Profile_View_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: activity_customer_profile_view */
export type Activity_Customer_Profile_View_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "activity_customer_profile_view" */
export enum Activity_Customer_Profile_View_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "activity_customer_profile_view" */
export type Activity_Customer_Profile_View_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Activity_Customer_Profile_View_Stddev_Fields = {
  __typename?: 'activity_customer_profile_view_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Activity_Customer_Profile_View_Stddev_Pop_Fields = {
  __typename?: 'activity_customer_profile_view_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Activity_Customer_Profile_View_Stddev_Samp_Fields = {
  __typename?: 'activity_customer_profile_view_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "activity_customer_profile_view" */
export type Activity_Customer_Profile_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Activity_Customer_Profile_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Activity_Customer_Profile_View_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Activity_Customer_Profile_View_Sum_Fields = {
  __typename?: 'activity_customer_profile_view_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  user_id: Maybe<Scalars['Int']>;
};

/** update columns of table "activity_customer_profile_view" */
export enum Activity_Customer_Profile_View_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

export type Activity_Customer_Profile_View_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Activity_Customer_Profile_View_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Activity_Customer_Profile_View_Set_Input>;
  /** filter the rows which have to be updated */
  where: Activity_Customer_Profile_View_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Activity_Customer_Profile_View_Var_Pop_Fields = {
  __typename?: 'activity_customer_profile_view_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Activity_Customer_Profile_View_Var_Samp_Fields = {
  __typename?: 'activity_customer_profile_view_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Activity_Customer_Profile_View_Variance_Fields = {
  __typename?: 'activity_customer_profile_view_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** Store AI levels with codes, names, and associated job levels */
export type Ai_Level = {
  __typename?: 'ai_level';
  code: Scalars['String'];
  id: Scalars['Int'];
  job_level: Maybe<Job_Level_Choices_Enum>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

/** aggregated selection of "ai_level" */
export type Ai_Level_Aggregate = {
  __typename?: 'ai_level_aggregate';
  aggregate: Maybe<Ai_Level_Aggregate_Fields>;
  nodes: Array<Ai_Level>;
};

export type Ai_Level_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ai_Level_Aggregate_Bool_Exp_Count>;
};

export type Ai_Level_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ai_Level_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ai_Level_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ai_level" */
export type Ai_Level_Aggregate_Fields = {
  __typename?: 'ai_level_aggregate_fields';
  avg: Maybe<Ai_Level_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Ai_Level_Max_Fields>;
  min: Maybe<Ai_Level_Min_Fields>;
  stddev: Maybe<Ai_Level_Stddev_Fields>;
  stddev_pop: Maybe<Ai_Level_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Ai_Level_Stddev_Samp_Fields>;
  sum: Maybe<Ai_Level_Sum_Fields>;
  var_pop: Maybe<Ai_Level_Var_Pop_Fields>;
  var_samp: Maybe<Ai_Level_Var_Samp_Fields>;
  variance: Maybe<Ai_Level_Variance_Fields>;
};

/** aggregate fields of "ai_level" */
export type Ai_Level_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Level_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ai_level" */
export type Ai_Level_Aggregate_Order_By = {
  avg?: InputMaybe<Ai_Level_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Level_Max_Order_By>;
  min?: InputMaybe<Ai_Level_Min_Order_By>;
  stddev?: InputMaybe<Ai_Level_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ai_Level_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ai_Level_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ai_Level_Sum_Order_By>;
  var_pop?: InputMaybe<Ai_Level_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ai_Level_Var_Samp_Order_By>;
  variance?: InputMaybe<Ai_Level_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ai_level" */
export type Ai_Level_Arr_Rel_Insert_Input = {
  data: Array<Ai_Level_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Level_On_Conflict>;
};

/** aggregate avg on columns */
export type Ai_Level_Avg_Fields = {
  __typename?: 'ai_level_avg_fields';
  id: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ai_level" */
export type Ai_Level_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ai_level". All fields are combined with a logical 'AND'. */
export type Ai_Level_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Level_Bool_Exp>>;
  _not?: InputMaybe<Ai_Level_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Level_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job_level?: InputMaybe<Job_Level_Choices_Enum_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai_level" */
export enum Ai_Level_Constraint {
  /** unique or primary key constraint on columns "code", "name" */
  AiLevelCodeNameKey = 'ai_level_code_name_key',
  /** unique or primary key constraint on columns "id" */
  AiLevelPkey = 'ai_level_pkey',
}

/** input type for incrementing numeric columns in table "ai_level" */
export type Ai_Level_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ai_level" */
export type Ai_Level_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  job_level?: InputMaybe<Job_Level_Choices_Enum>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Ai_Level_Max_Fields = {
  __typename?: 'ai_level_max_fields';
  code: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  order: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ai_level" */
export type Ai_Level_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ai_Level_Min_Fields = {
  __typename?: 'ai_level_min_fields';
  code: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  order: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ai_level" */
export type Ai_Level_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ai_level" */
export type Ai_Level_Mutation_Response = {
  __typename?: 'ai_level_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Level>;
};

/** input type for inserting object relation for remote table "ai_level" */
export type Ai_Level_Obj_Rel_Insert_Input = {
  data: Ai_Level_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Level_On_Conflict>;
};

/** on_conflict condition type for table "ai_level" */
export type Ai_Level_On_Conflict = {
  constraint: Ai_Level_Constraint;
  update_columns?: Array<Ai_Level_Update_Column>;
  where?: InputMaybe<Ai_Level_Bool_Exp>;
};

/** Ordering options when selecting data from "ai_level". */
export type Ai_Level_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_level?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai_level */
export type Ai_Level_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ai_level" */
export enum Ai_Level_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  JobLevel = 'job_level',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
}

/** input type for updating data in table "ai_level" */
export type Ai_Level_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  job_level?: InputMaybe<Job_Level_Choices_Enum>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Ai_Level_Stddev_Fields = {
  __typename?: 'ai_level_stddev_fields';
  id: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ai_level" */
export type Ai_Level_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ai_Level_Stddev_Pop_Fields = {
  __typename?: 'ai_level_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ai_level" */
export type Ai_Level_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ai_Level_Stddev_Samp_Fields = {
  __typename?: 'ai_level_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ai_level" */
export type Ai_Level_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ai_level" */
export type Ai_Level_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Level_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Level_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  job_level?: InputMaybe<Job_Level_Choices_Enum>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Ai_Level_Sum_Fields = {
  __typename?: 'ai_level_sum_fields';
  id: Maybe<Scalars['Int']>;
  order: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ai_level" */
export type Ai_Level_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** update columns of table "ai_level" */
export enum Ai_Level_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  JobLevel = 'job_level',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
}

export type Ai_Level_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Level_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Level_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Level_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Level_Var_Pop_Fields = {
  __typename?: 'ai_level_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ai_level" */
export type Ai_Level_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ai_Level_Var_Samp_Fields = {
  __typename?: 'ai_level_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ai_level" */
export type Ai_Level_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ai_Level_Variance_Fields = {
  __typename?: 'ai_level_variance_fields';
  id: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ai_level" */
export type Ai_Level_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** candidate, job, or work experience roles */
export type Ai_Role = {
  __typename?: 'ai_role';
  /** An object relationship */
  ai_role_choice: Maybe<Role_Choices>;
  id: Scalars['Int'];
  name: Scalars['String'];
  parent: Maybe<Scalars['Int']>;
  role: Maybe<Role_Choices_Enum>;
};

/** aggregated selection of "ai_role" */
export type Ai_Role_Aggregate = {
  __typename?: 'ai_role_aggregate';
  aggregate: Maybe<Ai_Role_Aggregate_Fields>;
  nodes: Array<Ai_Role>;
};

/** aggregate fields of "ai_role" */
export type Ai_Role_Aggregate_Fields = {
  __typename?: 'ai_role_aggregate_fields';
  avg: Maybe<Ai_Role_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Ai_Role_Max_Fields>;
  min: Maybe<Ai_Role_Min_Fields>;
  stddev: Maybe<Ai_Role_Stddev_Fields>;
  stddev_pop: Maybe<Ai_Role_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Ai_Role_Stddev_Samp_Fields>;
  sum: Maybe<Ai_Role_Sum_Fields>;
  var_pop: Maybe<Ai_Role_Var_Pop_Fields>;
  var_samp: Maybe<Ai_Role_Var_Samp_Fields>;
  variance: Maybe<Ai_Role_Variance_Fields>;
};

/** aggregate fields of "ai_role" */
export type Ai_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ai_Role_Avg_Fields = {
  __typename?: 'ai_role_avg_fields';
  id: Maybe<Scalars['Float']>;
  parent: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ai_role". All fields are combined with a logical 'AND'. */
export type Ai_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Role_Bool_Exp>>;
  _not?: InputMaybe<Ai_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Role_Bool_Exp>>;
  ai_role_choice?: InputMaybe<Role_Choices_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent?: InputMaybe<Int_Comparison_Exp>;
  role?: InputMaybe<Role_Choices_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai_role" */
export enum Ai_Role_Constraint {
  /** unique or primary key constraint on columns "parent", "name" */
  AiRoleNameParentKey = 'ai_role_name_parent_key',
  /** unique or primary key constraint on columns "id" */
  AiRolePkey = 'ai_role_pkey',
}

/** input type for incrementing numeric columns in table "ai_role" */
export type Ai_Role_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  parent?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ai_role" */
export type Ai_Role_Insert_Input = {
  ai_role_choice?: InputMaybe<Role_Choices_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Role_Choices_Enum>;
};

/** aggregate max on columns */
export type Ai_Role_Max_Fields = {
  __typename?: 'ai_role_max_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  parent: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Ai_Role_Min_Fields = {
  __typename?: 'ai_role_min_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  parent: Maybe<Scalars['Int']>;
};

/** Dynamic focus multiplier value to be used towards scoring candidates in curation step. */
export type Ai_Role_Multiplier_Focus = {
  __typename?: 'ai_role_multiplier_focus';
  id: Scalars['Int'];
  multiplier: Scalars['numeric'];
  role: Role_Choices_Enum;
};

/** aggregated selection of "ai_role_multiplier_focus" */
export type Ai_Role_Multiplier_Focus_Aggregate = {
  __typename?: 'ai_role_multiplier_focus_aggregate';
  aggregate: Maybe<Ai_Role_Multiplier_Focus_Aggregate_Fields>;
  nodes: Array<Ai_Role_Multiplier_Focus>;
};

/** aggregate fields of "ai_role_multiplier_focus" */
export type Ai_Role_Multiplier_Focus_Aggregate_Fields = {
  __typename?: 'ai_role_multiplier_focus_aggregate_fields';
  avg: Maybe<Ai_Role_Multiplier_Focus_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Ai_Role_Multiplier_Focus_Max_Fields>;
  min: Maybe<Ai_Role_Multiplier_Focus_Min_Fields>;
  stddev: Maybe<Ai_Role_Multiplier_Focus_Stddev_Fields>;
  stddev_pop: Maybe<Ai_Role_Multiplier_Focus_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Ai_Role_Multiplier_Focus_Stddev_Samp_Fields>;
  sum: Maybe<Ai_Role_Multiplier_Focus_Sum_Fields>;
  var_pop: Maybe<Ai_Role_Multiplier_Focus_Var_Pop_Fields>;
  var_samp: Maybe<Ai_Role_Multiplier_Focus_Var_Samp_Fields>;
  variance: Maybe<Ai_Role_Multiplier_Focus_Variance_Fields>;
};

/** aggregate fields of "ai_role_multiplier_focus" */
export type Ai_Role_Multiplier_Focus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Role_Multiplier_Focus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ai_Role_Multiplier_Focus_Avg_Fields = {
  __typename?: 'ai_role_multiplier_focus_avg_fields';
  id: Maybe<Scalars['Float']>;
  multiplier: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ai_role_multiplier_focus". All fields are combined with a logical 'AND'. */
export type Ai_Role_Multiplier_Focus_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Role_Multiplier_Focus_Bool_Exp>>;
  _not?: InputMaybe<Ai_Role_Multiplier_Focus_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Role_Multiplier_Focus_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  multiplier?: InputMaybe<Numeric_Comparison_Exp>;
  role?: InputMaybe<Role_Choices_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai_role_multiplier_focus" */
export enum Ai_Role_Multiplier_Focus_Constraint {
  /** unique or primary key constraint on columns "id" */
  AiRoleMultiplierFocusPkey = 'ai_role_multiplier_focus_pkey',
  /** unique or primary key constraint on columns "role" */
  AiRoleMultiplierFocusRoleKey = 'ai_role_multiplier_focus_role_key',
}

/** input type for incrementing numeric columns in table "ai_role_multiplier_focus" */
export type Ai_Role_Multiplier_Focus_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  multiplier?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ai_role_multiplier_focus" */
export type Ai_Role_Multiplier_Focus_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  multiplier?: InputMaybe<Scalars['numeric']>;
  role?: InputMaybe<Role_Choices_Enum>;
};

/** aggregate max on columns */
export type Ai_Role_Multiplier_Focus_Max_Fields = {
  __typename?: 'ai_role_multiplier_focus_max_fields';
  id: Maybe<Scalars['Int']>;
  multiplier: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Ai_Role_Multiplier_Focus_Min_Fields = {
  __typename?: 'ai_role_multiplier_focus_min_fields';
  id: Maybe<Scalars['Int']>;
  multiplier: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "ai_role_multiplier_focus" */
export type Ai_Role_Multiplier_Focus_Mutation_Response = {
  __typename?: 'ai_role_multiplier_focus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Role_Multiplier_Focus>;
};

/** input type for inserting object relation for remote table "ai_role_multiplier_focus" */
export type Ai_Role_Multiplier_Focus_Obj_Rel_Insert_Input = {
  data: Ai_Role_Multiplier_Focus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Role_Multiplier_Focus_On_Conflict>;
};

/** on_conflict condition type for table "ai_role_multiplier_focus" */
export type Ai_Role_Multiplier_Focus_On_Conflict = {
  constraint: Ai_Role_Multiplier_Focus_Constraint;
  update_columns?: Array<Ai_Role_Multiplier_Focus_Update_Column>;
  where?: InputMaybe<Ai_Role_Multiplier_Focus_Bool_Exp>;
};

/** Ordering options when selecting data from "ai_role_multiplier_focus". */
export type Ai_Role_Multiplier_Focus_Order_By = {
  id?: InputMaybe<Order_By>;
  multiplier?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai_role_multiplier_focus */
export type Ai_Role_Multiplier_Focus_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ai_role_multiplier_focus" */
export enum Ai_Role_Multiplier_Focus_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  Role = 'role',
}

/** input type for updating data in table "ai_role_multiplier_focus" */
export type Ai_Role_Multiplier_Focus_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  multiplier?: InputMaybe<Scalars['numeric']>;
  role?: InputMaybe<Role_Choices_Enum>;
};

/** aggregate stddev on columns */
export type Ai_Role_Multiplier_Focus_Stddev_Fields = {
  __typename?: 'ai_role_multiplier_focus_stddev_fields';
  id: Maybe<Scalars['Float']>;
  multiplier: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ai_Role_Multiplier_Focus_Stddev_Pop_Fields = {
  __typename?: 'ai_role_multiplier_focus_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  multiplier: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ai_Role_Multiplier_Focus_Stddev_Samp_Fields = {
  __typename?: 'ai_role_multiplier_focus_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  multiplier: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ai_role_multiplier_focus" */
export type Ai_Role_Multiplier_Focus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Role_Multiplier_Focus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Role_Multiplier_Focus_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  multiplier?: InputMaybe<Scalars['numeric']>;
  role?: InputMaybe<Role_Choices_Enum>;
};

/** aggregate sum on columns */
export type Ai_Role_Multiplier_Focus_Sum_Fields = {
  __typename?: 'ai_role_multiplier_focus_sum_fields';
  id: Maybe<Scalars['Int']>;
  multiplier: Maybe<Scalars['numeric']>;
};

/** update columns of table "ai_role_multiplier_focus" */
export enum Ai_Role_Multiplier_Focus_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  Role = 'role',
}

export type Ai_Role_Multiplier_Focus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Role_Multiplier_Focus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Role_Multiplier_Focus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Role_Multiplier_Focus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Role_Multiplier_Focus_Var_Pop_Fields = {
  __typename?: 'ai_role_multiplier_focus_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  multiplier: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ai_Role_Multiplier_Focus_Var_Samp_Fields = {
  __typename?: 'ai_role_multiplier_focus_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  multiplier: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ai_Role_Multiplier_Focus_Variance_Fields = {
  __typename?: 'ai_role_multiplier_focus_variance_fields';
  id: Maybe<Scalars['Float']>;
  multiplier: Maybe<Scalars['Float']>;
};

/** response of any mutation on the table "ai_role" */
export type Ai_Role_Mutation_Response = {
  __typename?: 'ai_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Role>;
};

/** input type for inserting object relation for remote table "ai_role" */
export type Ai_Role_Obj_Rel_Insert_Input = {
  data: Ai_Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Role_On_Conflict>;
};

/** on_conflict condition type for table "ai_role" */
export type Ai_Role_On_Conflict = {
  constraint: Ai_Role_Constraint;
  update_columns?: Array<Ai_Role_Update_Column>;
  where?: InputMaybe<Ai_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "ai_role". */
export type Ai_Role_Order_By = {
  ai_role_choice?: InputMaybe<Role_Choices_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai_role */
export type Ai_Role_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ai_role" */
export enum Ai_Role_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Parent = 'parent',
  /** column name */
  Role = 'role',
}

/** input type for updating data in table "ai_role" */
export type Ai_Role_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Role_Choices_Enum>;
};

/** aggregate stddev on columns */
export type Ai_Role_Stddev_Fields = {
  __typename?: 'ai_role_stddev_fields';
  id: Maybe<Scalars['Float']>;
  parent: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ai_Role_Stddev_Pop_Fields = {
  __typename?: 'ai_role_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  parent: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ai_Role_Stddev_Samp_Fields = {
  __typename?: 'ai_role_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  parent: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ai_role" */
export type Ai_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Role_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Role_Choices_Enum>;
};

/** aggregate sum on columns */
export type Ai_Role_Sum_Fields = {
  __typename?: 'ai_role_sum_fields';
  id: Maybe<Scalars['Int']>;
  parent: Maybe<Scalars['Int']>;
};

/** update columns of table "ai_role" */
export enum Ai_Role_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Parent = 'parent',
  /** column name */
  Role = 'role',
}

export type Ai_Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Role_Var_Pop_Fields = {
  __typename?: 'ai_role_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  parent: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ai_Role_Var_Samp_Fields = {
  __typename?: 'ai_role_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  parent: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ai_Role_Variance_Fields = {
  __typename?: 'ai_role_variance_fields';
  id: Maybe<Scalars['Float']>;
  parent: Maybe<Scalars['Float']>;
};

/** Log all the runs done through accessing one of the llms through langchain. */
export type Ai_Run_Dump = {
  __typename?: 'ai_run_dump';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  response: Scalars['jsonb'];
  type: Scalars['String'];
  version: Scalars['String'];
};

/** Log all the runs done through accessing one of the llms through langchain. */
export type Ai_Run_DumpResponseArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "ai_run_dump" */
export type Ai_Run_Dump_Aggregate = {
  __typename?: 'ai_run_dump_aggregate';
  aggregate: Maybe<Ai_Run_Dump_Aggregate_Fields>;
  nodes: Array<Ai_Run_Dump>;
};

/** aggregate fields of "ai_run_dump" */
export type Ai_Run_Dump_Aggregate_Fields = {
  __typename?: 'ai_run_dump_aggregate_fields';
  avg: Maybe<Ai_Run_Dump_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Ai_Run_Dump_Max_Fields>;
  min: Maybe<Ai_Run_Dump_Min_Fields>;
  stddev: Maybe<Ai_Run_Dump_Stddev_Fields>;
  stddev_pop: Maybe<Ai_Run_Dump_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Ai_Run_Dump_Stddev_Samp_Fields>;
  sum: Maybe<Ai_Run_Dump_Sum_Fields>;
  var_pop: Maybe<Ai_Run_Dump_Var_Pop_Fields>;
  var_samp: Maybe<Ai_Run_Dump_Var_Samp_Fields>;
  variance: Maybe<Ai_Run_Dump_Variance_Fields>;
};

/** aggregate fields of "ai_run_dump" */
export type Ai_Run_Dump_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Run_Dump_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ai_Run_Dump_Append_Input = {
  response?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Ai_Run_Dump_Avg_Fields = {
  __typename?: 'ai_run_dump_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ai_run_dump". All fields are combined with a logical 'AND'. */
export type Ai_Run_Dump_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Run_Dump_Bool_Exp>>;
  _not?: InputMaybe<Ai_Run_Dump_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Run_Dump_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  response?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai_run_dump" */
export enum Ai_Run_Dump_Constraint {
  /** unique or primary key constraint on columns "id" */
  AiRunDumpPkey = 'ai_run_dump_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ai_Run_Dump_Delete_At_Path_Input = {
  response?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ai_Run_Dump_Delete_Elem_Input = {
  response?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ai_Run_Dump_Delete_Key_Input = {
  response?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "ai_run_dump" */
export type Ai_Run_Dump_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ai_run_dump" */
export type Ai_Run_Dump_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ai_Run_Dump_Max_Fields = {
  __typename?: 'ai_run_dump_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  type: Maybe<Scalars['String']>;
  version: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ai_Run_Dump_Min_Fields = {
  __typename?: 'ai_run_dump_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  type: Maybe<Scalars['String']>;
  version: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ai_run_dump" */
export type Ai_Run_Dump_Mutation_Response = {
  __typename?: 'ai_run_dump_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Run_Dump>;
};

/** on_conflict condition type for table "ai_run_dump" */
export type Ai_Run_Dump_On_Conflict = {
  constraint: Ai_Run_Dump_Constraint;
  update_columns?: Array<Ai_Run_Dump_Update_Column>;
  where?: InputMaybe<Ai_Run_Dump_Bool_Exp>;
};

/** Ordering options when selecting data from "ai_run_dump". */
export type Ai_Run_Dump_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai_run_dump */
export type Ai_Run_Dump_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ai_Run_Dump_Prepend_Input = {
  response?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "ai_run_dump" */
export enum Ai_Run_Dump_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Response = 'response',
  /** column name */
  Type = 'type',
  /** column name */
  Version = 'version',
}

/** input type for updating data in table "ai_run_dump" */
export type Ai_Run_Dump_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ai_Run_Dump_Stddev_Fields = {
  __typename?: 'ai_run_dump_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ai_Run_Dump_Stddev_Pop_Fields = {
  __typename?: 'ai_run_dump_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ai_Run_Dump_Stddev_Samp_Fields = {
  __typename?: 'ai_run_dump_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ai_run_dump" */
export type Ai_Run_Dump_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Run_Dump_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Run_Dump_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ai_Run_Dump_Sum_Fields = {
  __typename?: 'ai_run_dump_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "ai_run_dump" */
export enum Ai_Run_Dump_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Response = 'response',
  /** column name */
  Type = 'type',
  /** column name */
  Version = 'version',
}

export type Ai_Run_Dump_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ai_Run_Dump_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ai_Run_Dump_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ai_Run_Dump_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ai_Run_Dump_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Run_Dump_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ai_Run_Dump_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Run_Dump_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Run_Dump_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Run_Dump_Var_Pop_Fields = {
  __typename?: 'ai_run_dump_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ai_Run_Dump_Var_Samp_Fields = {
  __typename?: 'ai_run_dump_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ai_Run_Dump_Variance_Fields = {
  __typename?: 'ai_run_dump_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "applicant_consent" */
export type Applicant_Consent = {
  __typename?: 'applicant_consent';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
};

/** aggregated selection of "applicant_consent" */
export type Applicant_Consent_Aggregate = {
  __typename?: 'applicant_consent_aggregate';
  aggregate: Maybe<Applicant_Consent_Aggregate_Fields>;
  nodes: Array<Applicant_Consent>;
};

export type Applicant_Consent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Applicant_Consent_Aggregate_Bool_Exp_Count>;
};

export type Applicant_Consent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Applicant_Consent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Applicant_Consent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "applicant_consent" */
export type Applicant_Consent_Aggregate_Fields = {
  __typename?: 'applicant_consent_aggregate_fields';
  avg: Maybe<Applicant_Consent_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Applicant_Consent_Max_Fields>;
  min: Maybe<Applicant_Consent_Min_Fields>;
  stddev: Maybe<Applicant_Consent_Stddev_Fields>;
  stddev_pop: Maybe<Applicant_Consent_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Applicant_Consent_Stddev_Samp_Fields>;
  sum: Maybe<Applicant_Consent_Sum_Fields>;
  var_pop: Maybe<Applicant_Consent_Var_Pop_Fields>;
  var_samp: Maybe<Applicant_Consent_Var_Samp_Fields>;
  variance: Maybe<Applicant_Consent_Variance_Fields>;
};

/** aggregate fields of "applicant_consent" */
export type Applicant_Consent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Applicant_Consent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "applicant_consent" */
export type Applicant_Consent_Aggregate_Order_By = {
  avg?: InputMaybe<Applicant_Consent_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Applicant_Consent_Max_Order_By>;
  min?: InputMaybe<Applicant_Consent_Min_Order_By>;
  stddev?: InputMaybe<Applicant_Consent_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Applicant_Consent_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Applicant_Consent_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Applicant_Consent_Sum_Order_By>;
  var_pop?: InputMaybe<Applicant_Consent_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Applicant_Consent_Var_Samp_Order_By>;
  variance?: InputMaybe<Applicant_Consent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "applicant_consent" */
export type Applicant_Consent_Arr_Rel_Insert_Input = {
  data: Array<Applicant_Consent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Applicant_Consent_On_Conflict>;
};

/** aggregate avg on columns */
export type Applicant_Consent_Avg_Fields = {
  __typename?: 'applicant_consent_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "applicant_consent" */
export type Applicant_Consent_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "applicant_consent". All fields are combined with a logical 'AND'. */
export type Applicant_Consent_Bool_Exp = {
  _and?: InputMaybe<Array<Applicant_Consent_Bool_Exp>>;
  _not?: InputMaybe<Applicant_Consent_Bool_Exp>;
  _or?: InputMaybe<Array<Applicant_Consent_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "applicant_consent" */
export enum Applicant_Consent_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApplicantConsentPkey = 'applicant_consent_pkey',
}

/** input type for incrementing numeric columns in table "applicant_consent" */
export type Applicant_Consent_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "applicant_consent" */
export type Applicant_Consent_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Applicant_Consent_Max_Fields = {
  __typename?: 'applicant_consent_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "applicant_consent" */
export type Applicant_Consent_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Applicant_Consent_Min_Fields = {
  __typename?: 'applicant_consent_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "applicant_consent" */
export type Applicant_Consent_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "applicant_consent" */
export type Applicant_Consent_Mutation_Response = {
  __typename?: 'applicant_consent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Applicant_Consent>;
};

/** on_conflict condition type for table "applicant_consent" */
export type Applicant_Consent_On_Conflict = {
  constraint: Applicant_Consent_Constraint;
  update_columns?: Array<Applicant_Consent_Update_Column>;
  where?: InputMaybe<Applicant_Consent_Bool_Exp>;
};

/** Ordering options when selecting data from "applicant_consent". */
export type Applicant_Consent_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: applicant_consent */
export type Applicant_Consent_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "applicant_consent" */
export enum Applicant_Consent_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "applicant_consent" */
export type Applicant_Consent_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Applicant_Consent_Stddev_Fields = {
  __typename?: 'applicant_consent_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "applicant_consent" */
export type Applicant_Consent_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Applicant_Consent_Stddev_Pop_Fields = {
  __typename?: 'applicant_consent_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "applicant_consent" */
export type Applicant_Consent_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Applicant_Consent_Stddev_Samp_Fields = {
  __typename?: 'applicant_consent_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "applicant_consent" */
export type Applicant_Consent_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "applicant_consent" */
export type Applicant_Consent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Applicant_Consent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Applicant_Consent_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Applicant_Consent_Sum_Fields = {
  __typename?: 'applicant_consent_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "applicant_consent" */
export type Applicant_Consent_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "applicant_consent" */
export enum Applicant_Consent_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
}

export type Applicant_Consent_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Applicant_Consent_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Applicant_Consent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Applicant_Consent_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Applicant_Consent_Var_Pop_Fields = {
  __typename?: 'applicant_consent_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "applicant_consent" */
export type Applicant_Consent_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Applicant_Consent_Var_Samp_Fields = {
  __typename?: 'applicant_consent_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "applicant_consent" */
export type Applicant_Consent_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Applicant_Consent_Variance_Fields = {
  __typename?: 'applicant_consent_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "applicant_consent" */
export type Applicant_Consent_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "applicant_job" */
export type Applicant_Job = {
  __typename?: 'applicant_job';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  cid: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  form_submission_url: Maybe<Scalars['String']>;
  /** An array relationship */
  icims_jobs: Array<Icims_Job>;
  /** An aggregate relationship */
  icims_jobs_aggregate: Icims_Job_Aggregate;
  /** An object relationship */
  icims_profile_job: Icims_Profile_Job;
  job: Scalars['Int'];
  referring_url: Maybe<Scalars['String']>;
  sync_status: Icims_Sync_Status_Choices_Enum;
  utm_campaign: Maybe<Scalars['String']>;
  utm_content: Maybe<Scalars['String']>;
  utm_medium: Maybe<Scalars['String']>;
  utm_source: Maybe<Scalars['String']>;
  utm_term: Maybe<Scalars['String']>;
};

/** columns and relationships of "applicant_job" */
export type Applicant_JobIcims_JobsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

/** columns and relationships of "applicant_job" */
export type Applicant_JobIcims_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

/** aggregated selection of "applicant_job" */
export type Applicant_Job_Aggregate = {
  __typename?: 'applicant_job_aggregate';
  aggregate: Maybe<Applicant_Job_Aggregate_Fields>;
  nodes: Array<Applicant_Job>;
};

export type Applicant_Job_Aggregate_Bool_Exp = {
  count?: InputMaybe<Applicant_Job_Aggregate_Bool_Exp_Count>;
};

export type Applicant_Job_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Applicant_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Applicant_Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "applicant_job" */
export type Applicant_Job_Aggregate_Fields = {
  __typename?: 'applicant_job_aggregate_fields';
  avg: Maybe<Applicant_Job_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Applicant_Job_Max_Fields>;
  min: Maybe<Applicant_Job_Min_Fields>;
  stddev: Maybe<Applicant_Job_Stddev_Fields>;
  stddev_pop: Maybe<Applicant_Job_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Applicant_Job_Stddev_Samp_Fields>;
  sum: Maybe<Applicant_Job_Sum_Fields>;
  var_pop: Maybe<Applicant_Job_Var_Pop_Fields>;
  var_samp: Maybe<Applicant_Job_Var_Samp_Fields>;
  variance: Maybe<Applicant_Job_Variance_Fields>;
};

/** aggregate fields of "applicant_job" */
export type Applicant_Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Applicant_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "applicant_job" */
export type Applicant_Job_Aggregate_Order_By = {
  avg?: InputMaybe<Applicant_Job_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Applicant_Job_Max_Order_By>;
  min?: InputMaybe<Applicant_Job_Min_Order_By>;
  stddev?: InputMaybe<Applicant_Job_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Applicant_Job_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Applicant_Job_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Applicant_Job_Sum_Order_By>;
  var_pop?: InputMaybe<Applicant_Job_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Applicant_Job_Var_Samp_Order_By>;
  variance?: InputMaybe<Applicant_Job_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "applicant_job" */
export type Applicant_Job_Arr_Rel_Insert_Input = {
  data: Array<Applicant_Job_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Applicant_Job_On_Conflict>;
};

/** aggregate avg on columns */
export type Applicant_Job_Avg_Fields = {
  __typename?: 'applicant_job_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "applicant_job" */
export type Applicant_Job_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "applicant_job". All fields are combined with a logical 'AND'. */
export type Applicant_Job_Bool_Exp = {
  _and?: InputMaybe<Array<Applicant_Job_Bool_Exp>>;
  _not?: InputMaybe<Applicant_Job_Bool_Exp>;
  _or?: InputMaybe<Array<Applicant_Job_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  cid?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  form_submission_url?: InputMaybe<String_Comparison_Exp>;
  icims_jobs?: InputMaybe<Icims_Job_Bool_Exp>;
  icims_jobs_aggregate?: InputMaybe<Icims_Job_Aggregate_Bool_Exp>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
  job?: InputMaybe<Int_Comparison_Exp>;
  referring_url?: InputMaybe<String_Comparison_Exp>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum_Comparison_Exp>;
  utm_campaign?: InputMaybe<String_Comparison_Exp>;
  utm_content?: InputMaybe<String_Comparison_Exp>;
  utm_medium?: InputMaybe<String_Comparison_Exp>;
  utm_source?: InputMaybe<String_Comparison_Exp>;
  utm_term?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "applicant_job" */
export enum Applicant_Job_Constraint {
  /** unique or primary key constraint on columns "job", "candidate_id" */
  ApplicantJobCandidateIdJobKey = 'applicant_job_candidate_id_job_key',
  /** unique or primary key constraint on columns "job", "candidate_id" */
  ApplicantJobPkey = 'applicant_job_pkey',
}

/** input type for incrementing numeric columns in table "applicant_job" */
export type Applicant_Job_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  job?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "applicant_job" */
export type Applicant_Job_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  cid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  form_submission_url?: InputMaybe<Scalars['String']>;
  icims_jobs?: InputMaybe<Icims_Job_Arr_Rel_Insert_Input>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Obj_Rel_Insert_Input>;
  job?: InputMaybe<Scalars['Int']>;
  referring_url?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
  utm_term?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Applicant_Job_Max_Fields = {
  __typename?: 'applicant_job_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  cid: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  form_submission_url: Maybe<Scalars['String']>;
  job: Maybe<Scalars['Int']>;
  referring_url: Maybe<Scalars['String']>;
  utm_campaign: Maybe<Scalars['String']>;
  utm_content: Maybe<Scalars['String']>;
  utm_medium: Maybe<Scalars['String']>;
  utm_source: Maybe<Scalars['String']>;
  utm_term: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "applicant_job" */
export type Applicant_Job_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  cid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_submission_url?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  referring_url?: InputMaybe<Order_By>;
  utm_campaign?: InputMaybe<Order_By>;
  utm_content?: InputMaybe<Order_By>;
  utm_medium?: InputMaybe<Order_By>;
  utm_source?: InputMaybe<Order_By>;
  utm_term?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Applicant_Job_Min_Fields = {
  __typename?: 'applicant_job_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  cid: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  form_submission_url: Maybe<Scalars['String']>;
  job: Maybe<Scalars['Int']>;
  referring_url: Maybe<Scalars['String']>;
  utm_campaign: Maybe<Scalars['String']>;
  utm_content: Maybe<Scalars['String']>;
  utm_medium: Maybe<Scalars['String']>;
  utm_source: Maybe<Scalars['String']>;
  utm_term: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "applicant_job" */
export type Applicant_Job_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  cid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_submission_url?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  referring_url?: InputMaybe<Order_By>;
  utm_campaign?: InputMaybe<Order_By>;
  utm_content?: InputMaybe<Order_By>;
  utm_medium?: InputMaybe<Order_By>;
  utm_source?: InputMaybe<Order_By>;
  utm_term?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "applicant_job" */
export type Applicant_Job_Mutation_Response = {
  __typename?: 'applicant_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Applicant_Job>;
};

/** input type for inserting object relation for remote table "applicant_job" */
export type Applicant_Job_Obj_Rel_Insert_Input = {
  data: Applicant_Job_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Applicant_Job_On_Conflict>;
};

/** on_conflict condition type for table "applicant_job" */
export type Applicant_Job_On_Conflict = {
  constraint: Applicant_Job_Constraint;
  update_columns?: Array<Applicant_Job_Update_Column>;
  where?: InputMaybe<Applicant_Job_Bool_Exp>;
};

/** Ordering options when selecting data from "applicant_job". */
export type Applicant_Job_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  cid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_submission_url?: InputMaybe<Order_By>;
  icims_jobs_aggregate?: InputMaybe<Icims_Job_Aggregate_Order_By>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Order_By>;
  job?: InputMaybe<Order_By>;
  referring_url?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  utm_campaign?: InputMaybe<Order_By>;
  utm_content?: InputMaybe<Order_By>;
  utm_medium?: InputMaybe<Order_By>;
  utm_source?: InputMaybe<Order_By>;
  utm_term?: InputMaybe<Order_By>;
};

/** primary key columns input for table: applicant_job */
export type Applicant_Job_Pk_Columns_Input = {
  candidate_id: Scalars['Int'];
  job: Scalars['Int'];
};

/** select columns of table "applicant_job" */
export enum Applicant_Job_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Cid = 'cid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormSubmissionUrl = 'form_submission_url',
  /** column name */
  Job = 'job',
  /** column name */
  ReferringUrl = 'referring_url',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmContent = 'utm_content',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  UtmTerm = 'utm_term',
}

/** input type for updating data in table "applicant_job" */
export type Applicant_Job_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  cid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  form_submission_url?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<Scalars['Int']>;
  referring_url?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
  utm_term?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Applicant_Job_Stddev_Fields = {
  __typename?: 'applicant_job_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "applicant_job" */
export type Applicant_Job_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Applicant_Job_Stddev_Pop_Fields = {
  __typename?: 'applicant_job_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "applicant_job" */
export type Applicant_Job_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Applicant_Job_Stddev_Samp_Fields = {
  __typename?: 'applicant_job_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "applicant_job" */
export type Applicant_Job_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "applicant_job" */
export type Applicant_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Applicant_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Applicant_Job_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  cid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  form_submission_url?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<Scalars['Int']>;
  referring_url?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
  utm_term?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Applicant_Job_Sum_Fields = {
  __typename?: 'applicant_job_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  job: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "applicant_job" */
export type Applicant_Job_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
};

/** update columns of table "applicant_job" */
export enum Applicant_Job_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Cid = 'cid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormSubmissionUrl = 'form_submission_url',
  /** column name */
  Job = 'job',
  /** column name */
  ReferringUrl = 'referring_url',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmContent = 'utm_content',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  UtmTerm = 'utm_term',
}

export type Applicant_Job_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Applicant_Job_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Applicant_Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Applicant_Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Applicant_Job_Var_Pop_Fields = {
  __typename?: 'applicant_job_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "applicant_job" */
export type Applicant_Job_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Applicant_Job_Var_Samp_Fields = {
  __typename?: 'applicant_job_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "applicant_job" */
export type Applicant_Job_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Applicant_Job_Variance_Fields = {
  __typename?: 'applicant_job_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "applicant_job" */
export type Applicant_Job_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
};

/** columns and relationships of "applicant_waitlist" */
export type Applicant_Waitlist = {
  __typename?: 'applicant_waitlist';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  /** An object relationship */
  country_choice: Maybe<Country_Choices>;
  country_id: Maybe<Scalars['Int']>;
  email: Scalars['citext'];
  first_name: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  last_name: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  point: Maybe<Scalars['geometry']>;
  state: Maybe<Scalars['String']>;
};

/** aggregated selection of "applicant_waitlist" */
export type Applicant_Waitlist_Aggregate = {
  __typename?: 'applicant_waitlist_aggregate';
  aggregate: Maybe<Applicant_Waitlist_Aggregate_Fields>;
  nodes: Array<Applicant_Waitlist>;
};

/** aggregate fields of "applicant_waitlist" */
export type Applicant_Waitlist_Aggregate_Fields = {
  __typename?: 'applicant_waitlist_aggregate_fields';
  avg: Maybe<Applicant_Waitlist_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Applicant_Waitlist_Max_Fields>;
  min: Maybe<Applicant_Waitlist_Min_Fields>;
  stddev: Maybe<Applicant_Waitlist_Stddev_Fields>;
  stddev_pop: Maybe<Applicant_Waitlist_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Applicant_Waitlist_Stddev_Samp_Fields>;
  sum: Maybe<Applicant_Waitlist_Sum_Fields>;
  var_pop: Maybe<Applicant_Waitlist_Var_Pop_Fields>;
  var_samp: Maybe<Applicant_Waitlist_Var_Samp_Fields>;
  variance: Maybe<Applicant_Waitlist_Variance_Fields>;
};

/** aggregate fields of "applicant_waitlist" */
export type Applicant_Waitlist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Applicant_Waitlist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Applicant_Waitlist_Avg_Fields = {
  __typename?: 'applicant_waitlist_avg_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "applicant_waitlist". All fields are combined with a logical 'AND'. */
export type Applicant_Waitlist_Bool_Exp = {
  _and?: InputMaybe<Array<Applicant_Waitlist_Bool_Exp>>;
  _not?: InputMaybe<Applicant_Waitlist_Bool_Exp>;
  _or?: InputMaybe<Array<Applicant_Waitlist_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  country_choice?: InputMaybe<Country_Choices_Bool_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  point?: InputMaybe<Geometry_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "applicant_waitlist" */
export enum Applicant_Waitlist_Constraint {
  /** unique or primary key constraint on columns "email" */
  ApplicantWaitlistEmailKey = 'applicant_waitlist_email_key',
  /** unique or primary key constraint on columns "id" */
  ApplicantWaitlistPkey = 'applicant_waitlist_pkey',
}

/** input type for incrementing numeric columns in table "applicant_waitlist" */
export type Applicant_Waitlist_Inc_Input = {
  country_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "applicant_waitlist" */
export type Applicant_Waitlist_Insert_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_choice?: InputMaybe<Country_Choices_Obj_Rel_Insert_Input>;
  country_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['citext']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['geometry']>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Applicant_Waitlist_Max_Fields = {
  __typename?: 'applicant_waitlist_max_fields';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['Int']>;
  email: Maybe<Scalars['citext']>;
  first_name: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  last_name: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Applicant_Waitlist_Min_Fields = {
  __typename?: 'applicant_waitlist_min_fields';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['Int']>;
  email: Maybe<Scalars['citext']>;
  first_name: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  last_name: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "applicant_waitlist" */
export type Applicant_Waitlist_Mutation_Response = {
  __typename?: 'applicant_waitlist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Applicant_Waitlist>;
};

/** on_conflict condition type for table "applicant_waitlist" */
export type Applicant_Waitlist_On_Conflict = {
  constraint: Applicant_Waitlist_Constraint;
  update_columns?: Array<Applicant_Waitlist_Update_Column>;
  where?: InputMaybe<Applicant_Waitlist_Bool_Exp>;
};

/** Ordering options when selecting data from "applicant_waitlist". */
export type Applicant_Waitlist_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  country_choice?: InputMaybe<Country_Choices_Order_By>;
  country_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: applicant_waitlist */
export type Applicant_Waitlist_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "applicant_waitlist" */
export enum Applicant_Waitlist_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Point = 'point',
  /** column name */
  State = 'state',
}

/** input type for updating data in table "applicant_waitlist" */
export type Applicant_Waitlist_Set_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['citext']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['geometry']>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Applicant_Waitlist_Stddev_Fields = {
  __typename?: 'applicant_waitlist_stddev_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Applicant_Waitlist_Stddev_Pop_Fields = {
  __typename?: 'applicant_waitlist_stddev_pop_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Applicant_Waitlist_Stddev_Samp_Fields = {
  __typename?: 'applicant_waitlist_stddev_samp_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "applicant_waitlist" */
export type Applicant_Waitlist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Applicant_Waitlist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Applicant_Waitlist_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['citext']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['geometry']>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Applicant_Waitlist_Sum_Fields = {
  __typename?: 'applicant_waitlist_sum_fields';
  country_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "applicant_waitlist" */
export enum Applicant_Waitlist_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Point = 'point',
  /** column name */
  State = 'state',
}

export type Applicant_Waitlist_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Applicant_Waitlist_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Applicant_Waitlist_Set_Input>;
  /** filter the rows which have to be updated */
  where: Applicant_Waitlist_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Applicant_Waitlist_Var_Pop_Fields = {
  __typename?: 'applicant_waitlist_var_pop_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Applicant_Waitlist_Var_Samp_Fields = {
  __typename?: 'applicant_waitlist_var_samp_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Applicant_Waitlist_Variance_Fields = {
  __typename?: 'applicant_waitlist_variance_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_Actions = {
  __typename?: 'audit_logged_actions';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action: Scalars['String'];
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm: Scalars['timestamptz'];
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields: Maybe<Scalars['jsonb']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query: Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id: Scalars['bigint'];
  hasura_user: Maybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid: Scalars['oid'];
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data: Maybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name: Scalars['String'];
  /** Non-schema-qualified table name of table event occured in */
  table_name: Scalars['String'];
};

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsRow_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate = {
  __typename?: 'audit_logged_actions_aggregate';
  aggregate: Maybe<Audit_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Audit_Logged_Actions>;
};

/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_Fields = {
  __typename?: 'audit_logged_actions_aggregate_fields';
  avg: Maybe<Audit_Logged_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Audit_Logged_Actions_Max_Fields>;
  min: Maybe<Audit_Logged_Actions_Min_Fields>;
  stddev: Maybe<Audit_Logged_Actions_Stddev_Fields>;
  stddev_pop: Maybe<Audit_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Audit_Logged_Actions_Stddev_Samp_Fields>;
  sum: Maybe<Audit_Logged_Actions_Sum_Fields>;
  var_pop: Maybe<Audit_Logged_Actions_Var_Pop_Fields>;
  var_samp: Maybe<Audit_Logged_Actions_Var_Samp_Fields>;
  variance: Maybe<Audit_Logged_Actions_Variance_Fields>;
};

/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Append_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Audit_Logged_Actions_Avg_Fields = {
  __typename?: 'audit_logged_actions_avg_fields';
  /** Unique identifier for each auditable event */
  event_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "audit.logged_actions". All fields are combined with a logical 'AND'. */
export type Audit_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_tstamp_stm?: InputMaybe<Timestamptz_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  client_query?: InputMaybe<String_Comparison_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  relid?: InputMaybe<Oid_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  schema_name?: InputMaybe<String_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit.logged_actions" */
export enum Audit_Logged_Actions_Constraint {
  /** unique or primary key constraint on columns "event_id" */
  LoggedActionsPkey = 'logged_actions_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Logged_Actions_Delete_At_Path_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Array<Scalars['String']>>;
  hasura_user?: InputMaybe<Array<Scalars['String']>>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Logged_Actions_Delete_Elem_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['Int']>;
  hasura_user?: InputMaybe<Scalars['Int']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Logged_Actions_Delete_Key_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['String']>;
  hasura_user?: InputMaybe<Scalars['String']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "audit.logged_actions" */
export type Audit_Logged_Actions_Inc_Input = {
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "audit.logged_actions" */
export type Audit_Logged_Actions_Insert_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Audit_Logged_Actions_Max_Fields = {
  __typename?: 'audit_logged_actions_max_fields';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action: Maybe<Scalars['String']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm: Maybe<Scalars['timestamptz']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query: Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id: Maybe<Scalars['bigint']>;
  /** Database schema audited table for this event is in */
  schema_name: Maybe<Scalars['String']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Audit_Logged_Actions_Min_Fields = {
  __typename?: 'audit_logged_actions_min_fields';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action: Maybe<Scalars['String']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm: Maybe<Scalars['timestamptz']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query: Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id: Maybe<Scalars['bigint']>;
  /** Database schema audited table for this event is in */
  schema_name: Maybe<Scalars['String']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "audit.logged_actions" */
export type Audit_Logged_Actions_Mutation_Response = {
  __typename?: 'audit_logged_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Logged_Actions>;
};

/** on_conflict condition type for table "audit.logged_actions" */
export type Audit_Logged_Actions_On_Conflict = {
  constraint: Audit_Logged_Actions_Constraint;
  update_columns?: Array<Audit_Logged_Actions_Update_Column>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "audit.logged_actions". */
export type Audit_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  action_tstamp_stm?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  client_query?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  relid?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  schema_name?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audit.logged_actions */
export type Audit_Logged_Actions_Pk_Columns_Input = {
  /** Unique identifier for each auditable event */
  event_id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Prepend_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  TableName = 'table_name',
}

/** input type for updating data in table "audit.logged_actions" */
export type Audit_Logged_Actions_Set_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Audit_Logged_Actions_Stddev_Fields = {
  __typename?: 'audit_logged_actions_stddev_fields';
  /** Unique identifier for each auditable event */
  event_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Audit_Logged_Actions_Stddev_Pop_Fields = {
  __typename?: 'audit_logged_actions_stddev_pop_fields';
  /** Unique identifier for each auditable event */
  event_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Audit_Logged_Actions_Stddev_Samp_Fields = {
  __typename?: 'audit_logged_actions_stddev_samp_fields';
  /** Unique identifier for each auditable event */
  event_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "audit_logged_actions" */
export type Audit_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Logged_Actions_Stream_Cursor_Value_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Audit_Logged_Actions_Sum_Fields = {
  __typename?: 'audit_logged_actions_sum_fields';
  /** Unique identifier for each auditable event */
  event_id: Maybe<Scalars['bigint']>;
};

/** update columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  TableName = 'table_name',
}

export type Audit_Logged_Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audit_Logged_Actions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Audit_Logged_Actions_Var_Pop_Fields = {
  __typename?: 'audit_logged_actions_var_pop_fields';
  /** Unique identifier for each auditable event */
  event_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Audit_Logged_Actions_Var_Samp_Fields = {
  __typename?: 'audit_logged_actions_var_samp_fields';
  /** Unique identifier for each auditable event */
  event_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Audit_Logged_Actions_Variance_Fields = {
  __typename?: 'audit_logged_actions_variance_fields';
  /** Unique identifier for each auditable event */
  event_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type Bytea_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bytea']>;
  _gt?: InputMaybe<Scalars['bytea']>;
  _gte?: InputMaybe<Scalars['bytea']>;
  _in?: InputMaybe<Array<Scalars['bytea']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bytea']>;
  _lte?: InputMaybe<Scalars['bytea']>;
  _neq?: InputMaybe<Scalars['bytea']>;
  _nin?: InputMaybe<Array<Scalars['bytea']>>;
};

/** A calendly event represents a scheduled interview time and location (probably a zoom chat) */
export type Calendly_Event = {
  __typename?: 'calendly_event';
  /** An object relationship */
  calendly_invitee: Calendly_Invitee;
  calendly_invitee_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** The duration of the event in minutes */
  duration: Scalars['Int'];
  end_time: Scalars['timestamptz'];
  event_raw: Scalars['jsonb'];
  event_url: Scalars['String'];
  id: Scalars['Int'];
  /** Check Calendly's documentation for location field on the get event for location data schema https://developer.calendly.com/api-docs/e2f95ebd44914-get-event */
  location_data: Scalars['jsonb'];
  /** Typically zoom but check Calendly's documentation for more types */
  location_type: Scalars['String'];
  /** The URL for joining the meeting */
  location_url: Maybe<Scalars['String']>;
  name: Scalars['String'];
  start_time: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
};

/** A calendly event represents a scheduled interview time and location (probably a zoom chat) */
export type Calendly_EventEvent_RawArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** A calendly event represents a scheduled interview time and location (probably a zoom chat) */
export type Calendly_EventLocation_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "calendly_event" */
export type Calendly_Event_Aggregate = {
  __typename?: 'calendly_event_aggregate';
  aggregate: Maybe<Calendly_Event_Aggregate_Fields>;
  nodes: Array<Calendly_Event>;
};

export type Calendly_Event_Aggregate_Bool_Exp = {
  count?: InputMaybe<Calendly_Event_Aggregate_Bool_Exp_Count>;
};

export type Calendly_Event_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Calendly_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendly_Event_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "calendly_event" */
export type Calendly_Event_Aggregate_Fields = {
  __typename?: 'calendly_event_aggregate_fields';
  avg: Maybe<Calendly_Event_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Calendly_Event_Max_Fields>;
  min: Maybe<Calendly_Event_Min_Fields>;
  stddev: Maybe<Calendly_Event_Stddev_Fields>;
  stddev_pop: Maybe<Calendly_Event_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Calendly_Event_Stddev_Samp_Fields>;
  sum: Maybe<Calendly_Event_Sum_Fields>;
  var_pop: Maybe<Calendly_Event_Var_Pop_Fields>;
  var_samp: Maybe<Calendly_Event_Var_Samp_Fields>;
  variance: Maybe<Calendly_Event_Variance_Fields>;
};

/** aggregate fields of "calendly_event" */
export type Calendly_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendly_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendly_event" */
export type Calendly_Event_Aggregate_Order_By = {
  avg?: InputMaybe<Calendly_Event_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Calendly_Event_Max_Order_By>;
  min?: InputMaybe<Calendly_Event_Min_Order_By>;
  stddev?: InputMaybe<Calendly_Event_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Calendly_Event_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Calendly_Event_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Calendly_Event_Sum_Order_By>;
  var_pop?: InputMaybe<Calendly_Event_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Calendly_Event_Var_Samp_Order_By>;
  variance?: InputMaybe<Calendly_Event_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Calendly_Event_Append_Input = {
  event_raw?: InputMaybe<Scalars['jsonb']>;
  /** Check Calendly's documentation for location field on the get event for location data schema https://developer.calendly.com/api-docs/e2f95ebd44914-get-event */
  location_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "calendly_event" */
export type Calendly_Event_Arr_Rel_Insert_Input = {
  data: Array<Calendly_Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendly_Event_On_Conflict>;
};

/** aggregate avg on columns */
export type Calendly_Event_Avg_Fields = {
  __typename?: 'calendly_event_avg_fields';
  calendly_invitee_id: Maybe<Scalars['Float']>;
  /** The duration of the event in minutes */
  duration: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "calendly_event" */
export type Calendly_Event_Avg_Order_By = {
  calendly_invitee_id?: InputMaybe<Order_By>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "calendly_event". All fields are combined with a logical 'AND'. */
export type Calendly_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Calendly_Event_Bool_Exp>>;
  _not?: InputMaybe<Calendly_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Calendly_Event_Bool_Exp>>;
  calendly_invitee?: InputMaybe<Calendly_Invitee_Bool_Exp>;
  calendly_invitee_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  end_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_raw?: InputMaybe<Jsonb_Comparison_Exp>;
  event_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location_data?: InputMaybe<Jsonb_Comparison_Exp>;
  location_type?: InputMaybe<String_Comparison_Exp>;
  location_url?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendly_event" */
export enum Calendly_Event_Constraint {
  /** unique or primary key constraint on columns "event_url" */
  CalendlyEventEventUrlKey = 'calendly_event_event_url_key',
  /** unique or primary key constraint on columns "id" */
  CalendlyEventPkey = 'calendly_event_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Calendly_Event_Delete_At_Path_Input = {
  event_raw?: InputMaybe<Array<Scalars['String']>>;
  /** Check Calendly's documentation for location field on the get event for location data schema https://developer.calendly.com/api-docs/e2f95ebd44914-get-event */
  location_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Calendly_Event_Delete_Elem_Input = {
  event_raw?: InputMaybe<Scalars['Int']>;
  /** Check Calendly's documentation for location field on the get event for location data schema https://developer.calendly.com/api-docs/e2f95ebd44914-get-event */
  location_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Calendly_Event_Delete_Key_Input = {
  event_raw?: InputMaybe<Scalars['String']>;
  /** Check Calendly's documentation for location field on the get event for location data schema https://developer.calendly.com/api-docs/e2f95ebd44914-get-event */
  location_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "calendly_event" */
export type Calendly_Event_Inc_Input = {
  calendly_invitee_id?: InputMaybe<Scalars['Int']>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "calendly_event" */
export type Calendly_Event_Insert_Input = {
  calendly_invitee?: InputMaybe<Calendly_Invitee_Obj_Rel_Insert_Input>;
  calendly_invitee_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Scalars['Int']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  event_raw?: InputMaybe<Scalars['jsonb']>;
  event_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  /** Check Calendly's documentation for location field on the get event for location data schema https://developer.calendly.com/api-docs/e2f95ebd44914-get-event */
  location_data?: InputMaybe<Scalars['jsonb']>;
  /** Typically zoom but check Calendly's documentation for more types */
  location_type?: InputMaybe<Scalars['String']>;
  /** The URL for joining the meeting */
  location_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Calendly_Event_Max_Fields = {
  __typename?: 'calendly_event_max_fields';
  calendly_invitee_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  /** The duration of the event in minutes */
  duration: Maybe<Scalars['Int']>;
  end_time: Maybe<Scalars['timestamptz']>;
  event_url: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  /** Typically zoom but check Calendly's documentation for more types */
  location_type: Maybe<Scalars['String']>;
  /** The URL for joining the meeting */
  location_url: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  start_time: Maybe<Scalars['timestamptz']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "calendly_event" */
export type Calendly_Event_Max_Order_By = {
  calendly_invitee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  event_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Typically zoom but check Calendly's documentation for more types */
  location_type?: InputMaybe<Order_By>;
  /** The URL for joining the meeting */
  location_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Calendly_Event_Min_Fields = {
  __typename?: 'calendly_event_min_fields';
  calendly_invitee_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  /** The duration of the event in minutes */
  duration: Maybe<Scalars['Int']>;
  end_time: Maybe<Scalars['timestamptz']>;
  event_url: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  /** Typically zoom but check Calendly's documentation for more types */
  location_type: Maybe<Scalars['String']>;
  /** The URL for joining the meeting */
  location_url: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  start_time: Maybe<Scalars['timestamptz']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "calendly_event" */
export type Calendly_Event_Min_Order_By = {
  calendly_invitee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  event_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Typically zoom but check Calendly's documentation for more types */
  location_type?: InputMaybe<Order_By>;
  /** The URL for joining the meeting */
  location_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "calendly_event" */
export type Calendly_Event_Mutation_Response = {
  __typename?: 'calendly_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calendly_Event>;
};

/** on_conflict condition type for table "calendly_event" */
export type Calendly_Event_On_Conflict = {
  constraint: Calendly_Event_Constraint;
  update_columns?: Array<Calendly_Event_Update_Column>;
  where?: InputMaybe<Calendly_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "calendly_event". */
export type Calendly_Event_Order_By = {
  calendly_invitee?: InputMaybe<Calendly_Invitee_Order_By>;
  calendly_invitee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  event_raw?: InputMaybe<Order_By>;
  event_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_data?: InputMaybe<Order_By>;
  location_type?: InputMaybe<Order_By>;
  location_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calendly_event */
export type Calendly_Event_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Calendly_Event_Prepend_Input = {
  event_raw?: InputMaybe<Scalars['jsonb']>;
  /** Check Calendly's documentation for location field on the get event for location data schema https://developer.calendly.com/api-docs/e2f95ebd44914-get-event */
  location_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "calendly_event" */
export enum Calendly_Event_Select_Column {
  /** column name */
  CalendlyInviteeId = 'calendly_invitee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  EventRaw = 'event_raw',
  /** column name */
  EventUrl = 'event_url',
  /** column name */
  Id = 'id',
  /** column name */
  LocationData = 'location_data',
  /** column name */
  LocationType = 'location_type',
  /** column name */
  LocationUrl = 'location_url',
  /** column name */
  Name = 'name',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "calendly_event" */
export type Calendly_Event_Set_Input = {
  calendly_invitee_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Scalars['Int']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  event_raw?: InputMaybe<Scalars['jsonb']>;
  event_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  /** Check Calendly's documentation for location field on the get event for location data schema https://developer.calendly.com/api-docs/e2f95ebd44914-get-event */
  location_data?: InputMaybe<Scalars['jsonb']>;
  /** Typically zoom but check Calendly's documentation for more types */
  location_type?: InputMaybe<Scalars['String']>;
  /** The URL for joining the meeting */
  location_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Calendly_Event_Stddev_Fields = {
  __typename?: 'calendly_event_stddev_fields';
  calendly_invitee_id: Maybe<Scalars['Float']>;
  /** The duration of the event in minutes */
  duration: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "calendly_event" */
export type Calendly_Event_Stddev_Order_By = {
  calendly_invitee_id?: InputMaybe<Order_By>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Calendly_Event_Stddev_Pop_Fields = {
  __typename?: 'calendly_event_stddev_pop_fields';
  calendly_invitee_id: Maybe<Scalars['Float']>;
  /** The duration of the event in minutes */
  duration: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "calendly_event" */
export type Calendly_Event_Stddev_Pop_Order_By = {
  calendly_invitee_id?: InputMaybe<Order_By>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Calendly_Event_Stddev_Samp_Fields = {
  __typename?: 'calendly_event_stddev_samp_fields';
  calendly_invitee_id: Maybe<Scalars['Float']>;
  /** The duration of the event in minutes */
  duration: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "calendly_event" */
export type Calendly_Event_Stddev_Samp_Order_By = {
  calendly_invitee_id?: InputMaybe<Order_By>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "calendly_event" */
export type Calendly_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendly_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendly_Event_Stream_Cursor_Value_Input = {
  calendly_invitee_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Scalars['Int']>;
  end_time?: InputMaybe<Scalars['timestamptz']>;
  event_raw?: InputMaybe<Scalars['jsonb']>;
  event_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  /** Check Calendly's documentation for location field on the get event for location data schema https://developer.calendly.com/api-docs/e2f95ebd44914-get-event */
  location_data?: InputMaybe<Scalars['jsonb']>;
  /** Typically zoom but check Calendly's documentation for more types */
  location_type?: InputMaybe<Scalars['String']>;
  /** The URL for joining the meeting */
  location_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  start_time?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Calendly_Event_Sum_Fields = {
  __typename?: 'calendly_event_sum_fields';
  calendly_invitee_id: Maybe<Scalars['Int']>;
  /** The duration of the event in minutes */
  duration: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "calendly_event" */
export type Calendly_Event_Sum_Order_By = {
  calendly_invitee_id?: InputMaybe<Order_By>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "calendly_event" */
export enum Calendly_Event_Update_Column {
  /** column name */
  CalendlyInviteeId = 'calendly_invitee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  EventRaw = 'event_raw',
  /** column name */
  EventUrl = 'event_url',
  /** column name */
  Id = 'id',
  /** column name */
  LocationData = 'location_data',
  /** column name */
  LocationType = 'location_type',
  /** column name */
  LocationUrl = 'location_url',
  /** column name */
  Name = 'name',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Calendly_Event_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Calendly_Event_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Calendly_Event_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Calendly_Event_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Calendly_Event_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Calendly_Event_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Calendly_Event_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calendly_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calendly_Event_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Calendly_Event_Var_Pop_Fields = {
  __typename?: 'calendly_event_var_pop_fields';
  calendly_invitee_id: Maybe<Scalars['Float']>;
  /** The duration of the event in minutes */
  duration: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "calendly_event" */
export type Calendly_Event_Var_Pop_Order_By = {
  calendly_invitee_id?: InputMaybe<Order_By>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Calendly_Event_Var_Samp_Fields = {
  __typename?: 'calendly_event_var_samp_fields';
  calendly_invitee_id: Maybe<Scalars['Float']>;
  /** The duration of the event in minutes */
  duration: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "calendly_event" */
export type Calendly_Event_Var_Samp_Order_By = {
  calendly_invitee_id?: InputMaybe<Order_By>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Calendly_Event_Variance_Fields = {
  __typename?: 'calendly_event_variance_fields';
  calendly_invitee_id: Maybe<Scalars['Float']>;
  /** The duration of the event in minutes */
  duration: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "calendly_event" */
export type Calendly_Event_Variance_Order_By = {
  calendly_invitee_id?: InputMaybe<Order_By>;
  /** The duration of the event in minutes */
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Multiple invitees can exist for a single calendly_event. A person rescheduling will appear as multple invitees with one that is active and another that is cancelled with a reschedule_url set */
export type Calendly_Invitee = {
  __typename?: 'calendly_invitee';
  /** An array relationship */
  calendly_events: Array<Calendly_Event>;
  /** An aggregate relationship */
  calendly_events_aggregate: Calendly_Event_Aggregate;
  cancel_url: Scalars['String'];
  /** An object relationship */
  client_prospect: Maybe<Client_Prospect>;
  client_prospect_id: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  event_url: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  interview_request: Maybe<Interview_Request>;
  interview_request_id: Maybe<Scalars['Int']>;
  invitee_url: Scalars['String'];
  is_active: Scalars['Boolean'];
  is_rescheduled: Scalars['Boolean'];
  name: Scalars['String'];
  questions_and_answers: Scalars['jsonb'];
  raw_cancelled: Maybe<Scalars['jsonb']>;
  raw_created: Maybe<Scalars['jsonb']>;
  reschedule_url: Scalars['String'];
  timezone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Multiple invitees can exist for a single calendly_event. A person rescheduling will appear as multple invitees with one that is active and another that is cancelled with a reschedule_url set */
export type Calendly_InviteeCalendly_EventsArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Event_Order_By>>;
  where?: InputMaybe<Calendly_Event_Bool_Exp>;
};

/** Multiple invitees can exist for a single calendly_event. A person rescheduling will appear as multple invitees with one that is active and another that is cancelled with a reschedule_url set */
export type Calendly_InviteeCalendly_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Event_Order_By>>;
  where?: InputMaybe<Calendly_Event_Bool_Exp>;
};

/** Multiple invitees can exist for a single calendly_event. A person rescheduling will appear as multple invitees with one that is active and another that is cancelled with a reschedule_url set */
export type Calendly_InviteeQuestions_And_AnswersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Multiple invitees can exist for a single calendly_event. A person rescheduling will appear as multple invitees with one that is active and another that is cancelled with a reschedule_url set */
export type Calendly_InviteeRaw_CancelledArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Multiple invitees can exist for a single calendly_event. A person rescheduling will appear as multple invitees with one that is active and another that is cancelled with a reschedule_url set */
export type Calendly_InviteeRaw_CreatedArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "calendly_invitee" */
export type Calendly_Invitee_Aggregate = {
  __typename?: 'calendly_invitee_aggregate';
  aggregate: Maybe<Calendly_Invitee_Aggregate_Fields>;
  nodes: Array<Calendly_Invitee>;
};

export type Calendly_Invitee_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Calendly_Invitee_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Calendly_Invitee_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Calendly_Invitee_Aggregate_Bool_Exp_Count>;
};

export type Calendly_Invitee_Aggregate_Bool_Exp_Bool_And = {
  arguments: Calendly_Invitee_Select_Column_Calendly_Invitee_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendly_Invitee_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Calendly_Invitee_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Calendly_Invitee_Select_Column_Calendly_Invitee_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendly_Invitee_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Calendly_Invitee_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Calendly_Invitee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Calendly_Invitee_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "calendly_invitee" */
export type Calendly_Invitee_Aggregate_Fields = {
  __typename?: 'calendly_invitee_aggregate_fields';
  avg: Maybe<Calendly_Invitee_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Calendly_Invitee_Max_Fields>;
  min: Maybe<Calendly_Invitee_Min_Fields>;
  stddev: Maybe<Calendly_Invitee_Stddev_Fields>;
  stddev_pop: Maybe<Calendly_Invitee_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Calendly_Invitee_Stddev_Samp_Fields>;
  sum: Maybe<Calendly_Invitee_Sum_Fields>;
  var_pop: Maybe<Calendly_Invitee_Var_Pop_Fields>;
  var_samp: Maybe<Calendly_Invitee_Var_Samp_Fields>;
  variance: Maybe<Calendly_Invitee_Variance_Fields>;
};

/** aggregate fields of "calendly_invitee" */
export type Calendly_Invitee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendly_Invitee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendly_invitee" */
export type Calendly_Invitee_Aggregate_Order_By = {
  avg?: InputMaybe<Calendly_Invitee_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Calendly_Invitee_Max_Order_By>;
  min?: InputMaybe<Calendly_Invitee_Min_Order_By>;
  stddev?: InputMaybe<Calendly_Invitee_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Calendly_Invitee_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Calendly_Invitee_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Calendly_Invitee_Sum_Order_By>;
  var_pop?: InputMaybe<Calendly_Invitee_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Calendly_Invitee_Var_Samp_Order_By>;
  variance?: InputMaybe<Calendly_Invitee_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Calendly_Invitee_Append_Input = {
  questions_and_answers?: InputMaybe<Scalars['jsonb']>;
  raw_cancelled?: InputMaybe<Scalars['jsonb']>;
  raw_created?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "calendly_invitee" */
export type Calendly_Invitee_Arr_Rel_Insert_Input = {
  data: Array<Calendly_Invitee_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendly_Invitee_On_Conflict>;
};

/** aggregate avg on columns */
export type Calendly_Invitee_Avg_Fields = {
  __typename?: 'calendly_invitee_avg_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interview_request_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "calendly_invitee" */
export type Calendly_Invitee_Avg_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_request_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "calendly_invitee". All fields are combined with a logical 'AND'. */
export type Calendly_Invitee_Bool_Exp = {
  _and?: InputMaybe<Array<Calendly_Invitee_Bool_Exp>>;
  _not?: InputMaybe<Calendly_Invitee_Bool_Exp>;
  _or?: InputMaybe<Array<Calendly_Invitee_Bool_Exp>>;
  calendly_events?: InputMaybe<Calendly_Event_Bool_Exp>;
  calendly_events_aggregate?: InputMaybe<Calendly_Event_Aggregate_Bool_Exp>;
  cancel_url?: InputMaybe<String_Comparison_Exp>;
  client_prospect?: InputMaybe<Client_Prospect_Bool_Exp>;
  client_prospect_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  event_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interview_request?: InputMaybe<Interview_Request_Bool_Exp>;
  interview_request_id?: InputMaybe<Int_Comparison_Exp>;
  invitee_url?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_rescheduled?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  questions_and_answers?: InputMaybe<Jsonb_Comparison_Exp>;
  raw_cancelled?: InputMaybe<Jsonb_Comparison_Exp>;
  raw_created?: InputMaybe<Jsonb_Comparison_Exp>;
  reschedule_url?: InputMaybe<String_Comparison_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendly_invitee" */
export enum Calendly_Invitee_Constraint {
  /** unique or primary key constraint on columns "invitee_url" */
  CalendlyInviteeInviteeUrlKey = 'calendly_invitee_invitee_url_key',
  /** unique or primary key constraint on columns "id" */
  CalendlyInviteePkey = 'calendly_invitee_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Calendly_Invitee_Delete_At_Path_Input = {
  questions_and_answers?: InputMaybe<Array<Scalars['String']>>;
  raw_cancelled?: InputMaybe<Array<Scalars['String']>>;
  raw_created?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Calendly_Invitee_Delete_Elem_Input = {
  questions_and_answers?: InputMaybe<Scalars['Int']>;
  raw_cancelled?: InputMaybe<Scalars['Int']>;
  raw_created?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Calendly_Invitee_Delete_Key_Input = {
  questions_and_answers?: InputMaybe<Scalars['String']>;
  raw_cancelled?: InputMaybe<Scalars['String']>;
  raw_created?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "calendly_invitee" */
export type Calendly_Invitee_Inc_Input = {
  client_prospect_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interview_request_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "calendly_invitee" */
export type Calendly_Invitee_Insert_Input = {
  calendly_events?: InputMaybe<Calendly_Event_Arr_Rel_Insert_Input>;
  cancel_url?: InputMaybe<Scalars['String']>;
  client_prospect?: InputMaybe<Client_Prospect_Obj_Rel_Insert_Input>;
  client_prospect_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  event_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  interview_request?: InputMaybe<Interview_Request_Obj_Rel_Insert_Input>;
  interview_request_id?: InputMaybe<Scalars['Int']>;
  invitee_url?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_rescheduled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  questions_and_answers?: InputMaybe<Scalars['jsonb']>;
  raw_cancelled?: InputMaybe<Scalars['jsonb']>;
  raw_created?: InputMaybe<Scalars['jsonb']>;
  reschedule_url?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Calendly_Invitee_Max_Fields = {
  __typename?: 'calendly_invitee_max_fields';
  cancel_url: Maybe<Scalars['String']>;
  client_prospect_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  event_url: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  interview_request_id: Maybe<Scalars['Int']>;
  invitee_url: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  reschedule_url: Maybe<Scalars['String']>;
  timezone: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "calendly_invitee" */
export type Calendly_Invitee_Max_Order_By = {
  cancel_url?: InputMaybe<Order_By>;
  client_prospect_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  event_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_request_id?: InputMaybe<Order_By>;
  invitee_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reschedule_url?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Calendly_Invitee_Min_Fields = {
  __typename?: 'calendly_invitee_min_fields';
  cancel_url: Maybe<Scalars['String']>;
  client_prospect_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  event_url: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  interview_request_id: Maybe<Scalars['Int']>;
  invitee_url: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  reschedule_url: Maybe<Scalars['String']>;
  timezone: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "calendly_invitee" */
export type Calendly_Invitee_Min_Order_By = {
  cancel_url?: InputMaybe<Order_By>;
  client_prospect_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  event_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_request_id?: InputMaybe<Order_By>;
  invitee_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reschedule_url?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "calendly_invitee" */
export type Calendly_Invitee_Mutation_Response = {
  __typename?: 'calendly_invitee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calendly_Invitee>;
};

/** input type for inserting object relation for remote table "calendly_invitee" */
export type Calendly_Invitee_Obj_Rel_Insert_Input = {
  data: Calendly_Invitee_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendly_Invitee_On_Conflict>;
};

/** on_conflict condition type for table "calendly_invitee" */
export type Calendly_Invitee_On_Conflict = {
  constraint: Calendly_Invitee_Constraint;
  update_columns?: Array<Calendly_Invitee_Update_Column>;
  where?: InputMaybe<Calendly_Invitee_Bool_Exp>;
};

/** Ordering options when selecting data from "calendly_invitee". */
export type Calendly_Invitee_Order_By = {
  calendly_events_aggregate?: InputMaybe<Calendly_Event_Aggregate_Order_By>;
  cancel_url?: InputMaybe<Order_By>;
  client_prospect?: InputMaybe<Client_Prospect_Order_By>;
  client_prospect_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  event_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_request?: InputMaybe<Interview_Request_Order_By>;
  interview_request_id?: InputMaybe<Order_By>;
  invitee_url?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_rescheduled?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  questions_and_answers?: InputMaybe<Order_By>;
  raw_cancelled?: InputMaybe<Order_By>;
  raw_created?: InputMaybe<Order_By>;
  reschedule_url?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calendly_invitee */
export type Calendly_Invitee_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Calendly_Invitee_Prepend_Input = {
  questions_and_answers?: InputMaybe<Scalars['jsonb']>;
  raw_cancelled?: InputMaybe<Scalars['jsonb']>;
  raw_created?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "calendly_invitee" */
export enum Calendly_Invitee_Select_Column {
  /** column name */
  CancelUrl = 'cancel_url',
  /** column name */
  ClientProspectId = 'client_prospect_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EventUrl = 'event_url',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewRequestId = 'interview_request_id',
  /** column name */
  InviteeUrl = 'invitee_url',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsRescheduled = 'is_rescheduled',
  /** column name */
  Name = 'name',
  /** column name */
  QuestionsAndAnswers = 'questions_and_answers',
  /** column name */
  RawCancelled = 'raw_cancelled',
  /** column name */
  RawCreated = 'raw_created',
  /** column name */
  RescheduleUrl = 'reschedule_url',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "calendly_invitee_aggregate_bool_exp_bool_and_arguments_columns" columns of table "calendly_invitee" */
export enum Calendly_Invitee_Select_Column_Calendly_Invitee_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsRescheduled = 'is_rescheduled',
}

/** select "calendly_invitee_aggregate_bool_exp_bool_or_arguments_columns" columns of table "calendly_invitee" */
export enum Calendly_Invitee_Select_Column_Calendly_Invitee_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsRescheduled = 'is_rescheduled',
}

/** input type for updating data in table "calendly_invitee" */
export type Calendly_Invitee_Set_Input = {
  cancel_url?: InputMaybe<Scalars['String']>;
  client_prospect_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  event_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  interview_request_id?: InputMaybe<Scalars['Int']>;
  invitee_url?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_rescheduled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  questions_and_answers?: InputMaybe<Scalars['jsonb']>;
  raw_cancelled?: InputMaybe<Scalars['jsonb']>;
  raw_created?: InputMaybe<Scalars['jsonb']>;
  reschedule_url?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Calendly_Invitee_Stddev_Fields = {
  __typename?: 'calendly_invitee_stddev_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interview_request_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "calendly_invitee" */
export type Calendly_Invitee_Stddev_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_request_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Calendly_Invitee_Stddev_Pop_Fields = {
  __typename?: 'calendly_invitee_stddev_pop_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interview_request_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "calendly_invitee" */
export type Calendly_Invitee_Stddev_Pop_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_request_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Calendly_Invitee_Stddev_Samp_Fields = {
  __typename?: 'calendly_invitee_stddev_samp_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interview_request_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "calendly_invitee" */
export type Calendly_Invitee_Stddev_Samp_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_request_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "calendly_invitee" */
export type Calendly_Invitee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendly_Invitee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendly_Invitee_Stream_Cursor_Value_Input = {
  cancel_url?: InputMaybe<Scalars['String']>;
  client_prospect_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  event_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  interview_request_id?: InputMaybe<Scalars['Int']>;
  invitee_url?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_rescheduled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  questions_and_answers?: InputMaybe<Scalars['jsonb']>;
  raw_cancelled?: InputMaybe<Scalars['jsonb']>;
  raw_created?: InputMaybe<Scalars['jsonb']>;
  reschedule_url?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Calendly_Invitee_Sum_Fields = {
  __typename?: 'calendly_invitee_sum_fields';
  client_prospect_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  interview_request_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "calendly_invitee" */
export type Calendly_Invitee_Sum_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_request_id?: InputMaybe<Order_By>;
};

/** update columns of table "calendly_invitee" */
export enum Calendly_Invitee_Update_Column {
  /** column name */
  CancelUrl = 'cancel_url',
  /** column name */
  ClientProspectId = 'client_prospect_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EventUrl = 'event_url',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewRequestId = 'interview_request_id',
  /** column name */
  InviteeUrl = 'invitee_url',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsRescheduled = 'is_rescheduled',
  /** column name */
  Name = 'name',
  /** column name */
  QuestionsAndAnswers = 'questions_and_answers',
  /** column name */
  RawCancelled = 'raw_cancelled',
  /** column name */
  RawCreated = 'raw_created',
  /** column name */
  RescheduleUrl = 'reschedule_url',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Calendly_Invitee_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Calendly_Invitee_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Calendly_Invitee_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Calendly_Invitee_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Calendly_Invitee_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Calendly_Invitee_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Calendly_Invitee_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calendly_Invitee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calendly_Invitee_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Calendly_Invitee_Var_Pop_Fields = {
  __typename?: 'calendly_invitee_var_pop_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interview_request_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "calendly_invitee" */
export type Calendly_Invitee_Var_Pop_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_request_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Calendly_Invitee_Var_Samp_Fields = {
  __typename?: 'calendly_invitee_var_samp_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interview_request_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "calendly_invitee" */
export type Calendly_Invitee_Var_Samp_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_request_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Calendly_Invitee_Variance_Fields = {
  __typename?: 'calendly_invitee_variance_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interview_request_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "calendly_invitee" */
export type Calendly_Invitee_Variance_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_request_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "calendly_user" */
export type Calendly_User = {
  __typename?: 'calendly_user';
  calendly_user_id: Scalars['String'];
  exploratory_chat_url: Maybe<Scalars['String']>;
  exploratory_chat_url_type: Maybe<Scalars['String']>;
  /** An object relationship */
  terminal_employee: Maybe<Terminal_Employee>;
  terminal_screen_url: Maybe<Scalars['String']>;
  terminal_screen_url_type: Maybe<Scalars['String']>;
  /** An object relationship */
  user_info: Maybe<User_Info>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** aggregated selection of "calendly_user" */
export type Calendly_User_Aggregate = {
  __typename?: 'calendly_user_aggregate';
  aggregate: Maybe<Calendly_User_Aggregate_Fields>;
  nodes: Array<Calendly_User>;
};

/** aggregate fields of "calendly_user" */
export type Calendly_User_Aggregate_Fields = {
  __typename?: 'calendly_user_aggregate_fields';
  avg: Maybe<Calendly_User_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Calendly_User_Max_Fields>;
  min: Maybe<Calendly_User_Min_Fields>;
  stddev: Maybe<Calendly_User_Stddev_Fields>;
  stddev_pop: Maybe<Calendly_User_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Calendly_User_Stddev_Samp_Fields>;
  sum: Maybe<Calendly_User_Sum_Fields>;
  var_pop: Maybe<Calendly_User_Var_Pop_Fields>;
  var_samp: Maybe<Calendly_User_Var_Samp_Fields>;
  variance: Maybe<Calendly_User_Variance_Fields>;
};

/** aggregate fields of "calendly_user" */
export type Calendly_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendly_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Calendly_User_Avg_Fields = {
  __typename?: 'calendly_user_avg_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "calendly_user". All fields are combined with a logical 'AND'. */
export type Calendly_User_Bool_Exp = {
  _and?: InputMaybe<Array<Calendly_User_Bool_Exp>>;
  _not?: InputMaybe<Calendly_User_Bool_Exp>;
  _or?: InputMaybe<Array<Calendly_User_Bool_Exp>>;
  calendly_user_id?: InputMaybe<String_Comparison_Exp>;
  exploratory_chat_url?: InputMaybe<String_Comparison_Exp>;
  exploratory_chat_url_type?: InputMaybe<String_Comparison_Exp>;
  terminal_employee?: InputMaybe<Terminal_Employee_Bool_Exp>;
  terminal_screen_url?: InputMaybe<String_Comparison_Exp>;
  terminal_screen_url_type?: InputMaybe<String_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
  user_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendly_user" */
export enum Calendly_User_Constraint {
  /** unique or primary key constraint on columns "calendly_user_id" */
  CalendlyUserPkey = 'calendly_user_pkey',
}

/** input type for incrementing numeric columns in table "calendly_user" */
export type Calendly_User_Inc_Input = {
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "calendly_user" */
export type Calendly_User_Insert_Input = {
  calendly_user_id?: InputMaybe<Scalars['String']>;
  exploratory_chat_url?: InputMaybe<Scalars['String']>;
  exploratory_chat_url_type?: InputMaybe<Scalars['String']>;
  terminal_employee?: InputMaybe<Terminal_Employee_Obj_Rel_Insert_Input>;
  terminal_screen_url?: InputMaybe<Scalars['String']>;
  terminal_screen_url_type?: InputMaybe<Scalars['String']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Calendly_User_Max_Fields = {
  __typename?: 'calendly_user_max_fields';
  calendly_user_id: Maybe<Scalars['String']>;
  exploratory_chat_url: Maybe<Scalars['String']>;
  exploratory_chat_url_type: Maybe<Scalars['String']>;
  terminal_screen_url: Maybe<Scalars['String']>;
  terminal_screen_url_type: Maybe<Scalars['String']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Calendly_User_Min_Fields = {
  __typename?: 'calendly_user_min_fields';
  calendly_user_id: Maybe<Scalars['String']>;
  exploratory_chat_url: Maybe<Scalars['String']>;
  exploratory_chat_url_type: Maybe<Scalars['String']>;
  terminal_screen_url: Maybe<Scalars['String']>;
  terminal_screen_url_type: Maybe<Scalars['String']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "calendly_user" */
export type Calendly_User_Mutation_Response = {
  __typename?: 'calendly_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calendly_User>;
};

/** input type for inserting object relation for remote table "calendly_user" */
export type Calendly_User_Obj_Rel_Insert_Input = {
  data: Calendly_User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Calendly_User_On_Conflict>;
};

/** on_conflict condition type for table "calendly_user" */
export type Calendly_User_On_Conflict = {
  constraint: Calendly_User_Constraint;
  update_columns?: Array<Calendly_User_Update_Column>;
  where?: InputMaybe<Calendly_User_Bool_Exp>;
};

/** Ordering options when selecting data from "calendly_user". */
export type Calendly_User_Order_By = {
  calendly_user_id?: InputMaybe<Order_By>;
  exploratory_chat_url?: InputMaybe<Order_By>;
  exploratory_chat_url_type?: InputMaybe<Order_By>;
  terminal_employee?: InputMaybe<Terminal_Employee_Order_By>;
  terminal_screen_url?: InputMaybe<Order_By>;
  terminal_screen_url_type?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calendly_user */
export type Calendly_User_Pk_Columns_Input = {
  calendly_user_id: Scalars['String'];
};

/** select columns of table "calendly_user" */
export enum Calendly_User_Select_Column {
  /** column name */
  CalendlyUserId = 'calendly_user_id',
  /** column name */
  ExploratoryChatUrl = 'exploratory_chat_url',
  /** column name */
  ExploratoryChatUrlType = 'exploratory_chat_url_type',
  /** column name */
  TerminalScreenUrl = 'terminal_screen_url',
  /** column name */
  TerminalScreenUrlType = 'terminal_screen_url_type',
  /** column name */
  UserInfoId = 'user_info_id',
}

/** input type for updating data in table "calendly_user" */
export type Calendly_User_Set_Input = {
  calendly_user_id?: InputMaybe<Scalars['String']>;
  exploratory_chat_url?: InputMaybe<Scalars['String']>;
  exploratory_chat_url_type?: InputMaybe<Scalars['String']>;
  terminal_screen_url?: InputMaybe<Scalars['String']>;
  terminal_screen_url_type?: InputMaybe<Scalars['String']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Calendly_User_Stddev_Fields = {
  __typename?: 'calendly_user_stddev_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Calendly_User_Stddev_Pop_Fields = {
  __typename?: 'calendly_user_stddev_pop_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Calendly_User_Stddev_Samp_Fields = {
  __typename?: 'calendly_user_stddev_samp_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "calendly_user" */
export type Calendly_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendly_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendly_User_Stream_Cursor_Value_Input = {
  calendly_user_id?: InputMaybe<Scalars['String']>;
  exploratory_chat_url?: InputMaybe<Scalars['String']>;
  exploratory_chat_url_type?: InputMaybe<Scalars['String']>;
  terminal_screen_url?: InputMaybe<Scalars['String']>;
  terminal_screen_url_type?: InputMaybe<Scalars['String']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Calendly_User_Sum_Fields = {
  __typename?: 'calendly_user_sum_fields';
  user_info_id: Maybe<Scalars['Int']>;
};

/** update columns of table "calendly_user" */
export enum Calendly_User_Update_Column {
  /** column name */
  CalendlyUserId = 'calendly_user_id',
  /** column name */
  ExploratoryChatUrl = 'exploratory_chat_url',
  /** column name */
  ExploratoryChatUrlType = 'exploratory_chat_url_type',
  /** column name */
  TerminalScreenUrl = 'terminal_screen_url',
  /** column name */
  TerminalScreenUrlType = 'terminal_screen_url_type',
  /** column name */
  UserInfoId = 'user_info_id',
}

export type Calendly_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Calendly_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calendly_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Calendly_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Calendly_User_Var_Pop_Fields = {
  __typename?: 'calendly_user_var_pop_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Calendly_User_Var_Samp_Fields = {
  __typename?: 'calendly_user_var_samp_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Calendly_User_Variance_Fields = {
  __typename?: 'calendly_user_variance_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** Primary source of truth for all Candidate related data. */
export type Candidate = {
  __typename?: 'candidate';
  /** An array relationship */
  applicant_consents: Array<Applicant_Consent>;
  /** An aggregate relationship */
  applicant_consents_aggregate: Applicant_Consent_Aggregate;
  /** An array relationship */
  applicant_jobs: Array<Applicant_Job>;
  /** An aggregate relationship */
  applicant_jobs_aggregate: Applicant_Job_Aggregate;
  availability: Maybe<Candidate_Availability_Choices_Enum>;
  /** An array relationship */
  candidate_ai_profile_summaries: Array<Candidate_Ai_Profile_Summary>;
  /** An aggregate relationship */
  candidate_ai_profile_summaries_aggregate: Candidate_Ai_Profile_Summary_Aggregate;
  /** An object relationship */
  candidate_availability_choice: Maybe<Candidate_Availability_Choices>;
  /** An object relationship */
  candidate_badge: Maybe<Candidate_Badge>;
  /** An array relationship */
  candidate_curation_ai_summaries: Array<Candidate_Curation_Ai_Summary>;
  /** An aggregate relationship */
  candidate_curation_ai_summaries_aggregate: Candidate_Curation_Ai_Summary_Aggregate;
  /** An object relationship */
  candidate_curation_detail: Maybe<Candidate_Curation_Detail>;
  /** An array relationship */
  candidate_curation_workflows: Array<Candidate_Curation_Workflow>;
  /** An aggregate relationship */
  candidate_curation_workflows_aggregate: Candidate_Curation_Workflow_Aggregate;
  /** An array relationship */
  candidate_educations: Array<Candidate_Education>;
  /** An aggregate relationship */
  candidate_educations_aggregate: Candidate_Education_Aggregate;
  /** An array relationship */
  candidate_emails: Array<Candidate_Email>;
  /** An aggregate relationship */
  candidate_emails_aggregate: Candidate_Email_Aggregate;
  /** An array relationship */
  candidate_hireflix_transcripts: Array<Hireflix_Transcript>;
  /** An aggregate relationship */
  candidate_hireflix_transcripts_aggregate: Hireflix_Transcript_Aggregate;
  /** An array relationship */
  candidate_hub_locations: Array<Candidate_Hub_Location>;
  /** An aggregate relationship */
  candidate_hub_locations_aggregate: Candidate_Hub_Location_Aggregate;
  /** An array relationship */
  candidate_job_workflows: Array<Candidate_Job_Workflow>;
  /** An aggregate relationship */
  candidate_job_workflows_aggregate: Candidate_Job_Workflow_Aggregate;
  /** An array relationship */
  candidate_jobs: Array<Candidate_Job>;
  /** An aggregate relationship */
  candidate_jobs_aggregate: Candidate_Job_Aggregate;
  /** An object relationship */
  candidate_pdl: Maybe<Candidate_Pdl>;
  /** An array relationship */
  candidate_pillar_transcripts: Array<Pillar_Transcript>;
  /** An aggregate relationship */
  candidate_pillar_transcripts_aggregate: Pillar_Transcript_Aggregate;
  /** An array relationship */
  candidate_roles: Array<Candidate_Role>;
  /** An aggregate relationship */
  candidate_roles_aggregate: Candidate_Role_Aggregate;
  /** An array relationship */
  candidate_skills: Array<Candidate_Skill>;
  /** An aggregate relationship */
  candidate_skills_aggregate: Candidate_Skill_Aggregate;
  /** An array relationship */
  candidate_stage_logs: Array<Candidate_Stage_Log>;
  /** An aggregate relationship */
  candidate_stage_logs_aggregate: Candidate_Stage_Log_Aggregate;
  /** An array relationship */
  candidate_work_experiences: Array<Candidate_Work_Experience>;
  /** An aggregate relationship */
  candidate_work_experiences_aggregate: Candidate_Work_Experience_Aggregate;
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  /** An object relationship */
  country_choice: Maybe<Country_Choices>;
  country_id: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  curation_countries: Array<Candidate_Approved_Country>;
  /** An aggregate relationship */
  curation_countries_aggregate: Candidate_Approved_Country_Aggregate;
  /** An array relationship */
  curation_notes: Array<Candidate_Curation_Note>;
  /** An aggregate relationship */
  curation_notes_aggregate: Candidate_Curation_Note_Aggregate;
  /** An object relationship */
  dayforce_employee: Maybe<Dayforce_Employee>;
  email: Maybe<Scalars['citext']>;
  employment_type: Maybe<Employment_Type_Choices_Enum>;
  first_name: Maybe<Scalars['String']>;
  /** An object relationship */
  furthest_candidate_curation_workflow: Maybe<Candidate_Curation_Workflow>;
  furthest_candidate_curation_workflow_id: Maybe<Scalars['Int']>;
  furthest_stage: Candidate_Stage_Choices_Enum;
  gem_id: Maybe<Scalars['String']>;
  github_url: Maybe<Scalars['String']>;
  /** An array relationship */
  hr_test_results: Array<Hr_Test_Result>;
  /** An aggregate relationship */
  hr_test_results_aggregate: Hr_Test_Result_Aggregate;
  icims_person_profile_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  icims_profile_person: Maybe<Icims_Profile_Person>;
  id: Scalars['Int'];
  /** An array relationship */
  interview_requests: Array<Interview_Request>;
  /** An aggregate relationship */
  interview_requests_aggregate: Interview_Request_Aggregate;
  is_active: Scalars['Boolean'];
  /** The candidate has worked or is currently working for our customers */
  is_previous_hire: Maybe<Scalars['Boolean']>;
  last_name: Maybe<Scalars['String']>;
  last_name_initial: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['numeric']>;
  linkedin_url: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['numeric']>;
  merge_candidate_id: Maybe<Scalars['String']>;
  open_to_all_companies: Maybe<Scalars['Boolean']>;
  other_url: Maybe<Scalars['String']>;
  owner: Maybe<Scalars['Int']>;
  /** An object relationship */
  owner_person: Maybe<Icims_Profile_Person>;
  /** An object relationship */
  parsed_resume: Maybe<Candidate_Parsed_Resume>;
  phone: Maybe<Scalars['String']>;
  profile_complete_override: Scalars['Boolean'];
  profile_flow_completed_at: Maybe<Scalars['timestamptz']>;
  profile_flow_furthest_step: Maybe<Scalars['String']>;
  profile_flow_started_at: Maybe<Scalars['timestamptz']>;
  profile_flow_variations: Maybe<Scalars['jsonb']>;
  public_id: Scalars['uuid'];
  /** An array relationship */
  referred_by: Array<Candidate_Referrals>;
  /** An aggregate relationship */
  referred_by_aggregate: Candidate_Referrals_Aggregate;
  resume_filename: Maybe<Scalars['String']>;
  /** An array relationship */
  saved_candidates: Array<Saved_Candidate>;
  /** An aggregate relationship */
  saved_candidates_aggregate: Saved_Candidate_Aggregate;
  source: Candidate_Source_Choices_Enum;
  stage: Candidate_Stage_Choices_Enum;
  stage_source: Candidate_Stage_Source_Choices_Enum;
  state: Maybe<Scalars['String']>;
  sync_status: Icims_Sync_Status_Choices_Enum;
  /** An array relationship */
  transcript_evaluations: Array<Transcript_Evaluation>;
  /** An aggregate relationship */
  transcript_evaluations_aggregate: Transcript_Evaluation_Aggregate;
  /** An array relationship */
  transcript_skills: Array<Transcript_Skill>;
  /** An aggregate relationship */
  transcript_skills_aggregate: Transcript_Skill_Aggregate;
  /** An array relationship */
  transcript_work_experiences: Array<Transcript_Work_Experience>;
  /** An aggregate relationship */
  transcript_work_experiences_aggregate: Transcript_Work_Experience_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_info: Maybe<User_Info>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateApplicant_ConsentsArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Consent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Consent_Order_By>>;
  where?: InputMaybe<Applicant_Consent_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateApplicant_Consents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Consent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Consent_Order_By>>;
  where?: InputMaybe<Applicant_Consent_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateApplicant_JobsArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Job_Order_By>>;
  where?: InputMaybe<Applicant_Job_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateApplicant_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Job_Order_By>>;
  where?: InputMaybe<Applicant_Job_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Ai_Profile_SummariesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Ai_Profile_Summary_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Ai_Profile_Summaries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Ai_Profile_Summary_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Curation_Ai_SummariesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Curation_Ai_Summaries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Curation_WorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Curation_Workflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_EducationsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Order_By>>;
  where?: InputMaybe<Candidate_Education_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Educations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Order_By>>;
  where?: InputMaybe<Candidate_Education_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Email_Order_By>>;
  where?: InputMaybe<Candidate_Email_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Email_Order_By>>;
  where?: InputMaybe<Candidate_Email_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Hireflix_TranscriptsArgs = {
  distinct_on?: InputMaybe<Array<Hireflix_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hireflix_Transcript_Order_By>>;
  where?: InputMaybe<Hireflix_Transcript_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Hireflix_Transcripts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hireflix_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hireflix_Transcript_Order_By>>;
  where?: InputMaybe<Hireflix_Transcript_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Hub_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Hub_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Hub_Location_Order_By>>;
  where?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Hub_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Hub_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Hub_Location_Order_By>>;
  where?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Job_WorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Job_Workflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_JobsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Order_By>>;
  where?: InputMaybe<Candidate_Job_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Order_By>>;
  where?: InputMaybe<Candidate_Job_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Pillar_TranscriptsArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Transcript_Order_By>>;
  where?: InputMaybe<Pillar_Transcript_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Pillar_Transcripts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Transcript_Order_By>>;
  where?: InputMaybe<Pillar_Transcript_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_RolesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Order_By>>;
  where?: InputMaybe<Candidate_Role_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Order_By>>;
  where?: InputMaybe<Candidate_Role_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Stage_LogsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Log_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Log_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Stage_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Log_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Log_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Work_ExperiencesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCandidate_Work_Experiences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCuration_CountriesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Approved_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Approved_Country_Order_By>>;
  where?: InputMaybe<Candidate_Approved_Country_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCuration_Countries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Approved_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Approved_Country_Order_By>>;
  where?: InputMaybe<Candidate_Approved_Country_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCuration_NotesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Note_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Note_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateCuration_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Note_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Note_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateHr_Test_ResultsArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Result_Order_By>>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateHr_Test_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Result_Order_By>>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateInterview_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Interview_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Request_Order_By>>;
  where?: InputMaybe<Interview_Request_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateInterview_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Request_Order_By>>;
  where?: InputMaybe<Interview_Request_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateProfile_Flow_VariationsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateReferred_ByArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Referrals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Referrals_Order_By>>;
  where?: InputMaybe<Candidate_Referrals_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateReferred_By_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Referrals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Referrals_Order_By>>;
  where?: InputMaybe<Candidate_Referrals_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateSaved_CandidatesArgs = {
  distinct_on?: InputMaybe<Array<Saved_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Saved_Candidate_Order_By>>;
  where?: InputMaybe<Saved_Candidate_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateSaved_Candidates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Saved_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Saved_Candidate_Order_By>>;
  where?: InputMaybe<Saved_Candidate_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateTranscript_EvaluationsArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Evaluation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Evaluation_Order_By>>;
  where?: InputMaybe<Transcript_Evaluation_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateTranscript_Evaluations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Evaluation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Evaluation_Order_By>>;
  where?: InputMaybe<Transcript_Evaluation_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateTranscript_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Skill_Order_By>>;
  where?: InputMaybe<Transcript_Skill_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateTranscript_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Skill_Order_By>>;
  where?: InputMaybe<Transcript_Skill_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateTranscript_Work_ExperiencesArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Work_Experience_Order_By>>;
  where?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
};

/** Primary source of truth for all Candidate related data. */
export type CandidateTranscript_Work_Experiences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Work_Experience_Order_By>>;
  where?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
};

/** aggregated selection of "candidate" */
export type Candidate_Aggregate = {
  __typename?: 'candidate_aggregate';
  aggregate: Maybe<Candidate_Aggregate_Fields>;
  nodes: Array<Candidate>;
};

export type Candidate_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Candidate_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Candidate_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Candidate_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Aggregate_Bool_Exp_Bool_And = {
  arguments: Candidate_Select_Column_Candidate_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Candidate_Select_Column_Candidate_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate" */
export type Candidate_Aggregate_Fields = {
  __typename?: 'candidate_aggregate_fields';
  avg: Maybe<Candidate_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Max_Fields>;
  min: Maybe<Candidate_Min_Fields>;
  stddev: Maybe<Candidate_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Sum_Fields>;
  var_pop: Maybe<Candidate_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Var_Samp_Fields>;
  variance: Maybe<Candidate_Variance_Fields>;
};

/** aggregate fields of "candidate" */
export type Candidate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate" */
export type Candidate_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Max_Order_By>;
  min?: InputMaybe<Candidate_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Variance_Order_By>;
};

/** columns and relationships of "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary = {
  __typename?: 'candidate_ai_profile_summary';
  blob: Maybe<Scalars['jsonb']>;
  candidate_id: Scalars['Int'];
  id: Scalars['Int'];
  prompt: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  version: Scalars['String'];
};

/** columns and relationships of "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_SummaryBlobArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Aggregate = {
  __typename?: 'candidate_ai_profile_summary_aggregate';
  aggregate: Maybe<Candidate_Ai_Profile_Summary_Aggregate_Fields>;
  nodes: Array<Candidate_Ai_Profile_Summary>;
};

export type Candidate_Ai_Profile_Summary_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Ai_Profile_Summary_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Ai_Profile_Summary_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Ai_Profile_Summary_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Aggregate_Fields = {
  __typename?: 'candidate_ai_profile_summary_aggregate_fields';
  avg: Maybe<Candidate_Ai_Profile_Summary_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Ai_Profile_Summary_Max_Fields>;
  min: Maybe<Candidate_Ai_Profile_Summary_Min_Fields>;
  stddev: Maybe<Candidate_Ai_Profile_Summary_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Ai_Profile_Summary_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Ai_Profile_Summary_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Ai_Profile_Summary_Sum_Fields>;
  var_pop: Maybe<Candidate_Ai_Profile_Summary_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Ai_Profile_Summary_Var_Samp_Fields>;
  variance: Maybe<Candidate_Ai_Profile_Summary_Variance_Fields>;
};

/** aggregate fields of "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Ai_Profile_Summary_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Ai_Profile_Summary_Max_Order_By>;
  min?: InputMaybe<Candidate_Ai_Profile_Summary_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Ai_Profile_Summary_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Ai_Profile_Summary_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Ai_Profile_Summary_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Ai_Profile_Summary_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Ai_Profile_Summary_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Ai_Profile_Summary_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Ai_Profile_Summary_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Ai_Profile_Summary_Append_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Ai_Profile_Summary_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Ai_Profile_Summary_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Ai_Profile_Summary_Avg_Fields = {
  __typename?: 'candidate_ai_profile_summary_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_ai_profile_summary". All fields are combined with a logical 'AND'. */
export type Candidate_Ai_Profile_Summary_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Ai_Profile_Summary_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Bool_Exp>>;
  blob?: InputMaybe<Jsonb_Comparison_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  prompt?: InputMaybe<String_Comparison_Exp>;
  summary?: InputMaybe<String_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_ai_profile_summary" */
export enum Candidate_Ai_Profile_Summary_Constraint {
  /** unique or primary key constraint on columns "candidate_id", "version" */
  CandidateAiProfileSummaryCandidateIdVersionKey = 'candidate_ai_profile_summary_candidate_id_version_key',
  /** unique or primary key constraint on columns "id" */
  CandidateAiProfileSummaryPkey = 'candidate_ai_profile_summary_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Candidate_Ai_Profile_Summary_Delete_At_Path_Input = {
  blob?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Candidate_Ai_Profile_Summary_Delete_Elem_Input = {
  blob?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Candidate_Ai_Profile_Summary_Delete_Key_Input = {
  blob?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Insert_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  prompt?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Ai_Profile_Summary_Max_Fields = {
  __typename?: 'candidate_ai_profile_summary_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  prompt: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  version: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prompt?: InputMaybe<Order_By>;
  summary?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Ai_Profile_Summary_Min_Fields = {
  __typename?: 'candidate_ai_profile_summary_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  prompt: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  version: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prompt?: InputMaybe<Order_By>;
  summary?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Mutation_Response = {
  __typename?: 'candidate_ai_profile_summary_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Ai_Profile_Summary>;
};

/** on_conflict condition type for table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_On_Conflict = {
  constraint: Candidate_Ai_Profile_Summary_Constraint;
  update_columns?: Array<Candidate_Ai_Profile_Summary_Update_Column>;
  where?: InputMaybe<Candidate_Ai_Profile_Summary_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_ai_profile_summary". */
export type Candidate_Ai_Profile_Summary_Order_By = {
  blob?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prompt?: InputMaybe<Order_By>;
  summary?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_ai_profile_summary */
export type Candidate_Ai_Profile_Summary_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Ai_Profile_Summary_Prepend_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "candidate_ai_profile_summary" */
export enum Candidate_Ai_Profile_Summary_Select_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Id = 'id',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  Summary = 'summary',
  /** column name */
  Version = 'version',
}

/** input type for updating data in table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Set_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  prompt?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Candidate_Ai_Profile_Summary_Stddev_Fields = {
  __typename?: 'candidate_ai_profile_summary_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Ai_Profile_Summary_Stddev_Pop_Fields = {
  __typename?: 'candidate_ai_profile_summary_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Ai_Profile_Summary_Stddev_Samp_Fields = {
  __typename?: 'candidate_ai_profile_summary_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Ai_Profile_Summary_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Ai_Profile_Summary_Stream_Cursor_Value_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  prompt?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Candidate_Ai_Profile_Summary_Sum_Fields = {
  __typename?: 'candidate_ai_profile_summary_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_ai_profile_summary" */
export enum Candidate_Ai_Profile_Summary_Update_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Id = 'id',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  Summary = 'summary',
  /** column name */
  Version = 'version',
}

export type Candidate_Ai_Profile_Summary_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Candidate_Ai_Profile_Summary_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Candidate_Ai_Profile_Summary_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Candidate_Ai_Profile_Summary_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Candidate_Ai_Profile_Summary_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Ai_Profile_Summary_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Candidate_Ai_Profile_Summary_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Ai_Profile_Summary_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Ai_Profile_Summary_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Ai_Profile_Summary_Var_Pop_Fields = {
  __typename?: 'candidate_ai_profile_summary_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Ai_Profile_Summary_Var_Samp_Fields = {
  __typename?: 'candidate_ai_profile_summary_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Ai_Profile_Summary_Variance_Fields = {
  __typename?: 'candidate_ai_profile_summary_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_ai_profile_summary" */
export type Candidate_Ai_Profile_Summary_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Append_Input = {
  profile_flow_variations?: InputMaybe<Scalars['jsonb']>;
};

export type Candidate_Applicant_Workflow_Stats_Args = {
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** This table is only used for its Hasura generated type, don't populate it. */
export type Candidate_Applicant_Workflow_Stats_Result = {
  __typename?: 'candidate_applicant_workflow_stats_result';
  in_interview_count: Maybe<Scalars['Int']>;
};

export type Candidate_Applicant_Workflow_Stats_Result_Aggregate = {
  __typename?: 'candidate_applicant_workflow_stats_result_aggregate';
  aggregate: Maybe<Candidate_Applicant_Workflow_Stats_Result_Aggregate_Fields>;
  nodes: Array<Candidate_Applicant_Workflow_Stats_Result>;
};

/** aggregate fields of "candidate_applicant_workflow_stats_result" */
export type Candidate_Applicant_Workflow_Stats_Result_Aggregate_Fields = {
  __typename?: 'candidate_applicant_workflow_stats_result_aggregate_fields';
  avg: Maybe<Candidate_Applicant_Workflow_Stats_Result_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Applicant_Workflow_Stats_Result_Max_Fields>;
  min: Maybe<Candidate_Applicant_Workflow_Stats_Result_Min_Fields>;
  stddev: Maybe<Candidate_Applicant_Workflow_Stats_Result_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Applicant_Workflow_Stats_Result_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Applicant_Workflow_Stats_Result_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Applicant_Workflow_Stats_Result_Sum_Fields>;
  var_pop: Maybe<Candidate_Applicant_Workflow_Stats_Result_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Applicant_Workflow_Stats_Result_Var_Samp_Fields>;
  variance: Maybe<Candidate_Applicant_Workflow_Stats_Result_Variance_Fields>;
};

/** aggregate fields of "candidate_applicant_workflow_stats_result" */
export type Candidate_Applicant_Workflow_Stats_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Applicant_Workflow_Stats_Result_Avg_Fields = {
  __typename?: 'candidate_applicant_workflow_stats_result_avg_fields';
  in_interview_count: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_applicant_workflow_stats_result". All fields are combined with a logical 'AND'. */
export type Candidate_Applicant_Workflow_Stats_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>>;
  in_interview_count?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "candidate_applicant_workflow_stats_result" */
export type Candidate_Applicant_Workflow_Stats_Result_Inc_Input = {
  in_interview_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_applicant_workflow_stats_result" */
export type Candidate_Applicant_Workflow_Stats_Result_Insert_Input = {
  in_interview_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Candidate_Applicant_Workflow_Stats_Result_Max_Fields = {
  __typename?: 'candidate_applicant_workflow_stats_result_max_fields';
  in_interview_count: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Candidate_Applicant_Workflow_Stats_Result_Min_Fields = {
  __typename?: 'candidate_applicant_workflow_stats_result_min_fields';
  in_interview_count: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "candidate_applicant_workflow_stats_result" */
export type Candidate_Applicant_Workflow_Stats_Result_Mutation_Response = {
  __typename?: 'candidate_applicant_workflow_stats_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Applicant_Workflow_Stats_Result>;
};

/** Ordering options when selecting data from "candidate_applicant_workflow_stats_result". */
export type Candidate_Applicant_Workflow_Stats_Result_Order_By = {
  in_interview_count?: InputMaybe<Order_By>;
};

/** select columns of table "candidate_applicant_workflow_stats_result" */
export enum Candidate_Applicant_Workflow_Stats_Result_Select_Column {
  /** column name */
  InInterviewCount = 'in_interview_count',
}

/** input type for updating data in table "candidate_applicant_workflow_stats_result" */
export type Candidate_Applicant_Workflow_Stats_Result_Set_Input = {
  in_interview_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Candidate_Applicant_Workflow_Stats_Result_Stddev_Fields = {
  __typename?: 'candidate_applicant_workflow_stats_result_stddev_fields';
  in_interview_count: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Applicant_Workflow_Stats_Result_Stddev_Pop_Fields = {
  __typename?: 'candidate_applicant_workflow_stats_result_stddev_pop_fields';
  in_interview_count: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Applicant_Workflow_Stats_Result_Stddev_Samp_Fields = {
  __typename?: 'candidate_applicant_workflow_stats_result_stddev_samp_fields';
  in_interview_count: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_applicant_workflow_stats_result" */
export type Candidate_Applicant_Workflow_Stats_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Applicant_Workflow_Stats_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Applicant_Workflow_Stats_Result_Stream_Cursor_Value_Input = {
  in_interview_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Applicant_Workflow_Stats_Result_Sum_Fields = {
  __typename?: 'candidate_applicant_workflow_stats_result_sum_fields';
  in_interview_count: Maybe<Scalars['Int']>;
};

export type Candidate_Applicant_Workflow_Stats_Result_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Applicant_Workflow_Stats_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Applicant_Workflow_Stats_Result_Var_Pop_Fields = {
  __typename?: 'candidate_applicant_workflow_stats_result_var_pop_fields';
  in_interview_count: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Applicant_Workflow_Stats_Result_Var_Samp_Fields = {
  __typename?: 'candidate_applicant_workflow_stats_result_var_samp_fields';
  in_interview_count: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Applicant_Workflow_Stats_Result_Variance_Fields = {
  __typename?: 'candidate_applicant_workflow_stats_result_variance_fields';
  in_interview_count: Maybe<Scalars['Float']>;
};

/** columns and relationships of "candidate_approved_country" */
export type Candidate_Approved_Country = {
  __typename?: 'candidate_approved_country';
  candidate_id: Scalars['Int'];
  country: Candidate_Curation_Country_Choices_Enum;
  id: Scalars['Int'];
  visa_status: Maybe<Candidate_Curation_Visa_Status_Choices_Enum>;
};

/** aggregated selection of "candidate_approved_country" */
export type Candidate_Approved_Country_Aggregate = {
  __typename?: 'candidate_approved_country_aggregate';
  aggregate: Maybe<Candidate_Approved_Country_Aggregate_Fields>;
  nodes: Array<Candidate_Approved_Country>;
};

export type Candidate_Approved_Country_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Approved_Country_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Approved_Country_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Approved_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Approved_Country_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_approved_country" */
export type Candidate_Approved_Country_Aggregate_Fields = {
  __typename?: 'candidate_approved_country_aggregate_fields';
  avg: Maybe<Candidate_Approved_Country_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Approved_Country_Max_Fields>;
  min: Maybe<Candidate_Approved_Country_Min_Fields>;
  stddev: Maybe<Candidate_Approved_Country_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Approved_Country_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Approved_Country_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Approved_Country_Sum_Fields>;
  var_pop: Maybe<Candidate_Approved_Country_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Approved_Country_Var_Samp_Fields>;
  variance: Maybe<Candidate_Approved_Country_Variance_Fields>;
};

/** aggregate fields of "candidate_approved_country" */
export type Candidate_Approved_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Approved_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_approved_country" */
export type Candidate_Approved_Country_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Approved_Country_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Approved_Country_Max_Order_By>;
  min?: InputMaybe<Candidate_Approved_Country_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Approved_Country_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Approved_Country_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Approved_Country_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Approved_Country_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Approved_Country_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Approved_Country_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Approved_Country_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_approved_country" */
export type Candidate_Approved_Country_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Approved_Country_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Approved_Country_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Approved_Country_Avg_Fields = {
  __typename?: 'candidate_approved_country_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_approved_country" */
export type Candidate_Approved_Country_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_approved_country". All fields are combined with a logical 'AND'. */
export type Candidate_Approved_Country_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Approved_Country_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Approved_Country_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Approved_Country_Bool_Exp>>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  country?: InputMaybe<Candidate_Curation_Country_Choices_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  visa_status?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_approved_country" */
export enum Candidate_Approved_Country_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateApprovedCountryPkey = 'candidate_approved_country_pkey',
}

/** input type for incrementing numeric columns in table "candidate_approved_country" */
export type Candidate_Approved_Country_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_approved_country" */
export type Candidate_Approved_Country_Insert_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Candidate_Curation_Country_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  visa_status?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Enum>;
};

/** aggregate max on columns */
export type Candidate_Approved_Country_Max_Fields = {
  __typename?: 'candidate_approved_country_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_approved_country" */
export type Candidate_Approved_Country_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Approved_Country_Min_Fields = {
  __typename?: 'candidate_approved_country_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_approved_country" */
export type Candidate_Approved_Country_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_approved_country" */
export type Candidate_Approved_Country_Mutation_Response = {
  __typename?: 'candidate_approved_country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Approved_Country>;
};

/** on_conflict condition type for table "candidate_approved_country" */
export type Candidate_Approved_Country_On_Conflict = {
  constraint: Candidate_Approved_Country_Constraint;
  update_columns?: Array<Candidate_Approved_Country_Update_Column>;
  where?: InputMaybe<Candidate_Approved_Country_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_approved_country". */
export type Candidate_Approved_Country_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  visa_status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_approved_country */
export type Candidate_Approved_Country_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_approved_country" */
export enum Candidate_Approved_Country_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Country = 'country',
  /** column name */
  Id = 'id',
  /** column name */
  VisaStatus = 'visa_status',
}

/** input type for updating data in table "candidate_approved_country" */
export type Candidate_Approved_Country_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Candidate_Curation_Country_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  visa_status?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Enum>;
};

/** aggregate stddev on columns */
export type Candidate_Approved_Country_Stddev_Fields = {
  __typename?: 'candidate_approved_country_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_approved_country" */
export type Candidate_Approved_Country_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Approved_Country_Stddev_Pop_Fields = {
  __typename?: 'candidate_approved_country_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_approved_country" */
export type Candidate_Approved_Country_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Approved_Country_Stddev_Samp_Fields = {
  __typename?: 'candidate_approved_country_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_approved_country" */
export type Candidate_Approved_Country_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_approved_country" */
export type Candidate_Approved_Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Approved_Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Approved_Country_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Candidate_Curation_Country_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  visa_status?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Enum>;
};

/** aggregate sum on columns */
export type Candidate_Approved_Country_Sum_Fields = {
  __typename?: 'candidate_approved_country_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_approved_country" */
export type Candidate_Approved_Country_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_approved_country" */
export enum Candidate_Approved_Country_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Country = 'country',
  /** column name */
  Id = 'id',
  /** column name */
  VisaStatus = 'visa_status',
}

export type Candidate_Approved_Country_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Approved_Country_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Approved_Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Approved_Country_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Approved_Country_Var_Pop_Fields = {
  __typename?: 'candidate_approved_country_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_approved_country" */
export type Candidate_Approved_Country_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Approved_Country_Var_Samp_Fields = {
  __typename?: 'candidate_approved_country_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_approved_country" */
export type Candidate_Approved_Country_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Approved_Country_Variance_Fields = {
  __typename?: 'candidate_approved_country_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_approved_country" */
export type Candidate_Approved_Country_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** input type for inserting array relation for remote table "candidate" */
export type Candidate_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_On_Conflict>;
};

/** Enum table for candidate availability choices */
export type Candidate_Availability_Choices = {
  __typename?: 'candidate_availability_choices';
  /** An object relationship */
  candidate_availability_choices_order: Candidate_Availability_Choices_Order;
  value: Scalars['String'];
};

/** aggregated selection of "candidate_availability_choices" */
export type Candidate_Availability_Choices_Aggregate = {
  __typename?: 'candidate_availability_choices_aggregate';
  aggregate: Maybe<Candidate_Availability_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Availability_Choices>;
};

/** aggregate fields of "candidate_availability_choices" */
export type Candidate_Availability_Choices_Aggregate_Fields = {
  __typename?: 'candidate_availability_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Availability_Choices_Max_Fields>;
  min: Maybe<Candidate_Availability_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_availability_choices" */
export type Candidate_Availability_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Availability_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_availability_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Availability_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Availability_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Availability_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Availability_Choices_Bool_Exp>>;
  candidate_availability_choices_order?: InputMaybe<Candidate_Availability_Choices_Order_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_availability_choices" */
export enum Candidate_Availability_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateAvailabilityChoicesPkey = 'candidate_availability_choices_pkey',
}

export enum Candidate_Availability_Choices_Enum {
  InterestedAndAvailableLater_3PlusMonths = 'Interested_And_Available_Later_3_Plus_Months',
  InterestedAndAvailableNow = 'Interested_And_Available_Now',
  InterestedAndAvailableSoon_1_3Months = 'Interested_And_Available_Soon_1_3_Months',
  NotInterestedInANewRole = 'Not_Interested_In_A_New_Role',
}

/** Boolean expression to compare columns of type "candidate_availability_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Availability_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Availability_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Availability_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Availability_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Availability_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_availability_choices" */
export type Candidate_Availability_Choices_Insert_Input = {
  candidate_availability_choices_order?: InputMaybe<Candidate_Availability_Choices_Order_Obj_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Availability_Choices_Max_Fields = {
  __typename?: 'candidate_availability_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Availability_Choices_Min_Fields = {
  __typename?: 'candidate_availability_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_availability_choices" */
export type Candidate_Availability_Choices_Mutation_Response = {
  __typename?: 'candidate_availability_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Availability_Choices>;
};

/** input type for inserting object relation for remote table "candidate_availability_choices" */
export type Candidate_Availability_Choices_Obj_Rel_Insert_Input = {
  data: Candidate_Availability_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Availability_Choices_On_Conflict>;
};

/** on_conflict condition type for table "candidate_availability_choices" */
export type Candidate_Availability_Choices_On_Conflict = {
  constraint: Candidate_Availability_Choices_Constraint;
  update_columns?: Array<Candidate_Availability_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Availability_Choices_Bool_Exp>;
};

/** Before creating a candidate_availability_choices, first create a row in this table. */
export type Candidate_Availability_Choices_Order = {
  __typename?: 'candidate_availability_choices_order';
  order: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_availability_choices_order" */
export type Candidate_Availability_Choices_Order_Aggregate = {
  __typename?: 'candidate_availability_choices_order_aggregate';
  aggregate: Maybe<Candidate_Availability_Choices_Order_Aggregate_Fields>;
  nodes: Array<Candidate_Availability_Choices_Order>;
};

/** aggregate fields of "candidate_availability_choices_order" */
export type Candidate_Availability_Choices_Order_Aggregate_Fields = {
  __typename?: 'candidate_availability_choices_order_aggregate_fields';
  avg: Maybe<Candidate_Availability_Choices_Order_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Availability_Choices_Order_Max_Fields>;
  min: Maybe<Candidate_Availability_Choices_Order_Min_Fields>;
  stddev: Maybe<Candidate_Availability_Choices_Order_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Availability_Choices_Order_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Availability_Choices_Order_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Availability_Choices_Order_Sum_Fields>;
  var_pop: Maybe<Candidate_Availability_Choices_Order_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Availability_Choices_Order_Var_Samp_Fields>;
  variance: Maybe<Candidate_Availability_Choices_Order_Variance_Fields>;
};

/** aggregate fields of "candidate_availability_choices_order" */
export type Candidate_Availability_Choices_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Availability_Choices_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Availability_Choices_Order_Avg_Fields = {
  __typename?: 'candidate_availability_choices_order_avg_fields';
  order: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_availability_choices_order". All fields are combined with a logical 'AND'. */
export type Candidate_Availability_Choices_Order_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Availability_Choices_Order_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Availability_Choices_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Availability_Choices_Order_Bool_Exp>>;
  order?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "candidate_availability_choices". */
export type Candidate_Availability_Choices_Order_By = {
  candidate_availability_choices_order?: InputMaybe<Candidate_Availability_Choices_Order_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "candidate_availability_choices_order" */
export enum Candidate_Availability_Choices_Order_Constraint {
  /** unique or primary key constraint on columns "order" */
  CandidateAvailabilityChoicesOrderOrderKey = 'candidate_availability_choices_order_order_key',
  /** unique or primary key constraint on columns "value" */
  CandidateAvailabilityChoicesOrderPkey = 'candidate_availability_choices_order_pkey',
}

/** input type for incrementing numeric columns in table "candidate_availability_choices_order" */
export type Candidate_Availability_Choices_Order_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_availability_choices_order" */
export type Candidate_Availability_Choices_Order_Insert_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Availability_Choices_Order_Max_Fields = {
  __typename?: 'candidate_availability_choices_order_max_fields';
  order: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Availability_Choices_Order_Min_Fields = {
  __typename?: 'candidate_availability_choices_order_min_fields';
  order: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_availability_choices_order" */
export type Candidate_Availability_Choices_Order_Mutation_Response = {
  __typename?: 'candidate_availability_choices_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Availability_Choices_Order>;
};

/** input type for inserting object relation for remote table "candidate_availability_choices_order" */
export type Candidate_Availability_Choices_Order_Obj_Rel_Insert_Input = {
  data: Candidate_Availability_Choices_Order_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Availability_Choices_Order_On_Conflict>;
};

/** on_conflict condition type for table "candidate_availability_choices_order" */
export type Candidate_Availability_Choices_Order_On_Conflict = {
  constraint: Candidate_Availability_Choices_Order_Constraint;
  update_columns?: Array<Candidate_Availability_Choices_Order_Update_Column>;
  where?: InputMaybe<Candidate_Availability_Choices_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_availability_choices_order". */
export type Candidate_Availability_Choices_Order_Order_By = {
  order?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_availability_choices_order */
export type Candidate_Availability_Choices_Order_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_availability_choices_order" */
export enum Candidate_Availability_Choices_Order_Select_Column {
  /** column name */
  Order = 'order',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_availability_choices_order" */
export type Candidate_Availability_Choices_Order_Set_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Candidate_Availability_Choices_Order_Stddev_Fields = {
  __typename?: 'candidate_availability_choices_order_stddev_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Availability_Choices_Order_Stddev_Pop_Fields = {
  __typename?: 'candidate_availability_choices_order_stddev_pop_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Availability_Choices_Order_Stddev_Samp_Fields = {
  __typename?: 'candidate_availability_choices_order_stddev_samp_fields';
  order: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_availability_choices_order" */
export type Candidate_Availability_Choices_Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Availability_Choices_Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Availability_Choices_Order_Stream_Cursor_Value_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Candidate_Availability_Choices_Order_Sum_Fields = {
  __typename?: 'candidate_availability_choices_order_sum_fields';
  order: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_availability_choices_order" */
export enum Candidate_Availability_Choices_Order_Update_Column {
  /** column name */
  Order = 'order',
  /** column name */
  Value = 'value',
}

export type Candidate_Availability_Choices_Order_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Availability_Choices_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Availability_Choices_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Availability_Choices_Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Availability_Choices_Order_Var_Pop_Fields = {
  __typename?: 'candidate_availability_choices_order_var_pop_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Availability_Choices_Order_Var_Samp_Fields = {
  __typename?: 'candidate_availability_choices_order_var_samp_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Availability_Choices_Order_Variance_Fields = {
  __typename?: 'candidate_availability_choices_order_variance_fields';
  order: Maybe<Scalars['Float']>;
};

/** primary key columns input for table: candidate_availability_choices */
export type Candidate_Availability_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_availability_choices" */
export enum Candidate_Availability_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_availability_choices" */
export type Candidate_Availability_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_availability_choices" */
export type Candidate_Availability_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Availability_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Availability_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_availability_choices" */
export enum Candidate_Availability_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Availability_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Availability_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Availability_Choices_Bool_Exp;
};

/** aggregate avg on columns */
export type Candidate_Avg_Fields = {
  __typename?: 'candidate_avg_fields';
  country_id: Maybe<Scalars['Float']>;
  furthest_candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  owner: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate" */
export type Candidate_Avg_Order_By = {
  country_id?: InputMaybe<Order_By>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "candidate_badge" */
export type Candidate_Badge = {
  __typename?: 'candidate_badge';
  /** An object relationship */
  built_new_candidate_work_experience: Maybe<Candidate_Work_Experience>;
  built_new_candidate_work_experience_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  created_at: Maybe<Scalars['timestamptz']>;
  cs_degree: Maybe<Scalars['Boolean']>;
  in_demand: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  industry_1: Maybe<Industry>;
  /** An object relationship */
  industry_2: Maybe<Industry>;
  industry_id_1: Maybe<Scalars['Int']>;
  industry_id_2: Maybe<Scalars['Int']>;
  multiple_languages: Scalars['Boolean'];
  referred: Scalars['Boolean'];
  rising_star: Scalars['Boolean'];
  second_top_industry: Maybe<Scalars['String']>;
  top_company_exp: Maybe<Scalars['Boolean']>;
  top_industry: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  years_people_lead_exp: Maybe<Scalars['smallint']>;
  years_tech_lead_exp: Maybe<Scalars['smallint']>;
};

/** aggregated selection of "candidate_badge" */
export type Candidate_Badge_Aggregate = {
  __typename?: 'candidate_badge_aggregate';
  aggregate: Maybe<Candidate_Badge_Aggregate_Fields>;
  nodes: Array<Candidate_Badge>;
};

/** aggregate fields of "candidate_badge" */
export type Candidate_Badge_Aggregate_Fields = {
  __typename?: 'candidate_badge_aggregate_fields';
  avg: Maybe<Candidate_Badge_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Badge_Max_Fields>;
  min: Maybe<Candidate_Badge_Min_Fields>;
  stddev: Maybe<Candidate_Badge_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Badge_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Badge_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Badge_Sum_Fields>;
  var_pop: Maybe<Candidate_Badge_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Badge_Var_Samp_Fields>;
  variance: Maybe<Candidate_Badge_Variance_Fields>;
};

/** aggregate fields of "candidate_badge" */
export type Candidate_Badge_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Badge_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Badge_Avg_Fields = {
  __typename?: 'candidate_badge_avg_fields';
  built_new_candidate_work_experience_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  industry_id_1: Maybe<Scalars['Float']>;
  industry_id_2: Maybe<Scalars['Float']>;
  years_people_lead_exp: Maybe<Scalars['Float']>;
  years_tech_lead_exp: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_badge". All fields are combined with a logical 'AND'. */
export type Candidate_Badge_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Badge_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Badge_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Badge_Bool_Exp>>;
  built_new_candidate_work_experience?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
  built_new_candidate_work_experience_id?: InputMaybe<Int_Comparison_Exp>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  cs_degree?: InputMaybe<Boolean_Comparison_Exp>;
  in_demand?: InputMaybe<Boolean_Comparison_Exp>;
  industry_1?: InputMaybe<Industry_Bool_Exp>;
  industry_2?: InputMaybe<Industry_Bool_Exp>;
  industry_id_1?: InputMaybe<Int_Comparison_Exp>;
  industry_id_2?: InputMaybe<Int_Comparison_Exp>;
  multiple_languages?: InputMaybe<Boolean_Comparison_Exp>;
  referred?: InputMaybe<Boolean_Comparison_Exp>;
  rising_star?: InputMaybe<Boolean_Comparison_Exp>;
  second_top_industry?: InputMaybe<String_Comparison_Exp>;
  top_company_exp?: InputMaybe<Boolean_Comparison_Exp>;
  top_industry?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  years_people_lead_exp?: InputMaybe<Smallint_Comparison_Exp>;
  years_tech_lead_exp?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_badge" */
export enum Candidate_Badge_Constraint {
  /** unique or primary key constraint on columns "candidate_id" */
  CandidateBadgePkey = 'candidate_badge_pkey',
}

/** input type for incrementing numeric columns in table "candidate_badge" */
export type Candidate_Badge_Inc_Input = {
  built_new_candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  industry_id_1?: InputMaybe<Scalars['Int']>;
  industry_id_2?: InputMaybe<Scalars['Int']>;
  years_people_lead_exp?: InputMaybe<Scalars['smallint']>;
  years_tech_lead_exp?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "candidate_badge" */
export type Candidate_Badge_Insert_Input = {
  built_new_candidate_work_experience?: InputMaybe<Candidate_Work_Experience_Obj_Rel_Insert_Input>;
  built_new_candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cs_degree?: InputMaybe<Scalars['Boolean']>;
  in_demand?: InputMaybe<Scalars['Boolean']>;
  industry_1?: InputMaybe<Industry_Obj_Rel_Insert_Input>;
  industry_2?: InputMaybe<Industry_Obj_Rel_Insert_Input>;
  industry_id_1?: InputMaybe<Scalars['Int']>;
  industry_id_2?: InputMaybe<Scalars['Int']>;
  multiple_languages?: InputMaybe<Scalars['Boolean']>;
  referred?: InputMaybe<Scalars['Boolean']>;
  rising_star?: InputMaybe<Scalars['Boolean']>;
  second_top_industry?: InputMaybe<Scalars['String']>;
  top_company_exp?: InputMaybe<Scalars['Boolean']>;
  top_industry?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  years_people_lead_exp?: InputMaybe<Scalars['smallint']>;
  years_tech_lead_exp?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Candidate_Badge_Max_Fields = {
  __typename?: 'candidate_badge_max_fields';
  built_new_candidate_work_experience_id: Maybe<Scalars['Int']>;
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  industry_id_1: Maybe<Scalars['Int']>;
  industry_id_2: Maybe<Scalars['Int']>;
  second_top_industry: Maybe<Scalars['String']>;
  top_industry: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  years_people_lead_exp: Maybe<Scalars['smallint']>;
  years_tech_lead_exp: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type Candidate_Badge_Min_Fields = {
  __typename?: 'candidate_badge_min_fields';
  built_new_candidate_work_experience_id: Maybe<Scalars['Int']>;
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  industry_id_1: Maybe<Scalars['Int']>;
  industry_id_2: Maybe<Scalars['Int']>;
  second_top_industry: Maybe<Scalars['String']>;
  top_industry: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  years_people_lead_exp: Maybe<Scalars['smallint']>;
  years_tech_lead_exp: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "candidate_badge" */
export type Candidate_Badge_Mutation_Response = {
  __typename?: 'candidate_badge_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Badge>;
};

/** input type for inserting object relation for remote table "candidate_badge" */
export type Candidate_Badge_Obj_Rel_Insert_Input = {
  data: Candidate_Badge_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Badge_On_Conflict>;
};

/** on_conflict condition type for table "candidate_badge" */
export type Candidate_Badge_On_Conflict = {
  constraint: Candidate_Badge_Constraint;
  update_columns?: Array<Candidate_Badge_Update_Column>;
  where?: InputMaybe<Candidate_Badge_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_badge". */
export type Candidate_Badge_Order_By = {
  built_new_candidate_work_experience?: InputMaybe<Candidate_Work_Experience_Order_By>;
  built_new_candidate_work_experience_id?: InputMaybe<Order_By>;
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cs_degree?: InputMaybe<Order_By>;
  in_demand?: InputMaybe<Order_By>;
  industry_1?: InputMaybe<Industry_Order_By>;
  industry_2?: InputMaybe<Industry_Order_By>;
  industry_id_1?: InputMaybe<Order_By>;
  industry_id_2?: InputMaybe<Order_By>;
  multiple_languages?: InputMaybe<Order_By>;
  referred?: InputMaybe<Order_By>;
  rising_star?: InputMaybe<Order_By>;
  second_top_industry?: InputMaybe<Order_By>;
  top_company_exp?: InputMaybe<Order_By>;
  top_industry?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  years_people_lead_exp?: InputMaybe<Order_By>;
  years_tech_lead_exp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_badge */
export type Candidate_Badge_Pk_Columns_Input = {
  candidate_id: Scalars['Int'];
};

/** select columns of table "candidate_badge" */
export enum Candidate_Badge_Select_Column {
  /** column name */
  BuiltNewCandidateWorkExperienceId = 'built_new_candidate_work_experience_id',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CsDegree = 'cs_degree',
  /** column name */
  InDemand = 'in_demand',
  /** column name */
  IndustryId_1 = 'industry_id_1',
  /** column name */
  IndustryId_2 = 'industry_id_2',
  /** column name */
  MultipleLanguages = 'multiple_languages',
  /** column name */
  Referred = 'referred',
  /** column name */
  RisingStar = 'rising_star',
  /** column name */
  SecondTopIndustry = 'second_top_industry',
  /** column name */
  TopCompanyExp = 'top_company_exp',
  /** column name */
  TopIndustry = 'top_industry',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YearsPeopleLeadExp = 'years_people_lead_exp',
  /** column name */
  YearsTechLeadExp = 'years_tech_lead_exp',
}

/** input type for updating data in table "candidate_badge" */
export type Candidate_Badge_Set_Input = {
  built_new_candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cs_degree?: InputMaybe<Scalars['Boolean']>;
  in_demand?: InputMaybe<Scalars['Boolean']>;
  industry_id_1?: InputMaybe<Scalars['Int']>;
  industry_id_2?: InputMaybe<Scalars['Int']>;
  multiple_languages?: InputMaybe<Scalars['Boolean']>;
  referred?: InputMaybe<Scalars['Boolean']>;
  rising_star?: InputMaybe<Scalars['Boolean']>;
  second_top_industry?: InputMaybe<Scalars['String']>;
  top_company_exp?: InputMaybe<Scalars['Boolean']>;
  top_industry?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  years_people_lead_exp?: InputMaybe<Scalars['smallint']>;
  years_tech_lead_exp?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Candidate_Badge_Stddev_Fields = {
  __typename?: 'candidate_badge_stddev_fields';
  built_new_candidate_work_experience_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  industry_id_1: Maybe<Scalars['Float']>;
  industry_id_2: Maybe<Scalars['Float']>;
  years_people_lead_exp: Maybe<Scalars['Float']>;
  years_tech_lead_exp: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Badge_Stddev_Pop_Fields = {
  __typename?: 'candidate_badge_stddev_pop_fields';
  built_new_candidate_work_experience_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  industry_id_1: Maybe<Scalars['Float']>;
  industry_id_2: Maybe<Scalars['Float']>;
  years_people_lead_exp: Maybe<Scalars['Float']>;
  years_tech_lead_exp: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Badge_Stddev_Samp_Fields = {
  __typename?: 'candidate_badge_stddev_samp_fields';
  built_new_candidate_work_experience_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  industry_id_1: Maybe<Scalars['Float']>;
  industry_id_2: Maybe<Scalars['Float']>;
  years_people_lead_exp: Maybe<Scalars['Float']>;
  years_tech_lead_exp: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_badge" */
export type Candidate_Badge_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Badge_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Badge_Stream_Cursor_Value_Input = {
  built_new_candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cs_degree?: InputMaybe<Scalars['Boolean']>;
  in_demand?: InputMaybe<Scalars['Boolean']>;
  industry_id_1?: InputMaybe<Scalars['Int']>;
  industry_id_2?: InputMaybe<Scalars['Int']>;
  multiple_languages?: InputMaybe<Scalars['Boolean']>;
  referred?: InputMaybe<Scalars['Boolean']>;
  rising_star?: InputMaybe<Scalars['Boolean']>;
  second_top_industry?: InputMaybe<Scalars['String']>;
  top_company_exp?: InputMaybe<Scalars['Boolean']>;
  top_industry?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  years_people_lead_exp?: InputMaybe<Scalars['smallint']>;
  years_tech_lead_exp?: InputMaybe<Scalars['smallint']>;
};

/** aggregate sum on columns */
export type Candidate_Badge_Sum_Fields = {
  __typename?: 'candidate_badge_sum_fields';
  built_new_candidate_work_experience_id: Maybe<Scalars['Int']>;
  candidate_id: Maybe<Scalars['Int']>;
  industry_id_1: Maybe<Scalars['Int']>;
  industry_id_2: Maybe<Scalars['Int']>;
  years_people_lead_exp: Maybe<Scalars['smallint']>;
  years_tech_lead_exp: Maybe<Scalars['smallint']>;
};

/** update columns of table "candidate_badge" */
export enum Candidate_Badge_Update_Column {
  /** column name */
  BuiltNewCandidateWorkExperienceId = 'built_new_candidate_work_experience_id',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CsDegree = 'cs_degree',
  /** column name */
  InDemand = 'in_demand',
  /** column name */
  IndustryId_1 = 'industry_id_1',
  /** column name */
  IndustryId_2 = 'industry_id_2',
  /** column name */
  MultipleLanguages = 'multiple_languages',
  /** column name */
  Referred = 'referred',
  /** column name */
  RisingStar = 'rising_star',
  /** column name */
  SecondTopIndustry = 'second_top_industry',
  /** column name */
  TopCompanyExp = 'top_company_exp',
  /** column name */
  TopIndustry = 'top_industry',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YearsPeopleLeadExp = 'years_people_lead_exp',
  /** column name */
  YearsTechLeadExp = 'years_tech_lead_exp',
}

export type Candidate_Badge_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Badge_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Badge_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Badge_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Badge_Var_Pop_Fields = {
  __typename?: 'candidate_badge_var_pop_fields';
  built_new_candidate_work_experience_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  industry_id_1: Maybe<Scalars['Float']>;
  industry_id_2: Maybe<Scalars['Float']>;
  years_people_lead_exp: Maybe<Scalars['Float']>;
  years_tech_lead_exp: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Badge_Var_Samp_Fields = {
  __typename?: 'candidate_badge_var_samp_fields';
  built_new_candidate_work_experience_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  industry_id_1: Maybe<Scalars['Float']>;
  industry_id_2: Maybe<Scalars['Float']>;
  years_people_lead_exp: Maybe<Scalars['Float']>;
  years_tech_lead_exp: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Badge_Variance_Fields = {
  __typename?: 'candidate_badge_variance_fields';
  built_new_candidate_work_experience_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  industry_id_1: Maybe<Scalars['Float']>;
  industry_id_2: Maybe<Scalars['Float']>;
  years_people_lead_exp: Maybe<Scalars['Float']>;
  years_tech_lead_exp: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate". All fields are combined with a logical 'AND'. */
export type Candidate_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Bool_Exp>>;
  applicant_consents?: InputMaybe<Applicant_Consent_Bool_Exp>;
  applicant_consents_aggregate?: InputMaybe<Applicant_Consent_Aggregate_Bool_Exp>;
  applicant_jobs?: InputMaybe<Applicant_Job_Bool_Exp>;
  applicant_jobs_aggregate?: InputMaybe<Applicant_Job_Aggregate_Bool_Exp>;
  availability?: InputMaybe<Candidate_Availability_Choices_Enum_Comparison_Exp>;
  candidate_ai_profile_summaries?: InputMaybe<Candidate_Ai_Profile_Summary_Bool_Exp>;
  candidate_ai_profile_summaries_aggregate?: InputMaybe<Candidate_Ai_Profile_Summary_Aggregate_Bool_Exp>;
  candidate_availability_choice?: InputMaybe<Candidate_Availability_Choices_Bool_Exp>;
  candidate_badge?: InputMaybe<Candidate_Badge_Bool_Exp>;
  candidate_curation_ai_summaries?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
  candidate_curation_ai_summaries_aggregate?: InputMaybe<Candidate_Curation_Ai_Summary_Aggregate_Bool_Exp>;
  candidate_curation_detail?: InputMaybe<Candidate_Curation_Detail_Bool_Exp>;
  candidate_curation_workflows?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
  candidate_curation_workflows_aggregate?: InputMaybe<Candidate_Curation_Workflow_Aggregate_Bool_Exp>;
  candidate_educations?: InputMaybe<Candidate_Education_Bool_Exp>;
  candidate_educations_aggregate?: InputMaybe<Candidate_Education_Aggregate_Bool_Exp>;
  candidate_emails?: InputMaybe<Candidate_Email_Bool_Exp>;
  candidate_emails_aggregate?: InputMaybe<Candidate_Email_Aggregate_Bool_Exp>;
  candidate_hireflix_transcripts?: InputMaybe<Hireflix_Transcript_Bool_Exp>;
  candidate_hireflix_transcripts_aggregate?: InputMaybe<Hireflix_Transcript_Aggregate_Bool_Exp>;
  candidate_hub_locations?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
  candidate_hub_locations_aggregate?: InputMaybe<Candidate_Hub_Location_Aggregate_Bool_Exp>;
  candidate_job_workflows?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
  candidate_job_workflows_aggregate?: InputMaybe<Candidate_Job_Workflow_Aggregate_Bool_Exp>;
  candidate_jobs?: InputMaybe<Candidate_Job_Bool_Exp>;
  candidate_jobs_aggregate?: InputMaybe<Candidate_Job_Aggregate_Bool_Exp>;
  candidate_pdl?: InputMaybe<Candidate_Pdl_Bool_Exp>;
  candidate_pillar_transcripts?: InputMaybe<Pillar_Transcript_Bool_Exp>;
  candidate_pillar_transcripts_aggregate?: InputMaybe<Pillar_Transcript_Aggregate_Bool_Exp>;
  candidate_roles?: InputMaybe<Candidate_Role_Bool_Exp>;
  candidate_roles_aggregate?: InputMaybe<Candidate_Role_Aggregate_Bool_Exp>;
  candidate_skills?: InputMaybe<Candidate_Skill_Bool_Exp>;
  candidate_skills_aggregate?: InputMaybe<Candidate_Skill_Aggregate_Bool_Exp>;
  candidate_stage_logs?: InputMaybe<Candidate_Stage_Log_Bool_Exp>;
  candidate_stage_logs_aggregate?: InputMaybe<Candidate_Stage_Log_Aggregate_Bool_Exp>;
  candidate_work_experiences?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
  candidate_work_experiences_aggregate?: InputMaybe<Candidate_Work_Experience_Aggregate_Bool_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  country_choice?: InputMaybe<Country_Choices_Bool_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  curation_countries?: InputMaybe<Candidate_Approved_Country_Bool_Exp>;
  curation_countries_aggregate?: InputMaybe<Candidate_Approved_Country_Aggregate_Bool_Exp>;
  curation_notes?: InputMaybe<Candidate_Curation_Note_Bool_Exp>;
  curation_notes_aggregate?: InputMaybe<Candidate_Curation_Note_Aggregate_Bool_Exp>;
  dayforce_employee?: InputMaybe<Dayforce_Employee_Bool_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  furthest_candidate_curation_workflow?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Int_Comparison_Exp>;
  furthest_stage?: InputMaybe<Candidate_Stage_Choices_Enum_Comparison_Exp>;
  gem_id?: InputMaybe<String_Comparison_Exp>;
  github_url?: InputMaybe<String_Comparison_Exp>;
  hr_test_results?: InputMaybe<Hr_Test_Result_Bool_Exp>;
  hr_test_results_aggregate?: InputMaybe<Hr_Test_Result_Aggregate_Bool_Exp>;
  icims_person_profile_id?: InputMaybe<Int_Comparison_Exp>;
  icims_profile_person?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interview_requests?: InputMaybe<Interview_Request_Bool_Exp>;
  interview_requests_aggregate?: InputMaybe<Interview_Request_Aggregate_Bool_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_previous_hire?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  last_name_initial?: InputMaybe<String_Comparison_Exp>;
  latitude?: InputMaybe<Numeric_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  longitude?: InputMaybe<Numeric_Comparison_Exp>;
  merge_candidate_id?: InputMaybe<String_Comparison_Exp>;
  open_to_all_companies?: InputMaybe<Boolean_Comparison_Exp>;
  other_url?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<Int_Comparison_Exp>;
  owner_person?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
  parsed_resume?: InputMaybe<Candidate_Parsed_Resume_Bool_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  profile_complete_override?: InputMaybe<Boolean_Comparison_Exp>;
  profile_flow_completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  profile_flow_furthest_step?: InputMaybe<String_Comparison_Exp>;
  profile_flow_started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  profile_flow_variations?: InputMaybe<Jsonb_Comparison_Exp>;
  public_id?: InputMaybe<Uuid_Comparison_Exp>;
  referred_by?: InputMaybe<Candidate_Referrals_Bool_Exp>;
  referred_by_aggregate?: InputMaybe<Candidate_Referrals_Aggregate_Bool_Exp>;
  resume_filename?: InputMaybe<String_Comparison_Exp>;
  saved_candidates?: InputMaybe<Saved_Candidate_Bool_Exp>;
  saved_candidates_aggregate?: InputMaybe<Saved_Candidate_Aggregate_Bool_Exp>;
  source?: InputMaybe<Candidate_Source_Choices_Enum_Comparison_Exp>;
  stage?: InputMaybe<Candidate_Stage_Choices_Enum_Comparison_Exp>;
  stage_source?: InputMaybe<Candidate_Stage_Source_Choices_Enum_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum_Comparison_Exp>;
  transcript_evaluations?: InputMaybe<Transcript_Evaluation_Bool_Exp>;
  transcript_evaluations_aggregate?: InputMaybe<Transcript_Evaluation_Aggregate_Bool_Exp>;
  transcript_skills?: InputMaybe<Transcript_Skill_Bool_Exp>;
  transcript_skills_aggregate?: InputMaybe<Transcript_Skill_Aggregate_Bool_Exp>;
  transcript_work_experiences?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
  transcript_work_experiences_aggregate?: InputMaybe<Transcript_Work_Experience_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
  user_info_id?: InputMaybe<Int_Comparison_Exp>;
};

export type Candidate_Browse_Args = {
  badges?: InputMaybe<Scalars['jsonb']>;
  candidate_role?: InputMaybe<Scalars['String']>;
  employment_type?: InputMaybe<Scalars['String']>;
  min_years_of_experience?: InputMaybe<Scalars['String']>;
  must_have_skill_ids?: InputMaybe<Scalars['jsonb']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  regions?: InputMaybe<Scalars['jsonb']>;
  should_have_skill_ids?: InputMaybe<Scalars['jsonb']>;
  sort_option?: InputMaybe<Scalars['String']>;
  top_candidates_criteria?: InputMaybe<Scalars['String']>;
};

/** This table is only used for its Hasura generated type, don't populate it. */
export type Candidate_Browse_Result = {
  __typename?: 'candidate_browse_result';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  candidate_profile_updated_at: Scalars['timestamptz'];
  has_in_demand: Scalars['Boolean'];
  has_mutual_interest: Scalars['Boolean'];
  has_top_company_exp: Scalars['Boolean'];
  has_years_tech_lead_exp: Scalars['Boolean'];
  score: Scalars['float8'];
  total_count: Scalars['Int'];
};

export type Candidate_Browse_Result_Aggregate = {
  __typename?: 'candidate_browse_result_aggregate';
  aggregate: Maybe<Candidate_Browse_Result_Aggregate_Fields>;
  nodes: Array<Candidate_Browse_Result>;
};

/** aggregate fields of "candidate_browse_result" */
export type Candidate_Browse_Result_Aggregate_Fields = {
  __typename?: 'candidate_browse_result_aggregate_fields';
  avg: Maybe<Candidate_Browse_Result_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Browse_Result_Max_Fields>;
  min: Maybe<Candidate_Browse_Result_Min_Fields>;
  stddev: Maybe<Candidate_Browse_Result_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Browse_Result_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Browse_Result_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Browse_Result_Sum_Fields>;
  var_pop: Maybe<Candidate_Browse_Result_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Browse_Result_Var_Samp_Fields>;
  variance: Maybe<Candidate_Browse_Result_Variance_Fields>;
};

/** aggregate fields of "candidate_browse_result" */
export type Candidate_Browse_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Browse_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Browse_Result_Avg_Fields = {
  __typename?: 'candidate_browse_result_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  total_count: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_browse_result". All fields are combined with a logical 'AND'. */
export type Candidate_Browse_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Browse_Result_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Browse_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Browse_Result_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  candidate_profile_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  has_in_demand?: InputMaybe<Boolean_Comparison_Exp>;
  has_mutual_interest?: InputMaybe<Boolean_Comparison_Exp>;
  has_top_company_exp?: InputMaybe<Boolean_Comparison_Exp>;
  has_years_tech_lead_exp?: InputMaybe<Boolean_Comparison_Exp>;
  score?: InputMaybe<Float8_Comparison_Exp>;
  total_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_browse_result" */
export enum Candidate_Browse_Result_Constraint {
  /** unique or primary key constraint on columns "candidate_id" */
  CandidateBrowseResultPkey = 'candidate_browse_result_pkey',
}

/** input type for incrementing numeric columns in table "candidate_browse_result" */
export type Candidate_Browse_Result_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['float8']>;
  total_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_browse_result" */
export type Candidate_Browse_Result_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_profile_updated_at?: InputMaybe<Scalars['timestamptz']>;
  has_in_demand?: InputMaybe<Scalars['Boolean']>;
  has_mutual_interest?: InputMaybe<Scalars['Boolean']>;
  has_top_company_exp?: InputMaybe<Scalars['Boolean']>;
  has_years_tech_lead_exp?: InputMaybe<Scalars['Boolean']>;
  score?: InputMaybe<Scalars['float8']>;
  total_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Candidate_Browse_Result_Max_Fields = {
  __typename?: 'candidate_browse_result_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  candidate_profile_updated_at: Maybe<Scalars['timestamptz']>;
  score: Maybe<Scalars['float8']>;
  total_count: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Candidate_Browse_Result_Min_Fields = {
  __typename?: 'candidate_browse_result_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  candidate_profile_updated_at: Maybe<Scalars['timestamptz']>;
  score: Maybe<Scalars['float8']>;
  total_count: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "candidate_browse_result" */
export type Candidate_Browse_Result_Mutation_Response = {
  __typename?: 'candidate_browse_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Browse_Result>;
};

/** on_conflict condition type for table "candidate_browse_result" */
export type Candidate_Browse_Result_On_Conflict = {
  constraint: Candidate_Browse_Result_Constraint;
  update_columns?: Array<Candidate_Browse_Result_Update_Column>;
  where?: InputMaybe<Candidate_Browse_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_browse_result". */
export type Candidate_Browse_Result_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_profile_updated_at?: InputMaybe<Order_By>;
  has_in_demand?: InputMaybe<Order_By>;
  has_mutual_interest?: InputMaybe<Order_By>;
  has_top_company_exp?: InputMaybe<Order_By>;
  has_years_tech_lead_exp?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_browse_result */
export type Candidate_Browse_Result_Pk_Columns_Input = {
  candidate_id: Scalars['Int'];
};

/** select columns of table "candidate_browse_result" */
export enum Candidate_Browse_Result_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CandidateProfileUpdatedAt = 'candidate_profile_updated_at',
  /** column name */
  HasInDemand = 'has_in_demand',
  /** column name */
  HasMutualInterest = 'has_mutual_interest',
  /** column name */
  HasTopCompanyExp = 'has_top_company_exp',
  /** column name */
  HasYearsTechLeadExp = 'has_years_tech_lead_exp',
  /** column name */
  Score = 'score',
  /** column name */
  TotalCount = 'total_count',
}

/** input type for updating data in table "candidate_browse_result" */
export type Candidate_Browse_Result_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_profile_updated_at?: InputMaybe<Scalars['timestamptz']>;
  has_in_demand?: InputMaybe<Scalars['Boolean']>;
  has_mutual_interest?: InputMaybe<Scalars['Boolean']>;
  has_top_company_exp?: InputMaybe<Scalars['Boolean']>;
  has_years_tech_lead_exp?: InputMaybe<Scalars['Boolean']>;
  score?: InputMaybe<Scalars['float8']>;
  total_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Candidate_Browse_Result_Stddev_Fields = {
  __typename?: 'candidate_browse_result_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  total_count: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Browse_Result_Stddev_Pop_Fields = {
  __typename?: 'candidate_browse_result_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  total_count: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Browse_Result_Stddev_Samp_Fields = {
  __typename?: 'candidate_browse_result_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  total_count: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_browse_result" */
export type Candidate_Browse_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Browse_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Browse_Result_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_profile_updated_at?: InputMaybe<Scalars['timestamptz']>;
  has_in_demand?: InputMaybe<Scalars['Boolean']>;
  has_mutual_interest?: InputMaybe<Scalars['Boolean']>;
  has_top_company_exp?: InputMaybe<Scalars['Boolean']>;
  has_years_tech_lead_exp?: InputMaybe<Scalars['Boolean']>;
  score?: InputMaybe<Scalars['float8']>;
  total_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Browse_Result_Sum_Fields = {
  __typename?: 'candidate_browse_result_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  score: Maybe<Scalars['float8']>;
  total_count: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_browse_result" */
export enum Candidate_Browse_Result_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CandidateProfileUpdatedAt = 'candidate_profile_updated_at',
  /** column name */
  HasInDemand = 'has_in_demand',
  /** column name */
  HasMutualInterest = 'has_mutual_interest',
  /** column name */
  HasTopCompanyExp = 'has_top_company_exp',
  /** column name */
  HasYearsTechLeadExp = 'has_years_tech_lead_exp',
  /** column name */
  Score = 'score',
  /** column name */
  TotalCount = 'total_count',
}

export type Candidate_Browse_Result_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Browse_Result_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Browse_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Browse_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Browse_Result_Var_Pop_Fields = {
  __typename?: 'candidate_browse_result_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  total_count: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Browse_Result_Var_Samp_Fields = {
  __typename?: 'candidate_browse_result_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  total_count: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Browse_Result_Variance_Fields = {
  __typename?: 'candidate_browse_result_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  total_count: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "candidate" */
export enum Candidate_Constraint {
  /** unique or primary key constraint on columns "email" */
  CandidateEmailKey = 'candidate_email_key',
  /** unique or primary key constraint on columns "id" */
  CandidatePkey = 'candidate_pkey',
  /** unique or primary key constraint on columns "public_id" */
  CandidateResumeStorageIdKey = 'candidate_resume_storage_id_key',
  /** unique or primary key constraint on columns "user_info_id" */
  CandidateUserInfoIdKey = 'candidate_user_info_id_key',
}

/** Table to store summaries generated by the AI over time */
export type Candidate_Curation_Ai_Summary = {
  __typename?: 'candidate_curation_ai_summary';
  ai_summary: Scalars['jsonb'];
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  created_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  score: Scalars['numeric'];
  updated_at: Maybe<Scalars['timestamptz']>;
  version: Scalars['String'];
};

/** Table to store summaries generated by the AI over time */
export type Candidate_Curation_Ai_SummaryAi_SummaryArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Aggregate = {
  __typename?: 'candidate_curation_ai_summary_aggregate';
  aggregate: Maybe<Candidate_Curation_Ai_Summary_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Ai_Summary>;
};

export type Candidate_Curation_Ai_Summary_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Curation_Ai_Summary_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Curation_Ai_Summary_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Aggregate_Fields = {
  __typename?: 'candidate_curation_ai_summary_aggregate_fields';
  avg: Maybe<Candidate_Curation_Ai_Summary_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Ai_Summary_Max_Fields>;
  min: Maybe<Candidate_Curation_Ai_Summary_Min_Fields>;
  stddev: Maybe<Candidate_Curation_Ai_Summary_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Curation_Ai_Summary_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Curation_Ai_Summary_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Curation_Ai_Summary_Sum_Fields>;
  var_pop: Maybe<Candidate_Curation_Ai_Summary_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Curation_Ai_Summary_Var_Samp_Fields>;
  variance: Maybe<Candidate_Curation_Ai_Summary_Variance_Fields>;
};

/** aggregate fields of "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Curation_Ai_Summary_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Curation_Ai_Summary_Max_Order_By>;
  min?: InputMaybe<Candidate_Curation_Ai_Summary_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Curation_Ai_Summary_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Curation_Ai_Summary_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Curation_Ai_Summary_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Curation_Ai_Summary_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Curation_Ai_Summary_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Curation_Ai_Summary_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Curation_Ai_Summary_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Curation_Ai_Summary_Append_Input = {
  ai_summary?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Curation_Ai_Summary_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Ai_Summary_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Curation_Ai_Summary_Avg_Fields = {
  __typename?: 'candidate_curation_ai_summary_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_curation_ai_summary". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Ai_Summary_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Bool_Exp>>;
  ai_summary?: InputMaybe<Jsonb_Comparison_Exp>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  score?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_ai_summary" */
export enum Candidate_Curation_Ai_Summary_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateCurationAiSummaryPkey = 'candidate_curation_ai_summary_pkey',
  /** unique or primary key constraint on columns "candidate_id", "version" */
  CandidateCurationAiSummaryVersionCandidateIdKey = 'candidate_curation_ai_summary_version_candidate_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Candidate_Curation_Ai_Summary_Delete_At_Path_Input = {
  ai_summary?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Candidate_Curation_Ai_Summary_Delete_Elem_Input = {
  ai_summary?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Candidate_Curation_Ai_Summary_Delete_Key_Input = {
  ai_summary?: InputMaybe<Scalars['String']>;
};

/** Feedback from curators on the quality of the AI summary. */
export type Candidate_Curation_Ai_Summary_Feedback = {
  __typename?: 'candidate_curation_ai_summary_feedback';
  /** An object relationship */
  candidate_curation_ai_summary_feedback_candidate_curation_ai_summary: Candidate_Curation_Ai_Summary;
  /** An object relationship */
  candidate_curation_ai_summary_feedback_user_info: Maybe<User_Info>;
  candidate_curation_ai_summary_id: Scalars['Int'];
  comment: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_good: Scalars['Boolean'];
  reviewer_user_info_id: Maybe<Scalars['Int']>;
};

/** aggregated selection of "candidate_curation_ai_summary_feedback" */
export type Candidate_Curation_Ai_Summary_Feedback_Aggregate = {
  __typename?: 'candidate_curation_ai_summary_feedback_aggregate';
  aggregate: Maybe<Candidate_Curation_Ai_Summary_Feedback_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Ai_Summary_Feedback>;
};

/** aggregate fields of "candidate_curation_ai_summary_feedback" */
export type Candidate_Curation_Ai_Summary_Feedback_Aggregate_Fields = {
  __typename?: 'candidate_curation_ai_summary_feedback_aggregate_fields';
  avg: Maybe<Candidate_Curation_Ai_Summary_Feedback_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Ai_Summary_Feedback_Max_Fields>;
  min: Maybe<Candidate_Curation_Ai_Summary_Feedback_Min_Fields>;
  stddev: Maybe<Candidate_Curation_Ai_Summary_Feedback_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Curation_Ai_Summary_Feedback_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Curation_Ai_Summary_Feedback_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Curation_Ai_Summary_Feedback_Sum_Fields>;
  var_pop: Maybe<Candidate_Curation_Ai_Summary_Feedback_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Curation_Ai_Summary_Feedback_Var_Samp_Fields>;
  variance: Maybe<Candidate_Curation_Ai_Summary_Feedback_Variance_Fields>;
};

/** aggregate fields of "candidate_curation_ai_summary_feedback" */
export type Candidate_Curation_Ai_Summary_Feedback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Curation_Ai_Summary_Feedback_Avg_Fields = {
  __typename?: 'candidate_curation_ai_summary_feedback_avg_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  reviewer_user_info_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_ai_summary_feedback". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Ai_Summary_Feedback_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Feedback_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Feedback_Bool_Exp>>;
  candidate_curation_ai_summary_feedback_candidate_curation_ai_summary?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
  candidate_curation_ai_summary_feedback_user_info?: InputMaybe<User_Info_Bool_Exp>;
  candidate_curation_ai_summary_id?: InputMaybe<Int_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_good?: InputMaybe<Boolean_Comparison_Exp>;
  reviewer_user_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_ai_summary_feedback" */
export enum Candidate_Curation_Ai_Summary_Feedback_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateCurationAiSummaryFeedbackPkey = 'candidate_curation_ai_summary_feedback_pkey',
}

/** input type for incrementing numeric columns in table "candidate_curation_ai_summary_feedback" */
export type Candidate_Curation_Ai_Summary_Feedback_Inc_Input = {
  candidate_curation_ai_summary_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  reviewer_user_info_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_curation_ai_summary_feedback" */
export type Candidate_Curation_Ai_Summary_Feedback_Insert_Input = {
  candidate_curation_ai_summary_feedback_candidate_curation_ai_summary?: InputMaybe<Candidate_Curation_Ai_Summary_Obj_Rel_Insert_Input>;
  candidate_curation_ai_summary_feedback_user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  candidate_curation_ai_summary_id?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_good?: InputMaybe<Scalars['Boolean']>;
  reviewer_user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Ai_Summary_Feedback_Max_Fields = {
  __typename?: 'candidate_curation_ai_summary_feedback_max_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Int']>;
  comment: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  reviewer_user_info_id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Ai_Summary_Feedback_Min_Fields = {
  __typename?: 'candidate_curation_ai_summary_feedback_min_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Int']>;
  comment: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  reviewer_user_info_id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "candidate_curation_ai_summary_feedback" */
export type Candidate_Curation_Ai_Summary_Feedback_Mutation_Response = {
  __typename?: 'candidate_curation_ai_summary_feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Ai_Summary_Feedback>;
};

/** on_conflict condition type for table "candidate_curation_ai_summary_feedback" */
export type Candidate_Curation_Ai_Summary_Feedback_On_Conflict = {
  constraint: Candidate_Curation_Ai_Summary_Feedback_Constraint;
  update_columns?: Array<Candidate_Curation_Ai_Summary_Feedback_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_ai_summary_feedback". */
export type Candidate_Curation_Ai_Summary_Feedback_Order_By = {
  candidate_curation_ai_summary_feedback_candidate_curation_ai_summary?: InputMaybe<Candidate_Curation_Ai_Summary_Order_By>;
  candidate_curation_ai_summary_feedback_user_info?: InputMaybe<User_Info_Order_By>;
  candidate_curation_ai_summary_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_good?: InputMaybe<Order_By>;
  reviewer_user_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_ai_summary_feedback */
export type Candidate_Curation_Ai_Summary_Feedback_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_curation_ai_summary_feedback" */
export enum Candidate_Curation_Ai_Summary_Feedback_Select_Column {
  /** column name */
  CandidateCurationAiSummaryId = 'candidate_curation_ai_summary_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsGood = 'is_good',
  /** column name */
  ReviewerUserInfoId = 'reviewer_user_info_id',
}

/** input type for updating data in table "candidate_curation_ai_summary_feedback" */
export type Candidate_Curation_Ai_Summary_Feedback_Set_Input = {
  candidate_curation_ai_summary_id?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_good?: InputMaybe<Scalars['Boolean']>;
  reviewer_user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Candidate_Curation_Ai_Summary_Feedback_Stddev_Fields = {
  __typename?: 'candidate_curation_ai_summary_feedback_stddev_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  reviewer_user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Curation_Ai_Summary_Feedback_Stddev_Pop_Fields = {
  __typename?: 'candidate_curation_ai_summary_feedback_stddev_pop_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  reviewer_user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Curation_Ai_Summary_Feedback_Stddev_Samp_Fields = {
  __typename?: 'candidate_curation_ai_summary_feedback_stddev_samp_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  reviewer_user_info_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_curation_ai_summary_feedback" */
export type Candidate_Curation_Ai_Summary_Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Ai_Summary_Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Ai_Summary_Feedback_Stream_Cursor_Value_Input = {
  candidate_curation_ai_summary_id?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_good?: InputMaybe<Scalars['Boolean']>;
  reviewer_user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Curation_Ai_Summary_Feedback_Sum_Fields = {
  __typename?: 'candidate_curation_ai_summary_feedback_sum_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  reviewer_user_info_id: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_curation_ai_summary_feedback" */
export enum Candidate_Curation_Ai_Summary_Feedback_Update_Column {
  /** column name */
  CandidateCurationAiSummaryId = 'candidate_curation_ai_summary_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsGood = 'is_good',
  /** column name */
  ReviewerUserInfoId = 'reviewer_user_info_id',
}

export type Candidate_Curation_Ai_Summary_Feedback_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Ai_Summary_Feedback_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Curation_Ai_Summary_Feedback_Var_Pop_Fields = {
  __typename?: 'candidate_curation_ai_summary_feedback_var_pop_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  reviewer_user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Curation_Ai_Summary_Feedback_Var_Samp_Fields = {
  __typename?: 'candidate_curation_ai_summary_feedback_var_samp_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  reviewer_user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Curation_Ai_Summary_Feedback_Variance_Fields = {
  __typename?: 'candidate_curation_ai_summary_feedback_variance_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  reviewer_user_info_id: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Insert_Input = {
  ai_summary?: InputMaybe<Scalars['jsonb']>;
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Ai_Summary_Max_Fields = {
  __typename?: 'candidate_curation_ai_summary_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  score: Maybe<Scalars['numeric']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  version: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Curation_Ai_Summary_Min_Fields = {
  __typename?: 'candidate_curation_ai_summary_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  score: Maybe<Scalars['numeric']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  version: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Mutation_Response = {
  __typename?: 'candidate_curation_ai_summary_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Ai_Summary>;
};

/** input type for inserting object relation for remote table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Obj_Rel_Insert_Input = {
  data: Candidate_Curation_Ai_Summary_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Ai_Summary_On_Conflict>;
};

/** on_conflict condition type for table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_On_Conflict = {
  constraint: Candidate_Curation_Ai_Summary_Constraint;
  update_columns?: Array<Candidate_Curation_Ai_Summary_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_ai_summary". */
export type Candidate_Curation_Ai_Summary_Order_By = {
  ai_summary?: InputMaybe<Order_By>;
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_ai_summary */
export type Candidate_Curation_Ai_Summary_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Curation_Ai_Summary_Prepend_Input = {
  ai_summary?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "candidate_curation_ai_summary" */
export enum Candidate_Curation_Ai_Summary_Select_Column {
  /** column name */
  AiSummary = 'ai_summary',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
}

/** input type for updating data in table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Set_Input = {
  ai_summary?: InputMaybe<Scalars['jsonb']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Candidate_Curation_Ai_Summary_Stddev_Fields = {
  __typename?: 'candidate_curation_ai_summary_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Curation_Ai_Summary_Stddev_Pop_Fields = {
  __typename?: 'candidate_curation_ai_summary_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Curation_Ai_Summary_Stddev_Samp_Fields = {
  __typename?: 'candidate_curation_ai_summary_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Ai_Summary_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Ai_Summary_Stream_Cursor_Value_Input = {
  ai_summary?: InputMaybe<Scalars['jsonb']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Candidate_Curation_Ai_Summary_Sum_Fields = {
  __typename?: 'candidate_curation_ai_summary_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  score: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_curation_ai_summary" */
export enum Candidate_Curation_Ai_Summary_Update_Column {
  /** column name */
  AiSummary = 'ai_summary',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
}

export type Candidate_Curation_Ai_Summary_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Candidate_Curation_Ai_Summary_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Candidate_Curation_Ai_Summary_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Candidate_Curation_Ai_Summary_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Candidate_Curation_Ai_Summary_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Curation_Ai_Summary_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Candidate_Curation_Ai_Summary_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Ai_Summary_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Ai_Summary_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Curation_Ai_Summary_Var_Pop_Fields = {
  __typename?: 'candidate_curation_ai_summary_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Curation_Ai_Summary_Var_Samp_Fields = {
  __typename?: 'candidate_curation_ai_summary_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Curation_Ai_Summary_Variance_Fields = {
  __typename?: 'candidate_curation_ai_summary_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_curation_ai_summary" */
export type Candidate_Curation_Ai_Summary_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** columns and relationships of "candidate_curation_country_choices" */
export type Candidate_Curation_Country_Choices = {
  __typename?: 'candidate_curation_country_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_country_choices" */
export type Candidate_Curation_Country_Choices_Aggregate = {
  __typename?: 'candidate_curation_country_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Country_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Country_Choices>;
};

/** aggregate fields of "candidate_curation_country_choices" */
export type Candidate_Curation_Country_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_country_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Country_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Country_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_country_choices" */
export type Candidate_Curation_Country_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Country_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_country_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Country_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Country_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Country_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Country_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_country_choices" */
export enum Candidate_Curation_Country_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationCountryChoicesPkey = 'candidate_curation_country_choices_pkey',
}

export enum Candidate_Curation_Country_Choices_Enum {
  Argentina = 'ARGENTINA',
  Canada = 'CANADA',
  Chile = 'CHILE',
  Colombia = 'COLOMBIA',
  CostaRica = 'COSTA_RICA',
  Hungary = 'HUNGARY',
  Mexico = 'MEXICO',
  Other = 'OTHER',
  Poland = 'POLAND',
  Romania = 'ROMANIA',
  Spain = 'SPAIN',
  Usa = 'USA',
}

/** Boolean expression to compare columns of type "candidate_curation_country_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Country_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Country_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Country_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Country_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Country_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_country_choices" */
export type Candidate_Curation_Country_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Country_Choices_Max_Fields = {
  __typename?: 'candidate_curation_country_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Country_Choices_Min_Fields = {
  __typename?: 'candidate_curation_country_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_country_choices" */
export type Candidate_Curation_Country_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_country_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Country_Choices>;
};

/** on_conflict condition type for table "candidate_curation_country_choices" */
export type Candidate_Curation_Country_Choices_On_Conflict = {
  constraint: Candidate_Curation_Country_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Country_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Country_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_country_choices". */
export type Candidate_Curation_Country_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_country_choices */
export type Candidate_Curation_Country_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_country_choices" */
export enum Candidate_Curation_Country_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_country_choices" */
export type Candidate_Curation_Country_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_country_choices" */
export type Candidate_Curation_Country_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Country_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Country_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_country_choices" */
export enum Candidate_Curation_Country_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Country_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Country_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Country_Choices_Bool_Exp;
};

/** columns and relationships of "candidate_curation_desired_salary_currency_choices" */
export type Candidate_Curation_Desired_Salary_Currency_Choices = {
  __typename?: 'candidate_curation_desired_salary_currency_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_desired_salary_currency_choices" */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Aggregate = {
  __typename?: 'candidate_curation_desired_salary_currency_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Desired_Salary_Currency_Choices>;
};

/** aggregate fields of "candidate_curation_desired_salary_currency_choices" */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_desired_salary_currency_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_desired_salary_currency_choices" */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_desired_salary_currency_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_desired_salary_currency_choices" */
export enum Candidate_Curation_Desired_Salary_Currency_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationDesiredSalaryCountryChoicesPkey = 'candidate_curation_desired_salary_country_choices_pkey',
}

export enum Candidate_Curation_Desired_Salary_Currency_Choices_Enum {
  Ars = 'ARS',
  Cad = 'CAD',
  Clp = 'CLP',
  Cop = 'COP',
  Crc = 'CRC',
  Eur = 'EUR',
  Gbp = 'GBP',
  Huf = 'HUF',
  Mxn = 'MXN',
  Pln = 'PLN',
  Ron = 'RON',
  Usd = 'USD',
}

/** Boolean expression to compare columns of type "candidate_curation_desired_salary_currency_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_desired_salary_currency_choices" */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Max_Fields = {
  __typename?: 'candidate_curation_desired_salary_currency_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Min_Fields = {
  __typename?: 'candidate_curation_desired_salary_currency_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_desired_salary_currency_choices" */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_desired_salary_currency_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Desired_Salary_Currency_Choices>;
};

/** on_conflict condition type for table "candidate_curation_desired_salary_currency_choices" */
export type Candidate_Curation_Desired_Salary_Currency_Choices_On_Conflict = {
  constraint: Candidate_Curation_Desired_Salary_Currency_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Desired_Salary_Currency_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_desired_salary_currency_choices". */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_desired_salary_currency_choices */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_desired_salary_currency_choices" */
export enum Candidate_Curation_Desired_Salary_Currency_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_desired_salary_currency_choices" */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_desired_salary_currency_choices" */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Desired_Salary_Currency_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Desired_Salary_Currency_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_desired_salary_currency_choices" */
export enum Candidate_Curation_Desired_Salary_Currency_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Desired_Salary_Currency_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp;
};

/** columns and relationships of "candidate_curation_desired_salary_frequency_choices" */
export type Candidate_Curation_Desired_Salary_Frequency_Choices = {
  __typename?: 'candidate_curation_desired_salary_frequency_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_desired_salary_frequency_choices" */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Aggregate = {
  __typename?: 'candidate_curation_desired_salary_frequency_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Desired_Salary_Frequency_Choices>;
};

/** aggregate fields of "candidate_curation_desired_salary_frequency_choices" */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_desired_salary_frequency_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_desired_salary_frequency_choices" */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_desired_salary_frequency_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_desired_salary_frequency_choices" */
export enum Candidate_Curation_Desired_Salary_Frequency_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationDesiredSalaryFrequencyChoicesPkey = 'candidate_curation_desired_salary_frequency_choices_pkey',
}

export enum Candidate_Curation_Desired_Salary_Frequency_Choices_Enum {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

/** Boolean expression to compare columns of type "candidate_curation_desired_salary_frequency_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_desired_salary_frequency_choices" */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Max_Fields = {
  __typename?: 'candidate_curation_desired_salary_frequency_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Min_Fields = {
  __typename?: 'candidate_curation_desired_salary_frequency_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_desired_salary_frequency_choices" */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_desired_salary_frequency_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Desired_Salary_Frequency_Choices>;
};

/** on_conflict condition type for table "candidate_curation_desired_salary_frequency_choices" */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_On_Conflict = {
  constraint: Candidate_Curation_Desired_Salary_Frequency_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_desired_salary_frequency_choices". */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_desired_salary_frequency_choices */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_desired_salary_frequency_choices" */
export enum Candidate_Curation_Desired_Salary_Frequency_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_desired_salary_frequency_choices" */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_desired_salary_frequency_choices" */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Desired_Salary_Frequency_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Desired_Salary_Frequency_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_desired_salary_frequency_choices" */
export enum Candidate_Curation_Desired_Salary_Frequency_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Desired_Salary_Frequency_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp;
};

/** columns and relationships of "candidate_curation_detail" */
export type Candidate_Curation_Detail = {
  __typename?: 'candidate_curation_detail';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  contractor_currency: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  contractor_payment_frequency: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>;
  contractor_rate: Maybe<Scalars['numeric']>;
  /** Contractor rate with a 33% markup applied to account for fees. */
  contractor_rate_markup: Maybe<Scalars['String']>;
  desired_salary_amount: Maybe<Scalars['bigint']>;
  desired_salary_currency: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  desired_salary_frequency: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>;
  english_proficiency: Maybe<Candidate_Curation_English_Proficiency_Choices_Enum>;
  equity_importance: Maybe<Candidate_Curation_Equity_Importance_Choices_Enum>;
  expected_total_comp: Maybe<Scalars['bigint']>;
  id: Scalars['Int'];
  is_experienced_contractor: Maybe<Scalars['Boolean']>;
  is_licensed_contractor: Maybe<Scalars['Boolean']>;
  recording_link: Maybe<Scalars['String']>;
  recruiter_summary: Maybe<Scalars['String']>;
  /** An object relationship */
  recruiter_summary_current_log: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log>;
  recruiter_summary_current_log_id: Maybe<Scalars['Int']>;
  remote_experience: Maybe<Candidate_Curation_Remote_Work_Exp_Choices_Enum>;
  screener_type: Maybe<Scalars['String']>;
  years_of_exp_range: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  years_of_experience: Maybe<Scalars['Int']>;
};

/** aggregated selection of "candidate_curation_detail" */
export type Candidate_Curation_Detail_Aggregate = {
  __typename?: 'candidate_curation_detail_aggregate';
  aggregate: Maybe<Candidate_Curation_Detail_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Detail>;
};

/** aggregate fields of "candidate_curation_detail" */
export type Candidate_Curation_Detail_Aggregate_Fields = {
  __typename?: 'candidate_curation_detail_aggregate_fields';
  avg: Maybe<Candidate_Curation_Detail_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Detail_Max_Fields>;
  min: Maybe<Candidate_Curation_Detail_Min_Fields>;
  stddev: Maybe<Candidate_Curation_Detail_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Curation_Detail_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Curation_Detail_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Curation_Detail_Sum_Fields>;
  var_pop: Maybe<Candidate_Curation_Detail_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Curation_Detail_Var_Samp_Fields>;
  variance: Maybe<Candidate_Curation_Detail_Variance_Fields>;
};

/** aggregate fields of "candidate_curation_detail" */
export type Candidate_Curation_Detail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Curation_Detail_Avg_Fields = {
  __typename?: 'candidate_curation_detail_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  contractor_rate: Maybe<Scalars['Float']>;
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_comp: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  recruiter_summary_current_log_id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_detail". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Detail_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Detail_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  contractor_currency?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum_Comparison_Exp>;
  contractor_payment_frequency?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum_Comparison_Exp>;
  contractor_rate?: InputMaybe<Numeric_Comparison_Exp>;
  contractor_rate_markup?: InputMaybe<String_Comparison_Exp>;
  desired_salary_amount?: InputMaybe<Bigint_Comparison_Exp>;
  desired_salary_currency?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum_Comparison_Exp>;
  desired_salary_frequency?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum_Comparison_Exp>;
  english_proficiency?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Enum_Comparison_Exp>;
  equity_importance?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Enum_Comparison_Exp>;
  expected_total_comp?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_experienced_contractor?: InputMaybe<Boolean_Comparison_Exp>;
  is_licensed_contractor?: InputMaybe<Boolean_Comparison_Exp>;
  recording_link?: InputMaybe<String_Comparison_Exp>;
  recruiter_summary?: InputMaybe<String_Comparison_Exp>;
  recruiter_summary_current_log?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp>;
  recruiter_summary_current_log_id?: InputMaybe<Int_Comparison_Exp>;
  remote_experience?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Enum_Comparison_Exp>;
  screener_type?: InputMaybe<String_Comparison_Exp>;
  years_of_exp_range?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum_Comparison_Exp>;
  years_of_experience?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_detail" */
export enum Candidate_Curation_Detail_Constraint {
  /** unique or primary key constraint on columns "candidate_id" */
  CandidateCurationDetailCandidateIdKey = 'candidate_curation_detail_candidate_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidateCurationDetailPkey = 'candidate_curation_detail_pkey',
}

/** input type for incrementing numeric columns in table "candidate_curation_detail" */
export type Candidate_Curation_Detail_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  contractor_rate?: InputMaybe<Scalars['numeric']>;
  desired_salary_amount?: InputMaybe<Scalars['bigint']>;
  expected_total_comp?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  recruiter_summary_current_log_id?: InputMaybe<Scalars['Int']>;
  years_of_experience?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_curation_detail" */
export type Candidate_Curation_Detail_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  contractor_currency?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  contractor_payment_frequency?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>;
  contractor_rate?: InputMaybe<Scalars['numeric']>;
  desired_salary_amount?: InputMaybe<Scalars['bigint']>;
  desired_salary_currency?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  desired_salary_frequency?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>;
  english_proficiency?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Enum>;
  equity_importance?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Enum>;
  expected_total_comp?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  is_experienced_contractor?: InputMaybe<Scalars['Boolean']>;
  is_licensed_contractor?: InputMaybe<Scalars['Boolean']>;
  recording_link?: InputMaybe<Scalars['String']>;
  recruiter_summary?: InputMaybe<Scalars['String']>;
  recruiter_summary_current_log?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Obj_Rel_Insert_Input>;
  recruiter_summary_current_log_id?: InputMaybe<Scalars['Int']>;
  remote_experience?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Enum>;
  screener_type?: InputMaybe<Scalars['String']>;
  years_of_exp_range?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  years_of_experience?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Detail_Max_Fields = {
  __typename?: 'candidate_curation_detail_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  contractor_rate: Maybe<Scalars['numeric']>;
  /** Contractor rate with a 33% markup applied to account for fees. */
  contractor_rate_markup: Maybe<Scalars['String']>;
  desired_salary_amount: Maybe<Scalars['bigint']>;
  expected_total_comp: Maybe<Scalars['bigint']>;
  id: Maybe<Scalars['Int']>;
  recording_link: Maybe<Scalars['String']>;
  recruiter_summary: Maybe<Scalars['String']>;
  recruiter_summary_current_log_id: Maybe<Scalars['Int']>;
  screener_type: Maybe<Scalars['String']>;
  years_of_experience: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Detail_Min_Fields = {
  __typename?: 'candidate_curation_detail_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  contractor_rate: Maybe<Scalars['numeric']>;
  /** Contractor rate with a 33% markup applied to account for fees. */
  contractor_rate_markup: Maybe<Scalars['String']>;
  desired_salary_amount: Maybe<Scalars['bigint']>;
  expected_total_comp: Maybe<Scalars['bigint']>;
  id: Maybe<Scalars['Int']>;
  recording_link: Maybe<Scalars['String']>;
  recruiter_summary: Maybe<Scalars['String']>;
  recruiter_summary_current_log_id: Maybe<Scalars['Int']>;
  screener_type: Maybe<Scalars['String']>;
  years_of_experience: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "candidate_curation_detail" */
export type Candidate_Curation_Detail_Mutation_Response = {
  __typename?: 'candidate_curation_detail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Detail>;
};

/** input type for inserting object relation for remote table "candidate_curation_detail" */
export type Candidate_Curation_Detail_Obj_Rel_Insert_Input = {
  data: Candidate_Curation_Detail_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Detail_On_Conflict>;
};

/** on_conflict condition type for table "candidate_curation_detail" */
export type Candidate_Curation_Detail_On_Conflict = {
  constraint: Candidate_Curation_Detail_Constraint;
  update_columns?: Array<Candidate_Curation_Detail_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Detail_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_detail". */
export type Candidate_Curation_Detail_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  contractor_currency?: InputMaybe<Order_By>;
  contractor_payment_frequency?: InputMaybe<Order_By>;
  contractor_rate?: InputMaybe<Order_By>;
  contractor_rate_markup?: InputMaybe<Order_By>;
  desired_salary_amount?: InputMaybe<Order_By>;
  desired_salary_currency?: InputMaybe<Order_By>;
  desired_salary_frequency?: InputMaybe<Order_By>;
  english_proficiency?: InputMaybe<Order_By>;
  equity_importance?: InputMaybe<Order_By>;
  expected_total_comp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_experienced_contractor?: InputMaybe<Order_By>;
  is_licensed_contractor?: InputMaybe<Order_By>;
  recording_link?: InputMaybe<Order_By>;
  recruiter_summary?: InputMaybe<Order_By>;
  recruiter_summary_current_log?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Order_By>;
  recruiter_summary_current_log_id?: InputMaybe<Order_By>;
  remote_experience?: InputMaybe<Order_By>;
  screener_type?: InputMaybe<Order_By>;
  years_of_exp_range?: InputMaybe<Order_By>;
  years_of_experience?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_detail */
export type Candidate_Curation_Detail_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** Append only to this table */
export type Candidate_Curation_Detail_Recruiter_Summary_Log = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log';
  candidate_curation_ai_summary_id: Maybe<Scalars['Int']>;
  candidate_curation_detail_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by_user_info_id: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  summary: Maybe<Scalars['String']>;
};

/** aggregated selection of "candidate_curation_detail_recruiter_summary_log" */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Aggregate = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_aggregate';
  aggregate: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Detail_Recruiter_Summary_Log>;
};

/** aggregate fields of "candidate_curation_detail_recruiter_summary_log" */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Aggregate_Fields = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_aggregate_fields';
  avg: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Max_Fields>;
  min: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Min_Fields>;
  stddev: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Sum_Fields>;
  var_pop: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Var_Samp_Fields>;
  variance: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Variance_Fields>;
};

/** aggregate fields of "candidate_curation_detail_recruiter_summary_log" */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Avg_Fields = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_avg_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  candidate_curation_detail_id: Maybe<Scalars['Float']>;
  created_by_user_info_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_detail_recruiter_summary_log". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp>>;
  candidate_curation_ai_summary_id?: InputMaybe<Int_Comparison_Exp>;
  candidate_curation_detail_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_user_info_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  summary?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_detail_recruiter_summary_log" */
export enum Candidate_Curation_Detail_Recruiter_Summary_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateCurationDetailRecruiterSummaryLogPkey = 'candidate_curation_detail_recruiter_summary_log_pkey',
}

/** input type for incrementing numeric columns in table "candidate_curation_detail_recruiter_summary_log" */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Inc_Input = {
  candidate_curation_ai_summary_id?: InputMaybe<Scalars['Int']>;
  candidate_curation_detail_id?: InputMaybe<Scalars['Int']>;
  created_by_user_info_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_curation_detail_recruiter_summary_log" */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Insert_Input = {
  candidate_curation_ai_summary_id?: InputMaybe<Scalars['Int']>;
  candidate_curation_detail_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user_info_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  summary?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Max_Fields = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_max_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Int']>;
  candidate_curation_detail_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by_user_info_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  summary: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Min_Fields = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_min_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Int']>;
  candidate_curation_detail_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by_user_info_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  summary: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_detail_recruiter_summary_log" */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Mutation_Response = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Detail_Recruiter_Summary_Log>;
};

/** input type for inserting object relation for remote table "candidate_curation_detail_recruiter_summary_log" */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Obj_Rel_Insert_Input = {
  data: Candidate_Curation_Detail_Recruiter_Summary_Log_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_On_Conflict>;
};

/** on_conflict condition type for table "candidate_curation_detail_recruiter_summary_log" */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_On_Conflict = {
  constraint: Candidate_Curation_Detail_Recruiter_Summary_Log_Constraint;
  update_columns?: Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_detail_recruiter_summary_log". */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Order_By = {
  candidate_curation_ai_summary_id?: InputMaybe<Order_By>;
  candidate_curation_detail_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_user_info_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  summary?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_detail_recruiter_summary_log */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_curation_detail_recruiter_summary_log" */
export enum Candidate_Curation_Detail_Recruiter_Summary_Log_Select_Column {
  /** column name */
  CandidateCurationAiSummaryId = 'candidate_curation_ai_summary_id',
  /** column name */
  CandidateCurationDetailId = 'candidate_curation_detail_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserInfoId = 'created_by_user_info_id',
  /** column name */
  Id = 'id',
  /** column name */
  Summary = 'summary',
}

/** input type for updating data in table "candidate_curation_detail_recruiter_summary_log" */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Set_Input = {
  candidate_curation_ai_summary_id?: InputMaybe<Scalars['Int']>;
  candidate_curation_detail_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user_info_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  summary?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Stddev_Fields = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_stddev_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  candidate_curation_detail_id: Maybe<Scalars['Float']>;
  created_by_user_info_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Stddev_Pop_Fields = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_stddev_pop_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  candidate_curation_detail_id: Maybe<Scalars['Float']>;
  created_by_user_info_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Stddev_Samp_Fields = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_stddev_samp_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  candidate_curation_detail_id: Maybe<Scalars['Float']>;
  created_by_user_info_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_curation_detail_recruiter_summary_log" */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Detail_Recruiter_Summary_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Stream_Cursor_Value_Input = {
  candidate_curation_ai_summary_id?: InputMaybe<Scalars['Int']>;
  candidate_curation_detail_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user_info_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  summary?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Sum_Fields = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_sum_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Int']>;
  candidate_curation_detail_id: Maybe<Scalars['Int']>;
  created_by_user_info_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_curation_detail_recruiter_summary_log" */
export enum Candidate_Curation_Detail_Recruiter_Summary_Log_Update_Column {
  /** column name */
  CandidateCurationAiSummaryId = 'candidate_curation_ai_summary_id',
  /** column name */
  CandidateCurationDetailId = 'candidate_curation_detail_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserInfoId = 'created_by_user_info_id',
  /** column name */
  Id = 'id',
  /** column name */
  Summary = 'summary',
}

export type Candidate_Curation_Detail_Recruiter_Summary_Log_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Var_Pop_Fields = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_var_pop_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  candidate_curation_detail_id: Maybe<Scalars['Float']>;
  created_by_user_info_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Var_Samp_Fields = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_var_samp_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  candidate_curation_detail_id: Maybe<Scalars['Float']>;
  created_by_user_info_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Curation_Detail_Recruiter_Summary_Log_Variance_Fields = {
  __typename?: 'candidate_curation_detail_recruiter_summary_log_variance_fields';
  candidate_curation_ai_summary_id: Maybe<Scalars['Float']>;
  candidate_curation_detail_id: Maybe<Scalars['Float']>;
  created_by_user_info_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** select columns of table "candidate_curation_detail" */
export enum Candidate_Curation_Detail_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ContractorCurrency = 'contractor_currency',
  /** column name */
  ContractorPaymentFrequency = 'contractor_payment_frequency',
  /** column name */
  ContractorRate = 'contractor_rate',
  /** column name */
  DesiredSalaryAmount = 'desired_salary_amount',
  /** column name */
  DesiredSalaryCurrency = 'desired_salary_currency',
  /** column name */
  DesiredSalaryFrequency = 'desired_salary_frequency',
  /** column name */
  EnglishProficiency = 'english_proficiency',
  /** column name */
  EquityImportance = 'equity_importance',
  /** column name */
  ExpectedTotalComp = 'expected_total_comp',
  /** column name */
  Id = 'id',
  /** column name */
  IsExperiencedContractor = 'is_experienced_contractor',
  /** column name */
  IsLicensedContractor = 'is_licensed_contractor',
  /** column name */
  RecordingLink = 'recording_link',
  /** column name */
  RecruiterSummary = 'recruiter_summary',
  /** column name */
  RecruiterSummaryCurrentLogId = 'recruiter_summary_current_log_id',
  /** column name */
  RemoteExperience = 'remote_experience',
  /** column name */
  ScreenerType = 'screener_type',
  /** column name */
  YearsOfExpRange = 'years_of_exp_range',
  /** column name */
  YearsOfExperience = 'years_of_experience',
}

/** input type for updating data in table "candidate_curation_detail" */
export type Candidate_Curation_Detail_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  contractor_currency?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  contractor_payment_frequency?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>;
  contractor_rate?: InputMaybe<Scalars['numeric']>;
  desired_salary_amount?: InputMaybe<Scalars['bigint']>;
  desired_salary_currency?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  desired_salary_frequency?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>;
  english_proficiency?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Enum>;
  equity_importance?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Enum>;
  expected_total_comp?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  is_experienced_contractor?: InputMaybe<Scalars['Boolean']>;
  is_licensed_contractor?: InputMaybe<Scalars['Boolean']>;
  recording_link?: InputMaybe<Scalars['String']>;
  recruiter_summary?: InputMaybe<Scalars['String']>;
  recruiter_summary_current_log_id?: InputMaybe<Scalars['Int']>;
  remote_experience?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Enum>;
  screener_type?: InputMaybe<Scalars['String']>;
  years_of_exp_range?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  years_of_experience?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Candidate_Curation_Detail_Stddev_Fields = {
  __typename?: 'candidate_curation_detail_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  contractor_rate: Maybe<Scalars['Float']>;
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_comp: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  recruiter_summary_current_log_id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Curation_Detail_Stddev_Pop_Fields = {
  __typename?: 'candidate_curation_detail_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  contractor_rate: Maybe<Scalars['Float']>;
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_comp: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  recruiter_summary_current_log_id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Curation_Detail_Stddev_Samp_Fields = {
  __typename?: 'candidate_curation_detail_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  contractor_rate: Maybe<Scalars['Float']>;
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_comp: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  recruiter_summary_current_log_id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_curation_detail" */
export type Candidate_Curation_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Detail_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  contractor_currency?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  contractor_payment_frequency?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>;
  contractor_rate?: InputMaybe<Scalars['numeric']>;
  desired_salary_amount?: InputMaybe<Scalars['bigint']>;
  desired_salary_currency?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  desired_salary_frequency?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Enum>;
  english_proficiency?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Enum>;
  equity_importance?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Enum>;
  expected_total_comp?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  is_experienced_contractor?: InputMaybe<Scalars['Boolean']>;
  is_licensed_contractor?: InputMaybe<Scalars['Boolean']>;
  recording_link?: InputMaybe<Scalars['String']>;
  recruiter_summary?: InputMaybe<Scalars['String']>;
  recruiter_summary_current_log_id?: InputMaybe<Scalars['Int']>;
  remote_experience?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Enum>;
  screener_type?: InputMaybe<Scalars['String']>;
  years_of_exp_range?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  years_of_experience?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Curation_Detail_Sum_Fields = {
  __typename?: 'candidate_curation_detail_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  contractor_rate: Maybe<Scalars['numeric']>;
  desired_salary_amount: Maybe<Scalars['bigint']>;
  expected_total_comp: Maybe<Scalars['bigint']>;
  id: Maybe<Scalars['Int']>;
  recruiter_summary_current_log_id: Maybe<Scalars['Int']>;
  years_of_experience: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_curation_detail" */
export enum Candidate_Curation_Detail_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  ContractorCurrency = 'contractor_currency',
  /** column name */
  ContractorPaymentFrequency = 'contractor_payment_frequency',
  /** column name */
  ContractorRate = 'contractor_rate',
  /** column name */
  DesiredSalaryAmount = 'desired_salary_amount',
  /** column name */
  DesiredSalaryCurrency = 'desired_salary_currency',
  /** column name */
  DesiredSalaryFrequency = 'desired_salary_frequency',
  /** column name */
  EnglishProficiency = 'english_proficiency',
  /** column name */
  EquityImportance = 'equity_importance',
  /** column name */
  ExpectedTotalComp = 'expected_total_comp',
  /** column name */
  Id = 'id',
  /** column name */
  IsExperiencedContractor = 'is_experienced_contractor',
  /** column name */
  IsLicensedContractor = 'is_licensed_contractor',
  /** column name */
  RecordingLink = 'recording_link',
  /** column name */
  RecruiterSummary = 'recruiter_summary',
  /** column name */
  RecruiterSummaryCurrentLogId = 'recruiter_summary_current_log_id',
  /** column name */
  RemoteExperience = 'remote_experience',
  /** column name */
  ScreenerType = 'screener_type',
  /** column name */
  YearsOfExpRange = 'years_of_exp_range',
  /** column name */
  YearsOfExperience = 'years_of_experience',
}

export type Candidate_Curation_Detail_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Curation_Detail_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Detail_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Detail_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Curation_Detail_Var_Pop_Fields = {
  __typename?: 'candidate_curation_detail_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  contractor_rate: Maybe<Scalars['Float']>;
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_comp: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  recruiter_summary_current_log_id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Curation_Detail_Var_Samp_Fields = {
  __typename?: 'candidate_curation_detail_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  contractor_rate: Maybe<Scalars['Float']>;
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_comp: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  recruiter_summary_current_log_id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Curation_Detail_Variance_Fields = {
  __typename?: 'candidate_curation_detail_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  contractor_rate: Maybe<Scalars['Float']>;
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_comp: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  recruiter_summary_current_log_id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** columns and relationships of "candidate_curation_english_proficiency_choices" */
export type Candidate_Curation_English_Proficiency_Choices = {
  __typename?: 'candidate_curation_english_proficiency_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_english_proficiency_choices" */
export type Candidate_Curation_English_Proficiency_Choices_Aggregate = {
  __typename?: 'candidate_curation_english_proficiency_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_English_Proficiency_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_English_Proficiency_Choices>;
};

/** aggregate fields of "candidate_curation_english_proficiency_choices" */
export type Candidate_Curation_English_Proficiency_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_english_proficiency_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_English_Proficiency_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_English_Proficiency_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_english_proficiency_choices" */
export type Candidate_Curation_English_Proficiency_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_english_proficiency_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_English_Proficiency_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_english_proficiency_choices" */
export enum Candidate_Curation_English_Proficiency_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationEnglishProficiencyChoicesPkey = 'candidate_curation_english_proficiency_choices_pkey',
}

export enum Candidate_Curation_English_Proficiency_Choices_Enum {
  Basic = 'BASIC',
  Native = 'NATIVE',
  None = 'NONE',
  Proficient = 'PROFICIENT',
}

/** Boolean expression to compare columns of type "candidate_curation_english_proficiency_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_English_Proficiency_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_english_proficiency_choices" */
export type Candidate_Curation_English_Proficiency_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_English_Proficiency_Choices_Max_Fields = {
  __typename?: 'candidate_curation_english_proficiency_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_English_Proficiency_Choices_Min_Fields = {
  __typename?: 'candidate_curation_english_proficiency_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_english_proficiency_choices" */
export type Candidate_Curation_English_Proficiency_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_english_proficiency_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_English_Proficiency_Choices>;
};

/** on_conflict condition type for table "candidate_curation_english_proficiency_choices" */
export type Candidate_Curation_English_Proficiency_Choices_On_Conflict = {
  constraint: Candidate_Curation_English_Proficiency_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_English_Proficiency_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_english_proficiency_choices". */
export type Candidate_Curation_English_Proficiency_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_english_proficiency_choices */
export type Candidate_Curation_English_Proficiency_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_english_proficiency_choices" */
export enum Candidate_Curation_English_Proficiency_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_english_proficiency_choices" */
export type Candidate_Curation_English_Proficiency_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_english_proficiency_choices" */
export type Candidate_Curation_English_Proficiency_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_English_Proficiency_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_English_Proficiency_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_english_proficiency_choices" */
export enum Candidate_Curation_English_Proficiency_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_English_Proficiency_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_English_Proficiency_Choices_Bool_Exp;
};

/** columns and relationships of "candidate_curation_equity_importance_choices" */
export type Candidate_Curation_Equity_Importance_Choices = {
  __typename?: 'candidate_curation_equity_importance_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_equity_importance_choices" */
export type Candidate_Curation_Equity_Importance_Choices_Aggregate = {
  __typename?: 'candidate_curation_equity_importance_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Equity_Importance_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Equity_Importance_Choices>;
};

/** aggregate fields of "candidate_curation_equity_importance_choices" */
export type Candidate_Curation_Equity_Importance_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_equity_importance_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Equity_Importance_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Equity_Importance_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_equity_importance_choices" */
export type Candidate_Curation_Equity_Importance_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_equity_importance_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Equity_Importance_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_equity_importance_choices" */
export enum Candidate_Curation_Equity_Importance_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationEquityImportanceChoicesPkey = 'candidate_curation_equity_importance_choices_pkey',
}

export enum Candidate_Curation_Equity_Importance_Choices_Enum {
  NotImportant = 'NOT_IMPORTANT',
  SomewhatImportant = 'SOMEWHAT_IMPORTANT',
  VeryImportant = 'VERY_IMPORTANT',
}

/** Boolean expression to compare columns of type "candidate_curation_equity_importance_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Equity_Importance_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_equity_importance_choices" */
export type Candidate_Curation_Equity_Importance_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Equity_Importance_Choices_Max_Fields = {
  __typename?: 'candidate_curation_equity_importance_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Equity_Importance_Choices_Min_Fields = {
  __typename?: 'candidate_curation_equity_importance_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_equity_importance_choices" */
export type Candidate_Curation_Equity_Importance_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_equity_importance_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Equity_Importance_Choices>;
};

/** on_conflict condition type for table "candidate_curation_equity_importance_choices" */
export type Candidate_Curation_Equity_Importance_Choices_On_Conflict = {
  constraint: Candidate_Curation_Equity_Importance_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Equity_Importance_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_equity_importance_choices". */
export type Candidate_Curation_Equity_Importance_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_equity_importance_choices */
export type Candidate_Curation_Equity_Importance_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_equity_importance_choices" */
export enum Candidate_Curation_Equity_Importance_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_equity_importance_choices" */
export type Candidate_Curation_Equity_Importance_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_equity_importance_choices" */
export type Candidate_Curation_Equity_Importance_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Equity_Importance_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Equity_Importance_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_equity_importance_choices" */
export enum Candidate_Curation_Equity_Importance_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Equity_Importance_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Equity_Importance_Choices_Bool_Exp;
};

/** columns and relationships of "candidate_curation_note" */
export type Candidate_Curation_Note = {
  __typename?: 'candidate_curation_note';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  /** An object relationship */
  created_by_user: User_Info;
  id: Scalars['Int'];
  note: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "candidate_curation_note" */
export type Candidate_Curation_Note_Aggregate = {
  __typename?: 'candidate_curation_note_aggregate';
  aggregate: Maybe<Candidate_Curation_Note_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Note>;
};

export type Candidate_Curation_Note_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Curation_Note_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Curation_Note_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Curation_Note_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Curation_Note_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_curation_note" */
export type Candidate_Curation_Note_Aggregate_Fields = {
  __typename?: 'candidate_curation_note_aggregate_fields';
  avg: Maybe<Candidate_Curation_Note_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Note_Max_Fields>;
  min: Maybe<Candidate_Curation_Note_Min_Fields>;
  stddev: Maybe<Candidate_Curation_Note_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Curation_Note_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Curation_Note_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Curation_Note_Sum_Fields>;
  var_pop: Maybe<Candidate_Curation_Note_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Curation_Note_Var_Samp_Fields>;
  variance: Maybe<Candidate_Curation_Note_Variance_Fields>;
};

/** aggregate fields of "candidate_curation_note" */
export type Candidate_Curation_Note_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Note_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_curation_note" */
export type Candidate_Curation_Note_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Curation_Note_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Curation_Note_Max_Order_By>;
  min?: InputMaybe<Candidate_Curation_Note_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Curation_Note_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Curation_Note_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Curation_Note_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Curation_Note_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Curation_Note_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Curation_Note_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Curation_Note_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_curation_note" */
export type Candidate_Curation_Note_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Curation_Note_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Note_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Curation_Note_Avg_Fields = {
  __typename?: 'candidate_curation_note_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  created_by: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_curation_note" */
export type Candidate_Curation_Note_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_curation_note". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Note_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Note_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Note_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Note_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  created_by_user?: InputMaybe<User_Info_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_note" */
export enum Candidate_Curation_Note_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateCurationNotePkey = 'candidate_curation_note_pkey',
}

/** input type for incrementing numeric columns in table "candidate_curation_note" */
export type Candidate_Curation_Note_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_curation_note" */
export type Candidate_Curation_Note_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  created_by_user?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Note_Max_Fields = {
  __typename?: 'candidate_curation_note_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  note: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_curation_note" */
export type Candidate_Curation_Note_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Curation_Note_Min_Fields = {
  __typename?: 'candidate_curation_note_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  note: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_curation_note" */
export type Candidate_Curation_Note_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_curation_note" */
export type Candidate_Curation_Note_Mutation_Response = {
  __typename?: 'candidate_curation_note_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Note>;
};

/** on_conflict condition type for table "candidate_curation_note" */
export type Candidate_Curation_Note_On_Conflict = {
  constraint: Candidate_Curation_Note_Constraint;
  update_columns?: Array<Candidate_Curation_Note_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Note_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_note". */
export type Candidate_Curation_Note_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_user?: InputMaybe<User_Info_Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_note */
export type Candidate_Curation_Note_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_curation_note" */
export enum Candidate_Curation_Note_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "candidate_curation_note" */
export type Candidate_Curation_Note_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Candidate_Curation_Note_Stddev_Fields = {
  __typename?: 'candidate_curation_note_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  created_by: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_curation_note" */
export type Candidate_Curation_Note_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Curation_Note_Stddev_Pop_Fields = {
  __typename?: 'candidate_curation_note_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  created_by: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_curation_note" */
export type Candidate_Curation_Note_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Curation_Note_Stddev_Samp_Fields = {
  __typename?: 'candidate_curation_note_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  created_by: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_curation_note" */
export type Candidate_Curation_Note_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_curation_note" */
export type Candidate_Curation_Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Note_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Note_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Candidate_Curation_Note_Sum_Fields = {
  __typename?: 'candidate_curation_note_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_by: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_curation_note" */
export type Candidate_Curation_Note_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_curation_note" */
export enum Candidate_Curation_Note_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Candidate_Curation_Note_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Curation_Note_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Note_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Note_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Curation_Note_Var_Pop_Fields = {
  __typename?: 'candidate_curation_note_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  created_by: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_curation_note" */
export type Candidate_Curation_Note_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Curation_Note_Var_Samp_Fields = {
  __typename?: 'candidate_curation_note_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  created_by: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_curation_note" */
export type Candidate_Curation_Note_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Curation_Note_Variance_Fields = {
  __typename?: 'candidate_curation_note_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  created_by: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_curation_note" */
export type Candidate_Curation_Note_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "candidate_curation_rejected_reason_choices" */
export type Candidate_Curation_Rejected_Reason_Choices = {
  __typename?: 'candidate_curation_rejected_reason_choices';
  comment: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_rejected_reason_choices" */
export type Candidate_Curation_Rejected_Reason_Choices_Aggregate = {
  __typename?: 'candidate_curation_rejected_reason_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Rejected_Reason_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Rejected_Reason_Choices>;
};

/** aggregate fields of "candidate_curation_rejected_reason_choices" */
export type Candidate_Curation_Rejected_Reason_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_rejected_reason_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Rejected_Reason_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Rejected_Reason_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_rejected_reason_choices" */
export type Candidate_Curation_Rejected_Reason_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_rejected_reason_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Rejected_Reason_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_rejected_reason_choices" */
export enum Candidate_Curation_Rejected_Reason_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationRejectedReasonChoicesPkey = 'candidate_curation_rejected_reason_choices_pkey',
}

export enum Candidate_Curation_Rejected_Reason_Choices_Enum {
  FailedSkillsTest = 'FAILED_SKILLS_TEST',
  JobHopper = 'JOB_HOPPER',
  LowTerminalScore = 'LOW_TERMINAL_SCORE',
  NotCoreFocus = 'NOT_CORE_FOCUS',
  NotCoreRole = 'NOT_CORE_ROLE',
  NotIc = 'NOT_IC',
  Other = 'OTHER',
  OutsideGeo = 'OUTSIDE_GEO',
  Timing = 'TIMING',
  TooJunior = 'TOO_JUNIOR',
  ValuableProfile = 'VALUABLE_PROFILE',
  WorkAuthorization = 'WORK_AUTHORIZATION',
}

/** Boolean expression to compare columns of type "candidate_curation_rejected_reason_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Rejected_Reason_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_rejected_reason_choices" */
export type Candidate_Curation_Rejected_Reason_Choices_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Rejected_Reason_Choices_Max_Fields = {
  __typename?: 'candidate_curation_rejected_reason_choices_max_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Rejected_Reason_Choices_Min_Fields = {
  __typename?: 'candidate_curation_rejected_reason_choices_min_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_rejected_reason_choices" */
export type Candidate_Curation_Rejected_Reason_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_rejected_reason_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Rejected_Reason_Choices>;
};

/** on_conflict condition type for table "candidate_curation_rejected_reason_choices" */
export type Candidate_Curation_Rejected_Reason_Choices_On_Conflict = {
  constraint: Candidate_Curation_Rejected_Reason_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Rejected_Reason_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_rejected_reason_choices". */
export type Candidate_Curation_Rejected_Reason_Choices_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_rejected_reason_choices */
export type Candidate_Curation_Rejected_Reason_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_rejected_reason_choices" */
export enum Candidate_Curation_Rejected_Reason_Choices_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_rejected_reason_choices" */
export type Candidate_Curation_Rejected_Reason_Choices_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_rejected_reason_choices" */
export type Candidate_Curation_Rejected_Reason_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Rejected_Reason_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Rejected_Reason_Choices_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_rejected_reason_choices" */
export enum Candidate_Curation_Rejected_Reason_Choices_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Rejected_Reason_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Rejected_Reason_Choices_Bool_Exp;
};

/** columns and relationships of "candidate_curation_remote_work_exp_choices" */
export type Candidate_Curation_Remote_Work_Exp_Choices = {
  __typename?: 'candidate_curation_remote_work_exp_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_remote_work_exp_choices" */
export type Candidate_Curation_Remote_Work_Exp_Choices_Aggregate = {
  __typename?: 'candidate_curation_remote_work_exp_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Remote_Work_Exp_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Remote_Work_Exp_Choices>;
};

/** aggregate fields of "candidate_curation_remote_work_exp_choices" */
export type Candidate_Curation_Remote_Work_Exp_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_remote_work_exp_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Remote_Work_Exp_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Remote_Work_Exp_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_remote_work_exp_choices" */
export type Candidate_Curation_Remote_Work_Exp_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_remote_work_exp_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_remote_work_exp_choices" */
export enum Candidate_Curation_Remote_Work_Exp_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationRemoteWorkExpChoicesPkey = 'candidate_curation_remote_work_exp_choices_pkey',
}

export enum Candidate_Curation_Remote_Work_Exp_Choices_Enum {
  OneTwo = 'ONE_TWO',
  ThreePlus = 'THREE_PLUS',
  ZeroOne = 'ZERO_ONE',
}

/** Boolean expression to compare columns of type "candidate_curation_remote_work_exp_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Remote_Work_Exp_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_remote_work_exp_choices" */
export type Candidate_Curation_Remote_Work_Exp_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Remote_Work_Exp_Choices_Max_Fields = {
  __typename?: 'candidate_curation_remote_work_exp_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Remote_Work_Exp_Choices_Min_Fields = {
  __typename?: 'candidate_curation_remote_work_exp_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_remote_work_exp_choices" */
export type Candidate_Curation_Remote_Work_Exp_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_remote_work_exp_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Remote_Work_Exp_Choices>;
};

/** on_conflict condition type for table "candidate_curation_remote_work_exp_choices" */
export type Candidate_Curation_Remote_Work_Exp_Choices_On_Conflict = {
  constraint: Candidate_Curation_Remote_Work_Exp_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Remote_Work_Exp_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_remote_work_exp_choices". */
export type Candidate_Curation_Remote_Work_Exp_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_remote_work_exp_choices */
export type Candidate_Curation_Remote_Work_Exp_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_remote_work_exp_choices" */
export enum Candidate_Curation_Remote_Work_Exp_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_remote_work_exp_choices" */
export type Candidate_Curation_Remote_Work_Exp_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_remote_work_exp_choices" */
export type Candidate_Curation_Remote_Work_Exp_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Remote_Work_Exp_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Remote_Work_Exp_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_remote_work_exp_choices" */
export enum Candidate_Curation_Remote_Work_Exp_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Remote_Work_Exp_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp;
};

/** columns and relationships of "candidate_curation_visa_status_choices" */
export type Candidate_Curation_Visa_Status_Choices = {
  __typename?: 'candidate_curation_visa_status_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_visa_status_choices" */
export type Candidate_Curation_Visa_Status_Choices_Aggregate = {
  __typename?: 'candidate_curation_visa_status_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Visa_Status_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Visa_Status_Choices>;
};

/** aggregate fields of "candidate_curation_visa_status_choices" */
export type Candidate_Curation_Visa_Status_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_visa_status_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Visa_Status_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Visa_Status_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_visa_status_choices" */
export type Candidate_Curation_Visa_Status_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_visa_status_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Visa_Status_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_visa_status_choices" */
export enum Candidate_Curation_Visa_Status_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationVisaStatusChoicesPkey = 'candidate_curation_visa_status_choices_pkey',
}

export enum Candidate_Curation_Visa_Status_Choices_Enum {
  CitizenOrPr = 'CITIZEN_OR_PR',
  ClosedWorkPermitExp_1_3Year = 'CLOSED_WORK_PERMIT_EXP_1_3_YEAR',
  ClosedWorkPermitExp_1Year = 'CLOSED_WORK_PERMIT_EXP_1_YEAR',
  NoValidWorkAuth = 'NO_VALID_WORK_AUTH',
  OpenWorkPermitExp_1_3Year = 'OPEN_WORK_PERMIT_EXP_1_3_YEAR',
  OpenWorkPermitExp_1Year = 'OPEN_WORK_PERMIT_EXP_1_YEAR',
  OtherValidClosedWorkPermit = 'OTHER_VALID_CLOSED_WORK_PERMIT',
  OtherValidWorkAuth = 'OTHER_VALID_WORK_AUTH',
}

/** Boolean expression to compare columns of type "candidate_curation_visa_status_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Visa_Status_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_visa_status_choices" */
export type Candidate_Curation_Visa_Status_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Visa_Status_Choices_Max_Fields = {
  __typename?: 'candidate_curation_visa_status_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Visa_Status_Choices_Min_Fields = {
  __typename?: 'candidate_curation_visa_status_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_visa_status_choices" */
export type Candidate_Curation_Visa_Status_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_visa_status_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Visa_Status_Choices>;
};

/** on_conflict condition type for table "candidate_curation_visa_status_choices" */
export type Candidate_Curation_Visa_Status_Choices_On_Conflict = {
  constraint: Candidate_Curation_Visa_Status_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Visa_Status_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_visa_status_choices". */
export type Candidate_Curation_Visa_Status_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_visa_status_choices */
export type Candidate_Curation_Visa_Status_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_visa_status_choices" */
export enum Candidate_Curation_Visa_Status_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_visa_status_choices" */
export type Candidate_Curation_Visa_Status_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_visa_status_choices" */
export type Candidate_Curation_Visa_Status_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Visa_Status_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Visa_Status_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_visa_status_choices" */
export enum Candidate_Curation_Visa_Status_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Visa_Status_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Visa_Status_Choices_Bool_Exp;
};

/** columns and relationships of "candidate_curation_workflow" */
export type Candidate_Curation_Workflow = {
  __typename?: 'candidate_curation_workflow';
  async_interview_url: Maybe<Scalars['String']>;
  /** An object relationship */
  candidate: Candidate;
  /** An array relationship */
  candidate_curation_workflow_needs_info_reasons: Array<Candidate_Curation_Workflow_Needs_Info_Reason>;
  /** An aggregate relationship */
  candidate_curation_workflow_needs_info_reasons_aggregate: Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate;
  /** An object relationship */
  candidate_curation_workflow_source_choice: Candidate_Curation_Workflow_Source_Choices;
  /** An object relationship */
  candidate_curation_workflow_status_choice: Candidate_Curation_Workflow_Status_Choices;
  /** An array relationship */
  candidate_curation_workflow_status_logs: Array<Candidate_Curation_Workflow_Status_Log>;
  /** An aggregate relationship */
  candidate_curation_workflow_status_logs_aggregate: Candidate_Curation_Workflow_Status_Log_Aggregate;
  candidate_id: Scalars['Int'];
  cid: Maybe<Scalars['String']>;
  comment: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  external_candidate_meta: Maybe<External_Candidate_Meta>;
  external_candidate_meta_id: Maybe<Scalars['Int']>;
  form_submission_url: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  owner_user_info: Maybe<User_Info>;
  owner_user_info_id: Maybe<Scalars['Int']>;
  referring_url: Maybe<Scalars['String']>;
  rejected_reason: Maybe<Candidate_Curation_Rejected_Reason_Choices_Enum>;
  screener_type: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices_Enum>;
  secondary_sourcer_id: Maybe<Scalars['Int']>;
  source: Candidate_Curation_Workflow_Source_Choices_Enum;
  /** An object relationship */
  source_applicant_job: Maybe<Applicant_Job>;
  source_applicant_job_id: Maybe<Scalars['Int']>;
  sourcer_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  sourcer_user_info: Maybe<User_Info>;
  status: Candidate_Curation_Workflow_Status_Choices_Enum;
  updated_at: Scalars['timestamptz'];
  updated_by: Maybe<Scalars['Int']>;
  utm_campaign: Maybe<Scalars['String']>;
  utm_content: Maybe<Scalars['String']>;
  utm_medium: Maybe<Scalars['String']>;
  utm_source: Maybe<Scalars['String']>;
  utm_term: Maybe<Scalars['String']>;
};

/** columns and relationships of "candidate_curation_workflow" */
export type Candidate_Curation_WorkflowCandidate_Curation_Workflow_Needs_Info_ReasonsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>;
};

/** columns and relationships of "candidate_curation_workflow" */
export type Candidate_Curation_WorkflowCandidate_Curation_Workflow_Needs_Info_Reasons_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Order_By>>;
    where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>;
  };

/** columns and relationships of "candidate_curation_workflow" */
export type Candidate_Curation_WorkflowCandidate_Curation_Workflow_Status_LogsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Bool_Exp>;
};

/** columns and relationships of "candidate_curation_workflow" */
export type Candidate_Curation_WorkflowCandidate_Curation_Workflow_Status_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Bool_Exp>;
};

/** aggregated selection of "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Aggregate = {
  __typename?: 'candidate_curation_workflow_aggregate';
  aggregate: Maybe<Candidate_Curation_Workflow_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Workflow>;
};

export type Candidate_Curation_Workflow_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Curation_Workflow_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Curation_Workflow_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Curation_Workflow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Aggregate_Fields = {
  __typename?: 'candidate_curation_workflow_aggregate_fields';
  avg: Maybe<Candidate_Curation_Workflow_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Workflow_Max_Fields>;
  min: Maybe<Candidate_Curation_Workflow_Min_Fields>;
  stddev: Maybe<Candidate_Curation_Workflow_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Curation_Workflow_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Curation_Workflow_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Curation_Workflow_Sum_Fields>;
  var_pop: Maybe<Candidate_Curation_Workflow_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Curation_Workflow_Var_Samp_Fields>;
  variance: Maybe<Candidate_Curation_Workflow_Variance_Fields>;
};

/** aggregate fields of "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Workflow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Curation_Workflow_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Curation_Workflow_Max_Order_By>;
  min?: InputMaybe<Candidate_Curation_Workflow_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Curation_Workflow_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Curation_Workflow_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Curation_Workflow_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Curation_Workflow_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Curation_Workflow_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Curation_Workflow_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Curation_Workflow_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Curation_Workflow_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Curation_Workflow_Avg_Fields = {
  __typename?: 'candidate_curation_workflow_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  external_candidate_meta_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  owner_user_info_id: Maybe<Scalars['Float']>;
  secondary_sourcer_id: Maybe<Scalars['Float']>;
  source_applicant_job_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  external_candidate_meta_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_info_id?: InputMaybe<Order_By>;
  secondary_sourcer_id?: InputMaybe<Order_By>;
  source_applicant_job_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_curation_workflow". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Workflow_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Workflow_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Workflow_Bool_Exp>>;
  async_interview_url?: InputMaybe<String_Comparison_Exp>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_curation_workflow_needs_info_reasons?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>;
  candidate_curation_workflow_needs_info_reasons_aggregate?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate_Bool_Exp>;
  candidate_curation_workflow_source_choice?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Bool_Exp>;
  candidate_curation_workflow_status_choice?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Bool_Exp>;
  candidate_curation_workflow_status_logs?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Bool_Exp>;
  candidate_curation_workflow_status_logs_aggregate?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Aggregate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  cid?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_candidate_meta?: InputMaybe<External_Candidate_Meta_Bool_Exp>;
  external_candidate_meta_id?: InputMaybe<Int_Comparison_Exp>;
  form_submission_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  owner_user_info?: InputMaybe<User_Info_Bool_Exp>;
  owner_user_info_id?: InputMaybe<Int_Comparison_Exp>;
  referring_url?: InputMaybe<String_Comparison_Exp>;
  rejected_reason?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Enum_Comparison_Exp>;
  screener_type?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Enum_Comparison_Exp>;
  secondary_sourcer_id?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum_Comparison_Exp>;
  source_applicant_job?: InputMaybe<Applicant_Job_Bool_Exp>;
  source_applicant_job_id?: InputMaybe<Int_Comparison_Exp>;
  sourcer_id?: InputMaybe<Int_Comparison_Exp>;
  sourcer_user_info?: InputMaybe<User_Info_Bool_Exp>;
  status?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  utm_campaign?: InputMaybe<String_Comparison_Exp>;
  utm_content?: InputMaybe<String_Comparison_Exp>;
  utm_medium?: InputMaybe<String_Comparison_Exp>;
  utm_source?: InputMaybe<String_Comparison_Exp>;
  utm_term?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_workflow" */
export enum Candidate_Curation_Workflow_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateCurationWorkflowPkey = 'candidate_curation_workflow_pkey',
}

/** input type for incrementing numeric columns in table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  external_candidate_meta_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  owner_user_info_id?: InputMaybe<Scalars['Int']>;
  secondary_sourcer_id?: InputMaybe<Scalars['Int']>;
  source_applicant_job_id?: InputMaybe<Scalars['Int']>;
  sourcer_id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Insert_Input = {
  async_interview_url?: InputMaybe<Scalars['String']>;
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_curation_workflow_needs_info_reasons?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Arr_Rel_Insert_Input>;
  candidate_curation_workflow_source_choice?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Obj_Rel_Insert_Input>;
  candidate_curation_workflow_status_choice?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Obj_Rel_Insert_Input>;
  candidate_curation_workflow_status_logs?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Arr_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  cid?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_candidate_meta?: InputMaybe<External_Candidate_Meta_Obj_Rel_Insert_Input>;
  external_candidate_meta_id?: InputMaybe<Scalars['Int']>;
  form_submission_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  owner_user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  owner_user_info_id?: InputMaybe<Scalars['Int']>;
  referring_url?: InputMaybe<Scalars['String']>;
  rejected_reason?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Enum>;
  screener_type?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Enum>;
  secondary_sourcer_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum>;
  source_applicant_job?: InputMaybe<Applicant_Job_Obj_Rel_Insert_Input>;
  source_applicant_job_id?: InputMaybe<Scalars['Int']>;
  sourcer_id?: InputMaybe<Scalars['Int']>;
  sourcer_user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
  utm_term?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Workflow_Max_Fields = {
  __typename?: 'candidate_curation_workflow_max_fields';
  async_interview_url: Maybe<Scalars['String']>;
  candidate_id: Maybe<Scalars['Int']>;
  cid: Maybe<Scalars['String']>;
  comment: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  external_candidate_meta_id: Maybe<Scalars['Int']>;
  form_submission_url: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  owner_user_info_id: Maybe<Scalars['Int']>;
  referring_url: Maybe<Scalars['String']>;
  secondary_sourcer_id: Maybe<Scalars['Int']>;
  source_applicant_job_id: Maybe<Scalars['Int']>;
  sourcer_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['Int']>;
  utm_campaign: Maybe<Scalars['String']>;
  utm_content: Maybe<Scalars['String']>;
  utm_medium: Maybe<Scalars['String']>;
  utm_source: Maybe<Scalars['String']>;
  utm_term: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Max_Order_By = {
  async_interview_url?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  cid?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_candidate_meta_id?: InputMaybe<Order_By>;
  form_submission_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_info_id?: InputMaybe<Order_By>;
  referring_url?: InputMaybe<Order_By>;
  secondary_sourcer_id?: InputMaybe<Order_By>;
  source_applicant_job_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  utm_campaign?: InputMaybe<Order_By>;
  utm_content?: InputMaybe<Order_By>;
  utm_medium?: InputMaybe<Order_By>;
  utm_source?: InputMaybe<Order_By>;
  utm_term?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Curation_Workflow_Min_Fields = {
  __typename?: 'candidate_curation_workflow_min_fields';
  async_interview_url: Maybe<Scalars['String']>;
  candidate_id: Maybe<Scalars['Int']>;
  cid: Maybe<Scalars['String']>;
  comment: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  external_candidate_meta_id: Maybe<Scalars['Int']>;
  form_submission_url: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  owner_user_info_id: Maybe<Scalars['Int']>;
  referring_url: Maybe<Scalars['String']>;
  secondary_sourcer_id: Maybe<Scalars['Int']>;
  source_applicant_job_id: Maybe<Scalars['Int']>;
  sourcer_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['Int']>;
  utm_campaign: Maybe<Scalars['String']>;
  utm_content: Maybe<Scalars['String']>;
  utm_medium: Maybe<Scalars['String']>;
  utm_source: Maybe<Scalars['String']>;
  utm_term: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Min_Order_By = {
  async_interview_url?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  cid?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_candidate_meta_id?: InputMaybe<Order_By>;
  form_submission_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_info_id?: InputMaybe<Order_By>;
  referring_url?: InputMaybe<Order_By>;
  secondary_sourcer_id?: InputMaybe<Order_By>;
  source_applicant_job_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  utm_campaign?: InputMaybe<Order_By>;
  utm_content?: InputMaybe<Order_By>;
  utm_medium?: InputMaybe<Order_By>;
  utm_source?: InputMaybe<Order_By>;
  utm_term?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Mutation_Response = {
  __typename?: 'candidate_curation_workflow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Workflow>;
};

/** columns and relationships of "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason = {
  __typename?: 'candidate_curation_workflow_needs_info_reason';
  curation_workflow_id: Scalars['Int'];
  id: Scalars['Int'];
  needs_info_reason: Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Enum;
};

/** aggregated selection of "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_aggregate';
  aggregate: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Workflow_Needs_Info_Reason>;
};

export type Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_aggregate_fields';
  avg: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Max_Fields>;
  min: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Min_Fields>;
  stddev: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Sum_Fields>;
  var_pop: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Var_Samp_Fields>;
  variance: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Variance_Fields>;
};

/** aggregate fields of "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Max_Order_By>;
  min?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Avg_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_avg_fields';
  curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Avg_Order_By = {
  curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_curation_workflow_needs_info_reason". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>>;
  curation_workflow_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  needs_info_reason?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Enum_Comparison_Exp>;
};

/** columns and relationships of "candidate_curation_workflow_needs_info_reason_choices" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_workflow_needs_info_reason_choices" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Aggregate = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices>;
};

/** aggregate fields of "candidate_curation_workflow_needs_info_reason_choices" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_workflow_needs_info_reason_choices" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_workflow_needs_info_reason_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_workflow_needs_info_reason_choices" */
export enum Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationWorkflowNeedsInfoReasonChoicesPkey = 'candidate_curation_workflow_needs_info_reason_choices_pkey',
}

export enum Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Enum {
  CurrentCompanyRole = 'CURRENT_COMPANY_ROLE',
  DesiredRole = 'DESIRED_ROLE',
  LinkedinUrlIncorrect = 'LINKEDIN_URL_INCORRECT',
  LocationMismatchExp = 'LOCATION_MISMATCH_EXP',
  MissingLocation = 'MISSING_LOCATION',
  Other = 'OTHER',
  RoleFocusArea = 'ROLE_FOCUS_AREA',
  SkillsUsedRecentExp = 'SKILLS_USED_RECENT_EXP',
  SkillFocusArea = 'SKILL_FOCUS_AREA',
  SparseResume = 'SPARSE_RESUME',
  TimelineClarification = 'TIMELINE_CLARIFICATION',
}

/** Boolean expression to compare columns of type "candidate_curation_workflow_needs_info_reason_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_workflow_needs_info_reason_choices" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Max_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Min_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_workflow_needs_info_reason_choices" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices>;
};

/** on_conflict condition type for table "candidate_curation_workflow_needs_info_reason_choices" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_On_Conflict = {
  constraint: Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_workflow_needs_info_reason_choices". */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_workflow_needs_info_reason_choices */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_workflow_needs_info_reason_choices" */
export enum Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_workflow_needs_info_reason_choices" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_workflow_needs_info_reason_choices" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_workflow_needs_info_reason_choices" */
export enum Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp;
};

/** unique or primary key constraints on table "candidate_curation_workflow_needs_info_reason" */
export enum Candidate_Curation_Workflow_Needs_Info_Reason_Constraint {
  /** unique or primary key constraint on columns "needs_info_reason", "curation_workflow_id" */
  CandidateCurationWorkflowNCurationWorkflowIdNeedsInKey = 'candidate_curation_workflow_n_curation_workflow_id_needs_in_key',
  /** unique or primary key constraint on columns "id" */
  CandidateCurationWorkflowNeedsInfoReasonPkey = 'candidate_curation_workflow_needs_info_reason_pkey',
}

/** input type for incrementing numeric columns in table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Inc_Input = {
  curation_workflow_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Insert_Input = {
  curation_workflow_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  needs_info_reason?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Enum>;
};

/** aggregate max on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Max_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_max_fields';
  curation_workflow_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Max_Order_By = {
  curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Min_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_min_fields';
  curation_workflow_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Min_Order_By = {
  curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Mutation_Response = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Workflow_Needs_Info_Reason>;
};

/** on_conflict condition type for table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_On_Conflict = {
  constraint: Candidate_Curation_Workflow_Needs_Info_Reason_Constraint;
  update_columns?: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_workflow_needs_info_reason". */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Order_By = {
  curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  needs_info_reason?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_workflow_needs_info_reason */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_curation_workflow_needs_info_reason" */
export enum Candidate_Curation_Workflow_Needs_Info_Reason_Select_Column {
  /** column name */
  CurationWorkflowId = 'curation_workflow_id',
  /** column name */
  Id = 'id',
  /** column name */
  NeedsInfoReason = 'needs_info_reason',
}

/** input type for updating data in table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Set_Input = {
  curation_workflow_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  needs_info_reason?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Enum>;
};

/** aggregate stddev on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_stddev_fields';
  curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Order_By = {
  curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Pop_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_stddev_pop_fields';
  curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Pop_Order_By = {
  curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Samp_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_stddev_samp_fields';
  curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Stddev_Samp_Order_By = {
  curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Workflow_Needs_Info_Reason_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Stream_Cursor_Value_Input = {
  curation_workflow_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  needs_info_reason?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Enum>;
};

/** aggregate sum on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Sum_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_sum_fields';
  curation_workflow_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Sum_Order_By = {
  curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_curation_workflow_needs_info_reason" */
export enum Candidate_Curation_Workflow_Needs_Info_Reason_Update_Column {
  /** column name */
  CurationWorkflowId = 'curation_workflow_id',
  /** column name */
  Id = 'id',
  /** column name */
  NeedsInfoReason = 'needs_info_reason',
}

export type Candidate_Curation_Workflow_Needs_Info_Reason_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Var_Pop_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_var_pop_fields';
  curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Var_Pop_Order_By = {
  curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Var_Samp_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_var_samp_fields';
  curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Var_Samp_Order_By = {
  curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Variance_Fields = {
  __typename?: 'candidate_curation_workflow_needs_info_reason_variance_fields';
  curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_curation_workflow_needs_info_reason" */
export type Candidate_Curation_Workflow_Needs_Info_Reason_Variance_Order_By = {
  curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Obj_Rel_Insert_Input = {
  data: Candidate_Curation_Workflow_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_On_Conflict>;
};

/** on_conflict condition type for table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_On_Conflict = {
  constraint: Candidate_Curation_Workflow_Constraint;
  update_columns?: Array<Candidate_Curation_Workflow_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_workflow". */
export type Candidate_Curation_Workflow_Order_By = {
  async_interview_url?: InputMaybe<Order_By>;
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_curation_workflow_needs_info_reasons_aggregate?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate_Order_By>;
  candidate_curation_workflow_source_choice?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Order_By>;
  candidate_curation_workflow_status_choice?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Order_By>;
  candidate_curation_workflow_status_logs_aggregate?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Aggregate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  cid?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_candidate_meta?: InputMaybe<External_Candidate_Meta_Order_By>;
  external_candidate_meta_id?: InputMaybe<Order_By>;
  form_submission_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_info?: InputMaybe<User_Info_Order_By>;
  owner_user_info_id?: InputMaybe<Order_By>;
  referring_url?: InputMaybe<Order_By>;
  rejected_reason?: InputMaybe<Order_By>;
  screener_type?: InputMaybe<Order_By>;
  secondary_sourcer_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_applicant_job?: InputMaybe<Applicant_Job_Order_By>;
  source_applicant_job_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
  sourcer_user_info?: InputMaybe<User_Info_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  utm_campaign?: InputMaybe<Order_By>;
  utm_content?: InputMaybe<Order_By>;
  utm_medium?: InputMaybe<Order_By>;
  utm_source?: InputMaybe<Order_By>;
  utm_term?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_workflow */
export type Candidate_Curation_Workflow_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "candidate_curation_workflow_screener_type_choices" */
export type Candidate_Curation_Workflow_Screener_Type_Choices = {
  __typename?: 'candidate_curation_workflow_screener_type_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_workflow_screener_type_choices" */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Aggregate = {
  __typename?: 'candidate_curation_workflow_screener_type_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Workflow_Screener_Type_Choices>;
};

/** aggregate fields of "candidate_curation_workflow_screener_type_choices" */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_workflow_screener_type_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_workflow_screener_type_choices" */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_workflow_screener_type_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_workflow_screener_type_choices" */
export enum Candidate_Curation_Workflow_Screener_Type_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationWorkflowScreenerTypeChoicesPkey = 'candidate_curation_workflow_screener_type_choices_pkey',
}

export enum Candidate_Curation_Workflow_Screener_Type_Choices_Enum {
  AsyncInterview = 'ASYNC_INTERVIEW',
  PhoneScreen = 'PHONE_SCREEN',
}

/** Boolean expression to compare columns of type "candidate_curation_workflow_screener_type_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_workflow_screener_type_choices" */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Max_Fields = {
  __typename?: 'candidate_curation_workflow_screener_type_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Min_Fields = {
  __typename?: 'candidate_curation_workflow_screener_type_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_workflow_screener_type_choices" */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_workflow_screener_type_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Workflow_Screener_Type_Choices>;
};

/** on_conflict condition type for table "candidate_curation_workflow_screener_type_choices" */
export type Candidate_Curation_Workflow_Screener_Type_Choices_On_Conflict = {
  constraint: Candidate_Curation_Workflow_Screener_Type_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Workflow_Screener_Type_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_workflow_screener_type_choices". */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_workflow_screener_type_choices */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_workflow_screener_type_choices" */
export enum Candidate_Curation_Workflow_Screener_Type_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_workflow_screener_type_choices" */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_workflow_screener_type_choices" */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Workflow_Screener_Type_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Workflow_Screener_Type_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_workflow_screener_type_choices" */
export enum Candidate_Curation_Workflow_Screener_Type_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Workflow_Screener_Type_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp;
};

/** select columns of table "candidate_curation_workflow" */
export enum Candidate_Curation_Workflow_Select_Column {
  /** column name */
  AsyncInterviewUrl = 'async_interview_url',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Cid = 'cid',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalCandidateMetaId = 'external_candidate_meta_id',
  /** column name */
  FormSubmissionUrl = 'form_submission_url',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerUserInfoId = 'owner_user_info_id',
  /** column name */
  ReferringUrl = 'referring_url',
  /** column name */
  RejectedReason = 'rejected_reason',
  /** column name */
  ScreenerType = 'screener_type',
  /** column name */
  SecondarySourcerId = 'secondary_sourcer_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceApplicantJobId = 'source_applicant_job_id',
  /** column name */
  SourcerId = 'sourcer_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmContent = 'utm_content',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  UtmTerm = 'utm_term',
}

/** input type for updating data in table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Set_Input = {
  async_interview_url?: InputMaybe<Scalars['String']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  cid?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_candidate_meta_id?: InputMaybe<Scalars['Int']>;
  form_submission_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  owner_user_info_id?: InputMaybe<Scalars['Int']>;
  referring_url?: InputMaybe<Scalars['String']>;
  rejected_reason?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Enum>;
  screener_type?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Enum>;
  secondary_sourcer_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum>;
  source_applicant_job_id?: InputMaybe<Scalars['Int']>;
  sourcer_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
  utm_term?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "candidate_curation_workflow_source_choices" */
export type Candidate_Curation_Workflow_Source_Choices = {
  __typename?: 'candidate_curation_workflow_source_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_workflow_source_choices" */
export type Candidate_Curation_Workflow_Source_Choices_Aggregate = {
  __typename?: 'candidate_curation_workflow_source_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Workflow_Source_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Workflow_Source_Choices>;
};

/** aggregate fields of "candidate_curation_workflow_source_choices" */
export type Candidate_Curation_Workflow_Source_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_workflow_source_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Workflow_Source_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Workflow_Source_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_workflow_source_choices" */
export type Candidate_Curation_Workflow_Source_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_workflow_source_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Workflow_Source_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_workflow_source_choices" */
export enum Candidate_Curation_Workflow_Source_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationWorkflowSourceChoicesPkey = 'candidate_curation_workflow_source_choices_pkey',
}

export enum Candidate_Curation_Workflow_Source_Choices_Enum {
  Gem = 'GEM',
  JobApplication = 'JOB_APPLICATION',
  LandingPage = 'LANDING_PAGE',
  Partnership = 'PARTNERSHIP',
  ProfileFlow = 'PROFILE_FLOW',
}

/** Boolean expression to compare columns of type "candidate_curation_workflow_source_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Workflow_Source_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_workflow_source_choices" */
export type Candidate_Curation_Workflow_Source_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Workflow_Source_Choices_Max_Fields = {
  __typename?: 'candidate_curation_workflow_source_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Workflow_Source_Choices_Min_Fields = {
  __typename?: 'candidate_curation_workflow_source_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_workflow_source_choices" */
export type Candidate_Curation_Workflow_Source_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_workflow_source_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Workflow_Source_Choices>;
};

/** input type for inserting object relation for remote table "candidate_curation_workflow_source_choices" */
export type Candidate_Curation_Workflow_Source_Choices_Obj_Rel_Insert_Input = {
  data: Candidate_Curation_Workflow_Source_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_On_Conflict>;
};

/** on_conflict condition type for table "candidate_curation_workflow_source_choices" */
export type Candidate_Curation_Workflow_Source_Choices_On_Conflict = {
  constraint: Candidate_Curation_Workflow_Source_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Workflow_Source_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_workflow_source_choices". */
export type Candidate_Curation_Workflow_Source_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_workflow_source_choices */
export type Candidate_Curation_Workflow_Source_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_workflow_source_choices" */
export enum Candidate_Curation_Workflow_Source_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_workflow_source_choices" */
export type Candidate_Curation_Workflow_Source_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_workflow_source_choices" */
export type Candidate_Curation_Workflow_Source_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Workflow_Source_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Workflow_Source_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_workflow_source_choices" */
export enum Candidate_Curation_Workflow_Source_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Workflow_Source_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Workflow_Source_Choices_Bool_Exp;
};

/** columns and relationships of "candidate_curation_workflow_status_choices" */
export type Candidate_Curation_Workflow_Status_Choices = {
  __typename?: 'candidate_curation_workflow_status_choices';
  order: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_workflow_status_choices" */
export type Candidate_Curation_Workflow_Status_Choices_Aggregate = {
  __typename?: 'candidate_curation_workflow_status_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Workflow_Status_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Workflow_Status_Choices>;
};

/** aggregate fields of "candidate_curation_workflow_status_choices" */
export type Candidate_Curation_Workflow_Status_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_workflow_status_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Workflow_Status_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Workflow_Status_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_workflow_status_choices" */
export type Candidate_Curation_Workflow_Status_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_workflow_status_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Workflow_Status_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Bool_Exp>>;
  order?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_workflow_status_choices" */
export enum Candidate_Curation_Workflow_Status_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationWorkflowStatusChoicesPkey = 'candidate_curation_workflow_status_choices_pkey',
}

export enum Candidate_Curation_Workflow_Status_Choices_Enum {
  /** 23 */
  Accepted = 'ACCEPTED',
  /** 19 */
  AiAccepted = 'AI_ACCEPTED',
  /** 12 */
  ClosedAlreadyScreened = 'CLOSED_ALREADY_SCREENED',
  /** 11 */
  ClosedCurrentlyScreeningElsewhere = 'CLOSED_CURRENTLY_SCREENING_ELSEWHERE',
  /** 14 */
  NeedsMoreInfo = 'NEEDS_MORE_INFO',
  /** 10 */
  NewNeedsReview = 'NEW_NEEDS_REVIEW',
  /** 24 */
  PhoneScreenCompleted = 'PHONE_SCREEN_COMPLETED',
  /** 13 */
  PhoneScreenNoShow = 'PHONE_SCREEN_NO_SHOW',
  /** 22 */
  PhoneScreenPassed = 'PHONE_SCREEN_PASSED',
  /** 21 */
  PhoneScreenScheduled = 'PHONE_SCREEN_SCHEDULED',
  /** 20 */
  PhoneScreenScheduling = 'PHONE_SCREEN_SCHEDULING',
  /** 16 */
  Rejected = 'REJECTED',
  /** 18 */
  TechnicalAssessmentCompleted = 'TECHNICAL_ASSESSMENT_COMPLETED',
  /** 17 */
  TechnicalAssessmentRequested = 'TECHNICAL_ASSESSMENT_REQUESTED',
  /** 15 */
  UpdatedNeedsReview = 'UPDATED_NEEDS_REVIEW',
}

/** Boolean expression to compare columns of type "candidate_curation_workflow_status_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Workflow_Status_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_workflow_status_choices" */
export type Candidate_Curation_Workflow_Status_Choices_Insert_Input = {
  order?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Workflow_Status_Choices_Max_Fields = {
  __typename?: 'candidate_curation_workflow_status_choices_max_fields';
  order: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Workflow_Status_Choices_Min_Fields = {
  __typename?: 'candidate_curation_workflow_status_choices_min_fields';
  order: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_workflow_status_choices" */
export type Candidate_Curation_Workflow_Status_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_workflow_status_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Workflow_Status_Choices>;
};

/** input type for inserting object relation for remote table "candidate_curation_workflow_status_choices" */
export type Candidate_Curation_Workflow_Status_Choices_Obj_Rel_Insert_Input = {
  data: Candidate_Curation_Workflow_Status_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_On_Conflict>;
};

/** on_conflict condition type for table "candidate_curation_workflow_status_choices" */
export type Candidate_Curation_Workflow_Status_Choices_On_Conflict = {
  constraint: Candidate_Curation_Workflow_Status_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Workflow_Status_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_workflow_status_choices". */
export type Candidate_Curation_Workflow_Status_Choices_Order_By = {
  order?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_workflow_status_choices */
export type Candidate_Curation_Workflow_Status_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_workflow_status_choices" */
export enum Candidate_Curation_Workflow_Status_Choices_Select_Column {
  /** column name */
  Order = 'order',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_workflow_status_choices" */
export type Candidate_Curation_Workflow_Status_Choices_Set_Input = {
  order?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_workflow_status_choices" */
export type Candidate_Curation_Workflow_Status_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Workflow_Status_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Workflow_Status_Choices_Stream_Cursor_Value_Input = {
  order?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_workflow_status_choices" */
export enum Candidate_Curation_Workflow_Status_Choices_Update_Column {
  /** column name */
  Order = 'order',
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Workflow_Status_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Workflow_Status_Choices_Bool_Exp;
};

/** columns and relationships of "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log = {
  __typename?: 'candidate_curation_workflow_status_log';
  candidate_curation_workflow_id: Scalars['Int'];
  /** An object relationship */
  candidate_curation_workflow_status_choice: Candidate_Curation_Workflow_Status_Choices;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  source: Candidate_Curation_Workflow_Source_Choices_Enum;
  status: Candidate_Curation_Workflow_Status_Choices_Enum;
  updated_by: Maybe<Scalars['Int']>;
  /** An object relationship */
  updated_by_user: Maybe<User_Info>;
};

/** aggregated selection of "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Aggregate = {
  __typename?: 'candidate_curation_workflow_status_log_aggregate';
  aggregate: Maybe<Candidate_Curation_Workflow_Status_Log_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Workflow_Status_Log>;
};

export type Candidate_Curation_Workflow_Status_Log_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Curation_Workflow_Status_Log_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Aggregate_Fields = {
  __typename?: 'candidate_curation_workflow_status_log_aggregate_fields';
  avg: Maybe<Candidate_Curation_Workflow_Status_Log_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Workflow_Status_Log_Max_Fields>;
  min: Maybe<Candidate_Curation_Workflow_Status_Log_Min_Fields>;
  stddev: Maybe<Candidate_Curation_Workflow_Status_Log_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Curation_Workflow_Status_Log_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Curation_Workflow_Status_Log_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Curation_Workflow_Status_Log_Sum_Fields>;
  var_pop: Maybe<Candidate_Curation_Workflow_Status_Log_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Curation_Workflow_Status_Log_Var_Samp_Fields>;
  variance: Maybe<Candidate_Curation_Workflow_Status_Log_Variance_Fields>;
};

/** aggregate fields of "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Max_Order_By>;
  min?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Curation_Workflow_Status_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Status_Log_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Curation_Workflow_Status_Log_Avg_Fields = {
  __typename?: 'candidate_curation_workflow_status_log_avg_fields';
  candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Avg_Order_By = {
  candidate_curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_curation_workflow_status_log". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Workflow_Status_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Bool_Exp>>;
  candidate_curation_workflow_id?: InputMaybe<Int_Comparison_Exp>;
  candidate_curation_workflow_status_choice?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum_Comparison_Exp>;
  status?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Enum_Comparison_Exp>;
  updated_by?: InputMaybe<Int_Comparison_Exp>;
  updated_by_user?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_workflow_status_log" */
export enum Candidate_Curation_Workflow_Status_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateCurationWorkflowStatusLogPkey = 'candidate_curation_workflow_status_log_pkey',
}

/** input type for incrementing numeric columns in table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Inc_Input = {
  candidate_curation_workflow_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Insert_Input = {
  candidate_curation_workflow_id?: InputMaybe<Scalars['Int']>;
  candidate_curation_workflow_status_choice?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum>;
  status?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Enum>;
  updated_by?: InputMaybe<Scalars['Int']>;
  updated_by_user?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Candidate_Curation_Workflow_Status_Log_Max_Fields = {
  __typename?: 'candidate_curation_workflow_status_log_max_fields';
  candidate_curation_workflow_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  updated_by: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Max_Order_By = {
  candidate_curation_workflow_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Curation_Workflow_Status_Log_Min_Fields = {
  __typename?: 'candidate_curation_workflow_status_log_min_fields';
  candidate_curation_workflow_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  updated_by: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Min_Order_By = {
  candidate_curation_workflow_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Mutation_Response = {
  __typename?: 'candidate_curation_workflow_status_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Workflow_Status_Log>;
};

/** on_conflict condition type for table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_On_Conflict = {
  constraint: Candidate_Curation_Workflow_Status_Log_Constraint;
  update_columns?: Array<Candidate_Curation_Workflow_Status_Log_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_workflow_status_log". */
export type Candidate_Curation_Workflow_Status_Log_Order_By = {
  candidate_curation_workflow_id?: InputMaybe<Order_By>;
  candidate_curation_workflow_status_choice?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  updated_by_user?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: candidate_curation_workflow_status_log */
export type Candidate_Curation_Workflow_Status_Log_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_curation_workflow_status_log" */
export enum Candidate_Curation_Workflow_Status_Log_Select_Column {
  /** column name */
  CandidateCurationWorkflowId = 'candidate_curation_workflow_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** input type for updating data in table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Set_Input = {
  candidate_curation_workflow_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum>;
  status?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Enum>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Candidate_Curation_Workflow_Status_Log_Stddev_Fields = {
  __typename?: 'candidate_curation_workflow_status_log_stddev_fields';
  candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Stddev_Order_By = {
  candidate_curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Curation_Workflow_Status_Log_Stddev_Pop_Fields = {
  __typename?: 'candidate_curation_workflow_status_log_stddev_pop_fields';
  candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Stddev_Pop_Order_By = {
  candidate_curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Curation_Workflow_Status_Log_Stddev_Samp_Fields = {
  __typename?: 'candidate_curation_workflow_status_log_stddev_samp_fields';
  candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Stddev_Samp_Order_By = {
  candidate_curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Workflow_Status_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Workflow_Status_Log_Stream_Cursor_Value_Input = {
  candidate_curation_workflow_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum>;
  status?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Enum>;
  updated_by?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Curation_Workflow_Status_Log_Sum_Fields = {
  __typename?: 'candidate_curation_workflow_status_log_sum_fields';
  candidate_curation_workflow_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  updated_by: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Sum_Order_By = {
  candidate_curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_curation_workflow_status_log" */
export enum Candidate_Curation_Workflow_Status_Log_Update_Column {
  /** column name */
  CandidateCurationWorkflowId = 'candidate_curation_workflow_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Candidate_Curation_Workflow_Status_Log_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Workflow_Status_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Curation_Workflow_Status_Log_Var_Pop_Fields = {
  __typename?: 'candidate_curation_workflow_status_log_var_pop_fields';
  candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Var_Pop_Order_By = {
  candidate_curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Curation_Workflow_Status_Log_Var_Samp_Fields = {
  __typename?: 'candidate_curation_workflow_status_log_var_samp_fields';
  candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Var_Samp_Order_By = {
  candidate_curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Curation_Workflow_Status_Log_Variance_Fields = {
  __typename?: 'candidate_curation_workflow_status_log_variance_fields';
  candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_curation_workflow_status_log" */
export type Candidate_Curation_Workflow_Status_Log_Variance_Order_By = {
  candidate_curation_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Candidate_Curation_Workflow_Stddev_Fields = {
  __typename?: 'candidate_curation_workflow_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  external_candidate_meta_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  owner_user_info_id: Maybe<Scalars['Float']>;
  secondary_sourcer_id: Maybe<Scalars['Float']>;
  source_applicant_job_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  external_candidate_meta_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_info_id?: InputMaybe<Order_By>;
  secondary_sourcer_id?: InputMaybe<Order_By>;
  source_applicant_job_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Curation_Workflow_Stddev_Pop_Fields = {
  __typename?: 'candidate_curation_workflow_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  external_candidate_meta_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  owner_user_info_id: Maybe<Scalars['Float']>;
  secondary_sourcer_id: Maybe<Scalars['Float']>;
  source_applicant_job_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  external_candidate_meta_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_info_id?: InputMaybe<Order_By>;
  secondary_sourcer_id?: InputMaybe<Order_By>;
  source_applicant_job_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Curation_Workflow_Stddev_Samp_Fields = {
  __typename?: 'candidate_curation_workflow_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  external_candidate_meta_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  owner_user_info_id: Maybe<Scalars['Float']>;
  secondary_sourcer_id: Maybe<Scalars['Float']>;
  source_applicant_job_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  external_candidate_meta_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_info_id?: InputMaybe<Order_By>;
  secondary_sourcer_id?: InputMaybe<Order_By>;
  source_applicant_job_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Workflow_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Workflow_Stream_Cursor_Value_Input = {
  async_interview_url?: InputMaybe<Scalars['String']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  cid?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  external_candidate_meta_id?: InputMaybe<Scalars['Int']>;
  form_submission_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  owner_user_info_id?: InputMaybe<Scalars['Int']>;
  referring_url?: InputMaybe<Scalars['String']>;
  rejected_reason?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Enum>;
  screener_type?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Enum>;
  secondary_sourcer_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum>;
  source_applicant_job_id?: InputMaybe<Scalars['Int']>;
  sourcer_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['Int']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
  utm_term?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Candidate_Curation_Workflow_Sum_Fields = {
  __typename?: 'candidate_curation_workflow_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  external_candidate_meta_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  owner_user_info_id: Maybe<Scalars['Int']>;
  secondary_sourcer_id: Maybe<Scalars['Int']>;
  source_applicant_job_id: Maybe<Scalars['Int']>;
  sourcer_id: Maybe<Scalars['Int']>;
  updated_by: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  external_candidate_meta_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_info_id?: InputMaybe<Order_By>;
  secondary_sourcer_id?: InputMaybe<Order_By>;
  source_applicant_job_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_curation_workflow" */
export enum Candidate_Curation_Workflow_Update_Column {
  /** column name */
  AsyncInterviewUrl = 'async_interview_url',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Cid = 'cid',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalCandidateMetaId = 'external_candidate_meta_id',
  /** column name */
  FormSubmissionUrl = 'form_submission_url',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerUserInfoId = 'owner_user_info_id',
  /** column name */
  ReferringUrl = 'referring_url',
  /** column name */
  RejectedReason = 'rejected_reason',
  /** column name */
  ScreenerType = 'screener_type',
  /** column name */
  SecondarySourcerId = 'secondary_sourcer_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceApplicantJobId = 'source_applicant_job_id',
  /** column name */
  SourcerId = 'sourcer_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmContent = 'utm_content',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  UtmTerm = 'utm_term',
}

export type Candidate_Curation_Workflow_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Curation_Workflow_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Workflow_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Workflow_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Curation_Workflow_Var_Pop_Fields = {
  __typename?: 'candidate_curation_workflow_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  external_candidate_meta_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  owner_user_info_id: Maybe<Scalars['Float']>;
  secondary_sourcer_id: Maybe<Scalars['Float']>;
  source_applicant_job_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  external_candidate_meta_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_info_id?: InputMaybe<Order_By>;
  secondary_sourcer_id?: InputMaybe<Order_By>;
  source_applicant_job_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Curation_Workflow_Var_Samp_Fields = {
  __typename?: 'candidate_curation_workflow_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  external_candidate_meta_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  owner_user_info_id: Maybe<Scalars['Float']>;
  secondary_sourcer_id: Maybe<Scalars['Float']>;
  source_applicant_job_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  external_candidate_meta_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_info_id?: InputMaybe<Order_By>;
  secondary_sourcer_id?: InputMaybe<Order_By>;
  source_applicant_job_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Curation_Workflow_Variance_Fields = {
  __typename?: 'candidate_curation_workflow_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  external_candidate_meta_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  owner_user_info_id: Maybe<Scalars['Float']>;
  secondary_sourcer_id: Maybe<Scalars['Float']>;
  source_applicant_job_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
  updated_by: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_curation_workflow" */
export type Candidate_Curation_Workflow_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  external_candidate_meta_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_user_info_id?: InputMaybe<Order_By>;
  secondary_sourcer_id?: InputMaybe<Order_By>;
  source_applicant_job_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** columns and relationships of "candidate_curation_years_of_exp_range_choices" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices = {
  __typename?: 'candidate_curation_years_of_exp_range_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_years_of_exp_range_choices" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Aggregate = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_aggregate';
  aggregate: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Years_Of_Exp_Range_Choices>;
};

/** aggregate fields of "candidate_curation_years_of_exp_range_choices" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Aggregate_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Max_Fields>;
  min: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_curation_years_of_exp_range_choices" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_years_of_exp_range_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_curation_years_of_exp_range_choices" */
export enum Candidate_Curation_Years_Of_Exp_Range_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationYearsOfExpRangeChoicesPkey = 'candidate_curation_years_of_exp_range_choices_pkey',
}

export enum Candidate_Curation_Years_Of_Exp_Range_Choices_Enum {
  FiveTen = 'FIVE_TEN',
  TenPlus = 'TEN_PLUS',
  TwoFive = 'TWO_FIVE',
  ZeroTwo = 'ZERO_TWO',
}

/** Boolean expression to compare columns of type "candidate_curation_years_of_exp_range_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_curation_years_of_exp_range_choices" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Max_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Min_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_years_of_exp_range_choices" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Mutation_Response = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Years_Of_Exp_Range_Choices>;
};

/** input type for inserting object relation for remote table "candidate_curation_years_of_exp_range_choices" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Obj_Rel_Insert_Input = {
  data: Candidate_Curation_Years_Of_Exp_Range_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_On_Conflict>;
};

/** on_conflict condition type for table "candidate_curation_years_of_exp_range_choices" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_On_Conflict = {
  constraint: Candidate_Curation_Years_Of_Exp_Range_Choices_Constraint;
  update_columns?: Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp>;
};

/** columns and relationships of "candidate_curation_years_of_exp_range_choices_order" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order';
  order: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_curation_years_of_exp_range_choices_order" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Aggregate = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_aggregate';
  aggregate: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Aggregate_Fields>;
  nodes: Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order>;
};

/** aggregate fields of "candidate_curation_years_of_exp_range_choices_order" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Aggregate_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_aggregate_fields';
  avg: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Max_Fields>;
  min: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Min_Fields>;
  stddev: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Sum_Fields>;
  var_pop: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Var_Samp_Fields>;
  variance: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Variance_Fields>;
};

/** aggregate fields of "candidate_curation_years_of_exp_range_choices_order" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Avg_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_avg_fields';
  order: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_curation_years_of_exp_range_choices_order". All fields are combined with a logical 'AND'. */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp>>;
  order?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_years_of_exp_range_choices". */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "candidate_curation_years_of_exp_range_choices_order" */
export enum Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateCurationYearsOfExpRangeChoicesOrderPkey = 'candidate_curation_years_of_exp_range_choices_order_pkey',
}

/** input type for incrementing numeric columns in table "candidate_curation_years_of_exp_range_choices_order" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_curation_years_of_exp_range_choices_order" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Insert_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Max_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_max_fields';
  order: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Min_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_min_fields';
  order: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_curation_years_of_exp_range_choices_order" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Mutation_Response = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order>;
};

/** on_conflict condition type for table "candidate_curation_years_of_exp_range_choices_order" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_On_Conflict = {
  constraint: Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Constraint;
  update_columns?: Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Update_Column>;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_curation_years_of_exp_range_choices_order". */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Order_By = {
  order?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_curation_years_of_exp_range_choices_order */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_years_of_exp_range_choices_order" */
export enum Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Select_Column {
  /** column name */
  Order = 'order',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_years_of_exp_range_choices_order" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Set_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Stddev_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_stddev_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Stddev_Pop_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_stddev_pop_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Stddev_Samp_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_stddev_samp_fields';
  order: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_curation_years_of_exp_range_choices_order" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Stream_Cursor_Value_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Sum_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_sum_fields';
  order: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_curation_years_of_exp_range_choices_order" */
export enum Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Update_Column {
  /** column name */
  Order = 'order',
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Var_Pop_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_var_pop_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Var_Samp_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_var_samp_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Variance_Fields = {
  __typename?: 'candidate_curation_years_of_exp_range_choices_order_variance_fields';
  order: Maybe<Scalars['Float']>;
};

/** primary key columns input for table: candidate_curation_years_of_exp_range_choices */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_curation_years_of_exp_range_choices" */
export enum Candidate_Curation_Years_Of_Exp_Range_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_curation_years_of_exp_range_choices" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_curation_years_of_exp_range_choices" */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Curation_Years_Of_Exp_Range_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Curation_Years_Of_Exp_Range_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_curation_years_of_exp_range_choices" */
export enum Candidate_Curation_Years_Of_Exp_Range_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Curation_Years_Of_Exp_Range_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Candidate_Delete_At_Path_Input = {
  profile_flow_variations?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Candidate_Delete_Elem_Input = {
  profile_flow_variations?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Candidate_Delete_Key_Input = {
  profile_flow_variations?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "candidate_detail_level_choices" */
export type Candidate_Detail_Level_Choices = {
  __typename?: 'candidate_detail_level_choices';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_detail_level_choices" */
export type Candidate_Detail_Level_Choices_Aggregate = {
  __typename?: 'candidate_detail_level_choices_aggregate';
  aggregate: Maybe<Candidate_Detail_Level_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Detail_Level_Choices>;
};

/** aggregate fields of "candidate_detail_level_choices" */
export type Candidate_Detail_Level_Choices_Aggregate_Fields = {
  __typename?: 'candidate_detail_level_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Detail_Level_Choices_Max_Fields>;
  min: Maybe<Candidate_Detail_Level_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_detail_level_choices" */
export type Candidate_Detail_Level_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Detail_Level_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_detail_level_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Detail_Level_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Detail_Level_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Detail_Level_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Detail_Level_Choices_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_detail_level_choices" */
export enum Candidate_Detail_Level_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateDetailLevelChoicesPkey = 'candidate_detail_level_choices_pkey',
}

export enum Candidate_Detail_Level_Choices_Enum {
  /** Candidate provided comprehensive and specific answers. They gave clear examples, results, and insights. */
  Detailed = 'DETAILED',
  /** Candidate actively avoided giving direct answers or details. Attempted to deflect questions or gave unrelated answers. */
  Evasive = 'EVASIVE',
  /** Candidate provided relevant answers but spoke in broad terms, lacking concrete examples, specific details, and insights. */
  General = 'GENERAL',
  /** Candidate was very vague in their answers, lacking clarity and specifics. Excessive use of buzzwords and jargon without clear articulation. */
  Vague = 'VAGUE',
}

/** Boolean expression to compare columns of type "candidate_detail_level_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Detail_Level_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Detail_Level_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Detail_Level_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Detail_Level_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Detail_Level_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_detail_level_choices" */
export type Candidate_Detail_Level_Choices_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Detail_Level_Choices_Max_Fields = {
  __typename?: 'candidate_detail_level_choices_max_fields';
  description: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Detail_Level_Choices_Min_Fields = {
  __typename?: 'candidate_detail_level_choices_min_fields';
  description: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_detail_level_choices" */
export type Candidate_Detail_Level_Choices_Mutation_Response = {
  __typename?: 'candidate_detail_level_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Detail_Level_Choices>;
};

/** on_conflict condition type for table "candidate_detail_level_choices" */
export type Candidate_Detail_Level_Choices_On_Conflict = {
  constraint: Candidate_Detail_Level_Choices_Constraint;
  update_columns?: Array<Candidate_Detail_Level_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Detail_Level_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_detail_level_choices". */
export type Candidate_Detail_Level_Choices_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_detail_level_choices */
export type Candidate_Detail_Level_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_detail_level_choices" */
export enum Candidate_Detail_Level_Choices_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_detail_level_choices" */
export type Candidate_Detail_Level_Choices_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_detail_level_choices" */
export type Candidate_Detail_Level_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Detail_Level_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Detail_Level_Choices_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_detail_level_choices" */
export enum Candidate_Detail_Level_Choices_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Candidate_Detail_Level_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Detail_Level_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Detail_Level_Choices_Bool_Exp;
};

/** columns and relationships of "candidate_education" */
export type Candidate_Education = {
  __typename?: 'candidate_education';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  concentration: Maybe<Scalars['String']>;
  concentration_code: Maybe<Candidate_Education_Concentration_Choices_Enum>;
  created_at: Scalars['timestamptz'];
  degree: Maybe<Candidate_Education_Degree_Choices_Enum>;
  graduation_year: Maybe<Scalars['Int']>;
  /** Bachelor's degree or higher in Computer Science or equivalent field. */
  has_cs_degree: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  school_name: Maybe<Scalars['String']>;
  source: Candidate_Source_Choices_Enum;
  start_year: Maybe<Scalars['Int']>;
  still_studying: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  visible_to_candidate: Scalars['Boolean'];
};

/** aggregated selection of "candidate_education" */
export type Candidate_Education_Aggregate = {
  __typename?: 'candidate_education_aggregate';
  aggregate: Maybe<Candidate_Education_Aggregate_Fields>;
  nodes: Array<Candidate_Education>;
};

export type Candidate_Education_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Candidate_Education_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Candidate_Education_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Candidate_Education_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Education_Aggregate_Bool_Exp_Bool_And = {
  arguments: Candidate_Education_Select_Column_Candidate_Education_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Education_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Education_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Candidate_Education_Select_Column_Candidate_Education_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Education_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Education_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Education_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Education_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_education" */
export type Candidate_Education_Aggregate_Fields = {
  __typename?: 'candidate_education_aggregate_fields';
  avg: Maybe<Candidate_Education_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Education_Max_Fields>;
  min: Maybe<Candidate_Education_Min_Fields>;
  stddev: Maybe<Candidate_Education_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Education_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Education_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Education_Sum_Fields>;
  var_pop: Maybe<Candidate_Education_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Education_Var_Samp_Fields>;
  variance: Maybe<Candidate_Education_Variance_Fields>;
};

/** aggregate fields of "candidate_education" */
export type Candidate_Education_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Education_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_education" */
export type Candidate_Education_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Education_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Education_Max_Order_By>;
  min?: InputMaybe<Candidate_Education_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Education_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Education_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Education_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Education_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Education_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Education_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Education_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_education" */
export type Candidate_Education_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Education_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Education_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Education_Avg_Fields = {
  __typename?: 'candidate_education_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  graduation_year: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  start_year: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_education" */
export type Candidate_Education_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_education". All fields are combined with a logical 'AND'. */
export type Candidate_Education_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Education_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Education_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Education_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  concentration?: InputMaybe<String_Comparison_Exp>;
  concentration_code?: InputMaybe<Candidate_Education_Concentration_Choices_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  degree?: InputMaybe<Candidate_Education_Degree_Choices_Enum_Comparison_Exp>;
  graduation_year?: InputMaybe<Int_Comparison_Exp>;
  has_cs_degree?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  school_name?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<Candidate_Source_Choices_Enum_Comparison_Exp>;
  start_year?: InputMaybe<Int_Comparison_Exp>;
  still_studying?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visible_to_candidate?: InputMaybe<Boolean_Comparison_Exp>;
};

/** Enum table that contains choices for concentration in candidate educations */
export type Candidate_Education_Concentration_Choices = {
  __typename?: 'candidate_education_concentration_choices';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_education_concentration_choices" */
export type Candidate_Education_Concentration_Choices_Aggregate = {
  __typename?: 'candidate_education_concentration_choices_aggregate';
  aggregate: Maybe<Candidate_Education_Concentration_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Education_Concentration_Choices>;
};

/** aggregate fields of "candidate_education_concentration_choices" */
export type Candidate_Education_Concentration_Choices_Aggregate_Fields = {
  __typename?: 'candidate_education_concentration_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Education_Concentration_Choices_Max_Fields>;
  min: Maybe<Candidate_Education_Concentration_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_education_concentration_choices" */
export type Candidate_Education_Concentration_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_education_concentration_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Education_Concentration_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Education_Concentration_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_education_concentration_choices" */
export enum Candidate_Education_Concentration_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateEducationConcentrationChoicesPkey = 'candidate_education_concentration_choices_pkey',
}

export enum Candidate_Education_Concentration_Choices_Enum {
  /** Education related to artificial intelligence */
  ArtificialIntelligence = 'ARTIFICIAL_INTELLIGENCE',
  /** Education related to computational mathematics */
  ComputationalMathematics = 'COMPUTATIONAL_MATHEMATICS',
  /** Education related to computer science */
  ComputerScience = 'COMPUTER_SCIENCE',
  /** Education related to cyber security */
  CyberSecurity = 'CYBER_SECURITY',
  /** Education related to data */
  Data = 'DATA',
  /** Education related to economics */
  Economics = 'ECONOMICS',
  /** Education related to information technology */
  InformationTechnology = 'INFORMATION_TECHNOLOGY',
  /** Education related to machine learning */
  MachineLearning = 'MACHINE_LEARNING',
  /** Education related to mathematical sciences */
  MathematicalSciences = 'MATHEMATICAL_SCIENCES',
  /** Education related to networks and communications */
  NetworksAndCommunications = 'NETWORKS_AND_COMMUNICATIONS',
  /** Education related to robotics */
  Robotics = 'ROBOTICS',
  /** Education related to science */
  Science = 'SCIENCE',
  /** Education related to software engineering */
  SoftwareEngineering = 'SOFTWARE_ENGINEERING',
}

/** Boolean expression to compare columns of type "candidate_education_concentration_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Education_Concentration_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Education_Concentration_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Education_Concentration_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_education_concentration_choices" */
export type Candidate_Education_Concentration_Choices_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Education_Concentration_Choices_Max_Fields = {
  __typename?: 'candidate_education_concentration_choices_max_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Education_Concentration_Choices_Min_Fields = {
  __typename?: 'candidate_education_concentration_choices_min_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_education_concentration_choices" */
export type Candidate_Education_Concentration_Choices_Mutation_Response = {
  __typename?: 'candidate_education_concentration_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Education_Concentration_Choices>;
};

/** on_conflict condition type for table "candidate_education_concentration_choices" */
export type Candidate_Education_Concentration_Choices_On_Conflict = {
  constraint: Candidate_Education_Concentration_Choices_Constraint;
  update_columns?: Array<Candidate_Education_Concentration_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Education_Concentration_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_education_concentration_choices". */
export type Candidate_Education_Concentration_Choices_Order_By = {
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_education_concentration_choices */
export type Candidate_Education_Concentration_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_education_concentration_choices" */
export enum Candidate_Education_Concentration_Choices_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_education_concentration_choices" */
export type Candidate_Education_Concentration_Choices_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_education_concentration_choices" */
export type Candidate_Education_Concentration_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Education_Concentration_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Education_Concentration_Choices_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_education_concentration_choices" */
export enum Candidate_Education_Concentration_Choices_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

export type Candidate_Education_Concentration_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Education_Concentration_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Education_Concentration_Choices_Bool_Exp;
};

/** unique or primary key constraints on table "candidate_education" */
export enum Candidate_Education_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateEducationPkey = 'candidate_education_pkey',
}

/** Enum table for degree selection in candidate education */
export type Candidate_Education_Degree_Choices = {
  __typename?: 'candidate_education_degree_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_education_degree_choices" */
export type Candidate_Education_Degree_Choices_Aggregate = {
  __typename?: 'candidate_education_degree_choices_aggregate';
  aggregate: Maybe<Candidate_Education_Degree_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Education_Degree_Choices>;
};

/** aggregate fields of "candidate_education_degree_choices" */
export type Candidate_Education_Degree_Choices_Aggregate_Fields = {
  __typename?: 'candidate_education_degree_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Education_Degree_Choices_Max_Fields>;
  min: Maybe<Candidate_Education_Degree_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_education_degree_choices" */
export type Candidate_Education_Degree_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Education_Degree_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_education_degree_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Education_Degree_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Education_Degree_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Education_Degree_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Education_Degree_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_education_degree_choices" */
export enum Candidate_Education_Degree_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateEducationDegreeChoicesPkey = 'candidate_education_degree_choices_pkey',
}

export enum Candidate_Education_Degree_Choices_Enum {
  Associate = 'Associate',
  Bachelors = 'Bachelors',
  Bootcamp = 'Bootcamp',
  Doctoral = 'Doctoral',
  Masters = 'Masters',
  NoDegree = 'No_Degree',
  Other = 'Other',
}

/** Boolean expression to compare columns of type "candidate_education_degree_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Education_Degree_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Education_Degree_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Education_Degree_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Education_Degree_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Education_Degree_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_education_degree_choices" */
export type Candidate_Education_Degree_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Education_Degree_Choices_Max_Fields = {
  __typename?: 'candidate_education_degree_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Education_Degree_Choices_Min_Fields = {
  __typename?: 'candidate_education_degree_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_education_degree_choices" */
export type Candidate_Education_Degree_Choices_Mutation_Response = {
  __typename?: 'candidate_education_degree_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Education_Degree_Choices>;
};

/** on_conflict condition type for table "candidate_education_degree_choices" */
export type Candidate_Education_Degree_Choices_On_Conflict = {
  constraint: Candidate_Education_Degree_Choices_Constraint;
  update_columns?: Array<Candidate_Education_Degree_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Education_Degree_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_education_degree_choices". */
export type Candidate_Education_Degree_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_education_degree_choices */
export type Candidate_Education_Degree_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_education_degree_choices" */
export enum Candidate_Education_Degree_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_education_degree_choices" */
export type Candidate_Education_Degree_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_education_degree_choices" */
export type Candidate_Education_Degree_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Education_Degree_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Education_Degree_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_education_degree_choices" */
export enum Candidate_Education_Degree_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Education_Degree_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Education_Degree_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Education_Degree_Choices_Bool_Exp;
};

/** input type for incrementing numeric columns in table "candidate_education" */
export type Candidate_Education_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  graduation_year?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  start_year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_education" */
export type Candidate_Education_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  concentration?: InputMaybe<Scalars['String']>;
  concentration_code?: InputMaybe<Candidate_Education_Concentration_Choices_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  degree?: InputMaybe<Candidate_Education_Degree_Choices_Enum>;
  graduation_year?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  school_name?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  start_year?: InputMaybe<Scalars['Int']>;
  still_studying?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Candidate_Education_Max_Fields = {
  __typename?: 'candidate_education_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  concentration: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  graduation_year: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  school_name: Maybe<Scalars['String']>;
  start_year: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_education" */
export type Candidate_Education_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  concentration?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  school_name?: InputMaybe<Order_By>;
  start_year?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Education_Min_Fields = {
  __typename?: 'candidate_education_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  concentration: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  graduation_year: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  school_name: Maybe<Scalars['String']>;
  start_year: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_education" */
export type Candidate_Education_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  concentration?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  school_name?: InputMaybe<Order_By>;
  start_year?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_education" */
export type Candidate_Education_Mutation_Response = {
  __typename?: 'candidate_education_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Education>;
};

/** on_conflict condition type for table "candidate_education" */
export type Candidate_Education_On_Conflict = {
  constraint: Candidate_Education_Constraint;
  update_columns?: Array<Candidate_Education_Update_Column>;
  where?: InputMaybe<Candidate_Education_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_education". */
export type Candidate_Education_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  concentration?: InputMaybe<Order_By>;
  concentration_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  degree?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  has_cs_degree?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  school_name?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  start_year?: InputMaybe<Order_By>;
  still_studying?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visible_to_candidate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_education */
export type Candidate_Education_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_education" */
export enum Candidate_Education_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Concentration = 'concentration',
  /** column name */
  ConcentrationCode = 'concentration_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Degree = 'degree',
  /** column name */
  GraduationYear = 'graduation_year',
  /** column name */
  Id = 'id',
  /** column name */
  SchoolName = 'school_name',
  /** column name */
  Source = 'source',
  /** column name */
  StartYear = 'start_year',
  /** column name */
  StillStudying = 'still_studying',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** select "candidate_education_aggregate_bool_exp_bool_and_arguments_columns" columns of table "candidate_education" */
export enum Candidate_Education_Select_Column_Candidate_Education_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  StillStudying = 'still_studying',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** select "candidate_education_aggregate_bool_exp_bool_or_arguments_columns" columns of table "candidate_education" */
export enum Candidate_Education_Select_Column_Candidate_Education_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  StillStudying = 'still_studying',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** input type for updating data in table "candidate_education" */
export type Candidate_Education_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  concentration?: InputMaybe<Scalars['String']>;
  concentration_code?: InputMaybe<Candidate_Education_Concentration_Choices_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  degree?: InputMaybe<Candidate_Education_Degree_Choices_Enum>;
  graduation_year?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  school_name?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  start_year?: InputMaybe<Scalars['Int']>;
  still_studying?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Candidate_Education_Stddev_Fields = {
  __typename?: 'candidate_education_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  graduation_year: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  start_year: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_education" */
export type Candidate_Education_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Education_Stddev_Pop_Fields = {
  __typename?: 'candidate_education_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  graduation_year: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  start_year: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_education" */
export type Candidate_Education_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Education_Stddev_Samp_Fields = {
  __typename?: 'candidate_education_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  graduation_year: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  start_year: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_education" */
export type Candidate_Education_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_education" */
export type Candidate_Education_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Education_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Education_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  concentration?: InputMaybe<Scalars['String']>;
  concentration_code?: InputMaybe<Candidate_Education_Concentration_Choices_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  degree?: InputMaybe<Candidate_Education_Degree_Choices_Enum>;
  graduation_year?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  school_name?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  start_year?: InputMaybe<Scalars['Int']>;
  still_studying?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Candidate_Education_Sum_Fields = {
  __typename?: 'candidate_education_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  graduation_year: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  start_year: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_education" */
export type Candidate_Education_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_year?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_education" */
export enum Candidate_Education_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Concentration = 'concentration',
  /** column name */
  ConcentrationCode = 'concentration_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Degree = 'degree',
  /** column name */
  GraduationYear = 'graduation_year',
  /** column name */
  Id = 'id',
  /** column name */
  SchoolName = 'school_name',
  /** column name */
  Source = 'source',
  /** column name */
  StartYear = 'start_year',
  /** column name */
  StillStudying = 'still_studying',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

export type Candidate_Education_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Education_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Education_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Education_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Education_Var_Pop_Fields = {
  __typename?: 'candidate_education_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  graduation_year: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  start_year: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_education" */
export type Candidate_Education_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Education_Var_Samp_Fields = {
  __typename?: 'candidate_education_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  graduation_year: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  start_year: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_education" */
export type Candidate_Education_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Education_Variance_Fields = {
  __typename?: 'candidate_education_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  graduation_year: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  start_year: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_education" */
export type Candidate_Education_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_year?: InputMaybe<Order_By>;
};

/** columns and relationships of "candidate_email" */
export type Candidate_Email = {
  __typename?: 'candidate_email';
  candidate_id: Scalars['Int'];
  email: Scalars['citext'];
  id: Scalars['Int'];
};

/** aggregated selection of "candidate_email" */
export type Candidate_Email_Aggregate = {
  __typename?: 'candidate_email_aggregate';
  aggregate: Maybe<Candidate_Email_Aggregate_Fields>;
  nodes: Array<Candidate_Email>;
};

export type Candidate_Email_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Email_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Email_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Email_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Email_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_email" */
export type Candidate_Email_Aggregate_Fields = {
  __typename?: 'candidate_email_aggregate_fields';
  avg: Maybe<Candidate_Email_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Email_Max_Fields>;
  min: Maybe<Candidate_Email_Min_Fields>;
  stddev: Maybe<Candidate_Email_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Email_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Email_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Email_Sum_Fields>;
  var_pop: Maybe<Candidate_Email_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Email_Var_Samp_Fields>;
  variance: Maybe<Candidate_Email_Variance_Fields>;
};

/** aggregate fields of "candidate_email" */
export type Candidate_Email_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Email_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_email" */
export type Candidate_Email_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Email_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Email_Max_Order_By>;
  min?: InputMaybe<Candidate_Email_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Email_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Email_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Email_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Email_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Email_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Email_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Email_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_email" */
export type Candidate_Email_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Email_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Email_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Email_Avg_Fields = {
  __typename?: 'candidate_email_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_email" */
export type Candidate_Email_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_email". All fields are combined with a logical 'AND'. */
export type Candidate_Email_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Email_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Email_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Email_Bool_Exp>>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_email" */
export enum Candidate_Email_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateEmailPkey = 'candidate_email_pkey',
}

/** input type for incrementing numeric columns in table "candidate_email" */
export type Candidate_Email_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_email" */
export type Candidate_Email_Insert_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Candidate_Email_Max_Fields = {
  __typename?: 'candidate_email_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  email: Maybe<Scalars['citext']>;
  id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_email" */
export type Candidate_Email_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Email_Min_Fields = {
  __typename?: 'candidate_email_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  email: Maybe<Scalars['citext']>;
  id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_email" */
export type Candidate_Email_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_email" */
export type Candidate_Email_Mutation_Response = {
  __typename?: 'candidate_email_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Email>;
};

/** on_conflict condition type for table "candidate_email" */
export type Candidate_Email_On_Conflict = {
  constraint: Candidate_Email_Constraint;
  update_columns?: Array<Candidate_Email_Update_Column>;
  where?: InputMaybe<Candidate_Email_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_email". */
export type Candidate_Email_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_email */
export type Candidate_Email_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_email" */
export enum Candidate_Email_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "candidate_email" */
export type Candidate_Email_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Candidate_Email_Stddev_Fields = {
  __typename?: 'candidate_email_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_email" */
export type Candidate_Email_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Email_Stddev_Pop_Fields = {
  __typename?: 'candidate_email_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_email" */
export type Candidate_Email_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Email_Stddev_Samp_Fields = {
  __typename?: 'candidate_email_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_email" */
export type Candidate_Email_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_email" */
export type Candidate_Email_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Email_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Email_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Email_Sum_Fields = {
  __typename?: 'candidate_email_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_email" */
export type Candidate_Email_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_email" */
export enum Candidate_Email_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

export type Candidate_Email_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Email_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Email_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Email_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Email_Var_Pop_Fields = {
  __typename?: 'candidate_email_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_email" */
export type Candidate_Email_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Email_Var_Samp_Fields = {
  __typename?: 'candidate_email_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_email" */
export type Candidate_Email_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Email_Variance_Fields = {
  __typename?: 'candidate_email_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_email" */
export type Candidate_Email_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Embeddings that encapsulate all the useful data on this candidate. */
export type Candidate_Embedding = {
  __typename?: 'candidate_embedding';
  candidate_id: Scalars['Int'];
  document: Scalars['String'];
  embedding: Scalars['bytea'];
  id: Scalars['Int'];
};

/** aggregated selection of "candidate_embedding" */
export type Candidate_Embedding_Aggregate = {
  __typename?: 'candidate_embedding_aggregate';
  aggregate: Maybe<Candidate_Embedding_Aggregate_Fields>;
  nodes: Array<Candidate_Embedding>;
};

/** aggregate fields of "candidate_embedding" */
export type Candidate_Embedding_Aggregate_Fields = {
  __typename?: 'candidate_embedding_aggregate_fields';
  avg: Maybe<Candidate_Embedding_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Embedding_Max_Fields>;
  min: Maybe<Candidate_Embedding_Min_Fields>;
  stddev: Maybe<Candidate_Embedding_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Embedding_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Embedding_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Embedding_Sum_Fields>;
  var_pop: Maybe<Candidate_Embedding_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Embedding_Var_Samp_Fields>;
  variance: Maybe<Candidate_Embedding_Variance_Fields>;
};

/** aggregate fields of "candidate_embedding" */
export type Candidate_Embedding_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Embedding_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Embedding_Avg_Fields = {
  __typename?: 'candidate_embedding_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_embedding". All fields are combined with a logical 'AND'. */
export type Candidate_Embedding_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Embedding_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Embedding_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Embedding_Bool_Exp>>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  document?: InputMaybe<String_Comparison_Exp>;
  embedding?: InputMaybe<Bytea_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_embedding" */
export enum Candidate_Embedding_Constraint {
  /** unique or primary key constraint on columns "candidate_id" */
  CandidateEmbeddingCandidateIdKey = 'candidate_embedding_candidate_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidateEmbeddingPkey = 'candidate_embedding_pkey',
}

/** input type for incrementing numeric columns in table "candidate_embedding" */
export type Candidate_Embedding_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_embedding" */
export type Candidate_Embedding_Insert_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  document?: InputMaybe<Scalars['String']>;
  embedding?: InputMaybe<Scalars['bytea']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Candidate_Embedding_Max_Fields = {
  __typename?: 'candidate_embedding_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  document: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Candidate_Embedding_Min_Fields = {
  __typename?: 'candidate_embedding_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  document: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "candidate_embedding" */
export type Candidate_Embedding_Mutation_Response = {
  __typename?: 'candidate_embedding_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Embedding>;
};

/** on_conflict condition type for table "candidate_embedding" */
export type Candidate_Embedding_On_Conflict = {
  constraint: Candidate_Embedding_Constraint;
  update_columns?: Array<Candidate_Embedding_Update_Column>;
  where?: InputMaybe<Candidate_Embedding_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_embedding". */
export type Candidate_Embedding_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_embedding */
export type Candidate_Embedding_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_embedding" */
export enum Candidate_Embedding_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Document = 'document',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "candidate_embedding" */
export type Candidate_Embedding_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  document?: InputMaybe<Scalars['String']>;
  embedding?: InputMaybe<Scalars['bytea']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Candidate_Embedding_Stddev_Fields = {
  __typename?: 'candidate_embedding_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Embedding_Stddev_Pop_Fields = {
  __typename?: 'candidate_embedding_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Embedding_Stddev_Samp_Fields = {
  __typename?: 'candidate_embedding_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_embedding" */
export type Candidate_Embedding_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Embedding_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Embedding_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  document?: InputMaybe<Scalars['String']>;
  embedding?: InputMaybe<Scalars['bytea']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Embedding_Sum_Fields = {
  __typename?: 'candidate_embedding_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_embedding" */
export enum Candidate_Embedding_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Document = 'document',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
}

export type Candidate_Embedding_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Embedding_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Embedding_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Embedding_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Embedding_Var_Pop_Fields = {
  __typename?: 'candidate_embedding_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Embedding_Var_Samp_Fields = {
  __typename?: 'candidate_embedding_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Embedding_Variance_Fields = {
  __typename?: 'candidate_embedding_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Enum table that contains choices for candidate highlights */
export type Candidate_Highlight_Choices = {
  __typename?: 'candidate_highlight_choices';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_highlight_choices" */
export type Candidate_Highlight_Choices_Aggregate = {
  __typename?: 'candidate_highlight_choices_aggregate';
  aggregate: Maybe<Candidate_Highlight_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Highlight_Choices>;
};

/** aggregate fields of "candidate_highlight_choices" */
export type Candidate_Highlight_Choices_Aggregate_Fields = {
  __typename?: 'candidate_highlight_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Highlight_Choices_Max_Fields>;
  min: Maybe<Candidate_Highlight_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_highlight_choices" */
export type Candidate_Highlight_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Highlight_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_highlight_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Highlight_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Highlight_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Highlight_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Highlight_Choices_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_highlight_choices" */
export enum Candidate_Highlight_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateHighlightChoicesPkey = 'candidate_highlight_choices_pkey',
}

export enum Candidate_Highlight_Choices_Enum {
  /** 0 -> 1 Experience */
  BuiltNew = 'built_new',
  /** People Leadership Experience */
  PeopleLeader = 'people_leader',
  /** Previous Hire */
  PreviousHire = 'previous_hire',
  /** Referred Candidate */
  Referred = 'referred',
  /** Startup Experience */
  StartupExp = 'startup_exp',
  /** Tech Lead Experience */
  TechLeader = 'tech_leader',
  /** Top Company Experience */
  TopCompanyExp = 'top_company_exp',
}

/** Boolean expression to compare columns of type "candidate_highlight_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Highlight_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Highlight_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Highlight_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Highlight_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Highlight_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_highlight_choices" */
export type Candidate_Highlight_Choices_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Highlight_Choices_Max_Fields = {
  __typename?: 'candidate_highlight_choices_max_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Highlight_Choices_Min_Fields = {
  __typename?: 'candidate_highlight_choices_min_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_highlight_choices" */
export type Candidate_Highlight_Choices_Mutation_Response = {
  __typename?: 'candidate_highlight_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Highlight_Choices>;
};

/** on_conflict condition type for table "candidate_highlight_choices" */
export type Candidate_Highlight_Choices_On_Conflict = {
  constraint: Candidate_Highlight_Choices_Constraint;
  update_columns?: Array<Candidate_Highlight_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Highlight_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_highlight_choices". */
export type Candidate_Highlight_Choices_Order_By = {
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_highlight_choices */
export type Candidate_Highlight_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_highlight_choices" */
export enum Candidate_Highlight_Choices_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_highlight_choices" */
export type Candidate_Highlight_Choices_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_highlight_choices" */
export type Candidate_Highlight_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Highlight_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Highlight_Choices_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_highlight_choices" */
export enum Candidate_Highlight_Choices_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

export type Candidate_Highlight_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Highlight_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Highlight_Choices_Bool_Exp;
};

/** Candidate's current location that links to terminal hub */
export type Candidate_Hub_Location = {
  __typename?: 'candidate_hub_location';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  location: Location;
  location_id: Scalars['Int'];
  source: Candidate_Source_Choices_Enum;
  visible_to_candidate: Scalars['Boolean'];
};

/** aggregated selection of "candidate_hub_location" */
export type Candidate_Hub_Location_Aggregate = {
  __typename?: 'candidate_hub_location_aggregate';
  aggregate: Maybe<Candidate_Hub_Location_Aggregate_Fields>;
  nodes: Array<Candidate_Hub_Location>;
};

export type Candidate_Hub_Location_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Candidate_Hub_Location_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Candidate_Hub_Location_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Candidate_Hub_Location_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Hub_Location_Aggregate_Bool_Exp_Bool_And = {
  arguments: Candidate_Hub_Location_Select_Column_Candidate_Hub_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Hub_Location_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Candidate_Hub_Location_Select_Column_Candidate_Hub_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Hub_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Hub_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_hub_location" */
export type Candidate_Hub_Location_Aggregate_Fields = {
  __typename?: 'candidate_hub_location_aggregate_fields';
  avg: Maybe<Candidate_Hub_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Hub_Location_Max_Fields>;
  min: Maybe<Candidate_Hub_Location_Min_Fields>;
  stddev: Maybe<Candidate_Hub_Location_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Hub_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Hub_Location_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Hub_Location_Sum_Fields>;
  var_pop: Maybe<Candidate_Hub_Location_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Hub_Location_Var_Samp_Fields>;
  variance: Maybe<Candidate_Hub_Location_Variance_Fields>;
};

/** aggregate fields of "candidate_hub_location" */
export type Candidate_Hub_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Hub_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_hub_location" */
export type Candidate_Hub_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Hub_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Hub_Location_Max_Order_By>;
  min?: InputMaybe<Candidate_Hub_Location_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Hub_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Hub_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Hub_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Hub_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Hub_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Hub_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Hub_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_hub_location" */
export type Candidate_Hub_Location_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Hub_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Hub_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Hub_Location_Avg_Fields = {
  __typename?: 'candidate_hub_location_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_hub_location" */
export type Candidate_Hub_Location_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_hub_location". All fields are combined with a logical 'AND'. */
export type Candidate_Hub_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Hub_Location_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Hub_Location_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<Candidate_Source_Choices_Enum_Comparison_Exp>;
  visible_to_candidate?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_hub_location" */
export enum Candidate_Hub_Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateLocationPkey = 'candidate_location_pkey',
}

/** input type for incrementing numeric columns in table "candidate_hub_location" */
export type Candidate_Hub_Location_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_hub_location" */
export type Candidate_Hub_Location_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Candidate_Hub_Location_Max_Fields = {
  __typename?: 'candidate_hub_location_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_hub_location" */
export type Candidate_Hub_Location_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Hub_Location_Min_Fields = {
  __typename?: 'candidate_hub_location_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_hub_location" */
export type Candidate_Hub_Location_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_hub_location" */
export type Candidate_Hub_Location_Mutation_Response = {
  __typename?: 'candidate_hub_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Hub_Location>;
};

/** on_conflict condition type for table "candidate_hub_location" */
export type Candidate_Hub_Location_On_Conflict = {
  constraint: Candidate_Hub_Location_Constraint;
  update_columns?: Array<Candidate_Hub_Location_Update_Column>;
  where?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_hub_location". */
export type Candidate_Hub_Location_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  location_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  visible_to_candidate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_hub_location */
export type Candidate_Hub_Location_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_hub_location" */
export enum Candidate_Hub_Location_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Source = 'source',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** select "candidate_hub_location_aggregate_bool_exp_bool_and_arguments_columns" columns of table "candidate_hub_location" */
export enum Candidate_Hub_Location_Select_Column_Candidate_Hub_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** select "candidate_hub_location_aggregate_bool_exp_bool_or_arguments_columns" columns of table "candidate_hub_location" */
export enum Candidate_Hub_Location_Select_Column_Candidate_Hub_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** input type for updating data in table "candidate_hub_location" */
export type Candidate_Hub_Location_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Candidate_Hub_Location_Stddev_Fields = {
  __typename?: 'candidate_hub_location_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_hub_location" */
export type Candidate_Hub_Location_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Hub_Location_Stddev_Pop_Fields = {
  __typename?: 'candidate_hub_location_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_hub_location" */
export type Candidate_Hub_Location_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Hub_Location_Stddev_Samp_Fields = {
  __typename?: 'candidate_hub_location_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_hub_location" */
export type Candidate_Hub_Location_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_hub_location" */
export type Candidate_Hub_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Hub_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Hub_Location_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Candidate_Hub_Location_Sum_Fields = {
  __typename?: 'candidate_hub_location_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_hub_location" */
export type Candidate_Hub_Location_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_hub_location" */
export enum Candidate_Hub_Location_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Source = 'source',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

export type Candidate_Hub_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Hub_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Hub_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Hub_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Hub_Location_Var_Pop_Fields = {
  __typename?: 'candidate_hub_location_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_hub_location" */
export type Candidate_Hub_Location_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Hub_Location_Var_Samp_Fields = {
  __typename?: 'candidate_hub_location_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_hub_location" */
export type Candidate_Hub_Location_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Hub_Location_Variance_Fields = {
  __typename?: 'candidate_hub_location_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_hub_location" */
export type Candidate_Hub_Location_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "candidate" */
export type Candidate_Inc_Input = {
  country_id?: InputMaybe<Scalars['Int']>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Scalars['Int']>;
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  owner?: InputMaybe<Scalars['Int']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate" */
export type Candidate_Insert_Input = {
  applicant_consents?: InputMaybe<Applicant_Consent_Arr_Rel_Insert_Input>;
  applicant_jobs?: InputMaybe<Applicant_Job_Arr_Rel_Insert_Input>;
  availability?: InputMaybe<Candidate_Availability_Choices_Enum>;
  candidate_ai_profile_summaries?: InputMaybe<Candidate_Ai_Profile_Summary_Arr_Rel_Insert_Input>;
  candidate_availability_choice?: InputMaybe<Candidate_Availability_Choices_Obj_Rel_Insert_Input>;
  candidate_badge?: InputMaybe<Candidate_Badge_Obj_Rel_Insert_Input>;
  candidate_curation_ai_summaries?: InputMaybe<Candidate_Curation_Ai_Summary_Arr_Rel_Insert_Input>;
  candidate_curation_detail?: InputMaybe<Candidate_Curation_Detail_Obj_Rel_Insert_Input>;
  candidate_curation_workflows?: InputMaybe<Candidate_Curation_Workflow_Arr_Rel_Insert_Input>;
  candidate_educations?: InputMaybe<Candidate_Education_Arr_Rel_Insert_Input>;
  candidate_emails?: InputMaybe<Candidate_Email_Arr_Rel_Insert_Input>;
  candidate_hireflix_transcripts?: InputMaybe<Hireflix_Transcript_Arr_Rel_Insert_Input>;
  candidate_hub_locations?: InputMaybe<Candidate_Hub_Location_Arr_Rel_Insert_Input>;
  candidate_job_workflows?: InputMaybe<Candidate_Job_Workflow_Arr_Rel_Insert_Input>;
  candidate_jobs?: InputMaybe<Candidate_Job_Arr_Rel_Insert_Input>;
  candidate_pdl?: InputMaybe<Candidate_Pdl_Obj_Rel_Insert_Input>;
  candidate_pillar_transcripts?: InputMaybe<Pillar_Transcript_Arr_Rel_Insert_Input>;
  candidate_roles?: InputMaybe<Candidate_Role_Arr_Rel_Insert_Input>;
  candidate_skills?: InputMaybe<Candidate_Skill_Arr_Rel_Insert_Input>;
  candidate_stage_logs?: InputMaybe<Candidate_Stage_Log_Arr_Rel_Insert_Input>;
  candidate_work_experiences?: InputMaybe<Candidate_Work_Experience_Arr_Rel_Insert_Input>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_choice?: InputMaybe<Country_Choices_Obj_Rel_Insert_Input>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  curation_countries?: InputMaybe<Candidate_Approved_Country_Arr_Rel_Insert_Input>;
  curation_notes?: InputMaybe<Candidate_Curation_Note_Arr_Rel_Insert_Input>;
  dayforce_employee?: InputMaybe<Dayforce_Employee_Obj_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['citext']>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  first_name?: InputMaybe<Scalars['String']>;
  furthest_candidate_curation_workflow?: InputMaybe<Candidate_Curation_Workflow_Obj_Rel_Insert_Input>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Scalars['Int']>;
  furthest_stage?: InputMaybe<Candidate_Stage_Choices_Enum>;
  gem_id?: InputMaybe<Scalars['String']>;
  github_url?: InputMaybe<Scalars['String']>;
  hr_test_results?: InputMaybe<Hr_Test_Result_Arr_Rel_Insert_Input>;
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
  icims_profile_person?: InputMaybe<Icims_Profile_Person_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  interview_requests?: InputMaybe<Interview_Request_Arr_Rel_Insert_Input>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  merge_candidate_id?: InputMaybe<Scalars['String']>;
  open_to_all_companies?: InputMaybe<Scalars['Boolean']>;
  other_url?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['Int']>;
  owner_person?: InputMaybe<Icims_Profile_Person_Obj_Rel_Insert_Input>;
  parsed_resume?: InputMaybe<Candidate_Parsed_Resume_Obj_Rel_Insert_Input>;
  phone?: InputMaybe<Scalars['String']>;
  profile_complete_override?: InputMaybe<Scalars['Boolean']>;
  profile_flow_completed_at?: InputMaybe<Scalars['timestamptz']>;
  profile_flow_furthest_step?: InputMaybe<Scalars['String']>;
  profile_flow_started_at?: InputMaybe<Scalars['timestamptz']>;
  profile_flow_variations?: InputMaybe<Scalars['jsonb']>;
  public_id?: InputMaybe<Scalars['uuid']>;
  referred_by?: InputMaybe<Candidate_Referrals_Arr_Rel_Insert_Input>;
  resume_filename?: InputMaybe<Scalars['String']>;
  saved_candidates?: InputMaybe<Saved_Candidate_Arr_Rel_Insert_Input>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  stage?: InputMaybe<Candidate_Stage_Choices_Enum>;
  stage_source?: InputMaybe<Candidate_Stage_Source_Choices_Enum>;
  state?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  transcript_evaluations?: InputMaybe<Transcript_Evaluation_Arr_Rel_Insert_Input>;
  transcript_skills?: InputMaybe<Transcript_Skill_Arr_Rel_Insert_Input>;
  transcript_work_experiences?: InputMaybe<Transcript_Work_Experience_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** Used for recording customers interested in interviewing candidates  */
export type Candidate_Interview_Invite = {
  __typename?: 'candidate_interview_invite';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  created_at: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  icims_profile_job: Maybe<Icims_Profile_Job>;
  id: Scalars['Int'];
  interested_user_info_id: Scalars['Int'];
  job_profile_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['Int'];
  updated_at: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user_info: User_Info;
};

/** aggregated selection of "candidate_interview_invite" */
export type Candidate_Interview_Invite_Aggregate = {
  __typename?: 'candidate_interview_invite_aggregate';
  aggregate: Maybe<Candidate_Interview_Invite_Aggregate_Fields>;
  nodes: Array<Candidate_Interview_Invite>;
};

/** aggregate fields of "candidate_interview_invite" */
export type Candidate_Interview_Invite_Aggregate_Fields = {
  __typename?: 'candidate_interview_invite_aggregate_fields';
  avg: Maybe<Candidate_Interview_Invite_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Interview_Invite_Max_Fields>;
  min: Maybe<Candidate_Interview_Invite_Min_Fields>;
  stddev: Maybe<Candidate_Interview_Invite_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Interview_Invite_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Interview_Invite_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Interview_Invite_Sum_Fields>;
  var_pop: Maybe<Candidate_Interview_Invite_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Interview_Invite_Var_Samp_Fields>;
  variance: Maybe<Candidate_Interview_Invite_Variance_Fields>;
};

/** aggregate fields of "candidate_interview_invite" */
export type Candidate_Interview_Invite_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Interview_Invite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Interview_Invite_Avg_Fields = {
  __typename?: 'candidate_interview_invite_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interested_user_info_id: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_interview_invite". All fields are combined with a logical 'AND'. */
export type Candidate_Interview_Invite_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Interview_Invite_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Interview_Invite_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Interview_Invite_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interested_user_info_id?: InputMaybe<Int_Comparison_Exp>;
  job_profile_id?: InputMaybe<Int_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "candidate_interview_invite" */
export enum Candidate_Interview_Invite_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateInterviewInvitePkey = 'candidate_interview_invite_pkey',
}

/** input type for incrementing numeric columns in table "candidate_interview_invite" */
export type Candidate_Interview_Invite_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interested_user_info_id?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_interview_invite" */
export type Candidate_Interview_Invite_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  interested_user_info_id?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Candidate_Interview_Invite_Max_Fields = {
  __typename?: 'candidate_interview_invite_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  interested_user_info_id: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  organization_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Candidate_Interview_Invite_Min_Fields = {
  __typename?: 'candidate_interview_invite_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  interested_user_info_id: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  organization_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "candidate_interview_invite" */
export type Candidate_Interview_Invite_Mutation_Response = {
  __typename?: 'candidate_interview_invite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Interview_Invite>;
};

/** on_conflict condition type for table "candidate_interview_invite" */
export type Candidate_Interview_Invite_On_Conflict = {
  constraint: Candidate_Interview_Invite_Constraint;
  update_columns?: Array<Candidate_Interview_Invite_Update_Column>;
  where?: InputMaybe<Candidate_Interview_Invite_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_interview_invite". */
export type Candidate_Interview_Invite_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Order_By>;
  id?: InputMaybe<Order_By>;
  interested_user_info_id?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: candidate_interview_invite */
export type Candidate_Interview_Invite_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_interview_invite" */
export enum Candidate_Interview_Invite_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterestedUserInfoId = 'interested_user_info_id',
  /** column name */
  JobProfileId = 'job_profile_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "candidate_interview_invite" */
export type Candidate_Interview_Invite_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interested_user_info_id?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Candidate_Interview_Invite_Stddev_Fields = {
  __typename?: 'candidate_interview_invite_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interested_user_info_id: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Interview_Invite_Stddev_Pop_Fields = {
  __typename?: 'candidate_interview_invite_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interested_user_info_id: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Interview_Invite_Stddev_Samp_Fields = {
  __typename?: 'candidate_interview_invite_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interested_user_info_id: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_interview_invite" */
export type Candidate_Interview_Invite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Interview_Invite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Interview_Invite_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interested_user_info_id?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Candidate_Interview_Invite_Sum_Fields = {
  __typename?: 'candidate_interview_invite_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  interested_user_info_id: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  organization_id: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_interview_invite" */
export enum Candidate_Interview_Invite_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterestedUserInfoId = 'interested_user_info_id',
  /** column name */
  JobProfileId = 'job_profile_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Candidate_Interview_Invite_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Interview_Invite_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Interview_Invite_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Interview_Invite_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Interview_Invite_Var_Pop_Fields = {
  __typename?: 'candidate_interview_invite_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interested_user_info_id: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Interview_Invite_Var_Samp_Fields = {
  __typename?: 'candidate_interview_invite_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interested_user_info_id: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Interview_Invite_Variance_Fields = {
  __typename?: 'candidate_interview_invite_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interested_user_info_id: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** Similar to applicant_job but with difference that other users are able to add candidate to job */
export type Candidate_Job = {
  __typename?: 'candidate_job';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  created_at: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  icims_profile_job: Icims_Profile_Job;
  job: Scalars['Int'];
  submitted_by: Maybe<Scalars['Int']>;
  sync_status: Icims_Sync_Status_Choices_Enum;
  /** An object relationship */
  user_info: Maybe<User_Info>;
};

/** aggregated selection of "candidate_job" */
export type Candidate_Job_Aggregate = {
  __typename?: 'candidate_job_aggregate';
  aggregate: Maybe<Candidate_Job_Aggregate_Fields>;
  nodes: Array<Candidate_Job>;
};

export type Candidate_Job_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Job_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Job_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_job" */
export type Candidate_Job_Aggregate_Fields = {
  __typename?: 'candidate_job_aggregate_fields';
  avg: Maybe<Candidate_Job_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Job_Max_Fields>;
  min: Maybe<Candidate_Job_Min_Fields>;
  stddev: Maybe<Candidate_Job_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Job_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Job_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Job_Sum_Fields>;
  var_pop: Maybe<Candidate_Job_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Job_Var_Samp_Fields>;
  variance: Maybe<Candidate_Job_Variance_Fields>;
};

/** aggregate fields of "candidate_job" */
export type Candidate_Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_job" */
export type Candidate_Job_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Job_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Job_Max_Order_By>;
  min?: InputMaybe<Candidate_Job_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Job_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Job_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Job_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Job_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Job_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Job_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Job_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_job" */
export type Candidate_Job_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Job_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Job_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Job_Avg_Fields = {
  __typename?: 'candidate_job_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
  submitted_by: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_job" */
export type Candidate_Job_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_job". All fields are combined with a logical 'AND'. */
export type Candidate_Job_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Job_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Job_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Job_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
  job?: InputMaybe<Int_Comparison_Exp>;
  submitted_by?: InputMaybe<Int_Comparison_Exp>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "candidate_job" */
export enum Candidate_Job_Constraint {
  /** unique or primary key constraint on columns "job", "candidate_id" */
  CandidateJobCandidateIdJobKey = 'candidate_job_candidate_id_job_key',
  /** unique or primary key constraint on columns "job", "candidate_id" */
  CandidateJobPkey = 'candidate_job_pkey',
}

/** input type for incrementing numeric columns in table "candidate_job" */
export type Candidate_Job_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  job?: InputMaybe<Scalars['Int']>;
  submitted_by?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_job" */
export type Candidate_Job_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Obj_Rel_Insert_Input>;
  job?: InputMaybe<Scalars['Int']>;
  submitted_by?: InputMaybe<Scalars['Int']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Candidate_Job_Max_Fields = {
  __typename?: 'candidate_job_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  job: Maybe<Scalars['Int']>;
  submitted_by: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_job" */
export type Candidate_Job_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Job_Min_Fields = {
  __typename?: 'candidate_job_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  job: Maybe<Scalars['Int']>;
  submitted_by: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_job" */
export type Candidate_Job_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_job" */
export type Candidate_Job_Mutation_Response = {
  __typename?: 'candidate_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Job>;
};

/** on_conflict condition type for table "candidate_job" */
export type Candidate_Job_On_Conflict = {
  constraint: Candidate_Job_Constraint;
  update_columns?: Array<Candidate_Job_Update_Column>;
  where?: InputMaybe<Candidate_Job_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_job". */
export type Candidate_Job_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Order_By>;
  job?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: candidate_job */
export type Candidate_Job_Pk_Columns_Input = {
  candidate_id: Scalars['Int'];
  job: Scalars['Int'];
};

/** select columns of table "candidate_job" */
export enum Candidate_Job_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Job = 'job',
  /** column name */
  SubmittedBy = 'submitted_by',
  /** column name */
  SyncStatus = 'sync_status',
}

/** input type for updating data in table "candidate_job" */
export type Candidate_Job_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  job?: InputMaybe<Scalars['Int']>;
  submitted_by?: InputMaybe<Scalars['Int']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
};

/** aggregate stddev on columns */
export type Candidate_Job_Stddev_Fields = {
  __typename?: 'candidate_job_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
  submitted_by: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_job" */
export type Candidate_Job_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Job_Stddev_Pop_Fields = {
  __typename?: 'candidate_job_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
  submitted_by: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_job" */
export type Candidate_Job_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Job_Stddev_Samp_Fields = {
  __typename?: 'candidate_job_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
  submitted_by: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_job" */
export type Candidate_Job_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_job" */
export type Candidate_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Job_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  job?: InputMaybe<Scalars['Int']>;
  submitted_by?: InputMaybe<Scalars['Int']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
};

/** aggregate sum on columns */
export type Candidate_Job_Sum_Fields = {
  __typename?: 'candidate_job_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  job: Maybe<Scalars['Int']>;
  submitted_by: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_job" */
export type Candidate_Job_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_job" */
export enum Candidate_Job_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Job = 'job',
  /** column name */
  SubmittedBy = 'submitted_by',
  /** column name */
  SyncStatus = 'sync_status',
}

export type Candidate_Job_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Job_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Job_Var_Pop_Fields = {
  __typename?: 'candidate_job_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
  submitted_by: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_job" */
export type Candidate_Job_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Job_Var_Samp_Fields = {
  __typename?: 'candidate_job_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
  submitted_by: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_job" */
export type Candidate_Job_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Job_Variance_Fields = {
  __typename?: 'candidate_job_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  job: Maybe<Scalars['Float']>;
  submitted_by: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_job" */
export type Candidate_Job_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  submitted_by?: InputMaybe<Order_By>;
};

/** Workflow table to record the journey of candidate through a job flow. */
export type Candidate_Job_Workflow = {
  __typename?: 'candidate_job_workflow';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  /** An array relationship */
  candidate_job_workflow_statuses: Array<Candidate_Job_Workflow_Status>;
  /** An aggregate relationship */
  candidate_job_workflow_statuses_aggregate: Candidate_Job_Workflow_Status_Aggregate;
  created_at: Scalars['timestamptz'];
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  created_by_user: Maybe<User_Info>;
  id: Scalars['Int'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['Int'];
  /** Record who initiated this match/application of this job for a candidate. */
  source: Candidate_Job_Workflow_Source_Choices_Enum;
  status: Candidate_Job_Workflow_Status_Choices_Enum;
  sync_status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Workflow table to record the journey of candidate through a job flow. */
export type Candidate_Job_WorkflowCandidate_Job_Workflow_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Status_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Bool_Exp>;
};

/** Workflow table to record the journey of candidate through a job flow. */
export type Candidate_Job_WorkflowCandidate_Job_Workflow_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Status_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Bool_Exp>;
};

/** aggregated selection of "candidate_job_workflow" */
export type Candidate_Job_Workflow_Aggregate = {
  __typename?: 'candidate_job_workflow_aggregate';
  aggregate: Maybe<Candidate_Job_Workflow_Aggregate_Fields>;
  nodes: Array<Candidate_Job_Workflow>;
};

export type Candidate_Job_Workflow_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Job_Workflow_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Job_Workflow_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Job_Workflow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_job_workflow" */
export type Candidate_Job_Workflow_Aggregate_Fields = {
  __typename?: 'candidate_job_workflow_aggregate_fields';
  avg: Maybe<Candidate_Job_Workflow_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Job_Workflow_Max_Fields>;
  min: Maybe<Candidate_Job_Workflow_Min_Fields>;
  stddev: Maybe<Candidate_Job_Workflow_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Job_Workflow_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Job_Workflow_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Job_Workflow_Sum_Fields>;
  var_pop: Maybe<Candidate_Job_Workflow_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Job_Workflow_Var_Samp_Fields>;
  variance: Maybe<Candidate_Job_Workflow_Variance_Fields>;
};

/** aggregate fields of "candidate_job_workflow" */
export type Candidate_Job_Workflow_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Job_Workflow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Job_Workflow_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Job_Workflow_Max_Order_By>;
  min?: InputMaybe<Candidate_Job_Workflow_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Job_Workflow_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Job_Workflow_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Job_Workflow_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Job_Workflow_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Job_Workflow_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Job_Workflow_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Job_Workflow_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Job_Workflow_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Job_Workflow_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Job_Workflow_Avg_Fields = {
  __typename?: 'candidate_job_workflow_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_job_workflow". All fields are combined with a logical 'AND'. */
export type Candidate_Job_Workflow_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Job_Workflow_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Job_Workflow_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  candidate_job_workflow_statuses?: InputMaybe<Candidate_Job_Workflow_Status_Bool_Exp>;
  candidate_job_workflow_statuses_aggregate?: InputMaybe<Candidate_Job_Workflow_Status_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<Int_Comparison_Exp>;
  created_by_user?: InputMaybe<User_Info_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Enum_Comparison_Exp>;
  status?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Enum_Comparison_Exp>;
  sync_status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_job_workflow" */
export enum Candidate_Job_Workflow_Constraint {
  /** unique or primary key constraint on columns "candidate_id", "job_id" */
  CandidateJobWorkflowCandidateIdJobIdKey = 'candidate_job_workflow_candidate_id_job_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidateJobWorkflowPkey = 'candidate_job_workflow_pkey',
}

/** input type for incrementing numeric columns in table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_job_workflow_statuses?: InputMaybe<Candidate_Job_Workflow_Status_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Scalars['Int']>;
  created_by_user?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['Int']>;
  /** Record who initiated this match/application of this job for a candidate. */
  source?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Enum>;
  status?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Enum>;
  sync_status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Job_Workflow_Max_Fields = {
  __typename?: 'candidate_job_workflow_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  sync_status: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Job_Workflow_Min_Fields = {
  __typename?: 'candidate_job_workflow_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  sync_status: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Mutation_Response = {
  __typename?: 'candidate_job_workflow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Job_Workflow>;
};

/** input type for inserting object relation for remote table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Obj_Rel_Insert_Input = {
  data: Candidate_Job_Workflow_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Job_Workflow_On_Conflict>;
};

/** on_conflict condition type for table "candidate_job_workflow" */
export type Candidate_Job_Workflow_On_Conflict = {
  constraint: Candidate_Job_Workflow_Constraint;
  update_columns?: Array<Candidate_Job_Workflow_Update_Column>;
  where?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_job_workflow". */
export type Candidate_Job_Workflow_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_job_workflow_statuses_aggregate?: InputMaybe<Candidate_Job_Workflow_Status_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  created_by_user?: InputMaybe<User_Info_Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_job_workflow */
export type Candidate_Job_Workflow_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_job_workflow" */
export enum Candidate_Job_Workflow_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  /** Record who initiated this match/application of this job for a candidate. */
  source?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Enum>;
  status?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Enum>;
  sync_status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "candidate_job_workflow_source_choices" */
export type Candidate_Job_Workflow_Source_Choices = {
  __typename?: 'candidate_job_workflow_source_choices';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_job_workflow_source_choices" */
export type Candidate_Job_Workflow_Source_Choices_Aggregate = {
  __typename?: 'candidate_job_workflow_source_choices_aggregate';
  aggregate: Maybe<Candidate_Job_Workflow_Source_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Job_Workflow_Source_Choices>;
};

/** aggregate fields of "candidate_job_workflow_source_choices" */
export type Candidate_Job_Workflow_Source_Choices_Aggregate_Fields = {
  __typename?: 'candidate_job_workflow_source_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Job_Workflow_Source_Choices_Max_Fields>;
  min: Maybe<Candidate_Job_Workflow_Source_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_job_workflow_source_choices" */
export type Candidate_Job_Workflow_Source_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_job_workflow_source_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Job_Workflow_Source_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_job_workflow_source_choices" */
export enum Candidate_Job_Workflow_Source_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateJobWorkflowSourceChoicesPkey = 'candidate_job_workflow_source_choices_pkey',
}

export enum Candidate_Job_Workflow_Source_Choices_Enum {
  /** Candidate applies for a new job. */
  Candidate = 'CANDIDATE',
  /** Curator matches candidate to a new job. */
  Curator = 'CURATOR',
  /** Sourcer matches candidate to a new job. */
  Sourcer = 'SOURCER',
  /** Talent Partner matches candidate to a new job. */
  TalentPartner = 'TALENT_PARTNER',
}

/** Boolean expression to compare columns of type "candidate_job_workflow_source_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Job_Workflow_Source_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_job_workflow_source_choices" */
export type Candidate_Job_Workflow_Source_Choices_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Job_Workflow_Source_Choices_Max_Fields = {
  __typename?: 'candidate_job_workflow_source_choices_max_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Job_Workflow_Source_Choices_Min_Fields = {
  __typename?: 'candidate_job_workflow_source_choices_min_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_job_workflow_source_choices" */
export type Candidate_Job_Workflow_Source_Choices_Mutation_Response = {
  __typename?: 'candidate_job_workflow_source_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Job_Workflow_Source_Choices>;
};

/** on_conflict condition type for table "candidate_job_workflow_source_choices" */
export type Candidate_Job_Workflow_Source_Choices_On_Conflict = {
  constraint: Candidate_Job_Workflow_Source_Choices_Constraint;
  update_columns?: Array<Candidate_Job_Workflow_Source_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_job_workflow_source_choices". */
export type Candidate_Job_Workflow_Source_Choices_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_job_workflow_source_choices */
export type Candidate_Job_Workflow_Source_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_job_workflow_source_choices" */
export enum Candidate_Job_Workflow_Source_Choices_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_job_workflow_source_choices" */
export type Candidate_Job_Workflow_Source_Choices_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_job_workflow_source_choices" */
export type Candidate_Job_Workflow_Source_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Job_Workflow_Source_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Job_Workflow_Source_Choices_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_job_workflow_source_choices" */
export enum Candidate_Job_Workflow_Source_Choices_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Candidate_Job_Workflow_Source_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Job_Workflow_Source_Choices_Bool_Exp;
};

/** Keeps track of all status updates on candidate_job_workflow */
export type Candidate_Job_Workflow_Status = {
  __typename?: 'candidate_job_workflow_status';
  /** An object relationship */
  candidate_job_workflow: Candidate_Job_Workflow;
  candidate_job_workflow_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  created_by_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  created_by_user: Maybe<User_Info>;
  id: Scalars['Int'];
  status: Candidate_Job_Workflow_Status_Choices_Enum;
};

/** aggregated selection of "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Aggregate = {
  __typename?: 'candidate_job_workflow_status_aggregate';
  aggregate: Maybe<Candidate_Job_Workflow_Status_Aggregate_Fields>;
  nodes: Array<Candidate_Job_Workflow_Status>;
};

export type Candidate_Job_Workflow_Status_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Job_Workflow_Status_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Job_Workflow_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Job_Workflow_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Job_Workflow_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Aggregate_Fields = {
  __typename?: 'candidate_job_workflow_status_aggregate_fields';
  avg: Maybe<Candidate_Job_Workflow_Status_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Job_Workflow_Status_Max_Fields>;
  min: Maybe<Candidate_Job_Workflow_Status_Min_Fields>;
  stddev: Maybe<Candidate_Job_Workflow_Status_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Job_Workflow_Status_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Job_Workflow_Status_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Job_Workflow_Status_Sum_Fields>;
  var_pop: Maybe<Candidate_Job_Workflow_Status_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Job_Workflow_Status_Var_Samp_Fields>;
  variance: Maybe<Candidate_Job_Workflow_Status_Variance_Fields>;
};

/** aggregate fields of "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Job_Workflow_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Job_Workflow_Status_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Job_Workflow_Status_Max_Order_By>;
  min?: InputMaybe<Candidate_Job_Workflow_Status_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Job_Workflow_Status_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Job_Workflow_Status_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Job_Workflow_Status_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Job_Workflow_Status_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Job_Workflow_Status_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Job_Workflow_Status_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Job_Workflow_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Job_Workflow_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Job_Workflow_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Job_Workflow_Status_Avg_Fields = {
  __typename?: 'candidate_job_workflow_status_avg_fields';
  candidate_job_workflow_id: Maybe<Scalars['Float']>;
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Avg_Order_By = {
  candidate_job_workflow_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_job_workflow_status". All fields are combined with a logical 'AND'. */
export type Candidate_Job_Workflow_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Job_Workflow_Status_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Job_Workflow_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Job_Workflow_Status_Bool_Exp>>;
  candidate_job_workflow?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
  candidate_job_workflow_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<Int_Comparison_Exp>;
  created_by_user?: InputMaybe<User_Info_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Enum_Comparison_Exp>;
};

/** columns and relationships of "candidate_job_workflow_status_choices" */
export type Candidate_Job_Workflow_Status_Choices = {
  __typename?: 'candidate_job_workflow_status_choices';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_job_workflow_status_choices" */
export type Candidate_Job_Workflow_Status_Choices_Aggregate = {
  __typename?: 'candidate_job_workflow_status_choices_aggregate';
  aggregate: Maybe<Candidate_Job_Workflow_Status_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Job_Workflow_Status_Choices>;
};

/** aggregate fields of "candidate_job_workflow_status_choices" */
export type Candidate_Job_Workflow_Status_Choices_Aggregate_Fields = {
  __typename?: 'candidate_job_workflow_status_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Job_Workflow_Status_Choices_Max_Fields>;
  min: Maybe<Candidate_Job_Workflow_Status_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_job_workflow_status_choices" */
export type Candidate_Job_Workflow_Status_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_job_workflow_status_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Job_Workflow_Status_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_job_workflow_status_choices" */
export enum Candidate_Job_Workflow_Status_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateJobWorkflowStatusChoicesPkey = 'candidate_job_workflow_status_choices_pkey',
}

export enum Candidate_Job_Workflow_Status_Choices_Enum {
  /** Candidate applies for a new job. */
  CandidateApplied = 'CANDIDATE_APPLIED',
  /** Candidate approves a match made by Talent Partner or in TP_MATCH_PENDING state. */
  CandidateApproved = 'CANDIDATE_APPROVED',
  /** Candidate rejects a match made by Talent Partner or in TP_MATCH_PENDING state. */
  CandidateRejected = 'CANDIDATE_REJECTED',
  /** Approved by curator for existing application, or new match made by curator */
  CuratorApproved = 'CURATOR_APPROVED',
  /** Rejected by curator for existing application. */
  CuratorRejected = 'CURATOR_REJECTED',
  /** Approved by sourcer for existing application, or new match made by sourcer */
  SourcerApproved = 'SOURCER_APPROVED',
  /** Talent Partner approves a match for candidate based on verbal confirmation. */
  TpMatchApproved = 'TP_MATCH_APPROVED',
  /** New match made by Talent Partner or existing match made by curator approved by Talent Partner. */
  TpMatchPending = 'TP_MATCH_PENDING',
  /** Talent Partner rejects a match that was in pending or that was suggested by curator. */
  TpMatchRejected = 'TP_MATCH_REJECTED',
}

/** Boolean expression to compare columns of type "candidate_job_workflow_status_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Job_Workflow_Status_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_job_workflow_status_choices" */
export type Candidate_Job_Workflow_Status_Choices_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Job_Workflow_Status_Choices_Max_Fields = {
  __typename?: 'candidate_job_workflow_status_choices_max_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Job_Workflow_Status_Choices_Min_Fields = {
  __typename?: 'candidate_job_workflow_status_choices_min_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_job_workflow_status_choices" */
export type Candidate_Job_Workflow_Status_Choices_Mutation_Response = {
  __typename?: 'candidate_job_workflow_status_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Job_Workflow_Status_Choices>;
};

/** on_conflict condition type for table "candidate_job_workflow_status_choices" */
export type Candidate_Job_Workflow_Status_Choices_On_Conflict = {
  constraint: Candidate_Job_Workflow_Status_Choices_Constraint;
  update_columns?: Array<Candidate_Job_Workflow_Status_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_job_workflow_status_choices". */
export type Candidate_Job_Workflow_Status_Choices_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_job_workflow_status_choices */
export type Candidate_Job_Workflow_Status_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_job_workflow_status_choices" */
export enum Candidate_Job_Workflow_Status_Choices_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_job_workflow_status_choices" */
export type Candidate_Job_Workflow_Status_Choices_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_job_workflow_status_choices" */
export type Candidate_Job_Workflow_Status_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Job_Workflow_Status_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Job_Workflow_Status_Choices_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_job_workflow_status_choices" */
export enum Candidate_Job_Workflow_Status_Choices_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Candidate_Job_Workflow_Status_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Job_Workflow_Status_Choices_Bool_Exp;
};

/** unique or primary key constraints on table "candidate_job_workflow_status" */
export enum Candidate_Job_Workflow_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateJobWorkflowStatusPkey = 'candidate_job_workflow_status_pkey',
}

/** input type for incrementing numeric columns in table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Inc_Input = {
  candidate_job_workflow_id?: InputMaybe<Scalars['Int']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Insert_Input = {
  candidate_job_workflow?: InputMaybe<Candidate_Job_Workflow_Obj_Rel_Insert_Input>;
  candidate_job_workflow_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  created_by_user?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Enum>;
};

/** aggregate max on columns */
export type Candidate_Job_Workflow_Status_Max_Fields = {
  __typename?: 'candidate_job_workflow_status_max_fields';
  candidate_job_workflow_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Max_Order_By = {
  candidate_job_workflow_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Job_Workflow_Status_Min_Fields = {
  __typename?: 'candidate_job_workflow_status_min_fields';
  candidate_job_workflow_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Min_Order_By = {
  candidate_job_workflow_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Mutation_Response = {
  __typename?: 'candidate_job_workflow_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Job_Workflow_Status>;
};

/** on_conflict condition type for table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_On_Conflict = {
  constraint: Candidate_Job_Workflow_Status_Constraint;
  update_columns?: Array<Candidate_Job_Workflow_Status_Update_Column>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_job_workflow_status". */
export type Candidate_Job_Workflow_Status_Order_By = {
  candidate_job_workflow?: InputMaybe<Candidate_Job_Workflow_Order_By>;
  candidate_job_workflow_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  created_by_user?: InputMaybe<User_Info_Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_job_workflow_status */
export type Candidate_Job_Workflow_Status_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_job_workflow_status" */
export enum Candidate_Job_Workflow_Status_Select_Column {
  /** column name */
  CandidateJobWorkflowId = 'candidate_job_workflow_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Set_Input = {
  candidate_job_workflow_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Enum>;
};

/** aggregate stddev on columns */
export type Candidate_Job_Workflow_Status_Stddev_Fields = {
  __typename?: 'candidate_job_workflow_status_stddev_fields';
  candidate_job_workflow_id: Maybe<Scalars['Float']>;
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Stddev_Order_By = {
  candidate_job_workflow_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Job_Workflow_Status_Stddev_Pop_Fields = {
  __typename?: 'candidate_job_workflow_status_stddev_pop_fields';
  candidate_job_workflow_id: Maybe<Scalars['Float']>;
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Stddev_Pop_Order_By = {
  candidate_job_workflow_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Job_Workflow_Status_Stddev_Samp_Fields = {
  __typename?: 'candidate_job_workflow_status_stddev_samp_fields';
  candidate_job_workflow_id: Maybe<Scalars['Float']>;
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Stddev_Samp_Order_By = {
  candidate_job_workflow_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Job_Workflow_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Job_Workflow_Status_Stream_Cursor_Value_Input = {
  candidate_job_workflow_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Enum>;
};

/** aggregate sum on columns */
export type Candidate_Job_Workflow_Status_Sum_Fields = {
  __typename?: 'candidate_job_workflow_status_sum_fields';
  candidate_job_workflow_id: Maybe<Scalars['Int']>;
  created_by_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Sum_Order_By = {
  candidate_job_workflow_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_job_workflow_status" */
export enum Candidate_Job_Workflow_Status_Update_Column {
  /** column name */
  CandidateJobWorkflowId = 'candidate_job_workflow_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
}

export type Candidate_Job_Workflow_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Job_Workflow_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Job_Workflow_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Job_Workflow_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Job_Workflow_Status_Var_Pop_Fields = {
  __typename?: 'candidate_job_workflow_status_var_pop_fields';
  candidate_job_workflow_id: Maybe<Scalars['Float']>;
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Var_Pop_Order_By = {
  candidate_job_workflow_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Job_Workflow_Status_Var_Samp_Fields = {
  __typename?: 'candidate_job_workflow_status_var_samp_fields';
  candidate_job_workflow_id: Maybe<Scalars['Float']>;
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Var_Samp_Order_By = {
  candidate_job_workflow_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Job_Workflow_Status_Variance_Fields = {
  __typename?: 'candidate_job_workflow_status_variance_fields';
  candidate_job_workflow_id: Maybe<Scalars['Float']>;
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_job_workflow_status" */
export type Candidate_Job_Workflow_Status_Variance_Order_By = {
  candidate_job_workflow_id?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Candidate_Job_Workflow_Stddev_Fields = {
  __typename?: 'candidate_job_workflow_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Job_Workflow_Stddev_Pop_Fields = {
  __typename?: 'candidate_job_workflow_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Job_Workflow_Stddev_Samp_Fields = {
  __typename?: 'candidate_job_workflow_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Job_Workflow_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Job_Workflow_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  /** Record who initiated this match/application of this job for a candidate. */
  source?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Enum>;
  status?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Enum>;
  sync_status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Candidate_Job_Workflow_Sum_Fields = {
  __typename?: 'candidate_job_workflow_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_job_workflow" */
export enum Candidate_Job_Workflow_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Candidate_Job_Workflow_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Job_Workflow_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Job_Workflow_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Job_Workflow_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Job_Workflow_Var_Pop_Fields = {
  __typename?: 'candidate_job_workflow_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Job_Workflow_Var_Samp_Fields = {
  __typename?: 'candidate_job_workflow_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Job_Workflow_Variance_Fields = {
  __typename?: 'candidate_job_workflow_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_job_workflow" */
export type Candidate_Job_Workflow_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  /** ID of the user that created this record. Can be candidate, sourcer or talent partner. */
  created_by_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Candidate_Max_Fields = {
  __typename?: 'candidate_max_fields';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  first_name: Maybe<Scalars['String']>;
  furthest_candidate_curation_workflow_id: Maybe<Scalars['Int']>;
  gem_id: Maybe<Scalars['String']>;
  github_url: Maybe<Scalars['String']>;
  icims_person_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  last_name: Maybe<Scalars['String']>;
  last_name_initial: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['numeric']>;
  linkedin_url: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['numeric']>;
  merge_candidate_id: Maybe<Scalars['String']>;
  other_url: Maybe<Scalars['String']>;
  owner: Maybe<Scalars['Int']>;
  phone: Maybe<Scalars['String']>;
  profile_flow_completed_at: Maybe<Scalars['timestamptz']>;
  profile_flow_furthest_step: Maybe<Scalars['String']>;
  profile_flow_started_at: Maybe<Scalars['timestamptz']>;
  public_id: Maybe<Scalars['uuid']>;
  resume_filename: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate" */
export type Candidate_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Order_By>;
  gem_id?: InputMaybe<Order_By>;
  github_url?: InputMaybe<Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_name_initial?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  merge_candidate_id?: InputMaybe<Order_By>;
  other_url?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  profile_flow_completed_at?: InputMaybe<Order_By>;
  profile_flow_furthest_step?: InputMaybe<Order_By>;
  profile_flow_started_at?: InputMaybe<Order_By>;
  public_id?: InputMaybe<Order_By>;
  resume_filename?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Min_Fields = {
  __typename?: 'candidate_min_fields';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  first_name: Maybe<Scalars['String']>;
  furthest_candidate_curation_workflow_id: Maybe<Scalars['Int']>;
  gem_id: Maybe<Scalars['String']>;
  github_url: Maybe<Scalars['String']>;
  icims_person_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  last_name: Maybe<Scalars['String']>;
  last_name_initial: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['numeric']>;
  linkedin_url: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['numeric']>;
  merge_candidate_id: Maybe<Scalars['String']>;
  other_url: Maybe<Scalars['String']>;
  owner: Maybe<Scalars['Int']>;
  phone: Maybe<Scalars['String']>;
  profile_flow_completed_at: Maybe<Scalars['timestamptz']>;
  profile_flow_furthest_step: Maybe<Scalars['String']>;
  profile_flow_started_at: Maybe<Scalars['timestamptz']>;
  public_id: Maybe<Scalars['uuid']>;
  resume_filename: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate" */
export type Candidate_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Order_By>;
  gem_id?: InputMaybe<Order_By>;
  github_url?: InputMaybe<Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_name_initial?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  merge_candidate_id?: InputMaybe<Order_By>;
  other_url?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  profile_flow_completed_at?: InputMaybe<Order_By>;
  profile_flow_furthest_step?: InputMaybe<Order_By>;
  profile_flow_started_at?: InputMaybe<Order_By>;
  public_id?: InputMaybe<Order_By>;
  resume_filename?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate" */
export type Candidate_Mutation_Response = {
  __typename?: 'candidate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate>;
};

/** input type for inserting object relation for remote table "candidate" */
export type Candidate_Obj_Rel_Insert_Input = {
  data: Candidate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_On_Conflict>;
};

/** on_conflict condition type for table "candidate" */
export type Candidate_On_Conflict = {
  constraint: Candidate_Constraint;
  update_columns?: Array<Candidate_Update_Column>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate". */
export type Candidate_Order_By = {
  applicant_consents_aggregate?: InputMaybe<Applicant_Consent_Aggregate_Order_By>;
  applicant_jobs_aggregate?: InputMaybe<Applicant_Job_Aggregate_Order_By>;
  availability?: InputMaybe<Order_By>;
  candidate_ai_profile_summaries_aggregate?: InputMaybe<Candidate_Ai_Profile_Summary_Aggregate_Order_By>;
  candidate_availability_choice?: InputMaybe<Candidate_Availability_Choices_Order_By>;
  candidate_badge?: InputMaybe<Candidate_Badge_Order_By>;
  candidate_curation_ai_summaries_aggregate?: InputMaybe<Candidate_Curation_Ai_Summary_Aggregate_Order_By>;
  candidate_curation_detail?: InputMaybe<Candidate_Curation_Detail_Order_By>;
  candidate_curation_workflows_aggregate?: InputMaybe<Candidate_Curation_Workflow_Aggregate_Order_By>;
  candidate_educations_aggregate?: InputMaybe<Candidate_Education_Aggregate_Order_By>;
  candidate_emails_aggregate?: InputMaybe<Candidate_Email_Aggregate_Order_By>;
  candidate_hireflix_transcripts_aggregate?: InputMaybe<Hireflix_Transcript_Aggregate_Order_By>;
  candidate_hub_locations_aggregate?: InputMaybe<Candidate_Hub_Location_Aggregate_Order_By>;
  candidate_job_workflows_aggregate?: InputMaybe<Candidate_Job_Workflow_Aggregate_Order_By>;
  candidate_jobs_aggregate?: InputMaybe<Candidate_Job_Aggregate_Order_By>;
  candidate_pdl?: InputMaybe<Candidate_Pdl_Order_By>;
  candidate_pillar_transcripts_aggregate?: InputMaybe<Pillar_Transcript_Aggregate_Order_By>;
  candidate_roles_aggregate?: InputMaybe<Candidate_Role_Aggregate_Order_By>;
  candidate_skills_aggregate?: InputMaybe<Candidate_Skill_Aggregate_Order_By>;
  candidate_stage_logs_aggregate?: InputMaybe<Candidate_Stage_Log_Aggregate_Order_By>;
  candidate_work_experiences_aggregate?: InputMaybe<Candidate_Work_Experience_Aggregate_Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  country_choice?: InputMaybe<Country_Choices_Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  curation_countries_aggregate?: InputMaybe<Candidate_Approved_Country_Aggregate_Order_By>;
  curation_notes_aggregate?: InputMaybe<Candidate_Curation_Note_Aggregate_Order_By>;
  dayforce_employee?: InputMaybe<Dayforce_Employee_Order_By>;
  email?: InputMaybe<Order_By>;
  employment_type?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  furthest_candidate_curation_workflow?: InputMaybe<Candidate_Curation_Workflow_Order_By>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Order_By>;
  furthest_stage?: InputMaybe<Order_By>;
  gem_id?: InputMaybe<Order_By>;
  github_url?: InputMaybe<Order_By>;
  hr_test_results_aggregate?: InputMaybe<Hr_Test_Result_Aggregate_Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  icims_profile_person?: InputMaybe<Icims_Profile_Person_Order_By>;
  id?: InputMaybe<Order_By>;
  interview_requests_aggregate?: InputMaybe<Interview_Request_Aggregate_Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_previous_hire?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_name_initial?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  merge_candidate_id?: InputMaybe<Order_By>;
  open_to_all_companies?: InputMaybe<Order_By>;
  other_url?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  owner_person?: InputMaybe<Icims_Profile_Person_Order_By>;
  parsed_resume?: InputMaybe<Candidate_Parsed_Resume_Order_By>;
  phone?: InputMaybe<Order_By>;
  profile_complete_override?: InputMaybe<Order_By>;
  profile_flow_completed_at?: InputMaybe<Order_By>;
  profile_flow_furthest_step?: InputMaybe<Order_By>;
  profile_flow_started_at?: InputMaybe<Order_By>;
  profile_flow_variations?: InputMaybe<Order_By>;
  public_id?: InputMaybe<Order_By>;
  referred_by_aggregate?: InputMaybe<Candidate_Referrals_Aggregate_Order_By>;
  resume_filename?: InputMaybe<Order_By>;
  saved_candidates_aggregate?: InputMaybe<Saved_Candidate_Aggregate_Order_By>;
  source?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  stage_source?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  transcript_evaluations_aggregate?: InputMaybe<Transcript_Evaluation_Aggregate_Order_By>;
  transcript_skills_aggregate?: InputMaybe<Transcript_Skill_Aggregate_Order_By>;
  transcript_work_experiences_aggregate?: InputMaybe<Transcript_Work_Experience_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "candidate_parsed_resume" */
export type Candidate_Parsed_Resume = {
  __typename?: 'candidate_parsed_resume';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  created_at: Maybe<Scalars['timestamptz']>;
  md5: Maybe<Scalars['String']>;
  source: Maybe<Scalars['String']>;
  status: Maybe<Terminal_Celery_Task_Status_Choices_Enum>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "candidate_parsed_resume" */
export type Candidate_Parsed_Resume_Aggregate = {
  __typename?: 'candidate_parsed_resume_aggregate';
  aggregate: Maybe<Candidate_Parsed_Resume_Aggregate_Fields>;
  nodes: Array<Candidate_Parsed_Resume>;
};

/** aggregate fields of "candidate_parsed_resume" */
export type Candidate_Parsed_Resume_Aggregate_Fields = {
  __typename?: 'candidate_parsed_resume_aggregate_fields';
  avg: Maybe<Candidate_Parsed_Resume_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Parsed_Resume_Max_Fields>;
  min: Maybe<Candidate_Parsed_Resume_Min_Fields>;
  stddev: Maybe<Candidate_Parsed_Resume_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Parsed_Resume_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Parsed_Resume_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Parsed_Resume_Sum_Fields>;
  var_pop: Maybe<Candidate_Parsed_Resume_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Parsed_Resume_Var_Samp_Fields>;
  variance: Maybe<Candidate_Parsed_Resume_Variance_Fields>;
};

/** aggregate fields of "candidate_parsed_resume" */
export type Candidate_Parsed_Resume_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Parsed_Resume_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Parsed_Resume_Avg_Fields = {
  __typename?: 'candidate_parsed_resume_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_parsed_resume". All fields are combined with a logical 'AND'. */
export type Candidate_Parsed_Resume_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Parsed_Resume_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Parsed_Resume_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Parsed_Resume_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  md5?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Terminal_Celery_Task_Status_Choices_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_parsed_resume" */
export enum Candidate_Parsed_Resume_Constraint {
  /** unique or primary key constraint on columns "candidate_id" */
  CandidateParsedResumeCandidateIdKey = 'candidate_parsed_resume_candidate_id_key',
  /** unique or primary key constraint on columns "candidate_id" */
  CandidateParsedResumePkey = 'candidate_parsed_resume_pkey',
}

/** input type for incrementing numeric columns in table "candidate_parsed_resume" */
export type Candidate_Parsed_Resume_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_parsed_resume" */
export type Candidate_Parsed_Resume_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  md5?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Terminal_Celery_Task_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Parsed_Resume_Max_Fields = {
  __typename?: 'candidate_parsed_resume_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  md5: Maybe<Scalars['String']>;
  source: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Candidate_Parsed_Resume_Min_Fields = {
  __typename?: 'candidate_parsed_resume_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  md5: Maybe<Scalars['String']>;
  source: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "candidate_parsed_resume" */
export type Candidate_Parsed_Resume_Mutation_Response = {
  __typename?: 'candidate_parsed_resume_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Parsed_Resume>;
};

/** input type for inserting object relation for remote table "candidate_parsed_resume" */
export type Candidate_Parsed_Resume_Obj_Rel_Insert_Input = {
  data: Candidate_Parsed_Resume_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Parsed_Resume_On_Conflict>;
};

/** on_conflict condition type for table "candidate_parsed_resume" */
export type Candidate_Parsed_Resume_On_Conflict = {
  constraint: Candidate_Parsed_Resume_Constraint;
  update_columns?: Array<Candidate_Parsed_Resume_Update_Column>;
  where?: InputMaybe<Candidate_Parsed_Resume_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_parsed_resume". */
export type Candidate_Parsed_Resume_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  md5?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_parsed_resume */
export type Candidate_Parsed_Resume_Pk_Columns_Input = {
  candidate_id: Scalars['Int'];
};

/** select columns of table "candidate_parsed_resume" */
export enum Candidate_Parsed_Resume_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Md5 = 'md5',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "candidate_parsed_resume" */
export type Candidate_Parsed_Resume_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  md5?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Terminal_Celery_Task_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Candidate_Parsed_Resume_Stddev_Fields = {
  __typename?: 'candidate_parsed_resume_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Parsed_Resume_Stddev_Pop_Fields = {
  __typename?: 'candidate_parsed_resume_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Parsed_Resume_Stddev_Samp_Fields = {
  __typename?: 'candidate_parsed_resume_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_parsed_resume" */
export type Candidate_Parsed_Resume_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Parsed_Resume_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Parsed_Resume_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  md5?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Terminal_Celery_Task_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Candidate_Parsed_Resume_Sum_Fields = {
  __typename?: 'candidate_parsed_resume_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_parsed_resume" */
export enum Candidate_Parsed_Resume_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Md5 = 'md5',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Candidate_Parsed_Resume_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Parsed_Resume_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Parsed_Resume_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Parsed_Resume_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Parsed_Resume_Var_Pop_Fields = {
  __typename?: 'candidate_parsed_resume_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Parsed_Resume_Var_Samp_Fields = {
  __typename?: 'candidate_parsed_resume_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Parsed_Resume_Variance_Fields = {
  __typename?: 'candidate_parsed_resume_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
};

/** People Data Lab data for this candidate */
export type Candidate_Pdl = {
  __typename?: 'candidate_pdl';
  blob: Scalars['jsonb'];
  candidate_id: Scalars['Int'];
  created_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  likelihood: Scalars['Int'];
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** People Data Lab data for this candidate */
export type Candidate_PdlBlobArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "candidate_pdl" */
export type Candidate_Pdl_Aggregate = {
  __typename?: 'candidate_pdl_aggregate';
  aggregate: Maybe<Candidate_Pdl_Aggregate_Fields>;
  nodes: Array<Candidate_Pdl>;
};

/** aggregate fields of "candidate_pdl" */
export type Candidate_Pdl_Aggregate_Fields = {
  __typename?: 'candidate_pdl_aggregate_fields';
  avg: Maybe<Candidate_Pdl_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Pdl_Max_Fields>;
  min: Maybe<Candidate_Pdl_Min_Fields>;
  stddev: Maybe<Candidate_Pdl_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Pdl_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Pdl_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Pdl_Sum_Fields>;
  var_pop: Maybe<Candidate_Pdl_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Pdl_Var_Samp_Fields>;
  variance: Maybe<Candidate_Pdl_Variance_Fields>;
};

/** aggregate fields of "candidate_pdl" */
export type Candidate_Pdl_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Pdl_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Pdl_Append_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Candidate_Pdl_Avg_Fields = {
  __typename?: 'candidate_pdl_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  likelihood: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_pdl". All fields are combined with a logical 'AND'. */
export type Candidate_Pdl_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Pdl_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Pdl_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Pdl_Bool_Exp>>;
  blob?: InputMaybe<Jsonb_Comparison_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  likelihood?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_pdl" */
export enum Candidate_Pdl_Constraint {
  /** unique or primary key constraint on columns "candidate_id" */
  CandidatePdlCandidateIdKey = 'candidate_pdl_candidate_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidatePdlPkey = 'candidate_pdl_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Candidate_Pdl_Delete_At_Path_Input = {
  blob?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Candidate_Pdl_Delete_Elem_Input = {
  blob?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Candidate_Pdl_Delete_Key_Input = {
  blob?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "candidate_pdl" */
export type Candidate_Pdl_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  likelihood?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_pdl" */
export type Candidate_Pdl_Insert_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  likelihood?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Pdl_Max_Fields = {
  __typename?: 'candidate_pdl_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  likelihood: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Candidate_Pdl_Min_Fields = {
  __typename?: 'candidate_pdl_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  likelihood: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "candidate_pdl" */
export type Candidate_Pdl_Mutation_Response = {
  __typename?: 'candidate_pdl_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Pdl>;
};

/** input type for inserting object relation for remote table "candidate_pdl" */
export type Candidate_Pdl_Obj_Rel_Insert_Input = {
  data: Candidate_Pdl_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Pdl_On_Conflict>;
};

/** on_conflict condition type for table "candidate_pdl" */
export type Candidate_Pdl_On_Conflict = {
  constraint: Candidate_Pdl_Constraint;
  update_columns?: Array<Candidate_Pdl_Update_Column>;
  where?: InputMaybe<Candidate_Pdl_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_pdl". */
export type Candidate_Pdl_Order_By = {
  blob?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  likelihood?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_pdl */
export type Candidate_Pdl_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Pdl_Prepend_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "candidate_pdl" */
export enum Candidate_Pdl_Select_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Likelihood = 'likelihood',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "candidate_pdl" */
export type Candidate_Pdl_Set_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  likelihood?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Candidate_Pdl_Stddev_Fields = {
  __typename?: 'candidate_pdl_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  likelihood: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Pdl_Stddev_Pop_Fields = {
  __typename?: 'candidate_pdl_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  likelihood: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Pdl_Stddev_Samp_Fields = {
  __typename?: 'candidate_pdl_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  likelihood: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_pdl" */
export type Candidate_Pdl_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Pdl_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Pdl_Stream_Cursor_Value_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  likelihood?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Candidate_Pdl_Sum_Fields = {
  __typename?: 'candidate_pdl_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  likelihood: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_pdl" */
export enum Candidate_Pdl_Update_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Likelihood = 'likelihood',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Candidate_Pdl_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Candidate_Pdl_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Candidate_Pdl_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Candidate_Pdl_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Candidate_Pdl_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Pdl_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Candidate_Pdl_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Pdl_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Pdl_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Pdl_Var_Pop_Fields = {
  __typename?: 'candidate_pdl_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  likelihood: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Pdl_Var_Samp_Fields = {
  __typename?: 'candidate_pdl_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  likelihood: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Pdl_Variance_Fields = {
  __typename?: 'candidate_pdl_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  likelihood: Maybe<Scalars['Float']>;
};

/** primary key columns input for table: candidate */
export type Candidate_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Prepend_Input = {
  profile_flow_variations?: InputMaybe<Scalars['jsonb']>;
};

/** Track which candidate referred whom */
export type Candidate_Referrals = {
  __typename?: 'candidate_referrals';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  referred_by_candidate_id: Scalars['Int'];
  referred_candidate_id: Scalars['Int'];
  /** An object relationship */
  referring_candidate: Candidate;
  status: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "candidate_referrals" */
export type Candidate_Referrals_Aggregate = {
  __typename?: 'candidate_referrals_aggregate';
  aggregate: Maybe<Candidate_Referrals_Aggregate_Fields>;
  nodes: Array<Candidate_Referrals>;
};

export type Candidate_Referrals_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Referrals_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Referrals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Referrals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Referrals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_referrals" */
export type Candidate_Referrals_Aggregate_Fields = {
  __typename?: 'candidate_referrals_aggregate_fields';
  avg: Maybe<Candidate_Referrals_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Referrals_Max_Fields>;
  min: Maybe<Candidate_Referrals_Min_Fields>;
  stddev: Maybe<Candidate_Referrals_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Referrals_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Referrals_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Referrals_Sum_Fields>;
  var_pop: Maybe<Candidate_Referrals_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Referrals_Var_Samp_Fields>;
  variance: Maybe<Candidate_Referrals_Variance_Fields>;
};

/** aggregate fields of "candidate_referrals" */
export type Candidate_Referrals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Referrals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_referrals" */
export type Candidate_Referrals_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Referrals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Referrals_Max_Order_By>;
  min?: InputMaybe<Candidate_Referrals_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Referrals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Referrals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Referrals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Referrals_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Referrals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Referrals_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Referrals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_referrals" */
export type Candidate_Referrals_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Referrals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Referrals_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Referrals_Avg_Fields = {
  __typename?: 'candidate_referrals_avg_fields';
  id: Maybe<Scalars['Float']>;
  referred_by_candidate_id: Maybe<Scalars['Float']>;
  referred_candidate_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_referrals" */
export type Candidate_Referrals_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  referred_by_candidate_id?: InputMaybe<Order_By>;
  referred_candidate_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_referrals". All fields are combined with a logical 'AND'. */
export type Candidate_Referrals_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Referrals_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Referrals_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Referrals_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  referred_by_candidate_id?: InputMaybe<Int_Comparison_Exp>;
  referred_candidate_id?: InputMaybe<Int_Comparison_Exp>;
  referring_candidate?: InputMaybe<Candidate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_referrals" */
export enum Candidate_Referrals_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateReferralsPkey = 'candidate_referrals_pkey',
  /** unique or primary key constraint on columns "referred_by_candidate_id", "referred_candidate_id" */
  CandidateReferralsReferredCandidateIdReferredByCandidate = 'candidate_referrals_referred_candidate_id_referred_by_candidate',
}

/** input type for incrementing numeric columns in table "candidate_referrals" */
export type Candidate_Referrals_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  referred_by_candidate_id?: InputMaybe<Scalars['Int']>;
  referred_candidate_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_referrals" */
export type Candidate_Referrals_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  referred_by_candidate_id?: InputMaybe<Scalars['Int']>;
  referred_candidate_id?: InputMaybe<Scalars['Int']>;
  referring_candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Referrals_Max_Fields = {
  __typename?: 'candidate_referrals_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  referred_by_candidate_id: Maybe<Scalars['Int']>;
  referred_candidate_id: Maybe<Scalars['Int']>;
  status: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_referrals" */
export type Candidate_Referrals_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referred_by_candidate_id?: InputMaybe<Order_By>;
  referred_candidate_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Referrals_Min_Fields = {
  __typename?: 'candidate_referrals_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  referred_by_candidate_id: Maybe<Scalars['Int']>;
  referred_candidate_id: Maybe<Scalars['Int']>;
  status: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_referrals" */
export type Candidate_Referrals_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referred_by_candidate_id?: InputMaybe<Order_By>;
  referred_candidate_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_referrals" */
export type Candidate_Referrals_Mutation_Response = {
  __typename?: 'candidate_referrals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Referrals>;
};

/** on_conflict condition type for table "candidate_referrals" */
export type Candidate_Referrals_On_Conflict = {
  constraint: Candidate_Referrals_Constraint;
  update_columns?: Array<Candidate_Referrals_Update_Column>;
  where?: InputMaybe<Candidate_Referrals_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_referrals". */
export type Candidate_Referrals_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referred_by_candidate_id?: InputMaybe<Order_By>;
  referred_candidate_id?: InputMaybe<Order_By>;
  referring_candidate?: InputMaybe<Candidate_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_referrals */
export type Candidate_Referrals_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_referrals" */
export enum Candidate_Referrals_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReferredByCandidateId = 'referred_by_candidate_id',
  /** column name */
  ReferredCandidateId = 'referred_candidate_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "candidate_referrals" */
export type Candidate_Referrals_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  referred_by_candidate_id?: InputMaybe<Scalars['Int']>;
  referred_candidate_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Candidate_Referrals_Stddev_Fields = {
  __typename?: 'candidate_referrals_stddev_fields';
  id: Maybe<Scalars['Float']>;
  referred_by_candidate_id: Maybe<Scalars['Float']>;
  referred_candidate_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_referrals" */
export type Candidate_Referrals_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  referred_by_candidate_id?: InputMaybe<Order_By>;
  referred_candidate_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Referrals_Stddev_Pop_Fields = {
  __typename?: 'candidate_referrals_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  referred_by_candidate_id: Maybe<Scalars['Float']>;
  referred_candidate_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_referrals" */
export type Candidate_Referrals_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  referred_by_candidate_id?: InputMaybe<Order_By>;
  referred_candidate_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Referrals_Stddev_Samp_Fields = {
  __typename?: 'candidate_referrals_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  referred_by_candidate_id: Maybe<Scalars['Float']>;
  referred_candidate_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_referrals" */
export type Candidate_Referrals_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  referred_by_candidate_id?: InputMaybe<Order_By>;
  referred_candidate_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_referrals" */
export type Candidate_Referrals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Referrals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Referrals_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  referred_by_candidate_id?: InputMaybe<Scalars['Int']>;
  referred_candidate_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Candidate_Referrals_Sum_Fields = {
  __typename?: 'candidate_referrals_sum_fields';
  id: Maybe<Scalars['Int']>;
  referred_by_candidate_id: Maybe<Scalars['Int']>;
  referred_candidate_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_referrals" */
export type Candidate_Referrals_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  referred_by_candidate_id?: InputMaybe<Order_By>;
  referred_candidate_id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_referrals" */
export enum Candidate_Referrals_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReferredByCandidateId = 'referred_by_candidate_id',
  /** column name */
  ReferredCandidateId = 'referred_candidate_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Candidate_Referrals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Referrals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Referrals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Referrals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Referrals_Var_Pop_Fields = {
  __typename?: 'candidate_referrals_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  referred_by_candidate_id: Maybe<Scalars['Float']>;
  referred_candidate_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_referrals" */
export type Candidate_Referrals_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  referred_by_candidate_id?: InputMaybe<Order_By>;
  referred_candidate_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Referrals_Var_Samp_Fields = {
  __typename?: 'candidate_referrals_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  referred_by_candidate_id: Maybe<Scalars['Float']>;
  referred_candidate_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_referrals" */
export type Candidate_Referrals_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  referred_by_candidate_id?: InputMaybe<Order_By>;
  referred_candidate_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Referrals_Variance_Fields = {
  __typename?: 'candidate_referrals_variance_fields';
  id: Maybe<Scalars['Float']>;
  referred_by_candidate_id: Maybe<Scalars['Float']>;
  referred_candidate_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_referrals" */
export type Candidate_Referrals_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  referred_by_candidate_id?: InputMaybe<Order_By>;
  referred_candidate_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "candidate_role" */
export type Candidate_Role = {
  __typename?: 'candidate_role';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  id: Scalars['Int'];
  role: Role_Choices_Enum;
  /** An object relationship */
  role_choice: Role_Choices;
  source: Candidate_Source_Choices_Enum;
  visible_to_candidate: Scalars['Boolean'];
  years_of_exp: Maybe<Scalars['Int']>;
};

/** aggregated selection of "candidate_role" */
export type Candidate_Role_Aggregate = {
  __typename?: 'candidate_role_aggregate';
  aggregate: Maybe<Candidate_Role_Aggregate_Fields>;
  nodes: Array<Candidate_Role>;
};

export type Candidate_Role_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Candidate_Role_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Candidate_Role_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Candidate_Role_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Role_Aggregate_Bool_Exp_Bool_And = {
  arguments: Candidate_Role_Select_Column_Candidate_Role_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Role_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Role_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Candidate_Role_Select_Column_Candidate_Role_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Role_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Role_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Role_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_role" */
export type Candidate_Role_Aggregate_Fields = {
  __typename?: 'candidate_role_aggregate_fields';
  avg: Maybe<Candidate_Role_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Role_Max_Fields>;
  min: Maybe<Candidate_Role_Min_Fields>;
  stddev: Maybe<Candidate_Role_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Role_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Role_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Role_Sum_Fields>;
  var_pop: Maybe<Candidate_Role_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Role_Var_Samp_Fields>;
  variance: Maybe<Candidate_Role_Variance_Fields>;
};

/** aggregate fields of "candidate_role" */
export type Candidate_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_role" */
export type Candidate_Role_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Role_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Role_Max_Order_By>;
  min?: InputMaybe<Candidate_Role_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Role_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Role_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Role_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Role_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Role_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Role_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Role_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_role" */
export type Candidate_Role_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Role_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Role_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Role_Avg_Fields = {
  __typename?: 'candidate_role_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_role" */
export type Candidate_Role_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_role". All fields are combined with a logical 'AND'. */
export type Candidate_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Role_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Role_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  role?: InputMaybe<Role_Choices_Enum_Comparison_Exp>;
  role_choice?: InputMaybe<Role_Choices_Bool_Exp>;
  source?: InputMaybe<Candidate_Source_Choices_Enum_Comparison_Exp>;
  visible_to_candidate?: InputMaybe<Boolean_Comparison_Exp>;
  years_of_exp?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_role" */
export enum Candidate_Role_Constraint {
  /** unique or primary key constraint on columns "candidate_id", "role" */
  CandidateRoleCandidateIdRoleKey = 'candidate_role_candidate_id_role_key',
  /** unique or primary key constraint on columns "id" */
  CandidateRolePkey = 'candidate_role_pkey',
}

/** input type for incrementing numeric columns in table "candidate_role" */
export type Candidate_Role_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  years_of_exp?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_role" */
export type Candidate_Role_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Role_Choices_Enum>;
  role_choice?: InputMaybe<Role_Choices_Obj_Rel_Insert_Input>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
  years_of_exp?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Candidate_Role_Max_Fields = {
  __typename?: 'candidate_role_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  years_of_exp: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_role" */
export type Candidate_Role_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Role_Min_Fields = {
  __typename?: 'candidate_role_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  years_of_exp: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_role" */
export type Candidate_Role_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_role" */
export type Candidate_Role_Mutation_Response = {
  __typename?: 'candidate_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Role>;
};

/** on_conflict condition type for table "candidate_role" */
export type Candidate_Role_On_Conflict = {
  constraint: Candidate_Role_Constraint;
  update_columns?: Array<Candidate_Role_Update_Column>;
  where?: InputMaybe<Candidate_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_role". */
export type Candidate_Role_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  role_choice?: InputMaybe<Role_Choices_Order_By>;
  source?: InputMaybe<Order_By>;
  visible_to_candidate?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_role */
export type Candidate_Role_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_role" */
export enum Candidate_Role_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  Source = 'source',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
  /** column name */
  YearsOfExp = 'years_of_exp',
}

/** select "candidate_role_aggregate_bool_exp_bool_and_arguments_columns" columns of table "candidate_role" */
export enum Candidate_Role_Select_Column_Candidate_Role_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** select "candidate_role_aggregate_bool_exp_bool_or_arguments_columns" columns of table "candidate_role" */
export enum Candidate_Role_Select_Column_Candidate_Role_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** input type for updating data in table "candidate_role" */
export type Candidate_Role_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Role_Choices_Enum>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
  years_of_exp?: InputMaybe<Scalars['Int']>;
};

/** Many to many relationship associating role with skill */
export type Candidate_Role_Skill = {
  __typename?: 'candidate_role_skill';
  candidate_role: Role_Choices_Enum;
  id: Scalars['Int'];
  /** An object relationship */
  role_choice: Role_Choices;
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
};

/** aggregated selection of "candidate_role_skill" */
export type Candidate_Role_Skill_Aggregate = {
  __typename?: 'candidate_role_skill_aggregate';
  aggregate: Maybe<Candidate_Role_Skill_Aggregate_Fields>;
  nodes: Array<Candidate_Role_Skill>;
};

export type Candidate_Role_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Role_Skill_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Role_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Role_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Role_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_role_skill" */
export type Candidate_Role_Skill_Aggregate_Fields = {
  __typename?: 'candidate_role_skill_aggregate_fields';
  avg: Maybe<Candidate_Role_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Role_Skill_Max_Fields>;
  min: Maybe<Candidate_Role_Skill_Min_Fields>;
  stddev: Maybe<Candidate_Role_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Role_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Role_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Role_Skill_Sum_Fields>;
  var_pop: Maybe<Candidate_Role_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Role_Skill_Var_Samp_Fields>;
  variance: Maybe<Candidate_Role_Skill_Variance_Fields>;
};

/** aggregate fields of "candidate_role_skill" */
export type Candidate_Role_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Role_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_role_skill" */
export type Candidate_Role_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Role_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Role_Skill_Max_Order_By>;
  min?: InputMaybe<Candidate_Role_Skill_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Role_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Role_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Role_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Role_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Role_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Role_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Role_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_role_skill" */
export type Candidate_Role_Skill_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Role_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Role_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Role_Skill_Avg_Fields = {
  __typename?: 'candidate_role_skill_avg_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_role_skill" */
export type Candidate_Role_Skill_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_role_skill". All fields are combined with a logical 'AND'. */
export type Candidate_Role_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Role_Skill_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Role_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Role_Skill_Bool_Exp>>;
  candidate_role?: InputMaybe<Role_Choices_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  role_choice?: InputMaybe<Role_Choices_Bool_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_role_skill" */
export enum Candidate_Role_Skill_Constraint {
  /** unique or primary key constraint on columns "skill_id", "candidate_role" */
  CandidateRoleSkillCandidateRoleSkillIdKey = 'candidate_role_skill_candidate_role_skill_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidateRoleSkillPkey = 'candidate_role_skill_pkey',
}

/** input type for incrementing numeric columns in table "candidate_role_skill" */
export type Candidate_Role_Skill_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_role_skill" */
export type Candidate_Role_Skill_Insert_Input = {
  candidate_role?: InputMaybe<Role_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  role_choice?: InputMaybe<Role_Choices_Obj_Rel_Insert_Input>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Candidate_Role_Skill_Max_Fields = {
  __typename?: 'candidate_role_skill_max_fields';
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_role_skill" */
export type Candidate_Role_Skill_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Role_Skill_Min_Fields = {
  __typename?: 'candidate_role_skill_min_fields';
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_role_skill" */
export type Candidate_Role_Skill_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_role_skill" */
export type Candidate_Role_Skill_Mutation_Response = {
  __typename?: 'candidate_role_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Role_Skill>;
};

/** on_conflict condition type for table "candidate_role_skill" */
export type Candidate_Role_Skill_On_Conflict = {
  constraint: Candidate_Role_Skill_Constraint;
  update_columns?: Array<Candidate_Role_Skill_Update_Column>;
  where?: InputMaybe<Candidate_Role_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_role_skill". */
export type Candidate_Role_Skill_Order_By = {
  candidate_role?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_choice?: InputMaybe<Role_Choices_Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_role_skill */
export type Candidate_Role_Skill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_role_skill" */
export enum Candidate_Role_Skill_Select_Column {
  /** column name */
  CandidateRole = 'candidate_role',
  /** column name */
  Id = 'id',
  /** column name */
  SkillId = 'skill_id',
}

/** input type for updating data in table "candidate_role_skill" */
export type Candidate_Role_Skill_Set_Input = {
  candidate_role?: InputMaybe<Role_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Candidate_Role_Skill_Stddev_Fields = {
  __typename?: 'candidate_role_skill_stddev_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_role_skill" */
export type Candidate_Role_Skill_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Role_Skill_Stddev_Pop_Fields = {
  __typename?: 'candidate_role_skill_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_role_skill" */
export type Candidate_Role_Skill_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Role_Skill_Stddev_Samp_Fields = {
  __typename?: 'candidate_role_skill_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_role_skill" */
export type Candidate_Role_Skill_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_role_skill" */
export type Candidate_Role_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Role_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Role_Skill_Stream_Cursor_Value_Input = {
  candidate_role?: InputMaybe<Role_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Role_Skill_Sum_Fields = {
  __typename?: 'candidate_role_skill_sum_fields';
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_role_skill" */
export type Candidate_Role_Skill_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_role_skill" */
export enum Candidate_Role_Skill_Update_Column {
  /** column name */
  CandidateRole = 'candidate_role',
  /** column name */
  Id = 'id',
  /** column name */
  SkillId = 'skill_id',
}

export type Candidate_Role_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Role_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Role_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Role_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Role_Skill_Var_Pop_Fields = {
  __typename?: 'candidate_role_skill_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_role_skill" */
export type Candidate_Role_Skill_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Role_Skill_Var_Samp_Fields = {
  __typename?: 'candidate_role_skill_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_role_skill" */
export type Candidate_Role_Skill_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Role_Skill_Variance_Fields = {
  __typename?: 'candidate_role_skill_variance_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_role_skill" */
export type Candidate_Role_Skill_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Candidate_Role_Stddev_Fields = {
  __typename?: 'candidate_role_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_role" */
export type Candidate_Role_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Role_Stddev_Pop_Fields = {
  __typename?: 'candidate_role_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_role" */
export type Candidate_Role_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Role_Stddev_Samp_Fields = {
  __typename?: 'candidate_role_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_role" */
export type Candidate_Role_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_role" */
export type Candidate_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Role_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Role_Choices_Enum>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
  years_of_exp?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Role_Sum_Fields = {
  __typename?: 'candidate_role_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  years_of_exp: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_role" */
export type Candidate_Role_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_role" */
export enum Candidate_Role_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  Source = 'source',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
  /** column name */
  YearsOfExp = 'years_of_exp',
}

export type Candidate_Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Role_Var_Pop_Fields = {
  __typename?: 'candidate_role_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_role" */
export type Candidate_Role_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Role_Var_Samp_Fields = {
  __typename?: 'candidate_role_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_role" */
export type Candidate_Role_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Role_Variance_Fields = {
  __typename?: 'candidate_role_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_role" */
export type Candidate_Role_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** columns and relationships of "candidate_sdlc_experience_choices" */
export type Candidate_Sdlc_Experience_Choices = {
  __typename?: 'candidate_sdlc_experience_choices';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_sdlc_experience_choices" */
export type Candidate_Sdlc_Experience_Choices_Aggregate = {
  __typename?: 'candidate_sdlc_experience_choices_aggregate';
  aggregate: Maybe<Candidate_Sdlc_Experience_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Sdlc_Experience_Choices>;
};

/** aggregate fields of "candidate_sdlc_experience_choices" */
export type Candidate_Sdlc_Experience_Choices_Aggregate_Fields = {
  __typename?: 'candidate_sdlc_experience_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Sdlc_Experience_Choices_Max_Fields>;
  min: Maybe<Candidate_Sdlc_Experience_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_sdlc_experience_choices" */
export type Candidate_Sdlc_Experience_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_sdlc_experience_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Sdlc_Experience_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Sdlc_Experience_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_sdlc_experience_choices" */
export enum Candidate_Sdlc_Experience_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateSdlcExperienceChoicesPkey = 'candidate_sdlc_experience_choices_pkey',
}

export enum Candidate_Sdlc_Experience_Choices_Enum {
  /** Experience in strategic project planning and direction, requirements gathering and analysis, and comprehensive project management. */
  Analyst = 'ANALYST',
  /** Deep technical involvement in all areas of the SDLC, making high-level design and architectural decisions. */
  Architect = 'ARCHITECT',
  /** Active contribution in various phases of the SDLC but limited involvement in project management and high-level decision-making. */
  Contributor = 'CONTRIBUTOR',
  /** Primarily focused on executing development tasks with limited involvement in broader SDLC activities. */
  TaskExecutor = 'TASK_EXECUTOR',
}

/** Boolean expression to compare columns of type "candidate_sdlc_experience_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Sdlc_Experience_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Sdlc_Experience_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Sdlc_Experience_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_sdlc_experience_choices" */
export type Candidate_Sdlc_Experience_Choices_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Sdlc_Experience_Choices_Max_Fields = {
  __typename?: 'candidate_sdlc_experience_choices_max_fields';
  description: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Sdlc_Experience_Choices_Min_Fields = {
  __typename?: 'candidate_sdlc_experience_choices_min_fields';
  description: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_sdlc_experience_choices" */
export type Candidate_Sdlc_Experience_Choices_Mutation_Response = {
  __typename?: 'candidate_sdlc_experience_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Sdlc_Experience_Choices>;
};

/** on_conflict condition type for table "candidate_sdlc_experience_choices" */
export type Candidate_Sdlc_Experience_Choices_On_Conflict = {
  constraint: Candidate_Sdlc_Experience_Choices_Constraint;
  update_columns?: Array<Candidate_Sdlc_Experience_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Sdlc_Experience_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_sdlc_experience_choices". */
export type Candidate_Sdlc_Experience_Choices_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_sdlc_experience_choices */
export type Candidate_Sdlc_Experience_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_sdlc_experience_choices" */
export enum Candidate_Sdlc_Experience_Choices_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_sdlc_experience_choices" */
export type Candidate_Sdlc_Experience_Choices_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_sdlc_experience_choices" */
export type Candidate_Sdlc_Experience_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Sdlc_Experience_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Sdlc_Experience_Choices_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_sdlc_experience_choices" */
export enum Candidate_Sdlc_Experience_Choices_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Candidate_Sdlc_Experience_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Sdlc_Experience_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Sdlc_Experience_Choices_Bool_Exp;
};

/** select columns of table "candidate" */
export enum Candidate_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FurthestCandidateCurationWorkflowId = 'furthest_candidate_curation_workflow_id',
  /** column name */
  FurthestStage = 'furthest_stage',
  /** column name */
  GemId = 'gem_id',
  /** column name */
  GithubUrl = 'github_url',
  /** column name */
  IcimsPersonProfileId = 'icims_person_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastNameInitial = 'last_name_initial',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  MergeCandidateId = 'merge_candidate_id',
  /** column name */
  OpenToAllCompanies = 'open_to_all_companies',
  /** column name */
  OtherUrl = 'other_url',
  /** column name */
  Owner = 'owner',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfileCompleteOverride = 'profile_complete_override',
  /** column name */
  ProfileFlowCompletedAt = 'profile_flow_completed_at',
  /** column name */
  ProfileFlowFurthestStep = 'profile_flow_furthest_step',
  /** column name */
  ProfileFlowStartedAt = 'profile_flow_started_at',
  /** column name */
  ProfileFlowVariations = 'profile_flow_variations',
  /** column name */
  PublicId = 'public_id',
  /** column name */
  ResumeFilename = 'resume_filename',
  /** column name */
  Source = 'source',
  /** column name */
  Stage = 'stage',
  /** column name */
  StageSource = 'stage_source',
  /** column name */
  State = 'state',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id',
}

/** select "candidate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "candidate" */
export enum Candidate_Select_Column_Candidate_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  OpenToAllCompanies = 'open_to_all_companies',
  /** column name */
  ProfileCompleteOverride = 'profile_complete_override',
}

/** select "candidate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "candidate" */
export enum Candidate_Select_Column_Candidate_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  OpenToAllCompanies = 'open_to_all_companies',
  /** column name */
  ProfileCompleteOverride = 'profile_complete_override',
}

/** input type for updating data in table "candidate" */
export type Candidate_Set_Input = {
  availability?: InputMaybe<Candidate_Availability_Choices_Enum>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  first_name?: InputMaybe<Scalars['String']>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Scalars['Int']>;
  furthest_stage?: InputMaybe<Candidate_Stage_Choices_Enum>;
  gem_id?: InputMaybe<Scalars['String']>;
  github_url?: InputMaybe<Scalars['String']>;
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  merge_candidate_id?: InputMaybe<Scalars['String']>;
  open_to_all_companies?: InputMaybe<Scalars['Boolean']>;
  other_url?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  profile_complete_override?: InputMaybe<Scalars['Boolean']>;
  profile_flow_completed_at?: InputMaybe<Scalars['timestamptz']>;
  profile_flow_furthest_step?: InputMaybe<Scalars['String']>;
  profile_flow_started_at?: InputMaybe<Scalars['timestamptz']>;
  profile_flow_variations?: InputMaybe<Scalars['jsonb']>;
  public_id?: InputMaybe<Scalars['uuid']>;
  resume_filename?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  stage?: InputMaybe<Candidate_Stage_Choices_Enum>;
  stage_source?: InputMaybe<Candidate_Stage_Source_Choices_Enum>;
  state?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "candidate_skill" */
export type Candidate_Skill = {
  __typename?: 'candidate_skill';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  competency: Maybe<Candidate_Skill_Comp_Choices_Enum>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_favorite: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
  source: Candidate_Source_Choices_Enum;
  updated_at: Scalars['timestamptz'];
  visible_to_candidate: Scalars['Boolean'];
  years_of_exp: Maybe<Scalars['Int']>;
};

/** aggregated selection of "candidate_skill" */
export type Candidate_Skill_Aggregate = {
  __typename?: 'candidate_skill_aggregate';
  aggregate: Maybe<Candidate_Skill_Aggregate_Fields>;
  nodes: Array<Candidate_Skill>;
};

export type Candidate_Skill_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Candidate_Skill_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Candidate_Skill_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Candidate_Skill_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Skill_Aggregate_Bool_Exp_Bool_And = {
  arguments: Candidate_Skill_Select_Column_Candidate_Skill_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Skill_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Skill_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Candidate_Skill_Select_Column_Candidate_Skill_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Skill_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_skill" */
export type Candidate_Skill_Aggregate_Fields = {
  __typename?: 'candidate_skill_aggregate_fields';
  avg: Maybe<Candidate_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Skill_Max_Fields>;
  min: Maybe<Candidate_Skill_Min_Fields>;
  stddev: Maybe<Candidate_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Skill_Sum_Fields>;
  var_pop: Maybe<Candidate_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Skill_Var_Samp_Fields>;
  variance: Maybe<Candidate_Skill_Variance_Fields>;
};

/** aggregate fields of "candidate_skill" */
export type Candidate_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_skill" */
export type Candidate_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Skill_Max_Order_By>;
  min?: InputMaybe<Candidate_Skill_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_skill" */
export type Candidate_Skill_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Skill_Avg_Fields = {
  __typename?: 'candidate_skill_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_skill" */
export type Candidate_Skill_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_skill". All fields are combined with a logical 'AND'. */
export type Candidate_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Skill_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Skill_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  competency?: InputMaybe<Candidate_Skill_Comp_Choices_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_favorite?: InputMaybe<Boolean_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<Candidate_Source_Choices_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visible_to_candidate?: InputMaybe<Boolean_Comparison_Exp>;
  years_of_exp?: InputMaybe<Int_Comparison_Exp>;
};

/** Enum table for candidate's skill competencies  */
export type Candidate_Skill_Comp_Choices = {
  __typename?: 'candidate_skill_comp_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_skill_comp_choices" */
export type Candidate_Skill_Comp_Choices_Aggregate = {
  __typename?: 'candidate_skill_comp_choices_aggregate';
  aggregate: Maybe<Candidate_Skill_Comp_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Skill_Comp_Choices>;
};

/** aggregate fields of "candidate_skill_comp_choices" */
export type Candidate_Skill_Comp_Choices_Aggregate_Fields = {
  __typename?: 'candidate_skill_comp_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Skill_Comp_Choices_Max_Fields>;
  min: Maybe<Candidate_Skill_Comp_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_skill_comp_choices" */
export type Candidate_Skill_Comp_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_skill_comp_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Skill_Comp_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Skill_Comp_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_skill_comp_choices" */
export enum Candidate_Skill_Comp_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateSkillCompChoicesPkey = 'candidate_skill_comp_choices_pkey',
}

export enum Candidate_Skill_Comp_Choices_Enum {
  Advanced = 'Advanced',
  Beginner = 'Beginner',
  Expert = 'Expert',
  Intermediate = 'Intermediate',
}

/** Boolean expression to compare columns of type "candidate_skill_comp_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Skill_Comp_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Skill_Comp_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Skill_Comp_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_skill_comp_choices" */
export type Candidate_Skill_Comp_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Skill_Comp_Choices_Max_Fields = {
  __typename?: 'candidate_skill_comp_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Skill_Comp_Choices_Min_Fields = {
  __typename?: 'candidate_skill_comp_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_skill_comp_choices" */
export type Candidate_Skill_Comp_Choices_Mutation_Response = {
  __typename?: 'candidate_skill_comp_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Skill_Comp_Choices>;
};

/** on_conflict condition type for table "candidate_skill_comp_choices" */
export type Candidate_Skill_Comp_Choices_On_Conflict = {
  constraint: Candidate_Skill_Comp_Choices_Constraint;
  update_columns?: Array<Candidate_Skill_Comp_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Skill_Comp_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_skill_comp_choices". */
export type Candidate_Skill_Comp_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_skill_comp_choices */
export type Candidate_Skill_Comp_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_skill_comp_choices" */
export enum Candidate_Skill_Comp_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_skill_comp_choices" */
export type Candidate_Skill_Comp_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_skill_comp_choices" */
export type Candidate_Skill_Comp_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Skill_Comp_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Skill_Comp_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_skill_comp_choices" */
export enum Candidate_Skill_Comp_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Skill_Comp_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Skill_Comp_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Skill_Comp_Choices_Bool_Exp;
};

/** unique or primary key constraints on table "candidate_skill" */
export enum Candidate_Skill_Constraint {
  /** unique or primary key constraint on columns "skill_id", "candidate_id" */
  CandidateSkillCandidateIdSkillIdKey = 'candidate_skill_candidate_id_skill_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidateSkillPkey = 'candidate_skill_pkey',
}

/** input type for incrementing numeric columns in table "candidate_skill" */
export type Candidate_Skill_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  years_of_exp?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_skill" */
export type Candidate_Skill_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  competency?: InputMaybe<Candidate_Skill_Comp_Choices_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_favorite?: InputMaybe<Scalars['Boolean']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
  years_of_exp?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Candidate_Skill_Max_Fields = {
  __typename?: 'candidate_skill_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  years_of_exp: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_skill" */
export type Candidate_Skill_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Skill_Min_Fields = {
  __typename?: 'candidate_skill_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  years_of_exp: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_skill" */
export type Candidate_Skill_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_skill" */
export type Candidate_Skill_Mutation_Response = {
  __typename?: 'candidate_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Skill>;
};

/** on_conflict condition type for table "candidate_skill" */
export type Candidate_Skill_On_Conflict = {
  constraint: Candidate_Skill_Constraint;
  update_columns?: Array<Candidate_Skill_Update_Column>;
  where?: InputMaybe<Candidate_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_skill". */
export type Candidate_Skill_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  competency?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_favorite?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visible_to_candidate?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_skill */
export type Candidate_Skill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_skill" */
export enum Candidate_Skill_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Competency = 'competency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsFavorite = 'is_favorite',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
  /** column name */
  YearsOfExp = 'years_of_exp',
}

/** select "candidate_skill_aggregate_bool_exp_bool_and_arguments_columns" columns of table "candidate_skill" */
export enum Candidate_Skill_Select_Column_Candidate_Skill_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsFavorite = 'is_favorite',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** select "candidate_skill_aggregate_bool_exp_bool_or_arguments_columns" columns of table "candidate_skill" */
export enum Candidate_Skill_Select_Column_Candidate_Skill_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsFavorite = 'is_favorite',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** input type for updating data in table "candidate_skill" */
export type Candidate_Skill_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  competency?: InputMaybe<Candidate_Skill_Comp_Choices_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_favorite?: InputMaybe<Scalars['Boolean']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
  years_of_exp?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Candidate_Skill_Stddev_Fields = {
  __typename?: 'candidate_skill_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_skill" */
export type Candidate_Skill_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Skill_Stddev_Pop_Fields = {
  __typename?: 'candidate_skill_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_skill" */
export type Candidate_Skill_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Skill_Stddev_Samp_Fields = {
  __typename?: 'candidate_skill_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_skill" */
export type Candidate_Skill_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_skill" */
export type Candidate_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Skill_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  competency?: InputMaybe<Candidate_Skill_Comp_Choices_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_favorite?: InputMaybe<Scalars['Boolean']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
  years_of_exp?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Skill_Sum_Fields = {
  __typename?: 'candidate_skill_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  years_of_exp: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_skill" */
export type Candidate_Skill_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_skill" */
export enum Candidate_Skill_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Competency = 'competency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsFavorite = 'is_favorite',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
  /** column name */
  YearsOfExp = 'years_of_exp',
}

export type Candidate_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Skill_Var_Pop_Fields = {
  __typename?: 'candidate_skill_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_skill" */
export type Candidate_Skill_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Skill_Var_Samp_Fields = {
  __typename?: 'candidate_skill_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_skill" */
export type Candidate_Skill_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Skill_Variance_Fields = {
  __typename?: 'candidate_skill_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  years_of_exp: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_skill" */
export type Candidate_Skill_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  years_of_exp?: InputMaybe<Order_By>;
};

/** Enum table for candidate source */
export type Candidate_Source_Choices = {
  __typename?: 'candidate_source_choices';
  value: Scalars['String'];
};

/** aggregated selection of "candidate_source_choices" */
export type Candidate_Source_Choices_Aggregate = {
  __typename?: 'candidate_source_choices_aggregate';
  aggregate: Maybe<Candidate_Source_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Source_Choices>;
};

/** aggregate fields of "candidate_source_choices" */
export type Candidate_Source_Choices_Aggregate_Fields = {
  __typename?: 'candidate_source_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Source_Choices_Max_Fields>;
  min: Maybe<Candidate_Source_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_source_choices" */
export type Candidate_Source_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Source_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_source_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Source_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Source_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Source_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Source_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_source_choices" */
export enum Candidate_Source_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateSourceChoicesPkey = 'candidate_source_choices_pkey',
}

export enum Candidate_Source_Choices_Enum {
  Admin = 'Admin',
  Applicant = 'Applicant',
  CandidatePortal = 'CandidatePortal',
  Gem = 'Gem',
  Icims = 'ICIMS',
  LandingPage = 'LANDING_PAGE',
  Partnership = 'PARTNERSHIP',
  Resume = 'Resume',
  ResumeGpt = 'Resume_GPT',
}

/** Boolean expression to compare columns of type "candidate_source_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Source_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Source_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Source_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Source_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Source_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_source_choices" */
export type Candidate_Source_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Source_Choices_Max_Fields = {
  __typename?: 'candidate_source_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Source_Choices_Min_Fields = {
  __typename?: 'candidate_source_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_source_choices" */
export type Candidate_Source_Choices_Mutation_Response = {
  __typename?: 'candidate_source_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Source_Choices>;
};

/** on_conflict condition type for table "candidate_source_choices" */
export type Candidate_Source_Choices_On_Conflict = {
  constraint: Candidate_Source_Choices_Constraint;
  update_columns?: Array<Candidate_Source_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Source_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_source_choices". */
export type Candidate_Source_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_source_choices */
export type Candidate_Source_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_source_choices" */
export enum Candidate_Source_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_source_choices" */
export type Candidate_Source_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_source_choices" */
export type Candidate_Source_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Source_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Source_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_source_choices" */
export enum Candidate_Source_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Candidate_Source_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Source_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Source_Choices_Bool_Exp;
};

/** Enum table for candidate.stage */
export type Candidate_Stage_Choices = {
  __typename?: 'candidate_stage_choices';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_stage_choices" */
export type Candidate_Stage_Choices_Aggregate = {
  __typename?: 'candidate_stage_choices_aggregate';
  aggregate: Maybe<Candidate_Stage_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Stage_Choices>;
};

/** aggregate fields of "candidate_stage_choices" */
export type Candidate_Stage_Choices_Aggregate_Fields = {
  __typename?: 'candidate_stage_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Stage_Choices_Max_Fields>;
  min: Maybe<Candidate_Stage_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_stage_choices" */
export type Candidate_Stage_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Stage_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_stage_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Stage_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Stage_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Stage_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Stage_Choices_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_stage_choices" */
export enum Candidate_Stage_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateStageChoicesPkey = 'candidate_stage_choices_pkey',
}

export enum Candidate_Stage_Choices_Enum {
  /** Terminal user who has completed a baseline profile and is available to be reviewed or supplemented by TA */
  CompleteProfile = 'COMPLETE_PROFILE',
  /** Hired candidate! */
  Hire = 'HIRE',
  /** Submissions who are accepted to Interview */
  Interviewee = 'INTERVIEWEE',
  /** Interviewees who have advanced to the Offer stage */
  Offer = 'OFFER',
  /** User who has created / claimed a Terminal account OR has been sourced from partners / Talent team */
  Profile = 'PROFILE',
  /** Completed Profile that has been assessed by a Talent Advisor */
  ScreenedProfile = 'SCREENED_PROFILE',
  /** Known person or contact who has not yet created a Terminal account */
  Seed = 'SEED',
  /** Assessed Profile who has been submitted / matched to specific open jobs */
  Submission = 'SUBMISSION',
}

/** Boolean expression to compare columns of type "candidate_stage_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Stage_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Stage_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Stage_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Stage_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Stage_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_stage_choices" */
export type Candidate_Stage_Choices_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Stage_Choices_Max_Fields = {
  __typename?: 'candidate_stage_choices_max_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Stage_Choices_Min_Fields = {
  __typename?: 'candidate_stage_choices_min_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_stage_choices" */
export type Candidate_Stage_Choices_Mutation_Response = {
  __typename?: 'candidate_stage_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Stage_Choices>;
};

/** on_conflict condition type for table "candidate_stage_choices" */
export type Candidate_Stage_Choices_On_Conflict = {
  constraint: Candidate_Stage_Choices_Constraint;
  update_columns?: Array<Candidate_Stage_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Stage_Choices_Bool_Exp>;
};

/** Before creating a candidate_stage_choice, first create a row in this table. */
export type Candidate_Stage_Choices_Order = {
  __typename?: 'candidate_stage_choices_order';
  order: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_stage_choices_order" */
export type Candidate_Stage_Choices_Order_Aggregate = {
  __typename?: 'candidate_stage_choices_order_aggregate';
  aggregate: Maybe<Candidate_Stage_Choices_Order_Aggregate_Fields>;
  nodes: Array<Candidate_Stage_Choices_Order>;
};

/** aggregate fields of "candidate_stage_choices_order" */
export type Candidate_Stage_Choices_Order_Aggregate_Fields = {
  __typename?: 'candidate_stage_choices_order_aggregate_fields';
  avg: Maybe<Candidate_Stage_Choices_Order_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Stage_Choices_Order_Max_Fields>;
  min: Maybe<Candidate_Stage_Choices_Order_Min_Fields>;
  stddev: Maybe<Candidate_Stage_Choices_Order_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Stage_Choices_Order_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Stage_Choices_Order_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Stage_Choices_Order_Sum_Fields>;
  var_pop: Maybe<Candidate_Stage_Choices_Order_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Stage_Choices_Order_Var_Samp_Fields>;
  variance: Maybe<Candidate_Stage_Choices_Order_Variance_Fields>;
};

/** aggregate fields of "candidate_stage_choices_order" */
export type Candidate_Stage_Choices_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Stage_Choices_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidate_Stage_Choices_Order_Avg_Fields = {
  __typename?: 'candidate_stage_choices_order_avg_fields';
  order: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_stage_choices_order". All fields are combined with a logical 'AND'. */
export type Candidate_Stage_Choices_Order_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Stage_Choices_Order_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Stage_Choices_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Stage_Choices_Order_Bool_Exp>>;
  order?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "candidate_stage_choices". */
export type Candidate_Stage_Choices_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "candidate_stage_choices_order" */
export enum Candidate_Stage_Choices_Order_Constraint {
  /** unique or primary key constraint on columns "order" */
  CandidateStageChoicesOrderOrderKey = 'candidate_stage_choices_order_order_key',
  /** unique or primary key constraint on columns "value" */
  CandidateStageChoicesOrderPkey = 'candidate_stage_choices_order_pkey',
}

/** input type for incrementing numeric columns in table "candidate_stage_choices_order" */
export type Candidate_Stage_Choices_Order_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_stage_choices_order" */
export type Candidate_Stage_Choices_Order_Insert_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Stage_Choices_Order_Max_Fields = {
  __typename?: 'candidate_stage_choices_order_max_fields';
  order: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Stage_Choices_Order_Min_Fields = {
  __typename?: 'candidate_stage_choices_order_min_fields';
  order: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_stage_choices_order" */
export type Candidate_Stage_Choices_Order_Mutation_Response = {
  __typename?: 'candidate_stage_choices_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Stage_Choices_Order>;
};

/** on_conflict condition type for table "candidate_stage_choices_order" */
export type Candidate_Stage_Choices_Order_On_Conflict = {
  constraint: Candidate_Stage_Choices_Order_Constraint;
  update_columns?: Array<Candidate_Stage_Choices_Order_Update_Column>;
  where?: InputMaybe<Candidate_Stage_Choices_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_stage_choices_order". */
export type Candidate_Stage_Choices_Order_Order_By = {
  order?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_stage_choices_order */
export type Candidate_Stage_Choices_Order_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_stage_choices_order" */
export enum Candidate_Stage_Choices_Order_Select_Column {
  /** column name */
  Order = 'order',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_stage_choices_order" */
export type Candidate_Stage_Choices_Order_Set_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Candidate_Stage_Choices_Order_Stddev_Fields = {
  __typename?: 'candidate_stage_choices_order_stddev_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Stage_Choices_Order_Stddev_Pop_Fields = {
  __typename?: 'candidate_stage_choices_order_stddev_pop_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Stage_Choices_Order_Stddev_Samp_Fields = {
  __typename?: 'candidate_stage_choices_order_stddev_samp_fields';
  order: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_stage_choices_order" */
export type Candidate_Stage_Choices_Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Stage_Choices_Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Stage_Choices_Order_Stream_Cursor_Value_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Candidate_Stage_Choices_Order_Sum_Fields = {
  __typename?: 'candidate_stage_choices_order_sum_fields';
  order: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_stage_choices_order" */
export enum Candidate_Stage_Choices_Order_Update_Column {
  /** column name */
  Order = 'order',
  /** column name */
  Value = 'value',
}

export type Candidate_Stage_Choices_Order_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Stage_Choices_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Stage_Choices_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Stage_Choices_Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Stage_Choices_Order_Var_Pop_Fields = {
  __typename?: 'candidate_stage_choices_order_var_pop_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Stage_Choices_Order_Var_Samp_Fields = {
  __typename?: 'candidate_stage_choices_order_var_samp_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Stage_Choices_Order_Variance_Fields = {
  __typename?: 'candidate_stage_choices_order_variance_fields';
  order: Maybe<Scalars['Float']>;
};

/** primary key columns input for table: candidate_stage_choices */
export type Candidate_Stage_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_stage_choices" */
export enum Candidate_Stage_Choices_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_stage_choices" */
export type Candidate_Stage_Choices_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_stage_choices" */
export type Candidate_Stage_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Stage_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Stage_Choices_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_stage_choices" */
export enum Candidate_Stage_Choices_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Candidate_Stage_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Stage_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Stage_Choices_Bool_Exp;
};

/** Do not add to this table.  Rows are created by a trigger when candidate.stage changes. */
export type Candidate_Stage_Log = {
  __typename?: 'candidate_stage_log';
  candidate_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  source: Candidate_Stage_Source_Choices_Enum;
  stage: Candidate_Stage_Choices_Enum;
};

/** aggregated selection of "candidate_stage_log" */
export type Candidate_Stage_Log_Aggregate = {
  __typename?: 'candidate_stage_log_aggregate';
  aggregate: Maybe<Candidate_Stage_Log_Aggregate_Fields>;
  nodes: Array<Candidate_Stage_Log>;
};

export type Candidate_Stage_Log_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Stage_Log_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Stage_Log_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Stage_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Stage_Log_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_stage_log" */
export type Candidate_Stage_Log_Aggregate_Fields = {
  __typename?: 'candidate_stage_log_aggregate_fields';
  avg: Maybe<Candidate_Stage_Log_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Stage_Log_Max_Fields>;
  min: Maybe<Candidate_Stage_Log_Min_Fields>;
  stddev: Maybe<Candidate_Stage_Log_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Stage_Log_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Stage_Log_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Stage_Log_Sum_Fields>;
  var_pop: Maybe<Candidate_Stage_Log_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Stage_Log_Var_Samp_Fields>;
  variance: Maybe<Candidate_Stage_Log_Variance_Fields>;
};

/** aggregate fields of "candidate_stage_log" */
export type Candidate_Stage_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Stage_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_stage_log" */
export type Candidate_Stage_Log_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Stage_Log_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Stage_Log_Max_Order_By>;
  min?: InputMaybe<Candidate_Stage_Log_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Stage_Log_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Stage_Log_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Stage_Log_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Stage_Log_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Stage_Log_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Stage_Log_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Stage_Log_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_stage_log" */
export type Candidate_Stage_Log_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Stage_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Stage_Log_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Stage_Log_Avg_Fields = {
  __typename?: 'candidate_stage_log_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_stage_log" */
export type Candidate_Stage_Log_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_stage_log". All fields are combined with a logical 'AND'. */
export type Candidate_Stage_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Stage_Log_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Stage_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Stage_Log_Bool_Exp>>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<Candidate_Stage_Source_Choices_Enum_Comparison_Exp>;
  stage?: InputMaybe<Candidate_Stage_Choices_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_stage_log" */
export enum Candidate_Stage_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateStageLogPkey = 'candidate_stage_log_pkey',
}

/** input type for incrementing numeric columns in table "candidate_stage_log" */
export type Candidate_Stage_Log_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_stage_log" */
export type Candidate_Stage_Log_Insert_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Stage_Source_Choices_Enum>;
  stage?: InputMaybe<Candidate_Stage_Choices_Enum>;
};

/** aggregate max on columns */
export type Candidate_Stage_Log_Max_Fields = {
  __typename?: 'candidate_stage_log_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "candidate_stage_log" */
export type Candidate_Stage_Log_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Stage_Log_Min_Fields = {
  __typename?: 'candidate_stage_log_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "candidate_stage_log" */
export type Candidate_Stage_Log_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_stage_log" */
export type Candidate_Stage_Log_Mutation_Response = {
  __typename?: 'candidate_stage_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Stage_Log>;
};

/** on_conflict condition type for table "candidate_stage_log" */
export type Candidate_Stage_Log_On_Conflict = {
  constraint: Candidate_Stage_Log_Constraint;
  update_columns?: Array<Candidate_Stage_Log_Update_Column>;
  where?: InputMaybe<Candidate_Stage_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_stage_log". */
export type Candidate_Stage_Log_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_stage_log */
export type Candidate_Stage_Log_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_stage_log" */
export enum Candidate_Stage_Log_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  Stage = 'stage',
}

/** input type for updating data in table "candidate_stage_log" */
export type Candidate_Stage_Log_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Stage_Source_Choices_Enum>;
  stage?: InputMaybe<Candidate_Stage_Choices_Enum>;
};

/** aggregate stddev on columns */
export type Candidate_Stage_Log_Stddev_Fields = {
  __typename?: 'candidate_stage_log_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_stage_log" */
export type Candidate_Stage_Log_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Stage_Log_Stddev_Pop_Fields = {
  __typename?: 'candidate_stage_log_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_stage_log" */
export type Candidate_Stage_Log_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Stage_Log_Stddev_Samp_Fields = {
  __typename?: 'candidate_stage_log_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_stage_log" */
export type Candidate_Stage_Log_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_stage_log" */
export type Candidate_Stage_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Stage_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Stage_Log_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Candidate_Stage_Source_Choices_Enum>;
  stage?: InputMaybe<Candidate_Stage_Choices_Enum>;
};

/** aggregate sum on columns */
export type Candidate_Stage_Log_Sum_Fields = {
  __typename?: 'candidate_stage_log_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_stage_log" */
export type Candidate_Stage_Log_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_stage_log" */
export enum Candidate_Stage_Log_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  Stage = 'stage',
}

export type Candidate_Stage_Log_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Stage_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Stage_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Stage_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Stage_Log_Var_Pop_Fields = {
  __typename?: 'candidate_stage_log_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_stage_log" */
export type Candidate_Stage_Log_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Stage_Log_Var_Samp_Fields = {
  __typename?: 'candidate_stage_log_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_stage_log" */
export type Candidate_Stage_Log_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Stage_Log_Variance_Fields = {
  __typename?: 'candidate_stage_log_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_stage_log" */
export type Candidate_Stage_Log_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Enum table for candidate.stage_source */
export type Candidate_Stage_Source_Choices = {
  __typename?: 'candidate_stage_source_choices';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "candidate_stage_source_choices" */
export type Candidate_Stage_Source_Choices_Aggregate = {
  __typename?: 'candidate_stage_source_choices_aggregate';
  aggregate: Maybe<Candidate_Stage_Source_Choices_Aggregate_Fields>;
  nodes: Array<Candidate_Stage_Source_Choices>;
};

/** aggregate fields of "candidate_stage_source_choices" */
export type Candidate_Stage_Source_Choices_Aggregate_Fields = {
  __typename?: 'candidate_stage_source_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Candidate_Stage_Source_Choices_Max_Fields>;
  min: Maybe<Candidate_Stage_Source_Choices_Min_Fields>;
};

/** aggregate fields of "candidate_stage_source_choices" */
export type Candidate_Stage_Source_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Stage_Source_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "candidate_stage_source_choices". All fields are combined with a logical 'AND'. */
export type Candidate_Stage_Source_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Stage_Source_Choices_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Stage_Source_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Stage_Source_Choices_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_stage_source_choices" */
export enum Candidate_Stage_Source_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateStageSourceChoicesPkey = 'candidate_stage_source_choices_pkey',
}

export enum Candidate_Stage_Source_Choices_Enum {
  /** Candidate finished an assessment. */
  CandidateAssessmentUpdate = 'CANDIDATE_ASSESSMENT_UPDATE',
  /** Candidate profile updated important information. */
  CandidateProfileUpdate = 'CANDIDATE_PROFILE_UPDATE',
  /** Gem updated candidate information. */
  GemUpdate = 'GEM_UPDATE',
  /** iCIMS workflow status changed for one of the candidate's workflows. */
  IcimsApplicantWorkflowStatusChange = 'ICIMS_APPLICANT_WORKFLOW_STATUS_CHANGE',
  /** Candidate was recently added and stage has not yet been evaluated. */
  New = 'NEW',
}

/** Boolean expression to compare columns of type "candidate_stage_source_choices_enum". All fields are combined with logical 'AND'. */
export type Candidate_Stage_Source_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Candidate_Stage_Source_Choices_Enum>;
  _in?: InputMaybe<Array<Candidate_Stage_Source_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Candidate_Stage_Source_Choices_Enum>;
  _nin?: InputMaybe<Array<Candidate_Stage_Source_Choices_Enum>>;
};

/** input type for inserting data into table "candidate_stage_source_choices" */
export type Candidate_Stage_Source_Choices_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Stage_Source_Choices_Max_Fields = {
  __typename?: 'candidate_stage_source_choices_max_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Candidate_Stage_Source_Choices_Min_Fields = {
  __typename?: 'candidate_stage_source_choices_min_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "candidate_stage_source_choices" */
export type Candidate_Stage_Source_Choices_Mutation_Response = {
  __typename?: 'candidate_stage_source_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Stage_Source_Choices>;
};

/** on_conflict condition type for table "candidate_stage_source_choices" */
export type Candidate_Stage_Source_Choices_On_Conflict = {
  constraint: Candidate_Stage_Source_Choices_Constraint;
  update_columns?: Array<Candidate_Stage_Source_Choices_Update_Column>;
  where?: InputMaybe<Candidate_Stage_Source_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_stage_source_choices". */
export type Candidate_Stage_Source_Choices_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_stage_source_choices */
export type Candidate_Stage_Source_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "candidate_stage_source_choices" */
export enum Candidate_Stage_Source_Choices_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "candidate_stage_source_choices" */
export type Candidate_Stage_Source_Choices_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "candidate_stage_source_choices" */
export type Candidate_Stage_Source_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Stage_Source_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Stage_Source_Choices_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "candidate_stage_source_choices" */
export enum Candidate_Stage_Source_Choices_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Candidate_Stage_Source_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Stage_Source_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Stage_Source_Choices_Bool_Exp;
};

/** aggregate stddev on columns */
export type Candidate_Stddev_Fields = {
  __typename?: 'candidate_stddev_fields';
  country_id: Maybe<Scalars['Float']>;
  furthest_candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  owner: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate" */
export type Candidate_Stddev_Order_By = {
  country_id?: InputMaybe<Order_By>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Stddev_Pop_Fields = {
  __typename?: 'candidate_stddev_pop_fields';
  country_id: Maybe<Scalars['Float']>;
  furthest_candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  owner: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate" */
export type Candidate_Stddev_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Stddev_Samp_Fields = {
  __typename?: 'candidate_stddev_samp_fields';
  country_id: Maybe<Scalars['Float']>;
  furthest_candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  owner: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate" */
export type Candidate_Stddev_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate" */
export type Candidate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Stream_Cursor_Value_Input = {
  availability?: InputMaybe<Candidate_Availability_Choices_Enum>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  first_name?: InputMaybe<Scalars['String']>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Scalars['Int']>;
  furthest_stage?: InputMaybe<Candidate_Stage_Choices_Enum>;
  gem_id?: InputMaybe<Scalars['String']>;
  github_url?: InputMaybe<Scalars['String']>;
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_name_initial?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['numeric']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['numeric']>;
  merge_candidate_id?: InputMaybe<Scalars['String']>;
  open_to_all_companies?: InputMaybe<Scalars['Boolean']>;
  other_url?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  profile_complete_override?: InputMaybe<Scalars['Boolean']>;
  profile_flow_completed_at?: InputMaybe<Scalars['timestamptz']>;
  profile_flow_furthest_step?: InputMaybe<Scalars['String']>;
  profile_flow_started_at?: InputMaybe<Scalars['timestamptz']>;
  profile_flow_variations?: InputMaybe<Scalars['jsonb']>;
  public_id?: InputMaybe<Scalars['uuid']>;
  resume_filename?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  stage?: InputMaybe<Candidate_Stage_Choices_Enum>;
  stage_source?: InputMaybe<Candidate_Stage_Source_Choices_Enum>;
  state?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

export type Candidate_Suggested_Job_Args = {
  candidate_id?: InputMaybe<Scalars['Int']>;
};

export type Candidate_Suggested_Job_Core_Args = {
  candidate_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidate_Sum_Fields = {
  __typename?: 'candidate_sum_fields';
  country_id: Maybe<Scalars['Int']>;
  furthest_candidate_curation_workflow_id: Maybe<Scalars['Int']>;
  icims_person_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  latitude: Maybe<Scalars['numeric']>;
  longitude: Maybe<Scalars['numeric']>;
  owner: Maybe<Scalars['Int']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate" */
export type Candidate_Sum_Order_By = {
  country_id?: InputMaybe<Order_By>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "candidate_trait" */
export type Candidate_Trait = {
  __typename?: 'candidate_trait';
  candidate_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  meta_data: Maybe<Scalars['jsonb']>;
  source: Scalars['String'];
  status: Scalars['String'];
  trait: Trait_Enum;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "candidate_trait" */
export type Candidate_TraitMeta_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "candidate_trait" */
export type Candidate_Trait_Aggregate = {
  __typename?: 'candidate_trait_aggregate';
  aggregate: Maybe<Candidate_Trait_Aggregate_Fields>;
  nodes: Array<Candidate_Trait>;
};

/** aggregate fields of "candidate_trait" */
export type Candidate_Trait_Aggregate_Fields = {
  __typename?: 'candidate_trait_aggregate_fields';
  avg: Maybe<Candidate_Trait_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Trait_Max_Fields>;
  min: Maybe<Candidate_Trait_Min_Fields>;
  stddev: Maybe<Candidate_Trait_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Trait_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Trait_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Trait_Sum_Fields>;
  var_pop: Maybe<Candidate_Trait_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Trait_Var_Samp_Fields>;
  variance: Maybe<Candidate_Trait_Variance_Fields>;
};

/** aggregate fields of "candidate_trait" */
export type Candidate_Trait_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Trait_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Trait_Append_Input = {
  meta_data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Candidate_Trait_Avg_Fields = {
  __typename?: 'candidate_trait_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidate_trait". All fields are combined with a logical 'AND'. */
export type Candidate_Trait_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Trait_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Trait_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Trait_Bool_Exp>>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  meta_data?: InputMaybe<Jsonb_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  trait?: InputMaybe<Trait_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_trait" */
export enum Candidate_Trait_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidateTraitPkey = 'candidate_trait_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Candidate_Trait_Delete_At_Path_Input = {
  meta_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Candidate_Trait_Delete_Elem_Input = {
  meta_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Candidate_Trait_Delete_Key_Input = {
  meta_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "candidate_trait" */
export type Candidate_Trait_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_trait" */
export type Candidate_Trait_Insert_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  meta_data?: InputMaybe<Scalars['jsonb']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  trait?: InputMaybe<Trait_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Candidate_Trait_Max_Fields = {
  __typename?: 'candidate_trait_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  source: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Candidate_Trait_Min_Fields = {
  __typename?: 'candidate_trait_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  source: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "candidate_trait" */
export type Candidate_Trait_Mutation_Response = {
  __typename?: 'candidate_trait_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Trait>;
};

/** on_conflict condition type for table "candidate_trait" */
export type Candidate_Trait_On_Conflict = {
  constraint: Candidate_Trait_Constraint;
  update_columns?: Array<Candidate_Trait_Update_Column>;
  where?: InputMaybe<Candidate_Trait_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_trait". */
export type Candidate_Trait_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_data?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trait?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_trait */
export type Candidate_Trait_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Trait_Prepend_Input = {
  meta_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "candidate_trait" */
export enum Candidate_Trait_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetaData = 'meta_data',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  Trait = 'trait',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "candidate_trait" */
export type Candidate_Trait_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  meta_data?: InputMaybe<Scalars['jsonb']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  trait?: InputMaybe<Trait_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Candidate_Trait_Stddev_Fields = {
  __typename?: 'candidate_trait_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Trait_Stddev_Pop_Fields = {
  __typename?: 'candidate_trait_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Trait_Stddev_Samp_Fields = {
  __typename?: 'candidate_trait_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidate_trait" */
export type Candidate_Trait_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Trait_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Trait_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  meta_data?: InputMaybe<Scalars['jsonb']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  trait?: InputMaybe<Trait_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Candidate_Trait_Sum_Fields = {
  __typename?: 'candidate_trait_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "candidate_trait" */
export enum Candidate_Trait_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetaData = 'meta_data',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  Trait = 'trait',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Candidate_Trait_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Candidate_Trait_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Candidate_Trait_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Candidate_Trait_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Candidate_Trait_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Trait_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Candidate_Trait_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Trait_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Trait_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Trait_Var_Pop_Fields = {
  __typename?: 'candidate_trait_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidate_Trait_Var_Samp_Fields = {
  __typename?: 'candidate_trait_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidate_Trait_Variance_Fields = {
  __typename?: 'candidate_trait_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** update columns of table "candidate" */
export enum Candidate_Update_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FurthestCandidateCurationWorkflowId = 'furthest_candidate_curation_workflow_id',
  /** column name */
  FurthestStage = 'furthest_stage',
  /** column name */
  GemId = 'gem_id',
  /** column name */
  GithubUrl = 'github_url',
  /** column name */
  IcimsPersonProfileId = 'icims_person_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  MergeCandidateId = 'merge_candidate_id',
  /** column name */
  OpenToAllCompanies = 'open_to_all_companies',
  /** column name */
  OtherUrl = 'other_url',
  /** column name */
  Owner = 'owner',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfileCompleteOverride = 'profile_complete_override',
  /** column name */
  ProfileFlowCompletedAt = 'profile_flow_completed_at',
  /** column name */
  ProfileFlowFurthestStep = 'profile_flow_furthest_step',
  /** column name */
  ProfileFlowStartedAt = 'profile_flow_started_at',
  /** column name */
  ProfileFlowVariations = 'profile_flow_variations',
  /** column name */
  PublicId = 'public_id',
  /** column name */
  ResumeFilename = 'resume_filename',
  /** column name */
  Source = 'source',
  /** column name */
  Stage = 'stage',
  /** column name */
  StageSource = 'stage_source',
  /** column name */
  State = 'state',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id',
}

export type Candidate_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Candidate_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Candidate_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Candidate_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Candidate_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Candidate_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Var_Pop_Fields = {
  __typename?: 'candidate_var_pop_fields';
  country_id: Maybe<Scalars['Float']>;
  furthest_candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  owner: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate" */
export type Candidate_Var_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Var_Samp_Fields = {
  __typename?: 'candidate_var_samp_fields';
  country_id: Maybe<Scalars['Float']>;
  furthest_candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  owner: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate" */
export type Candidate_Var_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Variance_Fields = {
  __typename?: 'candidate_variance_fields';
  country_id: Maybe<Scalars['Float']>;
  furthest_candidate_curation_workflow_id: Maybe<Scalars['Float']>;
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  owner: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate" */
export type Candidate_Variance_Order_By = {
  country_id?: InputMaybe<Order_By>;
  furthest_candidate_curation_workflow_id?: InputMaybe<Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "candidate_work_experience" */
export type Candidate_Work_Experience = {
  __typename?: 'candidate_work_experience';
  ai_all_skills: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  ai_level: Maybe<Ai_Level>;
  ai_level_id: Maybe<Scalars['Int']>;
  ai_management: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  ai_role: Maybe<Ai_Role>;
  ai_role_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  ai_specialty_role: Maybe<Ai_Role>;
  ai_specialty_role_id: Maybe<Scalars['Int']>;
  ai_team_lead: Maybe<Scalars['Boolean']>;
  ai_tech_lead: Maybe<Scalars['Boolean']>;
  ai_title_skill: Maybe<Scalars['String']>;
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  /** An array relationship */
  candidate_work_experience_skills: Array<Candidate_Work_Experience_Skill>;
  /** An aggregate relationship */
  candidate_work_experience_skills_aggregate: Candidate_Work_Experience_Skill_Aggregate;
  /** An object relationship */
  company: Maybe<Company>;
  company_id: Maybe<Scalars['Int']>;
  company_name: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  currently_working: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  job_title: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
  source: Candidate_Source_Choices_Enum;
  start_date: Maybe<Scalars['date']>;
  /** An array relationship */
  transcript_work_experiences: Array<Transcript_Work_Experience>;
  /** An aggregate relationship */
  transcript_work_experiences_aggregate: Transcript_Work_Experience_Aggregate;
  updated_at: Scalars['timestamptz'];
  visible_to_candidate: Scalars['Boolean'];
};

/** columns and relationships of "candidate_work_experience" */
export type Candidate_Work_ExperienceAi_All_SkillsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "candidate_work_experience" */
export type Candidate_Work_ExperienceCandidate_Work_Experience_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Skill_Bool_Exp>;
};

/** columns and relationships of "candidate_work_experience" */
export type Candidate_Work_ExperienceCandidate_Work_Experience_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Skill_Bool_Exp>;
};

/** columns and relationships of "candidate_work_experience" */
export type Candidate_Work_ExperienceTranscript_Work_ExperiencesArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Work_Experience_Order_By>>;
  where?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
};

/** columns and relationships of "candidate_work_experience" */
export type Candidate_Work_ExperienceTranscript_Work_Experiences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Work_Experience_Order_By>>;
  where?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
};

/** aggregated selection of "candidate_work_experience" */
export type Candidate_Work_Experience_Aggregate = {
  __typename?: 'candidate_work_experience_aggregate';
  aggregate: Maybe<Candidate_Work_Experience_Aggregate_Fields>;
  nodes: Array<Candidate_Work_Experience>;
};

export type Candidate_Work_Experience_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Candidate_Work_Experience_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Candidate_Work_Experience_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Candidate_Work_Experience_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Work_Experience_Aggregate_Bool_Exp_Bool_And = {
  arguments: Candidate_Work_Experience_Select_Column_Candidate_Work_Experience_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Work_Experience_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Candidate_Work_Experience_Select_Column_Candidate_Work_Experience_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Candidate_Work_Experience_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Work_Experience_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_work_experience" */
export type Candidate_Work_Experience_Aggregate_Fields = {
  __typename?: 'candidate_work_experience_aggregate_fields';
  avg: Maybe<Candidate_Work_Experience_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Work_Experience_Max_Fields>;
  min: Maybe<Candidate_Work_Experience_Min_Fields>;
  stddev: Maybe<Candidate_Work_Experience_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Work_Experience_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Work_Experience_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Work_Experience_Sum_Fields>;
  var_pop: Maybe<Candidate_Work_Experience_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Work_Experience_Var_Samp_Fields>;
  variance: Maybe<Candidate_Work_Experience_Variance_Fields>;
};

/** aggregate fields of "candidate_work_experience" */
export type Candidate_Work_Experience_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Work_Experience_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_work_experience" */
export type Candidate_Work_Experience_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Work_Experience_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Work_Experience_Max_Order_By>;
  min?: InputMaybe<Candidate_Work_Experience_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Work_Experience_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Work_Experience_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Work_Experience_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Work_Experience_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Work_Experience_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Work_Experience_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Work_Experience_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Work_Experience_Append_Input = {
  ai_all_skills?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "candidate_work_experience" */
export type Candidate_Work_Experience_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Work_Experience_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Work_Experience_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Work_Experience_Avg_Fields = {
  __typename?: 'candidate_work_experience_avg_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_work_experience" */
export type Candidate_Work_Experience_Avg_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_work_experience". All fields are combined with a logical 'AND'. */
export type Candidate_Work_Experience_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Work_Experience_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Work_Experience_Bool_Exp>>;
  ai_all_skills?: InputMaybe<Jsonb_Comparison_Exp>;
  ai_level?: InputMaybe<Ai_Level_Bool_Exp>;
  ai_level_id?: InputMaybe<Int_Comparison_Exp>;
  ai_management?: InputMaybe<Boolean_Comparison_Exp>;
  ai_role?: InputMaybe<Ai_Role_Bool_Exp>;
  ai_role_id?: InputMaybe<Int_Comparison_Exp>;
  ai_specialty_role?: InputMaybe<Ai_Role_Bool_Exp>;
  ai_specialty_role_id?: InputMaybe<Int_Comparison_Exp>;
  ai_team_lead?: InputMaybe<Boolean_Comparison_Exp>;
  ai_tech_lead?: InputMaybe<Boolean_Comparison_Exp>;
  ai_title_skill?: InputMaybe<String_Comparison_Exp>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  candidate_work_experience_skills?: InputMaybe<Candidate_Work_Experience_Skill_Bool_Exp>;
  candidate_work_experience_skills_aggregate?: InputMaybe<Candidate_Work_Experience_Skill_Aggregate_Bool_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currently_working?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job_title?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<Candidate_Source_Choices_Enum_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  transcript_work_experiences?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
  transcript_work_experiences_aggregate?: InputMaybe<Transcript_Work_Experience_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visible_to_candidate?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_work_experience" */
export enum Candidate_Work_Experience_Constraint {
  /** unique or primary key constraint on columns "id", "candidate_id" */
  CandidateWorkExperienceIdCandidateIdKey = 'candidate_work_experience_id_candidate_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidateWorkExperiencePkey = 'candidate_work_experience_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Candidate_Work_Experience_Delete_At_Path_Input = {
  ai_all_skills?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Candidate_Work_Experience_Delete_Elem_Input = {
  ai_all_skills?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Candidate_Work_Experience_Delete_Key_Input = {
  ai_all_skills?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "candidate_work_experience" */
export type Candidate_Work_Experience_Inc_Input = {
  ai_level_id?: InputMaybe<Scalars['Int']>;
  ai_role_id?: InputMaybe<Scalars['Int']>;
  ai_specialty_role_id?: InputMaybe<Scalars['Int']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_work_experience" */
export type Candidate_Work_Experience_Insert_Input = {
  ai_all_skills?: InputMaybe<Scalars['jsonb']>;
  ai_level?: InputMaybe<Ai_Level_Obj_Rel_Insert_Input>;
  ai_level_id?: InputMaybe<Scalars['Int']>;
  ai_management?: InputMaybe<Scalars['Boolean']>;
  ai_role?: InputMaybe<Ai_Role_Obj_Rel_Insert_Input>;
  ai_role_id?: InputMaybe<Scalars['Int']>;
  ai_specialty_role?: InputMaybe<Ai_Role_Obj_Rel_Insert_Input>;
  ai_specialty_role_id?: InputMaybe<Scalars['Int']>;
  ai_team_lead?: InputMaybe<Scalars['Boolean']>;
  ai_tech_lead?: InputMaybe<Scalars['Boolean']>;
  ai_title_skill?: InputMaybe<Scalars['String']>;
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_work_experience_skills?: InputMaybe<Candidate_Work_Experience_Skill_Arr_Rel_Insert_Input>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currently_working?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  job_title?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  start_date?: InputMaybe<Scalars['date']>;
  transcript_work_experiences?: InputMaybe<Transcript_Work_Experience_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Candidate_Work_Experience_Max_Fields = {
  __typename?: 'candidate_work_experience_max_fields';
  ai_level_id: Maybe<Scalars['Int']>;
  ai_role_id: Maybe<Scalars['Int']>;
  ai_specialty_role_id: Maybe<Scalars['Int']>;
  ai_title_skill: Maybe<Scalars['String']>;
  candidate_id: Maybe<Scalars['Int']>;
  company_id: Maybe<Scalars['Int']>;
  company_name: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['date']>;
  id: Maybe<Scalars['Int']>;
  job_title: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
  start_date: Maybe<Scalars['date']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "candidate_work_experience" */
export type Candidate_Work_Experience_Max_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  ai_title_skill?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_title?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Work_Experience_Min_Fields = {
  __typename?: 'candidate_work_experience_min_fields';
  ai_level_id: Maybe<Scalars['Int']>;
  ai_role_id: Maybe<Scalars['Int']>;
  ai_specialty_role_id: Maybe<Scalars['Int']>;
  ai_title_skill: Maybe<Scalars['String']>;
  candidate_id: Maybe<Scalars['Int']>;
  company_id: Maybe<Scalars['Int']>;
  company_name: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  end_date: Maybe<Scalars['date']>;
  id: Maybe<Scalars['Int']>;
  job_title: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
  start_date: Maybe<Scalars['date']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "candidate_work_experience" */
export type Candidate_Work_Experience_Min_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  ai_title_skill?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_title?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_work_experience" */
export type Candidate_Work_Experience_Mutation_Response = {
  __typename?: 'candidate_work_experience_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Work_Experience>;
};

/** input type for inserting object relation for remote table "candidate_work_experience" */
export type Candidate_Work_Experience_Obj_Rel_Insert_Input = {
  data: Candidate_Work_Experience_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Work_Experience_On_Conflict>;
};

/** on_conflict condition type for table "candidate_work_experience" */
export type Candidate_Work_Experience_On_Conflict = {
  constraint: Candidate_Work_Experience_Constraint;
  update_columns?: Array<Candidate_Work_Experience_Update_Column>;
  where?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_work_experience". */
export type Candidate_Work_Experience_Order_By = {
  ai_all_skills?: InputMaybe<Order_By>;
  ai_level?: InputMaybe<Ai_Level_Order_By>;
  ai_level_id?: InputMaybe<Order_By>;
  ai_management?: InputMaybe<Order_By>;
  ai_role?: InputMaybe<Ai_Role_Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role?: InputMaybe<Ai_Role_Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  ai_team_lead?: InputMaybe<Order_By>;
  ai_tech_lead?: InputMaybe<Order_By>;
  ai_title_skill?: InputMaybe<Order_By>;
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience_skills_aggregate?: InputMaybe<Candidate_Work_Experience_Skill_Aggregate_Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currently_working?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_title?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  transcript_work_experiences_aggregate?: InputMaybe<Transcript_Work_Experience_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visible_to_candidate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_work_experience */
export type Candidate_Work_Experience_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Candidate_Work_Experience_Prepend_Input = {
  ai_all_skills?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "candidate_work_experience" */
export enum Candidate_Work_Experience_Select_Column {
  /** column name */
  AiAllSkills = 'ai_all_skills',
  /** column name */
  AiLevelId = 'ai_level_id',
  /** column name */
  AiManagement = 'ai_management',
  /** column name */
  AiRoleId = 'ai_role_id',
  /** column name */
  AiSpecialtyRoleId = 'ai_specialty_role_id',
  /** column name */
  AiTeamLead = 'ai_team_lead',
  /** column name */
  AiTechLead = 'ai_tech_lead',
  /** column name */
  AiTitleSkill = 'ai_title_skill',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentlyWorking = 'currently_working',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitle = 'job_title',
  /** column name */
  Location = 'location',
  /** column name */
  Source = 'source',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** select "candidate_work_experience_aggregate_bool_exp_bool_and_arguments_columns" columns of table "candidate_work_experience" */
export enum Candidate_Work_Experience_Select_Column_Candidate_Work_Experience_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AiManagement = 'ai_management',
  /** column name */
  AiTeamLead = 'ai_team_lead',
  /** column name */
  AiTechLead = 'ai_tech_lead',
  /** column name */
  CurrentlyWorking = 'currently_working',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** select "candidate_work_experience_aggregate_bool_exp_bool_or_arguments_columns" columns of table "candidate_work_experience" */
export enum Candidate_Work_Experience_Select_Column_Candidate_Work_Experience_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AiManagement = 'ai_management',
  /** column name */
  AiTeamLead = 'ai_team_lead',
  /** column name */
  AiTechLead = 'ai_tech_lead',
  /** column name */
  CurrentlyWorking = 'currently_working',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

/** input type for updating data in table "candidate_work_experience" */
export type Candidate_Work_Experience_Set_Input = {
  ai_all_skills?: InputMaybe<Scalars['jsonb']>;
  ai_level_id?: InputMaybe<Scalars['Int']>;
  ai_management?: InputMaybe<Scalars['Boolean']>;
  ai_role_id?: InputMaybe<Scalars['Int']>;
  ai_specialty_role_id?: InputMaybe<Scalars['Int']>;
  ai_team_lead?: InputMaybe<Scalars['Boolean']>;
  ai_tech_lead?: InputMaybe<Scalars['Boolean']>;
  ai_title_skill?: InputMaybe<Scalars['String']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currently_working?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  job_title?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
};

/** Attach skills to work experiences */
export type Candidate_Work_Experience_Skill = {
  __typename?: 'candidate_work_experience_skill';
  /** An object relationship */
  candidate_work_experience: Candidate_Work_Experience;
  candidate_work_experience_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
  source: Scalars['String'];
};

/** aggregated selection of "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Aggregate = {
  __typename?: 'candidate_work_experience_skill_aggregate';
  aggregate: Maybe<Candidate_Work_Experience_Skill_Aggregate_Fields>;
  nodes: Array<Candidate_Work_Experience_Skill>;
};

export type Candidate_Work_Experience_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Candidate_Work_Experience_Skill_Aggregate_Bool_Exp_Count>;
};

export type Candidate_Work_Experience_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Candidate_Work_Experience_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Candidate_Work_Experience_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Aggregate_Fields = {
  __typename?: 'candidate_work_experience_skill_aggregate_fields';
  avg: Maybe<Candidate_Work_Experience_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidate_Work_Experience_Skill_Max_Fields>;
  min: Maybe<Candidate_Work_Experience_Skill_Min_Fields>;
  stddev: Maybe<Candidate_Work_Experience_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Candidate_Work_Experience_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidate_Work_Experience_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Candidate_Work_Experience_Skill_Sum_Fields>;
  var_pop: Maybe<Candidate_Work_Experience_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Candidate_Work_Experience_Skill_Var_Samp_Fields>;
  variance: Maybe<Candidate_Work_Experience_Skill_Variance_Fields>;
};

/** aggregate fields of "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Candidate_Work_Experience_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Candidate_Work_Experience_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Candidate_Work_Experience_Skill_Max_Order_By>;
  min?: InputMaybe<Candidate_Work_Experience_Skill_Min_Order_By>;
  stddev?: InputMaybe<Candidate_Work_Experience_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Candidate_Work_Experience_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Candidate_Work_Experience_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Candidate_Work_Experience_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Candidate_Work_Experience_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Candidate_Work_Experience_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Candidate_Work_Experience_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Arr_Rel_Insert_Input = {
  data: Array<Candidate_Work_Experience_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Candidate_Work_Experience_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Candidate_Work_Experience_Skill_Avg_Fields = {
  __typename?: 'candidate_work_experience_skill_avg_fields';
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Avg_Order_By = {
  candidate_work_experience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "candidate_work_experience_skill". All fields are combined with a logical 'AND'. */
export type Candidate_Work_Experience_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Candidate_Work_Experience_Skill_Bool_Exp>>;
  _not?: InputMaybe<Candidate_Work_Experience_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Candidate_Work_Experience_Skill_Bool_Exp>>;
  candidate_work_experience?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
  candidate_work_experience_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "candidate_work_experience_skill" */
export enum Candidate_Work_Experience_Skill_Constraint {
  /** unique or primary key constraint on columns "skill_id", "candidate_work_experience_id" */
  CandidateWorkExperienceSkiCandidateWorkExperienceIdKey = 'candidate_work_experience_ski_candidate_work_experience_id__key',
  /** unique or primary key constraint on columns "id" */
  CandidateWorkExperienceSkillPkey = 'candidate_work_experience_skill_pkey',
}

/** input type for incrementing numeric columns in table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Inc_Input = {
  candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Insert_Input = {
  candidate_work_experience?: InputMaybe<Candidate_Work_Experience_Obj_Rel_Insert_Input>;
  candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Candidate_Work_Experience_Skill_Max_Fields = {
  __typename?: 'candidate_work_experience_skill_max_fields';
  candidate_work_experience_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  source: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Max_Order_By = {
  candidate_work_experience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Candidate_Work_Experience_Skill_Min_Fields = {
  __typename?: 'candidate_work_experience_skill_min_fields';
  candidate_work_experience_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  source: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Min_Order_By = {
  candidate_work_experience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Mutation_Response = {
  __typename?: 'candidate_work_experience_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidate_Work_Experience_Skill>;
};

/** on_conflict condition type for table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_On_Conflict = {
  constraint: Candidate_Work_Experience_Skill_Constraint;
  update_columns?: Array<Candidate_Work_Experience_Skill_Update_Column>;
  where?: InputMaybe<Candidate_Work_Experience_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "candidate_work_experience_skill". */
export type Candidate_Work_Experience_Skill_Order_By = {
  candidate_work_experience?: InputMaybe<Candidate_Work_Experience_Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
};

/** primary key columns input for table: candidate_work_experience_skill */
export type Candidate_Work_Experience_Skill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "candidate_work_experience_skill" */
export enum Candidate_Work_Experience_Skill_Select_Column {
  /** column name */
  CandidateWorkExperienceId = 'candidate_work_experience_id',
  /** column name */
  Id = 'id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  Source = 'source',
}

/** input type for updating data in table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Set_Input = {
  candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Candidate_Work_Experience_Skill_Stddev_Fields = {
  __typename?: 'candidate_work_experience_skill_stddev_fields';
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Stddev_Order_By = {
  candidate_work_experience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Work_Experience_Skill_Stddev_Pop_Fields = {
  __typename?: 'candidate_work_experience_skill_stddev_pop_fields';
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Stddev_Pop_Order_By = {
  candidate_work_experience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Work_Experience_Skill_Stddev_Samp_Fields = {
  __typename?: 'candidate_work_experience_skill_stddev_samp_fields';
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Stddev_Samp_Order_By = {
  candidate_work_experience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Work_Experience_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Work_Experience_Skill_Stream_Cursor_Value_Input = {
  candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Candidate_Work_Experience_Skill_Sum_Fields = {
  __typename?: 'candidate_work_experience_skill_sum_fields';
  candidate_work_experience_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Sum_Order_By = {
  candidate_work_experience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_work_experience_skill" */
export enum Candidate_Work_Experience_Skill_Update_Column {
  /** column name */
  CandidateWorkExperienceId = 'candidate_work_experience_id',
  /** column name */
  Id = 'id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  Source = 'source',
}

export type Candidate_Work_Experience_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Work_Experience_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Work_Experience_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Work_Experience_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Work_Experience_Skill_Var_Pop_Fields = {
  __typename?: 'candidate_work_experience_skill_var_pop_fields';
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Var_Pop_Order_By = {
  candidate_work_experience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Work_Experience_Skill_Var_Samp_Fields = {
  __typename?: 'candidate_work_experience_skill_var_samp_fields';
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Var_Samp_Order_By = {
  candidate_work_experience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Work_Experience_Skill_Variance_Fields = {
  __typename?: 'candidate_work_experience_skill_variance_fields';
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_work_experience_skill" */
export type Candidate_Work_Experience_Skill_Variance_Order_By = {
  candidate_work_experience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Candidate_Work_Experience_Stddev_Fields = {
  __typename?: 'candidate_work_experience_stddev_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "candidate_work_experience" */
export type Candidate_Work_Experience_Stddev_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Candidate_Work_Experience_Stddev_Pop_Fields = {
  __typename?: 'candidate_work_experience_stddev_pop_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "candidate_work_experience" */
export type Candidate_Work_Experience_Stddev_Pop_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Candidate_Work_Experience_Stddev_Samp_Fields = {
  __typename?: 'candidate_work_experience_stddev_samp_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "candidate_work_experience" */
export type Candidate_Work_Experience_Stddev_Samp_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "candidate_work_experience" */
export type Candidate_Work_Experience_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidate_Work_Experience_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidate_Work_Experience_Stream_Cursor_Value_Input = {
  ai_all_skills?: InputMaybe<Scalars['jsonb']>;
  ai_level_id?: InputMaybe<Scalars['Int']>;
  ai_management?: InputMaybe<Scalars['Boolean']>;
  ai_role_id?: InputMaybe<Scalars['Int']>;
  ai_specialty_role_id?: InputMaybe<Scalars['Int']>;
  ai_team_lead?: InputMaybe<Scalars['Boolean']>;
  ai_tech_lead?: InputMaybe<Scalars['Boolean']>;
  ai_title_skill?: InputMaybe<Scalars['String']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  company_id?: InputMaybe<Scalars['Int']>;
  company_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currently_working?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  job_title?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Candidate_Source_Choices_Enum>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible_to_candidate?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Candidate_Work_Experience_Sum_Fields = {
  __typename?: 'candidate_work_experience_sum_fields';
  ai_level_id: Maybe<Scalars['Int']>;
  ai_role_id: Maybe<Scalars['Int']>;
  ai_specialty_role_id: Maybe<Scalars['Int']>;
  candidate_id: Maybe<Scalars['Int']>;
  company_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "candidate_work_experience" */
export type Candidate_Work_Experience_Sum_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "candidate_work_experience" */
export enum Candidate_Work_Experience_Update_Column {
  /** column name */
  AiAllSkills = 'ai_all_skills',
  /** column name */
  AiLevelId = 'ai_level_id',
  /** column name */
  AiManagement = 'ai_management',
  /** column name */
  AiRoleId = 'ai_role_id',
  /** column name */
  AiSpecialtyRoleId = 'ai_specialty_role_id',
  /** column name */
  AiTeamLead = 'ai_team_lead',
  /** column name */
  AiTechLead = 'ai_tech_lead',
  /** column name */
  AiTitleSkill = 'ai_title_skill',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentlyWorking = 'currently_working',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitle = 'job_title',
  /** column name */
  Location = 'location',
  /** column name */
  Source = 'source',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VisibleToCandidate = 'visible_to_candidate',
}

export type Candidate_Work_Experience_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Candidate_Work_Experience_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Candidate_Work_Experience_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Candidate_Work_Experience_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Candidate_Work_Experience_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidate_Work_Experience_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Candidate_Work_Experience_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidate_Work_Experience_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidate_Work_Experience_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidate_Work_Experience_Var_Pop_Fields = {
  __typename?: 'candidate_work_experience_var_pop_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "candidate_work_experience" */
export type Candidate_Work_Experience_Var_Pop_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Candidate_Work_Experience_Var_Samp_Fields = {
  __typename?: 'candidate_work_experience_var_samp_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "candidate_work_experience" */
export type Candidate_Work_Experience_Var_Samp_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Candidate_Work_Experience_Variance_Fields = {
  __typename?: 'candidate_work_experience_variance_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "candidate_work_experience" */
export type Candidate_Work_Experience_Variance_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Candidates_In_Interview_Or_Rejected_By_Organization_Args = {
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** This table is only used for its Hasura generated type, don't populate it. */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result';
  icims_person_profile_id: Maybe<Scalars['Int']>;
};

export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Aggregate = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_aggregate';
  aggregate: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Aggregate_Fields>;
  nodes: Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result>;
};

/** aggregate fields of "candidates_in_interview_or_rejected_by_organization_result" */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Aggregate_Fields = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_aggregate_fields';
  avg: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Max_Fields>;
  min: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Min_Fields>;
  stddev: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Stddev_Fields>;
  stddev_pop: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Stddev_Samp_Fields>;
  sum: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Sum_Fields>;
  var_pop: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Var_Pop_Fields>;
  var_samp: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Var_Samp_Fields>;
  variance: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Variance_Fields>;
};

/** aggregate fields of "candidates_in_interview_or_rejected_by_organization_result" */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Avg_Fields = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_avg_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "candidates_in_interview_or_rejected_by_organization_result". All fields are combined with a logical 'AND'. */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>>;
  _not?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>>;
  icims_person_profile_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "candidates_in_interview_or_rejected_by_organization_result" */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Inc_Input = {
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "candidates_in_interview_or_rejected_by_organization_result" */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Insert_Input = {
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Max_Fields = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_max_fields';
  icims_person_profile_id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Min_Fields = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_min_fields';
  icims_person_profile_id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "candidates_in_interview_or_rejected_by_organization_result" */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Mutation_Response = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result>;
};

/** Ordering options when selecting data from "candidates_in_interview_or_rejected_by_organization_result". */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Order_By = {
  icims_person_profile_id?: InputMaybe<Order_By>;
};

/** select columns of table "candidates_in_interview_or_rejected_by_organization_result" */
export enum Candidates_In_Interview_Or_Rejected_By_Organization_Result_Select_Column {
  /** column name */
  IcimsPersonProfileId = 'icims_person_profile_id',
}

/** input type for updating data in table "candidates_in_interview_or_rejected_by_organization_result" */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Set_Input = {
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Stddev_Fields = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_stddev_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Stddev_Pop_Fields = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_stddev_pop_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Stddev_Samp_Fields = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_stddev_samp_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "candidates_in_interview_or_rejected_by_organization_result" */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Candidates_In_Interview_Or_Rejected_By_Organization_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Stream_Cursor_Value_Input = {
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Sum_Fields = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_sum_fields';
  icims_person_profile_id: Maybe<Scalars['Int']>;
};

export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Var_Pop_Fields = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_var_pop_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Var_Samp_Fields = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_var_samp_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Candidates_In_Interview_Or_Rejected_By_Organization_Result_Variance_Fields = {
  __typename?: 'candidates_in_interview_or_rejected_by_organization_result_variance_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "celery.celery_taskmeta" */
export type Celery_Celery_Taskmeta = {
  __typename?: 'celery_celery_taskmeta';
  args: Maybe<Scalars['bytea']>;
  date_done: Maybe<Scalars['timestamp']>;
  id: Scalars['Int'];
  kwargs: Maybe<Scalars['bytea']>;
  name: Maybe<Scalars['String']>;
  queue: Maybe<Scalars['String']>;
  result: Maybe<Scalars['bytea']>;
  retries: Maybe<Scalars['Int']>;
  /** An array relationship */
  retry_logs: Array<Celery_Task_Retry_Log>;
  /** An aggregate relationship */
  retry_logs_aggregate: Celery_Task_Retry_Log_Aggregate;
  status: Maybe<Scalars['String']>;
  task_id: Maybe<Scalars['String']>;
  traceback: Maybe<Scalars['String']>;
  worker: Maybe<Scalars['String']>;
};

/** columns and relationships of "celery.celery_taskmeta" */
export type Celery_Celery_TaskmetaRetry_LogsArgs = {
  distinct_on?: InputMaybe<Array<Celery_Task_Retry_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Task_Retry_Log_Order_By>>;
  where?: InputMaybe<Celery_Task_Retry_Log_Bool_Exp>;
};

/** columns and relationships of "celery.celery_taskmeta" */
export type Celery_Celery_TaskmetaRetry_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Celery_Task_Retry_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Task_Retry_Log_Order_By>>;
  where?: InputMaybe<Celery_Task_Retry_Log_Bool_Exp>;
};

/** aggregated selection of "celery.celery_taskmeta" */
export type Celery_Celery_Taskmeta_Aggregate = {
  __typename?: 'celery_celery_taskmeta_aggregate';
  aggregate: Maybe<Celery_Celery_Taskmeta_Aggregate_Fields>;
  nodes: Array<Celery_Celery_Taskmeta>;
};

/** aggregate fields of "celery.celery_taskmeta" */
export type Celery_Celery_Taskmeta_Aggregate_Fields = {
  __typename?: 'celery_celery_taskmeta_aggregate_fields';
  avg: Maybe<Celery_Celery_Taskmeta_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Celery_Celery_Taskmeta_Max_Fields>;
  min: Maybe<Celery_Celery_Taskmeta_Min_Fields>;
  stddev: Maybe<Celery_Celery_Taskmeta_Stddev_Fields>;
  stddev_pop: Maybe<Celery_Celery_Taskmeta_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Celery_Celery_Taskmeta_Stddev_Samp_Fields>;
  sum: Maybe<Celery_Celery_Taskmeta_Sum_Fields>;
  var_pop: Maybe<Celery_Celery_Taskmeta_Var_Pop_Fields>;
  var_samp: Maybe<Celery_Celery_Taskmeta_Var_Samp_Fields>;
  variance: Maybe<Celery_Celery_Taskmeta_Variance_Fields>;
};

/** aggregate fields of "celery.celery_taskmeta" */
export type Celery_Celery_Taskmeta_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Celery_Celery_Taskmeta_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Celery_Celery_Taskmeta_Avg_Fields = {
  __typename?: 'celery_celery_taskmeta_avg_fields';
  id: Maybe<Scalars['Float']>;
  retries: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "celery.celery_taskmeta". All fields are combined with a logical 'AND'. */
export type Celery_Celery_Taskmeta_Bool_Exp = {
  _and?: InputMaybe<Array<Celery_Celery_Taskmeta_Bool_Exp>>;
  _not?: InputMaybe<Celery_Celery_Taskmeta_Bool_Exp>;
  _or?: InputMaybe<Array<Celery_Celery_Taskmeta_Bool_Exp>>;
  args?: InputMaybe<Bytea_Comparison_Exp>;
  date_done?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  kwargs?: InputMaybe<Bytea_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  queue?: InputMaybe<String_Comparison_Exp>;
  result?: InputMaybe<Bytea_Comparison_Exp>;
  retries?: InputMaybe<Int_Comparison_Exp>;
  retry_logs?: InputMaybe<Celery_Task_Retry_Log_Bool_Exp>;
  retry_logs_aggregate?: InputMaybe<Celery_Task_Retry_Log_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  task_id?: InputMaybe<String_Comparison_Exp>;
  traceback?: InputMaybe<String_Comparison_Exp>;
  worker?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "celery.celery_taskmeta" */
export enum Celery_Celery_Taskmeta_Constraint {
  /** unique or primary key constraint on columns "id" */
  CeleryTaskmetaPkey = 'celery_taskmeta_pkey',
  /** unique or primary key constraint on columns "task_id" */
  CeleryTaskmetaTaskIdKey = 'celery_taskmeta_task_id_key',
}

/** input type for incrementing numeric columns in table "celery.celery_taskmeta" */
export type Celery_Celery_Taskmeta_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  retries?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "celery.celery_taskmeta" */
export type Celery_Celery_Taskmeta_Insert_Input = {
  args?: InputMaybe<Scalars['bytea']>;
  date_done?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  kwargs?: InputMaybe<Scalars['bytea']>;
  name?: InputMaybe<Scalars['String']>;
  queue?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['bytea']>;
  retries?: InputMaybe<Scalars['Int']>;
  retry_logs?: InputMaybe<Celery_Task_Retry_Log_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  task_id?: InputMaybe<Scalars['String']>;
  traceback?: InputMaybe<Scalars['String']>;
  worker?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Celery_Celery_Taskmeta_Max_Fields = {
  __typename?: 'celery_celery_taskmeta_max_fields';
  date_done: Maybe<Scalars['timestamp']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  queue: Maybe<Scalars['String']>;
  retries: Maybe<Scalars['Int']>;
  status: Maybe<Scalars['String']>;
  task_id: Maybe<Scalars['String']>;
  traceback: Maybe<Scalars['String']>;
  worker: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Celery_Celery_Taskmeta_Min_Fields = {
  __typename?: 'celery_celery_taskmeta_min_fields';
  date_done: Maybe<Scalars['timestamp']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  queue: Maybe<Scalars['String']>;
  retries: Maybe<Scalars['Int']>;
  status: Maybe<Scalars['String']>;
  task_id: Maybe<Scalars['String']>;
  traceback: Maybe<Scalars['String']>;
  worker: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "celery.celery_taskmeta" */
export type Celery_Celery_Taskmeta_Mutation_Response = {
  __typename?: 'celery_celery_taskmeta_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Celery_Celery_Taskmeta>;
};

/** on_conflict condition type for table "celery.celery_taskmeta" */
export type Celery_Celery_Taskmeta_On_Conflict = {
  constraint: Celery_Celery_Taskmeta_Constraint;
  update_columns?: Array<Celery_Celery_Taskmeta_Update_Column>;
  where?: InputMaybe<Celery_Celery_Taskmeta_Bool_Exp>;
};

/** Ordering options when selecting data from "celery.celery_taskmeta". */
export type Celery_Celery_Taskmeta_Order_By = {
  args?: InputMaybe<Order_By>;
  date_done?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kwargs?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  queue?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  retries?: InputMaybe<Order_By>;
  retry_logs_aggregate?: InputMaybe<Celery_Task_Retry_Log_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  traceback?: InputMaybe<Order_By>;
  worker?: InputMaybe<Order_By>;
};

/** primary key columns input for table: celery.celery_taskmeta */
export type Celery_Celery_Taskmeta_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "celery.celery_taskmeta" */
export enum Celery_Celery_Taskmeta_Select_Column {
  /** column name */
  Args = 'args',
  /** column name */
  DateDone = 'date_done',
  /** column name */
  Id = 'id',
  /** column name */
  Kwargs = 'kwargs',
  /** column name */
  Name = 'name',
  /** column name */
  Queue = 'queue',
  /** column name */
  Result = 'result',
  /** column name */
  Retries = 'retries',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Traceback = 'traceback',
  /** column name */
  Worker = 'worker',
}

/** input type for updating data in table "celery.celery_taskmeta" */
export type Celery_Celery_Taskmeta_Set_Input = {
  args?: InputMaybe<Scalars['bytea']>;
  date_done?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  kwargs?: InputMaybe<Scalars['bytea']>;
  name?: InputMaybe<Scalars['String']>;
  queue?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['bytea']>;
  retries?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  task_id?: InputMaybe<Scalars['String']>;
  traceback?: InputMaybe<Scalars['String']>;
  worker?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Celery_Celery_Taskmeta_Stddev_Fields = {
  __typename?: 'celery_celery_taskmeta_stddev_fields';
  id: Maybe<Scalars['Float']>;
  retries: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Celery_Celery_Taskmeta_Stddev_Pop_Fields = {
  __typename?: 'celery_celery_taskmeta_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  retries: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Celery_Celery_Taskmeta_Stddev_Samp_Fields = {
  __typename?: 'celery_celery_taskmeta_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  retries: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "celery_celery_taskmeta" */
export type Celery_Celery_Taskmeta_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Celery_Celery_Taskmeta_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Celery_Celery_Taskmeta_Stream_Cursor_Value_Input = {
  args?: InputMaybe<Scalars['bytea']>;
  date_done?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  kwargs?: InputMaybe<Scalars['bytea']>;
  name?: InputMaybe<Scalars['String']>;
  queue?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['bytea']>;
  retries?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  task_id?: InputMaybe<Scalars['String']>;
  traceback?: InputMaybe<Scalars['String']>;
  worker?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Celery_Celery_Taskmeta_Sum_Fields = {
  __typename?: 'celery_celery_taskmeta_sum_fields';
  id: Maybe<Scalars['Int']>;
  retries: Maybe<Scalars['Int']>;
};

/** update columns of table "celery.celery_taskmeta" */
export enum Celery_Celery_Taskmeta_Update_Column {
  /** column name */
  Args = 'args',
  /** column name */
  DateDone = 'date_done',
  /** column name */
  Id = 'id',
  /** column name */
  Kwargs = 'kwargs',
  /** column name */
  Name = 'name',
  /** column name */
  Queue = 'queue',
  /** column name */
  Result = 'result',
  /** column name */
  Retries = 'retries',
  /** column name */
  Status = 'status',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Traceback = 'traceback',
  /** column name */
  Worker = 'worker',
}

export type Celery_Celery_Taskmeta_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Celery_Celery_Taskmeta_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Celery_Celery_Taskmeta_Set_Input>;
  /** filter the rows which have to be updated */
  where: Celery_Celery_Taskmeta_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Celery_Celery_Taskmeta_Var_Pop_Fields = {
  __typename?: 'celery_celery_taskmeta_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  retries: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Celery_Celery_Taskmeta_Var_Samp_Fields = {
  __typename?: 'celery_celery_taskmeta_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  retries: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Celery_Celery_Taskmeta_Variance_Fields = {
  __typename?: 'celery_celery_taskmeta_variance_fields';
  id: Maybe<Scalars['Float']>;
  retries: Maybe<Scalars['Float']>;
};

/** columns and relationships of "celery.celery_tasksetmeta" */
export type Celery_Celery_Tasksetmeta = {
  __typename?: 'celery_celery_tasksetmeta';
  date_done: Maybe<Scalars['timestamp']>;
  id: Scalars['Int'];
  result: Maybe<Scalars['bytea']>;
  taskset_id: Maybe<Scalars['String']>;
};

/** aggregated selection of "celery.celery_tasksetmeta" */
export type Celery_Celery_Tasksetmeta_Aggregate = {
  __typename?: 'celery_celery_tasksetmeta_aggregate';
  aggregate: Maybe<Celery_Celery_Tasksetmeta_Aggregate_Fields>;
  nodes: Array<Celery_Celery_Tasksetmeta>;
};

/** aggregate fields of "celery.celery_tasksetmeta" */
export type Celery_Celery_Tasksetmeta_Aggregate_Fields = {
  __typename?: 'celery_celery_tasksetmeta_aggregate_fields';
  avg: Maybe<Celery_Celery_Tasksetmeta_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Celery_Celery_Tasksetmeta_Max_Fields>;
  min: Maybe<Celery_Celery_Tasksetmeta_Min_Fields>;
  stddev: Maybe<Celery_Celery_Tasksetmeta_Stddev_Fields>;
  stddev_pop: Maybe<Celery_Celery_Tasksetmeta_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Celery_Celery_Tasksetmeta_Stddev_Samp_Fields>;
  sum: Maybe<Celery_Celery_Tasksetmeta_Sum_Fields>;
  var_pop: Maybe<Celery_Celery_Tasksetmeta_Var_Pop_Fields>;
  var_samp: Maybe<Celery_Celery_Tasksetmeta_Var_Samp_Fields>;
  variance: Maybe<Celery_Celery_Tasksetmeta_Variance_Fields>;
};

/** aggregate fields of "celery.celery_tasksetmeta" */
export type Celery_Celery_Tasksetmeta_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Celery_Celery_Tasksetmeta_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Celery_Celery_Tasksetmeta_Avg_Fields = {
  __typename?: 'celery_celery_tasksetmeta_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "celery.celery_tasksetmeta". All fields are combined with a logical 'AND'. */
export type Celery_Celery_Tasksetmeta_Bool_Exp = {
  _and?: InputMaybe<Array<Celery_Celery_Tasksetmeta_Bool_Exp>>;
  _not?: InputMaybe<Celery_Celery_Tasksetmeta_Bool_Exp>;
  _or?: InputMaybe<Array<Celery_Celery_Tasksetmeta_Bool_Exp>>;
  date_done?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  result?: InputMaybe<Bytea_Comparison_Exp>;
  taskset_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "celery.celery_tasksetmeta" */
export enum Celery_Celery_Tasksetmeta_Constraint {
  /** unique or primary key constraint on columns "id" */
  CeleryTasksetmetaPkey = 'celery_tasksetmeta_pkey',
  /** unique or primary key constraint on columns "taskset_id" */
  CeleryTasksetmetaTasksetIdKey = 'celery_tasksetmeta_taskset_id_key',
}

/** input type for incrementing numeric columns in table "celery.celery_tasksetmeta" */
export type Celery_Celery_Tasksetmeta_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "celery.celery_tasksetmeta" */
export type Celery_Celery_Tasksetmeta_Insert_Input = {
  date_done?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['bytea']>;
  taskset_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Celery_Celery_Tasksetmeta_Max_Fields = {
  __typename?: 'celery_celery_tasksetmeta_max_fields';
  date_done: Maybe<Scalars['timestamp']>;
  id: Maybe<Scalars['Int']>;
  taskset_id: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Celery_Celery_Tasksetmeta_Min_Fields = {
  __typename?: 'celery_celery_tasksetmeta_min_fields';
  date_done: Maybe<Scalars['timestamp']>;
  id: Maybe<Scalars['Int']>;
  taskset_id: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "celery.celery_tasksetmeta" */
export type Celery_Celery_Tasksetmeta_Mutation_Response = {
  __typename?: 'celery_celery_tasksetmeta_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Celery_Celery_Tasksetmeta>;
};

/** on_conflict condition type for table "celery.celery_tasksetmeta" */
export type Celery_Celery_Tasksetmeta_On_Conflict = {
  constraint: Celery_Celery_Tasksetmeta_Constraint;
  update_columns?: Array<Celery_Celery_Tasksetmeta_Update_Column>;
  where?: InputMaybe<Celery_Celery_Tasksetmeta_Bool_Exp>;
};

/** Ordering options when selecting data from "celery.celery_tasksetmeta". */
export type Celery_Celery_Tasksetmeta_Order_By = {
  date_done?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  taskset_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: celery.celery_tasksetmeta */
export type Celery_Celery_Tasksetmeta_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "celery.celery_tasksetmeta" */
export enum Celery_Celery_Tasksetmeta_Select_Column {
  /** column name */
  DateDone = 'date_done',
  /** column name */
  Id = 'id',
  /** column name */
  Result = 'result',
  /** column name */
  TasksetId = 'taskset_id',
}

/** input type for updating data in table "celery.celery_tasksetmeta" */
export type Celery_Celery_Tasksetmeta_Set_Input = {
  date_done?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['bytea']>;
  taskset_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Celery_Celery_Tasksetmeta_Stddev_Fields = {
  __typename?: 'celery_celery_tasksetmeta_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Celery_Celery_Tasksetmeta_Stddev_Pop_Fields = {
  __typename?: 'celery_celery_tasksetmeta_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Celery_Celery_Tasksetmeta_Stddev_Samp_Fields = {
  __typename?: 'celery_celery_tasksetmeta_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "celery_celery_tasksetmeta" */
export type Celery_Celery_Tasksetmeta_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Celery_Celery_Tasksetmeta_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Celery_Celery_Tasksetmeta_Stream_Cursor_Value_Input = {
  date_done?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  result?: InputMaybe<Scalars['bytea']>;
  taskset_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Celery_Celery_Tasksetmeta_Sum_Fields = {
  __typename?: 'celery_celery_tasksetmeta_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "celery.celery_tasksetmeta" */
export enum Celery_Celery_Tasksetmeta_Update_Column {
  /** column name */
  DateDone = 'date_done',
  /** column name */
  Id = 'id',
  /** column name */
  Result = 'result',
  /** column name */
  TasksetId = 'taskset_id',
}

export type Celery_Celery_Tasksetmeta_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Celery_Celery_Tasksetmeta_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Celery_Celery_Tasksetmeta_Set_Input>;
  /** filter the rows which have to be updated */
  where: Celery_Celery_Tasksetmeta_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Celery_Celery_Tasksetmeta_Var_Pop_Fields = {
  __typename?: 'celery_celery_tasksetmeta_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Celery_Celery_Tasksetmeta_Var_Samp_Fields = {
  __typename?: 'celery_celery_tasksetmeta_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Celery_Celery_Tasksetmeta_Variance_Fields = {
  __typename?: 'celery_celery_tasksetmeta_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "celery.task_retry_log" */
export type Celery_Task_Retry_Log = {
  __typename?: 'celery_task_retry_log';
  created_at: Scalars['timestamptz'];
  new_task_id: Scalars['String'];
  original_task_id: Scalars['String'];
};

/** aggregated selection of "celery.task_retry_log" */
export type Celery_Task_Retry_Log_Aggregate = {
  __typename?: 'celery_task_retry_log_aggregate';
  aggregate: Maybe<Celery_Task_Retry_Log_Aggregate_Fields>;
  nodes: Array<Celery_Task_Retry_Log>;
};

export type Celery_Task_Retry_Log_Aggregate_Bool_Exp = {
  count?: InputMaybe<Celery_Task_Retry_Log_Aggregate_Bool_Exp_Count>;
};

export type Celery_Task_Retry_Log_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Celery_Task_Retry_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Celery_Task_Retry_Log_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "celery.task_retry_log" */
export type Celery_Task_Retry_Log_Aggregate_Fields = {
  __typename?: 'celery_task_retry_log_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Celery_Task_Retry_Log_Max_Fields>;
  min: Maybe<Celery_Task_Retry_Log_Min_Fields>;
};

/** aggregate fields of "celery.task_retry_log" */
export type Celery_Task_Retry_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Celery_Task_Retry_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "celery.task_retry_log" */
export type Celery_Task_Retry_Log_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Celery_Task_Retry_Log_Max_Order_By>;
  min?: InputMaybe<Celery_Task_Retry_Log_Min_Order_By>;
};

/** input type for inserting array relation for remote table "celery.task_retry_log" */
export type Celery_Task_Retry_Log_Arr_Rel_Insert_Input = {
  data: Array<Celery_Task_Retry_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Celery_Task_Retry_Log_On_Conflict>;
};

/** Boolean expression to filter rows from the table "celery.task_retry_log". All fields are combined with a logical 'AND'. */
export type Celery_Task_Retry_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Celery_Task_Retry_Log_Bool_Exp>>;
  _not?: InputMaybe<Celery_Task_Retry_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Celery_Task_Retry_Log_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  new_task_id?: InputMaybe<String_Comparison_Exp>;
  original_task_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "celery.task_retry_log" */
export enum Celery_Task_Retry_Log_Constraint {
  /** unique or primary key constraint on columns "new_task_id", "original_task_id" */
  TaskRetryLogPkey = 'task_retry_log_pkey',
}

/** input type for inserting data into table "celery.task_retry_log" */
export type Celery_Task_Retry_Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  new_task_id?: InputMaybe<Scalars['String']>;
  original_task_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Celery_Task_Retry_Log_Max_Fields = {
  __typename?: 'celery_task_retry_log_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  new_task_id: Maybe<Scalars['String']>;
  original_task_id: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "celery.task_retry_log" */
export type Celery_Task_Retry_Log_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  new_task_id?: InputMaybe<Order_By>;
  original_task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Celery_Task_Retry_Log_Min_Fields = {
  __typename?: 'celery_task_retry_log_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  new_task_id: Maybe<Scalars['String']>;
  original_task_id: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "celery.task_retry_log" */
export type Celery_Task_Retry_Log_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  new_task_id?: InputMaybe<Order_By>;
  original_task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "celery.task_retry_log" */
export type Celery_Task_Retry_Log_Mutation_Response = {
  __typename?: 'celery_task_retry_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Celery_Task_Retry_Log>;
};

/** on_conflict condition type for table "celery.task_retry_log" */
export type Celery_Task_Retry_Log_On_Conflict = {
  constraint: Celery_Task_Retry_Log_Constraint;
  update_columns?: Array<Celery_Task_Retry_Log_Update_Column>;
  where?: InputMaybe<Celery_Task_Retry_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "celery.task_retry_log". */
export type Celery_Task_Retry_Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  new_task_id?: InputMaybe<Order_By>;
  original_task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: celery.task_retry_log */
export type Celery_Task_Retry_Log_Pk_Columns_Input = {
  new_task_id: Scalars['String'];
  original_task_id: Scalars['String'];
};

/** select columns of table "celery.task_retry_log" */
export enum Celery_Task_Retry_Log_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NewTaskId = 'new_task_id',
  /** column name */
  OriginalTaskId = 'original_task_id',
}

/** input type for updating data in table "celery.task_retry_log" */
export type Celery_Task_Retry_Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  new_task_id?: InputMaybe<Scalars['String']>;
  original_task_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "celery_task_retry_log" */
export type Celery_Task_Retry_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Celery_Task_Retry_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Celery_Task_Retry_Log_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  new_task_id?: InputMaybe<Scalars['String']>;
  original_task_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "celery.task_retry_log" */
export enum Celery_Task_Retry_Log_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NewTaskId = 'new_task_id',
  /** column name */
  OriginalTaskId = 'original_task_id',
}

export type Celery_Task_Retry_Log_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Celery_Task_Retry_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Celery_Task_Retry_Log_Bool_Exp;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']>;
  _gt?: InputMaybe<Scalars['citext']>;
  _gte?: InputMaybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']>;
  _in?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']>;
  _lt?: InputMaybe<Scalars['citext']>;
  _lte?: InputMaybe<Scalars['citext']>;
  _neq?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']>;
  _nin?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']>;
};

/** Table to store information about prospect clients  */
export type Client_Prospect = {
  __typename?: 'client_prospect';
  /** An array relationship */
  client_prospect_roles: Array<Client_Prospect_Role>;
  /** An aggregate relationship */
  client_prospect_roles_aggregate: Client_Prospect_Role_Aggregate;
  company_name: Maybe<Scalars['String']>;
  company_size: Maybe<Company_Size_Choices_Enum>;
  country: Maybe<Scalars['String']>;
  /** An object relationship */
  country_choice: Maybe<Country_Choices>;
  country_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  employee_type: Maybe<Employment_Type_Choices_Enum>;
  id: Scalars['Int'];
  /** Is using Professional Employer Organization or Employer of Record? */
  is_using_peo_or_eor: Maybe<Scalars['Boolean']>;
  last_step_completed: Maybe<Scalars['String']>;
  /** An object relationship */
  location: Maybe<Location>;
  location_id: Maybe<Scalars['Int']>;
  onboarding_variations: Maybe<Scalars['jsonb']>;
  role_choices: Maybe<Scalars['jsonb']>;
  role_choices_length: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user_info: User_Info;
  user_info_id: Scalars['Int'];
};

/** Table to store information about prospect clients  */
export type Client_ProspectClient_Prospect_RolesArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
};

/** Table to store information about prospect clients  */
export type Client_ProspectClient_Prospect_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
};

/** Table to store information about prospect clients  */
export type Client_ProspectOnboarding_VariationsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Table to store information about prospect clients  */
export type Client_ProspectRole_ChoicesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "client_prospect" */
export type Client_Prospect_Aggregate = {
  __typename?: 'client_prospect_aggregate';
  aggregate: Maybe<Client_Prospect_Aggregate_Fields>;
  nodes: Array<Client_Prospect>;
};

/** aggregate fields of "client_prospect" */
export type Client_Prospect_Aggregate_Fields = {
  __typename?: 'client_prospect_aggregate_fields';
  avg: Maybe<Client_Prospect_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Client_Prospect_Max_Fields>;
  min: Maybe<Client_Prospect_Min_Fields>;
  stddev: Maybe<Client_Prospect_Stddev_Fields>;
  stddev_pop: Maybe<Client_Prospect_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Client_Prospect_Stddev_Samp_Fields>;
  sum: Maybe<Client_Prospect_Sum_Fields>;
  var_pop: Maybe<Client_Prospect_Var_Pop_Fields>;
  var_samp: Maybe<Client_Prospect_Var_Samp_Fields>;
  variance: Maybe<Client_Prospect_Variance_Fields>;
};

/** aggregate fields of "client_prospect" */
export type Client_Prospect_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Prospect_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Client_Prospect_Append_Input = {
  onboarding_variations?: InputMaybe<Scalars['jsonb']>;
  role_choices?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Client_Prospect_Avg_Fields = {
  __typename?: 'client_prospect_avg_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
  role_choices_length: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "client_prospect". All fields are combined with a logical 'AND'. */
export type Client_Prospect_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Prospect_Bool_Exp>>;
  _not?: InputMaybe<Client_Prospect_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Prospect_Bool_Exp>>;
  client_prospect_roles?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
  client_prospect_roles_aggregate?: InputMaybe<Client_Prospect_Role_Aggregate_Bool_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
  company_size?: InputMaybe<Company_Size_Choices_Enum_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  country_choice?: InputMaybe<Country_Choices_Bool_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  employee_type?: InputMaybe<Employment_Type_Choices_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_using_peo_or_eor?: InputMaybe<Boolean_Comparison_Exp>;
  last_step_completed?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  onboarding_variations?: InputMaybe<Jsonb_Comparison_Exp>;
  role_choices?: InputMaybe<Jsonb_Comparison_Exp>;
  role_choices_length?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
  user_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_prospect" */
export enum Client_Prospect_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientProspectPkey = 'client_prospect_pkey',
  /** unique or primary key constraint on columns "user_info_id" */
  ClientProspectUserInfoIdKey = 'client_prospect_user_info_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Client_Prospect_Delete_At_Path_Input = {
  onboarding_variations?: InputMaybe<Array<Scalars['String']>>;
  role_choices?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Client_Prospect_Delete_Elem_Input = {
  onboarding_variations?: InputMaybe<Scalars['Int']>;
  role_choices?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Client_Prospect_Delete_Key_Input = {
  onboarding_variations?: InputMaybe<Scalars['String']>;
  role_choices?: InputMaybe<Scalars['String']>;
};

/** Table to store information about the events of prospect clients */
export type Client_Prospect_Event = {
  __typename?: 'client_prospect_event';
  created_at: Maybe<Scalars['timestamptz']>;
  event_choice: Client_Prospect_Events_Choices_Enum;
  id: Scalars['Int'];
  /** An object relationship */
  user_info: User_Info;
  user_info_id: Scalars['Int'];
};

/** aggregated selection of "client_prospect_event" */
export type Client_Prospect_Event_Aggregate = {
  __typename?: 'client_prospect_event_aggregate';
  aggregate: Maybe<Client_Prospect_Event_Aggregate_Fields>;
  nodes: Array<Client_Prospect_Event>;
};

/** aggregate fields of "client_prospect_event" */
export type Client_Prospect_Event_Aggregate_Fields = {
  __typename?: 'client_prospect_event_aggregate_fields';
  avg: Maybe<Client_Prospect_Event_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Client_Prospect_Event_Max_Fields>;
  min: Maybe<Client_Prospect_Event_Min_Fields>;
  stddev: Maybe<Client_Prospect_Event_Stddev_Fields>;
  stddev_pop: Maybe<Client_Prospect_Event_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Client_Prospect_Event_Stddev_Samp_Fields>;
  sum: Maybe<Client_Prospect_Event_Sum_Fields>;
  var_pop: Maybe<Client_Prospect_Event_Var_Pop_Fields>;
  var_samp: Maybe<Client_Prospect_Event_Var_Samp_Fields>;
  variance: Maybe<Client_Prospect_Event_Variance_Fields>;
};

/** aggregate fields of "client_prospect_event" */
export type Client_Prospect_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Prospect_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Client_Prospect_Event_Avg_Fields = {
  __typename?: 'client_prospect_event_avg_fields';
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "client_prospect_event". All fields are combined with a logical 'AND'. */
export type Client_Prospect_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Prospect_Event_Bool_Exp>>;
  _not?: InputMaybe<Client_Prospect_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Prospect_Event_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_choice?: InputMaybe<Client_Prospect_Events_Choices_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
  user_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_prospect_event" */
export enum Client_Prospect_Event_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientProspectEventPkey = 'client_prospect_event_pkey',
  /** unique or primary key constraint on columns "event_choice", "user_info_id" */
  ClientProspectEventUniqueKey = 'client_prospect_event_unique_key',
}

/** input type for incrementing numeric columns in table "client_prospect_event" */
export type Client_Prospect_Event_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "client_prospect_event" */
export type Client_Prospect_Event_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_choice?: InputMaybe<Client_Prospect_Events_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Client_Prospect_Event_Max_Fields = {
  __typename?: 'client_prospect_event_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Client_Prospect_Event_Min_Fields = {
  __typename?: 'client_prospect_event_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "client_prospect_event" */
export type Client_Prospect_Event_Mutation_Response = {
  __typename?: 'client_prospect_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Prospect_Event>;
};

/** on_conflict condition type for table "client_prospect_event" */
export type Client_Prospect_Event_On_Conflict = {
  constraint: Client_Prospect_Event_Constraint;
  update_columns?: Array<Client_Prospect_Event_Update_Column>;
  where?: InputMaybe<Client_Prospect_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "client_prospect_event". */
export type Client_Prospect_Event_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_choice?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_prospect_event */
export type Client_Prospect_Event_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "client_prospect_event" */
export enum Client_Prospect_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventChoice = 'event_choice',
  /** column name */
  Id = 'id',
  /** column name */
  UserInfoId = 'user_info_id',
}

/** input type for updating data in table "client_prospect_event" */
export type Client_Prospect_Event_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_choice?: InputMaybe<Client_Prospect_Events_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Client_Prospect_Event_Stddev_Fields = {
  __typename?: 'client_prospect_event_stddev_fields';
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Client_Prospect_Event_Stddev_Pop_Fields = {
  __typename?: 'client_prospect_event_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Client_Prospect_Event_Stddev_Samp_Fields = {
  __typename?: 'client_prospect_event_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "client_prospect_event" */
export type Client_Prospect_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Prospect_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Prospect_Event_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_choice?: InputMaybe<Client_Prospect_Events_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Client_Prospect_Event_Sum_Fields = {
  __typename?: 'client_prospect_event_sum_fields';
  id: Maybe<Scalars['Int']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** update columns of table "client_prospect_event" */
export enum Client_Prospect_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventChoice = 'event_choice',
  /** column name */
  Id = 'id',
  /** column name */
  UserInfoId = 'user_info_id',
}

export type Client_Prospect_Event_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Prospect_Event_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Prospect_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Prospect_Event_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Prospect_Event_Var_Pop_Fields = {
  __typename?: 'client_prospect_event_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Client_Prospect_Event_Var_Samp_Fields = {
  __typename?: 'client_prospect_event_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Client_Prospect_Event_Variance_Fields = {
  __typename?: 'client_prospect_event_variance_fields';
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** Client prospect event choices */
export type Client_Prospect_Events_Choices = {
  __typename?: 'client_prospect_events_choices';
  value: Scalars['String'];
};

/** aggregated selection of "client_prospect_events_choices" */
export type Client_Prospect_Events_Choices_Aggregate = {
  __typename?: 'client_prospect_events_choices_aggregate';
  aggregate: Maybe<Client_Prospect_Events_Choices_Aggregate_Fields>;
  nodes: Array<Client_Prospect_Events_Choices>;
};

/** aggregate fields of "client_prospect_events_choices" */
export type Client_Prospect_Events_Choices_Aggregate_Fields = {
  __typename?: 'client_prospect_events_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Client_Prospect_Events_Choices_Max_Fields>;
  min: Maybe<Client_Prospect_Events_Choices_Min_Fields>;
};

/** aggregate fields of "client_prospect_events_choices" */
export type Client_Prospect_Events_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Prospect_Events_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "client_prospect_events_choices". All fields are combined with a logical 'AND'. */
export type Client_Prospect_Events_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Prospect_Events_Choices_Bool_Exp>>;
  _not?: InputMaybe<Client_Prospect_Events_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Prospect_Events_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_prospect_events_choices" */
export enum Client_Prospect_Events_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  ClientProspectEventsChoicesPkey = 'client_prospect_events_choices_pkey',
}

export enum Client_Prospect_Events_Choices_Enum {
  EnterBrowseCandidates = 'ENTER_BROWSE_CANDIDATES',
  EnterTrial = 'ENTER_TRIAL',
  SaveCandidate = 'SAVE_CANDIDATE',
  ScheduleCall = 'SCHEDULE_CALL',
  VetsCandidates = 'VETS_CANDIDATES',
}

/** Boolean expression to compare columns of type "client_prospect_events_choices_enum". All fields are combined with logical 'AND'. */
export type Client_Prospect_Events_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Client_Prospect_Events_Choices_Enum>;
  _in?: InputMaybe<Array<Client_Prospect_Events_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Client_Prospect_Events_Choices_Enum>;
  _nin?: InputMaybe<Array<Client_Prospect_Events_Choices_Enum>>;
};

/** input type for inserting data into table "client_prospect_events_choices" */
export type Client_Prospect_Events_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Client_Prospect_Events_Choices_Max_Fields = {
  __typename?: 'client_prospect_events_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Client_Prospect_Events_Choices_Min_Fields = {
  __typename?: 'client_prospect_events_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "client_prospect_events_choices" */
export type Client_Prospect_Events_Choices_Mutation_Response = {
  __typename?: 'client_prospect_events_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Prospect_Events_Choices>;
};

/** on_conflict condition type for table "client_prospect_events_choices" */
export type Client_Prospect_Events_Choices_On_Conflict = {
  constraint: Client_Prospect_Events_Choices_Constraint;
  update_columns?: Array<Client_Prospect_Events_Choices_Update_Column>;
  where?: InputMaybe<Client_Prospect_Events_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "client_prospect_events_choices". */
export type Client_Prospect_Events_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_prospect_events_choices */
export type Client_Prospect_Events_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "client_prospect_events_choices" */
export enum Client_Prospect_Events_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "client_prospect_events_choices" */
export type Client_Prospect_Events_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "client_prospect_events_choices" */
export type Client_Prospect_Events_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Prospect_Events_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Prospect_Events_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "client_prospect_events_choices" */
export enum Client_Prospect_Events_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Client_Prospect_Events_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Prospect_Events_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Prospect_Events_Choices_Bool_Exp;
};

/** input type for incrementing numeric columns in table "client_prospect" */
export type Client_Prospect_Inc_Input = {
  country_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  role_choices_length?: InputMaybe<Scalars['Int']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "client_prospect" */
export type Client_Prospect_Insert_Input = {
  client_prospect_roles?: InputMaybe<Client_Prospect_Role_Arr_Rel_Insert_Input>;
  company_name?: InputMaybe<Scalars['String']>;
  company_size?: InputMaybe<Company_Size_Choices_Enum>;
  country?: InputMaybe<Scalars['String']>;
  country_choice?: InputMaybe<Country_Choices_Obj_Rel_Insert_Input>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  employee_type?: InputMaybe<Employment_Type_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  /** Is using Professional Employer Organization or Employer of Record? */
  is_using_peo_or_eor?: InputMaybe<Scalars['Boolean']>;
  last_step_completed?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['Int']>;
  onboarding_variations?: InputMaybe<Scalars['jsonb']>;
  role_choices?: InputMaybe<Scalars['jsonb']>;
  role_choices_length?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Client_Prospect_Max_Fields = {
  __typename?: 'client_prospect_max_fields';
  company_name: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  id: Maybe<Scalars['Int']>;
  last_step_completed: Maybe<Scalars['String']>;
  location_id: Maybe<Scalars['Int']>;
  role_choices_length: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Client_Prospect_Min_Fields = {
  __typename?: 'client_prospect_min_fields';
  company_name: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  id: Maybe<Scalars['Int']>;
  last_step_completed: Maybe<Scalars['String']>;
  location_id: Maybe<Scalars['Int']>;
  role_choices_length: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "client_prospect" */
export type Client_Prospect_Mutation_Response = {
  __typename?: 'client_prospect_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Prospect>;
};

/** input type for inserting object relation for remote table "client_prospect" */
export type Client_Prospect_Obj_Rel_Insert_Input = {
  data: Client_Prospect_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Prospect_On_Conflict>;
};

/** on_conflict condition type for table "client_prospect" */
export type Client_Prospect_On_Conflict = {
  constraint: Client_Prospect_Constraint;
  update_columns?: Array<Client_Prospect_Update_Column>;
  where?: InputMaybe<Client_Prospect_Bool_Exp>;
};

/** Ordering options when selecting data from "client_prospect". */
export type Client_Prospect_Order_By = {
  client_prospect_roles_aggregate?: InputMaybe<Client_Prospect_Role_Aggregate_Order_By>;
  company_name?: InputMaybe<Order_By>;
  company_size?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  country_choice?: InputMaybe<Country_Choices_Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employee_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_using_peo_or_eor?: InputMaybe<Order_By>;
  last_step_completed?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  location_id?: InputMaybe<Order_By>;
  onboarding_variations?: InputMaybe<Order_By>;
  role_choices?: InputMaybe<Order_By>;
  role_choices_length?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_prospect */
export type Client_Prospect_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Client_Prospect_Prepend_Input = {
  onboarding_variations?: InputMaybe<Scalars['jsonb']>;
  role_choices?: InputMaybe<Scalars['jsonb']>;
};

/** Table to store the list of roles prospect clients are looking to hire  */
export type Client_Prospect_Role = {
  __typename?: 'client_prospect_role';
  /** An object relationship */
  candidate_curation_years_of_exp_range_choice: Candidate_Curation_Years_Of_Exp_Range_Choices;
  /** An object relationship */
  client_prospect: Client_Prospect;
  client_prospect_id: Scalars['Int'];
  /** An array relationship */
  client_prospect_role_skills: Array<Client_Prospect_Role_Skill>;
  /** An aggregate relationship */
  client_prospect_role_skills_aggregate: Client_Prospect_Role_Skill_Aggregate;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  role_choice: Role_Choices;
  role_type: Role_Choices_Enum;
  updated_at: Maybe<Scalars['timestamptz']>;
  years_of_experience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum;
};

/** Table to store the list of roles prospect clients are looking to hire  */
export type Client_Prospect_RoleClient_Prospect_Role_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Skill_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Skill_Bool_Exp>;
};

/** Table to store the list of roles prospect clients are looking to hire  */
export type Client_Prospect_RoleClient_Prospect_Role_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Skill_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Skill_Bool_Exp>;
};

/** aggregated selection of "client_prospect_role" */
export type Client_Prospect_Role_Aggregate = {
  __typename?: 'client_prospect_role_aggregate';
  aggregate: Maybe<Client_Prospect_Role_Aggregate_Fields>;
  nodes: Array<Client_Prospect_Role>;
};

export type Client_Prospect_Role_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Prospect_Role_Aggregate_Bool_Exp_Count>;
};

export type Client_Prospect_Role_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Prospect_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_prospect_role" */
export type Client_Prospect_Role_Aggregate_Fields = {
  __typename?: 'client_prospect_role_aggregate_fields';
  avg: Maybe<Client_Prospect_Role_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Client_Prospect_Role_Max_Fields>;
  min: Maybe<Client_Prospect_Role_Min_Fields>;
  stddev: Maybe<Client_Prospect_Role_Stddev_Fields>;
  stddev_pop: Maybe<Client_Prospect_Role_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Client_Prospect_Role_Stddev_Samp_Fields>;
  sum: Maybe<Client_Prospect_Role_Sum_Fields>;
  var_pop: Maybe<Client_Prospect_Role_Var_Pop_Fields>;
  var_samp: Maybe<Client_Prospect_Role_Var_Samp_Fields>;
  variance: Maybe<Client_Prospect_Role_Variance_Fields>;
};

/** aggregate fields of "client_prospect_role" */
export type Client_Prospect_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Prospect_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client_prospect_role" */
export type Client_Prospect_Role_Aggregate_Order_By = {
  avg?: InputMaybe<Client_Prospect_Role_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Prospect_Role_Max_Order_By>;
  min?: InputMaybe<Client_Prospect_Role_Min_Order_By>;
  stddev?: InputMaybe<Client_Prospect_Role_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Client_Prospect_Role_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Client_Prospect_Role_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Client_Prospect_Role_Sum_Order_By>;
  var_pop?: InputMaybe<Client_Prospect_Role_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Client_Prospect_Role_Var_Samp_Order_By>;
  variance?: InputMaybe<Client_Prospect_Role_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "client_prospect_role" */
export type Client_Prospect_Role_Arr_Rel_Insert_Input = {
  data: Array<Client_Prospect_Role_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Prospect_Role_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Prospect_Role_Avg_Fields = {
  __typename?: 'client_prospect_role_avg_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "client_prospect_role" */
export type Client_Prospect_Role_Avg_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_prospect_role". All fields are combined with a logical 'AND'. */
export type Client_Prospect_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Prospect_Role_Bool_Exp>>;
  _not?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Prospect_Role_Bool_Exp>>;
  candidate_curation_years_of_exp_range_choice?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp>;
  client_prospect?: InputMaybe<Client_Prospect_Bool_Exp>;
  client_prospect_id?: InputMaybe<Int_Comparison_Exp>;
  client_prospect_role_skills?: InputMaybe<Client_Prospect_Role_Skill_Bool_Exp>;
  client_prospect_role_skills_aggregate?: InputMaybe<Client_Prospect_Role_Skill_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  role_choice?: InputMaybe<Role_Choices_Bool_Exp>;
  role_type?: InputMaybe<Role_Choices_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_prospect_role" */
export enum Client_Prospect_Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientProspectRolePkey = 'client_prospect_role_pkey',
}

/** input type for incrementing numeric columns in table "client_prospect_role" */
export type Client_Prospect_Role_Inc_Input = {
  client_prospect_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "client_prospect_role" */
export type Client_Prospect_Role_Insert_Input = {
  candidate_curation_years_of_exp_range_choice?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Obj_Rel_Insert_Input>;
  client_prospect?: InputMaybe<Client_Prospect_Obj_Rel_Insert_Input>;
  client_prospect_id?: InputMaybe<Scalars['Int']>;
  client_prospect_role_skills?: InputMaybe<Client_Prospect_Role_Skill_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  role_choice?: InputMaybe<Role_Choices_Obj_Rel_Insert_Input>;
  role_type?: InputMaybe<Role_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
};

/** aggregate max on columns */
export type Client_Prospect_Role_Max_Fields = {
  __typename?: 'client_prospect_role_max_fields';
  client_prospect_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "client_prospect_role" */
export type Client_Prospect_Role_Max_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Prospect_Role_Min_Fields = {
  __typename?: 'client_prospect_role_min_fields';
  client_prospect_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "client_prospect_role" */
export type Client_Prospect_Role_Min_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_prospect_role" */
export type Client_Prospect_Role_Mutation_Response = {
  __typename?: 'client_prospect_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Prospect_Role>;
};

/** input type for inserting object relation for remote table "client_prospect_role" */
export type Client_Prospect_Role_Obj_Rel_Insert_Input = {
  data: Client_Prospect_Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Prospect_Role_On_Conflict>;
};

/** on_conflict condition type for table "client_prospect_role" */
export type Client_Prospect_Role_On_Conflict = {
  constraint: Client_Prospect_Role_Constraint;
  update_columns?: Array<Client_Prospect_Role_Update_Column>;
  where?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "client_prospect_role". */
export type Client_Prospect_Role_Order_By = {
  candidate_curation_years_of_exp_range_choice?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_By>;
  client_prospect?: InputMaybe<Client_Prospect_Order_By>;
  client_prospect_id?: InputMaybe<Order_By>;
  client_prospect_role_skills_aggregate?: InputMaybe<Client_Prospect_Role_Skill_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_choice?: InputMaybe<Role_Choices_Order_By>;
  role_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  years_of_experience?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_prospect_role */
export type Client_Prospect_Role_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "client_prospect_role" */
export enum Client_Prospect_Role_Select_Column {
  /** column name */
  ClientProspectId = 'client_prospect_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleType = 'role_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YearsOfExperience = 'years_of_experience',
}

/** input type for updating data in table "client_prospect_role" */
export type Client_Prospect_Role_Set_Input = {
  client_prospect_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  role_type?: InputMaybe<Role_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
};

/** Table to store the required skills of roles that prospect clients are hiring for  */
export type Client_Prospect_Role_Skill = {
  __typename?: 'client_prospect_role_skill';
  /** An object relationship */
  client_prospect_role: Client_Prospect_Role;
  client_prospect_role_id: Scalars['Int'];
  created_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Aggregate = {
  __typename?: 'client_prospect_role_skill_aggregate';
  aggregate: Maybe<Client_Prospect_Role_Skill_Aggregate_Fields>;
  nodes: Array<Client_Prospect_Role_Skill>;
};

export type Client_Prospect_Role_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Prospect_Role_Skill_Aggregate_Bool_Exp_Count>;
};

export type Client_Prospect_Role_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Prospect_Role_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Client_Prospect_Role_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Aggregate_Fields = {
  __typename?: 'client_prospect_role_skill_aggregate_fields';
  avg: Maybe<Client_Prospect_Role_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Client_Prospect_Role_Skill_Max_Fields>;
  min: Maybe<Client_Prospect_Role_Skill_Min_Fields>;
  stddev: Maybe<Client_Prospect_Role_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Client_Prospect_Role_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Client_Prospect_Role_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Client_Prospect_Role_Skill_Sum_Fields>;
  var_pop: Maybe<Client_Prospect_Role_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Client_Prospect_Role_Skill_Var_Samp_Fields>;
  variance: Maybe<Client_Prospect_Role_Skill_Variance_Fields>;
};

/** aggregate fields of "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Prospect_Role_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Client_Prospect_Role_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Prospect_Role_Skill_Max_Order_By>;
  min?: InputMaybe<Client_Prospect_Role_Skill_Min_Order_By>;
  stddev?: InputMaybe<Client_Prospect_Role_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Client_Prospect_Role_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Client_Prospect_Role_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Client_Prospect_Role_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Client_Prospect_Role_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Client_Prospect_Role_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Client_Prospect_Role_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Arr_Rel_Insert_Input = {
  data: Array<Client_Prospect_Role_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Prospect_Role_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Prospect_Role_Skill_Avg_Fields = {
  __typename?: 'client_prospect_role_skill_avg_fields';
  client_prospect_role_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Avg_Order_By = {
  client_prospect_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_prospect_role_skill". All fields are combined with a logical 'AND'. */
export type Client_Prospect_Role_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Prospect_Role_Skill_Bool_Exp>>;
  _not?: InputMaybe<Client_Prospect_Role_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Prospect_Role_Skill_Bool_Exp>>;
  client_prospect_role?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
  client_prospect_role_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_prospect_role_skill" */
export enum Client_Prospect_Role_Skill_Constraint {
  /** unique or primary key constraint on columns "client_prospect_role_id", "skill_id" */
  ClientProspectRoleSkillClientProspectRoleIdSkillIdKey = 'client_prospect_role_skill_client_prospect_role_id_skill_id_key',
  /** unique or primary key constraint on columns "id" */
  ClientProspectRoleSkillPkey = 'client_prospect_role_skill_pkey',
}

/** input type for incrementing numeric columns in table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Inc_Input = {
  client_prospect_role_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Insert_Input = {
  client_prospect_role?: InputMaybe<Client_Prospect_Role_Obj_Rel_Insert_Input>;
  client_prospect_role_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Client_Prospect_Role_Skill_Max_Fields = {
  __typename?: 'client_prospect_role_skill_max_fields';
  client_prospect_role_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Max_Order_By = {
  client_prospect_role_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Prospect_Role_Skill_Min_Fields = {
  __typename?: 'client_prospect_role_skill_min_fields';
  client_prospect_role_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Min_Order_By = {
  client_prospect_role_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Mutation_Response = {
  __typename?: 'client_prospect_role_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Prospect_Role_Skill>;
};

/** on_conflict condition type for table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_On_Conflict = {
  constraint: Client_Prospect_Role_Skill_Constraint;
  update_columns?: Array<Client_Prospect_Role_Skill_Update_Column>;
  where?: InputMaybe<Client_Prospect_Role_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "client_prospect_role_skill". */
export type Client_Prospect_Role_Skill_Order_By = {
  client_prospect_role?: InputMaybe<Client_Prospect_Role_Order_By>;
  client_prospect_role_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_prospect_role_skill */
export type Client_Prospect_Role_Skill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "client_prospect_role_skill" */
export enum Client_Prospect_Role_Skill_Select_Column {
  /** column name */
  ClientProspectRoleId = 'client_prospect_role_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Set_Input = {
  client_prospect_role_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Client_Prospect_Role_Skill_Stddev_Fields = {
  __typename?: 'client_prospect_role_skill_stddev_fields';
  client_prospect_role_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Stddev_Order_By = {
  client_prospect_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Prospect_Role_Skill_Stddev_Pop_Fields = {
  __typename?: 'client_prospect_role_skill_stddev_pop_fields';
  client_prospect_role_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Stddev_Pop_Order_By = {
  client_prospect_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Prospect_Role_Skill_Stddev_Samp_Fields = {
  __typename?: 'client_prospect_role_skill_stddev_samp_fields';
  client_prospect_role_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Stddev_Samp_Order_By = {
  client_prospect_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Prospect_Role_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Prospect_Role_Skill_Stream_Cursor_Value_Input = {
  client_prospect_role_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Client_Prospect_Role_Skill_Sum_Fields = {
  __typename?: 'client_prospect_role_skill_sum_fields';
  client_prospect_role_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Sum_Order_By = {
  client_prospect_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "client_prospect_role_skill" */
export enum Client_Prospect_Role_Skill_Update_Column {
  /** column name */
  ClientProspectRoleId = 'client_prospect_role_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Client_Prospect_Role_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Prospect_Role_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Prospect_Role_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Prospect_Role_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Prospect_Role_Skill_Var_Pop_Fields = {
  __typename?: 'client_prospect_role_skill_var_pop_fields';
  client_prospect_role_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Var_Pop_Order_By = {
  client_prospect_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Prospect_Role_Skill_Var_Samp_Fields = {
  __typename?: 'client_prospect_role_skill_var_samp_fields';
  client_prospect_role_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Var_Samp_Order_By = {
  client_prospect_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Prospect_Role_Skill_Variance_Fields = {
  __typename?: 'client_prospect_role_skill_variance_fields';
  client_prospect_role_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "client_prospect_role_skill" */
export type Client_Prospect_Role_Skill_Variance_Order_By = {
  client_prospect_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Client_Prospect_Role_Stddev_Fields = {
  __typename?: 'client_prospect_role_stddev_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "client_prospect_role" */
export type Client_Prospect_Role_Stddev_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Prospect_Role_Stddev_Pop_Fields = {
  __typename?: 'client_prospect_role_stddev_pop_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "client_prospect_role" */
export type Client_Prospect_Role_Stddev_Pop_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Prospect_Role_Stddev_Samp_Fields = {
  __typename?: 'client_prospect_role_stddev_samp_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "client_prospect_role" */
export type Client_Prospect_Role_Stddev_Samp_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "client_prospect_role" */
export type Client_Prospect_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Prospect_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Prospect_Role_Stream_Cursor_Value_Input = {
  client_prospect_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  role_type?: InputMaybe<Role_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
};

/** aggregate sum on columns */
export type Client_Prospect_Role_Sum_Fields = {
  __typename?: 'client_prospect_role_sum_fields';
  client_prospect_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "client_prospect_role" */
export type Client_Prospect_Role_Sum_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "client_prospect_role" */
export enum Client_Prospect_Role_Update_Column {
  /** column name */
  ClientProspectId = 'client_prospect_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleType = 'role_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YearsOfExperience = 'years_of_experience',
}

export type Client_Prospect_Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Prospect_Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Prospect_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Prospect_Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Prospect_Role_Var_Pop_Fields = {
  __typename?: 'client_prospect_role_var_pop_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "client_prospect_role" */
export type Client_Prospect_Role_Var_Pop_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Prospect_Role_Var_Samp_Fields = {
  __typename?: 'client_prospect_role_var_samp_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "client_prospect_role" */
export type Client_Prospect_Role_Var_Samp_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Prospect_Role_Variance_Fields = {
  __typename?: 'client_prospect_role_variance_fields';
  client_prospect_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "client_prospect_role" */
export type Client_Prospect_Role_Variance_Order_By = {
  client_prospect_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "client_prospect" */
export enum Client_Prospect_Select_Column {
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CompanySize = 'company_size',
  /** column name */
  Country = 'country',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmployeeType = 'employee_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsUsingPeoOrEor = 'is_using_peo_or_eor',
  /** column name */
  LastStepCompleted = 'last_step_completed',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OnboardingVariations = 'onboarding_variations',
  /** column name */
  RoleChoices = 'role_choices',
  /** column name */
  RoleChoicesLength = 'role_choices_length',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id',
}

/** input type for updating data in table "client_prospect" */
export type Client_Prospect_Set_Input = {
  company_name?: InputMaybe<Scalars['String']>;
  company_size?: InputMaybe<Company_Size_Choices_Enum>;
  country?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  employee_type?: InputMaybe<Employment_Type_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  /** Is using Professional Employer Organization or Employer of Record? */
  is_using_peo_or_eor?: InputMaybe<Scalars['Boolean']>;
  last_step_completed?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  onboarding_variations?: InputMaybe<Scalars['jsonb']>;
  role_choices?: InputMaybe<Scalars['jsonb']>;
  role_choices_length?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Client_Prospect_Stddev_Fields = {
  __typename?: 'client_prospect_stddev_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
  role_choices_length: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Client_Prospect_Stddev_Pop_Fields = {
  __typename?: 'client_prospect_stddev_pop_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
  role_choices_length: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Client_Prospect_Stddev_Samp_Fields = {
  __typename?: 'client_prospect_stddev_samp_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
  role_choices_length: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "client_prospect" */
export type Client_Prospect_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Prospect_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Prospect_Stream_Cursor_Value_Input = {
  company_name?: InputMaybe<Scalars['String']>;
  company_size?: InputMaybe<Company_Size_Choices_Enum>;
  country?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  employee_type?: InputMaybe<Employment_Type_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  /** Is using Professional Employer Organization or Employer of Record? */
  is_using_peo_or_eor?: InputMaybe<Scalars['Boolean']>;
  last_step_completed?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  onboarding_variations?: InputMaybe<Scalars['jsonb']>;
  role_choices?: InputMaybe<Scalars['jsonb']>;
  role_choices_length?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Client_Prospect_Sum_Fields = {
  __typename?: 'client_prospect_sum_fields';
  country_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
  role_choices_length: Maybe<Scalars['Int']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** update columns of table "client_prospect" */
export enum Client_Prospect_Update_Column {
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CompanySize = 'company_size',
  /** column name */
  Country = 'country',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmployeeType = 'employee_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsUsingPeoOrEor = 'is_using_peo_or_eor',
  /** column name */
  LastStepCompleted = 'last_step_completed',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OnboardingVariations = 'onboarding_variations',
  /** column name */
  RoleChoices = 'role_choices',
  /** column name */
  RoleChoicesLength = 'role_choices_length',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id',
}

export type Client_Prospect_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Client_Prospect_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Client_Prospect_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Client_Prospect_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Client_Prospect_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Prospect_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Client_Prospect_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Prospect_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Prospect_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Prospect_Var_Pop_Fields = {
  __typename?: 'client_prospect_var_pop_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
  role_choices_length: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Client_Prospect_Var_Samp_Fields = {
  __typename?: 'client_prospect_var_samp_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
  role_choices_length: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Client_Prospect_Variance_Fields = {
  __typename?: 'client_prospect_variance_fields';
  country_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
  role_choices_length: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "client_versions" */
export type Client_Versions = {
  __typename?: 'client_versions';
  is_active: Scalars['Boolean'];
  version: Scalars['String'];
};

/** aggregated selection of "client_versions" */
export type Client_Versions_Aggregate = {
  __typename?: 'client_versions_aggregate';
  aggregate: Maybe<Client_Versions_Aggregate_Fields>;
  nodes: Array<Client_Versions>;
};

/** aggregate fields of "client_versions" */
export type Client_Versions_Aggregate_Fields = {
  __typename?: 'client_versions_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Client_Versions_Max_Fields>;
  min: Maybe<Client_Versions_Min_Fields>;
};

/** aggregate fields of "client_versions" */
export type Client_Versions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Versions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "client_versions". All fields are combined with a logical 'AND'. */
export type Client_Versions_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Versions_Bool_Exp>>;
  _not?: InputMaybe<Client_Versions_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Versions_Bool_Exp>>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_versions" */
export enum Client_Versions_Constraint {
  /** unique or primary key constraint on columns "version" */
  ClientVersionsPkey = 'client_versions_pkey',
  /** unique or primary key constraint on columns "version" */
  ClientVersionsVersionKey = 'client_versions_version_key',
}

/** input type for inserting data into table "client_versions" */
export type Client_Versions_Insert_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Client_Versions_Max_Fields = {
  __typename?: 'client_versions_max_fields';
  version: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Client_Versions_Min_Fields = {
  __typename?: 'client_versions_min_fields';
  version: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "client_versions" */
export type Client_Versions_Mutation_Response = {
  __typename?: 'client_versions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Versions>;
};

/** on_conflict condition type for table "client_versions" */
export type Client_Versions_On_Conflict = {
  constraint: Client_Versions_Constraint;
  update_columns?: Array<Client_Versions_Update_Column>;
  where?: InputMaybe<Client_Versions_Bool_Exp>;
};

/** Ordering options when selecting data from "client_versions". */
export type Client_Versions_Order_By = {
  is_active?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_versions */
export type Client_Versions_Pk_Columns_Input = {
  version: Scalars['String'];
};

/** select columns of table "client_versions" */
export enum Client_Versions_Select_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Version = 'version',
}

/** input type for updating data in table "client_versions" */
export type Client_Versions_Set_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "client_versions" */
export type Client_Versions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Versions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Versions_Stream_Cursor_Value_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  version?: InputMaybe<Scalars['String']>;
};

/** update columns of table "client_versions" */
export enum Client_Versions_Update_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Version = 'version',
}

export type Client_Versions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Versions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Versions_Bool_Exp;
};

/** columns and relationships of "company" */
export type Company = {
  __typename?: 'company';
  ai_industry_version: Maybe<Scalars['String']>;
  /** One or more industries return by AI without any constrains of industry choices */
  ai_raw_industry: Maybe<Scalars['String']>;
  /** An array relationship */
  candidate_work_experiences: Array<Candidate_Work_Experience>;
  /** An aggregate relationship */
  candidate_work_experiences_aggregate: Candidate_Work_Experience_Aggregate;
  /** An array relationship */
  company_name_synonyms: Array<Company_Name_Synonym>;
  /** An aggregate relationship */
  company_name_synonyms_aggregate: Company_Name_Synonym_Aggregate;
  /** An array relationship */
  company_pdl_blobs: Array<Company_Pdl_Blob>;
  /** An aggregate relationship */
  company_pdl_blobs_aggregate: Company_Pdl_Blob_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  industry: Maybe<Scalars['String']>;
  industry_confidence: Maybe<Scalars['String']>;
  industry_source: Maybe<Scalars['String']>;
  industry_status: Maybe<Scalars['String']>;
  /** Evaluates if a company qualifies as a recent startup based on its size, funding stage, and founding year. */
  is_startup: Maybe<Scalars['Boolean']>;
  /** Determines if a company is a top entity based on its name or funding stage. */
  is_top_company: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pdl_company_size: Maybe<Scalars['String']>;
  pdl_employee_count: Maybe<Scalars['Int']>;
  pdl_founded_year: Maybe<Scalars['Int']>;
  pdl_latest_funding_stage: Maybe<Scalars['String']>;
  pdl_raw_industry: Maybe<Scalars['String']>;
  /** Company stripped down name. Ex: armuarsinc is the sd_name of ArmuarsInc. and Armuars Inc */
  sd_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "company" */
export type CompanyCandidate_Work_ExperiencesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyCandidate_Work_Experiences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyCompany_Name_SynonymsArgs = {
  distinct_on?: InputMaybe<Array<Company_Name_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Name_Synonym_Order_By>>;
  where?: InputMaybe<Company_Name_Synonym_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyCompany_Name_Synonyms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Name_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Name_Synonym_Order_By>>;
  where?: InputMaybe<Company_Name_Synonym_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyCompany_Pdl_BlobsArgs = {
  distinct_on?: InputMaybe<Array<Company_Pdl_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Pdl_Blob_Order_By>>;
  where?: InputMaybe<Company_Pdl_Blob_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyCompany_Pdl_Blobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Pdl_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Pdl_Blob_Order_By>>;
  where?: InputMaybe<Company_Pdl_Blob_Bool_Exp>;
};

/** aggregated selection of "company" */
export type Company_Aggregate = {
  __typename?: 'company_aggregate';
  aggregate: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_Fields = {
  __typename?: 'company_aggregate_fields';
  avg: Maybe<Company_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Company_Max_Fields>;
  min: Maybe<Company_Min_Fields>;
  stddev: Maybe<Company_Stddev_Fields>;
  stddev_pop: Maybe<Company_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Company_Stddev_Samp_Fields>;
  sum: Maybe<Company_Sum_Fields>;
  var_pop: Maybe<Company_Var_Pop_Fields>;
  var_samp: Maybe<Company_Var_Samp_Fields>;
  variance: Maybe<Company_Variance_Fields>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Company_Avg_Fields = {
  __typename?: 'company_avg_fields';
  id: Maybe<Scalars['Float']>;
  pdl_employee_count: Maybe<Scalars['Float']>;
  pdl_founded_year: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Bool_Exp>>;
  _not?: InputMaybe<Company_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Bool_Exp>>;
  ai_industry_version?: InputMaybe<String_Comparison_Exp>;
  ai_raw_industry?: InputMaybe<String_Comparison_Exp>;
  candidate_work_experiences?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
  candidate_work_experiences_aggregate?: InputMaybe<Candidate_Work_Experience_Aggregate_Bool_Exp>;
  company_name_synonyms?: InputMaybe<Company_Name_Synonym_Bool_Exp>;
  company_name_synonyms_aggregate?: InputMaybe<Company_Name_Synonym_Aggregate_Bool_Exp>;
  company_pdl_blobs?: InputMaybe<Company_Pdl_Blob_Bool_Exp>;
  company_pdl_blobs_aggregate?: InputMaybe<Company_Pdl_Blob_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  industry?: InputMaybe<String_Comparison_Exp>;
  industry_confidence?: InputMaybe<String_Comparison_Exp>;
  industry_source?: InputMaybe<String_Comparison_Exp>;
  industry_status?: InputMaybe<String_Comparison_Exp>;
  is_startup?: InputMaybe<Boolean_Comparison_Exp>;
  is_top_company?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pdl_company_size?: InputMaybe<String_Comparison_Exp>;
  pdl_employee_count?: InputMaybe<Int_Comparison_Exp>;
  pdl_founded_year?: InputMaybe<Int_Comparison_Exp>;
  pdl_latest_funding_stage?: InputMaybe<String_Comparison_Exp>;
  pdl_raw_industry?: InputMaybe<String_Comparison_Exp>;
  sd_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "company" */
export enum Company_Constraint {
  /** unique or primary key constraint on columns "name" */
  CompanyCompanyNameKey = 'company_company_name_key',
  /** unique or primary key constraint on columns "id" */
  CompanyPkey = 'company_pkey',
}

/** input type for incrementing numeric columns in table "company" */
export type Company_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  pdl_employee_count?: InputMaybe<Scalars['Int']>;
  pdl_founded_year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company" */
export type Company_Insert_Input = {
  ai_industry_version?: InputMaybe<Scalars['String']>;
  /** One or more industries return by AI without any constrains of industry choices */
  ai_raw_industry?: InputMaybe<Scalars['String']>;
  candidate_work_experiences?: InputMaybe<Candidate_Work_Experience_Arr_Rel_Insert_Input>;
  company_name_synonyms?: InputMaybe<Company_Name_Synonym_Arr_Rel_Insert_Input>;
  company_pdl_blobs?: InputMaybe<Company_Pdl_Blob_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  industry_confidence?: InputMaybe<Scalars['String']>;
  industry_source?: InputMaybe<Scalars['String']>;
  industry_status?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pdl_company_size?: InputMaybe<Scalars['String']>;
  pdl_employee_count?: InputMaybe<Scalars['Int']>;
  pdl_founded_year?: InputMaybe<Scalars['Int']>;
  pdl_latest_funding_stage?: InputMaybe<Scalars['String']>;
  pdl_raw_industry?: InputMaybe<Scalars['String']>;
  /** Company stripped down name. Ex: armuarsinc is the sd_name of ArmuarsInc. and Armuars Inc */
  sd_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
  __typename?: 'company_max_fields';
  ai_industry_version: Maybe<Scalars['String']>;
  /** One or more industries return by AI without any constrains of industry choices */
  ai_raw_industry: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  industry: Maybe<Scalars['String']>;
  industry_confidence: Maybe<Scalars['String']>;
  industry_source: Maybe<Scalars['String']>;
  industry_status: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  pdl_company_size: Maybe<Scalars['String']>;
  pdl_employee_count: Maybe<Scalars['Int']>;
  pdl_founded_year: Maybe<Scalars['Int']>;
  pdl_latest_funding_stage: Maybe<Scalars['String']>;
  pdl_raw_industry: Maybe<Scalars['String']>;
  /** Company stripped down name. Ex: armuarsinc is the sd_name of ArmuarsInc. and Armuars Inc */
  sd_name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
  __typename?: 'company_min_fields';
  ai_industry_version: Maybe<Scalars['String']>;
  /** One or more industries return by AI without any constrains of industry choices */
  ai_raw_industry: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  industry: Maybe<Scalars['String']>;
  industry_confidence: Maybe<Scalars['String']>;
  industry_source: Maybe<Scalars['String']>;
  industry_status: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  pdl_company_size: Maybe<Scalars['String']>;
  pdl_employee_count: Maybe<Scalars['Int']>;
  pdl_founded_year: Maybe<Scalars['Int']>;
  pdl_latest_funding_stage: Maybe<Scalars['String']>;
  pdl_raw_industry: Maybe<Scalars['String']>;
  /** Company stripped down name. Ex: armuarsinc is the sd_name of ArmuarsInc. and Armuars Inc */
  sd_name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "company" */
export type Company_Mutation_Response = {
  __typename?: 'company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company>;
};

/** columns and relationships of "company_name_synonym" */
export type Company_Name_Synonym = {
  __typename?: 'company_name_synonym';
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  sd_synonym: Maybe<Scalars['String']>;
  synonym: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "company_name_synonym" */
export type Company_Name_Synonym_Aggregate = {
  __typename?: 'company_name_synonym_aggregate';
  aggregate: Maybe<Company_Name_Synonym_Aggregate_Fields>;
  nodes: Array<Company_Name_Synonym>;
};

export type Company_Name_Synonym_Aggregate_Bool_Exp = {
  count?: InputMaybe<Company_Name_Synonym_Aggregate_Bool_Exp_Count>;
};

export type Company_Name_Synonym_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_Name_Synonym_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Name_Synonym_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "company_name_synonym" */
export type Company_Name_Synonym_Aggregate_Fields = {
  __typename?: 'company_name_synonym_aggregate_fields';
  avg: Maybe<Company_Name_Synonym_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Company_Name_Synonym_Max_Fields>;
  min: Maybe<Company_Name_Synonym_Min_Fields>;
  stddev: Maybe<Company_Name_Synonym_Stddev_Fields>;
  stddev_pop: Maybe<Company_Name_Synonym_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Company_Name_Synonym_Stddev_Samp_Fields>;
  sum: Maybe<Company_Name_Synonym_Sum_Fields>;
  var_pop: Maybe<Company_Name_Synonym_Var_Pop_Fields>;
  var_samp: Maybe<Company_Name_Synonym_Var_Samp_Fields>;
  variance: Maybe<Company_Name_Synonym_Variance_Fields>;
};

/** aggregate fields of "company_name_synonym" */
export type Company_Name_Synonym_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Name_Synonym_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_name_synonym" */
export type Company_Name_Synonym_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Name_Synonym_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Name_Synonym_Max_Order_By>;
  min?: InputMaybe<Company_Name_Synonym_Min_Order_By>;
  stddev?: InputMaybe<Company_Name_Synonym_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Name_Synonym_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Name_Synonym_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Name_Synonym_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Name_Synonym_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Name_Synonym_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Name_Synonym_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "company_name_synonym" */
export type Company_Name_Synonym_Arr_Rel_Insert_Input = {
  data: Array<Company_Name_Synonym_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Name_Synonym_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Name_Synonym_Avg_Fields = {
  __typename?: 'company_name_synonym_avg_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company_name_synonym" */
export type Company_Name_Synonym_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company_name_synonym". All fields are combined with a logical 'AND'. */
export type Company_Name_Synonym_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Name_Synonym_Bool_Exp>>;
  _not?: InputMaybe<Company_Name_Synonym_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Name_Synonym_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  sd_synonym?: InputMaybe<String_Comparison_Exp>;
  synonym?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_name_synonym" */
export enum Company_Name_Synonym_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyNameSynonymPkey = 'company_name_synonym_pkey',
}

/** input type for incrementing numeric columns in table "company_name_synonym" */
export type Company_Name_Synonym_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_name_synonym" */
export type Company_Name_Synonym_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  sd_synonym?: InputMaybe<Scalars['String']>;
  synonym?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Company_Name_Synonym_Max_Fields = {
  __typename?: 'company_name_synonym_max_fields';
  company_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  sd_synonym: Maybe<Scalars['String']>;
  synonym: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "company_name_synonym" */
export type Company_Name_Synonym_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sd_synonym?: InputMaybe<Order_By>;
  synonym?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Name_Synonym_Min_Fields = {
  __typename?: 'company_name_synonym_min_fields';
  company_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  sd_synonym: Maybe<Scalars['String']>;
  synonym: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "company_name_synonym" */
export type Company_Name_Synonym_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sd_synonym?: InputMaybe<Order_By>;
  synonym?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_name_synonym" */
export type Company_Name_Synonym_Mutation_Response = {
  __typename?: 'company_name_synonym_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Name_Synonym>;
};

/** on_conflict condition type for table "company_name_synonym" */
export type Company_Name_Synonym_On_Conflict = {
  constraint: Company_Name_Synonym_Constraint;
  update_columns?: Array<Company_Name_Synonym_Update_Column>;
  where?: InputMaybe<Company_Name_Synonym_Bool_Exp>;
};

/** Ordering options when selecting data from "company_name_synonym". */
export type Company_Name_Synonym_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sd_synonym?: InputMaybe<Order_By>;
  synonym?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_name_synonym */
export type Company_Name_Synonym_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company_name_synonym" */
export enum Company_Name_Synonym_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SdSynonym = 'sd_synonym',
  /** column name */
  Synonym = 'synonym',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "company_name_synonym" */
export type Company_Name_Synonym_Set_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  sd_synonym?: InputMaybe<Scalars['String']>;
  synonym?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Company_Name_Synonym_Stddev_Fields = {
  __typename?: 'company_name_synonym_stddev_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company_name_synonym" */
export type Company_Name_Synonym_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Name_Synonym_Stddev_Pop_Fields = {
  __typename?: 'company_name_synonym_stddev_pop_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company_name_synonym" */
export type Company_Name_Synonym_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Name_Synonym_Stddev_Samp_Fields = {
  __typename?: 'company_name_synonym_stddev_samp_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company_name_synonym" */
export type Company_Name_Synonym_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "company_name_synonym" */
export type Company_Name_Synonym_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Name_Synonym_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Name_Synonym_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  sd_synonym?: InputMaybe<Scalars['String']>;
  synonym?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Company_Name_Synonym_Sum_Fields = {
  __typename?: 'company_name_synonym_sum_fields';
  company_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company_name_synonym" */
export type Company_Name_Synonym_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "company_name_synonym" */
export enum Company_Name_Synonym_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SdSynonym = 'sd_synonym',
  /** column name */
  Synonym = 'synonym',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Company_Name_Synonym_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Name_Synonym_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Name_Synonym_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Name_Synonym_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Name_Synonym_Var_Pop_Fields = {
  __typename?: 'company_name_synonym_var_pop_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company_name_synonym" */
export type Company_Name_Synonym_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Name_Synonym_Var_Samp_Fields = {
  __typename?: 'company_name_synonym_var_samp_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company_name_synonym" */
export type Company_Name_Synonym_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Name_Synonym_Variance_Fields = {
  __typename?: 'company_name_synonym_variance_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company_name_synonym" */
export type Company_Name_Synonym_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "company" */
export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** on_conflict condition type for table "company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns?: Array<Company_Update_Column>;
  where?: InputMaybe<Company_Bool_Exp>;
};

/** Ordering options when selecting data from "company". */
export type Company_Order_By = {
  ai_industry_version?: InputMaybe<Order_By>;
  ai_raw_industry?: InputMaybe<Order_By>;
  candidate_work_experiences_aggregate?: InputMaybe<Candidate_Work_Experience_Aggregate_Order_By>;
  company_name_synonyms_aggregate?: InputMaybe<Company_Name_Synonym_Aggregate_Order_By>;
  company_pdl_blobs_aggregate?: InputMaybe<Company_Pdl_Blob_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  industry_confidence?: InputMaybe<Order_By>;
  industry_source?: InputMaybe<Order_By>;
  industry_status?: InputMaybe<Order_By>;
  is_startup?: InputMaybe<Order_By>;
  is_top_company?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pdl_company_size?: InputMaybe<Order_By>;
  pdl_employee_count?: InputMaybe<Order_By>;
  pdl_founded_year?: InputMaybe<Order_By>;
  pdl_latest_funding_stage?: InputMaybe<Order_By>;
  pdl_raw_industry?: InputMaybe<Order_By>;
  sd_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "company_pdl_blob" */
export type Company_Pdl_Blob = {
  __typename?: 'company_pdl_blob';
  blob: Scalars['jsonb'];
  /** An object relationship */
  company: Company;
  company_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "company_pdl_blob" */
export type Company_Pdl_BlobBlobArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "company_pdl_blob" */
export type Company_Pdl_Blob_Aggregate = {
  __typename?: 'company_pdl_blob_aggregate';
  aggregate: Maybe<Company_Pdl_Blob_Aggregate_Fields>;
  nodes: Array<Company_Pdl_Blob>;
};

export type Company_Pdl_Blob_Aggregate_Bool_Exp = {
  count?: InputMaybe<Company_Pdl_Blob_Aggregate_Bool_Exp_Count>;
};

export type Company_Pdl_Blob_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_Pdl_Blob_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Pdl_Blob_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "company_pdl_blob" */
export type Company_Pdl_Blob_Aggregate_Fields = {
  __typename?: 'company_pdl_blob_aggregate_fields';
  avg: Maybe<Company_Pdl_Blob_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Company_Pdl_Blob_Max_Fields>;
  min: Maybe<Company_Pdl_Blob_Min_Fields>;
  stddev: Maybe<Company_Pdl_Blob_Stddev_Fields>;
  stddev_pop: Maybe<Company_Pdl_Blob_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Company_Pdl_Blob_Stddev_Samp_Fields>;
  sum: Maybe<Company_Pdl_Blob_Sum_Fields>;
  var_pop: Maybe<Company_Pdl_Blob_Var_Pop_Fields>;
  var_samp: Maybe<Company_Pdl_Blob_Var_Samp_Fields>;
  variance: Maybe<Company_Pdl_Blob_Variance_Fields>;
};

/** aggregate fields of "company_pdl_blob" */
export type Company_Pdl_Blob_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Pdl_Blob_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_pdl_blob" */
export type Company_Pdl_Blob_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Pdl_Blob_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Pdl_Blob_Max_Order_By>;
  min?: InputMaybe<Company_Pdl_Blob_Min_Order_By>;
  stddev?: InputMaybe<Company_Pdl_Blob_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Pdl_Blob_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Pdl_Blob_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Pdl_Blob_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Pdl_Blob_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Pdl_Blob_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Pdl_Blob_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Company_Pdl_Blob_Append_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "company_pdl_blob" */
export type Company_Pdl_Blob_Arr_Rel_Insert_Input = {
  data: Array<Company_Pdl_Blob_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Pdl_Blob_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Pdl_Blob_Avg_Fields = {
  __typename?: 'company_pdl_blob_avg_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company_pdl_blob" */
export type Company_Pdl_Blob_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company_pdl_blob". All fields are combined with a logical 'AND'. */
export type Company_Pdl_Blob_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Pdl_Blob_Bool_Exp>>;
  _not?: InputMaybe<Company_Pdl_Blob_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Pdl_Blob_Bool_Exp>>;
  blob?: InputMaybe<Jsonb_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_pdl_blob" */
export enum Company_Pdl_Blob_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyPdlBlobPkey = 'company_pdl_blob_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Company_Pdl_Blob_Delete_At_Path_Input = {
  blob?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Company_Pdl_Blob_Delete_Elem_Input = {
  blob?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Company_Pdl_Blob_Delete_Key_Input = {
  blob?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "company_pdl_blob" */
export type Company_Pdl_Blob_Inc_Input = {
  company_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company_pdl_blob" */
export type Company_Pdl_Blob_Insert_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Company_Pdl_Blob_Max_Fields = {
  __typename?: 'company_pdl_blob_max_fields';
  company_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "company_pdl_blob" */
export type Company_Pdl_Blob_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Pdl_Blob_Min_Fields = {
  __typename?: 'company_pdl_blob_min_fields';
  company_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "company_pdl_blob" */
export type Company_Pdl_Blob_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_pdl_blob" */
export type Company_Pdl_Blob_Mutation_Response = {
  __typename?: 'company_pdl_blob_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Pdl_Blob>;
};

/** on_conflict condition type for table "company_pdl_blob" */
export type Company_Pdl_Blob_On_Conflict = {
  constraint: Company_Pdl_Blob_Constraint;
  update_columns?: Array<Company_Pdl_Blob_Update_Column>;
  where?: InputMaybe<Company_Pdl_Blob_Bool_Exp>;
};

/** Ordering options when selecting data from "company_pdl_blob". */
export type Company_Pdl_Blob_Order_By = {
  blob?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_pdl_blob */
export type Company_Pdl_Blob_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Company_Pdl_Blob_Prepend_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "company_pdl_blob" */
export enum Company_Pdl_Blob_Select_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "company_pdl_blob" */
export type Company_Pdl_Blob_Set_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Company_Pdl_Blob_Stddev_Fields = {
  __typename?: 'company_pdl_blob_stddev_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company_pdl_blob" */
export type Company_Pdl_Blob_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Pdl_Blob_Stddev_Pop_Fields = {
  __typename?: 'company_pdl_blob_stddev_pop_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company_pdl_blob" */
export type Company_Pdl_Blob_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Pdl_Blob_Stddev_Samp_Fields = {
  __typename?: 'company_pdl_blob_stddev_samp_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company_pdl_blob" */
export type Company_Pdl_Blob_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "company_pdl_blob" */
export type Company_Pdl_Blob_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Pdl_Blob_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Pdl_Blob_Stream_Cursor_Value_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  company_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Company_Pdl_Blob_Sum_Fields = {
  __typename?: 'company_pdl_blob_sum_fields';
  company_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company_pdl_blob" */
export type Company_Pdl_Blob_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "company_pdl_blob" */
export enum Company_Pdl_Blob_Update_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Company_Pdl_Blob_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Company_Pdl_Blob_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Company_Pdl_Blob_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Company_Pdl_Blob_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Company_Pdl_Blob_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Pdl_Blob_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Company_Pdl_Blob_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Pdl_Blob_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Pdl_Blob_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Pdl_Blob_Var_Pop_Fields = {
  __typename?: 'company_pdl_blob_var_pop_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company_pdl_blob" */
export type Company_Pdl_Blob_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Pdl_Blob_Var_Samp_Fields = {
  __typename?: 'company_pdl_blob_var_samp_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company_pdl_blob" */
export type Company_Pdl_Blob_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Pdl_Blob_Variance_Fields = {
  __typename?: 'company_pdl_blob_variance_fields';
  company_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company_pdl_blob" */
export type Company_Pdl_Blob_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company */
export type Company_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company" */
export enum Company_Select_Column {
  /** column name */
  AiIndustryVersion = 'ai_industry_version',
  /** column name */
  AiRawIndustry = 'ai_raw_industry',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  IndustryConfidence = 'industry_confidence',
  /** column name */
  IndustrySource = 'industry_source',
  /** column name */
  IndustryStatus = 'industry_status',
  /** column name */
  Name = 'name',
  /** column name */
  PdlCompanySize = 'pdl_company_size',
  /** column name */
  PdlEmployeeCount = 'pdl_employee_count',
  /** column name */
  PdlFoundedYear = 'pdl_founded_year',
  /** column name */
  PdlLatestFundingStage = 'pdl_latest_funding_stage',
  /** column name */
  PdlRawIndustry = 'pdl_raw_industry',
  /** column name */
  SdName = 'sd_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "company" */
export type Company_Set_Input = {
  ai_industry_version?: InputMaybe<Scalars['String']>;
  /** One or more industries return by AI without any constrains of industry choices */
  ai_raw_industry?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  industry_confidence?: InputMaybe<Scalars['String']>;
  industry_source?: InputMaybe<Scalars['String']>;
  industry_status?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pdl_company_size?: InputMaybe<Scalars['String']>;
  pdl_employee_count?: InputMaybe<Scalars['Int']>;
  pdl_founded_year?: InputMaybe<Scalars['Int']>;
  pdl_latest_funding_stage?: InputMaybe<Scalars['String']>;
  pdl_raw_industry?: InputMaybe<Scalars['String']>;
  /** Company stripped down name. Ex: armuarsinc is the sd_name of ArmuarsInc. and Armuars Inc */
  sd_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Company size choices */
export type Company_Size_Choices = {
  __typename?: 'company_size_choices';
  value: Scalars['String'];
};

/** aggregated selection of "company_size_choices" */
export type Company_Size_Choices_Aggregate = {
  __typename?: 'company_size_choices_aggregate';
  aggregate: Maybe<Company_Size_Choices_Aggregate_Fields>;
  nodes: Array<Company_Size_Choices>;
};

/** aggregate fields of "company_size_choices" */
export type Company_Size_Choices_Aggregate_Fields = {
  __typename?: 'company_size_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Company_Size_Choices_Max_Fields>;
  min: Maybe<Company_Size_Choices_Min_Fields>;
};

/** aggregate fields of "company_size_choices" */
export type Company_Size_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Size_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "company_size_choices". All fields are combined with a logical 'AND'. */
export type Company_Size_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Size_Choices_Bool_Exp>>;
  _not?: InputMaybe<Company_Size_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Size_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_size_choices" */
export enum Company_Size_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CompanySizeChoicesPkey = 'company_size_choices_pkey',
}

export enum Company_Size_Choices_Enum {
  ElevenTwentyFive = 'ELEVEN_TWENTY_FIVE',
  FiftyPlus = 'FIFTY_PLUS',
  OneTen = 'ONE_TEN',
  TwentySixFifty = 'TWENTY_SIX_FIFTY',
}

/** Boolean expression to compare columns of type "company_size_choices_enum". All fields are combined with logical 'AND'. */
export type Company_Size_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Company_Size_Choices_Enum>;
  _in?: InputMaybe<Array<Company_Size_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Company_Size_Choices_Enum>;
  _nin?: InputMaybe<Array<Company_Size_Choices_Enum>>;
};

/** input type for inserting data into table "company_size_choices" */
export type Company_Size_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Company_Size_Choices_Max_Fields = {
  __typename?: 'company_size_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Company_Size_Choices_Min_Fields = {
  __typename?: 'company_size_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "company_size_choices" */
export type Company_Size_Choices_Mutation_Response = {
  __typename?: 'company_size_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Size_Choices>;
};

/** on_conflict condition type for table "company_size_choices" */
export type Company_Size_Choices_On_Conflict = {
  constraint: Company_Size_Choices_Constraint;
  update_columns?: Array<Company_Size_Choices_Update_Column>;
  where?: InputMaybe<Company_Size_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "company_size_choices". */
export type Company_Size_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_size_choices */
export type Company_Size_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "company_size_choices" */
export enum Company_Size_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "company_size_choices" */
export type Company_Size_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "company_size_choices" */
export type Company_Size_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Size_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Size_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "company_size_choices" */
export enum Company_Size_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Company_Size_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Size_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Size_Choices_Bool_Exp;
};

/** aggregate stddev on columns */
export type Company_Stddev_Fields = {
  __typename?: 'company_stddev_fields';
  id: Maybe<Scalars['Float']>;
  pdl_employee_count: Maybe<Scalars['Float']>;
  pdl_founded_year: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Stddev_Pop_Fields = {
  __typename?: 'company_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  pdl_employee_count: Maybe<Scalars['Float']>;
  pdl_founded_year: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Stddev_Samp_Fields = {
  __typename?: 'company_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  pdl_employee_count: Maybe<Scalars['Float']>;
  pdl_founded_year: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company" */
export type Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Stream_Cursor_Value_Input = {
  ai_industry_version?: InputMaybe<Scalars['String']>;
  /** One or more industries return by AI without any constrains of industry choices */
  ai_raw_industry?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  industry_confidence?: InputMaybe<Scalars['String']>;
  industry_source?: InputMaybe<Scalars['String']>;
  industry_status?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pdl_company_size?: InputMaybe<Scalars['String']>;
  pdl_employee_count?: InputMaybe<Scalars['Int']>;
  pdl_founded_year?: InputMaybe<Scalars['Int']>;
  pdl_latest_funding_stage?: InputMaybe<Scalars['String']>;
  pdl_raw_industry?: InputMaybe<Scalars['String']>;
  /** Company stripped down name. Ex: armuarsinc is the sd_name of ArmuarsInc. and Armuars Inc */
  sd_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Company_Sum_Fields = {
  __typename?: 'company_sum_fields';
  id: Maybe<Scalars['Int']>;
  pdl_employee_count: Maybe<Scalars['Int']>;
  pdl_founded_year: Maybe<Scalars['Int']>;
};

/** update columns of table "company" */
export enum Company_Update_Column {
  /** column name */
  AiIndustryVersion = 'ai_industry_version',
  /** column name */
  AiRawIndustry = 'ai_raw_industry',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  IndustryConfidence = 'industry_confidence',
  /** column name */
  IndustrySource = 'industry_source',
  /** column name */
  IndustryStatus = 'industry_status',
  /** column name */
  Name = 'name',
  /** column name */
  PdlCompanySize = 'pdl_company_size',
  /** column name */
  PdlEmployeeCount = 'pdl_employee_count',
  /** column name */
  PdlFoundedYear = 'pdl_founded_year',
  /** column name */
  PdlLatestFundingStage = 'pdl_latest_funding_stage',
  /** column name */
  PdlRawIndustry = 'pdl_raw_industry',
  /** column name */
  SdName = 'sd_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Company_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Var_Pop_Fields = {
  __typename?: 'company_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  pdl_employee_count: Maybe<Scalars['Float']>;
  pdl_founded_year: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Var_Samp_Fields = {
  __typename?: 'company_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  pdl_employee_count: Maybe<Scalars['Float']>;
  pdl_founded_year: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Variance_Fields = {
  __typename?: 'company_variance_fields';
  id: Maybe<Scalars['Float']>;
  pdl_employee_count: Maybe<Scalars['Float']>;
  pdl_founded_year: Maybe<Scalars['Float']>;
};

/** columns and relationships of "contact" */
export type Contact = {
  __typename?: 'contact';
  created_at: Scalars['timestamptz'];
  earliest_referred_date: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  linkedin_contact: Maybe<Linkedin_Contact>;
  linkedin_profile_url: Scalars['String'];
  /** An object relationship */
  manual_contact: Maybe<Manual_Contact>;
  /** An array relationship */
  manual_contacts: Array<Manual_Contact>;
  /** An aggregate relationship */
  manual_contacts_aggregate: Manual_Contact_Aggregate;
  recruiter_contact_action_count: Scalars['Int'];
  /** An array relationship */
  recruiter_contact_actions: Array<Recruiter_Contact_Action>;
  /** An aggregate relationship */
  recruiter_contact_actions_aggregate: Recruiter_Contact_Action_Aggregate;
  /** An array relationship */
  referral_statuses: Array<Referral_Status>;
  /** An aggregate relationship */
  referral_statuses_aggregate: Referral_Status_Aggregate;
  referred_count: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  userContactByContact: Array<User_Contact>;
  /** An aggregate relationship */
  userContactByContact_aggregate: User_Contact_Aggregate;
};

/** columns and relationships of "contact" */
export type ContactManual_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Manual_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manual_Contact_Order_By>>;
  where?: InputMaybe<Manual_Contact_Bool_Exp>;
};

/** columns and relationships of "contact" */
export type ContactManual_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manual_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manual_Contact_Order_By>>;
  where?: InputMaybe<Manual_Contact_Bool_Exp>;
};

/** columns and relationships of "contact" */
export type ContactRecruiter_Contact_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Recruiter_Contact_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recruiter_Contact_Action_Order_By>>;
  where?: InputMaybe<Recruiter_Contact_Action_Bool_Exp>;
};

/** columns and relationships of "contact" */
export type ContactRecruiter_Contact_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recruiter_Contact_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recruiter_Contact_Action_Order_By>>;
  where?: InputMaybe<Recruiter_Contact_Action_Bool_Exp>;
};

/** columns and relationships of "contact" */
export type ContactReferral_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Referral_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Status_Order_By>>;
  where?: InputMaybe<Referral_Status_Bool_Exp>;
};

/** columns and relationships of "contact" */
export type ContactReferral_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Referral_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Status_Order_By>>;
  where?: InputMaybe<Referral_Status_Bool_Exp>;
};

/** columns and relationships of "contact" */
export type ContactUserContactByContactArgs = {
  distinct_on?: InputMaybe<Array<User_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Contact_Order_By>>;
  where?: InputMaybe<User_Contact_Bool_Exp>;
};

/** columns and relationships of "contact" */
export type ContactUserContactByContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Contact_Order_By>>;
  where?: InputMaybe<User_Contact_Bool_Exp>;
};

/** aggregated selection of "contact" */
export type Contact_Aggregate = {
  __typename?: 'contact_aggregate';
  aggregate: Maybe<Contact_Aggregate_Fields>;
  nodes: Array<Contact>;
};

/** aggregate fields of "contact" */
export type Contact_Aggregate_Fields = {
  __typename?: 'contact_aggregate_fields';
  avg: Maybe<Contact_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Contact_Max_Fields>;
  min: Maybe<Contact_Min_Fields>;
  stddev: Maybe<Contact_Stddev_Fields>;
  stddev_pop: Maybe<Contact_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Contact_Stddev_Samp_Fields>;
  sum: Maybe<Contact_Sum_Fields>;
  var_pop: Maybe<Contact_Var_Pop_Fields>;
  var_samp: Maybe<Contact_Var_Samp_Fields>;
  variance: Maybe<Contact_Variance_Fields>;
};

/** aggregate fields of "contact" */
export type Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Contact_Avg_Fields = {
  __typename?: 'contact_avg_fields';
  id: Maybe<Scalars['Float']>;
  recruiter_contact_action_count: Maybe<Scalars['Float']>;
  referred_count: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "contact". All fields are combined with a logical 'AND'. */
export type Contact_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Bool_Exp>>;
  _not?: InputMaybe<Contact_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  earliest_referred_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  linkedin_contact?: InputMaybe<Linkedin_Contact_Bool_Exp>;
  linkedin_profile_url?: InputMaybe<String_Comparison_Exp>;
  manual_contact?: InputMaybe<Manual_Contact_Bool_Exp>;
  manual_contacts?: InputMaybe<Manual_Contact_Bool_Exp>;
  manual_contacts_aggregate?: InputMaybe<Manual_Contact_Aggregate_Bool_Exp>;
  recruiter_contact_action_count?: InputMaybe<Int_Comparison_Exp>;
  recruiter_contact_actions?: InputMaybe<Recruiter_Contact_Action_Bool_Exp>;
  recruiter_contact_actions_aggregate?: InputMaybe<Recruiter_Contact_Action_Aggregate_Bool_Exp>;
  referral_statuses?: InputMaybe<Referral_Status_Bool_Exp>;
  referral_statuses_aggregate?: InputMaybe<Referral_Status_Aggregate_Bool_Exp>;
  referred_count?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  userContactByContact?: InputMaybe<User_Contact_Bool_Exp>;
  userContactByContact_aggregate?: InputMaybe<User_Contact_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "contact" */
export enum Contact_Constraint {
  /** unique or primary key constraint on columns "linkedin_profile_url" */
  ContactLinkedinProfileUrlKey = 'contact_linkedin_profile_url_key',
  /** unique or primary key constraint on columns "id" */
  ContactPkey = 'contact_pkey',
}

/** input type for incrementing numeric columns in table "contact" */
export type Contact_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  recruiter_contact_action_count?: InputMaybe<Scalars['Int']>;
  referred_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "contact" */
export type Contact_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  earliest_referred_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  linkedin_contact?: InputMaybe<Linkedin_Contact_Obj_Rel_Insert_Input>;
  linkedin_profile_url?: InputMaybe<Scalars['String']>;
  manual_contact?: InputMaybe<Manual_Contact_Obj_Rel_Insert_Input>;
  manual_contacts?: InputMaybe<Manual_Contact_Arr_Rel_Insert_Input>;
  recruiter_contact_action_count?: InputMaybe<Scalars['Int']>;
  recruiter_contact_actions?: InputMaybe<Recruiter_Contact_Action_Arr_Rel_Insert_Input>;
  referral_statuses?: InputMaybe<Referral_Status_Arr_Rel_Insert_Input>;
  referred_count?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userContactByContact?: InputMaybe<User_Contact_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Contact_Max_Fields = {
  __typename?: 'contact_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  earliest_referred_date: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  linkedin_profile_url: Maybe<Scalars['String']>;
  recruiter_contact_action_count: Maybe<Scalars['Int']>;
  referred_count: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Contact_Min_Fields = {
  __typename?: 'contact_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  earliest_referred_date: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  linkedin_profile_url: Maybe<Scalars['String']>;
  recruiter_contact_action_count: Maybe<Scalars['Int']>;
  referred_count: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contact" */
export type Contact_Mutation_Response = {
  __typename?: 'contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact>;
};

/** input type for inserting object relation for remote table "contact" */
export type Contact_Obj_Rel_Insert_Input = {
  data: Contact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};

/** on_conflict condition type for table "contact" */
export type Contact_On_Conflict = {
  constraint: Contact_Constraint;
  update_columns?: Array<Contact_Update_Column>;
  where?: InputMaybe<Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "contact". */
export type Contact_Order_By = {
  created_at?: InputMaybe<Order_By>;
  earliest_referred_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Linkedin_Contact_Order_By>;
  linkedin_profile_url?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Manual_Contact_Order_By>;
  manual_contacts_aggregate?: InputMaybe<Manual_Contact_Aggregate_Order_By>;
  recruiter_contact_action_count?: InputMaybe<Order_By>;
  recruiter_contact_actions_aggregate?: InputMaybe<Recruiter_Contact_Action_Aggregate_Order_By>;
  referral_statuses_aggregate?: InputMaybe<Referral_Status_Aggregate_Order_By>;
  referred_count?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userContactByContact_aggregate?: InputMaybe<User_Contact_Aggregate_Order_By>;
};

/** primary key columns input for table: contact */
export type Contact_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contact" */
export enum Contact_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EarliestReferredDate = 'earliest_referred_date',
  /** column name */
  Id = 'id',
  /** column name */
  LinkedinProfileUrl = 'linkedin_profile_url',
  /** column name */
  RecruiterContactActionCount = 'recruiter_contact_action_count',
  /** column name */
  ReferredCount = 'referred_count',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "contact" */
export type Contact_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  earliest_referred_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  linkedin_profile_url?: InputMaybe<Scalars['String']>;
  recruiter_contact_action_count?: InputMaybe<Scalars['Int']>;
  referred_count?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Contact_Stddev_Fields = {
  __typename?: 'contact_stddev_fields';
  id: Maybe<Scalars['Float']>;
  recruiter_contact_action_count: Maybe<Scalars['Float']>;
  referred_count: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Contact_Stddev_Pop_Fields = {
  __typename?: 'contact_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  recruiter_contact_action_count: Maybe<Scalars['Float']>;
  referred_count: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Contact_Stddev_Samp_Fields = {
  __typename?: 'contact_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  recruiter_contact_action_count: Maybe<Scalars['Float']>;
  referred_count: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "contact" */
export type Contact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  earliest_referred_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  linkedin_profile_url?: InputMaybe<Scalars['String']>;
  recruiter_contact_action_count?: InputMaybe<Scalars['Int']>;
  referred_count?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Contact_Sum_Fields = {
  __typename?: 'contact_sum_fields';
  id: Maybe<Scalars['Int']>;
  recruiter_contact_action_count: Maybe<Scalars['Int']>;
  referred_count: Maybe<Scalars['Int']>;
};

/** update columns of table "contact" */
export enum Contact_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EarliestReferredDate = 'earliest_referred_date',
  /** column name */
  Id = 'id',
  /** column name */
  LinkedinProfileUrl = 'linkedin_profile_url',
  /** column name */
  RecruiterContactActionCount = 'recruiter_contact_action_count',
  /** column name */
  ReferredCount = 'referred_count',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Contact_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contact_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contact_Var_Pop_Fields = {
  __typename?: 'contact_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  recruiter_contact_action_count: Maybe<Scalars['Float']>;
  referred_count: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Contact_Var_Samp_Fields = {
  __typename?: 'contact_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  recruiter_contact_action_count: Maybe<Scalars['Float']>;
  referred_count: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Contact_Variance_Fields = {
  __typename?: 'contact_variance_fields';
  id: Maybe<Scalars['Float']>;
  recruiter_contact_action_count: Maybe<Scalars['Float']>;
  referred_count: Maybe<Scalars['Float']>;
};

/** Contract length choices */
export type Contract_Length_Choices = {
  __typename?: 'contract_length_choices';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "contract_length_choices" */
export type Contract_Length_Choices_Aggregate = {
  __typename?: 'contract_length_choices_aggregate';
  aggregate: Maybe<Contract_Length_Choices_Aggregate_Fields>;
  nodes: Array<Contract_Length_Choices>;
};

/** aggregate fields of "contract_length_choices" */
export type Contract_Length_Choices_Aggregate_Fields = {
  __typename?: 'contract_length_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Contract_Length_Choices_Max_Fields>;
  min: Maybe<Contract_Length_Choices_Min_Fields>;
};

/** aggregate fields of "contract_length_choices" */
export type Contract_Length_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contract_Length_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contract_length_choices". All fields are combined with a logical 'AND'. */
export type Contract_Length_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Contract_Length_Choices_Bool_Exp>>;
  _not?: InputMaybe<Contract_Length_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Contract_Length_Choices_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contract_length_choices" */
export enum Contract_Length_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  ContractLengthChoicesPkey = 'contract_length_choices_pkey',
}

export enum Contract_Length_Choices_Enum {
  /** 9 months */
  NineMonths = 'NINE_MONTHS',
  /** 6 months */
  SixMonths = 'SIX_MONTHS',
  /** 3 months */
  ThreeMonths = 'THREE_MONTHS',
  /** 12 months */
  TwelveMonths = 'TWELVE_MONTHS',
}

/** Boolean expression to compare columns of type "contract_length_choices_enum". All fields are combined with logical 'AND'. */
export type Contract_Length_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Contract_Length_Choices_Enum>;
  _in?: InputMaybe<Array<Contract_Length_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Contract_Length_Choices_Enum>;
  _nin?: InputMaybe<Array<Contract_Length_Choices_Enum>>;
};

/** input type for inserting data into table "contract_length_choices" */
export type Contract_Length_Choices_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Contract_Length_Choices_Max_Fields = {
  __typename?: 'contract_length_choices_max_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Contract_Length_Choices_Min_Fields = {
  __typename?: 'contract_length_choices_min_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contract_length_choices" */
export type Contract_Length_Choices_Mutation_Response = {
  __typename?: 'contract_length_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contract_Length_Choices>;
};

/** on_conflict condition type for table "contract_length_choices" */
export type Contract_Length_Choices_On_Conflict = {
  constraint: Contract_Length_Choices_Constraint;
  update_columns?: Array<Contract_Length_Choices_Update_Column>;
  where?: InputMaybe<Contract_Length_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "contract_length_choices". */
export type Contract_Length_Choices_Order_By = {
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contract_length_choices */
export type Contract_Length_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "contract_length_choices" */
export enum Contract_Length_Choices_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "contract_length_choices" */
export type Contract_Length_Choices_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "contract_length_choices" */
export type Contract_Length_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contract_Length_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contract_Length_Choices_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "contract_length_choices" */
export enum Contract_Length_Choices_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

export type Contract_Length_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contract_Length_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contract_Length_Choices_Bool_Exp;
};

/** Table that contains standardized Country names */
export type Country_Choices = {
  __typename?: 'country_choices';
  id: Scalars['Int'];
  name: Scalars['String'];
  place_id: Maybe<Scalars['String']>;
};

/** aggregated selection of "country_choices" */
export type Country_Choices_Aggregate = {
  __typename?: 'country_choices_aggregate';
  aggregate: Maybe<Country_Choices_Aggregate_Fields>;
  nodes: Array<Country_Choices>;
};

/** aggregate fields of "country_choices" */
export type Country_Choices_Aggregate_Fields = {
  __typename?: 'country_choices_aggregate_fields';
  avg: Maybe<Country_Choices_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Country_Choices_Max_Fields>;
  min: Maybe<Country_Choices_Min_Fields>;
  stddev: Maybe<Country_Choices_Stddev_Fields>;
  stddev_pop: Maybe<Country_Choices_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Country_Choices_Stddev_Samp_Fields>;
  sum: Maybe<Country_Choices_Sum_Fields>;
  var_pop: Maybe<Country_Choices_Var_Pop_Fields>;
  var_samp: Maybe<Country_Choices_Var_Samp_Fields>;
  variance: Maybe<Country_Choices_Variance_Fields>;
};

/** aggregate fields of "country_choices" */
export type Country_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Country_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Country_Choices_Avg_Fields = {
  __typename?: 'country_choices_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "country_choices". All fields are combined with a logical 'AND'. */
export type Country_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Choices_Bool_Exp>>;
  _not?: InputMaybe<Country_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Choices_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  place_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "country_choices" */
export enum Country_Choices_Constraint {
  /** unique or primary key constraint on columns "name" */
  CountryChoicesNameKey = 'country_choices_name_key',
  /** unique or primary key constraint on columns "id" */
  CountryChoicesPkey = 'country_choices_pkey',
}

/** input type for incrementing numeric columns in table "country_choices" */
export type Country_Choices_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "country_choices" */
export type Country_Choices_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  place_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Country_Choices_Max_Fields = {
  __typename?: 'country_choices_max_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  place_id: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Country_Choices_Min_Fields = {
  __typename?: 'country_choices_min_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  place_id: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "country_choices" */
export type Country_Choices_Mutation_Response = {
  __typename?: 'country_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Country_Choices>;
};

/** input type for inserting object relation for remote table "country_choices" */
export type Country_Choices_Obj_Rel_Insert_Input = {
  data: Country_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Country_Choices_On_Conflict>;
};

/** on_conflict condition type for table "country_choices" */
export type Country_Choices_On_Conflict = {
  constraint: Country_Choices_Constraint;
  update_columns?: Array<Country_Choices_Update_Column>;
  where?: InputMaybe<Country_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "country_choices". */
export type Country_Choices_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: country_choices */
export type Country_Choices_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "country_choices" */
export enum Country_Choices_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlaceId = 'place_id',
}

/** input type for updating data in table "country_choices" */
export type Country_Choices_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  place_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Country_Choices_Stddev_Fields = {
  __typename?: 'country_choices_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Country_Choices_Stddev_Pop_Fields = {
  __typename?: 'country_choices_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Country_Choices_Stddev_Samp_Fields = {
  __typename?: 'country_choices_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "country_choices" */
export type Country_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Choices_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  place_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Country_Choices_Sum_Fields = {
  __typename?: 'country_choices_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "country_choices" */
export enum Country_Choices_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlaceId = 'place_id',
}

export type Country_Choices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Country_Choices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Country_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Country_Choices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Country_Choices_Var_Pop_Fields = {
  __typename?: 'country_choices_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Country_Choices_Var_Samp_Fields = {
  __typename?: 'country_choices_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Country_Choices_Variance_Fields = {
  __typename?: 'country_choices_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** Table used for storing currency exchange rates  */
export type Currency_Exchange_Rate = {
  __typename?: 'currency_exchange_rate';
  country: Scalars['String'];
  /** An object relationship */
  country_choice: Maybe<Country_Choices>;
  country_id: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  currency_name: Scalars['String'];
  currency_symbol: Scalars['String'];
  finance_exchange_rate: Scalars['numeric'];
  finance_exchange_rate_updated_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  iso_code: Candidate_Curation_Desired_Salary_Currency_Choices_Enum;
  live_exchange_rate: Scalars['numeric'];
  live_exchange_rate_updated_at: Scalars['timestamptz'];
  locale: Scalars['String'];
};

/** aggregated selection of "currency_exchange_rate" */
export type Currency_Exchange_Rate_Aggregate = {
  __typename?: 'currency_exchange_rate_aggregate';
  aggregate: Maybe<Currency_Exchange_Rate_Aggregate_Fields>;
  nodes: Array<Currency_Exchange_Rate>;
};

/** aggregate fields of "currency_exchange_rate" */
export type Currency_Exchange_Rate_Aggregate_Fields = {
  __typename?: 'currency_exchange_rate_aggregate_fields';
  avg: Maybe<Currency_Exchange_Rate_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Currency_Exchange_Rate_Max_Fields>;
  min: Maybe<Currency_Exchange_Rate_Min_Fields>;
  stddev: Maybe<Currency_Exchange_Rate_Stddev_Fields>;
  stddev_pop: Maybe<Currency_Exchange_Rate_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Currency_Exchange_Rate_Stddev_Samp_Fields>;
  sum: Maybe<Currency_Exchange_Rate_Sum_Fields>;
  var_pop: Maybe<Currency_Exchange_Rate_Var_Pop_Fields>;
  var_samp: Maybe<Currency_Exchange_Rate_Var_Samp_Fields>;
  variance: Maybe<Currency_Exchange_Rate_Variance_Fields>;
};

/** aggregate fields of "currency_exchange_rate" */
export type Currency_Exchange_Rate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currency_Exchange_Rate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Currency_Exchange_Rate_Avg_Fields = {
  __typename?: 'currency_exchange_rate_avg_fields';
  country_id: Maybe<Scalars['Float']>;
  finance_exchange_rate: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  live_exchange_rate: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "currency_exchange_rate". All fields are combined with a logical 'AND'. */
export type Currency_Exchange_Rate_Bool_Exp = {
  _and?: InputMaybe<Array<Currency_Exchange_Rate_Bool_Exp>>;
  _not?: InputMaybe<Currency_Exchange_Rate_Bool_Exp>;
  _or?: InputMaybe<Array<Currency_Exchange_Rate_Bool_Exp>>;
  country?: InputMaybe<String_Comparison_Exp>;
  country_choice?: InputMaybe<Country_Choices_Bool_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency_name?: InputMaybe<String_Comparison_Exp>;
  currency_symbol?: InputMaybe<String_Comparison_Exp>;
  finance_exchange_rate?: InputMaybe<Numeric_Comparison_Exp>;
  finance_exchange_rate_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  iso_code?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum_Comparison_Exp>;
  live_exchange_rate?: InputMaybe<Numeric_Comparison_Exp>;
  live_exchange_rate_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "currency_exchange_rate" */
export enum Currency_Exchange_Rate_Constraint {
  /** unique or primary key constraint on columns "iso_code" */
  CurrencyExchangeRateIsoCodeKey = 'currency_exchange_rate_iso_code_key',
  /** unique or primary key constraint on columns "id" */
  CurrencyExchangeRatePkey = 'currency_exchange_rate_pkey',
}

/** input type for incrementing numeric columns in table "currency_exchange_rate" */
export type Currency_Exchange_Rate_Inc_Input = {
  country_id?: InputMaybe<Scalars['Int']>;
  finance_exchange_rate?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  live_exchange_rate?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "currency_exchange_rate" */
export type Currency_Exchange_Rate_Insert_Input = {
  country?: InputMaybe<Scalars['String']>;
  country_choice?: InputMaybe<Country_Choices_Obj_Rel_Insert_Input>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_name?: InputMaybe<Scalars['String']>;
  currency_symbol?: InputMaybe<Scalars['String']>;
  finance_exchange_rate?: InputMaybe<Scalars['numeric']>;
  finance_exchange_rate_updated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  iso_code?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  live_exchange_rate?: InputMaybe<Scalars['numeric']>;
  live_exchange_rate_updated_at?: InputMaybe<Scalars['timestamptz']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Currency_Exchange_Rate_Max_Fields = {
  __typename?: 'currency_exchange_rate_max_fields';
  country: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  currency_name: Maybe<Scalars['String']>;
  currency_symbol: Maybe<Scalars['String']>;
  finance_exchange_rate: Maybe<Scalars['numeric']>;
  finance_exchange_rate_updated_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  live_exchange_rate: Maybe<Scalars['numeric']>;
  live_exchange_rate_updated_at: Maybe<Scalars['timestamptz']>;
  locale: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Currency_Exchange_Rate_Min_Fields = {
  __typename?: 'currency_exchange_rate_min_fields';
  country: Maybe<Scalars['String']>;
  country_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  currency_name: Maybe<Scalars['String']>;
  currency_symbol: Maybe<Scalars['String']>;
  finance_exchange_rate: Maybe<Scalars['numeric']>;
  finance_exchange_rate_updated_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  live_exchange_rate: Maybe<Scalars['numeric']>;
  live_exchange_rate_updated_at: Maybe<Scalars['timestamptz']>;
  locale: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "currency_exchange_rate" */
export type Currency_Exchange_Rate_Mutation_Response = {
  __typename?: 'currency_exchange_rate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Currency_Exchange_Rate>;
};

/** input type for inserting object relation for remote table "currency_exchange_rate" */
export type Currency_Exchange_Rate_Obj_Rel_Insert_Input = {
  data: Currency_Exchange_Rate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Currency_Exchange_Rate_On_Conflict>;
};

/** on_conflict condition type for table "currency_exchange_rate" */
export type Currency_Exchange_Rate_On_Conflict = {
  constraint: Currency_Exchange_Rate_Constraint;
  update_columns?: Array<Currency_Exchange_Rate_Update_Column>;
  where?: InputMaybe<Currency_Exchange_Rate_Bool_Exp>;
};

/** Ordering options when selecting data from "currency_exchange_rate". */
export type Currency_Exchange_Rate_Order_By = {
  country?: InputMaybe<Order_By>;
  country_choice?: InputMaybe<Country_Choices_Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency_name?: InputMaybe<Order_By>;
  currency_symbol?: InputMaybe<Order_By>;
  finance_exchange_rate?: InputMaybe<Order_By>;
  finance_exchange_rate_updated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iso_code?: InputMaybe<Order_By>;
  live_exchange_rate?: InputMaybe<Order_By>;
  live_exchange_rate_updated_at?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
};

/** primary key columns input for table: currency_exchange_rate */
export type Currency_Exchange_Rate_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "currency_exchange_rate" */
export enum Currency_Exchange_Rate_Select_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyName = 'currency_name',
  /** column name */
  CurrencySymbol = 'currency_symbol',
  /** column name */
  FinanceExchangeRate = 'finance_exchange_rate',
  /** column name */
  FinanceExchangeRateUpdatedAt = 'finance_exchange_rate_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'iso_code',
  /** column name */
  LiveExchangeRate = 'live_exchange_rate',
  /** column name */
  LiveExchangeRateUpdatedAt = 'live_exchange_rate_updated_at',
  /** column name */
  Locale = 'locale',
}

/** input type for updating data in table "currency_exchange_rate" */
export type Currency_Exchange_Rate_Set_Input = {
  country?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_name?: InputMaybe<Scalars['String']>;
  currency_symbol?: InputMaybe<Scalars['String']>;
  finance_exchange_rate?: InputMaybe<Scalars['numeric']>;
  finance_exchange_rate_updated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  iso_code?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  live_exchange_rate?: InputMaybe<Scalars['numeric']>;
  live_exchange_rate_updated_at?: InputMaybe<Scalars['timestamptz']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Currency_Exchange_Rate_Stddev_Fields = {
  __typename?: 'currency_exchange_rate_stddev_fields';
  country_id: Maybe<Scalars['Float']>;
  finance_exchange_rate: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  live_exchange_rate: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Currency_Exchange_Rate_Stddev_Pop_Fields = {
  __typename?: 'currency_exchange_rate_stddev_pop_fields';
  country_id: Maybe<Scalars['Float']>;
  finance_exchange_rate: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  live_exchange_rate: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Currency_Exchange_Rate_Stddev_Samp_Fields = {
  __typename?: 'currency_exchange_rate_stddev_samp_fields';
  country_id: Maybe<Scalars['Float']>;
  finance_exchange_rate: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  live_exchange_rate: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "currency_exchange_rate" */
export type Currency_Exchange_Rate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currency_Exchange_Rate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Currency_Exchange_Rate_Stream_Cursor_Value_Input = {
  country?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  currency_name?: InputMaybe<Scalars['String']>;
  currency_symbol?: InputMaybe<Scalars['String']>;
  finance_exchange_rate?: InputMaybe<Scalars['numeric']>;
  finance_exchange_rate_updated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  iso_code?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Enum>;
  live_exchange_rate?: InputMaybe<Scalars['numeric']>;
  live_exchange_rate_updated_at?: InputMaybe<Scalars['timestamptz']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Currency_Exchange_Rate_Sum_Fields = {
  __typename?: 'currency_exchange_rate_sum_fields';
  country_id: Maybe<Scalars['Int']>;
  finance_exchange_rate: Maybe<Scalars['numeric']>;
  id: Maybe<Scalars['Int']>;
  live_exchange_rate: Maybe<Scalars['numeric']>;
};

/** update columns of table "currency_exchange_rate" */
export enum Currency_Exchange_Rate_Update_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyName = 'currency_name',
  /** column name */
  CurrencySymbol = 'currency_symbol',
  /** column name */
  FinanceExchangeRate = 'finance_exchange_rate',
  /** column name */
  FinanceExchangeRateUpdatedAt = 'finance_exchange_rate_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsoCode = 'iso_code',
  /** column name */
  LiveExchangeRate = 'live_exchange_rate',
  /** column name */
  LiveExchangeRateUpdatedAt = 'live_exchange_rate_updated_at',
  /** column name */
  Locale = 'locale',
}

export type Currency_Exchange_Rate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Currency_Exchange_Rate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Currency_Exchange_Rate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Currency_Exchange_Rate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Currency_Exchange_Rate_Var_Pop_Fields = {
  __typename?: 'currency_exchange_rate_var_pop_fields';
  country_id: Maybe<Scalars['Float']>;
  finance_exchange_rate: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  live_exchange_rate: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Currency_Exchange_Rate_Var_Samp_Fields = {
  __typename?: 'currency_exchange_rate_var_samp_fields';
  country_id: Maybe<Scalars['Float']>;
  finance_exchange_rate: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  live_exchange_rate: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Currency_Exchange_Rate_Variance_Fields = {
  __typename?: 'currency_exchange_rate_variance_fields';
  country_id: Maybe<Scalars['Float']>;
  finance_exchange_rate: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  live_exchange_rate: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "dayforce_assignment" */
export type Dayforce_Assignment = {
  __typename?: 'dayforce_assignment';
  created_at: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  dayforce_customer: Dayforce_Customer;
  dayforce_customer_id: Scalars['Int'];
  /** An object relationship */
  dayforce_employee: Dayforce_Employee;
  dayforce_employee_id: Scalars['Int'];
  /** An object relationship */
  dayforce_location: Dayforce_Location;
  dayforce_location_id: Scalars['Int'];
  department_code: Maybe<Scalars['String']>;
  effective_end: Maybe<Scalars['timestamp']>;
  effective_start: Scalars['timestamp'];
  is_coop: Scalars['Boolean'];
  is_primary: Scalars['Boolean'];
  title: Scalars['String'];
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "dayforce_assignment" */
export type Dayforce_Assignment_Aggregate = {
  __typename?: 'dayforce_assignment_aggregate';
  aggregate: Maybe<Dayforce_Assignment_Aggregate_Fields>;
  nodes: Array<Dayforce_Assignment>;
};

export type Dayforce_Assignment_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Dayforce_Assignment_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Dayforce_Assignment_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Dayforce_Assignment_Aggregate_Bool_Exp_Count>;
};

export type Dayforce_Assignment_Aggregate_Bool_Exp_Bool_And = {
  arguments: Dayforce_Assignment_Select_Column_Dayforce_Assignment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dayforce_Assignment_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Dayforce_Assignment_Select_Column_Dayforce_Assignment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dayforce_Assignment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dayforce_assignment" */
export type Dayforce_Assignment_Aggregate_Fields = {
  __typename?: 'dayforce_assignment_aggregate_fields';
  avg: Maybe<Dayforce_Assignment_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Dayforce_Assignment_Max_Fields>;
  min: Maybe<Dayforce_Assignment_Min_Fields>;
  stddev: Maybe<Dayforce_Assignment_Stddev_Fields>;
  stddev_pop: Maybe<Dayforce_Assignment_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Dayforce_Assignment_Stddev_Samp_Fields>;
  sum: Maybe<Dayforce_Assignment_Sum_Fields>;
  var_pop: Maybe<Dayforce_Assignment_Var_Pop_Fields>;
  var_samp: Maybe<Dayforce_Assignment_Var_Samp_Fields>;
  variance: Maybe<Dayforce_Assignment_Variance_Fields>;
};

/** aggregate fields of "dayforce_assignment" */
export type Dayforce_Assignment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dayforce_assignment" */
export type Dayforce_Assignment_Aggregate_Order_By = {
  avg?: InputMaybe<Dayforce_Assignment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dayforce_Assignment_Max_Order_By>;
  min?: InputMaybe<Dayforce_Assignment_Min_Order_By>;
  stddev?: InputMaybe<Dayforce_Assignment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dayforce_Assignment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dayforce_Assignment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dayforce_Assignment_Sum_Order_By>;
  var_pop?: InputMaybe<Dayforce_Assignment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dayforce_Assignment_Var_Samp_Order_By>;
  variance?: InputMaybe<Dayforce_Assignment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dayforce_assignment" */
export type Dayforce_Assignment_Arr_Rel_Insert_Input = {
  data: Array<Dayforce_Assignment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dayforce_Assignment_On_Conflict>;
};

/** aggregate avg on columns */
export type Dayforce_Assignment_Avg_Fields = {
  __typename?: 'dayforce_assignment_avg_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  dayforce_employee_id: Maybe<Scalars['Float']>;
  dayforce_location_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dayforce_assignment" */
export type Dayforce_Assignment_Avg_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  dayforce_employee_id?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dayforce_assignment". All fields are combined with a logical 'AND'. */
export type Dayforce_Assignment_Bool_Exp = {
  _and?: InputMaybe<Array<Dayforce_Assignment_Bool_Exp>>;
  _not?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
  _or?: InputMaybe<Array<Dayforce_Assignment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dayforce_customer?: InputMaybe<Dayforce_Customer_Bool_Exp>;
  dayforce_customer_id?: InputMaybe<Int_Comparison_Exp>;
  dayforce_employee?: InputMaybe<Dayforce_Employee_Bool_Exp>;
  dayforce_employee_id?: InputMaybe<Int_Comparison_Exp>;
  dayforce_location?: InputMaybe<Dayforce_Location_Bool_Exp>;
  dayforce_location_id?: InputMaybe<Int_Comparison_Exp>;
  department_code?: InputMaybe<String_Comparison_Exp>;
  effective_end?: InputMaybe<Timestamp_Comparison_Exp>;
  effective_start?: InputMaybe<Timestamp_Comparison_Exp>;
  is_coop?: InputMaybe<Boolean_Comparison_Exp>;
  is_primary?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "dayforce_assignment" */
export enum Dayforce_Assignment_Constraint {
  /** unique or primary key constraint on columns "effective_start", "dayforce_location_id", "dayforce_customer_id", "dayforce_employee_id" */
  DayforceAssignmentPkey = 'dayforce_assignment_pkey',
}

/** input type for incrementing numeric columns in table "dayforce_assignment" */
export type Dayforce_Assignment_Inc_Input = {
  dayforce_customer_id?: InputMaybe<Scalars['Int']>;
  dayforce_employee_id?: InputMaybe<Scalars['Int']>;
  dayforce_location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dayforce_assignment" */
export type Dayforce_Assignment_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dayforce_customer?: InputMaybe<Dayforce_Customer_Obj_Rel_Insert_Input>;
  dayforce_customer_id?: InputMaybe<Scalars['Int']>;
  dayforce_employee?: InputMaybe<Dayforce_Employee_Obj_Rel_Insert_Input>;
  dayforce_employee_id?: InputMaybe<Scalars['Int']>;
  dayforce_location?: InputMaybe<Dayforce_Location_Obj_Rel_Insert_Input>;
  dayforce_location_id?: InputMaybe<Scalars['Int']>;
  department_code?: InputMaybe<Scalars['String']>;
  effective_end?: InputMaybe<Scalars['timestamp']>;
  effective_start?: InputMaybe<Scalars['timestamp']>;
  is_coop?: InputMaybe<Scalars['Boolean']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Dayforce_Assignment_Max_Fields = {
  __typename?: 'dayforce_assignment_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  dayforce_customer_id: Maybe<Scalars['Int']>;
  dayforce_employee_id: Maybe<Scalars['Int']>;
  dayforce_location_id: Maybe<Scalars['Int']>;
  department_code: Maybe<Scalars['String']>;
  effective_end: Maybe<Scalars['timestamp']>;
  effective_start: Maybe<Scalars['timestamp']>;
  title: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "dayforce_assignment" */
export type Dayforce_Assignment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dayforce_customer_id?: InputMaybe<Order_By>;
  dayforce_employee_id?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
  department_code?: InputMaybe<Order_By>;
  effective_end?: InputMaybe<Order_By>;
  effective_start?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dayforce_Assignment_Min_Fields = {
  __typename?: 'dayforce_assignment_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  dayforce_customer_id: Maybe<Scalars['Int']>;
  dayforce_employee_id: Maybe<Scalars['Int']>;
  dayforce_location_id: Maybe<Scalars['Int']>;
  department_code: Maybe<Scalars['String']>;
  effective_end: Maybe<Scalars['timestamp']>;
  effective_start: Maybe<Scalars['timestamp']>;
  title: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "dayforce_assignment" */
export type Dayforce_Assignment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dayforce_customer_id?: InputMaybe<Order_By>;
  dayforce_employee_id?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
  department_code?: InputMaybe<Order_By>;
  effective_end?: InputMaybe<Order_By>;
  effective_start?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "dayforce_assignment" */
export type Dayforce_Assignment_Mutation_Response = {
  __typename?: 'dayforce_assignment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dayforce_Assignment>;
};

/** on_conflict condition type for table "dayforce_assignment" */
export type Dayforce_Assignment_On_Conflict = {
  constraint: Dayforce_Assignment_Constraint;
  update_columns?: Array<Dayforce_Assignment_Update_Column>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

/** Ordering options when selecting data from "dayforce_assignment". */
export type Dayforce_Assignment_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dayforce_customer?: InputMaybe<Dayforce_Customer_Order_By>;
  dayforce_customer_id?: InputMaybe<Order_By>;
  dayforce_employee?: InputMaybe<Dayforce_Employee_Order_By>;
  dayforce_employee_id?: InputMaybe<Order_By>;
  dayforce_location?: InputMaybe<Dayforce_Location_Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
  department_code?: InputMaybe<Order_By>;
  effective_end?: InputMaybe<Order_By>;
  effective_start?: InputMaybe<Order_By>;
  is_coop?: InputMaybe<Order_By>;
  is_primary?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dayforce_assignment */
export type Dayforce_Assignment_Pk_Columns_Input = {
  dayforce_customer_id: Scalars['Int'];
  dayforce_employee_id: Scalars['Int'];
  dayforce_location_id: Scalars['Int'];
  effective_start: Scalars['timestamp'];
};

/** select columns of table "dayforce_assignment" */
export enum Dayforce_Assignment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayforceCustomerId = 'dayforce_customer_id',
  /** column name */
  DayforceEmployeeId = 'dayforce_employee_id',
  /** column name */
  DayforceLocationId = 'dayforce_location_id',
  /** column name */
  DepartmentCode = 'department_code',
  /** column name */
  EffectiveEnd = 'effective_end',
  /** column name */
  EffectiveStart = 'effective_start',
  /** column name */
  IsCoop = 'is_coop',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "dayforce_assignment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "dayforce_assignment" */
export enum Dayforce_Assignment_Select_Column_Dayforce_Assignment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCoop = 'is_coop',
  /** column name */
  IsPrimary = 'is_primary',
}

/** select "dayforce_assignment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "dayforce_assignment" */
export enum Dayforce_Assignment_Select_Column_Dayforce_Assignment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCoop = 'is_coop',
  /** column name */
  IsPrimary = 'is_primary',
}

/** input type for updating data in table "dayforce_assignment" */
export type Dayforce_Assignment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dayforce_customer_id?: InputMaybe<Scalars['Int']>;
  dayforce_employee_id?: InputMaybe<Scalars['Int']>;
  dayforce_location_id?: InputMaybe<Scalars['Int']>;
  department_code?: InputMaybe<Scalars['String']>;
  effective_end?: InputMaybe<Scalars['timestamp']>;
  effective_start?: InputMaybe<Scalars['timestamp']>;
  is_coop?: InputMaybe<Scalars['Boolean']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Dayforce_Assignment_Stddev_Fields = {
  __typename?: 'dayforce_assignment_stddev_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  dayforce_employee_id: Maybe<Scalars['Float']>;
  dayforce_location_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dayforce_assignment" */
export type Dayforce_Assignment_Stddev_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  dayforce_employee_id?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dayforce_Assignment_Stddev_Pop_Fields = {
  __typename?: 'dayforce_assignment_stddev_pop_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  dayforce_employee_id: Maybe<Scalars['Float']>;
  dayforce_location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dayforce_assignment" */
export type Dayforce_Assignment_Stddev_Pop_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  dayforce_employee_id?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dayforce_Assignment_Stddev_Samp_Fields = {
  __typename?: 'dayforce_assignment_stddev_samp_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  dayforce_employee_id: Maybe<Scalars['Float']>;
  dayforce_location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dayforce_assignment" */
export type Dayforce_Assignment_Stddev_Samp_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  dayforce_employee_id?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dayforce_assignment" */
export type Dayforce_Assignment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dayforce_Assignment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dayforce_Assignment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dayforce_customer_id?: InputMaybe<Scalars['Int']>;
  dayforce_employee_id?: InputMaybe<Scalars['Int']>;
  dayforce_location_id?: InputMaybe<Scalars['Int']>;
  department_code?: InputMaybe<Scalars['String']>;
  effective_end?: InputMaybe<Scalars['timestamp']>;
  effective_start?: InputMaybe<Scalars['timestamp']>;
  is_coop?: InputMaybe<Scalars['Boolean']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Dayforce_Assignment_Sum_Fields = {
  __typename?: 'dayforce_assignment_sum_fields';
  dayforce_customer_id: Maybe<Scalars['Int']>;
  dayforce_employee_id: Maybe<Scalars['Int']>;
  dayforce_location_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dayforce_assignment" */
export type Dayforce_Assignment_Sum_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  dayforce_employee_id?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
};

/** update columns of table "dayforce_assignment" */
export enum Dayforce_Assignment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayforceCustomerId = 'dayforce_customer_id',
  /** column name */
  DayforceEmployeeId = 'dayforce_employee_id',
  /** column name */
  DayforceLocationId = 'dayforce_location_id',
  /** column name */
  DepartmentCode = 'department_code',
  /** column name */
  EffectiveEnd = 'effective_end',
  /** column name */
  EffectiveStart = 'effective_start',
  /** column name */
  IsCoop = 'is_coop',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Dayforce_Assignment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dayforce_Assignment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dayforce_Assignment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dayforce_Assignment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dayforce_Assignment_Var_Pop_Fields = {
  __typename?: 'dayforce_assignment_var_pop_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  dayforce_employee_id: Maybe<Scalars['Float']>;
  dayforce_location_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dayforce_assignment" */
export type Dayforce_Assignment_Var_Pop_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  dayforce_employee_id?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dayforce_Assignment_Var_Samp_Fields = {
  __typename?: 'dayforce_assignment_var_samp_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  dayforce_employee_id: Maybe<Scalars['Float']>;
  dayforce_location_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dayforce_assignment" */
export type Dayforce_Assignment_Var_Samp_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  dayforce_employee_id?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dayforce_Assignment_Variance_Fields = {
  __typename?: 'dayforce_assignment_variance_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  dayforce_employee_id: Maybe<Scalars['Float']>;
  dayforce_location_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dayforce_assignment" */
export type Dayforce_Assignment_Variance_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  dayforce_employee_id?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
};

/** May also be referred to as "union" in dayforce */
export type Dayforce_Customer = {
  __typename?: 'dayforce_customer';
  created_at: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  dayforce_assignments: Array<Dayforce_Assignment>;
  /** An aggregate relationship */
  dayforce_assignments_aggregate: Dayforce_Assignment_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  organization: Maybe<Organization>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** May also be referred to as "union" in dayforce */
export type Dayforce_CustomerDayforce_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Assignment_Order_By>>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

/** May also be referred to as "union" in dayforce */
export type Dayforce_CustomerDayforce_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Assignment_Order_By>>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

/** aggregated selection of "dayforce_customer" */
export type Dayforce_Customer_Aggregate = {
  __typename?: 'dayforce_customer_aggregate';
  aggregate: Maybe<Dayforce_Customer_Aggregate_Fields>;
  nodes: Array<Dayforce_Customer>;
};

/** aggregate fields of "dayforce_customer" */
export type Dayforce_Customer_Aggregate_Fields = {
  __typename?: 'dayforce_customer_aggregate_fields';
  avg: Maybe<Dayforce_Customer_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Dayforce_Customer_Max_Fields>;
  min: Maybe<Dayforce_Customer_Min_Fields>;
  stddev: Maybe<Dayforce_Customer_Stddev_Fields>;
  stddev_pop: Maybe<Dayforce_Customer_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Dayforce_Customer_Stddev_Samp_Fields>;
  sum: Maybe<Dayforce_Customer_Sum_Fields>;
  var_pop: Maybe<Dayforce_Customer_Var_Pop_Fields>;
  var_samp: Maybe<Dayforce_Customer_Var_Samp_Fields>;
  variance: Maybe<Dayforce_Customer_Variance_Fields>;
};

/** aggregate fields of "dayforce_customer" */
export type Dayforce_Customer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dayforce_Customer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dayforce_Customer_Avg_Fields = {
  __typename?: 'dayforce_customer_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dayforce_customer". All fields are combined with a logical 'AND'. */
export type Dayforce_Customer_Bool_Exp = {
  _and?: InputMaybe<Array<Dayforce_Customer_Bool_Exp>>;
  _not?: InputMaybe<Dayforce_Customer_Bool_Exp>;
  _or?: InputMaybe<Array<Dayforce_Customer_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dayforce_assignments?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
  dayforce_assignments_aggregate?: InputMaybe<Dayforce_Assignment_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "dayforce_customer" */
export enum Dayforce_Customer_Constraint {
  /** unique or primary key constraint on columns "id" */
  DayforceCustomerPkey = 'dayforce_customer_pkey',
}

/** input type for incrementing numeric columns in table "dayforce_customer" */
export type Dayforce_Customer_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dayforce_customer" */
export type Dayforce_Customer_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dayforce_assignments?: InputMaybe<Dayforce_Assignment_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Dayforce_Customer_Max_Fields = {
  __typename?: 'dayforce_customer_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Dayforce_Customer_Min_Fields = {
  __typename?: 'dayforce_customer_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "dayforce_customer" */
export type Dayforce_Customer_Mutation_Response = {
  __typename?: 'dayforce_customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dayforce_Customer>;
};

/** input type for inserting object relation for remote table "dayforce_customer" */
export type Dayforce_Customer_Obj_Rel_Insert_Input = {
  data: Dayforce_Customer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dayforce_Customer_On_Conflict>;
};

/** on_conflict condition type for table "dayforce_customer" */
export type Dayforce_Customer_On_Conflict = {
  constraint: Dayforce_Customer_Constraint;
  update_columns?: Array<Dayforce_Customer_Update_Column>;
  where?: InputMaybe<Dayforce_Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "dayforce_customer". */
export type Dayforce_Customer_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dayforce_assignments_aggregate?: InputMaybe<Dayforce_Assignment_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dayforce_customer */
export type Dayforce_Customer_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "dayforce_customer" */
export enum Dayforce_Customer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "dayforce_customer" */
export type Dayforce_Customer_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Dayforce_Customer_Stddev_Fields = {
  __typename?: 'dayforce_customer_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dayforce_Customer_Stddev_Pop_Fields = {
  __typename?: 'dayforce_customer_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dayforce_Customer_Stddev_Samp_Fields = {
  __typename?: 'dayforce_customer_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dayforce_customer" */
export type Dayforce_Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dayforce_Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dayforce_Customer_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Dayforce_Customer_Sum_Fields = {
  __typename?: 'dayforce_customer_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "dayforce_customer" */
export enum Dayforce_Customer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Dayforce_Customer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dayforce_Customer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dayforce_Customer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dayforce_Customer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dayforce_Customer_Var_Pop_Fields = {
  __typename?: 'dayforce_customer_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dayforce_Customer_Var_Samp_Fields = {
  __typename?: 'dayforce_customer_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dayforce_Customer_Variance_Fields = {
  __typename?: 'dayforce_customer_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dayforce_employee" */
export type Dayforce_Employee = {
  __typename?: 'dayforce_employee';
  /** An object relationship */
  candidate: Maybe<Candidate>;
  common_name: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  dayforce_assignments: Array<Dayforce_Assignment>;
  /** An aggregate relationship */
  dayforce_assignments_aggregate: Dayforce_Assignment_Aggregate;
  display_name: Maybe<Scalars['String']>;
  firstname: Maybe<Scalars['String']>;
  hire_date: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  icims_person: Maybe<Icims_Profile_Person>;
  icims_person_profile_id: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  lastname: Maybe<Scalars['String']>;
  original_hire_date: Maybe<Scalars['timestamp']>;
  termination_date: Maybe<Scalars['timestamp']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "dayforce_employee" */
export type Dayforce_EmployeeDayforce_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Assignment_Order_By>>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

/** columns and relationships of "dayforce_employee" */
export type Dayforce_EmployeeDayforce_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Assignment_Order_By>>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

/** aggregated selection of "dayforce_employee" */
export type Dayforce_Employee_Aggregate = {
  __typename?: 'dayforce_employee_aggregate';
  aggregate: Maybe<Dayforce_Employee_Aggregate_Fields>;
  nodes: Array<Dayforce_Employee>;
};

/** aggregate fields of "dayforce_employee" */
export type Dayforce_Employee_Aggregate_Fields = {
  __typename?: 'dayforce_employee_aggregate_fields';
  avg: Maybe<Dayforce_Employee_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Dayforce_Employee_Max_Fields>;
  min: Maybe<Dayforce_Employee_Min_Fields>;
  stddev: Maybe<Dayforce_Employee_Stddev_Fields>;
  stddev_pop: Maybe<Dayforce_Employee_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Dayforce_Employee_Stddev_Samp_Fields>;
  sum: Maybe<Dayforce_Employee_Sum_Fields>;
  var_pop: Maybe<Dayforce_Employee_Var_Pop_Fields>;
  var_samp: Maybe<Dayforce_Employee_Var_Samp_Fields>;
  variance: Maybe<Dayforce_Employee_Variance_Fields>;
};

/** aggregate fields of "dayforce_employee" */
export type Dayforce_Employee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dayforce_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dayforce_Employee_Avg_Fields = {
  __typename?: 'dayforce_employee_avg_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dayforce_employee". All fields are combined with a logical 'AND'. */
export type Dayforce_Employee_Bool_Exp = {
  _and?: InputMaybe<Array<Dayforce_Employee_Bool_Exp>>;
  _not?: InputMaybe<Dayforce_Employee_Bool_Exp>;
  _or?: InputMaybe<Array<Dayforce_Employee_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  common_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dayforce_assignments?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
  dayforce_assignments_aggregate?: InputMaybe<Dayforce_Assignment_Aggregate_Bool_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  firstname?: InputMaybe<String_Comparison_Exp>;
  hire_date?: InputMaybe<Timestamp_Comparison_Exp>;
  icims_person?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
  icims_person_profile_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastname?: InputMaybe<String_Comparison_Exp>;
  original_hire_date?: InputMaybe<Timestamp_Comparison_Exp>;
  termination_date?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "dayforce_employee" */
export enum Dayforce_Employee_Constraint {
  /** unique or primary key constraint on columns "id" */
  DayforceEmployeePkey = 'dayforce_employee_pkey',
}

/** input type for incrementing numeric columns in table "dayforce_employee" */
export type Dayforce_Employee_Inc_Input = {
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dayforce_employee" */
export type Dayforce_Employee_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  common_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dayforce_assignments?: InputMaybe<Dayforce_Assignment_Arr_Rel_Insert_Input>;
  display_name?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  hire_date?: InputMaybe<Scalars['timestamp']>;
  icims_person?: InputMaybe<Icims_Profile_Person_Obj_Rel_Insert_Input>;
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastname?: InputMaybe<Scalars['String']>;
  original_hire_date?: InputMaybe<Scalars['timestamp']>;
  termination_date?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Dayforce_Employee_Max_Fields = {
  __typename?: 'dayforce_employee_max_fields';
  common_name: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  display_name: Maybe<Scalars['String']>;
  firstname: Maybe<Scalars['String']>;
  hire_date: Maybe<Scalars['timestamp']>;
  icims_person_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  lastname: Maybe<Scalars['String']>;
  original_hire_date: Maybe<Scalars['timestamp']>;
  termination_date: Maybe<Scalars['timestamp']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Dayforce_Employee_Min_Fields = {
  __typename?: 'dayforce_employee_min_fields';
  common_name: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  display_name: Maybe<Scalars['String']>;
  firstname: Maybe<Scalars['String']>;
  hire_date: Maybe<Scalars['timestamp']>;
  icims_person_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  lastname: Maybe<Scalars['String']>;
  original_hire_date: Maybe<Scalars['timestamp']>;
  termination_date: Maybe<Scalars['timestamp']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "dayforce_employee" */
export type Dayforce_Employee_Mutation_Response = {
  __typename?: 'dayforce_employee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dayforce_Employee>;
};

/** input type for inserting object relation for remote table "dayforce_employee" */
export type Dayforce_Employee_Obj_Rel_Insert_Input = {
  data: Dayforce_Employee_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dayforce_Employee_On_Conflict>;
};

/** on_conflict condition type for table "dayforce_employee" */
export type Dayforce_Employee_On_Conflict = {
  constraint: Dayforce_Employee_Constraint;
  update_columns?: Array<Dayforce_Employee_Update_Column>;
  where?: InputMaybe<Dayforce_Employee_Bool_Exp>;
};

/** Ordering options when selecting data from "dayforce_employee". */
export type Dayforce_Employee_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  common_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dayforce_assignments_aggregate?: InputMaybe<Dayforce_Assignment_Aggregate_Order_By>;
  display_name?: InputMaybe<Order_By>;
  firstname?: InputMaybe<Order_By>;
  hire_date?: InputMaybe<Order_By>;
  icims_person?: InputMaybe<Icims_Profile_Person_Order_By>;
  icims_person_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastname?: InputMaybe<Order_By>;
  original_hire_date?: InputMaybe<Order_By>;
  termination_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dayforce_employee */
export type Dayforce_Employee_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "dayforce_employee" */
export enum Dayforce_Employee_Select_Column {
  /** column name */
  CommonName = 'common_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  HireDate = 'hire_date',
  /** column name */
  IcimsPersonProfileId = 'icims_person_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  OriginalHireDate = 'original_hire_date',
  /** column name */
  TerminationDate = 'termination_date',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "dayforce_employee" */
export type Dayforce_Employee_Set_Input = {
  common_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  hire_date?: InputMaybe<Scalars['timestamp']>;
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastname?: InputMaybe<Scalars['String']>;
  original_hire_date?: InputMaybe<Scalars['timestamp']>;
  termination_date?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Dayforce_Employee_Stddev_Fields = {
  __typename?: 'dayforce_employee_stddev_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dayforce_Employee_Stddev_Pop_Fields = {
  __typename?: 'dayforce_employee_stddev_pop_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dayforce_Employee_Stddev_Samp_Fields = {
  __typename?: 'dayforce_employee_stddev_samp_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dayforce_employee" */
export type Dayforce_Employee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dayforce_Employee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dayforce_Employee_Stream_Cursor_Value_Input = {
  common_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  hire_date?: InputMaybe<Scalars['timestamp']>;
  icims_person_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastname?: InputMaybe<Scalars['String']>;
  original_hire_date?: InputMaybe<Scalars['timestamp']>;
  termination_date?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Dayforce_Employee_Sum_Fields = {
  __typename?: 'dayforce_employee_sum_fields';
  icims_person_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "dayforce_employee" */
export enum Dayforce_Employee_Update_Column {
  /** column name */
  CommonName = 'common_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  HireDate = 'hire_date',
  /** column name */
  IcimsPersonProfileId = 'icims_person_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  OriginalHireDate = 'original_hire_date',
  /** column name */
  TerminationDate = 'termination_date',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Dayforce_Employee_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dayforce_Employee_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dayforce_Employee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dayforce_Employee_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dayforce_Employee_Var_Pop_Fields = {
  __typename?: 'dayforce_employee_var_pop_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dayforce_Employee_Var_Samp_Fields = {
  __typename?: 'dayforce_employee_var_samp_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dayforce_Employee_Variance_Fields = {
  __typename?: 'dayforce_employee_variance_fields';
  icims_person_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dayforce_location" */
export type Dayforce_Location = {
  __typename?: 'dayforce_location';
  /** An array relationship */
  dayforce_assignments: Array<Dayforce_Assignment>;
  /** An aggregate relationship */
  dayforce_assignments_aggregate: Dayforce_Assignment_Aggregate;
  id: Scalars['Int'];
  location: Scalars['String'];
  /** An array relationship */
  locations: Array<Location>;
  /** An aggregate relationship */
  locations_aggregate: Location_Aggregate;
};

/** columns and relationships of "dayforce_location" */
export type Dayforce_LocationDayforce_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Assignment_Order_By>>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

/** columns and relationships of "dayforce_location" */
export type Dayforce_LocationDayforce_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Assignment_Order_By>>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

/** columns and relationships of "dayforce_location" */
export type Dayforce_LocationLocationsArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};

/** columns and relationships of "dayforce_location" */
export type Dayforce_LocationLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};

/** aggregated selection of "dayforce_location" */
export type Dayforce_Location_Aggregate = {
  __typename?: 'dayforce_location_aggregate';
  aggregate: Maybe<Dayforce_Location_Aggregate_Fields>;
  nodes: Array<Dayforce_Location>;
};

/** aggregate fields of "dayforce_location" */
export type Dayforce_Location_Aggregate_Fields = {
  __typename?: 'dayforce_location_aggregate_fields';
  avg: Maybe<Dayforce_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Dayforce_Location_Max_Fields>;
  min: Maybe<Dayforce_Location_Min_Fields>;
  stddev: Maybe<Dayforce_Location_Stddev_Fields>;
  stddev_pop: Maybe<Dayforce_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Dayforce_Location_Stddev_Samp_Fields>;
  sum: Maybe<Dayforce_Location_Sum_Fields>;
  var_pop: Maybe<Dayforce_Location_Var_Pop_Fields>;
  var_samp: Maybe<Dayforce_Location_Var_Samp_Fields>;
  variance: Maybe<Dayforce_Location_Variance_Fields>;
};

/** aggregate fields of "dayforce_location" */
export type Dayforce_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dayforce_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dayforce_Location_Avg_Fields = {
  __typename?: 'dayforce_location_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dayforce_location". All fields are combined with a logical 'AND'. */
export type Dayforce_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Dayforce_Location_Bool_Exp>>;
  _not?: InputMaybe<Dayforce_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Dayforce_Location_Bool_Exp>>;
  dayforce_assignments?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
  dayforce_assignments_aggregate?: InputMaybe<Dayforce_Assignment_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  locations?: InputMaybe<Location_Bool_Exp>;
  locations_aggregate?: InputMaybe<Location_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "dayforce_location" */
export enum Dayforce_Location_Constraint {
  /** unique or primary key constraint on columns "location" */
  DayforceLocationLocationKey = 'dayforce_location_location_key',
  /** unique or primary key constraint on columns "id" */
  DayforceLocationPkey = 'dayforce_location_pkey',
}

/** input type for incrementing numeric columns in table "dayforce_location" */
export type Dayforce_Location_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dayforce_location" */
export type Dayforce_Location_Insert_Input = {
  dayforce_assignments?: InputMaybe<Dayforce_Assignment_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Location_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Dayforce_Location_Max_Fields = {
  __typename?: 'dayforce_location_max_fields';
  id: Maybe<Scalars['Int']>;
  location: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dayforce_Location_Min_Fields = {
  __typename?: 'dayforce_location_min_fields';
  id: Maybe<Scalars['Int']>;
  location: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "dayforce_location" */
export type Dayforce_Location_Mutation_Response = {
  __typename?: 'dayforce_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dayforce_Location>;
};

/** input type for inserting object relation for remote table "dayforce_location" */
export type Dayforce_Location_Obj_Rel_Insert_Input = {
  data: Dayforce_Location_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dayforce_Location_On_Conflict>;
};

/** on_conflict condition type for table "dayforce_location" */
export type Dayforce_Location_On_Conflict = {
  constraint: Dayforce_Location_Constraint;
  update_columns?: Array<Dayforce_Location_Update_Column>;
  where?: InputMaybe<Dayforce_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "dayforce_location". */
export type Dayforce_Location_Order_By = {
  dayforce_assignments_aggregate?: InputMaybe<Dayforce_Assignment_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  locations_aggregate?: InputMaybe<Location_Aggregate_Order_By>;
};

/** primary key columns input for table: dayforce_location */
export type Dayforce_Location_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "dayforce_location" */
export enum Dayforce_Location_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
}

/** input type for updating data in table "dayforce_location" */
export type Dayforce_Location_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Dayforce_Location_Stddev_Fields = {
  __typename?: 'dayforce_location_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dayforce_Location_Stddev_Pop_Fields = {
  __typename?: 'dayforce_location_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dayforce_Location_Stddev_Samp_Fields = {
  __typename?: 'dayforce_location_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dayforce_location" */
export type Dayforce_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dayforce_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dayforce_Location_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Dayforce_Location_Sum_Fields = {
  __typename?: 'dayforce_location_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "dayforce_location" */
export enum Dayforce_Location_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
}

export type Dayforce_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dayforce_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dayforce_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dayforce_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dayforce_Location_Var_Pop_Fields = {
  __typename?: 'dayforce_location_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dayforce_Location_Var_Samp_Fields = {
  __typename?: 'dayforce_location_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dayforce_Location_Variance_Fields = {
  __typename?: 'dayforce_location_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "email_notification" */
export type Email_Notification = {
  __typename?: 'email_notification';
  created_at: Scalars['timestamptz'];
  email_type: Scalars['String'];
  id: Scalars['Int'];
  not_sent_reason: Maybe<Scalars['String']>;
  payload: Scalars['jsonb'];
  recipient_email: Scalars['String'];
  status: Scalars['String'];
  trigger_from: Scalars['String'];
  unique_payload: Scalars['jsonb'];
  version: Scalars['Int'];
};

/** columns and relationships of "email_notification" */
export type Email_NotificationPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "email_notification" */
export type Email_NotificationUnique_PayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "email_notification" */
export type Email_Notification_Aggregate = {
  __typename?: 'email_notification_aggregate';
  aggregate: Maybe<Email_Notification_Aggregate_Fields>;
  nodes: Array<Email_Notification>;
};

/** aggregate fields of "email_notification" */
export type Email_Notification_Aggregate_Fields = {
  __typename?: 'email_notification_aggregate_fields';
  avg: Maybe<Email_Notification_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Email_Notification_Max_Fields>;
  min: Maybe<Email_Notification_Min_Fields>;
  stddev: Maybe<Email_Notification_Stddev_Fields>;
  stddev_pop: Maybe<Email_Notification_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Email_Notification_Stddev_Samp_Fields>;
  sum: Maybe<Email_Notification_Sum_Fields>;
  var_pop: Maybe<Email_Notification_Var_Pop_Fields>;
  var_samp: Maybe<Email_Notification_Var_Samp_Fields>;
  variance: Maybe<Email_Notification_Variance_Fields>;
};

/** aggregate fields of "email_notification" */
export type Email_Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Email_Notification_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
  unique_payload?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Email_Notification_Avg_Fields = {
  __typename?: 'email_notification_avg_fields';
  id: Maybe<Scalars['Float']>;
  version: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "email_notification". All fields are combined with a logical 'AND'. */
export type Email_Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Notification_Bool_Exp>>;
  _not?: InputMaybe<Email_Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Notification_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  not_sent_reason?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  recipient_email?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  trigger_from?: InputMaybe<String_Comparison_Exp>;
  unique_payload?: InputMaybe<Jsonb_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_notification" */
export enum Email_Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailNotificationPkey = 'email_notification_pkey',
  /** unique or primary key constraint on columns "recipient_email", "email_type", "unique_payload" */
  EmailNotificationRecipientEmailUniquePayloadEmailTypeKe = 'email_notification_recipient_email_unique_payload_email_type_ke',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Email_Notification_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
  unique_payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Email_Notification_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
  unique_payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Email_Notification_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
  unique_payload?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "email_notification" */
export type Email_Notification_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "email_notification" */
export type Email_Notification_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  not_sent_reason?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  recipient_email?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  trigger_from?: InputMaybe<Scalars['String']>;
  unique_payload?: InputMaybe<Scalars['jsonb']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Email_Notification_Max_Fields = {
  __typename?: 'email_notification_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  email_type: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  not_sent_reason: Maybe<Scalars['String']>;
  recipient_email: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  trigger_from: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Email_Notification_Min_Fields = {
  __typename?: 'email_notification_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  email_type: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  not_sent_reason: Maybe<Scalars['String']>;
  recipient_email: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  trigger_from: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "email_notification" */
export type Email_Notification_Mutation_Response = {
  __typename?: 'email_notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Notification>;
};

/** on_conflict condition type for table "email_notification" */
export type Email_Notification_On_Conflict = {
  constraint: Email_Notification_Constraint;
  update_columns?: Array<Email_Notification_Update_Column>;
  where?: InputMaybe<Email_Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "email_notification". */
export type Email_Notification_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  not_sent_reason?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  recipient_email?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trigger_from?: InputMaybe<Order_By>;
  unique_payload?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_notification */
export type Email_Notification_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Email_Notification_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
  unique_payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "email_notification" */
export enum Email_Notification_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailType = 'email_type',
  /** column name */
  Id = 'id',
  /** column name */
  NotSentReason = 'not_sent_reason',
  /** column name */
  Payload = 'payload',
  /** column name */
  RecipientEmail = 'recipient_email',
  /** column name */
  Status = 'status',
  /** column name */
  TriggerFrom = 'trigger_from',
  /** column name */
  UniquePayload = 'unique_payload',
  /** column name */
  Version = 'version',
}

/** input type for updating data in table "email_notification" */
export type Email_Notification_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  not_sent_reason?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  recipient_email?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  trigger_from?: InputMaybe<Scalars['String']>;
  unique_payload?: InputMaybe<Scalars['jsonb']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Email_Notification_Stddev_Fields = {
  __typename?: 'email_notification_stddev_fields';
  id: Maybe<Scalars['Float']>;
  version: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Email_Notification_Stddev_Pop_Fields = {
  __typename?: 'email_notification_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  version: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Email_Notification_Stddev_Samp_Fields = {
  __typename?: 'email_notification_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  version: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "email_notification" */
export type Email_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Notification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Notification_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  not_sent_reason?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  recipient_email?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  trigger_from?: InputMaybe<Scalars['String']>;
  unique_payload?: InputMaybe<Scalars['jsonb']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Email_Notification_Sum_Fields = {
  __typename?: 'email_notification_sum_fields';
  id: Maybe<Scalars['Int']>;
  version: Maybe<Scalars['Int']>;
};

/** update columns of table "email_notification" */
export enum Email_Notification_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailType = 'email_type',
  /** column name */
  Id = 'id',
  /** column name */
  NotSentReason = 'not_sent_reason',
  /** column name */
  Payload = 'payload',
  /** column name */
  RecipientEmail = 'recipient_email',
  /** column name */
  Status = 'status',
  /** column name */
  TriggerFrom = 'trigger_from',
  /** column name */
  UniquePayload = 'unique_payload',
  /** column name */
  Version = 'version',
}

export type Email_Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Email_Notification_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Email_Notification_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Email_Notification_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Email_Notification_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Email_Notification_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Email_Notification_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Notification_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Email_Notification_Var_Pop_Fields = {
  __typename?: 'email_notification_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  version: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Email_Notification_Var_Samp_Fields = {
  __typename?: 'email_notification_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  version: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Email_Notification_Variance_Fields = {
  __typename?: 'email_notification_variance_fields';
  id: Maybe<Scalars['Float']>;
  version: Maybe<Scalars['Float']>;
};

/** Email templates for curators and other users to pick from frontend and then send email */
export type Email_Template = {
  __typename?: 'email_template';
  body: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  subject: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "email_template" */
export type Email_Template_Aggregate = {
  __typename?: 'email_template_aggregate';
  aggregate: Maybe<Email_Template_Aggregate_Fields>;
  nodes: Array<Email_Template>;
};

/** aggregate fields of "email_template" */
export type Email_Template_Aggregate_Fields = {
  __typename?: 'email_template_aggregate_fields';
  avg: Maybe<Email_Template_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Email_Template_Max_Fields>;
  min: Maybe<Email_Template_Min_Fields>;
  stddev: Maybe<Email_Template_Stddev_Fields>;
  stddev_pop: Maybe<Email_Template_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Email_Template_Stddev_Samp_Fields>;
  sum: Maybe<Email_Template_Sum_Fields>;
  var_pop: Maybe<Email_Template_Var_Pop_Fields>;
  var_samp: Maybe<Email_Template_Var_Samp_Fields>;
  variance: Maybe<Email_Template_Variance_Fields>;
};

/** aggregate fields of "email_template" */
export type Email_Template_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Template_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Email_Template_Avg_Fields = {
  __typename?: 'email_template_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "email_template". All fields are combined with a logical 'AND'. */
export type Email_Template_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Template_Bool_Exp>>;
  _not?: InputMaybe<Email_Template_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Template_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_template" */
export enum Email_Template_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailTemplatePkey = 'email_template_pkey',
}

/** input type for incrementing numeric columns in table "email_template" */
export type Email_Template_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "email_template" */
export type Email_Template_Insert_Input = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Email_Template_Max_Fields = {
  __typename?: 'email_template_max_fields';
  body: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  subject: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Email_Template_Min_Fields = {
  __typename?: 'email_template_min_fields';
  body: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  subject: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "email_template" */
export type Email_Template_Mutation_Response = {
  __typename?: 'email_template_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Template>;
};

/** on_conflict condition type for table "email_template" */
export type Email_Template_On_Conflict = {
  constraint: Email_Template_Constraint;
  update_columns?: Array<Email_Template_Update_Column>;
  where?: InputMaybe<Email_Template_Bool_Exp>;
};

/** Ordering options when selecting data from "email_template". */
export type Email_Template_Order_By = {
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_template */
export type Email_Template_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "email_template" */
export enum Email_Template_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "email_template" */
export type Email_Template_Set_Input = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Email_Template_Stddev_Fields = {
  __typename?: 'email_template_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Email_Template_Stddev_Pop_Fields = {
  __typename?: 'email_template_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Email_Template_Stddev_Samp_Fields = {
  __typename?: 'email_template_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "email_template" */
export type Email_Template_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Template_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Template_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Email_Template_Sum_Fields = {
  __typename?: 'email_template_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "email_template" */
export enum Email_Template_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Email_Template_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Email_Template_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Template_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Template_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Email_Template_Var_Pop_Fields = {
  __typename?: 'email_template_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Email_Template_Var_Samp_Fields = {
  __typename?: 'email_template_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Email_Template_Variance_Fields = {
  __typename?: 'email_template_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** Employment type choices (Full-time, Contractor, Both, etc.) */
export type Employment_Type_Choices = {
  __typename?: 'employment_type_choices';
  value: Scalars['String'];
};

/** aggregated selection of "employment_type_choices" */
export type Employment_Type_Choices_Aggregate = {
  __typename?: 'employment_type_choices_aggregate';
  aggregate: Maybe<Employment_Type_Choices_Aggregate_Fields>;
  nodes: Array<Employment_Type_Choices>;
};

/** aggregate fields of "employment_type_choices" */
export type Employment_Type_Choices_Aggregate_Fields = {
  __typename?: 'employment_type_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Employment_Type_Choices_Max_Fields>;
  min: Maybe<Employment_Type_Choices_Min_Fields>;
};

/** aggregate fields of "employment_type_choices" */
export type Employment_Type_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employment_Type_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "employment_type_choices". All fields are combined with a logical 'AND'. */
export type Employment_Type_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Employment_Type_Choices_Bool_Exp>>;
  _not?: InputMaybe<Employment_Type_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Employment_Type_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "employment_type_choices" */
export enum Employment_Type_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  EmploymentTypeChoicesPkey = 'employment_type_choices_pkey',
}

export enum Employment_Type_Choices_Enum {
  Contract = 'CONTRACT',
  FullTime = 'FULL_TIME',
  FullTimeAndContract = 'FULL_TIME_AND_CONTRACT',
}

/** Boolean expression to compare columns of type "employment_type_choices_enum". All fields are combined with logical 'AND'. */
export type Employment_Type_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Employment_Type_Choices_Enum>;
  _in?: InputMaybe<Array<Employment_Type_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Employment_Type_Choices_Enum>;
  _nin?: InputMaybe<Array<Employment_Type_Choices_Enum>>;
};

/** input type for inserting data into table "employment_type_choices" */
export type Employment_Type_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Employment_Type_Choices_Max_Fields = {
  __typename?: 'employment_type_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Employment_Type_Choices_Min_Fields = {
  __typename?: 'employment_type_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "employment_type_choices" */
export type Employment_Type_Choices_Mutation_Response = {
  __typename?: 'employment_type_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employment_Type_Choices>;
};

/** on_conflict condition type for table "employment_type_choices" */
export type Employment_Type_Choices_On_Conflict = {
  constraint: Employment_Type_Choices_Constraint;
  update_columns?: Array<Employment_Type_Choices_Update_Column>;
  where?: InputMaybe<Employment_Type_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "employment_type_choices". */
export type Employment_Type_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employment_type_choices */
export type Employment_Type_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "employment_type_choices" */
export enum Employment_Type_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "employment_type_choices" */
export type Employment_Type_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "employment_type_choices" */
export type Employment_Type_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employment_Type_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employment_Type_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "employment_type_choices" */
export enum Employment_Type_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Employment_Type_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employment_Type_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Employment_Type_Choices_Bool_Exp;
};

/** Table for storing popular explore candidates searches */
export type Explore_Candidates_Popular_Search = {
  __typename?: 'explore_candidates_popular_search';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  explore_candidates_search: Explore_Candidates_Search;
  explore_candidates_search_id: Scalars['Int'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Aggregate = {
  __typename?: 'explore_candidates_popular_search_aggregate';
  aggregate: Maybe<Explore_Candidates_Popular_Search_Aggregate_Fields>;
  nodes: Array<Explore_Candidates_Popular_Search>;
};

export type Explore_Candidates_Popular_Search_Aggregate_Bool_Exp = {
  count?: InputMaybe<Explore_Candidates_Popular_Search_Aggregate_Bool_Exp_Count>;
};

export type Explore_Candidates_Popular_Search_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Explore_Candidates_Popular_Search_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Explore_Candidates_Popular_Search_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Aggregate_Fields = {
  __typename?: 'explore_candidates_popular_search_aggregate_fields';
  avg: Maybe<Explore_Candidates_Popular_Search_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Explore_Candidates_Popular_Search_Max_Fields>;
  min: Maybe<Explore_Candidates_Popular_Search_Min_Fields>;
  stddev: Maybe<Explore_Candidates_Popular_Search_Stddev_Fields>;
  stddev_pop: Maybe<Explore_Candidates_Popular_Search_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Explore_Candidates_Popular_Search_Stddev_Samp_Fields>;
  sum: Maybe<Explore_Candidates_Popular_Search_Sum_Fields>;
  var_pop: Maybe<Explore_Candidates_Popular_Search_Var_Pop_Fields>;
  var_samp: Maybe<Explore_Candidates_Popular_Search_Var_Samp_Fields>;
  variance: Maybe<Explore_Candidates_Popular_Search_Variance_Fields>;
};

/** aggregate fields of "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Explore_Candidates_Popular_Search_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Aggregate_Order_By = {
  avg?: InputMaybe<Explore_Candidates_Popular_Search_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Explore_Candidates_Popular_Search_Max_Order_By>;
  min?: InputMaybe<Explore_Candidates_Popular_Search_Min_Order_By>;
  stddev?: InputMaybe<Explore_Candidates_Popular_Search_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Explore_Candidates_Popular_Search_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Explore_Candidates_Popular_Search_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Explore_Candidates_Popular_Search_Sum_Order_By>;
  var_pop?: InputMaybe<Explore_Candidates_Popular_Search_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Explore_Candidates_Popular_Search_Var_Samp_Order_By>;
  variance?: InputMaybe<Explore_Candidates_Popular_Search_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Arr_Rel_Insert_Input = {
  data: Array<Explore_Candidates_Popular_Search_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Explore_Candidates_Popular_Search_On_Conflict>;
};

/** aggregate avg on columns */
export type Explore_Candidates_Popular_Search_Avg_Fields = {
  __typename?: 'explore_candidates_popular_search_avg_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Avg_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "explore_candidates_popular_search". All fields are combined with a logical 'AND'. */
export type Explore_Candidates_Popular_Search_Bool_Exp = {
  _and?: InputMaybe<Array<Explore_Candidates_Popular_Search_Bool_Exp>>;
  _not?: InputMaybe<Explore_Candidates_Popular_Search_Bool_Exp>;
  _or?: InputMaybe<Array<Explore_Candidates_Popular_Search_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
  explore_candidates_search_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "explore_candidates_popular_search" */
export enum Explore_Candidates_Popular_Search_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExploreCandidatesPopularSearchPkey = 'explore_candidates_popular_search_pkey',
}

/** input type for incrementing numeric columns in table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Inc_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Obj_Rel_Insert_Input>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Explore_Candidates_Popular_Search_Max_Fields = {
  __typename?: 'explore_candidates_popular_search_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Explore_Candidates_Popular_Search_Min_Fields = {
  __typename?: 'explore_candidates_popular_search_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Mutation_Response = {
  __typename?: 'explore_candidates_popular_search_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Explore_Candidates_Popular_Search>;
};

/** on_conflict condition type for table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_On_Conflict = {
  constraint: Explore_Candidates_Popular_Search_Constraint;
  update_columns?: Array<Explore_Candidates_Popular_Search_Update_Column>;
  where?: InputMaybe<Explore_Candidates_Popular_Search_Bool_Exp>;
};

/** Ordering options when selecting data from "explore_candidates_popular_search". */
export type Explore_Candidates_Popular_Search_Order_By = {
  created_at?: InputMaybe<Order_By>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Order_By>;
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: explore_candidates_popular_search */
export type Explore_Candidates_Popular_Search_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "explore_candidates_popular_search" */
export enum Explore_Candidates_Popular_Search_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Explore_Candidates_Popular_Search_Stddev_Fields = {
  __typename?: 'explore_candidates_popular_search_stddev_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Stddev_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Explore_Candidates_Popular_Search_Stddev_Pop_Fields = {
  __typename?: 'explore_candidates_popular_search_stddev_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Stddev_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Explore_Candidates_Popular_Search_Stddev_Samp_Fields = {
  __typename?: 'explore_candidates_popular_search_stddev_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Stddev_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Explore_Candidates_Popular_Search_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Explore_Candidates_Popular_Search_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Explore_Candidates_Popular_Search_Sum_Fields = {
  __typename?: 'explore_candidates_popular_search_sum_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Sum_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "explore_candidates_popular_search" */
export enum Explore_Candidates_Popular_Search_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Explore_Candidates_Popular_Search_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Explore_Candidates_Popular_Search_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Explore_Candidates_Popular_Search_Set_Input>;
  /** filter the rows which have to be updated */
  where: Explore_Candidates_Popular_Search_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Explore_Candidates_Popular_Search_Var_Pop_Fields = {
  __typename?: 'explore_candidates_popular_search_var_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Var_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Explore_Candidates_Popular_Search_Var_Samp_Fields = {
  __typename?: 'explore_candidates_popular_search_var_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Var_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Explore_Candidates_Popular_Search_Variance_Fields = {
  __typename?: 'explore_candidates_popular_search_variance_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "explore_candidates_popular_search" */
export type Explore_Candidates_Popular_Search_Variance_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Explore_Candidates_Query_Args = {
  organization_id?: InputMaybe<Scalars['Int']>;
  should_have_skill_ids?: InputMaybe<Scalars['jsonb']>;
};

/** This table is only used for its Hasura generated type, don't populate it. */
export type Explore_Candidates_Query_Result = {
  __typename?: 'explore_candidates_query_result';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  candidate_profile_updated_at: Maybe<Scalars['timestamptz']>;
  employment_type: Maybe<Scalars['String']>;
  has_built_new_experience: Maybe<Scalars['Boolean']>;
  has_startup_experience: Maybe<Scalars['Boolean']>;
  has_top_company_experience: Maybe<Scalars['Boolean']>;
  is_in_demand: Maybe<Scalars['Boolean']>;
  is_mutual_interest: Maybe<Scalars['Boolean']>;
  is_previous_hire: Maybe<Scalars['Boolean']>;
  is_referred: Maybe<Scalars['Boolean']>;
  is_rising_star: Maybe<Scalars['Boolean']>;
  recommended_score: Maybe<Scalars['float8']>;
  score: Maybe<Scalars['float8']>;
  years_people_lead_experience: Maybe<Scalars['Int']>;
  years_tech_lead_experience: Maybe<Scalars['Int']>;
  yoe_range_order: Maybe<Scalars['Int']>;
};

export type Explore_Candidates_Query_Result_Aggregate = {
  __typename?: 'explore_candidates_query_result_aggregate';
  aggregate: Maybe<Explore_Candidates_Query_Result_Aggregate_Fields>;
  nodes: Array<Explore_Candidates_Query_Result>;
};

/** aggregate fields of "explore_candidates_query_result" */
export type Explore_Candidates_Query_Result_Aggregate_Fields = {
  __typename?: 'explore_candidates_query_result_aggregate_fields';
  avg: Maybe<Explore_Candidates_Query_Result_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Explore_Candidates_Query_Result_Max_Fields>;
  min: Maybe<Explore_Candidates_Query_Result_Min_Fields>;
  stddev: Maybe<Explore_Candidates_Query_Result_Stddev_Fields>;
  stddev_pop: Maybe<Explore_Candidates_Query_Result_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Explore_Candidates_Query_Result_Stddev_Samp_Fields>;
  sum: Maybe<Explore_Candidates_Query_Result_Sum_Fields>;
  var_pop: Maybe<Explore_Candidates_Query_Result_Var_Pop_Fields>;
  var_samp: Maybe<Explore_Candidates_Query_Result_Var_Samp_Fields>;
  variance: Maybe<Explore_Candidates_Query_Result_Variance_Fields>;
};

/** aggregate fields of "explore_candidates_query_result" */
export type Explore_Candidates_Query_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Explore_Candidates_Query_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Explore_Candidates_Query_Result_Avg_Fields = {
  __typename?: 'explore_candidates_query_result_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  recommended_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  years_people_lead_experience: Maybe<Scalars['Float']>;
  years_tech_lead_experience: Maybe<Scalars['Float']>;
  yoe_range_order: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "explore_candidates_query_result". All fields are combined with a logical 'AND'. */
export type Explore_Candidates_Query_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Explore_Candidates_Query_Result_Bool_Exp>>;
  _not?: InputMaybe<Explore_Candidates_Query_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Explore_Candidates_Query_Result_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  candidate_profile_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employment_type?: InputMaybe<String_Comparison_Exp>;
  has_built_new_experience?: InputMaybe<Boolean_Comparison_Exp>;
  has_startup_experience?: InputMaybe<Boolean_Comparison_Exp>;
  has_top_company_experience?: InputMaybe<Boolean_Comparison_Exp>;
  is_in_demand?: InputMaybe<Boolean_Comparison_Exp>;
  is_mutual_interest?: InputMaybe<Boolean_Comparison_Exp>;
  is_previous_hire?: InputMaybe<Boolean_Comparison_Exp>;
  is_referred?: InputMaybe<Boolean_Comparison_Exp>;
  is_rising_star?: InputMaybe<Boolean_Comparison_Exp>;
  recommended_score?: InputMaybe<Float8_Comparison_Exp>;
  score?: InputMaybe<Float8_Comparison_Exp>;
  years_people_lead_experience?: InputMaybe<Int_Comparison_Exp>;
  years_tech_lead_experience?: InputMaybe<Int_Comparison_Exp>;
  yoe_range_order?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "explore_candidates_query_result" */
export enum Explore_Candidates_Query_Result_Constraint {
  /** unique or primary key constraint on columns "candidate_id" */
  ExploreCandidatesQueryResultPkey = 'explore_candidates_query_result_pkey',
}

/** input type for incrementing numeric columns in table "explore_candidates_query_result" */
export type Explore_Candidates_Query_Result_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  recommended_score?: InputMaybe<Scalars['float8']>;
  score?: InputMaybe<Scalars['float8']>;
  years_people_lead_experience?: InputMaybe<Scalars['Int']>;
  years_tech_lead_experience?: InputMaybe<Scalars['Int']>;
  yoe_range_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "explore_candidates_query_result" */
export type Explore_Candidates_Query_Result_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_profile_updated_at?: InputMaybe<Scalars['timestamptz']>;
  employment_type?: InputMaybe<Scalars['String']>;
  has_built_new_experience?: InputMaybe<Scalars['Boolean']>;
  has_startup_experience?: InputMaybe<Scalars['Boolean']>;
  has_top_company_experience?: InputMaybe<Scalars['Boolean']>;
  is_in_demand?: InputMaybe<Scalars['Boolean']>;
  is_mutual_interest?: InputMaybe<Scalars['Boolean']>;
  is_previous_hire?: InputMaybe<Scalars['Boolean']>;
  is_referred?: InputMaybe<Scalars['Boolean']>;
  is_rising_star?: InputMaybe<Scalars['Boolean']>;
  recommended_score?: InputMaybe<Scalars['float8']>;
  score?: InputMaybe<Scalars['float8']>;
  years_people_lead_experience?: InputMaybe<Scalars['Int']>;
  years_tech_lead_experience?: InputMaybe<Scalars['Int']>;
  yoe_range_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Explore_Candidates_Query_Result_Max_Fields = {
  __typename?: 'explore_candidates_query_result_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  candidate_profile_updated_at: Maybe<Scalars['timestamptz']>;
  employment_type: Maybe<Scalars['String']>;
  recommended_score: Maybe<Scalars['float8']>;
  score: Maybe<Scalars['float8']>;
  years_people_lead_experience: Maybe<Scalars['Int']>;
  years_tech_lead_experience: Maybe<Scalars['Int']>;
  yoe_range_order: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Explore_Candidates_Query_Result_Min_Fields = {
  __typename?: 'explore_candidates_query_result_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  candidate_profile_updated_at: Maybe<Scalars['timestamptz']>;
  employment_type: Maybe<Scalars['String']>;
  recommended_score: Maybe<Scalars['float8']>;
  score: Maybe<Scalars['float8']>;
  years_people_lead_experience: Maybe<Scalars['Int']>;
  years_tech_lead_experience: Maybe<Scalars['Int']>;
  yoe_range_order: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "explore_candidates_query_result" */
export type Explore_Candidates_Query_Result_Mutation_Response = {
  __typename?: 'explore_candidates_query_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Explore_Candidates_Query_Result>;
};

/** on_conflict condition type for table "explore_candidates_query_result" */
export type Explore_Candidates_Query_Result_On_Conflict = {
  constraint: Explore_Candidates_Query_Result_Constraint;
  update_columns?: Array<Explore_Candidates_Query_Result_Update_Column>;
  where?: InputMaybe<Explore_Candidates_Query_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "explore_candidates_query_result". */
export type Explore_Candidates_Query_Result_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_profile_updated_at?: InputMaybe<Order_By>;
  employment_type?: InputMaybe<Order_By>;
  has_built_new_experience?: InputMaybe<Order_By>;
  has_startup_experience?: InputMaybe<Order_By>;
  has_top_company_experience?: InputMaybe<Order_By>;
  is_in_demand?: InputMaybe<Order_By>;
  is_mutual_interest?: InputMaybe<Order_By>;
  is_previous_hire?: InputMaybe<Order_By>;
  is_referred?: InputMaybe<Order_By>;
  is_rising_star?: InputMaybe<Order_By>;
  recommended_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  years_people_lead_experience?: InputMaybe<Order_By>;
  years_tech_lead_experience?: InputMaybe<Order_By>;
  yoe_range_order?: InputMaybe<Order_By>;
};

/** primary key columns input for table: explore_candidates_query_result */
export type Explore_Candidates_Query_Result_Pk_Columns_Input = {
  candidate_id: Scalars['Int'];
};

/** select columns of table "explore_candidates_query_result" */
export enum Explore_Candidates_Query_Result_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CandidateProfileUpdatedAt = 'candidate_profile_updated_at',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  HasBuiltNewExperience = 'has_built_new_experience',
  /** column name */
  HasStartupExperience = 'has_startup_experience',
  /** column name */
  HasTopCompanyExperience = 'has_top_company_experience',
  /** column name */
  IsInDemand = 'is_in_demand',
  /** column name */
  IsMutualInterest = 'is_mutual_interest',
  /** column name */
  IsPreviousHire = 'is_previous_hire',
  /** column name */
  IsReferred = 'is_referred',
  /** column name */
  IsRisingStar = 'is_rising_star',
  /** column name */
  RecommendedScore = 'recommended_score',
  /** column name */
  Score = 'score',
  /** column name */
  YearsPeopleLeadExperience = 'years_people_lead_experience',
  /** column name */
  YearsTechLeadExperience = 'years_tech_lead_experience',
  /** column name */
  YoeRangeOrder = 'yoe_range_order',
}

/** input type for updating data in table "explore_candidates_query_result" */
export type Explore_Candidates_Query_Result_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_profile_updated_at?: InputMaybe<Scalars['timestamptz']>;
  employment_type?: InputMaybe<Scalars['String']>;
  has_built_new_experience?: InputMaybe<Scalars['Boolean']>;
  has_startup_experience?: InputMaybe<Scalars['Boolean']>;
  has_top_company_experience?: InputMaybe<Scalars['Boolean']>;
  is_in_demand?: InputMaybe<Scalars['Boolean']>;
  is_mutual_interest?: InputMaybe<Scalars['Boolean']>;
  is_previous_hire?: InputMaybe<Scalars['Boolean']>;
  is_referred?: InputMaybe<Scalars['Boolean']>;
  is_rising_star?: InputMaybe<Scalars['Boolean']>;
  recommended_score?: InputMaybe<Scalars['float8']>;
  score?: InputMaybe<Scalars['float8']>;
  years_people_lead_experience?: InputMaybe<Scalars['Int']>;
  years_tech_lead_experience?: InputMaybe<Scalars['Int']>;
  yoe_range_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Explore_Candidates_Query_Result_Stddev_Fields = {
  __typename?: 'explore_candidates_query_result_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  recommended_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  years_people_lead_experience: Maybe<Scalars['Float']>;
  years_tech_lead_experience: Maybe<Scalars['Float']>;
  yoe_range_order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Explore_Candidates_Query_Result_Stddev_Pop_Fields = {
  __typename?: 'explore_candidates_query_result_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  recommended_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  years_people_lead_experience: Maybe<Scalars['Float']>;
  years_tech_lead_experience: Maybe<Scalars['Float']>;
  yoe_range_order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Explore_Candidates_Query_Result_Stddev_Samp_Fields = {
  __typename?: 'explore_candidates_query_result_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  recommended_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  years_people_lead_experience: Maybe<Scalars['Float']>;
  years_tech_lead_experience: Maybe<Scalars['Float']>;
  yoe_range_order: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "explore_candidates_query_result" */
export type Explore_Candidates_Query_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Explore_Candidates_Query_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Explore_Candidates_Query_Result_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_profile_updated_at?: InputMaybe<Scalars['timestamptz']>;
  employment_type?: InputMaybe<Scalars['String']>;
  has_built_new_experience?: InputMaybe<Scalars['Boolean']>;
  has_startup_experience?: InputMaybe<Scalars['Boolean']>;
  has_top_company_experience?: InputMaybe<Scalars['Boolean']>;
  is_in_demand?: InputMaybe<Scalars['Boolean']>;
  is_mutual_interest?: InputMaybe<Scalars['Boolean']>;
  is_previous_hire?: InputMaybe<Scalars['Boolean']>;
  is_referred?: InputMaybe<Scalars['Boolean']>;
  is_rising_star?: InputMaybe<Scalars['Boolean']>;
  recommended_score?: InputMaybe<Scalars['float8']>;
  score?: InputMaybe<Scalars['float8']>;
  years_people_lead_experience?: InputMaybe<Scalars['Int']>;
  years_tech_lead_experience?: InputMaybe<Scalars['Int']>;
  yoe_range_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Explore_Candidates_Query_Result_Sum_Fields = {
  __typename?: 'explore_candidates_query_result_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  recommended_score: Maybe<Scalars['float8']>;
  score: Maybe<Scalars['float8']>;
  years_people_lead_experience: Maybe<Scalars['Int']>;
  years_tech_lead_experience: Maybe<Scalars['Int']>;
  yoe_range_order: Maybe<Scalars['Int']>;
};

/** update columns of table "explore_candidates_query_result" */
export enum Explore_Candidates_Query_Result_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CandidateProfileUpdatedAt = 'candidate_profile_updated_at',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  HasBuiltNewExperience = 'has_built_new_experience',
  /** column name */
  HasStartupExperience = 'has_startup_experience',
  /** column name */
  HasTopCompanyExperience = 'has_top_company_experience',
  /** column name */
  IsInDemand = 'is_in_demand',
  /** column name */
  IsMutualInterest = 'is_mutual_interest',
  /** column name */
  IsPreviousHire = 'is_previous_hire',
  /** column name */
  IsReferred = 'is_referred',
  /** column name */
  IsRisingStar = 'is_rising_star',
  /** column name */
  RecommendedScore = 'recommended_score',
  /** column name */
  Score = 'score',
  /** column name */
  YearsPeopleLeadExperience = 'years_people_lead_experience',
  /** column name */
  YearsTechLeadExperience = 'years_tech_lead_experience',
  /** column name */
  YoeRangeOrder = 'yoe_range_order',
}

export type Explore_Candidates_Query_Result_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Explore_Candidates_Query_Result_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Explore_Candidates_Query_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Explore_Candidates_Query_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Explore_Candidates_Query_Result_Var_Pop_Fields = {
  __typename?: 'explore_candidates_query_result_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  recommended_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  years_people_lead_experience: Maybe<Scalars['Float']>;
  years_tech_lead_experience: Maybe<Scalars['Float']>;
  yoe_range_order: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Explore_Candidates_Query_Result_Var_Samp_Fields = {
  __typename?: 'explore_candidates_query_result_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  recommended_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  years_people_lead_experience: Maybe<Scalars['Float']>;
  years_tech_lead_experience: Maybe<Scalars['Float']>;
  yoe_range_order: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Explore_Candidates_Query_Result_Variance_Fields = {
  __typename?: 'explore_candidates_query_result_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  recommended_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  years_people_lead_experience: Maybe<Scalars['Float']>;
  years_tech_lead_experience: Maybe<Scalars['Float']>;
  yoe_range_order: Maybe<Scalars['Float']>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_Search = {
  __typename?: 'explore_candidates_search';
  created_at: Maybe<Scalars['timestamptz']>;
  employment_type: Maybe<Employment_Type_Choices_Enum>;
  /** An array relationship */
  explore_candidates_popular_searches: Array<Explore_Candidates_Popular_Search>;
  /** An aggregate relationship */
  explore_candidates_popular_searches_aggregate: Explore_Candidates_Popular_Search_Aggregate;
  /** An array relationship */
  explore_candidates_search_candidate_highlights: Array<Explore_Candidates_Search_Candidate_Highlight>;
  /** An aggregate relationship */
  explore_candidates_search_candidate_highlights_aggregate: Explore_Candidates_Search_Candidate_Highlight_Aggregate;
  /** An array relationship */
  explore_candidates_user_saved_searches: Array<Explore_Candidates_User_Saved_Search>;
  /** An aggregate relationship */
  explore_candidates_user_saved_searches_aggregate: Explore_Candidates_User_Saved_Search_Aggregate;
  id: Scalars['Int'];
  min_years_of_experience: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  /** An array relationship */
  must_have_skills: Array<Explore_Candidates_Search_Must_Have_Skill>;
  /** An aggregate relationship */
  must_have_skills_aggregate: Explore_Candidates_Search_Must_Have_Skill_Aggregate;
  /** An array relationship */
  nice_to_have_skills: Array<Explore_Candidates_Search_Nice_To_Have_Skill>;
  /** An aggregate relationship */
  nice_to_have_skills_aggregate: Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate;
  /** An array relationship */
  regions: Array<Explore_Candidates_Search_Region>;
  /** An aggregate relationship */
  regions_aggregate: Explore_Candidates_Search_Region_Aggregate;
  role: Maybe<Role_Choices_Enum>;
  title: Scalars['String'];
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchExplore_Candidates_Popular_SearchesArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Popular_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Popular_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Popular_Search_Bool_Exp>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchExplore_Candidates_Popular_Searches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Popular_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Popular_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Popular_Search_Bool_Exp>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchExplore_Candidates_Search_Candidate_HighlightsArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchExplore_Candidates_Search_Candidate_Highlights_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Order_By>>;
    where?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>;
  };

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchExplore_Candidates_User_Saved_SearchesArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_User_Saved_Search_Bool_Exp>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchExplore_Candidates_User_Saved_Searches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_User_Saved_Search_Bool_Exp>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchMust_Have_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchMust_Have_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchNice_To_Have_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchNice_To_Have_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchRegionsArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Region_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Region_Bool_Exp>;
};

/** Table for storing explore candidates searches */
export type Explore_Candidates_SearchRegions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Region_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Region_Bool_Exp>;
};

/** aggregated selection of "explore_candidates_search" */
export type Explore_Candidates_Search_Aggregate = {
  __typename?: 'explore_candidates_search_aggregate';
  aggregate: Maybe<Explore_Candidates_Search_Aggregate_Fields>;
  nodes: Array<Explore_Candidates_Search>;
};

/** aggregate fields of "explore_candidates_search" */
export type Explore_Candidates_Search_Aggregate_Fields = {
  __typename?: 'explore_candidates_search_aggregate_fields';
  avg: Maybe<Explore_Candidates_Search_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Explore_Candidates_Search_Max_Fields>;
  min: Maybe<Explore_Candidates_Search_Min_Fields>;
  stddev: Maybe<Explore_Candidates_Search_Stddev_Fields>;
  stddev_pop: Maybe<Explore_Candidates_Search_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Explore_Candidates_Search_Stddev_Samp_Fields>;
  sum: Maybe<Explore_Candidates_Search_Sum_Fields>;
  var_pop: Maybe<Explore_Candidates_Search_Var_Pop_Fields>;
  var_samp: Maybe<Explore_Candidates_Search_Var_Samp_Fields>;
  variance: Maybe<Explore_Candidates_Search_Variance_Fields>;
};

/** aggregate fields of "explore_candidates_search" */
export type Explore_Candidates_Search_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Explore_Candidates_Search_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Explore_Candidates_Search_Avg_Fields = {
  __typename?: 'explore_candidates_search_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "explore_candidates_search". All fields are combined with a logical 'AND'. */
export type Explore_Candidates_Search_Bool_Exp = {
  _and?: InputMaybe<Array<Explore_Candidates_Search_Bool_Exp>>;
  _not?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
  _or?: InputMaybe<Array<Explore_Candidates_Search_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum_Comparison_Exp>;
  explore_candidates_popular_searches?: InputMaybe<Explore_Candidates_Popular_Search_Bool_Exp>;
  explore_candidates_popular_searches_aggregate?: InputMaybe<Explore_Candidates_Popular_Search_Aggregate_Bool_Exp>;
  explore_candidates_search_candidate_highlights?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>;
  explore_candidates_search_candidate_highlights_aggregate?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Aggregate_Bool_Exp>;
  explore_candidates_user_saved_searches?: InputMaybe<Explore_Candidates_User_Saved_Search_Bool_Exp>;
  explore_candidates_user_saved_searches_aggregate?: InputMaybe<Explore_Candidates_User_Saved_Search_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  min_years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum_Comparison_Exp>;
  must_have_skills?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>;
  must_have_skills_aggregate?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Aggregate_Bool_Exp>;
  nice_to_have_skills?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>;
  nice_to_have_skills_aggregate?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate_Bool_Exp>;
  regions?: InputMaybe<Explore_Candidates_Search_Region_Bool_Exp>;
  regions_aggregate?: InputMaybe<Explore_Candidates_Search_Region_Aggregate_Bool_Exp>;
  role?: InputMaybe<Role_Choices_Enum_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** M2M table for specifying candidate highlights for explore candidates searches */
export type Explore_Candidates_Search_Candidate_Highlight = {
  __typename?: 'explore_candidates_search_candidate_highlight';
  candidate_highlight: Candidate_Highlight_Choices_Enum;
  /** An object relationship */
  explore_candidates_search: Explore_Candidates_Search;
  explore_candidates_search_id: Scalars['Int'];
};

/** aggregated selection of "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Aggregate = {
  __typename?: 'explore_candidates_search_candidate_highlight_aggregate';
  aggregate: Maybe<Explore_Candidates_Search_Candidate_Highlight_Aggregate_Fields>;
  nodes: Array<Explore_Candidates_Search_Candidate_Highlight>;
};

export type Explore_Candidates_Search_Candidate_Highlight_Aggregate_Bool_Exp = {
  count?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Aggregate_Bool_Exp_Count>;
};

export type Explore_Candidates_Search_Candidate_Highlight_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Aggregate_Fields = {
  __typename?: 'explore_candidates_search_candidate_highlight_aggregate_fields';
  avg: Maybe<Explore_Candidates_Search_Candidate_Highlight_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Explore_Candidates_Search_Candidate_Highlight_Max_Fields>;
  min: Maybe<Explore_Candidates_Search_Candidate_Highlight_Min_Fields>;
  stddev: Maybe<Explore_Candidates_Search_Candidate_Highlight_Stddev_Fields>;
  stddev_pop: Maybe<Explore_Candidates_Search_Candidate_Highlight_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Explore_Candidates_Search_Candidate_Highlight_Stddev_Samp_Fields>;
  sum: Maybe<Explore_Candidates_Search_Candidate_Highlight_Sum_Fields>;
  var_pop: Maybe<Explore_Candidates_Search_Candidate_Highlight_Var_Pop_Fields>;
  var_samp: Maybe<Explore_Candidates_Search_Candidate_Highlight_Var_Samp_Fields>;
  variance: Maybe<Explore_Candidates_Search_Candidate_Highlight_Variance_Fields>;
};

/** aggregate fields of "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Aggregate_Order_By = {
  avg?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Max_Order_By>;
  min?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Min_Order_By>;
  stddev?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Sum_Order_By>;
  var_pop?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Var_Samp_Order_By>;
  variance?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Arr_Rel_Insert_Input = {
  data: Array<Explore_Candidates_Search_Candidate_Highlight_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_On_Conflict>;
};

/** aggregate avg on columns */
export type Explore_Candidates_Search_Candidate_Highlight_Avg_Fields = {
  __typename?: 'explore_candidates_search_candidate_highlight_avg_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Avg_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "explore_candidates_search_candidate_highlight". All fields are combined with a logical 'AND'. */
export type Explore_Candidates_Search_Candidate_Highlight_Bool_Exp = {
  _and?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>>;
  _not?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>;
  _or?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>>;
  candidate_highlight?: InputMaybe<Candidate_Highlight_Choices_Enum_Comparison_Exp>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
  explore_candidates_search_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "explore_candidates_search_candidate_highlight" */
export enum Explore_Candidates_Search_Candidate_Highlight_Constraint {
  /** unique or primary key constraint on columns "explore_candidates_search_id", "candidate_highlight" */
  ExploreCandidatesSearchCandidateHighlightPkey = 'explore_candidates_search_candidate_highlight_pkey',
}

/** input type for incrementing numeric columns in table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Inc_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Insert_Input = {
  candidate_highlight?: InputMaybe<Candidate_Highlight_Choices_Enum>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Obj_Rel_Insert_Input>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Explore_Candidates_Search_Candidate_Highlight_Max_Fields = {
  __typename?: 'explore_candidates_search_candidate_highlight_max_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Max_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Explore_Candidates_Search_Candidate_Highlight_Min_Fields = {
  __typename?: 'explore_candidates_search_candidate_highlight_min_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Min_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Mutation_Response = {
  __typename?: 'explore_candidates_search_candidate_highlight_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Explore_Candidates_Search_Candidate_Highlight>;
};

/** on_conflict condition type for table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_On_Conflict = {
  constraint: Explore_Candidates_Search_Candidate_Highlight_Constraint;
  update_columns?: Array<Explore_Candidates_Search_Candidate_Highlight_Update_Column>;
  where?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>;
};

/** Ordering options when selecting data from "explore_candidates_search_candidate_highlight". */
export type Explore_Candidates_Search_Candidate_Highlight_Order_By = {
  candidate_highlight?: InputMaybe<Order_By>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Order_By>;
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: explore_candidates_search_candidate_highlight */
export type Explore_Candidates_Search_Candidate_Highlight_Pk_Columns_Input = {
  candidate_highlight: Candidate_Highlight_Choices_Enum;
  explore_candidates_search_id: Scalars['Int'];
};

/** select columns of table "explore_candidates_search_candidate_highlight" */
export enum Explore_Candidates_Search_Candidate_Highlight_Select_Column {
  /** column name */
  CandidateHighlight = 'candidate_highlight',
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
}

/** input type for updating data in table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Set_Input = {
  candidate_highlight?: InputMaybe<Candidate_Highlight_Choices_Enum>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Explore_Candidates_Search_Candidate_Highlight_Stddev_Fields = {
  __typename?: 'explore_candidates_search_candidate_highlight_stddev_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Stddev_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Explore_Candidates_Search_Candidate_Highlight_Stddev_Pop_Fields = {
  __typename?: 'explore_candidates_search_candidate_highlight_stddev_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Stddev_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Explore_Candidates_Search_Candidate_Highlight_Stddev_Samp_Fields = {
  __typename?: 'explore_candidates_search_candidate_highlight_stddev_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Stddev_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Explore_Candidates_Search_Candidate_Highlight_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Explore_Candidates_Search_Candidate_Highlight_Stream_Cursor_Value_Input = {
  candidate_highlight?: InputMaybe<Candidate_Highlight_Choices_Enum>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Explore_Candidates_Search_Candidate_Highlight_Sum_Fields = {
  __typename?: 'explore_candidates_search_candidate_highlight_sum_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Sum_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** update columns of table "explore_candidates_search_candidate_highlight" */
export enum Explore_Candidates_Search_Candidate_Highlight_Update_Column {
  /** column name */
  CandidateHighlight = 'candidate_highlight',
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
}

export type Explore_Candidates_Search_Candidate_Highlight_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Set_Input>;
  /** filter the rows which have to be updated */
  where: Explore_Candidates_Search_Candidate_Highlight_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Explore_Candidates_Search_Candidate_Highlight_Var_Pop_Fields = {
  __typename?: 'explore_candidates_search_candidate_highlight_var_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Var_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Explore_Candidates_Search_Candidate_Highlight_Var_Samp_Fields = {
  __typename?: 'explore_candidates_search_candidate_highlight_var_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Var_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Explore_Candidates_Search_Candidate_Highlight_Variance_Fields = {
  __typename?: 'explore_candidates_search_candidate_highlight_variance_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "explore_candidates_search_candidate_highlight" */
export type Explore_Candidates_Search_Candidate_Highlight_Variance_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "explore_candidates_search" */
export enum Explore_Candidates_Search_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExploreCandidatesSearchPkey = 'explore_candidates_search_pkey',
}

/** input type for incrementing numeric columns in table "explore_candidates_search" */
export type Explore_Candidates_Search_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "explore_candidates_search" */
export type Explore_Candidates_Search_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  explore_candidates_popular_searches?: InputMaybe<Explore_Candidates_Popular_Search_Arr_Rel_Insert_Input>;
  explore_candidates_search_candidate_highlights?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Arr_Rel_Insert_Input>;
  explore_candidates_user_saved_searches?: InputMaybe<Explore_Candidates_User_Saved_Search_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  min_years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  must_have_skills?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Arr_Rel_Insert_Input>;
  nice_to_have_skills?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Arr_Rel_Insert_Input>;
  regions?: InputMaybe<Explore_Candidates_Search_Region_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Role_Choices_Enum>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Explore_Candidates_Search_Max_Fields = {
  __typename?: 'explore_candidates_search_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  title: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Explore_Candidates_Search_Min_Fields = {
  __typename?: 'explore_candidates_search_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  title: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** M2M table for specifying must-have skills for explore candidates searches */
export type Explore_Candidates_Search_Must_Have_Skill = {
  __typename?: 'explore_candidates_search_must_have_skill';
  /** An object relationship */
  explore_candidates_search: Explore_Candidates_Search;
  explore_candidates_search_id: Scalars['Int'];
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
};

/** aggregated selection of "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Aggregate = {
  __typename?: 'explore_candidates_search_must_have_skill_aggregate';
  aggregate: Maybe<Explore_Candidates_Search_Must_Have_Skill_Aggregate_Fields>;
  nodes: Array<Explore_Candidates_Search_Must_Have_Skill>;
};

export type Explore_Candidates_Search_Must_Have_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Aggregate_Bool_Exp_Count>;
};

export type Explore_Candidates_Search_Must_Have_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Aggregate_Fields = {
  __typename?: 'explore_candidates_search_must_have_skill_aggregate_fields';
  avg: Maybe<Explore_Candidates_Search_Must_Have_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Explore_Candidates_Search_Must_Have_Skill_Max_Fields>;
  min: Maybe<Explore_Candidates_Search_Must_Have_Skill_Min_Fields>;
  stddev: Maybe<Explore_Candidates_Search_Must_Have_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Explore_Candidates_Search_Must_Have_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Explore_Candidates_Search_Must_Have_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Explore_Candidates_Search_Must_Have_Skill_Sum_Fields>;
  var_pop: Maybe<Explore_Candidates_Search_Must_Have_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Explore_Candidates_Search_Must_Have_Skill_Var_Samp_Fields>;
  variance: Maybe<Explore_Candidates_Search_Must_Have_Skill_Variance_Fields>;
};

/** aggregate fields of "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Max_Order_By>;
  min?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Min_Order_By>;
  stddev?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Arr_Rel_Insert_Input = {
  data: Array<Explore_Candidates_Search_Must_Have_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Explore_Candidates_Search_Must_Have_Skill_Avg_Fields = {
  __typename?: 'explore_candidates_search_must_have_skill_avg_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Avg_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "explore_candidates_search_must_have_skill". All fields are combined with a logical 'AND'. */
export type Explore_Candidates_Search_Must_Have_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>>;
  _not?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
  explore_candidates_search_id?: InputMaybe<Int_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "explore_candidates_search_must_have_skill" */
export enum Explore_Candidates_Search_Must_Have_Skill_Constraint {
  /** unique or primary key constraint on columns "explore_candidates_search_id", "skill_id" */
  ExploreCandidatesSearchMustHaveSkillPkey = 'explore_candidates_search_must_have_skill_pkey',
}

/** input type for incrementing numeric columns in table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Inc_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Insert_Input = {
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Obj_Rel_Insert_Input>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Explore_Candidates_Search_Must_Have_Skill_Max_Fields = {
  __typename?: 'explore_candidates_search_must_have_skill_max_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Max_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Explore_Candidates_Search_Must_Have_Skill_Min_Fields = {
  __typename?: 'explore_candidates_search_must_have_skill_min_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Min_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Mutation_Response = {
  __typename?: 'explore_candidates_search_must_have_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Explore_Candidates_Search_Must_Have_Skill>;
};

/** on_conflict condition type for table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_On_Conflict = {
  constraint: Explore_Candidates_Search_Must_Have_Skill_Constraint;
  update_columns?: Array<Explore_Candidates_Search_Must_Have_Skill_Update_Column>;
  where?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "explore_candidates_search_must_have_skill". */
export type Explore_Candidates_Search_Must_Have_Skill_Order_By = {
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Order_By>;
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: explore_candidates_search_must_have_skill */
export type Explore_Candidates_Search_Must_Have_Skill_Pk_Columns_Input = {
  explore_candidates_search_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

/** select columns of table "explore_candidates_search_must_have_skill" */
export enum Explore_Candidates_Search_Must_Have_Skill_Select_Column {
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
  /** column name */
  SkillId = 'skill_id',
}

/** input type for updating data in table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Set_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Explore_Candidates_Search_Must_Have_Skill_Stddev_Fields = {
  __typename?: 'explore_candidates_search_must_have_skill_stddev_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Stddev_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Explore_Candidates_Search_Must_Have_Skill_Stddev_Pop_Fields = {
  __typename?: 'explore_candidates_search_must_have_skill_stddev_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Stddev_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Explore_Candidates_Search_Must_Have_Skill_Stddev_Samp_Fields = {
  __typename?: 'explore_candidates_search_must_have_skill_stddev_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Stddev_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Explore_Candidates_Search_Must_Have_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Explore_Candidates_Search_Must_Have_Skill_Stream_Cursor_Value_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Explore_Candidates_Search_Must_Have_Skill_Sum_Fields = {
  __typename?: 'explore_candidates_search_must_have_skill_sum_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Sum_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "explore_candidates_search_must_have_skill" */
export enum Explore_Candidates_Search_Must_Have_Skill_Update_Column {
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
  /** column name */
  SkillId = 'skill_id',
}

export type Explore_Candidates_Search_Must_Have_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Explore_Candidates_Search_Must_Have_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Explore_Candidates_Search_Must_Have_Skill_Var_Pop_Fields = {
  __typename?: 'explore_candidates_search_must_have_skill_var_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Var_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Explore_Candidates_Search_Must_Have_Skill_Var_Samp_Fields = {
  __typename?: 'explore_candidates_search_must_have_skill_var_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Var_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Explore_Candidates_Search_Must_Have_Skill_Variance_Fields = {
  __typename?: 'explore_candidates_search_must_have_skill_variance_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "explore_candidates_search_must_have_skill" */
export type Explore_Candidates_Search_Must_Have_Skill_Variance_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "explore_candidates_search" */
export type Explore_Candidates_Search_Mutation_Response = {
  __typename?: 'explore_candidates_search_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Explore_Candidates_Search>;
};

/** M2M table for specifying nice-to-have skills for explore candidates searches */
export type Explore_Candidates_Search_Nice_To_Have_Skill = {
  __typename?: 'explore_candidates_search_nice_to_have_skill';
  /** An object relationship */
  explore_candidates_search: Explore_Candidates_Search;
  explore_candidates_search_id: Scalars['Int'];
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
};

/** aggregated selection of "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_aggregate';
  aggregate: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate_Fields>;
  nodes: Array<Explore_Candidates_Search_Nice_To_Have_Skill>;
};

export type Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate_Bool_Exp_Count>;
};

export type Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate_Fields = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_aggregate_fields';
  avg: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Max_Fields>;
  min: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Min_Fields>;
  stddev: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Sum_Fields>;
  var_pop: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Var_Samp_Fields>;
  variance: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Variance_Fields>;
};

/** aggregate fields of "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Max_Order_By>;
  min?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Min_Order_By>;
  stddev?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Arr_Rel_Insert_Input = {
  data: Array<Explore_Candidates_Search_Nice_To_Have_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Avg_Fields = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_avg_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Avg_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "explore_candidates_search_nice_to_have_skill". All fields are combined with a logical 'AND'. */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>>;
  _not?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
  explore_candidates_search_id?: InputMaybe<Int_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "explore_candidates_search_nice_to_have_skill" */
export enum Explore_Candidates_Search_Nice_To_Have_Skill_Constraint {
  /** unique or primary key constraint on columns "explore_candidates_search_id", "skill_id" */
  ExploreCandidatesSearchNiceToHaveSkillPkey = 'explore_candidates_search_nice_to_have_skill_pkey',
}

/** input type for incrementing numeric columns in table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Inc_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Insert_Input = {
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Obj_Rel_Insert_Input>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Max_Fields = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_max_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Max_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Min_Fields = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_min_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Min_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Mutation_Response = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Explore_Candidates_Search_Nice_To_Have_Skill>;
};

/** on_conflict condition type for table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_On_Conflict = {
  constraint: Explore_Candidates_Search_Nice_To_Have_Skill_Constraint;
  update_columns?: Array<Explore_Candidates_Search_Nice_To_Have_Skill_Update_Column>;
  where?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "explore_candidates_search_nice_to_have_skill". */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Order_By = {
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Order_By>;
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: explore_candidates_search_nice_to_have_skill */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Pk_Columns_Input = {
  explore_candidates_search_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

/** select columns of table "explore_candidates_search_nice_to_have_skill" */
export enum Explore_Candidates_Search_Nice_To_Have_Skill_Select_Column {
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
  /** column name */
  SkillId = 'skill_id',
}

/** input type for updating data in table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Set_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Fields = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_stddev_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Pop_Fields = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_stddev_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Samp_Fields = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_stddev_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Stddev_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Explore_Candidates_Search_Nice_To_Have_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Stream_Cursor_Value_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Sum_Fields = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_sum_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Sum_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "explore_candidates_search_nice_to_have_skill" */
export enum Explore_Candidates_Search_Nice_To_Have_Skill_Update_Column {
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
  /** column name */
  SkillId = 'skill_id',
}

export type Explore_Candidates_Search_Nice_To_Have_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Var_Pop_Fields = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_var_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Var_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Var_Samp_Fields = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_var_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Var_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Variance_Fields = {
  __typename?: 'explore_candidates_search_nice_to_have_skill_variance_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "explore_candidates_search_nice_to_have_skill" */
export type Explore_Candidates_Search_Nice_To_Have_Skill_Variance_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "explore_candidates_search" */
export type Explore_Candidates_Search_Obj_Rel_Insert_Input = {
  data: Explore_Candidates_Search_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Explore_Candidates_Search_On_Conflict>;
};

/** on_conflict condition type for table "explore_candidates_search" */
export type Explore_Candidates_Search_On_Conflict = {
  constraint: Explore_Candidates_Search_Constraint;
  update_columns?: Array<Explore_Candidates_Search_Update_Column>;
  where?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
};

/** Ordering options when selecting data from "explore_candidates_search". */
export type Explore_Candidates_Search_Order_By = {
  created_at?: InputMaybe<Order_By>;
  employment_type?: InputMaybe<Order_By>;
  explore_candidates_popular_searches_aggregate?: InputMaybe<Explore_Candidates_Popular_Search_Aggregate_Order_By>;
  explore_candidates_search_candidate_highlights_aggregate?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Aggregate_Order_By>;
  explore_candidates_user_saved_searches_aggregate?: InputMaybe<Explore_Candidates_User_Saved_Search_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  min_years_of_experience?: InputMaybe<Order_By>;
  must_have_skills_aggregate?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Aggregate_Order_By>;
  nice_to_have_skills_aggregate?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate_Order_By>;
  regions_aggregate?: InputMaybe<Explore_Candidates_Search_Region_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: explore_candidates_search */
export type Explore_Candidates_Search_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** M2M table for specifying regions for explore candidates searches */
export type Explore_Candidates_Search_Region = {
  __typename?: 'explore_candidates_search_region';
  /** An object relationship */
  explore_candidates_search: Explore_Candidates_Search;
  explore_candidates_search_id: Scalars['Int'];
  region: Region_Choices_Enum;
  /** An object relationship */
  region_choice: Region_Choices;
};

/** aggregated selection of "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Aggregate = {
  __typename?: 'explore_candidates_search_region_aggregate';
  aggregate: Maybe<Explore_Candidates_Search_Region_Aggregate_Fields>;
  nodes: Array<Explore_Candidates_Search_Region>;
};

export type Explore_Candidates_Search_Region_Aggregate_Bool_Exp = {
  count?: InputMaybe<Explore_Candidates_Search_Region_Aggregate_Bool_Exp_Count>;
};

export type Explore_Candidates_Search_Region_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Explore_Candidates_Search_Region_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Explore_Candidates_Search_Region_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Aggregate_Fields = {
  __typename?: 'explore_candidates_search_region_aggregate_fields';
  avg: Maybe<Explore_Candidates_Search_Region_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Explore_Candidates_Search_Region_Max_Fields>;
  min: Maybe<Explore_Candidates_Search_Region_Min_Fields>;
  stddev: Maybe<Explore_Candidates_Search_Region_Stddev_Fields>;
  stddev_pop: Maybe<Explore_Candidates_Search_Region_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Explore_Candidates_Search_Region_Stddev_Samp_Fields>;
  sum: Maybe<Explore_Candidates_Search_Region_Sum_Fields>;
  var_pop: Maybe<Explore_Candidates_Search_Region_Var_Pop_Fields>;
  var_samp: Maybe<Explore_Candidates_Search_Region_Var_Samp_Fields>;
  variance: Maybe<Explore_Candidates_Search_Region_Variance_Fields>;
};

/** aggregate fields of "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Explore_Candidates_Search_Region_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Aggregate_Order_By = {
  avg?: InputMaybe<Explore_Candidates_Search_Region_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Explore_Candidates_Search_Region_Max_Order_By>;
  min?: InputMaybe<Explore_Candidates_Search_Region_Min_Order_By>;
  stddev?: InputMaybe<Explore_Candidates_Search_Region_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Explore_Candidates_Search_Region_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Explore_Candidates_Search_Region_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Explore_Candidates_Search_Region_Sum_Order_By>;
  var_pop?: InputMaybe<Explore_Candidates_Search_Region_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Explore_Candidates_Search_Region_Var_Samp_Order_By>;
  variance?: InputMaybe<Explore_Candidates_Search_Region_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Arr_Rel_Insert_Input = {
  data: Array<Explore_Candidates_Search_Region_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Explore_Candidates_Search_Region_On_Conflict>;
};

/** aggregate avg on columns */
export type Explore_Candidates_Search_Region_Avg_Fields = {
  __typename?: 'explore_candidates_search_region_avg_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Avg_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "explore_candidates_search_region". All fields are combined with a logical 'AND'. */
export type Explore_Candidates_Search_Region_Bool_Exp = {
  _and?: InputMaybe<Array<Explore_Candidates_Search_Region_Bool_Exp>>;
  _not?: InputMaybe<Explore_Candidates_Search_Region_Bool_Exp>;
  _or?: InputMaybe<Array<Explore_Candidates_Search_Region_Bool_Exp>>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
  explore_candidates_search_id?: InputMaybe<Int_Comparison_Exp>;
  region?: InputMaybe<Region_Choices_Enum_Comparison_Exp>;
  region_choice?: InputMaybe<Region_Choices_Bool_Exp>;
};

/** unique or primary key constraints on table "explore_candidates_search_region" */
export enum Explore_Candidates_Search_Region_Constraint {
  /** unique or primary key constraint on columns "region", "explore_candidates_search_id" */
  ExploreCandidatesSearchRegionPkey = 'explore_candidates_search_region_pkey',
}

/** input type for incrementing numeric columns in table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Inc_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Insert_Input = {
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Obj_Rel_Insert_Input>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Region_Choices_Enum>;
  region_choice?: InputMaybe<Region_Choices_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Explore_Candidates_Search_Region_Max_Fields = {
  __typename?: 'explore_candidates_search_region_max_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Max_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Explore_Candidates_Search_Region_Min_Fields = {
  __typename?: 'explore_candidates_search_region_min_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Min_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Mutation_Response = {
  __typename?: 'explore_candidates_search_region_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Explore_Candidates_Search_Region>;
};

/** on_conflict condition type for table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_On_Conflict = {
  constraint: Explore_Candidates_Search_Region_Constraint;
  update_columns?: Array<Explore_Candidates_Search_Region_Update_Column>;
  where?: InputMaybe<Explore_Candidates_Search_Region_Bool_Exp>;
};

/** Ordering options when selecting data from "explore_candidates_search_region". */
export type Explore_Candidates_Search_Region_Order_By = {
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Order_By>;
  explore_candidates_search_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  region_choice?: InputMaybe<Region_Choices_Order_By>;
};

/** primary key columns input for table: explore_candidates_search_region */
export type Explore_Candidates_Search_Region_Pk_Columns_Input = {
  explore_candidates_search_id: Scalars['Int'];
  region: Region_Choices_Enum;
};

/** select columns of table "explore_candidates_search_region" */
export enum Explore_Candidates_Search_Region_Select_Column {
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
  /** column name */
  Region = 'region',
}

/** input type for updating data in table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Set_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Region_Choices_Enum>;
};

/** aggregate stddev on columns */
export type Explore_Candidates_Search_Region_Stddev_Fields = {
  __typename?: 'explore_candidates_search_region_stddev_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Stddev_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Explore_Candidates_Search_Region_Stddev_Pop_Fields = {
  __typename?: 'explore_candidates_search_region_stddev_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Stddev_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Explore_Candidates_Search_Region_Stddev_Samp_Fields = {
  __typename?: 'explore_candidates_search_region_stddev_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Stddev_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Explore_Candidates_Search_Region_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Explore_Candidates_Search_Region_Stream_Cursor_Value_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Region_Choices_Enum>;
};

/** aggregate sum on columns */
export type Explore_Candidates_Search_Region_Sum_Fields = {
  __typename?: 'explore_candidates_search_region_sum_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Sum_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** update columns of table "explore_candidates_search_region" */
export enum Explore_Candidates_Search_Region_Update_Column {
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
  /** column name */
  Region = 'region',
}

export type Explore_Candidates_Search_Region_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Explore_Candidates_Search_Region_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Explore_Candidates_Search_Region_Set_Input>;
  /** filter the rows which have to be updated */
  where: Explore_Candidates_Search_Region_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Explore_Candidates_Search_Region_Var_Pop_Fields = {
  __typename?: 'explore_candidates_search_region_var_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Var_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Explore_Candidates_Search_Region_Var_Samp_Fields = {
  __typename?: 'explore_candidates_search_region_var_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Var_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Explore_Candidates_Search_Region_Variance_Fields = {
  __typename?: 'explore_candidates_search_region_variance_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "explore_candidates_search_region" */
export type Explore_Candidates_Search_Region_Variance_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
};

/** select columns of table "explore_candidates_search" */
export enum Explore_Candidates_Search_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  Id = 'id',
  /** column name */
  MinYearsOfExperience = 'min_years_of_experience',
  /** column name */
  Role = 'role',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "explore_candidates_search" */
export type Explore_Candidates_Search_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  min_years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  role?: InputMaybe<Role_Choices_Enum>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Explore_Candidates_Search_Stddev_Fields = {
  __typename?: 'explore_candidates_search_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Explore_Candidates_Search_Stddev_Pop_Fields = {
  __typename?: 'explore_candidates_search_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Explore_Candidates_Search_Stddev_Samp_Fields = {
  __typename?: 'explore_candidates_search_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "explore_candidates_search" */
export type Explore_Candidates_Search_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Explore_Candidates_Search_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Explore_Candidates_Search_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  min_years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  role?: InputMaybe<Role_Choices_Enum>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Explore_Candidates_Search_Sum_Fields = {
  __typename?: 'explore_candidates_search_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "explore_candidates_search" */
export enum Explore_Candidates_Search_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  Id = 'id',
  /** column name */
  MinYearsOfExperience = 'min_years_of_experience',
  /** column name */
  Role = 'role',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Explore_Candidates_Search_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Explore_Candidates_Search_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Explore_Candidates_Search_Set_Input>;
  /** filter the rows which have to be updated */
  where: Explore_Candidates_Search_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Explore_Candidates_Search_Var_Pop_Fields = {
  __typename?: 'explore_candidates_search_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Explore_Candidates_Search_Var_Samp_Fields = {
  __typename?: 'explore_candidates_search_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Explore_Candidates_Search_Variance_Fields = {
  __typename?: 'explore_candidates_search_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** Table for storing user-saved explore candidates searches */
export type Explore_Candidates_User_Saved_Search = {
  __typename?: 'explore_candidates_user_saved_search';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  explore_candidates_search: Explore_Candidates_Search;
  explore_candidates_search_id: Scalars['Int'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_info: User_Info;
  user_info_id: Scalars['Int'];
};

/** aggregated selection of "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Aggregate = {
  __typename?: 'explore_candidates_user_saved_search_aggregate';
  aggregate: Maybe<Explore_Candidates_User_Saved_Search_Aggregate_Fields>;
  nodes: Array<Explore_Candidates_User_Saved_Search>;
};

export type Explore_Candidates_User_Saved_Search_Aggregate_Bool_Exp = {
  count?: InputMaybe<Explore_Candidates_User_Saved_Search_Aggregate_Bool_Exp_Count>;
};

export type Explore_Candidates_User_Saved_Search_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Explore_Candidates_User_Saved_Search_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Aggregate_Fields = {
  __typename?: 'explore_candidates_user_saved_search_aggregate_fields';
  avg: Maybe<Explore_Candidates_User_Saved_Search_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Explore_Candidates_User_Saved_Search_Max_Fields>;
  min: Maybe<Explore_Candidates_User_Saved_Search_Min_Fields>;
  stddev: Maybe<Explore_Candidates_User_Saved_Search_Stddev_Fields>;
  stddev_pop: Maybe<Explore_Candidates_User_Saved_Search_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Explore_Candidates_User_Saved_Search_Stddev_Samp_Fields>;
  sum: Maybe<Explore_Candidates_User_Saved_Search_Sum_Fields>;
  var_pop: Maybe<Explore_Candidates_User_Saved_Search_Var_Pop_Fields>;
  var_samp: Maybe<Explore_Candidates_User_Saved_Search_Var_Samp_Fields>;
  variance: Maybe<Explore_Candidates_User_Saved_Search_Variance_Fields>;
};

/** aggregate fields of "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Aggregate_Order_By = {
  avg?: InputMaybe<Explore_Candidates_User_Saved_Search_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Explore_Candidates_User_Saved_Search_Max_Order_By>;
  min?: InputMaybe<Explore_Candidates_User_Saved_Search_Min_Order_By>;
  stddev?: InputMaybe<Explore_Candidates_User_Saved_Search_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Explore_Candidates_User_Saved_Search_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Explore_Candidates_User_Saved_Search_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Explore_Candidates_User_Saved_Search_Sum_Order_By>;
  var_pop?: InputMaybe<Explore_Candidates_User_Saved_Search_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Explore_Candidates_User_Saved_Search_Var_Samp_Order_By>;
  variance?: InputMaybe<Explore_Candidates_User_Saved_Search_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Arr_Rel_Insert_Input = {
  data: Array<Explore_Candidates_User_Saved_Search_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Explore_Candidates_User_Saved_Search_On_Conflict>;
};

/** aggregate avg on columns */
export type Explore_Candidates_User_Saved_Search_Avg_Fields = {
  __typename?: 'explore_candidates_user_saved_search_avg_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Avg_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "explore_candidates_user_saved_search". All fields are combined with a logical 'AND'. */
export type Explore_Candidates_User_Saved_Search_Bool_Exp = {
  _and?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Bool_Exp>>;
  _not?: InputMaybe<Explore_Candidates_User_Saved_Search_Bool_Exp>;
  _or?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
  explore_candidates_search_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
  user_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "explore_candidates_user_saved_search" */
export enum Explore_Candidates_User_Saved_Search_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExploreCandidatesUserSavedSearchPkey = 'explore_candidates_user_saved_search_pkey',
}

/** input type for incrementing numeric columns in table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Inc_Input = {
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Obj_Rel_Insert_Input>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Explore_Candidates_User_Saved_Search_Max_Fields = {
  __typename?: 'explore_candidates_user_saved_search_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Explore_Candidates_User_Saved_Search_Min_Fields = {
  __typename?: 'explore_candidates_user_saved_search_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Mutation_Response = {
  __typename?: 'explore_candidates_user_saved_search_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Explore_Candidates_User_Saved_Search>;
};

/** on_conflict condition type for table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_On_Conflict = {
  constraint: Explore_Candidates_User_Saved_Search_Constraint;
  update_columns?: Array<Explore_Candidates_User_Saved_Search_Update_Column>;
  where?: InputMaybe<Explore_Candidates_User_Saved_Search_Bool_Exp>;
};

/** Ordering options when selecting data from "explore_candidates_user_saved_search". */
export type Explore_Candidates_User_Saved_Search_Order_By = {
  created_at?: InputMaybe<Order_By>;
  explore_candidates_search?: InputMaybe<Explore_Candidates_Search_Order_By>;
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: explore_candidates_user_saved_search */
export type Explore_Candidates_User_Saved_Search_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "explore_candidates_user_saved_search" */
export enum Explore_Candidates_User_Saved_Search_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id',
}

/** input type for updating data in table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Explore_Candidates_User_Saved_Search_Stddev_Fields = {
  __typename?: 'explore_candidates_user_saved_search_stddev_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Stddev_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Explore_Candidates_User_Saved_Search_Stddev_Pop_Fields = {
  __typename?: 'explore_candidates_user_saved_search_stddev_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Stddev_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Explore_Candidates_User_Saved_Search_Stddev_Samp_Fields = {
  __typename?: 'explore_candidates_user_saved_search_stddev_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Stddev_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Explore_Candidates_User_Saved_Search_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Explore_Candidates_User_Saved_Search_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  explore_candidates_search_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Explore_Candidates_User_Saved_Search_Sum_Fields = {
  __typename?: 'explore_candidates_user_saved_search_sum_fields';
  explore_candidates_search_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Sum_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** update columns of table "explore_candidates_user_saved_search" */
export enum Explore_Candidates_User_Saved_Search_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExploreCandidatesSearchId = 'explore_candidates_search_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id',
}

export type Explore_Candidates_User_Saved_Search_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Explore_Candidates_User_Saved_Search_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Explore_Candidates_User_Saved_Search_Set_Input>;
  /** filter the rows which have to be updated */
  where: Explore_Candidates_User_Saved_Search_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Explore_Candidates_User_Saved_Search_Var_Pop_Fields = {
  __typename?: 'explore_candidates_user_saved_search_var_pop_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Var_Pop_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Explore_Candidates_User_Saved_Search_Var_Samp_Fields = {
  __typename?: 'explore_candidates_user_saved_search_var_samp_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Var_Samp_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Explore_Candidates_User_Saved_Search_Variance_Fields = {
  __typename?: 'explore_candidates_user_saved_search_variance_fields';
  explore_candidates_search_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "explore_candidates_user_saved_search" */
export type Explore_Candidates_User_Saved_Search_Variance_Order_By = {
  explore_candidates_search_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "external_candidate_meta" */
export type External_Candidate_Meta = {
  __typename?: 'external_candidate_meta';
  agency_contact_email: Maybe<Scalars['citext']>;
  cid: Maybe<Scalars['String']>;
  country_of_residence: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cv_or_resume_url: Maybe<Scalars['citext']>;
  email: Scalars['citext'];
  first_name: Maybe<Scalars['String']>;
  form_submission_url: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  last_name: Maybe<Scalars['String']>;
  linkedin_or_personal_url: Maybe<Scalars['String']>;
  referring_url: Maybe<Scalars['String']>;
  skills_array: Maybe<Scalars['jsonb']>;
  source: Candidate_Curation_Workflow_Source_Choices_Enum;
  updated_at: Scalars['timestamptz'];
  utm_campaign: Maybe<Scalars['String']>;
  utm_content: Maybe<Scalars['String']>;
  utm_medium: Maybe<Scalars['String']>;
  utm_source: Maybe<Scalars['String']>;
  utm_term: Maybe<Scalars['String']>;
  years_of_experience: Maybe<Scalars['Int']>;
};

/** columns and relationships of "external_candidate_meta" */
export type External_Candidate_MetaSkills_ArrayArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "external_candidate_meta" */
export type External_Candidate_Meta_Aggregate = {
  __typename?: 'external_candidate_meta_aggregate';
  aggregate: Maybe<External_Candidate_Meta_Aggregate_Fields>;
  nodes: Array<External_Candidate_Meta>;
};

/** aggregate fields of "external_candidate_meta" */
export type External_Candidate_Meta_Aggregate_Fields = {
  __typename?: 'external_candidate_meta_aggregate_fields';
  avg: Maybe<External_Candidate_Meta_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<External_Candidate_Meta_Max_Fields>;
  min: Maybe<External_Candidate_Meta_Min_Fields>;
  stddev: Maybe<External_Candidate_Meta_Stddev_Fields>;
  stddev_pop: Maybe<External_Candidate_Meta_Stddev_Pop_Fields>;
  stddev_samp: Maybe<External_Candidate_Meta_Stddev_Samp_Fields>;
  sum: Maybe<External_Candidate_Meta_Sum_Fields>;
  var_pop: Maybe<External_Candidate_Meta_Var_Pop_Fields>;
  var_samp: Maybe<External_Candidate_Meta_Var_Samp_Fields>;
  variance: Maybe<External_Candidate_Meta_Variance_Fields>;
};

/** aggregate fields of "external_candidate_meta" */
export type External_Candidate_Meta_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<External_Candidate_Meta_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type External_Candidate_Meta_Append_Input = {
  skills_array?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type External_Candidate_Meta_Avg_Fields = {
  __typename?: 'external_candidate_meta_avg_fields';
  id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "external_candidate_meta". All fields are combined with a logical 'AND'. */
export type External_Candidate_Meta_Bool_Exp = {
  _and?: InputMaybe<Array<External_Candidate_Meta_Bool_Exp>>;
  _not?: InputMaybe<External_Candidate_Meta_Bool_Exp>;
  _or?: InputMaybe<Array<External_Candidate_Meta_Bool_Exp>>;
  agency_contact_email?: InputMaybe<Citext_Comparison_Exp>;
  cid?: InputMaybe<String_Comparison_Exp>;
  country_of_residence?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  cv_or_resume_url?: InputMaybe<Citext_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  form_submission_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  linkedin_or_personal_url?: InputMaybe<String_Comparison_Exp>;
  referring_url?: InputMaybe<String_Comparison_Exp>;
  skills_array?: InputMaybe<Jsonb_Comparison_Exp>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  utm_campaign?: InputMaybe<String_Comparison_Exp>;
  utm_content?: InputMaybe<String_Comparison_Exp>;
  utm_medium?: InputMaybe<String_Comparison_Exp>;
  utm_source?: InputMaybe<String_Comparison_Exp>;
  utm_term?: InputMaybe<String_Comparison_Exp>;
  years_of_experience?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "external_candidate_meta" */
export enum External_Candidate_Meta_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExternalCandidateMetaPkey = 'external_candidate_meta_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type External_Candidate_Meta_Delete_At_Path_Input = {
  skills_array?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type External_Candidate_Meta_Delete_Elem_Input = {
  skills_array?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type External_Candidate_Meta_Delete_Key_Input = {
  skills_array?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "external_candidate_meta" */
export type External_Candidate_Meta_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  years_of_experience?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "external_candidate_meta" */
export type External_Candidate_Meta_Insert_Input = {
  agency_contact_email?: InputMaybe<Scalars['citext']>;
  cid?: InputMaybe<Scalars['String']>;
  country_of_residence?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cv_or_resume_url?: InputMaybe<Scalars['citext']>;
  email?: InputMaybe<Scalars['citext']>;
  first_name?: InputMaybe<Scalars['String']>;
  form_submission_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  linkedin_or_personal_url?: InputMaybe<Scalars['String']>;
  referring_url?: InputMaybe<Scalars['String']>;
  skills_array?: InputMaybe<Scalars['jsonb']>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
  utm_term?: InputMaybe<Scalars['String']>;
  years_of_experience?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type External_Candidate_Meta_Max_Fields = {
  __typename?: 'external_candidate_meta_max_fields';
  agency_contact_email: Maybe<Scalars['citext']>;
  cid: Maybe<Scalars['String']>;
  country_of_residence: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  cv_or_resume_url: Maybe<Scalars['citext']>;
  email: Maybe<Scalars['citext']>;
  first_name: Maybe<Scalars['String']>;
  form_submission_url: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  last_name: Maybe<Scalars['String']>;
  linkedin_or_personal_url: Maybe<Scalars['String']>;
  referring_url: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  utm_campaign: Maybe<Scalars['String']>;
  utm_content: Maybe<Scalars['String']>;
  utm_medium: Maybe<Scalars['String']>;
  utm_source: Maybe<Scalars['String']>;
  utm_term: Maybe<Scalars['String']>;
  years_of_experience: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type External_Candidate_Meta_Min_Fields = {
  __typename?: 'external_candidate_meta_min_fields';
  agency_contact_email: Maybe<Scalars['citext']>;
  cid: Maybe<Scalars['String']>;
  country_of_residence: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  cv_or_resume_url: Maybe<Scalars['citext']>;
  email: Maybe<Scalars['citext']>;
  first_name: Maybe<Scalars['String']>;
  form_submission_url: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  last_name: Maybe<Scalars['String']>;
  linkedin_or_personal_url: Maybe<Scalars['String']>;
  referring_url: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  utm_campaign: Maybe<Scalars['String']>;
  utm_content: Maybe<Scalars['String']>;
  utm_medium: Maybe<Scalars['String']>;
  utm_source: Maybe<Scalars['String']>;
  utm_term: Maybe<Scalars['String']>;
  years_of_experience: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "external_candidate_meta" */
export type External_Candidate_Meta_Mutation_Response = {
  __typename?: 'external_candidate_meta_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<External_Candidate_Meta>;
};

/** input type for inserting object relation for remote table "external_candidate_meta" */
export type External_Candidate_Meta_Obj_Rel_Insert_Input = {
  data: External_Candidate_Meta_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<External_Candidate_Meta_On_Conflict>;
};

/** on_conflict condition type for table "external_candidate_meta" */
export type External_Candidate_Meta_On_Conflict = {
  constraint: External_Candidate_Meta_Constraint;
  update_columns?: Array<External_Candidate_Meta_Update_Column>;
  where?: InputMaybe<External_Candidate_Meta_Bool_Exp>;
};

/** Ordering options when selecting data from "external_candidate_meta". */
export type External_Candidate_Meta_Order_By = {
  agency_contact_email?: InputMaybe<Order_By>;
  cid?: InputMaybe<Order_By>;
  country_of_residence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cv_or_resume_url?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  form_submission_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  linkedin_or_personal_url?: InputMaybe<Order_By>;
  referring_url?: InputMaybe<Order_By>;
  skills_array?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  utm_campaign?: InputMaybe<Order_By>;
  utm_content?: InputMaybe<Order_By>;
  utm_medium?: InputMaybe<Order_By>;
  utm_source?: InputMaybe<Order_By>;
  utm_term?: InputMaybe<Order_By>;
  years_of_experience?: InputMaybe<Order_By>;
};

/** primary key columns input for table: external_candidate_meta */
export type External_Candidate_Meta_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type External_Candidate_Meta_Prepend_Input = {
  skills_array?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "external_candidate_meta" */
export enum External_Candidate_Meta_Select_Column {
  /** column name */
  AgencyContactEmail = 'agency_contact_email',
  /** column name */
  Cid = 'cid',
  /** column name */
  CountryOfResidence = 'country_of_residence',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvOrResumeUrl = 'cv_or_resume_url',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FormSubmissionUrl = 'form_submission_url',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LinkedinOrPersonalUrl = 'linkedin_or_personal_url',
  /** column name */
  ReferringUrl = 'referring_url',
  /** column name */
  SkillsArray = 'skills_array',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmContent = 'utm_content',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  UtmTerm = 'utm_term',
  /** column name */
  YearsOfExperience = 'years_of_experience',
}

/** input type for updating data in table "external_candidate_meta" */
export type External_Candidate_Meta_Set_Input = {
  agency_contact_email?: InputMaybe<Scalars['citext']>;
  cid?: InputMaybe<Scalars['String']>;
  country_of_residence?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cv_or_resume_url?: InputMaybe<Scalars['citext']>;
  email?: InputMaybe<Scalars['citext']>;
  first_name?: InputMaybe<Scalars['String']>;
  form_submission_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  linkedin_or_personal_url?: InputMaybe<Scalars['String']>;
  referring_url?: InputMaybe<Scalars['String']>;
  skills_array?: InputMaybe<Scalars['jsonb']>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
  utm_term?: InputMaybe<Scalars['String']>;
  years_of_experience?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type External_Candidate_Meta_Stddev_Fields = {
  __typename?: 'external_candidate_meta_stddev_fields';
  id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type External_Candidate_Meta_Stddev_Pop_Fields = {
  __typename?: 'external_candidate_meta_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type External_Candidate_Meta_Stddev_Samp_Fields = {
  __typename?: 'external_candidate_meta_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "external_candidate_meta" */
export type External_Candidate_Meta_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: External_Candidate_Meta_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type External_Candidate_Meta_Stream_Cursor_Value_Input = {
  agency_contact_email?: InputMaybe<Scalars['citext']>;
  cid?: InputMaybe<Scalars['String']>;
  country_of_residence?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cv_or_resume_url?: InputMaybe<Scalars['citext']>;
  email?: InputMaybe<Scalars['citext']>;
  first_name?: InputMaybe<Scalars['String']>;
  form_submission_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  linkedin_or_personal_url?: InputMaybe<Scalars['String']>;
  referring_url?: InputMaybe<Scalars['String']>;
  skills_array?: InputMaybe<Scalars['jsonb']>;
  source?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
  utm_term?: InputMaybe<Scalars['String']>;
  years_of_experience?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type External_Candidate_Meta_Sum_Fields = {
  __typename?: 'external_candidate_meta_sum_fields';
  id: Maybe<Scalars['Int']>;
  years_of_experience: Maybe<Scalars['Int']>;
};

/** update columns of table "external_candidate_meta" */
export enum External_Candidate_Meta_Update_Column {
  /** column name */
  AgencyContactEmail = 'agency_contact_email',
  /** column name */
  Cid = 'cid',
  /** column name */
  CountryOfResidence = 'country_of_residence',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvOrResumeUrl = 'cv_or_resume_url',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FormSubmissionUrl = 'form_submission_url',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LinkedinOrPersonalUrl = 'linkedin_or_personal_url',
  /** column name */
  ReferringUrl = 'referring_url',
  /** column name */
  SkillsArray = 'skills_array',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmContent = 'utm_content',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  UtmTerm = 'utm_term',
  /** column name */
  YearsOfExperience = 'years_of_experience',
}

export type External_Candidate_Meta_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<External_Candidate_Meta_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<External_Candidate_Meta_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<External_Candidate_Meta_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<External_Candidate_Meta_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<External_Candidate_Meta_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<External_Candidate_Meta_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<External_Candidate_Meta_Set_Input>;
  /** filter the rows which have to be updated */
  where: External_Candidate_Meta_Bool_Exp;
};

/** aggregate var_pop on columns */
export type External_Candidate_Meta_Var_Pop_Fields = {
  __typename?: 'external_candidate_meta_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type External_Candidate_Meta_Var_Samp_Fields = {
  __typename?: 'external_candidate_meta_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type External_Candidate_Meta_Variance_Fields = {
  __typename?: 'external_candidate_meta_variance_fields';
  id: Maybe<Scalars['Float']>;
  years_of_experience: Maybe<Scalars['Float']>;
};

/** Features like "autocomplete", "job-application" etc. */
export type Feature = {
  __typename?: 'feature';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "feature" */
export type Feature_Aggregate = {
  __typename?: 'feature_aggregate';
  aggregate: Maybe<Feature_Aggregate_Fields>;
  nodes: Array<Feature>;
};

/** aggregate fields of "feature" */
export type Feature_Aggregate_Fields = {
  __typename?: 'feature_aggregate_fields';
  avg: Maybe<Feature_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Feature_Max_Fields>;
  min: Maybe<Feature_Min_Fields>;
  stddev: Maybe<Feature_Stddev_Fields>;
  stddev_pop: Maybe<Feature_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Feature_Stddev_Samp_Fields>;
  sum: Maybe<Feature_Sum_Fields>;
  var_pop: Maybe<Feature_Var_Pop_Fields>;
  var_samp: Maybe<Feature_Var_Samp_Fields>;
  variance: Maybe<Feature_Variance_Fields>;
};

/** aggregate fields of "feature" */
export type Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Feature_Avg_Fields = {
  __typename?: 'feature_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "feature". All fields are combined with a logical 'AND'. */
export type Feature_Bool_Exp = {
  _and?: InputMaybe<Array<Feature_Bool_Exp>>;
  _not?: InputMaybe<Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "feature" */
export enum Feature_Constraint {
  /** unique or primary key constraint on columns "name" */
  FeatureNameKey = 'feature_name_key',
  /** unique or primary key constraint on columns "id" */
  FeaturePkey = 'feature_pkey',
}

/** input type for incrementing numeric columns in table "feature" */
export type Feature_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "feature" */
export type Feature_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Feature_Max_Fields = {
  __typename?: 'feature_max_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Feature_Min_Fields = {
  __typename?: 'feature_min_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "feature" */
export type Feature_Mutation_Response = {
  __typename?: 'feature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature>;
};

/** input type for inserting object relation for remote table "feature" */
export type Feature_Obj_Rel_Insert_Input = {
  data: Feature_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Feature_On_Conflict>;
};

/** on_conflict condition type for table "feature" */
export type Feature_On_Conflict = {
  constraint: Feature_Constraint;
  update_columns?: Array<Feature_Update_Column>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "feature". */
export type Feature_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feature */
export type Feature_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "feature" */
export enum Feature_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "feature" */
export type Feature_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Feature_Stddev_Fields = {
  __typename?: 'feature_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Feature_Stddev_Pop_Fields = {
  __typename?: 'feature_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Feature_Stddev_Samp_Fields = {
  __typename?: 'feature_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "feature" */
export type Feature_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feature_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Feature_Sum_Fields = {
  __typename?: 'feature_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "feature" */
export enum Feature_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

export type Feature_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Feature_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Feature_Var_Pop_Fields = {
  __typename?: 'feature_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Feature_Var_Samp_Fields = {
  __typename?: 'feature_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Feature_Variance_Fields = {
  __typename?: 'feature_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']>;
  _gt?: InputMaybe<Scalars['geography']>;
  _gte?: InputMaybe<Scalars['geography']>;
  _in?: InputMaybe<Array<Scalars['geography']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geography']>;
  _lte?: InputMaybe<Scalars['geography']>;
  _neq?: InputMaybe<Scalars['geography']>;
  _nin?: InputMaybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']>;
  _gt?: InputMaybe<Scalars['geometry']>;
  _gte?: InputMaybe<Scalars['geometry']>;
  _in?: InputMaybe<Array<Scalars['geometry']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geometry']>;
  _lte?: InputMaybe<Scalars['geometry']>;
  _neq?: InputMaybe<Scalars['geometry']>;
  _nin?: InputMaybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']>;
};

/** columns and relationships of "hireflix_transcript" */
export type Hireflix_Transcript = {
  __typename?: 'hireflix_transcript';
  ai_extracted_info: Maybe<Scalars['jsonb']>;
  ai_extracted_info_version: Maybe<Scalars['String']>;
  candidate_email: Scalars['citext'];
  candidate_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  hireflix_transcript_blob: Hireflix_Transcript_Blob;
  id: Scalars['Int'];
  interview_completed_at: Maybe<Scalars['timestamptz']>;
  interview_id: Scalars['String'];
  position_id: Scalars['String'];
  position_name: Scalars['String'];
};

/** columns and relationships of "hireflix_transcript" */
export type Hireflix_TranscriptAi_Extracted_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hireflix_transcript" */
export type Hireflix_Transcript_Aggregate = {
  __typename?: 'hireflix_transcript_aggregate';
  aggregate: Maybe<Hireflix_Transcript_Aggregate_Fields>;
  nodes: Array<Hireflix_Transcript>;
};

export type Hireflix_Transcript_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hireflix_Transcript_Aggregate_Bool_Exp_Count>;
};

export type Hireflix_Transcript_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hireflix_Transcript_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hireflix_Transcript_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "hireflix_transcript" */
export type Hireflix_Transcript_Aggregate_Fields = {
  __typename?: 'hireflix_transcript_aggregate_fields';
  avg: Maybe<Hireflix_Transcript_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Hireflix_Transcript_Max_Fields>;
  min: Maybe<Hireflix_Transcript_Min_Fields>;
  stddev: Maybe<Hireflix_Transcript_Stddev_Fields>;
  stddev_pop: Maybe<Hireflix_Transcript_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Hireflix_Transcript_Stddev_Samp_Fields>;
  sum: Maybe<Hireflix_Transcript_Sum_Fields>;
  var_pop: Maybe<Hireflix_Transcript_Var_Pop_Fields>;
  var_samp: Maybe<Hireflix_Transcript_Var_Samp_Fields>;
  variance: Maybe<Hireflix_Transcript_Variance_Fields>;
};

/** aggregate fields of "hireflix_transcript" */
export type Hireflix_Transcript_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hireflix_Transcript_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hireflix_transcript" */
export type Hireflix_Transcript_Aggregate_Order_By = {
  avg?: InputMaybe<Hireflix_Transcript_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hireflix_Transcript_Max_Order_By>;
  min?: InputMaybe<Hireflix_Transcript_Min_Order_By>;
  stddev?: InputMaybe<Hireflix_Transcript_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hireflix_Transcript_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hireflix_Transcript_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hireflix_Transcript_Sum_Order_By>;
  var_pop?: InputMaybe<Hireflix_Transcript_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hireflix_Transcript_Var_Samp_Order_By>;
  variance?: InputMaybe<Hireflix_Transcript_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hireflix_Transcript_Append_Input = {
  ai_extracted_info?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hireflix_transcript" */
export type Hireflix_Transcript_Arr_Rel_Insert_Input = {
  data: Array<Hireflix_Transcript_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hireflix_Transcript_On_Conflict>;
};

/** aggregate avg on columns */
export type Hireflix_Transcript_Avg_Fields = {
  __typename?: 'hireflix_transcript_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hireflix_transcript" */
export type Hireflix_Transcript_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "hireflix_transcript_blob" */
export type Hireflix_Transcript_Blob = {
  __typename?: 'hireflix_transcript_blob';
  blob: Scalars['jsonb'];
  interview_id: Scalars['String'];
};

/** columns and relationships of "hireflix_transcript_blob" */
export type Hireflix_Transcript_BlobBlobArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hireflix_transcript_blob" */
export type Hireflix_Transcript_Blob_Aggregate = {
  __typename?: 'hireflix_transcript_blob_aggregate';
  aggregate: Maybe<Hireflix_Transcript_Blob_Aggregate_Fields>;
  nodes: Array<Hireflix_Transcript_Blob>;
};

/** aggregate fields of "hireflix_transcript_blob" */
export type Hireflix_Transcript_Blob_Aggregate_Fields = {
  __typename?: 'hireflix_transcript_blob_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Hireflix_Transcript_Blob_Max_Fields>;
  min: Maybe<Hireflix_Transcript_Blob_Min_Fields>;
};

/** aggregate fields of "hireflix_transcript_blob" */
export type Hireflix_Transcript_Blob_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hireflix_Transcript_Blob_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hireflix_Transcript_Blob_Append_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "hireflix_transcript_blob". All fields are combined with a logical 'AND'. */
export type Hireflix_Transcript_Blob_Bool_Exp = {
  _and?: InputMaybe<Array<Hireflix_Transcript_Blob_Bool_Exp>>;
  _not?: InputMaybe<Hireflix_Transcript_Blob_Bool_Exp>;
  _or?: InputMaybe<Array<Hireflix_Transcript_Blob_Bool_Exp>>;
  blob?: InputMaybe<Jsonb_Comparison_Exp>;
  interview_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "hireflix_transcript_blob" */
export enum Hireflix_Transcript_Blob_Constraint {
  /** unique or primary key constraint on columns "interview_id" */
  HireflixTranscriptBlobPkey = 'hireflix_transcript_blob_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hireflix_Transcript_Blob_Delete_At_Path_Input = {
  blob?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hireflix_Transcript_Blob_Delete_Elem_Input = {
  blob?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hireflix_Transcript_Blob_Delete_Key_Input = {
  blob?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "hireflix_transcript_blob" */
export type Hireflix_Transcript_Blob_Insert_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  interview_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hireflix_Transcript_Blob_Max_Fields = {
  __typename?: 'hireflix_transcript_blob_max_fields';
  interview_id: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hireflix_Transcript_Blob_Min_Fields = {
  __typename?: 'hireflix_transcript_blob_min_fields';
  interview_id: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hireflix_transcript_blob" */
export type Hireflix_Transcript_Blob_Mutation_Response = {
  __typename?: 'hireflix_transcript_blob_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hireflix_Transcript_Blob>;
};

/** input type for inserting object relation for remote table "hireflix_transcript_blob" */
export type Hireflix_Transcript_Blob_Obj_Rel_Insert_Input = {
  data: Hireflix_Transcript_Blob_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hireflix_Transcript_Blob_On_Conflict>;
};

/** on_conflict condition type for table "hireflix_transcript_blob" */
export type Hireflix_Transcript_Blob_On_Conflict = {
  constraint: Hireflix_Transcript_Blob_Constraint;
  update_columns?: Array<Hireflix_Transcript_Blob_Update_Column>;
  where?: InputMaybe<Hireflix_Transcript_Blob_Bool_Exp>;
};

/** Ordering options when selecting data from "hireflix_transcript_blob". */
export type Hireflix_Transcript_Blob_Order_By = {
  blob?: InputMaybe<Order_By>;
  interview_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hireflix_transcript_blob */
export type Hireflix_Transcript_Blob_Pk_Columns_Input = {
  interview_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hireflix_Transcript_Blob_Prepend_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hireflix_transcript_blob" */
export enum Hireflix_Transcript_Blob_Select_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  InterviewId = 'interview_id',
}

/** input type for updating data in table "hireflix_transcript_blob" */
export type Hireflix_Transcript_Blob_Set_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  interview_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "hireflix_transcript_blob" */
export type Hireflix_Transcript_Blob_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hireflix_Transcript_Blob_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hireflix_Transcript_Blob_Stream_Cursor_Value_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  interview_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "hireflix_transcript_blob" */
export enum Hireflix_Transcript_Blob_Update_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  InterviewId = 'interview_id',
}

export type Hireflix_Transcript_Blob_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hireflix_Transcript_Blob_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hireflix_Transcript_Blob_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hireflix_Transcript_Blob_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hireflix_Transcript_Blob_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hireflix_Transcript_Blob_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hireflix_Transcript_Blob_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hireflix_Transcript_Blob_Bool_Exp;
};

/** Boolean expression to filter rows from the table "hireflix_transcript". All fields are combined with a logical 'AND'. */
export type Hireflix_Transcript_Bool_Exp = {
  _and?: InputMaybe<Array<Hireflix_Transcript_Bool_Exp>>;
  _not?: InputMaybe<Hireflix_Transcript_Bool_Exp>;
  _or?: InputMaybe<Array<Hireflix_Transcript_Bool_Exp>>;
  ai_extracted_info?: InputMaybe<Jsonb_Comparison_Exp>;
  ai_extracted_info_version?: InputMaybe<String_Comparison_Exp>;
  candidate_email?: InputMaybe<Citext_Comparison_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  hireflix_transcript_blob?: InputMaybe<Hireflix_Transcript_Blob_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interview_completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  interview_id?: InputMaybe<String_Comparison_Exp>;
  position_id?: InputMaybe<String_Comparison_Exp>;
  position_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "hireflix_transcript" */
export enum Hireflix_Transcript_Constraint {
  /** unique or primary key constraint on columns "interview_id" */
  HireflixTranscriptInterviewIdKey = 'hireflix_transcript_interview_id_key',
  /** unique or primary key constraint on columns "id" */
  HireflixTranscriptPkey = 'hireflix_transcript_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hireflix_Transcript_Delete_At_Path_Input = {
  ai_extracted_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hireflix_Transcript_Delete_Elem_Input = {
  ai_extracted_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hireflix_Transcript_Delete_Key_Input = {
  ai_extracted_info?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "hireflix_transcript" */
export type Hireflix_Transcript_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "hireflix_transcript" */
export type Hireflix_Transcript_Insert_Input = {
  ai_extracted_info?: InputMaybe<Scalars['jsonb']>;
  ai_extracted_info_version?: InputMaybe<Scalars['String']>;
  candidate_email?: InputMaybe<Scalars['citext']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  hireflix_transcript_blob?: InputMaybe<Hireflix_Transcript_Blob_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  interview_completed_at?: InputMaybe<Scalars['timestamptz']>;
  interview_id?: InputMaybe<Scalars['String']>;
  position_id?: InputMaybe<Scalars['String']>;
  position_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hireflix_Transcript_Max_Fields = {
  __typename?: 'hireflix_transcript_max_fields';
  ai_extracted_info_version: Maybe<Scalars['String']>;
  candidate_email: Maybe<Scalars['citext']>;
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  interview_completed_at: Maybe<Scalars['timestamptz']>;
  interview_id: Maybe<Scalars['String']>;
  position_id: Maybe<Scalars['String']>;
  position_name: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hireflix_transcript" */
export type Hireflix_Transcript_Max_Order_By = {
  ai_extracted_info_version?: InputMaybe<Order_By>;
  candidate_email?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_completed_at?: InputMaybe<Order_By>;
  interview_id?: InputMaybe<Order_By>;
  position_id?: InputMaybe<Order_By>;
  position_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hireflix_Transcript_Min_Fields = {
  __typename?: 'hireflix_transcript_min_fields';
  ai_extracted_info_version: Maybe<Scalars['String']>;
  candidate_email: Maybe<Scalars['citext']>;
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  interview_completed_at: Maybe<Scalars['timestamptz']>;
  interview_id: Maybe<Scalars['String']>;
  position_id: Maybe<Scalars['String']>;
  position_name: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hireflix_transcript" */
export type Hireflix_Transcript_Min_Order_By = {
  ai_extracted_info_version?: InputMaybe<Order_By>;
  candidate_email?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_completed_at?: InputMaybe<Order_By>;
  interview_id?: InputMaybe<Order_By>;
  position_id?: InputMaybe<Order_By>;
  position_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hireflix_transcript" */
export type Hireflix_Transcript_Mutation_Response = {
  __typename?: 'hireflix_transcript_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hireflix_Transcript>;
};

/** on_conflict condition type for table "hireflix_transcript" */
export type Hireflix_Transcript_On_Conflict = {
  constraint: Hireflix_Transcript_Constraint;
  update_columns?: Array<Hireflix_Transcript_Update_Column>;
  where?: InputMaybe<Hireflix_Transcript_Bool_Exp>;
};

/** Ordering options when selecting data from "hireflix_transcript". */
export type Hireflix_Transcript_Order_By = {
  ai_extracted_info?: InputMaybe<Order_By>;
  ai_extracted_info_version?: InputMaybe<Order_By>;
  candidate_email?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  hireflix_transcript_blob?: InputMaybe<Hireflix_Transcript_Blob_Order_By>;
  id?: InputMaybe<Order_By>;
  interview_completed_at?: InputMaybe<Order_By>;
  interview_id?: InputMaybe<Order_By>;
  position_id?: InputMaybe<Order_By>;
  position_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hireflix_transcript */
export type Hireflix_Transcript_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hireflix_Transcript_Prepend_Input = {
  ai_extracted_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hireflix_transcript" */
export enum Hireflix_Transcript_Select_Column {
  /** column name */
  AiExtractedInfo = 'ai_extracted_info',
  /** column name */
  AiExtractedInfoVersion = 'ai_extracted_info_version',
  /** column name */
  CandidateEmail = 'candidate_email',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewCompletedAt = 'interview_completed_at',
  /** column name */
  InterviewId = 'interview_id',
  /** column name */
  PositionId = 'position_id',
  /** column name */
  PositionName = 'position_name',
}

/** input type for updating data in table "hireflix_transcript" */
export type Hireflix_Transcript_Set_Input = {
  ai_extracted_info?: InputMaybe<Scalars['jsonb']>;
  ai_extracted_info_version?: InputMaybe<Scalars['String']>;
  candidate_email?: InputMaybe<Scalars['citext']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interview_completed_at?: InputMaybe<Scalars['timestamptz']>;
  interview_id?: InputMaybe<Scalars['String']>;
  position_id?: InputMaybe<Scalars['String']>;
  position_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Hireflix_Transcript_Stddev_Fields = {
  __typename?: 'hireflix_transcript_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hireflix_transcript" */
export type Hireflix_Transcript_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hireflix_Transcript_Stddev_Pop_Fields = {
  __typename?: 'hireflix_transcript_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hireflix_transcript" */
export type Hireflix_Transcript_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hireflix_Transcript_Stddev_Samp_Fields = {
  __typename?: 'hireflix_transcript_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hireflix_transcript" */
export type Hireflix_Transcript_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "hireflix_transcript" */
export type Hireflix_Transcript_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hireflix_Transcript_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hireflix_Transcript_Stream_Cursor_Value_Input = {
  ai_extracted_info?: InputMaybe<Scalars['jsonb']>;
  ai_extracted_info_version?: InputMaybe<Scalars['String']>;
  candidate_email?: InputMaybe<Scalars['citext']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interview_completed_at?: InputMaybe<Scalars['timestamptz']>;
  interview_id?: InputMaybe<Scalars['String']>;
  position_id?: InputMaybe<Scalars['String']>;
  position_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Hireflix_Transcript_Sum_Fields = {
  __typename?: 'hireflix_transcript_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "hireflix_transcript" */
export type Hireflix_Transcript_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "hireflix_transcript" */
export enum Hireflix_Transcript_Update_Column {
  /** column name */
  AiExtractedInfo = 'ai_extracted_info',
  /** column name */
  AiExtractedInfoVersion = 'ai_extracted_info_version',
  /** column name */
  CandidateEmail = 'candidate_email',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewCompletedAt = 'interview_completed_at',
  /** column name */
  InterviewId = 'interview_id',
  /** column name */
  PositionId = 'position_id',
  /** column name */
  PositionName = 'position_name',
}

export type Hireflix_Transcript_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hireflix_Transcript_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hireflix_Transcript_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hireflix_Transcript_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hireflix_Transcript_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hireflix_Transcript_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hireflix_Transcript_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hireflix_Transcript_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hireflix_Transcript_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hireflix_Transcript_Var_Pop_Fields = {
  __typename?: 'hireflix_transcript_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hireflix_transcript" */
export type Hireflix_Transcript_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hireflix_Transcript_Var_Samp_Fields = {
  __typename?: 'hireflix_transcript_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hireflix_transcript" */
export type Hireflix_Transcript_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hireflix_Transcript_Variance_Fields = {
  __typename?: 'hireflix_transcript_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hireflix_transcript" */
export type Hireflix_Transcript_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Represents hackerrank Test object. */
export type Hr_Test = {
  __typename?: 'hr_test';
  about: Scalars['String'];
  created_at: Scalars['timestamptz'];
  duration: Scalars['Int'];
  format: Scalars['String'];
  /** An array relationship */
  hr_test_candidates: Array<Hr_Test_Candidate>;
  /** An aggregate relationship */
  hr_test_candidates_aggregate: Hr_Test_Candidate_Aggregate;
  /** An array relationship */
  hr_test_results: Array<Hr_Test_Result>;
  /** An aggregate relationship */
  hr_test_results_aggregate: Hr_Test_Result_Aggregate;
  id: Scalars['String'];
  instructions: Scalars['String'];
  minimum_passing_percentage: Scalars['Int'];
  name: Scalars['String'];
  raw_data: Scalars['jsonb'];
  slug: Scalars['String'];
  state: Scalars['String'];
  unique_id: Scalars['String'];
};

/** Represents hackerrank Test object. */
export type Hr_TestHr_Test_CandidatesArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Candidate_Order_By>>;
  where?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
};

/** Represents hackerrank Test object. */
export type Hr_TestHr_Test_Candidates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Candidate_Order_By>>;
  where?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
};

/** Represents hackerrank Test object. */
export type Hr_TestHr_Test_ResultsArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Result_Order_By>>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

/** Represents hackerrank Test object. */
export type Hr_TestHr_Test_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Result_Order_By>>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

/** Represents hackerrank Test object. */
export type Hr_TestRaw_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hr_test" */
export type Hr_Test_Aggregate = {
  __typename?: 'hr_test_aggregate';
  aggregate: Maybe<Hr_Test_Aggregate_Fields>;
  nodes: Array<Hr_Test>;
};

/** aggregate fields of "hr_test" */
export type Hr_Test_Aggregate_Fields = {
  __typename?: 'hr_test_aggregate_fields';
  avg: Maybe<Hr_Test_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Hr_Test_Max_Fields>;
  min: Maybe<Hr_Test_Min_Fields>;
  stddev: Maybe<Hr_Test_Stddev_Fields>;
  stddev_pop: Maybe<Hr_Test_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Hr_Test_Stddev_Samp_Fields>;
  sum: Maybe<Hr_Test_Sum_Fields>;
  var_pop: Maybe<Hr_Test_Var_Pop_Fields>;
  var_samp: Maybe<Hr_Test_Var_Samp_Fields>;
  variance: Maybe<Hr_Test_Variance_Fields>;
};

/** aggregate fields of "hr_test" */
export type Hr_Test_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hr_Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hr_Test_Append_Input = {
  raw_data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Hr_Test_Avg_Fields = {
  __typename?: 'hr_test_avg_fields';
  duration: Maybe<Scalars['Float']>;
  minimum_passing_percentage: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "hr_test". All fields are combined with a logical 'AND'. */
export type Hr_Test_Bool_Exp = {
  _and?: InputMaybe<Array<Hr_Test_Bool_Exp>>;
  _not?: InputMaybe<Hr_Test_Bool_Exp>;
  _or?: InputMaybe<Array<Hr_Test_Bool_Exp>>;
  about?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  format?: InputMaybe<String_Comparison_Exp>;
  hr_test_candidates?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
  hr_test_candidates_aggregate?: InputMaybe<Hr_Test_Candidate_Aggregate_Bool_Exp>;
  hr_test_results?: InputMaybe<Hr_Test_Result_Bool_Exp>;
  hr_test_results_aggregate?: InputMaybe<Hr_Test_Result_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  instructions?: InputMaybe<String_Comparison_Exp>;
  minimum_passing_percentage?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  raw_data?: InputMaybe<Jsonb_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  unique_id?: InputMaybe<String_Comparison_Exp>;
};

/** Table that corresponds and tracks Hackerrank's TestCandidate object.  */
export type Hr_Test_Candidate = {
  __typename?: 'hr_test_candidate';
  candidate_email: Scalars['citext'];
  completed: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  hr_test: Hr_Test;
  /** An array relationship */
  hr_test_results: Array<Hr_Test_Result>;
  /** An aggregate relationship */
  hr_test_results_aggregate: Hr_Test_Result_Aggregate;
  /** Corresponds to TestCandidate Id in hackerrank. */
  id: Scalars['String'];
  invite_valid_from: Scalars['timestamptz'];
  invite_valid_to: Scalars['timestamptz'];
  test_id: Scalars['String'];
  test_link: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['Int'];
  /** An object relationship */
  user_info: User_Info;
};

/** Table that corresponds and tracks Hackerrank's TestCandidate object.  */
export type Hr_Test_CandidateHr_Test_ResultsArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Result_Order_By>>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

/** Table that corresponds and tracks Hackerrank's TestCandidate object.  */
export type Hr_Test_CandidateHr_Test_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Result_Order_By>>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

/** aggregated selection of "hr_test_candidate" */
export type Hr_Test_Candidate_Aggregate = {
  __typename?: 'hr_test_candidate_aggregate';
  aggregate: Maybe<Hr_Test_Candidate_Aggregate_Fields>;
  nodes: Array<Hr_Test_Candidate>;
};

export type Hr_Test_Candidate_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Hr_Test_Candidate_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Hr_Test_Candidate_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Hr_Test_Candidate_Aggregate_Bool_Exp_Count>;
};

export type Hr_Test_Candidate_Aggregate_Bool_Exp_Bool_And = {
  arguments: Hr_Test_Candidate_Select_Column_Hr_Test_Candidate_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Hr_Test_Candidate_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Hr_Test_Candidate_Select_Column_Hr_Test_Candidate_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Hr_Test_Candidate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hr_Test_Candidate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "hr_test_candidate" */
export type Hr_Test_Candidate_Aggregate_Fields = {
  __typename?: 'hr_test_candidate_aggregate_fields';
  avg: Maybe<Hr_Test_Candidate_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Hr_Test_Candidate_Max_Fields>;
  min: Maybe<Hr_Test_Candidate_Min_Fields>;
  stddev: Maybe<Hr_Test_Candidate_Stddev_Fields>;
  stddev_pop: Maybe<Hr_Test_Candidate_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Hr_Test_Candidate_Stddev_Samp_Fields>;
  sum: Maybe<Hr_Test_Candidate_Sum_Fields>;
  var_pop: Maybe<Hr_Test_Candidate_Var_Pop_Fields>;
  var_samp: Maybe<Hr_Test_Candidate_Var_Samp_Fields>;
  variance: Maybe<Hr_Test_Candidate_Variance_Fields>;
};

/** aggregate fields of "hr_test_candidate" */
export type Hr_Test_Candidate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hr_Test_Candidate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hr_test_candidate" */
export type Hr_Test_Candidate_Aggregate_Order_By = {
  avg?: InputMaybe<Hr_Test_Candidate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hr_Test_Candidate_Max_Order_By>;
  min?: InputMaybe<Hr_Test_Candidate_Min_Order_By>;
  stddev?: InputMaybe<Hr_Test_Candidate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hr_Test_Candidate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hr_Test_Candidate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hr_Test_Candidate_Sum_Order_By>;
  var_pop?: InputMaybe<Hr_Test_Candidate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hr_Test_Candidate_Var_Samp_Order_By>;
  variance?: InputMaybe<Hr_Test_Candidate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "hr_test_candidate" */
export type Hr_Test_Candidate_Arr_Rel_Insert_Input = {
  data: Array<Hr_Test_Candidate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hr_Test_Candidate_On_Conflict>;
};

/** aggregate avg on columns */
export type Hr_Test_Candidate_Avg_Fields = {
  __typename?: 'hr_test_candidate_avg_fields';
  user_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hr_test_candidate" */
export type Hr_Test_Candidate_Avg_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hr_test_candidate". All fields are combined with a logical 'AND'. */
export type Hr_Test_Candidate_Bool_Exp = {
  _and?: InputMaybe<Array<Hr_Test_Candidate_Bool_Exp>>;
  _not?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
  _or?: InputMaybe<Array<Hr_Test_Candidate_Bool_Exp>>;
  candidate_email?: InputMaybe<Citext_Comparison_Exp>;
  completed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hr_test?: InputMaybe<Hr_Test_Bool_Exp>;
  hr_test_results?: InputMaybe<Hr_Test_Result_Bool_Exp>;
  hr_test_results_aggregate?: InputMaybe<Hr_Test_Result_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  invite_valid_from?: InputMaybe<Timestamptz_Comparison_Exp>;
  invite_valid_to?: InputMaybe<Timestamptz_Comparison_Exp>;
  test_id?: InputMaybe<String_Comparison_Exp>;
  test_link?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "hr_test_candidate" */
export enum Hr_Test_Candidate_Constraint {
  /** unique or primary key constraint on columns "id" */
  HrTestCandidateIdKey = 'hr_test_candidate_id_key',
  /** unique or primary key constraint on columns "id" */
  HrTestCandidatePkey = 'hr_test_candidate_pkey',
}

/** input type for incrementing numeric columns in table "hr_test_candidate" */
export type Hr_Test_Candidate_Inc_Input = {
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "hr_test_candidate" */
export type Hr_Test_Candidate_Insert_Input = {
  candidate_email?: InputMaybe<Scalars['citext']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hr_test?: InputMaybe<Hr_Test_Obj_Rel_Insert_Input>;
  hr_test_results?: InputMaybe<Hr_Test_Result_Arr_Rel_Insert_Input>;
  /** Corresponds to TestCandidate Id in hackerrank. */
  id?: InputMaybe<Scalars['String']>;
  invite_valid_from?: InputMaybe<Scalars['timestamptz']>;
  invite_valid_to?: InputMaybe<Scalars['timestamptz']>;
  test_id?: InputMaybe<Scalars['String']>;
  test_link?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Hr_Test_Candidate_Max_Fields = {
  __typename?: 'hr_test_candidate_max_fields';
  candidate_email: Maybe<Scalars['citext']>;
  created_at: Maybe<Scalars['timestamptz']>;
  /** Corresponds to TestCandidate Id in hackerrank. */
  id: Maybe<Scalars['String']>;
  invite_valid_from: Maybe<Scalars['timestamptz']>;
  invite_valid_to: Maybe<Scalars['timestamptz']>;
  test_id: Maybe<Scalars['String']>;
  test_link: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "hr_test_candidate" */
export type Hr_Test_Candidate_Max_Order_By = {
  candidate_email?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** Corresponds to TestCandidate Id in hackerrank. */
  id?: InputMaybe<Order_By>;
  invite_valid_from?: InputMaybe<Order_By>;
  invite_valid_to?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  test_link?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hr_Test_Candidate_Min_Fields = {
  __typename?: 'hr_test_candidate_min_fields';
  candidate_email: Maybe<Scalars['citext']>;
  created_at: Maybe<Scalars['timestamptz']>;
  /** Corresponds to TestCandidate Id in hackerrank. */
  id: Maybe<Scalars['String']>;
  invite_valid_from: Maybe<Scalars['timestamptz']>;
  invite_valid_to: Maybe<Scalars['timestamptz']>;
  test_id: Maybe<Scalars['String']>;
  test_link: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "hr_test_candidate" */
export type Hr_Test_Candidate_Min_Order_By = {
  candidate_email?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** Corresponds to TestCandidate Id in hackerrank. */
  id?: InputMaybe<Order_By>;
  invite_valid_from?: InputMaybe<Order_By>;
  invite_valid_to?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  test_link?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hr_test_candidate" */
export type Hr_Test_Candidate_Mutation_Response = {
  __typename?: 'hr_test_candidate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Test_Candidate>;
};

/** input type for inserting object relation for remote table "hr_test_candidate" */
export type Hr_Test_Candidate_Obj_Rel_Insert_Input = {
  data: Hr_Test_Candidate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hr_Test_Candidate_On_Conflict>;
};

/** on_conflict condition type for table "hr_test_candidate" */
export type Hr_Test_Candidate_On_Conflict = {
  constraint: Hr_Test_Candidate_Constraint;
  update_columns?: Array<Hr_Test_Candidate_Update_Column>;
  where?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
};

/** Ordering options when selecting data from "hr_test_candidate". */
export type Hr_Test_Candidate_Order_By = {
  candidate_email?: InputMaybe<Order_By>;
  completed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  hr_test?: InputMaybe<Hr_Test_Order_By>;
  hr_test_results_aggregate?: InputMaybe<Hr_Test_Result_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  invite_valid_from?: InputMaybe<Order_By>;
  invite_valid_to?: InputMaybe<Order_By>;
  test_id?: InputMaybe<Order_By>;
  test_link?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: hr_test_candidate */
export type Hr_Test_Candidate_Pk_Columns_Input = {
  /** Corresponds to TestCandidate Id in hackerrank. */
  id: Scalars['String'];
};

/** select columns of table "hr_test_candidate" */
export enum Hr_Test_Candidate_Select_Column {
  /** column name */
  CandidateEmail = 'candidate_email',
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteValidFrom = 'invite_valid_from',
  /** column name */
  InviteValidTo = 'invite_valid_to',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TestLink = 'test_link',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "hr_test_candidate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "hr_test_candidate" */
export enum Hr_Test_Candidate_Select_Column_Hr_Test_Candidate_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Completed = 'completed',
}

/** select "hr_test_candidate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "hr_test_candidate" */
export enum Hr_Test_Candidate_Select_Column_Hr_Test_Candidate_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Completed = 'completed',
}

/** input type for updating data in table "hr_test_candidate" */
export type Hr_Test_Candidate_Set_Input = {
  candidate_email?: InputMaybe<Scalars['citext']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Corresponds to TestCandidate Id in hackerrank. */
  id?: InputMaybe<Scalars['String']>;
  invite_valid_from?: InputMaybe<Scalars['timestamptz']>;
  invite_valid_to?: InputMaybe<Scalars['timestamptz']>;
  test_id?: InputMaybe<Scalars['String']>;
  test_link?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Hr_Test_Candidate_Stddev_Fields = {
  __typename?: 'hr_test_candidate_stddev_fields';
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hr_test_candidate" */
export type Hr_Test_Candidate_Stddev_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hr_Test_Candidate_Stddev_Pop_Fields = {
  __typename?: 'hr_test_candidate_stddev_pop_fields';
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hr_test_candidate" */
export type Hr_Test_Candidate_Stddev_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hr_Test_Candidate_Stddev_Samp_Fields = {
  __typename?: 'hr_test_candidate_stddev_samp_fields';
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hr_test_candidate" */
export type Hr_Test_Candidate_Stddev_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "hr_test_candidate" */
export type Hr_Test_Candidate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hr_Test_Candidate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hr_Test_Candidate_Stream_Cursor_Value_Input = {
  candidate_email?: InputMaybe<Scalars['citext']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Corresponds to TestCandidate Id in hackerrank. */
  id?: InputMaybe<Scalars['String']>;
  invite_valid_from?: InputMaybe<Scalars['timestamptz']>;
  invite_valid_to?: InputMaybe<Scalars['timestamptz']>;
  test_id?: InputMaybe<Scalars['String']>;
  test_link?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Hr_Test_Candidate_Sum_Fields = {
  __typename?: 'hr_test_candidate_sum_fields';
  user_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "hr_test_candidate" */
export type Hr_Test_Candidate_Sum_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "hr_test_candidate" */
export enum Hr_Test_Candidate_Update_Column {
  /** column name */
  CandidateEmail = 'candidate_email',
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteValidFrom = 'invite_valid_from',
  /** column name */
  InviteValidTo = 'invite_valid_to',
  /** column name */
  TestId = 'test_id',
  /** column name */
  TestLink = 'test_link',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Hr_Test_Candidate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hr_Test_Candidate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hr_Test_Candidate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hr_Test_Candidate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hr_Test_Candidate_Var_Pop_Fields = {
  __typename?: 'hr_test_candidate_var_pop_fields';
  user_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hr_test_candidate" */
export type Hr_Test_Candidate_Var_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hr_Test_Candidate_Var_Samp_Fields = {
  __typename?: 'hr_test_candidate_var_samp_fields';
  user_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hr_test_candidate" */
export type Hr_Test_Candidate_Var_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hr_Test_Candidate_Variance_Fields = {
  __typename?: 'hr_test_candidate_variance_fields';
  user_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hr_test_candidate" */
export type Hr_Test_Candidate_Variance_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "hr_test" */
export enum Hr_Test_Constraint {
  /** unique or primary key constraint on columns "id" */
  HrTestPkey = 'hr_test_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hr_Test_Delete_At_Path_Input = {
  raw_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hr_Test_Delete_Elem_Input = {
  raw_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hr_Test_Delete_Key_Input = {
  raw_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "hr_test" */
export type Hr_Test_Inc_Input = {
  duration?: InputMaybe<Scalars['Int']>;
  minimum_passing_percentage?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "hr_test" */
export type Hr_Test_Insert_Input = {
  about?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  hr_test_candidates?: InputMaybe<Hr_Test_Candidate_Arr_Rel_Insert_Input>;
  hr_test_results?: InputMaybe<Hr_Test_Result_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  minimum_passing_percentage?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  raw_data?: InputMaybe<Scalars['jsonb']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  unique_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Test_Max_Fields = {
  __typename?: 'hr_test_max_fields';
  about: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  duration: Maybe<Scalars['Int']>;
  format: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  instructions: Maybe<Scalars['String']>;
  minimum_passing_percentage: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  unique_id: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hr_Test_Min_Fields = {
  __typename?: 'hr_test_min_fields';
  about: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  duration: Maybe<Scalars['Int']>;
  format: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  instructions: Maybe<Scalars['String']>;
  minimum_passing_percentage: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  unique_id: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hr_test" */
export type Hr_Test_Mutation_Response = {
  __typename?: 'hr_test_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Test>;
};

/** input type for inserting object relation for remote table "hr_test" */
export type Hr_Test_Obj_Rel_Insert_Input = {
  data: Hr_Test_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hr_Test_On_Conflict>;
};

/** on_conflict condition type for table "hr_test" */
export type Hr_Test_On_Conflict = {
  constraint: Hr_Test_Constraint;
  update_columns?: Array<Hr_Test_Update_Column>;
  where?: InputMaybe<Hr_Test_Bool_Exp>;
};

/** Ordering options when selecting data from "hr_test". */
export type Hr_Test_Order_By = {
  about?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  format?: InputMaybe<Order_By>;
  hr_test_candidates_aggregate?: InputMaybe<Hr_Test_Candidate_Aggregate_Order_By>;
  hr_test_results_aggregate?: InputMaybe<Hr_Test_Result_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  minimum_passing_percentage?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  raw_data?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  unique_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hr_test */
export type Hr_Test_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hr_Test_Prepend_Input = {
  raw_data?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "hr_test_result" */
export type Hr_Test_Result = {
  __typename?: 'hr_test_result';
  attempt_endtime: Scalars['timestamptz'];
  attempt_id: Scalars['String'];
  attempt_starttime: Scalars['timestamptz'];
  /** An object relationship */
  candidate: Maybe<Candidate>;
  created_at: Scalars['timestamptz'];
  email: Scalars['citext'];
  full_name: Scalars['String'];
  /** An object relationship */
  hr_test: Hr_Test;
  /** An object relationship */
  hr_test_candidate: Hr_Test_Candidate;
  hr_test_candidate_id: Scalars['String'];
  hr_test_id: Scalars['String'];
  invite_valid: Scalars['Boolean'];
  max_score: Scalars['numeric'];
  percentage_score: Scalars['numeric'];
  plagiarism_status: Scalars['Boolean'];
  raw_data: Scalars['jsonb'];
  score: Scalars['numeric'];
  sync_status: Scalars['String'];
  test_user_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_info: User_Info;
  user_info_id: Scalars['Int'];
};

/** columns and relationships of "hr_test_result" */
export type Hr_Test_ResultRaw_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hr_test_result" */
export type Hr_Test_Result_Aggregate = {
  __typename?: 'hr_test_result_aggregate';
  aggregate: Maybe<Hr_Test_Result_Aggregate_Fields>;
  nodes: Array<Hr_Test_Result>;
};

export type Hr_Test_Result_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Hr_Test_Result_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Hr_Test_Result_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Hr_Test_Result_Aggregate_Bool_Exp_Count>;
};

export type Hr_Test_Result_Aggregate_Bool_Exp_Bool_And = {
  arguments: Hr_Test_Result_Select_Column_Hr_Test_Result_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hr_Test_Result_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Hr_Test_Result_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Hr_Test_Result_Select_Column_Hr_Test_Result_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hr_Test_Result_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Hr_Test_Result_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hr_Test_Result_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "hr_test_result" */
export type Hr_Test_Result_Aggregate_Fields = {
  __typename?: 'hr_test_result_aggregate_fields';
  avg: Maybe<Hr_Test_Result_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Hr_Test_Result_Max_Fields>;
  min: Maybe<Hr_Test_Result_Min_Fields>;
  stddev: Maybe<Hr_Test_Result_Stddev_Fields>;
  stddev_pop: Maybe<Hr_Test_Result_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Hr_Test_Result_Stddev_Samp_Fields>;
  sum: Maybe<Hr_Test_Result_Sum_Fields>;
  var_pop: Maybe<Hr_Test_Result_Var_Pop_Fields>;
  var_samp: Maybe<Hr_Test_Result_Var_Samp_Fields>;
  variance: Maybe<Hr_Test_Result_Variance_Fields>;
};

/** aggregate fields of "hr_test_result" */
export type Hr_Test_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hr_test_result" */
export type Hr_Test_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Hr_Test_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hr_Test_Result_Max_Order_By>;
  min?: InputMaybe<Hr_Test_Result_Min_Order_By>;
  stddev?: InputMaybe<Hr_Test_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hr_Test_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hr_Test_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hr_Test_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Hr_Test_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hr_Test_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Hr_Test_Result_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hr_Test_Result_Append_Input = {
  raw_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hr_test_result" */
export type Hr_Test_Result_Arr_Rel_Insert_Input = {
  data: Array<Hr_Test_Result_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hr_Test_Result_On_Conflict>;
};

/** aggregate avg on columns */
export type Hr_Test_Result_Avg_Fields = {
  __typename?: 'hr_test_result_avg_fields';
  max_score: Maybe<Scalars['Float']>;
  percentage_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hr_test_result" */
export type Hr_Test_Result_Avg_Order_By = {
  max_score?: InputMaybe<Order_By>;
  percentage_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hr_test_result". All fields are combined with a logical 'AND'. */
export type Hr_Test_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Hr_Test_Result_Bool_Exp>>;
  _not?: InputMaybe<Hr_Test_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Hr_Test_Result_Bool_Exp>>;
  attempt_endtime?: InputMaybe<Timestamptz_Comparison_Exp>;
  attempt_id?: InputMaybe<String_Comparison_Exp>;
  attempt_starttime?: InputMaybe<Timestamptz_Comparison_Exp>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  hr_test?: InputMaybe<Hr_Test_Bool_Exp>;
  hr_test_candidate?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
  hr_test_candidate_id?: InputMaybe<String_Comparison_Exp>;
  hr_test_id?: InputMaybe<String_Comparison_Exp>;
  invite_valid?: InputMaybe<Boolean_Comparison_Exp>;
  max_score?: InputMaybe<Numeric_Comparison_Exp>;
  percentage_score?: InputMaybe<Numeric_Comparison_Exp>;
  plagiarism_status?: InputMaybe<Boolean_Comparison_Exp>;
  raw_data?: InputMaybe<Jsonb_Comparison_Exp>;
  score?: InputMaybe<Numeric_Comparison_Exp>;
  sync_status?: InputMaybe<String_Comparison_Exp>;
  test_user_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
  user_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "hr_test_result" */
export enum Hr_Test_Result_Constraint {
  /** unique or primary key constraint on columns "hr_test_candidate_id", "attempt_id" */
  HrTestResultHrTestCandidateIdAttemptIdKey = 'hr_test_result_hr_test_candidate_id_attempt_id_key',
  /** unique or primary key constraint on columns "hr_test_candidate_id", "attempt_id" */
  HrTestResultPkey = 'hr_test_result_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hr_Test_Result_Delete_At_Path_Input = {
  raw_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hr_Test_Result_Delete_Elem_Input = {
  raw_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hr_Test_Result_Delete_Key_Input = {
  raw_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "hr_test_result" */
export type Hr_Test_Result_Inc_Input = {
  max_score?: InputMaybe<Scalars['numeric']>;
  percentage_score?: InputMaybe<Scalars['numeric']>;
  score?: InputMaybe<Scalars['numeric']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "hr_test_result" */
export type Hr_Test_Result_Insert_Input = {
  attempt_endtime?: InputMaybe<Scalars['timestamptz']>;
  attempt_id?: InputMaybe<Scalars['String']>;
  attempt_starttime?: InputMaybe<Scalars['timestamptz']>;
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  full_name?: InputMaybe<Scalars['String']>;
  hr_test?: InputMaybe<Hr_Test_Obj_Rel_Insert_Input>;
  hr_test_candidate?: InputMaybe<Hr_Test_Candidate_Obj_Rel_Insert_Input>;
  hr_test_candidate_id?: InputMaybe<Scalars['String']>;
  hr_test_id?: InputMaybe<Scalars['String']>;
  invite_valid?: InputMaybe<Scalars['Boolean']>;
  max_score?: InputMaybe<Scalars['numeric']>;
  percentage_score?: InputMaybe<Scalars['numeric']>;
  plagiarism_status?: InputMaybe<Scalars['Boolean']>;
  raw_data?: InputMaybe<Scalars['jsonb']>;
  score?: InputMaybe<Scalars['numeric']>;
  sync_status?: InputMaybe<Scalars['String']>;
  test_user_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Hr_Test_Result_Max_Fields = {
  __typename?: 'hr_test_result_max_fields';
  attempt_endtime: Maybe<Scalars['timestamptz']>;
  attempt_id: Maybe<Scalars['String']>;
  attempt_starttime: Maybe<Scalars['timestamptz']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  full_name: Maybe<Scalars['String']>;
  hr_test_candidate_id: Maybe<Scalars['String']>;
  hr_test_id: Maybe<Scalars['String']>;
  max_score: Maybe<Scalars['numeric']>;
  percentage_score: Maybe<Scalars['numeric']>;
  score: Maybe<Scalars['numeric']>;
  sync_status: Maybe<Scalars['String']>;
  test_user_id: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "hr_test_result" */
export type Hr_Test_Result_Max_Order_By = {
  attempt_endtime?: InputMaybe<Order_By>;
  attempt_id?: InputMaybe<Order_By>;
  attempt_starttime?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  hr_test_candidate_id?: InputMaybe<Order_By>;
  hr_test_id?: InputMaybe<Order_By>;
  max_score?: InputMaybe<Order_By>;
  percentage_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  test_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hr_Test_Result_Min_Fields = {
  __typename?: 'hr_test_result_min_fields';
  attempt_endtime: Maybe<Scalars['timestamptz']>;
  attempt_id: Maybe<Scalars['String']>;
  attempt_starttime: Maybe<Scalars['timestamptz']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  full_name: Maybe<Scalars['String']>;
  hr_test_candidate_id: Maybe<Scalars['String']>;
  hr_test_id: Maybe<Scalars['String']>;
  max_score: Maybe<Scalars['numeric']>;
  percentage_score: Maybe<Scalars['numeric']>;
  score: Maybe<Scalars['numeric']>;
  sync_status: Maybe<Scalars['String']>;
  test_user_id: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "hr_test_result" */
export type Hr_Test_Result_Min_Order_By = {
  attempt_endtime?: InputMaybe<Order_By>;
  attempt_id?: InputMaybe<Order_By>;
  attempt_starttime?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  hr_test_candidate_id?: InputMaybe<Order_By>;
  hr_test_id?: InputMaybe<Order_By>;
  max_score?: InputMaybe<Order_By>;
  percentage_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  test_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hr_test_result" */
export type Hr_Test_Result_Mutation_Response = {
  __typename?: 'hr_test_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Test_Result>;
};

/** on_conflict condition type for table "hr_test_result" */
export type Hr_Test_Result_On_Conflict = {
  constraint: Hr_Test_Result_Constraint;
  update_columns?: Array<Hr_Test_Result_Update_Column>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "hr_test_result". */
export type Hr_Test_Result_Order_By = {
  attempt_endtime?: InputMaybe<Order_By>;
  attempt_id?: InputMaybe<Order_By>;
  attempt_starttime?: InputMaybe<Order_By>;
  candidate?: InputMaybe<Candidate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  hr_test?: InputMaybe<Hr_Test_Order_By>;
  hr_test_candidate?: InputMaybe<Hr_Test_Candidate_Order_By>;
  hr_test_candidate_id?: InputMaybe<Order_By>;
  hr_test_id?: InputMaybe<Order_By>;
  invite_valid?: InputMaybe<Order_By>;
  max_score?: InputMaybe<Order_By>;
  percentage_score?: InputMaybe<Order_By>;
  plagiarism_status?: InputMaybe<Order_By>;
  raw_data?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  test_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hr_test_result */
export type Hr_Test_Result_Pk_Columns_Input = {
  attempt_id: Scalars['String'];
  hr_test_candidate_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hr_Test_Result_Prepend_Input = {
  raw_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hr_test_result" */
export enum Hr_Test_Result_Select_Column {
  /** column name */
  AttemptEndtime = 'attempt_endtime',
  /** column name */
  AttemptId = 'attempt_id',
  /** column name */
  AttemptStarttime = 'attempt_starttime',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  HrTestCandidateId = 'hr_test_candidate_id',
  /** column name */
  HrTestId = 'hr_test_id',
  /** column name */
  InviteValid = 'invite_valid',
  /** column name */
  MaxScore = 'max_score',
  /** column name */
  PercentageScore = 'percentage_score',
  /** column name */
  PlagiarismStatus = 'plagiarism_status',
  /** column name */
  RawData = 'raw_data',
  /** column name */
  Score = 'score',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  TestUserId = 'test_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id',
}

/** select "hr_test_result_aggregate_bool_exp_bool_and_arguments_columns" columns of table "hr_test_result" */
export enum Hr_Test_Result_Select_Column_Hr_Test_Result_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  InviteValid = 'invite_valid',
  /** column name */
  PlagiarismStatus = 'plagiarism_status',
}

/** select "hr_test_result_aggregate_bool_exp_bool_or_arguments_columns" columns of table "hr_test_result" */
export enum Hr_Test_Result_Select_Column_Hr_Test_Result_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  InviteValid = 'invite_valid',
  /** column name */
  PlagiarismStatus = 'plagiarism_status',
}

/** input type for updating data in table "hr_test_result" */
export type Hr_Test_Result_Set_Input = {
  attempt_endtime?: InputMaybe<Scalars['timestamptz']>;
  attempt_id?: InputMaybe<Scalars['String']>;
  attempt_starttime?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  full_name?: InputMaybe<Scalars['String']>;
  hr_test_candidate_id?: InputMaybe<Scalars['String']>;
  hr_test_id?: InputMaybe<Scalars['String']>;
  invite_valid?: InputMaybe<Scalars['Boolean']>;
  max_score?: InputMaybe<Scalars['numeric']>;
  percentage_score?: InputMaybe<Scalars['numeric']>;
  plagiarism_status?: InputMaybe<Scalars['Boolean']>;
  raw_data?: InputMaybe<Scalars['jsonb']>;
  score?: InputMaybe<Scalars['numeric']>;
  sync_status?: InputMaybe<Scalars['String']>;
  test_user_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Hr_Test_Result_Stddev_Fields = {
  __typename?: 'hr_test_result_stddev_fields';
  max_score: Maybe<Scalars['Float']>;
  percentage_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hr_test_result" */
export type Hr_Test_Result_Stddev_Order_By = {
  max_score?: InputMaybe<Order_By>;
  percentage_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hr_Test_Result_Stddev_Pop_Fields = {
  __typename?: 'hr_test_result_stddev_pop_fields';
  max_score: Maybe<Scalars['Float']>;
  percentage_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hr_test_result" */
export type Hr_Test_Result_Stddev_Pop_Order_By = {
  max_score?: InputMaybe<Order_By>;
  percentage_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hr_Test_Result_Stddev_Samp_Fields = {
  __typename?: 'hr_test_result_stddev_samp_fields';
  max_score: Maybe<Scalars['Float']>;
  percentage_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hr_test_result" */
export type Hr_Test_Result_Stddev_Samp_Order_By = {
  max_score?: InputMaybe<Order_By>;
  percentage_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "hr_test_result" */
export type Hr_Test_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hr_Test_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hr_Test_Result_Stream_Cursor_Value_Input = {
  attempt_endtime?: InputMaybe<Scalars['timestamptz']>;
  attempt_id?: InputMaybe<Scalars['String']>;
  attempt_starttime?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  full_name?: InputMaybe<Scalars['String']>;
  hr_test_candidate_id?: InputMaybe<Scalars['String']>;
  hr_test_id?: InputMaybe<Scalars['String']>;
  invite_valid?: InputMaybe<Scalars['Boolean']>;
  max_score?: InputMaybe<Scalars['numeric']>;
  percentage_score?: InputMaybe<Scalars['numeric']>;
  plagiarism_status?: InputMaybe<Scalars['Boolean']>;
  raw_data?: InputMaybe<Scalars['jsonb']>;
  score?: InputMaybe<Scalars['numeric']>;
  sync_status?: InputMaybe<Scalars['String']>;
  test_user_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Hr_Test_Result_Sum_Fields = {
  __typename?: 'hr_test_result_sum_fields';
  max_score: Maybe<Scalars['numeric']>;
  percentage_score: Maybe<Scalars['numeric']>;
  score: Maybe<Scalars['numeric']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "hr_test_result" */
export type Hr_Test_Result_Sum_Order_By = {
  max_score?: InputMaybe<Order_By>;
  percentage_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** update columns of table "hr_test_result" */
export enum Hr_Test_Result_Update_Column {
  /** column name */
  AttemptEndtime = 'attempt_endtime',
  /** column name */
  AttemptId = 'attempt_id',
  /** column name */
  AttemptStarttime = 'attempt_starttime',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  HrTestCandidateId = 'hr_test_candidate_id',
  /** column name */
  HrTestId = 'hr_test_id',
  /** column name */
  InviteValid = 'invite_valid',
  /** column name */
  MaxScore = 'max_score',
  /** column name */
  PercentageScore = 'percentage_score',
  /** column name */
  PlagiarismStatus = 'plagiarism_status',
  /** column name */
  RawData = 'raw_data',
  /** column name */
  Score = 'score',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  TestUserId = 'test_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id',
}

export type Hr_Test_Result_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hr_Test_Result_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hr_Test_Result_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hr_Test_Result_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hr_Test_Result_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hr_Test_Result_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hr_Test_Result_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hr_Test_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hr_Test_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hr_Test_Result_Var_Pop_Fields = {
  __typename?: 'hr_test_result_var_pop_fields';
  max_score: Maybe<Scalars['Float']>;
  percentage_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hr_test_result" */
export type Hr_Test_Result_Var_Pop_Order_By = {
  max_score?: InputMaybe<Order_By>;
  percentage_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hr_Test_Result_Var_Samp_Fields = {
  __typename?: 'hr_test_result_var_samp_fields';
  max_score: Maybe<Scalars['Float']>;
  percentage_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hr_test_result" */
export type Hr_Test_Result_Var_Samp_Order_By = {
  max_score?: InputMaybe<Order_By>;
  percentage_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hr_Test_Result_Variance_Fields = {
  __typename?: 'hr_test_result_variance_fields';
  max_score: Maybe<Scalars['Float']>;
  percentage_score: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hr_test_result" */
export type Hr_Test_Result_Variance_Order_By = {
  max_score?: InputMaybe<Order_By>;
  percentage_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** select columns of table "hr_test" */
export enum Hr_Test_Select_Column {
  /** column name */
  About = 'about',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  MinimumPassingPercentage = 'minimum_passing_percentage',
  /** column name */
  Name = 'name',
  /** column name */
  RawData = 'raw_data',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state',
  /** column name */
  UniqueId = 'unique_id',
}

/** input type for updating data in table "hr_test" */
export type Hr_Test_Set_Input = {
  about?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  minimum_passing_percentage?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  raw_data?: InputMaybe<Scalars['jsonb']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  unique_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Hr_Test_Stddev_Fields = {
  __typename?: 'hr_test_stddev_fields';
  duration: Maybe<Scalars['Float']>;
  minimum_passing_percentage: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Hr_Test_Stddev_Pop_Fields = {
  __typename?: 'hr_test_stddev_pop_fields';
  duration: Maybe<Scalars['Float']>;
  minimum_passing_percentage: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Hr_Test_Stddev_Samp_Fields = {
  __typename?: 'hr_test_stddev_samp_fields';
  duration: Maybe<Scalars['Float']>;
  minimum_passing_percentage: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "hr_test" */
export type Hr_Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hr_Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hr_Test_Stream_Cursor_Value_Input = {
  about?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['Int']>;
  format?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  minimum_passing_percentage?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  raw_data?: InputMaybe<Scalars['jsonb']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  unique_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Hr_Test_Sum_Fields = {
  __typename?: 'hr_test_sum_fields';
  duration: Maybe<Scalars['Int']>;
  minimum_passing_percentage: Maybe<Scalars['Int']>;
};

/** update columns of table "hr_test" */
export enum Hr_Test_Update_Column {
  /** column name */
  About = 'about',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  MinimumPassingPercentage = 'minimum_passing_percentage',
  /** column name */
  Name = 'name',
  /** column name */
  RawData = 'raw_data',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state',
  /** column name */
  UniqueId = 'unique_id',
}

export type Hr_Test_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hr_Test_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hr_Test_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hr_Test_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hr_Test_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hr_Test_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hr_Test_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hr_Test_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hr_Test_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hr_Test_Var_Pop_Fields = {
  __typename?: 'hr_test_var_pop_fields';
  duration: Maybe<Scalars['Float']>;
  minimum_passing_percentage: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Hr_Test_Var_Samp_Fields = {
  __typename?: 'hr_test_var_samp_fields';
  duration: Maybe<Scalars['Float']>;
  minimum_passing_percentage: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Hr_Test_Variance_Fields = {
  __typename?: 'hr_test_variance_fields';
  duration: Maybe<Scalars['Float']>;
  minimum_passing_percentage: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_applicant_workflow" */
export type Icims_Applicant_Workflow = {
  __typename?: 'icims_applicant_workflow';
  /** An array relationship */
  icims_applicant_workflow_feedbacks: Array<Icims_Applicant_Workflow_Feedback>;
  /** An aggregate relationship */
  icims_applicant_workflow_feedbacks_aggregate: Icims_Applicant_Workflow_Feedback_Aggregate;
  /** An array relationship */
  icims_applicant_workflow_statuses: Array<Icims_Applicant_Workflow_Status>;
  /** An aggregate relationship */
  icims_applicant_workflow_statuses_aggregate: Icims_Applicant_Workflow_Status_Aggregate;
  /** An object relationship */
  icims_initial_data_transfer: Icims_Initial_Data_Transfer;
  /** An object relationship */
  icims_job: Maybe<Icims_Job>;
  /** An object relationship */
  icims_person: Maybe<Icims_Person>;
  /** An object relationship */
  icims_profile_applicant_workflow: Icims_Profile_Applicant_Workflow;
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Maybe<Scalars['Int']>;
  luma_link: Maybe<Scalars['String']>;
  person_profile_id: Maybe<Scalars['Int']>;
  profile_id: Scalars['Int'];
  submission_notes: Maybe<Scalars['String']>;
  total_compensation: Maybe<Scalars['Int']>;
};

/** columns and relationships of "icims_applicant_workflow" */
export type Icims_Applicant_WorkflowIcims_Applicant_Workflow_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
};

/** columns and relationships of "icims_applicant_workflow" */
export type Icims_Applicant_WorkflowIcims_Applicant_Workflow_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
};

/** columns and relationships of "icims_applicant_workflow" */
export type Icims_Applicant_WorkflowIcims_Applicant_Workflow_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

/** columns and relationships of "icims_applicant_workflow" */
export type Icims_Applicant_WorkflowIcims_Applicant_Workflow_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

/** aggregated selection of "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Aggregate = {
  __typename?: 'icims_applicant_workflow_aggregate';
  aggregate: Maybe<Icims_Applicant_Workflow_Aggregate_Fields>;
  nodes: Array<Icims_Applicant_Workflow>;
};

export type Icims_Applicant_Workflow_Aggregate_Bool_Exp = {
  count?: InputMaybe<Icims_Applicant_Workflow_Aggregate_Bool_Exp_Count>;
};

export type Icims_Applicant_Workflow_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Aggregate_Fields = {
  __typename?: 'icims_applicant_workflow_aggregate_fields';
  avg: Maybe<Icims_Applicant_Workflow_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Applicant_Workflow_Max_Fields>;
  min: Maybe<Icims_Applicant_Workflow_Min_Fields>;
  stddev: Maybe<Icims_Applicant_Workflow_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Applicant_Workflow_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Applicant_Workflow_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Applicant_Workflow_Sum_Fields>;
  var_pop: Maybe<Icims_Applicant_Workflow_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Applicant_Workflow_Var_Samp_Fields>;
  variance: Maybe<Icims_Applicant_Workflow_Variance_Fields>;
};

/** aggregate fields of "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Applicant_Workflow_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Applicant_Workflow_Max_Order_By>;
  min?: InputMaybe<Icims_Applicant_Workflow_Min_Order_By>;
  stddev?: InputMaybe<Icims_Applicant_Workflow_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Applicant_Workflow_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Applicant_Workflow_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Applicant_Workflow_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Applicant_Workflow_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Applicant_Workflow_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Applicant_Workflow_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Arr_Rel_Insert_Input = {
  data: Array<Icims_Applicant_Workflow_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Applicant_Workflow_Avg_Fields = {
  __typename?: 'icims_applicant_workflow_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  total_compensation: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Avg_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  total_compensation?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_applicant_workflow". All fields are combined with a logical 'AND'. */
export type Icims_Applicant_Workflow_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Applicant_Workflow_Bool_Exp>>;
  _not?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Applicant_Workflow_Bool_Exp>>;
  icims_applicant_workflow_feedbacks?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
  icims_applicant_workflow_feedbacks_aggregate?: InputMaybe<Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp>;
  icims_applicant_workflow_statuses?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
  icims_applicant_workflow_statuses_aggregate?: InputMaybe<Icims_Applicant_Workflow_Status_Aggregate_Bool_Exp>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
  icims_job?: InputMaybe<Icims_Job_Bool_Exp>;
  icims_person?: InputMaybe<Icims_Person_Bool_Exp>;
  icims_profile_applicant_workflow?: InputMaybe<Icims_Profile_Applicant_Workflow_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  job_profile_id?: InputMaybe<Int_Comparison_Exp>;
  luma_link?: InputMaybe<String_Comparison_Exp>;
  person_profile_id?: InputMaybe<Int_Comparison_Exp>;
  profile_id?: InputMaybe<Int_Comparison_Exp>;
  submission_notes?: InputMaybe<String_Comparison_Exp>;
  total_compensation?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_applicant_workflow" */
export enum Icims_Applicant_Workflow_Constraint {
  /** unique or primary key constraint on columns "profile_id", "initial_data_transfer" */
  IcimsApplicantWorkflowPkey = 'icims_applicant_workflow_pkey',
}

/** columns and relationships of "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback = {
  __typename?: 'icims_applicant_workflow_feedback';
  /** An object relationship */
  applicant_workflow: Maybe<Icims_Applicant_Workflow>;
  applicant_workflow_profile_id: Scalars['Int'];
  comments: Maybe<Scalars['String']>;
  created_at: Scalars['date'];
  entry: Maybe<Scalars['Int']>;
  /** An object relationship */
  icims_applicant_workflow_status: Maybe<Icims_Applicant_Workflow_Status>;
  id: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  rejected_reason_compensation: Maybe<Scalars['Boolean']>;
  rejected_reason_experience: Maybe<Scalars['Boolean']>;
  rejected_reason_junior: Maybe<Scalars['Boolean']>;
  rejected_reason_other: Maybe<Scalars['Boolean']>;
  rejected_reason_skill: Maybe<Scalars['Boolean']>;
  rejected_reasons: Maybe<Scalars['jsonb']>;
  reviewer_email: Maybe<Scalars['String']>;
  reviewer_id: Maybe<Scalars['Int']>;
  reviewer_name: Maybe<Scalars['String']>;
  /** An object relationship */
  reviewer_user_info: Maybe<User_Info>;
  sync_status: Maybe<Icims_Sync_Status_Choices_Enum>;
  updated_at: Maybe<Scalars['date']>;
};

/** columns and relationships of "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_FeedbackRejected_ReasonsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Aggregate = {
  __typename?: 'icims_applicant_workflow_feedback_aggregate';
  aggregate: Maybe<Icims_Applicant_Workflow_Feedback_Aggregate_Fields>;
  nodes: Array<Icims_Applicant_Workflow_Feedback>;
};

export type Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp_Count>;
};

export type Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp_Bool_And = {
  arguments: Icims_Applicant_Workflow_Feedback_Select_Column_Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Icims_Applicant_Workflow_Feedback_Select_Column_Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Aggregate_Fields = {
  __typename?: 'icims_applicant_workflow_feedback_aggregate_fields';
  avg: Maybe<Icims_Applicant_Workflow_Feedback_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Applicant_Workflow_Feedback_Max_Fields>;
  min: Maybe<Icims_Applicant_Workflow_Feedback_Min_Fields>;
  stddev: Maybe<Icims_Applicant_Workflow_Feedback_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Applicant_Workflow_Feedback_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Applicant_Workflow_Feedback_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Applicant_Workflow_Feedback_Sum_Fields>;
  var_pop: Maybe<Icims_Applicant_Workflow_Feedback_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Applicant_Workflow_Feedback_Var_Samp_Fields>;
  variance: Maybe<Icims_Applicant_Workflow_Feedback_Variance_Fields>;
};

/** aggregate fields of "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Applicant_Workflow_Feedback_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Applicant_Workflow_Feedback_Max_Order_By>;
  min?: InputMaybe<Icims_Applicant_Workflow_Feedback_Min_Order_By>;
  stddev?: InputMaybe<Icims_Applicant_Workflow_Feedback_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Applicant_Workflow_Feedback_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Applicant_Workflow_Feedback_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Applicant_Workflow_Feedback_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Applicant_Workflow_Feedback_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Applicant_Workflow_Feedback_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Applicant_Workflow_Feedback_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Icims_Applicant_Workflow_Feedback_Append_Input = {
  rejected_reasons?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Arr_Rel_Insert_Input = {
  data: Array<Icims_Applicant_Workflow_Feedback_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_Feedback_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Applicant_Workflow_Feedback_Avg_Fields = {
  __typename?: 'icims_applicant_workflow_feedback_avg_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  entry: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  reviewer_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Avg_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_applicant_workflow_feedback". All fields are combined with a logical 'AND'. */
export type Icims_Applicant_Workflow_Feedback_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Bool_Exp>>;
  _not?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Bool_Exp>>;
  applicant_workflow?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
  applicant_workflow_profile_id?: InputMaybe<Int_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Date_Comparison_Exp>;
  entry?: InputMaybe<Int_Comparison_Exp>;
  icims_applicant_workflow_status?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  rejected_reason_compensation?: InputMaybe<Boolean_Comparison_Exp>;
  rejected_reason_experience?: InputMaybe<Boolean_Comparison_Exp>;
  rejected_reason_junior?: InputMaybe<Boolean_Comparison_Exp>;
  rejected_reason_other?: InputMaybe<Boolean_Comparison_Exp>;
  rejected_reason_skill?: InputMaybe<Boolean_Comparison_Exp>;
  rejected_reasons?: InputMaybe<Jsonb_Comparison_Exp>;
  reviewer_email?: InputMaybe<String_Comparison_Exp>;
  reviewer_id?: InputMaybe<Int_Comparison_Exp>;
  reviewer_name?: InputMaybe<String_Comparison_Exp>;
  reviewer_user_info?: InputMaybe<User_Info_Bool_Exp>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_applicant_workflow_feedback" */
export enum Icims_Applicant_Workflow_Feedback_Constraint {
  /** unique or primary key constraint on columns "id" */
  IcimsApplicantWorkflowFeedbackIdKey = 'icims_applicant_workflow_feedback_id_key',
  /** unique or primary key constraint on columns "applicant_workflow_profile_id", "initial_data_transfer", "entry" */
  IcimsApplicantWorkflowFeedbackInitialDataTransferApplica = 'icims_applicant_workflow_feedback_initial_data_transfer_applica',
  /** unique or primary key constraint on columns "applicant_workflow_profile_id", "initial_data_transfer", "id" */
  IcimsApplicantWorkflowFeedbackPkey = 'icims_applicant_workflow_feedback_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Icims_Applicant_Workflow_Feedback_Delete_At_Path_Input = {
  rejected_reasons?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Icims_Applicant_Workflow_Feedback_Delete_Elem_Input = {
  rejected_reasons?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Icims_Applicant_Workflow_Feedback_Delete_Key_Input = {
  rejected_reasons?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Inc_Input = {
  applicant_workflow_profile_id?: InputMaybe<Scalars['Int']>;
  entry?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  reviewer_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Insert_Input = {
  applicant_workflow?: InputMaybe<Icims_Applicant_Workflow_Obj_Rel_Insert_Input>;
  applicant_workflow_profile_id?: InputMaybe<Scalars['Int']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['date']>;
  entry?: InputMaybe<Scalars['Int']>;
  icims_applicant_workflow_status?: InputMaybe<Icims_Applicant_Workflow_Status_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  rejected_reason_compensation?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_experience?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_junior?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_other?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_skill?: InputMaybe<Scalars['Boolean']>;
  rejected_reasons?: InputMaybe<Scalars['jsonb']>;
  reviewer_email?: InputMaybe<Scalars['String']>;
  reviewer_id?: InputMaybe<Scalars['Int']>;
  reviewer_name?: InputMaybe<Scalars['String']>;
  reviewer_user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type Icims_Applicant_Workflow_Feedback_Max_Fields = {
  __typename?: 'icims_applicant_workflow_feedback_max_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Int']>;
  comments: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['date']>;
  entry: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  reviewer_email: Maybe<Scalars['String']>;
  reviewer_id: Maybe<Scalars['Int']>;
  reviewer_name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Max_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  reviewer_email?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  reviewer_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Applicant_Workflow_Feedback_Min_Fields = {
  __typename?: 'icims_applicant_workflow_feedback_min_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Int']>;
  comments: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['date']>;
  entry: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  reviewer_email: Maybe<Scalars['String']>;
  reviewer_id: Maybe<Scalars['Int']>;
  reviewer_name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['date']>;
};

/** order by min() on columns of table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Min_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  reviewer_email?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  reviewer_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Mutation_Response = {
  __typename?: 'icims_applicant_workflow_feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Applicant_Workflow_Feedback>;
};

/** input type for inserting object relation for remote table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Obj_Rel_Insert_Input = {
  data: Icims_Applicant_Workflow_Feedback_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_Feedback_On_Conflict>;
};

/** on_conflict condition type for table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_On_Conflict = {
  constraint: Icims_Applicant_Workflow_Feedback_Constraint;
  update_columns?: Array<Icims_Applicant_Workflow_Feedback_Update_Column>;
  where?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_applicant_workflow_feedback". */
export type Icims_Applicant_Workflow_Feedback_Order_By = {
  applicant_workflow?: InputMaybe<Icims_Applicant_Workflow_Order_By>;
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  icims_applicant_workflow_status?: InputMaybe<Icims_Applicant_Workflow_Status_Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  rejected_reason_compensation?: InputMaybe<Order_By>;
  rejected_reason_experience?: InputMaybe<Order_By>;
  rejected_reason_junior?: InputMaybe<Order_By>;
  rejected_reason_other?: InputMaybe<Order_By>;
  rejected_reason_skill?: InputMaybe<Order_By>;
  rejected_reasons?: InputMaybe<Order_By>;
  reviewer_email?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  reviewer_name?: InputMaybe<Order_By>;
  reviewer_user_info?: InputMaybe<User_Info_Order_By>;
  sync_status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_applicant_workflow_feedback */
export type Icims_Applicant_Workflow_Feedback_Pk_Columns_Input = {
  applicant_workflow_profile_id: Scalars['Int'];
  id: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Icims_Applicant_Workflow_Feedback_Prepend_Input = {
  rejected_reasons?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "icims_applicant_workflow_feedback" */
export enum Icims_Applicant_Workflow_Feedback_Select_Column {
  /** column name */
  ApplicantWorkflowProfileId = 'applicant_workflow_profile_id',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Entry = 'entry',
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  RejectedReasonCompensation = 'rejected_reason_compensation',
  /** column name */
  RejectedReasonExperience = 'rejected_reason_experience',
  /** column name */
  RejectedReasonJunior = 'rejected_reason_junior',
  /** column name */
  RejectedReasonOther = 'rejected_reason_other',
  /** column name */
  RejectedReasonSkill = 'rejected_reason_skill',
  /** column name */
  RejectedReasons = 'rejected_reasons',
  /** column name */
  ReviewerEmail = 'reviewer_email',
  /** column name */
  ReviewerId = 'reviewer_id',
  /** column name */
  ReviewerName = 'reviewer_name',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "icims_applicant_workflow_feedback_aggregate_bool_exp_bool_and_arguments_columns" columns of table "icims_applicant_workflow_feedback" */
export enum Icims_Applicant_Workflow_Feedback_Select_Column_Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  RejectedReasonCompensation = 'rejected_reason_compensation',
  /** column name */
  RejectedReasonExperience = 'rejected_reason_experience',
  /** column name */
  RejectedReasonJunior = 'rejected_reason_junior',
  /** column name */
  RejectedReasonOther = 'rejected_reason_other',
  /** column name */
  RejectedReasonSkill = 'rejected_reason_skill',
}

/** select "icims_applicant_workflow_feedback_aggregate_bool_exp_bool_or_arguments_columns" columns of table "icims_applicant_workflow_feedback" */
export enum Icims_Applicant_Workflow_Feedback_Select_Column_Icims_Applicant_Workflow_Feedback_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  RejectedReasonCompensation = 'rejected_reason_compensation',
  /** column name */
  RejectedReasonExperience = 'rejected_reason_experience',
  /** column name */
  RejectedReasonJunior = 'rejected_reason_junior',
  /** column name */
  RejectedReasonOther = 'rejected_reason_other',
  /** column name */
  RejectedReasonSkill = 'rejected_reason_skill',
}

/** input type for updating data in table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Set_Input = {
  applicant_workflow_profile_id?: InputMaybe<Scalars['Int']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['date']>;
  entry?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  rejected_reason_compensation?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_experience?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_junior?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_other?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_skill?: InputMaybe<Scalars['Boolean']>;
  rejected_reasons?: InputMaybe<Scalars['jsonb']>;
  reviewer_email?: InputMaybe<Scalars['String']>;
  reviewer_id?: InputMaybe<Scalars['Int']>;
  reviewer_name?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type Icims_Applicant_Workflow_Feedback_Stddev_Fields = {
  __typename?: 'icims_applicant_workflow_feedback_stddev_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  entry: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  reviewer_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Stddev_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Applicant_Workflow_Feedback_Stddev_Pop_Fields = {
  __typename?: 'icims_applicant_workflow_feedback_stddev_pop_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  entry: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  reviewer_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Stddev_Pop_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Applicant_Workflow_Feedback_Stddev_Samp_Fields = {
  __typename?: 'icims_applicant_workflow_feedback_stddev_samp_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  entry: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  reviewer_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Stddev_Samp_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Applicant_Workflow_Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Applicant_Workflow_Feedback_Stream_Cursor_Value_Input = {
  applicant_workflow_profile_id?: InputMaybe<Scalars['Int']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['date']>;
  entry?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  rejected_reason_compensation?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_experience?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_junior?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_other?: InputMaybe<Scalars['Boolean']>;
  rejected_reason_skill?: InputMaybe<Scalars['Boolean']>;
  rejected_reasons?: InputMaybe<Scalars['jsonb']>;
  reviewer_email?: InputMaybe<Scalars['String']>;
  reviewer_id?: InputMaybe<Scalars['Int']>;
  reviewer_name?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['date']>;
};

/** aggregate sum on columns */
export type Icims_Applicant_Workflow_Feedback_Sum_Fields = {
  __typename?: 'icims_applicant_workflow_feedback_sum_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Int']>;
  entry: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  reviewer_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Sum_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** update columns of table "icims_applicant_workflow_feedback" */
export enum Icims_Applicant_Workflow_Feedback_Update_Column {
  /** column name */
  ApplicantWorkflowProfileId = 'applicant_workflow_profile_id',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Entry = 'entry',
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  RejectedReasonCompensation = 'rejected_reason_compensation',
  /** column name */
  RejectedReasonExperience = 'rejected_reason_experience',
  /** column name */
  RejectedReasonJunior = 'rejected_reason_junior',
  /** column name */
  RejectedReasonOther = 'rejected_reason_other',
  /** column name */
  RejectedReasonSkill = 'rejected_reason_skill',
  /** column name */
  RejectedReasons = 'rejected_reasons',
  /** column name */
  ReviewerEmail = 'reviewer_email',
  /** column name */
  ReviewerId = 'reviewer_id',
  /** column name */
  ReviewerName = 'reviewer_name',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Icims_Applicant_Workflow_Feedback_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Icims_Applicant_Workflow_Feedback_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Icims_Applicant_Workflow_Feedback_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Icims_Applicant_Workflow_Feedback_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Icims_Applicant_Workflow_Feedback_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Applicant_Workflow_Feedback_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Icims_Applicant_Workflow_Feedback_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Applicant_Workflow_Feedback_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Applicant_Workflow_Feedback_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Applicant_Workflow_Feedback_Var_Pop_Fields = {
  __typename?: 'icims_applicant_workflow_feedback_var_pop_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  entry: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  reviewer_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Var_Pop_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Applicant_Workflow_Feedback_Var_Samp_Fields = {
  __typename?: 'icims_applicant_workflow_feedback_var_samp_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  entry: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  reviewer_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Var_Samp_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Applicant_Workflow_Feedback_Variance_Fields = {
  __typename?: 'icims_applicant_workflow_feedback_variance_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  entry: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  reviewer_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_applicant_workflow_feedback" */
export type Icims_Applicant_Workflow_Feedback_Variance_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  total_compensation?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Insert_Input = {
  icims_applicant_workflow_feedbacks?: InputMaybe<Icims_Applicant_Workflow_Feedback_Arr_Rel_Insert_Input>;
  icims_applicant_workflow_statuses?: InputMaybe<Icims_Applicant_Workflow_Status_Arr_Rel_Insert_Input>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Obj_Rel_Insert_Input>;
  icims_job?: InputMaybe<Icims_Job_Obj_Rel_Insert_Input>;
  icims_person?: InputMaybe<Icims_Person_Obj_Rel_Insert_Input>;
  icims_profile_applicant_workflow?: InputMaybe<Icims_Profile_Applicant_Workflow_Obj_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  luma_link?: InputMaybe<Scalars['String']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  submission_notes?: InputMaybe<Scalars['String']>;
  total_compensation?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Icims_Applicant_Workflow_Max_Fields = {
  __typename?: 'icims_applicant_workflow_max_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  luma_link: Maybe<Scalars['String']>;
  person_profile_id: Maybe<Scalars['Int']>;
  profile_id: Maybe<Scalars['Int']>;
  submission_notes: Maybe<Scalars['String']>;
  total_compensation: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Max_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  luma_link?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  submission_notes?: InputMaybe<Order_By>;
  total_compensation?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Applicant_Workflow_Min_Fields = {
  __typename?: 'icims_applicant_workflow_min_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  luma_link: Maybe<Scalars['String']>;
  person_profile_id: Maybe<Scalars['Int']>;
  profile_id: Maybe<Scalars['Int']>;
  submission_notes: Maybe<Scalars['String']>;
  total_compensation: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Min_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  luma_link?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  submission_notes?: InputMaybe<Order_By>;
  total_compensation?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Mutation_Response = {
  __typename?: 'icims_applicant_workflow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Applicant_Workflow>;
};

/** input type for inserting object relation for remote table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Obj_Rel_Insert_Input = {
  data: Icims_Applicant_Workflow_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_On_Conflict>;
};

/** on_conflict condition type for table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_On_Conflict = {
  constraint: Icims_Applicant_Workflow_Constraint;
  update_columns?: Array<Icims_Applicant_Workflow_Update_Column>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_applicant_workflow". */
export type Icims_Applicant_Workflow_Order_By = {
  icims_applicant_workflow_feedbacks_aggregate?: InputMaybe<Icims_Applicant_Workflow_Feedback_Aggregate_Order_By>;
  icims_applicant_workflow_statuses_aggregate?: InputMaybe<Icims_Applicant_Workflow_Status_Aggregate_Order_By>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Order_By>;
  icims_job?: InputMaybe<Icims_Job_Order_By>;
  icims_person?: InputMaybe<Icims_Person_Order_By>;
  icims_profile_applicant_workflow?: InputMaybe<Icims_Profile_Applicant_Workflow_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  luma_link?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  submission_notes?: InputMaybe<Order_By>;
  total_compensation?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_applicant_workflow */
export type Icims_Applicant_Workflow_Pk_Columns_Input = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

/** select columns of table "icims_applicant_workflow" */
export enum Icims_Applicant_Workflow_Select_Column {
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  JobProfileId = 'job_profile_id',
  /** column name */
  LumaLink = 'luma_link',
  /** column name */
  PersonProfileId = 'person_profile_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SubmissionNotes = 'submission_notes',
  /** column name */
  TotalCompensation = 'total_compensation',
}

/** input type for updating data in table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Set_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  luma_link?: InputMaybe<Scalars['String']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  submission_notes?: InputMaybe<Scalars['String']>;
  total_compensation?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status = {
  __typename?: 'icims_applicant_workflow_status';
  applicant_workflow_profile_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  icims_applicant_workflow: Maybe<Icims_Applicant_Workflow>;
  /** An object relationship */
  icims_applicant_workflow_feedback: Maybe<Icims_Applicant_Workflow_Feedback>;
  icims_applicant_workflow_feedback_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  icims_status: Maybe<Icims_Status>;
  initial_data_transfer: Scalars['Int'];
  status_id: Scalars['String'];
  sync_status: Icims_Sync_Status_Choices_Enum;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Aggregate = {
  __typename?: 'icims_applicant_workflow_status_aggregate';
  aggregate: Maybe<Icims_Applicant_Workflow_Status_Aggregate_Fields>;
  nodes: Array<Icims_Applicant_Workflow_Status>;
};

export type Icims_Applicant_Workflow_Status_Aggregate_Bool_Exp = {
  count?: InputMaybe<Icims_Applicant_Workflow_Status_Aggregate_Bool_Exp_Count>;
};

export type Icims_Applicant_Workflow_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Aggregate_Fields = {
  __typename?: 'icims_applicant_workflow_status_aggregate_fields';
  avg: Maybe<Icims_Applicant_Workflow_Status_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Applicant_Workflow_Status_Max_Fields>;
  min: Maybe<Icims_Applicant_Workflow_Status_Min_Fields>;
  stddev: Maybe<Icims_Applicant_Workflow_Status_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Applicant_Workflow_Status_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Applicant_Workflow_Status_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Applicant_Workflow_Status_Sum_Fields>;
  var_pop: Maybe<Icims_Applicant_Workflow_Status_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Applicant_Workflow_Status_Var_Samp_Fields>;
  variance: Maybe<Icims_Applicant_Workflow_Status_Variance_Fields>;
};

/** aggregate fields of "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Applicant_Workflow_Status_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Applicant_Workflow_Status_Max_Order_By>;
  min?: InputMaybe<Icims_Applicant_Workflow_Status_Min_Order_By>;
  stddev?: InputMaybe<Icims_Applicant_Workflow_Status_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Applicant_Workflow_Status_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Applicant_Workflow_Status_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Applicant_Workflow_Status_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Applicant_Workflow_Status_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Applicant_Workflow_Status_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Applicant_Workflow_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Arr_Rel_Insert_Input = {
  data: Array<Icims_Applicant_Workflow_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Applicant_Workflow_Status_Avg_Fields = {
  __typename?: 'icims_applicant_workflow_status_avg_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  icims_applicant_workflow_feedback_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Avg_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_applicant_workflow_status". All fields are combined with a logical 'AND'. */
export type Icims_Applicant_Workflow_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Bool_Exp>>;
  _not?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Bool_Exp>>;
  applicant_workflow_profile_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  icims_applicant_workflow?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
  icims_applicant_workflow_feedback?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Int_Comparison_Exp>;
  icims_status?: InputMaybe<Icims_Status_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  status_id?: InputMaybe<String_Comparison_Exp>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_applicant_workflow_status" */
export enum Icims_Applicant_Workflow_Status_Constraint {
  /** unique or primary key constraint on columns "applicant_workflow_profile_id", "status_id", "initial_data_transfer", "created_at" */
  IcimsApplicantWorkflowStatusInitialDataTransferApplicant = 'icims_applicant_workflow_status_initial_data_transfer_applicant',
  /** unique or primary key constraint on columns "applicant_workflow_profile_id", "status_id", "initial_data_transfer", "created_at" */
  IcimsApplicantWorkflowStatusPkey = 'icims_applicant_workflow_status_pkey',
}

/** input type for incrementing numeric columns in table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Inc_Input = {
  applicant_workflow_profile_id?: InputMaybe<Scalars['Int']>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Insert_Input = {
  applicant_workflow_profile_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  icims_applicant_workflow?: InputMaybe<Icims_Applicant_Workflow_Obj_Rel_Insert_Input>;
  icims_applicant_workflow_feedback?: InputMaybe<Icims_Applicant_Workflow_Feedback_Obj_Rel_Insert_Input>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Scalars['Int']>;
  icims_status?: InputMaybe<Icims_Status_Obj_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  status_id?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Icims_Applicant_Workflow_Status_Max_Fields = {
  __typename?: 'icims_applicant_workflow_status_max_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  icims_applicant_workflow_feedback_id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  status_id: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Max_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Applicant_Workflow_Status_Min_Fields = {
  __typename?: 'icims_applicant_workflow_status_min_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  icims_applicant_workflow_feedback_id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  status_id: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Min_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Mutation_Response = {
  __typename?: 'icims_applicant_workflow_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Applicant_Workflow_Status>;
};

/** input type for inserting object relation for remote table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Obj_Rel_Insert_Input = {
  data: Icims_Applicant_Workflow_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_Status_On_Conflict>;
};

/** on_conflict condition type for table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_On_Conflict = {
  constraint: Icims_Applicant_Workflow_Status_Constraint;
  update_columns?: Array<Icims_Applicant_Workflow_Status_Update_Column>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_applicant_workflow_status". */
export type Icims_Applicant_Workflow_Status_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  icims_applicant_workflow?: InputMaybe<Icims_Applicant_Workflow_Order_By>;
  icims_applicant_workflow_feedback?: InputMaybe<Icims_Applicant_Workflow_Feedback_Order_By>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Order_By>;
  icims_status?: InputMaybe<Icims_Status_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_applicant_workflow_status */
export type Icims_Applicant_Workflow_Status_Pk_Columns_Input = {
  applicant_workflow_profile_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  initial_data_transfer: Scalars['Int'];
  status_id: Scalars['String'];
};

/** select columns of table "icims_applicant_workflow_status" */
export enum Icims_Applicant_Workflow_Status_Select_Column {
  /** column name */
  ApplicantWorkflowProfileId = 'applicant_workflow_profile_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IcimsApplicantWorkflowFeedbackId = 'icims_applicant_workflow_feedback_id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Set_Input = {
  applicant_workflow_profile_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  status_id?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Icims_Applicant_Workflow_Status_Stddev_Fields = {
  __typename?: 'icims_applicant_workflow_status_stddev_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  icims_applicant_workflow_feedback_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Stddev_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Applicant_Workflow_Status_Stddev_Pop_Fields = {
  __typename?: 'icims_applicant_workflow_status_stddev_pop_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  icims_applicant_workflow_feedback_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Stddev_Pop_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Applicant_Workflow_Status_Stddev_Samp_Fields = {
  __typename?: 'icims_applicant_workflow_status_stddev_samp_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  icims_applicant_workflow_feedback_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Stddev_Samp_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Applicant_Workflow_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Applicant_Workflow_Status_Stream_Cursor_Value_Input = {
  applicant_workflow_profile_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  status_id?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Icims_Applicant_Workflow_Status_Sum_Fields = {
  __typename?: 'icims_applicant_workflow_status_sum_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Int']>;
  icims_applicant_workflow_feedback_id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Sum_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
};

/** update columns of table "icims_applicant_workflow_status" */
export enum Icims_Applicant_Workflow_Status_Update_Column {
  /** column name */
  ApplicantWorkflowProfileId = 'applicant_workflow_profile_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IcimsApplicantWorkflowFeedbackId = 'icims_applicant_workflow_feedback_id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Icims_Applicant_Workflow_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Applicant_Workflow_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Applicant_Workflow_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Applicant_Workflow_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Applicant_Workflow_Status_Var_Pop_Fields = {
  __typename?: 'icims_applicant_workflow_status_var_pop_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  icims_applicant_workflow_feedback_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Var_Pop_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Applicant_Workflow_Status_Var_Samp_Fields = {
  __typename?: 'icims_applicant_workflow_status_var_samp_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  icims_applicant_workflow_feedback_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Var_Samp_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Applicant_Workflow_Status_Variance_Fields = {
  __typename?: 'icims_applicant_workflow_status_variance_fields';
  applicant_workflow_profile_id: Maybe<Scalars['Float']>;
  icims_applicant_workflow_feedback_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_applicant_workflow_status" */
export type Icims_Applicant_Workflow_Status_Variance_Order_By = {
  applicant_workflow_profile_id?: InputMaybe<Order_By>;
  icims_applicant_workflow_feedback_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Icims_Applicant_Workflow_Stddev_Fields = {
  __typename?: 'icims_applicant_workflow_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  total_compensation: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Stddev_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  total_compensation?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Applicant_Workflow_Stddev_Pop_Fields = {
  __typename?: 'icims_applicant_workflow_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  total_compensation: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Stddev_Pop_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  total_compensation?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Applicant_Workflow_Stddev_Samp_Fields = {
  __typename?: 'icims_applicant_workflow_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  total_compensation: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Stddev_Samp_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  total_compensation?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Applicant_Workflow_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Applicant_Workflow_Stream_Cursor_Value_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  luma_link?: InputMaybe<Scalars['String']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  submission_notes?: InputMaybe<Scalars['String']>;
  total_compensation?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Icims_Applicant_Workflow_Sum_Fields = {
  __typename?: 'icims_applicant_workflow_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
  profile_id: Maybe<Scalars['Int']>;
  total_compensation: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Sum_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  total_compensation?: InputMaybe<Order_By>;
};

/** update columns of table "icims_applicant_workflow" */
export enum Icims_Applicant_Workflow_Update_Column {
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  JobProfileId = 'job_profile_id',
  /** column name */
  LumaLink = 'luma_link',
  /** column name */
  PersonProfileId = 'person_profile_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SubmissionNotes = 'submission_notes',
  /** column name */
  TotalCompensation = 'total_compensation',
}

export type Icims_Applicant_Workflow_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Applicant_Workflow_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Applicant_Workflow_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Applicant_Workflow_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Applicant_Workflow_Var_Pop_Fields = {
  __typename?: 'icims_applicant_workflow_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  total_compensation: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Var_Pop_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  total_compensation?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Applicant_Workflow_Var_Samp_Fields = {
  __typename?: 'icims_applicant_workflow_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  total_compensation: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Var_Samp_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  total_compensation?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Applicant_Workflow_Variance_Fields = {
  __typename?: 'icims_applicant_workflow_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  total_compensation: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_applicant_workflow" */
export type Icims_Applicant_Workflow_Variance_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  total_compensation?: InputMaybe<Order_By>;
};

/** columns and relationships of "icims_company" */
export type Icims_Company = {
  __typename?: 'icims_company';
  /** An array relationship */
  icims_jobs: Array<Icims_Job>;
  /** An aggregate relationship */
  icims_jobs_aggregate: Icims_Job_Aggregate;
  initial_data_transfer: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  numofemps: Maybe<Scalars['Int']>;
  /** An array relationship */
  organizations: Array<Organization>;
  /** An aggregate relationship */
  organizations_aggregate: Organization_Aggregate;
  profile_id: Scalars['Int'];
};

/** columns and relationships of "icims_company" */
export type Icims_CompanyIcims_JobsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

/** columns and relationships of "icims_company" */
export type Icims_CompanyIcims_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

/** columns and relationships of "icims_company" */
export type Icims_CompanyOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** columns and relationships of "icims_company" */
export type Icims_CompanyOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** aggregated selection of "icims_company" */
export type Icims_Company_Aggregate = {
  __typename?: 'icims_company_aggregate';
  aggregate: Maybe<Icims_Company_Aggregate_Fields>;
  nodes: Array<Icims_Company>;
};

/** aggregate fields of "icims_company" */
export type Icims_Company_Aggregate_Fields = {
  __typename?: 'icims_company_aggregate_fields';
  avg: Maybe<Icims_Company_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Company_Max_Fields>;
  min: Maybe<Icims_Company_Min_Fields>;
  stddev: Maybe<Icims_Company_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Company_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Company_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Company_Sum_Fields>;
  var_pop: Maybe<Icims_Company_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Company_Var_Samp_Fields>;
  variance: Maybe<Icims_Company_Variance_Fields>;
};

/** aggregate fields of "icims_company" */
export type Icims_Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Company_Avg_Fields = {
  __typename?: 'icims_company_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  numofemps: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_company". All fields are combined with a logical 'AND'. */
export type Icims_Company_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Company_Bool_Exp>>;
  _not?: InputMaybe<Icims_Company_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Company_Bool_Exp>>;
  icims_jobs?: InputMaybe<Icims_Job_Bool_Exp>;
  icims_jobs_aggregate?: InputMaybe<Icims_Job_Aggregate_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  numofemps?: InputMaybe<Int_Comparison_Exp>;
  organizations?: InputMaybe<Organization_Bool_Exp>;
  organizations_aggregate?: InputMaybe<Organization_Aggregate_Bool_Exp>;
  profile_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_company" */
export enum Icims_Company_Constraint {
  /** unique or primary key constraint on columns "profile_id", "initial_data_transfer" */
  IcimsCompanyPkey = 'icims_company_pkey',
}

/** input type for incrementing numeric columns in table "icims_company" */
export type Icims_Company_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  numofemps?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_company" */
export type Icims_Company_Insert_Input = {
  icims_jobs?: InputMaybe<Icims_Job_Arr_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  numofemps?: InputMaybe<Scalars['Int']>;
  organizations?: InputMaybe<Organization_Arr_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Icims_Company_Max_Fields = {
  __typename?: 'icims_company_max_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  numofemps: Maybe<Scalars['Int']>;
  profile_id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Icims_Company_Min_Fields = {
  __typename?: 'icims_company_min_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  numofemps: Maybe<Scalars['Int']>;
  profile_id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "icims_company" */
export type Icims_Company_Mutation_Response = {
  __typename?: 'icims_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Company>;
};

/** input type for inserting object relation for remote table "icims_company" */
export type Icims_Company_Obj_Rel_Insert_Input = {
  data: Icims_Company_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Company_On_Conflict>;
};

/** on_conflict condition type for table "icims_company" */
export type Icims_Company_On_Conflict = {
  constraint: Icims_Company_Constraint;
  update_columns?: Array<Icims_Company_Update_Column>;
  where?: InputMaybe<Icims_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_company". */
export type Icims_Company_Order_By = {
  icims_jobs_aggregate?: InputMaybe<Icims_Job_Aggregate_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  numofemps?: InputMaybe<Order_By>;
  organizations_aggregate?: InputMaybe<Organization_Aggregate_Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_company */
export type Icims_Company_Pk_Columns_Input = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

/** select columns of table "icims_company" */
export enum Icims_Company_Select_Column {
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Name = 'name',
  /** column name */
  Numofemps = 'numofemps',
  /** column name */
  ProfileId = 'profile_id',
}

/** input type for updating data in table "icims_company" */
export type Icims_Company_Set_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  numofemps?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Icims_Company_Stddev_Fields = {
  __typename?: 'icims_company_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  numofemps: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Company_Stddev_Pop_Fields = {
  __typename?: 'icims_company_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  numofemps: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Company_Stddev_Samp_Fields = {
  __typename?: 'icims_company_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  numofemps: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_company" */
export type Icims_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Company_Stream_Cursor_Value_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  numofemps?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Icims_Company_Sum_Fields = {
  __typename?: 'icims_company_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  numofemps: Maybe<Scalars['Int']>;
  profile_id: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_company" */
export enum Icims_Company_Update_Column {
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Name = 'name',
  /** column name */
  Numofemps = 'numofemps',
  /** column name */
  ProfileId = 'profile_id',
}

export type Icims_Company_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Company_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Company_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Company_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Company_Var_Pop_Fields = {
  __typename?: 'icims_company_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  numofemps: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Company_Var_Samp_Fields = {
  __typename?: 'icims_company_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  numofemps: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Company_Variance_Fields = {
  __typename?: 'icims_company_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  numofemps: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_country" */
export type Icims_Country = {
  __typename?: 'icims_country';
  abbrev: Scalars['String'];
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "icims_country" */
export type Icims_Country_Aggregate = {
  __typename?: 'icims_country_aggregate';
  aggregate: Maybe<Icims_Country_Aggregate_Fields>;
  nodes: Array<Icims_Country>;
};

/** aggregate fields of "icims_country" */
export type Icims_Country_Aggregate_Fields = {
  __typename?: 'icims_country_aggregate_fields';
  avg: Maybe<Icims_Country_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Country_Max_Fields>;
  min: Maybe<Icims_Country_Min_Fields>;
  stddev: Maybe<Icims_Country_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Country_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Country_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Country_Sum_Fields>;
  var_pop: Maybe<Icims_Country_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Country_Var_Samp_Fields>;
  variance: Maybe<Icims_Country_Variance_Fields>;
};

/** aggregate fields of "icims_country" */
export type Icims_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Country_Avg_Fields = {
  __typename?: 'icims_country_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_country". All fields are combined with a logical 'AND'. */
export type Icims_Country_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Country_Bool_Exp>>;
  _not?: InputMaybe<Icims_Country_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Country_Bool_Exp>>;
  abbrev?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_country" */
export enum Icims_Country_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "id" */
  IcimsCountryPkey = 'icims_country_pkey',
}

/** input type for incrementing numeric columns in table "icims_country" */
export type Icims_Country_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_country" */
export type Icims_Country_Insert_Input = {
  abbrev?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Country_Max_Fields = {
  __typename?: 'icims_country_max_fields';
  abbrev: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Country_Min_Fields = {
  __typename?: 'icims_country_min_fields';
  abbrev: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_country" */
export type Icims_Country_Mutation_Response = {
  __typename?: 'icims_country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Country>;
};

/** input type for inserting object relation for remote table "icims_country" */
export type Icims_Country_Obj_Rel_Insert_Input = {
  data: Icims_Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Country_On_Conflict>;
};

/** on_conflict condition type for table "icims_country" */
export type Icims_Country_On_Conflict = {
  constraint: Icims_Country_Constraint;
  update_columns?: Array<Icims_Country_Update_Column>;
  where?: InputMaybe<Icims_Country_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_country". */
export type Icims_Country_Order_By = {
  abbrev?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_country */
export type Icims_Country_Pk_Columns_Input = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** select columns of table "icims_country" */
export enum Icims_Country_Select_Column {
  /** column name */
  Abbrev = 'abbrev',
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "icims_country" */
export type Icims_Country_Set_Input = {
  abbrev?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Country_Stddev_Fields = {
  __typename?: 'icims_country_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Country_Stddev_Pop_Fields = {
  __typename?: 'icims_country_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Country_Stddev_Samp_Fields = {
  __typename?: 'icims_country_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_country" */
export type Icims_Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Country_Stream_Cursor_Value_Input = {
  abbrev?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Country_Sum_Fields = {
  __typename?: 'icims_country_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_country" */
export enum Icims_Country_Update_Column {
  /** column name */
  Abbrev = 'abbrev',
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

export type Icims_Country_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Country_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Country_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Country_Var_Pop_Fields = {
  __typename?: 'icims_country_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Country_Var_Samp_Fields = {
  __typename?: 'icims_country_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Country_Variance_Fields = {
  __typename?: 'icims_country_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_degree" */
export type Icims_Degree = {
  __typename?: 'icims_degree';
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "icims_degree" */
export type Icims_Degree_Aggregate = {
  __typename?: 'icims_degree_aggregate';
  aggregate: Maybe<Icims_Degree_Aggregate_Fields>;
  nodes: Array<Icims_Degree>;
};

/** aggregate fields of "icims_degree" */
export type Icims_Degree_Aggregate_Fields = {
  __typename?: 'icims_degree_aggregate_fields';
  avg: Maybe<Icims_Degree_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Degree_Max_Fields>;
  min: Maybe<Icims_Degree_Min_Fields>;
  stddev: Maybe<Icims_Degree_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Degree_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Degree_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Degree_Sum_Fields>;
  var_pop: Maybe<Icims_Degree_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Degree_Var_Samp_Fields>;
  variance: Maybe<Icims_Degree_Variance_Fields>;
};

/** aggregate fields of "icims_degree" */
export type Icims_Degree_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Degree_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Degree_Avg_Fields = {
  __typename?: 'icims_degree_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_degree". All fields are combined with a logical 'AND'. */
export type Icims_Degree_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Degree_Bool_Exp>>;
  _not?: InputMaybe<Icims_Degree_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Degree_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_degree" */
export enum Icims_Degree_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "id" */
  IcimsDegreePkey = 'icims_degree_pkey',
}

/** input type for incrementing numeric columns in table "icims_degree" */
export type Icims_Degree_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_degree" */
export type Icims_Degree_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Degree_Max_Fields = {
  __typename?: 'icims_degree_max_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Degree_Min_Fields = {
  __typename?: 'icims_degree_min_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_degree" */
export type Icims_Degree_Mutation_Response = {
  __typename?: 'icims_degree_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Degree>;
};

/** input type for inserting object relation for remote table "icims_degree" */
export type Icims_Degree_Obj_Rel_Insert_Input = {
  data: Icims_Degree_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Degree_On_Conflict>;
};

/** on_conflict condition type for table "icims_degree" */
export type Icims_Degree_On_Conflict = {
  constraint: Icims_Degree_Constraint;
  update_columns?: Array<Icims_Degree_Update_Column>;
  where?: InputMaybe<Icims_Degree_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_degree". */
export type Icims_Degree_Order_By = {
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_degree */
export type Icims_Degree_Pk_Columns_Input = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** select columns of table "icims_degree" */
export enum Icims_Degree_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "icims_degree" */
export type Icims_Degree_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Degree_Stddev_Fields = {
  __typename?: 'icims_degree_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Degree_Stddev_Pop_Fields = {
  __typename?: 'icims_degree_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Degree_Stddev_Samp_Fields = {
  __typename?: 'icims_degree_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_degree" */
export type Icims_Degree_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Degree_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Degree_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Degree_Sum_Fields = {
  __typename?: 'icims_degree_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_degree" */
export enum Icims_Degree_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

export type Icims_Degree_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Degree_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Degree_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Degree_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Degree_Var_Pop_Fields = {
  __typename?: 'icims_degree_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Degree_Var_Samp_Fields = {
  __typename?: 'icims_degree_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Degree_Variance_Fields = {
  __typename?: 'icims_degree_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_education" */
export type Icims_Education = {
  __typename?: 'icims_education';
  degree: Maybe<Scalars['String']>;
  educationcountry: Maybe<Scalars['String']>;
  entry: Scalars['Int'];
  graduationdate: Maybe<Scalars['date']>;
  /** An object relationship */
  icims_country: Maybe<Icims_Country>;
  /** An object relationship */
  icims_degree: Maybe<Icims_Degree>;
  /** An object relationship */
  icims_major: Maybe<Icims_Major>;
  /** An object relationship */
  icims_person: Maybe<Icims_Person>;
  /** An object relationship */
  icims_school: Maybe<Icims_School>;
  initial_data_transfer: Scalars['Int'];
  isgraduated: Maybe<Scalars['Boolean']>;
  major: Maybe<Scalars['String']>;
  person_profile_id: Scalars['Int'];
  school: Maybe<Scalars['String']>;
};

/** aggregated selection of "icims_education" */
export type Icims_Education_Aggregate = {
  __typename?: 'icims_education_aggregate';
  aggregate: Maybe<Icims_Education_Aggregate_Fields>;
  nodes: Array<Icims_Education>;
};

export type Icims_Education_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Icims_Education_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Icims_Education_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Icims_Education_Aggregate_Bool_Exp_Count>;
};

export type Icims_Education_Aggregate_Bool_Exp_Bool_And = {
  arguments: Icims_Education_Select_Column_Icims_Education_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Education_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Icims_Education_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Icims_Education_Select_Column_Icims_Education_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Education_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Icims_Education_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Education_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Education_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_education" */
export type Icims_Education_Aggregate_Fields = {
  __typename?: 'icims_education_aggregate_fields';
  avg: Maybe<Icims_Education_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Education_Max_Fields>;
  min: Maybe<Icims_Education_Min_Fields>;
  stddev: Maybe<Icims_Education_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Education_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Education_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Education_Sum_Fields>;
  var_pop: Maybe<Icims_Education_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Education_Var_Samp_Fields>;
  variance: Maybe<Icims_Education_Variance_Fields>;
};

/** aggregate fields of "icims_education" */
export type Icims_Education_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Education_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_education" */
export type Icims_Education_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Education_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Education_Max_Order_By>;
  min?: InputMaybe<Icims_Education_Min_Order_By>;
  stddev?: InputMaybe<Icims_Education_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Education_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Education_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Education_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Education_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Education_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Education_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "icims_education" */
export type Icims_Education_Arr_Rel_Insert_Input = {
  data: Array<Icims_Education_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Education_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Education_Avg_Fields = {
  __typename?: 'icims_education_avg_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_education" */
export type Icims_Education_Avg_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_education". All fields are combined with a logical 'AND'. */
export type Icims_Education_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Education_Bool_Exp>>;
  _not?: InputMaybe<Icims_Education_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Education_Bool_Exp>>;
  degree?: InputMaybe<String_Comparison_Exp>;
  educationcountry?: InputMaybe<String_Comparison_Exp>;
  entry?: InputMaybe<Int_Comparison_Exp>;
  graduationdate?: InputMaybe<Date_Comparison_Exp>;
  icims_country?: InputMaybe<Icims_Country_Bool_Exp>;
  icims_degree?: InputMaybe<Icims_Degree_Bool_Exp>;
  icims_major?: InputMaybe<Icims_Major_Bool_Exp>;
  icims_person?: InputMaybe<Icims_Person_Bool_Exp>;
  icims_school?: InputMaybe<Icims_School_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  isgraduated?: InputMaybe<Boolean_Comparison_Exp>;
  major?: InputMaybe<String_Comparison_Exp>;
  person_profile_id?: InputMaybe<Int_Comparison_Exp>;
  school?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_education" */
export enum Icims_Education_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "entry", "person_profile_id" */
  IcimsEducationInitialDataTransferPersonProfileIdEntKey = 'icims_education_initial_data_transfer_person_profile_id_ent_key',
  /** unique or primary key constraint on columns "initial_data_transfer", "entry", "person_profile_id" */
  IcimsEducationPkey = 'icims_education_pkey',
}

/** input type for incrementing numeric columns in table "icims_education" */
export type Icims_Education_Inc_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_education" */
export type Icims_Education_Insert_Input = {
  degree?: InputMaybe<Scalars['String']>;
  educationcountry?: InputMaybe<Scalars['String']>;
  entry?: InputMaybe<Scalars['Int']>;
  graduationdate?: InputMaybe<Scalars['date']>;
  icims_country?: InputMaybe<Icims_Country_Obj_Rel_Insert_Input>;
  icims_degree?: InputMaybe<Icims_Degree_Obj_Rel_Insert_Input>;
  icims_major?: InputMaybe<Icims_Major_Obj_Rel_Insert_Input>;
  icims_person?: InputMaybe<Icims_Person_Obj_Rel_Insert_Input>;
  icims_school?: InputMaybe<Icims_School_Obj_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  isgraduated?: InputMaybe<Scalars['Boolean']>;
  major?: InputMaybe<Scalars['String']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Education_Max_Fields = {
  __typename?: 'icims_education_max_fields';
  degree: Maybe<Scalars['String']>;
  educationcountry: Maybe<Scalars['String']>;
  entry: Maybe<Scalars['Int']>;
  graduationdate: Maybe<Scalars['date']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  major: Maybe<Scalars['String']>;
  person_profile_id: Maybe<Scalars['Int']>;
  school: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "icims_education" */
export type Icims_Education_Max_Order_By = {
  degree?: InputMaybe<Order_By>;
  educationcountry?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  graduationdate?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  major?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  school?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Education_Min_Fields = {
  __typename?: 'icims_education_min_fields';
  degree: Maybe<Scalars['String']>;
  educationcountry: Maybe<Scalars['String']>;
  entry: Maybe<Scalars['Int']>;
  graduationdate: Maybe<Scalars['date']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  major: Maybe<Scalars['String']>;
  person_profile_id: Maybe<Scalars['Int']>;
  school: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "icims_education" */
export type Icims_Education_Min_Order_By = {
  degree?: InputMaybe<Order_By>;
  educationcountry?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  graduationdate?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  major?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  school?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_education" */
export type Icims_Education_Mutation_Response = {
  __typename?: 'icims_education_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Education>;
};

/** on_conflict condition type for table "icims_education" */
export type Icims_Education_On_Conflict = {
  constraint: Icims_Education_Constraint;
  update_columns?: Array<Icims_Education_Update_Column>;
  where?: InputMaybe<Icims_Education_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_education". */
export type Icims_Education_Order_By = {
  degree?: InputMaybe<Order_By>;
  educationcountry?: InputMaybe<Order_By>;
  entry?: InputMaybe<Order_By>;
  graduationdate?: InputMaybe<Order_By>;
  icims_country?: InputMaybe<Icims_Country_Order_By>;
  icims_degree?: InputMaybe<Icims_Degree_Order_By>;
  icims_major?: InputMaybe<Icims_Major_Order_By>;
  icims_person?: InputMaybe<Icims_Person_Order_By>;
  icims_school?: InputMaybe<Icims_School_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  isgraduated?: InputMaybe<Order_By>;
  major?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  school?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_education */
export type Icims_Education_Pk_Columns_Input = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

/** select columns of table "icims_education" */
export enum Icims_Education_Select_Column {
  /** column name */
  Degree = 'degree',
  /** column name */
  Educationcountry = 'educationcountry',
  /** column name */
  Entry = 'entry',
  /** column name */
  Graduationdate = 'graduationdate',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Isgraduated = 'isgraduated',
  /** column name */
  Major = 'major',
  /** column name */
  PersonProfileId = 'person_profile_id',
  /** column name */
  School = 'school',
}

/** select "icims_education_aggregate_bool_exp_bool_and_arguments_columns" columns of table "icims_education" */
export enum Icims_Education_Select_Column_Icims_Education_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Isgraduated = 'isgraduated',
}

/** select "icims_education_aggregate_bool_exp_bool_or_arguments_columns" columns of table "icims_education" */
export enum Icims_Education_Select_Column_Icims_Education_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Isgraduated = 'isgraduated',
}

/** input type for updating data in table "icims_education" */
export type Icims_Education_Set_Input = {
  degree?: InputMaybe<Scalars['String']>;
  educationcountry?: InputMaybe<Scalars['String']>;
  entry?: InputMaybe<Scalars['Int']>;
  graduationdate?: InputMaybe<Scalars['date']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  isgraduated?: InputMaybe<Scalars['Boolean']>;
  major?: InputMaybe<Scalars['String']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Education_Stddev_Fields = {
  __typename?: 'icims_education_stddev_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_education" */
export type Icims_Education_Stddev_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Education_Stddev_Pop_Fields = {
  __typename?: 'icims_education_stddev_pop_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_education" */
export type Icims_Education_Stddev_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Education_Stddev_Samp_Fields = {
  __typename?: 'icims_education_stddev_samp_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_education" */
export type Icims_Education_Stddev_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_education" */
export type Icims_Education_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Education_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Education_Stream_Cursor_Value_Input = {
  degree?: InputMaybe<Scalars['String']>;
  educationcountry?: InputMaybe<Scalars['String']>;
  entry?: InputMaybe<Scalars['Int']>;
  graduationdate?: InputMaybe<Scalars['date']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  isgraduated?: InputMaybe<Scalars['Boolean']>;
  major?: InputMaybe<Scalars['String']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Education_Sum_Fields = {
  __typename?: 'icims_education_sum_fields';
  entry: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_education" */
export type Icims_Education_Sum_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** update columns of table "icims_education" */
export enum Icims_Education_Update_Column {
  /** column name */
  Degree = 'degree',
  /** column name */
  Educationcountry = 'educationcountry',
  /** column name */
  Entry = 'entry',
  /** column name */
  Graduationdate = 'graduationdate',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Isgraduated = 'isgraduated',
  /** column name */
  Major = 'major',
  /** column name */
  PersonProfileId = 'person_profile_id',
  /** column name */
  School = 'school',
}

export type Icims_Education_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Education_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Education_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Education_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Education_Var_Pop_Fields = {
  __typename?: 'icims_education_var_pop_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_education" */
export type Icims_Education_Var_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Education_Var_Samp_Fields = {
  __typename?: 'icims_education_var_samp_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_education" */
export type Icims_Education_Var_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Education_Variance_Fields = {
  __typename?: 'icims_education_variance_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_education" */
export type Icims_Education_Variance_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "icims_folder" */
export type Icims_Folder = {
  __typename?: 'icims_folder';
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "icims_folder" */
export type Icims_Folder_Aggregate = {
  __typename?: 'icims_folder_aggregate';
  aggregate: Maybe<Icims_Folder_Aggregate_Fields>;
  nodes: Array<Icims_Folder>;
};

/** aggregate fields of "icims_folder" */
export type Icims_Folder_Aggregate_Fields = {
  __typename?: 'icims_folder_aggregate_fields';
  avg: Maybe<Icims_Folder_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Folder_Max_Fields>;
  min: Maybe<Icims_Folder_Min_Fields>;
  stddev: Maybe<Icims_Folder_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Folder_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Folder_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Folder_Sum_Fields>;
  var_pop: Maybe<Icims_Folder_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Folder_Var_Samp_Fields>;
  variance: Maybe<Icims_Folder_Variance_Fields>;
};

/** aggregate fields of "icims_folder" */
export type Icims_Folder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Folder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Folder_Avg_Fields = {
  __typename?: 'icims_folder_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_folder". All fields are combined with a logical 'AND'. */
export type Icims_Folder_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Folder_Bool_Exp>>;
  _not?: InputMaybe<Icims_Folder_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Folder_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_folder" */
export enum Icims_Folder_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "id" */
  IcimsFolderPkey = 'icims_folder_pkey',
}

/** input type for incrementing numeric columns in table "icims_folder" */
export type Icims_Folder_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_folder" */
export type Icims_Folder_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Folder_Max_Fields = {
  __typename?: 'icims_folder_max_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Folder_Min_Fields = {
  __typename?: 'icims_folder_min_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_folder" */
export type Icims_Folder_Mutation_Response = {
  __typename?: 'icims_folder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Folder>;
};

/** input type for inserting object relation for remote table "icims_folder" */
export type Icims_Folder_Obj_Rel_Insert_Input = {
  data: Icims_Folder_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Folder_On_Conflict>;
};

/** on_conflict condition type for table "icims_folder" */
export type Icims_Folder_On_Conflict = {
  constraint: Icims_Folder_Constraint;
  update_columns?: Array<Icims_Folder_Update_Column>;
  where?: InputMaybe<Icims_Folder_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_folder". */
export type Icims_Folder_Order_By = {
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_folder */
export type Icims_Folder_Pk_Columns_Input = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** select columns of table "icims_folder" */
export enum Icims_Folder_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "icims_folder" */
export type Icims_Folder_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Folder_Stddev_Fields = {
  __typename?: 'icims_folder_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Folder_Stddev_Pop_Fields = {
  __typename?: 'icims_folder_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Folder_Stddev_Samp_Fields = {
  __typename?: 'icims_folder_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_folder" */
export type Icims_Folder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Folder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Folder_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Folder_Sum_Fields = {
  __typename?: 'icims_folder_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_folder" */
export enum Icims_Folder_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

export type Icims_Folder_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Folder_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Folder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Folder_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Folder_Var_Pop_Fields = {
  __typename?: 'icims_folder_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Folder_Var_Samp_Fields = {
  __typename?: 'icims_folder_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Folder_Variance_Fields = {
  __typename?: 'icims_folder_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_initial_data_transfer" */
export type Icims_Initial_Data_Transfer = {
  __typename?: 'icims_initial_data_transfer';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_current: Scalars['Boolean'];
  offset: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "icims_initial_data_transfer" */
export type Icims_Initial_Data_Transfer_Aggregate = {
  __typename?: 'icims_initial_data_transfer_aggregate';
  aggregate: Maybe<Icims_Initial_Data_Transfer_Aggregate_Fields>;
  nodes: Array<Icims_Initial_Data_Transfer>;
};

/** aggregate fields of "icims_initial_data_transfer" */
export type Icims_Initial_Data_Transfer_Aggregate_Fields = {
  __typename?: 'icims_initial_data_transfer_aggregate_fields';
  avg: Maybe<Icims_Initial_Data_Transfer_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Initial_Data_Transfer_Max_Fields>;
  min: Maybe<Icims_Initial_Data_Transfer_Min_Fields>;
  stddev: Maybe<Icims_Initial_Data_Transfer_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Initial_Data_Transfer_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Initial_Data_Transfer_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Initial_Data_Transfer_Sum_Fields>;
  var_pop: Maybe<Icims_Initial_Data_Transfer_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Initial_Data_Transfer_Var_Samp_Fields>;
  variance: Maybe<Icims_Initial_Data_Transfer_Variance_Fields>;
};

/** aggregate fields of "icims_initial_data_transfer" */
export type Icims_Initial_Data_Transfer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Initial_Data_Transfer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Initial_Data_Transfer_Avg_Fields = {
  __typename?: 'icims_initial_data_transfer_avg_fields';
  id: Maybe<Scalars['Float']>;
  offset: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_initial_data_transfer". All fields are combined with a logical 'AND'. */
export type Icims_Initial_Data_Transfer_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Initial_Data_Transfer_Bool_Exp>>;
  _not?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Initial_Data_Transfer_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_current?: InputMaybe<Boolean_Comparison_Exp>;
  offset?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_initial_data_transfer" */
export enum Icims_Initial_Data_Transfer_Constraint {
  /** unique or primary key constraint on columns "id" */
  IcimsInitialDataTransferPkey = 'icims_initial_data_transfer_pkey',
}

/** input type for incrementing numeric columns in table "icims_initial_data_transfer" */
export type Icims_Initial_Data_Transfer_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_initial_data_transfer" */
export type Icims_Initial_Data_Transfer_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_current?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Icims_Initial_Data_Transfer_Max_Fields = {
  __typename?: 'icims_initial_data_transfer_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  status: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Icims_Initial_Data_Transfer_Min_Fields = {
  __typename?: 'icims_initial_data_transfer_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  status: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "icims_initial_data_transfer" */
export type Icims_Initial_Data_Transfer_Mutation_Response = {
  __typename?: 'icims_initial_data_transfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Initial_Data_Transfer>;
};

/** input type for inserting object relation for remote table "icims_initial_data_transfer" */
export type Icims_Initial_Data_Transfer_Obj_Rel_Insert_Input = {
  data: Icims_Initial_Data_Transfer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Initial_Data_Transfer_On_Conflict>;
};

/** on_conflict condition type for table "icims_initial_data_transfer" */
export type Icims_Initial_Data_Transfer_On_Conflict = {
  constraint: Icims_Initial_Data_Transfer_Constraint;
  update_columns?: Array<Icims_Initial_Data_Transfer_Update_Column>;
  where?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_initial_data_transfer". */
export type Icims_Initial_Data_Transfer_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_current?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_initial_data_transfer */
export type Icims_Initial_Data_Transfer_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "icims_initial_data_transfer" */
export enum Icims_Initial_Data_Transfer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCurrent = 'is_current',
  /** column name */
  Offset = 'offset',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "icims_initial_data_transfer" */
export type Icims_Initial_Data_Transfer_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_current?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Icims_Initial_Data_Transfer_Stddev_Fields = {
  __typename?: 'icims_initial_data_transfer_stddev_fields';
  id: Maybe<Scalars['Float']>;
  offset: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Initial_Data_Transfer_Stddev_Pop_Fields = {
  __typename?: 'icims_initial_data_transfer_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  offset: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Initial_Data_Transfer_Stddev_Samp_Fields = {
  __typename?: 'icims_initial_data_transfer_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  offset: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_initial_data_transfer" */
export type Icims_Initial_Data_Transfer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Initial_Data_Transfer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Initial_Data_Transfer_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  is_current?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Icims_Initial_Data_Transfer_Sum_Fields = {
  __typename?: 'icims_initial_data_transfer_sum_fields';
  id: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_initial_data_transfer" */
export enum Icims_Initial_Data_Transfer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsCurrent = 'is_current',
  /** column name */
  Offset = 'offset',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Icims_Initial_Data_Transfer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Initial_Data_Transfer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Initial_Data_Transfer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Initial_Data_Transfer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Initial_Data_Transfer_Var_Pop_Fields = {
  __typename?: 'icims_initial_data_transfer_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  offset: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Initial_Data_Transfer_Var_Samp_Fields = {
  __typename?: 'icims_initial_data_transfer_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  offset: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Initial_Data_Transfer_Variance_Fields = {
  __typename?: 'icims_initial_data_transfer_variance_fields';
  id: Maybe<Scalars['Float']>;
  offset: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_interested_location" */
export type Icims_Interested_Location = {
  __typename?: 'icims_interested_location';
  /** An array relationship */
  icims_person_interested_locations: Array<Icims_Person_Interested_Location>;
  /** An aggregate relationship */
  icims_person_interested_locations_aggregate: Icims_Person_Interested_Location_Aggregate;
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
  value: Scalars['String'];
};

/** columns and relationships of "icims_interested_location" */
export type Icims_Interested_LocationIcims_Person_Interested_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
};

/** columns and relationships of "icims_interested_location" */
export type Icims_Interested_LocationIcims_Person_Interested_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
};

/** aggregated selection of "icims_interested_location" */
export type Icims_Interested_Location_Aggregate = {
  __typename?: 'icims_interested_location_aggregate';
  aggregate: Maybe<Icims_Interested_Location_Aggregate_Fields>;
  nodes: Array<Icims_Interested_Location>;
};

/** aggregate fields of "icims_interested_location" */
export type Icims_Interested_Location_Aggregate_Fields = {
  __typename?: 'icims_interested_location_aggregate_fields';
  avg: Maybe<Icims_Interested_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Interested_Location_Max_Fields>;
  min: Maybe<Icims_Interested_Location_Min_Fields>;
  stddev: Maybe<Icims_Interested_Location_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Interested_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Interested_Location_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Interested_Location_Sum_Fields>;
  var_pop: Maybe<Icims_Interested_Location_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Interested_Location_Var_Samp_Fields>;
  variance: Maybe<Icims_Interested_Location_Variance_Fields>;
};

/** aggregate fields of "icims_interested_location" */
export type Icims_Interested_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Interested_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Interested_Location_Avg_Fields = {
  __typename?: 'icims_interested_location_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_interested_location". All fields are combined with a logical 'AND'. */
export type Icims_Interested_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Interested_Location_Bool_Exp>>;
  _not?: InputMaybe<Icims_Interested_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Interested_Location_Bool_Exp>>;
  icims_person_interested_locations?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
  icims_person_interested_locations_aggregate?: InputMaybe<Icims_Person_Interested_Location_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_interested_location" */
export enum Icims_Interested_Location_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "id" */
  IcimsInterestedLocationPkey = 'icims_interested_location_pkey',
}

/** input type for incrementing numeric columns in table "icims_interested_location" */
export type Icims_Interested_Location_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_interested_location" */
export type Icims_Interested_Location_Insert_Input = {
  icims_person_interested_locations?: InputMaybe<Icims_Person_Interested_Location_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Interested_Location_Max_Fields = {
  __typename?: 'icims_interested_location_max_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Interested_Location_Min_Fields = {
  __typename?: 'icims_interested_location_min_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_interested_location" */
export type Icims_Interested_Location_Mutation_Response = {
  __typename?: 'icims_interested_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Interested_Location>;
};

/** input type for inserting object relation for remote table "icims_interested_location" */
export type Icims_Interested_Location_Obj_Rel_Insert_Input = {
  data: Icims_Interested_Location_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Interested_Location_On_Conflict>;
};

/** on_conflict condition type for table "icims_interested_location" */
export type Icims_Interested_Location_On_Conflict = {
  constraint: Icims_Interested_Location_Constraint;
  update_columns?: Array<Icims_Interested_Location_Update_Column>;
  where?: InputMaybe<Icims_Interested_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_interested_location". */
export type Icims_Interested_Location_Order_By = {
  icims_person_interested_locations_aggregate?: InputMaybe<Icims_Person_Interested_Location_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_interested_location */
export type Icims_Interested_Location_Pk_Columns_Input = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** select columns of table "icims_interested_location" */
export enum Icims_Interested_Location_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "icims_interested_location" */
export type Icims_Interested_Location_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Interested_Location_Stddev_Fields = {
  __typename?: 'icims_interested_location_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Interested_Location_Stddev_Pop_Fields = {
  __typename?: 'icims_interested_location_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Interested_Location_Stddev_Samp_Fields = {
  __typename?: 'icims_interested_location_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_interested_location" */
export type Icims_Interested_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Interested_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Interested_Location_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Interested_Location_Sum_Fields = {
  __typename?: 'icims_interested_location_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_interested_location" */
export enum Icims_Interested_Location_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

export type Icims_Interested_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Interested_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Interested_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Interested_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Interested_Location_Var_Pop_Fields = {
  __typename?: 'icims_interested_location_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Interested_Location_Var_Samp_Fields = {
  __typename?: 'icims_interested_location_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Interested_Location_Variance_Fields = {
  __typename?: 'icims_interested_location_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_job" */
export type Icims_Job = {
  __typename?: 'icims_job';
  about: Maybe<Scalars['String']>;
  additional_info: Maybe<Scalars['String']>;
  /** An array relationship */
  candidate_curation_workflows: Array<Candidate_Curation_Workflow>;
  /** An aggregate relationship */
  candidate_curation_workflows_aggregate: Candidate_Curation_Workflow_Aggregate;
  company_profile_id: Maybe<Scalars['Int']>;
  contract_length: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  degree_requirement: Maybe<Scalars['String']>;
  family: Maybe<Scalars['String']>;
  folder: Maybe<Scalars['String']>;
  hiring_manager: Maybe<Scalars['Int']>;
  hiring_manager_email: Maybe<Scalars['String']>;
  /** An array relationship */
  icims_applicant_workflows: Array<Icims_Applicant_Workflow>;
  /** An aggregate relationship */
  icims_applicant_workflows_aggregate: Icims_Applicant_Workflow_Aggregate;
  /** An object relationship */
  icims_company: Maybe<Icims_Company>;
  /** An object relationship */
  icims_folder: Maybe<Icims_Folder>;
  /** An object relationship */
  icims_initial_data_transfer: Icims_Initial_Data_Transfer;
  /** An array relationship */
  icims_job_locations: Array<Icims_Job_Location>;
  /** An aggregate relationship */
  icims_job_locations_aggregate: Icims_Job_Location_Aggregate;
  /** An array relationship */
  icims_job_positions: Array<Icims_Job>;
  /** An aggregate relationship */
  icims_job_positions_aggregate: Icims_Job_Aggregate;
  /** An object relationship */
  icims_job_posting: Maybe<Icims_Job>;
  /** An array relationship */
  icims_job_requested_locations: Array<Icims_Job_Requested_Location>;
  /** An aggregate relationship */
  icims_job_requested_locations_aggregate: Icims_Job_Requested_Location_Aggregate;
  /** An array relationship */
  icims_job_skills: Array<Icims_Job_Skill>;
  /** An aggregate relationship */
  icims_job_skills_aggregate: Icims_Job_Skill_Aggregate;
  /** An object relationship */
  icims_person: Maybe<Icims_Person>;
  /** An object relationship */
  icims_profile_job: Icims_Profile_Job;
  initial_data_transfer: Scalars['Int'];
  initial_headcount: Maybe<Scalars['Int']>;
  is_position: Scalars['Boolean'];
  /** An object relationship */
  job: Maybe<Job>;
  jobid: Maybe<Scalars['String']>;
  jobtitle: Maybe<Scalars['String']>;
  level: Maybe<Scalars['Int']>;
  level_type: Maybe<Scalars['String']>;
  max_contract_rate: Maybe<Scalars['numeric']>;
  max_salary: Maybe<Scalars['numeric']>;
  min_contract_rate: Maybe<Scalars['numeric']>;
  min_salary: Maybe<Scalars['numeric']>;
  nice_to_have: Maybe<Scalars['String']>;
  notes: Maybe<Scalars['String']>;
  open_to_international: Maybe<Scalars['Boolean']>;
  pause_additional_candidates: Maybe<Scalars['String']>;
  /** An object relationship */
  peopleOpsById: Maybe<Icims_Person>;
  people_ops: Maybe<Scalars['Int']>;
  position_detail_type: Maybe<Scalars['String']>;
  posting: Maybe<Scalars['Int']>;
  profile_id: Scalars['Int'];
  recruiter: Maybe<Scalars['Int']>;
  /** An object relationship */
  recruiterById: Maybe<Icims_Person>;
  required_skill_1: Maybe<Scalars['Int']>;
  required_skill_2: Maybe<Scalars['Int']>;
  required_skill_3: Maybe<Scalars['Int']>;
  /** An object relationship */
  secondaryRecruiterById: Maybe<Icims_Person>;
  /** An object relationship */
  secondarySourcerById: Maybe<Icims_Person>;
  secondary_sourcer: Maybe<Scalars['Int']>;
  secondaryrecruiter: Maybe<Scalars['Int']>;
  /** An object relationship */
  skillByRequiredSkill1: Maybe<Skill>;
  /** An object relationship */
  skillByRequiredSkill2: Maybe<Skill>;
  /** An object relationship */
  skillByRequiredSkill3: Maybe<Skill>;
  sourcer: Maybe<Scalars['Int']>;
  /** An object relationship */
  sourcerById: Maybe<Icims_Person>;
  tech_stack: Maybe<Scalars['String']>;
  what_you_bring: Maybe<Scalars['String']>;
  what_youll_do: Maybe<Scalars['String']>;
  years_experience: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobCandidate_Curation_WorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobCandidate_Curation_Workflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobIcims_Applicant_WorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobIcims_Applicant_Workflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobIcims_Job_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Location_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobIcims_Job_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Location_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobIcims_Job_PositionsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobIcims_Job_Positions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobIcims_Job_Requested_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobIcims_Job_Requested_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobIcims_Job_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Skill_Order_By>>;
  where?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobIcims_Job_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Skill_Order_By>>;
  where?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
};

/** columns and relationships of "icims_job" */
export type Icims_JobYears_ExperienceArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "icims_job" */
export type Icims_Job_Aggregate = {
  __typename?: 'icims_job_aggregate';
  aggregate: Maybe<Icims_Job_Aggregate_Fields>;
  nodes: Array<Icims_Job>;
};

export type Icims_Job_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Icims_Job_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Icims_Job_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Icims_Job_Aggregate_Bool_Exp_Count>;
};

export type Icims_Job_Aggregate_Bool_Exp_Bool_And = {
  arguments: Icims_Job_Select_Column_Icims_Job_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Job_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Icims_Job_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Icims_Job_Select_Column_Icims_Job_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Job_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Icims_Job_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_job" */
export type Icims_Job_Aggregate_Fields = {
  __typename?: 'icims_job_aggregate_fields';
  avg: Maybe<Icims_Job_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Job_Max_Fields>;
  min: Maybe<Icims_Job_Min_Fields>;
  stddev: Maybe<Icims_Job_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Job_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Job_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Job_Sum_Fields>;
  var_pop: Maybe<Icims_Job_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Job_Var_Samp_Fields>;
  variance: Maybe<Icims_Job_Variance_Fields>;
};

/** aggregate fields of "icims_job" */
export type Icims_Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_job" */
export type Icims_Job_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Job_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Job_Max_Order_By>;
  min?: InputMaybe<Icims_Job_Min_Order_By>;
  stddev?: InputMaybe<Icims_Job_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Job_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Job_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Job_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Job_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Job_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Job_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Icims_Job_Append_Input = {
  years_experience?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "icims_job" */
export type Icims_Job_Arr_Rel_Insert_Input = {
  data: Array<Icims_Job_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Job_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Job_Avg_Fields = {
  __typename?: 'icims_job_avg_fields';
  company_profile_id: Maybe<Scalars['Float']>;
  contract_length: Maybe<Scalars['Float']>;
  hiring_manager: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  level: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  people_ops: Maybe<Scalars['Float']>;
  posting: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  recruiter: Maybe<Scalars['Float']>;
  required_skill_1: Maybe<Scalars['Float']>;
  required_skill_2: Maybe<Scalars['Float']>;
  required_skill_3: Maybe<Scalars['Float']>;
  secondary_sourcer: Maybe<Scalars['Float']>;
  secondaryrecruiter: Maybe<Scalars['Float']>;
  sourcer: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_job" */
export type Icims_Job_Avg_Order_By = {
  company_profile_id?: InputMaybe<Order_By>;
  contract_length?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  people_ops?: InputMaybe<Order_By>;
  posting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Order_By>;
  required_skill_1?: InputMaybe<Order_By>;
  required_skill_2?: InputMaybe<Order_By>;
  required_skill_3?: InputMaybe<Order_By>;
  secondary_sourcer?: InputMaybe<Order_By>;
  secondaryrecruiter?: InputMaybe<Order_By>;
  sourcer?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_job". All fields are combined with a logical 'AND'. */
export type Icims_Job_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Job_Bool_Exp>>;
  _not?: InputMaybe<Icims_Job_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Job_Bool_Exp>>;
  about?: InputMaybe<String_Comparison_Exp>;
  additional_info?: InputMaybe<String_Comparison_Exp>;
  candidate_curation_workflows?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
  candidate_curation_workflows_aggregate?: InputMaybe<Candidate_Curation_Workflow_Aggregate_Bool_Exp>;
  company_profile_id?: InputMaybe<Int_Comparison_Exp>;
  contract_length?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  degree_requirement?: InputMaybe<String_Comparison_Exp>;
  family?: InputMaybe<String_Comparison_Exp>;
  folder?: InputMaybe<String_Comparison_Exp>;
  hiring_manager?: InputMaybe<Int_Comparison_Exp>;
  hiring_manager_email?: InputMaybe<String_Comparison_Exp>;
  icims_applicant_workflows?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
  icims_applicant_workflows_aggregate?: InputMaybe<Icims_Applicant_Workflow_Aggregate_Bool_Exp>;
  icims_company?: InputMaybe<Icims_Company_Bool_Exp>;
  icims_folder?: InputMaybe<Icims_Folder_Bool_Exp>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
  icims_job_locations?: InputMaybe<Icims_Job_Location_Bool_Exp>;
  icims_job_locations_aggregate?: InputMaybe<Icims_Job_Location_Aggregate_Bool_Exp>;
  icims_job_positions?: InputMaybe<Icims_Job_Bool_Exp>;
  icims_job_positions_aggregate?: InputMaybe<Icims_Job_Aggregate_Bool_Exp>;
  icims_job_posting?: InputMaybe<Icims_Job_Bool_Exp>;
  icims_job_requested_locations?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
  icims_job_requested_locations_aggregate?: InputMaybe<Icims_Job_Requested_Location_Aggregate_Bool_Exp>;
  icims_job_skills?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
  icims_job_skills_aggregate?: InputMaybe<Icims_Job_Skill_Aggregate_Bool_Exp>;
  icims_person?: InputMaybe<Icims_Person_Bool_Exp>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  initial_headcount?: InputMaybe<Int_Comparison_Exp>;
  is_position?: InputMaybe<Boolean_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  jobid?: InputMaybe<String_Comparison_Exp>;
  jobtitle?: InputMaybe<String_Comparison_Exp>;
  level?: InputMaybe<Int_Comparison_Exp>;
  level_type?: InputMaybe<String_Comparison_Exp>;
  max_contract_rate?: InputMaybe<Numeric_Comparison_Exp>;
  max_salary?: InputMaybe<Numeric_Comparison_Exp>;
  min_contract_rate?: InputMaybe<Numeric_Comparison_Exp>;
  min_salary?: InputMaybe<Numeric_Comparison_Exp>;
  nice_to_have?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  open_to_international?: InputMaybe<Boolean_Comparison_Exp>;
  pause_additional_candidates?: InputMaybe<String_Comparison_Exp>;
  peopleOpsById?: InputMaybe<Icims_Person_Bool_Exp>;
  people_ops?: InputMaybe<Int_Comparison_Exp>;
  position_detail_type?: InputMaybe<String_Comparison_Exp>;
  posting?: InputMaybe<Int_Comparison_Exp>;
  profile_id?: InputMaybe<Int_Comparison_Exp>;
  recruiter?: InputMaybe<Int_Comparison_Exp>;
  recruiterById?: InputMaybe<Icims_Person_Bool_Exp>;
  required_skill_1?: InputMaybe<Int_Comparison_Exp>;
  required_skill_2?: InputMaybe<Int_Comparison_Exp>;
  required_skill_3?: InputMaybe<Int_Comparison_Exp>;
  secondaryRecruiterById?: InputMaybe<Icims_Person_Bool_Exp>;
  secondarySourcerById?: InputMaybe<Icims_Person_Bool_Exp>;
  secondary_sourcer?: InputMaybe<Int_Comparison_Exp>;
  secondaryrecruiter?: InputMaybe<Int_Comparison_Exp>;
  skillByRequiredSkill1?: InputMaybe<Skill_Bool_Exp>;
  skillByRequiredSkill2?: InputMaybe<Skill_Bool_Exp>;
  skillByRequiredSkill3?: InputMaybe<Skill_Bool_Exp>;
  sourcer?: InputMaybe<Int_Comparison_Exp>;
  sourcerById?: InputMaybe<Icims_Person_Bool_Exp>;
  tech_stack?: InputMaybe<String_Comparison_Exp>;
  what_you_bring?: InputMaybe<String_Comparison_Exp>;
  what_youll_do?: InputMaybe<String_Comparison_Exp>;
  years_experience?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_job" */
export enum Icims_Job_Constraint {
  /** unique or primary key constraint on columns "profile_id", "initial_data_transfer" */
  IcimsJobPkey = 'icims_job_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Icims_Job_Delete_At_Path_Input = {
  years_experience?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Icims_Job_Delete_Elem_Input = {
  years_experience?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Icims_Job_Delete_Key_Input = {
  years_experience?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "icims_job" */
export type Icims_Job_Inc_Input = {
  company_profile_id?: InputMaybe<Scalars['Int']>;
  contract_length?: InputMaybe<Scalars['Int']>;
  hiring_manager?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  initial_headcount?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  max_contract_rate?: InputMaybe<Scalars['numeric']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  min_contract_rate?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
  people_ops?: InputMaybe<Scalars['Int']>;
  posting?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  recruiter?: InputMaybe<Scalars['Int']>;
  required_skill_1?: InputMaybe<Scalars['Int']>;
  required_skill_2?: InputMaybe<Scalars['Int']>;
  required_skill_3?: InputMaybe<Scalars['Int']>;
  secondary_sourcer?: InputMaybe<Scalars['Int']>;
  secondaryrecruiter?: InputMaybe<Scalars['Int']>;
  sourcer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_job" */
export type Icims_Job_Insert_Input = {
  about?: InputMaybe<Scalars['String']>;
  additional_info?: InputMaybe<Scalars['String']>;
  candidate_curation_workflows?: InputMaybe<Candidate_Curation_Workflow_Arr_Rel_Insert_Input>;
  company_profile_id?: InputMaybe<Scalars['Int']>;
  contract_length?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  degree_requirement?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['String']>;
  hiring_manager?: InputMaybe<Scalars['Int']>;
  hiring_manager_email?: InputMaybe<Scalars['String']>;
  icims_applicant_workflows?: InputMaybe<Icims_Applicant_Workflow_Arr_Rel_Insert_Input>;
  icims_company?: InputMaybe<Icims_Company_Obj_Rel_Insert_Input>;
  icims_folder?: InputMaybe<Icims_Folder_Obj_Rel_Insert_Input>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Obj_Rel_Insert_Input>;
  icims_job_locations?: InputMaybe<Icims_Job_Location_Arr_Rel_Insert_Input>;
  icims_job_positions?: InputMaybe<Icims_Job_Arr_Rel_Insert_Input>;
  icims_job_posting?: InputMaybe<Icims_Job_Obj_Rel_Insert_Input>;
  icims_job_requested_locations?: InputMaybe<Icims_Job_Requested_Location_Arr_Rel_Insert_Input>;
  icims_job_skills?: InputMaybe<Icims_Job_Skill_Arr_Rel_Insert_Input>;
  icims_person?: InputMaybe<Icims_Person_Obj_Rel_Insert_Input>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Obj_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  initial_headcount?: InputMaybe<Scalars['Int']>;
  is_position?: InputMaybe<Scalars['Boolean']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  jobid?: InputMaybe<Scalars['String']>;
  jobtitle?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  level_type?: InputMaybe<Scalars['String']>;
  max_contract_rate?: InputMaybe<Scalars['numeric']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  min_contract_rate?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
  nice_to_have?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  open_to_international?: InputMaybe<Scalars['Boolean']>;
  pause_additional_candidates?: InputMaybe<Scalars['String']>;
  peopleOpsById?: InputMaybe<Icims_Person_Obj_Rel_Insert_Input>;
  people_ops?: InputMaybe<Scalars['Int']>;
  position_detail_type?: InputMaybe<Scalars['String']>;
  posting?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  recruiter?: InputMaybe<Scalars['Int']>;
  recruiterById?: InputMaybe<Icims_Person_Obj_Rel_Insert_Input>;
  required_skill_1?: InputMaybe<Scalars['Int']>;
  required_skill_2?: InputMaybe<Scalars['Int']>;
  required_skill_3?: InputMaybe<Scalars['Int']>;
  secondaryRecruiterById?: InputMaybe<Icims_Person_Obj_Rel_Insert_Input>;
  secondarySourcerById?: InputMaybe<Icims_Person_Obj_Rel_Insert_Input>;
  secondary_sourcer?: InputMaybe<Scalars['Int']>;
  secondaryrecruiter?: InputMaybe<Scalars['Int']>;
  skillByRequiredSkill1?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skillByRequiredSkill2?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skillByRequiredSkill3?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  sourcer?: InputMaybe<Scalars['Int']>;
  sourcerById?: InputMaybe<Icims_Person_Obj_Rel_Insert_Input>;
  tech_stack?: InputMaybe<Scalars['String']>;
  what_you_bring?: InputMaybe<Scalars['String']>;
  what_youll_do?: InputMaybe<Scalars['String']>;
  years_experience?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "icims_job_location" */
export type Icims_Job_Location = {
  __typename?: 'icims_job_location';
  entry: Scalars['Int'];
  /** An object relationship */
  icims_job: Maybe<Icims_Job>;
  /** An object relationship */
  icims_location: Maybe<Icims_Location>;
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
  location: Maybe<Scalars['Int']>;
};

/** aggregated selection of "icims_job_location" */
export type Icims_Job_Location_Aggregate = {
  __typename?: 'icims_job_location_aggregate';
  aggregate: Maybe<Icims_Job_Location_Aggregate_Fields>;
  nodes: Array<Icims_Job_Location>;
};

export type Icims_Job_Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<Icims_Job_Location_Aggregate_Bool_Exp_Count>;
};

export type Icims_Job_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Job_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Job_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_job_location" */
export type Icims_Job_Location_Aggregate_Fields = {
  __typename?: 'icims_job_location_aggregate_fields';
  avg: Maybe<Icims_Job_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Job_Location_Max_Fields>;
  min: Maybe<Icims_Job_Location_Min_Fields>;
  stddev: Maybe<Icims_Job_Location_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Job_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Job_Location_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Job_Location_Sum_Fields>;
  var_pop: Maybe<Icims_Job_Location_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Job_Location_Var_Samp_Fields>;
  variance: Maybe<Icims_Job_Location_Variance_Fields>;
};

/** aggregate fields of "icims_job_location" */
export type Icims_Job_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Job_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_job_location" */
export type Icims_Job_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Job_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Job_Location_Max_Order_By>;
  min?: InputMaybe<Icims_Job_Location_Min_Order_By>;
  stddev?: InputMaybe<Icims_Job_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Job_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Job_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Job_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Job_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Job_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Job_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "icims_job_location" */
export type Icims_Job_Location_Arr_Rel_Insert_Input = {
  data: Array<Icims_Job_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Job_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Job_Location_Avg_Fields = {
  __typename?: 'icims_job_location_avg_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  location: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_job_location" */
export type Icims_Job_Location_Avg_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_job_location". All fields are combined with a logical 'AND'. */
export type Icims_Job_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Job_Location_Bool_Exp>>;
  _not?: InputMaybe<Icims_Job_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Job_Location_Bool_Exp>>;
  entry?: InputMaybe<Int_Comparison_Exp>;
  icims_job?: InputMaybe<Icims_Job_Bool_Exp>;
  icims_location?: InputMaybe<Icims_Location_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  job_profile_id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_job_location" */
export enum Icims_Job_Location_Constraint {
  /** unique or primary key constraint on columns "job_profile_id", "initial_data_transfer", "entry" */
  IcimsJobLocationPkey = 'icims_job_location_pkey',
}

/** input type for incrementing numeric columns in table "icims_job_location" */
export type Icims_Job_Location_Inc_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_job_location" */
export type Icims_Job_Location_Insert_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  icims_job?: InputMaybe<Icims_Job_Obj_Rel_Insert_Input>;
  icims_location?: InputMaybe<Icims_Location_Obj_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Icims_Job_Location_Max_Fields = {
  __typename?: 'icims_job_location_max_fields';
  entry: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  location: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "icims_job_location" */
export type Icims_Job_Location_Max_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Job_Location_Min_Fields = {
  __typename?: 'icims_job_location_min_fields';
  entry: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  location: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "icims_job_location" */
export type Icims_Job_Location_Min_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_job_location" */
export type Icims_Job_Location_Mutation_Response = {
  __typename?: 'icims_job_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Job_Location>;
};

/** on_conflict condition type for table "icims_job_location" */
export type Icims_Job_Location_On_Conflict = {
  constraint: Icims_Job_Location_Constraint;
  update_columns?: Array<Icims_Job_Location_Update_Column>;
  where?: InputMaybe<Icims_Job_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_job_location". */
export type Icims_Job_Location_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_job?: InputMaybe<Icims_Job_Order_By>;
  icims_location?: InputMaybe<Icims_Location_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_job_location */
export type Icims_Job_Location_Pk_Columns_Input = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
};

/** select columns of table "icims_job_location" */
export enum Icims_Job_Location_Select_Column {
  /** column name */
  Entry = 'entry',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  JobProfileId = 'job_profile_id',
  /** column name */
  Location = 'location',
}

/** input type for updating data in table "icims_job_location" */
export type Icims_Job_Location_Set_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Icims_Job_Location_Stddev_Fields = {
  __typename?: 'icims_job_location_stddev_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  location: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_job_location" */
export type Icims_Job_Location_Stddev_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Job_Location_Stddev_Pop_Fields = {
  __typename?: 'icims_job_location_stddev_pop_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  location: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_job_location" */
export type Icims_Job_Location_Stddev_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Job_Location_Stddev_Samp_Fields = {
  __typename?: 'icims_job_location_stddev_samp_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  location: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_job_location" */
export type Icims_Job_Location_Stddev_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_job_location" */
export type Icims_Job_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Job_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Job_Location_Stream_Cursor_Value_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Icims_Job_Location_Sum_Fields = {
  __typename?: 'icims_job_location_sum_fields';
  entry: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  location: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_job_location" */
export type Icims_Job_Location_Sum_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** update columns of table "icims_job_location" */
export enum Icims_Job_Location_Update_Column {
  /** column name */
  Entry = 'entry',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  JobProfileId = 'job_profile_id',
  /** column name */
  Location = 'location',
}

export type Icims_Job_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Job_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Job_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Job_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Job_Location_Var_Pop_Fields = {
  __typename?: 'icims_job_location_var_pop_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  location: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_job_location" */
export type Icims_Job_Location_Var_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Job_Location_Var_Samp_Fields = {
  __typename?: 'icims_job_location_var_samp_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  location: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_job_location" */
export type Icims_Job_Location_Var_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Job_Location_Variance_Fields = {
  __typename?: 'icims_job_location_variance_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
  location: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_job_location" */
export type Icims_Job_Location_Variance_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Icims_Job_Max_Fields = {
  __typename?: 'icims_job_max_fields';
  about: Maybe<Scalars['String']>;
  additional_info: Maybe<Scalars['String']>;
  company_profile_id: Maybe<Scalars['Int']>;
  contract_length: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  degree_requirement: Maybe<Scalars['String']>;
  family: Maybe<Scalars['String']>;
  folder: Maybe<Scalars['String']>;
  hiring_manager: Maybe<Scalars['Int']>;
  hiring_manager_email: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  initial_headcount: Maybe<Scalars['Int']>;
  jobid: Maybe<Scalars['String']>;
  jobtitle: Maybe<Scalars['String']>;
  level: Maybe<Scalars['Int']>;
  level_type: Maybe<Scalars['String']>;
  max_contract_rate: Maybe<Scalars['numeric']>;
  max_salary: Maybe<Scalars['numeric']>;
  min_contract_rate: Maybe<Scalars['numeric']>;
  min_salary: Maybe<Scalars['numeric']>;
  nice_to_have: Maybe<Scalars['String']>;
  notes: Maybe<Scalars['String']>;
  pause_additional_candidates: Maybe<Scalars['String']>;
  people_ops: Maybe<Scalars['Int']>;
  position_detail_type: Maybe<Scalars['String']>;
  posting: Maybe<Scalars['Int']>;
  profile_id: Maybe<Scalars['Int']>;
  recruiter: Maybe<Scalars['Int']>;
  required_skill_1: Maybe<Scalars['Int']>;
  required_skill_2: Maybe<Scalars['Int']>;
  required_skill_3: Maybe<Scalars['Int']>;
  secondary_sourcer: Maybe<Scalars['Int']>;
  secondaryrecruiter: Maybe<Scalars['Int']>;
  sourcer: Maybe<Scalars['Int']>;
  tech_stack: Maybe<Scalars['String']>;
  what_you_bring: Maybe<Scalars['String']>;
  what_youll_do: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "icims_job" */
export type Icims_Job_Max_Order_By = {
  about?: InputMaybe<Order_By>;
  additional_info?: InputMaybe<Order_By>;
  company_profile_id?: InputMaybe<Order_By>;
  contract_length?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  degree_requirement?: InputMaybe<Order_By>;
  family?: InputMaybe<Order_By>;
  folder?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<Order_By>;
  hiring_manager_email?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  jobid?: InputMaybe<Order_By>;
  jobtitle?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_type?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  nice_to_have?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  pause_additional_candidates?: InputMaybe<Order_By>;
  people_ops?: InputMaybe<Order_By>;
  position_detail_type?: InputMaybe<Order_By>;
  posting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Order_By>;
  required_skill_1?: InputMaybe<Order_By>;
  required_skill_2?: InputMaybe<Order_By>;
  required_skill_3?: InputMaybe<Order_By>;
  secondary_sourcer?: InputMaybe<Order_By>;
  secondaryrecruiter?: InputMaybe<Order_By>;
  sourcer?: InputMaybe<Order_By>;
  tech_stack?: InputMaybe<Order_By>;
  what_you_bring?: InputMaybe<Order_By>;
  what_youll_do?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Job_Min_Fields = {
  __typename?: 'icims_job_min_fields';
  about: Maybe<Scalars['String']>;
  additional_info: Maybe<Scalars['String']>;
  company_profile_id: Maybe<Scalars['Int']>;
  contract_length: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  degree_requirement: Maybe<Scalars['String']>;
  family: Maybe<Scalars['String']>;
  folder: Maybe<Scalars['String']>;
  hiring_manager: Maybe<Scalars['Int']>;
  hiring_manager_email: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  initial_headcount: Maybe<Scalars['Int']>;
  jobid: Maybe<Scalars['String']>;
  jobtitle: Maybe<Scalars['String']>;
  level: Maybe<Scalars['Int']>;
  level_type: Maybe<Scalars['String']>;
  max_contract_rate: Maybe<Scalars['numeric']>;
  max_salary: Maybe<Scalars['numeric']>;
  min_contract_rate: Maybe<Scalars['numeric']>;
  min_salary: Maybe<Scalars['numeric']>;
  nice_to_have: Maybe<Scalars['String']>;
  notes: Maybe<Scalars['String']>;
  pause_additional_candidates: Maybe<Scalars['String']>;
  people_ops: Maybe<Scalars['Int']>;
  position_detail_type: Maybe<Scalars['String']>;
  posting: Maybe<Scalars['Int']>;
  profile_id: Maybe<Scalars['Int']>;
  recruiter: Maybe<Scalars['Int']>;
  required_skill_1: Maybe<Scalars['Int']>;
  required_skill_2: Maybe<Scalars['Int']>;
  required_skill_3: Maybe<Scalars['Int']>;
  secondary_sourcer: Maybe<Scalars['Int']>;
  secondaryrecruiter: Maybe<Scalars['Int']>;
  sourcer: Maybe<Scalars['Int']>;
  tech_stack: Maybe<Scalars['String']>;
  what_you_bring: Maybe<Scalars['String']>;
  what_youll_do: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "icims_job" */
export type Icims_Job_Min_Order_By = {
  about?: InputMaybe<Order_By>;
  additional_info?: InputMaybe<Order_By>;
  company_profile_id?: InputMaybe<Order_By>;
  contract_length?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  degree_requirement?: InputMaybe<Order_By>;
  family?: InputMaybe<Order_By>;
  folder?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<Order_By>;
  hiring_manager_email?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  jobid?: InputMaybe<Order_By>;
  jobtitle?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_type?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  nice_to_have?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  pause_additional_candidates?: InputMaybe<Order_By>;
  people_ops?: InputMaybe<Order_By>;
  position_detail_type?: InputMaybe<Order_By>;
  posting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Order_By>;
  required_skill_1?: InputMaybe<Order_By>;
  required_skill_2?: InputMaybe<Order_By>;
  required_skill_3?: InputMaybe<Order_By>;
  secondary_sourcer?: InputMaybe<Order_By>;
  secondaryrecruiter?: InputMaybe<Order_By>;
  sourcer?: InputMaybe<Order_By>;
  tech_stack?: InputMaybe<Order_By>;
  what_you_bring?: InputMaybe<Order_By>;
  what_youll_do?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_job" */
export type Icims_Job_Mutation_Response = {
  __typename?: 'icims_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Job>;
};

/** input type for inserting object relation for remote table "icims_job" */
export type Icims_Job_Obj_Rel_Insert_Input = {
  data: Icims_Job_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Job_On_Conflict>;
};

/** on_conflict condition type for table "icims_job" */
export type Icims_Job_On_Conflict = {
  constraint: Icims_Job_Constraint;
  update_columns?: Array<Icims_Job_Update_Column>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_job". */
export type Icims_Job_Order_By = {
  about?: InputMaybe<Order_By>;
  additional_info?: InputMaybe<Order_By>;
  candidate_curation_workflows_aggregate?: InputMaybe<Candidate_Curation_Workflow_Aggregate_Order_By>;
  company_profile_id?: InputMaybe<Order_By>;
  contract_length?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  degree_requirement?: InputMaybe<Order_By>;
  family?: InputMaybe<Order_By>;
  folder?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<Order_By>;
  hiring_manager_email?: InputMaybe<Order_By>;
  icims_applicant_workflows_aggregate?: InputMaybe<Icims_Applicant_Workflow_Aggregate_Order_By>;
  icims_company?: InputMaybe<Icims_Company_Order_By>;
  icims_folder?: InputMaybe<Icims_Folder_Order_By>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Order_By>;
  icims_job_locations_aggregate?: InputMaybe<Icims_Job_Location_Aggregate_Order_By>;
  icims_job_positions_aggregate?: InputMaybe<Icims_Job_Aggregate_Order_By>;
  icims_job_posting?: InputMaybe<Icims_Job_Order_By>;
  icims_job_requested_locations_aggregate?: InputMaybe<Icims_Job_Requested_Location_Aggregate_Order_By>;
  icims_job_skills_aggregate?: InputMaybe<Icims_Job_Skill_Aggregate_Order_By>;
  icims_person?: InputMaybe<Icims_Person_Order_By>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  is_position?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  jobid?: InputMaybe<Order_By>;
  jobtitle?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_type?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  nice_to_have?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  open_to_international?: InputMaybe<Order_By>;
  pause_additional_candidates?: InputMaybe<Order_By>;
  peopleOpsById?: InputMaybe<Icims_Person_Order_By>;
  people_ops?: InputMaybe<Order_By>;
  position_detail_type?: InputMaybe<Order_By>;
  posting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Order_By>;
  recruiterById?: InputMaybe<Icims_Person_Order_By>;
  required_skill_1?: InputMaybe<Order_By>;
  required_skill_2?: InputMaybe<Order_By>;
  required_skill_3?: InputMaybe<Order_By>;
  secondaryRecruiterById?: InputMaybe<Icims_Person_Order_By>;
  secondarySourcerById?: InputMaybe<Icims_Person_Order_By>;
  secondary_sourcer?: InputMaybe<Order_By>;
  secondaryrecruiter?: InputMaybe<Order_By>;
  skillByRequiredSkill1?: InputMaybe<Skill_Order_By>;
  skillByRequiredSkill2?: InputMaybe<Skill_Order_By>;
  skillByRequiredSkill3?: InputMaybe<Skill_Order_By>;
  sourcer?: InputMaybe<Order_By>;
  sourcerById?: InputMaybe<Icims_Person_Order_By>;
  tech_stack?: InputMaybe<Order_By>;
  what_you_bring?: InputMaybe<Order_By>;
  what_youll_do?: InputMaybe<Order_By>;
  years_experience?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_job */
export type Icims_Job_Pk_Columns_Input = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Icims_Job_Prepend_Input = {
  years_experience?: InputMaybe<Scalars['jsonb']>;
};

/** iCIMS field28054 */
export type Icims_Job_Requested_Location = {
  __typename?: 'icims_job_requested_location';
  /** An object relationship */
  icims_job: Maybe<Icims_Job>;
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
  location: Scalars['String'];
};

/** aggregated selection of "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Aggregate = {
  __typename?: 'icims_job_requested_location_aggregate';
  aggregate: Maybe<Icims_Job_Requested_Location_Aggregate_Fields>;
  nodes: Array<Icims_Job_Requested_Location>;
};

export type Icims_Job_Requested_Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<Icims_Job_Requested_Location_Aggregate_Bool_Exp_Count>;
};

export type Icims_Job_Requested_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Job_Requested_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Aggregate_Fields = {
  __typename?: 'icims_job_requested_location_aggregate_fields';
  avg: Maybe<Icims_Job_Requested_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Job_Requested_Location_Max_Fields>;
  min: Maybe<Icims_Job_Requested_Location_Min_Fields>;
  stddev: Maybe<Icims_Job_Requested_Location_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Job_Requested_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Job_Requested_Location_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Job_Requested_Location_Sum_Fields>;
  var_pop: Maybe<Icims_Job_Requested_Location_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Job_Requested_Location_Var_Samp_Fields>;
  variance: Maybe<Icims_Job_Requested_Location_Variance_Fields>;
};

/** aggregate fields of "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Job_Requested_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Job_Requested_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Job_Requested_Location_Max_Order_By>;
  min?: InputMaybe<Icims_Job_Requested_Location_Min_Order_By>;
  stddev?: InputMaybe<Icims_Job_Requested_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Job_Requested_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Job_Requested_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Job_Requested_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Job_Requested_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Job_Requested_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Job_Requested_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Arr_Rel_Insert_Input = {
  data: Array<Icims_Job_Requested_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Job_Requested_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Job_Requested_Location_Avg_Fields = {
  __typename?: 'icims_job_requested_location_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Avg_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_job_requested_location". All fields are combined with a logical 'AND'. */
export type Icims_Job_Requested_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Job_Requested_Location_Bool_Exp>>;
  _not?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Job_Requested_Location_Bool_Exp>>;
  icims_job?: InputMaybe<Icims_Job_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  job_profile_id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_job_requested_location" */
export enum Icims_Job_Requested_Location_Constraint {
  /** unique or primary key constraint on columns "job_profile_id", "initial_data_transfer", "location" */
  IcimsJobRequestedLocationPkey = 'icims_job_requested_location_pkey',
}

/** input type for incrementing numeric columns in table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Insert_Input = {
  icims_job?: InputMaybe<Icims_Job_Obj_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Job_Requested_Location_Max_Fields = {
  __typename?: 'icims_job_requested_location_max_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  location: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Max_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Job_Requested_Location_Min_Fields = {
  __typename?: 'icims_job_requested_location_min_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  location: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Min_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Mutation_Response = {
  __typename?: 'icims_job_requested_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Job_Requested_Location>;
};

/** on_conflict condition type for table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_On_Conflict = {
  constraint: Icims_Job_Requested_Location_Constraint;
  update_columns?: Array<Icims_Job_Requested_Location_Update_Column>;
  where?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_job_requested_location". */
export type Icims_Job_Requested_Location_Order_By = {
  icims_job?: InputMaybe<Icims_Job_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_job_requested_location */
export type Icims_Job_Requested_Location_Pk_Columns_Input = {
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
  location: Scalars['String'];
};

/** select columns of table "icims_job_requested_location" */
export enum Icims_Job_Requested_Location_Select_Column {
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  JobProfileId = 'job_profile_id',
  /** column name */
  Location = 'location',
}

/** input type for updating data in table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Set_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Job_Requested_Location_Stddev_Fields = {
  __typename?: 'icims_job_requested_location_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Stddev_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Job_Requested_Location_Stddev_Pop_Fields = {
  __typename?: 'icims_job_requested_location_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Stddev_Pop_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Job_Requested_Location_Stddev_Samp_Fields = {
  __typename?: 'icims_job_requested_location_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Stddev_Samp_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Job_Requested_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Job_Requested_Location_Stream_Cursor_Value_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Job_Requested_Location_Sum_Fields = {
  __typename?: 'icims_job_requested_location_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Sum_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** update columns of table "icims_job_requested_location" */
export enum Icims_Job_Requested_Location_Update_Column {
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  JobProfileId = 'job_profile_id',
  /** column name */
  Location = 'location',
}

export type Icims_Job_Requested_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Job_Requested_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Job_Requested_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Job_Requested_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Job_Requested_Location_Var_Pop_Fields = {
  __typename?: 'icims_job_requested_location_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Var_Pop_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Job_Requested_Location_Var_Samp_Fields = {
  __typename?: 'icims_job_requested_location_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Var_Samp_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Job_Requested_Location_Variance_Fields = {
  __typename?: 'icims_job_requested_location_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_job_requested_location" */
export type Icims_Job_Requested_Location_Variance_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** select columns of table "icims_job" */
export enum Icims_Job_Select_Column {
  /** column name */
  About = 'about',
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CompanyProfileId = 'company_profile_id',
  /** column name */
  ContractLength = 'contract_length',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DegreeRequirement = 'degree_requirement',
  /** column name */
  Family = 'family',
  /** column name */
  Folder = 'folder',
  /** column name */
  HiringManager = 'hiring_manager',
  /** column name */
  HiringManagerEmail = 'hiring_manager_email',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  InitialHeadcount = 'initial_headcount',
  /** column name */
  IsPosition = 'is_position',
  /** column name */
  Jobid = 'jobid',
  /** column name */
  Jobtitle = 'jobtitle',
  /** column name */
  Level = 'level',
  /** column name */
  LevelType = 'level_type',
  /** column name */
  MaxContractRate = 'max_contract_rate',
  /** column name */
  MaxSalary = 'max_salary',
  /** column name */
  MinContractRate = 'min_contract_rate',
  /** column name */
  MinSalary = 'min_salary',
  /** column name */
  NiceToHave = 'nice_to_have',
  /** column name */
  Notes = 'notes',
  /** column name */
  OpenToInternational = 'open_to_international',
  /** column name */
  PauseAdditionalCandidates = 'pause_additional_candidates',
  /** column name */
  PeopleOps = 'people_ops',
  /** column name */
  PositionDetailType = 'position_detail_type',
  /** column name */
  Posting = 'posting',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Recruiter = 'recruiter',
  /** column name */
  RequiredSkill_1 = 'required_skill_1',
  /** column name */
  RequiredSkill_2 = 'required_skill_2',
  /** column name */
  RequiredSkill_3 = 'required_skill_3',
  /** column name */
  SecondarySourcer = 'secondary_sourcer',
  /** column name */
  Secondaryrecruiter = 'secondaryrecruiter',
  /** column name */
  Sourcer = 'sourcer',
  /** column name */
  TechStack = 'tech_stack',
  /** column name */
  WhatYouBring = 'what_you_bring',
  /** column name */
  WhatYoullDo = 'what_youll_do',
  /** column name */
  YearsExperience = 'years_experience',
}

/** select "icims_job_aggregate_bool_exp_bool_and_arguments_columns" columns of table "icims_job" */
export enum Icims_Job_Select_Column_Icims_Job_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPosition = 'is_position',
  /** column name */
  OpenToInternational = 'open_to_international',
}

/** select "icims_job_aggregate_bool_exp_bool_or_arguments_columns" columns of table "icims_job" */
export enum Icims_Job_Select_Column_Icims_Job_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPosition = 'is_position',
  /** column name */
  OpenToInternational = 'open_to_international',
}

/** input type for updating data in table "icims_job" */
export type Icims_Job_Set_Input = {
  about?: InputMaybe<Scalars['String']>;
  additional_info?: InputMaybe<Scalars['String']>;
  company_profile_id?: InputMaybe<Scalars['Int']>;
  contract_length?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  degree_requirement?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['String']>;
  hiring_manager?: InputMaybe<Scalars['Int']>;
  hiring_manager_email?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  initial_headcount?: InputMaybe<Scalars['Int']>;
  is_position?: InputMaybe<Scalars['Boolean']>;
  jobid?: InputMaybe<Scalars['String']>;
  jobtitle?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  level_type?: InputMaybe<Scalars['String']>;
  max_contract_rate?: InputMaybe<Scalars['numeric']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  min_contract_rate?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
  nice_to_have?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  open_to_international?: InputMaybe<Scalars['Boolean']>;
  pause_additional_candidates?: InputMaybe<Scalars['String']>;
  people_ops?: InputMaybe<Scalars['Int']>;
  position_detail_type?: InputMaybe<Scalars['String']>;
  posting?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  recruiter?: InputMaybe<Scalars['Int']>;
  required_skill_1?: InputMaybe<Scalars['Int']>;
  required_skill_2?: InputMaybe<Scalars['Int']>;
  required_skill_3?: InputMaybe<Scalars['Int']>;
  secondary_sourcer?: InputMaybe<Scalars['Int']>;
  secondaryrecruiter?: InputMaybe<Scalars['Int']>;
  sourcer?: InputMaybe<Scalars['Int']>;
  tech_stack?: InputMaybe<Scalars['String']>;
  what_you_bring?: InputMaybe<Scalars['String']>;
  what_youll_do?: InputMaybe<Scalars['String']>;
  years_experience?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "icims_job_skill" */
export type Icims_Job_Skill = {
  __typename?: 'icims_job_skill';
  entry: Scalars['Int'];
  /** An object relationship */
  icims_job: Maybe<Icims_Job>;
  /** An object relationship */
  icims_skill: Maybe<Icims_Skill>;
  icims_skill_id: Maybe<Scalars['String']>;
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
  skill_level: Maybe<Scalars['String']>;
};

/** aggregated selection of "icims_job_skill" */
export type Icims_Job_Skill_Aggregate = {
  __typename?: 'icims_job_skill_aggregate';
  aggregate: Maybe<Icims_Job_Skill_Aggregate_Fields>;
  nodes: Array<Icims_Job_Skill>;
};

export type Icims_Job_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Icims_Job_Skill_Aggregate_Bool_Exp_Count>;
};

export type Icims_Job_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Job_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_job_skill" */
export type Icims_Job_Skill_Aggregate_Fields = {
  __typename?: 'icims_job_skill_aggregate_fields';
  avg: Maybe<Icims_Job_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Job_Skill_Max_Fields>;
  min: Maybe<Icims_Job_Skill_Min_Fields>;
  stddev: Maybe<Icims_Job_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Job_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Job_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Job_Skill_Sum_Fields>;
  var_pop: Maybe<Icims_Job_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Job_Skill_Var_Samp_Fields>;
  variance: Maybe<Icims_Job_Skill_Variance_Fields>;
};

/** aggregate fields of "icims_job_skill" */
export type Icims_Job_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Job_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_job_skill" */
export type Icims_Job_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Job_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Job_Skill_Max_Order_By>;
  min?: InputMaybe<Icims_Job_Skill_Min_Order_By>;
  stddev?: InputMaybe<Icims_Job_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Job_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Job_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Job_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Job_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Job_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Job_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "icims_job_skill" */
export type Icims_Job_Skill_Arr_Rel_Insert_Input = {
  data: Array<Icims_Job_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Job_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Job_Skill_Avg_Fields = {
  __typename?: 'icims_job_skill_avg_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_job_skill" */
export type Icims_Job_Skill_Avg_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_job_skill". All fields are combined with a logical 'AND'. */
export type Icims_Job_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Job_Skill_Bool_Exp>>;
  _not?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Job_Skill_Bool_Exp>>;
  entry?: InputMaybe<Int_Comparison_Exp>;
  icims_job?: InputMaybe<Icims_Job_Bool_Exp>;
  icims_skill?: InputMaybe<Icims_Skill_Bool_Exp>;
  icims_skill_id?: InputMaybe<String_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  job_profile_id?: InputMaybe<Int_Comparison_Exp>;
  skill_level?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_job_skill" */
export enum Icims_Job_Skill_Constraint {
  /** unique or primary key constraint on columns "job_profile_id", "initial_data_transfer", "entry" */
  IcimsJobSkillPkey = 'icims_job_skill_pkey',
}

/** input type for incrementing numeric columns in table "icims_job_skill" */
export type Icims_Job_Skill_Inc_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_job_skill" */
export type Icims_Job_Skill_Insert_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  icims_job?: InputMaybe<Icims_Job_Obj_Rel_Insert_Input>;
  icims_skill?: InputMaybe<Icims_Skill_Obj_Rel_Insert_Input>;
  icims_skill_id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  skill_level?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Job_Skill_Max_Fields = {
  __typename?: 'icims_job_skill_max_fields';
  entry: Maybe<Scalars['Int']>;
  icims_skill_id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  skill_level: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "icims_job_skill" */
export type Icims_Job_Skill_Max_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_skill_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  skill_level?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Job_Skill_Min_Fields = {
  __typename?: 'icims_job_skill_min_fields';
  entry: Maybe<Scalars['Int']>;
  icims_skill_id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
  skill_level: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "icims_job_skill" */
export type Icims_Job_Skill_Min_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_skill_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  skill_level?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_job_skill" */
export type Icims_Job_Skill_Mutation_Response = {
  __typename?: 'icims_job_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Job_Skill>;
};

/** on_conflict condition type for table "icims_job_skill" */
export type Icims_Job_Skill_On_Conflict = {
  constraint: Icims_Job_Skill_Constraint;
  update_columns?: Array<Icims_Job_Skill_Update_Column>;
  where?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_job_skill". */
export type Icims_Job_Skill_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_job?: InputMaybe<Icims_Job_Order_By>;
  icims_skill?: InputMaybe<Icims_Skill_Order_By>;
  icims_skill_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
  skill_level?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_job_skill */
export type Icims_Job_Skill_Pk_Columns_Input = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
};

/** select columns of table "icims_job_skill" */
export enum Icims_Job_Skill_Select_Column {
  /** column name */
  Entry = 'entry',
  /** column name */
  IcimsSkillId = 'icims_skill_id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  JobProfileId = 'job_profile_id',
  /** column name */
  SkillLevel = 'skill_level',
}

/** input type for updating data in table "icims_job_skill" */
export type Icims_Job_Skill_Set_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  icims_skill_id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  skill_level?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Job_Skill_Stddev_Fields = {
  __typename?: 'icims_job_skill_stddev_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_job_skill" */
export type Icims_Job_Skill_Stddev_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Job_Skill_Stddev_Pop_Fields = {
  __typename?: 'icims_job_skill_stddev_pop_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_job_skill" */
export type Icims_Job_Skill_Stddev_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Job_Skill_Stddev_Samp_Fields = {
  __typename?: 'icims_job_skill_stddev_samp_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_job_skill" */
export type Icims_Job_Skill_Stddev_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_job_skill" */
export type Icims_Job_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Job_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Job_Skill_Stream_Cursor_Value_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  icims_skill_id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
  skill_level?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Job_Skill_Sum_Fields = {
  __typename?: 'icims_job_skill_sum_fields';
  entry: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  job_profile_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_job_skill" */
export type Icims_Job_Skill_Sum_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** update columns of table "icims_job_skill" */
export enum Icims_Job_Skill_Update_Column {
  /** column name */
  Entry = 'entry',
  /** column name */
  IcimsSkillId = 'icims_skill_id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  JobProfileId = 'job_profile_id',
  /** column name */
  SkillLevel = 'skill_level',
}

export type Icims_Job_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Job_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Job_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Job_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Job_Skill_Var_Pop_Fields = {
  __typename?: 'icims_job_skill_var_pop_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_job_skill" */
export type Icims_Job_Skill_Var_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Job_Skill_Var_Samp_Fields = {
  __typename?: 'icims_job_skill_var_samp_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_job_skill" */
export type Icims_Job_Skill_Var_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Job_Skill_Variance_Fields = {
  __typename?: 'icims_job_skill_variance_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  job_profile_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_job_skill" */
export type Icims_Job_Skill_Variance_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Icims_Job_Stddev_Fields = {
  __typename?: 'icims_job_stddev_fields';
  company_profile_id: Maybe<Scalars['Float']>;
  contract_length: Maybe<Scalars['Float']>;
  hiring_manager: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  level: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  people_ops: Maybe<Scalars['Float']>;
  posting: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  recruiter: Maybe<Scalars['Float']>;
  required_skill_1: Maybe<Scalars['Float']>;
  required_skill_2: Maybe<Scalars['Float']>;
  required_skill_3: Maybe<Scalars['Float']>;
  secondary_sourcer: Maybe<Scalars['Float']>;
  secondaryrecruiter: Maybe<Scalars['Float']>;
  sourcer: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_job" */
export type Icims_Job_Stddev_Order_By = {
  company_profile_id?: InputMaybe<Order_By>;
  contract_length?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  people_ops?: InputMaybe<Order_By>;
  posting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Order_By>;
  required_skill_1?: InputMaybe<Order_By>;
  required_skill_2?: InputMaybe<Order_By>;
  required_skill_3?: InputMaybe<Order_By>;
  secondary_sourcer?: InputMaybe<Order_By>;
  secondaryrecruiter?: InputMaybe<Order_By>;
  sourcer?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Job_Stddev_Pop_Fields = {
  __typename?: 'icims_job_stddev_pop_fields';
  company_profile_id: Maybe<Scalars['Float']>;
  contract_length: Maybe<Scalars['Float']>;
  hiring_manager: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  level: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  people_ops: Maybe<Scalars['Float']>;
  posting: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  recruiter: Maybe<Scalars['Float']>;
  required_skill_1: Maybe<Scalars['Float']>;
  required_skill_2: Maybe<Scalars['Float']>;
  required_skill_3: Maybe<Scalars['Float']>;
  secondary_sourcer: Maybe<Scalars['Float']>;
  secondaryrecruiter: Maybe<Scalars['Float']>;
  sourcer: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_job" */
export type Icims_Job_Stddev_Pop_Order_By = {
  company_profile_id?: InputMaybe<Order_By>;
  contract_length?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  people_ops?: InputMaybe<Order_By>;
  posting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Order_By>;
  required_skill_1?: InputMaybe<Order_By>;
  required_skill_2?: InputMaybe<Order_By>;
  required_skill_3?: InputMaybe<Order_By>;
  secondary_sourcer?: InputMaybe<Order_By>;
  secondaryrecruiter?: InputMaybe<Order_By>;
  sourcer?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Job_Stddev_Samp_Fields = {
  __typename?: 'icims_job_stddev_samp_fields';
  company_profile_id: Maybe<Scalars['Float']>;
  contract_length: Maybe<Scalars['Float']>;
  hiring_manager: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  level: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  people_ops: Maybe<Scalars['Float']>;
  posting: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  recruiter: Maybe<Scalars['Float']>;
  required_skill_1: Maybe<Scalars['Float']>;
  required_skill_2: Maybe<Scalars['Float']>;
  required_skill_3: Maybe<Scalars['Float']>;
  secondary_sourcer: Maybe<Scalars['Float']>;
  secondaryrecruiter: Maybe<Scalars['Float']>;
  sourcer: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_job" */
export type Icims_Job_Stddev_Samp_Order_By = {
  company_profile_id?: InputMaybe<Order_By>;
  contract_length?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  people_ops?: InputMaybe<Order_By>;
  posting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Order_By>;
  required_skill_1?: InputMaybe<Order_By>;
  required_skill_2?: InputMaybe<Order_By>;
  required_skill_3?: InputMaybe<Order_By>;
  secondary_sourcer?: InputMaybe<Order_By>;
  secondaryrecruiter?: InputMaybe<Order_By>;
  sourcer?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_job" */
export type Icims_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Job_Stream_Cursor_Value_Input = {
  about?: InputMaybe<Scalars['String']>;
  additional_info?: InputMaybe<Scalars['String']>;
  company_profile_id?: InputMaybe<Scalars['Int']>;
  contract_length?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  degree_requirement?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['String']>;
  hiring_manager?: InputMaybe<Scalars['Int']>;
  hiring_manager_email?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  initial_headcount?: InputMaybe<Scalars['Int']>;
  is_position?: InputMaybe<Scalars['Boolean']>;
  jobid?: InputMaybe<Scalars['String']>;
  jobtitle?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  level_type?: InputMaybe<Scalars['String']>;
  max_contract_rate?: InputMaybe<Scalars['numeric']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  min_contract_rate?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
  nice_to_have?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  open_to_international?: InputMaybe<Scalars['Boolean']>;
  pause_additional_candidates?: InputMaybe<Scalars['String']>;
  people_ops?: InputMaybe<Scalars['Int']>;
  position_detail_type?: InputMaybe<Scalars['String']>;
  posting?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  recruiter?: InputMaybe<Scalars['Int']>;
  required_skill_1?: InputMaybe<Scalars['Int']>;
  required_skill_2?: InputMaybe<Scalars['Int']>;
  required_skill_3?: InputMaybe<Scalars['Int']>;
  secondary_sourcer?: InputMaybe<Scalars['Int']>;
  secondaryrecruiter?: InputMaybe<Scalars['Int']>;
  sourcer?: InputMaybe<Scalars['Int']>;
  tech_stack?: InputMaybe<Scalars['String']>;
  what_you_bring?: InputMaybe<Scalars['String']>;
  what_youll_do?: InputMaybe<Scalars['String']>;
  years_experience?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Icims_Job_Sum_Fields = {
  __typename?: 'icims_job_sum_fields';
  company_profile_id: Maybe<Scalars['Int']>;
  contract_length: Maybe<Scalars['Int']>;
  hiring_manager: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  initial_headcount: Maybe<Scalars['Int']>;
  level: Maybe<Scalars['Int']>;
  max_contract_rate: Maybe<Scalars['numeric']>;
  max_salary: Maybe<Scalars['numeric']>;
  min_contract_rate: Maybe<Scalars['numeric']>;
  min_salary: Maybe<Scalars['numeric']>;
  people_ops: Maybe<Scalars['Int']>;
  posting: Maybe<Scalars['Int']>;
  profile_id: Maybe<Scalars['Int']>;
  recruiter: Maybe<Scalars['Int']>;
  required_skill_1: Maybe<Scalars['Int']>;
  required_skill_2: Maybe<Scalars['Int']>;
  required_skill_3: Maybe<Scalars['Int']>;
  secondary_sourcer: Maybe<Scalars['Int']>;
  secondaryrecruiter: Maybe<Scalars['Int']>;
  sourcer: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_job" */
export type Icims_Job_Sum_Order_By = {
  company_profile_id?: InputMaybe<Order_By>;
  contract_length?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  people_ops?: InputMaybe<Order_By>;
  posting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Order_By>;
  required_skill_1?: InputMaybe<Order_By>;
  required_skill_2?: InputMaybe<Order_By>;
  required_skill_3?: InputMaybe<Order_By>;
  secondary_sourcer?: InputMaybe<Order_By>;
  secondaryrecruiter?: InputMaybe<Order_By>;
  sourcer?: InputMaybe<Order_By>;
};

/** update columns of table "icims_job" */
export enum Icims_Job_Update_Column {
  /** column name */
  About = 'about',
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CompanyProfileId = 'company_profile_id',
  /** column name */
  ContractLength = 'contract_length',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DegreeRequirement = 'degree_requirement',
  /** column name */
  Family = 'family',
  /** column name */
  Folder = 'folder',
  /** column name */
  HiringManager = 'hiring_manager',
  /** column name */
  HiringManagerEmail = 'hiring_manager_email',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  InitialHeadcount = 'initial_headcount',
  /** column name */
  IsPosition = 'is_position',
  /** column name */
  Jobid = 'jobid',
  /** column name */
  Jobtitle = 'jobtitle',
  /** column name */
  Level = 'level',
  /** column name */
  LevelType = 'level_type',
  /** column name */
  MaxContractRate = 'max_contract_rate',
  /** column name */
  MaxSalary = 'max_salary',
  /** column name */
  MinContractRate = 'min_contract_rate',
  /** column name */
  MinSalary = 'min_salary',
  /** column name */
  NiceToHave = 'nice_to_have',
  /** column name */
  Notes = 'notes',
  /** column name */
  OpenToInternational = 'open_to_international',
  /** column name */
  PauseAdditionalCandidates = 'pause_additional_candidates',
  /** column name */
  PeopleOps = 'people_ops',
  /** column name */
  PositionDetailType = 'position_detail_type',
  /** column name */
  Posting = 'posting',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Recruiter = 'recruiter',
  /** column name */
  RequiredSkill_1 = 'required_skill_1',
  /** column name */
  RequiredSkill_2 = 'required_skill_2',
  /** column name */
  RequiredSkill_3 = 'required_skill_3',
  /** column name */
  SecondarySourcer = 'secondary_sourcer',
  /** column name */
  Secondaryrecruiter = 'secondaryrecruiter',
  /** column name */
  Sourcer = 'sourcer',
  /** column name */
  TechStack = 'tech_stack',
  /** column name */
  WhatYouBring = 'what_you_bring',
  /** column name */
  WhatYoullDo = 'what_youll_do',
  /** column name */
  YearsExperience = 'years_experience',
}

export type Icims_Job_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Icims_Job_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Icims_Job_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Icims_Job_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Icims_Job_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Job_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Icims_Job_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Job_Var_Pop_Fields = {
  __typename?: 'icims_job_var_pop_fields';
  company_profile_id: Maybe<Scalars['Float']>;
  contract_length: Maybe<Scalars['Float']>;
  hiring_manager: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  level: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  people_ops: Maybe<Scalars['Float']>;
  posting: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  recruiter: Maybe<Scalars['Float']>;
  required_skill_1: Maybe<Scalars['Float']>;
  required_skill_2: Maybe<Scalars['Float']>;
  required_skill_3: Maybe<Scalars['Float']>;
  secondary_sourcer: Maybe<Scalars['Float']>;
  secondaryrecruiter: Maybe<Scalars['Float']>;
  sourcer: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_job" */
export type Icims_Job_Var_Pop_Order_By = {
  company_profile_id?: InputMaybe<Order_By>;
  contract_length?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  people_ops?: InputMaybe<Order_By>;
  posting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Order_By>;
  required_skill_1?: InputMaybe<Order_By>;
  required_skill_2?: InputMaybe<Order_By>;
  required_skill_3?: InputMaybe<Order_By>;
  secondary_sourcer?: InputMaybe<Order_By>;
  secondaryrecruiter?: InputMaybe<Order_By>;
  sourcer?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Job_Var_Samp_Fields = {
  __typename?: 'icims_job_var_samp_fields';
  company_profile_id: Maybe<Scalars['Float']>;
  contract_length: Maybe<Scalars['Float']>;
  hiring_manager: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  level: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  people_ops: Maybe<Scalars['Float']>;
  posting: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  recruiter: Maybe<Scalars['Float']>;
  required_skill_1: Maybe<Scalars['Float']>;
  required_skill_2: Maybe<Scalars['Float']>;
  required_skill_3: Maybe<Scalars['Float']>;
  secondary_sourcer: Maybe<Scalars['Float']>;
  secondaryrecruiter: Maybe<Scalars['Float']>;
  sourcer: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_job" */
export type Icims_Job_Var_Samp_Order_By = {
  company_profile_id?: InputMaybe<Order_By>;
  contract_length?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  people_ops?: InputMaybe<Order_By>;
  posting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Order_By>;
  required_skill_1?: InputMaybe<Order_By>;
  required_skill_2?: InputMaybe<Order_By>;
  required_skill_3?: InputMaybe<Order_By>;
  secondary_sourcer?: InputMaybe<Order_By>;
  secondaryrecruiter?: InputMaybe<Order_By>;
  sourcer?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Job_Variance_Fields = {
  __typename?: 'icims_job_variance_fields';
  company_profile_id: Maybe<Scalars['Float']>;
  contract_length: Maybe<Scalars['Float']>;
  hiring_manager: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  level: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  people_ops: Maybe<Scalars['Float']>;
  posting: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
  recruiter: Maybe<Scalars['Float']>;
  required_skill_1: Maybe<Scalars['Float']>;
  required_skill_2: Maybe<Scalars['Float']>;
  required_skill_3: Maybe<Scalars['Float']>;
  secondary_sourcer: Maybe<Scalars['Float']>;
  secondaryrecruiter: Maybe<Scalars['Float']>;
  sourcer: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_job" */
export type Icims_Job_Variance_Order_By = {
  company_profile_id?: InputMaybe<Order_By>;
  contract_length?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  people_ops?: InputMaybe<Order_By>;
  posting?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Order_By>;
  required_skill_1?: InputMaybe<Order_By>;
  required_skill_2?: InputMaybe<Order_By>;
  required_skill_3?: InputMaybe<Order_By>;
  secondary_sourcer?: InputMaybe<Order_By>;
  secondaryrecruiter?: InputMaybe<Order_By>;
  sourcer?: InputMaybe<Order_By>;
};

/** iCIMS additionallocations */
export type Icims_Location = {
  __typename?: 'icims_location';
  company: Maybe<Scalars['Int']>;
  /** An object relationship */
  icims_initial_data_transfer: Maybe<Icims_Initial_Data_Transfer>;
  /** An array relationship */
  icims_job_locations: Array<Icims_Job_Location>;
  /** An aggregate relationship */
  icims_job_locations_aggregate: Icims_Job_Location_Aggregate;
  id: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  /** An array relationship */
  locations: Array<Location>;
  /** An aggregate relationship */
  locations_aggregate: Location_Aggregate;
  value: Maybe<Scalars['String']>;
};

/** iCIMS additionallocations */
export type Icims_LocationIcims_Job_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Location_Bool_Exp>;
};

/** iCIMS additionallocations */
export type Icims_LocationIcims_Job_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Location_Bool_Exp>;
};

/** iCIMS additionallocations */
export type Icims_LocationLocationsArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};

/** iCIMS additionallocations */
export type Icims_LocationLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};

/** aggregated selection of "icims_location" */
export type Icims_Location_Aggregate = {
  __typename?: 'icims_location_aggregate';
  aggregate: Maybe<Icims_Location_Aggregate_Fields>;
  nodes: Array<Icims_Location>;
};

/** aggregate fields of "icims_location" */
export type Icims_Location_Aggregate_Fields = {
  __typename?: 'icims_location_aggregate_fields';
  avg: Maybe<Icims_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Location_Max_Fields>;
  min: Maybe<Icims_Location_Min_Fields>;
  stddev: Maybe<Icims_Location_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Location_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Location_Sum_Fields>;
  var_pop: Maybe<Icims_Location_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Location_Var_Samp_Fields>;
  variance: Maybe<Icims_Location_Variance_Fields>;
};

/** aggregate fields of "icims_location" */
export type Icims_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Location_Avg_Fields = {
  __typename?: 'icims_location_avg_fields';
  company: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_location". All fields are combined with a logical 'AND'. */
export type Icims_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Location_Bool_Exp>>;
  _not?: InputMaybe<Icims_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Location_Bool_Exp>>;
  company?: InputMaybe<Int_Comparison_Exp>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
  icims_job_locations?: InputMaybe<Icims_Job_Location_Bool_Exp>;
  icims_job_locations_aggregate?: InputMaybe<Icims_Job_Location_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  locations?: InputMaybe<Location_Bool_Exp>;
  locations_aggregate?: InputMaybe<Location_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_location" */
export enum Icims_Location_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "id" */
  IcimsLocationPkey = 'icims_location_pkey',
}

/** input type for incrementing numeric columns in table "icims_location" */
export type Icims_Location_Inc_Input = {
  company?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_location" */
export type Icims_Location_Insert_Input = {
  company?: InputMaybe<Scalars['Int']>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Obj_Rel_Insert_Input>;
  icims_job_locations?: InputMaybe<Icims_Job_Location_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  locations?: InputMaybe<Location_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Location_Max_Fields = {
  __typename?: 'icims_location_max_fields';
  company: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Location_Min_Fields = {
  __typename?: 'icims_location_min_fields';
  company: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_location" */
export type Icims_Location_Mutation_Response = {
  __typename?: 'icims_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Location>;
};

/** input type for inserting object relation for remote table "icims_location" */
export type Icims_Location_Obj_Rel_Insert_Input = {
  data: Icims_Location_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Location_On_Conflict>;
};

/** on_conflict condition type for table "icims_location" */
export type Icims_Location_On_Conflict = {
  constraint: Icims_Location_Constraint;
  update_columns?: Array<Icims_Location_Update_Column>;
  where?: InputMaybe<Icims_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_location". */
export type Icims_Location_Order_By = {
  company?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Order_By>;
  icims_job_locations_aggregate?: InputMaybe<Icims_Job_Location_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  locations_aggregate?: InputMaybe<Location_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_location */
export type Icims_Location_Pk_Columns_Input = {
  id: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
};

/** select columns of table "icims_location" */
export enum Icims_Location_Select_Column {
  /** column name */
  Company = 'company',
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "icims_location" */
export type Icims_Location_Set_Input = {
  company?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Location_Stddev_Fields = {
  __typename?: 'icims_location_stddev_fields';
  company: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Location_Stddev_Pop_Fields = {
  __typename?: 'icims_location_stddev_pop_fields';
  company: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Location_Stddev_Samp_Fields = {
  __typename?: 'icims_location_stddev_samp_fields';
  company: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_location" */
export type Icims_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Location_Stream_Cursor_Value_Input = {
  company?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Location_Sum_Fields = {
  __typename?: 'icims_location_sum_fields';
  company: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_location" */
export enum Icims_Location_Update_Column {
  /** column name */
  Company = 'company',
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

export type Icims_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Location_Var_Pop_Fields = {
  __typename?: 'icims_location_var_pop_fields';
  company: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Location_Var_Samp_Fields = {
  __typename?: 'icims_location_var_samp_fields';
  company: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Location_Variance_Fields = {
  __typename?: 'icims_location_variance_fields';
  company: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_major" */
export type Icims_Major = {
  __typename?: 'icims_major';
  /** An array relationship */
  icims_educations: Array<Icims_Education>;
  /** An aggregate relationship */
  icims_educations_aggregate: Icims_Education_Aggregate;
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
  value: Scalars['String'];
};

/** columns and relationships of "icims_major" */
export type Icims_MajorIcims_EducationsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Education_Order_By>>;
  where?: InputMaybe<Icims_Education_Bool_Exp>;
};

/** columns and relationships of "icims_major" */
export type Icims_MajorIcims_Educations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Education_Order_By>>;
  where?: InputMaybe<Icims_Education_Bool_Exp>;
};

/** aggregated selection of "icims_major" */
export type Icims_Major_Aggregate = {
  __typename?: 'icims_major_aggregate';
  aggregate: Maybe<Icims_Major_Aggregate_Fields>;
  nodes: Array<Icims_Major>;
};

/** aggregate fields of "icims_major" */
export type Icims_Major_Aggregate_Fields = {
  __typename?: 'icims_major_aggregate_fields';
  avg: Maybe<Icims_Major_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Major_Max_Fields>;
  min: Maybe<Icims_Major_Min_Fields>;
  stddev: Maybe<Icims_Major_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Major_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Major_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Major_Sum_Fields>;
  var_pop: Maybe<Icims_Major_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Major_Var_Samp_Fields>;
  variance: Maybe<Icims_Major_Variance_Fields>;
};

/** aggregate fields of "icims_major" */
export type Icims_Major_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Major_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Major_Avg_Fields = {
  __typename?: 'icims_major_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_major". All fields are combined with a logical 'AND'. */
export type Icims_Major_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Major_Bool_Exp>>;
  _not?: InputMaybe<Icims_Major_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Major_Bool_Exp>>;
  icims_educations?: InputMaybe<Icims_Education_Bool_Exp>;
  icims_educations_aggregate?: InputMaybe<Icims_Education_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_major" */
export enum Icims_Major_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "id" */
  IcimsMajorInitialDataTransferIdKey = 'icims_major_initial_data_transfer_id_key',
  /** unique or primary key constraint on columns "initial_data_transfer", "id" */
  IcimsMajorPkey = 'icims_major_pkey',
}

/** input type for incrementing numeric columns in table "icims_major" */
export type Icims_Major_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_major" */
export type Icims_Major_Insert_Input = {
  icims_educations?: InputMaybe<Icims_Education_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Major_Max_Fields = {
  __typename?: 'icims_major_max_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Major_Min_Fields = {
  __typename?: 'icims_major_min_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_major" */
export type Icims_Major_Mutation_Response = {
  __typename?: 'icims_major_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Major>;
};

/** input type for inserting object relation for remote table "icims_major" */
export type Icims_Major_Obj_Rel_Insert_Input = {
  data: Icims_Major_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Major_On_Conflict>;
};

/** on_conflict condition type for table "icims_major" */
export type Icims_Major_On_Conflict = {
  constraint: Icims_Major_Constraint;
  update_columns?: Array<Icims_Major_Update_Column>;
  where?: InputMaybe<Icims_Major_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_major". */
export type Icims_Major_Order_By = {
  icims_educations_aggregate?: InputMaybe<Icims_Education_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_major */
export type Icims_Major_Pk_Columns_Input = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** select columns of table "icims_major" */
export enum Icims_Major_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "icims_major" */
export type Icims_Major_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Major_Stddev_Fields = {
  __typename?: 'icims_major_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Major_Stddev_Pop_Fields = {
  __typename?: 'icims_major_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Major_Stddev_Samp_Fields = {
  __typename?: 'icims_major_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_major" */
export type Icims_Major_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Major_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Major_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Major_Sum_Fields = {
  __typename?: 'icims_major_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_major" */
export enum Icims_Major_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

export type Icims_Major_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Major_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Major_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Major_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Major_Var_Pop_Fields = {
  __typename?: 'icims_major_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Major_Var_Samp_Fields = {
  __typename?: 'icims_major_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Major_Variance_Fields = {
  __typename?: 'icims_major_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_person" */
export type Icims_Person = {
  __typename?: 'icims_person';
  availability: Maybe<Scalars['String']>;
  /** An object relationship */
  candidate: Maybe<Candidate>;
  desired_salary_amount: Maybe<Scalars['numeric']>;
  desired_salary_currency: Maybe<Scalars['String']>;
  desired_salary_timeframe: Maybe<Scalars['String']>;
  email: Maybe<Scalars['citext']>;
  english_proficiency: Maybe<Scalars['String']>;
  equity_importance: Maybe<Scalars['String']>;
  expected_total_compensation: Maybe<Scalars['Int']>;
  firstname: Maybe<Scalars['String']>;
  github_profile_url: Maybe<Scalars['String']>;
  has_resume: Scalars['Boolean'];
  /** An array relationship */
  icims_applicant_workflows: Array<Icims_Applicant_Workflow>;
  /** An aggregate relationship */
  icims_applicant_workflows_aggregate: Icims_Applicant_Workflow_Aggregate;
  /** An array relationship */
  icims_educations: Array<Icims_Education>;
  /** An aggregate relationship */
  icims_educations_aggregate: Icims_Education_Aggregate;
  /** An object relationship */
  icims_initial_data_transfer: Icims_Initial_Data_Transfer;
  /** An array relationship */
  icims_person_interested_locations: Array<Icims_Person_Interested_Location>;
  /** An aggregate relationship */
  icims_person_interested_locations_aggregate: Icims_Person_Interested_Location_Aggregate;
  /** An array relationship */
  icims_person_preferred_locations: Array<Icims_Person_Preferred_Location>;
  /** An aggregate relationship */
  icims_person_preferred_locations_aggregate: Icims_Person_Preferred_Location_Aggregate;
  /** An array relationship */
  icims_person_skills: Array<Icims_Person_Skill>;
  /** An aggregate relationship */
  icims_person_skills_aggregate: Icims_Person_Skill_Aggregate;
  /** An object relationship */
  icims_profile_person: Icims_Profile_Person;
  /** An array relationship */
  icims_work_experiences: Array<Icims_Work_Experience>;
  /** An aggregate relationship */
  icims_work_experiences_aggregate: Icims_Work_Experience_Aggregate;
  initial_data_transfer: Scalars['Int'];
  is_talent_partner: Scalars['Boolean'];
  lastname: Maybe<Scalars['String']>;
  lastname_initial: Maybe<Scalars['String']>;
  linkedin_profile_url: Maybe<Scalars['String']>;
  /** An array relationship */
  owned_candidates: Array<Candidate>;
  /** An aggregate relationship */
  owned_candidates_aggregate: Candidate_Aggregate;
  profile_id: Scalars['Int'];
  remote_readiness: Maybe<Scalars['String']>;
  socialaccounturl: Maybe<Scalars['String']>;
  top_skill_1: Maybe<Scalars['String']>;
  top_skill_2: Maybe<Scalars['String']>;
  top_skill_3: Maybe<Scalars['String']>;
  years_experience: Maybe<Scalars['String']>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_Applicant_WorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_Applicant_Workflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_EducationsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Education_Order_By>>;
  where?: InputMaybe<Icims_Education_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_Educations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Education_Order_By>>;
  where?: InputMaybe<Icims_Education_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_Person_Interested_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_Person_Interested_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_Person_Preferred_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Preferred_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Preferred_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Preferred_Location_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_Person_Preferred_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Preferred_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Preferred_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Preferred_Location_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_Person_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Skill_Order_By>>;
  where?: InputMaybe<Icims_Person_Skill_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_Person_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Skill_Order_By>>;
  where?: InputMaybe<Icims_Person_Skill_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_Work_ExperiencesArgs = {
  distinct_on?: InputMaybe<Array<Icims_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Work_Experience_Order_By>>;
  where?: InputMaybe<Icims_Work_Experience_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonIcims_Work_Experiences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Work_Experience_Order_By>>;
  where?: InputMaybe<Icims_Work_Experience_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonOwned_CandidatesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Order_By>>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

/** columns and relationships of "icims_person" */
export type Icims_PersonOwned_Candidates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Order_By>>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

/** aggregated selection of "icims_person" */
export type Icims_Person_Aggregate = {
  __typename?: 'icims_person_aggregate';
  aggregate: Maybe<Icims_Person_Aggregate_Fields>;
  nodes: Array<Icims_Person>;
};

export type Icims_Person_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Icims_Person_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Icims_Person_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Icims_Person_Aggregate_Bool_Exp_Count>;
};

export type Icims_Person_Aggregate_Bool_Exp_Bool_And = {
  arguments: Icims_Person_Select_Column_Icims_Person_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Person_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Icims_Person_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Icims_Person_Select_Column_Icims_Person_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Person_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Icims_Person_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Person_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Person_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_person" */
export type Icims_Person_Aggregate_Fields = {
  __typename?: 'icims_person_aggregate_fields';
  avg: Maybe<Icims_Person_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Person_Max_Fields>;
  min: Maybe<Icims_Person_Min_Fields>;
  stddev: Maybe<Icims_Person_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Person_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Person_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Person_Sum_Fields>;
  var_pop: Maybe<Icims_Person_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Person_Var_Samp_Fields>;
  variance: Maybe<Icims_Person_Variance_Fields>;
};

/** aggregate fields of "icims_person" */
export type Icims_Person_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Person_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_person" */
export type Icims_Person_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Person_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Person_Max_Order_By>;
  min?: InputMaybe<Icims_Person_Min_Order_By>;
  stddev?: InputMaybe<Icims_Person_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Person_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Person_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Person_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Person_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Person_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Person_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "icims_person" */
export type Icims_Person_Arr_Rel_Insert_Input = {
  data: Array<Icims_Person_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Person_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Person_Avg_Fields = {
  __typename?: 'icims_person_avg_fields';
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_compensation: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_person" */
export type Icims_Person_Avg_Order_By = {
  desired_salary_amount?: InputMaybe<Order_By>;
  expected_total_compensation?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_person". All fields are combined with a logical 'AND'. */
export type Icims_Person_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Person_Bool_Exp>>;
  _not?: InputMaybe<Icims_Person_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Person_Bool_Exp>>;
  availability?: InputMaybe<String_Comparison_Exp>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  desired_salary_amount?: InputMaybe<Numeric_Comparison_Exp>;
  desired_salary_currency?: InputMaybe<String_Comparison_Exp>;
  desired_salary_timeframe?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  english_proficiency?: InputMaybe<String_Comparison_Exp>;
  equity_importance?: InputMaybe<String_Comparison_Exp>;
  expected_total_compensation?: InputMaybe<Int_Comparison_Exp>;
  firstname?: InputMaybe<String_Comparison_Exp>;
  github_profile_url?: InputMaybe<String_Comparison_Exp>;
  has_resume?: InputMaybe<Boolean_Comparison_Exp>;
  icims_applicant_workflows?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
  icims_applicant_workflows_aggregate?: InputMaybe<Icims_Applicant_Workflow_Aggregate_Bool_Exp>;
  icims_educations?: InputMaybe<Icims_Education_Bool_Exp>;
  icims_educations_aggregate?: InputMaybe<Icims_Education_Aggregate_Bool_Exp>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
  icims_person_interested_locations?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
  icims_person_interested_locations_aggregate?: InputMaybe<Icims_Person_Interested_Location_Aggregate_Bool_Exp>;
  icims_person_preferred_locations?: InputMaybe<Icims_Person_Preferred_Location_Bool_Exp>;
  icims_person_preferred_locations_aggregate?: InputMaybe<Icims_Person_Preferred_Location_Aggregate_Bool_Exp>;
  icims_person_skills?: InputMaybe<Icims_Person_Skill_Bool_Exp>;
  icims_person_skills_aggregate?: InputMaybe<Icims_Person_Skill_Aggregate_Bool_Exp>;
  icims_profile_person?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
  icims_work_experiences?: InputMaybe<Icims_Work_Experience_Bool_Exp>;
  icims_work_experiences_aggregate?: InputMaybe<Icims_Work_Experience_Aggregate_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  is_talent_partner?: InputMaybe<Boolean_Comparison_Exp>;
  lastname?: InputMaybe<String_Comparison_Exp>;
  lastname_initial?: InputMaybe<String_Comparison_Exp>;
  linkedin_profile_url?: InputMaybe<String_Comparison_Exp>;
  owned_candidates?: InputMaybe<Candidate_Bool_Exp>;
  owned_candidates_aggregate?: InputMaybe<Candidate_Aggregate_Bool_Exp>;
  profile_id?: InputMaybe<Int_Comparison_Exp>;
  remote_readiness?: InputMaybe<String_Comparison_Exp>;
  socialaccounturl?: InputMaybe<String_Comparison_Exp>;
  top_skill_1?: InputMaybe<String_Comparison_Exp>;
  top_skill_2?: InputMaybe<String_Comparison_Exp>;
  top_skill_3?: InputMaybe<String_Comparison_Exp>;
  years_experience?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_person" */
export enum Icims_Person_Constraint {
  /** unique or primary key constraint on columns "profile_id", "initial_data_transfer" */
  IcimsPersonPkey = 'icims_person_pkey',
}

/** input type for incrementing numeric columns in table "icims_person" */
export type Icims_Person_Inc_Input = {
  desired_salary_amount?: InputMaybe<Scalars['numeric']>;
  expected_total_compensation?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_person" */
export type Icims_Person_Insert_Input = {
  availability?: InputMaybe<Scalars['String']>;
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  desired_salary_amount?: InputMaybe<Scalars['numeric']>;
  desired_salary_currency?: InputMaybe<Scalars['String']>;
  desired_salary_timeframe?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  english_proficiency?: InputMaybe<Scalars['String']>;
  equity_importance?: InputMaybe<Scalars['String']>;
  expected_total_compensation?: InputMaybe<Scalars['Int']>;
  firstname?: InputMaybe<Scalars['String']>;
  github_profile_url?: InputMaybe<Scalars['String']>;
  has_resume?: InputMaybe<Scalars['Boolean']>;
  icims_applicant_workflows?: InputMaybe<Icims_Applicant_Workflow_Arr_Rel_Insert_Input>;
  icims_educations?: InputMaybe<Icims_Education_Arr_Rel_Insert_Input>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Obj_Rel_Insert_Input>;
  icims_person_interested_locations?: InputMaybe<Icims_Person_Interested_Location_Arr_Rel_Insert_Input>;
  icims_person_preferred_locations?: InputMaybe<Icims_Person_Preferred_Location_Arr_Rel_Insert_Input>;
  icims_person_skills?: InputMaybe<Icims_Person_Skill_Arr_Rel_Insert_Input>;
  icims_profile_person?: InputMaybe<Icims_Profile_Person_Obj_Rel_Insert_Input>;
  icims_work_experiences?: InputMaybe<Icims_Work_Experience_Arr_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  is_talent_partner?: InputMaybe<Scalars['Boolean']>;
  lastname?: InputMaybe<Scalars['String']>;
  linkedin_profile_url?: InputMaybe<Scalars['String']>;
  owned_candidates?: InputMaybe<Candidate_Arr_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['Int']>;
  remote_readiness?: InputMaybe<Scalars['String']>;
  socialaccounturl?: InputMaybe<Scalars['String']>;
  top_skill_1?: InputMaybe<Scalars['String']>;
  top_skill_2?: InputMaybe<Scalars['String']>;
  top_skill_3?: InputMaybe<Scalars['String']>;
  years_experience?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "icims_person_interested_location" */
export type Icims_Person_Interested_Location = {
  __typename?: 'icims_person_interested_location';
  /** An object relationship */
  icims_interested_location: Maybe<Icims_Interested_Location>;
  /** An object relationship */
  icims_person: Maybe<Icims_Person>;
  initial_data_transfer: Scalars['Int'];
  interested_location: Scalars['String'];
  person_profile_id: Scalars['Int'];
};

/** aggregated selection of "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Aggregate = {
  __typename?: 'icims_person_interested_location_aggregate';
  aggregate: Maybe<Icims_Person_Interested_Location_Aggregate_Fields>;
  nodes: Array<Icims_Person_Interested_Location>;
};

export type Icims_Person_Interested_Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<Icims_Person_Interested_Location_Aggregate_Bool_Exp_Count>;
};

export type Icims_Person_Interested_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Person_Interested_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Aggregate_Fields = {
  __typename?: 'icims_person_interested_location_aggregate_fields';
  avg: Maybe<Icims_Person_Interested_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Person_Interested_Location_Max_Fields>;
  min: Maybe<Icims_Person_Interested_Location_Min_Fields>;
  stddev: Maybe<Icims_Person_Interested_Location_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Person_Interested_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Person_Interested_Location_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Person_Interested_Location_Sum_Fields>;
  var_pop: Maybe<Icims_Person_Interested_Location_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Person_Interested_Location_Var_Samp_Fields>;
  variance: Maybe<Icims_Person_Interested_Location_Variance_Fields>;
};

/** aggregate fields of "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Person_Interested_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Person_Interested_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Person_Interested_Location_Max_Order_By>;
  min?: InputMaybe<Icims_Person_Interested_Location_Min_Order_By>;
  stddev?: InputMaybe<Icims_Person_Interested_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Person_Interested_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Person_Interested_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Person_Interested_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Person_Interested_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Person_Interested_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Person_Interested_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Arr_Rel_Insert_Input = {
  data: Array<Icims_Person_Interested_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Person_Interested_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Person_Interested_Location_Avg_Fields = {
  __typename?: 'icims_person_interested_location_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Avg_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_person_interested_location". All fields are combined with a logical 'AND'. */
export type Icims_Person_Interested_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Person_Interested_Location_Bool_Exp>>;
  _not?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Person_Interested_Location_Bool_Exp>>;
  icims_interested_location?: InputMaybe<Icims_Interested_Location_Bool_Exp>;
  icims_person?: InputMaybe<Icims_Person_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  interested_location?: InputMaybe<String_Comparison_Exp>;
  person_profile_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_person_interested_location" */
export enum Icims_Person_Interested_Location_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "interested_location", "person_profile_id" */
  IcimsPersonInterestedLocationPkey = 'icims_person_interested_location_pkey',
}

/** input type for incrementing numeric columns in table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Insert_Input = {
  icims_interested_location?: InputMaybe<Icims_Interested_Location_Obj_Rel_Insert_Input>;
  icims_person?: InputMaybe<Icims_Person_Obj_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  interested_location?: InputMaybe<Scalars['String']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Icims_Person_Interested_Location_Max_Fields = {
  __typename?: 'icims_person_interested_location_max_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  interested_location: Maybe<Scalars['String']>;
  person_profile_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Max_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  interested_location?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Person_Interested_Location_Min_Fields = {
  __typename?: 'icims_person_interested_location_min_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  interested_location: Maybe<Scalars['String']>;
  person_profile_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Min_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  interested_location?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Mutation_Response = {
  __typename?: 'icims_person_interested_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Person_Interested_Location>;
};

/** on_conflict condition type for table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_On_Conflict = {
  constraint: Icims_Person_Interested_Location_Constraint;
  update_columns?: Array<Icims_Person_Interested_Location_Update_Column>;
  where?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_person_interested_location". */
export type Icims_Person_Interested_Location_Order_By = {
  icims_interested_location?: InputMaybe<Icims_Interested_Location_Order_By>;
  icims_person?: InputMaybe<Icims_Person_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  interested_location?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_person_interested_location */
export type Icims_Person_Interested_Location_Pk_Columns_Input = {
  initial_data_transfer: Scalars['Int'];
  interested_location: Scalars['String'];
  person_profile_id: Scalars['Int'];
};

/** select columns of table "icims_person_interested_location" */
export enum Icims_Person_Interested_Location_Select_Column {
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  InterestedLocation = 'interested_location',
  /** column name */
  PersonProfileId = 'person_profile_id',
}

/** input type for updating data in table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Set_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  interested_location?: InputMaybe<Scalars['String']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Icims_Person_Interested_Location_Stddev_Fields = {
  __typename?: 'icims_person_interested_location_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Stddev_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Person_Interested_Location_Stddev_Pop_Fields = {
  __typename?: 'icims_person_interested_location_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Stddev_Pop_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Person_Interested_Location_Stddev_Samp_Fields = {
  __typename?: 'icims_person_interested_location_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Stddev_Samp_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Person_Interested_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Person_Interested_Location_Stream_Cursor_Value_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  interested_location?: InputMaybe<Scalars['String']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Icims_Person_Interested_Location_Sum_Fields = {
  __typename?: 'icims_person_interested_location_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Sum_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** update columns of table "icims_person_interested_location" */
export enum Icims_Person_Interested_Location_Update_Column {
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  InterestedLocation = 'interested_location',
  /** column name */
  PersonProfileId = 'person_profile_id',
}

export type Icims_Person_Interested_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Person_Interested_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Person_Interested_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Person_Interested_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Person_Interested_Location_Var_Pop_Fields = {
  __typename?: 'icims_person_interested_location_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Var_Pop_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Person_Interested_Location_Var_Samp_Fields = {
  __typename?: 'icims_person_interested_location_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Var_Samp_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Person_Interested_Location_Variance_Fields = {
  __typename?: 'icims_person_interested_location_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_person_interested_location" */
export type Icims_Person_Interested_Location_Variance_Order_By = {
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Icims_Person_Max_Fields = {
  __typename?: 'icims_person_max_fields';
  availability: Maybe<Scalars['String']>;
  desired_salary_amount: Maybe<Scalars['numeric']>;
  desired_salary_currency: Maybe<Scalars['String']>;
  desired_salary_timeframe: Maybe<Scalars['String']>;
  email: Maybe<Scalars['citext']>;
  english_proficiency: Maybe<Scalars['String']>;
  equity_importance: Maybe<Scalars['String']>;
  expected_total_compensation: Maybe<Scalars['Int']>;
  firstname: Maybe<Scalars['String']>;
  github_profile_url: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  lastname: Maybe<Scalars['String']>;
  lastname_initial: Maybe<Scalars['String']>;
  linkedin_profile_url: Maybe<Scalars['String']>;
  profile_id: Maybe<Scalars['Int']>;
  remote_readiness: Maybe<Scalars['String']>;
  socialaccounturl: Maybe<Scalars['String']>;
  top_skill_1: Maybe<Scalars['String']>;
  top_skill_2: Maybe<Scalars['String']>;
  top_skill_3: Maybe<Scalars['String']>;
  years_experience: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "icims_person" */
export type Icims_Person_Max_Order_By = {
  availability?: InputMaybe<Order_By>;
  desired_salary_amount?: InputMaybe<Order_By>;
  desired_salary_currency?: InputMaybe<Order_By>;
  desired_salary_timeframe?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  english_proficiency?: InputMaybe<Order_By>;
  equity_importance?: InputMaybe<Order_By>;
  expected_total_compensation?: InputMaybe<Order_By>;
  firstname?: InputMaybe<Order_By>;
  github_profile_url?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  lastname?: InputMaybe<Order_By>;
  lastname_initial?: InputMaybe<Order_By>;
  linkedin_profile_url?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  remote_readiness?: InputMaybe<Order_By>;
  socialaccounturl?: InputMaybe<Order_By>;
  top_skill_1?: InputMaybe<Order_By>;
  top_skill_2?: InputMaybe<Order_By>;
  top_skill_3?: InputMaybe<Order_By>;
  years_experience?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Person_Min_Fields = {
  __typename?: 'icims_person_min_fields';
  availability: Maybe<Scalars['String']>;
  desired_salary_amount: Maybe<Scalars['numeric']>;
  desired_salary_currency: Maybe<Scalars['String']>;
  desired_salary_timeframe: Maybe<Scalars['String']>;
  email: Maybe<Scalars['citext']>;
  english_proficiency: Maybe<Scalars['String']>;
  equity_importance: Maybe<Scalars['String']>;
  expected_total_compensation: Maybe<Scalars['Int']>;
  firstname: Maybe<Scalars['String']>;
  github_profile_url: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  lastname: Maybe<Scalars['String']>;
  lastname_initial: Maybe<Scalars['String']>;
  linkedin_profile_url: Maybe<Scalars['String']>;
  profile_id: Maybe<Scalars['Int']>;
  remote_readiness: Maybe<Scalars['String']>;
  socialaccounturl: Maybe<Scalars['String']>;
  top_skill_1: Maybe<Scalars['String']>;
  top_skill_2: Maybe<Scalars['String']>;
  top_skill_3: Maybe<Scalars['String']>;
  years_experience: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "icims_person" */
export type Icims_Person_Min_Order_By = {
  availability?: InputMaybe<Order_By>;
  desired_salary_amount?: InputMaybe<Order_By>;
  desired_salary_currency?: InputMaybe<Order_By>;
  desired_salary_timeframe?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  english_proficiency?: InputMaybe<Order_By>;
  equity_importance?: InputMaybe<Order_By>;
  expected_total_compensation?: InputMaybe<Order_By>;
  firstname?: InputMaybe<Order_By>;
  github_profile_url?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  lastname?: InputMaybe<Order_By>;
  lastname_initial?: InputMaybe<Order_By>;
  linkedin_profile_url?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  remote_readiness?: InputMaybe<Order_By>;
  socialaccounturl?: InputMaybe<Order_By>;
  top_skill_1?: InputMaybe<Order_By>;
  top_skill_2?: InputMaybe<Order_By>;
  top_skill_3?: InputMaybe<Order_By>;
  years_experience?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_person" */
export type Icims_Person_Mutation_Response = {
  __typename?: 'icims_person_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Person>;
};

/** input type for inserting object relation for remote table "icims_person" */
export type Icims_Person_Obj_Rel_Insert_Input = {
  data: Icims_Person_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Person_On_Conflict>;
};

/** on_conflict condition type for table "icims_person" */
export type Icims_Person_On_Conflict = {
  constraint: Icims_Person_Constraint;
  update_columns?: Array<Icims_Person_Update_Column>;
  where?: InputMaybe<Icims_Person_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_person". */
export type Icims_Person_Order_By = {
  availability?: InputMaybe<Order_By>;
  candidate?: InputMaybe<Candidate_Order_By>;
  desired_salary_amount?: InputMaybe<Order_By>;
  desired_salary_currency?: InputMaybe<Order_By>;
  desired_salary_timeframe?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  english_proficiency?: InputMaybe<Order_By>;
  equity_importance?: InputMaybe<Order_By>;
  expected_total_compensation?: InputMaybe<Order_By>;
  firstname?: InputMaybe<Order_By>;
  github_profile_url?: InputMaybe<Order_By>;
  has_resume?: InputMaybe<Order_By>;
  icims_applicant_workflows_aggregate?: InputMaybe<Icims_Applicant_Workflow_Aggregate_Order_By>;
  icims_educations_aggregate?: InputMaybe<Icims_Education_Aggregate_Order_By>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Order_By>;
  icims_person_interested_locations_aggregate?: InputMaybe<Icims_Person_Interested_Location_Aggregate_Order_By>;
  icims_person_preferred_locations_aggregate?: InputMaybe<Icims_Person_Preferred_Location_Aggregate_Order_By>;
  icims_person_skills_aggregate?: InputMaybe<Icims_Person_Skill_Aggregate_Order_By>;
  icims_profile_person?: InputMaybe<Icims_Profile_Person_Order_By>;
  icims_work_experiences_aggregate?: InputMaybe<Icims_Work_Experience_Aggregate_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  is_talent_partner?: InputMaybe<Order_By>;
  lastname?: InputMaybe<Order_By>;
  lastname_initial?: InputMaybe<Order_By>;
  linkedin_profile_url?: InputMaybe<Order_By>;
  owned_candidates_aggregate?: InputMaybe<Candidate_Aggregate_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  remote_readiness?: InputMaybe<Order_By>;
  socialaccounturl?: InputMaybe<Order_By>;
  top_skill_1?: InputMaybe<Order_By>;
  top_skill_2?: InputMaybe<Order_By>;
  top_skill_3?: InputMaybe<Order_By>;
  years_experience?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_person */
export type Icims_Person_Pk_Columns_Input = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

/** columns and relationships of "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location = {
  __typename?: 'icims_person_preferred_location';
  entry: Scalars['Int'];
  /** An object relationship */
  icims_location: Maybe<Icims_Location>;
  icims_location_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  icims_person: Maybe<Icims_Person>;
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

/** aggregated selection of "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Aggregate = {
  __typename?: 'icims_person_preferred_location_aggregate';
  aggregate: Maybe<Icims_Person_Preferred_Location_Aggregate_Fields>;
  nodes: Array<Icims_Person_Preferred_Location>;
};

export type Icims_Person_Preferred_Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<Icims_Person_Preferred_Location_Aggregate_Bool_Exp_Count>;
};

export type Icims_Person_Preferred_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Person_Preferred_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Person_Preferred_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Aggregate_Fields = {
  __typename?: 'icims_person_preferred_location_aggregate_fields';
  avg: Maybe<Icims_Person_Preferred_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Person_Preferred_Location_Max_Fields>;
  min: Maybe<Icims_Person_Preferred_Location_Min_Fields>;
  stddev: Maybe<Icims_Person_Preferred_Location_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Person_Preferred_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Person_Preferred_Location_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Person_Preferred_Location_Sum_Fields>;
  var_pop: Maybe<Icims_Person_Preferred_Location_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Person_Preferred_Location_Var_Samp_Fields>;
  variance: Maybe<Icims_Person_Preferred_Location_Variance_Fields>;
};

/** aggregate fields of "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Person_Preferred_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Person_Preferred_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Person_Preferred_Location_Max_Order_By>;
  min?: InputMaybe<Icims_Person_Preferred_Location_Min_Order_By>;
  stddev?: InputMaybe<Icims_Person_Preferred_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Person_Preferred_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Person_Preferred_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Person_Preferred_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Person_Preferred_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Person_Preferred_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Person_Preferred_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Arr_Rel_Insert_Input = {
  data: Array<Icims_Person_Preferred_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Person_Preferred_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Person_Preferred_Location_Avg_Fields = {
  __typename?: 'icims_person_preferred_location_avg_fields';
  entry: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Avg_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_person_preferred_location". All fields are combined with a logical 'AND'. */
export type Icims_Person_Preferred_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Person_Preferred_Location_Bool_Exp>>;
  _not?: InputMaybe<Icims_Person_Preferred_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Person_Preferred_Location_Bool_Exp>>;
  entry?: InputMaybe<Int_Comparison_Exp>;
  icims_location?: InputMaybe<Icims_Location_Bool_Exp>;
  icims_location_id?: InputMaybe<Int_Comparison_Exp>;
  icims_person?: InputMaybe<Icims_Person_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  person_profile_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_person_preferred_location" */
export enum Icims_Person_Preferred_Location_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "entry", "person_profile_id" */
  IcimsPersonPreferredLocationPkey = 'icims_person_preferred_location_pkey',
}

/** input type for incrementing numeric columns in table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Inc_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  icims_location_id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Insert_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  icims_location?: InputMaybe<Icims_Location_Obj_Rel_Insert_Input>;
  icims_location_id?: InputMaybe<Scalars['Int']>;
  icims_person?: InputMaybe<Icims_Person_Obj_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Icims_Person_Preferred_Location_Max_Fields = {
  __typename?: 'icims_person_preferred_location_max_fields';
  entry: Maybe<Scalars['Int']>;
  icims_location_id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Max_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Person_Preferred_Location_Min_Fields = {
  __typename?: 'icims_person_preferred_location_min_fields';
  entry: Maybe<Scalars['Int']>;
  icims_location_id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Min_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Mutation_Response = {
  __typename?: 'icims_person_preferred_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Person_Preferred_Location>;
};

/** on_conflict condition type for table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_On_Conflict = {
  constraint: Icims_Person_Preferred_Location_Constraint;
  update_columns?: Array<Icims_Person_Preferred_Location_Update_Column>;
  where?: InputMaybe<Icims_Person_Preferred_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_person_preferred_location". */
export type Icims_Person_Preferred_Location_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_location?: InputMaybe<Icims_Location_Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_person?: InputMaybe<Icims_Person_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_person_preferred_location */
export type Icims_Person_Preferred_Location_Pk_Columns_Input = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

/** select columns of table "icims_person_preferred_location" */
export enum Icims_Person_Preferred_Location_Select_Column {
  /** column name */
  Entry = 'entry',
  /** column name */
  IcimsLocationId = 'icims_location_id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  PersonProfileId = 'person_profile_id',
}

/** input type for updating data in table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Set_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  icims_location_id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Icims_Person_Preferred_Location_Stddev_Fields = {
  __typename?: 'icims_person_preferred_location_stddev_fields';
  entry: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Stddev_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Person_Preferred_Location_Stddev_Pop_Fields = {
  __typename?: 'icims_person_preferred_location_stddev_pop_fields';
  entry: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Stddev_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Person_Preferred_Location_Stddev_Samp_Fields = {
  __typename?: 'icims_person_preferred_location_stddev_samp_fields';
  entry: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Stddev_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Person_Preferred_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Person_Preferred_Location_Stream_Cursor_Value_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  icims_location_id?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Icims_Person_Preferred_Location_Sum_Fields = {
  __typename?: 'icims_person_preferred_location_sum_fields';
  entry: Maybe<Scalars['Int']>;
  icims_location_id: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Sum_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** update columns of table "icims_person_preferred_location" */
export enum Icims_Person_Preferred_Location_Update_Column {
  /** column name */
  Entry = 'entry',
  /** column name */
  IcimsLocationId = 'icims_location_id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  PersonProfileId = 'person_profile_id',
}

export type Icims_Person_Preferred_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Person_Preferred_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Person_Preferred_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Person_Preferred_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Person_Preferred_Location_Var_Pop_Fields = {
  __typename?: 'icims_person_preferred_location_var_pop_fields';
  entry: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Var_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Person_Preferred_Location_Var_Samp_Fields = {
  __typename?: 'icims_person_preferred_location_var_samp_fields';
  entry: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Var_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Person_Preferred_Location_Variance_Fields = {
  __typename?: 'icims_person_preferred_location_variance_fields';
  entry: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_person_preferred_location" */
export type Icims_Person_Preferred_Location_Variance_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** select columns of table "icims_person" */
export enum Icims_Person_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  DesiredSalaryAmount = 'desired_salary_amount',
  /** column name */
  DesiredSalaryCurrency = 'desired_salary_currency',
  /** column name */
  DesiredSalaryTimeframe = 'desired_salary_timeframe',
  /** column name */
  Email = 'email',
  /** column name */
  EnglishProficiency = 'english_proficiency',
  /** column name */
  EquityImportance = 'equity_importance',
  /** column name */
  ExpectedTotalCompensation = 'expected_total_compensation',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  GithubProfileUrl = 'github_profile_url',
  /** column name */
  HasResume = 'has_resume',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  IsTalentPartner = 'is_talent_partner',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  LastnameInitial = 'lastname_initial',
  /** column name */
  LinkedinProfileUrl = 'linkedin_profile_url',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  RemoteReadiness = 'remote_readiness',
  /** column name */
  Socialaccounturl = 'socialaccounturl',
  /** column name */
  TopSkill_1 = 'top_skill_1',
  /** column name */
  TopSkill_2 = 'top_skill_2',
  /** column name */
  TopSkill_3 = 'top_skill_3',
  /** column name */
  YearsExperience = 'years_experience',
}

/** select "icims_person_aggregate_bool_exp_bool_and_arguments_columns" columns of table "icims_person" */
export enum Icims_Person_Select_Column_Icims_Person_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasResume = 'has_resume',
  /** column name */
  IsTalentPartner = 'is_talent_partner',
}

/** select "icims_person_aggregate_bool_exp_bool_or_arguments_columns" columns of table "icims_person" */
export enum Icims_Person_Select_Column_Icims_Person_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasResume = 'has_resume',
  /** column name */
  IsTalentPartner = 'is_talent_partner',
}

/** input type for updating data in table "icims_person" */
export type Icims_Person_Set_Input = {
  availability?: InputMaybe<Scalars['String']>;
  desired_salary_amount?: InputMaybe<Scalars['numeric']>;
  desired_salary_currency?: InputMaybe<Scalars['String']>;
  desired_salary_timeframe?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  english_proficiency?: InputMaybe<Scalars['String']>;
  equity_importance?: InputMaybe<Scalars['String']>;
  expected_total_compensation?: InputMaybe<Scalars['Int']>;
  firstname?: InputMaybe<Scalars['String']>;
  github_profile_url?: InputMaybe<Scalars['String']>;
  has_resume?: InputMaybe<Scalars['Boolean']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  is_talent_partner?: InputMaybe<Scalars['Boolean']>;
  lastname?: InputMaybe<Scalars['String']>;
  linkedin_profile_url?: InputMaybe<Scalars['String']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  remote_readiness?: InputMaybe<Scalars['String']>;
  socialaccounturl?: InputMaybe<Scalars['String']>;
  top_skill_1?: InputMaybe<Scalars['String']>;
  top_skill_2?: InputMaybe<Scalars['String']>;
  top_skill_3?: InputMaybe<Scalars['String']>;
  years_experience?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "icims_person_skill" */
export type Icims_Person_Skill = {
  __typename?: 'icims_person_skill';
  entry: Scalars['Int'];
  /** An object relationship */
  icims_skill: Maybe<Icims_Skill>;
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
  skill: Maybe<Scalars['String']>;
};

/** aggregated selection of "icims_person_skill" */
export type Icims_Person_Skill_Aggregate = {
  __typename?: 'icims_person_skill_aggregate';
  aggregate: Maybe<Icims_Person_Skill_Aggregate_Fields>;
  nodes: Array<Icims_Person_Skill>;
};

export type Icims_Person_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Icims_Person_Skill_Aggregate_Bool_Exp_Count>;
};

export type Icims_Person_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Person_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Person_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_person_skill" */
export type Icims_Person_Skill_Aggregate_Fields = {
  __typename?: 'icims_person_skill_aggregate_fields';
  avg: Maybe<Icims_Person_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Person_Skill_Max_Fields>;
  min: Maybe<Icims_Person_Skill_Min_Fields>;
  stddev: Maybe<Icims_Person_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Person_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Person_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Person_Skill_Sum_Fields>;
  var_pop: Maybe<Icims_Person_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Person_Skill_Var_Samp_Fields>;
  variance: Maybe<Icims_Person_Skill_Variance_Fields>;
};

/** aggregate fields of "icims_person_skill" */
export type Icims_Person_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Person_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_person_skill" */
export type Icims_Person_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Person_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Person_Skill_Max_Order_By>;
  min?: InputMaybe<Icims_Person_Skill_Min_Order_By>;
  stddev?: InputMaybe<Icims_Person_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Person_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Person_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Person_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Person_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Person_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Person_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "icims_person_skill" */
export type Icims_Person_Skill_Arr_Rel_Insert_Input = {
  data: Array<Icims_Person_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Person_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Person_Skill_Avg_Fields = {
  __typename?: 'icims_person_skill_avg_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_person_skill" */
export type Icims_Person_Skill_Avg_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_person_skill". All fields are combined with a logical 'AND'. */
export type Icims_Person_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Person_Skill_Bool_Exp>>;
  _not?: InputMaybe<Icims_Person_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Person_Skill_Bool_Exp>>;
  entry?: InputMaybe<Int_Comparison_Exp>;
  icims_skill?: InputMaybe<Icims_Skill_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  person_profile_id?: InputMaybe<Int_Comparison_Exp>;
  skill?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_person_skill" */
export enum Icims_Person_Skill_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "entry", "person_profile_id" */
  IcimsPersonSkillPkey = 'icims_person_skill_pkey',
}

/** input type for incrementing numeric columns in table "icims_person_skill" */
export type Icims_Person_Skill_Inc_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_person_skill" */
export type Icims_Person_Skill_Insert_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  icims_skill?: InputMaybe<Icims_Skill_Obj_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Person_Skill_Max_Fields = {
  __typename?: 'icims_person_skill_max_fields';
  entry: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
  skill: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "icims_person_skill" */
export type Icims_Person_Skill_Max_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Person_Skill_Min_Fields = {
  __typename?: 'icims_person_skill_min_fields';
  entry: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
  skill: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "icims_person_skill" */
export type Icims_Person_Skill_Min_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_person_skill" */
export type Icims_Person_Skill_Mutation_Response = {
  __typename?: 'icims_person_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Person_Skill>;
};

/** on_conflict condition type for table "icims_person_skill" */
export type Icims_Person_Skill_On_Conflict = {
  constraint: Icims_Person_Skill_Constraint;
  update_columns?: Array<Icims_Person_Skill_Update_Column>;
  where?: InputMaybe<Icims_Person_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_person_skill". */
export type Icims_Person_Skill_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_skill?: InputMaybe<Icims_Skill_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_person_skill */
export type Icims_Person_Skill_Pk_Columns_Input = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

/** select columns of table "icims_person_skill" */
export enum Icims_Person_Skill_Select_Column {
  /** column name */
  Entry = 'entry',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  PersonProfileId = 'person_profile_id',
  /** column name */
  Skill = 'skill',
}

/** input type for updating data in table "icims_person_skill" */
export type Icims_Person_Skill_Set_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Person_Skill_Stddev_Fields = {
  __typename?: 'icims_person_skill_stddev_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_person_skill" */
export type Icims_Person_Skill_Stddev_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Person_Skill_Stddev_Pop_Fields = {
  __typename?: 'icims_person_skill_stddev_pop_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_person_skill" */
export type Icims_Person_Skill_Stddev_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Person_Skill_Stddev_Samp_Fields = {
  __typename?: 'icims_person_skill_stddev_samp_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_person_skill" */
export type Icims_Person_Skill_Stddev_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_person_skill" */
export type Icims_Person_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Person_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Person_Skill_Stream_Cursor_Value_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Person_Skill_Sum_Fields = {
  __typename?: 'icims_person_skill_sum_fields';
  entry: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_person_skill" */
export type Icims_Person_Skill_Sum_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** update columns of table "icims_person_skill" */
export enum Icims_Person_Skill_Update_Column {
  /** column name */
  Entry = 'entry',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  PersonProfileId = 'person_profile_id',
  /** column name */
  Skill = 'skill',
}

export type Icims_Person_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Person_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Person_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Person_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Person_Skill_Var_Pop_Fields = {
  __typename?: 'icims_person_skill_var_pop_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_person_skill" */
export type Icims_Person_Skill_Var_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Person_Skill_Var_Samp_Fields = {
  __typename?: 'icims_person_skill_var_samp_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_person_skill" */
export type Icims_Person_Skill_Var_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Person_Skill_Variance_Fields = {
  __typename?: 'icims_person_skill_variance_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_person_skill" */
export type Icims_Person_Skill_Variance_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Icims_Person_Stddev_Fields = {
  __typename?: 'icims_person_stddev_fields';
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_compensation: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_person" */
export type Icims_Person_Stddev_Order_By = {
  desired_salary_amount?: InputMaybe<Order_By>;
  expected_total_compensation?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Person_Stddev_Pop_Fields = {
  __typename?: 'icims_person_stddev_pop_fields';
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_compensation: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_person" */
export type Icims_Person_Stddev_Pop_Order_By = {
  desired_salary_amount?: InputMaybe<Order_By>;
  expected_total_compensation?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Person_Stddev_Samp_Fields = {
  __typename?: 'icims_person_stddev_samp_fields';
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_compensation: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_person" */
export type Icims_Person_Stddev_Samp_Order_By = {
  desired_salary_amount?: InputMaybe<Order_By>;
  expected_total_compensation?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_person" */
export type Icims_Person_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Person_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Person_Stream_Cursor_Value_Input = {
  availability?: InputMaybe<Scalars['String']>;
  desired_salary_amount?: InputMaybe<Scalars['numeric']>;
  desired_salary_currency?: InputMaybe<Scalars['String']>;
  desired_salary_timeframe?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  english_proficiency?: InputMaybe<Scalars['String']>;
  equity_importance?: InputMaybe<Scalars['String']>;
  expected_total_compensation?: InputMaybe<Scalars['Int']>;
  firstname?: InputMaybe<Scalars['String']>;
  github_profile_url?: InputMaybe<Scalars['String']>;
  has_resume?: InputMaybe<Scalars['Boolean']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  is_talent_partner?: InputMaybe<Scalars['Boolean']>;
  lastname?: InputMaybe<Scalars['String']>;
  lastname_initial?: InputMaybe<Scalars['String']>;
  linkedin_profile_url?: InputMaybe<Scalars['String']>;
  profile_id?: InputMaybe<Scalars['Int']>;
  remote_readiness?: InputMaybe<Scalars['String']>;
  socialaccounturl?: InputMaybe<Scalars['String']>;
  top_skill_1?: InputMaybe<Scalars['String']>;
  top_skill_2?: InputMaybe<Scalars['String']>;
  top_skill_3?: InputMaybe<Scalars['String']>;
  years_experience?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Person_Sum_Fields = {
  __typename?: 'icims_person_sum_fields';
  desired_salary_amount: Maybe<Scalars['numeric']>;
  expected_total_compensation: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  profile_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_person" */
export type Icims_Person_Sum_Order_By = {
  desired_salary_amount?: InputMaybe<Order_By>;
  expected_total_compensation?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** update columns of table "icims_person" */
export enum Icims_Person_Update_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  DesiredSalaryAmount = 'desired_salary_amount',
  /** column name */
  DesiredSalaryCurrency = 'desired_salary_currency',
  /** column name */
  DesiredSalaryTimeframe = 'desired_salary_timeframe',
  /** column name */
  Email = 'email',
  /** column name */
  EnglishProficiency = 'english_proficiency',
  /** column name */
  EquityImportance = 'equity_importance',
  /** column name */
  ExpectedTotalCompensation = 'expected_total_compensation',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  GithubProfileUrl = 'github_profile_url',
  /** column name */
  HasResume = 'has_resume',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  IsTalentPartner = 'is_talent_partner',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  LinkedinProfileUrl = 'linkedin_profile_url',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  RemoteReadiness = 'remote_readiness',
  /** column name */
  Socialaccounturl = 'socialaccounturl',
  /** column name */
  TopSkill_1 = 'top_skill_1',
  /** column name */
  TopSkill_2 = 'top_skill_2',
  /** column name */
  TopSkill_3 = 'top_skill_3',
  /** column name */
  YearsExperience = 'years_experience',
}

export type Icims_Person_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Person_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Person_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Person_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Person_Var_Pop_Fields = {
  __typename?: 'icims_person_var_pop_fields';
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_compensation: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_person" */
export type Icims_Person_Var_Pop_Order_By = {
  desired_salary_amount?: InputMaybe<Order_By>;
  expected_total_compensation?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Person_Var_Samp_Fields = {
  __typename?: 'icims_person_var_samp_fields';
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_compensation: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_person" */
export type Icims_Person_Var_Samp_Order_By = {
  desired_salary_amount?: InputMaybe<Order_By>;
  expected_total_compensation?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Person_Variance_Fields = {
  __typename?: 'icims_person_variance_fields';
  desired_salary_amount: Maybe<Scalars['Float']>;
  expected_total_compensation: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  profile_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_person" */
export type Icims_Person_Variance_Order_By = {
  desired_salary_amount?: InputMaybe<Order_By>;
  expected_total_compensation?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
};

/** Do not insert or update this table, it is auto-populated by the function insert_icims_profile_applicant_workflow. */
export type Icims_Profile_Applicant_Workflow = {
  __typename?: 'icims_profile_applicant_workflow';
  /** An array relationship */
  icims_applicant_workflows: Array<Icims_Applicant_Workflow>;
  /** An aggregate relationship */
  icims_applicant_workflows_aggregate: Icims_Applicant_Workflow_Aggregate;
  id: Scalars['Int'];
  /** An array relationship */
  interview_feedback_requests: Array<Interview_Feedback_Request>;
  /** An aggregate relationship */
  interview_feedback_requests_aggregate: Interview_Feedback_Request_Aggregate;
  /** An array relationship */
  interview_feedbacks: Array<Interview_Feedback>;
  /** An aggregate relationship */
  interview_feedbacks_aggregate: Interview_Feedback_Aggregate;
};

/** Do not insert or update this table, it is auto-populated by the function insert_icims_profile_applicant_workflow. */
export type Icims_Profile_Applicant_WorkflowIcims_Applicant_WorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

/** Do not insert or update this table, it is auto-populated by the function insert_icims_profile_applicant_workflow. */
export type Icims_Profile_Applicant_WorkflowIcims_Applicant_Workflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

/** Do not insert or update this table, it is auto-populated by the function insert_icims_profile_applicant_workflow. */
export type Icims_Profile_Applicant_WorkflowInterview_Feedback_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Request_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Request_Bool_Exp>;
};

/** Do not insert or update this table, it is auto-populated by the function insert_icims_profile_applicant_workflow. */
export type Icims_Profile_Applicant_WorkflowInterview_Feedback_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Request_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Request_Bool_Exp>;
};

/** Do not insert or update this table, it is auto-populated by the function insert_icims_profile_applicant_workflow. */
export type Icims_Profile_Applicant_WorkflowInterview_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Bool_Exp>;
};

/** Do not insert or update this table, it is auto-populated by the function insert_icims_profile_applicant_workflow. */
export type Icims_Profile_Applicant_WorkflowInterview_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Bool_Exp>;
};

/** aggregated selection of "icims_profile_applicant_workflow" */
export type Icims_Profile_Applicant_Workflow_Aggregate = {
  __typename?: 'icims_profile_applicant_workflow_aggregate';
  aggregate: Maybe<Icims_Profile_Applicant_Workflow_Aggregate_Fields>;
  nodes: Array<Icims_Profile_Applicant_Workflow>;
};

/** aggregate fields of "icims_profile_applicant_workflow" */
export type Icims_Profile_Applicant_Workflow_Aggregate_Fields = {
  __typename?: 'icims_profile_applicant_workflow_aggregate_fields';
  avg: Maybe<Icims_Profile_Applicant_Workflow_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Profile_Applicant_Workflow_Max_Fields>;
  min: Maybe<Icims_Profile_Applicant_Workflow_Min_Fields>;
  stddev: Maybe<Icims_Profile_Applicant_Workflow_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Profile_Applicant_Workflow_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Profile_Applicant_Workflow_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Profile_Applicant_Workflow_Sum_Fields>;
  var_pop: Maybe<Icims_Profile_Applicant_Workflow_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Profile_Applicant_Workflow_Var_Samp_Fields>;
  variance: Maybe<Icims_Profile_Applicant_Workflow_Variance_Fields>;
};

/** aggregate fields of "icims_profile_applicant_workflow" */
export type Icims_Profile_Applicant_Workflow_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Profile_Applicant_Workflow_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Profile_Applicant_Workflow_Avg_Fields = {
  __typename?: 'icims_profile_applicant_workflow_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_profile_applicant_workflow". All fields are combined with a logical 'AND'. */
export type Icims_Profile_Applicant_Workflow_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Profile_Applicant_Workflow_Bool_Exp>>;
  _not?: InputMaybe<Icims_Profile_Applicant_Workflow_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Profile_Applicant_Workflow_Bool_Exp>>;
  icims_applicant_workflows?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
  icims_applicant_workflows_aggregate?: InputMaybe<Icims_Applicant_Workflow_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interview_feedback_requests?: InputMaybe<Interview_Feedback_Request_Bool_Exp>;
  interview_feedback_requests_aggregate?: InputMaybe<Interview_Feedback_Request_Aggregate_Bool_Exp>;
  interview_feedbacks?: InputMaybe<Interview_Feedback_Bool_Exp>;
  interview_feedbacks_aggregate?: InputMaybe<Interview_Feedback_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "icims_profile_applicant_workflow" */
export enum Icims_Profile_Applicant_Workflow_Constraint {
  /** unique or primary key constraint on columns "id" */
  IcimsProfileApplicantWorkflowPkey = 'icims_profile_applicant_workflow_pkey',
}

/** input type for incrementing numeric columns in table "icims_profile_applicant_workflow" */
export type Icims_Profile_Applicant_Workflow_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_profile_applicant_workflow" */
export type Icims_Profile_Applicant_Workflow_Insert_Input = {
  icims_applicant_workflows?: InputMaybe<Icims_Applicant_Workflow_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  interview_feedback_requests?: InputMaybe<Interview_Feedback_Request_Arr_Rel_Insert_Input>;
  interview_feedbacks?: InputMaybe<Interview_Feedback_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Icims_Profile_Applicant_Workflow_Max_Fields = {
  __typename?: 'icims_profile_applicant_workflow_max_fields';
  id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Icims_Profile_Applicant_Workflow_Min_Fields = {
  __typename?: 'icims_profile_applicant_workflow_min_fields';
  id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "icims_profile_applicant_workflow" */
export type Icims_Profile_Applicant_Workflow_Mutation_Response = {
  __typename?: 'icims_profile_applicant_workflow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Profile_Applicant_Workflow>;
};

/** input type for inserting object relation for remote table "icims_profile_applicant_workflow" */
export type Icims_Profile_Applicant_Workflow_Obj_Rel_Insert_Input = {
  data: Icims_Profile_Applicant_Workflow_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Profile_Applicant_Workflow_On_Conflict>;
};

/** on_conflict condition type for table "icims_profile_applicant_workflow" */
export type Icims_Profile_Applicant_Workflow_On_Conflict = {
  constraint: Icims_Profile_Applicant_Workflow_Constraint;
  update_columns?: Array<Icims_Profile_Applicant_Workflow_Update_Column>;
  where?: InputMaybe<Icims_Profile_Applicant_Workflow_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_profile_applicant_workflow". */
export type Icims_Profile_Applicant_Workflow_Order_By = {
  icims_applicant_workflows_aggregate?: InputMaybe<Icims_Applicant_Workflow_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  interview_feedback_requests_aggregate?: InputMaybe<Interview_Feedback_Request_Aggregate_Order_By>;
  interview_feedbacks_aggregate?: InputMaybe<Interview_Feedback_Aggregate_Order_By>;
};

/** primary key columns input for table: icims_profile_applicant_workflow */
export type Icims_Profile_Applicant_Workflow_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "icims_profile_applicant_workflow" */
export enum Icims_Profile_Applicant_Workflow_Select_Column {
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "icims_profile_applicant_workflow" */
export type Icims_Profile_Applicant_Workflow_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Icims_Profile_Applicant_Workflow_Stddev_Fields = {
  __typename?: 'icims_profile_applicant_workflow_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Profile_Applicant_Workflow_Stddev_Pop_Fields = {
  __typename?: 'icims_profile_applicant_workflow_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Profile_Applicant_Workflow_Stddev_Samp_Fields = {
  __typename?: 'icims_profile_applicant_workflow_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_profile_applicant_workflow" */
export type Icims_Profile_Applicant_Workflow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Profile_Applicant_Workflow_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Profile_Applicant_Workflow_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Icims_Profile_Applicant_Workflow_Sum_Fields = {
  __typename?: 'icims_profile_applicant_workflow_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_profile_applicant_workflow" */
export enum Icims_Profile_Applicant_Workflow_Update_Column {
  /** column name */
  Id = 'id',
}

export type Icims_Profile_Applicant_Workflow_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Profile_Applicant_Workflow_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Profile_Applicant_Workflow_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Profile_Applicant_Workflow_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Profile_Applicant_Workflow_Var_Pop_Fields = {
  __typename?: 'icims_profile_applicant_workflow_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Profile_Applicant_Workflow_Var_Samp_Fields = {
  __typename?: 'icims_profile_applicant_workflow_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Profile_Applicant_Workflow_Variance_Fields = {
  __typename?: 'icims_profile_applicant_workflow_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_profile_job" */
export type Icims_Profile_Job = {
  __typename?: 'icims_profile_job';
  active_on_job_portal_at: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  applicant_jobs: Array<Applicant_Job>;
  /** An aggregate relationship */
  applicant_jobs_aggregate: Applicant_Job_Aggregate;
  /** An array relationship */
  candidate_jobs: Array<Candidate_Job>;
  /** An aggregate relationship */
  candidate_jobs_aggregate: Candidate_Job_Aggregate;
  created_date: Maybe<Scalars['date']>;
  /** An array relationship */
  icims_jobs: Array<Icims_Job>;
  /** An aggregate relationship */
  icims_jobs_aggregate: Icims_Job_Aggregate;
  id: Scalars['Int'];
  last_approved: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "icims_profile_job" */
export type Icims_Profile_JobApplicant_JobsArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Job_Order_By>>;
  where?: InputMaybe<Applicant_Job_Bool_Exp>;
};

/** columns and relationships of "icims_profile_job" */
export type Icims_Profile_JobApplicant_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Job_Order_By>>;
  where?: InputMaybe<Applicant_Job_Bool_Exp>;
};

/** columns and relationships of "icims_profile_job" */
export type Icims_Profile_JobCandidate_JobsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Order_By>>;
  where?: InputMaybe<Candidate_Job_Bool_Exp>;
};

/** columns and relationships of "icims_profile_job" */
export type Icims_Profile_JobCandidate_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Order_By>>;
  where?: InputMaybe<Candidate_Job_Bool_Exp>;
};

/** columns and relationships of "icims_profile_job" */
export type Icims_Profile_JobIcims_JobsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

/** columns and relationships of "icims_profile_job" */
export type Icims_Profile_JobIcims_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

/** aggregated selection of "icims_profile_job" */
export type Icims_Profile_Job_Aggregate = {
  __typename?: 'icims_profile_job_aggregate';
  aggregate: Maybe<Icims_Profile_Job_Aggregate_Fields>;
  nodes: Array<Icims_Profile_Job>;
};

/** aggregate fields of "icims_profile_job" */
export type Icims_Profile_Job_Aggregate_Fields = {
  __typename?: 'icims_profile_job_aggregate_fields';
  avg: Maybe<Icims_Profile_Job_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Profile_Job_Max_Fields>;
  min: Maybe<Icims_Profile_Job_Min_Fields>;
  stddev: Maybe<Icims_Profile_Job_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Profile_Job_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Profile_Job_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Profile_Job_Sum_Fields>;
  var_pop: Maybe<Icims_Profile_Job_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Profile_Job_Var_Samp_Fields>;
  variance: Maybe<Icims_Profile_Job_Variance_Fields>;
};

/** aggregate fields of "icims_profile_job" */
export type Icims_Profile_Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Profile_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Profile_Job_Avg_Fields = {
  __typename?: 'icims_profile_job_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_profile_job". All fields are combined with a logical 'AND'. */
export type Icims_Profile_Job_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Profile_Job_Bool_Exp>>;
  _not?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Profile_Job_Bool_Exp>>;
  active_on_job_portal_at?: InputMaybe<Timestamp_Comparison_Exp>;
  applicant_jobs?: InputMaybe<Applicant_Job_Bool_Exp>;
  applicant_jobs_aggregate?: InputMaybe<Applicant_Job_Aggregate_Bool_Exp>;
  candidate_jobs?: InputMaybe<Candidate_Job_Bool_Exp>;
  candidate_jobs_aggregate?: InputMaybe<Candidate_Job_Aggregate_Bool_Exp>;
  created_date?: InputMaybe<Date_Comparison_Exp>;
  icims_jobs?: InputMaybe<Icims_Job_Bool_Exp>;
  icims_jobs_aggregate?: InputMaybe<Icims_Job_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_approved?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_profile_job" */
export enum Icims_Profile_Job_Constraint {
  /** unique or primary key constraint on columns "id" */
  IcimsProfileJobPkey = 'icims_profile_job_pkey',
}

/** input type for incrementing numeric columns in table "icims_profile_job" */
export type Icims_Profile_Job_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_profile_job" */
export type Icims_Profile_Job_Insert_Input = {
  active_on_job_portal_at?: InputMaybe<Scalars['timestamp']>;
  applicant_jobs?: InputMaybe<Applicant_Job_Arr_Rel_Insert_Input>;
  candidate_jobs?: InputMaybe<Candidate_Job_Arr_Rel_Insert_Input>;
  created_date?: InputMaybe<Scalars['date']>;
  icims_jobs?: InputMaybe<Icims_Job_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  last_approved?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Icims_Profile_Job_Max_Fields = {
  __typename?: 'icims_profile_job_max_fields';
  active_on_job_portal_at: Maybe<Scalars['timestamp']>;
  created_date: Maybe<Scalars['date']>;
  id: Maybe<Scalars['Int']>;
  last_approved: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Icims_Profile_Job_Min_Fields = {
  __typename?: 'icims_profile_job_min_fields';
  active_on_job_portal_at: Maybe<Scalars['timestamp']>;
  created_date: Maybe<Scalars['date']>;
  id: Maybe<Scalars['Int']>;
  last_approved: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "icims_profile_job" */
export type Icims_Profile_Job_Mutation_Response = {
  __typename?: 'icims_profile_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Profile_Job>;
};

/** input type for inserting object relation for remote table "icims_profile_job" */
export type Icims_Profile_Job_Obj_Rel_Insert_Input = {
  data: Icims_Profile_Job_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Profile_Job_On_Conflict>;
};

/** on_conflict condition type for table "icims_profile_job" */
export type Icims_Profile_Job_On_Conflict = {
  constraint: Icims_Profile_Job_Constraint;
  update_columns?: Array<Icims_Profile_Job_Update_Column>;
  where?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_profile_job". */
export type Icims_Profile_Job_Order_By = {
  active_on_job_portal_at?: InputMaybe<Order_By>;
  applicant_jobs_aggregate?: InputMaybe<Applicant_Job_Aggregate_Order_By>;
  candidate_jobs_aggregate?: InputMaybe<Candidate_Job_Aggregate_Order_By>;
  created_date?: InputMaybe<Order_By>;
  icims_jobs_aggregate?: InputMaybe<Icims_Job_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  last_approved?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_profile_job */
export type Icims_Profile_Job_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "icims_profile_job" */
export enum Icims_Profile_Job_Select_Column {
  /** column name */
  ActiveOnJobPortalAt = 'active_on_job_portal_at',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Id = 'id',
  /** column name */
  LastApproved = 'last_approved',
}

/** input type for updating data in table "icims_profile_job" */
export type Icims_Profile_Job_Set_Input = {
  active_on_job_portal_at?: InputMaybe<Scalars['timestamp']>;
  created_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  last_approved?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Icims_Profile_Job_Stddev_Fields = {
  __typename?: 'icims_profile_job_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Profile_Job_Stddev_Pop_Fields = {
  __typename?: 'icims_profile_job_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Profile_Job_Stddev_Samp_Fields = {
  __typename?: 'icims_profile_job_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_profile_job" */
export type Icims_Profile_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Profile_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Profile_Job_Stream_Cursor_Value_Input = {
  active_on_job_portal_at?: InputMaybe<Scalars['timestamp']>;
  created_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  last_approved?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Icims_Profile_Job_Sum_Fields = {
  __typename?: 'icims_profile_job_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_profile_job" */
export enum Icims_Profile_Job_Update_Column {
  /** column name */
  ActiveOnJobPortalAt = 'active_on_job_portal_at',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Id = 'id',
  /** column name */
  LastApproved = 'last_approved',
}

export type Icims_Profile_Job_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Profile_Job_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Profile_Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Profile_Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Profile_Job_Var_Pop_Fields = {
  __typename?: 'icims_profile_job_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Profile_Job_Var_Samp_Fields = {
  __typename?: 'icims_profile_job_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Profile_Job_Variance_Fields = {
  __typename?: 'icims_profile_job_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_profile_location" */
export type Icims_Profile_Location = {
  __typename?: 'icims_profile_location';
  id: Scalars['Int'];
};

/** aggregated selection of "icims_profile_location" */
export type Icims_Profile_Location_Aggregate = {
  __typename?: 'icims_profile_location_aggregate';
  aggregate: Maybe<Icims_Profile_Location_Aggregate_Fields>;
  nodes: Array<Icims_Profile_Location>;
};

/** aggregate fields of "icims_profile_location" */
export type Icims_Profile_Location_Aggregate_Fields = {
  __typename?: 'icims_profile_location_aggregate_fields';
  avg: Maybe<Icims_Profile_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Profile_Location_Max_Fields>;
  min: Maybe<Icims_Profile_Location_Min_Fields>;
  stddev: Maybe<Icims_Profile_Location_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Profile_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Profile_Location_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Profile_Location_Sum_Fields>;
  var_pop: Maybe<Icims_Profile_Location_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Profile_Location_Var_Samp_Fields>;
  variance: Maybe<Icims_Profile_Location_Variance_Fields>;
};

/** aggregate fields of "icims_profile_location" */
export type Icims_Profile_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Profile_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Profile_Location_Avg_Fields = {
  __typename?: 'icims_profile_location_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_profile_location". All fields are combined with a logical 'AND'. */
export type Icims_Profile_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Profile_Location_Bool_Exp>>;
  _not?: InputMaybe<Icims_Profile_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Profile_Location_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_profile_location" */
export enum Icims_Profile_Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  IcimsProfileLocationPkey = 'icims_profile_location_pkey',
}

/** input type for incrementing numeric columns in table "icims_profile_location" */
export type Icims_Profile_Location_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_profile_location" */
export type Icims_Profile_Location_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Icims_Profile_Location_Max_Fields = {
  __typename?: 'icims_profile_location_max_fields';
  id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Icims_Profile_Location_Min_Fields = {
  __typename?: 'icims_profile_location_min_fields';
  id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "icims_profile_location" */
export type Icims_Profile_Location_Mutation_Response = {
  __typename?: 'icims_profile_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Profile_Location>;
};

/** on_conflict condition type for table "icims_profile_location" */
export type Icims_Profile_Location_On_Conflict = {
  constraint: Icims_Profile_Location_Constraint;
  update_columns?: Array<Icims_Profile_Location_Update_Column>;
  where?: InputMaybe<Icims_Profile_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_profile_location". */
export type Icims_Profile_Location_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_profile_location */
export type Icims_Profile_Location_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "icims_profile_location" */
export enum Icims_Profile_Location_Select_Column {
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "icims_profile_location" */
export type Icims_Profile_Location_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Icims_Profile_Location_Stddev_Fields = {
  __typename?: 'icims_profile_location_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Profile_Location_Stddev_Pop_Fields = {
  __typename?: 'icims_profile_location_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Profile_Location_Stddev_Samp_Fields = {
  __typename?: 'icims_profile_location_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_profile_location" */
export type Icims_Profile_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Profile_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Profile_Location_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Icims_Profile_Location_Sum_Fields = {
  __typename?: 'icims_profile_location_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_profile_location" */
export enum Icims_Profile_Location_Update_Column {
  /** column name */
  Id = 'id',
}

export type Icims_Profile_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Profile_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Profile_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Profile_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Profile_Location_Var_Pop_Fields = {
  __typename?: 'icims_profile_location_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Profile_Location_Var_Samp_Fields = {
  __typename?: 'icims_profile_location_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Profile_Location_Variance_Fields = {
  __typename?: 'icims_profile_location_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_profile_person" */
export type Icims_Profile_Person = {
  __typename?: 'icims_profile_person';
  /** An array relationship */
  candidates: Array<Candidate>;
  /** An aggregate relationship */
  candidates_aggregate: Candidate_Aggregate;
  /** An array relationship */
  icims_people: Array<Icims_Person>;
  /** An aggregate relationship */
  icims_people_aggregate: Icims_Person_Aggregate;
  id: Scalars['Int'];
  /** An array relationship */
  owned_candidates: Array<Candidate>;
  /** An aggregate relationship */
  owned_candidates_aggregate: Candidate_Aggregate;
  public_id: Maybe<Scalars['uuid']>;
  resume_filename: Maybe<Scalars['String']>;
  /** An array relationship */
  terminal_employees: Array<Terminal_Employee>;
  /** An aggregate relationship */
  terminal_employees_aggregate: Terminal_Employee_Aggregate;
};

/** columns and relationships of "icims_profile_person" */
export type Icims_Profile_PersonCandidatesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Order_By>>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

/** columns and relationships of "icims_profile_person" */
export type Icims_Profile_PersonCandidates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Order_By>>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

/** columns and relationships of "icims_profile_person" */
export type Icims_Profile_PersonIcims_PeopleArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Order_By>>;
  where?: InputMaybe<Icims_Person_Bool_Exp>;
};

/** columns and relationships of "icims_profile_person" */
export type Icims_Profile_PersonIcims_People_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Order_By>>;
  where?: InputMaybe<Icims_Person_Bool_Exp>;
};

/** columns and relationships of "icims_profile_person" */
export type Icims_Profile_PersonOwned_CandidatesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Order_By>>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

/** columns and relationships of "icims_profile_person" */
export type Icims_Profile_PersonOwned_Candidates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Order_By>>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

/** columns and relationships of "icims_profile_person" */
export type Icims_Profile_PersonTerminal_EmployeesArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Bool_Exp>;
};

/** columns and relationships of "icims_profile_person" */
export type Icims_Profile_PersonTerminal_Employees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Bool_Exp>;
};

/** aggregated selection of "icims_profile_person" */
export type Icims_Profile_Person_Aggregate = {
  __typename?: 'icims_profile_person_aggregate';
  aggregate: Maybe<Icims_Profile_Person_Aggregate_Fields>;
  nodes: Array<Icims_Profile_Person>;
};

/** aggregate fields of "icims_profile_person" */
export type Icims_Profile_Person_Aggregate_Fields = {
  __typename?: 'icims_profile_person_aggregate_fields';
  avg: Maybe<Icims_Profile_Person_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Profile_Person_Max_Fields>;
  min: Maybe<Icims_Profile_Person_Min_Fields>;
  stddev: Maybe<Icims_Profile_Person_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Profile_Person_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Profile_Person_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Profile_Person_Sum_Fields>;
  var_pop: Maybe<Icims_Profile_Person_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Profile_Person_Var_Samp_Fields>;
  variance: Maybe<Icims_Profile_Person_Variance_Fields>;
};

/** aggregate fields of "icims_profile_person" */
export type Icims_Profile_Person_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Profile_Person_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Profile_Person_Avg_Fields = {
  __typename?: 'icims_profile_person_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_profile_person". All fields are combined with a logical 'AND'. */
export type Icims_Profile_Person_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Profile_Person_Bool_Exp>>;
  _not?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Profile_Person_Bool_Exp>>;
  candidates?: InputMaybe<Candidate_Bool_Exp>;
  candidates_aggregate?: InputMaybe<Candidate_Aggregate_Bool_Exp>;
  icims_people?: InputMaybe<Icims_Person_Bool_Exp>;
  icims_people_aggregate?: InputMaybe<Icims_Person_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  owned_candidates?: InputMaybe<Candidate_Bool_Exp>;
  owned_candidates_aggregate?: InputMaybe<Candidate_Aggregate_Bool_Exp>;
  public_id?: InputMaybe<Uuid_Comparison_Exp>;
  resume_filename?: InputMaybe<String_Comparison_Exp>;
  terminal_employees?: InputMaybe<Terminal_Employee_Bool_Exp>;
  terminal_employees_aggregate?: InputMaybe<Terminal_Employee_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "icims_profile_person" */
export enum Icims_Profile_Person_Constraint {
  /** unique or primary key constraint on columns "id" */
  IcimsProfilePersonPkey = 'icims_profile_person_pkey',
}

/** input type for incrementing numeric columns in table "icims_profile_person" */
export type Icims_Profile_Person_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_profile_person" */
export type Icims_Profile_Person_Insert_Input = {
  candidates?: InputMaybe<Candidate_Arr_Rel_Insert_Input>;
  icims_people?: InputMaybe<Icims_Person_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  owned_candidates?: InputMaybe<Candidate_Arr_Rel_Insert_Input>;
  public_id?: InputMaybe<Scalars['uuid']>;
  resume_filename?: InputMaybe<Scalars['String']>;
  terminal_employees?: InputMaybe<Terminal_Employee_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Icims_Profile_Person_Max_Fields = {
  __typename?: 'icims_profile_person_max_fields';
  id: Maybe<Scalars['Int']>;
  public_id: Maybe<Scalars['uuid']>;
  resume_filename: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Profile_Person_Min_Fields = {
  __typename?: 'icims_profile_person_min_fields';
  id: Maybe<Scalars['Int']>;
  public_id: Maybe<Scalars['uuid']>;
  resume_filename: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_profile_person" */
export type Icims_Profile_Person_Mutation_Response = {
  __typename?: 'icims_profile_person_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Profile_Person>;
};

/** input type for inserting object relation for remote table "icims_profile_person" */
export type Icims_Profile_Person_Obj_Rel_Insert_Input = {
  data: Icims_Profile_Person_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Profile_Person_On_Conflict>;
};

/** on_conflict condition type for table "icims_profile_person" */
export type Icims_Profile_Person_On_Conflict = {
  constraint: Icims_Profile_Person_Constraint;
  update_columns?: Array<Icims_Profile_Person_Update_Column>;
  where?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_profile_person". */
export type Icims_Profile_Person_Order_By = {
  candidates_aggregate?: InputMaybe<Candidate_Aggregate_Order_By>;
  icims_people_aggregate?: InputMaybe<Icims_Person_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  owned_candidates_aggregate?: InputMaybe<Candidate_Aggregate_Order_By>;
  public_id?: InputMaybe<Order_By>;
  resume_filename?: InputMaybe<Order_By>;
  terminal_employees_aggregate?: InputMaybe<Terminal_Employee_Aggregate_Order_By>;
};

/** primary key columns input for table: icims_profile_person */
export type Icims_Profile_Person_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "icims_profile_person" */
export enum Icims_Profile_Person_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PublicId = 'public_id',
  /** column name */
  ResumeFilename = 'resume_filename',
}

/** input type for updating data in table "icims_profile_person" */
export type Icims_Profile_Person_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  public_id?: InputMaybe<Scalars['uuid']>;
  resume_filename?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Profile_Person_Stddev_Fields = {
  __typename?: 'icims_profile_person_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Profile_Person_Stddev_Pop_Fields = {
  __typename?: 'icims_profile_person_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Profile_Person_Stddev_Samp_Fields = {
  __typename?: 'icims_profile_person_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_profile_person" */
export type Icims_Profile_Person_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Profile_Person_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Profile_Person_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  public_id?: InputMaybe<Scalars['uuid']>;
  resume_filename?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Profile_Person_Sum_Fields = {
  __typename?: 'icims_profile_person_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_profile_person" */
export enum Icims_Profile_Person_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PublicId = 'public_id',
  /** column name */
  ResumeFilename = 'resume_filename',
}

export type Icims_Profile_Person_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Profile_Person_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Profile_Person_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Profile_Person_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Profile_Person_Var_Pop_Fields = {
  __typename?: 'icims_profile_person_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Profile_Person_Var_Samp_Fields = {
  __typename?: 'icims_profile_person_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Profile_Person_Variance_Fields = {
  __typename?: 'icims_profile_person_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_profile_requested_location" */
export type Icims_Profile_Requested_Location = {
  __typename?: 'icims_profile_requested_location';
  id: Scalars['String'];
};

/** aggregated selection of "icims_profile_requested_location" */
export type Icims_Profile_Requested_Location_Aggregate = {
  __typename?: 'icims_profile_requested_location_aggregate';
  aggregate: Maybe<Icims_Profile_Requested_Location_Aggregate_Fields>;
  nodes: Array<Icims_Profile_Requested_Location>;
};

/** aggregate fields of "icims_profile_requested_location" */
export type Icims_Profile_Requested_Location_Aggregate_Fields = {
  __typename?: 'icims_profile_requested_location_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Icims_Profile_Requested_Location_Max_Fields>;
  min: Maybe<Icims_Profile_Requested_Location_Min_Fields>;
};

/** aggregate fields of "icims_profile_requested_location" */
export type Icims_Profile_Requested_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Profile_Requested_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "icims_profile_requested_location". All fields are combined with a logical 'AND'. */
export type Icims_Profile_Requested_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Profile_Requested_Location_Bool_Exp>>;
  _not?: InputMaybe<Icims_Profile_Requested_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Profile_Requested_Location_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_profile_requested_location" */
export enum Icims_Profile_Requested_Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  IcimsProfileRequestedLocationPkey = 'icims_profile_requested_location_pkey',
}

/** input type for inserting data into table "icims_profile_requested_location" */
export type Icims_Profile_Requested_Location_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Profile_Requested_Location_Max_Fields = {
  __typename?: 'icims_profile_requested_location_max_fields';
  id: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Profile_Requested_Location_Min_Fields = {
  __typename?: 'icims_profile_requested_location_min_fields';
  id: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_profile_requested_location" */
export type Icims_Profile_Requested_Location_Mutation_Response = {
  __typename?: 'icims_profile_requested_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Profile_Requested_Location>;
};

/** on_conflict condition type for table "icims_profile_requested_location" */
export type Icims_Profile_Requested_Location_On_Conflict = {
  constraint: Icims_Profile_Requested_Location_Constraint;
  update_columns?: Array<Icims_Profile_Requested_Location_Update_Column>;
  where?: InputMaybe<Icims_Profile_Requested_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_profile_requested_location". */
export type Icims_Profile_Requested_Location_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_profile_requested_location */
export type Icims_Profile_Requested_Location_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "icims_profile_requested_location" */
export enum Icims_Profile_Requested_Location_Select_Column {
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "icims_profile_requested_location" */
export type Icims_Profile_Requested_Location_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "icims_profile_requested_location" */
export type Icims_Profile_Requested_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Profile_Requested_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Profile_Requested_Location_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "icims_profile_requested_location" */
export enum Icims_Profile_Requested_Location_Update_Column {
  /** column name */
  Id = 'id',
}

export type Icims_Profile_Requested_Location_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Profile_Requested_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Profile_Requested_Location_Bool_Exp;
};

/** iCIMS field28054 */
export type Icims_Requested_Location = {
  __typename?: 'icims_requested_location';
  /** An array relationship */
  icims_job_requested_locations: Array<Icims_Job_Requested_Location>;
  /** An aggregate relationship */
  icims_job_requested_locations_aggregate: Icims_Job_Requested_Location_Aggregate;
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
  value: Maybe<Scalars['String']>;
};

/** iCIMS field28054 */
export type Icims_Requested_LocationIcims_Job_Requested_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
};

/** iCIMS field28054 */
export type Icims_Requested_LocationIcims_Job_Requested_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
};

/** aggregated selection of "icims_requested_location" */
export type Icims_Requested_Location_Aggregate = {
  __typename?: 'icims_requested_location_aggregate';
  aggregate: Maybe<Icims_Requested_Location_Aggregate_Fields>;
  nodes: Array<Icims_Requested_Location>;
};

/** aggregate fields of "icims_requested_location" */
export type Icims_Requested_Location_Aggregate_Fields = {
  __typename?: 'icims_requested_location_aggregate_fields';
  avg: Maybe<Icims_Requested_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Requested_Location_Max_Fields>;
  min: Maybe<Icims_Requested_Location_Min_Fields>;
  stddev: Maybe<Icims_Requested_Location_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Requested_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Requested_Location_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Requested_Location_Sum_Fields>;
  var_pop: Maybe<Icims_Requested_Location_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Requested_Location_Var_Samp_Fields>;
  variance: Maybe<Icims_Requested_Location_Variance_Fields>;
};

/** aggregate fields of "icims_requested_location" */
export type Icims_Requested_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Requested_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Requested_Location_Avg_Fields = {
  __typename?: 'icims_requested_location_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_requested_location". All fields are combined with a logical 'AND'. */
export type Icims_Requested_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Requested_Location_Bool_Exp>>;
  _not?: InputMaybe<Icims_Requested_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Requested_Location_Bool_Exp>>;
  icims_job_requested_locations?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
  icims_job_requested_locations_aggregate?: InputMaybe<Icims_Job_Requested_Location_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_requested_location" */
export enum Icims_Requested_Location_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "id" */
  IcimsRequestedLocationPkey = 'icims_requested_location_pkey',
}

/** input type for incrementing numeric columns in table "icims_requested_location" */
export type Icims_Requested_Location_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_requested_location" */
export type Icims_Requested_Location_Insert_Input = {
  icims_job_requested_locations?: InputMaybe<Icims_Job_Requested_Location_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Requested_Location_Max_Fields = {
  __typename?: 'icims_requested_location_max_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Requested_Location_Min_Fields = {
  __typename?: 'icims_requested_location_min_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_requested_location" */
export type Icims_Requested_Location_Mutation_Response = {
  __typename?: 'icims_requested_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Requested_Location>;
};

/** on_conflict condition type for table "icims_requested_location" */
export type Icims_Requested_Location_On_Conflict = {
  constraint: Icims_Requested_Location_Constraint;
  update_columns?: Array<Icims_Requested_Location_Update_Column>;
  where?: InputMaybe<Icims_Requested_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_requested_location". */
export type Icims_Requested_Location_Order_By = {
  icims_job_requested_locations_aggregate?: InputMaybe<Icims_Job_Requested_Location_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_requested_location */
export type Icims_Requested_Location_Pk_Columns_Input = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** select columns of table "icims_requested_location" */
export enum Icims_Requested_Location_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "icims_requested_location" */
export type Icims_Requested_Location_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Requested_Location_Stddev_Fields = {
  __typename?: 'icims_requested_location_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Requested_Location_Stddev_Pop_Fields = {
  __typename?: 'icims_requested_location_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Requested_Location_Stddev_Samp_Fields = {
  __typename?: 'icims_requested_location_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_requested_location" */
export type Icims_Requested_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Requested_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Requested_Location_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Requested_Location_Sum_Fields = {
  __typename?: 'icims_requested_location_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_requested_location" */
export enum Icims_Requested_Location_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

export type Icims_Requested_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Requested_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Requested_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Requested_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Requested_Location_Var_Pop_Fields = {
  __typename?: 'icims_requested_location_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Requested_Location_Var_Samp_Fields = {
  __typename?: 'icims_requested_location_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Requested_Location_Variance_Fields = {
  __typename?: 'icims_requested_location_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_school" */
export type Icims_School = {
  __typename?: 'icims_school';
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "icims_school" */
export type Icims_School_Aggregate = {
  __typename?: 'icims_school_aggregate';
  aggregate: Maybe<Icims_School_Aggregate_Fields>;
  nodes: Array<Icims_School>;
};

/** aggregate fields of "icims_school" */
export type Icims_School_Aggregate_Fields = {
  __typename?: 'icims_school_aggregate_fields';
  avg: Maybe<Icims_School_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_School_Max_Fields>;
  min: Maybe<Icims_School_Min_Fields>;
  stddev: Maybe<Icims_School_Stddev_Fields>;
  stddev_pop: Maybe<Icims_School_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_School_Stddev_Samp_Fields>;
  sum: Maybe<Icims_School_Sum_Fields>;
  var_pop: Maybe<Icims_School_Var_Pop_Fields>;
  var_samp: Maybe<Icims_School_Var_Samp_Fields>;
  variance: Maybe<Icims_School_Variance_Fields>;
};

/** aggregate fields of "icims_school" */
export type Icims_School_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_School_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_School_Avg_Fields = {
  __typename?: 'icims_school_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_school". All fields are combined with a logical 'AND'. */
export type Icims_School_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_School_Bool_Exp>>;
  _not?: InputMaybe<Icims_School_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_School_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_school" */
export enum Icims_School_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "id" */
  IcimsSchoolPkey = 'icims_school_pkey',
}

/** input type for incrementing numeric columns in table "icims_school" */
export type Icims_School_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_school" */
export type Icims_School_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_School_Max_Fields = {
  __typename?: 'icims_school_max_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_School_Min_Fields = {
  __typename?: 'icims_school_min_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_school" */
export type Icims_School_Mutation_Response = {
  __typename?: 'icims_school_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_School>;
};

/** input type for inserting object relation for remote table "icims_school" */
export type Icims_School_Obj_Rel_Insert_Input = {
  data: Icims_School_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_School_On_Conflict>;
};

/** on_conflict condition type for table "icims_school" */
export type Icims_School_On_Conflict = {
  constraint: Icims_School_Constraint;
  update_columns?: Array<Icims_School_Update_Column>;
  where?: InputMaybe<Icims_School_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_school". */
export type Icims_School_Order_By = {
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_school */
export type Icims_School_Pk_Columns_Input = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** select columns of table "icims_school" */
export enum Icims_School_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "icims_school" */
export type Icims_School_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_School_Stddev_Fields = {
  __typename?: 'icims_school_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_School_Stddev_Pop_Fields = {
  __typename?: 'icims_school_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_School_Stddev_Samp_Fields = {
  __typename?: 'icims_school_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_school" */
export type Icims_School_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_School_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_School_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_School_Sum_Fields = {
  __typename?: 'icims_school_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_school" */
export enum Icims_School_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

export type Icims_School_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_School_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_School_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_School_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_School_Var_Pop_Fields = {
  __typename?: 'icims_school_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_School_Var_Samp_Fields = {
  __typename?: 'icims_school_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_School_Variance_Fields = {
  __typename?: 'icims_school_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_skill" */
export type Icims_Skill = {
  __typename?: 'icims_skill';
  /** An array relationship */
  icims_job_skills: Array<Icims_Job_Skill>;
  /** An aggregate relationship */
  icims_job_skills_aggregate: Icims_Job_Skill_Aggregate;
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
  value: Scalars['String'];
};

/** columns and relationships of "icims_skill" */
export type Icims_SkillIcims_Job_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Skill_Order_By>>;
  where?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
};

/** columns and relationships of "icims_skill" */
export type Icims_SkillIcims_Job_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Skill_Order_By>>;
  where?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
};

/** aggregated selection of "icims_skill" */
export type Icims_Skill_Aggregate = {
  __typename?: 'icims_skill_aggregate';
  aggregate: Maybe<Icims_Skill_Aggregate_Fields>;
  nodes: Array<Icims_Skill>;
};

/** aggregate fields of "icims_skill" */
export type Icims_Skill_Aggregate_Fields = {
  __typename?: 'icims_skill_aggregate_fields';
  avg: Maybe<Icims_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Skill_Max_Fields>;
  min: Maybe<Icims_Skill_Min_Fields>;
  stddev: Maybe<Icims_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Skill_Sum_Fields>;
  var_pop: Maybe<Icims_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Skill_Var_Samp_Fields>;
  variance: Maybe<Icims_Skill_Variance_Fields>;
};

/** aggregate fields of "icims_skill" */
export type Icims_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Skill_Avg_Fields = {
  __typename?: 'icims_skill_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_skill". All fields are combined with a logical 'AND'. */
export type Icims_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Skill_Bool_Exp>>;
  _not?: InputMaybe<Icims_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Skill_Bool_Exp>>;
  icims_job_skills?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
  icims_job_skills_aggregate?: InputMaybe<Icims_Job_Skill_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_skill" */
export enum Icims_Skill_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "id" */
  IcimsSkillPkey = 'icims_skill_pkey',
}

/** input type for incrementing numeric columns in table "icims_skill" */
export type Icims_Skill_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_skill" */
export type Icims_Skill_Insert_Input = {
  icims_job_skills?: InputMaybe<Icims_Job_Skill_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Skill_Max_Fields = {
  __typename?: 'icims_skill_max_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Skill_Min_Fields = {
  __typename?: 'icims_skill_min_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_skill" */
export type Icims_Skill_Mutation_Response = {
  __typename?: 'icims_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Skill>;
};

/** input type for inserting object relation for remote table "icims_skill" */
export type Icims_Skill_Obj_Rel_Insert_Input = {
  data: Icims_Skill_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Skill_On_Conflict>;
};

/** on_conflict condition type for table "icims_skill" */
export type Icims_Skill_On_Conflict = {
  constraint: Icims_Skill_Constraint;
  update_columns?: Array<Icims_Skill_Update_Column>;
  where?: InputMaybe<Icims_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_skill". */
export type Icims_Skill_Order_By = {
  icims_job_skills_aggregate?: InputMaybe<Icims_Job_Skill_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_skill */
export type Icims_Skill_Pk_Columns_Input = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** select columns of table "icims_skill" */
export enum Icims_Skill_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "icims_skill" */
export type Icims_Skill_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Skill_Stddev_Fields = {
  __typename?: 'icims_skill_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Skill_Stddev_Pop_Fields = {
  __typename?: 'icims_skill_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Skill_Stddev_Samp_Fields = {
  __typename?: 'icims_skill_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_skill" */
export type Icims_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Skill_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Skill_Sum_Fields = {
  __typename?: 'icims_skill_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_skill" */
export enum Icims_Skill_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

export type Icims_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Skill_Var_Pop_Fields = {
  __typename?: 'icims_skill_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Skill_Var_Samp_Fields = {
  __typename?: 'icims_skill_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Skill_Variance_Fields = {
  __typename?: 'icims_skill_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** columns and relationships of "icims_status" */
export type Icims_Status = {
  __typename?: 'icims_status';
  /** An array relationship */
  icims_applicant_workflow_statuses: Array<Icims_Applicant_Workflow_Status>;
  /** An aggregate relationship */
  icims_applicant_workflow_statuses_aggregate: Icims_Applicant_Workflow_Status_Aggregate;
  /** An object relationship */
  icims_initial_data_transfer: Icims_Initial_Data_Transfer;
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
  value: Maybe<Scalars['String']>;
};

/** columns and relationships of "icims_status" */
export type Icims_StatusIcims_Applicant_Workflow_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

/** columns and relationships of "icims_status" */
export type Icims_StatusIcims_Applicant_Workflow_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

/** aggregated selection of "icims_status" */
export type Icims_Status_Aggregate = {
  __typename?: 'icims_status_aggregate';
  aggregate: Maybe<Icims_Status_Aggregate_Fields>;
  nodes: Array<Icims_Status>;
};

/** aggregate fields of "icims_status" */
export type Icims_Status_Aggregate_Fields = {
  __typename?: 'icims_status_aggregate_fields';
  avg: Maybe<Icims_Status_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Status_Max_Fields>;
  min: Maybe<Icims_Status_Min_Fields>;
  stddev: Maybe<Icims_Status_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Status_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Status_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Status_Sum_Fields>;
  var_pop: Maybe<Icims_Status_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Status_Var_Samp_Fields>;
  variance: Maybe<Icims_Status_Variance_Fields>;
};

/** aggregate fields of "icims_status" */
export type Icims_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Icims_Status_Avg_Fields = {
  __typename?: 'icims_status_avg_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "icims_status". All fields are combined with a logical 'AND'. */
export type Icims_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Status_Bool_Exp>>;
  _not?: InputMaybe<Icims_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Status_Bool_Exp>>;
  icims_applicant_workflow_statuses?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
  icims_applicant_workflow_statuses_aggregate?: InputMaybe<Icims_Applicant_Workflow_Status_Aggregate_Bool_Exp>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_status" */
export enum Icims_Status_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "id" */
  IcimsStatusPkey = 'icims_status_pkey',
}

/** input type for incrementing numeric columns in table "icims_status" */
export type Icims_Status_Inc_Input = {
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_status" */
export type Icims_Status_Insert_Input = {
  icims_applicant_workflow_statuses?: InputMaybe<Icims_Applicant_Workflow_Status_Arr_Rel_Insert_Input>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Status_Max_Fields = {
  __typename?: 'icims_status_max_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Status_Min_Fields = {
  __typename?: 'icims_status_min_fields';
  id: Maybe<Scalars['String']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_status" */
export type Icims_Status_Mutation_Response = {
  __typename?: 'icims_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Status>;
};

/** input type for inserting object relation for remote table "icims_status" */
export type Icims_Status_Obj_Rel_Insert_Input = {
  data: Icims_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Status_On_Conflict>;
};

/** on_conflict condition type for table "icims_status" */
export type Icims_Status_On_Conflict = {
  constraint: Icims_Status_Constraint;
  update_columns?: Array<Icims_Status_Update_Column>;
  where?: InputMaybe<Icims_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_status". */
export type Icims_Status_Order_By = {
  icims_applicant_workflow_statuses_aggregate?: InputMaybe<Icims_Applicant_Workflow_Status_Aggregate_Order_By>;
  icims_initial_data_transfer?: InputMaybe<Icims_Initial_Data_Transfer_Order_By>;
  id?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_status */
export type Icims_Status_Pk_Columns_Input = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** select columns of table "icims_status" */
export enum Icims_Status_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "icims_status" */
export type Icims_Status_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Status_Stddev_Fields = {
  __typename?: 'icims_status_stddev_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Icims_Status_Stddev_Pop_Fields = {
  __typename?: 'icims_status_stddev_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Icims_Status_Stddev_Samp_Fields = {
  __typename?: 'icims_status_stddev_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "icims_status" */
export type Icims_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Status_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Status_Sum_Fields = {
  __typename?: 'icims_status_sum_fields';
  initial_data_transfer: Maybe<Scalars['Int']>;
};

/** update columns of table "icims_status" */
export enum Icims_Status_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  Value = 'value',
}

export type Icims_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Status_Var_Pop_Fields = {
  __typename?: 'icims_status_var_pop_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Icims_Status_Var_Samp_Fields = {
  __typename?: 'icims_status_var_samp_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Icims_Status_Variance_Fields = {
  __typename?: 'icims_status_variance_fields';
  initial_data_transfer: Maybe<Scalars['Float']>;
};

/** Enum table for sync status to iCIMS */
export type Icims_Sync_Status_Choices = {
  __typename?: 'icims_sync_status_choices';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "icims_sync_status_choices" */
export type Icims_Sync_Status_Choices_Aggregate = {
  __typename?: 'icims_sync_status_choices_aggregate';
  aggregate: Maybe<Icims_Sync_Status_Choices_Aggregate_Fields>;
  nodes: Array<Icims_Sync_Status_Choices>;
};

/** aggregate fields of "icims_sync_status_choices" */
export type Icims_Sync_Status_Choices_Aggregate_Fields = {
  __typename?: 'icims_sync_status_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Icims_Sync_Status_Choices_Max_Fields>;
  min: Maybe<Icims_Sync_Status_Choices_Min_Fields>;
};

/** aggregate fields of "icims_sync_status_choices" */
export type Icims_Sync_Status_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Sync_Status_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "icims_sync_status_choices". All fields are combined with a logical 'AND'. */
export type Icims_Sync_Status_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Sync_Status_Choices_Bool_Exp>>;
  _not?: InputMaybe<Icims_Sync_Status_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Sync_Status_Choices_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_sync_status_choices" */
export enum Icims_Sync_Status_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  IcimsSyncStatusPkey = 'icims_sync_status_pkey',
}

export enum Icims_Sync_Status_Choices_Enum {
  /** There was an error in processing or sending this to iCIMS */
  Error = 'ERROR',
  /** When a locally created status is returned from ICIMS */
  FromIcims = 'FROM_ICIMS',
  /** Do not send to iCIMS */
  Pause = 'PAUSE',
  /** This has been sent to iCIMS successfully */
  PushedToIcims = 'PUSHED_TO_ICIMS',
  /** Currently being pushed to ICIMS */
  PushingToIcims = 'PUSHING_TO_ICIMS',
  /** Try sending this again later */
  Retry = 'RETRY',
  /** This has been updated and needs to be pushed to iCIMS */
  Waiting = 'WAITING',
}

/** Boolean expression to compare columns of type "icims_sync_status_choices_enum". All fields are combined with logical 'AND'. */
export type Icims_Sync_Status_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  _in?: InputMaybe<Array<Icims_Sync_Status_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  _nin?: InputMaybe<Array<Icims_Sync_Status_Choices_Enum>>;
};

/** input type for inserting data into table "icims_sync_status_choices" */
export type Icims_Sync_Status_Choices_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Sync_Status_Choices_Max_Fields = {
  __typename?: 'icims_sync_status_choices_max_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icims_Sync_Status_Choices_Min_Fields = {
  __typename?: 'icims_sync_status_choices_min_fields';
  comment: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icims_sync_status_choices" */
export type Icims_Sync_Status_Choices_Mutation_Response = {
  __typename?: 'icims_sync_status_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Sync_Status_Choices>;
};

/** on_conflict condition type for table "icims_sync_status_choices" */
export type Icims_Sync_Status_Choices_On_Conflict = {
  constraint: Icims_Sync_Status_Choices_Constraint;
  update_columns?: Array<Icims_Sync_Status_Choices_Update_Column>;
  where?: InputMaybe<Icims_Sync_Status_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_sync_status_choices". */
export type Icims_Sync_Status_Choices_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_sync_status_choices */
export type Icims_Sync_Status_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "icims_sync_status_choices" */
export enum Icims_Sync_Status_Choices_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "icims_sync_status_choices" */
export type Icims_Sync_Status_Choices_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "icims_sync_status_choices" */
export type Icims_Sync_Status_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Sync_Status_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Sync_Status_Choices_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "icims_sync_status_choices" */
export enum Icims_Sync_Status_Choices_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Icims_Sync_Status_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Sync_Status_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Sync_Status_Choices_Bool_Exp;
};

/** columns and relationships of "icims_work_experience" */
export type Icims_Work_Experience = {
  __typename?: 'icims_work_experience';
  entry: Scalars['Int'];
  /** An object relationship */
  icims_person: Maybe<Icims_Person>;
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
  workemployer: Maybe<Scalars['String']>;
  workenddate: Maybe<Scalars['date']>;
  workstartdate: Maybe<Scalars['date']>;
  worktitle: Maybe<Scalars['String']>;
};

/** aggregated selection of "icims_work_experience" */
export type Icims_Work_Experience_Aggregate = {
  __typename?: 'icims_work_experience_aggregate';
  aggregate: Maybe<Icims_Work_Experience_Aggregate_Fields>;
  nodes: Array<Icims_Work_Experience>;
};

export type Icims_Work_Experience_Aggregate_Bool_Exp = {
  count?: InputMaybe<Icims_Work_Experience_Aggregate_Bool_Exp_Count>;
};

export type Icims_Work_Experience_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Icims_Work_Experience_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Icims_Work_Experience_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "icims_work_experience" */
export type Icims_Work_Experience_Aggregate_Fields = {
  __typename?: 'icims_work_experience_aggregate_fields';
  avg: Maybe<Icims_Work_Experience_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Icims_Work_Experience_Max_Fields>;
  min: Maybe<Icims_Work_Experience_Min_Fields>;
  stddev: Maybe<Icims_Work_Experience_Stddev_Fields>;
  stddev_pop: Maybe<Icims_Work_Experience_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Icims_Work_Experience_Stddev_Samp_Fields>;
  sum: Maybe<Icims_Work_Experience_Sum_Fields>;
  var_pop: Maybe<Icims_Work_Experience_Var_Pop_Fields>;
  var_samp: Maybe<Icims_Work_Experience_Var_Samp_Fields>;
  variance: Maybe<Icims_Work_Experience_Variance_Fields>;
};

/** aggregate fields of "icims_work_experience" */
export type Icims_Work_Experience_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icims_Work_Experience_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "icims_work_experience" */
export type Icims_Work_Experience_Aggregate_Order_By = {
  avg?: InputMaybe<Icims_Work_Experience_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Icims_Work_Experience_Max_Order_By>;
  min?: InputMaybe<Icims_Work_Experience_Min_Order_By>;
  stddev?: InputMaybe<Icims_Work_Experience_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Icims_Work_Experience_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Icims_Work_Experience_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Icims_Work_Experience_Sum_Order_By>;
  var_pop?: InputMaybe<Icims_Work_Experience_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Icims_Work_Experience_Var_Samp_Order_By>;
  variance?: InputMaybe<Icims_Work_Experience_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "icims_work_experience" */
export type Icims_Work_Experience_Arr_Rel_Insert_Input = {
  data: Array<Icims_Work_Experience_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Icims_Work_Experience_On_Conflict>;
};

/** aggregate avg on columns */
export type Icims_Work_Experience_Avg_Fields = {
  __typename?: 'icims_work_experience_avg_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "icims_work_experience" */
export type Icims_Work_Experience_Avg_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "icims_work_experience". All fields are combined with a logical 'AND'. */
export type Icims_Work_Experience_Bool_Exp = {
  _and?: InputMaybe<Array<Icims_Work_Experience_Bool_Exp>>;
  _not?: InputMaybe<Icims_Work_Experience_Bool_Exp>;
  _or?: InputMaybe<Array<Icims_Work_Experience_Bool_Exp>>;
  entry?: InputMaybe<Int_Comparison_Exp>;
  icims_person?: InputMaybe<Icims_Person_Bool_Exp>;
  initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  person_profile_id?: InputMaybe<Int_Comparison_Exp>;
  workemployer?: InputMaybe<String_Comparison_Exp>;
  workenddate?: InputMaybe<Date_Comparison_Exp>;
  workstartdate?: InputMaybe<Date_Comparison_Exp>;
  worktitle?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icims_work_experience" */
export enum Icims_Work_Experience_Constraint {
  /** unique or primary key constraint on columns "initial_data_transfer", "entry", "person_profile_id" */
  IcimsWorkExperiencePkey = 'icims_work_experience_pkey',
}

/** input type for incrementing numeric columns in table "icims_work_experience" */
export type Icims_Work_Experience_Inc_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "icims_work_experience" */
export type Icims_Work_Experience_Insert_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  icims_person?: InputMaybe<Icims_Person_Obj_Rel_Insert_Input>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  workemployer?: InputMaybe<Scalars['String']>;
  workenddate?: InputMaybe<Scalars['date']>;
  workstartdate?: InputMaybe<Scalars['date']>;
  worktitle?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icims_Work_Experience_Max_Fields = {
  __typename?: 'icims_work_experience_max_fields';
  entry: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
  workemployer: Maybe<Scalars['String']>;
  workenddate: Maybe<Scalars['date']>;
  workstartdate: Maybe<Scalars['date']>;
  worktitle: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "icims_work_experience" */
export type Icims_Work_Experience_Max_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  workemployer?: InputMaybe<Order_By>;
  workenddate?: InputMaybe<Order_By>;
  workstartdate?: InputMaybe<Order_By>;
  worktitle?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Icims_Work_Experience_Min_Fields = {
  __typename?: 'icims_work_experience_min_fields';
  entry: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
  workemployer: Maybe<Scalars['String']>;
  workenddate: Maybe<Scalars['date']>;
  workstartdate: Maybe<Scalars['date']>;
  worktitle: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "icims_work_experience" */
export type Icims_Work_Experience_Min_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  workemployer?: InputMaybe<Order_By>;
  workenddate?: InputMaybe<Order_By>;
  workstartdate?: InputMaybe<Order_By>;
  worktitle?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "icims_work_experience" */
export type Icims_Work_Experience_Mutation_Response = {
  __typename?: 'icims_work_experience_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icims_Work_Experience>;
};

/** on_conflict condition type for table "icims_work_experience" */
export type Icims_Work_Experience_On_Conflict = {
  constraint: Icims_Work_Experience_Constraint;
  update_columns?: Array<Icims_Work_Experience_Update_Column>;
  where?: InputMaybe<Icims_Work_Experience_Bool_Exp>;
};

/** Ordering options when selecting data from "icims_work_experience". */
export type Icims_Work_Experience_Order_By = {
  entry?: InputMaybe<Order_By>;
  icims_person?: InputMaybe<Icims_Person_Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
  workemployer?: InputMaybe<Order_By>;
  workenddate?: InputMaybe<Order_By>;
  workstartdate?: InputMaybe<Order_By>;
  worktitle?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icims_work_experience */
export type Icims_Work_Experience_Pk_Columns_Input = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

/** select columns of table "icims_work_experience" */
export enum Icims_Work_Experience_Select_Column {
  /** column name */
  Entry = 'entry',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  PersonProfileId = 'person_profile_id',
  /** column name */
  Workemployer = 'workemployer',
  /** column name */
  Workenddate = 'workenddate',
  /** column name */
  Workstartdate = 'workstartdate',
  /** column name */
  Worktitle = 'worktitle',
}

/** input type for updating data in table "icims_work_experience" */
export type Icims_Work_Experience_Set_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  workemployer?: InputMaybe<Scalars['String']>;
  workenddate?: InputMaybe<Scalars['date']>;
  workstartdate?: InputMaybe<Scalars['date']>;
  worktitle?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Icims_Work_Experience_Stddev_Fields = {
  __typename?: 'icims_work_experience_stddev_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "icims_work_experience" */
export type Icims_Work_Experience_Stddev_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Icims_Work_Experience_Stddev_Pop_Fields = {
  __typename?: 'icims_work_experience_stddev_pop_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "icims_work_experience" */
export type Icims_Work_Experience_Stddev_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Icims_Work_Experience_Stddev_Samp_Fields = {
  __typename?: 'icims_work_experience_stddev_samp_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "icims_work_experience" */
export type Icims_Work_Experience_Stddev_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "icims_work_experience" */
export type Icims_Work_Experience_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icims_Work_Experience_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icims_Work_Experience_Stream_Cursor_Value_Input = {
  entry?: InputMaybe<Scalars['Int']>;
  initial_data_transfer?: InputMaybe<Scalars['Int']>;
  person_profile_id?: InputMaybe<Scalars['Int']>;
  workemployer?: InputMaybe<Scalars['String']>;
  workenddate?: InputMaybe<Scalars['date']>;
  workstartdate?: InputMaybe<Scalars['date']>;
  worktitle?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Icims_Work_Experience_Sum_Fields = {
  __typename?: 'icims_work_experience_sum_fields';
  entry: Maybe<Scalars['Int']>;
  initial_data_transfer: Maybe<Scalars['Int']>;
  person_profile_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "icims_work_experience" */
export type Icims_Work_Experience_Sum_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** update columns of table "icims_work_experience" */
export enum Icims_Work_Experience_Update_Column {
  /** column name */
  Entry = 'entry',
  /** column name */
  InitialDataTransfer = 'initial_data_transfer',
  /** column name */
  PersonProfileId = 'person_profile_id',
  /** column name */
  Workemployer = 'workemployer',
  /** column name */
  Workenddate = 'workenddate',
  /** column name */
  Workstartdate = 'workstartdate',
  /** column name */
  Worktitle = 'worktitle',
}

export type Icims_Work_Experience_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Icims_Work_Experience_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icims_Work_Experience_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icims_Work_Experience_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Icims_Work_Experience_Var_Pop_Fields = {
  __typename?: 'icims_work_experience_var_pop_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "icims_work_experience" */
export type Icims_Work_Experience_Var_Pop_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Icims_Work_Experience_Var_Samp_Fields = {
  __typename?: 'icims_work_experience_var_samp_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "icims_work_experience" */
export type Icims_Work_Experience_Var_Samp_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Icims_Work_Experience_Variance_Fields = {
  __typename?: 'icims_work_experience_variance_fields';
  entry: Maybe<Scalars['Float']>;
  initial_data_transfer: Maybe<Scalars['Float']>;
  person_profile_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "icims_work_experience" */
export type Icims_Work_Experience_Variance_Order_By = {
  entry?: InputMaybe<Order_By>;
  initial_data_transfer?: InputMaybe<Order_By>;
  person_profile_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "industry" */
export type Industry = {
  __typename?: 'industry';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "industry" */
export type Industry_Aggregate = {
  __typename?: 'industry_aggregate';
  aggregate: Maybe<Industry_Aggregate_Fields>;
  nodes: Array<Industry>;
};

/** aggregate fields of "industry" */
export type Industry_Aggregate_Fields = {
  __typename?: 'industry_aggregate_fields';
  avg: Maybe<Industry_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Industry_Max_Fields>;
  min: Maybe<Industry_Min_Fields>;
  stddev: Maybe<Industry_Stddev_Fields>;
  stddev_pop: Maybe<Industry_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Industry_Stddev_Samp_Fields>;
  sum: Maybe<Industry_Sum_Fields>;
  var_pop: Maybe<Industry_Var_Pop_Fields>;
  var_samp: Maybe<Industry_Var_Samp_Fields>;
  variance: Maybe<Industry_Variance_Fields>;
};

/** aggregate fields of "industry" */
export type Industry_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Industry_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Industry_Avg_Fields = {
  __typename?: 'industry_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "industry". All fields are combined with a logical 'AND'. */
export type Industry_Bool_Exp = {
  _and?: InputMaybe<Array<Industry_Bool_Exp>>;
  _not?: InputMaybe<Industry_Bool_Exp>;
  _or?: InputMaybe<Array<Industry_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "industry" */
export enum Industry_Constraint {
  /** unique or primary key constraint on columns "name" */
  IndustryNameKey = 'industry_name_key',
  /** unique or primary key constraint on columns "id" */
  IndustryPkey = 'industry_pkey',
}

/** input type for incrementing numeric columns in table "industry" */
export type Industry_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "industry" */
export type Industry_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Industry_Max_Fields = {
  __typename?: 'industry_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Industry_Min_Fields = {
  __typename?: 'industry_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "industry" */
export type Industry_Mutation_Response = {
  __typename?: 'industry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Industry>;
};

/** input type for inserting object relation for remote table "industry" */
export type Industry_Obj_Rel_Insert_Input = {
  data: Industry_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Industry_On_Conflict>;
};

/** on_conflict condition type for table "industry" */
export type Industry_On_Conflict = {
  constraint: Industry_Constraint;
  update_columns?: Array<Industry_Update_Column>;
  where?: InputMaybe<Industry_Bool_Exp>;
};

/** Ordering options when selecting data from "industry". */
export type Industry_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: industry */
export type Industry_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "industry" */
export enum Industry_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "industry" */
export type Industry_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Industry_Stddev_Fields = {
  __typename?: 'industry_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Industry_Stddev_Pop_Fields = {
  __typename?: 'industry_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Industry_Stddev_Samp_Fields = {
  __typename?: 'industry_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "industry" */
export type Industry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Industry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Industry_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Industry_Sum_Fields = {
  __typename?: 'industry_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "industry" */
export enum Industry_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Industry_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Industry_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Industry_Set_Input>;
  /** filter the rows which have to be updated */
  where: Industry_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Industry_Var_Pop_Fields = {
  __typename?: 'industry_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Industry_Var_Samp_Fields = {
  __typename?: 'industry_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Industry_Variance_Fields = {
  __typename?: 'industry_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "initial_sell_type_choices" */
export type Initial_Sell_Type_Choices = {
  __typename?: 'initial_sell_type_choices';
  value: Scalars['String'];
};

/** aggregated selection of "initial_sell_type_choices" */
export type Initial_Sell_Type_Choices_Aggregate = {
  __typename?: 'initial_sell_type_choices_aggregate';
  aggregate: Maybe<Initial_Sell_Type_Choices_Aggregate_Fields>;
  nodes: Array<Initial_Sell_Type_Choices>;
};

/** aggregate fields of "initial_sell_type_choices" */
export type Initial_Sell_Type_Choices_Aggregate_Fields = {
  __typename?: 'initial_sell_type_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Initial_Sell_Type_Choices_Max_Fields>;
  min: Maybe<Initial_Sell_Type_Choices_Min_Fields>;
};

/** aggregate fields of "initial_sell_type_choices" */
export type Initial_Sell_Type_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Initial_Sell_Type_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "initial_sell_type_choices". All fields are combined with a logical 'AND'. */
export type Initial_Sell_Type_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Initial_Sell_Type_Choices_Bool_Exp>>;
  _not?: InputMaybe<Initial_Sell_Type_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Initial_Sell_Type_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "initial_sell_type_choices" */
export enum Initial_Sell_Type_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  InitialSellTypeChoicesPkey = 'initial_sell_type_choices_pkey',
}

/** input type for inserting data into table "initial_sell_type_choices" */
export type Initial_Sell_Type_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Initial_Sell_Type_Choices_Max_Fields = {
  __typename?: 'initial_sell_type_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Initial_Sell_Type_Choices_Min_Fields = {
  __typename?: 'initial_sell_type_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "initial_sell_type_choices" */
export type Initial_Sell_Type_Choices_Mutation_Response = {
  __typename?: 'initial_sell_type_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Initial_Sell_Type_Choices>;
};

/** on_conflict condition type for table "initial_sell_type_choices" */
export type Initial_Sell_Type_Choices_On_Conflict = {
  constraint: Initial_Sell_Type_Choices_Constraint;
  update_columns?: Array<Initial_Sell_Type_Choices_Update_Column>;
  where?: InputMaybe<Initial_Sell_Type_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "initial_sell_type_choices". */
export type Initial_Sell_Type_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: initial_sell_type_choices */
export type Initial_Sell_Type_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "initial_sell_type_choices" */
export enum Initial_Sell_Type_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "initial_sell_type_choices" */
export type Initial_Sell_Type_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "initial_sell_type_choices" */
export type Initial_Sell_Type_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Initial_Sell_Type_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Initial_Sell_Type_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "initial_sell_type_choices" */
export enum Initial_Sell_Type_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Initial_Sell_Type_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Initial_Sell_Type_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Initial_Sell_Type_Choices_Bool_Exp;
};

/** columns and relationships of "interview_detail" */
export type Interview_Detail = {
  __typename?: 'interview_detail';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['Int'];
  /** in minutes */
  length: Scalars['smallint'];
  order: Scalars['smallint'];
  type: Interview_Type_Choices_Enum;
  /** An object relationship */
  type_choice: Interview_Type_Choices;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "interview_detail" */
export type Interview_Detail_Aggregate = {
  __typename?: 'interview_detail_aggregate';
  aggregate: Maybe<Interview_Detail_Aggregate_Fields>;
  nodes: Array<Interview_Detail>;
};

export type Interview_Detail_Aggregate_Bool_Exp = {
  count?: InputMaybe<Interview_Detail_Aggregate_Bool_Exp_Count>;
};

export type Interview_Detail_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Interview_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Interview_Detail_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "interview_detail" */
export type Interview_Detail_Aggregate_Fields = {
  __typename?: 'interview_detail_aggregate_fields';
  avg: Maybe<Interview_Detail_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Interview_Detail_Max_Fields>;
  min: Maybe<Interview_Detail_Min_Fields>;
  stddev: Maybe<Interview_Detail_Stddev_Fields>;
  stddev_pop: Maybe<Interview_Detail_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Interview_Detail_Stddev_Samp_Fields>;
  sum: Maybe<Interview_Detail_Sum_Fields>;
  var_pop: Maybe<Interview_Detail_Var_Pop_Fields>;
  var_samp: Maybe<Interview_Detail_Var_Samp_Fields>;
  variance: Maybe<Interview_Detail_Variance_Fields>;
};

/** aggregate fields of "interview_detail" */
export type Interview_Detail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Interview_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "interview_detail" */
export type Interview_Detail_Aggregate_Order_By = {
  avg?: InputMaybe<Interview_Detail_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Interview_Detail_Max_Order_By>;
  min?: InputMaybe<Interview_Detail_Min_Order_By>;
  stddev?: InputMaybe<Interview_Detail_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Interview_Detail_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Interview_Detail_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Interview_Detail_Sum_Order_By>;
  var_pop?: InputMaybe<Interview_Detail_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Interview_Detail_Var_Samp_Order_By>;
  variance?: InputMaybe<Interview_Detail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "interview_detail" */
export type Interview_Detail_Arr_Rel_Insert_Input = {
  data: Array<Interview_Detail_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Interview_Detail_On_Conflict>;
};

/** aggregate avg on columns */
export type Interview_Detail_Avg_Fields = {
  __typename?: 'interview_detail_avg_fields';
  job_id: Maybe<Scalars['Float']>;
  /** in minutes */
  length: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "interview_detail" */
export type Interview_Detail_Avg_Order_By = {
  job_id?: InputMaybe<Order_By>;
  /** in minutes */
  length?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "interview_detail". All fields are combined with a logical 'AND'. */
export type Interview_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Interview_Detail_Bool_Exp>>;
  _not?: InputMaybe<Interview_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Interview_Detail_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  length?: InputMaybe<Smallint_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  type?: InputMaybe<Interview_Type_Choices_Enum_Comparison_Exp>;
  type_choice?: InputMaybe<Interview_Type_Choices_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "interview_detail" */
export enum Interview_Detail_Constraint {
  /** unique or primary key constraint on columns "order", "job_id" */
  InterviewDetailPkey = 'interview_detail_pkey',
}

/** input type for incrementing numeric columns in table "interview_detail" */
export type Interview_Detail_Inc_Input = {
  job_id?: InputMaybe<Scalars['Int']>;
  /** in minutes */
  length?: InputMaybe<Scalars['smallint']>;
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "interview_detail" */
export type Interview_Detail_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['Int']>;
  /** in minutes */
  length?: InputMaybe<Scalars['smallint']>;
  order?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Interview_Type_Choices_Enum>;
  type_choice?: InputMaybe<Interview_Type_Choices_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Interview_Detail_Max_Fields = {
  __typename?: 'interview_detail_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  job_id: Maybe<Scalars['Int']>;
  /** in minutes */
  length: Maybe<Scalars['smallint']>;
  order: Maybe<Scalars['smallint']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "interview_detail" */
export type Interview_Detail_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  /** in minutes */
  length?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Interview_Detail_Min_Fields = {
  __typename?: 'interview_detail_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  job_id: Maybe<Scalars['Int']>;
  /** in minutes */
  length: Maybe<Scalars['smallint']>;
  order: Maybe<Scalars['smallint']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "interview_detail" */
export type Interview_Detail_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  /** in minutes */
  length?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "interview_detail" */
export type Interview_Detail_Mutation_Response = {
  __typename?: 'interview_detail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interview_Detail>;
};

/** on_conflict condition type for table "interview_detail" */
export type Interview_Detail_On_Conflict = {
  constraint: Interview_Detail_Constraint;
  update_columns?: Array<Interview_Detail_Update_Column>;
  where?: InputMaybe<Interview_Detail_Bool_Exp>;
};

/** Ordering options when selecting data from "interview_detail". */
export type Interview_Detail_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  length?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  type_choice?: InputMaybe<Interview_Type_Choices_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: interview_detail */
export type Interview_Detail_Pk_Columns_Input = {
  job_id: Scalars['Int'];
  order: Scalars['smallint'];
};

/** select columns of table "interview_detail" */
export enum Interview_Detail_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Length = 'length',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "interview_detail" */
export type Interview_Detail_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  job_id?: InputMaybe<Scalars['Int']>;
  /** in minutes */
  length?: InputMaybe<Scalars['smallint']>;
  order?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Interview_Type_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Interview_Detail_Stddev_Fields = {
  __typename?: 'interview_detail_stddev_fields';
  job_id: Maybe<Scalars['Float']>;
  /** in minutes */
  length: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "interview_detail" */
export type Interview_Detail_Stddev_Order_By = {
  job_id?: InputMaybe<Order_By>;
  /** in minutes */
  length?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Interview_Detail_Stddev_Pop_Fields = {
  __typename?: 'interview_detail_stddev_pop_fields';
  job_id: Maybe<Scalars['Float']>;
  /** in minutes */
  length: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "interview_detail" */
export type Interview_Detail_Stddev_Pop_Order_By = {
  job_id?: InputMaybe<Order_By>;
  /** in minutes */
  length?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Interview_Detail_Stddev_Samp_Fields = {
  __typename?: 'interview_detail_stddev_samp_fields';
  job_id: Maybe<Scalars['Float']>;
  /** in minutes */
  length: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "interview_detail" */
export type Interview_Detail_Stddev_Samp_Order_By = {
  job_id?: InputMaybe<Order_By>;
  /** in minutes */
  length?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "interview_detail" */
export type Interview_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interview_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interview_Detail_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  job_id?: InputMaybe<Scalars['Int']>;
  /** in minutes */
  length?: InputMaybe<Scalars['smallint']>;
  order?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Interview_Type_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Interview_Detail_Sum_Fields = {
  __typename?: 'interview_detail_sum_fields';
  job_id: Maybe<Scalars['Int']>;
  /** in minutes */
  length: Maybe<Scalars['smallint']>;
  order: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "interview_detail" */
export type Interview_Detail_Sum_Order_By = {
  job_id?: InputMaybe<Order_By>;
  /** in minutes */
  length?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** update columns of table "interview_detail" */
export enum Interview_Detail_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Length = 'length',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Interview_Detail_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Interview_Detail_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interview_Detail_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interview_Detail_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Interview_Detail_Var_Pop_Fields = {
  __typename?: 'interview_detail_var_pop_fields';
  job_id: Maybe<Scalars['Float']>;
  /** in minutes */
  length: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "interview_detail" */
export type Interview_Detail_Var_Pop_Order_By = {
  job_id?: InputMaybe<Order_By>;
  /** in minutes */
  length?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Interview_Detail_Var_Samp_Fields = {
  __typename?: 'interview_detail_var_samp_fields';
  job_id: Maybe<Scalars['Float']>;
  /** in minutes */
  length: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "interview_detail" */
export type Interview_Detail_Var_Samp_Order_By = {
  job_id?: InputMaybe<Order_By>;
  /** in minutes */
  length?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Interview_Detail_Variance_Fields = {
  __typename?: 'interview_detail_variance_fields';
  job_id: Maybe<Scalars['Float']>;
  /** in minutes */
  length: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "interview_detail" */
export type Interview_Detail_Variance_Order_By = {
  job_id?: InputMaybe<Order_By>;
  /** in minutes */
  length?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "interview_feedback" */
export type Interview_Feedback = {
  __typename?: 'interview_feedback';
  comments: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['Int'];
  /** An object relationship */
  created_by_user_info: User_Info;
  icims_applicant_workflow_id: Scalars['Int'];
  /** An object relationship */
  icims_profile_applicant_workflow: Icims_Profile_Applicant_Workflow;
  id: Scalars['Int'];
  /** An object relationship */
  interview_feedback_rating_choice: Interview_Feedback_Rating_Choices;
  rating: Interview_Feedback_Rating_Choices_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "interview_feedback" */
export type Interview_Feedback_Aggregate = {
  __typename?: 'interview_feedback_aggregate';
  aggregate: Maybe<Interview_Feedback_Aggregate_Fields>;
  nodes: Array<Interview_Feedback>;
};

export type Interview_Feedback_Aggregate_Bool_Exp = {
  count?: InputMaybe<Interview_Feedback_Aggregate_Bool_Exp_Count>;
};

export type Interview_Feedback_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Interview_Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Interview_Feedback_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "interview_feedback" */
export type Interview_Feedback_Aggregate_Fields = {
  __typename?: 'interview_feedback_aggregate_fields';
  avg: Maybe<Interview_Feedback_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Interview_Feedback_Max_Fields>;
  min: Maybe<Interview_Feedback_Min_Fields>;
  stddev: Maybe<Interview_Feedback_Stddev_Fields>;
  stddev_pop: Maybe<Interview_Feedback_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Interview_Feedback_Stddev_Samp_Fields>;
  sum: Maybe<Interview_Feedback_Sum_Fields>;
  var_pop: Maybe<Interview_Feedback_Var_Pop_Fields>;
  var_samp: Maybe<Interview_Feedback_Var_Samp_Fields>;
  variance: Maybe<Interview_Feedback_Variance_Fields>;
};

/** aggregate fields of "interview_feedback" */
export type Interview_Feedback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Interview_Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "interview_feedback" */
export type Interview_Feedback_Aggregate_Order_By = {
  avg?: InputMaybe<Interview_Feedback_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Interview_Feedback_Max_Order_By>;
  min?: InputMaybe<Interview_Feedback_Min_Order_By>;
  stddev?: InputMaybe<Interview_Feedback_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Interview_Feedback_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Interview_Feedback_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Interview_Feedback_Sum_Order_By>;
  var_pop?: InputMaybe<Interview_Feedback_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Interview_Feedback_Var_Samp_Order_By>;
  variance?: InputMaybe<Interview_Feedback_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "interview_feedback" */
export type Interview_Feedback_Arr_Rel_Insert_Input = {
  data: Array<Interview_Feedback_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Interview_Feedback_On_Conflict>;
};

/** aggregate avg on columns */
export type Interview_Feedback_Avg_Fields = {
  __typename?: 'interview_feedback_avg_fields';
  created_by: Maybe<Scalars['Float']>;
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "interview_feedback" */
export type Interview_Feedback_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>;
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "interview_feedback". All fields are combined with a logical 'AND'. */
export type Interview_Feedback_Bool_Exp = {
  _and?: InputMaybe<Array<Interview_Feedback_Bool_Exp>>;
  _not?: InputMaybe<Interview_Feedback_Bool_Exp>;
  _or?: InputMaybe<Array<Interview_Feedback_Bool_Exp>>;
  comments?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Int_Comparison_Exp>;
  created_by_user_info?: InputMaybe<User_Info_Bool_Exp>;
  icims_applicant_workflow_id?: InputMaybe<Int_Comparison_Exp>;
  icims_profile_applicant_workflow?: InputMaybe<Icims_Profile_Applicant_Workflow_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interview_feedback_rating_choice?: InputMaybe<Interview_Feedback_Rating_Choices_Bool_Exp>;
  rating?: InputMaybe<Interview_Feedback_Rating_Choices_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "interview_feedback" */
export enum Interview_Feedback_Constraint {
  /** unique or primary key constraint on columns "icims_applicant_workflow_id", "created_by" */
  InterviewFeedbackIcimsApplicantWorkflowIdCreatedByKey = 'interview_feedback_icims_applicant_workflow_id_created_by_key',
  /** unique or primary key constraint on columns "id" */
  InterviewFeedbackPkey = 'interview_feedback_pkey',
}

/** input type for incrementing numeric columns in table "interview_feedback" */
export type Interview_Feedback_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>;
  icims_applicant_workflow_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "interview_feedback" */
export type Interview_Feedback_Insert_Input = {
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  created_by_user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  icims_applicant_workflow_id?: InputMaybe<Scalars['Int']>;
  icims_profile_applicant_workflow?: InputMaybe<Icims_Profile_Applicant_Workflow_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  interview_feedback_rating_choice?: InputMaybe<Interview_Feedback_Rating_Choices_Obj_Rel_Insert_Input>;
  rating?: InputMaybe<Interview_Feedback_Rating_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Interview_Feedback_Max_Fields = {
  __typename?: 'interview_feedback_max_fields';
  comments: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['Int']>;
  icims_applicant_workflow_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "interview_feedback" */
export type Interview_Feedback_Max_Order_By = {
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Interview_Feedback_Min_Fields = {
  __typename?: 'interview_feedback_min_fields';
  comments: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['Int']>;
  icims_applicant_workflow_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "interview_feedback" */
export type Interview_Feedback_Min_Order_By = {
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "interview_feedback" */
export type Interview_Feedback_Mutation_Response = {
  __typename?: 'interview_feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interview_Feedback>;
};

/** on_conflict condition type for table "interview_feedback" */
export type Interview_Feedback_On_Conflict = {
  constraint: Interview_Feedback_Constraint;
  update_columns?: Array<Interview_Feedback_Update_Column>;
  where?: InputMaybe<Interview_Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "interview_feedback". */
export type Interview_Feedback_Order_By = {
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_user_info?: InputMaybe<User_Info_Order_By>;
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  icims_profile_applicant_workflow?: InputMaybe<Icims_Profile_Applicant_Workflow_Order_By>;
  id?: InputMaybe<Order_By>;
  interview_feedback_rating_choice?: InputMaybe<Interview_Feedback_Rating_Choices_Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: interview_feedback */
export type Interview_Feedback_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "interview_feedback_rating_choices" */
export type Interview_Feedback_Rating_Choices = {
  __typename?: 'interview_feedback_rating_choices';
  value: Scalars['String'];
};

/** aggregated selection of "interview_feedback_rating_choices" */
export type Interview_Feedback_Rating_Choices_Aggregate = {
  __typename?: 'interview_feedback_rating_choices_aggregate';
  aggregate: Maybe<Interview_Feedback_Rating_Choices_Aggregate_Fields>;
  nodes: Array<Interview_Feedback_Rating_Choices>;
};

/** aggregate fields of "interview_feedback_rating_choices" */
export type Interview_Feedback_Rating_Choices_Aggregate_Fields = {
  __typename?: 'interview_feedback_rating_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Interview_Feedback_Rating_Choices_Max_Fields>;
  min: Maybe<Interview_Feedback_Rating_Choices_Min_Fields>;
};

/** aggregate fields of "interview_feedback_rating_choices" */
export type Interview_Feedback_Rating_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "interview_feedback_rating_choices". All fields are combined with a logical 'AND'. */
export type Interview_Feedback_Rating_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Bool_Exp>>;
  _not?: InputMaybe<Interview_Feedback_Rating_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "interview_feedback_rating_choices" */
export enum Interview_Feedback_Rating_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  InterviewFeedbackRatingChoicesPkey = 'interview_feedback_rating_choices_pkey',
}

export enum Interview_Feedback_Rating_Choices_Enum {
  No = 'NO',
  StrongNo = 'STRONG_NO',
  StrongYes = 'STRONG_YES',
  Yes = 'YES',
}

/** Boolean expression to compare columns of type "interview_feedback_rating_choices_enum". All fields are combined with logical 'AND'. */
export type Interview_Feedback_Rating_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Interview_Feedback_Rating_Choices_Enum>;
  _in?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Interview_Feedback_Rating_Choices_Enum>;
  _nin?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Enum>>;
};

/** input type for inserting data into table "interview_feedback_rating_choices" */
export type Interview_Feedback_Rating_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Interview_Feedback_Rating_Choices_Max_Fields = {
  __typename?: 'interview_feedback_rating_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Interview_Feedback_Rating_Choices_Min_Fields = {
  __typename?: 'interview_feedback_rating_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "interview_feedback_rating_choices" */
export type Interview_Feedback_Rating_Choices_Mutation_Response = {
  __typename?: 'interview_feedback_rating_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interview_Feedback_Rating_Choices>;
};

/** input type for inserting object relation for remote table "interview_feedback_rating_choices" */
export type Interview_Feedback_Rating_Choices_Obj_Rel_Insert_Input = {
  data: Interview_Feedback_Rating_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Interview_Feedback_Rating_Choices_On_Conflict>;
};

/** on_conflict condition type for table "interview_feedback_rating_choices" */
export type Interview_Feedback_Rating_Choices_On_Conflict = {
  constraint: Interview_Feedback_Rating_Choices_Constraint;
  update_columns?: Array<Interview_Feedback_Rating_Choices_Update_Column>;
  where?: InputMaybe<Interview_Feedback_Rating_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "interview_feedback_rating_choices". */
export type Interview_Feedback_Rating_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: interview_feedback_rating_choices */
export type Interview_Feedback_Rating_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "interview_feedback_rating_choices" */
export enum Interview_Feedback_Rating_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "interview_feedback_rating_choices" */
export type Interview_Feedback_Rating_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "interview_feedback_rating_choices" */
export type Interview_Feedback_Rating_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interview_Feedback_Rating_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interview_Feedback_Rating_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "interview_feedback_rating_choices" */
export enum Interview_Feedback_Rating_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Interview_Feedback_Rating_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interview_Feedback_Rating_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interview_Feedback_Rating_Choices_Bool_Exp;
};

/** This table keeps the record of who was invited/requested to provide feedback on a candidate. */
export type Interview_Feedback_Request = {
  __typename?: 'interview_feedback_request';
  icims_applicant_workflow_id: Scalars['Int'];
  /** An object relationship */
  icims_profile_applicant_workflow: Icims_Profile_Applicant_Workflow;
  id: Scalars['Int'];
  interviewer_email: Maybe<Scalars['citext']>;
  /** An object relationship */
  interviewer_user: Maybe<User_Info>;
  interviewer_user_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  requester_user: User_Info;
  requester_user_id: Scalars['Int'];
};

/** aggregated selection of "interview_feedback_request" */
export type Interview_Feedback_Request_Aggregate = {
  __typename?: 'interview_feedback_request_aggregate';
  aggregate: Maybe<Interview_Feedback_Request_Aggregate_Fields>;
  nodes: Array<Interview_Feedback_Request>;
};

export type Interview_Feedback_Request_Aggregate_Bool_Exp = {
  count?: InputMaybe<Interview_Feedback_Request_Aggregate_Bool_Exp_Count>;
};

export type Interview_Feedback_Request_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Interview_Feedback_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Interview_Feedback_Request_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "interview_feedback_request" */
export type Interview_Feedback_Request_Aggregate_Fields = {
  __typename?: 'interview_feedback_request_aggregate_fields';
  avg: Maybe<Interview_Feedback_Request_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Interview_Feedback_Request_Max_Fields>;
  min: Maybe<Interview_Feedback_Request_Min_Fields>;
  stddev: Maybe<Interview_Feedback_Request_Stddev_Fields>;
  stddev_pop: Maybe<Interview_Feedback_Request_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Interview_Feedback_Request_Stddev_Samp_Fields>;
  sum: Maybe<Interview_Feedback_Request_Sum_Fields>;
  var_pop: Maybe<Interview_Feedback_Request_Var_Pop_Fields>;
  var_samp: Maybe<Interview_Feedback_Request_Var_Samp_Fields>;
  variance: Maybe<Interview_Feedback_Request_Variance_Fields>;
};

/** aggregate fields of "interview_feedback_request" */
export type Interview_Feedback_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Interview_Feedback_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "interview_feedback_request" */
export type Interview_Feedback_Request_Aggregate_Order_By = {
  avg?: InputMaybe<Interview_Feedback_Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Interview_Feedback_Request_Max_Order_By>;
  min?: InputMaybe<Interview_Feedback_Request_Min_Order_By>;
  stddev?: InputMaybe<Interview_Feedback_Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Interview_Feedback_Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Interview_Feedback_Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Interview_Feedback_Request_Sum_Order_By>;
  var_pop?: InputMaybe<Interview_Feedback_Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Interview_Feedback_Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Interview_Feedback_Request_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "interview_feedback_request" */
export type Interview_Feedback_Request_Arr_Rel_Insert_Input = {
  data: Array<Interview_Feedback_Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Interview_Feedback_Request_On_Conflict>;
};

/** aggregate avg on columns */
export type Interview_Feedback_Request_Avg_Fields = {
  __typename?: 'interview_feedback_request_avg_fields';
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interviewer_user_id: Maybe<Scalars['Float']>;
  requester_user_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "interview_feedback_request" */
export type Interview_Feedback_Request_Avg_Order_By = {
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "interview_feedback_request". All fields are combined with a logical 'AND'. */
export type Interview_Feedback_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Interview_Feedback_Request_Bool_Exp>>;
  _not?: InputMaybe<Interview_Feedback_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Interview_Feedback_Request_Bool_Exp>>;
  icims_applicant_workflow_id?: InputMaybe<Int_Comparison_Exp>;
  icims_profile_applicant_workflow?: InputMaybe<Icims_Profile_Applicant_Workflow_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interviewer_email?: InputMaybe<Citext_Comparison_Exp>;
  interviewer_user?: InputMaybe<User_Info_Bool_Exp>;
  interviewer_user_id?: InputMaybe<Int_Comparison_Exp>;
  requester_user?: InputMaybe<User_Info_Bool_Exp>;
  requester_user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "interview_feedback_request" */
export enum Interview_Feedback_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  InterviewFeedbackRequestPkey = 'interview_feedback_request_pkey',
}

/** input type for incrementing numeric columns in table "interview_feedback_request" */
export type Interview_Feedback_Request_Inc_Input = {
  icims_applicant_workflow_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewer_user_id?: InputMaybe<Scalars['Int']>;
  requester_user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "interview_feedback_request" */
export type Interview_Feedback_Request_Insert_Input = {
  icims_applicant_workflow_id?: InputMaybe<Scalars['Int']>;
  icims_profile_applicant_workflow?: InputMaybe<Icims_Profile_Applicant_Workflow_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  interviewer_email?: InputMaybe<Scalars['citext']>;
  interviewer_user?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  interviewer_user_id?: InputMaybe<Scalars['Int']>;
  requester_user?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  requester_user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Interview_Feedback_Request_Max_Fields = {
  __typename?: 'interview_feedback_request_max_fields';
  icims_applicant_workflow_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  interviewer_email: Maybe<Scalars['citext']>;
  interviewer_user_id: Maybe<Scalars['Int']>;
  requester_user_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "interview_feedback_request" */
export type Interview_Feedback_Request_Max_Order_By = {
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_email?: InputMaybe<Order_By>;
  interviewer_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Interview_Feedback_Request_Min_Fields = {
  __typename?: 'interview_feedback_request_min_fields';
  icims_applicant_workflow_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  interviewer_email: Maybe<Scalars['citext']>;
  interviewer_user_id: Maybe<Scalars['Int']>;
  requester_user_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "interview_feedback_request" */
export type Interview_Feedback_Request_Min_Order_By = {
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_email?: InputMaybe<Order_By>;
  interviewer_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "interview_feedback_request" */
export type Interview_Feedback_Request_Mutation_Response = {
  __typename?: 'interview_feedback_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interview_Feedback_Request>;
};

/** on_conflict condition type for table "interview_feedback_request" */
export type Interview_Feedback_Request_On_Conflict = {
  constraint: Interview_Feedback_Request_Constraint;
  update_columns?: Array<Interview_Feedback_Request_Update_Column>;
  where?: InputMaybe<Interview_Feedback_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "interview_feedback_request". */
export type Interview_Feedback_Request_Order_By = {
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  icims_profile_applicant_workflow?: InputMaybe<Icims_Profile_Applicant_Workflow_Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_email?: InputMaybe<Order_By>;
  interviewer_user?: InputMaybe<User_Info_Order_By>;
  interviewer_user_id?: InputMaybe<Order_By>;
  requester_user?: InputMaybe<User_Info_Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: interview_feedback_request */
export type Interview_Feedback_Request_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "interview_feedback_request" */
export enum Interview_Feedback_Request_Select_Column {
  /** column name */
  IcimsApplicantWorkflowId = 'icims_applicant_workflow_id',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewerEmail = 'interviewer_email',
  /** column name */
  InterviewerUserId = 'interviewer_user_id',
  /** column name */
  RequesterUserId = 'requester_user_id',
}

/** input type for updating data in table "interview_feedback_request" */
export type Interview_Feedback_Request_Set_Input = {
  icims_applicant_workflow_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewer_email?: InputMaybe<Scalars['citext']>;
  interviewer_user_id?: InputMaybe<Scalars['Int']>;
  requester_user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Interview_Feedback_Request_Stddev_Fields = {
  __typename?: 'interview_feedback_request_stddev_fields';
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interviewer_user_id: Maybe<Scalars['Float']>;
  requester_user_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "interview_feedback_request" */
export type Interview_Feedback_Request_Stddev_Order_By = {
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Interview_Feedback_Request_Stddev_Pop_Fields = {
  __typename?: 'interview_feedback_request_stddev_pop_fields';
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interviewer_user_id: Maybe<Scalars['Float']>;
  requester_user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "interview_feedback_request" */
export type Interview_Feedback_Request_Stddev_Pop_Order_By = {
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Interview_Feedback_Request_Stddev_Samp_Fields = {
  __typename?: 'interview_feedback_request_stddev_samp_fields';
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interviewer_user_id: Maybe<Scalars['Float']>;
  requester_user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "interview_feedback_request" */
export type Interview_Feedback_Request_Stddev_Samp_Order_By = {
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "interview_feedback_request" */
export type Interview_Feedback_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interview_Feedback_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interview_Feedback_Request_Stream_Cursor_Value_Input = {
  icims_applicant_workflow_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewer_email?: InputMaybe<Scalars['citext']>;
  interviewer_user_id?: InputMaybe<Scalars['Int']>;
  requester_user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Interview_Feedback_Request_Sum_Fields = {
  __typename?: 'interview_feedback_request_sum_fields';
  icims_applicant_workflow_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  interviewer_user_id: Maybe<Scalars['Int']>;
  requester_user_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "interview_feedback_request" */
export type Interview_Feedback_Request_Sum_Order_By = {
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** update columns of table "interview_feedback_request" */
export enum Interview_Feedback_Request_Update_Column {
  /** column name */
  IcimsApplicantWorkflowId = 'icims_applicant_workflow_id',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewerEmail = 'interviewer_email',
  /** column name */
  InterviewerUserId = 'interviewer_user_id',
  /** column name */
  RequesterUserId = 'requester_user_id',
}

export type Interview_Feedback_Request_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Interview_Feedback_Request_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interview_Feedback_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interview_Feedback_Request_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Interview_Feedback_Request_Var_Pop_Fields = {
  __typename?: 'interview_feedback_request_var_pop_fields';
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interviewer_user_id: Maybe<Scalars['Float']>;
  requester_user_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "interview_feedback_request" */
export type Interview_Feedback_Request_Var_Pop_Order_By = {
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Interview_Feedback_Request_Var_Samp_Fields = {
  __typename?: 'interview_feedback_request_var_samp_fields';
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interviewer_user_id: Maybe<Scalars['Float']>;
  requester_user_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "interview_feedback_request" */
export type Interview_Feedback_Request_Var_Samp_Order_By = {
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Interview_Feedback_Request_Variance_Fields = {
  __typename?: 'interview_feedback_request_variance_fields';
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  interviewer_user_id: Maybe<Scalars['Float']>;
  requester_user_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "interview_feedback_request" */
export type Interview_Feedback_Request_Variance_Order_By = {
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interviewer_user_id?: InputMaybe<Order_By>;
  requester_user_id?: InputMaybe<Order_By>;
};

/** select columns of table "interview_feedback" */
export enum Interview_Feedback_Select_Column {
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  IcimsApplicantWorkflowId = 'icims_applicant_workflow_id',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "interview_feedback" */
export type Interview_Feedback_Set_Input = {
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  icims_applicant_workflow_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Interview_Feedback_Rating_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Interview_Feedback_Stddev_Fields = {
  __typename?: 'interview_feedback_stddev_fields';
  created_by: Maybe<Scalars['Float']>;
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "interview_feedback" */
export type Interview_Feedback_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>;
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Interview_Feedback_Stddev_Pop_Fields = {
  __typename?: 'interview_feedback_stddev_pop_fields';
  created_by: Maybe<Scalars['Float']>;
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "interview_feedback" */
export type Interview_Feedback_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Interview_Feedback_Stddev_Samp_Fields = {
  __typename?: 'interview_feedback_stddev_samp_fields';
  created_by: Maybe<Scalars['Float']>;
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "interview_feedback" */
export type Interview_Feedback_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "interview_feedback" */
export type Interview_Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interview_Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interview_Feedback_Stream_Cursor_Value_Input = {
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['Int']>;
  icims_applicant_workflow_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Interview_Feedback_Rating_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Interview_Feedback_Sum_Fields = {
  __typename?: 'interview_feedback_sum_fields';
  created_by: Maybe<Scalars['Int']>;
  icims_applicant_workflow_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "interview_feedback" */
export type Interview_Feedback_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>;
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "interview_feedback" */
export enum Interview_Feedback_Update_Column {
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  IcimsApplicantWorkflowId = 'icims_applicant_workflow_id',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Interview_Feedback_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Interview_Feedback_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interview_Feedback_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interview_Feedback_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Interview_Feedback_Var_Pop_Fields = {
  __typename?: 'interview_feedback_var_pop_fields';
  created_by: Maybe<Scalars['Float']>;
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "interview_feedback" */
export type Interview_Feedback_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>;
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Interview_Feedback_Var_Samp_Fields = {
  __typename?: 'interview_feedback_var_samp_fields';
  created_by: Maybe<Scalars['Float']>;
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "interview_feedback" */
export type Interview_Feedback_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>;
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Interview_Feedback_Variance_Fields = {
  __typename?: 'interview_feedback_variance_fields';
  created_by: Maybe<Scalars['Float']>;
  icims_applicant_workflow_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "interview_feedback" */
export type Interview_Feedback_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>;
  icims_applicant_workflow_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Interview requests from terminal users to candidates */
export type Interview_Request = {
  __typename?: 'interview_request';
  /** An array relationship */
  calendly_invitees: Array<Calendly_Invitee>;
  /** An aggregate relationship */
  calendly_invitees_aggregate: Calendly_Invitee_Aggregate;
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** Single-use links are possible in Calendly to prevent link sharing for booking screenings */
  invite_link: Scalars['String'];
  /** An object relationship */
  requester: Maybe<User_Info>;
  requester_user_info_id: Maybe<Scalars['Int']>;
  /** PHONE_SCREEN is the only current use-case but others may be possible in the future */
  type: Interview_Type_Choices_Enum;
  updated_at: Scalars['timestamptz'];
};

/** Interview requests from terminal users to candidates */
export type Interview_RequestCalendly_InviteesArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Invitee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Invitee_Order_By>>;
  where?: InputMaybe<Calendly_Invitee_Bool_Exp>;
};

/** Interview requests from terminal users to candidates */
export type Interview_RequestCalendly_Invitees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Invitee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Invitee_Order_By>>;
  where?: InputMaybe<Calendly_Invitee_Bool_Exp>;
};

/** aggregated selection of "interview_request" */
export type Interview_Request_Aggregate = {
  __typename?: 'interview_request_aggregate';
  aggregate: Maybe<Interview_Request_Aggregate_Fields>;
  nodes: Array<Interview_Request>;
};

export type Interview_Request_Aggregate_Bool_Exp = {
  count?: InputMaybe<Interview_Request_Aggregate_Bool_Exp_Count>;
};

export type Interview_Request_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Interview_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Interview_Request_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "interview_request" */
export type Interview_Request_Aggregate_Fields = {
  __typename?: 'interview_request_aggregate_fields';
  avg: Maybe<Interview_Request_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Interview_Request_Max_Fields>;
  min: Maybe<Interview_Request_Min_Fields>;
  stddev: Maybe<Interview_Request_Stddev_Fields>;
  stddev_pop: Maybe<Interview_Request_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Interview_Request_Stddev_Samp_Fields>;
  sum: Maybe<Interview_Request_Sum_Fields>;
  var_pop: Maybe<Interview_Request_Var_Pop_Fields>;
  var_samp: Maybe<Interview_Request_Var_Samp_Fields>;
  variance: Maybe<Interview_Request_Variance_Fields>;
};

/** aggregate fields of "interview_request" */
export type Interview_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Interview_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "interview_request" */
export type Interview_Request_Aggregate_Order_By = {
  avg?: InputMaybe<Interview_Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Interview_Request_Max_Order_By>;
  min?: InputMaybe<Interview_Request_Min_Order_By>;
  stddev?: InputMaybe<Interview_Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Interview_Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Interview_Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Interview_Request_Sum_Order_By>;
  var_pop?: InputMaybe<Interview_Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Interview_Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Interview_Request_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "interview_request" */
export type Interview_Request_Arr_Rel_Insert_Input = {
  data: Array<Interview_Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Interview_Request_On_Conflict>;
};

/** aggregate avg on columns */
export type Interview_Request_Avg_Fields = {
  __typename?: 'interview_request_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  requester_user_info_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "interview_request" */
export type Interview_Request_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requester_user_info_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "interview_request". All fields are combined with a logical 'AND'. */
export type Interview_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Interview_Request_Bool_Exp>>;
  _not?: InputMaybe<Interview_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Interview_Request_Bool_Exp>>;
  calendly_invitees?: InputMaybe<Calendly_Invitee_Bool_Exp>;
  calendly_invitees_aggregate?: InputMaybe<Calendly_Invitee_Aggregate_Bool_Exp>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invite_link?: InputMaybe<String_Comparison_Exp>;
  requester?: InputMaybe<User_Info_Bool_Exp>;
  requester_user_info_id?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<Interview_Type_Choices_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "interview_request" */
export enum Interview_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  InterviewRequestPkey = 'interview_request_pkey',
}

/** input type for incrementing numeric columns in table "interview_request" */
export type Interview_Request_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  requester_user_info_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "interview_request" */
export type Interview_Request_Insert_Input = {
  calendly_invitees?: InputMaybe<Calendly_Invitee_Arr_Rel_Insert_Input>;
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  /** Single-use links are possible in Calendly to prevent link sharing for booking screenings */
  invite_link?: InputMaybe<Scalars['String']>;
  requester?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  requester_user_info_id?: InputMaybe<Scalars['Int']>;
  /** PHONE_SCREEN is the only current use-case but others may be possible in the future */
  type?: InputMaybe<Interview_Type_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Interview_Request_Max_Fields = {
  __typename?: 'interview_request_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  /** Single-use links are possible in Calendly to prevent link sharing for booking screenings */
  invite_link: Maybe<Scalars['String']>;
  requester_user_info_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "interview_request" */
export type Interview_Request_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Single-use links are possible in Calendly to prevent link sharing for booking screenings */
  invite_link?: InputMaybe<Order_By>;
  requester_user_info_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Interview_Request_Min_Fields = {
  __typename?: 'interview_request_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  /** Single-use links are possible in Calendly to prevent link sharing for booking screenings */
  invite_link: Maybe<Scalars['String']>;
  requester_user_info_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "interview_request" */
export type Interview_Request_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Single-use links are possible in Calendly to prevent link sharing for booking screenings */
  invite_link?: InputMaybe<Order_By>;
  requester_user_info_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "interview_request" */
export type Interview_Request_Mutation_Response = {
  __typename?: 'interview_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interview_Request>;
};

/** input type for inserting object relation for remote table "interview_request" */
export type Interview_Request_Obj_Rel_Insert_Input = {
  data: Interview_Request_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Interview_Request_On_Conflict>;
};

/** on_conflict condition type for table "interview_request" */
export type Interview_Request_On_Conflict = {
  constraint: Interview_Request_Constraint;
  update_columns?: Array<Interview_Request_Update_Column>;
  where?: InputMaybe<Interview_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "interview_request". */
export type Interview_Request_Order_By = {
  calendly_invitees_aggregate?: InputMaybe<Calendly_Invitee_Aggregate_Order_By>;
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invite_link?: InputMaybe<Order_By>;
  requester?: InputMaybe<User_Info_Order_By>;
  requester_user_info_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: interview_request */
export type Interview_Request_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "interview_request" */
export enum Interview_Request_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteLink = 'invite_link',
  /** column name */
  RequesterUserInfoId = 'requester_user_info_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "interview_request" */
export type Interview_Request_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  /** Single-use links are possible in Calendly to prevent link sharing for booking screenings */
  invite_link?: InputMaybe<Scalars['String']>;
  requester_user_info_id?: InputMaybe<Scalars['Int']>;
  /** PHONE_SCREEN is the only current use-case but others may be possible in the future */
  type?: InputMaybe<Interview_Type_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Interview_Request_Stddev_Fields = {
  __typename?: 'interview_request_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  requester_user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "interview_request" */
export type Interview_Request_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requester_user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Interview_Request_Stddev_Pop_Fields = {
  __typename?: 'interview_request_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  requester_user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "interview_request" */
export type Interview_Request_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requester_user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Interview_Request_Stddev_Samp_Fields = {
  __typename?: 'interview_request_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  requester_user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "interview_request" */
export type Interview_Request_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requester_user_info_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "interview_request" */
export type Interview_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interview_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interview_Request_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  /** Single-use links are possible in Calendly to prevent link sharing for booking screenings */
  invite_link?: InputMaybe<Scalars['String']>;
  requester_user_info_id?: InputMaybe<Scalars['Int']>;
  /** PHONE_SCREEN is the only current use-case but others may be possible in the future */
  type?: InputMaybe<Interview_Type_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Interview_Request_Sum_Fields = {
  __typename?: 'interview_request_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  requester_user_info_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "interview_request" */
export type Interview_Request_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requester_user_info_id?: InputMaybe<Order_By>;
};

/** update columns of table "interview_request" */
export enum Interview_Request_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteLink = 'invite_link',
  /** column name */
  RequesterUserInfoId = 'requester_user_info_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Interview_Request_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Interview_Request_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interview_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interview_Request_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Interview_Request_Var_Pop_Fields = {
  __typename?: 'interview_request_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  requester_user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "interview_request" */
export type Interview_Request_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requester_user_info_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Interview_Request_Var_Samp_Fields = {
  __typename?: 'interview_request_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  requester_user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "interview_request" */
export type Interview_Request_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requester_user_info_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Interview_Request_Variance_Fields = {
  __typename?: 'interview_request_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  requester_user_info_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "interview_request" */
export type Interview_Request_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requester_user_info_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "interview_type_choices" */
export type Interview_Type_Choices = {
  __typename?: 'interview_type_choices';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "interview_type_choices" */
export type Interview_Type_Choices_Aggregate = {
  __typename?: 'interview_type_choices_aggregate';
  aggregate: Maybe<Interview_Type_Choices_Aggregate_Fields>;
  nodes: Array<Interview_Type_Choices>;
};

/** aggregate fields of "interview_type_choices" */
export type Interview_Type_Choices_Aggregate_Fields = {
  __typename?: 'interview_type_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Interview_Type_Choices_Max_Fields>;
  min: Maybe<Interview_Type_Choices_Min_Fields>;
};

/** aggregate fields of "interview_type_choices" */
export type Interview_Type_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Interview_Type_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "interview_type_choices". All fields are combined with a logical 'AND'. */
export type Interview_Type_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Interview_Type_Choices_Bool_Exp>>;
  _not?: InputMaybe<Interview_Type_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Interview_Type_Choices_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "interview_type_choices" */
export enum Interview_Type_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  InterviewTypeChoicesPkey = 'interview_type_choices_pkey',
}

export enum Interview_Type_Choices_Enum {
  /** Async Interview */
  AsyncInterview = 'ASYNC_INTERVIEW',
  /** Final Interview */
  FinalInterview = 'FINAL_INTERVIEW',
  /** Hiring Manager Interview */
  HiringManagerInterview = 'HIRING_MANAGER_INTERVIEW',
  /** Phone Screen */
  PhoneScreen = 'PHONE_SCREEN',
  /** Technical Assessment - Take Home */
  TechnicalAssessmentHome = 'TECHNICAL_ASSESSMENT_HOME',
  /** Technical Interview */
  TechnicalInterview = 'TECHNICAL_INTERVIEW',
  /** Virtual Onsite */
  VirtualOnsite = 'VIRTUAL_ONSITE',
}

/** Boolean expression to compare columns of type "interview_type_choices_enum". All fields are combined with logical 'AND'. */
export type Interview_Type_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Interview_Type_Choices_Enum>;
  _in?: InputMaybe<Array<Interview_Type_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Interview_Type_Choices_Enum>;
  _nin?: InputMaybe<Array<Interview_Type_Choices_Enum>>;
};

/** input type for inserting data into table "interview_type_choices" */
export type Interview_Type_Choices_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Interview_Type_Choices_Max_Fields = {
  __typename?: 'interview_type_choices_max_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Interview_Type_Choices_Min_Fields = {
  __typename?: 'interview_type_choices_min_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "interview_type_choices" */
export type Interview_Type_Choices_Mutation_Response = {
  __typename?: 'interview_type_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interview_Type_Choices>;
};

/** input type for inserting object relation for remote table "interview_type_choices" */
export type Interview_Type_Choices_Obj_Rel_Insert_Input = {
  data: Interview_Type_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Interview_Type_Choices_On_Conflict>;
};

/** on_conflict condition type for table "interview_type_choices" */
export type Interview_Type_Choices_On_Conflict = {
  constraint: Interview_Type_Choices_Constraint;
  update_columns?: Array<Interview_Type_Choices_Update_Column>;
  where?: InputMaybe<Interview_Type_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "interview_type_choices". */
export type Interview_Type_Choices_Order_By = {
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: interview_type_choices */
export type Interview_Type_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "interview_type_choices" */
export enum Interview_Type_Choices_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "interview_type_choices" */
export type Interview_Type_Choices_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "interview_type_choices" */
export type Interview_Type_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interview_Type_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interview_Type_Choices_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "interview_type_choices" */
export enum Interview_Type_Choices_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

export type Interview_Type_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interview_Type_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interview_Type_Choices_Bool_Exp;
};

/** Primary table to save all terminal product generated job data.  */
export type Job = {
  __typename?: 'job';
  about: Maybe<Scalars['String']>;
  /** An array relationship */
  candidate_job_workflows: Array<Candidate_Job_Workflow>;
  /** An aggregate relationship */
  candidate_job_workflows_aggregate: Candidate_Job_Workflow_Aggregate;
  contract_length: Maybe<Contract_Length_Choices_Enum>;
  contract_to_hire: Maybe<Scalars['Boolean']>;
  created_at: Maybe<Scalars['timestamptz']>;
  education_requirement: Maybe<Candidate_Education_Degree_Choices_Enum>;
  employment_type: Maybe<Employment_Type_Choices_Enum>;
  /** An object relationship */
  hiring_manager: Maybe<User_Info>;
  hiring_manager_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  hiring_manager_user_invitation: Maybe<User_Invitation>;
  hiring_manager_user_invite_id: Maybe<Scalars['Int']>;
  icims_job_profile_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  icims_profile_job: Maybe<Icims_Profile_Job>;
  id: Scalars['Int'];
  initial_headcount: Scalars['Int'];
  /** An array relationship */
  interview_details: Array<Interview_Detail>;
  /** An aggregate relationship */
  interview_details_aggregate: Interview_Detail_Aggregate;
  /** An array relationship */
  job_acceptable_locations: Array<Job_Acceptable_Location>;
  /** An aggregate relationship */
  job_acceptable_locations_aggregate: Job_Acceptable_Location_Aggregate;
  /** An object relationship */
  job_meta_info: Maybe<Job_Meta_Info>;
  /** An array relationship */
  job_meta_infos: Array<Job_Meta_Info>;
  /** An aggregate relationship */
  job_meta_infos_aggregate: Job_Meta_Info_Aggregate;
  /** An array relationship */
  job_nice_to_have_skills: Array<Job_Nice_To_Have_Skill>;
  /** An aggregate relationship */
  job_nice_to_have_skills_aggregate: Job_Nice_To_Have_Skill_Aggregate;
  job_offering_selection: Maybe<Offering_Selection_Choices_Enum>;
  /** An array relationship */
  job_positions: Array<Job_Position>;
  /** An aggregate relationship */
  job_positions_aggregate: Job_Position_Aggregate;
  /** An array relationship */
  job_required_skills: Array<Job_Required_Skill>;
  /** An aggregate relationship */
  job_required_skills_aggregate: Job_Required_Skill_Aggregate;
  /** An array relationship */
  job_status_logs: Array<Job_Status_Log>;
  /** An aggregate relationship */
  job_status_logs_aggregate: Job_Status_Log_Aggregate;
  /** An array relationship */
  job_tech_stack: Array<Job_Tech_Stack>;
  /** An aggregate relationship */
  job_tech_stack_aggregate: Job_Tech_Stack_Aggregate;
  level: Maybe<Job_Level_Choices_Enum>;
  /** An object relationship */
  level_choice: Maybe<Job_Level_Choices>;
  max_contract_rate: Maybe<Scalars['numeric']>;
  max_salary: Maybe<Scalars['numeric']>;
  merge_ats_job_id: Maybe<Scalars['String']>;
  min_contract_rate: Maybe<Scalars['numeric']>;
  min_salary: Maybe<Scalars['numeric']>;
  min_years_of_experience: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  notes: Maybe<Scalars['String']>;
  offering_bonus: Scalars['Boolean'];
  offering_equity: Scalars['Boolean'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['Int'];
  posted: Maybe<Scalars['Boolean']>;
  priority: Job_Priority_Choices_Enum;
  role_type: Maybe<Role_Choices_Enum>;
  service_type: Maybe<Job_Service_Type_Enum>;
  status: Job_Status_Choices_Enum;
  sync_status: Icims_Sync_Status_Choices_Enum;
  title: Scalars['String'];
  tp_kickoff_date: Maybe<Scalars['date']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  what_youll_bring: Maybe<Scalars['String']>;
  what_youll_do: Maybe<Scalars['String']>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobCandidate_Job_WorkflowsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobCandidate_Job_Workflows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobInterview_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Interview_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Detail_Order_By>>;
  where?: InputMaybe<Interview_Detail_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobInterview_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Detail_Order_By>>;
  where?: InputMaybe<Interview_Detail_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Acceptable_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Job_Acceptable_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Acceptable_Location_Order_By>>;
  where?: InputMaybe<Job_Acceptable_Location_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Acceptable_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Acceptable_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Acceptable_Location_Order_By>>;
  where?: InputMaybe<Job_Acceptable_Location_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Meta_InfosArgs = {
  distinct_on?: InputMaybe<Array<Job_Meta_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Meta_Info_Order_By>>;
  where?: InputMaybe<Job_Meta_Info_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Meta_Infos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Meta_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Meta_Info_Order_By>>;
  where?: InputMaybe<Job_Meta_Info_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Nice_To_Have_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Job_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Job_Nice_To_Have_Skill_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Nice_To_Have_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Job_Nice_To_Have_Skill_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_PositionsArgs = {
  distinct_on?: InputMaybe<Array<Job_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Position_Order_By>>;
  where?: InputMaybe<Job_Position_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Positions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Position_Order_By>>;
  where?: InputMaybe<Job_Position_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Required_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Job_Required_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Required_Skill_Order_By>>;
  where?: InputMaybe<Job_Required_Skill_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Required_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Required_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Required_Skill_Order_By>>;
  where?: InputMaybe<Job_Required_Skill_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Status_LogsArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Log_Order_By>>;
  where?: InputMaybe<Job_Status_Log_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Status_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Log_Order_By>>;
  where?: InputMaybe<Job_Status_Log_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Tech_StackArgs = {
  distinct_on?: InputMaybe<Array<Job_Tech_Stack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Tech_Stack_Order_By>>;
  where?: InputMaybe<Job_Tech_Stack_Bool_Exp>;
};

/** Primary table to save all terminal product generated job data.  */
export type JobJob_Tech_Stack_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Tech_Stack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Tech_Stack_Order_By>>;
  where?: InputMaybe<Job_Tech_Stack_Bool_Exp>;
};

/** columns and relationships of "job_acceptable_location" */
export type Job_Acceptable_Location = {
  __typename?: 'job_acceptable_location';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['Int'];
  /** An object relationship */
  location: Location;
  location_id: Scalars['Int'];
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "job_acceptable_location" */
export type Job_Acceptable_Location_Aggregate = {
  __typename?: 'job_acceptable_location_aggregate';
  aggregate: Maybe<Job_Acceptable_Location_Aggregate_Fields>;
  nodes: Array<Job_Acceptable_Location>;
};

export type Job_Acceptable_Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Acceptable_Location_Aggregate_Bool_Exp_Count>;
};

export type Job_Acceptable_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Acceptable_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Acceptable_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_acceptable_location" */
export type Job_Acceptable_Location_Aggregate_Fields = {
  __typename?: 'job_acceptable_location_aggregate_fields';
  avg: Maybe<Job_Acceptable_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Job_Acceptable_Location_Max_Fields>;
  min: Maybe<Job_Acceptable_Location_Min_Fields>;
  stddev: Maybe<Job_Acceptable_Location_Stddev_Fields>;
  stddev_pop: Maybe<Job_Acceptable_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Job_Acceptable_Location_Stddev_Samp_Fields>;
  sum: Maybe<Job_Acceptable_Location_Sum_Fields>;
  var_pop: Maybe<Job_Acceptable_Location_Var_Pop_Fields>;
  var_samp: Maybe<Job_Acceptable_Location_Var_Samp_Fields>;
  variance: Maybe<Job_Acceptable_Location_Variance_Fields>;
};

/** aggregate fields of "job_acceptable_location" */
export type Job_Acceptable_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Acceptable_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_acceptable_location" */
export type Job_Acceptable_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Acceptable_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Acceptable_Location_Max_Order_By>;
  min?: InputMaybe<Job_Acceptable_Location_Min_Order_By>;
  stddev?: InputMaybe<Job_Acceptable_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Acceptable_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Acceptable_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Acceptable_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Acceptable_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Acceptable_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Acceptable_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_acceptable_location" */
export type Job_Acceptable_Location_Arr_Rel_Insert_Input = {
  data: Array<Job_Acceptable_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Acceptable_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Acceptable_Location_Avg_Fields = {
  __typename?: 'job_acceptable_location_avg_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_acceptable_location" */
export type Job_Acceptable_Location_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_acceptable_location". All fields are combined with a logical 'AND'. */
export type Job_Acceptable_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Acceptable_Location_Bool_Exp>>;
  _not?: InputMaybe<Job_Acceptable_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Acceptable_Location_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_acceptable_location" */
export enum Job_Acceptable_Location_Constraint {
  /** unique or primary key constraint on columns "location_id", "job_id" */
  JobAcceptableLocationJobIdLocationIdKey = 'job_acceptable_location_job_id_location_id_key',
  /** unique or primary key constraint on columns "id" */
  JobAcceptableLocationPkey = 'job_acceptable_location_pkey',
}

/** input type for incrementing numeric columns in table "job_acceptable_location" */
export type Job_Acceptable_Location_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_acceptable_location" */
export type Job_Acceptable_Location_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Job_Acceptable_Location_Max_Fields = {
  __typename?: 'job_acceptable_location_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "job_acceptable_location" */
export type Job_Acceptable_Location_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Acceptable_Location_Min_Fields = {
  __typename?: 'job_acceptable_location_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "job_acceptable_location" */
export type Job_Acceptable_Location_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_acceptable_location" */
export type Job_Acceptable_Location_Mutation_Response = {
  __typename?: 'job_acceptable_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Acceptable_Location>;
};

/** on_conflict condition type for table "job_acceptable_location" */
export type Job_Acceptable_Location_On_Conflict = {
  constraint: Job_Acceptable_Location_Constraint;
  update_columns?: Array<Job_Acceptable_Location_Update_Column>;
  where?: InputMaybe<Job_Acceptable_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "job_acceptable_location". */
export type Job_Acceptable_Location_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  location_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_acceptable_location */
export type Job_Acceptable_Location_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "job_acceptable_location" */
export enum Job_Acceptable_Location_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "job_acceptable_location" */
export type Job_Acceptable_Location_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Job_Acceptable_Location_Stddev_Fields = {
  __typename?: 'job_acceptable_location_stddev_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_acceptable_location" */
export type Job_Acceptable_Location_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Acceptable_Location_Stddev_Pop_Fields = {
  __typename?: 'job_acceptable_location_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_acceptable_location" */
export type Job_Acceptable_Location_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Acceptable_Location_Stddev_Samp_Fields = {
  __typename?: 'job_acceptable_location_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_acceptable_location" */
export type Job_Acceptable_Location_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_acceptable_location" */
export type Job_Acceptable_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Acceptable_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Acceptable_Location_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Job_Acceptable_Location_Sum_Fields = {
  __typename?: 'job_acceptable_location_sum_fields';
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_acceptable_location" */
export type Job_Acceptable_Location_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** update columns of table "job_acceptable_location" */
export enum Job_Acceptable_Location_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Job_Acceptable_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Acceptable_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Acceptable_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Acceptable_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Acceptable_Location_Var_Pop_Fields = {
  __typename?: 'job_acceptable_location_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_acceptable_location" */
export type Job_Acceptable_Location_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Acceptable_Location_Var_Samp_Fields = {
  __typename?: 'job_acceptable_location_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_acceptable_location" */
export type Job_Acceptable_Location_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Acceptable_Location_Variance_Fields = {
  __typename?: 'job_acceptable_location_variance_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_acceptable_location" */
export type Job_Acceptable_Location_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "job" */
export type Job_Aggregate = {
  __typename?: 'job_aggregate';
  aggregate: Maybe<Job_Aggregate_Fields>;
  nodes: Array<Job>;
};

export type Job_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Job_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Job_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Job_Aggregate_Bool_Exp_Count>;
};

export type Job_Aggregate_Bool_Exp_Bool_And = {
  arguments: Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Job_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Job_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job" */
export type Job_Aggregate_Fields = {
  __typename?: 'job_aggregate_fields';
  avg: Maybe<Job_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Job_Max_Fields>;
  min: Maybe<Job_Min_Fields>;
  stddev: Maybe<Job_Stddev_Fields>;
  stddev_pop: Maybe<Job_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Job_Stddev_Samp_Fields>;
  sum: Maybe<Job_Sum_Fields>;
  var_pop: Maybe<Job_Var_Pop_Fields>;
  var_samp: Maybe<Job_Var_Samp_Fields>;
  variance: Maybe<Job_Variance_Fields>;
};

/** aggregate fields of "job" */
export type Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job" */
export type Job_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Max_Order_By>;
  min?: InputMaybe<Job_Min_Order_By>;
  stddev?: InputMaybe<Job_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job" */
export type Job_Arr_Rel_Insert_Input = {
  data: Array<Job_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Avg_Fields = {
  __typename?: 'job_avg_fields';
  hiring_manager_id: Maybe<Scalars['Float']>;
  hiring_manager_user_invite_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job" */
export type Job_Avg_Order_By = {
  hiring_manager_id?: InputMaybe<Order_By>;
  hiring_manager_user_invite_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job". All fields are combined with a logical 'AND'. */
export type Job_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Bool_Exp>>;
  _not?: InputMaybe<Job_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Bool_Exp>>;
  about?: InputMaybe<String_Comparison_Exp>;
  candidate_job_workflows?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
  candidate_job_workflows_aggregate?: InputMaybe<Candidate_Job_Workflow_Aggregate_Bool_Exp>;
  contract_length?: InputMaybe<Contract_Length_Choices_Enum_Comparison_Exp>;
  contract_to_hire?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  education_requirement?: InputMaybe<Candidate_Education_Degree_Choices_Enum_Comparison_Exp>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum_Comparison_Exp>;
  hiring_manager?: InputMaybe<User_Info_Bool_Exp>;
  hiring_manager_id?: InputMaybe<Int_Comparison_Exp>;
  hiring_manager_user_invitation?: InputMaybe<User_Invitation_Bool_Exp>;
  hiring_manager_user_invite_id?: InputMaybe<Int_Comparison_Exp>;
  icims_job_profile_id?: InputMaybe<Int_Comparison_Exp>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  initial_headcount?: InputMaybe<Int_Comparison_Exp>;
  interview_details?: InputMaybe<Interview_Detail_Bool_Exp>;
  interview_details_aggregate?: InputMaybe<Interview_Detail_Aggregate_Bool_Exp>;
  job_acceptable_locations?: InputMaybe<Job_Acceptable_Location_Bool_Exp>;
  job_acceptable_locations_aggregate?: InputMaybe<Job_Acceptable_Location_Aggregate_Bool_Exp>;
  job_meta_info?: InputMaybe<Job_Meta_Info_Bool_Exp>;
  job_meta_infos?: InputMaybe<Job_Meta_Info_Bool_Exp>;
  job_meta_infos_aggregate?: InputMaybe<Job_Meta_Info_Aggregate_Bool_Exp>;
  job_nice_to_have_skills?: InputMaybe<Job_Nice_To_Have_Skill_Bool_Exp>;
  job_nice_to_have_skills_aggregate?: InputMaybe<Job_Nice_To_Have_Skill_Aggregate_Bool_Exp>;
  job_offering_selection?: InputMaybe<Offering_Selection_Choices_Enum_Comparison_Exp>;
  job_positions?: InputMaybe<Job_Position_Bool_Exp>;
  job_positions_aggregate?: InputMaybe<Job_Position_Aggregate_Bool_Exp>;
  job_required_skills?: InputMaybe<Job_Required_Skill_Bool_Exp>;
  job_required_skills_aggregate?: InputMaybe<Job_Required_Skill_Aggregate_Bool_Exp>;
  job_status_logs?: InputMaybe<Job_Status_Log_Bool_Exp>;
  job_status_logs_aggregate?: InputMaybe<Job_Status_Log_Aggregate_Bool_Exp>;
  job_tech_stack?: InputMaybe<Job_Tech_Stack_Bool_Exp>;
  job_tech_stack_aggregate?: InputMaybe<Job_Tech_Stack_Aggregate_Bool_Exp>;
  level?: InputMaybe<Job_Level_Choices_Enum_Comparison_Exp>;
  level_choice?: InputMaybe<Job_Level_Choices_Bool_Exp>;
  max_contract_rate?: InputMaybe<Numeric_Comparison_Exp>;
  max_salary?: InputMaybe<Numeric_Comparison_Exp>;
  merge_ats_job_id?: InputMaybe<String_Comparison_Exp>;
  min_contract_rate?: InputMaybe<Numeric_Comparison_Exp>;
  min_salary?: InputMaybe<Numeric_Comparison_Exp>;
  min_years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  offering_bonus?: InputMaybe<Boolean_Comparison_Exp>;
  offering_equity?: InputMaybe<Boolean_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  posted?: InputMaybe<Boolean_Comparison_Exp>;
  priority?: InputMaybe<Job_Priority_Choices_Enum_Comparison_Exp>;
  role_type?: InputMaybe<Role_Choices_Enum_Comparison_Exp>;
  service_type?: InputMaybe<Job_Service_Type_Enum_Comparison_Exp>;
  status?: InputMaybe<Job_Status_Choices_Enum_Comparison_Exp>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  tp_kickoff_date?: InputMaybe<Date_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  what_youll_bring?: InputMaybe<String_Comparison_Exp>;
  what_youll_do?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "job_code" */
export type Job_Code = {
  __typename?: 'job_code';
  code: Scalars['String'];
  level: Scalars['smallint'];
  role: Role_Choices_Enum;
  title: Scalars['String'];
};

/** aggregated selection of "job_code" */
export type Job_Code_Aggregate = {
  __typename?: 'job_code_aggregate';
  aggregate: Maybe<Job_Code_Aggregate_Fields>;
  nodes: Array<Job_Code>;
};

/** aggregate fields of "job_code" */
export type Job_Code_Aggregate_Fields = {
  __typename?: 'job_code_aggregate_fields';
  avg: Maybe<Job_Code_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Job_Code_Max_Fields>;
  min: Maybe<Job_Code_Min_Fields>;
  stddev: Maybe<Job_Code_Stddev_Fields>;
  stddev_pop: Maybe<Job_Code_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Job_Code_Stddev_Samp_Fields>;
  sum: Maybe<Job_Code_Sum_Fields>;
  var_pop: Maybe<Job_Code_Var_Pop_Fields>;
  var_samp: Maybe<Job_Code_Var_Samp_Fields>;
  variance: Maybe<Job_Code_Variance_Fields>;
};

/** aggregate fields of "job_code" */
export type Job_Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Job_Code_Avg_Fields = {
  __typename?: 'job_code_avg_fields';
  level: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "job_code". All fields are combined with a logical 'AND'. */
export type Job_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Code_Bool_Exp>>;
  _not?: InputMaybe<Job_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Code_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  level?: InputMaybe<Smallint_Comparison_Exp>;
  role?: InputMaybe<Role_Choices_Enum_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_code" */
export enum Job_Code_Constraint {
  /** unique or primary key constraint on columns "code" */
  JobCodePkey = 'job_code_pkey',
}

/** input type for incrementing numeric columns in table "job_code" */
export type Job_Code_Inc_Input = {
  level?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "job_code" */
export type Job_Code_Insert_Input = {
  level?: InputMaybe<Scalars['smallint']>;
  role?: InputMaybe<Role_Choices_Enum>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Code_Max_Fields = {
  __typename?: 'job_code_max_fields';
  code: Maybe<Scalars['String']>;
  level: Maybe<Scalars['smallint']>;
  title: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Job_Code_Min_Fields = {
  __typename?: 'job_code_min_fields';
  code: Maybe<Scalars['String']>;
  level: Maybe<Scalars['smallint']>;
  title: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "job_code" */
export type Job_Code_Mutation_Response = {
  __typename?: 'job_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Code>;
};

/** on_conflict condition type for table "job_code" */
export type Job_Code_On_Conflict = {
  constraint: Job_Code_Constraint;
  update_columns?: Array<Job_Code_Update_Column>;
  where?: InputMaybe<Job_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "job_code". */
export type Job_Code_Order_By = {
  code?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_code */
export type Job_Code_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "job_code" */
export enum Job_Code_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Level = 'level',
  /** column name */
  Role = 'role',
  /** column name */
  Title = 'title',
}

/** input type for updating data in table "job_code" */
export type Job_Code_Set_Input = {
  level?: InputMaybe<Scalars['smallint']>;
  role?: InputMaybe<Role_Choices_Enum>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Job_Code_Stddev_Fields = {
  __typename?: 'job_code_stddev_fields';
  level: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Job_Code_Stddev_Pop_Fields = {
  __typename?: 'job_code_stddev_pop_fields';
  level: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Job_Code_Stddev_Samp_Fields = {
  __typename?: 'job_code_stddev_samp_fields';
  level: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "job_code" */
export type Job_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Code_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['smallint']>;
  role?: InputMaybe<Role_Choices_Enum>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Job_Code_Sum_Fields = {
  __typename?: 'job_code_sum_fields';
  level: Maybe<Scalars['smallint']>;
};

/** update columns of table "job_code" */
export enum Job_Code_Update_Column {
  /** column name */
  Level = 'level',
  /** column name */
  Role = 'role',
  /** column name */
  Title = 'title',
}

export type Job_Code_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Code_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Code_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Code_Var_Pop_Fields = {
  __typename?: 'job_code_var_pop_fields';
  level: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Job_Code_Var_Samp_Fields = {
  __typename?: 'job_code_var_samp_fields';
  level: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Job_Code_Variance_Fields = {
  __typename?: 'job_code_variance_fields';
  level: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "job" */
export enum Job_Constraint {
  /** unique or primary key constraint on columns "icims_job_profile_id" */
  JobIcimsJobProfileIdKey = 'job_icims_job_profile_id_key',
  /** unique or primary key constraint on columns "id" */
  JobPkey = 'job_pkey',
}

/** input type for incrementing numeric columns in table "job" */
export type Job_Inc_Input = {
  hiring_manager_id?: InputMaybe<Scalars['Int']>;
  hiring_manager_user_invite_id?: InputMaybe<Scalars['Int']>;
  icims_job_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_headcount?: InputMaybe<Scalars['Int']>;
  max_contract_rate?: InputMaybe<Scalars['numeric']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  min_contract_rate?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job" */
export type Job_Insert_Input = {
  about?: InputMaybe<Scalars['String']>;
  candidate_job_workflows?: InputMaybe<Candidate_Job_Workflow_Arr_Rel_Insert_Input>;
  contract_length?: InputMaybe<Contract_Length_Choices_Enum>;
  contract_to_hire?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  education_requirement?: InputMaybe<Candidate_Education_Degree_Choices_Enum>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  hiring_manager?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  hiring_manager_id?: InputMaybe<Scalars['Int']>;
  hiring_manager_user_invitation?: InputMaybe<User_Invitation_Obj_Rel_Insert_Input>;
  hiring_manager_user_invite_id?: InputMaybe<Scalars['Int']>;
  icims_job_profile_id?: InputMaybe<Scalars['Int']>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  initial_headcount?: InputMaybe<Scalars['Int']>;
  interview_details?: InputMaybe<Interview_Detail_Arr_Rel_Insert_Input>;
  job_acceptable_locations?: InputMaybe<Job_Acceptable_Location_Arr_Rel_Insert_Input>;
  job_meta_info?: InputMaybe<Job_Meta_Info_Obj_Rel_Insert_Input>;
  job_meta_infos?: InputMaybe<Job_Meta_Info_Arr_Rel_Insert_Input>;
  job_nice_to_have_skills?: InputMaybe<Job_Nice_To_Have_Skill_Arr_Rel_Insert_Input>;
  job_offering_selection?: InputMaybe<Offering_Selection_Choices_Enum>;
  job_positions?: InputMaybe<Job_Position_Arr_Rel_Insert_Input>;
  job_required_skills?: InputMaybe<Job_Required_Skill_Arr_Rel_Insert_Input>;
  job_status_logs?: InputMaybe<Job_Status_Log_Arr_Rel_Insert_Input>;
  job_tech_stack?: InputMaybe<Job_Tech_Stack_Arr_Rel_Insert_Input>;
  level?: InputMaybe<Job_Level_Choices_Enum>;
  level_choice?: InputMaybe<Job_Level_Choices_Obj_Rel_Insert_Input>;
  max_contract_rate?: InputMaybe<Scalars['numeric']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  merge_ats_job_id?: InputMaybe<Scalars['String']>;
  min_contract_rate?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
  min_years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  notes?: InputMaybe<Scalars['String']>;
  offering_bonus?: InputMaybe<Scalars['Boolean']>;
  offering_equity?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['Int']>;
  posted?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Job_Priority_Choices_Enum>;
  role_type?: InputMaybe<Role_Choices_Enum>;
  service_type?: InputMaybe<Job_Service_Type_Enum>;
  status?: InputMaybe<Job_Status_Choices_Enum>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  title?: InputMaybe<Scalars['String']>;
  tp_kickoff_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  what_youll_bring?: InputMaybe<Scalars['String']>;
  what_youll_do?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "job_level_choices" */
export type Job_Level_Choices = {
  __typename?: 'job_level_choices';
  /** An array relationship */
  ai_levels: Array<Ai_Level>;
  /** An aggregate relationship */
  ai_levels_aggregate: Ai_Level_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "job_level_choices" */
export type Job_Level_ChoicesAi_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Ai_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Level_Order_By>>;
  where?: InputMaybe<Ai_Level_Bool_Exp>;
};

/** columns and relationships of "job_level_choices" */
export type Job_Level_ChoicesAi_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Level_Order_By>>;
  where?: InputMaybe<Ai_Level_Bool_Exp>;
};

/** aggregated selection of "job_level_choices" */
export type Job_Level_Choices_Aggregate = {
  __typename?: 'job_level_choices_aggregate';
  aggregate: Maybe<Job_Level_Choices_Aggregate_Fields>;
  nodes: Array<Job_Level_Choices>;
};

/** aggregate fields of "job_level_choices" */
export type Job_Level_Choices_Aggregate_Fields = {
  __typename?: 'job_level_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Job_Level_Choices_Max_Fields>;
  min: Maybe<Job_Level_Choices_Min_Fields>;
};

/** aggregate fields of "job_level_choices" */
export type Job_Level_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Level_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "job_level_choices". All fields are combined with a logical 'AND'. */
export type Job_Level_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Level_Choices_Bool_Exp>>;
  _not?: InputMaybe<Job_Level_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Level_Choices_Bool_Exp>>;
  ai_levels?: InputMaybe<Ai_Level_Bool_Exp>;
  ai_levels_aggregate?: InputMaybe<Ai_Level_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_level_choices" */
export enum Job_Level_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  JobLevelChoicesPkey = 'job_level_choices_pkey',
}

export enum Job_Level_Choices_Enum {
  Ic1 = 'IC1',
  Ic2 = 'IC2',
  Ic3 = 'IC3',
  Ic4 = 'IC4',
  Ic5 = 'IC5',
  Ic6 = 'IC6',
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M4 = 'M4',
  M5 = 'M5',
  M6 = 'M6',
}

/** Boolean expression to compare columns of type "job_level_choices_enum". All fields are combined with logical 'AND'. */
export type Job_Level_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Job_Level_Choices_Enum>;
  _in?: InputMaybe<Array<Job_Level_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Job_Level_Choices_Enum>;
  _nin?: InputMaybe<Array<Job_Level_Choices_Enum>>;
};

/** input type for inserting data into table "job_level_choices" */
export type Job_Level_Choices_Insert_Input = {
  ai_levels?: InputMaybe<Ai_Level_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Level_Choices_Max_Fields = {
  __typename?: 'job_level_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Job_Level_Choices_Min_Fields = {
  __typename?: 'job_level_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "job_level_choices" */
export type Job_Level_Choices_Mutation_Response = {
  __typename?: 'job_level_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Level_Choices>;
};

/** input type for inserting object relation for remote table "job_level_choices" */
export type Job_Level_Choices_Obj_Rel_Insert_Input = {
  data: Job_Level_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Level_Choices_On_Conflict>;
};

/** on_conflict condition type for table "job_level_choices" */
export type Job_Level_Choices_On_Conflict = {
  constraint: Job_Level_Choices_Constraint;
  update_columns?: Array<Job_Level_Choices_Update_Column>;
  where?: InputMaybe<Job_Level_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "job_level_choices". */
export type Job_Level_Choices_Order_By = {
  ai_levels_aggregate?: InputMaybe<Ai_Level_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_level_choices */
export type Job_Level_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "job_level_choices" */
export enum Job_Level_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "job_level_choices" */
export type Job_Level_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "job_level_choices" */
export type Job_Level_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Level_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Level_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "job_level_choices" */
export enum Job_Level_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Job_Level_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Level_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Level_Choices_Bool_Exp;
};

/** aggregate max on columns */
export type Job_Max_Fields = {
  __typename?: 'job_max_fields';
  about: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  hiring_manager_id: Maybe<Scalars['Int']>;
  hiring_manager_user_invite_id: Maybe<Scalars['Int']>;
  icims_job_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  initial_headcount: Maybe<Scalars['Int']>;
  max_contract_rate: Maybe<Scalars['numeric']>;
  max_salary: Maybe<Scalars['numeric']>;
  merge_ats_job_id: Maybe<Scalars['String']>;
  min_contract_rate: Maybe<Scalars['numeric']>;
  min_salary: Maybe<Scalars['numeric']>;
  notes: Maybe<Scalars['String']>;
  organization_id: Maybe<Scalars['Int']>;
  title: Maybe<Scalars['String']>;
  tp_kickoff_date: Maybe<Scalars['date']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  what_youll_bring: Maybe<Scalars['String']>;
  what_youll_do: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "job" */
export type Job_Max_Order_By = {
  about?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  hiring_manager_id?: InputMaybe<Order_By>;
  hiring_manager_user_invite_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  merge_ats_job_id?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tp_kickoff_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  what_youll_bring?: InputMaybe<Order_By>;
  what_youll_do?: InputMaybe<Order_By>;
};

/** 1-1 relationship with job to store additional data mostly used by internal teams. */
export type Job_Meta_Info = {
  __typename?: 'job_meta_info';
  additional_info: Maybe<Scalars['String']>;
  canada_max_contractor_rate: Maybe<Scalars['numeric']>;
  canada_max_salary: Maybe<Scalars['numeric']>;
  canada_min_contractor_rate: Maybe<Scalars['numeric']>;
  canada_min_salary: Maybe<Scalars['numeric']>;
  europe_max_contractor_rate: Maybe<Scalars['numeric']>;
  europe_max_salary: Maybe<Scalars['numeric']>;
  europe_min_contractor_rate: Maybe<Scalars['numeric']>;
  europe_min_salary: Maybe<Scalars['numeric']>;
  id: Scalars['Int'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['Int'];
  latam_max_contractor_rate: Maybe<Scalars['numeric']>;
  latam_max_salary: Maybe<Scalars['numeric']>;
  latam_min_contractor_rate: Maybe<Scalars['numeric']>;
  latam_min_salary: Maybe<Scalars['numeric']>;
  /** An object relationship */
  people_ops: Maybe<Terminal_Employee>;
  people_ops_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  recruiter: Maybe<Terminal_Employee>;
  recruiter_id: Maybe<Scalars['Int']>;
  role_source: Maybe<Job_Role_Source_Choices_Enum>;
  /** An object relationship */
  sourcer: Maybe<Terminal_Employee>;
  sourcer_id: Maybe<Scalars['Int']>;
};

/** aggregated selection of "job_meta_info" */
export type Job_Meta_Info_Aggregate = {
  __typename?: 'job_meta_info_aggregate';
  aggregate: Maybe<Job_Meta_Info_Aggregate_Fields>;
  nodes: Array<Job_Meta_Info>;
};

export type Job_Meta_Info_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Meta_Info_Aggregate_Bool_Exp_Count>;
};

export type Job_Meta_Info_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Meta_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Meta_Info_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_meta_info" */
export type Job_Meta_Info_Aggregate_Fields = {
  __typename?: 'job_meta_info_aggregate_fields';
  avg: Maybe<Job_Meta_Info_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Job_Meta_Info_Max_Fields>;
  min: Maybe<Job_Meta_Info_Min_Fields>;
  stddev: Maybe<Job_Meta_Info_Stddev_Fields>;
  stddev_pop: Maybe<Job_Meta_Info_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Job_Meta_Info_Stddev_Samp_Fields>;
  sum: Maybe<Job_Meta_Info_Sum_Fields>;
  var_pop: Maybe<Job_Meta_Info_Var_Pop_Fields>;
  var_samp: Maybe<Job_Meta_Info_Var_Samp_Fields>;
  variance: Maybe<Job_Meta_Info_Variance_Fields>;
};

/** aggregate fields of "job_meta_info" */
export type Job_Meta_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Meta_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_meta_info" */
export type Job_Meta_Info_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Meta_Info_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Meta_Info_Max_Order_By>;
  min?: InputMaybe<Job_Meta_Info_Min_Order_By>;
  stddev?: InputMaybe<Job_Meta_Info_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Meta_Info_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Meta_Info_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Meta_Info_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Meta_Info_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Meta_Info_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Meta_Info_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_meta_info" */
export type Job_Meta_Info_Arr_Rel_Insert_Input = {
  data: Array<Job_Meta_Info_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Meta_Info_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Meta_Info_Avg_Fields = {
  __typename?: 'job_meta_info_avg_fields';
  canada_max_contractor_rate: Maybe<Scalars['Float']>;
  canada_max_salary: Maybe<Scalars['Float']>;
  canada_min_contractor_rate: Maybe<Scalars['Float']>;
  canada_min_salary: Maybe<Scalars['Float']>;
  europe_max_contractor_rate: Maybe<Scalars['Float']>;
  europe_max_salary: Maybe<Scalars['Float']>;
  europe_min_contractor_rate: Maybe<Scalars['Float']>;
  europe_min_salary: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  latam_max_contractor_rate: Maybe<Scalars['Float']>;
  latam_max_salary: Maybe<Scalars['Float']>;
  latam_min_contractor_rate: Maybe<Scalars['Float']>;
  latam_min_salary: Maybe<Scalars['Float']>;
  people_ops_id: Maybe<Scalars['Float']>;
  recruiter_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_meta_info" */
export type Job_Meta_Info_Avg_Order_By = {
  canada_max_contractor_rate?: InputMaybe<Order_By>;
  canada_max_salary?: InputMaybe<Order_By>;
  canada_min_contractor_rate?: InputMaybe<Order_By>;
  canada_min_salary?: InputMaybe<Order_By>;
  europe_max_contractor_rate?: InputMaybe<Order_By>;
  europe_max_salary?: InputMaybe<Order_By>;
  europe_min_contractor_rate?: InputMaybe<Order_By>;
  europe_min_salary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latam_max_contractor_rate?: InputMaybe<Order_By>;
  latam_max_salary?: InputMaybe<Order_By>;
  latam_min_contractor_rate?: InputMaybe<Order_By>;
  latam_min_salary?: InputMaybe<Order_By>;
  people_ops_id?: InputMaybe<Order_By>;
  recruiter_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_meta_info". All fields are combined with a logical 'AND'. */
export type Job_Meta_Info_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Meta_Info_Bool_Exp>>;
  _not?: InputMaybe<Job_Meta_Info_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Meta_Info_Bool_Exp>>;
  additional_info?: InputMaybe<String_Comparison_Exp>;
  canada_max_contractor_rate?: InputMaybe<Numeric_Comparison_Exp>;
  canada_max_salary?: InputMaybe<Numeric_Comparison_Exp>;
  canada_min_contractor_rate?: InputMaybe<Numeric_Comparison_Exp>;
  canada_min_salary?: InputMaybe<Numeric_Comparison_Exp>;
  europe_max_contractor_rate?: InputMaybe<Numeric_Comparison_Exp>;
  europe_max_salary?: InputMaybe<Numeric_Comparison_Exp>;
  europe_min_contractor_rate?: InputMaybe<Numeric_Comparison_Exp>;
  europe_min_salary?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  latam_max_contractor_rate?: InputMaybe<Numeric_Comparison_Exp>;
  latam_max_salary?: InputMaybe<Numeric_Comparison_Exp>;
  latam_min_contractor_rate?: InputMaybe<Numeric_Comparison_Exp>;
  latam_min_salary?: InputMaybe<Numeric_Comparison_Exp>;
  people_ops?: InputMaybe<Terminal_Employee_Bool_Exp>;
  people_ops_id?: InputMaybe<Int_Comparison_Exp>;
  recruiter?: InputMaybe<Terminal_Employee_Bool_Exp>;
  recruiter_id?: InputMaybe<Int_Comparison_Exp>;
  role_source?: InputMaybe<Job_Role_Source_Choices_Enum_Comparison_Exp>;
  sourcer?: InputMaybe<Terminal_Employee_Bool_Exp>;
  sourcer_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_meta_info" */
export enum Job_Meta_Info_Constraint {
  /** unique or primary key constraint on columns "job_id" */
  JobMetaInfoJobIdKey = 'job_meta_info_job_id_key',
  /** unique or primary key constraint on columns "id" */
  JobMetaInfoPkey = 'job_meta_info_pkey',
}

/** input type for incrementing numeric columns in table "job_meta_info" */
export type Job_Meta_Info_Inc_Input = {
  canada_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  canada_max_salary?: InputMaybe<Scalars['numeric']>;
  canada_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  canada_min_salary?: InputMaybe<Scalars['numeric']>;
  europe_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  europe_max_salary?: InputMaybe<Scalars['numeric']>;
  europe_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  europe_min_salary?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  latam_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  latam_max_salary?: InputMaybe<Scalars['numeric']>;
  latam_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  latam_min_salary?: InputMaybe<Scalars['numeric']>;
  people_ops_id?: InputMaybe<Scalars['Int']>;
  recruiter_id?: InputMaybe<Scalars['Int']>;
  sourcer_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_meta_info" */
export type Job_Meta_Info_Insert_Input = {
  additional_info?: InputMaybe<Scalars['String']>;
  canada_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  canada_max_salary?: InputMaybe<Scalars['numeric']>;
  canada_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  canada_min_salary?: InputMaybe<Scalars['numeric']>;
  europe_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  europe_max_salary?: InputMaybe<Scalars['numeric']>;
  europe_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  europe_min_salary?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['Int']>;
  latam_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  latam_max_salary?: InputMaybe<Scalars['numeric']>;
  latam_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  latam_min_salary?: InputMaybe<Scalars['numeric']>;
  people_ops?: InputMaybe<Terminal_Employee_Obj_Rel_Insert_Input>;
  people_ops_id?: InputMaybe<Scalars['Int']>;
  recruiter?: InputMaybe<Terminal_Employee_Obj_Rel_Insert_Input>;
  recruiter_id?: InputMaybe<Scalars['Int']>;
  role_source?: InputMaybe<Job_Role_Source_Choices_Enum>;
  sourcer?: InputMaybe<Terminal_Employee_Obj_Rel_Insert_Input>;
  sourcer_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Job_Meta_Info_Max_Fields = {
  __typename?: 'job_meta_info_max_fields';
  additional_info: Maybe<Scalars['String']>;
  canada_max_contractor_rate: Maybe<Scalars['numeric']>;
  canada_max_salary: Maybe<Scalars['numeric']>;
  canada_min_contractor_rate: Maybe<Scalars['numeric']>;
  canada_min_salary: Maybe<Scalars['numeric']>;
  europe_max_contractor_rate: Maybe<Scalars['numeric']>;
  europe_max_salary: Maybe<Scalars['numeric']>;
  europe_min_contractor_rate: Maybe<Scalars['numeric']>;
  europe_min_salary: Maybe<Scalars['numeric']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  latam_max_contractor_rate: Maybe<Scalars['numeric']>;
  latam_max_salary: Maybe<Scalars['numeric']>;
  latam_min_contractor_rate: Maybe<Scalars['numeric']>;
  latam_min_salary: Maybe<Scalars['numeric']>;
  people_ops_id: Maybe<Scalars['Int']>;
  recruiter_id: Maybe<Scalars['Int']>;
  sourcer_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "job_meta_info" */
export type Job_Meta_Info_Max_Order_By = {
  additional_info?: InputMaybe<Order_By>;
  canada_max_contractor_rate?: InputMaybe<Order_By>;
  canada_max_salary?: InputMaybe<Order_By>;
  canada_min_contractor_rate?: InputMaybe<Order_By>;
  canada_min_salary?: InputMaybe<Order_By>;
  europe_max_contractor_rate?: InputMaybe<Order_By>;
  europe_max_salary?: InputMaybe<Order_By>;
  europe_min_contractor_rate?: InputMaybe<Order_By>;
  europe_min_salary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latam_max_contractor_rate?: InputMaybe<Order_By>;
  latam_max_salary?: InputMaybe<Order_By>;
  latam_min_contractor_rate?: InputMaybe<Order_By>;
  latam_min_salary?: InputMaybe<Order_By>;
  people_ops_id?: InputMaybe<Order_By>;
  recruiter_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Meta_Info_Min_Fields = {
  __typename?: 'job_meta_info_min_fields';
  additional_info: Maybe<Scalars['String']>;
  canada_max_contractor_rate: Maybe<Scalars['numeric']>;
  canada_max_salary: Maybe<Scalars['numeric']>;
  canada_min_contractor_rate: Maybe<Scalars['numeric']>;
  canada_min_salary: Maybe<Scalars['numeric']>;
  europe_max_contractor_rate: Maybe<Scalars['numeric']>;
  europe_max_salary: Maybe<Scalars['numeric']>;
  europe_min_contractor_rate: Maybe<Scalars['numeric']>;
  europe_min_salary: Maybe<Scalars['numeric']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  latam_max_contractor_rate: Maybe<Scalars['numeric']>;
  latam_max_salary: Maybe<Scalars['numeric']>;
  latam_min_contractor_rate: Maybe<Scalars['numeric']>;
  latam_min_salary: Maybe<Scalars['numeric']>;
  people_ops_id: Maybe<Scalars['Int']>;
  recruiter_id: Maybe<Scalars['Int']>;
  sourcer_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "job_meta_info" */
export type Job_Meta_Info_Min_Order_By = {
  additional_info?: InputMaybe<Order_By>;
  canada_max_contractor_rate?: InputMaybe<Order_By>;
  canada_max_salary?: InputMaybe<Order_By>;
  canada_min_contractor_rate?: InputMaybe<Order_By>;
  canada_min_salary?: InputMaybe<Order_By>;
  europe_max_contractor_rate?: InputMaybe<Order_By>;
  europe_max_salary?: InputMaybe<Order_By>;
  europe_min_contractor_rate?: InputMaybe<Order_By>;
  europe_min_salary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latam_max_contractor_rate?: InputMaybe<Order_By>;
  latam_max_salary?: InputMaybe<Order_By>;
  latam_min_contractor_rate?: InputMaybe<Order_By>;
  latam_min_salary?: InputMaybe<Order_By>;
  people_ops_id?: InputMaybe<Order_By>;
  recruiter_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_meta_info" */
export type Job_Meta_Info_Mutation_Response = {
  __typename?: 'job_meta_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Meta_Info>;
};

/** input type for inserting object relation for remote table "job_meta_info" */
export type Job_Meta_Info_Obj_Rel_Insert_Input = {
  data: Job_Meta_Info_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Meta_Info_On_Conflict>;
};

/** on_conflict condition type for table "job_meta_info" */
export type Job_Meta_Info_On_Conflict = {
  constraint: Job_Meta_Info_Constraint;
  update_columns?: Array<Job_Meta_Info_Update_Column>;
  where?: InputMaybe<Job_Meta_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "job_meta_info". */
export type Job_Meta_Info_Order_By = {
  additional_info?: InputMaybe<Order_By>;
  canada_max_contractor_rate?: InputMaybe<Order_By>;
  canada_max_salary?: InputMaybe<Order_By>;
  canada_min_contractor_rate?: InputMaybe<Order_By>;
  canada_min_salary?: InputMaybe<Order_By>;
  europe_max_contractor_rate?: InputMaybe<Order_By>;
  europe_max_salary?: InputMaybe<Order_By>;
  europe_min_contractor_rate?: InputMaybe<Order_By>;
  europe_min_salary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  latam_max_contractor_rate?: InputMaybe<Order_By>;
  latam_max_salary?: InputMaybe<Order_By>;
  latam_min_contractor_rate?: InputMaybe<Order_By>;
  latam_min_salary?: InputMaybe<Order_By>;
  people_ops?: InputMaybe<Terminal_Employee_Order_By>;
  people_ops_id?: InputMaybe<Order_By>;
  recruiter?: InputMaybe<Terminal_Employee_Order_By>;
  recruiter_id?: InputMaybe<Order_By>;
  role_source?: InputMaybe<Order_By>;
  sourcer?: InputMaybe<Terminal_Employee_Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_meta_info */
export type Job_Meta_Info_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "job_meta_info" */
export enum Job_Meta_Info_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CanadaMaxContractorRate = 'canada_max_contractor_rate',
  /** column name */
  CanadaMaxSalary = 'canada_max_salary',
  /** column name */
  CanadaMinContractorRate = 'canada_min_contractor_rate',
  /** column name */
  CanadaMinSalary = 'canada_min_salary',
  /** column name */
  EuropeMaxContractorRate = 'europe_max_contractor_rate',
  /** column name */
  EuropeMaxSalary = 'europe_max_salary',
  /** column name */
  EuropeMinContractorRate = 'europe_min_contractor_rate',
  /** column name */
  EuropeMinSalary = 'europe_min_salary',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LatamMaxContractorRate = 'latam_max_contractor_rate',
  /** column name */
  LatamMaxSalary = 'latam_max_salary',
  /** column name */
  LatamMinContractorRate = 'latam_min_contractor_rate',
  /** column name */
  LatamMinSalary = 'latam_min_salary',
  /** column name */
  PeopleOpsId = 'people_ops_id',
  /** column name */
  RecruiterId = 'recruiter_id',
  /** column name */
  RoleSource = 'role_source',
  /** column name */
  SourcerId = 'sourcer_id',
}

/** input type for updating data in table "job_meta_info" */
export type Job_Meta_Info_Set_Input = {
  additional_info?: InputMaybe<Scalars['String']>;
  canada_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  canada_max_salary?: InputMaybe<Scalars['numeric']>;
  canada_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  canada_min_salary?: InputMaybe<Scalars['numeric']>;
  europe_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  europe_max_salary?: InputMaybe<Scalars['numeric']>;
  europe_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  europe_min_salary?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  latam_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  latam_max_salary?: InputMaybe<Scalars['numeric']>;
  latam_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  latam_min_salary?: InputMaybe<Scalars['numeric']>;
  people_ops_id?: InputMaybe<Scalars['Int']>;
  recruiter_id?: InputMaybe<Scalars['Int']>;
  role_source?: InputMaybe<Job_Role_Source_Choices_Enum>;
  sourcer_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Job_Meta_Info_Stddev_Fields = {
  __typename?: 'job_meta_info_stddev_fields';
  canada_max_contractor_rate: Maybe<Scalars['Float']>;
  canada_max_salary: Maybe<Scalars['Float']>;
  canada_min_contractor_rate: Maybe<Scalars['Float']>;
  canada_min_salary: Maybe<Scalars['Float']>;
  europe_max_contractor_rate: Maybe<Scalars['Float']>;
  europe_max_salary: Maybe<Scalars['Float']>;
  europe_min_contractor_rate: Maybe<Scalars['Float']>;
  europe_min_salary: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  latam_max_contractor_rate: Maybe<Scalars['Float']>;
  latam_max_salary: Maybe<Scalars['Float']>;
  latam_min_contractor_rate: Maybe<Scalars['Float']>;
  latam_min_salary: Maybe<Scalars['Float']>;
  people_ops_id: Maybe<Scalars['Float']>;
  recruiter_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_meta_info" */
export type Job_Meta_Info_Stddev_Order_By = {
  canada_max_contractor_rate?: InputMaybe<Order_By>;
  canada_max_salary?: InputMaybe<Order_By>;
  canada_min_contractor_rate?: InputMaybe<Order_By>;
  canada_min_salary?: InputMaybe<Order_By>;
  europe_max_contractor_rate?: InputMaybe<Order_By>;
  europe_max_salary?: InputMaybe<Order_By>;
  europe_min_contractor_rate?: InputMaybe<Order_By>;
  europe_min_salary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latam_max_contractor_rate?: InputMaybe<Order_By>;
  latam_max_salary?: InputMaybe<Order_By>;
  latam_min_contractor_rate?: InputMaybe<Order_By>;
  latam_min_salary?: InputMaybe<Order_By>;
  people_ops_id?: InputMaybe<Order_By>;
  recruiter_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Meta_Info_Stddev_Pop_Fields = {
  __typename?: 'job_meta_info_stddev_pop_fields';
  canada_max_contractor_rate: Maybe<Scalars['Float']>;
  canada_max_salary: Maybe<Scalars['Float']>;
  canada_min_contractor_rate: Maybe<Scalars['Float']>;
  canada_min_salary: Maybe<Scalars['Float']>;
  europe_max_contractor_rate: Maybe<Scalars['Float']>;
  europe_max_salary: Maybe<Scalars['Float']>;
  europe_min_contractor_rate: Maybe<Scalars['Float']>;
  europe_min_salary: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  latam_max_contractor_rate: Maybe<Scalars['Float']>;
  latam_max_salary: Maybe<Scalars['Float']>;
  latam_min_contractor_rate: Maybe<Scalars['Float']>;
  latam_min_salary: Maybe<Scalars['Float']>;
  people_ops_id: Maybe<Scalars['Float']>;
  recruiter_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_meta_info" */
export type Job_Meta_Info_Stddev_Pop_Order_By = {
  canada_max_contractor_rate?: InputMaybe<Order_By>;
  canada_max_salary?: InputMaybe<Order_By>;
  canada_min_contractor_rate?: InputMaybe<Order_By>;
  canada_min_salary?: InputMaybe<Order_By>;
  europe_max_contractor_rate?: InputMaybe<Order_By>;
  europe_max_salary?: InputMaybe<Order_By>;
  europe_min_contractor_rate?: InputMaybe<Order_By>;
  europe_min_salary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latam_max_contractor_rate?: InputMaybe<Order_By>;
  latam_max_salary?: InputMaybe<Order_By>;
  latam_min_contractor_rate?: InputMaybe<Order_By>;
  latam_min_salary?: InputMaybe<Order_By>;
  people_ops_id?: InputMaybe<Order_By>;
  recruiter_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Meta_Info_Stddev_Samp_Fields = {
  __typename?: 'job_meta_info_stddev_samp_fields';
  canada_max_contractor_rate: Maybe<Scalars['Float']>;
  canada_max_salary: Maybe<Scalars['Float']>;
  canada_min_contractor_rate: Maybe<Scalars['Float']>;
  canada_min_salary: Maybe<Scalars['Float']>;
  europe_max_contractor_rate: Maybe<Scalars['Float']>;
  europe_max_salary: Maybe<Scalars['Float']>;
  europe_min_contractor_rate: Maybe<Scalars['Float']>;
  europe_min_salary: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  latam_max_contractor_rate: Maybe<Scalars['Float']>;
  latam_max_salary: Maybe<Scalars['Float']>;
  latam_min_contractor_rate: Maybe<Scalars['Float']>;
  latam_min_salary: Maybe<Scalars['Float']>;
  people_ops_id: Maybe<Scalars['Float']>;
  recruiter_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_meta_info" */
export type Job_Meta_Info_Stddev_Samp_Order_By = {
  canada_max_contractor_rate?: InputMaybe<Order_By>;
  canada_max_salary?: InputMaybe<Order_By>;
  canada_min_contractor_rate?: InputMaybe<Order_By>;
  canada_min_salary?: InputMaybe<Order_By>;
  europe_max_contractor_rate?: InputMaybe<Order_By>;
  europe_max_salary?: InputMaybe<Order_By>;
  europe_min_contractor_rate?: InputMaybe<Order_By>;
  europe_min_salary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latam_max_contractor_rate?: InputMaybe<Order_By>;
  latam_max_salary?: InputMaybe<Order_By>;
  latam_min_contractor_rate?: InputMaybe<Order_By>;
  latam_min_salary?: InputMaybe<Order_By>;
  people_ops_id?: InputMaybe<Order_By>;
  recruiter_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_meta_info" */
export type Job_Meta_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Meta_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Meta_Info_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['String']>;
  canada_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  canada_max_salary?: InputMaybe<Scalars['numeric']>;
  canada_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  canada_min_salary?: InputMaybe<Scalars['numeric']>;
  europe_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  europe_max_salary?: InputMaybe<Scalars['numeric']>;
  europe_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  europe_min_salary?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  latam_max_contractor_rate?: InputMaybe<Scalars['numeric']>;
  latam_max_salary?: InputMaybe<Scalars['numeric']>;
  latam_min_contractor_rate?: InputMaybe<Scalars['numeric']>;
  latam_min_salary?: InputMaybe<Scalars['numeric']>;
  people_ops_id?: InputMaybe<Scalars['Int']>;
  recruiter_id?: InputMaybe<Scalars['Int']>;
  role_source?: InputMaybe<Job_Role_Source_Choices_Enum>;
  sourcer_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Job_Meta_Info_Sum_Fields = {
  __typename?: 'job_meta_info_sum_fields';
  canada_max_contractor_rate: Maybe<Scalars['numeric']>;
  canada_max_salary: Maybe<Scalars['numeric']>;
  canada_min_contractor_rate: Maybe<Scalars['numeric']>;
  canada_min_salary: Maybe<Scalars['numeric']>;
  europe_max_contractor_rate: Maybe<Scalars['numeric']>;
  europe_max_salary: Maybe<Scalars['numeric']>;
  europe_min_contractor_rate: Maybe<Scalars['numeric']>;
  europe_min_salary: Maybe<Scalars['numeric']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  latam_max_contractor_rate: Maybe<Scalars['numeric']>;
  latam_max_salary: Maybe<Scalars['numeric']>;
  latam_min_contractor_rate: Maybe<Scalars['numeric']>;
  latam_min_salary: Maybe<Scalars['numeric']>;
  people_ops_id: Maybe<Scalars['Int']>;
  recruiter_id: Maybe<Scalars['Int']>;
  sourcer_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_meta_info" */
export type Job_Meta_Info_Sum_Order_By = {
  canada_max_contractor_rate?: InputMaybe<Order_By>;
  canada_max_salary?: InputMaybe<Order_By>;
  canada_min_contractor_rate?: InputMaybe<Order_By>;
  canada_min_salary?: InputMaybe<Order_By>;
  europe_max_contractor_rate?: InputMaybe<Order_By>;
  europe_max_salary?: InputMaybe<Order_By>;
  europe_min_contractor_rate?: InputMaybe<Order_By>;
  europe_min_salary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latam_max_contractor_rate?: InputMaybe<Order_By>;
  latam_max_salary?: InputMaybe<Order_By>;
  latam_min_contractor_rate?: InputMaybe<Order_By>;
  latam_min_salary?: InputMaybe<Order_By>;
  people_ops_id?: InputMaybe<Order_By>;
  recruiter_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
};

/** update columns of table "job_meta_info" */
export enum Job_Meta_Info_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CanadaMaxContractorRate = 'canada_max_contractor_rate',
  /** column name */
  CanadaMaxSalary = 'canada_max_salary',
  /** column name */
  CanadaMinContractorRate = 'canada_min_contractor_rate',
  /** column name */
  CanadaMinSalary = 'canada_min_salary',
  /** column name */
  EuropeMaxContractorRate = 'europe_max_contractor_rate',
  /** column name */
  EuropeMaxSalary = 'europe_max_salary',
  /** column name */
  EuropeMinContractorRate = 'europe_min_contractor_rate',
  /** column name */
  EuropeMinSalary = 'europe_min_salary',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LatamMaxContractorRate = 'latam_max_contractor_rate',
  /** column name */
  LatamMaxSalary = 'latam_max_salary',
  /** column name */
  LatamMinContractorRate = 'latam_min_contractor_rate',
  /** column name */
  LatamMinSalary = 'latam_min_salary',
  /** column name */
  PeopleOpsId = 'people_ops_id',
  /** column name */
  RecruiterId = 'recruiter_id',
  /** column name */
  RoleSource = 'role_source',
  /** column name */
  SourcerId = 'sourcer_id',
}

export type Job_Meta_Info_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Meta_Info_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Meta_Info_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Meta_Info_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Meta_Info_Var_Pop_Fields = {
  __typename?: 'job_meta_info_var_pop_fields';
  canada_max_contractor_rate: Maybe<Scalars['Float']>;
  canada_max_salary: Maybe<Scalars['Float']>;
  canada_min_contractor_rate: Maybe<Scalars['Float']>;
  canada_min_salary: Maybe<Scalars['Float']>;
  europe_max_contractor_rate: Maybe<Scalars['Float']>;
  europe_max_salary: Maybe<Scalars['Float']>;
  europe_min_contractor_rate: Maybe<Scalars['Float']>;
  europe_min_salary: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  latam_max_contractor_rate: Maybe<Scalars['Float']>;
  latam_max_salary: Maybe<Scalars['Float']>;
  latam_min_contractor_rate: Maybe<Scalars['Float']>;
  latam_min_salary: Maybe<Scalars['Float']>;
  people_ops_id: Maybe<Scalars['Float']>;
  recruiter_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_meta_info" */
export type Job_Meta_Info_Var_Pop_Order_By = {
  canada_max_contractor_rate?: InputMaybe<Order_By>;
  canada_max_salary?: InputMaybe<Order_By>;
  canada_min_contractor_rate?: InputMaybe<Order_By>;
  canada_min_salary?: InputMaybe<Order_By>;
  europe_max_contractor_rate?: InputMaybe<Order_By>;
  europe_max_salary?: InputMaybe<Order_By>;
  europe_min_contractor_rate?: InputMaybe<Order_By>;
  europe_min_salary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latam_max_contractor_rate?: InputMaybe<Order_By>;
  latam_max_salary?: InputMaybe<Order_By>;
  latam_min_contractor_rate?: InputMaybe<Order_By>;
  latam_min_salary?: InputMaybe<Order_By>;
  people_ops_id?: InputMaybe<Order_By>;
  recruiter_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Meta_Info_Var_Samp_Fields = {
  __typename?: 'job_meta_info_var_samp_fields';
  canada_max_contractor_rate: Maybe<Scalars['Float']>;
  canada_max_salary: Maybe<Scalars['Float']>;
  canada_min_contractor_rate: Maybe<Scalars['Float']>;
  canada_min_salary: Maybe<Scalars['Float']>;
  europe_max_contractor_rate: Maybe<Scalars['Float']>;
  europe_max_salary: Maybe<Scalars['Float']>;
  europe_min_contractor_rate: Maybe<Scalars['Float']>;
  europe_min_salary: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  latam_max_contractor_rate: Maybe<Scalars['Float']>;
  latam_max_salary: Maybe<Scalars['Float']>;
  latam_min_contractor_rate: Maybe<Scalars['Float']>;
  latam_min_salary: Maybe<Scalars['Float']>;
  people_ops_id: Maybe<Scalars['Float']>;
  recruiter_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_meta_info" */
export type Job_Meta_Info_Var_Samp_Order_By = {
  canada_max_contractor_rate?: InputMaybe<Order_By>;
  canada_max_salary?: InputMaybe<Order_By>;
  canada_min_contractor_rate?: InputMaybe<Order_By>;
  canada_min_salary?: InputMaybe<Order_By>;
  europe_max_contractor_rate?: InputMaybe<Order_By>;
  europe_max_salary?: InputMaybe<Order_By>;
  europe_min_contractor_rate?: InputMaybe<Order_By>;
  europe_min_salary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latam_max_contractor_rate?: InputMaybe<Order_By>;
  latam_max_salary?: InputMaybe<Order_By>;
  latam_min_contractor_rate?: InputMaybe<Order_By>;
  latam_min_salary?: InputMaybe<Order_By>;
  people_ops_id?: InputMaybe<Order_By>;
  recruiter_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Meta_Info_Variance_Fields = {
  __typename?: 'job_meta_info_variance_fields';
  canada_max_contractor_rate: Maybe<Scalars['Float']>;
  canada_max_salary: Maybe<Scalars['Float']>;
  canada_min_contractor_rate: Maybe<Scalars['Float']>;
  canada_min_salary: Maybe<Scalars['Float']>;
  europe_max_contractor_rate: Maybe<Scalars['Float']>;
  europe_max_salary: Maybe<Scalars['Float']>;
  europe_min_contractor_rate: Maybe<Scalars['Float']>;
  europe_min_salary: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  latam_max_contractor_rate: Maybe<Scalars['Float']>;
  latam_max_salary: Maybe<Scalars['Float']>;
  latam_min_contractor_rate: Maybe<Scalars['Float']>;
  latam_min_salary: Maybe<Scalars['Float']>;
  people_ops_id: Maybe<Scalars['Float']>;
  recruiter_id: Maybe<Scalars['Float']>;
  sourcer_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_meta_info" */
export type Job_Meta_Info_Variance_Order_By = {
  canada_max_contractor_rate?: InputMaybe<Order_By>;
  canada_max_salary?: InputMaybe<Order_By>;
  canada_min_contractor_rate?: InputMaybe<Order_By>;
  canada_min_salary?: InputMaybe<Order_By>;
  europe_max_contractor_rate?: InputMaybe<Order_By>;
  europe_max_salary?: InputMaybe<Order_By>;
  europe_min_contractor_rate?: InputMaybe<Order_By>;
  europe_min_salary?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  latam_max_contractor_rate?: InputMaybe<Order_By>;
  latam_max_salary?: InputMaybe<Order_By>;
  latam_min_contractor_rate?: InputMaybe<Order_By>;
  latam_min_salary?: InputMaybe<Order_By>;
  people_ops_id?: InputMaybe<Order_By>;
  recruiter_id?: InputMaybe<Order_By>;
  sourcer_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Min_Fields = {
  __typename?: 'job_min_fields';
  about: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  hiring_manager_id: Maybe<Scalars['Int']>;
  hiring_manager_user_invite_id: Maybe<Scalars['Int']>;
  icims_job_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  initial_headcount: Maybe<Scalars['Int']>;
  max_contract_rate: Maybe<Scalars['numeric']>;
  max_salary: Maybe<Scalars['numeric']>;
  merge_ats_job_id: Maybe<Scalars['String']>;
  min_contract_rate: Maybe<Scalars['numeric']>;
  min_salary: Maybe<Scalars['numeric']>;
  notes: Maybe<Scalars['String']>;
  organization_id: Maybe<Scalars['Int']>;
  title: Maybe<Scalars['String']>;
  tp_kickoff_date: Maybe<Scalars['date']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  what_youll_bring: Maybe<Scalars['String']>;
  what_youll_do: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "job" */
export type Job_Min_Order_By = {
  about?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  hiring_manager_id?: InputMaybe<Order_By>;
  hiring_manager_user_invite_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  merge_ats_job_id?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tp_kickoff_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  what_youll_bring?: InputMaybe<Order_By>;
  what_youll_do?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job" */
export type Job_Mutation_Response = {
  __typename?: 'job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job>;
};

/** columns and relationships of "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill = {
  __typename?: 'job_nice_to_have_skill';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['Int'];
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Aggregate = {
  __typename?: 'job_nice_to_have_skill_aggregate';
  aggregate: Maybe<Job_Nice_To_Have_Skill_Aggregate_Fields>;
  nodes: Array<Job_Nice_To_Have_Skill>;
};

export type Job_Nice_To_Have_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Nice_To_Have_Skill_Aggregate_Bool_Exp_Count>;
};

export type Job_Nice_To_Have_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Nice_To_Have_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Nice_To_Have_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Aggregate_Fields = {
  __typename?: 'job_nice_to_have_skill_aggregate_fields';
  avg: Maybe<Job_Nice_To_Have_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Job_Nice_To_Have_Skill_Max_Fields>;
  min: Maybe<Job_Nice_To_Have_Skill_Min_Fields>;
  stddev: Maybe<Job_Nice_To_Have_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Job_Nice_To_Have_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Job_Nice_To_Have_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Job_Nice_To_Have_Skill_Sum_Fields>;
  var_pop: Maybe<Job_Nice_To_Have_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Job_Nice_To_Have_Skill_Var_Samp_Fields>;
  variance: Maybe<Job_Nice_To_Have_Skill_Variance_Fields>;
};

/** aggregate fields of "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Nice_To_Have_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Nice_To_Have_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Nice_To_Have_Skill_Max_Order_By>;
  min?: InputMaybe<Job_Nice_To_Have_Skill_Min_Order_By>;
  stddev?: InputMaybe<Job_Nice_To_Have_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Nice_To_Have_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Nice_To_Have_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Nice_To_Have_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Nice_To_Have_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Nice_To_Have_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Nice_To_Have_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Arr_Rel_Insert_Input = {
  data: Array<Job_Nice_To_Have_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Nice_To_Have_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Nice_To_Have_Skill_Avg_Fields = {
  __typename?: 'job_nice_to_have_skill_avg_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_nice_to_have_skill". All fields are combined with a logical 'AND'. */
export type Job_Nice_To_Have_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Nice_To_Have_Skill_Bool_Exp>>;
  _not?: InputMaybe<Job_Nice_To_Have_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Nice_To_Have_Skill_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_nice_to_have_skill" */
export enum Job_Nice_To_Have_Skill_Constraint {
  /** unique or primary key constraint on columns "skill_id", "job_id" */
  JobNiceToHaveSkillJobIdSkillIdKey = 'job_nice_to_have_skill_job_id_skill_id_key',
  /** unique or primary key constraint on columns "id" */
  JobNiceToHaveSkillPkey = 'job_nice_to_have_skill_pkey',
}

/** input type for incrementing numeric columns in table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Job_Nice_To_Have_Skill_Max_Fields = {
  __typename?: 'job_nice_to_have_skill_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Nice_To_Have_Skill_Min_Fields = {
  __typename?: 'job_nice_to_have_skill_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Mutation_Response = {
  __typename?: 'job_nice_to_have_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Nice_To_Have_Skill>;
};

/** on_conflict condition type for table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_On_Conflict = {
  constraint: Job_Nice_To_Have_Skill_Constraint;
  update_columns?: Array<Job_Nice_To_Have_Skill_Update_Column>;
  where?: InputMaybe<Job_Nice_To_Have_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "job_nice_to_have_skill". */
export type Job_Nice_To_Have_Skill_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_nice_to_have_skill */
export type Job_Nice_To_Have_Skill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "job_nice_to_have_skill" */
export enum Job_Nice_To_Have_Skill_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Job_Nice_To_Have_Skill_Stddev_Fields = {
  __typename?: 'job_nice_to_have_skill_stddev_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Nice_To_Have_Skill_Stddev_Pop_Fields = {
  __typename?: 'job_nice_to_have_skill_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Nice_To_Have_Skill_Stddev_Samp_Fields = {
  __typename?: 'job_nice_to_have_skill_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Nice_To_Have_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Nice_To_Have_Skill_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Job_Nice_To_Have_Skill_Sum_Fields = {
  __typename?: 'job_nice_to_have_skill_sum_fields';
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "job_nice_to_have_skill" */
export enum Job_Nice_To_Have_Skill_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Job_Nice_To_Have_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Nice_To_Have_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Nice_To_Have_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Nice_To_Have_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Nice_To_Have_Skill_Var_Pop_Fields = {
  __typename?: 'job_nice_to_have_skill_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Nice_To_Have_Skill_Var_Samp_Fields = {
  __typename?: 'job_nice_to_have_skill_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Nice_To_Have_Skill_Variance_Fields = {
  __typename?: 'job_nice_to_have_skill_variance_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_nice_to_have_skill" */
export type Job_Nice_To_Have_Skill_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "job" */
export type Job_Obj_Rel_Insert_Input = {
  data: Job_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_On_Conflict>;
};

/** on_conflict condition type for table "job" */
export type Job_On_Conflict = {
  constraint: Job_Constraint;
  update_columns?: Array<Job_Update_Column>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** Ordering options when selecting data from "job". */
export type Job_Order_By = {
  about?: InputMaybe<Order_By>;
  candidate_job_workflows_aggregate?: InputMaybe<Candidate_Job_Workflow_Aggregate_Order_By>;
  contract_length?: InputMaybe<Order_By>;
  contract_to_hire?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  education_requirement?: InputMaybe<Order_By>;
  employment_type?: InputMaybe<Order_By>;
  hiring_manager?: InputMaybe<User_Info_Order_By>;
  hiring_manager_id?: InputMaybe<Order_By>;
  hiring_manager_user_invitation?: InputMaybe<User_Invitation_Order_By>;
  hiring_manager_user_invite_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  icims_profile_job?: InputMaybe<Icims_Profile_Job_Order_By>;
  id?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  interview_details_aggregate?: InputMaybe<Interview_Detail_Aggregate_Order_By>;
  job_acceptable_locations_aggregate?: InputMaybe<Job_Acceptable_Location_Aggregate_Order_By>;
  job_meta_info?: InputMaybe<Job_Meta_Info_Order_By>;
  job_meta_infos_aggregate?: InputMaybe<Job_Meta_Info_Aggregate_Order_By>;
  job_nice_to_have_skills_aggregate?: InputMaybe<Job_Nice_To_Have_Skill_Aggregate_Order_By>;
  job_offering_selection?: InputMaybe<Order_By>;
  job_positions_aggregate?: InputMaybe<Job_Position_Aggregate_Order_By>;
  job_required_skills_aggregate?: InputMaybe<Job_Required_Skill_Aggregate_Order_By>;
  job_status_logs_aggregate?: InputMaybe<Job_Status_Log_Aggregate_Order_By>;
  job_tech_stack_aggregate?: InputMaybe<Job_Tech_Stack_Aggregate_Order_By>;
  level?: InputMaybe<Order_By>;
  level_choice?: InputMaybe<Job_Level_Choices_Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  merge_ats_job_id?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  min_years_of_experience?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  offering_bonus?: InputMaybe<Order_By>;
  offering_equity?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  posted?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  role_type?: InputMaybe<Order_By>;
  service_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  tp_kickoff_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  what_youll_bring?: InputMaybe<Order_By>;
  what_youll_do?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job */
export type Job_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "job_position" */
export type Job_Position = {
  __typename?: 'job_position';
  /** An object relationship */
  candidate: Maybe<Candidate>;
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  filled_on: Maybe<Scalars['timestamptz']>;
  icims_job_profile_id: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['Int'];
  opened_on: Maybe<Scalars['timestamptz']>;
  status: Job_Status_Choices_Enum;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "job_position" */
export type Job_Position_Aggregate = {
  __typename?: 'job_position_aggregate';
  aggregate: Maybe<Job_Position_Aggregate_Fields>;
  nodes: Array<Job_Position>;
};

export type Job_Position_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Position_Aggregate_Bool_Exp_Count>;
};

export type Job_Position_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Position_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Position_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_position" */
export type Job_Position_Aggregate_Fields = {
  __typename?: 'job_position_aggregate_fields';
  avg: Maybe<Job_Position_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Job_Position_Max_Fields>;
  min: Maybe<Job_Position_Min_Fields>;
  stddev: Maybe<Job_Position_Stddev_Fields>;
  stddev_pop: Maybe<Job_Position_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Job_Position_Stddev_Samp_Fields>;
  sum: Maybe<Job_Position_Sum_Fields>;
  var_pop: Maybe<Job_Position_Var_Pop_Fields>;
  var_samp: Maybe<Job_Position_Var_Samp_Fields>;
  variance: Maybe<Job_Position_Variance_Fields>;
};

/** aggregate fields of "job_position" */
export type Job_Position_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Position_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_position" */
export type Job_Position_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Position_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Position_Max_Order_By>;
  min?: InputMaybe<Job_Position_Min_Order_By>;
  stddev?: InputMaybe<Job_Position_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Position_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Position_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Position_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Position_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Position_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Position_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_position" */
export type Job_Position_Arr_Rel_Insert_Input = {
  data: Array<Job_Position_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Position_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Position_Avg_Fields = {
  __typename?: 'job_position_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_position" */
export type Job_Position_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_position". All fields are combined with a logical 'AND'. */
export type Job_Position_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Position_Bool_Exp>>;
  _not?: InputMaybe<Job_Position_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Position_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  filled_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  icims_job_profile_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  opened_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<Job_Status_Choices_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_position" */
export enum Job_Position_Constraint {
  /** unique or primary key constraint on columns "icims_job_profile_id", "job_id" */
  JobPositionIcimsJobProfileIdJobIdKey = 'job_position_icims_job_profile_id_job_id_key',
  /** unique or primary key constraint on columns "id" */
  JobPositionPkey = 'job_position_pkey',
}

/** input type for incrementing numeric columns in table "job_position" */
export type Job_Position_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  icims_job_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_position" */
export type Job_Position_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  filled_on?: InputMaybe<Scalars['timestamptz']>;
  icims_job_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['Int']>;
  opened_on?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Job_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Job_Position_Max_Fields = {
  __typename?: 'job_position_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  filled_on: Maybe<Scalars['timestamptz']>;
  icims_job_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  opened_on: Maybe<Scalars['timestamptz']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "job_position" */
export type Job_Position_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  filled_on?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  opened_on?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Position_Min_Fields = {
  __typename?: 'job_position_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  filled_on: Maybe<Scalars['timestamptz']>;
  icims_job_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  opened_on: Maybe<Scalars['timestamptz']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "job_position" */
export type Job_Position_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  filled_on?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  opened_on?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_position" */
export type Job_Position_Mutation_Response = {
  __typename?: 'job_position_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Position>;
};

/** on_conflict condition type for table "job_position" */
export type Job_Position_On_Conflict = {
  constraint: Job_Position_Constraint;
  update_columns?: Array<Job_Position_Update_Column>;
  where?: InputMaybe<Job_Position_Bool_Exp>;
};

/** Ordering options when selecting data from "job_position". */
export type Job_Position_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  filled_on?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  opened_on?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_position */
export type Job_Position_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "job_position" */
export enum Job_Position_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilledOn = 'filled_on',
  /** column name */
  IcimsJobProfileId = 'icims_job_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OpenedOn = 'opened_on',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "job_position" */
export type Job_Position_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  filled_on?: InputMaybe<Scalars['timestamptz']>;
  icims_job_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  opened_on?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Job_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Job_Position_Stddev_Fields = {
  __typename?: 'job_position_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_position" */
export type Job_Position_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Position_Stddev_Pop_Fields = {
  __typename?: 'job_position_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_position" */
export type Job_Position_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Position_Stddev_Samp_Fields = {
  __typename?: 'job_position_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_position" */
export type Job_Position_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_position" */
export type Job_Position_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Position_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Position_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  filled_on?: InputMaybe<Scalars['timestamptz']>;
  icims_job_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  opened_on?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Job_Status_Choices_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Job_Position_Sum_Fields = {
  __typename?: 'job_position_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  icims_job_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_position" */
export type Job_Position_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** update columns of table "job_position" */
export enum Job_Position_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilledOn = 'filled_on',
  /** column name */
  IcimsJobProfileId = 'icims_job_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  OpenedOn = 'opened_on',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Job_Position_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Position_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Position_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Position_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Position_Var_Pop_Fields = {
  __typename?: 'job_position_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_position" */
export type Job_Position_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Position_Var_Samp_Fields = {
  __typename?: 'job_position_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_position" */
export type Job_Position_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Position_Variance_Fields = {
  __typename?: 'job_position_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_position" */
export type Job_Position_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "job_priority_choices" */
export type Job_Priority_Choices = {
  __typename?: 'job_priority_choices';
  value: Scalars['String'];
};

/** aggregated selection of "job_priority_choices" */
export type Job_Priority_Choices_Aggregate = {
  __typename?: 'job_priority_choices_aggregate';
  aggregate: Maybe<Job_Priority_Choices_Aggregate_Fields>;
  nodes: Array<Job_Priority_Choices>;
};

/** aggregate fields of "job_priority_choices" */
export type Job_Priority_Choices_Aggregate_Fields = {
  __typename?: 'job_priority_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Job_Priority_Choices_Max_Fields>;
  min: Maybe<Job_Priority_Choices_Min_Fields>;
};

/** aggregate fields of "job_priority_choices" */
export type Job_Priority_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Priority_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "job_priority_choices". All fields are combined with a logical 'AND'. */
export type Job_Priority_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Priority_Choices_Bool_Exp>>;
  _not?: InputMaybe<Job_Priority_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Priority_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_priority_choices" */
export enum Job_Priority_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  JobPriorityChoicesPkey = 'job_priority_choices_pkey',
}

export enum Job_Priority_Choices_Enum {
  Green = 'GREEN',
  Red = 'RED',
  Yellow = 'YELLOW',
}

/** Boolean expression to compare columns of type "job_priority_choices_enum". All fields are combined with logical 'AND'. */
export type Job_Priority_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Job_Priority_Choices_Enum>;
  _in?: InputMaybe<Array<Job_Priority_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Job_Priority_Choices_Enum>;
  _nin?: InputMaybe<Array<Job_Priority_Choices_Enum>>;
};

/** input type for inserting data into table "job_priority_choices" */
export type Job_Priority_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Priority_Choices_Max_Fields = {
  __typename?: 'job_priority_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Job_Priority_Choices_Min_Fields = {
  __typename?: 'job_priority_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "job_priority_choices" */
export type Job_Priority_Choices_Mutation_Response = {
  __typename?: 'job_priority_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Priority_Choices>;
};

/** on_conflict condition type for table "job_priority_choices" */
export type Job_Priority_Choices_On_Conflict = {
  constraint: Job_Priority_Choices_Constraint;
  update_columns?: Array<Job_Priority_Choices_Update_Column>;
  where?: InputMaybe<Job_Priority_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "job_priority_choices". */
export type Job_Priority_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_priority_choices */
export type Job_Priority_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "job_priority_choices" */
export enum Job_Priority_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "job_priority_choices" */
export type Job_Priority_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "job_priority_choices" */
export type Job_Priority_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Priority_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Priority_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "job_priority_choices" */
export enum Job_Priority_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Job_Priority_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Priority_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Priority_Choices_Bool_Exp;
};

/** columns and relationships of "job_required_skill" */
export type Job_Required_Skill = {
  __typename?: 'job_required_skill';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['Int'];
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "job_required_skill" */
export type Job_Required_Skill_Aggregate = {
  __typename?: 'job_required_skill_aggregate';
  aggregate: Maybe<Job_Required_Skill_Aggregate_Fields>;
  nodes: Array<Job_Required_Skill>;
};

export type Job_Required_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Required_Skill_Aggregate_Bool_Exp_Count>;
};

export type Job_Required_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Required_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Required_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_required_skill" */
export type Job_Required_Skill_Aggregate_Fields = {
  __typename?: 'job_required_skill_aggregate_fields';
  avg: Maybe<Job_Required_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Job_Required_Skill_Max_Fields>;
  min: Maybe<Job_Required_Skill_Min_Fields>;
  stddev: Maybe<Job_Required_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Job_Required_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Job_Required_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Job_Required_Skill_Sum_Fields>;
  var_pop: Maybe<Job_Required_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Job_Required_Skill_Var_Samp_Fields>;
  variance: Maybe<Job_Required_Skill_Variance_Fields>;
};

/** aggregate fields of "job_required_skill" */
export type Job_Required_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Required_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_required_skill" */
export type Job_Required_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Required_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Required_Skill_Max_Order_By>;
  min?: InputMaybe<Job_Required_Skill_Min_Order_By>;
  stddev?: InputMaybe<Job_Required_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Required_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Required_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Required_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Required_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Required_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Required_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_required_skill" */
export type Job_Required_Skill_Arr_Rel_Insert_Input = {
  data: Array<Job_Required_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Required_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Required_Skill_Avg_Fields = {
  __typename?: 'job_required_skill_avg_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_required_skill" */
export type Job_Required_Skill_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_required_skill". All fields are combined with a logical 'AND'. */
export type Job_Required_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Required_Skill_Bool_Exp>>;
  _not?: InputMaybe<Job_Required_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Required_Skill_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_required_skill" */
export enum Job_Required_Skill_Constraint {
  /** unique or primary key constraint on columns "skill_id", "job_id" */
  JobRequiredSkillJobIdSkillIdKey = 'job_required_skill_job_id_skill_id_key',
  /** unique or primary key constraint on columns "id" */
  JobRequiredSkillPkey = 'job_required_skill_pkey',
}

/** input type for incrementing numeric columns in table "job_required_skill" */
export type Job_Required_Skill_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_required_skill" */
export type Job_Required_Skill_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Job_Required_Skill_Max_Fields = {
  __typename?: 'job_required_skill_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "job_required_skill" */
export type Job_Required_Skill_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Required_Skill_Min_Fields = {
  __typename?: 'job_required_skill_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "job_required_skill" */
export type Job_Required_Skill_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_required_skill" */
export type Job_Required_Skill_Mutation_Response = {
  __typename?: 'job_required_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Required_Skill>;
};

/** on_conflict condition type for table "job_required_skill" */
export type Job_Required_Skill_On_Conflict = {
  constraint: Job_Required_Skill_Constraint;
  update_columns?: Array<Job_Required_Skill_Update_Column>;
  where?: InputMaybe<Job_Required_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "job_required_skill". */
export type Job_Required_Skill_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_required_skill */
export type Job_Required_Skill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "job_required_skill" */
export enum Job_Required_Skill_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "job_required_skill" */
export type Job_Required_Skill_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Job_Required_Skill_Stddev_Fields = {
  __typename?: 'job_required_skill_stddev_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_required_skill" */
export type Job_Required_Skill_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Required_Skill_Stddev_Pop_Fields = {
  __typename?: 'job_required_skill_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_required_skill" */
export type Job_Required_Skill_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Required_Skill_Stddev_Samp_Fields = {
  __typename?: 'job_required_skill_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_required_skill" */
export type Job_Required_Skill_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_required_skill" */
export type Job_Required_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Required_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Required_Skill_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Job_Required_Skill_Sum_Fields = {
  __typename?: 'job_required_skill_sum_fields';
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_required_skill" */
export type Job_Required_Skill_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "job_required_skill" */
export enum Job_Required_Skill_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Job_Required_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Required_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Required_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Required_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Required_Skill_Var_Pop_Fields = {
  __typename?: 'job_required_skill_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_required_skill" */
export type Job_Required_Skill_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Required_Skill_Var_Samp_Fields = {
  __typename?: 'job_required_skill_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_required_skill" */
export type Job_Required_Skill_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Required_Skill_Variance_Fields = {
  __typename?: 'job_required_skill_variance_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_required_skill" */
export type Job_Required_Skill_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "job_role_source_choices" */
export type Job_Role_Source_Choices = {
  __typename?: 'job_role_source_choices';
  value: Scalars['String'];
};

/** aggregated selection of "job_role_source_choices" */
export type Job_Role_Source_Choices_Aggregate = {
  __typename?: 'job_role_source_choices_aggregate';
  aggregate: Maybe<Job_Role_Source_Choices_Aggregate_Fields>;
  nodes: Array<Job_Role_Source_Choices>;
};

/** aggregate fields of "job_role_source_choices" */
export type Job_Role_Source_Choices_Aggregate_Fields = {
  __typename?: 'job_role_source_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Job_Role_Source_Choices_Max_Fields>;
  min: Maybe<Job_Role_Source_Choices_Min_Fields>;
};

/** aggregate fields of "job_role_source_choices" */
export type Job_Role_Source_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Role_Source_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "job_role_source_choices". All fields are combined with a logical 'AND'. */
export type Job_Role_Source_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Role_Source_Choices_Bool_Exp>>;
  _not?: InputMaybe<Job_Role_Source_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Role_Source_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_role_source_choices" */
export enum Job_Role_Source_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  JobRoleSourcePkey = 'job_role_source_pkey',
}

export enum Job_Role_Source_Choices_Enum {
  ClientFill = 'CLIENT_FILL',
  InitialSell = 'INITIAL_SELL',
  InternalRole = 'INTERNAL_ROLE',
  UpsellCustomer = 'UPSELL_CUSTOMER',
  UpsellTerminal = 'UPSELL_TERMINAL',
}

/** Boolean expression to compare columns of type "job_role_source_choices_enum". All fields are combined with logical 'AND'. */
export type Job_Role_Source_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Job_Role_Source_Choices_Enum>;
  _in?: InputMaybe<Array<Job_Role_Source_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Job_Role_Source_Choices_Enum>;
  _nin?: InputMaybe<Array<Job_Role_Source_Choices_Enum>>;
};

/** input type for inserting data into table "job_role_source_choices" */
export type Job_Role_Source_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Role_Source_Choices_Max_Fields = {
  __typename?: 'job_role_source_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Job_Role_Source_Choices_Min_Fields = {
  __typename?: 'job_role_source_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "job_role_source_choices" */
export type Job_Role_Source_Choices_Mutation_Response = {
  __typename?: 'job_role_source_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Role_Source_Choices>;
};

/** on_conflict condition type for table "job_role_source_choices" */
export type Job_Role_Source_Choices_On_Conflict = {
  constraint: Job_Role_Source_Choices_Constraint;
  update_columns?: Array<Job_Role_Source_Choices_Update_Column>;
  where?: InputMaybe<Job_Role_Source_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "job_role_source_choices". */
export type Job_Role_Source_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_role_source_choices */
export type Job_Role_Source_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "job_role_source_choices" */
export enum Job_Role_Source_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "job_role_source_choices" */
export type Job_Role_Source_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "job_role_source_choices" */
export type Job_Role_Source_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Role_Source_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Role_Source_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "job_role_source_choices" */
export enum Job_Role_Source_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Job_Role_Source_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Role_Source_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Role_Source_Choices_Bool_Exp;
};

/** select columns of table "job" */
export enum Job_Select_Column {
  /** column name */
  About = 'about',
  /** column name */
  ContractLength = 'contract_length',
  /** column name */
  ContractToHire = 'contract_to_hire',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EducationRequirement = 'education_requirement',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  HiringManagerId = 'hiring_manager_id',
  /** column name */
  HiringManagerUserInviteId = 'hiring_manager_user_invite_id',
  /** column name */
  IcimsJobProfileId = 'icims_job_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  InitialHeadcount = 'initial_headcount',
  /** column name */
  JobOfferingSelection = 'job_offering_selection',
  /** column name */
  Level = 'level',
  /** column name */
  MaxContractRate = 'max_contract_rate',
  /** column name */
  MaxSalary = 'max_salary',
  /** column name */
  MergeAtsJobId = 'merge_ats_job_id',
  /** column name */
  MinContractRate = 'min_contract_rate',
  /** column name */
  MinSalary = 'min_salary',
  /** column name */
  MinYearsOfExperience = 'min_years_of_experience',
  /** column name */
  Notes = 'notes',
  /** column name */
  OfferingBonus = 'offering_bonus',
  /** column name */
  OfferingEquity = 'offering_equity',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Posted = 'posted',
  /** column name */
  Priority = 'priority',
  /** column name */
  RoleType = 'role_type',
  /** column name */
  ServiceType = 'service_type',
  /** column name */
  Status = 'status',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  Title = 'title',
  /** column name */
  TpKickoffDate = 'tp_kickoff_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WhatYoullBring = 'what_youll_bring',
  /** column name */
  WhatYoullDo = 'what_youll_do',
}

/** select "job_aggregate_bool_exp_bool_and_arguments_columns" columns of table "job" */
export enum Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ContractToHire = 'contract_to_hire',
  /** column name */
  OfferingBonus = 'offering_bonus',
  /** column name */
  OfferingEquity = 'offering_equity',
  /** column name */
  Posted = 'posted',
}

/** select "job_aggregate_bool_exp_bool_or_arguments_columns" columns of table "job" */
export enum Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ContractToHire = 'contract_to_hire',
  /** column name */
  OfferingBonus = 'offering_bonus',
  /** column name */
  OfferingEquity = 'offering_equity',
  /** column name */
  Posted = 'posted',
}

/** columns and relationships of "job_service_type" */
export type Job_Service_Type = {
  __typename?: 'job_service_type';
  value: Scalars['String'];
};

/** aggregated selection of "job_service_type" */
export type Job_Service_Type_Aggregate = {
  __typename?: 'job_service_type_aggregate';
  aggregate: Maybe<Job_Service_Type_Aggregate_Fields>;
  nodes: Array<Job_Service_Type>;
};

/** aggregate fields of "job_service_type" */
export type Job_Service_Type_Aggregate_Fields = {
  __typename?: 'job_service_type_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Job_Service_Type_Max_Fields>;
  min: Maybe<Job_Service_Type_Min_Fields>;
};

/** aggregate fields of "job_service_type" */
export type Job_Service_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Service_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "job_service_type". All fields are combined with a logical 'AND'. */
export type Job_Service_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Service_Type_Bool_Exp>>;
  _not?: InputMaybe<Job_Service_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Service_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_service_type" */
export enum Job_Service_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  JobServiceTypePkey = 'job_service_type_pkey',
}

export enum Job_Service_Type_Enum {
  SelfServe = 'SELF_SERVE',
  TaLed = 'TA_LED',
}

/** Boolean expression to compare columns of type "job_service_type_enum". All fields are combined with logical 'AND'. */
export type Job_Service_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Job_Service_Type_Enum>;
  _in?: InputMaybe<Array<Job_Service_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Job_Service_Type_Enum>;
  _nin?: InputMaybe<Array<Job_Service_Type_Enum>>;
};

/** input type for inserting data into table "job_service_type" */
export type Job_Service_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Service_Type_Max_Fields = {
  __typename?: 'job_service_type_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Job_Service_Type_Min_Fields = {
  __typename?: 'job_service_type_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "job_service_type" */
export type Job_Service_Type_Mutation_Response = {
  __typename?: 'job_service_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Service_Type>;
};

/** on_conflict condition type for table "job_service_type" */
export type Job_Service_Type_On_Conflict = {
  constraint: Job_Service_Type_Constraint;
  update_columns?: Array<Job_Service_Type_Update_Column>;
  where?: InputMaybe<Job_Service_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "job_service_type". */
export type Job_Service_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_service_type */
export type Job_Service_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "job_service_type" */
export enum Job_Service_Type_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "job_service_type" */
export type Job_Service_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "job_service_type" */
export type Job_Service_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Service_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Service_Type_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "job_service_type" */
export enum Job_Service_Type_Update_Column {
  /** column name */
  Value = 'value',
}

export type Job_Service_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Service_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Service_Type_Bool_Exp;
};

/** input type for updating data in table "job" */
export type Job_Set_Input = {
  about?: InputMaybe<Scalars['String']>;
  contract_length?: InputMaybe<Contract_Length_Choices_Enum>;
  contract_to_hire?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  education_requirement?: InputMaybe<Candidate_Education_Degree_Choices_Enum>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  hiring_manager_id?: InputMaybe<Scalars['Int']>;
  hiring_manager_user_invite_id?: InputMaybe<Scalars['Int']>;
  icims_job_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_headcount?: InputMaybe<Scalars['Int']>;
  job_offering_selection?: InputMaybe<Offering_Selection_Choices_Enum>;
  level?: InputMaybe<Job_Level_Choices_Enum>;
  max_contract_rate?: InputMaybe<Scalars['numeric']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  merge_ats_job_id?: InputMaybe<Scalars['String']>;
  min_contract_rate?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
  min_years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  notes?: InputMaybe<Scalars['String']>;
  offering_bonus?: InputMaybe<Scalars['Boolean']>;
  offering_equity?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  posted?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Job_Priority_Choices_Enum>;
  role_type?: InputMaybe<Role_Choices_Enum>;
  service_type?: InputMaybe<Job_Service_Type_Enum>;
  status?: InputMaybe<Job_Status_Choices_Enum>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  title?: InputMaybe<Scalars['String']>;
  tp_kickoff_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  what_youll_bring?: InputMaybe<Scalars['String']>;
  what_youll_do?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "job_status_choices" */
export type Job_Status_Choices = {
  __typename?: 'job_status_choices';
  value: Scalars['String'];
};

/** aggregated selection of "job_status_choices" */
export type Job_Status_Choices_Aggregate = {
  __typename?: 'job_status_choices_aggregate';
  aggregate: Maybe<Job_Status_Choices_Aggregate_Fields>;
  nodes: Array<Job_Status_Choices>;
};

/** aggregate fields of "job_status_choices" */
export type Job_Status_Choices_Aggregate_Fields = {
  __typename?: 'job_status_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Job_Status_Choices_Max_Fields>;
  min: Maybe<Job_Status_Choices_Min_Fields>;
};

/** aggregate fields of "job_status_choices" */
export type Job_Status_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Status_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "job_status_choices". All fields are combined with a logical 'AND'. */
export type Job_Status_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Status_Choices_Bool_Exp>>;
  _not?: InputMaybe<Job_Status_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Status_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_status_choices" */
export enum Job_Status_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  JobStatusChoicesPkey = 'job_status_choices_pkey',
}

export enum Job_Status_Choices_Enum {
  Approved = 'APPROVED',
  ClosedFilled = 'CLOSED_FILLED',
  ClosedNotApproved = 'CLOSED_NOT_APPROVED',
  ClosedNotFilled = 'CLOSED_NOT_FILLED',
  Draft = 'DRAFT',
  OnHold = 'ON_HOLD',
  Paused = 'PAUSED',
  PendingClose = 'PENDING_CLOSE',
  Posted = 'POSTED',
  Submitted = 'SUBMITTED',
}

/** Boolean expression to compare columns of type "job_status_choices_enum". All fields are combined with logical 'AND'. */
export type Job_Status_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Job_Status_Choices_Enum>;
  _in?: InputMaybe<Array<Job_Status_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Job_Status_Choices_Enum>;
  _nin?: InputMaybe<Array<Job_Status_Choices_Enum>>;
};

/** input type for inserting data into table "job_status_choices" */
export type Job_Status_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Status_Choices_Max_Fields = {
  __typename?: 'job_status_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Job_Status_Choices_Min_Fields = {
  __typename?: 'job_status_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "job_status_choices" */
export type Job_Status_Choices_Mutation_Response = {
  __typename?: 'job_status_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Status_Choices>;
};

/** on_conflict condition type for table "job_status_choices" */
export type Job_Status_Choices_On_Conflict = {
  constraint: Job_Status_Choices_Constraint;
  update_columns?: Array<Job_Status_Choices_Update_Column>;
  where?: InputMaybe<Job_Status_Choices_Bool_Exp>;
};

/** columns and relationships of "job_status_choices_order" */
export type Job_Status_Choices_Order = {
  __typename?: 'job_status_choices_order';
  order: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "job_status_choices_order" */
export type Job_Status_Choices_Order_Aggregate = {
  __typename?: 'job_status_choices_order_aggregate';
  aggregate: Maybe<Job_Status_Choices_Order_Aggregate_Fields>;
  nodes: Array<Job_Status_Choices_Order>;
};

/** aggregate fields of "job_status_choices_order" */
export type Job_Status_Choices_Order_Aggregate_Fields = {
  __typename?: 'job_status_choices_order_aggregate_fields';
  avg: Maybe<Job_Status_Choices_Order_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Job_Status_Choices_Order_Max_Fields>;
  min: Maybe<Job_Status_Choices_Order_Min_Fields>;
  stddev: Maybe<Job_Status_Choices_Order_Stddev_Fields>;
  stddev_pop: Maybe<Job_Status_Choices_Order_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Job_Status_Choices_Order_Stddev_Samp_Fields>;
  sum: Maybe<Job_Status_Choices_Order_Sum_Fields>;
  var_pop: Maybe<Job_Status_Choices_Order_Var_Pop_Fields>;
  var_samp: Maybe<Job_Status_Choices_Order_Var_Samp_Fields>;
  variance: Maybe<Job_Status_Choices_Order_Variance_Fields>;
};

/** aggregate fields of "job_status_choices_order" */
export type Job_Status_Choices_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Status_Choices_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Job_Status_Choices_Order_Avg_Fields = {
  __typename?: 'job_status_choices_order_avg_fields';
  order: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "job_status_choices_order". All fields are combined with a logical 'AND'. */
export type Job_Status_Choices_Order_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Status_Choices_Order_Bool_Exp>>;
  _not?: InputMaybe<Job_Status_Choices_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Status_Choices_Order_Bool_Exp>>;
  order?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "job_status_choices". */
export type Job_Status_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "job_status_choices_order" */
export enum Job_Status_Choices_Order_Constraint {
  /** unique or primary key constraint on columns "value" */
  JobStatusChoicesOrderPkey = 'job_status_choices_order_pkey',
}

/** input type for incrementing numeric columns in table "job_status_choices_order" */
export type Job_Status_Choices_Order_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_status_choices_order" */
export type Job_Status_Choices_Order_Insert_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Status_Choices_Order_Max_Fields = {
  __typename?: 'job_status_choices_order_max_fields';
  order: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Job_Status_Choices_Order_Min_Fields = {
  __typename?: 'job_status_choices_order_min_fields';
  order: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "job_status_choices_order" */
export type Job_Status_Choices_Order_Mutation_Response = {
  __typename?: 'job_status_choices_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Status_Choices_Order>;
};

/** on_conflict condition type for table "job_status_choices_order" */
export type Job_Status_Choices_Order_On_Conflict = {
  constraint: Job_Status_Choices_Order_Constraint;
  update_columns?: Array<Job_Status_Choices_Order_Update_Column>;
  where?: InputMaybe<Job_Status_Choices_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "job_status_choices_order". */
export type Job_Status_Choices_Order_Order_By = {
  order?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_status_choices_order */
export type Job_Status_Choices_Order_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "job_status_choices_order" */
export enum Job_Status_Choices_Order_Select_Column {
  /** column name */
  Order = 'order',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "job_status_choices_order" */
export type Job_Status_Choices_Order_Set_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Job_Status_Choices_Order_Stddev_Fields = {
  __typename?: 'job_status_choices_order_stddev_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Job_Status_Choices_Order_Stddev_Pop_Fields = {
  __typename?: 'job_status_choices_order_stddev_pop_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Job_Status_Choices_Order_Stddev_Samp_Fields = {
  __typename?: 'job_status_choices_order_stddev_samp_fields';
  order: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "job_status_choices_order" */
export type Job_Status_Choices_Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Status_Choices_Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Status_Choices_Order_Stream_Cursor_Value_Input = {
  order?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Job_Status_Choices_Order_Sum_Fields = {
  __typename?: 'job_status_choices_order_sum_fields';
  order: Maybe<Scalars['Int']>;
};

/** update columns of table "job_status_choices_order" */
export enum Job_Status_Choices_Order_Update_Column {
  /** column name */
  Order = 'order',
  /** column name */
  Value = 'value',
}

export type Job_Status_Choices_Order_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Status_Choices_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Status_Choices_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Status_Choices_Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Status_Choices_Order_Var_Pop_Fields = {
  __typename?: 'job_status_choices_order_var_pop_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Job_Status_Choices_Order_Var_Samp_Fields = {
  __typename?: 'job_status_choices_order_var_samp_fields';
  order: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Job_Status_Choices_Order_Variance_Fields = {
  __typename?: 'job_status_choices_order_variance_fields';
  order: Maybe<Scalars['Float']>;
};

/** primary key columns input for table: job_status_choices */
export type Job_Status_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "job_status_choices" */
export enum Job_Status_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "job_status_choices" */
export type Job_Status_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "job_status_choices" */
export type Job_Status_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Status_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Status_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "job_status_choices" */
export enum Job_Status_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Job_Status_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Status_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Status_Choices_Bool_Exp;
};

/** This table is supposed to be auto populated by a trigger, do not add anything manually. */
export type Job_Status_Log = {
  __typename?: 'job_status_log';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  job_id: Scalars['Int'];
  status: Scalars['String'];
  updated_by_user_id: Maybe<Scalars['Int']>;
};

/** aggregated selection of "job_status_log" */
export type Job_Status_Log_Aggregate = {
  __typename?: 'job_status_log_aggregate';
  aggregate: Maybe<Job_Status_Log_Aggregate_Fields>;
  nodes: Array<Job_Status_Log>;
};

export type Job_Status_Log_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Status_Log_Aggregate_Bool_Exp_Count>;
};

export type Job_Status_Log_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Status_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Status_Log_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_status_log" */
export type Job_Status_Log_Aggregate_Fields = {
  __typename?: 'job_status_log_aggregate_fields';
  avg: Maybe<Job_Status_Log_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Job_Status_Log_Max_Fields>;
  min: Maybe<Job_Status_Log_Min_Fields>;
  stddev: Maybe<Job_Status_Log_Stddev_Fields>;
  stddev_pop: Maybe<Job_Status_Log_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Job_Status_Log_Stddev_Samp_Fields>;
  sum: Maybe<Job_Status_Log_Sum_Fields>;
  var_pop: Maybe<Job_Status_Log_Var_Pop_Fields>;
  var_samp: Maybe<Job_Status_Log_Var_Samp_Fields>;
  variance: Maybe<Job_Status_Log_Variance_Fields>;
};

/** aggregate fields of "job_status_log" */
export type Job_Status_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Status_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_status_log" */
export type Job_Status_Log_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Status_Log_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Status_Log_Max_Order_By>;
  min?: InputMaybe<Job_Status_Log_Min_Order_By>;
  stddev?: InputMaybe<Job_Status_Log_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Status_Log_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Status_Log_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Status_Log_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Status_Log_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Status_Log_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Status_Log_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_status_log" */
export type Job_Status_Log_Arr_Rel_Insert_Input = {
  data: Array<Job_Status_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Status_Log_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Status_Log_Avg_Fields = {
  __typename?: 'job_status_log_avg_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  updated_by_user_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_status_log" */
export type Job_Status_Log_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_status_log". All fields are combined with a logical 'AND'. */
export type Job_Status_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Status_Log_Bool_Exp>>;
  _not?: InputMaybe<Job_Status_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Status_Log_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_by_user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_status_log" */
export enum Job_Status_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobStatusLogPkey = 'job_status_log_pkey',
}

/** input type for incrementing numeric columns in table "job_status_log" */
export type Job_Status_Log_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  updated_by_user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_status_log" */
export type Job_Status_Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_by_user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Job_Status_Log_Max_Fields = {
  __typename?: 'job_status_log_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  status: Maybe<Scalars['String']>;
  updated_by_user_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "job_status_log" */
export type Job_Status_Log_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Status_Log_Min_Fields = {
  __typename?: 'job_status_log_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  status: Maybe<Scalars['String']>;
  updated_by_user_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "job_status_log" */
export type Job_Status_Log_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_status_log" */
export type Job_Status_Log_Mutation_Response = {
  __typename?: 'job_status_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Status_Log>;
};

/** on_conflict condition type for table "job_status_log" */
export type Job_Status_Log_On_Conflict = {
  constraint: Job_Status_Log_Constraint;
  update_columns?: Array<Job_Status_Log_Update_Column>;
  where?: InputMaybe<Job_Status_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "job_status_log". */
export type Job_Status_Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_status_log */
export type Job_Status_Log_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "job_status_log" */
export enum Job_Status_Log_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id',
}

/** input type for updating data in table "job_status_log" */
export type Job_Status_Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_by_user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Job_Status_Log_Stddev_Fields = {
  __typename?: 'job_status_log_stddev_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  updated_by_user_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_status_log" */
export type Job_Status_Log_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Status_Log_Stddev_Pop_Fields = {
  __typename?: 'job_status_log_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  updated_by_user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_status_log" */
export type Job_Status_Log_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Status_Log_Stddev_Samp_Fields = {
  __typename?: 'job_status_log_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  updated_by_user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_status_log" */
export type Job_Status_Log_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_status_log" */
export type Job_Status_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Status_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Status_Log_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_by_user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Job_Status_Log_Sum_Fields = {
  __typename?: 'job_status_log_sum_fields';
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  updated_by_user_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_status_log" */
export type Job_Status_Log_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
};

/** update columns of table "job_status_log" */
export enum Job_Status_Log_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedByUserId = 'updated_by_user_id',
}

export type Job_Status_Log_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Status_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Status_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Status_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Status_Log_Var_Pop_Fields = {
  __typename?: 'job_status_log_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  updated_by_user_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_status_log" */
export type Job_Status_Log_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Status_Log_Var_Samp_Fields = {
  __typename?: 'job_status_log_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  updated_by_user_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_status_log" */
export type Job_Status_Log_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Status_Log_Variance_Fields = {
  __typename?: 'job_status_log_variance_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  updated_by_user_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_status_log" */
export type Job_Status_Log_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Job_Stddev_Fields = {
  __typename?: 'job_stddev_fields';
  hiring_manager_id: Maybe<Scalars['Float']>;
  hiring_manager_user_invite_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job" */
export type Job_Stddev_Order_By = {
  hiring_manager_id?: InputMaybe<Order_By>;
  hiring_manager_user_invite_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Stddev_Pop_Fields = {
  __typename?: 'job_stddev_pop_fields';
  hiring_manager_id: Maybe<Scalars['Float']>;
  hiring_manager_user_invite_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job" */
export type Job_Stddev_Pop_Order_By = {
  hiring_manager_id?: InputMaybe<Order_By>;
  hiring_manager_user_invite_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Stddev_Samp_Fields = {
  __typename?: 'job_stddev_samp_fields';
  hiring_manager_id: Maybe<Scalars['Float']>;
  hiring_manager_user_invite_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job" */
export type Job_Stddev_Samp_Order_By = {
  hiring_manager_id?: InputMaybe<Order_By>;
  hiring_manager_user_invite_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job" */
export type Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Stream_Cursor_Value_Input = {
  about?: InputMaybe<Scalars['String']>;
  contract_length?: InputMaybe<Contract_Length_Choices_Enum>;
  contract_to_hire?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  education_requirement?: InputMaybe<Candidate_Education_Degree_Choices_Enum>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  hiring_manager_id?: InputMaybe<Scalars['Int']>;
  hiring_manager_user_invite_id?: InputMaybe<Scalars['Int']>;
  icims_job_profile_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_headcount?: InputMaybe<Scalars['Int']>;
  job_offering_selection?: InputMaybe<Offering_Selection_Choices_Enum>;
  level?: InputMaybe<Job_Level_Choices_Enum>;
  max_contract_rate?: InputMaybe<Scalars['numeric']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  merge_ats_job_id?: InputMaybe<Scalars['String']>;
  min_contract_rate?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
  min_years_of_experience?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Enum>;
  notes?: InputMaybe<Scalars['String']>;
  offering_bonus?: InputMaybe<Scalars['Boolean']>;
  offering_equity?: InputMaybe<Scalars['Boolean']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  posted?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Job_Priority_Choices_Enum>;
  role_type?: InputMaybe<Role_Choices_Enum>;
  service_type?: InputMaybe<Job_Service_Type_Enum>;
  status?: InputMaybe<Job_Status_Choices_Enum>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  title?: InputMaybe<Scalars['String']>;
  tp_kickoff_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  what_youll_bring?: InputMaybe<Scalars['String']>;
  what_youll_do?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Job_Sum_Fields = {
  __typename?: 'job_sum_fields';
  hiring_manager_id: Maybe<Scalars['Int']>;
  hiring_manager_user_invite_id: Maybe<Scalars['Int']>;
  icims_job_profile_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  initial_headcount: Maybe<Scalars['Int']>;
  max_contract_rate: Maybe<Scalars['numeric']>;
  max_salary: Maybe<Scalars['numeric']>;
  min_contract_rate: Maybe<Scalars['numeric']>;
  min_salary: Maybe<Scalars['numeric']>;
  organization_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job" */
export type Job_Sum_Order_By = {
  hiring_manager_id?: InputMaybe<Order_By>;
  hiring_manager_user_invite_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "job_tech_stack" */
export type Job_Tech_Stack = {
  __typename?: 'job_tech_stack';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['Int'];
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "job_tech_stack" */
export type Job_Tech_Stack_Aggregate = {
  __typename?: 'job_tech_stack_aggregate';
  aggregate: Maybe<Job_Tech_Stack_Aggregate_Fields>;
  nodes: Array<Job_Tech_Stack>;
};

export type Job_Tech_Stack_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Tech_Stack_Aggregate_Bool_Exp_Count>;
};

export type Job_Tech_Stack_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Tech_Stack_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Tech_Stack_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_tech_stack" */
export type Job_Tech_Stack_Aggregate_Fields = {
  __typename?: 'job_tech_stack_aggregate_fields';
  avg: Maybe<Job_Tech_Stack_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Job_Tech_Stack_Max_Fields>;
  min: Maybe<Job_Tech_Stack_Min_Fields>;
  stddev: Maybe<Job_Tech_Stack_Stddev_Fields>;
  stddev_pop: Maybe<Job_Tech_Stack_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Job_Tech_Stack_Stddev_Samp_Fields>;
  sum: Maybe<Job_Tech_Stack_Sum_Fields>;
  var_pop: Maybe<Job_Tech_Stack_Var_Pop_Fields>;
  var_samp: Maybe<Job_Tech_Stack_Var_Samp_Fields>;
  variance: Maybe<Job_Tech_Stack_Variance_Fields>;
};

/** aggregate fields of "job_tech_stack" */
export type Job_Tech_Stack_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Tech_Stack_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_tech_stack" */
export type Job_Tech_Stack_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Tech_Stack_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Tech_Stack_Max_Order_By>;
  min?: InputMaybe<Job_Tech_Stack_Min_Order_By>;
  stddev?: InputMaybe<Job_Tech_Stack_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Tech_Stack_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Tech_Stack_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Tech_Stack_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Tech_Stack_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Tech_Stack_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Tech_Stack_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "job_tech_stack" */
export type Job_Tech_Stack_Arr_Rel_Insert_Input = {
  data: Array<Job_Tech_Stack_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Tech_Stack_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Tech_Stack_Avg_Fields = {
  __typename?: 'job_tech_stack_avg_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_tech_stack" */
export type Job_Tech_Stack_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_tech_stack". All fields are combined with a logical 'AND'. */
export type Job_Tech_Stack_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Tech_Stack_Bool_Exp>>;
  _not?: InputMaybe<Job_Tech_Stack_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Tech_Stack_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_tech_stack" */
export enum Job_Tech_Stack_Constraint {
  /** unique or primary key constraint on columns "skill_id", "job_id" */
  JobTechStackJobIdSkillIdKey = 'job_tech_stack_job_id_skill_id_key',
  /** unique or primary key constraint on columns "id" */
  JobTechStackPkey = 'job_tech_stack_pkey',
}

/** input type for incrementing numeric columns in table "job_tech_stack" */
export type Job_Tech_Stack_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "job_tech_stack" */
export type Job_Tech_Stack_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Job_Tech_Stack_Max_Fields = {
  __typename?: 'job_tech_stack_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "job_tech_stack" */
export type Job_Tech_Stack_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Tech_Stack_Min_Fields = {
  __typename?: 'job_tech_stack_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "job_tech_stack" */
export type Job_Tech_Stack_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_tech_stack" */
export type Job_Tech_Stack_Mutation_Response = {
  __typename?: 'job_tech_stack_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Tech_Stack>;
};

/** on_conflict condition type for table "job_tech_stack" */
export type Job_Tech_Stack_On_Conflict = {
  constraint: Job_Tech_Stack_Constraint;
  update_columns?: Array<Job_Tech_Stack_Update_Column>;
  where?: InputMaybe<Job_Tech_Stack_Bool_Exp>;
};

/** Ordering options when selecting data from "job_tech_stack". */
export type Job_Tech_Stack_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_tech_stack */
export type Job_Tech_Stack_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "job_tech_stack" */
export enum Job_Tech_Stack_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "job_tech_stack" */
export type Job_Tech_Stack_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Job_Tech_Stack_Stddev_Fields = {
  __typename?: 'job_tech_stack_stddev_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_tech_stack" */
export type Job_Tech_Stack_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Tech_Stack_Stddev_Pop_Fields = {
  __typename?: 'job_tech_stack_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_tech_stack" */
export type Job_Tech_Stack_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Tech_Stack_Stddev_Samp_Fields = {
  __typename?: 'job_tech_stack_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_tech_stack" */
export type Job_Tech_Stack_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "job_tech_stack" */
export type Job_Tech_Stack_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Tech_Stack_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Tech_Stack_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Job_Tech_Stack_Sum_Fields = {
  __typename?: 'job_tech_stack_sum_fields';
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "job_tech_stack" */
export type Job_Tech_Stack_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "job_tech_stack" */
export enum Job_Tech_Stack_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Job_Tech_Stack_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Tech_Stack_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Tech_Stack_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Tech_Stack_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Tech_Stack_Var_Pop_Fields = {
  __typename?: 'job_tech_stack_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_tech_stack" */
export type Job_Tech_Stack_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Tech_Stack_Var_Samp_Fields = {
  __typename?: 'job_tech_stack_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_tech_stack" */
export type Job_Tech_Stack_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Tech_Stack_Variance_Fields = {
  __typename?: 'job_tech_stack_variance_fields';
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_tech_stack" */
export type Job_Tech_Stack_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "job" */
export enum Job_Update_Column {
  /** column name */
  About = 'about',
  /** column name */
  ContractLength = 'contract_length',
  /** column name */
  ContractToHire = 'contract_to_hire',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EducationRequirement = 'education_requirement',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  HiringManagerId = 'hiring_manager_id',
  /** column name */
  HiringManagerUserInviteId = 'hiring_manager_user_invite_id',
  /** column name */
  IcimsJobProfileId = 'icims_job_profile_id',
  /** column name */
  Id = 'id',
  /** column name */
  InitialHeadcount = 'initial_headcount',
  /** column name */
  JobOfferingSelection = 'job_offering_selection',
  /** column name */
  Level = 'level',
  /** column name */
  MaxContractRate = 'max_contract_rate',
  /** column name */
  MaxSalary = 'max_salary',
  /** column name */
  MergeAtsJobId = 'merge_ats_job_id',
  /** column name */
  MinContractRate = 'min_contract_rate',
  /** column name */
  MinSalary = 'min_salary',
  /** column name */
  MinYearsOfExperience = 'min_years_of_experience',
  /** column name */
  Notes = 'notes',
  /** column name */
  OfferingBonus = 'offering_bonus',
  /** column name */
  OfferingEquity = 'offering_equity',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Posted = 'posted',
  /** column name */
  Priority = 'priority',
  /** column name */
  RoleType = 'role_type',
  /** column name */
  ServiceType = 'service_type',
  /** column name */
  Status = 'status',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  Title = 'title',
  /** column name */
  TpKickoffDate = 'tp_kickoff_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WhatYoullBring = 'what_youll_bring',
  /** column name */
  WhatYoullDo = 'what_youll_do',
}

export type Job_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Job_Var_Pop_Fields = {
  __typename?: 'job_var_pop_fields';
  hiring_manager_id: Maybe<Scalars['Float']>;
  hiring_manager_user_invite_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job" */
export type Job_Var_Pop_Order_By = {
  hiring_manager_id?: InputMaybe<Order_By>;
  hiring_manager_user_invite_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Var_Samp_Fields = {
  __typename?: 'job_var_samp_fields';
  hiring_manager_id: Maybe<Scalars['Float']>;
  hiring_manager_user_invite_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job" */
export type Job_Var_Samp_Order_By = {
  hiring_manager_id?: InputMaybe<Order_By>;
  hiring_manager_user_invite_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Variance_Fields = {
  __typename?: 'job_variance_fields';
  hiring_manager_id: Maybe<Scalars['Float']>;
  hiring_manager_user_invite_id: Maybe<Scalars['Float']>;
  icims_job_profile_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  initial_headcount: Maybe<Scalars['Float']>;
  max_contract_rate: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_contract_rate: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job" */
export type Job_Variance_Order_By = {
  hiring_manager_id?: InputMaybe<Order_By>;
  hiring_manager_user_invite_id?: InputMaybe<Order_By>;
  icims_job_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_headcount?: InputMaybe<Order_By>;
  max_contract_rate?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_contract_rate?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "linkedin_contact" */
export type Linkedin_Contact = {
  __typename?: 'linkedin_contact';
  contact: Scalars['Int'];
  /** An object relationship */
  contactByContact: Contact;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  occupation_description: Scalars['String'];
  photo_url: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_linkedin_contacts: Array<User_Linkedin_Contact>;
  /** An aggregate relationship */
  user_linkedin_contacts_aggregate: User_Linkedin_Contact_Aggregate;
};

/** columns and relationships of "linkedin_contact" */
export type Linkedin_ContactUser_Linkedin_ContactsArgs = {
  distinct_on?: InputMaybe<Array<User_Linkedin_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linkedin_Contact_Order_By>>;
  where?: InputMaybe<User_Linkedin_Contact_Bool_Exp>;
};

/** columns and relationships of "linkedin_contact" */
export type Linkedin_ContactUser_Linkedin_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Linkedin_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linkedin_Contact_Order_By>>;
  where?: InputMaybe<User_Linkedin_Contact_Bool_Exp>;
};

/** aggregated selection of "linkedin_contact" */
export type Linkedin_Contact_Aggregate = {
  __typename?: 'linkedin_contact_aggregate';
  aggregate: Maybe<Linkedin_Contact_Aggregate_Fields>;
  nodes: Array<Linkedin_Contact>;
};

/** aggregate fields of "linkedin_contact" */
export type Linkedin_Contact_Aggregate_Fields = {
  __typename?: 'linkedin_contact_aggregate_fields';
  avg: Maybe<Linkedin_Contact_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Linkedin_Contact_Max_Fields>;
  min: Maybe<Linkedin_Contact_Min_Fields>;
  stddev: Maybe<Linkedin_Contact_Stddev_Fields>;
  stddev_pop: Maybe<Linkedin_Contact_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Linkedin_Contact_Stddev_Samp_Fields>;
  sum: Maybe<Linkedin_Contact_Sum_Fields>;
  var_pop: Maybe<Linkedin_Contact_Var_Pop_Fields>;
  var_samp: Maybe<Linkedin_Contact_Var_Samp_Fields>;
  variance: Maybe<Linkedin_Contact_Variance_Fields>;
};

/** aggregate fields of "linkedin_contact" */
export type Linkedin_Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Linkedin_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Linkedin_Contact_Avg_Fields = {
  __typename?: 'linkedin_contact_avg_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "linkedin_contact". All fields are combined with a logical 'AND'. */
export type Linkedin_Contact_Bool_Exp = {
  _and?: InputMaybe<Array<Linkedin_Contact_Bool_Exp>>;
  _not?: InputMaybe<Linkedin_Contact_Bool_Exp>;
  _or?: InputMaybe<Array<Linkedin_Contact_Bool_Exp>>;
  contact?: InputMaybe<Int_Comparison_Exp>;
  contactByContact?: InputMaybe<Contact_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  occupation_description?: InputMaybe<String_Comparison_Exp>;
  photo_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_linkedin_contacts?: InputMaybe<User_Linkedin_Contact_Bool_Exp>;
  user_linkedin_contacts_aggregate?: InputMaybe<User_Linkedin_Contact_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "linkedin_contact" */
export enum Linkedin_Contact_Constraint {
  /** unique or primary key constraint on columns "contact" */
  LinkedinContactContactKey = 'linkedin_contact_contact_key',
  /** unique or primary key constraint on columns "id" */
  LinkedinContactPkey = 'linkedin_contact_pkey',
}

/** input type for incrementing numeric columns in table "linkedin_contact" */
export type Linkedin_Contact_Inc_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "linkedin_contact" */
export type Linkedin_Contact_Insert_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  contactByContact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  occupation_description?: InputMaybe<Scalars['String']>;
  photo_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_linkedin_contacts?: InputMaybe<User_Linkedin_Contact_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Linkedin_Contact_Max_Fields = {
  __typename?: 'linkedin_contact_max_fields';
  contact: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  occupation_description: Maybe<Scalars['String']>;
  photo_url: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Linkedin_Contact_Min_Fields = {
  __typename?: 'linkedin_contact_min_fields';
  contact: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  occupation_description: Maybe<Scalars['String']>;
  photo_url: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "linkedin_contact" */
export type Linkedin_Contact_Mutation_Response = {
  __typename?: 'linkedin_contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Linkedin_Contact>;
};

/** input type for inserting object relation for remote table "linkedin_contact" */
export type Linkedin_Contact_Obj_Rel_Insert_Input = {
  data: Linkedin_Contact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Linkedin_Contact_On_Conflict>;
};

/** on_conflict condition type for table "linkedin_contact" */
export type Linkedin_Contact_On_Conflict = {
  constraint: Linkedin_Contact_Constraint;
  update_columns?: Array<Linkedin_Contact_Update_Column>;
  where?: InputMaybe<Linkedin_Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "linkedin_contact". */
export type Linkedin_Contact_Order_By = {
  contact?: InputMaybe<Order_By>;
  contactByContact?: InputMaybe<Contact_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  occupation_description?: InputMaybe<Order_By>;
  photo_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_linkedin_contacts_aggregate?: InputMaybe<User_Linkedin_Contact_Aggregate_Order_By>;
};

/** primary key columns input for table: linkedin_contact */
export type Linkedin_Contact_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "linkedin_contact" */
export enum Linkedin_Contact_Select_Column {
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OccupationDescription = 'occupation_description',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "linkedin_contact" */
export type Linkedin_Contact_Set_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  occupation_description?: InputMaybe<Scalars['String']>;
  photo_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Linkedin_Contact_Stddev_Fields = {
  __typename?: 'linkedin_contact_stddev_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Linkedin_Contact_Stddev_Pop_Fields = {
  __typename?: 'linkedin_contact_stddev_pop_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Linkedin_Contact_Stddev_Samp_Fields = {
  __typename?: 'linkedin_contact_stddev_samp_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "linkedin_contact" */
export type Linkedin_Contact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Linkedin_Contact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Linkedin_Contact_Stream_Cursor_Value_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  occupation_description?: InputMaybe<Scalars['String']>;
  photo_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Linkedin_Contact_Sum_Fields = {
  __typename?: 'linkedin_contact_sum_fields';
  contact: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "linkedin_contact" */
export enum Linkedin_Contact_Update_Column {
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OccupationDescription = 'occupation_description',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Linkedin_Contact_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Linkedin_Contact_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Linkedin_Contact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Linkedin_Contact_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Linkedin_Contact_Var_Pop_Fields = {
  __typename?: 'linkedin_contact_var_pop_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Linkedin_Contact_Var_Samp_Fields = {
  __typename?: 'linkedin_contact_var_samp_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Linkedin_Contact_Variance_Fields = {
  __typename?: 'linkedin_contact_variance_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** Table containing cleaned up location names referring individual entity's location. */
export type Location = {
  __typename?: 'location';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  /** An object relationship */
  dayforce_location: Maybe<Dayforce_Location>;
  dayforce_location_id: Maybe<Scalars['Int']>;
  employment_type: Maybe<Employment_Type_Choices_Enum>;
  icims_location_id: Maybe<Scalars['Int']>;
  icims_location_idt: Maybe<Scalars['Int']>;
  icims_requested_location_id: Maybe<Scalars['String']>;
  icims_requested_location_idt: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  region: Maybe<Region_Choices_Enum>;
  remote: Maybe<Scalars['Boolean']>;
  salesforce_location_id: Maybe<Scalars['Int']>;
  state: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "location" */
export type Location_Aggregate = {
  __typename?: 'location_aggregate';
  aggregate: Maybe<Location_Aggregate_Fields>;
  nodes: Array<Location>;
};

export type Location_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Location_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Location_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Location_Aggregate_Bool_Exp_Count>;
};

export type Location_Aggregate_Bool_Exp_Bool_And = {
  arguments: Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Location_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "location" */
export type Location_Aggregate_Fields = {
  __typename?: 'location_aggregate_fields';
  avg: Maybe<Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Location_Max_Fields>;
  min: Maybe<Location_Min_Fields>;
  stddev: Maybe<Location_Stddev_Fields>;
  stddev_pop: Maybe<Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Location_Stddev_Samp_Fields>;
  sum: Maybe<Location_Sum_Fields>;
  var_pop: Maybe<Location_Var_Pop_Fields>;
  var_samp: Maybe<Location_Var_Samp_Fields>;
  variance: Maybe<Location_Variance_Fields>;
};

/** aggregate fields of "location" */
export type Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "location" */
export type Location_Aggregate_Order_By = {
  avg?: InputMaybe<Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Location_Max_Order_By>;
  min?: InputMaybe<Location_Min_Order_By>;
  stddev?: InputMaybe<Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Location_Sum_Order_By>;
  var_pop?: InputMaybe<Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "location" */
export type Location_Arr_Rel_Insert_Input = {
  data: Array<Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Location_Avg_Fields = {
  __typename?: 'location_avg_fields';
  dayforce_location_id: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  icims_location_idt: Maybe<Scalars['Float']>;
  icims_requested_location_idt: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "location" */
export type Location_Avg_Order_By = {
  dayforce_location_id?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_location_idt?: InputMaybe<Order_By>;
  icims_requested_location_idt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export type Location_Bool_Exp = {
  _and?: InputMaybe<Array<Location_Bool_Exp>>;
  _not?: InputMaybe<Location_Bool_Exp>;
  _or?: InputMaybe<Array<Location_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  dayforce_location?: InputMaybe<Dayforce_Location_Bool_Exp>;
  dayforce_location_id?: InputMaybe<Int_Comparison_Exp>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum_Comparison_Exp>;
  icims_location_id?: InputMaybe<Int_Comparison_Exp>;
  icims_location_idt?: InputMaybe<Int_Comparison_Exp>;
  icims_requested_location_id?: InputMaybe<String_Comparison_Exp>;
  icims_requested_location_idt?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  region?: InputMaybe<Region_Choices_Enum_Comparison_Exp>;
  remote?: InputMaybe<Boolean_Comparison_Exp>;
  salesforce_location_id?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "location" */
export enum Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationPkey = 'location_pkey',
}

/** input type for incrementing numeric columns in table "location" */
export type Location_Inc_Input = {
  dayforce_location_id?: InputMaybe<Scalars['Int']>;
  icims_location_id?: InputMaybe<Scalars['Int']>;
  icims_location_idt?: InputMaybe<Scalars['Int']>;
  icims_requested_location_idt?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  salesforce_location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "location" */
export type Location_Insert_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dayforce_location?: InputMaybe<Dayforce_Location_Obj_Rel_Insert_Input>;
  dayforce_location_id?: InputMaybe<Scalars['Int']>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  icims_location_id?: InputMaybe<Scalars['Int']>;
  icims_location_idt?: InputMaybe<Scalars['Int']>;
  icims_requested_location_id?: InputMaybe<Scalars['String']>;
  icims_requested_location_idt?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Region_Choices_Enum>;
  remote?: InputMaybe<Scalars['Boolean']>;
  salesforce_location_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Location_Max_Fields = {
  __typename?: 'location_max_fields';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  dayforce_location_id: Maybe<Scalars['Int']>;
  icims_location_id: Maybe<Scalars['Int']>;
  icims_location_idt: Maybe<Scalars['Int']>;
  icims_requested_location_id: Maybe<Scalars['String']>;
  icims_requested_location_idt: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  salesforce_location_id: Maybe<Scalars['Int']>;
  state: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "location" */
export type Location_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_location_idt?: InputMaybe<Order_By>;
  icims_requested_location_id?: InputMaybe<Order_By>;
  icims_requested_location_idt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Location_Min_Fields = {
  __typename?: 'location_min_fields';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  dayforce_location_id: Maybe<Scalars['Int']>;
  icims_location_id: Maybe<Scalars['Int']>;
  icims_location_idt: Maybe<Scalars['Int']>;
  icims_requested_location_id: Maybe<Scalars['String']>;
  icims_requested_location_idt: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  salesforce_location_id: Maybe<Scalars['Int']>;
  state: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "location" */
export type Location_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_location_idt?: InputMaybe<Order_By>;
  icims_requested_location_id?: InputMaybe<Order_By>;
  icims_requested_location_idt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "location" */
export type Location_Mutation_Response = {
  __typename?: 'location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Location>;
};

/** input type for inserting object relation for remote table "location" */
export type Location_Obj_Rel_Insert_Input = {
  data: Location_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Location_On_Conflict>;
};

/** on_conflict condition type for table "location" */
export type Location_On_Conflict = {
  constraint: Location_Constraint;
  update_columns?: Array<Location_Update_Column>;
  where?: InputMaybe<Location_Bool_Exp>;
};

/** Ordering options when selecting data from "location". */
export type Location_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  dayforce_location?: InputMaybe<Dayforce_Location_Order_By>;
  dayforce_location_id?: InputMaybe<Order_By>;
  employment_type?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_location_idt?: InputMaybe<Order_By>;
  icims_requested_location_id?: InputMaybe<Order_By>;
  icims_requested_location_idt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  remote?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: location */
export type Location_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "location" */
export enum Location_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  DayforceLocationId = 'dayforce_location_id',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  IcimsLocationId = 'icims_location_id',
  /** column name */
  IcimsLocationIdt = 'icims_location_idt',
  /** column name */
  IcimsRequestedLocationId = 'icims_requested_location_id',
  /** column name */
  IcimsRequestedLocationIdt = 'icims_requested_location_idt',
  /** column name */
  Id = 'id',
  /** column name */
  Region = 'region',
  /** column name */
  Remote = 'remote',
  /** column name */
  SalesforceLocationId = 'salesforce_location_id',
  /** column name */
  State = 'state',
  /** column name */
  Value = 'value',
}

/** select "location_aggregate_bool_exp_bool_and_arguments_columns" columns of table "location" */
export enum Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Remote = 'remote',
}

/** select "location_aggregate_bool_exp_bool_or_arguments_columns" columns of table "location" */
export enum Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Remote = 'remote',
}

/** input type for updating data in table "location" */
export type Location_Set_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dayforce_location_id?: InputMaybe<Scalars['Int']>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  icims_location_id?: InputMaybe<Scalars['Int']>;
  icims_location_idt?: InputMaybe<Scalars['Int']>;
  icims_requested_location_id?: InputMaybe<Scalars['String']>;
  icims_requested_location_idt?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Region_Choices_Enum>;
  remote?: InputMaybe<Scalars['Boolean']>;
  salesforce_location_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Location_Stddev_Fields = {
  __typename?: 'location_stddev_fields';
  dayforce_location_id: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  icims_location_idt: Maybe<Scalars['Float']>;
  icims_requested_location_idt: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "location" */
export type Location_Stddev_Order_By = {
  dayforce_location_id?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_location_idt?: InputMaybe<Order_By>;
  icims_requested_location_idt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Location_Stddev_Pop_Fields = {
  __typename?: 'location_stddev_pop_fields';
  dayforce_location_id: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  icims_location_idt: Maybe<Scalars['Float']>;
  icims_requested_location_idt: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "location" */
export type Location_Stddev_Pop_Order_By = {
  dayforce_location_id?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_location_idt?: InputMaybe<Order_By>;
  icims_requested_location_idt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Location_Stddev_Samp_Fields = {
  __typename?: 'location_stddev_samp_fields';
  dayforce_location_id: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  icims_location_idt: Maybe<Scalars['Float']>;
  icims_requested_location_idt: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "location" */
export type Location_Stddev_Samp_Order_By = {
  dayforce_location_id?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_location_idt?: InputMaybe<Order_By>;
  icims_requested_location_idt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "location" */
export type Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Location_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dayforce_location_id?: InputMaybe<Scalars['Int']>;
  employment_type?: InputMaybe<Employment_Type_Choices_Enum>;
  icims_location_id?: InputMaybe<Scalars['Int']>;
  icims_location_idt?: InputMaybe<Scalars['Int']>;
  icims_requested_location_id?: InputMaybe<Scalars['String']>;
  icims_requested_location_idt?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Region_Choices_Enum>;
  remote?: InputMaybe<Scalars['Boolean']>;
  salesforce_location_id?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Location_Sum_Fields = {
  __typename?: 'location_sum_fields';
  dayforce_location_id: Maybe<Scalars['Int']>;
  icims_location_id: Maybe<Scalars['Int']>;
  icims_location_idt: Maybe<Scalars['Int']>;
  icims_requested_location_idt: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  salesforce_location_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "location" */
export type Location_Sum_Order_By = {
  dayforce_location_id?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_location_idt?: InputMaybe<Order_By>;
  icims_requested_location_idt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** update columns of table "location" */
export enum Location_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  DayforceLocationId = 'dayforce_location_id',
  /** column name */
  EmploymentType = 'employment_type',
  /** column name */
  IcimsLocationId = 'icims_location_id',
  /** column name */
  IcimsLocationIdt = 'icims_location_idt',
  /** column name */
  IcimsRequestedLocationId = 'icims_requested_location_id',
  /** column name */
  IcimsRequestedLocationIdt = 'icims_requested_location_idt',
  /** column name */
  Id = 'id',
  /** column name */
  Region = 'region',
  /** column name */
  Remote = 'remote',
  /** column name */
  SalesforceLocationId = 'salesforce_location_id',
  /** column name */
  State = 'state',
  /** column name */
  Value = 'value',
}

export type Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Location_Var_Pop_Fields = {
  __typename?: 'location_var_pop_fields';
  dayforce_location_id: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  icims_location_idt: Maybe<Scalars['Float']>;
  icims_requested_location_idt: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "location" */
export type Location_Var_Pop_Order_By = {
  dayforce_location_id?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_location_idt?: InputMaybe<Order_By>;
  icims_requested_location_idt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Location_Var_Samp_Fields = {
  __typename?: 'location_var_samp_fields';
  dayforce_location_id: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  icims_location_idt: Maybe<Scalars['Float']>;
  icims_requested_location_idt: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "location" */
export type Location_Var_Samp_Order_By = {
  dayforce_location_id?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_location_idt?: InputMaybe<Order_By>;
  icims_requested_location_idt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Location_Variance_Fields = {
  __typename?: 'location_variance_fields';
  dayforce_location_id: Maybe<Scalars['Float']>;
  icims_location_id: Maybe<Scalars['Float']>;
  icims_location_idt: Maybe<Scalars['Float']>;
  icims_requested_location_idt: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "location" */
export type Location_Variance_Order_By = {
  dayforce_location_id?: InputMaybe<Order_By>;
  icims_location_id?: InputMaybe<Order_By>;
  icims_location_idt?: InputMaybe<Order_By>;
  icims_requested_location_idt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "manual_contact" */
export type Manual_Contact = {
  __typename?: 'manual_contact';
  active: Scalars['Boolean'];
  comments: Scalars['String'];
  contact: Scalars['Int'];
  /** An object relationship */
  contactByContact: Contact;
  created_at: Scalars['timestamptz'];
  email: Scalars['citext'];
  id: Scalars['Int'];
  know_about_referral: Scalars['Boolean'];
  name: Scalars['String'];
  relationship_description: Scalars['String'];
  suggested_job: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user: Scalars['Int'];
  /** An object relationship */
  user_info: User_Info;
};

/** aggregated selection of "manual_contact" */
export type Manual_Contact_Aggregate = {
  __typename?: 'manual_contact_aggregate';
  aggregate: Maybe<Manual_Contact_Aggregate_Fields>;
  nodes: Array<Manual_Contact>;
};

export type Manual_Contact_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Manual_Contact_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Manual_Contact_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Manual_Contact_Aggregate_Bool_Exp_Count>;
};

export type Manual_Contact_Aggregate_Bool_Exp_Bool_And = {
  arguments: Manual_Contact_Select_Column_Manual_Contact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Manual_Contact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Manual_Contact_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Manual_Contact_Select_Column_Manual_Contact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Manual_Contact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Manual_Contact_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Manual_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Manual_Contact_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "manual_contact" */
export type Manual_Contact_Aggregate_Fields = {
  __typename?: 'manual_contact_aggregate_fields';
  avg: Maybe<Manual_Contact_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Manual_Contact_Max_Fields>;
  min: Maybe<Manual_Contact_Min_Fields>;
  stddev: Maybe<Manual_Contact_Stddev_Fields>;
  stddev_pop: Maybe<Manual_Contact_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Manual_Contact_Stddev_Samp_Fields>;
  sum: Maybe<Manual_Contact_Sum_Fields>;
  var_pop: Maybe<Manual_Contact_Var_Pop_Fields>;
  var_samp: Maybe<Manual_Contact_Var_Samp_Fields>;
  variance: Maybe<Manual_Contact_Variance_Fields>;
};

/** aggregate fields of "manual_contact" */
export type Manual_Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Manual_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "manual_contact" */
export type Manual_Contact_Aggregate_Order_By = {
  avg?: InputMaybe<Manual_Contact_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Manual_Contact_Max_Order_By>;
  min?: InputMaybe<Manual_Contact_Min_Order_By>;
  stddev?: InputMaybe<Manual_Contact_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Manual_Contact_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Manual_Contact_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Manual_Contact_Sum_Order_By>;
  var_pop?: InputMaybe<Manual_Contact_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Manual_Contact_Var_Samp_Order_By>;
  variance?: InputMaybe<Manual_Contact_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "manual_contact" */
export type Manual_Contact_Arr_Rel_Insert_Input = {
  data: Array<Manual_Contact_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Manual_Contact_On_Conflict>;
};

/** aggregate avg on columns */
export type Manual_Contact_Avg_Fields = {
  __typename?: 'manual_contact_avg_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "manual_contact" */
export type Manual_Contact_Avg_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "manual_contact". All fields are combined with a logical 'AND'. */
export type Manual_Contact_Bool_Exp = {
  _and?: InputMaybe<Array<Manual_Contact_Bool_Exp>>;
  _not?: InputMaybe<Manual_Contact_Bool_Exp>;
  _or?: InputMaybe<Array<Manual_Contact_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  contact?: InputMaybe<Int_Comparison_Exp>;
  contactByContact?: InputMaybe<Contact_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  know_about_referral?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  relationship_description?: InputMaybe<String_Comparison_Exp>;
  suggested_job?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Int_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "manual_contact" */
export enum Manual_Contact_Constraint {
  /** unique or primary key constraint on columns "id" */
  ManualContactPkey = 'manual_contact_pkey',
  /** unique or primary key constraint on columns "user", "contact" */
  ManualContactUserContactKey = 'manual_contact_user_contact_key',
}

/** input type for incrementing numeric columns in table "manual_contact" */
export type Manual_Contact_Inc_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "manual_contact" */
export type Manual_Contact_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['Int']>;
  contactByContact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  know_about_referral?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  relationship_description?: InputMaybe<Scalars['String']>;
  suggested_job?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['Int']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Manual_Contact_Max_Fields = {
  __typename?: 'manual_contact_max_fields';
  comments: Maybe<Scalars['String']>;
  contact: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  relationship_description: Maybe<Scalars['String']>;
  suggested_job: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "manual_contact" */
export type Manual_Contact_Max_Order_By = {
  comments?: InputMaybe<Order_By>;
  contact?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  relationship_description?: InputMaybe<Order_By>;
  suggested_job?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Manual_Contact_Min_Fields = {
  __typename?: 'manual_contact_min_fields';
  comments: Maybe<Scalars['String']>;
  contact: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  relationship_description: Maybe<Scalars['String']>;
  suggested_job: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "manual_contact" */
export type Manual_Contact_Min_Order_By = {
  comments?: InputMaybe<Order_By>;
  contact?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  relationship_description?: InputMaybe<Order_By>;
  suggested_job?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "manual_contact" */
export type Manual_Contact_Mutation_Response = {
  __typename?: 'manual_contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Manual_Contact>;
};

/** input type for inserting object relation for remote table "manual_contact" */
export type Manual_Contact_Obj_Rel_Insert_Input = {
  data: Manual_Contact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Manual_Contact_On_Conflict>;
};

/** on_conflict condition type for table "manual_contact" */
export type Manual_Contact_On_Conflict = {
  constraint: Manual_Contact_Constraint;
  update_columns?: Array<Manual_Contact_Update_Column>;
  where?: InputMaybe<Manual_Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "manual_contact". */
export type Manual_Contact_Order_By = {
  active?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  contact?: InputMaybe<Order_By>;
  contactByContact?: InputMaybe<Contact_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  know_about_referral?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  relationship_description?: InputMaybe<Order_By>;
  suggested_job?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: manual_contact */
export type Manual_Contact_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "manual_contact" */
export enum Manual_Contact_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Comments = 'comments',
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  KnowAboutReferral = 'know_about_referral',
  /** column name */
  Name = 'name',
  /** column name */
  RelationshipDescription = 'relationship_description',
  /** column name */
  SuggestedJob = 'suggested_job',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  User = 'user',
}

/** select "manual_contact_aggregate_bool_exp_bool_and_arguments_columns" columns of table "manual_contact" */
export enum Manual_Contact_Select_Column_Manual_Contact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  KnowAboutReferral = 'know_about_referral',
}

/** select "manual_contact_aggregate_bool_exp_bool_or_arguments_columns" columns of table "manual_contact" */
export enum Manual_Contact_Select_Column_Manual_Contact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  KnowAboutReferral = 'know_about_referral',
}

/** input type for updating data in table "manual_contact" */
export type Manual_Contact_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  know_about_referral?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  relationship_description?: InputMaybe<Scalars['String']>;
  suggested_job?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Manual_Contact_Stddev_Fields = {
  __typename?: 'manual_contact_stddev_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "manual_contact" */
export type Manual_Contact_Stddev_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Manual_Contact_Stddev_Pop_Fields = {
  __typename?: 'manual_contact_stddev_pop_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "manual_contact" */
export type Manual_Contact_Stddev_Pop_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Manual_Contact_Stddev_Samp_Fields = {
  __typename?: 'manual_contact_stddev_samp_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "manual_contact" */
export type Manual_Contact_Stddev_Samp_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "manual_contact" */
export type Manual_Contact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Manual_Contact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Manual_Contact_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  know_about_referral?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  relationship_description?: InputMaybe<Scalars['String']>;
  suggested_job?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Manual_Contact_Sum_Fields = {
  __typename?: 'manual_contact_sum_fields';
  contact: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "manual_contact" */
export type Manual_Contact_Sum_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** update columns of table "manual_contact" */
export enum Manual_Contact_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Comments = 'comments',
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  KnowAboutReferral = 'know_about_referral',
  /** column name */
  Name = 'name',
  /** column name */
  RelationshipDescription = 'relationship_description',
  /** column name */
  SuggestedJob = 'suggested_job',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  User = 'user',
}

export type Manual_Contact_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Manual_Contact_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Manual_Contact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Manual_Contact_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Manual_Contact_Var_Pop_Fields = {
  __typename?: 'manual_contact_var_pop_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "manual_contact" */
export type Manual_Contact_Var_Pop_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Manual_Contact_Var_Samp_Fields = {
  __typename?: 'manual_contact_var_samp_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "manual_contact" */
export type Manual_Contact_Var_Samp_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Manual_Contact_Variance_Fields = {
  __typename?: 'manual_contact_variance_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "manual_contact" */
export type Manual_Contact_Variance_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** columns and relationships of "merge_ats_application" */
export type Merge_Ats_Application = {
  __typename?: 'merge_ats_application';
  applied_at: Maybe<Scalars['timestamptz']>;
  current_stage: Scalars['String'];
  field_mappings: Maybe<Scalars['jsonb']>;
  merge_application_id: Scalars['String'];
  merge_candidate_id: Scalars['String'];
  merge_job_id: Scalars['String'];
  modified_at: Maybe<Scalars['timestamptz']>;
  rejected_at: Maybe<Scalars['timestamptz']>;
  remote_id: Scalars['String'];
  source: Maybe<Scalars['String']>;
};

/** columns and relationships of "merge_ats_application" */
export type Merge_Ats_ApplicationField_MappingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "merge_ats_application" */
export type Merge_Ats_Application_Aggregate = {
  __typename?: 'merge_ats_application_aggregate';
  aggregate: Maybe<Merge_Ats_Application_Aggregate_Fields>;
  nodes: Array<Merge_Ats_Application>;
};

/** aggregate fields of "merge_ats_application" */
export type Merge_Ats_Application_Aggregate_Fields = {
  __typename?: 'merge_ats_application_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Merge_Ats_Application_Max_Fields>;
  min: Maybe<Merge_Ats_Application_Min_Fields>;
};

/** aggregate fields of "merge_ats_application" */
export type Merge_Ats_Application_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Merge_Ats_Application_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Merge_Ats_Application_Append_Input = {
  field_mappings?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "merge_ats_application". All fields are combined with a logical 'AND'. */
export type Merge_Ats_Application_Bool_Exp = {
  _and?: InputMaybe<Array<Merge_Ats_Application_Bool_Exp>>;
  _not?: InputMaybe<Merge_Ats_Application_Bool_Exp>;
  _or?: InputMaybe<Array<Merge_Ats_Application_Bool_Exp>>;
  applied_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_stage?: InputMaybe<String_Comparison_Exp>;
  field_mappings?: InputMaybe<Jsonb_Comparison_Exp>;
  merge_application_id?: InputMaybe<String_Comparison_Exp>;
  merge_candidate_id?: InputMaybe<String_Comparison_Exp>;
  merge_job_id?: InputMaybe<String_Comparison_Exp>;
  modified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  rejected_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  remote_id?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "merge_ats_application" */
export enum Merge_Ats_Application_Constraint {
  /** unique or primary key constraint on columns "merge_application_id" */
  MergeAtsApplicationPkey = 'merge_ats_application_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Merge_Ats_Application_Delete_At_Path_Input = {
  field_mappings?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Merge_Ats_Application_Delete_Elem_Input = {
  field_mappings?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Merge_Ats_Application_Delete_Key_Input = {
  field_mappings?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "merge_ats_application" */
export type Merge_Ats_Application_Insert_Input = {
  applied_at?: InputMaybe<Scalars['timestamptz']>;
  current_stage?: InputMaybe<Scalars['String']>;
  field_mappings?: InputMaybe<Scalars['jsonb']>;
  merge_application_id?: InputMaybe<Scalars['String']>;
  merge_candidate_id?: InputMaybe<Scalars['String']>;
  merge_job_id?: InputMaybe<Scalars['String']>;
  modified_at?: InputMaybe<Scalars['timestamptz']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  remote_id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

/** Auto generated log entries using trigger on merge_ats_application update */
export type Merge_Ats_Application_Log = {
  __typename?: 'merge_ats_application_log';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  merge_ats_application_id: Scalars['String'];
  status: Scalars['String'];
};

/** aggregated selection of "merge_ats_application_log" */
export type Merge_Ats_Application_Log_Aggregate = {
  __typename?: 'merge_ats_application_log_aggregate';
  aggregate: Maybe<Merge_Ats_Application_Log_Aggregate_Fields>;
  nodes: Array<Merge_Ats_Application_Log>;
};

/** aggregate fields of "merge_ats_application_log" */
export type Merge_Ats_Application_Log_Aggregate_Fields = {
  __typename?: 'merge_ats_application_log_aggregate_fields';
  avg: Maybe<Merge_Ats_Application_Log_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Merge_Ats_Application_Log_Max_Fields>;
  min: Maybe<Merge_Ats_Application_Log_Min_Fields>;
  stddev: Maybe<Merge_Ats_Application_Log_Stddev_Fields>;
  stddev_pop: Maybe<Merge_Ats_Application_Log_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Merge_Ats_Application_Log_Stddev_Samp_Fields>;
  sum: Maybe<Merge_Ats_Application_Log_Sum_Fields>;
  var_pop: Maybe<Merge_Ats_Application_Log_Var_Pop_Fields>;
  var_samp: Maybe<Merge_Ats_Application_Log_Var_Samp_Fields>;
  variance: Maybe<Merge_Ats_Application_Log_Variance_Fields>;
};

/** aggregate fields of "merge_ats_application_log" */
export type Merge_Ats_Application_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Merge_Ats_Application_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Merge_Ats_Application_Log_Avg_Fields = {
  __typename?: 'merge_ats_application_log_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "merge_ats_application_log". All fields are combined with a logical 'AND'. */
export type Merge_Ats_Application_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Merge_Ats_Application_Log_Bool_Exp>>;
  _not?: InputMaybe<Merge_Ats_Application_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Merge_Ats_Application_Log_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  merge_ats_application_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "merge_ats_application_log" */
export enum Merge_Ats_Application_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  MergeAtsApplicationLogPkey = 'merge_ats_application_log_pkey',
}

/** input type for incrementing numeric columns in table "merge_ats_application_log" */
export type Merge_Ats_Application_Log_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "merge_ats_application_log" */
export type Merge_Ats_Application_Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  merge_ats_application_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Merge_Ats_Application_Log_Max_Fields = {
  __typename?: 'merge_ats_application_log_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  merge_ats_application_id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Merge_Ats_Application_Log_Min_Fields = {
  __typename?: 'merge_ats_application_log_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  merge_ats_application_id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "merge_ats_application_log" */
export type Merge_Ats_Application_Log_Mutation_Response = {
  __typename?: 'merge_ats_application_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Merge_Ats_Application_Log>;
};

/** on_conflict condition type for table "merge_ats_application_log" */
export type Merge_Ats_Application_Log_On_Conflict = {
  constraint: Merge_Ats_Application_Log_Constraint;
  update_columns?: Array<Merge_Ats_Application_Log_Update_Column>;
  where?: InputMaybe<Merge_Ats_Application_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "merge_ats_application_log". */
export type Merge_Ats_Application_Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  merge_ats_application_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: merge_ats_application_log */
export type Merge_Ats_Application_Log_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "merge_ats_application_log" */
export enum Merge_Ats_Application_Log_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MergeAtsApplicationId = 'merge_ats_application_id',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "merge_ats_application_log" */
export type Merge_Ats_Application_Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  merge_ats_application_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Merge_Ats_Application_Log_Stddev_Fields = {
  __typename?: 'merge_ats_application_log_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Merge_Ats_Application_Log_Stddev_Pop_Fields = {
  __typename?: 'merge_ats_application_log_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Merge_Ats_Application_Log_Stddev_Samp_Fields = {
  __typename?: 'merge_ats_application_log_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "merge_ats_application_log" */
export type Merge_Ats_Application_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Merge_Ats_Application_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Merge_Ats_Application_Log_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  merge_ats_application_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Merge_Ats_Application_Log_Sum_Fields = {
  __typename?: 'merge_ats_application_log_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "merge_ats_application_log" */
export enum Merge_Ats_Application_Log_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MergeAtsApplicationId = 'merge_ats_application_id',
  /** column name */
  Status = 'status',
}

export type Merge_Ats_Application_Log_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Merge_Ats_Application_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Merge_Ats_Application_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Merge_Ats_Application_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Merge_Ats_Application_Log_Var_Pop_Fields = {
  __typename?: 'merge_ats_application_log_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Merge_Ats_Application_Log_Var_Samp_Fields = {
  __typename?: 'merge_ats_application_log_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Merge_Ats_Application_Log_Variance_Fields = {
  __typename?: 'merge_ats_application_log_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Merge_Ats_Application_Max_Fields = {
  __typename?: 'merge_ats_application_max_fields';
  applied_at: Maybe<Scalars['timestamptz']>;
  current_stage: Maybe<Scalars['String']>;
  merge_application_id: Maybe<Scalars['String']>;
  merge_candidate_id: Maybe<Scalars['String']>;
  merge_job_id: Maybe<Scalars['String']>;
  modified_at: Maybe<Scalars['timestamptz']>;
  rejected_at: Maybe<Scalars['timestamptz']>;
  remote_id: Maybe<Scalars['String']>;
  source: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Merge_Ats_Application_Min_Fields = {
  __typename?: 'merge_ats_application_min_fields';
  applied_at: Maybe<Scalars['timestamptz']>;
  current_stage: Maybe<Scalars['String']>;
  merge_application_id: Maybe<Scalars['String']>;
  merge_candidate_id: Maybe<Scalars['String']>;
  merge_job_id: Maybe<Scalars['String']>;
  modified_at: Maybe<Scalars['timestamptz']>;
  rejected_at: Maybe<Scalars['timestamptz']>;
  remote_id: Maybe<Scalars['String']>;
  source: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "merge_ats_application" */
export type Merge_Ats_Application_Mutation_Response = {
  __typename?: 'merge_ats_application_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Merge_Ats_Application>;
};

/** on_conflict condition type for table "merge_ats_application" */
export type Merge_Ats_Application_On_Conflict = {
  constraint: Merge_Ats_Application_Constraint;
  update_columns?: Array<Merge_Ats_Application_Update_Column>;
  where?: InputMaybe<Merge_Ats_Application_Bool_Exp>;
};

/** Ordering options when selecting data from "merge_ats_application". */
export type Merge_Ats_Application_Order_By = {
  applied_at?: InputMaybe<Order_By>;
  current_stage?: InputMaybe<Order_By>;
  field_mappings?: InputMaybe<Order_By>;
  merge_application_id?: InputMaybe<Order_By>;
  merge_candidate_id?: InputMaybe<Order_By>;
  merge_job_id?: InputMaybe<Order_By>;
  modified_at?: InputMaybe<Order_By>;
  rejected_at?: InputMaybe<Order_By>;
  remote_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
};

/** primary key columns input for table: merge_ats_application */
export type Merge_Ats_Application_Pk_Columns_Input = {
  merge_application_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Merge_Ats_Application_Prepend_Input = {
  field_mappings?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "merge_ats_application" */
export enum Merge_Ats_Application_Select_Column {
  /** column name */
  AppliedAt = 'applied_at',
  /** column name */
  CurrentStage = 'current_stage',
  /** column name */
  FieldMappings = 'field_mappings',
  /** column name */
  MergeApplicationId = 'merge_application_id',
  /** column name */
  MergeCandidateId = 'merge_candidate_id',
  /** column name */
  MergeJobId = 'merge_job_id',
  /** column name */
  ModifiedAt = 'modified_at',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  Source = 'source',
}

/** input type for updating data in table "merge_ats_application" */
export type Merge_Ats_Application_Set_Input = {
  applied_at?: InputMaybe<Scalars['timestamptz']>;
  current_stage?: InputMaybe<Scalars['String']>;
  field_mappings?: InputMaybe<Scalars['jsonb']>;
  merge_application_id?: InputMaybe<Scalars['String']>;
  merge_candidate_id?: InputMaybe<Scalars['String']>;
  merge_job_id?: InputMaybe<Scalars['String']>;
  modified_at?: InputMaybe<Scalars['timestamptz']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  remote_id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "merge_ats_application" */
export type Merge_Ats_Application_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Merge_Ats_Application_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Merge_Ats_Application_Stream_Cursor_Value_Input = {
  applied_at?: InputMaybe<Scalars['timestamptz']>;
  current_stage?: InputMaybe<Scalars['String']>;
  field_mappings?: InputMaybe<Scalars['jsonb']>;
  merge_application_id?: InputMaybe<Scalars['String']>;
  merge_candidate_id?: InputMaybe<Scalars['String']>;
  merge_job_id?: InputMaybe<Scalars['String']>;
  modified_at?: InputMaybe<Scalars['timestamptz']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  remote_id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

/** update columns of table "merge_ats_application" */
export enum Merge_Ats_Application_Update_Column {
  /** column name */
  AppliedAt = 'applied_at',
  /** column name */
  CurrentStage = 'current_stage',
  /** column name */
  FieldMappings = 'field_mappings',
  /** column name */
  MergeApplicationId = 'merge_application_id',
  /** column name */
  MergeCandidateId = 'merge_candidate_id',
  /** column name */
  MergeJobId = 'merge_job_id',
  /** column name */
  ModifiedAt = 'modified_at',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  Source = 'source',
}

export type Merge_Ats_Application_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Merge_Ats_Application_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Merge_Ats_Application_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Merge_Ats_Application_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Merge_Ats_Application_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Merge_Ats_Application_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Merge_Ats_Application_Set_Input>;
  /** filter the rows which have to be updated */
  where: Merge_Ats_Application_Bool_Exp;
};

/** Candidate copy of that reflects data in merge ats. Primarily used for terminal candidate to merge candidate. */
export type Merge_Ats_Candidate = {
  __typename?: 'merge_ats_candidate';
  email_addresses: Scalars['jsonb'];
  first_name: Maybe<Scalars['String']>;
  last_name: Maybe<Scalars['String']>;
  locations: Maybe<Scalars['jsonb']>;
  merge_candidate_id: Scalars['String'];
  remote_id: Maybe<Scalars['String']>;
  urls: Maybe<Scalars['jsonb']>;
};

/** Candidate copy of that reflects data in merge ats. Primarily used for terminal candidate to merge candidate. */
export type Merge_Ats_CandidateEmail_AddressesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Candidate copy of that reflects data in merge ats. Primarily used for terminal candidate to merge candidate. */
export type Merge_Ats_CandidateLocationsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Candidate copy of that reflects data in merge ats. Primarily used for terminal candidate to merge candidate. */
export type Merge_Ats_CandidateUrlsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "merge_ats_candidate" */
export type Merge_Ats_Candidate_Aggregate = {
  __typename?: 'merge_ats_candidate_aggregate';
  aggregate: Maybe<Merge_Ats_Candidate_Aggregate_Fields>;
  nodes: Array<Merge_Ats_Candidate>;
};

/** aggregate fields of "merge_ats_candidate" */
export type Merge_Ats_Candidate_Aggregate_Fields = {
  __typename?: 'merge_ats_candidate_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Merge_Ats_Candidate_Max_Fields>;
  min: Maybe<Merge_Ats_Candidate_Min_Fields>;
};

/** aggregate fields of "merge_ats_candidate" */
export type Merge_Ats_Candidate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Merge_Ats_Candidate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Merge_Ats_Candidate_Append_Input = {
  email_addresses?: InputMaybe<Scalars['jsonb']>;
  locations?: InputMaybe<Scalars['jsonb']>;
  urls?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "merge_ats_candidate". All fields are combined with a logical 'AND'. */
export type Merge_Ats_Candidate_Bool_Exp = {
  _and?: InputMaybe<Array<Merge_Ats_Candidate_Bool_Exp>>;
  _not?: InputMaybe<Merge_Ats_Candidate_Bool_Exp>;
  _or?: InputMaybe<Array<Merge_Ats_Candidate_Bool_Exp>>;
  email_addresses?: InputMaybe<Jsonb_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  locations?: InputMaybe<Jsonb_Comparison_Exp>;
  merge_candidate_id?: InputMaybe<String_Comparison_Exp>;
  remote_id?: InputMaybe<String_Comparison_Exp>;
  urls?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "merge_ats_candidate" */
export enum Merge_Ats_Candidate_Constraint {
  /** unique or primary key constraint on columns "merge_candidate_id" */
  MergeAtsCandidatePkey = 'merge_ats_candidate_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Merge_Ats_Candidate_Delete_At_Path_Input = {
  email_addresses?: InputMaybe<Array<Scalars['String']>>;
  locations?: InputMaybe<Array<Scalars['String']>>;
  urls?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Merge_Ats_Candidate_Delete_Elem_Input = {
  email_addresses?: InputMaybe<Scalars['Int']>;
  locations?: InputMaybe<Scalars['Int']>;
  urls?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Merge_Ats_Candidate_Delete_Key_Input = {
  email_addresses?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Scalars['String']>;
  urls?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "merge_ats_candidate" */
export type Merge_Ats_Candidate_Insert_Input = {
  email_addresses?: InputMaybe<Scalars['jsonb']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Scalars['jsonb']>;
  merge_candidate_id?: InputMaybe<Scalars['String']>;
  remote_id?: InputMaybe<Scalars['String']>;
  urls?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Merge_Ats_Candidate_Max_Fields = {
  __typename?: 'merge_ats_candidate_max_fields';
  first_name: Maybe<Scalars['String']>;
  last_name: Maybe<Scalars['String']>;
  merge_candidate_id: Maybe<Scalars['String']>;
  remote_id: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Merge_Ats_Candidate_Min_Fields = {
  __typename?: 'merge_ats_candidate_min_fields';
  first_name: Maybe<Scalars['String']>;
  last_name: Maybe<Scalars['String']>;
  merge_candidate_id: Maybe<Scalars['String']>;
  remote_id: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "merge_ats_candidate" */
export type Merge_Ats_Candidate_Mutation_Response = {
  __typename?: 'merge_ats_candidate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Merge_Ats_Candidate>;
};

/** on_conflict condition type for table "merge_ats_candidate" */
export type Merge_Ats_Candidate_On_Conflict = {
  constraint: Merge_Ats_Candidate_Constraint;
  update_columns?: Array<Merge_Ats_Candidate_Update_Column>;
  where?: InputMaybe<Merge_Ats_Candidate_Bool_Exp>;
};

/** Ordering options when selecting data from "merge_ats_candidate". */
export type Merge_Ats_Candidate_Order_By = {
  email_addresses?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  locations?: InputMaybe<Order_By>;
  merge_candidate_id?: InputMaybe<Order_By>;
  remote_id?: InputMaybe<Order_By>;
  urls?: InputMaybe<Order_By>;
};

/** primary key columns input for table: merge_ats_candidate */
export type Merge_Ats_Candidate_Pk_Columns_Input = {
  merge_candidate_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Merge_Ats_Candidate_Prepend_Input = {
  email_addresses?: InputMaybe<Scalars['jsonb']>;
  locations?: InputMaybe<Scalars['jsonb']>;
  urls?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "merge_ats_candidate" */
export enum Merge_Ats_Candidate_Select_Column {
  /** column name */
  EmailAddresses = 'email_addresses',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Locations = 'locations',
  /** column name */
  MergeCandidateId = 'merge_candidate_id',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  Urls = 'urls',
}

/** input type for updating data in table "merge_ats_candidate" */
export type Merge_Ats_Candidate_Set_Input = {
  email_addresses?: InputMaybe<Scalars['jsonb']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Scalars['jsonb']>;
  merge_candidate_id?: InputMaybe<Scalars['String']>;
  remote_id?: InputMaybe<Scalars['String']>;
  urls?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "merge_ats_candidate" */
export type Merge_Ats_Candidate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Merge_Ats_Candidate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Merge_Ats_Candidate_Stream_Cursor_Value_Input = {
  email_addresses?: InputMaybe<Scalars['jsonb']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Scalars['jsonb']>;
  merge_candidate_id?: InputMaybe<Scalars['String']>;
  remote_id?: InputMaybe<Scalars['String']>;
  urls?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "merge_ats_candidate" */
export enum Merge_Ats_Candidate_Update_Column {
  /** column name */
  EmailAddresses = 'email_addresses',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Locations = 'locations',
  /** column name */
  MergeCandidateId = 'merge_candidate_id',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  Urls = 'urls',
}

export type Merge_Ats_Candidate_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Merge_Ats_Candidate_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Merge_Ats_Candidate_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Merge_Ats_Candidate_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Merge_Ats_Candidate_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Merge_Ats_Candidate_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Merge_Ats_Candidate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Merge_Ats_Candidate_Bool_Exp;
};

/** Job data representing merge job. */
export type Merge_Ats_Job = {
  __typename?: 'merge_ats_job';
  description: Maybe<Scalars['String']>;
  /** An object relationship */
  job: Maybe<Job>;
  job_id: Maybe<Scalars['Int']>;
  linked_account_id: Maybe<Scalars['String']>;
  /** An object relationship */
  merge_ats_linked_account: Maybe<Merge_Ats_Linked_Account>;
  merge_job_id: Scalars['String'];
  name: Scalars['String'];
  remote_id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
};

/** aggregated selection of "merge_ats_job" */
export type Merge_Ats_Job_Aggregate = {
  __typename?: 'merge_ats_job_aggregate';
  aggregate: Maybe<Merge_Ats_Job_Aggregate_Fields>;
  nodes: Array<Merge_Ats_Job>;
};

/** aggregate fields of "merge_ats_job" */
export type Merge_Ats_Job_Aggregate_Fields = {
  __typename?: 'merge_ats_job_aggregate_fields';
  avg: Maybe<Merge_Ats_Job_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Merge_Ats_Job_Max_Fields>;
  min: Maybe<Merge_Ats_Job_Min_Fields>;
  stddev: Maybe<Merge_Ats_Job_Stddev_Fields>;
  stddev_pop: Maybe<Merge_Ats_Job_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Merge_Ats_Job_Stddev_Samp_Fields>;
  sum: Maybe<Merge_Ats_Job_Sum_Fields>;
  var_pop: Maybe<Merge_Ats_Job_Var_Pop_Fields>;
  var_samp: Maybe<Merge_Ats_Job_Var_Samp_Fields>;
  variance: Maybe<Merge_Ats_Job_Variance_Fields>;
};

/** aggregate fields of "merge_ats_job" */
export type Merge_Ats_Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Merge_Ats_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Merge_Ats_Job_Avg_Fields = {
  __typename?: 'merge_ats_job_avg_fields';
  job_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "merge_ats_job". All fields are combined with a logical 'AND'. */
export type Merge_Ats_Job_Bool_Exp = {
  _and?: InputMaybe<Array<Merge_Ats_Job_Bool_Exp>>;
  _not?: InputMaybe<Merge_Ats_Job_Bool_Exp>;
  _or?: InputMaybe<Array<Merge_Ats_Job_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  linked_account_id?: InputMaybe<String_Comparison_Exp>;
  merge_ats_linked_account?: InputMaybe<Merge_Ats_Linked_Account_Bool_Exp>;
  merge_job_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  remote_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "merge_ats_job" */
export enum Merge_Ats_Job_Constraint {
  /** unique or primary key constraint on columns "merge_job_id" */
  MergeAtsJobPkey = 'merge_ats_job_pkey',
}

/** input type for incrementing numeric columns in table "merge_ats_job" */
export type Merge_Ats_Job_Inc_Input = {
  job_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "merge_ats_job" */
export type Merge_Ats_Job_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['Int']>;
  linked_account_id?: InputMaybe<Scalars['String']>;
  merge_ats_linked_account?: InputMaybe<Merge_Ats_Linked_Account_Obj_Rel_Insert_Input>;
  merge_job_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  remote_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Interview Stages for merge job. Each job has unique stage ids from merge. */
export type Merge_Ats_Job_Interview_Stage = {
  __typename?: 'merge_ats_job_interview_stage';
  field_mappings: Maybe<Scalars['jsonb']>;
  merge_interview_stage_id: Scalars['String'];
  merge_job_id: Scalars['String'];
  name: Scalars['String'];
  remote_id: Scalars['String'];
  stage_order: Scalars['Int'];
};

/** Interview Stages for merge job. Each job has unique stage ids from merge. */
export type Merge_Ats_Job_Interview_StageField_MappingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "merge_ats_job_interview_stage" */
export type Merge_Ats_Job_Interview_Stage_Aggregate = {
  __typename?: 'merge_ats_job_interview_stage_aggregate';
  aggregate: Maybe<Merge_Ats_Job_Interview_Stage_Aggregate_Fields>;
  nodes: Array<Merge_Ats_Job_Interview_Stage>;
};

/** aggregate fields of "merge_ats_job_interview_stage" */
export type Merge_Ats_Job_Interview_Stage_Aggregate_Fields = {
  __typename?: 'merge_ats_job_interview_stage_aggregate_fields';
  avg: Maybe<Merge_Ats_Job_Interview_Stage_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Merge_Ats_Job_Interview_Stage_Max_Fields>;
  min: Maybe<Merge_Ats_Job_Interview_Stage_Min_Fields>;
  stddev: Maybe<Merge_Ats_Job_Interview_Stage_Stddev_Fields>;
  stddev_pop: Maybe<Merge_Ats_Job_Interview_Stage_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Merge_Ats_Job_Interview_Stage_Stddev_Samp_Fields>;
  sum: Maybe<Merge_Ats_Job_Interview_Stage_Sum_Fields>;
  var_pop: Maybe<Merge_Ats_Job_Interview_Stage_Var_Pop_Fields>;
  var_samp: Maybe<Merge_Ats_Job_Interview_Stage_Var_Samp_Fields>;
  variance: Maybe<Merge_Ats_Job_Interview_Stage_Variance_Fields>;
};

/** aggregate fields of "merge_ats_job_interview_stage" */
export type Merge_Ats_Job_Interview_Stage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Merge_Ats_Job_Interview_Stage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Merge_Ats_Job_Interview_Stage_Append_Input = {
  field_mappings?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Merge_Ats_Job_Interview_Stage_Avg_Fields = {
  __typename?: 'merge_ats_job_interview_stage_avg_fields';
  stage_order: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "merge_ats_job_interview_stage". All fields are combined with a logical 'AND'. */
export type Merge_Ats_Job_Interview_Stage_Bool_Exp = {
  _and?: InputMaybe<Array<Merge_Ats_Job_Interview_Stage_Bool_Exp>>;
  _not?: InputMaybe<Merge_Ats_Job_Interview_Stage_Bool_Exp>;
  _or?: InputMaybe<Array<Merge_Ats_Job_Interview_Stage_Bool_Exp>>;
  field_mappings?: InputMaybe<Jsonb_Comparison_Exp>;
  merge_interview_stage_id?: InputMaybe<String_Comparison_Exp>;
  merge_job_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  remote_id?: InputMaybe<String_Comparison_Exp>;
  stage_order?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "merge_ats_job_interview_stage" */
export enum Merge_Ats_Job_Interview_Stage_Constraint {
  /** unique or primary key constraint on columns "merge_interview_stage_id" */
  MergeAtsJobInterviewStagePkey = 'merge_ats_job_interview_stage_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Merge_Ats_Job_Interview_Stage_Delete_At_Path_Input = {
  field_mappings?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Merge_Ats_Job_Interview_Stage_Delete_Elem_Input = {
  field_mappings?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Merge_Ats_Job_Interview_Stage_Delete_Key_Input = {
  field_mappings?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "merge_ats_job_interview_stage" */
export type Merge_Ats_Job_Interview_Stage_Inc_Input = {
  stage_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "merge_ats_job_interview_stage" */
export type Merge_Ats_Job_Interview_Stage_Insert_Input = {
  field_mappings?: InputMaybe<Scalars['jsonb']>;
  merge_interview_stage_id?: InputMaybe<Scalars['String']>;
  merge_job_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  remote_id?: InputMaybe<Scalars['String']>;
  stage_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Merge_Ats_Job_Interview_Stage_Max_Fields = {
  __typename?: 'merge_ats_job_interview_stage_max_fields';
  merge_interview_stage_id: Maybe<Scalars['String']>;
  merge_job_id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  remote_id: Maybe<Scalars['String']>;
  stage_order: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Merge_Ats_Job_Interview_Stage_Min_Fields = {
  __typename?: 'merge_ats_job_interview_stage_min_fields';
  merge_interview_stage_id: Maybe<Scalars['String']>;
  merge_job_id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  remote_id: Maybe<Scalars['String']>;
  stage_order: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "merge_ats_job_interview_stage" */
export type Merge_Ats_Job_Interview_Stage_Mutation_Response = {
  __typename?: 'merge_ats_job_interview_stage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Merge_Ats_Job_Interview_Stage>;
};

/** on_conflict condition type for table "merge_ats_job_interview_stage" */
export type Merge_Ats_Job_Interview_Stage_On_Conflict = {
  constraint: Merge_Ats_Job_Interview_Stage_Constraint;
  update_columns?: Array<Merge_Ats_Job_Interview_Stage_Update_Column>;
  where?: InputMaybe<Merge_Ats_Job_Interview_Stage_Bool_Exp>;
};

/** Ordering options when selecting data from "merge_ats_job_interview_stage". */
export type Merge_Ats_Job_Interview_Stage_Order_By = {
  field_mappings?: InputMaybe<Order_By>;
  merge_interview_stage_id?: InputMaybe<Order_By>;
  merge_job_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remote_id?: InputMaybe<Order_By>;
  stage_order?: InputMaybe<Order_By>;
};

/** primary key columns input for table: merge_ats_job_interview_stage */
export type Merge_Ats_Job_Interview_Stage_Pk_Columns_Input = {
  merge_interview_stage_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Merge_Ats_Job_Interview_Stage_Prepend_Input = {
  field_mappings?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "merge_ats_job_interview_stage" */
export enum Merge_Ats_Job_Interview_Stage_Select_Column {
  /** column name */
  FieldMappings = 'field_mappings',
  /** column name */
  MergeInterviewStageId = 'merge_interview_stage_id',
  /** column name */
  MergeJobId = 'merge_job_id',
  /** column name */
  Name = 'name',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  StageOrder = 'stage_order',
}

/** input type for updating data in table "merge_ats_job_interview_stage" */
export type Merge_Ats_Job_Interview_Stage_Set_Input = {
  field_mappings?: InputMaybe<Scalars['jsonb']>;
  merge_interview_stage_id?: InputMaybe<Scalars['String']>;
  merge_job_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  remote_id?: InputMaybe<Scalars['String']>;
  stage_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Merge_Ats_Job_Interview_Stage_Stddev_Fields = {
  __typename?: 'merge_ats_job_interview_stage_stddev_fields';
  stage_order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Merge_Ats_Job_Interview_Stage_Stddev_Pop_Fields = {
  __typename?: 'merge_ats_job_interview_stage_stddev_pop_fields';
  stage_order: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Merge_Ats_Job_Interview_Stage_Stddev_Samp_Fields = {
  __typename?: 'merge_ats_job_interview_stage_stddev_samp_fields';
  stage_order: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "merge_ats_job_interview_stage" */
export type Merge_Ats_Job_Interview_Stage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Merge_Ats_Job_Interview_Stage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Merge_Ats_Job_Interview_Stage_Stream_Cursor_Value_Input = {
  field_mappings?: InputMaybe<Scalars['jsonb']>;
  merge_interview_stage_id?: InputMaybe<Scalars['String']>;
  merge_job_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  remote_id?: InputMaybe<Scalars['String']>;
  stage_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Merge_Ats_Job_Interview_Stage_Sum_Fields = {
  __typename?: 'merge_ats_job_interview_stage_sum_fields';
  stage_order: Maybe<Scalars['Int']>;
};

/** update columns of table "merge_ats_job_interview_stage" */
export enum Merge_Ats_Job_Interview_Stage_Update_Column {
  /** column name */
  FieldMappings = 'field_mappings',
  /** column name */
  MergeInterviewStageId = 'merge_interview_stage_id',
  /** column name */
  MergeJobId = 'merge_job_id',
  /** column name */
  Name = 'name',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  StageOrder = 'stage_order',
}

export type Merge_Ats_Job_Interview_Stage_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Merge_Ats_Job_Interview_Stage_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Merge_Ats_Job_Interview_Stage_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Merge_Ats_Job_Interview_Stage_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Merge_Ats_Job_Interview_Stage_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Merge_Ats_Job_Interview_Stage_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Merge_Ats_Job_Interview_Stage_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Merge_Ats_Job_Interview_Stage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Merge_Ats_Job_Interview_Stage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Merge_Ats_Job_Interview_Stage_Var_Pop_Fields = {
  __typename?: 'merge_ats_job_interview_stage_var_pop_fields';
  stage_order: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Merge_Ats_Job_Interview_Stage_Var_Samp_Fields = {
  __typename?: 'merge_ats_job_interview_stage_var_samp_fields';
  stage_order: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Merge_Ats_Job_Interview_Stage_Variance_Fields = {
  __typename?: 'merge_ats_job_interview_stage_variance_fields';
  stage_order: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Merge_Ats_Job_Max_Fields = {
  __typename?: 'merge_ats_job_max_fields';
  description: Maybe<Scalars['String']>;
  job_id: Maybe<Scalars['Int']>;
  linked_account_id: Maybe<Scalars['String']>;
  merge_job_id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  remote_id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Merge_Ats_Job_Min_Fields = {
  __typename?: 'merge_ats_job_min_fields';
  description: Maybe<Scalars['String']>;
  job_id: Maybe<Scalars['Int']>;
  linked_account_id: Maybe<Scalars['String']>;
  merge_job_id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  remote_id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "merge_ats_job" */
export type Merge_Ats_Job_Mutation_Response = {
  __typename?: 'merge_ats_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Merge_Ats_Job>;
};

/** on_conflict condition type for table "merge_ats_job" */
export type Merge_Ats_Job_On_Conflict = {
  constraint: Merge_Ats_Job_Constraint;
  update_columns?: Array<Merge_Ats_Job_Update_Column>;
  where?: InputMaybe<Merge_Ats_Job_Bool_Exp>;
};

/** Ordering options when selecting data from "merge_ats_job". */
export type Merge_Ats_Job_Order_By = {
  description?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  linked_account_id?: InputMaybe<Order_By>;
  merge_ats_linked_account?: InputMaybe<Merge_Ats_Linked_Account_Order_By>;
  merge_job_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  remote_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: merge_ats_job */
export type Merge_Ats_Job_Pk_Columns_Input = {
  merge_job_id: Scalars['String'];
};

/** select columns of table "merge_ats_job" */
export enum Merge_Ats_Job_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LinkedAccountId = 'linked_account_id',
  /** column name */
  MergeJobId = 'merge_job_id',
  /** column name */
  Name = 'name',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "merge_ats_job" */
export type Merge_Ats_Job_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  job_id?: InputMaybe<Scalars['Int']>;
  linked_account_id?: InputMaybe<Scalars['String']>;
  merge_job_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  remote_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Merge_Ats_Job_Stddev_Fields = {
  __typename?: 'merge_ats_job_stddev_fields';
  job_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Merge_Ats_Job_Stddev_Pop_Fields = {
  __typename?: 'merge_ats_job_stddev_pop_fields';
  job_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Merge_Ats_Job_Stddev_Samp_Fields = {
  __typename?: 'merge_ats_job_stddev_samp_fields';
  job_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "merge_ats_job" */
export type Merge_Ats_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Merge_Ats_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Merge_Ats_Job_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  job_id?: InputMaybe<Scalars['Int']>;
  linked_account_id?: InputMaybe<Scalars['String']>;
  merge_job_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  remote_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Merge_Ats_Job_Sum_Fields = {
  __typename?: 'merge_ats_job_sum_fields';
  job_id: Maybe<Scalars['Int']>;
};

/** update columns of table "merge_ats_job" */
export enum Merge_Ats_Job_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LinkedAccountId = 'linked_account_id',
  /** column name */
  MergeJobId = 'merge_job_id',
  /** column name */
  Name = 'name',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  Status = 'status',
}

export type Merge_Ats_Job_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Merge_Ats_Job_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Merge_Ats_Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Merge_Ats_Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Merge_Ats_Job_Var_Pop_Fields = {
  __typename?: 'merge_ats_job_var_pop_fields';
  job_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Merge_Ats_Job_Var_Samp_Fields = {
  __typename?: 'merge_ats_job_var_samp_fields';
  job_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Merge_Ats_Job_Variance_Fields = {
  __typename?: 'merge_ats_job_variance_fields';
  job_id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "merge_ats_linked_account" */
export type Merge_Ats_Linked_Account = {
  __typename?: 'merge_ats_linked_account';
  end_user_email_address: Maybe<Scalars['citext']>;
  end_user_organization_name: Maybe<Scalars['String']>;
  end_user_origin_id: Maybe<Scalars['String']>;
  id: Scalars['String'];
  integration: Scalars['String'];
};

/** aggregated selection of "merge_ats_linked_account" */
export type Merge_Ats_Linked_Account_Aggregate = {
  __typename?: 'merge_ats_linked_account_aggregate';
  aggregate: Maybe<Merge_Ats_Linked_Account_Aggregate_Fields>;
  nodes: Array<Merge_Ats_Linked_Account>;
};

/** aggregate fields of "merge_ats_linked_account" */
export type Merge_Ats_Linked_Account_Aggregate_Fields = {
  __typename?: 'merge_ats_linked_account_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Merge_Ats_Linked_Account_Max_Fields>;
  min: Maybe<Merge_Ats_Linked_Account_Min_Fields>;
};

/** aggregate fields of "merge_ats_linked_account" */
export type Merge_Ats_Linked_Account_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Merge_Ats_Linked_Account_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "merge_ats_linked_account". All fields are combined with a logical 'AND'. */
export type Merge_Ats_Linked_Account_Bool_Exp = {
  _and?: InputMaybe<Array<Merge_Ats_Linked_Account_Bool_Exp>>;
  _not?: InputMaybe<Merge_Ats_Linked_Account_Bool_Exp>;
  _or?: InputMaybe<Array<Merge_Ats_Linked_Account_Bool_Exp>>;
  end_user_email_address?: InputMaybe<Citext_Comparison_Exp>;
  end_user_organization_name?: InputMaybe<String_Comparison_Exp>;
  end_user_origin_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  integration?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "merge_ats_linked_account" */
export enum Merge_Ats_Linked_Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  MergeAtsLinkedAccountPkey = 'merge_ats_linked_account_pkey',
}

/** input type for inserting data into table "merge_ats_linked_account" */
export type Merge_Ats_Linked_Account_Insert_Input = {
  end_user_email_address?: InputMaybe<Scalars['citext']>;
  end_user_organization_name?: InputMaybe<Scalars['String']>;
  end_user_origin_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  integration?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Merge_Ats_Linked_Account_Max_Fields = {
  __typename?: 'merge_ats_linked_account_max_fields';
  end_user_email_address: Maybe<Scalars['citext']>;
  end_user_organization_name: Maybe<Scalars['String']>;
  end_user_origin_id: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  integration: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Merge_Ats_Linked_Account_Min_Fields = {
  __typename?: 'merge_ats_linked_account_min_fields';
  end_user_email_address: Maybe<Scalars['citext']>;
  end_user_organization_name: Maybe<Scalars['String']>;
  end_user_origin_id: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  integration: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "merge_ats_linked_account" */
export type Merge_Ats_Linked_Account_Mutation_Response = {
  __typename?: 'merge_ats_linked_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Merge_Ats_Linked_Account>;
};

/** input type for inserting object relation for remote table "merge_ats_linked_account" */
export type Merge_Ats_Linked_Account_Obj_Rel_Insert_Input = {
  data: Merge_Ats_Linked_Account_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Merge_Ats_Linked_Account_On_Conflict>;
};

/** on_conflict condition type for table "merge_ats_linked_account" */
export type Merge_Ats_Linked_Account_On_Conflict = {
  constraint: Merge_Ats_Linked_Account_Constraint;
  update_columns?: Array<Merge_Ats_Linked_Account_Update_Column>;
  where?: InputMaybe<Merge_Ats_Linked_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "merge_ats_linked_account". */
export type Merge_Ats_Linked_Account_Order_By = {
  end_user_email_address?: InputMaybe<Order_By>;
  end_user_organization_name?: InputMaybe<Order_By>;
  end_user_origin_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration?: InputMaybe<Order_By>;
};

/** primary key columns input for table: merge_ats_linked_account */
export type Merge_Ats_Linked_Account_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "merge_ats_linked_account" */
export enum Merge_Ats_Linked_Account_Select_Column {
  /** column name */
  EndUserEmailAddress = 'end_user_email_address',
  /** column name */
  EndUserOrganizationName = 'end_user_organization_name',
  /** column name */
  EndUserOriginId = 'end_user_origin_id',
  /** column name */
  Id = 'id',
  /** column name */
  Integration = 'integration',
}

/** input type for updating data in table "merge_ats_linked_account" */
export type Merge_Ats_Linked_Account_Set_Input = {
  end_user_email_address?: InputMaybe<Scalars['citext']>;
  end_user_organization_name?: InputMaybe<Scalars['String']>;
  end_user_origin_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  integration?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "merge_ats_linked_account" */
export type Merge_Ats_Linked_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Merge_Ats_Linked_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Merge_Ats_Linked_Account_Stream_Cursor_Value_Input = {
  end_user_email_address?: InputMaybe<Scalars['citext']>;
  end_user_organization_name?: InputMaybe<Scalars['String']>;
  end_user_origin_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  integration?: InputMaybe<Scalars['String']>;
};

/** update columns of table "merge_ats_linked_account" */
export enum Merge_Ats_Linked_Account_Update_Column {
  /** column name */
  EndUserEmailAddress = 'end_user_email_address',
  /** column name */
  EndUserOrganizationName = 'end_user_organization_name',
  /** column name */
  EndUserOriginId = 'end_user_origin_id',
  /** column name */
  Id = 'id',
  /** column name */
  Integration = 'integration',
}

export type Merge_Ats_Linked_Account_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Merge_Ats_Linked_Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Merge_Ats_Linked_Account_Bool_Exp;
};

/** columns and relationships of "mixpanel.duplicate_profiles" */
export type Mixpanel_Duplicate_Profiles = {
  __typename?: 'mixpanel_duplicate_profiles';
  distinct_ids: Maybe<Array<Scalars['String']>>;
  email: Maybe<Scalars['String']>;
  first_seen: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "mixpanel.duplicate_profiles" */
export type Mixpanel_Duplicate_Profiles_Aggregate = {
  __typename?: 'mixpanel_duplicate_profiles_aggregate';
  aggregate: Maybe<Mixpanel_Duplicate_Profiles_Aggregate_Fields>;
  nodes: Array<Mixpanel_Duplicate_Profiles>;
};

/** aggregate fields of "mixpanel.duplicate_profiles" */
export type Mixpanel_Duplicate_Profiles_Aggregate_Fields = {
  __typename?: 'mixpanel_duplicate_profiles_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Mixpanel_Duplicate_Profiles_Max_Fields>;
  min: Maybe<Mixpanel_Duplicate_Profiles_Min_Fields>;
};

/** aggregate fields of "mixpanel.duplicate_profiles" */
export type Mixpanel_Duplicate_Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mixpanel_Duplicate_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "mixpanel.duplicate_profiles". All fields are combined with a logical 'AND'. */
export type Mixpanel_Duplicate_Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Mixpanel_Duplicate_Profiles_Bool_Exp>>;
  _not?: InputMaybe<Mixpanel_Duplicate_Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Mixpanel_Duplicate_Profiles_Bool_Exp>>;
  distinct_ids?: InputMaybe<String_Array_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_seen?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Mixpanel_Duplicate_Profiles_Max_Fields = {
  __typename?: 'mixpanel_duplicate_profiles_max_fields';
  distinct_ids: Maybe<Array<Scalars['String']>>;
  email: Maybe<Scalars['String']>;
  first_seen: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Mixpanel_Duplicate_Profiles_Min_Fields = {
  __typename?: 'mixpanel_duplicate_profiles_min_fields';
  distinct_ids: Maybe<Array<Scalars['String']>>;
  email: Maybe<Scalars['String']>;
  first_seen: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "mixpanel.duplicate_profiles". */
export type Mixpanel_Duplicate_Profiles_Order_By = {
  distinct_ids?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_seen?: InputMaybe<Order_By>;
};

/** select columns of table "mixpanel.duplicate_profiles" */
export enum Mixpanel_Duplicate_Profiles_Select_Column {
  /** column name */
  DistinctIds = 'distinct_ids',
  /** column name */
  Email = 'email',
  /** column name */
  FirstSeen = 'first_seen',
}

/** Streaming cursor of the table "mixpanel_duplicate_profiles" */
export type Mixpanel_Duplicate_Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mixpanel_Duplicate_Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mixpanel_Duplicate_Profiles_Stream_Cursor_Value_Input = {
  distinct_ids?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  first_seen?: InputMaybe<Scalars['timestamp']>;
};

/** This table contains Mixpanel profiles which are used to deduplicate profiles in Mixpanel */
export type Mixpanel_Profiles = {
  __typename?: 'mixpanel_profiles';
  distinct_id: Scalars['String'];
  email: Scalars['String'];
  first_seen: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "mixpanel.profiles" */
export type Mixpanel_Profiles_Aggregate = {
  __typename?: 'mixpanel_profiles_aggregate';
  aggregate: Maybe<Mixpanel_Profiles_Aggregate_Fields>;
  nodes: Array<Mixpanel_Profiles>;
};

/** aggregate fields of "mixpanel.profiles" */
export type Mixpanel_Profiles_Aggregate_Fields = {
  __typename?: 'mixpanel_profiles_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Mixpanel_Profiles_Max_Fields>;
  min: Maybe<Mixpanel_Profiles_Min_Fields>;
};

/** aggregate fields of "mixpanel.profiles" */
export type Mixpanel_Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mixpanel_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "mixpanel.profiles". All fields are combined with a logical 'AND'. */
export type Mixpanel_Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Mixpanel_Profiles_Bool_Exp>>;
  _not?: InputMaybe<Mixpanel_Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Mixpanel_Profiles_Bool_Exp>>;
  distinct_id?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_seen?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "mixpanel.profiles" */
export enum Mixpanel_Profiles_Constraint {
  /** unique or primary key constraint on columns "distinct_id", "email" */
  ProfilesPkey = 'profiles_pkey',
}

/** input type for inserting data into table "mixpanel.profiles" */
export type Mixpanel_Profiles_Insert_Input = {
  distinct_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_seen?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Mixpanel_Profiles_Max_Fields = {
  __typename?: 'mixpanel_profiles_max_fields';
  distinct_id: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  first_seen: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Mixpanel_Profiles_Min_Fields = {
  __typename?: 'mixpanel_profiles_min_fields';
  distinct_id: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  first_seen: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "mixpanel.profiles" */
export type Mixpanel_Profiles_Mutation_Response = {
  __typename?: 'mixpanel_profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mixpanel_Profiles>;
};

/** on_conflict condition type for table "mixpanel.profiles" */
export type Mixpanel_Profiles_On_Conflict = {
  constraint: Mixpanel_Profiles_Constraint;
  update_columns?: Array<Mixpanel_Profiles_Update_Column>;
  where?: InputMaybe<Mixpanel_Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "mixpanel.profiles". */
export type Mixpanel_Profiles_Order_By = {
  distinct_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_seen?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mixpanel.profiles */
export type Mixpanel_Profiles_Pk_Columns_Input = {
  distinct_id: Scalars['String'];
  email: Scalars['String'];
};

/** select columns of table "mixpanel.profiles" */
export enum Mixpanel_Profiles_Select_Column {
  /** column name */
  DistinctId = 'distinct_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstSeen = 'first_seen',
}

/** input type for updating data in table "mixpanel.profiles" */
export type Mixpanel_Profiles_Set_Input = {
  distinct_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_seen?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "mixpanel_profiles" */
export type Mixpanel_Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mixpanel_Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mixpanel_Profiles_Stream_Cursor_Value_Input = {
  distinct_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_seen?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "mixpanel.profiles" */
export enum Mixpanel_Profiles_Update_Column {
  /** column name */
  DistinctId = 'distinct_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstSeen = 'first_seen',
}

export type Mixpanel_Profiles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mixpanel_Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mixpanel_Profiles_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "activity_customer_profile_view" */
  delete_activity_customer_profile_view: Maybe<Activity_Customer_Profile_View_Mutation_Response>;
  /** delete single row from the table: "activity_customer_profile_view" */
  delete_activity_customer_profile_view_by_pk: Maybe<Activity_Customer_Profile_View>;
  /** delete data from the table: "ai_level" */
  delete_ai_level: Maybe<Ai_Level_Mutation_Response>;
  /** delete single row from the table: "ai_level" */
  delete_ai_level_by_pk: Maybe<Ai_Level>;
  /** delete data from the table: "ai_role" */
  delete_ai_role: Maybe<Ai_Role_Mutation_Response>;
  /** delete single row from the table: "ai_role" */
  delete_ai_role_by_pk: Maybe<Ai_Role>;
  /** delete data from the table: "ai_role_multiplier_focus" */
  delete_ai_role_multiplier_focus: Maybe<Ai_Role_Multiplier_Focus_Mutation_Response>;
  /** delete single row from the table: "ai_role_multiplier_focus" */
  delete_ai_role_multiplier_focus_by_pk: Maybe<Ai_Role_Multiplier_Focus>;
  /** delete data from the table: "ai_run_dump" */
  delete_ai_run_dump: Maybe<Ai_Run_Dump_Mutation_Response>;
  /** delete single row from the table: "ai_run_dump" */
  delete_ai_run_dump_by_pk: Maybe<Ai_Run_Dump>;
  /** delete data from the table: "applicant_consent" */
  delete_applicant_consent: Maybe<Applicant_Consent_Mutation_Response>;
  /** delete single row from the table: "applicant_consent" */
  delete_applicant_consent_by_pk: Maybe<Applicant_Consent>;
  /** delete data from the table: "applicant_job" */
  delete_applicant_job: Maybe<Applicant_Job_Mutation_Response>;
  /** delete single row from the table: "applicant_job" */
  delete_applicant_job_by_pk: Maybe<Applicant_Job>;
  /** delete data from the table: "applicant_waitlist" */
  delete_applicant_waitlist: Maybe<Applicant_Waitlist_Mutation_Response>;
  /** delete single row from the table: "applicant_waitlist" */
  delete_applicant_waitlist_by_pk: Maybe<Applicant_Waitlist>;
  /** delete data from the table: "audit.logged_actions" */
  delete_audit_logged_actions: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** delete single row from the table: "audit.logged_actions" */
  delete_audit_logged_actions_by_pk: Maybe<Audit_Logged_Actions>;
  /** delete data from the table: "calendly_event" */
  delete_calendly_event: Maybe<Calendly_Event_Mutation_Response>;
  /** delete single row from the table: "calendly_event" */
  delete_calendly_event_by_pk: Maybe<Calendly_Event>;
  /** delete data from the table: "calendly_invitee" */
  delete_calendly_invitee: Maybe<Calendly_Invitee_Mutation_Response>;
  /** delete single row from the table: "calendly_invitee" */
  delete_calendly_invitee_by_pk: Maybe<Calendly_Invitee>;
  /** delete data from the table: "calendly_user" */
  delete_calendly_user: Maybe<Calendly_User_Mutation_Response>;
  /** delete single row from the table: "calendly_user" */
  delete_calendly_user_by_pk: Maybe<Calendly_User>;
  /** delete data from the table: "candidate" */
  delete_candidate: Maybe<Candidate_Mutation_Response>;
  /** delete data from the table: "candidate_ai_profile_summary" */
  delete_candidate_ai_profile_summary: Maybe<Candidate_Ai_Profile_Summary_Mutation_Response>;
  /** delete single row from the table: "candidate_ai_profile_summary" */
  delete_candidate_ai_profile_summary_by_pk: Maybe<Candidate_Ai_Profile_Summary>;
  /** delete data from the table: "candidate_applicant_workflow_stats_result" */
  delete_candidate_applicant_workflow_stats_result: Maybe<Candidate_Applicant_Workflow_Stats_Result_Mutation_Response>;
  /** delete data from the table: "candidate_approved_country" */
  delete_candidate_approved_country: Maybe<Candidate_Approved_Country_Mutation_Response>;
  /** delete single row from the table: "candidate_approved_country" */
  delete_candidate_approved_country_by_pk: Maybe<Candidate_Approved_Country>;
  /** delete data from the table: "candidate_availability_choices" */
  delete_candidate_availability_choices: Maybe<Candidate_Availability_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_availability_choices" */
  delete_candidate_availability_choices_by_pk: Maybe<Candidate_Availability_Choices>;
  /** delete data from the table: "candidate_availability_choices_order" */
  delete_candidate_availability_choices_order: Maybe<Candidate_Availability_Choices_Order_Mutation_Response>;
  /** delete single row from the table: "candidate_availability_choices_order" */
  delete_candidate_availability_choices_order_by_pk: Maybe<Candidate_Availability_Choices_Order>;
  /** delete data from the table: "candidate_badge" */
  delete_candidate_badge: Maybe<Candidate_Badge_Mutation_Response>;
  /** delete single row from the table: "candidate_badge" */
  delete_candidate_badge_by_pk: Maybe<Candidate_Badge>;
  /** delete data from the table: "candidate_browse_result" */
  delete_candidate_browse_result: Maybe<Candidate_Browse_Result_Mutation_Response>;
  /** delete single row from the table: "candidate_browse_result" */
  delete_candidate_browse_result_by_pk: Maybe<Candidate_Browse_Result>;
  /** delete single row from the table: "candidate" */
  delete_candidate_by_pk: Maybe<Candidate>;
  /** delete data from the table: "candidate_curation_ai_summary" */
  delete_candidate_curation_ai_summary: Maybe<Candidate_Curation_Ai_Summary_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_ai_summary" */
  delete_candidate_curation_ai_summary_by_pk: Maybe<Candidate_Curation_Ai_Summary>;
  /** delete data from the table: "candidate_curation_ai_summary_feedback" */
  delete_candidate_curation_ai_summary_feedback: Maybe<Candidate_Curation_Ai_Summary_Feedback_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_ai_summary_feedback" */
  delete_candidate_curation_ai_summary_feedback_by_pk: Maybe<Candidate_Curation_Ai_Summary_Feedback>;
  /** delete data from the table: "candidate_curation_country_choices" */
  delete_candidate_curation_country_choices: Maybe<Candidate_Curation_Country_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_country_choices" */
  delete_candidate_curation_country_choices_by_pk: Maybe<Candidate_Curation_Country_Choices>;
  /** delete data from the table: "candidate_curation_desired_salary_currency_choices" */
  delete_candidate_curation_desired_salary_currency_choices: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_desired_salary_currency_choices" */
  delete_candidate_curation_desired_salary_currency_choices_by_pk: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices>;
  /** delete data from the table: "candidate_curation_desired_salary_frequency_choices" */
  delete_candidate_curation_desired_salary_frequency_choices: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_desired_salary_frequency_choices" */
  delete_candidate_curation_desired_salary_frequency_choices_by_pk: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices>;
  /** delete data from the table: "candidate_curation_detail" */
  delete_candidate_curation_detail: Maybe<Candidate_Curation_Detail_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_detail" */
  delete_candidate_curation_detail_by_pk: Maybe<Candidate_Curation_Detail>;
  /** delete data from the table: "candidate_curation_detail_recruiter_summary_log" */
  delete_candidate_curation_detail_recruiter_summary_log: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_detail_recruiter_summary_log" */
  delete_candidate_curation_detail_recruiter_summary_log_by_pk: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log>;
  /** delete data from the table: "candidate_curation_english_proficiency_choices" */
  delete_candidate_curation_english_proficiency_choices: Maybe<Candidate_Curation_English_Proficiency_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_english_proficiency_choices" */
  delete_candidate_curation_english_proficiency_choices_by_pk: Maybe<Candidate_Curation_English_Proficiency_Choices>;
  /** delete data from the table: "candidate_curation_equity_importance_choices" */
  delete_candidate_curation_equity_importance_choices: Maybe<Candidate_Curation_Equity_Importance_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_equity_importance_choices" */
  delete_candidate_curation_equity_importance_choices_by_pk: Maybe<Candidate_Curation_Equity_Importance_Choices>;
  /** delete data from the table: "candidate_curation_note" */
  delete_candidate_curation_note: Maybe<Candidate_Curation_Note_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_note" */
  delete_candidate_curation_note_by_pk: Maybe<Candidate_Curation_Note>;
  /** delete data from the table: "candidate_curation_rejected_reason_choices" */
  delete_candidate_curation_rejected_reason_choices: Maybe<Candidate_Curation_Rejected_Reason_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_rejected_reason_choices" */
  delete_candidate_curation_rejected_reason_choices_by_pk: Maybe<Candidate_Curation_Rejected_Reason_Choices>;
  /** delete data from the table: "candidate_curation_remote_work_exp_choices" */
  delete_candidate_curation_remote_work_exp_choices: Maybe<Candidate_Curation_Remote_Work_Exp_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_remote_work_exp_choices" */
  delete_candidate_curation_remote_work_exp_choices_by_pk: Maybe<Candidate_Curation_Remote_Work_Exp_Choices>;
  /** delete data from the table: "candidate_curation_visa_status_choices" */
  delete_candidate_curation_visa_status_choices: Maybe<Candidate_Curation_Visa_Status_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_visa_status_choices" */
  delete_candidate_curation_visa_status_choices_by_pk: Maybe<Candidate_Curation_Visa_Status_Choices>;
  /** delete data from the table: "candidate_curation_workflow" */
  delete_candidate_curation_workflow: Maybe<Candidate_Curation_Workflow_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_workflow" */
  delete_candidate_curation_workflow_by_pk: Maybe<Candidate_Curation_Workflow>;
  /** delete data from the table: "candidate_curation_workflow_needs_info_reason" */
  delete_candidate_curation_workflow_needs_info_reason: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_workflow_needs_info_reason" */
  delete_candidate_curation_workflow_needs_info_reason_by_pk: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason>;
  /** delete data from the table: "candidate_curation_workflow_needs_info_reason_choices" */
  delete_candidate_curation_workflow_needs_info_reason_choices: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_workflow_needs_info_reason_choices" */
  delete_candidate_curation_workflow_needs_info_reason_choices_by_pk: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices>;
  /** delete data from the table: "candidate_curation_workflow_screener_type_choices" */
  delete_candidate_curation_workflow_screener_type_choices: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_workflow_screener_type_choices" */
  delete_candidate_curation_workflow_screener_type_choices_by_pk: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices>;
  /** delete data from the table: "candidate_curation_workflow_source_choices" */
  delete_candidate_curation_workflow_source_choices: Maybe<Candidate_Curation_Workflow_Source_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_workflow_source_choices" */
  delete_candidate_curation_workflow_source_choices_by_pk: Maybe<Candidate_Curation_Workflow_Source_Choices>;
  /** delete data from the table: "candidate_curation_workflow_status_choices" */
  delete_candidate_curation_workflow_status_choices: Maybe<Candidate_Curation_Workflow_Status_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_workflow_status_choices" */
  delete_candidate_curation_workflow_status_choices_by_pk: Maybe<Candidate_Curation_Workflow_Status_Choices>;
  /** delete data from the table: "candidate_curation_workflow_status_log" */
  delete_candidate_curation_workflow_status_log: Maybe<Candidate_Curation_Workflow_Status_Log_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_workflow_status_log" */
  delete_candidate_curation_workflow_status_log_by_pk: Maybe<Candidate_Curation_Workflow_Status_Log>;
  /** delete data from the table: "candidate_curation_years_of_exp_range_choices" */
  delete_candidate_curation_years_of_exp_range_choices: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_years_of_exp_range_choices" */
  delete_candidate_curation_years_of_exp_range_choices_by_pk: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices>;
  /** delete data from the table: "candidate_curation_years_of_exp_range_choices_order" */
  delete_candidate_curation_years_of_exp_range_choices_order: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Mutation_Response>;
  /** delete single row from the table: "candidate_curation_years_of_exp_range_choices_order" */
  delete_candidate_curation_years_of_exp_range_choices_order_by_pk: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order>;
  /** delete data from the table: "candidate_detail_level_choices" */
  delete_candidate_detail_level_choices: Maybe<Candidate_Detail_Level_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_detail_level_choices" */
  delete_candidate_detail_level_choices_by_pk: Maybe<Candidate_Detail_Level_Choices>;
  /** delete data from the table: "candidate_education" */
  delete_candidate_education: Maybe<Candidate_Education_Mutation_Response>;
  /** delete single row from the table: "candidate_education" */
  delete_candidate_education_by_pk: Maybe<Candidate_Education>;
  /** delete data from the table: "candidate_education_concentration_choices" */
  delete_candidate_education_concentration_choices: Maybe<Candidate_Education_Concentration_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_education_concentration_choices" */
  delete_candidate_education_concentration_choices_by_pk: Maybe<Candidate_Education_Concentration_Choices>;
  /** delete data from the table: "candidate_education_degree_choices" */
  delete_candidate_education_degree_choices: Maybe<Candidate_Education_Degree_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_education_degree_choices" */
  delete_candidate_education_degree_choices_by_pk: Maybe<Candidate_Education_Degree_Choices>;
  /** delete data from the table: "candidate_email" */
  delete_candidate_email: Maybe<Candidate_Email_Mutation_Response>;
  /** delete single row from the table: "candidate_email" */
  delete_candidate_email_by_pk: Maybe<Candidate_Email>;
  /** delete data from the table: "candidate_embedding" */
  delete_candidate_embedding: Maybe<Candidate_Embedding_Mutation_Response>;
  /** delete single row from the table: "candidate_embedding" */
  delete_candidate_embedding_by_pk: Maybe<Candidate_Embedding>;
  /** delete data from the table: "candidate_highlight_choices" */
  delete_candidate_highlight_choices: Maybe<Candidate_Highlight_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_highlight_choices" */
  delete_candidate_highlight_choices_by_pk: Maybe<Candidate_Highlight_Choices>;
  /** delete data from the table: "candidate_hub_location" */
  delete_candidate_hub_location: Maybe<Candidate_Hub_Location_Mutation_Response>;
  /** delete single row from the table: "candidate_hub_location" */
  delete_candidate_hub_location_by_pk: Maybe<Candidate_Hub_Location>;
  /** delete data from the table: "candidate_interview_invite" */
  delete_candidate_interview_invite: Maybe<Candidate_Interview_Invite_Mutation_Response>;
  /** delete single row from the table: "candidate_interview_invite" */
  delete_candidate_interview_invite_by_pk: Maybe<Candidate_Interview_Invite>;
  /** delete data from the table: "candidate_job" */
  delete_candidate_job: Maybe<Candidate_Job_Mutation_Response>;
  /** delete single row from the table: "candidate_job" */
  delete_candidate_job_by_pk: Maybe<Candidate_Job>;
  /** delete data from the table: "candidate_job_workflow" */
  delete_candidate_job_workflow: Maybe<Candidate_Job_Workflow_Mutation_Response>;
  /** delete single row from the table: "candidate_job_workflow" */
  delete_candidate_job_workflow_by_pk: Maybe<Candidate_Job_Workflow>;
  /** delete data from the table: "candidate_job_workflow_source_choices" */
  delete_candidate_job_workflow_source_choices: Maybe<Candidate_Job_Workflow_Source_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_job_workflow_source_choices" */
  delete_candidate_job_workflow_source_choices_by_pk: Maybe<Candidate_Job_Workflow_Source_Choices>;
  /** delete data from the table: "candidate_job_workflow_status" */
  delete_candidate_job_workflow_status: Maybe<Candidate_Job_Workflow_Status_Mutation_Response>;
  /** delete single row from the table: "candidate_job_workflow_status" */
  delete_candidate_job_workflow_status_by_pk: Maybe<Candidate_Job_Workflow_Status>;
  /** delete data from the table: "candidate_job_workflow_status_choices" */
  delete_candidate_job_workflow_status_choices: Maybe<Candidate_Job_Workflow_Status_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_job_workflow_status_choices" */
  delete_candidate_job_workflow_status_choices_by_pk: Maybe<Candidate_Job_Workflow_Status_Choices>;
  /** delete data from the table: "candidate_parsed_resume" */
  delete_candidate_parsed_resume: Maybe<Candidate_Parsed_Resume_Mutation_Response>;
  /** delete single row from the table: "candidate_parsed_resume" */
  delete_candidate_parsed_resume_by_pk: Maybe<Candidate_Parsed_Resume>;
  /** delete data from the table: "candidate_pdl" */
  delete_candidate_pdl: Maybe<Candidate_Pdl_Mutation_Response>;
  /** delete single row from the table: "candidate_pdl" */
  delete_candidate_pdl_by_pk: Maybe<Candidate_Pdl>;
  /** delete data from the table: "candidate_referrals" */
  delete_candidate_referrals: Maybe<Candidate_Referrals_Mutation_Response>;
  /** delete single row from the table: "candidate_referrals" */
  delete_candidate_referrals_by_pk: Maybe<Candidate_Referrals>;
  /** delete data from the table: "candidate_role" */
  delete_candidate_role: Maybe<Candidate_Role_Mutation_Response>;
  /** delete single row from the table: "candidate_role" */
  delete_candidate_role_by_pk: Maybe<Candidate_Role>;
  /** delete data from the table: "candidate_role_skill" */
  delete_candidate_role_skill: Maybe<Candidate_Role_Skill_Mutation_Response>;
  /** delete single row from the table: "candidate_role_skill" */
  delete_candidate_role_skill_by_pk: Maybe<Candidate_Role_Skill>;
  /** delete data from the table: "candidate_sdlc_experience_choices" */
  delete_candidate_sdlc_experience_choices: Maybe<Candidate_Sdlc_Experience_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_sdlc_experience_choices" */
  delete_candidate_sdlc_experience_choices_by_pk: Maybe<Candidate_Sdlc_Experience_Choices>;
  /** delete data from the table: "candidate_skill" */
  delete_candidate_skill: Maybe<Candidate_Skill_Mutation_Response>;
  /** delete single row from the table: "candidate_skill" */
  delete_candidate_skill_by_pk: Maybe<Candidate_Skill>;
  /** delete data from the table: "candidate_skill_comp_choices" */
  delete_candidate_skill_comp_choices: Maybe<Candidate_Skill_Comp_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_skill_comp_choices" */
  delete_candidate_skill_comp_choices_by_pk: Maybe<Candidate_Skill_Comp_Choices>;
  /** delete data from the table: "candidate_source_choices" */
  delete_candidate_source_choices: Maybe<Candidate_Source_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_source_choices" */
  delete_candidate_source_choices_by_pk: Maybe<Candidate_Source_Choices>;
  /** delete data from the table: "candidate_stage_choices" */
  delete_candidate_stage_choices: Maybe<Candidate_Stage_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_stage_choices" */
  delete_candidate_stage_choices_by_pk: Maybe<Candidate_Stage_Choices>;
  /** delete data from the table: "candidate_stage_choices_order" */
  delete_candidate_stage_choices_order: Maybe<Candidate_Stage_Choices_Order_Mutation_Response>;
  /** delete single row from the table: "candidate_stage_choices_order" */
  delete_candidate_stage_choices_order_by_pk: Maybe<Candidate_Stage_Choices_Order>;
  /** delete data from the table: "candidate_stage_log" */
  delete_candidate_stage_log: Maybe<Candidate_Stage_Log_Mutation_Response>;
  /** delete single row from the table: "candidate_stage_log" */
  delete_candidate_stage_log_by_pk: Maybe<Candidate_Stage_Log>;
  /** delete data from the table: "candidate_stage_source_choices" */
  delete_candidate_stage_source_choices: Maybe<Candidate_Stage_Source_Choices_Mutation_Response>;
  /** delete single row from the table: "candidate_stage_source_choices" */
  delete_candidate_stage_source_choices_by_pk: Maybe<Candidate_Stage_Source_Choices>;
  /** delete data from the table: "candidate_trait" */
  delete_candidate_trait: Maybe<Candidate_Trait_Mutation_Response>;
  /** delete single row from the table: "candidate_trait" */
  delete_candidate_trait_by_pk: Maybe<Candidate_Trait>;
  /** delete data from the table: "candidate_work_experience" */
  delete_candidate_work_experience: Maybe<Candidate_Work_Experience_Mutation_Response>;
  /** delete single row from the table: "candidate_work_experience" */
  delete_candidate_work_experience_by_pk: Maybe<Candidate_Work_Experience>;
  /** delete data from the table: "candidate_work_experience_skill" */
  delete_candidate_work_experience_skill: Maybe<Candidate_Work_Experience_Skill_Mutation_Response>;
  /** delete single row from the table: "candidate_work_experience_skill" */
  delete_candidate_work_experience_skill_by_pk: Maybe<Candidate_Work_Experience_Skill>;
  /** delete data from the table: "candidates_in_interview_or_rejected_by_organization_result" */
  delete_candidates_in_interview_or_rejected_by_organization_result: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Mutation_Response>;
  /** delete data from the table: "celery.celery_taskmeta" */
  delete_celery_celery_taskmeta: Maybe<Celery_Celery_Taskmeta_Mutation_Response>;
  /** delete single row from the table: "celery.celery_taskmeta" */
  delete_celery_celery_taskmeta_by_pk: Maybe<Celery_Celery_Taskmeta>;
  /** delete data from the table: "celery.celery_tasksetmeta" */
  delete_celery_celery_tasksetmeta: Maybe<Celery_Celery_Tasksetmeta_Mutation_Response>;
  /** delete single row from the table: "celery.celery_tasksetmeta" */
  delete_celery_celery_tasksetmeta_by_pk: Maybe<Celery_Celery_Tasksetmeta>;
  /** delete data from the table: "celery.task_retry_log" */
  delete_celery_task_retry_log: Maybe<Celery_Task_Retry_Log_Mutation_Response>;
  /** delete single row from the table: "celery.task_retry_log" */
  delete_celery_task_retry_log_by_pk: Maybe<Celery_Task_Retry_Log>;
  /** delete data from the table: "client_prospect" */
  delete_client_prospect: Maybe<Client_Prospect_Mutation_Response>;
  /** delete single row from the table: "client_prospect" */
  delete_client_prospect_by_pk: Maybe<Client_Prospect>;
  /** delete data from the table: "client_prospect_event" */
  delete_client_prospect_event: Maybe<Client_Prospect_Event_Mutation_Response>;
  /** delete single row from the table: "client_prospect_event" */
  delete_client_prospect_event_by_pk: Maybe<Client_Prospect_Event>;
  /** delete data from the table: "client_prospect_events_choices" */
  delete_client_prospect_events_choices: Maybe<Client_Prospect_Events_Choices_Mutation_Response>;
  /** delete single row from the table: "client_prospect_events_choices" */
  delete_client_prospect_events_choices_by_pk: Maybe<Client_Prospect_Events_Choices>;
  /** delete data from the table: "client_prospect_role" */
  delete_client_prospect_role: Maybe<Client_Prospect_Role_Mutation_Response>;
  /** delete single row from the table: "client_prospect_role" */
  delete_client_prospect_role_by_pk: Maybe<Client_Prospect_Role>;
  /** delete data from the table: "client_prospect_role_skill" */
  delete_client_prospect_role_skill: Maybe<Client_Prospect_Role_Skill_Mutation_Response>;
  /** delete single row from the table: "client_prospect_role_skill" */
  delete_client_prospect_role_skill_by_pk: Maybe<Client_Prospect_Role_Skill>;
  /** delete data from the table: "client_versions" */
  delete_client_versions: Maybe<Client_Versions_Mutation_Response>;
  /** delete single row from the table: "client_versions" */
  delete_client_versions_by_pk: Maybe<Client_Versions>;
  /** delete data from the table: "company" */
  delete_company: Maybe<Company_Mutation_Response>;
  /** delete single row from the table: "company" */
  delete_company_by_pk: Maybe<Company>;
  /** delete data from the table: "company_name_synonym" */
  delete_company_name_synonym: Maybe<Company_Name_Synonym_Mutation_Response>;
  /** delete single row from the table: "company_name_synonym" */
  delete_company_name_synonym_by_pk: Maybe<Company_Name_Synonym>;
  /** delete data from the table: "company_pdl_blob" */
  delete_company_pdl_blob: Maybe<Company_Pdl_Blob_Mutation_Response>;
  /** delete single row from the table: "company_pdl_blob" */
  delete_company_pdl_blob_by_pk: Maybe<Company_Pdl_Blob>;
  /** delete data from the table: "company_size_choices" */
  delete_company_size_choices: Maybe<Company_Size_Choices_Mutation_Response>;
  /** delete single row from the table: "company_size_choices" */
  delete_company_size_choices_by_pk: Maybe<Company_Size_Choices>;
  /** delete data from the table: "contact" */
  delete_contact: Maybe<Contact_Mutation_Response>;
  /** delete single row from the table: "contact" */
  delete_contact_by_pk: Maybe<Contact>;
  /** delete data from the table: "contract_length_choices" */
  delete_contract_length_choices: Maybe<Contract_Length_Choices_Mutation_Response>;
  /** delete single row from the table: "contract_length_choices" */
  delete_contract_length_choices_by_pk: Maybe<Contract_Length_Choices>;
  /** delete data from the table: "country_choices" */
  delete_country_choices: Maybe<Country_Choices_Mutation_Response>;
  /** delete single row from the table: "country_choices" */
  delete_country_choices_by_pk: Maybe<Country_Choices>;
  /** delete data from the table: "currency_exchange_rate" */
  delete_currency_exchange_rate: Maybe<Currency_Exchange_Rate_Mutation_Response>;
  /** delete single row from the table: "currency_exchange_rate" */
  delete_currency_exchange_rate_by_pk: Maybe<Currency_Exchange_Rate>;
  /** delete data from the table: "dayforce_assignment" */
  delete_dayforce_assignment: Maybe<Dayforce_Assignment_Mutation_Response>;
  /** delete single row from the table: "dayforce_assignment" */
  delete_dayforce_assignment_by_pk: Maybe<Dayforce_Assignment>;
  /** delete data from the table: "dayforce_customer" */
  delete_dayforce_customer: Maybe<Dayforce_Customer_Mutation_Response>;
  /** delete single row from the table: "dayforce_customer" */
  delete_dayforce_customer_by_pk: Maybe<Dayforce_Customer>;
  /** delete data from the table: "dayforce_employee" */
  delete_dayforce_employee: Maybe<Dayforce_Employee_Mutation_Response>;
  /** delete single row from the table: "dayforce_employee" */
  delete_dayforce_employee_by_pk: Maybe<Dayforce_Employee>;
  /** delete data from the table: "dayforce_location" */
  delete_dayforce_location: Maybe<Dayforce_Location_Mutation_Response>;
  /** delete single row from the table: "dayforce_location" */
  delete_dayforce_location_by_pk: Maybe<Dayforce_Location>;
  /** delete data from the table: "email_notification" */
  delete_email_notification: Maybe<Email_Notification_Mutation_Response>;
  /** delete single row from the table: "email_notification" */
  delete_email_notification_by_pk: Maybe<Email_Notification>;
  /** delete data from the table: "email_template" */
  delete_email_template: Maybe<Email_Template_Mutation_Response>;
  /** delete single row from the table: "email_template" */
  delete_email_template_by_pk: Maybe<Email_Template>;
  /** delete data from the table: "employment_type_choices" */
  delete_employment_type_choices: Maybe<Employment_Type_Choices_Mutation_Response>;
  /** delete single row from the table: "employment_type_choices" */
  delete_employment_type_choices_by_pk: Maybe<Employment_Type_Choices>;
  /** delete data from the table: "explore_candidates_popular_search" */
  delete_explore_candidates_popular_search: Maybe<Explore_Candidates_Popular_Search_Mutation_Response>;
  /** delete single row from the table: "explore_candidates_popular_search" */
  delete_explore_candidates_popular_search_by_pk: Maybe<Explore_Candidates_Popular_Search>;
  /** delete data from the table: "explore_candidates_query_result" */
  delete_explore_candidates_query_result: Maybe<Explore_Candidates_Query_Result_Mutation_Response>;
  /** delete single row from the table: "explore_candidates_query_result" */
  delete_explore_candidates_query_result_by_pk: Maybe<Explore_Candidates_Query_Result>;
  /** delete data from the table: "explore_candidates_search" */
  delete_explore_candidates_search: Maybe<Explore_Candidates_Search_Mutation_Response>;
  /** delete single row from the table: "explore_candidates_search" */
  delete_explore_candidates_search_by_pk: Maybe<Explore_Candidates_Search>;
  /** delete data from the table: "explore_candidates_search_candidate_highlight" */
  delete_explore_candidates_search_candidate_highlight: Maybe<Explore_Candidates_Search_Candidate_Highlight_Mutation_Response>;
  /** delete single row from the table: "explore_candidates_search_candidate_highlight" */
  delete_explore_candidates_search_candidate_highlight_by_pk: Maybe<Explore_Candidates_Search_Candidate_Highlight>;
  /** delete data from the table: "explore_candidates_search_must_have_skill" */
  delete_explore_candidates_search_must_have_skill: Maybe<Explore_Candidates_Search_Must_Have_Skill_Mutation_Response>;
  /** delete single row from the table: "explore_candidates_search_must_have_skill" */
  delete_explore_candidates_search_must_have_skill_by_pk: Maybe<Explore_Candidates_Search_Must_Have_Skill>;
  /** delete data from the table: "explore_candidates_search_nice_to_have_skill" */
  delete_explore_candidates_search_nice_to_have_skill: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Mutation_Response>;
  /** delete single row from the table: "explore_candidates_search_nice_to_have_skill" */
  delete_explore_candidates_search_nice_to_have_skill_by_pk: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill>;
  /** delete data from the table: "explore_candidates_search_region" */
  delete_explore_candidates_search_region: Maybe<Explore_Candidates_Search_Region_Mutation_Response>;
  /** delete single row from the table: "explore_candidates_search_region" */
  delete_explore_candidates_search_region_by_pk: Maybe<Explore_Candidates_Search_Region>;
  /** delete data from the table: "explore_candidates_user_saved_search" */
  delete_explore_candidates_user_saved_search: Maybe<Explore_Candidates_User_Saved_Search_Mutation_Response>;
  /** delete single row from the table: "explore_candidates_user_saved_search" */
  delete_explore_candidates_user_saved_search_by_pk: Maybe<Explore_Candidates_User_Saved_Search>;
  /** delete data from the table: "external_candidate_meta" */
  delete_external_candidate_meta: Maybe<External_Candidate_Meta_Mutation_Response>;
  /** delete single row from the table: "external_candidate_meta" */
  delete_external_candidate_meta_by_pk: Maybe<External_Candidate_Meta>;
  /** delete data from the table: "feature" */
  delete_feature: Maybe<Feature_Mutation_Response>;
  /** delete single row from the table: "feature" */
  delete_feature_by_pk: Maybe<Feature>;
  /** delete data from the table: "hireflix_transcript" */
  delete_hireflix_transcript: Maybe<Hireflix_Transcript_Mutation_Response>;
  /** delete data from the table: "hireflix_transcript_blob" */
  delete_hireflix_transcript_blob: Maybe<Hireflix_Transcript_Blob_Mutation_Response>;
  /** delete single row from the table: "hireflix_transcript_blob" */
  delete_hireflix_transcript_blob_by_pk: Maybe<Hireflix_Transcript_Blob>;
  /** delete single row from the table: "hireflix_transcript" */
  delete_hireflix_transcript_by_pk: Maybe<Hireflix_Transcript>;
  /** delete data from the table: "hr_test" */
  delete_hr_test: Maybe<Hr_Test_Mutation_Response>;
  /** delete single row from the table: "hr_test" */
  delete_hr_test_by_pk: Maybe<Hr_Test>;
  /** delete data from the table: "hr_test_candidate" */
  delete_hr_test_candidate: Maybe<Hr_Test_Candidate_Mutation_Response>;
  /** delete single row from the table: "hr_test_candidate" */
  delete_hr_test_candidate_by_pk: Maybe<Hr_Test_Candidate>;
  /** delete data from the table: "hr_test_result" */
  delete_hr_test_result: Maybe<Hr_Test_Result_Mutation_Response>;
  /** delete single row from the table: "hr_test_result" */
  delete_hr_test_result_by_pk: Maybe<Hr_Test_Result>;
  /** delete data from the table: "icims_applicant_workflow" */
  delete_icims_applicant_workflow: Maybe<Icims_Applicant_Workflow_Mutation_Response>;
  /** delete single row from the table: "icims_applicant_workflow" */
  delete_icims_applicant_workflow_by_pk: Maybe<Icims_Applicant_Workflow>;
  /** delete data from the table: "icims_applicant_workflow_feedback" */
  delete_icims_applicant_workflow_feedback: Maybe<Icims_Applicant_Workflow_Feedback_Mutation_Response>;
  /** delete single row from the table: "icims_applicant_workflow_feedback" */
  delete_icims_applicant_workflow_feedback_by_pk: Maybe<Icims_Applicant_Workflow_Feedback>;
  /** delete data from the table: "icims_applicant_workflow_status" */
  delete_icims_applicant_workflow_status: Maybe<Icims_Applicant_Workflow_Status_Mutation_Response>;
  /** delete single row from the table: "icims_applicant_workflow_status" */
  delete_icims_applicant_workflow_status_by_pk: Maybe<Icims_Applicant_Workflow_Status>;
  /** delete data from the table: "icims_company" */
  delete_icims_company: Maybe<Icims_Company_Mutation_Response>;
  /** delete single row from the table: "icims_company" */
  delete_icims_company_by_pk: Maybe<Icims_Company>;
  /** delete data from the table: "icims_country" */
  delete_icims_country: Maybe<Icims_Country_Mutation_Response>;
  /** delete single row from the table: "icims_country" */
  delete_icims_country_by_pk: Maybe<Icims_Country>;
  /** delete data from the table: "icims_degree" */
  delete_icims_degree: Maybe<Icims_Degree_Mutation_Response>;
  /** delete single row from the table: "icims_degree" */
  delete_icims_degree_by_pk: Maybe<Icims_Degree>;
  /** delete data from the table: "icims_education" */
  delete_icims_education: Maybe<Icims_Education_Mutation_Response>;
  /** delete single row from the table: "icims_education" */
  delete_icims_education_by_pk: Maybe<Icims_Education>;
  /** delete data from the table: "icims_folder" */
  delete_icims_folder: Maybe<Icims_Folder_Mutation_Response>;
  /** delete single row from the table: "icims_folder" */
  delete_icims_folder_by_pk: Maybe<Icims_Folder>;
  /** delete data from the table: "icims_initial_data_transfer" */
  delete_icims_initial_data_transfer: Maybe<Icims_Initial_Data_Transfer_Mutation_Response>;
  /** delete single row from the table: "icims_initial_data_transfer" */
  delete_icims_initial_data_transfer_by_pk: Maybe<Icims_Initial_Data_Transfer>;
  /** delete data from the table: "icims_interested_location" */
  delete_icims_interested_location: Maybe<Icims_Interested_Location_Mutation_Response>;
  /** delete single row from the table: "icims_interested_location" */
  delete_icims_interested_location_by_pk: Maybe<Icims_Interested_Location>;
  /** delete data from the table: "icims_job" */
  delete_icims_job: Maybe<Icims_Job_Mutation_Response>;
  /** delete single row from the table: "icims_job" */
  delete_icims_job_by_pk: Maybe<Icims_Job>;
  /** delete data from the table: "icims_job_location" */
  delete_icims_job_location: Maybe<Icims_Job_Location_Mutation_Response>;
  /** delete single row from the table: "icims_job_location" */
  delete_icims_job_location_by_pk: Maybe<Icims_Job_Location>;
  /** delete data from the table: "icims_job_requested_location" */
  delete_icims_job_requested_location: Maybe<Icims_Job_Requested_Location_Mutation_Response>;
  /** delete single row from the table: "icims_job_requested_location" */
  delete_icims_job_requested_location_by_pk: Maybe<Icims_Job_Requested_Location>;
  /** delete data from the table: "icims_job_skill" */
  delete_icims_job_skill: Maybe<Icims_Job_Skill_Mutation_Response>;
  /** delete single row from the table: "icims_job_skill" */
  delete_icims_job_skill_by_pk: Maybe<Icims_Job_Skill>;
  /** delete data from the table: "icims_location" */
  delete_icims_location: Maybe<Icims_Location_Mutation_Response>;
  /** delete single row from the table: "icims_location" */
  delete_icims_location_by_pk: Maybe<Icims_Location>;
  /** delete data from the table: "icims_major" */
  delete_icims_major: Maybe<Icims_Major_Mutation_Response>;
  /** delete single row from the table: "icims_major" */
  delete_icims_major_by_pk: Maybe<Icims_Major>;
  /** delete data from the table: "icims_person" */
  delete_icims_person: Maybe<Icims_Person_Mutation_Response>;
  /** delete single row from the table: "icims_person" */
  delete_icims_person_by_pk: Maybe<Icims_Person>;
  /** delete data from the table: "icims_person_interested_location" */
  delete_icims_person_interested_location: Maybe<Icims_Person_Interested_Location_Mutation_Response>;
  /** delete single row from the table: "icims_person_interested_location" */
  delete_icims_person_interested_location_by_pk: Maybe<Icims_Person_Interested_Location>;
  /** delete data from the table: "icims_person_preferred_location" */
  delete_icims_person_preferred_location: Maybe<Icims_Person_Preferred_Location_Mutation_Response>;
  /** delete single row from the table: "icims_person_preferred_location" */
  delete_icims_person_preferred_location_by_pk: Maybe<Icims_Person_Preferred_Location>;
  /** delete data from the table: "icims_person_skill" */
  delete_icims_person_skill: Maybe<Icims_Person_Skill_Mutation_Response>;
  /** delete single row from the table: "icims_person_skill" */
  delete_icims_person_skill_by_pk: Maybe<Icims_Person_Skill>;
  /** delete data from the table: "icims_profile_applicant_workflow" */
  delete_icims_profile_applicant_workflow: Maybe<Icims_Profile_Applicant_Workflow_Mutation_Response>;
  /** delete single row from the table: "icims_profile_applicant_workflow" */
  delete_icims_profile_applicant_workflow_by_pk: Maybe<Icims_Profile_Applicant_Workflow>;
  /** delete data from the table: "icims_profile_job" */
  delete_icims_profile_job: Maybe<Icims_Profile_Job_Mutation_Response>;
  /** delete single row from the table: "icims_profile_job" */
  delete_icims_profile_job_by_pk: Maybe<Icims_Profile_Job>;
  /** delete data from the table: "icims_profile_location" */
  delete_icims_profile_location: Maybe<Icims_Profile_Location_Mutation_Response>;
  /** delete single row from the table: "icims_profile_location" */
  delete_icims_profile_location_by_pk: Maybe<Icims_Profile_Location>;
  /** delete data from the table: "icims_profile_person" */
  delete_icims_profile_person: Maybe<Icims_Profile_Person_Mutation_Response>;
  /** delete single row from the table: "icims_profile_person" */
  delete_icims_profile_person_by_pk: Maybe<Icims_Profile_Person>;
  /** delete data from the table: "icims_profile_requested_location" */
  delete_icims_profile_requested_location: Maybe<Icims_Profile_Requested_Location_Mutation_Response>;
  /** delete single row from the table: "icims_profile_requested_location" */
  delete_icims_profile_requested_location_by_pk: Maybe<Icims_Profile_Requested_Location>;
  /** delete data from the table: "icims_requested_location" */
  delete_icims_requested_location: Maybe<Icims_Requested_Location_Mutation_Response>;
  /** delete single row from the table: "icims_requested_location" */
  delete_icims_requested_location_by_pk: Maybe<Icims_Requested_Location>;
  /** delete data from the table: "icims_school" */
  delete_icims_school: Maybe<Icims_School_Mutation_Response>;
  /** delete single row from the table: "icims_school" */
  delete_icims_school_by_pk: Maybe<Icims_School>;
  /** delete data from the table: "icims_skill" */
  delete_icims_skill: Maybe<Icims_Skill_Mutation_Response>;
  /** delete single row from the table: "icims_skill" */
  delete_icims_skill_by_pk: Maybe<Icims_Skill>;
  /** delete data from the table: "icims_status" */
  delete_icims_status: Maybe<Icims_Status_Mutation_Response>;
  /** delete single row from the table: "icims_status" */
  delete_icims_status_by_pk: Maybe<Icims_Status>;
  /** delete data from the table: "icims_sync_status_choices" */
  delete_icims_sync_status_choices: Maybe<Icims_Sync_Status_Choices_Mutation_Response>;
  /** delete single row from the table: "icims_sync_status_choices" */
  delete_icims_sync_status_choices_by_pk: Maybe<Icims_Sync_Status_Choices>;
  /** delete data from the table: "icims_work_experience" */
  delete_icims_work_experience: Maybe<Icims_Work_Experience_Mutation_Response>;
  /** delete single row from the table: "icims_work_experience" */
  delete_icims_work_experience_by_pk: Maybe<Icims_Work_Experience>;
  /** delete data from the table: "industry" */
  delete_industry: Maybe<Industry_Mutation_Response>;
  /** delete single row from the table: "industry" */
  delete_industry_by_pk: Maybe<Industry>;
  /** delete data from the table: "initial_sell_type_choices" */
  delete_initial_sell_type_choices: Maybe<Initial_Sell_Type_Choices_Mutation_Response>;
  /** delete single row from the table: "initial_sell_type_choices" */
  delete_initial_sell_type_choices_by_pk: Maybe<Initial_Sell_Type_Choices>;
  /** delete data from the table: "interview_detail" */
  delete_interview_detail: Maybe<Interview_Detail_Mutation_Response>;
  /** delete single row from the table: "interview_detail" */
  delete_interview_detail_by_pk: Maybe<Interview_Detail>;
  /** delete data from the table: "interview_feedback" */
  delete_interview_feedback: Maybe<Interview_Feedback_Mutation_Response>;
  /** delete single row from the table: "interview_feedback" */
  delete_interview_feedback_by_pk: Maybe<Interview_Feedback>;
  /** delete data from the table: "interview_feedback_rating_choices" */
  delete_interview_feedback_rating_choices: Maybe<Interview_Feedback_Rating_Choices_Mutation_Response>;
  /** delete single row from the table: "interview_feedback_rating_choices" */
  delete_interview_feedback_rating_choices_by_pk: Maybe<Interview_Feedback_Rating_Choices>;
  /** delete data from the table: "interview_feedback_request" */
  delete_interview_feedback_request: Maybe<Interview_Feedback_Request_Mutation_Response>;
  /** delete single row from the table: "interview_feedback_request" */
  delete_interview_feedback_request_by_pk: Maybe<Interview_Feedback_Request>;
  /** delete data from the table: "interview_request" */
  delete_interview_request: Maybe<Interview_Request_Mutation_Response>;
  /** delete single row from the table: "interview_request" */
  delete_interview_request_by_pk: Maybe<Interview_Request>;
  /** delete data from the table: "interview_type_choices" */
  delete_interview_type_choices: Maybe<Interview_Type_Choices_Mutation_Response>;
  /** delete single row from the table: "interview_type_choices" */
  delete_interview_type_choices_by_pk: Maybe<Interview_Type_Choices>;
  /** delete data from the table: "job" */
  delete_job: Maybe<Job_Mutation_Response>;
  /** delete data from the table: "job_acceptable_location" */
  delete_job_acceptable_location: Maybe<Job_Acceptable_Location_Mutation_Response>;
  /** delete single row from the table: "job_acceptable_location" */
  delete_job_acceptable_location_by_pk: Maybe<Job_Acceptable_Location>;
  /** delete single row from the table: "job" */
  delete_job_by_pk: Maybe<Job>;
  /** delete data from the table: "job_code" */
  delete_job_code: Maybe<Job_Code_Mutation_Response>;
  /** delete single row from the table: "job_code" */
  delete_job_code_by_pk: Maybe<Job_Code>;
  /** delete data from the table: "job_level_choices" */
  delete_job_level_choices: Maybe<Job_Level_Choices_Mutation_Response>;
  /** delete single row from the table: "job_level_choices" */
  delete_job_level_choices_by_pk: Maybe<Job_Level_Choices>;
  /** delete data from the table: "job_meta_info" */
  delete_job_meta_info: Maybe<Job_Meta_Info_Mutation_Response>;
  /** delete single row from the table: "job_meta_info" */
  delete_job_meta_info_by_pk: Maybe<Job_Meta_Info>;
  /** delete data from the table: "job_nice_to_have_skill" */
  delete_job_nice_to_have_skill: Maybe<Job_Nice_To_Have_Skill_Mutation_Response>;
  /** delete single row from the table: "job_nice_to_have_skill" */
  delete_job_nice_to_have_skill_by_pk: Maybe<Job_Nice_To_Have_Skill>;
  /** delete data from the table: "job_position" */
  delete_job_position: Maybe<Job_Position_Mutation_Response>;
  /** delete single row from the table: "job_position" */
  delete_job_position_by_pk: Maybe<Job_Position>;
  /** delete data from the table: "job_priority_choices" */
  delete_job_priority_choices: Maybe<Job_Priority_Choices_Mutation_Response>;
  /** delete single row from the table: "job_priority_choices" */
  delete_job_priority_choices_by_pk: Maybe<Job_Priority_Choices>;
  /** delete data from the table: "job_required_skill" */
  delete_job_required_skill: Maybe<Job_Required_Skill_Mutation_Response>;
  /** delete single row from the table: "job_required_skill" */
  delete_job_required_skill_by_pk: Maybe<Job_Required_Skill>;
  /** delete data from the table: "job_role_source_choices" */
  delete_job_role_source_choices: Maybe<Job_Role_Source_Choices_Mutation_Response>;
  /** delete single row from the table: "job_role_source_choices" */
  delete_job_role_source_choices_by_pk: Maybe<Job_Role_Source_Choices>;
  /** delete data from the table: "job_service_type" */
  delete_job_service_type: Maybe<Job_Service_Type_Mutation_Response>;
  /** delete single row from the table: "job_service_type" */
  delete_job_service_type_by_pk: Maybe<Job_Service_Type>;
  /** delete data from the table: "job_status_choices" */
  delete_job_status_choices: Maybe<Job_Status_Choices_Mutation_Response>;
  /** delete single row from the table: "job_status_choices" */
  delete_job_status_choices_by_pk: Maybe<Job_Status_Choices>;
  /** delete data from the table: "job_status_choices_order" */
  delete_job_status_choices_order: Maybe<Job_Status_Choices_Order_Mutation_Response>;
  /** delete single row from the table: "job_status_choices_order" */
  delete_job_status_choices_order_by_pk: Maybe<Job_Status_Choices_Order>;
  /** delete data from the table: "job_status_log" */
  delete_job_status_log: Maybe<Job_Status_Log_Mutation_Response>;
  /** delete single row from the table: "job_status_log" */
  delete_job_status_log_by_pk: Maybe<Job_Status_Log>;
  /** delete data from the table: "job_tech_stack" */
  delete_job_tech_stack: Maybe<Job_Tech_Stack_Mutation_Response>;
  /** delete single row from the table: "job_tech_stack" */
  delete_job_tech_stack_by_pk: Maybe<Job_Tech_Stack>;
  /** delete data from the table: "linkedin_contact" */
  delete_linkedin_contact: Maybe<Linkedin_Contact_Mutation_Response>;
  /** delete single row from the table: "linkedin_contact" */
  delete_linkedin_contact_by_pk: Maybe<Linkedin_Contact>;
  /** delete data from the table: "location" */
  delete_location: Maybe<Location_Mutation_Response>;
  /** delete single row from the table: "location" */
  delete_location_by_pk: Maybe<Location>;
  /** delete data from the table: "manual_contact" */
  delete_manual_contact: Maybe<Manual_Contact_Mutation_Response>;
  /** delete single row from the table: "manual_contact" */
  delete_manual_contact_by_pk: Maybe<Manual_Contact>;
  /** delete data from the table: "merge_ats_application" */
  delete_merge_ats_application: Maybe<Merge_Ats_Application_Mutation_Response>;
  /** delete single row from the table: "merge_ats_application" */
  delete_merge_ats_application_by_pk: Maybe<Merge_Ats_Application>;
  /** delete data from the table: "merge_ats_application_log" */
  delete_merge_ats_application_log: Maybe<Merge_Ats_Application_Log_Mutation_Response>;
  /** delete single row from the table: "merge_ats_application_log" */
  delete_merge_ats_application_log_by_pk: Maybe<Merge_Ats_Application_Log>;
  /** delete data from the table: "merge_ats_candidate" */
  delete_merge_ats_candidate: Maybe<Merge_Ats_Candidate_Mutation_Response>;
  /** delete single row from the table: "merge_ats_candidate" */
  delete_merge_ats_candidate_by_pk: Maybe<Merge_Ats_Candidate>;
  /** delete data from the table: "merge_ats_job" */
  delete_merge_ats_job: Maybe<Merge_Ats_Job_Mutation_Response>;
  /** delete single row from the table: "merge_ats_job" */
  delete_merge_ats_job_by_pk: Maybe<Merge_Ats_Job>;
  /** delete data from the table: "merge_ats_job_interview_stage" */
  delete_merge_ats_job_interview_stage: Maybe<Merge_Ats_Job_Interview_Stage_Mutation_Response>;
  /** delete single row from the table: "merge_ats_job_interview_stage" */
  delete_merge_ats_job_interview_stage_by_pk: Maybe<Merge_Ats_Job_Interview_Stage>;
  /** delete data from the table: "merge_ats_linked_account" */
  delete_merge_ats_linked_account: Maybe<Merge_Ats_Linked_Account_Mutation_Response>;
  /** delete single row from the table: "merge_ats_linked_account" */
  delete_merge_ats_linked_account_by_pk: Maybe<Merge_Ats_Linked_Account>;
  /** delete data from the table: "mixpanel.profiles" */
  delete_mixpanel_profiles: Maybe<Mixpanel_Profiles_Mutation_Response>;
  /** delete single row from the table: "mixpanel.profiles" */
  delete_mixpanel_profiles_by_pk: Maybe<Mixpanel_Profiles>;
  /** delete data from the table: "offering_selection_choices" */
  delete_offering_selection_choices: Maybe<Offering_Selection_Choices_Mutation_Response>;
  /** delete single row from the table: "offering_selection_choices" */
  delete_offering_selection_choices_by_pk: Maybe<Offering_Selection_Choices>;
  /** delete data from the table: "organization" */
  delete_organization: Maybe<Organization_Mutation_Response>;
  /** delete single row from the table: "organization" */
  delete_organization_by_pk: Maybe<Organization>;
  /** delete data from the table: "organization_contact" */
  delete_organization_contact: Maybe<Organization_Contact_Mutation_Response>;
  /** delete single row from the table: "organization_contact" */
  delete_organization_contact_by_pk: Maybe<Organization_Contact>;
  /** delete data from the table: "organization_domain" */
  delete_organization_domain: Maybe<Organization_Domain_Mutation_Response>;
  /** delete single row from the table: "organization_domain" */
  delete_organization_domain_by_pk: Maybe<Organization_Domain>;
  /** delete data from the table: "organization_employee" */
  delete_organization_employee: Maybe<Organization_Employee_Mutation_Response>;
  /** delete single row from the table: "organization_employee" */
  delete_organization_employee_by_pk: Maybe<Organization_Employee>;
  /** delete data from the table: "organization_icims_jobs_result" */
  delete_organization_icims_jobs_result: Maybe<Organization_Icims_Jobs_Result_Mutation_Response>;
  /** delete data from the table: "organization_leadership" */
  delete_organization_leadership: Maybe<Organization_Leadership_Mutation_Response>;
  /** delete single row from the table: "organization_leadership" */
  delete_organization_leadership_by_pk: Maybe<Organization_Leadership>;
  /** delete data from the table: "organization_type_choices" */
  delete_organization_type_choices: Maybe<Organization_Type_Choices_Mutation_Response>;
  /** delete single row from the table: "organization_type_choices" */
  delete_organization_type_choices_by_pk: Maybe<Organization_Type_Choices>;
  /** delete data from the table: "organization_video" */
  delete_organization_video: Maybe<Organization_Video_Mutation_Response>;
  /** delete single row from the table: "organization_video" */
  delete_organization_video_by_pk: Maybe<Organization_Video>;
  /** delete data from the table: "pillar_transcript" */
  delete_pillar_transcript: Maybe<Pillar_Transcript_Mutation_Response>;
  /** delete data from the table: "pillar_transcript_blob" */
  delete_pillar_transcript_blob: Maybe<Pillar_Transcript_Blob_Mutation_Response>;
  /** delete single row from the table: "pillar_transcript_blob" */
  delete_pillar_transcript_blob_by_pk: Maybe<Pillar_Transcript_Blob>;
  /** delete single row from the table: "pillar_transcript" */
  delete_pillar_transcript_by_pk: Maybe<Pillar_Transcript>;
  /** delete data from the table: "recruiter_contact_action" */
  delete_recruiter_contact_action: Maybe<Recruiter_Contact_Action_Mutation_Response>;
  /** delete single row from the table: "recruiter_contact_action" */
  delete_recruiter_contact_action_by_pk: Maybe<Recruiter_Contact_Action>;
  /** delete data from the table: "referral_status" */
  delete_referral_status: Maybe<Referral_Status_Mutation_Response>;
  /** delete single row from the table: "referral_status" */
  delete_referral_status_by_pk: Maybe<Referral_Status>;
  /** delete data from the table: "referral_status_current" */
  delete_referral_status_current: Maybe<Referral_Status_Current_Mutation_Response>;
  /** delete single row from the table: "referral_status_current" */
  delete_referral_status_current_by_pk: Maybe<Referral_Status_Current>;
  /** delete data from the table: "region_choices" */
  delete_region_choices: Maybe<Region_Choices_Mutation_Response>;
  /** delete single row from the table: "region_choices" */
  delete_region_choices_by_pk: Maybe<Region_Choices>;
  /** delete data from the table: "reporting.candidate_metadata" */
  delete_reporting_candidate_metadata: Maybe<Reporting_Candidate_Metadata_Mutation_Response>;
  /** delete data from the table: "reporting.report_job_matches" */
  delete_reporting_report_job_matches: Maybe<Reporting_Report_Job_Matches_Mutation_Response>;
  /** delete single row from the table: "reporting.report_job_matches" */
  delete_reporting_report_job_matches_by_pk: Maybe<Reporting_Report_Job_Matches>;
  /** delete data from the table: "role" */
  delete_role: Maybe<Role_Mutation_Response>;
  /** delete single row from the table: "role" */
  delete_role_by_pk: Maybe<Role>;
  /** delete data from the table: "role_choices" */
  delete_role_choices: Maybe<Role_Choices_Mutation_Response>;
  /** delete single row from the table: "role_choices" */
  delete_role_choices_by_pk: Maybe<Role_Choices>;
  /** delete data from the table: "salary_info" */
  delete_salary_info: Maybe<Salary_Info_Mutation_Response>;
  /** delete single row from the table: "salary_info" */
  delete_salary_info_by_pk: Maybe<Salary_Info>;
  /** delete data from the table: "salary_years_experience_range_choices" */
  delete_salary_years_experience_range_choices: Maybe<Salary_Years_Experience_Range_Choices_Mutation_Response>;
  /** delete single row from the table: "salary_years_experience_range_choices" */
  delete_salary_years_experience_range_choices_by_pk: Maybe<Salary_Years_Experience_Range_Choices>;
  /** delete data from the table: "salesforce_customer" */
  delete_salesforce_customer: Maybe<Salesforce_Customer_Mutation_Response>;
  /** delete single row from the table: "salesforce_customer" */
  delete_salesforce_customer_by_pk: Maybe<Salesforce_Customer>;
  /** delete data from the table: "salesforce_customer_location" */
  delete_salesforce_customer_location: Maybe<Salesforce_Customer_Location_Mutation_Response>;
  /** delete single row from the table: "salesforce_customer_location" */
  delete_salesforce_customer_location_by_pk: Maybe<Salesforce_Customer_Location>;
  /** delete data from the table: "salesforce_location" */
  delete_salesforce_location: Maybe<Salesforce_Location_Mutation_Response>;
  /** delete single row from the table: "salesforce_location" */
  delete_salesforce_location_by_pk: Maybe<Salesforce_Location>;
  /** delete data from the table: "saved_candidate" */
  delete_saved_candidate: Maybe<Saved_Candidate_Mutation_Response>;
  /** delete single row from the table: "saved_candidate" */
  delete_saved_candidate_by_pk: Maybe<Saved_Candidate>;
  /** delete data from the table: "segment_sendgrid_prod.activity" */
  delete_segment_sendgrid_prod_activity: Maybe<Segment_Sendgrid_Prod_Activity_Mutation_Response>;
  /** delete single row from the table: "segment_sendgrid_prod.activity" */
  delete_segment_sendgrid_prod_activity_by_pk: Maybe<Segment_Sendgrid_Prod_Activity>;
  /** delete data from the table: "shared_candidate" */
  delete_shared_candidate: Maybe<Shared_Candidate_Mutation_Response>;
  /** delete single row from the table: "shared_candidate" */
  delete_shared_candidate_by_pk: Maybe<Shared_Candidate>;
  /** delete data from the table: "skill" */
  delete_skill: Maybe<Skill_Mutation_Response>;
  /** delete single row from the table: "skill" */
  delete_skill_by_pk: Maybe<Skill>;
  /** delete data from the table: "skill_feature" */
  delete_skill_feature: Maybe<Skill_Feature_Mutation_Response>;
  /** delete single row from the table: "skill_feature" */
  delete_skill_feature_by_pk: Maybe<Skill_Feature>;
  /** delete data from the table: "skill_icims_skill" */
  delete_skill_icims_skill: Maybe<Skill_Icims_Skill_Mutation_Response>;
  /** delete single row from the table: "skill_icims_skill" */
  delete_skill_icims_skill_by_pk: Maybe<Skill_Icims_Skill>;
  /** delete data from the table: "skill_synonym" */
  delete_skill_synonym: Maybe<Skill_Synonym_Mutation_Response>;
  /** delete single row from the table: "skill_synonym" */
  delete_skill_synonym_by_pk: Maybe<Skill_Synonym>;
  /** delete data from the table: "spike_matching.candidate" */
  delete_spike_matching_candidate: Maybe<Spike_Matching_Candidate_Mutation_Response>;
  /** delete single row from the table: "spike_matching.candidate" */
  delete_spike_matching_candidate_by_pk: Maybe<Spike_Matching_Candidate>;
  /** delete data from the table: "spike_matching.candidate_skill" */
  delete_spike_matching_candidate_skill: Maybe<Spike_Matching_Candidate_Skill_Mutation_Response>;
  /** delete single row from the table: "spike_matching.candidate_skill" */
  delete_spike_matching_candidate_skill_by_pk: Maybe<Spike_Matching_Candidate_Skill>;
  /** delete data from the table: "spike_matching.job" */
  delete_spike_matching_job: Maybe<Spike_Matching_Job_Mutation_Response>;
  /** delete single row from the table: "spike_matching.job" */
  delete_spike_matching_job_by_pk: Maybe<Spike_Matching_Job>;
  /** delete data from the table: "spike_matching.job_skill" */
  delete_spike_matching_job_skill: Maybe<Spike_Matching_Job_Skill_Mutation_Response>;
  /** delete single row from the table: "spike_matching.job_skill" */
  delete_spike_matching_job_skill_by_pk: Maybe<Spike_Matching_Job_Skill>;
  /** delete data from the table: "spike_matching.location" */
  delete_spike_matching_location: Maybe<Spike_Matching_Location_Mutation_Response>;
  /** delete single row from the table: "spike_matching.location" */
  delete_spike_matching_location_by_pk: Maybe<Spike_Matching_Location>;
  /** delete data from the table: "spike_matching.location_job" */
  delete_spike_matching_location_job: Maybe<Spike_Matching_Location_Job_Mutation_Response>;
  /** delete single row from the table: "spike_matching.location_job" */
  delete_spike_matching_location_job_by_pk: Maybe<Spike_Matching_Location_Job>;
  /** delete data from the table: "spike_matching.match_result" */
  delete_spike_matching_match_result: Maybe<Spike_Matching_Match_Result_Mutation_Response>;
  /** delete single row from the table: "spike_matching.match_result" */
  delete_spike_matching_match_result_by_pk: Maybe<Spike_Matching_Match_Result>;
  /** delete data from the table: "spike_matching.skill" */
  delete_spike_matching_skill: Maybe<Spike_Matching_Skill_Mutation_Response>;
  /** delete single row from the table: "spike_matching.skill" */
  delete_spike_matching_skill_by_pk: Maybe<Spike_Matching_Skill>;
  /** delete data from the table: "terminal_celery_task_status_choices" */
  delete_terminal_celery_task_status_choices: Maybe<Terminal_Celery_Task_Status_Choices_Mutation_Response>;
  /** delete single row from the table: "terminal_celery_task_status_choices" */
  delete_terminal_celery_task_status_choices_by_pk: Maybe<Terminal_Celery_Task_Status_Choices>;
  /** delete data from the table: "terminal_employee" */
  delete_terminal_employee: Maybe<Terminal_Employee_Mutation_Response>;
  /** delete single row from the table: "terminal_employee" */
  delete_terminal_employee_by_pk: Maybe<Terminal_Employee>;
  /** delete data from the table: "terminal_employee_organization" */
  delete_terminal_employee_organization: Maybe<Terminal_Employee_Organization_Mutation_Response>;
  /** delete single row from the table: "terminal_employee_organization" */
  delete_terminal_employee_organization_by_pk: Maybe<Terminal_Employee_Organization>;
  /** delete data from the table: "terminal_employee_role_choices" */
  delete_terminal_employee_role_choices: Maybe<Terminal_Employee_Role_Choices_Mutation_Response>;
  /** delete single row from the table: "terminal_employee_role_choices" */
  delete_terminal_employee_role_choices_by_pk: Maybe<Terminal_Employee_Role_Choices>;
  /** delete data from the table: "trait" */
  delete_trait: Maybe<Trait_Mutation_Response>;
  /** delete single row from the table: "trait" */
  delete_trait_by_pk: Maybe<Trait>;
  /** delete data from the table: "transcript_evaluation" */
  delete_transcript_evaluation: Maybe<Transcript_Evaluation_Mutation_Response>;
  /** delete single row from the table: "transcript_evaluation" */
  delete_transcript_evaluation_by_pk: Maybe<Transcript_Evaluation>;
  /** delete data from the table: "transcript_skill" */
  delete_transcript_skill: Maybe<Transcript_Skill_Mutation_Response>;
  /** delete single row from the table: "transcript_skill" */
  delete_transcript_skill_by_pk: Maybe<Transcript_Skill>;
  /** delete data from the table: "transcript_work_experience" */
  delete_transcript_work_experience: Maybe<Transcript_Work_Experience_Mutation_Response>;
  /** delete single row from the table: "transcript_work_experience" */
  delete_transcript_work_experience_by_pk: Maybe<Transcript_Work_Experience>;
  /** delete data from the table: "user_info" */
  delete_user_info: Maybe<User_Info_Mutation_Response>;
  /** delete single row from the table: "user_info" */
  delete_user_info_by_pk: Maybe<User_Info>;
  /** delete data from the table: "user_invitation" */
  delete_user_invitation: Maybe<User_Invitation_Mutation_Response>;
  /** delete single row from the table: "user_invitation" */
  delete_user_invitation_by_pk: Maybe<User_Invitation>;
  /** delete data from the table: "user_linkedin_contact" */
  delete_user_linkedin_contact: Maybe<User_Linkedin_Contact_Mutation_Response>;
  /** delete single row from the table: "user_linkedin_contact" */
  delete_user_linkedin_contact_by_pk: Maybe<User_Linkedin_Contact>;
  /** delete data from the table: "user_password_blacklist" */
  delete_user_password_blacklist: Maybe<User_Password_Blacklist_Mutation_Response>;
  /** delete single row from the table: "user_password_blacklist" */
  delete_user_password_blacklist_by_pk: Maybe<User_Password_Blacklist>;
  /** delete data from the table: "user_role" */
  delete_user_role: Maybe<User_Role_Mutation_Response>;
  /** delete single row from the table: "user_role" */
  delete_user_role_by_pk: Maybe<User_Role>;
  /** delete data from the table: "user_subscription" */
  delete_user_subscription: Maybe<User_Subscription_Mutation_Response>;
  /** delete single row from the table: "user_subscription" */
  delete_user_subscription_by_pk: Maybe<User_Subscription>;
  /** delete data from the table: "user_subscription_choices" */
  delete_user_subscription_choices: Maybe<User_Subscription_Choices_Mutation_Response>;
  /** delete single row from the table: "user_subscription_choices" */
  delete_user_subscription_choices_by_pk: Maybe<User_Subscription_Choices>;
  /** delete data from the table: "user_temp_blob" */
  delete_user_temp_blob: Maybe<User_Temp_Blob_Mutation_Response>;
  /** delete single row from the table: "user_temp_blob" */
  delete_user_temp_blob_by_pk: Maybe<User_Temp_Blob>;
  /** delete data from the table: "validator" */
  delete_validator: Maybe<Validator_Mutation_Response>;
  /** delete single row from the table: "validator" */
  delete_validator_by_pk: Maybe<Validator>;
  /** insert data into the table: "activity_customer_profile_view" */
  insert_activity_customer_profile_view: Maybe<Activity_Customer_Profile_View_Mutation_Response>;
  /** insert a single row into the table: "activity_customer_profile_view" */
  insert_activity_customer_profile_view_one: Maybe<Activity_Customer_Profile_View>;
  /** insert data into the table: "ai_level" */
  insert_ai_level: Maybe<Ai_Level_Mutation_Response>;
  /** insert a single row into the table: "ai_level" */
  insert_ai_level_one: Maybe<Ai_Level>;
  /** insert data into the table: "ai_role" */
  insert_ai_role: Maybe<Ai_Role_Mutation_Response>;
  /** insert data into the table: "ai_role_multiplier_focus" */
  insert_ai_role_multiplier_focus: Maybe<Ai_Role_Multiplier_Focus_Mutation_Response>;
  /** insert a single row into the table: "ai_role_multiplier_focus" */
  insert_ai_role_multiplier_focus_one: Maybe<Ai_Role_Multiplier_Focus>;
  /** insert a single row into the table: "ai_role" */
  insert_ai_role_one: Maybe<Ai_Role>;
  /** insert data into the table: "ai_run_dump" */
  insert_ai_run_dump: Maybe<Ai_Run_Dump_Mutation_Response>;
  /** insert a single row into the table: "ai_run_dump" */
  insert_ai_run_dump_one: Maybe<Ai_Run_Dump>;
  /** insert data into the table: "applicant_consent" */
  insert_applicant_consent: Maybe<Applicant_Consent_Mutation_Response>;
  /** insert a single row into the table: "applicant_consent" */
  insert_applicant_consent_one: Maybe<Applicant_Consent>;
  /** insert data into the table: "applicant_job" */
  insert_applicant_job: Maybe<Applicant_Job_Mutation_Response>;
  /** insert a single row into the table: "applicant_job" */
  insert_applicant_job_one: Maybe<Applicant_Job>;
  /** insert data into the table: "applicant_waitlist" */
  insert_applicant_waitlist: Maybe<Applicant_Waitlist_Mutation_Response>;
  /** insert a single row into the table: "applicant_waitlist" */
  insert_applicant_waitlist_one: Maybe<Applicant_Waitlist>;
  /** insert data into the table: "audit.logged_actions" */
  insert_audit_logged_actions: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** insert a single row into the table: "audit.logged_actions" */
  insert_audit_logged_actions_one: Maybe<Audit_Logged_Actions>;
  /** insert data into the table: "calendly_event" */
  insert_calendly_event: Maybe<Calendly_Event_Mutation_Response>;
  /** insert a single row into the table: "calendly_event" */
  insert_calendly_event_one: Maybe<Calendly_Event>;
  /** insert data into the table: "calendly_invitee" */
  insert_calendly_invitee: Maybe<Calendly_Invitee_Mutation_Response>;
  /** insert a single row into the table: "calendly_invitee" */
  insert_calendly_invitee_one: Maybe<Calendly_Invitee>;
  /** insert data into the table: "calendly_user" */
  insert_calendly_user: Maybe<Calendly_User_Mutation_Response>;
  /** insert a single row into the table: "calendly_user" */
  insert_calendly_user_one: Maybe<Calendly_User>;
  /** insert data into the table: "candidate" */
  insert_candidate: Maybe<Candidate_Mutation_Response>;
  /** insert data into the table: "candidate_ai_profile_summary" */
  insert_candidate_ai_profile_summary: Maybe<Candidate_Ai_Profile_Summary_Mutation_Response>;
  /** insert a single row into the table: "candidate_ai_profile_summary" */
  insert_candidate_ai_profile_summary_one: Maybe<Candidate_Ai_Profile_Summary>;
  /** insert data into the table: "candidate_applicant_workflow_stats_result" */
  insert_candidate_applicant_workflow_stats_result: Maybe<Candidate_Applicant_Workflow_Stats_Result_Mutation_Response>;
  /** insert a single row into the table: "candidate_applicant_workflow_stats_result" */
  insert_candidate_applicant_workflow_stats_result_one: Maybe<Candidate_Applicant_Workflow_Stats_Result>;
  /** insert data into the table: "candidate_approved_country" */
  insert_candidate_approved_country: Maybe<Candidate_Approved_Country_Mutation_Response>;
  /** insert a single row into the table: "candidate_approved_country" */
  insert_candidate_approved_country_one: Maybe<Candidate_Approved_Country>;
  /** insert data into the table: "candidate_availability_choices" */
  insert_candidate_availability_choices: Maybe<Candidate_Availability_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_availability_choices" */
  insert_candidate_availability_choices_one: Maybe<Candidate_Availability_Choices>;
  /** insert data into the table: "candidate_availability_choices_order" */
  insert_candidate_availability_choices_order: Maybe<Candidate_Availability_Choices_Order_Mutation_Response>;
  /** insert a single row into the table: "candidate_availability_choices_order" */
  insert_candidate_availability_choices_order_one: Maybe<Candidate_Availability_Choices_Order>;
  /** insert data into the table: "candidate_badge" */
  insert_candidate_badge: Maybe<Candidate_Badge_Mutation_Response>;
  /** insert a single row into the table: "candidate_badge" */
  insert_candidate_badge_one: Maybe<Candidate_Badge>;
  /** insert data into the table: "candidate_browse_result" */
  insert_candidate_browse_result: Maybe<Candidate_Browse_Result_Mutation_Response>;
  /** insert a single row into the table: "candidate_browse_result" */
  insert_candidate_browse_result_one: Maybe<Candidate_Browse_Result>;
  /** insert data into the table: "candidate_curation_ai_summary" */
  insert_candidate_curation_ai_summary: Maybe<Candidate_Curation_Ai_Summary_Mutation_Response>;
  /** insert data into the table: "candidate_curation_ai_summary_feedback" */
  insert_candidate_curation_ai_summary_feedback: Maybe<Candidate_Curation_Ai_Summary_Feedback_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_ai_summary_feedback" */
  insert_candidate_curation_ai_summary_feedback_one: Maybe<Candidate_Curation_Ai_Summary_Feedback>;
  /** insert a single row into the table: "candidate_curation_ai_summary" */
  insert_candidate_curation_ai_summary_one: Maybe<Candidate_Curation_Ai_Summary>;
  /** insert data into the table: "candidate_curation_country_choices" */
  insert_candidate_curation_country_choices: Maybe<Candidate_Curation_Country_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_country_choices" */
  insert_candidate_curation_country_choices_one: Maybe<Candidate_Curation_Country_Choices>;
  /** insert data into the table: "candidate_curation_desired_salary_currency_choices" */
  insert_candidate_curation_desired_salary_currency_choices: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_desired_salary_currency_choices" */
  insert_candidate_curation_desired_salary_currency_choices_one: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices>;
  /** insert data into the table: "candidate_curation_desired_salary_frequency_choices" */
  insert_candidate_curation_desired_salary_frequency_choices: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_desired_salary_frequency_choices" */
  insert_candidate_curation_desired_salary_frequency_choices_one: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices>;
  /** insert data into the table: "candidate_curation_detail" */
  insert_candidate_curation_detail: Maybe<Candidate_Curation_Detail_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_detail" */
  insert_candidate_curation_detail_one: Maybe<Candidate_Curation_Detail>;
  /** insert data into the table: "candidate_curation_detail_recruiter_summary_log" */
  insert_candidate_curation_detail_recruiter_summary_log: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_detail_recruiter_summary_log" */
  insert_candidate_curation_detail_recruiter_summary_log_one: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log>;
  /** insert data into the table: "candidate_curation_english_proficiency_choices" */
  insert_candidate_curation_english_proficiency_choices: Maybe<Candidate_Curation_English_Proficiency_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_english_proficiency_choices" */
  insert_candidate_curation_english_proficiency_choices_one: Maybe<Candidate_Curation_English_Proficiency_Choices>;
  /** insert data into the table: "candidate_curation_equity_importance_choices" */
  insert_candidate_curation_equity_importance_choices: Maybe<Candidate_Curation_Equity_Importance_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_equity_importance_choices" */
  insert_candidate_curation_equity_importance_choices_one: Maybe<Candidate_Curation_Equity_Importance_Choices>;
  /** insert data into the table: "candidate_curation_note" */
  insert_candidate_curation_note: Maybe<Candidate_Curation_Note_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_note" */
  insert_candidate_curation_note_one: Maybe<Candidate_Curation_Note>;
  /** insert data into the table: "candidate_curation_rejected_reason_choices" */
  insert_candidate_curation_rejected_reason_choices: Maybe<Candidate_Curation_Rejected_Reason_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_rejected_reason_choices" */
  insert_candidate_curation_rejected_reason_choices_one: Maybe<Candidate_Curation_Rejected_Reason_Choices>;
  /** insert data into the table: "candidate_curation_remote_work_exp_choices" */
  insert_candidate_curation_remote_work_exp_choices: Maybe<Candidate_Curation_Remote_Work_Exp_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_remote_work_exp_choices" */
  insert_candidate_curation_remote_work_exp_choices_one: Maybe<Candidate_Curation_Remote_Work_Exp_Choices>;
  /** insert data into the table: "candidate_curation_visa_status_choices" */
  insert_candidate_curation_visa_status_choices: Maybe<Candidate_Curation_Visa_Status_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_visa_status_choices" */
  insert_candidate_curation_visa_status_choices_one: Maybe<Candidate_Curation_Visa_Status_Choices>;
  /** insert data into the table: "candidate_curation_workflow" */
  insert_candidate_curation_workflow: Maybe<Candidate_Curation_Workflow_Mutation_Response>;
  /** insert data into the table: "candidate_curation_workflow_needs_info_reason" */
  insert_candidate_curation_workflow_needs_info_reason: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Mutation_Response>;
  /** insert data into the table: "candidate_curation_workflow_needs_info_reason_choices" */
  insert_candidate_curation_workflow_needs_info_reason_choices: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_workflow_needs_info_reason_choices" */
  insert_candidate_curation_workflow_needs_info_reason_choices_one: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices>;
  /** insert a single row into the table: "candidate_curation_workflow_needs_info_reason" */
  insert_candidate_curation_workflow_needs_info_reason_one: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason>;
  /** insert a single row into the table: "candidate_curation_workflow" */
  insert_candidate_curation_workflow_one: Maybe<Candidate_Curation_Workflow>;
  /** insert data into the table: "candidate_curation_workflow_screener_type_choices" */
  insert_candidate_curation_workflow_screener_type_choices: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_workflow_screener_type_choices" */
  insert_candidate_curation_workflow_screener_type_choices_one: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices>;
  /** insert data into the table: "candidate_curation_workflow_source_choices" */
  insert_candidate_curation_workflow_source_choices: Maybe<Candidate_Curation_Workflow_Source_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_workflow_source_choices" */
  insert_candidate_curation_workflow_source_choices_one: Maybe<Candidate_Curation_Workflow_Source_Choices>;
  /** insert data into the table: "candidate_curation_workflow_status_choices" */
  insert_candidate_curation_workflow_status_choices: Maybe<Candidate_Curation_Workflow_Status_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_workflow_status_choices" */
  insert_candidate_curation_workflow_status_choices_one: Maybe<Candidate_Curation_Workflow_Status_Choices>;
  /** insert data into the table: "candidate_curation_workflow_status_log" */
  insert_candidate_curation_workflow_status_log: Maybe<Candidate_Curation_Workflow_Status_Log_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_workflow_status_log" */
  insert_candidate_curation_workflow_status_log_one: Maybe<Candidate_Curation_Workflow_Status_Log>;
  /** insert data into the table: "candidate_curation_years_of_exp_range_choices" */
  insert_candidate_curation_years_of_exp_range_choices: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_years_of_exp_range_choices" */
  insert_candidate_curation_years_of_exp_range_choices_one: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices>;
  /** insert data into the table: "candidate_curation_years_of_exp_range_choices_order" */
  insert_candidate_curation_years_of_exp_range_choices_order: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Mutation_Response>;
  /** insert a single row into the table: "candidate_curation_years_of_exp_range_choices_order" */
  insert_candidate_curation_years_of_exp_range_choices_order_one: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order>;
  /** insert data into the table: "candidate_detail_level_choices" */
  insert_candidate_detail_level_choices: Maybe<Candidate_Detail_Level_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_detail_level_choices" */
  insert_candidate_detail_level_choices_one: Maybe<Candidate_Detail_Level_Choices>;
  /** insert data into the table: "candidate_education" */
  insert_candidate_education: Maybe<Candidate_Education_Mutation_Response>;
  /** insert data into the table: "candidate_education_concentration_choices" */
  insert_candidate_education_concentration_choices: Maybe<Candidate_Education_Concentration_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_education_concentration_choices" */
  insert_candidate_education_concentration_choices_one: Maybe<Candidate_Education_Concentration_Choices>;
  /** insert data into the table: "candidate_education_degree_choices" */
  insert_candidate_education_degree_choices: Maybe<Candidate_Education_Degree_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_education_degree_choices" */
  insert_candidate_education_degree_choices_one: Maybe<Candidate_Education_Degree_Choices>;
  /** insert a single row into the table: "candidate_education" */
  insert_candidate_education_one: Maybe<Candidate_Education>;
  /** insert data into the table: "candidate_email" */
  insert_candidate_email: Maybe<Candidate_Email_Mutation_Response>;
  /** insert a single row into the table: "candidate_email" */
  insert_candidate_email_one: Maybe<Candidate_Email>;
  /** insert data into the table: "candidate_embedding" */
  insert_candidate_embedding: Maybe<Candidate_Embedding_Mutation_Response>;
  /** insert a single row into the table: "candidate_embedding" */
  insert_candidate_embedding_one: Maybe<Candidate_Embedding>;
  /** insert data into the table: "candidate_highlight_choices" */
  insert_candidate_highlight_choices: Maybe<Candidate_Highlight_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_highlight_choices" */
  insert_candidate_highlight_choices_one: Maybe<Candidate_Highlight_Choices>;
  /** insert data into the table: "candidate_hub_location" */
  insert_candidate_hub_location: Maybe<Candidate_Hub_Location_Mutation_Response>;
  /** insert a single row into the table: "candidate_hub_location" */
  insert_candidate_hub_location_one: Maybe<Candidate_Hub_Location>;
  /** insert data into the table: "candidate_interview_invite" */
  insert_candidate_interview_invite: Maybe<Candidate_Interview_Invite_Mutation_Response>;
  /** insert a single row into the table: "candidate_interview_invite" */
  insert_candidate_interview_invite_one: Maybe<Candidate_Interview_Invite>;
  /** insert data into the table: "candidate_job" */
  insert_candidate_job: Maybe<Candidate_Job_Mutation_Response>;
  /** insert a single row into the table: "candidate_job" */
  insert_candidate_job_one: Maybe<Candidate_Job>;
  /** insert data into the table: "candidate_job_workflow" */
  insert_candidate_job_workflow: Maybe<Candidate_Job_Workflow_Mutation_Response>;
  /** insert a single row into the table: "candidate_job_workflow" */
  insert_candidate_job_workflow_one: Maybe<Candidate_Job_Workflow>;
  /** insert data into the table: "candidate_job_workflow_source_choices" */
  insert_candidate_job_workflow_source_choices: Maybe<Candidate_Job_Workflow_Source_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_job_workflow_source_choices" */
  insert_candidate_job_workflow_source_choices_one: Maybe<Candidate_Job_Workflow_Source_Choices>;
  /** insert data into the table: "candidate_job_workflow_status" */
  insert_candidate_job_workflow_status: Maybe<Candidate_Job_Workflow_Status_Mutation_Response>;
  /** insert data into the table: "candidate_job_workflow_status_choices" */
  insert_candidate_job_workflow_status_choices: Maybe<Candidate_Job_Workflow_Status_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_job_workflow_status_choices" */
  insert_candidate_job_workflow_status_choices_one: Maybe<Candidate_Job_Workflow_Status_Choices>;
  /** insert a single row into the table: "candidate_job_workflow_status" */
  insert_candidate_job_workflow_status_one: Maybe<Candidate_Job_Workflow_Status>;
  /** insert a single row into the table: "candidate" */
  insert_candidate_one: Maybe<Candidate>;
  /** insert data into the table: "candidate_parsed_resume" */
  insert_candidate_parsed_resume: Maybe<Candidate_Parsed_Resume_Mutation_Response>;
  /** insert a single row into the table: "candidate_parsed_resume" */
  insert_candidate_parsed_resume_one: Maybe<Candidate_Parsed_Resume>;
  /** insert data into the table: "candidate_pdl" */
  insert_candidate_pdl: Maybe<Candidate_Pdl_Mutation_Response>;
  /** insert a single row into the table: "candidate_pdl" */
  insert_candidate_pdl_one: Maybe<Candidate_Pdl>;
  /** insert data into the table: "candidate_referrals" */
  insert_candidate_referrals: Maybe<Candidate_Referrals_Mutation_Response>;
  /** insert a single row into the table: "candidate_referrals" */
  insert_candidate_referrals_one: Maybe<Candidate_Referrals>;
  /** insert data into the table: "candidate_role" */
  insert_candidate_role: Maybe<Candidate_Role_Mutation_Response>;
  /** insert a single row into the table: "candidate_role" */
  insert_candidate_role_one: Maybe<Candidate_Role>;
  /** insert data into the table: "candidate_role_skill" */
  insert_candidate_role_skill: Maybe<Candidate_Role_Skill_Mutation_Response>;
  /** insert a single row into the table: "candidate_role_skill" */
  insert_candidate_role_skill_one: Maybe<Candidate_Role_Skill>;
  /** insert data into the table: "candidate_sdlc_experience_choices" */
  insert_candidate_sdlc_experience_choices: Maybe<Candidate_Sdlc_Experience_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_sdlc_experience_choices" */
  insert_candidate_sdlc_experience_choices_one: Maybe<Candidate_Sdlc_Experience_Choices>;
  /** insert data into the table: "candidate_skill" */
  insert_candidate_skill: Maybe<Candidate_Skill_Mutation_Response>;
  /** insert data into the table: "candidate_skill_comp_choices" */
  insert_candidate_skill_comp_choices: Maybe<Candidate_Skill_Comp_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_skill_comp_choices" */
  insert_candidate_skill_comp_choices_one: Maybe<Candidate_Skill_Comp_Choices>;
  /** insert a single row into the table: "candidate_skill" */
  insert_candidate_skill_one: Maybe<Candidate_Skill>;
  /** insert data into the table: "candidate_source_choices" */
  insert_candidate_source_choices: Maybe<Candidate_Source_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_source_choices" */
  insert_candidate_source_choices_one: Maybe<Candidate_Source_Choices>;
  /** insert data into the table: "candidate_stage_choices" */
  insert_candidate_stage_choices: Maybe<Candidate_Stage_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_stage_choices" */
  insert_candidate_stage_choices_one: Maybe<Candidate_Stage_Choices>;
  /** insert data into the table: "candidate_stage_choices_order" */
  insert_candidate_stage_choices_order: Maybe<Candidate_Stage_Choices_Order_Mutation_Response>;
  /** insert a single row into the table: "candidate_stage_choices_order" */
  insert_candidate_stage_choices_order_one: Maybe<Candidate_Stage_Choices_Order>;
  /** insert data into the table: "candidate_stage_log" */
  insert_candidate_stage_log: Maybe<Candidate_Stage_Log_Mutation_Response>;
  /** insert a single row into the table: "candidate_stage_log" */
  insert_candidate_stage_log_one: Maybe<Candidate_Stage_Log>;
  /** insert data into the table: "candidate_stage_source_choices" */
  insert_candidate_stage_source_choices: Maybe<Candidate_Stage_Source_Choices_Mutation_Response>;
  /** insert a single row into the table: "candidate_stage_source_choices" */
  insert_candidate_stage_source_choices_one: Maybe<Candidate_Stage_Source_Choices>;
  /** insert data into the table: "candidate_trait" */
  insert_candidate_trait: Maybe<Candidate_Trait_Mutation_Response>;
  /** insert a single row into the table: "candidate_trait" */
  insert_candidate_trait_one: Maybe<Candidate_Trait>;
  /** insert data into the table: "candidate_work_experience" */
  insert_candidate_work_experience: Maybe<Candidate_Work_Experience_Mutation_Response>;
  /** insert a single row into the table: "candidate_work_experience" */
  insert_candidate_work_experience_one: Maybe<Candidate_Work_Experience>;
  /** insert data into the table: "candidate_work_experience_skill" */
  insert_candidate_work_experience_skill: Maybe<Candidate_Work_Experience_Skill_Mutation_Response>;
  /** insert a single row into the table: "candidate_work_experience_skill" */
  insert_candidate_work_experience_skill_one: Maybe<Candidate_Work_Experience_Skill>;
  /** insert data into the table: "candidates_in_interview_or_rejected_by_organization_result" */
  insert_candidates_in_interview_or_rejected_by_organization_result: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Mutation_Response>;
  /** insert a single row into the table: "candidates_in_interview_or_rejected_by_organization_result" */
  insert_candidates_in_interview_or_rejected_by_organization_result_one: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result>;
  /** insert data into the table: "celery.celery_taskmeta" */
  insert_celery_celery_taskmeta: Maybe<Celery_Celery_Taskmeta_Mutation_Response>;
  /** insert a single row into the table: "celery.celery_taskmeta" */
  insert_celery_celery_taskmeta_one: Maybe<Celery_Celery_Taskmeta>;
  /** insert data into the table: "celery.celery_tasksetmeta" */
  insert_celery_celery_tasksetmeta: Maybe<Celery_Celery_Tasksetmeta_Mutation_Response>;
  /** insert a single row into the table: "celery.celery_tasksetmeta" */
  insert_celery_celery_tasksetmeta_one: Maybe<Celery_Celery_Tasksetmeta>;
  /** insert data into the table: "celery.task_retry_log" */
  insert_celery_task_retry_log: Maybe<Celery_Task_Retry_Log_Mutation_Response>;
  /** insert a single row into the table: "celery.task_retry_log" */
  insert_celery_task_retry_log_one: Maybe<Celery_Task_Retry_Log>;
  /** insert data into the table: "client_prospect" */
  insert_client_prospect: Maybe<Client_Prospect_Mutation_Response>;
  /** insert data into the table: "client_prospect_event" */
  insert_client_prospect_event: Maybe<Client_Prospect_Event_Mutation_Response>;
  /** insert a single row into the table: "client_prospect_event" */
  insert_client_prospect_event_one: Maybe<Client_Prospect_Event>;
  /** insert data into the table: "client_prospect_events_choices" */
  insert_client_prospect_events_choices: Maybe<Client_Prospect_Events_Choices_Mutation_Response>;
  /** insert a single row into the table: "client_prospect_events_choices" */
  insert_client_prospect_events_choices_one: Maybe<Client_Prospect_Events_Choices>;
  /** insert a single row into the table: "client_prospect" */
  insert_client_prospect_one: Maybe<Client_Prospect>;
  /** insert data into the table: "client_prospect_role" */
  insert_client_prospect_role: Maybe<Client_Prospect_Role_Mutation_Response>;
  /** insert a single row into the table: "client_prospect_role" */
  insert_client_prospect_role_one: Maybe<Client_Prospect_Role>;
  /** insert data into the table: "client_prospect_role_skill" */
  insert_client_prospect_role_skill: Maybe<Client_Prospect_Role_Skill_Mutation_Response>;
  /** insert a single row into the table: "client_prospect_role_skill" */
  insert_client_prospect_role_skill_one: Maybe<Client_Prospect_Role_Skill>;
  /** insert data into the table: "client_versions" */
  insert_client_versions: Maybe<Client_Versions_Mutation_Response>;
  /** insert a single row into the table: "client_versions" */
  insert_client_versions_one: Maybe<Client_Versions>;
  /** insert data into the table: "company" */
  insert_company: Maybe<Company_Mutation_Response>;
  /** insert data into the table: "company_name_synonym" */
  insert_company_name_synonym: Maybe<Company_Name_Synonym_Mutation_Response>;
  /** insert a single row into the table: "company_name_synonym" */
  insert_company_name_synonym_one: Maybe<Company_Name_Synonym>;
  /** insert a single row into the table: "company" */
  insert_company_one: Maybe<Company>;
  /** insert data into the table: "company_pdl_blob" */
  insert_company_pdl_blob: Maybe<Company_Pdl_Blob_Mutation_Response>;
  /** insert a single row into the table: "company_pdl_blob" */
  insert_company_pdl_blob_one: Maybe<Company_Pdl_Blob>;
  /** insert data into the table: "company_size_choices" */
  insert_company_size_choices: Maybe<Company_Size_Choices_Mutation_Response>;
  /** insert a single row into the table: "company_size_choices" */
  insert_company_size_choices_one: Maybe<Company_Size_Choices>;
  /** insert data into the table: "contact" */
  insert_contact: Maybe<Contact_Mutation_Response>;
  /** insert a single row into the table: "contact" */
  insert_contact_one: Maybe<Contact>;
  /** insert data into the table: "contract_length_choices" */
  insert_contract_length_choices: Maybe<Contract_Length_Choices_Mutation_Response>;
  /** insert a single row into the table: "contract_length_choices" */
  insert_contract_length_choices_one: Maybe<Contract_Length_Choices>;
  /** insert data into the table: "country_choices" */
  insert_country_choices: Maybe<Country_Choices_Mutation_Response>;
  /** insert a single row into the table: "country_choices" */
  insert_country_choices_one: Maybe<Country_Choices>;
  /** insert data into the table: "currency_exchange_rate" */
  insert_currency_exchange_rate: Maybe<Currency_Exchange_Rate_Mutation_Response>;
  /** insert a single row into the table: "currency_exchange_rate" */
  insert_currency_exchange_rate_one: Maybe<Currency_Exchange_Rate>;
  /** insert data into the table: "dayforce_assignment" */
  insert_dayforce_assignment: Maybe<Dayforce_Assignment_Mutation_Response>;
  /** insert a single row into the table: "dayforce_assignment" */
  insert_dayforce_assignment_one: Maybe<Dayforce_Assignment>;
  /** insert data into the table: "dayforce_customer" */
  insert_dayforce_customer: Maybe<Dayforce_Customer_Mutation_Response>;
  /** insert a single row into the table: "dayforce_customer" */
  insert_dayforce_customer_one: Maybe<Dayforce_Customer>;
  /** insert data into the table: "dayforce_employee" */
  insert_dayforce_employee: Maybe<Dayforce_Employee_Mutation_Response>;
  /** insert a single row into the table: "dayforce_employee" */
  insert_dayforce_employee_one: Maybe<Dayforce_Employee>;
  /** insert data into the table: "dayforce_location" */
  insert_dayforce_location: Maybe<Dayforce_Location_Mutation_Response>;
  /** insert a single row into the table: "dayforce_location" */
  insert_dayforce_location_one: Maybe<Dayforce_Location>;
  /** insert data into the table: "email_notification" */
  insert_email_notification: Maybe<Email_Notification_Mutation_Response>;
  /** insert a single row into the table: "email_notification" */
  insert_email_notification_one: Maybe<Email_Notification>;
  /** insert data into the table: "email_template" */
  insert_email_template: Maybe<Email_Template_Mutation_Response>;
  /** insert a single row into the table: "email_template" */
  insert_email_template_one: Maybe<Email_Template>;
  /** insert data into the table: "employment_type_choices" */
  insert_employment_type_choices: Maybe<Employment_Type_Choices_Mutation_Response>;
  /** insert a single row into the table: "employment_type_choices" */
  insert_employment_type_choices_one: Maybe<Employment_Type_Choices>;
  /** insert data into the table: "explore_candidates_popular_search" */
  insert_explore_candidates_popular_search: Maybe<Explore_Candidates_Popular_Search_Mutation_Response>;
  /** insert a single row into the table: "explore_candidates_popular_search" */
  insert_explore_candidates_popular_search_one: Maybe<Explore_Candidates_Popular_Search>;
  /** insert data into the table: "explore_candidates_query_result" */
  insert_explore_candidates_query_result: Maybe<Explore_Candidates_Query_Result_Mutation_Response>;
  /** insert a single row into the table: "explore_candidates_query_result" */
  insert_explore_candidates_query_result_one: Maybe<Explore_Candidates_Query_Result>;
  /** insert data into the table: "explore_candidates_search" */
  insert_explore_candidates_search: Maybe<Explore_Candidates_Search_Mutation_Response>;
  /** insert data into the table: "explore_candidates_search_candidate_highlight" */
  insert_explore_candidates_search_candidate_highlight: Maybe<Explore_Candidates_Search_Candidate_Highlight_Mutation_Response>;
  /** insert a single row into the table: "explore_candidates_search_candidate_highlight" */
  insert_explore_candidates_search_candidate_highlight_one: Maybe<Explore_Candidates_Search_Candidate_Highlight>;
  /** insert data into the table: "explore_candidates_search_must_have_skill" */
  insert_explore_candidates_search_must_have_skill: Maybe<Explore_Candidates_Search_Must_Have_Skill_Mutation_Response>;
  /** insert a single row into the table: "explore_candidates_search_must_have_skill" */
  insert_explore_candidates_search_must_have_skill_one: Maybe<Explore_Candidates_Search_Must_Have_Skill>;
  /** insert data into the table: "explore_candidates_search_nice_to_have_skill" */
  insert_explore_candidates_search_nice_to_have_skill: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Mutation_Response>;
  /** insert a single row into the table: "explore_candidates_search_nice_to_have_skill" */
  insert_explore_candidates_search_nice_to_have_skill_one: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill>;
  /** insert a single row into the table: "explore_candidates_search" */
  insert_explore_candidates_search_one: Maybe<Explore_Candidates_Search>;
  /** insert data into the table: "explore_candidates_search_region" */
  insert_explore_candidates_search_region: Maybe<Explore_Candidates_Search_Region_Mutation_Response>;
  /** insert a single row into the table: "explore_candidates_search_region" */
  insert_explore_candidates_search_region_one: Maybe<Explore_Candidates_Search_Region>;
  /** insert data into the table: "explore_candidates_user_saved_search" */
  insert_explore_candidates_user_saved_search: Maybe<Explore_Candidates_User_Saved_Search_Mutation_Response>;
  /** insert a single row into the table: "explore_candidates_user_saved_search" */
  insert_explore_candidates_user_saved_search_one: Maybe<Explore_Candidates_User_Saved_Search>;
  /** insert data into the table: "external_candidate_meta" */
  insert_external_candidate_meta: Maybe<External_Candidate_Meta_Mutation_Response>;
  /** insert a single row into the table: "external_candidate_meta" */
  insert_external_candidate_meta_one: Maybe<External_Candidate_Meta>;
  /** insert data into the table: "feature" */
  insert_feature: Maybe<Feature_Mutation_Response>;
  /** insert a single row into the table: "feature" */
  insert_feature_one: Maybe<Feature>;
  /** insert data into the table: "hireflix_transcript" */
  insert_hireflix_transcript: Maybe<Hireflix_Transcript_Mutation_Response>;
  /** insert data into the table: "hireflix_transcript_blob" */
  insert_hireflix_transcript_blob: Maybe<Hireflix_Transcript_Blob_Mutation_Response>;
  /** insert a single row into the table: "hireflix_transcript_blob" */
  insert_hireflix_transcript_blob_one: Maybe<Hireflix_Transcript_Blob>;
  /** insert a single row into the table: "hireflix_transcript" */
  insert_hireflix_transcript_one: Maybe<Hireflix_Transcript>;
  /** insert data into the table: "hr_test" */
  insert_hr_test: Maybe<Hr_Test_Mutation_Response>;
  /** insert data into the table: "hr_test_candidate" */
  insert_hr_test_candidate: Maybe<Hr_Test_Candidate_Mutation_Response>;
  /** insert a single row into the table: "hr_test_candidate" */
  insert_hr_test_candidate_one: Maybe<Hr_Test_Candidate>;
  /** insert a single row into the table: "hr_test" */
  insert_hr_test_one: Maybe<Hr_Test>;
  /** insert data into the table: "hr_test_result" */
  insert_hr_test_result: Maybe<Hr_Test_Result_Mutation_Response>;
  /** insert a single row into the table: "hr_test_result" */
  insert_hr_test_result_one: Maybe<Hr_Test_Result>;
  /** insert data into the table: "icims_applicant_workflow" */
  insert_icims_applicant_workflow: Maybe<Icims_Applicant_Workflow_Mutation_Response>;
  /** insert data into the table: "icims_applicant_workflow_feedback" */
  insert_icims_applicant_workflow_feedback: Maybe<Icims_Applicant_Workflow_Feedback_Mutation_Response>;
  /** insert a single row into the table: "icims_applicant_workflow_feedback" */
  insert_icims_applicant_workflow_feedback_one: Maybe<Icims_Applicant_Workflow_Feedback>;
  /** insert a single row into the table: "icims_applicant_workflow" */
  insert_icims_applicant_workflow_one: Maybe<Icims_Applicant_Workflow>;
  /** insert data into the table: "icims_applicant_workflow_status" */
  insert_icims_applicant_workflow_status: Maybe<Icims_Applicant_Workflow_Status_Mutation_Response>;
  /** insert a single row into the table: "icims_applicant_workflow_status" */
  insert_icims_applicant_workflow_status_one: Maybe<Icims_Applicant_Workflow_Status>;
  /** insert data into the table: "icims_company" */
  insert_icims_company: Maybe<Icims_Company_Mutation_Response>;
  /** insert a single row into the table: "icims_company" */
  insert_icims_company_one: Maybe<Icims_Company>;
  /** insert data into the table: "icims_country" */
  insert_icims_country: Maybe<Icims_Country_Mutation_Response>;
  /** insert a single row into the table: "icims_country" */
  insert_icims_country_one: Maybe<Icims_Country>;
  /** insert data into the table: "icims_degree" */
  insert_icims_degree: Maybe<Icims_Degree_Mutation_Response>;
  /** insert a single row into the table: "icims_degree" */
  insert_icims_degree_one: Maybe<Icims_Degree>;
  /** insert data into the table: "icims_education" */
  insert_icims_education: Maybe<Icims_Education_Mutation_Response>;
  /** insert a single row into the table: "icims_education" */
  insert_icims_education_one: Maybe<Icims_Education>;
  /** insert data into the table: "icims_folder" */
  insert_icims_folder: Maybe<Icims_Folder_Mutation_Response>;
  /** insert a single row into the table: "icims_folder" */
  insert_icims_folder_one: Maybe<Icims_Folder>;
  /** insert data into the table: "icims_initial_data_transfer" */
  insert_icims_initial_data_transfer: Maybe<Icims_Initial_Data_Transfer_Mutation_Response>;
  /** insert a single row into the table: "icims_initial_data_transfer" */
  insert_icims_initial_data_transfer_one: Maybe<Icims_Initial_Data_Transfer>;
  /** insert data into the table: "icims_interested_location" */
  insert_icims_interested_location: Maybe<Icims_Interested_Location_Mutation_Response>;
  /** insert a single row into the table: "icims_interested_location" */
  insert_icims_interested_location_one: Maybe<Icims_Interested_Location>;
  /** insert data into the table: "icims_job" */
  insert_icims_job: Maybe<Icims_Job_Mutation_Response>;
  /** insert data into the table: "icims_job_location" */
  insert_icims_job_location: Maybe<Icims_Job_Location_Mutation_Response>;
  /** insert a single row into the table: "icims_job_location" */
  insert_icims_job_location_one: Maybe<Icims_Job_Location>;
  /** insert a single row into the table: "icims_job" */
  insert_icims_job_one: Maybe<Icims_Job>;
  /** insert data into the table: "icims_job_requested_location" */
  insert_icims_job_requested_location: Maybe<Icims_Job_Requested_Location_Mutation_Response>;
  /** insert a single row into the table: "icims_job_requested_location" */
  insert_icims_job_requested_location_one: Maybe<Icims_Job_Requested_Location>;
  /** insert data into the table: "icims_job_skill" */
  insert_icims_job_skill: Maybe<Icims_Job_Skill_Mutation_Response>;
  /** insert a single row into the table: "icims_job_skill" */
  insert_icims_job_skill_one: Maybe<Icims_Job_Skill>;
  /** insert data into the table: "icims_location" */
  insert_icims_location: Maybe<Icims_Location_Mutation_Response>;
  /** insert a single row into the table: "icims_location" */
  insert_icims_location_one: Maybe<Icims_Location>;
  /** insert data into the table: "icims_major" */
  insert_icims_major: Maybe<Icims_Major_Mutation_Response>;
  /** insert a single row into the table: "icims_major" */
  insert_icims_major_one: Maybe<Icims_Major>;
  /** insert data into the table: "icims_person" */
  insert_icims_person: Maybe<Icims_Person_Mutation_Response>;
  /** insert data into the table: "icims_person_interested_location" */
  insert_icims_person_interested_location: Maybe<Icims_Person_Interested_Location_Mutation_Response>;
  /** insert a single row into the table: "icims_person_interested_location" */
  insert_icims_person_interested_location_one: Maybe<Icims_Person_Interested_Location>;
  /** insert a single row into the table: "icims_person" */
  insert_icims_person_one: Maybe<Icims_Person>;
  /** insert data into the table: "icims_person_preferred_location" */
  insert_icims_person_preferred_location: Maybe<Icims_Person_Preferred_Location_Mutation_Response>;
  /** insert a single row into the table: "icims_person_preferred_location" */
  insert_icims_person_preferred_location_one: Maybe<Icims_Person_Preferred_Location>;
  /** insert data into the table: "icims_person_skill" */
  insert_icims_person_skill: Maybe<Icims_Person_Skill_Mutation_Response>;
  /** insert a single row into the table: "icims_person_skill" */
  insert_icims_person_skill_one: Maybe<Icims_Person_Skill>;
  /** insert data into the table: "icims_profile_applicant_workflow" */
  insert_icims_profile_applicant_workflow: Maybe<Icims_Profile_Applicant_Workflow_Mutation_Response>;
  /** insert a single row into the table: "icims_profile_applicant_workflow" */
  insert_icims_profile_applicant_workflow_one: Maybe<Icims_Profile_Applicant_Workflow>;
  /** insert data into the table: "icims_profile_job" */
  insert_icims_profile_job: Maybe<Icims_Profile_Job_Mutation_Response>;
  /** insert a single row into the table: "icims_profile_job" */
  insert_icims_profile_job_one: Maybe<Icims_Profile_Job>;
  /** insert data into the table: "icims_profile_location" */
  insert_icims_profile_location: Maybe<Icims_Profile_Location_Mutation_Response>;
  /** insert a single row into the table: "icims_profile_location" */
  insert_icims_profile_location_one: Maybe<Icims_Profile_Location>;
  /** insert data into the table: "icims_profile_person" */
  insert_icims_profile_person: Maybe<Icims_Profile_Person_Mutation_Response>;
  /** insert a single row into the table: "icims_profile_person" */
  insert_icims_profile_person_one: Maybe<Icims_Profile_Person>;
  /** insert data into the table: "icims_profile_requested_location" */
  insert_icims_profile_requested_location: Maybe<Icims_Profile_Requested_Location_Mutation_Response>;
  /** insert a single row into the table: "icims_profile_requested_location" */
  insert_icims_profile_requested_location_one: Maybe<Icims_Profile_Requested_Location>;
  /** insert data into the table: "icims_requested_location" */
  insert_icims_requested_location: Maybe<Icims_Requested_Location_Mutation_Response>;
  /** insert a single row into the table: "icims_requested_location" */
  insert_icims_requested_location_one: Maybe<Icims_Requested_Location>;
  /** insert data into the table: "icims_school" */
  insert_icims_school: Maybe<Icims_School_Mutation_Response>;
  /** insert a single row into the table: "icims_school" */
  insert_icims_school_one: Maybe<Icims_School>;
  /** insert data into the table: "icims_skill" */
  insert_icims_skill: Maybe<Icims_Skill_Mutation_Response>;
  /** insert a single row into the table: "icims_skill" */
  insert_icims_skill_one: Maybe<Icims_Skill>;
  /** insert data into the table: "icims_status" */
  insert_icims_status: Maybe<Icims_Status_Mutation_Response>;
  /** insert a single row into the table: "icims_status" */
  insert_icims_status_one: Maybe<Icims_Status>;
  /** insert data into the table: "icims_sync_status_choices" */
  insert_icims_sync_status_choices: Maybe<Icims_Sync_Status_Choices_Mutation_Response>;
  /** insert a single row into the table: "icims_sync_status_choices" */
  insert_icims_sync_status_choices_one: Maybe<Icims_Sync_Status_Choices>;
  /** insert data into the table: "icims_work_experience" */
  insert_icims_work_experience: Maybe<Icims_Work_Experience_Mutation_Response>;
  /** insert a single row into the table: "icims_work_experience" */
  insert_icims_work_experience_one: Maybe<Icims_Work_Experience>;
  /** insert data into the table: "industry" */
  insert_industry: Maybe<Industry_Mutation_Response>;
  /** insert a single row into the table: "industry" */
  insert_industry_one: Maybe<Industry>;
  /** insert data into the table: "initial_sell_type_choices" */
  insert_initial_sell_type_choices: Maybe<Initial_Sell_Type_Choices_Mutation_Response>;
  /** insert a single row into the table: "initial_sell_type_choices" */
  insert_initial_sell_type_choices_one: Maybe<Initial_Sell_Type_Choices>;
  /** insert data into the table: "interview_detail" */
  insert_interview_detail: Maybe<Interview_Detail_Mutation_Response>;
  /** insert a single row into the table: "interview_detail" */
  insert_interview_detail_one: Maybe<Interview_Detail>;
  /** insert data into the table: "interview_feedback" */
  insert_interview_feedback: Maybe<Interview_Feedback_Mutation_Response>;
  /** insert a single row into the table: "interview_feedback" */
  insert_interview_feedback_one: Maybe<Interview_Feedback>;
  /** insert data into the table: "interview_feedback_rating_choices" */
  insert_interview_feedback_rating_choices: Maybe<Interview_Feedback_Rating_Choices_Mutation_Response>;
  /** insert a single row into the table: "interview_feedback_rating_choices" */
  insert_interview_feedback_rating_choices_one: Maybe<Interview_Feedback_Rating_Choices>;
  /** insert data into the table: "interview_feedback_request" */
  insert_interview_feedback_request: Maybe<Interview_Feedback_Request_Mutation_Response>;
  /** insert a single row into the table: "interview_feedback_request" */
  insert_interview_feedback_request_one: Maybe<Interview_Feedback_Request>;
  /** insert data into the table: "interview_request" */
  insert_interview_request: Maybe<Interview_Request_Mutation_Response>;
  /** insert a single row into the table: "interview_request" */
  insert_interview_request_one: Maybe<Interview_Request>;
  /** insert data into the table: "interview_type_choices" */
  insert_interview_type_choices: Maybe<Interview_Type_Choices_Mutation_Response>;
  /** insert a single row into the table: "interview_type_choices" */
  insert_interview_type_choices_one: Maybe<Interview_Type_Choices>;
  /** insert data into the table: "job" */
  insert_job: Maybe<Job_Mutation_Response>;
  /** insert data into the table: "job_acceptable_location" */
  insert_job_acceptable_location: Maybe<Job_Acceptable_Location_Mutation_Response>;
  /** insert a single row into the table: "job_acceptable_location" */
  insert_job_acceptable_location_one: Maybe<Job_Acceptable_Location>;
  /** insert data into the table: "job_code" */
  insert_job_code: Maybe<Job_Code_Mutation_Response>;
  /** insert a single row into the table: "job_code" */
  insert_job_code_one: Maybe<Job_Code>;
  /** insert data into the table: "job_level_choices" */
  insert_job_level_choices: Maybe<Job_Level_Choices_Mutation_Response>;
  /** insert a single row into the table: "job_level_choices" */
  insert_job_level_choices_one: Maybe<Job_Level_Choices>;
  /** insert data into the table: "job_meta_info" */
  insert_job_meta_info: Maybe<Job_Meta_Info_Mutation_Response>;
  /** insert a single row into the table: "job_meta_info" */
  insert_job_meta_info_one: Maybe<Job_Meta_Info>;
  /** insert data into the table: "job_nice_to_have_skill" */
  insert_job_nice_to_have_skill: Maybe<Job_Nice_To_Have_Skill_Mutation_Response>;
  /** insert a single row into the table: "job_nice_to_have_skill" */
  insert_job_nice_to_have_skill_one: Maybe<Job_Nice_To_Have_Skill>;
  /** insert a single row into the table: "job" */
  insert_job_one: Maybe<Job>;
  /** insert data into the table: "job_position" */
  insert_job_position: Maybe<Job_Position_Mutation_Response>;
  /** insert a single row into the table: "job_position" */
  insert_job_position_one: Maybe<Job_Position>;
  /** insert data into the table: "job_priority_choices" */
  insert_job_priority_choices: Maybe<Job_Priority_Choices_Mutation_Response>;
  /** insert a single row into the table: "job_priority_choices" */
  insert_job_priority_choices_one: Maybe<Job_Priority_Choices>;
  /** insert data into the table: "job_required_skill" */
  insert_job_required_skill: Maybe<Job_Required_Skill_Mutation_Response>;
  /** insert a single row into the table: "job_required_skill" */
  insert_job_required_skill_one: Maybe<Job_Required_Skill>;
  /** insert data into the table: "job_role_source_choices" */
  insert_job_role_source_choices: Maybe<Job_Role_Source_Choices_Mutation_Response>;
  /** insert a single row into the table: "job_role_source_choices" */
  insert_job_role_source_choices_one: Maybe<Job_Role_Source_Choices>;
  /** insert data into the table: "job_service_type" */
  insert_job_service_type: Maybe<Job_Service_Type_Mutation_Response>;
  /** insert a single row into the table: "job_service_type" */
  insert_job_service_type_one: Maybe<Job_Service_Type>;
  /** insert data into the table: "job_status_choices" */
  insert_job_status_choices: Maybe<Job_Status_Choices_Mutation_Response>;
  /** insert a single row into the table: "job_status_choices" */
  insert_job_status_choices_one: Maybe<Job_Status_Choices>;
  /** insert data into the table: "job_status_choices_order" */
  insert_job_status_choices_order: Maybe<Job_Status_Choices_Order_Mutation_Response>;
  /** insert a single row into the table: "job_status_choices_order" */
  insert_job_status_choices_order_one: Maybe<Job_Status_Choices_Order>;
  /** insert data into the table: "job_status_log" */
  insert_job_status_log: Maybe<Job_Status_Log_Mutation_Response>;
  /** insert a single row into the table: "job_status_log" */
  insert_job_status_log_one: Maybe<Job_Status_Log>;
  /** insert data into the table: "job_tech_stack" */
  insert_job_tech_stack: Maybe<Job_Tech_Stack_Mutation_Response>;
  /** insert a single row into the table: "job_tech_stack" */
  insert_job_tech_stack_one: Maybe<Job_Tech_Stack>;
  /** insert data into the table: "linkedin_contact" */
  insert_linkedin_contact: Maybe<Linkedin_Contact_Mutation_Response>;
  /** insert a single row into the table: "linkedin_contact" */
  insert_linkedin_contact_one: Maybe<Linkedin_Contact>;
  /** insert data into the table: "location" */
  insert_location: Maybe<Location_Mutation_Response>;
  /** insert a single row into the table: "location" */
  insert_location_one: Maybe<Location>;
  /** insert data into the table: "manual_contact" */
  insert_manual_contact: Maybe<Manual_Contact_Mutation_Response>;
  /** insert a single row into the table: "manual_contact" */
  insert_manual_contact_one: Maybe<Manual_Contact>;
  /** insert data into the table: "merge_ats_application" */
  insert_merge_ats_application: Maybe<Merge_Ats_Application_Mutation_Response>;
  /** insert data into the table: "merge_ats_application_log" */
  insert_merge_ats_application_log: Maybe<Merge_Ats_Application_Log_Mutation_Response>;
  /** insert a single row into the table: "merge_ats_application_log" */
  insert_merge_ats_application_log_one: Maybe<Merge_Ats_Application_Log>;
  /** insert a single row into the table: "merge_ats_application" */
  insert_merge_ats_application_one: Maybe<Merge_Ats_Application>;
  /** insert data into the table: "merge_ats_candidate" */
  insert_merge_ats_candidate: Maybe<Merge_Ats_Candidate_Mutation_Response>;
  /** insert a single row into the table: "merge_ats_candidate" */
  insert_merge_ats_candidate_one: Maybe<Merge_Ats_Candidate>;
  /** insert data into the table: "merge_ats_job" */
  insert_merge_ats_job: Maybe<Merge_Ats_Job_Mutation_Response>;
  /** insert data into the table: "merge_ats_job_interview_stage" */
  insert_merge_ats_job_interview_stage: Maybe<Merge_Ats_Job_Interview_Stage_Mutation_Response>;
  /** insert a single row into the table: "merge_ats_job_interview_stage" */
  insert_merge_ats_job_interview_stage_one: Maybe<Merge_Ats_Job_Interview_Stage>;
  /** insert a single row into the table: "merge_ats_job" */
  insert_merge_ats_job_one: Maybe<Merge_Ats_Job>;
  /** insert data into the table: "merge_ats_linked_account" */
  insert_merge_ats_linked_account: Maybe<Merge_Ats_Linked_Account_Mutation_Response>;
  /** insert a single row into the table: "merge_ats_linked_account" */
  insert_merge_ats_linked_account_one: Maybe<Merge_Ats_Linked_Account>;
  /** insert data into the table: "mixpanel.profiles" */
  insert_mixpanel_profiles: Maybe<Mixpanel_Profiles_Mutation_Response>;
  /** insert a single row into the table: "mixpanel.profiles" */
  insert_mixpanel_profiles_one: Maybe<Mixpanel_Profiles>;
  /** insert data into the table: "offering_selection_choices" */
  insert_offering_selection_choices: Maybe<Offering_Selection_Choices_Mutation_Response>;
  /** insert a single row into the table: "offering_selection_choices" */
  insert_offering_selection_choices_one: Maybe<Offering_Selection_Choices>;
  /** insert data into the table: "organization" */
  insert_organization: Maybe<Organization_Mutation_Response>;
  /** insert data into the table: "organization_contact" */
  insert_organization_contact: Maybe<Organization_Contact_Mutation_Response>;
  /** insert a single row into the table: "organization_contact" */
  insert_organization_contact_one: Maybe<Organization_Contact>;
  /** insert data into the table: "organization_domain" */
  insert_organization_domain: Maybe<Organization_Domain_Mutation_Response>;
  /** insert a single row into the table: "organization_domain" */
  insert_organization_domain_one: Maybe<Organization_Domain>;
  /** insert data into the table: "organization_employee" */
  insert_organization_employee: Maybe<Organization_Employee_Mutation_Response>;
  /** insert a single row into the table: "organization_employee" */
  insert_organization_employee_one: Maybe<Organization_Employee>;
  /** insert data into the table: "organization_icims_jobs_result" */
  insert_organization_icims_jobs_result: Maybe<Organization_Icims_Jobs_Result_Mutation_Response>;
  /** insert a single row into the table: "organization_icims_jobs_result" */
  insert_organization_icims_jobs_result_one: Maybe<Organization_Icims_Jobs_Result>;
  /** insert data into the table: "organization_leadership" */
  insert_organization_leadership: Maybe<Organization_Leadership_Mutation_Response>;
  /** insert a single row into the table: "organization_leadership" */
  insert_organization_leadership_one: Maybe<Organization_Leadership>;
  /** insert a single row into the table: "organization" */
  insert_organization_one: Maybe<Organization>;
  /** insert data into the table: "organization_type_choices" */
  insert_organization_type_choices: Maybe<Organization_Type_Choices_Mutation_Response>;
  /** insert a single row into the table: "organization_type_choices" */
  insert_organization_type_choices_one: Maybe<Organization_Type_Choices>;
  /** insert data into the table: "organization_video" */
  insert_organization_video: Maybe<Organization_Video_Mutation_Response>;
  /** insert a single row into the table: "organization_video" */
  insert_organization_video_one: Maybe<Organization_Video>;
  /** insert data into the table: "pillar_transcript" */
  insert_pillar_transcript: Maybe<Pillar_Transcript_Mutation_Response>;
  /** insert data into the table: "pillar_transcript_blob" */
  insert_pillar_transcript_blob: Maybe<Pillar_Transcript_Blob_Mutation_Response>;
  /** insert a single row into the table: "pillar_transcript_blob" */
  insert_pillar_transcript_blob_one: Maybe<Pillar_Transcript_Blob>;
  /** insert a single row into the table: "pillar_transcript" */
  insert_pillar_transcript_one: Maybe<Pillar_Transcript>;
  /** insert data into the table: "recruiter_contact_action" */
  insert_recruiter_contact_action: Maybe<Recruiter_Contact_Action_Mutation_Response>;
  /** insert a single row into the table: "recruiter_contact_action" */
  insert_recruiter_contact_action_one: Maybe<Recruiter_Contact_Action>;
  /** insert data into the table: "referral_status" */
  insert_referral_status: Maybe<Referral_Status_Mutation_Response>;
  /** insert data into the table: "referral_status_current" */
  insert_referral_status_current: Maybe<Referral_Status_Current_Mutation_Response>;
  /** insert a single row into the table: "referral_status_current" */
  insert_referral_status_current_one: Maybe<Referral_Status_Current>;
  /** insert a single row into the table: "referral_status" */
  insert_referral_status_one: Maybe<Referral_Status>;
  /** insert data into the table: "region_choices" */
  insert_region_choices: Maybe<Region_Choices_Mutation_Response>;
  /** insert a single row into the table: "region_choices" */
  insert_region_choices_one: Maybe<Region_Choices>;
  /** insert data into the table: "reporting.candidate_metadata" */
  insert_reporting_candidate_metadata: Maybe<Reporting_Candidate_Metadata_Mutation_Response>;
  /** insert a single row into the table: "reporting.candidate_metadata" */
  insert_reporting_candidate_metadata_one: Maybe<Reporting_Candidate_Metadata>;
  /** insert data into the table: "reporting.report_job_matches" */
  insert_reporting_report_job_matches: Maybe<Reporting_Report_Job_Matches_Mutation_Response>;
  /** insert a single row into the table: "reporting.report_job_matches" */
  insert_reporting_report_job_matches_one: Maybe<Reporting_Report_Job_Matches>;
  /** insert data into the table: "role" */
  insert_role: Maybe<Role_Mutation_Response>;
  /** insert data into the table: "role_choices" */
  insert_role_choices: Maybe<Role_Choices_Mutation_Response>;
  /** insert a single row into the table: "role_choices" */
  insert_role_choices_one: Maybe<Role_Choices>;
  /** insert a single row into the table: "role" */
  insert_role_one: Maybe<Role>;
  /** insert data into the table: "salary_info" */
  insert_salary_info: Maybe<Salary_Info_Mutation_Response>;
  /** insert a single row into the table: "salary_info" */
  insert_salary_info_one: Maybe<Salary_Info>;
  /** insert data into the table: "salary_years_experience_range_choices" */
  insert_salary_years_experience_range_choices: Maybe<Salary_Years_Experience_Range_Choices_Mutation_Response>;
  /** insert a single row into the table: "salary_years_experience_range_choices" */
  insert_salary_years_experience_range_choices_one: Maybe<Salary_Years_Experience_Range_Choices>;
  /** insert data into the table: "salesforce_customer" */
  insert_salesforce_customer: Maybe<Salesforce_Customer_Mutation_Response>;
  /** insert data into the table: "salesforce_customer_location" */
  insert_salesforce_customer_location: Maybe<Salesforce_Customer_Location_Mutation_Response>;
  /** insert a single row into the table: "salesforce_customer_location" */
  insert_salesforce_customer_location_one: Maybe<Salesforce_Customer_Location>;
  /** insert a single row into the table: "salesforce_customer" */
  insert_salesforce_customer_one: Maybe<Salesforce_Customer>;
  /** insert data into the table: "salesforce_location" */
  insert_salesforce_location: Maybe<Salesforce_Location_Mutation_Response>;
  /** insert a single row into the table: "salesforce_location" */
  insert_salesforce_location_one: Maybe<Salesforce_Location>;
  /** insert data into the table: "saved_candidate" */
  insert_saved_candidate: Maybe<Saved_Candidate_Mutation_Response>;
  /** insert a single row into the table: "saved_candidate" */
  insert_saved_candidate_one: Maybe<Saved_Candidate>;
  /** insert data into the table: "segment_sendgrid_prod.activity" */
  insert_segment_sendgrid_prod_activity: Maybe<Segment_Sendgrid_Prod_Activity_Mutation_Response>;
  /** insert a single row into the table: "segment_sendgrid_prod.activity" */
  insert_segment_sendgrid_prod_activity_one: Maybe<Segment_Sendgrid_Prod_Activity>;
  /** insert data into the table: "shared_candidate" */
  insert_shared_candidate: Maybe<Shared_Candidate_Mutation_Response>;
  /** insert a single row into the table: "shared_candidate" */
  insert_shared_candidate_one: Maybe<Shared_Candidate>;
  /** insert data into the table: "skill" */
  insert_skill: Maybe<Skill_Mutation_Response>;
  /** insert data into the table: "skill_feature" */
  insert_skill_feature: Maybe<Skill_Feature_Mutation_Response>;
  /** insert a single row into the table: "skill_feature" */
  insert_skill_feature_one: Maybe<Skill_Feature>;
  /** insert data into the table: "skill_icims_skill" */
  insert_skill_icims_skill: Maybe<Skill_Icims_Skill_Mutation_Response>;
  /** insert a single row into the table: "skill_icims_skill" */
  insert_skill_icims_skill_one: Maybe<Skill_Icims_Skill>;
  /** insert a single row into the table: "skill" */
  insert_skill_one: Maybe<Skill>;
  /** insert data into the table: "skill_synonym" */
  insert_skill_synonym: Maybe<Skill_Synonym_Mutation_Response>;
  /** insert a single row into the table: "skill_synonym" */
  insert_skill_synonym_one: Maybe<Skill_Synonym>;
  /** insert data into the table: "spike_matching.candidate" */
  insert_spike_matching_candidate: Maybe<Spike_Matching_Candidate_Mutation_Response>;
  /** insert a single row into the table: "spike_matching.candidate" */
  insert_spike_matching_candidate_one: Maybe<Spike_Matching_Candidate>;
  /** insert data into the table: "spike_matching.candidate_skill" */
  insert_spike_matching_candidate_skill: Maybe<Spike_Matching_Candidate_Skill_Mutation_Response>;
  /** insert a single row into the table: "spike_matching.candidate_skill" */
  insert_spike_matching_candidate_skill_one: Maybe<Spike_Matching_Candidate_Skill>;
  /** insert data into the table: "spike_matching.job" */
  insert_spike_matching_job: Maybe<Spike_Matching_Job_Mutation_Response>;
  /** insert a single row into the table: "spike_matching.job" */
  insert_spike_matching_job_one: Maybe<Spike_Matching_Job>;
  /** insert data into the table: "spike_matching.job_skill" */
  insert_spike_matching_job_skill: Maybe<Spike_Matching_Job_Skill_Mutation_Response>;
  /** insert a single row into the table: "spike_matching.job_skill" */
  insert_spike_matching_job_skill_one: Maybe<Spike_Matching_Job_Skill>;
  /** insert data into the table: "spike_matching.location" */
  insert_spike_matching_location: Maybe<Spike_Matching_Location_Mutation_Response>;
  /** insert data into the table: "spike_matching.location_job" */
  insert_spike_matching_location_job: Maybe<Spike_Matching_Location_Job_Mutation_Response>;
  /** insert a single row into the table: "spike_matching.location_job" */
  insert_spike_matching_location_job_one: Maybe<Spike_Matching_Location_Job>;
  /** insert a single row into the table: "spike_matching.location" */
  insert_spike_matching_location_one: Maybe<Spike_Matching_Location>;
  /** insert data into the table: "spike_matching.match_result" */
  insert_spike_matching_match_result: Maybe<Spike_Matching_Match_Result_Mutation_Response>;
  /** insert a single row into the table: "spike_matching.match_result" */
  insert_spike_matching_match_result_one: Maybe<Spike_Matching_Match_Result>;
  /** insert data into the table: "spike_matching.skill" */
  insert_spike_matching_skill: Maybe<Spike_Matching_Skill_Mutation_Response>;
  /** insert a single row into the table: "spike_matching.skill" */
  insert_spike_matching_skill_one: Maybe<Spike_Matching_Skill>;
  /** insert data into the table: "terminal_celery_task_status_choices" */
  insert_terminal_celery_task_status_choices: Maybe<Terminal_Celery_Task_Status_Choices_Mutation_Response>;
  /** insert a single row into the table: "terminal_celery_task_status_choices" */
  insert_terminal_celery_task_status_choices_one: Maybe<Terminal_Celery_Task_Status_Choices>;
  /** insert data into the table: "terminal_employee" */
  insert_terminal_employee: Maybe<Terminal_Employee_Mutation_Response>;
  /** insert a single row into the table: "terminal_employee" */
  insert_terminal_employee_one: Maybe<Terminal_Employee>;
  /** insert data into the table: "terminal_employee_organization" */
  insert_terminal_employee_organization: Maybe<Terminal_Employee_Organization_Mutation_Response>;
  /** insert a single row into the table: "terminal_employee_organization" */
  insert_terminal_employee_organization_one: Maybe<Terminal_Employee_Organization>;
  /** insert data into the table: "terminal_employee_role_choices" */
  insert_terminal_employee_role_choices: Maybe<Terminal_Employee_Role_Choices_Mutation_Response>;
  /** insert a single row into the table: "terminal_employee_role_choices" */
  insert_terminal_employee_role_choices_one: Maybe<Terminal_Employee_Role_Choices>;
  /** insert data into the table: "trait" */
  insert_trait: Maybe<Trait_Mutation_Response>;
  /** insert a single row into the table: "trait" */
  insert_trait_one: Maybe<Trait>;
  /** insert data into the table: "transcript_evaluation" */
  insert_transcript_evaluation: Maybe<Transcript_Evaluation_Mutation_Response>;
  /** insert a single row into the table: "transcript_evaluation" */
  insert_transcript_evaluation_one: Maybe<Transcript_Evaluation>;
  /** insert data into the table: "transcript_skill" */
  insert_transcript_skill: Maybe<Transcript_Skill_Mutation_Response>;
  /** insert a single row into the table: "transcript_skill" */
  insert_transcript_skill_one: Maybe<Transcript_Skill>;
  /** insert data into the table: "transcript_work_experience" */
  insert_transcript_work_experience: Maybe<Transcript_Work_Experience_Mutation_Response>;
  /** insert a single row into the table: "transcript_work_experience" */
  insert_transcript_work_experience_one: Maybe<Transcript_Work_Experience>;
  /** insert data into the table: "user_info" */
  insert_user_info: Maybe<User_Info_Mutation_Response>;
  /** insert a single row into the table: "user_info" */
  insert_user_info_one: Maybe<User_Info>;
  /** insert data into the table: "user_invitation" */
  insert_user_invitation: Maybe<User_Invitation_Mutation_Response>;
  /** insert a single row into the table: "user_invitation" */
  insert_user_invitation_one: Maybe<User_Invitation>;
  /** insert data into the table: "user_linkedin_contact" */
  insert_user_linkedin_contact: Maybe<User_Linkedin_Contact_Mutation_Response>;
  /** insert a single row into the table: "user_linkedin_contact" */
  insert_user_linkedin_contact_one: Maybe<User_Linkedin_Contact>;
  /** insert data into the table: "user_password_blacklist" */
  insert_user_password_blacklist: Maybe<User_Password_Blacklist_Mutation_Response>;
  /** insert a single row into the table: "user_password_blacklist" */
  insert_user_password_blacklist_one: Maybe<User_Password_Blacklist>;
  /** insert data into the table: "user_role" */
  insert_user_role: Maybe<User_Role_Mutation_Response>;
  /** insert a single row into the table: "user_role" */
  insert_user_role_one: Maybe<User_Role>;
  /** insert data into the table: "user_subscription" */
  insert_user_subscription: Maybe<User_Subscription_Mutation_Response>;
  /** insert data into the table: "user_subscription_choices" */
  insert_user_subscription_choices: Maybe<User_Subscription_Choices_Mutation_Response>;
  /** insert a single row into the table: "user_subscription_choices" */
  insert_user_subscription_choices_one: Maybe<User_Subscription_Choices>;
  /** insert a single row into the table: "user_subscription" */
  insert_user_subscription_one: Maybe<User_Subscription>;
  /** insert data into the table: "user_temp_blob" */
  insert_user_temp_blob: Maybe<User_Temp_Blob_Mutation_Response>;
  /** insert a single row into the table: "user_temp_blob" */
  insert_user_temp_blob_one: Maybe<User_Temp_Blob>;
  /** insert data into the table: "validator" */
  insert_validator: Maybe<Validator_Mutation_Response>;
  /** insert a single row into the table: "validator" */
  insert_validator_one: Maybe<Validator>;
  /** update data of the table: "activity_customer_profile_view" */
  update_activity_customer_profile_view: Maybe<Activity_Customer_Profile_View_Mutation_Response>;
  /** update single row of the table: "activity_customer_profile_view" */
  update_activity_customer_profile_view_by_pk: Maybe<Activity_Customer_Profile_View>;
  /** update multiples rows of table: "activity_customer_profile_view" */
  update_activity_customer_profile_view_many: Maybe<
    Array<Maybe<Activity_Customer_Profile_View_Mutation_Response>>
  >;
  /** update data of the table: "ai_level" */
  update_ai_level: Maybe<Ai_Level_Mutation_Response>;
  /** update single row of the table: "ai_level" */
  update_ai_level_by_pk: Maybe<Ai_Level>;
  /** update multiples rows of table: "ai_level" */
  update_ai_level_many: Maybe<Array<Maybe<Ai_Level_Mutation_Response>>>;
  /** update data of the table: "ai_role" */
  update_ai_role: Maybe<Ai_Role_Mutation_Response>;
  /** update single row of the table: "ai_role" */
  update_ai_role_by_pk: Maybe<Ai_Role>;
  /** update multiples rows of table: "ai_role" */
  update_ai_role_many: Maybe<Array<Maybe<Ai_Role_Mutation_Response>>>;
  /** update data of the table: "ai_role_multiplier_focus" */
  update_ai_role_multiplier_focus: Maybe<Ai_Role_Multiplier_Focus_Mutation_Response>;
  /** update single row of the table: "ai_role_multiplier_focus" */
  update_ai_role_multiplier_focus_by_pk: Maybe<Ai_Role_Multiplier_Focus>;
  /** update multiples rows of table: "ai_role_multiplier_focus" */
  update_ai_role_multiplier_focus_many: Maybe<
    Array<Maybe<Ai_Role_Multiplier_Focus_Mutation_Response>>
  >;
  /** update data of the table: "ai_run_dump" */
  update_ai_run_dump: Maybe<Ai_Run_Dump_Mutation_Response>;
  /** update single row of the table: "ai_run_dump" */
  update_ai_run_dump_by_pk: Maybe<Ai_Run_Dump>;
  /** update multiples rows of table: "ai_run_dump" */
  update_ai_run_dump_many: Maybe<Array<Maybe<Ai_Run_Dump_Mutation_Response>>>;
  /** update data of the table: "applicant_consent" */
  update_applicant_consent: Maybe<Applicant_Consent_Mutation_Response>;
  /** update single row of the table: "applicant_consent" */
  update_applicant_consent_by_pk: Maybe<Applicant_Consent>;
  /** update multiples rows of table: "applicant_consent" */
  update_applicant_consent_many: Maybe<Array<Maybe<Applicant_Consent_Mutation_Response>>>;
  /** update data of the table: "applicant_job" */
  update_applicant_job: Maybe<Applicant_Job_Mutation_Response>;
  /** update single row of the table: "applicant_job" */
  update_applicant_job_by_pk: Maybe<Applicant_Job>;
  /** update multiples rows of table: "applicant_job" */
  update_applicant_job_many: Maybe<Array<Maybe<Applicant_Job_Mutation_Response>>>;
  /** update data of the table: "applicant_waitlist" */
  update_applicant_waitlist: Maybe<Applicant_Waitlist_Mutation_Response>;
  /** update single row of the table: "applicant_waitlist" */
  update_applicant_waitlist_by_pk: Maybe<Applicant_Waitlist>;
  /** update multiples rows of table: "applicant_waitlist" */
  update_applicant_waitlist_many: Maybe<Array<Maybe<Applicant_Waitlist_Mutation_Response>>>;
  /** update data of the table: "audit.logged_actions" */
  update_audit_logged_actions: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** update single row of the table: "audit.logged_actions" */
  update_audit_logged_actions_by_pk: Maybe<Audit_Logged_Actions>;
  /** update multiples rows of table: "audit.logged_actions" */
  update_audit_logged_actions_many: Maybe<Array<Maybe<Audit_Logged_Actions_Mutation_Response>>>;
  /** update data of the table: "calendly_event" */
  update_calendly_event: Maybe<Calendly_Event_Mutation_Response>;
  /** update single row of the table: "calendly_event" */
  update_calendly_event_by_pk: Maybe<Calendly_Event>;
  /** update multiples rows of table: "calendly_event" */
  update_calendly_event_many: Maybe<Array<Maybe<Calendly_Event_Mutation_Response>>>;
  /** update data of the table: "calendly_invitee" */
  update_calendly_invitee: Maybe<Calendly_Invitee_Mutation_Response>;
  /** update single row of the table: "calendly_invitee" */
  update_calendly_invitee_by_pk: Maybe<Calendly_Invitee>;
  /** update multiples rows of table: "calendly_invitee" */
  update_calendly_invitee_many: Maybe<Array<Maybe<Calendly_Invitee_Mutation_Response>>>;
  /** update data of the table: "calendly_user" */
  update_calendly_user: Maybe<Calendly_User_Mutation_Response>;
  /** update single row of the table: "calendly_user" */
  update_calendly_user_by_pk: Maybe<Calendly_User>;
  /** update multiples rows of table: "calendly_user" */
  update_calendly_user_many: Maybe<Array<Maybe<Calendly_User_Mutation_Response>>>;
  /** update data of the table: "candidate" */
  update_candidate: Maybe<Candidate_Mutation_Response>;
  /** update data of the table: "candidate_ai_profile_summary" */
  update_candidate_ai_profile_summary: Maybe<Candidate_Ai_Profile_Summary_Mutation_Response>;
  /** update single row of the table: "candidate_ai_profile_summary" */
  update_candidate_ai_profile_summary_by_pk: Maybe<Candidate_Ai_Profile_Summary>;
  /** update multiples rows of table: "candidate_ai_profile_summary" */
  update_candidate_ai_profile_summary_many: Maybe<
    Array<Maybe<Candidate_Ai_Profile_Summary_Mutation_Response>>
  >;
  /** update data of the table: "candidate_applicant_workflow_stats_result" */
  update_candidate_applicant_workflow_stats_result: Maybe<Candidate_Applicant_Workflow_Stats_Result_Mutation_Response>;
  /** update multiples rows of table: "candidate_applicant_workflow_stats_result" */
  update_candidate_applicant_workflow_stats_result_many: Maybe<
    Array<Maybe<Candidate_Applicant_Workflow_Stats_Result_Mutation_Response>>
  >;
  /** update data of the table: "candidate_approved_country" */
  update_candidate_approved_country: Maybe<Candidate_Approved_Country_Mutation_Response>;
  /** update single row of the table: "candidate_approved_country" */
  update_candidate_approved_country_by_pk: Maybe<Candidate_Approved_Country>;
  /** update multiples rows of table: "candidate_approved_country" */
  update_candidate_approved_country_many: Maybe<
    Array<Maybe<Candidate_Approved_Country_Mutation_Response>>
  >;
  /** update data of the table: "candidate_availability_choices" */
  update_candidate_availability_choices: Maybe<Candidate_Availability_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_availability_choices" */
  update_candidate_availability_choices_by_pk: Maybe<Candidate_Availability_Choices>;
  /** update multiples rows of table: "candidate_availability_choices" */
  update_candidate_availability_choices_many: Maybe<
    Array<Maybe<Candidate_Availability_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_availability_choices_order" */
  update_candidate_availability_choices_order: Maybe<Candidate_Availability_Choices_Order_Mutation_Response>;
  /** update single row of the table: "candidate_availability_choices_order" */
  update_candidate_availability_choices_order_by_pk: Maybe<Candidate_Availability_Choices_Order>;
  /** update multiples rows of table: "candidate_availability_choices_order" */
  update_candidate_availability_choices_order_many: Maybe<
    Array<Maybe<Candidate_Availability_Choices_Order_Mutation_Response>>
  >;
  /** update data of the table: "candidate_badge" */
  update_candidate_badge: Maybe<Candidate_Badge_Mutation_Response>;
  /** update single row of the table: "candidate_badge" */
  update_candidate_badge_by_pk: Maybe<Candidate_Badge>;
  /** update multiples rows of table: "candidate_badge" */
  update_candidate_badge_many: Maybe<Array<Maybe<Candidate_Badge_Mutation_Response>>>;
  /** update data of the table: "candidate_browse_result" */
  update_candidate_browse_result: Maybe<Candidate_Browse_Result_Mutation_Response>;
  /** update single row of the table: "candidate_browse_result" */
  update_candidate_browse_result_by_pk: Maybe<Candidate_Browse_Result>;
  /** update multiples rows of table: "candidate_browse_result" */
  update_candidate_browse_result_many: Maybe<
    Array<Maybe<Candidate_Browse_Result_Mutation_Response>>
  >;
  /** update single row of the table: "candidate" */
  update_candidate_by_pk: Maybe<Candidate>;
  /** update data of the table: "candidate_curation_ai_summary" */
  update_candidate_curation_ai_summary: Maybe<Candidate_Curation_Ai_Summary_Mutation_Response>;
  /** update single row of the table: "candidate_curation_ai_summary" */
  update_candidate_curation_ai_summary_by_pk: Maybe<Candidate_Curation_Ai_Summary>;
  /** update data of the table: "candidate_curation_ai_summary_feedback" */
  update_candidate_curation_ai_summary_feedback: Maybe<Candidate_Curation_Ai_Summary_Feedback_Mutation_Response>;
  /** update single row of the table: "candidate_curation_ai_summary_feedback" */
  update_candidate_curation_ai_summary_feedback_by_pk: Maybe<Candidate_Curation_Ai_Summary_Feedback>;
  /** update multiples rows of table: "candidate_curation_ai_summary_feedback" */
  update_candidate_curation_ai_summary_feedback_many: Maybe<
    Array<Maybe<Candidate_Curation_Ai_Summary_Feedback_Mutation_Response>>
  >;
  /** update multiples rows of table: "candidate_curation_ai_summary" */
  update_candidate_curation_ai_summary_many: Maybe<
    Array<Maybe<Candidate_Curation_Ai_Summary_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_country_choices" */
  update_candidate_curation_country_choices: Maybe<Candidate_Curation_Country_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_country_choices" */
  update_candidate_curation_country_choices_by_pk: Maybe<Candidate_Curation_Country_Choices>;
  /** update multiples rows of table: "candidate_curation_country_choices" */
  update_candidate_curation_country_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Country_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_desired_salary_currency_choices" */
  update_candidate_curation_desired_salary_currency_choices: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_desired_salary_currency_choices" */
  update_candidate_curation_desired_salary_currency_choices_by_pk: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices>;
  /** update multiples rows of table: "candidate_curation_desired_salary_currency_choices" */
  update_candidate_curation_desired_salary_currency_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Desired_Salary_Currency_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_desired_salary_frequency_choices" */
  update_candidate_curation_desired_salary_frequency_choices: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_desired_salary_frequency_choices" */
  update_candidate_curation_desired_salary_frequency_choices_by_pk: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices>;
  /** update multiples rows of table: "candidate_curation_desired_salary_frequency_choices" */
  update_candidate_curation_desired_salary_frequency_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_detail" */
  update_candidate_curation_detail: Maybe<Candidate_Curation_Detail_Mutation_Response>;
  /** update single row of the table: "candidate_curation_detail" */
  update_candidate_curation_detail_by_pk: Maybe<Candidate_Curation_Detail>;
  /** update multiples rows of table: "candidate_curation_detail" */
  update_candidate_curation_detail_many: Maybe<
    Array<Maybe<Candidate_Curation_Detail_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_detail_recruiter_summary_log" */
  update_candidate_curation_detail_recruiter_summary_log: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Mutation_Response>;
  /** update single row of the table: "candidate_curation_detail_recruiter_summary_log" */
  update_candidate_curation_detail_recruiter_summary_log_by_pk: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log>;
  /** update multiples rows of table: "candidate_curation_detail_recruiter_summary_log" */
  update_candidate_curation_detail_recruiter_summary_log_many: Maybe<
    Array<Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_english_proficiency_choices" */
  update_candidate_curation_english_proficiency_choices: Maybe<Candidate_Curation_English_Proficiency_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_english_proficiency_choices" */
  update_candidate_curation_english_proficiency_choices_by_pk: Maybe<Candidate_Curation_English_Proficiency_Choices>;
  /** update multiples rows of table: "candidate_curation_english_proficiency_choices" */
  update_candidate_curation_english_proficiency_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_English_Proficiency_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_equity_importance_choices" */
  update_candidate_curation_equity_importance_choices: Maybe<Candidate_Curation_Equity_Importance_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_equity_importance_choices" */
  update_candidate_curation_equity_importance_choices_by_pk: Maybe<Candidate_Curation_Equity_Importance_Choices>;
  /** update multiples rows of table: "candidate_curation_equity_importance_choices" */
  update_candidate_curation_equity_importance_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Equity_Importance_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_note" */
  update_candidate_curation_note: Maybe<Candidate_Curation_Note_Mutation_Response>;
  /** update single row of the table: "candidate_curation_note" */
  update_candidate_curation_note_by_pk: Maybe<Candidate_Curation_Note>;
  /** update multiples rows of table: "candidate_curation_note" */
  update_candidate_curation_note_many: Maybe<
    Array<Maybe<Candidate_Curation_Note_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_rejected_reason_choices" */
  update_candidate_curation_rejected_reason_choices: Maybe<Candidate_Curation_Rejected_Reason_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_rejected_reason_choices" */
  update_candidate_curation_rejected_reason_choices_by_pk: Maybe<Candidate_Curation_Rejected_Reason_Choices>;
  /** update multiples rows of table: "candidate_curation_rejected_reason_choices" */
  update_candidate_curation_rejected_reason_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Rejected_Reason_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_remote_work_exp_choices" */
  update_candidate_curation_remote_work_exp_choices: Maybe<Candidate_Curation_Remote_Work_Exp_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_remote_work_exp_choices" */
  update_candidate_curation_remote_work_exp_choices_by_pk: Maybe<Candidate_Curation_Remote_Work_Exp_Choices>;
  /** update multiples rows of table: "candidate_curation_remote_work_exp_choices" */
  update_candidate_curation_remote_work_exp_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Remote_Work_Exp_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_visa_status_choices" */
  update_candidate_curation_visa_status_choices: Maybe<Candidate_Curation_Visa_Status_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_visa_status_choices" */
  update_candidate_curation_visa_status_choices_by_pk: Maybe<Candidate_Curation_Visa_Status_Choices>;
  /** update multiples rows of table: "candidate_curation_visa_status_choices" */
  update_candidate_curation_visa_status_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Visa_Status_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_workflow" */
  update_candidate_curation_workflow: Maybe<Candidate_Curation_Workflow_Mutation_Response>;
  /** update single row of the table: "candidate_curation_workflow" */
  update_candidate_curation_workflow_by_pk: Maybe<Candidate_Curation_Workflow>;
  /** update multiples rows of table: "candidate_curation_workflow" */
  update_candidate_curation_workflow_many: Maybe<
    Array<Maybe<Candidate_Curation_Workflow_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_workflow_needs_info_reason" */
  update_candidate_curation_workflow_needs_info_reason: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Mutation_Response>;
  /** update single row of the table: "candidate_curation_workflow_needs_info_reason" */
  update_candidate_curation_workflow_needs_info_reason_by_pk: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason>;
  /** update data of the table: "candidate_curation_workflow_needs_info_reason_choices" */
  update_candidate_curation_workflow_needs_info_reason_choices: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_workflow_needs_info_reason_choices" */
  update_candidate_curation_workflow_needs_info_reason_choices_by_pk: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices>;
  /** update multiples rows of table: "candidate_curation_workflow_needs_info_reason_choices" */
  update_candidate_curation_workflow_needs_info_reason_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Mutation_Response>>
  >;
  /** update multiples rows of table: "candidate_curation_workflow_needs_info_reason" */
  update_candidate_curation_workflow_needs_info_reason_many: Maybe<
    Array<Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_workflow_screener_type_choices" */
  update_candidate_curation_workflow_screener_type_choices: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_workflow_screener_type_choices" */
  update_candidate_curation_workflow_screener_type_choices_by_pk: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices>;
  /** update multiples rows of table: "candidate_curation_workflow_screener_type_choices" */
  update_candidate_curation_workflow_screener_type_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Workflow_Screener_Type_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_workflow_source_choices" */
  update_candidate_curation_workflow_source_choices: Maybe<Candidate_Curation_Workflow_Source_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_workflow_source_choices" */
  update_candidate_curation_workflow_source_choices_by_pk: Maybe<Candidate_Curation_Workflow_Source_Choices>;
  /** update multiples rows of table: "candidate_curation_workflow_source_choices" */
  update_candidate_curation_workflow_source_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Workflow_Source_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_workflow_status_choices" */
  update_candidate_curation_workflow_status_choices: Maybe<Candidate_Curation_Workflow_Status_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_workflow_status_choices" */
  update_candidate_curation_workflow_status_choices_by_pk: Maybe<Candidate_Curation_Workflow_Status_Choices>;
  /** update multiples rows of table: "candidate_curation_workflow_status_choices" */
  update_candidate_curation_workflow_status_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Workflow_Status_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_workflow_status_log" */
  update_candidate_curation_workflow_status_log: Maybe<Candidate_Curation_Workflow_Status_Log_Mutation_Response>;
  /** update single row of the table: "candidate_curation_workflow_status_log" */
  update_candidate_curation_workflow_status_log_by_pk: Maybe<Candidate_Curation_Workflow_Status_Log>;
  /** update multiples rows of table: "candidate_curation_workflow_status_log" */
  update_candidate_curation_workflow_status_log_many: Maybe<
    Array<Maybe<Candidate_Curation_Workflow_Status_Log_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_years_of_exp_range_choices" */
  update_candidate_curation_years_of_exp_range_choices: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_curation_years_of_exp_range_choices" */
  update_candidate_curation_years_of_exp_range_choices_by_pk: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices>;
  /** update multiples rows of table: "candidate_curation_years_of_exp_range_choices" */
  update_candidate_curation_years_of_exp_range_choices_many: Maybe<
    Array<Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_curation_years_of_exp_range_choices_order" */
  update_candidate_curation_years_of_exp_range_choices_order: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Mutation_Response>;
  /** update single row of the table: "candidate_curation_years_of_exp_range_choices_order" */
  update_candidate_curation_years_of_exp_range_choices_order_by_pk: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order>;
  /** update multiples rows of table: "candidate_curation_years_of_exp_range_choices_order" */
  update_candidate_curation_years_of_exp_range_choices_order_many: Maybe<
    Array<Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Mutation_Response>>
  >;
  /** update data of the table: "candidate_detail_level_choices" */
  update_candidate_detail_level_choices: Maybe<Candidate_Detail_Level_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_detail_level_choices" */
  update_candidate_detail_level_choices_by_pk: Maybe<Candidate_Detail_Level_Choices>;
  /** update multiples rows of table: "candidate_detail_level_choices" */
  update_candidate_detail_level_choices_many: Maybe<
    Array<Maybe<Candidate_Detail_Level_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_education" */
  update_candidate_education: Maybe<Candidate_Education_Mutation_Response>;
  /** update single row of the table: "candidate_education" */
  update_candidate_education_by_pk: Maybe<Candidate_Education>;
  /** update data of the table: "candidate_education_concentration_choices" */
  update_candidate_education_concentration_choices: Maybe<Candidate_Education_Concentration_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_education_concentration_choices" */
  update_candidate_education_concentration_choices_by_pk: Maybe<Candidate_Education_Concentration_Choices>;
  /** update multiples rows of table: "candidate_education_concentration_choices" */
  update_candidate_education_concentration_choices_many: Maybe<
    Array<Maybe<Candidate_Education_Concentration_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_education_degree_choices" */
  update_candidate_education_degree_choices: Maybe<Candidate_Education_Degree_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_education_degree_choices" */
  update_candidate_education_degree_choices_by_pk: Maybe<Candidate_Education_Degree_Choices>;
  /** update multiples rows of table: "candidate_education_degree_choices" */
  update_candidate_education_degree_choices_many: Maybe<
    Array<Maybe<Candidate_Education_Degree_Choices_Mutation_Response>>
  >;
  /** update multiples rows of table: "candidate_education" */
  update_candidate_education_many: Maybe<Array<Maybe<Candidate_Education_Mutation_Response>>>;
  /** update data of the table: "candidate_email" */
  update_candidate_email: Maybe<Candidate_Email_Mutation_Response>;
  /** update single row of the table: "candidate_email" */
  update_candidate_email_by_pk: Maybe<Candidate_Email>;
  /** update multiples rows of table: "candidate_email" */
  update_candidate_email_many: Maybe<Array<Maybe<Candidate_Email_Mutation_Response>>>;
  /** update data of the table: "candidate_embedding" */
  update_candidate_embedding: Maybe<Candidate_Embedding_Mutation_Response>;
  /** update single row of the table: "candidate_embedding" */
  update_candidate_embedding_by_pk: Maybe<Candidate_Embedding>;
  /** update multiples rows of table: "candidate_embedding" */
  update_candidate_embedding_many: Maybe<Array<Maybe<Candidate_Embedding_Mutation_Response>>>;
  /** update data of the table: "candidate_highlight_choices" */
  update_candidate_highlight_choices: Maybe<Candidate_Highlight_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_highlight_choices" */
  update_candidate_highlight_choices_by_pk: Maybe<Candidate_Highlight_Choices>;
  /** update multiples rows of table: "candidate_highlight_choices" */
  update_candidate_highlight_choices_many: Maybe<
    Array<Maybe<Candidate_Highlight_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_hub_location" */
  update_candidate_hub_location: Maybe<Candidate_Hub_Location_Mutation_Response>;
  /** update single row of the table: "candidate_hub_location" */
  update_candidate_hub_location_by_pk: Maybe<Candidate_Hub_Location>;
  /** update multiples rows of table: "candidate_hub_location" */
  update_candidate_hub_location_many: Maybe<Array<Maybe<Candidate_Hub_Location_Mutation_Response>>>;
  /** update data of the table: "candidate_interview_invite" */
  update_candidate_interview_invite: Maybe<Candidate_Interview_Invite_Mutation_Response>;
  /** update single row of the table: "candidate_interview_invite" */
  update_candidate_interview_invite_by_pk: Maybe<Candidate_Interview_Invite>;
  /** update multiples rows of table: "candidate_interview_invite" */
  update_candidate_interview_invite_many: Maybe<
    Array<Maybe<Candidate_Interview_Invite_Mutation_Response>>
  >;
  /** update data of the table: "candidate_job" */
  update_candidate_job: Maybe<Candidate_Job_Mutation_Response>;
  /** update single row of the table: "candidate_job" */
  update_candidate_job_by_pk: Maybe<Candidate_Job>;
  /** update multiples rows of table: "candidate_job" */
  update_candidate_job_many: Maybe<Array<Maybe<Candidate_Job_Mutation_Response>>>;
  /** update data of the table: "candidate_job_workflow" */
  update_candidate_job_workflow: Maybe<Candidate_Job_Workflow_Mutation_Response>;
  /** update single row of the table: "candidate_job_workflow" */
  update_candidate_job_workflow_by_pk: Maybe<Candidate_Job_Workflow>;
  /** update multiples rows of table: "candidate_job_workflow" */
  update_candidate_job_workflow_many: Maybe<Array<Maybe<Candidate_Job_Workflow_Mutation_Response>>>;
  /** update data of the table: "candidate_job_workflow_source_choices" */
  update_candidate_job_workflow_source_choices: Maybe<Candidate_Job_Workflow_Source_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_job_workflow_source_choices" */
  update_candidate_job_workflow_source_choices_by_pk: Maybe<Candidate_Job_Workflow_Source_Choices>;
  /** update multiples rows of table: "candidate_job_workflow_source_choices" */
  update_candidate_job_workflow_source_choices_many: Maybe<
    Array<Maybe<Candidate_Job_Workflow_Source_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_job_workflow_status" */
  update_candidate_job_workflow_status: Maybe<Candidate_Job_Workflow_Status_Mutation_Response>;
  /** update single row of the table: "candidate_job_workflow_status" */
  update_candidate_job_workflow_status_by_pk: Maybe<Candidate_Job_Workflow_Status>;
  /** update data of the table: "candidate_job_workflow_status_choices" */
  update_candidate_job_workflow_status_choices: Maybe<Candidate_Job_Workflow_Status_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_job_workflow_status_choices" */
  update_candidate_job_workflow_status_choices_by_pk: Maybe<Candidate_Job_Workflow_Status_Choices>;
  /** update multiples rows of table: "candidate_job_workflow_status_choices" */
  update_candidate_job_workflow_status_choices_many: Maybe<
    Array<Maybe<Candidate_Job_Workflow_Status_Choices_Mutation_Response>>
  >;
  /** update multiples rows of table: "candidate_job_workflow_status" */
  update_candidate_job_workflow_status_many: Maybe<
    Array<Maybe<Candidate_Job_Workflow_Status_Mutation_Response>>
  >;
  /** update multiples rows of table: "candidate" */
  update_candidate_many: Maybe<Array<Maybe<Candidate_Mutation_Response>>>;
  /** update data of the table: "candidate_parsed_resume" */
  update_candidate_parsed_resume: Maybe<Candidate_Parsed_Resume_Mutation_Response>;
  /** update single row of the table: "candidate_parsed_resume" */
  update_candidate_parsed_resume_by_pk: Maybe<Candidate_Parsed_Resume>;
  /** update multiples rows of table: "candidate_parsed_resume" */
  update_candidate_parsed_resume_many: Maybe<
    Array<Maybe<Candidate_Parsed_Resume_Mutation_Response>>
  >;
  /** update data of the table: "candidate_pdl" */
  update_candidate_pdl: Maybe<Candidate_Pdl_Mutation_Response>;
  /** update single row of the table: "candidate_pdl" */
  update_candidate_pdl_by_pk: Maybe<Candidate_Pdl>;
  /** update multiples rows of table: "candidate_pdl" */
  update_candidate_pdl_many: Maybe<Array<Maybe<Candidate_Pdl_Mutation_Response>>>;
  /** update data of the table: "candidate_referrals" */
  update_candidate_referrals: Maybe<Candidate_Referrals_Mutation_Response>;
  /** update single row of the table: "candidate_referrals" */
  update_candidate_referrals_by_pk: Maybe<Candidate_Referrals>;
  /** update multiples rows of table: "candidate_referrals" */
  update_candidate_referrals_many: Maybe<Array<Maybe<Candidate_Referrals_Mutation_Response>>>;
  /** update data of the table: "candidate_role" */
  update_candidate_role: Maybe<Candidate_Role_Mutation_Response>;
  /** update single row of the table: "candidate_role" */
  update_candidate_role_by_pk: Maybe<Candidate_Role>;
  /** update multiples rows of table: "candidate_role" */
  update_candidate_role_many: Maybe<Array<Maybe<Candidate_Role_Mutation_Response>>>;
  /** update data of the table: "candidate_role_skill" */
  update_candidate_role_skill: Maybe<Candidate_Role_Skill_Mutation_Response>;
  /** update single row of the table: "candidate_role_skill" */
  update_candidate_role_skill_by_pk: Maybe<Candidate_Role_Skill>;
  /** update multiples rows of table: "candidate_role_skill" */
  update_candidate_role_skill_many: Maybe<Array<Maybe<Candidate_Role_Skill_Mutation_Response>>>;
  /** update data of the table: "candidate_sdlc_experience_choices" */
  update_candidate_sdlc_experience_choices: Maybe<Candidate_Sdlc_Experience_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_sdlc_experience_choices" */
  update_candidate_sdlc_experience_choices_by_pk: Maybe<Candidate_Sdlc_Experience_Choices>;
  /** update multiples rows of table: "candidate_sdlc_experience_choices" */
  update_candidate_sdlc_experience_choices_many: Maybe<
    Array<Maybe<Candidate_Sdlc_Experience_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_skill" */
  update_candidate_skill: Maybe<Candidate_Skill_Mutation_Response>;
  /** update single row of the table: "candidate_skill" */
  update_candidate_skill_by_pk: Maybe<Candidate_Skill>;
  /** update data of the table: "candidate_skill_comp_choices" */
  update_candidate_skill_comp_choices: Maybe<Candidate_Skill_Comp_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_skill_comp_choices" */
  update_candidate_skill_comp_choices_by_pk: Maybe<Candidate_Skill_Comp_Choices>;
  /** update multiples rows of table: "candidate_skill_comp_choices" */
  update_candidate_skill_comp_choices_many: Maybe<
    Array<Maybe<Candidate_Skill_Comp_Choices_Mutation_Response>>
  >;
  /** update multiples rows of table: "candidate_skill" */
  update_candidate_skill_many: Maybe<Array<Maybe<Candidate_Skill_Mutation_Response>>>;
  /** update data of the table: "candidate_source_choices" */
  update_candidate_source_choices: Maybe<Candidate_Source_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_source_choices" */
  update_candidate_source_choices_by_pk: Maybe<Candidate_Source_Choices>;
  /** update multiples rows of table: "candidate_source_choices" */
  update_candidate_source_choices_many: Maybe<
    Array<Maybe<Candidate_Source_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_stage_choices" */
  update_candidate_stage_choices: Maybe<Candidate_Stage_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_stage_choices" */
  update_candidate_stage_choices_by_pk: Maybe<Candidate_Stage_Choices>;
  /** update multiples rows of table: "candidate_stage_choices" */
  update_candidate_stage_choices_many: Maybe<
    Array<Maybe<Candidate_Stage_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_stage_choices_order" */
  update_candidate_stage_choices_order: Maybe<Candidate_Stage_Choices_Order_Mutation_Response>;
  /** update single row of the table: "candidate_stage_choices_order" */
  update_candidate_stage_choices_order_by_pk: Maybe<Candidate_Stage_Choices_Order>;
  /** update multiples rows of table: "candidate_stage_choices_order" */
  update_candidate_stage_choices_order_many: Maybe<
    Array<Maybe<Candidate_Stage_Choices_Order_Mutation_Response>>
  >;
  /** update data of the table: "candidate_stage_log" */
  update_candidate_stage_log: Maybe<Candidate_Stage_Log_Mutation_Response>;
  /** update single row of the table: "candidate_stage_log" */
  update_candidate_stage_log_by_pk: Maybe<Candidate_Stage_Log>;
  /** update multiples rows of table: "candidate_stage_log" */
  update_candidate_stage_log_many: Maybe<Array<Maybe<Candidate_Stage_Log_Mutation_Response>>>;
  /** update data of the table: "candidate_stage_source_choices" */
  update_candidate_stage_source_choices: Maybe<Candidate_Stage_Source_Choices_Mutation_Response>;
  /** update single row of the table: "candidate_stage_source_choices" */
  update_candidate_stage_source_choices_by_pk: Maybe<Candidate_Stage_Source_Choices>;
  /** update multiples rows of table: "candidate_stage_source_choices" */
  update_candidate_stage_source_choices_many: Maybe<
    Array<Maybe<Candidate_Stage_Source_Choices_Mutation_Response>>
  >;
  /** update data of the table: "candidate_trait" */
  update_candidate_trait: Maybe<Candidate_Trait_Mutation_Response>;
  /** update single row of the table: "candidate_trait" */
  update_candidate_trait_by_pk: Maybe<Candidate_Trait>;
  /** update multiples rows of table: "candidate_trait" */
  update_candidate_trait_many: Maybe<Array<Maybe<Candidate_Trait_Mutation_Response>>>;
  /** update data of the table: "candidate_work_experience" */
  update_candidate_work_experience: Maybe<Candidate_Work_Experience_Mutation_Response>;
  /** update single row of the table: "candidate_work_experience" */
  update_candidate_work_experience_by_pk: Maybe<Candidate_Work_Experience>;
  /** update multiples rows of table: "candidate_work_experience" */
  update_candidate_work_experience_many: Maybe<
    Array<Maybe<Candidate_Work_Experience_Mutation_Response>>
  >;
  /** update data of the table: "candidate_work_experience_skill" */
  update_candidate_work_experience_skill: Maybe<Candidate_Work_Experience_Skill_Mutation_Response>;
  /** update single row of the table: "candidate_work_experience_skill" */
  update_candidate_work_experience_skill_by_pk: Maybe<Candidate_Work_Experience_Skill>;
  /** update multiples rows of table: "candidate_work_experience_skill" */
  update_candidate_work_experience_skill_many: Maybe<
    Array<Maybe<Candidate_Work_Experience_Skill_Mutation_Response>>
  >;
  /** update data of the table: "candidates_in_interview_or_rejected_by_organization_result" */
  update_candidates_in_interview_or_rejected_by_organization_result: Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Mutation_Response>;
  /** update multiples rows of table: "candidates_in_interview_or_rejected_by_organization_result" */
  update_candidates_in_interview_or_rejected_by_organization_result_many: Maybe<
    Array<Maybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Mutation_Response>>
  >;
  /** update data of the table: "celery.celery_taskmeta" */
  update_celery_celery_taskmeta: Maybe<Celery_Celery_Taskmeta_Mutation_Response>;
  /** update single row of the table: "celery.celery_taskmeta" */
  update_celery_celery_taskmeta_by_pk: Maybe<Celery_Celery_Taskmeta>;
  /** update multiples rows of table: "celery.celery_taskmeta" */
  update_celery_celery_taskmeta_many: Maybe<Array<Maybe<Celery_Celery_Taskmeta_Mutation_Response>>>;
  /** update data of the table: "celery.celery_tasksetmeta" */
  update_celery_celery_tasksetmeta: Maybe<Celery_Celery_Tasksetmeta_Mutation_Response>;
  /** update single row of the table: "celery.celery_tasksetmeta" */
  update_celery_celery_tasksetmeta_by_pk: Maybe<Celery_Celery_Tasksetmeta>;
  /** update multiples rows of table: "celery.celery_tasksetmeta" */
  update_celery_celery_tasksetmeta_many: Maybe<
    Array<Maybe<Celery_Celery_Tasksetmeta_Mutation_Response>>
  >;
  /** update data of the table: "celery.task_retry_log" */
  update_celery_task_retry_log: Maybe<Celery_Task_Retry_Log_Mutation_Response>;
  /** update single row of the table: "celery.task_retry_log" */
  update_celery_task_retry_log_by_pk: Maybe<Celery_Task_Retry_Log>;
  /** update multiples rows of table: "celery.task_retry_log" */
  update_celery_task_retry_log_many: Maybe<Array<Maybe<Celery_Task_Retry_Log_Mutation_Response>>>;
  /** update data of the table: "client_prospect" */
  update_client_prospect: Maybe<Client_Prospect_Mutation_Response>;
  /** update single row of the table: "client_prospect" */
  update_client_prospect_by_pk: Maybe<Client_Prospect>;
  /** update data of the table: "client_prospect_event" */
  update_client_prospect_event: Maybe<Client_Prospect_Event_Mutation_Response>;
  /** update single row of the table: "client_prospect_event" */
  update_client_prospect_event_by_pk: Maybe<Client_Prospect_Event>;
  /** update multiples rows of table: "client_prospect_event" */
  update_client_prospect_event_many: Maybe<Array<Maybe<Client_Prospect_Event_Mutation_Response>>>;
  /** update data of the table: "client_prospect_events_choices" */
  update_client_prospect_events_choices: Maybe<Client_Prospect_Events_Choices_Mutation_Response>;
  /** update single row of the table: "client_prospect_events_choices" */
  update_client_prospect_events_choices_by_pk: Maybe<Client_Prospect_Events_Choices>;
  /** update multiples rows of table: "client_prospect_events_choices" */
  update_client_prospect_events_choices_many: Maybe<
    Array<Maybe<Client_Prospect_Events_Choices_Mutation_Response>>
  >;
  /** update multiples rows of table: "client_prospect" */
  update_client_prospect_many: Maybe<Array<Maybe<Client_Prospect_Mutation_Response>>>;
  /** update data of the table: "client_prospect_role" */
  update_client_prospect_role: Maybe<Client_Prospect_Role_Mutation_Response>;
  /** update single row of the table: "client_prospect_role" */
  update_client_prospect_role_by_pk: Maybe<Client_Prospect_Role>;
  /** update multiples rows of table: "client_prospect_role" */
  update_client_prospect_role_many: Maybe<Array<Maybe<Client_Prospect_Role_Mutation_Response>>>;
  /** update data of the table: "client_prospect_role_skill" */
  update_client_prospect_role_skill: Maybe<Client_Prospect_Role_Skill_Mutation_Response>;
  /** update single row of the table: "client_prospect_role_skill" */
  update_client_prospect_role_skill_by_pk: Maybe<Client_Prospect_Role_Skill>;
  /** update multiples rows of table: "client_prospect_role_skill" */
  update_client_prospect_role_skill_many: Maybe<
    Array<Maybe<Client_Prospect_Role_Skill_Mutation_Response>>
  >;
  /** update data of the table: "client_versions" */
  update_client_versions: Maybe<Client_Versions_Mutation_Response>;
  /** update single row of the table: "client_versions" */
  update_client_versions_by_pk: Maybe<Client_Versions>;
  /** update multiples rows of table: "client_versions" */
  update_client_versions_many: Maybe<Array<Maybe<Client_Versions_Mutation_Response>>>;
  /** update data of the table: "company" */
  update_company: Maybe<Company_Mutation_Response>;
  /** update single row of the table: "company" */
  update_company_by_pk: Maybe<Company>;
  /** update multiples rows of table: "company" */
  update_company_many: Maybe<Array<Maybe<Company_Mutation_Response>>>;
  /** update data of the table: "company_name_synonym" */
  update_company_name_synonym: Maybe<Company_Name_Synonym_Mutation_Response>;
  /** update single row of the table: "company_name_synonym" */
  update_company_name_synonym_by_pk: Maybe<Company_Name_Synonym>;
  /** update multiples rows of table: "company_name_synonym" */
  update_company_name_synonym_many: Maybe<Array<Maybe<Company_Name_Synonym_Mutation_Response>>>;
  /** update data of the table: "company_pdl_blob" */
  update_company_pdl_blob: Maybe<Company_Pdl_Blob_Mutation_Response>;
  /** update single row of the table: "company_pdl_blob" */
  update_company_pdl_blob_by_pk: Maybe<Company_Pdl_Blob>;
  /** update multiples rows of table: "company_pdl_blob" */
  update_company_pdl_blob_many: Maybe<Array<Maybe<Company_Pdl_Blob_Mutation_Response>>>;
  /** update data of the table: "company_size_choices" */
  update_company_size_choices: Maybe<Company_Size_Choices_Mutation_Response>;
  /** update single row of the table: "company_size_choices" */
  update_company_size_choices_by_pk: Maybe<Company_Size_Choices>;
  /** update multiples rows of table: "company_size_choices" */
  update_company_size_choices_many: Maybe<Array<Maybe<Company_Size_Choices_Mutation_Response>>>;
  /** update data of the table: "contact" */
  update_contact: Maybe<Contact_Mutation_Response>;
  /** update single row of the table: "contact" */
  update_contact_by_pk: Maybe<Contact>;
  /** update multiples rows of table: "contact" */
  update_contact_many: Maybe<Array<Maybe<Contact_Mutation_Response>>>;
  /** update data of the table: "contract_length_choices" */
  update_contract_length_choices: Maybe<Contract_Length_Choices_Mutation_Response>;
  /** update single row of the table: "contract_length_choices" */
  update_contract_length_choices_by_pk: Maybe<Contract_Length_Choices>;
  /** update multiples rows of table: "contract_length_choices" */
  update_contract_length_choices_many: Maybe<
    Array<Maybe<Contract_Length_Choices_Mutation_Response>>
  >;
  /** update data of the table: "country_choices" */
  update_country_choices: Maybe<Country_Choices_Mutation_Response>;
  /** update single row of the table: "country_choices" */
  update_country_choices_by_pk: Maybe<Country_Choices>;
  /** update multiples rows of table: "country_choices" */
  update_country_choices_many: Maybe<Array<Maybe<Country_Choices_Mutation_Response>>>;
  /** update data of the table: "currency_exchange_rate" */
  update_currency_exchange_rate: Maybe<Currency_Exchange_Rate_Mutation_Response>;
  /** update single row of the table: "currency_exchange_rate" */
  update_currency_exchange_rate_by_pk: Maybe<Currency_Exchange_Rate>;
  /** update multiples rows of table: "currency_exchange_rate" */
  update_currency_exchange_rate_many: Maybe<Array<Maybe<Currency_Exchange_Rate_Mutation_Response>>>;
  /** update data of the table: "dayforce_assignment" */
  update_dayforce_assignment: Maybe<Dayforce_Assignment_Mutation_Response>;
  /** update single row of the table: "dayforce_assignment" */
  update_dayforce_assignment_by_pk: Maybe<Dayforce_Assignment>;
  /** update multiples rows of table: "dayforce_assignment" */
  update_dayforce_assignment_many: Maybe<Array<Maybe<Dayforce_Assignment_Mutation_Response>>>;
  /** update data of the table: "dayforce_customer" */
  update_dayforce_customer: Maybe<Dayforce_Customer_Mutation_Response>;
  /** update single row of the table: "dayforce_customer" */
  update_dayforce_customer_by_pk: Maybe<Dayforce_Customer>;
  /** update multiples rows of table: "dayforce_customer" */
  update_dayforce_customer_many: Maybe<Array<Maybe<Dayforce_Customer_Mutation_Response>>>;
  /** update data of the table: "dayforce_employee" */
  update_dayforce_employee: Maybe<Dayforce_Employee_Mutation_Response>;
  /** update single row of the table: "dayforce_employee" */
  update_dayforce_employee_by_pk: Maybe<Dayforce_Employee>;
  /** update multiples rows of table: "dayforce_employee" */
  update_dayforce_employee_many: Maybe<Array<Maybe<Dayforce_Employee_Mutation_Response>>>;
  /** update data of the table: "dayforce_location" */
  update_dayforce_location: Maybe<Dayforce_Location_Mutation_Response>;
  /** update single row of the table: "dayforce_location" */
  update_dayforce_location_by_pk: Maybe<Dayforce_Location>;
  /** update multiples rows of table: "dayforce_location" */
  update_dayforce_location_many: Maybe<Array<Maybe<Dayforce_Location_Mutation_Response>>>;
  /** update data of the table: "email_notification" */
  update_email_notification: Maybe<Email_Notification_Mutation_Response>;
  /** update single row of the table: "email_notification" */
  update_email_notification_by_pk: Maybe<Email_Notification>;
  /** update multiples rows of table: "email_notification" */
  update_email_notification_many: Maybe<Array<Maybe<Email_Notification_Mutation_Response>>>;
  /** update data of the table: "email_template" */
  update_email_template: Maybe<Email_Template_Mutation_Response>;
  /** update single row of the table: "email_template" */
  update_email_template_by_pk: Maybe<Email_Template>;
  /** update multiples rows of table: "email_template" */
  update_email_template_many: Maybe<Array<Maybe<Email_Template_Mutation_Response>>>;
  /** update data of the table: "employment_type_choices" */
  update_employment_type_choices: Maybe<Employment_Type_Choices_Mutation_Response>;
  /** update single row of the table: "employment_type_choices" */
  update_employment_type_choices_by_pk: Maybe<Employment_Type_Choices>;
  /** update multiples rows of table: "employment_type_choices" */
  update_employment_type_choices_many: Maybe<
    Array<Maybe<Employment_Type_Choices_Mutation_Response>>
  >;
  /** update data of the table: "explore_candidates_popular_search" */
  update_explore_candidates_popular_search: Maybe<Explore_Candidates_Popular_Search_Mutation_Response>;
  /** update single row of the table: "explore_candidates_popular_search" */
  update_explore_candidates_popular_search_by_pk: Maybe<Explore_Candidates_Popular_Search>;
  /** update multiples rows of table: "explore_candidates_popular_search" */
  update_explore_candidates_popular_search_many: Maybe<
    Array<Maybe<Explore_Candidates_Popular_Search_Mutation_Response>>
  >;
  /** update data of the table: "explore_candidates_query_result" */
  update_explore_candidates_query_result: Maybe<Explore_Candidates_Query_Result_Mutation_Response>;
  /** update single row of the table: "explore_candidates_query_result" */
  update_explore_candidates_query_result_by_pk: Maybe<Explore_Candidates_Query_Result>;
  /** update multiples rows of table: "explore_candidates_query_result" */
  update_explore_candidates_query_result_many: Maybe<
    Array<Maybe<Explore_Candidates_Query_Result_Mutation_Response>>
  >;
  /** update data of the table: "explore_candidates_search" */
  update_explore_candidates_search: Maybe<Explore_Candidates_Search_Mutation_Response>;
  /** update single row of the table: "explore_candidates_search" */
  update_explore_candidates_search_by_pk: Maybe<Explore_Candidates_Search>;
  /** update data of the table: "explore_candidates_search_candidate_highlight" */
  update_explore_candidates_search_candidate_highlight: Maybe<Explore_Candidates_Search_Candidate_Highlight_Mutation_Response>;
  /** update single row of the table: "explore_candidates_search_candidate_highlight" */
  update_explore_candidates_search_candidate_highlight_by_pk: Maybe<Explore_Candidates_Search_Candidate_Highlight>;
  /** update multiples rows of table: "explore_candidates_search_candidate_highlight" */
  update_explore_candidates_search_candidate_highlight_many: Maybe<
    Array<Maybe<Explore_Candidates_Search_Candidate_Highlight_Mutation_Response>>
  >;
  /** update multiples rows of table: "explore_candidates_search" */
  update_explore_candidates_search_many: Maybe<
    Array<Maybe<Explore_Candidates_Search_Mutation_Response>>
  >;
  /** update data of the table: "explore_candidates_search_must_have_skill" */
  update_explore_candidates_search_must_have_skill: Maybe<Explore_Candidates_Search_Must_Have_Skill_Mutation_Response>;
  /** update single row of the table: "explore_candidates_search_must_have_skill" */
  update_explore_candidates_search_must_have_skill_by_pk: Maybe<Explore_Candidates_Search_Must_Have_Skill>;
  /** update multiples rows of table: "explore_candidates_search_must_have_skill" */
  update_explore_candidates_search_must_have_skill_many: Maybe<
    Array<Maybe<Explore_Candidates_Search_Must_Have_Skill_Mutation_Response>>
  >;
  /** update data of the table: "explore_candidates_search_nice_to_have_skill" */
  update_explore_candidates_search_nice_to_have_skill: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Mutation_Response>;
  /** update single row of the table: "explore_candidates_search_nice_to_have_skill" */
  update_explore_candidates_search_nice_to_have_skill_by_pk: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill>;
  /** update multiples rows of table: "explore_candidates_search_nice_to_have_skill" */
  update_explore_candidates_search_nice_to_have_skill_many: Maybe<
    Array<Maybe<Explore_Candidates_Search_Nice_To_Have_Skill_Mutation_Response>>
  >;
  /** update data of the table: "explore_candidates_search_region" */
  update_explore_candidates_search_region: Maybe<Explore_Candidates_Search_Region_Mutation_Response>;
  /** update single row of the table: "explore_candidates_search_region" */
  update_explore_candidates_search_region_by_pk: Maybe<Explore_Candidates_Search_Region>;
  /** update multiples rows of table: "explore_candidates_search_region" */
  update_explore_candidates_search_region_many: Maybe<
    Array<Maybe<Explore_Candidates_Search_Region_Mutation_Response>>
  >;
  /** update data of the table: "explore_candidates_user_saved_search" */
  update_explore_candidates_user_saved_search: Maybe<Explore_Candidates_User_Saved_Search_Mutation_Response>;
  /** update single row of the table: "explore_candidates_user_saved_search" */
  update_explore_candidates_user_saved_search_by_pk: Maybe<Explore_Candidates_User_Saved_Search>;
  /** update multiples rows of table: "explore_candidates_user_saved_search" */
  update_explore_candidates_user_saved_search_many: Maybe<
    Array<Maybe<Explore_Candidates_User_Saved_Search_Mutation_Response>>
  >;
  /** update data of the table: "external_candidate_meta" */
  update_external_candidate_meta: Maybe<External_Candidate_Meta_Mutation_Response>;
  /** update single row of the table: "external_candidate_meta" */
  update_external_candidate_meta_by_pk: Maybe<External_Candidate_Meta>;
  /** update multiples rows of table: "external_candidate_meta" */
  update_external_candidate_meta_many: Maybe<
    Array<Maybe<External_Candidate_Meta_Mutation_Response>>
  >;
  /** update data of the table: "feature" */
  update_feature: Maybe<Feature_Mutation_Response>;
  /** update single row of the table: "feature" */
  update_feature_by_pk: Maybe<Feature>;
  /** update multiples rows of table: "feature" */
  update_feature_many: Maybe<Array<Maybe<Feature_Mutation_Response>>>;
  /** update data of the table: "hireflix_transcript" */
  update_hireflix_transcript: Maybe<Hireflix_Transcript_Mutation_Response>;
  /** update data of the table: "hireflix_transcript_blob" */
  update_hireflix_transcript_blob: Maybe<Hireflix_Transcript_Blob_Mutation_Response>;
  /** update single row of the table: "hireflix_transcript_blob" */
  update_hireflix_transcript_blob_by_pk: Maybe<Hireflix_Transcript_Blob>;
  /** update multiples rows of table: "hireflix_transcript_blob" */
  update_hireflix_transcript_blob_many: Maybe<
    Array<Maybe<Hireflix_Transcript_Blob_Mutation_Response>>
  >;
  /** update single row of the table: "hireflix_transcript" */
  update_hireflix_transcript_by_pk: Maybe<Hireflix_Transcript>;
  /** update multiples rows of table: "hireflix_transcript" */
  update_hireflix_transcript_many: Maybe<Array<Maybe<Hireflix_Transcript_Mutation_Response>>>;
  /** update data of the table: "hr_test" */
  update_hr_test: Maybe<Hr_Test_Mutation_Response>;
  /** update single row of the table: "hr_test" */
  update_hr_test_by_pk: Maybe<Hr_Test>;
  /** update data of the table: "hr_test_candidate" */
  update_hr_test_candidate: Maybe<Hr_Test_Candidate_Mutation_Response>;
  /** update single row of the table: "hr_test_candidate" */
  update_hr_test_candidate_by_pk: Maybe<Hr_Test_Candidate>;
  /** update multiples rows of table: "hr_test_candidate" */
  update_hr_test_candidate_many: Maybe<Array<Maybe<Hr_Test_Candidate_Mutation_Response>>>;
  /** update multiples rows of table: "hr_test" */
  update_hr_test_many: Maybe<Array<Maybe<Hr_Test_Mutation_Response>>>;
  /** update data of the table: "hr_test_result" */
  update_hr_test_result: Maybe<Hr_Test_Result_Mutation_Response>;
  /** update single row of the table: "hr_test_result" */
  update_hr_test_result_by_pk: Maybe<Hr_Test_Result>;
  /** update multiples rows of table: "hr_test_result" */
  update_hr_test_result_many: Maybe<Array<Maybe<Hr_Test_Result_Mutation_Response>>>;
  /** update data of the table: "icims_applicant_workflow" */
  update_icims_applicant_workflow: Maybe<Icims_Applicant_Workflow_Mutation_Response>;
  /** update single row of the table: "icims_applicant_workflow" */
  update_icims_applicant_workflow_by_pk: Maybe<Icims_Applicant_Workflow>;
  /** update data of the table: "icims_applicant_workflow_feedback" */
  update_icims_applicant_workflow_feedback: Maybe<Icims_Applicant_Workflow_Feedback_Mutation_Response>;
  /** update single row of the table: "icims_applicant_workflow_feedback" */
  update_icims_applicant_workflow_feedback_by_pk: Maybe<Icims_Applicant_Workflow_Feedback>;
  /** update multiples rows of table: "icims_applicant_workflow_feedback" */
  update_icims_applicant_workflow_feedback_many: Maybe<
    Array<Maybe<Icims_Applicant_Workflow_Feedback_Mutation_Response>>
  >;
  /** update multiples rows of table: "icims_applicant_workflow" */
  update_icims_applicant_workflow_many: Maybe<
    Array<Maybe<Icims_Applicant_Workflow_Mutation_Response>>
  >;
  /** update data of the table: "icims_applicant_workflow_status" */
  update_icims_applicant_workflow_status: Maybe<Icims_Applicant_Workflow_Status_Mutation_Response>;
  /** update single row of the table: "icims_applicant_workflow_status" */
  update_icims_applicant_workflow_status_by_pk: Maybe<Icims_Applicant_Workflow_Status>;
  /** update multiples rows of table: "icims_applicant_workflow_status" */
  update_icims_applicant_workflow_status_many: Maybe<
    Array<Maybe<Icims_Applicant_Workflow_Status_Mutation_Response>>
  >;
  /** update data of the table: "icims_company" */
  update_icims_company: Maybe<Icims_Company_Mutation_Response>;
  /** update single row of the table: "icims_company" */
  update_icims_company_by_pk: Maybe<Icims_Company>;
  /** update multiples rows of table: "icims_company" */
  update_icims_company_many: Maybe<Array<Maybe<Icims_Company_Mutation_Response>>>;
  /** update data of the table: "icims_country" */
  update_icims_country: Maybe<Icims_Country_Mutation_Response>;
  /** update single row of the table: "icims_country" */
  update_icims_country_by_pk: Maybe<Icims_Country>;
  /** update multiples rows of table: "icims_country" */
  update_icims_country_many: Maybe<Array<Maybe<Icims_Country_Mutation_Response>>>;
  /** update data of the table: "icims_degree" */
  update_icims_degree: Maybe<Icims_Degree_Mutation_Response>;
  /** update single row of the table: "icims_degree" */
  update_icims_degree_by_pk: Maybe<Icims_Degree>;
  /** update multiples rows of table: "icims_degree" */
  update_icims_degree_many: Maybe<Array<Maybe<Icims_Degree_Mutation_Response>>>;
  /** update data of the table: "icims_education" */
  update_icims_education: Maybe<Icims_Education_Mutation_Response>;
  /** update single row of the table: "icims_education" */
  update_icims_education_by_pk: Maybe<Icims_Education>;
  /** update multiples rows of table: "icims_education" */
  update_icims_education_many: Maybe<Array<Maybe<Icims_Education_Mutation_Response>>>;
  /** update data of the table: "icims_folder" */
  update_icims_folder: Maybe<Icims_Folder_Mutation_Response>;
  /** update single row of the table: "icims_folder" */
  update_icims_folder_by_pk: Maybe<Icims_Folder>;
  /** update multiples rows of table: "icims_folder" */
  update_icims_folder_many: Maybe<Array<Maybe<Icims_Folder_Mutation_Response>>>;
  /** update data of the table: "icims_initial_data_transfer" */
  update_icims_initial_data_transfer: Maybe<Icims_Initial_Data_Transfer_Mutation_Response>;
  /** update single row of the table: "icims_initial_data_transfer" */
  update_icims_initial_data_transfer_by_pk: Maybe<Icims_Initial_Data_Transfer>;
  /** update multiples rows of table: "icims_initial_data_transfer" */
  update_icims_initial_data_transfer_many: Maybe<
    Array<Maybe<Icims_Initial_Data_Transfer_Mutation_Response>>
  >;
  /** update data of the table: "icims_interested_location" */
  update_icims_interested_location: Maybe<Icims_Interested_Location_Mutation_Response>;
  /** update single row of the table: "icims_interested_location" */
  update_icims_interested_location_by_pk: Maybe<Icims_Interested_Location>;
  /** update multiples rows of table: "icims_interested_location" */
  update_icims_interested_location_many: Maybe<
    Array<Maybe<Icims_Interested_Location_Mutation_Response>>
  >;
  /** update data of the table: "icims_job" */
  update_icims_job: Maybe<Icims_Job_Mutation_Response>;
  /** update single row of the table: "icims_job" */
  update_icims_job_by_pk: Maybe<Icims_Job>;
  /** update data of the table: "icims_job_location" */
  update_icims_job_location: Maybe<Icims_Job_Location_Mutation_Response>;
  /** update single row of the table: "icims_job_location" */
  update_icims_job_location_by_pk: Maybe<Icims_Job_Location>;
  /** update multiples rows of table: "icims_job_location" */
  update_icims_job_location_many: Maybe<Array<Maybe<Icims_Job_Location_Mutation_Response>>>;
  /** update multiples rows of table: "icims_job" */
  update_icims_job_many: Maybe<Array<Maybe<Icims_Job_Mutation_Response>>>;
  /** update data of the table: "icims_job_requested_location" */
  update_icims_job_requested_location: Maybe<Icims_Job_Requested_Location_Mutation_Response>;
  /** update single row of the table: "icims_job_requested_location" */
  update_icims_job_requested_location_by_pk: Maybe<Icims_Job_Requested_Location>;
  /** update multiples rows of table: "icims_job_requested_location" */
  update_icims_job_requested_location_many: Maybe<
    Array<Maybe<Icims_Job_Requested_Location_Mutation_Response>>
  >;
  /** update data of the table: "icims_job_skill" */
  update_icims_job_skill: Maybe<Icims_Job_Skill_Mutation_Response>;
  /** update single row of the table: "icims_job_skill" */
  update_icims_job_skill_by_pk: Maybe<Icims_Job_Skill>;
  /** update multiples rows of table: "icims_job_skill" */
  update_icims_job_skill_many: Maybe<Array<Maybe<Icims_Job_Skill_Mutation_Response>>>;
  /** update data of the table: "icims_location" */
  update_icims_location: Maybe<Icims_Location_Mutation_Response>;
  /** update single row of the table: "icims_location" */
  update_icims_location_by_pk: Maybe<Icims_Location>;
  /** update multiples rows of table: "icims_location" */
  update_icims_location_many: Maybe<Array<Maybe<Icims_Location_Mutation_Response>>>;
  /** update data of the table: "icims_major" */
  update_icims_major: Maybe<Icims_Major_Mutation_Response>;
  /** update single row of the table: "icims_major" */
  update_icims_major_by_pk: Maybe<Icims_Major>;
  /** update multiples rows of table: "icims_major" */
  update_icims_major_many: Maybe<Array<Maybe<Icims_Major_Mutation_Response>>>;
  /** update data of the table: "icims_person" */
  update_icims_person: Maybe<Icims_Person_Mutation_Response>;
  /** update single row of the table: "icims_person" */
  update_icims_person_by_pk: Maybe<Icims_Person>;
  /** update data of the table: "icims_person_interested_location" */
  update_icims_person_interested_location: Maybe<Icims_Person_Interested_Location_Mutation_Response>;
  /** update single row of the table: "icims_person_interested_location" */
  update_icims_person_interested_location_by_pk: Maybe<Icims_Person_Interested_Location>;
  /** update multiples rows of table: "icims_person_interested_location" */
  update_icims_person_interested_location_many: Maybe<
    Array<Maybe<Icims_Person_Interested_Location_Mutation_Response>>
  >;
  /** update multiples rows of table: "icims_person" */
  update_icims_person_many: Maybe<Array<Maybe<Icims_Person_Mutation_Response>>>;
  /** update data of the table: "icims_person_preferred_location" */
  update_icims_person_preferred_location: Maybe<Icims_Person_Preferred_Location_Mutation_Response>;
  /** update single row of the table: "icims_person_preferred_location" */
  update_icims_person_preferred_location_by_pk: Maybe<Icims_Person_Preferred_Location>;
  /** update multiples rows of table: "icims_person_preferred_location" */
  update_icims_person_preferred_location_many: Maybe<
    Array<Maybe<Icims_Person_Preferred_Location_Mutation_Response>>
  >;
  /** update data of the table: "icims_person_skill" */
  update_icims_person_skill: Maybe<Icims_Person_Skill_Mutation_Response>;
  /** update single row of the table: "icims_person_skill" */
  update_icims_person_skill_by_pk: Maybe<Icims_Person_Skill>;
  /** update multiples rows of table: "icims_person_skill" */
  update_icims_person_skill_many: Maybe<Array<Maybe<Icims_Person_Skill_Mutation_Response>>>;
  /** update data of the table: "icims_profile_applicant_workflow" */
  update_icims_profile_applicant_workflow: Maybe<Icims_Profile_Applicant_Workflow_Mutation_Response>;
  /** update single row of the table: "icims_profile_applicant_workflow" */
  update_icims_profile_applicant_workflow_by_pk: Maybe<Icims_Profile_Applicant_Workflow>;
  /** update multiples rows of table: "icims_profile_applicant_workflow" */
  update_icims_profile_applicant_workflow_many: Maybe<
    Array<Maybe<Icims_Profile_Applicant_Workflow_Mutation_Response>>
  >;
  /** update data of the table: "icims_profile_job" */
  update_icims_profile_job: Maybe<Icims_Profile_Job_Mutation_Response>;
  /** update single row of the table: "icims_profile_job" */
  update_icims_profile_job_by_pk: Maybe<Icims_Profile_Job>;
  /** update multiples rows of table: "icims_profile_job" */
  update_icims_profile_job_many: Maybe<Array<Maybe<Icims_Profile_Job_Mutation_Response>>>;
  /** update data of the table: "icims_profile_location" */
  update_icims_profile_location: Maybe<Icims_Profile_Location_Mutation_Response>;
  /** update single row of the table: "icims_profile_location" */
  update_icims_profile_location_by_pk: Maybe<Icims_Profile_Location>;
  /** update multiples rows of table: "icims_profile_location" */
  update_icims_profile_location_many: Maybe<Array<Maybe<Icims_Profile_Location_Mutation_Response>>>;
  /** update data of the table: "icims_profile_person" */
  update_icims_profile_person: Maybe<Icims_Profile_Person_Mutation_Response>;
  /** update single row of the table: "icims_profile_person" */
  update_icims_profile_person_by_pk: Maybe<Icims_Profile_Person>;
  /** update multiples rows of table: "icims_profile_person" */
  update_icims_profile_person_many: Maybe<Array<Maybe<Icims_Profile_Person_Mutation_Response>>>;
  /** update data of the table: "icims_profile_requested_location" */
  update_icims_profile_requested_location: Maybe<Icims_Profile_Requested_Location_Mutation_Response>;
  /** update single row of the table: "icims_profile_requested_location" */
  update_icims_profile_requested_location_by_pk: Maybe<Icims_Profile_Requested_Location>;
  /** update multiples rows of table: "icims_profile_requested_location" */
  update_icims_profile_requested_location_many: Maybe<
    Array<Maybe<Icims_Profile_Requested_Location_Mutation_Response>>
  >;
  /** update data of the table: "icims_requested_location" */
  update_icims_requested_location: Maybe<Icims_Requested_Location_Mutation_Response>;
  /** update single row of the table: "icims_requested_location" */
  update_icims_requested_location_by_pk: Maybe<Icims_Requested_Location>;
  /** update multiples rows of table: "icims_requested_location" */
  update_icims_requested_location_many: Maybe<
    Array<Maybe<Icims_Requested_Location_Mutation_Response>>
  >;
  /** update data of the table: "icims_school" */
  update_icims_school: Maybe<Icims_School_Mutation_Response>;
  /** update single row of the table: "icims_school" */
  update_icims_school_by_pk: Maybe<Icims_School>;
  /** update multiples rows of table: "icims_school" */
  update_icims_school_many: Maybe<Array<Maybe<Icims_School_Mutation_Response>>>;
  /** update data of the table: "icims_skill" */
  update_icims_skill: Maybe<Icims_Skill_Mutation_Response>;
  /** update single row of the table: "icims_skill" */
  update_icims_skill_by_pk: Maybe<Icims_Skill>;
  /** update multiples rows of table: "icims_skill" */
  update_icims_skill_many: Maybe<Array<Maybe<Icims_Skill_Mutation_Response>>>;
  /** update data of the table: "icims_status" */
  update_icims_status: Maybe<Icims_Status_Mutation_Response>;
  /** update single row of the table: "icims_status" */
  update_icims_status_by_pk: Maybe<Icims_Status>;
  /** update multiples rows of table: "icims_status" */
  update_icims_status_many: Maybe<Array<Maybe<Icims_Status_Mutation_Response>>>;
  /** update data of the table: "icims_sync_status_choices" */
  update_icims_sync_status_choices: Maybe<Icims_Sync_Status_Choices_Mutation_Response>;
  /** update single row of the table: "icims_sync_status_choices" */
  update_icims_sync_status_choices_by_pk: Maybe<Icims_Sync_Status_Choices>;
  /** update multiples rows of table: "icims_sync_status_choices" */
  update_icims_sync_status_choices_many: Maybe<
    Array<Maybe<Icims_Sync_Status_Choices_Mutation_Response>>
  >;
  /** update data of the table: "icims_work_experience" */
  update_icims_work_experience: Maybe<Icims_Work_Experience_Mutation_Response>;
  /** update single row of the table: "icims_work_experience" */
  update_icims_work_experience_by_pk: Maybe<Icims_Work_Experience>;
  /** update multiples rows of table: "icims_work_experience" */
  update_icims_work_experience_many: Maybe<Array<Maybe<Icims_Work_Experience_Mutation_Response>>>;
  /** update data of the table: "industry" */
  update_industry: Maybe<Industry_Mutation_Response>;
  /** update single row of the table: "industry" */
  update_industry_by_pk: Maybe<Industry>;
  /** update multiples rows of table: "industry" */
  update_industry_many: Maybe<Array<Maybe<Industry_Mutation_Response>>>;
  /** update data of the table: "initial_sell_type_choices" */
  update_initial_sell_type_choices: Maybe<Initial_Sell_Type_Choices_Mutation_Response>;
  /** update single row of the table: "initial_sell_type_choices" */
  update_initial_sell_type_choices_by_pk: Maybe<Initial_Sell_Type_Choices>;
  /** update multiples rows of table: "initial_sell_type_choices" */
  update_initial_sell_type_choices_many: Maybe<
    Array<Maybe<Initial_Sell_Type_Choices_Mutation_Response>>
  >;
  /** update data of the table: "interview_detail" */
  update_interview_detail: Maybe<Interview_Detail_Mutation_Response>;
  /** update single row of the table: "interview_detail" */
  update_interview_detail_by_pk: Maybe<Interview_Detail>;
  /** update multiples rows of table: "interview_detail" */
  update_interview_detail_many: Maybe<Array<Maybe<Interview_Detail_Mutation_Response>>>;
  /** update data of the table: "interview_feedback" */
  update_interview_feedback: Maybe<Interview_Feedback_Mutation_Response>;
  /** update single row of the table: "interview_feedback" */
  update_interview_feedback_by_pk: Maybe<Interview_Feedback>;
  /** update multiples rows of table: "interview_feedback" */
  update_interview_feedback_many: Maybe<Array<Maybe<Interview_Feedback_Mutation_Response>>>;
  /** update data of the table: "interview_feedback_rating_choices" */
  update_interview_feedback_rating_choices: Maybe<Interview_Feedback_Rating_Choices_Mutation_Response>;
  /** update single row of the table: "interview_feedback_rating_choices" */
  update_interview_feedback_rating_choices_by_pk: Maybe<Interview_Feedback_Rating_Choices>;
  /** update multiples rows of table: "interview_feedback_rating_choices" */
  update_interview_feedback_rating_choices_many: Maybe<
    Array<Maybe<Interview_Feedback_Rating_Choices_Mutation_Response>>
  >;
  /** update data of the table: "interview_feedback_request" */
  update_interview_feedback_request: Maybe<Interview_Feedback_Request_Mutation_Response>;
  /** update single row of the table: "interview_feedback_request" */
  update_interview_feedback_request_by_pk: Maybe<Interview_Feedback_Request>;
  /** update multiples rows of table: "interview_feedback_request" */
  update_interview_feedback_request_many: Maybe<
    Array<Maybe<Interview_Feedback_Request_Mutation_Response>>
  >;
  /** update data of the table: "interview_request" */
  update_interview_request: Maybe<Interview_Request_Mutation_Response>;
  /** update single row of the table: "interview_request" */
  update_interview_request_by_pk: Maybe<Interview_Request>;
  /** update multiples rows of table: "interview_request" */
  update_interview_request_many: Maybe<Array<Maybe<Interview_Request_Mutation_Response>>>;
  /** update data of the table: "interview_type_choices" */
  update_interview_type_choices: Maybe<Interview_Type_Choices_Mutation_Response>;
  /** update single row of the table: "interview_type_choices" */
  update_interview_type_choices_by_pk: Maybe<Interview_Type_Choices>;
  /** update multiples rows of table: "interview_type_choices" */
  update_interview_type_choices_many: Maybe<Array<Maybe<Interview_Type_Choices_Mutation_Response>>>;
  /** update data of the table: "job" */
  update_job: Maybe<Job_Mutation_Response>;
  /** update data of the table: "job_acceptable_location" */
  update_job_acceptable_location: Maybe<Job_Acceptable_Location_Mutation_Response>;
  /** update single row of the table: "job_acceptable_location" */
  update_job_acceptable_location_by_pk: Maybe<Job_Acceptable_Location>;
  /** update multiples rows of table: "job_acceptable_location" */
  update_job_acceptable_location_many: Maybe<
    Array<Maybe<Job_Acceptable_Location_Mutation_Response>>
  >;
  /** update single row of the table: "job" */
  update_job_by_pk: Maybe<Job>;
  /** update data of the table: "job_code" */
  update_job_code: Maybe<Job_Code_Mutation_Response>;
  /** update single row of the table: "job_code" */
  update_job_code_by_pk: Maybe<Job_Code>;
  /** update multiples rows of table: "job_code" */
  update_job_code_many: Maybe<Array<Maybe<Job_Code_Mutation_Response>>>;
  /** update data of the table: "job_level_choices" */
  update_job_level_choices: Maybe<Job_Level_Choices_Mutation_Response>;
  /** update single row of the table: "job_level_choices" */
  update_job_level_choices_by_pk: Maybe<Job_Level_Choices>;
  /** update multiples rows of table: "job_level_choices" */
  update_job_level_choices_many: Maybe<Array<Maybe<Job_Level_Choices_Mutation_Response>>>;
  /** update multiples rows of table: "job" */
  update_job_many: Maybe<Array<Maybe<Job_Mutation_Response>>>;
  /** update data of the table: "job_meta_info" */
  update_job_meta_info: Maybe<Job_Meta_Info_Mutation_Response>;
  /** update single row of the table: "job_meta_info" */
  update_job_meta_info_by_pk: Maybe<Job_Meta_Info>;
  /** update multiples rows of table: "job_meta_info" */
  update_job_meta_info_many: Maybe<Array<Maybe<Job_Meta_Info_Mutation_Response>>>;
  /** update data of the table: "job_nice_to_have_skill" */
  update_job_nice_to_have_skill: Maybe<Job_Nice_To_Have_Skill_Mutation_Response>;
  /** update single row of the table: "job_nice_to_have_skill" */
  update_job_nice_to_have_skill_by_pk: Maybe<Job_Nice_To_Have_Skill>;
  /** update multiples rows of table: "job_nice_to_have_skill" */
  update_job_nice_to_have_skill_many: Maybe<Array<Maybe<Job_Nice_To_Have_Skill_Mutation_Response>>>;
  /** update data of the table: "job_position" */
  update_job_position: Maybe<Job_Position_Mutation_Response>;
  /** update single row of the table: "job_position" */
  update_job_position_by_pk: Maybe<Job_Position>;
  /** update multiples rows of table: "job_position" */
  update_job_position_many: Maybe<Array<Maybe<Job_Position_Mutation_Response>>>;
  /** update data of the table: "job_priority_choices" */
  update_job_priority_choices: Maybe<Job_Priority_Choices_Mutation_Response>;
  /** update single row of the table: "job_priority_choices" */
  update_job_priority_choices_by_pk: Maybe<Job_Priority_Choices>;
  /** update multiples rows of table: "job_priority_choices" */
  update_job_priority_choices_many: Maybe<Array<Maybe<Job_Priority_Choices_Mutation_Response>>>;
  /** update data of the table: "job_required_skill" */
  update_job_required_skill: Maybe<Job_Required_Skill_Mutation_Response>;
  /** update single row of the table: "job_required_skill" */
  update_job_required_skill_by_pk: Maybe<Job_Required_Skill>;
  /** update multiples rows of table: "job_required_skill" */
  update_job_required_skill_many: Maybe<Array<Maybe<Job_Required_Skill_Mutation_Response>>>;
  /** update data of the table: "job_role_source_choices" */
  update_job_role_source_choices: Maybe<Job_Role_Source_Choices_Mutation_Response>;
  /** update single row of the table: "job_role_source_choices" */
  update_job_role_source_choices_by_pk: Maybe<Job_Role_Source_Choices>;
  /** update multiples rows of table: "job_role_source_choices" */
  update_job_role_source_choices_many: Maybe<
    Array<Maybe<Job_Role_Source_Choices_Mutation_Response>>
  >;
  /** update data of the table: "job_service_type" */
  update_job_service_type: Maybe<Job_Service_Type_Mutation_Response>;
  /** update single row of the table: "job_service_type" */
  update_job_service_type_by_pk: Maybe<Job_Service_Type>;
  /** update multiples rows of table: "job_service_type" */
  update_job_service_type_many: Maybe<Array<Maybe<Job_Service_Type_Mutation_Response>>>;
  /** update data of the table: "job_status_choices" */
  update_job_status_choices: Maybe<Job_Status_Choices_Mutation_Response>;
  /** update single row of the table: "job_status_choices" */
  update_job_status_choices_by_pk: Maybe<Job_Status_Choices>;
  /** update multiples rows of table: "job_status_choices" */
  update_job_status_choices_many: Maybe<Array<Maybe<Job_Status_Choices_Mutation_Response>>>;
  /** update data of the table: "job_status_choices_order" */
  update_job_status_choices_order: Maybe<Job_Status_Choices_Order_Mutation_Response>;
  /** update single row of the table: "job_status_choices_order" */
  update_job_status_choices_order_by_pk: Maybe<Job_Status_Choices_Order>;
  /** update multiples rows of table: "job_status_choices_order" */
  update_job_status_choices_order_many: Maybe<
    Array<Maybe<Job_Status_Choices_Order_Mutation_Response>>
  >;
  /** update data of the table: "job_status_log" */
  update_job_status_log: Maybe<Job_Status_Log_Mutation_Response>;
  /** update single row of the table: "job_status_log" */
  update_job_status_log_by_pk: Maybe<Job_Status_Log>;
  /** update multiples rows of table: "job_status_log" */
  update_job_status_log_many: Maybe<Array<Maybe<Job_Status_Log_Mutation_Response>>>;
  /** update data of the table: "job_tech_stack" */
  update_job_tech_stack: Maybe<Job_Tech_Stack_Mutation_Response>;
  /** update single row of the table: "job_tech_stack" */
  update_job_tech_stack_by_pk: Maybe<Job_Tech_Stack>;
  /** update multiples rows of table: "job_tech_stack" */
  update_job_tech_stack_many: Maybe<Array<Maybe<Job_Tech_Stack_Mutation_Response>>>;
  /** update data of the table: "linkedin_contact" */
  update_linkedin_contact: Maybe<Linkedin_Contact_Mutation_Response>;
  /** update single row of the table: "linkedin_contact" */
  update_linkedin_contact_by_pk: Maybe<Linkedin_Contact>;
  /** update multiples rows of table: "linkedin_contact" */
  update_linkedin_contact_many: Maybe<Array<Maybe<Linkedin_Contact_Mutation_Response>>>;
  /** update data of the table: "location" */
  update_location: Maybe<Location_Mutation_Response>;
  /** update single row of the table: "location" */
  update_location_by_pk: Maybe<Location>;
  /** update multiples rows of table: "location" */
  update_location_many: Maybe<Array<Maybe<Location_Mutation_Response>>>;
  /** update data of the table: "manual_contact" */
  update_manual_contact: Maybe<Manual_Contact_Mutation_Response>;
  /** update single row of the table: "manual_contact" */
  update_manual_contact_by_pk: Maybe<Manual_Contact>;
  /** update multiples rows of table: "manual_contact" */
  update_manual_contact_many: Maybe<Array<Maybe<Manual_Contact_Mutation_Response>>>;
  /** update data of the table: "merge_ats_application" */
  update_merge_ats_application: Maybe<Merge_Ats_Application_Mutation_Response>;
  /** update single row of the table: "merge_ats_application" */
  update_merge_ats_application_by_pk: Maybe<Merge_Ats_Application>;
  /** update data of the table: "merge_ats_application_log" */
  update_merge_ats_application_log: Maybe<Merge_Ats_Application_Log_Mutation_Response>;
  /** update single row of the table: "merge_ats_application_log" */
  update_merge_ats_application_log_by_pk: Maybe<Merge_Ats_Application_Log>;
  /** update multiples rows of table: "merge_ats_application_log" */
  update_merge_ats_application_log_many: Maybe<
    Array<Maybe<Merge_Ats_Application_Log_Mutation_Response>>
  >;
  /** update multiples rows of table: "merge_ats_application" */
  update_merge_ats_application_many: Maybe<Array<Maybe<Merge_Ats_Application_Mutation_Response>>>;
  /** update data of the table: "merge_ats_candidate" */
  update_merge_ats_candidate: Maybe<Merge_Ats_Candidate_Mutation_Response>;
  /** update single row of the table: "merge_ats_candidate" */
  update_merge_ats_candidate_by_pk: Maybe<Merge_Ats_Candidate>;
  /** update multiples rows of table: "merge_ats_candidate" */
  update_merge_ats_candidate_many: Maybe<Array<Maybe<Merge_Ats_Candidate_Mutation_Response>>>;
  /** update data of the table: "merge_ats_job" */
  update_merge_ats_job: Maybe<Merge_Ats_Job_Mutation_Response>;
  /** update single row of the table: "merge_ats_job" */
  update_merge_ats_job_by_pk: Maybe<Merge_Ats_Job>;
  /** update data of the table: "merge_ats_job_interview_stage" */
  update_merge_ats_job_interview_stage: Maybe<Merge_Ats_Job_Interview_Stage_Mutation_Response>;
  /** update single row of the table: "merge_ats_job_interview_stage" */
  update_merge_ats_job_interview_stage_by_pk: Maybe<Merge_Ats_Job_Interview_Stage>;
  /** update multiples rows of table: "merge_ats_job_interview_stage" */
  update_merge_ats_job_interview_stage_many: Maybe<
    Array<Maybe<Merge_Ats_Job_Interview_Stage_Mutation_Response>>
  >;
  /** update multiples rows of table: "merge_ats_job" */
  update_merge_ats_job_many: Maybe<Array<Maybe<Merge_Ats_Job_Mutation_Response>>>;
  /** update data of the table: "merge_ats_linked_account" */
  update_merge_ats_linked_account: Maybe<Merge_Ats_Linked_Account_Mutation_Response>;
  /** update single row of the table: "merge_ats_linked_account" */
  update_merge_ats_linked_account_by_pk: Maybe<Merge_Ats_Linked_Account>;
  /** update multiples rows of table: "merge_ats_linked_account" */
  update_merge_ats_linked_account_many: Maybe<
    Array<Maybe<Merge_Ats_Linked_Account_Mutation_Response>>
  >;
  /** update data of the table: "mixpanel.profiles" */
  update_mixpanel_profiles: Maybe<Mixpanel_Profiles_Mutation_Response>;
  /** update single row of the table: "mixpanel.profiles" */
  update_mixpanel_profiles_by_pk: Maybe<Mixpanel_Profiles>;
  /** update multiples rows of table: "mixpanel.profiles" */
  update_mixpanel_profiles_many: Maybe<Array<Maybe<Mixpanel_Profiles_Mutation_Response>>>;
  /** update data of the table: "offering_selection_choices" */
  update_offering_selection_choices: Maybe<Offering_Selection_Choices_Mutation_Response>;
  /** update single row of the table: "offering_selection_choices" */
  update_offering_selection_choices_by_pk: Maybe<Offering_Selection_Choices>;
  /** update multiples rows of table: "offering_selection_choices" */
  update_offering_selection_choices_many: Maybe<
    Array<Maybe<Offering_Selection_Choices_Mutation_Response>>
  >;
  /** update data of the table: "organization" */
  update_organization: Maybe<Organization_Mutation_Response>;
  /** update single row of the table: "organization" */
  update_organization_by_pk: Maybe<Organization>;
  /** update data of the table: "organization_contact" */
  update_organization_contact: Maybe<Organization_Contact_Mutation_Response>;
  /** update single row of the table: "organization_contact" */
  update_organization_contact_by_pk: Maybe<Organization_Contact>;
  /** update multiples rows of table: "organization_contact" */
  update_organization_contact_many: Maybe<Array<Maybe<Organization_Contact_Mutation_Response>>>;
  /** update data of the table: "organization_domain" */
  update_organization_domain: Maybe<Organization_Domain_Mutation_Response>;
  /** update single row of the table: "organization_domain" */
  update_organization_domain_by_pk: Maybe<Organization_Domain>;
  /** update multiples rows of table: "organization_domain" */
  update_organization_domain_many: Maybe<Array<Maybe<Organization_Domain_Mutation_Response>>>;
  /** update data of the table: "organization_employee" */
  update_organization_employee: Maybe<Organization_Employee_Mutation_Response>;
  /** update single row of the table: "organization_employee" */
  update_organization_employee_by_pk: Maybe<Organization_Employee>;
  /** update multiples rows of table: "organization_employee" */
  update_organization_employee_many: Maybe<Array<Maybe<Organization_Employee_Mutation_Response>>>;
  /** update data of the table: "organization_icims_jobs_result" */
  update_organization_icims_jobs_result: Maybe<Organization_Icims_Jobs_Result_Mutation_Response>;
  /** update multiples rows of table: "organization_icims_jobs_result" */
  update_organization_icims_jobs_result_many: Maybe<
    Array<Maybe<Organization_Icims_Jobs_Result_Mutation_Response>>
  >;
  /** update data of the table: "organization_leadership" */
  update_organization_leadership: Maybe<Organization_Leadership_Mutation_Response>;
  /** update single row of the table: "organization_leadership" */
  update_organization_leadership_by_pk: Maybe<Organization_Leadership>;
  /** update multiples rows of table: "organization_leadership" */
  update_organization_leadership_many: Maybe<
    Array<Maybe<Organization_Leadership_Mutation_Response>>
  >;
  /** update multiples rows of table: "organization" */
  update_organization_many: Maybe<Array<Maybe<Organization_Mutation_Response>>>;
  /** update data of the table: "organization_type_choices" */
  update_organization_type_choices: Maybe<Organization_Type_Choices_Mutation_Response>;
  /** update single row of the table: "organization_type_choices" */
  update_organization_type_choices_by_pk: Maybe<Organization_Type_Choices>;
  /** update multiples rows of table: "organization_type_choices" */
  update_organization_type_choices_many: Maybe<
    Array<Maybe<Organization_Type_Choices_Mutation_Response>>
  >;
  /** update data of the table: "organization_video" */
  update_organization_video: Maybe<Organization_Video_Mutation_Response>;
  /** update single row of the table: "organization_video" */
  update_organization_video_by_pk: Maybe<Organization_Video>;
  /** update multiples rows of table: "organization_video" */
  update_organization_video_many: Maybe<Array<Maybe<Organization_Video_Mutation_Response>>>;
  /** update data of the table: "pillar_transcript" */
  update_pillar_transcript: Maybe<Pillar_Transcript_Mutation_Response>;
  /** update data of the table: "pillar_transcript_blob" */
  update_pillar_transcript_blob: Maybe<Pillar_Transcript_Blob_Mutation_Response>;
  /** update single row of the table: "pillar_transcript_blob" */
  update_pillar_transcript_blob_by_pk: Maybe<Pillar_Transcript_Blob>;
  /** update multiples rows of table: "pillar_transcript_blob" */
  update_pillar_transcript_blob_many: Maybe<Array<Maybe<Pillar_Transcript_Blob_Mutation_Response>>>;
  /** update single row of the table: "pillar_transcript" */
  update_pillar_transcript_by_pk: Maybe<Pillar_Transcript>;
  /** update multiples rows of table: "pillar_transcript" */
  update_pillar_transcript_many: Maybe<Array<Maybe<Pillar_Transcript_Mutation_Response>>>;
  /** update data of the table: "recruiter_contact_action" */
  update_recruiter_contact_action: Maybe<Recruiter_Contact_Action_Mutation_Response>;
  /** update single row of the table: "recruiter_contact_action" */
  update_recruiter_contact_action_by_pk: Maybe<Recruiter_Contact_Action>;
  /** update multiples rows of table: "recruiter_contact_action" */
  update_recruiter_contact_action_many: Maybe<
    Array<Maybe<Recruiter_Contact_Action_Mutation_Response>>
  >;
  /** update data of the table: "referral_status" */
  update_referral_status: Maybe<Referral_Status_Mutation_Response>;
  /** update single row of the table: "referral_status" */
  update_referral_status_by_pk: Maybe<Referral_Status>;
  /** update data of the table: "referral_status_current" */
  update_referral_status_current: Maybe<Referral_Status_Current_Mutation_Response>;
  /** update single row of the table: "referral_status_current" */
  update_referral_status_current_by_pk: Maybe<Referral_Status_Current>;
  /** update multiples rows of table: "referral_status_current" */
  update_referral_status_current_many: Maybe<
    Array<Maybe<Referral_Status_Current_Mutation_Response>>
  >;
  /** update multiples rows of table: "referral_status" */
  update_referral_status_many: Maybe<Array<Maybe<Referral_Status_Mutation_Response>>>;
  /** update data of the table: "region_choices" */
  update_region_choices: Maybe<Region_Choices_Mutation_Response>;
  /** update single row of the table: "region_choices" */
  update_region_choices_by_pk: Maybe<Region_Choices>;
  /** update multiples rows of table: "region_choices" */
  update_region_choices_many: Maybe<Array<Maybe<Region_Choices_Mutation_Response>>>;
  /** update data of the table: "reporting.candidate_metadata" */
  update_reporting_candidate_metadata: Maybe<Reporting_Candidate_Metadata_Mutation_Response>;
  /** update multiples rows of table: "reporting.candidate_metadata" */
  update_reporting_candidate_metadata_many: Maybe<
    Array<Maybe<Reporting_Candidate_Metadata_Mutation_Response>>
  >;
  /** update data of the table: "reporting.report_job_matches" */
  update_reporting_report_job_matches: Maybe<Reporting_Report_Job_Matches_Mutation_Response>;
  /** update single row of the table: "reporting.report_job_matches" */
  update_reporting_report_job_matches_by_pk: Maybe<Reporting_Report_Job_Matches>;
  /** update multiples rows of table: "reporting.report_job_matches" */
  update_reporting_report_job_matches_many: Maybe<
    Array<Maybe<Reporting_Report_Job_Matches_Mutation_Response>>
  >;
  /** update data of the table: "role" */
  update_role: Maybe<Role_Mutation_Response>;
  /** update single row of the table: "role" */
  update_role_by_pk: Maybe<Role>;
  /** update data of the table: "role_choices" */
  update_role_choices: Maybe<Role_Choices_Mutation_Response>;
  /** update single row of the table: "role_choices" */
  update_role_choices_by_pk: Maybe<Role_Choices>;
  /** update multiples rows of table: "role_choices" */
  update_role_choices_many: Maybe<Array<Maybe<Role_Choices_Mutation_Response>>>;
  /** update multiples rows of table: "role" */
  update_role_many: Maybe<Array<Maybe<Role_Mutation_Response>>>;
  /** update data of the table: "salary_info" */
  update_salary_info: Maybe<Salary_Info_Mutation_Response>;
  /** update single row of the table: "salary_info" */
  update_salary_info_by_pk: Maybe<Salary_Info>;
  /** update multiples rows of table: "salary_info" */
  update_salary_info_many: Maybe<Array<Maybe<Salary_Info_Mutation_Response>>>;
  /** update data of the table: "salary_years_experience_range_choices" */
  update_salary_years_experience_range_choices: Maybe<Salary_Years_Experience_Range_Choices_Mutation_Response>;
  /** update single row of the table: "salary_years_experience_range_choices" */
  update_salary_years_experience_range_choices_by_pk: Maybe<Salary_Years_Experience_Range_Choices>;
  /** update multiples rows of table: "salary_years_experience_range_choices" */
  update_salary_years_experience_range_choices_many: Maybe<
    Array<Maybe<Salary_Years_Experience_Range_Choices_Mutation_Response>>
  >;
  /** update data of the table: "salesforce_customer" */
  update_salesforce_customer: Maybe<Salesforce_Customer_Mutation_Response>;
  /** update single row of the table: "salesforce_customer" */
  update_salesforce_customer_by_pk: Maybe<Salesforce_Customer>;
  /** update data of the table: "salesforce_customer_location" */
  update_salesforce_customer_location: Maybe<Salesforce_Customer_Location_Mutation_Response>;
  /** update single row of the table: "salesforce_customer_location" */
  update_salesforce_customer_location_by_pk: Maybe<Salesforce_Customer_Location>;
  /** update multiples rows of table: "salesforce_customer_location" */
  update_salesforce_customer_location_many: Maybe<
    Array<Maybe<Salesforce_Customer_Location_Mutation_Response>>
  >;
  /** update multiples rows of table: "salesforce_customer" */
  update_salesforce_customer_many: Maybe<Array<Maybe<Salesforce_Customer_Mutation_Response>>>;
  /** update data of the table: "salesforce_location" */
  update_salesforce_location: Maybe<Salesforce_Location_Mutation_Response>;
  /** update single row of the table: "salesforce_location" */
  update_salesforce_location_by_pk: Maybe<Salesforce_Location>;
  /** update multiples rows of table: "salesforce_location" */
  update_salesforce_location_many: Maybe<Array<Maybe<Salesforce_Location_Mutation_Response>>>;
  /** update data of the table: "saved_candidate" */
  update_saved_candidate: Maybe<Saved_Candidate_Mutation_Response>;
  /** update single row of the table: "saved_candidate" */
  update_saved_candidate_by_pk: Maybe<Saved_Candidate>;
  /** update multiples rows of table: "saved_candidate" */
  update_saved_candidate_many: Maybe<Array<Maybe<Saved_Candidate_Mutation_Response>>>;
  /** update data of the table: "segment_sendgrid_prod.activity" */
  update_segment_sendgrid_prod_activity: Maybe<Segment_Sendgrid_Prod_Activity_Mutation_Response>;
  /** update single row of the table: "segment_sendgrid_prod.activity" */
  update_segment_sendgrid_prod_activity_by_pk: Maybe<Segment_Sendgrid_Prod_Activity>;
  /** update multiples rows of table: "segment_sendgrid_prod.activity" */
  update_segment_sendgrid_prod_activity_many: Maybe<
    Array<Maybe<Segment_Sendgrid_Prod_Activity_Mutation_Response>>
  >;
  /** update data of the table: "shared_candidate" */
  update_shared_candidate: Maybe<Shared_Candidate_Mutation_Response>;
  /** update single row of the table: "shared_candidate" */
  update_shared_candidate_by_pk: Maybe<Shared_Candidate>;
  /** update multiples rows of table: "shared_candidate" */
  update_shared_candidate_many: Maybe<Array<Maybe<Shared_Candidate_Mutation_Response>>>;
  /** update data of the table: "skill" */
  update_skill: Maybe<Skill_Mutation_Response>;
  /** update single row of the table: "skill" */
  update_skill_by_pk: Maybe<Skill>;
  /** update data of the table: "skill_feature" */
  update_skill_feature: Maybe<Skill_Feature_Mutation_Response>;
  /** update single row of the table: "skill_feature" */
  update_skill_feature_by_pk: Maybe<Skill_Feature>;
  /** update multiples rows of table: "skill_feature" */
  update_skill_feature_many: Maybe<Array<Maybe<Skill_Feature_Mutation_Response>>>;
  /** update data of the table: "skill_icims_skill" */
  update_skill_icims_skill: Maybe<Skill_Icims_Skill_Mutation_Response>;
  /** update single row of the table: "skill_icims_skill" */
  update_skill_icims_skill_by_pk: Maybe<Skill_Icims_Skill>;
  /** update multiples rows of table: "skill_icims_skill" */
  update_skill_icims_skill_many: Maybe<Array<Maybe<Skill_Icims_Skill_Mutation_Response>>>;
  /** update multiples rows of table: "skill" */
  update_skill_many: Maybe<Array<Maybe<Skill_Mutation_Response>>>;
  /** update data of the table: "skill_synonym" */
  update_skill_synonym: Maybe<Skill_Synonym_Mutation_Response>;
  /** update single row of the table: "skill_synonym" */
  update_skill_synonym_by_pk: Maybe<Skill_Synonym>;
  /** update multiples rows of table: "skill_synonym" */
  update_skill_synonym_many: Maybe<Array<Maybe<Skill_Synonym_Mutation_Response>>>;
  /** update data of the table: "spike_matching.candidate" */
  update_spike_matching_candidate: Maybe<Spike_Matching_Candidate_Mutation_Response>;
  /** update single row of the table: "spike_matching.candidate" */
  update_spike_matching_candidate_by_pk: Maybe<Spike_Matching_Candidate>;
  /** update multiples rows of table: "spike_matching.candidate" */
  update_spike_matching_candidate_many: Maybe<
    Array<Maybe<Spike_Matching_Candidate_Mutation_Response>>
  >;
  /** update data of the table: "spike_matching.candidate_skill" */
  update_spike_matching_candidate_skill: Maybe<Spike_Matching_Candidate_Skill_Mutation_Response>;
  /** update single row of the table: "spike_matching.candidate_skill" */
  update_spike_matching_candidate_skill_by_pk: Maybe<Spike_Matching_Candidate_Skill>;
  /** update multiples rows of table: "spike_matching.candidate_skill" */
  update_spike_matching_candidate_skill_many: Maybe<
    Array<Maybe<Spike_Matching_Candidate_Skill_Mutation_Response>>
  >;
  /** update data of the table: "spike_matching.job" */
  update_spike_matching_job: Maybe<Spike_Matching_Job_Mutation_Response>;
  /** update single row of the table: "spike_matching.job" */
  update_spike_matching_job_by_pk: Maybe<Spike_Matching_Job>;
  /** update multiples rows of table: "spike_matching.job" */
  update_spike_matching_job_many: Maybe<Array<Maybe<Spike_Matching_Job_Mutation_Response>>>;
  /** update data of the table: "spike_matching.job_skill" */
  update_spike_matching_job_skill: Maybe<Spike_Matching_Job_Skill_Mutation_Response>;
  /** update single row of the table: "spike_matching.job_skill" */
  update_spike_matching_job_skill_by_pk: Maybe<Spike_Matching_Job_Skill>;
  /** update multiples rows of table: "spike_matching.job_skill" */
  update_spike_matching_job_skill_many: Maybe<
    Array<Maybe<Spike_Matching_Job_Skill_Mutation_Response>>
  >;
  /** update data of the table: "spike_matching.location" */
  update_spike_matching_location: Maybe<Spike_Matching_Location_Mutation_Response>;
  /** update single row of the table: "spike_matching.location" */
  update_spike_matching_location_by_pk: Maybe<Spike_Matching_Location>;
  /** update data of the table: "spike_matching.location_job" */
  update_spike_matching_location_job: Maybe<Spike_Matching_Location_Job_Mutation_Response>;
  /** update single row of the table: "spike_matching.location_job" */
  update_spike_matching_location_job_by_pk: Maybe<Spike_Matching_Location_Job>;
  /** update multiples rows of table: "spike_matching.location_job" */
  update_spike_matching_location_job_many: Maybe<
    Array<Maybe<Spike_Matching_Location_Job_Mutation_Response>>
  >;
  /** update multiples rows of table: "spike_matching.location" */
  update_spike_matching_location_many: Maybe<
    Array<Maybe<Spike_Matching_Location_Mutation_Response>>
  >;
  /** update data of the table: "spike_matching.match_result" */
  update_spike_matching_match_result: Maybe<Spike_Matching_Match_Result_Mutation_Response>;
  /** update single row of the table: "spike_matching.match_result" */
  update_spike_matching_match_result_by_pk: Maybe<Spike_Matching_Match_Result>;
  /** update multiples rows of table: "spike_matching.match_result" */
  update_spike_matching_match_result_many: Maybe<
    Array<Maybe<Spike_Matching_Match_Result_Mutation_Response>>
  >;
  /** update data of the table: "spike_matching.skill" */
  update_spike_matching_skill: Maybe<Spike_Matching_Skill_Mutation_Response>;
  /** update single row of the table: "spike_matching.skill" */
  update_spike_matching_skill_by_pk: Maybe<Spike_Matching_Skill>;
  /** update multiples rows of table: "spike_matching.skill" */
  update_spike_matching_skill_many: Maybe<Array<Maybe<Spike_Matching_Skill_Mutation_Response>>>;
  /** update data of the table: "terminal_celery_task_status_choices" */
  update_terminal_celery_task_status_choices: Maybe<Terminal_Celery_Task_Status_Choices_Mutation_Response>;
  /** update single row of the table: "terminal_celery_task_status_choices" */
  update_terminal_celery_task_status_choices_by_pk: Maybe<Terminal_Celery_Task_Status_Choices>;
  /** update multiples rows of table: "terminal_celery_task_status_choices" */
  update_terminal_celery_task_status_choices_many: Maybe<
    Array<Maybe<Terminal_Celery_Task_Status_Choices_Mutation_Response>>
  >;
  /** update data of the table: "terminal_employee" */
  update_terminal_employee: Maybe<Terminal_Employee_Mutation_Response>;
  /** update single row of the table: "terminal_employee" */
  update_terminal_employee_by_pk: Maybe<Terminal_Employee>;
  /** update multiples rows of table: "terminal_employee" */
  update_terminal_employee_many: Maybe<Array<Maybe<Terminal_Employee_Mutation_Response>>>;
  /** update data of the table: "terminal_employee_organization" */
  update_terminal_employee_organization: Maybe<Terminal_Employee_Organization_Mutation_Response>;
  /** update single row of the table: "terminal_employee_organization" */
  update_terminal_employee_organization_by_pk: Maybe<Terminal_Employee_Organization>;
  /** update multiples rows of table: "terminal_employee_organization" */
  update_terminal_employee_organization_many: Maybe<
    Array<Maybe<Terminal_Employee_Organization_Mutation_Response>>
  >;
  /** update data of the table: "terminal_employee_role_choices" */
  update_terminal_employee_role_choices: Maybe<Terminal_Employee_Role_Choices_Mutation_Response>;
  /** update single row of the table: "terminal_employee_role_choices" */
  update_terminal_employee_role_choices_by_pk: Maybe<Terminal_Employee_Role_Choices>;
  /** update multiples rows of table: "terminal_employee_role_choices" */
  update_terminal_employee_role_choices_many: Maybe<
    Array<Maybe<Terminal_Employee_Role_Choices_Mutation_Response>>
  >;
  /** update data of the table: "trait" */
  update_trait: Maybe<Trait_Mutation_Response>;
  /** update single row of the table: "trait" */
  update_trait_by_pk: Maybe<Trait>;
  /** update multiples rows of table: "trait" */
  update_trait_many: Maybe<Array<Maybe<Trait_Mutation_Response>>>;
  /** update data of the table: "transcript_evaluation" */
  update_transcript_evaluation: Maybe<Transcript_Evaluation_Mutation_Response>;
  /** update single row of the table: "transcript_evaluation" */
  update_transcript_evaluation_by_pk: Maybe<Transcript_Evaluation>;
  /** update multiples rows of table: "transcript_evaluation" */
  update_transcript_evaluation_many: Maybe<Array<Maybe<Transcript_Evaluation_Mutation_Response>>>;
  /** update data of the table: "transcript_skill" */
  update_transcript_skill: Maybe<Transcript_Skill_Mutation_Response>;
  /** update single row of the table: "transcript_skill" */
  update_transcript_skill_by_pk: Maybe<Transcript_Skill>;
  /** update multiples rows of table: "transcript_skill" */
  update_transcript_skill_many: Maybe<Array<Maybe<Transcript_Skill_Mutation_Response>>>;
  /** update data of the table: "transcript_work_experience" */
  update_transcript_work_experience: Maybe<Transcript_Work_Experience_Mutation_Response>;
  /** update single row of the table: "transcript_work_experience" */
  update_transcript_work_experience_by_pk: Maybe<Transcript_Work_Experience>;
  /** update multiples rows of table: "transcript_work_experience" */
  update_transcript_work_experience_many: Maybe<
    Array<Maybe<Transcript_Work_Experience_Mutation_Response>>
  >;
  /** update data of the table: "user_info" */
  update_user_info: Maybe<User_Info_Mutation_Response>;
  /** update single row of the table: "user_info" */
  update_user_info_by_pk: Maybe<User_Info>;
  /** update multiples rows of table: "user_info" */
  update_user_info_many: Maybe<Array<Maybe<User_Info_Mutation_Response>>>;
  /** update data of the table: "user_invitation" */
  update_user_invitation: Maybe<User_Invitation_Mutation_Response>;
  /** update single row of the table: "user_invitation" */
  update_user_invitation_by_pk: Maybe<User_Invitation>;
  /** update multiples rows of table: "user_invitation" */
  update_user_invitation_many: Maybe<Array<Maybe<User_Invitation_Mutation_Response>>>;
  /** update data of the table: "user_linkedin_contact" */
  update_user_linkedin_contact: Maybe<User_Linkedin_Contact_Mutation_Response>;
  /** update single row of the table: "user_linkedin_contact" */
  update_user_linkedin_contact_by_pk: Maybe<User_Linkedin_Contact>;
  /** update multiples rows of table: "user_linkedin_contact" */
  update_user_linkedin_contact_many: Maybe<Array<Maybe<User_Linkedin_Contact_Mutation_Response>>>;
  /** update data of the table: "user_password_blacklist" */
  update_user_password_blacklist: Maybe<User_Password_Blacklist_Mutation_Response>;
  /** update single row of the table: "user_password_blacklist" */
  update_user_password_blacklist_by_pk: Maybe<User_Password_Blacklist>;
  /** update multiples rows of table: "user_password_blacklist" */
  update_user_password_blacklist_many: Maybe<
    Array<Maybe<User_Password_Blacklist_Mutation_Response>>
  >;
  /** update data of the table: "user_role" */
  update_user_role: Maybe<User_Role_Mutation_Response>;
  /** update single row of the table: "user_role" */
  update_user_role_by_pk: Maybe<User_Role>;
  /** update multiples rows of table: "user_role" */
  update_user_role_many: Maybe<Array<Maybe<User_Role_Mutation_Response>>>;
  /** update data of the table: "user_subscription" */
  update_user_subscription: Maybe<User_Subscription_Mutation_Response>;
  /** update single row of the table: "user_subscription" */
  update_user_subscription_by_pk: Maybe<User_Subscription>;
  /** update data of the table: "user_subscription_choices" */
  update_user_subscription_choices: Maybe<User_Subscription_Choices_Mutation_Response>;
  /** update single row of the table: "user_subscription_choices" */
  update_user_subscription_choices_by_pk: Maybe<User_Subscription_Choices>;
  /** update multiples rows of table: "user_subscription_choices" */
  update_user_subscription_choices_many: Maybe<
    Array<Maybe<User_Subscription_Choices_Mutation_Response>>
  >;
  /** update multiples rows of table: "user_subscription" */
  update_user_subscription_many: Maybe<Array<Maybe<User_Subscription_Mutation_Response>>>;
  /** update data of the table: "user_temp_blob" */
  update_user_temp_blob: Maybe<User_Temp_Blob_Mutation_Response>;
  /** update single row of the table: "user_temp_blob" */
  update_user_temp_blob_by_pk: Maybe<User_Temp_Blob>;
  /** update multiples rows of table: "user_temp_blob" */
  update_user_temp_blob_many: Maybe<Array<Maybe<User_Temp_Blob_Mutation_Response>>>;
  /** update data of the table: "validator" */
  update_validator: Maybe<Validator_Mutation_Response>;
  /** update single row of the table: "validator" */
  update_validator_by_pk: Maybe<Validator>;
  /** update multiples rows of table: "validator" */
  update_validator_many: Maybe<Array<Maybe<Validator_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootDelete_Activity_Customer_Profile_ViewArgs = {
  where: Activity_Customer_Profile_View_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Activity_Customer_Profile_View_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_LevelArgs = {
  where: Ai_Level_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Level_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_RoleArgs = {
  where: Ai_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Role_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_Role_Multiplier_FocusArgs = {
  where: Ai_Role_Multiplier_Focus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Role_Multiplier_Focus_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_Run_DumpArgs = {
  where: Ai_Run_Dump_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Run_Dump_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Applicant_ConsentArgs = {
  where: Applicant_Consent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Applicant_Consent_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Applicant_JobArgs = {
  where: Applicant_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Applicant_Job_By_PkArgs = {
  candidate_id: Scalars['Int'];
  job: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Applicant_WaitlistArgs = {
  where: Applicant_Waitlist_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Applicant_Waitlist_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Audit_Logged_ActionsArgs = {
  where: Audit_Logged_Actions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Audit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Calendly_EventArgs = {
  where: Calendly_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Calendly_Event_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Calendly_InviteeArgs = {
  where: Calendly_Invitee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Calendly_Invitee_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Calendly_UserArgs = {
  where: Calendly_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Calendly_User_By_PkArgs = {
  calendly_user_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_CandidateArgs = {
  where: Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Ai_Profile_SummaryArgs = {
  where: Candidate_Ai_Profile_Summary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Ai_Profile_Summary_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Applicant_Workflow_Stats_ResultArgs = {
  where: Candidate_Applicant_Workflow_Stats_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Approved_CountryArgs = {
  where: Candidate_Approved_Country_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Approved_Country_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Availability_ChoicesArgs = {
  where: Candidate_Availability_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Availability_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Availability_Choices_OrderArgs = {
  where: Candidate_Availability_Choices_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Availability_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_BadgeArgs = {
  where: Candidate_Badge_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Badge_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Browse_ResultArgs = {
  where: Candidate_Browse_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Browse_Result_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Ai_SummaryArgs = {
  where: Candidate_Curation_Ai_Summary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Ai_Summary_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Ai_Summary_FeedbackArgs = {
  where: Candidate_Curation_Ai_Summary_Feedback_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Ai_Summary_Feedback_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Country_ChoicesArgs = {
  where: Candidate_Curation_Country_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Country_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Desired_Salary_Currency_ChoicesArgs = {
  where: Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Desired_Salary_Currency_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Desired_Salary_Frequency_ChoicesArgs = {
  where: Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Desired_Salary_Frequency_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_DetailArgs = {
  where: Candidate_Curation_Detail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Detail_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Detail_Recruiter_Summary_LogArgs = {
  where: Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Detail_Recruiter_Summary_Log_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_English_Proficiency_ChoicesArgs = {
  where: Candidate_Curation_English_Proficiency_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_English_Proficiency_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Equity_Importance_ChoicesArgs = {
  where: Candidate_Curation_Equity_Importance_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Equity_Importance_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_NoteArgs = {
  where: Candidate_Curation_Note_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Note_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Rejected_Reason_ChoicesArgs = {
  where: Candidate_Curation_Rejected_Reason_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Rejected_Reason_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Remote_Work_Exp_ChoicesArgs = {
  where: Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Remote_Work_Exp_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Visa_Status_ChoicesArgs = {
  where: Candidate_Curation_Visa_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Visa_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_WorkflowArgs = {
  where: Candidate_Curation_Workflow_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Needs_Info_ReasonArgs = {
  where: Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Needs_Info_Reason_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Needs_Info_Reason_ChoicesArgs = {
  where: Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Needs_Info_Reason_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Screener_Type_ChoicesArgs = {
  where: Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Screener_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Source_ChoicesArgs = {
  where: Candidate_Curation_Workflow_Source_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Status_ChoicesArgs = {
  where: Candidate_Curation_Workflow_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Status_LogArgs = {
  where: Candidate_Curation_Workflow_Status_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Workflow_Status_Log_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Years_Of_Exp_Range_ChoicesArgs = {
  where: Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Years_Of_Exp_Range_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Years_Of_Exp_Range_Choices_OrderArgs = {
  where: Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Curation_Years_Of_Exp_Range_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Detail_Level_ChoicesArgs = {
  where: Candidate_Detail_Level_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Detail_Level_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_EducationArgs = {
  where: Candidate_Education_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Education_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Education_Concentration_ChoicesArgs = {
  where: Candidate_Education_Concentration_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Education_Concentration_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Education_Degree_ChoicesArgs = {
  where: Candidate_Education_Degree_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Education_Degree_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_EmailArgs = {
  where: Candidate_Email_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Email_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_EmbeddingArgs = {
  where: Candidate_Embedding_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Embedding_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Highlight_ChoicesArgs = {
  where: Candidate_Highlight_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Highlight_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Hub_LocationArgs = {
  where: Candidate_Hub_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Hub_Location_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Interview_InviteArgs = {
  where: Candidate_Interview_Invite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Interview_Invite_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_JobArgs = {
  where: Candidate_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Job_By_PkArgs = {
  candidate_id: Scalars['Int'];
  job: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Job_WorkflowArgs = {
  where: Candidate_Job_Workflow_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Job_Workflow_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Job_Workflow_Source_ChoicesArgs = {
  where: Candidate_Job_Workflow_Source_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Job_Workflow_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Job_Workflow_StatusArgs = {
  where: Candidate_Job_Workflow_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Job_Workflow_Status_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Job_Workflow_Status_ChoicesArgs = {
  where: Candidate_Job_Workflow_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Job_Workflow_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Parsed_ResumeArgs = {
  where: Candidate_Parsed_Resume_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Parsed_Resume_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_PdlArgs = {
  where: Candidate_Pdl_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Pdl_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_ReferralsArgs = {
  where: Candidate_Referrals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Referrals_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_RoleArgs = {
  where: Candidate_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Role_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Role_SkillArgs = {
  where: Candidate_Role_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Role_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Sdlc_Experience_ChoicesArgs = {
  where: Candidate_Sdlc_Experience_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Sdlc_Experience_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_SkillArgs = {
  where: Candidate_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Skill_Comp_ChoicesArgs = {
  where: Candidate_Skill_Comp_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Skill_Comp_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Source_ChoicesArgs = {
  where: Candidate_Source_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Stage_ChoicesArgs = {
  where: Candidate_Stage_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Stage_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Stage_Choices_OrderArgs = {
  where: Candidate_Stage_Choices_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Stage_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Stage_LogArgs = {
  where: Candidate_Stage_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Stage_Log_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Stage_Source_ChoicesArgs = {
  where: Candidate_Stage_Source_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Stage_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_TraitArgs = {
  where: Candidate_Trait_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Trait_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Work_ExperienceArgs = {
  where: Candidate_Work_Experience_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Work_Experience_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Work_Experience_SkillArgs = {
  where: Candidate_Work_Experience_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Candidate_Work_Experience_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Candidates_In_Interview_Or_Rejected_By_Organization_ResultArgs = {
  where: Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Celery_Celery_TaskmetaArgs = {
  where: Celery_Celery_Taskmeta_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Celery_Celery_Taskmeta_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Celery_Celery_TasksetmetaArgs = {
  where: Celery_Celery_Tasksetmeta_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Celery_Celery_Tasksetmeta_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Celery_Task_Retry_LogArgs = {
  where: Celery_Task_Retry_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Celery_Task_Retry_Log_By_PkArgs = {
  new_task_id: Scalars['String'];
  original_task_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Client_ProspectArgs = {
  where: Client_Prospect_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Prospect_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Client_Prospect_EventArgs = {
  where: Client_Prospect_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Prospect_Event_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Client_Prospect_Events_ChoicesArgs = {
  where: Client_Prospect_Events_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Prospect_Events_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Client_Prospect_RoleArgs = {
  where: Client_Prospect_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Prospect_Role_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Client_Prospect_Role_SkillArgs = {
  where: Client_Prospect_Role_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Prospect_Role_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Client_VersionsArgs = {
  where: Client_Versions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Versions_By_PkArgs = {
  version: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Company_Name_SynonymArgs = {
  where: Company_Name_Synonym_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Company_Name_Synonym_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Company_Pdl_BlobArgs = {
  where: Company_Pdl_Blob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Company_Pdl_Blob_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Company_Size_ChoicesArgs = {
  where: Company_Size_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Company_Size_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_ContactArgs = {
  where: Contact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Contract_Length_ChoicesArgs = {
  where: Contract_Length_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Contract_Length_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Country_ChoicesArgs = {
  where: Country_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Country_Choices_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Currency_Exchange_RateArgs = {
  where: Currency_Exchange_Rate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Currency_Exchange_Rate_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Dayforce_AssignmentArgs = {
  where: Dayforce_Assignment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Dayforce_Assignment_By_PkArgs = {
  dayforce_customer_id: Scalars['Int'];
  dayforce_employee_id: Scalars['Int'];
  dayforce_location_id: Scalars['Int'];
  effective_start: Scalars['timestamp'];
};

/** mutation root */
export type Mutation_RootDelete_Dayforce_CustomerArgs = {
  where: Dayforce_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Dayforce_Customer_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Dayforce_EmployeeArgs = {
  where: Dayforce_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Dayforce_Employee_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Dayforce_LocationArgs = {
  where: Dayforce_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Dayforce_Location_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Email_NotificationArgs = {
  where: Email_Notification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Email_Notification_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Email_TemplateArgs = {
  where: Email_Template_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Email_Template_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Employment_Type_ChoicesArgs = {
  where: Employment_Type_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Employment_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Popular_SearchArgs = {
  where: Explore_Candidates_Popular_Search_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Popular_Search_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Query_ResultArgs = {
  where: Explore_Candidates_Query_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Query_Result_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_SearchArgs = {
  where: Explore_Candidates_Search_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Search_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Search_Candidate_HighlightArgs = {
  where: Explore_Candidates_Search_Candidate_Highlight_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Search_Candidate_Highlight_By_PkArgs = {
  candidate_highlight: Candidate_Highlight_Choices_Enum;
  explore_candidates_search_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Search_Must_Have_SkillArgs = {
  where: Explore_Candidates_Search_Must_Have_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Search_Must_Have_Skill_By_PkArgs = {
  explore_candidates_search_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Search_Nice_To_Have_SkillArgs = {
  where: Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Search_Nice_To_Have_Skill_By_PkArgs = {
  explore_candidates_search_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Search_RegionArgs = {
  where: Explore_Candidates_Search_Region_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_Search_Region_By_PkArgs = {
  explore_candidates_search_id: Scalars['Int'];
  region: Region_Choices_Enum;
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_User_Saved_SearchArgs = {
  where: Explore_Candidates_User_Saved_Search_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Explore_Candidates_User_Saved_Search_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_External_Candidate_MetaArgs = {
  where: External_Candidate_Meta_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_External_Candidate_Meta_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_FeatureArgs = {
  where: Feature_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Feature_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Hireflix_TranscriptArgs = {
  where: Hireflix_Transcript_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hireflix_Transcript_BlobArgs = {
  where: Hireflix_Transcript_Blob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hireflix_Transcript_Blob_By_PkArgs = {
  interview_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Hireflix_Transcript_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Hr_TestArgs = {
  where: Hr_Test_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hr_Test_By_PkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Hr_Test_CandidateArgs = {
  where: Hr_Test_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hr_Test_Candidate_By_PkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Hr_Test_ResultArgs = {
  where: Hr_Test_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hr_Test_Result_By_PkArgs = {
  attempt_id: Scalars['String'];
  hr_test_candidate_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Applicant_WorkflowArgs = {
  where: Icims_Applicant_Workflow_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Applicant_Workflow_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Applicant_Workflow_FeedbackArgs = {
  where: Icims_Applicant_Workflow_Feedback_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Applicant_Workflow_Feedback_By_PkArgs = {
  applicant_workflow_profile_id: Scalars['Int'];
  id: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Applicant_Workflow_StatusArgs = {
  where: Icims_Applicant_Workflow_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Applicant_Workflow_Status_By_PkArgs = {
  applicant_workflow_profile_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  initial_data_transfer: Scalars['Int'];
  status_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_CompanyArgs = {
  where: Icims_Company_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Company_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_CountryArgs = {
  where: Icims_Country_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Country_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_DegreeArgs = {
  where: Icims_Degree_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Degree_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_EducationArgs = {
  where: Icims_Education_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Education_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_FolderArgs = {
  where: Icims_Folder_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Folder_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Initial_Data_TransferArgs = {
  where: Icims_Initial_Data_Transfer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Initial_Data_Transfer_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Interested_LocationArgs = {
  where: Icims_Interested_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Interested_Location_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_JobArgs = {
  where: Icims_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Job_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Job_LocationArgs = {
  where: Icims_Job_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Job_Location_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Job_Requested_LocationArgs = {
  where: Icims_Job_Requested_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Job_Requested_Location_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
  location: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Job_SkillArgs = {
  where: Icims_Job_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Job_Skill_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_LocationArgs = {
  where: Icims_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Location_By_PkArgs = {
  id: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_MajorArgs = {
  where: Icims_Major_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Major_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_PersonArgs = {
  where: Icims_Person_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Person_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Person_Interested_LocationArgs = {
  where: Icims_Person_Interested_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Person_Interested_Location_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  interested_location: Scalars['String'];
  person_profile_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Person_Preferred_LocationArgs = {
  where: Icims_Person_Preferred_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Person_Preferred_Location_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Person_SkillArgs = {
  where: Icims_Person_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Person_Skill_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Profile_Applicant_WorkflowArgs = {
  where: Icims_Profile_Applicant_Workflow_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Profile_Applicant_Workflow_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Profile_JobArgs = {
  where: Icims_Profile_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Profile_Job_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Profile_LocationArgs = {
  where: Icims_Profile_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Profile_Location_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Profile_PersonArgs = {
  where: Icims_Profile_Person_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Profile_Person_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Profile_Requested_LocationArgs = {
  where: Icims_Profile_Requested_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Profile_Requested_Location_By_PkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Requested_LocationArgs = {
  where: Icims_Requested_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Requested_Location_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_SchoolArgs = {
  where: Icims_School_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_School_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_SkillArgs = {
  where: Icims_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Skill_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_StatusArgs = {
  where: Icims_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Status_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Sync_Status_ChoicesArgs = {
  where: Icims_Sync_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Sync_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Icims_Work_ExperienceArgs = {
  where: Icims_Work_Experience_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Icims_Work_Experience_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_IndustryArgs = {
  where: Industry_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Industry_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Initial_Sell_Type_ChoicesArgs = {
  where: Initial_Sell_Type_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Initial_Sell_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Interview_DetailArgs = {
  where: Interview_Detail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Interview_Detail_By_PkArgs = {
  job_id: Scalars['Int'];
  order: Scalars['smallint'];
};

/** mutation root */
export type Mutation_RootDelete_Interview_FeedbackArgs = {
  where: Interview_Feedback_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Interview_Feedback_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Interview_Feedback_Rating_ChoicesArgs = {
  where: Interview_Feedback_Rating_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Interview_Feedback_Rating_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Interview_Feedback_RequestArgs = {
  where: Interview_Feedback_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Interview_Feedback_Request_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Interview_RequestArgs = {
  where: Interview_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Interview_Request_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Interview_Type_ChoicesArgs = {
  where: Interview_Type_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Interview_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_JobArgs = {
  where: Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Acceptable_LocationArgs = {
  where: Job_Acceptable_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Acceptable_Location_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Job_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Job_CodeArgs = {
  where: Job_Code_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Code_By_PkArgs = {
  code: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Job_Level_ChoicesArgs = {
  where: Job_Level_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Level_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Job_Meta_InfoArgs = {
  where: Job_Meta_Info_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Meta_Info_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Job_Nice_To_Have_SkillArgs = {
  where: Job_Nice_To_Have_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Nice_To_Have_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Job_PositionArgs = {
  where: Job_Position_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Position_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Job_Priority_ChoicesArgs = {
  where: Job_Priority_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Priority_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Job_Required_SkillArgs = {
  where: Job_Required_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Required_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Job_Role_Source_ChoicesArgs = {
  where: Job_Role_Source_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Role_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Job_Service_TypeArgs = {
  where: Job_Service_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Service_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Job_Status_ChoicesArgs = {
  where: Job_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Job_Status_Choices_OrderArgs = {
  where: Job_Status_Choices_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Status_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Job_Status_LogArgs = {
  where: Job_Status_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Status_Log_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Job_Tech_StackArgs = {
  where: Job_Tech_Stack_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Tech_Stack_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Linkedin_ContactArgs = {
  where: Linkedin_Contact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Linkedin_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_LocationArgs = {
  where: Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Location_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Manual_ContactArgs = {
  where: Manual_Contact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Manual_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_ApplicationArgs = {
  where: Merge_Ats_Application_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_Application_By_PkArgs = {
  merge_application_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_Application_LogArgs = {
  where: Merge_Ats_Application_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_Application_Log_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_CandidateArgs = {
  where: Merge_Ats_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_Candidate_By_PkArgs = {
  merge_candidate_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_JobArgs = {
  where: Merge_Ats_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_Job_By_PkArgs = {
  merge_job_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_Job_Interview_StageArgs = {
  where: Merge_Ats_Job_Interview_Stage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_Job_Interview_Stage_By_PkArgs = {
  merge_interview_stage_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_Linked_AccountArgs = {
  where: Merge_Ats_Linked_Account_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Merge_Ats_Linked_Account_By_PkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Mixpanel_ProfilesArgs = {
  where: Mixpanel_Profiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Mixpanel_Profiles_By_PkArgs = {
  distinct_id: Scalars['String'];
  email: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Offering_Selection_ChoicesArgs = {
  where: Offering_Selection_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Offering_Selection_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_OrganizationArgs = {
  where: Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_ContactArgs = {
  where: Organization_Contact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_DomainArgs = {
  where: Organization_Domain_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Domain_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_EmployeeArgs = {
  where: Organization_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Employee_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_Icims_Jobs_ResultArgs = {
  where: Organization_Icims_Jobs_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_LeadershipArgs = {
  where: Organization_Leadership_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Leadership_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_Type_ChoicesArgs = {
  where: Organization_Type_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_VideoArgs = {
  where: Organization_Video_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Video_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Pillar_TranscriptArgs = {
  where: Pillar_Transcript_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Pillar_Transcript_BlobArgs = {
  where: Pillar_Transcript_Blob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Pillar_Transcript_Blob_By_PkArgs = {
  transcript_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Pillar_Transcript_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Recruiter_Contact_ActionArgs = {
  where: Recruiter_Contact_Action_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Recruiter_Contact_Action_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Referral_StatusArgs = {
  where: Referral_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Referral_Status_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Referral_Status_CurrentArgs = {
  where: Referral_Status_Current_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Referral_Status_Current_By_PkArgs = {
  contact: Scalars['Int'];
  user: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Region_ChoicesArgs = {
  where: Region_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Region_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Reporting_Candidate_MetadataArgs = {
  where: Reporting_Candidate_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Reporting_Report_Job_MatchesArgs = {
  where: Reporting_Report_Job_Matches_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Reporting_Report_Job_Matches_By_PkArgs = {
  created_at: Scalars['timestamptz'];
};

/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Role_ChoicesArgs = {
  where: Role_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Salary_InfoArgs = {
  where: Salary_Info_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Salary_Info_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Salary_Years_Experience_Range_ChoicesArgs = {
  where: Salary_Years_Experience_Range_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Salary_Years_Experience_Range_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Salesforce_CustomerArgs = {
  where: Salesforce_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Salesforce_Customer_By_PkArgs = {
  salesforce_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Salesforce_Customer_LocationArgs = {
  where: Salesforce_Customer_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Salesforce_Customer_Location_By_PkArgs = {
  salesforce_id: Scalars['String'];
  salesforce_location_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Salesforce_LocationArgs = {
  where: Salesforce_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Salesforce_Location_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Saved_CandidateArgs = {
  where: Saved_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Saved_Candidate_By_PkArgs = {
  candidate_id: Scalars['Int'];
  saved_by_user_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Segment_Sendgrid_Prod_ActivityArgs = {
  where: Segment_Sendgrid_Prod_Activity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Segment_Sendgrid_Prod_Activity_By_PkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Shared_CandidateArgs = {
  where: Shared_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Shared_Candidate_By_PkArgs = {
  candidate_id: Scalars['Int'];
  user_info_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_SkillArgs = {
  where: Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Skill_FeatureArgs = {
  where: Skill_Feature_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Skill_Feature_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Skill_Icims_SkillArgs = {
  where: Skill_Icims_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Skill_Icims_Skill_By_PkArgs = {
  icims_skill_id: Scalars['String'];
  skill_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Skill_SynonymArgs = {
  where: Skill_Synonym_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Skill_Synonym_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_CandidateArgs = {
  where: Spike_Matching_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Candidate_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Candidate_SkillArgs = {
  where: Spike_Matching_Candidate_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Candidate_Skill_By_PkArgs = {
  candidate_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_JobArgs = {
  where: Spike_Matching_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Job_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Job_SkillArgs = {
  where: Spike_Matching_Job_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Job_Skill_By_PkArgs = {
  job_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_LocationArgs = {
  where: Spike_Matching_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Location_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Location_JobArgs = {
  where: Spike_Matching_Location_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Location_Job_By_PkArgs = {
  job_id: Scalars['Int'];
  location_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Match_ResultArgs = {
  where: Spike_Matching_Match_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Match_Result_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_SkillArgs = {
  where: Spike_Matching_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Spike_Matching_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Terminal_Celery_Task_Status_ChoicesArgs = {
  where: Terminal_Celery_Task_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Terminal_Celery_Task_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Terminal_EmployeeArgs = {
  where: Terminal_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Terminal_Employee_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Terminal_Employee_OrganizationArgs = {
  where: Terminal_Employee_Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Terminal_Employee_Organization_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Terminal_Employee_Role_ChoicesArgs = {
  where: Terminal_Employee_Role_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Terminal_Employee_Role_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_TraitArgs = {
  where: Trait_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Trait_By_PkArgs = {
  code: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Transcript_EvaluationArgs = {
  where: Transcript_Evaluation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Transcript_Evaluation_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Transcript_SkillArgs = {
  where: Transcript_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Transcript_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Transcript_Work_ExperienceArgs = {
  where: Transcript_Work_Experience_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Transcript_Work_Experience_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_User_InfoArgs = {
  where: User_Info_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Info_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_User_InvitationArgs = {
  where: User_Invitation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Invitation_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_User_Linkedin_ContactArgs = {
  where: User_Linkedin_Contact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Linkedin_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_User_Password_BlacklistArgs = {
  where: User_Password_Blacklist_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Password_Blacklist_By_PkArgs = {
  password: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_User_RoleArgs = {
  where: User_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Role_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_User_SubscriptionArgs = {
  where: User_Subscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Subscription_By_PkArgs = {
  subscription: User_Subscription_Choices_Enum;
  user_info_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_User_Subscription_ChoicesArgs = {
  where: User_Subscription_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Subscription_Choices_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_User_Temp_BlobArgs = {
  where: User_Temp_Blob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Temp_Blob_By_PkArgs = {
  firebase_uid: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_ValidatorArgs = {
  where: Validator_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Validator_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootInsert_Activity_Customer_Profile_ViewArgs = {
  objects: Array<Activity_Customer_Profile_View_Insert_Input>;
  on_conflict?: InputMaybe<Activity_Customer_Profile_View_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Activity_Customer_Profile_View_OneArgs = {
  object: Activity_Customer_Profile_View_Insert_Input;
  on_conflict?: InputMaybe<Activity_Customer_Profile_View_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_LevelArgs = {
  objects: Array<Ai_Level_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Level_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Level_OneArgs = {
  object: Ai_Level_Insert_Input;
  on_conflict?: InputMaybe<Ai_Level_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_RoleArgs = {
  objects: Array<Ai_Role_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Role_Multiplier_FocusArgs = {
  objects: Array<Ai_Role_Multiplier_Focus_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Role_Multiplier_Focus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Role_Multiplier_Focus_OneArgs = {
  object: Ai_Role_Multiplier_Focus_Insert_Input;
  on_conflict?: InputMaybe<Ai_Role_Multiplier_Focus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Role_OneArgs = {
  object: Ai_Role_Insert_Input;
  on_conflict?: InputMaybe<Ai_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Run_DumpArgs = {
  objects: Array<Ai_Run_Dump_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Run_Dump_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Run_Dump_OneArgs = {
  object: Ai_Run_Dump_Insert_Input;
  on_conflict?: InputMaybe<Ai_Run_Dump_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Applicant_ConsentArgs = {
  objects: Array<Applicant_Consent_Insert_Input>;
  on_conflict?: InputMaybe<Applicant_Consent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Applicant_Consent_OneArgs = {
  object: Applicant_Consent_Insert_Input;
  on_conflict?: InputMaybe<Applicant_Consent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Applicant_JobArgs = {
  objects: Array<Applicant_Job_Insert_Input>;
  on_conflict?: InputMaybe<Applicant_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Applicant_Job_OneArgs = {
  object: Applicant_Job_Insert_Input;
  on_conflict?: InputMaybe<Applicant_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Applicant_WaitlistArgs = {
  objects: Array<Applicant_Waitlist_Insert_Input>;
  on_conflict?: InputMaybe<Applicant_Waitlist_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Applicant_Waitlist_OneArgs = {
  object: Applicant_Waitlist_Insert_Input;
  on_conflict?: InputMaybe<Applicant_Waitlist_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Logged_ActionsArgs = {
  objects: Array<Audit_Logged_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Audit_Logged_Actions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Logged_Actions_OneArgs = {
  object: Audit_Logged_Actions_Insert_Input;
  on_conflict?: InputMaybe<Audit_Logged_Actions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendly_EventArgs = {
  objects: Array<Calendly_Event_Insert_Input>;
  on_conflict?: InputMaybe<Calendly_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendly_Event_OneArgs = {
  object: Calendly_Event_Insert_Input;
  on_conflict?: InputMaybe<Calendly_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendly_InviteeArgs = {
  objects: Array<Calendly_Invitee_Insert_Input>;
  on_conflict?: InputMaybe<Calendly_Invitee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendly_Invitee_OneArgs = {
  object: Calendly_Invitee_Insert_Input;
  on_conflict?: InputMaybe<Calendly_Invitee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendly_UserArgs = {
  objects: Array<Calendly_User_Insert_Input>;
  on_conflict?: InputMaybe<Calendly_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Calendly_User_OneArgs = {
  object: Calendly_User_Insert_Input;
  on_conflict?: InputMaybe<Calendly_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CandidateArgs = {
  objects: Array<Candidate_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Ai_Profile_SummaryArgs = {
  objects: Array<Candidate_Ai_Profile_Summary_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Ai_Profile_Summary_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Ai_Profile_Summary_OneArgs = {
  object: Candidate_Ai_Profile_Summary_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Ai_Profile_Summary_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Applicant_Workflow_Stats_ResultArgs = {
  objects: Array<Candidate_Applicant_Workflow_Stats_Result_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Applicant_Workflow_Stats_Result_OneArgs = {
  object: Candidate_Applicant_Workflow_Stats_Result_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Approved_CountryArgs = {
  objects: Array<Candidate_Approved_Country_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Approved_Country_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Approved_Country_OneArgs = {
  object: Candidate_Approved_Country_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Approved_Country_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Availability_ChoicesArgs = {
  objects: Array<Candidate_Availability_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Availability_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Availability_Choices_OneArgs = {
  object: Candidate_Availability_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Availability_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Availability_Choices_OrderArgs = {
  objects: Array<Candidate_Availability_Choices_Order_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Availability_Choices_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Availability_Choices_Order_OneArgs = {
  object: Candidate_Availability_Choices_Order_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Availability_Choices_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_BadgeArgs = {
  objects: Array<Candidate_Badge_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Badge_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Badge_OneArgs = {
  object: Candidate_Badge_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Badge_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Browse_ResultArgs = {
  objects: Array<Candidate_Browse_Result_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Browse_Result_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Browse_Result_OneArgs = {
  object: Candidate_Browse_Result_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Browse_Result_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Ai_SummaryArgs = {
  objects: Array<Candidate_Curation_Ai_Summary_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Ai_Summary_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Ai_Summary_FeedbackArgs = {
  objects: Array<Candidate_Curation_Ai_Summary_Feedback_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Ai_Summary_Feedback_OneArgs = {
  object: Candidate_Curation_Ai_Summary_Feedback_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Ai_Summary_OneArgs = {
  object: Candidate_Curation_Ai_Summary_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Ai_Summary_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Country_ChoicesArgs = {
  objects: Array<Candidate_Curation_Country_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Country_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Country_Choices_OneArgs = {
  object: Candidate_Curation_Country_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Country_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Desired_Salary_Currency_ChoicesArgs = {
  objects: Array<Candidate_Curation_Desired_Salary_Currency_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Desired_Salary_Currency_Choices_OneArgs = {
  object: Candidate_Curation_Desired_Salary_Currency_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Desired_Salary_Frequency_ChoicesArgs = {
  objects: Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Desired_Salary_Frequency_Choices_OneArgs = {
  object: Candidate_Curation_Desired_Salary_Frequency_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_DetailArgs = {
  objects: Array<Candidate_Curation_Detail_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Detail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Detail_OneArgs = {
  object: Candidate_Curation_Detail_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Detail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Detail_Recruiter_Summary_LogArgs = {
  objects: Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Detail_Recruiter_Summary_Log_OneArgs = {
  object: Candidate_Curation_Detail_Recruiter_Summary_Log_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_English_Proficiency_ChoicesArgs = {
  objects: Array<Candidate_Curation_English_Proficiency_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_English_Proficiency_Choices_OneArgs = {
  object: Candidate_Curation_English_Proficiency_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Equity_Importance_ChoicesArgs = {
  objects: Array<Candidate_Curation_Equity_Importance_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Equity_Importance_Choices_OneArgs = {
  object: Candidate_Curation_Equity_Importance_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_NoteArgs = {
  objects: Array<Candidate_Curation_Note_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Note_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Note_OneArgs = {
  object: Candidate_Curation_Note_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Note_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Rejected_Reason_ChoicesArgs = {
  objects: Array<Candidate_Curation_Rejected_Reason_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Rejected_Reason_Choices_OneArgs = {
  object: Candidate_Curation_Rejected_Reason_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Remote_Work_Exp_ChoicesArgs = {
  objects: Array<Candidate_Curation_Remote_Work_Exp_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Remote_Work_Exp_Choices_OneArgs = {
  object: Candidate_Curation_Remote_Work_Exp_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Visa_Status_ChoicesArgs = {
  objects: Array<Candidate_Curation_Visa_Status_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Visa_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Visa_Status_Choices_OneArgs = {
  object: Candidate_Curation_Visa_Status_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Visa_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_WorkflowArgs = {
  objects: Array<Candidate_Curation_Workflow_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Needs_Info_ReasonArgs = {
  objects: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Needs_Info_Reason_ChoicesArgs = {
  objects: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Needs_Info_Reason_Choices_OneArgs = {
  object: Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Needs_Info_Reason_OneArgs = {
  object: Candidate_Curation_Workflow_Needs_Info_Reason_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_OneArgs = {
  object: Candidate_Curation_Workflow_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Screener_Type_ChoicesArgs = {
  objects: Array<Candidate_Curation_Workflow_Screener_Type_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Screener_Type_Choices_OneArgs = {
  object: Candidate_Curation_Workflow_Screener_Type_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Source_ChoicesArgs = {
  objects: Array<Candidate_Curation_Workflow_Source_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Source_Choices_OneArgs = {
  object: Candidate_Curation_Workflow_Source_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Status_ChoicesArgs = {
  objects: Array<Candidate_Curation_Workflow_Status_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Status_Choices_OneArgs = {
  object: Candidate_Curation_Workflow_Status_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Status_LogArgs = {
  objects: Array<Candidate_Curation_Workflow_Status_Log_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Status_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Workflow_Status_Log_OneArgs = {
  object: Candidate_Curation_Workflow_Status_Log_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Workflow_Status_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Years_Of_Exp_Range_ChoicesArgs = {
  objects: Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Years_Of_Exp_Range_Choices_OneArgs = {
  object: Candidate_Curation_Years_Of_Exp_Range_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Years_Of_Exp_Range_Choices_OrderArgs = {
  objects: Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Curation_Years_Of_Exp_Range_Choices_Order_OneArgs = {
  object: Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Detail_Level_ChoicesArgs = {
  objects: Array<Candidate_Detail_Level_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Detail_Level_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Detail_Level_Choices_OneArgs = {
  object: Candidate_Detail_Level_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Detail_Level_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_EducationArgs = {
  objects: Array<Candidate_Education_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Education_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Education_Concentration_ChoicesArgs = {
  objects: Array<Candidate_Education_Concentration_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Education_Concentration_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Education_Concentration_Choices_OneArgs = {
  object: Candidate_Education_Concentration_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Education_Concentration_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Education_Degree_ChoicesArgs = {
  objects: Array<Candidate_Education_Degree_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Education_Degree_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Education_Degree_Choices_OneArgs = {
  object: Candidate_Education_Degree_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Education_Degree_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Education_OneArgs = {
  object: Candidate_Education_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Education_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_EmailArgs = {
  objects: Array<Candidate_Email_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Email_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Email_OneArgs = {
  object: Candidate_Email_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Email_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_EmbeddingArgs = {
  objects: Array<Candidate_Embedding_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Embedding_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Embedding_OneArgs = {
  object: Candidate_Embedding_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Embedding_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Highlight_ChoicesArgs = {
  objects: Array<Candidate_Highlight_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Highlight_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Highlight_Choices_OneArgs = {
  object: Candidate_Highlight_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Highlight_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Hub_LocationArgs = {
  objects: Array<Candidate_Hub_Location_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Hub_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Hub_Location_OneArgs = {
  object: Candidate_Hub_Location_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Hub_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Interview_InviteArgs = {
  objects: Array<Candidate_Interview_Invite_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Interview_Invite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Interview_Invite_OneArgs = {
  object: Candidate_Interview_Invite_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Interview_Invite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_JobArgs = {
  objects: Array<Candidate_Job_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Job_OneArgs = {
  object: Candidate_Job_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Job_WorkflowArgs = {
  objects: Array<Candidate_Job_Workflow_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Job_Workflow_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Job_Workflow_OneArgs = {
  object: Candidate_Job_Workflow_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Job_Workflow_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Job_Workflow_Source_ChoicesArgs = {
  objects: Array<Candidate_Job_Workflow_Source_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Job_Workflow_Source_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Job_Workflow_Source_Choices_OneArgs = {
  object: Candidate_Job_Workflow_Source_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Job_Workflow_Source_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Job_Workflow_StatusArgs = {
  objects: Array<Candidate_Job_Workflow_Status_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Job_Workflow_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Job_Workflow_Status_ChoicesArgs = {
  objects: Array<Candidate_Job_Workflow_Status_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Job_Workflow_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Job_Workflow_Status_Choices_OneArgs = {
  object: Candidate_Job_Workflow_Status_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Job_Workflow_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Job_Workflow_Status_OneArgs = {
  object: Candidate_Job_Workflow_Status_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Job_Workflow_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_OneArgs = {
  object: Candidate_Insert_Input;
  on_conflict?: InputMaybe<Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Parsed_ResumeArgs = {
  objects: Array<Candidate_Parsed_Resume_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Parsed_Resume_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Parsed_Resume_OneArgs = {
  object: Candidate_Parsed_Resume_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Parsed_Resume_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_PdlArgs = {
  objects: Array<Candidate_Pdl_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Pdl_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Pdl_OneArgs = {
  object: Candidate_Pdl_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Pdl_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_ReferralsArgs = {
  objects: Array<Candidate_Referrals_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Referrals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Referrals_OneArgs = {
  object: Candidate_Referrals_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Referrals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_RoleArgs = {
  objects: Array<Candidate_Role_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Role_OneArgs = {
  object: Candidate_Role_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Role_SkillArgs = {
  objects: Array<Candidate_Role_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Role_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Role_Skill_OneArgs = {
  object: Candidate_Role_Skill_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Role_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Sdlc_Experience_ChoicesArgs = {
  objects: Array<Candidate_Sdlc_Experience_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Sdlc_Experience_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Sdlc_Experience_Choices_OneArgs = {
  object: Candidate_Sdlc_Experience_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Sdlc_Experience_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_SkillArgs = {
  objects: Array<Candidate_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Skill_Comp_ChoicesArgs = {
  objects: Array<Candidate_Skill_Comp_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Skill_Comp_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Skill_Comp_Choices_OneArgs = {
  object: Candidate_Skill_Comp_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Skill_Comp_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Skill_OneArgs = {
  object: Candidate_Skill_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Source_ChoicesArgs = {
  objects: Array<Candidate_Source_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Source_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Source_Choices_OneArgs = {
  object: Candidate_Source_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Source_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Stage_ChoicesArgs = {
  objects: Array<Candidate_Stage_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Stage_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Stage_Choices_OneArgs = {
  object: Candidate_Stage_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Stage_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Stage_Choices_OrderArgs = {
  objects: Array<Candidate_Stage_Choices_Order_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Stage_Choices_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Stage_Choices_Order_OneArgs = {
  object: Candidate_Stage_Choices_Order_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Stage_Choices_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Stage_LogArgs = {
  objects: Array<Candidate_Stage_Log_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Stage_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Stage_Log_OneArgs = {
  object: Candidate_Stage_Log_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Stage_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Stage_Source_ChoicesArgs = {
  objects: Array<Candidate_Stage_Source_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Stage_Source_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Stage_Source_Choices_OneArgs = {
  object: Candidate_Stage_Source_Choices_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Stage_Source_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_TraitArgs = {
  objects: Array<Candidate_Trait_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Trait_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Trait_OneArgs = {
  object: Candidate_Trait_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Trait_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Work_ExperienceArgs = {
  objects: Array<Candidate_Work_Experience_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Work_Experience_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Work_Experience_OneArgs = {
  object: Candidate_Work_Experience_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Work_Experience_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Work_Experience_SkillArgs = {
  objects: Array<Candidate_Work_Experience_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Candidate_Work_Experience_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidate_Work_Experience_Skill_OneArgs = {
  object: Candidate_Work_Experience_Skill_Insert_Input;
  on_conflict?: InputMaybe<Candidate_Work_Experience_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Candidates_In_Interview_Or_Rejected_By_Organization_ResultArgs = {
  objects: Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Candidates_In_Interview_Or_Rejected_By_Organization_Result_OneArgs =
  {
    object: Candidates_In_Interview_Or_Rejected_By_Organization_Result_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Celery_Celery_TaskmetaArgs = {
  objects: Array<Celery_Celery_Taskmeta_Insert_Input>;
  on_conflict?: InputMaybe<Celery_Celery_Taskmeta_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Celery_Celery_Taskmeta_OneArgs = {
  object: Celery_Celery_Taskmeta_Insert_Input;
  on_conflict?: InputMaybe<Celery_Celery_Taskmeta_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Celery_Celery_TasksetmetaArgs = {
  objects: Array<Celery_Celery_Tasksetmeta_Insert_Input>;
  on_conflict?: InputMaybe<Celery_Celery_Tasksetmeta_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Celery_Celery_Tasksetmeta_OneArgs = {
  object: Celery_Celery_Tasksetmeta_Insert_Input;
  on_conflict?: InputMaybe<Celery_Celery_Tasksetmeta_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Celery_Task_Retry_LogArgs = {
  objects: Array<Celery_Task_Retry_Log_Insert_Input>;
  on_conflict?: InputMaybe<Celery_Task_Retry_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Celery_Task_Retry_Log_OneArgs = {
  object: Celery_Task_Retry_Log_Insert_Input;
  on_conflict?: InputMaybe<Celery_Task_Retry_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_ProspectArgs = {
  objects: Array<Client_Prospect_Insert_Input>;
  on_conflict?: InputMaybe<Client_Prospect_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Prospect_EventArgs = {
  objects: Array<Client_Prospect_Event_Insert_Input>;
  on_conflict?: InputMaybe<Client_Prospect_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Prospect_Event_OneArgs = {
  object: Client_Prospect_Event_Insert_Input;
  on_conflict?: InputMaybe<Client_Prospect_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Prospect_Events_ChoicesArgs = {
  objects: Array<Client_Prospect_Events_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Client_Prospect_Events_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Prospect_Events_Choices_OneArgs = {
  object: Client_Prospect_Events_Choices_Insert_Input;
  on_conflict?: InputMaybe<Client_Prospect_Events_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Prospect_OneArgs = {
  object: Client_Prospect_Insert_Input;
  on_conflict?: InputMaybe<Client_Prospect_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Prospect_RoleArgs = {
  objects: Array<Client_Prospect_Role_Insert_Input>;
  on_conflict?: InputMaybe<Client_Prospect_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Prospect_Role_OneArgs = {
  object: Client_Prospect_Role_Insert_Input;
  on_conflict?: InputMaybe<Client_Prospect_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Prospect_Role_SkillArgs = {
  objects: Array<Client_Prospect_Role_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Client_Prospect_Role_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Prospect_Role_Skill_OneArgs = {
  object: Client_Prospect_Role_Skill_Insert_Input;
  on_conflict?: InputMaybe<Client_Prospect_Role_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_VersionsArgs = {
  objects: Array<Client_Versions_Insert_Input>;
  on_conflict?: InputMaybe<Client_Versions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Versions_OneArgs = {
  object: Client_Versions_Insert_Input;
  on_conflict?: InputMaybe<Client_Versions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_Name_SynonymArgs = {
  objects: Array<Company_Name_Synonym_Insert_Input>;
  on_conflict?: InputMaybe<Company_Name_Synonym_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_Name_Synonym_OneArgs = {
  object: Company_Name_Synonym_Insert_Input;
  on_conflict?: InputMaybe<Company_Name_Synonym_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_Pdl_BlobArgs = {
  objects: Array<Company_Pdl_Blob_Insert_Input>;
  on_conflict?: InputMaybe<Company_Pdl_Blob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_Pdl_Blob_OneArgs = {
  object: Company_Pdl_Blob_Insert_Input;
  on_conflict?: InputMaybe<Company_Pdl_Blob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_Size_ChoicesArgs = {
  objects: Array<Company_Size_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Company_Size_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_Size_Choices_OneArgs = {
  object: Company_Size_Choices_Insert_Input;
  on_conflict?: InputMaybe<Company_Size_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactArgs = {
  objects: Array<Contact_Insert_Input>;
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contact_OneArgs = {
  object: Contact_Insert_Input;
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contract_Length_ChoicesArgs = {
  objects: Array<Contract_Length_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Contract_Length_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Contract_Length_Choices_OneArgs = {
  object: Contract_Length_Choices_Insert_Input;
  on_conflict?: InputMaybe<Contract_Length_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Country_ChoicesArgs = {
  objects: Array<Country_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Country_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Country_Choices_OneArgs = {
  object: Country_Choices_Insert_Input;
  on_conflict?: InputMaybe<Country_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Currency_Exchange_RateArgs = {
  objects: Array<Currency_Exchange_Rate_Insert_Input>;
  on_conflict?: InputMaybe<Currency_Exchange_Rate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Currency_Exchange_Rate_OneArgs = {
  object: Currency_Exchange_Rate_Insert_Input;
  on_conflict?: InputMaybe<Currency_Exchange_Rate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dayforce_AssignmentArgs = {
  objects: Array<Dayforce_Assignment_Insert_Input>;
  on_conflict?: InputMaybe<Dayforce_Assignment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dayforce_Assignment_OneArgs = {
  object: Dayforce_Assignment_Insert_Input;
  on_conflict?: InputMaybe<Dayforce_Assignment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dayforce_CustomerArgs = {
  objects: Array<Dayforce_Customer_Insert_Input>;
  on_conflict?: InputMaybe<Dayforce_Customer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dayforce_Customer_OneArgs = {
  object: Dayforce_Customer_Insert_Input;
  on_conflict?: InputMaybe<Dayforce_Customer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dayforce_EmployeeArgs = {
  objects: Array<Dayforce_Employee_Insert_Input>;
  on_conflict?: InputMaybe<Dayforce_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dayforce_Employee_OneArgs = {
  object: Dayforce_Employee_Insert_Input;
  on_conflict?: InputMaybe<Dayforce_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dayforce_LocationArgs = {
  objects: Array<Dayforce_Location_Insert_Input>;
  on_conflict?: InputMaybe<Dayforce_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dayforce_Location_OneArgs = {
  object: Dayforce_Location_Insert_Input;
  on_conflict?: InputMaybe<Dayforce_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_NotificationArgs = {
  objects: Array<Email_Notification_Insert_Input>;
  on_conflict?: InputMaybe<Email_Notification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_Notification_OneArgs = {
  object: Email_Notification_Insert_Input;
  on_conflict?: InputMaybe<Email_Notification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_TemplateArgs = {
  objects: Array<Email_Template_Insert_Input>;
  on_conflict?: InputMaybe<Email_Template_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_Template_OneArgs = {
  object: Email_Template_Insert_Input;
  on_conflict?: InputMaybe<Email_Template_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Employment_Type_ChoicesArgs = {
  objects: Array<Employment_Type_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Employment_Type_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Employment_Type_Choices_OneArgs = {
  object: Employment_Type_Choices_Insert_Input;
  on_conflict?: InputMaybe<Employment_Type_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Popular_SearchArgs = {
  objects: Array<Explore_Candidates_Popular_Search_Insert_Input>;
  on_conflict?: InputMaybe<Explore_Candidates_Popular_Search_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Popular_Search_OneArgs = {
  object: Explore_Candidates_Popular_Search_Insert_Input;
  on_conflict?: InputMaybe<Explore_Candidates_Popular_Search_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Query_ResultArgs = {
  objects: Array<Explore_Candidates_Query_Result_Insert_Input>;
  on_conflict?: InputMaybe<Explore_Candidates_Query_Result_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Query_Result_OneArgs = {
  object: Explore_Candidates_Query_Result_Insert_Input;
  on_conflict?: InputMaybe<Explore_Candidates_Query_Result_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_SearchArgs = {
  objects: Array<Explore_Candidates_Search_Insert_Input>;
  on_conflict?: InputMaybe<Explore_Candidates_Search_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Search_Candidate_HighlightArgs = {
  objects: Array<Explore_Candidates_Search_Candidate_Highlight_Insert_Input>;
  on_conflict?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Search_Candidate_Highlight_OneArgs = {
  object: Explore_Candidates_Search_Candidate_Highlight_Insert_Input;
  on_conflict?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Search_Must_Have_SkillArgs = {
  objects: Array<Explore_Candidates_Search_Must_Have_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Search_Must_Have_Skill_OneArgs = {
  object: Explore_Candidates_Search_Must_Have_Skill_Insert_Input;
  on_conflict?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Search_Nice_To_Have_SkillArgs = {
  objects: Array<Explore_Candidates_Search_Nice_To_Have_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Search_Nice_To_Have_Skill_OneArgs = {
  object: Explore_Candidates_Search_Nice_To_Have_Skill_Insert_Input;
  on_conflict?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Search_OneArgs = {
  object: Explore_Candidates_Search_Insert_Input;
  on_conflict?: InputMaybe<Explore_Candidates_Search_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Search_RegionArgs = {
  objects: Array<Explore_Candidates_Search_Region_Insert_Input>;
  on_conflict?: InputMaybe<Explore_Candidates_Search_Region_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_Search_Region_OneArgs = {
  object: Explore_Candidates_Search_Region_Insert_Input;
  on_conflict?: InputMaybe<Explore_Candidates_Search_Region_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_User_Saved_SearchArgs = {
  objects: Array<Explore_Candidates_User_Saved_Search_Insert_Input>;
  on_conflict?: InputMaybe<Explore_Candidates_User_Saved_Search_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Explore_Candidates_User_Saved_Search_OneArgs = {
  object: Explore_Candidates_User_Saved_Search_Insert_Input;
  on_conflict?: InputMaybe<Explore_Candidates_User_Saved_Search_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_External_Candidate_MetaArgs = {
  objects: Array<External_Candidate_Meta_Insert_Input>;
  on_conflict?: InputMaybe<External_Candidate_Meta_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_External_Candidate_Meta_OneArgs = {
  object: External_Candidate_Meta_Insert_Input;
  on_conflict?: InputMaybe<External_Candidate_Meta_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FeatureArgs = {
  objects: Array<Feature_Insert_Input>;
  on_conflict?: InputMaybe<Feature_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feature_OneArgs = {
  object: Feature_Insert_Input;
  on_conflict?: InputMaybe<Feature_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hireflix_TranscriptArgs = {
  objects: Array<Hireflix_Transcript_Insert_Input>;
  on_conflict?: InputMaybe<Hireflix_Transcript_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hireflix_Transcript_BlobArgs = {
  objects: Array<Hireflix_Transcript_Blob_Insert_Input>;
  on_conflict?: InputMaybe<Hireflix_Transcript_Blob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hireflix_Transcript_Blob_OneArgs = {
  object: Hireflix_Transcript_Blob_Insert_Input;
  on_conflict?: InputMaybe<Hireflix_Transcript_Blob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hireflix_Transcript_OneArgs = {
  object: Hireflix_Transcript_Insert_Input;
  on_conflict?: InputMaybe<Hireflix_Transcript_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hr_TestArgs = {
  objects: Array<Hr_Test_Insert_Input>;
  on_conflict?: InputMaybe<Hr_Test_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hr_Test_CandidateArgs = {
  objects: Array<Hr_Test_Candidate_Insert_Input>;
  on_conflict?: InputMaybe<Hr_Test_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hr_Test_Candidate_OneArgs = {
  object: Hr_Test_Candidate_Insert_Input;
  on_conflict?: InputMaybe<Hr_Test_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hr_Test_OneArgs = {
  object: Hr_Test_Insert_Input;
  on_conflict?: InputMaybe<Hr_Test_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hr_Test_ResultArgs = {
  objects: Array<Hr_Test_Result_Insert_Input>;
  on_conflict?: InputMaybe<Hr_Test_Result_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hr_Test_Result_OneArgs = {
  object: Hr_Test_Result_Insert_Input;
  on_conflict?: InputMaybe<Hr_Test_Result_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Applicant_WorkflowArgs = {
  objects: Array<Icims_Applicant_Workflow_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Applicant_Workflow_FeedbackArgs = {
  objects: Array<Icims_Applicant_Workflow_Feedback_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_Feedback_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Applicant_Workflow_Feedback_OneArgs = {
  object: Icims_Applicant_Workflow_Feedback_Insert_Input;
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_Feedback_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Applicant_Workflow_OneArgs = {
  object: Icims_Applicant_Workflow_Insert_Input;
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Applicant_Workflow_StatusArgs = {
  objects: Array<Icims_Applicant_Workflow_Status_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Applicant_Workflow_Status_OneArgs = {
  object: Icims_Applicant_Workflow_Status_Insert_Input;
  on_conflict?: InputMaybe<Icims_Applicant_Workflow_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_CompanyArgs = {
  objects: Array<Icims_Company_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Company_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Company_OneArgs = {
  object: Icims_Company_Insert_Input;
  on_conflict?: InputMaybe<Icims_Company_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_CountryArgs = {
  objects: Array<Icims_Country_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Country_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Country_OneArgs = {
  object: Icims_Country_Insert_Input;
  on_conflict?: InputMaybe<Icims_Country_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_DegreeArgs = {
  objects: Array<Icims_Degree_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Degree_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Degree_OneArgs = {
  object: Icims_Degree_Insert_Input;
  on_conflict?: InputMaybe<Icims_Degree_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_EducationArgs = {
  objects: Array<Icims_Education_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Education_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Education_OneArgs = {
  object: Icims_Education_Insert_Input;
  on_conflict?: InputMaybe<Icims_Education_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_FolderArgs = {
  objects: Array<Icims_Folder_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Folder_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Folder_OneArgs = {
  object: Icims_Folder_Insert_Input;
  on_conflict?: InputMaybe<Icims_Folder_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Initial_Data_TransferArgs = {
  objects: Array<Icims_Initial_Data_Transfer_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Initial_Data_Transfer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Initial_Data_Transfer_OneArgs = {
  object: Icims_Initial_Data_Transfer_Insert_Input;
  on_conflict?: InputMaybe<Icims_Initial_Data_Transfer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Interested_LocationArgs = {
  objects: Array<Icims_Interested_Location_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Interested_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Interested_Location_OneArgs = {
  object: Icims_Interested_Location_Insert_Input;
  on_conflict?: InputMaybe<Icims_Interested_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_JobArgs = {
  objects: Array<Icims_Job_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Job_LocationArgs = {
  objects: Array<Icims_Job_Location_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Job_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Job_Location_OneArgs = {
  object: Icims_Job_Location_Insert_Input;
  on_conflict?: InputMaybe<Icims_Job_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Job_OneArgs = {
  object: Icims_Job_Insert_Input;
  on_conflict?: InputMaybe<Icims_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Job_Requested_LocationArgs = {
  objects: Array<Icims_Job_Requested_Location_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Job_Requested_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Job_Requested_Location_OneArgs = {
  object: Icims_Job_Requested_Location_Insert_Input;
  on_conflict?: InputMaybe<Icims_Job_Requested_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Job_SkillArgs = {
  objects: Array<Icims_Job_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Job_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Job_Skill_OneArgs = {
  object: Icims_Job_Skill_Insert_Input;
  on_conflict?: InputMaybe<Icims_Job_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_LocationArgs = {
  objects: Array<Icims_Location_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Location_OneArgs = {
  object: Icims_Location_Insert_Input;
  on_conflict?: InputMaybe<Icims_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_MajorArgs = {
  objects: Array<Icims_Major_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Major_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Major_OneArgs = {
  object: Icims_Major_Insert_Input;
  on_conflict?: InputMaybe<Icims_Major_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_PersonArgs = {
  objects: Array<Icims_Person_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Person_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Person_Interested_LocationArgs = {
  objects: Array<Icims_Person_Interested_Location_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Person_Interested_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Person_Interested_Location_OneArgs = {
  object: Icims_Person_Interested_Location_Insert_Input;
  on_conflict?: InputMaybe<Icims_Person_Interested_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Person_OneArgs = {
  object: Icims_Person_Insert_Input;
  on_conflict?: InputMaybe<Icims_Person_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Person_Preferred_LocationArgs = {
  objects: Array<Icims_Person_Preferred_Location_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Person_Preferred_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Person_Preferred_Location_OneArgs = {
  object: Icims_Person_Preferred_Location_Insert_Input;
  on_conflict?: InputMaybe<Icims_Person_Preferred_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Person_SkillArgs = {
  objects: Array<Icims_Person_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Person_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Person_Skill_OneArgs = {
  object: Icims_Person_Skill_Insert_Input;
  on_conflict?: InputMaybe<Icims_Person_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Profile_Applicant_WorkflowArgs = {
  objects: Array<Icims_Profile_Applicant_Workflow_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Profile_Applicant_Workflow_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Profile_Applicant_Workflow_OneArgs = {
  object: Icims_Profile_Applicant_Workflow_Insert_Input;
  on_conflict?: InputMaybe<Icims_Profile_Applicant_Workflow_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Profile_JobArgs = {
  objects: Array<Icims_Profile_Job_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Profile_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Profile_Job_OneArgs = {
  object: Icims_Profile_Job_Insert_Input;
  on_conflict?: InputMaybe<Icims_Profile_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Profile_LocationArgs = {
  objects: Array<Icims_Profile_Location_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Profile_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Profile_Location_OneArgs = {
  object: Icims_Profile_Location_Insert_Input;
  on_conflict?: InputMaybe<Icims_Profile_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Profile_PersonArgs = {
  objects: Array<Icims_Profile_Person_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Profile_Person_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Profile_Person_OneArgs = {
  object: Icims_Profile_Person_Insert_Input;
  on_conflict?: InputMaybe<Icims_Profile_Person_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Profile_Requested_LocationArgs = {
  objects: Array<Icims_Profile_Requested_Location_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Profile_Requested_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Profile_Requested_Location_OneArgs = {
  object: Icims_Profile_Requested_Location_Insert_Input;
  on_conflict?: InputMaybe<Icims_Profile_Requested_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Requested_LocationArgs = {
  objects: Array<Icims_Requested_Location_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Requested_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Requested_Location_OneArgs = {
  object: Icims_Requested_Location_Insert_Input;
  on_conflict?: InputMaybe<Icims_Requested_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_SchoolArgs = {
  objects: Array<Icims_School_Insert_Input>;
  on_conflict?: InputMaybe<Icims_School_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_School_OneArgs = {
  object: Icims_School_Insert_Input;
  on_conflict?: InputMaybe<Icims_School_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_SkillArgs = {
  objects: Array<Icims_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Skill_OneArgs = {
  object: Icims_Skill_Insert_Input;
  on_conflict?: InputMaybe<Icims_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_StatusArgs = {
  objects: Array<Icims_Status_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Status_OneArgs = {
  object: Icims_Status_Insert_Input;
  on_conflict?: InputMaybe<Icims_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Sync_Status_ChoicesArgs = {
  objects: Array<Icims_Sync_Status_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Sync_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Sync_Status_Choices_OneArgs = {
  object: Icims_Sync_Status_Choices_Insert_Input;
  on_conflict?: InputMaybe<Icims_Sync_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Work_ExperienceArgs = {
  objects: Array<Icims_Work_Experience_Insert_Input>;
  on_conflict?: InputMaybe<Icims_Work_Experience_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Icims_Work_Experience_OneArgs = {
  object: Icims_Work_Experience_Insert_Input;
  on_conflict?: InputMaybe<Icims_Work_Experience_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_IndustryArgs = {
  objects: Array<Industry_Insert_Input>;
  on_conflict?: InputMaybe<Industry_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Industry_OneArgs = {
  object: Industry_Insert_Input;
  on_conflict?: InputMaybe<Industry_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Initial_Sell_Type_ChoicesArgs = {
  objects: Array<Initial_Sell_Type_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Initial_Sell_Type_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Initial_Sell_Type_Choices_OneArgs = {
  object: Initial_Sell_Type_Choices_Insert_Input;
  on_conflict?: InputMaybe<Initial_Sell_Type_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_DetailArgs = {
  objects: Array<Interview_Detail_Insert_Input>;
  on_conflict?: InputMaybe<Interview_Detail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Detail_OneArgs = {
  object: Interview_Detail_Insert_Input;
  on_conflict?: InputMaybe<Interview_Detail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_FeedbackArgs = {
  objects: Array<Interview_Feedback_Insert_Input>;
  on_conflict?: InputMaybe<Interview_Feedback_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Feedback_OneArgs = {
  object: Interview_Feedback_Insert_Input;
  on_conflict?: InputMaybe<Interview_Feedback_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Feedback_Rating_ChoicesArgs = {
  objects: Array<Interview_Feedback_Rating_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Interview_Feedback_Rating_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Feedback_Rating_Choices_OneArgs = {
  object: Interview_Feedback_Rating_Choices_Insert_Input;
  on_conflict?: InputMaybe<Interview_Feedback_Rating_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Feedback_RequestArgs = {
  objects: Array<Interview_Feedback_Request_Insert_Input>;
  on_conflict?: InputMaybe<Interview_Feedback_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Feedback_Request_OneArgs = {
  object: Interview_Feedback_Request_Insert_Input;
  on_conflict?: InputMaybe<Interview_Feedback_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_RequestArgs = {
  objects: Array<Interview_Request_Insert_Input>;
  on_conflict?: InputMaybe<Interview_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Request_OneArgs = {
  object: Interview_Request_Insert_Input;
  on_conflict?: InputMaybe<Interview_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Type_ChoicesArgs = {
  objects: Array<Interview_Type_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Interview_Type_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Type_Choices_OneArgs = {
  object: Interview_Type_Choices_Insert_Input;
  on_conflict?: InputMaybe<Interview_Type_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_JobArgs = {
  objects: Array<Job_Insert_Input>;
  on_conflict?: InputMaybe<Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Acceptable_LocationArgs = {
  objects: Array<Job_Acceptable_Location_Insert_Input>;
  on_conflict?: InputMaybe<Job_Acceptable_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Acceptable_Location_OneArgs = {
  object: Job_Acceptable_Location_Insert_Input;
  on_conflict?: InputMaybe<Job_Acceptable_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_CodeArgs = {
  objects: Array<Job_Code_Insert_Input>;
  on_conflict?: InputMaybe<Job_Code_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Code_OneArgs = {
  object: Job_Code_Insert_Input;
  on_conflict?: InputMaybe<Job_Code_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Level_ChoicesArgs = {
  objects: Array<Job_Level_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Job_Level_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Level_Choices_OneArgs = {
  object: Job_Level_Choices_Insert_Input;
  on_conflict?: InputMaybe<Job_Level_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Meta_InfoArgs = {
  objects: Array<Job_Meta_Info_Insert_Input>;
  on_conflict?: InputMaybe<Job_Meta_Info_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Meta_Info_OneArgs = {
  object: Job_Meta_Info_Insert_Input;
  on_conflict?: InputMaybe<Job_Meta_Info_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Nice_To_Have_SkillArgs = {
  objects: Array<Job_Nice_To_Have_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Job_Nice_To_Have_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Nice_To_Have_Skill_OneArgs = {
  object: Job_Nice_To_Have_Skill_Insert_Input;
  on_conflict?: InputMaybe<Job_Nice_To_Have_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_OneArgs = {
  object: Job_Insert_Input;
  on_conflict?: InputMaybe<Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_PositionArgs = {
  objects: Array<Job_Position_Insert_Input>;
  on_conflict?: InputMaybe<Job_Position_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Position_OneArgs = {
  object: Job_Position_Insert_Input;
  on_conflict?: InputMaybe<Job_Position_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Priority_ChoicesArgs = {
  objects: Array<Job_Priority_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Job_Priority_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Priority_Choices_OneArgs = {
  object: Job_Priority_Choices_Insert_Input;
  on_conflict?: InputMaybe<Job_Priority_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Required_SkillArgs = {
  objects: Array<Job_Required_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Job_Required_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Required_Skill_OneArgs = {
  object: Job_Required_Skill_Insert_Input;
  on_conflict?: InputMaybe<Job_Required_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Role_Source_ChoicesArgs = {
  objects: Array<Job_Role_Source_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Job_Role_Source_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Role_Source_Choices_OneArgs = {
  object: Job_Role_Source_Choices_Insert_Input;
  on_conflict?: InputMaybe<Job_Role_Source_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Service_TypeArgs = {
  objects: Array<Job_Service_Type_Insert_Input>;
  on_conflict?: InputMaybe<Job_Service_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Service_Type_OneArgs = {
  object: Job_Service_Type_Insert_Input;
  on_conflict?: InputMaybe<Job_Service_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Status_ChoicesArgs = {
  objects: Array<Job_Status_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Job_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Status_Choices_OneArgs = {
  object: Job_Status_Choices_Insert_Input;
  on_conflict?: InputMaybe<Job_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Status_Choices_OrderArgs = {
  objects: Array<Job_Status_Choices_Order_Insert_Input>;
  on_conflict?: InputMaybe<Job_Status_Choices_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Status_Choices_Order_OneArgs = {
  object: Job_Status_Choices_Order_Insert_Input;
  on_conflict?: InputMaybe<Job_Status_Choices_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Status_LogArgs = {
  objects: Array<Job_Status_Log_Insert_Input>;
  on_conflict?: InputMaybe<Job_Status_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Status_Log_OneArgs = {
  object: Job_Status_Log_Insert_Input;
  on_conflict?: InputMaybe<Job_Status_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Tech_StackArgs = {
  objects: Array<Job_Tech_Stack_Insert_Input>;
  on_conflict?: InputMaybe<Job_Tech_Stack_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Tech_Stack_OneArgs = {
  object: Job_Tech_Stack_Insert_Input;
  on_conflict?: InputMaybe<Job_Tech_Stack_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Linkedin_ContactArgs = {
  objects: Array<Linkedin_Contact_Insert_Input>;
  on_conflict?: InputMaybe<Linkedin_Contact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Linkedin_Contact_OneArgs = {
  object: Linkedin_Contact_Insert_Input;
  on_conflict?: InputMaybe<Linkedin_Contact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LocationArgs = {
  objects: Array<Location_Insert_Input>;
  on_conflict?: InputMaybe<Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Location_OneArgs = {
  object: Location_Insert_Input;
  on_conflict?: InputMaybe<Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Manual_ContactArgs = {
  objects: Array<Manual_Contact_Insert_Input>;
  on_conflict?: InputMaybe<Manual_Contact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Manual_Contact_OneArgs = {
  object: Manual_Contact_Insert_Input;
  on_conflict?: InputMaybe<Manual_Contact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_ApplicationArgs = {
  objects: Array<Merge_Ats_Application_Insert_Input>;
  on_conflict?: InputMaybe<Merge_Ats_Application_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_Application_LogArgs = {
  objects: Array<Merge_Ats_Application_Log_Insert_Input>;
  on_conflict?: InputMaybe<Merge_Ats_Application_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_Application_Log_OneArgs = {
  object: Merge_Ats_Application_Log_Insert_Input;
  on_conflict?: InputMaybe<Merge_Ats_Application_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_Application_OneArgs = {
  object: Merge_Ats_Application_Insert_Input;
  on_conflict?: InputMaybe<Merge_Ats_Application_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_CandidateArgs = {
  objects: Array<Merge_Ats_Candidate_Insert_Input>;
  on_conflict?: InputMaybe<Merge_Ats_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_Candidate_OneArgs = {
  object: Merge_Ats_Candidate_Insert_Input;
  on_conflict?: InputMaybe<Merge_Ats_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_JobArgs = {
  objects: Array<Merge_Ats_Job_Insert_Input>;
  on_conflict?: InputMaybe<Merge_Ats_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_Job_Interview_StageArgs = {
  objects: Array<Merge_Ats_Job_Interview_Stage_Insert_Input>;
  on_conflict?: InputMaybe<Merge_Ats_Job_Interview_Stage_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_Job_Interview_Stage_OneArgs = {
  object: Merge_Ats_Job_Interview_Stage_Insert_Input;
  on_conflict?: InputMaybe<Merge_Ats_Job_Interview_Stage_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_Job_OneArgs = {
  object: Merge_Ats_Job_Insert_Input;
  on_conflict?: InputMaybe<Merge_Ats_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_Linked_AccountArgs = {
  objects: Array<Merge_Ats_Linked_Account_Insert_Input>;
  on_conflict?: InputMaybe<Merge_Ats_Linked_Account_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Merge_Ats_Linked_Account_OneArgs = {
  object: Merge_Ats_Linked_Account_Insert_Input;
  on_conflict?: InputMaybe<Merge_Ats_Linked_Account_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Mixpanel_ProfilesArgs = {
  objects: Array<Mixpanel_Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Mixpanel_Profiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Mixpanel_Profiles_OneArgs = {
  object: Mixpanel_Profiles_Insert_Input;
  on_conflict?: InputMaybe<Mixpanel_Profiles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Offering_Selection_ChoicesArgs = {
  objects: Array<Offering_Selection_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Offering_Selection_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Offering_Selection_Choices_OneArgs = {
  object: Offering_Selection_Choices_Insert_Input;
  on_conflict?: InputMaybe<Offering_Selection_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OrganizationArgs = {
  objects: Array<Organization_Insert_Input>;
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_ContactArgs = {
  objects: Array<Organization_Contact_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Contact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Contact_OneArgs = {
  object: Organization_Contact_Insert_Input;
  on_conflict?: InputMaybe<Organization_Contact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_DomainArgs = {
  objects: Array<Organization_Domain_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Domain_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Domain_OneArgs = {
  object: Organization_Domain_Insert_Input;
  on_conflict?: InputMaybe<Organization_Domain_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_EmployeeArgs = {
  objects: Array<Organization_Employee_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Employee_OneArgs = {
  object: Organization_Employee_Insert_Input;
  on_conflict?: InputMaybe<Organization_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Icims_Jobs_ResultArgs = {
  objects: Array<Organization_Icims_Jobs_Result_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Icims_Jobs_Result_OneArgs = {
  object: Organization_Icims_Jobs_Result_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Organization_LeadershipArgs = {
  objects: Array<Organization_Leadership_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Leadership_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Leadership_OneArgs = {
  object: Organization_Leadership_Insert_Input;
  on_conflict?: InputMaybe<Organization_Leadership_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_OneArgs = {
  object: Organization_Insert_Input;
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Type_ChoicesArgs = {
  objects: Array<Organization_Type_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Type_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Type_Choices_OneArgs = {
  object: Organization_Type_Choices_Insert_Input;
  on_conflict?: InputMaybe<Organization_Type_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_VideoArgs = {
  objects: Array<Organization_Video_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Video_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Video_OneArgs = {
  object: Organization_Video_Insert_Input;
  on_conflict?: InputMaybe<Organization_Video_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Pillar_TranscriptArgs = {
  objects: Array<Pillar_Transcript_Insert_Input>;
  on_conflict?: InputMaybe<Pillar_Transcript_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Pillar_Transcript_BlobArgs = {
  objects: Array<Pillar_Transcript_Blob_Insert_Input>;
  on_conflict?: InputMaybe<Pillar_Transcript_Blob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Pillar_Transcript_Blob_OneArgs = {
  object: Pillar_Transcript_Blob_Insert_Input;
  on_conflict?: InputMaybe<Pillar_Transcript_Blob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Pillar_Transcript_OneArgs = {
  object: Pillar_Transcript_Insert_Input;
  on_conflict?: InputMaybe<Pillar_Transcript_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recruiter_Contact_ActionArgs = {
  objects: Array<Recruiter_Contact_Action_Insert_Input>;
  on_conflict?: InputMaybe<Recruiter_Contact_Action_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recruiter_Contact_Action_OneArgs = {
  object: Recruiter_Contact_Action_Insert_Input;
  on_conflict?: InputMaybe<Recruiter_Contact_Action_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Referral_StatusArgs = {
  objects: Array<Referral_Status_Insert_Input>;
  on_conflict?: InputMaybe<Referral_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Referral_Status_CurrentArgs = {
  objects: Array<Referral_Status_Current_Insert_Input>;
  on_conflict?: InputMaybe<Referral_Status_Current_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Referral_Status_Current_OneArgs = {
  object: Referral_Status_Current_Insert_Input;
  on_conflict?: InputMaybe<Referral_Status_Current_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Referral_Status_OneArgs = {
  object: Referral_Status_Insert_Input;
  on_conflict?: InputMaybe<Referral_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Region_ChoicesArgs = {
  objects: Array<Region_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Region_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Region_Choices_OneArgs = {
  object: Region_Choices_Insert_Input;
  on_conflict?: InputMaybe<Region_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reporting_Candidate_MetadataArgs = {
  objects: Array<Reporting_Candidate_Metadata_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Reporting_Candidate_Metadata_OneArgs = {
  object: Reporting_Candidate_Metadata_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Reporting_Report_Job_MatchesArgs = {
  objects: Array<Reporting_Report_Job_Matches_Insert_Input>;
  on_conflict?: InputMaybe<Reporting_Report_Job_Matches_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reporting_Report_Job_Matches_OneArgs = {
  object: Reporting_Report_Job_Matches_Insert_Input;
  on_conflict?: InputMaybe<Reporting_Report_Job_Matches_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_ChoicesArgs = {
  objects: Array<Role_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Role_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Choices_OneArgs = {
  object: Role_Choices_Insert_Input;
  on_conflict?: InputMaybe<Role_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Salary_InfoArgs = {
  objects: Array<Salary_Info_Insert_Input>;
  on_conflict?: InputMaybe<Salary_Info_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Salary_Info_OneArgs = {
  object: Salary_Info_Insert_Input;
  on_conflict?: InputMaybe<Salary_Info_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Salary_Years_Experience_Range_ChoicesArgs = {
  objects: Array<Salary_Years_Experience_Range_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Salary_Years_Experience_Range_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Salary_Years_Experience_Range_Choices_OneArgs = {
  object: Salary_Years_Experience_Range_Choices_Insert_Input;
  on_conflict?: InputMaybe<Salary_Years_Experience_Range_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Salesforce_CustomerArgs = {
  objects: Array<Salesforce_Customer_Insert_Input>;
  on_conflict?: InputMaybe<Salesforce_Customer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Salesforce_Customer_LocationArgs = {
  objects: Array<Salesforce_Customer_Location_Insert_Input>;
  on_conflict?: InputMaybe<Salesforce_Customer_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Salesforce_Customer_Location_OneArgs = {
  object: Salesforce_Customer_Location_Insert_Input;
  on_conflict?: InputMaybe<Salesforce_Customer_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Salesforce_Customer_OneArgs = {
  object: Salesforce_Customer_Insert_Input;
  on_conflict?: InputMaybe<Salesforce_Customer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Salesforce_LocationArgs = {
  objects: Array<Salesforce_Location_Insert_Input>;
  on_conflict?: InputMaybe<Salesforce_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Salesforce_Location_OneArgs = {
  object: Salesforce_Location_Insert_Input;
  on_conflict?: InputMaybe<Salesforce_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Saved_CandidateArgs = {
  objects: Array<Saved_Candidate_Insert_Input>;
  on_conflict?: InputMaybe<Saved_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Saved_Candidate_OneArgs = {
  object: Saved_Candidate_Insert_Input;
  on_conflict?: InputMaybe<Saved_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Segment_Sendgrid_Prod_ActivityArgs = {
  objects: Array<Segment_Sendgrid_Prod_Activity_Insert_Input>;
  on_conflict?: InputMaybe<Segment_Sendgrid_Prod_Activity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Segment_Sendgrid_Prod_Activity_OneArgs = {
  object: Segment_Sendgrid_Prod_Activity_Insert_Input;
  on_conflict?: InputMaybe<Segment_Sendgrid_Prod_Activity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shared_CandidateArgs = {
  objects: Array<Shared_Candidate_Insert_Input>;
  on_conflict?: InputMaybe<Shared_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Shared_Candidate_OneArgs = {
  object: Shared_Candidate_Insert_Input;
  on_conflict?: InputMaybe<Shared_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SkillArgs = {
  objects: Array<Skill_Insert_Input>;
  on_conflict?: InputMaybe<Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Skill_FeatureArgs = {
  objects: Array<Skill_Feature_Insert_Input>;
  on_conflict?: InputMaybe<Skill_Feature_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Skill_Feature_OneArgs = {
  object: Skill_Feature_Insert_Input;
  on_conflict?: InputMaybe<Skill_Feature_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Skill_Icims_SkillArgs = {
  objects: Array<Skill_Icims_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Skill_Icims_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Skill_Icims_Skill_OneArgs = {
  object: Skill_Icims_Skill_Insert_Input;
  on_conflict?: InputMaybe<Skill_Icims_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Skill_OneArgs = {
  object: Skill_Insert_Input;
  on_conflict?: InputMaybe<Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Skill_SynonymArgs = {
  objects: Array<Skill_Synonym_Insert_Input>;
  on_conflict?: InputMaybe<Skill_Synonym_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Skill_Synonym_OneArgs = {
  object: Skill_Synonym_Insert_Input;
  on_conflict?: InputMaybe<Skill_Synonym_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_CandidateArgs = {
  objects: Array<Spike_Matching_Candidate_Insert_Input>;
  on_conflict?: InputMaybe<Spike_Matching_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Candidate_OneArgs = {
  object: Spike_Matching_Candidate_Insert_Input;
  on_conflict?: InputMaybe<Spike_Matching_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Candidate_SkillArgs = {
  objects: Array<Spike_Matching_Candidate_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Spike_Matching_Candidate_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Candidate_Skill_OneArgs = {
  object: Spike_Matching_Candidate_Skill_Insert_Input;
  on_conflict?: InputMaybe<Spike_Matching_Candidate_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_JobArgs = {
  objects: Array<Spike_Matching_Job_Insert_Input>;
  on_conflict?: InputMaybe<Spike_Matching_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Job_OneArgs = {
  object: Spike_Matching_Job_Insert_Input;
  on_conflict?: InputMaybe<Spike_Matching_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Job_SkillArgs = {
  objects: Array<Spike_Matching_Job_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Spike_Matching_Job_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Job_Skill_OneArgs = {
  object: Spike_Matching_Job_Skill_Insert_Input;
  on_conflict?: InputMaybe<Spike_Matching_Job_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_LocationArgs = {
  objects: Array<Spike_Matching_Location_Insert_Input>;
  on_conflict?: InputMaybe<Spike_Matching_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Location_JobArgs = {
  objects: Array<Spike_Matching_Location_Job_Insert_Input>;
  on_conflict?: InputMaybe<Spike_Matching_Location_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Location_Job_OneArgs = {
  object: Spike_Matching_Location_Job_Insert_Input;
  on_conflict?: InputMaybe<Spike_Matching_Location_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Location_OneArgs = {
  object: Spike_Matching_Location_Insert_Input;
  on_conflict?: InputMaybe<Spike_Matching_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Match_ResultArgs = {
  objects: Array<Spike_Matching_Match_Result_Insert_Input>;
  on_conflict?: InputMaybe<Spike_Matching_Match_Result_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Match_Result_OneArgs = {
  object: Spike_Matching_Match_Result_Insert_Input;
  on_conflict?: InputMaybe<Spike_Matching_Match_Result_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_SkillArgs = {
  objects: Array<Spike_Matching_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Spike_Matching_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spike_Matching_Skill_OneArgs = {
  object: Spike_Matching_Skill_Insert_Input;
  on_conflict?: InputMaybe<Spike_Matching_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Terminal_Celery_Task_Status_ChoicesArgs = {
  objects: Array<Terminal_Celery_Task_Status_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Terminal_Celery_Task_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Terminal_Celery_Task_Status_Choices_OneArgs = {
  object: Terminal_Celery_Task_Status_Choices_Insert_Input;
  on_conflict?: InputMaybe<Terminal_Celery_Task_Status_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Terminal_EmployeeArgs = {
  objects: Array<Terminal_Employee_Insert_Input>;
  on_conflict?: InputMaybe<Terminal_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Terminal_Employee_OneArgs = {
  object: Terminal_Employee_Insert_Input;
  on_conflict?: InputMaybe<Terminal_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Terminal_Employee_OrganizationArgs = {
  objects: Array<Terminal_Employee_Organization_Insert_Input>;
  on_conflict?: InputMaybe<Terminal_Employee_Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Terminal_Employee_Organization_OneArgs = {
  object: Terminal_Employee_Organization_Insert_Input;
  on_conflict?: InputMaybe<Terminal_Employee_Organization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Terminal_Employee_Role_ChoicesArgs = {
  objects: Array<Terminal_Employee_Role_Choices_Insert_Input>;
  on_conflict?: InputMaybe<Terminal_Employee_Role_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Terminal_Employee_Role_Choices_OneArgs = {
  object: Terminal_Employee_Role_Choices_Insert_Input;
  on_conflict?: InputMaybe<Terminal_Employee_Role_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TraitArgs = {
  objects: Array<Trait_Insert_Input>;
  on_conflict?: InputMaybe<Trait_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trait_OneArgs = {
  object: Trait_Insert_Input;
  on_conflict?: InputMaybe<Trait_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Transcript_EvaluationArgs = {
  objects: Array<Transcript_Evaluation_Insert_Input>;
  on_conflict?: InputMaybe<Transcript_Evaluation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Transcript_Evaluation_OneArgs = {
  object: Transcript_Evaluation_Insert_Input;
  on_conflict?: InputMaybe<Transcript_Evaluation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Transcript_SkillArgs = {
  objects: Array<Transcript_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Transcript_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Transcript_Skill_OneArgs = {
  object: Transcript_Skill_Insert_Input;
  on_conflict?: InputMaybe<Transcript_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Transcript_Work_ExperienceArgs = {
  objects: Array<Transcript_Work_Experience_Insert_Input>;
  on_conflict?: InputMaybe<Transcript_Work_Experience_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Transcript_Work_Experience_OneArgs = {
  object: Transcript_Work_Experience_Insert_Input;
  on_conflict?: InputMaybe<Transcript_Work_Experience_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_InfoArgs = {
  objects: Array<User_Info_Insert_Input>;
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Info_OneArgs = {
  object: User_Info_Insert_Input;
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_InvitationArgs = {
  objects: Array<User_Invitation_Insert_Input>;
  on_conflict?: InputMaybe<User_Invitation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Invitation_OneArgs = {
  object: User_Invitation_Insert_Input;
  on_conflict?: InputMaybe<User_Invitation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Linkedin_ContactArgs = {
  objects: Array<User_Linkedin_Contact_Insert_Input>;
  on_conflict?: InputMaybe<User_Linkedin_Contact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Linkedin_Contact_OneArgs = {
  object: User_Linkedin_Contact_Insert_Input;
  on_conflict?: InputMaybe<User_Linkedin_Contact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Password_BlacklistArgs = {
  objects: Array<User_Password_Blacklist_Insert_Input>;
  on_conflict?: InputMaybe<User_Password_Blacklist_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Password_Blacklist_OneArgs = {
  object: User_Password_Blacklist_Insert_Input;
  on_conflict?: InputMaybe<User_Password_Blacklist_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_RoleArgs = {
  objects: Array<User_Role_Insert_Input>;
  on_conflict?: InputMaybe<User_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Role_OneArgs = {
  object: User_Role_Insert_Input;
  on_conflict?: InputMaybe<User_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_SubscriptionArgs = {
  objects: Array<User_Subscription_Insert_Input>;
  on_conflict?: InputMaybe<User_Subscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Subscription_ChoicesArgs = {
  objects: Array<User_Subscription_Choices_Insert_Input>;
  on_conflict?: InputMaybe<User_Subscription_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Subscription_Choices_OneArgs = {
  object: User_Subscription_Choices_Insert_Input;
  on_conflict?: InputMaybe<User_Subscription_Choices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Subscription_OneArgs = {
  object: User_Subscription_Insert_Input;
  on_conflict?: InputMaybe<User_Subscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Temp_BlobArgs = {
  objects: Array<User_Temp_Blob_Insert_Input>;
  on_conflict?: InputMaybe<User_Temp_Blob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Temp_Blob_OneArgs = {
  object: User_Temp_Blob_Insert_Input;
  on_conflict?: InputMaybe<User_Temp_Blob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ValidatorArgs = {
  objects: Array<Validator_Insert_Input>;
  on_conflict?: InputMaybe<Validator_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Validator_OneArgs = {
  object: Validator_Insert_Input;
  on_conflict?: InputMaybe<Validator_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_Activity_Customer_Profile_ViewArgs = {
  _inc?: InputMaybe<Activity_Customer_Profile_View_Inc_Input>;
  _set?: InputMaybe<Activity_Customer_Profile_View_Set_Input>;
  where: Activity_Customer_Profile_View_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Activity_Customer_Profile_View_By_PkArgs = {
  _inc?: InputMaybe<Activity_Customer_Profile_View_Inc_Input>;
  _set?: InputMaybe<Activity_Customer_Profile_View_Set_Input>;
  pk_columns: Activity_Customer_Profile_View_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Activity_Customer_Profile_View_ManyArgs = {
  updates: Array<Activity_Customer_Profile_View_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_LevelArgs = {
  _inc?: InputMaybe<Ai_Level_Inc_Input>;
  _set?: InputMaybe<Ai_Level_Set_Input>;
  where: Ai_Level_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Level_By_PkArgs = {
  _inc?: InputMaybe<Ai_Level_Inc_Input>;
  _set?: InputMaybe<Ai_Level_Set_Input>;
  pk_columns: Ai_Level_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Level_ManyArgs = {
  updates: Array<Ai_Level_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_RoleArgs = {
  _inc?: InputMaybe<Ai_Role_Inc_Input>;
  _set?: InputMaybe<Ai_Role_Set_Input>;
  where: Ai_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Role_By_PkArgs = {
  _inc?: InputMaybe<Ai_Role_Inc_Input>;
  _set?: InputMaybe<Ai_Role_Set_Input>;
  pk_columns: Ai_Role_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Role_ManyArgs = {
  updates: Array<Ai_Role_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Role_Multiplier_FocusArgs = {
  _inc?: InputMaybe<Ai_Role_Multiplier_Focus_Inc_Input>;
  _set?: InputMaybe<Ai_Role_Multiplier_Focus_Set_Input>;
  where: Ai_Role_Multiplier_Focus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Role_Multiplier_Focus_By_PkArgs = {
  _inc?: InputMaybe<Ai_Role_Multiplier_Focus_Inc_Input>;
  _set?: InputMaybe<Ai_Role_Multiplier_Focus_Set_Input>;
  pk_columns: Ai_Role_Multiplier_Focus_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Role_Multiplier_Focus_ManyArgs = {
  updates: Array<Ai_Role_Multiplier_Focus_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Run_DumpArgs = {
  _append?: InputMaybe<Ai_Run_Dump_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Run_Dump_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Run_Dump_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Run_Dump_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Run_Dump_Inc_Input>;
  _prepend?: InputMaybe<Ai_Run_Dump_Prepend_Input>;
  _set?: InputMaybe<Ai_Run_Dump_Set_Input>;
  where: Ai_Run_Dump_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Run_Dump_By_PkArgs = {
  _append?: InputMaybe<Ai_Run_Dump_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Run_Dump_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Run_Dump_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Run_Dump_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Run_Dump_Inc_Input>;
  _prepend?: InputMaybe<Ai_Run_Dump_Prepend_Input>;
  _set?: InputMaybe<Ai_Run_Dump_Set_Input>;
  pk_columns: Ai_Run_Dump_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Run_Dump_ManyArgs = {
  updates: Array<Ai_Run_Dump_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Applicant_ConsentArgs = {
  _inc?: InputMaybe<Applicant_Consent_Inc_Input>;
  _set?: InputMaybe<Applicant_Consent_Set_Input>;
  where: Applicant_Consent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Applicant_Consent_By_PkArgs = {
  _inc?: InputMaybe<Applicant_Consent_Inc_Input>;
  _set?: InputMaybe<Applicant_Consent_Set_Input>;
  pk_columns: Applicant_Consent_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Applicant_Consent_ManyArgs = {
  updates: Array<Applicant_Consent_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Applicant_JobArgs = {
  _inc?: InputMaybe<Applicant_Job_Inc_Input>;
  _set?: InputMaybe<Applicant_Job_Set_Input>;
  where: Applicant_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Applicant_Job_By_PkArgs = {
  _inc?: InputMaybe<Applicant_Job_Inc_Input>;
  _set?: InputMaybe<Applicant_Job_Set_Input>;
  pk_columns: Applicant_Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Applicant_Job_ManyArgs = {
  updates: Array<Applicant_Job_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Applicant_WaitlistArgs = {
  _inc?: InputMaybe<Applicant_Waitlist_Inc_Input>;
  _set?: InputMaybe<Applicant_Waitlist_Set_Input>;
  where: Applicant_Waitlist_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Applicant_Waitlist_By_PkArgs = {
  _inc?: InputMaybe<Applicant_Waitlist_Inc_Input>;
  _set?: InputMaybe<Applicant_Waitlist_Set_Input>;
  pk_columns: Applicant_Waitlist_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Applicant_Waitlist_ManyArgs = {
  updates: Array<Applicant_Waitlist_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_ActionsArgs = {
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>;
  where: Audit_Logged_Actions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_By_PkArgs = {
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>;
  pk_columns: Audit_Logged_Actions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_ManyArgs = {
  updates: Array<Audit_Logged_Actions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Calendly_EventArgs = {
  _append?: InputMaybe<Calendly_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Calendly_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calendly_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calendly_Event_Delete_Key_Input>;
  _inc?: InputMaybe<Calendly_Event_Inc_Input>;
  _prepend?: InputMaybe<Calendly_Event_Prepend_Input>;
  _set?: InputMaybe<Calendly_Event_Set_Input>;
  where: Calendly_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Calendly_Event_By_PkArgs = {
  _append?: InputMaybe<Calendly_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Calendly_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calendly_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calendly_Event_Delete_Key_Input>;
  _inc?: InputMaybe<Calendly_Event_Inc_Input>;
  _prepend?: InputMaybe<Calendly_Event_Prepend_Input>;
  _set?: InputMaybe<Calendly_Event_Set_Input>;
  pk_columns: Calendly_Event_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Calendly_Event_ManyArgs = {
  updates: Array<Calendly_Event_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Calendly_InviteeArgs = {
  _append?: InputMaybe<Calendly_Invitee_Append_Input>;
  _delete_at_path?: InputMaybe<Calendly_Invitee_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calendly_Invitee_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calendly_Invitee_Delete_Key_Input>;
  _inc?: InputMaybe<Calendly_Invitee_Inc_Input>;
  _prepend?: InputMaybe<Calendly_Invitee_Prepend_Input>;
  _set?: InputMaybe<Calendly_Invitee_Set_Input>;
  where: Calendly_Invitee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Calendly_Invitee_By_PkArgs = {
  _append?: InputMaybe<Calendly_Invitee_Append_Input>;
  _delete_at_path?: InputMaybe<Calendly_Invitee_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Calendly_Invitee_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Calendly_Invitee_Delete_Key_Input>;
  _inc?: InputMaybe<Calendly_Invitee_Inc_Input>;
  _prepend?: InputMaybe<Calendly_Invitee_Prepend_Input>;
  _set?: InputMaybe<Calendly_Invitee_Set_Input>;
  pk_columns: Calendly_Invitee_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Calendly_Invitee_ManyArgs = {
  updates: Array<Calendly_Invitee_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Calendly_UserArgs = {
  _inc?: InputMaybe<Calendly_User_Inc_Input>;
  _set?: InputMaybe<Calendly_User_Set_Input>;
  where: Calendly_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Calendly_User_By_PkArgs = {
  _inc?: InputMaybe<Calendly_User_Inc_Input>;
  _set?: InputMaybe<Calendly_User_Set_Input>;
  pk_columns: Calendly_User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Calendly_User_ManyArgs = {
  updates: Array<Calendly_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CandidateArgs = {
  _append?: InputMaybe<Candidate_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Prepend_Input>;
  _set?: InputMaybe<Candidate_Set_Input>;
  where: Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Ai_Profile_SummaryArgs = {
  _append?: InputMaybe<Candidate_Ai_Profile_Summary_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Ai_Profile_Summary_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Ai_Profile_Summary_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Ai_Profile_Summary_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Ai_Profile_Summary_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Ai_Profile_Summary_Prepend_Input>;
  _set?: InputMaybe<Candidate_Ai_Profile_Summary_Set_Input>;
  where: Candidate_Ai_Profile_Summary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Ai_Profile_Summary_By_PkArgs = {
  _append?: InputMaybe<Candidate_Ai_Profile_Summary_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Ai_Profile_Summary_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Ai_Profile_Summary_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Ai_Profile_Summary_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Ai_Profile_Summary_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Ai_Profile_Summary_Prepend_Input>;
  _set?: InputMaybe<Candidate_Ai_Profile_Summary_Set_Input>;
  pk_columns: Candidate_Ai_Profile_Summary_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Ai_Profile_Summary_ManyArgs = {
  updates: Array<Candidate_Ai_Profile_Summary_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Applicant_Workflow_Stats_ResultArgs = {
  _inc?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Inc_Input>;
  _set?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Set_Input>;
  where: Candidate_Applicant_Workflow_Stats_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Applicant_Workflow_Stats_Result_ManyArgs = {
  updates: Array<Candidate_Applicant_Workflow_Stats_Result_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Approved_CountryArgs = {
  _inc?: InputMaybe<Candidate_Approved_Country_Inc_Input>;
  _set?: InputMaybe<Candidate_Approved_Country_Set_Input>;
  where: Candidate_Approved_Country_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Approved_Country_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Approved_Country_Inc_Input>;
  _set?: InputMaybe<Candidate_Approved_Country_Set_Input>;
  pk_columns: Candidate_Approved_Country_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Approved_Country_ManyArgs = {
  updates: Array<Candidate_Approved_Country_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Availability_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Availability_Choices_Set_Input>;
  where: Candidate_Availability_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Availability_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Availability_Choices_Set_Input>;
  pk_columns: Candidate_Availability_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Availability_Choices_ManyArgs = {
  updates: Array<Candidate_Availability_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Availability_Choices_OrderArgs = {
  _inc?: InputMaybe<Candidate_Availability_Choices_Order_Inc_Input>;
  _set?: InputMaybe<Candidate_Availability_Choices_Order_Set_Input>;
  where: Candidate_Availability_Choices_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Availability_Choices_Order_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Availability_Choices_Order_Inc_Input>;
  _set?: InputMaybe<Candidate_Availability_Choices_Order_Set_Input>;
  pk_columns: Candidate_Availability_Choices_Order_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Availability_Choices_Order_ManyArgs = {
  updates: Array<Candidate_Availability_Choices_Order_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_BadgeArgs = {
  _inc?: InputMaybe<Candidate_Badge_Inc_Input>;
  _set?: InputMaybe<Candidate_Badge_Set_Input>;
  where: Candidate_Badge_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Badge_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Badge_Inc_Input>;
  _set?: InputMaybe<Candidate_Badge_Set_Input>;
  pk_columns: Candidate_Badge_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Badge_ManyArgs = {
  updates: Array<Candidate_Badge_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Browse_ResultArgs = {
  _inc?: InputMaybe<Candidate_Browse_Result_Inc_Input>;
  _set?: InputMaybe<Candidate_Browse_Result_Set_Input>;
  where: Candidate_Browse_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Browse_Result_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Browse_Result_Inc_Input>;
  _set?: InputMaybe<Candidate_Browse_Result_Set_Input>;
  pk_columns: Candidate_Browse_Result_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Browse_Result_ManyArgs = {
  updates: Array<Candidate_Browse_Result_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_By_PkArgs = {
  _append?: InputMaybe<Candidate_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Prepend_Input>;
  _set?: InputMaybe<Candidate_Set_Input>;
  pk_columns: Candidate_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Ai_SummaryArgs = {
  _append?: InputMaybe<Candidate_Curation_Ai_Summary_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Curation_Ai_Summary_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Curation_Ai_Summary_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Curation_Ai_Summary_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Curation_Ai_Summary_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Curation_Ai_Summary_Prepend_Input>;
  _set?: InputMaybe<Candidate_Curation_Ai_Summary_Set_Input>;
  where: Candidate_Curation_Ai_Summary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Ai_Summary_By_PkArgs = {
  _append?: InputMaybe<Candidate_Curation_Ai_Summary_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Curation_Ai_Summary_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Curation_Ai_Summary_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Curation_Ai_Summary_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Curation_Ai_Summary_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Curation_Ai_Summary_Prepend_Input>;
  _set?: InputMaybe<Candidate_Curation_Ai_Summary_Set_Input>;
  pk_columns: Candidate_Curation_Ai_Summary_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Ai_Summary_FeedbackArgs = {
  _inc?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Set_Input>;
  where: Candidate_Curation_Ai_Summary_Feedback_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Ai_Summary_Feedback_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Set_Input>;
  pk_columns: Candidate_Curation_Ai_Summary_Feedback_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Ai_Summary_Feedback_ManyArgs = {
  updates: Array<Candidate_Curation_Ai_Summary_Feedback_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Ai_Summary_ManyArgs = {
  updates: Array<Candidate_Curation_Ai_Summary_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Country_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Country_Choices_Set_Input>;
  where: Candidate_Curation_Country_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Country_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Country_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Country_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Country_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Country_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Desired_Salary_Currency_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Set_Input>;
  where: Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Desired_Salary_Currency_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Desired_Salary_Currency_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Desired_Salary_Currency_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Desired_Salary_Currency_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Desired_Salary_Frequency_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Set_Input>;
  where: Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Desired_Salary_Frequency_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Desired_Salary_Frequency_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Desired_Salary_Frequency_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_DetailArgs = {
  _inc?: InputMaybe<Candidate_Curation_Detail_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Detail_Set_Input>;
  where: Candidate_Curation_Detail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Detail_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Curation_Detail_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Detail_Set_Input>;
  pk_columns: Candidate_Curation_Detail_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Detail_ManyArgs = {
  updates: Array<Candidate_Curation_Detail_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Detail_Recruiter_Summary_LogArgs = {
  _inc?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Set_Input>;
  where: Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Detail_Recruiter_Summary_Log_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Set_Input>;
  pk_columns: Candidate_Curation_Detail_Recruiter_Summary_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Detail_Recruiter_Summary_Log_ManyArgs = {
  updates: Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_English_Proficiency_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Set_Input>;
  where: Candidate_Curation_English_Proficiency_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_English_Proficiency_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Set_Input>;
  pk_columns: Candidate_Curation_English_Proficiency_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_English_Proficiency_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_English_Proficiency_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Equity_Importance_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Set_Input>;
  where: Candidate_Curation_Equity_Importance_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Equity_Importance_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Equity_Importance_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Equity_Importance_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Equity_Importance_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_NoteArgs = {
  _inc?: InputMaybe<Candidate_Curation_Note_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Note_Set_Input>;
  where: Candidate_Curation_Note_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Note_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Curation_Note_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Note_Set_Input>;
  pk_columns: Candidate_Curation_Note_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Note_ManyArgs = {
  updates: Array<Candidate_Curation_Note_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Rejected_Reason_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Set_Input>;
  where: Candidate_Curation_Rejected_Reason_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Rejected_Reason_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Rejected_Reason_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Rejected_Reason_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Rejected_Reason_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Remote_Work_Exp_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Set_Input>;
  where: Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Remote_Work_Exp_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Remote_Work_Exp_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Remote_Work_Exp_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Remote_Work_Exp_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Visa_Status_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Set_Input>;
  where: Candidate_Curation_Visa_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Visa_Status_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Visa_Status_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Visa_Status_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Visa_Status_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_WorkflowArgs = {
  _inc?: InputMaybe<Candidate_Curation_Workflow_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Workflow_Set_Input>;
  where: Candidate_Curation_Workflow_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Curation_Workflow_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Workflow_Set_Input>;
  pk_columns: Candidate_Curation_Workflow_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_ManyArgs = {
  updates: Array<Candidate_Curation_Workflow_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Needs_Info_ReasonArgs = {
  _inc?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Set_Input>;
  where: Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Needs_Info_Reason_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Set_Input>;
  pk_columns: Candidate_Curation_Workflow_Needs_Info_Reason_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Needs_Info_Reason_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Set_Input>;
  where: Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Needs_Info_Reason_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Needs_Info_Reason_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Needs_Info_Reason_ManyArgs = {
  updates: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Screener_Type_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Set_Input>;
  where: Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Screener_Type_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Workflow_Screener_Type_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Screener_Type_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Workflow_Screener_Type_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Source_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Set_Input>;
  where: Candidate_Curation_Workflow_Source_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Source_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Workflow_Source_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Source_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Workflow_Source_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Status_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Set_Input>;
  where: Candidate_Curation_Workflow_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Status_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Workflow_Status_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Status_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Workflow_Status_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Status_LogArgs = {
  _inc?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Set_Input>;
  where: Candidate_Curation_Workflow_Status_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Status_Log_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Set_Input>;
  pk_columns: Candidate_Curation_Workflow_Status_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Workflow_Status_Log_ManyArgs = {
  updates: Array<Candidate_Curation_Workflow_Status_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Years_Of_Exp_Range_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Set_Input>;
  where: Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Years_Of_Exp_Range_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Set_Input>;
  pk_columns: Candidate_Curation_Years_Of_Exp_Range_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Years_Of_Exp_Range_Choices_ManyArgs = {
  updates: Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Years_Of_Exp_Range_Choices_OrderArgs = {
  _inc?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Set_Input>;
  where: Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Years_Of_Exp_Range_Choices_Order_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Inc_Input>;
  _set?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Set_Input>;
  pk_columns: Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Curation_Years_Of_Exp_Range_Choices_Order_ManyArgs = {
  updates: Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Detail_Level_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Detail_Level_Choices_Set_Input>;
  where: Candidate_Detail_Level_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Detail_Level_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Detail_Level_Choices_Set_Input>;
  pk_columns: Candidate_Detail_Level_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Detail_Level_Choices_ManyArgs = {
  updates: Array<Candidate_Detail_Level_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_EducationArgs = {
  _inc?: InputMaybe<Candidate_Education_Inc_Input>;
  _set?: InputMaybe<Candidate_Education_Set_Input>;
  where: Candidate_Education_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Education_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Education_Inc_Input>;
  _set?: InputMaybe<Candidate_Education_Set_Input>;
  pk_columns: Candidate_Education_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Education_Concentration_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Education_Concentration_Choices_Set_Input>;
  where: Candidate_Education_Concentration_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Education_Concentration_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Education_Concentration_Choices_Set_Input>;
  pk_columns: Candidate_Education_Concentration_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Education_Concentration_Choices_ManyArgs = {
  updates: Array<Candidate_Education_Concentration_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Education_Degree_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Education_Degree_Choices_Set_Input>;
  where: Candidate_Education_Degree_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Education_Degree_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Education_Degree_Choices_Set_Input>;
  pk_columns: Candidate_Education_Degree_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Education_Degree_Choices_ManyArgs = {
  updates: Array<Candidate_Education_Degree_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Education_ManyArgs = {
  updates: Array<Candidate_Education_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_EmailArgs = {
  _inc?: InputMaybe<Candidate_Email_Inc_Input>;
  _set?: InputMaybe<Candidate_Email_Set_Input>;
  where: Candidate_Email_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Email_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Email_Inc_Input>;
  _set?: InputMaybe<Candidate_Email_Set_Input>;
  pk_columns: Candidate_Email_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Email_ManyArgs = {
  updates: Array<Candidate_Email_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_EmbeddingArgs = {
  _inc?: InputMaybe<Candidate_Embedding_Inc_Input>;
  _set?: InputMaybe<Candidate_Embedding_Set_Input>;
  where: Candidate_Embedding_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Embedding_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Embedding_Inc_Input>;
  _set?: InputMaybe<Candidate_Embedding_Set_Input>;
  pk_columns: Candidate_Embedding_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Embedding_ManyArgs = {
  updates: Array<Candidate_Embedding_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Highlight_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Highlight_Choices_Set_Input>;
  where: Candidate_Highlight_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Highlight_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Highlight_Choices_Set_Input>;
  pk_columns: Candidate_Highlight_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Highlight_Choices_ManyArgs = {
  updates: Array<Candidate_Highlight_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Hub_LocationArgs = {
  _inc?: InputMaybe<Candidate_Hub_Location_Inc_Input>;
  _set?: InputMaybe<Candidate_Hub_Location_Set_Input>;
  where: Candidate_Hub_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Hub_Location_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Hub_Location_Inc_Input>;
  _set?: InputMaybe<Candidate_Hub_Location_Set_Input>;
  pk_columns: Candidate_Hub_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Hub_Location_ManyArgs = {
  updates: Array<Candidate_Hub_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Interview_InviteArgs = {
  _inc?: InputMaybe<Candidate_Interview_Invite_Inc_Input>;
  _set?: InputMaybe<Candidate_Interview_Invite_Set_Input>;
  where: Candidate_Interview_Invite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Interview_Invite_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Interview_Invite_Inc_Input>;
  _set?: InputMaybe<Candidate_Interview_Invite_Set_Input>;
  pk_columns: Candidate_Interview_Invite_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Interview_Invite_ManyArgs = {
  updates: Array<Candidate_Interview_Invite_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_JobArgs = {
  _inc?: InputMaybe<Candidate_Job_Inc_Input>;
  _set?: InputMaybe<Candidate_Job_Set_Input>;
  where: Candidate_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Job_Inc_Input>;
  _set?: InputMaybe<Candidate_Job_Set_Input>;
  pk_columns: Candidate_Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_ManyArgs = {
  updates: Array<Candidate_Job_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_WorkflowArgs = {
  _inc?: InputMaybe<Candidate_Job_Workflow_Inc_Input>;
  _set?: InputMaybe<Candidate_Job_Workflow_Set_Input>;
  where: Candidate_Job_Workflow_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_Workflow_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Job_Workflow_Inc_Input>;
  _set?: InputMaybe<Candidate_Job_Workflow_Set_Input>;
  pk_columns: Candidate_Job_Workflow_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_Workflow_ManyArgs = {
  updates: Array<Candidate_Job_Workflow_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_Workflow_Source_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Set_Input>;
  where: Candidate_Job_Workflow_Source_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_Workflow_Source_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Set_Input>;
  pk_columns: Candidate_Job_Workflow_Source_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_Workflow_Source_Choices_ManyArgs = {
  updates: Array<Candidate_Job_Workflow_Source_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_Workflow_StatusArgs = {
  _inc?: InputMaybe<Candidate_Job_Workflow_Status_Inc_Input>;
  _set?: InputMaybe<Candidate_Job_Workflow_Status_Set_Input>;
  where: Candidate_Job_Workflow_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_Workflow_Status_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Job_Workflow_Status_Inc_Input>;
  _set?: InputMaybe<Candidate_Job_Workflow_Status_Set_Input>;
  pk_columns: Candidate_Job_Workflow_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_Workflow_Status_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Set_Input>;
  where: Candidate_Job_Workflow_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_Workflow_Status_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Set_Input>;
  pk_columns: Candidate_Job_Workflow_Status_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_Workflow_Status_Choices_ManyArgs = {
  updates: Array<Candidate_Job_Workflow_Status_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Job_Workflow_Status_ManyArgs = {
  updates: Array<Candidate_Job_Workflow_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_ManyArgs = {
  updates: Array<Candidate_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Parsed_ResumeArgs = {
  _inc?: InputMaybe<Candidate_Parsed_Resume_Inc_Input>;
  _set?: InputMaybe<Candidate_Parsed_Resume_Set_Input>;
  where: Candidate_Parsed_Resume_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Parsed_Resume_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Parsed_Resume_Inc_Input>;
  _set?: InputMaybe<Candidate_Parsed_Resume_Set_Input>;
  pk_columns: Candidate_Parsed_Resume_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Parsed_Resume_ManyArgs = {
  updates: Array<Candidate_Parsed_Resume_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_PdlArgs = {
  _append?: InputMaybe<Candidate_Pdl_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Pdl_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Pdl_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Pdl_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Pdl_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Pdl_Prepend_Input>;
  _set?: InputMaybe<Candidate_Pdl_Set_Input>;
  where: Candidate_Pdl_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Pdl_By_PkArgs = {
  _append?: InputMaybe<Candidate_Pdl_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Pdl_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Pdl_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Pdl_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Pdl_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Pdl_Prepend_Input>;
  _set?: InputMaybe<Candidate_Pdl_Set_Input>;
  pk_columns: Candidate_Pdl_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Pdl_ManyArgs = {
  updates: Array<Candidate_Pdl_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_ReferralsArgs = {
  _inc?: InputMaybe<Candidate_Referrals_Inc_Input>;
  _set?: InputMaybe<Candidate_Referrals_Set_Input>;
  where: Candidate_Referrals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Referrals_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Referrals_Inc_Input>;
  _set?: InputMaybe<Candidate_Referrals_Set_Input>;
  pk_columns: Candidate_Referrals_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Referrals_ManyArgs = {
  updates: Array<Candidate_Referrals_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_RoleArgs = {
  _inc?: InputMaybe<Candidate_Role_Inc_Input>;
  _set?: InputMaybe<Candidate_Role_Set_Input>;
  where: Candidate_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Role_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Role_Inc_Input>;
  _set?: InputMaybe<Candidate_Role_Set_Input>;
  pk_columns: Candidate_Role_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Role_ManyArgs = {
  updates: Array<Candidate_Role_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Role_SkillArgs = {
  _inc?: InputMaybe<Candidate_Role_Skill_Inc_Input>;
  _set?: InputMaybe<Candidate_Role_Skill_Set_Input>;
  where: Candidate_Role_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Role_Skill_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Role_Skill_Inc_Input>;
  _set?: InputMaybe<Candidate_Role_Skill_Set_Input>;
  pk_columns: Candidate_Role_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Role_Skill_ManyArgs = {
  updates: Array<Candidate_Role_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Sdlc_Experience_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Sdlc_Experience_Choices_Set_Input>;
  where: Candidate_Sdlc_Experience_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Sdlc_Experience_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Sdlc_Experience_Choices_Set_Input>;
  pk_columns: Candidate_Sdlc_Experience_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Sdlc_Experience_Choices_ManyArgs = {
  updates: Array<Candidate_Sdlc_Experience_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_SkillArgs = {
  _inc?: InputMaybe<Candidate_Skill_Inc_Input>;
  _set?: InputMaybe<Candidate_Skill_Set_Input>;
  where: Candidate_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Skill_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Skill_Inc_Input>;
  _set?: InputMaybe<Candidate_Skill_Set_Input>;
  pk_columns: Candidate_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Skill_Comp_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Skill_Comp_Choices_Set_Input>;
  where: Candidate_Skill_Comp_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Skill_Comp_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Skill_Comp_Choices_Set_Input>;
  pk_columns: Candidate_Skill_Comp_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Skill_Comp_Choices_ManyArgs = {
  updates: Array<Candidate_Skill_Comp_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Skill_ManyArgs = {
  updates: Array<Candidate_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Source_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Source_Choices_Set_Input>;
  where: Candidate_Source_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Source_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Source_Choices_Set_Input>;
  pk_columns: Candidate_Source_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Source_Choices_ManyArgs = {
  updates: Array<Candidate_Source_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Stage_Choices_Set_Input>;
  where: Candidate_Stage_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Stage_Choices_Set_Input>;
  pk_columns: Candidate_Stage_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_Choices_ManyArgs = {
  updates: Array<Candidate_Stage_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_Choices_OrderArgs = {
  _inc?: InputMaybe<Candidate_Stage_Choices_Order_Inc_Input>;
  _set?: InputMaybe<Candidate_Stage_Choices_Order_Set_Input>;
  where: Candidate_Stage_Choices_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_Choices_Order_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Stage_Choices_Order_Inc_Input>;
  _set?: InputMaybe<Candidate_Stage_Choices_Order_Set_Input>;
  pk_columns: Candidate_Stage_Choices_Order_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_Choices_Order_ManyArgs = {
  updates: Array<Candidate_Stage_Choices_Order_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_LogArgs = {
  _inc?: InputMaybe<Candidate_Stage_Log_Inc_Input>;
  _set?: InputMaybe<Candidate_Stage_Log_Set_Input>;
  where: Candidate_Stage_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_Log_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Stage_Log_Inc_Input>;
  _set?: InputMaybe<Candidate_Stage_Log_Set_Input>;
  pk_columns: Candidate_Stage_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_Log_ManyArgs = {
  updates: Array<Candidate_Stage_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_Source_ChoicesArgs = {
  _set?: InputMaybe<Candidate_Stage_Source_Choices_Set_Input>;
  where: Candidate_Stage_Source_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_Source_Choices_By_PkArgs = {
  _set?: InputMaybe<Candidate_Stage_Source_Choices_Set_Input>;
  pk_columns: Candidate_Stage_Source_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Stage_Source_Choices_ManyArgs = {
  updates: Array<Candidate_Stage_Source_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_TraitArgs = {
  _append?: InputMaybe<Candidate_Trait_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Trait_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Trait_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Trait_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Trait_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Trait_Prepend_Input>;
  _set?: InputMaybe<Candidate_Trait_Set_Input>;
  where: Candidate_Trait_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Trait_By_PkArgs = {
  _append?: InputMaybe<Candidate_Trait_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Trait_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Trait_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Trait_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Trait_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Trait_Prepend_Input>;
  _set?: InputMaybe<Candidate_Trait_Set_Input>;
  pk_columns: Candidate_Trait_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Trait_ManyArgs = {
  updates: Array<Candidate_Trait_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Work_ExperienceArgs = {
  _append?: InputMaybe<Candidate_Work_Experience_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Work_Experience_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Work_Experience_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Work_Experience_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Work_Experience_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Work_Experience_Prepend_Input>;
  _set?: InputMaybe<Candidate_Work_Experience_Set_Input>;
  where: Candidate_Work_Experience_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Work_Experience_By_PkArgs = {
  _append?: InputMaybe<Candidate_Work_Experience_Append_Input>;
  _delete_at_path?: InputMaybe<Candidate_Work_Experience_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Candidate_Work_Experience_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Candidate_Work_Experience_Delete_Key_Input>;
  _inc?: InputMaybe<Candidate_Work_Experience_Inc_Input>;
  _prepend?: InputMaybe<Candidate_Work_Experience_Prepend_Input>;
  _set?: InputMaybe<Candidate_Work_Experience_Set_Input>;
  pk_columns: Candidate_Work_Experience_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Work_Experience_ManyArgs = {
  updates: Array<Candidate_Work_Experience_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Work_Experience_SkillArgs = {
  _inc?: InputMaybe<Candidate_Work_Experience_Skill_Inc_Input>;
  _set?: InputMaybe<Candidate_Work_Experience_Skill_Set_Input>;
  where: Candidate_Work_Experience_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Work_Experience_Skill_By_PkArgs = {
  _inc?: InputMaybe<Candidate_Work_Experience_Skill_Inc_Input>;
  _set?: InputMaybe<Candidate_Work_Experience_Skill_Set_Input>;
  pk_columns: Candidate_Work_Experience_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Candidate_Work_Experience_Skill_ManyArgs = {
  updates: Array<Candidate_Work_Experience_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Candidates_In_Interview_Or_Rejected_By_Organization_ResultArgs = {
  _inc?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Inc_Input>;
  _set?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Set_Input>;
  where: Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Candidates_In_Interview_Or_Rejected_By_Organization_Result_ManyArgs =
  {
    updates: Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Updates>;
  };

/** mutation root */
export type Mutation_RootUpdate_Celery_Celery_TaskmetaArgs = {
  _inc?: InputMaybe<Celery_Celery_Taskmeta_Inc_Input>;
  _set?: InputMaybe<Celery_Celery_Taskmeta_Set_Input>;
  where: Celery_Celery_Taskmeta_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Celery_Celery_Taskmeta_By_PkArgs = {
  _inc?: InputMaybe<Celery_Celery_Taskmeta_Inc_Input>;
  _set?: InputMaybe<Celery_Celery_Taskmeta_Set_Input>;
  pk_columns: Celery_Celery_Taskmeta_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Celery_Celery_Taskmeta_ManyArgs = {
  updates: Array<Celery_Celery_Taskmeta_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Celery_Celery_TasksetmetaArgs = {
  _inc?: InputMaybe<Celery_Celery_Tasksetmeta_Inc_Input>;
  _set?: InputMaybe<Celery_Celery_Tasksetmeta_Set_Input>;
  where: Celery_Celery_Tasksetmeta_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Celery_Celery_Tasksetmeta_By_PkArgs = {
  _inc?: InputMaybe<Celery_Celery_Tasksetmeta_Inc_Input>;
  _set?: InputMaybe<Celery_Celery_Tasksetmeta_Set_Input>;
  pk_columns: Celery_Celery_Tasksetmeta_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Celery_Celery_Tasksetmeta_ManyArgs = {
  updates: Array<Celery_Celery_Tasksetmeta_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Celery_Task_Retry_LogArgs = {
  _set?: InputMaybe<Celery_Task_Retry_Log_Set_Input>;
  where: Celery_Task_Retry_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Celery_Task_Retry_Log_By_PkArgs = {
  _set?: InputMaybe<Celery_Task_Retry_Log_Set_Input>;
  pk_columns: Celery_Task_Retry_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Celery_Task_Retry_Log_ManyArgs = {
  updates: Array<Celery_Task_Retry_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_ProspectArgs = {
  _append?: InputMaybe<Client_Prospect_Append_Input>;
  _delete_at_path?: InputMaybe<Client_Prospect_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Client_Prospect_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Client_Prospect_Delete_Key_Input>;
  _inc?: InputMaybe<Client_Prospect_Inc_Input>;
  _prepend?: InputMaybe<Client_Prospect_Prepend_Input>;
  _set?: InputMaybe<Client_Prospect_Set_Input>;
  where: Client_Prospect_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_By_PkArgs = {
  _append?: InputMaybe<Client_Prospect_Append_Input>;
  _delete_at_path?: InputMaybe<Client_Prospect_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Client_Prospect_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Client_Prospect_Delete_Key_Input>;
  _inc?: InputMaybe<Client_Prospect_Inc_Input>;
  _prepend?: InputMaybe<Client_Prospect_Prepend_Input>;
  _set?: InputMaybe<Client_Prospect_Set_Input>;
  pk_columns: Client_Prospect_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_EventArgs = {
  _inc?: InputMaybe<Client_Prospect_Event_Inc_Input>;
  _set?: InputMaybe<Client_Prospect_Event_Set_Input>;
  where: Client_Prospect_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_Event_By_PkArgs = {
  _inc?: InputMaybe<Client_Prospect_Event_Inc_Input>;
  _set?: InputMaybe<Client_Prospect_Event_Set_Input>;
  pk_columns: Client_Prospect_Event_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_Event_ManyArgs = {
  updates: Array<Client_Prospect_Event_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_Events_ChoicesArgs = {
  _set?: InputMaybe<Client_Prospect_Events_Choices_Set_Input>;
  where: Client_Prospect_Events_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_Events_Choices_By_PkArgs = {
  _set?: InputMaybe<Client_Prospect_Events_Choices_Set_Input>;
  pk_columns: Client_Prospect_Events_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_Events_Choices_ManyArgs = {
  updates: Array<Client_Prospect_Events_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_ManyArgs = {
  updates: Array<Client_Prospect_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_RoleArgs = {
  _inc?: InputMaybe<Client_Prospect_Role_Inc_Input>;
  _set?: InputMaybe<Client_Prospect_Role_Set_Input>;
  where: Client_Prospect_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_Role_By_PkArgs = {
  _inc?: InputMaybe<Client_Prospect_Role_Inc_Input>;
  _set?: InputMaybe<Client_Prospect_Role_Set_Input>;
  pk_columns: Client_Prospect_Role_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_Role_ManyArgs = {
  updates: Array<Client_Prospect_Role_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_Role_SkillArgs = {
  _inc?: InputMaybe<Client_Prospect_Role_Skill_Inc_Input>;
  _set?: InputMaybe<Client_Prospect_Role_Skill_Set_Input>;
  where: Client_Prospect_Role_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_Role_Skill_By_PkArgs = {
  _inc?: InputMaybe<Client_Prospect_Role_Skill_Inc_Input>;
  _set?: InputMaybe<Client_Prospect_Role_Skill_Set_Input>;
  pk_columns: Client_Prospect_Role_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Prospect_Role_Skill_ManyArgs = {
  updates: Array<Client_Prospect_Role_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_VersionsArgs = {
  _set?: InputMaybe<Client_Versions_Set_Input>;
  where: Client_Versions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Versions_By_PkArgs = {
  _set?: InputMaybe<Client_Versions_Set_Input>;
  pk_columns: Client_Versions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Versions_ManyArgs = {
  updates: Array<Client_Versions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Company_By_PkArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Company_ManyArgs = {
  updates: Array<Company_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Company_Name_SynonymArgs = {
  _inc?: InputMaybe<Company_Name_Synonym_Inc_Input>;
  _set?: InputMaybe<Company_Name_Synonym_Set_Input>;
  where: Company_Name_Synonym_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Company_Name_Synonym_By_PkArgs = {
  _inc?: InputMaybe<Company_Name_Synonym_Inc_Input>;
  _set?: InputMaybe<Company_Name_Synonym_Set_Input>;
  pk_columns: Company_Name_Synonym_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Company_Name_Synonym_ManyArgs = {
  updates: Array<Company_Name_Synonym_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Company_Pdl_BlobArgs = {
  _append?: InputMaybe<Company_Pdl_Blob_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Pdl_Blob_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Pdl_Blob_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Pdl_Blob_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Pdl_Blob_Inc_Input>;
  _prepend?: InputMaybe<Company_Pdl_Blob_Prepend_Input>;
  _set?: InputMaybe<Company_Pdl_Blob_Set_Input>;
  where: Company_Pdl_Blob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Company_Pdl_Blob_By_PkArgs = {
  _append?: InputMaybe<Company_Pdl_Blob_Append_Input>;
  _delete_at_path?: InputMaybe<Company_Pdl_Blob_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Company_Pdl_Blob_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Company_Pdl_Blob_Delete_Key_Input>;
  _inc?: InputMaybe<Company_Pdl_Blob_Inc_Input>;
  _prepend?: InputMaybe<Company_Pdl_Blob_Prepend_Input>;
  _set?: InputMaybe<Company_Pdl_Blob_Set_Input>;
  pk_columns: Company_Pdl_Blob_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Company_Pdl_Blob_ManyArgs = {
  updates: Array<Company_Pdl_Blob_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Company_Size_ChoicesArgs = {
  _set?: InputMaybe<Company_Size_Choices_Set_Input>;
  where: Company_Size_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Company_Size_Choices_By_PkArgs = {
  _set?: InputMaybe<Company_Size_Choices_Set_Input>;
  pk_columns: Company_Size_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Company_Size_Choices_ManyArgs = {
  updates: Array<Company_Size_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ContactArgs = {
  _inc?: InputMaybe<Contact_Inc_Input>;
  _set?: InputMaybe<Contact_Set_Input>;
  where: Contact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_By_PkArgs = {
  _inc?: InputMaybe<Contact_Inc_Input>;
  _set?: InputMaybe<Contact_Set_Input>;
  pk_columns: Contact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Contact_ManyArgs = {
  updates: Array<Contact_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Contract_Length_ChoicesArgs = {
  _set?: InputMaybe<Contract_Length_Choices_Set_Input>;
  where: Contract_Length_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Contract_Length_Choices_By_PkArgs = {
  _set?: InputMaybe<Contract_Length_Choices_Set_Input>;
  pk_columns: Contract_Length_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Contract_Length_Choices_ManyArgs = {
  updates: Array<Contract_Length_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Country_ChoicesArgs = {
  _inc?: InputMaybe<Country_Choices_Inc_Input>;
  _set?: InputMaybe<Country_Choices_Set_Input>;
  where: Country_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Country_Choices_By_PkArgs = {
  _inc?: InputMaybe<Country_Choices_Inc_Input>;
  _set?: InputMaybe<Country_Choices_Set_Input>;
  pk_columns: Country_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Country_Choices_ManyArgs = {
  updates: Array<Country_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Currency_Exchange_RateArgs = {
  _inc?: InputMaybe<Currency_Exchange_Rate_Inc_Input>;
  _set?: InputMaybe<Currency_Exchange_Rate_Set_Input>;
  where: Currency_Exchange_Rate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Currency_Exchange_Rate_By_PkArgs = {
  _inc?: InputMaybe<Currency_Exchange_Rate_Inc_Input>;
  _set?: InputMaybe<Currency_Exchange_Rate_Set_Input>;
  pk_columns: Currency_Exchange_Rate_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Currency_Exchange_Rate_ManyArgs = {
  updates: Array<Currency_Exchange_Rate_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_AssignmentArgs = {
  _inc?: InputMaybe<Dayforce_Assignment_Inc_Input>;
  _set?: InputMaybe<Dayforce_Assignment_Set_Input>;
  where: Dayforce_Assignment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_Assignment_By_PkArgs = {
  _inc?: InputMaybe<Dayforce_Assignment_Inc_Input>;
  _set?: InputMaybe<Dayforce_Assignment_Set_Input>;
  pk_columns: Dayforce_Assignment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_Assignment_ManyArgs = {
  updates: Array<Dayforce_Assignment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_CustomerArgs = {
  _inc?: InputMaybe<Dayforce_Customer_Inc_Input>;
  _set?: InputMaybe<Dayforce_Customer_Set_Input>;
  where: Dayforce_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_Customer_By_PkArgs = {
  _inc?: InputMaybe<Dayforce_Customer_Inc_Input>;
  _set?: InputMaybe<Dayforce_Customer_Set_Input>;
  pk_columns: Dayforce_Customer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_Customer_ManyArgs = {
  updates: Array<Dayforce_Customer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_EmployeeArgs = {
  _inc?: InputMaybe<Dayforce_Employee_Inc_Input>;
  _set?: InputMaybe<Dayforce_Employee_Set_Input>;
  where: Dayforce_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_Employee_By_PkArgs = {
  _inc?: InputMaybe<Dayforce_Employee_Inc_Input>;
  _set?: InputMaybe<Dayforce_Employee_Set_Input>;
  pk_columns: Dayforce_Employee_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_Employee_ManyArgs = {
  updates: Array<Dayforce_Employee_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_LocationArgs = {
  _inc?: InputMaybe<Dayforce_Location_Inc_Input>;
  _set?: InputMaybe<Dayforce_Location_Set_Input>;
  where: Dayforce_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_Location_By_PkArgs = {
  _inc?: InputMaybe<Dayforce_Location_Inc_Input>;
  _set?: InputMaybe<Dayforce_Location_Set_Input>;
  pk_columns: Dayforce_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Dayforce_Location_ManyArgs = {
  updates: Array<Dayforce_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Email_NotificationArgs = {
  _append?: InputMaybe<Email_Notification_Append_Input>;
  _delete_at_path?: InputMaybe<Email_Notification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Email_Notification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Email_Notification_Delete_Key_Input>;
  _inc?: InputMaybe<Email_Notification_Inc_Input>;
  _prepend?: InputMaybe<Email_Notification_Prepend_Input>;
  _set?: InputMaybe<Email_Notification_Set_Input>;
  where: Email_Notification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Notification_By_PkArgs = {
  _append?: InputMaybe<Email_Notification_Append_Input>;
  _delete_at_path?: InputMaybe<Email_Notification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Email_Notification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Email_Notification_Delete_Key_Input>;
  _inc?: InputMaybe<Email_Notification_Inc_Input>;
  _prepend?: InputMaybe<Email_Notification_Prepend_Input>;
  _set?: InputMaybe<Email_Notification_Set_Input>;
  pk_columns: Email_Notification_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Notification_ManyArgs = {
  updates: Array<Email_Notification_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Email_TemplateArgs = {
  _inc?: InputMaybe<Email_Template_Inc_Input>;
  _set?: InputMaybe<Email_Template_Set_Input>;
  where: Email_Template_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Template_By_PkArgs = {
  _inc?: InputMaybe<Email_Template_Inc_Input>;
  _set?: InputMaybe<Email_Template_Set_Input>;
  pk_columns: Email_Template_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Template_ManyArgs = {
  updates: Array<Email_Template_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Employment_Type_ChoicesArgs = {
  _set?: InputMaybe<Employment_Type_Choices_Set_Input>;
  where: Employment_Type_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Employment_Type_Choices_By_PkArgs = {
  _set?: InputMaybe<Employment_Type_Choices_Set_Input>;
  pk_columns: Employment_Type_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Employment_Type_Choices_ManyArgs = {
  updates: Array<Employment_Type_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Popular_SearchArgs = {
  _inc?: InputMaybe<Explore_Candidates_Popular_Search_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Popular_Search_Set_Input>;
  where: Explore_Candidates_Popular_Search_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Popular_Search_By_PkArgs = {
  _inc?: InputMaybe<Explore_Candidates_Popular_Search_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Popular_Search_Set_Input>;
  pk_columns: Explore_Candidates_Popular_Search_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Popular_Search_ManyArgs = {
  updates: Array<Explore_Candidates_Popular_Search_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Query_ResultArgs = {
  _inc?: InputMaybe<Explore_Candidates_Query_Result_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Query_Result_Set_Input>;
  where: Explore_Candidates_Query_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Query_Result_By_PkArgs = {
  _inc?: InputMaybe<Explore_Candidates_Query_Result_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Query_Result_Set_Input>;
  pk_columns: Explore_Candidates_Query_Result_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Query_Result_ManyArgs = {
  updates: Array<Explore_Candidates_Query_Result_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_SearchArgs = {
  _inc?: InputMaybe<Explore_Candidates_Search_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Search_Set_Input>;
  where: Explore_Candidates_Search_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_By_PkArgs = {
  _inc?: InputMaybe<Explore_Candidates_Search_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Search_Set_Input>;
  pk_columns: Explore_Candidates_Search_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_Candidate_HighlightArgs = {
  _inc?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Set_Input>;
  where: Explore_Candidates_Search_Candidate_Highlight_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_Candidate_Highlight_By_PkArgs = {
  _inc?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Set_Input>;
  pk_columns: Explore_Candidates_Search_Candidate_Highlight_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_Candidate_Highlight_ManyArgs = {
  updates: Array<Explore_Candidates_Search_Candidate_Highlight_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_ManyArgs = {
  updates: Array<Explore_Candidates_Search_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_Must_Have_SkillArgs = {
  _inc?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Set_Input>;
  where: Explore_Candidates_Search_Must_Have_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_Must_Have_Skill_By_PkArgs = {
  _inc?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Set_Input>;
  pk_columns: Explore_Candidates_Search_Must_Have_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_Must_Have_Skill_ManyArgs = {
  updates: Array<Explore_Candidates_Search_Must_Have_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_Nice_To_Have_SkillArgs = {
  _inc?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Set_Input>;
  where: Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_Nice_To_Have_Skill_By_PkArgs = {
  _inc?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Set_Input>;
  pk_columns: Explore_Candidates_Search_Nice_To_Have_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_Nice_To_Have_Skill_ManyArgs = {
  updates: Array<Explore_Candidates_Search_Nice_To_Have_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_RegionArgs = {
  _inc?: InputMaybe<Explore_Candidates_Search_Region_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Search_Region_Set_Input>;
  where: Explore_Candidates_Search_Region_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_Region_By_PkArgs = {
  _inc?: InputMaybe<Explore_Candidates_Search_Region_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_Search_Region_Set_Input>;
  pk_columns: Explore_Candidates_Search_Region_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_Search_Region_ManyArgs = {
  updates: Array<Explore_Candidates_Search_Region_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_User_Saved_SearchArgs = {
  _inc?: InputMaybe<Explore_Candidates_User_Saved_Search_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_User_Saved_Search_Set_Input>;
  where: Explore_Candidates_User_Saved_Search_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_User_Saved_Search_By_PkArgs = {
  _inc?: InputMaybe<Explore_Candidates_User_Saved_Search_Inc_Input>;
  _set?: InputMaybe<Explore_Candidates_User_Saved_Search_Set_Input>;
  pk_columns: Explore_Candidates_User_Saved_Search_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Explore_Candidates_User_Saved_Search_ManyArgs = {
  updates: Array<Explore_Candidates_User_Saved_Search_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_External_Candidate_MetaArgs = {
  _append?: InputMaybe<External_Candidate_Meta_Append_Input>;
  _delete_at_path?: InputMaybe<External_Candidate_Meta_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<External_Candidate_Meta_Delete_Elem_Input>;
  _delete_key?: InputMaybe<External_Candidate_Meta_Delete_Key_Input>;
  _inc?: InputMaybe<External_Candidate_Meta_Inc_Input>;
  _prepend?: InputMaybe<External_Candidate_Meta_Prepend_Input>;
  _set?: InputMaybe<External_Candidate_Meta_Set_Input>;
  where: External_Candidate_Meta_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_External_Candidate_Meta_By_PkArgs = {
  _append?: InputMaybe<External_Candidate_Meta_Append_Input>;
  _delete_at_path?: InputMaybe<External_Candidate_Meta_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<External_Candidate_Meta_Delete_Elem_Input>;
  _delete_key?: InputMaybe<External_Candidate_Meta_Delete_Key_Input>;
  _inc?: InputMaybe<External_Candidate_Meta_Inc_Input>;
  _prepend?: InputMaybe<External_Candidate_Meta_Prepend_Input>;
  _set?: InputMaybe<External_Candidate_Meta_Set_Input>;
  pk_columns: External_Candidate_Meta_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_External_Candidate_Meta_ManyArgs = {
  updates: Array<External_Candidate_Meta_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FeatureArgs = {
  _inc?: InputMaybe<Feature_Inc_Input>;
  _set?: InputMaybe<Feature_Set_Input>;
  where: Feature_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Feature_By_PkArgs = {
  _inc?: InputMaybe<Feature_Inc_Input>;
  _set?: InputMaybe<Feature_Set_Input>;
  pk_columns: Feature_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Feature_ManyArgs = {
  updates: Array<Feature_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Hireflix_TranscriptArgs = {
  _append?: InputMaybe<Hireflix_Transcript_Append_Input>;
  _delete_at_path?: InputMaybe<Hireflix_Transcript_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hireflix_Transcript_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hireflix_Transcript_Delete_Key_Input>;
  _inc?: InputMaybe<Hireflix_Transcript_Inc_Input>;
  _prepend?: InputMaybe<Hireflix_Transcript_Prepend_Input>;
  _set?: InputMaybe<Hireflix_Transcript_Set_Input>;
  where: Hireflix_Transcript_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hireflix_Transcript_BlobArgs = {
  _append?: InputMaybe<Hireflix_Transcript_Blob_Append_Input>;
  _delete_at_path?: InputMaybe<Hireflix_Transcript_Blob_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hireflix_Transcript_Blob_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hireflix_Transcript_Blob_Delete_Key_Input>;
  _prepend?: InputMaybe<Hireflix_Transcript_Blob_Prepend_Input>;
  _set?: InputMaybe<Hireflix_Transcript_Blob_Set_Input>;
  where: Hireflix_Transcript_Blob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hireflix_Transcript_Blob_By_PkArgs = {
  _append?: InputMaybe<Hireflix_Transcript_Blob_Append_Input>;
  _delete_at_path?: InputMaybe<Hireflix_Transcript_Blob_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hireflix_Transcript_Blob_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hireflix_Transcript_Blob_Delete_Key_Input>;
  _prepend?: InputMaybe<Hireflix_Transcript_Blob_Prepend_Input>;
  _set?: InputMaybe<Hireflix_Transcript_Blob_Set_Input>;
  pk_columns: Hireflix_Transcript_Blob_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hireflix_Transcript_Blob_ManyArgs = {
  updates: Array<Hireflix_Transcript_Blob_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Hireflix_Transcript_By_PkArgs = {
  _append?: InputMaybe<Hireflix_Transcript_Append_Input>;
  _delete_at_path?: InputMaybe<Hireflix_Transcript_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hireflix_Transcript_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hireflix_Transcript_Delete_Key_Input>;
  _inc?: InputMaybe<Hireflix_Transcript_Inc_Input>;
  _prepend?: InputMaybe<Hireflix_Transcript_Prepend_Input>;
  _set?: InputMaybe<Hireflix_Transcript_Set_Input>;
  pk_columns: Hireflix_Transcript_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hireflix_Transcript_ManyArgs = {
  updates: Array<Hireflix_Transcript_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Hr_TestArgs = {
  _append?: InputMaybe<Hr_Test_Append_Input>;
  _delete_at_path?: InputMaybe<Hr_Test_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hr_Test_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hr_Test_Delete_Key_Input>;
  _inc?: InputMaybe<Hr_Test_Inc_Input>;
  _prepend?: InputMaybe<Hr_Test_Prepend_Input>;
  _set?: InputMaybe<Hr_Test_Set_Input>;
  where: Hr_Test_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hr_Test_By_PkArgs = {
  _append?: InputMaybe<Hr_Test_Append_Input>;
  _delete_at_path?: InputMaybe<Hr_Test_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hr_Test_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hr_Test_Delete_Key_Input>;
  _inc?: InputMaybe<Hr_Test_Inc_Input>;
  _prepend?: InputMaybe<Hr_Test_Prepend_Input>;
  _set?: InputMaybe<Hr_Test_Set_Input>;
  pk_columns: Hr_Test_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hr_Test_CandidateArgs = {
  _inc?: InputMaybe<Hr_Test_Candidate_Inc_Input>;
  _set?: InputMaybe<Hr_Test_Candidate_Set_Input>;
  where: Hr_Test_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hr_Test_Candidate_By_PkArgs = {
  _inc?: InputMaybe<Hr_Test_Candidate_Inc_Input>;
  _set?: InputMaybe<Hr_Test_Candidate_Set_Input>;
  pk_columns: Hr_Test_Candidate_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hr_Test_Candidate_ManyArgs = {
  updates: Array<Hr_Test_Candidate_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Hr_Test_ManyArgs = {
  updates: Array<Hr_Test_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Hr_Test_ResultArgs = {
  _append?: InputMaybe<Hr_Test_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Hr_Test_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hr_Test_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hr_Test_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Hr_Test_Result_Inc_Input>;
  _prepend?: InputMaybe<Hr_Test_Result_Prepend_Input>;
  _set?: InputMaybe<Hr_Test_Result_Set_Input>;
  where: Hr_Test_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hr_Test_Result_By_PkArgs = {
  _append?: InputMaybe<Hr_Test_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Hr_Test_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hr_Test_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hr_Test_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Hr_Test_Result_Inc_Input>;
  _prepend?: InputMaybe<Hr_Test_Result_Prepend_Input>;
  _set?: InputMaybe<Hr_Test_Result_Set_Input>;
  pk_columns: Hr_Test_Result_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hr_Test_Result_ManyArgs = {
  updates: Array<Hr_Test_Result_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Applicant_WorkflowArgs = {
  _inc?: InputMaybe<Icims_Applicant_Workflow_Inc_Input>;
  _set?: InputMaybe<Icims_Applicant_Workflow_Set_Input>;
  where: Icims_Applicant_Workflow_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Applicant_Workflow_By_PkArgs = {
  _inc?: InputMaybe<Icims_Applicant_Workflow_Inc_Input>;
  _set?: InputMaybe<Icims_Applicant_Workflow_Set_Input>;
  pk_columns: Icims_Applicant_Workflow_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Applicant_Workflow_FeedbackArgs = {
  _append?: InputMaybe<Icims_Applicant_Workflow_Feedback_Append_Input>;
  _delete_at_path?: InputMaybe<Icims_Applicant_Workflow_Feedback_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Icims_Applicant_Workflow_Feedback_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Icims_Applicant_Workflow_Feedback_Delete_Key_Input>;
  _inc?: InputMaybe<Icims_Applicant_Workflow_Feedback_Inc_Input>;
  _prepend?: InputMaybe<Icims_Applicant_Workflow_Feedback_Prepend_Input>;
  _set?: InputMaybe<Icims_Applicant_Workflow_Feedback_Set_Input>;
  where: Icims_Applicant_Workflow_Feedback_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Applicant_Workflow_Feedback_By_PkArgs = {
  _append?: InputMaybe<Icims_Applicant_Workflow_Feedback_Append_Input>;
  _delete_at_path?: InputMaybe<Icims_Applicant_Workflow_Feedback_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Icims_Applicant_Workflow_Feedback_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Icims_Applicant_Workflow_Feedback_Delete_Key_Input>;
  _inc?: InputMaybe<Icims_Applicant_Workflow_Feedback_Inc_Input>;
  _prepend?: InputMaybe<Icims_Applicant_Workflow_Feedback_Prepend_Input>;
  _set?: InputMaybe<Icims_Applicant_Workflow_Feedback_Set_Input>;
  pk_columns: Icims_Applicant_Workflow_Feedback_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Applicant_Workflow_Feedback_ManyArgs = {
  updates: Array<Icims_Applicant_Workflow_Feedback_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Applicant_Workflow_ManyArgs = {
  updates: Array<Icims_Applicant_Workflow_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Applicant_Workflow_StatusArgs = {
  _inc?: InputMaybe<Icims_Applicant_Workflow_Status_Inc_Input>;
  _set?: InputMaybe<Icims_Applicant_Workflow_Status_Set_Input>;
  where: Icims_Applicant_Workflow_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Applicant_Workflow_Status_By_PkArgs = {
  _inc?: InputMaybe<Icims_Applicant_Workflow_Status_Inc_Input>;
  _set?: InputMaybe<Icims_Applicant_Workflow_Status_Set_Input>;
  pk_columns: Icims_Applicant_Workflow_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Applicant_Workflow_Status_ManyArgs = {
  updates: Array<Icims_Applicant_Workflow_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_CompanyArgs = {
  _inc?: InputMaybe<Icims_Company_Inc_Input>;
  _set?: InputMaybe<Icims_Company_Set_Input>;
  where: Icims_Company_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Company_By_PkArgs = {
  _inc?: InputMaybe<Icims_Company_Inc_Input>;
  _set?: InputMaybe<Icims_Company_Set_Input>;
  pk_columns: Icims_Company_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Company_ManyArgs = {
  updates: Array<Icims_Company_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_CountryArgs = {
  _inc?: InputMaybe<Icims_Country_Inc_Input>;
  _set?: InputMaybe<Icims_Country_Set_Input>;
  where: Icims_Country_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Country_By_PkArgs = {
  _inc?: InputMaybe<Icims_Country_Inc_Input>;
  _set?: InputMaybe<Icims_Country_Set_Input>;
  pk_columns: Icims_Country_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Country_ManyArgs = {
  updates: Array<Icims_Country_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_DegreeArgs = {
  _inc?: InputMaybe<Icims_Degree_Inc_Input>;
  _set?: InputMaybe<Icims_Degree_Set_Input>;
  where: Icims_Degree_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Degree_By_PkArgs = {
  _inc?: InputMaybe<Icims_Degree_Inc_Input>;
  _set?: InputMaybe<Icims_Degree_Set_Input>;
  pk_columns: Icims_Degree_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Degree_ManyArgs = {
  updates: Array<Icims_Degree_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_EducationArgs = {
  _inc?: InputMaybe<Icims_Education_Inc_Input>;
  _set?: InputMaybe<Icims_Education_Set_Input>;
  where: Icims_Education_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Education_By_PkArgs = {
  _inc?: InputMaybe<Icims_Education_Inc_Input>;
  _set?: InputMaybe<Icims_Education_Set_Input>;
  pk_columns: Icims_Education_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Education_ManyArgs = {
  updates: Array<Icims_Education_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_FolderArgs = {
  _inc?: InputMaybe<Icims_Folder_Inc_Input>;
  _set?: InputMaybe<Icims_Folder_Set_Input>;
  where: Icims_Folder_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Folder_By_PkArgs = {
  _inc?: InputMaybe<Icims_Folder_Inc_Input>;
  _set?: InputMaybe<Icims_Folder_Set_Input>;
  pk_columns: Icims_Folder_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Folder_ManyArgs = {
  updates: Array<Icims_Folder_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Initial_Data_TransferArgs = {
  _inc?: InputMaybe<Icims_Initial_Data_Transfer_Inc_Input>;
  _set?: InputMaybe<Icims_Initial_Data_Transfer_Set_Input>;
  where: Icims_Initial_Data_Transfer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Initial_Data_Transfer_By_PkArgs = {
  _inc?: InputMaybe<Icims_Initial_Data_Transfer_Inc_Input>;
  _set?: InputMaybe<Icims_Initial_Data_Transfer_Set_Input>;
  pk_columns: Icims_Initial_Data_Transfer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Initial_Data_Transfer_ManyArgs = {
  updates: Array<Icims_Initial_Data_Transfer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Interested_LocationArgs = {
  _inc?: InputMaybe<Icims_Interested_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Interested_Location_Set_Input>;
  where: Icims_Interested_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Interested_Location_By_PkArgs = {
  _inc?: InputMaybe<Icims_Interested_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Interested_Location_Set_Input>;
  pk_columns: Icims_Interested_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Interested_Location_ManyArgs = {
  updates: Array<Icims_Interested_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_JobArgs = {
  _append?: InputMaybe<Icims_Job_Append_Input>;
  _delete_at_path?: InputMaybe<Icims_Job_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Icims_Job_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Icims_Job_Delete_Key_Input>;
  _inc?: InputMaybe<Icims_Job_Inc_Input>;
  _prepend?: InputMaybe<Icims_Job_Prepend_Input>;
  _set?: InputMaybe<Icims_Job_Set_Input>;
  where: Icims_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Job_By_PkArgs = {
  _append?: InputMaybe<Icims_Job_Append_Input>;
  _delete_at_path?: InputMaybe<Icims_Job_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Icims_Job_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Icims_Job_Delete_Key_Input>;
  _inc?: InputMaybe<Icims_Job_Inc_Input>;
  _prepend?: InputMaybe<Icims_Job_Prepend_Input>;
  _set?: InputMaybe<Icims_Job_Set_Input>;
  pk_columns: Icims_Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Job_LocationArgs = {
  _inc?: InputMaybe<Icims_Job_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Job_Location_Set_Input>;
  where: Icims_Job_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Job_Location_By_PkArgs = {
  _inc?: InputMaybe<Icims_Job_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Job_Location_Set_Input>;
  pk_columns: Icims_Job_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Job_Location_ManyArgs = {
  updates: Array<Icims_Job_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Job_ManyArgs = {
  updates: Array<Icims_Job_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Job_Requested_LocationArgs = {
  _inc?: InputMaybe<Icims_Job_Requested_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Job_Requested_Location_Set_Input>;
  where: Icims_Job_Requested_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Job_Requested_Location_By_PkArgs = {
  _inc?: InputMaybe<Icims_Job_Requested_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Job_Requested_Location_Set_Input>;
  pk_columns: Icims_Job_Requested_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Job_Requested_Location_ManyArgs = {
  updates: Array<Icims_Job_Requested_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Job_SkillArgs = {
  _inc?: InputMaybe<Icims_Job_Skill_Inc_Input>;
  _set?: InputMaybe<Icims_Job_Skill_Set_Input>;
  where: Icims_Job_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Job_Skill_By_PkArgs = {
  _inc?: InputMaybe<Icims_Job_Skill_Inc_Input>;
  _set?: InputMaybe<Icims_Job_Skill_Set_Input>;
  pk_columns: Icims_Job_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Job_Skill_ManyArgs = {
  updates: Array<Icims_Job_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_LocationArgs = {
  _inc?: InputMaybe<Icims_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Location_Set_Input>;
  where: Icims_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Location_By_PkArgs = {
  _inc?: InputMaybe<Icims_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Location_Set_Input>;
  pk_columns: Icims_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Location_ManyArgs = {
  updates: Array<Icims_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_MajorArgs = {
  _inc?: InputMaybe<Icims_Major_Inc_Input>;
  _set?: InputMaybe<Icims_Major_Set_Input>;
  where: Icims_Major_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Major_By_PkArgs = {
  _inc?: InputMaybe<Icims_Major_Inc_Input>;
  _set?: InputMaybe<Icims_Major_Set_Input>;
  pk_columns: Icims_Major_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Major_ManyArgs = {
  updates: Array<Icims_Major_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_PersonArgs = {
  _inc?: InputMaybe<Icims_Person_Inc_Input>;
  _set?: InputMaybe<Icims_Person_Set_Input>;
  where: Icims_Person_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Person_By_PkArgs = {
  _inc?: InputMaybe<Icims_Person_Inc_Input>;
  _set?: InputMaybe<Icims_Person_Set_Input>;
  pk_columns: Icims_Person_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Person_Interested_LocationArgs = {
  _inc?: InputMaybe<Icims_Person_Interested_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Person_Interested_Location_Set_Input>;
  where: Icims_Person_Interested_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Person_Interested_Location_By_PkArgs = {
  _inc?: InputMaybe<Icims_Person_Interested_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Person_Interested_Location_Set_Input>;
  pk_columns: Icims_Person_Interested_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Person_Interested_Location_ManyArgs = {
  updates: Array<Icims_Person_Interested_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Person_ManyArgs = {
  updates: Array<Icims_Person_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Person_Preferred_LocationArgs = {
  _inc?: InputMaybe<Icims_Person_Preferred_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Person_Preferred_Location_Set_Input>;
  where: Icims_Person_Preferred_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Person_Preferred_Location_By_PkArgs = {
  _inc?: InputMaybe<Icims_Person_Preferred_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Person_Preferred_Location_Set_Input>;
  pk_columns: Icims_Person_Preferred_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Person_Preferred_Location_ManyArgs = {
  updates: Array<Icims_Person_Preferred_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Person_SkillArgs = {
  _inc?: InputMaybe<Icims_Person_Skill_Inc_Input>;
  _set?: InputMaybe<Icims_Person_Skill_Set_Input>;
  where: Icims_Person_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Person_Skill_By_PkArgs = {
  _inc?: InputMaybe<Icims_Person_Skill_Inc_Input>;
  _set?: InputMaybe<Icims_Person_Skill_Set_Input>;
  pk_columns: Icims_Person_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Person_Skill_ManyArgs = {
  updates: Array<Icims_Person_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Applicant_WorkflowArgs = {
  _inc?: InputMaybe<Icims_Profile_Applicant_Workflow_Inc_Input>;
  _set?: InputMaybe<Icims_Profile_Applicant_Workflow_Set_Input>;
  where: Icims_Profile_Applicant_Workflow_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Applicant_Workflow_By_PkArgs = {
  _inc?: InputMaybe<Icims_Profile_Applicant_Workflow_Inc_Input>;
  _set?: InputMaybe<Icims_Profile_Applicant_Workflow_Set_Input>;
  pk_columns: Icims_Profile_Applicant_Workflow_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Applicant_Workflow_ManyArgs = {
  updates: Array<Icims_Profile_Applicant_Workflow_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_JobArgs = {
  _inc?: InputMaybe<Icims_Profile_Job_Inc_Input>;
  _set?: InputMaybe<Icims_Profile_Job_Set_Input>;
  where: Icims_Profile_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Job_By_PkArgs = {
  _inc?: InputMaybe<Icims_Profile_Job_Inc_Input>;
  _set?: InputMaybe<Icims_Profile_Job_Set_Input>;
  pk_columns: Icims_Profile_Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Job_ManyArgs = {
  updates: Array<Icims_Profile_Job_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_LocationArgs = {
  _inc?: InputMaybe<Icims_Profile_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Profile_Location_Set_Input>;
  where: Icims_Profile_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Location_By_PkArgs = {
  _inc?: InputMaybe<Icims_Profile_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Profile_Location_Set_Input>;
  pk_columns: Icims_Profile_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Location_ManyArgs = {
  updates: Array<Icims_Profile_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_PersonArgs = {
  _inc?: InputMaybe<Icims_Profile_Person_Inc_Input>;
  _set?: InputMaybe<Icims_Profile_Person_Set_Input>;
  where: Icims_Profile_Person_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Person_By_PkArgs = {
  _inc?: InputMaybe<Icims_Profile_Person_Inc_Input>;
  _set?: InputMaybe<Icims_Profile_Person_Set_Input>;
  pk_columns: Icims_Profile_Person_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Person_ManyArgs = {
  updates: Array<Icims_Profile_Person_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Requested_LocationArgs = {
  _set?: InputMaybe<Icims_Profile_Requested_Location_Set_Input>;
  where: Icims_Profile_Requested_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Requested_Location_By_PkArgs = {
  _set?: InputMaybe<Icims_Profile_Requested_Location_Set_Input>;
  pk_columns: Icims_Profile_Requested_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Profile_Requested_Location_ManyArgs = {
  updates: Array<Icims_Profile_Requested_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Requested_LocationArgs = {
  _inc?: InputMaybe<Icims_Requested_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Requested_Location_Set_Input>;
  where: Icims_Requested_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Requested_Location_By_PkArgs = {
  _inc?: InputMaybe<Icims_Requested_Location_Inc_Input>;
  _set?: InputMaybe<Icims_Requested_Location_Set_Input>;
  pk_columns: Icims_Requested_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Requested_Location_ManyArgs = {
  updates: Array<Icims_Requested_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_SchoolArgs = {
  _inc?: InputMaybe<Icims_School_Inc_Input>;
  _set?: InputMaybe<Icims_School_Set_Input>;
  where: Icims_School_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_School_By_PkArgs = {
  _inc?: InputMaybe<Icims_School_Inc_Input>;
  _set?: InputMaybe<Icims_School_Set_Input>;
  pk_columns: Icims_School_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_School_ManyArgs = {
  updates: Array<Icims_School_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_SkillArgs = {
  _inc?: InputMaybe<Icims_Skill_Inc_Input>;
  _set?: InputMaybe<Icims_Skill_Set_Input>;
  where: Icims_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Skill_By_PkArgs = {
  _inc?: InputMaybe<Icims_Skill_Inc_Input>;
  _set?: InputMaybe<Icims_Skill_Set_Input>;
  pk_columns: Icims_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Skill_ManyArgs = {
  updates: Array<Icims_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_StatusArgs = {
  _inc?: InputMaybe<Icims_Status_Inc_Input>;
  _set?: InputMaybe<Icims_Status_Set_Input>;
  where: Icims_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Status_By_PkArgs = {
  _inc?: InputMaybe<Icims_Status_Inc_Input>;
  _set?: InputMaybe<Icims_Status_Set_Input>;
  pk_columns: Icims_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Status_ManyArgs = {
  updates: Array<Icims_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Sync_Status_ChoicesArgs = {
  _set?: InputMaybe<Icims_Sync_Status_Choices_Set_Input>;
  where: Icims_Sync_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Sync_Status_Choices_By_PkArgs = {
  _set?: InputMaybe<Icims_Sync_Status_Choices_Set_Input>;
  pk_columns: Icims_Sync_Status_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Sync_Status_Choices_ManyArgs = {
  updates: Array<Icims_Sync_Status_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Work_ExperienceArgs = {
  _inc?: InputMaybe<Icims_Work_Experience_Inc_Input>;
  _set?: InputMaybe<Icims_Work_Experience_Set_Input>;
  where: Icims_Work_Experience_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Work_Experience_By_PkArgs = {
  _inc?: InputMaybe<Icims_Work_Experience_Inc_Input>;
  _set?: InputMaybe<Icims_Work_Experience_Set_Input>;
  pk_columns: Icims_Work_Experience_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Icims_Work_Experience_ManyArgs = {
  updates: Array<Icims_Work_Experience_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_IndustryArgs = {
  _inc?: InputMaybe<Industry_Inc_Input>;
  _set?: InputMaybe<Industry_Set_Input>;
  where: Industry_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Industry_By_PkArgs = {
  _inc?: InputMaybe<Industry_Inc_Input>;
  _set?: InputMaybe<Industry_Set_Input>;
  pk_columns: Industry_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Industry_ManyArgs = {
  updates: Array<Industry_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Initial_Sell_Type_ChoicesArgs = {
  _set?: InputMaybe<Initial_Sell_Type_Choices_Set_Input>;
  where: Initial_Sell_Type_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Initial_Sell_Type_Choices_By_PkArgs = {
  _set?: InputMaybe<Initial_Sell_Type_Choices_Set_Input>;
  pk_columns: Initial_Sell_Type_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Initial_Sell_Type_Choices_ManyArgs = {
  updates: Array<Initial_Sell_Type_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_DetailArgs = {
  _inc?: InputMaybe<Interview_Detail_Inc_Input>;
  _set?: InputMaybe<Interview_Detail_Set_Input>;
  where: Interview_Detail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Detail_By_PkArgs = {
  _inc?: InputMaybe<Interview_Detail_Inc_Input>;
  _set?: InputMaybe<Interview_Detail_Set_Input>;
  pk_columns: Interview_Detail_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Detail_ManyArgs = {
  updates: Array<Interview_Detail_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_FeedbackArgs = {
  _inc?: InputMaybe<Interview_Feedback_Inc_Input>;
  _set?: InputMaybe<Interview_Feedback_Set_Input>;
  where: Interview_Feedback_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Feedback_By_PkArgs = {
  _inc?: InputMaybe<Interview_Feedback_Inc_Input>;
  _set?: InputMaybe<Interview_Feedback_Set_Input>;
  pk_columns: Interview_Feedback_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Feedback_ManyArgs = {
  updates: Array<Interview_Feedback_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Feedback_Rating_ChoicesArgs = {
  _set?: InputMaybe<Interview_Feedback_Rating_Choices_Set_Input>;
  where: Interview_Feedback_Rating_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Feedback_Rating_Choices_By_PkArgs = {
  _set?: InputMaybe<Interview_Feedback_Rating_Choices_Set_Input>;
  pk_columns: Interview_Feedback_Rating_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Feedback_Rating_Choices_ManyArgs = {
  updates: Array<Interview_Feedback_Rating_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Feedback_RequestArgs = {
  _inc?: InputMaybe<Interview_Feedback_Request_Inc_Input>;
  _set?: InputMaybe<Interview_Feedback_Request_Set_Input>;
  where: Interview_Feedback_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Feedback_Request_By_PkArgs = {
  _inc?: InputMaybe<Interview_Feedback_Request_Inc_Input>;
  _set?: InputMaybe<Interview_Feedback_Request_Set_Input>;
  pk_columns: Interview_Feedback_Request_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Feedback_Request_ManyArgs = {
  updates: Array<Interview_Feedback_Request_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_RequestArgs = {
  _inc?: InputMaybe<Interview_Request_Inc_Input>;
  _set?: InputMaybe<Interview_Request_Set_Input>;
  where: Interview_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Request_By_PkArgs = {
  _inc?: InputMaybe<Interview_Request_Inc_Input>;
  _set?: InputMaybe<Interview_Request_Set_Input>;
  pk_columns: Interview_Request_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Request_ManyArgs = {
  updates: Array<Interview_Request_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Type_ChoicesArgs = {
  _set?: InputMaybe<Interview_Type_Choices_Set_Input>;
  where: Interview_Type_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Type_Choices_By_PkArgs = {
  _set?: InputMaybe<Interview_Type_Choices_Set_Input>;
  pk_columns: Interview_Type_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Type_Choices_ManyArgs = {
  updates: Array<Interview_Type_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_JobArgs = {
  _inc?: InputMaybe<Job_Inc_Input>;
  _set?: InputMaybe<Job_Set_Input>;
  where: Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Acceptable_LocationArgs = {
  _inc?: InputMaybe<Job_Acceptable_Location_Inc_Input>;
  _set?: InputMaybe<Job_Acceptable_Location_Set_Input>;
  where: Job_Acceptable_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Acceptable_Location_By_PkArgs = {
  _inc?: InputMaybe<Job_Acceptable_Location_Inc_Input>;
  _set?: InputMaybe<Job_Acceptable_Location_Set_Input>;
  pk_columns: Job_Acceptable_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Acceptable_Location_ManyArgs = {
  updates: Array<Job_Acceptable_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_By_PkArgs = {
  _inc?: InputMaybe<Job_Inc_Input>;
  _set?: InputMaybe<Job_Set_Input>;
  pk_columns: Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_CodeArgs = {
  _inc?: InputMaybe<Job_Code_Inc_Input>;
  _set?: InputMaybe<Job_Code_Set_Input>;
  where: Job_Code_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Code_By_PkArgs = {
  _inc?: InputMaybe<Job_Code_Inc_Input>;
  _set?: InputMaybe<Job_Code_Set_Input>;
  pk_columns: Job_Code_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Code_ManyArgs = {
  updates: Array<Job_Code_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Level_ChoicesArgs = {
  _set?: InputMaybe<Job_Level_Choices_Set_Input>;
  where: Job_Level_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Level_Choices_By_PkArgs = {
  _set?: InputMaybe<Job_Level_Choices_Set_Input>;
  pk_columns: Job_Level_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Level_Choices_ManyArgs = {
  updates: Array<Job_Level_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_ManyArgs = {
  updates: Array<Job_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Meta_InfoArgs = {
  _inc?: InputMaybe<Job_Meta_Info_Inc_Input>;
  _set?: InputMaybe<Job_Meta_Info_Set_Input>;
  where: Job_Meta_Info_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Meta_Info_By_PkArgs = {
  _inc?: InputMaybe<Job_Meta_Info_Inc_Input>;
  _set?: InputMaybe<Job_Meta_Info_Set_Input>;
  pk_columns: Job_Meta_Info_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Meta_Info_ManyArgs = {
  updates: Array<Job_Meta_Info_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Nice_To_Have_SkillArgs = {
  _inc?: InputMaybe<Job_Nice_To_Have_Skill_Inc_Input>;
  _set?: InputMaybe<Job_Nice_To_Have_Skill_Set_Input>;
  where: Job_Nice_To_Have_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Nice_To_Have_Skill_By_PkArgs = {
  _inc?: InputMaybe<Job_Nice_To_Have_Skill_Inc_Input>;
  _set?: InputMaybe<Job_Nice_To_Have_Skill_Set_Input>;
  pk_columns: Job_Nice_To_Have_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Nice_To_Have_Skill_ManyArgs = {
  updates: Array<Job_Nice_To_Have_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_PositionArgs = {
  _inc?: InputMaybe<Job_Position_Inc_Input>;
  _set?: InputMaybe<Job_Position_Set_Input>;
  where: Job_Position_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Position_By_PkArgs = {
  _inc?: InputMaybe<Job_Position_Inc_Input>;
  _set?: InputMaybe<Job_Position_Set_Input>;
  pk_columns: Job_Position_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Position_ManyArgs = {
  updates: Array<Job_Position_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Priority_ChoicesArgs = {
  _set?: InputMaybe<Job_Priority_Choices_Set_Input>;
  where: Job_Priority_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Priority_Choices_By_PkArgs = {
  _set?: InputMaybe<Job_Priority_Choices_Set_Input>;
  pk_columns: Job_Priority_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Priority_Choices_ManyArgs = {
  updates: Array<Job_Priority_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Required_SkillArgs = {
  _inc?: InputMaybe<Job_Required_Skill_Inc_Input>;
  _set?: InputMaybe<Job_Required_Skill_Set_Input>;
  where: Job_Required_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Required_Skill_By_PkArgs = {
  _inc?: InputMaybe<Job_Required_Skill_Inc_Input>;
  _set?: InputMaybe<Job_Required_Skill_Set_Input>;
  pk_columns: Job_Required_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Required_Skill_ManyArgs = {
  updates: Array<Job_Required_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Role_Source_ChoicesArgs = {
  _set?: InputMaybe<Job_Role_Source_Choices_Set_Input>;
  where: Job_Role_Source_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Role_Source_Choices_By_PkArgs = {
  _set?: InputMaybe<Job_Role_Source_Choices_Set_Input>;
  pk_columns: Job_Role_Source_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Role_Source_Choices_ManyArgs = {
  updates: Array<Job_Role_Source_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Service_TypeArgs = {
  _set?: InputMaybe<Job_Service_Type_Set_Input>;
  where: Job_Service_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Service_Type_By_PkArgs = {
  _set?: InputMaybe<Job_Service_Type_Set_Input>;
  pk_columns: Job_Service_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Service_Type_ManyArgs = {
  updates: Array<Job_Service_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Status_ChoicesArgs = {
  _set?: InputMaybe<Job_Status_Choices_Set_Input>;
  where: Job_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Status_Choices_By_PkArgs = {
  _set?: InputMaybe<Job_Status_Choices_Set_Input>;
  pk_columns: Job_Status_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Status_Choices_ManyArgs = {
  updates: Array<Job_Status_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Status_Choices_OrderArgs = {
  _inc?: InputMaybe<Job_Status_Choices_Order_Inc_Input>;
  _set?: InputMaybe<Job_Status_Choices_Order_Set_Input>;
  where: Job_Status_Choices_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Status_Choices_Order_By_PkArgs = {
  _inc?: InputMaybe<Job_Status_Choices_Order_Inc_Input>;
  _set?: InputMaybe<Job_Status_Choices_Order_Set_Input>;
  pk_columns: Job_Status_Choices_Order_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Status_Choices_Order_ManyArgs = {
  updates: Array<Job_Status_Choices_Order_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Status_LogArgs = {
  _inc?: InputMaybe<Job_Status_Log_Inc_Input>;
  _set?: InputMaybe<Job_Status_Log_Set_Input>;
  where: Job_Status_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Status_Log_By_PkArgs = {
  _inc?: InputMaybe<Job_Status_Log_Inc_Input>;
  _set?: InputMaybe<Job_Status_Log_Set_Input>;
  pk_columns: Job_Status_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Status_Log_ManyArgs = {
  updates: Array<Job_Status_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Tech_StackArgs = {
  _inc?: InputMaybe<Job_Tech_Stack_Inc_Input>;
  _set?: InputMaybe<Job_Tech_Stack_Set_Input>;
  where: Job_Tech_Stack_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Tech_Stack_By_PkArgs = {
  _inc?: InputMaybe<Job_Tech_Stack_Inc_Input>;
  _set?: InputMaybe<Job_Tech_Stack_Set_Input>;
  pk_columns: Job_Tech_Stack_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Tech_Stack_ManyArgs = {
  updates: Array<Job_Tech_Stack_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Linkedin_ContactArgs = {
  _inc?: InputMaybe<Linkedin_Contact_Inc_Input>;
  _set?: InputMaybe<Linkedin_Contact_Set_Input>;
  where: Linkedin_Contact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Linkedin_Contact_By_PkArgs = {
  _inc?: InputMaybe<Linkedin_Contact_Inc_Input>;
  _set?: InputMaybe<Linkedin_Contact_Set_Input>;
  pk_columns: Linkedin_Contact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Linkedin_Contact_ManyArgs = {
  updates: Array<Linkedin_Contact_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LocationArgs = {
  _inc?: InputMaybe<Location_Inc_Input>;
  _set?: InputMaybe<Location_Set_Input>;
  where: Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Location_By_PkArgs = {
  _inc?: InputMaybe<Location_Inc_Input>;
  _set?: InputMaybe<Location_Set_Input>;
  pk_columns: Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Location_ManyArgs = {
  updates: Array<Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Manual_ContactArgs = {
  _inc?: InputMaybe<Manual_Contact_Inc_Input>;
  _set?: InputMaybe<Manual_Contact_Set_Input>;
  where: Manual_Contact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Manual_Contact_By_PkArgs = {
  _inc?: InputMaybe<Manual_Contact_Inc_Input>;
  _set?: InputMaybe<Manual_Contact_Set_Input>;
  pk_columns: Manual_Contact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Manual_Contact_ManyArgs = {
  updates: Array<Manual_Contact_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_ApplicationArgs = {
  _append?: InputMaybe<Merge_Ats_Application_Append_Input>;
  _delete_at_path?: InputMaybe<Merge_Ats_Application_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Merge_Ats_Application_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Merge_Ats_Application_Delete_Key_Input>;
  _prepend?: InputMaybe<Merge_Ats_Application_Prepend_Input>;
  _set?: InputMaybe<Merge_Ats_Application_Set_Input>;
  where: Merge_Ats_Application_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Application_By_PkArgs = {
  _append?: InputMaybe<Merge_Ats_Application_Append_Input>;
  _delete_at_path?: InputMaybe<Merge_Ats_Application_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Merge_Ats_Application_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Merge_Ats_Application_Delete_Key_Input>;
  _prepend?: InputMaybe<Merge_Ats_Application_Prepend_Input>;
  _set?: InputMaybe<Merge_Ats_Application_Set_Input>;
  pk_columns: Merge_Ats_Application_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Application_LogArgs = {
  _inc?: InputMaybe<Merge_Ats_Application_Log_Inc_Input>;
  _set?: InputMaybe<Merge_Ats_Application_Log_Set_Input>;
  where: Merge_Ats_Application_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Application_Log_By_PkArgs = {
  _inc?: InputMaybe<Merge_Ats_Application_Log_Inc_Input>;
  _set?: InputMaybe<Merge_Ats_Application_Log_Set_Input>;
  pk_columns: Merge_Ats_Application_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Application_Log_ManyArgs = {
  updates: Array<Merge_Ats_Application_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Application_ManyArgs = {
  updates: Array<Merge_Ats_Application_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_CandidateArgs = {
  _append?: InputMaybe<Merge_Ats_Candidate_Append_Input>;
  _delete_at_path?: InputMaybe<Merge_Ats_Candidate_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Merge_Ats_Candidate_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Merge_Ats_Candidate_Delete_Key_Input>;
  _prepend?: InputMaybe<Merge_Ats_Candidate_Prepend_Input>;
  _set?: InputMaybe<Merge_Ats_Candidate_Set_Input>;
  where: Merge_Ats_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Candidate_By_PkArgs = {
  _append?: InputMaybe<Merge_Ats_Candidate_Append_Input>;
  _delete_at_path?: InputMaybe<Merge_Ats_Candidate_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Merge_Ats_Candidate_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Merge_Ats_Candidate_Delete_Key_Input>;
  _prepend?: InputMaybe<Merge_Ats_Candidate_Prepend_Input>;
  _set?: InputMaybe<Merge_Ats_Candidate_Set_Input>;
  pk_columns: Merge_Ats_Candidate_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Candidate_ManyArgs = {
  updates: Array<Merge_Ats_Candidate_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_JobArgs = {
  _inc?: InputMaybe<Merge_Ats_Job_Inc_Input>;
  _set?: InputMaybe<Merge_Ats_Job_Set_Input>;
  where: Merge_Ats_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Job_By_PkArgs = {
  _inc?: InputMaybe<Merge_Ats_Job_Inc_Input>;
  _set?: InputMaybe<Merge_Ats_Job_Set_Input>;
  pk_columns: Merge_Ats_Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Job_Interview_StageArgs = {
  _append?: InputMaybe<Merge_Ats_Job_Interview_Stage_Append_Input>;
  _delete_at_path?: InputMaybe<Merge_Ats_Job_Interview_Stage_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Merge_Ats_Job_Interview_Stage_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Merge_Ats_Job_Interview_Stage_Delete_Key_Input>;
  _inc?: InputMaybe<Merge_Ats_Job_Interview_Stage_Inc_Input>;
  _prepend?: InputMaybe<Merge_Ats_Job_Interview_Stage_Prepend_Input>;
  _set?: InputMaybe<Merge_Ats_Job_Interview_Stage_Set_Input>;
  where: Merge_Ats_Job_Interview_Stage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Job_Interview_Stage_By_PkArgs = {
  _append?: InputMaybe<Merge_Ats_Job_Interview_Stage_Append_Input>;
  _delete_at_path?: InputMaybe<Merge_Ats_Job_Interview_Stage_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Merge_Ats_Job_Interview_Stage_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Merge_Ats_Job_Interview_Stage_Delete_Key_Input>;
  _inc?: InputMaybe<Merge_Ats_Job_Interview_Stage_Inc_Input>;
  _prepend?: InputMaybe<Merge_Ats_Job_Interview_Stage_Prepend_Input>;
  _set?: InputMaybe<Merge_Ats_Job_Interview_Stage_Set_Input>;
  pk_columns: Merge_Ats_Job_Interview_Stage_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Job_Interview_Stage_ManyArgs = {
  updates: Array<Merge_Ats_Job_Interview_Stage_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Job_ManyArgs = {
  updates: Array<Merge_Ats_Job_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Linked_AccountArgs = {
  _set?: InputMaybe<Merge_Ats_Linked_Account_Set_Input>;
  where: Merge_Ats_Linked_Account_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Linked_Account_By_PkArgs = {
  _set?: InputMaybe<Merge_Ats_Linked_Account_Set_Input>;
  pk_columns: Merge_Ats_Linked_Account_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Merge_Ats_Linked_Account_ManyArgs = {
  updates: Array<Merge_Ats_Linked_Account_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Mixpanel_ProfilesArgs = {
  _set?: InputMaybe<Mixpanel_Profiles_Set_Input>;
  where: Mixpanel_Profiles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Mixpanel_Profiles_By_PkArgs = {
  _set?: InputMaybe<Mixpanel_Profiles_Set_Input>;
  pk_columns: Mixpanel_Profiles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Mixpanel_Profiles_ManyArgs = {
  updates: Array<Mixpanel_Profiles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Offering_Selection_ChoicesArgs = {
  _set?: InputMaybe<Offering_Selection_Choices_Set_Input>;
  where: Offering_Selection_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Offering_Selection_Choices_By_PkArgs = {
  _set?: InputMaybe<Offering_Selection_Choices_Set_Input>;
  pk_columns: Offering_Selection_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Offering_Selection_Choices_ManyArgs = {
  updates: Array<Offering_Selection_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OrganizationArgs = {
  _append?: InputMaybe<Organization_Append_Input>;
  _delete_at_path?: InputMaybe<Organization_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organization_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organization_Delete_Key_Input>;
  _inc?: InputMaybe<Organization_Inc_Input>;
  _prepend?: InputMaybe<Organization_Prepend_Input>;
  _set?: InputMaybe<Organization_Set_Input>;
  where: Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_By_PkArgs = {
  _append?: InputMaybe<Organization_Append_Input>;
  _delete_at_path?: InputMaybe<Organization_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organization_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organization_Delete_Key_Input>;
  _inc?: InputMaybe<Organization_Inc_Input>;
  _prepend?: InputMaybe<Organization_Prepend_Input>;
  _set?: InputMaybe<Organization_Set_Input>;
  pk_columns: Organization_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_ContactArgs = {
  _inc?: InputMaybe<Organization_Contact_Inc_Input>;
  _set?: InputMaybe<Organization_Contact_Set_Input>;
  where: Organization_Contact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Contact_By_PkArgs = {
  _inc?: InputMaybe<Organization_Contact_Inc_Input>;
  _set?: InputMaybe<Organization_Contact_Set_Input>;
  pk_columns: Organization_Contact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Contact_ManyArgs = {
  updates: Array<Organization_Contact_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_DomainArgs = {
  _inc?: InputMaybe<Organization_Domain_Inc_Input>;
  _set?: InputMaybe<Organization_Domain_Set_Input>;
  where: Organization_Domain_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Domain_By_PkArgs = {
  _inc?: InputMaybe<Organization_Domain_Inc_Input>;
  _set?: InputMaybe<Organization_Domain_Set_Input>;
  pk_columns: Organization_Domain_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Domain_ManyArgs = {
  updates: Array<Organization_Domain_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_EmployeeArgs = {
  _inc?: InputMaybe<Organization_Employee_Inc_Input>;
  _set?: InputMaybe<Organization_Employee_Set_Input>;
  where: Organization_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Employee_By_PkArgs = {
  _inc?: InputMaybe<Organization_Employee_Inc_Input>;
  _set?: InputMaybe<Organization_Employee_Set_Input>;
  pk_columns: Organization_Employee_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Employee_ManyArgs = {
  updates: Array<Organization_Employee_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Icims_Jobs_ResultArgs = {
  _inc?: InputMaybe<Organization_Icims_Jobs_Result_Inc_Input>;
  _set?: InputMaybe<Organization_Icims_Jobs_Result_Set_Input>;
  where: Organization_Icims_Jobs_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Icims_Jobs_Result_ManyArgs = {
  updates: Array<Organization_Icims_Jobs_Result_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_LeadershipArgs = {
  _inc?: InputMaybe<Organization_Leadership_Inc_Input>;
  _set?: InputMaybe<Organization_Leadership_Set_Input>;
  where: Organization_Leadership_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Leadership_By_PkArgs = {
  _inc?: InputMaybe<Organization_Leadership_Inc_Input>;
  _set?: InputMaybe<Organization_Leadership_Set_Input>;
  pk_columns: Organization_Leadership_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Leadership_ManyArgs = {
  updates: Array<Organization_Leadership_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_ManyArgs = {
  updates: Array<Organization_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Type_ChoicesArgs = {
  _set?: InputMaybe<Organization_Type_Choices_Set_Input>;
  where: Organization_Type_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Type_Choices_By_PkArgs = {
  _set?: InputMaybe<Organization_Type_Choices_Set_Input>;
  pk_columns: Organization_Type_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Type_Choices_ManyArgs = {
  updates: Array<Organization_Type_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_VideoArgs = {
  _inc?: InputMaybe<Organization_Video_Inc_Input>;
  _set?: InputMaybe<Organization_Video_Set_Input>;
  where: Organization_Video_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Video_By_PkArgs = {
  _inc?: InputMaybe<Organization_Video_Inc_Input>;
  _set?: InputMaybe<Organization_Video_Set_Input>;
  pk_columns: Organization_Video_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Video_ManyArgs = {
  updates: Array<Organization_Video_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Pillar_TranscriptArgs = {
  _append?: InputMaybe<Pillar_Transcript_Append_Input>;
  _delete_at_path?: InputMaybe<Pillar_Transcript_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pillar_Transcript_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pillar_Transcript_Delete_Key_Input>;
  _inc?: InputMaybe<Pillar_Transcript_Inc_Input>;
  _prepend?: InputMaybe<Pillar_Transcript_Prepend_Input>;
  _set?: InputMaybe<Pillar_Transcript_Set_Input>;
  where: Pillar_Transcript_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Pillar_Transcript_BlobArgs = {
  _append?: InputMaybe<Pillar_Transcript_Blob_Append_Input>;
  _delete_at_path?: InputMaybe<Pillar_Transcript_Blob_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pillar_Transcript_Blob_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pillar_Transcript_Blob_Delete_Key_Input>;
  _prepend?: InputMaybe<Pillar_Transcript_Blob_Prepend_Input>;
  _set?: InputMaybe<Pillar_Transcript_Blob_Set_Input>;
  where: Pillar_Transcript_Blob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Pillar_Transcript_Blob_By_PkArgs = {
  _append?: InputMaybe<Pillar_Transcript_Blob_Append_Input>;
  _delete_at_path?: InputMaybe<Pillar_Transcript_Blob_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pillar_Transcript_Blob_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pillar_Transcript_Blob_Delete_Key_Input>;
  _prepend?: InputMaybe<Pillar_Transcript_Blob_Prepend_Input>;
  _set?: InputMaybe<Pillar_Transcript_Blob_Set_Input>;
  pk_columns: Pillar_Transcript_Blob_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Pillar_Transcript_Blob_ManyArgs = {
  updates: Array<Pillar_Transcript_Blob_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Pillar_Transcript_By_PkArgs = {
  _append?: InputMaybe<Pillar_Transcript_Append_Input>;
  _delete_at_path?: InputMaybe<Pillar_Transcript_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pillar_Transcript_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pillar_Transcript_Delete_Key_Input>;
  _inc?: InputMaybe<Pillar_Transcript_Inc_Input>;
  _prepend?: InputMaybe<Pillar_Transcript_Prepend_Input>;
  _set?: InputMaybe<Pillar_Transcript_Set_Input>;
  pk_columns: Pillar_Transcript_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Pillar_Transcript_ManyArgs = {
  updates: Array<Pillar_Transcript_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Recruiter_Contact_ActionArgs = {
  _inc?: InputMaybe<Recruiter_Contact_Action_Inc_Input>;
  _set?: InputMaybe<Recruiter_Contact_Action_Set_Input>;
  where: Recruiter_Contact_Action_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Recruiter_Contact_Action_By_PkArgs = {
  _inc?: InputMaybe<Recruiter_Contact_Action_Inc_Input>;
  _set?: InputMaybe<Recruiter_Contact_Action_Set_Input>;
  pk_columns: Recruiter_Contact_Action_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Recruiter_Contact_Action_ManyArgs = {
  updates: Array<Recruiter_Contact_Action_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Referral_StatusArgs = {
  _inc?: InputMaybe<Referral_Status_Inc_Input>;
  _set?: InputMaybe<Referral_Status_Set_Input>;
  where: Referral_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Referral_Status_By_PkArgs = {
  _inc?: InputMaybe<Referral_Status_Inc_Input>;
  _set?: InputMaybe<Referral_Status_Set_Input>;
  pk_columns: Referral_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Referral_Status_CurrentArgs = {
  _inc?: InputMaybe<Referral_Status_Current_Inc_Input>;
  _set?: InputMaybe<Referral_Status_Current_Set_Input>;
  where: Referral_Status_Current_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Referral_Status_Current_By_PkArgs = {
  _inc?: InputMaybe<Referral_Status_Current_Inc_Input>;
  _set?: InputMaybe<Referral_Status_Current_Set_Input>;
  pk_columns: Referral_Status_Current_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Referral_Status_Current_ManyArgs = {
  updates: Array<Referral_Status_Current_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Referral_Status_ManyArgs = {
  updates: Array<Referral_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Region_ChoicesArgs = {
  _set?: InputMaybe<Region_Choices_Set_Input>;
  where: Region_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Region_Choices_By_PkArgs = {
  _set?: InputMaybe<Region_Choices_Set_Input>;
  pk_columns: Region_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Region_Choices_ManyArgs = {
  updates: Array<Region_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Reporting_Candidate_MetadataArgs = {
  _inc?: InputMaybe<Reporting_Candidate_Metadata_Inc_Input>;
  _set?: InputMaybe<Reporting_Candidate_Metadata_Set_Input>;
  where: Reporting_Candidate_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reporting_Candidate_Metadata_ManyArgs = {
  updates: Array<Reporting_Candidate_Metadata_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Reporting_Report_Job_MatchesArgs = {
  _inc?: InputMaybe<Reporting_Report_Job_Matches_Inc_Input>;
  _set?: InputMaybe<Reporting_Report_Job_Matches_Set_Input>;
  where: Reporting_Report_Job_Matches_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reporting_Report_Job_Matches_By_PkArgs = {
  _inc?: InputMaybe<Reporting_Report_Job_Matches_Inc_Input>;
  _set?: InputMaybe<Reporting_Report_Job_Matches_Set_Input>;
  pk_columns: Reporting_Report_Job_Matches_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Reporting_Report_Job_Matches_ManyArgs = {
  updates: Array<Reporting_Report_Job_Matches_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _inc?: InputMaybe<Role_Inc_Input>;
  _set?: InputMaybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _inc?: InputMaybe<Role_Inc_Input>;
  _set?: InputMaybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_ChoicesArgs = {
  _set?: InputMaybe<Role_Choices_Set_Input>;
  where: Role_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Choices_By_PkArgs = {
  _set?: InputMaybe<Role_Choices_Set_Input>;
  pk_columns: Role_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Choices_ManyArgs = {
  updates: Array<Role_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Role_ManyArgs = {
  updates: Array<Role_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Salary_InfoArgs = {
  _inc?: InputMaybe<Salary_Info_Inc_Input>;
  _set?: InputMaybe<Salary_Info_Set_Input>;
  where: Salary_Info_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Salary_Info_By_PkArgs = {
  _inc?: InputMaybe<Salary_Info_Inc_Input>;
  _set?: InputMaybe<Salary_Info_Set_Input>;
  pk_columns: Salary_Info_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Salary_Info_ManyArgs = {
  updates: Array<Salary_Info_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Salary_Years_Experience_Range_ChoicesArgs = {
  _set?: InputMaybe<Salary_Years_Experience_Range_Choices_Set_Input>;
  where: Salary_Years_Experience_Range_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Salary_Years_Experience_Range_Choices_By_PkArgs = {
  _set?: InputMaybe<Salary_Years_Experience_Range_Choices_Set_Input>;
  pk_columns: Salary_Years_Experience_Range_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Salary_Years_Experience_Range_Choices_ManyArgs = {
  updates: Array<Salary_Years_Experience_Range_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Salesforce_CustomerArgs = {
  _set?: InputMaybe<Salesforce_Customer_Set_Input>;
  where: Salesforce_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Salesforce_Customer_By_PkArgs = {
  _set?: InputMaybe<Salesforce_Customer_Set_Input>;
  pk_columns: Salesforce_Customer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Salesforce_Customer_LocationArgs = {
  _inc?: InputMaybe<Salesforce_Customer_Location_Inc_Input>;
  _set?: InputMaybe<Salesforce_Customer_Location_Set_Input>;
  where: Salesforce_Customer_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Salesforce_Customer_Location_By_PkArgs = {
  _inc?: InputMaybe<Salesforce_Customer_Location_Inc_Input>;
  _set?: InputMaybe<Salesforce_Customer_Location_Set_Input>;
  pk_columns: Salesforce_Customer_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Salesforce_Customer_Location_ManyArgs = {
  updates: Array<Salesforce_Customer_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Salesforce_Customer_ManyArgs = {
  updates: Array<Salesforce_Customer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Salesforce_LocationArgs = {
  _inc?: InputMaybe<Salesforce_Location_Inc_Input>;
  _set?: InputMaybe<Salesforce_Location_Set_Input>;
  where: Salesforce_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Salesforce_Location_By_PkArgs = {
  _inc?: InputMaybe<Salesforce_Location_Inc_Input>;
  _set?: InputMaybe<Salesforce_Location_Set_Input>;
  pk_columns: Salesforce_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Salesforce_Location_ManyArgs = {
  updates: Array<Salesforce_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Saved_CandidateArgs = {
  _inc?: InputMaybe<Saved_Candidate_Inc_Input>;
  _set?: InputMaybe<Saved_Candidate_Set_Input>;
  where: Saved_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Saved_Candidate_By_PkArgs = {
  _inc?: InputMaybe<Saved_Candidate_Inc_Input>;
  _set?: InputMaybe<Saved_Candidate_Set_Input>;
  pk_columns: Saved_Candidate_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Saved_Candidate_ManyArgs = {
  updates: Array<Saved_Candidate_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Segment_Sendgrid_Prod_ActivityArgs = {
  _inc?: InputMaybe<Segment_Sendgrid_Prod_Activity_Inc_Input>;
  _set?: InputMaybe<Segment_Sendgrid_Prod_Activity_Set_Input>;
  where: Segment_Sendgrid_Prod_Activity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Segment_Sendgrid_Prod_Activity_By_PkArgs = {
  _inc?: InputMaybe<Segment_Sendgrid_Prod_Activity_Inc_Input>;
  _set?: InputMaybe<Segment_Sendgrid_Prod_Activity_Set_Input>;
  pk_columns: Segment_Sendgrid_Prod_Activity_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Segment_Sendgrid_Prod_Activity_ManyArgs = {
  updates: Array<Segment_Sendgrid_Prod_Activity_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Shared_CandidateArgs = {
  _inc?: InputMaybe<Shared_Candidate_Inc_Input>;
  _set?: InputMaybe<Shared_Candidate_Set_Input>;
  where: Shared_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Shared_Candidate_By_PkArgs = {
  _inc?: InputMaybe<Shared_Candidate_Inc_Input>;
  _set?: InputMaybe<Shared_Candidate_Set_Input>;
  pk_columns: Shared_Candidate_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Shared_Candidate_ManyArgs = {
  updates: Array<Shared_Candidate_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SkillArgs = {
  _inc?: InputMaybe<Skill_Inc_Input>;
  _set?: InputMaybe<Skill_Set_Input>;
  where: Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Skill_By_PkArgs = {
  _inc?: InputMaybe<Skill_Inc_Input>;
  _set?: InputMaybe<Skill_Set_Input>;
  pk_columns: Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Skill_FeatureArgs = {
  _inc?: InputMaybe<Skill_Feature_Inc_Input>;
  _set?: InputMaybe<Skill_Feature_Set_Input>;
  where: Skill_Feature_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Skill_Feature_By_PkArgs = {
  _inc?: InputMaybe<Skill_Feature_Inc_Input>;
  _set?: InputMaybe<Skill_Feature_Set_Input>;
  pk_columns: Skill_Feature_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Skill_Feature_ManyArgs = {
  updates: Array<Skill_Feature_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Skill_Icims_SkillArgs = {
  _inc?: InputMaybe<Skill_Icims_Skill_Inc_Input>;
  _set?: InputMaybe<Skill_Icims_Skill_Set_Input>;
  where: Skill_Icims_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Skill_Icims_Skill_By_PkArgs = {
  _inc?: InputMaybe<Skill_Icims_Skill_Inc_Input>;
  _set?: InputMaybe<Skill_Icims_Skill_Set_Input>;
  pk_columns: Skill_Icims_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Skill_Icims_Skill_ManyArgs = {
  updates: Array<Skill_Icims_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Skill_ManyArgs = {
  updates: Array<Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Skill_SynonymArgs = {
  _inc?: InputMaybe<Skill_Synonym_Inc_Input>;
  _set?: InputMaybe<Skill_Synonym_Set_Input>;
  where: Skill_Synonym_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Skill_Synonym_By_PkArgs = {
  _inc?: InputMaybe<Skill_Synonym_Inc_Input>;
  _set?: InputMaybe<Skill_Synonym_Set_Input>;
  pk_columns: Skill_Synonym_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Skill_Synonym_ManyArgs = {
  updates: Array<Skill_Synonym_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_CandidateArgs = {
  _inc?: InputMaybe<Spike_Matching_Candidate_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Candidate_Set_Input>;
  where: Spike_Matching_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Candidate_By_PkArgs = {
  _inc?: InputMaybe<Spike_Matching_Candidate_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Candidate_Set_Input>;
  pk_columns: Spike_Matching_Candidate_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Candidate_ManyArgs = {
  updates: Array<Spike_Matching_Candidate_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Candidate_SkillArgs = {
  _inc?: InputMaybe<Spike_Matching_Candidate_Skill_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Candidate_Skill_Set_Input>;
  where: Spike_Matching_Candidate_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Candidate_Skill_By_PkArgs = {
  _inc?: InputMaybe<Spike_Matching_Candidate_Skill_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Candidate_Skill_Set_Input>;
  pk_columns: Spike_Matching_Candidate_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Candidate_Skill_ManyArgs = {
  updates: Array<Spike_Matching_Candidate_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_JobArgs = {
  _inc?: InputMaybe<Spike_Matching_Job_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Job_Set_Input>;
  where: Spike_Matching_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Job_By_PkArgs = {
  _inc?: InputMaybe<Spike_Matching_Job_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Job_Set_Input>;
  pk_columns: Spike_Matching_Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Job_ManyArgs = {
  updates: Array<Spike_Matching_Job_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Job_SkillArgs = {
  _inc?: InputMaybe<Spike_Matching_Job_Skill_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Job_Skill_Set_Input>;
  where: Spike_Matching_Job_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Job_Skill_By_PkArgs = {
  _inc?: InputMaybe<Spike_Matching_Job_Skill_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Job_Skill_Set_Input>;
  pk_columns: Spike_Matching_Job_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Job_Skill_ManyArgs = {
  updates: Array<Spike_Matching_Job_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_LocationArgs = {
  _inc?: InputMaybe<Spike_Matching_Location_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Location_Set_Input>;
  where: Spike_Matching_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Location_By_PkArgs = {
  _inc?: InputMaybe<Spike_Matching_Location_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Location_Set_Input>;
  pk_columns: Spike_Matching_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Location_JobArgs = {
  _inc?: InputMaybe<Spike_Matching_Location_Job_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Location_Job_Set_Input>;
  where: Spike_Matching_Location_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Location_Job_By_PkArgs = {
  _inc?: InputMaybe<Spike_Matching_Location_Job_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Location_Job_Set_Input>;
  pk_columns: Spike_Matching_Location_Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Location_Job_ManyArgs = {
  updates: Array<Spike_Matching_Location_Job_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Location_ManyArgs = {
  updates: Array<Spike_Matching_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Match_ResultArgs = {
  _inc?: InputMaybe<Spike_Matching_Match_Result_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Match_Result_Set_Input>;
  where: Spike_Matching_Match_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Match_Result_By_PkArgs = {
  _inc?: InputMaybe<Spike_Matching_Match_Result_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Match_Result_Set_Input>;
  pk_columns: Spike_Matching_Match_Result_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Match_Result_ManyArgs = {
  updates: Array<Spike_Matching_Match_Result_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_SkillArgs = {
  _inc?: InputMaybe<Spike_Matching_Skill_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Skill_Set_Input>;
  where: Spike_Matching_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Skill_By_PkArgs = {
  _inc?: InputMaybe<Spike_Matching_Skill_Inc_Input>;
  _set?: InputMaybe<Spike_Matching_Skill_Set_Input>;
  pk_columns: Spike_Matching_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Spike_Matching_Skill_ManyArgs = {
  updates: Array<Spike_Matching_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_Celery_Task_Status_ChoicesArgs = {
  _set?: InputMaybe<Terminal_Celery_Task_Status_Choices_Set_Input>;
  where: Terminal_Celery_Task_Status_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_Celery_Task_Status_Choices_By_PkArgs = {
  _set?: InputMaybe<Terminal_Celery_Task_Status_Choices_Set_Input>;
  pk_columns: Terminal_Celery_Task_Status_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_Celery_Task_Status_Choices_ManyArgs = {
  updates: Array<Terminal_Celery_Task_Status_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_EmployeeArgs = {
  _inc?: InputMaybe<Terminal_Employee_Inc_Input>;
  _set?: InputMaybe<Terminal_Employee_Set_Input>;
  where: Terminal_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_Employee_By_PkArgs = {
  _inc?: InputMaybe<Terminal_Employee_Inc_Input>;
  _set?: InputMaybe<Terminal_Employee_Set_Input>;
  pk_columns: Terminal_Employee_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_Employee_ManyArgs = {
  updates: Array<Terminal_Employee_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_Employee_OrganizationArgs = {
  _inc?: InputMaybe<Terminal_Employee_Organization_Inc_Input>;
  _set?: InputMaybe<Terminal_Employee_Organization_Set_Input>;
  where: Terminal_Employee_Organization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_Employee_Organization_By_PkArgs = {
  _inc?: InputMaybe<Terminal_Employee_Organization_Inc_Input>;
  _set?: InputMaybe<Terminal_Employee_Organization_Set_Input>;
  pk_columns: Terminal_Employee_Organization_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_Employee_Organization_ManyArgs = {
  updates: Array<Terminal_Employee_Organization_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_Employee_Role_ChoicesArgs = {
  _set?: InputMaybe<Terminal_Employee_Role_Choices_Set_Input>;
  where: Terminal_Employee_Role_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_Employee_Role_Choices_By_PkArgs = {
  _set?: InputMaybe<Terminal_Employee_Role_Choices_Set_Input>;
  pk_columns: Terminal_Employee_Role_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Terminal_Employee_Role_Choices_ManyArgs = {
  updates: Array<Terminal_Employee_Role_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TraitArgs = {
  _set?: InputMaybe<Trait_Set_Input>;
  where: Trait_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Trait_By_PkArgs = {
  _set?: InputMaybe<Trait_Set_Input>;
  pk_columns: Trait_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Trait_ManyArgs = {
  updates: Array<Trait_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Transcript_EvaluationArgs = {
  _append?: InputMaybe<Transcript_Evaluation_Append_Input>;
  _delete_at_path?: InputMaybe<Transcript_Evaluation_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transcript_Evaluation_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transcript_Evaluation_Delete_Key_Input>;
  _inc?: InputMaybe<Transcript_Evaluation_Inc_Input>;
  _prepend?: InputMaybe<Transcript_Evaluation_Prepend_Input>;
  _set?: InputMaybe<Transcript_Evaluation_Set_Input>;
  where: Transcript_Evaluation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Transcript_Evaluation_By_PkArgs = {
  _append?: InputMaybe<Transcript_Evaluation_Append_Input>;
  _delete_at_path?: InputMaybe<Transcript_Evaluation_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transcript_Evaluation_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transcript_Evaluation_Delete_Key_Input>;
  _inc?: InputMaybe<Transcript_Evaluation_Inc_Input>;
  _prepend?: InputMaybe<Transcript_Evaluation_Prepend_Input>;
  _set?: InputMaybe<Transcript_Evaluation_Set_Input>;
  pk_columns: Transcript_Evaluation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Transcript_Evaluation_ManyArgs = {
  updates: Array<Transcript_Evaluation_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Transcript_SkillArgs = {
  _inc?: InputMaybe<Transcript_Skill_Inc_Input>;
  _set?: InputMaybe<Transcript_Skill_Set_Input>;
  where: Transcript_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Transcript_Skill_By_PkArgs = {
  _inc?: InputMaybe<Transcript_Skill_Inc_Input>;
  _set?: InputMaybe<Transcript_Skill_Set_Input>;
  pk_columns: Transcript_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Transcript_Skill_ManyArgs = {
  updates: Array<Transcript_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Transcript_Work_ExperienceArgs = {
  _inc?: InputMaybe<Transcript_Work_Experience_Inc_Input>;
  _set?: InputMaybe<Transcript_Work_Experience_Set_Input>;
  where: Transcript_Work_Experience_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Transcript_Work_Experience_By_PkArgs = {
  _inc?: InputMaybe<Transcript_Work_Experience_Inc_Input>;
  _set?: InputMaybe<Transcript_Work_Experience_Set_Input>;
  pk_columns: Transcript_Work_Experience_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Transcript_Work_Experience_ManyArgs = {
  updates: Array<Transcript_Work_Experience_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_InfoArgs = {
  _inc?: InputMaybe<User_Info_Inc_Input>;
  _set?: InputMaybe<User_Info_Set_Input>;
  where: User_Info_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Info_By_PkArgs = {
  _inc?: InputMaybe<User_Info_Inc_Input>;
  _set?: InputMaybe<User_Info_Set_Input>;
  pk_columns: User_Info_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Info_ManyArgs = {
  updates: Array<User_Info_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_InvitationArgs = {
  _inc?: InputMaybe<User_Invitation_Inc_Input>;
  _set?: InputMaybe<User_Invitation_Set_Input>;
  where: User_Invitation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Invitation_By_PkArgs = {
  _inc?: InputMaybe<User_Invitation_Inc_Input>;
  _set?: InputMaybe<User_Invitation_Set_Input>;
  pk_columns: User_Invitation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Invitation_ManyArgs = {
  updates: Array<User_Invitation_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Linkedin_ContactArgs = {
  _inc?: InputMaybe<User_Linkedin_Contact_Inc_Input>;
  _set?: InputMaybe<User_Linkedin_Contact_Set_Input>;
  where: User_Linkedin_Contact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Linkedin_Contact_By_PkArgs = {
  _inc?: InputMaybe<User_Linkedin_Contact_Inc_Input>;
  _set?: InputMaybe<User_Linkedin_Contact_Set_Input>;
  pk_columns: User_Linkedin_Contact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Linkedin_Contact_ManyArgs = {
  updates: Array<User_Linkedin_Contact_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Password_BlacklistArgs = {
  _set?: InputMaybe<User_Password_Blacklist_Set_Input>;
  where: User_Password_Blacklist_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Password_Blacklist_By_PkArgs = {
  _set?: InputMaybe<User_Password_Blacklist_Set_Input>;
  pk_columns: User_Password_Blacklist_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Password_Blacklist_ManyArgs = {
  updates: Array<User_Password_Blacklist_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_RoleArgs = {
  _inc?: InputMaybe<User_Role_Inc_Input>;
  _set?: InputMaybe<User_Role_Set_Input>;
  where: User_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Role_By_PkArgs = {
  _inc?: InputMaybe<User_Role_Inc_Input>;
  _set?: InputMaybe<User_Role_Set_Input>;
  pk_columns: User_Role_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Role_ManyArgs = {
  updates: Array<User_Role_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_SubscriptionArgs = {
  _inc?: InputMaybe<User_Subscription_Inc_Input>;
  _set?: InputMaybe<User_Subscription_Set_Input>;
  where: User_Subscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_By_PkArgs = {
  _inc?: InputMaybe<User_Subscription_Inc_Input>;
  _set?: InputMaybe<User_Subscription_Set_Input>;
  pk_columns: User_Subscription_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_ChoicesArgs = {
  _set?: InputMaybe<User_Subscription_Choices_Set_Input>;
  where: User_Subscription_Choices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_Choices_By_PkArgs = {
  _set?: InputMaybe<User_Subscription_Choices_Set_Input>;
  pk_columns: User_Subscription_Choices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_Choices_ManyArgs = {
  updates: Array<User_Subscription_Choices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_ManyArgs = {
  updates: Array<User_Subscription_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Temp_BlobArgs = {
  _append?: InputMaybe<User_Temp_Blob_Append_Input>;
  _delete_at_path?: InputMaybe<User_Temp_Blob_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Temp_Blob_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Temp_Blob_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Temp_Blob_Prepend_Input>;
  _set?: InputMaybe<User_Temp_Blob_Set_Input>;
  where: User_Temp_Blob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Temp_Blob_By_PkArgs = {
  _append?: InputMaybe<User_Temp_Blob_Append_Input>;
  _delete_at_path?: InputMaybe<User_Temp_Blob_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Temp_Blob_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Temp_Blob_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Temp_Blob_Prepend_Input>;
  _set?: InputMaybe<User_Temp_Blob_Set_Input>;
  pk_columns: User_Temp_Blob_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Temp_Blob_ManyArgs = {
  updates: Array<User_Temp_Blob_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ValidatorArgs = {
  _set?: InputMaybe<Validator_Set_Input>;
  where: Validator_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Validator_By_PkArgs = {
  _set?: InputMaybe<Validator_Set_Input>;
  pk_columns: Validator_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Validator_ManyArgs = {
  updates: Array<Validator_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "offering_selection_choices" */
export type Offering_Selection_Choices = {
  __typename?: 'offering_selection_choices';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "offering_selection_choices" */
export type Offering_Selection_Choices_Aggregate = {
  __typename?: 'offering_selection_choices_aggregate';
  aggregate: Maybe<Offering_Selection_Choices_Aggregate_Fields>;
  nodes: Array<Offering_Selection_Choices>;
};

/** aggregate fields of "offering_selection_choices" */
export type Offering_Selection_Choices_Aggregate_Fields = {
  __typename?: 'offering_selection_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Offering_Selection_Choices_Max_Fields>;
  min: Maybe<Offering_Selection_Choices_Min_Fields>;
};

/** aggregate fields of "offering_selection_choices" */
export type Offering_Selection_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Offering_Selection_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "offering_selection_choices". All fields are combined with a logical 'AND'. */
export type Offering_Selection_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Offering_Selection_Choices_Bool_Exp>>;
  _not?: InputMaybe<Offering_Selection_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Offering_Selection_Choices_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "offering_selection_choices" */
export enum Offering_Selection_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CustomerOfferingSelectionChoicesPkey = 'customer_offering_selection_choices_pkey',
}

export enum Offering_Selection_Choices_Enum {
  /** Contractor */
  Contractor = 'CONTRACTOR',
  /** Employ Only */
  EmployOnly = 'EMPLOY_ONLY',
  /** Remote Management Platform */
  RmpOnly = 'RMP_ONLY',
  /** Talent and Remote Management Platforms */
  TpAndRmp = 'TP_AND_RMP',
  /** Talent Platform Only */
  TpOnly = 'TP_ONLY',
  /** Talent Platform Self Serve */
  TpSelfServe = 'TP_SELF_SERVE',
}

/** Boolean expression to compare columns of type "offering_selection_choices_enum". All fields are combined with logical 'AND'. */
export type Offering_Selection_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Offering_Selection_Choices_Enum>;
  _in?: InputMaybe<Array<Offering_Selection_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Offering_Selection_Choices_Enum>;
  _nin?: InputMaybe<Array<Offering_Selection_Choices_Enum>>;
};

/** input type for inserting data into table "offering_selection_choices" */
export type Offering_Selection_Choices_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Offering_Selection_Choices_Max_Fields = {
  __typename?: 'offering_selection_choices_max_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Offering_Selection_Choices_Min_Fields = {
  __typename?: 'offering_selection_choices_min_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "offering_selection_choices" */
export type Offering_Selection_Choices_Mutation_Response = {
  __typename?: 'offering_selection_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Offering_Selection_Choices>;
};

/** on_conflict condition type for table "offering_selection_choices" */
export type Offering_Selection_Choices_On_Conflict = {
  constraint: Offering_Selection_Choices_Constraint;
  update_columns?: Array<Offering_Selection_Choices_Update_Column>;
  where?: InputMaybe<Offering_Selection_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "offering_selection_choices". */
export type Offering_Selection_Choices_Order_By = {
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: offering_selection_choices */
export type Offering_Selection_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "offering_selection_choices" */
export enum Offering_Selection_Choices_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "offering_selection_choices" */
export type Offering_Selection_Choices_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "offering_selection_choices" */
export type Offering_Selection_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Offering_Selection_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Offering_Selection_Choices_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "offering_selection_choices" */
export enum Offering_Selection_Choices_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

export type Offering_Selection_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Offering_Selection_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Offering_Selection_Choices_Bool_Exp;
};

/** Boolean expression to compare columns of type "oid". All fields are combined with logical 'AND'. */
export type Oid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['oid']>;
  _gt?: InputMaybe<Scalars['oid']>;
  _gte?: InputMaybe<Scalars['oid']>;
  _in?: InputMaybe<Array<Scalars['oid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['oid']>;
  _lte?: InputMaybe<Scalars['oid']>;
  _neq?: InputMaybe<Scalars['oid']>;
  _nin?: InputMaybe<Array<Scalars['oid']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "organization" */
export type Organization = {
  __typename?: 'organization';
  about: Maybe<Scalars['String']>;
  company_size: Maybe<Company_Size_Choices_Enum>;
  culture_values: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  /** An object relationship */
  currency_exchange_rate: Currency_Exchange_Rate;
  customer_offering_selection: Maybe<Offering_Selection_Choices_Enum>;
  /** An object relationship */
  dayforce_customer: Maybe<Dayforce_Customer>;
  dayforce_customer_id: Maybe<Scalars['Int']>;
  /** An array relationship */
  domains: Array<Organization_Domain>;
  /** An aggregate relationship */
  domains_aggregate: Organization_Domain_Aggregate;
  hq_city: Maybe<Scalars['String']>;
  hq_country: Maybe<Scalars['String']>;
  hq_state: Maybe<Scalars['String']>;
  /** An object relationship */
  icims_company: Maybe<Icims_Company>;
  icims_company_profile_id: Maybe<Scalars['Int']>;
  icims_initial_data_transfer: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  initial_sell_type: Maybe<Scalars['String']>;
  /** An array relationship */
  jobs: Array<Job>;
  /** An aggregate relationship */
  jobs_aggregate: Job_Aggregate;
  last_funding_date: Maybe<Scalars['date']>;
  linkedin_url: Maybe<Scalars['String']>;
  logo: Maybe<Scalars['String']>;
  logo_sq: Maybe<Scalars['String']>;
  name: Scalars['String'];
  number_of_funding_rounds: Maybe<Scalars['Int']>;
  /** An array relationship */
  organization_contacts: Array<Organization_Contact>;
  /** An aggregate relationship */
  organization_contacts_aggregate: Organization_Contact_Aggregate;
  /** An array relationship */
  organization_leaderships: Array<Organization_Leadership>;
  /** An aggregate relationship */
  organization_leaderships_aggregate: Organization_Leadership_Aggregate;
  /** An array relationship */
  organization_videos: Array<Organization_Video>;
  /** An aggregate relationship */
  organization_videos_aggregate: Organization_Video_Aggregate;
  pdl_blob: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  salesforce_customer: Maybe<Salesforce_Customer>;
  salesforce_customer_id: Maybe<Scalars['Int']>;
  salesforce_id: Maybe<Scalars['String']>;
  slack_channel: Maybe<Scalars['String']>;
  sync_status: Maybe<Icims_Sync_Status_Choices_Enum>;
  /** An array relationship */
  terminal_employee_organizations: Array<Terminal_Employee_Organization>;
  /** An aggregate relationship */
  terminal_employee_organizations_aggregate: Terminal_Employee_Organization_Aggregate;
  total_funds_raised: Maybe<Scalars['numeric']>;
  type: Organization_Type_Choices_Enum;
  /** An array relationship */
  user_infos: Array<User_Info>;
  /** An aggregate relationship */
  user_infos_aggregate: User_Info_Aggregate;
  website_url: Maybe<Scalars['String']>;
  year_company_founded: Maybe<Scalars['Int']>;
};

/** columns and relationships of "organization" */
export type OrganizationDomainsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Domain_Order_By>>;
  where?: InputMaybe<Organization_Domain_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationDomains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Domain_Order_By>>;
  where?: InputMaybe<Organization_Domain_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationJobsArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganization_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Contact_Order_By>>;
  where?: InputMaybe<Organization_Contact_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganization_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Contact_Order_By>>;
  where?: InputMaybe<Organization_Contact_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganization_LeadershipsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leadership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leadership_Order_By>>;
  where?: InputMaybe<Organization_Leadership_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganization_Leaderships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leadership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leadership_Order_By>>;
  where?: InputMaybe<Organization_Leadership_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganization_VideosArgs = {
  distinct_on?: InputMaybe<Array<Organization_Video_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Video_Order_By>>;
  where?: InputMaybe<Organization_Video_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationOrganization_Videos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Video_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Video_Order_By>>;
  where?: InputMaybe<Organization_Video_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationPdl_BlobArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "organization" */
export type OrganizationTerminal_Employee_OrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Organization_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationTerminal_Employee_Organizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Organization_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationUser_InfosArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

/** columns and relationships of "organization" */
export type OrganizationUser_Infos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

/** aggregated selection of "organization" */
export type Organization_Aggregate = {
  __typename?: 'organization_aggregate';
  aggregate: Maybe<Organization_Aggregate_Fields>;
  nodes: Array<Organization>;
};

export type Organization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Aggregate_Bool_Exp_Count>;
};

export type Organization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization" */
export type Organization_Aggregate_Fields = {
  __typename?: 'organization_aggregate_fields';
  avg: Maybe<Organization_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Organization_Max_Fields>;
  min: Maybe<Organization_Min_Fields>;
  stddev: Maybe<Organization_Stddev_Fields>;
  stddev_pop: Maybe<Organization_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Organization_Stddev_Samp_Fields>;
  sum: Maybe<Organization_Sum_Fields>;
  var_pop: Maybe<Organization_Var_Pop_Fields>;
  var_samp: Maybe<Organization_Var_Samp_Fields>;
  variance: Maybe<Organization_Variance_Fields>;
};

/** aggregate fields of "organization" */
export type Organization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization" */
export type Organization_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Max_Order_By>;
  min?: InputMaybe<Organization_Min_Order_By>;
  stddev?: InputMaybe<Organization_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Organization_Append_Input = {
  pdl_blob?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "organization" */
export type Organization_Arr_Rel_Insert_Input = {
  data: Array<Organization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_Avg_Fields = {
  __typename?: 'organization_avg_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  icims_company_profile_id: Maybe<Scalars['Float']>;
  icims_initial_data_transfer: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  number_of_funding_rounds: Maybe<Scalars['Float']>;
  salesforce_customer_id: Maybe<Scalars['Float']>;
  total_funds_raised: Maybe<Scalars['Float']>;
  year_company_founded: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization" */
export type Organization_Avg_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  icims_company_profile_id?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_funding_rounds?: InputMaybe<Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  total_funds_raised?: InputMaybe<Order_By>;
  year_company_founded?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization". All fields are combined with a logical 'AND'. */
export type Organization_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Bool_Exp>>;
  _not?: InputMaybe<Organization_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Bool_Exp>>;
  about?: InputMaybe<String_Comparison_Exp>;
  company_size?: InputMaybe<Company_Size_Choices_Enum_Comparison_Exp>;
  culture_values?: InputMaybe<String_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  currency_exchange_rate?: InputMaybe<Currency_Exchange_Rate_Bool_Exp>;
  customer_offering_selection?: InputMaybe<Offering_Selection_Choices_Enum_Comparison_Exp>;
  dayforce_customer?: InputMaybe<Dayforce_Customer_Bool_Exp>;
  dayforce_customer_id?: InputMaybe<Int_Comparison_Exp>;
  domains?: InputMaybe<Organization_Domain_Bool_Exp>;
  domains_aggregate?: InputMaybe<Organization_Domain_Aggregate_Bool_Exp>;
  hq_city?: InputMaybe<String_Comparison_Exp>;
  hq_country?: InputMaybe<String_Comparison_Exp>;
  hq_state?: InputMaybe<String_Comparison_Exp>;
  icims_company?: InputMaybe<Icims_Company_Bool_Exp>;
  icims_company_profile_id?: InputMaybe<Int_Comparison_Exp>;
  icims_initial_data_transfer?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  initial_sell_type?: InputMaybe<String_Comparison_Exp>;
  jobs?: InputMaybe<Job_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Bool_Exp>;
  last_funding_date?: InputMaybe<Date_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  logo_sq?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  number_of_funding_rounds?: InputMaybe<Int_Comparison_Exp>;
  organization_contacts?: InputMaybe<Organization_Contact_Bool_Exp>;
  organization_contacts_aggregate?: InputMaybe<Organization_Contact_Aggregate_Bool_Exp>;
  organization_leaderships?: InputMaybe<Organization_Leadership_Bool_Exp>;
  organization_leaderships_aggregate?: InputMaybe<Organization_Leadership_Aggregate_Bool_Exp>;
  organization_videos?: InputMaybe<Organization_Video_Bool_Exp>;
  organization_videos_aggregate?: InputMaybe<Organization_Video_Aggregate_Bool_Exp>;
  pdl_blob?: InputMaybe<Jsonb_Comparison_Exp>;
  salesforce_customer?: InputMaybe<Salesforce_Customer_Bool_Exp>;
  salesforce_customer_id?: InputMaybe<Int_Comparison_Exp>;
  salesforce_id?: InputMaybe<String_Comparison_Exp>;
  slack_channel?: InputMaybe<String_Comparison_Exp>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum_Comparison_Exp>;
  terminal_employee_organizations?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
  terminal_employee_organizations_aggregate?: InputMaybe<Terminal_Employee_Organization_Aggregate_Bool_Exp>;
  total_funds_raised?: InputMaybe<Numeric_Comparison_Exp>;
  type?: InputMaybe<Organization_Type_Choices_Enum_Comparison_Exp>;
  user_infos?: InputMaybe<User_Info_Bool_Exp>;
  user_infos_aggregate?: InputMaybe<User_Info_Aggregate_Bool_Exp>;
  website_url?: InputMaybe<String_Comparison_Exp>;
  year_company_founded?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization" */
export enum Organization_Constraint {
  /** unique or primary key constraint on columns "dayforce_customer_id" */
  OrganizationDayforceCustomerIdKey = 'organization_dayforce_customer_id_key',
  /** unique or primary key constraint on columns "icims_company_profile_id" */
  OrganizationIcimsCompanyProfileIdKey = 'organization_icims_company_profile_id_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationPkey = 'organization_pkey',
  /** unique or primary key constraint on columns "salesforce_customer_id" */
  OrganizationSalesforceCustomerIdKey = 'organization_salesforce_customer_id_key',
  /** unique or primary key constraint on columns "salesforce_id" */
  OrganizationSalesforceIdKey = 'organization_salesforce_id_key',
}

/** Table to store organization contacts from Salesforce */
export type Organization_Contact = {
  __typename?: 'organization_contact';
  email: Scalars['citext'];
  id: Scalars['Int'];
  is_invited: Scalars['Boolean'];
  name: Scalars['String'];
  organization_id: Scalars['Int'];
};

/** aggregated selection of "organization_contact" */
export type Organization_Contact_Aggregate = {
  __typename?: 'organization_contact_aggregate';
  aggregate: Maybe<Organization_Contact_Aggregate_Fields>;
  nodes: Array<Organization_Contact>;
};

export type Organization_Contact_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Organization_Contact_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organization_Contact_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Organization_Contact_Aggregate_Bool_Exp_Count>;
};

export type Organization_Contact_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organization_Contact_Select_Column_Organization_Contact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Contact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_Contact_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organization_Contact_Select_Column_Organization_Contact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Contact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_Contact_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Contact_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_contact" */
export type Organization_Contact_Aggregate_Fields = {
  __typename?: 'organization_contact_aggregate_fields';
  avg: Maybe<Organization_Contact_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Organization_Contact_Max_Fields>;
  min: Maybe<Organization_Contact_Min_Fields>;
  stddev: Maybe<Organization_Contact_Stddev_Fields>;
  stddev_pop: Maybe<Organization_Contact_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Organization_Contact_Stddev_Samp_Fields>;
  sum: Maybe<Organization_Contact_Sum_Fields>;
  var_pop: Maybe<Organization_Contact_Var_Pop_Fields>;
  var_samp: Maybe<Organization_Contact_Var_Samp_Fields>;
  variance: Maybe<Organization_Contact_Variance_Fields>;
};

/** aggregate fields of "organization_contact" */
export type Organization_Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_contact" */
export type Organization_Contact_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_Contact_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Contact_Max_Order_By>;
  min?: InputMaybe<Organization_Contact_Min_Order_By>;
  stddev?: InputMaybe<Organization_Contact_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_Contact_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_Contact_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_Contact_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_Contact_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_Contact_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_Contact_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organization_contact" */
export type Organization_Contact_Arr_Rel_Insert_Input = {
  data: Array<Organization_Contact_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Contact_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_Contact_Avg_Fields = {
  __typename?: 'organization_contact_avg_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization_contact" */
export type Organization_Contact_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization_contact". All fields are combined with a logical 'AND'. */
export type Organization_Contact_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Contact_Bool_Exp>>;
  _not?: InputMaybe<Organization_Contact_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Contact_Bool_Exp>>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_invited?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_contact" */
export enum Organization_Contact_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationContactPkey = 'organization_contact_pkey',
}

/** input type for incrementing numeric columns in table "organization_contact" */
export type Organization_Contact_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organization_contact" */
export type Organization_Contact_Insert_Input = {
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  is_invited?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Organization_Contact_Max_Fields = {
  __typename?: 'organization_contact_max_fields';
  email: Maybe<Scalars['citext']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  organization_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "organization_contact" */
export type Organization_Contact_Max_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Contact_Min_Fields = {
  __typename?: 'organization_contact_min_fields';
  email: Maybe<Scalars['citext']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  organization_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "organization_contact" */
export type Organization_Contact_Min_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_contact" */
export type Organization_Contact_Mutation_Response = {
  __typename?: 'organization_contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Contact>;
};

/** on_conflict condition type for table "organization_contact" */
export type Organization_Contact_On_Conflict = {
  constraint: Organization_Contact_Constraint;
  update_columns?: Array<Organization_Contact_Update_Column>;
  where?: InputMaybe<Organization_Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_contact". */
export type Organization_Contact_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_invited?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_contact */
export type Organization_Contact_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "organization_contact" */
export enum Organization_Contact_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsInvited = 'is_invited',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
}

/** select "organization_contact_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organization_contact" */
export enum Organization_Contact_Select_Column_Organization_Contact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsInvited = 'is_invited',
}

/** select "organization_contact_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organization_contact" */
export enum Organization_Contact_Select_Column_Organization_Contact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsInvited = 'is_invited',
}

/** input type for updating data in table "organization_contact" */
export type Organization_Contact_Set_Input = {
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  is_invited?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Organization_Contact_Stddev_Fields = {
  __typename?: 'organization_contact_stddev_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization_contact" */
export type Organization_Contact_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_Contact_Stddev_Pop_Fields = {
  __typename?: 'organization_contact_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization_contact" */
export type Organization_Contact_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_Contact_Stddev_Samp_Fields = {
  __typename?: 'organization_contact_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization_contact" */
export type Organization_Contact_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization_contact" */
export type Organization_Contact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Contact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Contact_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  is_invited?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Organization_Contact_Sum_Fields = {
  __typename?: 'organization_contact_sum_fields';
  id: Maybe<Scalars['Int']>;
  organization_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organization_contact" */
export type Organization_Contact_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** update columns of table "organization_contact" */
export enum Organization_Contact_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsInvited = 'is_invited',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
}

export type Organization_Contact_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Contact_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Contact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Contact_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Contact_Var_Pop_Fields = {
  __typename?: 'organization_contact_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization_contact" */
export type Organization_Contact_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_Contact_Var_Samp_Fields = {
  __typename?: 'organization_contact_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization_contact" */
export type Organization_Contact_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_Contact_Variance_Fields = {
  __typename?: 'organization_contact_variance_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization_contact" */
export type Organization_Contact_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Organization_Delete_At_Path_Input = {
  pdl_blob?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Organization_Delete_Elem_Input = {
  pdl_blob?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Organization_Delete_Key_Input = {
  pdl_blob?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "organization_domain" */
export type Organization_Domain = {
  __typename?: 'organization_domain';
  domain: Scalars['String'];
  id: Scalars['Int'];
  organization: Scalars['Int'];
};

/** aggregated selection of "organization_domain" */
export type Organization_Domain_Aggregate = {
  __typename?: 'organization_domain_aggregate';
  aggregate: Maybe<Organization_Domain_Aggregate_Fields>;
  nodes: Array<Organization_Domain>;
};

export type Organization_Domain_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Domain_Aggregate_Bool_Exp_Count>;
};

export type Organization_Domain_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Domain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Domain_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_domain" */
export type Organization_Domain_Aggregate_Fields = {
  __typename?: 'organization_domain_aggregate_fields';
  avg: Maybe<Organization_Domain_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Organization_Domain_Max_Fields>;
  min: Maybe<Organization_Domain_Min_Fields>;
  stddev: Maybe<Organization_Domain_Stddev_Fields>;
  stddev_pop: Maybe<Organization_Domain_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Organization_Domain_Stddev_Samp_Fields>;
  sum: Maybe<Organization_Domain_Sum_Fields>;
  var_pop: Maybe<Organization_Domain_Var_Pop_Fields>;
  var_samp: Maybe<Organization_Domain_Var_Samp_Fields>;
  variance: Maybe<Organization_Domain_Variance_Fields>;
};

/** aggregate fields of "organization_domain" */
export type Organization_Domain_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Domain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_domain" */
export type Organization_Domain_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_Domain_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Domain_Max_Order_By>;
  min?: InputMaybe<Organization_Domain_Min_Order_By>;
  stddev?: InputMaybe<Organization_Domain_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_Domain_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_Domain_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_Domain_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_Domain_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_Domain_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_Domain_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organization_domain" */
export type Organization_Domain_Arr_Rel_Insert_Input = {
  data: Array<Organization_Domain_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Domain_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_Domain_Avg_Fields = {
  __typename?: 'organization_domain_avg_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization_domain" */
export type Organization_Domain_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization_domain". All fields are combined with a logical 'AND'. */
export type Organization_Domain_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Domain_Bool_Exp>>;
  _not?: InputMaybe<Organization_Domain_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Domain_Bool_Exp>>;
  domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  organization?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_domain" */
export enum Organization_Domain_Constraint {
  /** unique or primary key constraint on columns "domain", "organization" */
  OrganizationDomainOrganizationDomainKey = 'organization_domain_organization_domain_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationDomainPkey = 'organization_domain_pkey',
}

/** input type for incrementing numeric columns in table "organization_domain" */
export type Organization_Domain_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organization_domain" */
export type Organization_Domain_Insert_Input = {
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Organization_Domain_Max_Fields = {
  __typename?: 'organization_domain_max_fields';
  domain: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  organization: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "organization_domain" */
export type Organization_Domain_Max_Order_By = {
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Domain_Min_Fields = {
  __typename?: 'organization_domain_min_fields';
  domain: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  organization: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "organization_domain" */
export type Organization_Domain_Min_Order_By = {
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_domain" */
export type Organization_Domain_Mutation_Response = {
  __typename?: 'organization_domain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Domain>;
};

/** on_conflict condition type for table "organization_domain" */
export type Organization_Domain_On_Conflict = {
  constraint: Organization_Domain_Constraint;
  update_columns?: Array<Organization_Domain_Update_Column>;
  where?: InputMaybe<Organization_Domain_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_domain". */
export type Organization_Domain_Order_By = {
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_domain */
export type Organization_Domain_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "organization_domain" */
export enum Organization_Domain_Select_Column {
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Organization = 'organization',
}

/** input type for updating data in table "organization_domain" */
export type Organization_Domain_Set_Input = {
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Organization_Domain_Stddev_Fields = {
  __typename?: 'organization_domain_stddev_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization_domain" */
export type Organization_Domain_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_Domain_Stddev_Pop_Fields = {
  __typename?: 'organization_domain_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization_domain" */
export type Organization_Domain_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_Domain_Stddev_Samp_Fields = {
  __typename?: 'organization_domain_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization_domain" */
export type Organization_Domain_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization_domain" */
export type Organization_Domain_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Domain_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Domain_Stream_Cursor_Value_Input = {
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Organization_Domain_Sum_Fields = {
  __typename?: 'organization_domain_sum_fields';
  id: Maybe<Scalars['Int']>;
  organization: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organization_domain" */
export type Organization_Domain_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** update columns of table "organization_domain" */
export enum Organization_Domain_Update_Column {
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Organization = 'organization',
}

export type Organization_Domain_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Domain_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Domain_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Domain_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Domain_Var_Pop_Fields = {
  __typename?: 'organization_domain_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization_domain" */
export type Organization_Domain_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_Domain_Var_Samp_Fields = {
  __typename?: 'organization_domain_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization_domain" */
export type Organization_Domain_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_Domain_Variance_Fields = {
  __typename?: 'organization_domain_variance_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization_domain" */
export type Organization_Domain_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** columns and relationships of "organization_employee" */
export type Organization_Employee = {
  __typename?: 'organization_employee';
  id: Scalars['Int'];
  linkedin_url: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  organization_leaderships: Array<Organization_Leadership>;
  /** An aggregate relationship */
  organization_leaderships_aggregate: Organization_Leadership_Aggregate;
  title: Maybe<Scalars['String']>;
};

/** columns and relationships of "organization_employee" */
export type Organization_EmployeeOrganization_LeadershipsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leadership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leadership_Order_By>>;
  where?: InputMaybe<Organization_Leadership_Bool_Exp>;
};

/** columns and relationships of "organization_employee" */
export type Organization_EmployeeOrganization_Leaderships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leadership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leadership_Order_By>>;
  where?: InputMaybe<Organization_Leadership_Bool_Exp>;
};

/** aggregated selection of "organization_employee" */
export type Organization_Employee_Aggregate = {
  __typename?: 'organization_employee_aggregate';
  aggregate: Maybe<Organization_Employee_Aggregate_Fields>;
  nodes: Array<Organization_Employee>;
};

/** aggregate fields of "organization_employee" */
export type Organization_Employee_Aggregate_Fields = {
  __typename?: 'organization_employee_aggregate_fields';
  avg: Maybe<Organization_Employee_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Organization_Employee_Max_Fields>;
  min: Maybe<Organization_Employee_Min_Fields>;
  stddev: Maybe<Organization_Employee_Stddev_Fields>;
  stddev_pop: Maybe<Organization_Employee_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Organization_Employee_Stddev_Samp_Fields>;
  sum: Maybe<Organization_Employee_Sum_Fields>;
  var_pop: Maybe<Organization_Employee_Var_Pop_Fields>;
  var_samp: Maybe<Organization_Employee_Var_Samp_Fields>;
  variance: Maybe<Organization_Employee_Variance_Fields>;
};

/** aggregate fields of "organization_employee" */
export type Organization_Employee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Organization_Employee_Avg_Fields = {
  __typename?: 'organization_employee_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "organization_employee". All fields are combined with a logical 'AND'. */
export type Organization_Employee_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Employee_Bool_Exp>>;
  _not?: InputMaybe<Organization_Employee_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Employee_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_leaderships?: InputMaybe<Organization_Leadership_Bool_Exp>;
  organization_leaderships_aggregate?: InputMaybe<Organization_Leadership_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_employee" */
export enum Organization_Employee_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrgEmployeePkey = 'org_employee_pkey',
}

/** input type for incrementing numeric columns in table "organization_employee" */
export type Organization_Employee_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organization_employee" */
export type Organization_Employee_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization_leaderships?: InputMaybe<Organization_Leadership_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Organization_Employee_Max_Fields = {
  __typename?: 'organization_employee_max_fields';
  id: Maybe<Scalars['Int']>;
  linkedin_url: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Organization_Employee_Min_Fields = {
  __typename?: 'organization_employee_min_fields';
  id: Maybe<Scalars['Int']>;
  linkedin_url: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "organization_employee" */
export type Organization_Employee_Mutation_Response = {
  __typename?: 'organization_employee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Employee>;
};

/** input type for inserting object relation for remote table "organization_employee" */
export type Organization_Employee_Obj_Rel_Insert_Input = {
  data: Organization_Employee_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Employee_On_Conflict>;
};

/** on_conflict condition type for table "organization_employee" */
export type Organization_Employee_On_Conflict = {
  constraint: Organization_Employee_Constraint;
  update_columns?: Array<Organization_Employee_Update_Column>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_employee". */
export type Organization_Employee_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_leaderships_aggregate?: InputMaybe<Organization_Leadership_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_employee */
export type Organization_Employee_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "organization_employee" */
export enum Organization_Employee_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Name = 'name',
  /** column name */
  Title = 'title',
}

/** input type for updating data in table "organization_employee" */
export type Organization_Employee_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Organization_Employee_Stddev_Fields = {
  __typename?: 'organization_employee_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Organization_Employee_Stddev_Pop_Fields = {
  __typename?: 'organization_employee_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Organization_Employee_Stddev_Samp_Fields = {
  __typename?: 'organization_employee_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "organization_employee" */
export type Organization_Employee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Employee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Employee_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Organization_Employee_Sum_Fields = {
  __typename?: 'organization_employee_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "organization_employee" */
export enum Organization_Employee_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Name = 'name',
  /** column name */
  Title = 'title',
}

export type Organization_Employee_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Employee_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Employee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Employee_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Employee_Var_Pop_Fields = {
  __typename?: 'organization_employee_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Organization_Employee_Var_Samp_Fields = {
  __typename?: 'organization_employee_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Organization_Employee_Variance_Fields = {
  __typename?: 'organization_employee_variance_fields';
  id: Maybe<Scalars['Float']>;
};

export type Organization_Icims_Jobs_Args = {
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** This table is only used for its Hasura generated type, don't populate it. */
export type Organization_Icims_Jobs_Result = {
  __typename?: 'organization_icims_jobs_result';
  job_folder: Maybe<Scalars['String']>;
  job_profile_id: Maybe<Scalars['Int']>;
};

export type Organization_Icims_Jobs_Result_Aggregate = {
  __typename?: 'organization_icims_jobs_result_aggregate';
  aggregate: Maybe<Organization_Icims_Jobs_Result_Aggregate_Fields>;
  nodes: Array<Organization_Icims_Jobs_Result>;
};

/** aggregate fields of "organization_icims_jobs_result" */
export type Organization_Icims_Jobs_Result_Aggregate_Fields = {
  __typename?: 'organization_icims_jobs_result_aggregate_fields';
  avg: Maybe<Organization_Icims_Jobs_Result_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Organization_Icims_Jobs_Result_Max_Fields>;
  min: Maybe<Organization_Icims_Jobs_Result_Min_Fields>;
  stddev: Maybe<Organization_Icims_Jobs_Result_Stddev_Fields>;
  stddev_pop: Maybe<Organization_Icims_Jobs_Result_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Organization_Icims_Jobs_Result_Stddev_Samp_Fields>;
  sum: Maybe<Organization_Icims_Jobs_Result_Sum_Fields>;
  var_pop: Maybe<Organization_Icims_Jobs_Result_Var_Pop_Fields>;
  var_samp: Maybe<Organization_Icims_Jobs_Result_Var_Samp_Fields>;
  variance: Maybe<Organization_Icims_Jobs_Result_Variance_Fields>;
};

/** aggregate fields of "organization_icims_jobs_result" */
export type Organization_Icims_Jobs_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Icims_Jobs_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Organization_Icims_Jobs_Result_Avg_Fields = {
  __typename?: 'organization_icims_jobs_result_avg_fields';
  job_profile_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "organization_icims_jobs_result". All fields are combined with a logical 'AND'. */
export type Organization_Icims_Jobs_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Icims_Jobs_Result_Bool_Exp>>;
  _not?: InputMaybe<Organization_Icims_Jobs_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Icims_Jobs_Result_Bool_Exp>>;
  job_folder?: InputMaybe<String_Comparison_Exp>;
  job_profile_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "organization_icims_jobs_result" */
export type Organization_Icims_Jobs_Result_Inc_Input = {
  job_profile_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organization_icims_jobs_result" */
export type Organization_Icims_Jobs_Result_Insert_Input = {
  job_folder?: InputMaybe<Scalars['String']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Organization_Icims_Jobs_Result_Max_Fields = {
  __typename?: 'organization_icims_jobs_result_max_fields';
  job_folder: Maybe<Scalars['String']>;
  job_profile_id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Organization_Icims_Jobs_Result_Min_Fields = {
  __typename?: 'organization_icims_jobs_result_min_fields';
  job_folder: Maybe<Scalars['String']>;
  job_profile_id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "organization_icims_jobs_result" */
export type Organization_Icims_Jobs_Result_Mutation_Response = {
  __typename?: 'organization_icims_jobs_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Icims_Jobs_Result>;
};

/** Ordering options when selecting data from "organization_icims_jobs_result". */
export type Organization_Icims_Jobs_Result_Order_By = {
  job_folder?: InputMaybe<Order_By>;
  job_profile_id?: InputMaybe<Order_By>;
};

/** select columns of table "organization_icims_jobs_result" */
export enum Organization_Icims_Jobs_Result_Select_Column {
  /** column name */
  JobFolder = 'job_folder',
  /** column name */
  JobProfileId = 'job_profile_id',
}

/** input type for updating data in table "organization_icims_jobs_result" */
export type Organization_Icims_Jobs_Result_Set_Input = {
  job_folder?: InputMaybe<Scalars['String']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Organization_Icims_Jobs_Result_Stddev_Fields = {
  __typename?: 'organization_icims_jobs_result_stddev_fields';
  job_profile_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Organization_Icims_Jobs_Result_Stddev_Pop_Fields = {
  __typename?: 'organization_icims_jobs_result_stddev_pop_fields';
  job_profile_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Organization_Icims_Jobs_Result_Stddev_Samp_Fields = {
  __typename?: 'organization_icims_jobs_result_stddev_samp_fields';
  job_profile_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "organization_icims_jobs_result" */
export type Organization_Icims_Jobs_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Icims_Jobs_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Icims_Jobs_Result_Stream_Cursor_Value_Input = {
  job_folder?: InputMaybe<Scalars['String']>;
  job_profile_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Organization_Icims_Jobs_Result_Sum_Fields = {
  __typename?: 'organization_icims_jobs_result_sum_fields';
  job_profile_id: Maybe<Scalars['Int']>;
};

export type Organization_Icims_Jobs_Result_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Icims_Jobs_Result_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Icims_Jobs_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Icims_Jobs_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Icims_Jobs_Result_Var_Pop_Fields = {
  __typename?: 'organization_icims_jobs_result_var_pop_fields';
  job_profile_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Organization_Icims_Jobs_Result_Var_Samp_Fields = {
  __typename?: 'organization_icims_jobs_result_var_samp_fields';
  job_profile_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Organization_Icims_Jobs_Result_Variance_Fields = {
  __typename?: 'organization_icims_jobs_result_variance_fields';
  job_profile_id: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "organization" */
export type Organization_Inc_Input = {
  dayforce_customer_id?: InputMaybe<Scalars['Int']>;
  icims_company_profile_id?: InputMaybe<Scalars['Int']>;
  icims_initial_data_transfer?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  number_of_funding_rounds?: InputMaybe<Scalars['Int']>;
  salesforce_customer_id?: InputMaybe<Scalars['Int']>;
  total_funds_raised?: InputMaybe<Scalars['numeric']>;
  year_company_founded?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organization" */
export type Organization_Insert_Input = {
  about?: InputMaybe<Scalars['String']>;
  company_size?: InputMaybe<Company_Size_Choices_Enum>;
  culture_values?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currency_exchange_rate?: InputMaybe<Currency_Exchange_Rate_Obj_Rel_Insert_Input>;
  customer_offering_selection?: InputMaybe<Offering_Selection_Choices_Enum>;
  dayforce_customer?: InputMaybe<Dayforce_Customer_Obj_Rel_Insert_Input>;
  dayforce_customer_id?: InputMaybe<Scalars['Int']>;
  domains?: InputMaybe<Organization_Domain_Arr_Rel_Insert_Input>;
  hq_city?: InputMaybe<Scalars['String']>;
  hq_country?: InputMaybe<Scalars['String']>;
  hq_state?: InputMaybe<Scalars['String']>;
  icims_company?: InputMaybe<Icims_Company_Obj_Rel_Insert_Input>;
  icims_company_profile_id?: InputMaybe<Scalars['Int']>;
  icims_initial_data_transfer?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_sell_type?: InputMaybe<Scalars['String']>;
  jobs?: InputMaybe<Job_Arr_Rel_Insert_Input>;
  last_funding_date?: InputMaybe<Scalars['date']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logo_sq?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number_of_funding_rounds?: InputMaybe<Scalars['Int']>;
  organization_contacts?: InputMaybe<Organization_Contact_Arr_Rel_Insert_Input>;
  organization_leaderships?: InputMaybe<Organization_Leadership_Arr_Rel_Insert_Input>;
  organization_videos?: InputMaybe<Organization_Video_Arr_Rel_Insert_Input>;
  pdl_blob?: InputMaybe<Scalars['jsonb']>;
  salesforce_customer?: InputMaybe<Salesforce_Customer_Obj_Rel_Insert_Input>;
  salesforce_customer_id?: InputMaybe<Scalars['Int']>;
  salesforce_id?: InputMaybe<Scalars['String']>;
  slack_channel?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  terminal_employee_organizations?: InputMaybe<Terminal_Employee_Organization_Arr_Rel_Insert_Input>;
  total_funds_raised?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Organization_Type_Choices_Enum>;
  user_infos?: InputMaybe<User_Info_Arr_Rel_Insert_Input>;
  website_url?: InputMaybe<Scalars['String']>;
  year_company_founded?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "organization_leadership" */
export type Organization_Leadership = {
  __typename?: 'organization_leadership';
  id: Scalars['Int'];
  /** An object relationship */
  organization: Organization;
  /** An object relationship */
  organization_employee: Organization_Employee;
  organization_employee_id: Scalars['Int'];
  organization_id: Scalars['Int'];
};

/** aggregated selection of "organization_leadership" */
export type Organization_Leadership_Aggregate = {
  __typename?: 'organization_leadership_aggregate';
  aggregate: Maybe<Organization_Leadership_Aggregate_Fields>;
  nodes: Array<Organization_Leadership>;
};

export type Organization_Leadership_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Leadership_Aggregate_Bool_Exp_Count>;
};

export type Organization_Leadership_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Leadership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Leadership_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_leadership" */
export type Organization_Leadership_Aggregate_Fields = {
  __typename?: 'organization_leadership_aggregate_fields';
  avg: Maybe<Organization_Leadership_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Organization_Leadership_Max_Fields>;
  min: Maybe<Organization_Leadership_Min_Fields>;
  stddev: Maybe<Organization_Leadership_Stddev_Fields>;
  stddev_pop: Maybe<Organization_Leadership_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Organization_Leadership_Stddev_Samp_Fields>;
  sum: Maybe<Organization_Leadership_Sum_Fields>;
  var_pop: Maybe<Organization_Leadership_Var_Pop_Fields>;
  var_samp: Maybe<Organization_Leadership_Var_Samp_Fields>;
  variance: Maybe<Organization_Leadership_Variance_Fields>;
};

/** aggregate fields of "organization_leadership" */
export type Organization_Leadership_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Leadership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_leadership" */
export type Organization_Leadership_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_Leadership_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Leadership_Max_Order_By>;
  min?: InputMaybe<Organization_Leadership_Min_Order_By>;
  stddev?: InputMaybe<Organization_Leadership_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_Leadership_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_Leadership_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_Leadership_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_Leadership_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_Leadership_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_Leadership_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organization_leadership" */
export type Organization_Leadership_Arr_Rel_Insert_Input = {
  data: Array<Organization_Leadership_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Leadership_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_Leadership_Avg_Fields = {
  __typename?: 'organization_leadership_avg_fields';
  id: Maybe<Scalars['Float']>;
  organization_employee_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization_leadership" */
export type Organization_Leadership_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization_leadership". All fields are combined with a logical 'AND'. */
export type Organization_Leadership_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Leadership_Bool_Exp>>;
  _not?: InputMaybe<Organization_Leadership_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Leadership_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_employee?: InputMaybe<Organization_Employee_Bool_Exp>;
  organization_employee_id?: InputMaybe<Int_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_leadership" */
export enum Organization_Leadership_Constraint {
  /** unique or primary key constraint on columns "organization_employee_id", "organization_id" */
  OrganizationLeadershipOrganziationIdOrganizationEmployKey = 'organization_leadership_organziation_id_organization_employ_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationLeadershipPkey = 'organization_leadership_pkey',
}

/** input type for incrementing numeric columns in table "organization_leadership" */
export type Organization_Leadership_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization_employee_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organization_leadership" */
export type Organization_Leadership_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_employee?: InputMaybe<Organization_Employee_Obj_Rel_Insert_Input>;
  organization_employee_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Organization_Leadership_Max_Fields = {
  __typename?: 'organization_leadership_max_fields';
  id: Maybe<Scalars['Int']>;
  organization_employee_id: Maybe<Scalars['Int']>;
  organization_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "organization_leadership" */
export type Organization_Leadership_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Leadership_Min_Fields = {
  __typename?: 'organization_leadership_min_fields';
  id: Maybe<Scalars['Int']>;
  organization_employee_id: Maybe<Scalars['Int']>;
  organization_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "organization_leadership" */
export type Organization_Leadership_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_leadership" */
export type Organization_Leadership_Mutation_Response = {
  __typename?: 'organization_leadership_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Leadership>;
};

/** on_conflict condition type for table "organization_leadership" */
export type Organization_Leadership_On_Conflict = {
  constraint: Organization_Leadership_Constraint;
  update_columns?: Array<Organization_Leadership_Update_Column>;
  where?: InputMaybe<Organization_Leadership_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_leadership". */
export type Organization_Leadership_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_employee?: InputMaybe<Organization_Employee_Order_By>;
  organization_employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_leadership */
export type Organization_Leadership_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "organization_leadership" */
export enum Organization_Leadership_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationEmployeeId = 'organization_employee_id',
  /** column name */
  OrganizationId = 'organization_id',
}

/** input type for updating data in table "organization_leadership" */
export type Organization_Leadership_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization_employee_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Organization_Leadership_Stddev_Fields = {
  __typename?: 'organization_leadership_stddev_fields';
  id: Maybe<Scalars['Float']>;
  organization_employee_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization_leadership" */
export type Organization_Leadership_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_Leadership_Stddev_Pop_Fields = {
  __typename?: 'organization_leadership_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization_employee_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization_leadership" */
export type Organization_Leadership_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_Leadership_Stddev_Samp_Fields = {
  __typename?: 'organization_leadership_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization_employee_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization_leadership" */
export type Organization_Leadership_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization_leadership" */
export type Organization_Leadership_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Leadership_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Leadership_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization_employee_id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Organization_Leadership_Sum_Fields = {
  __typename?: 'organization_leadership_sum_fields';
  id: Maybe<Scalars['Int']>;
  organization_employee_id: Maybe<Scalars['Int']>;
  organization_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organization_leadership" */
export type Organization_Leadership_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** update columns of table "organization_leadership" */
export enum Organization_Leadership_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationEmployeeId = 'organization_employee_id',
  /** column name */
  OrganizationId = 'organization_id',
}

export type Organization_Leadership_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Leadership_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Leadership_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Leadership_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Leadership_Var_Pop_Fields = {
  __typename?: 'organization_leadership_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization_employee_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization_leadership" */
export type Organization_Leadership_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_Leadership_Var_Samp_Fields = {
  __typename?: 'organization_leadership_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization_employee_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization_leadership" */
export type Organization_Leadership_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_Leadership_Variance_Fields = {
  __typename?: 'organization_leadership_variance_fields';
  id: Maybe<Scalars['Float']>;
  organization_employee_id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization_leadership" */
export type Organization_Leadership_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_employee_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Organization_Max_Fields = {
  __typename?: 'organization_max_fields';
  about: Maybe<Scalars['String']>;
  culture_values: Maybe<Scalars['String']>;
  currency: Maybe<Scalars['String']>;
  dayforce_customer_id: Maybe<Scalars['Int']>;
  hq_city: Maybe<Scalars['String']>;
  hq_country: Maybe<Scalars['String']>;
  hq_state: Maybe<Scalars['String']>;
  icims_company_profile_id: Maybe<Scalars['Int']>;
  icims_initial_data_transfer: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  initial_sell_type: Maybe<Scalars['String']>;
  last_funding_date: Maybe<Scalars['date']>;
  linkedin_url: Maybe<Scalars['String']>;
  logo: Maybe<Scalars['String']>;
  logo_sq: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  number_of_funding_rounds: Maybe<Scalars['Int']>;
  salesforce_customer_id: Maybe<Scalars['Int']>;
  salesforce_id: Maybe<Scalars['String']>;
  slack_channel: Maybe<Scalars['String']>;
  total_funds_raised: Maybe<Scalars['numeric']>;
  website_url: Maybe<Scalars['String']>;
  year_company_founded: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "organization" */
export type Organization_Max_Order_By = {
  about?: InputMaybe<Order_By>;
  culture_values?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  dayforce_customer_id?: InputMaybe<Order_By>;
  hq_city?: InputMaybe<Order_By>;
  hq_country?: InputMaybe<Order_By>;
  hq_state?: InputMaybe<Order_By>;
  icims_company_profile_id?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_sell_type?: InputMaybe<Order_By>;
  last_funding_date?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  logo_sq?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  number_of_funding_rounds?: InputMaybe<Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  salesforce_id?: InputMaybe<Order_By>;
  slack_channel?: InputMaybe<Order_By>;
  total_funds_raised?: InputMaybe<Order_By>;
  website_url?: InputMaybe<Order_By>;
  year_company_founded?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Min_Fields = {
  __typename?: 'organization_min_fields';
  about: Maybe<Scalars['String']>;
  culture_values: Maybe<Scalars['String']>;
  currency: Maybe<Scalars['String']>;
  dayforce_customer_id: Maybe<Scalars['Int']>;
  hq_city: Maybe<Scalars['String']>;
  hq_country: Maybe<Scalars['String']>;
  hq_state: Maybe<Scalars['String']>;
  icims_company_profile_id: Maybe<Scalars['Int']>;
  icims_initial_data_transfer: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  initial_sell_type: Maybe<Scalars['String']>;
  last_funding_date: Maybe<Scalars['date']>;
  linkedin_url: Maybe<Scalars['String']>;
  logo: Maybe<Scalars['String']>;
  logo_sq: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  number_of_funding_rounds: Maybe<Scalars['Int']>;
  salesforce_customer_id: Maybe<Scalars['Int']>;
  salesforce_id: Maybe<Scalars['String']>;
  slack_channel: Maybe<Scalars['String']>;
  total_funds_raised: Maybe<Scalars['numeric']>;
  website_url: Maybe<Scalars['String']>;
  year_company_founded: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "organization" */
export type Organization_Min_Order_By = {
  about?: InputMaybe<Order_By>;
  culture_values?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  dayforce_customer_id?: InputMaybe<Order_By>;
  hq_city?: InputMaybe<Order_By>;
  hq_country?: InputMaybe<Order_By>;
  hq_state?: InputMaybe<Order_By>;
  icims_company_profile_id?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_sell_type?: InputMaybe<Order_By>;
  last_funding_date?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  logo_sq?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  number_of_funding_rounds?: InputMaybe<Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  salesforce_id?: InputMaybe<Order_By>;
  slack_channel?: InputMaybe<Order_By>;
  total_funds_raised?: InputMaybe<Order_By>;
  website_url?: InputMaybe<Order_By>;
  year_company_founded?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization" */
export type Organization_Mutation_Response = {
  __typename?: 'organization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization>;
};

/** input type for inserting object relation for remote table "organization" */
export type Organization_Obj_Rel_Insert_Input = {
  data: Organization_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_On_Conflict>;
};

/** on_conflict condition type for table "organization" */
export type Organization_On_Conflict = {
  constraint: Organization_Constraint;
  update_columns?: Array<Organization_Update_Column>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

/** Ordering options when selecting data from "organization". */
export type Organization_Order_By = {
  about?: InputMaybe<Order_By>;
  company_size?: InputMaybe<Order_By>;
  culture_values?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  currency_exchange_rate?: InputMaybe<Currency_Exchange_Rate_Order_By>;
  customer_offering_selection?: InputMaybe<Order_By>;
  dayforce_customer?: InputMaybe<Dayforce_Customer_Order_By>;
  dayforce_customer_id?: InputMaybe<Order_By>;
  domains_aggregate?: InputMaybe<Organization_Domain_Aggregate_Order_By>;
  hq_city?: InputMaybe<Order_By>;
  hq_country?: InputMaybe<Order_By>;
  hq_state?: InputMaybe<Order_By>;
  icims_company?: InputMaybe<Icims_Company_Order_By>;
  icims_company_profile_id?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_sell_type?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Order_By>;
  last_funding_date?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  logo_sq?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  number_of_funding_rounds?: InputMaybe<Order_By>;
  organization_contacts_aggregate?: InputMaybe<Organization_Contact_Aggregate_Order_By>;
  organization_leaderships_aggregate?: InputMaybe<Organization_Leadership_Aggregate_Order_By>;
  organization_videos_aggregate?: InputMaybe<Organization_Video_Aggregate_Order_By>;
  pdl_blob?: InputMaybe<Order_By>;
  salesforce_customer?: InputMaybe<Salesforce_Customer_Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  salesforce_id?: InputMaybe<Order_By>;
  slack_channel?: InputMaybe<Order_By>;
  sync_status?: InputMaybe<Order_By>;
  terminal_employee_organizations_aggregate?: InputMaybe<Terminal_Employee_Organization_Aggregate_Order_By>;
  total_funds_raised?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_infos_aggregate?: InputMaybe<User_Info_Aggregate_Order_By>;
  website_url?: InputMaybe<Order_By>;
  year_company_founded?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization */
export type Organization_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Organization_Prepend_Input = {
  pdl_blob?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "organization" */
export enum Organization_Select_Column {
  /** column name */
  About = 'about',
  /** column name */
  CompanySize = 'company_size',
  /** column name */
  CultureValues = 'culture_values',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerOfferingSelection = 'customer_offering_selection',
  /** column name */
  DayforceCustomerId = 'dayforce_customer_id',
  /** column name */
  HqCity = 'hq_city',
  /** column name */
  HqCountry = 'hq_country',
  /** column name */
  HqState = 'hq_state',
  /** column name */
  IcimsCompanyProfileId = 'icims_company_profile_id',
  /** column name */
  IcimsInitialDataTransfer = 'icims_initial_data_transfer',
  /** column name */
  Id = 'id',
  /** column name */
  InitialSellType = 'initial_sell_type',
  /** column name */
  LastFundingDate = 'last_funding_date',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Logo = 'logo',
  /** column name */
  LogoSq = 'logo_sq',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfFundingRounds = 'number_of_funding_rounds',
  /** column name */
  PdlBlob = 'pdl_blob',
  /** column name */
  SalesforceCustomerId = 'salesforce_customer_id',
  /** column name */
  SalesforceId = 'salesforce_id',
  /** column name */
  SlackChannel = 'slack_channel',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  TotalFundsRaised = 'total_funds_raised',
  /** column name */
  Type = 'type',
  /** column name */
  WebsiteUrl = 'website_url',
  /** column name */
  YearCompanyFounded = 'year_company_founded',
}

/** input type for updating data in table "organization" */
export type Organization_Set_Input = {
  about?: InputMaybe<Scalars['String']>;
  company_size?: InputMaybe<Company_Size_Choices_Enum>;
  culture_values?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  customer_offering_selection?: InputMaybe<Offering_Selection_Choices_Enum>;
  dayforce_customer_id?: InputMaybe<Scalars['Int']>;
  hq_city?: InputMaybe<Scalars['String']>;
  hq_country?: InputMaybe<Scalars['String']>;
  hq_state?: InputMaybe<Scalars['String']>;
  icims_company_profile_id?: InputMaybe<Scalars['Int']>;
  icims_initial_data_transfer?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_sell_type?: InputMaybe<Scalars['String']>;
  last_funding_date?: InputMaybe<Scalars['date']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logo_sq?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number_of_funding_rounds?: InputMaybe<Scalars['Int']>;
  pdl_blob?: InputMaybe<Scalars['jsonb']>;
  salesforce_customer_id?: InputMaybe<Scalars['Int']>;
  salesforce_id?: InputMaybe<Scalars['String']>;
  slack_channel?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  total_funds_raised?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Organization_Type_Choices_Enum>;
  website_url?: InputMaybe<Scalars['String']>;
  year_company_founded?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Organization_Stddev_Fields = {
  __typename?: 'organization_stddev_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  icims_company_profile_id: Maybe<Scalars['Float']>;
  icims_initial_data_transfer: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  number_of_funding_rounds: Maybe<Scalars['Float']>;
  salesforce_customer_id: Maybe<Scalars['Float']>;
  total_funds_raised: Maybe<Scalars['Float']>;
  year_company_founded: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization" */
export type Organization_Stddev_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  icims_company_profile_id?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_funding_rounds?: InputMaybe<Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  total_funds_raised?: InputMaybe<Order_By>;
  year_company_founded?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_Stddev_Pop_Fields = {
  __typename?: 'organization_stddev_pop_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  icims_company_profile_id: Maybe<Scalars['Float']>;
  icims_initial_data_transfer: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  number_of_funding_rounds: Maybe<Scalars['Float']>;
  salesforce_customer_id: Maybe<Scalars['Float']>;
  total_funds_raised: Maybe<Scalars['Float']>;
  year_company_founded: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization" */
export type Organization_Stddev_Pop_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  icims_company_profile_id?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_funding_rounds?: InputMaybe<Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  total_funds_raised?: InputMaybe<Order_By>;
  year_company_founded?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_Stddev_Samp_Fields = {
  __typename?: 'organization_stddev_samp_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  icims_company_profile_id: Maybe<Scalars['Float']>;
  icims_initial_data_transfer: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  number_of_funding_rounds: Maybe<Scalars['Float']>;
  salesforce_customer_id: Maybe<Scalars['Float']>;
  total_funds_raised: Maybe<Scalars['Float']>;
  year_company_founded: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization" */
export type Organization_Stddev_Samp_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  icims_company_profile_id?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_funding_rounds?: InputMaybe<Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  total_funds_raised?: InputMaybe<Order_By>;
  year_company_founded?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization" */
export type Organization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Stream_Cursor_Value_Input = {
  about?: InputMaybe<Scalars['String']>;
  company_size?: InputMaybe<Company_Size_Choices_Enum>;
  culture_values?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  customer_offering_selection?: InputMaybe<Offering_Selection_Choices_Enum>;
  dayforce_customer_id?: InputMaybe<Scalars['Int']>;
  hq_city?: InputMaybe<Scalars['String']>;
  hq_country?: InputMaybe<Scalars['String']>;
  hq_state?: InputMaybe<Scalars['String']>;
  icims_company_profile_id?: InputMaybe<Scalars['Int']>;
  icims_initial_data_transfer?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initial_sell_type?: InputMaybe<Scalars['String']>;
  last_funding_date?: InputMaybe<Scalars['date']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logo_sq?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number_of_funding_rounds?: InputMaybe<Scalars['Int']>;
  pdl_blob?: InputMaybe<Scalars['jsonb']>;
  salesforce_customer_id?: InputMaybe<Scalars['Int']>;
  salesforce_id?: InputMaybe<Scalars['String']>;
  slack_channel?: InputMaybe<Scalars['String']>;
  sync_status?: InputMaybe<Icims_Sync_Status_Choices_Enum>;
  total_funds_raised?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Organization_Type_Choices_Enum>;
  website_url?: InputMaybe<Scalars['String']>;
  year_company_founded?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Organization_Sum_Fields = {
  __typename?: 'organization_sum_fields';
  dayforce_customer_id: Maybe<Scalars['Int']>;
  icims_company_profile_id: Maybe<Scalars['Int']>;
  icims_initial_data_transfer: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  number_of_funding_rounds: Maybe<Scalars['Int']>;
  salesforce_customer_id: Maybe<Scalars['Int']>;
  total_funds_raised: Maybe<Scalars['numeric']>;
  year_company_founded: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organization" */
export type Organization_Sum_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  icims_company_profile_id?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_funding_rounds?: InputMaybe<Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  total_funds_raised?: InputMaybe<Order_By>;
  year_company_founded?: InputMaybe<Order_By>;
};

/** columns and relationships of "organization_type_choices" */
export type Organization_Type_Choices = {
  __typename?: 'organization_type_choices';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "organization_type_choices" */
export type Organization_Type_Choices_Aggregate = {
  __typename?: 'organization_type_choices_aggregate';
  aggregate: Maybe<Organization_Type_Choices_Aggregate_Fields>;
  nodes: Array<Organization_Type_Choices>;
};

/** aggregate fields of "organization_type_choices" */
export type Organization_Type_Choices_Aggregate_Fields = {
  __typename?: 'organization_type_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Organization_Type_Choices_Max_Fields>;
  min: Maybe<Organization_Type_Choices_Min_Fields>;
};

/** aggregate fields of "organization_type_choices" */
export type Organization_Type_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Type_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organization_type_choices". All fields are combined with a logical 'AND'. */
export type Organization_Type_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Type_Choices_Bool_Exp>>;
  _not?: InputMaybe<Organization_Type_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Type_Choices_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_type_choices" */
export enum Organization_Type_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  OrganizationTypeChoicesPkey = 'organization_type_choices_pkey',
}

export enum Organization_Type_Choices_Enum {
  /** Client Prospect Organization */
  Prospect = 'PROSPECT',
  /** Regular Organization */
  Regular = 'REGULAR',
}

/** Boolean expression to compare columns of type "organization_type_choices_enum". All fields are combined with logical 'AND'. */
export type Organization_Type_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Organization_Type_Choices_Enum>;
  _in?: InputMaybe<Array<Organization_Type_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Organization_Type_Choices_Enum>;
  _nin?: InputMaybe<Array<Organization_Type_Choices_Enum>>;
};

/** input type for inserting data into table "organization_type_choices" */
export type Organization_Type_Choices_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Organization_Type_Choices_Max_Fields = {
  __typename?: 'organization_type_choices_max_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Organization_Type_Choices_Min_Fields = {
  __typename?: 'organization_type_choices_min_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "organization_type_choices" */
export type Organization_Type_Choices_Mutation_Response = {
  __typename?: 'organization_type_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Type_Choices>;
};

/** on_conflict condition type for table "organization_type_choices" */
export type Organization_Type_Choices_On_Conflict = {
  constraint: Organization_Type_Choices_Constraint;
  update_columns?: Array<Organization_Type_Choices_Update_Column>;
  where?: InputMaybe<Organization_Type_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_type_choices". */
export type Organization_Type_Choices_Order_By = {
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_type_choices */
export type Organization_Type_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "organization_type_choices" */
export enum Organization_Type_Choices_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "organization_type_choices" */
export type Organization_Type_Choices_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "organization_type_choices" */
export type Organization_Type_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Type_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Type_Choices_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "organization_type_choices" */
export enum Organization_Type_Choices_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

export type Organization_Type_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Type_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Type_Choices_Bool_Exp;
};

/** update columns of table "organization" */
export enum Organization_Update_Column {
  /** column name */
  About = 'about',
  /** column name */
  CompanySize = 'company_size',
  /** column name */
  CultureValues = 'culture_values',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerOfferingSelection = 'customer_offering_selection',
  /** column name */
  DayforceCustomerId = 'dayforce_customer_id',
  /** column name */
  HqCity = 'hq_city',
  /** column name */
  HqCountry = 'hq_country',
  /** column name */
  HqState = 'hq_state',
  /** column name */
  IcimsCompanyProfileId = 'icims_company_profile_id',
  /** column name */
  IcimsInitialDataTransfer = 'icims_initial_data_transfer',
  /** column name */
  Id = 'id',
  /** column name */
  InitialSellType = 'initial_sell_type',
  /** column name */
  LastFundingDate = 'last_funding_date',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Logo = 'logo',
  /** column name */
  LogoSq = 'logo_sq',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfFundingRounds = 'number_of_funding_rounds',
  /** column name */
  PdlBlob = 'pdl_blob',
  /** column name */
  SalesforceCustomerId = 'salesforce_customer_id',
  /** column name */
  SalesforceId = 'salesforce_id',
  /** column name */
  SlackChannel = 'slack_channel',
  /** column name */
  SyncStatus = 'sync_status',
  /** column name */
  TotalFundsRaised = 'total_funds_raised',
  /** column name */
  Type = 'type',
  /** column name */
  WebsiteUrl = 'website_url',
  /** column name */
  YearCompanyFounded = 'year_company_founded',
}

export type Organization_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Organization_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Organization_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Organization_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Organization_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Organization_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Var_Pop_Fields = {
  __typename?: 'organization_var_pop_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  icims_company_profile_id: Maybe<Scalars['Float']>;
  icims_initial_data_transfer: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  number_of_funding_rounds: Maybe<Scalars['Float']>;
  salesforce_customer_id: Maybe<Scalars['Float']>;
  total_funds_raised: Maybe<Scalars['Float']>;
  year_company_founded: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization" */
export type Organization_Var_Pop_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  icims_company_profile_id?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_funding_rounds?: InputMaybe<Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  total_funds_raised?: InputMaybe<Order_By>;
  year_company_founded?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_Var_Samp_Fields = {
  __typename?: 'organization_var_samp_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  icims_company_profile_id: Maybe<Scalars['Float']>;
  icims_initial_data_transfer: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  number_of_funding_rounds: Maybe<Scalars['Float']>;
  salesforce_customer_id: Maybe<Scalars['Float']>;
  total_funds_raised: Maybe<Scalars['Float']>;
  year_company_founded: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization" */
export type Organization_Var_Samp_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  icims_company_profile_id?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_funding_rounds?: InputMaybe<Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  total_funds_raised?: InputMaybe<Order_By>;
  year_company_founded?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_Variance_Fields = {
  __typename?: 'organization_variance_fields';
  dayforce_customer_id: Maybe<Scalars['Float']>;
  icims_company_profile_id: Maybe<Scalars['Float']>;
  icims_initial_data_transfer: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  number_of_funding_rounds: Maybe<Scalars['Float']>;
  salesforce_customer_id: Maybe<Scalars['Float']>;
  total_funds_raised: Maybe<Scalars['Float']>;
  year_company_founded: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization" */
export type Organization_Variance_Order_By = {
  dayforce_customer_id?: InputMaybe<Order_By>;
  icims_company_profile_id?: InputMaybe<Order_By>;
  icims_initial_data_transfer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_funding_rounds?: InputMaybe<Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  total_funds_raised?: InputMaybe<Order_By>;
  year_company_founded?: InputMaybe<Order_By>;
};

/** columns and relationships of "organization_video" */
export type Organization_Video = {
  __typename?: 'organization_video';
  id: Scalars['Int'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['Int'];
  url: Scalars['String'];
};

/** aggregated selection of "organization_video" */
export type Organization_Video_Aggregate = {
  __typename?: 'organization_video_aggregate';
  aggregate: Maybe<Organization_Video_Aggregate_Fields>;
  nodes: Array<Organization_Video>;
};

export type Organization_Video_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Video_Aggregate_Bool_Exp_Count>;
};

export type Organization_Video_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Video_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organization_Video_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_video" */
export type Organization_Video_Aggregate_Fields = {
  __typename?: 'organization_video_aggregate_fields';
  avg: Maybe<Organization_Video_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Organization_Video_Max_Fields>;
  min: Maybe<Organization_Video_Min_Fields>;
  stddev: Maybe<Organization_Video_Stddev_Fields>;
  stddev_pop: Maybe<Organization_Video_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Organization_Video_Stddev_Samp_Fields>;
  sum: Maybe<Organization_Video_Sum_Fields>;
  var_pop: Maybe<Organization_Video_Var_Pop_Fields>;
  var_samp: Maybe<Organization_Video_Var_Samp_Fields>;
  variance: Maybe<Organization_Video_Variance_Fields>;
};

/** aggregate fields of "organization_video" */
export type Organization_Video_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Video_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_video" */
export type Organization_Video_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_Video_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Video_Max_Order_By>;
  min?: InputMaybe<Organization_Video_Min_Order_By>;
  stddev?: InputMaybe<Organization_Video_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_Video_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_Video_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_Video_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_Video_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_Video_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_Video_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organization_video" */
export type Organization_Video_Arr_Rel_Insert_Input = {
  data: Array<Organization_Video_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Video_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_Video_Avg_Fields = {
  __typename?: 'organization_video_avg_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organization_video" */
export type Organization_Video_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization_video". All fields are combined with a logical 'AND'. */
export type Organization_Video_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Video_Bool_Exp>>;
  _not?: InputMaybe<Organization_Video_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Video_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_video" */
export enum Organization_Video_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationVideoPkey = 'organization_video_pkey',
}

/** input type for incrementing numeric columns in table "organization_video" */
export type Organization_Video_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "organization_video" */
export type Organization_Video_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Organization_Video_Max_Fields = {
  __typename?: 'organization_video_max_fields';
  id: Maybe<Scalars['Int']>;
  organization_id: Maybe<Scalars['Int']>;
  url: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "organization_video" */
export type Organization_Video_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Video_Min_Fields = {
  __typename?: 'organization_video_min_fields';
  id: Maybe<Scalars['Int']>;
  organization_id: Maybe<Scalars['Int']>;
  url: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "organization_video" */
export type Organization_Video_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_video" */
export type Organization_Video_Mutation_Response = {
  __typename?: 'organization_video_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Video>;
};

/** on_conflict condition type for table "organization_video" */
export type Organization_Video_On_Conflict = {
  constraint: Organization_Video_Constraint;
  update_columns?: Array<Organization_Video_Update_Column>;
  where?: InputMaybe<Organization_Video_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_video". */
export type Organization_Video_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_video */
export type Organization_Video_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "organization_video" */
export enum Organization_Video_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "organization_video" */
export type Organization_Video_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Organization_Video_Stddev_Fields = {
  __typename?: 'organization_video_stddev_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organization_video" */
export type Organization_Video_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_Video_Stddev_Pop_Fields = {
  __typename?: 'organization_video_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organization_video" */
export type Organization_Video_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_Video_Stddev_Samp_Fields = {
  __typename?: 'organization_video_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organization_video" */
export type Organization_Video_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization_video" */
export type Organization_Video_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Video_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Video_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization_id?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Organization_Video_Sum_Fields = {
  __typename?: 'organization_video_sum_fields';
  id: Maybe<Scalars['Int']>;
  organization_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organization_video" */
export type Organization_Video_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** update columns of table "organization_video" */
export enum Organization_Video_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Url = 'url',
}

export type Organization_Video_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Video_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Video_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Video_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Video_Var_Pop_Fields = {
  __typename?: 'organization_video_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organization_video" */
export type Organization_Video_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_Video_Var_Samp_Fields = {
  __typename?: 'organization_video_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organization_video" */
export type Organization_Video_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_Video_Variance_Fields = {
  __typename?: 'organization_video_variance_fields';
  id: Maybe<Scalars['Float']>;
  organization_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organization_video" */
export type Organization_Video_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "pillar_transcript" */
export type Pillar_Transcript = {
  __typename?: 'pillar_transcript';
  ai_extracted_info: Maybe<Scalars['jsonb']>;
  ai_extracted_info_version: Maybe<Scalars['String']>;
  candidate_email: Scalars['citext'];
  candidate_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  interview_started_at: Scalars['date'];
  /** An object relationship */
  pillar_transcript_blob: Pillar_Transcript_Blob;
  transcript_id: Scalars['String'];
  transcript_job_id: Maybe<Scalars['String']>;
  transcript_job_title: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "pillar_transcript" */
export type Pillar_TranscriptAi_Extracted_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "pillar_transcript" */
export type Pillar_Transcript_Aggregate = {
  __typename?: 'pillar_transcript_aggregate';
  aggregate: Maybe<Pillar_Transcript_Aggregate_Fields>;
  nodes: Array<Pillar_Transcript>;
};

export type Pillar_Transcript_Aggregate_Bool_Exp = {
  count?: InputMaybe<Pillar_Transcript_Aggregate_Bool_Exp_Count>;
};

export type Pillar_Transcript_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Pillar_Transcript_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Pillar_Transcript_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "pillar_transcript" */
export type Pillar_Transcript_Aggregate_Fields = {
  __typename?: 'pillar_transcript_aggregate_fields';
  avg: Maybe<Pillar_Transcript_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Pillar_Transcript_Max_Fields>;
  min: Maybe<Pillar_Transcript_Min_Fields>;
  stddev: Maybe<Pillar_Transcript_Stddev_Fields>;
  stddev_pop: Maybe<Pillar_Transcript_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Pillar_Transcript_Stddev_Samp_Fields>;
  sum: Maybe<Pillar_Transcript_Sum_Fields>;
  var_pop: Maybe<Pillar_Transcript_Var_Pop_Fields>;
  var_samp: Maybe<Pillar_Transcript_Var_Samp_Fields>;
  variance: Maybe<Pillar_Transcript_Variance_Fields>;
};

/** aggregate fields of "pillar_transcript" */
export type Pillar_Transcript_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pillar_Transcript_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pillar_transcript" */
export type Pillar_Transcript_Aggregate_Order_By = {
  avg?: InputMaybe<Pillar_Transcript_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pillar_Transcript_Max_Order_By>;
  min?: InputMaybe<Pillar_Transcript_Min_Order_By>;
  stddev?: InputMaybe<Pillar_Transcript_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Pillar_Transcript_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Pillar_Transcript_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Pillar_Transcript_Sum_Order_By>;
  var_pop?: InputMaybe<Pillar_Transcript_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Pillar_Transcript_Var_Samp_Order_By>;
  variance?: InputMaybe<Pillar_Transcript_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pillar_Transcript_Append_Input = {
  ai_extracted_info?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "pillar_transcript" */
export type Pillar_Transcript_Arr_Rel_Insert_Input = {
  data: Array<Pillar_Transcript_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Pillar_Transcript_On_Conflict>;
};

/** aggregate avg on columns */
export type Pillar_Transcript_Avg_Fields = {
  __typename?: 'pillar_transcript_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pillar_transcript" */
export type Pillar_Transcript_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "pillar_transcript_blob" */
export type Pillar_Transcript_Blob = {
  __typename?: 'pillar_transcript_blob';
  blob: Scalars['jsonb'];
  transcript_id: Scalars['String'];
};

/** columns and relationships of "pillar_transcript_blob" */
export type Pillar_Transcript_BlobBlobArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "pillar_transcript_blob" */
export type Pillar_Transcript_Blob_Aggregate = {
  __typename?: 'pillar_transcript_blob_aggregate';
  aggregate: Maybe<Pillar_Transcript_Blob_Aggregate_Fields>;
  nodes: Array<Pillar_Transcript_Blob>;
};

/** aggregate fields of "pillar_transcript_blob" */
export type Pillar_Transcript_Blob_Aggregate_Fields = {
  __typename?: 'pillar_transcript_blob_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Pillar_Transcript_Blob_Max_Fields>;
  min: Maybe<Pillar_Transcript_Blob_Min_Fields>;
};

/** aggregate fields of "pillar_transcript_blob" */
export type Pillar_Transcript_Blob_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pillar_Transcript_Blob_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pillar_Transcript_Blob_Append_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "pillar_transcript_blob". All fields are combined with a logical 'AND'. */
export type Pillar_Transcript_Blob_Bool_Exp = {
  _and?: InputMaybe<Array<Pillar_Transcript_Blob_Bool_Exp>>;
  _not?: InputMaybe<Pillar_Transcript_Blob_Bool_Exp>;
  _or?: InputMaybe<Array<Pillar_Transcript_Blob_Bool_Exp>>;
  blob?: InputMaybe<Jsonb_Comparison_Exp>;
  transcript_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pillar_transcript_blob" */
export enum Pillar_Transcript_Blob_Constraint {
  /** unique or primary key constraint on columns "transcript_id" */
  PillarTranscriptBlobPkey = 'pillar_transcript_blob_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pillar_Transcript_Blob_Delete_At_Path_Input = {
  blob?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pillar_Transcript_Blob_Delete_Elem_Input = {
  blob?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pillar_Transcript_Blob_Delete_Key_Input = {
  blob?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "pillar_transcript_blob" */
export type Pillar_Transcript_Blob_Insert_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  transcript_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Pillar_Transcript_Blob_Max_Fields = {
  __typename?: 'pillar_transcript_blob_max_fields';
  transcript_id: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Pillar_Transcript_Blob_Min_Fields = {
  __typename?: 'pillar_transcript_blob_min_fields';
  transcript_id: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "pillar_transcript_blob" */
export type Pillar_Transcript_Blob_Mutation_Response = {
  __typename?: 'pillar_transcript_blob_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pillar_Transcript_Blob>;
};

/** input type for inserting object relation for remote table "pillar_transcript_blob" */
export type Pillar_Transcript_Blob_Obj_Rel_Insert_Input = {
  data: Pillar_Transcript_Blob_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Pillar_Transcript_Blob_On_Conflict>;
};

/** on_conflict condition type for table "pillar_transcript_blob" */
export type Pillar_Transcript_Blob_On_Conflict = {
  constraint: Pillar_Transcript_Blob_Constraint;
  update_columns?: Array<Pillar_Transcript_Blob_Update_Column>;
  where?: InputMaybe<Pillar_Transcript_Blob_Bool_Exp>;
};

/** Ordering options when selecting data from "pillar_transcript_blob". */
export type Pillar_Transcript_Blob_Order_By = {
  blob?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pillar_transcript_blob */
export type Pillar_Transcript_Blob_Pk_Columns_Input = {
  transcript_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pillar_Transcript_Blob_Prepend_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "pillar_transcript_blob" */
export enum Pillar_Transcript_Blob_Select_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  TranscriptId = 'transcript_id',
}

/** input type for updating data in table "pillar_transcript_blob" */
export type Pillar_Transcript_Blob_Set_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  transcript_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "pillar_transcript_blob" */
export type Pillar_Transcript_Blob_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pillar_Transcript_Blob_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pillar_Transcript_Blob_Stream_Cursor_Value_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  transcript_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "pillar_transcript_blob" */
export enum Pillar_Transcript_Blob_Update_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  TranscriptId = 'transcript_id',
}

export type Pillar_Transcript_Blob_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Pillar_Transcript_Blob_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Pillar_Transcript_Blob_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Pillar_Transcript_Blob_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Pillar_Transcript_Blob_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Pillar_Transcript_Blob_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pillar_Transcript_Blob_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pillar_Transcript_Blob_Bool_Exp;
};

/** Boolean expression to filter rows from the table "pillar_transcript". All fields are combined with a logical 'AND'. */
export type Pillar_Transcript_Bool_Exp = {
  _and?: InputMaybe<Array<Pillar_Transcript_Bool_Exp>>;
  _not?: InputMaybe<Pillar_Transcript_Bool_Exp>;
  _or?: InputMaybe<Array<Pillar_Transcript_Bool_Exp>>;
  ai_extracted_info?: InputMaybe<Jsonb_Comparison_Exp>;
  ai_extracted_info_version?: InputMaybe<String_Comparison_Exp>;
  candidate_email?: InputMaybe<Citext_Comparison_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interview_started_at?: InputMaybe<Date_Comparison_Exp>;
  pillar_transcript_blob?: InputMaybe<Pillar_Transcript_Blob_Bool_Exp>;
  transcript_id?: InputMaybe<String_Comparison_Exp>;
  transcript_job_id?: InputMaybe<String_Comparison_Exp>;
  transcript_job_title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "pillar_transcript" */
export enum Pillar_Transcript_Constraint {
  /** unique or primary key constraint on columns "id" */
  PillarTranscriptPkey = 'pillar_transcript_pkey',
  /** unique or primary key constraint on columns "transcript_id" */
  PillarTranscriptTranscriptIdKey = 'pillar_transcript_transcript_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pillar_Transcript_Delete_At_Path_Input = {
  ai_extracted_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pillar_Transcript_Delete_Elem_Input = {
  ai_extracted_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pillar_Transcript_Delete_Key_Input = {
  ai_extracted_info?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "pillar_transcript" */
export type Pillar_Transcript_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pillar_transcript" */
export type Pillar_Transcript_Insert_Input = {
  ai_extracted_info?: InputMaybe<Scalars['jsonb']>;
  ai_extracted_info_version?: InputMaybe<Scalars['String']>;
  candidate_email?: InputMaybe<Scalars['citext']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interview_started_at?: InputMaybe<Scalars['date']>;
  pillar_transcript_blob?: InputMaybe<Pillar_Transcript_Blob_Obj_Rel_Insert_Input>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_job_id?: InputMaybe<Scalars['String']>;
  transcript_job_title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Pillar_Transcript_Max_Fields = {
  __typename?: 'pillar_transcript_max_fields';
  ai_extracted_info_version: Maybe<Scalars['String']>;
  candidate_email: Maybe<Scalars['citext']>;
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  interview_started_at: Maybe<Scalars['date']>;
  transcript_id: Maybe<Scalars['String']>;
  transcript_job_id: Maybe<Scalars['String']>;
  transcript_job_title: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "pillar_transcript" */
export type Pillar_Transcript_Max_Order_By = {
  ai_extracted_info_version?: InputMaybe<Order_By>;
  candidate_email?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_started_at?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_job_id?: InputMaybe<Order_By>;
  transcript_job_title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Pillar_Transcript_Min_Fields = {
  __typename?: 'pillar_transcript_min_fields';
  ai_extracted_info_version: Maybe<Scalars['String']>;
  candidate_email: Maybe<Scalars['citext']>;
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  interview_started_at: Maybe<Scalars['date']>;
  transcript_id: Maybe<Scalars['String']>;
  transcript_job_id: Maybe<Scalars['String']>;
  transcript_job_title: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "pillar_transcript" */
export type Pillar_Transcript_Min_Order_By = {
  ai_extracted_info_version?: InputMaybe<Order_By>;
  candidate_email?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_started_at?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_job_id?: InputMaybe<Order_By>;
  transcript_job_title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "pillar_transcript" */
export type Pillar_Transcript_Mutation_Response = {
  __typename?: 'pillar_transcript_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pillar_Transcript>;
};

/** on_conflict condition type for table "pillar_transcript" */
export type Pillar_Transcript_On_Conflict = {
  constraint: Pillar_Transcript_Constraint;
  update_columns?: Array<Pillar_Transcript_Update_Column>;
  where?: InputMaybe<Pillar_Transcript_Bool_Exp>;
};

/** Ordering options when selecting data from "pillar_transcript". */
export type Pillar_Transcript_Order_By = {
  ai_extracted_info?: InputMaybe<Order_By>;
  ai_extracted_info_version?: InputMaybe<Order_By>;
  candidate_email?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_started_at?: InputMaybe<Order_By>;
  pillar_transcript_blob?: InputMaybe<Pillar_Transcript_Blob_Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_job_id?: InputMaybe<Order_By>;
  transcript_job_title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pillar_transcript */
export type Pillar_Transcript_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pillar_Transcript_Prepend_Input = {
  ai_extracted_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "pillar_transcript" */
export enum Pillar_Transcript_Select_Column {
  /** column name */
  AiExtractedInfo = 'ai_extracted_info',
  /** column name */
  AiExtractedInfoVersion = 'ai_extracted_info_version',
  /** column name */
  CandidateEmail = 'candidate_email',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewStartedAt = 'interview_started_at',
  /** column name */
  TranscriptId = 'transcript_id',
  /** column name */
  TranscriptJobId = 'transcript_job_id',
  /** column name */
  TranscriptJobTitle = 'transcript_job_title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "pillar_transcript" */
export type Pillar_Transcript_Set_Input = {
  ai_extracted_info?: InputMaybe<Scalars['jsonb']>;
  ai_extracted_info_version?: InputMaybe<Scalars['String']>;
  candidate_email?: InputMaybe<Scalars['citext']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interview_started_at?: InputMaybe<Scalars['date']>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_job_id?: InputMaybe<Scalars['String']>;
  transcript_job_title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Pillar_Transcript_Stddev_Fields = {
  __typename?: 'pillar_transcript_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pillar_transcript" */
export type Pillar_Transcript_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Pillar_Transcript_Stddev_Pop_Fields = {
  __typename?: 'pillar_transcript_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pillar_transcript" */
export type Pillar_Transcript_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Pillar_Transcript_Stddev_Samp_Fields = {
  __typename?: 'pillar_transcript_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pillar_transcript" */
export type Pillar_Transcript_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "pillar_transcript" */
export type Pillar_Transcript_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pillar_Transcript_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pillar_Transcript_Stream_Cursor_Value_Input = {
  ai_extracted_info?: InputMaybe<Scalars['jsonb']>;
  ai_extracted_info_version?: InputMaybe<Scalars['String']>;
  candidate_email?: InputMaybe<Scalars['citext']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  interview_started_at?: InputMaybe<Scalars['date']>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_job_id?: InputMaybe<Scalars['String']>;
  transcript_job_title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Pillar_Transcript_Sum_Fields = {
  __typename?: 'pillar_transcript_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pillar_transcript" */
export type Pillar_Transcript_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "pillar_transcript" */
export enum Pillar_Transcript_Update_Column {
  /** column name */
  AiExtractedInfo = 'ai_extracted_info',
  /** column name */
  AiExtractedInfoVersion = 'ai_extracted_info_version',
  /** column name */
  CandidateEmail = 'candidate_email',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewStartedAt = 'interview_started_at',
  /** column name */
  TranscriptId = 'transcript_id',
  /** column name */
  TranscriptJobId = 'transcript_job_id',
  /** column name */
  TranscriptJobTitle = 'transcript_job_title',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Pillar_Transcript_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Pillar_Transcript_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Pillar_Transcript_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Pillar_Transcript_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Pillar_Transcript_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Pillar_Transcript_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Pillar_Transcript_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pillar_Transcript_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pillar_Transcript_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Pillar_Transcript_Var_Pop_Fields = {
  __typename?: 'pillar_transcript_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pillar_transcript" */
export type Pillar_Transcript_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Pillar_Transcript_Var_Samp_Fields = {
  __typename?: 'pillar_transcript_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pillar_transcript" */
export type Pillar_Transcript_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Pillar_Transcript_Variance_Fields = {
  __typename?: 'pillar_transcript_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pillar_transcript" */
export type Pillar_Transcript_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "activity_customer_profile_view" */
  activity_customer_profile_view: Array<Activity_Customer_Profile_View>;
  /** fetch aggregated fields from the table: "activity_customer_profile_view" */
  activity_customer_profile_view_aggregate: Activity_Customer_Profile_View_Aggregate;
  /** fetch data from the table: "activity_customer_profile_view" using primary key columns */
  activity_customer_profile_view_by_pk: Maybe<Activity_Customer_Profile_View>;
  /** fetch data from the table: "ai_level" */
  ai_level: Array<Ai_Level>;
  /** fetch aggregated fields from the table: "ai_level" */
  ai_level_aggregate: Ai_Level_Aggregate;
  /** fetch data from the table: "ai_level" using primary key columns */
  ai_level_by_pk: Maybe<Ai_Level>;
  /** fetch data from the table: "ai_role" */
  ai_role: Array<Ai_Role>;
  /** fetch aggregated fields from the table: "ai_role" */
  ai_role_aggregate: Ai_Role_Aggregate;
  /** fetch data from the table: "ai_role" using primary key columns */
  ai_role_by_pk: Maybe<Ai_Role>;
  /** fetch data from the table: "ai_role_multiplier_focus" */
  ai_role_multiplier_focus: Array<Ai_Role_Multiplier_Focus>;
  /** fetch aggregated fields from the table: "ai_role_multiplier_focus" */
  ai_role_multiplier_focus_aggregate: Ai_Role_Multiplier_Focus_Aggregate;
  /** fetch data from the table: "ai_role_multiplier_focus" using primary key columns */
  ai_role_multiplier_focus_by_pk: Maybe<Ai_Role_Multiplier_Focus>;
  /** fetch data from the table: "ai_run_dump" */
  ai_run_dump: Array<Ai_Run_Dump>;
  /** fetch aggregated fields from the table: "ai_run_dump" */
  ai_run_dump_aggregate: Ai_Run_Dump_Aggregate;
  /** fetch data from the table: "ai_run_dump" using primary key columns */
  ai_run_dump_by_pk: Maybe<Ai_Run_Dump>;
  /** fetch data from the table: "applicant_consent" */
  applicant_consent: Array<Applicant_Consent>;
  /** fetch aggregated fields from the table: "applicant_consent" */
  applicant_consent_aggregate: Applicant_Consent_Aggregate;
  /** fetch data from the table: "applicant_consent" using primary key columns */
  applicant_consent_by_pk: Maybe<Applicant_Consent>;
  /** fetch data from the table: "applicant_job" */
  applicant_job: Array<Applicant_Job>;
  /** fetch aggregated fields from the table: "applicant_job" */
  applicant_job_aggregate: Applicant_Job_Aggregate;
  /** fetch data from the table: "applicant_job" using primary key columns */
  applicant_job_by_pk: Maybe<Applicant_Job>;
  /** fetch data from the table: "applicant_waitlist" */
  applicant_waitlist: Array<Applicant_Waitlist>;
  /** fetch aggregated fields from the table: "applicant_waitlist" */
  applicant_waitlist_aggregate: Applicant_Waitlist_Aggregate;
  /** fetch data from the table: "applicant_waitlist" using primary key columns */
  applicant_waitlist_by_pk: Maybe<Applicant_Waitlist>;
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<Audit_Logged_Actions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk: Maybe<Audit_Logged_Actions>;
  /** fetch data from the table: "calendly_event" */
  calendly_event: Array<Calendly_Event>;
  /** fetch aggregated fields from the table: "calendly_event" */
  calendly_event_aggregate: Calendly_Event_Aggregate;
  /** fetch data from the table: "calendly_event" using primary key columns */
  calendly_event_by_pk: Maybe<Calendly_Event>;
  /** fetch data from the table: "calendly_invitee" */
  calendly_invitee: Array<Calendly_Invitee>;
  /** fetch aggregated fields from the table: "calendly_invitee" */
  calendly_invitee_aggregate: Calendly_Invitee_Aggregate;
  /** fetch data from the table: "calendly_invitee" using primary key columns */
  calendly_invitee_by_pk: Maybe<Calendly_Invitee>;
  /** fetch data from the table: "calendly_user" */
  calendly_user: Array<Calendly_User>;
  /** fetch aggregated fields from the table: "calendly_user" */
  calendly_user_aggregate: Calendly_User_Aggregate;
  /** fetch data from the table: "calendly_user" using primary key columns */
  calendly_user_by_pk: Maybe<Calendly_User>;
  /** fetch data from the table: "candidate" */
  candidate: Array<Candidate>;
  /** fetch aggregated fields from the table: "candidate" */
  candidate_aggregate: Candidate_Aggregate;
  /** fetch data from the table: "candidate_ai_profile_summary" */
  candidate_ai_profile_summary: Array<Candidate_Ai_Profile_Summary>;
  /** fetch aggregated fields from the table: "candidate_ai_profile_summary" */
  candidate_ai_profile_summary_aggregate: Candidate_Ai_Profile_Summary_Aggregate;
  /** fetch data from the table: "candidate_ai_profile_summary" using primary key columns */
  candidate_ai_profile_summary_by_pk: Maybe<Candidate_Ai_Profile_Summary>;
  /** execute function "candidate_applicant_workflow_stats" which returns "candidate_applicant_workflow_stats_result" */
  candidate_applicant_workflow_stats: Array<Candidate_Applicant_Workflow_Stats_Result>;
  /** execute function "candidate_applicant_workflow_stats" and query aggregates on result of table type "candidate_applicant_workflow_stats_result" */
  candidate_applicant_workflow_stats_aggregate: Candidate_Applicant_Workflow_Stats_Result_Aggregate;
  /** fetch data from the table: "candidate_applicant_workflow_stats_result" */
  candidate_applicant_workflow_stats_result: Array<Candidate_Applicant_Workflow_Stats_Result>;
  /** fetch aggregated fields from the table: "candidate_applicant_workflow_stats_result" */
  candidate_applicant_workflow_stats_result_aggregate: Candidate_Applicant_Workflow_Stats_Result_Aggregate;
  /** fetch data from the table: "candidate_approved_country" */
  candidate_approved_country: Array<Candidate_Approved_Country>;
  /** fetch aggregated fields from the table: "candidate_approved_country" */
  candidate_approved_country_aggregate: Candidate_Approved_Country_Aggregate;
  /** fetch data from the table: "candidate_approved_country" using primary key columns */
  candidate_approved_country_by_pk: Maybe<Candidate_Approved_Country>;
  /** fetch data from the table: "candidate_availability_choices" */
  candidate_availability_choices: Array<Candidate_Availability_Choices>;
  /** fetch aggregated fields from the table: "candidate_availability_choices" */
  candidate_availability_choices_aggregate: Candidate_Availability_Choices_Aggregate;
  /** fetch data from the table: "candidate_availability_choices" using primary key columns */
  candidate_availability_choices_by_pk: Maybe<Candidate_Availability_Choices>;
  /** fetch data from the table: "candidate_availability_choices_order" */
  candidate_availability_choices_order: Array<Candidate_Availability_Choices_Order>;
  /** fetch aggregated fields from the table: "candidate_availability_choices_order" */
  candidate_availability_choices_order_aggregate: Candidate_Availability_Choices_Order_Aggregate;
  /** fetch data from the table: "candidate_availability_choices_order" using primary key columns */
  candidate_availability_choices_order_by_pk: Maybe<Candidate_Availability_Choices_Order>;
  /** fetch data from the table: "candidate_badge" */
  candidate_badge: Array<Candidate_Badge>;
  /** fetch aggregated fields from the table: "candidate_badge" */
  candidate_badge_aggregate: Candidate_Badge_Aggregate;
  /** fetch data from the table: "candidate_badge" using primary key columns */
  candidate_badge_by_pk: Maybe<Candidate_Badge>;
  /** execute function "candidate_browse" which returns "candidate_browse_result" */
  candidate_browse: Array<Candidate_Browse_Result>;
  /** execute function "candidate_browse" and query aggregates on result of table type "candidate_browse_result" */
  candidate_browse_aggregate: Candidate_Browse_Result_Aggregate;
  /** fetch data from the table: "candidate_browse_result" */
  candidate_browse_result: Array<Candidate_Browse_Result>;
  /** fetch aggregated fields from the table: "candidate_browse_result" */
  candidate_browse_result_aggregate: Candidate_Browse_Result_Aggregate;
  /** fetch data from the table: "candidate_browse_result" using primary key columns */
  candidate_browse_result_by_pk: Maybe<Candidate_Browse_Result>;
  /** fetch data from the table: "candidate" using primary key columns */
  candidate_by_pk: Maybe<Candidate>;
  /** fetch data from the table: "candidate_curation_ai_summary" */
  candidate_curation_ai_summary: Array<Candidate_Curation_Ai_Summary>;
  /** fetch aggregated fields from the table: "candidate_curation_ai_summary" */
  candidate_curation_ai_summary_aggregate: Candidate_Curation_Ai_Summary_Aggregate;
  /** fetch data from the table: "candidate_curation_ai_summary" using primary key columns */
  candidate_curation_ai_summary_by_pk: Maybe<Candidate_Curation_Ai_Summary>;
  /** fetch data from the table: "candidate_curation_ai_summary_feedback" */
  candidate_curation_ai_summary_feedback: Array<Candidate_Curation_Ai_Summary_Feedback>;
  /** fetch aggregated fields from the table: "candidate_curation_ai_summary_feedback" */
  candidate_curation_ai_summary_feedback_aggregate: Candidate_Curation_Ai_Summary_Feedback_Aggregate;
  /** fetch data from the table: "candidate_curation_ai_summary_feedback" using primary key columns */
  candidate_curation_ai_summary_feedback_by_pk: Maybe<Candidate_Curation_Ai_Summary_Feedback>;
  /** fetch data from the table: "candidate_curation_country_choices" */
  candidate_curation_country_choices: Array<Candidate_Curation_Country_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_country_choices" */
  candidate_curation_country_choices_aggregate: Candidate_Curation_Country_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_country_choices" using primary key columns */
  candidate_curation_country_choices_by_pk: Maybe<Candidate_Curation_Country_Choices>;
  /** fetch data from the table: "candidate_curation_desired_salary_currency_choices" */
  candidate_curation_desired_salary_currency_choices: Array<Candidate_Curation_Desired_Salary_Currency_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_desired_salary_currency_choices" */
  candidate_curation_desired_salary_currency_choices_aggregate: Candidate_Curation_Desired_Salary_Currency_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_desired_salary_currency_choices" using primary key columns */
  candidate_curation_desired_salary_currency_choices_by_pk: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices>;
  /** fetch data from the table: "candidate_curation_desired_salary_frequency_choices" */
  candidate_curation_desired_salary_frequency_choices: Array<Candidate_Curation_Desired_Salary_Frequency_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_desired_salary_frequency_choices" */
  candidate_curation_desired_salary_frequency_choices_aggregate: Candidate_Curation_Desired_Salary_Frequency_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_desired_salary_frequency_choices" using primary key columns */
  candidate_curation_desired_salary_frequency_choices_by_pk: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices>;
  /** fetch data from the table: "candidate_curation_detail" */
  candidate_curation_detail: Array<Candidate_Curation_Detail>;
  /** fetch aggregated fields from the table: "candidate_curation_detail" */
  candidate_curation_detail_aggregate: Candidate_Curation_Detail_Aggregate;
  /** fetch data from the table: "candidate_curation_detail" using primary key columns */
  candidate_curation_detail_by_pk: Maybe<Candidate_Curation_Detail>;
  /** fetch data from the table: "candidate_curation_detail_recruiter_summary_log" */
  candidate_curation_detail_recruiter_summary_log: Array<Candidate_Curation_Detail_Recruiter_Summary_Log>;
  /** fetch aggregated fields from the table: "candidate_curation_detail_recruiter_summary_log" */
  candidate_curation_detail_recruiter_summary_log_aggregate: Candidate_Curation_Detail_Recruiter_Summary_Log_Aggregate;
  /** fetch data from the table: "candidate_curation_detail_recruiter_summary_log" using primary key columns */
  candidate_curation_detail_recruiter_summary_log_by_pk: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log>;
  /** fetch data from the table: "candidate_curation_english_proficiency_choices" */
  candidate_curation_english_proficiency_choices: Array<Candidate_Curation_English_Proficiency_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_english_proficiency_choices" */
  candidate_curation_english_proficiency_choices_aggregate: Candidate_Curation_English_Proficiency_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_english_proficiency_choices" using primary key columns */
  candidate_curation_english_proficiency_choices_by_pk: Maybe<Candidate_Curation_English_Proficiency_Choices>;
  /** fetch data from the table: "candidate_curation_equity_importance_choices" */
  candidate_curation_equity_importance_choices: Array<Candidate_Curation_Equity_Importance_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_equity_importance_choices" */
  candidate_curation_equity_importance_choices_aggregate: Candidate_Curation_Equity_Importance_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_equity_importance_choices" using primary key columns */
  candidate_curation_equity_importance_choices_by_pk: Maybe<Candidate_Curation_Equity_Importance_Choices>;
  /** fetch data from the table: "candidate_curation_note" */
  candidate_curation_note: Array<Candidate_Curation_Note>;
  /** fetch aggregated fields from the table: "candidate_curation_note" */
  candidate_curation_note_aggregate: Candidate_Curation_Note_Aggregate;
  /** fetch data from the table: "candidate_curation_note" using primary key columns */
  candidate_curation_note_by_pk: Maybe<Candidate_Curation_Note>;
  /** fetch data from the table: "candidate_curation_rejected_reason_choices" */
  candidate_curation_rejected_reason_choices: Array<Candidate_Curation_Rejected_Reason_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_rejected_reason_choices" */
  candidate_curation_rejected_reason_choices_aggregate: Candidate_Curation_Rejected_Reason_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_rejected_reason_choices" using primary key columns */
  candidate_curation_rejected_reason_choices_by_pk: Maybe<Candidate_Curation_Rejected_Reason_Choices>;
  /** fetch data from the table: "candidate_curation_remote_work_exp_choices" */
  candidate_curation_remote_work_exp_choices: Array<Candidate_Curation_Remote_Work_Exp_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_remote_work_exp_choices" */
  candidate_curation_remote_work_exp_choices_aggregate: Candidate_Curation_Remote_Work_Exp_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_remote_work_exp_choices" using primary key columns */
  candidate_curation_remote_work_exp_choices_by_pk: Maybe<Candidate_Curation_Remote_Work_Exp_Choices>;
  /** fetch data from the table: "candidate_curation_visa_status_choices" */
  candidate_curation_visa_status_choices: Array<Candidate_Curation_Visa_Status_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_visa_status_choices" */
  candidate_curation_visa_status_choices_aggregate: Candidate_Curation_Visa_Status_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_visa_status_choices" using primary key columns */
  candidate_curation_visa_status_choices_by_pk: Maybe<Candidate_Curation_Visa_Status_Choices>;
  /** fetch data from the table: "candidate_curation_workflow" */
  candidate_curation_workflow: Array<Candidate_Curation_Workflow>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow" */
  candidate_curation_workflow_aggregate: Candidate_Curation_Workflow_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow" using primary key columns */
  candidate_curation_workflow_by_pk: Maybe<Candidate_Curation_Workflow>;
  /** fetch data from the table: "candidate_curation_workflow_needs_info_reason" */
  candidate_curation_workflow_needs_info_reason: Array<Candidate_Curation_Workflow_Needs_Info_Reason>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_needs_info_reason" */
  candidate_curation_workflow_needs_info_reason_aggregate: Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_needs_info_reason" using primary key columns */
  candidate_curation_workflow_needs_info_reason_by_pk: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason>;
  /** fetch data from the table: "candidate_curation_workflow_needs_info_reason_choices" */
  candidate_curation_workflow_needs_info_reason_choices: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_needs_info_reason_choices" */
  candidate_curation_workflow_needs_info_reason_choices_aggregate: Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_needs_info_reason_choices" using primary key columns */
  candidate_curation_workflow_needs_info_reason_choices_by_pk: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices>;
  /** fetch data from the table: "candidate_curation_workflow_screener_type_choices" */
  candidate_curation_workflow_screener_type_choices: Array<Candidate_Curation_Workflow_Screener_Type_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_screener_type_choices" */
  candidate_curation_workflow_screener_type_choices_aggregate: Candidate_Curation_Workflow_Screener_Type_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_screener_type_choices" using primary key columns */
  candidate_curation_workflow_screener_type_choices_by_pk: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices>;
  /** fetch data from the table: "candidate_curation_workflow_source_choices" */
  candidate_curation_workflow_source_choices: Array<Candidate_Curation_Workflow_Source_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_source_choices" */
  candidate_curation_workflow_source_choices_aggregate: Candidate_Curation_Workflow_Source_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_source_choices" using primary key columns */
  candidate_curation_workflow_source_choices_by_pk: Maybe<Candidate_Curation_Workflow_Source_Choices>;
  /** fetch data from the table: "candidate_curation_workflow_status_choices" */
  candidate_curation_workflow_status_choices: Array<Candidate_Curation_Workflow_Status_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_status_choices" */
  candidate_curation_workflow_status_choices_aggregate: Candidate_Curation_Workflow_Status_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_status_choices" using primary key columns */
  candidate_curation_workflow_status_choices_by_pk: Maybe<Candidate_Curation_Workflow_Status_Choices>;
  /** fetch data from the table: "candidate_curation_workflow_status_log" */
  candidate_curation_workflow_status_log: Array<Candidate_Curation_Workflow_Status_Log>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_status_log" */
  candidate_curation_workflow_status_log_aggregate: Candidate_Curation_Workflow_Status_Log_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_status_log" using primary key columns */
  candidate_curation_workflow_status_log_by_pk: Maybe<Candidate_Curation_Workflow_Status_Log>;
  /** fetch data from the table: "candidate_curation_years_of_exp_range_choices" */
  candidate_curation_years_of_exp_range_choices: Array<Candidate_Curation_Years_Of_Exp_Range_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_years_of_exp_range_choices" */
  candidate_curation_years_of_exp_range_choices_aggregate: Candidate_Curation_Years_Of_Exp_Range_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_years_of_exp_range_choices" using primary key columns */
  candidate_curation_years_of_exp_range_choices_by_pk: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices>;
  /** fetch data from the table: "candidate_curation_years_of_exp_range_choices_order" */
  candidate_curation_years_of_exp_range_choices_order: Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order>;
  /** fetch aggregated fields from the table: "candidate_curation_years_of_exp_range_choices_order" */
  candidate_curation_years_of_exp_range_choices_order_aggregate: Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Aggregate;
  /** fetch data from the table: "candidate_curation_years_of_exp_range_choices_order" using primary key columns */
  candidate_curation_years_of_exp_range_choices_order_by_pk: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order>;
  /** fetch data from the table: "candidate_detail_level_choices" */
  candidate_detail_level_choices: Array<Candidate_Detail_Level_Choices>;
  /** fetch aggregated fields from the table: "candidate_detail_level_choices" */
  candidate_detail_level_choices_aggregate: Candidate_Detail_Level_Choices_Aggregate;
  /** fetch data from the table: "candidate_detail_level_choices" using primary key columns */
  candidate_detail_level_choices_by_pk: Maybe<Candidate_Detail_Level_Choices>;
  /** fetch data from the table: "candidate_education" */
  candidate_education: Array<Candidate_Education>;
  /** fetch aggregated fields from the table: "candidate_education" */
  candidate_education_aggregate: Candidate_Education_Aggregate;
  /** fetch data from the table: "candidate_education" using primary key columns */
  candidate_education_by_pk: Maybe<Candidate_Education>;
  /** fetch data from the table: "candidate_education_concentration_choices" */
  candidate_education_concentration_choices: Array<Candidate_Education_Concentration_Choices>;
  /** fetch aggregated fields from the table: "candidate_education_concentration_choices" */
  candidate_education_concentration_choices_aggregate: Candidate_Education_Concentration_Choices_Aggregate;
  /** fetch data from the table: "candidate_education_concentration_choices" using primary key columns */
  candidate_education_concentration_choices_by_pk: Maybe<Candidate_Education_Concentration_Choices>;
  /** fetch data from the table: "candidate_education_degree_choices" */
  candidate_education_degree_choices: Array<Candidate_Education_Degree_Choices>;
  /** fetch aggregated fields from the table: "candidate_education_degree_choices" */
  candidate_education_degree_choices_aggregate: Candidate_Education_Degree_Choices_Aggregate;
  /** fetch data from the table: "candidate_education_degree_choices" using primary key columns */
  candidate_education_degree_choices_by_pk: Maybe<Candidate_Education_Degree_Choices>;
  /** fetch data from the table: "candidate_email" */
  candidate_email: Array<Candidate_Email>;
  /** fetch aggregated fields from the table: "candidate_email" */
  candidate_email_aggregate: Candidate_Email_Aggregate;
  /** fetch data from the table: "candidate_email" using primary key columns */
  candidate_email_by_pk: Maybe<Candidate_Email>;
  /** fetch data from the table: "candidate_embedding" */
  candidate_embedding: Array<Candidate_Embedding>;
  /** fetch aggregated fields from the table: "candidate_embedding" */
  candidate_embedding_aggregate: Candidate_Embedding_Aggregate;
  /** fetch data from the table: "candidate_embedding" using primary key columns */
  candidate_embedding_by_pk: Maybe<Candidate_Embedding>;
  /** fetch data from the table: "candidate_highlight_choices" */
  candidate_highlight_choices: Array<Candidate_Highlight_Choices>;
  /** fetch aggregated fields from the table: "candidate_highlight_choices" */
  candidate_highlight_choices_aggregate: Candidate_Highlight_Choices_Aggregate;
  /** fetch data from the table: "candidate_highlight_choices" using primary key columns */
  candidate_highlight_choices_by_pk: Maybe<Candidate_Highlight_Choices>;
  /** fetch data from the table: "candidate_hub_location" */
  candidate_hub_location: Array<Candidate_Hub_Location>;
  /** fetch aggregated fields from the table: "candidate_hub_location" */
  candidate_hub_location_aggregate: Candidate_Hub_Location_Aggregate;
  /** fetch data from the table: "candidate_hub_location" using primary key columns */
  candidate_hub_location_by_pk: Maybe<Candidate_Hub_Location>;
  /** fetch data from the table: "candidate_interview_invite" */
  candidate_interview_invite: Array<Candidate_Interview_Invite>;
  /** fetch aggregated fields from the table: "candidate_interview_invite" */
  candidate_interview_invite_aggregate: Candidate_Interview_Invite_Aggregate;
  /** fetch data from the table: "candidate_interview_invite" using primary key columns */
  candidate_interview_invite_by_pk: Maybe<Candidate_Interview_Invite>;
  /** fetch data from the table: "candidate_job" */
  candidate_job: Array<Candidate_Job>;
  /** fetch aggregated fields from the table: "candidate_job" */
  candidate_job_aggregate: Candidate_Job_Aggregate;
  /** fetch data from the table: "candidate_job" using primary key columns */
  candidate_job_by_pk: Maybe<Candidate_Job>;
  /** fetch data from the table: "candidate_job_workflow" */
  candidate_job_workflow: Array<Candidate_Job_Workflow>;
  /** fetch aggregated fields from the table: "candidate_job_workflow" */
  candidate_job_workflow_aggregate: Candidate_Job_Workflow_Aggregate;
  /** fetch data from the table: "candidate_job_workflow" using primary key columns */
  candidate_job_workflow_by_pk: Maybe<Candidate_Job_Workflow>;
  /** fetch data from the table: "candidate_job_workflow_source_choices" */
  candidate_job_workflow_source_choices: Array<Candidate_Job_Workflow_Source_Choices>;
  /** fetch aggregated fields from the table: "candidate_job_workflow_source_choices" */
  candidate_job_workflow_source_choices_aggregate: Candidate_Job_Workflow_Source_Choices_Aggregate;
  /** fetch data from the table: "candidate_job_workflow_source_choices" using primary key columns */
  candidate_job_workflow_source_choices_by_pk: Maybe<Candidate_Job_Workflow_Source_Choices>;
  /** fetch data from the table: "candidate_job_workflow_status" */
  candidate_job_workflow_status: Array<Candidate_Job_Workflow_Status>;
  /** fetch aggregated fields from the table: "candidate_job_workflow_status" */
  candidate_job_workflow_status_aggregate: Candidate_Job_Workflow_Status_Aggregate;
  /** fetch data from the table: "candidate_job_workflow_status" using primary key columns */
  candidate_job_workflow_status_by_pk: Maybe<Candidate_Job_Workflow_Status>;
  /** fetch data from the table: "candidate_job_workflow_status_choices" */
  candidate_job_workflow_status_choices: Array<Candidate_Job_Workflow_Status_Choices>;
  /** fetch aggregated fields from the table: "candidate_job_workflow_status_choices" */
  candidate_job_workflow_status_choices_aggregate: Candidate_Job_Workflow_Status_Choices_Aggregate;
  /** fetch data from the table: "candidate_job_workflow_status_choices" using primary key columns */
  candidate_job_workflow_status_choices_by_pk: Maybe<Candidate_Job_Workflow_Status_Choices>;
  /** fetch data from the table: "candidate_parsed_resume" */
  candidate_parsed_resume: Array<Candidate_Parsed_Resume>;
  /** fetch aggregated fields from the table: "candidate_parsed_resume" */
  candidate_parsed_resume_aggregate: Candidate_Parsed_Resume_Aggregate;
  /** fetch data from the table: "candidate_parsed_resume" using primary key columns */
  candidate_parsed_resume_by_pk: Maybe<Candidate_Parsed_Resume>;
  /** fetch data from the table: "candidate_pdl" */
  candidate_pdl: Array<Candidate_Pdl>;
  /** fetch aggregated fields from the table: "candidate_pdl" */
  candidate_pdl_aggregate: Candidate_Pdl_Aggregate;
  /** fetch data from the table: "candidate_pdl" using primary key columns */
  candidate_pdl_by_pk: Maybe<Candidate_Pdl>;
  /** fetch data from the table: "candidate_referrals" */
  candidate_referrals: Array<Candidate_Referrals>;
  /** fetch aggregated fields from the table: "candidate_referrals" */
  candidate_referrals_aggregate: Candidate_Referrals_Aggregate;
  /** fetch data from the table: "candidate_referrals" using primary key columns */
  candidate_referrals_by_pk: Maybe<Candidate_Referrals>;
  /** fetch data from the table: "candidate_role" */
  candidate_role: Array<Candidate_Role>;
  /** fetch aggregated fields from the table: "candidate_role" */
  candidate_role_aggregate: Candidate_Role_Aggregate;
  /** fetch data from the table: "candidate_role" using primary key columns */
  candidate_role_by_pk: Maybe<Candidate_Role>;
  /** fetch data from the table: "candidate_role_skill" */
  candidate_role_skill: Array<Candidate_Role_Skill>;
  /** fetch aggregated fields from the table: "candidate_role_skill" */
  candidate_role_skill_aggregate: Candidate_Role_Skill_Aggregate;
  /** fetch data from the table: "candidate_role_skill" using primary key columns */
  candidate_role_skill_by_pk: Maybe<Candidate_Role_Skill>;
  /** fetch data from the table: "candidate_sdlc_experience_choices" */
  candidate_sdlc_experience_choices: Array<Candidate_Sdlc_Experience_Choices>;
  /** fetch aggregated fields from the table: "candidate_sdlc_experience_choices" */
  candidate_sdlc_experience_choices_aggregate: Candidate_Sdlc_Experience_Choices_Aggregate;
  /** fetch data from the table: "candidate_sdlc_experience_choices" using primary key columns */
  candidate_sdlc_experience_choices_by_pk: Maybe<Candidate_Sdlc_Experience_Choices>;
  /** fetch data from the table: "candidate_skill" */
  candidate_skill: Array<Candidate_Skill>;
  /** fetch aggregated fields from the table: "candidate_skill" */
  candidate_skill_aggregate: Candidate_Skill_Aggregate;
  /** fetch data from the table: "candidate_skill" using primary key columns */
  candidate_skill_by_pk: Maybe<Candidate_Skill>;
  /** fetch data from the table: "candidate_skill_comp_choices" */
  candidate_skill_comp_choices: Array<Candidate_Skill_Comp_Choices>;
  /** fetch aggregated fields from the table: "candidate_skill_comp_choices" */
  candidate_skill_comp_choices_aggregate: Candidate_Skill_Comp_Choices_Aggregate;
  /** fetch data from the table: "candidate_skill_comp_choices" using primary key columns */
  candidate_skill_comp_choices_by_pk: Maybe<Candidate_Skill_Comp_Choices>;
  /** fetch data from the table: "candidate_source_choices" */
  candidate_source_choices: Array<Candidate_Source_Choices>;
  /** fetch aggregated fields from the table: "candidate_source_choices" */
  candidate_source_choices_aggregate: Candidate_Source_Choices_Aggregate;
  /** fetch data from the table: "candidate_source_choices" using primary key columns */
  candidate_source_choices_by_pk: Maybe<Candidate_Source_Choices>;
  /** fetch data from the table: "candidate_stage_choices" */
  candidate_stage_choices: Array<Candidate_Stage_Choices>;
  /** fetch aggregated fields from the table: "candidate_stage_choices" */
  candidate_stage_choices_aggregate: Candidate_Stage_Choices_Aggregate;
  /** fetch data from the table: "candidate_stage_choices" using primary key columns */
  candidate_stage_choices_by_pk: Maybe<Candidate_Stage_Choices>;
  /** fetch data from the table: "candidate_stage_choices_order" */
  candidate_stage_choices_order: Array<Candidate_Stage_Choices_Order>;
  /** fetch aggregated fields from the table: "candidate_stage_choices_order" */
  candidate_stage_choices_order_aggregate: Candidate_Stage_Choices_Order_Aggregate;
  /** fetch data from the table: "candidate_stage_choices_order" using primary key columns */
  candidate_stage_choices_order_by_pk: Maybe<Candidate_Stage_Choices_Order>;
  /** fetch data from the table: "candidate_stage_log" */
  candidate_stage_log: Array<Candidate_Stage_Log>;
  /** fetch aggregated fields from the table: "candidate_stage_log" */
  candidate_stage_log_aggregate: Candidate_Stage_Log_Aggregate;
  /** fetch data from the table: "candidate_stage_log" using primary key columns */
  candidate_stage_log_by_pk: Maybe<Candidate_Stage_Log>;
  /** fetch data from the table: "candidate_stage_source_choices" */
  candidate_stage_source_choices: Array<Candidate_Stage_Source_Choices>;
  /** fetch aggregated fields from the table: "candidate_stage_source_choices" */
  candidate_stage_source_choices_aggregate: Candidate_Stage_Source_Choices_Aggregate;
  /** fetch data from the table: "candidate_stage_source_choices" using primary key columns */
  candidate_stage_source_choices_by_pk: Maybe<Candidate_Stage_Source_Choices>;
  /** execute function "candidate_suggested_job" which returns "icims_job" */
  candidate_suggested_job: Array<Icims_Job>;
  /** execute function "candidate_suggested_job" and query aggregates on result of table type "icims_job" */
  candidate_suggested_job_aggregate: Icims_Job_Aggregate;
  /** execute function "candidate_suggested_job_core" which returns "icims_job" */
  candidate_suggested_job_core: Array<Icims_Job>;
  /** execute function "candidate_suggested_job_core" and query aggregates on result of table type "icims_job" */
  candidate_suggested_job_core_aggregate: Icims_Job_Aggregate;
  /** fetch data from the table: "candidate_trait" */
  candidate_trait: Array<Candidate_Trait>;
  /** fetch aggregated fields from the table: "candidate_trait" */
  candidate_trait_aggregate: Candidate_Trait_Aggregate;
  /** fetch data from the table: "candidate_trait" using primary key columns */
  candidate_trait_by_pk: Maybe<Candidate_Trait>;
  /** fetch data from the table: "candidate_work_experience" */
  candidate_work_experience: Array<Candidate_Work_Experience>;
  /** fetch aggregated fields from the table: "candidate_work_experience" */
  candidate_work_experience_aggregate: Candidate_Work_Experience_Aggregate;
  /** fetch data from the table: "candidate_work_experience" using primary key columns */
  candidate_work_experience_by_pk: Maybe<Candidate_Work_Experience>;
  /** fetch data from the table: "candidate_work_experience_skill" */
  candidate_work_experience_skill: Array<Candidate_Work_Experience_Skill>;
  /** fetch aggregated fields from the table: "candidate_work_experience_skill" */
  candidate_work_experience_skill_aggregate: Candidate_Work_Experience_Skill_Aggregate;
  /** fetch data from the table: "candidate_work_experience_skill" using primary key columns */
  candidate_work_experience_skill_by_pk: Maybe<Candidate_Work_Experience_Skill>;
  /** execute function "candidates_in_interview_or_rejected_by_organization" which returns "candidates_in_interview_or_rejected_by_organization_result" */
  candidates_in_interview_or_rejected_by_organization: Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result>;
  /** execute function "candidates_in_interview_or_rejected_by_organization" and query aggregates on result of table type "candidates_in_interview_or_rejected_by_organization_result" */
  candidates_in_interview_or_rejected_by_organization_aggregate: Candidates_In_Interview_Or_Rejected_By_Organization_Result_Aggregate;
  /** fetch data from the table: "candidates_in_interview_or_rejected_by_organization_result" */
  candidates_in_interview_or_rejected_by_organization_result: Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result>;
  /** fetch aggregated fields from the table: "candidates_in_interview_or_rejected_by_organization_result" */
  candidates_in_interview_or_rejected_by_organization_result_aggregate: Candidates_In_Interview_Or_Rejected_By_Organization_Result_Aggregate;
  /** fetch data from the table: "celery.celery_taskmeta" */
  celery_celery_taskmeta: Array<Celery_Celery_Taskmeta>;
  /** fetch aggregated fields from the table: "celery.celery_taskmeta" */
  celery_celery_taskmeta_aggregate: Celery_Celery_Taskmeta_Aggregate;
  /** fetch data from the table: "celery.celery_taskmeta" using primary key columns */
  celery_celery_taskmeta_by_pk: Maybe<Celery_Celery_Taskmeta>;
  /** fetch data from the table: "celery.celery_tasksetmeta" */
  celery_celery_tasksetmeta: Array<Celery_Celery_Tasksetmeta>;
  /** fetch aggregated fields from the table: "celery.celery_tasksetmeta" */
  celery_celery_tasksetmeta_aggregate: Celery_Celery_Tasksetmeta_Aggregate;
  /** fetch data from the table: "celery.celery_tasksetmeta" using primary key columns */
  celery_celery_tasksetmeta_by_pk: Maybe<Celery_Celery_Tasksetmeta>;
  /** fetch data from the table: "celery.task_retry_log" */
  celery_task_retry_log: Array<Celery_Task_Retry_Log>;
  /** fetch aggregated fields from the table: "celery.task_retry_log" */
  celery_task_retry_log_aggregate: Celery_Task_Retry_Log_Aggregate;
  /** fetch data from the table: "celery.task_retry_log" using primary key columns */
  celery_task_retry_log_by_pk: Maybe<Celery_Task_Retry_Log>;
  /** fetch data from the table: "client_prospect" */
  client_prospect: Array<Client_Prospect>;
  /** fetch aggregated fields from the table: "client_prospect" */
  client_prospect_aggregate: Client_Prospect_Aggregate;
  /** fetch data from the table: "client_prospect" using primary key columns */
  client_prospect_by_pk: Maybe<Client_Prospect>;
  /** fetch data from the table: "client_prospect_event" */
  client_prospect_event: Array<Client_Prospect_Event>;
  /** fetch aggregated fields from the table: "client_prospect_event" */
  client_prospect_event_aggregate: Client_Prospect_Event_Aggregate;
  /** fetch data from the table: "client_prospect_event" using primary key columns */
  client_prospect_event_by_pk: Maybe<Client_Prospect_Event>;
  /** fetch data from the table: "client_prospect_events_choices" */
  client_prospect_events_choices: Array<Client_Prospect_Events_Choices>;
  /** fetch aggregated fields from the table: "client_prospect_events_choices" */
  client_prospect_events_choices_aggregate: Client_Prospect_Events_Choices_Aggregate;
  /** fetch data from the table: "client_prospect_events_choices" using primary key columns */
  client_prospect_events_choices_by_pk: Maybe<Client_Prospect_Events_Choices>;
  /** fetch data from the table: "client_prospect_role" */
  client_prospect_role: Array<Client_Prospect_Role>;
  /** fetch aggregated fields from the table: "client_prospect_role" */
  client_prospect_role_aggregate: Client_Prospect_Role_Aggregate;
  /** fetch data from the table: "client_prospect_role" using primary key columns */
  client_prospect_role_by_pk: Maybe<Client_Prospect_Role>;
  /** fetch data from the table: "client_prospect_role_skill" */
  client_prospect_role_skill: Array<Client_Prospect_Role_Skill>;
  /** fetch aggregated fields from the table: "client_prospect_role_skill" */
  client_prospect_role_skill_aggregate: Client_Prospect_Role_Skill_Aggregate;
  /** fetch data from the table: "client_prospect_role_skill" using primary key columns */
  client_prospect_role_skill_by_pk: Maybe<Client_Prospect_Role_Skill>;
  /** fetch data from the table: "client_versions" */
  client_versions: Array<Client_Versions>;
  /** fetch aggregated fields from the table: "client_versions" */
  client_versions_aggregate: Client_Versions_Aggregate;
  /** fetch data from the table: "client_versions" using primary key columns */
  client_versions_by_pk: Maybe<Client_Versions>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk: Maybe<Company>;
  /** fetch data from the table: "company_name_synonym" */
  company_name_synonym: Array<Company_Name_Synonym>;
  /** fetch aggregated fields from the table: "company_name_synonym" */
  company_name_synonym_aggregate: Company_Name_Synonym_Aggregate;
  /** fetch data from the table: "company_name_synonym" using primary key columns */
  company_name_synonym_by_pk: Maybe<Company_Name_Synonym>;
  /** fetch data from the table: "company_pdl_blob" */
  company_pdl_blob: Array<Company_Pdl_Blob>;
  /** fetch aggregated fields from the table: "company_pdl_blob" */
  company_pdl_blob_aggregate: Company_Pdl_Blob_Aggregate;
  /** fetch data from the table: "company_pdl_blob" using primary key columns */
  company_pdl_blob_by_pk: Maybe<Company_Pdl_Blob>;
  /** fetch data from the table: "company_size_choices" */
  company_size_choices: Array<Company_Size_Choices>;
  /** fetch aggregated fields from the table: "company_size_choices" */
  company_size_choices_aggregate: Company_Size_Choices_Aggregate;
  /** fetch data from the table: "company_size_choices" using primary key columns */
  company_size_choices_by_pk: Maybe<Company_Size_Choices>;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk: Maybe<Contact>;
  /** fetch data from the table: "contract_length_choices" */
  contract_length_choices: Array<Contract_Length_Choices>;
  /** fetch aggregated fields from the table: "contract_length_choices" */
  contract_length_choices_aggregate: Contract_Length_Choices_Aggregate;
  /** fetch data from the table: "contract_length_choices" using primary key columns */
  contract_length_choices_by_pk: Maybe<Contract_Length_Choices>;
  /** fetch data from the table: "country_choices" */
  country_choices: Array<Country_Choices>;
  /** fetch aggregated fields from the table: "country_choices" */
  country_choices_aggregate: Country_Choices_Aggregate;
  /** fetch data from the table: "country_choices" using primary key columns */
  country_choices_by_pk: Maybe<Country_Choices>;
  /** fetch data from the table: "currency_exchange_rate" */
  currency_exchange_rate: Array<Currency_Exchange_Rate>;
  /** fetch aggregated fields from the table: "currency_exchange_rate" */
  currency_exchange_rate_aggregate: Currency_Exchange_Rate_Aggregate;
  /** fetch data from the table: "currency_exchange_rate" using primary key columns */
  currency_exchange_rate_by_pk: Maybe<Currency_Exchange_Rate>;
  /** fetch data from the table: "dayforce_assignment" */
  dayforce_assignment: Array<Dayforce_Assignment>;
  /** fetch aggregated fields from the table: "dayforce_assignment" */
  dayforce_assignment_aggregate: Dayforce_Assignment_Aggregate;
  /** fetch data from the table: "dayforce_assignment" using primary key columns */
  dayforce_assignment_by_pk: Maybe<Dayforce_Assignment>;
  /** fetch data from the table: "dayforce_customer" */
  dayforce_customer: Array<Dayforce_Customer>;
  /** fetch aggregated fields from the table: "dayforce_customer" */
  dayforce_customer_aggregate: Dayforce_Customer_Aggregate;
  /** fetch data from the table: "dayforce_customer" using primary key columns */
  dayforce_customer_by_pk: Maybe<Dayforce_Customer>;
  /** fetch data from the table: "dayforce_employee" */
  dayforce_employee: Array<Dayforce_Employee>;
  /** fetch aggregated fields from the table: "dayforce_employee" */
  dayforce_employee_aggregate: Dayforce_Employee_Aggregate;
  /** fetch data from the table: "dayforce_employee" using primary key columns */
  dayforce_employee_by_pk: Maybe<Dayforce_Employee>;
  /** fetch data from the table: "dayforce_location" */
  dayforce_location: Array<Dayforce_Location>;
  /** fetch aggregated fields from the table: "dayforce_location" */
  dayforce_location_aggregate: Dayforce_Location_Aggregate;
  /** fetch data from the table: "dayforce_location" using primary key columns */
  dayforce_location_by_pk: Maybe<Dayforce_Location>;
  /** fetch data from the table: "email_notification" */
  email_notification: Array<Email_Notification>;
  /** fetch aggregated fields from the table: "email_notification" */
  email_notification_aggregate: Email_Notification_Aggregate;
  /** fetch data from the table: "email_notification" using primary key columns */
  email_notification_by_pk: Maybe<Email_Notification>;
  /** fetch data from the table: "email_template" */
  email_template: Array<Email_Template>;
  /** fetch aggregated fields from the table: "email_template" */
  email_template_aggregate: Email_Template_Aggregate;
  /** fetch data from the table: "email_template" using primary key columns */
  email_template_by_pk: Maybe<Email_Template>;
  /** fetch data from the table: "employment_type_choices" */
  employment_type_choices: Array<Employment_Type_Choices>;
  /** fetch aggregated fields from the table: "employment_type_choices" */
  employment_type_choices_aggregate: Employment_Type_Choices_Aggregate;
  /** fetch data from the table: "employment_type_choices" using primary key columns */
  employment_type_choices_by_pk: Maybe<Employment_Type_Choices>;
  /** Explore candidates */
  explore_candidates: Maybe<Array<Maybe<ExploreCandidatesOutput>>>;
  /** fetch data from the table: "explore_candidates_popular_search" */
  explore_candidates_popular_search: Array<Explore_Candidates_Popular_Search>;
  /** fetch aggregated fields from the table: "explore_candidates_popular_search" */
  explore_candidates_popular_search_aggregate: Explore_Candidates_Popular_Search_Aggregate;
  /** fetch data from the table: "explore_candidates_popular_search" using primary key columns */
  explore_candidates_popular_search_by_pk: Maybe<Explore_Candidates_Popular_Search>;
  /** execute function "explore_candidates_query" which returns "explore_candidates_query_result" */
  explore_candidates_query: Array<Explore_Candidates_Query_Result>;
  /** execute function "explore_candidates_query" and query aggregates on result of table type "explore_candidates_query_result" */
  explore_candidates_query_aggregate: Explore_Candidates_Query_Result_Aggregate;
  /** fetch data from the table: "explore_candidates_query_result" */
  explore_candidates_query_result: Array<Explore_Candidates_Query_Result>;
  /** fetch aggregated fields from the table: "explore_candidates_query_result" */
  explore_candidates_query_result_aggregate: Explore_Candidates_Query_Result_Aggregate;
  /** fetch data from the table: "explore_candidates_query_result" using primary key columns */
  explore_candidates_query_result_by_pk: Maybe<Explore_Candidates_Query_Result>;
  /** fetch data from the table: "explore_candidates_search" */
  explore_candidates_search: Array<Explore_Candidates_Search>;
  /** fetch aggregated fields from the table: "explore_candidates_search" */
  explore_candidates_search_aggregate: Explore_Candidates_Search_Aggregate;
  /** fetch data from the table: "explore_candidates_search" using primary key columns */
  explore_candidates_search_by_pk: Maybe<Explore_Candidates_Search>;
  /** fetch data from the table: "explore_candidates_search_candidate_highlight" */
  explore_candidates_search_candidate_highlight: Array<Explore_Candidates_Search_Candidate_Highlight>;
  /** fetch aggregated fields from the table: "explore_candidates_search_candidate_highlight" */
  explore_candidates_search_candidate_highlight_aggregate: Explore_Candidates_Search_Candidate_Highlight_Aggregate;
  /** fetch data from the table: "explore_candidates_search_candidate_highlight" using primary key columns */
  explore_candidates_search_candidate_highlight_by_pk: Maybe<Explore_Candidates_Search_Candidate_Highlight>;
  /** fetch data from the table: "explore_candidates_search_must_have_skill" */
  explore_candidates_search_must_have_skill: Array<Explore_Candidates_Search_Must_Have_Skill>;
  /** fetch aggregated fields from the table: "explore_candidates_search_must_have_skill" */
  explore_candidates_search_must_have_skill_aggregate: Explore_Candidates_Search_Must_Have_Skill_Aggregate;
  /** fetch data from the table: "explore_candidates_search_must_have_skill" using primary key columns */
  explore_candidates_search_must_have_skill_by_pk: Maybe<Explore_Candidates_Search_Must_Have_Skill>;
  /** fetch data from the table: "explore_candidates_search_nice_to_have_skill" */
  explore_candidates_search_nice_to_have_skill: Array<Explore_Candidates_Search_Nice_To_Have_Skill>;
  /** fetch aggregated fields from the table: "explore_candidates_search_nice_to_have_skill" */
  explore_candidates_search_nice_to_have_skill_aggregate: Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate;
  /** fetch data from the table: "explore_candidates_search_nice_to_have_skill" using primary key columns */
  explore_candidates_search_nice_to_have_skill_by_pk: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill>;
  /** fetch data from the table: "explore_candidates_search_region" */
  explore_candidates_search_region: Array<Explore_Candidates_Search_Region>;
  /** fetch aggregated fields from the table: "explore_candidates_search_region" */
  explore_candidates_search_region_aggregate: Explore_Candidates_Search_Region_Aggregate;
  /** fetch data from the table: "explore_candidates_search_region" using primary key columns */
  explore_candidates_search_region_by_pk: Maybe<Explore_Candidates_Search_Region>;
  /** fetch data from the table: "explore_candidates_user_saved_search" */
  explore_candidates_user_saved_search: Array<Explore_Candidates_User_Saved_Search>;
  /** fetch aggregated fields from the table: "explore_candidates_user_saved_search" */
  explore_candidates_user_saved_search_aggregate: Explore_Candidates_User_Saved_Search_Aggregate;
  /** fetch data from the table: "explore_candidates_user_saved_search" using primary key columns */
  explore_candidates_user_saved_search_by_pk: Maybe<Explore_Candidates_User_Saved_Search>;
  /** fetch data from the table: "external_candidate_meta" */
  external_candidate_meta: Array<External_Candidate_Meta>;
  /** fetch aggregated fields from the table: "external_candidate_meta" */
  external_candidate_meta_aggregate: External_Candidate_Meta_Aggregate;
  /** fetch data from the table: "external_candidate_meta" using primary key columns */
  external_candidate_meta_by_pk: Maybe<External_Candidate_Meta>;
  /** fetch data from the table: "feature" */
  feature: Array<Feature>;
  /** fetch aggregated fields from the table: "feature" */
  feature_aggregate: Feature_Aggregate;
  /** fetch data from the table: "feature" using primary key columns */
  feature_by_pk: Maybe<Feature>;
  /** fetch data from the table: "hireflix_transcript" */
  hireflix_transcript: Array<Hireflix_Transcript>;
  /** fetch aggregated fields from the table: "hireflix_transcript" */
  hireflix_transcript_aggregate: Hireflix_Transcript_Aggregate;
  /** fetch data from the table: "hireflix_transcript_blob" */
  hireflix_transcript_blob: Array<Hireflix_Transcript_Blob>;
  /** fetch aggregated fields from the table: "hireflix_transcript_blob" */
  hireflix_transcript_blob_aggregate: Hireflix_Transcript_Blob_Aggregate;
  /** fetch data from the table: "hireflix_transcript_blob" using primary key columns */
  hireflix_transcript_blob_by_pk: Maybe<Hireflix_Transcript_Blob>;
  /** fetch data from the table: "hireflix_transcript" using primary key columns */
  hireflix_transcript_by_pk: Maybe<Hireflix_Transcript>;
  /** fetch data from the table: "hr_test" */
  hr_test: Array<Hr_Test>;
  /** fetch aggregated fields from the table: "hr_test" */
  hr_test_aggregate: Hr_Test_Aggregate;
  /** fetch data from the table: "hr_test" using primary key columns */
  hr_test_by_pk: Maybe<Hr_Test>;
  /** fetch data from the table: "hr_test_candidate" */
  hr_test_candidate: Array<Hr_Test_Candidate>;
  /** fetch aggregated fields from the table: "hr_test_candidate" */
  hr_test_candidate_aggregate: Hr_Test_Candidate_Aggregate;
  /** fetch data from the table: "hr_test_candidate" using primary key columns */
  hr_test_candidate_by_pk: Maybe<Hr_Test_Candidate>;
  /** fetch data from the table: "hr_test_result" */
  hr_test_result: Array<Hr_Test_Result>;
  /** fetch aggregated fields from the table: "hr_test_result" */
  hr_test_result_aggregate: Hr_Test_Result_Aggregate;
  /** fetch data from the table: "hr_test_result" using primary key columns */
  hr_test_result_by_pk: Maybe<Hr_Test_Result>;
  /** fetch data from the table: "icims_applicant_workflow" */
  icims_applicant_workflow: Array<Icims_Applicant_Workflow>;
  /** fetch aggregated fields from the table: "icims_applicant_workflow" */
  icims_applicant_workflow_aggregate: Icims_Applicant_Workflow_Aggregate;
  /** fetch data from the table: "icims_applicant_workflow" using primary key columns */
  icims_applicant_workflow_by_pk: Maybe<Icims_Applicant_Workflow>;
  /** fetch data from the table: "icims_applicant_workflow_feedback" */
  icims_applicant_workflow_feedback: Array<Icims_Applicant_Workflow_Feedback>;
  /** fetch aggregated fields from the table: "icims_applicant_workflow_feedback" */
  icims_applicant_workflow_feedback_aggregate: Icims_Applicant_Workflow_Feedback_Aggregate;
  /** fetch data from the table: "icims_applicant_workflow_feedback" using primary key columns */
  icims_applicant_workflow_feedback_by_pk: Maybe<Icims_Applicant_Workflow_Feedback>;
  /** fetch data from the table: "icims_applicant_workflow_status" */
  icims_applicant_workflow_status: Array<Icims_Applicant_Workflow_Status>;
  /** fetch aggregated fields from the table: "icims_applicant_workflow_status" */
  icims_applicant_workflow_status_aggregate: Icims_Applicant_Workflow_Status_Aggregate;
  /** fetch data from the table: "icims_applicant_workflow_status" using primary key columns */
  icims_applicant_workflow_status_by_pk: Maybe<Icims_Applicant_Workflow_Status>;
  /** execute function "icims_applicant_workflow_status_current" which returns "icims_applicant_workflow_status" */
  icims_applicant_workflow_status_current: Array<Icims_Applicant_Workflow_Status>;
  /** execute function "icims_applicant_workflow_status_current" and query aggregates on result of table type "icims_applicant_workflow_status" */
  icims_applicant_workflow_status_current_aggregate: Icims_Applicant_Workflow_Status_Aggregate;
  /** fetch data from the table: "icims_company" */
  icims_company: Array<Icims_Company>;
  /** fetch aggregated fields from the table: "icims_company" */
  icims_company_aggregate: Icims_Company_Aggregate;
  /** fetch data from the table: "icims_company" using primary key columns */
  icims_company_by_pk: Maybe<Icims_Company>;
  /** fetch data from the table: "icims_country" */
  icims_country: Array<Icims_Country>;
  /** fetch aggregated fields from the table: "icims_country" */
  icims_country_aggregate: Icims_Country_Aggregate;
  /** fetch data from the table: "icims_country" using primary key columns */
  icims_country_by_pk: Maybe<Icims_Country>;
  /** fetch data from the table: "icims_degree" */
  icims_degree: Array<Icims_Degree>;
  /** fetch aggregated fields from the table: "icims_degree" */
  icims_degree_aggregate: Icims_Degree_Aggregate;
  /** fetch data from the table: "icims_degree" using primary key columns */
  icims_degree_by_pk: Maybe<Icims_Degree>;
  /** fetch data from the table: "icims_education" */
  icims_education: Array<Icims_Education>;
  /** fetch aggregated fields from the table: "icims_education" */
  icims_education_aggregate: Icims_Education_Aggregate;
  /** fetch data from the table: "icims_education" using primary key columns */
  icims_education_by_pk: Maybe<Icims_Education>;
  /** fetch data from the table: "icims_folder" */
  icims_folder: Array<Icims_Folder>;
  /** fetch aggregated fields from the table: "icims_folder" */
  icims_folder_aggregate: Icims_Folder_Aggregate;
  /** fetch data from the table: "icims_folder" using primary key columns */
  icims_folder_by_pk: Maybe<Icims_Folder>;
  /** fetch data from the table: "icims_initial_data_transfer" */
  icims_initial_data_transfer: Array<Icims_Initial_Data_Transfer>;
  /** fetch aggregated fields from the table: "icims_initial_data_transfer" */
  icims_initial_data_transfer_aggregate: Icims_Initial_Data_Transfer_Aggregate;
  /** fetch data from the table: "icims_initial_data_transfer" using primary key columns */
  icims_initial_data_transfer_by_pk: Maybe<Icims_Initial_Data_Transfer>;
  /** fetch data from the table: "icims_interested_location" */
  icims_interested_location: Array<Icims_Interested_Location>;
  /** fetch aggregated fields from the table: "icims_interested_location" */
  icims_interested_location_aggregate: Icims_Interested_Location_Aggregate;
  /** fetch data from the table: "icims_interested_location" using primary key columns */
  icims_interested_location_by_pk: Maybe<Icims_Interested_Location>;
  /** fetch data from the table: "icims_job" */
  icims_job: Array<Icims_Job>;
  /** fetch aggregated fields from the table: "icims_job" */
  icims_job_aggregate: Icims_Job_Aggregate;
  /** fetch data from the table: "icims_job" using primary key columns */
  icims_job_by_pk: Maybe<Icims_Job>;
  /** fetch data from the table: "icims_job_location" */
  icims_job_location: Array<Icims_Job_Location>;
  /** fetch aggregated fields from the table: "icims_job_location" */
  icims_job_location_aggregate: Icims_Job_Location_Aggregate;
  /** fetch data from the table: "icims_job_location" using primary key columns */
  icims_job_location_by_pk: Maybe<Icims_Job_Location>;
  /** fetch data from the table: "icims_job_requested_location" */
  icims_job_requested_location: Array<Icims_Job_Requested_Location>;
  /** fetch aggregated fields from the table: "icims_job_requested_location" */
  icims_job_requested_location_aggregate: Icims_Job_Requested_Location_Aggregate;
  /** fetch data from the table: "icims_job_requested_location" using primary key columns */
  icims_job_requested_location_by_pk: Maybe<Icims_Job_Requested_Location>;
  /** fetch data from the table: "icims_job_skill" */
  icims_job_skill: Array<Icims_Job_Skill>;
  /** fetch aggregated fields from the table: "icims_job_skill" */
  icims_job_skill_aggregate: Icims_Job_Skill_Aggregate;
  /** fetch data from the table: "icims_job_skill" using primary key columns */
  icims_job_skill_by_pk: Maybe<Icims_Job_Skill>;
  /** fetch data from the table: "icims_location" */
  icims_location: Array<Icims_Location>;
  /** fetch aggregated fields from the table: "icims_location" */
  icims_location_aggregate: Icims_Location_Aggregate;
  /** fetch data from the table: "icims_location" using primary key columns */
  icims_location_by_pk: Maybe<Icims_Location>;
  /** fetch data from the table: "icims_major" */
  icims_major: Array<Icims_Major>;
  /** fetch aggregated fields from the table: "icims_major" */
  icims_major_aggregate: Icims_Major_Aggregate;
  /** fetch data from the table: "icims_major" using primary key columns */
  icims_major_by_pk: Maybe<Icims_Major>;
  /** fetch data from the table: "icims_person" */
  icims_person: Array<Icims_Person>;
  /** fetch aggregated fields from the table: "icims_person" */
  icims_person_aggregate: Icims_Person_Aggregate;
  /** fetch data from the table: "icims_person" using primary key columns */
  icims_person_by_pk: Maybe<Icims_Person>;
  /** fetch data from the table: "icims_person_interested_location" */
  icims_person_interested_location: Array<Icims_Person_Interested_Location>;
  /** fetch aggregated fields from the table: "icims_person_interested_location" */
  icims_person_interested_location_aggregate: Icims_Person_Interested_Location_Aggregate;
  /** fetch data from the table: "icims_person_interested_location" using primary key columns */
  icims_person_interested_location_by_pk: Maybe<Icims_Person_Interested_Location>;
  /** fetch data from the table: "icims_person_preferred_location" */
  icims_person_preferred_location: Array<Icims_Person_Preferred_Location>;
  /** fetch aggregated fields from the table: "icims_person_preferred_location" */
  icims_person_preferred_location_aggregate: Icims_Person_Preferred_Location_Aggregate;
  /** fetch data from the table: "icims_person_preferred_location" using primary key columns */
  icims_person_preferred_location_by_pk: Maybe<Icims_Person_Preferred_Location>;
  /** fetch data from the table: "icims_person_skill" */
  icims_person_skill: Array<Icims_Person_Skill>;
  /** fetch aggregated fields from the table: "icims_person_skill" */
  icims_person_skill_aggregate: Icims_Person_Skill_Aggregate;
  /** fetch data from the table: "icims_person_skill" using primary key columns */
  icims_person_skill_by_pk: Maybe<Icims_Person_Skill>;
  /** fetch data from the table: "icims_profile_applicant_workflow" */
  icims_profile_applicant_workflow: Array<Icims_Profile_Applicant_Workflow>;
  /** fetch aggregated fields from the table: "icims_profile_applicant_workflow" */
  icims_profile_applicant_workflow_aggregate: Icims_Profile_Applicant_Workflow_Aggregate;
  /** fetch data from the table: "icims_profile_applicant_workflow" using primary key columns */
  icims_profile_applicant_workflow_by_pk: Maybe<Icims_Profile_Applicant_Workflow>;
  /** fetch data from the table: "icims_profile_job" */
  icims_profile_job: Array<Icims_Profile_Job>;
  /** fetch aggregated fields from the table: "icims_profile_job" */
  icims_profile_job_aggregate: Icims_Profile_Job_Aggregate;
  /** fetch data from the table: "icims_profile_job" using primary key columns */
  icims_profile_job_by_pk: Maybe<Icims_Profile_Job>;
  /** fetch data from the table: "icims_profile_location" */
  icims_profile_location: Array<Icims_Profile_Location>;
  /** fetch aggregated fields from the table: "icims_profile_location" */
  icims_profile_location_aggregate: Icims_Profile_Location_Aggregate;
  /** fetch data from the table: "icims_profile_location" using primary key columns */
  icims_profile_location_by_pk: Maybe<Icims_Profile_Location>;
  /** fetch data from the table: "icims_profile_person" */
  icims_profile_person: Array<Icims_Profile_Person>;
  /** fetch aggregated fields from the table: "icims_profile_person" */
  icims_profile_person_aggregate: Icims_Profile_Person_Aggregate;
  /** fetch data from the table: "icims_profile_person" using primary key columns */
  icims_profile_person_by_pk: Maybe<Icims_Profile_Person>;
  /** fetch data from the table: "icims_profile_requested_location" */
  icims_profile_requested_location: Array<Icims_Profile_Requested_Location>;
  /** fetch aggregated fields from the table: "icims_profile_requested_location" */
  icims_profile_requested_location_aggregate: Icims_Profile_Requested_Location_Aggregate;
  /** fetch data from the table: "icims_profile_requested_location" using primary key columns */
  icims_profile_requested_location_by_pk: Maybe<Icims_Profile_Requested_Location>;
  /** fetch data from the table: "icims_requested_location" */
  icims_requested_location: Array<Icims_Requested_Location>;
  /** fetch aggregated fields from the table: "icims_requested_location" */
  icims_requested_location_aggregate: Icims_Requested_Location_Aggregate;
  /** fetch data from the table: "icims_requested_location" using primary key columns */
  icims_requested_location_by_pk: Maybe<Icims_Requested_Location>;
  /** fetch data from the table: "icims_school" */
  icims_school: Array<Icims_School>;
  /** fetch aggregated fields from the table: "icims_school" */
  icims_school_aggregate: Icims_School_Aggregate;
  /** fetch data from the table: "icims_school" using primary key columns */
  icims_school_by_pk: Maybe<Icims_School>;
  /** fetch data from the table: "icims_skill" */
  icims_skill: Array<Icims_Skill>;
  /** fetch aggregated fields from the table: "icims_skill" */
  icims_skill_aggregate: Icims_Skill_Aggregate;
  /** fetch data from the table: "icims_skill" using primary key columns */
  icims_skill_by_pk: Maybe<Icims_Skill>;
  /** fetch data from the table: "icims_status" */
  icims_status: Array<Icims_Status>;
  /** fetch aggregated fields from the table: "icims_status" */
  icims_status_aggregate: Icims_Status_Aggregate;
  /** fetch data from the table: "icims_status" using primary key columns */
  icims_status_by_pk: Maybe<Icims_Status>;
  /** fetch data from the table: "icims_sync_status_choices" */
  icims_sync_status_choices: Array<Icims_Sync_Status_Choices>;
  /** fetch aggregated fields from the table: "icims_sync_status_choices" */
  icims_sync_status_choices_aggregate: Icims_Sync_Status_Choices_Aggregate;
  /** fetch data from the table: "icims_sync_status_choices" using primary key columns */
  icims_sync_status_choices_by_pk: Maybe<Icims_Sync_Status_Choices>;
  /** fetch data from the table: "icims_work_experience" */
  icims_work_experience: Array<Icims_Work_Experience>;
  /** fetch aggregated fields from the table: "icims_work_experience" */
  icims_work_experience_aggregate: Icims_Work_Experience_Aggregate;
  /** fetch data from the table: "icims_work_experience" using primary key columns */
  icims_work_experience_by_pk: Maybe<Icims_Work_Experience>;
  /** fetch data from the table: "industry" */
  industry: Array<Industry>;
  /** fetch aggregated fields from the table: "industry" */
  industry_aggregate: Industry_Aggregate;
  /** fetch data from the table: "industry" using primary key columns */
  industry_by_pk: Maybe<Industry>;
  /** fetch data from the table: "initial_sell_type_choices" */
  initial_sell_type_choices: Array<Initial_Sell_Type_Choices>;
  /** fetch aggregated fields from the table: "initial_sell_type_choices" */
  initial_sell_type_choices_aggregate: Initial_Sell_Type_Choices_Aggregate;
  /** fetch data from the table: "initial_sell_type_choices" using primary key columns */
  initial_sell_type_choices_by_pk: Maybe<Initial_Sell_Type_Choices>;
  /** fetch data from the table: "interview_detail" */
  interview_detail: Array<Interview_Detail>;
  /** fetch aggregated fields from the table: "interview_detail" */
  interview_detail_aggregate: Interview_Detail_Aggregate;
  /** fetch data from the table: "interview_detail" using primary key columns */
  interview_detail_by_pk: Maybe<Interview_Detail>;
  /** fetch data from the table: "interview_feedback" */
  interview_feedback: Array<Interview_Feedback>;
  /** fetch aggregated fields from the table: "interview_feedback" */
  interview_feedback_aggregate: Interview_Feedback_Aggregate;
  /** fetch data from the table: "interview_feedback" using primary key columns */
  interview_feedback_by_pk: Maybe<Interview_Feedback>;
  /** fetch data from the table: "interview_feedback_rating_choices" */
  interview_feedback_rating_choices: Array<Interview_Feedback_Rating_Choices>;
  /** fetch aggregated fields from the table: "interview_feedback_rating_choices" */
  interview_feedback_rating_choices_aggregate: Interview_Feedback_Rating_Choices_Aggregate;
  /** fetch data from the table: "interview_feedback_rating_choices" using primary key columns */
  interview_feedback_rating_choices_by_pk: Maybe<Interview_Feedback_Rating_Choices>;
  /** fetch data from the table: "interview_feedback_request" */
  interview_feedback_request: Array<Interview_Feedback_Request>;
  /** fetch aggregated fields from the table: "interview_feedback_request" */
  interview_feedback_request_aggregate: Interview_Feedback_Request_Aggregate;
  /** fetch data from the table: "interview_feedback_request" using primary key columns */
  interview_feedback_request_by_pk: Maybe<Interview_Feedback_Request>;
  /** fetch data from the table: "interview_request" */
  interview_request: Array<Interview_Request>;
  /** fetch aggregated fields from the table: "interview_request" */
  interview_request_aggregate: Interview_Request_Aggregate;
  /** fetch data from the table: "interview_request" using primary key columns */
  interview_request_by_pk: Maybe<Interview_Request>;
  /** fetch data from the table: "interview_type_choices" */
  interview_type_choices: Array<Interview_Type_Choices>;
  /** fetch aggregated fields from the table: "interview_type_choices" */
  interview_type_choices_aggregate: Interview_Type_Choices_Aggregate;
  /** fetch data from the table: "interview_type_choices" using primary key columns */
  interview_type_choices_by_pk: Maybe<Interview_Type_Choices>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch data from the table: "job_acceptable_location" */
  job_acceptable_location: Array<Job_Acceptable_Location>;
  /** fetch aggregated fields from the table: "job_acceptable_location" */
  job_acceptable_location_aggregate: Job_Acceptable_Location_Aggregate;
  /** fetch data from the table: "job_acceptable_location" using primary key columns */
  job_acceptable_location_by_pk: Maybe<Job_Acceptable_Location>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk: Maybe<Job>;
  /** fetch data from the table: "job_code" */
  job_code: Array<Job_Code>;
  /** fetch aggregated fields from the table: "job_code" */
  job_code_aggregate: Job_Code_Aggregate;
  /** fetch data from the table: "job_code" using primary key columns */
  job_code_by_pk: Maybe<Job_Code>;
  /** fetch data from the table: "job_level_choices" */
  job_level_choices: Array<Job_Level_Choices>;
  /** fetch aggregated fields from the table: "job_level_choices" */
  job_level_choices_aggregate: Job_Level_Choices_Aggregate;
  /** fetch data from the table: "job_level_choices" using primary key columns */
  job_level_choices_by_pk: Maybe<Job_Level_Choices>;
  /** fetch data from the table: "job_meta_info" */
  job_meta_info: Array<Job_Meta_Info>;
  /** fetch aggregated fields from the table: "job_meta_info" */
  job_meta_info_aggregate: Job_Meta_Info_Aggregate;
  /** fetch data from the table: "job_meta_info" using primary key columns */
  job_meta_info_by_pk: Maybe<Job_Meta_Info>;
  /** fetch data from the table: "job_nice_to_have_skill" */
  job_nice_to_have_skill: Array<Job_Nice_To_Have_Skill>;
  /** fetch aggregated fields from the table: "job_nice_to_have_skill" */
  job_nice_to_have_skill_aggregate: Job_Nice_To_Have_Skill_Aggregate;
  /** fetch data from the table: "job_nice_to_have_skill" using primary key columns */
  job_nice_to_have_skill_by_pk: Maybe<Job_Nice_To_Have_Skill>;
  /** fetch data from the table: "job_position" */
  job_position: Array<Job_Position>;
  /** fetch aggregated fields from the table: "job_position" */
  job_position_aggregate: Job_Position_Aggregate;
  /** fetch data from the table: "job_position" using primary key columns */
  job_position_by_pk: Maybe<Job_Position>;
  /** fetch data from the table: "job_priority_choices" */
  job_priority_choices: Array<Job_Priority_Choices>;
  /** fetch aggregated fields from the table: "job_priority_choices" */
  job_priority_choices_aggregate: Job_Priority_Choices_Aggregate;
  /** fetch data from the table: "job_priority_choices" using primary key columns */
  job_priority_choices_by_pk: Maybe<Job_Priority_Choices>;
  /** fetch data from the table: "job_required_skill" */
  job_required_skill: Array<Job_Required_Skill>;
  /** fetch aggregated fields from the table: "job_required_skill" */
  job_required_skill_aggregate: Job_Required_Skill_Aggregate;
  /** fetch data from the table: "job_required_skill" using primary key columns */
  job_required_skill_by_pk: Maybe<Job_Required_Skill>;
  /** fetch data from the table: "job_role_source_choices" */
  job_role_source_choices: Array<Job_Role_Source_Choices>;
  /** fetch aggregated fields from the table: "job_role_source_choices" */
  job_role_source_choices_aggregate: Job_Role_Source_Choices_Aggregate;
  /** fetch data from the table: "job_role_source_choices" using primary key columns */
  job_role_source_choices_by_pk: Maybe<Job_Role_Source_Choices>;
  /** fetch data from the table: "job_service_type" */
  job_service_type: Array<Job_Service_Type>;
  /** fetch aggregated fields from the table: "job_service_type" */
  job_service_type_aggregate: Job_Service_Type_Aggregate;
  /** fetch data from the table: "job_service_type" using primary key columns */
  job_service_type_by_pk: Maybe<Job_Service_Type>;
  /** fetch data from the table: "job_status_choices" */
  job_status_choices: Array<Job_Status_Choices>;
  /** fetch aggregated fields from the table: "job_status_choices" */
  job_status_choices_aggregate: Job_Status_Choices_Aggregate;
  /** fetch data from the table: "job_status_choices" using primary key columns */
  job_status_choices_by_pk: Maybe<Job_Status_Choices>;
  /** fetch data from the table: "job_status_choices_order" */
  job_status_choices_order: Array<Job_Status_Choices_Order>;
  /** fetch aggregated fields from the table: "job_status_choices_order" */
  job_status_choices_order_aggregate: Job_Status_Choices_Order_Aggregate;
  /** fetch data from the table: "job_status_choices_order" using primary key columns */
  job_status_choices_order_by_pk: Maybe<Job_Status_Choices_Order>;
  /** fetch data from the table: "job_status_log" */
  job_status_log: Array<Job_Status_Log>;
  /** fetch aggregated fields from the table: "job_status_log" */
  job_status_log_aggregate: Job_Status_Log_Aggregate;
  /** fetch data from the table: "job_status_log" using primary key columns */
  job_status_log_by_pk: Maybe<Job_Status_Log>;
  /** An array relationship */
  job_tech_stack: Array<Job_Tech_Stack>;
  /** An aggregate relationship */
  job_tech_stack_aggregate: Job_Tech_Stack_Aggregate;
  /** fetch data from the table: "job_tech_stack" using primary key columns */
  job_tech_stack_by_pk: Maybe<Job_Tech_Stack>;
  /** fetch data from the table: "linkedin_contact" */
  linkedin_contact: Array<Linkedin_Contact>;
  /** fetch aggregated fields from the table: "linkedin_contact" */
  linkedin_contact_aggregate: Linkedin_Contact_Aggregate;
  /** fetch data from the table: "linkedin_contact" using primary key columns */
  linkedin_contact_by_pk: Maybe<Linkedin_Contact>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk: Maybe<Location>;
  /** fetch data from the table: "manual_contact" */
  manual_contact: Array<Manual_Contact>;
  /** fetch aggregated fields from the table: "manual_contact" */
  manual_contact_aggregate: Manual_Contact_Aggregate;
  /** fetch data from the table: "manual_contact" using primary key columns */
  manual_contact_by_pk: Maybe<Manual_Contact>;
  /** fetch data from the table: "merge_ats_application" */
  merge_ats_application: Array<Merge_Ats_Application>;
  /** fetch aggregated fields from the table: "merge_ats_application" */
  merge_ats_application_aggregate: Merge_Ats_Application_Aggregate;
  /** fetch data from the table: "merge_ats_application" using primary key columns */
  merge_ats_application_by_pk: Maybe<Merge_Ats_Application>;
  /** fetch data from the table: "merge_ats_application_log" */
  merge_ats_application_log: Array<Merge_Ats_Application_Log>;
  /** fetch aggregated fields from the table: "merge_ats_application_log" */
  merge_ats_application_log_aggregate: Merge_Ats_Application_Log_Aggregate;
  /** fetch data from the table: "merge_ats_application_log" using primary key columns */
  merge_ats_application_log_by_pk: Maybe<Merge_Ats_Application_Log>;
  /** fetch data from the table: "merge_ats_candidate" */
  merge_ats_candidate: Array<Merge_Ats_Candidate>;
  /** fetch aggregated fields from the table: "merge_ats_candidate" */
  merge_ats_candidate_aggregate: Merge_Ats_Candidate_Aggregate;
  /** fetch data from the table: "merge_ats_candidate" using primary key columns */
  merge_ats_candidate_by_pk: Maybe<Merge_Ats_Candidate>;
  /** fetch data from the table: "merge_ats_job" */
  merge_ats_job: Array<Merge_Ats_Job>;
  /** fetch aggregated fields from the table: "merge_ats_job" */
  merge_ats_job_aggregate: Merge_Ats_Job_Aggregate;
  /** fetch data from the table: "merge_ats_job" using primary key columns */
  merge_ats_job_by_pk: Maybe<Merge_Ats_Job>;
  /** fetch data from the table: "merge_ats_job_interview_stage" */
  merge_ats_job_interview_stage: Array<Merge_Ats_Job_Interview_Stage>;
  /** fetch aggregated fields from the table: "merge_ats_job_interview_stage" */
  merge_ats_job_interview_stage_aggregate: Merge_Ats_Job_Interview_Stage_Aggregate;
  /** fetch data from the table: "merge_ats_job_interview_stage" using primary key columns */
  merge_ats_job_interview_stage_by_pk: Maybe<Merge_Ats_Job_Interview_Stage>;
  /** fetch data from the table: "merge_ats_linked_account" */
  merge_ats_linked_account: Array<Merge_Ats_Linked_Account>;
  /** fetch aggregated fields from the table: "merge_ats_linked_account" */
  merge_ats_linked_account_aggregate: Merge_Ats_Linked_Account_Aggregate;
  /** fetch data from the table: "merge_ats_linked_account" using primary key columns */
  merge_ats_linked_account_by_pk: Maybe<Merge_Ats_Linked_Account>;
  /** fetch data from the table: "mixpanel.duplicate_profiles" */
  mixpanel_duplicate_profiles: Array<Mixpanel_Duplicate_Profiles>;
  /** fetch aggregated fields from the table: "mixpanel.duplicate_profiles" */
  mixpanel_duplicate_profiles_aggregate: Mixpanel_Duplicate_Profiles_Aggregate;
  /** fetch data from the table: "mixpanel.profiles" */
  mixpanel_profiles: Array<Mixpanel_Profiles>;
  /** fetch aggregated fields from the table: "mixpanel.profiles" */
  mixpanel_profiles_aggregate: Mixpanel_Profiles_Aggregate;
  /** fetch data from the table: "mixpanel.profiles" using primary key columns */
  mixpanel_profiles_by_pk: Maybe<Mixpanel_Profiles>;
  /** fetch data from the table: "offering_selection_choices" */
  offering_selection_choices: Array<Offering_Selection_Choices>;
  /** fetch aggregated fields from the table: "offering_selection_choices" */
  offering_selection_choices_aggregate: Offering_Selection_Choices_Aggregate;
  /** fetch data from the table: "offering_selection_choices" using primary key columns */
  offering_selection_choices_by_pk: Maybe<Offering_Selection_Choices>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch aggregated fields from the table: "organization" */
  organization_aggregate: Organization_Aggregate;
  /** fetch data from the table: "organization" using primary key columns */
  organization_by_pk: Maybe<Organization>;
  /** fetch data from the table: "organization_contact" */
  organization_contact: Array<Organization_Contact>;
  /** fetch aggregated fields from the table: "organization_contact" */
  organization_contact_aggregate: Organization_Contact_Aggregate;
  /** fetch data from the table: "organization_contact" using primary key columns */
  organization_contact_by_pk: Maybe<Organization_Contact>;
  /** fetch data from the table: "organization_domain" */
  organization_domain: Array<Organization_Domain>;
  /** fetch aggregated fields from the table: "organization_domain" */
  organization_domain_aggregate: Organization_Domain_Aggregate;
  /** fetch data from the table: "organization_domain" using primary key columns */
  organization_domain_by_pk: Maybe<Organization_Domain>;
  /** fetch data from the table: "organization_employee" */
  organization_employee: Array<Organization_Employee>;
  /** fetch aggregated fields from the table: "organization_employee" */
  organization_employee_aggregate: Organization_Employee_Aggregate;
  /** fetch data from the table: "organization_employee" using primary key columns */
  organization_employee_by_pk: Maybe<Organization_Employee>;
  /** execute function "organization_icims_jobs" which returns "organization_icims_jobs_result" */
  organization_icims_jobs: Array<Organization_Icims_Jobs_Result>;
  /** execute function "organization_icims_jobs" and query aggregates on result of table type "organization_icims_jobs_result" */
  organization_icims_jobs_aggregate: Organization_Icims_Jobs_Result_Aggregate;
  /** fetch data from the table: "organization_icims_jobs_result" */
  organization_icims_jobs_result: Array<Organization_Icims_Jobs_Result>;
  /** fetch aggregated fields from the table: "organization_icims_jobs_result" */
  organization_icims_jobs_result_aggregate: Organization_Icims_Jobs_Result_Aggregate;
  /** fetch data from the table: "organization_leadership" */
  organization_leadership: Array<Organization_Leadership>;
  /** fetch aggregated fields from the table: "organization_leadership" */
  organization_leadership_aggregate: Organization_Leadership_Aggregate;
  /** fetch data from the table: "organization_leadership" using primary key columns */
  organization_leadership_by_pk: Maybe<Organization_Leadership>;
  /** fetch data from the table: "organization_type_choices" */
  organization_type_choices: Array<Organization_Type_Choices>;
  /** fetch aggregated fields from the table: "organization_type_choices" */
  organization_type_choices_aggregate: Organization_Type_Choices_Aggregate;
  /** fetch data from the table: "organization_type_choices" using primary key columns */
  organization_type_choices_by_pk: Maybe<Organization_Type_Choices>;
  /** fetch data from the table: "organization_video" */
  organization_video: Array<Organization_Video>;
  /** fetch aggregated fields from the table: "organization_video" */
  organization_video_aggregate: Organization_Video_Aggregate;
  /** fetch data from the table: "organization_video" using primary key columns */
  organization_video_by_pk: Maybe<Organization_Video>;
  /** fetch data from the table: "pillar_transcript" */
  pillar_transcript: Array<Pillar_Transcript>;
  /** fetch aggregated fields from the table: "pillar_transcript" */
  pillar_transcript_aggregate: Pillar_Transcript_Aggregate;
  /** fetch data from the table: "pillar_transcript_blob" */
  pillar_transcript_blob: Array<Pillar_Transcript_Blob>;
  /** fetch aggregated fields from the table: "pillar_transcript_blob" */
  pillar_transcript_blob_aggregate: Pillar_Transcript_Blob_Aggregate;
  /** fetch data from the table: "pillar_transcript_blob" using primary key columns */
  pillar_transcript_blob_by_pk: Maybe<Pillar_Transcript_Blob>;
  /** fetch data from the table: "pillar_transcript" using primary key columns */
  pillar_transcript_by_pk: Maybe<Pillar_Transcript>;
  /** fetch data from the table: "recruiter_contact_action" */
  recruiter_contact_action: Array<Recruiter_Contact_Action>;
  /** fetch aggregated fields from the table: "recruiter_contact_action" */
  recruiter_contact_action_aggregate: Recruiter_Contact_Action_Aggregate;
  /** fetch data from the table: "recruiter_contact_action" using primary key columns */
  recruiter_contact_action_by_pk: Maybe<Recruiter_Contact_Action>;
  /** fetch data from the table: "referral_status" */
  referral_status: Array<Referral_Status>;
  /** fetch aggregated fields from the table: "referral_status" */
  referral_status_aggregate: Referral_Status_Aggregate;
  /** fetch data from the table: "referral_status" using primary key columns */
  referral_status_by_pk: Maybe<Referral_Status>;
  /** fetch data from the table: "referral_status_current" */
  referral_status_current: Array<Referral_Status_Current>;
  /** fetch aggregated fields from the table: "referral_status_current" */
  referral_status_current_aggregate: Referral_Status_Current_Aggregate;
  /** fetch data from the table: "referral_status_current" using primary key columns */
  referral_status_current_by_pk: Maybe<Referral_Status_Current>;
  /** fetch data from the table: "region_choices" */
  region_choices: Array<Region_Choices>;
  /** fetch aggregated fields from the table: "region_choices" */
  region_choices_aggregate: Region_Choices_Aggregate;
  /** fetch data from the table: "region_choices" using primary key columns */
  region_choices_by_pk: Maybe<Region_Choices>;
  /** fetch data from the table: "reporting.candidate_metadata" */
  reporting_candidate_metadata: Array<Reporting_Candidate_Metadata>;
  /** fetch aggregated fields from the table: "reporting.candidate_metadata" */
  reporting_candidate_metadata_aggregate: Reporting_Candidate_Metadata_Aggregate;
  /** fetch data from the table: "reporting.email_suppression_traits" */
  reporting_email_suppression_traits: Array<Reporting_Email_Suppression_Traits>;
  /** fetch aggregated fields from the table: "reporting.email_suppression_traits" */
  reporting_email_suppression_traits_aggregate: Reporting_Email_Suppression_Traits_Aggregate;
  /** fetch data from the table: "reporting.report_job_matches" */
  reporting_report_job_matches: Array<Reporting_Report_Job_Matches>;
  /** fetch aggregated fields from the table: "reporting.report_job_matches" */
  reporting_report_job_matches_aggregate: Reporting_Report_Job_Matches_Aggregate;
  /** fetch data from the table: "reporting.report_job_matches" using primary key columns */
  reporting_report_job_matches_by_pk: Maybe<Reporting_Report_Job_Matches>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk: Maybe<Role>;
  /** fetch data from the table: "role_choices" */
  role_choices: Array<Role_Choices>;
  /** fetch aggregated fields from the table: "role_choices" */
  role_choices_aggregate: Role_Choices_Aggregate;
  /** fetch data from the table: "role_choices" using primary key columns */
  role_choices_by_pk: Maybe<Role_Choices>;
  /** fetch data from the table: "salary_info" */
  salary_info: Array<Salary_Info>;
  /** fetch aggregated fields from the table: "salary_info" */
  salary_info_aggregate: Salary_Info_Aggregate;
  /** fetch data from the table: "salary_info" using primary key columns */
  salary_info_by_pk: Maybe<Salary_Info>;
  /** fetch data from the table: "salary_years_experience_range_choices" */
  salary_years_experience_range_choices: Array<Salary_Years_Experience_Range_Choices>;
  /** fetch aggregated fields from the table: "salary_years_experience_range_choices" */
  salary_years_experience_range_choices_aggregate: Salary_Years_Experience_Range_Choices_Aggregate;
  /** fetch data from the table: "salary_years_experience_range_choices" using primary key columns */
  salary_years_experience_range_choices_by_pk: Maybe<Salary_Years_Experience_Range_Choices>;
  /** fetch data from the table: "salesforce_customer" */
  salesforce_customer: Array<Salesforce_Customer>;
  /** fetch aggregated fields from the table: "salesforce_customer" */
  salesforce_customer_aggregate: Salesforce_Customer_Aggregate;
  /** fetch data from the table: "salesforce_customer" using primary key columns */
  salesforce_customer_by_pk: Maybe<Salesforce_Customer>;
  /** fetch data from the table: "salesforce_customer_location" */
  salesforce_customer_location: Array<Salesforce_Customer_Location>;
  /** fetch aggregated fields from the table: "salesforce_customer_location" */
  salesforce_customer_location_aggregate: Salesforce_Customer_Location_Aggregate;
  /** fetch data from the table: "salesforce_customer_location" using primary key columns */
  salesforce_customer_location_by_pk: Maybe<Salesforce_Customer_Location>;
  /** fetch data from the table: "salesforce_location" */
  salesforce_location: Array<Salesforce_Location>;
  /** fetch aggregated fields from the table: "salesforce_location" */
  salesforce_location_aggregate: Salesforce_Location_Aggregate;
  /** fetch data from the table: "salesforce_location" using primary key columns */
  salesforce_location_by_pk: Maybe<Salesforce_Location>;
  /** fetch data from the table: "saved_candidate" */
  saved_candidate: Array<Saved_Candidate>;
  /** fetch aggregated fields from the table: "saved_candidate" */
  saved_candidate_aggregate: Saved_Candidate_Aggregate;
  /** fetch data from the table: "saved_candidate" using primary key columns */
  saved_candidate_by_pk: Maybe<Saved_Candidate>;
  /** fetch data from the table: "segment_sendgrid_prod.activity" */
  segment_sendgrid_prod_activity: Array<Segment_Sendgrid_Prod_Activity>;
  /** fetch aggregated fields from the table: "segment_sendgrid_prod.activity" */
  segment_sendgrid_prod_activity_aggregate: Segment_Sendgrid_Prod_Activity_Aggregate;
  /** fetch data from the table: "segment_sendgrid_prod.activity" using primary key columns */
  segment_sendgrid_prod_activity_by_pk: Maybe<Segment_Sendgrid_Prod_Activity>;
  /** fetch data from the table: "shared_candidate" */
  shared_candidate: Array<Shared_Candidate>;
  /** fetch aggregated fields from the table: "shared_candidate" */
  shared_candidate_aggregate: Shared_Candidate_Aggregate;
  /** fetch data from the table: "shared_candidate" using primary key columns */
  shared_candidate_by_pk: Maybe<Shared_Candidate>;
  /** fetch data from the table: "skill" */
  skill: Array<Skill>;
  /** fetch aggregated fields from the table: "skill" */
  skill_aggregate: Skill_Aggregate;
  /** fetch data from the table: "skill" using primary key columns */
  skill_by_pk: Maybe<Skill>;
  /** fetch data from the table: "skill_feature" */
  skill_feature: Array<Skill_Feature>;
  /** fetch aggregated fields from the table: "skill_feature" */
  skill_feature_aggregate: Skill_Feature_Aggregate;
  /** fetch data from the table: "skill_feature" using primary key columns */
  skill_feature_by_pk: Maybe<Skill_Feature>;
  /** fetch data from the table: "skill_icims_skill" */
  skill_icims_skill: Array<Skill_Icims_Skill>;
  /** fetch aggregated fields from the table: "skill_icims_skill" */
  skill_icims_skill_aggregate: Skill_Icims_Skill_Aggregate;
  /** fetch data from the table: "skill_icims_skill" using primary key columns */
  skill_icims_skill_by_pk: Maybe<Skill_Icims_Skill>;
  /** fetch data from the table: "skill_synonym" */
  skill_synonym: Array<Skill_Synonym>;
  /** fetch aggregated fields from the table: "skill_synonym" */
  skill_synonym_aggregate: Skill_Synonym_Aggregate;
  /** fetch data from the table: "skill_synonym" using primary key columns */
  skill_synonym_by_pk: Maybe<Skill_Synonym>;
  /** fetch data from the table: "spike_matching.candidate" */
  spike_matching_candidate: Array<Spike_Matching_Candidate>;
  /** fetch aggregated fields from the table: "spike_matching.candidate" */
  spike_matching_candidate_aggregate: Spike_Matching_Candidate_Aggregate;
  /** fetch data from the table: "spike_matching.candidate" using primary key columns */
  spike_matching_candidate_by_pk: Maybe<Spike_Matching_Candidate>;
  /** fetch data from the table: "spike_matching.candidate_skill" */
  spike_matching_candidate_skill: Array<Spike_Matching_Candidate_Skill>;
  /** fetch aggregated fields from the table: "spike_matching.candidate_skill" */
  spike_matching_candidate_skill_aggregate: Spike_Matching_Candidate_Skill_Aggregate;
  /** fetch data from the table: "spike_matching.candidate_skill" using primary key columns */
  spike_matching_candidate_skill_by_pk: Maybe<Spike_Matching_Candidate_Skill>;
  /** fetch data from the table: "spike_matching.job" */
  spike_matching_job: Array<Spike_Matching_Job>;
  /** fetch aggregated fields from the table: "spike_matching.job" */
  spike_matching_job_aggregate: Spike_Matching_Job_Aggregate;
  /** fetch data from the table: "spike_matching.job" using primary key columns */
  spike_matching_job_by_pk: Maybe<Spike_Matching_Job>;
  /** fetch data from the table: "spike_matching.job_skill" */
  spike_matching_job_skill: Array<Spike_Matching_Job_Skill>;
  /** fetch aggregated fields from the table: "spike_matching.job_skill" */
  spike_matching_job_skill_aggregate: Spike_Matching_Job_Skill_Aggregate;
  /** fetch data from the table: "spike_matching.job_skill" using primary key columns */
  spike_matching_job_skill_by_pk: Maybe<Spike_Matching_Job_Skill>;
  /** fetch data from the table: "spike_matching.location" */
  spike_matching_location: Array<Spike_Matching_Location>;
  /** fetch aggregated fields from the table: "spike_matching.location" */
  spike_matching_location_aggregate: Spike_Matching_Location_Aggregate;
  /** fetch data from the table: "spike_matching.location" using primary key columns */
  spike_matching_location_by_pk: Maybe<Spike_Matching_Location>;
  /** fetch data from the table: "spike_matching.location_job" */
  spike_matching_location_job: Array<Spike_Matching_Location_Job>;
  /** fetch aggregated fields from the table: "spike_matching.location_job" */
  spike_matching_location_job_aggregate: Spike_Matching_Location_Job_Aggregate;
  /** fetch data from the table: "spike_matching.location_job" using primary key columns */
  spike_matching_location_job_by_pk: Maybe<Spike_Matching_Location_Job>;
  /** execute function "spike_matching.match" which returns "spike_matching.match_result" */
  spike_matching_match: Array<Spike_Matching_Match_Result>;
  /** execute function "spike_matching.match" and query aggregates on result of table type "spike_matching.match_result" */
  spike_matching_match_aggregate: Spike_Matching_Match_Result_Aggregate;
  /** fetch data from the table: "spike_matching.match_result" */
  spike_matching_match_result: Array<Spike_Matching_Match_Result>;
  /** fetch aggregated fields from the table: "spike_matching.match_result" */
  spike_matching_match_result_aggregate: Spike_Matching_Match_Result_Aggregate;
  /** fetch data from the table: "spike_matching.match_result" using primary key columns */
  spike_matching_match_result_by_pk: Maybe<Spike_Matching_Match_Result>;
  /** fetch data from the table: "spike_matching.skill" */
  spike_matching_skill: Array<Spike_Matching_Skill>;
  /** fetch aggregated fields from the table: "spike_matching.skill" */
  spike_matching_skill_aggregate: Spike_Matching_Skill_Aggregate;
  /** fetch data from the table: "spike_matching.skill" using primary key columns */
  spike_matching_skill_by_pk: Maybe<Spike_Matching_Skill>;
  /** fetch data from the table: "terminal_celery_task_status_choices" */
  terminal_celery_task_status_choices: Array<Terminal_Celery_Task_Status_Choices>;
  /** fetch aggregated fields from the table: "terminal_celery_task_status_choices" */
  terminal_celery_task_status_choices_aggregate: Terminal_Celery_Task_Status_Choices_Aggregate;
  /** fetch data from the table: "terminal_celery_task_status_choices" using primary key columns */
  terminal_celery_task_status_choices_by_pk: Maybe<Terminal_Celery_Task_Status_Choices>;
  /** fetch data from the table: "terminal_employee" */
  terminal_employee: Array<Terminal_Employee>;
  /** fetch aggregated fields from the table: "terminal_employee" */
  terminal_employee_aggregate: Terminal_Employee_Aggregate;
  /** fetch data from the table: "terminal_employee" using primary key columns */
  terminal_employee_by_pk: Maybe<Terminal_Employee>;
  /** fetch data from the table: "terminal_employee_organization" */
  terminal_employee_organization: Array<Terminal_Employee_Organization>;
  /** fetch aggregated fields from the table: "terminal_employee_organization" */
  terminal_employee_organization_aggregate: Terminal_Employee_Organization_Aggregate;
  /** fetch data from the table: "terminal_employee_organization" using primary key columns */
  terminal_employee_organization_by_pk: Maybe<Terminal_Employee_Organization>;
  /** fetch data from the table: "terminal_employee_role_choices" */
  terminal_employee_role_choices: Array<Terminal_Employee_Role_Choices>;
  /** fetch aggregated fields from the table: "terminal_employee_role_choices" */
  terminal_employee_role_choices_aggregate: Terminal_Employee_Role_Choices_Aggregate;
  /** fetch data from the table: "terminal_employee_role_choices" using primary key columns */
  terminal_employee_role_choices_by_pk: Maybe<Terminal_Employee_Role_Choices>;
  /** fetch data from the table: "trait" */
  trait: Array<Trait>;
  /** fetch aggregated fields from the table: "trait" */
  trait_aggregate: Trait_Aggregate;
  /** fetch data from the table: "trait" using primary key columns */
  trait_by_pk: Maybe<Trait>;
  /** fetch data from the table: "transcript_evaluation" */
  transcript_evaluation: Array<Transcript_Evaluation>;
  /** fetch aggregated fields from the table: "transcript_evaluation" */
  transcript_evaluation_aggregate: Transcript_Evaluation_Aggregate;
  /** fetch data from the table: "transcript_evaluation" using primary key columns */
  transcript_evaluation_by_pk: Maybe<Transcript_Evaluation>;
  /** fetch data from the table: "transcript_skill" */
  transcript_skill: Array<Transcript_Skill>;
  /** fetch aggregated fields from the table: "transcript_skill" */
  transcript_skill_aggregate: Transcript_Skill_Aggregate;
  /** fetch data from the table: "transcript_skill" using primary key columns */
  transcript_skill_by_pk: Maybe<Transcript_Skill>;
  /** fetch data from the table: "transcript_work_experience" */
  transcript_work_experience: Array<Transcript_Work_Experience>;
  /** fetch aggregated fields from the table: "transcript_work_experience" */
  transcript_work_experience_aggregate: Transcript_Work_Experience_Aggregate;
  /** fetch data from the table: "transcript_work_experience" using primary key columns */
  transcript_work_experience_by_pk: Maybe<Transcript_Work_Experience>;
  /** fetch data from the table: "user_contact" */
  user_contact: Array<User_Contact>;
  /** fetch aggregated fields from the table: "user_contact" */
  user_contact_aggregate: User_Contact_Aggregate;
  /** fetch data from the table: "user_info" */
  user_info: Array<User_Info>;
  /** fetch aggregated fields from the table: "user_info" */
  user_info_aggregate: User_Info_Aggregate;
  /** fetch data from the table: "user_info" using primary key columns */
  user_info_by_pk: Maybe<User_Info>;
  /** fetch data from the table: "user_invitation" */
  user_invitation: Array<User_Invitation>;
  /** fetch aggregated fields from the table: "user_invitation" */
  user_invitation_aggregate: User_Invitation_Aggregate;
  /** fetch data from the table: "user_invitation" using primary key columns */
  user_invitation_by_pk: Maybe<User_Invitation>;
  /** fetch data from the table: "user_linkedin_contact" */
  user_linkedin_contact: Array<User_Linkedin_Contact>;
  /** fetch aggregated fields from the table: "user_linkedin_contact" */
  user_linkedin_contact_aggregate: User_Linkedin_Contact_Aggregate;
  /** fetch data from the table: "user_linkedin_contact" using primary key columns */
  user_linkedin_contact_by_pk: Maybe<User_Linkedin_Contact>;
  /** fetch data from the table: "user_password_blacklist" */
  user_password_blacklist: Array<User_Password_Blacklist>;
  /** fetch aggregated fields from the table: "user_password_blacklist" */
  user_password_blacklist_aggregate: User_Password_Blacklist_Aggregate;
  /** fetch data from the table: "user_password_blacklist" using primary key columns */
  user_password_blacklist_by_pk: Maybe<User_Password_Blacklist>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk: Maybe<User_Role>;
  /** fetch data from the table: "user_subscription" */
  user_subscription: Array<User_Subscription>;
  /** fetch aggregated fields from the table: "user_subscription" */
  user_subscription_aggregate: User_Subscription_Aggregate;
  /** fetch data from the table: "user_subscription" using primary key columns */
  user_subscription_by_pk: Maybe<User_Subscription>;
  /** fetch data from the table: "user_subscription_choices" */
  user_subscription_choices: Array<User_Subscription_Choices>;
  /** fetch aggregated fields from the table: "user_subscription_choices" */
  user_subscription_choices_aggregate: User_Subscription_Choices_Aggregate;
  /** fetch data from the table: "user_subscription_choices" using primary key columns */
  user_subscription_choices_by_pk: Maybe<User_Subscription_Choices>;
  /** fetch data from the table: "user_temp_blob" */
  user_temp_blob: Array<User_Temp_Blob>;
  /** fetch aggregated fields from the table: "user_temp_blob" */
  user_temp_blob_aggregate: User_Temp_Blob_Aggregate;
  /** fetch data from the table: "user_temp_blob" using primary key columns */
  user_temp_blob_by_pk: Maybe<User_Temp_Blob>;
  /** fetch data from the table: "validator" */
  validator: Array<Validator>;
  /** fetch aggregated fields from the table: "validator" */
  validator_aggregate: Validator_Aggregate;
  /** fetch data from the table: "validator" using primary key columns */
  validator_by_pk: Maybe<Validator>;
};

export type Query_RootActivity_Customer_Profile_ViewArgs = {
  distinct_on?: InputMaybe<Array<Activity_Customer_Profile_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Activity_Customer_Profile_View_Order_By>>;
  where?: InputMaybe<Activity_Customer_Profile_View_Bool_Exp>;
};

export type Query_RootActivity_Customer_Profile_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activity_Customer_Profile_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Activity_Customer_Profile_View_Order_By>>;
  where?: InputMaybe<Activity_Customer_Profile_View_Bool_Exp>;
};

export type Query_RootActivity_Customer_Profile_View_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootAi_LevelArgs = {
  distinct_on?: InputMaybe<Array<Ai_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Level_Order_By>>;
  where?: InputMaybe<Ai_Level_Bool_Exp>;
};

export type Query_RootAi_Level_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Level_Order_By>>;
  where?: InputMaybe<Ai_Level_Bool_Exp>;
};

export type Query_RootAi_Level_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootAi_RoleArgs = {
  distinct_on?: InputMaybe<Array<Ai_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Role_Order_By>>;
  where?: InputMaybe<Ai_Role_Bool_Exp>;
};

export type Query_RootAi_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Role_Order_By>>;
  where?: InputMaybe<Ai_Role_Bool_Exp>;
};

export type Query_RootAi_Role_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootAi_Role_Multiplier_FocusArgs = {
  distinct_on?: InputMaybe<Array<Ai_Role_Multiplier_Focus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Role_Multiplier_Focus_Order_By>>;
  where?: InputMaybe<Ai_Role_Multiplier_Focus_Bool_Exp>;
};

export type Query_RootAi_Role_Multiplier_Focus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Role_Multiplier_Focus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Role_Multiplier_Focus_Order_By>>;
  where?: InputMaybe<Ai_Role_Multiplier_Focus_Bool_Exp>;
};

export type Query_RootAi_Role_Multiplier_Focus_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootAi_Run_DumpArgs = {
  distinct_on?: InputMaybe<Array<Ai_Run_Dump_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Run_Dump_Order_By>>;
  where?: InputMaybe<Ai_Run_Dump_Bool_Exp>;
};

export type Query_RootAi_Run_Dump_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Run_Dump_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Run_Dump_Order_By>>;
  where?: InputMaybe<Ai_Run_Dump_Bool_Exp>;
};

export type Query_RootAi_Run_Dump_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootApplicant_ConsentArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Consent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Consent_Order_By>>;
  where?: InputMaybe<Applicant_Consent_Bool_Exp>;
};

export type Query_RootApplicant_Consent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Consent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Consent_Order_By>>;
  where?: InputMaybe<Applicant_Consent_Bool_Exp>;
};

export type Query_RootApplicant_Consent_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootApplicant_JobArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Job_Order_By>>;
  where?: InputMaybe<Applicant_Job_Bool_Exp>;
};

export type Query_RootApplicant_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Job_Order_By>>;
  where?: InputMaybe<Applicant_Job_Bool_Exp>;
};

export type Query_RootApplicant_Job_By_PkArgs = {
  candidate_id: Scalars['Int'];
  job: Scalars['Int'];
};

export type Query_RootApplicant_WaitlistArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Waitlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Waitlist_Order_By>>;
  where?: InputMaybe<Applicant_Waitlist_Bool_Exp>;
};

export type Query_RootApplicant_Waitlist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Waitlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Waitlist_Order_By>>;
  where?: InputMaybe<Applicant_Waitlist_Bool_Exp>;
};

export type Query_RootApplicant_Waitlist_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootAudit_Logged_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

export type Query_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

export type Query_RootAudit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint'];
};

export type Query_RootCalendly_EventArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Event_Order_By>>;
  where?: InputMaybe<Calendly_Event_Bool_Exp>;
};

export type Query_RootCalendly_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Event_Order_By>>;
  where?: InputMaybe<Calendly_Event_Bool_Exp>;
};

export type Query_RootCalendly_Event_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCalendly_InviteeArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Invitee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Invitee_Order_By>>;
  where?: InputMaybe<Calendly_Invitee_Bool_Exp>;
};

export type Query_RootCalendly_Invitee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Invitee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Invitee_Order_By>>;
  where?: InputMaybe<Calendly_Invitee_Bool_Exp>;
};

export type Query_RootCalendly_Invitee_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCalendly_UserArgs = {
  distinct_on?: InputMaybe<Array<Calendly_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_User_Order_By>>;
  where?: InputMaybe<Calendly_User_Bool_Exp>;
};

export type Query_RootCalendly_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendly_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_User_Order_By>>;
  where?: InputMaybe<Calendly_User_Bool_Exp>;
};

export type Query_RootCalendly_User_By_PkArgs = {
  calendly_user_id: Scalars['String'];
};

export type Query_RootCandidateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Order_By>>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

export type Query_RootCandidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Order_By>>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

export type Query_RootCandidate_Ai_Profile_SummaryArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Ai_Profile_Summary_Bool_Exp>;
};

export type Query_RootCandidate_Ai_Profile_Summary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Ai_Profile_Summary_Bool_Exp>;
};

export type Query_RootCandidate_Ai_Profile_Summary_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Applicant_Workflow_StatsArgs = {
  args: Candidate_Applicant_Workflow_Stats_Args;
  distinct_on?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Order_By>>;
  where?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>;
};

export type Query_RootCandidate_Applicant_Workflow_Stats_AggregateArgs = {
  args: Candidate_Applicant_Workflow_Stats_Args;
  distinct_on?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Order_By>>;
  where?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>;
};

export type Query_RootCandidate_Applicant_Workflow_Stats_ResultArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Order_By>>;
  where?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>;
};

export type Query_RootCandidate_Applicant_Workflow_Stats_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Order_By>>;
  where?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>;
};

export type Query_RootCandidate_Approved_CountryArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Approved_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Approved_Country_Order_By>>;
  where?: InputMaybe<Candidate_Approved_Country_Bool_Exp>;
};

export type Query_RootCandidate_Approved_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Approved_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Approved_Country_Order_By>>;
  where?: InputMaybe<Candidate_Approved_Country_Bool_Exp>;
};

export type Query_RootCandidate_Approved_Country_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Availability_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Availability_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Availability_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Availability_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Availability_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Availability_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Availability_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Availability_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Availability_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Availability_Choices_OrderArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Availability_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Availability_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Availability_Choices_Order_Bool_Exp>;
};

export type Query_RootCandidate_Availability_Choices_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Availability_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Availability_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Availability_Choices_Order_Bool_Exp>;
};

export type Query_RootCandidate_Availability_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_BadgeArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Badge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Badge_Order_By>>;
  where?: InputMaybe<Candidate_Badge_Bool_Exp>;
};

export type Query_RootCandidate_Badge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Badge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Badge_Order_By>>;
  where?: InputMaybe<Candidate_Badge_Bool_Exp>;
};

export type Query_RootCandidate_Badge_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

export type Query_RootCandidate_BrowseArgs = {
  args: Candidate_Browse_Args;
  distinct_on?: InputMaybe<Array<Candidate_Browse_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Browse_Result_Order_By>>;
  where?: InputMaybe<Candidate_Browse_Result_Bool_Exp>;
};

export type Query_RootCandidate_Browse_AggregateArgs = {
  args: Candidate_Browse_Args;
  distinct_on?: InputMaybe<Array<Candidate_Browse_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Browse_Result_Order_By>>;
  where?: InputMaybe<Candidate_Browse_Result_Bool_Exp>;
};

export type Query_RootCandidate_Browse_ResultArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Browse_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Browse_Result_Order_By>>;
  where?: InputMaybe<Candidate_Browse_Result_Bool_Exp>;
};

export type Query_RootCandidate_Browse_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Browse_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Browse_Result_Order_By>>;
  where?: InputMaybe<Candidate_Browse_Result_Bool_Exp>;
};

export type Query_RootCandidate_Browse_Result_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

export type Query_RootCandidate_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Curation_Ai_SummaryArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Ai_Summary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Ai_Summary_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Curation_Ai_Summary_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Feedback_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Ai_Summary_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Feedback_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Ai_Summary_Feedback_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Curation_Country_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Country_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Country_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Country_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Country_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Country_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Country_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Country_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Country_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_Desired_Salary_Currency_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Desired_Salary_Currency_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Desired_Salary_Currency_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_Desired_Salary_Frequency_ChoicesArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Desired_Salary_Frequency_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Desired_Salary_Frequency_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_DetailArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Detail_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Detail_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Detail_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Detail_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Detail_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Curation_Detail_Recruiter_Summary_LogArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Detail_Recruiter_Summary_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Detail_Recruiter_Summary_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Curation_English_Proficiency_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_English_Proficiency_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_English_Proficiency_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_Equity_Importance_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Equity_Importance_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Equity_Importance_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_NoteArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Note_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Note_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Note_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Note_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Note_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Note_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Curation_Rejected_Reason_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Rejected_Reason_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Rejected_Reason_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_Remote_Work_Exp_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Remote_Work_Exp_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Remote_Work_Exp_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_Visa_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Visa_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Visa_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_WorkflowArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Curation_Workflow_Needs_Info_ReasonArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Needs_Info_Reason_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Needs_Info_Reason_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Curation_Workflow_Needs_Info_Reason_ChoicesArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Needs_Info_Reason_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Needs_Info_Reason_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_Workflow_Screener_Type_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Screener_Type_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Screener_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_Workflow_Source_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Source_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_Workflow_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_Workflow_Status_LogArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Status_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Workflow_Status_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Curation_Years_Of_Exp_Range_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Years_Of_Exp_Range_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Years_Of_Exp_Range_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Curation_Years_Of_Exp_Range_Choices_OrderArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Years_Of_Exp_Range_Choices_Order_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp>;
};

export type Query_RootCandidate_Curation_Years_Of_Exp_Range_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Detail_Level_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Detail_Level_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Detail_Level_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Detail_Level_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Detail_Level_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Detail_Level_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Detail_Level_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Detail_Level_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Detail_Level_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_EducationArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Order_By>>;
  where?: InputMaybe<Candidate_Education_Bool_Exp>;
};

export type Query_RootCandidate_Education_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Order_By>>;
  where?: InputMaybe<Candidate_Education_Bool_Exp>;
};

export type Query_RootCandidate_Education_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Education_Concentration_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Education_Concentration_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Education_Concentration_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Education_Concentration_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Education_Concentration_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Education_Degree_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Degree_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Degree_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Education_Degree_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Education_Degree_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Degree_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Degree_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Education_Degree_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Education_Degree_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_EmailArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Email_Order_By>>;
  where?: InputMaybe<Candidate_Email_Bool_Exp>;
};

export type Query_RootCandidate_Email_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Email_Order_By>>;
  where?: InputMaybe<Candidate_Email_Bool_Exp>;
};

export type Query_RootCandidate_Email_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_EmbeddingArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Embedding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Embedding_Order_By>>;
  where?: InputMaybe<Candidate_Embedding_Bool_Exp>;
};

export type Query_RootCandidate_Embedding_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Embedding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Embedding_Order_By>>;
  where?: InputMaybe<Candidate_Embedding_Bool_Exp>;
};

export type Query_RootCandidate_Embedding_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Highlight_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Highlight_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Highlight_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Highlight_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Highlight_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Highlight_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Highlight_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Highlight_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Highlight_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Hub_LocationArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Hub_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Hub_Location_Order_By>>;
  where?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
};

export type Query_RootCandidate_Hub_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Hub_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Hub_Location_Order_By>>;
  where?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
};

export type Query_RootCandidate_Hub_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Interview_InviteArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Interview_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Interview_Invite_Order_By>>;
  where?: InputMaybe<Candidate_Interview_Invite_Bool_Exp>;
};

export type Query_RootCandidate_Interview_Invite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Interview_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Interview_Invite_Order_By>>;
  where?: InputMaybe<Candidate_Interview_Invite_Bool_Exp>;
};

export type Query_RootCandidate_Interview_Invite_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_JobArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Order_By>>;
  where?: InputMaybe<Candidate_Job_Bool_Exp>;
};

export type Query_RootCandidate_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Order_By>>;
  where?: InputMaybe<Candidate_Job_Bool_Exp>;
};

export type Query_RootCandidate_Job_By_PkArgs = {
  candidate_id: Scalars['Int'];
  job: Scalars['Int'];
};

export type Query_RootCandidate_Job_WorkflowArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
};

export type Query_RootCandidate_Job_Workflow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
};

export type Query_RootCandidate_Job_Workflow_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Job_Workflow_Source_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Job_Workflow_Source_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Job_Workflow_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Job_Workflow_StatusArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Status_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Bool_Exp>;
};

export type Query_RootCandidate_Job_Workflow_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Status_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Bool_Exp>;
};

export type Query_RootCandidate_Job_Workflow_Status_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Job_Workflow_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Job_Workflow_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Job_Workflow_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Parsed_ResumeArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Parsed_Resume_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Parsed_Resume_Order_By>>;
  where?: InputMaybe<Candidate_Parsed_Resume_Bool_Exp>;
};

export type Query_RootCandidate_Parsed_Resume_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Parsed_Resume_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Parsed_Resume_Order_By>>;
  where?: InputMaybe<Candidate_Parsed_Resume_Bool_Exp>;
};

export type Query_RootCandidate_Parsed_Resume_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

export type Query_RootCandidate_PdlArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Pdl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Pdl_Order_By>>;
  where?: InputMaybe<Candidate_Pdl_Bool_Exp>;
};

export type Query_RootCandidate_Pdl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Pdl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Pdl_Order_By>>;
  where?: InputMaybe<Candidate_Pdl_Bool_Exp>;
};

export type Query_RootCandidate_Pdl_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_ReferralsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Referrals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Referrals_Order_By>>;
  where?: InputMaybe<Candidate_Referrals_Bool_Exp>;
};

export type Query_RootCandidate_Referrals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Referrals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Referrals_Order_By>>;
  where?: InputMaybe<Candidate_Referrals_Bool_Exp>;
};

export type Query_RootCandidate_Referrals_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_RoleArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Order_By>>;
  where?: InputMaybe<Candidate_Role_Bool_Exp>;
};

export type Query_RootCandidate_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Order_By>>;
  where?: InputMaybe<Candidate_Role_Bool_Exp>;
};

export type Query_RootCandidate_Role_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Role_SkillArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Role_Skill_Bool_Exp>;
};

export type Query_RootCandidate_Role_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Role_Skill_Bool_Exp>;
};

export type Query_RootCandidate_Role_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Sdlc_Experience_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Sdlc_Experience_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Sdlc_Experience_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Sdlc_Experience_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Sdlc_Experience_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_SkillArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Bool_Exp>;
};

export type Query_RootCandidate_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Bool_Exp>;
};

export type Query_RootCandidate_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Skill_Comp_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Comp_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Skill_Comp_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Comp_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Skill_Comp_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Source_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Source_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Source_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Source_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Stage_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Stage_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Stage_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Stage_Choices_OrderArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Choices_Order_Bool_Exp>;
};

export type Query_RootCandidate_Stage_Choices_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Choices_Order_Bool_Exp>;
};

export type Query_RootCandidate_Stage_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Stage_LogArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Log_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Log_Bool_Exp>;
};

export type Query_RootCandidate_Stage_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Log_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Log_Bool_Exp>;
};

export type Query_RootCandidate_Stage_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Stage_Source_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Source_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Stage_Source_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Source_Choices_Bool_Exp>;
};

export type Query_RootCandidate_Stage_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCandidate_Suggested_JobArgs = {
  args: Candidate_Suggested_Job_Args;
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Query_RootCandidate_Suggested_Job_AggregateArgs = {
  args: Candidate_Suggested_Job_Args;
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Query_RootCandidate_Suggested_Job_CoreArgs = {
  args: Candidate_Suggested_Job_Core_Args;
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Query_RootCandidate_Suggested_Job_Core_AggregateArgs = {
  args: Candidate_Suggested_Job_Core_Args;
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Query_RootCandidate_TraitArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Trait_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Trait_Order_By>>;
  where?: InputMaybe<Candidate_Trait_Bool_Exp>;
};

export type Query_RootCandidate_Trait_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Trait_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Trait_Order_By>>;
  where?: InputMaybe<Candidate_Trait_Bool_Exp>;
};

export type Query_RootCandidate_Trait_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Work_ExperienceArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
};

export type Query_RootCandidate_Work_Experience_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
};

export type Query_RootCandidate_Work_Experience_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidate_Work_Experience_SkillArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Skill_Bool_Exp>;
};

export type Query_RootCandidate_Work_Experience_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Skill_Bool_Exp>;
};

export type Query_RootCandidate_Work_Experience_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCandidates_In_Interview_Or_Rejected_By_OrganizationArgs = {
  args: Candidates_In_Interview_Or_Rejected_By_Organization_Args;
  distinct_on?: InputMaybe<
    Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Order_By>>;
  where?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>;
};

export type Query_RootCandidates_In_Interview_Or_Rejected_By_Organization_AggregateArgs = {
  args: Candidates_In_Interview_Or_Rejected_By_Organization_Args;
  distinct_on?: InputMaybe<
    Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Order_By>>;
  where?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>;
};

export type Query_RootCandidates_In_Interview_Or_Rejected_By_Organization_ResultArgs = {
  distinct_on?: InputMaybe<
    Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Order_By>>;
  where?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>;
};

export type Query_RootCandidates_In_Interview_Or_Rejected_By_Organization_Result_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Order_By>>;
  where?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>;
};

export type Query_RootCelery_Celery_TaskmetaArgs = {
  distinct_on?: InputMaybe<Array<Celery_Celery_Taskmeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Celery_Taskmeta_Order_By>>;
  where?: InputMaybe<Celery_Celery_Taskmeta_Bool_Exp>;
};

export type Query_RootCelery_Celery_Taskmeta_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Celery_Celery_Taskmeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Celery_Taskmeta_Order_By>>;
  where?: InputMaybe<Celery_Celery_Taskmeta_Bool_Exp>;
};

export type Query_RootCelery_Celery_Taskmeta_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCelery_Celery_TasksetmetaArgs = {
  distinct_on?: InputMaybe<Array<Celery_Celery_Tasksetmeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Celery_Tasksetmeta_Order_By>>;
  where?: InputMaybe<Celery_Celery_Tasksetmeta_Bool_Exp>;
};

export type Query_RootCelery_Celery_Tasksetmeta_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Celery_Celery_Tasksetmeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Celery_Tasksetmeta_Order_By>>;
  where?: InputMaybe<Celery_Celery_Tasksetmeta_Bool_Exp>;
};

export type Query_RootCelery_Celery_Tasksetmeta_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCelery_Task_Retry_LogArgs = {
  distinct_on?: InputMaybe<Array<Celery_Task_Retry_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Task_Retry_Log_Order_By>>;
  where?: InputMaybe<Celery_Task_Retry_Log_Bool_Exp>;
};

export type Query_RootCelery_Task_Retry_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Celery_Task_Retry_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Task_Retry_Log_Order_By>>;
  where?: InputMaybe<Celery_Task_Retry_Log_Bool_Exp>;
};

export type Query_RootCelery_Task_Retry_Log_By_PkArgs = {
  new_task_id: Scalars['String'];
  original_task_id: Scalars['String'];
};

export type Query_RootClient_ProspectArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Order_By>>;
  where?: InputMaybe<Client_Prospect_Bool_Exp>;
};

export type Query_RootClient_Prospect_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Order_By>>;
  where?: InputMaybe<Client_Prospect_Bool_Exp>;
};

export type Query_RootClient_Prospect_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootClient_Prospect_EventArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Event_Order_By>>;
  where?: InputMaybe<Client_Prospect_Event_Bool_Exp>;
};

export type Query_RootClient_Prospect_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Event_Order_By>>;
  where?: InputMaybe<Client_Prospect_Event_Bool_Exp>;
};

export type Query_RootClient_Prospect_Event_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootClient_Prospect_Events_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Events_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Events_Choices_Order_By>>;
  where?: InputMaybe<Client_Prospect_Events_Choices_Bool_Exp>;
};

export type Query_RootClient_Prospect_Events_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Events_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Events_Choices_Order_By>>;
  where?: InputMaybe<Client_Prospect_Events_Choices_Bool_Exp>;
};

export type Query_RootClient_Prospect_Events_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootClient_Prospect_RoleArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
};

export type Query_RootClient_Prospect_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
};

export type Query_RootClient_Prospect_Role_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootClient_Prospect_Role_SkillArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Skill_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Skill_Bool_Exp>;
};

export type Query_RootClient_Prospect_Role_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Skill_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Skill_Bool_Exp>;
};

export type Query_RootClient_Prospect_Role_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootClient_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Client_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Versions_Order_By>>;
  where?: InputMaybe<Client_Versions_Bool_Exp>;
};

export type Query_RootClient_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Versions_Order_By>>;
  where?: InputMaybe<Client_Versions_Bool_Exp>;
};

export type Query_RootClient_Versions_By_PkArgs = {
  version: Scalars['String'];
};

export type Query_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Query_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Query_RootCompany_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCompany_Name_SynonymArgs = {
  distinct_on?: InputMaybe<Array<Company_Name_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Name_Synonym_Order_By>>;
  where?: InputMaybe<Company_Name_Synonym_Bool_Exp>;
};

export type Query_RootCompany_Name_Synonym_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Name_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Name_Synonym_Order_By>>;
  where?: InputMaybe<Company_Name_Synonym_Bool_Exp>;
};

export type Query_RootCompany_Name_Synonym_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCompany_Pdl_BlobArgs = {
  distinct_on?: InputMaybe<Array<Company_Pdl_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Pdl_Blob_Order_By>>;
  where?: InputMaybe<Company_Pdl_Blob_Bool_Exp>;
};

export type Query_RootCompany_Pdl_Blob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Pdl_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Pdl_Blob_Order_By>>;
  where?: InputMaybe<Company_Pdl_Blob_Bool_Exp>;
};

export type Query_RootCompany_Pdl_Blob_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCompany_Size_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Company_Size_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Size_Choices_Order_By>>;
  where?: InputMaybe<Company_Size_Choices_Bool_Exp>;
};

export type Query_RootCompany_Size_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Size_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Size_Choices_Order_By>>;
  where?: InputMaybe<Company_Size_Choices_Bool_Exp>;
};

export type Query_RootCompany_Size_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootContactArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type Query_RootContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type Query_RootContact_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootContract_Length_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Contract_Length_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Length_Choices_Order_By>>;
  where?: InputMaybe<Contract_Length_Choices_Bool_Exp>;
};

export type Query_RootContract_Length_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Length_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Length_Choices_Order_By>>;
  where?: InputMaybe<Contract_Length_Choices_Bool_Exp>;
};

export type Query_RootContract_Length_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCountry_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Country_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Choices_Order_By>>;
  where?: InputMaybe<Country_Choices_Bool_Exp>;
};

export type Query_RootCountry_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Choices_Order_By>>;
  where?: InputMaybe<Country_Choices_Bool_Exp>;
};

export type Query_RootCountry_Choices_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCurrency_Exchange_RateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Exchange_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Exchange_Rate_Order_By>>;
  where?: InputMaybe<Currency_Exchange_Rate_Bool_Exp>;
};

export type Query_RootCurrency_Exchange_Rate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Exchange_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Exchange_Rate_Order_By>>;
  where?: InputMaybe<Currency_Exchange_Rate_Bool_Exp>;
};

export type Query_RootCurrency_Exchange_Rate_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootDayforce_AssignmentArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Assignment_Order_By>>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

export type Query_RootDayforce_Assignment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Assignment_Order_By>>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

export type Query_RootDayforce_Assignment_By_PkArgs = {
  dayforce_customer_id: Scalars['Int'];
  dayforce_employee_id: Scalars['Int'];
  dayforce_location_id: Scalars['Int'];
  effective_start: Scalars['timestamp'];
};

export type Query_RootDayforce_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Customer_Order_By>>;
  where?: InputMaybe<Dayforce_Customer_Bool_Exp>;
};

export type Query_RootDayforce_Customer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Customer_Order_By>>;
  where?: InputMaybe<Dayforce_Customer_Bool_Exp>;
};

export type Query_RootDayforce_Customer_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootDayforce_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Employee_Order_By>>;
  where?: InputMaybe<Dayforce_Employee_Bool_Exp>;
};

export type Query_RootDayforce_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Employee_Order_By>>;
  where?: InputMaybe<Dayforce_Employee_Bool_Exp>;
};

export type Query_RootDayforce_Employee_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootDayforce_LocationArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Location_Order_By>>;
  where?: InputMaybe<Dayforce_Location_Bool_Exp>;
};

export type Query_RootDayforce_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Location_Order_By>>;
  where?: InputMaybe<Dayforce_Location_Bool_Exp>;
};

export type Query_RootDayforce_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEmail_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Email_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Notification_Order_By>>;
  where?: InputMaybe<Email_Notification_Bool_Exp>;
};

export type Query_RootEmail_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Notification_Order_By>>;
  where?: InputMaybe<Email_Notification_Bool_Exp>;
};

export type Query_RootEmail_Notification_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEmail_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Email_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Template_Order_By>>;
  where?: InputMaybe<Email_Template_Bool_Exp>;
};

export type Query_RootEmail_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Template_Order_By>>;
  where?: InputMaybe<Email_Template_Bool_Exp>;
};

export type Query_RootEmail_Template_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEmployment_Type_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Employment_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employment_Type_Choices_Order_By>>;
  where?: InputMaybe<Employment_Type_Choices_Bool_Exp>;
};

export type Query_RootEmployment_Type_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employment_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employment_Type_Choices_Order_By>>;
  where?: InputMaybe<Employment_Type_Choices_Bool_Exp>;
};

export type Query_RootEmployment_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootExplore_CandidatesArgs = {
  args: ExploreCandidatesArgs;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Query_RootExplore_Candidates_Popular_SearchArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Popular_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Popular_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Popular_Search_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Popular_Search_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Popular_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Popular_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Popular_Search_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Popular_Search_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootExplore_Candidates_QueryArgs = {
  args: Explore_Candidates_Query_Args;
  distinct_on?: InputMaybe<Array<Explore_Candidates_Query_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Query_Result_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Query_Result_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Query_AggregateArgs = {
  args: Explore_Candidates_Query_Args;
  distinct_on?: InputMaybe<Array<Explore_Candidates_Query_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Query_Result_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Query_Result_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Query_ResultArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Query_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Query_Result_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Query_Result_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Query_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Query_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Query_Result_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Query_Result_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Query_Result_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

export type Query_RootExplore_Candidates_SearchArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Search_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Search_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootExplore_Candidates_Search_Candidate_HighlightArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Search_Candidate_Highlight_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Search_Candidate_Highlight_By_PkArgs = {
  candidate_highlight: Candidate_Highlight_Choices_Enum;
  explore_candidates_search_id: Scalars['Int'];
};

export type Query_RootExplore_Candidates_Search_Must_Have_SkillArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Search_Must_Have_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Search_Must_Have_Skill_By_PkArgs = {
  explore_candidates_search_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

export type Query_RootExplore_Candidates_Search_Nice_To_Have_SkillArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Search_Nice_To_Have_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Search_Nice_To_Have_Skill_By_PkArgs = {
  explore_candidates_search_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

export type Query_RootExplore_Candidates_Search_RegionArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Region_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Region_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Search_Region_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Region_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Region_Bool_Exp>;
};

export type Query_RootExplore_Candidates_Search_Region_By_PkArgs = {
  explore_candidates_search_id: Scalars['Int'];
  region: Region_Choices_Enum;
};

export type Query_RootExplore_Candidates_User_Saved_SearchArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_User_Saved_Search_Bool_Exp>;
};

export type Query_RootExplore_Candidates_User_Saved_Search_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_User_Saved_Search_Bool_Exp>;
};

export type Query_RootExplore_Candidates_User_Saved_Search_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootExternal_Candidate_MetaArgs = {
  distinct_on?: InputMaybe<Array<External_Candidate_Meta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<External_Candidate_Meta_Order_By>>;
  where?: InputMaybe<External_Candidate_Meta_Bool_Exp>;
};

export type Query_RootExternal_Candidate_Meta_AggregateArgs = {
  distinct_on?: InputMaybe<Array<External_Candidate_Meta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<External_Candidate_Meta_Order_By>>;
  where?: InputMaybe<External_Candidate_Meta_Bool_Exp>;
};

export type Query_RootExternal_Candidate_Meta_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootFeatureArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Query_RootFeature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Query_RootFeature_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootHireflix_TranscriptArgs = {
  distinct_on?: InputMaybe<Array<Hireflix_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hireflix_Transcript_Order_By>>;
  where?: InputMaybe<Hireflix_Transcript_Bool_Exp>;
};

export type Query_RootHireflix_Transcript_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hireflix_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hireflix_Transcript_Order_By>>;
  where?: InputMaybe<Hireflix_Transcript_Bool_Exp>;
};

export type Query_RootHireflix_Transcript_BlobArgs = {
  distinct_on?: InputMaybe<Array<Hireflix_Transcript_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hireflix_Transcript_Blob_Order_By>>;
  where?: InputMaybe<Hireflix_Transcript_Blob_Bool_Exp>;
};

export type Query_RootHireflix_Transcript_Blob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hireflix_Transcript_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hireflix_Transcript_Blob_Order_By>>;
  where?: InputMaybe<Hireflix_Transcript_Blob_Bool_Exp>;
};

export type Query_RootHireflix_Transcript_Blob_By_PkArgs = {
  interview_id: Scalars['String'];
};

export type Query_RootHireflix_Transcript_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootHr_TestArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Order_By>>;
  where?: InputMaybe<Hr_Test_Bool_Exp>;
};

export type Query_RootHr_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Order_By>>;
  where?: InputMaybe<Hr_Test_Bool_Exp>;
};

export type Query_RootHr_Test_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootHr_Test_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Candidate_Order_By>>;
  where?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
};

export type Query_RootHr_Test_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Candidate_Order_By>>;
  where?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
};

export type Query_RootHr_Test_Candidate_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootHr_Test_ResultArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Result_Order_By>>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

export type Query_RootHr_Test_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Result_Order_By>>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

export type Query_RootHr_Test_Result_By_PkArgs = {
  attempt_id: Scalars['String'];
  hr_test_candidate_id: Scalars['String'];
};

export type Query_RootIcims_Applicant_WorkflowArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

export type Query_RootIcims_Applicant_Workflow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

export type Query_RootIcims_Applicant_Workflow_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

export type Query_RootIcims_Applicant_Workflow_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
};

export type Query_RootIcims_Applicant_Workflow_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
};

export type Query_RootIcims_Applicant_Workflow_Feedback_By_PkArgs = {
  applicant_workflow_profile_id: Scalars['Int'];
  id: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
};

export type Query_RootIcims_Applicant_Workflow_StatusArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

export type Query_RootIcims_Applicant_Workflow_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

export type Query_RootIcims_Applicant_Workflow_Status_By_PkArgs = {
  applicant_workflow_profile_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  initial_data_transfer: Scalars['Int'];
  status_id: Scalars['String'];
};

export type Query_RootIcims_Applicant_Workflow_Status_CurrentArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

export type Query_RootIcims_Applicant_Workflow_Status_Current_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

export type Query_RootIcims_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Icims_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Company_Order_By>>;
  where?: InputMaybe<Icims_Company_Bool_Exp>;
};

export type Query_RootIcims_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Company_Order_By>>;
  where?: InputMaybe<Icims_Company_Bool_Exp>;
};

export type Query_RootIcims_Company_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

export type Query_RootIcims_CountryArgs = {
  distinct_on?: InputMaybe<Array<Icims_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Country_Order_By>>;
  where?: InputMaybe<Icims_Country_Bool_Exp>;
};

export type Query_RootIcims_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Country_Order_By>>;
  where?: InputMaybe<Icims_Country_Bool_Exp>;
};

export type Query_RootIcims_Country_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Query_RootIcims_DegreeArgs = {
  distinct_on?: InputMaybe<Array<Icims_Degree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Degree_Order_By>>;
  where?: InputMaybe<Icims_Degree_Bool_Exp>;
};

export type Query_RootIcims_Degree_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Degree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Degree_Order_By>>;
  where?: InputMaybe<Icims_Degree_Bool_Exp>;
};

export type Query_RootIcims_Degree_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Query_RootIcims_EducationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Education_Order_By>>;
  where?: InputMaybe<Icims_Education_Bool_Exp>;
};

export type Query_RootIcims_Education_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Education_Order_By>>;
  where?: InputMaybe<Icims_Education_Bool_Exp>;
};

export type Query_RootIcims_Education_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

export type Query_RootIcims_FolderArgs = {
  distinct_on?: InputMaybe<Array<Icims_Folder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Folder_Order_By>>;
  where?: InputMaybe<Icims_Folder_Bool_Exp>;
};

export type Query_RootIcims_Folder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Folder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Folder_Order_By>>;
  where?: InputMaybe<Icims_Folder_Bool_Exp>;
};

export type Query_RootIcims_Folder_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Query_RootIcims_Initial_Data_TransferArgs = {
  distinct_on?: InputMaybe<Array<Icims_Initial_Data_Transfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Initial_Data_Transfer_Order_By>>;
  where?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
};

export type Query_RootIcims_Initial_Data_Transfer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Initial_Data_Transfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Initial_Data_Transfer_Order_By>>;
  where?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
};

export type Query_RootIcims_Initial_Data_Transfer_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootIcims_Interested_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Interested_Location_Bool_Exp>;
};

export type Query_RootIcims_Interested_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Interested_Location_Bool_Exp>;
};

export type Query_RootIcims_Interested_Location_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Query_RootIcims_JobArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Query_RootIcims_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Query_RootIcims_Job_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

export type Query_RootIcims_Job_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Location_Bool_Exp>;
};

export type Query_RootIcims_Job_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Location_Bool_Exp>;
};

export type Query_RootIcims_Job_Location_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
};

export type Query_RootIcims_Job_Requested_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
};

export type Query_RootIcims_Job_Requested_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
};

export type Query_RootIcims_Job_Requested_Location_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
  location: Scalars['String'];
};

export type Query_RootIcims_Job_SkillArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Skill_Order_By>>;
  where?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
};

export type Query_RootIcims_Job_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Skill_Order_By>>;
  where?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
};

export type Query_RootIcims_Job_Skill_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
};

export type Query_RootIcims_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Location_Order_By>>;
  where?: InputMaybe<Icims_Location_Bool_Exp>;
};

export type Query_RootIcims_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Location_Order_By>>;
  where?: InputMaybe<Icims_Location_Bool_Exp>;
};

export type Query_RootIcims_Location_By_PkArgs = {
  id: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
};

export type Query_RootIcims_MajorArgs = {
  distinct_on?: InputMaybe<Array<Icims_Major_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Major_Order_By>>;
  where?: InputMaybe<Icims_Major_Bool_Exp>;
};

export type Query_RootIcims_Major_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Major_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Major_Order_By>>;
  where?: InputMaybe<Icims_Major_Bool_Exp>;
};

export type Query_RootIcims_Major_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Query_RootIcims_PersonArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Order_By>>;
  where?: InputMaybe<Icims_Person_Bool_Exp>;
};

export type Query_RootIcims_Person_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Order_By>>;
  where?: InputMaybe<Icims_Person_Bool_Exp>;
};

export type Query_RootIcims_Person_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

export type Query_RootIcims_Person_Interested_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
};

export type Query_RootIcims_Person_Interested_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
};

export type Query_RootIcims_Person_Interested_Location_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  interested_location: Scalars['String'];
  person_profile_id: Scalars['Int'];
};

export type Query_RootIcims_Person_Preferred_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Preferred_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Preferred_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Preferred_Location_Bool_Exp>;
};

export type Query_RootIcims_Person_Preferred_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Preferred_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Preferred_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Preferred_Location_Bool_Exp>;
};

export type Query_RootIcims_Person_Preferred_Location_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

export type Query_RootIcims_Person_SkillArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Skill_Order_By>>;
  where?: InputMaybe<Icims_Person_Skill_Bool_Exp>;
};

export type Query_RootIcims_Person_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Skill_Order_By>>;
  where?: InputMaybe<Icims_Person_Skill_Bool_Exp>;
};

export type Query_RootIcims_Person_Skill_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

export type Query_RootIcims_Profile_Applicant_WorkflowArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Profile_Applicant_Workflow_Bool_Exp>;
};

export type Query_RootIcims_Profile_Applicant_Workflow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Profile_Applicant_Workflow_Bool_Exp>;
};

export type Query_RootIcims_Profile_Applicant_Workflow_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootIcims_Profile_JobArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Job_Order_By>>;
  where?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
};

export type Query_RootIcims_Profile_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Job_Order_By>>;
  where?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
};

export type Query_RootIcims_Profile_Job_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootIcims_Profile_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Location_Order_By>>;
  where?: InputMaybe<Icims_Profile_Location_Bool_Exp>;
};

export type Query_RootIcims_Profile_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Location_Order_By>>;
  where?: InputMaybe<Icims_Profile_Location_Bool_Exp>;
};

export type Query_RootIcims_Profile_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootIcims_Profile_PersonArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Person_Order_By>>;
  where?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
};

export type Query_RootIcims_Profile_Person_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Person_Order_By>>;
  where?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
};

export type Query_RootIcims_Profile_Person_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootIcims_Profile_Requested_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Profile_Requested_Location_Bool_Exp>;
};

export type Query_RootIcims_Profile_Requested_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Profile_Requested_Location_Bool_Exp>;
};

export type Query_RootIcims_Profile_Requested_Location_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootIcims_Requested_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Requested_Location_Bool_Exp>;
};

export type Query_RootIcims_Requested_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Requested_Location_Bool_Exp>;
};

export type Query_RootIcims_Requested_Location_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Query_RootIcims_SchoolArgs = {
  distinct_on?: InputMaybe<Array<Icims_School_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_School_Order_By>>;
  where?: InputMaybe<Icims_School_Bool_Exp>;
};

export type Query_RootIcims_School_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_School_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_School_Order_By>>;
  where?: InputMaybe<Icims_School_Bool_Exp>;
};

export type Query_RootIcims_School_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Query_RootIcims_SkillArgs = {
  distinct_on?: InputMaybe<Array<Icims_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Skill_Order_By>>;
  where?: InputMaybe<Icims_Skill_Bool_Exp>;
};

export type Query_RootIcims_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Skill_Order_By>>;
  where?: InputMaybe<Icims_Skill_Bool_Exp>;
};

export type Query_RootIcims_Skill_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Query_RootIcims_StatusArgs = {
  distinct_on?: InputMaybe<Array<Icims_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Status_Order_By>>;
  where?: InputMaybe<Icims_Status_Bool_Exp>;
};

export type Query_RootIcims_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Status_Order_By>>;
  where?: InputMaybe<Icims_Status_Bool_Exp>;
};

export type Query_RootIcims_Status_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Query_RootIcims_Sync_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Icims_Sync_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Sync_Status_Choices_Order_By>>;
  where?: InputMaybe<Icims_Sync_Status_Choices_Bool_Exp>;
};

export type Query_RootIcims_Sync_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Sync_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Sync_Status_Choices_Order_By>>;
  where?: InputMaybe<Icims_Sync_Status_Choices_Bool_Exp>;
};

export type Query_RootIcims_Sync_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootIcims_Work_ExperienceArgs = {
  distinct_on?: InputMaybe<Array<Icims_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Work_Experience_Order_By>>;
  where?: InputMaybe<Icims_Work_Experience_Bool_Exp>;
};

export type Query_RootIcims_Work_Experience_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Work_Experience_Order_By>>;
  where?: InputMaybe<Icims_Work_Experience_Bool_Exp>;
};

export type Query_RootIcims_Work_Experience_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

export type Query_RootIndustryArgs = {
  distinct_on?: InputMaybe<Array<Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Industry_Order_By>>;
  where?: InputMaybe<Industry_Bool_Exp>;
};

export type Query_RootIndustry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Industry_Order_By>>;
  where?: InputMaybe<Industry_Bool_Exp>;
};

export type Query_RootIndustry_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootInitial_Sell_Type_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Initial_Sell_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Initial_Sell_Type_Choices_Order_By>>;
  where?: InputMaybe<Initial_Sell_Type_Choices_Bool_Exp>;
};

export type Query_RootInitial_Sell_Type_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Initial_Sell_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Initial_Sell_Type_Choices_Order_By>>;
  where?: InputMaybe<Initial_Sell_Type_Choices_Bool_Exp>;
};

export type Query_RootInitial_Sell_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootInterview_DetailArgs = {
  distinct_on?: InputMaybe<Array<Interview_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Detail_Order_By>>;
  where?: InputMaybe<Interview_Detail_Bool_Exp>;
};

export type Query_RootInterview_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Detail_Order_By>>;
  where?: InputMaybe<Interview_Detail_Bool_Exp>;
};

export type Query_RootInterview_Detail_By_PkArgs = {
  job_id: Scalars['Int'];
  order: Scalars['smallint'];
};

export type Query_RootInterview_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Bool_Exp>;
};

export type Query_RootInterview_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Bool_Exp>;
};

export type Query_RootInterview_Feedback_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootInterview_Feedback_Rating_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Rating_Choices_Bool_Exp>;
};

export type Query_RootInterview_Feedback_Rating_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Rating_Choices_Bool_Exp>;
};

export type Query_RootInterview_Feedback_Rating_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootInterview_Feedback_RequestArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Request_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Request_Bool_Exp>;
};

export type Query_RootInterview_Feedback_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Request_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Request_Bool_Exp>;
};

export type Query_RootInterview_Feedback_Request_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootInterview_RequestArgs = {
  distinct_on?: InputMaybe<Array<Interview_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Request_Order_By>>;
  where?: InputMaybe<Interview_Request_Bool_Exp>;
};

export type Query_RootInterview_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Request_Order_By>>;
  where?: InputMaybe<Interview_Request_Bool_Exp>;
};

export type Query_RootInterview_Request_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootInterview_Type_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Interview_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Type_Choices_Order_By>>;
  where?: InputMaybe<Interview_Type_Choices_Bool_Exp>;
};

export type Query_RootInterview_Type_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Type_Choices_Order_By>>;
  where?: InputMaybe<Interview_Type_Choices_Bool_Exp>;
};

export type Query_RootInterview_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

export type Query_RootJob_Acceptable_LocationArgs = {
  distinct_on?: InputMaybe<Array<Job_Acceptable_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Acceptable_Location_Order_By>>;
  where?: InputMaybe<Job_Acceptable_Location_Bool_Exp>;
};

export type Query_RootJob_Acceptable_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Acceptable_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Acceptable_Location_Order_By>>;
  where?: InputMaybe<Job_Acceptable_Location_Bool_Exp>;
};

export type Query_RootJob_Acceptable_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

export type Query_RootJob_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootJob_CodeArgs = {
  distinct_on?: InputMaybe<Array<Job_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Code_Order_By>>;
  where?: InputMaybe<Job_Code_Bool_Exp>;
};

export type Query_RootJob_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Code_Order_By>>;
  where?: InputMaybe<Job_Code_Bool_Exp>;
};

export type Query_RootJob_Code_By_PkArgs = {
  code: Scalars['String'];
};

export type Query_RootJob_Level_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Job_Level_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Level_Choices_Order_By>>;
  where?: InputMaybe<Job_Level_Choices_Bool_Exp>;
};

export type Query_RootJob_Level_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Level_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Level_Choices_Order_By>>;
  where?: InputMaybe<Job_Level_Choices_Bool_Exp>;
};

export type Query_RootJob_Level_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootJob_Meta_InfoArgs = {
  distinct_on?: InputMaybe<Array<Job_Meta_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Meta_Info_Order_By>>;
  where?: InputMaybe<Job_Meta_Info_Bool_Exp>;
};

export type Query_RootJob_Meta_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Meta_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Meta_Info_Order_By>>;
  where?: InputMaybe<Job_Meta_Info_Bool_Exp>;
};

export type Query_RootJob_Meta_Info_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootJob_Nice_To_Have_SkillArgs = {
  distinct_on?: InputMaybe<Array<Job_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Job_Nice_To_Have_Skill_Bool_Exp>;
};

export type Query_RootJob_Nice_To_Have_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Job_Nice_To_Have_Skill_Bool_Exp>;
};

export type Query_RootJob_Nice_To_Have_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootJob_PositionArgs = {
  distinct_on?: InputMaybe<Array<Job_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Position_Order_By>>;
  where?: InputMaybe<Job_Position_Bool_Exp>;
};

export type Query_RootJob_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Position_Order_By>>;
  where?: InputMaybe<Job_Position_Bool_Exp>;
};

export type Query_RootJob_Position_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootJob_Priority_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Job_Priority_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Priority_Choices_Order_By>>;
  where?: InputMaybe<Job_Priority_Choices_Bool_Exp>;
};

export type Query_RootJob_Priority_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Priority_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Priority_Choices_Order_By>>;
  where?: InputMaybe<Job_Priority_Choices_Bool_Exp>;
};

export type Query_RootJob_Priority_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootJob_Required_SkillArgs = {
  distinct_on?: InputMaybe<Array<Job_Required_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Required_Skill_Order_By>>;
  where?: InputMaybe<Job_Required_Skill_Bool_Exp>;
};

export type Query_RootJob_Required_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Required_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Required_Skill_Order_By>>;
  where?: InputMaybe<Job_Required_Skill_Bool_Exp>;
};

export type Query_RootJob_Required_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootJob_Role_Source_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Job_Role_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Role_Source_Choices_Order_By>>;
  where?: InputMaybe<Job_Role_Source_Choices_Bool_Exp>;
};

export type Query_RootJob_Role_Source_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Role_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Role_Source_Choices_Order_By>>;
  where?: InputMaybe<Job_Role_Source_Choices_Bool_Exp>;
};

export type Query_RootJob_Role_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootJob_Service_TypeArgs = {
  distinct_on?: InputMaybe<Array<Job_Service_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Service_Type_Order_By>>;
  where?: InputMaybe<Job_Service_Type_Bool_Exp>;
};

export type Query_RootJob_Service_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Service_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Service_Type_Order_By>>;
  where?: InputMaybe<Job_Service_Type_Bool_Exp>;
};

export type Query_RootJob_Service_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootJob_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Choices_Order_By>>;
  where?: InputMaybe<Job_Status_Choices_Bool_Exp>;
};

export type Query_RootJob_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Choices_Order_By>>;
  where?: InputMaybe<Job_Status_Choices_Bool_Exp>;
};

export type Query_RootJob_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootJob_Status_Choices_OrderArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Choices_Order_Order_By>>;
  where?: InputMaybe<Job_Status_Choices_Order_Bool_Exp>;
};

export type Query_RootJob_Status_Choices_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Choices_Order_Order_By>>;
  where?: InputMaybe<Job_Status_Choices_Order_Bool_Exp>;
};

export type Query_RootJob_Status_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootJob_Status_LogArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Log_Order_By>>;
  where?: InputMaybe<Job_Status_Log_Bool_Exp>;
};

export type Query_RootJob_Status_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Log_Order_By>>;
  where?: InputMaybe<Job_Status_Log_Bool_Exp>;
};

export type Query_RootJob_Status_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootJob_Tech_StackArgs = {
  distinct_on?: InputMaybe<Array<Job_Tech_Stack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Tech_Stack_Order_By>>;
  where?: InputMaybe<Job_Tech_Stack_Bool_Exp>;
};

export type Query_RootJob_Tech_Stack_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Tech_Stack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Tech_Stack_Order_By>>;
  where?: InputMaybe<Job_Tech_Stack_Bool_Exp>;
};

export type Query_RootJob_Tech_Stack_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootLinkedin_ContactArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Contact_Order_By>>;
  where?: InputMaybe<Linkedin_Contact_Bool_Exp>;
};

export type Query_RootLinkedin_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Contact_Order_By>>;
  where?: InputMaybe<Linkedin_Contact_Bool_Exp>;
};

export type Query_RootLinkedin_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootLocationArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};

export type Query_RootLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};

export type Query_RootLocation_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootManual_ContactArgs = {
  distinct_on?: InputMaybe<Array<Manual_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manual_Contact_Order_By>>;
  where?: InputMaybe<Manual_Contact_Bool_Exp>;
};

export type Query_RootManual_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manual_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manual_Contact_Order_By>>;
  where?: InputMaybe<Manual_Contact_Bool_Exp>;
};

export type Query_RootManual_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootMerge_Ats_ApplicationArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Application_Order_By>>;
  where?: InputMaybe<Merge_Ats_Application_Bool_Exp>;
};

export type Query_RootMerge_Ats_Application_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Application_Order_By>>;
  where?: InputMaybe<Merge_Ats_Application_Bool_Exp>;
};

export type Query_RootMerge_Ats_Application_By_PkArgs = {
  merge_application_id: Scalars['String'];
};

export type Query_RootMerge_Ats_Application_LogArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Application_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Application_Log_Order_By>>;
  where?: InputMaybe<Merge_Ats_Application_Log_Bool_Exp>;
};

export type Query_RootMerge_Ats_Application_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Application_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Application_Log_Order_By>>;
  where?: InputMaybe<Merge_Ats_Application_Log_Bool_Exp>;
};

export type Query_RootMerge_Ats_Application_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootMerge_Ats_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Candidate_Order_By>>;
  where?: InputMaybe<Merge_Ats_Candidate_Bool_Exp>;
};

export type Query_RootMerge_Ats_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Candidate_Order_By>>;
  where?: InputMaybe<Merge_Ats_Candidate_Bool_Exp>;
};

export type Query_RootMerge_Ats_Candidate_By_PkArgs = {
  merge_candidate_id: Scalars['String'];
};

export type Query_RootMerge_Ats_JobArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Job_Order_By>>;
  where?: InputMaybe<Merge_Ats_Job_Bool_Exp>;
};

export type Query_RootMerge_Ats_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Job_Order_By>>;
  where?: InputMaybe<Merge_Ats_Job_Bool_Exp>;
};

export type Query_RootMerge_Ats_Job_By_PkArgs = {
  merge_job_id: Scalars['String'];
};

export type Query_RootMerge_Ats_Job_Interview_StageArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Job_Interview_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Job_Interview_Stage_Order_By>>;
  where?: InputMaybe<Merge_Ats_Job_Interview_Stage_Bool_Exp>;
};

export type Query_RootMerge_Ats_Job_Interview_Stage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Job_Interview_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Job_Interview_Stage_Order_By>>;
  where?: InputMaybe<Merge_Ats_Job_Interview_Stage_Bool_Exp>;
};

export type Query_RootMerge_Ats_Job_Interview_Stage_By_PkArgs = {
  merge_interview_stage_id: Scalars['String'];
};

export type Query_RootMerge_Ats_Linked_AccountArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Linked_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Linked_Account_Order_By>>;
  where?: InputMaybe<Merge_Ats_Linked_Account_Bool_Exp>;
};

export type Query_RootMerge_Ats_Linked_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Linked_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Linked_Account_Order_By>>;
  where?: InputMaybe<Merge_Ats_Linked_Account_Bool_Exp>;
};

export type Query_RootMerge_Ats_Linked_Account_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootMixpanel_Duplicate_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Mixpanel_Duplicate_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mixpanel_Duplicate_Profiles_Order_By>>;
  where?: InputMaybe<Mixpanel_Duplicate_Profiles_Bool_Exp>;
};

export type Query_RootMixpanel_Duplicate_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mixpanel_Duplicate_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mixpanel_Duplicate_Profiles_Order_By>>;
  where?: InputMaybe<Mixpanel_Duplicate_Profiles_Bool_Exp>;
};

export type Query_RootMixpanel_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Mixpanel_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mixpanel_Profiles_Order_By>>;
  where?: InputMaybe<Mixpanel_Profiles_Bool_Exp>;
};

export type Query_RootMixpanel_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mixpanel_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mixpanel_Profiles_Order_By>>;
  where?: InputMaybe<Mixpanel_Profiles_Bool_Exp>;
};

export type Query_RootMixpanel_Profiles_By_PkArgs = {
  distinct_id: Scalars['String'];
  email: Scalars['String'];
};

export type Query_RootOffering_Selection_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Offering_Selection_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Offering_Selection_Choices_Order_By>>;
  where?: InputMaybe<Offering_Selection_Choices_Bool_Exp>;
};

export type Query_RootOffering_Selection_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Offering_Selection_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Offering_Selection_Choices_Order_By>>;
  where?: InputMaybe<Offering_Selection_Choices_Bool_Exp>;
};

export type Query_RootOffering_Selection_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootOrganizationArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Query_RootOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Query_RootOrganization_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootOrganization_ContactArgs = {
  distinct_on?: InputMaybe<Array<Organization_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Contact_Order_By>>;
  where?: InputMaybe<Organization_Contact_Bool_Exp>;
};

export type Query_RootOrganization_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Contact_Order_By>>;
  where?: InputMaybe<Organization_Contact_Bool_Exp>;
};

export type Query_RootOrganization_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootOrganization_DomainArgs = {
  distinct_on?: InputMaybe<Array<Organization_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Domain_Order_By>>;
  where?: InputMaybe<Organization_Domain_Bool_Exp>;
};

export type Query_RootOrganization_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Domain_Order_By>>;
  where?: InputMaybe<Organization_Domain_Bool_Exp>;
};

export type Query_RootOrganization_Domain_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootOrganization_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

export type Query_RootOrganization_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

export type Query_RootOrganization_Employee_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootOrganization_Icims_JobsArgs = {
  args: Organization_Icims_Jobs_Args;
  distinct_on?: InputMaybe<Array<Organization_Icims_Jobs_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Icims_Jobs_Result_Order_By>>;
  where?: InputMaybe<Organization_Icims_Jobs_Result_Bool_Exp>;
};

export type Query_RootOrganization_Icims_Jobs_AggregateArgs = {
  args: Organization_Icims_Jobs_Args;
  distinct_on?: InputMaybe<Array<Organization_Icims_Jobs_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Icims_Jobs_Result_Order_By>>;
  where?: InputMaybe<Organization_Icims_Jobs_Result_Bool_Exp>;
};

export type Query_RootOrganization_Icims_Jobs_ResultArgs = {
  distinct_on?: InputMaybe<Array<Organization_Icims_Jobs_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Icims_Jobs_Result_Order_By>>;
  where?: InputMaybe<Organization_Icims_Jobs_Result_Bool_Exp>;
};

export type Query_RootOrganization_Icims_Jobs_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Icims_Jobs_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Icims_Jobs_Result_Order_By>>;
  where?: InputMaybe<Organization_Icims_Jobs_Result_Bool_Exp>;
};

export type Query_RootOrganization_LeadershipArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leadership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leadership_Order_By>>;
  where?: InputMaybe<Organization_Leadership_Bool_Exp>;
};

export type Query_RootOrganization_Leadership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leadership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leadership_Order_By>>;
  where?: InputMaybe<Organization_Leadership_Bool_Exp>;
};

export type Query_RootOrganization_Leadership_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootOrganization_Type_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Type_Choices_Order_By>>;
  where?: InputMaybe<Organization_Type_Choices_Bool_Exp>;
};

export type Query_RootOrganization_Type_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Type_Choices_Order_By>>;
  where?: InputMaybe<Organization_Type_Choices_Bool_Exp>;
};

export type Query_RootOrganization_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootOrganization_VideoArgs = {
  distinct_on?: InputMaybe<Array<Organization_Video_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Video_Order_By>>;
  where?: InputMaybe<Organization_Video_Bool_Exp>;
};

export type Query_RootOrganization_Video_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Video_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Video_Order_By>>;
  where?: InputMaybe<Organization_Video_Bool_Exp>;
};

export type Query_RootOrganization_Video_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootPillar_TranscriptArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Transcript_Order_By>>;
  where?: InputMaybe<Pillar_Transcript_Bool_Exp>;
};

export type Query_RootPillar_Transcript_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Transcript_Order_By>>;
  where?: InputMaybe<Pillar_Transcript_Bool_Exp>;
};

export type Query_RootPillar_Transcript_BlobArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Transcript_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Transcript_Blob_Order_By>>;
  where?: InputMaybe<Pillar_Transcript_Blob_Bool_Exp>;
};

export type Query_RootPillar_Transcript_Blob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Transcript_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Transcript_Blob_Order_By>>;
  where?: InputMaybe<Pillar_Transcript_Blob_Bool_Exp>;
};

export type Query_RootPillar_Transcript_Blob_By_PkArgs = {
  transcript_id: Scalars['String'];
};

export type Query_RootPillar_Transcript_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootRecruiter_Contact_ActionArgs = {
  distinct_on?: InputMaybe<Array<Recruiter_Contact_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recruiter_Contact_Action_Order_By>>;
  where?: InputMaybe<Recruiter_Contact_Action_Bool_Exp>;
};

export type Query_RootRecruiter_Contact_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recruiter_Contact_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recruiter_Contact_Action_Order_By>>;
  where?: InputMaybe<Recruiter_Contact_Action_Bool_Exp>;
};

export type Query_RootRecruiter_Contact_Action_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootReferral_StatusArgs = {
  distinct_on?: InputMaybe<Array<Referral_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Status_Order_By>>;
  where?: InputMaybe<Referral_Status_Bool_Exp>;
};

export type Query_RootReferral_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Referral_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Status_Order_By>>;
  where?: InputMaybe<Referral_Status_Bool_Exp>;
};

export type Query_RootReferral_Status_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootReferral_Status_CurrentArgs = {
  distinct_on?: InputMaybe<Array<Referral_Status_Current_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Status_Current_Order_By>>;
  where?: InputMaybe<Referral_Status_Current_Bool_Exp>;
};

export type Query_RootReferral_Status_Current_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Referral_Status_Current_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Status_Current_Order_By>>;
  where?: InputMaybe<Referral_Status_Current_Bool_Exp>;
};

export type Query_RootReferral_Status_Current_By_PkArgs = {
  contact: Scalars['Int'];
  user: Scalars['Int'];
};

export type Query_RootRegion_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Region_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_Choices_Order_By>>;
  where?: InputMaybe<Region_Choices_Bool_Exp>;
};

export type Query_RootRegion_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Region_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_Choices_Order_By>>;
  where?: InputMaybe<Region_Choices_Bool_Exp>;
};

export type Query_RootRegion_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootReporting_Candidate_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Candidate_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Candidate_Metadata_Order_By>>;
  where?: InputMaybe<Reporting_Candidate_Metadata_Bool_Exp>;
};

export type Query_RootReporting_Candidate_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Candidate_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Candidate_Metadata_Order_By>>;
  where?: InputMaybe<Reporting_Candidate_Metadata_Bool_Exp>;
};

export type Query_RootReporting_Email_Suppression_TraitsArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Email_Suppression_Traits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Email_Suppression_Traits_Order_By>>;
  where?: InputMaybe<Reporting_Email_Suppression_Traits_Bool_Exp>;
};

export type Query_RootReporting_Email_Suppression_Traits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Email_Suppression_Traits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Email_Suppression_Traits_Order_By>>;
  where?: InputMaybe<Reporting_Email_Suppression_Traits_Bool_Exp>;
};

export type Query_RootReporting_Report_Job_MatchesArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Report_Job_Matches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Report_Job_Matches_Order_By>>;
  where?: InputMaybe<Reporting_Report_Job_Matches_Bool_Exp>;
};

export type Query_RootReporting_Report_Job_Matches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Report_Job_Matches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Report_Job_Matches_Order_By>>;
  where?: InputMaybe<Reporting_Report_Job_Matches_Bool_Exp>;
};

export type Query_RootReporting_Report_Job_Matches_By_PkArgs = {
  created_at: Scalars['timestamptz'];
};

export type Query_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

export type Query_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

export type Query_RootRole_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootRole_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Role_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Choices_Order_By>>;
  where?: InputMaybe<Role_Choices_Bool_Exp>;
};

export type Query_RootRole_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Choices_Order_By>>;
  where?: InputMaybe<Role_Choices_Bool_Exp>;
};

export type Query_RootRole_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootSalary_InfoArgs = {
  distinct_on?: InputMaybe<Array<Salary_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Info_Order_By>>;
  where?: InputMaybe<Salary_Info_Bool_Exp>;
};

export type Query_RootSalary_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salary_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Info_Order_By>>;
  where?: InputMaybe<Salary_Info_Bool_Exp>;
};

export type Query_RootSalary_Info_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSalary_Years_Experience_Range_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Order_By>>;
  where?: InputMaybe<Salary_Years_Experience_Range_Choices_Bool_Exp>;
};

export type Query_RootSalary_Years_Experience_Range_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Order_By>>;
  where?: InputMaybe<Salary_Years_Experience_Range_Choices_Bool_Exp>;
};

export type Query_RootSalary_Years_Experience_Range_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootSalesforce_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Customer_Order_By>>;
  where?: InputMaybe<Salesforce_Customer_Bool_Exp>;
};

export type Query_RootSalesforce_Customer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Customer_Order_By>>;
  where?: InputMaybe<Salesforce_Customer_Bool_Exp>;
};

export type Query_RootSalesforce_Customer_By_PkArgs = {
  salesforce_id: Scalars['String'];
};

export type Query_RootSalesforce_Customer_LocationArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Customer_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Customer_Location_Order_By>>;
  where?: InputMaybe<Salesforce_Customer_Location_Bool_Exp>;
};

export type Query_RootSalesforce_Customer_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Customer_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Customer_Location_Order_By>>;
  where?: InputMaybe<Salesforce_Customer_Location_Bool_Exp>;
};

export type Query_RootSalesforce_Customer_Location_By_PkArgs = {
  salesforce_id: Scalars['String'];
  salesforce_location_id: Scalars['Int'];
};

export type Query_RootSalesforce_LocationArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Location_Order_By>>;
  where?: InputMaybe<Salesforce_Location_Bool_Exp>;
};

export type Query_RootSalesforce_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Location_Order_By>>;
  where?: InputMaybe<Salesforce_Location_Bool_Exp>;
};

export type Query_RootSalesforce_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSaved_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Saved_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Saved_Candidate_Order_By>>;
  where?: InputMaybe<Saved_Candidate_Bool_Exp>;
};

export type Query_RootSaved_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Saved_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Saved_Candidate_Order_By>>;
  where?: InputMaybe<Saved_Candidate_Bool_Exp>;
};

export type Query_RootSaved_Candidate_By_PkArgs = {
  candidate_id: Scalars['Int'];
  saved_by_user_id: Scalars['Int'];
};

export type Query_RootSegment_Sendgrid_Prod_ActivityArgs = {
  distinct_on?: InputMaybe<Array<Segment_Sendgrid_Prod_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Sendgrid_Prod_Activity_Order_By>>;
  where?: InputMaybe<Segment_Sendgrid_Prod_Activity_Bool_Exp>;
};

export type Query_RootSegment_Sendgrid_Prod_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Segment_Sendgrid_Prod_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Sendgrid_Prod_Activity_Order_By>>;
  where?: InputMaybe<Segment_Sendgrid_Prod_Activity_Bool_Exp>;
};

export type Query_RootSegment_Sendgrid_Prod_Activity_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootShared_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Shared_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Shared_Candidate_Order_By>>;
  where?: InputMaybe<Shared_Candidate_Bool_Exp>;
};

export type Query_RootShared_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shared_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Shared_Candidate_Order_By>>;
  where?: InputMaybe<Shared_Candidate_Bool_Exp>;
};

export type Query_RootShared_Candidate_By_PkArgs = {
  candidate_id: Scalars['Int'];
  user_info_id: Scalars['Int'];
};

export type Query_RootSkillArgs = {
  distinct_on?: InputMaybe<Array<Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Order_By>>;
  where?: InputMaybe<Skill_Bool_Exp>;
};

export type Query_RootSkill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Order_By>>;
  where?: InputMaybe<Skill_Bool_Exp>;
};

export type Query_RootSkill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSkill_FeatureArgs = {
  distinct_on?: InputMaybe<Array<Skill_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Feature_Order_By>>;
  where?: InputMaybe<Skill_Feature_Bool_Exp>;
};

export type Query_RootSkill_Feature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Feature_Order_By>>;
  where?: InputMaybe<Skill_Feature_Bool_Exp>;
};

export type Query_RootSkill_Feature_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSkill_Icims_SkillArgs = {
  distinct_on?: InputMaybe<Array<Skill_Icims_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Icims_Skill_Order_By>>;
  where?: InputMaybe<Skill_Icims_Skill_Bool_Exp>;
};

export type Query_RootSkill_Icims_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Icims_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Icims_Skill_Order_By>>;
  where?: InputMaybe<Skill_Icims_Skill_Bool_Exp>;
};

export type Query_RootSkill_Icims_Skill_By_PkArgs = {
  icims_skill_id: Scalars['String'];
  skill_id: Scalars['Int'];
};

export type Query_RootSkill_SynonymArgs = {
  distinct_on?: InputMaybe<Array<Skill_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Synonym_Order_By>>;
  where?: InputMaybe<Skill_Synonym_Bool_Exp>;
};

export type Query_RootSkill_Synonym_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Synonym_Order_By>>;
  where?: InputMaybe<Skill_Synonym_Bool_Exp>;
};

export type Query_RootSkill_Synonym_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSpike_Matching_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Candidate_Order_By>>;
  where?: InputMaybe<Spike_Matching_Candidate_Bool_Exp>;
};

export type Query_RootSpike_Matching_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Candidate_Order_By>>;
  where?: InputMaybe<Spike_Matching_Candidate_Bool_Exp>;
};

export type Query_RootSpike_Matching_Candidate_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSpike_Matching_Candidate_SkillArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Candidate_Skill_Bool_Exp>;
};

export type Query_RootSpike_Matching_Candidate_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Candidate_Skill_Bool_Exp>;
};

export type Query_RootSpike_Matching_Candidate_Skill_By_PkArgs = {
  candidate_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

export type Query_RootSpike_Matching_JobArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Job_Order_By>>;
  where?: InputMaybe<Spike_Matching_Job_Bool_Exp>;
};

export type Query_RootSpike_Matching_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Job_Order_By>>;
  where?: InputMaybe<Spike_Matching_Job_Bool_Exp>;
};

export type Query_RootSpike_Matching_Job_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSpike_Matching_Job_SkillArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Job_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Job_Skill_Bool_Exp>;
};

export type Query_RootSpike_Matching_Job_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Job_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Job_Skill_Bool_Exp>;
};

export type Query_RootSpike_Matching_Job_Skill_By_PkArgs = {
  job_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

export type Query_RootSpike_Matching_LocationArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Location_Order_By>>;
  where?: InputMaybe<Spike_Matching_Location_Bool_Exp>;
};

export type Query_RootSpike_Matching_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Location_Order_By>>;
  where?: InputMaybe<Spike_Matching_Location_Bool_Exp>;
};

export type Query_RootSpike_Matching_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSpike_Matching_Location_JobArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Location_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Location_Job_Order_By>>;
  where?: InputMaybe<Spike_Matching_Location_Job_Bool_Exp>;
};

export type Query_RootSpike_Matching_Location_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Location_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Location_Job_Order_By>>;
  where?: InputMaybe<Spike_Matching_Location_Job_Bool_Exp>;
};

export type Query_RootSpike_Matching_Location_Job_By_PkArgs = {
  job_id: Scalars['Int'];
  location_id: Scalars['Int'];
};

export type Query_RootSpike_Matching_MatchArgs = {
  args: Spike_Matching_Match_Args;
  distinct_on?: InputMaybe<Array<Spike_Matching_Match_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Match_Result_Order_By>>;
  where?: InputMaybe<Spike_Matching_Match_Result_Bool_Exp>;
};

export type Query_RootSpike_Matching_Match_AggregateArgs = {
  args: Spike_Matching_Match_Args;
  distinct_on?: InputMaybe<Array<Spike_Matching_Match_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Match_Result_Order_By>>;
  where?: InputMaybe<Spike_Matching_Match_Result_Bool_Exp>;
};

export type Query_RootSpike_Matching_Match_ResultArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Match_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Match_Result_Order_By>>;
  where?: InputMaybe<Spike_Matching_Match_Result_Bool_Exp>;
};

export type Query_RootSpike_Matching_Match_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Match_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Match_Result_Order_By>>;
  where?: InputMaybe<Spike_Matching_Match_Result_Bool_Exp>;
};

export type Query_RootSpike_Matching_Match_Result_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSpike_Matching_SkillArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Skill_Bool_Exp>;
};

export type Query_RootSpike_Matching_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Skill_Bool_Exp>;
};

export type Query_RootSpike_Matching_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootTerminal_Celery_Task_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Order_By>>;
  where?: InputMaybe<Terminal_Celery_Task_Status_Choices_Bool_Exp>;
};

export type Query_RootTerminal_Celery_Task_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Order_By>>;
  where?: InputMaybe<Terminal_Celery_Task_Status_Choices_Bool_Exp>;
};

export type Query_RootTerminal_Celery_Task_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootTerminal_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Bool_Exp>;
};

export type Query_RootTerminal_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Bool_Exp>;
};

export type Query_RootTerminal_Employee_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootTerminal_Employee_OrganizationArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Organization_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
};

export type Query_RootTerminal_Employee_Organization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Organization_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
};

export type Query_RootTerminal_Employee_Organization_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootTerminal_Employee_Role_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Role_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Role_Choices_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Role_Choices_Bool_Exp>;
};

export type Query_RootTerminal_Employee_Role_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Role_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Role_Choices_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Role_Choices_Bool_Exp>;
};

export type Query_RootTerminal_Employee_Role_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootTraitArgs = {
  distinct_on?: InputMaybe<Array<Trait_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trait_Order_By>>;
  where?: InputMaybe<Trait_Bool_Exp>;
};

export type Query_RootTrait_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trait_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trait_Order_By>>;
  where?: InputMaybe<Trait_Bool_Exp>;
};

export type Query_RootTrait_By_PkArgs = {
  code: Scalars['String'];
};

export type Query_RootTranscript_EvaluationArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Evaluation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Evaluation_Order_By>>;
  where?: InputMaybe<Transcript_Evaluation_Bool_Exp>;
};

export type Query_RootTranscript_Evaluation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Evaluation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Evaluation_Order_By>>;
  where?: InputMaybe<Transcript_Evaluation_Bool_Exp>;
};

export type Query_RootTranscript_Evaluation_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootTranscript_SkillArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Skill_Order_By>>;
  where?: InputMaybe<Transcript_Skill_Bool_Exp>;
};

export type Query_RootTranscript_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Skill_Order_By>>;
  where?: InputMaybe<Transcript_Skill_Bool_Exp>;
};

export type Query_RootTranscript_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootTranscript_Work_ExperienceArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Work_Experience_Order_By>>;
  where?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
};

export type Query_RootTranscript_Work_Experience_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Work_Experience_Order_By>>;
  where?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
};

export type Query_RootTranscript_Work_Experience_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_ContactArgs = {
  distinct_on?: InputMaybe<Array<User_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Contact_Order_By>>;
  where?: InputMaybe<User_Contact_Bool_Exp>;
};

export type Query_RootUser_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Contact_Order_By>>;
  where?: InputMaybe<User_Contact_Bool_Exp>;
};

export type Query_RootUser_InfoArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

export type Query_RootUser_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

export type Query_RootUser_Info_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_InvitationArgs = {
  distinct_on?: InputMaybe<Array<User_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invitation_Order_By>>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};

export type Query_RootUser_Invitation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invitation_Order_By>>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};

export type Query_RootUser_Invitation_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_Linkedin_ContactArgs = {
  distinct_on?: InputMaybe<Array<User_Linkedin_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linkedin_Contact_Order_By>>;
  where?: InputMaybe<User_Linkedin_Contact_Bool_Exp>;
};

export type Query_RootUser_Linkedin_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Linkedin_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linkedin_Contact_Order_By>>;
  where?: InputMaybe<User_Linkedin_Contact_Bool_Exp>;
};

export type Query_RootUser_Linkedin_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_Password_BlacklistArgs = {
  distinct_on?: InputMaybe<Array<User_Password_Blacklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Password_Blacklist_Order_By>>;
  where?: InputMaybe<User_Password_Blacklist_Bool_Exp>;
};

export type Query_RootUser_Password_Blacklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Password_Blacklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Password_Blacklist_Order_By>>;
  where?: InputMaybe<User_Password_Blacklist_Bool_Exp>;
};

export type Query_RootUser_Password_Blacklist_By_PkArgs = {
  password: Scalars['String'];
};

export type Query_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

export type Query_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

export type Query_RootUser_Role_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUser_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
  where?: InputMaybe<User_Subscription_Bool_Exp>;
};

export type Query_RootUser_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
  where?: InputMaybe<User_Subscription_Bool_Exp>;
};

export type Query_RootUser_Subscription_By_PkArgs = {
  subscription: User_Subscription_Choices_Enum;
  user_info_id: Scalars['Int'];
};

export type Query_RootUser_Subscription_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<User_Subscription_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Subscription_Choices_Order_By>>;
  where?: InputMaybe<User_Subscription_Choices_Bool_Exp>;
};

export type Query_RootUser_Subscription_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Subscription_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Subscription_Choices_Order_By>>;
  where?: InputMaybe<User_Subscription_Choices_Bool_Exp>;
};

export type Query_RootUser_Subscription_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootUser_Temp_BlobArgs = {
  distinct_on?: InputMaybe<Array<User_Temp_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Temp_Blob_Order_By>>;
  where?: InputMaybe<User_Temp_Blob_Bool_Exp>;
};

export type Query_RootUser_Temp_Blob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Temp_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Temp_Blob_Order_By>>;
  where?: InputMaybe<User_Temp_Blob_Bool_Exp>;
};

export type Query_RootUser_Temp_Blob_By_PkArgs = {
  firebase_uid: Scalars['String'];
};

export type Query_RootValidatorArgs = {
  distinct_on?: InputMaybe<Array<Validator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Validator_Order_By>>;
  where?: InputMaybe<Validator_Bool_Exp>;
};

export type Query_RootValidator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Validator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Validator_Order_By>>;
  where?: InputMaybe<Validator_Bool_Exp>;
};

export type Query_RootValidator_By_PkArgs = {
  name: Scalars['String'];
};

/** columns and relationships of "recruiter_contact_action" */
export type Recruiter_Contact_Action = {
  __typename?: 'recruiter_contact_action';
  action: Scalars['String'];
  contact: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  required_function: Scalars['String'];
  required_location: Scalars['String'];
  required_other: Scalars['String'];
  user: Scalars['Int'];
  /** An object relationship */
  user_info: User_Info;
};

/** aggregated selection of "recruiter_contact_action" */
export type Recruiter_Contact_Action_Aggregate = {
  __typename?: 'recruiter_contact_action_aggregate';
  aggregate: Maybe<Recruiter_Contact_Action_Aggregate_Fields>;
  nodes: Array<Recruiter_Contact_Action>;
};

export type Recruiter_Contact_Action_Aggregate_Bool_Exp = {
  count?: InputMaybe<Recruiter_Contact_Action_Aggregate_Bool_Exp_Count>;
};

export type Recruiter_Contact_Action_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Recruiter_Contact_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Recruiter_Contact_Action_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "recruiter_contact_action" */
export type Recruiter_Contact_Action_Aggregate_Fields = {
  __typename?: 'recruiter_contact_action_aggregate_fields';
  avg: Maybe<Recruiter_Contact_Action_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Recruiter_Contact_Action_Max_Fields>;
  min: Maybe<Recruiter_Contact_Action_Min_Fields>;
  stddev: Maybe<Recruiter_Contact_Action_Stddev_Fields>;
  stddev_pop: Maybe<Recruiter_Contact_Action_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Recruiter_Contact_Action_Stddev_Samp_Fields>;
  sum: Maybe<Recruiter_Contact_Action_Sum_Fields>;
  var_pop: Maybe<Recruiter_Contact_Action_Var_Pop_Fields>;
  var_samp: Maybe<Recruiter_Contact_Action_Var_Samp_Fields>;
  variance: Maybe<Recruiter_Contact_Action_Variance_Fields>;
};

/** aggregate fields of "recruiter_contact_action" */
export type Recruiter_Contact_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Recruiter_Contact_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Aggregate_Order_By = {
  avg?: InputMaybe<Recruiter_Contact_Action_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Recruiter_Contact_Action_Max_Order_By>;
  min?: InputMaybe<Recruiter_Contact_Action_Min_Order_By>;
  stddev?: InputMaybe<Recruiter_Contact_Action_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Recruiter_Contact_Action_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Recruiter_Contact_Action_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Recruiter_Contact_Action_Sum_Order_By>;
  var_pop?: InputMaybe<Recruiter_Contact_Action_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Recruiter_Contact_Action_Var_Samp_Order_By>;
  variance?: InputMaybe<Recruiter_Contact_Action_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Arr_Rel_Insert_Input = {
  data: Array<Recruiter_Contact_Action_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Recruiter_Contact_Action_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiter_Contact_Action_Avg_Fields = {
  __typename?: 'recruiter_contact_action_avg_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Avg_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiter_contact_action". All fields are combined with a logical 'AND'. */
export type Recruiter_Contact_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Recruiter_Contact_Action_Bool_Exp>>;
  _not?: InputMaybe<Recruiter_Contact_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Recruiter_Contact_Action_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  contact?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  required_function?: InputMaybe<String_Comparison_Exp>;
  required_location?: InputMaybe<String_Comparison_Exp>;
  required_other?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Int_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "recruiter_contact_action" */
export enum Recruiter_Contact_Action_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecruiterContactActionPkey = 'recruiter_contact_action_pkey',
}

/** input type for incrementing numeric columns in table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Inc_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Insert_Input = {
  action?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  required_function?: InputMaybe<Scalars['String']>;
  required_location?: InputMaybe<Scalars['String']>;
  required_other?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['Int']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Recruiter_Contact_Action_Max_Fields = {
  __typename?: 'recruiter_contact_action_max_fields';
  action: Maybe<Scalars['String']>;
  contact: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  required_function: Maybe<Scalars['String']>;
  required_location: Maybe<Scalars['String']>;
  required_other: Maybe<Scalars['String']>;
  user: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Max_Order_By = {
  action?: InputMaybe<Order_By>;
  contact?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  required_function?: InputMaybe<Order_By>;
  required_location?: InputMaybe<Order_By>;
  required_other?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiter_Contact_Action_Min_Fields = {
  __typename?: 'recruiter_contact_action_min_fields';
  action: Maybe<Scalars['String']>;
  contact: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  required_function: Maybe<Scalars['String']>;
  required_location: Maybe<Scalars['String']>;
  required_other: Maybe<Scalars['String']>;
  user: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Min_Order_By = {
  action?: InputMaybe<Order_By>;
  contact?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  required_function?: InputMaybe<Order_By>;
  required_location?: InputMaybe<Order_By>;
  required_other?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Mutation_Response = {
  __typename?: 'recruiter_contact_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recruiter_Contact_Action>;
};

/** on_conflict condition type for table "recruiter_contact_action" */
export type Recruiter_Contact_Action_On_Conflict = {
  constraint: Recruiter_Contact_Action_Constraint;
  update_columns?: Array<Recruiter_Contact_Action_Update_Column>;
  where?: InputMaybe<Recruiter_Contact_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiter_contact_action". */
export type Recruiter_Contact_Action_Order_By = {
  action?: InputMaybe<Order_By>;
  contact?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  required_function?: InputMaybe<Order_By>;
  required_location?: InputMaybe<Order_By>;
  required_other?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: recruiter_contact_action */
export type Recruiter_Contact_Action_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "recruiter_contact_action" */
export enum Recruiter_Contact_Action_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RequiredFunction = 'required_function',
  /** column name */
  RequiredLocation = 'required_location',
  /** column name */
  RequiredOther = 'required_other',
  /** column name */
  User = 'user',
}

/** input type for updating data in table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Set_Input = {
  action?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  required_function?: InputMaybe<Scalars['String']>;
  required_location?: InputMaybe<Scalars['String']>;
  required_other?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Recruiter_Contact_Action_Stddev_Fields = {
  __typename?: 'recruiter_contact_action_stddev_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Stddev_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiter_Contact_Action_Stddev_Pop_Fields = {
  __typename?: 'recruiter_contact_action_stddev_pop_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Stddev_Pop_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiter_Contact_Action_Stddev_Samp_Fields = {
  __typename?: 'recruiter_contact_action_stddev_samp_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Stddev_Samp_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recruiter_Contact_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiter_Contact_Action_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  required_function?: InputMaybe<Scalars['String']>;
  required_location?: InputMaybe<Scalars['String']>;
  required_other?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Recruiter_Contact_Action_Sum_Fields = {
  __typename?: 'recruiter_contact_action_sum_fields';
  contact: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Sum_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** update columns of table "recruiter_contact_action" */
export enum Recruiter_Contact_Action_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RequiredFunction = 'required_function',
  /** column name */
  RequiredLocation = 'required_location',
  /** column name */
  RequiredOther = 'required_other',
  /** column name */
  User = 'user',
}

export type Recruiter_Contact_Action_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Recruiter_Contact_Action_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Recruiter_Contact_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recruiter_Contact_Action_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiter_Contact_Action_Var_Pop_Fields = {
  __typename?: 'recruiter_contact_action_var_pop_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Var_Pop_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiter_Contact_Action_Var_Samp_Fields = {
  __typename?: 'recruiter_contact_action_var_samp_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Var_Samp_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiter_Contact_Action_Variance_Fields = {
  __typename?: 'recruiter_contact_action_variance_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiter_contact_action" */
export type Recruiter_Contact_Action_Variance_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** columns and relationships of "referral_status" */
export type Referral_Status = {
  __typename?: 'referral_status';
  contact: Scalars['Int'];
  /** An object relationship */
  contactByContact: Contact;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  referred: Scalars['Boolean'];
  user: Scalars['Int'];
  /** An object relationship */
  user_info: User_Info;
};

/** aggregated selection of "referral_status" */
export type Referral_Status_Aggregate = {
  __typename?: 'referral_status_aggregate';
  aggregate: Maybe<Referral_Status_Aggregate_Fields>;
  nodes: Array<Referral_Status>;
};

export type Referral_Status_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Referral_Status_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Referral_Status_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Referral_Status_Aggregate_Bool_Exp_Count>;
};

export type Referral_Status_Aggregate_Bool_Exp_Bool_And = {
  arguments: Referral_Status_Select_Column_Referral_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Referral_Status_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Referral_Status_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Referral_Status_Select_Column_Referral_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Referral_Status_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Referral_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Referral_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Referral_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "referral_status" */
export type Referral_Status_Aggregate_Fields = {
  __typename?: 'referral_status_aggregate_fields';
  avg: Maybe<Referral_Status_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Referral_Status_Max_Fields>;
  min: Maybe<Referral_Status_Min_Fields>;
  stddev: Maybe<Referral_Status_Stddev_Fields>;
  stddev_pop: Maybe<Referral_Status_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Referral_Status_Stddev_Samp_Fields>;
  sum: Maybe<Referral_Status_Sum_Fields>;
  var_pop: Maybe<Referral_Status_Var_Pop_Fields>;
  var_samp: Maybe<Referral_Status_Var_Samp_Fields>;
  variance: Maybe<Referral_Status_Variance_Fields>;
};

/** aggregate fields of "referral_status" */
export type Referral_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Referral_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "referral_status" */
export type Referral_Status_Aggregate_Order_By = {
  avg?: InputMaybe<Referral_Status_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Referral_Status_Max_Order_By>;
  min?: InputMaybe<Referral_Status_Min_Order_By>;
  stddev?: InputMaybe<Referral_Status_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Referral_Status_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Referral_Status_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Referral_Status_Sum_Order_By>;
  var_pop?: InputMaybe<Referral_Status_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Referral_Status_Var_Samp_Order_By>;
  variance?: InputMaybe<Referral_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "referral_status" */
export type Referral_Status_Arr_Rel_Insert_Input = {
  data: Array<Referral_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Referral_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Referral_Status_Avg_Fields = {
  __typename?: 'referral_status_avg_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "referral_status" */
export type Referral_Status_Avg_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "referral_status". All fields are combined with a logical 'AND'. */
export type Referral_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Referral_Status_Bool_Exp>>;
  _not?: InputMaybe<Referral_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Referral_Status_Bool_Exp>>;
  contact?: InputMaybe<Int_Comparison_Exp>;
  contactByContact?: InputMaybe<Contact_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  referred?: InputMaybe<Boolean_Comparison_Exp>;
  user?: InputMaybe<Int_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "referral_status" */
export enum Referral_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReferralStatusPkey = 'referral_status_pkey',
}

/** columns and relationships of "referral_status_current" */
export type Referral_Status_Current = {
  __typename?: 'referral_status_current';
  contact: Scalars['Int'];
  /** An object relationship */
  referralStatusByReferralStatus: Referral_Status;
  referral_status: Scalars['Int'];
  user: Scalars['Int'];
};

/** aggregated selection of "referral_status_current" */
export type Referral_Status_Current_Aggregate = {
  __typename?: 'referral_status_current_aggregate';
  aggregate: Maybe<Referral_Status_Current_Aggregate_Fields>;
  nodes: Array<Referral_Status_Current>;
};

/** aggregate fields of "referral_status_current" */
export type Referral_Status_Current_Aggregate_Fields = {
  __typename?: 'referral_status_current_aggregate_fields';
  avg: Maybe<Referral_Status_Current_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Referral_Status_Current_Max_Fields>;
  min: Maybe<Referral_Status_Current_Min_Fields>;
  stddev: Maybe<Referral_Status_Current_Stddev_Fields>;
  stddev_pop: Maybe<Referral_Status_Current_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Referral_Status_Current_Stddev_Samp_Fields>;
  sum: Maybe<Referral_Status_Current_Sum_Fields>;
  var_pop: Maybe<Referral_Status_Current_Var_Pop_Fields>;
  var_samp: Maybe<Referral_Status_Current_Var_Samp_Fields>;
  variance: Maybe<Referral_Status_Current_Variance_Fields>;
};

/** aggregate fields of "referral_status_current" */
export type Referral_Status_Current_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Referral_Status_Current_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Referral_Status_Current_Avg_Fields = {
  __typename?: 'referral_status_current_avg_fields';
  contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "referral_status_current". All fields are combined with a logical 'AND'. */
export type Referral_Status_Current_Bool_Exp = {
  _and?: InputMaybe<Array<Referral_Status_Current_Bool_Exp>>;
  _not?: InputMaybe<Referral_Status_Current_Bool_Exp>;
  _or?: InputMaybe<Array<Referral_Status_Current_Bool_Exp>>;
  contact?: InputMaybe<Int_Comparison_Exp>;
  referralStatusByReferralStatus?: InputMaybe<Referral_Status_Bool_Exp>;
  referral_status?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "referral_status_current" */
export enum Referral_Status_Current_Constraint {
  /** unique or primary key constraint on columns "user", "contact" */
  ReferralStatusCurrentPkey = 'referral_status_current_pkey',
}

/** input type for incrementing numeric columns in table "referral_status_current" */
export type Referral_Status_Current_Inc_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  referral_status?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "referral_status_current" */
export type Referral_Status_Current_Insert_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  referralStatusByReferralStatus?: InputMaybe<Referral_Status_Obj_Rel_Insert_Input>;
  referral_status?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Referral_Status_Current_Max_Fields = {
  __typename?: 'referral_status_current_max_fields';
  contact: Maybe<Scalars['Int']>;
  referral_status: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Referral_Status_Current_Min_Fields = {
  __typename?: 'referral_status_current_min_fields';
  contact: Maybe<Scalars['Int']>;
  referral_status: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "referral_status_current" */
export type Referral_Status_Current_Mutation_Response = {
  __typename?: 'referral_status_current_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Referral_Status_Current>;
};

/** on_conflict condition type for table "referral_status_current" */
export type Referral_Status_Current_On_Conflict = {
  constraint: Referral_Status_Current_Constraint;
  update_columns?: Array<Referral_Status_Current_Update_Column>;
  where?: InputMaybe<Referral_Status_Current_Bool_Exp>;
};

/** Ordering options when selecting data from "referral_status_current". */
export type Referral_Status_Current_Order_By = {
  contact?: InputMaybe<Order_By>;
  referralStatusByReferralStatus?: InputMaybe<Referral_Status_Order_By>;
  referral_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** primary key columns input for table: referral_status_current */
export type Referral_Status_Current_Pk_Columns_Input = {
  contact: Scalars['Int'];
  user: Scalars['Int'];
};

/** select columns of table "referral_status_current" */
export enum Referral_Status_Current_Select_Column {
  /** column name */
  Contact = 'contact',
  /** column name */
  ReferralStatus = 'referral_status',
  /** column name */
  User = 'user',
}

/** input type for updating data in table "referral_status_current" */
export type Referral_Status_Current_Set_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  referral_status?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Referral_Status_Current_Stddev_Fields = {
  __typename?: 'referral_status_current_stddev_fields';
  contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Referral_Status_Current_Stddev_Pop_Fields = {
  __typename?: 'referral_status_current_stddev_pop_fields';
  contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Referral_Status_Current_Stddev_Samp_Fields = {
  __typename?: 'referral_status_current_stddev_samp_fields';
  contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "referral_status_current" */
export type Referral_Status_Current_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Referral_Status_Current_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Referral_Status_Current_Stream_Cursor_Value_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  referral_status?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Referral_Status_Current_Sum_Fields = {
  __typename?: 'referral_status_current_sum_fields';
  contact: Maybe<Scalars['Int']>;
  referral_status: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** update columns of table "referral_status_current" */
export enum Referral_Status_Current_Update_Column {
  /** column name */
  Contact = 'contact',
  /** column name */
  ReferralStatus = 'referral_status',
  /** column name */
  User = 'user',
}

export type Referral_Status_Current_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Referral_Status_Current_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Referral_Status_Current_Set_Input>;
  /** filter the rows which have to be updated */
  where: Referral_Status_Current_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Referral_Status_Current_Var_Pop_Fields = {
  __typename?: 'referral_status_current_var_pop_fields';
  contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Referral_Status_Current_Var_Samp_Fields = {
  __typename?: 'referral_status_current_var_samp_fields';
  contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Referral_Status_Current_Variance_Fields = {
  __typename?: 'referral_status_current_variance_fields';
  contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "referral_status" */
export type Referral_Status_Inc_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "referral_status" */
export type Referral_Status_Insert_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  contactByContact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  referred?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['Int']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Referral_Status_Max_Fields = {
  __typename?: 'referral_status_max_fields';
  contact: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "referral_status" */
export type Referral_Status_Max_Order_By = {
  contact?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Referral_Status_Min_Fields = {
  __typename?: 'referral_status_min_fields';
  contact: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "referral_status" */
export type Referral_Status_Min_Order_By = {
  contact?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "referral_status" */
export type Referral_Status_Mutation_Response = {
  __typename?: 'referral_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Referral_Status>;
};

/** input type for inserting object relation for remote table "referral_status" */
export type Referral_Status_Obj_Rel_Insert_Input = {
  data: Referral_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Referral_Status_On_Conflict>;
};

/** on_conflict condition type for table "referral_status" */
export type Referral_Status_On_Conflict = {
  constraint: Referral_Status_Constraint;
  update_columns?: Array<Referral_Status_Update_Column>;
  where?: InputMaybe<Referral_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "referral_status". */
export type Referral_Status_Order_By = {
  contact?: InputMaybe<Order_By>;
  contactByContact?: InputMaybe<Contact_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referred?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: referral_status */
export type Referral_Status_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "referral_status" */
export enum Referral_Status_Select_Column {
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Referred = 'referred',
  /** column name */
  User = 'user',
}

/** select "referral_status_aggregate_bool_exp_bool_and_arguments_columns" columns of table "referral_status" */
export enum Referral_Status_Select_Column_Referral_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Referred = 'referred',
}

/** select "referral_status_aggregate_bool_exp_bool_or_arguments_columns" columns of table "referral_status" */
export enum Referral_Status_Select_Column_Referral_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Referred = 'referred',
}

/** input type for updating data in table "referral_status" */
export type Referral_Status_Set_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  referred?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Referral_Status_Stddev_Fields = {
  __typename?: 'referral_status_stddev_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "referral_status" */
export type Referral_Status_Stddev_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Referral_Status_Stddev_Pop_Fields = {
  __typename?: 'referral_status_stddev_pop_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "referral_status" */
export type Referral_Status_Stddev_Pop_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Referral_Status_Stddev_Samp_Fields = {
  __typename?: 'referral_status_stddev_samp_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "referral_status" */
export type Referral_Status_Stddev_Samp_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "referral_status" */
export type Referral_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Referral_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Referral_Status_Stream_Cursor_Value_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  referred?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Referral_Status_Sum_Fields = {
  __typename?: 'referral_status_sum_fields';
  contact: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "referral_status" */
export type Referral_Status_Sum_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** update columns of table "referral_status" */
export enum Referral_Status_Update_Column {
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Referred = 'referred',
  /** column name */
  User = 'user',
}

export type Referral_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Referral_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Referral_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Referral_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Referral_Status_Var_Pop_Fields = {
  __typename?: 'referral_status_var_pop_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "referral_status" */
export type Referral_Status_Var_Pop_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Referral_Status_Var_Samp_Fields = {
  __typename?: 'referral_status_var_samp_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "referral_status" */
export type Referral_Status_Var_Samp_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Referral_Status_Variance_Fields = {
  __typename?: 'referral_status_variance_fields';
  contact: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "referral_status" */
export type Referral_Status_Variance_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Enum table that contains choices for regions that group together locations */
export type Region_Choices = {
  __typename?: 'region_choices';
  /** Region this location part of */
  name: Scalars['String'];
  /** Enum value to be used in relations */
  value: Scalars['String'];
};

/** aggregated selection of "region_choices" */
export type Region_Choices_Aggregate = {
  __typename?: 'region_choices_aggregate';
  aggregate: Maybe<Region_Choices_Aggregate_Fields>;
  nodes: Array<Region_Choices>;
};

/** aggregate fields of "region_choices" */
export type Region_Choices_Aggregate_Fields = {
  __typename?: 'region_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Region_Choices_Max_Fields>;
  min: Maybe<Region_Choices_Min_Fields>;
};

/** aggregate fields of "region_choices" */
export type Region_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Region_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "region_choices". All fields are combined with a logical 'AND'. */
export type Region_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Region_Choices_Bool_Exp>>;
  _not?: InputMaybe<Region_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Region_Choices_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "region_choices" */
export enum Region_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  RegionChoicesPkey = 'region_choices_pkey',
}

export enum Region_Choices_Enum {
  /** Canada */
  Canada = 'CANADA',
  /** Europe */
  Europe = 'EUROPE',
  /** Latin America */
  LatAm = 'LAT_AM',
}

/** Boolean expression to compare columns of type "region_choices_enum". All fields are combined with logical 'AND'. */
export type Region_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Region_Choices_Enum>;
  _in?: InputMaybe<Array<Region_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Region_Choices_Enum>;
  _nin?: InputMaybe<Array<Region_Choices_Enum>>;
};

/** input type for inserting data into table "region_choices" */
export type Region_Choices_Insert_Input = {
  /** Region this location part of */
  name?: InputMaybe<Scalars['String']>;
  /** Enum value to be used in relations */
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Region_Choices_Max_Fields = {
  __typename?: 'region_choices_max_fields';
  /** Region this location part of */
  name: Maybe<Scalars['String']>;
  /** Enum value to be used in relations */
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Region_Choices_Min_Fields = {
  __typename?: 'region_choices_min_fields';
  /** Region this location part of */
  name: Maybe<Scalars['String']>;
  /** Enum value to be used in relations */
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "region_choices" */
export type Region_Choices_Mutation_Response = {
  __typename?: 'region_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Region_Choices>;
};

/** input type for inserting object relation for remote table "region_choices" */
export type Region_Choices_Obj_Rel_Insert_Input = {
  data: Region_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Region_Choices_On_Conflict>;
};

/** on_conflict condition type for table "region_choices" */
export type Region_Choices_On_Conflict = {
  constraint: Region_Choices_Constraint;
  update_columns?: Array<Region_Choices_Update_Column>;
  where?: InputMaybe<Region_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "region_choices". */
export type Region_Choices_Order_By = {
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: region_choices */
export type Region_Choices_Pk_Columns_Input = {
  /** Enum value to be used in relations */
  value: Scalars['String'];
};

/** select columns of table "region_choices" */
export enum Region_Choices_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "region_choices" */
export type Region_Choices_Set_Input = {
  /** Region this location part of */
  name?: InputMaybe<Scalars['String']>;
  /** Enum value to be used in relations */
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "region_choices" */
export type Region_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Region_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Region_Choices_Stream_Cursor_Value_Input = {
  /** Region this location part of */
  name?: InputMaybe<Scalars['String']>;
  /** Enum value to be used in relations */
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "region_choices" */
export enum Region_Choices_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

export type Region_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Region_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Region_Choices_Bool_Exp;
};

/** columns and relationships of "reporting.candidate_metadata" */
export type Reporting_Candidate_Metadata = {
  __typename?: 'reporting_candidate_metadata';
  candidate_created_at: Maybe<Scalars['date']>;
  has_availability: Maybe<Scalars['Int']>;
  has_firstname: Maybe<Scalars['Int']>;
  has_fullname: Maybe<Scalars['Int']>;
  has_lastname: Maybe<Scalars['Int']>;
  has_linkedin: Maybe<Scalars['Int']>;
  has_resume: Maybe<Scalars['Int']>;
  icims_created_at: Maybe<Scalars['date']>;
  id: Maybe<Scalars['Int']>;
  is_portal_user: Maybe<Scalars['Int']>;
  num_skills: Maybe<Scalars['bigint']>;
  num_work_experience: Maybe<Scalars['bigint']>;
  source: Maybe<Scalars['String']>;
  stage: Maybe<Scalars['String']>;
};

/** aggregated selection of "reporting.candidate_metadata" */
export type Reporting_Candidate_Metadata_Aggregate = {
  __typename?: 'reporting_candidate_metadata_aggregate';
  aggregate: Maybe<Reporting_Candidate_Metadata_Aggregate_Fields>;
  nodes: Array<Reporting_Candidate_Metadata>;
};

/** aggregate fields of "reporting.candidate_metadata" */
export type Reporting_Candidate_Metadata_Aggregate_Fields = {
  __typename?: 'reporting_candidate_metadata_aggregate_fields';
  avg: Maybe<Reporting_Candidate_Metadata_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Reporting_Candidate_Metadata_Max_Fields>;
  min: Maybe<Reporting_Candidate_Metadata_Min_Fields>;
  stddev: Maybe<Reporting_Candidate_Metadata_Stddev_Fields>;
  stddev_pop: Maybe<Reporting_Candidate_Metadata_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Reporting_Candidate_Metadata_Stddev_Samp_Fields>;
  sum: Maybe<Reporting_Candidate_Metadata_Sum_Fields>;
  var_pop: Maybe<Reporting_Candidate_Metadata_Var_Pop_Fields>;
  var_samp: Maybe<Reporting_Candidate_Metadata_Var_Samp_Fields>;
  variance: Maybe<Reporting_Candidate_Metadata_Variance_Fields>;
};

/** aggregate fields of "reporting.candidate_metadata" */
export type Reporting_Candidate_Metadata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reporting_Candidate_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Reporting_Candidate_Metadata_Avg_Fields = {
  __typename?: 'reporting_candidate_metadata_avg_fields';
  has_availability: Maybe<Scalars['Float']>;
  has_firstname: Maybe<Scalars['Float']>;
  has_fullname: Maybe<Scalars['Float']>;
  has_lastname: Maybe<Scalars['Float']>;
  has_linkedin: Maybe<Scalars['Float']>;
  has_resume: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  is_portal_user: Maybe<Scalars['Float']>;
  num_skills: Maybe<Scalars['Float']>;
  num_work_experience: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "reporting.candidate_metadata". All fields are combined with a logical 'AND'. */
export type Reporting_Candidate_Metadata_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Candidate_Metadata_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Candidate_Metadata_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Candidate_Metadata_Bool_Exp>>;
  candidate_created_at?: InputMaybe<Date_Comparison_Exp>;
  has_availability?: InputMaybe<Int_Comparison_Exp>;
  has_firstname?: InputMaybe<Int_Comparison_Exp>;
  has_fullname?: InputMaybe<Int_Comparison_Exp>;
  has_lastname?: InputMaybe<Int_Comparison_Exp>;
  has_linkedin?: InputMaybe<Int_Comparison_Exp>;
  has_resume?: InputMaybe<Int_Comparison_Exp>;
  icims_created_at?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_portal_user?: InputMaybe<Int_Comparison_Exp>;
  num_skills?: InputMaybe<Bigint_Comparison_Exp>;
  num_work_experience?: InputMaybe<Bigint_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  stage?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "reporting.candidate_metadata" */
export type Reporting_Candidate_Metadata_Inc_Input = {
  has_availability?: InputMaybe<Scalars['Int']>;
  has_firstname?: InputMaybe<Scalars['Int']>;
  has_fullname?: InputMaybe<Scalars['Int']>;
  has_lastname?: InputMaybe<Scalars['Int']>;
  has_linkedin?: InputMaybe<Scalars['Int']>;
  has_resume?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_portal_user?: InputMaybe<Scalars['Int']>;
  num_skills?: InputMaybe<Scalars['bigint']>;
  num_work_experience?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "reporting.candidate_metadata" */
export type Reporting_Candidate_Metadata_Insert_Input = {
  candidate_created_at?: InputMaybe<Scalars['date']>;
  has_availability?: InputMaybe<Scalars['Int']>;
  has_firstname?: InputMaybe<Scalars['Int']>;
  has_fullname?: InputMaybe<Scalars['Int']>;
  has_lastname?: InputMaybe<Scalars['Int']>;
  has_linkedin?: InputMaybe<Scalars['Int']>;
  has_resume?: InputMaybe<Scalars['Int']>;
  icims_created_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  is_portal_user?: InputMaybe<Scalars['Int']>;
  num_skills?: InputMaybe<Scalars['bigint']>;
  num_work_experience?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reporting_Candidate_Metadata_Max_Fields = {
  __typename?: 'reporting_candidate_metadata_max_fields';
  candidate_created_at: Maybe<Scalars['date']>;
  has_availability: Maybe<Scalars['Int']>;
  has_firstname: Maybe<Scalars['Int']>;
  has_fullname: Maybe<Scalars['Int']>;
  has_lastname: Maybe<Scalars['Int']>;
  has_linkedin: Maybe<Scalars['Int']>;
  has_resume: Maybe<Scalars['Int']>;
  icims_created_at: Maybe<Scalars['date']>;
  id: Maybe<Scalars['Int']>;
  is_portal_user: Maybe<Scalars['Int']>;
  num_skills: Maybe<Scalars['bigint']>;
  num_work_experience: Maybe<Scalars['bigint']>;
  source: Maybe<Scalars['String']>;
  stage: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Reporting_Candidate_Metadata_Min_Fields = {
  __typename?: 'reporting_candidate_metadata_min_fields';
  candidate_created_at: Maybe<Scalars['date']>;
  has_availability: Maybe<Scalars['Int']>;
  has_firstname: Maybe<Scalars['Int']>;
  has_fullname: Maybe<Scalars['Int']>;
  has_lastname: Maybe<Scalars['Int']>;
  has_linkedin: Maybe<Scalars['Int']>;
  has_resume: Maybe<Scalars['Int']>;
  icims_created_at: Maybe<Scalars['date']>;
  id: Maybe<Scalars['Int']>;
  is_portal_user: Maybe<Scalars['Int']>;
  num_skills: Maybe<Scalars['bigint']>;
  num_work_experience: Maybe<Scalars['bigint']>;
  source: Maybe<Scalars['String']>;
  stage: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "reporting.candidate_metadata" */
export type Reporting_Candidate_Metadata_Mutation_Response = {
  __typename?: 'reporting_candidate_metadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reporting_Candidate_Metadata>;
};

/** Ordering options when selecting data from "reporting.candidate_metadata". */
export type Reporting_Candidate_Metadata_Order_By = {
  candidate_created_at?: InputMaybe<Order_By>;
  has_availability?: InputMaybe<Order_By>;
  has_firstname?: InputMaybe<Order_By>;
  has_fullname?: InputMaybe<Order_By>;
  has_lastname?: InputMaybe<Order_By>;
  has_linkedin?: InputMaybe<Order_By>;
  has_resume?: InputMaybe<Order_By>;
  icims_created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_portal_user?: InputMaybe<Order_By>;
  num_skills?: InputMaybe<Order_By>;
  num_work_experience?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.candidate_metadata" */
export enum Reporting_Candidate_Metadata_Select_Column {
  /** column name */
  CandidateCreatedAt = 'candidate_created_at',
  /** column name */
  HasAvailability = 'has_availability',
  /** column name */
  HasFirstname = 'has_firstname',
  /** column name */
  HasFullname = 'has_fullname',
  /** column name */
  HasLastname = 'has_lastname',
  /** column name */
  HasLinkedin = 'has_linkedin',
  /** column name */
  HasResume = 'has_resume',
  /** column name */
  IcimsCreatedAt = 'icims_created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsPortalUser = 'is_portal_user',
  /** column name */
  NumSkills = 'num_skills',
  /** column name */
  NumWorkExperience = 'num_work_experience',
  /** column name */
  Source = 'source',
  /** column name */
  Stage = 'stage',
}

/** input type for updating data in table "reporting.candidate_metadata" */
export type Reporting_Candidate_Metadata_Set_Input = {
  candidate_created_at?: InputMaybe<Scalars['date']>;
  has_availability?: InputMaybe<Scalars['Int']>;
  has_firstname?: InputMaybe<Scalars['Int']>;
  has_fullname?: InputMaybe<Scalars['Int']>;
  has_lastname?: InputMaybe<Scalars['Int']>;
  has_linkedin?: InputMaybe<Scalars['Int']>;
  has_resume?: InputMaybe<Scalars['Int']>;
  icims_created_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  is_portal_user?: InputMaybe<Scalars['Int']>;
  num_skills?: InputMaybe<Scalars['bigint']>;
  num_work_experience?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Reporting_Candidate_Metadata_Stddev_Fields = {
  __typename?: 'reporting_candidate_metadata_stddev_fields';
  has_availability: Maybe<Scalars['Float']>;
  has_firstname: Maybe<Scalars['Float']>;
  has_fullname: Maybe<Scalars['Float']>;
  has_lastname: Maybe<Scalars['Float']>;
  has_linkedin: Maybe<Scalars['Float']>;
  has_resume: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  is_portal_user: Maybe<Scalars['Float']>;
  num_skills: Maybe<Scalars['Float']>;
  num_work_experience: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Reporting_Candidate_Metadata_Stddev_Pop_Fields = {
  __typename?: 'reporting_candidate_metadata_stddev_pop_fields';
  has_availability: Maybe<Scalars['Float']>;
  has_firstname: Maybe<Scalars['Float']>;
  has_fullname: Maybe<Scalars['Float']>;
  has_lastname: Maybe<Scalars['Float']>;
  has_linkedin: Maybe<Scalars['Float']>;
  has_resume: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  is_portal_user: Maybe<Scalars['Float']>;
  num_skills: Maybe<Scalars['Float']>;
  num_work_experience: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Reporting_Candidate_Metadata_Stddev_Samp_Fields = {
  __typename?: 'reporting_candidate_metadata_stddev_samp_fields';
  has_availability: Maybe<Scalars['Float']>;
  has_firstname: Maybe<Scalars['Float']>;
  has_fullname: Maybe<Scalars['Float']>;
  has_lastname: Maybe<Scalars['Float']>;
  has_linkedin: Maybe<Scalars['Float']>;
  has_resume: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  is_portal_user: Maybe<Scalars['Float']>;
  num_skills: Maybe<Scalars['Float']>;
  num_work_experience: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "reporting_candidate_metadata" */
export type Reporting_Candidate_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Candidate_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Candidate_Metadata_Stream_Cursor_Value_Input = {
  candidate_created_at?: InputMaybe<Scalars['date']>;
  has_availability?: InputMaybe<Scalars['Int']>;
  has_firstname?: InputMaybe<Scalars['Int']>;
  has_fullname?: InputMaybe<Scalars['Int']>;
  has_lastname?: InputMaybe<Scalars['Int']>;
  has_linkedin?: InputMaybe<Scalars['Int']>;
  has_resume?: InputMaybe<Scalars['Int']>;
  icims_created_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  is_portal_user?: InputMaybe<Scalars['Int']>;
  num_skills?: InputMaybe<Scalars['bigint']>;
  num_work_experience?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Reporting_Candidate_Metadata_Sum_Fields = {
  __typename?: 'reporting_candidate_metadata_sum_fields';
  has_availability: Maybe<Scalars['Int']>;
  has_firstname: Maybe<Scalars['Int']>;
  has_fullname: Maybe<Scalars['Int']>;
  has_lastname: Maybe<Scalars['Int']>;
  has_linkedin: Maybe<Scalars['Int']>;
  has_resume: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  is_portal_user: Maybe<Scalars['Int']>;
  num_skills: Maybe<Scalars['bigint']>;
  num_work_experience: Maybe<Scalars['bigint']>;
};

export type Reporting_Candidate_Metadata_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reporting_Candidate_Metadata_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Candidate_Metadata_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Candidate_Metadata_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reporting_Candidate_Metadata_Var_Pop_Fields = {
  __typename?: 'reporting_candidate_metadata_var_pop_fields';
  has_availability: Maybe<Scalars['Float']>;
  has_firstname: Maybe<Scalars['Float']>;
  has_fullname: Maybe<Scalars['Float']>;
  has_lastname: Maybe<Scalars['Float']>;
  has_linkedin: Maybe<Scalars['Float']>;
  has_resume: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  is_portal_user: Maybe<Scalars['Float']>;
  num_skills: Maybe<Scalars['Float']>;
  num_work_experience: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Reporting_Candidate_Metadata_Var_Samp_Fields = {
  __typename?: 'reporting_candidate_metadata_var_samp_fields';
  has_availability: Maybe<Scalars['Float']>;
  has_firstname: Maybe<Scalars['Float']>;
  has_fullname: Maybe<Scalars['Float']>;
  has_lastname: Maybe<Scalars['Float']>;
  has_linkedin: Maybe<Scalars['Float']>;
  has_resume: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  is_portal_user: Maybe<Scalars['Float']>;
  num_skills: Maybe<Scalars['Float']>;
  num_work_experience: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Reporting_Candidate_Metadata_Variance_Fields = {
  __typename?: 'reporting_candidate_metadata_variance_fields';
  has_availability: Maybe<Scalars['Float']>;
  has_firstname: Maybe<Scalars['Float']>;
  has_fullname: Maybe<Scalars['Float']>;
  has_lastname: Maybe<Scalars['Float']>;
  has_linkedin: Maybe<Scalars['Float']>;
  has_resume: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  is_portal_user: Maybe<Scalars['Float']>;
  num_skills: Maybe<Scalars['Float']>;
  num_work_experience: Maybe<Scalars['Float']>;
};

/** columns and relationships of "reporting.email_suppression_traits" */
export type Reporting_Email_Suppression_Traits = {
  __typename?: 'reporting_email_suppression_traits';
  anonymous_id: Maybe<Scalars['String']>;
  in_hiring_process: Maybe<Scalars['Boolean']>;
  is_member: Maybe<Scalars['Boolean']>;
  is_terminated: Maybe<Scalars['Boolean']>;
  recently_partner_submitted: Maybe<Scalars['Boolean']>;
  recently_rejected: Maybe<Scalars['Boolean']>;
  recently_rescinded: Maybe<Scalars['Boolean']>;
  termination_date: Maybe<Scalars['timestamp']>;
  user_id: Maybe<Scalars['String']>;
};

/** aggregated selection of "reporting.email_suppression_traits" */
export type Reporting_Email_Suppression_Traits_Aggregate = {
  __typename?: 'reporting_email_suppression_traits_aggregate';
  aggregate: Maybe<Reporting_Email_Suppression_Traits_Aggregate_Fields>;
  nodes: Array<Reporting_Email_Suppression_Traits>;
};

/** aggregate fields of "reporting.email_suppression_traits" */
export type Reporting_Email_Suppression_Traits_Aggregate_Fields = {
  __typename?: 'reporting_email_suppression_traits_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Reporting_Email_Suppression_Traits_Max_Fields>;
  min: Maybe<Reporting_Email_Suppression_Traits_Min_Fields>;
};

/** aggregate fields of "reporting.email_suppression_traits" */
export type Reporting_Email_Suppression_Traits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reporting_Email_Suppression_Traits_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "reporting.email_suppression_traits". All fields are combined with a logical 'AND'. */
export type Reporting_Email_Suppression_Traits_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Email_Suppression_Traits_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Email_Suppression_Traits_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Email_Suppression_Traits_Bool_Exp>>;
  anonymous_id?: InputMaybe<String_Comparison_Exp>;
  in_hiring_process?: InputMaybe<Boolean_Comparison_Exp>;
  is_member?: InputMaybe<Boolean_Comparison_Exp>;
  is_terminated?: InputMaybe<Boolean_Comparison_Exp>;
  recently_partner_submitted?: InputMaybe<Boolean_Comparison_Exp>;
  recently_rejected?: InputMaybe<Boolean_Comparison_Exp>;
  recently_rescinded?: InputMaybe<Boolean_Comparison_Exp>;
  termination_date?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Reporting_Email_Suppression_Traits_Max_Fields = {
  __typename?: 'reporting_email_suppression_traits_max_fields';
  anonymous_id: Maybe<Scalars['String']>;
  termination_date: Maybe<Scalars['timestamp']>;
  user_id: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Reporting_Email_Suppression_Traits_Min_Fields = {
  __typename?: 'reporting_email_suppression_traits_min_fields';
  anonymous_id: Maybe<Scalars['String']>;
  termination_date: Maybe<Scalars['timestamp']>;
  user_id: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "reporting.email_suppression_traits". */
export type Reporting_Email_Suppression_Traits_Order_By = {
  anonymous_id?: InputMaybe<Order_By>;
  in_hiring_process?: InputMaybe<Order_By>;
  is_member?: InputMaybe<Order_By>;
  is_terminated?: InputMaybe<Order_By>;
  recently_partner_submitted?: InputMaybe<Order_By>;
  recently_rejected?: InputMaybe<Order_By>;
  recently_rescinded?: InputMaybe<Order_By>;
  termination_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.email_suppression_traits" */
export enum Reporting_Email_Suppression_Traits_Select_Column {
  /** column name */
  AnonymousId = 'anonymous_id',
  /** column name */
  InHiringProcess = 'in_hiring_process',
  /** column name */
  IsMember = 'is_member',
  /** column name */
  IsTerminated = 'is_terminated',
  /** column name */
  RecentlyPartnerSubmitted = 'recently_partner_submitted',
  /** column name */
  RecentlyRejected = 'recently_rejected',
  /** column name */
  RecentlyRescinded = 'recently_rescinded',
  /** column name */
  TerminationDate = 'termination_date',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "reporting_email_suppression_traits" */
export type Reporting_Email_Suppression_Traits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Email_Suppression_Traits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Email_Suppression_Traits_Stream_Cursor_Value_Input = {
  anonymous_id?: InputMaybe<Scalars['String']>;
  in_hiring_process?: InputMaybe<Scalars['Boolean']>;
  is_member?: InputMaybe<Scalars['Boolean']>;
  is_terminated?: InputMaybe<Scalars['Boolean']>;
  recently_partner_submitted?: InputMaybe<Scalars['Boolean']>;
  recently_rejected?: InputMaybe<Scalars['Boolean']>;
  recently_rescinded?: InputMaybe<Scalars['Boolean']>;
  termination_date?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Daily job match analytics */
export type Reporting_Report_Job_Matches = {
  __typename?: 'reporting_report_job_matches';
  avg_matches_per_candidate: Scalars['float8'];
  avg_matches_per_job: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  max_matches_per_candidate: Scalars['Int'];
  median_matches_per_candidate: Scalars['float8'];
  total_active_jobs: Scalars['Int'];
  total_anonymous_users: Scalars['Int'];
  total_candidates: Scalars['Int'];
  total_matches: Scalars['Int'];
  total_portal_users: Scalars['Int'];
  total_qualified_candidates: Scalars['Int'];
  total_subscriptions: Maybe<Scalars['Int']>;
  unq_candidates_with_matches: Scalars['Int'];
  unq_candidates_without_matches: Scalars['Int'];
};

/** aggregated selection of "reporting.report_job_matches" */
export type Reporting_Report_Job_Matches_Aggregate = {
  __typename?: 'reporting_report_job_matches_aggregate';
  aggregate: Maybe<Reporting_Report_Job_Matches_Aggregate_Fields>;
  nodes: Array<Reporting_Report_Job_Matches>;
};

/** aggregate fields of "reporting.report_job_matches" */
export type Reporting_Report_Job_Matches_Aggregate_Fields = {
  __typename?: 'reporting_report_job_matches_aggregate_fields';
  avg: Maybe<Reporting_Report_Job_Matches_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Reporting_Report_Job_Matches_Max_Fields>;
  min: Maybe<Reporting_Report_Job_Matches_Min_Fields>;
  stddev: Maybe<Reporting_Report_Job_Matches_Stddev_Fields>;
  stddev_pop: Maybe<Reporting_Report_Job_Matches_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Reporting_Report_Job_Matches_Stddev_Samp_Fields>;
  sum: Maybe<Reporting_Report_Job_Matches_Sum_Fields>;
  var_pop: Maybe<Reporting_Report_Job_Matches_Var_Pop_Fields>;
  var_samp: Maybe<Reporting_Report_Job_Matches_Var_Samp_Fields>;
  variance: Maybe<Reporting_Report_Job_Matches_Variance_Fields>;
};

/** aggregate fields of "reporting.report_job_matches" */
export type Reporting_Report_Job_Matches_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reporting_Report_Job_Matches_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Reporting_Report_Job_Matches_Avg_Fields = {
  __typename?: 'reporting_report_job_matches_avg_fields';
  avg_matches_per_candidate: Maybe<Scalars['Float']>;
  avg_matches_per_job: Maybe<Scalars['Float']>;
  max_matches_per_candidate: Maybe<Scalars['Float']>;
  median_matches_per_candidate: Maybe<Scalars['Float']>;
  total_active_jobs: Maybe<Scalars['Float']>;
  total_anonymous_users: Maybe<Scalars['Float']>;
  total_candidates: Maybe<Scalars['Float']>;
  total_matches: Maybe<Scalars['Float']>;
  total_portal_users: Maybe<Scalars['Float']>;
  total_qualified_candidates: Maybe<Scalars['Float']>;
  total_subscriptions: Maybe<Scalars['Float']>;
  unq_candidates_with_matches: Maybe<Scalars['Float']>;
  unq_candidates_without_matches: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "reporting.report_job_matches". All fields are combined with a logical 'AND'. */
export type Reporting_Report_Job_Matches_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Report_Job_Matches_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Report_Job_Matches_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Report_Job_Matches_Bool_Exp>>;
  avg_matches_per_candidate?: InputMaybe<Float8_Comparison_Exp>;
  avg_matches_per_job?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  max_matches_per_candidate?: InputMaybe<Int_Comparison_Exp>;
  median_matches_per_candidate?: InputMaybe<Float8_Comparison_Exp>;
  total_active_jobs?: InputMaybe<Int_Comparison_Exp>;
  total_anonymous_users?: InputMaybe<Int_Comparison_Exp>;
  total_candidates?: InputMaybe<Int_Comparison_Exp>;
  total_matches?: InputMaybe<Int_Comparison_Exp>;
  total_portal_users?: InputMaybe<Int_Comparison_Exp>;
  total_qualified_candidates?: InputMaybe<Int_Comparison_Exp>;
  total_subscriptions?: InputMaybe<Int_Comparison_Exp>;
  unq_candidates_with_matches?: InputMaybe<Int_Comparison_Exp>;
  unq_candidates_without_matches?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "reporting.report_job_matches" */
export enum Reporting_Report_Job_Matches_Constraint {
  /** unique or primary key constraint on columns "created_at" */
  ReportJobMatchesPkey = 'report_job_matches_pkey',
}

/** input type for incrementing numeric columns in table "reporting.report_job_matches" */
export type Reporting_Report_Job_Matches_Inc_Input = {
  avg_matches_per_candidate?: InputMaybe<Scalars['float8']>;
  avg_matches_per_job?: InputMaybe<Scalars['float8']>;
  max_matches_per_candidate?: InputMaybe<Scalars['Int']>;
  median_matches_per_candidate?: InputMaybe<Scalars['float8']>;
  total_active_jobs?: InputMaybe<Scalars['Int']>;
  total_anonymous_users?: InputMaybe<Scalars['Int']>;
  total_candidates?: InputMaybe<Scalars['Int']>;
  total_matches?: InputMaybe<Scalars['Int']>;
  total_portal_users?: InputMaybe<Scalars['Int']>;
  total_qualified_candidates?: InputMaybe<Scalars['Int']>;
  total_subscriptions?: InputMaybe<Scalars['Int']>;
  unq_candidates_with_matches?: InputMaybe<Scalars['Int']>;
  unq_candidates_without_matches?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reporting.report_job_matches" */
export type Reporting_Report_Job_Matches_Insert_Input = {
  avg_matches_per_candidate?: InputMaybe<Scalars['float8']>;
  avg_matches_per_job?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  max_matches_per_candidate?: InputMaybe<Scalars['Int']>;
  median_matches_per_candidate?: InputMaybe<Scalars['float8']>;
  total_active_jobs?: InputMaybe<Scalars['Int']>;
  total_anonymous_users?: InputMaybe<Scalars['Int']>;
  total_candidates?: InputMaybe<Scalars['Int']>;
  total_matches?: InputMaybe<Scalars['Int']>;
  total_portal_users?: InputMaybe<Scalars['Int']>;
  total_qualified_candidates?: InputMaybe<Scalars['Int']>;
  total_subscriptions?: InputMaybe<Scalars['Int']>;
  unq_candidates_with_matches?: InputMaybe<Scalars['Int']>;
  unq_candidates_without_matches?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Reporting_Report_Job_Matches_Max_Fields = {
  __typename?: 'reporting_report_job_matches_max_fields';
  avg_matches_per_candidate: Maybe<Scalars['float8']>;
  avg_matches_per_job: Maybe<Scalars['float8']>;
  created_at: Maybe<Scalars['timestamptz']>;
  max_matches_per_candidate: Maybe<Scalars['Int']>;
  median_matches_per_candidate: Maybe<Scalars['float8']>;
  total_active_jobs: Maybe<Scalars['Int']>;
  total_anonymous_users: Maybe<Scalars['Int']>;
  total_candidates: Maybe<Scalars['Int']>;
  total_matches: Maybe<Scalars['Int']>;
  total_portal_users: Maybe<Scalars['Int']>;
  total_qualified_candidates: Maybe<Scalars['Int']>;
  total_subscriptions: Maybe<Scalars['Int']>;
  unq_candidates_with_matches: Maybe<Scalars['Int']>;
  unq_candidates_without_matches: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Reporting_Report_Job_Matches_Min_Fields = {
  __typename?: 'reporting_report_job_matches_min_fields';
  avg_matches_per_candidate: Maybe<Scalars['float8']>;
  avg_matches_per_job: Maybe<Scalars['float8']>;
  created_at: Maybe<Scalars['timestamptz']>;
  max_matches_per_candidate: Maybe<Scalars['Int']>;
  median_matches_per_candidate: Maybe<Scalars['float8']>;
  total_active_jobs: Maybe<Scalars['Int']>;
  total_anonymous_users: Maybe<Scalars['Int']>;
  total_candidates: Maybe<Scalars['Int']>;
  total_matches: Maybe<Scalars['Int']>;
  total_portal_users: Maybe<Scalars['Int']>;
  total_qualified_candidates: Maybe<Scalars['Int']>;
  total_subscriptions: Maybe<Scalars['Int']>;
  unq_candidates_with_matches: Maybe<Scalars['Int']>;
  unq_candidates_without_matches: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "reporting.report_job_matches" */
export type Reporting_Report_Job_Matches_Mutation_Response = {
  __typename?: 'reporting_report_job_matches_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reporting_Report_Job_Matches>;
};

/** on_conflict condition type for table "reporting.report_job_matches" */
export type Reporting_Report_Job_Matches_On_Conflict = {
  constraint: Reporting_Report_Job_Matches_Constraint;
  update_columns?: Array<Reporting_Report_Job_Matches_Update_Column>;
  where?: InputMaybe<Reporting_Report_Job_Matches_Bool_Exp>;
};

/** Ordering options when selecting data from "reporting.report_job_matches". */
export type Reporting_Report_Job_Matches_Order_By = {
  avg_matches_per_candidate?: InputMaybe<Order_By>;
  avg_matches_per_job?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  max_matches_per_candidate?: InputMaybe<Order_By>;
  median_matches_per_candidate?: InputMaybe<Order_By>;
  total_active_jobs?: InputMaybe<Order_By>;
  total_anonymous_users?: InputMaybe<Order_By>;
  total_candidates?: InputMaybe<Order_By>;
  total_matches?: InputMaybe<Order_By>;
  total_portal_users?: InputMaybe<Order_By>;
  total_qualified_candidates?: InputMaybe<Order_By>;
  total_subscriptions?: InputMaybe<Order_By>;
  unq_candidates_with_matches?: InputMaybe<Order_By>;
  unq_candidates_without_matches?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reporting.report_job_matches */
export type Reporting_Report_Job_Matches_Pk_Columns_Input = {
  created_at: Scalars['timestamptz'];
};

/** select columns of table "reporting.report_job_matches" */
export enum Reporting_Report_Job_Matches_Select_Column {
  /** column name */
  AvgMatchesPerCandidate = 'avg_matches_per_candidate',
  /** column name */
  AvgMatchesPerJob = 'avg_matches_per_job',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MaxMatchesPerCandidate = 'max_matches_per_candidate',
  /** column name */
  MedianMatchesPerCandidate = 'median_matches_per_candidate',
  /** column name */
  TotalActiveJobs = 'total_active_jobs',
  /** column name */
  TotalAnonymousUsers = 'total_anonymous_users',
  /** column name */
  TotalCandidates = 'total_candidates',
  /** column name */
  TotalMatches = 'total_matches',
  /** column name */
  TotalPortalUsers = 'total_portal_users',
  /** column name */
  TotalQualifiedCandidates = 'total_qualified_candidates',
  /** column name */
  TotalSubscriptions = 'total_subscriptions',
  /** column name */
  UnqCandidatesWithMatches = 'unq_candidates_with_matches',
  /** column name */
  UnqCandidatesWithoutMatches = 'unq_candidates_without_matches',
}

/** input type for updating data in table "reporting.report_job_matches" */
export type Reporting_Report_Job_Matches_Set_Input = {
  avg_matches_per_candidate?: InputMaybe<Scalars['float8']>;
  avg_matches_per_job?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  max_matches_per_candidate?: InputMaybe<Scalars['Int']>;
  median_matches_per_candidate?: InputMaybe<Scalars['float8']>;
  total_active_jobs?: InputMaybe<Scalars['Int']>;
  total_anonymous_users?: InputMaybe<Scalars['Int']>;
  total_candidates?: InputMaybe<Scalars['Int']>;
  total_matches?: InputMaybe<Scalars['Int']>;
  total_portal_users?: InputMaybe<Scalars['Int']>;
  total_qualified_candidates?: InputMaybe<Scalars['Int']>;
  total_subscriptions?: InputMaybe<Scalars['Int']>;
  unq_candidates_with_matches?: InputMaybe<Scalars['Int']>;
  unq_candidates_without_matches?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Reporting_Report_Job_Matches_Stddev_Fields = {
  __typename?: 'reporting_report_job_matches_stddev_fields';
  avg_matches_per_candidate: Maybe<Scalars['Float']>;
  avg_matches_per_job: Maybe<Scalars['Float']>;
  max_matches_per_candidate: Maybe<Scalars['Float']>;
  median_matches_per_candidate: Maybe<Scalars['Float']>;
  total_active_jobs: Maybe<Scalars['Float']>;
  total_anonymous_users: Maybe<Scalars['Float']>;
  total_candidates: Maybe<Scalars['Float']>;
  total_matches: Maybe<Scalars['Float']>;
  total_portal_users: Maybe<Scalars['Float']>;
  total_qualified_candidates: Maybe<Scalars['Float']>;
  total_subscriptions: Maybe<Scalars['Float']>;
  unq_candidates_with_matches: Maybe<Scalars['Float']>;
  unq_candidates_without_matches: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Reporting_Report_Job_Matches_Stddev_Pop_Fields = {
  __typename?: 'reporting_report_job_matches_stddev_pop_fields';
  avg_matches_per_candidate: Maybe<Scalars['Float']>;
  avg_matches_per_job: Maybe<Scalars['Float']>;
  max_matches_per_candidate: Maybe<Scalars['Float']>;
  median_matches_per_candidate: Maybe<Scalars['Float']>;
  total_active_jobs: Maybe<Scalars['Float']>;
  total_anonymous_users: Maybe<Scalars['Float']>;
  total_candidates: Maybe<Scalars['Float']>;
  total_matches: Maybe<Scalars['Float']>;
  total_portal_users: Maybe<Scalars['Float']>;
  total_qualified_candidates: Maybe<Scalars['Float']>;
  total_subscriptions: Maybe<Scalars['Float']>;
  unq_candidates_with_matches: Maybe<Scalars['Float']>;
  unq_candidates_without_matches: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Reporting_Report_Job_Matches_Stddev_Samp_Fields = {
  __typename?: 'reporting_report_job_matches_stddev_samp_fields';
  avg_matches_per_candidate: Maybe<Scalars['Float']>;
  avg_matches_per_job: Maybe<Scalars['Float']>;
  max_matches_per_candidate: Maybe<Scalars['Float']>;
  median_matches_per_candidate: Maybe<Scalars['Float']>;
  total_active_jobs: Maybe<Scalars['Float']>;
  total_anonymous_users: Maybe<Scalars['Float']>;
  total_candidates: Maybe<Scalars['Float']>;
  total_matches: Maybe<Scalars['Float']>;
  total_portal_users: Maybe<Scalars['Float']>;
  total_qualified_candidates: Maybe<Scalars['Float']>;
  total_subscriptions: Maybe<Scalars['Float']>;
  unq_candidates_with_matches: Maybe<Scalars['Float']>;
  unq_candidates_without_matches: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "reporting_report_job_matches" */
export type Reporting_Report_Job_Matches_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Report_Job_Matches_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Report_Job_Matches_Stream_Cursor_Value_Input = {
  avg_matches_per_candidate?: InputMaybe<Scalars['float8']>;
  avg_matches_per_job?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  max_matches_per_candidate?: InputMaybe<Scalars['Int']>;
  median_matches_per_candidate?: InputMaybe<Scalars['float8']>;
  total_active_jobs?: InputMaybe<Scalars['Int']>;
  total_anonymous_users?: InputMaybe<Scalars['Int']>;
  total_candidates?: InputMaybe<Scalars['Int']>;
  total_matches?: InputMaybe<Scalars['Int']>;
  total_portal_users?: InputMaybe<Scalars['Int']>;
  total_qualified_candidates?: InputMaybe<Scalars['Int']>;
  total_subscriptions?: InputMaybe<Scalars['Int']>;
  unq_candidates_with_matches?: InputMaybe<Scalars['Int']>;
  unq_candidates_without_matches?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Reporting_Report_Job_Matches_Sum_Fields = {
  __typename?: 'reporting_report_job_matches_sum_fields';
  avg_matches_per_candidate: Maybe<Scalars['float8']>;
  avg_matches_per_job: Maybe<Scalars['float8']>;
  max_matches_per_candidate: Maybe<Scalars['Int']>;
  median_matches_per_candidate: Maybe<Scalars['float8']>;
  total_active_jobs: Maybe<Scalars['Int']>;
  total_anonymous_users: Maybe<Scalars['Int']>;
  total_candidates: Maybe<Scalars['Int']>;
  total_matches: Maybe<Scalars['Int']>;
  total_portal_users: Maybe<Scalars['Int']>;
  total_qualified_candidates: Maybe<Scalars['Int']>;
  total_subscriptions: Maybe<Scalars['Int']>;
  unq_candidates_with_matches: Maybe<Scalars['Int']>;
  unq_candidates_without_matches: Maybe<Scalars['Int']>;
};

/** update columns of table "reporting.report_job_matches" */
export enum Reporting_Report_Job_Matches_Update_Column {
  /** column name */
  AvgMatchesPerCandidate = 'avg_matches_per_candidate',
  /** column name */
  AvgMatchesPerJob = 'avg_matches_per_job',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MaxMatchesPerCandidate = 'max_matches_per_candidate',
  /** column name */
  MedianMatchesPerCandidate = 'median_matches_per_candidate',
  /** column name */
  TotalActiveJobs = 'total_active_jobs',
  /** column name */
  TotalAnonymousUsers = 'total_anonymous_users',
  /** column name */
  TotalCandidates = 'total_candidates',
  /** column name */
  TotalMatches = 'total_matches',
  /** column name */
  TotalPortalUsers = 'total_portal_users',
  /** column name */
  TotalQualifiedCandidates = 'total_qualified_candidates',
  /** column name */
  TotalSubscriptions = 'total_subscriptions',
  /** column name */
  UnqCandidatesWithMatches = 'unq_candidates_with_matches',
  /** column name */
  UnqCandidatesWithoutMatches = 'unq_candidates_without_matches',
}

export type Reporting_Report_Job_Matches_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reporting_Report_Job_Matches_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Report_Job_Matches_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Report_Job_Matches_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reporting_Report_Job_Matches_Var_Pop_Fields = {
  __typename?: 'reporting_report_job_matches_var_pop_fields';
  avg_matches_per_candidate: Maybe<Scalars['Float']>;
  avg_matches_per_job: Maybe<Scalars['Float']>;
  max_matches_per_candidate: Maybe<Scalars['Float']>;
  median_matches_per_candidate: Maybe<Scalars['Float']>;
  total_active_jobs: Maybe<Scalars['Float']>;
  total_anonymous_users: Maybe<Scalars['Float']>;
  total_candidates: Maybe<Scalars['Float']>;
  total_matches: Maybe<Scalars['Float']>;
  total_portal_users: Maybe<Scalars['Float']>;
  total_qualified_candidates: Maybe<Scalars['Float']>;
  total_subscriptions: Maybe<Scalars['Float']>;
  unq_candidates_with_matches: Maybe<Scalars['Float']>;
  unq_candidates_without_matches: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Reporting_Report_Job_Matches_Var_Samp_Fields = {
  __typename?: 'reporting_report_job_matches_var_samp_fields';
  avg_matches_per_candidate: Maybe<Scalars['Float']>;
  avg_matches_per_job: Maybe<Scalars['Float']>;
  max_matches_per_candidate: Maybe<Scalars['Float']>;
  median_matches_per_candidate: Maybe<Scalars['Float']>;
  total_active_jobs: Maybe<Scalars['Float']>;
  total_anonymous_users: Maybe<Scalars['Float']>;
  total_candidates: Maybe<Scalars['Float']>;
  total_matches: Maybe<Scalars['Float']>;
  total_portal_users: Maybe<Scalars['Float']>;
  total_qualified_candidates: Maybe<Scalars['Float']>;
  total_subscriptions: Maybe<Scalars['Float']>;
  unq_candidates_with_matches: Maybe<Scalars['Float']>;
  unq_candidates_without_matches: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Reporting_Report_Job_Matches_Variance_Fields = {
  __typename?: 'reporting_report_job_matches_variance_fields';
  avg_matches_per_candidate: Maybe<Scalars['Float']>;
  avg_matches_per_job: Maybe<Scalars['Float']>;
  max_matches_per_candidate: Maybe<Scalars['Float']>;
  median_matches_per_candidate: Maybe<Scalars['Float']>;
  total_active_jobs: Maybe<Scalars['Float']>;
  total_anonymous_users: Maybe<Scalars['Float']>;
  total_candidates: Maybe<Scalars['Float']>;
  total_matches: Maybe<Scalars['Float']>;
  total_portal_users: Maybe<Scalars['Float']>;
  total_qualified_candidates: Maybe<Scalars['Float']>;
  total_subscriptions: Maybe<Scalars['Float']>;
  unq_candidates_with_matches: Maybe<Scalars['Float']>;
  unq_candidates_without_matches: Maybe<Scalars['Float']>;
};

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role';
  id: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: 'role_aggregate';
  aggregate: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: 'role_aggregate_fields';
  avg: Maybe<Role_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Role_Max_Fields>;
  min: Maybe<Role_Min_Fields>;
  stddev: Maybe<Role_Stddev_Fields>;
  stddev_pop: Maybe<Role_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Role_Stddev_Samp_Fields>;
  sum: Maybe<Role_Sum_Fields>;
  var_pop: Maybe<Role_Var_Pop_Fields>;
  var_samp: Maybe<Role_Var_Samp_Fields>;
  variance: Maybe<Role_Variance_Fields>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Role_Avg_Fields = {
  __typename?: 'role_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Bool_Exp>>;
  _not?: InputMaybe<Role_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Enum table that contains choices for roles */
export type Role_Choices = {
  __typename?: 'role_choices';
  /** An object relationship */
  ai_role_multiplier_focus: Maybe<Ai_Role_Multiplier_Focus>;
  /** An array relationship */
  candidate_roles: Array<Candidate_Role>;
  /** An aggregate relationship */
  candidate_roles_aggregate: Candidate_Role_Aggregate;
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Enum table that contains choices for roles */
export type Role_ChoicesCandidate_RolesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Order_By>>;
  where?: InputMaybe<Candidate_Role_Bool_Exp>;
};

/** Enum table that contains choices for roles */
export type Role_ChoicesCandidate_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Order_By>>;
  where?: InputMaybe<Candidate_Role_Bool_Exp>;
};

/** aggregated selection of "role_choices" */
export type Role_Choices_Aggregate = {
  __typename?: 'role_choices_aggregate';
  aggregate: Maybe<Role_Choices_Aggregate_Fields>;
  nodes: Array<Role_Choices>;
};

/** aggregate fields of "role_choices" */
export type Role_Choices_Aggregate_Fields = {
  __typename?: 'role_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Role_Choices_Max_Fields>;
  min: Maybe<Role_Choices_Min_Fields>;
};

/** aggregate fields of "role_choices" */
export type Role_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "role_choices". All fields are combined with a logical 'AND'. */
export type Role_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Choices_Bool_Exp>>;
  _not?: InputMaybe<Role_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Choices_Bool_Exp>>;
  ai_role_multiplier_focus?: InputMaybe<Ai_Role_Multiplier_Focus_Bool_Exp>;
  candidate_roles?: InputMaybe<Candidate_Role_Bool_Exp>;
  candidate_roles_aggregate?: InputMaybe<Candidate_Role_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_choices" */
export enum Role_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  CandidateRoleChoicesPkey = 'candidate_role_choices_pkey',
}

export enum Role_Choices_Enum {
  /** AI/ML */
  Aml = 'AML',
  /** Automation QA */
  Aqa = 'AQA',
  /** Backend */
  Bed = 'BED',
  /** Data Analyst */
  Dan = 'DAN',
  /** Data Engineer */
  Den = 'DEN',
  /** Designer */
  Des = 'DES',
  /** DevOps */
  Dev = 'DEV',
  /** Data Science */
  Dsc = 'DSC',
  /** Eng. Lead */
  Enl = 'ENL',
  /** Frontend */
  Fed = 'FED',
  /** Full Stack */
  Fsd = 'FSD',
  /** Mobile */
  Mod = 'MOD',
  /** Manual QA */
  Mqa = 'MQA',
  /** Product Management */
  Prm = 'PRM',
  /** Scrum Master */
  Scm = 'SCM',
}

/** Boolean expression to compare columns of type "role_choices_enum". All fields are combined with logical 'AND'. */
export type Role_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Role_Choices_Enum>;
  _in?: InputMaybe<Array<Role_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Role_Choices_Enum>;
  _nin?: InputMaybe<Array<Role_Choices_Enum>>;
};

/** input type for inserting data into table "role_choices" */
export type Role_Choices_Insert_Input = {
  ai_role_multiplier_focus?: InputMaybe<Ai_Role_Multiplier_Focus_Obj_Rel_Insert_Input>;
  candidate_roles?: InputMaybe<Candidate_Role_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Role_Choices_Max_Fields = {
  __typename?: 'role_choices_max_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Role_Choices_Min_Fields = {
  __typename?: 'role_choices_min_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role_choices" */
export type Role_Choices_Mutation_Response = {
  __typename?: 'role_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Choices>;
};

/** input type for inserting object relation for remote table "role_choices" */
export type Role_Choices_Obj_Rel_Insert_Input = {
  data: Role_Choices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Choices_On_Conflict>;
};

/** on_conflict condition type for table "role_choices" */
export type Role_Choices_On_Conflict = {
  constraint: Role_Choices_Constraint;
  update_columns?: Array<Role_Choices_Update_Column>;
  where?: InputMaybe<Role_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "role_choices". */
export type Role_Choices_Order_By = {
  ai_role_multiplier_focus?: InputMaybe<Ai_Role_Multiplier_Focus_Order_By>;
  candidate_roles_aggregate?: InputMaybe<Candidate_Role_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_choices */
export type Role_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "role_choices" */
export enum Role_Choices_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "role_choices" */
export type Role_Choices_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "role_choices" */
export type Role_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Choices_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "role_choices" */
export enum Role_Choices_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

export type Role_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Choices_Bool_Exp;
};

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  RolePkey = 'role_pkey',
  /** unique or primary key constraint on columns "value" */
  RoleValueKey = 'role_value_key',
}

/** input type for incrementing numeric columns in table "role" */
export type Role_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'role_max_fields';
  id: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'role_min_fields';
  id: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: 'role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
};

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_On_Conflict>;
};

/** on_conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint;
  update_columns?: Array<Role_Update_Column>;
  where?: InputMaybe<Role_Bool_Exp>;
};

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Role_Stddev_Fields = {
  __typename?: 'role_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Role_Stddev_Pop_Fields = {
  __typename?: 'role_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Role_Stddev_Samp_Fields = {
  __typename?: 'role_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "role" */
export type Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Role_Sum_Fields = {
  __typename?: 'role_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value',
}

export type Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Role_Var_Pop_Fields = {
  __typename?: 'role_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Role_Var_Samp_Fields = {
  __typename?: 'role_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Role_Variance_Fields = {
  __typename?: 'role_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** Table containing salary info for different regions */
export type Salary_Info = {
  __typename?: 'salary_info';
  country: Scalars['String'];
  id: Scalars['Int'];
  max_salary: Scalars['numeric'];
  min_salary: Scalars['numeric'];
  region: Maybe<Region_Choices_Enum>;
  role: Role_Choices_Enum;
  years_of_experience: Salary_Years_Experience_Range_Choices_Enum;
};

/** aggregated selection of "salary_info" */
export type Salary_Info_Aggregate = {
  __typename?: 'salary_info_aggregate';
  aggregate: Maybe<Salary_Info_Aggregate_Fields>;
  nodes: Array<Salary_Info>;
};

/** aggregate fields of "salary_info" */
export type Salary_Info_Aggregate_Fields = {
  __typename?: 'salary_info_aggregate_fields';
  avg: Maybe<Salary_Info_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Salary_Info_Max_Fields>;
  min: Maybe<Salary_Info_Min_Fields>;
  stddev: Maybe<Salary_Info_Stddev_Fields>;
  stddev_pop: Maybe<Salary_Info_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Salary_Info_Stddev_Samp_Fields>;
  sum: Maybe<Salary_Info_Sum_Fields>;
  var_pop: Maybe<Salary_Info_Var_Pop_Fields>;
  var_samp: Maybe<Salary_Info_Var_Samp_Fields>;
  variance: Maybe<Salary_Info_Variance_Fields>;
};

/** aggregate fields of "salary_info" */
export type Salary_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salary_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Salary_Info_Avg_Fields = {
  __typename?: 'salary_info_avg_fields';
  id: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "salary_info". All fields are combined with a logical 'AND'. */
export type Salary_Info_Bool_Exp = {
  _and?: InputMaybe<Array<Salary_Info_Bool_Exp>>;
  _not?: InputMaybe<Salary_Info_Bool_Exp>;
  _or?: InputMaybe<Array<Salary_Info_Bool_Exp>>;
  country?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  max_salary?: InputMaybe<Numeric_Comparison_Exp>;
  min_salary?: InputMaybe<Numeric_Comparison_Exp>;
  region?: InputMaybe<Region_Choices_Enum_Comparison_Exp>;
  role?: InputMaybe<Role_Choices_Enum_Comparison_Exp>;
  years_of_experience?: InputMaybe<Salary_Years_Experience_Range_Choices_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "salary_info" */
export enum Salary_Info_Constraint {
  /** unique or primary key constraint on columns "id" */
  SalaryInfoPkey = 'salary_info_pkey',
}

/** input type for incrementing numeric columns in table "salary_info" */
export type Salary_Info_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "salary_info" */
export type Salary_Info_Insert_Input = {
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
  region?: InputMaybe<Region_Choices_Enum>;
  role?: InputMaybe<Role_Choices_Enum>;
  years_of_experience?: InputMaybe<Salary_Years_Experience_Range_Choices_Enum>;
};

/** aggregate max on columns */
export type Salary_Info_Max_Fields = {
  __typename?: 'salary_info_max_fields';
  country: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  max_salary: Maybe<Scalars['numeric']>;
  min_salary: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Salary_Info_Min_Fields = {
  __typename?: 'salary_info_min_fields';
  country: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  max_salary: Maybe<Scalars['numeric']>;
  min_salary: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "salary_info" */
export type Salary_Info_Mutation_Response = {
  __typename?: 'salary_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salary_Info>;
};

/** on_conflict condition type for table "salary_info" */
export type Salary_Info_On_Conflict = {
  constraint: Salary_Info_Constraint;
  update_columns?: Array<Salary_Info_Update_Column>;
  where?: InputMaybe<Salary_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "salary_info". */
export type Salary_Info_Order_By = {
  country?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_salary?: InputMaybe<Order_By>;
  min_salary?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  years_of_experience?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salary_info */
export type Salary_Info_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "salary_info" */
export enum Salary_Info_Select_Column {
  /** column name */
  Country = 'country',
  /** column name */
  Id = 'id',
  /** column name */
  MaxSalary = 'max_salary',
  /** column name */
  MinSalary = 'min_salary',
  /** column name */
  Region = 'region',
  /** column name */
  Role = 'role',
  /** column name */
  YearsOfExperience = 'years_of_experience',
}

/** input type for updating data in table "salary_info" */
export type Salary_Info_Set_Input = {
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
  region?: InputMaybe<Region_Choices_Enum>;
  role?: InputMaybe<Role_Choices_Enum>;
  years_of_experience?: InputMaybe<Salary_Years_Experience_Range_Choices_Enum>;
};

/** aggregate stddev on columns */
export type Salary_Info_Stddev_Fields = {
  __typename?: 'salary_info_stddev_fields';
  id: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Salary_Info_Stddev_Pop_Fields = {
  __typename?: 'salary_info_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Salary_Info_Stddev_Samp_Fields = {
  __typename?: 'salary_info_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "salary_info" */
export type Salary_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Salary_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salary_Info_Stream_Cursor_Value_Input = {
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_salary?: InputMaybe<Scalars['numeric']>;
  min_salary?: InputMaybe<Scalars['numeric']>;
  region?: InputMaybe<Region_Choices_Enum>;
  role?: InputMaybe<Role_Choices_Enum>;
  years_of_experience?: InputMaybe<Salary_Years_Experience_Range_Choices_Enum>;
};

/** aggregate sum on columns */
export type Salary_Info_Sum_Fields = {
  __typename?: 'salary_info_sum_fields';
  id: Maybe<Scalars['Int']>;
  max_salary: Maybe<Scalars['numeric']>;
  min_salary: Maybe<Scalars['numeric']>;
};

/** update columns of table "salary_info" */
export enum Salary_Info_Update_Column {
  /** column name */
  Country = 'country',
  /** column name */
  Id = 'id',
  /** column name */
  MaxSalary = 'max_salary',
  /** column name */
  MinSalary = 'min_salary',
  /** column name */
  Region = 'region',
  /** column name */
  Role = 'role',
  /** column name */
  YearsOfExperience = 'years_of_experience',
}

export type Salary_Info_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Salary_Info_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salary_Info_Set_Input>;
  /** filter the rows which have to be updated */
  where: Salary_Info_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Salary_Info_Var_Pop_Fields = {
  __typename?: 'salary_info_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Salary_Info_Var_Samp_Fields = {
  __typename?: 'salary_info_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Salary_Info_Variance_Fields = {
  __typename?: 'salary_info_variance_fields';
  id: Maybe<Scalars['Float']>;
  max_salary: Maybe<Scalars['Float']>;
  min_salary: Maybe<Scalars['Float']>;
};

/** Years of experience range choices as used in salary calculator. */
export type Salary_Years_Experience_Range_Choices = {
  __typename?: 'salary_years_experience_range_choices';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "salary_years_experience_range_choices" */
export type Salary_Years_Experience_Range_Choices_Aggregate = {
  __typename?: 'salary_years_experience_range_choices_aggregate';
  aggregate: Maybe<Salary_Years_Experience_Range_Choices_Aggregate_Fields>;
  nodes: Array<Salary_Years_Experience_Range_Choices>;
};

/** aggregate fields of "salary_years_experience_range_choices" */
export type Salary_Years_Experience_Range_Choices_Aggregate_Fields = {
  __typename?: 'salary_years_experience_range_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Salary_Years_Experience_Range_Choices_Max_Fields>;
  min: Maybe<Salary_Years_Experience_Range_Choices_Min_Fields>;
};

/** aggregate fields of "salary_years_experience_range_choices" */
export type Salary_Years_Experience_Range_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "salary_years_experience_range_choices". All fields are combined with a logical 'AND'. */
export type Salary_Years_Experience_Range_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Bool_Exp>>;
  _not?: InputMaybe<Salary_Years_Experience_Range_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "salary_years_experience_range_choices" */
export enum Salary_Years_Experience_Range_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  SalaryYearsExperienceRangeChoicesPkey = 'salary_years_experience_range_choices_pkey',
}

export enum Salary_Years_Experience_Range_Choices_Enum {
  /** 8-12 (Senior) */
  EightTwelve = 'EIGHT_TWELVE',
  /** 5-8 (Mid) */
  FiveEight = 'FIVE_EIGHT',
  /** 2-5 (Junior) */
  TwoFive = 'TWO_FIVE',
}

/** Boolean expression to compare columns of type "salary_years_experience_range_choices_enum". All fields are combined with logical 'AND'. */
export type Salary_Years_Experience_Range_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Salary_Years_Experience_Range_Choices_Enum>;
  _in?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Salary_Years_Experience_Range_Choices_Enum>;
  _nin?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Enum>>;
};

/** input type for inserting data into table "salary_years_experience_range_choices" */
export type Salary_Years_Experience_Range_Choices_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Salary_Years_Experience_Range_Choices_Max_Fields = {
  __typename?: 'salary_years_experience_range_choices_max_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Salary_Years_Experience_Range_Choices_Min_Fields = {
  __typename?: 'salary_years_experience_range_choices_min_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "salary_years_experience_range_choices" */
export type Salary_Years_Experience_Range_Choices_Mutation_Response = {
  __typename?: 'salary_years_experience_range_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salary_Years_Experience_Range_Choices>;
};

/** on_conflict condition type for table "salary_years_experience_range_choices" */
export type Salary_Years_Experience_Range_Choices_On_Conflict = {
  constraint: Salary_Years_Experience_Range_Choices_Constraint;
  update_columns?: Array<Salary_Years_Experience_Range_Choices_Update_Column>;
  where?: InputMaybe<Salary_Years_Experience_Range_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "salary_years_experience_range_choices". */
export type Salary_Years_Experience_Range_Choices_Order_By = {
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salary_years_experience_range_choices */
export type Salary_Years_Experience_Range_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "salary_years_experience_range_choices" */
export enum Salary_Years_Experience_Range_Choices_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "salary_years_experience_range_choices" */
export type Salary_Years_Experience_Range_Choices_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "salary_years_experience_range_choices" */
export type Salary_Years_Experience_Range_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Salary_Years_Experience_Range_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salary_Years_Experience_Range_Choices_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "salary_years_experience_range_choices" */
export enum Salary_Years_Experience_Range_Choices_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

export type Salary_Years_Experience_Range_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salary_Years_Experience_Range_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Salary_Years_Experience_Range_Choices_Bool_Exp;
};

/** columns and relationships of "salesforce_customer" */
export type Salesforce_Customer = {
  __typename?: 'salesforce_customer';
  about: Maybe<Scalars['String']>;
  consent_given: Scalars['Boolean'];
  consent_given_raw: Maybe<Scalars['String']>;
  consent_given_when: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  industry: Maybe<Scalars['String']>;
  locations_raw: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object relationship */
  organization: Maybe<Organization>;
  salesforce_customer_id: Maybe<Scalars['String']>;
  /** An array relationship */
  salesforce_customer_locations: Array<Salesforce_Customer_Location>;
  /** An aggregate relationship */
  salesforce_customer_locations_aggregate: Salesforce_Customer_Location_Aggregate;
  salesforce_id: Scalars['String'];
  status: Maybe<Scalars['String']>;
  website: Maybe<Scalars['String']>;
};

/** columns and relationships of "salesforce_customer" */
export type Salesforce_CustomerSalesforce_Customer_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Customer_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Customer_Location_Order_By>>;
  where?: InputMaybe<Salesforce_Customer_Location_Bool_Exp>;
};

/** columns and relationships of "salesforce_customer" */
export type Salesforce_CustomerSalesforce_Customer_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Customer_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Customer_Location_Order_By>>;
  where?: InputMaybe<Salesforce_Customer_Location_Bool_Exp>;
};

/** aggregated selection of "salesforce_customer" */
export type Salesforce_Customer_Aggregate = {
  __typename?: 'salesforce_customer_aggregate';
  aggregate: Maybe<Salesforce_Customer_Aggregate_Fields>;
  nodes: Array<Salesforce_Customer>;
};

/** aggregate fields of "salesforce_customer" */
export type Salesforce_Customer_Aggregate_Fields = {
  __typename?: 'salesforce_customer_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Salesforce_Customer_Max_Fields>;
  min: Maybe<Salesforce_Customer_Min_Fields>;
};

/** aggregate fields of "salesforce_customer" */
export type Salesforce_Customer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salesforce_Customer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "salesforce_customer". All fields are combined with a logical 'AND'. */
export type Salesforce_Customer_Bool_Exp = {
  _and?: InputMaybe<Array<Salesforce_Customer_Bool_Exp>>;
  _not?: InputMaybe<Salesforce_Customer_Bool_Exp>;
  _or?: InputMaybe<Array<Salesforce_Customer_Bool_Exp>>;
  about?: InputMaybe<String_Comparison_Exp>;
  consent_given?: InputMaybe<Boolean_Comparison_Exp>;
  consent_given_raw?: InputMaybe<String_Comparison_Exp>;
  consent_given_when?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  industry?: InputMaybe<String_Comparison_Exp>;
  locations_raw?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organization_Bool_Exp>;
  salesforce_customer_id?: InputMaybe<String_Comparison_Exp>;
  salesforce_customer_locations?: InputMaybe<Salesforce_Customer_Location_Bool_Exp>;
  salesforce_customer_locations_aggregate?: InputMaybe<Salesforce_Customer_Location_Aggregate_Bool_Exp>;
  salesforce_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  website?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "salesforce_customer" */
export enum Salesforce_Customer_Constraint {
  /** unique or primary key constraint on columns "salesforce_id" */
  SalesforceCustomerPkey = 'salesforce_customer_pkey',
  /** unique or primary key constraint on columns "salesforce_customer_id" */
  SalesforceCustomerSalesforceCustomerIdKey = 'salesforce_customer_salesforce_customer_id_key',
}

/** input type for inserting data into table "salesforce_customer" */
export type Salesforce_Customer_Insert_Input = {
  about?: InputMaybe<Scalars['String']>;
  consent_given?: InputMaybe<Scalars['Boolean']>;
  consent_given_raw?: InputMaybe<Scalars['String']>;
  consent_given_when?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  locations_raw?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  salesforce_customer_id?: InputMaybe<Scalars['String']>;
  salesforce_customer_locations?: InputMaybe<Salesforce_Customer_Location_Arr_Rel_Insert_Input>;
  salesforce_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "salesforce_customer_location" */
export type Salesforce_Customer_Location = {
  __typename?: 'salesforce_customer_location';
  /** An object relationship */
  salesforce_customer: Salesforce_Customer;
  salesforce_id: Scalars['String'];
  salesforce_location_id: Scalars['Int'];
};

/** aggregated selection of "salesforce_customer_location" */
export type Salesforce_Customer_Location_Aggregate = {
  __typename?: 'salesforce_customer_location_aggregate';
  aggregate: Maybe<Salesforce_Customer_Location_Aggregate_Fields>;
  nodes: Array<Salesforce_Customer_Location>;
};

export type Salesforce_Customer_Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<Salesforce_Customer_Location_Aggregate_Bool_Exp_Count>;
};

export type Salesforce_Customer_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Salesforce_Customer_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Salesforce_Customer_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "salesforce_customer_location" */
export type Salesforce_Customer_Location_Aggregate_Fields = {
  __typename?: 'salesforce_customer_location_aggregate_fields';
  avg: Maybe<Salesforce_Customer_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Salesforce_Customer_Location_Max_Fields>;
  min: Maybe<Salesforce_Customer_Location_Min_Fields>;
  stddev: Maybe<Salesforce_Customer_Location_Stddev_Fields>;
  stddev_pop: Maybe<Salesforce_Customer_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Salesforce_Customer_Location_Stddev_Samp_Fields>;
  sum: Maybe<Salesforce_Customer_Location_Sum_Fields>;
  var_pop: Maybe<Salesforce_Customer_Location_Var_Pop_Fields>;
  var_samp: Maybe<Salesforce_Customer_Location_Var_Samp_Fields>;
  variance: Maybe<Salesforce_Customer_Location_Variance_Fields>;
};

/** aggregate fields of "salesforce_customer_location" */
export type Salesforce_Customer_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salesforce_Customer_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Salesforce_Customer_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Salesforce_Customer_Location_Max_Order_By>;
  min?: InputMaybe<Salesforce_Customer_Location_Min_Order_By>;
  stddev?: InputMaybe<Salesforce_Customer_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Salesforce_Customer_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Salesforce_Customer_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Salesforce_Customer_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Salesforce_Customer_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Salesforce_Customer_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Salesforce_Customer_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Arr_Rel_Insert_Input = {
  data: Array<Salesforce_Customer_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Customer_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Salesforce_Customer_Location_Avg_Fields = {
  __typename?: 'salesforce_customer_location_avg_fields';
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Avg_Order_By = {
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "salesforce_customer_location". All fields are combined with a logical 'AND'. */
export type Salesforce_Customer_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Salesforce_Customer_Location_Bool_Exp>>;
  _not?: InputMaybe<Salesforce_Customer_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Salesforce_Customer_Location_Bool_Exp>>;
  salesforce_customer?: InputMaybe<Salesforce_Customer_Bool_Exp>;
  salesforce_id?: InputMaybe<String_Comparison_Exp>;
  salesforce_location_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "salesforce_customer_location" */
export enum Salesforce_Customer_Location_Constraint {
  /** unique or primary key constraint on columns "salesforce_location_id", "salesforce_id" */
  SalesforceCustomerLocationPkey = 'salesforce_customer_location_pkey',
}

/** input type for incrementing numeric columns in table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Inc_Input = {
  salesforce_location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Insert_Input = {
  salesforce_customer?: InputMaybe<Salesforce_Customer_Obj_Rel_Insert_Input>;
  salesforce_id?: InputMaybe<Scalars['String']>;
  salesforce_location_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Salesforce_Customer_Location_Max_Fields = {
  __typename?: 'salesforce_customer_location_max_fields';
  salesforce_id: Maybe<Scalars['String']>;
  salesforce_location_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Max_Order_By = {
  salesforce_id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Salesforce_Customer_Location_Min_Fields = {
  __typename?: 'salesforce_customer_location_min_fields';
  salesforce_id: Maybe<Scalars['String']>;
  salesforce_location_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Min_Order_By = {
  salesforce_id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Mutation_Response = {
  __typename?: 'salesforce_customer_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salesforce_Customer_Location>;
};

/** on_conflict condition type for table "salesforce_customer_location" */
export type Salesforce_Customer_Location_On_Conflict = {
  constraint: Salesforce_Customer_Location_Constraint;
  update_columns?: Array<Salesforce_Customer_Location_Update_Column>;
  where?: InputMaybe<Salesforce_Customer_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforce_customer_location". */
export type Salesforce_Customer_Location_Order_By = {
  salesforce_customer?: InputMaybe<Salesforce_Customer_Order_By>;
  salesforce_id?: InputMaybe<Order_By>;
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforce_customer_location */
export type Salesforce_Customer_Location_Pk_Columns_Input = {
  salesforce_id: Scalars['String'];
  salesforce_location_id: Scalars['Int'];
};

/** select columns of table "salesforce_customer_location" */
export enum Salesforce_Customer_Location_Select_Column {
  /** column name */
  SalesforceId = 'salesforce_id',
  /** column name */
  SalesforceLocationId = 'salesforce_location_id',
}

/** input type for updating data in table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Set_Input = {
  salesforce_id?: InputMaybe<Scalars['String']>;
  salesforce_location_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Salesforce_Customer_Location_Stddev_Fields = {
  __typename?: 'salesforce_customer_location_stddev_fields';
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Stddev_Order_By = {
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Salesforce_Customer_Location_Stddev_Pop_Fields = {
  __typename?: 'salesforce_customer_location_stddev_pop_fields';
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Stddev_Pop_Order_By = {
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Salesforce_Customer_Location_Stddev_Samp_Fields = {
  __typename?: 'salesforce_customer_location_stddev_samp_fields';
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Stddev_Samp_Order_By = {
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Salesforce_Customer_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salesforce_Customer_Location_Stream_Cursor_Value_Input = {
  salesforce_id?: InputMaybe<Scalars['String']>;
  salesforce_location_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Salesforce_Customer_Location_Sum_Fields = {
  __typename?: 'salesforce_customer_location_sum_fields';
  salesforce_location_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Sum_Order_By = {
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** update columns of table "salesforce_customer_location" */
export enum Salesforce_Customer_Location_Update_Column {
  /** column name */
  SalesforceId = 'salesforce_id',
  /** column name */
  SalesforceLocationId = 'salesforce_location_id',
}

export type Salesforce_Customer_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Salesforce_Customer_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Customer_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Salesforce_Customer_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Salesforce_Customer_Location_Var_Pop_Fields = {
  __typename?: 'salesforce_customer_location_var_pop_fields';
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Var_Pop_Order_By = {
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Salesforce_Customer_Location_Var_Samp_Fields = {
  __typename?: 'salesforce_customer_location_var_samp_fields';
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Var_Samp_Order_By = {
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Salesforce_Customer_Location_Variance_Fields = {
  __typename?: 'salesforce_customer_location_variance_fields';
  salesforce_location_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "salesforce_customer_location" */
export type Salesforce_Customer_Location_Variance_Order_By = {
  salesforce_location_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Salesforce_Customer_Max_Fields = {
  __typename?: 'salesforce_customer_max_fields';
  about: Maybe<Scalars['String']>;
  consent_given_raw: Maybe<Scalars['String']>;
  consent_given_when: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  industry: Maybe<Scalars['String']>;
  locations_raw: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  salesforce_customer_id: Maybe<Scalars['String']>;
  salesforce_id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  website: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Salesforce_Customer_Min_Fields = {
  __typename?: 'salesforce_customer_min_fields';
  about: Maybe<Scalars['String']>;
  consent_given_raw: Maybe<Scalars['String']>;
  consent_given_when: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  industry: Maybe<Scalars['String']>;
  locations_raw: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  salesforce_customer_id: Maybe<Scalars['String']>;
  salesforce_id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  website: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "salesforce_customer" */
export type Salesforce_Customer_Mutation_Response = {
  __typename?: 'salesforce_customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salesforce_Customer>;
};

/** input type for inserting object relation for remote table "salesforce_customer" */
export type Salesforce_Customer_Obj_Rel_Insert_Input = {
  data: Salesforce_Customer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Customer_On_Conflict>;
};

/** on_conflict condition type for table "salesforce_customer" */
export type Salesforce_Customer_On_Conflict = {
  constraint: Salesforce_Customer_Constraint;
  update_columns?: Array<Salesforce_Customer_Update_Column>;
  where?: InputMaybe<Salesforce_Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforce_customer". */
export type Salesforce_Customer_Order_By = {
  about?: InputMaybe<Order_By>;
  consent_given?: InputMaybe<Order_By>;
  consent_given_raw?: InputMaybe<Order_By>;
  consent_given_when?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  locations_raw?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organization_Order_By>;
  salesforce_customer_id?: InputMaybe<Order_By>;
  salesforce_customer_locations_aggregate?: InputMaybe<Salesforce_Customer_Location_Aggregate_Order_By>;
  salesforce_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforce_customer */
export type Salesforce_Customer_Pk_Columns_Input = {
  salesforce_id: Scalars['String'];
};

/** select columns of table "salesforce_customer" */
export enum Salesforce_Customer_Select_Column {
  /** column name */
  About = 'about',
  /** column name */
  ConsentGiven = 'consent_given',
  /** column name */
  ConsentGivenRaw = 'consent_given_raw',
  /** column name */
  ConsentGivenWhen = 'consent_given_when',
  /** column name */
  Description = 'description',
  /** column name */
  Industry = 'industry',
  /** column name */
  LocationsRaw = 'locations_raw',
  /** column name */
  Name = 'name',
  /** column name */
  SalesforceCustomerId = 'salesforce_customer_id',
  /** column name */
  SalesforceId = 'salesforce_id',
  /** column name */
  Status = 'status',
  /** column name */
  Website = 'website',
}

/** input type for updating data in table "salesforce_customer" */
export type Salesforce_Customer_Set_Input = {
  about?: InputMaybe<Scalars['String']>;
  consent_given?: InputMaybe<Scalars['Boolean']>;
  consent_given_raw?: InputMaybe<Scalars['String']>;
  consent_given_when?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  locations_raw?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  salesforce_customer_id?: InputMaybe<Scalars['String']>;
  salesforce_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "salesforce_customer" */
export type Salesforce_Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Salesforce_Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salesforce_Customer_Stream_Cursor_Value_Input = {
  about?: InputMaybe<Scalars['String']>;
  consent_given?: InputMaybe<Scalars['Boolean']>;
  consent_given_raw?: InputMaybe<Scalars['String']>;
  consent_given_when?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  locations_raw?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  salesforce_customer_id?: InputMaybe<Scalars['String']>;
  salesforce_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** update columns of table "salesforce_customer" */
export enum Salesforce_Customer_Update_Column {
  /** column name */
  About = 'about',
  /** column name */
  ConsentGiven = 'consent_given',
  /** column name */
  ConsentGivenRaw = 'consent_given_raw',
  /** column name */
  ConsentGivenWhen = 'consent_given_when',
  /** column name */
  Description = 'description',
  /** column name */
  Industry = 'industry',
  /** column name */
  LocationsRaw = 'locations_raw',
  /** column name */
  Name = 'name',
  /** column name */
  SalesforceCustomerId = 'salesforce_customer_id',
  /** column name */
  SalesforceId = 'salesforce_id',
  /** column name */
  Status = 'status',
  /** column name */
  Website = 'website',
}

export type Salesforce_Customer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Customer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Salesforce_Customer_Bool_Exp;
};

/** columns and relationships of "salesforce_location" */
export type Salesforce_Location = {
  __typename?: 'salesforce_location';
  id: Scalars['Int'];
  location: Scalars['String'];
};

/** aggregated selection of "salesforce_location" */
export type Salesforce_Location_Aggregate = {
  __typename?: 'salesforce_location_aggregate';
  aggregate: Maybe<Salesforce_Location_Aggregate_Fields>;
  nodes: Array<Salesforce_Location>;
};

/** aggregate fields of "salesforce_location" */
export type Salesforce_Location_Aggregate_Fields = {
  __typename?: 'salesforce_location_aggregate_fields';
  avg: Maybe<Salesforce_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Salesforce_Location_Max_Fields>;
  min: Maybe<Salesforce_Location_Min_Fields>;
  stddev: Maybe<Salesforce_Location_Stddev_Fields>;
  stddev_pop: Maybe<Salesforce_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Salesforce_Location_Stddev_Samp_Fields>;
  sum: Maybe<Salesforce_Location_Sum_Fields>;
  var_pop: Maybe<Salesforce_Location_Var_Pop_Fields>;
  var_samp: Maybe<Salesforce_Location_Var_Samp_Fields>;
  variance: Maybe<Salesforce_Location_Variance_Fields>;
};

/** aggregate fields of "salesforce_location" */
export type Salesforce_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salesforce_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Salesforce_Location_Avg_Fields = {
  __typename?: 'salesforce_location_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "salesforce_location". All fields are combined with a logical 'AND'. */
export type Salesforce_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Salesforce_Location_Bool_Exp>>;
  _not?: InputMaybe<Salesforce_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Salesforce_Location_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "salesforce_location" */
export enum Salesforce_Location_Constraint {
  /** unique or primary key constraint on columns "location" */
  SalesforceLocationLocationKey = 'salesforce_location_location_key',
  /** unique or primary key constraint on columns "id" */
  SalesforceLocationPkey = 'salesforce_location_pkey',
}

/** input type for incrementing numeric columns in table "salesforce_location" */
export type Salesforce_Location_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "salesforce_location" */
export type Salesforce_Location_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Salesforce_Location_Max_Fields = {
  __typename?: 'salesforce_location_max_fields';
  id: Maybe<Scalars['Int']>;
  location: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Salesforce_Location_Min_Fields = {
  __typename?: 'salesforce_location_min_fields';
  id: Maybe<Scalars['Int']>;
  location: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "salesforce_location" */
export type Salesforce_Location_Mutation_Response = {
  __typename?: 'salesforce_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salesforce_Location>;
};

/** on_conflict condition type for table "salesforce_location" */
export type Salesforce_Location_On_Conflict = {
  constraint: Salesforce_Location_Constraint;
  update_columns?: Array<Salesforce_Location_Update_Column>;
  where?: InputMaybe<Salesforce_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforce_location". */
export type Salesforce_Location_Order_By = {
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforce_location */
export type Salesforce_Location_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "salesforce_location" */
export enum Salesforce_Location_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
}

/** input type for updating data in table "salesforce_location" */
export type Salesforce_Location_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Salesforce_Location_Stddev_Fields = {
  __typename?: 'salesforce_location_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Salesforce_Location_Stddev_Pop_Fields = {
  __typename?: 'salesforce_location_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Salesforce_Location_Stddev_Samp_Fields = {
  __typename?: 'salesforce_location_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "salesforce_location" */
export type Salesforce_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Salesforce_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salesforce_Location_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Salesforce_Location_Sum_Fields = {
  __typename?: 'salesforce_location_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "salesforce_location" */
export enum Salesforce_Location_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
}

export type Salesforce_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Salesforce_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Salesforce_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Salesforce_Location_Var_Pop_Fields = {
  __typename?: 'salesforce_location_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Salesforce_Location_Var_Samp_Fields = {
  __typename?: 'salesforce_location_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Salesforce_Location_Variance_Fields = {
  __typename?: 'salesforce_location_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "saved_candidate" */
export type Saved_Candidate = {
  __typename?: 'saved_candidate';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  saved_by_user_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_info: User_Info;
};

/** aggregated selection of "saved_candidate" */
export type Saved_Candidate_Aggregate = {
  __typename?: 'saved_candidate_aggregate';
  aggregate: Maybe<Saved_Candidate_Aggregate_Fields>;
  nodes: Array<Saved_Candidate>;
};

export type Saved_Candidate_Aggregate_Bool_Exp = {
  count?: InputMaybe<Saved_Candidate_Aggregate_Bool_Exp_Count>;
};

export type Saved_Candidate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Saved_Candidate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Saved_Candidate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "saved_candidate" */
export type Saved_Candidate_Aggregate_Fields = {
  __typename?: 'saved_candidate_aggregate_fields';
  avg: Maybe<Saved_Candidate_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Saved_Candidate_Max_Fields>;
  min: Maybe<Saved_Candidate_Min_Fields>;
  stddev: Maybe<Saved_Candidate_Stddev_Fields>;
  stddev_pop: Maybe<Saved_Candidate_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Saved_Candidate_Stddev_Samp_Fields>;
  sum: Maybe<Saved_Candidate_Sum_Fields>;
  var_pop: Maybe<Saved_Candidate_Var_Pop_Fields>;
  var_samp: Maybe<Saved_Candidate_Var_Samp_Fields>;
  variance: Maybe<Saved_Candidate_Variance_Fields>;
};

/** aggregate fields of "saved_candidate" */
export type Saved_Candidate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Saved_Candidate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "saved_candidate" */
export type Saved_Candidate_Aggregate_Order_By = {
  avg?: InputMaybe<Saved_Candidate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Saved_Candidate_Max_Order_By>;
  min?: InputMaybe<Saved_Candidate_Min_Order_By>;
  stddev?: InputMaybe<Saved_Candidate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Saved_Candidate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Saved_Candidate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Saved_Candidate_Sum_Order_By>;
  var_pop?: InputMaybe<Saved_Candidate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Saved_Candidate_Var_Samp_Order_By>;
  variance?: InputMaybe<Saved_Candidate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "saved_candidate" */
export type Saved_Candidate_Arr_Rel_Insert_Input = {
  data: Array<Saved_Candidate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Saved_Candidate_On_Conflict>;
};

/** aggregate avg on columns */
export type Saved_Candidate_Avg_Fields = {
  __typename?: 'saved_candidate_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  saved_by_user_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "saved_candidate" */
export type Saved_Candidate_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  saved_by_user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "saved_candidate". All fields are combined with a logical 'AND'. */
export type Saved_Candidate_Bool_Exp = {
  _and?: InputMaybe<Array<Saved_Candidate_Bool_Exp>>;
  _not?: InputMaybe<Saved_Candidate_Bool_Exp>;
  _or?: InputMaybe<Array<Saved_Candidate_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  saved_by_user_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "saved_candidate" */
export enum Saved_Candidate_Constraint {
  /** unique or primary key constraint on columns "candidate_id", "saved_by_user_id" */
  SavedCandidatePkey = 'saved_candidate_pkey',
}

/** input type for incrementing numeric columns in table "saved_candidate" */
export type Saved_Candidate_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  saved_by_user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "saved_candidate" */
export type Saved_Candidate_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  saved_by_user_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Saved_Candidate_Max_Fields = {
  __typename?: 'saved_candidate_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  saved_by_user_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "saved_candidate" */
export type Saved_Candidate_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  saved_by_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Saved_Candidate_Min_Fields = {
  __typename?: 'saved_candidate_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  saved_by_user_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "saved_candidate" */
export type Saved_Candidate_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  saved_by_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "saved_candidate" */
export type Saved_Candidate_Mutation_Response = {
  __typename?: 'saved_candidate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Saved_Candidate>;
};

/** on_conflict condition type for table "saved_candidate" */
export type Saved_Candidate_On_Conflict = {
  constraint: Saved_Candidate_Constraint;
  update_columns?: Array<Saved_Candidate_Update_Column>;
  where?: InputMaybe<Saved_Candidate_Bool_Exp>;
};

/** Ordering options when selecting data from "saved_candidate". */
export type Saved_Candidate_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  saved_by_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: saved_candidate */
export type Saved_Candidate_Pk_Columns_Input = {
  candidate_id: Scalars['Int'];
  saved_by_user_id: Scalars['Int'];
};

/** select columns of table "saved_candidate" */
export enum Saved_Candidate_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SavedByUserId = 'saved_by_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "saved_candidate" */
export type Saved_Candidate_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  saved_by_user_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Saved_Candidate_Stddev_Fields = {
  __typename?: 'saved_candidate_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  saved_by_user_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "saved_candidate" */
export type Saved_Candidate_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  saved_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Saved_Candidate_Stddev_Pop_Fields = {
  __typename?: 'saved_candidate_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  saved_by_user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "saved_candidate" */
export type Saved_Candidate_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  saved_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Saved_Candidate_Stddev_Samp_Fields = {
  __typename?: 'saved_candidate_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  saved_by_user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "saved_candidate" */
export type Saved_Candidate_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  saved_by_user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "saved_candidate" */
export type Saved_Candidate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Saved_Candidate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Saved_Candidate_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  saved_by_user_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Saved_Candidate_Sum_Fields = {
  __typename?: 'saved_candidate_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  saved_by_user_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "saved_candidate" */
export type Saved_Candidate_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  saved_by_user_id?: InputMaybe<Order_By>;
};

/** update columns of table "saved_candidate" */
export enum Saved_Candidate_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SavedByUserId = 'saved_by_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Saved_Candidate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Saved_Candidate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Saved_Candidate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Saved_Candidate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Saved_Candidate_Var_Pop_Fields = {
  __typename?: 'saved_candidate_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  saved_by_user_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "saved_candidate" */
export type Saved_Candidate_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  saved_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Saved_Candidate_Var_Samp_Fields = {
  __typename?: 'saved_candidate_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  saved_by_user_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "saved_candidate" */
export type Saved_Candidate_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  saved_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Saved_Candidate_Variance_Fields = {
  __typename?: 'saved_candidate_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  saved_by_user_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "saved_candidate" */
export type Saved_Candidate_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  saved_by_user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "segment_sendgrid_prod.activity" */
export type Segment_Sendgrid_Prod_Activity = {
  __typename?: 'segment_sendgrid_prod_activity';
  asm_group_id: Maybe<Scalars['bigint']>;
  attempt: Maybe<Scalars['String']>;
  bounce_classification: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  event: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ip: Maybe<Scalars['String']>;
  mc_stats: Maybe<Scalars['String']>;
  reason: Maybe<Scalars['String']>;
  received_at: Maybe<Scalars['timestamptz']>;
  response: Maybe<Scalars['String']>;
  send_at: Maybe<Scalars['bigint']>;
  sendtest_id: Maybe<Scalars['String']>;
  sg_content_type: Maybe<Scalars['String']>;
  sg_event_id: Maybe<Scalars['String']>;
  sg_machine_open: Maybe<Scalars['Boolean']>;
  sg_message_id: Maybe<Scalars['String']>;
  sg_template_id: Maybe<Scalars['String']>;
  sg_template_name: Maybe<Scalars['String']>;
  smtp_id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  template_id: Maybe<Scalars['String']>;
  timestamp: Maybe<Scalars['bigint']>;
  tls: Maybe<Scalars['bigint']>;
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  url_offset_index: Maybe<Scalars['bigint']>;
  url_offset_type: Maybe<Scalars['String']>;
  useragent: Maybe<Scalars['String']>;
  uuid_ts: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "segment_sendgrid_prod.activity" */
export type Segment_Sendgrid_Prod_Activity_Aggregate = {
  __typename?: 'segment_sendgrid_prod_activity_aggregate';
  aggregate: Maybe<Segment_Sendgrid_Prod_Activity_Aggregate_Fields>;
  nodes: Array<Segment_Sendgrid_Prod_Activity>;
};

/** aggregate fields of "segment_sendgrid_prod.activity" */
export type Segment_Sendgrid_Prod_Activity_Aggregate_Fields = {
  __typename?: 'segment_sendgrid_prod_activity_aggregate_fields';
  avg: Maybe<Segment_Sendgrid_Prod_Activity_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Segment_Sendgrid_Prod_Activity_Max_Fields>;
  min: Maybe<Segment_Sendgrid_Prod_Activity_Min_Fields>;
  stddev: Maybe<Segment_Sendgrid_Prod_Activity_Stddev_Fields>;
  stddev_pop: Maybe<Segment_Sendgrid_Prod_Activity_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Segment_Sendgrid_Prod_Activity_Stddev_Samp_Fields>;
  sum: Maybe<Segment_Sendgrid_Prod_Activity_Sum_Fields>;
  var_pop: Maybe<Segment_Sendgrid_Prod_Activity_Var_Pop_Fields>;
  var_samp: Maybe<Segment_Sendgrid_Prod_Activity_Var_Samp_Fields>;
  variance: Maybe<Segment_Sendgrid_Prod_Activity_Variance_Fields>;
};

/** aggregate fields of "segment_sendgrid_prod.activity" */
export type Segment_Sendgrid_Prod_Activity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Segment_Sendgrid_Prod_Activity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Segment_Sendgrid_Prod_Activity_Avg_Fields = {
  __typename?: 'segment_sendgrid_prod_activity_avg_fields';
  asm_group_id: Maybe<Scalars['Float']>;
  send_at: Maybe<Scalars['Float']>;
  timestamp: Maybe<Scalars['Float']>;
  tls: Maybe<Scalars['Float']>;
  url_offset_index: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "segment_sendgrid_prod.activity". All fields are combined with a logical 'AND'. */
export type Segment_Sendgrid_Prod_Activity_Bool_Exp = {
  _and?: InputMaybe<Array<Segment_Sendgrid_Prod_Activity_Bool_Exp>>;
  _not?: InputMaybe<Segment_Sendgrid_Prod_Activity_Bool_Exp>;
  _or?: InputMaybe<Array<Segment_Sendgrid_Prod_Activity_Bool_Exp>>;
  asm_group_id?: InputMaybe<Bigint_Comparison_Exp>;
  attempt?: InputMaybe<String_Comparison_Exp>;
  bounce_classification?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  ip?: InputMaybe<String_Comparison_Exp>;
  mc_stats?: InputMaybe<String_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  received_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  response?: InputMaybe<String_Comparison_Exp>;
  send_at?: InputMaybe<Bigint_Comparison_Exp>;
  sendtest_id?: InputMaybe<String_Comparison_Exp>;
  sg_content_type?: InputMaybe<String_Comparison_Exp>;
  sg_event_id?: InputMaybe<String_Comparison_Exp>;
  sg_machine_open?: InputMaybe<Boolean_Comparison_Exp>;
  sg_message_id?: InputMaybe<String_Comparison_Exp>;
  sg_template_id?: InputMaybe<String_Comparison_Exp>;
  sg_template_name?: InputMaybe<String_Comparison_Exp>;
  smtp_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  template_id?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<Bigint_Comparison_Exp>;
  tls?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  url_offset_index?: InputMaybe<Bigint_Comparison_Exp>;
  url_offset_type?: InputMaybe<String_Comparison_Exp>;
  useragent?: InputMaybe<String_Comparison_Exp>;
  uuid_ts?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "segment_sendgrid_prod.activity" */
export enum Segment_Sendgrid_Prod_Activity_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActivityPkey = 'activity_pkey',
}

/** input type for incrementing numeric columns in table "segment_sendgrid_prod.activity" */
export type Segment_Sendgrid_Prod_Activity_Inc_Input = {
  asm_group_id?: InputMaybe<Scalars['bigint']>;
  send_at?: InputMaybe<Scalars['bigint']>;
  timestamp?: InputMaybe<Scalars['bigint']>;
  tls?: InputMaybe<Scalars['bigint']>;
  url_offset_index?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "segment_sendgrid_prod.activity" */
export type Segment_Sendgrid_Prod_Activity_Insert_Input = {
  asm_group_id?: InputMaybe<Scalars['bigint']>;
  attempt?: InputMaybe<Scalars['String']>;
  bounce_classification?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  mc_stats?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  received_at?: InputMaybe<Scalars['timestamptz']>;
  response?: InputMaybe<Scalars['String']>;
  send_at?: InputMaybe<Scalars['bigint']>;
  sendtest_id?: InputMaybe<Scalars['String']>;
  sg_content_type?: InputMaybe<Scalars['String']>;
  sg_event_id?: InputMaybe<Scalars['String']>;
  sg_machine_open?: InputMaybe<Scalars['Boolean']>;
  sg_message_id?: InputMaybe<Scalars['String']>;
  sg_template_id?: InputMaybe<Scalars['String']>;
  sg_template_name?: InputMaybe<Scalars['String']>;
  smtp_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  template_id?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['bigint']>;
  tls?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  url_offset_index?: InputMaybe<Scalars['bigint']>;
  url_offset_type?: InputMaybe<Scalars['String']>;
  useragent?: InputMaybe<Scalars['String']>;
  uuid_ts?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Segment_Sendgrid_Prod_Activity_Max_Fields = {
  __typename?: 'segment_sendgrid_prod_activity_max_fields';
  asm_group_id: Maybe<Scalars['bigint']>;
  attempt: Maybe<Scalars['String']>;
  bounce_classification: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  event: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  ip: Maybe<Scalars['String']>;
  mc_stats: Maybe<Scalars['String']>;
  reason: Maybe<Scalars['String']>;
  received_at: Maybe<Scalars['timestamptz']>;
  response: Maybe<Scalars['String']>;
  send_at: Maybe<Scalars['bigint']>;
  sendtest_id: Maybe<Scalars['String']>;
  sg_content_type: Maybe<Scalars['String']>;
  sg_event_id: Maybe<Scalars['String']>;
  sg_message_id: Maybe<Scalars['String']>;
  sg_template_id: Maybe<Scalars['String']>;
  sg_template_name: Maybe<Scalars['String']>;
  smtp_id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  template_id: Maybe<Scalars['String']>;
  timestamp: Maybe<Scalars['bigint']>;
  tls: Maybe<Scalars['bigint']>;
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  url_offset_index: Maybe<Scalars['bigint']>;
  url_offset_type: Maybe<Scalars['String']>;
  useragent: Maybe<Scalars['String']>;
  uuid_ts: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Segment_Sendgrid_Prod_Activity_Min_Fields = {
  __typename?: 'segment_sendgrid_prod_activity_min_fields';
  asm_group_id: Maybe<Scalars['bigint']>;
  attempt: Maybe<Scalars['String']>;
  bounce_classification: Maybe<Scalars['String']>;
  category: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  event: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  ip: Maybe<Scalars['String']>;
  mc_stats: Maybe<Scalars['String']>;
  reason: Maybe<Scalars['String']>;
  received_at: Maybe<Scalars['timestamptz']>;
  response: Maybe<Scalars['String']>;
  send_at: Maybe<Scalars['bigint']>;
  sendtest_id: Maybe<Scalars['String']>;
  sg_content_type: Maybe<Scalars['String']>;
  sg_event_id: Maybe<Scalars['String']>;
  sg_message_id: Maybe<Scalars['String']>;
  sg_template_id: Maybe<Scalars['String']>;
  sg_template_name: Maybe<Scalars['String']>;
  smtp_id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  template_id: Maybe<Scalars['String']>;
  timestamp: Maybe<Scalars['bigint']>;
  tls: Maybe<Scalars['bigint']>;
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  url_offset_index: Maybe<Scalars['bigint']>;
  url_offset_type: Maybe<Scalars['String']>;
  useragent: Maybe<Scalars['String']>;
  uuid_ts: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "segment_sendgrid_prod.activity" */
export type Segment_Sendgrid_Prod_Activity_Mutation_Response = {
  __typename?: 'segment_sendgrid_prod_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Segment_Sendgrid_Prod_Activity>;
};

/** on_conflict condition type for table "segment_sendgrid_prod.activity" */
export type Segment_Sendgrid_Prod_Activity_On_Conflict = {
  constraint: Segment_Sendgrid_Prod_Activity_Constraint;
  update_columns?: Array<Segment_Sendgrid_Prod_Activity_Update_Column>;
  where?: InputMaybe<Segment_Sendgrid_Prod_Activity_Bool_Exp>;
};

/** Ordering options when selecting data from "segment_sendgrid_prod.activity". */
export type Segment_Sendgrid_Prod_Activity_Order_By = {
  asm_group_id?: InputMaybe<Order_By>;
  attempt?: InputMaybe<Order_By>;
  bounce_classification?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  mc_stats?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  received_at?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  send_at?: InputMaybe<Order_By>;
  sendtest_id?: InputMaybe<Order_By>;
  sg_content_type?: InputMaybe<Order_By>;
  sg_event_id?: InputMaybe<Order_By>;
  sg_machine_open?: InputMaybe<Order_By>;
  sg_message_id?: InputMaybe<Order_By>;
  sg_template_id?: InputMaybe<Order_By>;
  sg_template_name?: InputMaybe<Order_By>;
  smtp_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  tls?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  url_offset_index?: InputMaybe<Order_By>;
  url_offset_type?: InputMaybe<Order_By>;
  useragent?: InputMaybe<Order_By>;
  uuid_ts?: InputMaybe<Order_By>;
};

/** primary key columns input for table: segment_sendgrid_prod.activity */
export type Segment_Sendgrid_Prod_Activity_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "segment_sendgrid_prod.activity" */
export enum Segment_Sendgrid_Prod_Activity_Select_Column {
  /** column name */
  AsmGroupId = 'asm_group_id',
  /** column name */
  Attempt = 'attempt',
  /** column name */
  BounceClassification = 'bounce_classification',
  /** column name */
  Category = 'category',
  /** column name */
  Email = 'email',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  McStats = 'mc_stats',
  /** column name */
  Reason = 'reason',
  /** column name */
  ReceivedAt = 'received_at',
  /** column name */
  Response = 'response',
  /** column name */
  SendAt = 'send_at',
  /** column name */
  SendtestId = 'sendtest_id',
  /** column name */
  SgContentType = 'sg_content_type',
  /** column name */
  SgEventId = 'sg_event_id',
  /** column name */
  SgMachineOpen = 'sg_machine_open',
  /** column name */
  SgMessageId = 'sg_message_id',
  /** column name */
  SgTemplateId = 'sg_template_id',
  /** column name */
  SgTemplateName = 'sg_template_name',
  /** column name */
  SmtpId = 'smtp_id',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Tls = 'tls',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url',
  /** column name */
  UrlOffsetIndex = 'url_offset_index',
  /** column name */
  UrlOffsetType = 'url_offset_type',
  /** column name */
  Useragent = 'useragent',
  /** column name */
  UuidTs = 'uuid_ts',
}

/** input type for updating data in table "segment_sendgrid_prod.activity" */
export type Segment_Sendgrid_Prod_Activity_Set_Input = {
  asm_group_id?: InputMaybe<Scalars['bigint']>;
  attempt?: InputMaybe<Scalars['String']>;
  bounce_classification?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  mc_stats?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  received_at?: InputMaybe<Scalars['timestamptz']>;
  response?: InputMaybe<Scalars['String']>;
  send_at?: InputMaybe<Scalars['bigint']>;
  sendtest_id?: InputMaybe<Scalars['String']>;
  sg_content_type?: InputMaybe<Scalars['String']>;
  sg_event_id?: InputMaybe<Scalars['String']>;
  sg_machine_open?: InputMaybe<Scalars['Boolean']>;
  sg_message_id?: InputMaybe<Scalars['String']>;
  sg_template_id?: InputMaybe<Scalars['String']>;
  sg_template_name?: InputMaybe<Scalars['String']>;
  smtp_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  template_id?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['bigint']>;
  tls?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  url_offset_index?: InputMaybe<Scalars['bigint']>;
  url_offset_type?: InputMaybe<Scalars['String']>;
  useragent?: InputMaybe<Scalars['String']>;
  uuid_ts?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Segment_Sendgrid_Prod_Activity_Stddev_Fields = {
  __typename?: 'segment_sendgrid_prod_activity_stddev_fields';
  asm_group_id: Maybe<Scalars['Float']>;
  send_at: Maybe<Scalars['Float']>;
  timestamp: Maybe<Scalars['Float']>;
  tls: Maybe<Scalars['Float']>;
  url_offset_index: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Segment_Sendgrid_Prod_Activity_Stddev_Pop_Fields = {
  __typename?: 'segment_sendgrid_prod_activity_stddev_pop_fields';
  asm_group_id: Maybe<Scalars['Float']>;
  send_at: Maybe<Scalars['Float']>;
  timestamp: Maybe<Scalars['Float']>;
  tls: Maybe<Scalars['Float']>;
  url_offset_index: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Segment_Sendgrid_Prod_Activity_Stddev_Samp_Fields = {
  __typename?: 'segment_sendgrid_prod_activity_stddev_samp_fields';
  asm_group_id: Maybe<Scalars['Float']>;
  send_at: Maybe<Scalars['Float']>;
  timestamp: Maybe<Scalars['Float']>;
  tls: Maybe<Scalars['Float']>;
  url_offset_index: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "segment_sendgrid_prod_activity" */
export type Segment_Sendgrid_Prod_Activity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Segment_Sendgrid_Prod_Activity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Segment_Sendgrid_Prod_Activity_Stream_Cursor_Value_Input = {
  asm_group_id?: InputMaybe<Scalars['bigint']>;
  attempt?: InputMaybe<Scalars['String']>;
  bounce_classification?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  mc_stats?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  received_at?: InputMaybe<Scalars['timestamptz']>;
  response?: InputMaybe<Scalars['String']>;
  send_at?: InputMaybe<Scalars['bigint']>;
  sendtest_id?: InputMaybe<Scalars['String']>;
  sg_content_type?: InputMaybe<Scalars['String']>;
  sg_event_id?: InputMaybe<Scalars['String']>;
  sg_machine_open?: InputMaybe<Scalars['Boolean']>;
  sg_message_id?: InputMaybe<Scalars['String']>;
  sg_template_id?: InputMaybe<Scalars['String']>;
  sg_template_name?: InputMaybe<Scalars['String']>;
  smtp_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  template_id?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['bigint']>;
  tls?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  url_offset_index?: InputMaybe<Scalars['bigint']>;
  url_offset_type?: InputMaybe<Scalars['String']>;
  useragent?: InputMaybe<Scalars['String']>;
  uuid_ts?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Segment_Sendgrid_Prod_Activity_Sum_Fields = {
  __typename?: 'segment_sendgrid_prod_activity_sum_fields';
  asm_group_id: Maybe<Scalars['bigint']>;
  send_at: Maybe<Scalars['bigint']>;
  timestamp: Maybe<Scalars['bigint']>;
  tls: Maybe<Scalars['bigint']>;
  url_offset_index: Maybe<Scalars['bigint']>;
};

/** update columns of table "segment_sendgrid_prod.activity" */
export enum Segment_Sendgrid_Prod_Activity_Update_Column {
  /** column name */
  AsmGroupId = 'asm_group_id',
  /** column name */
  Attempt = 'attempt',
  /** column name */
  BounceClassification = 'bounce_classification',
  /** column name */
  Category = 'category',
  /** column name */
  Email = 'email',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  McStats = 'mc_stats',
  /** column name */
  Reason = 'reason',
  /** column name */
  ReceivedAt = 'received_at',
  /** column name */
  Response = 'response',
  /** column name */
  SendAt = 'send_at',
  /** column name */
  SendtestId = 'sendtest_id',
  /** column name */
  SgContentType = 'sg_content_type',
  /** column name */
  SgEventId = 'sg_event_id',
  /** column name */
  SgMachineOpen = 'sg_machine_open',
  /** column name */
  SgMessageId = 'sg_message_id',
  /** column name */
  SgTemplateId = 'sg_template_id',
  /** column name */
  SgTemplateName = 'sg_template_name',
  /** column name */
  SmtpId = 'smtp_id',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Tls = 'tls',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url',
  /** column name */
  UrlOffsetIndex = 'url_offset_index',
  /** column name */
  UrlOffsetType = 'url_offset_type',
  /** column name */
  Useragent = 'useragent',
  /** column name */
  UuidTs = 'uuid_ts',
}

export type Segment_Sendgrid_Prod_Activity_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Segment_Sendgrid_Prod_Activity_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Segment_Sendgrid_Prod_Activity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Segment_Sendgrid_Prod_Activity_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Segment_Sendgrid_Prod_Activity_Var_Pop_Fields = {
  __typename?: 'segment_sendgrid_prod_activity_var_pop_fields';
  asm_group_id: Maybe<Scalars['Float']>;
  send_at: Maybe<Scalars['Float']>;
  timestamp: Maybe<Scalars['Float']>;
  tls: Maybe<Scalars['Float']>;
  url_offset_index: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Segment_Sendgrid_Prod_Activity_Var_Samp_Fields = {
  __typename?: 'segment_sendgrid_prod_activity_var_samp_fields';
  asm_group_id: Maybe<Scalars['Float']>;
  send_at: Maybe<Scalars['Float']>;
  timestamp: Maybe<Scalars['Float']>;
  tls: Maybe<Scalars['Float']>;
  url_offset_index: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Segment_Sendgrid_Prod_Activity_Variance_Fields = {
  __typename?: 'segment_sendgrid_prod_activity_variance_fields';
  asm_group_id: Maybe<Scalars['Float']>;
  send_at: Maybe<Scalars['Float']>;
  timestamp: Maybe<Scalars['Float']>;
  tls: Maybe<Scalars['Float']>;
  url_offset_index: Maybe<Scalars['Float']>;
};

/** columns and relationships of "shared_candidate" */
export type Shared_Candidate = {
  __typename?: 'shared_candidate';
  /** An object relationship */
  candidate: Candidate;
  candidate_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  shared_by_user_info: User_Info;
  shared_by_user_info_id: Scalars['Int'];
  /** An object relationship */
  shared_by_user_org: Organization;
  shared_by_user_org_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_info: User_Info;
  user_info_id: Scalars['Int'];
};

/** aggregated selection of "shared_candidate" */
export type Shared_Candidate_Aggregate = {
  __typename?: 'shared_candidate_aggregate';
  aggregate: Maybe<Shared_Candidate_Aggregate_Fields>;
  nodes: Array<Shared_Candidate>;
};

/** aggregate fields of "shared_candidate" */
export type Shared_Candidate_Aggregate_Fields = {
  __typename?: 'shared_candidate_aggregate_fields';
  avg: Maybe<Shared_Candidate_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Shared_Candidate_Max_Fields>;
  min: Maybe<Shared_Candidate_Min_Fields>;
  stddev: Maybe<Shared_Candidate_Stddev_Fields>;
  stddev_pop: Maybe<Shared_Candidate_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Shared_Candidate_Stddev_Samp_Fields>;
  sum: Maybe<Shared_Candidate_Sum_Fields>;
  var_pop: Maybe<Shared_Candidate_Var_Pop_Fields>;
  var_samp: Maybe<Shared_Candidate_Var_Samp_Fields>;
  variance: Maybe<Shared_Candidate_Variance_Fields>;
};

/** aggregate fields of "shared_candidate" */
export type Shared_Candidate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Shared_Candidate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Shared_Candidate_Avg_Fields = {
  __typename?: 'shared_candidate_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  shared_by_user_info_id: Maybe<Scalars['Float']>;
  shared_by_user_org_id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "shared_candidate". All fields are combined with a logical 'AND'. */
export type Shared_Candidate_Bool_Exp = {
  _and?: InputMaybe<Array<Shared_Candidate_Bool_Exp>>;
  _not?: InputMaybe<Shared_Candidate_Bool_Exp>;
  _or?: InputMaybe<Array<Shared_Candidate_Bool_Exp>>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  shared_by_user_info?: InputMaybe<User_Info_Bool_Exp>;
  shared_by_user_info_id?: InputMaybe<Int_Comparison_Exp>;
  shared_by_user_org?: InputMaybe<Organization_Bool_Exp>;
  shared_by_user_org_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
  user_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "shared_candidate" */
export enum Shared_Candidate_Constraint {
  /** unique or primary key constraint on columns "candidate_id", "user_info_id" */
  SharedCandidatePkey = 'shared_candidate_pkey',
}

/** input type for incrementing numeric columns in table "shared_candidate" */
export type Shared_Candidate_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  shared_by_user_info_id?: InputMaybe<Scalars['Int']>;
  shared_by_user_org_id?: InputMaybe<Scalars['Int']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shared_candidate" */
export type Shared_Candidate_Insert_Input = {
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  shared_by_user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  shared_by_user_info_id?: InputMaybe<Scalars['Int']>;
  shared_by_user_org?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  shared_by_user_org_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Shared_Candidate_Max_Fields = {
  __typename?: 'shared_candidate_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  shared_by_user_info_id: Maybe<Scalars['Int']>;
  shared_by_user_org_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Shared_Candidate_Min_Fields = {
  __typename?: 'shared_candidate_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  shared_by_user_info_id: Maybe<Scalars['Int']>;
  shared_by_user_org_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "shared_candidate" */
export type Shared_Candidate_Mutation_Response = {
  __typename?: 'shared_candidate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Shared_Candidate>;
};

/** on_conflict condition type for table "shared_candidate" */
export type Shared_Candidate_On_Conflict = {
  constraint: Shared_Candidate_Constraint;
  update_columns?: Array<Shared_Candidate_Update_Column>;
  where?: InputMaybe<Shared_Candidate_Bool_Exp>;
};

/** Ordering options when selecting data from "shared_candidate". */
export type Shared_Candidate_Order_By = {
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  shared_by_user_info?: InputMaybe<User_Info_Order_By>;
  shared_by_user_info_id?: InputMaybe<Order_By>;
  shared_by_user_org?: InputMaybe<Organization_Order_By>;
  shared_by_user_org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shared_candidate */
export type Shared_Candidate_Pk_Columns_Input = {
  candidate_id: Scalars['Int'];
  user_info_id: Scalars['Int'];
};

/** select columns of table "shared_candidate" */
export enum Shared_Candidate_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SharedByUserInfoId = 'shared_by_user_info_id',
  /** column name */
  SharedByUserOrgId = 'shared_by_user_org_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id',
}

/** input type for updating data in table "shared_candidate" */
export type Shared_Candidate_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  shared_by_user_info_id?: InputMaybe<Scalars['Int']>;
  shared_by_user_org_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Shared_Candidate_Stddev_Fields = {
  __typename?: 'shared_candidate_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  shared_by_user_info_id: Maybe<Scalars['Float']>;
  shared_by_user_org_id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Shared_Candidate_Stddev_Pop_Fields = {
  __typename?: 'shared_candidate_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  shared_by_user_info_id: Maybe<Scalars['Float']>;
  shared_by_user_org_id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Shared_Candidate_Stddev_Samp_Fields = {
  __typename?: 'shared_candidate_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  shared_by_user_info_id: Maybe<Scalars['Float']>;
  shared_by_user_org_id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "shared_candidate" */
export type Shared_Candidate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shared_Candidate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shared_Candidate_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  shared_by_user_info_id?: InputMaybe<Scalars['Int']>;
  shared_by_user_org_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Shared_Candidate_Sum_Fields = {
  __typename?: 'shared_candidate_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  shared_by_user_info_id: Maybe<Scalars['Int']>;
  shared_by_user_org_id: Maybe<Scalars['Int']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** update columns of table "shared_candidate" */
export enum Shared_Candidate_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SharedByUserInfoId = 'shared_by_user_info_id',
  /** column name */
  SharedByUserOrgId = 'shared_by_user_org_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id',
}

export type Shared_Candidate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Shared_Candidate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shared_Candidate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shared_Candidate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Shared_Candidate_Var_Pop_Fields = {
  __typename?: 'shared_candidate_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  shared_by_user_info_id: Maybe<Scalars['Float']>;
  shared_by_user_org_id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Shared_Candidate_Var_Samp_Fields = {
  __typename?: 'shared_candidate_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  shared_by_user_info_id: Maybe<Scalars['Float']>;
  shared_by_user_org_id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Shared_Candidate_Variance_Fields = {
  __typename?: 'shared_candidate_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  shared_by_user_info_id: Maybe<Scalars['Float']>;
  shared_by_user_org_id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** Generic skill table to record all names of skills */
export type Skill = {
  __typename?: 'skill';
  /** An array relationship */
  candidate_role_skills: Array<Candidate_Role_Skill>;
  /** An aggregate relationship */
  candidate_role_skills_aggregate: Candidate_Role_Skill_Aggregate;
  /** An array relationship */
  candidate_skills: Array<Candidate_Skill>;
  /** An aggregate relationship */
  candidate_skills_aggregate: Candidate_Skill_Aggregate;
  id: Scalars['Int'];
  name: Scalars['citext'];
  /** An array relationship */
  skill_features: Array<Skill_Feature>;
  /** An aggregate relationship */
  skill_features_aggregate: Skill_Feature_Aggregate;
  /** An array relationship */
  skill_icims_skills: Array<Skill_Icims_Skill>;
  /** An aggregate relationship */
  skill_icims_skills_aggregate: Skill_Icims_Skill_Aggregate;
  sovren_id: Maybe<Scalars['String']>;
  /** An array relationship */
  synonyms: Array<Skill_Synonym>;
  /** An aggregate relationship */
  synonyms_aggregate: Skill_Synonym_Aggregate;
};

/** Generic skill table to record all names of skills */
export type SkillCandidate_Role_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Role_Skill_Bool_Exp>;
};

/** Generic skill table to record all names of skills */
export type SkillCandidate_Role_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Role_Skill_Bool_Exp>;
};

/** Generic skill table to record all names of skills */
export type SkillCandidate_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Bool_Exp>;
};

/** Generic skill table to record all names of skills */
export type SkillCandidate_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Bool_Exp>;
};

/** Generic skill table to record all names of skills */
export type SkillSkill_FeaturesArgs = {
  distinct_on?: InputMaybe<Array<Skill_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Feature_Order_By>>;
  where?: InputMaybe<Skill_Feature_Bool_Exp>;
};

/** Generic skill table to record all names of skills */
export type SkillSkill_Features_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Feature_Order_By>>;
  where?: InputMaybe<Skill_Feature_Bool_Exp>;
};

/** Generic skill table to record all names of skills */
export type SkillSkill_Icims_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Skill_Icims_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Icims_Skill_Order_By>>;
  where?: InputMaybe<Skill_Icims_Skill_Bool_Exp>;
};

/** Generic skill table to record all names of skills */
export type SkillSkill_Icims_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Icims_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Icims_Skill_Order_By>>;
  where?: InputMaybe<Skill_Icims_Skill_Bool_Exp>;
};

/** Generic skill table to record all names of skills */
export type SkillSynonymsArgs = {
  distinct_on?: InputMaybe<Array<Skill_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Synonym_Order_By>>;
  where?: InputMaybe<Skill_Synonym_Bool_Exp>;
};

/** Generic skill table to record all names of skills */
export type SkillSynonyms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Synonym_Order_By>>;
  where?: InputMaybe<Skill_Synonym_Bool_Exp>;
};

/** aggregated selection of "skill" */
export type Skill_Aggregate = {
  __typename?: 'skill_aggregate';
  aggregate: Maybe<Skill_Aggregate_Fields>;
  nodes: Array<Skill>;
};

/** aggregate fields of "skill" */
export type Skill_Aggregate_Fields = {
  __typename?: 'skill_aggregate_fields';
  avg: Maybe<Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Skill_Max_Fields>;
  min: Maybe<Skill_Min_Fields>;
  stddev: Maybe<Skill_Stddev_Fields>;
  stddev_pop: Maybe<Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Skill_Stddev_Samp_Fields>;
  sum: Maybe<Skill_Sum_Fields>;
  var_pop: Maybe<Skill_Var_Pop_Fields>;
  var_samp: Maybe<Skill_Var_Samp_Fields>;
  variance: Maybe<Skill_Variance_Fields>;
};

/** aggregate fields of "skill" */
export type Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Skill_Avg_Fields = {
  __typename?: 'skill_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "skill". All fields are combined with a logical 'AND'. */
export type Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Skill_Bool_Exp>>;
  _not?: InputMaybe<Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Skill_Bool_Exp>>;
  candidate_role_skills?: InputMaybe<Candidate_Role_Skill_Bool_Exp>;
  candidate_role_skills_aggregate?: InputMaybe<Candidate_Role_Skill_Aggregate_Bool_Exp>;
  candidate_skills?: InputMaybe<Candidate_Skill_Bool_Exp>;
  candidate_skills_aggregate?: InputMaybe<Candidate_Skill_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<Citext_Comparison_Exp>;
  skill_features?: InputMaybe<Skill_Feature_Bool_Exp>;
  skill_features_aggregate?: InputMaybe<Skill_Feature_Aggregate_Bool_Exp>;
  skill_icims_skills?: InputMaybe<Skill_Icims_Skill_Bool_Exp>;
  skill_icims_skills_aggregate?: InputMaybe<Skill_Icims_Skill_Aggregate_Bool_Exp>;
  sovren_id?: InputMaybe<String_Comparison_Exp>;
  synonyms?: InputMaybe<Skill_Synonym_Bool_Exp>;
  synonyms_aggregate?: InputMaybe<Skill_Synonym_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "skill" */
export enum Skill_Constraint {
  /** unique or primary key constraint on columns "name" */
  SkillNameKey = 'skill_name_key',
  /** unique or primary key constraint on columns "id" */
  SkillPkey = 'skill_pkey',
  /** unique or primary key constraint on columns "sovren_id" */
  SkillSovrenIdKey = 'skill_sovren_id_key',
}

/** Which skills to show in which feature */
export type Skill_Feature = {
  __typename?: 'skill_feature';
  /** An object relationship */
  feature: Feature;
  feature_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
};

/** aggregated selection of "skill_feature" */
export type Skill_Feature_Aggregate = {
  __typename?: 'skill_feature_aggregate';
  aggregate: Maybe<Skill_Feature_Aggregate_Fields>;
  nodes: Array<Skill_Feature>;
};

export type Skill_Feature_Aggregate_Bool_Exp = {
  count?: InputMaybe<Skill_Feature_Aggregate_Bool_Exp_Count>;
};

export type Skill_Feature_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Skill_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Skill_Feature_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "skill_feature" */
export type Skill_Feature_Aggregate_Fields = {
  __typename?: 'skill_feature_aggregate_fields';
  avg: Maybe<Skill_Feature_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Skill_Feature_Max_Fields>;
  min: Maybe<Skill_Feature_Min_Fields>;
  stddev: Maybe<Skill_Feature_Stddev_Fields>;
  stddev_pop: Maybe<Skill_Feature_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Skill_Feature_Stddev_Samp_Fields>;
  sum: Maybe<Skill_Feature_Sum_Fields>;
  var_pop: Maybe<Skill_Feature_Var_Pop_Fields>;
  var_samp: Maybe<Skill_Feature_Var_Samp_Fields>;
  variance: Maybe<Skill_Feature_Variance_Fields>;
};

/** aggregate fields of "skill_feature" */
export type Skill_Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Skill_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "skill_feature" */
export type Skill_Feature_Aggregate_Order_By = {
  avg?: InputMaybe<Skill_Feature_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Skill_Feature_Max_Order_By>;
  min?: InputMaybe<Skill_Feature_Min_Order_By>;
  stddev?: InputMaybe<Skill_Feature_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Skill_Feature_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Skill_Feature_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Skill_Feature_Sum_Order_By>;
  var_pop?: InputMaybe<Skill_Feature_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Skill_Feature_Var_Samp_Order_By>;
  variance?: InputMaybe<Skill_Feature_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "skill_feature" */
export type Skill_Feature_Arr_Rel_Insert_Input = {
  data: Array<Skill_Feature_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Skill_Feature_On_Conflict>;
};

/** aggregate avg on columns */
export type Skill_Feature_Avg_Fields = {
  __typename?: 'skill_feature_avg_fields';
  feature_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "skill_feature" */
export type Skill_Feature_Avg_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "skill_feature". All fields are combined with a logical 'AND'. */
export type Skill_Feature_Bool_Exp = {
  _and?: InputMaybe<Array<Skill_Feature_Bool_Exp>>;
  _not?: InputMaybe<Skill_Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Skill_Feature_Bool_Exp>>;
  feature?: InputMaybe<Feature_Bool_Exp>;
  feature_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "skill_feature" */
export enum Skill_Feature_Constraint {
  /** unique or primary key constraint on columns "id" */
  SkillFeaturePkey = 'skill_feature_pkey',
}

/** input type for incrementing numeric columns in table "skill_feature" */
export type Skill_Feature_Inc_Input = {
  feature_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "skill_feature" */
export type Skill_Feature_Insert_Input = {
  feature?: InputMaybe<Feature_Obj_Rel_Insert_Input>;
  feature_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Skill_Feature_Max_Fields = {
  __typename?: 'skill_feature_max_fields';
  feature_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "skill_feature" */
export type Skill_Feature_Max_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Skill_Feature_Min_Fields = {
  __typename?: 'skill_feature_min_fields';
  feature_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "skill_feature" */
export type Skill_Feature_Min_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "skill_feature" */
export type Skill_Feature_Mutation_Response = {
  __typename?: 'skill_feature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Skill_Feature>;
};

/** on_conflict condition type for table "skill_feature" */
export type Skill_Feature_On_Conflict = {
  constraint: Skill_Feature_Constraint;
  update_columns?: Array<Skill_Feature_Update_Column>;
  where?: InputMaybe<Skill_Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "skill_feature". */
export type Skill_Feature_Order_By = {
  feature?: InputMaybe<Feature_Order_By>;
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: skill_feature */
export type Skill_Feature_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "skill_feature" */
export enum Skill_Feature_Select_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Id = 'id',
  /** column name */
  SkillId = 'skill_id',
}

/** input type for updating data in table "skill_feature" */
export type Skill_Feature_Set_Input = {
  feature_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Skill_Feature_Stddev_Fields = {
  __typename?: 'skill_feature_stddev_fields';
  feature_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "skill_feature" */
export type Skill_Feature_Stddev_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Skill_Feature_Stddev_Pop_Fields = {
  __typename?: 'skill_feature_stddev_pop_fields';
  feature_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "skill_feature" */
export type Skill_Feature_Stddev_Pop_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Skill_Feature_Stddev_Samp_Fields = {
  __typename?: 'skill_feature_stddev_samp_fields';
  feature_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "skill_feature" */
export type Skill_Feature_Stddev_Samp_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "skill_feature" */
export type Skill_Feature_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Skill_Feature_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Skill_Feature_Stream_Cursor_Value_Input = {
  feature_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Skill_Feature_Sum_Fields = {
  __typename?: 'skill_feature_sum_fields';
  feature_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "skill_feature" */
export type Skill_Feature_Sum_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "skill_feature" */
export enum Skill_Feature_Update_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Id = 'id',
  /** column name */
  SkillId = 'skill_id',
}

export type Skill_Feature_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Skill_Feature_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Skill_Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Skill_Feature_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Skill_Feature_Var_Pop_Fields = {
  __typename?: 'skill_feature_var_pop_fields';
  feature_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "skill_feature" */
export type Skill_Feature_Var_Pop_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Skill_Feature_Var_Samp_Fields = {
  __typename?: 'skill_feature_var_samp_fields';
  feature_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "skill_feature" */
export type Skill_Feature_Var_Samp_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Skill_Feature_Variance_Fields = {
  __typename?: 'skill_feature_variance_fields';
  feature_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "skill_feature" */
export type Skill_Feature_Variance_Order_By = {
  feature_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "skill_icims_skill" */
export type Skill_Icims_Skill = {
  __typename?: 'skill_icims_skill';
  icims_skill_id: Scalars['String'];
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
};

/** aggregated selection of "skill_icims_skill" */
export type Skill_Icims_Skill_Aggregate = {
  __typename?: 'skill_icims_skill_aggregate';
  aggregate: Maybe<Skill_Icims_Skill_Aggregate_Fields>;
  nodes: Array<Skill_Icims_Skill>;
};

export type Skill_Icims_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Skill_Icims_Skill_Aggregate_Bool_Exp_Count>;
};

export type Skill_Icims_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Skill_Icims_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Skill_Icims_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "skill_icims_skill" */
export type Skill_Icims_Skill_Aggregate_Fields = {
  __typename?: 'skill_icims_skill_aggregate_fields';
  avg: Maybe<Skill_Icims_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Skill_Icims_Skill_Max_Fields>;
  min: Maybe<Skill_Icims_Skill_Min_Fields>;
  stddev: Maybe<Skill_Icims_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Skill_Icims_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Skill_Icims_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Skill_Icims_Skill_Sum_Fields>;
  var_pop: Maybe<Skill_Icims_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Skill_Icims_Skill_Var_Samp_Fields>;
  variance: Maybe<Skill_Icims_Skill_Variance_Fields>;
};

/** aggregate fields of "skill_icims_skill" */
export type Skill_Icims_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Skill_Icims_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "skill_icims_skill" */
export type Skill_Icims_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Skill_Icims_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Skill_Icims_Skill_Max_Order_By>;
  min?: InputMaybe<Skill_Icims_Skill_Min_Order_By>;
  stddev?: InputMaybe<Skill_Icims_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Skill_Icims_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Skill_Icims_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Skill_Icims_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Skill_Icims_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Skill_Icims_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Skill_Icims_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "skill_icims_skill" */
export type Skill_Icims_Skill_Arr_Rel_Insert_Input = {
  data: Array<Skill_Icims_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Skill_Icims_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Skill_Icims_Skill_Avg_Fields = {
  __typename?: 'skill_icims_skill_avg_fields';
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "skill_icims_skill" */
export type Skill_Icims_Skill_Avg_Order_By = {
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "skill_icims_skill". All fields are combined with a logical 'AND'. */
export type Skill_Icims_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Skill_Icims_Skill_Bool_Exp>>;
  _not?: InputMaybe<Skill_Icims_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Skill_Icims_Skill_Bool_Exp>>;
  icims_skill_id?: InputMaybe<String_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "skill_icims_skill" */
export enum Skill_Icims_Skill_Constraint {
  /** unique or primary key constraint on columns "icims_skill_id", "skill_id" */
  SkillIcimsSkillPkey = 'skill_icims_skill_pkey',
}

/** input type for incrementing numeric columns in table "skill_icims_skill" */
export type Skill_Icims_Skill_Inc_Input = {
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "skill_icims_skill" */
export type Skill_Icims_Skill_Insert_Input = {
  icims_skill_id?: InputMaybe<Scalars['String']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Skill_Icims_Skill_Max_Fields = {
  __typename?: 'skill_icims_skill_max_fields';
  icims_skill_id: Maybe<Scalars['String']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "skill_icims_skill" */
export type Skill_Icims_Skill_Max_Order_By = {
  icims_skill_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Skill_Icims_Skill_Min_Fields = {
  __typename?: 'skill_icims_skill_min_fields';
  icims_skill_id: Maybe<Scalars['String']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "skill_icims_skill" */
export type Skill_Icims_Skill_Min_Order_By = {
  icims_skill_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "skill_icims_skill" */
export type Skill_Icims_Skill_Mutation_Response = {
  __typename?: 'skill_icims_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Skill_Icims_Skill>;
};

/** on_conflict condition type for table "skill_icims_skill" */
export type Skill_Icims_Skill_On_Conflict = {
  constraint: Skill_Icims_Skill_Constraint;
  update_columns?: Array<Skill_Icims_Skill_Update_Column>;
  where?: InputMaybe<Skill_Icims_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "skill_icims_skill". */
export type Skill_Icims_Skill_Order_By = {
  icims_skill_id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: skill_icims_skill */
export type Skill_Icims_Skill_Pk_Columns_Input = {
  icims_skill_id: Scalars['String'];
  skill_id: Scalars['Int'];
};

/** select columns of table "skill_icims_skill" */
export enum Skill_Icims_Skill_Select_Column {
  /** column name */
  IcimsSkillId = 'icims_skill_id',
  /** column name */
  SkillId = 'skill_id',
}

/** input type for updating data in table "skill_icims_skill" */
export type Skill_Icims_Skill_Set_Input = {
  icims_skill_id?: InputMaybe<Scalars['String']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Skill_Icims_Skill_Stddev_Fields = {
  __typename?: 'skill_icims_skill_stddev_fields';
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "skill_icims_skill" */
export type Skill_Icims_Skill_Stddev_Order_By = {
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Skill_Icims_Skill_Stddev_Pop_Fields = {
  __typename?: 'skill_icims_skill_stddev_pop_fields';
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "skill_icims_skill" */
export type Skill_Icims_Skill_Stddev_Pop_Order_By = {
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Skill_Icims_Skill_Stddev_Samp_Fields = {
  __typename?: 'skill_icims_skill_stddev_samp_fields';
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "skill_icims_skill" */
export type Skill_Icims_Skill_Stddev_Samp_Order_By = {
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "skill_icims_skill" */
export type Skill_Icims_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Skill_Icims_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Skill_Icims_Skill_Stream_Cursor_Value_Input = {
  icims_skill_id?: InputMaybe<Scalars['String']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Skill_Icims_Skill_Sum_Fields = {
  __typename?: 'skill_icims_skill_sum_fields';
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "skill_icims_skill" */
export type Skill_Icims_Skill_Sum_Order_By = {
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "skill_icims_skill" */
export enum Skill_Icims_Skill_Update_Column {
  /** column name */
  IcimsSkillId = 'icims_skill_id',
  /** column name */
  SkillId = 'skill_id',
}

export type Skill_Icims_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Skill_Icims_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Skill_Icims_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Skill_Icims_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Skill_Icims_Skill_Var_Pop_Fields = {
  __typename?: 'skill_icims_skill_var_pop_fields';
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "skill_icims_skill" */
export type Skill_Icims_Skill_Var_Pop_Order_By = {
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Skill_Icims_Skill_Var_Samp_Fields = {
  __typename?: 'skill_icims_skill_var_samp_fields';
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "skill_icims_skill" */
export type Skill_Icims_Skill_Var_Samp_Order_By = {
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Skill_Icims_Skill_Variance_Fields = {
  __typename?: 'skill_icims_skill_variance_fields';
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "skill_icims_skill" */
export type Skill_Icims_Skill_Variance_Order_By = {
  skill_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "skill" */
export type Skill_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "skill" */
export type Skill_Insert_Input = {
  candidate_role_skills?: InputMaybe<Candidate_Role_Skill_Arr_Rel_Insert_Input>;
  candidate_skills?: InputMaybe<Candidate_Skill_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['citext']>;
  skill_features?: InputMaybe<Skill_Feature_Arr_Rel_Insert_Input>;
  skill_icims_skills?: InputMaybe<Skill_Icims_Skill_Arr_Rel_Insert_Input>;
  sovren_id?: InputMaybe<Scalars['String']>;
  synonyms?: InputMaybe<Skill_Synonym_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Skill_Max_Fields = {
  __typename?: 'skill_max_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['citext']>;
  sovren_id: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Skill_Min_Fields = {
  __typename?: 'skill_min_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['citext']>;
  sovren_id: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "skill" */
export type Skill_Mutation_Response = {
  __typename?: 'skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Skill>;
};

/** input type for inserting object relation for remote table "skill" */
export type Skill_Obj_Rel_Insert_Input = {
  data: Skill_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Skill_On_Conflict>;
};

/** on_conflict condition type for table "skill" */
export type Skill_On_Conflict = {
  constraint: Skill_Constraint;
  update_columns?: Array<Skill_Update_Column>;
  where?: InputMaybe<Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "skill". */
export type Skill_Order_By = {
  candidate_role_skills_aggregate?: InputMaybe<Candidate_Role_Skill_Aggregate_Order_By>;
  candidate_skills_aggregate?: InputMaybe<Candidate_Skill_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  skill_features_aggregate?: InputMaybe<Skill_Feature_Aggregate_Order_By>;
  skill_icims_skills_aggregate?: InputMaybe<Skill_Icims_Skill_Aggregate_Order_By>;
  sovren_id?: InputMaybe<Order_By>;
  synonyms_aggregate?: InputMaybe<Skill_Synonym_Aggregate_Order_By>;
};

/** primary key columns input for table: skill */
export type Skill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "skill" */
export enum Skill_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SovrenId = 'sovren_id',
}

/** input type for updating data in table "skill" */
export type Skill_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['citext']>;
  sovren_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Skill_Stddev_Fields = {
  __typename?: 'skill_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Skill_Stddev_Pop_Fields = {
  __typename?: 'skill_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Skill_Stddev_Samp_Fields = {
  __typename?: 'skill_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "skill" */
export type Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Skill_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['citext']>;
  sovren_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Skill_Sum_Fields = {
  __typename?: 'skill_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** Table to normalize skills and its synonyms. */
export type Skill_Synonym = {
  __typename?: 'skill_synonym';
  id: Scalars['Int'];
  /** An object relationship */
  skill: Skill;
  skill_id: Scalars['Int'];
  synonym: Scalars['citext'];
};

/** aggregated selection of "skill_synonym" */
export type Skill_Synonym_Aggregate = {
  __typename?: 'skill_synonym_aggregate';
  aggregate: Maybe<Skill_Synonym_Aggregate_Fields>;
  nodes: Array<Skill_Synonym>;
};

export type Skill_Synonym_Aggregate_Bool_Exp = {
  count?: InputMaybe<Skill_Synonym_Aggregate_Bool_Exp_Count>;
};

export type Skill_Synonym_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Skill_Synonym_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Skill_Synonym_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "skill_synonym" */
export type Skill_Synonym_Aggregate_Fields = {
  __typename?: 'skill_synonym_aggregate_fields';
  avg: Maybe<Skill_Synonym_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Skill_Synonym_Max_Fields>;
  min: Maybe<Skill_Synonym_Min_Fields>;
  stddev: Maybe<Skill_Synonym_Stddev_Fields>;
  stddev_pop: Maybe<Skill_Synonym_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Skill_Synonym_Stddev_Samp_Fields>;
  sum: Maybe<Skill_Synonym_Sum_Fields>;
  var_pop: Maybe<Skill_Synonym_Var_Pop_Fields>;
  var_samp: Maybe<Skill_Synonym_Var_Samp_Fields>;
  variance: Maybe<Skill_Synonym_Variance_Fields>;
};

/** aggregate fields of "skill_synonym" */
export type Skill_Synonym_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Skill_Synonym_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "skill_synonym" */
export type Skill_Synonym_Aggregate_Order_By = {
  avg?: InputMaybe<Skill_Synonym_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Skill_Synonym_Max_Order_By>;
  min?: InputMaybe<Skill_Synonym_Min_Order_By>;
  stddev?: InputMaybe<Skill_Synonym_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Skill_Synonym_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Skill_Synonym_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Skill_Synonym_Sum_Order_By>;
  var_pop?: InputMaybe<Skill_Synonym_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Skill_Synonym_Var_Samp_Order_By>;
  variance?: InputMaybe<Skill_Synonym_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "skill_synonym" */
export type Skill_Synonym_Arr_Rel_Insert_Input = {
  data: Array<Skill_Synonym_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Skill_Synonym_On_Conflict>;
};

/** aggregate avg on columns */
export type Skill_Synonym_Avg_Fields = {
  __typename?: 'skill_synonym_avg_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "skill_synonym" */
export type Skill_Synonym_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "skill_synonym". All fields are combined with a logical 'AND'. */
export type Skill_Synonym_Bool_Exp = {
  _and?: InputMaybe<Array<Skill_Synonym_Bool_Exp>>;
  _not?: InputMaybe<Skill_Synonym_Bool_Exp>;
  _or?: InputMaybe<Array<Skill_Synonym_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
  synonym?: InputMaybe<Citext_Comparison_Exp>;
};

/** unique or primary key constraints on table "skill_synonym" */
export enum Skill_Synonym_Constraint {
  /** unique or primary key constraint on columns "id" */
  SkillSynonymPkey = 'skill_synonym_pkey',
}

/** input type for incrementing numeric columns in table "skill_synonym" */
export type Skill_Synonym_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "skill_synonym" */
export type Skill_Synonym_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
  synonym?: InputMaybe<Scalars['citext']>;
};

/** aggregate max on columns */
export type Skill_Synonym_Max_Fields = {
  __typename?: 'skill_synonym_max_fields';
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  synonym: Maybe<Scalars['citext']>;
};

/** order by max() on columns of table "skill_synonym" */
export type Skill_Synonym_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  synonym?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Skill_Synonym_Min_Fields = {
  __typename?: 'skill_synonym_min_fields';
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  synonym: Maybe<Scalars['citext']>;
};

/** order by min() on columns of table "skill_synonym" */
export type Skill_Synonym_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  synonym?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "skill_synonym" */
export type Skill_Synonym_Mutation_Response = {
  __typename?: 'skill_synonym_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Skill_Synonym>;
};

/** on_conflict condition type for table "skill_synonym" */
export type Skill_Synonym_On_Conflict = {
  constraint: Skill_Synonym_Constraint;
  update_columns?: Array<Skill_Synonym_Update_Column>;
  where?: InputMaybe<Skill_Synonym_Bool_Exp>;
};

/** Ordering options when selecting data from "skill_synonym". */
export type Skill_Synonym_Order_By = {
  id?: InputMaybe<Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
  synonym?: InputMaybe<Order_By>;
};

/** primary key columns input for table: skill_synonym */
export type Skill_Synonym_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "skill_synonym" */
export enum Skill_Synonym_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  Synonym = 'synonym',
}

/** input type for updating data in table "skill_synonym" */
export type Skill_Synonym_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  synonym?: InputMaybe<Scalars['citext']>;
};

/** aggregate stddev on columns */
export type Skill_Synonym_Stddev_Fields = {
  __typename?: 'skill_synonym_stddev_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "skill_synonym" */
export type Skill_Synonym_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Skill_Synonym_Stddev_Pop_Fields = {
  __typename?: 'skill_synonym_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "skill_synonym" */
export type Skill_Synonym_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Skill_Synonym_Stddev_Samp_Fields = {
  __typename?: 'skill_synonym_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "skill_synonym" */
export type Skill_Synonym_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "skill_synonym" */
export type Skill_Synonym_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Skill_Synonym_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Skill_Synonym_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  synonym?: InputMaybe<Scalars['citext']>;
};

/** aggregate sum on columns */
export type Skill_Synonym_Sum_Fields = {
  __typename?: 'skill_synonym_sum_fields';
  id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "skill_synonym" */
export type Skill_Synonym_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "skill_synonym" */
export enum Skill_Synonym_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  Synonym = 'synonym',
}

export type Skill_Synonym_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Skill_Synonym_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Skill_Synonym_Set_Input>;
  /** filter the rows which have to be updated */
  where: Skill_Synonym_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Skill_Synonym_Var_Pop_Fields = {
  __typename?: 'skill_synonym_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "skill_synonym" */
export type Skill_Synonym_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Skill_Synonym_Var_Samp_Fields = {
  __typename?: 'skill_synonym_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "skill_synonym" */
export type Skill_Synonym_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Skill_Synonym_Variance_Fields = {
  __typename?: 'skill_synonym_variance_fields';
  id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "skill_synonym" */
export type Skill_Synonym_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "skill" */
export enum Skill_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SovrenId = 'sovren_id',
}

export type Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Skill_Var_Pop_Fields = {
  __typename?: 'skill_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Skill_Var_Samp_Fields = {
  __typename?: 'skill_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Skill_Variance_Fields = {
  __typename?: 'skill_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "spike_matching.candidate" */
export type Spike_Matching_Candidate = {
  __typename?: 'spike_matching_candidate';
  /** An array relationship */
  candidate_skills: Array<Spike_Matching_Candidate_Skill>;
  /** An aggregate relationship */
  candidate_skills_aggregate: Spike_Matching_Candidate_Skill_Aggregate;
  id: Scalars['Int'];
  /** An object relationship */
  location: Maybe<Spike_Matching_Location>;
  location_id: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

/** columns and relationships of "spike_matching.candidate" */
export type Spike_Matching_CandidateCandidate_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Candidate_Skill_Bool_Exp>;
};

/** columns and relationships of "spike_matching.candidate" */
export type Spike_Matching_CandidateCandidate_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Candidate_Skill_Bool_Exp>;
};

/** aggregated selection of "spike_matching.candidate" */
export type Spike_Matching_Candidate_Aggregate = {
  __typename?: 'spike_matching_candidate_aggregate';
  aggregate: Maybe<Spike_Matching_Candidate_Aggregate_Fields>;
  nodes: Array<Spike_Matching_Candidate>;
};

/** aggregate fields of "spike_matching.candidate" */
export type Spike_Matching_Candidate_Aggregate_Fields = {
  __typename?: 'spike_matching_candidate_aggregate_fields';
  avg: Maybe<Spike_Matching_Candidate_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Spike_Matching_Candidate_Max_Fields>;
  min: Maybe<Spike_Matching_Candidate_Min_Fields>;
  stddev: Maybe<Spike_Matching_Candidate_Stddev_Fields>;
  stddev_pop: Maybe<Spike_Matching_Candidate_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Spike_Matching_Candidate_Stddev_Samp_Fields>;
  sum: Maybe<Spike_Matching_Candidate_Sum_Fields>;
  var_pop: Maybe<Spike_Matching_Candidate_Var_Pop_Fields>;
  var_samp: Maybe<Spike_Matching_Candidate_Var_Samp_Fields>;
  variance: Maybe<Spike_Matching_Candidate_Variance_Fields>;
};

/** aggregate fields of "spike_matching.candidate" */
export type Spike_Matching_Candidate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spike_Matching_Candidate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Spike_Matching_Candidate_Avg_Fields = {
  __typename?: 'spike_matching_candidate_avg_fields';
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spike_matching.candidate". All fields are combined with a logical 'AND'. */
export type Spike_Matching_Candidate_Bool_Exp = {
  _and?: InputMaybe<Array<Spike_Matching_Candidate_Bool_Exp>>;
  _not?: InputMaybe<Spike_Matching_Candidate_Bool_Exp>;
  _or?: InputMaybe<Array<Spike_Matching_Candidate_Bool_Exp>>;
  candidate_skills?: InputMaybe<Spike_Matching_Candidate_Skill_Bool_Exp>;
  candidate_skills_aggregate?: InputMaybe<Spike_Matching_Candidate_Skill_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Spike_Matching_Location_Bool_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "spike_matching.candidate" */
export enum Spike_Matching_Candidate_Constraint {
  /** unique or primary key constraint on columns "id" */
  CandidatePkey = 'candidate_pkey',
}

/** input type for incrementing numeric columns in table "spike_matching.candidate" */
export type Spike_Matching_Candidate_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "spike_matching.candidate" */
export type Spike_Matching_Candidate_Insert_Input = {
  candidate_skills?: InputMaybe<Spike_Matching_Candidate_Skill_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Spike_Matching_Location_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Spike_Matching_Candidate_Max_Fields = {
  __typename?: 'spike_matching_candidate_max_fields';
  id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Spike_Matching_Candidate_Min_Fields = {
  __typename?: 'spike_matching_candidate_min_fields';
  id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "spike_matching.candidate" */
export type Spike_Matching_Candidate_Mutation_Response = {
  __typename?: 'spike_matching_candidate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spike_Matching_Candidate>;
};

/** on_conflict condition type for table "spike_matching.candidate" */
export type Spike_Matching_Candidate_On_Conflict = {
  constraint: Spike_Matching_Candidate_Constraint;
  update_columns?: Array<Spike_Matching_Candidate_Update_Column>;
  where?: InputMaybe<Spike_Matching_Candidate_Bool_Exp>;
};

/** Ordering options when selecting data from "spike_matching.candidate". */
export type Spike_Matching_Candidate_Order_By = {
  candidate_skills_aggregate?: InputMaybe<Spike_Matching_Candidate_Skill_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Spike_Matching_Location_Order_By>;
  location_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spike_matching.candidate */
export type Spike_Matching_Candidate_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "spike_matching.candidate" */
export enum Spike_Matching_Candidate_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "spike_matching.candidate" */
export type Spike_Matching_Candidate_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill = {
  __typename?: 'spike_matching_candidate_skill';
  candidate_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

/** aggregated selection of "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Aggregate = {
  __typename?: 'spike_matching_candidate_skill_aggregate';
  aggregate: Maybe<Spike_Matching_Candidate_Skill_Aggregate_Fields>;
  nodes: Array<Spike_Matching_Candidate_Skill>;
};

export type Spike_Matching_Candidate_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Spike_Matching_Candidate_Skill_Aggregate_Bool_Exp_Count>;
};

export type Spike_Matching_Candidate_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Spike_Matching_Candidate_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Aggregate_Fields = {
  __typename?: 'spike_matching_candidate_skill_aggregate_fields';
  avg: Maybe<Spike_Matching_Candidate_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Spike_Matching_Candidate_Skill_Max_Fields>;
  min: Maybe<Spike_Matching_Candidate_Skill_Min_Fields>;
  stddev: Maybe<Spike_Matching_Candidate_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Spike_Matching_Candidate_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Spike_Matching_Candidate_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Spike_Matching_Candidate_Skill_Sum_Fields>;
  var_pop: Maybe<Spike_Matching_Candidate_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Spike_Matching_Candidate_Skill_Var_Samp_Fields>;
  variance: Maybe<Spike_Matching_Candidate_Skill_Variance_Fields>;
};

/** aggregate fields of "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Spike_Matching_Candidate_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Spike_Matching_Candidate_Skill_Max_Order_By>;
  min?: InputMaybe<Spike_Matching_Candidate_Skill_Min_Order_By>;
  stddev?: InputMaybe<Spike_Matching_Candidate_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Spike_Matching_Candidate_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Spike_Matching_Candidate_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Spike_Matching_Candidate_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Spike_Matching_Candidate_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Spike_Matching_Candidate_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Spike_Matching_Candidate_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Arr_Rel_Insert_Input = {
  data: Array<Spike_Matching_Candidate_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Spike_Matching_Candidate_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Spike_Matching_Candidate_Skill_Avg_Fields = {
  __typename?: 'spike_matching_candidate_skill_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "spike_matching.candidate_skill". All fields are combined with a logical 'AND'. */
export type Spike_Matching_Candidate_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Bool_Exp>>;
  _not?: InputMaybe<Spike_Matching_Candidate_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Bool_Exp>>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "spike_matching.candidate_skill" */
export enum Spike_Matching_Candidate_Skill_Constraint {
  /** unique or primary key constraint on columns "skill_id", "candidate_id" */
  CandidateSkillPkey = 'candidate_skill_pkey',
}

/** input type for incrementing numeric columns in table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Insert_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Spike_Matching_Candidate_Skill_Max_Fields = {
  __typename?: 'spike_matching_candidate_skill_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Spike_Matching_Candidate_Skill_Min_Fields = {
  __typename?: 'spike_matching_candidate_skill_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Mutation_Response = {
  __typename?: 'spike_matching_candidate_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spike_Matching_Candidate_Skill>;
};

/** on_conflict condition type for table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_On_Conflict = {
  constraint: Spike_Matching_Candidate_Skill_Constraint;
  update_columns?: Array<Spike_Matching_Candidate_Skill_Update_Column>;
  where?: InputMaybe<Spike_Matching_Candidate_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "spike_matching.candidate_skill". */
export type Spike_Matching_Candidate_Skill_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spike_matching.candidate_skill */
export type Spike_Matching_Candidate_Skill_Pk_Columns_Input = {
  candidate_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

/** select columns of table "spike_matching.candidate_skill" */
export enum Spike_Matching_Candidate_Skill_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  SkillId = 'skill_id',
}

/** input type for updating data in table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Spike_Matching_Candidate_Skill_Stddev_Fields = {
  __typename?: 'spike_matching_candidate_skill_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Spike_Matching_Candidate_Skill_Stddev_Pop_Fields = {
  __typename?: 'spike_matching_candidate_skill_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Spike_Matching_Candidate_Skill_Stddev_Samp_Fields = {
  __typename?: 'spike_matching_candidate_skill_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "spike_matching_candidate_skill" */
export type Spike_Matching_Candidate_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spike_Matching_Candidate_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spike_Matching_Candidate_Skill_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Spike_Matching_Candidate_Skill_Sum_Fields = {
  __typename?: 'spike_matching_candidate_skill_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "spike_matching.candidate_skill" */
export enum Spike_Matching_Candidate_Skill_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  SkillId = 'skill_id',
}

export type Spike_Matching_Candidate_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spike_Matching_Candidate_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spike_Matching_Candidate_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spike_Matching_Candidate_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spike_Matching_Candidate_Skill_Var_Pop_Fields = {
  __typename?: 'spike_matching_candidate_skill_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Spike_Matching_Candidate_Skill_Var_Samp_Fields = {
  __typename?: 'spike_matching_candidate_skill_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Spike_Matching_Candidate_Skill_Variance_Fields = {
  __typename?: 'spike_matching_candidate_skill_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "spike_matching.candidate_skill" */
export type Spike_Matching_Candidate_Skill_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Spike_Matching_Candidate_Stddev_Fields = {
  __typename?: 'spike_matching_candidate_stddev_fields';
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Spike_Matching_Candidate_Stddev_Pop_Fields = {
  __typename?: 'spike_matching_candidate_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Spike_Matching_Candidate_Stddev_Samp_Fields = {
  __typename?: 'spike_matching_candidate_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "spike_matching_candidate" */
export type Spike_Matching_Candidate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spike_Matching_Candidate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spike_Matching_Candidate_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Spike_Matching_Candidate_Sum_Fields = {
  __typename?: 'spike_matching_candidate_sum_fields';
  id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
};

/** update columns of table "spike_matching.candidate" */
export enum Spike_Matching_Candidate_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
}

export type Spike_Matching_Candidate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spike_Matching_Candidate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spike_Matching_Candidate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spike_Matching_Candidate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spike_Matching_Candidate_Var_Pop_Fields = {
  __typename?: 'spike_matching_candidate_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Spike_Matching_Candidate_Var_Samp_Fields = {
  __typename?: 'spike_matching_candidate_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Spike_Matching_Candidate_Variance_Fields = {
  __typename?: 'spike_matching_candidate_variance_fields';
  id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "spike_matching.job" */
export type Spike_Matching_Job = {
  __typename?: 'spike_matching_job';
  id: Scalars['Int'];
  /** An array relationship */
  job_skills: Array<Spike_Matching_Job_Skill>;
  /** An aggregate relationship */
  job_skills_aggregate: Spike_Matching_Job_Skill_Aggregate;
  /** An array relationship */
  locations: Array<Spike_Matching_Location_Job>;
  /** An aggregate relationship */
  locations_aggregate: Spike_Matching_Location_Job_Aggregate;
  name: Scalars['String'];
};

/** columns and relationships of "spike_matching.job" */
export type Spike_Matching_JobJob_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Job_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Job_Skill_Bool_Exp>;
};

/** columns and relationships of "spike_matching.job" */
export type Spike_Matching_JobJob_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Job_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Job_Skill_Bool_Exp>;
};

/** columns and relationships of "spike_matching.job" */
export type Spike_Matching_JobLocationsArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Location_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Location_Job_Order_By>>;
  where?: InputMaybe<Spike_Matching_Location_Job_Bool_Exp>;
};

/** columns and relationships of "spike_matching.job" */
export type Spike_Matching_JobLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Location_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Location_Job_Order_By>>;
  where?: InputMaybe<Spike_Matching_Location_Job_Bool_Exp>;
};

/** aggregated selection of "spike_matching.job" */
export type Spike_Matching_Job_Aggregate = {
  __typename?: 'spike_matching_job_aggregate';
  aggregate: Maybe<Spike_Matching_Job_Aggregate_Fields>;
  nodes: Array<Spike_Matching_Job>;
};

/** aggregate fields of "spike_matching.job" */
export type Spike_Matching_Job_Aggregate_Fields = {
  __typename?: 'spike_matching_job_aggregate_fields';
  avg: Maybe<Spike_Matching_Job_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Spike_Matching_Job_Max_Fields>;
  min: Maybe<Spike_Matching_Job_Min_Fields>;
  stddev: Maybe<Spike_Matching_Job_Stddev_Fields>;
  stddev_pop: Maybe<Spike_Matching_Job_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Spike_Matching_Job_Stddev_Samp_Fields>;
  sum: Maybe<Spike_Matching_Job_Sum_Fields>;
  var_pop: Maybe<Spike_Matching_Job_Var_Pop_Fields>;
  var_samp: Maybe<Spike_Matching_Job_Var_Samp_Fields>;
  variance: Maybe<Spike_Matching_Job_Variance_Fields>;
};

/** aggregate fields of "spike_matching.job" */
export type Spike_Matching_Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spike_Matching_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Spike_Matching_Job_Avg_Fields = {
  __typename?: 'spike_matching_job_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spike_matching.job". All fields are combined with a logical 'AND'. */
export type Spike_Matching_Job_Bool_Exp = {
  _and?: InputMaybe<Array<Spike_Matching_Job_Bool_Exp>>;
  _not?: InputMaybe<Spike_Matching_Job_Bool_Exp>;
  _or?: InputMaybe<Array<Spike_Matching_Job_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job_skills?: InputMaybe<Spike_Matching_Job_Skill_Bool_Exp>;
  job_skills_aggregate?: InputMaybe<Spike_Matching_Job_Skill_Aggregate_Bool_Exp>;
  locations?: InputMaybe<Spike_Matching_Location_Job_Bool_Exp>;
  locations_aggregate?: InputMaybe<Spike_Matching_Location_Job_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "spike_matching.job" */
export enum Spike_Matching_Job_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobPkey = 'job_pkey',
}

/** input type for incrementing numeric columns in table "spike_matching.job" */
export type Spike_Matching_Job_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "spike_matching.job" */
export type Spike_Matching_Job_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  job_skills?: InputMaybe<Spike_Matching_Job_Skill_Arr_Rel_Insert_Input>;
  locations?: InputMaybe<Spike_Matching_Location_Job_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Spike_Matching_Job_Max_Fields = {
  __typename?: 'spike_matching_job_max_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Spike_Matching_Job_Min_Fields = {
  __typename?: 'spike_matching_job_min_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "spike_matching.job" */
export type Spike_Matching_Job_Mutation_Response = {
  __typename?: 'spike_matching_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spike_Matching_Job>;
};

/** on_conflict condition type for table "spike_matching.job" */
export type Spike_Matching_Job_On_Conflict = {
  constraint: Spike_Matching_Job_Constraint;
  update_columns?: Array<Spike_Matching_Job_Update_Column>;
  where?: InputMaybe<Spike_Matching_Job_Bool_Exp>;
};

/** Ordering options when selecting data from "spike_matching.job". */
export type Spike_Matching_Job_Order_By = {
  id?: InputMaybe<Order_By>;
  job_skills_aggregate?: InputMaybe<Spike_Matching_Job_Skill_Aggregate_Order_By>;
  locations_aggregate?: InputMaybe<Spike_Matching_Location_Job_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spike_matching.job */
export type Spike_Matching_Job_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "spike_matching.job" */
export enum Spike_Matching_Job_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "spike_matching.job" */
export type Spike_Matching_Job_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill = {
  __typename?: 'spike_matching_job_skill';
  job_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

/** aggregated selection of "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Aggregate = {
  __typename?: 'spike_matching_job_skill_aggregate';
  aggregate: Maybe<Spike_Matching_Job_Skill_Aggregate_Fields>;
  nodes: Array<Spike_Matching_Job_Skill>;
};

export type Spike_Matching_Job_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Spike_Matching_Job_Skill_Aggregate_Bool_Exp_Count>;
};

export type Spike_Matching_Job_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Spike_Matching_Job_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Spike_Matching_Job_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Aggregate_Fields = {
  __typename?: 'spike_matching_job_skill_aggregate_fields';
  avg: Maybe<Spike_Matching_Job_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Spike_Matching_Job_Skill_Max_Fields>;
  min: Maybe<Spike_Matching_Job_Skill_Min_Fields>;
  stddev: Maybe<Spike_Matching_Job_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Spike_Matching_Job_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Spike_Matching_Job_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Spike_Matching_Job_Skill_Sum_Fields>;
  var_pop: Maybe<Spike_Matching_Job_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Spike_Matching_Job_Skill_Var_Samp_Fields>;
  variance: Maybe<Spike_Matching_Job_Skill_Variance_Fields>;
};

/** aggregate fields of "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spike_Matching_Job_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Spike_Matching_Job_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Spike_Matching_Job_Skill_Max_Order_By>;
  min?: InputMaybe<Spike_Matching_Job_Skill_Min_Order_By>;
  stddev?: InputMaybe<Spike_Matching_Job_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Spike_Matching_Job_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Spike_Matching_Job_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Spike_Matching_Job_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Spike_Matching_Job_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Spike_Matching_Job_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Spike_Matching_Job_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Arr_Rel_Insert_Input = {
  data: Array<Spike_Matching_Job_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Spike_Matching_Job_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Spike_Matching_Job_Skill_Avg_Fields = {
  __typename?: 'spike_matching_job_skill_avg_fields';
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Avg_Order_By = {
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "spike_matching.job_skill". All fields are combined with a logical 'AND'. */
export type Spike_Matching_Job_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Spike_Matching_Job_Skill_Bool_Exp>>;
  _not?: InputMaybe<Spike_Matching_Job_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Spike_Matching_Job_Skill_Bool_Exp>>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "spike_matching.job_skill" */
export enum Spike_Matching_Job_Skill_Constraint {
  /** unique or primary key constraint on columns "skill_id", "job_id" */
  JobSkillPkey = 'job_skill_pkey',
}

/** input type for incrementing numeric columns in table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Inc_Input = {
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Insert_Input = {
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Spike_Matching_Job_Skill_Max_Fields = {
  __typename?: 'spike_matching_job_skill_max_fields';
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Max_Order_By = {
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Spike_Matching_Job_Skill_Min_Fields = {
  __typename?: 'spike_matching_job_skill_min_fields';
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Min_Order_By = {
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Mutation_Response = {
  __typename?: 'spike_matching_job_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spike_Matching_Job_Skill>;
};

/** on_conflict condition type for table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_On_Conflict = {
  constraint: Spike_Matching_Job_Skill_Constraint;
  update_columns?: Array<Spike_Matching_Job_Skill_Update_Column>;
  where?: InputMaybe<Spike_Matching_Job_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "spike_matching.job_skill". */
export type Spike_Matching_Job_Skill_Order_By = {
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spike_matching.job_skill */
export type Spike_Matching_Job_Skill_Pk_Columns_Input = {
  job_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

/** select columns of table "spike_matching.job_skill" */
export enum Spike_Matching_Job_Skill_Select_Column {
  /** column name */
  JobId = 'job_id',
  /** column name */
  SkillId = 'skill_id',
}

/** input type for updating data in table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Set_Input = {
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Spike_Matching_Job_Skill_Stddev_Fields = {
  __typename?: 'spike_matching_job_skill_stddev_fields';
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Stddev_Order_By = {
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Spike_Matching_Job_Skill_Stddev_Pop_Fields = {
  __typename?: 'spike_matching_job_skill_stddev_pop_fields';
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Stddev_Pop_Order_By = {
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Spike_Matching_Job_Skill_Stddev_Samp_Fields = {
  __typename?: 'spike_matching_job_skill_stddev_samp_fields';
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Stddev_Samp_Order_By = {
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "spike_matching_job_skill" */
export type Spike_Matching_Job_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spike_Matching_Job_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spike_Matching_Job_Skill_Stream_Cursor_Value_Input = {
  job_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Spike_Matching_Job_Skill_Sum_Fields = {
  __typename?: 'spike_matching_job_skill_sum_fields';
  job_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Sum_Order_By = {
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** update columns of table "spike_matching.job_skill" */
export enum Spike_Matching_Job_Skill_Update_Column {
  /** column name */
  JobId = 'job_id',
  /** column name */
  SkillId = 'skill_id',
}

export type Spike_Matching_Job_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spike_Matching_Job_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spike_Matching_Job_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spike_Matching_Job_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spike_Matching_Job_Skill_Var_Pop_Fields = {
  __typename?: 'spike_matching_job_skill_var_pop_fields';
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Var_Pop_Order_By = {
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Spike_Matching_Job_Skill_Var_Samp_Fields = {
  __typename?: 'spike_matching_job_skill_var_samp_fields';
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Var_Samp_Order_By = {
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Spike_Matching_Job_Skill_Variance_Fields = {
  __typename?: 'spike_matching_job_skill_variance_fields';
  job_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "spike_matching.job_skill" */
export type Spike_Matching_Job_Skill_Variance_Order_By = {
  job_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Spike_Matching_Job_Stddev_Fields = {
  __typename?: 'spike_matching_job_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Spike_Matching_Job_Stddev_Pop_Fields = {
  __typename?: 'spike_matching_job_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Spike_Matching_Job_Stddev_Samp_Fields = {
  __typename?: 'spike_matching_job_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "spike_matching_job" */
export type Spike_Matching_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spike_Matching_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spike_Matching_Job_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Spike_Matching_Job_Sum_Fields = {
  __typename?: 'spike_matching_job_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "spike_matching.job" */
export enum Spike_Matching_Job_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

export type Spike_Matching_Job_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spike_Matching_Job_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spike_Matching_Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spike_Matching_Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spike_Matching_Job_Var_Pop_Fields = {
  __typename?: 'spike_matching_job_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Spike_Matching_Job_Var_Samp_Fields = {
  __typename?: 'spike_matching_job_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Spike_Matching_Job_Variance_Fields = {
  __typename?: 'spike_matching_job_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "spike_matching.location" */
export type Spike_Matching_Location = {
  __typename?: 'spike_matching_location';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "spike_matching.location" */
export type Spike_Matching_Location_Aggregate = {
  __typename?: 'spike_matching_location_aggregate';
  aggregate: Maybe<Spike_Matching_Location_Aggregate_Fields>;
  nodes: Array<Spike_Matching_Location>;
};

/** aggregate fields of "spike_matching.location" */
export type Spike_Matching_Location_Aggregate_Fields = {
  __typename?: 'spike_matching_location_aggregate_fields';
  avg: Maybe<Spike_Matching_Location_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Spike_Matching_Location_Max_Fields>;
  min: Maybe<Spike_Matching_Location_Min_Fields>;
  stddev: Maybe<Spike_Matching_Location_Stddev_Fields>;
  stddev_pop: Maybe<Spike_Matching_Location_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Spike_Matching_Location_Stddev_Samp_Fields>;
  sum: Maybe<Spike_Matching_Location_Sum_Fields>;
  var_pop: Maybe<Spike_Matching_Location_Var_Pop_Fields>;
  var_samp: Maybe<Spike_Matching_Location_Var_Samp_Fields>;
  variance: Maybe<Spike_Matching_Location_Variance_Fields>;
};

/** aggregate fields of "spike_matching.location" */
export type Spike_Matching_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spike_Matching_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Spike_Matching_Location_Avg_Fields = {
  __typename?: 'spike_matching_location_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spike_matching.location". All fields are combined with a logical 'AND'. */
export type Spike_Matching_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Spike_Matching_Location_Bool_Exp>>;
  _not?: InputMaybe<Spike_Matching_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Spike_Matching_Location_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "spike_matching.location" */
export enum Spike_Matching_Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationPkey = 'location_pkey',
}

/** input type for incrementing numeric columns in table "spike_matching.location" */
export type Spike_Matching_Location_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "spike_matching.location" */
export type Spike_Matching_Location_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "spike_matching.location_job" */
export type Spike_Matching_Location_Job = {
  __typename?: 'spike_matching_location_job';
  job_id: Scalars['Int'];
  location_id: Scalars['Int'];
};

/** aggregated selection of "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Aggregate = {
  __typename?: 'spike_matching_location_job_aggregate';
  aggregate: Maybe<Spike_Matching_Location_Job_Aggregate_Fields>;
  nodes: Array<Spike_Matching_Location_Job>;
};

export type Spike_Matching_Location_Job_Aggregate_Bool_Exp = {
  count?: InputMaybe<Spike_Matching_Location_Job_Aggregate_Bool_Exp_Count>;
};

export type Spike_Matching_Location_Job_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Spike_Matching_Location_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Spike_Matching_Location_Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Aggregate_Fields = {
  __typename?: 'spike_matching_location_job_aggregate_fields';
  avg: Maybe<Spike_Matching_Location_Job_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Spike_Matching_Location_Job_Max_Fields>;
  min: Maybe<Spike_Matching_Location_Job_Min_Fields>;
  stddev: Maybe<Spike_Matching_Location_Job_Stddev_Fields>;
  stddev_pop: Maybe<Spike_Matching_Location_Job_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Spike_Matching_Location_Job_Stddev_Samp_Fields>;
  sum: Maybe<Spike_Matching_Location_Job_Sum_Fields>;
  var_pop: Maybe<Spike_Matching_Location_Job_Var_Pop_Fields>;
  var_samp: Maybe<Spike_Matching_Location_Job_Var_Samp_Fields>;
  variance: Maybe<Spike_Matching_Location_Job_Variance_Fields>;
};

/** aggregate fields of "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spike_Matching_Location_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Aggregate_Order_By = {
  avg?: InputMaybe<Spike_Matching_Location_Job_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Spike_Matching_Location_Job_Max_Order_By>;
  min?: InputMaybe<Spike_Matching_Location_Job_Min_Order_By>;
  stddev?: InputMaybe<Spike_Matching_Location_Job_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Spike_Matching_Location_Job_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Spike_Matching_Location_Job_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Spike_Matching_Location_Job_Sum_Order_By>;
  var_pop?: InputMaybe<Spike_Matching_Location_Job_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Spike_Matching_Location_Job_Var_Samp_Order_By>;
  variance?: InputMaybe<Spike_Matching_Location_Job_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Arr_Rel_Insert_Input = {
  data: Array<Spike_Matching_Location_Job_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Spike_Matching_Location_Job_On_Conflict>;
};

/** aggregate avg on columns */
export type Spike_Matching_Location_Job_Avg_Fields = {
  __typename?: 'spike_matching_location_job_avg_fields';
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Avg_Order_By = {
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "spike_matching.location_job". All fields are combined with a logical 'AND'. */
export type Spike_Matching_Location_Job_Bool_Exp = {
  _and?: InputMaybe<Array<Spike_Matching_Location_Job_Bool_Exp>>;
  _not?: InputMaybe<Spike_Matching_Location_Job_Bool_Exp>;
  _or?: InputMaybe<Array<Spike_Matching_Location_Job_Bool_Exp>>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "spike_matching.location_job" */
export enum Spike_Matching_Location_Job_Constraint {
  /** unique or primary key constraint on columns "location_id", "job_id" */
  LocationJobPkey = 'location_job_pkey',
}

/** input type for incrementing numeric columns in table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Inc_Input = {
  job_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Insert_Input = {
  job_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Spike_Matching_Location_Job_Max_Fields = {
  __typename?: 'spike_matching_location_job_max_fields';
  job_id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Max_Order_By = {
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Spike_Matching_Location_Job_Min_Fields = {
  __typename?: 'spike_matching_location_job_min_fields';
  job_id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Min_Order_By = {
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Mutation_Response = {
  __typename?: 'spike_matching_location_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spike_Matching_Location_Job>;
};

/** on_conflict condition type for table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_On_Conflict = {
  constraint: Spike_Matching_Location_Job_Constraint;
  update_columns?: Array<Spike_Matching_Location_Job_Update_Column>;
  where?: InputMaybe<Spike_Matching_Location_Job_Bool_Exp>;
};

/** Ordering options when selecting data from "spike_matching.location_job". */
export type Spike_Matching_Location_Job_Order_By = {
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spike_matching.location_job */
export type Spike_Matching_Location_Job_Pk_Columns_Input = {
  job_id: Scalars['Int'];
  location_id: Scalars['Int'];
};

/** select columns of table "spike_matching.location_job" */
export enum Spike_Matching_Location_Job_Select_Column {
  /** column name */
  JobId = 'job_id',
  /** column name */
  LocationId = 'location_id',
}

/** input type for updating data in table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Set_Input = {
  job_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Spike_Matching_Location_Job_Stddev_Fields = {
  __typename?: 'spike_matching_location_job_stddev_fields';
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Stddev_Order_By = {
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Spike_Matching_Location_Job_Stddev_Pop_Fields = {
  __typename?: 'spike_matching_location_job_stddev_pop_fields';
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Stddev_Pop_Order_By = {
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Spike_Matching_Location_Job_Stddev_Samp_Fields = {
  __typename?: 'spike_matching_location_job_stddev_samp_fields';
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Stddev_Samp_Order_By = {
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "spike_matching_location_job" */
export type Spike_Matching_Location_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spike_Matching_Location_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spike_Matching_Location_Job_Stream_Cursor_Value_Input = {
  job_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Spike_Matching_Location_Job_Sum_Fields = {
  __typename?: 'spike_matching_location_job_sum_fields';
  job_id: Maybe<Scalars['Int']>;
  location_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Sum_Order_By = {
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** update columns of table "spike_matching.location_job" */
export enum Spike_Matching_Location_Job_Update_Column {
  /** column name */
  JobId = 'job_id',
  /** column name */
  LocationId = 'location_id',
}

export type Spike_Matching_Location_Job_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spike_Matching_Location_Job_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spike_Matching_Location_Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spike_Matching_Location_Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spike_Matching_Location_Job_Var_Pop_Fields = {
  __typename?: 'spike_matching_location_job_var_pop_fields';
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Var_Pop_Order_By = {
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Spike_Matching_Location_Job_Var_Samp_Fields = {
  __typename?: 'spike_matching_location_job_var_samp_fields';
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Var_Samp_Order_By = {
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Spike_Matching_Location_Job_Variance_Fields = {
  __typename?: 'spike_matching_location_job_variance_fields';
  job_id: Maybe<Scalars['Float']>;
  location_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "spike_matching.location_job" */
export type Spike_Matching_Location_Job_Variance_Order_By = {
  job_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Spike_Matching_Location_Max_Fields = {
  __typename?: 'spike_matching_location_max_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Spike_Matching_Location_Min_Fields = {
  __typename?: 'spike_matching_location_min_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "spike_matching.location" */
export type Spike_Matching_Location_Mutation_Response = {
  __typename?: 'spike_matching_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spike_Matching_Location>;
};

/** input type for inserting object relation for remote table "spike_matching.location" */
export type Spike_Matching_Location_Obj_Rel_Insert_Input = {
  data: Spike_Matching_Location_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Spike_Matching_Location_On_Conflict>;
};

/** on_conflict condition type for table "spike_matching.location" */
export type Spike_Matching_Location_On_Conflict = {
  constraint: Spike_Matching_Location_Constraint;
  update_columns?: Array<Spike_Matching_Location_Update_Column>;
  where?: InputMaybe<Spike_Matching_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "spike_matching.location". */
export type Spike_Matching_Location_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spike_matching.location */
export type Spike_Matching_Location_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "spike_matching.location" */
export enum Spike_Matching_Location_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "spike_matching.location" */
export type Spike_Matching_Location_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Spike_Matching_Location_Stddev_Fields = {
  __typename?: 'spike_matching_location_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Spike_Matching_Location_Stddev_Pop_Fields = {
  __typename?: 'spike_matching_location_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Spike_Matching_Location_Stddev_Samp_Fields = {
  __typename?: 'spike_matching_location_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "spike_matching_location" */
export type Spike_Matching_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spike_Matching_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spike_Matching_Location_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Spike_Matching_Location_Sum_Fields = {
  __typename?: 'spike_matching_location_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "spike_matching.location" */
export enum Spike_Matching_Location_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

export type Spike_Matching_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spike_Matching_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spike_Matching_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spike_Matching_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spike_Matching_Location_Var_Pop_Fields = {
  __typename?: 'spike_matching_location_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Spike_Matching_Location_Var_Samp_Fields = {
  __typename?: 'spike_matching_location_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Spike_Matching_Location_Variance_Fields = {
  __typename?: 'spike_matching_location_variance_fields';
  id: Maybe<Scalars['Float']>;
};

export type Spike_Matching_Match_Args = {
  location_weight?: InputMaybe<Scalars['json']>;
  skill_weight?: InputMaybe<Scalars['json']>;
};

/** This table is only used for its Hasura generated type, don't populate it. */
export type Spike_Matching_Match_Result = {
  __typename?: 'spike_matching_match_result';
  candidate_id: Scalars['Int'];
  id: Scalars['Int'];
  job_id: Scalars['Int'];
  /** An object relationship */
  location: Maybe<Spike_Matching_Location>;
  location_score: Scalars['float8'];
  matched_location_id: Maybe<Scalars['Int']>;
  matched_location_name: Maybe<Scalars['String']>;
  matched_skill_ids: Array<Scalars['Int']>;
  matched_skill_names: Array<Scalars['String']>;
  score: Scalars['float8'];
  skill_score: Scalars['float8'];
};

export type Spike_Matching_Match_Result_Aggregate = {
  __typename?: 'spike_matching_match_result_aggregate';
  aggregate: Maybe<Spike_Matching_Match_Result_Aggregate_Fields>;
  nodes: Array<Spike_Matching_Match_Result>;
};

/** aggregate fields of "spike_matching.match_result" */
export type Spike_Matching_Match_Result_Aggregate_Fields = {
  __typename?: 'spike_matching_match_result_aggregate_fields';
  avg: Maybe<Spike_Matching_Match_Result_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Spike_Matching_Match_Result_Max_Fields>;
  min: Maybe<Spike_Matching_Match_Result_Min_Fields>;
  stddev: Maybe<Spike_Matching_Match_Result_Stddev_Fields>;
  stddev_pop: Maybe<Spike_Matching_Match_Result_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Spike_Matching_Match_Result_Stddev_Samp_Fields>;
  sum: Maybe<Spike_Matching_Match_Result_Sum_Fields>;
  var_pop: Maybe<Spike_Matching_Match_Result_Var_Pop_Fields>;
  var_samp: Maybe<Spike_Matching_Match_Result_Var_Samp_Fields>;
  variance: Maybe<Spike_Matching_Match_Result_Variance_Fields>;
};

/** aggregate fields of "spike_matching.match_result" */
export type Spike_Matching_Match_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spike_Matching_Match_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Spike_Matching_Match_Result_Avg_Fields = {
  __typename?: 'spike_matching_match_result_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_score: Maybe<Scalars['Float']>;
  matched_location_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  skill_score: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spike_matching.match_result". All fields are combined with a logical 'AND'. */
export type Spike_Matching_Match_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Spike_Matching_Match_Result_Bool_Exp>>;
  _not?: InputMaybe<Spike_Matching_Match_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Spike_Matching_Match_Result_Bool_Exp>>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Spike_Matching_Location_Bool_Exp>;
  location_score?: InputMaybe<Float8_Comparison_Exp>;
  matched_location_id?: InputMaybe<Int_Comparison_Exp>;
  matched_location_name?: InputMaybe<String_Comparison_Exp>;
  matched_skill_ids?: InputMaybe<Int_Array_Comparison_Exp>;
  matched_skill_names?: InputMaybe<String_Array_Comparison_Exp>;
  score?: InputMaybe<Float8_Comparison_Exp>;
  skill_score?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "spike_matching.match_result" */
export enum Spike_Matching_Match_Result_Constraint {
  /** unique or primary key constraint on columns "id" */
  MatchResultPkey = 'match_result_pkey',
}

/** input type for incrementing numeric columns in table "spike_matching.match_result" */
export type Spike_Matching_Match_Result_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  location_score?: InputMaybe<Scalars['float8']>;
  matched_location_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['float8']>;
  skill_score?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "spike_matching.match_result" */
export type Spike_Matching_Match_Result_Insert_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Spike_Matching_Location_Obj_Rel_Insert_Input>;
  location_score?: InputMaybe<Scalars['float8']>;
  matched_location_id?: InputMaybe<Scalars['Int']>;
  matched_location_name?: InputMaybe<Scalars['String']>;
  matched_skill_ids?: InputMaybe<Array<Scalars['Int']>>;
  matched_skill_names?: InputMaybe<Array<Scalars['String']>>;
  score?: InputMaybe<Scalars['float8']>;
  skill_score?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Spike_Matching_Match_Result_Max_Fields = {
  __typename?: 'spike_matching_match_result_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  location_score: Maybe<Scalars['float8']>;
  matched_location_id: Maybe<Scalars['Int']>;
  matched_location_name: Maybe<Scalars['String']>;
  matched_skill_ids: Maybe<Array<Scalars['Int']>>;
  matched_skill_names: Maybe<Array<Scalars['String']>>;
  score: Maybe<Scalars['float8']>;
  skill_score: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Spike_Matching_Match_Result_Min_Fields = {
  __typename?: 'spike_matching_match_result_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  location_score: Maybe<Scalars['float8']>;
  matched_location_id: Maybe<Scalars['Int']>;
  matched_location_name: Maybe<Scalars['String']>;
  matched_skill_ids: Maybe<Array<Scalars['Int']>>;
  matched_skill_names: Maybe<Array<Scalars['String']>>;
  score: Maybe<Scalars['float8']>;
  skill_score: Maybe<Scalars['float8']>;
};

/** response of any mutation on the table "spike_matching.match_result" */
export type Spike_Matching_Match_Result_Mutation_Response = {
  __typename?: 'spike_matching_match_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spike_Matching_Match_Result>;
};

/** on_conflict condition type for table "spike_matching.match_result" */
export type Spike_Matching_Match_Result_On_Conflict = {
  constraint: Spike_Matching_Match_Result_Constraint;
  update_columns?: Array<Spike_Matching_Match_Result_Update_Column>;
  where?: InputMaybe<Spike_Matching_Match_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "spike_matching.match_result". */
export type Spike_Matching_Match_Result_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Spike_Matching_Location_Order_By>;
  location_score?: InputMaybe<Order_By>;
  matched_location_id?: InputMaybe<Order_By>;
  matched_location_name?: InputMaybe<Order_By>;
  matched_skill_ids?: InputMaybe<Order_By>;
  matched_skill_names?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  skill_score?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spike_matching.match_result */
export type Spike_Matching_Match_Result_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "spike_matching.match_result" */
export enum Spike_Matching_Match_Result_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LocationScore = 'location_score',
  /** column name */
  MatchedLocationId = 'matched_location_id',
  /** column name */
  MatchedLocationName = 'matched_location_name',
  /** column name */
  MatchedSkillIds = 'matched_skill_ids',
  /** column name */
  MatchedSkillNames = 'matched_skill_names',
  /** column name */
  Score = 'score',
  /** column name */
  SkillScore = 'skill_score',
}

/** input type for updating data in table "spike_matching.match_result" */
export type Spike_Matching_Match_Result_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  location_score?: InputMaybe<Scalars['float8']>;
  matched_location_id?: InputMaybe<Scalars['Int']>;
  matched_location_name?: InputMaybe<Scalars['String']>;
  matched_skill_ids?: InputMaybe<Array<Scalars['Int']>>;
  matched_skill_names?: InputMaybe<Array<Scalars['String']>>;
  score?: InputMaybe<Scalars['float8']>;
  skill_score?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Spike_Matching_Match_Result_Stddev_Fields = {
  __typename?: 'spike_matching_match_result_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_score: Maybe<Scalars['Float']>;
  matched_location_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  skill_score: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Spike_Matching_Match_Result_Stddev_Pop_Fields = {
  __typename?: 'spike_matching_match_result_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_score: Maybe<Scalars['Float']>;
  matched_location_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  skill_score: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Spike_Matching_Match_Result_Stddev_Samp_Fields = {
  __typename?: 'spike_matching_match_result_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_score: Maybe<Scalars['Float']>;
  matched_location_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  skill_score: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "spike_matching_match_result" */
export type Spike_Matching_Match_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spike_Matching_Match_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spike_Matching_Match_Result_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_id?: InputMaybe<Scalars['Int']>;
  location_score?: InputMaybe<Scalars['float8']>;
  matched_location_id?: InputMaybe<Scalars['Int']>;
  matched_location_name?: InputMaybe<Scalars['String']>;
  matched_skill_ids?: InputMaybe<Array<Scalars['Int']>>;
  matched_skill_names?: InputMaybe<Array<Scalars['String']>>;
  score?: InputMaybe<Scalars['float8']>;
  skill_score?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Spike_Matching_Match_Result_Sum_Fields = {
  __typename?: 'spike_matching_match_result_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  job_id: Maybe<Scalars['Int']>;
  location_score: Maybe<Scalars['float8']>;
  matched_location_id: Maybe<Scalars['Int']>;
  score: Maybe<Scalars['float8']>;
  skill_score: Maybe<Scalars['float8']>;
};

/** update columns of table "spike_matching.match_result" */
export enum Spike_Matching_Match_Result_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LocationScore = 'location_score',
  /** column name */
  MatchedLocationId = 'matched_location_id',
  /** column name */
  MatchedLocationName = 'matched_location_name',
  /** column name */
  MatchedSkillIds = 'matched_skill_ids',
  /** column name */
  MatchedSkillNames = 'matched_skill_names',
  /** column name */
  Score = 'score',
  /** column name */
  SkillScore = 'skill_score',
}

export type Spike_Matching_Match_Result_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spike_Matching_Match_Result_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spike_Matching_Match_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spike_Matching_Match_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spike_Matching_Match_Result_Var_Pop_Fields = {
  __typename?: 'spike_matching_match_result_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_score: Maybe<Scalars['Float']>;
  matched_location_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  skill_score: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Spike_Matching_Match_Result_Var_Samp_Fields = {
  __typename?: 'spike_matching_match_result_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_score: Maybe<Scalars['Float']>;
  matched_location_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  skill_score: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Spike_Matching_Match_Result_Variance_Fields = {
  __typename?: 'spike_matching_match_result_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_id: Maybe<Scalars['Float']>;
  location_score: Maybe<Scalars['Float']>;
  matched_location_id: Maybe<Scalars['Float']>;
  score: Maybe<Scalars['Float']>;
  skill_score: Maybe<Scalars['Float']>;
};

/** columns and relationships of "spike_matching.skill" */
export type Spike_Matching_Skill = {
  __typename?: 'spike_matching_skill';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "spike_matching.skill" */
export type Spike_Matching_Skill_Aggregate = {
  __typename?: 'spike_matching_skill_aggregate';
  aggregate: Maybe<Spike_Matching_Skill_Aggregate_Fields>;
  nodes: Array<Spike_Matching_Skill>;
};

/** aggregate fields of "spike_matching.skill" */
export type Spike_Matching_Skill_Aggregate_Fields = {
  __typename?: 'spike_matching_skill_aggregate_fields';
  avg: Maybe<Spike_Matching_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Spike_Matching_Skill_Max_Fields>;
  min: Maybe<Spike_Matching_Skill_Min_Fields>;
  stddev: Maybe<Spike_Matching_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Spike_Matching_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Spike_Matching_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Spike_Matching_Skill_Sum_Fields>;
  var_pop: Maybe<Spike_Matching_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Spike_Matching_Skill_Var_Samp_Fields>;
  variance: Maybe<Spike_Matching_Skill_Variance_Fields>;
};

/** aggregate fields of "spike_matching.skill" */
export type Spike_Matching_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spike_Matching_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Spike_Matching_Skill_Avg_Fields = {
  __typename?: 'spike_matching_skill_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spike_matching.skill". All fields are combined with a logical 'AND'. */
export type Spike_Matching_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Spike_Matching_Skill_Bool_Exp>>;
  _not?: InputMaybe<Spike_Matching_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Spike_Matching_Skill_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "spike_matching.skill" */
export enum Spike_Matching_Skill_Constraint {
  /** unique or primary key constraint on columns "id" */
  SkillPkey = 'skill_pkey',
}

/** input type for incrementing numeric columns in table "spike_matching.skill" */
export type Spike_Matching_Skill_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "spike_matching.skill" */
export type Spike_Matching_Skill_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Spike_Matching_Skill_Max_Fields = {
  __typename?: 'spike_matching_skill_max_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Spike_Matching_Skill_Min_Fields = {
  __typename?: 'spike_matching_skill_min_fields';
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "spike_matching.skill" */
export type Spike_Matching_Skill_Mutation_Response = {
  __typename?: 'spike_matching_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spike_Matching_Skill>;
};

/** on_conflict condition type for table "spike_matching.skill" */
export type Spike_Matching_Skill_On_Conflict = {
  constraint: Spike_Matching_Skill_Constraint;
  update_columns?: Array<Spike_Matching_Skill_Update_Column>;
  where?: InputMaybe<Spike_Matching_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "spike_matching.skill". */
export type Spike_Matching_Skill_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spike_matching.skill */
export type Spike_Matching_Skill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "spike_matching.skill" */
export enum Spike_Matching_Skill_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "spike_matching.skill" */
export type Spike_Matching_Skill_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Spike_Matching_Skill_Stddev_Fields = {
  __typename?: 'spike_matching_skill_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Spike_Matching_Skill_Stddev_Pop_Fields = {
  __typename?: 'spike_matching_skill_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Spike_Matching_Skill_Stddev_Samp_Fields = {
  __typename?: 'spike_matching_skill_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "spike_matching_skill" */
export type Spike_Matching_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spike_Matching_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spike_Matching_Skill_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Spike_Matching_Skill_Sum_Fields = {
  __typename?: 'spike_matching_skill_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "spike_matching.skill" */
export enum Spike_Matching_Skill_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

export type Spike_Matching_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spike_Matching_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spike_Matching_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spike_Matching_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spike_Matching_Skill_Var_Pop_Fields = {
  __typename?: 'spike_matching_skill_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Spike_Matching_Skill_Var_Samp_Fields = {
  __typename?: 'spike_matching_skill_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Spike_Matching_Skill_Variance_Fields = {
  __typename?: 'spike_matching_skill_variance_fields';
  id: Maybe<Scalars['Float']>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: InputMaybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "activity_customer_profile_view" */
  activity_customer_profile_view: Array<Activity_Customer_Profile_View>;
  /** fetch aggregated fields from the table: "activity_customer_profile_view" */
  activity_customer_profile_view_aggregate: Activity_Customer_Profile_View_Aggregate;
  /** fetch data from the table: "activity_customer_profile_view" using primary key columns */
  activity_customer_profile_view_by_pk: Maybe<Activity_Customer_Profile_View>;
  /** fetch data from the table in a streaming manner: "activity_customer_profile_view" */
  activity_customer_profile_view_stream: Array<Activity_Customer_Profile_View>;
  /** fetch data from the table: "ai_level" */
  ai_level: Array<Ai_Level>;
  /** fetch aggregated fields from the table: "ai_level" */
  ai_level_aggregate: Ai_Level_Aggregate;
  /** fetch data from the table: "ai_level" using primary key columns */
  ai_level_by_pk: Maybe<Ai_Level>;
  /** fetch data from the table in a streaming manner: "ai_level" */
  ai_level_stream: Array<Ai_Level>;
  /** fetch data from the table: "ai_role" */
  ai_role: Array<Ai_Role>;
  /** fetch aggregated fields from the table: "ai_role" */
  ai_role_aggregate: Ai_Role_Aggregate;
  /** fetch data from the table: "ai_role" using primary key columns */
  ai_role_by_pk: Maybe<Ai_Role>;
  /** fetch data from the table: "ai_role_multiplier_focus" */
  ai_role_multiplier_focus: Array<Ai_Role_Multiplier_Focus>;
  /** fetch aggregated fields from the table: "ai_role_multiplier_focus" */
  ai_role_multiplier_focus_aggregate: Ai_Role_Multiplier_Focus_Aggregate;
  /** fetch data from the table: "ai_role_multiplier_focus" using primary key columns */
  ai_role_multiplier_focus_by_pk: Maybe<Ai_Role_Multiplier_Focus>;
  /** fetch data from the table in a streaming manner: "ai_role_multiplier_focus" */
  ai_role_multiplier_focus_stream: Array<Ai_Role_Multiplier_Focus>;
  /** fetch data from the table in a streaming manner: "ai_role" */
  ai_role_stream: Array<Ai_Role>;
  /** fetch data from the table: "ai_run_dump" */
  ai_run_dump: Array<Ai_Run_Dump>;
  /** fetch aggregated fields from the table: "ai_run_dump" */
  ai_run_dump_aggregate: Ai_Run_Dump_Aggregate;
  /** fetch data from the table: "ai_run_dump" using primary key columns */
  ai_run_dump_by_pk: Maybe<Ai_Run_Dump>;
  /** fetch data from the table in a streaming manner: "ai_run_dump" */
  ai_run_dump_stream: Array<Ai_Run_Dump>;
  /** fetch data from the table: "applicant_consent" */
  applicant_consent: Array<Applicant_Consent>;
  /** fetch aggregated fields from the table: "applicant_consent" */
  applicant_consent_aggregate: Applicant_Consent_Aggregate;
  /** fetch data from the table: "applicant_consent" using primary key columns */
  applicant_consent_by_pk: Maybe<Applicant_Consent>;
  /** fetch data from the table in a streaming manner: "applicant_consent" */
  applicant_consent_stream: Array<Applicant_Consent>;
  /** fetch data from the table: "applicant_job" */
  applicant_job: Array<Applicant_Job>;
  /** fetch aggregated fields from the table: "applicant_job" */
  applicant_job_aggregate: Applicant_Job_Aggregate;
  /** fetch data from the table: "applicant_job" using primary key columns */
  applicant_job_by_pk: Maybe<Applicant_Job>;
  /** fetch data from the table in a streaming manner: "applicant_job" */
  applicant_job_stream: Array<Applicant_Job>;
  /** fetch data from the table: "applicant_waitlist" */
  applicant_waitlist: Array<Applicant_Waitlist>;
  /** fetch aggregated fields from the table: "applicant_waitlist" */
  applicant_waitlist_aggregate: Applicant_Waitlist_Aggregate;
  /** fetch data from the table: "applicant_waitlist" using primary key columns */
  applicant_waitlist_by_pk: Maybe<Applicant_Waitlist>;
  /** fetch data from the table in a streaming manner: "applicant_waitlist" */
  applicant_waitlist_stream: Array<Applicant_Waitlist>;
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<Audit_Logged_Actions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk: Maybe<Audit_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "audit.logged_actions" */
  audit_logged_actions_stream: Array<Audit_Logged_Actions>;
  /** fetch data from the table: "calendly_event" */
  calendly_event: Array<Calendly_Event>;
  /** fetch aggregated fields from the table: "calendly_event" */
  calendly_event_aggregate: Calendly_Event_Aggregate;
  /** fetch data from the table: "calendly_event" using primary key columns */
  calendly_event_by_pk: Maybe<Calendly_Event>;
  /** fetch data from the table in a streaming manner: "calendly_event" */
  calendly_event_stream: Array<Calendly_Event>;
  /** fetch data from the table: "calendly_invitee" */
  calendly_invitee: Array<Calendly_Invitee>;
  /** fetch aggregated fields from the table: "calendly_invitee" */
  calendly_invitee_aggregate: Calendly_Invitee_Aggregate;
  /** fetch data from the table: "calendly_invitee" using primary key columns */
  calendly_invitee_by_pk: Maybe<Calendly_Invitee>;
  /** fetch data from the table in a streaming manner: "calendly_invitee" */
  calendly_invitee_stream: Array<Calendly_Invitee>;
  /** fetch data from the table: "calendly_user" */
  calendly_user: Array<Calendly_User>;
  /** fetch aggregated fields from the table: "calendly_user" */
  calendly_user_aggregate: Calendly_User_Aggregate;
  /** fetch data from the table: "calendly_user" using primary key columns */
  calendly_user_by_pk: Maybe<Calendly_User>;
  /** fetch data from the table in a streaming manner: "calendly_user" */
  calendly_user_stream: Array<Calendly_User>;
  /** fetch data from the table: "candidate" */
  candidate: Array<Candidate>;
  /** fetch aggregated fields from the table: "candidate" */
  candidate_aggregate: Candidate_Aggregate;
  /** fetch data from the table: "candidate_ai_profile_summary" */
  candidate_ai_profile_summary: Array<Candidate_Ai_Profile_Summary>;
  /** fetch aggregated fields from the table: "candidate_ai_profile_summary" */
  candidate_ai_profile_summary_aggregate: Candidate_Ai_Profile_Summary_Aggregate;
  /** fetch data from the table: "candidate_ai_profile_summary" using primary key columns */
  candidate_ai_profile_summary_by_pk: Maybe<Candidate_Ai_Profile_Summary>;
  /** fetch data from the table in a streaming manner: "candidate_ai_profile_summary" */
  candidate_ai_profile_summary_stream: Array<Candidate_Ai_Profile_Summary>;
  /** execute function "candidate_applicant_workflow_stats" which returns "candidate_applicant_workflow_stats_result" */
  candidate_applicant_workflow_stats: Array<Candidate_Applicant_Workflow_Stats_Result>;
  /** execute function "candidate_applicant_workflow_stats" and query aggregates on result of table type "candidate_applicant_workflow_stats_result" */
  candidate_applicant_workflow_stats_aggregate: Candidate_Applicant_Workflow_Stats_Result_Aggregate;
  /** fetch data from the table: "candidate_applicant_workflow_stats_result" */
  candidate_applicant_workflow_stats_result: Array<Candidate_Applicant_Workflow_Stats_Result>;
  /** fetch aggregated fields from the table: "candidate_applicant_workflow_stats_result" */
  candidate_applicant_workflow_stats_result_aggregate: Candidate_Applicant_Workflow_Stats_Result_Aggregate;
  /** fetch data from the table in a streaming manner: "candidate_applicant_workflow_stats_result" */
  candidate_applicant_workflow_stats_result_stream: Array<Candidate_Applicant_Workflow_Stats_Result>;
  /** fetch data from the table: "candidate_approved_country" */
  candidate_approved_country: Array<Candidate_Approved_Country>;
  /** fetch aggregated fields from the table: "candidate_approved_country" */
  candidate_approved_country_aggregate: Candidate_Approved_Country_Aggregate;
  /** fetch data from the table: "candidate_approved_country" using primary key columns */
  candidate_approved_country_by_pk: Maybe<Candidate_Approved_Country>;
  /** fetch data from the table in a streaming manner: "candidate_approved_country" */
  candidate_approved_country_stream: Array<Candidate_Approved_Country>;
  /** fetch data from the table: "candidate_availability_choices" */
  candidate_availability_choices: Array<Candidate_Availability_Choices>;
  /** fetch aggregated fields from the table: "candidate_availability_choices" */
  candidate_availability_choices_aggregate: Candidate_Availability_Choices_Aggregate;
  /** fetch data from the table: "candidate_availability_choices" using primary key columns */
  candidate_availability_choices_by_pk: Maybe<Candidate_Availability_Choices>;
  /** fetch data from the table: "candidate_availability_choices_order" */
  candidate_availability_choices_order: Array<Candidate_Availability_Choices_Order>;
  /** fetch aggregated fields from the table: "candidate_availability_choices_order" */
  candidate_availability_choices_order_aggregate: Candidate_Availability_Choices_Order_Aggregate;
  /** fetch data from the table: "candidate_availability_choices_order" using primary key columns */
  candidate_availability_choices_order_by_pk: Maybe<Candidate_Availability_Choices_Order>;
  /** fetch data from the table in a streaming manner: "candidate_availability_choices_order" */
  candidate_availability_choices_order_stream: Array<Candidate_Availability_Choices_Order>;
  /** fetch data from the table in a streaming manner: "candidate_availability_choices" */
  candidate_availability_choices_stream: Array<Candidate_Availability_Choices>;
  /** fetch data from the table: "candidate_badge" */
  candidate_badge: Array<Candidate_Badge>;
  /** fetch aggregated fields from the table: "candidate_badge" */
  candidate_badge_aggregate: Candidate_Badge_Aggregate;
  /** fetch data from the table: "candidate_badge" using primary key columns */
  candidate_badge_by_pk: Maybe<Candidate_Badge>;
  /** fetch data from the table in a streaming manner: "candidate_badge" */
  candidate_badge_stream: Array<Candidate_Badge>;
  /** execute function "candidate_browse" which returns "candidate_browse_result" */
  candidate_browse: Array<Candidate_Browse_Result>;
  /** execute function "candidate_browse" and query aggregates on result of table type "candidate_browse_result" */
  candidate_browse_aggregate: Candidate_Browse_Result_Aggregate;
  /** fetch data from the table: "candidate_browse_result" */
  candidate_browse_result: Array<Candidate_Browse_Result>;
  /** fetch aggregated fields from the table: "candidate_browse_result" */
  candidate_browse_result_aggregate: Candidate_Browse_Result_Aggregate;
  /** fetch data from the table: "candidate_browse_result" using primary key columns */
  candidate_browse_result_by_pk: Maybe<Candidate_Browse_Result>;
  /** fetch data from the table in a streaming manner: "candidate_browse_result" */
  candidate_browse_result_stream: Array<Candidate_Browse_Result>;
  /** fetch data from the table: "candidate" using primary key columns */
  candidate_by_pk: Maybe<Candidate>;
  /** fetch data from the table: "candidate_curation_ai_summary" */
  candidate_curation_ai_summary: Array<Candidate_Curation_Ai_Summary>;
  /** fetch aggregated fields from the table: "candidate_curation_ai_summary" */
  candidate_curation_ai_summary_aggregate: Candidate_Curation_Ai_Summary_Aggregate;
  /** fetch data from the table: "candidate_curation_ai_summary" using primary key columns */
  candidate_curation_ai_summary_by_pk: Maybe<Candidate_Curation_Ai_Summary>;
  /** fetch data from the table: "candidate_curation_ai_summary_feedback" */
  candidate_curation_ai_summary_feedback: Array<Candidate_Curation_Ai_Summary_Feedback>;
  /** fetch aggregated fields from the table: "candidate_curation_ai_summary_feedback" */
  candidate_curation_ai_summary_feedback_aggregate: Candidate_Curation_Ai_Summary_Feedback_Aggregate;
  /** fetch data from the table: "candidate_curation_ai_summary_feedback" using primary key columns */
  candidate_curation_ai_summary_feedback_by_pk: Maybe<Candidate_Curation_Ai_Summary_Feedback>;
  /** fetch data from the table in a streaming manner: "candidate_curation_ai_summary_feedback" */
  candidate_curation_ai_summary_feedback_stream: Array<Candidate_Curation_Ai_Summary_Feedback>;
  /** fetch data from the table in a streaming manner: "candidate_curation_ai_summary" */
  candidate_curation_ai_summary_stream: Array<Candidate_Curation_Ai_Summary>;
  /** fetch data from the table: "candidate_curation_country_choices" */
  candidate_curation_country_choices: Array<Candidate_Curation_Country_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_country_choices" */
  candidate_curation_country_choices_aggregate: Candidate_Curation_Country_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_country_choices" using primary key columns */
  candidate_curation_country_choices_by_pk: Maybe<Candidate_Curation_Country_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_country_choices" */
  candidate_curation_country_choices_stream: Array<Candidate_Curation_Country_Choices>;
  /** fetch data from the table: "candidate_curation_desired_salary_currency_choices" */
  candidate_curation_desired_salary_currency_choices: Array<Candidate_Curation_Desired_Salary_Currency_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_desired_salary_currency_choices" */
  candidate_curation_desired_salary_currency_choices_aggregate: Candidate_Curation_Desired_Salary_Currency_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_desired_salary_currency_choices" using primary key columns */
  candidate_curation_desired_salary_currency_choices_by_pk: Maybe<Candidate_Curation_Desired_Salary_Currency_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_desired_salary_currency_choices" */
  candidate_curation_desired_salary_currency_choices_stream: Array<Candidate_Curation_Desired_Salary_Currency_Choices>;
  /** fetch data from the table: "candidate_curation_desired_salary_frequency_choices" */
  candidate_curation_desired_salary_frequency_choices: Array<Candidate_Curation_Desired_Salary_Frequency_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_desired_salary_frequency_choices" */
  candidate_curation_desired_salary_frequency_choices_aggregate: Candidate_Curation_Desired_Salary_Frequency_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_desired_salary_frequency_choices" using primary key columns */
  candidate_curation_desired_salary_frequency_choices_by_pk: Maybe<Candidate_Curation_Desired_Salary_Frequency_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_desired_salary_frequency_choices" */
  candidate_curation_desired_salary_frequency_choices_stream: Array<Candidate_Curation_Desired_Salary_Frequency_Choices>;
  /** fetch data from the table: "candidate_curation_detail" */
  candidate_curation_detail: Array<Candidate_Curation_Detail>;
  /** fetch aggregated fields from the table: "candidate_curation_detail" */
  candidate_curation_detail_aggregate: Candidate_Curation_Detail_Aggregate;
  /** fetch data from the table: "candidate_curation_detail" using primary key columns */
  candidate_curation_detail_by_pk: Maybe<Candidate_Curation_Detail>;
  /** fetch data from the table: "candidate_curation_detail_recruiter_summary_log" */
  candidate_curation_detail_recruiter_summary_log: Array<Candidate_Curation_Detail_Recruiter_Summary_Log>;
  /** fetch aggregated fields from the table: "candidate_curation_detail_recruiter_summary_log" */
  candidate_curation_detail_recruiter_summary_log_aggregate: Candidate_Curation_Detail_Recruiter_Summary_Log_Aggregate;
  /** fetch data from the table: "candidate_curation_detail_recruiter_summary_log" using primary key columns */
  candidate_curation_detail_recruiter_summary_log_by_pk: Maybe<Candidate_Curation_Detail_Recruiter_Summary_Log>;
  /** fetch data from the table in a streaming manner: "candidate_curation_detail_recruiter_summary_log" */
  candidate_curation_detail_recruiter_summary_log_stream: Array<Candidate_Curation_Detail_Recruiter_Summary_Log>;
  /** fetch data from the table in a streaming manner: "candidate_curation_detail" */
  candidate_curation_detail_stream: Array<Candidate_Curation_Detail>;
  /** fetch data from the table: "candidate_curation_english_proficiency_choices" */
  candidate_curation_english_proficiency_choices: Array<Candidate_Curation_English_Proficiency_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_english_proficiency_choices" */
  candidate_curation_english_proficiency_choices_aggregate: Candidate_Curation_English_Proficiency_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_english_proficiency_choices" using primary key columns */
  candidate_curation_english_proficiency_choices_by_pk: Maybe<Candidate_Curation_English_Proficiency_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_english_proficiency_choices" */
  candidate_curation_english_proficiency_choices_stream: Array<Candidate_Curation_English_Proficiency_Choices>;
  /** fetch data from the table: "candidate_curation_equity_importance_choices" */
  candidate_curation_equity_importance_choices: Array<Candidate_Curation_Equity_Importance_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_equity_importance_choices" */
  candidate_curation_equity_importance_choices_aggregate: Candidate_Curation_Equity_Importance_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_equity_importance_choices" using primary key columns */
  candidate_curation_equity_importance_choices_by_pk: Maybe<Candidate_Curation_Equity_Importance_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_equity_importance_choices" */
  candidate_curation_equity_importance_choices_stream: Array<Candidate_Curation_Equity_Importance_Choices>;
  /** fetch data from the table: "candidate_curation_note" */
  candidate_curation_note: Array<Candidate_Curation_Note>;
  /** fetch aggregated fields from the table: "candidate_curation_note" */
  candidate_curation_note_aggregate: Candidate_Curation_Note_Aggregate;
  /** fetch data from the table: "candidate_curation_note" using primary key columns */
  candidate_curation_note_by_pk: Maybe<Candidate_Curation_Note>;
  /** fetch data from the table in a streaming manner: "candidate_curation_note" */
  candidate_curation_note_stream: Array<Candidate_Curation_Note>;
  /** fetch data from the table: "candidate_curation_rejected_reason_choices" */
  candidate_curation_rejected_reason_choices: Array<Candidate_Curation_Rejected_Reason_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_rejected_reason_choices" */
  candidate_curation_rejected_reason_choices_aggregate: Candidate_Curation_Rejected_Reason_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_rejected_reason_choices" using primary key columns */
  candidate_curation_rejected_reason_choices_by_pk: Maybe<Candidate_Curation_Rejected_Reason_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_rejected_reason_choices" */
  candidate_curation_rejected_reason_choices_stream: Array<Candidate_Curation_Rejected_Reason_Choices>;
  /** fetch data from the table: "candidate_curation_remote_work_exp_choices" */
  candidate_curation_remote_work_exp_choices: Array<Candidate_Curation_Remote_Work_Exp_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_remote_work_exp_choices" */
  candidate_curation_remote_work_exp_choices_aggregate: Candidate_Curation_Remote_Work_Exp_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_remote_work_exp_choices" using primary key columns */
  candidate_curation_remote_work_exp_choices_by_pk: Maybe<Candidate_Curation_Remote_Work_Exp_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_remote_work_exp_choices" */
  candidate_curation_remote_work_exp_choices_stream: Array<Candidate_Curation_Remote_Work_Exp_Choices>;
  /** fetch data from the table: "candidate_curation_visa_status_choices" */
  candidate_curation_visa_status_choices: Array<Candidate_Curation_Visa_Status_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_visa_status_choices" */
  candidate_curation_visa_status_choices_aggregate: Candidate_Curation_Visa_Status_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_visa_status_choices" using primary key columns */
  candidate_curation_visa_status_choices_by_pk: Maybe<Candidate_Curation_Visa_Status_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_visa_status_choices" */
  candidate_curation_visa_status_choices_stream: Array<Candidate_Curation_Visa_Status_Choices>;
  /** fetch data from the table: "candidate_curation_workflow" */
  candidate_curation_workflow: Array<Candidate_Curation_Workflow>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow" */
  candidate_curation_workflow_aggregate: Candidate_Curation_Workflow_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow" using primary key columns */
  candidate_curation_workflow_by_pk: Maybe<Candidate_Curation_Workflow>;
  /** fetch data from the table: "candidate_curation_workflow_needs_info_reason" */
  candidate_curation_workflow_needs_info_reason: Array<Candidate_Curation_Workflow_Needs_Info_Reason>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_needs_info_reason" */
  candidate_curation_workflow_needs_info_reason_aggregate: Candidate_Curation_Workflow_Needs_Info_Reason_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_needs_info_reason" using primary key columns */
  candidate_curation_workflow_needs_info_reason_by_pk: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason>;
  /** fetch data from the table: "candidate_curation_workflow_needs_info_reason_choices" */
  candidate_curation_workflow_needs_info_reason_choices: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_needs_info_reason_choices" */
  candidate_curation_workflow_needs_info_reason_choices_aggregate: Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_needs_info_reason_choices" using primary key columns */
  candidate_curation_workflow_needs_info_reason_choices_by_pk: Maybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_workflow_needs_info_reason_choices" */
  candidate_curation_workflow_needs_info_reason_choices_stream: Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_workflow_needs_info_reason" */
  candidate_curation_workflow_needs_info_reason_stream: Array<Candidate_Curation_Workflow_Needs_Info_Reason>;
  /** fetch data from the table: "candidate_curation_workflow_screener_type_choices" */
  candidate_curation_workflow_screener_type_choices: Array<Candidate_Curation_Workflow_Screener_Type_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_screener_type_choices" */
  candidate_curation_workflow_screener_type_choices_aggregate: Candidate_Curation_Workflow_Screener_Type_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_screener_type_choices" using primary key columns */
  candidate_curation_workflow_screener_type_choices_by_pk: Maybe<Candidate_Curation_Workflow_Screener_Type_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_workflow_screener_type_choices" */
  candidate_curation_workflow_screener_type_choices_stream: Array<Candidate_Curation_Workflow_Screener_Type_Choices>;
  /** fetch data from the table: "candidate_curation_workflow_source_choices" */
  candidate_curation_workflow_source_choices: Array<Candidate_Curation_Workflow_Source_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_source_choices" */
  candidate_curation_workflow_source_choices_aggregate: Candidate_Curation_Workflow_Source_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_source_choices" using primary key columns */
  candidate_curation_workflow_source_choices_by_pk: Maybe<Candidate_Curation_Workflow_Source_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_workflow_source_choices" */
  candidate_curation_workflow_source_choices_stream: Array<Candidate_Curation_Workflow_Source_Choices>;
  /** fetch data from the table: "candidate_curation_workflow_status_choices" */
  candidate_curation_workflow_status_choices: Array<Candidate_Curation_Workflow_Status_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_status_choices" */
  candidate_curation_workflow_status_choices_aggregate: Candidate_Curation_Workflow_Status_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_status_choices" using primary key columns */
  candidate_curation_workflow_status_choices_by_pk: Maybe<Candidate_Curation_Workflow_Status_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_curation_workflow_status_choices" */
  candidate_curation_workflow_status_choices_stream: Array<Candidate_Curation_Workflow_Status_Choices>;
  /** fetch data from the table: "candidate_curation_workflow_status_log" */
  candidate_curation_workflow_status_log: Array<Candidate_Curation_Workflow_Status_Log>;
  /** fetch aggregated fields from the table: "candidate_curation_workflow_status_log" */
  candidate_curation_workflow_status_log_aggregate: Candidate_Curation_Workflow_Status_Log_Aggregate;
  /** fetch data from the table: "candidate_curation_workflow_status_log" using primary key columns */
  candidate_curation_workflow_status_log_by_pk: Maybe<Candidate_Curation_Workflow_Status_Log>;
  /** fetch data from the table in a streaming manner: "candidate_curation_workflow_status_log" */
  candidate_curation_workflow_status_log_stream: Array<Candidate_Curation_Workflow_Status_Log>;
  /** fetch data from the table in a streaming manner: "candidate_curation_workflow" */
  candidate_curation_workflow_stream: Array<Candidate_Curation_Workflow>;
  /** fetch data from the table: "candidate_curation_years_of_exp_range_choices" */
  candidate_curation_years_of_exp_range_choices: Array<Candidate_Curation_Years_Of_Exp_Range_Choices>;
  /** fetch aggregated fields from the table: "candidate_curation_years_of_exp_range_choices" */
  candidate_curation_years_of_exp_range_choices_aggregate: Candidate_Curation_Years_Of_Exp_Range_Choices_Aggregate;
  /** fetch data from the table: "candidate_curation_years_of_exp_range_choices" using primary key columns */
  candidate_curation_years_of_exp_range_choices_by_pk: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices>;
  /** fetch data from the table: "candidate_curation_years_of_exp_range_choices_order" */
  candidate_curation_years_of_exp_range_choices_order: Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order>;
  /** fetch aggregated fields from the table: "candidate_curation_years_of_exp_range_choices_order" */
  candidate_curation_years_of_exp_range_choices_order_aggregate: Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Aggregate;
  /** fetch data from the table: "candidate_curation_years_of_exp_range_choices_order" using primary key columns */
  candidate_curation_years_of_exp_range_choices_order_by_pk: Maybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order>;
  /** fetch data from the table in a streaming manner: "candidate_curation_years_of_exp_range_choices_order" */
  candidate_curation_years_of_exp_range_choices_order_stream: Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order>;
  /** fetch data from the table in a streaming manner: "candidate_curation_years_of_exp_range_choices" */
  candidate_curation_years_of_exp_range_choices_stream: Array<Candidate_Curation_Years_Of_Exp_Range_Choices>;
  /** fetch data from the table: "candidate_detail_level_choices" */
  candidate_detail_level_choices: Array<Candidate_Detail_Level_Choices>;
  /** fetch aggregated fields from the table: "candidate_detail_level_choices" */
  candidate_detail_level_choices_aggregate: Candidate_Detail_Level_Choices_Aggregate;
  /** fetch data from the table: "candidate_detail_level_choices" using primary key columns */
  candidate_detail_level_choices_by_pk: Maybe<Candidate_Detail_Level_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_detail_level_choices" */
  candidate_detail_level_choices_stream: Array<Candidate_Detail_Level_Choices>;
  /** fetch data from the table: "candidate_education" */
  candidate_education: Array<Candidate_Education>;
  /** fetch aggregated fields from the table: "candidate_education" */
  candidate_education_aggregate: Candidate_Education_Aggregate;
  /** fetch data from the table: "candidate_education" using primary key columns */
  candidate_education_by_pk: Maybe<Candidate_Education>;
  /** fetch data from the table: "candidate_education_concentration_choices" */
  candidate_education_concentration_choices: Array<Candidate_Education_Concentration_Choices>;
  /** fetch aggregated fields from the table: "candidate_education_concentration_choices" */
  candidate_education_concentration_choices_aggregate: Candidate_Education_Concentration_Choices_Aggregate;
  /** fetch data from the table: "candidate_education_concentration_choices" using primary key columns */
  candidate_education_concentration_choices_by_pk: Maybe<Candidate_Education_Concentration_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_education_concentration_choices" */
  candidate_education_concentration_choices_stream: Array<Candidate_Education_Concentration_Choices>;
  /** fetch data from the table: "candidate_education_degree_choices" */
  candidate_education_degree_choices: Array<Candidate_Education_Degree_Choices>;
  /** fetch aggregated fields from the table: "candidate_education_degree_choices" */
  candidate_education_degree_choices_aggregate: Candidate_Education_Degree_Choices_Aggregate;
  /** fetch data from the table: "candidate_education_degree_choices" using primary key columns */
  candidate_education_degree_choices_by_pk: Maybe<Candidate_Education_Degree_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_education_degree_choices" */
  candidate_education_degree_choices_stream: Array<Candidate_Education_Degree_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_education" */
  candidate_education_stream: Array<Candidate_Education>;
  /** fetch data from the table: "candidate_email" */
  candidate_email: Array<Candidate_Email>;
  /** fetch aggregated fields from the table: "candidate_email" */
  candidate_email_aggregate: Candidate_Email_Aggregate;
  /** fetch data from the table: "candidate_email" using primary key columns */
  candidate_email_by_pk: Maybe<Candidate_Email>;
  /** fetch data from the table in a streaming manner: "candidate_email" */
  candidate_email_stream: Array<Candidate_Email>;
  /** fetch data from the table: "candidate_embedding" */
  candidate_embedding: Array<Candidate_Embedding>;
  /** fetch aggregated fields from the table: "candidate_embedding" */
  candidate_embedding_aggregate: Candidate_Embedding_Aggregate;
  /** fetch data from the table: "candidate_embedding" using primary key columns */
  candidate_embedding_by_pk: Maybe<Candidate_Embedding>;
  /** fetch data from the table in a streaming manner: "candidate_embedding" */
  candidate_embedding_stream: Array<Candidate_Embedding>;
  /** fetch data from the table: "candidate_highlight_choices" */
  candidate_highlight_choices: Array<Candidate_Highlight_Choices>;
  /** fetch aggregated fields from the table: "candidate_highlight_choices" */
  candidate_highlight_choices_aggregate: Candidate_Highlight_Choices_Aggregate;
  /** fetch data from the table: "candidate_highlight_choices" using primary key columns */
  candidate_highlight_choices_by_pk: Maybe<Candidate_Highlight_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_highlight_choices" */
  candidate_highlight_choices_stream: Array<Candidate_Highlight_Choices>;
  /** fetch data from the table: "candidate_hub_location" */
  candidate_hub_location: Array<Candidate_Hub_Location>;
  /** fetch aggregated fields from the table: "candidate_hub_location" */
  candidate_hub_location_aggregate: Candidate_Hub_Location_Aggregate;
  /** fetch data from the table: "candidate_hub_location" using primary key columns */
  candidate_hub_location_by_pk: Maybe<Candidate_Hub_Location>;
  /** fetch data from the table in a streaming manner: "candidate_hub_location" */
  candidate_hub_location_stream: Array<Candidate_Hub_Location>;
  /** fetch data from the table: "candidate_interview_invite" */
  candidate_interview_invite: Array<Candidate_Interview_Invite>;
  /** fetch aggregated fields from the table: "candidate_interview_invite" */
  candidate_interview_invite_aggregate: Candidate_Interview_Invite_Aggregate;
  /** fetch data from the table: "candidate_interview_invite" using primary key columns */
  candidate_interview_invite_by_pk: Maybe<Candidate_Interview_Invite>;
  /** fetch data from the table in a streaming manner: "candidate_interview_invite" */
  candidate_interview_invite_stream: Array<Candidate_Interview_Invite>;
  /** fetch data from the table: "candidate_job" */
  candidate_job: Array<Candidate_Job>;
  /** fetch aggregated fields from the table: "candidate_job" */
  candidate_job_aggregate: Candidate_Job_Aggregate;
  /** fetch data from the table: "candidate_job" using primary key columns */
  candidate_job_by_pk: Maybe<Candidate_Job>;
  /** fetch data from the table in a streaming manner: "candidate_job" */
  candidate_job_stream: Array<Candidate_Job>;
  /** fetch data from the table: "candidate_job_workflow" */
  candidate_job_workflow: Array<Candidate_Job_Workflow>;
  /** fetch aggregated fields from the table: "candidate_job_workflow" */
  candidate_job_workflow_aggregate: Candidate_Job_Workflow_Aggregate;
  /** fetch data from the table: "candidate_job_workflow" using primary key columns */
  candidate_job_workflow_by_pk: Maybe<Candidate_Job_Workflow>;
  /** fetch data from the table: "candidate_job_workflow_source_choices" */
  candidate_job_workflow_source_choices: Array<Candidate_Job_Workflow_Source_Choices>;
  /** fetch aggregated fields from the table: "candidate_job_workflow_source_choices" */
  candidate_job_workflow_source_choices_aggregate: Candidate_Job_Workflow_Source_Choices_Aggregate;
  /** fetch data from the table: "candidate_job_workflow_source_choices" using primary key columns */
  candidate_job_workflow_source_choices_by_pk: Maybe<Candidate_Job_Workflow_Source_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_job_workflow_source_choices" */
  candidate_job_workflow_source_choices_stream: Array<Candidate_Job_Workflow_Source_Choices>;
  /** fetch data from the table: "candidate_job_workflow_status" */
  candidate_job_workflow_status: Array<Candidate_Job_Workflow_Status>;
  /** fetch aggregated fields from the table: "candidate_job_workflow_status" */
  candidate_job_workflow_status_aggregate: Candidate_Job_Workflow_Status_Aggregate;
  /** fetch data from the table: "candidate_job_workflow_status" using primary key columns */
  candidate_job_workflow_status_by_pk: Maybe<Candidate_Job_Workflow_Status>;
  /** fetch data from the table: "candidate_job_workflow_status_choices" */
  candidate_job_workflow_status_choices: Array<Candidate_Job_Workflow_Status_Choices>;
  /** fetch aggregated fields from the table: "candidate_job_workflow_status_choices" */
  candidate_job_workflow_status_choices_aggregate: Candidate_Job_Workflow_Status_Choices_Aggregate;
  /** fetch data from the table: "candidate_job_workflow_status_choices" using primary key columns */
  candidate_job_workflow_status_choices_by_pk: Maybe<Candidate_Job_Workflow_Status_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_job_workflow_status_choices" */
  candidate_job_workflow_status_choices_stream: Array<Candidate_Job_Workflow_Status_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_job_workflow_status" */
  candidate_job_workflow_status_stream: Array<Candidate_Job_Workflow_Status>;
  /** fetch data from the table in a streaming manner: "candidate_job_workflow" */
  candidate_job_workflow_stream: Array<Candidate_Job_Workflow>;
  /** fetch data from the table: "candidate_parsed_resume" */
  candidate_parsed_resume: Array<Candidate_Parsed_Resume>;
  /** fetch aggregated fields from the table: "candidate_parsed_resume" */
  candidate_parsed_resume_aggregate: Candidate_Parsed_Resume_Aggregate;
  /** fetch data from the table: "candidate_parsed_resume" using primary key columns */
  candidate_parsed_resume_by_pk: Maybe<Candidate_Parsed_Resume>;
  /** fetch data from the table in a streaming manner: "candidate_parsed_resume" */
  candidate_parsed_resume_stream: Array<Candidate_Parsed_Resume>;
  /** fetch data from the table: "candidate_pdl" */
  candidate_pdl: Array<Candidate_Pdl>;
  /** fetch aggregated fields from the table: "candidate_pdl" */
  candidate_pdl_aggregate: Candidate_Pdl_Aggregate;
  /** fetch data from the table: "candidate_pdl" using primary key columns */
  candidate_pdl_by_pk: Maybe<Candidate_Pdl>;
  /** fetch data from the table in a streaming manner: "candidate_pdl" */
  candidate_pdl_stream: Array<Candidate_Pdl>;
  /** fetch data from the table: "candidate_referrals" */
  candidate_referrals: Array<Candidate_Referrals>;
  /** fetch aggregated fields from the table: "candidate_referrals" */
  candidate_referrals_aggregate: Candidate_Referrals_Aggregate;
  /** fetch data from the table: "candidate_referrals" using primary key columns */
  candidate_referrals_by_pk: Maybe<Candidate_Referrals>;
  /** fetch data from the table in a streaming manner: "candidate_referrals" */
  candidate_referrals_stream: Array<Candidate_Referrals>;
  /** fetch data from the table: "candidate_role" */
  candidate_role: Array<Candidate_Role>;
  /** fetch aggregated fields from the table: "candidate_role" */
  candidate_role_aggregate: Candidate_Role_Aggregate;
  /** fetch data from the table: "candidate_role" using primary key columns */
  candidate_role_by_pk: Maybe<Candidate_Role>;
  /** fetch data from the table: "candidate_role_skill" */
  candidate_role_skill: Array<Candidate_Role_Skill>;
  /** fetch aggregated fields from the table: "candidate_role_skill" */
  candidate_role_skill_aggregate: Candidate_Role_Skill_Aggregate;
  /** fetch data from the table: "candidate_role_skill" using primary key columns */
  candidate_role_skill_by_pk: Maybe<Candidate_Role_Skill>;
  /** fetch data from the table in a streaming manner: "candidate_role_skill" */
  candidate_role_skill_stream: Array<Candidate_Role_Skill>;
  /** fetch data from the table in a streaming manner: "candidate_role" */
  candidate_role_stream: Array<Candidate_Role>;
  /** fetch data from the table: "candidate_sdlc_experience_choices" */
  candidate_sdlc_experience_choices: Array<Candidate_Sdlc_Experience_Choices>;
  /** fetch aggregated fields from the table: "candidate_sdlc_experience_choices" */
  candidate_sdlc_experience_choices_aggregate: Candidate_Sdlc_Experience_Choices_Aggregate;
  /** fetch data from the table: "candidate_sdlc_experience_choices" using primary key columns */
  candidate_sdlc_experience_choices_by_pk: Maybe<Candidate_Sdlc_Experience_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_sdlc_experience_choices" */
  candidate_sdlc_experience_choices_stream: Array<Candidate_Sdlc_Experience_Choices>;
  /** fetch data from the table: "candidate_skill" */
  candidate_skill: Array<Candidate_Skill>;
  /** fetch aggregated fields from the table: "candidate_skill" */
  candidate_skill_aggregate: Candidate_Skill_Aggregate;
  /** fetch data from the table: "candidate_skill" using primary key columns */
  candidate_skill_by_pk: Maybe<Candidate_Skill>;
  /** fetch data from the table: "candidate_skill_comp_choices" */
  candidate_skill_comp_choices: Array<Candidate_Skill_Comp_Choices>;
  /** fetch aggregated fields from the table: "candidate_skill_comp_choices" */
  candidate_skill_comp_choices_aggregate: Candidate_Skill_Comp_Choices_Aggregate;
  /** fetch data from the table: "candidate_skill_comp_choices" using primary key columns */
  candidate_skill_comp_choices_by_pk: Maybe<Candidate_Skill_Comp_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_skill_comp_choices" */
  candidate_skill_comp_choices_stream: Array<Candidate_Skill_Comp_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_skill" */
  candidate_skill_stream: Array<Candidate_Skill>;
  /** fetch data from the table: "candidate_source_choices" */
  candidate_source_choices: Array<Candidate_Source_Choices>;
  /** fetch aggregated fields from the table: "candidate_source_choices" */
  candidate_source_choices_aggregate: Candidate_Source_Choices_Aggregate;
  /** fetch data from the table: "candidate_source_choices" using primary key columns */
  candidate_source_choices_by_pk: Maybe<Candidate_Source_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_source_choices" */
  candidate_source_choices_stream: Array<Candidate_Source_Choices>;
  /** fetch data from the table: "candidate_stage_choices" */
  candidate_stage_choices: Array<Candidate_Stage_Choices>;
  /** fetch aggregated fields from the table: "candidate_stage_choices" */
  candidate_stage_choices_aggregate: Candidate_Stage_Choices_Aggregate;
  /** fetch data from the table: "candidate_stage_choices" using primary key columns */
  candidate_stage_choices_by_pk: Maybe<Candidate_Stage_Choices>;
  /** fetch data from the table: "candidate_stage_choices_order" */
  candidate_stage_choices_order: Array<Candidate_Stage_Choices_Order>;
  /** fetch aggregated fields from the table: "candidate_stage_choices_order" */
  candidate_stage_choices_order_aggregate: Candidate_Stage_Choices_Order_Aggregate;
  /** fetch data from the table: "candidate_stage_choices_order" using primary key columns */
  candidate_stage_choices_order_by_pk: Maybe<Candidate_Stage_Choices_Order>;
  /** fetch data from the table in a streaming manner: "candidate_stage_choices_order" */
  candidate_stage_choices_order_stream: Array<Candidate_Stage_Choices_Order>;
  /** fetch data from the table in a streaming manner: "candidate_stage_choices" */
  candidate_stage_choices_stream: Array<Candidate_Stage_Choices>;
  /** fetch data from the table: "candidate_stage_log" */
  candidate_stage_log: Array<Candidate_Stage_Log>;
  /** fetch aggregated fields from the table: "candidate_stage_log" */
  candidate_stage_log_aggregate: Candidate_Stage_Log_Aggregate;
  /** fetch data from the table: "candidate_stage_log" using primary key columns */
  candidate_stage_log_by_pk: Maybe<Candidate_Stage_Log>;
  /** fetch data from the table in a streaming manner: "candidate_stage_log" */
  candidate_stage_log_stream: Array<Candidate_Stage_Log>;
  /** fetch data from the table: "candidate_stage_source_choices" */
  candidate_stage_source_choices: Array<Candidate_Stage_Source_Choices>;
  /** fetch aggregated fields from the table: "candidate_stage_source_choices" */
  candidate_stage_source_choices_aggregate: Candidate_Stage_Source_Choices_Aggregate;
  /** fetch data from the table: "candidate_stage_source_choices" using primary key columns */
  candidate_stage_source_choices_by_pk: Maybe<Candidate_Stage_Source_Choices>;
  /** fetch data from the table in a streaming manner: "candidate_stage_source_choices" */
  candidate_stage_source_choices_stream: Array<Candidate_Stage_Source_Choices>;
  /** fetch data from the table in a streaming manner: "candidate" */
  candidate_stream: Array<Candidate>;
  /** execute function "candidate_suggested_job" which returns "icims_job" */
  candidate_suggested_job: Array<Icims_Job>;
  /** execute function "candidate_suggested_job" and query aggregates on result of table type "icims_job" */
  candidate_suggested_job_aggregate: Icims_Job_Aggregate;
  /** execute function "candidate_suggested_job_core" which returns "icims_job" */
  candidate_suggested_job_core: Array<Icims_Job>;
  /** execute function "candidate_suggested_job_core" and query aggregates on result of table type "icims_job" */
  candidate_suggested_job_core_aggregate: Icims_Job_Aggregate;
  /** fetch data from the table: "candidate_trait" */
  candidate_trait: Array<Candidate_Trait>;
  /** fetch aggregated fields from the table: "candidate_trait" */
  candidate_trait_aggregate: Candidate_Trait_Aggregate;
  /** fetch data from the table: "candidate_trait" using primary key columns */
  candidate_trait_by_pk: Maybe<Candidate_Trait>;
  /** fetch data from the table in a streaming manner: "candidate_trait" */
  candidate_trait_stream: Array<Candidate_Trait>;
  /** fetch data from the table: "candidate_work_experience" */
  candidate_work_experience: Array<Candidate_Work_Experience>;
  /** fetch aggregated fields from the table: "candidate_work_experience" */
  candidate_work_experience_aggregate: Candidate_Work_Experience_Aggregate;
  /** fetch data from the table: "candidate_work_experience" using primary key columns */
  candidate_work_experience_by_pk: Maybe<Candidate_Work_Experience>;
  /** fetch data from the table: "candidate_work_experience_skill" */
  candidate_work_experience_skill: Array<Candidate_Work_Experience_Skill>;
  /** fetch aggregated fields from the table: "candidate_work_experience_skill" */
  candidate_work_experience_skill_aggregate: Candidate_Work_Experience_Skill_Aggregate;
  /** fetch data from the table: "candidate_work_experience_skill" using primary key columns */
  candidate_work_experience_skill_by_pk: Maybe<Candidate_Work_Experience_Skill>;
  /** fetch data from the table in a streaming manner: "candidate_work_experience_skill" */
  candidate_work_experience_skill_stream: Array<Candidate_Work_Experience_Skill>;
  /** fetch data from the table in a streaming manner: "candidate_work_experience" */
  candidate_work_experience_stream: Array<Candidate_Work_Experience>;
  /** execute function "candidates_in_interview_or_rejected_by_organization" which returns "candidates_in_interview_or_rejected_by_organization_result" */
  candidates_in_interview_or_rejected_by_organization: Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result>;
  /** execute function "candidates_in_interview_or_rejected_by_organization" and query aggregates on result of table type "candidates_in_interview_or_rejected_by_organization_result" */
  candidates_in_interview_or_rejected_by_organization_aggregate: Candidates_In_Interview_Or_Rejected_By_Organization_Result_Aggregate;
  /** fetch data from the table: "candidates_in_interview_or_rejected_by_organization_result" */
  candidates_in_interview_or_rejected_by_organization_result: Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result>;
  /** fetch aggregated fields from the table: "candidates_in_interview_or_rejected_by_organization_result" */
  candidates_in_interview_or_rejected_by_organization_result_aggregate: Candidates_In_Interview_Or_Rejected_By_Organization_Result_Aggregate;
  /** fetch data from the table in a streaming manner: "candidates_in_interview_or_rejected_by_organization_result" */
  candidates_in_interview_or_rejected_by_organization_result_stream: Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result>;
  /** fetch data from the table: "celery.celery_taskmeta" */
  celery_celery_taskmeta: Array<Celery_Celery_Taskmeta>;
  /** fetch aggregated fields from the table: "celery.celery_taskmeta" */
  celery_celery_taskmeta_aggregate: Celery_Celery_Taskmeta_Aggregate;
  /** fetch data from the table: "celery.celery_taskmeta" using primary key columns */
  celery_celery_taskmeta_by_pk: Maybe<Celery_Celery_Taskmeta>;
  /** fetch data from the table in a streaming manner: "celery.celery_taskmeta" */
  celery_celery_taskmeta_stream: Array<Celery_Celery_Taskmeta>;
  /** fetch data from the table: "celery.celery_tasksetmeta" */
  celery_celery_tasksetmeta: Array<Celery_Celery_Tasksetmeta>;
  /** fetch aggregated fields from the table: "celery.celery_tasksetmeta" */
  celery_celery_tasksetmeta_aggregate: Celery_Celery_Tasksetmeta_Aggregate;
  /** fetch data from the table: "celery.celery_tasksetmeta" using primary key columns */
  celery_celery_tasksetmeta_by_pk: Maybe<Celery_Celery_Tasksetmeta>;
  /** fetch data from the table in a streaming manner: "celery.celery_tasksetmeta" */
  celery_celery_tasksetmeta_stream: Array<Celery_Celery_Tasksetmeta>;
  /** fetch data from the table: "celery.task_retry_log" */
  celery_task_retry_log: Array<Celery_Task_Retry_Log>;
  /** fetch aggregated fields from the table: "celery.task_retry_log" */
  celery_task_retry_log_aggregate: Celery_Task_Retry_Log_Aggregate;
  /** fetch data from the table: "celery.task_retry_log" using primary key columns */
  celery_task_retry_log_by_pk: Maybe<Celery_Task_Retry_Log>;
  /** fetch data from the table in a streaming manner: "celery.task_retry_log" */
  celery_task_retry_log_stream: Array<Celery_Task_Retry_Log>;
  /** fetch data from the table: "client_prospect" */
  client_prospect: Array<Client_Prospect>;
  /** fetch aggregated fields from the table: "client_prospect" */
  client_prospect_aggregate: Client_Prospect_Aggregate;
  /** fetch data from the table: "client_prospect" using primary key columns */
  client_prospect_by_pk: Maybe<Client_Prospect>;
  /** fetch data from the table: "client_prospect_event" */
  client_prospect_event: Array<Client_Prospect_Event>;
  /** fetch aggregated fields from the table: "client_prospect_event" */
  client_prospect_event_aggregate: Client_Prospect_Event_Aggregate;
  /** fetch data from the table: "client_prospect_event" using primary key columns */
  client_prospect_event_by_pk: Maybe<Client_Prospect_Event>;
  /** fetch data from the table in a streaming manner: "client_prospect_event" */
  client_prospect_event_stream: Array<Client_Prospect_Event>;
  /** fetch data from the table: "client_prospect_events_choices" */
  client_prospect_events_choices: Array<Client_Prospect_Events_Choices>;
  /** fetch aggregated fields from the table: "client_prospect_events_choices" */
  client_prospect_events_choices_aggregate: Client_Prospect_Events_Choices_Aggregate;
  /** fetch data from the table: "client_prospect_events_choices" using primary key columns */
  client_prospect_events_choices_by_pk: Maybe<Client_Prospect_Events_Choices>;
  /** fetch data from the table in a streaming manner: "client_prospect_events_choices" */
  client_prospect_events_choices_stream: Array<Client_Prospect_Events_Choices>;
  /** fetch data from the table: "client_prospect_role" */
  client_prospect_role: Array<Client_Prospect_Role>;
  /** fetch aggregated fields from the table: "client_prospect_role" */
  client_prospect_role_aggregate: Client_Prospect_Role_Aggregate;
  /** fetch data from the table: "client_prospect_role" using primary key columns */
  client_prospect_role_by_pk: Maybe<Client_Prospect_Role>;
  /** fetch data from the table: "client_prospect_role_skill" */
  client_prospect_role_skill: Array<Client_Prospect_Role_Skill>;
  /** fetch aggregated fields from the table: "client_prospect_role_skill" */
  client_prospect_role_skill_aggregate: Client_Prospect_Role_Skill_Aggregate;
  /** fetch data from the table: "client_prospect_role_skill" using primary key columns */
  client_prospect_role_skill_by_pk: Maybe<Client_Prospect_Role_Skill>;
  /** fetch data from the table in a streaming manner: "client_prospect_role_skill" */
  client_prospect_role_skill_stream: Array<Client_Prospect_Role_Skill>;
  /** fetch data from the table in a streaming manner: "client_prospect_role" */
  client_prospect_role_stream: Array<Client_Prospect_Role>;
  /** fetch data from the table in a streaming manner: "client_prospect" */
  client_prospect_stream: Array<Client_Prospect>;
  /** fetch data from the table: "client_versions" */
  client_versions: Array<Client_Versions>;
  /** fetch aggregated fields from the table: "client_versions" */
  client_versions_aggregate: Client_Versions_Aggregate;
  /** fetch data from the table: "client_versions" using primary key columns */
  client_versions_by_pk: Maybe<Client_Versions>;
  /** fetch data from the table in a streaming manner: "client_versions" */
  client_versions_stream: Array<Client_Versions>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk: Maybe<Company>;
  /** fetch data from the table: "company_name_synonym" */
  company_name_synonym: Array<Company_Name_Synonym>;
  /** fetch aggregated fields from the table: "company_name_synonym" */
  company_name_synonym_aggregate: Company_Name_Synonym_Aggregate;
  /** fetch data from the table: "company_name_synonym" using primary key columns */
  company_name_synonym_by_pk: Maybe<Company_Name_Synonym>;
  /** fetch data from the table in a streaming manner: "company_name_synonym" */
  company_name_synonym_stream: Array<Company_Name_Synonym>;
  /** fetch data from the table: "company_pdl_blob" */
  company_pdl_blob: Array<Company_Pdl_Blob>;
  /** fetch aggregated fields from the table: "company_pdl_blob" */
  company_pdl_blob_aggregate: Company_Pdl_Blob_Aggregate;
  /** fetch data from the table: "company_pdl_blob" using primary key columns */
  company_pdl_blob_by_pk: Maybe<Company_Pdl_Blob>;
  /** fetch data from the table in a streaming manner: "company_pdl_blob" */
  company_pdl_blob_stream: Array<Company_Pdl_Blob>;
  /** fetch data from the table: "company_size_choices" */
  company_size_choices: Array<Company_Size_Choices>;
  /** fetch aggregated fields from the table: "company_size_choices" */
  company_size_choices_aggregate: Company_Size_Choices_Aggregate;
  /** fetch data from the table: "company_size_choices" using primary key columns */
  company_size_choices_by_pk: Maybe<Company_Size_Choices>;
  /** fetch data from the table in a streaming manner: "company_size_choices" */
  company_size_choices_stream: Array<Company_Size_Choices>;
  /** fetch data from the table in a streaming manner: "company" */
  company_stream: Array<Company>;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk: Maybe<Contact>;
  /** fetch data from the table in a streaming manner: "contact" */
  contact_stream: Array<Contact>;
  /** fetch data from the table: "contract_length_choices" */
  contract_length_choices: Array<Contract_Length_Choices>;
  /** fetch aggregated fields from the table: "contract_length_choices" */
  contract_length_choices_aggregate: Contract_Length_Choices_Aggregate;
  /** fetch data from the table: "contract_length_choices" using primary key columns */
  contract_length_choices_by_pk: Maybe<Contract_Length_Choices>;
  /** fetch data from the table in a streaming manner: "contract_length_choices" */
  contract_length_choices_stream: Array<Contract_Length_Choices>;
  /** fetch data from the table: "country_choices" */
  country_choices: Array<Country_Choices>;
  /** fetch aggregated fields from the table: "country_choices" */
  country_choices_aggregate: Country_Choices_Aggregate;
  /** fetch data from the table: "country_choices" using primary key columns */
  country_choices_by_pk: Maybe<Country_Choices>;
  /** fetch data from the table in a streaming manner: "country_choices" */
  country_choices_stream: Array<Country_Choices>;
  /** fetch data from the table: "currency_exchange_rate" */
  currency_exchange_rate: Array<Currency_Exchange_Rate>;
  /** fetch aggregated fields from the table: "currency_exchange_rate" */
  currency_exchange_rate_aggregate: Currency_Exchange_Rate_Aggregate;
  /** fetch data from the table: "currency_exchange_rate" using primary key columns */
  currency_exchange_rate_by_pk: Maybe<Currency_Exchange_Rate>;
  /** fetch data from the table in a streaming manner: "currency_exchange_rate" */
  currency_exchange_rate_stream: Array<Currency_Exchange_Rate>;
  /** fetch data from the table: "dayforce_assignment" */
  dayforce_assignment: Array<Dayforce_Assignment>;
  /** fetch aggregated fields from the table: "dayforce_assignment" */
  dayforce_assignment_aggregate: Dayforce_Assignment_Aggregate;
  /** fetch data from the table: "dayforce_assignment" using primary key columns */
  dayforce_assignment_by_pk: Maybe<Dayforce_Assignment>;
  /** fetch data from the table in a streaming manner: "dayforce_assignment" */
  dayforce_assignment_stream: Array<Dayforce_Assignment>;
  /** fetch data from the table: "dayforce_customer" */
  dayforce_customer: Array<Dayforce_Customer>;
  /** fetch aggregated fields from the table: "dayforce_customer" */
  dayforce_customer_aggregate: Dayforce_Customer_Aggregate;
  /** fetch data from the table: "dayforce_customer" using primary key columns */
  dayforce_customer_by_pk: Maybe<Dayforce_Customer>;
  /** fetch data from the table in a streaming manner: "dayforce_customer" */
  dayforce_customer_stream: Array<Dayforce_Customer>;
  /** fetch data from the table: "dayforce_employee" */
  dayforce_employee: Array<Dayforce_Employee>;
  /** fetch aggregated fields from the table: "dayforce_employee" */
  dayforce_employee_aggregate: Dayforce_Employee_Aggregate;
  /** fetch data from the table: "dayforce_employee" using primary key columns */
  dayforce_employee_by_pk: Maybe<Dayforce_Employee>;
  /** fetch data from the table in a streaming manner: "dayforce_employee" */
  dayforce_employee_stream: Array<Dayforce_Employee>;
  /** fetch data from the table: "dayforce_location" */
  dayforce_location: Array<Dayforce_Location>;
  /** fetch aggregated fields from the table: "dayforce_location" */
  dayforce_location_aggregate: Dayforce_Location_Aggregate;
  /** fetch data from the table: "dayforce_location" using primary key columns */
  dayforce_location_by_pk: Maybe<Dayforce_Location>;
  /** fetch data from the table in a streaming manner: "dayforce_location" */
  dayforce_location_stream: Array<Dayforce_Location>;
  /** fetch data from the table: "email_notification" */
  email_notification: Array<Email_Notification>;
  /** fetch aggregated fields from the table: "email_notification" */
  email_notification_aggregate: Email_Notification_Aggregate;
  /** fetch data from the table: "email_notification" using primary key columns */
  email_notification_by_pk: Maybe<Email_Notification>;
  /** fetch data from the table in a streaming manner: "email_notification" */
  email_notification_stream: Array<Email_Notification>;
  /** fetch data from the table: "email_template" */
  email_template: Array<Email_Template>;
  /** fetch aggregated fields from the table: "email_template" */
  email_template_aggregate: Email_Template_Aggregate;
  /** fetch data from the table: "email_template" using primary key columns */
  email_template_by_pk: Maybe<Email_Template>;
  /** fetch data from the table in a streaming manner: "email_template" */
  email_template_stream: Array<Email_Template>;
  /** fetch data from the table: "employment_type_choices" */
  employment_type_choices: Array<Employment_Type_Choices>;
  /** fetch aggregated fields from the table: "employment_type_choices" */
  employment_type_choices_aggregate: Employment_Type_Choices_Aggregate;
  /** fetch data from the table: "employment_type_choices" using primary key columns */
  employment_type_choices_by_pk: Maybe<Employment_Type_Choices>;
  /** fetch data from the table in a streaming manner: "employment_type_choices" */
  employment_type_choices_stream: Array<Employment_Type_Choices>;
  /** fetch data from the table: "explore_candidates_popular_search" */
  explore_candidates_popular_search: Array<Explore_Candidates_Popular_Search>;
  /** fetch aggregated fields from the table: "explore_candidates_popular_search" */
  explore_candidates_popular_search_aggregate: Explore_Candidates_Popular_Search_Aggregate;
  /** fetch data from the table: "explore_candidates_popular_search" using primary key columns */
  explore_candidates_popular_search_by_pk: Maybe<Explore_Candidates_Popular_Search>;
  /** fetch data from the table in a streaming manner: "explore_candidates_popular_search" */
  explore_candidates_popular_search_stream: Array<Explore_Candidates_Popular_Search>;
  /** execute function "explore_candidates_query" which returns "explore_candidates_query_result" */
  explore_candidates_query: Array<Explore_Candidates_Query_Result>;
  /** execute function "explore_candidates_query" and query aggregates on result of table type "explore_candidates_query_result" */
  explore_candidates_query_aggregate: Explore_Candidates_Query_Result_Aggregate;
  /** fetch data from the table: "explore_candidates_query_result" */
  explore_candidates_query_result: Array<Explore_Candidates_Query_Result>;
  /** fetch aggregated fields from the table: "explore_candidates_query_result" */
  explore_candidates_query_result_aggregate: Explore_Candidates_Query_Result_Aggregate;
  /** fetch data from the table: "explore_candidates_query_result" using primary key columns */
  explore_candidates_query_result_by_pk: Maybe<Explore_Candidates_Query_Result>;
  /** fetch data from the table in a streaming manner: "explore_candidates_query_result" */
  explore_candidates_query_result_stream: Array<Explore_Candidates_Query_Result>;
  /** fetch data from the table: "explore_candidates_search" */
  explore_candidates_search: Array<Explore_Candidates_Search>;
  /** fetch aggregated fields from the table: "explore_candidates_search" */
  explore_candidates_search_aggregate: Explore_Candidates_Search_Aggregate;
  /** fetch data from the table: "explore_candidates_search" using primary key columns */
  explore_candidates_search_by_pk: Maybe<Explore_Candidates_Search>;
  /** fetch data from the table: "explore_candidates_search_candidate_highlight" */
  explore_candidates_search_candidate_highlight: Array<Explore_Candidates_Search_Candidate_Highlight>;
  /** fetch aggregated fields from the table: "explore_candidates_search_candidate_highlight" */
  explore_candidates_search_candidate_highlight_aggregate: Explore_Candidates_Search_Candidate_Highlight_Aggregate;
  /** fetch data from the table: "explore_candidates_search_candidate_highlight" using primary key columns */
  explore_candidates_search_candidate_highlight_by_pk: Maybe<Explore_Candidates_Search_Candidate_Highlight>;
  /** fetch data from the table in a streaming manner: "explore_candidates_search_candidate_highlight" */
  explore_candidates_search_candidate_highlight_stream: Array<Explore_Candidates_Search_Candidate_Highlight>;
  /** fetch data from the table: "explore_candidates_search_must_have_skill" */
  explore_candidates_search_must_have_skill: Array<Explore_Candidates_Search_Must_Have_Skill>;
  /** fetch aggregated fields from the table: "explore_candidates_search_must_have_skill" */
  explore_candidates_search_must_have_skill_aggregate: Explore_Candidates_Search_Must_Have_Skill_Aggregate;
  /** fetch data from the table: "explore_candidates_search_must_have_skill" using primary key columns */
  explore_candidates_search_must_have_skill_by_pk: Maybe<Explore_Candidates_Search_Must_Have_Skill>;
  /** fetch data from the table in a streaming manner: "explore_candidates_search_must_have_skill" */
  explore_candidates_search_must_have_skill_stream: Array<Explore_Candidates_Search_Must_Have_Skill>;
  /** fetch data from the table: "explore_candidates_search_nice_to_have_skill" */
  explore_candidates_search_nice_to_have_skill: Array<Explore_Candidates_Search_Nice_To_Have_Skill>;
  /** fetch aggregated fields from the table: "explore_candidates_search_nice_to_have_skill" */
  explore_candidates_search_nice_to_have_skill_aggregate: Explore_Candidates_Search_Nice_To_Have_Skill_Aggregate;
  /** fetch data from the table: "explore_candidates_search_nice_to_have_skill" using primary key columns */
  explore_candidates_search_nice_to_have_skill_by_pk: Maybe<Explore_Candidates_Search_Nice_To_Have_Skill>;
  /** fetch data from the table in a streaming manner: "explore_candidates_search_nice_to_have_skill" */
  explore_candidates_search_nice_to_have_skill_stream: Array<Explore_Candidates_Search_Nice_To_Have_Skill>;
  /** fetch data from the table: "explore_candidates_search_region" */
  explore_candidates_search_region: Array<Explore_Candidates_Search_Region>;
  /** fetch aggregated fields from the table: "explore_candidates_search_region" */
  explore_candidates_search_region_aggregate: Explore_Candidates_Search_Region_Aggregate;
  /** fetch data from the table: "explore_candidates_search_region" using primary key columns */
  explore_candidates_search_region_by_pk: Maybe<Explore_Candidates_Search_Region>;
  /** fetch data from the table in a streaming manner: "explore_candidates_search_region" */
  explore_candidates_search_region_stream: Array<Explore_Candidates_Search_Region>;
  /** fetch data from the table in a streaming manner: "explore_candidates_search" */
  explore_candidates_search_stream: Array<Explore_Candidates_Search>;
  /** fetch data from the table: "explore_candidates_user_saved_search" */
  explore_candidates_user_saved_search: Array<Explore_Candidates_User_Saved_Search>;
  /** fetch aggregated fields from the table: "explore_candidates_user_saved_search" */
  explore_candidates_user_saved_search_aggregate: Explore_Candidates_User_Saved_Search_Aggregate;
  /** fetch data from the table: "explore_candidates_user_saved_search" using primary key columns */
  explore_candidates_user_saved_search_by_pk: Maybe<Explore_Candidates_User_Saved_Search>;
  /** fetch data from the table in a streaming manner: "explore_candidates_user_saved_search" */
  explore_candidates_user_saved_search_stream: Array<Explore_Candidates_User_Saved_Search>;
  /** fetch data from the table: "external_candidate_meta" */
  external_candidate_meta: Array<External_Candidate_Meta>;
  /** fetch aggregated fields from the table: "external_candidate_meta" */
  external_candidate_meta_aggregate: External_Candidate_Meta_Aggregate;
  /** fetch data from the table: "external_candidate_meta" using primary key columns */
  external_candidate_meta_by_pk: Maybe<External_Candidate_Meta>;
  /** fetch data from the table in a streaming manner: "external_candidate_meta" */
  external_candidate_meta_stream: Array<External_Candidate_Meta>;
  /** fetch data from the table: "feature" */
  feature: Array<Feature>;
  /** fetch aggregated fields from the table: "feature" */
  feature_aggregate: Feature_Aggregate;
  /** fetch data from the table: "feature" using primary key columns */
  feature_by_pk: Maybe<Feature>;
  /** fetch data from the table in a streaming manner: "feature" */
  feature_stream: Array<Feature>;
  /** fetch data from the table: "hireflix_transcript" */
  hireflix_transcript: Array<Hireflix_Transcript>;
  /** fetch aggregated fields from the table: "hireflix_transcript" */
  hireflix_transcript_aggregate: Hireflix_Transcript_Aggregate;
  /** fetch data from the table: "hireflix_transcript_blob" */
  hireflix_transcript_blob: Array<Hireflix_Transcript_Blob>;
  /** fetch aggregated fields from the table: "hireflix_transcript_blob" */
  hireflix_transcript_blob_aggregate: Hireflix_Transcript_Blob_Aggregate;
  /** fetch data from the table: "hireflix_transcript_blob" using primary key columns */
  hireflix_transcript_blob_by_pk: Maybe<Hireflix_Transcript_Blob>;
  /** fetch data from the table in a streaming manner: "hireflix_transcript_blob" */
  hireflix_transcript_blob_stream: Array<Hireflix_Transcript_Blob>;
  /** fetch data from the table: "hireflix_transcript" using primary key columns */
  hireflix_transcript_by_pk: Maybe<Hireflix_Transcript>;
  /** fetch data from the table in a streaming manner: "hireflix_transcript" */
  hireflix_transcript_stream: Array<Hireflix_Transcript>;
  /** fetch data from the table: "hr_test" */
  hr_test: Array<Hr_Test>;
  /** fetch aggregated fields from the table: "hr_test" */
  hr_test_aggregate: Hr_Test_Aggregate;
  /** fetch data from the table: "hr_test" using primary key columns */
  hr_test_by_pk: Maybe<Hr_Test>;
  /** fetch data from the table: "hr_test_candidate" */
  hr_test_candidate: Array<Hr_Test_Candidate>;
  /** fetch aggregated fields from the table: "hr_test_candidate" */
  hr_test_candidate_aggregate: Hr_Test_Candidate_Aggregate;
  /** fetch data from the table: "hr_test_candidate" using primary key columns */
  hr_test_candidate_by_pk: Maybe<Hr_Test_Candidate>;
  /** fetch data from the table in a streaming manner: "hr_test_candidate" */
  hr_test_candidate_stream: Array<Hr_Test_Candidate>;
  /** fetch data from the table: "hr_test_result" */
  hr_test_result: Array<Hr_Test_Result>;
  /** fetch aggregated fields from the table: "hr_test_result" */
  hr_test_result_aggregate: Hr_Test_Result_Aggregate;
  /** fetch data from the table: "hr_test_result" using primary key columns */
  hr_test_result_by_pk: Maybe<Hr_Test_Result>;
  /** fetch data from the table in a streaming manner: "hr_test_result" */
  hr_test_result_stream: Array<Hr_Test_Result>;
  /** fetch data from the table in a streaming manner: "hr_test" */
  hr_test_stream: Array<Hr_Test>;
  /** fetch data from the table: "icims_applicant_workflow" */
  icims_applicant_workflow: Array<Icims_Applicant_Workflow>;
  /** fetch aggregated fields from the table: "icims_applicant_workflow" */
  icims_applicant_workflow_aggregate: Icims_Applicant_Workflow_Aggregate;
  /** fetch data from the table: "icims_applicant_workflow" using primary key columns */
  icims_applicant_workflow_by_pk: Maybe<Icims_Applicant_Workflow>;
  /** fetch data from the table: "icims_applicant_workflow_feedback" */
  icims_applicant_workflow_feedback: Array<Icims_Applicant_Workflow_Feedback>;
  /** fetch aggregated fields from the table: "icims_applicant_workflow_feedback" */
  icims_applicant_workflow_feedback_aggregate: Icims_Applicant_Workflow_Feedback_Aggregate;
  /** fetch data from the table: "icims_applicant_workflow_feedback" using primary key columns */
  icims_applicant_workflow_feedback_by_pk: Maybe<Icims_Applicant_Workflow_Feedback>;
  /** fetch data from the table in a streaming manner: "icims_applicant_workflow_feedback" */
  icims_applicant_workflow_feedback_stream: Array<Icims_Applicant_Workflow_Feedback>;
  /** fetch data from the table: "icims_applicant_workflow_status" */
  icims_applicant_workflow_status: Array<Icims_Applicant_Workflow_Status>;
  /** fetch aggregated fields from the table: "icims_applicant_workflow_status" */
  icims_applicant_workflow_status_aggregate: Icims_Applicant_Workflow_Status_Aggregate;
  /** fetch data from the table: "icims_applicant_workflow_status" using primary key columns */
  icims_applicant_workflow_status_by_pk: Maybe<Icims_Applicant_Workflow_Status>;
  /** execute function "icims_applicant_workflow_status_current" which returns "icims_applicant_workflow_status" */
  icims_applicant_workflow_status_current: Array<Icims_Applicant_Workflow_Status>;
  /** execute function "icims_applicant_workflow_status_current" and query aggregates on result of table type "icims_applicant_workflow_status" */
  icims_applicant_workflow_status_current_aggregate: Icims_Applicant_Workflow_Status_Aggregate;
  /** fetch data from the table in a streaming manner: "icims_applicant_workflow_status" */
  icims_applicant_workflow_status_stream: Array<Icims_Applicant_Workflow_Status>;
  /** fetch data from the table in a streaming manner: "icims_applicant_workflow" */
  icims_applicant_workflow_stream: Array<Icims_Applicant_Workflow>;
  /** fetch data from the table: "icims_company" */
  icims_company: Array<Icims_Company>;
  /** fetch aggregated fields from the table: "icims_company" */
  icims_company_aggregate: Icims_Company_Aggregate;
  /** fetch data from the table: "icims_company" using primary key columns */
  icims_company_by_pk: Maybe<Icims_Company>;
  /** fetch data from the table in a streaming manner: "icims_company" */
  icims_company_stream: Array<Icims_Company>;
  /** fetch data from the table: "icims_country" */
  icims_country: Array<Icims_Country>;
  /** fetch aggregated fields from the table: "icims_country" */
  icims_country_aggregate: Icims_Country_Aggregate;
  /** fetch data from the table: "icims_country" using primary key columns */
  icims_country_by_pk: Maybe<Icims_Country>;
  /** fetch data from the table in a streaming manner: "icims_country" */
  icims_country_stream: Array<Icims_Country>;
  /** fetch data from the table: "icims_degree" */
  icims_degree: Array<Icims_Degree>;
  /** fetch aggregated fields from the table: "icims_degree" */
  icims_degree_aggregate: Icims_Degree_Aggregate;
  /** fetch data from the table: "icims_degree" using primary key columns */
  icims_degree_by_pk: Maybe<Icims_Degree>;
  /** fetch data from the table in a streaming manner: "icims_degree" */
  icims_degree_stream: Array<Icims_Degree>;
  /** fetch data from the table: "icims_education" */
  icims_education: Array<Icims_Education>;
  /** fetch aggregated fields from the table: "icims_education" */
  icims_education_aggregate: Icims_Education_Aggregate;
  /** fetch data from the table: "icims_education" using primary key columns */
  icims_education_by_pk: Maybe<Icims_Education>;
  /** fetch data from the table in a streaming manner: "icims_education" */
  icims_education_stream: Array<Icims_Education>;
  /** fetch data from the table: "icims_folder" */
  icims_folder: Array<Icims_Folder>;
  /** fetch aggregated fields from the table: "icims_folder" */
  icims_folder_aggregate: Icims_Folder_Aggregate;
  /** fetch data from the table: "icims_folder" using primary key columns */
  icims_folder_by_pk: Maybe<Icims_Folder>;
  /** fetch data from the table in a streaming manner: "icims_folder" */
  icims_folder_stream: Array<Icims_Folder>;
  /** fetch data from the table: "icims_initial_data_transfer" */
  icims_initial_data_transfer: Array<Icims_Initial_Data_Transfer>;
  /** fetch aggregated fields from the table: "icims_initial_data_transfer" */
  icims_initial_data_transfer_aggregate: Icims_Initial_Data_Transfer_Aggregate;
  /** fetch data from the table: "icims_initial_data_transfer" using primary key columns */
  icims_initial_data_transfer_by_pk: Maybe<Icims_Initial_Data_Transfer>;
  /** fetch data from the table in a streaming manner: "icims_initial_data_transfer" */
  icims_initial_data_transfer_stream: Array<Icims_Initial_Data_Transfer>;
  /** fetch data from the table: "icims_interested_location" */
  icims_interested_location: Array<Icims_Interested_Location>;
  /** fetch aggregated fields from the table: "icims_interested_location" */
  icims_interested_location_aggregate: Icims_Interested_Location_Aggregate;
  /** fetch data from the table: "icims_interested_location" using primary key columns */
  icims_interested_location_by_pk: Maybe<Icims_Interested_Location>;
  /** fetch data from the table in a streaming manner: "icims_interested_location" */
  icims_interested_location_stream: Array<Icims_Interested_Location>;
  /** fetch data from the table: "icims_job" */
  icims_job: Array<Icims_Job>;
  /** fetch aggregated fields from the table: "icims_job" */
  icims_job_aggregate: Icims_Job_Aggregate;
  /** fetch data from the table: "icims_job" using primary key columns */
  icims_job_by_pk: Maybe<Icims_Job>;
  /** fetch data from the table: "icims_job_location" */
  icims_job_location: Array<Icims_Job_Location>;
  /** fetch aggregated fields from the table: "icims_job_location" */
  icims_job_location_aggregate: Icims_Job_Location_Aggregate;
  /** fetch data from the table: "icims_job_location" using primary key columns */
  icims_job_location_by_pk: Maybe<Icims_Job_Location>;
  /** fetch data from the table in a streaming manner: "icims_job_location" */
  icims_job_location_stream: Array<Icims_Job_Location>;
  /** fetch data from the table: "icims_job_requested_location" */
  icims_job_requested_location: Array<Icims_Job_Requested_Location>;
  /** fetch aggregated fields from the table: "icims_job_requested_location" */
  icims_job_requested_location_aggregate: Icims_Job_Requested_Location_Aggregate;
  /** fetch data from the table: "icims_job_requested_location" using primary key columns */
  icims_job_requested_location_by_pk: Maybe<Icims_Job_Requested_Location>;
  /** fetch data from the table in a streaming manner: "icims_job_requested_location" */
  icims_job_requested_location_stream: Array<Icims_Job_Requested_Location>;
  /** fetch data from the table: "icims_job_skill" */
  icims_job_skill: Array<Icims_Job_Skill>;
  /** fetch aggregated fields from the table: "icims_job_skill" */
  icims_job_skill_aggregate: Icims_Job_Skill_Aggregate;
  /** fetch data from the table: "icims_job_skill" using primary key columns */
  icims_job_skill_by_pk: Maybe<Icims_Job_Skill>;
  /** fetch data from the table in a streaming manner: "icims_job_skill" */
  icims_job_skill_stream: Array<Icims_Job_Skill>;
  /** fetch data from the table in a streaming manner: "icims_job" */
  icims_job_stream: Array<Icims_Job>;
  /** fetch data from the table: "icims_location" */
  icims_location: Array<Icims_Location>;
  /** fetch aggregated fields from the table: "icims_location" */
  icims_location_aggregate: Icims_Location_Aggregate;
  /** fetch data from the table: "icims_location" using primary key columns */
  icims_location_by_pk: Maybe<Icims_Location>;
  /** fetch data from the table in a streaming manner: "icims_location" */
  icims_location_stream: Array<Icims_Location>;
  /** fetch data from the table: "icims_major" */
  icims_major: Array<Icims_Major>;
  /** fetch aggregated fields from the table: "icims_major" */
  icims_major_aggregate: Icims_Major_Aggregate;
  /** fetch data from the table: "icims_major" using primary key columns */
  icims_major_by_pk: Maybe<Icims_Major>;
  /** fetch data from the table in a streaming manner: "icims_major" */
  icims_major_stream: Array<Icims_Major>;
  /** fetch data from the table: "icims_person" */
  icims_person: Array<Icims_Person>;
  /** fetch aggregated fields from the table: "icims_person" */
  icims_person_aggregate: Icims_Person_Aggregate;
  /** fetch data from the table: "icims_person" using primary key columns */
  icims_person_by_pk: Maybe<Icims_Person>;
  /** fetch data from the table: "icims_person_interested_location" */
  icims_person_interested_location: Array<Icims_Person_Interested_Location>;
  /** fetch aggregated fields from the table: "icims_person_interested_location" */
  icims_person_interested_location_aggregate: Icims_Person_Interested_Location_Aggregate;
  /** fetch data from the table: "icims_person_interested_location" using primary key columns */
  icims_person_interested_location_by_pk: Maybe<Icims_Person_Interested_Location>;
  /** fetch data from the table in a streaming manner: "icims_person_interested_location" */
  icims_person_interested_location_stream: Array<Icims_Person_Interested_Location>;
  /** fetch data from the table: "icims_person_preferred_location" */
  icims_person_preferred_location: Array<Icims_Person_Preferred_Location>;
  /** fetch aggregated fields from the table: "icims_person_preferred_location" */
  icims_person_preferred_location_aggregate: Icims_Person_Preferred_Location_Aggregate;
  /** fetch data from the table: "icims_person_preferred_location" using primary key columns */
  icims_person_preferred_location_by_pk: Maybe<Icims_Person_Preferred_Location>;
  /** fetch data from the table in a streaming manner: "icims_person_preferred_location" */
  icims_person_preferred_location_stream: Array<Icims_Person_Preferred_Location>;
  /** fetch data from the table: "icims_person_skill" */
  icims_person_skill: Array<Icims_Person_Skill>;
  /** fetch aggregated fields from the table: "icims_person_skill" */
  icims_person_skill_aggregate: Icims_Person_Skill_Aggregate;
  /** fetch data from the table: "icims_person_skill" using primary key columns */
  icims_person_skill_by_pk: Maybe<Icims_Person_Skill>;
  /** fetch data from the table in a streaming manner: "icims_person_skill" */
  icims_person_skill_stream: Array<Icims_Person_Skill>;
  /** fetch data from the table in a streaming manner: "icims_person" */
  icims_person_stream: Array<Icims_Person>;
  /** fetch data from the table: "icims_profile_applicant_workflow" */
  icims_profile_applicant_workflow: Array<Icims_Profile_Applicant_Workflow>;
  /** fetch aggregated fields from the table: "icims_profile_applicant_workflow" */
  icims_profile_applicant_workflow_aggregate: Icims_Profile_Applicant_Workflow_Aggregate;
  /** fetch data from the table: "icims_profile_applicant_workflow" using primary key columns */
  icims_profile_applicant_workflow_by_pk: Maybe<Icims_Profile_Applicant_Workflow>;
  /** fetch data from the table in a streaming manner: "icims_profile_applicant_workflow" */
  icims_profile_applicant_workflow_stream: Array<Icims_Profile_Applicant_Workflow>;
  /** fetch data from the table: "icims_profile_job" */
  icims_profile_job: Array<Icims_Profile_Job>;
  /** fetch aggregated fields from the table: "icims_profile_job" */
  icims_profile_job_aggregate: Icims_Profile_Job_Aggregate;
  /** fetch data from the table: "icims_profile_job" using primary key columns */
  icims_profile_job_by_pk: Maybe<Icims_Profile_Job>;
  /** fetch data from the table in a streaming manner: "icims_profile_job" */
  icims_profile_job_stream: Array<Icims_Profile_Job>;
  /** fetch data from the table: "icims_profile_location" */
  icims_profile_location: Array<Icims_Profile_Location>;
  /** fetch aggregated fields from the table: "icims_profile_location" */
  icims_profile_location_aggregate: Icims_Profile_Location_Aggregate;
  /** fetch data from the table: "icims_profile_location" using primary key columns */
  icims_profile_location_by_pk: Maybe<Icims_Profile_Location>;
  /** fetch data from the table in a streaming manner: "icims_profile_location" */
  icims_profile_location_stream: Array<Icims_Profile_Location>;
  /** fetch data from the table: "icims_profile_person" */
  icims_profile_person: Array<Icims_Profile_Person>;
  /** fetch aggregated fields from the table: "icims_profile_person" */
  icims_profile_person_aggregate: Icims_Profile_Person_Aggregate;
  /** fetch data from the table: "icims_profile_person" using primary key columns */
  icims_profile_person_by_pk: Maybe<Icims_Profile_Person>;
  /** fetch data from the table in a streaming manner: "icims_profile_person" */
  icims_profile_person_stream: Array<Icims_Profile_Person>;
  /** fetch data from the table: "icims_profile_requested_location" */
  icims_profile_requested_location: Array<Icims_Profile_Requested_Location>;
  /** fetch aggregated fields from the table: "icims_profile_requested_location" */
  icims_profile_requested_location_aggregate: Icims_Profile_Requested_Location_Aggregate;
  /** fetch data from the table: "icims_profile_requested_location" using primary key columns */
  icims_profile_requested_location_by_pk: Maybe<Icims_Profile_Requested_Location>;
  /** fetch data from the table in a streaming manner: "icims_profile_requested_location" */
  icims_profile_requested_location_stream: Array<Icims_Profile_Requested_Location>;
  /** fetch data from the table: "icims_requested_location" */
  icims_requested_location: Array<Icims_Requested_Location>;
  /** fetch aggregated fields from the table: "icims_requested_location" */
  icims_requested_location_aggregate: Icims_Requested_Location_Aggregate;
  /** fetch data from the table: "icims_requested_location" using primary key columns */
  icims_requested_location_by_pk: Maybe<Icims_Requested_Location>;
  /** fetch data from the table in a streaming manner: "icims_requested_location" */
  icims_requested_location_stream: Array<Icims_Requested_Location>;
  /** fetch data from the table: "icims_school" */
  icims_school: Array<Icims_School>;
  /** fetch aggregated fields from the table: "icims_school" */
  icims_school_aggregate: Icims_School_Aggregate;
  /** fetch data from the table: "icims_school" using primary key columns */
  icims_school_by_pk: Maybe<Icims_School>;
  /** fetch data from the table in a streaming manner: "icims_school" */
  icims_school_stream: Array<Icims_School>;
  /** fetch data from the table: "icims_skill" */
  icims_skill: Array<Icims_Skill>;
  /** fetch aggregated fields from the table: "icims_skill" */
  icims_skill_aggregate: Icims_Skill_Aggregate;
  /** fetch data from the table: "icims_skill" using primary key columns */
  icims_skill_by_pk: Maybe<Icims_Skill>;
  /** fetch data from the table in a streaming manner: "icims_skill" */
  icims_skill_stream: Array<Icims_Skill>;
  /** fetch data from the table: "icims_status" */
  icims_status: Array<Icims_Status>;
  /** fetch aggregated fields from the table: "icims_status" */
  icims_status_aggregate: Icims_Status_Aggregate;
  /** fetch data from the table: "icims_status" using primary key columns */
  icims_status_by_pk: Maybe<Icims_Status>;
  /** fetch data from the table in a streaming manner: "icims_status" */
  icims_status_stream: Array<Icims_Status>;
  /** fetch data from the table: "icims_sync_status_choices" */
  icims_sync_status_choices: Array<Icims_Sync_Status_Choices>;
  /** fetch aggregated fields from the table: "icims_sync_status_choices" */
  icims_sync_status_choices_aggregate: Icims_Sync_Status_Choices_Aggregate;
  /** fetch data from the table: "icims_sync_status_choices" using primary key columns */
  icims_sync_status_choices_by_pk: Maybe<Icims_Sync_Status_Choices>;
  /** fetch data from the table in a streaming manner: "icims_sync_status_choices" */
  icims_sync_status_choices_stream: Array<Icims_Sync_Status_Choices>;
  /** fetch data from the table: "icims_work_experience" */
  icims_work_experience: Array<Icims_Work_Experience>;
  /** fetch aggregated fields from the table: "icims_work_experience" */
  icims_work_experience_aggregate: Icims_Work_Experience_Aggregate;
  /** fetch data from the table: "icims_work_experience" using primary key columns */
  icims_work_experience_by_pk: Maybe<Icims_Work_Experience>;
  /** fetch data from the table in a streaming manner: "icims_work_experience" */
  icims_work_experience_stream: Array<Icims_Work_Experience>;
  /** fetch data from the table: "industry" */
  industry: Array<Industry>;
  /** fetch aggregated fields from the table: "industry" */
  industry_aggregate: Industry_Aggregate;
  /** fetch data from the table: "industry" using primary key columns */
  industry_by_pk: Maybe<Industry>;
  /** fetch data from the table in a streaming manner: "industry" */
  industry_stream: Array<Industry>;
  /** fetch data from the table: "initial_sell_type_choices" */
  initial_sell_type_choices: Array<Initial_Sell_Type_Choices>;
  /** fetch aggregated fields from the table: "initial_sell_type_choices" */
  initial_sell_type_choices_aggregate: Initial_Sell_Type_Choices_Aggregate;
  /** fetch data from the table: "initial_sell_type_choices" using primary key columns */
  initial_sell_type_choices_by_pk: Maybe<Initial_Sell_Type_Choices>;
  /** fetch data from the table in a streaming manner: "initial_sell_type_choices" */
  initial_sell_type_choices_stream: Array<Initial_Sell_Type_Choices>;
  /** fetch data from the table: "interview_detail" */
  interview_detail: Array<Interview_Detail>;
  /** fetch aggregated fields from the table: "interview_detail" */
  interview_detail_aggregate: Interview_Detail_Aggregate;
  /** fetch data from the table: "interview_detail" using primary key columns */
  interview_detail_by_pk: Maybe<Interview_Detail>;
  /** fetch data from the table in a streaming manner: "interview_detail" */
  interview_detail_stream: Array<Interview_Detail>;
  /** fetch data from the table: "interview_feedback" */
  interview_feedback: Array<Interview_Feedback>;
  /** fetch aggregated fields from the table: "interview_feedback" */
  interview_feedback_aggregate: Interview_Feedback_Aggregate;
  /** fetch data from the table: "interview_feedback" using primary key columns */
  interview_feedback_by_pk: Maybe<Interview_Feedback>;
  /** fetch data from the table: "interview_feedback_rating_choices" */
  interview_feedback_rating_choices: Array<Interview_Feedback_Rating_Choices>;
  /** fetch aggregated fields from the table: "interview_feedback_rating_choices" */
  interview_feedback_rating_choices_aggregate: Interview_Feedback_Rating_Choices_Aggregate;
  /** fetch data from the table: "interview_feedback_rating_choices" using primary key columns */
  interview_feedback_rating_choices_by_pk: Maybe<Interview_Feedback_Rating_Choices>;
  /** fetch data from the table in a streaming manner: "interview_feedback_rating_choices" */
  interview_feedback_rating_choices_stream: Array<Interview_Feedback_Rating_Choices>;
  /** fetch data from the table: "interview_feedback_request" */
  interview_feedback_request: Array<Interview_Feedback_Request>;
  /** fetch aggregated fields from the table: "interview_feedback_request" */
  interview_feedback_request_aggregate: Interview_Feedback_Request_Aggregate;
  /** fetch data from the table: "interview_feedback_request" using primary key columns */
  interview_feedback_request_by_pk: Maybe<Interview_Feedback_Request>;
  /** fetch data from the table in a streaming manner: "interview_feedback_request" */
  interview_feedback_request_stream: Array<Interview_Feedback_Request>;
  /** fetch data from the table in a streaming manner: "interview_feedback" */
  interview_feedback_stream: Array<Interview_Feedback>;
  /** fetch data from the table: "interview_request" */
  interview_request: Array<Interview_Request>;
  /** fetch aggregated fields from the table: "interview_request" */
  interview_request_aggregate: Interview_Request_Aggregate;
  /** fetch data from the table: "interview_request" using primary key columns */
  interview_request_by_pk: Maybe<Interview_Request>;
  /** fetch data from the table in a streaming manner: "interview_request" */
  interview_request_stream: Array<Interview_Request>;
  /** fetch data from the table: "interview_type_choices" */
  interview_type_choices: Array<Interview_Type_Choices>;
  /** fetch aggregated fields from the table: "interview_type_choices" */
  interview_type_choices_aggregate: Interview_Type_Choices_Aggregate;
  /** fetch data from the table: "interview_type_choices" using primary key columns */
  interview_type_choices_by_pk: Maybe<Interview_Type_Choices>;
  /** fetch data from the table in a streaming manner: "interview_type_choices" */
  interview_type_choices_stream: Array<Interview_Type_Choices>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch data from the table: "job_acceptable_location" */
  job_acceptable_location: Array<Job_Acceptable_Location>;
  /** fetch aggregated fields from the table: "job_acceptable_location" */
  job_acceptable_location_aggregate: Job_Acceptable_Location_Aggregate;
  /** fetch data from the table: "job_acceptable_location" using primary key columns */
  job_acceptable_location_by_pk: Maybe<Job_Acceptable_Location>;
  /** fetch data from the table in a streaming manner: "job_acceptable_location" */
  job_acceptable_location_stream: Array<Job_Acceptable_Location>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk: Maybe<Job>;
  /** fetch data from the table: "job_code" */
  job_code: Array<Job_Code>;
  /** fetch aggregated fields from the table: "job_code" */
  job_code_aggregate: Job_Code_Aggregate;
  /** fetch data from the table: "job_code" using primary key columns */
  job_code_by_pk: Maybe<Job_Code>;
  /** fetch data from the table in a streaming manner: "job_code" */
  job_code_stream: Array<Job_Code>;
  /** fetch data from the table: "job_level_choices" */
  job_level_choices: Array<Job_Level_Choices>;
  /** fetch aggregated fields from the table: "job_level_choices" */
  job_level_choices_aggregate: Job_Level_Choices_Aggregate;
  /** fetch data from the table: "job_level_choices" using primary key columns */
  job_level_choices_by_pk: Maybe<Job_Level_Choices>;
  /** fetch data from the table in a streaming manner: "job_level_choices" */
  job_level_choices_stream: Array<Job_Level_Choices>;
  /** fetch data from the table: "job_meta_info" */
  job_meta_info: Array<Job_Meta_Info>;
  /** fetch aggregated fields from the table: "job_meta_info" */
  job_meta_info_aggregate: Job_Meta_Info_Aggregate;
  /** fetch data from the table: "job_meta_info" using primary key columns */
  job_meta_info_by_pk: Maybe<Job_Meta_Info>;
  /** fetch data from the table in a streaming manner: "job_meta_info" */
  job_meta_info_stream: Array<Job_Meta_Info>;
  /** fetch data from the table: "job_nice_to_have_skill" */
  job_nice_to_have_skill: Array<Job_Nice_To_Have_Skill>;
  /** fetch aggregated fields from the table: "job_nice_to_have_skill" */
  job_nice_to_have_skill_aggregate: Job_Nice_To_Have_Skill_Aggregate;
  /** fetch data from the table: "job_nice_to_have_skill" using primary key columns */
  job_nice_to_have_skill_by_pk: Maybe<Job_Nice_To_Have_Skill>;
  /** fetch data from the table in a streaming manner: "job_nice_to_have_skill" */
  job_nice_to_have_skill_stream: Array<Job_Nice_To_Have_Skill>;
  /** fetch data from the table: "job_position" */
  job_position: Array<Job_Position>;
  /** fetch aggregated fields from the table: "job_position" */
  job_position_aggregate: Job_Position_Aggregate;
  /** fetch data from the table: "job_position" using primary key columns */
  job_position_by_pk: Maybe<Job_Position>;
  /** fetch data from the table in a streaming manner: "job_position" */
  job_position_stream: Array<Job_Position>;
  /** fetch data from the table: "job_priority_choices" */
  job_priority_choices: Array<Job_Priority_Choices>;
  /** fetch aggregated fields from the table: "job_priority_choices" */
  job_priority_choices_aggregate: Job_Priority_Choices_Aggregate;
  /** fetch data from the table: "job_priority_choices" using primary key columns */
  job_priority_choices_by_pk: Maybe<Job_Priority_Choices>;
  /** fetch data from the table in a streaming manner: "job_priority_choices" */
  job_priority_choices_stream: Array<Job_Priority_Choices>;
  /** fetch data from the table: "job_required_skill" */
  job_required_skill: Array<Job_Required_Skill>;
  /** fetch aggregated fields from the table: "job_required_skill" */
  job_required_skill_aggregate: Job_Required_Skill_Aggregate;
  /** fetch data from the table: "job_required_skill" using primary key columns */
  job_required_skill_by_pk: Maybe<Job_Required_Skill>;
  /** fetch data from the table in a streaming manner: "job_required_skill" */
  job_required_skill_stream: Array<Job_Required_Skill>;
  /** fetch data from the table: "job_role_source_choices" */
  job_role_source_choices: Array<Job_Role_Source_Choices>;
  /** fetch aggregated fields from the table: "job_role_source_choices" */
  job_role_source_choices_aggregate: Job_Role_Source_Choices_Aggregate;
  /** fetch data from the table: "job_role_source_choices" using primary key columns */
  job_role_source_choices_by_pk: Maybe<Job_Role_Source_Choices>;
  /** fetch data from the table in a streaming manner: "job_role_source_choices" */
  job_role_source_choices_stream: Array<Job_Role_Source_Choices>;
  /** fetch data from the table: "job_service_type" */
  job_service_type: Array<Job_Service_Type>;
  /** fetch aggregated fields from the table: "job_service_type" */
  job_service_type_aggregate: Job_Service_Type_Aggregate;
  /** fetch data from the table: "job_service_type" using primary key columns */
  job_service_type_by_pk: Maybe<Job_Service_Type>;
  /** fetch data from the table in a streaming manner: "job_service_type" */
  job_service_type_stream: Array<Job_Service_Type>;
  /** fetch data from the table: "job_status_choices" */
  job_status_choices: Array<Job_Status_Choices>;
  /** fetch aggregated fields from the table: "job_status_choices" */
  job_status_choices_aggregate: Job_Status_Choices_Aggregate;
  /** fetch data from the table: "job_status_choices" using primary key columns */
  job_status_choices_by_pk: Maybe<Job_Status_Choices>;
  /** fetch data from the table: "job_status_choices_order" */
  job_status_choices_order: Array<Job_Status_Choices_Order>;
  /** fetch aggregated fields from the table: "job_status_choices_order" */
  job_status_choices_order_aggregate: Job_Status_Choices_Order_Aggregate;
  /** fetch data from the table: "job_status_choices_order" using primary key columns */
  job_status_choices_order_by_pk: Maybe<Job_Status_Choices_Order>;
  /** fetch data from the table in a streaming manner: "job_status_choices_order" */
  job_status_choices_order_stream: Array<Job_Status_Choices_Order>;
  /** fetch data from the table in a streaming manner: "job_status_choices" */
  job_status_choices_stream: Array<Job_Status_Choices>;
  /** fetch data from the table: "job_status_log" */
  job_status_log: Array<Job_Status_Log>;
  /** fetch aggregated fields from the table: "job_status_log" */
  job_status_log_aggregate: Job_Status_Log_Aggregate;
  /** fetch data from the table: "job_status_log" using primary key columns */
  job_status_log_by_pk: Maybe<Job_Status_Log>;
  /** fetch data from the table in a streaming manner: "job_status_log" */
  job_status_log_stream: Array<Job_Status_Log>;
  /** fetch data from the table in a streaming manner: "job" */
  job_stream: Array<Job>;
  /** An array relationship */
  job_tech_stack: Array<Job_Tech_Stack>;
  /** An aggregate relationship */
  job_tech_stack_aggregate: Job_Tech_Stack_Aggregate;
  /** fetch data from the table: "job_tech_stack" using primary key columns */
  job_tech_stack_by_pk: Maybe<Job_Tech_Stack>;
  /** fetch data from the table in a streaming manner: "job_tech_stack" */
  job_tech_stack_stream: Array<Job_Tech_Stack>;
  /** fetch data from the table: "linkedin_contact" */
  linkedin_contact: Array<Linkedin_Contact>;
  /** fetch aggregated fields from the table: "linkedin_contact" */
  linkedin_contact_aggregate: Linkedin_Contact_Aggregate;
  /** fetch data from the table: "linkedin_contact" using primary key columns */
  linkedin_contact_by_pk: Maybe<Linkedin_Contact>;
  /** fetch data from the table in a streaming manner: "linkedin_contact" */
  linkedin_contact_stream: Array<Linkedin_Contact>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk: Maybe<Location>;
  /** fetch data from the table in a streaming manner: "location" */
  location_stream: Array<Location>;
  /** fetch data from the table: "manual_contact" */
  manual_contact: Array<Manual_Contact>;
  /** fetch aggregated fields from the table: "manual_contact" */
  manual_contact_aggregate: Manual_Contact_Aggregate;
  /** fetch data from the table: "manual_contact" using primary key columns */
  manual_contact_by_pk: Maybe<Manual_Contact>;
  /** fetch data from the table in a streaming manner: "manual_contact" */
  manual_contact_stream: Array<Manual_Contact>;
  /** fetch data from the table: "merge_ats_application" */
  merge_ats_application: Array<Merge_Ats_Application>;
  /** fetch aggregated fields from the table: "merge_ats_application" */
  merge_ats_application_aggregate: Merge_Ats_Application_Aggregate;
  /** fetch data from the table: "merge_ats_application" using primary key columns */
  merge_ats_application_by_pk: Maybe<Merge_Ats_Application>;
  /** fetch data from the table: "merge_ats_application_log" */
  merge_ats_application_log: Array<Merge_Ats_Application_Log>;
  /** fetch aggregated fields from the table: "merge_ats_application_log" */
  merge_ats_application_log_aggregate: Merge_Ats_Application_Log_Aggregate;
  /** fetch data from the table: "merge_ats_application_log" using primary key columns */
  merge_ats_application_log_by_pk: Maybe<Merge_Ats_Application_Log>;
  /** fetch data from the table in a streaming manner: "merge_ats_application_log" */
  merge_ats_application_log_stream: Array<Merge_Ats_Application_Log>;
  /** fetch data from the table in a streaming manner: "merge_ats_application" */
  merge_ats_application_stream: Array<Merge_Ats_Application>;
  /** fetch data from the table: "merge_ats_candidate" */
  merge_ats_candidate: Array<Merge_Ats_Candidate>;
  /** fetch aggregated fields from the table: "merge_ats_candidate" */
  merge_ats_candidate_aggregate: Merge_Ats_Candidate_Aggregate;
  /** fetch data from the table: "merge_ats_candidate" using primary key columns */
  merge_ats_candidate_by_pk: Maybe<Merge_Ats_Candidate>;
  /** fetch data from the table in a streaming manner: "merge_ats_candidate" */
  merge_ats_candidate_stream: Array<Merge_Ats_Candidate>;
  /** fetch data from the table: "merge_ats_job" */
  merge_ats_job: Array<Merge_Ats_Job>;
  /** fetch aggregated fields from the table: "merge_ats_job" */
  merge_ats_job_aggregate: Merge_Ats_Job_Aggregate;
  /** fetch data from the table: "merge_ats_job" using primary key columns */
  merge_ats_job_by_pk: Maybe<Merge_Ats_Job>;
  /** fetch data from the table: "merge_ats_job_interview_stage" */
  merge_ats_job_interview_stage: Array<Merge_Ats_Job_Interview_Stage>;
  /** fetch aggregated fields from the table: "merge_ats_job_interview_stage" */
  merge_ats_job_interview_stage_aggregate: Merge_Ats_Job_Interview_Stage_Aggregate;
  /** fetch data from the table: "merge_ats_job_interview_stage" using primary key columns */
  merge_ats_job_interview_stage_by_pk: Maybe<Merge_Ats_Job_Interview_Stage>;
  /** fetch data from the table in a streaming manner: "merge_ats_job_interview_stage" */
  merge_ats_job_interview_stage_stream: Array<Merge_Ats_Job_Interview_Stage>;
  /** fetch data from the table in a streaming manner: "merge_ats_job" */
  merge_ats_job_stream: Array<Merge_Ats_Job>;
  /** fetch data from the table: "merge_ats_linked_account" */
  merge_ats_linked_account: Array<Merge_Ats_Linked_Account>;
  /** fetch aggregated fields from the table: "merge_ats_linked_account" */
  merge_ats_linked_account_aggregate: Merge_Ats_Linked_Account_Aggregate;
  /** fetch data from the table: "merge_ats_linked_account" using primary key columns */
  merge_ats_linked_account_by_pk: Maybe<Merge_Ats_Linked_Account>;
  /** fetch data from the table in a streaming manner: "merge_ats_linked_account" */
  merge_ats_linked_account_stream: Array<Merge_Ats_Linked_Account>;
  /** fetch data from the table: "mixpanel.duplicate_profiles" */
  mixpanel_duplicate_profiles: Array<Mixpanel_Duplicate_Profiles>;
  /** fetch aggregated fields from the table: "mixpanel.duplicate_profiles" */
  mixpanel_duplicate_profiles_aggregate: Mixpanel_Duplicate_Profiles_Aggregate;
  /** fetch data from the table in a streaming manner: "mixpanel.duplicate_profiles" */
  mixpanel_duplicate_profiles_stream: Array<Mixpanel_Duplicate_Profiles>;
  /** fetch data from the table: "mixpanel.profiles" */
  mixpanel_profiles: Array<Mixpanel_Profiles>;
  /** fetch aggregated fields from the table: "mixpanel.profiles" */
  mixpanel_profiles_aggregate: Mixpanel_Profiles_Aggregate;
  /** fetch data from the table: "mixpanel.profiles" using primary key columns */
  mixpanel_profiles_by_pk: Maybe<Mixpanel_Profiles>;
  /** fetch data from the table in a streaming manner: "mixpanel.profiles" */
  mixpanel_profiles_stream: Array<Mixpanel_Profiles>;
  /** fetch data from the table: "offering_selection_choices" */
  offering_selection_choices: Array<Offering_Selection_Choices>;
  /** fetch aggregated fields from the table: "offering_selection_choices" */
  offering_selection_choices_aggregate: Offering_Selection_Choices_Aggregate;
  /** fetch data from the table: "offering_selection_choices" using primary key columns */
  offering_selection_choices_by_pk: Maybe<Offering_Selection_Choices>;
  /** fetch data from the table in a streaming manner: "offering_selection_choices" */
  offering_selection_choices_stream: Array<Offering_Selection_Choices>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch aggregated fields from the table: "organization" */
  organization_aggregate: Organization_Aggregate;
  /** fetch data from the table: "organization" using primary key columns */
  organization_by_pk: Maybe<Organization>;
  /** fetch data from the table: "organization_contact" */
  organization_contact: Array<Organization_Contact>;
  /** fetch aggregated fields from the table: "organization_contact" */
  organization_contact_aggregate: Organization_Contact_Aggregate;
  /** fetch data from the table: "organization_contact" using primary key columns */
  organization_contact_by_pk: Maybe<Organization_Contact>;
  /** fetch data from the table in a streaming manner: "organization_contact" */
  organization_contact_stream: Array<Organization_Contact>;
  /** fetch data from the table: "organization_domain" */
  organization_domain: Array<Organization_Domain>;
  /** fetch aggregated fields from the table: "organization_domain" */
  organization_domain_aggregate: Organization_Domain_Aggregate;
  /** fetch data from the table: "organization_domain" using primary key columns */
  organization_domain_by_pk: Maybe<Organization_Domain>;
  /** fetch data from the table in a streaming manner: "organization_domain" */
  organization_domain_stream: Array<Organization_Domain>;
  /** fetch data from the table: "organization_employee" */
  organization_employee: Array<Organization_Employee>;
  /** fetch aggregated fields from the table: "organization_employee" */
  organization_employee_aggregate: Organization_Employee_Aggregate;
  /** fetch data from the table: "organization_employee" using primary key columns */
  organization_employee_by_pk: Maybe<Organization_Employee>;
  /** fetch data from the table in a streaming manner: "organization_employee" */
  organization_employee_stream: Array<Organization_Employee>;
  /** execute function "organization_icims_jobs" which returns "organization_icims_jobs_result" */
  organization_icims_jobs: Array<Organization_Icims_Jobs_Result>;
  /** execute function "organization_icims_jobs" and query aggregates on result of table type "organization_icims_jobs_result" */
  organization_icims_jobs_aggregate: Organization_Icims_Jobs_Result_Aggregate;
  /** fetch data from the table: "organization_icims_jobs_result" */
  organization_icims_jobs_result: Array<Organization_Icims_Jobs_Result>;
  /** fetch aggregated fields from the table: "organization_icims_jobs_result" */
  organization_icims_jobs_result_aggregate: Organization_Icims_Jobs_Result_Aggregate;
  /** fetch data from the table in a streaming manner: "organization_icims_jobs_result" */
  organization_icims_jobs_result_stream: Array<Organization_Icims_Jobs_Result>;
  /** fetch data from the table: "organization_leadership" */
  organization_leadership: Array<Organization_Leadership>;
  /** fetch aggregated fields from the table: "organization_leadership" */
  organization_leadership_aggregate: Organization_Leadership_Aggregate;
  /** fetch data from the table: "organization_leadership" using primary key columns */
  organization_leadership_by_pk: Maybe<Organization_Leadership>;
  /** fetch data from the table in a streaming manner: "organization_leadership" */
  organization_leadership_stream: Array<Organization_Leadership>;
  /** fetch data from the table in a streaming manner: "organization" */
  organization_stream: Array<Organization>;
  /** fetch data from the table: "organization_type_choices" */
  organization_type_choices: Array<Organization_Type_Choices>;
  /** fetch aggregated fields from the table: "organization_type_choices" */
  organization_type_choices_aggregate: Organization_Type_Choices_Aggregate;
  /** fetch data from the table: "organization_type_choices" using primary key columns */
  organization_type_choices_by_pk: Maybe<Organization_Type_Choices>;
  /** fetch data from the table in a streaming manner: "organization_type_choices" */
  organization_type_choices_stream: Array<Organization_Type_Choices>;
  /** fetch data from the table: "organization_video" */
  organization_video: Array<Organization_Video>;
  /** fetch aggregated fields from the table: "organization_video" */
  organization_video_aggregate: Organization_Video_Aggregate;
  /** fetch data from the table: "organization_video" using primary key columns */
  organization_video_by_pk: Maybe<Organization_Video>;
  /** fetch data from the table in a streaming manner: "organization_video" */
  organization_video_stream: Array<Organization_Video>;
  /** fetch data from the table: "pillar_transcript" */
  pillar_transcript: Array<Pillar_Transcript>;
  /** fetch aggregated fields from the table: "pillar_transcript" */
  pillar_transcript_aggregate: Pillar_Transcript_Aggregate;
  /** fetch data from the table: "pillar_transcript_blob" */
  pillar_transcript_blob: Array<Pillar_Transcript_Blob>;
  /** fetch aggregated fields from the table: "pillar_transcript_blob" */
  pillar_transcript_blob_aggregate: Pillar_Transcript_Blob_Aggregate;
  /** fetch data from the table: "pillar_transcript_blob" using primary key columns */
  pillar_transcript_blob_by_pk: Maybe<Pillar_Transcript_Blob>;
  /** fetch data from the table in a streaming manner: "pillar_transcript_blob" */
  pillar_transcript_blob_stream: Array<Pillar_Transcript_Blob>;
  /** fetch data from the table: "pillar_transcript" using primary key columns */
  pillar_transcript_by_pk: Maybe<Pillar_Transcript>;
  /** fetch data from the table in a streaming manner: "pillar_transcript" */
  pillar_transcript_stream: Array<Pillar_Transcript>;
  /** fetch data from the table: "recruiter_contact_action" */
  recruiter_contact_action: Array<Recruiter_Contact_Action>;
  /** fetch aggregated fields from the table: "recruiter_contact_action" */
  recruiter_contact_action_aggregate: Recruiter_Contact_Action_Aggregate;
  /** fetch data from the table: "recruiter_contact_action" using primary key columns */
  recruiter_contact_action_by_pk: Maybe<Recruiter_Contact_Action>;
  /** fetch data from the table in a streaming manner: "recruiter_contact_action" */
  recruiter_contact_action_stream: Array<Recruiter_Contact_Action>;
  /** fetch data from the table: "referral_status" */
  referral_status: Array<Referral_Status>;
  /** fetch aggregated fields from the table: "referral_status" */
  referral_status_aggregate: Referral_Status_Aggregate;
  /** fetch data from the table: "referral_status" using primary key columns */
  referral_status_by_pk: Maybe<Referral_Status>;
  /** fetch data from the table: "referral_status_current" */
  referral_status_current: Array<Referral_Status_Current>;
  /** fetch aggregated fields from the table: "referral_status_current" */
  referral_status_current_aggregate: Referral_Status_Current_Aggregate;
  /** fetch data from the table: "referral_status_current" using primary key columns */
  referral_status_current_by_pk: Maybe<Referral_Status_Current>;
  /** fetch data from the table in a streaming manner: "referral_status_current" */
  referral_status_current_stream: Array<Referral_Status_Current>;
  /** fetch data from the table in a streaming manner: "referral_status" */
  referral_status_stream: Array<Referral_Status>;
  /** fetch data from the table: "region_choices" */
  region_choices: Array<Region_Choices>;
  /** fetch aggregated fields from the table: "region_choices" */
  region_choices_aggregate: Region_Choices_Aggregate;
  /** fetch data from the table: "region_choices" using primary key columns */
  region_choices_by_pk: Maybe<Region_Choices>;
  /** fetch data from the table in a streaming manner: "region_choices" */
  region_choices_stream: Array<Region_Choices>;
  /** fetch data from the table: "reporting.candidate_metadata" */
  reporting_candidate_metadata: Array<Reporting_Candidate_Metadata>;
  /** fetch aggregated fields from the table: "reporting.candidate_metadata" */
  reporting_candidate_metadata_aggregate: Reporting_Candidate_Metadata_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.candidate_metadata" */
  reporting_candidate_metadata_stream: Array<Reporting_Candidate_Metadata>;
  /** fetch data from the table: "reporting.email_suppression_traits" */
  reporting_email_suppression_traits: Array<Reporting_Email_Suppression_Traits>;
  /** fetch aggregated fields from the table: "reporting.email_suppression_traits" */
  reporting_email_suppression_traits_aggregate: Reporting_Email_Suppression_Traits_Aggregate;
  /** fetch data from the table in a streaming manner: "reporting.email_suppression_traits" */
  reporting_email_suppression_traits_stream: Array<Reporting_Email_Suppression_Traits>;
  /** fetch data from the table: "reporting.report_job_matches" */
  reporting_report_job_matches: Array<Reporting_Report_Job_Matches>;
  /** fetch aggregated fields from the table: "reporting.report_job_matches" */
  reporting_report_job_matches_aggregate: Reporting_Report_Job_Matches_Aggregate;
  /** fetch data from the table: "reporting.report_job_matches" using primary key columns */
  reporting_report_job_matches_by_pk: Maybe<Reporting_Report_Job_Matches>;
  /** fetch data from the table in a streaming manner: "reporting.report_job_matches" */
  reporting_report_job_matches_stream: Array<Reporting_Report_Job_Matches>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk: Maybe<Role>;
  /** fetch data from the table: "role_choices" */
  role_choices: Array<Role_Choices>;
  /** fetch aggregated fields from the table: "role_choices" */
  role_choices_aggregate: Role_Choices_Aggregate;
  /** fetch data from the table: "role_choices" using primary key columns */
  role_choices_by_pk: Maybe<Role_Choices>;
  /** fetch data from the table in a streaming manner: "role_choices" */
  role_choices_stream: Array<Role_Choices>;
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>;
  /** fetch data from the table: "salary_info" */
  salary_info: Array<Salary_Info>;
  /** fetch aggregated fields from the table: "salary_info" */
  salary_info_aggregate: Salary_Info_Aggregate;
  /** fetch data from the table: "salary_info" using primary key columns */
  salary_info_by_pk: Maybe<Salary_Info>;
  /** fetch data from the table in a streaming manner: "salary_info" */
  salary_info_stream: Array<Salary_Info>;
  /** fetch data from the table: "salary_years_experience_range_choices" */
  salary_years_experience_range_choices: Array<Salary_Years_Experience_Range_Choices>;
  /** fetch aggregated fields from the table: "salary_years_experience_range_choices" */
  salary_years_experience_range_choices_aggregate: Salary_Years_Experience_Range_Choices_Aggregate;
  /** fetch data from the table: "salary_years_experience_range_choices" using primary key columns */
  salary_years_experience_range_choices_by_pk: Maybe<Salary_Years_Experience_Range_Choices>;
  /** fetch data from the table in a streaming manner: "salary_years_experience_range_choices" */
  salary_years_experience_range_choices_stream: Array<Salary_Years_Experience_Range_Choices>;
  /** fetch data from the table: "salesforce_customer" */
  salesforce_customer: Array<Salesforce_Customer>;
  /** fetch aggregated fields from the table: "salesforce_customer" */
  salesforce_customer_aggregate: Salesforce_Customer_Aggregate;
  /** fetch data from the table: "salesforce_customer" using primary key columns */
  salesforce_customer_by_pk: Maybe<Salesforce_Customer>;
  /** fetch data from the table: "salesforce_customer_location" */
  salesforce_customer_location: Array<Salesforce_Customer_Location>;
  /** fetch aggregated fields from the table: "salesforce_customer_location" */
  salesforce_customer_location_aggregate: Salesforce_Customer_Location_Aggregate;
  /** fetch data from the table: "salesforce_customer_location" using primary key columns */
  salesforce_customer_location_by_pk: Maybe<Salesforce_Customer_Location>;
  /** fetch data from the table in a streaming manner: "salesforce_customer_location" */
  salesforce_customer_location_stream: Array<Salesforce_Customer_Location>;
  /** fetch data from the table in a streaming manner: "salesforce_customer" */
  salesforce_customer_stream: Array<Salesforce_Customer>;
  /** fetch data from the table: "salesforce_location" */
  salesforce_location: Array<Salesforce_Location>;
  /** fetch aggregated fields from the table: "salesforce_location" */
  salesforce_location_aggregate: Salesforce_Location_Aggregate;
  /** fetch data from the table: "salesforce_location" using primary key columns */
  salesforce_location_by_pk: Maybe<Salesforce_Location>;
  /** fetch data from the table in a streaming manner: "salesforce_location" */
  salesforce_location_stream: Array<Salesforce_Location>;
  /** fetch data from the table: "saved_candidate" */
  saved_candidate: Array<Saved_Candidate>;
  /** fetch aggregated fields from the table: "saved_candidate" */
  saved_candidate_aggregate: Saved_Candidate_Aggregate;
  /** fetch data from the table: "saved_candidate" using primary key columns */
  saved_candidate_by_pk: Maybe<Saved_Candidate>;
  /** fetch data from the table in a streaming manner: "saved_candidate" */
  saved_candidate_stream: Array<Saved_Candidate>;
  /** fetch data from the table: "segment_sendgrid_prod.activity" */
  segment_sendgrid_prod_activity: Array<Segment_Sendgrid_Prod_Activity>;
  /** fetch aggregated fields from the table: "segment_sendgrid_prod.activity" */
  segment_sendgrid_prod_activity_aggregate: Segment_Sendgrid_Prod_Activity_Aggregate;
  /** fetch data from the table: "segment_sendgrid_prod.activity" using primary key columns */
  segment_sendgrid_prod_activity_by_pk: Maybe<Segment_Sendgrid_Prod_Activity>;
  /** fetch data from the table in a streaming manner: "segment_sendgrid_prod.activity" */
  segment_sendgrid_prod_activity_stream: Array<Segment_Sendgrid_Prod_Activity>;
  /** fetch data from the table: "shared_candidate" */
  shared_candidate: Array<Shared_Candidate>;
  /** fetch aggregated fields from the table: "shared_candidate" */
  shared_candidate_aggregate: Shared_Candidate_Aggregate;
  /** fetch data from the table: "shared_candidate" using primary key columns */
  shared_candidate_by_pk: Maybe<Shared_Candidate>;
  /** fetch data from the table in a streaming manner: "shared_candidate" */
  shared_candidate_stream: Array<Shared_Candidate>;
  /** fetch data from the table: "skill" */
  skill: Array<Skill>;
  /** fetch aggregated fields from the table: "skill" */
  skill_aggregate: Skill_Aggregate;
  /** fetch data from the table: "skill" using primary key columns */
  skill_by_pk: Maybe<Skill>;
  /** fetch data from the table: "skill_feature" */
  skill_feature: Array<Skill_Feature>;
  /** fetch aggregated fields from the table: "skill_feature" */
  skill_feature_aggregate: Skill_Feature_Aggregate;
  /** fetch data from the table: "skill_feature" using primary key columns */
  skill_feature_by_pk: Maybe<Skill_Feature>;
  /** fetch data from the table in a streaming manner: "skill_feature" */
  skill_feature_stream: Array<Skill_Feature>;
  /** fetch data from the table: "skill_icims_skill" */
  skill_icims_skill: Array<Skill_Icims_Skill>;
  /** fetch aggregated fields from the table: "skill_icims_skill" */
  skill_icims_skill_aggregate: Skill_Icims_Skill_Aggregate;
  /** fetch data from the table: "skill_icims_skill" using primary key columns */
  skill_icims_skill_by_pk: Maybe<Skill_Icims_Skill>;
  /** fetch data from the table in a streaming manner: "skill_icims_skill" */
  skill_icims_skill_stream: Array<Skill_Icims_Skill>;
  /** fetch data from the table in a streaming manner: "skill" */
  skill_stream: Array<Skill>;
  /** fetch data from the table: "skill_synonym" */
  skill_synonym: Array<Skill_Synonym>;
  /** fetch aggregated fields from the table: "skill_synonym" */
  skill_synonym_aggregate: Skill_Synonym_Aggregate;
  /** fetch data from the table: "skill_synonym" using primary key columns */
  skill_synonym_by_pk: Maybe<Skill_Synonym>;
  /** fetch data from the table in a streaming manner: "skill_synonym" */
  skill_synonym_stream: Array<Skill_Synonym>;
  /** fetch data from the table: "spike_matching.candidate" */
  spike_matching_candidate: Array<Spike_Matching_Candidate>;
  /** fetch aggregated fields from the table: "spike_matching.candidate" */
  spike_matching_candidate_aggregate: Spike_Matching_Candidate_Aggregate;
  /** fetch data from the table: "spike_matching.candidate" using primary key columns */
  spike_matching_candidate_by_pk: Maybe<Spike_Matching_Candidate>;
  /** fetch data from the table: "spike_matching.candidate_skill" */
  spike_matching_candidate_skill: Array<Spike_Matching_Candidate_Skill>;
  /** fetch aggregated fields from the table: "spike_matching.candidate_skill" */
  spike_matching_candidate_skill_aggregate: Spike_Matching_Candidate_Skill_Aggregate;
  /** fetch data from the table: "spike_matching.candidate_skill" using primary key columns */
  spike_matching_candidate_skill_by_pk: Maybe<Spike_Matching_Candidate_Skill>;
  /** fetch data from the table in a streaming manner: "spike_matching.candidate_skill" */
  spike_matching_candidate_skill_stream: Array<Spike_Matching_Candidate_Skill>;
  /** fetch data from the table in a streaming manner: "spike_matching.candidate" */
  spike_matching_candidate_stream: Array<Spike_Matching_Candidate>;
  /** fetch data from the table: "spike_matching.job" */
  spike_matching_job: Array<Spike_Matching_Job>;
  /** fetch aggregated fields from the table: "spike_matching.job" */
  spike_matching_job_aggregate: Spike_Matching_Job_Aggregate;
  /** fetch data from the table: "spike_matching.job" using primary key columns */
  spike_matching_job_by_pk: Maybe<Spike_Matching_Job>;
  /** fetch data from the table: "spike_matching.job_skill" */
  spike_matching_job_skill: Array<Spike_Matching_Job_Skill>;
  /** fetch aggregated fields from the table: "spike_matching.job_skill" */
  spike_matching_job_skill_aggregate: Spike_Matching_Job_Skill_Aggregate;
  /** fetch data from the table: "spike_matching.job_skill" using primary key columns */
  spike_matching_job_skill_by_pk: Maybe<Spike_Matching_Job_Skill>;
  /** fetch data from the table in a streaming manner: "spike_matching.job_skill" */
  spike_matching_job_skill_stream: Array<Spike_Matching_Job_Skill>;
  /** fetch data from the table in a streaming manner: "spike_matching.job" */
  spike_matching_job_stream: Array<Spike_Matching_Job>;
  /** fetch data from the table: "spike_matching.location" */
  spike_matching_location: Array<Spike_Matching_Location>;
  /** fetch aggregated fields from the table: "spike_matching.location" */
  spike_matching_location_aggregate: Spike_Matching_Location_Aggregate;
  /** fetch data from the table: "spike_matching.location" using primary key columns */
  spike_matching_location_by_pk: Maybe<Spike_Matching_Location>;
  /** fetch data from the table: "spike_matching.location_job" */
  spike_matching_location_job: Array<Spike_Matching_Location_Job>;
  /** fetch aggregated fields from the table: "spike_matching.location_job" */
  spike_matching_location_job_aggregate: Spike_Matching_Location_Job_Aggregate;
  /** fetch data from the table: "spike_matching.location_job" using primary key columns */
  spike_matching_location_job_by_pk: Maybe<Spike_Matching_Location_Job>;
  /** fetch data from the table in a streaming manner: "spike_matching.location_job" */
  spike_matching_location_job_stream: Array<Spike_Matching_Location_Job>;
  /** fetch data from the table in a streaming manner: "spike_matching.location" */
  spike_matching_location_stream: Array<Spike_Matching_Location>;
  /** execute function "spike_matching.match" which returns "spike_matching.match_result" */
  spike_matching_match: Array<Spike_Matching_Match_Result>;
  /** execute function "spike_matching.match" and query aggregates on result of table type "spike_matching.match_result" */
  spike_matching_match_aggregate: Spike_Matching_Match_Result_Aggregate;
  /** fetch data from the table: "spike_matching.match_result" */
  spike_matching_match_result: Array<Spike_Matching_Match_Result>;
  /** fetch aggregated fields from the table: "spike_matching.match_result" */
  spike_matching_match_result_aggregate: Spike_Matching_Match_Result_Aggregate;
  /** fetch data from the table: "spike_matching.match_result" using primary key columns */
  spike_matching_match_result_by_pk: Maybe<Spike_Matching_Match_Result>;
  /** fetch data from the table in a streaming manner: "spike_matching.match_result" */
  spike_matching_match_result_stream: Array<Spike_Matching_Match_Result>;
  /** fetch data from the table: "spike_matching.skill" */
  spike_matching_skill: Array<Spike_Matching_Skill>;
  /** fetch aggregated fields from the table: "spike_matching.skill" */
  spike_matching_skill_aggregate: Spike_Matching_Skill_Aggregate;
  /** fetch data from the table: "spike_matching.skill" using primary key columns */
  spike_matching_skill_by_pk: Maybe<Spike_Matching_Skill>;
  /** fetch data from the table in a streaming manner: "spike_matching.skill" */
  spike_matching_skill_stream: Array<Spike_Matching_Skill>;
  /** fetch data from the table: "terminal_celery_task_status_choices" */
  terminal_celery_task_status_choices: Array<Terminal_Celery_Task_Status_Choices>;
  /** fetch aggregated fields from the table: "terminal_celery_task_status_choices" */
  terminal_celery_task_status_choices_aggregate: Terminal_Celery_Task_Status_Choices_Aggregate;
  /** fetch data from the table: "terminal_celery_task_status_choices" using primary key columns */
  terminal_celery_task_status_choices_by_pk: Maybe<Terminal_Celery_Task_Status_Choices>;
  /** fetch data from the table in a streaming manner: "terminal_celery_task_status_choices" */
  terminal_celery_task_status_choices_stream: Array<Terminal_Celery_Task_Status_Choices>;
  /** fetch data from the table: "terminal_employee" */
  terminal_employee: Array<Terminal_Employee>;
  /** fetch aggregated fields from the table: "terminal_employee" */
  terminal_employee_aggregate: Terminal_Employee_Aggregate;
  /** fetch data from the table: "terminal_employee" using primary key columns */
  terminal_employee_by_pk: Maybe<Terminal_Employee>;
  /** fetch data from the table: "terminal_employee_organization" */
  terminal_employee_organization: Array<Terminal_Employee_Organization>;
  /** fetch aggregated fields from the table: "terminal_employee_organization" */
  terminal_employee_organization_aggregate: Terminal_Employee_Organization_Aggregate;
  /** fetch data from the table: "terminal_employee_organization" using primary key columns */
  terminal_employee_organization_by_pk: Maybe<Terminal_Employee_Organization>;
  /** fetch data from the table in a streaming manner: "terminal_employee_organization" */
  terminal_employee_organization_stream: Array<Terminal_Employee_Organization>;
  /** fetch data from the table: "terminal_employee_role_choices" */
  terminal_employee_role_choices: Array<Terminal_Employee_Role_Choices>;
  /** fetch aggregated fields from the table: "terminal_employee_role_choices" */
  terminal_employee_role_choices_aggregate: Terminal_Employee_Role_Choices_Aggregate;
  /** fetch data from the table: "terminal_employee_role_choices" using primary key columns */
  terminal_employee_role_choices_by_pk: Maybe<Terminal_Employee_Role_Choices>;
  /** fetch data from the table in a streaming manner: "terminal_employee_role_choices" */
  terminal_employee_role_choices_stream: Array<Terminal_Employee_Role_Choices>;
  /** fetch data from the table in a streaming manner: "terminal_employee" */
  terminal_employee_stream: Array<Terminal_Employee>;
  /** fetch data from the table: "trait" */
  trait: Array<Trait>;
  /** fetch aggregated fields from the table: "trait" */
  trait_aggregate: Trait_Aggregate;
  /** fetch data from the table: "trait" using primary key columns */
  trait_by_pk: Maybe<Trait>;
  /** fetch data from the table in a streaming manner: "trait" */
  trait_stream: Array<Trait>;
  /** fetch data from the table: "transcript_evaluation" */
  transcript_evaluation: Array<Transcript_Evaluation>;
  /** fetch aggregated fields from the table: "transcript_evaluation" */
  transcript_evaluation_aggregate: Transcript_Evaluation_Aggregate;
  /** fetch data from the table: "transcript_evaluation" using primary key columns */
  transcript_evaluation_by_pk: Maybe<Transcript_Evaluation>;
  /** fetch data from the table in a streaming manner: "transcript_evaluation" */
  transcript_evaluation_stream: Array<Transcript_Evaluation>;
  /** fetch data from the table: "transcript_skill" */
  transcript_skill: Array<Transcript_Skill>;
  /** fetch aggregated fields from the table: "transcript_skill" */
  transcript_skill_aggregate: Transcript_Skill_Aggregate;
  /** fetch data from the table: "transcript_skill" using primary key columns */
  transcript_skill_by_pk: Maybe<Transcript_Skill>;
  /** fetch data from the table in a streaming manner: "transcript_skill" */
  transcript_skill_stream: Array<Transcript_Skill>;
  /** fetch data from the table: "transcript_work_experience" */
  transcript_work_experience: Array<Transcript_Work_Experience>;
  /** fetch aggregated fields from the table: "transcript_work_experience" */
  transcript_work_experience_aggregate: Transcript_Work_Experience_Aggregate;
  /** fetch data from the table: "transcript_work_experience" using primary key columns */
  transcript_work_experience_by_pk: Maybe<Transcript_Work_Experience>;
  /** fetch data from the table in a streaming manner: "transcript_work_experience" */
  transcript_work_experience_stream: Array<Transcript_Work_Experience>;
  /** fetch data from the table: "user_contact" */
  user_contact: Array<User_Contact>;
  /** fetch aggregated fields from the table: "user_contact" */
  user_contact_aggregate: User_Contact_Aggregate;
  /** fetch data from the table in a streaming manner: "user_contact" */
  user_contact_stream: Array<User_Contact>;
  /** fetch data from the table: "user_info" */
  user_info: Array<User_Info>;
  /** fetch aggregated fields from the table: "user_info" */
  user_info_aggregate: User_Info_Aggregate;
  /** fetch data from the table: "user_info" using primary key columns */
  user_info_by_pk: Maybe<User_Info>;
  /** fetch data from the table in a streaming manner: "user_info" */
  user_info_stream: Array<User_Info>;
  /** fetch data from the table: "user_invitation" */
  user_invitation: Array<User_Invitation>;
  /** fetch aggregated fields from the table: "user_invitation" */
  user_invitation_aggregate: User_Invitation_Aggregate;
  /** fetch data from the table: "user_invitation" using primary key columns */
  user_invitation_by_pk: Maybe<User_Invitation>;
  /** fetch data from the table in a streaming manner: "user_invitation" */
  user_invitation_stream: Array<User_Invitation>;
  /** fetch data from the table: "user_linkedin_contact" */
  user_linkedin_contact: Array<User_Linkedin_Contact>;
  /** fetch aggregated fields from the table: "user_linkedin_contact" */
  user_linkedin_contact_aggregate: User_Linkedin_Contact_Aggregate;
  /** fetch data from the table: "user_linkedin_contact" using primary key columns */
  user_linkedin_contact_by_pk: Maybe<User_Linkedin_Contact>;
  /** fetch data from the table in a streaming manner: "user_linkedin_contact" */
  user_linkedin_contact_stream: Array<User_Linkedin_Contact>;
  /** fetch data from the table: "user_password_blacklist" */
  user_password_blacklist: Array<User_Password_Blacklist>;
  /** fetch aggregated fields from the table: "user_password_blacklist" */
  user_password_blacklist_aggregate: User_Password_Blacklist_Aggregate;
  /** fetch data from the table: "user_password_blacklist" using primary key columns */
  user_password_blacklist_by_pk: Maybe<User_Password_Blacklist>;
  /** fetch data from the table in a streaming manner: "user_password_blacklist" */
  user_password_blacklist_stream: Array<User_Password_Blacklist>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk: Maybe<User_Role>;
  /** fetch data from the table in a streaming manner: "user_role" */
  user_role_stream: Array<User_Role>;
  /** fetch data from the table: "user_subscription" */
  user_subscription: Array<User_Subscription>;
  /** fetch aggregated fields from the table: "user_subscription" */
  user_subscription_aggregate: User_Subscription_Aggregate;
  /** fetch data from the table: "user_subscription" using primary key columns */
  user_subscription_by_pk: Maybe<User_Subscription>;
  /** fetch data from the table: "user_subscription_choices" */
  user_subscription_choices: Array<User_Subscription_Choices>;
  /** fetch aggregated fields from the table: "user_subscription_choices" */
  user_subscription_choices_aggregate: User_Subscription_Choices_Aggregate;
  /** fetch data from the table: "user_subscription_choices" using primary key columns */
  user_subscription_choices_by_pk: Maybe<User_Subscription_Choices>;
  /** fetch data from the table in a streaming manner: "user_subscription_choices" */
  user_subscription_choices_stream: Array<User_Subscription_Choices>;
  /** fetch data from the table in a streaming manner: "user_subscription" */
  user_subscription_stream: Array<User_Subscription>;
  /** fetch data from the table: "user_temp_blob" */
  user_temp_blob: Array<User_Temp_Blob>;
  /** fetch aggregated fields from the table: "user_temp_blob" */
  user_temp_blob_aggregate: User_Temp_Blob_Aggregate;
  /** fetch data from the table: "user_temp_blob" using primary key columns */
  user_temp_blob_by_pk: Maybe<User_Temp_Blob>;
  /** fetch data from the table in a streaming manner: "user_temp_blob" */
  user_temp_blob_stream: Array<User_Temp_Blob>;
  /** fetch data from the table: "validator" */
  validator: Array<Validator>;
  /** fetch aggregated fields from the table: "validator" */
  validator_aggregate: Validator_Aggregate;
  /** fetch data from the table: "validator" using primary key columns */
  validator_by_pk: Maybe<Validator>;
  /** fetch data from the table in a streaming manner: "validator" */
  validator_stream: Array<Validator>;
};

export type Subscription_RootActivity_Customer_Profile_ViewArgs = {
  distinct_on?: InputMaybe<Array<Activity_Customer_Profile_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Activity_Customer_Profile_View_Order_By>>;
  where?: InputMaybe<Activity_Customer_Profile_View_Bool_Exp>;
};

export type Subscription_RootActivity_Customer_Profile_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Activity_Customer_Profile_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Activity_Customer_Profile_View_Order_By>>;
  where?: InputMaybe<Activity_Customer_Profile_View_Bool_Exp>;
};

export type Subscription_RootActivity_Customer_Profile_View_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootActivity_Customer_Profile_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Activity_Customer_Profile_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Activity_Customer_Profile_View_Bool_Exp>;
};

export type Subscription_RootAi_LevelArgs = {
  distinct_on?: InputMaybe<Array<Ai_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Level_Order_By>>;
  where?: InputMaybe<Ai_Level_Bool_Exp>;
};

export type Subscription_RootAi_Level_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Level_Order_By>>;
  where?: InputMaybe<Ai_Level_Bool_Exp>;
};

export type Subscription_RootAi_Level_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootAi_Level_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ai_Level_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Level_Bool_Exp>;
};

export type Subscription_RootAi_RoleArgs = {
  distinct_on?: InputMaybe<Array<Ai_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Role_Order_By>>;
  where?: InputMaybe<Ai_Role_Bool_Exp>;
};

export type Subscription_RootAi_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Role_Order_By>>;
  where?: InputMaybe<Ai_Role_Bool_Exp>;
};

export type Subscription_RootAi_Role_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootAi_Role_Multiplier_FocusArgs = {
  distinct_on?: InputMaybe<Array<Ai_Role_Multiplier_Focus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Role_Multiplier_Focus_Order_By>>;
  where?: InputMaybe<Ai_Role_Multiplier_Focus_Bool_Exp>;
};

export type Subscription_RootAi_Role_Multiplier_Focus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Role_Multiplier_Focus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Role_Multiplier_Focus_Order_By>>;
  where?: InputMaybe<Ai_Role_Multiplier_Focus_Bool_Exp>;
};

export type Subscription_RootAi_Role_Multiplier_Focus_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootAi_Role_Multiplier_Focus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ai_Role_Multiplier_Focus_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Role_Multiplier_Focus_Bool_Exp>;
};

export type Subscription_RootAi_Role_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ai_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Role_Bool_Exp>;
};

export type Subscription_RootAi_Run_DumpArgs = {
  distinct_on?: InputMaybe<Array<Ai_Run_Dump_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Run_Dump_Order_By>>;
  where?: InputMaybe<Ai_Run_Dump_Bool_Exp>;
};

export type Subscription_RootAi_Run_Dump_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Run_Dump_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ai_Run_Dump_Order_By>>;
  where?: InputMaybe<Ai_Run_Dump_Bool_Exp>;
};

export type Subscription_RootAi_Run_Dump_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootAi_Run_Dump_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ai_Run_Dump_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Run_Dump_Bool_Exp>;
};

export type Subscription_RootApplicant_ConsentArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Consent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Consent_Order_By>>;
  where?: InputMaybe<Applicant_Consent_Bool_Exp>;
};

export type Subscription_RootApplicant_Consent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Consent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Consent_Order_By>>;
  where?: InputMaybe<Applicant_Consent_Bool_Exp>;
};

export type Subscription_RootApplicant_Consent_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootApplicant_Consent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Applicant_Consent_Stream_Cursor_Input>>;
  where?: InputMaybe<Applicant_Consent_Bool_Exp>;
};

export type Subscription_RootApplicant_JobArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Job_Order_By>>;
  where?: InputMaybe<Applicant_Job_Bool_Exp>;
};

export type Subscription_RootApplicant_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Job_Order_By>>;
  where?: InputMaybe<Applicant_Job_Bool_Exp>;
};

export type Subscription_RootApplicant_Job_By_PkArgs = {
  candidate_id: Scalars['Int'];
  job: Scalars['Int'];
};

export type Subscription_RootApplicant_Job_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Applicant_Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Applicant_Job_Bool_Exp>;
};

export type Subscription_RootApplicant_WaitlistArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Waitlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Waitlist_Order_By>>;
  where?: InputMaybe<Applicant_Waitlist_Bool_Exp>;
};

export type Subscription_RootApplicant_Waitlist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Applicant_Waitlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Applicant_Waitlist_Order_By>>;
  where?: InputMaybe<Applicant_Waitlist_Bool_Exp>;
};

export type Subscription_RootApplicant_Waitlist_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootApplicant_Waitlist_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Applicant_Waitlist_Stream_Cursor_Input>>;
  where?: InputMaybe<Applicant_Waitlist_Bool_Exp>;
};

export type Subscription_RootAudit_Logged_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

export type Subscription_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

export type Subscription_RootAudit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint'];
};

export type Subscription_RootAudit_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Audit_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

export type Subscription_RootCalendly_EventArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Event_Order_By>>;
  where?: InputMaybe<Calendly_Event_Bool_Exp>;
};

export type Subscription_RootCalendly_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Event_Order_By>>;
  where?: InputMaybe<Calendly_Event_Bool_Exp>;
};

export type Subscription_RootCalendly_Event_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCalendly_Event_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendly_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendly_Event_Bool_Exp>;
};

export type Subscription_RootCalendly_InviteeArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Invitee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Invitee_Order_By>>;
  where?: InputMaybe<Calendly_Invitee_Bool_Exp>;
};

export type Subscription_RootCalendly_Invitee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendly_Invitee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_Invitee_Order_By>>;
  where?: InputMaybe<Calendly_Invitee_Bool_Exp>;
};

export type Subscription_RootCalendly_Invitee_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCalendly_Invitee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendly_Invitee_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendly_Invitee_Bool_Exp>;
};

export type Subscription_RootCalendly_UserArgs = {
  distinct_on?: InputMaybe<Array<Calendly_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_User_Order_By>>;
  where?: InputMaybe<Calendly_User_Bool_Exp>;
};

export type Subscription_RootCalendly_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendly_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendly_User_Order_By>>;
  where?: InputMaybe<Calendly_User_Bool_Exp>;
};

export type Subscription_RootCalendly_User_By_PkArgs = {
  calendly_user_id: Scalars['String'];
};

export type Subscription_RootCalendly_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendly_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendly_User_Bool_Exp>;
};

export type Subscription_RootCandidateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Order_By>>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

export type Subscription_RootCandidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Order_By>>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

export type Subscription_RootCandidate_Ai_Profile_SummaryArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Ai_Profile_Summary_Bool_Exp>;
};

export type Subscription_RootCandidate_Ai_Profile_Summary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Ai_Profile_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Ai_Profile_Summary_Bool_Exp>;
};

export type Subscription_RootCandidate_Ai_Profile_Summary_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Ai_Profile_Summary_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Ai_Profile_Summary_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Ai_Profile_Summary_Bool_Exp>;
};

export type Subscription_RootCandidate_Applicant_Workflow_StatsArgs = {
  args: Candidate_Applicant_Workflow_Stats_Args;
  distinct_on?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Order_By>>;
  where?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>;
};

export type Subscription_RootCandidate_Applicant_Workflow_Stats_AggregateArgs = {
  args: Candidate_Applicant_Workflow_Stats_Args;
  distinct_on?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Order_By>>;
  where?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>;
};

export type Subscription_RootCandidate_Applicant_Workflow_Stats_ResultArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Order_By>>;
  where?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>;
};

export type Subscription_RootCandidate_Applicant_Workflow_Stats_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Applicant_Workflow_Stats_Result_Order_By>>;
  where?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>;
};

export type Subscription_RootCandidate_Applicant_Workflow_Stats_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Applicant_Workflow_Stats_Result_Bool_Exp>;
};

export type Subscription_RootCandidate_Approved_CountryArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Approved_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Approved_Country_Order_By>>;
  where?: InputMaybe<Candidate_Approved_Country_Bool_Exp>;
};

export type Subscription_RootCandidate_Approved_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Approved_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Approved_Country_Order_By>>;
  where?: InputMaybe<Candidate_Approved_Country_Bool_Exp>;
};

export type Subscription_RootCandidate_Approved_Country_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Approved_Country_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Approved_Country_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Approved_Country_Bool_Exp>;
};

export type Subscription_RootCandidate_Availability_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Availability_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Availability_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Availability_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Availability_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Availability_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Availability_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Availability_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Availability_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Availability_Choices_OrderArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Availability_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Availability_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Availability_Choices_Order_Bool_Exp>;
};

export type Subscription_RootCandidate_Availability_Choices_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Availability_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Availability_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Availability_Choices_Order_Bool_Exp>;
};

export type Subscription_RootCandidate_Availability_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Availability_Choices_Order_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Availability_Choices_Order_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Availability_Choices_Order_Bool_Exp>;
};

export type Subscription_RootCandidate_Availability_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Availability_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Availability_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_BadgeArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Badge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Badge_Order_By>>;
  where?: InputMaybe<Candidate_Badge_Bool_Exp>;
};

export type Subscription_RootCandidate_Badge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Badge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Badge_Order_By>>;
  where?: InputMaybe<Candidate_Badge_Bool_Exp>;
};

export type Subscription_RootCandidate_Badge_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

export type Subscription_RootCandidate_Badge_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Badge_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Badge_Bool_Exp>;
};

export type Subscription_RootCandidate_BrowseArgs = {
  args: Candidate_Browse_Args;
  distinct_on?: InputMaybe<Array<Candidate_Browse_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Browse_Result_Order_By>>;
  where?: InputMaybe<Candidate_Browse_Result_Bool_Exp>;
};

export type Subscription_RootCandidate_Browse_AggregateArgs = {
  args: Candidate_Browse_Args;
  distinct_on?: InputMaybe<Array<Candidate_Browse_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Browse_Result_Order_By>>;
  where?: InputMaybe<Candidate_Browse_Result_Bool_Exp>;
};

export type Subscription_RootCandidate_Browse_ResultArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Browse_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Browse_Result_Order_By>>;
  where?: InputMaybe<Candidate_Browse_Result_Bool_Exp>;
};

export type Subscription_RootCandidate_Browse_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Browse_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Browse_Result_Order_By>>;
  where?: InputMaybe<Candidate_Browse_Result_Bool_Exp>;
};

export type Subscription_RootCandidate_Browse_Result_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

export type Subscription_RootCandidate_Browse_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Browse_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Browse_Result_Bool_Exp>;
};

export type Subscription_RootCandidate_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Curation_Ai_SummaryArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Ai_Summary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Ai_Summary_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Curation_Ai_Summary_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Feedback_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Ai_Summary_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Ai_Summary_Feedback_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Ai_Summary_Feedback_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Curation_Ai_Summary_Feedback_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Feedback_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Ai_Summary_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Ai_Summary_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Ai_Summary_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Country_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Country_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Country_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Country_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Country_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Country_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Country_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Country_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Country_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Country_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Country_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Country_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Desired_Salary_Currency_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Desired_Salary_Currency_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Currency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Desired_Salary_Currency_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Desired_Salary_Currency_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Currency_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Desired_Salary_Frequency_ChoicesArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Desired_Salary_Frequency_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Desired_Salary_Frequency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Desired_Salary_Frequency_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Desired_Salary_Frequency_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Candidate_Curation_Desired_Salary_Frequency_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_DetailArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Detail_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Detail_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Detail_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Detail_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Detail_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Curation_Detail_Recruiter_Summary_LogArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Detail_Recruiter_Summary_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Detail_Recruiter_Summary_Log_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Detail_Recruiter_Summary_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Curation_Detail_Recruiter_Summary_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Detail_Recruiter_Summary_Log_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Detail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Detail_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_English_Proficiency_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_English_Proficiency_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_English_Proficiency_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_English_Proficiency_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_English_Proficiency_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_English_Proficiency_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Equity_Importance_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Equity_Importance_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Equity_Importance_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Equity_Importance_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Equity_Importance_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Equity_Importance_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Equity_Importance_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_NoteArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Note_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Note_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Note_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Note_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Note_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Note_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Note_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Curation_Note_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Note_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Note_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Rejected_Reason_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Rejected_Reason_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Rejected_Reason_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Rejected_Reason_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Rejected_Reason_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Rejected_Reason_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Remote_Work_Exp_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Remote_Work_Exp_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Remote_Work_Exp_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Remote_Work_Exp_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Remote_Work_Exp_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Remote_Work_Exp_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Visa_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Visa_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Visa_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Visa_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Visa_Status_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Visa_Status_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Visa_Status_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_WorkflowArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Curation_Workflow_Needs_Info_ReasonArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Needs_Info_Reason_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Needs_Info_Reason_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Curation_Workflow_Needs_Info_Reason_ChoicesArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Needs_Info_Reason_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Needs_Info_Reason_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Workflow_Needs_Info_Reason_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Needs_Info_Reason_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Needs_Info_Reason_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Screener_Type_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Screener_Type_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Screener_Type_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Screener_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Workflow_Screener_Type_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Screener_Type_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Source_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Source_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Workflow_Source_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Workflow_Source_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Workflow_Status_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Workflow_Status_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Status_LogArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Status_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Workflow_Status_Log_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_Status_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Curation_Workflow_Status_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Workflow_Status_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Status_Log_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Workflow_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Workflow_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Workflow_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Years_Of_Exp_Range_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Years_Of_Exp_Range_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Years_Of_Exp_Range_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Years_Of_Exp_Range_Choices_OrderArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Years_Of_Exp_Range_Choices_Order_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Years_Of_Exp_Range_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Curation_Years_Of_Exp_Range_Choices_Order_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Order_Bool_Exp>;
};

export type Subscription_RootCandidate_Curation_Years_Of_Exp_Range_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Curation_Years_Of_Exp_Range_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Detail_Level_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Detail_Level_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Detail_Level_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Detail_Level_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Detail_Level_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Detail_Level_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Detail_Level_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Detail_Level_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Detail_Level_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Detail_Level_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Detail_Level_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Detail_Level_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_EducationArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Order_By>>;
  where?: InputMaybe<Candidate_Education_Bool_Exp>;
};

export type Subscription_RootCandidate_Education_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Order_By>>;
  where?: InputMaybe<Candidate_Education_Bool_Exp>;
};

export type Subscription_RootCandidate_Education_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Education_Concentration_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Education_Concentration_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Education_Concentration_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Concentration_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Education_Concentration_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Education_Concentration_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Education_Concentration_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Education_Concentration_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Education_Concentration_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Education_Degree_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Degree_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Degree_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Education_Degree_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Education_Degree_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Education_Degree_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Education_Degree_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Education_Degree_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Education_Degree_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Education_Degree_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Education_Degree_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Education_Degree_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Education_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Education_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Education_Bool_Exp>;
};

export type Subscription_RootCandidate_EmailArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Email_Order_By>>;
  where?: InputMaybe<Candidate_Email_Bool_Exp>;
};

export type Subscription_RootCandidate_Email_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Email_Order_By>>;
  where?: InputMaybe<Candidate_Email_Bool_Exp>;
};

export type Subscription_RootCandidate_Email_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Email_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Email_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Email_Bool_Exp>;
};

export type Subscription_RootCandidate_EmbeddingArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Embedding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Embedding_Order_By>>;
  where?: InputMaybe<Candidate_Embedding_Bool_Exp>;
};

export type Subscription_RootCandidate_Embedding_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Embedding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Embedding_Order_By>>;
  where?: InputMaybe<Candidate_Embedding_Bool_Exp>;
};

export type Subscription_RootCandidate_Embedding_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Embedding_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Embedding_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Embedding_Bool_Exp>;
};

export type Subscription_RootCandidate_Highlight_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Highlight_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Highlight_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Highlight_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Highlight_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Highlight_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Highlight_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Highlight_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Highlight_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Highlight_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Highlight_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Highlight_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Hub_LocationArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Hub_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Hub_Location_Order_By>>;
  where?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
};

export type Subscription_RootCandidate_Hub_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Hub_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Hub_Location_Order_By>>;
  where?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
};

export type Subscription_RootCandidate_Hub_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Hub_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Hub_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Hub_Location_Bool_Exp>;
};

export type Subscription_RootCandidate_Interview_InviteArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Interview_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Interview_Invite_Order_By>>;
  where?: InputMaybe<Candidate_Interview_Invite_Bool_Exp>;
};

export type Subscription_RootCandidate_Interview_Invite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Interview_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Interview_Invite_Order_By>>;
  where?: InputMaybe<Candidate_Interview_Invite_Bool_Exp>;
};

export type Subscription_RootCandidate_Interview_Invite_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Interview_Invite_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Interview_Invite_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Interview_Invite_Bool_Exp>;
};

export type Subscription_RootCandidate_JobArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Order_By>>;
  where?: InputMaybe<Candidate_Job_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Order_By>>;
  where?: InputMaybe<Candidate_Job_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_By_PkArgs = {
  candidate_id: Scalars['Int'];
  job: Scalars['Int'];
};

export type Subscription_RootCandidate_Job_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Job_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_WorkflowArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_Workflow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_Workflow_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Job_Workflow_Source_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_Workflow_Source_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_Workflow_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Job_Workflow_Source_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Job_Workflow_Source_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Job_Workflow_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_Workflow_StatusArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Status_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_Workflow_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Status_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_Workflow_Status_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Job_Workflow_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_Workflow_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Job_Workflow_Status_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_Workflow_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Job_Workflow_Status_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Job_Workflow_Status_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_Workflow_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Job_Workflow_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Job_Workflow_Status_Bool_Exp>;
};

export type Subscription_RootCandidate_Job_Workflow_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Job_Workflow_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Job_Workflow_Bool_Exp>;
};

export type Subscription_RootCandidate_Parsed_ResumeArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Parsed_Resume_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Parsed_Resume_Order_By>>;
  where?: InputMaybe<Candidate_Parsed_Resume_Bool_Exp>;
};

export type Subscription_RootCandidate_Parsed_Resume_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Parsed_Resume_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Parsed_Resume_Order_By>>;
  where?: InputMaybe<Candidate_Parsed_Resume_Bool_Exp>;
};

export type Subscription_RootCandidate_Parsed_Resume_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

export type Subscription_RootCandidate_Parsed_Resume_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Parsed_Resume_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Parsed_Resume_Bool_Exp>;
};

export type Subscription_RootCandidate_PdlArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Pdl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Pdl_Order_By>>;
  where?: InputMaybe<Candidate_Pdl_Bool_Exp>;
};

export type Subscription_RootCandidate_Pdl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Pdl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Pdl_Order_By>>;
  where?: InputMaybe<Candidate_Pdl_Bool_Exp>;
};

export type Subscription_RootCandidate_Pdl_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Pdl_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Pdl_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Pdl_Bool_Exp>;
};

export type Subscription_RootCandidate_ReferralsArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Referrals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Referrals_Order_By>>;
  where?: InputMaybe<Candidate_Referrals_Bool_Exp>;
};

export type Subscription_RootCandidate_Referrals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Referrals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Referrals_Order_By>>;
  where?: InputMaybe<Candidate_Referrals_Bool_Exp>;
};

export type Subscription_RootCandidate_Referrals_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Referrals_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Referrals_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Referrals_Bool_Exp>;
};

export type Subscription_RootCandidate_RoleArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Order_By>>;
  where?: InputMaybe<Candidate_Role_Bool_Exp>;
};

export type Subscription_RootCandidate_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Order_By>>;
  where?: InputMaybe<Candidate_Role_Bool_Exp>;
};

export type Subscription_RootCandidate_Role_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Role_SkillArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Role_Skill_Bool_Exp>;
};

export type Subscription_RootCandidate_Role_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Role_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Role_Skill_Bool_Exp>;
};

export type Subscription_RootCandidate_Role_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Role_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Role_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Role_Skill_Bool_Exp>;
};

export type Subscription_RootCandidate_Role_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Role_Bool_Exp>;
};

export type Subscription_RootCandidate_Sdlc_Experience_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Sdlc_Experience_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Sdlc_Experience_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Sdlc_Experience_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Sdlc_Experience_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Sdlc_Experience_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Sdlc_Experience_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Sdlc_Experience_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Sdlc_Experience_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_SkillArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Bool_Exp>;
};

export type Subscription_RootCandidate_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Bool_Exp>;
};

export type Subscription_RootCandidate_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Skill_Comp_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Comp_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Skill_Comp_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Skill_Comp_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Skill_Comp_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Skill_Comp_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Skill_Comp_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Skill_Comp_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Skill_Comp_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Skill_Bool_Exp>;
};

export type Subscription_RootCandidate_Source_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Source_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Source_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Source_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Stage_Choices_OrderArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Choices_Order_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_Choices_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Choices_Order_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Choices_Order_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Stage_Choices_Order_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Stage_Choices_Order_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Stage_Choices_Order_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Stage_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Stage_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_LogArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Log_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Log_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Log_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Log_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Stage_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Stage_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Stage_Log_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_Source_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_Source_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Stage_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Stage_Source_Choices_Order_By>>;
  where?: InputMaybe<Candidate_Stage_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_Stage_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCandidate_Stage_Source_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Stage_Source_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Stage_Source_Choices_Bool_Exp>;
};

export type Subscription_RootCandidate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Bool_Exp>;
};

export type Subscription_RootCandidate_Suggested_JobArgs = {
  args: Candidate_Suggested_Job_Args;
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Subscription_RootCandidate_Suggested_Job_AggregateArgs = {
  args: Candidate_Suggested_Job_Args;
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Subscription_RootCandidate_Suggested_Job_CoreArgs = {
  args: Candidate_Suggested_Job_Core_Args;
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Subscription_RootCandidate_Suggested_Job_Core_AggregateArgs = {
  args: Candidate_Suggested_Job_Core_Args;
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Subscription_RootCandidate_TraitArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Trait_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Trait_Order_By>>;
  where?: InputMaybe<Candidate_Trait_Bool_Exp>;
};

export type Subscription_RootCandidate_Trait_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Trait_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Trait_Order_By>>;
  where?: InputMaybe<Candidate_Trait_Bool_Exp>;
};

export type Subscription_RootCandidate_Trait_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Trait_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Trait_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Trait_Bool_Exp>;
};

export type Subscription_RootCandidate_Work_ExperienceArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
};

export type Subscription_RootCandidate_Work_Experience_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
};

export type Subscription_RootCandidate_Work_Experience_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Work_Experience_SkillArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Skill_Bool_Exp>;
};

export type Subscription_RootCandidate_Work_Experience_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Candidate_Work_Experience_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidate_Work_Experience_Skill_Order_By>>;
  where?: InputMaybe<Candidate_Work_Experience_Skill_Bool_Exp>;
};

export type Subscription_RootCandidate_Work_Experience_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCandidate_Work_Experience_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Work_Experience_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Work_Experience_Skill_Bool_Exp>;
};

export type Subscription_RootCandidate_Work_Experience_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Candidate_Work_Experience_Stream_Cursor_Input>>;
  where?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
};

export type Subscription_RootCandidates_In_Interview_Or_Rejected_By_OrganizationArgs = {
  args: Candidates_In_Interview_Or_Rejected_By_Organization_Args;
  distinct_on?: InputMaybe<
    Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Order_By>>;
  where?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>;
};

export type Subscription_RootCandidates_In_Interview_Or_Rejected_By_Organization_AggregateArgs = {
  args: Candidates_In_Interview_Or_Rejected_By_Organization_Args;
  distinct_on?: InputMaybe<
    Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Order_By>>;
  where?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>;
};

export type Subscription_RootCandidates_In_Interview_Or_Rejected_By_Organization_ResultArgs = {
  distinct_on?: InputMaybe<
    Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Order_By>>;
  where?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>;
};

export type Subscription_RootCandidates_In_Interview_Or_Rejected_By_Organization_Result_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<
      Array<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Order_By>
    >;
    where?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>;
  };

export type Subscription_RootCandidates_In_Interview_Or_Rejected_By_Organization_Result_StreamArgs =
  {
    batch_size: Scalars['Int'];
    cursor: Array<
      InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Candidates_In_Interview_Or_Rejected_By_Organization_Result_Bool_Exp>;
  };

export type Subscription_RootCelery_Celery_TaskmetaArgs = {
  distinct_on?: InputMaybe<Array<Celery_Celery_Taskmeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Celery_Taskmeta_Order_By>>;
  where?: InputMaybe<Celery_Celery_Taskmeta_Bool_Exp>;
};

export type Subscription_RootCelery_Celery_Taskmeta_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Celery_Celery_Taskmeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Celery_Taskmeta_Order_By>>;
  where?: InputMaybe<Celery_Celery_Taskmeta_Bool_Exp>;
};

export type Subscription_RootCelery_Celery_Taskmeta_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCelery_Celery_Taskmeta_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Celery_Celery_Taskmeta_Stream_Cursor_Input>>;
  where?: InputMaybe<Celery_Celery_Taskmeta_Bool_Exp>;
};

export type Subscription_RootCelery_Celery_TasksetmetaArgs = {
  distinct_on?: InputMaybe<Array<Celery_Celery_Tasksetmeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Celery_Tasksetmeta_Order_By>>;
  where?: InputMaybe<Celery_Celery_Tasksetmeta_Bool_Exp>;
};

export type Subscription_RootCelery_Celery_Tasksetmeta_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Celery_Celery_Tasksetmeta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Celery_Tasksetmeta_Order_By>>;
  where?: InputMaybe<Celery_Celery_Tasksetmeta_Bool_Exp>;
};

export type Subscription_RootCelery_Celery_Tasksetmeta_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCelery_Celery_Tasksetmeta_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Celery_Celery_Tasksetmeta_Stream_Cursor_Input>>;
  where?: InputMaybe<Celery_Celery_Tasksetmeta_Bool_Exp>;
};

export type Subscription_RootCelery_Task_Retry_LogArgs = {
  distinct_on?: InputMaybe<Array<Celery_Task_Retry_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Task_Retry_Log_Order_By>>;
  where?: InputMaybe<Celery_Task_Retry_Log_Bool_Exp>;
};

export type Subscription_RootCelery_Task_Retry_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Celery_Task_Retry_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Celery_Task_Retry_Log_Order_By>>;
  where?: InputMaybe<Celery_Task_Retry_Log_Bool_Exp>;
};

export type Subscription_RootCelery_Task_Retry_Log_By_PkArgs = {
  new_task_id: Scalars['String'];
  original_task_id: Scalars['String'];
};

export type Subscription_RootCelery_Task_Retry_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Celery_Task_Retry_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Celery_Task_Retry_Log_Bool_Exp>;
};

export type Subscription_RootClient_ProspectArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Order_By>>;
  where?: InputMaybe<Client_Prospect_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Order_By>>;
  where?: InputMaybe<Client_Prospect_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootClient_Prospect_EventArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Event_Order_By>>;
  where?: InputMaybe<Client_Prospect_Event_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Event_Order_By>>;
  where?: InputMaybe<Client_Prospect_Event_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_Event_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootClient_Prospect_Event_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Prospect_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Prospect_Event_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_Events_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Events_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Events_Choices_Order_By>>;
  where?: InputMaybe<Client_Prospect_Events_Choices_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_Events_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Events_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Events_Choices_Order_By>>;
  where?: InputMaybe<Client_Prospect_Events_Choices_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_Events_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootClient_Prospect_Events_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Prospect_Events_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Prospect_Events_Choices_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_RoleArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_Role_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootClient_Prospect_Role_SkillArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Skill_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Skill_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_Role_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Prospect_Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Prospect_Role_Skill_Order_By>>;
  where?: InputMaybe<Client_Prospect_Role_Skill_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_Role_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootClient_Prospect_Role_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Prospect_Role_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Prospect_Role_Skill_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_Role_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Prospect_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Prospect_Role_Bool_Exp>;
};

export type Subscription_RootClient_Prospect_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Prospect_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Prospect_Bool_Exp>;
};

export type Subscription_RootClient_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Client_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Versions_Order_By>>;
  where?: InputMaybe<Client_Versions_Bool_Exp>;
};

export type Subscription_RootClient_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Versions_Order_By>>;
  where?: InputMaybe<Client_Versions_Bool_Exp>;
};

export type Subscription_RootClient_Versions_By_PkArgs = {
  version: Scalars['String'];
};

export type Subscription_RootClient_Versions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Versions_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Versions_Bool_Exp>;
};

export type Subscription_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCompany_Name_SynonymArgs = {
  distinct_on?: InputMaybe<Array<Company_Name_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Name_Synonym_Order_By>>;
  where?: InputMaybe<Company_Name_Synonym_Bool_Exp>;
};

export type Subscription_RootCompany_Name_Synonym_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Name_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Name_Synonym_Order_By>>;
  where?: InputMaybe<Company_Name_Synonym_Bool_Exp>;
};

export type Subscription_RootCompany_Name_Synonym_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCompany_Name_Synonym_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Name_Synonym_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Name_Synonym_Bool_Exp>;
};

export type Subscription_RootCompany_Pdl_BlobArgs = {
  distinct_on?: InputMaybe<Array<Company_Pdl_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Pdl_Blob_Order_By>>;
  where?: InputMaybe<Company_Pdl_Blob_Bool_Exp>;
};

export type Subscription_RootCompany_Pdl_Blob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Pdl_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Pdl_Blob_Order_By>>;
  where?: InputMaybe<Company_Pdl_Blob_Bool_Exp>;
};

export type Subscription_RootCompany_Pdl_Blob_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCompany_Pdl_Blob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Pdl_Blob_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Pdl_Blob_Bool_Exp>;
};

export type Subscription_RootCompany_Size_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Company_Size_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Size_Choices_Order_By>>;
  where?: InputMaybe<Company_Size_Choices_Bool_Exp>;
};

export type Subscription_RootCompany_Size_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Size_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Size_Choices_Order_By>>;
  where?: InputMaybe<Company_Size_Choices_Bool_Exp>;
};

export type Subscription_RootCompany_Size_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCompany_Size_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Size_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Size_Choices_Bool_Exp>;
};

export type Subscription_RootCompany_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Subscription_RootContactArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type Subscription_RootContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type Subscription_RootContact_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootContact_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contact_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type Subscription_RootContract_Length_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Contract_Length_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Length_Choices_Order_By>>;
  where?: InputMaybe<Contract_Length_Choices_Bool_Exp>;
};

export type Subscription_RootContract_Length_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contract_Length_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contract_Length_Choices_Order_By>>;
  where?: InputMaybe<Contract_Length_Choices_Bool_Exp>;
};

export type Subscription_RootContract_Length_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootContract_Length_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contract_Length_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Contract_Length_Choices_Bool_Exp>;
};

export type Subscription_RootCountry_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Country_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Choices_Order_By>>;
  where?: InputMaybe<Country_Choices_Bool_Exp>;
};

export type Subscription_RootCountry_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Choices_Order_By>>;
  where?: InputMaybe<Country_Choices_Bool_Exp>;
};

export type Subscription_RootCountry_Choices_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCountry_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Country_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Country_Choices_Bool_Exp>;
};

export type Subscription_RootCurrency_Exchange_RateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Exchange_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Exchange_Rate_Order_By>>;
  where?: InputMaybe<Currency_Exchange_Rate_Bool_Exp>;
};

export type Subscription_RootCurrency_Exchange_Rate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Exchange_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_Exchange_Rate_Order_By>>;
  where?: InputMaybe<Currency_Exchange_Rate_Bool_Exp>;
};

export type Subscription_RootCurrency_Exchange_Rate_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCurrency_Exchange_Rate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Currency_Exchange_Rate_Stream_Cursor_Input>>;
  where?: InputMaybe<Currency_Exchange_Rate_Bool_Exp>;
};

export type Subscription_RootDayforce_AssignmentArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Assignment_Order_By>>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

export type Subscription_RootDayforce_Assignment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Assignment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Assignment_Order_By>>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

export type Subscription_RootDayforce_Assignment_By_PkArgs = {
  dayforce_customer_id: Scalars['Int'];
  dayforce_employee_id: Scalars['Int'];
  dayforce_location_id: Scalars['Int'];
  effective_start: Scalars['timestamp'];
};

export type Subscription_RootDayforce_Assignment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dayforce_Assignment_Stream_Cursor_Input>>;
  where?: InputMaybe<Dayforce_Assignment_Bool_Exp>;
};

export type Subscription_RootDayforce_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Customer_Order_By>>;
  where?: InputMaybe<Dayforce_Customer_Bool_Exp>;
};

export type Subscription_RootDayforce_Customer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Customer_Order_By>>;
  where?: InputMaybe<Dayforce_Customer_Bool_Exp>;
};

export type Subscription_RootDayforce_Customer_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootDayforce_Customer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dayforce_Customer_Stream_Cursor_Input>>;
  where?: InputMaybe<Dayforce_Customer_Bool_Exp>;
};

export type Subscription_RootDayforce_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Employee_Order_By>>;
  where?: InputMaybe<Dayforce_Employee_Bool_Exp>;
};

export type Subscription_RootDayforce_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Employee_Order_By>>;
  where?: InputMaybe<Dayforce_Employee_Bool_Exp>;
};

export type Subscription_RootDayforce_Employee_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootDayforce_Employee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dayforce_Employee_Stream_Cursor_Input>>;
  where?: InputMaybe<Dayforce_Employee_Bool_Exp>;
};

export type Subscription_RootDayforce_LocationArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Location_Order_By>>;
  where?: InputMaybe<Dayforce_Location_Bool_Exp>;
};

export type Subscription_RootDayforce_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dayforce_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dayforce_Location_Order_By>>;
  where?: InputMaybe<Dayforce_Location_Bool_Exp>;
};

export type Subscription_RootDayforce_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootDayforce_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dayforce_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Dayforce_Location_Bool_Exp>;
};

export type Subscription_RootEmail_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Email_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Notification_Order_By>>;
  where?: InputMaybe<Email_Notification_Bool_Exp>;
};

export type Subscription_RootEmail_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Notification_Order_By>>;
  where?: InputMaybe<Email_Notification_Bool_Exp>;
};

export type Subscription_RootEmail_Notification_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEmail_Notification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Email_Notification_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Notification_Bool_Exp>;
};

export type Subscription_RootEmail_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Email_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Template_Order_By>>;
  where?: InputMaybe<Email_Template_Bool_Exp>;
};

export type Subscription_RootEmail_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Template_Order_By>>;
  where?: InputMaybe<Email_Template_Bool_Exp>;
};

export type Subscription_RootEmail_Template_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEmail_Template_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Email_Template_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Template_Bool_Exp>;
};

export type Subscription_RootEmployment_Type_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Employment_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employment_Type_Choices_Order_By>>;
  where?: InputMaybe<Employment_Type_Choices_Bool_Exp>;
};

export type Subscription_RootEmployment_Type_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employment_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employment_Type_Choices_Order_By>>;
  where?: InputMaybe<Employment_Type_Choices_Bool_Exp>;
};

export type Subscription_RootEmployment_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEmployment_Type_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employment_Type_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Employment_Type_Choices_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Popular_SearchArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Popular_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Popular_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Popular_Search_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Popular_Search_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Popular_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Popular_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Popular_Search_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Popular_Search_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootExplore_Candidates_Popular_Search_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Explore_Candidates_Popular_Search_Stream_Cursor_Input>>;
  where?: InputMaybe<Explore_Candidates_Popular_Search_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_QueryArgs = {
  args: Explore_Candidates_Query_Args;
  distinct_on?: InputMaybe<Array<Explore_Candidates_Query_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Query_Result_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Query_Result_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Query_AggregateArgs = {
  args: Explore_Candidates_Query_Args;
  distinct_on?: InputMaybe<Array<Explore_Candidates_Query_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Query_Result_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Query_Result_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Query_ResultArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Query_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Query_Result_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Query_Result_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Query_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Query_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Query_Result_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Query_Result_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Query_Result_By_PkArgs = {
  candidate_id: Scalars['Int'];
};

export type Subscription_RootExplore_Candidates_Query_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Explore_Candidates_Query_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Explore_Candidates_Query_Result_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_SearchArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootExplore_Candidates_Search_Candidate_HighlightArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_Candidate_Highlight_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Candidate_Highlight_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_Candidate_Highlight_By_PkArgs = {
  candidate_highlight: Candidate_Highlight_Choices_Enum;
  explore_candidates_search_id: Scalars['Int'];
};

export type Subscription_RootExplore_Candidates_Search_Candidate_Highlight_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Stream_Cursor_Input>>;
  where?: InputMaybe<Explore_Candidates_Search_Candidate_Highlight_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_Must_Have_SkillArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_Must_Have_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Must_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_Must_Have_Skill_By_PkArgs = {
  explore_candidates_search_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

export type Subscription_RootExplore_Candidates_Search_Must_Have_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Explore_Candidates_Search_Must_Have_Skill_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_Nice_To_Have_SkillArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_Nice_To_Have_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_Nice_To_Have_Skill_By_PkArgs = {
  explore_candidates_search_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

export type Subscription_RootExplore_Candidates_Search_Nice_To_Have_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Explore_Candidates_Search_Nice_To_Have_Skill_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_RegionArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Region_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Region_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_Region_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_Search_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_Search_Region_Order_By>>;
  where?: InputMaybe<Explore_Candidates_Search_Region_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_Region_By_PkArgs = {
  explore_candidates_search_id: Scalars['Int'];
  region: Region_Choices_Enum;
};

export type Subscription_RootExplore_Candidates_Search_Region_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Explore_Candidates_Search_Region_Stream_Cursor_Input>>;
  where?: InputMaybe<Explore_Candidates_Search_Region_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_Search_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Explore_Candidates_Search_Stream_Cursor_Input>>;
  where?: InputMaybe<Explore_Candidates_Search_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_User_Saved_SearchArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_User_Saved_Search_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_User_Saved_Search_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Explore_Candidates_User_Saved_Search_Order_By>>;
  where?: InputMaybe<Explore_Candidates_User_Saved_Search_Bool_Exp>;
};

export type Subscription_RootExplore_Candidates_User_Saved_Search_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootExplore_Candidates_User_Saved_Search_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Explore_Candidates_User_Saved_Search_Stream_Cursor_Input>>;
  where?: InputMaybe<Explore_Candidates_User_Saved_Search_Bool_Exp>;
};

export type Subscription_RootExternal_Candidate_MetaArgs = {
  distinct_on?: InputMaybe<Array<External_Candidate_Meta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<External_Candidate_Meta_Order_By>>;
  where?: InputMaybe<External_Candidate_Meta_Bool_Exp>;
};

export type Subscription_RootExternal_Candidate_Meta_AggregateArgs = {
  distinct_on?: InputMaybe<Array<External_Candidate_Meta_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<External_Candidate_Meta_Order_By>>;
  where?: InputMaybe<External_Candidate_Meta_Bool_Exp>;
};

export type Subscription_RootExternal_Candidate_Meta_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootExternal_Candidate_Meta_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<External_Candidate_Meta_Stream_Cursor_Input>>;
  where?: InputMaybe<External_Candidate_Meta_Bool_Exp>;
};

export type Subscription_RootFeatureArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Subscription_RootFeature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Subscription_RootFeature_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootFeature_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Feature_Stream_Cursor_Input>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Subscription_RootHireflix_TranscriptArgs = {
  distinct_on?: InputMaybe<Array<Hireflix_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hireflix_Transcript_Order_By>>;
  where?: InputMaybe<Hireflix_Transcript_Bool_Exp>;
};

export type Subscription_RootHireflix_Transcript_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hireflix_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hireflix_Transcript_Order_By>>;
  where?: InputMaybe<Hireflix_Transcript_Bool_Exp>;
};

export type Subscription_RootHireflix_Transcript_BlobArgs = {
  distinct_on?: InputMaybe<Array<Hireflix_Transcript_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hireflix_Transcript_Blob_Order_By>>;
  where?: InputMaybe<Hireflix_Transcript_Blob_Bool_Exp>;
};

export type Subscription_RootHireflix_Transcript_Blob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hireflix_Transcript_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hireflix_Transcript_Blob_Order_By>>;
  where?: InputMaybe<Hireflix_Transcript_Blob_Bool_Exp>;
};

export type Subscription_RootHireflix_Transcript_Blob_By_PkArgs = {
  interview_id: Scalars['String'];
};

export type Subscription_RootHireflix_Transcript_Blob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hireflix_Transcript_Blob_Stream_Cursor_Input>>;
  where?: InputMaybe<Hireflix_Transcript_Blob_Bool_Exp>;
};

export type Subscription_RootHireflix_Transcript_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootHireflix_Transcript_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hireflix_Transcript_Stream_Cursor_Input>>;
  where?: InputMaybe<Hireflix_Transcript_Bool_Exp>;
};

export type Subscription_RootHr_TestArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Order_By>>;
  where?: InputMaybe<Hr_Test_Bool_Exp>;
};

export type Subscription_RootHr_Test_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Order_By>>;
  where?: InputMaybe<Hr_Test_Bool_Exp>;
};

export type Subscription_RootHr_Test_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootHr_Test_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Candidate_Order_By>>;
  where?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
};

export type Subscription_RootHr_Test_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Candidate_Order_By>>;
  where?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
};

export type Subscription_RootHr_Test_Candidate_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootHr_Test_Candidate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hr_Test_Candidate_Stream_Cursor_Input>>;
  where?: InputMaybe<Hr_Test_Candidate_Bool_Exp>;
};

export type Subscription_RootHr_Test_ResultArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Result_Order_By>>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

export type Subscription_RootHr_Test_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hr_Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hr_Test_Result_Order_By>>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

export type Subscription_RootHr_Test_Result_By_PkArgs = {
  attempt_id: Scalars['String'];
  hr_test_candidate_id: Scalars['String'];
};

export type Subscription_RootHr_Test_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hr_Test_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Hr_Test_Result_Bool_Exp>;
};

export type Subscription_RootHr_Test_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hr_Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Hr_Test_Bool_Exp>;
};

export type Subscription_RootIcims_Applicant_WorkflowArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

export type Subscription_RootIcims_Applicant_Workflow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

export type Subscription_RootIcims_Applicant_Workflow_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

export type Subscription_RootIcims_Applicant_Workflow_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
};

export type Subscription_RootIcims_Applicant_Workflow_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Feedback_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
};

export type Subscription_RootIcims_Applicant_Workflow_Feedback_By_PkArgs = {
  applicant_workflow_profile_id: Scalars['Int'];
  id: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
};

export type Subscription_RootIcims_Applicant_Workflow_Feedback_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Applicant_Workflow_Feedback_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Feedback_Bool_Exp>;
};

export type Subscription_RootIcims_Applicant_Workflow_StatusArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

export type Subscription_RootIcims_Applicant_Workflow_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

export type Subscription_RootIcims_Applicant_Workflow_Status_By_PkArgs = {
  applicant_workflow_profile_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  initial_data_transfer: Scalars['Int'];
  status_id: Scalars['String'];
};

export type Subscription_RootIcims_Applicant_Workflow_Status_CurrentArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

export type Subscription_RootIcims_Applicant_Workflow_Status_Current_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Applicant_Workflow_Status_Order_By>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

export type Subscription_RootIcims_Applicant_Workflow_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Applicant_Workflow_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Status_Bool_Exp>;
};

export type Subscription_RootIcims_Applicant_Workflow_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Applicant_Workflow_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Applicant_Workflow_Bool_Exp>;
};

export type Subscription_RootIcims_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Icims_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Company_Order_By>>;
  where?: InputMaybe<Icims_Company_Bool_Exp>;
};

export type Subscription_RootIcims_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Company_Order_By>>;
  where?: InputMaybe<Icims_Company_Bool_Exp>;
};

export type Subscription_RootIcims_Company_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

export type Subscription_RootIcims_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Company_Bool_Exp>;
};

export type Subscription_RootIcims_CountryArgs = {
  distinct_on?: InputMaybe<Array<Icims_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Country_Order_By>>;
  where?: InputMaybe<Icims_Country_Bool_Exp>;
};

export type Subscription_RootIcims_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Country_Order_By>>;
  where?: InputMaybe<Icims_Country_Bool_Exp>;
};

export type Subscription_RootIcims_Country_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Subscription_RootIcims_Country_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Country_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Country_Bool_Exp>;
};

export type Subscription_RootIcims_DegreeArgs = {
  distinct_on?: InputMaybe<Array<Icims_Degree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Degree_Order_By>>;
  where?: InputMaybe<Icims_Degree_Bool_Exp>;
};

export type Subscription_RootIcims_Degree_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Degree_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Degree_Order_By>>;
  where?: InputMaybe<Icims_Degree_Bool_Exp>;
};

export type Subscription_RootIcims_Degree_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Subscription_RootIcims_Degree_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Degree_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Degree_Bool_Exp>;
};

export type Subscription_RootIcims_EducationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Education_Order_By>>;
  where?: InputMaybe<Icims_Education_Bool_Exp>;
};

export type Subscription_RootIcims_Education_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Education_Order_By>>;
  where?: InputMaybe<Icims_Education_Bool_Exp>;
};

export type Subscription_RootIcims_Education_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

export type Subscription_RootIcims_Education_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Education_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Education_Bool_Exp>;
};

export type Subscription_RootIcims_FolderArgs = {
  distinct_on?: InputMaybe<Array<Icims_Folder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Folder_Order_By>>;
  where?: InputMaybe<Icims_Folder_Bool_Exp>;
};

export type Subscription_RootIcims_Folder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Folder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Folder_Order_By>>;
  where?: InputMaybe<Icims_Folder_Bool_Exp>;
};

export type Subscription_RootIcims_Folder_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Subscription_RootIcims_Folder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Folder_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Folder_Bool_Exp>;
};

export type Subscription_RootIcims_Initial_Data_TransferArgs = {
  distinct_on?: InputMaybe<Array<Icims_Initial_Data_Transfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Initial_Data_Transfer_Order_By>>;
  where?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
};

export type Subscription_RootIcims_Initial_Data_Transfer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Initial_Data_Transfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Initial_Data_Transfer_Order_By>>;
  where?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
};

export type Subscription_RootIcims_Initial_Data_Transfer_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootIcims_Initial_Data_Transfer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Initial_Data_Transfer_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Initial_Data_Transfer_Bool_Exp>;
};

export type Subscription_RootIcims_Interested_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Interested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Interested_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Interested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Interested_Location_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Subscription_RootIcims_Interested_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Interested_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Interested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_JobArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Subscription_RootIcims_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Order_By>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Subscription_RootIcims_Job_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

export type Subscription_RootIcims_Job_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Job_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Job_Location_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
};

export type Subscription_RootIcims_Job_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Job_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Job_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Job_Requested_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Job_Requested_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Job_Requested_Location_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
  location: Scalars['String'];
};

export type Subscription_RootIcims_Job_Requested_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Job_Requested_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Job_Requested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Job_SkillArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Skill_Order_By>>;
  where?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
};

export type Subscription_RootIcims_Job_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Job_Skill_Order_By>>;
  where?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
};

export type Subscription_RootIcims_Job_Skill_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  job_profile_id: Scalars['Int'];
};

export type Subscription_RootIcims_Job_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Job_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Job_Skill_Bool_Exp>;
};

export type Subscription_RootIcims_Job_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Job_Bool_Exp>;
};

export type Subscription_RootIcims_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Location_Order_By>>;
  where?: InputMaybe<Icims_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Location_Order_By>>;
  where?: InputMaybe<Icims_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Location_By_PkArgs = {
  id: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
};

export type Subscription_RootIcims_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Location_Bool_Exp>;
};

export type Subscription_RootIcims_MajorArgs = {
  distinct_on?: InputMaybe<Array<Icims_Major_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Major_Order_By>>;
  where?: InputMaybe<Icims_Major_Bool_Exp>;
};

export type Subscription_RootIcims_Major_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Major_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Major_Order_By>>;
  where?: InputMaybe<Icims_Major_Bool_Exp>;
};

export type Subscription_RootIcims_Major_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Subscription_RootIcims_Major_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Major_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Major_Bool_Exp>;
};

export type Subscription_RootIcims_PersonArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Order_By>>;
  where?: InputMaybe<Icims_Person_Bool_Exp>;
};

export type Subscription_RootIcims_Person_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Order_By>>;
  where?: InputMaybe<Icims_Person_Bool_Exp>;
};

export type Subscription_RootIcims_Person_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  profile_id: Scalars['Int'];
};

export type Subscription_RootIcims_Person_Interested_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Person_Interested_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Interested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Interested_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Person_Interested_Location_By_PkArgs = {
  initial_data_transfer: Scalars['Int'];
  interested_location: Scalars['String'];
  person_profile_id: Scalars['Int'];
};

export type Subscription_RootIcims_Person_Interested_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Person_Interested_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Person_Interested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Person_Preferred_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Preferred_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Preferred_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Preferred_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Person_Preferred_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Preferred_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Preferred_Location_Order_By>>;
  where?: InputMaybe<Icims_Person_Preferred_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Person_Preferred_Location_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

export type Subscription_RootIcims_Person_Preferred_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Person_Preferred_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Person_Preferred_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Person_SkillArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Skill_Order_By>>;
  where?: InputMaybe<Icims_Person_Skill_Bool_Exp>;
};

export type Subscription_RootIcims_Person_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Person_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Person_Skill_Order_By>>;
  where?: InputMaybe<Icims_Person_Skill_Bool_Exp>;
};

export type Subscription_RootIcims_Person_Skill_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

export type Subscription_RootIcims_Person_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Person_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Person_Skill_Bool_Exp>;
};

export type Subscription_RootIcims_Person_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Person_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Person_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Applicant_WorkflowArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Profile_Applicant_Workflow_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Applicant_Workflow_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Applicant_Workflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Applicant_Workflow_Order_By>>;
  where?: InputMaybe<Icims_Profile_Applicant_Workflow_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Applicant_Workflow_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootIcims_Profile_Applicant_Workflow_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Profile_Applicant_Workflow_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Profile_Applicant_Workflow_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_JobArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Job_Order_By>>;
  where?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Job_Order_By>>;
  where?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Job_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootIcims_Profile_Job_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Profile_Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Profile_Job_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Location_Order_By>>;
  where?: InputMaybe<Icims_Profile_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Location_Order_By>>;
  where?: InputMaybe<Icims_Profile_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootIcims_Profile_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Profile_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Profile_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_PersonArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Person_Order_By>>;
  where?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Person_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Person_Order_By>>;
  where?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Person_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootIcims_Profile_Person_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Profile_Person_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Requested_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Profile_Requested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Requested_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Profile_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Profile_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Profile_Requested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Profile_Requested_Location_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootIcims_Profile_Requested_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Profile_Requested_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Profile_Requested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Requested_LocationArgs = {
  distinct_on?: InputMaybe<Array<Icims_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Requested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Requested_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Requested_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Requested_Location_Order_By>>;
  where?: InputMaybe<Icims_Requested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_Requested_Location_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Subscription_RootIcims_Requested_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Requested_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Requested_Location_Bool_Exp>;
};

export type Subscription_RootIcims_SchoolArgs = {
  distinct_on?: InputMaybe<Array<Icims_School_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_School_Order_By>>;
  where?: InputMaybe<Icims_School_Bool_Exp>;
};

export type Subscription_RootIcims_School_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_School_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_School_Order_By>>;
  where?: InputMaybe<Icims_School_Bool_Exp>;
};

export type Subscription_RootIcims_School_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Subscription_RootIcims_School_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_School_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_School_Bool_Exp>;
};

export type Subscription_RootIcims_SkillArgs = {
  distinct_on?: InputMaybe<Array<Icims_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Skill_Order_By>>;
  where?: InputMaybe<Icims_Skill_Bool_Exp>;
};

export type Subscription_RootIcims_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Skill_Order_By>>;
  where?: InputMaybe<Icims_Skill_Bool_Exp>;
};

export type Subscription_RootIcims_Skill_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Subscription_RootIcims_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Skill_Bool_Exp>;
};

export type Subscription_RootIcims_StatusArgs = {
  distinct_on?: InputMaybe<Array<Icims_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Status_Order_By>>;
  where?: InputMaybe<Icims_Status_Bool_Exp>;
};

export type Subscription_RootIcims_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Status_Order_By>>;
  where?: InputMaybe<Icims_Status_Bool_Exp>;
};

export type Subscription_RootIcims_Status_By_PkArgs = {
  id: Scalars['String'];
  initial_data_transfer: Scalars['Int'];
};

export type Subscription_RootIcims_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Status_Bool_Exp>;
};

export type Subscription_RootIcims_Sync_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Icims_Sync_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Sync_Status_Choices_Order_By>>;
  where?: InputMaybe<Icims_Sync_Status_Choices_Bool_Exp>;
};

export type Subscription_RootIcims_Sync_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Sync_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Sync_Status_Choices_Order_By>>;
  where?: InputMaybe<Icims_Sync_Status_Choices_Bool_Exp>;
};

export type Subscription_RootIcims_Sync_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootIcims_Sync_Status_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Sync_Status_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Sync_Status_Choices_Bool_Exp>;
};

export type Subscription_RootIcims_Work_ExperienceArgs = {
  distinct_on?: InputMaybe<Array<Icims_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Work_Experience_Order_By>>;
  where?: InputMaybe<Icims_Work_Experience_Bool_Exp>;
};

export type Subscription_RootIcims_Work_Experience_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icims_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icims_Work_Experience_Order_By>>;
  where?: InputMaybe<Icims_Work_Experience_Bool_Exp>;
};

export type Subscription_RootIcims_Work_Experience_By_PkArgs = {
  entry: Scalars['Int'];
  initial_data_transfer: Scalars['Int'];
  person_profile_id: Scalars['Int'];
};

export type Subscription_RootIcims_Work_Experience_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icims_Work_Experience_Stream_Cursor_Input>>;
  where?: InputMaybe<Icims_Work_Experience_Bool_Exp>;
};

export type Subscription_RootIndustryArgs = {
  distinct_on?: InputMaybe<Array<Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Industry_Order_By>>;
  where?: InputMaybe<Industry_Bool_Exp>;
};

export type Subscription_RootIndustry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Industry_Order_By>>;
  where?: InputMaybe<Industry_Bool_Exp>;
};

export type Subscription_RootIndustry_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootIndustry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Industry_Stream_Cursor_Input>>;
  where?: InputMaybe<Industry_Bool_Exp>;
};

export type Subscription_RootInitial_Sell_Type_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Initial_Sell_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Initial_Sell_Type_Choices_Order_By>>;
  where?: InputMaybe<Initial_Sell_Type_Choices_Bool_Exp>;
};

export type Subscription_RootInitial_Sell_Type_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Initial_Sell_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Initial_Sell_Type_Choices_Order_By>>;
  where?: InputMaybe<Initial_Sell_Type_Choices_Bool_Exp>;
};

export type Subscription_RootInitial_Sell_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootInitial_Sell_Type_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Initial_Sell_Type_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Initial_Sell_Type_Choices_Bool_Exp>;
};

export type Subscription_RootInterview_DetailArgs = {
  distinct_on?: InputMaybe<Array<Interview_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Detail_Order_By>>;
  where?: InputMaybe<Interview_Detail_Bool_Exp>;
};

export type Subscription_RootInterview_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Detail_Order_By>>;
  where?: InputMaybe<Interview_Detail_Bool_Exp>;
};

export type Subscription_RootInterview_Detail_By_PkArgs = {
  job_id: Scalars['Int'];
  order: Scalars['smallint'];
};

export type Subscription_RootInterview_Detail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Interview_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Interview_Detail_Bool_Exp>;
};

export type Subscription_RootInterview_FeedbackArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Bool_Exp>;
};

export type Subscription_RootInterview_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Bool_Exp>;
};

export type Subscription_RootInterview_Feedback_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootInterview_Feedback_Rating_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Rating_Choices_Bool_Exp>;
};

export type Subscription_RootInterview_Feedback_Rating_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Rating_Choices_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Rating_Choices_Bool_Exp>;
};

export type Subscription_RootInterview_Feedback_Rating_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootInterview_Feedback_Rating_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Interview_Feedback_Rating_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Interview_Feedback_Rating_Choices_Bool_Exp>;
};

export type Subscription_RootInterview_Feedback_RequestArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Request_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Request_Bool_Exp>;
};

export type Subscription_RootInterview_Feedback_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Feedback_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Feedback_Request_Order_By>>;
  where?: InputMaybe<Interview_Feedback_Request_Bool_Exp>;
};

export type Subscription_RootInterview_Feedback_Request_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootInterview_Feedback_Request_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Interview_Feedback_Request_Stream_Cursor_Input>>;
  where?: InputMaybe<Interview_Feedback_Request_Bool_Exp>;
};

export type Subscription_RootInterview_Feedback_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Interview_Feedback_Stream_Cursor_Input>>;
  where?: InputMaybe<Interview_Feedback_Bool_Exp>;
};

export type Subscription_RootInterview_RequestArgs = {
  distinct_on?: InputMaybe<Array<Interview_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Request_Order_By>>;
  where?: InputMaybe<Interview_Request_Bool_Exp>;
};

export type Subscription_RootInterview_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Request_Order_By>>;
  where?: InputMaybe<Interview_Request_Bool_Exp>;
};

export type Subscription_RootInterview_Request_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootInterview_Request_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Interview_Request_Stream_Cursor_Input>>;
  where?: InputMaybe<Interview_Request_Bool_Exp>;
};

export type Subscription_RootInterview_Type_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Interview_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Type_Choices_Order_By>>;
  where?: InputMaybe<Interview_Type_Choices_Bool_Exp>;
};

export type Subscription_RootInterview_Type_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Interview_Type_Choices_Order_By>>;
  where?: InputMaybe<Interview_Type_Choices_Bool_Exp>;
};

export type Subscription_RootInterview_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootInterview_Type_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Interview_Type_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Interview_Type_Choices_Bool_Exp>;
};

export type Subscription_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

export type Subscription_RootJob_Acceptable_LocationArgs = {
  distinct_on?: InputMaybe<Array<Job_Acceptable_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Acceptable_Location_Order_By>>;
  where?: InputMaybe<Job_Acceptable_Location_Bool_Exp>;
};

export type Subscription_RootJob_Acceptable_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Acceptable_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Acceptable_Location_Order_By>>;
  where?: InputMaybe<Job_Acceptable_Location_Bool_Exp>;
};

export type Subscription_RootJob_Acceptable_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootJob_Acceptable_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Acceptable_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Acceptable_Location_Bool_Exp>;
};

export type Subscription_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

export type Subscription_RootJob_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootJob_CodeArgs = {
  distinct_on?: InputMaybe<Array<Job_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Code_Order_By>>;
  where?: InputMaybe<Job_Code_Bool_Exp>;
};

export type Subscription_RootJob_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Code_Order_By>>;
  where?: InputMaybe<Job_Code_Bool_Exp>;
};

export type Subscription_RootJob_Code_By_PkArgs = {
  code: Scalars['String'];
};

export type Subscription_RootJob_Code_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Code_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Code_Bool_Exp>;
};

export type Subscription_RootJob_Level_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Job_Level_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Level_Choices_Order_By>>;
  where?: InputMaybe<Job_Level_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Level_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Level_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Level_Choices_Order_By>>;
  where?: InputMaybe<Job_Level_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Level_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootJob_Level_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Level_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Level_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Meta_InfoArgs = {
  distinct_on?: InputMaybe<Array<Job_Meta_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Meta_Info_Order_By>>;
  where?: InputMaybe<Job_Meta_Info_Bool_Exp>;
};

export type Subscription_RootJob_Meta_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Meta_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Meta_Info_Order_By>>;
  where?: InputMaybe<Job_Meta_Info_Bool_Exp>;
};

export type Subscription_RootJob_Meta_Info_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootJob_Meta_Info_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Meta_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Meta_Info_Bool_Exp>;
};

export type Subscription_RootJob_Nice_To_Have_SkillArgs = {
  distinct_on?: InputMaybe<Array<Job_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Job_Nice_To_Have_Skill_Bool_Exp>;
};

export type Subscription_RootJob_Nice_To_Have_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Nice_To_Have_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Nice_To_Have_Skill_Order_By>>;
  where?: InputMaybe<Job_Nice_To_Have_Skill_Bool_Exp>;
};

export type Subscription_RootJob_Nice_To_Have_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootJob_Nice_To_Have_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Nice_To_Have_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Nice_To_Have_Skill_Bool_Exp>;
};

export type Subscription_RootJob_PositionArgs = {
  distinct_on?: InputMaybe<Array<Job_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Position_Order_By>>;
  where?: InputMaybe<Job_Position_Bool_Exp>;
};

export type Subscription_RootJob_Position_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Position_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Position_Order_By>>;
  where?: InputMaybe<Job_Position_Bool_Exp>;
};

export type Subscription_RootJob_Position_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootJob_Position_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Position_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Position_Bool_Exp>;
};

export type Subscription_RootJob_Priority_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Job_Priority_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Priority_Choices_Order_By>>;
  where?: InputMaybe<Job_Priority_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Priority_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Priority_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Priority_Choices_Order_By>>;
  where?: InputMaybe<Job_Priority_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Priority_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootJob_Priority_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Priority_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Priority_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Required_SkillArgs = {
  distinct_on?: InputMaybe<Array<Job_Required_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Required_Skill_Order_By>>;
  where?: InputMaybe<Job_Required_Skill_Bool_Exp>;
};

export type Subscription_RootJob_Required_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Required_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Required_Skill_Order_By>>;
  where?: InputMaybe<Job_Required_Skill_Bool_Exp>;
};

export type Subscription_RootJob_Required_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootJob_Required_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Required_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Required_Skill_Bool_Exp>;
};

export type Subscription_RootJob_Role_Source_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Job_Role_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Role_Source_Choices_Order_By>>;
  where?: InputMaybe<Job_Role_Source_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Role_Source_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Role_Source_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Role_Source_Choices_Order_By>>;
  where?: InputMaybe<Job_Role_Source_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Role_Source_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootJob_Role_Source_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Role_Source_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Role_Source_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Service_TypeArgs = {
  distinct_on?: InputMaybe<Array<Job_Service_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Service_Type_Order_By>>;
  where?: InputMaybe<Job_Service_Type_Bool_Exp>;
};

export type Subscription_RootJob_Service_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Service_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Service_Type_Order_By>>;
  where?: InputMaybe<Job_Service_Type_Bool_Exp>;
};

export type Subscription_RootJob_Service_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootJob_Service_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Service_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Service_Type_Bool_Exp>;
};

export type Subscription_RootJob_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Choices_Order_By>>;
  where?: InputMaybe<Job_Status_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Choices_Order_By>>;
  where?: InputMaybe<Job_Status_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootJob_Status_Choices_OrderArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Choices_Order_Order_By>>;
  where?: InputMaybe<Job_Status_Choices_Order_Bool_Exp>;
};

export type Subscription_RootJob_Status_Choices_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Choices_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Choices_Order_Order_By>>;
  where?: InputMaybe<Job_Status_Choices_Order_Bool_Exp>;
};

export type Subscription_RootJob_Status_Choices_Order_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootJob_Status_Choices_Order_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Status_Choices_Order_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Status_Choices_Order_Bool_Exp>;
};

export type Subscription_RootJob_Status_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Status_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Status_Choices_Bool_Exp>;
};

export type Subscription_RootJob_Status_LogArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Log_Order_By>>;
  where?: InputMaybe<Job_Status_Log_Bool_Exp>;
};

export type Subscription_RootJob_Status_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Status_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Status_Log_Order_By>>;
  where?: InputMaybe<Job_Status_Log_Bool_Exp>;
};

export type Subscription_RootJob_Status_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootJob_Status_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Status_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Status_Log_Bool_Exp>;
};

export type Subscription_RootJob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

export type Subscription_RootJob_Tech_StackArgs = {
  distinct_on?: InputMaybe<Array<Job_Tech_Stack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Tech_Stack_Order_By>>;
  where?: InputMaybe<Job_Tech_Stack_Bool_Exp>;
};

export type Subscription_RootJob_Tech_Stack_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Tech_Stack_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Tech_Stack_Order_By>>;
  where?: InputMaybe<Job_Tech_Stack_Bool_Exp>;
};

export type Subscription_RootJob_Tech_Stack_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootJob_Tech_Stack_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Tech_Stack_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Tech_Stack_Bool_Exp>;
};

export type Subscription_RootLinkedin_ContactArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Contact_Order_By>>;
  where?: InputMaybe<Linkedin_Contact_Bool_Exp>;
};

export type Subscription_RootLinkedin_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Contact_Order_By>>;
  where?: InputMaybe<Linkedin_Contact_Bool_Exp>;
};

export type Subscription_RootLinkedin_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLinkedin_Contact_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Linkedin_Contact_Stream_Cursor_Input>>;
  where?: InputMaybe<Linkedin_Contact_Bool_Exp>;
};

export type Subscription_RootLocationArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};

export type Subscription_RootLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
};

export type Subscription_RootLocation_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLocation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Location_Bool_Exp>;
};

export type Subscription_RootManual_ContactArgs = {
  distinct_on?: InputMaybe<Array<Manual_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manual_Contact_Order_By>>;
  where?: InputMaybe<Manual_Contact_Bool_Exp>;
};

export type Subscription_RootManual_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manual_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Manual_Contact_Order_By>>;
  where?: InputMaybe<Manual_Contact_Bool_Exp>;
};

export type Subscription_RootManual_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootManual_Contact_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Manual_Contact_Stream_Cursor_Input>>;
  where?: InputMaybe<Manual_Contact_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_ApplicationArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Application_Order_By>>;
  where?: InputMaybe<Merge_Ats_Application_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Application_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Application_Order_By>>;
  where?: InputMaybe<Merge_Ats_Application_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Application_By_PkArgs = {
  merge_application_id: Scalars['String'];
};

export type Subscription_RootMerge_Ats_Application_LogArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Application_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Application_Log_Order_By>>;
  where?: InputMaybe<Merge_Ats_Application_Log_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Application_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Application_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Application_Log_Order_By>>;
  where?: InputMaybe<Merge_Ats_Application_Log_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Application_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootMerge_Ats_Application_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Merge_Ats_Application_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Merge_Ats_Application_Log_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Application_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Merge_Ats_Application_Stream_Cursor_Input>>;
  where?: InputMaybe<Merge_Ats_Application_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Candidate_Order_By>>;
  where?: InputMaybe<Merge_Ats_Candidate_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Candidate_Order_By>>;
  where?: InputMaybe<Merge_Ats_Candidate_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Candidate_By_PkArgs = {
  merge_candidate_id: Scalars['String'];
};

export type Subscription_RootMerge_Ats_Candidate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Merge_Ats_Candidate_Stream_Cursor_Input>>;
  where?: InputMaybe<Merge_Ats_Candidate_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_JobArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Job_Order_By>>;
  where?: InputMaybe<Merge_Ats_Job_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Job_Order_By>>;
  where?: InputMaybe<Merge_Ats_Job_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Job_By_PkArgs = {
  merge_job_id: Scalars['String'];
};

export type Subscription_RootMerge_Ats_Job_Interview_StageArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Job_Interview_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Job_Interview_Stage_Order_By>>;
  where?: InputMaybe<Merge_Ats_Job_Interview_Stage_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Job_Interview_Stage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Job_Interview_Stage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Job_Interview_Stage_Order_By>>;
  where?: InputMaybe<Merge_Ats_Job_Interview_Stage_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Job_Interview_Stage_By_PkArgs = {
  merge_interview_stage_id: Scalars['String'];
};

export type Subscription_RootMerge_Ats_Job_Interview_Stage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Merge_Ats_Job_Interview_Stage_Stream_Cursor_Input>>;
  where?: InputMaybe<Merge_Ats_Job_Interview_Stage_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Job_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Merge_Ats_Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Merge_Ats_Job_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Linked_AccountArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Linked_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Linked_Account_Order_By>>;
  where?: InputMaybe<Merge_Ats_Linked_Account_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Linked_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Merge_Ats_Linked_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Merge_Ats_Linked_Account_Order_By>>;
  where?: InputMaybe<Merge_Ats_Linked_Account_Bool_Exp>;
};

export type Subscription_RootMerge_Ats_Linked_Account_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootMerge_Ats_Linked_Account_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Merge_Ats_Linked_Account_Stream_Cursor_Input>>;
  where?: InputMaybe<Merge_Ats_Linked_Account_Bool_Exp>;
};

export type Subscription_RootMixpanel_Duplicate_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Mixpanel_Duplicate_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mixpanel_Duplicate_Profiles_Order_By>>;
  where?: InputMaybe<Mixpanel_Duplicate_Profiles_Bool_Exp>;
};

export type Subscription_RootMixpanel_Duplicate_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mixpanel_Duplicate_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mixpanel_Duplicate_Profiles_Order_By>>;
  where?: InputMaybe<Mixpanel_Duplicate_Profiles_Bool_Exp>;
};

export type Subscription_RootMixpanel_Duplicate_Profiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mixpanel_Duplicate_Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Mixpanel_Duplicate_Profiles_Bool_Exp>;
};

export type Subscription_RootMixpanel_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Mixpanel_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mixpanel_Profiles_Order_By>>;
  where?: InputMaybe<Mixpanel_Profiles_Bool_Exp>;
};

export type Subscription_RootMixpanel_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mixpanel_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mixpanel_Profiles_Order_By>>;
  where?: InputMaybe<Mixpanel_Profiles_Bool_Exp>;
};

export type Subscription_RootMixpanel_Profiles_By_PkArgs = {
  distinct_id: Scalars['String'];
  email: Scalars['String'];
};

export type Subscription_RootMixpanel_Profiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mixpanel_Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Mixpanel_Profiles_Bool_Exp>;
};

export type Subscription_RootOffering_Selection_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Offering_Selection_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Offering_Selection_Choices_Order_By>>;
  where?: InputMaybe<Offering_Selection_Choices_Bool_Exp>;
};

export type Subscription_RootOffering_Selection_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Offering_Selection_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Offering_Selection_Choices_Order_By>>;
  where?: InputMaybe<Offering_Selection_Choices_Bool_Exp>;
};

export type Subscription_RootOffering_Selection_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootOffering_Selection_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Offering_Selection_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Offering_Selection_Choices_Bool_Exp>;
};

export type Subscription_RootOrganizationArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Order_By>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootOrganization_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootOrganization_ContactArgs = {
  distinct_on?: InputMaybe<Array<Organization_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Contact_Order_By>>;
  where?: InputMaybe<Organization_Contact_Bool_Exp>;
};

export type Subscription_RootOrganization_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Contact_Order_By>>;
  where?: InputMaybe<Organization_Contact_Bool_Exp>;
};

export type Subscription_RootOrganization_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootOrganization_Contact_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Contact_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Contact_Bool_Exp>;
};

export type Subscription_RootOrganization_DomainArgs = {
  distinct_on?: InputMaybe<Array<Organization_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Domain_Order_By>>;
  where?: InputMaybe<Organization_Domain_Bool_Exp>;
};

export type Subscription_RootOrganization_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Domain_Order_By>>;
  where?: InputMaybe<Organization_Domain_Bool_Exp>;
};

export type Subscription_RootOrganization_Domain_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootOrganization_Domain_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Domain_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Domain_Bool_Exp>;
};

export type Subscription_RootOrganization_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

export type Subscription_RootOrganization_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Employee_Order_By>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

export type Subscription_RootOrganization_Employee_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootOrganization_Employee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Employee_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Employee_Bool_Exp>;
};

export type Subscription_RootOrganization_Icims_JobsArgs = {
  args: Organization_Icims_Jobs_Args;
  distinct_on?: InputMaybe<Array<Organization_Icims_Jobs_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Icims_Jobs_Result_Order_By>>;
  where?: InputMaybe<Organization_Icims_Jobs_Result_Bool_Exp>;
};

export type Subscription_RootOrganization_Icims_Jobs_AggregateArgs = {
  args: Organization_Icims_Jobs_Args;
  distinct_on?: InputMaybe<Array<Organization_Icims_Jobs_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Icims_Jobs_Result_Order_By>>;
  where?: InputMaybe<Organization_Icims_Jobs_Result_Bool_Exp>;
};

export type Subscription_RootOrganization_Icims_Jobs_ResultArgs = {
  distinct_on?: InputMaybe<Array<Organization_Icims_Jobs_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Icims_Jobs_Result_Order_By>>;
  where?: InputMaybe<Organization_Icims_Jobs_Result_Bool_Exp>;
};

export type Subscription_RootOrganization_Icims_Jobs_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Icims_Jobs_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Icims_Jobs_Result_Order_By>>;
  where?: InputMaybe<Organization_Icims_Jobs_Result_Bool_Exp>;
};

export type Subscription_RootOrganization_Icims_Jobs_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Icims_Jobs_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Icims_Jobs_Result_Bool_Exp>;
};

export type Subscription_RootOrganization_LeadershipArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leadership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leadership_Order_By>>;
  where?: InputMaybe<Organization_Leadership_Bool_Exp>;
};

export type Subscription_RootOrganization_Leadership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Leadership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Leadership_Order_By>>;
  where?: InputMaybe<Organization_Leadership_Bool_Exp>;
};

export type Subscription_RootOrganization_Leadership_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootOrganization_Leadership_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Leadership_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Leadership_Bool_Exp>;
};

export type Subscription_RootOrganization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Bool_Exp>;
};

export type Subscription_RootOrganization_Type_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Type_Choices_Order_By>>;
  where?: InputMaybe<Organization_Type_Choices_Bool_Exp>;
};

export type Subscription_RootOrganization_Type_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Type_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Type_Choices_Order_By>>;
  where?: InputMaybe<Organization_Type_Choices_Bool_Exp>;
};

export type Subscription_RootOrganization_Type_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootOrganization_Type_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Type_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Type_Choices_Bool_Exp>;
};

export type Subscription_RootOrganization_VideoArgs = {
  distinct_on?: InputMaybe<Array<Organization_Video_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Video_Order_By>>;
  where?: InputMaybe<Organization_Video_Bool_Exp>;
};

export type Subscription_RootOrganization_Video_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Video_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Organization_Video_Order_By>>;
  where?: InputMaybe<Organization_Video_Bool_Exp>;
};

export type Subscription_RootOrganization_Video_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootOrganization_Video_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Organization_Video_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Video_Bool_Exp>;
};

export type Subscription_RootPillar_TranscriptArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Transcript_Order_By>>;
  where?: InputMaybe<Pillar_Transcript_Bool_Exp>;
};

export type Subscription_RootPillar_Transcript_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Transcript_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Transcript_Order_By>>;
  where?: InputMaybe<Pillar_Transcript_Bool_Exp>;
};

export type Subscription_RootPillar_Transcript_BlobArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Transcript_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Transcript_Blob_Order_By>>;
  where?: InputMaybe<Pillar_Transcript_Blob_Bool_Exp>;
};

export type Subscription_RootPillar_Transcript_Blob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Transcript_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Transcript_Blob_Order_By>>;
  where?: InputMaybe<Pillar_Transcript_Blob_Bool_Exp>;
};

export type Subscription_RootPillar_Transcript_Blob_By_PkArgs = {
  transcript_id: Scalars['String'];
};

export type Subscription_RootPillar_Transcript_Blob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Pillar_Transcript_Blob_Stream_Cursor_Input>>;
  where?: InputMaybe<Pillar_Transcript_Blob_Bool_Exp>;
};

export type Subscription_RootPillar_Transcript_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootPillar_Transcript_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Pillar_Transcript_Stream_Cursor_Input>>;
  where?: InputMaybe<Pillar_Transcript_Bool_Exp>;
};

export type Subscription_RootRecruiter_Contact_ActionArgs = {
  distinct_on?: InputMaybe<Array<Recruiter_Contact_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recruiter_Contact_Action_Order_By>>;
  where?: InputMaybe<Recruiter_Contact_Action_Bool_Exp>;
};

export type Subscription_RootRecruiter_Contact_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Recruiter_Contact_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Recruiter_Contact_Action_Order_By>>;
  where?: InputMaybe<Recruiter_Contact_Action_Bool_Exp>;
};

export type Subscription_RootRecruiter_Contact_Action_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootRecruiter_Contact_Action_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Recruiter_Contact_Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Recruiter_Contact_Action_Bool_Exp>;
};

export type Subscription_RootReferral_StatusArgs = {
  distinct_on?: InputMaybe<Array<Referral_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Status_Order_By>>;
  where?: InputMaybe<Referral_Status_Bool_Exp>;
};

export type Subscription_RootReferral_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Referral_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Status_Order_By>>;
  where?: InputMaybe<Referral_Status_Bool_Exp>;
};

export type Subscription_RootReferral_Status_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootReferral_Status_CurrentArgs = {
  distinct_on?: InputMaybe<Array<Referral_Status_Current_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Status_Current_Order_By>>;
  where?: InputMaybe<Referral_Status_Current_Bool_Exp>;
};

export type Subscription_RootReferral_Status_Current_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Referral_Status_Current_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Referral_Status_Current_Order_By>>;
  where?: InputMaybe<Referral_Status_Current_Bool_Exp>;
};

export type Subscription_RootReferral_Status_Current_By_PkArgs = {
  contact: Scalars['Int'];
  user: Scalars['Int'];
};

export type Subscription_RootReferral_Status_Current_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Referral_Status_Current_Stream_Cursor_Input>>;
  where?: InputMaybe<Referral_Status_Current_Bool_Exp>;
};

export type Subscription_RootReferral_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Referral_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Referral_Status_Bool_Exp>;
};

export type Subscription_RootRegion_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Region_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_Choices_Order_By>>;
  where?: InputMaybe<Region_Choices_Bool_Exp>;
};

export type Subscription_RootRegion_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Region_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_Choices_Order_By>>;
  where?: InputMaybe<Region_Choices_Bool_Exp>;
};

export type Subscription_RootRegion_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootRegion_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Region_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Region_Choices_Bool_Exp>;
};

export type Subscription_RootReporting_Candidate_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Candidate_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Candidate_Metadata_Order_By>>;
  where?: InputMaybe<Reporting_Candidate_Metadata_Bool_Exp>;
};

export type Subscription_RootReporting_Candidate_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Candidate_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Candidate_Metadata_Order_By>>;
  where?: InputMaybe<Reporting_Candidate_Metadata_Bool_Exp>;
};

export type Subscription_RootReporting_Candidate_Metadata_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reporting_Candidate_Metadata_Stream_Cursor_Input>>;
  where?: InputMaybe<Reporting_Candidate_Metadata_Bool_Exp>;
};

export type Subscription_RootReporting_Email_Suppression_TraitsArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Email_Suppression_Traits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Email_Suppression_Traits_Order_By>>;
  where?: InputMaybe<Reporting_Email_Suppression_Traits_Bool_Exp>;
};

export type Subscription_RootReporting_Email_Suppression_Traits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Email_Suppression_Traits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Email_Suppression_Traits_Order_By>>;
  where?: InputMaybe<Reporting_Email_Suppression_Traits_Bool_Exp>;
};

export type Subscription_RootReporting_Email_Suppression_Traits_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reporting_Email_Suppression_Traits_Stream_Cursor_Input>>;
  where?: InputMaybe<Reporting_Email_Suppression_Traits_Bool_Exp>;
};

export type Subscription_RootReporting_Report_Job_MatchesArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Report_Job_Matches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Report_Job_Matches_Order_By>>;
  where?: InputMaybe<Reporting_Report_Job_Matches_Bool_Exp>;
};

export type Subscription_RootReporting_Report_Job_Matches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reporting_Report_Job_Matches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reporting_Report_Job_Matches_Order_By>>;
  where?: InputMaybe<Reporting_Report_Job_Matches_Bool_Exp>;
};

export type Subscription_RootReporting_Report_Job_Matches_By_PkArgs = {
  created_at: Scalars['timestamptz'];
};

export type Subscription_RootReporting_Report_Job_Matches_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reporting_Report_Job_Matches_Stream_Cursor_Input>>;
  where?: InputMaybe<Reporting_Report_Job_Matches_Bool_Exp>;
};

export type Subscription_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

export type Subscription_RootRole_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootRole_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Role_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Choices_Order_By>>;
  where?: InputMaybe<Role_Choices_Bool_Exp>;
};

export type Subscription_RootRole_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Choices_Order_By>>;
  where?: InputMaybe<Role_Choices_Bool_Exp>;
};

export type Subscription_RootRole_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootRole_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Role_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Choices_Bool_Exp>;
};

export type Subscription_RootRole_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

export type Subscription_RootSalary_InfoArgs = {
  distinct_on?: InputMaybe<Array<Salary_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Info_Order_By>>;
  where?: InputMaybe<Salary_Info_Bool_Exp>;
};

export type Subscription_RootSalary_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salary_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Info_Order_By>>;
  where?: InputMaybe<Salary_Info_Bool_Exp>;
};

export type Subscription_RootSalary_Info_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSalary_Info_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Salary_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<Salary_Info_Bool_Exp>;
};

export type Subscription_RootSalary_Years_Experience_Range_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Order_By>>;
  where?: InputMaybe<Salary_Years_Experience_Range_Choices_Bool_Exp>;
};

export type Subscription_RootSalary_Years_Experience_Range_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Years_Experience_Range_Choices_Order_By>>;
  where?: InputMaybe<Salary_Years_Experience_Range_Choices_Bool_Exp>;
};

export type Subscription_RootSalary_Years_Experience_Range_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootSalary_Years_Experience_Range_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Salary_Years_Experience_Range_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Salary_Years_Experience_Range_Choices_Bool_Exp>;
};

export type Subscription_RootSalesforce_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Customer_Order_By>>;
  where?: InputMaybe<Salesforce_Customer_Bool_Exp>;
};

export type Subscription_RootSalesforce_Customer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Customer_Order_By>>;
  where?: InputMaybe<Salesforce_Customer_Bool_Exp>;
};

export type Subscription_RootSalesforce_Customer_By_PkArgs = {
  salesforce_id: Scalars['String'];
};

export type Subscription_RootSalesforce_Customer_LocationArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Customer_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Customer_Location_Order_By>>;
  where?: InputMaybe<Salesforce_Customer_Location_Bool_Exp>;
};

export type Subscription_RootSalesforce_Customer_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Customer_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Customer_Location_Order_By>>;
  where?: InputMaybe<Salesforce_Customer_Location_Bool_Exp>;
};

export type Subscription_RootSalesforce_Customer_Location_By_PkArgs = {
  salesforce_id: Scalars['String'];
  salesforce_location_id: Scalars['Int'];
};

export type Subscription_RootSalesforce_Customer_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Salesforce_Customer_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Salesforce_Customer_Location_Bool_Exp>;
};

export type Subscription_RootSalesforce_Customer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Salesforce_Customer_Stream_Cursor_Input>>;
  where?: InputMaybe<Salesforce_Customer_Bool_Exp>;
};

export type Subscription_RootSalesforce_LocationArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Location_Order_By>>;
  where?: InputMaybe<Salesforce_Location_Bool_Exp>;
};

export type Subscription_RootSalesforce_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salesforce_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salesforce_Location_Order_By>>;
  where?: InputMaybe<Salesforce_Location_Bool_Exp>;
};

export type Subscription_RootSalesforce_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSalesforce_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Salesforce_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Salesforce_Location_Bool_Exp>;
};

export type Subscription_RootSaved_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Saved_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Saved_Candidate_Order_By>>;
  where?: InputMaybe<Saved_Candidate_Bool_Exp>;
};

export type Subscription_RootSaved_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Saved_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Saved_Candidate_Order_By>>;
  where?: InputMaybe<Saved_Candidate_Bool_Exp>;
};

export type Subscription_RootSaved_Candidate_By_PkArgs = {
  candidate_id: Scalars['Int'];
  saved_by_user_id: Scalars['Int'];
};

export type Subscription_RootSaved_Candidate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Saved_Candidate_Stream_Cursor_Input>>;
  where?: InputMaybe<Saved_Candidate_Bool_Exp>;
};

export type Subscription_RootSegment_Sendgrid_Prod_ActivityArgs = {
  distinct_on?: InputMaybe<Array<Segment_Sendgrid_Prod_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Sendgrid_Prod_Activity_Order_By>>;
  where?: InputMaybe<Segment_Sendgrid_Prod_Activity_Bool_Exp>;
};

export type Subscription_RootSegment_Sendgrid_Prod_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Segment_Sendgrid_Prod_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Sendgrid_Prod_Activity_Order_By>>;
  where?: InputMaybe<Segment_Sendgrid_Prod_Activity_Bool_Exp>;
};

export type Subscription_RootSegment_Sendgrid_Prod_Activity_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootSegment_Sendgrid_Prod_Activity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Segment_Sendgrid_Prod_Activity_Stream_Cursor_Input>>;
  where?: InputMaybe<Segment_Sendgrid_Prod_Activity_Bool_Exp>;
};

export type Subscription_RootShared_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Shared_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Shared_Candidate_Order_By>>;
  where?: InputMaybe<Shared_Candidate_Bool_Exp>;
};

export type Subscription_RootShared_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Shared_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Shared_Candidate_Order_By>>;
  where?: InputMaybe<Shared_Candidate_Bool_Exp>;
};

export type Subscription_RootShared_Candidate_By_PkArgs = {
  candidate_id: Scalars['Int'];
  user_info_id: Scalars['Int'];
};

export type Subscription_RootShared_Candidate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Shared_Candidate_Stream_Cursor_Input>>;
  where?: InputMaybe<Shared_Candidate_Bool_Exp>;
};

export type Subscription_RootSkillArgs = {
  distinct_on?: InputMaybe<Array<Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Order_By>>;
  where?: InputMaybe<Skill_Bool_Exp>;
};

export type Subscription_RootSkill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Order_By>>;
  where?: InputMaybe<Skill_Bool_Exp>;
};

export type Subscription_RootSkill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSkill_FeatureArgs = {
  distinct_on?: InputMaybe<Array<Skill_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Feature_Order_By>>;
  where?: InputMaybe<Skill_Feature_Bool_Exp>;
};

export type Subscription_RootSkill_Feature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Feature_Order_By>>;
  where?: InputMaybe<Skill_Feature_Bool_Exp>;
};

export type Subscription_RootSkill_Feature_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSkill_Feature_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Skill_Feature_Stream_Cursor_Input>>;
  where?: InputMaybe<Skill_Feature_Bool_Exp>;
};

export type Subscription_RootSkill_Icims_SkillArgs = {
  distinct_on?: InputMaybe<Array<Skill_Icims_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Icims_Skill_Order_By>>;
  where?: InputMaybe<Skill_Icims_Skill_Bool_Exp>;
};

export type Subscription_RootSkill_Icims_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Icims_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Icims_Skill_Order_By>>;
  where?: InputMaybe<Skill_Icims_Skill_Bool_Exp>;
};

export type Subscription_RootSkill_Icims_Skill_By_PkArgs = {
  icims_skill_id: Scalars['String'];
  skill_id: Scalars['Int'];
};

export type Subscription_RootSkill_Icims_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Skill_Icims_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Skill_Icims_Skill_Bool_Exp>;
};

export type Subscription_RootSkill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Skill_Bool_Exp>;
};

export type Subscription_RootSkill_SynonymArgs = {
  distinct_on?: InputMaybe<Array<Skill_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Synonym_Order_By>>;
  where?: InputMaybe<Skill_Synonym_Bool_Exp>;
};

export type Subscription_RootSkill_Synonym_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Skill_Synonym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Skill_Synonym_Order_By>>;
  where?: InputMaybe<Skill_Synonym_Bool_Exp>;
};

export type Subscription_RootSkill_Synonym_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSkill_Synonym_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Skill_Synonym_Stream_Cursor_Input>>;
  where?: InputMaybe<Skill_Synonym_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Candidate_Order_By>>;
  where?: InputMaybe<Spike_Matching_Candidate_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Candidate_Order_By>>;
  where?: InputMaybe<Spike_Matching_Candidate_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Candidate_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSpike_Matching_Candidate_SkillArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Candidate_Skill_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Candidate_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Candidate_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Candidate_Skill_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Candidate_Skill_By_PkArgs = {
  candidate_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

export type Subscription_RootSpike_Matching_Candidate_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Spike_Matching_Candidate_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Spike_Matching_Candidate_Skill_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Candidate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Spike_Matching_Candidate_Stream_Cursor_Input>>;
  where?: InputMaybe<Spike_Matching_Candidate_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_JobArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Job_Order_By>>;
  where?: InputMaybe<Spike_Matching_Job_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Job_Order_By>>;
  where?: InputMaybe<Spike_Matching_Job_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Job_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSpike_Matching_Job_SkillArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Job_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Job_Skill_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Job_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Job_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Job_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Job_Skill_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Job_Skill_By_PkArgs = {
  job_id: Scalars['Int'];
  skill_id: Scalars['Int'];
};

export type Subscription_RootSpike_Matching_Job_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Spike_Matching_Job_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Spike_Matching_Job_Skill_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Job_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Spike_Matching_Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Spike_Matching_Job_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_LocationArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Location_Order_By>>;
  where?: InputMaybe<Spike_Matching_Location_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Location_Order_By>>;
  where?: InputMaybe<Spike_Matching_Location_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Location_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSpike_Matching_Location_JobArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Location_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Location_Job_Order_By>>;
  where?: InputMaybe<Spike_Matching_Location_Job_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Location_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Location_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Location_Job_Order_By>>;
  where?: InputMaybe<Spike_Matching_Location_Job_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Location_Job_By_PkArgs = {
  job_id: Scalars['Int'];
  location_id: Scalars['Int'];
};

export type Subscription_RootSpike_Matching_Location_Job_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Spike_Matching_Location_Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Spike_Matching_Location_Job_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Location_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Spike_Matching_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Spike_Matching_Location_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_MatchArgs = {
  args: Spike_Matching_Match_Args;
  distinct_on?: InputMaybe<Array<Spike_Matching_Match_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Match_Result_Order_By>>;
  where?: InputMaybe<Spike_Matching_Match_Result_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Match_AggregateArgs = {
  args: Spike_Matching_Match_Args;
  distinct_on?: InputMaybe<Array<Spike_Matching_Match_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Match_Result_Order_By>>;
  where?: InputMaybe<Spike_Matching_Match_Result_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Match_ResultArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Match_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Match_Result_Order_By>>;
  where?: InputMaybe<Spike_Matching_Match_Result_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Match_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Match_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Match_Result_Order_By>>;
  where?: InputMaybe<Spike_Matching_Match_Result_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Match_Result_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSpike_Matching_Match_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Spike_Matching_Match_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Spike_Matching_Match_Result_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_SkillArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Skill_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spike_Matching_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Spike_Matching_Skill_Order_By>>;
  where?: InputMaybe<Spike_Matching_Skill_Bool_Exp>;
};

export type Subscription_RootSpike_Matching_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSpike_Matching_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Spike_Matching_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Spike_Matching_Skill_Bool_Exp>;
};

export type Subscription_RootTerminal_Celery_Task_Status_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Order_By>>;
  where?: InputMaybe<Terminal_Celery_Task_Status_Choices_Bool_Exp>;
};

export type Subscription_RootTerminal_Celery_Task_Status_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Order_By>>;
  where?: InputMaybe<Terminal_Celery_Task_Status_Choices_Bool_Exp>;
};

export type Subscription_RootTerminal_Celery_Task_Status_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootTerminal_Celery_Task_Status_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Terminal_Celery_Task_Status_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Terminal_Celery_Task_Status_Choices_Bool_Exp>;
};

export type Subscription_RootTerminal_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Bool_Exp>;
};

export type Subscription_RootTerminal_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Bool_Exp>;
};

export type Subscription_RootTerminal_Employee_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootTerminal_Employee_OrganizationArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Organization_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
};

export type Subscription_RootTerminal_Employee_Organization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Organization_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
};

export type Subscription_RootTerminal_Employee_Organization_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootTerminal_Employee_Organization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Terminal_Employee_Organization_Stream_Cursor_Input>>;
  where?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
};

export type Subscription_RootTerminal_Employee_Role_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Role_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Role_Choices_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Role_Choices_Bool_Exp>;
};

export type Subscription_RootTerminal_Employee_Role_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Role_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Role_Choices_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Role_Choices_Bool_Exp>;
};

export type Subscription_RootTerminal_Employee_Role_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootTerminal_Employee_Role_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Terminal_Employee_Role_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<Terminal_Employee_Role_Choices_Bool_Exp>;
};

export type Subscription_RootTerminal_Employee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Terminal_Employee_Stream_Cursor_Input>>;
  where?: InputMaybe<Terminal_Employee_Bool_Exp>;
};

export type Subscription_RootTraitArgs = {
  distinct_on?: InputMaybe<Array<Trait_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trait_Order_By>>;
  where?: InputMaybe<Trait_Bool_Exp>;
};

export type Subscription_RootTrait_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trait_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trait_Order_By>>;
  where?: InputMaybe<Trait_Bool_Exp>;
};

export type Subscription_RootTrait_By_PkArgs = {
  code: Scalars['String'];
};

export type Subscription_RootTrait_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Trait_Stream_Cursor_Input>>;
  where?: InputMaybe<Trait_Bool_Exp>;
};

export type Subscription_RootTranscript_EvaluationArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Evaluation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Evaluation_Order_By>>;
  where?: InputMaybe<Transcript_Evaluation_Bool_Exp>;
};

export type Subscription_RootTranscript_Evaluation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Evaluation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Evaluation_Order_By>>;
  where?: InputMaybe<Transcript_Evaluation_Bool_Exp>;
};

export type Subscription_RootTranscript_Evaluation_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootTranscript_Evaluation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Transcript_Evaluation_Stream_Cursor_Input>>;
  where?: InputMaybe<Transcript_Evaluation_Bool_Exp>;
};

export type Subscription_RootTranscript_SkillArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Skill_Order_By>>;
  where?: InputMaybe<Transcript_Skill_Bool_Exp>;
};

export type Subscription_RootTranscript_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Skill_Order_By>>;
  where?: InputMaybe<Transcript_Skill_Bool_Exp>;
};

export type Subscription_RootTranscript_Skill_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootTranscript_Skill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Transcript_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Transcript_Skill_Bool_Exp>;
};

export type Subscription_RootTranscript_Work_ExperienceArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Work_Experience_Order_By>>;
  where?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
};

export type Subscription_RootTranscript_Work_Experience_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Work_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Work_Experience_Order_By>>;
  where?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
};

export type Subscription_RootTranscript_Work_Experience_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootTranscript_Work_Experience_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Transcript_Work_Experience_Stream_Cursor_Input>>;
  where?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
};

export type Subscription_RootUser_ContactArgs = {
  distinct_on?: InputMaybe<Array<User_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Contact_Order_By>>;
  where?: InputMaybe<User_Contact_Bool_Exp>;
};

export type Subscription_RootUser_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Contact_Order_By>>;
  where?: InputMaybe<User_Contact_Bool_Exp>;
};

export type Subscription_RootUser_Contact_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Contact_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Contact_Bool_Exp>;
};

export type Subscription_RootUser_InfoArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

export type Subscription_RootUser_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

export type Subscription_RootUser_Info_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_Info_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

export type Subscription_RootUser_InvitationArgs = {
  distinct_on?: InputMaybe<Array<User_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invitation_Order_By>>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};

export type Subscription_RootUser_Invitation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invitation_Order_By>>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};

export type Subscription_RootUser_Invitation_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_Invitation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Invitation_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};

export type Subscription_RootUser_Linkedin_ContactArgs = {
  distinct_on?: InputMaybe<Array<User_Linkedin_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linkedin_Contact_Order_By>>;
  where?: InputMaybe<User_Linkedin_Contact_Bool_Exp>;
};

export type Subscription_RootUser_Linkedin_Contact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Linkedin_Contact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linkedin_Contact_Order_By>>;
  where?: InputMaybe<User_Linkedin_Contact_Bool_Exp>;
};

export type Subscription_RootUser_Linkedin_Contact_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_Linkedin_Contact_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Linkedin_Contact_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Linkedin_Contact_Bool_Exp>;
};

export type Subscription_RootUser_Password_BlacklistArgs = {
  distinct_on?: InputMaybe<Array<User_Password_Blacklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Password_Blacklist_Order_By>>;
  where?: InputMaybe<User_Password_Blacklist_Bool_Exp>;
};

export type Subscription_RootUser_Password_Blacklist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Password_Blacklist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Password_Blacklist_Order_By>>;
  where?: InputMaybe<User_Password_Blacklist_Bool_Exp>;
};

export type Subscription_RootUser_Password_Blacklist_By_PkArgs = {
  password: Scalars['String'];
};

export type Subscription_RootUser_Password_Blacklist_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Password_Blacklist_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Password_Blacklist_Bool_Exp>;
};

export type Subscription_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

export type Subscription_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

export type Subscription_RootUser_Role_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_Role_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

export type Subscription_RootUser_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
  where?: InputMaybe<User_Subscription_Bool_Exp>;
};

export type Subscription_RootUser_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
  where?: InputMaybe<User_Subscription_Bool_Exp>;
};

export type Subscription_RootUser_Subscription_By_PkArgs = {
  subscription: User_Subscription_Choices_Enum;
  user_info_id: Scalars['Int'];
};

export type Subscription_RootUser_Subscription_ChoicesArgs = {
  distinct_on?: InputMaybe<Array<User_Subscription_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Subscription_Choices_Order_By>>;
  where?: InputMaybe<User_Subscription_Choices_Bool_Exp>;
};

export type Subscription_RootUser_Subscription_Choices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Subscription_Choices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Subscription_Choices_Order_By>>;
  where?: InputMaybe<User_Subscription_Choices_Bool_Exp>;
};

export type Subscription_RootUser_Subscription_Choices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootUser_Subscription_Choices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Subscription_Choices_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Subscription_Choices_Bool_Exp>;
};

export type Subscription_RootUser_Subscription_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Subscription_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Subscription_Bool_Exp>;
};

export type Subscription_RootUser_Temp_BlobArgs = {
  distinct_on?: InputMaybe<Array<User_Temp_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Temp_Blob_Order_By>>;
  where?: InputMaybe<User_Temp_Blob_Bool_Exp>;
};

export type Subscription_RootUser_Temp_Blob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Temp_Blob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Temp_Blob_Order_By>>;
  where?: InputMaybe<User_Temp_Blob_Bool_Exp>;
};

export type Subscription_RootUser_Temp_Blob_By_PkArgs = {
  firebase_uid: Scalars['String'];
};

export type Subscription_RootUser_Temp_Blob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Temp_Blob_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Temp_Blob_Bool_Exp>;
};

export type Subscription_RootValidatorArgs = {
  distinct_on?: InputMaybe<Array<Validator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Validator_Order_By>>;
  where?: InputMaybe<Validator_Bool_Exp>;
};

export type Subscription_RootValidator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Validator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Validator_Order_By>>;
  where?: InputMaybe<Validator_Bool_Exp>;
};

export type Subscription_RootValidator_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootValidator_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Validator_Stream_Cursor_Input>>;
  where?: InputMaybe<Validator_Bool_Exp>;
};

/** Enum table representing the values of celery task statuses */
export type Terminal_Celery_Task_Status_Choices = {
  __typename?: 'terminal_celery_task_status_choices';
  value: Scalars['String'];
};

/** aggregated selection of "terminal_celery_task_status_choices" */
export type Terminal_Celery_Task_Status_Choices_Aggregate = {
  __typename?: 'terminal_celery_task_status_choices_aggregate';
  aggregate: Maybe<Terminal_Celery_Task_Status_Choices_Aggregate_Fields>;
  nodes: Array<Terminal_Celery_Task_Status_Choices>;
};

/** aggregate fields of "terminal_celery_task_status_choices" */
export type Terminal_Celery_Task_Status_Choices_Aggregate_Fields = {
  __typename?: 'terminal_celery_task_status_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Terminal_Celery_Task_Status_Choices_Max_Fields>;
  min: Maybe<Terminal_Celery_Task_Status_Choices_Min_Fields>;
};

/** aggregate fields of "terminal_celery_task_status_choices" */
export type Terminal_Celery_Task_Status_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "terminal_celery_task_status_choices". All fields are combined with a logical 'AND'. */
export type Terminal_Celery_Task_Status_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Bool_Exp>>;
  _not?: InputMaybe<Terminal_Celery_Task_Status_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "terminal_celery_task_status_choices" */
export enum Terminal_Celery_Task_Status_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  TerminalCeleryTaskStatusChoicesPkey = 'terminal_celery_task_status_choices_pkey',
}

export enum Terminal_Celery_Task_Status_Choices_Enum {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Retry = 'RETRY',
  Revoked = 'REVOKED',
  Started = 'STARTED',
  Success = 'SUCCESS',
}

/** Boolean expression to compare columns of type "terminal_celery_task_status_choices_enum". All fields are combined with logical 'AND'. */
export type Terminal_Celery_Task_Status_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Terminal_Celery_Task_Status_Choices_Enum>;
  _in?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Terminal_Celery_Task_Status_Choices_Enum>;
  _nin?: InputMaybe<Array<Terminal_Celery_Task_Status_Choices_Enum>>;
};

/** input type for inserting data into table "terminal_celery_task_status_choices" */
export type Terminal_Celery_Task_Status_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Terminal_Celery_Task_Status_Choices_Max_Fields = {
  __typename?: 'terminal_celery_task_status_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Terminal_Celery_Task_Status_Choices_Min_Fields = {
  __typename?: 'terminal_celery_task_status_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "terminal_celery_task_status_choices" */
export type Terminal_Celery_Task_Status_Choices_Mutation_Response = {
  __typename?: 'terminal_celery_task_status_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Terminal_Celery_Task_Status_Choices>;
};

/** on_conflict condition type for table "terminal_celery_task_status_choices" */
export type Terminal_Celery_Task_Status_Choices_On_Conflict = {
  constraint: Terminal_Celery_Task_Status_Choices_Constraint;
  update_columns?: Array<Terminal_Celery_Task_Status_Choices_Update_Column>;
  where?: InputMaybe<Terminal_Celery_Task_Status_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "terminal_celery_task_status_choices". */
export type Terminal_Celery_Task_Status_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: terminal_celery_task_status_choices */
export type Terminal_Celery_Task_Status_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "terminal_celery_task_status_choices" */
export enum Terminal_Celery_Task_Status_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "terminal_celery_task_status_choices" */
export type Terminal_Celery_Task_Status_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "terminal_celery_task_status_choices" */
export type Terminal_Celery_Task_Status_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Terminal_Celery_Task_Status_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Terminal_Celery_Task_Status_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "terminal_celery_task_status_choices" */
export enum Terminal_Celery_Task_Status_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type Terminal_Celery_Task_Status_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Terminal_Celery_Task_Status_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Terminal_Celery_Task_Status_Choices_Bool_Exp;
};

/** columns and relationships of "terminal_employee" */
export type Terminal_Employee = {
  __typename?: 'terminal_employee';
  calendly_url: Maybe<Scalars['String']>;
  /** An object relationship */
  calendly_user: Maybe<Calendly_User>;
  email: Scalars['citext'];
  icims_person_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  icims_profile_person: Maybe<Icims_Profile_Person>;
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  linkedin_url: Maybe<Scalars['String']>;
  location: Scalars['String'];
  name: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  role: Terminal_Employee_Role_Choices_Enum;
  slack: Maybe<Scalars['String']>;
  /** An array relationship */
  terminal_employee_organizations: Array<Terminal_Employee_Organization>;
  /** An aggregate relationship */
  terminal_employee_organizations_aggregate: Terminal_Employee_Organization_Aggregate;
  timezone: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** An object relationship */
  user_info: Maybe<User_Info>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** columns and relationships of "terminal_employee" */
export type Terminal_EmployeeTerminal_Employee_OrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Organization_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
};

/** columns and relationships of "terminal_employee" */
export type Terminal_EmployeeTerminal_Employee_Organizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Terminal_Employee_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Terminal_Employee_Organization_Order_By>>;
  where?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
};

/** aggregated selection of "terminal_employee" */
export type Terminal_Employee_Aggregate = {
  __typename?: 'terminal_employee_aggregate';
  aggregate: Maybe<Terminal_Employee_Aggregate_Fields>;
  nodes: Array<Terminal_Employee>;
};

export type Terminal_Employee_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Terminal_Employee_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Terminal_Employee_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Terminal_Employee_Aggregate_Bool_Exp_Count>;
};

export type Terminal_Employee_Aggregate_Bool_Exp_Bool_And = {
  arguments: Terminal_Employee_Select_Column_Terminal_Employee_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Terminal_Employee_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Terminal_Employee_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Terminal_Employee_Select_Column_Terminal_Employee_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Terminal_Employee_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Terminal_Employee_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Terminal_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Terminal_Employee_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "terminal_employee" */
export type Terminal_Employee_Aggregate_Fields = {
  __typename?: 'terminal_employee_aggregate_fields';
  avg: Maybe<Terminal_Employee_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Terminal_Employee_Max_Fields>;
  min: Maybe<Terminal_Employee_Min_Fields>;
  stddev: Maybe<Terminal_Employee_Stddev_Fields>;
  stddev_pop: Maybe<Terminal_Employee_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Terminal_Employee_Stddev_Samp_Fields>;
  sum: Maybe<Terminal_Employee_Sum_Fields>;
  var_pop: Maybe<Terminal_Employee_Var_Pop_Fields>;
  var_samp: Maybe<Terminal_Employee_Var_Samp_Fields>;
  variance: Maybe<Terminal_Employee_Variance_Fields>;
};

/** aggregate fields of "terminal_employee" */
export type Terminal_Employee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Terminal_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "terminal_employee" */
export type Terminal_Employee_Aggregate_Order_By = {
  avg?: InputMaybe<Terminal_Employee_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Terminal_Employee_Max_Order_By>;
  min?: InputMaybe<Terminal_Employee_Min_Order_By>;
  stddev?: InputMaybe<Terminal_Employee_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Terminal_Employee_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Terminal_Employee_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Terminal_Employee_Sum_Order_By>;
  var_pop?: InputMaybe<Terminal_Employee_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Terminal_Employee_Var_Samp_Order_By>;
  variance?: InputMaybe<Terminal_Employee_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "terminal_employee" */
export type Terminal_Employee_Arr_Rel_Insert_Input = {
  data: Array<Terminal_Employee_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Terminal_Employee_On_Conflict>;
};

/** aggregate avg on columns */
export type Terminal_Employee_Avg_Fields = {
  __typename?: 'terminal_employee_avg_fields';
  icims_person_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "terminal_employee" */
export type Terminal_Employee_Avg_Order_By = {
  icims_person_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "terminal_employee". All fields are combined with a logical 'AND'. */
export type Terminal_Employee_Bool_Exp = {
  _and?: InputMaybe<Array<Terminal_Employee_Bool_Exp>>;
  _not?: InputMaybe<Terminal_Employee_Bool_Exp>;
  _or?: InputMaybe<Array<Terminal_Employee_Bool_Exp>>;
  calendly_url?: InputMaybe<String_Comparison_Exp>;
  calendly_user?: InputMaybe<Calendly_User_Bool_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  icims_person_id?: InputMaybe<Int_Comparison_Exp>;
  icims_profile_person?: InputMaybe<Icims_Profile_Person_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Terminal_Employee_Role_Choices_Enum_Comparison_Exp>;
  slack?: InputMaybe<String_Comparison_Exp>;
  terminal_employee_organizations?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
  terminal_employee_organizations_aggregate?: InputMaybe<Terminal_Employee_Organization_Aggregate_Bool_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
  user_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "terminal_employee" */
export enum Terminal_Employee_Constraint {
  /** unique or primary key constraint on columns "email" */
  TerminalEmployeeEmailKey = 'terminal_employee_email_key',
  /** unique or primary key constraint on columns "id" */
  TerminalEmployeePkey = 'terminal_employee_pkey',
}

/** input type for incrementing numeric columns in table "terminal_employee" */
export type Terminal_Employee_Inc_Input = {
  icims_person_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "terminal_employee" */
export type Terminal_Employee_Insert_Input = {
  calendly_url?: InputMaybe<Scalars['String']>;
  calendly_user?: InputMaybe<Calendly_User_Obj_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['citext']>;
  icims_person_id?: InputMaybe<Scalars['Int']>;
  icims_profile_person?: InputMaybe<Icims_Profile_Person_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Terminal_Employee_Role_Choices_Enum>;
  slack?: InputMaybe<Scalars['String']>;
  terminal_employee_organizations?: InputMaybe<Terminal_Employee_Organization_Arr_Rel_Insert_Input>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Terminal_Employee_Max_Fields = {
  __typename?: 'terminal_employee_max_fields';
  calendly_url: Maybe<Scalars['String']>;
  email: Maybe<Scalars['citext']>;
  icims_person_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  linkedin_url: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  slack: Maybe<Scalars['String']>;
  timezone: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "terminal_employee" */
export type Terminal_Employee_Max_Order_By = {
  calendly_url?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  icims_person_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  slack?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Terminal_Employee_Min_Fields = {
  __typename?: 'terminal_employee_min_fields';
  calendly_url: Maybe<Scalars['String']>;
  email: Maybe<Scalars['citext']>;
  icims_person_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  linkedin_url: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  slack: Maybe<Scalars['String']>;
  timezone: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "terminal_employee" */
export type Terminal_Employee_Min_Order_By = {
  calendly_url?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  icims_person_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  slack?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "terminal_employee" */
export type Terminal_Employee_Mutation_Response = {
  __typename?: 'terminal_employee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Terminal_Employee>;
};

/** input type for inserting object relation for remote table "terminal_employee" */
export type Terminal_Employee_Obj_Rel_Insert_Input = {
  data: Terminal_Employee_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Terminal_Employee_On_Conflict>;
};

/** on_conflict condition type for table "terminal_employee" */
export type Terminal_Employee_On_Conflict = {
  constraint: Terminal_Employee_Constraint;
  update_columns?: Array<Terminal_Employee_Update_Column>;
  where?: InputMaybe<Terminal_Employee_Bool_Exp>;
};

/** Ordering options when selecting data from "terminal_employee". */
export type Terminal_Employee_Order_By = {
  calendly_url?: InputMaybe<Order_By>;
  calendly_user?: InputMaybe<Calendly_User_Order_By>;
  email?: InputMaybe<Order_By>;
  icims_person_id?: InputMaybe<Order_By>;
  icims_profile_person?: InputMaybe<Icims_Profile_Person_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  slack?: InputMaybe<Order_By>;
  terminal_employee_organizations_aggregate?: InputMaybe<Terminal_Employee_Organization_Aggregate_Order_By>;
  timezone?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "terminal_employee_organization" */
export type Terminal_Employee_Organization = {
  __typename?: 'terminal_employee_organization';
  id: Scalars['Int'];
  is_assigned_cs: Maybe<Scalars['Boolean']>;
  is_onboarding_lead: Maybe<Scalars['Boolean']>;
  organization: Scalars['Int'];
  /** An object relationship */
  organizationByOrganizationId: Organization;
  /** An object relationship */
  terminalEmployeeByTerminalEmployeeId: Terminal_Employee;
  terminal_employee: Scalars['Int'];
};

/** aggregated selection of "terminal_employee_organization" */
export type Terminal_Employee_Organization_Aggregate = {
  __typename?: 'terminal_employee_organization_aggregate';
  aggregate: Maybe<Terminal_Employee_Organization_Aggregate_Fields>;
  nodes: Array<Terminal_Employee_Organization>;
};

export type Terminal_Employee_Organization_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Terminal_Employee_Organization_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Terminal_Employee_Organization_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Terminal_Employee_Organization_Aggregate_Bool_Exp_Count>;
};

export type Terminal_Employee_Organization_Aggregate_Bool_Exp_Bool_And = {
  arguments: Terminal_Employee_Organization_Select_Column_Terminal_Employee_Organization_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Terminal_Employee_Organization_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Terminal_Employee_Organization_Select_Column_Terminal_Employee_Organization_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Terminal_Employee_Organization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Terminal_Employee_Organization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "terminal_employee_organization" */
export type Terminal_Employee_Organization_Aggregate_Fields = {
  __typename?: 'terminal_employee_organization_aggregate_fields';
  avg: Maybe<Terminal_Employee_Organization_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Terminal_Employee_Organization_Max_Fields>;
  min: Maybe<Terminal_Employee_Organization_Min_Fields>;
  stddev: Maybe<Terminal_Employee_Organization_Stddev_Fields>;
  stddev_pop: Maybe<Terminal_Employee_Organization_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Terminal_Employee_Organization_Stddev_Samp_Fields>;
  sum: Maybe<Terminal_Employee_Organization_Sum_Fields>;
  var_pop: Maybe<Terminal_Employee_Organization_Var_Pop_Fields>;
  var_samp: Maybe<Terminal_Employee_Organization_Var_Samp_Fields>;
  variance: Maybe<Terminal_Employee_Organization_Variance_Fields>;
};

/** aggregate fields of "terminal_employee_organization" */
export type Terminal_Employee_Organization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Terminal_Employee_Organization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Aggregate_Order_By = {
  avg?: InputMaybe<Terminal_Employee_Organization_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Terminal_Employee_Organization_Max_Order_By>;
  min?: InputMaybe<Terminal_Employee_Organization_Min_Order_By>;
  stddev?: InputMaybe<Terminal_Employee_Organization_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Terminal_Employee_Organization_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Terminal_Employee_Organization_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Terminal_Employee_Organization_Sum_Order_By>;
  var_pop?: InputMaybe<Terminal_Employee_Organization_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Terminal_Employee_Organization_Var_Samp_Order_By>;
  variance?: InputMaybe<Terminal_Employee_Organization_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Arr_Rel_Insert_Input = {
  data: Array<Terminal_Employee_Organization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Terminal_Employee_Organization_On_Conflict>;
};

/** aggregate avg on columns */
export type Terminal_Employee_Organization_Avg_Fields = {
  __typename?: 'terminal_employee_organization_avg_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
  terminal_employee: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  terminal_employee?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "terminal_employee_organization". All fields are combined with a logical 'AND'. */
export type Terminal_Employee_Organization_Bool_Exp = {
  _and?: InputMaybe<Array<Terminal_Employee_Organization_Bool_Exp>>;
  _not?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
  _or?: InputMaybe<Array<Terminal_Employee_Organization_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_assigned_cs?: InputMaybe<Boolean_Comparison_Exp>;
  is_onboarding_lead?: InputMaybe<Boolean_Comparison_Exp>;
  organization?: InputMaybe<Int_Comparison_Exp>;
  organizationByOrganizationId?: InputMaybe<Organization_Bool_Exp>;
  terminalEmployeeByTerminalEmployeeId?: InputMaybe<Terminal_Employee_Bool_Exp>;
  terminal_employee?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "terminal_employee_organization" */
export enum Terminal_Employee_Organization_Constraint {
  /** unique or primary key constraint on columns "id" */
  TerminalEmployeeOrganizationPkey = 'terminal_employee_organization_pkey',
  /** unique or primary key constraint on columns "organization", "terminal_employee" */
  TerminalEmployeeOrganizationTerminalEmployeeOrganizationK = 'terminal_employee_organization_terminal_employee_organization_k',
}

/** input type for incrementing numeric columns in table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['Int']>;
  terminal_employee?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_assigned_cs?: InputMaybe<Scalars['Boolean']>;
  is_onboarding_lead?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<Scalars['Int']>;
  organizationByOrganizationId?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  terminalEmployeeByTerminalEmployeeId?: InputMaybe<Terminal_Employee_Obj_Rel_Insert_Input>;
  terminal_employee?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Terminal_Employee_Organization_Max_Fields = {
  __typename?: 'terminal_employee_organization_max_fields';
  id: Maybe<Scalars['Int']>;
  organization: Maybe<Scalars['Int']>;
  terminal_employee: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  terminal_employee?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Terminal_Employee_Organization_Min_Fields = {
  __typename?: 'terminal_employee_organization_min_fields';
  id: Maybe<Scalars['Int']>;
  organization: Maybe<Scalars['Int']>;
  terminal_employee: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  terminal_employee?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Mutation_Response = {
  __typename?: 'terminal_employee_organization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Terminal_Employee_Organization>;
};

/** on_conflict condition type for table "terminal_employee_organization" */
export type Terminal_Employee_Organization_On_Conflict = {
  constraint: Terminal_Employee_Organization_Constraint;
  update_columns?: Array<Terminal_Employee_Organization_Update_Column>;
  where?: InputMaybe<Terminal_Employee_Organization_Bool_Exp>;
};

/** Ordering options when selecting data from "terminal_employee_organization". */
export type Terminal_Employee_Organization_Order_By = {
  id?: InputMaybe<Order_By>;
  is_assigned_cs?: InputMaybe<Order_By>;
  is_onboarding_lead?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  organizationByOrganizationId?: InputMaybe<Organization_Order_By>;
  terminalEmployeeByTerminalEmployeeId?: InputMaybe<Terminal_Employee_Order_By>;
  terminal_employee?: InputMaybe<Order_By>;
};

/** primary key columns input for table: terminal_employee_organization */
export type Terminal_Employee_Organization_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "terminal_employee_organization" */
export enum Terminal_Employee_Organization_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsAssignedCs = 'is_assigned_cs',
  /** column name */
  IsOnboardingLead = 'is_onboarding_lead',
  /** column name */
  Organization = 'organization',
  /** column name */
  TerminalEmployee = 'terminal_employee',
}

/** select "terminal_employee_organization_aggregate_bool_exp_bool_and_arguments_columns" columns of table "terminal_employee_organization" */
export enum Terminal_Employee_Organization_Select_Column_Terminal_Employee_Organization_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsAssignedCs = 'is_assigned_cs',
  /** column name */
  IsOnboardingLead = 'is_onboarding_lead',
}

/** select "terminal_employee_organization_aggregate_bool_exp_bool_or_arguments_columns" columns of table "terminal_employee_organization" */
export enum Terminal_Employee_Organization_Select_Column_Terminal_Employee_Organization_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsAssignedCs = 'is_assigned_cs',
  /** column name */
  IsOnboardingLead = 'is_onboarding_lead',
}

/** input type for updating data in table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_assigned_cs?: InputMaybe<Scalars['Boolean']>;
  is_onboarding_lead?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<Scalars['Int']>;
  terminal_employee?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Terminal_Employee_Organization_Stddev_Fields = {
  __typename?: 'terminal_employee_organization_stddev_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
  terminal_employee: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  terminal_employee?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Terminal_Employee_Organization_Stddev_Pop_Fields = {
  __typename?: 'terminal_employee_organization_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
  terminal_employee: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  terminal_employee?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Terminal_Employee_Organization_Stddev_Samp_Fields = {
  __typename?: 'terminal_employee_organization_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
  terminal_employee: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  terminal_employee?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Terminal_Employee_Organization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Terminal_Employee_Organization_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  is_assigned_cs?: InputMaybe<Scalars['Boolean']>;
  is_onboarding_lead?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<Scalars['Int']>;
  terminal_employee?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Terminal_Employee_Organization_Sum_Fields = {
  __typename?: 'terminal_employee_organization_sum_fields';
  id: Maybe<Scalars['Int']>;
  organization: Maybe<Scalars['Int']>;
  terminal_employee: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  terminal_employee?: InputMaybe<Order_By>;
};

/** update columns of table "terminal_employee_organization" */
export enum Terminal_Employee_Organization_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsAssignedCs = 'is_assigned_cs',
  /** column name */
  IsOnboardingLead = 'is_onboarding_lead',
  /** column name */
  Organization = 'organization',
  /** column name */
  TerminalEmployee = 'terminal_employee',
}

export type Terminal_Employee_Organization_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Terminal_Employee_Organization_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Terminal_Employee_Organization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Terminal_Employee_Organization_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Terminal_Employee_Organization_Var_Pop_Fields = {
  __typename?: 'terminal_employee_organization_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
  terminal_employee: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  terminal_employee?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Terminal_Employee_Organization_Var_Samp_Fields = {
  __typename?: 'terminal_employee_organization_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
  terminal_employee: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  terminal_employee?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Terminal_Employee_Organization_Variance_Fields = {
  __typename?: 'terminal_employee_organization_variance_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
  terminal_employee: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "terminal_employee_organization" */
export type Terminal_Employee_Organization_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  terminal_employee?: InputMaybe<Order_By>;
};

/** primary key columns input for table: terminal_employee */
export type Terminal_Employee_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "terminal_employee_role_choices" */
export type Terminal_Employee_Role_Choices = {
  __typename?: 'terminal_employee_role_choices';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "terminal_employee_role_choices" */
export type Terminal_Employee_Role_Choices_Aggregate = {
  __typename?: 'terminal_employee_role_choices_aggregate';
  aggregate: Maybe<Terminal_Employee_Role_Choices_Aggregate_Fields>;
  nodes: Array<Terminal_Employee_Role_Choices>;
};

/** aggregate fields of "terminal_employee_role_choices" */
export type Terminal_Employee_Role_Choices_Aggregate_Fields = {
  __typename?: 'terminal_employee_role_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Terminal_Employee_Role_Choices_Max_Fields>;
  min: Maybe<Terminal_Employee_Role_Choices_Min_Fields>;
};

/** aggregate fields of "terminal_employee_role_choices" */
export type Terminal_Employee_Role_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Terminal_Employee_Role_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "terminal_employee_role_choices". All fields are combined with a logical 'AND'. */
export type Terminal_Employee_Role_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<Terminal_Employee_Role_Choices_Bool_Exp>>;
  _not?: InputMaybe<Terminal_Employee_Role_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<Terminal_Employee_Role_Choices_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "terminal_employee_role_choices" */
export enum Terminal_Employee_Role_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  TerminalEmployeeRoleChoicesPkey = 'terminal_employee_role_choices_pkey',
}

export enum Terminal_Employee_Role_Choices_Enum {
  /** Coordinator */
  Coordinator = 'COORDINATOR',
  /** Sourcer */
  Sourcer = 'SOURCER',
  /** Talent Partner */
  TalentPartner = 'TALENT_PARTNER',
}

/** Boolean expression to compare columns of type "terminal_employee_role_choices_enum". All fields are combined with logical 'AND'. */
export type Terminal_Employee_Role_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Terminal_Employee_Role_Choices_Enum>;
  _in?: InputMaybe<Array<Terminal_Employee_Role_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Terminal_Employee_Role_Choices_Enum>;
  _nin?: InputMaybe<Array<Terminal_Employee_Role_Choices_Enum>>;
};

/** input type for inserting data into table "terminal_employee_role_choices" */
export type Terminal_Employee_Role_Choices_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Terminal_Employee_Role_Choices_Max_Fields = {
  __typename?: 'terminal_employee_role_choices_max_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Terminal_Employee_Role_Choices_Min_Fields = {
  __typename?: 'terminal_employee_role_choices_min_fields';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "terminal_employee_role_choices" */
export type Terminal_Employee_Role_Choices_Mutation_Response = {
  __typename?: 'terminal_employee_role_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Terminal_Employee_Role_Choices>;
};

/** on_conflict condition type for table "terminal_employee_role_choices" */
export type Terminal_Employee_Role_Choices_On_Conflict = {
  constraint: Terminal_Employee_Role_Choices_Constraint;
  update_columns?: Array<Terminal_Employee_Role_Choices_Update_Column>;
  where?: InputMaybe<Terminal_Employee_Role_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "terminal_employee_role_choices". */
export type Terminal_Employee_Role_Choices_Order_By = {
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: terminal_employee_role_choices */
export type Terminal_Employee_Role_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "terminal_employee_role_choices" */
export enum Terminal_Employee_Role_Choices_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "terminal_employee_role_choices" */
export type Terminal_Employee_Role_Choices_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "terminal_employee_role_choices" */
export type Terminal_Employee_Role_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Terminal_Employee_Role_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Terminal_Employee_Role_Choices_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "terminal_employee_role_choices" */
export enum Terminal_Employee_Role_Choices_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

export type Terminal_Employee_Role_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Terminal_Employee_Role_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Terminal_Employee_Role_Choices_Bool_Exp;
};

/** select columns of table "terminal_employee" */
export enum Terminal_Employee_Select_Column {
  /** column name */
  CalendlyUrl = 'calendly_url',
  /** column name */
  Email = 'email',
  /** column name */
  IcimsPersonId = 'icims_person_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Role = 'role',
  /** column name */
  Slack = 'slack',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Title = 'title',
  /** column name */
  UserInfoId = 'user_info_id',
}

/** select "terminal_employee_aggregate_bool_exp_bool_and_arguments_columns" columns of table "terminal_employee" */
export enum Terminal_Employee_Select_Column_Terminal_Employee_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** select "terminal_employee_aggregate_bool_exp_bool_or_arguments_columns" columns of table "terminal_employee" */
export enum Terminal_Employee_Select_Column_Terminal_Employee_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** input type for updating data in table "terminal_employee" */
export type Terminal_Employee_Set_Input = {
  calendly_url?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  icims_person_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Terminal_Employee_Role_Choices_Enum>;
  slack?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Terminal_Employee_Stddev_Fields = {
  __typename?: 'terminal_employee_stddev_fields';
  icims_person_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "terminal_employee" */
export type Terminal_Employee_Stddev_Order_By = {
  icims_person_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Terminal_Employee_Stddev_Pop_Fields = {
  __typename?: 'terminal_employee_stddev_pop_fields';
  icims_person_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "terminal_employee" */
export type Terminal_Employee_Stddev_Pop_Order_By = {
  icims_person_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Terminal_Employee_Stddev_Samp_Fields = {
  __typename?: 'terminal_employee_stddev_samp_fields';
  icims_person_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "terminal_employee" */
export type Terminal_Employee_Stddev_Samp_Order_By = {
  icims_person_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "terminal_employee" */
export type Terminal_Employee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Terminal_Employee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Terminal_Employee_Stream_Cursor_Value_Input = {
  calendly_url?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  icims_person_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Terminal_Employee_Role_Choices_Enum>;
  slack?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Terminal_Employee_Sum_Fields = {
  __typename?: 'terminal_employee_sum_fields';
  icims_person_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "terminal_employee" */
export type Terminal_Employee_Sum_Order_By = {
  icims_person_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** update columns of table "terminal_employee" */
export enum Terminal_Employee_Update_Column {
  /** column name */
  CalendlyUrl = 'calendly_url',
  /** column name */
  Email = 'email',
  /** column name */
  IcimsPersonId = 'icims_person_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Role = 'role',
  /** column name */
  Slack = 'slack',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Title = 'title',
  /** column name */
  UserInfoId = 'user_info_id',
}

export type Terminal_Employee_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Terminal_Employee_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Terminal_Employee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Terminal_Employee_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Terminal_Employee_Var_Pop_Fields = {
  __typename?: 'terminal_employee_var_pop_fields';
  icims_person_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "terminal_employee" */
export type Terminal_Employee_Var_Pop_Order_By = {
  icims_person_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Terminal_Employee_Var_Samp_Fields = {
  __typename?: 'terminal_employee_var_samp_fields';
  icims_person_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "terminal_employee" */
export type Terminal_Employee_Var_Samp_Order_By = {
  icims_person_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Terminal_Employee_Variance_Fields = {
  __typename?: 'terminal_employee_variance_fields';
  icims_person_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "terminal_employee" */
export type Terminal_Employee_Variance_Order_By = {
  icims_person_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** A view table that holds traits about candidates derived from our existing data, either by computation or Gen AI. */
export type Trait = {
  __typename?: 'trait';
  code: Scalars['String'];
  /** Describe the trait code if its meaning is not obvious from its name */
  description: Maybe<Scalars['String']>;
};

/** aggregated selection of "trait" */
export type Trait_Aggregate = {
  __typename?: 'trait_aggregate';
  aggregate: Maybe<Trait_Aggregate_Fields>;
  nodes: Array<Trait>;
};

/** aggregate fields of "trait" */
export type Trait_Aggregate_Fields = {
  __typename?: 'trait_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Trait_Max_Fields>;
  min: Maybe<Trait_Min_Fields>;
};

/** aggregate fields of "trait" */
export type Trait_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trait_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "trait". All fields are combined with a logical 'AND'. */
export type Trait_Bool_Exp = {
  _and?: InputMaybe<Array<Trait_Bool_Exp>>;
  _not?: InputMaybe<Trait_Bool_Exp>;
  _or?: InputMaybe<Array<Trait_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "trait" */
export enum Trait_Constraint {
  /** unique or primary key constraint on columns "code" */
  TraitPkey = 'trait_pkey',
}

export enum Trait_Enum {
  RisingStar = 'RISING_STAR',
}

/** Boolean expression to compare columns of type "trait_enum". All fields are combined with logical 'AND'. */
export type Trait_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Trait_Enum>;
  _in?: InputMaybe<Array<Trait_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Trait_Enum>;
  _nin?: InputMaybe<Array<Trait_Enum>>;
};

/** input type for inserting data into table "trait" */
export type Trait_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  /** Describe the trait code if its meaning is not obvious from its name */
  description?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Trait_Max_Fields = {
  __typename?: 'trait_max_fields';
  code: Maybe<Scalars['String']>;
  /** Describe the trait code if its meaning is not obvious from its name */
  description: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Trait_Min_Fields = {
  __typename?: 'trait_min_fields';
  code: Maybe<Scalars['String']>;
  /** Describe the trait code if its meaning is not obvious from its name */
  description: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "trait" */
export type Trait_Mutation_Response = {
  __typename?: 'trait_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trait>;
};

/** on_conflict condition type for table "trait" */
export type Trait_On_Conflict = {
  constraint: Trait_Constraint;
  update_columns?: Array<Trait_Update_Column>;
  where?: InputMaybe<Trait_Bool_Exp>;
};

/** Ordering options when selecting data from "trait". */
export type Trait_Order_By = {
  code?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trait */
export type Trait_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "trait" */
export enum Trait_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
}

/** input type for updating data in table "trait" */
export type Trait_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  /** Describe the trait code if its meaning is not obvious from its name */
  description?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "trait" */
export type Trait_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trait_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trait_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  /** Describe the trait code if its meaning is not obvious from its name */
  description?: InputMaybe<Scalars['String']>;
};

/** update columns of table "trait" */
export enum Trait_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
}

export type Trait_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trait_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trait_Bool_Exp;
};

/** Table to store results of AI transcript evaluation  */
export type Transcript_Evaluation = {
  __typename?: 'transcript_evaluation';
  candidate_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  detail_level: Candidate_Detail_Level_Choices_Enum;
  english_proficiency: Candidate_Curation_English_Proficiency_Choices_Enum;
  id: Scalars['Int'];
  most_proficient_technologies: Scalars['jsonb'];
  most_recent_technologies: Scalars['jsonb'];
  question_summary: Scalars['jsonb'];
  recommendation: Scalars['String'];
  sdlc_experience: Candidate_Sdlc_Experience_Choices_Enum;
  transcript_id: Scalars['String'];
  transcript_source: Scalars['String'];
  transcript_summary: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Table to store results of AI transcript evaluation  */
export type Transcript_EvaluationMost_Proficient_TechnologiesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Table to store results of AI transcript evaluation  */
export type Transcript_EvaluationMost_Recent_TechnologiesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Table to store results of AI transcript evaluation  */
export type Transcript_EvaluationQuestion_SummaryArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "transcript_evaluation" */
export type Transcript_Evaluation_Aggregate = {
  __typename?: 'transcript_evaluation_aggregate';
  aggregate: Maybe<Transcript_Evaluation_Aggregate_Fields>;
  nodes: Array<Transcript_Evaluation>;
};

export type Transcript_Evaluation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Transcript_Evaluation_Aggregate_Bool_Exp_Count>;
};

export type Transcript_Evaluation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Transcript_Evaluation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transcript_Evaluation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "transcript_evaluation" */
export type Transcript_Evaluation_Aggregate_Fields = {
  __typename?: 'transcript_evaluation_aggregate_fields';
  avg: Maybe<Transcript_Evaluation_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Transcript_Evaluation_Max_Fields>;
  min: Maybe<Transcript_Evaluation_Min_Fields>;
  stddev: Maybe<Transcript_Evaluation_Stddev_Fields>;
  stddev_pop: Maybe<Transcript_Evaluation_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Transcript_Evaluation_Stddev_Samp_Fields>;
  sum: Maybe<Transcript_Evaluation_Sum_Fields>;
  var_pop: Maybe<Transcript_Evaluation_Var_Pop_Fields>;
  var_samp: Maybe<Transcript_Evaluation_Var_Samp_Fields>;
  variance: Maybe<Transcript_Evaluation_Variance_Fields>;
};

/** aggregate fields of "transcript_evaluation" */
export type Transcript_Evaluation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transcript_Evaluation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transcript_evaluation" */
export type Transcript_Evaluation_Aggregate_Order_By = {
  avg?: InputMaybe<Transcript_Evaluation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Transcript_Evaluation_Max_Order_By>;
  min?: InputMaybe<Transcript_Evaluation_Min_Order_By>;
  stddev?: InputMaybe<Transcript_Evaluation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Transcript_Evaluation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Transcript_Evaluation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Transcript_Evaluation_Sum_Order_By>;
  var_pop?: InputMaybe<Transcript_Evaluation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Transcript_Evaluation_Var_Samp_Order_By>;
  variance?: InputMaybe<Transcript_Evaluation_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Transcript_Evaluation_Append_Input = {
  most_proficient_technologies?: InputMaybe<Scalars['jsonb']>;
  most_recent_technologies?: InputMaybe<Scalars['jsonb']>;
  question_summary?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "transcript_evaluation" */
export type Transcript_Evaluation_Arr_Rel_Insert_Input = {
  data: Array<Transcript_Evaluation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Transcript_Evaluation_On_Conflict>;
};

/** aggregate avg on columns */
export type Transcript_Evaluation_Avg_Fields = {
  __typename?: 'transcript_evaluation_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transcript_evaluation" */
export type Transcript_Evaluation_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transcript_evaluation". All fields are combined with a logical 'AND'. */
export type Transcript_Evaluation_Bool_Exp = {
  _and?: InputMaybe<Array<Transcript_Evaluation_Bool_Exp>>;
  _not?: InputMaybe<Transcript_Evaluation_Bool_Exp>;
  _or?: InputMaybe<Array<Transcript_Evaluation_Bool_Exp>>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  detail_level?: InputMaybe<Candidate_Detail_Level_Choices_Enum_Comparison_Exp>;
  english_proficiency?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Enum_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  most_proficient_technologies?: InputMaybe<Jsonb_Comparison_Exp>;
  most_recent_technologies?: InputMaybe<Jsonb_Comparison_Exp>;
  question_summary?: InputMaybe<Jsonb_Comparison_Exp>;
  recommendation?: InputMaybe<String_Comparison_Exp>;
  sdlc_experience?: InputMaybe<Candidate_Sdlc_Experience_Choices_Enum_Comparison_Exp>;
  transcript_id?: InputMaybe<String_Comparison_Exp>;
  transcript_source?: InputMaybe<String_Comparison_Exp>;
  transcript_summary?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transcript_evaluation" */
export enum Transcript_Evaluation_Constraint {
  /** unique or primary key constraint on columns "id" */
  TranscriptEvaluationPkey = 'transcript_evaluation_pkey',
  /** unique or primary key constraint on columns "transcript_source", "transcript_id" */
  TranscriptEvaluationTranscriptIdTranscriptSourceKey = 'transcript_evaluation_transcript_id_transcript_source_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Transcript_Evaluation_Delete_At_Path_Input = {
  most_proficient_technologies?: InputMaybe<Array<Scalars['String']>>;
  most_recent_technologies?: InputMaybe<Array<Scalars['String']>>;
  question_summary?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Transcript_Evaluation_Delete_Elem_Input = {
  most_proficient_technologies?: InputMaybe<Scalars['Int']>;
  most_recent_technologies?: InputMaybe<Scalars['Int']>;
  question_summary?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Transcript_Evaluation_Delete_Key_Input = {
  most_proficient_technologies?: InputMaybe<Scalars['String']>;
  most_recent_technologies?: InputMaybe<Scalars['String']>;
  question_summary?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "transcript_evaluation" */
export type Transcript_Evaluation_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "transcript_evaluation" */
export type Transcript_Evaluation_Insert_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detail_level?: InputMaybe<Candidate_Detail_Level_Choices_Enum>;
  english_proficiency?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  most_proficient_technologies?: InputMaybe<Scalars['jsonb']>;
  most_recent_technologies?: InputMaybe<Scalars['jsonb']>;
  question_summary?: InputMaybe<Scalars['jsonb']>;
  recommendation?: InputMaybe<Scalars['String']>;
  sdlc_experience?: InputMaybe<Candidate_Sdlc_Experience_Choices_Enum>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_source?: InputMaybe<Scalars['String']>;
  transcript_summary?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Transcript_Evaluation_Max_Fields = {
  __typename?: 'transcript_evaluation_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  recommendation: Maybe<Scalars['String']>;
  transcript_id: Maybe<Scalars['String']>;
  transcript_source: Maybe<Scalars['String']>;
  transcript_summary: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "transcript_evaluation" */
export type Transcript_Evaluation_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recommendation?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_source?: InputMaybe<Order_By>;
  transcript_summary?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Transcript_Evaluation_Min_Fields = {
  __typename?: 'transcript_evaluation_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  recommendation: Maybe<Scalars['String']>;
  transcript_id: Maybe<Scalars['String']>;
  transcript_source: Maybe<Scalars['String']>;
  transcript_summary: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "transcript_evaluation" */
export type Transcript_Evaluation_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recommendation?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_source?: InputMaybe<Order_By>;
  transcript_summary?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "transcript_evaluation" */
export type Transcript_Evaluation_Mutation_Response = {
  __typename?: 'transcript_evaluation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transcript_Evaluation>;
};

/** on_conflict condition type for table "transcript_evaluation" */
export type Transcript_Evaluation_On_Conflict = {
  constraint: Transcript_Evaluation_Constraint;
  update_columns?: Array<Transcript_Evaluation_Update_Column>;
  where?: InputMaybe<Transcript_Evaluation_Bool_Exp>;
};

/** Ordering options when selecting data from "transcript_evaluation". */
export type Transcript_Evaluation_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  detail_level?: InputMaybe<Order_By>;
  english_proficiency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  most_proficient_technologies?: InputMaybe<Order_By>;
  most_recent_technologies?: InputMaybe<Order_By>;
  question_summary?: InputMaybe<Order_By>;
  recommendation?: InputMaybe<Order_By>;
  sdlc_experience?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_source?: InputMaybe<Order_By>;
  transcript_summary?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transcript_evaluation */
export type Transcript_Evaluation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Transcript_Evaluation_Prepend_Input = {
  most_proficient_technologies?: InputMaybe<Scalars['jsonb']>;
  most_recent_technologies?: InputMaybe<Scalars['jsonb']>;
  question_summary?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "transcript_evaluation" */
export enum Transcript_Evaluation_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DetailLevel = 'detail_level',
  /** column name */
  EnglishProficiency = 'english_proficiency',
  /** column name */
  Id = 'id',
  /** column name */
  MostProficientTechnologies = 'most_proficient_technologies',
  /** column name */
  MostRecentTechnologies = 'most_recent_technologies',
  /** column name */
  QuestionSummary = 'question_summary',
  /** column name */
  Recommendation = 'recommendation',
  /** column name */
  SdlcExperience = 'sdlc_experience',
  /** column name */
  TranscriptId = 'transcript_id',
  /** column name */
  TranscriptSource = 'transcript_source',
  /** column name */
  TranscriptSummary = 'transcript_summary',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "transcript_evaluation" */
export type Transcript_Evaluation_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detail_level?: InputMaybe<Candidate_Detail_Level_Choices_Enum>;
  english_proficiency?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  most_proficient_technologies?: InputMaybe<Scalars['jsonb']>;
  most_recent_technologies?: InputMaybe<Scalars['jsonb']>;
  question_summary?: InputMaybe<Scalars['jsonb']>;
  recommendation?: InputMaybe<Scalars['String']>;
  sdlc_experience?: InputMaybe<Candidate_Sdlc_Experience_Choices_Enum>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_source?: InputMaybe<Scalars['String']>;
  transcript_summary?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Transcript_Evaluation_Stddev_Fields = {
  __typename?: 'transcript_evaluation_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transcript_evaluation" */
export type Transcript_Evaluation_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transcript_Evaluation_Stddev_Pop_Fields = {
  __typename?: 'transcript_evaluation_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "transcript_evaluation" */
export type Transcript_Evaluation_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transcript_Evaluation_Stddev_Samp_Fields = {
  __typename?: 'transcript_evaluation_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "transcript_evaluation" */
export type Transcript_Evaluation_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transcript_evaluation" */
export type Transcript_Evaluation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transcript_Evaluation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transcript_Evaluation_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detail_level?: InputMaybe<Candidate_Detail_Level_Choices_Enum>;
  english_proficiency?: InputMaybe<Candidate_Curation_English_Proficiency_Choices_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  most_proficient_technologies?: InputMaybe<Scalars['jsonb']>;
  most_recent_technologies?: InputMaybe<Scalars['jsonb']>;
  question_summary?: InputMaybe<Scalars['jsonb']>;
  recommendation?: InputMaybe<Scalars['String']>;
  sdlc_experience?: InputMaybe<Candidate_Sdlc_Experience_Choices_Enum>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_source?: InputMaybe<Scalars['String']>;
  transcript_summary?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Transcript_Evaluation_Sum_Fields = {
  __typename?: 'transcript_evaluation_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "transcript_evaluation" */
export type Transcript_Evaluation_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "transcript_evaluation" */
export enum Transcript_Evaluation_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DetailLevel = 'detail_level',
  /** column name */
  EnglishProficiency = 'english_proficiency',
  /** column name */
  Id = 'id',
  /** column name */
  MostProficientTechnologies = 'most_proficient_technologies',
  /** column name */
  MostRecentTechnologies = 'most_recent_technologies',
  /** column name */
  QuestionSummary = 'question_summary',
  /** column name */
  Recommendation = 'recommendation',
  /** column name */
  SdlcExperience = 'sdlc_experience',
  /** column name */
  TranscriptId = 'transcript_id',
  /** column name */
  TranscriptSource = 'transcript_source',
  /** column name */
  TranscriptSummary = 'transcript_summary',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Transcript_Evaluation_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Transcript_Evaluation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Transcript_Evaluation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Transcript_Evaluation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Transcript_Evaluation_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transcript_Evaluation_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Transcript_Evaluation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transcript_Evaluation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transcript_Evaluation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Transcript_Evaluation_Var_Pop_Fields = {
  __typename?: 'transcript_evaluation_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "transcript_evaluation" */
export type Transcript_Evaluation_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transcript_Evaluation_Var_Samp_Fields = {
  __typename?: 'transcript_evaluation_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "transcript_evaluation" */
export type Transcript_Evaluation_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Transcript_Evaluation_Variance_Fields = {
  __typename?: 'transcript_evaluation_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transcript_evaluation" */
export type Transcript_Evaluation_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "transcript_skill" */
export type Transcript_Skill = {
  __typename?: 'transcript_skill';
  candidate_id: Scalars['Int'];
  competency: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  has_leadership: Maybe<Scalars['Boolean']>;
  has_professional_exp: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  moe: Maybe<Scalars['Int']>;
  more_info: Maybe<Scalars['String']>;
  preference: Maybe<Scalars['String']>;
  review_status: Scalars['String'];
  reviewed_by_user_info_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  reviewer_user_info: Maybe<User_Info>;
  /** An object relationship */
  skill: Maybe<Skill>;
  skill_id: Maybe<Scalars['Int']>;
  skill_name_from_transcript: Maybe<Scalars['String']>;
  transcript_id: Scalars['String'];
  transcript_source: Scalars['String'];
  transcript_work_experience_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "transcript_skill" */
export type Transcript_Skill_Aggregate = {
  __typename?: 'transcript_skill_aggregate';
  aggregate: Maybe<Transcript_Skill_Aggregate_Fields>;
  nodes: Array<Transcript_Skill>;
};

export type Transcript_Skill_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Transcript_Skill_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Transcript_Skill_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Transcript_Skill_Aggregate_Bool_Exp_Count>;
};

export type Transcript_Skill_Aggregate_Bool_Exp_Bool_And = {
  arguments: Transcript_Skill_Select_Column_Transcript_Skill_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transcript_Skill_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Transcript_Skill_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Transcript_Skill_Select_Column_Transcript_Skill_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transcript_Skill_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Transcript_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Transcript_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transcript_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "transcript_skill" */
export type Transcript_Skill_Aggregate_Fields = {
  __typename?: 'transcript_skill_aggregate_fields';
  avg: Maybe<Transcript_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Transcript_Skill_Max_Fields>;
  min: Maybe<Transcript_Skill_Min_Fields>;
  stddev: Maybe<Transcript_Skill_Stddev_Fields>;
  stddev_pop: Maybe<Transcript_Skill_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Transcript_Skill_Stddev_Samp_Fields>;
  sum: Maybe<Transcript_Skill_Sum_Fields>;
  var_pop: Maybe<Transcript_Skill_Var_Pop_Fields>;
  var_samp: Maybe<Transcript_Skill_Var_Samp_Fields>;
  variance: Maybe<Transcript_Skill_Variance_Fields>;
};

/** aggregate fields of "transcript_skill" */
export type Transcript_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transcript_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transcript_skill" */
export type Transcript_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Transcript_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Transcript_Skill_Max_Order_By>;
  min?: InputMaybe<Transcript_Skill_Min_Order_By>;
  stddev?: InputMaybe<Transcript_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Transcript_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Transcript_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Transcript_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Transcript_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Transcript_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Transcript_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "transcript_skill" */
export type Transcript_Skill_Arr_Rel_Insert_Input = {
  data: Array<Transcript_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Transcript_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Transcript_Skill_Avg_Fields = {
  __typename?: 'transcript_skill_avg_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  moe: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  transcript_work_experience_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transcript_skill" */
export type Transcript_Skill_Avg_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moe?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  transcript_work_experience_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transcript_skill". All fields are combined with a logical 'AND'. */
export type Transcript_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Transcript_Skill_Bool_Exp>>;
  _not?: InputMaybe<Transcript_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Transcript_Skill_Bool_Exp>>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  competency?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  has_leadership?: InputMaybe<Boolean_Comparison_Exp>;
  has_professional_exp?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  moe?: InputMaybe<Int_Comparison_Exp>;
  more_info?: InputMaybe<String_Comparison_Exp>;
  preference?: InputMaybe<String_Comparison_Exp>;
  review_status?: InputMaybe<String_Comparison_Exp>;
  reviewed_by_user_info_id?: InputMaybe<Int_Comparison_Exp>;
  reviewer_user_info?: InputMaybe<User_Info_Bool_Exp>;
  skill?: InputMaybe<Skill_Bool_Exp>;
  skill_id?: InputMaybe<Int_Comparison_Exp>;
  skill_name_from_transcript?: InputMaybe<String_Comparison_Exp>;
  transcript_id?: InputMaybe<String_Comparison_Exp>;
  transcript_source?: InputMaybe<String_Comparison_Exp>;
  transcript_work_experience_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transcript_skill" */
export enum Transcript_Skill_Constraint {
  /** unique or primary key constraint on columns "id" */
  TranscriptSkillPkey = 'transcript_skill_pkey',
}

/** input type for incrementing numeric columns in table "transcript_skill" */
export type Transcript_Skill_Inc_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  moe?: InputMaybe<Scalars['Int']>;
  reviewed_by_user_info_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  transcript_work_experience_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "transcript_skill" */
export type Transcript_Skill_Insert_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  competency?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  has_leadership?: InputMaybe<Scalars['Boolean']>;
  has_professional_exp?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  moe?: InputMaybe<Scalars['Int']>;
  more_info?: InputMaybe<Scalars['String']>;
  preference?: InputMaybe<Scalars['String']>;
  review_status?: InputMaybe<Scalars['String']>;
  reviewed_by_user_info_id?: InputMaybe<Scalars['Int']>;
  reviewer_user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  skill?: InputMaybe<Skill_Obj_Rel_Insert_Input>;
  skill_id?: InputMaybe<Scalars['Int']>;
  skill_name_from_transcript?: InputMaybe<Scalars['String']>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_source?: InputMaybe<Scalars['String']>;
  transcript_work_experience_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Transcript_Skill_Max_Fields = {
  __typename?: 'transcript_skill_max_fields';
  candidate_id: Maybe<Scalars['Int']>;
  competency: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  moe: Maybe<Scalars['Int']>;
  more_info: Maybe<Scalars['String']>;
  preference: Maybe<Scalars['String']>;
  review_status: Maybe<Scalars['String']>;
  reviewed_by_user_info_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  skill_name_from_transcript: Maybe<Scalars['String']>;
  transcript_id: Maybe<Scalars['String']>;
  transcript_source: Maybe<Scalars['String']>;
  transcript_work_experience_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "transcript_skill" */
export type Transcript_Skill_Max_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  competency?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moe?: InputMaybe<Order_By>;
  more_info?: InputMaybe<Order_By>;
  preference?: InputMaybe<Order_By>;
  review_status?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  skill_name_from_transcript?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_source?: InputMaybe<Order_By>;
  transcript_work_experience_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Transcript_Skill_Min_Fields = {
  __typename?: 'transcript_skill_min_fields';
  candidate_id: Maybe<Scalars['Int']>;
  competency: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  moe: Maybe<Scalars['Int']>;
  more_info: Maybe<Scalars['String']>;
  preference: Maybe<Scalars['String']>;
  review_status: Maybe<Scalars['String']>;
  reviewed_by_user_info_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  skill_name_from_transcript: Maybe<Scalars['String']>;
  transcript_id: Maybe<Scalars['String']>;
  transcript_source: Maybe<Scalars['String']>;
  transcript_work_experience_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "transcript_skill" */
export type Transcript_Skill_Min_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  competency?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moe?: InputMaybe<Order_By>;
  more_info?: InputMaybe<Order_By>;
  preference?: InputMaybe<Order_By>;
  review_status?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  skill_name_from_transcript?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_source?: InputMaybe<Order_By>;
  transcript_work_experience_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "transcript_skill" */
export type Transcript_Skill_Mutation_Response = {
  __typename?: 'transcript_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transcript_Skill>;
};

/** on_conflict condition type for table "transcript_skill" */
export type Transcript_Skill_On_Conflict = {
  constraint: Transcript_Skill_Constraint;
  update_columns?: Array<Transcript_Skill_Update_Column>;
  where?: InputMaybe<Transcript_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "transcript_skill". */
export type Transcript_Skill_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  competency?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  has_leadership?: InputMaybe<Order_By>;
  has_professional_exp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moe?: InputMaybe<Order_By>;
  more_info?: InputMaybe<Order_By>;
  preference?: InputMaybe<Order_By>;
  review_status?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  reviewer_user_info?: InputMaybe<User_Info_Order_By>;
  skill?: InputMaybe<Skill_Order_By>;
  skill_id?: InputMaybe<Order_By>;
  skill_name_from_transcript?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_source?: InputMaybe<Order_By>;
  transcript_work_experience_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transcript_skill */
export type Transcript_Skill_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "transcript_skill" */
export enum Transcript_Skill_Select_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Competency = 'competency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasLeadership = 'has_leadership',
  /** column name */
  HasProfessionalExp = 'has_professional_exp',
  /** column name */
  Id = 'id',
  /** column name */
  Moe = 'moe',
  /** column name */
  MoreInfo = 'more_info',
  /** column name */
  Preference = 'preference',
  /** column name */
  ReviewStatus = 'review_status',
  /** column name */
  ReviewedByUserInfoId = 'reviewed_by_user_info_id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  SkillNameFromTranscript = 'skill_name_from_transcript',
  /** column name */
  TranscriptId = 'transcript_id',
  /** column name */
  TranscriptSource = 'transcript_source',
  /** column name */
  TranscriptWorkExperienceId = 'transcript_work_experience_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "transcript_skill_aggregate_bool_exp_bool_and_arguments_columns" columns of table "transcript_skill" */
export enum Transcript_Skill_Select_Column_Transcript_Skill_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasLeadership = 'has_leadership',
  /** column name */
  HasProfessionalExp = 'has_professional_exp',
}

/** select "transcript_skill_aggregate_bool_exp_bool_or_arguments_columns" columns of table "transcript_skill" */
export enum Transcript_Skill_Select_Column_Transcript_Skill_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasLeadership = 'has_leadership',
  /** column name */
  HasProfessionalExp = 'has_professional_exp',
}

/** input type for updating data in table "transcript_skill" */
export type Transcript_Skill_Set_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  competency?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  has_leadership?: InputMaybe<Scalars['Boolean']>;
  has_professional_exp?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  moe?: InputMaybe<Scalars['Int']>;
  more_info?: InputMaybe<Scalars['String']>;
  preference?: InputMaybe<Scalars['String']>;
  review_status?: InputMaybe<Scalars['String']>;
  reviewed_by_user_info_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  skill_name_from_transcript?: InputMaybe<Scalars['String']>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_source?: InputMaybe<Scalars['String']>;
  transcript_work_experience_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Transcript_Skill_Stddev_Fields = {
  __typename?: 'transcript_skill_stddev_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  moe: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  transcript_work_experience_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transcript_skill" */
export type Transcript_Skill_Stddev_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moe?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  transcript_work_experience_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transcript_Skill_Stddev_Pop_Fields = {
  __typename?: 'transcript_skill_stddev_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  moe: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  transcript_work_experience_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "transcript_skill" */
export type Transcript_Skill_Stddev_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moe?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  transcript_work_experience_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transcript_Skill_Stddev_Samp_Fields = {
  __typename?: 'transcript_skill_stddev_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  moe: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  transcript_work_experience_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "transcript_skill" */
export type Transcript_Skill_Stddev_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moe?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  transcript_work_experience_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transcript_skill" */
export type Transcript_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transcript_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transcript_Skill_Stream_Cursor_Value_Input = {
  candidate_id?: InputMaybe<Scalars['Int']>;
  competency?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  has_leadership?: InputMaybe<Scalars['Boolean']>;
  has_professional_exp?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  moe?: InputMaybe<Scalars['Int']>;
  more_info?: InputMaybe<Scalars['String']>;
  preference?: InputMaybe<Scalars['String']>;
  review_status?: InputMaybe<Scalars['String']>;
  reviewed_by_user_info_id?: InputMaybe<Scalars['Int']>;
  skill_id?: InputMaybe<Scalars['Int']>;
  skill_name_from_transcript?: InputMaybe<Scalars['String']>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_source?: InputMaybe<Scalars['String']>;
  transcript_work_experience_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Transcript_Skill_Sum_Fields = {
  __typename?: 'transcript_skill_sum_fields';
  candidate_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  moe: Maybe<Scalars['Int']>;
  reviewed_by_user_info_id: Maybe<Scalars['Int']>;
  skill_id: Maybe<Scalars['Int']>;
  transcript_work_experience_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "transcript_skill" */
export type Transcript_Skill_Sum_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moe?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  transcript_work_experience_id?: InputMaybe<Order_By>;
};

/** update columns of table "transcript_skill" */
export enum Transcript_Skill_Update_Column {
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Competency = 'competency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasLeadership = 'has_leadership',
  /** column name */
  HasProfessionalExp = 'has_professional_exp',
  /** column name */
  Id = 'id',
  /** column name */
  Moe = 'moe',
  /** column name */
  MoreInfo = 'more_info',
  /** column name */
  Preference = 'preference',
  /** column name */
  ReviewStatus = 'review_status',
  /** column name */
  ReviewedByUserInfoId = 'reviewed_by_user_info_id',
  /** column name */
  SkillId = 'skill_id',
  /** column name */
  SkillNameFromTranscript = 'skill_name_from_transcript',
  /** column name */
  TranscriptId = 'transcript_id',
  /** column name */
  TranscriptSource = 'transcript_source',
  /** column name */
  TranscriptWorkExperienceId = 'transcript_work_experience_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Transcript_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transcript_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transcript_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transcript_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Transcript_Skill_Var_Pop_Fields = {
  __typename?: 'transcript_skill_var_pop_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  moe: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  transcript_work_experience_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "transcript_skill" */
export type Transcript_Skill_Var_Pop_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moe?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  transcript_work_experience_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transcript_Skill_Var_Samp_Fields = {
  __typename?: 'transcript_skill_var_samp_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  moe: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  transcript_work_experience_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "transcript_skill" */
export type Transcript_Skill_Var_Samp_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moe?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  transcript_work_experience_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Transcript_Skill_Variance_Fields = {
  __typename?: 'transcript_skill_variance_fields';
  candidate_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  moe: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  skill_id: Maybe<Scalars['Float']>;
  transcript_work_experience_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transcript_skill" */
export type Transcript_Skill_Variance_Order_By = {
  candidate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moe?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  skill_id?: InputMaybe<Order_By>;
  transcript_work_experience_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "transcript_work_experience" */
export type Transcript_Work_Experience = {
  __typename?: 'transcript_work_experience';
  /** An object relationship */
  ai_level: Maybe<Ai_Level>;
  ai_level_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  ai_role: Maybe<Ai_Role>;
  ai_role_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  ai_specialty_role: Maybe<Ai_Role>;
  ai_specialty_role_id: Maybe<Scalars['Int']>;
  all_team_members_roles: Maybe<Scalars['String']>;
  candidate_id: Scalars['Int'];
  /** An object relationship */
  candidate_work_experience: Maybe<Candidate_Work_Experience>;
  candidate_work_experience_id: Maybe<Scalars['Int']>;
  company_name: Maybe<Scalars['String']>;
  company_size: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description: Maybe<Scalars['String']>;
  duration_in_month: Maybe<Scalars['Int']>;
  end_date: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  industry: Maybe<Scalars['String']>;
  job_recency_index: Maybe<Scalars['Int']>;
  management_info: Maybe<Scalars['String']>;
  mentorship_info: Maybe<Scalars['String']>;
  review_status: Scalars['String'];
  reviewed_by_user_info_id: Maybe<Scalars['Int']>;
  /** An object relationship */
  reviewer_user_info: Maybe<User_Info>;
  start_date: Maybe<Scalars['date']>;
  team_lead_info: Maybe<Scalars['String']>;
  team_size: Maybe<Scalars['Int']>;
  tech_lead_info: Maybe<Scalars['String']>;
  transcript_id: Scalars['String'];
  /** An array relationship */
  transcript_skills: Array<Transcript_Skill>;
  /** An aggregate relationship */
  transcript_skills_aggregate: Transcript_Skill_Aggregate;
  transcript_source: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "transcript_work_experience" */
export type Transcript_Work_ExperienceTranscript_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Skill_Order_By>>;
  where?: InputMaybe<Transcript_Skill_Bool_Exp>;
};

/** columns and relationships of "transcript_work_experience" */
export type Transcript_Work_ExperienceTranscript_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transcript_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transcript_Skill_Order_By>>;
  where?: InputMaybe<Transcript_Skill_Bool_Exp>;
};

/** aggregated selection of "transcript_work_experience" */
export type Transcript_Work_Experience_Aggregate = {
  __typename?: 'transcript_work_experience_aggregate';
  aggregate: Maybe<Transcript_Work_Experience_Aggregate_Fields>;
  nodes: Array<Transcript_Work_Experience>;
};

export type Transcript_Work_Experience_Aggregate_Bool_Exp = {
  count?: InputMaybe<Transcript_Work_Experience_Aggregate_Bool_Exp_Count>;
};

export type Transcript_Work_Experience_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Transcript_Work_Experience_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "transcript_work_experience" */
export type Transcript_Work_Experience_Aggregate_Fields = {
  __typename?: 'transcript_work_experience_aggregate_fields';
  avg: Maybe<Transcript_Work_Experience_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Transcript_Work_Experience_Max_Fields>;
  min: Maybe<Transcript_Work_Experience_Min_Fields>;
  stddev: Maybe<Transcript_Work_Experience_Stddev_Fields>;
  stddev_pop: Maybe<Transcript_Work_Experience_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Transcript_Work_Experience_Stddev_Samp_Fields>;
  sum: Maybe<Transcript_Work_Experience_Sum_Fields>;
  var_pop: Maybe<Transcript_Work_Experience_Var_Pop_Fields>;
  var_samp: Maybe<Transcript_Work_Experience_Var_Samp_Fields>;
  variance: Maybe<Transcript_Work_Experience_Variance_Fields>;
};

/** aggregate fields of "transcript_work_experience" */
export type Transcript_Work_Experience_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transcript_Work_Experience_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transcript_work_experience" */
export type Transcript_Work_Experience_Aggregate_Order_By = {
  avg?: InputMaybe<Transcript_Work_Experience_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Transcript_Work_Experience_Max_Order_By>;
  min?: InputMaybe<Transcript_Work_Experience_Min_Order_By>;
  stddev?: InputMaybe<Transcript_Work_Experience_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Transcript_Work_Experience_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Transcript_Work_Experience_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Transcript_Work_Experience_Sum_Order_By>;
  var_pop?: InputMaybe<Transcript_Work_Experience_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Transcript_Work_Experience_Var_Samp_Order_By>;
  variance?: InputMaybe<Transcript_Work_Experience_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "transcript_work_experience" */
export type Transcript_Work_Experience_Arr_Rel_Insert_Input = {
  data: Array<Transcript_Work_Experience_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Transcript_Work_Experience_On_Conflict>;
};

/** aggregate avg on columns */
export type Transcript_Work_Experience_Avg_Fields = {
  __typename?: 'transcript_work_experience_avg_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  duration_in_month: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_recency_index: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  team_size: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transcript_work_experience" */
export type Transcript_Work_Experience_Avg_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  duration_in_month?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_recency_index?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  team_size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transcript_work_experience". All fields are combined with a logical 'AND'. */
export type Transcript_Work_Experience_Bool_Exp = {
  _and?: InputMaybe<Array<Transcript_Work_Experience_Bool_Exp>>;
  _not?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
  _or?: InputMaybe<Array<Transcript_Work_Experience_Bool_Exp>>;
  ai_level?: InputMaybe<Ai_Level_Bool_Exp>;
  ai_level_id?: InputMaybe<Int_Comparison_Exp>;
  ai_role?: InputMaybe<Ai_Role_Bool_Exp>;
  ai_role_id?: InputMaybe<Int_Comparison_Exp>;
  ai_specialty_role?: InputMaybe<Ai_Role_Bool_Exp>;
  ai_specialty_role_id?: InputMaybe<Int_Comparison_Exp>;
  all_team_members_roles?: InputMaybe<String_Comparison_Exp>;
  candidate_id?: InputMaybe<Int_Comparison_Exp>;
  candidate_work_experience?: InputMaybe<Candidate_Work_Experience_Bool_Exp>;
  candidate_work_experience_id?: InputMaybe<Int_Comparison_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
  company_size?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  duration_in_month?: InputMaybe<Int_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  industry?: InputMaybe<String_Comparison_Exp>;
  job_recency_index?: InputMaybe<Int_Comparison_Exp>;
  management_info?: InputMaybe<String_Comparison_Exp>;
  mentorship_info?: InputMaybe<String_Comparison_Exp>;
  review_status?: InputMaybe<String_Comparison_Exp>;
  reviewed_by_user_info_id?: InputMaybe<Int_Comparison_Exp>;
  reviewer_user_info?: InputMaybe<User_Info_Bool_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  team_lead_info?: InputMaybe<String_Comparison_Exp>;
  team_size?: InputMaybe<Int_Comparison_Exp>;
  tech_lead_info?: InputMaybe<String_Comparison_Exp>;
  transcript_id?: InputMaybe<String_Comparison_Exp>;
  transcript_skills?: InputMaybe<Transcript_Skill_Bool_Exp>;
  transcript_skills_aggregate?: InputMaybe<Transcript_Skill_Aggregate_Bool_Exp>;
  transcript_source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transcript_work_experience" */
export enum Transcript_Work_Experience_Constraint {
  /** unique or primary key constraint on columns "id" */
  TranscriptWorkExperiencePkey = 'transcript_work_experience_pkey',
}

/** input type for incrementing numeric columns in table "transcript_work_experience" */
export type Transcript_Work_Experience_Inc_Input = {
  ai_level_id?: InputMaybe<Scalars['Int']>;
  ai_role_id?: InputMaybe<Scalars['Int']>;
  ai_specialty_role_id?: InputMaybe<Scalars['Int']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  duration_in_month?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  job_recency_index?: InputMaybe<Scalars['Int']>;
  reviewed_by_user_info_id?: InputMaybe<Scalars['Int']>;
  team_size?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "transcript_work_experience" */
export type Transcript_Work_Experience_Insert_Input = {
  ai_level?: InputMaybe<Ai_Level_Obj_Rel_Insert_Input>;
  ai_level_id?: InputMaybe<Scalars['Int']>;
  ai_role?: InputMaybe<Ai_Role_Obj_Rel_Insert_Input>;
  ai_role_id?: InputMaybe<Scalars['Int']>;
  ai_specialty_role?: InputMaybe<Ai_Role_Obj_Rel_Insert_Input>;
  ai_specialty_role_id?: InputMaybe<Scalars['Int']>;
  all_team_members_roles?: InputMaybe<Scalars['String']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_work_experience?: InputMaybe<Candidate_Work_Experience_Obj_Rel_Insert_Input>;
  candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  company_name?: InputMaybe<Scalars['String']>;
  company_size?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  duration_in_month?: InputMaybe<Scalars['Int']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  job_recency_index?: InputMaybe<Scalars['Int']>;
  management_info?: InputMaybe<Scalars['String']>;
  mentorship_info?: InputMaybe<Scalars['String']>;
  review_status?: InputMaybe<Scalars['String']>;
  reviewed_by_user_info_id?: InputMaybe<Scalars['Int']>;
  reviewer_user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  start_date?: InputMaybe<Scalars['date']>;
  team_lead_info?: InputMaybe<Scalars['String']>;
  team_size?: InputMaybe<Scalars['Int']>;
  tech_lead_info?: InputMaybe<Scalars['String']>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_skills?: InputMaybe<Transcript_Skill_Arr_Rel_Insert_Input>;
  transcript_source?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Transcript_Work_Experience_Max_Fields = {
  __typename?: 'transcript_work_experience_max_fields';
  ai_level_id: Maybe<Scalars['Int']>;
  ai_role_id: Maybe<Scalars['Int']>;
  ai_specialty_role_id: Maybe<Scalars['Int']>;
  all_team_members_roles: Maybe<Scalars['String']>;
  candidate_id: Maybe<Scalars['Int']>;
  candidate_work_experience_id: Maybe<Scalars['Int']>;
  company_name: Maybe<Scalars['String']>;
  company_size: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  duration_in_month: Maybe<Scalars['Int']>;
  end_date: Maybe<Scalars['date']>;
  id: Maybe<Scalars['Int']>;
  industry: Maybe<Scalars['String']>;
  job_recency_index: Maybe<Scalars['Int']>;
  management_info: Maybe<Scalars['String']>;
  mentorship_info: Maybe<Scalars['String']>;
  review_status: Maybe<Scalars['String']>;
  reviewed_by_user_info_id: Maybe<Scalars['Int']>;
  start_date: Maybe<Scalars['date']>;
  team_lead_info: Maybe<Scalars['String']>;
  team_size: Maybe<Scalars['Int']>;
  tech_lead_info: Maybe<Scalars['String']>;
  transcript_id: Maybe<Scalars['String']>;
  transcript_source: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "transcript_work_experience" */
export type Transcript_Work_Experience_Max_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  all_team_members_roles?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  company_size?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration_in_month?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  job_recency_index?: InputMaybe<Order_By>;
  management_info?: InputMaybe<Order_By>;
  mentorship_info?: InputMaybe<Order_By>;
  review_status?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  team_lead_info?: InputMaybe<Order_By>;
  team_size?: InputMaybe<Order_By>;
  tech_lead_info?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Transcript_Work_Experience_Min_Fields = {
  __typename?: 'transcript_work_experience_min_fields';
  ai_level_id: Maybe<Scalars['Int']>;
  ai_role_id: Maybe<Scalars['Int']>;
  ai_specialty_role_id: Maybe<Scalars['Int']>;
  all_team_members_roles: Maybe<Scalars['String']>;
  candidate_id: Maybe<Scalars['Int']>;
  candidate_work_experience_id: Maybe<Scalars['Int']>;
  company_name: Maybe<Scalars['String']>;
  company_size: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  duration_in_month: Maybe<Scalars['Int']>;
  end_date: Maybe<Scalars['date']>;
  id: Maybe<Scalars['Int']>;
  industry: Maybe<Scalars['String']>;
  job_recency_index: Maybe<Scalars['Int']>;
  management_info: Maybe<Scalars['String']>;
  mentorship_info: Maybe<Scalars['String']>;
  review_status: Maybe<Scalars['String']>;
  reviewed_by_user_info_id: Maybe<Scalars['Int']>;
  start_date: Maybe<Scalars['date']>;
  team_lead_info: Maybe<Scalars['String']>;
  team_size: Maybe<Scalars['Int']>;
  tech_lead_info: Maybe<Scalars['String']>;
  transcript_id: Maybe<Scalars['String']>;
  transcript_source: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "transcript_work_experience" */
export type Transcript_Work_Experience_Min_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  all_team_members_roles?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  company_size?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration_in_month?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  job_recency_index?: InputMaybe<Order_By>;
  management_info?: InputMaybe<Order_By>;
  mentorship_info?: InputMaybe<Order_By>;
  review_status?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  team_lead_info?: InputMaybe<Order_By>;
  team_size?: InputMaybe<Order_By>;
  tech_lead_info?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "transcript_work_experience" */
export type Transcript_Work_Experience_Mutation_Response = {
  __typename?: 'transcript_work_experience_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transcript_Work_Experience>;
};

/** on_conflict condition type for table "transcript_work_experience" */
export type Transcript_Work_Experience_On_Conflict = {
  constraint: Transcript_Work_Experience_Constraint;
  update_columns?: Array<Transcript_Work_Experience_Update_Column>;
  where?: InputMaybe<Transcript_Work_Experience_Bool_Exp>;
};

/** Ordering options when selecting data from "transcript_work_experience". */
export type Transcript_Work_Experience_Order_By = {
  ai_level?: InputMaybe<Ai_Level_Order_By>;
  ai_level_id?: InputMaybe<Order_By>;
  ai_role?: InputMaybe<Ai_Role_Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role?: InputMaybe<Ai_Role_Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  all_team_members_roles?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience?: InputMaybe<Candidate_Work_Experience_Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  company_size?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration_in_month?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  job_recency_index?: InputMaybe<Order_By>;
  management_info?: InputMaybe<Order_By>;
  mentorship_info?: InputMaybe<Order_By>;
  review_status?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  reviewer_user_info?: InputMaybe<User_Info_Order_By>;
  start_date?: InputMaybe<Order_By>;
  team_lead_info?: InputMaybe<Order_By>;
  team_size?: InputMaybe<Order_By>;
  tech_lead_info?: InputMaybe<Order_By>;
  transcript_id?: InputMaybe<Order_By>;
  transcript_skills_aggregate?: InputMaybe<Transcript_Skill_Aggregate_Order_By>;
  transcript_source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transcript_work_experience */
export type Transcript_Work_Experience_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "transcript_work_experience" */
export enum Transcript_Work_Experience_Select_Column {
  /** column name */
  AiLevelId = 'ai_level_id',
  /** column name */
  AiRoleId = 'ai_role_id',
  /** column name */
  AiSpecialtyRoleId = 'ai_specialty_role_id',
  /** column name */
  AllTeamMembersRoles = 'all_team_members_roles',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CandidateWorkExperienceId = 'candidate_work_experience_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CompanySize = 'company_size',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DurationInMonth = 'duration_in_month',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  JobRecencyIndex = 'job_recency_index',
  /** column name */
  ManagementInfo = 'management_info',
  /** column name */
  MentorshipInfo = 'mentorship_info',
  /** column name */
  ReviewStatus = 'review_status',
  /** column name */
  ReviewedByUserInfoId = 'reviewed_by_user_info_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  TeamLeadInfo = 'team_lead_info',
  /** column name */
  TeamSize = 'team_size',
  /** column name */
  TechLeadInfo = 'tech_lead_info',
  /** column name */
  TranscriptId = 'transcript_id',
  /** column name */
  TranscriptSource = 'transcript_source',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "transcript_work_experience" */
export type Transcript_Work_Experience_Set_Input = {
  ai_level_id?: InputMaybe<Scalars['Int']>;
  ai_role_id?: InputMaybe<Scalars['Int']>;
  ai_specialty_role_id?: InputMaybe<Scalars['Int']>;
  all_team_members_roles?: InputMaybe<Scalars['String']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  company_name?: InputMaybe<Scalars['String']>;
  company_size?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  duration_in_month?: InputMaybe<Scalars['Int']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  job_recency_index?: InputMaybe<Scalars['Int']>;
  management_info?: InputMaybe<Scalars['String']>;
  mentorship_info?: InputMaybe<Scalars['String']>;
  review_status?: InputMaybe<Scalars['String']>;
  reviewed_by_user_info_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  team_lead_info?: InputMaybe<Scalars['String']>;
  team_size?: InputMaybe<Scalars['Int']>;
  tech_lead_info?: InputMaybe<Scalars['String']>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_source?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Transcript_Work_Experience_Stddev_Fields = {
  __typename?: 'transcript_work_experience_stddev_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  duration_in_month: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_recency_index: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  team_size: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transcript_work_experience" */
export type Transcript_Work_Experience_Stddev_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  duration_in_month?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_recency_index?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  team_size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transcript_Work_Experience_Stddev_Pop_Fields = {
  __typename?: 'transcript_work_experience_stddev_pop_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  duration_in_month: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_recency_index: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  team_size: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "transcript_work_experience" */
export type Transcript_Work_Experience_Stddev_Pop_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  duration_in_month?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_recency_index?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  team_size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transcript_Work_Experience_Stddev_Samp_Fields = {
  __typename?: 'transcript_work_experience_stddev_samp_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  duration_in_month: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_recency_index: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  team_size: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "transcript_work_experience" */
export type Transcript_Work_Experience_Stddev_Samp_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  duration_in_month?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_recency_index?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  team_size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transcript_work_experience" */
export type Transcript_Work_Experience_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transcript_Work_Experience_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transcript_Work_Experience_Stream_Cursor_Value_Input = {
  ai_level_id?: InputMaybe<Scalars['Int']>;
  ai_role_id?: InputMaybe<Scalars['Int']>;
  ai_specialty_role_id?: InputMaybe<Scalars['Int']>;
  all_team_members_roles?: InputMaybe<Scalars['String']>;
  candidate_id?: InputMaybe<Scalars['Int']>;
  candidate_work_experience_id?: InputMaybe<Scalars['Int']>;
  company_name?: InputMaybe<Scalars['String']>;
  company_size?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  duration_in_month?: InputMaybe<Scalars['Int']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  job_recency_index?: InputMaybe<Scalars['Int']>;
  management_info?: InputMaybe<Scalars['String']>;
  mentorship_info?: InputMaybe<Scalars['String']>;
  review_status?: InputMaybe<Scalars['String']>;
  reviewed_by_user_info_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  team_lead_info?: InputMaybe<Scalars['String']>;
  team_size?: InputMaybe<Scalars['Int']>;
  tech_lead_info?: InputMaybe<Scalars['String']>;
  transcript_id?: InputMaybe<Scalars['String']>;
  transcript_source?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Transcript_Work_Experience_Sum_Fields = {
  __typename?: 'transcript_work_experience_sum_fields';
  ai_level_id: Maybe<Scalars['Int']>;
  ai_role_id: Maybe<Scalars['Int']>;
  ai_specialty_role_id: Maybe<Scalars['Int']>;
  candidate_id: Maybe<Scalars['Int']>;
  candidate_work_experience_id: Maybe<Scalars['Int']>;
  duration_in_month: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  job_recency_index: Maybe<Scalars['Int']>;
  reviewed_by_user_info_id: Maybe<Scalars['Int']>;
  team_size: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "transcript_work_experience" */
export type Transcript_Work_Experience_Sum_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  duration_in_month?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_recency_index?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  team_size?: InputMaybe<Order_By>;
};

/** update columns of table "transcript_work_experience" */
export enum Transcript_Work_Experience_Update_Column {
  /** column name */
  AiLevelId = 'ai_level_id',
  /** column name */
  AiRoleId = 'ai_role_id',
  /** column name */
  AiSpecialtyRoleId = 'ai_specialty_role_id',
  /** column name */
  AllTeamMembersRoles = 'all_team_members_roles',
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CandidateWorkExperienceId = 'candidate_work_experience_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CompanySize = 'company_size',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DurationInMonth = 'duration_in_month',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  JobRecencyIndex = 'job_recency_index',
  /** column name */
  ManagementInfo = 'management_info',
  /** column name */
  MentorshipInfo = 'mentorship_info',
  /** column name */
  ReviewStatus = 'review_status',
  /** column name */
  ReviewedByUserInfoId = 'reviewed_by_user_info_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  TeamLeadInfo = 'team_lead_info',
  /** column name */
  TeamSize = 'team_size',
  /** column name */
  TechLeadInfo = 'tech_lead_info',
  /** column name */
  TranscriptId = 'transcript_id',
  /** column name */
  TranscriptSource = 'transcript_source',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Transcript_Work_Experience_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transcript_Work_Experience_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transcript_Work_Experience_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transcript_Work_Experience_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Transcript_Work_Experience_Var_Pop_Fields = {
  __typename?: 'transcript_work_experience_var_pop_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  duration_in_month: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_recency_index: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  team_size: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "transcript_work_experience" */
export type Transcript_Work_Experience_Var_Pop_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  duration_in_month?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_recency_index?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  team_size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transcript_Work_Experience_Var_Samp_Fields = {
  __typename?: 'transcript_work_experience_var_samp_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  duration_in_month: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_recency_index: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  team_size: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "transcript_work_experience" */
export type Transcript_Work_Experience_Var_Samp_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  duration_in_month?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_recency_index?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  team_size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Transcript_Work_Experience_Variance_Fields = {
  __typename?: 'transcript_work_experience_variance_fields';
  ai_level_id: Maybe<Scalars['Float']>;
  ai_role_id: Maybe<Scalars['Float']>;
  ai_specialty_role_id: Maybe<Scalars['Float']>;
  candidate_id: Maybe<Scalars['Float']>;
  candidate_work_experience_id: Maybe<Scalars['Float']>;
  duration_in_month: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  job_recency_index: Maybe<Scalars['Float']>;
  reviewed_by_user_info_id: Maybe<Scalars['Float']>;
  team_size: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transcript_work_experience" */
export type Transcript_Work_Experience_Variance_Order_By = {
  ai_level_id?: InputMaybe<Order_By>;
  ai_role_id?: InputMaybe<Order_By>;
  ai_specialty_role_id?: InputMaybe<Order_By>;
  candidate_id?: InputMaybe<Order_By>;
  candidate_work_experience_id?: InputMaybe<Order_By>;
  duration_in_month?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_recency_index?: InputMaybe<Order_By>;
  reviewed_by_user_info_id?: InputMaybe<Order_By>;
  team_size?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_contact" */
export type User_Contact = {
  __typename?: 'user_contact';
  contact: Maybe<Scalars['Int']>;
  /** An object relationship */
  contactByContact: Maybe<Contact>;
  linkedin_contact: Maybe<Scalars['Int']>;
  manual_contact: Maybe<Scalars['Int']>;
  /** An object relationship */
  referralStatusById: Maybe<Referral_Status>;
  referral_status: Maybe<Scalars['Int']>;
  referred: Maybe<Scalars['Boolean']>;
  user: Maybe<Scalars['Int']>;
  /** An object relationship */
  user_info: Maybe<User_Info>;
};

/** aggregated selection of "user_contact" */
export type User_Contact_Aggregate = {
  __typename?: 'user_contact_aggregate';
  aggregate: Maybe<User_Contact_Aggregate_Fields>;
  nodes: Array<User_Contact>;
};

export type User_Contact_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Contact_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Contact_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Contact_Aggregate_Bool_Exp_Count>;
};

export type User_Contact_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Contact_Select_Column_User_Contact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Contact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Contact_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Contact_Select_Column_User_Contact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Contact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Contact_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Contact_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_contact" */
export type User_Contact_Aggregate_Fields = {
  __typename?: 'user_contact_aggregate_fields';
  avg: Maybe<User_Contact_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<User_Contact_Max_Fields>;
  min: Maybe<User_Contact_Min_Fields>;
  stddev: Maybe<User_Contact_Stddev_Fields>;
  stddev_pop: Maybe<User_Contact_Stddev_Pop_Fields>;
  stddev_samp: Maybe<User_Contact_Stddev_Samp_Fields>;
  sum: Maybe<User_Contact_Sum_Fields>;
  var_pop: Maybe<User_Contact_Var_Pop_Fields>;
  var_samp: Maybe<User_Contact_Var_Samp_Fields>;
  variance: Maybe<User_Contact_Variance_Fields>;
};

/** aggregate fields of "user_contact" */
export type User_Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_contact" */
export type User_Contact_Aggregate_Order_By = {
  avg?: InputMaybe<User_Contact_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Contact_Max_Order_By>;
  min?: InputMaybe<User_Contact_Min_Order_By>;
  stddev?: InputMaybe<User_Contact_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Contact_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Contact_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Contact_Sum_Order_By>;
  var_pop?: InputMaybe<User_Contact_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Contact_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Contact_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_contact" */
export type User_Contact_Arr_Rel_Insert_Input = {
  data: Array<User_Contact_Insert_Input>;
};

/** aggregate avg on columns */
export type User_Contact_Avg_Fields = {
  __typename?: 'user_contact_avg_fields';
  contact: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  manual_contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_contact" */
export type User_Contact_Avg_Order_By = {
  contact?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Order_By>;
  referral_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_contact". All fields are combined with a logical 'AND'. */
export type User_Contact_Bool_Exp = {
  _and?: InputMaybe<Array<User_Contact_Bool_Exp>>;
  _not?: InputMaybe<User_Contact_Bool_Exp>;
  _or?: InputMaybe<Array<User_Contact_Bool_Exp>>;
  contact?: InputMaybe<Int_Comparison_Exp>;
  contactByContact?: InputMaybe<Contact_Bool_Exp>;
  linkedin_contact?: InputMaybe<Int_Comparison_Exp>;
  manual_contact?: InputMaybe<Int_Comparison_Exp>;
  referralStatusById?: InputMaybe<Referral_Status_Bool_Exp>;
  referral_status?: InputMaybe<Int_Comparison_Exp>;
  referred?: InputMaybe<Boolean_Comparison_Exp>;
  user?: InputMaybe<Int_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** input type for inserting data into table "user_contact" */
export type User_Contact_Insert_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  contactByContact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  linkedin_contact?: InputMaybe<Scalars['Int']>;
  manual_contact?: InputMaybe<Scalars['Int']>;
  referralStatusById?: InputMaybe<Referral_Status_Obj_Rel_Insert_Input>;
  referral_status?: InputMaybe<Scalars['Int']>;
  referred?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['Int']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Contact_Max_Fields = {
  __typename?: 'user_contact_max_fields';
  contact: Maybe<Scalars['Int']>;
  linkedin_contact: Maybe<Scalars['Int']>;
  manual_contact: Maybe<Scalars['Int']>;
  referral_status: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "user_contact" */
export type User_Contact_Max_Order_By = {
  contact?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Order_By>;
  referral_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Contact_Min_Fields = {
  __typename?: 'user_contact_min_fields';
  contact: Maybe<Scalars['Int']>;
  linkedin_contact: Maybe<Scalars['Int']>;
  manual_contact: Maybe<Scalars['Int']>;
  referral_status: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "user_contact" */
export type User_Contact_Min_Order_By = {
  contact?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Order_By>;
  referral_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "user_contact". */
export type User_Contact_Order_By = {
  contact?: InputMaybe<Order_By>;
  contactByContact?: InputMaybe<Contact_Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Order_By>;
  referralStatusById?: InputMaybe<Referral_Status_Order_By>;
  referral_status?: InputMaybe<Order_By>;
  referred?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** select columns of table "user_contact" */
export enum User_Contact_Select_Column {
  /** column name */
  Contact = 'contact',
  /** column name */
  LinkedinContact = 'linkedin_contact',
  /** column name */
  ManualContact = 'manual_contact',
  /** column name */
  ReferralStatus = 'referral_status',
  /** column name */
  Referred = 'referred',
  /** column name */
  User = 'user',
}

/** select "user_contact_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_contact" */
export enum User_Contact_Select_Column_User_Contact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Referred = 'referred',
}

/** select "user_contact_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_contact" */
export enum User_Contact_Select_Column_User_Contact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Referred = 'referred',
}

/** aggregate stddev on columns */
export type User_Contact_Stddev_Fields = {
  __typename?: 'user_contact_stddev_fields';
  contact: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  manual_contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_contact" */
export type User_Contact_Stddev_Order_By = {
  contact?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Order_By>;
  referral_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Contact_Stddev_Pop_Fields = {
  __typename?: 'user_contact_stddev_pop_fields';
  contact: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  manual_contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_contact" */
export type User_Contact_Stddev_Pop_Order_By = {
  contact?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Order_By>;
  referral_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Contact_Stddev_Samp_Fields = {
  __typename?: 'user_contact_stddev_samp_fields';
  contact: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  manual_contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_contact" */
export type User_Contact_Stddev_Samp_Order_By = {
  contact?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Order_By>;
  referral_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_contact" */
export type User_Contact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Contact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Contact_Stream_Cursor_Value_Input = {
  contact?: InputMaybe<Scalars['Int']>;
  linkedin_contact?: InputMaybe<Scalars['Int']>;
  manual_contact?: InputMaybe<Scalars['Int']>;
  referral_status?: InputMaybe<Scalars['Int']>;
  referred?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type User_Contact_Sum_Fields = {
  __typename?: 'user_contact_sum_fields';
  contact: Maybe<Scalars['Int']>;
  linkedin_contact: Maybe<Scalars['Int']>;
  manual_contact: Maybe<Scalars['Int']>;
  referral_status: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_contact" */
export type User_Contact_Sum_Order_By = {
  contact?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Order_By>;
  referral_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type User_Contact_Var_Pop_Fields = {
  __typename?: 'user_contact_var_pop_fields';
  contact: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  manual_contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_contact" */
export type User_Contact_Var_Pop_Order_By = {
  contact?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Order_By>;
  referral_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Contact_Var_Samp_Fields = {
  __typename?: 'user_contact_var_samp_fields';
  contact: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  manual_contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_contact" */
export type User_Contact_Var_Samp_Order_By = {
  contact?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Order_By>;
  referral_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Contact_Variance_Fields = {
  __typename?: 'user_contact_variance_fields';
  contact: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  manual_contact: Maybe<Scalars['Float']>;
  referral_status: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_contact" */
export type User_Contact_Variance_Order_By = {
  contact?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  manual_contact?: InputMaybe<Order_By>;
  referral_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_info" */
export type User_Info = {
  __typename?: 'user_info';
  auth_provider: Maybe<Scalars['String']>;
  /** An object relationship */
  calendly_user: Maybe<Calendly_User>;
  /** An object relationship */
  candidate: Maybe<Candidate>;
  candidate_skip_email_verify: Scalars['Boolean'];
  /** An object relationship */
  client_prospect: Maybe<Client_Prospect>;
  created_at: Scalars['timestamptz'];
  do_not_use_name: Scalars['Boolean'];
  email: Maybe<Scalars['citext']>;
  email_verified: Maybe<Scalars['Boolean']>;
  firebase_uid: Scalars['String'];
  first_name: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  last_name: Maybe<Scalars['String']>;
  last_seen_at: Maybe<Scalars['timestamptz']>;
  last_sign_in: Maybe<Scalars['timestamptz']>;
  name: Maybe<Scalars['String']>;
  organization: Maybe<Scalars['Int']>;
  /** An object relationship */
  organizationByOrganization: Maybe<Organization>;
  photo_url: Maybe<Scalars['String']>;
  skip_email_verify: Scalars['Boolean'];
  /** An object relationship */
  temp_blob: Maybe<User_Temp_Blob>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_roles: Array<User_Role>;
  /** An aggregate relationship */
  user_roles_aggregate: User_Role_Aggregate;
  /** An array relationship */
  user_subscriptions: Array<User_Subscription>;
  /** An aggregate relationship */
  user_subscriptions_aggregate: User_Subscription_Aggregate;
};

/** columns and relationships of "user_info" */
export type User_InfoUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

/** columns and relationships of "user_info" */
export type User_InfoUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

/** columns and relationships of "user_info" */
export type User_InfoUser_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
  where?: InputMaybe<User_Subscription_Bool_Exp>;
};

/** columns and relationships of "user_info" */
export type User_InfoUser_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Subscription_Order_By>>;
  where?: InputMaybe<User_Subscription_Bool_Exp>;
};

/** aggregated selection of "user_info" */
export type User_Info_Aggregate = {
  __typename?: 'user_info_aggregate';
  aggregate: Maybe<User_Info_Aggregate_Fields>;
  nodes: Array<User_Info>;
};

export type User_Info_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Info_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Info_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Info_Aggregate_Bool_Exp_Count>;
};

export type User_Info_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Info_Select_Column_User_Info_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Info_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Info_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Info_Select_Column_User_Info_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Info_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Info_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Info_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_info" */
export type User_Info_Aggregate_Fields = {
  __typename?: 'user_info_aggregate_fields';
  avg: Maybe<User_Info_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<User_Info_Max_Fields>;
  min: Maybe<User_Info_Min_Fields>;
  stddev: Maybe<User_Info_Stddev_Fields>;
  stddev_pop: Maybe<User_Info_Stddev_Pop_Fields>;
  stddev_samp: Maybe<User_Info_Stddev_Samp_Fields>;
  sum: Maybe<User_Info_Sum_Fields>;
  var_pop: Maybe<User_Info_Var_Pop_Fields>;
  var_samp: Maybe<User_Info_Var_Samp_Fields>;
  variance: Maybe<User_Info_Variance_Fields>;
};

/** aggregate fields of "user_info" */
export type User_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_info" */
export type User_Info_Aggregate_Order_By = {
  avg?: InputMaybe<User_Info_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Info_Max_Order_By>;
  min?: InputMaybe<User_Info_Min_Order_By>;
  stddev?: InputMaybe<User_Info_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Info_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Info_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Info_Sum_Order_By>;
  var_pop?: InputMaybe<User_Info_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Info_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Info_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_info" */
export type User_Info_Arr_Rel_Insert_Input = {
  data: Array<User_Info_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Info_Avg_Fields = {
  __typename?: 'user_info_avg_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_info" */
export type User_Info_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_info". All fields are combined with a logical 'AND'. */
export type User_Info_Bool_Exp = {
  _and?: InputMaybe<Array<User_Info_Bool_Exp>>;
  _not?: InputMaybe<User_Info_Bool_Exp>;
  _or?: InputMaybe<Array<User_Info_Bool_Exp>>;
  auth_provider?: InputMaybe<String_Comparison_Exp>;
  calendly_user?: InputMaybe<Calendly_User_Bool_Exp>;
  candidate?: InputMaybe<Candidate_Bool_Exp>;
  candidate_skip_email_verify?: InputMaybe<Boolean_Comparison_Exp>;
  client_prospect?: InputMaybe<Client_Prospect_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  do_not_use_name?: InputMaybe<Boolean_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  email_verified?: InputMaybe<Boolean_Comparison_Exp>;
  firebase_uid?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  last_seen_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_sign_in?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Int_Comparison_Exp>;
  organizationByOrganization?: InputMaybe<Organization_Bool_Exp>;
  photo_url?: InputMaybe<String_Comparison_Exp>;
  skip_email_verify?: InputMaybe<Boolean_Comparison_Exp>;
  temp_blob?: InputMaybe<User_Temp_Blob_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_roles?: InputMaybe<User_Role_Bool_Exp>;
  user_roles_aggregate?: InputMaybe<User_Role_Aggregate_Bool_Exp>;
  user_subscriptions?: InputMaybe<User_Subscription_Bool_Exp>;
  user_subscriptions_aggregate?: InputMaybe<User_Subscription_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user_info" */
export enum User_Info_Constraint {
  /** unique or primary key constraint on columns "firebase_uid" */
  UserInfoFirebaseUidKey = 'user_info_firebase_uid_key',
  /** unique or primary key constraint on columns "id" */
  UserInfoPkey = 'user_info_pkey',
}

/** input type for incrementing numeric columns in table "user_info" */
export type User_Info_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_info" */
export type User_Info_Insert_Input = {
  auth_provider?: InputMaybe<Scalars['String']>;
  calendly_user?: InputMaybe<Calendly_User_Obj_Rel_Insert_Input>;
  candidate?: InputMaybe<Candidate_Obj_Rel_Insert_Input>;
  candidate_skip_email_verify?: InputMaybe<Scalars['Boolean']>;
  client_prospect?: InputMaybe<Client_Prospect_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  do_not_use_name?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['citext']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  firebase_uid?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_seen_at?: InputMaybe<Scalars['timestamptz']>;
  last_sign_in?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['Int']>;
  organizationByOrganization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  photo_url?: InputMaybe<Scalars['String']>;
  skip_email_verify?: InputMaybe<Scalars['Boolean']>;
  temp_blob?: InputMaybe<User_Temp_Blob_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_roles?: InputMaybe<User_Role_Arr_Rel_Insert_Input>;
  user_subscriptions?: InputMaybe<User_Subscription_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Info_Max_Fields = {
  __typename?: 'user_info_max_fields';
  auth_provider: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  firebase_uid: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  last_name: Maybe<Scalars['String']>;
  last_seen_at: Maybe<Scalars['timestamptz']>;
  last_sign_in: Maybe<Scalars['timestamptz']>;
  name: Maybe<Scalars['String']>;
  organization: Maybe<Scalars['Int']>;
  photo_url: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user_info" */
export type User_Info_Max_Order_By = {
  auth_provider?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firebase_uid?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_seen_at?: InputMaybe<Order_By>;
  last_sign_in?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  photo_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Info_Min_Fields = {
  __typename?: 'user_info_min_fields';
  auth_provider: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  firebase_uid: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  last_name: Maybe<Scalars['String']>;
  last_seen_at: Maybe<Scalars['timestamptz']>;
  last_sign_in: Maybe<Scalars['timestamptz']>;
  name: Maybe<Scalars['String']>;
  organization: Maybe<Scalars['Int']>;
  photo_url: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user_info" */
export type User_Info_Min_Order_By = {
  auth_provider?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firebase_uid?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_seen_at?: InputMaybe<Order_By>;
  last_sign_in?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  photo_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_info" */
export type User_Info_Mutation_Response = {
  __typename?: 'user_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Info>;
};

/** input type for inserting object relation for remote table "user_info" */
export type User_Info_Obj_Rel_Insert_Input = {
  data: User_Info_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};

/** on_conflict condition type for table "user_info" */
export type User_Info_On_Conflict = {
  constraint: User_Info_Constraint;
  update_columns?: Array<User_Info_Update_Column>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "user_info". */
export type User_Info_Order_By = {
  auth_provider?: InputMaybe<Order_By>;
  calendly_user?: InputMaybe<Calendly_User_Order_By>;
  candidate?: InputMaybe<Candidate_Order_By>;
  candidate_skip_email_verify?: InputMaybe<Order_By>;
  client_prospect?: InputMaybe<Client_Prospect_Order_By>;
  created_at?: InputMaybe<Order_By>;
  do_not_use_name?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_verified?: InputMaybe<Order_By>;
  firebase_uid?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_seen_at?: InputMaybe<Order_By>;
  last_sign_in?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  organizationByOrganization?: InputMaybe<Organization_Order_By>;
  photo_url?: InputMaybe<Order_By>;
  skip_email_verify?: InputMaybe<Order_By>;
  temp_blob?: InputMaybe<User_Temp_Blob_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_roles_aggregate?: InputMaybe<User_Role_Aggregate_Order_By>;
  user_subscriptions_aggregate?: InputMaybe<User_Subscription_Aggregate_Order_By>;
};

/** primary key columns input for table: user_info */
export type User_Info_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_info" */
export enum User_Info_Select_Column {
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  CandidateSkipEmailVerify = 'candidate_skip_email_verify',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoNotUseName = 'do_not_use_name',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  FirebaseUid = 'firebase_uid',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeenAt = 'last_seen_at',
  /** column name */
  LastSignIn = 'last_sign_in',
  /** column name */
  Name = 'name',
  /** column name */
  Organization = 'organization',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  SkipEmailVerify = 'skip_email_verify',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "user_info_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_info" */
export enum User_Info_Select_Column_User_Info_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CandidateSkipEmailVerify = 'candidate_skip_email_verify',
  /** column name */
  DoNotUseName = 'do_not_use_name',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  SkipEmailVerify = 'skip_email_verify',
}

/** select "user_info_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_info" */
export enum User_Info_Select_Column_User_Info_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CandidateSkipEmailVerify = 'candidate_skip_email_verify',
  /** column name */
  DoNotUseName = 'do_not_use_name',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  SkipEmailVerify = 'skip_email_verify',
}

/** input type for updating data in table "user_info" */
export type User_Info_Set_Input = {
  auth_provider?: InputMaybe<Scalars['String']>;
  candidate_skip_email_verify?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  do_not_use_name?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['citext']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  firebase_uid?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_seen_at?: InputMaybe<Scalars['timestamptz']>;
  last_sign_in?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['Int']>;
  photo_url?: InputMaybe<Scalars['String']>;
  skip_email_verify?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type User_Info_Stddev_Fields = {
  __typename?: 'user_info_stddev_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_info" */
export type User_Info_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Info_Stddev_Pop_Fields = {
  __typename?: 'user_info_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_info" */
export type User_Info_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Info_Stddev_Samp_Fields = {
  __typename?: 'user_info_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_info" */
export type User_Info_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_info" */
export type User_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Info_Stream_Cursor_Value_Input = {
  auth_provider?: InputMaybe<Scalars['String']>;
  candidate_skip_email_verify?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  do_not_use_name?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['citext']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  firebase_uid?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_seen_at?: InputMaybe<Scalars['timestamptz']>;
  last_sign_in?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['Int']>;
  photo_url?: InputMaybe<Scalars['String']>;
  skip_email_verify?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type User_Info_Sum_Fields = {
  __typename?: 'user_info_sum_fields';
  id: Maybe<Scalars['Int']>;
  organization: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_info" */
export type User_Info_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** update columns of table "user_info" */
export enum User_Info_Update_Column {
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  CandidateSkipEmailVerify = 'candidate_skip_email_verify',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoNotUseName = 'do_not_use_name',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  FirebaseUid = 'firebase_uid',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeenAt = 'last_seen_at',
  /** column name */
  LastSignIn = 'last_sign_in',
  /** column name */
  Name = 'name',
  /** column name */
  Organization = 'organization',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  SkipEmailVerify = 'skip_email_verify',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type User_Info_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Info_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Info_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Info_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Info_Var_Pop_Fields = {
  __typename?: 'user_info_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_info" */
export type User_Info_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Info_Var_Samp_Fields = {
  __typename?: 'user_info_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_info" */
export type User_Info_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Info_Variance_Fields = {
  __typename?: 'user_info_variance_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_info" */
export type User_Info_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_invitation" */
export type User_Invitation = {
  __typename?: 'user_invitation';
  created_at: Scalars['timestamptz'];
  email: Scalars['citext'];
  id: Scalars['Int'];
  invitation_active: Scalars['Boolean'];
  organization: Maybe<Scalars['Int']>;
  /** An object relationship */
  organizationByOrganization: Maybe<Organization>;
  roles: Maybe<Array<Scalars['String']>>;
};

/** aggregated selection of "user_invitation" */
export type User_Invitation_Aggregate = {
  __typename?: 'user_invitation_aggregate';
  aggregate: Maybe<User_Invitation_Aggregate_Fields>;
  nodes: Array<User_Invitation>;
};

/** aggregate fields of "user_invitation" */
export type User_Invitation_Aggregate_Fields = {
  __typename?: 'user_invitation_aggregate_fields';
  avg: Maybe<User_Invitation_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<User_Invitation_Max_Fields>;
  min: Maybe<User_Invitation_Min_Fields>;
  stddev: Maybe<User_Invitation_Stddev_Fields>;
  stddev_pop: Maybe<User_Invitation_Stddev_Pop_Fields>;
  stddev_samp: Maybe<User_Invitation_Stddev_Samp_Fields>;
  sum: Maybe<User_Invitation_Sum_Fields>;
  var_pop: Maybe<User_Invitation_Var_Pop_Fields>;
  var_samp: Maybe<User_Invitation_Var_Samp_Fields>;
  variance: Maybe<User_Invitation_Variance_Fields>;
};

/** aggregate fields of "user_invitation" */
export type User_Invitation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Invitation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Invitation_Avg_Fields = {
  __typename?: 'user_invitation_avg_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_invitation". All fields are combined with a logical 'AND'. */
export type User_Invitation_Bool_Exp = {
  _and?: InputMaybe<Array<User_Invitation_Bool_Exp>>;
  _not?: InputMaybe<User_Invitation_Bool_Exp>;
  _or?: InputMaybe<Array<User_Invitation_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invitation_active?: InputMaybe<Boolean_Comparison_Exp>;
  organization?: InputMaybe<Int_Comparison_Exp>;
  organizationByOrganization?: InputMaybe<Organization_Bool_Exp>;
  roles?: InputMaybe<String_Array_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_invitation" */
export enum User_Invitation_Constraint {
  /** unique or primary key constraint on columns "organization", "email" */
  UserInvitationOrganizationEmailKey = 'user_invitation_organization_email_key',
  /** unique or primary key constraint on columns "id" */
  UserInvitationPkey = 'user_invitation_pkey',
}

/** input type for incrementing numeric columns in table "user_invitation" */
export type User_Invitation_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_invitation" */
export type User_Invitation_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  invitation_active?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<Scalars['Int']>;
  organizationByOrganization?: InputMaybe<Organization_Obj_Rel_Insert_Input>;
  roles?: InputMaybe<Array<Scalars['String']>>;
};

/** aggregate max on columns */
export type User_Invitation_Max_Fields = {
  __typename?: 'user_invitation_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  id: Maybe<Scalars['Int']>;
  organization: Maybe<Scalars['Int']>;
  roles: Maybe<Array<Scalars['String']>>;
};

/** aggregate min on columns */
export type User_Invitation_Min_Fields = {
  __typename?: 'user_invitation_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['citext']>;
  id: Maybe<Scalars['Int']>;
  organization: Maybe<Scalars['Int']>;
  roles: Maybe<Array<Scalars['String']>>;
};

/** response of any mutation on the table "user_invitation" */
export type User_Invitation_Mutation_Response = {
  __typename?: 'user_invitation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Invitation>;
};

/** input type for inserting object relation for remote table "user_invitation" */
export type User_Invitation_Obj_Rel_Insert_Input = {
  data: User_Invitation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Invitation_On_Conflict>;
};

/** on_conflict condition type for table "user_invitation" */
export type User_Invitation_On_Conflict = {
  constraint: User_Invitation_Constraint;
  update_columns?: Array<User_Invitation_Update_Column>;
  where?: InputMaybe<User_Invitation_Bool_Exp>;
};

/** Ordering options when selecting data from "user_invitation". */
export type User_Invitation_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitation_active?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  organizationByOrganization?: InputMaybe<Organization_Order_By>;
  roles?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_invitation */
export type User_Invitation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_invitation" */
export enum User_Invitation_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InvitationActive = 'invitation_active',
  /** column name */
  Organization = 'organization',
  /** column name */
  Roles = 'roles',
}

/** input type for updating data in table "user_invitation" */
export type User_Invitation_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  invitation_active?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
};

/** aggregate stddev on columns */
export type User_Invitation_Stddev_Fields = {
  __typename?: 'user_invitation_stddev_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Invitation_Stddev_Pop_Fields = {
  __typename?: 'user_invitation_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Invitation_Stddev_Samp_Fields = {
  __typename?: 'user_invitation_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_invitation" */
export type User_Invitation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Invitation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Invitation_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  invitation_active?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
};

/** aggregate sum on columns */
export type User_Invitation_Sum_Fields = {
  __typename?: 'user_invitation_sum_fields';
  id: Maybe<Scalars['Int']>;
  organization: Maybe<Scalars['Int']>;
};

/** update columns of table "user_invitation" */
export enum User_Invitation_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InvitationActive = 'invitation_active',
  /** column name */
  Organization = 'organization',
  /** column name */
  Roles = 'roles',
}

export type User_Invitation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Invitation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Invitation_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Invitation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Invitation_Var_Pop_Fields = {
  __typename?: 'user_invitation_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Invitation_Var_Samp_Fields = {
  __typename?: 'user_invitation_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Invitation_Variance_Fields = {
  __typename?: 'user_invitation_variance_fields';
  id: Maybe<Scalars['Float']>;
  organization: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_linkedin_contact" */
export type User_Linkedin_Contact = {
  __typename?: 'user_linkedin_contact';
  id: Scalars['Int'];
  /** An object relationship */
  linkedinContactByLinkedinContact: Linkedin_Contact;
  linkedin_contact: Scalars['Int'];
  updated_at: Maybe<Scalars['timestamptz']>;
  user: Scalars['Int'];
  /** An object relationship */
  user_info: User_Info;
};

/** aggregated selection of "user_linkedin_contact" */
export type User_Linkedin_Contact_Aggregate = {
  __typename?: 'user_linkedin_contact_aggregate';
  aggregate: Maybe<User_Linkedin_Contact_Aggregate_Fields>;
  nodes: Array<User_Linkedin_Contact>;
};

export type User_Linkedin_Contact_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Linkedin_Contact_Aggregate_Bool_Exp_Count>;
};

export type User_Linkedin_Contact_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Linkedin_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Linkedin_Contact_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_linkedin_contact" */
export type User_Linkedin_Contact_Aggregate_Fields = {
  __typename?: 'user_linkedin_contact_aggregate_fields';
  avg: Maybe<User_Linkedin_Contact_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<User_Linkedin_Contact_Max_Fields>;
  min: Maybe<User_Linkedin_Contact_Min_Fields>;
  stddev: Maybe<User_Linkedin_Contact_Stddev_Fields>;
  stddev_pop: Maybe<User_Linkedin_Contact_Stddev_Pop_Fields>;
  stddev_samp: Maybe<User_Linkedin_Contact_Stddev_Samp_Fields>;
  sum: Maybe<User_Linkedin_Contact_Sum_Fields>;
  var_pop: Maybe<User_Linkedin_Contact_Var_Pop_Fields>;
  var_samp: Maybe<User_Linkedin_Contact_Var_Samp_Fields>;
  variance: Maybe<User_Linkedin_Contact_Variance_Fields>;
};

/** aggregate fields of "user_linkedin_contact" */
export type User_Linkedin_Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Linkedin_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_linkedin_contact" */
export type User_Linkedin_Contact_Aggregate_Order_By = {
  avg?: InputMaybe<User_Linkedin_Contact_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Linkedin_Contact_Max_Order_By>;
  min?: InputMaybe<User_Linkedin_Contact_Min_Order_By>;
  stddev?: InputMaybe<User_Linkedin_Contact_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Linkedin_Contact_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Linkedin_Contact_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Linkedin_Contact_Sum_Order_By>;
  var_pop?: InputMaybe<User_Linkedin_Contact_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Linkedin_Contact_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Linkedin_Contact_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_linkedin_contact" */
export type User_Linkedin_Contact_Arr_Rel_Insert_Input = {
  data: Array<User_Linkedin_Contact_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Linkedin_Contact_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Linkedin_Contact_Avg_Fields = {
  __typename?: 'user_linkedin_contact_avg_fields';
  id: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_linkedin_contact" */
export type User_Linkedin_Contact_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_linkedin_contact". All fields are combined with a logical 'AND'. */
export type User_Linkedin_Contact_Bool_Exp = {
  _and?: InputMaybe<Array<User_Linkedin_Contact_Bool_Exp>>;
  _not?: InputMaybe<User_Linkedin_Contact_Bool_Exp>;
  _or?: InputMaybe<Array<User_Linkedin_Contact_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  linkedinContactByLinkedinContact?: InputMaybe<Linkedin_Contact_Bool_Exp>;
  linkedin_contact?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Int_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "user_linkedin_contact" */
export enum User_Linkedin_Contact_Constraint {
  /** unique or primary key constraint on columns "linkedin_contact", "user" */
  UserLinkedinContactLinkedinContactUserKey = 'user_linkedin_contact_linkedin_contact_user_key',
  /** unique or primary key constraint on columns "id" */
  UserLinkedinContactPkey = 'user_linkedin_contact_pkey',
}

/** input type for incrementing numeric columns in table "user_linkedin_contact" */
export type User_Linkedin_Contact_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  linkedin_contact?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_linkedin_contact" */
export type User_Linkedin_Contact_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  linkedinContactByLinkedinContact?: InputMaybe<Linkedin_Contact_Obj_Rel_Insert_Input>;
  linkedin_contact?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['Int']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Linkedin_Contact_Max_Fields = {
  __typename?: 'user_linkedin_contact_max_fields';
  id: Maybe<Scalars['Int']>;
  linkedin_contact: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "user_linkedin_contact" */
export type User_Linkedin_Contact_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Linkedin_Contact_Min_Fields = {
  __typename?: 'user_linkedin_contact_min_fields';
  id: Maybe<Scalars['Int']>;
  linkedin_contact: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "user_linkedin_contact" */
export type User_Linkedin_Contact_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_linkedin_contact" */
export type User_Linkedin_Contact_Mutation_Response = {
  __typename?: 'user_linkedin_contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Linkedin_Contact>;
};

/** on_conflict condition type for table "user_linkedin_contact" */
export type User_Linkedin_Contact_On_Conflict = {
  constraint: User_Linkedin_Contact_Constraint;
  update_columns?: Array<User_Linkedin_Contact_Update_Column>;
  where?: InputMaybe<User_Linkedin_Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "user_linkedin_contact". */
export type User_Linkedin_Contact_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedinContactByLinkedinContact?: InputMaybe<Linkedin_Contact_Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: user_linkedin_contact */
export type User_Linkedin_Contact_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_linkedin_contact" */
export enum User_Linkedin_Contact_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LinkedinContact = 'linkedin_contact',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  User = 'user',
}

/** input type for updating data in table "user_linkedin_contact" */
export type User_Linkedin_Contact_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  linkedin_contact?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_Linkedin_Contact_Stddev_Fields = {
  __typename?: 'user_linkedin_contact_stddev_fields';
  id: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_linkedin_contact" */
export type User_Linkedin_Contact_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Linkedin_Contact_Stddev_Pop_Fields = {
  __typename?: 'user_linkedin_contact_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_linkedin_contact" */
export type User_Linkedin_Contact_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Linkedin_Contact_Stddev_Samp_Fields = {
  __typename?: 'user_linkedin_contact_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_linkedin_contact" */
export type User_Linkedin_Contact_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_linkedin_contact" */
export type User_Linkedin_Contact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Linkedin_Contact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Linkedin_Contact_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  linkedin_contact?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type User_Linkedin_Contact_Sum_Fields = {
  __typename?: 'user_linkedin_contact_sum_fields';
  id: Maybe<Scalars['Int']>;
  linkedin_contact: Maybe<Scalars['Int']>;
  user: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_linkedin_contact" */
export type User_Linkedin_Contact_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** update columns of table "user_linkedin_contact" */
export enum User_Linkedin_Contact_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LinkedinContact = 'linkedin_contact',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  User = 'user',
}

export type User_Linkedin_Contact_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Linkedin_Contact_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Linkedin_Contact_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Linkedin_Contact_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Linkedin_Contact_Var_Pop_Fields = {
  __typename?: 'user_linkedin_contact_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_linkedin_contact" */
export type User_Linkedin_Contact_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Linkedin_Contact_Var_Samp_Fields = {
  __typename?: 'user_linkedin_contact_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_linkedin_contact" */
export type User_Linkedin_Contact_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Linkedin_Contact_Variance_Fields = {
  __typename?: 'user_linkedin_contact_variance_fields';
  id: Maybe<Scalars['Float']>;
  linkedin_contact: Maybe<Scalars['Float']>;
  user: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_linkedin_contact" */
export type User_Linkedin_Contact_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  linkedin_contact?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_password_blacklist" */
export type User_Password_Blacklist = {
  __typename?: 'user_password_blacklist';
  password: Scalars['String'];
};

/** aggregated selection of "user_password_blacklist" */
export type User_Password_Blacklist_Aggregate = {
  __typename?: 'user_password_blacklist_aggregate';
  aggregate: Maybe<User_Password_Blacklist_Aggregate_Fields>;
  nodes: Array<User_Password_Blacklist>;
};

/** aggregate fields of "user_password_blacklist" */
export type User_Password_Blacklist_Aggregate_Fields = {
  __typename?: 'user_password_blacklist_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<User_Password_Blacklist_Max_Fields>;
  min: Maybe<User_Password_Blacklist_Min_Fields>;
};

/** aggregate fields of "user_password_blacklist" */
export type User_Password_Blacklist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Password_Blacklist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_password_blacklist". All fields are combined with a logical 'AND'. */
export type User_Password_Blacklist_Bool_Exp = {
  _and?: InputMaybe<Array<User_Password_Blacklist_Bool_Exp>>;
  _not?: InputMaybe<User_Password_Blacklist_Bool_Exp>;
  _or?: InputMaybe<Array<User_Password_Blacklist_Bool_Exp>>;
  password?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_password_blacklist" */
export enum User_Password_Blacklist_Constraint {
  /** unique or primary key constraint on columns "password" */
  UserPasswordBlacklistPkey = 'user_password_blacklist_pkey',
}

/** input type for inserting data into table "user_password_blacklist" */
export type User_Password_Blacklist_Insert_Input = {
  password?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Password_Blacklist_Max_Fields = {
  __typename?: 'user_password_blacklist_max_fields';
  password: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Password_Blacklist_Min_Fields = {
  __typename?: 'user_password_blacklist_min_fields';
  password: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_password_blacklist" */
export type User_Password_Blacklist_Mutation_Response = {
  __typename?: 'user_password_blacklist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Password_Blacklist>;
};

/** on_conflict condition type for table "user_password_blacklist" */
export type User_Password_Blacklist_On_Conflict = {
  constraint: User_Password_Blacklist_Constraint;
  update_columns?: Array<User_Password_Blacklist_Update_Column>;
  where?: InputMaybe<User_Password_Blacklist_Bool_Exp>;
};

/** Ordering options when selecting data from "user_password_blacklist". */
export type User_Password_Blacklist_Order_By = {
  password?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_password_blacklist */
export type User_Password_Blacklist_Pk_Columns_Input = {
  password: Scalars['String'];
};

/** select columns of table "user_password_blacklist" */
export enum User_Password_Blacklist_Select_Column {
  /** column name */
  Password = 'password',
}

/** input type for updating data in table "user_password_blacklist" */
export type User_Password_Blacklist_Set_Input = {
  password?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_password_blacklist" */
export type User_Password_Blacklist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Password_Blacklist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Password_Blacklist_Stream_Cursor_Value_Input = {
  password?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_password_blacklist" */
export enum User_Password_Blacklist_Update_Column {
  /** column name */
  Password = 'password',
}

export type User_Password_Blacklist_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Password_Blacklist_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Password_Blacklist_Bool_Exp;
};

/** columns and relationships of "user_role" */
export type User_Role = {
  __typename?: 'user_role';
  id: Scalars['Int'];
  /** An object relationship */
  role: Role;
  role_id: Scalars['Int'];
  user_id: Scalars['Int'];
  /** An object relationship */
  user_info: User_Info;
};

/** aggregated selection of "user_role" */
export type User_Role_Aggregate = {
  __typename?: 'user_role_aggregate';
  aggregate: Maybe<User_Role_Aggregate_Fields>;
  nodes: Array<User_Role>;
};

export type User_Role_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Role_Aggregate_Bool_Exp_Count>;
};

export type User_Role_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Role_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_Fields = {
  __typename?: 'user_role_aggregate_fields';
  avg: Maybe<User_Role_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<User_Role_Max_Fields>;
  min: Maybe<User_Role_Min_Fields>;
  stddev: Maybe<User_Role_Stddev_Fields>;
  stddev_pop: Maybe<User_Role_Stddev_Pop_Fields>;
  stddev_samp: Maybe<User_Role_Stddev_Samp_Fields>;
  sum: Maybe<User_Role_Sum_Fields>;
  var_pop: Maybe<User_Role_Var_Pop_Fields>;
  var_samp: Maybe<User_Role_Var_Samp_Fields>;
  variance: Maybe<User_Role_Variance_Fields>;
};

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_role" */
export type User_Role_Aggregate_Order_By = {
  avg?: InputMaybe<User_Role_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Role_Max_Order_By>;
  min?: InputMaybe<User_Role_Min_Order_By>;
  stddev?: InputMaybe<User_Role_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Role_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Role_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Role_Sum_Order_By>;
  var_pop?: InputMaybe<User_Role_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Role_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Role_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_role" */
export type User_Role_Arr_Rel_Insert_Input = {
  data: Array<User_Role_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Role_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Role_Avg_Fields = {
  __typename?: 'user_role_avg_fields';
  id: Maybe<Scalars['Float']>;
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_role" */
export type User_Role_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'. */
export type User_Role_Bool_Exp = {
  _and?: InputMaybe<Array<User_Role_Bool_Exp>>;
  _not?: InputMaybe<User_Role_Bool_Exp>;
  _or?: InputMaybe<Array<User_Role_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "user_role" */
export enum User_Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRolePkey = 'user_role_pkey',
  /** unique or primary key constraint on columns "role_id", "user_id" */
  UserRoleUserIdRoleIdKey = 'user_role_user_id_role_id_key',
}

/** input type for incrementing numeric columns in table "user_role" */
export type User_Role_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_role" */
export type User_Role_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Role_Max_Fields = {
  __typename?: 'user_role_max_fields';
  id: Maybe<Scalars['Int']>;
  role_id: Maybe<Scalars['Int']>;
  user_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "user_role" */
export type User_Role_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Role_Min_Fields = {
  __typename?: 'user_role_min_fields';
  id: Maybe<Scalars['Int']>;
  role_id: Maybe<Scalars['Int']>;
  user_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "user_role" */
export type User_Role_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_role" */
export type User_Role_Mutation_Response = {
  __typename?: 'user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Role>;
};

/** on_conflict condition type for table "user_role" */
export type User_Role_On_Conflict = {
  constraint: User_Role_Constraint;
  update_columns?: Array<User_Role_Update_Column>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "user_role". */
export type User_Role_Order_By = {
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: user_role */
export type User_Role_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_role" */
export enum User_Role_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_role" */
export type User_Role_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_Role_Stddev_Fields = {
  __typename?: 'user_role_stddev_fields';
  id: Maybe<Scalars['Float']>;
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_role" */
export type User_Role_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Role_Stddev_Pop_Fields = {
  __typename?: 'user_role_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_role" */
export type User_Role_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Role_Stddev_Samp_Fields = {
  __typename?: 'user_role_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_role" */
export type User_Role_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_role" */
export type User_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Role_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  role_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type User_Role_Sum_Fields = {
  __typename?: 'user_role_sum_fields';
  id: Maybe<Scalars['Int']>;
  role_id: Maybe<Scalars['Int']>;
  user_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_role" */
export type User_Role_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_role" */
export enum User_Role_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id',
}

export type User_Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Role_Var_Pop_Fields = {
  __typename?: 'user_role_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_role" */
export type User_Role_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Role_Var_Samp_Fields = {
  __typename?: 'user_role_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_role" */
export type User_Role_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Role_Variance_Fields = {
  __typename?: 'user_role_variance_fields';
  id: Maybe<Scalars['Float']>;
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_role" */
export type User_Role_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_subscription" */
export type User_Subscription = {
  __typename?: 'user_subscription';
  created_at: Scalars['timestamptz'];
  subscription: User_Subscription_Choices_Enum;
  /** An object relationship */
  user_info: User_Info;
  user_info_id: Scalars['Int'];
};

/** aggregated selection of "user_subscription" */
export type User_Subscription_Aggregate = {
  __typename?: 'user_subscription_aggregate';
  aggregate: Maybe<User_Subscription_Aggregate_Fields>;
  nodes: Array<User_Subscription>;
};

export type User_Subscription_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Subscription_Aggregate_Bool_Exp_Count>;
};

export type User_Subscription_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Subscription_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_subscription" */
export type User_Subscription_Aggregate_Fields = {
  __typename?: 'user_subscription_aggregate_fields';
  avg: Maybe<User_Subscription_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<User_Subscription_Max_Fields>;
  min: Maybe<User_Subscription_Min_Fields>;
  stddev: Maybe<User_Subscription_Stddev_Fields>;
  stddev_pop: Maybe<User_Subscription_Stddev_Pop_Fields>;
  stddev_samp: Maybe<User_Subscription_Stddev_Samp_Fields>;
  sum: Maybe<User_Subscription_Sum_Fields>;
  var_pop: Maybe<User_Subscription_Var_Pop_Fields>;
  var_samp: Maybe<User_Subscription_Var_Samp_Fields>;
  variance: Maybe<User_Subscription_Variance_Fields>;
};

/** aggregate fields of "user_subscription" */
export type User_Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_subscription" */
export type User_Subscription_Aggregate_Order_By = {
  avg?: InputMaybe<User_Subscription_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Subscription_Max_Order_By>;
  min?: InputMaybe<User_Subscription_Min_Order_By>;
  stddev?: InputMaybe<User_Subscription_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Subscription_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Subscription_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Subscription_Sum_Order_By>;
  var_pop?: InputMaybe<User_Subscription_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Subscription_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Subscription_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_subscription" */
export type User_Subscription_Arr_Rel_Insert_Input = {
  data: Array<User_Subscription_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Subscription_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Subscription_Avg_Fields = {
  __typename?: 'user_subscription_avg_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_subscription" */
export type User_Subscription_Avg_Order_By = {
  user_info_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_subscription". All fields are combined with a logical 'AND'. */
export type User_Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<User_Subscription_Bool_Exp>>;
  _not?: InputMaybe<User_Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<User_Subscription_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  subscription?: InputMaybe<User_Subscription_Choices_Enum_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
  user_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "user_subscription_choices" */
export type User_Subscription_Choices = {
  __typename?: 'user_subscription_choices';
  value: Scalars['String'];
};

/** aggregated selection of "user_subscription_choices" */
export type User_Subscription_Choices_Aggregate = {
  __typename?: 'user_subscription_choices_aggregate';
  aggregate: Maybe<User_Subscription_Choices_Aggregate_Fields>;
  nodes: Array<User_Subscription_Choices>;
};

/** aggregate fields of "user_subscription_choices" */
export type User_Subscription_Choices_Aggregate_Fields = {
  __typename?: 'user_subscription_choices_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<User_Subscription_Choices_Max_Fields>;
  min: Maybe<User_Subscription_Choices_Min_Fields>;
};

/** aggregate fields of "user_subscription_choices" */
export type User_Subscription_Choices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Subscription_Choices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_subscription_choices". All fields are combined with a logical 'AND'. */
export type User_Subscription_Choices_Bool_Exp = {
  _and?: InputMaybe<Array<User_Subscription_Choices_Bool_Exp>>;
  _not?: InputMaybe<User_Subscription_Choices_Bool_Exp>;
  _or?: InputMaybe<Array<User_Subscription_Choices_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_subscription_choices" */
export enum User_Subscription_Choices_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserSubscriptionChoicesPkey = 'user_subscription_choices_pkey',
}

export enum User_Subscription_Choices_Enum {
  NewJobAlert = 'NEW_JOB_ALERT',
}

/** Boolean expression to compare columns of type "user_subscription_choices_enum". All fields are combined with logical 'AND'. */
export type User_Subscription_Choices_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Subscription_Choices_Enum>;
  _in?: InputMaybe<Array<User_Subscription_Choices_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<User_Subscription_Choices_Enum>;
  _nin?: InputMaybe<Array<User_Subscription_Choices_Enum>>;
};

/** input type for inserting data into table "user_subscription_choices" */
export type User_Subscription_Choices_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Subscription_Choices_Max_Fields = {
  __typename?: 'user_subscription_choices_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Subscription_Choices_Min_Fields = {
  __typename?: 'user_subscription_choices_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_subscription_choices" */
export type User_Subscription_Choices_Mutation_Response = {
  __typename?: 'user_subscription_choices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Subscription_Choices>;
};

/** on_conflict condition type for table "user_subscription_choices" */
export type User_Subscription_Choices_On_Conflict = {
  constraint: User_Subscription_Choices_Constraint;
  update_columns?: Array<User_Subscription_Choices_Update_Column>;
  where?: InputMaybe<User_Subscription_Choices_Bool_Exp>;
};

/** Ordering options when selecting data from "user_subscription_choices". */
export type User_Subscription_Choices_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_subscription_choices */
export type User_Subscription_Choices_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "user_subscription_choices" */
export enum User_Subscription_Choices_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_subscription_choices" */
export type User_Subscription_Choices_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_subscription_choices" */
export type User_Subscription_Choices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Subscription_Choices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Subscription_Choices_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_subscription_choices" */
export enum User_Subscription_Choices_Update_Column {
  /** column name */
  Value = 'value',
}

export type User_Subscription_Choices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Subscription_Choices_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Subscription_Choices_Bool_Exp;
};

/** unique or primary key constraints on table "user_subscription" */
export enum User_Subscription_Constraint {
  /** unique or primary key constraint on columns "subscription", "user_info_id" */
  UserSubscriptionPkey = 'user_subscription_pkey',
}

/** input type for incrementing numeric columns in table "user_subscription" */
export type User_Subscription_Inc_Input = {
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_subscription" */
export type User_Subscription_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  subscription?: InputMaybe<User_Subscription_Choices_Enum>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type User_Subscription_Max_Fields = {
  __typename?: 'user_subscription_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "user_subscription" */
export type User_Subscription_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Subscription_Min_Fields = {
  __typename?: 'user_subscription_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "user_subscription" */
export type User_Subscription_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_subscription" */
export type User_Subscription_Mutation_Response = {
  __typename?: 'user_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Subscription>;
};

/** on_conflict condition type for table "user_subscription" */
export type User_Subscription_On_Conflict = {
  constraint: User_Subscription_Constraint;
  update_columns?: Array<User_Subscription_Update_Column>;
  where?: InputMaybe<User_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "user_subscription". */
export type User_Subscription_Order_By = {
  created_at?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_subscription */
export type User_Subscription_Pk_Columns_Input = {
  subscription: User_Subscription_Choices_Enum;
  user_info_id: Scalars['Int'];
};

/** select columns of table "user_subscription" */
export enum User_Subscription_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  UserInfoId = 'user_info_id',
}

/** input type for updating data in table "user_subscription" */
export type User_Subscription_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  subscription?: InputMaybe<User_Subscription_Choices_Enum>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_Subscription_Stddev_Fields = {
  __typename?: 'user_subscription_stddev_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_subscription" */
export type User_Subscription_Stddev_Order_By = {
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Subscription_Stddev_Pop_Fields = {
  __typename?: 'user_subscription_stddev_pop_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_subscription" */
export type User_Subscription_Stddev_Pop_Order_By = {
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Subscription_Stddev_Samp_Fields = {
  __typename?: 'user_subscription_stddev_samp_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_subscription" */
export type User_Subscription_Stddev_Samp_Order_By = {
  user_info_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_subscription" */
export type User_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Subscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Subscription_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  subscription?: InputMaybe<User_Subscription_Choices_Enum>;
  user_info_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type User_Subscription_Sum_Fields = {
  __typename?: 'user_subscription_sum_fields';
  user_info_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_subscription" */
export type User_Subscription_Sum_Order_By = {
  user_info_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_subscription" */
export enum User_Subscription_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  UserInfoId = 'user_info_id',
}

export type User_Subscription_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Subscription_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Subscription_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Subscription_Var_Pop_Fields = {
  __typename?: 'user_subscription_var_pop_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_subscription" */
export type User_Subscription_Var_Pop_Order_By = {
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Subscription_Var_Samp_Fields = {
  __typename?: 'user_subscription_var_samp_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_subscription" */
export type User_Subscription_Var_Samp_Order_By = {
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Subscription_Variance_Fields = {
  __typename?: 'user_subscription_variance_fields';
  user_info_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_subscription" */
export type User_Subscription_Variance_Order_By = {
  user_info_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_temp_blob" */
export type User_Temp_Blob = {
  __typename?: 'user_temp_blob';
  blob: Maybe<Scalars['jsonb']>;
  firebase_uid: Scalars['String'];
  /** An object relationship */
  user_info: Maybe<User_Info>;
};

/** columns and relationships of "user_temp_blob" */
export type User_Temp_BlobBlobArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_temp_blob" */
export type User_Temp_Blob_Aggregate = {
  __typename?: 'user_temp_blob_aggregate';
  aggregate: Maybe<User_Temp_Blob_Aggregate_Fields>;
  nodes: Array<User_Temp_Blob>;
};

/** aggregate fields of "user_temp_blob" */
export type User_Temp_Blob_Aggregate_Fields = {
  __typename?: 'user_temp_blob_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<User_Temp_Blob_Max_Fields>;
  min: Maybe<User_Temp_Blob_Min_Fields>;
};

/** aggregate fields of "user_temp_blob" */
export type User_Temp_Blob_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Temp_Blob_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Temp_Blob_Append_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "user_temp_blob". All fields are combined with a logical 'AND'. */
export type User_Temp_Blob_Bool_Exp = {
  _and?: InputMaybe<Array<User_Temp_Blob_Bool_Exp>>;
  _not?: InputMaybe<User_Temp_Blob_Bool_Exp>;
  _or?: InputMaybe<Array<User_Temp_Blob_Bool_Exp>>;
  blob?: InputMaybe<Jsonb_Comparison_Exp>;
  firebase_uid?: InputMaybe<String_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
};

/** unique or primary key constraints on table "user_temp_blob" */
export enum User_Temp_Blob_Constraint {
  /** unique or primary key constraint on columns "firebase_uid" */
  UserTempBlobPkey = 'user_temp_blob_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Temp_Blob_Delete_At_Path_Input = {
  blob?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Temp_Blob_Delete_Elem_Input = {
  blob?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Temp_Blob_Delete_Key_Input = {
  blob?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_temp_blob" */
export type User_Temp_Blob_Insert_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  firebase_uid?: InputMaybe<Scalars['String']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Temp_Blob_Max_Fields = {
  __typename?: 'user_temp_blob_max_fields';
  firebase_uid: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Temp_Blob_Min_Fields = {
  __typename?: 'user_temp_blob_min_fields';
  firebase_uid: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_temp_blob" */
export type User_Temp_Blob_Mutation_Response = {
  __typename?: 'user_temp_blob_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Temp_Blob>;
};

/** input type for inserting object relation for remote table "user_temp_blob" */
export type User_Temp_Blob_Obj_Rel_Insert_Input = {
  data: User_Temp_Blob_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Temp_Blob_On_Conflict>;
};

/** on_conflict condition type for table "user_temp_blob" */
export type User_Temp_Blob_On_Conflict = {
  constraint: User_Temp_Blob_Constraint;
  update_columns?: Array<User_Temp_Blob_Update_Column>;
  where?: InputMaybe<User_Temp_Blob_Bool_Exp>;
};

/** Ordering options when selecting data from "user_temp_blob". */
export type User_Temp_Blob_Order_By = {
  blob?: InputMaybe<Order_By>;
  firebase_uid?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
};

/** primary key columns input for table: user_temp_blob */
export type User_Temp_Blob_Pk_Columns_Input = {
  firebase_uid: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Temp_Blob_Prepend_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_temp_blob" */
export enum User_Temp_Blob_Select_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  FirebaseUid = 'firebase_uid',
}

/** input type for updating data in table "user_temp_blob" */
export type User_Temp_Blob_Set_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  firebase_uid?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_temp_blob" */
export type User_Temp_Blob_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Temp_Blob_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Temp_Blob_Stream_Cursor_Value_Input = {
  blob?: InputMaybe<Scalars['jsonb']>;
  firebase_uid?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_temp_blob" */
export enum User_Temp_Blob_Update_Column {
  /** column name */
  Blob = 'blob',
  /** column name */
  FirebaseUid = 'firebase_uid',
}

export type User_Temp_Blob_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Temp_Blob_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Temp_Blob_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Temp_Blob_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Temp_Blob_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Temp_Blob_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Temp_Blob_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Temp_Blob_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** validateRegex(<value>, <field_name>, <validator_name>) is case-insensitive */
export type Validator = {
  __typename?: 'validator';
  name: Scalars['String'];
  regex: Scalars['String'];
};

/** aggregated selection of "validator" */
export type Validator_Aggregate = {
  __typename?: 'validator_aggregate';
  aggregate: Maybe<Validator_Aggregate_Fields>;
  nodes: Array<Validator>;
};

/** aggregate fields of "validator" */
export type Validator_Aggregate_Fields = {
  __typename?: 'validator_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Validator_Max_Fields>;
  min: Maybe<Validator_Min_Fields>;
};

/** aggregate fields of "validator" */
export type Validator_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Validator_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "validator". All fields are combined with a logical 'AND'. */
export type Validator_Bool_Exp = {
  _and?: InputMaybe<Array<Validator_Bool_Exp>>;
  _not?: InputMaybe<Validator_Bool_Exp>;
  _or?: InputMaybe<Array<Validator_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  regex?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "validator" */
export enum Validator_Constraint {
  /** unique or primary key constraint on columns "name" */
  ValidatorPkey = 'validator_pkey',
}

/** input type for inserting data into table "validator" */
export type Validator_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  regex?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Validator_Max_Fields = {
  __typename?: 'validator_max_fields';
  name: Maybe<Scalars['String']>;
  regex: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Validator_Min_Fields = {
  __typename?: 'validator_min_fields';
  name: Maybe<Scalars['String']>;
  regex: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "validator" */
export type Validator_Mutation_Response = {
  __typename?: 'validator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Validator>;
};

/** on_conflict condition type for table "validator" */
export type Validator_On_Conflict = {
  constraint: Validator_Constraint;
  update_columns?: Array<Validator_Update_Column>;
  where?: InputMaybe<Validator_Bool_Exp>;
};

/** Ordering options when selecting data from "validator". */
export type Validator_Order_By = {
  name?: InputMaybe<Order_By>;
  regex?: InputMaybe<Order_By>;
};

/** primary key columns input for table: validator */
export type Validator_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "validator" */
export enum Validator_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Regex = 'regex',
}

/** input type for updating data in table "validator" */
export type Validator_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  regex?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "validator" */
export type Validator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Validator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Validator_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  regex?: InputMaybe<Scalars['String']>;
};

/** update columns of table "validator" */
export enum Validator_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Regex = 'regex',
}

export type Validator_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Validator_Set_Input>;
  /** filter the rows which have to be updated */
  where: Validator_Bool_Exp;
};
