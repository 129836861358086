import { useEffect } from 'react';
import * as events from 'global/events';
import { useRedirection } from 'global/utils';

import type {
  SelectCandidateQuery,
  SelectCurrencyExchangeRatesQuery,
  SelectCurrencyExchangeRatesQueryVariables,
  SelectJobSuggestionsQuery,
} from 'candidate/shared/modules';
import {
  SelectCurrencyExchangeRates,
  serializerForJobSuggestions,
  useJobSuggestionsController,
} from 'candidate/shared/modules';
import { supportedCountries } from 'candidate/shared';
import { useQuery } from '@apollo/client';
import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import type { serializeProfileCompletion } from 'candidate/shared/modules/profile/Profile.serializer';
import { JobSuggestions, JobSuggestionsLoading } from './JobSuggestions';

export function JobSuggestionsController({
  candidateID,
  userID,
  candidateInfo,
  profileCompletion,
}: {
  candidateID: number;
  userID: number;
  candidateInfo: {
    country:
      | Exclude<SelectCandidateQuery['candidate'][number]['country_choice'], null>['name']
      | null;
    talentPartnerEmail: string | null | undefined;
    candidateSkills: SelectCandidateQuery['candidate'][number]['candidate_skills'][number]['skill']['name'];
    yearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null | undefined;
    furthestCandidateCurationWorkflow:
      | NonNullable<
          SelectCandidateQuery['candidate'][number]['furthest_candidate_curation_workflow']
        >['status']
      | null;
  } | null;
  profileCompletion: ReturnType<typeof serializeProfileCompletion>;
}) {
  const redirectTo = useRedirection();
  const { data, isLoading_selectJobSuggestions, handleOnMatchAlertsChange } =
    useJobSuggestionsController({
      candidateID,
      userID,
    });

  const { loading: isLoading_selectCurrencyExchangeRatesQuery, data: currencyExchangeRates } =
    useQuery<SelectCurrencyExchangeRatesQuery, SelectCurrencyExchangeRatesQueryVariables>(
      SelectCurrencyExchangeRates,
      {
        fetchPolicy: 'cache-and-network',
      },
    );

  const handleGoToApplyClick = (id: number) => {
    events.track(events.name.ctaClicked, {
      job_id: id,
      name: 'quick-apply',
    });
    redirectTo(`/job/matches/detail/${id}`);
  };

  const handleOnViewAllClick = () => {
    redirectTo(`/job/matches`);
  };

  const { jobSuggestions, isSubscribedToAlerts } = serializerForJobSuggestions({
    ...(data as SelectJobSuggestionsQuery),
    candidateInfo,
    conversionTable: currencyExchangeRates?.exchange_rates,
  });

  useEffect(() => {
    if (!isLoading_selectJobSuggestions && !isLoading_selectCurrencyExchangeRatesQuery && data) {
      events.track(events.name.homePage.jobMatches.viewed, {
        num_matches: data?.candidate_suggested_job?.length ?? 0,
        num_top_applicants: jobSuggestions.filter(
          (job) => job.jobDetail?.topApplicantInfo.isTopApplicant,
        ).length,
      });
    }
  }, [
    isLoading_selectJobSuggestions,
    isLoading_selectCurrencyExchangeRatesQuery,
    data,
    jobSuggestions,
  ]);
  if (isLoading_selectJobSuggestions || isLoading_selectCurrencyExchangeRatesQuery) {
    return <JobSuggestionsLoading />;
  }

  return (
    <JobSuggestions
      // We show a maximum of 2 job suggestions
      jobSuggestions={jobSuggestions.slice(0, 2)}
      onGoToJobApplicationClick={handleGoToApplyClick}
      showIsSubscribedOn={isSubscribedToAlerts}
      onViewAllClick={handleOnViewAllClick}
      onMatchAlertsChange={() => handleOnMatchAlertsChange(isSubscribedToAlerts)}
      showCountryNotSupported={
        !supportedCountries.includes(candidateInfo?.country ?? '') && !jobSuggestions.length
      }
      profileCompletion={profileCompletion}
    />
  );
}
