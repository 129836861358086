import {
  Box,
  Flex,
  Text,
  Button,
  EditIcon,
  Tag,
  WarningIcon,
  UnorderedList,
  ListItem,
  ListIcon,
  ArrowRightIcon,
  Link,
  Grid,
} from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';
import { ProfileSectionButton } from '../../components';
import type { ProfileProps } from '../../Profile.types';

export function EducationsSection({
  educations,
  onAddClick,
  onEditClick,
}: {
  educations: ProfileProps['candidate']['educations'];
  onAddClick: () => void;
  onEditClick: (
    values: ProfileProps['candidate']['educations'][number]['formValues'],
    editID: number,
  ) => void;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <>
      <Grid templateColumns="1fr" gap={6} w="full">
        {educations.some(({ hasMissingData }) => hasMissingData) && (
          <Flex bg="ui.lighter.warning" py={4} p={[3, 3, 8]} alignItems="center">
            <WarningIcon fontSize="2xl" color="ui.warning" />
            <Flex flexDir="column" ml={6}>
              <Text>
                There are missing information in your{' '}
                <Text as="span" variant="label">
                  Education:
                </Text>
              </Text>
              <UnorderedList spacing={1} mt={1} pl={3}>
                {educations
                  .filter(({ hasMissingData }) => hasMissingData)
                  .map(({ missingDataCTA, id, formValues }) => (
                    <Link
                      onClick={() => {
                        onEditClick(formValues, id);
                      }}
                      key={`missing-data-item-${id}`}
                    >
                      <ListItem>
                        {missingDataCTA}
                        <ListIcon as={ArrowRightIcon} ml={2} />
                      </ListItem>
                    </Link>
                  ))
                  .flat()}
              </UnorderedList>
            </Flex>
          </Flex>
        )}
        {educations.map(({ id, title, subTitle, datesTitle, isCurrent, formValues }) => (
          <Box key={`education-${id}`} p={[3, 3, 8]} bgColor="bg.secondary">
            {!isDesktop && isCurrent && (
              <Tag colorScheme="accent-lightest" size="sm" mb={2} variant="subtle">
                Current School
              </Tag>
            )}

            <Flex mt={[3, 3, 0]} justifyContent="space-between">
              <Box>
                <Text variant="label" textTransform="capitalize">
                  {title}
                </Text>
                {!!subTitle && <Text textTransform="capitalize">{subTitle}</Text>}
                {!!datesTitle && (
                  <Text mt={1} textTransform="capitalize">
                    {datesTitle}
                  </Text>
                )}
              </Box>
              {isDesktop ? (
                <Flex alignItems="center">
                  {isCurrent && (
                    <Tag colorScheme="accent-lightest" size="sm" mr={2} variant="subtle">
                      Current School
                    </Tag>
                  )}
                  <Button
                    size="sm"
                    onClick={() => {
                      onEditClick(formValues, id);
                    }}
                    variant="ghost"
                  >
                    <EditIcon fontSize="md" />
                  </Button>
                </Flex>
              ) : (
                <Button
                  size="sm"
                  onClick={() => {
                    onEditClick(formValues, id);
                  }}
                  variant="ghost"
                >
                  <EditIcon fontSize="md" />
                </Button>
              )}
            </Flex>
          </Box>
        ))}
      </Grid>
      <ProfileSectionButton mt={educations.length ? 6 : 0} onAddClick={onAddClick}>
        Add Education
      </ProfileSectionButton>
    </>
  );
}
