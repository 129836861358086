import { useRouteMatch } from 'react-router-dom';
import { ErrorBoundary } from 'global/sentry';
import { useRedirection } from 'global/utils';

import { ErrorFallback, PrivateRoute, SwitchWithPageNotFound } from 'candidate/components';
import { JobMatchesRouter } from './subfeatures/job-matches';

export function JobRouter() {
  const { path } = useRouteMatch();
  const redirectTo = useRedirection();

  return (
    <ErrorBoundary
      fallback={<ErrorFallback onActionClick={() => redirectTo('/')} actionTitle="Return Home" />}
    >
      <SwitchWithPageNotFound>
        <PrivateRoute title="Job Matches" path={`${path}/matches`} authPath="/auth">
          <JobMatchesRouter />
        </PrivateRoute>
      </SwitchWithPageNotFound>
    </ErrorBoundary>
  );
}
