import type { ComponentProps } from 'react';
import { useRedirection } from 'global/utils';
import { Button, Image } from '@terminal/design-system';
import { useCandidateAuth, appState } from 'candidate/utils';
import { AccountCreatedSuccess } from './AccountCreatedSuccess';
import { Success } from '../../components';
import ArrowRight from '../../assets/arrow-right-blue.svg?url';

export function AccountCreatedSuccessController({
  companyName,
  position,
}: Omit<ComponentProps<typeof Success>, 'header' | 'CTATitle' | 'applicantFirstName'>) {
  const redirectTo = useRedirection();

  const handleOnClick = () => {
    appState.flow.update('landing-from-direct-application', 'create-account');
    redirectTo('/');
  };

  const auth = useCandidateAuth();

  return (
    <Success
      companyName={companyName}
      position={position}
      applicantFirstName={auth.user?.first_name}
      header={<AccountCreatedSuccess onClick={handleOnClick} />}
      secondaryCTA={
        <Button variant="ghost" rightIcon={<Image src={ArrowRight} />} onClick={handleOnClick}>
          Explore My Terminal Account
        </Button>
      }
    />
  );
}
