/* eslint-disable func-names */
import { useState } from 'react';
import { useSkillsController } from 'candidate/shared/modules';
import type { SkillsProps } from 'candidate/shared/modules';
import { useCandidateAuth } from 'candidate/utils';
import type { HStepsProps } from 'candidate/components';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import { moreSkills as moreSkillsNames } from './SkillsStepTags.constants';
import { SkillsStepTags } from './SkillsStepTags';

export function SkillsStepController({
  skillGroups,
  userID,
  candidateID,
  skillChoices,
  skillChoicesByRole,
  onStepCompleteSuccess,
  progressProps,
  hasOptionalSkills,
}: {
  skillGroups: SkillsProps['skillGroups'];
  candidateID: number;
  userID?: number;
  skillChoices: SkillsProps['skillChoices'];
  skillChoicesByRole: SkillsProps['skillChoices'];
  progressProps: HStepsProps;
  onStepCompleteSuccess: () => void;
  hasOptionalSkills: boolean;
}) {
  const [shouldShowFullList, setShouldShowFullList] = useState(false);
  const auth = useCandidateAuth();

  const handleOnShowMoreLessClick = () =>
    setShouldShowFullList((prevShouldShowFullList) => !prevShouldShowFullList);

  const { formik, handleOnSaveClick, isLoading_upsert } = useSkillsController({
    candidateID,
    defaultEmptyRows: 0,
    onSaveSuccess: onStepCompleteSuccess,
    shouldUseValidation: false,
    skillChoices,
    skillGroups,
    userID,
  });

  function addSkillToValues({
    id,
    name,
  }: {
    id: SkillsProps['skillGroups'][number]['skills'][number]['formValues']['skillID'];
    name: SkillsProps['skillGroups'][number]['skills'][number]['formValues']['name'];
  }) {
    formik.setValues({
      candidateSkills: [
        ...formik.values.candidateSkills,
        {
          candidateSkillID: '0',
          competency: null,
          is_favorite: false,
          name,
          skillID: id,
          years_of_exp: null,
        },
      ],
    });
  }

  function removeSkillFromValues(
    id: SkillsProps['skillGroups'][number]['skills'][number]['formValues']['skillID'],
  ) {
    const newCandidateSkills: SkillsProps['skillGroups'][number]['skills'][number]['formValues'][] =
      formik.values.candidateSkills.reduce(
        (
          valuesToSet: SkillsProps['skillGroups'][number]['skills'][number]['formValues'][],
          currentSkill,
        ) => {
          return currentSkill.skillID !== id ? [...valuesToSet, currentSkill] : valuesToSet;
        },
        [],
      );

    formik.setValues({ candidateSkills: newCandidateSkills });
  }

  const hanldeOnSkillClick = (selectedSkillId: number) => {
    const isSkillInValues = formik.values.candidateSkills.find(
      ({ skillID }) => skillID === selectedSkillId,
    );
    const selectedSkill = skillChoices.find(({ id }) => id === selectedSkillId);

    if (isSkillInValues && selectedSkill) {
      removeSkillFromValues(selectedSkill.id);
    } else if (selectedSkill) {
      addSkillToValues(selectedSkill);
    }
  };

  const moreSkills = moreSkillsNames
    .map((skillName) =>
      skillChoices.find(
        (skill) => skill.name.toLocaleLowerCase() === skillName.toLocaleLowerCase(),
      ),
    )
    .filter(Boolean);

  // Filling the room if needed with the moreSkills constant to match 20 items
  const mainItemsCount = 20;
  const mainSkillOptions = [
    ...skillChoicesByRole.slice(0, mainItemsCount),
    ...moreSkills
      .filter(
        (skill) =>
          !skillChoicesByRole.find(
            (roleSkill) => roleSkill.name.toLocaleLowerCase() === skill?.name.toLocaleLowerCase(),
          ),
      )
      .slice(
        0,
        skillChoicesByRole.length < mainItemsCount ? mainItemsCount - skillChoicesByRole.length : 0,
      ),
  ].sort((a, b) => a?.name.replace(/\W/g, '').localeCompare(b?.name.replace(/\W/g, '') || '') || 0);
  const moreSkillOptions = [
    ...skillChoicesByRole.slice(mainItemsCount),
    ...moreSkills.filter(
      (skill) =>
        ![...mainSkillOptions, ...skillChoicesByRole.slice(mainItemsCount)].find(
          (mainSkill) => mainSkill?.name.toLocaleLowerCase() === skill?.name.toLocaleLowerCase(),
        ),
    ),
  ]
    .slice(0, 40)
    .sort(
      (a, b) => a?.name.replace(/\W/g, '').localeCompare(b?.name.replace(/\W/g, '') || '') || 0,
    );

  return (
    <SkillsStepTags
      isNextDisabled={
        isLoading_upsert || (!hasOptionalSkills && formik.values.candidateSkills.length < 3)
      }
      candidateSelectedSkills={skillGroups.flatMap(({ skills }) =>
        skills.map(({ formValues }) => formValues),
      )}
      onNextClick={() => handleOnSaveClick({ allowingSavingWithoutValueChange: true })}
      onSkillClick={hanldeOnSkillClick}
      stepPageLayoutProps={{
        progressProps,
        onSignOutClick: () => signOut({ auth: firebaseAuth }),
        userFullName: auth.userFullName,
      }}
      onShowMoreLessClick={handleOnShowMoreLessClick}
      mainSkillOptions={mainSkillOptions as SkillsProps['skillChoices']}
      moreSkillOptions={moreSkillOptions as SkillsProps['skillChoices']}
      shouldShowFullList={shouldShowFullList}
      title={hasOptionalSkills ? 'Do you want to add skills?' : 'What Are Your Top Skills?'}
      description={
        hasOptionalSkills
          ? 'This is not required but can help your profile stand out.'
          : 'To get better job matches, select at least 3 of your top skills.'
      }
    />
  );
}
