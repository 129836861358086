import { Card, CardBody } from 'candidate/components';
import { Flex, Spinner, Center, Text } from '@terminal/design-system';
import type { HStepsProps } from 'candidate/components';
import { StepPageLayout } from '../../components/StepPageLayout';

export function ResumeParsingLoading({
  onSignOutClick,
  userFullName,
  progressProps,
}: {
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  progressProps: HStepsProps;
}) {
  return (
    <StepPageLayout
      title="Upload Resume to Finalize"
      description="By uploading your resume, you can be contacted by our team sooner and quickly move ahead of 50% of candidates."
      {...{ progressProps, userFullName, onSignOutClick }}
      footer={null}
    >
      <Card>
        <CardBody px={[3, 8]} py={[6, 8]}>
          <Center>
            <Flex flexDir="column" alignItems="center">
              <Spinner color="accent.main" mb={4} aria-label="Loading" role="status" />
              <Text>Uploading and translating your file...</Text>
            </Flex>
          </Center>
        </CardBody>
      </Card>
    </StepPageLayout>
  );
}
