import { Center, Text, useToken, Image } from '@terminal/design-system';

export interface HeaderMenuItemProps {
  onClick: () => void;
  isActive?: boolean;
  title: string;
  rightIcon?: string;
}

export function HeaderMenuItem({ isActive, title, onClick, rightIcon }: HeaderMenuItemProps) {
  const [brandDarkerPrimary, brandPrimary] = useToken('colors', ['accent.darkest', 'brand.main']);

  return (
    <Center
      onClick={onClick}
      flexDir="row"
      _hover={{ borderBottom: `2px solid ${brandPrimary}`, cursor: 'pointer' }}
      h={6}
      borderBottom={`2px solid ${isActive ? brandDarkerPrimary : 'transparent'}`}
    >
      <Text variant="caption" color="text.primary" paddingRight={rightIcon ? 2 : 0}>
        {title}
      </Text>

      {rightIcon && <Image w={3} h={3} src={rightIcon} />}
    </Center>
  );
}
