import { Link as RouterLink } from 'react-router-dom';
import type { FormikState, FormikHandlers } from 'formik';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
  Divider,
  Text,
  AddIcon,
} from '@terminal/design-system';
import type { HStepsProps } from 'candidate/components';
import { StepPageLayout } from '../../components/StepPageLayout';

export function ExperiencesWhatDoYouHave({
  isNextDisabled,
  onNextClick,
  onSignOutClick,
  progressProps,
  title,
  userFullName,
  errors,
  values,
  touched,
  onBlur,
  onChange,
  addExperienceHref,
}: {
  isNextDisabled: boolean;
  onNextClick: () => void;
  onSignOutClick: () => Promise<void>;
  progressProps: HStepsProps;
  title: string;
  userFullName: string;
  onBlur: FormikHandlers['handleBlur'];
  onChange: FormikHandlers['handleChange'];
  addExperienceHref: string;
} & Pick<FormikState<{ linkedin: string }>, 'errors' | 'values' | 'touched'>) {
  return (
    <StepPageLayout
      progressProps={progressProps}
      title={title}
      description="Provide your LinkedIn profile or manually add experience to get matched to the right jobs."
      userFullName={userFullName}
      onSignOutClick={onSignOutClick}
      footer={
        <Flex flexDir={['column', 'column', 'row-reverse']} w="full" justifyContent="center">
          <Button
            onClick={onNextClick}
            w={['full', 'full', 64]}
            mb={[3, 3, 0]}
            isDisabled={isNextDisabled}
          >
            Finalize
          </Button>
        </Flex>
      }
    >
      <VStack spacing={[4, 8, 8]}>
        <FormControl isInvalid={touched.linkedin && !!errors.linkedin}>
          <FormLabel htmlFor="linkedin">LinkedIn</FormLabel>
          <Input
            id="linkedin"
            name="linkedin"
            onBlur={onBlur}
            onChange={onChange}
            placeholder="www.linkedin.com/in"
            value={values.linkedin}
            autoCapitalize="none"
            spellCheck={false}
          />
          <FormErrorMessage>{errors.linkedin}</FormErrorMessage>
        </FormControl>
        <Flex my={6} flexDir="row" align="center" justify="center" w="full">
          <Divider />
          <Text px={2} variant="hint" fontWeight="bold">
            OR
          </Text>
          <Divider />
        </Flex>
        <Button
          variant="secondary"
          leftIcon={<AddIcon fontSize="2xl" />}
          iconSpacing={3}
          w="full"
          as={RouterLink}
          to={addExperienceHref}
        >
          Add Experience
        </Button>
      </VStack>
    </StepPageLayout>
  );
}
