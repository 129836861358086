import type { ChangeEvent, FocusEvent } from 'react';
import type { FormikState } from 'formik';
import {
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
  Checkbox,
  Select,
  Textarea,
} from '@terminal/design-system';

const monthsOptions = [
  [0, 'January'],
  [1, 'February'],
  [2, 'March'],
  [3, 'April'],
  [4, 'May'],
  [5, 'June'],
  [6, 'July'],
  [7, 'August'],
  [8, 'September'],
  [9, 'October'],
  [10, 'November'],
  [11, 'December'],
];

export function WorkExperiencesForm({
  errors,
  onChange,
  onBlur,
  initialRef,
  values,
  touched,
}: {
  onChange: (event: string | ChangeEvent<any>) => void;
  onBlur: (event: FocusEvent<any>) => void;
  initialRef?: React.MutableRefObject<null>;
} & Pick<
  FormikState<{
    company_name: string;
    currently_working: boolean;
    description: string;
    endDateMonth: number | null;
    endDateYear: number | null;
    job_title: string;
    location: string;
    startDateMonth: number | null;
    startDateYear: number | null;
  }>,
  'values' | 'errors' | 'touched'
>) {
  const now = new Date().getUTCFullYear();
  const yearsOptions = Array(now - 1960)
    .fill('')
    .map((_, idx) => (now - idx).toString());

  return (
    <VStack spacing={[4, 4, 6]}>
      <FormControl id="job_title" isInvalid={touched.job_title && !!errors.job_title}>
        <FormLabel htmlFor="job_title">Job Title</FormLabel>
        <Input
          ref={initialRef}
          placeholder="Type job title"
          id="job_title"
          name="job_title"
          onChange={onChange}
          onBlur={onBlur}
          value={values.job_title}
        />
        <FormErrorMessage>{errors.job_title}</FormErrorMessage>
      </FormControl>

      <FormControl id="company_name" isInvalid={touched.company_name && !!errors.company_name}>
        <FormLabel htmlFor="company_name">Company</FormLabel>
        <Input
          placeholder="Type company name"
          id="company_name"
          name="company_name"
          onChange={onChange}
          onBlur={onBlur}
          value={values.company_name}
        />
        <FormErrorMessage>{errors.company_name}</FormErrorMessage>
      </FormControl>

      <FormControl id="location" isInvalid={touched.location && !!errors.location}>
        <FormLabel htmlFor="location">Location</FormLabel>
        <Input
          placeholder="Type location"
          id="location"
          name="location"
          onChange={onChange}
          onBlur={onBlur}
          value={values.location}
        />
        <FormErrorMessage>{errors.location}</FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={
          touched.startDateMonth &&
          touched.startDateYear &&
          (!!errors.startDateMonth || !!errors.startDateYear)
        }
      >
        <FormLabel>Start Date</FormLabel>
        <HStack spacing={3} alignItems="flex-start" flex="1 1 auto">
          <Select
            flex={1}
            placeholder="Month"
            name="startDateMonth"
            defaultValue={values.startDateMonth ?? undefined}
            onChange={onChange}
            onBlur={onBlur}
          >
            {monthsOptions.map(([value, label]) => (
              <option value={value} key={`startDateMonth-${value}`}>
                {label}
              </option>
            ))}
          </Select>
          <Select
            flex={1}
            placeholder="Year"
            name="startDateYear"
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={values.startDateYear || undefined}
          >
            {yearsOptions.map((value) => (
              <option value={value} key={`startDateYear-${value}`}>
                {value}
              </option>
            ))}
          </Select>
        </HStack>
        <FormErrorMessage>{errors.startDateMonth}</FormErrorMessage>
        <FormErrorMessage>{errors.startDateYear}</FormErrorMessage>
      </FormControl>

      <FormControl
        id="currently_working"
        isInvalid={touched.currently_working && !!errors.currently_working}
      >
        <Checkbox
          id="currently_working"
          name="currently_working"
          onChange={onChange}
          onBlur={onBlur}
          isChecked={values.currently_working}
        >
          I currently work here
        </Checkbox>
        <FormErrorMessage>{errors.currently_working}</FormErrorMessage>
      </FormControl>

      {!values.currently_working && (
        <FormControl
          isInvalid={
            touched.endDateMonth &&
            touched.endDateYear &&
            (!!errors.endDateMonth || !!errors.endDateYear)
          }
        >
          <FormLabel>End Date</FormLabel>
          <HStack spacing={3} alignItems="flex-start" flex="1 1 auto">
            <Select
              flex={1}
              placeholder="Month"
              name="endDateMonth"
              defaultValue={values.endDateMonth ?? undefined}
              onChange={onChange}
              onBlur={onBlur}
            >
              {monthsOptions.map(([value, label]) => (
                <option value={value} key={`endDateMonth-${value}`}>
                  {label}
                </option>
              ))}
            </Select>
            <Select
              flex={1}
              placeholder="Year"
              name="endDateYear"
              defaultValue={values.endDateYear || undefined}
              onChange={onChange}
              onBlur={onBlur}
            >
              {yearsOptions.map((value) => (
                <option value={value} key={`endDateYear-${value}`}>
                  {value}
                </option>
              ))}
            </Select>
          </HStack>
          <FormErrorMessage>{errors.endDateMonth}</FormErrorMessage>
          <FormErrorMessage>{errors.endDateYear}</FormErrorMessage>
        </FormControl>
      )}
      <FormControl id="description" isInvalid={touched.description && !!errors.description}>
        <FormLabel htmlFor="description">Description (optional)</FormLabel>
        <Textarea
          placeholder="Add a brief description about your job"
          id="description"
          name="description"
          onChange={onChange}
          onBlur={onBlur}
          value={values.description}
        />
        <FormErrorMessage>{errors.description}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
}
