import type { ChangeEvent, FocusEvent } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import type { FormikState } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Grid,
  GridItem,
  InputGroup,
  InputRightElement,
  EditIcon,
} from '@terminal/design-system';
import { validators } from 'global/utils';
import type { ProfileProps } from '../../Profile.types';

export const SocialProfilesSchema = Yup.object().shape({
  linkedin_url: Yup.string()
    .matches(validators.linkedIn.validURL.regex, validators.linkedIn.validURL.message)
    .nullable(),
  github_url: Yup.string().matches(validators.github.regex, validators.github.message).nullable(),
  other_url: Yup.string().matches(validators.url.regex, validators.url.message).nullable(),
});

export function SocialProfilesForm({
  errors,
  handleChange,
  handleBlur,
  initialRef,
  values,
  touched,
}: {
  handleChange: (e: string | ChangeEvent<any>) => void;
  handleBlur: (e: FocusEvent<any>) => void;
  initialRef?: React.MutableRefObject<null>;
} & Pick<
  FormikState<ProfileProps['candidate']['socialProfile']['formValues']>,
  'errors' | 'values' | 'touched'
>) {
  return (
    <FormControl id="social-profiles">
      <Grid gridTemplateColumns={['1fr', '1fr', '1fr 1fr']} rowGap={6} columnGap={6}>
        <GridItem order={[1, 1, 'unset']}>
          <FormControl id="linkedin_url" isInvalid={touched.linkedin_url && !!errors.linkedin_url}>
            <FormLabel htmlFor="linkedin_url">LinkedIn</FormLabel>
            <InputGroup>
              <Input
                ref={initialRef}
                placeholder="linkedin.com/in/username"
                id="linkedin_url"
                name="linkedin_url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.linkedin_url || ''}
                autoCapitalize="none"
                spellCheck={false}
              />
              <InputRightElement pointerEvents="none">
                <EditIcon fontSize="md" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.linkedin_url}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem order={[3, 3, 'unset']}>
          <FormControl id="other_url" isInvalid={touched.other_url && !!errors.other_url}>
            <FormLabel htmlFor="portfolio">Portfolio</FormLabel>
            <InputGroup>
              <Input
                placeholder="url.com"
                id="other_url"
                name="other_url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.other_url || ''}
                autoCapitalize="none"
                spellCheck={false}
              />
              <InputRightElement pointerEvents="none">
                <EditIcon fontSize="md" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.other_url}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem order={[2, 2, 'unset']}>
          <FormControl id="github_url" isInvalid={touched.github_url && !!errors.github_url}>
            <FormLabel htmlFor="github_url">GitHub</FormLabel>
            <InputGroup>
              <Input
                placeholder="github.com/username"
                id="github_url"
                name="github_url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.github_url || ''}
                autoCapitalize="none"
                spellCheck={false}
              />
              <InputRightElement pointerEvents="none">
                <EditIcon fontSize="md" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.github_url}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </FormControl>
  );
}

export function useSocialProfileEditConfig({
  onSubmit,
  initialRef,
  initialValues,
}: {
  onSubmit: (args: ProfileProps['candidate']['socialProfile']['formValues']) => void;
  initialValues: ProfileProps['candidate']['socialProfile']['formValues'];
  initialRef: React.MutableRefObject<null>;
}) {
  const formik = useFormik<ProfileProps['candidate']['socialProfile']['formValues']>({
    initialValues,
    validationSchema: SocialProfilesSchema,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const config = {
    component: (
      <SocialProfilesForm
        initialRef={initialRef}
        values={formik.values}
        touched={formik.touched}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        errors={formik.errors}
      />
    ),
    getModalTitle: () => {
      return 'Edit Social Profiles';
    },
    onSubmit: formik.handleSubmit,
    resetForm: formik.resetForm,
    hasFormValuesChanged: () =>
      JSON.stringify(formik.initialValues) !== JSON.stringify(formik.values),
  };

  return { config, formik };
}
