import { useIsViewPortDesktop, useRedirection } from 'global/utils';
import * as events from 'global/events';
import { triggerGoal } from 'candidate/utils';
import type { JobSuggestionProps } from '../data/JobSuggestions.types';
import { JobCard } from './JobCard';

export function JobCardController({
  jobData,
  maxSkillsToDisplay = [2, 5],
  jobSection = 'other-jobs',
  onJobDetailClick,
  shouldHighlight,
}: {
  jobData: JobSuggestionProps;
  maxSkillsToDisplay?: [mobile: number, desktop: number];
  jobSection?: 'suggested-jobs' | 'other-jobs';
  shouldHighlight?: boolean;
  onJobDetailClick?: (job: JobSuggestionProps) => void;
}) {
  const redirectTo = useRedirection();
  const isDesktop = useIsViewPortDesktop();
  const handleOnJobClick = (job: JobSuggestionProps) => {
    if (onJobDetailClick) {
      onJobDetailClick(job);
      return;
    }
    events.track(events.name.ctaClicked, {
      job_id: job.id,
      name: 'quick-apply',
    });
    // Trigger goal (Campaign: Hide.show salaries) -> Click on job card
    triggerGoal({ goalID: 204, campaignID: 71 });

    redirectTo(`/job/matches/detail/${job.id}`, { shouldScrollToTopOnChange: false });
  };

  return (
    <JobCard
      key={`suggested-jobs-${jobData.id}`}
      jobSuggestionData={jobData}
      onJobDetailClick={handleOnJobClick}
      jobSection={jobSection}
      isDesktop={isDesktop}
      maxSkillsToDisplay={maxSkillsToDisplay[isDesktop ? 1 : 0]}
      shouldHighlight={shouldHighlight}
    />
  );
}
