import { useEffect, useState } from 'react';
import { useQuery, useRedirection } from 'global/utils';
import { BlankScreenLoading } from 'global/components';
import { useParams, useRouteMatch, useLocation } from 'react-router-dom';
import { useCandidateAuth } from 'candidate/utils';
import type { SkillsProps } from 'candidate/shared/modules';
import { CustomRoute, SwitchWithPageNotFound } from 'candidate/components';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth/authMethods';
import type {
  SelectDirectApplicationInfoQuery,
  SelectDirectApplicationInfoQueryVariables,
} from './DirectApplication.types';
import { SelectDirectApplicationInfo } from './graphql';
import { serializerForDirectApplication } from './DirectApplication.serializer';
import { ApplicationSectionsController } from './subfeatures/ApplicationSections/ApplicationSections.controller';
import { AccountExistsLogInFlowController } from './subfeatures/AccountExistsLogIn';
import { JobApplicationSignUpController } from './subfeatures/JobApplicationSignUp';
import { CountryNotSupportedFlowController } from './subfeatures/CountryNotSupported';
import type { PersonalInfoForm } from './subfeatures/ApplicationSections/PersonalInfo.controller';

export type DirectApplicationInfo = {
  id: number | null;
  companyName: string;
  position: string;
  location: string;
  youAppliedForCopy: string;
};

export function DirectApplicationController() {
  const auth = useCandidateAuth();
  const location = useLocation<string>();
  const [skipSelectJobApplicationFetch, setSkipSelectJobApplicationFetch] = useState(false);
  const { path, url } = useRouteMatch();
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoForm>();
  const { jobID } = useParams<{ jobID: string }>();
  const redirectTo = useRedirection();

  useEffect(() => {
    if (firebaseAuth?.currentUser?.isAnonymous) {
      signOut({ auth: firebaseAuth });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isResolved]);

  const [serializedData, setSerializedData] = useState<{
    jobApplicationInfo: DirectApplicationInfo;
    skillOptionsData: {
      skillsCompetencyOptions: SkillsProps['skillCompetencyChoices'];
      skillsOptions: Omit<SkillsProps['skillChoices'][number], 'roles'>[];
    };
    applicationSectionsPageTitle: string;
    yearsOfExperienceRangeChoices: ReturnType<
      typeof serializerForDirectApplication
    >['yearsOfExperienceRangeChoices'];
  }>({
    jobApplicationInfo: {
      id: null,
      companyName: '',
      location: '',
      position: '',
      youAppliedForCopy: '',
    },
    skillOptionsData: {
      skillsCompetencyOptions: [],
      skillsOptions: [],
    },
    applicationSectionsPageTitle: '',
    yearsOfExperienceRangeChoices: [],
  });

  const { loading: isLoading_selectJobApplicationInfo, data } = useQuery<
    SelectDirectApplicationInfoQuery,
    SelectDirectApplicationInfoQueryVariables
  >(SelectDirectApplicationInfo, {
    skip: skipSelectJobApplicationFetch,
    context: {
      role: 'candidate',
    },
    variables: {
      job_id: Number(jobID),
    },
  });

  // This hook is being used as a hack because even with the skip parameter set as true, the
  // SelectDirectApplicationInfo query still gets executed and after the first successful fetch, it
  // returns data as undefined.
  // TODO: Fix the query executing even after the skip parameter is set
  // to true or at least modify it so it stops returning undefined on repeated calls
  // https://terminal.atlassian.net/browse/GROW-63
  useEffect(() => {
    if (data) {
      const {
        jobApplicationInfo,
        skillOptionsData,
        applicationSectionsPageTitle,
        yearsOfExperienceRangeChoices,
      } = serializerForDirectApplication(data as SelectDirectApplicationInfoQuery);

      if (jobApplicationInfo.companyName == null && jobApplicationInfo.position == null) {
        redirectTo('/job-apply/not-found', { shouldReplacePath: true });
      }

      setSerializedData({
        jobApplicationInfo: {
          id: jobApplicationInfo.id,
          companyName: jobApplicationInfo.companyName || '',
          position: jobApplicationInfo.position || '',
          location: jobApplicationInfo.location || '',
          youAppliedForCopy: jobApplicationInfo.youAppliedForCopy,
        },
        skillOptionsData: {
          skillsCompetencyOptions: skillOptionsData.skillsCompetencyOptions,
          skillsOptions: skillOptionsData.skillsOptions,
        },
        applicationSectionsPageTitle,
        yearsOfExperienceRangeChoices,
      });

      setSkipSelectJobApplicationFetch(true);
    }
    // We know data changes when isLoading_selectJobApplicationInfo changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading_selectJobApplicationInfo]);

  if ((isLoading_selectJobApplicationInfo && !data) || (!auth.isResolved && !auth.isError)) {
    return <BlankScreenLoading aria-label="Loading" />;
  }

  return (
    <SwitchWithPageNotFound>
      <CustomRoute
        path={`${url}/existing-account`}
        title={[serializedData.applicationSectionsPageTitle]}
      >
        <AccountExistsLogInFlowController
          companyName={serializedData.jobApplicationInfo.companyName}
          firstName={personalInfo?.firstName}
          currentEmail={personalInfo?.email}
          jobID={serializedData.jobApplicationInfo.id}
          position={serializedData.jobApplicationInfo.position}
        />
      </CustomRoute>

      <CustomRoute
        path={`${url}/claim-account`}
        title={[serializedData.applicationSectionsPageTitle]}
      >
        <JobApplicationSignUpController
          companyName={serializedData.jobApplicationInfo.companyName}
          firstName={personalInfo?.firstName as string}
          lastName={personalInfo?.lastName as string}
          currentEmail={personalInfo?.email as string}
          jobID={serializedData.jobApplicationInfo.id}
          position={serializedData.jobApplicationInfo.position}
          continueURL={`${window.location.origin}/job/matches/detail/${jobID}?from=claim-account`} // TODO: GROW-81 investigate if continueURLs could point to itself to process the incoming request, make sure the redirection rules are still working after that change.
          parentURL={url}
        />
      </CustomRoute>

      <CustomRoute
        path={`${url}/country-not-supported`}
        title={[serializedData.applicationSectionsPageTitle]}
        shouldRedirect={!personalInfo}
        redirectURL={url}
      >
        <CountryNotSupportedFlowController
          personalInfo={personalInfo as PersonalInfoForm}
          parentNestedPageTitles={[]}
        />
      </CustomRoute>

      <CustomRoute
        path={`${path}/provide-info`}
        title={[serializedData.applicationSectionsPageTitle]}
      >
        <ApplicationSectionsController
          companyName={serializedData.jobApplicationInfo.companyName}
          jobLocation={serializedData.jobApplicationInfo.location}
          yearsOfExperienceRangeChoices={serializedData.yearsOfExperienceRangeChoices}
          onAccountExistsClick={() => {
            redirectTo(`${url}/existing-account`);
          }}
          position={serializedData.jobApplicationInfo.position}
          skillsOptions={serializedData.skillOptionsData.skillsOptions}
          handleRecognizedApplicant={(values) => {
            setPersonalInfo(values);
            redirectTo(`${url}/claim-account`);
          }}
          handleCandidateExists={(values) => {
            setPersonalInfo(values);
            redirectTo(`${url}/existing-account`);
          }}
          handleNoCountrySupported={(values) => {
            setPersonalInfo(values);
            redirectTo(`${url}/country-not-supported`, { shouldPassQuerystringAlong: true });
          }}
        />
      </CustomRoute>

      <CustomRoute
        path={path}
        title={[serializedData.applicationSectionsPageTitle]}
        onBeforeRedirect={() => {
          if (!auth.isAuthorized) return;

          const isExternalReferrer =
            document.referrer.length && new URL(document.referrer).host !== window.location.host;

          // If the user comes from an external URL we enable the logic to handle the application in the JobMatches controller
          if (isExternalReferrer) {
            localStorage.setItem(
              'from-direct-application-job-id',
              `${serializedData.jobApplicationInfo.id}`,
            );
            localStorage.setItem(
              'from-direct-application-job-title',
              serializedData.jobApplicationInfo.position,
            );
            localStorage.setItem(
              'from-direct-application-job-company',
              serializedData.jobApplicationInfo.companyName,
            );
          }
        }}
        conditionalRedirects={[
          {
            condition: !auth.isAuthorized,
            redirectURL: `${url}/provide-info`,
            search: location.search,
          },
          {
            condition: auth.isAuthorized && !!serializedData.jobApplicationInfo.id,
            redirectURL: `/job/matches/detail/${serializedData.jobApplicationInfo.id}`,
            search: location.search
              ? `${location.search}&from=direct-application`
              : `?from=direct-application`,
          },
        ]}
      />
    </SwitchWithPageNotFound>
  );
}
