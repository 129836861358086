/* eslint-disable react/no-array-index-key */
import {
  Center,
  Box,
  Flex,
  Heading,
  useTheme,
  Checkbox,
  Text,
  Spinner,
} from '@terminal/design-system';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

function easeOutBack(x: number): number {
  const c1 = 1.70158;
  const c3 = c1 + 1;
  return 1 + c3 * (x - 1) ** 3 + c1 * (x - 1) ** 2;
}

export function MultiMessageLoading({
  title,
  steps,
}: {
  title: string;
  steps: {
    title: string;
    /* Duraction in second */
    duration: number;
  }[];
}) {
  const { zIndices, colors } = useTheme();
  const [current_step_index, set_current_step_index] = useState<number>(0);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const check_next_step = (index: number) => {
      if (index < steps.length) {
        const duration_second = steps?.[index]?.duration || 1;

        set_current_step_index(index);

        timeout = setTimeout(() => {
          check_next_step(index + 1);
        }, duration_second * 1000);
      }
    };

    if (steps.length > 0) {
      check_next_step(0);
    }

    return () => clearTimeout(timeout);
  }, [steps]);

  return (
    <motion.div
      key="endless-loading-bar-with-icon__root"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: colors.bg.primary,
        zIndex: zIndices.tooltip,
      }}
      aria-label="App Loading"
    >
      <Center w="100vw" minH="100vh" bg="bg.primary">
        <Flex flexDir="column" alignItems="center">
          <Box borderRadius="xl" bgColor="accent.lightest">
            <svg
              width="112"
              height="112"
              viewBox="0 0 112 112"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.g
                key="endless-loading-bar-with-icon__svg"
                initial={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1.5 }}
                transition={{ duration: 0.5, delay: 0.25 }}
              >
                <path d="M65.5 46.5H26V66H46V85.5H65.5V46.5Z" stroke="#6DA8BB" strokeWidth="4.5" />
                <motion.g
                  key="endless-loading-bar-with-icon__edge"
                  animate={{ x: '8px', y: '-8px' }}
                  exit={{ x: '0px', y: '0px' }}
                  transition={{ duration: 0.5, ease: 'easeInOut' }}
                >
                  <motion.path
                    key="endless-loading-bar-with-icon__edge-rotation"
                    d="M65.5 26V46H85.5V26H65.5Z"
                    stroke="#6DA8BB"
                    strokeWidth="4.5"
                    animate={{ rotate: ['0deg', '360deg'] }}
                    exit={{ rotate: '0deg', transition: { repeat: 0 } }}
                    transition={{
                      duration: 1,
                      ease: easeOutBack,
                      repeat: Infinity,
                      delay: 1,
                      repeatDelay: 0.5,
                    }}
                  />
                </motion.g>
              </motion.g>
            </svg>
          </Box>
          <Box mt={10} w="14.688rem" h={3} pos="relative" bgColor="ui.secondary" overflow="hidden">
            <motion.div
              key="endless-loading-bar-with-icon__bar"
              animate={{ x: ['-100%', '0%', '100%'] }}
              transition={{
                duration: 2,
                ease: 'easeInOut',
                times: [0, 0.5, 1],
                repeat: Infinity,
                repeatDelay: 0,
              }}
            >
              <Box w="14.688rem" h={3} bgColor="accent.main" />
            </motion.div>
          </Box>
          <Flex flexDir="column" mt={6} alignItems="center">
            <Heading as="span" variant="heading-3" ml={2}>
              {title}
            </Heading>
            <Box textAlign="left" mt={8} ml={3}>
              {steps.map((step, step_index) => (
                <Flex key={`${step_index}-loading`} alignItems="center">
                  {current_step_index === step_index ? (
                    <Spinner size="sm" />
                  ) : (
                    <Checkbox isChecked={step_index < current_step_index} />
                  )}
                  <Text as="span" ml={2}>
                    {step?.title}
                  </Text>
                </Flex>
              ))}
            </Box>
          </Flex>
        </Flex>
      </Center>
    </motion.div>
  );
}
