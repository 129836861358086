import { HSteps, PublicPageLayout, PageLayout } from 'candidate/components';
import type { HStepsProps } from 'candidate/components';
import { Box, Heading, Link, Text, Flex, Container } from '@terminal/design-system';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { useRedirection } from 'global/utils';

function StepContent({
  title,
  description,
  captionInfo,
  header,
  children,
  footer,
  bannerContent,
  progressProps,
  contentContainerProps,
}: {
  title: string;
  description: string;
  captionInfo?: string;
  header?: React.ReactNode;
  children: React.ReactNode;
  footer: React.ReactNode;
  bannerContent?: React.ReactNode;
  progressProps: HStepsProps;
  contentContainerProps?: React.ComponentProps<typeof Flex>;
}) {
  return (
    <Flex flexDir="column" w="full" flex={1}>
      <Flex pt={[0, 0, 6]} pb={[9, 9, 6]} justifyContent="center">
        <HSteps {...progressProps} />
      </Flex>
      {header}
      <Container
        flex={1}
        pb={[3, 3, 12]}
        display="flex"
        flexDir="column"
        justifyContent={header ? 'flex-start' : ['flex-start', 'center', 'center']}
      >
        <Flex
          justifyContent={['normal', 'center', 'center']}
          alignItems={['normal', 'center', 'center']}
          flex={1}
        >
          <Flex
            w={['full', 'ml']}
            flexDir="column"
            justifyContent="space-between"
            {...contentContainerProps}
          >
            <Box>
              <Box mb={[6, 6, 10]} textAlign="center">
                {bannerContent && (
                  <Box
                    bg="accent.lightest"
                    w="100%"
                    py={[2, 2, 4]}
                    px={[3, 3, 6]}
                    mt={header ? 10 : 0}
                    border="1px solid"
                    borderColor="accent.main"
                    borderRadius="base"
                  >
                    {bannerContent}
                  </Box>
                )}

                <Heading variant="heading-2" mt={bannerContent ? [4, 4, 10] : 0}>
                  {title}
                </Heading>

                <Text mt={[2, 2, 1]}>{description}</Text>
                {captionInfo && (
                  <Text variant="caption" mt={[2, 2, 1]} color="text.secondary">
                    {captionInfo}
                  </Text>
                )}
              </Box>
              <Box>{children}</Box>
            </Box>
            <Flex mt={8} justifyContent="center">
              {footer}
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
}

// TODO: [CAND-360] hide menu links by passing a flag to PageLayout
export function StepPageLayout({
  title,
  description,
  captionInfo,
  userFullName,
  onSignOutClick,
  header,
  children,
  footer,
  bannerContent,
  progressProps,
  contentContainerProps,
}: {
  title: string;
  description: string;
  captionInfo?: string;
  userFullName: string;
  onSignOutClick: () => Promise<void>;
  header?: React.ReactNode;
  children: React.ReactNode;
  footer: React.ReactNode;
  bannerContent?: React.ReactNode;
  progressProps: HStepsProps;
  contentContainerProps?: React.ComponentProps<typeof Flex>;
}) {
  const redirectTo = useRedirection();

  if (userFullName) {
    return (
      <PageLayout.Variation.WithNoSections
        userFullName={userFullName}
        onSignOutClick={() => {
          redirectTo('/auth');
          return onSignOutClick();
        }}
        shouldNotWrapWithContainer
        candidateAvailability={null}
      >
        <StepContent
          captionInfo={captionInfo}
          children={children}
          description={description}
          footer={footer}
          header={header}
          bannerContent={bannerContent}
          progressProps={progressProps}
          title={title}
          contentContainerProps={contentContainerProps}
        />
      </PageLayout.Variation.WithNoSections>
    );
  }

  return (
    <PublicPageLayout
      headerRightPlaceholderDesktop={
        <Flex alignItems="center">
          <Text>Do You Have a Terminal Account?</Text>
          <Link as={RouterLink} to={`/auth${window.location.search}`} ml={2} fontWeight="bold">
            Login
          </Link>
        </Flex>
      }
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link
            as={RouterLink}
            to={`/auth/login${window.location.search}`}
            ml={2}
            fontWeight="bold"
          >
            Login
          </Link>
        </Flex>
      }
    >
      <StepContent
        captionInfo={captionInfo}
        children={children}
        description={description}
        footer={footer}
        header={header}
        bannerContent={bannerContent}
        progressProps={progressProps}
        title={title}
        contentContainerProps={contentContainerProps}
      />
    </PublicPageLayout>
  );
}
