import type { SkillsProps } from 'candidate/shared/modules';
import type {
  Candidate_Curation_Desired_Salary_Currency_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Role_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';

export * from './graphql/types';
export interface ProfileProps {
  candidate: {
    id: number;
    personalInfo: {
      fullName: string | null;
      email: string;
      location: string | null;
      country_id: number | null;
      phone: string | null;
      availability: string;
      formValues: {
        firstName: string;
        lastName: string;
        email: string;
        formattedAddress: string;
        city: string;
        country_id: number | null;
        country: string;
        state: string;
        latitude: string;
        longitude: string;
        availability: string | null;
        phone: string;
      };
    };
    roles: {
      id: number;
      role: CandidateRole;
      yearsOfExperience: string | null;
      formValues: {
        id: string;
        role: Role_Choices_Enum | null;
        years_of_exp: string;
      };
    }[];
    yearsOfExperienceRange: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null;
    assessments: {
      name: string;
      footerNote: string;
      status: string;
    }[];
    workExperiences: {
      id: number;
      title: string | null;
      subTitle: string | null;
      datesTitle: string | null;
      isCurrent: boolean;
      formValues: {
        job_title: string;
        company_name: string;
        location: string;
        startDateYear: number | null;
        startDateMonth: number | null;
        endDateYear: number | null;
        endDateMonth: number | null;
        currently_working: boolean;
        description: string;
      };
      hasMissingData: boolean;
      missingDataCTA: Maybe<string>;
      description: string | null;
    }[];
    educations: {
      id: number;
      isCurrent: boolean;
      title: string | null;
      subTitle: string | null;
      datesTitle: string | null;
      formValues: {
        concentration: string;
        degree: string | null;
        school_name: string;
        start_year: number | null;
        graduation_year: number | null;
      };
      hasMissingData: boolean;
      missingDataCTA: Maybe<string>;
    }[];
    socialProfile: {
      formValues: {
        linkedin_url: string | null;
        github_url: string | null;
        other_url: string | null;
      };
      links: {
        linkedin_url: string | null;
        github_url: string | null;
        other_url: string | null;
      };
    };
    resume: {
      fileName: string | null;
      publicID: string | null;
      updatedAt: string | null;
      formValues: {
        file: File | null;
      };
    };
    desiredSalary: {
      formattedSalary: string | null;
      formValues: DesiredSalaryInformation;
    };
    hasParsedResume: boolean;
    profileFlowFurthestStep: string | null;
    skillGroups: SkillsProps['skillGroups'];
    profileFlowStartedAt: string | null;
    profileFlowVariations: Record<string, string> | null;
    hasOptionalSkills: boolean;
  };
  roleChoices: CandidateRole[];
  yearsOfExperienceRangeChoices: {
    name: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum;
    displayName?: string;
  }[];
  degreeChoices: { [key: string]: string };
  availabilityOptions: { [key: string]: string };
  sectionsCompletionStatus: {
    personalInfo: boolean;
    roles: boolean;
    socialProfile: boolean;
    workExperience: boolean;
    educations: boolean;
    skills: boolean;
    resume: boolean;
    desiredSalary: boolean;
  };
  completionPercentage: number;
  skillChoices: SkillsProps['skillChoices'];
  skillChoicesByRole: SkillsProps['skillChoices'];
  skillCompetencyChoices: SkillsProps['skillCompetencyChoices'];
}

export const AllModals = [
  'personal-info',
  'roles',
  'work-experience',
  'education',
  'skills',
  'social-profile',
  'resume',
  'desired-salary',
] as const;

export type ProfileModalName = (typeof AllModals)[number];

export type SectionName =
  | 'Education'
  | 'Personal Info'
  | 'Roles'
  | 'Skills'
  | 'Social Profiles'
  | 'Resume'
  | 'Work'
  | 'Desired Salary';

export interface DesiredSalaryInformation {
  desiredSalaryAmount: number | null;
  desiredSalaryCurrency: Candidate_Curation_Desired_Salary_Currency_Choices_Enum | null;
}

type CandidateRole = {
  name: Role_Choices_Enum;
  displayName?: string;
};
