import {
  User_Subscription_Choices_Enum,
  Employment_Type_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import type { SelectJobSuggestionsQuery } from './types';

function createICIMSJob(
  override: Partial<SelectJobSuggestionsQuery['candidate_suggested_job'][number]> = {} as Partial<
    SelectJobSuggestionsQuery['candidate_suggested_job'][number]
  >,
): SelectJobSuggestionsQuery['candidate_suggested_job'][number] {
  return {
    profile_id: 10873,
    jobtitle: 'Geophysical data processor',
    tech_stack: 'Python/Golang, Micro services, Database Modeling, ER Modeling',
    years_experience: [
      {
        id: 'C28235',
        value: 'Intermediate 2-5',
      },
    ],
    skillByRequiredSkill1: {
      name: 'JavaScript',
    },
    skillByRequiredSkill2: {
      name: 'Node',
    },
    skillByRequiredSkill3: {
      name: 'TypeScript',
    },
    job: {
      min_contract_rate: null,
      max_contract_rate: null,
      interview_details: [],
      job_meta_infos: [
        {
          latam_min_salary: 123,
          latam_max_salary: 223424,
          canada_max_salary: 425322,
          canada_min_salary: 323542,
          europe_max_salary: 652323,
          europe_min_salary: 52532,
        },
      ],
      employment_type: Employment_Type_Choices_Enum.FullTime,
    },
    icims_company: {
      numofemps: 0,
      organizations: [
        {
          id: 155,
          salesforce_customer: {
            industry: 'Business Services',
          },
          logo: 'https://via.placeholder.com/350x150',
          name: 'Terminal',
        },
      ],
    },
    about:
      'The Senior Full-Stack Engineer has end-to-end responsibility for projects of increasing complexity with very little oversight beyond high-level direction.',
    what_youll_do:
      'Work both independently and in collaboration with other team members on the design and development of an end-to-end product for launch.',
    what_you_bring: 'Minimum 5 years of professional development experience',
    icims_job_locations: [
      {
        icims_location: {
          value: 'Canada',
        },
      },
      {
        icims_location: {
          value: 'Chile',
        },
      },
      {
        icims_location: {
          value: 'Colombia',
        },
      },
      {
        icims_location: {
          value: 'Mexico',
        },
      },
    ],
    ...override,
  };
}

function createApplicantJob(
  override: Partial<SelectJobSuggestionsQuery['applicant_job'][number]> = {} as Partial<
    SelectJobSuggestionsQuery['applicant_job'][number]
  >,
): SelectJobSuggestionsQuery['applicant_job'][number] {
  return {
    __typename: 'applicant_job',
    job: 3701,
    ...override,
  };
}

function createSelectJobSuggestion(
  override: Partial<SelectJobSuggestionsQuery> = {} as Partial<SelectJobSuggestionsQuery>,
): SelectJobSuggestionsQuery {
  return {
    candidate_suggested_job: [createICIMSJob()],
    user_subscription: [{ subscription: User_Subscription_Choices_Enum.NewJobAlert }],
    applicant_job: [
      createApplicantJob(),
      createApplicantJob({
        job: 3060,
      }),
      createApplicantJob({
        job: 3620,
      }),
    ],
    candidate_job: [],
    ...override,
  };
}

export const jobSuggestionsFixture = {
  createData: createSelectJobSuggestion,
  createApplicantJob,
  createICIMSJob,
  results: createSelectJobSuggestion({
    candidate_suggested_job: [
      createICIMSJob({
        profile_id: 1,
        jobtitle: 'Senior Software Engineer',
        tech_stack: 'React, Redux, Database Modeling, ER Modeling, Next JS',
        skillByRequiredSkill1: null,
        skillByRequiredSkill2: null,
        skillByRequiredSkill3: null,
        icims_company: {
          numofemps: 0,
          organizations: [
            {
              id: 1,
              salesforce_customer: {
                industry: 'Business Services',
              },
              logo: 'https://static.prod.terminal.io/logos/sesolabor.svg',
              name: 'Sesolabor',
            },
          ],
        },
        about:
          'The Senior Full-Stack Engineer has end-to-end responsibility for projects of increasing complexity with very little oversight beyond high-level direction.',
        what_youll_do:
          'Work both independently and in collaboration with other team members on the design and development of an end-to-end product for launch.',
        what_you_bring: 'Minimum 5 years of professional development experience',
        icims_job_locations: [
          {
            icims_location: {
              value: 'Canada',
            },
          },
        ],
      }),
      createICIMSJob({
        profile_id: 2,
        jobtitle: 'Senior Software Engineer - Backend',
        tech_stack: 'Python/Golang, Micro services, Database Modeling, ER Modeling',
        skillByRequiredSkill1: null,
        skillByRequiredSkill2: null,
        skillByRequiredSkill3: null,
        icims_company: {
          numofemps: 0,
          organizations: [
            {
              id: 2,
              salesforce_customer: {
                industry: 'Business Services',
              },
              logo: 'https://static.prod.terminal.io/logos/retain.svg',
              name: 'Retain AI',
            },
          ],
        },
        about:
          'The Senior Full-Stack Engineer has end-to-end responsibility for projects of increasing complexity with very little oversight beyond high-level direction.',
        what_youll_do:
          'Work both independently and in collaboration with other team members on the design and development of an end-to-end product for launch.',
        what_you_bring: 'Minimum 5 years of professional development experience',
        icims_job_locations: [
          {
            icims_location: {
              value: 'Canada',
            },
          },
        ],
      }),
      createICIMSJob({
        profile_id: 3,
        jobtitle: 'Senior Software Engineer',
        tech_stack: 'Python, Ruby',
        skillByRequiredSkill1: null,
        skillByRequiredSkill2: null,
        skillByRequiredSkill3: null,
        icims_company: {
          numofemps: 0,
          organizations: [
            {
              id: 3,
              salesforce_customer: {
                industry: 'Business Services',
              },
              logo: 'https://static.prod.terminal.io/logos/handshake.svg',
              name: 'Handshake',
            },
          ],
        },
        about:
          'The Senior Full-Stack Engineer has end-to-end responsibility for projects of increasing complexity with very little oversight beyond high-level direction.',
        what_youll_do:
          'Work both independently and in collaboration with other team members on the design and development of an end-to-end product for launch.',
        what_you_bring: 'Minimum 5 years of professional development experience',
        icims_job_locations: [
          {
            icims_location: {
              value: 'Canada',
            },
          },
        ],
      }),
      createICIMSJob({
        profile_id: 4,
        jobtitle: 'Senior Software Engineer - Backend & Frontend',
        tech_stack: 'Angular, RxJS, Python, Ruby',
        skillByRequiredSkill1: null,
        skillByRequiredSkill2: null,
        skillByRequiredSkill3: null,
        icims_company: {
          numofemps: 0,
          organizations: [
            {
              id: 4,
              salesforce_customer: {
                industry: 'Business Services',
              },
              logo: 'https://static.prod.terminal.io/logos/goldinauctions.svg',
              name: 'Goldin Auctions',
            },
          ],
        },
        about:
          'The Senior Full-Stack Engineer has end-to-end responsibility for projects of increasing complexity with very little oversight beyond high-level direction.',
        what_youll_do:
          'Work both independently and in collaboration with other team members on the design and development of an end-to-end product for launch.',
        what_you_bring: 'Minimum 5 years of professional development experience',
        icims_job_locations: [
          {
            icims_location: {
              value: 'Canada',
            },
          },
        ],
      }),
    ],
    user_subscription: [],
    applicant_job: [],
    candidate_job: [],
  }),
};
