import { Box, CheckboxCard, Text, CheckboxGroup, Grid, Button } from '@terminal/design-system';
import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import type { ProfileProps } from '../../Profile.types';

export function YearsOfExperienceForm({
  showLoadingForSaveButton,
  showSaveButton,
  values,
  yearsOfExperienceChoices,
  onCheckboxClick,
  onSaveClick,
}: {
  showLoadingForSaveButton: boolean;
  showSaveButton: boolean;
  values: { yearsOfExperienceRange: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null };
  yearsOfExperienceChoices: ProfileProps['yearsOfExperienceRangeChoices'];
  onCheckboxClick: (years_of_exp_range: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum) => void;
  onSaveClick: () => void;
}) {
  return (
    <Box w="full">
      <CheckboxGroup>
        <Grid templateColumns="repeat(auto-fill, minmax(183px, 1fr))" gap={4}>
          {yearsOfExperienceChoices.map(({ name: choice, displayName }) => {
            const isSelected = values.yearsOfExperienceRange === choice;

            return (
              <CheckboxCard
                role="checkbox"
                aria-checked={isSelected}
                aria-labelledby="candidate-years-of-experience"
                onChange={() => onCheckboxClick(choice)}
                isChecked={isSelected}
                key={`insider-years-of-experience-${choice}`}
              >
                <Text>{displayName}</Text>
              </CheckboxCard>
            );
          })}
        </Grid>
      </CheckboxGroup>
      {showSaveButton && (
        <Button
          flex={1}
          variant="primary"
          type="submit"
          onClick={onSaveClick}
          isLoading={showLoadingForSaveButton}
          mt={6}
        >
          Save
        </Button>
      )}
    </Box>
  );
}
