import type { JobSuggestionProps } from 'candidate/shared/modules/job/data/JobSuggestions.types';
import {
  Heading,
  Box,
  VStack,
  Text,
  Button,
  Switch,
  Flex,
  HStack,
  Stack,
  Container,
} from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils/hooks';
import { HandpickedJobsBanner } from './HandpickedJobsBanner';
import { JobCardController } from './JobCard';

export function JobMatchesList({
  jobSuggestions,
  activeSuggestion,
  onJobDetailClick,
  notJobMatchesButtonBgColor,
  handleNoJobMatchesClick,
}: {
  activeSuggestion: JobSuggestionProps['id'];
  jobSuggestions?: JobSuggestionProps[];
  onJobDetailClick?: (job: JobSuggestionProps) => void;
  handleNoJobMatchesClick?: () => void;
  notJobMatchesButtonBgColor?: string;
}) {
  return (
    <Flex w="full" flexDir="column" h="full">
      <VStack
        align="flex-start"
        flex="1"
        overflowY="auto"
        overflowX="hidden"
        spacing={0}
        paddingBottom={6}
      >
        {jobSuggestions && !jobSuggestions?.length && (
          <Flex justifyContent="start" w="full">
            <Button
              alignItems="start"
              bgColor={notJobMatchesButtonBgColor}
              flexDirection="column"
              onClick={handleNoJobMatchesClick}
              paddingLeft={3}
              py={8}
              variant="unstyled"
              w="full"
            >
              <Heading variant="heading-4" as="p">
                No Job Matches
              </Heading>
            </Button>
          </Flex>
        )}
        {jobSuggestions?.map?.((job) => (
          <JobCardController
            jobData={job}
            jobSection="suggested-jobs"
            maxSkillsToDisplay={[2, 2]}
            onJobDetailClick={onJobDetailClick}
            shouldHighlight={activeSuggestion === job.id}
          />
        ))}
      </VStack>
    </Flex>
  );
}

export function JobMatches({
  activeSuggestion,
  jobSuggestions,
  onMatchAlertsChange,
  showIsSubscribedOn,
  notJobMatchesButtonBgColor,
  handleNoJobMatchesClick,
  header,
  jobSectionDetail,
  shouldNotWrapWithContainer = false,
  shouldShowHandpickedJobsBanner,
  onJobDetailClick,
}: {
  activeSuggestion: JobSuggestionProps['id'];
  jobSuggestions?: JobSuggestionProps[];
  handleNoJobMatchesClick?: () => void;
  onMatchAlertsChange?: () => void;
  showIsSubscribedOn?: boolean;
  notJobMatchesButtonBgColor?: string;
  jobSectionDetail?: React.ReactNode;
  header?: React.ReactNode;
  shouldNotWrapWithContainer?: boolean;
  shouldShowHandpickedJobsBanner: boolean;
  onJobDetailClick?: (job: JobSuggestionProps) => void;
}) {
  const isDesktop = useIsViewPortDesktop();

  const layout = (
    <>
      <Flex
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems={['flex-start', 'flex-start', 'center']}
      >
        {header}
      </Flex>
      {shouldShowHandpickedJobsBanner && <HandpickedJobsBanner mt={3} />}
      <Stack
        direction={['column', 'column', 'row']}
        align="flex-start"
        spacing={0}
        mt={[3, 3, 6]}
        /* Magic Number (177.5px) => NavHeight(80px) - ContainerPadding(24px) - HeadingHeight(49.5px) - HeadingMargin(24px) */
        h={['auto', 'auto', 'calc(100vh - 177.5px)']}
      >
        <Box w={['full', 'full', '25.625rem']}>
          {onMatchAlertsChange && (
            <HStack
              w="full"
              flexDir="row"
              justifyContent="space-between"
              p={3}
              bgColor="accent.darkest"
            >
              <Box>
                <Text variant="label" color="text.inverse">
                  Email Job Alerts
                </Text>
                <Text variant="caption" color="text.inverse">
                  Get weekly alerts for matching jobs
                </Text>
              </Box>
              <Switch
                onChange={onMatchAlertsChange}
                isChecked={showIsSubscribedOn}
                aria-label="job-alerts-toggle"
              />
            </HStack>
          )}
          <Box
            /* Magic Number (246.5px) => NavHeight(80px) - ContainerPadding(24px) - HeadingHeight(49.5px) - HeadingMargin(24px) - emailJobAlerts_height(69px) */
            h={['auto', 'auto', 'calc(100vh - 246.5px)']}
          >
            <Box h={jobSuggestions?.length === 0 && !isDesktop ? 'auto' : 'full'} w="full">
              <JobMatchesList
                notJobMatchesButtonBgColor={notJobMatchesButtonBgColor}
                handleNoJobMatchesClick={handleNoJobMatchesClick}
                jobSuggestions={jobSuggestions}
                onJobDetailClick={onJobDetailClick}
                activeSuggestion={activeSuggestion}
              />
            </Box>
          </Box>
        </Box>
        <Box flex="1" h="full" w={['full', 'full', 'auto']} overflowY="auto">
          {jobSectionDetail}
        </Box>
      </Stack>
    </>
  );

  return shouldNotWrapWithContainer ? (
    layout
  ) : (
    <Container flex="1" paddingTop={[4, 4, 6]}>
      {layout}
    </Container>
  );
}
