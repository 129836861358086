import { useEffect } from 'react';
import Sentry from 'global/sentry';
import { ActivityIndicator } from 'global/components';
import { useQueryString, useRedirection } from 'global/utils';
import { firebaseAuth } from 'global/firebaseApp';
import { signInAnonymously } from 'global/auth';
import { useCandidateAuth } from 'candidate/utils';
import { useMutation } from '@apollo/client';
import { RolesValidationSchema } from 'candidate/shared/modules/roles/useRolesController';
import { YearsValidationSchema } from 'candidate/shared/modules/years/useYearsController';
import { Candidate_Source_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import type {
  Role_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { UpsertDeleteCandidateRoles } from 'candidate/shared/modules/roles/data';
import type { OnboardingStep } from 'candidate/shared/modules/onboarding/Onboarding.types';
import type {
  UpsertDeleteCandidateRolesMutation,
  UpsertDeleteCandidateRolesMutationVariables,
} from 'candidate/shared/modules/roles/data';
import { UpdateCandidateOnboardingFlowData } from 'candidate/shared/modules/onboarding/data/graphql';
import type {
  UpdateCandidateOnboardingFlowDataMutation,
  UpdateCandidateOnboardingFlowDataMutationVariables,
} from 'candidate/shared/modules/onboarding/data/graphql';
import type {
  UpsertCandidateYearsOfExperienceRangeMutation,
  UpsertCandidateYearsOfExperienceRangeMutationVariables,
} from 'candidate/shared/modules/years/data/types/UpsertCandidateYearsOfExperienceRange.query.generated';
import { UpsertCandidateYearsOfExperienceRange } from 'candidate/shared/modules/years/data';

export function SetOnboardingController() {
  const auth = useCandidateAuth();
  const queryString = useQueryString();
  const redirectTo = useRedirection();

  const [updateCandidateOnboardingFlowData] = useMutation<
    UpdateCandidateOnboardingFlowDataMutation,
    UpdateCandidateOnboardingFlowDataMutationVariables
  >(UpdateCandidateOnboardingFlowData);

  const [upsertCandidateRoles] = useMutation<
    UpsertDeleteCandidateRolesMutation,
    UpsertDeleteCandidateRolesMutationVariables
  >(UpsertDeleteCandidateRoles);

  const [updateCandidateYearsOfExperience] = useMutation<
    UpsertCandidateYearsOfExperienceRangeMutation,
    UpsertCandidateYearsOfExperienceRangeMutationVariables
  >(UpsertCandidateYearsOfExperienceRange);

  useEffect(() => {
    if (
      !auth.isAuthenticated &&
      // To prevent anonymous sign in loop when auth fails. Chose 3 attempts to leave some
      // room for grace when auth fails
      auth.attemptCount <= 3
    ) {
      signInAnonymously({ auth: firebaseAuth, dispatch: auth.dispatch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (!auth.user?.firebase_uid) return;

    const role = queryString.get('role');
    const years = queryString.get('years');

    const updateUserInitialValues = async () => {
      let furthestStep: OnboardingStep | undefined;

      try {
        if (role && RolesValidationSchema.isValidSync({ candidateRoles: [{ role }] })) {
          await upsertCandidateRoles({
            variables: {
              upsert_objects: [
                {
                  candidate_id: auth.candidateID,
                  role: role as Role_Choices_Enum,
                  source: Candidate_Source_Choices_Enum.CandidatePortal,
                  years_of_exp: null,
                },
              ],
              delete_candidate_role_ids: [],
            },
          });

          furthestStep = 'add-roles';
        } else {
          // If not role or not valid we stop the setup here.
          redirectTo('/onboarding', { shouldReplacePath: true });

          return;
        }
      } catch (error) {
        Sentry.captureException(error);
        redirectTo('/onboarding', { shouldReplacePath: true });

        return;
      }

      try {
        if (
          furthestStep === 'add-roles' &&
          years &&
          YearsValidationSchema.isValidSync({ yearsOfExperienceRange: years })
        ) {
          await updateCandidateYearsOfExperience({
            variables: {
              candidate_id: auth.candidateID as number,
              years_of_exp_range: years as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
            },
          });

          furthestStep = 'years';
        }
      } catch (error) {
        Sentry.captureException(error);
      }

      try {
        await updateCandidateOnboardingFlowData({
          variables: {
            candidate_id: auth.candidateID as number,
            candidate_set_input: {
              profile_flow_started_at: new Date(),
              profile_flow_furthest_step: furthestStep,
            },
          },
        });
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        redirectTo('onboarding', { shouldReplacePath: true });
      }
    };

    updateUserInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user?.firebase_uid, auth.candidateID]);

  return <ActivityIndicator />;
}
