import { ErrorBoundary } from 'global/sentry';
import { ErrorFallback } from 'candidate/components';
import { useRedirection } from 'global/utils';
import { CompanyController } from './Company.controller';

export function CompanyRouter() {
  const redirectTo = useRedirection();

  return (
    <ErrorBoundary
      fallback={
        <ErrorFallback
          onActionClick={() => {
            redirectTo('/job/matches');
            window.location.reload();
          }}
          actionTitle="Return to Job Matches"
        />
      }
    >
      <CompanyController />
    </ErrorBoundary>
  );
}
