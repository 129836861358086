import { useEffect, useState } from 'react';
import {
  useToast,
  useClipboard,
  Link,
  Flex,
  Heading,
  Text,
  Button,
  Image,
} from '@terminal/design-system';
import pageNotFound404ImageSrc from 'candidate/assets/images/404-rebrand.svg?url';
import { Link as RouterLink } from 'react-router-dom';
import { PublicPageLayout } from 'candidate/components';

export function IncompatibleBrowserErrorPage() {
  const toast = useToast({
    status: 'success',
    duration: 4000,
  });

  const [isActionDisabled, setIsActionDisabled] = useState(false);
  const { hasCopied, onCopy } = useClipboard(`${window.location.href}&btm=webview`, 10000000);

  const handleOnActionClick = () => {
    onCopy();
    setIsActionDisabled(true);

    setTimeout(() => {
      setIsActionDisabled(false);
    }, 4000);
  };

  useEffect(() => {
    if (hasCopied && isActionDisabled) {
      toast({
        title: 'Link Copied!',
        description: 'Paste the link on a browser to continue signing up.',
      });
    }
    // toast is a static design system component and doesn't need to be included in dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCopied, isActionDisabled]);

  return (
    <PublicPageLayout
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link as={RouterLink} to={`/onboarding${window.location.search}`}>
            Sign Up
          </Link>
        </Flex>
      }
    >
      <Flex px={3} h="full" justifyContent="center" flexDir="column" alignItems="center">
        <Image src={pageNotFound404ImageSrc} maxW="175px" />
        <Heading
          mt={[6, 6, 0]}
          variant="heading-2"
          color="brand.main"
          textAlign={['center', 'center', 'left']}
        >
          Oops!
        </Heading>
        <Heading variant="heading-2" mt={2}>
          Something went wrong.
        </Heading>
        <Text textAlign={['center', 'center', 'left']} mt={2}>
          There was an error accessing this page. Please use the button below to copy the link and
          paste it on your browser to continue signing up.
        </Text>
        <Button onClick={handleOnActionClick} mt={6} w="full">
          Copy Link
        </Button>
      </Flex>
    </PublicPageLayout>
  );
}
