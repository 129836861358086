import { PageLayout, WideLeftNarrowRightLayout } from 'candidate/components';
import { JobCardController } from 'candidate/shared/modules';
import {
  AspectRatio,
  Accordion,
  Box,
  BuildingIcon,
  Button,
  Container,
  Divider,
  Flex,
  Image,
  LeadershipIcon,
  LinkedinIcon,
  LinkoutIcon,
  LocationIcon,
  Text,
  VStack,
  WebsiteIcon,
} from '@terminal/design-system';
import { CardWithViewMore } from 'global/components';
import { useIsViewPortDesktop } from 'global/utils';
import Illustration from './assets/company-illustration.svg?url';

function CompanyCard({
  headquarters,
  industry,
  leadership,
}: Pick<
  React.ComponentProps<typeof CompanyPage>['companyInfo'],
  'headquarters' | 'industry' | 'leadership'
>) {
  return (
    <VStack
      alignItems="start"
      bgColor="bg.secondary"
      mt={[0, 0, 6]}
      pl={8}
      pr={3}
      py={4}
      spacing={4}
      divider={<Divider borderColor="accent.lightest" mt={4} opacity="1" />}
    >
      {headquarters && (
        <Box w="full" mt={[0, 0, 2]}>
          <Flex justifyContent="flex-start">
            <LocationIcon fontSize="2xl" color="accent.lighter" mt={4} />
            <Box ml={4}>
              <Text variant="label">Headquarters</Text>
              <Text variant="body">{headquarters}</Text>
            </Box>
          </Flex>
        </Box>
      )}

      {industry && (
        <Box w="full">
          <Flex justifyContent="flex-start">
            <BuildingIcon fontSize="2xl" color="accent.lighter" mt={4} />
            <Box ml={4}>
              <Text variant="label">Industry</Text>
              <Text variant="body">{industry}</Text>
            </Box>
          </Flex>
        </Box>
      )}

      {leadership?.length && (
        <Box w="full">
          <Flex justifyContent="flex-start">
            <LeadershipIcon fontSize="2xl" color="accent.lighter" mt={4} />
            <Box ml={4}>
              <Text variant="label">Leadership</Text>
              {leadership?.map(({ name, title, linkedinURL }) => (
                <Flex alignItems="center" key={name} mt="2px">
                  <Text variant="body" className="notranslate">
                    {name}, {title}
                    {linkedinURL && (
                      <LinkoutIcon
                        fontSize="sm"
                        color="accent.lighter"
                        cursor="pointer"
                        onClick={() => window.open(linkedinURL, '_blank')}
                        ml={2}
                        mb={1}
                      />
                    )}
                  </Text>
                </Flex>
              ))}
            </Box>
          </Flex>
        </Box>
      )}
    </VStack>
  );
}

export function CompanyPage({
  aboutViewMore_onClick,
  candidateAvailability,
  companyInfo,
  cultureViewMore_onClick,
  isIllustrationOverlapping,
  onSignOutClick,
  shouldDisplay_fullAbout,
  shouldDisplay_fullCulture,
  userFullName,
  shouldNotWrapWithPageLayout = false,
}: {
  aboutViewMore_onClick: () => void;
  candidateAvailability: string | null;
  companyInfo: {
    about?: string;
    cultureAndValues?: string;
    headquarters: string;
    industry: string;
    jobs: {
      id: number;
      skills: string[];
      title: string | null;
      applied: boolean;
      salary: string | null;
      isTopApplicant: boolean;
    }[];
    leadership?: {
      linkedinURL: Maybe<string>;
      name: string;
      title: Maybe<string>;
    }[];
    linkedinURL?: string;
    squareLogoURL: string;
    logoURL: string;
    name: string | null;
    websiteURL?: string;
    videoTestimonials: {
      id: number;
      url: string;
    }[];
  };
  cultureViewMore_onClick: () => void;
  isIllustrationOverlapping: boolean;
  onSignOutClick: () => Promise<void>;
  shouldDisplay_fullAbout: boolean;
  shouldDisplay_fullCulture: boolean;
  userFullName: string;
  shouldNotWrapWithPageLayout?: boolean;
}) {
  const isDesktop = useIsViewPortDesktop();

  const content = (
    <>
      <Box backgroundColor="bg.tertiary">
        {companyInfo.logoURL && (
          <Container flex="1" py={4}>
            <Box w="full">
              <Image
                w="full"
                maxW="xs"
                h={16}
                src={companyInfo.logoURL}
                alt={`${companyInfo.name} company logo`}
                objectFit="contain"
                objectPosition="left"
              />
            </Box>
          </Container>
        )}
      </Box>

      <Container pt={[0, 0, 6]}>
        <WideLeftNarrowRightLayout
          left={
            <VStack
              divider={<Divider borderColor="ui.secondary" my={[6, 6, 10]} py={[2, 2, 0]} />}
              w="full"
            >
              {companyInfo.about && (
                <CardWithViewMore
                  header={
                    <Text color="text.disabled" fontWeight="bold">
                      ABOUT THE COMPANY
                    </Text>
                  }
                  descriptionVariant="body"
                  description={companyInfo.about}
                  onViewMoreClick={aboutViewMore_onClick}
                  isDesktop={isDesktop as boolean}
                  shouldShowFullBody={shouldDisplay_fullAbout}
                />
              )}

              {companyInfo.cultureAndValues && (
                <CardWithViewMore
                  header={
                    <Text color="text.disabled" fontWeight="bold" mt={[4, 4, 0]}>
                      CULTURE & VALUES
                    </Text>
                  }
                  descriptionVariant="body"
                  description={companyInfo.cultureAndValues}
                  onViewMoreClick={cultureViewMore_onClick}
                  isDesktop={isDesktop as boolean}
                  shouldShowFullBody={shouldDisplay_fullCulture}
                />
              )}

              {!isDesktop && !!companyInfo.videoTestimonials?.length && (
                <Box w="full" mb="6">
                  <Text color="text.disabled" fontWeight="bold" mt={6}>
                    TESTIMONIALS
                  </Text>
                  {companyInfo.videoTestimonials?.map((video) => (
                    <AspectRatio
                      w="full"
                      mt={2}
                      ratio={16 / 9}
                      key={`${companyInfo.name}-testimonial-${video.id}`}
                    >
                      <iframe
                        data-chromatic="ignore"
                        width="100%"
                        src={video.url}
                        title={`${companyInfo.name} testimonial video`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </AspectRatio>
                  ))}
                </Box>
              )}

              {companyInfo.jobs?.length && (
                <Box w="full">
                  <Text color="text.disabled" fontWeight="bold" mt={[4, 4, 0]} mb={2}>
                    ALL OPPORTUNITIES
                  </Text>

                  {companyInfo.jobs.map(
                    ({ skills, id, title, applied, salary, isTopApplicant }) => (
                      <JobCardController
                        jobData={{
                          id,
                          title: companyInfo.name,
                          subtitle: title,
                          image: {
                            src: companyInfo.squareLogoURL,
                            alt: `${companyInfo.name} square logo`,
                          },
                          skills,
                          applied,
                          salary,
                          isTopApplicant,
                        }}
                      />
                    ),
                  )}
                </Box>
              )}
            </VStack>
          }
          right={
            <Box w="full">
              <Flex justifyContent="space-between" flexDir="row" mt={0} gridGap={4}>
                {companyInfo.linkedinURL && (
                  <Button
                    variant="outline"
                    colorScheme="accent"
                    leftIcon={<LinkedinIcon />}
                    onClick={() => window.open(companyInfo.linkedinURL, '_blank')}
                    w="full"
                  >
                    LinkedIn
                  </Button>
                )}

                {companyInfo.websiteURL && (
                  <Button
                    variant="outline"
                    colorScheme="accent"
                    leftIcon={<WebsiteIcon w={6} h={6} mt={2} />}
                    onClick={() => window.open(companyInfo.websiteURL, '_blank')}
                    w="full"
                  >
                    Website
                  </Button>
                )}
              </Flex>
              {!isDesktop && (
                <Box mt={6} mb={6}>
                  <Accordion
                    headingVariant="heading-4"
                    arrowColor="accent.darkest"
                    accordions={[
                      {
                        title: `More about ${companyInfo.name}`,
                        children: (
                          <CompanyCard
                            industry={companyInfo.industry}
                            headquarters={companyInfo.headquarters}
                            leadership={companyInfo.leadership}
                          />
                        ),
                      },
                    ]}
                    backgroundColor="accent.lighter"
                    px={3}
                    py={4}
                  />
                </Box>
              )}

              {isDesktop && (
                <Box>
                  {(companyInfo.industry || companyInfo.headquarters || companyInfo.leadership) && (
                    <Box id="companyCard" zIndex="docked" position="relative">
                      <CompanyCard
                        industry={companyInfo.industry}
                        headquarters={companyInfo.headquarters}
                        leadership={companyInfo.leadership}
                      />
                    </Box>
                  )}
                  {!companyInfo.videoTestimonials?.length ? (
                    <Flex
                      position={isIllustrationOverlapping ? 'absolute' : 'fixed'}
                      bottom={0}
                      ml={21}
                      id="illustration"
                      height={64}
                      zIndex="base"
                      visibility={
                        isIllustrationOverlapping && !companyInfo.jobs?.length
                          ? 'hidden'
                          : 'visible'
                      }
                    >
                      <Image src={Illustration} />
                    </Flex>
                  ) : (
                    <>
                      <Text color="text.disabled" fontWeight="bold" mt={6}>
                        TESTIMONIALS
                      </Text>
                      {companyInfo.videoTestimonials?.map((video) => (
                        <AspectRatio
                          w="full"
                          mt={2}
                          ratio={16 / 9}
                          key={`${companyInfo.name}-testimonial-${video.id}`}
                        >
                          <iframe
                            data-chromatic="ignore"
                            width="100%"
                            src={video.url}
                            title={`${companyInfo.name} testimonial video`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </AspectRatio>
                      ))}
                    </>
                  )}
                </Box>
              )}
            </Box>
          }
          rightContainerProps={{ ml: [0, 0, 8], mt: [4, 4, 0] }}
          flexProps={{ flexDir: ['column-reverse', 'column-reverse', 'row'] }}
        />
      </Container>
    </>
  );

  return shouldNotWrapWithPageLayout ? (
    <Flex flexDir="column" bg="bg.primary" as="main" role="main" flex={1} maxW="100vw">
      {content}
    </Flex>
  ) : (
    <PageLayout.Variation.WithAllSections
      candidateAvailability={candidateAvailability}
      shouldNotWrapWithContainer
      onSignOutClick={onSignOutClick}
      userFullName={userFullName}
    >
      {content}
    </PageLayout.Variation.WithAllSections>
  );
}
