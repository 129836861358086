import type { HStepsProps } from 'candidate/components';
import { useYearsController } from 'candidate/shared/modules/years';
import type { ProfileProps } from '../../../../Profile.types';
import { YearsStep } from './YearsStep';

export function YearsController({
  candidateID,
  candidateYearsOfExperienceRange,
  userID,
  onSignOutClick,
  onStepCompleteSuccess,
  progressProps,
  yearsOfExperienceRangeChoices,
  userFullName,
}: {
  candidateID: number;
  candidateYearsOfExperienceRange: ProfileProps['candidate']['yearsOfExperienceRange'];
  onSignOutClick: () => Promise<void>;
  onStepCompleteSuccess: () => void;
  userID?: number;
  progressProps: HStepsProps;
  yearsOfExperienceRangeChoices: ProfileProps['yearsOfExperienceRangeChoices'];
  userFullName: string;
}) {
  const { formik, handleOnCheckboxClick, isLoading_upsert } = useYearsController({
    candidateYearsOfExperienceRange,
    candidateID,
    yearsOfExperienceRangeChoices,
    userID,
    onSaveSuccess: onStepCompleteSuccess,
  });

  return (
    <YearsStep
      onSignOutClick={onSignOutClick}
      selectedYearsOfExperienceRange={formik.values.yearsOfExperienceRange}
      yearsOfExperienceRangeChoices={yearsOfExperienceRangeChoices}
      onCheckboxClick={handleOnCheckboxClick}
      onNextClick={formik.handleSubmit}
      isNextDisabled={isLoading_upsert || !formik.values.yearsOfExperienceRange}
      userFullName={userFullName}
      progressProps={progressProps}
    />
  );
}
