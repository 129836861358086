import * as events from 'global/events';
import moment from 'moment';
import type { ProfileModalName, SectionName } from './Profile.types';
import { AllModals } from './Profile.types';

export function toDatesTitle({
  startDate,
  endDate,
  isCurrent,
  format,
}: {
  startDate?: string | null;
  endDate?: string | null;
  isCurrent: boolean;
  format: string;
}) {
  if (startDate && isCurrent) {
    return `${moment(startDate).format(format)} - Current`;
  }

  if (startDate && endDate) {
    return `${moment(startDate).format(format)} - ${moment(endDate).format(format)}`;
  }

  if (startDate && !endDate) {
    return `${moment(startDate).format(format)} - Unknown`;
  }

  if (!startDate && endDate) {
    return isCurrent ? 'Current' : `Unknown - ${moment(endDate).format(format)}`;
  }

  return isCurrent ? 'Current' : null;
}

export function objectsEmptyValuesToNull(values: { [key: string]: any }): { [key: string]: any } {
  const newValues: { [key: string]: any } = { ...values };
  Object.keys(newValues).forEach((prop) => {
    if (newValues[prop] == null) {
      newValues[prop] = null;
    } else if (typeof newValues[prop] === 'string' || Array.isArray(newValues[prop])) {
      newValues[prop] = newValues[prop] && newValues[prop].length > 0 ? newValues[prop] : null;
    }
  });

  return newValues;
}

const ProfileEvent = {
  'entry-edited': events.name.editProfile.editedEntry,
  'entry-added': events.name.editProfile.addedEntry,
  'entry-deleted': events.name.editProfile.deletedEntry,
  'clicked-add-entry': events.name.editProfile.clickedToAddFirstEntry,
  'edit-modal-opened': events.name.editProfile.openedEditModal,
};

export function trackProfile(
  eventNameKey: keyof typeof ProfileEvent,
  params: {
    section?: SectionName;
    context?: 'Progress List' | 'Card' | 'Personalized Complete Profile Action';
  } & { [keyof: string]: any },
) {
  events.track(ProfileEvent[eventNameKey], params);
}

export const isValidSectionName = (value: string): boolean =>
  AllModals.includes(value as ProfileModalName);
