import { useMutation } from '@apollo/client';
import Sentry from 'global/sentry';
import { useToast } from '@terminal/design-system';
import { useState } from 'react';
import { useFormik } from 'formik';
import type { InputMaybe, Scalars } from 'global/types/hasura-tables.generated.types';
import { Candidate_Source_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { SelectProfile } from 'candidate/features/profile/graphql';
import type { SelectProfileQuery } from 'candidate/features/profile/Profile.types';
import type { SkillsProps } from 'candidate/shared/modules';
import { trackProfile } from 'candidate/features/profile/Profile.utils';
import { UpsertDeleteCandidateSkills } from './data';
import type {
  UpsertDeleteCandidateSkillsMutation,
  UpsertDeleteCandidateSkillsMutationVariables,
} from './data';
import {
  skillsForm_validationSchema,
  skillsForm_withoutYoE_validationSchema,
} from './SkillsForm.validation';

export function useSkillsController({
  skillWithYearsOfExperience = true,
  skillGroups,
  skillChoices,
  userID,
  candidateID,
  onModalClose,
  onModalOpen,
  onSaveSuccess,
  shouldUseValidation = true,
  defaultEmptyRows = 3,
}: {
  skillWithYearsOfExperience?: boolean;
  skillGroups: SkillsProps['skillGroups'];
  skillChoices: SkillsProps['skillChoices'];
  candidateID: number;
  userID?: number;
  onModalClose?: () => void;
  onModalOpen?: () => void;
  onSaveSuccess?: () => void;
  shouldUseValidation?: boolean;
  defaultEmptyRows?: number;
}) {
  // Multiplied by -1 to be a negative number to difference with database ID's of Skills
  const [newSkillID, setNewSkillID] = useState<number>((1 + defaultEmptyRows) * -1);

  const allSkills = skillGroups.flatMap(({ skills }) => skills.map(({ formValues }) => formValues));
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });
  const [upsert, { loading: isLoading_upsert }] = useMutation<
    UpsertDeleteCandidateSkillsMutation,
    UpsertDeleteCandidateSkillsMutationVariables
  >(UpsertDeleteCandidateSkills, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to update your skills. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
    onCompleted: (upsertDeleteSkills) => {
      if (onModalClose) {
        onModalClose();
      }
      if (skillGroups.length) {
        trackProfile('entry-edited', { section: 'Skills' });
      } else {
        trackProfile('entry-added', { section: 'Skills' });
      }
      if (upsertDeleteSkills?.delete_candidate_skill?.returning.length) {
        trackProfile('entry-deleted', { section: 'Skills' });
      }
      if (onSaveSuccess) {
        onSaveSuccess();
      }
    },
    update(cache, { data: upsertDeleteSkills }) {
      try {
        const readData = cache.readQuery<SelectProfileQuery>({
          query: SelectProfile,
          variables: {
            user_id: userID,
          },
        });

        const newCandidateSkills = (
          upsertDeleteSkills?.insert_candidate_skill?.returning || []
        ).filter((skill) =>
          upsertDeleteSkills?.delete_candidate_skill?.returning.every(({ id }) => skill.id !== id),
        );

        const updatedCandidate = {
          ...readData?.candidates[0],
          candidate_skills: newCandidateSkills,
        };

        cache.writeQuery({
          query: SelectProfile,
          broadcast: true,
          variables: {
            user_id: userID as number,
          },
          data: { ...readData, candidates: [updatedCandidate] },
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  // Since the form needs to have at least 3 skills, when candidate has no or less than 3 skills we
  // fill the rest of the 3 with empty skills from rows.
  const initialSkills =
    allSkills.length >= defaultEmptyRows
      ? allSkills
      : [
          ...allSkills,
          ...[...Array(defaultEmptyRows - allSkills.length)].map((_, index) => ({
            candidateSkillID: `-${index + 1}`,
            skillID: 0,
            name: '',
            years_of_exp: '',
            competency: null,
            is_favorite: false,
          })),
        ];

  const validationSchema = () => {
    if (shouldUseValidation) {
      return skillWithYearsOfExperience
        ? skillsForm_validationSchema
        : skillsForm_withoutYoE_validationSchema;
    }
    return null;
  };

  const formik = useFormik<{
    candidateSkills: SkillsProps['skillGroups'][number]['skills'][number]['formValues'][];
  }>({
    initialValues: {
      candidateSkills: initialSkills,
    },
    validationSchema: validationSchema(),
    onSubmit: (newSkillsValues) => {
      const deleteValues = allSkills
        .filter((skill) =>
          newSkillsValues.candidateSkills.every(
            (newValue) => newValue.candidateSkillID !== skill.candidateSkillID,
          ),
        )
        .map((skill) => Number(skill.candidateSkillID));

      upsert({
        variables: {
          upsert_objects: newSkillsValues.candidateSkills.map((newSkillValues) => ({
            candidate_id: candidateID,
            competency: newSkillValues.competency,
            skill_id: newSkillValues.skillID,
            years_of_exp: skillWithYearsOfExperience
              ? (newSkillValues.years_of_exp as InputMaybe<Scalars['Int']>)
              : undefined,
            is_favorite: newSkillValues.is_favorite,
            source: Candidate_Source_Choices_Enum.CandidatePortal,
            ...(newSkillValues.candidateSkillID &&
              Number(newSkillValues.candidateSkillID) > 0 && {
                id: Number(newSkillValues.candidateSkillID),
              }),
          })),
          delete_candidate_skill_ids: deleteValues,
        },
      });
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const shouldShowDeleteColumn = formik.values.candidateSkills.length > defaultEmptyRows;

  const hasFormValuesChanged = () =>
    JSON.stringify(formik.initialValues) !== JSON.stringify(formik.values);

  const handleOnClose = () => {
    formik.resetForm();
    if (onModalClose) {
      onModalClose();
    }
    formik.resetForm();
  };

  const handleOnSaveClick = ({
    allowingSavingWithoutValueChange = false,
  }: { allowingSavingWithoutValueChange?: boolean } = {}) => {
    if (allowingSavingWithoutValueChange || hasFormValuesChanged()) {
      formik.handleSubmit();
    } else {
      if (onModalClose) {
        onModalClose();
      }
      formik.resetForm();
    }
  };

  const handleAddClick = () => {
    formik.resetForm();
    if (onModalOpen) {
      onModalOpen();
    }
    if (!skillGroups.length) {
      trackProfile('clicked-add-entry', { section: 'Skills', context: 'Card' });
    }
    trackProfile('edit-modal-opened', { section: 'Skills' });
  };

  const handleSelectSkill = (skillName: string, fieldIndex: number) => {
    const skillID = skillChoices.find((skillChoice) => skillChoice.name === skillName)?.id || 0;

    formik.setValues({
      candidateSkills: formik.values.candidateSkills.map((candidateSkill, index) => {
        return fieldIndex === index
          ? {
              ...candidateSkill,
              skillID,
              name: skillName,
            }
          : candidateSkill;
      }),
    });
  };

  const handleAddSkillClick = () => {
    formik.setValues({
      candidateSkills: [
        ...formik.values.candidateSkills,
        {
          candidateSkillID: newSkillID.toString(),
          skillID: 0,
          name: '',
          years_of_exp: '',
          competency: null,
          is_favorite: false,
        },
      ],
    });

    setNewSkillID(newSkillID - 1);
  };

  const handleDeleteSkill = (deleteID: string) => {
    const skillsMinusDeletedOne = {
      candidateSkills: formik.values.candidateSkills.filter(
        ({ candidateSkillID }) => candidateSkillID !== deleteID,
      ),
    };
    formik.setValues(skillsMinusDeletedOne);
  };

  const skillAvailableOption = skillChoices.filter((skill) =>
    // if the skill id was in the candidate skill, don't show it (return false)
    {
      return !formik.values.candidateSkills.some(
        (candidateSkill) => candidateSkill.skillID === skill.id,
      );
    },
  );

  return {
    formik,
    handleAddClick,
    handleAddSkillClick,
    handleSelectSkill,
    handleDeleteSkill,
    handleOnClose,
    handleOnSaveClick,
    hasFormValuesChanged,
    shouldShowDeleteColumn,
    isLoading_upsert,
    skillAvailableOption,
  };
}
