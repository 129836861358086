import moment from 'moment';
import { serializeForAssessments } from 'candidate/shared/data-layer/SelectAssessments';
import { serializeSkills } from 'candidate/shared/modules';
import {
  displayRolesNames,
  displayRolesOrder,
  serializerRole,
} from 'candidate/shared/modules/roles/data';
import { availabilityCTAsDictionary } from 'candidate/shared';
import type { Candidate_Availability_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { Role_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { serializeProfileCompletion } from 'candidate/shared/modules/profile/Profile.serializer';
import { serializerYearsOfExperienceRange } from 'candidate/shared/modules/years';
import { serializeForPersonalInfo } from '../sections';
import type { SelectProfileQuery } from '../graphql/types';
import type { ProfileProps } from '../Profile.types';
import { serializerWorkExperiences } from '../modules/work-experiences';
import { degreeDictionary, serializerEducations } from '../modules/educations';

export function serializeForProfile(
  data: SelectProfileQuery,
  resumeUpdatedDate?: string,
): ProfileProps {
  const {
    candidates,
    role_choices,
    candidate_curation_years_of_exp_range_choices,
    assessments: assessmentsData,
    skills,
    candidate_skill_comp_choices,
  } = data;

  if (!data.candidates.length) {
    throw new Error(`Candidate not found in database`);
  }

  const candidate = candidates[0] as SelectProfileQuery['candidates'][number];

  const { assessments } = serializeForAssessments({
    assessments: assessmentsData,
  });
  const withHttpUrl = (url: string) => (url.startsWith('http') ? url : `//${url}`);

  const personalInfo = serializeForPersonalInfo(candidate);
  const roles = serializerRole(candidate.candidate_roles);
  const socialProfileLinks = {
    linkedin_url: candidate.linkedin_url ? withHttpUrl(candidate.linkedin_url) : null,
    github_url: candidate.github_url ? withHttpUrl(candidate.github_url) : null,
    other_url: candidate.other_url ? withHttpUrl(candidate.other_url) : null,
  };
  const workExperiences = serializerWorkExperiences(candidate.candidate_work_experiences);
  const educations = serializerEducations(candidate.candidate_educations);
  const skillGroups = serializeSkills(candidate.candidate_skills);
  const yearsOfExperienceRangeChoices = serializerYearsOfExperienceRange(
    candidate_curation_years_of_exp_range_choices,
  );
  const { sectionsCompletionStatus, completionPercentage } = serializeProfileCompletion({
    fullName: personalInfo.fullName,
    availability: candidate.availability,
    location: personalInfo.location,
    email: candidate.email,
    roles,
    socialProfileLinks,
    workExperiences,
    educations,
    skillGroups,
    resumeFilename: candidate.resume_filename,
    desiredSalaryAmount: candidate.candidate_curation_detail?.desired_salary_amount,
  });

  return {
    candidate: {
      id: candidate.id,
      roles,
      yearsOfExperienceRange: candidate.candidate_curation_detail?.years_of_exp_range || null,
      personalInfo,
      skillGroups,
      assessments: assessments
        .filter((assessment) => assessment.status === 'Passed')
        .map(({ name, status, cardProps: { footerNote } }) => ({
          name,
          footerNote,
          status,
        })),
      workExperiences,
      educations,
      socialProfile: {
        formValues: {
          linkedin_url: candidate.linkedin_url || null,
          github_url: candidate.github_url || null,
          other_url: candidate.other_url || null,
        },
        links: socialProfileLinks,
      },
      resume: {
        fileName: candidate.resume_filename,
        publicID: candidate.public_id?.toString() ?? null,
        updatedAt: resumeUpdatedDate ? moment(resumeUpdatedDate).format('MMM D, YYYY') : null,
        formValues: { file: null },
      },
      desiredSalary: {
        formattedSalary: candidate.candidate_curation_detail?.desired_salary_amount
          ? `$ ${Number(
              candidate.candidate_curation_detail?.desired_salary_amount,
            ).toLocaleString()}.00 ${candidate.candidate_curation_detail?.desired_salary_currency}`
          : null,
        formValues: {
          desiredSalaryAmount: candidate.candidate_curation_detail?.desired_salary_amount || null,
          desiredSalaryCurrency:
            candidate.candidate_curation_detail?.desired_salary_currency || null,
        },
      },
      hasParsedResume: candidate.parsed_resume != null,
      profileFlowFurthestStep: candidate.profile_flow_furthest_step,
      profileFlowStartedAt: candidate.profile_flow_started_at,
      profileFlowVariations: candidate.profile_flow_variations,
      hasOptionalSkills: !roles.some(
        ({ role: candidateRole }) =>
          ![Role_Choices_Enum.Des, Role_Choices_Enum.Prm, Role_Choices_Enum.Scm].includes(
            candidateRole.name,
          ),
      ),
    },
    roleChoices: role_choices
      .slice()
      .sort((a, b) => {
        if (Number(displayRolesOrder[a.value]) === Number(displayRolesOrder[b.value])) return 0;

        return Number(displayRolesOrder[a.value]) < Number(displayRolesOrder[b.value]) ? -1 : 1;
      })
      .map(({ value }) => ({
        name: value as Role_Choices_Enum,
        displayName: displayRolesNames[value] || '',
      })),
    yearsOfExperienceRangeChoices,
    degreeChoices: degreeDictionary,
    availabilityOptions: Object.keys(availabilityCTAsDictionary).reduce<{
      [key: string]: string;
    }>((availabilityOptions: ProfileProps['availabilityOptions'], key) => {
      return {
        ...availabilityOptions,
        [key]: availabilityCTAsDictionary[key as Candidate_Availability_Choices_Enum].short,
      };
    }, {}),
    sectionsCompletionStatus,
    completionPercentage,
    skillChoices: skills
      .slice()
      .sort((a, b) => a.name.replace(/\W/g, '').localeCompare(b.name.replace(/\W/g, ''))),
    skillChoicesByRole: skills
      .filter((skill) =>
        skill.roles.some(
          ({ role }) => !!roles.find(({ role: candidateRole }) => candidateRole.name === role),
        ),
      )
      .sort((a, b) => a.name.replace(/\W/g, '').localeCompare(b.name.replace(/\W/g, ''))),
    skillCompetencyChoices: candidate_skill_comp_choices.map(({ value }) => value),
  };
}
