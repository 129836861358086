import type { ComponentProps } from 'react';
import { useCandidateAuth } from 'candidate/utils';
import { useRouteMatch } from 'react-router-dom';
import { firebaseAuth } from 'global/firebaseApp';
import { useSignUp, googleSignIn } from 'global/auth';
import { Success } from '../../components';
import { NoAccountCreatedSuccess } from './NoAccountCreatedSuccess';

export function NoAccountCreatedSuccessController({
  companyName,
  position,
  firstName,
  lastName,
  email,
}: { companyName: string; position: string; firstName: string; lastName: string } & Omit<
  ComponentProps<typeof NoAccountCreatedSuccess>,
  'googleSignIn' | 'handleSignUpSubmit' | 'isLoading' | 'shouldHideGoogleSSO'
>) {
  const auth = useCandidateAuth();
  const { url } = useRouteMatch();

  const { handleSignUpSubmit, isLoading, shouldHideGoogleSSO } = useSignUp({
    continueURL: `${window.location.origin}${url}`,
  });

  return (
    <Success
      companyName={companyName}
      position={position}
      applicantFirstName={firstName || ''}
      header={
        <NoAccountCreatedSuccess
          googleSignIn={() =>
            googleSignIn(
              { auth: firebaseAuth, dispatch: auth.dispatch },
              { forceWithoutLinking: true },
            )
          }
          handleSignUpSubmit={(_, password) => {
            handleSignUpSubmit({ email, password, firstName, lastName });
          }}
          isLoading={isLoading}
          shouldHideGoogleSSO={shouldHideGoogleSSO}
          email={email || ''}
        />
      }
    />
  );
}
