import { IconButton } from '@chakra-ui/react';
import { Heading, Box, Collapse, Divider, Flex, Image, EditIcon } from '@terminal/design-system';
import type { ComponentProps, MouseEventHandler } from 'react';
import CheckCircleIcon from '../assets/ic-check-circle.svg?url';
import type { Section } from '../DirectApplication.types';

function ApplicationSectionBoxBase({
  isCompleted,
  isOpened,
  title,
  children,
  headingLeftComponent,
  headingRightComponent,
  spaceAboveDivider = 3,
}: {
  isCompleted: boolean;
  isOpened: boolean;
  children: React.ReactNode;
  title: string;
  headingLeftComponent: React.ReactNode;
  headingRightComponent: React.ReactNode;
  spaceAboveDivider?: number;
}) {
  return (
    <Box
      mt="6"
      border="1px solid"
      borderRadius="base"
      width="full"
      py="6"
      px={[3, 3, 8]}
      borderColor={isOpened ? 'ui.primary' : 'ui.secondary'}
      textAlign="left"
      backgroundColor={isCompleted && !isOpened ? 'ui.inverse.secondary' : 'white'}
    >
      <Flex flexDirection="row" alignItems="center" color="primary">
        {headingLeftComponent}
        <Heading variant="heading-3" flexGrow={1}>
          {title}
        </Heading>
        {headingRightComponent}
      </Flex>
      <Collapse in={isOpened}>
        <Divider orientation="horizontal" flex="1" mt={spaceAboveDivider} w="full" />
        {children}
      </Collapse>
    </Box>
  );
}

export function ApplicationSectionBox({
  section,
  onSectionBoxEditClick,
  headingRightComponent,
  ...props
}: {
  onSectionBoxEditClick: (index: number) => MouseEventHandler<HTMLButtonElement>;
  section: Section;
  headingRightComponent?: React.ReactNode;
} & Omit<
  ComponentProps<typeof ApplicationSectionBoxBase>,
  'switchComponent' | 'headingRightComponent' | 'headingLeftComponent'
>) {
  return (
    <ApplicationSectionBoxBase
      {...props}
      headingLeftComponent={
        props.isCompleted ? <Image src={CheckCircleIcon} marginRight={4} /> : null
      }
      headingRightComponent={
        headingRightComponent ||
        (props.isCompleted && !props.isOpened ? (
          <IconButton
            _hover={{ backgroundColor: 'initial' }}
            _focus={{ boxShadow: 'none' }}
            variant="ghost"
            onClick={onSectionBoxEditClick(section)}
            aria-label="Edit Section"
            icon={<EditIcon color="accent.main" />}
          />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        ))
      }
    />
  );
}
