import React, { useEffect, useRef, useState } from 'react';
import Sentry from 'global/sentry';
import * as events from 'global/events';
import { SwitchWithPageNotFound, CustomRoute } from 'candidate/components';
import type { HStepsProps } from 'candidate/components';
import { appState, useCandidateAuth, useExperiment, triggerGoal } from 'candidate/utils';
import type { Experiment } from 'candidate/utils';
import { useQuery, useRedirection } from 'global/utils';
import { useAttachDriveScripts } from 'global/hooks';
import {
  Redirect,
  Route,
  useLocation,
  useRouteMatch,
  matchPath,
  useHistory,
} from 'react-router-dom';
import { useApolloClient, useMutation, useReactiveVar } from '@apollo/client';
import { useToast } from '@terminal/design-system';
import { firebaseAuth, createStorageRef, getMetadata } from 'global/firebaseApp';
import { signOut, signInAnonymously } from 'global/auth';
// The reason we check this from regex is that in the desktop when user open the browser in smaller
// view port sizes, we still want to give them the option of parsing their resume.
import { isMobile as isMobileByRegex } from 'react-device-detect';
import { ActivityIndicator } from 'global/components';
import type { TrackingParams } from 'candidate/utils/state/trackingParams.state';
import { SelectCandidateInsiderInfo } from 'candidate/shared/data-layer/SelectCandidateInsiderInfo';
import { updateUserTempBlob, selectUserTempBlob } from 'candidate/shared/data-layer/userTempBlob';
import type { SelectCandidateInsiderInfoQuery } from 'candidate/shared/data-layer/SelectCandidateInsiderInfo';
import type { OnboardingStep } from 'candidate/shared/modules/onboarding/Onboarding.types';
import {
  InsertApplicantConsent,
  UpdateCandidateOnboardingFlowData,
  UpdateCandidateProfileFlowFurthestStep,
} from 'candidate/shared/modules/onboarding/data/graphql';
import type {
  InsertApplicantConsentMutation,
  InsertApplicantConsentMutationVariables,
  UpdateCandidateOnboardingFlowDataMutation,
  UpdateCandidateOnboardingFlowDataMutationVariables,
  UpdateCandidateProfileFlowFurthestStepMutation,
  UpdateCandidateProfileFlowFurthestStepMutationVariables,
} from 'candidate/shared/modules/onboarding/data/graphql';
import { SelectProfile } from '../../graphql';
import type { SelectProfileQuery, SelectProfileQueryVariables } from '../../graphql/types';
import { serializeForProfile } from '../../data';
import {
  RolesController,
  AvailabilityController,
  AddLocationStepController,
  MoreInfoStepController,
  ResumeParsingStepRouter,
  ExperiencesStepRouter,
  HowItWorks,
  SkillsStepController,
  SalaryController,
  SignUpRouter,
  YearsController,
} from './steps';

export type OnboardingForkDecision = { resumeParsing?: 'resume-parsed' | 'manual-entry' | null };

/**
 * Creates routes base on route pathname, candidates furthest completed step and current
 * decision on a paths fork.
 *
 */
export function createOnboardingSteps({
  isDesktop,
  currentForkDecision,
  currentStepPath,
  profileFlowFurthestStep,
  isAfterInitialForcedRedirect,
  onboardingVariation,
}: {
  isDesktop: boolean;
  currentForkDecision?: OnboardingForkDecision;
  currentStepPath: string;
  profileFlowFurthestStep?: OnboardingStep | null;
  isAfterInitialForcedRedirect: boolean;
  onboardingVariation: Experiment;
}): OnboardingStep[] {
  const inAllFlows_initialRoutes: OnboardingStep[] = [
    ...(['beginning-solo', 'beginning-pre-sign-up'].includes(onboardingVariation.signUp)
      ? ['sign-up' as OnboardingStep]
      : []),
    ...(onboardingVariation.signUp === 'end-pre-sign-up' ? ['start' as OnboardingStep] : []),
    'add-roles',
    'years',
    'add-availability',
    'add-salary',
  ];

  const resumeParsingFlow_EndingRoutes: OnboardingStep[] = [
    'import-resume',
    ...(['end-solo', 'end-pre-sign-up', 'end-blurred-info'].includes(onboardingVariation.signUp)
      ? ['sign-up' as OnboardingStep]
      : []),
  ];

  const manualEntryFlow_endingRoutes: OnboardingStep[] = [
    'add-location',
    'add-skills',
    'add-experience',
    ...(['end-solo', 'end-pre-sign-up', 'end-blurred-info'].includes(onboardingVariation.signUp)
      ? ['sign-up' as OnboardingStep]
      : []),
  ];

  if (isDesktop) {
    // Because sign up can be at the end of both manual-entry and resume-parsing flow, the isCurrentRoute_afterResumeFork
    // and isFurthestStep_afterResumeFork can have an incorrect value when pathname is /sign-up and sign up is the last step.
    const isNotSignUpRelatedStep = (step: OnboardingStep) => !['sign-up', 'start'].includes(step);

    const isCurrentRoute_afterResumeFork = manualEntryFlow_endingRoutes
      .filter(isNotSignUpRelatedStep)
      .some((step) => currentStepPath.includes(step));

    const isFurthestStep_afterResumeFork = [
      ...manualEntryFlow_endingRoutes,
      ...(currentForkDecision?.resumeParsing === 'resume-parsed'
        ? []
        : ['import-resume' as OnboardingStep]),
    ]
      .filter(isNotSignUpRelatedStep)
      .some((step) => profileFlowFurthestStep?.includes(step));

    if (
      // When the decision of Manual entry path has made on step completion, the manual entry
      // path is always returned regardless of other factors.
      currentForkDecision?.resumeParsing === 'manual-entry' ||
      // Supports the scenario when a user returns to onboarding while their furthest step is a step
      // after resume-parsing choice. Only should be considered when the force redirect to that step has not
      // occurred yet. Otherwise, the logic of step will not update correctly with browser navigation
      // in certain scenarios.
      (!isAfterInitialForcedRedirect && isFurthestStep_afterResumeFork) ||
      // Instead of persisting user choices, this logic derives what the user choice has been from the route
      // they are currently on. It also Supports the scenario when user via browse navigation, navigates back to before resume-parsing choice
      // then navigates forward to after that
      isCurrentRoute_afterResumeFork ||
      (currentStepPath.includes('sign-up') &&
        ['end-solo', 'end-pre-sign-up', 'end-blurred-info'].includes(onboardingVariation.signUp) &&
        profileFlowFurthestStep !== 'import-resume')
    ) {
      return [...inAllFlows_initialRoutes, 'import-resume', ...manualEntryFlow_endingRoutes];
    }
    return [...inAllFlows_initialRoutes, ...resumeParsingFlow_EndingRoutes];
  }
  return [...inAllFlows_initialRoutes, ...manualEntryFlow_endingRoutes];
}

/**
 * If the flow is completed it returns an object indicating so, the current step and the completion
 * path.
 *
 * Otherwise, it return the next step path and the shouldUpdate_furthestStepCompleted flag indicating
 * the furthest step completed must be updated
 *
 * @todo Write lots of test for this component.
 */
export function handleOnboardingStepCompletion({
  isDesktop,
  currentForkDecision,
  onboardingVariation,
  hasParsedResume,
  currentStepPath,
  path,
  workExperiences,
  profileFlowFurthestStep,
}: {
  isDesktop: boolean;
  currentStepPath: string;
  currentForkDecision: OnboardingForkDecision | undefined;
  onboardingVariation: Experiment;
  hasParsedResume: boolean | undefined;
  path: string;
  workExperiences:
    | ReturnType<typeof serializeForProfile>['candidate']['workExperiences']
    | undefined;
  profileFlowFurthestStep: OnboardingStep | null | undefined;
}):
  | { next: 'completed'; currentStep: OnboardingStep; completionPath: 'manual' | 'resume-parsing' }
  | {
      next: 'next-step';
      currentStep: OnboardingStep;
      nextStepPath: string;
      shouldUpdate_furthestStepCompleted: boolean;
    } {
  const onboardingSteps = createOnboardingSteps({
    isDesktop,
    currentForkDecision: currentForkDecision || {
      resumeParsing: hasParsedResume ? 'resume-parsed' : undefined,
    },
    currentStepPath,
    onboardingVariation,
    // handleOnboardingStepCompletion will never care about these values because once it is called the
    // forced redirection has been already done
    isAfterInitialForcedRedirect: true,
    profileFlowFurthestStep,
  });

  const currentStepIndex = onboardingSteps.findIndex((stepName) => {
    return matchPath(currentStepPath, {
      path: `${path}/${stepName}`,
      exact: false, // False so that it matches correct with deeper routes of a step
      strict: false,
    });
  });

  if (currentStepIndex + 1 === onboardingSteps.length) {
    return {
      next: 'completed',
      completionPath: onboardingSteps.some((step) =>
        ['add-location', 'add-skills', 'add-experience', 'add-more-info'].includes(step),
      )
        ? 'manual'
        : 'resume-parsing',
      // TODO: completionPath should be set this way so it does not depend on the order of the steps
      //       between forks. We're leaving it as it was before because tests are failing.
      // completionPath: hasParsedResume ? 'resume-parsing' : 'manual',
      currentStep: onboardingSteps[currentStepIndex] as OnboardingStep,
    };
  }

  const nextStep = onboardingSteps[currentStepIndex + 1];

  let querystring = '';

  if (nextStep === 'add-more-info') {
    querystring = (workExperiences || []).length > 0 ? '?v=a1' : '?v=a2'; // TODO: move this to Add More Info controller
  }

  const furthestStepIndex = profileFlowFurthestStep
    ? onboardingSteps.indexOf(profileFlowFurthestStep as OnboardingStep)
    : -1;

  return {
    next: 'next-step',
    currentStep: onboardingSteps[currentStepIndex] as OnboardingStep,
    nextStepPath: `${path}/${nextStep}${querystring}`,
    shouldUpdate_furthestStepCompleted: currentStepIndex > furthestStepIndex,
  };
}

/*
 * Returns an object with the last_utm_* properties to be spread.
 * Props are added to te object if:
 * - the utm_* prop to be persisted is currently set in the user_temp_blob
 * - the currently stored utm_* value is different from current one in the query params
 * - the currently stored utm_* value is different from the currently stored last_utm_* value
 */
export const createLastUTMProps = ({
  utmPropsData,
  currentUserTempBlob,
  trackingParams,
}: {
  utmPropsData: {
    currentPropName: string;
    lastPropName: string;
  }[];
  currentUserTempBlob: { [key: string]: string };
  trackingParams: TrackingParams;
}) => {
  const lastUTMProps: { [key: string]: string } = {};
  utmPropsData.forEach(({ currentPropName, lastPropName }) => {
    const currentValue = currentUserTempBlob[currentPropName];
    const lastValue = currentUserTempBlob[lastPropName];

    if (
      currentValue &&
      currentPropName in currentUserTempBlob &&
      currentValue !== trackingParams[currentPropName] &&
      currentValue !== lastValue
    ) {
      lastUTMProps[lastPropName] = currentValue;
    }
  });

  return lastUTMProps;
};

function OnboardingAuthHandler({ children }: { children: React.ReactNode }) {
  const auth = useCandidateAuth();

  const toast = useToast({
    position: 'top',
    duration: 10000,
    status: 'error',
  });

  useEffect(() => {
    if (auth.friendlyErrorMessage) {
      toast({
        description: auth.friendlyErrorMessage,
      });
    }
  }, [auth.friendlyErrorMessage, toast]);

  useEffect(() => {
    if (
      !auth.isAuthenticated &&
      // To prevent anonymous sign in loop when auth fails. Chose 3 attempts to leave some
      // room for grace when auth fails
      auth.attemptCount <= 3
    ) {
      signInAnonymously({ auth: firebaseAuth, dispatch: auth.dispatch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated]);

  // To prevent anonymous sign in loop when auth fails. Chose 3 attempts to leave some
  // room for grace when auth fails
  if (auth.attemptCount >= 3) {
    throw auth.error || new Error('Auth failed 3 times while attempting anonymous sign in.');
  }

  if (!auth.isAuthenticated) {
    return <ActivityIndicator aria-label="Loading" />;
  }

  return children as React.ReactElement;
}

/**
 * Use the createOnboardingSteps to create all the variation of paths, the rest of flow and step
 * related logic derives themselves from the outcome of this function.
 */
function OnboardingPrivateController({ rootTitle }: { rootTitle: string }) {
  // Attaching Google Drive Scripts in preparation to display the CTA as soon as possible to improve UX
  useAttachDriveScripts();
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const auth = useCandidateAuth();
  const trackingParams = useReactiveVar(appState.trackingParams.stateVar);
  const experiment = useExperiment();

  // TODO: [CAND-538] move this logic to the a use hook and use that here and in profile controller
  const { user, candidateID, userFullName } = useCandidateAuth();

  const [resumeUpdatedDate, setResumeUpdatedDate] = useState<string>();
  const redirectTo = useRedirection();
  const hasForcedRedirectOnce = useRef(false);
  const hasSignUpSuccessExecutedOnce = useRef(false);
  /**
   * This ref was added because those props are being used in callback, and in jest tests, the callback was getting
   * the closure value from the initial render and not from the subsequent render. Strangely enough, this was not
   * an issue in the browser. So basically, this workaround is so that are jest tests and browser work the same.
   */
  const candidatePropsForCallback = useRef<{
    workExperiences: ReturnType<typeof serializeForProfile>['candidate']['workExperiences'];
    profileFlowFurthestStep: OnboardingStep | null;
    // TODO: applicant_consents should come from the serializer, and therefore this type should picked from ProfileProps too
    hasApplicantConsent: boolean;
    hasParsedResume: boolean;
  } | null>(null);

  const client = useApolloClient();

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const {
    loading: isLoading_selectProfile,
    data,
    refetch,
  } = useQuery<SelectProfileQuery, SelectProfileQueryVariables>(SelectProfile, {
    context: {
      role: 'candidate',
    },
    variables: {
      user_id: user?.id as number,
    },
  });

  const {
    availabilityOptions,
    candidate,
    degreeChoices,
    roleChoices,
    yearsOfExperienceRangeChoices,
    skillChoices,
    skillChoicesByRole,
  } = data
    ? serializeForProfile(data as SelectProfileQuery, resumeUpdatedDate)
    : ({} as ReturnType<typeof serializeForProfile>);

  const onboardingVariation = {
    ...experiment,
    signUp: (candidate?.profileFlowVariations?.signUp as Experiment['signUp']) || experiment.signUp,
  };

  const [insertApplicantConsent] = useMutation<
    InsertApplicantConsentMutation,
    InsertApplicantConsentMutationVariables
  >(InsertApplicantConsent, {
    onError: (error) => {
      Sentry.captureException(error);
    },
  });

  const [updateProfileFlowFurthestStepCompleted] = useMutation<
    UpdateCandidateProfileFlowFurthestStepMutation,
    UpdateCandidateProfileFlowFurthestStepMutationVariables
  >(UpdateCandidateProfileFlowFurthestStep, {
    onError: (error) => {
      Sentry.captureException(error);
    },
    update(cache, { data: updateData }) {
      try {
        const readData = cache.readQuery<SelectProfileQuery>({
          query: SelectProfile,
          variables: {
            user_id: auth.user?.id,
          },
        });

        const updatedCandidate = {
          ...readData?.candidates[0],
          ...updateData?.update_candidate_by_pk,
        };

        cache.writeQuery({
          query: SelectProfile,
          broadcast: true,
          variables: {
            user_id: auth.user?.id,
          },
          data: { ...readData, candidates: [updatedCandidate] },
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  const [updateCandidateOnboardingFlowData] = useMutation<
    UpdateCandidateOnboardingFlowDataMutation,
    UpdateCandidateOnboardingFlowDataMutationVariables
  >(UpdateCandidateOnboardingFlowData, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to set your flow data. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
    update: (cache, { data: updateCandidateOnboardingFlowData_result }) => {
      const readData = cache.readQuery<SelectCandidateInsiderInfoQuery>({
        query: SelectCandidateInsiderInfo,
        variables: {
          candidate_id: auth.candidateID as number,
        },
      });

      const updatedCandidate = {
        ...readData?.candidate[0],
        ...updateCandidateOnboardingFlowData_result?.update_candidate_by_pk,
      };

      cache.writeQuery({
        query: SelectCandidateInsiderInfo,
        broadcast: true,
        variables: {
          candidate_id: auth.candidateID as number,
        },
        data: {
          candidate: [updatedCandidate],
        },
      });
    },
  });

  useEffect(() => {
    const updateUserInitialValues = async () => {
      /**
       * The profile_started_at needs to be validated this way because the
       * serializer is loaded after isLoading_selectProfile is true, but
       * useEffect can't be conditionally called.
       */
      if (!isLoading_selectProfile && !data?.candidates[0]?.profile_flow_started_at) {
        await updateCandidateOnboardingFlowData({
          variables: {
            candidate_id: candidateID as number,
            candidate_set_input: {
              profile_flow_started_at: new Date(),
              profile_flow_variations: onboardingVariation,
            },
          },
        });

        events.track(events.name.profileFlow.started);
      }
      try {
        const userTempBlob_queryData = await selectUserTempBlob({
          firebaseUID: auth.user?.firebase_uid as string,
          client,
        });
        const currentUserTempBlob = userTempBlob_queryData.user_temp_blob[0]?.blob;

        await updateUserTempBlob({
          firebaseUID: auth.user?.firebase_uid as string,
          newValues: {
            utm_source: trackingParams.utm_source,
            utm_medium: trackingParams.utm_medium,
            utm_campaign: trackingParams.utm_campaign,
            utm_term: trackingParams.utm_term,
            utm_content: trackingParams.utm_content,
            cid: trackingParams.cid,
            referring_url: document.referrer,
            // if theres no user_temp_blob theres would be no utm values to persist
            ...(currentUserTempBlob
              ? createLastUTMProps({
                  currentUserTempBlob,
                  utmPropsData: [
                    {
                      currentPropName: 'utm_medium',
                      lastPropName: 'last_utm_medium',
                    },
                    {
                      currentPropName: 'utm_source',
                      lastPropName: 'last_utm_source',
                    },
                    {
                      currentPropName: 'utm_campaign',
                      lastPropName: 'last_utm_campaign',
                    },
                  ],
                  trackingParams,
                })
              : {}),
          },
          client,
          shouldOverwriteProps: true,
        });
      } catch (error) {
        toast({
          description: 'Something went wrong trying to set your profile values.',
          status: 'error',
        });

        Sentry.captureException(error);
      }
    };

    if (!auth.user?.firebase_uid) return;

    updateUserInitialValues();
    // Ignoring toast as it is a fixed design system utility
    // Ignoring location.pathname as this block must not run with every pathname change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth.user?.firebase_uid,
    auth.isAuthenticated,
    candidateID,
    updateCandidateOnboardingFlowData,
    client,
    onboardingVariation.signUp,
    isLoading_selectProfile,
  ]);

  // TODO: move this to resume ResumeParsingController
  const resumeFileName = data?.candidates[0]?.resume_filename;
  useEffect(() => {
    const getUpdatedDate = async (candidateArg: any) => {
      const { public_id, resume_filename } = candidateArg;
      const fileRef = createStorageRef(`/candidate/resume/${public_id}/${resume_filename}`);
      const { updated } = await getMetadata(fileRef);
      setResumeUpdatedDate(updated);
    };

    if (data && data.candidates[0]?.resume_filename) {
      getUpdatedDate(data.candidates[0]);
    }
    // Ignoring data as resumeFileName is the only dependency we care
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeFileName]);

  if (isLoading_selectProfile) {
    return <ActivityIndicator />;
  }

  candidatePropsForCallback.current = {
    profileFlowFurthestStep: candidate.profileFlowFurthestStep as OnboardingStep | null,
    workExperiences: candidate.workExperiences,
    hasApplicantConsent: !!data?.candidates[0]?.applicant_consents.length,
    hasParsedResume: candidate.hasParsedResume,
  };

  const handleStepCompleteSuccess = ({
    currentForkDecision,
  }: { currentForkDecision?: OnboardingForkDecision } = {}) => {
    const whatsNextResult = handleOnboardingStepCompletion({
      isDesktop: !isMobileByRegex,
      onboardingVariation,
      currentStepPath: location.pathname,
      currentForkDecision,
      workExperiences: candidatePropsForCallback.current?.workExperiences,
      path,
      hasParsedResume: candidatePropsForCallback.current?.hasParsedResume,
      profileFlowFurthestStep: candidatePropsForCallback.current?.profileFlowFurthestStep,
    });

    if (whatsNextResult.next === 'next-step') {
      // This allows redirecting the returning users who have left this flow uncompleted,
      // to the right step. Since its not part of the essential user experience, when this
      // mutation fails, we don't want to show error nor disrupt user experience.

      if (whatsNextResult.shouldUpdate_furthestStepCompleted) {
        updateProfileFlowFurthestStepCompleted({
          variables: {
            candidate_id: candidateID as number,
            step: whatsNextResult.currentStep,
          },
        });
      }

      redirectTo(whatsNextResult.nextStepPath);

      return;
    }

    if (whatsNextResult.next === 'completed') {
      // TODO: explain why this is here
      updateProfileFlowFurthestStepCompleted({
        variables: {
          candidate_id: candidateID as number,
          step: whatsNextResult.currentStep,
        },
      });

      // These app states should be updated before the mutation for profile_flow_completed_at
      // is resolved as immediately after that user is redirected out of onboarding. And those two
      // states must be set before the redirection in order that in the subsequent page the can
      // accessed on the page component mount.
      appState.flow.update(
        'landing-first-time-from-completed-onboarding-flow',
        whatsNextResult.completionPath,
      );

      // The page automatically get redirected out of onboarding when
      // profile_flow_completed_at is set. So here we are just updating
      // the query param that is used for analytics.
      // TODO: move this to home page as its the responsibility of home page to show this link
      history.replace({
        search: whatsNextResult.completionPath === 'resume-parsing' ? '?rm=t' : '?ref=manu',
      });

      updateCandidateOnboardingFlowData({
        variables: {
          candidate_id: candidateID as number,
          candidate_set_input: {
            profile_flow_completed_at: new Date(),
          },
        },
      });

      events.track(events.name.profileFlow.completed);

      triggerGoal({ goalID: 1, campaignID: 51 });
      appState.flow.update('is-completed-onboarding-session', true);
    }
  };

  const handleSignUpSuccess = async () => {
    if (hasSignUpSuccessExecutedOnce.current) return;

    hasSignUpSuccessExecutedOnce.current = true;

    if (
      data?.candidates[0]?.applicant_consents &&
      data?.candidates[0]?.applicant_consents.length === 0
    ) {
      await insertApplicantConsent({
        variables: {
          candidate_id: auth.candidateID as number,
        },
      });
    }

    handleStepCompleteSuccess();
  };

  const onboardingSteps = createOnboardingSteps({
    isDesktop: !isMobileByRegex,
    currentStepPath: location.pathname,
    profileFlowFurthestStep: candidate.profileFlowFurthestStep as OnboardingStep | null,
    isAfterInitialForcedRedirect: hasForcedRedirectOnce.current,
    onboardingVariation,
    currentForkDecision: { resumeParsing: candidate.hasParsedResume ? 'resume-parsed' : undefined },
  });

  const calculateStepProgress = (stepName: OnboardingStep): HStepsProps => {
    const countableSteps = onboardingSteps.filter((step) => !['sign-up', 'start'].includes(step));

    return {
      currentStepNumber: countableSteps.indexOf(stepName) + 1,
      totalSteps: countableSteps.length,
    };
  };

  const furthestStepIndex = candidate.profileFlowFurthestStep
    ? onboardingSteps.indexOf(candidate.profileFlowFurthestStep as OnboardingStep)
    : -1;

  const forceRedirectURL =
    furthestStepIndex !== -1 ? onboardingSteps[furthestStepIndex + 1] : onboardingSteps[0];

  if (
    matchPath(location.pathname, {
      path: `${path}/${forceRedirectURL}`,
      exact: false,
      strict: false,
    })
  ) {
    // We only want to force redirect to the furthest step in profile flow load, not on any subsequent renders.
    hasForcedRedirectOnce.current = true;
  } else if (!hasForcedRedirectOnce.current && forceRedirectURL) {
    redirectTo(`${url}/${forceRedirectURL}`, {
      shouldPassQuerystringAlong: true,
    });

    return <ActivityIndicator />;
  }

  return (
    <SwitchWithPageNotFound>
      <CustomRoute path={`${path}/sign-up`} title={[rootTitle, 'Sign Up']}>
        <SignUpRouter onStepCompleteSuccess={handleSignUpSuccess} />
      </CustomRoute>
      <CustomRoute path={`${path}/start`} title={[rootTitle, 'Start']}>
        <HowItWorks.Variation.WithSignUpAtTheEnd
          onStepCompleteSuccess={() => handleStepCompleteSuccess()}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/add-roles`} title={[rootTitle, 'Add Roles']}>
        <RolesController
          candidateID={candidateID as number}
          candidateRoles={candidate.roles}
          onSignOutClick={() => signOut({ auth: firebaseAuth })}
          onStepCompleteSuccess={() => handleStepCompleteSuccess()}
          progressProps={calculateStepProgress('add-roles')}
          roleChoices={roleChoices}
          userFullName={userFullName}
          userID={auth.user?.id}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/years`} title={[rootTitle, 'Add Years of Experience']}>
        <YearsController
          candidateID={candidateID as number}
          candidateYearsOfExperienceRange={candidate.yearsOfExperienceRange}
          onSignOutClick={() => signOut({ auth: firebaseAuth })}
          userID={auth.user?.id}
          onStepCompleteSuccess={() => handleStepCompleteSuccess()}
          progressProps={calculateStepProgress('years')}
          yearsOfExperienceRangeChoices={yearsOfExperienceRangeChoices}
          userFullName={userFullName}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/add-salary`} title={[rootTitle, 'Add Desired Salary']}>
        <SalaryController
          progressProps={calculateStepProgress('add-salary')}
          candidateID={candidateID as number}
          onStepCompleteSuccess={() => handleStepCompleteSuccess()}
          onSignOutClick={() => signOut({ auth: firebaseAuth })}
          userFullName={userFullName}
          desiredSalaryData={candidate.desiredSalary.formValues}
          userID={auth.user?.id}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/add-availability`} title={[rootTitle, 'Add Availability']}>
        <AvailabilityController
          progressProps={calculateStepProgress('add-availability')}
          candidateID={candidateID as number}
          availabilityOptions={availabilityOptions}
          candidateAvailability={candidate.personalInfo.availability}
          onStepCompleteSuccess={() => handleStepCompleteSuccess()}
          onSignOutClick={() => signOut({ auth: firebaseAuth })}
          userFullName={userFullName}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/import-resume`} title={[rootTitle, 'Import Resume']}>
        <ResumeParsingStepRouter
          progressProps={calculateStepProgress('import-resume')}
          candidateID={candidateID as number}
          onStepCompleteSuccess={(hasParsedResume) => {
            handleStepCompleteSuccess({
              currentForkDecision: {
                resumeParsing: hasParsedResume ? 'resume-parsed' : 'manual-entry',
              },
            });
          }}
          onSignOutClick={() => signOut({ auth: firebaseAuth })}
          userFullName={userFullName}
          publicID={candidate.resume?.publicID}
          refetch={refetch}
          candidateCountry={candidate.personalInfo.formValues.country}
          country_id={candidate.personalInfo.formValues.country_id}
          formattedAddress={candidate.personalInfo.formValues.formattedAddress}
          parentNestedPageTitles={[rootTitle, 'Import Resume']}
          locationVariation={onboardingVariation.onboardingLocation}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/add-location`} title={[rootTitle, 'Add Location']}>
        <AddLocationStepController
          progressProps={calculateStepProgress('add-location')}
          candidateID={candidateID as number}
          candidateCountry={candidate.personalInfo.formValues.country}
          country_id={candidate.personalInfo.formValues.country_id}
          onStepCompleteSuccess={() => handleStepCompleteSuccess()}
          onSignOutClick={() => signOut({ auth: firebaseAuth })}
          userFullName={userFullName}
          formattedAddress={candidate.personalInfo.formValues.formattedAddress}
          isConfirmStep={false}
          experienceVariation={onboardingVariation.onboardingLocation}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/add-skills`} title={[rootTitle, 'Add Skills']}>
        <SkillsStepController
          progressProps={calculateStepProgress('add-skills')}
          skillChoices={skillChoices}
          skillChoicesByRole={skillChoicesByRole}
          skillGroups={candidate.skillGroups}
          onStepCompleteSuccess={() => handleStepCompleteSuccess()}
          candidateID={candidateID as number}
          userID={auth.user?.id}
          hasOptionalSkills={candidate.hasOptionalSkills}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/add-experience`} title={[rootTitle, 'Add Experience']}>
        <ExperiencesStepRouter
          candidateID={candidateID as number}
          onStepCompleteSuccess={() => handleStepCompleteSuccess()}
          progressProps={calculateStepProgress('add-experience')}
          mode="add"
          userID={auth.user?.id}
          workExperiences={candidate.workExperiences}
          degreeChoices={degreeChoices}
          educations={candidate.educations}
          parentNestedPageTitles={[rootTitle, 'Add Experience']}
        />
      </CustomRoute>
      <CustomRoute path={`${path}/add-more-info`} title={[rootTitle, 'Add More Info']}>
        <MoreInfoStepController
          candidateResume={candidate.resume}
          candidateSocialProfile={candidate.socialProfile}
          candidateWorkExperiences={candidate.workExperiences}
          candidateID={candidateID as number}
          onStepCompleteSuccess={() => handleStepCompleteSuccess()}
          onSignOutClick={() => signOut({ auth: firebaseAuth })}
          userFullName={userFullName}
          progressProps={calculateStepProgress('add-more-info')}
          mode="add"
        />
      </CustomRoute>
      <Route
        path={path}
        render={() => {
          return (
            <Redirect
              to={{
                pathname: `${url}/${
                  !hasForcedRedirectOnce.current && forceRedirectURL
                    ? forceRedirectURL
                    : onboardingSteps[0]
                }`,
                search: location.search,
              }}
            />
          );
        }}
      />
    </SwitchWithPageNotFound>
  );
}

export function OnboardingController({ rootTitle }: { rootTitle: string }) {
  return (
    <OnboardingAuthHandler>
      <React.StrictMode>
        <OnboardingPrivateController rootTitle={rootTitle} />
      </React.StrictMode>
    </OnboardingAuthHandler>
  );
}
