import type { FormikState } from 'formik';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  VStack,
} from '@terminal/design-system';
import type { ChangeEventHandler, ComponentProps, FocusEventHandler } from 'react';
import type { PersonalInfoForm } from './PersonalInfo.controller';
import { Section } from '../../DirectApplication.types';
import { ApplicationSectionBox } from '../../components';

export function PersonalInfo({
  errors,
  jobLocationOptions,
  onBlur,
  onChange,
  onNextClick,
  values,
  onSectionBoxEditClick,
  isOpened,
  touched,
  sectionSubmitButtonIsLoading,
  isCompleted,
  sectionSubmitButtonIsDisabled,
}: {
  jobLocationOptions: string[];
  onBlur: FocusEventHandler<HTMLInputElement | HTMLSelectElement>;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  onNextClick: () => void;
  sectionSubmitButtonIsLoading: boolean;
  sectionSubmitButtonIsDisabled: boolean;
} & Pick<FormikState<PersonalInfoForm>, 'errors' | 'values' | 'touched'> &
  Omit<ComponentProps<typeof ApplicationSectionBox>, 'title' | 'children' | 'section'>) {
  return (
    <ApplicationSectionBox
      section={Section.personalInformation}
      onSectionBoxEditClick={onSectionBoxEditClick}
      isCompleted={isCompleted}
      isOpened={isOpened}
      title="Personal Information"
    >
      <>
        <VStack spacing={6} mt="6">
          <FormControl isInvalid={touched.email && !!errors.email}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              name="email"
              onBlur={onBlur}
              onChange={onChange}
              placeholder="Email"
              value={values.email}
              type="email"
              autoCapitalize="none"
              spellCheck={false}
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={touched.firstName && !!errors.firstName}>
            <FormLabel htmlFor="firstName">First Name</FormLabel>
            <Input
              id="firstName"
              name="firstName"
              onBlur={onBlur}
              onChange={onChange}
              placeholder="First Name"
              value={values.firstName}
              spellCheck={false}
            />
            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={touched.lastName && !!errors.lastName}>
            <FormLabel htmlFor="lastName">Last Name</FormLabel>
            <Input
              id="lastName"
              name="lastName"
              onBlur={onBlur}
              onChange={onChange}
              placeholder="Last Name"
              value={values.lastName}
              spellCheck={false}
            />
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={touched.location && !!errors.location}>
            <FormLabel htmlFor="location">Location</FormLabel>
            <FormHelperText>
              We are currently recruiting on the following countries:{' '}
            </FormHelperText>
            <Select
              defaultValue={values.location ?? ''}
              mt={2}
              id="location"
              name="location"
              onBlur={onBlur}
              onChange={onChange}
              placeholder="Select your location"
            >
              {jobLocationOptions.map((value) => (
                <option value={value} key={`location-${value}`}>
                  {value}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.location}</FormErrorMessage>
          </FormControl>
        </VStack>

        <Button
          isLoading={sectionSubmitButtonIsLoading}
          isDisabled={sectionSubmitButtonIsDisabled}
          w="full"
          mt={6}
          onClick={onNextClick}
        >
          Continue
        </Button>
      </>
    </ApplicationSectionBox>
  );
}
