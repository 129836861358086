import { Icon } from '@terminal/design-system';

export function DevOpsIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <path
          d="m31 13.251c5e-4 8.7838-6.339 16.285-15 17.749-8.661-1.464-15.001-8.9655-15-17.749v-10.251c0-1.1046 0.89543-2 2-2h26c1.1046 0 2 0.89543 2 2v10.251z"
          clipRule="evenodd"
          fill="#A0CFDA"
          fillRule="evenodd"
        />
        <path
          d="m31 13.251c5e-4 8.7838-6.339 16.285-15 17.749-8.661-1.464-15.001-8.9655-15-17.749v-10.251c0-1.1046 0.89543-2 2-2h26c1.1046 0 2 0.89543 2 2v10.251z"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m17.699 6.2667 0.5893 1.9373c0.2018 0.66722 0.886 1.0641 1.5653 0.908l1.9627-0.45467c0.7636-0.17208 1.5502 0.17329 1.9403 0.8519 0.3901 0.67866 0.2927 1.5322-0.2403 2.1055l-1.3747 1.4773c-0.4759 0.5118-0.4759 1.3042 0 1.816l1.3747 1.4813c0.533 0.5733 0.6304 1.4269 0.2403 2.1055s-1.1767 1.024-1.9403 0.8519l-1.9627-0.4547c-0.6793-0.1561-1.3635 0.2408-1.5653 0.908l-0.5893 1.9333c-0.2249 0.7503-0.9154 1.2642-1.6987 1.2642s-1.4738-0.5139-1.6987-1.2642l-0.5893-1.9373c-0.2018-0.6672-0.886-1.0641-1.5653-0.908l-1.9627 0.4547c-0.76359 0.1721-1.5502-0.1733-1.9403-0.8519-0.39009-0.6786-0.29266-1.5322 0.24033-2.1055l1.3747-1.4813c0.47593-0.5118 0.47593-1.3042 0-1.816l-1.3747-1.4773c-0.53299-0.5733-0.63042-1.4268-0.24033-2.1055 0.3901-0.67861 1.1767-1.024 1.9403-0.8519l1.9627 0.45467c0.6793 0.15608 1.3635-0.24078 1.5653-0.908l0.5893-1.9333c0.2249-0.7503 0.9154-1.2642 1.6987-1.2642s1.4738 0.51388 1.6987 1.2642z"
          clipRule="evenodd"
          fill="#EFD58B"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <circle
          cx="16"
          cy="14"
          r="2"
          fill="#8FA25B"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M31 13.25c0 8.785-6.339 16.286-15 17.75-8.661-1.464-15-8.965-15-17.75V3c0-1.105.895-2 2-2h26c1.105 0 2 .895 2 2v10.25z"
        clipRule="evenodd"
      />
      <circle
        cx="16"
        cy="14"
        r="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.699 6.267l.589 1.937c.202.667.886 1.064 1.565.908l1.963-.455c.764-.172 1.55.174 1.94.852.39.679.293 1.532-.24 2.106l-1.375 1.477c-.476.512-.476 1.304 0 1.816l1.375 1.481c.533.574.63 1.427.24 2.106-.39.678-1.176 1.024-1.94.852l-1.963-.455c-.679-.156-1.363.24-1.565.908l-.59 1.933c-.224.75-.915 1.264-1.698 1.264-.783 0-1.474-.513-1.699-1.264l-.589-1.937c-.202-.667-.886-1.064-1.565-.908l-1.963.455c-.764.172-1.55-.174-1.94-.852-.39-.679-.293-1.532.24-2.106l1.375-1.481c.476-.512.476-1.304 0-1.816l-1.375-1.477c-.533-.574-.63-1.427-.24-2.106.39-.678 1.176-1.024 1.94-.852l1.963.455c.679.156 1.363-.24 1.565-.908l.59-1.933c.224-.75.915-1.265 1.698-1.265.783 0 1.474.514 1.699 1.265z"
        clipRule="evenodd"
      />
      <mask id="bofydf3cra" width="32" height="32" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="currentColor" d="M0 0H32V32H0z" />
      </mask>
    </Icon>
  );
}
