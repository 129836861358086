import type { Candidate_Availability_Choices_Enum } from 'global/types/hasura-tables.generated.types';

export function serializeProfileCompletion({
  fullName,
  availability,
  location,
  email,
  roles,
  socialProfileLinks,
  workExperiences,
  educations,
  skillGroups,
  resumeFilename,
  desiredSalaryAmount,
}: {
  fullName?: string | null;
  availability?: Candidate_Availability_Choices_Enum | null;
  location?: string | null;
  email?: string | null;
  resumeFilename?: string | null;
  socialProfileLinks: {
    linkedin_url?: string | null;
    github_url?: string | null;
    other_url?: string | null;
  };
  // using type <any> since the data inside those arrays is not important for this function
  roles?: any[];
  workExperiences?: any[];
  educations?: any[];
  skillGroups?: any[];
  desiredSalaryAmount?: number;
}) {
  const sectionsCompletionStatus = {
    personalInfo: !!fullName && !!availability && !!location && !!email,
    roles: (roles?.length ?? 0) > 0,
    socialProfile: Object.values(socialProfileLinks).filter(Boolean).length > 0,
    workExperience: (workExperiences?.length ?? 0) > 0,
    educations: (educations?.length ?? 0) > 0,
    skills: (skillGroups?.length ?? 0) > 0,
    resume: !!resumeFilename,
    desiredSalary: (desiredSalaryAmount ?? 0) > 0,
  };

  return {
    sectionsCompletionStatus,
    completionPercentage: Math.round(
      (Object.values(sectionsCompletionStatus).filter(Boolean).length * 100) /
        Object.keys(sectionsCompletionStatus).length,
    ),
  };
}
