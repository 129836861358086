import { SimpleGrid, Box } from '@terminal/design-system';
import type { AssessmentProps } from 'candidate/shared/data-layer/SelectAssessments';
import { AssessmentCard } from './AssessmentCard';

export function AssessmentsList({
  assessmentsList,
  onLearnMoreClick,
}: {
  assessmentsList: AssessmentProps[];
  onLearnMoreClick: (assessment: AssessmentProps) => void;
}) {
  return (
    <SimpleGrid spacing={6} columns={[1, 2, 2, 3]}>
      {assessmentsList.map((assessment) => (
        <Box w={['full', '%33']} flexDirection="column" key={assessment.name}>
          <AssessmentCard
            about={assessment.about}
            name={assessment.name}
            status={assessment.status}
            onLearnMoreClick={() => onLearnMoreClick(assessment)}
            footerNote={assessment.cardProps.footerNote}
          />
        </Box>
      ))}
    </SimpleGrid>
  );
}
