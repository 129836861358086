import { CommunicationModal } from 'candidate/components';
import { Box, Button, Heading, Text } from '@terminal/design-system';
import TrophyIllustration from './assets/trophy.svg?url';

export function CompletionLanding({ onClose }: { onClose: () => void }) {
  return (
    <CommunicationModal
      isOpen
      onClose={onClose}
      image={{ src: TrophyIllustration, alt: 'Assessment completed' }}
    >
      <Box pb={3} mt={[4, 4, 2]} pt={[1, 1, 0]}>
        <Heading variant="heading-2" color="brand.main" align="center">
          Skill Test Complete!
        </Heading>
        <Text variant="body" align="center" mt={2}>
          Thank you for completing this test. This gives our Talent Team more information about your
          technical proficiency!
        </Text>
        <Button w="full" onClick={onClose} mt={[6, 6, 8]}>
          Take Another Test
        </Button>
      </Box>
    </CommunicationModal>
  );
}
