import { Icon } from '@terminal/design-system';

export function AutomationQAIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 31 32" fill="none" boxSize={8}>
        <path
          d="M22.4851 23.683L30.16 30.37"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse
          cx="11.5271"
          cy="11.3611"
          rx="11.5271"
          ry="11.3611"
          transform="matrix(0.915497 -0.402324 0.380142 0.924928 0 9.27527)"
          fill="#A0CFDA"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5407 8.98352L16.9992 10.4908C17.1562 11.0098 17.6885 11.3186 18.217 11.1972L19.744 10.8434C20.3381 10.7096 20.9501 10.9783 21.2535 11.5062C21.557 12.0342 21.4812 12.6982 21.0666 13.1442L19.9971 14.2936C19.6268 14.6917 19.6268 15.3083 19.9971 15.7064L21.0666 16.8589C21.4812 17.3049 21.557 17.9689 21.2535 18.4969C20.9501 19.0249 20.3381 19.2936 19.744 19.1597L18.217 18.8059C17.6885 18.6845 17.1562 18.9933 16.9992 19.5124L16.5407 21.0165C16.3658 21.6002 15.8286 22 15.2192 22C14.6098 22 14.0726 21.6002 13.8976 21.0165L13.4391 19.5092C13.2821 18.9902 12.7499 18.6814 12.2213 18.8028L10.6944 19.1566C10.1003 19.2904 9.4883 19.0217 9.1848 18.4938C8.88131 17.9658 8.95712 17.3018 9.37178 16.8558L10.4413 15.7033C10.8115 15.3051 10.8115 14.6886 10.4413 14.2905L9.37178 13.1411C8.95712 12.6951 8.88131 12.0311 9.1848 11.5031C9.4883 10.9751 10.1003 10.7064 10.6944 10.8403L12.2213 11.1941C12.7499 11.3155 13.2821 11.0067 13.4391 10.4876L13.8976 8.98352C14.0726 8.39979 14.6098 8 15.2192 8C15.8286 8 16.3658 8.39979 16.5407 8.98352Z"
          fill="#EFD58B"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="15.2192"
          cy="15"
          r="1.55599"
          fill="#8FA25B"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 31 32" fill="none" boxSize={8}>
      <path
        d="M22.4851 23.683L30.16 30.37"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="11.5271"
        cy="11.3611"
        rx="11.5271"
        ry="11.3611"
        transform="matrix(0.915497 -0.402324 0.380142 0.924928 0 9.27527)"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="15.2193"
        cy="15"
        r="1.55599"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5407 8.98352L16.9992 10.4908C17.1562 11.0098 17.6885 11.3186 18.217 11.1972L19.744 10.8434C20.3381 10.7096 20.9501 10.9783 21.2535 11.5062C21.557 12.0342 21.4812 12.6982 21.0666 13.1442L19.9971 14.2936C19.6268 14.6917 19.6268 15.3083 19.9971 15.7064L21.0666 16.8589C21.4812 17.3049 21.557 17.9689 21.2535 18.4969C20.9501 19.0249 20.3381 19.2936 19.744 19.1597L18.217 18.8059C17.6885 18.6845 17.1562 18.9933 16.9992 19.5124L16.5407 21.0165C16.3658 21.6002 15.8286 22 15.2192 22C14.6098 22 14.0726 21.6002 13.8976 21.0165L13.4391 19.5092C13.2821 18.9902 12.7499 18.6814 12.2213 18.8028L10.6944 19.1566C10.1003 19.2904 9.4883 19.0217 9.1848 18.4938C8.88131 17.9658 8.95712 17.3018 9.37178 16.8558L10.4413 15.7033C10.8115 15.3051 10.8115 14.6886 10.4413 14.2905L9.37178 13.1411C8.95712 12.6951 8.88131 12.0311 9.1848 11.5031C9.4883 10.9751 10.1003 10.7064 10.6944 10.8403L12.2213 11.1941C12.7499 11.3155 13.2821 11.0067 13.4391 10.4876L13.8976 8.98352C14.0726 8.39979 14.6098 8 15.2192 8C15.8286 8 16.3658 8.39979 16.5407 8.98352Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
