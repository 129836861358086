import type { HStepsProps } from 'candidate/components';
import {
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  GridItem,
  CheckboxCard,
  Center,
  Box,
  Button,
  Flex,
  CheckboxToggle,
  Image,
  Text,
  Link,
} from '@terminal/design-system';
import { JobMatches } from 'candidate/shared/modules/job/JobMatches';
import { useIsViewPortDesktop } from 'global/utils';
import { PublicPageLayout, HSteps } from 'candidate/components';
import { Link as RouterLink } from 'react-router-dom';

import type { JobSuggestionProps } from 'candidate/shared/modules/job/data/JobSuggestions.types';
import { CheckboxCardGroup } from '../../components';
import { roleIcons } from '../../../../../../shared/modules/roles';
import type { ProfileProps } from '../../../../Profile.types';
import StartInterviewSVG from './assets/start_interviewing-lg.svg?url';

function BannerSmall() {
  return (
    <Center
      w="full"
      bgColor="accent.lightest"
      px={3}
      py={2}
      border="1px solid"
      borderColor="accent.main"
      borderRadius="base"
    >
      <Text variant="caption" fontWeight="bold" textAlign="center">
        On average, qualified candidates receive{' '}
        <Text variant="caption" fontWeight="bold">
          2-3 interviews within two weeks
        </Text>
      </Text>
      <Text ml={4} variant="caption">
        🚀
      </Text>
    </Center>
  );
}

function BannerLarge() {
  return (
    <Box w="full" maxW="21.25rem" bgColor="accent.main" my={-6} mr={-6}>
      <Center w="full" h="full" flexDir="column">
        <Image src={StartInterviewSVG} alt="Start Interview" />
        <Text w="full" mt={4} variant="label" textAlign="center" px={10} color="text.warning">
          Start Interviewing Faster 🚀
        </Text>
        <Heading
          w="full"
          mt={4}
          variant="heading-3"
          textAlign="center"
          px={10}
          color="text.inverse"
        >
          On average, qualified candidates receive 2-3 interviews within two weeks
        </Heading>
      </Center>
    </Box>
  );
}

export function RolesAndJobs({
  activeSuggestion,
  isNextDisabled,
  onCheckboxClick,
  onNextClick,
  progressProps,
  roleChoices,
  selectedRoles,
  allJobs,
  jobSectionDetail,
  onJobDetailClick,
  isModalOpen,
  onModalClose,
  shouldDisplayBanner = false,
}: {
  isNextDisabled: boolean;
  onCheckboxClick: (role: string) => void;
  onNextClick: () => void;
  progressProps: HStepsProps;
  roleChoices: ProfileProps['roleChoices'];
  selectedRoles: ProfileProps['candidate']['roles'][number]['formValues'][];
  allJobs: JobSuggestionProps[];
  jobSectionDetail: React.ReactNode;
  onJobDetailClick: (job: JobSuggestionProps) => void;
  activeSuggestion: number;
  isModalOpen: boolean;
  onModalClose: () => void;
  shouldDisplayBanner?: boolean;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <>
      <PublicPageLayout
        headerRightPlaceholderDesktop={
          <Flex alignItems="center">
            <Text>Do You Have a Terminal Account?</Text>
            <Link as={RouterLink} to={`/auth${window.location.search}`} ml={2} fontWeight="bold">
              Login
            </Link>
          </Flex>
        }
        headerRightPlaceholderMobile={
          <Flex alignItems="center">
            <Link
              as={RouterLink}
              to={`/auth/login${window.location.search}`}
              ml={2}
              fontWeight="bold"
            >
              Login
            </Link>
          </Flex>
        }
      >
        <JobMatches
          header={
            <Heading variant={isDesktop ? 'heading-2' : 'heading-1'} color="text.primary">
              Featured Remote Jobs With High-Impact Startups
            </Heading>
          }
          onJobDetailClick={onJobDetailClick}
          jobSuggestions={allJobs}
          jobSectionDetail={jobSectionDetail}
          activeSuggestion={activeSuggestion}
          shouldShowHandpickedJobsBanner={false}
        />
      </PublicPageLayout>

      <Modal
        isCentered={isDesktop}
        isOpen={isModalOpen}
        onClose={onModalClose}
        scrollBehavior="outside"
        closeOnEsc={false}
        closeOnOverlayClick
        returnFocusOnClose={false}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent
          maxW={['full', 'full', shouldDisplayBanner ? '63.25rem' : '42rem']}
          alignSelf={['flex-end', 'flex-end', 'center']}
        >
          <ModalBody pos="relative" bgColor="bg.primary">
            <ModalCloseButton
              color={shouldDisplayBanner && isDesktop ? 'bg.primary' : 'accent.main'}
            />
            <Flex flexDir={['column', 'column', 'row']}>
              <Box px={[0, 0, 14]}>
                {isDesktop && (
                  <Center mt={[6, 6, 6]}>
                    <HSteps {...progressProps} />
                  </Center>
                )}
                <Box mt={[12, 12, 8]}>
                  <Heading variant="heading-2" color="text.primary" textAlign="center">
                    Select Your Desired Roles To Start Your Profile And Unlock Job Matches For You
                  </Heading>
                </Box>
                {shouldDisplayBanner && !isDesktop && (
                  <Box mt={4} mb={10}>
                    <BannerSmall />
                  </Box>
                )}
                <Box mt={[6, 6, 6]}>
                  <CheckboxCardGroup
                    display={['flex', 'flex', 'grid']}
                    flexDir={['row', 'row', 'unset']}
                    flexWrap={['wrap', 'wrap', 'unset']}
                    gap={[2, 2, 4]}
                    justifyContent={['center', 'center', 'unset']}
                  >
                    {roleChoices.map(({ name: roleChoice, displayName }) => {
                      const isSelected = selectedRoles.some(
                        (selectedRole) => selectedRole.role === roleChoice,
                      );

                      return (
                        <GridItem key={`insider-role-${roleChoice}`}>
                          {!isDesktop && (
                            <CheckboxToggle
                              defaultChecked={isSelected}
                              onChange={() => onCheckboxClick(roleChoice)}
                              className="notranslate"
                            >
                              {displayName}
                            </CheckboxToggle>
                          )}
                          {isDesktop && (
                            <CheckboxCard
                              role="checkbox"
                              aria-checked={isSelected}
                              aria-labelledby="candidate-role"
                              onChange={() => onCheckboxClick(roleChoice)}
                              isChecked={isSelected}
                              checkboxIcon={roleIcons({
                                role: roleChoice,
                                isColored: isSelected,
                              })}
                              className="notranslate"
                            >
                              {displayName}
                            </CheckboxCard>
                          )}
                        </GridItem>
                      );
                    })}
                  </CheckboxCardGroup>
                </Box>
                <Center mt={[12, 12, 12]}>
                  <Button onClick={onNextClick} w={['full', 'full', 64]} disabled={isNextDisabled}>
                    Next
                  </Button>
                </Center>
              </Box>
              {shouldDisplayBanner && isDesktop && <BannerLarge />}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
