import type { ComponentProps } from 'react';
import type { AuthenticationRouterOverwrite } from 'global/auth';
import {
  Flex,
  Text,
  Image,
  Heading,
  HStack,
  Divider,
  Button,
  Link,
  CheckCircleSolidIcon,
  Center,
} from '@terminal/design-system';
import welcomeBackImageSrc from 'candidate/assets/images/welcome-back.svg?url';
import forgotPasswordImageSrc from 'candidate/assets/images/forgot-password.svg?url';
import emailVerificationImageSrc from 'candidate/assets/images/email-verification.svg?url';
import { IncompatibleBrowserErrorPage } from './incompatible-browser';
import { CandidateEmailNotVerifiedPage } from './email-not-verified/CandidateEmailNotVerifiedPage';
import { CandidateAuthDefaultPageTemplate } from './CandidateAuthDefaultTemplate';
import { CandidateNoAccessPage } from './no-access/CandidateNoAccessPage';

function CandidateLogInPage(props: ComponentProps<typeof CandidateAuthDefaultPageTemplate>) {
  return (
    <CandidateAuthDefaultPageTemplate
      {...props}
      leftTitle="Welcome Back!"
      headerTitle="Log In"
      mobileTitle="Welcome Back!"
      mobileSubtitle="Log in to your account."
      leftImage={<Image src={welcomeBackImageSrc} w="342px" />}
    />
  );
}

function CandidateForgotPasswordPage(
  props: ComponentProps<typeof CandidateAuthDefaultPageTemplate>,
) {
  return (
    <CandidateAuthDefaultPageTemplate
      {...props}
      headerTitle="Forgot Password?"
      mobileTitle="Forgot Password?"
      leftImage={<Image src={forgotPasswordImageSrc} w="362px" />}
    >
      {props.children}
      <HStack spacing={3} my={6}>
        <Divider />
        <Text fontWeight="bold" variant="hint">
          OR
        </Text>
        <Divider />
      </HStack>

      <Button variant="secondary" onClick={props.footerLinkOnClick} w="full" type="button">
        Back to Log In
      </Button>
    </CandidateAuthDefaultPageTemplate>
  );
}

function CandidateForgotPasswordSuccessPage(
  props: ComponentProps<typeof CandidateAuthDefaultPageTemplate>,
) {
  return (
    <CandidateAuthDefaultPageTemplate
      {...props}
      mobileTitle="Forgot Password?"
      leftImage={<Image src={forgotPasswordImageSrc} w="362px" />}
    >
      <Flex flexDir="column" alignItems={['center', 'center', 'flex-start']}>
        <CheckCircleSolidIcon fontSize="30px" color="ui.success" />
        <Heading variant="heading-2" mt={6} color="brand.main">
          Reset Link Sent!
        </Heading>
        <Text mt={2} textAlign={['center', 'center', 'left']}>
          A reset link was sent to the email provided. Follow the instructions in the email to reset
          your password.
        </Text>
        <Button onClick={props.footerLinkOnClick} mt={6} w="full">
          Try Again
        </Button>
      </Flex>
    </CandidateAuthDefaultPageTemplate>
  );
}

function CandidateResetPasswordPage(
  props: ComponentProps<typeof CandidateAuthDefaultPageTemplate>,
) {
  return (
    <CandidateAuthDefaultPageTemplate
      {...props}
      mobileTitle="Reset Your Password"
      leftImage={<Image src={welcomeBackImageSrc} w="342px" />}
    />
  );
}

function CandidatePasswordResetExpiredPage(
  props: ComponentProps<typeof CandidateAuthDefaultPageTemplate>,
) {
  return (
    <CandidateAuthDefaultPageTemplate
      {...props}
      leftImage={<Image src={forgotPasswordImageSrc} w="362px" />}
    >
      <Flex flexDir="column" alignItems={['center', 'center', 'flex-start']}>
        <Heading variant="heading-2" color="brand.main">
          Link Expired
        </Heading>
        <Text textAlign={['center', 'center', 'left']} mt={2}>
          This link is no longer active. If you would like to reset your password, please request a
          new link.
        </Text>
        <Button onClick={props.footerLinkOnClick} mt={6} w="full">
          Resend Link
        </Button>
      </Flex>
    </CandidateAuthDefaultPageTemplate>
  );
}

function CandidatSignupPage(props: ComponentProps<typeof CandidateAuthDefaultPageTemplate>) {
  return (
    <CandidateAuthDefaultPageTemplate
      {...props}
      leftTitle="Welcome Back!"
      mobileTitle="Welcome Back!"
      mobileSubtitle="Claim your account"
      leftImage={<Image src={welcomeBackImageSrc} maxW="21.375rem" />}
    />
  );
}

function CandidateVerifyEmailPage(props: ComponentProps<typeof CandidateAuthDefaultPageTemplate>) {
  return (
    <CandidateAuthDefaultPageTemplate
      {...props}
      leftImage={<Image src={emailVerificationImageSrc} maxW="18rem" />}
    />
  );
}

function CandidateEmailNotVerifiedSuccessPage(
  props: ComponentProps<typeof CandidateAuthDefaultPageTemplate>,
) {
  return (
    <CandidateAuthDefaultPageTemplate
      {...props}
      leftImage={<Image src={emailVerificationImageSrc} maxW="18rem" />}
    >
      <Center display={['flex', 'flex', 'none']}>
        <Image src={emailVerificationImageSrc} maxW="9.8rem" />
      </Center>
      <Heading
        variant="heading-2"
        color="brand.main"
        mt={[6, 6, 0]}
        textAlign={['center', 'center', 'left']}
      >
        Please Verify Your Email Address
      </Heading>
      <Text mt={2} textAlign={['center', 'center', 'left']}>
        A verification email has been sent to your email account, please check your inbox to verify.
        If you have any problems, please{' '}
        <Link href="https://help.terminal.io/" isExternal display="inline">
          contact us.
        </Link>
      </Text>
    </CandidateAuthDefaultPageTemplate>
  );
}

export const candidateAuthPageTemplateOverwrite: AuthenticationRouterOverwrite['pageTemplate'] = {
  default: CandidateAuthDefaultPageTemplate,
  login: CandidateLogInPage,
  signup: CandidatSignupPage,
  'incompatible-browser': IncompatibleBrowserErrorPage,
  'forgot-password': CandidateForgotPasswordPage,
  'forgot-password-success': CandidateForgotPasswordSuccessPage,
  'password-reset': CandidateResetPasswordPage,
  'password-reset-expired': CandidatePasswordResetExpiredPage,
  'verify-email': CandidateVerifyEmailPage,
  'email-not-verified': CandidateEmailNotVerifiedPage,
  'email-not-verified-success': CandidateEmailNotVerifiedSuccessPage,
  'no-access': CandidateNoAccessPage,
};
