import { Card, CardBody } from 'candidate/components';
import { Flex, Button, Center, DeleteIcon, Text, FileIcon } from '@terminal/design-system';
import type { HStepsProps } from 'candidate/components';
import { StepPageLayout } from '../../components/StepPageLayout';

export function UploadResumeShowAttachedFile({
  onNextClick,
  onSkipClick,
  initialRef,
  isNextLoading,
  onSignOutClick,
  userFullName,
  progressProps,
  onDeleteClick,
  fileName,
}: {
  initialRef: React.MutableRefObject<null>;
  isNextLoading: boolean;
  fileName: string;
  onNextClick: () => void;
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  progressProps: HStepsProps;
  onDeleteClick: () => void;
  onSkipClick: () => void;
}) {
  return (
    <StepPageLayout
      title="Upload Resume to Finalize"
      description="By uploading your resume, you can be contacted by our team sooner and quickly move ahead of 50% of candidates."
      {...{ progressProps, userFullName, onSignOutClick }}
      footer={
        <Flex flexDir={['column', 'row']} w="full" justifyContent="flex-end">
          <Button onClick={onSkipClick} variant="ghost" w={['full', 64]}>
            Skip
          </Button>
          <Button onClick={onNextClick} ml={[0, 6]} w={['full', 64]} isLoading={isNextLoading}>
            Next
          </Button>
        </Flex>
      }
    >
      <Card>
        <CardBody px={[3, 8]} py={[6, 8]}>
          <Center justifyContent="space-between" w="full">
            <Flex alignItems="center" w="full">
              <FileIcon mr={6} />
              <Text className="notranslate" variant="label" isTruncated maxWidth="full">
                {fileName}
              </Text>
              <Button
                ref={initialRef}
                variant="ghost"
                ml="auto"
                size="sm"
                onClick={onDeleteClick}
                role="button"
                aria-label="Delete file"
              >
                <DeleteIcon color="ui.error" />
              </Button>
            </Flex>
          </Center>
        </CardBody>
      </Card>
    </StepPageLayout>
  );
}
