import { trackingParamsState } from './trackingParams.state';
import { flowState } from './flow.state';

/**
 * if we need to use the values of app state directly in graphql or for some reason
 * or the other we needed them to be in apollo caching, we will need.
 * @see https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/
 * @see https://github.com/apollographql/ac3-state-management-examples
 *
 * When we use them directly in the query, then if we wanted to get the benefit of auto
 * generating types, we will need to add Graphql Type of those state
 * @see https://www.graphql-code-generator.com/docs/integrations/apollo-local-state
 */

export const appState = {
  trackingParams: trackingParamsState,
  flow: flowState,
  reset: () => {
    trackingParamsState.reset();
    flowState.reset();
  },
};
