import * as Yup from 'yup';

export const educationsForm_validationSchema = Yup.object().shape({
  school_name: Yup.string().required('Please enter a school name.'),
  degree: Yup.string().required('Please select degree.').nullable(),
  concentration: Yup.string().required('Please enter a field of study.'),
  start_year: Yup.number()
    .typeError('Please select start year.')
    .required('Please select start year.'),
  graduation_year: Yup.number()
    .test(
      'graduation-year-greater-than-start-year',
      'Please select end year that is equal or more recent than the start year.',
      function testMonth(value) {
        const { start_year } = this.parent;

        return Number(value) >= Number(start_year);
      },
    )
    .typeError('Please select end year.'),
});
