import { Icon } from '@terminal/design-system';

export function MobileIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <path
          d="m12 3c0-1.1046 0.8954-2 2-2h14c1.1046 0 2 0.89543 2 2v20c0 1.1046-0.8954 2-2 2h-11c-1.4272 0-2.3951-1.4519-1.8462-2.7692l0.428-1.0271c0.4787-1.149-0.1972-2.4516-1.4123-2.7216l-0.6034-0.1341c-0.915-0.2033-1.5661-1.0149-1.5661-1.9523v-13.396z"
          fill="#8FA25B"
        />
        <path
          d="m11 20 2.2307-1.6733c0.4601-0.3447 1.0417-0.4849 1.6084-0.3878 0.5666 0.0971 1.0684 0.4229 1.3876 0.9011v0c0.468 0.7025 0.468 1.6175 0 2.32l-2.1934 3.2893c-0.6733 1.01-1.637 1.7919-2.764 2.2427l-3.2693 1.308v3"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m12 13-4.8 2.0547c-1.416 0.6072-2.5441 1.7358-3.1507 3.152l-1.564 3.66c-0.32039 0.7474-0.48551 1.5521-0.48533 2.3653v6.768"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m12 19.251v-14.251c0-2.2091 1.7909-4 4-4h10c2.2091 0 4 1.7909 4 4v22c0 2.2091-1.7909 4-4 4h-10c-2.2091 0-4-1.7909-4-4v-0.6667"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M13.6187 25H30"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11 20l2.23-1.673c.46-.345 1.042-.485 1.61-.388.566.097 1.068.423 1.387.901v0c.468.703.468 1.617 0 2.32l-2.194 3.29c-.673 1.01-1.637 1.791-2.764 2.242L8 28v3M12 13l-4.8 2.055c-1.416.607-2.544 1.736-3.15 3.152l-1.565 3.66c-.32.747-.485 1.552-.485 2.365V31"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 19.25V5c0-2.21 1.79-4 4-4h10c2.21 0 4 1.79 4 4v22c0 2.21-1.79 4-4 4H16c-2.21 0-4-1.79-4-4v-.667M13.619 25H30"
      />
      <mask id="hj9sz2q0ua" width="32" height="32" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="currentColor" d="M0 0H32V32H0z" />
      </mask>
    </Icon>
  );
}
