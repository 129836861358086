/* eslint-disable react/no-array-index-key */
import { useRef } from 'react';
import type { BoxProps, FlexProps, SpacerProps } from '@terminal/design-system';
import type { KeenSliderOptions, KeenSliderInstance, KeenSliderHooks } from 'keen-slider/react';
import { useKeenSlider } from 'keen-slider/react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Box,
  Button,
  Circle,
  Flex,
  HStack,
} from '@terminal/design-system';
import * as React from 'react';

// TODO: Write the coursel as a compound component

export const Carousel = React.forwardRef<HTMLDivElement, FlexProps>(function Carousel(props, ref) {
  return (
    <Flex
      ref={ref}
      className="chakra-carousel"
      overflow="hidden"
      position="relative"
      userSelect="none"
      {...props}
    />
  );
});

export function CarouselSlide(props: BoxProps) {
  return (
    <Box
      className="chakra-carousel__slide"
      position="relative"
      overflow="hidden"
      width="100%"
      minH="100%"
      maxW="100% !important"
      minW="100% !important"
      {...props}
    />
  );
}

export function CarouselHorizontalControls({
  hasPrevious,
  hasNext,
  onPreviousClick,
  onNextClick,
  onStepsClick,
  currentSlideIndex,
  numberOfSlides,
  name = 'horizontal',
  ...styleProps
}: {
  hasPrevious: boolean;
  hasNext: boolean;
  onPreviousClick: () => void;
  onNextClick: () => void;
  onStepsClick?: (slideIndex: number) => void;
  currentSlideIndex: number;
  numberOfSlides: number;
  /**
   * Used only as the list key. Need to be used when more than one carousel
   * controller is on a page
   */
  name?: string;
} & SpacerProps) {
  return (
    <Flex w="full" {...styleProps}>
      <Box w={6}>
        {hasPrevious && (
          <Button
            variant="ghost"
            w={8}
            h={8}
            onClick={onPreviousClick}
            children={<ArrowLeftIcon color="accent.main" />}
            aria-label="Previous Slide"
          />
        )}
      </Box>
      <Flex flex={1} justify="center" alignItems="center" px={4}>
        <HStack
          _hover={{
            cursor: onStepsClick ? 'pointer' : 'defeault',
          }}
        >
          {Array(numberOfSlides)
            .fill(null)
            .map((_, index) => {
              if (currentSlideIndex === index) {
                return (
                  <Box
                    key={`carousel-control-${name}-${index}`}
                    w={8}
                    h={2}
                    bg="accent.main"
                    borderRadius="lg"
                  />
                );
              }
              return (
                <Circle
                  key={`carousel-control-${name}-${index}`}
                  onClick={() => {
                    if (onStepsClick) onStepsClick(index);
                  }}
                  size={2}
                  bg="ui.disabled"
                />
              );
            })}
        </HStack>
      </Flex>
      <Box w={6} mr={2}>
        {hasNext && (
          <Button
            variant="ghost"
            w={8}
            h={8}
            onClick={onNextClick}
            children={<ArrowRightIcon color="accent.main" />}
            aria-label="Next Slide"
          />
        )}
      </Box>
    </Flex>
  );
}

export type CarouselOptions = KeenSliderOptions & {
  /**
   * Set the true if the carousel is initially rendered in a UI that is not visible.
   * It will recalculates the width of sliced after it is created.
   */
  shouldDelaySizingCalculation?: boolean;
};

export const useCarousel = (options?: CarouselOptions) => {
  const timer = useRef<NodeJS.Timeout>();
  const defaultOptions = { selector: '.chakra-carousel__slide' };

  const otherOptions = options?.shouldDelaySizingCalculation
    ? {
        created(slider: KeenSliderInstance<{}, {}, KeenSliderHooks>) {
          timer.current = setTimeout(() => {
            slider.update();
          }, 200);
        },
        destroyed() {
          clearTimeout(timer.current as NodeJS.Timeout);
        },
      }
    : {};

  return useKeenSlider<HTMLDivElement>({
    ...defaultOptions,
    ...otherOptions,
    renderMode: 'precision',
    ...options,
  });
};
