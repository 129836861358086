import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import Sentry from 'global/sentry';
import { CustomRoute, PublicPageLoading, SwitchWithPageNotFound } from 'candidate/components';
import type { LocationTypeAheadProps } from 'global/components';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useImportGooglePlacesScript, useRedirection } from 'global/utils';
import { useToast } from '@terminal/design-system';
import type { SelectCountryListQuery, SelectCountryListQueryVariables } from 'candidate/utils/data';
import { SelectCountryList } from 'candidate/utils/data';
import { InsertApplicantWaitlist } from '../../graphql';
import type {
  InsertApplicantWaitlistMutation,
  InsertApplicantWaitlistMutationVariables,
} from '../../graphql/types';
import type { CountryNotSupportedForm } from './CountryNotSupported.types';
import { CountryNotSupported } from './CountryNotSupported';
import type { PersonalInfoForm } from '../ApplicationSections/PersonalInfo.controller';
import { CountryNotSupportedThankYou } from './CountryNotSupportedThankYou';

const validationSchema = Yup.object().shape({
  country: Yup.string().required('Location is a required field.'),
  city: Yup.string().notRequired().nullable(),
  state: Yup.string().notRequired().nullable(),
  latitude: Yup.string().notRequired().nullable(),
  longitude: Yup.string().notRequired().nullable(),
  country_id: Yup.number().notRequired().nullable(),
});

export function CountryNotSupportedFlowController({
  personalInfo,
  parentNestedPageTitles,
}: {
  personalInfo: PersonalInfoForm;
  parentNestedPageTitles: string[];
}) {
  const { path, url } = useRouteMatch();
  const redirectTo = useRedirection();
  const [isApplicationCompleted, setSsApplicationCompleted] = useState(false);

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });
  const normalizedCountries = useQuery<SelectCountryListQuery, SelectCountryListQueryVariables>(
    SelectCountryList,
  );
  const placeIDToCountryIDMap: Record<string, number> =
    normalizedCountries.data?.country_choices?.reduce(
      (map: Record<string, number>, obj: { place_id: string | null; id: number }) => {
        if (!obj.place_id) return map;
        return {
          ...map,
          [obj.place_id]: obj.id,
        };
      },
      {},
    ) || {};

  const isGooglePlaceAPIReady = useImportGooglePlacesScript();

  const [insertApplicantWaitlist, { loading: isSubmissionLoading }] = useMutation<
    InsertApplicantWaitlistMutation,
    InsertApplicantWaitlistMutationVariables
  >(InsertApplicantWaitlist, {
    onCompleted: () => {
      setSsApplicationCompleted(true);
      redirectTo(`${url}/joined-waitlist`);
    },
    onError: (error: ApolloError) => {
      if (
        error.message ===
        'Uniqueness violation. duplicate key value violates unique constraint "applicant_waitlist_email_key"'
      ) {
        toast({
          description: 'Your email is already in our waitlist.',
          status: 'error',
        });
        return;
      }
      toast({
        description: 'Something went wrong trying to add you to the waitlist. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
  });

  const formik = useFormik<CountryNotSupportedForm>({
    initialValues: {
      country: '',
      country_id: null,
      city: '',
      state: '',
      latitude: '',
      longitude: '',
      isNoticeChecked: false,
      isPrivacyChecked: false,
    },
    validationSchema,
    onSubmit: ({
      country,
      state,
      city,
      latitude,
      longitude,
      country_id,
    }: CountryNotSupportedForm) => {
      insertApplicantWaitlist({
        variables: {
          objects: {
            email: personalInfo.email,
            first_name: personalInfo.firstName,
            last_name: personalInfo.lastName,
            city: city === '' ? null : city,
            country_id,
            state: state === '' ? null : state,
            country: country === '' ? null : country,
            point: {
              type: 'Point',
              coordinates: [latitude, longitude],
            },
          },
        },
      });
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleLocationChange = ({
    latitude,
    longitude,
    city,
    state,
    country,
    country_id,
  }: Parameters<LocationTypeAheadProps['onChange']>[number]) => {
    formik.setFieldValue('latitude', latitude || '');
    formik.setFieldValue('longitude', longitude || '');
    formik.setFieldValue('city', city);
    formik.setFieldValue('state', state);
    formik.setFieldValue('country', country);
    formik.setFieldValue('country_id', country_id);
  };

  if (isGooglePlaceAPIReady !== 'ready' || normalizedCountries.loading) {
    return <PublicPageLoading />;
  }

  return (
    <SwitchWithPageNotFound>
      <CustomRoute
        path={`${path}/share-location`}
        title={[...parentNestedPageTitles, 'Share Location']}
        shouldRedirect={isApplicationCompleted}
        redirectURL={`${url}/joined-waitlist`}
      >
        <CountryNotSupported
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          onJoinWaitListClick={formik.handleSubmit}
          onLocationChange={handleLocationChange}
          placeIDToCountryIDMap={placeIDToCountryIDMap}
          isSubmissionLoading={isSubmissionLoading}
        />
      </CustomRoute>
      <CustomRoute
        path={`${path}/joined-waitlist`}
        title={[...parentNestedPageTitles, 'Joined Waitlist']}
        redirectURL={`${url}/share-location`}
        shouldRedirect={!(formik.isValid && formik.dirty)}
      >
        <CountryNotSupportedThankYou />
      </CustomRoute>
      <Route
        path={path}
        exact
        render={() => (
          <Redirect
            to={{
              pathname: `${url}/share-location`,
            }}
          />
        )}
      />
    </SwitchWithPageNotFound>
  );
}
