import { Icon } from '@terminal/design-system';

export function ProductManagerIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <g
          clipPath="url(#a)"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M28.594 26.656h-3.875l-5.813 3.875v-3.875h-1.937a1.937 1.937 0 0 1-1.938-1.937v-7.75a1.937 1.937 0 0 1 1.938-1.938h11.625a1.937 1.937 0 0 1 1.937 1.938v7.75a1.937 1.937 0 0 1-1.937 1.937Z"
            fill="#A0CFDA"
          />
          <path d="M18.906 18.906h7.75M18.906 22.781h7.75" />
          <path
            d="M4.375 5.344a3.875 3.875 0 1 0 7.75 0 3.874 3.874 0 1 0-7.75 0Z"
            fill="#EFD58B"
          />
          <path d="M12.254 12.466a6.783 6.783 0 0 0-10.785 5.471v2.906h2.906l.969 9.688h5.813l.334-3.351M18.225 4.86a3.39 3.39 0 1 0 6.782 0 3.39 3.39 0 0 0-6.782 0ZM26.65 12.125a5.812 5.812 0 0 0-9.41-.917" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h32v32H0z" />
          </clipPath>
        </defs>
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <g
        clipPath="url(#a)"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M28.594 26.656h-3.875l-5.813 3.875v-3.875h-1.937a1.937 1.937 0 0 1-1.938-1.937v-7.75a1.937 1.937 0 0 1 1.938-1.938h11.625a1.937 1.937 0 0 1 1.937 1.938v7.75a1.937 1.937 0 0 1-1.937 1.937ZM18.906 18.906h7.75M18.906 22.781h7.75M4.375 5.344a3.875 3.875 0 1 0 7.75 0 3.874 3.874 0 1 0-7.75 0ZM12.254 12.466a6.783 6.783 0 0 0-10.785 5.471v2.906h2.906l.969 9.688h5.813l.334-3.351M18.225 4.86a3.39 3.39 0 1 0 6.781 0 3.39 3.39 0 0 0-6.78 0ZM26.65 12.125a5.812 5.812 0 0 0-9.41-.917" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
}
