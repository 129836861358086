/* eslint-disable react/jsx-no-useless-fragment */
import type { SpaceProps } from '@terminal/design-system';
import {
  Flex,
  Button,
  Heading,
  Text,
  Box,
  ChatBubble,
  ArrowRightIcon,
} from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';
import { Link as RouterLink } from 'react-router-dom';
import PatternGrid from './assets/pattern-grid.svg?url';

export function HandpickedJobsBanner({
  shouldShowCTA = true,
  ...spaceProps
}: { shouldShowCTA?: boolean } & SpaceProps) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <Flex
      alignItems="center"
      bgColor="brand.lightest"
      bgImage={isDesktop ? PatternGrid : undefined}
      bgPosition="right center"
      bgRepeat="no-repeat"
      justifyContent="space-between"
      p={[3, 3, 6]}
      {...spaceProps}
    >
      <Flex gridGap={[2, 2, 6]} alignItems="center">
        <ChatBubble w={[8, 8, 20]} h={[8, 8, 20]} />
        <Box>
          <Heading variant={isDesktop ? 'heading-2' : 'heading-4'}>
            New Handpicked Jobs to Review
          </Heading>
          <Text variant={isDesktop ? 'body' : 'hint'}>
            Please review the jobs your Talent Partner picked for you.
          </Text>
        </Box>
      </Flex>
      {shouldShowCTA && (
        <>
          {isDesktop ? (
            <Button
              as={RouterLink}
              bgColor="brand.lightest"
              colorScheme="accent"
              to="/my-jobs"
              variant="outline"
            >
              Review Jobs
            </Button>
          ) : (
            <Button colorScheme="accent" variant="ghost" as={RouterLink} to="/my-jobs" ml={2}>
              <ArrowRightIcon color="black" />
            </Button>
          )}
        </>
      )}
    </Flex>
  );
}
