import type { ApolloError } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { SelectMyJobsQuery } from 'candidate/shared/modules/job/MyJobs/graphql';
import { SelectMyJobs } from 'candidate/shared/modules/job/MyJobs/graphql';
import { useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import type { Candidate_Job_Workflow_Status_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import moment from 'moment';
import { useState } from 'react';
import { JobWorkflowStatus } from './data';
import type {
  JobWorkflowStatusMutation,
  JobWorkflowStatusMutationVariables,
} from './data/types/JobWorkflowStatus.graphql-service.query.generated';

export const useHandpickedJobsByTPController = ({
  userID,
  feedInitialDate,
  onComplete,
}: {
  userID: number;
  feedInitialDate?: string | moment.Moment;
  onComplete: () => void;
}) => {
  const toast = useToast();
  const [feedInitialDateState] = useState(feedInitialDate || moment().subtract(3, 'month'));

  const [applyToJob, { loading: isLoading_applyToJob }] = useMutation<
    JobWorkflowStatusMutation,
    JobWorkflowStatusMutationVariables
  >(JobWorkflowStatus, {
    context: { role: 'candidate', service: 'service' },
    onCompleted: () => {
      toast({
        description: 'You have successfully applied to this job.',
        status: 'success',
      });
      onComplete();
    },
    onError: (error: ApolloError) => {
      toast({
        description: 'There was an error applying to this job. Please try again later.',
        status: 'error',
      });

      Sentry.captureException(error);
    },
    update(cache, _, { variables }) {
      try {
        const readData = cache.readQuery<SelectMyJobsQuery>({
          query: SelectMyJobs,
          variables: {
            user_id: userID as number,
            feed_initial_date: feedInitialDateState,
          },
        });

        if (
          readData?.candidate[0]?.candidate_job_workflows?.find(
            (workflow) => workflow.id === variables?.workflowId,
          )?.status &&
          variables?.workflowId
        ) {
          readData.candidate[0].candidate_job_workflows.find(
            (workflow) => workflow.id === variables.workflowId,
          )!.status = variables.status as Candidate_Job_Workflow_Status_Choices_Enum;
        }

        cache.writeQuery({
          query: SelectMyJobs,
          broadcast: true,
          variables: {
            user_id: userID as number,
            feed_initial_date: feedInitialDateState,
          },
          data: readData,
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  const [declineJob, { loading: isLoading_declineJob }] = useMutation<
    JobWorkflowStatusMutation,
    JobWorkflowStatusMutationVariables
  >(JobWorkflowStatus, {
    context: { role: 'candidate', service: 'service' },
    onCompleted: () => {
      toast({
        description: "You've marked that you are not interested in this job",
        status: 'success',
      });
      onComplete();
    },
    onError: (error: ApolloError) => {
      toast({
        description: 'There was an error. Please try again later.',
        status: 'error',
      });

      Sentry.captureException(error);
    },
    update(cache, _, { variables }) {
      try {
        const readData = cache.readQuery<SelectMyJobsQuery>({
          query: SelectMyJobs,
          variables: {
            user_id: userID as number,
            feed_initial_date: feedInitialDateState,
          },
        });

        if (
          readData?.candidate[0]?.candidate_job_workflows?.find(
            (workflow) => workflow.id === variables?.workflowId,
          )?.status &&
          variables?.workflowId
        ) {
          readData.candidate[0].candidate_job_workflows.find(
            (workflow) => workflow.id === variables.workflowId,
          )!.status = variables.status as Candidate_Job_Workflow_Status_Choices_Enum;
        }

        cache.writeQuery({
          query: SelectMyJobs,
          broadcast: true,
          variables: {
            user_id: userID as number,
            feed_initial_date: feedInitialDateState,
          },
          data: readData,
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  return {
    applyToJob,
    isLoading_applyToJob,
    declineJob,
    isLoading_declineJob,
  };
};
