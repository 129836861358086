/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { useState, useEffect, useRef } from 'react';
import type { RefObject } from 'react';
import {
  Divider,
  Box,
  Flex,
  Text,
  Heading,
  Image,
  Tag,
  Wrap,
  WrapItem,
  VStack,
  HStack,
  Button,
  CheckCircleSolidIcon,
  BuildingIcon,
  BriefcaseIcon,
  WalletIcon,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  ArrowRightIcon,
  TooltipIcon,
  Link,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  StopwatchIcon,
  linkTheme,
  VerticalSteps,
} from '@terminal/design-system';
import { useIsViewPortDesktop, debounce, stripAllHTMLAttributes } from 'global/utils';
import * as events from 'global/events';
import { CardWithViewMore } from 'global/components';
import hiringMarketsSrc from 'candidate/assets/images/hiring-markets.svg?url';
import salaryIllustration from './assets/salary.svg?url';
import noMatchImageSource from '../assets/no-job-matches.svg?url';
import type { JobDetailDataProps } from './JobDetail.types';

function JobSpecificationCard({
  title,
  description,
  isDesktop,
}: {
  title: string;
  description: string;
  isDesktop: boolean;
}) {
  const [shouldShowFullDescription, setShouldShowFullDescription] = useState(false);
  return (
    <CardWithViewMore
      header={<Heading variant="heading-4">{title}</Heading>}
      description={description}
      onViewMoreClick={() => setShouldShowFullDescription(!shouldShowFullDescription)}
      isDesktop={isDesktop}
      shouldShowFullBody={shouldShowFullDescription}
    />
  );
}

export function JobDetailInterviewDetails({
  jobDetailData,
}: {
  jobDetailData: JobDetailDataProps | null;
}) {
  return (
    <>
      <Heading variant="heading-4">Interview process</Heading>
      <VerticalSteps
        ml={[8, 8, 6]}
        mt={2}
        timelineColor="accent.lighter"
        steps={jobDetailData?.interviewDetails?.map((interviewDetail) => (
          <>
            <Text variant="caption" fontWeight="bold">
              {interviewDetail.title}
            </Text>
            <Flex alignItems="center" mt={1}>
              <StopwatchIcon />
              <Text variant="caption" fontWeight="bold" color="accent.main" ml={1}>
                {interviewDetail.duration}
              </Text>
            </Flex>
            <Text
              variant="caption"
              mt={1}
              whiteSpace="pre-line"
              sx={{
                ul: { listStyle: 'disc' },
                li: { margin: 2, marginLeft: 4 },
                a: { ...linkTheme.baseStyle({}), display: 'inline', fontSize: 'inherit' },
              }}
              dangerouslySetInnerHTML={{
                __html: stripAllHTMLAttributes(interviewDetail.description),
              }}
            />
          </>
        ))}
      />
    </>
  );
}

function SalaryBanner({
  jobDetailData,
  salaryBanner,
}: {
  jobDetailData: JobDetailDataProps;
  salaryBanner?: RefObject<HTMLDivElement>;
}) {
  return (
    <Box pb={[6, 0, 0]}>
      <Divider borderColor="ui.secondary" my={[4, 4, 6]} opacity={1} />
      <Flex
        bgColor="bg.secondary"
        border="1px solid"
        borderColor="ui.disabled.dark"
        borderRadius="base"
        flexDirection={['column', 'column', 'row']}
        gridGap={6}
        p={4}
        ref={salaryBanner}
      >
        <Image src={salaryIllustration} alt="Salary range" w={[18, 18]} />
        <Box>
          <Heading variant="heading-4" color="text.secondary">
            {jobDetailData?.employmentType === 'Contractor' ? 'Pay Range' : 'Salary Range'}
          </Heading>
          <Text variant="caption" color="text.primary" mt={2}>
            {jobDetailData?.employmentType === 'Contractor'
              ? 'The pay range provided is based on the current job market and will be paid in USD. The pay you could be offered is based on your work experience and performance in the interviews, and does not include benefits, equity or bonuses.'
              : 'The salary range provided is based on the current job market in your location, and will be paid in your local currency. The salary you could be offered is based on your work experience and performance in the interviews, and does not include benefits, equity or bonuses.'}

            {!!jobDetailData.talentPartnerEmail && (
              <span> For more information contact your Talent Partner.</span>
            )}
          </Text>

          {!!jobDetailData.talentPartnerEmail && (
            <Button
              colorScheme="accent"
              mt={[5, 5, 6]}
              variant="outline"
              w={['full', 'full', 'unset']}
              onClick={() => {
                events.track(events.name.ctaClicked, {
                  name: 'contact-talent-partner',
                });
                window.location.href = `mailto:${jobDetailData.talentPartnerEmail}`;
              }}
            >
              Contact Talent Partner
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

export function JobDrawerDetail({
  applyCTAText = 'Apply',
  btnRef,
  doesHaveJobMatches,
  hasAlreadyApplied,
  isApplyButtonLoading,
  isOpen,
  jobDetailData,
  onApplyClick,
  onClose = () => {},
  onCompleteProfileClick,
  onAboutCompanyClick,
  isCountrySupported = true,
}: {
  applyCTAText?: string;
  btnRef?: RefObject<HTMLElement>;
  doesHaveJobMatches: boolean;
  hasAlreadyApplied: boolean;
  isApplyButtonLoading: boolean;
  isOpen: boolean;
  jobDetailData: JobDetailDataProps;
  onApplyClick?: () => void;
  onClose?: () => void;
  onCompleteProfileClick?: () => void;
  onAboutCompanyClick?: (organizationID: number) => void;
  isCountrySupported?: boolean;
}) {
  const [viewportHeight, setViewportHeight] = useState(0);
  const salaryBanner = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onResize = debounce(() => {
      setViewportHeight(() => window.innerHeight);
    }, 250);
    setViewportHeight(() => window.innerHeight);

    document.addEventListener('resize', onResize);

    return () => {
      document.removeEventListener('resize', onResize);
    };
  }, []);

  if (!doesHaveJobMatches) {
    return (
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent px={3}>
          <DrawerCloseButton zIndex="sticky" left="auto" />
          <DrawerBody px={3} py={12}>
            <Flex
              flexDir="column"
              alignItems="center"
              height={viewportHeight ? `${viewportHeight - 120}px` : 'calc(100vh - 120px)'}
              minH="sm"
              justifyContent="center"
              bgColor={['transparent', 'transparent', 'ui.inverse.secondary']}
            >
              {isCountrySupported ? (
                <>
                  <Image src={noMatchImageSource} alt="No job suggestion match" maxW="145px" />
                  <Heading mt={6} variant="heading-2" color="text.primary">
                    You Have No Matches Yet!
                  </Heading>
                  <Text mt={2} color="text.primary">
                    Fill your profile to get matching jobs!
                  </Text>

                  <Button
                    onClick={onCompleteProfileClick}
                    mt={6}
                    w={[350, 64]}
                    position="absolute"
                    bottom={6}
                  >
                    Complete Profile
                  </Button>
                </>
              ) : (
                <>
                  <Image
                    h="auto"
                    w="full"
                    maxWidth="15.402rem"
                    objectFit="cover"
                    src={hiringMarketsSrc}
                    alt="No job suggestion match"
                  />
                  <Heading mt={6} variant="heading-2" color="text.primary">
                    You Have No Matches Yet!
                  </Heading>
                  <Text mt={2} color="text.primary" textAlign="center" px={20}>
                    We do not have any jobs available for your location. We will let you know if we
                    expand to your area
                  </Text>
                </>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} finalFocusRef={btnRef}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton zIndex="sticky" left="auto" />
        <DrawerBody px={3} py={0} className="notranslate">
          <Box
            pb={6}
            pt={14}
            height={viewportHeight ? `${viewportHeight - 80}px` : 'calc(100vh - 80px)'}
            maxW="full"
            overflowY="auto"
            overflowX="hidden"
          >
            {jobDetailData?.logo && (
              <Box w="full" mb={4}>
                <Image
                  w="full"
                  maxW="xs"
                  h={16}
                  src={jobDetailData?.logo.src}
                  alt={jobDetailData?.logo.alt}
                  objectFit="contain"
                  objectPosition="left"
                />
              </Box>
            )}
            {jobDetailData?.companyName && jobDetailData?.organizationID && (
              <Link
                color="accent.main"
                display="inline-block"
                onClick={() => onAboutCompanyClick?.(jobDetailData.organizationID as number)}
              >
                {jobDetailData?.companyName}
              </Link>
            )}
            {jobDetailData?.title && (
              <Heading color="text.primary" variant="heading-2">
                {jobDetailData?.title}
              </Heading>
            )}
            <Divider borderColor="ui.secondary" my={4} opacity={1} />

            <Flex gridColumnGap={6} gridRowGap={3} flexWrap="wrap">
              {jobDetailData?.industry && (
                <Flex alignItems="center">
                  <BuildingIcon fontSize="2xl" color="ui.darker.disabled" />
                  <Flex flexDir="column" ml={3}>
                    <Text variant="hint" fontWeight="bold">
                      Industry
                    </Text>
                    <Text variant="caption">{jobDetailData?.industry}</Text>
                  </Flex>
                </Flex>
              )}

              {jobDetailData?.employmentType && (
                <Flex alignItems="center">
                  <BriefcaseIcon fontSize="2xl" color="ui.darker.disabled" />
                  <Flex flexDir="column" ml={3}>
                    <Text variant="hint" fontWeight="bold">
                      Job Type
                    </Text>
                    <Text variant="caption">{jobDetailData?.employmentType}</Text>
                  </Flex>
                </Flex>
              )}

              {jobDetailData?.salary && (
                <Flex alignItems="center">
                  <WalletIcon fontSize="2xl" color="ui.darker.disabled" />
                  <Flex flexDir="column" ml={3}>
                    <Text variant="hint" fontWeight="bold">
                      Salary
                    </Text>
                    <Text variant="caption">{jobDetailData?.salary}</Text>
                  </Flex>
                  <TooltipIcon
                    color="ui.darker.disabled"
                    ml={3}
                    cursor="pointer"
                    onClick={() =>
                      salaryBanner.current &&
                      salaryBanner.current.scrollIntoView({ behavior: 'smooth' })
                    }
                  />
                </Flex>
              )}
            </Flex>

            {jobDetailData.organizationID && (
              <Button
                fontWeight="bold"
                variant="ghost"
                color="accent.main"
                rightIcon={<ArrowRightIcon fontSize="xs" />}
                mt={2}
                onClick={() => onAboutCompanyClick?.(jobDetailData.organizationID as number)}
              >
                About {jobDetailData?.companyName}
              </Button>
            )}

            <Divider borderColor="ui.secondary" my={4} opacity={1} />

            {jobDetailData?.techStacks && jobDetailData?.techStacks.length > 0 && (
              <>
                <Wrap mt={3} data-testid="tech-stack-wrapper">
                  {jobDetailData?.techStacks.map(({ stack, shouldHighlight }) => (
                    <WrapItem key={`techStack-${stack}`}>
                      <Tag
                        colorScheme={shouldHighlight ? 'success' : 'accent-lightest'}
                        size="sm"
                        variant="subtle"
                      >
                        {stack}
                      </Tag>
                    </WrapItem>
                  ))}
                </Wrap>

                {jobDetailData.topApplicantInfo.isTopApplicant && (
                  <Box alignItems="center" backgroundColor="accent.lightest" mt={6} p={4}>
                    <Heading variant="heading-4">You Are a Top Applicant</Heading>
                    <Text variant="caption" mt={1}>
                      Your experience matches the minimum requirements for this role.
                    </Text>
                    <Flex gridGap={4} mt={3}>
                      <Flex alignItems="center">
                        <CheckCircleSolidIcon color="ui.success" mr={1} />
                        <Text>
                          {jobDetailData.topApplicantInfo.numberOfSkillsMatched} Skills Match
                        </Text>
                      </Flex>

                      <Flex alignItems="center">
                        <CheckCircleSolidIcon color="ui.success" mr={1} />
                        <Text>{jobDetailData.topApplicantInfo.yearsOfExperience}</Text>
                      </Flex>
                    </Flex>
                  </Box>
                )}
              </>
            )}
            <Box flex="1" pb={6}>
              {jobDetailData?.interviewDetails && jobDetailData.interviewDetails?.length > 0 ? (
                <Tabs mt={[4, 4, 6]}>
                  <TabList>
                    <Tab
                      border="none"
                      _focus={{
                        backgroundColor: 'unset',
                      }}
                      _selected={{
                        color: 'accent.main',
                        border: 'none',
                        borderBottom: '2px solid',
                        borderColor: 'accent.main',
                      }}
                    >
                      Job Description
                    </Tab>
                    <Tab
                      border="none"
                      _focus={{
                        backgroundColor: 'unset',
                      }}
                      _selected={{
                        color: 'accent.main',
                        border: 'none',
                        borderBottom: '2px solid',
                        borderColor: 'accent.main',
                      }}
                    >
                      Interview Details
                    </Tab>
                  </TabList>
                  <TabPanels>
                    {/* Job Description */}
                    <TabPanel pt={4}>
                      {(jobDetailData?.descriptiveInfos || []).map((info) => (
                        <>
                          <JobSpecificationCard
                            title={info.title}
                            description={info.description}
                            isDesktop={false}
                            key={`job-info-${info.title}`}
                          />

                          <Divider
                            borderColor="ui.secondary"
                            my={6}
                            opacity={1}
                            _last={{ display: 'none ' }}
                          />
                        </>
                      ))}
                      {jobDetailData?.tagInfos && jobDetailData?.tagInfos.length > 0 && (
                        <VStack
                          spacing={[3, 3, 6]}
                          alignItems="start"
                          aria-label="tag-infos-vstack"
                        >
                          {jobDetailData?.tagInfos.map((tagInfo) => (
                            <Box key={`tag-info-${tagInfo.title}`}>
                              <Text variant="caption" fontWeight="bold" color="accent.darkest">
                                {tagInfo.title}
                              </Text>
                              <Text mt={3}>{tagInfo.description}</Text>
                            </Box>
                          ))}
                        </VStack>
                      )}
                      {!!jobDetailData?.salary && (
                        <SalaryBanner jobDetailData={jobDetailData} salaryBanner={salaryBanner} />
                      )}
                    </TabPanel>
                    {/* Interview Details */}
                    <TabPanel pt={4}>
                      <JobDetailInterviewDetails jobDetailData={jobDetailData} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              ) : (
                <>
                  {(jobDetailData?.descriptiveInfos || []).map((info) => (
                    <>
                      <Divider borderColor="ui.secondary" my={4} opacity={1} />
                      <JobSpecificationCard
                        title={info.title}
                        description={info.description}
                        isDesktop={false}
                        key={`job-info-${info.title}`}
                      />
                    </>
                  ))}

                  {!!jobDetailData?.salary && (
                    <SalaryBanner jobDetailData={jobDetailData} salaryBanner={salaryBanner} />
                  )}
                </>
              )}
            </Box>

            <Box position="sticky" w="full" bottom={0} zIndex="overlay" bg="bg.primary">
              <Button
                onClick={onApplyClick}
                isLoading={isApplyButtonLoading}
                isDisabled={hasAlreadyApplied}
                leftIcon={hasAlreadyApplied ? <CheckCircleSolidIcon fontSize="md" /> : undefined}
                w="full"
              >
                {hasAlreadyApplied ? 'Applied' : applyCTAText}
              </Button>
            </Box>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export function JobDescription({
  jobDetailData,
  salaryBanner,
}: {
  jobDetailData: JobDetailDataProps | null;
  salaryBanner?: RefObject<HTMLDivElement>;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <Box flex="1">
      {(jobDetailData?.descriptiveInfos || []).map((info) => (
        <>
          <JobSpecificationCard
            title={info.title}
            description={info.description}
            isDesktop={!!isDesktop}
            key={`job-info-${info.title}`}
          />
          <Divider borderColor="ui.secondary" my={6} opacity={1} _last={{ display: 'none ' }} />
        </>
      ))}
      {jobDetailData?.tagInfos && jobDetailData?.tagInfos.length > 0 && (
        <VStack spacing={[3, 3, 6]} alignItems="start" aria-label="tag-infos-vstack">
          {jobDetailData?.tagInfos.map((tagInfo) => (
            <Box key={`tag-info-${tagInfo.title}`}>
              <Text variant="caption" fontWeight="bold" color="accent.darkest">
                {tagInfo.title}
              </Text>
              <Text mt={3}>{tagInfo.description}</Text>
            </Box>
          ))}
        </VStack>
      )}

      {!!jobDetailData?.salary && (
        <SalaryBanner jobDetailData={jobDetailData} salaryBanner={salaryBanner} />
      )}
    </Box>
  );
}

export function JobDetail({
  applyCTAText = 'Apply',
  focusDrawerBtnRef,
  hasAlreadyApplied,
  isApplyButtonLoading,
  isDrawerOpen = false,
  jobDetailData,
  isCountrySupported = true,
  onApplyClick,
  onCompleteProfileClick,
  onDrawerClose,
  onAboutCompanyClick,
}: {
  applyCTAText?: string;
  focusDrawerBtnRef?: RefObject<HTMLElement>;
  hasAlreadyApplied: boolean;
  isApplyButtonLoading: boolean;
  isDrawerOpen?: boolean;
  jobDetailData: JobDetailDataProps;
  onApplyClick?: () => void;
  onCompleteProfileClick?: () => void;
  onDrawerClose?: () => void;
  onAboutCompanyClick?: (organizationID: number) => void;
  isCountrySupported?: boolean;
}) {
  const isDesktop = useIsViewPortDesktop();
  const salaryBanner = useRef<HTMLDivElement>(null);

  return (
    <Box className="notranslate">
      {isDesktop && (
        <Box w="full" bg="ui.inverse.secondary">
          <Box p={8}>
            {jobDetailData?.logo && (
              <Box w="full" mb={6}>
                <Image
                  w="full"
                  maxW="xs"
                  h={16}
                  src={jobDetailData?.logo.src}
                  alt={jobDetailData?.logo.alt}
                  objectFit="contain"
                  objectPosition="left"
                />
              </Box>
            )}
            <HStack justifyContent="space-between" w="full" m={0}>
              <Box>
                {jobDetailData?.companyName && jobDetailData.organizationID && (
                  <Link
                    color="accent.main"
                    display="inline-block"
                    onClick={() => onAboutCompanyClick?.(jobDetailData.organizationID as number)}
                  >
                    {jobDetailData?.companyName}
                  </Link>
                )}
                {jobDetailData?.title && (
                  <Heading color="text.primary" variant="heading-2">
                    {jobDetailData?.title}
                  </Heading>
                )}
              </Box>
              <Button
                onClick={onApplyClick}
                isLoading={isApplyButtonLoading}
                isDisabled={hasAlreadyApplied}
                leftIcon={hasAlreadyApplied ? <CheckCircleSolidIcon fontSize="md" /> : undefined}
              >
                {hasAlreadyApplied ? 'Applied' : applyCTAText}
              </Button>
            </HStack>
            <Divider borderColor="ui.secondary" my={6} opacity={1} />
            <Flex justifyContent="space-between" flexWrap="wrap" gridRowGap={3}>
              <Flex gridColumnGap={6} flexWrap="nowrap">
                {jobDetailData?.industry && (
                  <Flex alignItems="center">
                    <BuildingIcon fontSize="2xl" color="ui.darker.disabled" />
                    <Flex flexDir="column" ml={3}>
                      <Text variant="hint" fontWeight="bold">
                        Industry
                      </Text>
                      <Text variant="caption">{jobDetailData?.industry}</Text>
                    </Flex>
                  </Flex>
                )}

                {jobDetailData?.employmentType && (
                  <Flex alignItems="center">
                    <BriefcaseIcon fontSize="2xl" color="ui.darker.disabled" />
                    <Flex flexDir="column" ml={3}>
                      <Text variant="hint" fontWeight="bold">
                        Job Type
                      </Text>
                      <Text variant="caption">{jobDetailData?.employmentType}</Text>
                    </Flex>
                  </Flex>
                )}

                {jobDetailData?.salary && (
                  <Flex alignItems="center">
                    <WalletIcon fontSize="2xl" color="ui.darker.disabled" />
                    <Flex flexDir="column" ml={3}>
                      <Text variant="hint" fontWeight="bold">
                        Salary
                      </Text>
                      <Text variant="caption">{jobDetailData?.salary}</Text>
                    </Flex>
                    <TooltipIcon
                      color="ui.darker.disabled"
                      ml={3}
                      cursor="pointer"
                      onClick={() =>
                        salaryBanner.current &&
                        salaryBanner.current.scrollIntoView({ behavior: 'smooth' })
                      }
                    />
                  </Flex>
                )}
              </Flex>
              {jobDetailData?.organizationID && (
                <Button
                  fontWeight="bold"
                  variant="ghost"
                  color="accent.main"
                  rightIcon={<ArrowRightIcon fontSize="xs" />}
                  onClick={() => onAboutCompanyClick?.(jobDetailData.organizationID as number)}
                >
                  About {jobDetailData?.companyName}
                </Button>
              )}
            </Flex>

            <Divider borderColor="ui.secondary" my={6} opacity={1} />

            {jobDetailData?.techStacks && jobDetailData?.techStacks.length > 0 && (
              <>
                <Wrap mt={3} data-testid="tech-stack-wrapper">
                  {jobDetailData?.techStacks.map(({ stack, shouldHighlight }) => (
                    <WrapItem key={`techStack-${stack}`}>
                      <Tag
                        colorScheme={shouldHighlight ? 'success' : 'accent-lightest'}
                        size="sm"
                        variant="subtle"
                      >
                        {stack}
                      </Tag>
                    </WrapItem>
                  ))}
                </Wrap>

                {jobDetailData.topApplicantInfo.isTopApplicant && (
                  <HStack
                    alignItems="center"
                    backgroundColor="accent.lightest"
                    flex={1}
                    justifyContent="space-between"
                    mt={6}
                    p={4}
                  >
                    <Box>
                      <Heading variant="heading-4">You Are a Top Applicant</Heading>
                      <Text variant="caption" maxW={64}>
                        Your experience matches the minimum requirements for this role.
                      </Text>
                    </Box>
                    <Flex gridGap={4}>
                      <Flex alignItems="center">
                        <CheckCircleSolidIcon color="ui.success" mr={1} />
                        <Text>
                          {jobDetailData.topApplicantInfo.numberOfSkillsMatched} Skills Match
                        </Text>
                      </Flex>

                      <Flex alignItems="center">
                        <CheckCircleSolidIcon color="ui.success" mr={1} />
                        <Text>{jobDetailData.topApplicantInfo.yearsOfExperience}</Text>
                      </Flex>
                    </Flex>
                  </HStack>
                )}
              </>
            )}

            {jobDetailData?.interviewDetails && jobDetailData.interviewDetails?.length > 0 ? (
              <Tabs mt={[4, 4, 6]}>
                <TabList>
                  <Tab
                    border="none"
                    _focus={{
                      backgroundColor: 'unset',
                    }}
                    _selected={{
                      color: 'accent.main',
                      borderBottom: '2px solid',
                      borderColor: 'accent.main',
                    }}
                  >
                    Job Description
                  </Tab>
                  <Tab
                    border="none"
                    _focus={{
                      backgroundColor: 'unset',
                    }}
                    _selected={{
                      color: 'accent.main',
                      borderBottom: '2px solid',
                      borderColor: 'accent.main',
                    }}
                  >
                    Interview Details
                  </Tab>
                </TabList>
                <TabPanels>
                  {/* Job Description */}
                  <TabPanel pt={6}>
                    <JobDescription jobDetailData={jobDetailData} salaryBanner={salaryBanner} />
                  </TabPanel>
                  {/* Interview Details */}
                  <TabPanel pt={6}>
                    <JobDetailInterviewDetails jobDetailData={jobDetailData} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            ) : (
              <>
                <Divider borderColor="ui.secondary" my={6} opacity={1} />
                <JobDescription jobDetailData={jobDetailData} salaryBanner={salaryBanner} />
              </>
            )}
          </Box>
        </Box>
      )}
      {!isDesktop && (
        <JobDrawerDetail
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          btnRef={focusDrawerBtnRef}
          onApplyClick={onApplyClick}
          isApplyButtonLoading={isApplyButtonLoading}
          hasAlreadyApplied={hasAlreadyApplied}
          isCountrySupported={isCountrySupported}
          doesHaveJobMatches={
            !!(jobDetailData?.title && jobDetailData?.companyName && jobDetailData?.techStacks)
          }
          onCompleteProfileClick={onCompleteProfileClick}
          applyCTAText={applyCTAText}
          jobDetailData={jobDetailData}
          onAboutCompanyClick={onAboutCompanyClick}
        />
      )}
    </Box>
  );
}
