import { Icon } from '@terminal/design-system';

export function DataAnalystIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
        <path
          d="M9.71825 7.21512L4.01514 12.9182"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.2855 8.92173L13.6437 7.15817"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.9324 4.04358L23.8408 8.0258"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6667 8.46668C12.8449 8.46668 13.8 7.51155 13.8 6.33335C13.8 5.15514 12.8449 4.20001 11.6667 4.20001C10.4885 4.20001 9.53333 5.15514 9.53333 6.33335C9.53333 7.51155 10.4885 8.46668 11.6667 8.46668Z"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.8666 5.26667C32.0448 5.26667 32.9999 4.31154 32.9999 3.13333C32.9999 1.95513 32.0448 1 30.8666 1C29.6884 1 28.7333 1.95513 28.7333 3.13333C28.7333 4.31154 29.6884 5.26667 30.8666 5.26667Z"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.3333 11.6667C23.5115 11.6667 24.4666 10.7115 24.4666 9.53333C24.4666 8.35512 23.5115 7.39999 22.3333 7.39999C21.1551 7.39999 20.2 8.35512 20.2 9.53333C20.2 10.7115 21.1551 11.6667 22.3333 11.6667Z"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.13333 17C4.31154 17 5.26667 16.0449 5.26667 14.8667C5.26667 13.6885 4.31154 12.7333 3.13333 12.7333C1.95513 12.7333 1 13.6885 1 14.8667C1 16.0449 1.95513 17 3.13333 17Z"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 33H33"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.46663 25.1778H4.19997C3.91822 25.1814 3.64904 25.295 3.44979 25.4943C3.25055 25.6935 3.13698 25.9627 3.1333 26.2444V33H9.5333V26.2444C9.5333 25.9615 9.42092 25.6902 9.22088 25.4902C9.02084 25.2901 8.74953 25.1778 8.46663 25.1778Z"
          fill="#A0CFDA"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1334 15.2222H14.8667C14.585 15.2259 14.3158 15.3395 14.1165 15.5387C13.9173 15.738 13.8037 16.0071 13.8 16.2889V33H20.2V16.2889C20.2 16.006 20.0877 15.7347 19.8876 15.5346C19.6876 15.3346 19.4163 15.2222 19.1334 15.2222Z"
          fill="#EFD58B"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.8 20.9111H25.5333C25.2516 20.9148 24.9824 21.0283 24.7832 21.2276C24.5839 21.4268 24.4704 21.696 24.4667 21.9778V33H30.8667V21.9778C30.8667 21.6949 30.7543 21.4236 30.5543 21.2235C30.3542 21.0235 30.0829 20.9111 29.8 20.9111Z"
          fill="#ECB23F"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 34 34" fill="none" boxSize={8}>
      <path
        d="M9.71849 7.21512L4.01538 12.9182"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2856 8.92173L13.6438 7.15817"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.9326 4.04358L23.8411 8.0258"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6668 8.46668C12.845 8.46668 13.8001 7.51155 13.8001 6.33335C13.8001 5.15514 12.845 4.20001 11.6668 4.20001C10.4886 4.20001 9.53345 5.15514 9.53345 6.33335C9.53345 7.51155 10.4886 8.46668 11.6668 8.46668Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8667 5.26667C32.0449 5.26667 33.0001 4.31154 33.0001 3.13333C33.0001 1.95513 32.0449 1 30.8667 1C29.6885 1 28.7334 1.95513 28.7334 3.13333C28.7334 4.31154 29.6885 5.26667 30.8667 5.26667Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3333 11.6667C23.5115 11.6667 24.4666 10.7115 24.4666 9.53333C24.4666 8.35512 23.5115 7.39999 22.3333 7.39999C21.1551 7.39999 20.2 8.35512 20.2 9.53333C20.2 10.7115 21.1551 11.6667 22.3333 11.6667Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.13333 17C4.31154 17 5.26667 16.0449 5.26667 14.8667C5.26667 13.6885 4.31154 12.7333 3.13333 12.7333C1.95513 12.7333 1 13.6885 1 14.8667C1 16.0449 1.95513 17 3.13333 17Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 33H33"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.46663 25.1778H4.19997C3.91822 25.1814 3.64904 25.295 3.44979 25.4943C3.25055 25.6935 3.13698 25.9627 3.1333 26.2444V33H9.5333V26.2444C9.5333 25.9615 9.42092 25.6902 9.22088 25.4902C9.02084 25.2901 8.74953 25.1778 8.46663 25.1778Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1334 15.2222H14.8667C14.585 15.2259 14.3158 15.3395 14.1165 15.5387C13.9173 15.738 13.8037 16.0071 13.8 16.2889V33H20.2V16.2889C20.2 16.006 20.0877 15.7347 19.8876 15.5346C19.6876 15.3346 19.4163 15.2222 19.1334 15.2222Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7999 20.9111H25.5332C25.2515 20.9148 24.9823 21.0283 24.783 21.2276C24.5838 21.4268 24.4702 21.696 24.4666 21.9778V33H30.8666V21.9778C30.8666 21.6949 30.7542 21.4236 30.5541 21.2235C30.3541 21.0235 30.0828 20.9111 29.7999 20.9111Z"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
