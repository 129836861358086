import { useEffect } from 'react';

/**
 * @obsolete
 * Will be removed. Import DocTitle from 'global/components/router' instead;
 */
export function DocTitle({ title }: { title?: string | string[] }) {
  useEffect(() => {
    if (title) {
      document.title = Array.isArray(title)
        ? `${title.reverse().join(' - ')} | Terminal`
        : `${title} | Terminal`;
    }
  }, [title]);

  return null;
}
