import { Center, Heading, Text, Image, Button, Box } from '@terminal/design-system';
import { CenteredContainer } from './CenteredContainer';

export function ExceptionPage({
  title,
  subTitle,
  imageSrc,
  description,
  onActionClick,
  actionTitle,
  isActionDisabled = false,
}: {
  title: string;
  subTitle: string;
  imageSrc: string;
  description: string | React.ReactNode;
  onActionClick?: () => void;
  actionTitle?: string;
  isActionDisabled?: boolean;
}) {
  return (
    <CenteredContainer>
      <Box w="full" maxW="350px">
        <Image src={imageSrc} alt="Page not found" />
      </Box>
      <Center flexDirection="column" maxW="lg" mt={6}>
        <Heading variant="heading-1" color="brand.main">
          {title}
        </Heading>
        <Heading variant="heading-2" pt={1} textAlign="center">
          {subTitle}
        </Heading>
        <Text variant="body" mt={2} align="center">
          {description}
        </Text>
        {actionTitle && onActionClick && (
          <Button onClick={onActionClick} mt={6} w="auto" isDisabled={isActionDisabled}>
            {actionTitle}
          </Button>
        )}
      </Center>
    </CenteredContainer>
  );
}
