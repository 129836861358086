import {
  Flex,
  Button,
  FormControl,
  Center,
  FormErrorMessage,
  FormHelperText,
  Text,
  InfoIconBold,
  Tooltip,
} from '@terminal/design-system';
import type { HStepsProps } from 'candidate/components';
import { StepPageLayout } from '../../components/StepPageLayout';

export function UploadResumeForm({
  initialRef,
  onSignOutClick,
  onFileChange,
  userFullName,
  progressProps,
  onUploadClick,
  onGoogleDrivePickerClick,
  isInvalid,
  inputErrorText,
  onEnterInfoManuallyClicked,
  isGoogleDriveLoading,
  isGoogleDriveDisabled,
}: {
  initialRef: React.MutableRefObject<null>;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  progressProps: HStepsProps;
  onUploadClick: () => void;
  isInvalid: boolean;
  inputErrorText?: string;
  onEnterInfoManuallyClicked: () => void;
  onGoogleDrivePickerClick?: () => void;
  isGoogleDriveLoading?: boolean;
  isGoogleDriveDisabled?: boolean;
}) {
  return (
    <StepPageLayout
      title="Upload Your Resume"
      description="By uploading your resume, you can be contacted by our team sooner and quickly move ahead of 50% of candidates."
      {...{ progressProps, userFullName, onSignOutClick }}
      footer={
        <Flex w="full" justifyContent="center" flexDir="column" alignItems="center">
          <Button onClick={onEnterInfoManuallyClicked} variant="ghost" role="link">
            I Prefer Manual Entry
          </Button>
          <Text variant="caption">Adds 4 more steps.</Text>
        </Flex>
      }
    >
      <Flex justifyContent="center">
        <FormControl justifyContent="center" isInvalid={isInvalid}>
          <Center flexDir="column">
            <>
              {/* Input hidden used for testing purposes, since testing tools doesn't support uploading files to <button> tags */}
              <input
                type="file"
                style={{ height: 0, overflow: 'hidden', position: 'absolute' }}
                ref={initialRef}
                onChange={onFileChange}
                data-testid="hidden-file-input"
              />
              <FormHelperText fontSize="sm" color="text.secondary">
                Accepted files: DOC, DOCX, PDF, TXT.
              </FormHelperText>
              <FormHelperText alignItems="center" display="flex">
                <Text variant="caption" color="text.secondary">
                  Max. file size 5mb.
                </Text>
                <Tooltip
                  w={40}
                  label="Helpful tip: Keep it simple. Do not use templates, images, shapes or mix font types and sizes in your document."
                >
                  <InfoIconBold fontSize="lg" ml={1} cursor="pointer" />
                </Tooltip>
              </FormHelperText>
              <Button w={[64]} mt={3} onClick={onUploadClick} disabled={isGoogleDriveLoading}>
                Upload from Device
              </Button>
              {onGoogleDrivePickerClick && (
                <Button
                  variant="secondary"
                  w={[64]}
                  mt={3}
                  onClick={onGoogleDrivePickerClick}
                  isLoading={isGoogleDriveLoading}
                  disabled={isGoogleDriveDisabled}
                >
                  Upload from Google Drive
                </Button>
              )}
            </>
          </Center>
          <FormErrorMessage justifyContent="center">{inputErrorText}</FormErrorMessage>
        </FormControl>
      </Flex>
    </StepPageLayout>
  );
}
