import { useState, useEffect } from 'react';
import type { StackProps } from '@terminal/design-system';
import { Box, Stack } from '@terminal/design-system';
import { CarouselHorizontalControls, Carousel, CarouselSlide, useCarousel } from './Carousel';
import type { CarouselOptions } from './Carousel';

export function HorizontalCarousel({
  slides,
  name = 'horizontal',
  onSlideChange,
  onRender,
  isStepsNavigatable = true,
  options = {} as CarouselOptions,
  ...styleProps
}: {
  slides: React.ReactNode[];
  isStepsNavigatable?: boolean;
  onSlideChange?: (toSlideIndex: number) => void;
  onRender?: (slideIndex: number) => void;
  options?: CarouselOptions;
  /**
   * Used only as the list key. Need to be used when more than one carousel
   * controller is on a page
   */
  name?: string;
} & StackProps) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [ref, slider] = useCarousel({
    slideChanged: (_slider) => setCurrentSlide(_slider.track.details.rel),
    ...options,
  });

  useEffect(() => {
    if (onRender) onRender(currentSlide);
    // we don't want the useEffect code to get executed when currentSlide changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRender]);

  const hasPrevious = currentSlide !== 0;
  const hasNext = currentSlide < slides.length - 1;

  return (
    <Stack spacing={10} height="fit-content" maxW="full" {...styleProps}>
      <Box position="relative">
        <Carousel ref={ref}>
          {slides.map((slide, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <CarouselSlide key={`carousel-control-${name}-${index}`}>{slide}</CarouselSlide>
          ))}
        </Carousel>
        <CarouselHorizontalControls
          mt={4}
          currentSlideIndex={currentSlide}
          onPreviousClick={() => {
            if (onSlideChange) onSlideChange(currentSlide - 1);
            slider.current?.prev();
          }}
          onNextClick={() => {
            if (onSlideChange) onSlideChange(currentSlide + 1);
            slider.current?.next();
          }}
          onStepsClick={
            isStepsNavigatable
              ? (index) => {
                  if (onSlideChange) onSlideChange(currentSlide - 1);
                  slider.current?.moveToIdx(index);
                }
              : undefined
          }
          numberOfSlides={slides.length}
          {...{ hasNext, hasPrevious }}
        />
      </Box>
    </Stack>
  );
}
