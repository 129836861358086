import type { ChangeEvent } from 'react';
import type { FormikErrors } from 'formik';
import {
  Flex,
  Button,
  Text,
  FileIcon,
  Center,
  Box,
  FormErrorMessage,
  FormControl,
  VStack,
  Stack,
  Toast,
  InfoIconBold,
  CircularProgress,
  Tooltip,
} from '@terminal/design-system';
import type { ProfileProps } from '../../Profile.types';

export function ResumeForm({
  values,
  initialRef,
  onFileChange,
  onUploadClick,
  onGoogleDrivePickerClick,
  errors,
  uploadedFileName,
  isFileLoading,
}: {
  values: ProfileProps['candidate']['resume']['formValues'];
  errors: FormikErrors<ProfileProps['candidate']['resume']['formValues']>;
  initialRef: React.MutableRefObject<null>;
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onUploadClick: () => void;
  onGoogleDrivePickerClick?: () => void;
  uploadedFileName: ProfileProps['candidate']['resume']['fileName'];
  isFileLoading?: boolean;
}) {
  return (
    <Flex direction="column" alignItems="center" pt={1}>
      <FormControl isInvalid={!!errors.file} justifyContent="center">
        <FormErrorMessage justifyContent="center" mb={4}>
          <Toast status="error" description={errors.file} isClosable={false} boxShadow="none" />
        </FormErrorMessage>
        {isFileLoading ? (
          <VStack>
            <CircularProgress isIndeterminate color="brand.main" />
            <Text>Uploading and translating your file...</Text>
          </VStack>
        ) : (
          <Center>
            {!values.file && !uploadedFileName ? (
              <>
                <input
                  type="file"
                  ref={initialRef}
                  onChange={onFileChange}
                  style={{ height: 0, overflow: 'hidden', position: 'absolute' }}
                  // hiding the input this way lets the user open the file dialog by pressing enter
                />
                <Stack
                  {...(onGoogleDrivePickerClick
                    ? { direction: 'row', w: 'full' }
                    : { direction: 'column' })}
                >
                  {onGoogleDrivePickerClick && (
                    <Button variant="secondary" flex={1} onClick={onGoogleDrivePickerClick}>
                      Upload from Drive
                    </Button>
                  )}
                  <Button
                    variant="primary"
                    onClick={onUploadClick}
                    {...(onGoogleDrivePickerClick ? { flex: 1 } : { w: 64 })}
                  >
                    {onGoogleDrivePickerClick ? 'Upload from Device' : 'Upload'}
                  </Button>
                </Stack>
              </>
            ) : (
              <Flex w="full" pl={6} pr={8} py={10} bgColor="bg.secondary">
                <FileIcon fontSize="2xl" mr={3} />
                <Text variant="label" isTruncated maxW="lg">
                  {values.file?.name || uploadedFileName}
                </Text>
              </Flex>
            )}
          </Center>
        )}
      </FormControl>
      {!isFileLoading && !(values.file?.name || uploadedFileName) && (
        <Box mt={3} textAlign="center">
          <Text variant="caption" color="text.secondary" maxW="64">
            Accepted files: DOC, DOCX, PDF, TXT. Max. file size 5mb.
            <Tooltip
              w={40}
              label="Helpful tip: Keep it simple. Do not use templates, images, shapes or mix font types and sizes in your document."
            >
              <InfoIconBold fontSize="lg" ml={1} cursor="pointer" />
            </Tooltip>
          </Text>
        </Box>
      )}
    </Flex>
  );
}
