import { Icon } from '@terminal/design-system';

export function DataScienceIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <path
          d="m6.9835 10.574v5.7442c0 2.1151 4.0183 3.8295 8.9753 3.8295s8.9753-1.7144 8.9753-3.8295v-5.7442"
          clipRule="evenodd"
          fill="#ECB23F"
          fillRule="evenodd"
        />
        <path
          d="m6.9835 4.8295v5.7441c0 2.1152 4.0183 3.8295 8.9753 3.8295s8.9753-1.7143 8.9753-3.8295v-5.7441"
          clipRule="evenodd"
          fill="#EFD58B"
          fillRule="evenodd"
        />
        <ellipse cx="15.959" cy="4.8294" rx="8.9753" ry="3.8294" fill="#A0CFDA" />
        <path
          d="m15.959 20.147v9.5737"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m5.9863 29.721h19.945"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m30.419 28.762c-0.2754 0-0.4987 0.2144-0.4987 0.4787 0 0.2644 0.2233 0.4787 0.4987 0.4787 0.2753 0 0.4986-0.2143 0.4986-0.4787 3e-4 -0.127-0.0521-0.249-0.1457-0.3388-0.0936-0.0899-0.2206-0.1402-0.3529-0.1399v0"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m1.4986 28.762c-0.27539 0-0.49863 0.2144-0.49863 0.4787 0 0.2644 0.22324 0.4787 0.49863 0.4787 0.27538 0 0.49862-0.2143 0.49862-0.4787 3.6e-4 -0.127-0.05206-0.249-0.14565-0.3388-0.09359-0.0899-0.22062-0.1402-0.35297-0.1399v0"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <ellipse
          cx="15.959"
          cy="4.8294"
          rx="8.9753"
          ry="3.8294"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m6.9835 4.8295v5.7441c0 2.1152 4.0183 3.8295 8.9753 3.8295s8.9753-1.7143 8.9753-3.8295v-5.7441"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m6.9835 10.574v5.7442c0 2.1151 4.0183 3.8295 8.9753 3.8295s8.9753-1.7144 8.9753-3.8295v-5.7442"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.959 20.147v9.574M5.986 29.72h19.945M30.419 28.762c-.275 0-.499.215-.499.479s.224.479.499.479.499-.215.499-.48c0-.126-.052-.248-.146-.338-.094-.09-.22-.14-.353-.14v0M1.499 28.762c-.276 0-.499.215-.499.479s.223.479.499.479c.275 0 .498-.215.498-.48 0-.126-.052-.248-.145-.338-.094-.09-.221-.14-.353-.14v0"
      />
      <ellipse
        cx="15.959"
        cy="4.829"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        rx="8.975"
        ry="3.829"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.984 4.83v5.744c0 2.115 4.018 3.83 8.975 3.83s8.975-1.715 8.975-3.83V4.829"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.984 10.574v5.744c0 2.115 4.018 3.83 8.975 3.83s8.975-1.715 8.975-3.83v-5.744"
      />
      <mask id="3zlahoxrna" width="32" height="32" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="currentColor" d="M0 0H32V32H0z" />
      </mask>
    </Icon>
  );
}
