import { Heading, Select, Tag, Flex, TagLabel, Text, Spinner } from '@terminal/design-system';
import type { FormikState } from 'formik';
import type { ProfileProps } from '../../Profile.types';

export function PersonalInfoSection({
  personalInfo,
  availabilityOptions,
  workExperiences = [],
  values,
  onChange,
  isLoading,
}: {
  personalInfo: ProfileProps['candidate']['personalInfo'];
  availabilityOptions: ProfileProps['availabilityOptions'];
  workExperiences: ProfileProps['candidate']['workExperiences'];
  onChange?: (event: string | React.ChangeEvent<any>) => void;
  isLoading?: boolean;
} & Pick<FormikState<ProfileProps['candidate']['personalInfo']['formValues']>, 'values'>) {
  return (
    <Flex flexDir="column">
      <Heading variant="heading-1" color="text.primary">
        {personalInfo.fullName || '-'}
      </Heading>
      <Text>{workExperiences?.find((experience) => experience.isCurrent)?.title}</Text>
      <Tag
        variant="toggle"
        mt={[2, 2, 6]}
        w="fit-content"
        position="relative"
        bgColor="ui.lighter.success"
        opacity={isLoading ? 0.5 : 1}
        className="profile-info__tag"
      >
        <TagLabel
          as="label"
          htmlFor="availability"
          paddingRight={5}
          pointerEvents={isLoading ? 'none' : 'auto'}
        >
          {personalInfo.availability || 'Add your availability'}
          {!isLoading ? (
            <Select
              position="absolute"
              top={0}
              left={0}
              opacity={0}
              w="full"
              height="1.813rem" // forcing height because of a Safari issue with the chakra-select-wrapper fit-content property
              defaultValue={values.availability || ''}
              id="availability"
              name="availability"
              cursor="pointer"
              onChange={onChange}
            >
              {Object.entries(availabilityOptions).map(([value, description]) => (
                <option value={value} key={`availability-${value}`}>
                  {description}
                </option>
              ))}
            </Select>
          ) : (
            <Spinner size="xs" pos="absolute" right={3} top={2} />
          )}
        </TagLabel>
      </Tag>
    </Flex>
  );
}
