import { Route, useRouteMatch } from 'react-router-dom';
import { ErrorBoundary } from 'global/sentry';
import { useRedirection } from 'global/utils';

import { ErrorFallback, PageLayout, SwitchWithPageNotFound } from 'candidate/components';
import { useAppInitData } from 'candidate/utils/general';

import { useCandidateAuth } from 'candidate/utils';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import { AssessmentsController } from './Assessments.controller';

export function AssessmentRouter() {
  const auth = useCandidateAuth();
  const { path } = useRouteMatch();
  const redirectTo = useRedirection();
  const { candidateAvailability } = useAppInitData({
    userID: auth.user?.id,
  });

  return (
    <PageLayout.Variation.WithAllSections
      userFullName={auth.userFullName}
      onSignOutClick={() => signOut({ auth: firebaseAuth })}
      {...{ candidateAvailability }}
    >
      <ErrorBoundary
        fallback={
          <ErrorFallback
            onActionClick={() => {
              redirectTo('/assessments');
              window.location.reload();
            }}
            actionTitle="Go Back to Assessments"
          />
        }
      >
        <SwitchWithPageNotFound>
          <Route path={[`${path}/`, `${path}/:slug`, `${path}/complete/:testID`]} exact>
            <AssessmentsController />
          </Route>
        </SwitchWithPageNotFound>
      </ErrorBoundary>
    </PageLayout.Variation.WithAllSections>
  );
}
