import { Route, useRouteMatch } from 'react-router-dom';
import { ErrorBoundary } from 'global/sentry';
import { firebaseAuth } from 'global/firebaseApp';
import { googleSignIn } from 'global/auth';
import {
  ErrorFallback,
  ScrollToTopOnPathChange,
  SwitchWithPageNotFound,
} from 'candidate/components';
import { useCandidateAuth } from 'candidate/utils';
import { SignUpController } from './SignUp.controller';
import { HowItWorks } from './HowItWorks';

export function SignUpRouter({ onStepCompleteSuccess }: { onStepCompleteSuccess: () => void }) {
  const auth = useCandidateAuth();
  const { path } = useRouteMatch();

  return (
    <ErrorBoundary
      fallback={
        <ErrorFallback
          onActionClick={() => {
            window.location.reload();
          }}
          actionTitle="Try Again"
        />
      }
    >
      <ScrollToTopOnPathChange />
      <SwitchWithPageNotFound>
        <Route path={`${path}`} exact>
          <SignUpController
            continueURL={`${window.location.origin}/onboarding/sign-up`}
            onGoogleLoginClicked={() =>
              googleSignIn({ auth: firebaseAuth, dispatch: auth.dispatch })
            }
            {...{
              onStepCompleteSuccess,
            }}
          />
        </Route>
        <Route path={`${path}/form`}>
          <SignUpController
            continueURL={`${window.location.origin}/onboarding/sign-up`}
            onGoogleLoginClicked={() =>
              googleSignIn({ auth: firebaseAuth, dispatch: auth.dispatch })
            }
            {...{
              onStepCompleteSuccess,
            }}
          />
        </Route>
        <Route path={`${path}/how-it-works`}>
          <HowItWorks.Variation.WithSignUpAtBeginning />
        </Route>
      </SwitchWithPageNotFound>
    </ErrorBoundary>
  );
}
