import { serializeJobDetails } from 'candidate/features/job/utils';
import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import type {
  SelectCandidateQuery,
  SelectCurrencyExchangeRatesQuery,
  SelectJobSuggestionsQuery,
} from './types';

// TODO: [GROW-120] this utility should not import from features
export function serializerForJobSuggestions({
  candidate_suggested_job,
  applicant_job,
  candidate_job,
  user_subscription,
  candidateInfo,
  conversionTable,
}: SelectJobSuggestionsQuery & {
  candidateInfo?: {
    country:
      | Exclude<SelectCandidateQuery['candidate'][number]['country_choice'], null>['name']
      | null;
    talentPartnerEmail: string | null | undefined;
    candidateSkills: SelectCandidateQuery['candidate'][number]['candidate_skills'][number]['skill']['name'];
    yearsOfExperience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null | undefined;
    furthestCandidateCurationWorkflow:
      | NonNullable<
          SelectCandidateQuery['candidate'][number]['furthest_candidate_curation_workflow']
        >['status']
      | null;
  } | null;
  conversionTable: SelectCurrencyExchangeRatesQuery['exchange_rates'] | undefined;
}): {
  jobSuggestions: ReturnType<typeof serializeJobDetails>[];
  isSubscribedToAlerts: boolean;
} {
  const jobSuggestions = candidate_suggested_job
    ?.map((job) =>
      serializeJobDetails({
        job,
        applicant_jobs: applicant_job,
        candidate_jobs: candidate_job,
        candidateInfo: {
          country: candidateInfo?.country || null,
          talentPartnerEmail: candidateInfo?.talentPartnerEmail,
          candidateSkills: candidateInfo?.candidateSkills,
          yearsOfExperience: candidateInfo?.yearsOfExperience,
          furthestCandidateCurationWorkflow:
            candidateInfo?.furthestCandidateCurationWorkflow || null,
        },
        conversionTable,
      }),
    ) // `Top applicants` at the top job list
    .sort(
      (jobA, jobB) =>
        Number(jobB.jobDetail?.topApplicantInfo.numberOfSkillsMatched) -
        Number(jobA.jobDetail?.topApplicantInfo.numberOfSkillsMatched),
    );

  return {
    jobSuggestions,
    isSubscribedToAlerts: user_subscription?.length > 0,
  };
}
