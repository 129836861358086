import { Card, CardBody } from 'candidate/components';
import {
  Flex,
  Button,
  Center,
  Text,
  WarningIcon,
  Heading,
  useToast,
} from '@terminal/design-system';
import { useEffect } from 'react';
import type { HStepsProps } from 'candidate/components';
import { StepPageLayout } from '../../components/StepPageLayout';

export function ResumeParsingFailed({
  onSignOutClick,
  userFullName,
  progressProps,
  onTryAgainClick,
  onEnterInfoManuallyClicked,
}: {
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  progressProps: HStepsProps;
  onTryAgainClick: () => void;
  onEnterInfoManuallyClicked: () => void;
}) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  useEffect(() => {
    toast({
      title: 'Upload Failed!',
      description: 'Accepted files: DOC, DOCX, PDF, TXT. Max. file size 5mb.',
      status: 'error',
    });
    // Ignoring toast as it is a fixed design system utility
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StepPageLayout
      title="Upload Resume to Finalize"
      description="By uploading your resume, you can be contacted by our team sooner and quickly move ahead of 50% of candidates."
      {...{ userFullName, progressProps, onSignOutClick }}
      footer={
        <Flex w="full" justifyContent="center" flexDir="column" alignItems="center">
          <Button onClick={onEnterInfoManuallyClicked} variant="ghost" role="link">
            I Prefer Manual Entry
          </Button>
          <Text variant="caption">Adds 4 more steps.</Text>
        </Flex>
      }
    >
      <Card>
        <CardBody px={[3, 8]} py={[6, 8]}>
          <Center>
            <Flex flexDir="column" alignItems="center">
              <WarningIcon fontSize="2xl" color="ui.error" />
              <Heading variant="heading-3" as="h3" role="heading" mt={2}>
                Something Went Wrong!
              </Heading>
              <Text textAlign="center">
                You can try uploading your file again or entering information manually.
              </Text>
              <Button onClick={onTryAgainClick} mt={5}>
                Try Again
              </Button>
            </Flex>
          </Center>
        </CardBody>
      </Card>
    </StepPageLayout>
  );
}
