import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Text,
  Flex,
  Progress,
  Container,
  Image,
  Divider,
  Button,
  Link,
  UserIcon,
  CheckCircleSolidIcon,
  EducationIcon,
  FileIcon,
  SettingsIcon,
  Collapse,
  useDisclosure,
  DropdownIcon,
} from '@terminal/design-system';
import type { SkillsProps } from 'candidate/shared/modules';
import { useIsViewPortDesktop } from 'global/utils';
import { useSocialProfileEditConfig } from './components';
import type { ProfileModalName, ProfileProps } from './Profile.types';
import {
  EducationsController,
  WorkExperiencesController,
  PersonalInfoController,
  RolesController,
  SkillsController,
  ResumeController,
} from './sections';
import { DesiredSalaryController } from './sections/desired-salary';
import { usePersonalInfoController } from './sections/personal-info/PersonalInfo.controller';
import { PersonalInfoSection } from './sections/personal-info/PersonalInfoSection';
import ProfileLeftSide from './assets/profile-left-side.svg?url';
import { YearsOfExperienceController } from './sections/years-of-experience/YearsOfExperience.controller';

type SectionID = 'about' | 'career-and-education' | 'resume-and-documents' | 'preferences';

function SectionLink({
  href,
  isActive,
  icon = null,
  isChecked = false,
  onClick,
  order,
  isDesktop,
  dropdownIconProps = {},
  children,
}: {
  isActive: boolean;
  icon: React.ReactNode;
  isChecked?: boolean;
  order?: number;
  isDesktop?: boolean;
  dropdownIconProps?: React.ComponentProps<typeof DropdownIcon>;
} & React.ComponentProps<typeof Link>) {
  return (
    <Link
      display="block"
      px={6}
      py={4}
      href={href}
      textDecor="none"
      onClick={onClick}
      order={order}
      {...(isActive
        ? {
            bgColor: 'accent.main',
            color: 'text.inverse',
            _hover: { textDecor: 'none' },
          }
        : {
            color: 'text.primary',
            _hover: {
              textDecor: 'none',
              color: 'accent.darkest',
            },
          })}
    >
      <Flex justify="flex-start" alignItems="center">
        {icon}
        <Text variant="caption" color="inherit" ml={4}>
          {children}
        </Text>
        {isChecked && (!isActive || isDesktop) && (
          <CheckCircleSolidIcon
            color={isActive ? 'ui.primary.inverse' : 'brand.main'}
            fontSize="sm"
            ml="auto"
            __css={{
              path: {
                strokeWidth: '2',
                stroke: isActive ? 'accent.main' : 'bg.primary',
              },
            }}
          />
        )}
        {isActive && !isDesktop && (
          // @ts-ignore
          <DropdownIcon color="ui.primary.inverse" fontSize="sm" ml="auto" {...dropdownIconProps} />
        )}
      </Flex>
    </Link>
  );
}

function ProfileSection({
  title,
  children,
  shouldDisplayDivider = false,
}: {
  title: string;
  children: React.ReactNode;
  shouldDisplayDivider?: boolean;
}) {
  return (
    <Box as="section" mb={[6, 6, 10]}>
      {shouldDisplayDivider && <Divider />}
      <Box mb={6} mt={[6, 6, 10]}>
        <Text variant="label" casing="uppercase" color="text.disabled">
          {title}
        </Text>
      </Box>
      {children}
    </Box>
  );
}

function AnchorSection({
  children,
  anchorID,
  onLayout = () => {},
}: {
  children: React.ReactNode;
  anchorID: SectionID;
  onLayout?: (element: HTMLElement | undefined) => void;
}) {
  const [element, setElement] = useState<HTMLElement | undefined>();

  useEffect(() => {
    if (!element) return undefined;

    onLayout(element);

    return () => {
      onLayout(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  return (
    <Box as="article" pos="relative" ref={(el: HTMLDivElement) => setElement(el)}>
      {/* The actual anchored element should remove the space of the PageHeader for correct display of the sections */}
      <Box id={anchorID} pos="absolute" top={[-14, -14, -20]} />
      {children}
    </Box>
  );
}

const determineStickyVisibilityTranslate = ({
  isSticky,
  isVisible,
}: {
  isSticky: boolean;
  isVisible: boolean | null;
}) => {
  if (isVisible) return { transform: 'translateY(0)' };

  return isSticky ? { transform: 'translateY(-100%)' } : {};
};

export function Profile({
  availabilityOptions,
  candidate,
  sectionsCompletionStatus,
  completionPercentage,
  degreeChoices,
  roleChoices,
  yearsOfExperienceChoices,
  skillChoices,
  isModalOpen,
  isSavingForm,
  selectedModal,
  onModalClose,
  onModalOpen,
  onSocialProfileChangeSubmit,
  userID,
}: {
  availabilityOptions: ProfileProps['availabilityOptions'];
  candidate: ProfileProps['candidate'];
  sectionsCompletionStatus: ProfileProps['sectionsCompletionStatus'];
  completionPercentage: ProfileProps['completionPercentage'];
  degreeChoices: ProfileProps['degreeChoices'];
  roleChoices: ProfileProps['roleChoices'];
  yearsOfExperienceChoices: ProfileProps['yearsOfExperienceRangeChoices'];
  skillChoices: SkillsProps['skillChoices'];
  isModalOpen: boolean;
  isSavingForm: boolean;
  selectedModal: ProfileModalName;
  onModalClose: () => void;
  onModalOpen: (modal: ProfileModalName) => void;
  onSocialProfileChangeSubmit: (
    values: ProfileProps['candidate']['socialProfile']['formValues'],
  ) => void;
  userID?: number;
}) {
  const initialRef = useRef(null);
  const scrollRef = useRef<number>(0);
  const {
    isOpen: isOpen_sideMenu,
    onToggle: onToggle_sideMenu,
    onClose: onClose_sideMenu,
  } = useDisclosure();
  const [stickyNav, setStickyNav] = useState<HTMLElement | undefined>();
  const [stickyState, setSticky] = useState<{ isSticky: boolean; isVisible: boolean | null }>({
    isSticky: false,
    isVisible: null,
  });
  const isDesktop = useIsViewPortDesktop();
  const [activeSection, setActiveSection] = useState<SectionID>('about');
  const location = useLocation();
  const [sections, setSections] = useState<Record<SectionID, HTMLElement | undefined>>(
    {} as Record<SectionID, HTMLElement | undefined>,
  );
  const { formik: formik_PersonalInfo, isLoading: isLoading_PersonalInfo } =
    usePersonalInfoController({
      isEdit: !!candidate.personalInfo.fullName,
      personalInfo: candidate.personalInfo,
      candidateID: candidate.id,
    });

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);

      if (section) {
        window.scrollTo({ top: section.getBoundingClientRect().top, left: 0, behavior: 'auto' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!stickyNav) return undefined;

    if (isDesktop) {
      setSticky(() => ({ isSticky: false, isVisible: true }));
      return undefined;
    }

    // Scroll logic to handle sticky menu on mobile
    const onScroll = () => {
      // Scroll + Nav height on mobile
      const scrollWithNav = window.scrollY + 3.5 * 16; // 3.5rem * 16 = Nav height on mobile
      const targetBounds = stickyNav.getBoundingClientRect();
      const isScrollUp = scrollRef.current > window.scrollY;

      // if the scroll+Nav is below the expected element it should stop the sticky behavior.
      if (scrollWithNav <= targetBounds.top + window.scrollY) {
        setSticky(() => ({ isSticky: false, isVisible: null }));
      }

      // if the scroll+Nav goes beyond element target
      if (scrollWithNav >= targetBounds.bottom + window.scrollY) {
        if (!isScrollUp) onClose_sideMenu();

        /**
         * isVisible = null; represents the state before we start showing or hiding the Nav
         * That way we know when to set the animation transition of showing or hiding
         * Otherwise we would have a weird transition at the beginning of the sticky state.
         */
        setSticky((prevState) => ({
          isSticky: true,
          isVisible: prevState.isVisible === null && !isScrollUp ? null : isScrollUp,
        }));
      }

      scrollRef.current = window.scrollY;
    };
    document.addEventListener('scroll', onScroll);
    onScroll();

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickyNav, isDesktop]);

  useEffect(() => {
    /**
     * Validating the object of section to handle unmounting onLayout scenario
     * in which we get a record of undefined
     */
    const mountedSections = Object.keys(sections || {}).filter(
      (section) => !!sections[section as SectionID],
    );

    if (!sections || !mountedSections.length) return undefined;

    // Scroll logic to handle active sections
    const onScroll = () => {
      mountedSections.forEach((section) => {
        const element = sections[section as SectionID] as HTMLElement;
        const scrollBottom = window.scrollY + window.innerHeight / 2;
        const targetBounds = element.getBoundingClientRect();

        if (
          scrollBottom >= targetBounds.top + window.scrollY &&
          scrollBottom <= targetBounds.bottom + window.scrollY
        ) {
          setActiveSection(section as SectionID);
        }
      });
    };
    document.addEventListener('scroll', onScroll);
    onScroll();

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [sections]);

  const socialProfile = useSocialProfileEditConfig({
    initialRef,
    initialValues: candidate.socialProfile.formValues,
    onSubmit: onSocialProfileChangeSubmit,
  });

  const handleOnSaveClick = () => {
    if (socialProfile.config.hasFormValuesChanged()) {
      socialProfile.config.onSubmit();
    }
  };

  /**
   * The setTimeout is a workaround to move setActiveSection call to the next execution stack
   * because of the anchor behavior and the section scroll logic.
   *
   * The idea is to prioritize user interactions with the menu
   * even if the scroll logic tries to set a different section based on
   * the triggers and the amount space available for an specific screen height
   */
  const handleSectionLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    section: SectionID,
  ) => {
    if ((!isDesktop && !isOpen_sideMenu) || (!isDesktop && activeSection === section)) {
      event.preventDefault();
      onToggle_sideMenu();
      return;
    }

    onToggle_sideMenu();
    setTimeout(() => setActiveSection(section), 0);
  };

  return (
    <Container flex={['none', 'none', '1']}>
      <Flex flexDir={['column', 'column', 'row']} w="full" mt={[6, 6, 0]}>
        <Box
          pos="relative"
          w={['full', 'full', 'xs']}
          minW={['unset', 'unset', 'xs']}
          alignSelf="stretch"
          bgColor="accent.lightest"
          _before={
            isDesktop
              ? {
                  content: '""',
                  pos: 'absolute',
                  right: '100%',
                  h: 'full',
                  w: '50vw',
                  bgColor: 'accent.lightest',
                  top: 0,
                }
              : {}
          }
        >
          <Flex
            pos={['static', 'static', 'fixed']}
            w={['full', 'full', 'xs']}
            minW={['unset', 'unset', 'xs']}
            minH={['unset', 'unset', 'full']}
            pt={[6, 6, 16]}
            pb={[0, 0, 16]}
            px={[3, 3, 0]}
          >
            <Flex flex={1} flexDir="column" justifyContent="space-between">
              <Box>
                {!isDesktop && (
                  <Avatar
                    className="notranslate"
                    name={candidate.personalInfo.fullName as string}
                    color="text.inverse"
                    bg="accent.darkest"
                    size="xl"
                    mb={6}
                  />
                )}
                <PersonalInfoSection
                  personalInfo={candidate.personalInfo}
                  availabilityOptions={availabilityOptions}
                  workExperiences={candidate.workExperiences}
                  values={formik_PersonalInfo.values}
                  onChange={(e) => {
                    formik_PersonalInfo.handleChange(e);
                    // Moving handleSubmit to the next call stack
                    setTimeout(() => {
                      formik_PersonalInfo.handleSubmit();
                    }, 0);
                  }}
                  isLoading={isLoading_PersonalInfo}
                />
                <Box mr={3} mt={6}>
                  <Progress
                    size="sm"
                    value={completionPercentage}
                    colorScheme="accent.main"
                    bg="bg.primary"
                  />
                  <Text variant="hint" mt={1}>{`${completionPercentage}% Complete`}</Text>
                </Box>
                <Box
                  ref={(el: HTMLDivElement) => setStickyNav(el)}
                  pos="relative"
                  mt={6}
                  mx={[-3, -3, 0]}
                  minH="3.313rem"
                >
                  <Box
                    left={0}
                    w="full"
                    zIndex="dropdown"
                    transition={stickyState.isVisible !== null ? 'transform 250ms ease-in-out' : ''}
                    bgColor={['accent.main', 'accent.main', 'transparent']}
                    {...(stickyState.isSticky
                      ? { pos: ['fixed', 'fixed', 'relative'], top: 14 }
                      : { pos: ['absolute', 'absolute', 'relative'], top: 0 })}
                    {...determineStickyVisibilityTranslate(stickyState)}
                  >
                    <Collapse startingHeight="3.313rem" in={isDesktop || isOpen_sideMenu}>
                      <Flex
                        w="full"
                        flexDir="column"
                        alignItems="stretch"
                        bgColor={['bg.primary', 'bg.primary', 'transparent']}
                        shadow={['md', 'md', 'none']}
                      >
                        <SectionLink
                          href="#about"
                          icon={<UserIcon fontSize="md" />}
                          isActive={activeSection === 'about'}
                          order={!isDesktop && activeSection === 'about' ? -1 : 0}
                          isChecked={
                            sectionsCompletionStatus.personalInfo &&
                            sectionsCompletionStatus.socialProfile &&
                            sectionsCompletionStatus.skills
                          }
                          isDesktop={isDesktop}
                          onClick={(event) => {
                            handleSectionLinkClick(event, 'about');
                          }}
                          dropdownIconProps={
                            isOpen_sideMenu ? { transform: 'rotate(-180deg)' } : {}
                          }
                        >
                          About
                        </SectionLink>
                        <SectionLink
                          href="#career-and-education"
                          icon={<EducationIcon fontSize="md" />}
                          isActive={activeSection === 'career-and-education'}
                          order={!isDesktop && activeSection === 'career-and-education' ? -1 : 0}
                          isDesktop={isDesktop}
                          isChecked={
                            sectionsCompletionStatus.workExperience &&
                            sectionsCompletionStatus.educations
                          }
                          onClick={(event) => {
                            handleSectionLinkClick(event, 'career-and-education');
                          }}
                          dropdownIconProps={
                            isOpen_sideMenu ? { transform: 'rotate(-180deg)' } : {}
                          }
                        >
                          Career & Education
                        </SectionLink>
                        <SectionLink
                          href="#resume-and-documents"
                          icon={<FileIcon fontSize="md" />}
                          isActive={activeSection === 'resume-and-documents'}
                          order={!isDesktop && activeSection === 'resume-and-documents' ? -1 : 0}
                          isChecked={sectionsCompletionStatus.resume}
                          isDesktop={isDesktop}
                          onClick={(event) => {
                            handleSectionLinkClick(event, 'resume-and-documents');
                          }}
                          dropdownIconProps={
                            isOpen_sideMenu ? { transform: 'rotate(-180deg)' } : {}
                          }
                        >
                          Resume & Documents
                        </SectionLink>
                        <SectionLink
                          href="#preferences"
                          icon={<SettingsIcon fontSize="md" />}
                          isActive={activeSection === 'preferences'}
                          order={!isDesktop && activeSection === 'preferences' ? -1 : 0}
                          isDesktop={isDesktop}
                          isChecked={
                            sectionsCompletionStatus.roles && sectionsCompletionStatus.desiredSalary
                          }
                          onClick={(event) => {
                            handleSectionLinkClick(event, 'preferences');
                          }}
                          dropdownIconProps={
                            isOpen_sideMenu ? { transform: 'rotate(-180deg)' } : {}
                          }
                        >
                          Preferences
                        </SectionLink>
                      </Flex>
                    </Collapse>
                  </Box>
                </Box>
              </Box>
              <Box display={['none', 'none', 'block']} pl={6} pr={10} mt={[0, 0, 20]}>
                <Image src={ProfileLeftSide} alt="" role="presentation" />
              </Box>
            </Flex>
          </Flex>
        </Box>
        <Flex flex={1} flexDir="column" ml={[0, 0, 10]}>
          <AnchorSection
            anchorID="about"
            onLayout={(el) => {
              setSections((prev) => ({ ...prev, about: el }));
            }}
          >
            <ProfileSection title="Account Information">
              <PersonalInfoController
                candidateID={candidate.id}
                personalInfo={candidate.personalInfo}
              />
            </ProfileSection>

            <ProfileSection title="Social Profiles" shouldDisplayDivider>
              {socialProfile.config.component}
              {socialProfile.config.hasFormValuesChanged() && (
                <Button
                  flex={1}
                  variant="primary"
                  type="submit"
                  onClick={handleOnSaveClick}
                  isLoading={isSavingForm}
                  mt={6}
                >
                  Save
                </Button>
              )}
            </ProfileSection>
            <ProfileSection title="Skills" shouldDisplayDivider>
              <SkillsController
                skillGroups={candidate.skillGroups}
                candidateID={candidate.id}
                skillChoices={skillChoices}
                userID={userID}
                isModalOpen={isModalOpen && selectedModal === 'skills'}
                onModalClose={onModalClose}
                onModalOpen={() => onModalOpen('skills')}
              />
            </ProfileSection>

            <ProfileSection title="YEARS OF RELEVANT EXPERIENCE" shouldDisplayDivider>
              <YearsOfExperienceController
                candidateID={candidate.id}
                userID={userID}
                candidateYearsOfExperienceRange={candidate.yearsOfExperienceRange}
                yearsOfExperienceRangeChoices={yearsOfExperienceChoices}
              />
            </ProfileSection>
          </AnchorSection>
          <AnchorSection
            anchorID="career-and-education"
            onLayout={(el) => {
              setSections((prev) => ({ ...prev, 'career-and-education': el }));
            }}
          >
            <ProfileSection title="Work Experience" shouldDisplayDivider>
              <WorkExperiencesController
                candidateID={candidate.id}
                isModalOpen={isModalOpen && selectedModal === 'work-experience'}
                onModalClose={onModalClose}
                onModalOpen={() => onModalOpen('work-experience')}
                userID={userID}
                workExperiences={candidate.workExperiences}
              />
            </ProfileSection>
            <ProfileSection title="Education" shouldDisplayDivider>
              <EducationsController
                degreeChoices={degreeChoices}
                educations={candidate.educations}
                candidateID={candidate.id}
                userID={userID}
                isModalOpen={isModalOpen && selectedModal === 'education'}
                onModalClose={onModalClose}
                onModalOpen={() => onModalOpen('education')}
              />
            </ProfileSection>
          </AnchorSection>
          <AnchorSection
            anchorID="resume-and-documents"
            onLayout={(el) => {
              setSections((prev) => ({ ...prev, 'resume-and-documents': el }));
            }}
          >
            <ProfileSection title="Resume" shouldDisplayDivider>
              <ResumeController
                resume={candidate.resume}
                candidateID={candidate.id}
                isModalOpen={isModalOpen && selectedModal === 'resume'}
                onModalClose={onModalClose}
                onModalOpen={() => onModalOpen('resume')}
              />
            </ProfileSection>
          </AnchorSection>
          <AnchorSection
            anchorID="preferences"
            onLayout={(el) => {
              setSections((prev) => ({ ...prev, preferences: el }));
            }}
          >
            <ProfileSection title="Desired Roles" shouldDisplayDivider>
              <RolesController
                candidateRoles={candidate.roles}
                candidateID={candidate.id}
                roleChoices={roleChoices}
                userID={userID}
                onModalClose={onModalClose}
                onModalOpen={() => onModalOpen('roles')}
              />
            </ProfileSection>
            <ProfileSection title="Desired Salary" shouldDisplayDivider>
              <DesiredSalaryController
                userID={userID}
                candidateID={candidate.id}
                desiredSalaryData={candidate.desiredSalary.formValues}
              />
            </ProfileSection>
          </AnchorSection>
        </Flex>
      </Flex>
    </Container>
  );
}
