import type { HStepsProps } from 'candidate/components';
import { useExperiment } from 'candidate/utils';
import type { ProfileProps } from '../../../../Profile.types';
import { RolesSelectionController } from './RolesSelection.controller';
import { RolesAndJobsController } from './RolesAndJobs.controller';

export function RolesController({
  candidateID,
  candidateRoles,
  onSignOutClick,
  onStepCompleteSuccess,
  progressProps,
  roleChoices,
  userFullName,
  userID,
}: {
  candidateID: number;
  candidateRoles: ProfileProps['candidate']['roles'];
  onSignOutClick: () => Promise<void>;
  onStepCompleteSuccess: () => void;
  progressProps: HStepsProps;
  roleChoices: ProfileProps['roleChoices'];
  userFullName: string;
  userID?: number;
}) {
  const experiment = useExperiment();
  const roleVariation = experiment.role;

  if (['select-job-and-role', 'select-job-and-role-with-banner'].includes(roleVariation)) {
    return (
      <RolesAndJobsController
        candidateID={candidateID}
        candidateRoles={candidateRoles}
        onStepCompleteSuccess={onStepCompleteSuccess}
        progressProps={progressProps}
        roleChoices={roleChoices}
        userID={userID}
        shouldDisplayBanner={roleVariation === 'select-job-and-role-with-banner'}
      />
    );
  }

  return (
    <RolesSelectionController
      candidateID={candidateID}
      candidateRoles={candidateRoles}
      onSignOutClick={onSignOutClick}
      onStepCompleteSuccess={onStepCompleteSuccess}
      progressProps={progressProps}
      roleChoices={roleChoices}
      userFullName={userFullName}
      userID={userID}
    />
  );
}
