import { PublicPageLayout } from 'candidate/components';
import {
  Box,
  Flex,
  Container,
  Text,
  Heading,
  Link,
  Image,
  Grid,
  CheckCircleSolidIcon,
} from '@terminal/design-system';
import { Link as RouterLink } from 'react-router-dom';
import GlobeSVG from './globe.svg?url';

export function CountryNotSupportedThankYou() {
  return (
    <PublicPageLayout
      headerRightPlaceholderDesktop={
        <Flex alignItems="center">
          <Text>Don’t Have An Account?</Text>
          <Link as={RouterLink} to="/onboarding" ml={2} fontWeight="bold">
            Sign Up
          </Link>
        </Flex>
      }
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link
            as={RouterLink}
            to={`/auth/login${window.location.search}`}
            ml={2}
            fontWeight="bold"
          >
            Login
          </Link>
        </Flex>
      }
    >
      <Container flex="1" px={[3, 3, 0]} py={0}>
        <Grid gridTemplateColumns={['1fr', '1fr', '1fr 1fr']} h={['auto', 'auto', '100%']}>
          <Flex
            pos="relative"
            bg={['transparent', 'transparent', 'bg.tertiary']}
            alignItems="center"
            justifyContent="center"
            flex="1"
            pr={[0, 0, 12]}
          >
            <Image
              src={GlobeSVG}
              alt="coming soon at your country"
              role="presentation"
              mt={[18, 18, 0]}
            />
            <Box
              display={['none', 'none', 'block']}
              bg="bg.tertiary"
              w="50vw"
              h="full"
              position="absolute"
              top={0}
              right="100%"
            />
          </Flex>
          <Flex
            justifyContent="center"
            alignItems={['center', 'center', 'flex-start']}
            direction="column"
            flex="1"
            ml={[0, 0, '18%']}
            mt={[6, 6, 0]}
          >
            <CheckCircleSolidIcon fontSize="30px" color="ui.success" />
            <Heading
              variant="heading-2"
              textAlign={['center', 'center', 'left']}
              mt={6}
              color="brand.main"
            >
              Thank You For Joining!
            </Heading>
            <Text mt={2} textAlign={['center', 'center', 'left']}>
              You will receive an email when new opportunities are available in your country.
            </Text>
          </Flex>
        </Grid>
      </Container>
    </PublicPageLayout>
  );
}
