import { useMutation } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import { useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import { User_Subscription_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { useQuery } from 'global/utils';
import type {
  DeleteUserSubscriptionMutation,
  DeleteUserSubscriptionMutationVariables,
  InsertUserSubscriptionMutation,
  InsertUserSubscriptionMutationVariables,
  SelectJobSuggestionsQuery,
  SelectJobSuggestionsQueryVariables,
} from './data/types';
import { DeleteUserSubscription, InsertUserSubscription, SelectJobSuggestions } from './data';

export function useJobSuggestionsController({
  userID,
  candidateID,
}: {
  candidateID: number;
  userID: number;
}) {
  const toast = useToast();

  const { loading: isLoading_selectJobSuggestions, data } = useQuery<
    SelectJobSuggestionsQuery,
    SelectJobSuggestionsQueryVariables
  >(SelectJobSuggestions, {
    context: {
      role: 'candidate',
    },
    variables: {
      candidate_id: candidateID,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [insertUserSubscription] = useMutation<
    InsertUserSubscriptionMutation,
    InsertUserSubscriptionMutationVariables
  >(InsertUserSubscription, {
    onError: (error: ApolloError) => {
      toast({
        description: 'Something went wrong trying to update your subscription. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
    update(cache, { data: updateData }) {
      try {
        const didSubscribe = updateData?.insert_user_subscription?.affected_rows === 1;

        const readData = cache.readQuery<SelectJobSuggestionsQuery>({
          query: SelectJobSuggestions,
          variables: {
            candidate_id: candidateID,
          },
        });

        const updatedJobSuggestions = {
          ...readData,
          user_subscription: didSubscribe
            ? [{ subscription: User_Subscription_Choices_Enum.NewJobAlert }]
            : [],
        };

        cache.writeQuery({
          query: SelectJobSuggestions,
          broadcast: true,
          variables: {
            candidate_id: candidateID,
          },
          data: updatedJobSuggestions,
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  const [deleteUserSubscription] = useMutation<
    DeleteUserSubscriptionMutation,
    DeleteUserSubscriptionMutationVariables
  >(DeleteUserSubscription, {
    onError: (error: ApolloError) => {
      toast({
        description: 'Something went wrong trying to update your subscription. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
    update(cache, { data: updateData }) {
      try {
        const didUnsubscribe = updateData?.delete_user_subscription?.affected_rows === 1;

        const readData = cache.readQuery<SelectJobSuggestionsQuery>({
          query: SelectJobSuggestions,
          variables: {
            candidate_id: candidateID,
          },
        });

        const updatedJobSuggestions = {
          ...readData,
          user_subscription: didUnsubscribe
            ? []
            : [{ subscription: User_Subscription_Choices_Enum.NewJobAlert }],
        };

        cache.writeQuery({
          query: SelectJobSuggestions,
          broadcast: true,
          variables: {
            candidate_id: candidateID,
          },
          data: updatedJobSuggestions,
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  const handleOnMatchAlertsChange = (isSubscribedToAlerts: boolean) => {
    if (!isSubscribedToAlerts) {
      insertUserSubscription({
        variables: {
          objects: {
            subscription: User_Subscription_Choices_Enum.NewJobAlert,
            user_info_id: userID,
          },
        },
        optimisticResponse: {
          insert_user_subscription: {
            affected_rows: 1,
            returning: [
              {
                __typename: 'user_subscription',
                user_info_id: userID,
                subscription: User_Subscription_Choices_Enum.NewJobAlert,
              },
            ],
          },
        },
      });
    } else {
      deleteUserSubscription({
        variables: {
          id: userID,
        },
        optimisticResponse: {
          delete_user_subscription: {
            affected_rows: 1,
            returning: [
              {
                __typename: 'user_subscription',
                user_info_id: userID,
                subscription: User_Subscription_Choices_Enum.NewJobAlert,
              },
            ],
          },
        },
      });
    }
  };

  return {
    isLoading_selectJobSuggestions,
    handleOnMatchAlertsChange,
    data,
  };
}
