import type { ProfileProps, SelectProfileQuery } from 'candidate/features/profile/Profile.types';
import { toFriendlyYearsOfExperience } from 'global/utils';
import { Role_Choices_Enum } from 'global/types/hasura-tables.generated.types';

export const displayRolesNames: Record<Role_Choices_Enum | string, string> = {
  [Role_Choices_Enum.Fed]: 'Frontend',
  [Role_Choices_Enum.Bed]: 'Backend',
  [Role_Choices_Enum.Fsd]: 'Full Stack',
  [Role_Choices_Enum.Mqa]: 'Manual QA',
  [Role_Choices_Enum.Aqa]: 'Auto QA',
  [Role_Choices_Enum.Dev]: 'DevOps',
  [Role_Choices_Enum.Mod]: 'Mobile',
  [Role_Choices_Enum.Dsc]: 'Data Science',
  [Role_Choices_Enum.Den]: 'Data Engineer',
  [Role_Choices_Enum.Dan]: 'Data Analyst',
  [Role_Choices_Enum.Enl]: 'Eng. Lead',
  [Role_Choices_Enum.Aml]: 'AI/ML',
  [Role_Choices_Enum.Scm]: 'Scrum Master',
  [Role_Choices_Enum.Prm]: 'Product Manager',
  [Role_Choices_Enum.Des]: 'Designer',
};

export const displayRolesOrder: Record<Role_Choices_Enum | string, number> = Object.keys(
  displayRolesNames,
).reduce((result, key, index) => ({ ...result, [key]: index }), {});

export function serializerRole(
  roles: SelectProfileQuery['candidates'][number]['candidate_roles'],
): ProfileProps['candidate']['roles'] {
  return roles
    .slice()
    .sort((a, b) => (b.years_of_exp || -1) - (a.years_of_exp || -1))
    .map(({ id, role, years_of_exp }) => ({
      id,
      role: {
        name: role,
        displayName: displayRolesNames[role] || '',
      },
      yearsOfExperience: years_of_exp ? toFriendlyYearsOfExperience(years_of_exp) : null,
      formValues: {
        id: id.toString(),
        role: role || null,
        years_of_exp: years_of_exp?.toString() || '',
      },
    }));
}
