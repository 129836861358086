import { Route, useRouteMatch } from 'react-router-dom';
import { ErrorBoundary } from 'global/sentry';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import {
  ErrorFallback,
  PageLayout,
  ScrollToTopOnPathChange,
  SwitchWithPageNotFound,
} from 'candidate/components';
import { useRedirection } from 'global/utils';
import { useAppInitData } from 'candidate/utils/general';
import { useCandidateAuth } from 'candidate/utils';
import { ProfileController } from './Profile.controller';

export function ProfileRouter() {
  const auth = useCandidateAuth();
  const { path } = useRouteMatch();
  const redirectTo = useRedirection();
  const { candidateAvailability } = useAppInitData({ userID: auth.user?.id });

  return (
    <PageLayout.Variation.WithAllSections
      onSignOutClick={() => signOut({ auth: firebaseAuth })}
      userFullName={auth.userFullName}
      shouldNotWrapWithContainer
      {...{ candidateAvailability }}
    >
      <ErrorBoundary
        fallback={
          <ErrorFallback
            onActionClick={() => {
              redirectTo('/profile');
              window.location.reload();
            }}
            actionTitle="Go back to Edit Profile"
          />
        }
      >
        <ScrollToTopOnPathChange />
        <SwitchWithPageNotFound>
          <Route path={`${path}/`}>
            <ProfileController />
          </Route>
        </SwitchWithPageNotFound>
      </ErrorBoundary>
    </PageLayout.Variation.WithAllSections>
  );
}
