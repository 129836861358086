import { Link } from '@terminal/design-system';
import pageNotFound404ImageSrc from 'candidate/assets/images/404-rebrand.svg?url';
import { ExceptionPage } from 'candidate/components/ExceptionPage';

export function PageNotFound() {
  return (
    <ExceptionPage
      title="404"
      subTitle="Page Not Found"
      imageSrc={pageNotFound404ImageSrc}
      description={
        <>
          We are sorry, the page you requested could not be found. Please go back to the homepage or
          contact us at{' '}
          <Link display="inline" href="mailto:support@terminal.io" isExternal>
            support@terminal.io
          </Link>
          .
        </>
      }
    />
  );
}
