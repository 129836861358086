import * as Yup from 'yup';

export const workExperiences_validationSchema = Yup.object().shape({
  job_title: Yup.string().required('Please enter a job title.'),
  company_name: Yup.string().required('Please enter a company name.'),
  location: Yup.string().required('Please enter a location.'),
  startDateMonth: Yup.number()
    .typeError('Please select start month.')
    .required('Please select start month.'),
  startDateYear: Yup.number()
    .typeError('Please select start year.')
    .required('Please select start year.')
    .test(
      'conditional-required-end-year',
      "Start Date can't be in the future.",
      function isStartDateBeforeNow(startDateYear): boolean {
        const { startDateMonth } = this.parent;
        const now = new Date();

        return !(
          Number(startDateYear) >= Number(now.getFullYear()) &&
          Number(startDateMonth) > Number(now.getMonth())
        );
      },
    ),
  endDateMonth: Yup.number()
    .test(
      'conditional-required-end-month',
      'Please select end month.',
      function hasEndDateMonthValue_whenCurrentlyWorking(endDateMonth): boolean {
        const { currently_working } = this.parent;
        return currently_working === 'true' || endDateMonth != null;
      },
    )
    .nullable(),
  endDateYear: Yup.number()
    .test(
      'end-date-greater-start-date',
      'Please select end year.',
      function hasEndDateYearValue_whenCurrentlyWorking(endDateYear): boolean {
        const { currently_working } = this.parent;
        return currently_working === 'true' || endDateYear != null;
      },
    )
    .test(
      'conditional-required-end-year',
      'Please select end date that is more recent than the start date.',
      function isStartDateLessRecent_thanEndDate(endDateYear): boolean {
        const { startDateMonth, startDateYear, endDateMonth, currently_working } = this.parent;

        if (currently_working === 'true') {
          return true;
        }

        if (Number.isNaN(startDateYear) || Number.isNaN(startDateMonth)) {
          return true;
        }

        if (Number(endDateYear) > Number(startDateYear)) {
          return true;
        }

        if (Number(endDateYear) < Number(startDateYear)) {
          return false;
        }

        return Number(endDateMonth) > Number(startDateMonth);
      },
    )
    .test(
      'conditional-required-end-year',
      "End Date can't be in the future.",
      function isEndDateBeforeNow(endDateYear): boolean {
        const { endDateMonth, currently_working } = this.parent;

        if (currently_working === 'true') {
          return true;
        }

        const now = new Date();

        return !(
          Number(endDateYear) >= Number(now.getFullYear()) &&
          Number(endDateMonth) > Number(now.getMonth())
        );
      },
    )
    .nullable(),
  currently_working: Yup.string().notRequired(),
});
