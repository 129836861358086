import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useDisclosure, useToast } from '@terminal/design-system';
import { useAppInitData, useCandidateAuth } from 'candidate/utils';
import { BlankScreenLoading } from 'global/components';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import { PageLayout, PageNotFound } from 'candidate/components';
import { useWindowSize } from 'global/utils';
import { useParams } from 'react-router-dom';
import { CompanyPage } from 'candidate/shared/modules/company';
import type {
  SelectCandidateQuery,
  SelectCandidateQueryVariables,
  SelectCurrencyExchangeRatesQuery,
  SelectCurrencyExchangeRatesQueryVariables,
} from 'candidate/shared/modules';
import { SelectCandidate, SelectCurrencyExchangeRates } from 'candidate/shared/modules';
import Sentry from 'global/sentry';
import { serializeForCompany } from './Company.serializer';
import { SelectCompanyInfo } from './data';
import type { SelectCompanyInfoQuery, SelectCompanyInfoQueryVariables } from './data';

export function CompanyController() {
  const auth = useCandidateAuth();
  const toast = useToast();
  const { candidateAvailability } = useAppInitData({ userID: auth.user?.id });
  const { height: viewportHeight } = useWindowSize();
  const companyCardPosition = document
    .getElementById('companyCard')
    ?.getBoundingClientRect().bottom;
  const illustrationHeight = document.getElementById('illustration')?.clientHeight;
  const { id } = useParams<{ id: string }>();
  const { isOpen: shouldDisplay_fullAbout, onToggle: toggleFullAbout } = useDisclosure({
    defaultIsOpen: false,
  });
  const { isOpen: shouldDisplay_fullCulture, onToggle: toggleFullCulture } = useDisclosure({
    defaultIsOpen: false,
  });

  const { loading: isLoading_SelectCandidateInfo, data: selectCandidateInfoData } = useQuery<
    SelectCandidateQuery,
    SelectCandidateQueryVariables
  >(SelectCandidate, {
    context: {
      role: 'candidate',
    },
    variables: {
      user_id: auth.user?.id as number,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading: isLoading_selectCurrencyExchangeRatesQuery, data: currencyExchangeRates } =
    useQuery<SelectCurrencyExchangeRatesQuery, SelectCurrencyExchangeRatesQueryVariables>(
      SelectCurrencyExchangeRates,
      {
        fetchPolicy: 'cache-and-network',
      },
    );

  const candidateInfo = selectCandidateInfoData?.candidate[0];

  const { loading: loading_companyInfo, data } = useQuery<
    SelectCompanyInfoQuery,
    SelectCompanyInfoQueryVariables
  >(SelectCompanyInfo, {
    skip: !candidateInfo,
    context: {
      role: 'candidate',
    },
    variables: {
      // @ts-ignore
      // TODO: Since this `ID` can be modified by a user, it may be worthwhile to avoid making the request when it is not a number, to avoid false positives in sentry.
      id: id as number,
      // it is safe to cast since we are skipping if candidateInfo is falsy
      candidate_country: candidateInfo?.country_choice?.name as string,
    },
    onError: (error: ApolloError) => {
      if (!candidateInfo?.country_choice?.name) {
        toast({
          description:
            'Your profile is missing important information. Please go to the profile page to enter your country.',
          status: 'error',
        });
        return;
      }

      Sentry.captureException(error);
    },
  });

  if (
    loading_companyInfo ||
    isLoading_SelectCandidateInfo ||
    isLoading_selectCurrencyExchangeRatesQuery
  ) {
    return <BlankScreenLoading />;
  }

  // TODO [CAND-1298]: properly manage 404
  if (!data?.organization_by_pk) {
    return (
      <PageLayout.Variation.WithAllSections
        onSignOutClick={() => signOut({ auth: firebaseAuth })}
        userFullName={auth.userFullName}
        candidateAvailability={candidateAvailability}
      >
        <PageNotFound />
      </PageLayout.Variation.WithAllSections>
    );
  }

  const companyInfo = serializeForCompany({
    data,
    applicantJobs: candidateInfo?.applicant_jobs,
    candidateJobs: candidateInfo?.candidate_jobs,
    conversionTable: currencyExchangeRates,
    candidateInfo: {
      candidateCountry: candidateInfo?.country_choice?.name || null,
      candidateSkills: candidateInfo?.candidate_skills?.map(({ skill }) => skill.name),
      yearsOfExperience: candidateInfo?.candidate_curation_detail?.years_of_exp_range,
    },
  });

  return (
    <CompanyPage
      aboutViewMore_onClick={toggleFullAbout}
      candidateAvailability={candidateAvailability}
      companyInfo={companyInfo}
      cultureViewMore_onClick={toggleFullCulture}
      onSignOutClick={() => signOut({ auth: firebaseAuth })}
      shouldDisplay_fullAbout={shouldDisplay_fullAbout}
      shouldDisplay_fullCulture={shouldDisplay_fullCulture}
      userFullName={auth.userFullName}
      isIllustrationOverlapping={
        viewportHeight < (companyCardPosition as number) + (illustrationHeight as number)
      }
    />
  );
}
