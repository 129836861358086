import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { SpaceProps } from '@terminal/design-system';
import { PageLayout, WideLeftNarrowRightLayout } from 'candidate/components';
import {
  Avatar,
  ArrowRightIcon,
  Box,
  Button,
  Container,
  Heading,
  Flex,
  Image,
  Text,
  EmailVerificationIcon,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@terminal/design-system';
import { useIsViewPortDesktop, useRedirection } from 'global/utils';
import { HorizontalCarousel } from 'candidate/app/components';
import {
  CommunicationModal,
  CommunicationModalBody,
  CommunicationModalFooterCTAs,
} from 'candidate/components/CommunicationModal';
import { JobCard } from 'candidate/shared/modules/job/JobCard';
import { PopupModal } from 'react-calendly';
import personalizeHeaderBg from './assets/personalized-header-bg.png';
import resumeParsedComplete from './assets/welcome-resume-parsed.svg?url';
import resumeFailureImage from './assets/resume-failure.svg?url';
import type { serializerForHomePage } from './HomePage.serializer';
import OwlImage from './assets/owl.png';

function OnboardingSlide({
  title,
  description,
  isDesktop,
}: {
  title: string;
  description: string;
  isDesktop?: boolean;
}) {
  return (
    <Box textAlign="center">
      <Heading
        variant={isDesktop ? 'heading-3' : 'heading-2'}
        color={['brand.main', 'brand.main', 'accent.main']}
      >
        {title}
      </Heading>
      <Text mt={2}>{description}</Text>
    </Box>
  );
}

function TalentPartner({
  fullName,
  email,
  image,
}: {
  fullName: string;
  email: string;
  image: string | null;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <Flex
      bg="bg.primary"
      border="1px solid"
      borderColor="ui.secondary"
      p={[3, 3, 6]}
      w="full"
      alignItems="center"
      justifyContent="space-between"
      as="a"
      href={`mailto:${email}`}
      _hover={{
        bg: 'bg.secondary',
        cursor: 'pointer',
      }}
    >
      {/* Ignoring in chromatic since sometimes the images take a little time to load */}
      {image && <Avatar mr={3} boxSize={12} name={fullName} src={image} data-chromatic="ignore" />}
      <Flex flexDir="row" alignItems="center" justifyContent="space-between" mr={3}>
        <Box>
          <Text variant={isDesktop ? 'hint' : 'caption'} color="text.secondary" fontWeight="bold">
            YOUR TALENT PARTNER
          </Text>
          <Heading variant="heading-3">{fullName}</Heading>
          <Text>Contact through email with any concerns.</Text>
        </Box>
      </Flex>
      <Box py={3} px={2} minW="auto" h={12}>
        <EmailVerificationIcon fontSize="md" color="accent.main" />
      </Box>
    </Flex>
  );
}

export function PersonalizedHeader({
  title,
  description,
  buttonCTA,
  redirectToURL,
  candidateInfo,
  calendlyModal,
  onRender,
  onCTAButtonClick,
  ...containerSpaceProps
}: {
  title: string;
  description: string;
  buttonCTA?: string;
  /* This redirection happens when the CTA button is clicked
  use `onCTAButtonClick` if you want to handle the click event in a different way */
  redirectToURL?: string;
  candidateInfo?: {
    name: string;
    email: string;
    calendlyMeetingURL: string | null;
    interviewRequestID: number;
  };
  calendlyModal: {
    isOpen: boolean;
    onClose: () => void;
  };
  onRender?: () => void;
  onCTAButtonClick?: () => void;
} & SpaceProps) {
  const redirectTo = useRedirection();
  useEffect(() => {
    if (onRender) {
      onRender();
    }
  }, [onRender]);
  const isDesktop = useIsViewPortDesktop();
  const rootElement =
    document.getElementById('root') ||
    // So that this component is displayed in the storybook as well
    document.getElementById('storybook-root');

  if (!rootElement) return null;

  return (
    <>
      <Flex
        bg={['bg.primary', 'bg.primary', 'bg.tertiary']}
        boxShadow={['md', 'md', 'none']}
        top={[-10, -10, 0]}
        position="relative"
        borderRadius={['base', 'base', 'none']}
        {...containerSpaceProps}
      >
        <Flex flexDir="column" px={[3, 3, 6]} py={6} flex={1}>
          {isDesktop ? (
            <Heading variant="heading-2">{title}</Heading>
          ) : (
            <Heading variant="heading-3">{title}</Heading>
          )}
          <Text mt={2}>{description}</Text>
          {buttonCTA && (redirectToURL || onCTAButtonClick) && (
            <Button
              w={['full', 'full', 'min']}
              mt={[3, 3, 6]}
              onClick={() => {
                if (onCTAButtonClick) onCTAButtonClick();
                if (redirectToURL) redirectTo(redirectToURL as string);
              }}
            >
              {buttonCTA}
            </Button>
          )}
        </Flex>
        {isDesktop && (
          <Box flex={1}>
            <Image src={personalizeHeaderBg} position="absolute" right={6} bottom={0} />
          </Box>
        )}
      </Flex>
      {candidateInfo?.calendlyMeetingURL && (
        <PopupModal
          url={candidateInfo?.calendlyMeetingURL}
          rootElement={rootElement}
          open={calendlyModal.isOpen}
          onModalClose={calendlyModal.onClose}
          prefill={{
            email: candidateInfo?.email,
            name: candidateInfo?.name,
          }}
          utm={{
            utmContent: `interview_request:${candidateInfo.interviewRequestID}`,
          }}
        />
      )}
    </>
  );
}

export function TalentPartnerJobRecommendations({
  jobRecommendations,
  onJobDetailClick,
}: {
  jobRecommendations: ReturnType<typeof serializerForHomePage>['talentPartnerJobRecommendations'];
  onJobDetailClick: (id: number) => void;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <Box p={[3, 3, 6]} mb={6} mt={[-8, -8, 0]} bgColor="ui.lightest.warning">
      <Heading variant="heading-3">Handpicked Jobs to Review</Heading>
      <Text>Your Talent Partner picked some jobs especially for you.</Text>
      {jobRecommendations?.map((jobRecommendation) => (
        <Box bgColor="bg.primary" mt={4}>
          <JobCard
            jobSection="tp-suggestion"
            onJobDetailClick={() => onJobDetailClick(jobRecommendation.jobID as number)}
            jobSuggestionData={{
              applied: false,
              // job should not be without ID
              id: jobRecommendation.jobID as number,
              image: {
                src: jobRecommendation.summary.logo_sq || jobRecommendation.summary.logo,
                alt: `${jobRecommendation.summary.companyName} logo`,
              },
              skills: jobRecommendation.summary.skills,
              title: jobRecommendation.summary.companyName,
              subtitle: jobRecommendation.summary.jobTitle,
              salary: jobRecommendation.summary.salary,
              isTopApplicant: jobRecommendation.jobDescription.topApplicantInfo.isTopApplicant,
              jobDetail: jobRecommendation.jobDescription,
            }}
            maxSkillsToDisplay={isDesktop ? 5 : 2}
          />
        </Box>
      ))}
    </Box>
  );
}

function AppliedJobsSummary({
  appliedJobsSummary,
}: {
  appliedJobsSummary: {
    numberOfApplicationsInProgress: number;
    description: string;
  };
}) {
  return (
    <Flex
      bg="bg.primary"
      border="1px solid"
      borderColor="ui.secondary"
      p={[3, 3, 6]}
      pb={6}
      pt={[8, 8, 6]}
      w="full"
      alignItems="center"
      justifyContent="space-between"
      _hover={{
        bg: 'bg.secondary',
        cursor: 'pointer',
      }}
      as={RouterLink}
      to="/my-jobs/applied"
      gridGap={6}
    >
      <Flex alignItems="center">
        <Heading variant="heading-1">{appliedJobsSummary.numberOfApplicationsInProgress}</Heading>
        <Box ml={3}>
          <Heading variant="heading-3">Applications in Progress</Heading>
          <Text variant="body">{appliedJobsSummary.description}</Text>
        </Box>
      </Flex>
      <Button
        variant="ghost"
        colorScheme="accent"
        as={RouterLink}
        to="/my-jobs/applied"
        _hover={{
          bgColor: 'transparent',
        }}
      >
        <ArrowRightIcon color="accent.main" />
      </Button>
    </Flex>
  );
}

function TerminalProcess({
  isOpen,
  onClose,
  onOpen,
}: {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}) {
  return (
    <>
      <Flex
        alignItems="center"
        bg="bg.primary"
        border="1px solid"
        borderColor="ui.secondary"
        gridGap={6}
        justifyContent="space-between"
        p={[3, 3, 6]}
        pb={6}
        pt={[8, 8, 6]}
        w="full"
        _hover={{
          bg: 'bg.secondary',
          cursor: 'pointer',
        }}
        onClick={onOpen}
      >
        <Flex alignItems="center">
          <Image src={OwlImage} w={9} h={9} />
          <Box ml={3}>
            <Heading variant="heading-3">Terminal Process</Heading>
            <Text variant="body">
              Learn about your Terminal Journey and what to expect from it.
            </Text>
          </Box>
        </Flex>
        <Button
          colorScheme="accent"
          variant="ghost"
          _hover={{
            bgColor: 'transparent',
          }}
          onClick={onOpen}
        >
          <ArrowRightIcon color="accent.main" />
        </Button>
      </Flex>
      {isOpen && (
        <Modal
          isCentered
          isOpen
          onClose={onClose}
          closeOnEsc
          closeOnOverlayClick
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent maxW="lg">
            <ModalCloseButton color="ui.primary" />
            <Flex bg="bg.tertiary" alignItems="center" justifyContent="center" h="min" py={10}>
              <Image src={OwlImage} />
            </Flex>
            <ModalBody p={6} pl={[3, 3, 8]} pr={[3, 3, 8]}>
              <Heading variant="heading-2" color="brand.main" textAlign="center">
                Terminal Process
              </Heading>
              <Text variant="body" mt={2}>
                Terminal is where global engineering talent just like you finds high-impact, fully
                remote opportunities with innovative US and UK tech companies. Here&apos;s how it
                works:
              </Text>

              <Text
                variant="body"
                sx={{
                  li: { marginTop: 2 },
                  ol: { listStyle: 'inside decimal' },
                }}
              >
                <ol>
                  <li>
                    <strong>Get matched:</strong> Once your profile is complete, you&apos;ll get
                    matched with our exclusive roles and will be able to apply to even more
                    opportunities. A person from our team will contact you for a pre-screen.
                  </li>
                  <li>
                    <strong>Interviews:</strong> We facilitate the interview process with the
                    partner company and keep you informed along the way. If they choose to extend an
                    offer, we help facilitate any negotiations and paperwork.
                  </li>
                  <li>
                    <strong>Your new role:</strong> Terminal will help make sure you get everything
                    you need to get started and have a successful onboarding at your partner
                    company.
                  </li>
                  <li>
                    <strong>Ongoing support:</strong> Although you work 100% of your time with your
                    partner company, Terminal is here anytime for support related to your
                    employment!
                  </li>
                </ol>
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export function HomePage({
  appliedJobsSummary,
  userFullName,
  onSignOutClick,
  leftSections,
  onboardingSlides,
  parseResumeCompletionModal,
  parseResumeFailureModal,
  personalizedHeaderProps,
  candidateAvailability,
  talentPartnerInfo,
  terminalProcessModal,
}: {
  appliedJobsSummary: {
    numberOfApplicationsInProgress: number;
    description: string;
  };
  terminalProcessModal: {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
  };
  userFullName: string;
  onSignOutClick: () => Promise<void>;
  leftSections?: React.ReactNode;
  onboardingSlides: {
    slides: {
      title: string;
      description: string;
      imageSRC?: string;
    }[];
    card: {
      onSlideChange: (toSlideIndex: number) => void;
      onRender: (slideIndex: number) => void;
    };
    modal: {
      onClose: () => void;
      isOpen: boolean;
      onSlideChange: (toSlideIndex: number) => void;
      onRender: (slideIndex: number) => void;
      onOpen?: () => void;
    };
  };
  parseResumeCompletionModal: {
    onClose: () => void;
    isOpen: boolean;
    onGoToProfileClick: () => void;
  };
  parseResumeFailureModal: {
    onClose: () => void;
    isOpen: boolean;
    onGoToProfileClick: () => void;
  };
  personalizedHeaderProps: React.ComponentProps<typeof PersonalizedHeader>;
  candidateAvailability: string | null;
  talentPartnerInfo: React.ComponentProps<typeof TalentPartner> | null;
}) {
  const isDesktop = useIsViewPortDesktop();
  const [toSlideIndex, setToSlideIndex] = useState<number>(0);

  return (
    <>
      <PageLayout.Variation.WithAllSections
        shouldNotWrapWithContainer
        {...{ candidateAvailability, onSignOutClick, userFullName }}
      >
        <Flex flex="1" flexDir="column">
          {isDesktop === false && (
            <Box bg="bg.tertiary" pb={10} mt="-1px">
              <Flex p={3} alignItems="center" justifyContent="space-between">
                <Flex flexDir="column">
                  <Text variant="caption">Welcome,</Text>
                  <Heading variant="heading-2">{userFullName}</Heading>
                </Flex>
                <Avatar
                  className="notranslate"
                  name={userFullName}
                  color="text.inverse"
                  bg="accent.darkest"
                />
              </Flex>
            </Box>
          )}
          <Container p={[3, 3, 10]} pt={[0, 0, 10]}>
            {isDesktop && (
              <>
                <Heading variant="heading-3" color="text.secondary">
                  Welcome,
                </Heading>
                <Heading>{userFullName}</Heading>
              </>
            )}

            <PersonalizedHeader {...personalizedHeaderProps} mt={[0, 0, 6]} />

            <WideLeftNarrowRightLayout
              mt={[6, 6, 10]}
              leftContainerProps={{
                maxW: ['full', 'full', 'xl'],
              }}
              rightContainerProps={{
                maxW: ['full', 'full', 'md'],
                mt: [3, 3, 0],
              }}
              left={
                <Box mt={[0, 0, 0]} w="full">
                  {leftSections}
                </Box>
              }
              right={
                isDesktop && (
                  <VStack w="full" spacing={6}>
                    {!!talentPartnerInfo && <TalentPartner {...talentPartnerInfo} />}
                    <AppliedJobsSummary appliedJobsSummary={appliedJobsSummary} />
                    <TerminalProcess {...terminalProcessModal} />
                  </VStack>
                )
              }
            />
            {isDesktop === false && (
              <VStack w="full" spacing={3}>
                {!!talentPartnerInfo && <TalentPartner {...talentPartnerInfo} />}
                <AppliedJobsSummary appliedJobsSummary={appliedJobsSummary} />
                <TerminalProcess {...terminalProcessModal} />
              </VStack>
            )}
          </Container>
        </Flex>
      </PageLayout.Variation.WithAllSections>
      <CommunicationModal
        image={{
          src: onboardingSlides.slides[toSlideIndex]?.imageSRC as string,
          alt: onboardingSlides.slides[toSlideIndex]?.title as string,
        }}
        onClose={onboardingSlides.modal.onClose}
        isOpen={onboardingSlides.modal.isOpen}
      >
        <HorizontalCarousel
          onRender={onboardingSlides.modal.onRender}
          onSlideChange={(index) => {
            setToSlideIndex(() => index);
            onboardingSlides.modal.onSlideChange(index);
          }}
          isStepsNavigatable={false}
          options={{
            shouldDelaySizingCalculation: true,
          }}
          slides={onboardingSlides.slides.map((props) => (
            <OnboardingSlide {...props} isDesktop={isDesktop} />
          ))}
        />
      </CommunicationModal>
      <CommunicationModal
        isOpen={parseResumeCompletionModal.isOpen}
        image={{ src: resumeParsedComplete, alt: 'resume applied image' }}
        onClose={parseResumeCompletionModal.onClose}
      >
        <CommunicationModalBody>
          <Heading variant="heading-2" color="brand.main" textAlign="center">
            Thanks for Uploading Your Resume!
          </Heading>
          <Text mt={2} textAlign="center">
            Welcome to your Terminal platform. You just filled out a bit of your profile. What do
            you want to do now?
          </Text>
        </CommunicationModalBody>
        <CommunicationModalFooterCTAs
          primaryCTA={{
            title: 'Review Your Info',
            onClick: parseResumeCompletionModal.onGoToProfileClick,
          }}
          secondaryCTA={{
            title: 'Learn More',
            onClick: () => {
              parseResumeCompletionModal.onClose();
              onboardingSlides.modal.onOpen?.();
            },
          }}
        />
      </CommunicationModal>
      <CommunicationModal
        isOpen={parseResumeFailureModal.isOpen}
        image={{ src: resumeFailureImage, alt: 'resume failure image' }}
        onClose={parseResumeFailureModal.onClose}
      >
        <CommunicationModalBody>
          <Heading variant="heading-2" color="brand.main" textAlign="center">
            Thanks for Uploading Your Resume!
          </Heading>
          <Text mt={2} textAlign="center">
            Unfortunately we could not parse your resume successfully. Please fill out your profile
            manually.
          </Text>
        </CommunicationModalBody>
        <CommunicationModalFooterCTAs
          primaryCTA={{
            title: 'Complete your profile',
            onClick: parseResumeFailureModal.onGoToProfileClick,
          }}
          secondaryCTA={{
            title: 'Learn More',
            onClick: () => {
              parseResumeFailureModal.onClose();
              onboardingSlides.modal.onOpen?.();
            },
          }}
        />
      </CommunicationModal>
    </>
  );
}
