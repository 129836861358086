import { useEffect, useState, createRef } from 'react';
import type { ChangeEvent, FocusEvent } from 'react';
import type { FormikState } from 'formik';
import type { SpaceProps } from '@terminal/design-system';
import {
  Box,
  VStack,
  FormControl,
  Input,
  Button,
  FormErrorMessage,
  Flex,
  DeleteIcon,
  FormLabel,
  Typeahead,
  AddIcon,
  Text,
} from '@terminal/design-system';
import type { SkillsProps } from 'candidate/shared/modules';

function SkillFormLayout({
  column1,
  column2,
  column3,
  is3ColumnRow,
  shouldShowYearsOfExperience,
  ...stylingProps
}: {
  column1: React.ReactNode;
  column2?: React.ReactNode;
  column3?: React.ReactNode;
  shouldShowYearsOfExperience?: boolean;
  is3ColumnRow: boolean;
} & SpaceProps) {
  if (is3ColumnRow) {
    return (
      <Flex alignItems="baseline" w="full" {...stylingProps}>
        <Box flex={3.36} mr={[3, 3, 4]}>
          {column1}
        </Box>
        {shouldShowYearsOfExperience && (
          <Box minW="100px" flex={1} mr={[3, 3, 4]}>
            {column2}
          </Box>
        )}
        <Box>{column3}</Box>
      </Flex>
    );
  }

  return (
    <Flex alignItems="baseline" w="full">
      <Box flex={3.36} mr={[3, 3, 4]}>
        {column1}
      </Box>
      {shouldShowYearsOfExperience && (
        <Box flex={1} minW="100px">
          {column2}
        </Box>
      )}
    </Flex>
  );
}

function SkillFormRow({
  errors,
  onBlur,
  onChange,
  onDeleteSkillClick,
  onSelectSkill,
  shouldShowDeleteColumn,
  shouldShowYearsOfExperience,
  skillsOptions,
  values,
  index,
  candidateSkill,
  inputRef,
  touched,
}: {
  onBlur: (event: FocusEvent<any>) => void;
  onChange: (event: string | ChangeEvent<any>) => void;
  onDeleteSkillClick?: (deleteID: string) => void;
  onSelectSkill: (skillName: string, index: number) => void;
  shouldShowDeleteColumn: boolean;
  shouldShowYearsOfExperience?: boolean;
  skillsOptions: Omit<SkillsProps['skillChoices'][number], 'roles'>[];
  index: number;
  candidateSkill: SkillsProps['skillGroups'][number]['skills'][number]['formValues'];
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
} & Pick<
  FormikState<{
    candidateSkills: SkillsProps['skillGroups'][number]['skills'][number]['formValues'][];
  }>,
  'errors' | 'values' | 'touched'
>) {
  useEffect(() => {
    if (inputRef == null || inputRef.current == null || !candidateSkill.name) return;
    inputRef.current.focus();
  }, [candidateSkill.name, inputRef]);

  return (
    <Box key={`skill-fields-${candidateSkill.name}-${candidateSkill.candidateSkillID}`} w="full">
      <Input
        placeholder="0"
        id={`candidateSkills.${index}.id`}
        name={`candidateSkills.${index}.id`}
        value={candidateSkill?.candidateSkillID?.toString()}
        type="hidden"
        css={{ display: 'none' }}
      />
      <SkillFormLayout
        shouldShowYearsOfExperience={shouldShowYearsOfExperience}
        column1={
          <Box>
            <FormControl
              id={`candidateSkills.${index}.name`}
              onClick={(e) => e.currentTarget.scrollIntoView({ behavior: 'smooth' })}
              isInvalid={
                // @ts-ignore
                touched.candidateSkills?.[index]?.name && errors.candidateSkills?.[index]?.name
              }
              sx={{
                'ul[role=listbox]': { position: 'relative', zIndex: 10 },
              }}
            >
              <Typeahead
                name={`candidateSkills.${index}.name`}
                fullScreen={{
                  title: 'Add Skill',
                  renderBefore: ({ getLabelProps }) => (
                    <FormLabel mb={2} {...getLabelProps()}>
                      Skill
                    </FormLabel>
                  ),
                }}
                placeholder="Search Skill"
                shouldOpenOnFocus
                onSelectionChange={(_, skillName) => {
                  onSelectSkill(skillName, index);
                }}
                initialValue={candidateSkill.name || ''}
                options={skillsOptions.map(({ name }) => name)}
                optionsToFilter={values.candidateSkills
                  .filter(({ name }) => !!name)
                  .map(({ name }) => name as string)}
                onBlur={onBlur}
                onInputChange={(event) => {
                  if (event.target.value === '') {
                    onSelectSkill('', index);
                  }
                }}
              />
            </FormControl>
          </Box>
        }
        column2={
          <FormControl
            id={`candidateSkills.${index}.years_of_exp`}
            isInvalid={
              // @ts-ignore
              touched.candidateSkills?.[index]?.years_of_exp &&
              // @ts-ignore
              errors.candidateSkills?.[index]?.years_of_exp
            }
          >
            <Input
              ref={inputRef}
              placeholder="0"
              id={`candidateSkills.${index}.years_of_exp`}
              name={`candidateSkills.${index}.years_of_exp`}
              onChange={onChange}
              onBlur={onBlur}
              value={candidateSkill.years_of_exp || ''}
              type="number"
              inputMode="decimal"
              min={0}
            />
          </FormControl>
        }
        column3={
          <Flex w="auto">
            <Button
              variant="ghost"
              onClick={() => {
                if (onDeleteSkillClick) onDeleteSkillClick(candidateSkill?.candidateSkillID);
              }}
            >
              <DeleteIcon color="ui.error" />
            </Button>
          </Flex>
        }
        is3ColumnRow={shouldShowDeleteColumn}
      />

      {errors.candidateSkills && (
        <VStack spacing={0} mt={2} w="full" alignItems="flex-start">
          {
            // @ts-ignore
            errors.candidateSkills?.[index]?.name && (
              <Text color="text.error">
                {touched.candidateSkills?.[index]?.name &&
                  // @ts-ignore
                  errors.candidateSkills?.[index]?.name}
              </Text>
            )
          }
          {
            // @ts-ignore
            errors.candidateSkills?.[index]?.years_of_exp && (
              <Text color="text.error">
                {
                  // @ts-ignore
                  touched.candidateSkills?.[index]?.years_of_exp &&
                    // @ts-ignore
                    errors.candidateSkills[index]?.years_of_exp
                }
              </Text>
            )
          }

          <FormControl id="candidateSkills" isInvalid={typeof errors.candidateSkills === 'string'}>
            <FormErrorMessage>
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>{errors.candidateSkills}</>
            </FormErrorMessage>
          </FormControl>
        </VStack>
      )}
    </Box>
  );
}

export function SkillsForm({
  errors,
  onAddClick,
  onBlur,
  onChange,
  onDeleteSkillClick,
  onSelectSkill,
  shouldShowAddSkillsButton = true,
  shouldShowDeleteColumn,
  shouldShowYearsOfExperience = true,
  skillsOptions,
  values,
  touched,
}: {
  onBlur: (event: FocusEvent<any>) => void;
  onChange: (event: string | ChangeEvent<any>) => void;
  onAddClick?: () => void;
  onDeleteSkillClick?: (deleteID: string) => void;
  onSelectSkill: (skillName: string, index: number) => void;
  shouldShowAddSkillsButton?: boolean;
  shouldShowDeleteColumn: boolean;
  shouldShowYearsOfExperience?: boolean;
  skillsOptions: Omit<SkillsProps['skillChoices'][number], 'roles'>[];
} & Pick<
  FormikState<{
    candidateSkills: SkillsProps['skillGroups'][number]['skills'][number]['formValues'][];
  }>,
  'errors' | 'values' | 'touched'
>) {
  const [elRefs, setElRefs] = useState<React.MutableRefObject<null>[]>(
    [] as React.MutableRefObject<null>[],
  );

  // Creates and array of refs with the size of number of skills
  useEffect(() => {
    setElRefs((_elRefs) =>
      Array(values.candidateSkills.length)
        .fill(null)
        .map((_, i) => _elRefs[i] || createRef()),
    );
  }, [values.candidateSkills.length]);

  return (
    <Box w="full">
      <SkillFormLayout
        shouldShowYearsOfExperience={shouldShowYearsOfExperience}
        column1={
          <FormLabel marginInlineEnd={0} mb={0} whiteSpace="nowrap">
            Skill
          </FormLabel>
        }
        column2={
          <FormLabel marginInlineEnd={0} mb={0} whiteSpace="nowrap">
            Years of Exp.
          </FormLabel>
        }
        column3={<Box w={10} />}
        is3ColumnRow={shouldShowDeleteColumn}
      />

      <VStack spacing={[4, 4, 6]} w="full" mt={2} alignItems="flex-start">
        {values.candidateSkills.map((candidateSkill, index) => {
          return (
            <SkillFormRow
              candidateSkill={candidateSkill}
              index={index}
              errors={errors}
              onBlur={onBlur}
              onChange={onChange}
              onDeleteSkillClick={onDeleteSkillClick}
              onSelectSkill={onSelectSkill}
              skillsOptions={skillsOptions}
              shouldShowDeleteColumn={shouldShowDeleteColumn}
              shouldShowYearsOfExperience={shouldShowYearsOfExperience}
              values={values}
              touched={touched}
              inputRef={elRefs[index]}
            />
          );
        })}

        {shouldShowAddSkillsButton && (
          <Button
            iconSpacing={3}
            leftIcon={<AddIcon fontSize="md" />}
            onClick={onAddClick}
            size="sm"
            variant="ghost"
            colorScheme="accent"
          >
            Add Skill
          </Button>
        )}
      </VStack>
    </Box>
  );
}
