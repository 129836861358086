/* eslint-disable react/no-unused-prop-types */

import { Container, Flex, Box } from '@terminal/design-system';
import { PageHeader, PublicPageHeader } from './PageHeader';

export interface PageLayoutProps {
  children: React.ReactNode;
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  shouldNotWrapWithContainer?: boolean;
  shouldNotShowSectionLinks?: boolean;
  candidateAvailability: string | null;
}

function PageLayoutBase({
  children,
  header,
  shouldNotWrapWithContainer = false,
}: {
  children: React.ReactNode;
  header: React.ReactNode;
  shouldNotWrapWithContainer?: PageLayoutProps['shouldNotWrapWithContainer'];
}) {
  return (
    <Flex flexDirection="column" flex={1}>
      <Box position="fixed" w="full" zIndex="sticky" as="header" role="banner" top={0}>
        {header}
      </Box>
      <Flex
        flexDir="column"
        bg="bg.primary"
        pt={['56px', '56px', '80px']}
        as="main"
        role="main"
        flex={1}
        maxW="100vw"
      >
        {shouldNotWrapWithContainer ? (
          children
        ) : (
          <Container flex="1" py={[4, 4, 12]}>
            {children}
          </Container>
        )}
      </Flex>
    </Flex>
  );
}

function VariationWithSections({
  children,
  onSignOutClick,
  userFullName,
  shouldNotWrapWithContainer = false,
  candidateAvailability,
}: PageLayoutProps) {
  return (
    <PageLayoutBase
      shouldNotWrapWithContainer={shouldNotWrapWithContainer}
      header={
        <PageHeader.Variation.WithAllSections
          {...{ candidateAvailability, onSignOutClick, userFullName }}
        />
      }
    >
      {children}
    </PageLayoutBase>
  );
}

function VariationWithReferralOnly({
  children,
  onSignOutClick,
  userFullName,
  shouldNotWrapWithContainer = false,
}: Omit<PageLayoutProps, 'candidateAvailability'>) {
  return (
    <PageLayoutBase
      shouldNotWrapWithContainer={shouldNotWrapWithContainer}
      header={
        <PageHeader.Variation.WithReferralOnly
          {...{ onSignOutClick, userFullName }}
          candidateAvailability={null}
        />
      }
    >
      {children}
    </PageLayoutBase>
  );
}

function VariationWithNoSections({
  children,
  onSignOutClick,
  userFullName,
  shouldNotWrapWithContainer = false,
  candidateAvailability,
}: PageLayoutProps) {
  return (
    <PageLayoutBase
      shouldNotWrapWithContainer={shouldNotWrapWithContainer}
      header={
        <PageHeader.Variation.WithNoSections
          {...{ candidateAvailability, onSignOutClick, userFullName }}
        />
      }
    >
      {children}
    </PageLayoutBase>
  );
}

export function PublicPageLayout({
  children,
  headerRightPlaceholderDesktop,
  headerRightPlaceholderMobile,
}: {
  children: React.ReactNode;
  headerRightPlaceholderDesktop?: React.ReactNode;
  headerRightPlaceholderMobile?: React.ReactNode;
}) {
  return (
    <Flex flexDirection="column" flex={1}>
      <Box zIndex="sticky" position="fixed" w="full" as="header" role="banner" top={0}>
        <PublicPageHeader
          rightSidePlaceholderDesktop={headerRightPlaceholderDesktop}
          rightSidePlaceholderMobile={headerRightPlaceholderMobile}
        />
      </Box>
      <Flex
        bg="bg.primary"
        pt={['56px', '56px', '80px']}
        as="main"
        role="main"
        flex={1}
        flexDirection="column"
        maxW="100vw"
      >
        {children}
      </Flex>
    </Flex>
  );
}

export const PageLayout = {
  Variation: {
    WithAllSections: VariationWithSections,
    WithNoSections: VariationWithNoSections,
    WithReferralOnly: VariationWithReferralOnly,
  },
};
