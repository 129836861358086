import { Link as RouterLink } from 'react-router-dom';
import type { SpaceProps, LayoutProps } from '@terminal/design-system';
import { Card, CardBody } from 'candidate/components';
import { JobCardController } from 'candidate/shared/modules';
import type { JobSuggestionProps } from 'candidate/shared/modules/job/data/JobSuggestions.types';
import {
  Heading,
  Box,
  Text,
  Image,
  Skeleton,
  SkeletonText,
  Flex,
  ArrowRightIcon,
  Button,
  VStack,
  Switch,
} from '@terminal/design-system';
import hiringMarketsSrc from 'candidate/assets/images/hiring-markets.svg?url';
import type { serializeProfileCompletion } from 'candidate/shared/modules/profile/Profile.serializer';
import noMatchImageSource from '../../../../shared/modules/job/assets/no-job-matches.svg?url';

export function JobSuggestionsLoading() {
  return (
    <Box w="full">
      <VStack mt={[3, 3, 4]} spacing={4}>
        {['1st'].map((key) => (
          <Card p={6} h="auto" key={`job-suggestion-loading-${key}`}>
            <CardBody>
              <Box>
                <Skeleton mt={4} h={12} w="full" />
              </Box>
              <Box mt={4}>
                <SkeletonText noOfLines={1} />
                <SkeletonText noOfLines={1} mt={4} />
              </Box>
            </CardBody>
          </Card>
        ))}
      </VStack>
    </Box>
  );
}

export function JobSuggestions({
  jobSuggestions,
  onGoToJobApplicationClick,
  onViewAllClick,
  showIsSubscribedOn,
  showCountryNotSupported,
  onMatchAlertsChange,
  profileCompletion,
  ...styleProps
}: {
  jobSuggestions: JobSuggestionProps[];
  onGoToJobApplicationClick: (id: number) => void;
  onViewAllClick: () => void;
  showIsSubscribedOn: boolean;
  showCountryNotSupported: boolean;
  onMatchAlertsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  profileCompletion: ReturnType<typeof serializeProfileCompletion>;
} & SpaceProps &
  LayoutProps) {
  return (
    <Box w="full" bgColor="bg.secondary" p={[3, 3, 6]} {...styleProps}>
      <Flex
        flexDir={['column', 'column', 'row']}
        justifyContent={['unset', 'unset', 'space-between']}
        alignItems={['unset', 'unset', 'center']}
      >
        <Flex flexDir="column">
          <Heading variant="heading-3">Browse Jobs</Heading>
          <Text variant="body">Find the perfect job match for you.</Text>
        </Flex>
        <Flex
          mt={[4, 4, 0]}
          px={[4, 4, 0]}
          py={[2, 2, 0]}
          bgColor={['ui.disabled', 'ui.disabled', 'bg.secondary']}
          alignItems="center"
          justifyContent={['space-between', 'space-between', 'unset']}
        >
          <Text mr={3}>Get weekly alerts</Text>
          <Box mr={[0, 0, 3]}>
            <Switch
              onChange={onMatchAlertsChange}
              isChecked={showIsSubscribedOn}
              aria-label="job-alerts-toggle"
            />
          </Box>
        </Flex>
      </Flex>
      {showCountryNotSupported && (
        <Flex
          p={6}
          border="1px solid"
          borderColor="ui.inverse.secondary"
          flexDir="column"
          bgColor="bg.primary"
          alignItems="center"
          mt={3}
        >
          <Image
            h="auto"
            w="full"
            maxWidth="8.328rem"
            objectFit="cover"
            src={hiringMarketsSrc}
            alt="No job suggestion match"
          />
          <Heading mt={4} variant="heading-4">
            No Matches Yet!
          </Heading>
          <Text color="text.secondary" textAlign="center" px={20}>
            We do not have any jobs available for your location. We will let you know if we expand
            to your area
          </Text>
        </Flex>
      )}
      {!showCountryNotSupported && jobSuggestions.length === 0 && (
        <Flex
          p={6}
          border="1px solid"
          borderColor="ui.inverse.secondary"
          flexDir="column"
          bgColor="bg.primary"
          alignItems="center"
          mt={3}
        >
          <Image h="115px" maxWidth={32} src={noMatchImageSource} alt="No job suggestion match" />
          <Heading mt={5} variant="heading-4">
            No Matches Yet!
          </Heading>
          <Text color="text.secondary">
            {profileCompletion.completionPercentage === 100
              ? 'Subscribe to alerts to find out when you have new matches'
              : 'Fill your profile to get matching jobs!'}
          </Text>
        </Flex>
      )}
      {Boolean(jobSuggestions.length) && (
        <>
          {jobSuggestions.map((job) => (
            <Box bgColor="bg.primary" mt={4}>
              <JobCardController jobData={job} jobSection="suggested-jobs" />
            </Box>
          ))}
          <Button
            as={RouterLink}
            variant="ghost"
            colorScheme="accent"
            mt={4}
            rightIcon={<ArrowRightIcon color="ui.primary" fontWeight="bold" />}
            to="/job/matches"
          >
            <Text variant="label">View More Jobs</Text>
          </Button>
        </>
      )}
    </Box>
  );
}
