import { ErrorBoundary } from 'global/sentry';
import { useRedirection } from 'global/utils';
import { ErrorFallback } from 'candidate/components';
import { ReferralController } from './Referral.controller';

export function ReferralRouter() {
  const redirectTo = useRedirection();

  return (
    <ErrorBoundary
      fallback={
        <ErrorFallback
          onActionClick={() => {
            redirectTo('/referral');
            window.location.reload();
          }}
          actionTitle="Go back to Referral Page"
        />
      }
    >
      <ReferralController />
    </ErrorBoundary>
  );
}
