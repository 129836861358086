import { PublicPageLayout } from 'candidate/components';
import { SignInForm } from 'global/auth/SignIn';
import { Text, Box, Flex, Link, Image } from '@terminal/design-system';
import { Link as RouterLink } from 'react-router-dom';
import { TwoColumnAuthPageLayout } from 'candidate/app/components';
import { useIsViewPortDesktop } from 'global/utils';
import WeHaveSeenYouBeforeSVG from './assets/we-have-seen-you-before.svg?url';

export function JobApplicationLogIn({
  onGoogleLoginClicked,
  onEmailSignSubmitted,
  isLoading,
  email,
  onForgotPasswordClick,
  shouldHideGoogleSSO,
  header,
  footer,
}: {
  onGoogleLoginClicked: () => void;
  onEmailSignSubmitted: (email: string, password: string) => Promise<void>;
  isLoading: boolean;
  email?: string;
  onForgotPasswordClick: () => void;
  shouldHideGoogleSSO: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <PublicPageLayout
      headerRightPlaceholderDesktop={
        <Flex alignItems="center">
          <Text>Don’t Have An Account?</Text>
          <Link as={RouterLink} to="/onboarding" ml={2} fontWeight="bold">
            Sign Up
          </Link>
        </Flex>
      }
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link
            as={RouterLink}
            to={`/auth/login${window.location.search}`}
            ml={2}
            fontWeight="bold"
          >
            Login
          </Link>
        </Flex>
      }
    >
      <TwoColumnAuthPageLayout
        leftColumn={
          isDesktop && (
            <Flex w="full" flexDir="column" alignItems="center">
              <Image src={WeHaveSeenYouBeforeSVG} />
            </Flex>
          )
        }
        rightColumn={
          <Flex
            zIndex="docked"
            alignItems="stretch"
            w="full"
            h="full"
            mt={[6, 6, 0]}
            justifyContent={['flex-start', 'flex-start', 'center']}
            flexDir="column"
          >
            {header}
            <Box mt={6}>
              <SignInForm
                onGoogleLoginClicked={onGoogleLoginClicked}
                onForgotPasswordClick={onForgotPasswordClick}
                onEmailSignSubmitted={onEmailSignSubmitted}
                showLoadingForEmailSignIn={isLoading}
                disableGoogleSignInButton={isLoading}
                email={email}
                isEmailInputDisabled={!!email}
                shouldHideGoogleSSO={shouldHideGoogleSSO}
              />
            </Box>
            {footer}
          </Flex>
        }
      />
    </PublicPageLayout>
  );
}
