import type { LayoutProps, SpaceProps, FlexboxProps, PositionProps } from '@terminal/design-system';
import { Center } from '@terminal/design-system';

export function CenteredContainer({
  children,
  ...styleProps
}: { children: React.ReactNode } & LayoutProps & SpaceProps & FlexboxProps & PositionProps) {
  return (
    <Center flexDirection="column" w="full" px={[10, 0]} {...styleProps} minH={['85vh', '80vh']}>
      {children}
    </Center>
  );
}
