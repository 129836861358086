import type { FormikState } from 'formik';
import { LocationTypeAhead, HungaryFlagIcon } from 'global/components';
import type { LocationTypeAheadProps } from 'global/components';
import type { HStepsProps } from 'candidate/components';
import {
  CheckboxCard,
  Button,
  Box,
  Skeleton,
  FormControl,
  FormErrorMessage,
  SearchIcon,
} from '@terminal/design-system';
import type { ProfileProps } from '../../../../Profile.types';
import {
  CanadaFlagIcon,
  ChileFlagIcon,
  ColombiaFlagIcon,
  MexicoFlagIcon,
  PolandFlagIcon,
  CostaRicaFlagIcon,
  OtherFlagIcon,
  SpainFlagIcon,
  RomaniaFlagIcon,
} from '../../assets/flag-icons';
import { StepPageLayout } from '../../components/StepPageLayout';
import type { LocationFormProps } from './Location.types';
import { CheckboxCardGroup } from '../../components';

function mapCountryToFlagIcon({
  country,
  isColored,
}: {
  country: string;
  isColored: boolean;
}): React.ReactNode {
  const iconMap: { [key: string]: any } = {
    Canada: <CanadaFlagIcon isColored={isColored} />,
    Chile: <ChileFlagIcon isColored={isColored} />,
    Colombia: <ColombiaFlagIcon isColored={isColored} />,
    'Costa Rica': <CostaRicaFlagIcon isColored={isColored} />,
    Hungary: <HungaryFlagIcon isColored={isColored} />,
    Mexico: <MexicoFlagIcon isColored={isColored} />,
    Poland: <PolandFlagIcon isColored={isColored} />,
    Spain: <SpainFlagIcon isColored={isColored} />,
    Romania: <RomaniaFlagIcon isColored={isColored} />,
  };

  return iconMap[country];
}

export function AddLocationCountrySelection({
  selectedLocation,
  locationOptions,
  isNextDisabled,
  onCheckboxClick,
  onNextClick,
  onSignOutClick,
  userFullName,
  shouldShowTypeahead,
  onLocationBlur,
  onLocationChange,
  typeaheadDefault,
  placeIDToCountryIDMap,
  progressProps,
  isTypeaheadReady,
  errors,
  touched,
  title,
  description,
}: {
  selectedLocation: ProfileProps['candidate']['personalInfo']['location'];
  locationOptions: string[];
  isNextDisabled: boolean;
  onCheckboxClick: (selectedLocation: string) => void;
  onNextClick: () => void;
  onSignOutClick: () => Promise<void>;
  userFullName: string;
  shouldShowTypeahead: boolean;
  onLocationBlur: (event: React.FocusEvent<any>) => void;
  onLocationChange: LocationTypeAheadProps['onChange'];
  typeaheadDefault: string;
  placeIDToCountryIDMap: Record<string, number>;
  progressProps: HStepsProps;
  isTypeaheadReady: boolean;
  title: string;
  description: string;
} & Pick<FormikState<LocationFormProps>, 'errors' | 'touched'>) {
  return (
    <StepPageLayout
      {...{
        progressProps,
        title,
        description,
        userFullName,
        onSignOutClick,
      }}
      footer={
        <Button onClick={onNextClick} w={['full', 64]} disabled={isNextDisabled}>
          Next
        </Button>
      }
    >
      <Skeleton isLoaded={isTypeaheadReady}>
        <CheckboxCardGroup>
          {locationOptions.map((location) => (
            <CheckboxCard
              role="checkbox"
              onChange={() => onCheckboxClick(location)}
              isChecked={location === selectedLocation}
              key={`insider-location-${location}`}
              value={location}
              name="candidateLocation"
              checkboxIcon={mapCountryToFlagIcon({
                country: location,
                isColored: location === selectedLocation,
              })}
              aria-checked={location === selectedLocation}
            >
              {location}
            </CheckboxCard>
          ))}
          <CheckboxCard
            role="checkbox"
            onChange={() => onCheckboxClick('Other')}
            isChecked={shouldShowTypeahead}
            value="Other"
            name="candidateLocation"
            checkboxIcon={<OtherFlagIcon isColored={shouldShowTypeahead} />}
            aria-checked={shouldShowTypeahead}
            // Centers the checkbox card
            gridColumnEnd="3"
          >
            Other
          </CheckboxCard>
        </CheckboxCardGroup>

        {shouldShowTypeahead && (
          <Box h={12} mt={4}>
            <FormControl isInvalid={touched.country && !!errors.country}>
              <LocationTypeAhead
                defaultValue={typeaheadDefault}
                placeIDToCountryIDMap={placeIDToCountryIDMap}
                noOptionsMatchedCopy="Address not found"
                placeholder="Type your location"
                name="country"
                onBlur={onLocationBlur}
                onChange={onLocationChange}
                containerProps={{
                  bg: 'bg.primary',
                }}
              />
              <FormErrorMessage>{errors.country}</FormErrorMessage>
            </FormControl>
          </Box>
        )}
      </Skeleton>
    </StepPageLayout>
  );
}

export function AddLocationCitySelection({
  isNextDisabled,
  onNextClick,
  onSignOutClick,
  userFullName,
  onLocationBlur,
  onLocationChange,
  typeaheadDefault,
  placeIDToCountryIDMap,
  progressProps,
  isTypeaheadReady,
  errors,
  touched,
  title,
  description,
}: React.ComponentProps<typeof AddLocationCountrySelection>) {
  return (
    <StepPageLayout
      progressProps={progressProps}
      title={title}
      description={description}
      userFullName={userFullName}
      onSignOutClick={onSignOutClick}
      footer={
        <Button onClick={onNextClick} w={['full', 64]} disabled={isNextDisabled}>
          Next
        </Button>
      }
    >
      <Skeleton isLoaded={isTypeaheadReady}>
        <Box h={12} mt={4}>
          <FormControl isInvalid={touched.country && !!errors.country}>
            <Box position="relative">
              <LocationTypeAhead
                defaultValue={typeaheadDefault}
                placeIDToCountryIDMap={placeIDToCountryIDMap}
                noOptionsMatchedCopy="Address not found"
                placeholder="Type your location"
                name="country"
                onBlur={onLocationBlur}
                onChange={onLocationChange}
                containerProps={{
                  bg: 'bg.primary',
                }}
              />
              <SearchIcon
                position="absolute"
                w={4}
                h={4}
                top={4}
                right={4}
                pointerEvents="none"
                zIndex="dropdown"
              />
            </Box>
            <FormErrorMessage>{errors.country}</FormErrorMessage>
          </FormControl>
        </Box>
      </Skeleton>
    </StepPageLayout>
  );
}

export const AddLocationStep = {
  Variation: {
    CheckboxCountrySelection: AddLocationCountrySelection,
    InputCitySelection: AddLocationCitySelection,
  },
};
