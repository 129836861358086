import { PublicPageLayout } from 'candidate/components';
import { Heading, Button, Flex, Image, Text, Divider, Link } from '@terminal/design-system';
import Logo from 'global/images/logo-bug-white.png';
import type { ComponentProps, MouseEventHandler } from 'react';
import { useIsViewPortDesktop } from 'global/utils';
import { Link as RouterLink } from 'react-router-dom';
import ArrowRight from '../../assets/arrow-right.svg?url';
import { PersonalInfoController } from './PersonalInfo.controller';
import { ResumeAndExperienceController } from './ResumeAndExperience.controller';
import Location from '../../assets/location.svg?url';
import { Section } from '../../DirectApplication.types';
import { GetMatchedToMoreRolesController } from './GetMatchedToMoreRoles.controller';

export function ApplicationSections({
  activeSection,
  companyName,
  jobLocation,
  onAccountExistsClick,
  onSectionBoxEditClick,
  personalInfoOnComplete,
  position,
  skillsOptions,
  sectionSubmitButtonIsLoading,
  completedSections,
  resumeAndExperienceOnComplete,
  getMatchedToMoreRolesOnComplete,
  yearsOfExperienceRangeChoices,
}: {
  skillsOptions: {
    id: number;
    name: string;
  }[];
  companyName: string;
  jobLocation: string;
  sectionSubmitButtonIsLoading: boolean;
  onAccountExistsClick: () => void;
  position: string;
  activeSection: Section;
  onSectionBoxEditClick: (index: number) => MouseEventHandler<HTMLButtonElement>;
  personalInfoOnComplete: ComponentProps<typeof PersonalInfoController>['onComplete'];
  getMatchedToMoreRolesOnComplete: ComponentProps<
    typeof GetMatchedToMoreRolesController
  >['onComplete'];
  resumeAndExperienceOnComplete: ComponentProps<typeof ResumeAndExperienceController>['onComplete'];
  yearsOfExperienceRangeChoices: ComponentProps<
    typeof ResumeAndExperienceController
  >['yearsOfExperienceRangeChoices'];
  completedSections: Record<Section, boolean>;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <PublicPageLayout
      headerRightPlaceholderDesktop={
        <Flex alignItems="center">
          <Text>Don’t Have An Account?</Text>
          <Link as={RouterLink} to="/onboarding" ml={2} fontWeight="bold">
            Sign Up
          </Link>
        </Flex>
      }
      headerRightPlaceholderMobile={
        <Flex alignItems="center">
          <Link
            as={RouterLink}
            to={`/auth/login${window.location.search}`}
            ml={2}
            fontWeight="bold"
          >
            Login
          </Link>
        </Flex>
      }
    >
      <Flex w="full" bg="bg.primary" direction="column" alignItems="center" pt={[4, 4, 12]}>
        <Heading variant={isDesktop ? 'heading-3' : 'heading-4'} color="accent.main">
          {companyName}
        </Heading>
        <Heading mt="2" variant={isDesktop ? 'heading-2' : 'heading-3'}>
          {position}
        </Heading>
        <Flex mt="2" flexDir="row" alignItems="center" justifyContent="center">
          <Image display="inline" src={Location} alt="" role="presentation" marginRight="3" />
          <Text variant="body" width={64}>
            {jobLocation}
          </Text>
        </Flex>
      </Flex>
      <Flex flex="1" justifyContent="center" pb="12">
        <Flex alignItems="center" direction="column" w={['full', 'ml']} mx={[1, 0]}>
          <Button
            variant="ghost"
            w="full"
            height={isDesktop ? 21 : 20}
            mt={isDesktop ? 14 : 6}
            onClick={onAccountExistsClick}
            backgroundColor="accent.main"
            borderRadius="base"
            color="text.inverse"
            leftIcon={<Image h="12" display="inline" src={Logo} alt="" role="presentation" />}
            rightIcon={<Image display="inline" src={ArrowRight} alt="" role="presentation" />}
            _hover={{
              background: 'accent.lighter',
            }}
          >
            {isDesktop ? (
              <Heading variant="heading-3" color="text.inverse">
                Apply With Your Terminal Account
              </Heading>
            ) : (
              <Text variant="body" fontWeight="bold" color="text.inverse" width={64}>
                Apply With Your Terminal Account
              </Text>
            )}
          </Button>
          <Flex flexDir="row" alignItems="center" mt="6" justifyContent="center" width="full">
            <Divider orientation="horizontal" flex="1" />
            <Text variant="hint" color="text.disabled" px="2" fontWeight="bold">
              OR CONTINUE BELOW TO APPLY MANUALLY
            </Text>
            <Divider orientation="horizontal" flex="1" />
          </Flex>
          <PersonalInfoController
            isCompleted={completedSections[Section.personalInformation]}
            isOpened={activeSection === Section.personalInformation}
            onSectionBoxEditClick={onSectionBoxEditClick}
            onComplete={personalInfoOnComplete}
            sectionSubmitButtonIsLoading={sectionSubmitButtonIsLoading}
          />
          <ResumeAndExperienceController
            isCompleted={completedSections[Section.experience]}
            isOpened={activeSection === Section.experience}
            onComplete={resumeAndExperienceOnComplete}
            onSectionBoxEditClick={onSectionBoxEditClick}
            sectionSubmitButtonIsLoading={sectionSubmitButtonIsLoading}
            skillChoices={skillsOptions}
            yearsOfExperienceRangeChoices={yearsOfExperienceRangeChoices}
          />
          <GetMatchedToMoreRolesController
            isCompleted={completedSections[Section.accountCreation]}
            isOpened={activeSection === Section.accountCreation}
            onComplete={getMatchedToMoreRolesOnComplete}
            sectionSubmitButtonIsLoading={sectionSubmitButtonIsLoading}
            onSectionBoxEditClick={onSectionBoxEditClick}
          />
        </Flex>
      </Flex>
    </PublicPageLayout>
  );
}
