import { useEffect, useReducer, useRef } from 'react';
import type { Reducer } from 'react';
import { asyncReducer, usePrevious } from 'global/utils';

type ApiStatus = 'idle' | 'ready' | 'failed';
type PickerAsyncState<T> = AsyncState<T> & { apiStatus: ApiStatus; isOpen: boolean };
type PickerAsyncAction<T> =
  | AsyncAction<T>
  | { type: 'initialized'; data?: T }
  | { type: 'failed-to-initialize'; data?: T }
  | { type: 'is-open'; data?: T; payload: { isOpen: boolean } };

const initialState = {
  /**
   * Async status to handle file selection
   */
  status: 'idle' as AsyncStatus,
  /**
   * Async status to handle API initialization
   */
  apiStatus: 'idle' as ApiStatus,
  data: null,
  error: null,
  attemptsCount: 0,
  isOpen: false,
};

function asyncReducer_withIsReady<T>(
  state: PickerAsyncState<T>,
  action: PickerAsyncAction<T>,
): PickerAsyncState<T> {
  switch (action.type) {
    case 'initialized': {
      return {
        ...state,
        apiStatus: 'ready',
      };
    }
    case 'failed-to-initialize': {
      return {
        ...state,
        apiStatus: 'failed',
      };
    }
    case 'is-open': {
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    }
    default: {
      return {
        ...state,
        ...asyncReducer<T>(state, action),
      };
    }
  }
}

/**
 * Checks if the google api is already in place
 * @returns {boolean} isReady
 */
const isGoogleReady = () => !!window.gapi && !!window.google?.accounts?.oauth2;

/**
 * Loads the client, auth and picker-view api and when the client api is ready loads the drive api
 */
const loadDriveAndPickerAPIs = ({
  onLoad = () => {},
  onError = () => {},
}: {
  onLoad?: () => void;
  onError?: () => void;
}) => {
  window.gapi.load('client:picker', () => {
    window.gapi.client
      .load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest')
      .then(onLoad)
      .catch(onError);
  });
};

function attachDriveScripts({
  onAPILoaded,
  onGSILoaded,
}: {
  onAPILoaded?: () => void;
  onGSILoaded?: () => void;
}) {
  const head = document.head || document.getElementsByTagName('head')[0];
  const apiScript = document.createElement('script');
  const gsiScript = document.createElement('script');

  apiScript.src = 'https://apis.google.com/js/api.js';
  gsiScript.src = 'https://accounts.google.com/gsi/client';
  [apiScript, gsiScript].forEach((script) => {
    // eslint-disable-next-line no-param-reassign
    script.type = 'text/javascript';
    // eslint-disable-next-line no-param-reassign
    script.async = true;
    // eslint-disable-next-line no-param-reassign
    script.onload = () => {
      switch (script.src) {
        case apiScript.src:
          onAPILoaded?.();
          break;
        case gsiScript.src:
          onGSILoaded?.();
          break;

        default:
          break;
      }
    };

    head.appendChild(script);
  });
}

// Hook to attach in advance the Google Drive Scripts in preparation to display the CTA as soon as possible to improve UX
export function useAttachDriveScripts() {
  useEffect(() => {
    attachDriveScripts({
      onAPILoaded: () => {
        loadDriveAndPickerAPIs({});
      },
    });
  }, []);
}

/**
 * Handles the picker action 'cancel' | 'picked'
 */
async function handlePickerAction(
  // @ts-ignore // TODO: [TP-1919] Fix this type
  data: typeof window.google.picker.ResponseObject,
  // @ts-ignore // TODO: [TP-1919] Fix this type
  picker: typeof window.google.picker.Picker,
  dispatch?: (action: PickerAsyncAction<File>) => void,
) {
  try {
    if (data.action === window.google.picker.Action.PICKED) {
      dispatch?.({ type: 'is-open', payload: { isOpen: false } });
      const document = data[window.google.picker.Response.DOCUMENTS][0];
      const {
        [window.google.picker.Document.ID]: fileId,
        [window.google.picker.Document.NAME]: fileName,
        [window.google.picker.Document.MIME_TYPE]: fileMimeType,
      } = document || {};

      if (!fileId) return;

      dispatch?.({ type: 'pending' });
      const { body } =
        // if is a google document must use export instead of get
        fileMimeType === 'application/vnd.google-apps.document'
          ? await window.gapi.client.drive.files.export({
              fileId,
              alt: 'media',
              mimeType: 'application/pdf',
            })
          : await window.gapi.client.drive.files.get({
              fileId,
              alt: 'media',
            });
      /**
       * The api is giving us a string which can be parsed to an actual file using the fetch api
       */
      const base64str = btoa(body);
      const fileBlob = await fetch(`data:plain/text;base64,${base64str}`).then((res) => res.blob());
      const file = new File([fileBlob], fileName || 'resume', {
        type:
          fileMimeType === 'application/vnd.google-apps.document'
            ? 'application/pdf'
            : fileMimeType,
      });
      dispatch?.({ type: 'resolved', data: file });
    }

    if (data.action === window.google.picker.Action.CANCEL) {
      dispatch?.({ type: 'is-open', payload: { isOpen: false } });
    }
  } catch (error) {
    dispatch?.({ type: 'rejected', error });
    dispatch?.({ type: 'is-open', payload: { isOpen: false } });
    picker.setVisible(false);
  }
}

const allowedMimeTypes = [
  'text/plain', // .txt
  'application/pdf', // .pdf
  'application/msword', // .doc
  'application/vnd.google-apps.document', // google document
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx,
].join(',');

/**
 * Creates the picker view using the user token
 */
const createPicker = (oauthToken: string, dispatch?: (action: PickerAsyncAction<File>) => void) => {
  const view = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS);
  view.setMimeTypes(allowedMimeTypes).setEnableDrives(false).setOwnedByMe(true);

  const picker = new window.google.picker.PickerBuilder()
    .addView(view)
    .setOAuthToken(oauthToken)
    .setDeveloperKey(import.meta.env.REACT_APP_FIREBASE_API_KEY as string)
    .build();
  // TODO: [TP-1472] Fix type
  picker.setCallback((data: any) => handlePickerAction(data, picker, dispatch));
  picker.setVisible(true);

  dispatch?.({ type: 'is-open', payload: { isOpen: true } });
};

export function useGoogleDrivePicker({
  onFilePicked,
  onError,
}: {
  onFilePicked?: (file: File) => void;
  onError?: (error: any) => void;
}): {
  /**
   * Wether the Picker View is open or not
   */
  isOpen: boolean;
  /**
   * Indicates that a file was selected and it is been processed
   */
  isLoading: boolean;
  /**
   * Wether the API is ready to be used or not
   */
  isReady: boolean;
  /**
   * The click event used to trigger the Google Picker View
   * Goes attached to the CTA that opens the modal for selecting a file
   */
  onClick: () => void;
} {
  const [state, dispatch] = useReducer<Reducer<PickerAsyncState<File>, PickerAsyncAction<File>>>(
    asyncReducer_withIsReady,
    initialState,
  );
  // @ts-ignore // TODO: [TP-1919] Fix this type
  const tokenClientRef = useRef<typeof window.google.accounts.oauth2.TokenClient>();
  const prevState = usePrevious(state);

  useEffect(() => {
    /**
     * If the google api is available it tries to set the other apis needed client, picker, auth and drive
     * Otherwise attach a script to the head of the page to load the google api
     */
    if (isGoogleReady()) {
      loadDriveAndPickerAPIs({
        onLoad: () => dispatch({ type: 'initialized' }),
        onError: () => dispatch({ type: 'failed-to-initialize' }),
      });
    } else {
      attachDriveScripts({
        onAPILoaded: () => {
          loadDriveAndPickerAPIs({
            onLoad: () => dispatch({ type: 'initialized' }),
            onError: () => dispatch({ type: 'failed-to-initialize' }),
          });
        },
      });
    }
  }, []);

  useEffect(() => {
    if (prevState?.status === 'pending' && state.status === 'resolved' && state.data) {
      onFilePicked?.(state.data);
    }

    if (prevState?.status === 'pending' && state.status === 'rejected' && state.error) {
      onError?.(state.error);
    }
  }, [state.data, state.error, state.status, prevState, onFilePicked, onError]);

  const isReady = state.apiStatus === 'ready';

  return {
    isOpen: state.isOpen,
    isLoading: state.status === 'pending',
    isReady,
    onClick: () => {
      if (!isReady) return;
      if (!tokenClientRef.current) {
        tokenClientRef.current = window.google.accounts.oauth2.initTokenClient({
          client_id: import.meta.env.REACT_APP_GOOGLE_CLIENT_ID as string,
          scope: 'https://www.googleapis.com/auth/drive.readonly',
          prompt: 'select_account',
          // TODO: [TP-1472] Fix type
          callback: (response: any) => {
            if (response.access_token) {
              createPicker(response.access_token, dispatch);
            } else if (response.error) {
              onError?.(response.error);
            }
          },
        });
      }

      tokenClientRef.current?.requestAccessToken?.();
    },
  };
}
