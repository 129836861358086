import { useCandidateAuth } from 'candidate/utils';
import { useEffect } from 'react';
import { useQuery } from 'global/utils';
import moment from 'moment';
import { Terminal_Celery_Task_Status_Choices_Enum } from 'global/types';
import type {
  SelectResumeParsingStatusQuery,
  SelectResumeParsingStatusQueryVariables,
} from './data';
import { SelectResumeParsingStatus } from './data';

export function useCheckResumeStatus({
  should_skip,
  max_wait_minutes,
  retry_interval_seconds,
}: {
  should_skip: boolean;
  max_wait_minutes: number;
  retry_interval_seconds: number;
}) {
  const { candidateID } = useCandidateAuth();

  const {
    loading: is_loading_initial_data,
    data: data_selectResumeParsingStatus,
    refetch: refetch_selectResumeParsingStatus,
  } = useQuery<SelectResumeParsingStatusQuery, SelectResumeParsingStatusQueryVariables>(
    SelectResumeParsingStatus,
    {
      context: {
        role: 'candidate',
      },
      variables: {
        candidate_id: candidateID as number,
      },
      fetchPolicy: 'network-only',
      skip: should_skip,
    },
  );

  const resume_parsing_status = data_selectResumeParsingStatus?.candidate[0]?.parsed_resume?.status;
  const resume_parsing_updated_at: string | undefined =
    data_selectResumeParsingStatus?.candidate[0]?.parsed_resume?.updated_at;

  const is_resume_parsing_staled_for_too_long =
    resume_parsing_updated_at &&
    moment(resume_parsing_updated_at).isBefore(moment().subtract(max_wait_minutes, 'minutes'));

  const is_waiting_for_resume_parsing =
    resume_parsing_status &&
    [
      Terminal_Celery_Task_Status_Choices_Enum.Pending,
      Terminal_Celery_Task_Status_Choices_Enum.Started,
    ].includes(resume_parsing_status) &&
    !is_resume_parsing_staled_for_too_long &&
    !should_skip;

  const is_resume_parsing_failed =
    resume_parsing_status === Terminal_Celery_Task_Status_Choices_Enum.Failure &&
    !is_resume_parsing_staled_for_too_long &&
    !should_skip;

  useEffect(() => {
    // Return early without refetching when we shouldn't wait for resume parsing
    if (!is_waiting_for_resume_parsing) {
      return () => null;
    }

    const interval = setInterval(() => {
      refetch_selectResumeParsingStatus();
    }, retry_interval_seconds * 1000);

    // After refetching with the right conditions, clear the interval
    if (!is_waiting_for_resume_parsing) {
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Clear the interval when the component unmounts
  }, [
    refetch_selectResumeParsingStatus,
    is_waiting_for_resume_parsing,
    resume_parsing_status,
    retry_interval_seconds,
  ]);

  return {
    /**
     * The loading state of the query that checks the resume parsing status
     */
    is_loading_initial_data,
    /**
     * Resume parsing is either Pending or Started and the user had landed from one of the expected resume parsing flows and too long
     * has not passed and skipped prop is negative
     */
    is_waiting_for_resume_parsing,
    /**
     * Resume parsing failed and the user had landed from one of the expected resume parsing flows and too long has not passed since
     * resume parsing started and skipped prop is negative
     */
    is_resume_parsing_failed,
  };
}
