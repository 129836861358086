import { ErrorBoundary } from 'global/sentry';
import { useRedirection } from 'global/utils';
import { MultiMessageLoading } from 'global/components/MultiMessageLoading';
import { ErrorFallback } from 'candidate/components';
import { BlankScreenLoading } from 'global/components';
import { useCheckResumeStatus } from 'candidate/shared/modules/resume';
import { appState } from 'candidate/utils';
import { useReactiveVar } from '@apollo/client';
import { HomePageController } from './HomePage.controller';

export function HomePageRouter() {
  const redirectTo = useRedirection();

  const appFlow = useReactiveVar(appState.flow.stateVar);

  const is_landing_from_target_experience_flows =
    appFlow['landing-first-time-from-completed-onboarding-flow'] === 'resume-parsing' ||
    ['create-account', 'claim-account'].includes(
      appFlow['landing-from-direct-application'] || '',
    ) ||
    appFlow['is-completed-onboarding-session'] ||
    appFlow['is-signing-up-in-direct-application'];

  const { is_loading_initial_data, is_waiting_for_resume_parsing, is_resume_parsing_failed } =
    useCheckResumeStatus({
      should_skip: !is_landing_from_target_experience_flows,
      max_wait_minutes: 3,
      retry_interval_seconds: 2,
    });

  if (is_loading_initial_data) {
    return <BlankScreenLoading pos="fixed" top={0} left={0} aria-label="loading" />;
  }

  if (is_waiting_for_resume_parsing) {
    return (
      <MultiMessageLoading
        title="Creating your profile and matching you to open roles..."
        steps={[
          { title: 'Processing your resume', duration: 9 },
          { title: 'Analyzing your work experience', duration: 8 },
          { title: 'Reviewing your skills', duration: 5 },
          { title: 'Finalizing your profile', duration: 300 },
        ]}
      />
    );
  }

  return (
    <ErrorBoundary
      fallback={
        <ErrorFallback
          onActionClick={() => {
            redirectTo('/');
            window.location.reload();
          }}
          actionTitle="Go back to Home Page"
        />
      }
    >
      <HomePageController is_resume_parsing_failed={is_resume_parsing_failed} />
    </ErrorBoundary>
  );
}
