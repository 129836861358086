import { availabilityCTAsDictionary } from 'candidate/shared';
import type { SelectProfileQuery, ProfileProps } from '../../Profile.types';

export function serializeForPersonalInfo(
  candidate: SelectProfileQuery['candidates'][number],
): ProfileProps['candidate']['personalInfo'] {
  return {
    fullName: [candidate.first_name, candidate.last_name].filter(Boolean).join(' ') || null,
    email: candidate.email,
    location: [candidate.city, candidate.country_choice?.name].filter(Boolean).join(', ') || null,
    phone: candidate.phone || '-',
    // @ts-ignore
    availability: candidate.availability
      ? availabilityCTAsDictionary[candidate.availability].short
      : null,
    formValues: {
      firstName: candidate.first_name || '',
      lastName: candidate.last_name || '',
      email: candidate.email,
      formattedAddress:
        [candidate.city, candidate.state, candidate.country_choice?.name]
          .filter(Boolean)
          .join(', ') || '',
      city: candidate.city || '',
      country: candidate.country_choice?.name || '',
      country_id: candidate.country_choice?.id || null,
      state: candidate.state || '',
      latitude: candidate.latitude || '',
      longitude: candidate.longitude || '',
      availability: candidate.availability || null,
      phone: candidate.phone || '',
    },
  };
}
