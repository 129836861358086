import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTopOnPathChange({
  scrollAnimationBehavior = 'auto',
}: {
  scrollAnimationBehavior?: ScrollBehavior;
}) {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: scrollAnimationBehavior });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
}
