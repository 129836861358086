import { useMutation } from '@apollo/client';
import Sentry from 'global/sentry';
import { useToast } from '@terminal/design-system';
import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { SelectProfile } from '../../graphql';
import type { SelectProfileQuery } from '../../graphql/types';
import type { ProfileProps } from '../../Profile.types';
import { trackProfile } from '../../Profile.utils';
import {
  DeleteCandidateWorkHistory,
  UpsertCandidateWorkHistory,
  serializerForWorkExperienceUpsert,
} from './data';
import type {
  DeleteCandidateWorkHistoryMutation,
  DeleteCandidateWorkHistoryMutationVariables,
  UpsertCandidateWorkHistoryMutation,
  UpsertCandidateWorkHistoryMutationVariables,
} from './data';
import { workExperiences_validationSchema } from './WorkExperiences.validation';

export function useWorkExperiencesController({
  workExperiences,
  userID,
  candidateID,
  onModalClose,
  onModalOpen,
}: {
  workExperiences: ProfileProps['candidate']['workExperiences'];
  candidateID: number;
  userID?: number;
  onModalClose: () => void;
  onModalOpen: () => void;
}) {
  const initialRef = useRef(null);
  const [editID, setEditID] = useState<number | null>(null);

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const addInitialValues = {
    job_title: '',
    company_name: '',
    location: '',
    startDateYear: null,
    startDateMonth: null,
    endDateYear: null,
    endDateMonth: null,
    currently_working: false,
    description: '',
  };

  const [initialValues, setInitialValues] =
    useState<ProfileProps['candidate']['workExperiences'][number]['formValues']>(addInitialValues);

  const resetEditForm = () => {
    setInitialValues(addInitialValues);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.resetForm();
    setEditID(null);
  };

  const [upsert, { loading: isLoading_upsert }] = useMutation<
    UpsertCandidateWorkHistoryMutation,
    UpsertCandidateWorkHistoryMutationVariables
  >(UpsertCandidateWorkHistory, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to update your work histories. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
    onCompleted: () => {
      onModalClose();
      resetEditForm();
      if (workExperiences.length) {
        trackProfile('entry-edited', { section: 'Work' });
      } else {
        trackProfile('entry-added', { section: 'Work' });
      }
    },
    update(cache, { data: upsertWorkExperienceData }) {
      try {
        const readData = cache.readQuery<SelectProfileQuery>({
          query: SelectProfile,
          variables: {
            user_id: userID as number,
          },
        });

        const currentCandidateWorkExperiences =
          readData?.candidates[0]?.candidate_work_experiences || [];

        if (
          currentCandidateWorkExperiences?.some(
            (workExperience) =>
              workExperience.id ===
              upsertWorkExperienceData?.insert_candidate_work_experience_one?.id,
          )
        ) {
          // when item already exist in cache, we can allow apollo to automatically update its cache
          return;
        }

        const newCandidate = {
          ...readData?.candidates[0],
          candidate_work_experiences: [
            ...currentCandidateWorkExperiences,
            upsertWorkExperienceData?.insert_candidate_work_experience_one,
          ],
        };

        cache.writeQuery({
          query: SelectProfile,
          broadcast: true,
          variables: {
            user_id: userID as number,
          },
          data: { ...readData, candidates: [newCandidate] },
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  const formik = useFormik<ProfileProps['candidate']['workExperiences'][number]['formValues']>({
    initialValues,
    validationSchema: workExperiences_validationSchema,
    onSubmit: (values) => {
      upsert({
        variables: serializerForWorkExperienceUpsert({
          values,
          candidateID,
          workExperienceID: editID,
        }),
      });
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const [deleteWorkExperience, { loading: isLoading_delete }] = useMutation<
    DeleteCandidateWorkHistoryMutation,
    DeleteCandidateWorkHistoryMutationVariables
  >(DeleteCandidateWorkHistory, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to update your work histories. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
    onCompleted: () => {
      onModalClose();
      resetEditForm();
      trackProfile('entry-deleted', { section: 'Work' });
    },
    update(cache, { data: delete_candidate_work_experience_by_pk }) {
      try {
        const readData = cache.readQuery<SelectProfileQuery>({
          query: SelectProfile,
          variables: {
            user_id: userID as number,
          },
        });

        const currentCandidateWorkExperiences =
          readData?.candidates[0]?.candidate_work_experiences || [];

        const newCandidate = {
          ...readData?.candidates[0],
          candidate_work_experiences: currentCandidateWorkExperiences.filter(
            (workExperience) =>
              workExperience.id !==
              delete_candidate_work_experience_by_pk?.delete_candidate_work_experience_by_pk?.id,
          ),
        };

        cache.writeQuery({
          query: SelectProfile,
          broadcast: true,
          variables: {
            user_id: userID as number,
          },
          data: { ...readData, candidates: [newCandidate] },
        });
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  const hasFormValuesChanged = () =>
    JSON.stringify(formik.initialValues) !== JSON.stringify(formik.values);

  const handleOnClose = () => {
    formik.resetForm();
    onModalClose();
    resetEditForm();
  };

  const handleOnSaveClick = () => {
    if (hasFormValuesChanged()) {
      formik.handleSubmit();
    } else {
      onModalClose();
      resetEditForm();
    }
  };

  const handleAddClick = () => {
    resetEditForm();
    onModalOpen();
    if (!workExperiences.length) {
      trackProfile('clicked-add-entry', { section: 'Work', context: 'Card' });
    }
    trackProfile('edit-modal-opened', { section: 'Work' });
  };

  const handleEditClick = (
    editInitialValues: ProfileProps['candidate']['workExperiences'][number]['formValues'],
    id: number,
  ) => {
    setEditID(id);
    setInitialValues(editInitialValues);
    onModalOpen();
    trackProfile('edit-modal-opened', { section: 'Work' });
  };

  return {
    deleteWorkExperience,
    editID,
    formik,
    handleAddClick,
    handleEditClick,
    handleOnClose,
    handleOnSaveClick,
    hasFormValuesChanged,
    initialRef,
    isLoading_delete,
    isLoading_upsert,
  };
}
