import { Grid, CheckboxGroup } from '@terminal/design-system';
import React from 'react';

export function CheckboxCardGroup({
  children,
  spacing = 4,
  gap = 4,
  templateColumns = 'repeat(auto-fill, minmax(142px, 1fr))',
  ...gridProps
}: { children: React.ReactNode } & React.ComponentProps<typeof Grid>) {
  return (
    <CheckboxGroup>
      <Grid
        spacing={spacing}
        justify="center"
        templateColumns={templateColumns}
        gap={gap}
        {...gridProps}
      >
        {children}
      </Grid>
    </CheckboxGroup>
  );
}
