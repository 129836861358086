import { Icon } from '@terminal/design-system';

export function BackendIcon({ isColored }: { isColored: boolean }) {
  if (isColored) {
    return (
      <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
        <path
          d="M3.93336 9C2.86561 9 2.00003 8.79336 2.00003 8.53846V3.49692C2.00357 3.22283 2.93344 3.00084 4.08159 3H28.9288C30.0712 3.00084 30.9965 3.22173 31 3.49446V8.50308C30.9965 8.77717 30.0666 8.99916 28.9185 9H3.93336Z"
          clipRule="evenodd"
          fill="#EFD58B"
          fillRule="evenodd"
        />
        <path
          d="m3 29c-1.1046 0-2-0.6888-2-1.5385v-16.805c0.00366-0.91363 0.9656-1.6536 2.1533-1.6564h25.704c1.1818 0.00282 2.139 0.7391 2.1426 1.6482v16.695c-0.0036 0.9136-0.9656 1.6536-2.1533 1.6564h-25.847z"
          clipRule="evenodd"
          fill="#A0CFDA"
          fillRule="evenodd"
        />
        <path
          d="m3.3333 28.667c-1.1046 0-2-0.8955-2-2v-21.847c0.00366-1.1877 0.9656-2.1497 2.1533-2.1533h25.704c1.1818 0.00366 2.139 0.96082 2.1426 2.1427v21.704c-0.0036 1.1878-0.9656 2.1497-2.1533 2.1534h-25.847z"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m31.333 8.6667h-30"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m22.333 14.667 4 4-4 4"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m10.333 14.667-4 4 4 4"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m18.333 14.667-4 8"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </Icon>
    );
  }

  return (
    <Icon viewBox="0 0 32 32" fill="none" boxSize={8}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3.333 28.667c-1.104 0-2-.896-2-2V4.82c.004-1.188.966-2.15 2.154-2.153H29.19c1.181.003 2.139.96 2.142 2.142v21.704c-.003 1.188-.965 2.15-2.153 2.154H3.333z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M31.333 8.667h-30M22.333 14.667l4 4-4 4M10.333 14.667l-4 4 4 4M18.333 14.667l-4 8"
      />
    </Icon>
  );
}
