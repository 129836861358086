import { createExperimentProvider } from 'global/utils/experiment';

export type Experiment = {
  signUp:
    | 'beginning-solo'
    | 'beginning-pre-sign-up'
    | 'end-solo'
    | 'end-pre-sign-up'
    | 'end-blurred-info';
  role:
    | 'role-selection'
    | 'role-selection-with-banner'
    | 'role-selection-with-banner-statistic'
    | 'role-selection-with-banner-partner-promise'
    | 'select-job-and-role'
    | 'select-job-and-role-with-banner';
  onboardingLocation: 'checkbox-country-selection' | 'input-city-selection';
};

const defaultExperiment: Experiment = {
  signUp: 'end-solo',
  role: 'role-selection',
  onboardingLocation: 'checkbox-country-selection',
};

export const { ExperimentProvider, useExperiment, triggerExperiments, triggerGoal } =
  createExperimentProvider<Experiment>({
    defaultValue: defaultExperiment,
  });
