import { useMutation } from '@apollo/client';
import Sentry from 'global/sentry';
import { useToast } from '@terminal/design-system';
import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Candidate_Curation_Desired_Salary_Frequency_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { SelectProfile, UpsertCandidateCurationDetail } from '../../graphql';
import type {
  SelectProfileQuery,
  UpsertCandidateCurationDetailMutation,
  UpsertCandidateCurationDetailMutationVariables,
} from '../../graphql/types';
import type { DesiredSalaryInformation, ProfileProps } from '../../Profile.types';
import { trackProfile } from '../../Profile.utils';
import { createDesiredSalaryYupValidation } from './DesiredSalaryForm.validation';

export function useDesiredSalaryController({
  desiredSalaryData,
  userID,
  candidateID,
  onModalClose,
  onModalOpen,
  onSaveClick,
  fieldsAreRequired,
}: {
  desiredSalaryData: DesiredSalaryInformation;
  candidateID: number;
  userID?: number;
  onModalClose?: () => void;
  onModalOpen?: () => void;
  onSaveClick: () => void;
  fieldsAreRequired: boolean;
}) {
  const initialRef = useRef(null);

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const addInitialValues: ProfileProps['candidate']['desiredSalary']['formValues'] = {
    desiredSalaryAmount: null,
    desiredSalaryCurrency: null,
  };

  const [initialValues, setInitialValues] =
    useState<ProfileProps['candidate']['desiredSalary']['formValues']>(addInitialValues);

  const resetEditForm = () => {
    setInitialValues(initialValues);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.resetForm();
  };

  const [upsert, { loading: isLoading_upsert }] = useMutation<
    UpsertCandidateCurationDetailMutation,
    UpsertCandidateCurationDetailMutationVariables
  >(UpsertCandidateCurationDetail, {
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to set your desired salary. Please try again!',
        status: 'error',
      });
      Sentry.captureException(error);
    },
    onCompleted: () => {
      onSaveClick();
    },
    update(cache, { data: upsert_candidate_curation_data }) {
      try {
        const readData = cache.readQuery<SelectProfileQuery>({
          query: SelectProfile,
          variables: {
            user_id: userID as number,
          },
        });

        if (!readData) {
          throw new Error('Cache could not be read');
        }

        const desiredSalaryAmount =
          upsert_candidate_curation_data?.insert_candidate_curation_detail?.returning[0]
            ?.desired_salary_amount || null;
        const desiredSalaryCurrency =
          upsert_candidate_curation_data?.insert_candidate_curation_detail?.returning[0]
            ?.desired_salary_currency || null;

        const candidate = readData.candidates[0];

        if (candidate) {
          const newCandidate: SelectProfileQuery['candidates'][number] = {
            ...candidate,
            candidate_curation_detail: {
              ...(candidate.candidate_curation_detail as NonNullable<
                SelectProfileQuery['candidates'][number]['candidate_curation_detail']
              >),
              desired_salary_amount: desiredSalaryAmount,
              desired_salary_currency: desiredSalaryCurrency,
            },
          };

          cache.writeQuery({
            query: SelectProfile,
            broadcast: true,
            variables: {
              user_id: userID as number,
            },
            data: { ...readData, candidates: [newCandidate] },
          });
        }
      } catch (error) {
        cache.evict({
          fieldName: 'candidate',
          broadcast: true,
        });
        Sentry.captureException(error);
      }
    },
  });

  const formik = useFormik<DesiredSalaryInformation>({
    initialValues: desiredSalaryData,
    validationSchema: createDesiredSalaryYupValidation(!!fieldsAreRequired),
    onSubmit: ({ desiredSalaryAmount, desiredSalaryCurrency }) => {
      upsert({
        variables: {
          upsert_objects: [
            {
              candidate_id: candidateID,
              desired_salary_amount:
                desiredSalaryAmount && desiredSalaryAmount > 0 ? desiredSalaryAmount : null,
              // @ts-ignore --> to avoid a database error where an empty string was submitted
              desired_salary_currency: desiredSalaryCurrency !== '' ? desiredSalaryCurrency : null,
              desired_salary_frequency:
                Candidate_Curation_Desired_Salary_Frequency_Choices_Enum.Yearly,
            },
          ],
        },
      });
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const hasFormValuesChanged = () =>
    JSON.stringify(formik.initialValues) !== JSON.stringify(formik.values);

  const handleOnClose = () => {
    formik.resetForm();
    if (onModalClose) {
      onModalClose();
    }
    resetEditForm();
  };

  const handleOnSaveClick = () => {
    if (hasFormValuesChanged()) {
      formik.handleSubmit();
    } else {
      onSaveClick();
      resetEditForm();
    }
  };

  const handleAddClick = () => {
    resetEditForm();
    if (onModalOpen) {
      onModalOpen();
    }
    trackProfile('edit-modal-opened', { section: 'Desired Salary' });
  };

  const handleEditClick = (
    editInitialValues: ProfileProps['candidate']['desiredSalary']['formValues'],
  ) => {
    setInitialValues(editInitialValues);
    if (onModalOpen) {
      onModalOpen();
    }
    trackProfile('edit-modal-opened', { section: 'Desired Salary' });
  };

  return {
    formik,
    handleAddClick,
    handleEditClick,
    handleOnClose,
    handleOnSaveClick,
    hasFormValuesChanged,
    initialRef,
    isLoading_upsert,
  };
}
