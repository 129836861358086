import {
  Avatar,
  Box,
  LogoutIcon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  Flex,
  DropdownIcon,
} from '@terminal/design-system';

export interface UserMenuDesktopProps {
  fullName: string;
  onHelpClick: () => void;
  onTermsClick: () => void;
  onManageCookiesClick: () => void;
  signOut: () => Promise<void>;
  candidateAvailability: string | null;
}

export function UserMenuDesktop({
  fullName,
  onHelpClick,
  onTermsClick,
  onManageCookiesClick,
  signOut,
  candidateAvailability,
}: UserMenuDesktopProps) {
  const [firstName] = fullName?.split?.(/\s/g) || [];

  return (
    <Menu>
      <MenuButton>
        <Flex flexDirection="row" align="center">
          <Avatar
            className="notranslate"
            name={fullName}
            color="text.inverse"
            bg="accent.darkest"
            size="md"
          />
          <Text variant="caption" ml={2}>
            {firstName}
          </Text>
          <DropdownIcon fontSize="sm" ml={2} />
        </Flex>
      </MenuButton>
      <MenuList>
        <Box py={4} px={6}>
          <Text variant="label" mb={1} textTransform="capitalize">
            {fullName}
          </Text>
          <Text variant="hint">{candidateAvailability}</Text>
        </Box>

        <MenuDivider />
        <MenuGroup>
          <MenuItem onClick={onHelpClick}>
            <Text variant="caption">Help</Text>
          </MenuItem>
          <MenuItem onClick={onTermsClick}>
            <Text variant="caption">Terms of Use</Text>
          </MenuItem>
          <MenuItem onClick={onManageCookiesClick}>
            <Text variant="caption">Manage Cookies</Text>
          </MenuItem>
        </MenuGroup>

        <MenuDivider />

        <MenuGroup>
          <MenuItem onClick={signOut}>
            <LogoutIcon fontSize="xl" mr={3} />
            <Text variant="caption">Logout</Text>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
